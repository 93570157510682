<div class="main-container">
    <h1>{{group?.name}}</h1>
    <h4><strong>Formador </strong> &nbsp; {{group?.trainerName}}</h4>
    <p><strong>Fecha inicio </strong> {{group?.start | date:'dd/MM/yyyy'}} - <strong>Fecha fin </strong> {{group?.end | date:'dd/MM/yyyy'}}</p>
    <p>En este espacio podrás visualizar los detalles de la solicitud, detallar los candidatos <br> que ya han sido cargados a esta solicitud. No olvides, “El respeto y empatía son clave”.</p>
    <mat-tab-group (selectedTabChange)="onTabChanged($event);">
        <mat-tab label="Detalles">
            <div fxLayout="column" fxLayoutAlign="space-around center">
                <div class="group_actions">
                    <button mat-flat-button color="primary" *ngIf="bonding" (click)="openPreBonding()">
                Gestionar previnculación
                </button>

                    <button mat-button (click)="activeEdit()" *ngIf="trainerGroupForm.disabled && noEdit">
                    Editar detalles del grupo &nbsp;
                    <span class="fi fi-rr-edit"></span>
                </button>
                </div>
                <form [formGroup]="trainerGroupForm" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start">

                    <mat-form-field appearance="fill" fxFlex="0 1 calc(25% - 32px)">
                        <mat-label>Nombre</mat-label>
                        <input matInput formControlName="name" type="text">
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="0 1 calc(25% - 32px)">
                        <mat-label>Modalidad</mat-label>
                        <mat-select formControlName="modality">
                            <mat-option [value]="1">Presencial</mat-option>
                            <mat-option [value]="2">Remota</mat-option>
                            <mat-option [value]="3">Mixta</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="0 1 calc(25% - 32px)">
                        <mat-label>Capacidad</mat-label>
                        <input matInput formControlName="capacity" type="number">
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="0 1 calc(25% - 32px)">
                        <mat-label>Rescate</mat-label>
                        <mat-select formControlName="mode_rescue">
                            <mat-option [value]="1">Si</mat-option>
                            <mat-option [value]="0">No</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="0 1 calc(25% - 32px)">
                        <mat-label>Fecha Inicio</mat-label>
                        <input matInput [matDatepicker]="picker1" formControlName="start" [value]="group.start">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="0 1 calc(25% - 32px)">
                        <mat-label>Fecha Fin</mat-label>
                        <input matInput [matDatepicker]="picker2" formControlName="end">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="0 1 calc(25% - 32px)">
                        <mat-label>Ubicación</mat-label>
                        <mat-select formControlName="ubication">
                            <mat-option *ngFor="let site of sites" [value]="site.id">{{site.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- <mat-form-field appearance="fill" fxFlex="0 1 calc(25% - 32px)">
                        <mat-label>Formador</mat-label>
                        <mat-select formControlName="user_trainer_id">
                            <mat-option *ngFor="let trainer of trainers" [value]="trainer.id">{{trainer.name}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <mat-form-field appearance="fill" fxFlex="calc(25% - 32px)">
                        <mat-label>Formador</mat-label>
                        <input matInput [(ngModel)]="user_trainer_name" [ngModelOptions]="{standalone: true}" disabled>
                        <button mat-icon-button matSuffix type="button" [disabled]="trainerGroupForm.disabled" matTooltip="Buscar Responsable" (click)="searchUsers('formador')">
                        <i class="fi-rr-search" ></i>
                      </button>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="0 1 calc(25% - 32px)">
                        <mat-label>Habilitar Exámenes Médicos</mat-label>
                        <mat-select formControlName="medicalTests">
                            <mat-option value="1">Habilitado </mat-option>
                            <mat-option value="0">No Habilitado </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="0 1 calc(25% - 32px)">
                        <mat-label>Caso GLPI</mat-label>
                        <input matInput formControlName="case_glpi">
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="0 1 calc(25% - 32px)">
                        <mat-label>Tipo de grupo</mat-label>
                        <mat-select formControlName="group_type_id">
                            <mat-option *ngFor="let group of  groupType" [value]="group.id">
                                {{group.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="0 1 calc(25% - 32px)">
                        <mat-label>Segmento </mat-label>
                        <input matInput formControlName="segment">
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="0 1 calc(100% - 32px)">
                        <mat-label>Descripción</mat-label>
                        <textarea matInput placeholder="Descripción..." formControlName="description"></textarea>
                    </mat-form-field>
                </form>
                <div class="action-btns" *ngIf="!trainerGroupForm.disabled">
                    <button mat-stroked-button (click)="activeEdit()">Cancelar</button>
                    <button mat-raised-button color="primary" (click)="updateGroup()">Actualizar</button>

                </div>

            </div>
        </mat-tab>

        <mat-tab label="Candidatos">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="filter">
                <mat-form-field appearance="fill" class="col-3">
                    <mat-label>Tipo de filtro</mat-label>
                    <mat-select [(ngModel)]="typeFilter">
                        <mat-option value="Identificación">Identificación</mat-option>
                        <mat-option value="Email">Email</mat-option>
                        <mat-option value="Teléfono">Teléfono</mat-option>
                        <mat-option value="Estado">Estado</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" *ngIf="typeFilter !=null && typeFilter!='Estado'" class="col-3">
                    <mat-label>Filtrar por {{typeFilter}}</mat-label>
                    <input matInput #filter>
                    <button mat-icon-button matSuffix (click)="filterByValue(filter.value)"><mat-icon>search</mat-icon></button>
                </mat-form-field>
                <mat-form-field appearance="fill" *ngIf="typeFilter=='Estado'" class="col-3">
                    <mat-label>Filtrar por Estado</mat-label>
                    <mat-select #filterState (selectionChange)="filterByValue(filterState.value)">
                        <mat-option *ngFor="let row of statesToFilter" [value]="row.id">{{row.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="btns">
                    <button mat-button (click)="seeFiles()">Descargas &nbsp; <span class="fi-rr-download"></span></button>
                    <button mat-stroked-button color="primary" (click)="actionsToCandidates()">Acciones por lotes</button>
                    <button mat-raised-button color="primary" (click)="registerCandidates()" *ngIf="noEdit">Agregar Candidato</button>

                </div>

            </div>
            <div class="table">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button matTooltip="Crear proceso" (click)="commentCandidates(row)">  <span class="fi fi-rr-comment"></span> </button>
                            <button mat-icon-button matTooltip="Ver documentos del candidato" (click)="getDocuments(row.id)">  <span class="fi-rr-folder"></span> </button>
                            <button mat-icon-button *ngIf="row.medicalTest" [matTooltip]="row.medicalTest">  <span class="fi-rr-treatment"></span> </button>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Nombre </th>
                        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                    </ng-container>


                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Estado </th>
                        <td mat-cell *matCellDef="let row">
                            <span matSubheader>
                                <mat-icon [style.color]="row.stateColor" >fiber_manual_record</mat-icon> &nbsp; {{row.states.name}}</span>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="id_number">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header># Documento </th>
                        <td mat-cell *matCellDef="let row"> {{row.documentId}} </td>
                    </ng-container>


                    <ng-container matColumnDef="mobile_phone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Celular </th>
                        <td mat-cell *matCellDef="let row"> {{row.mobile_phone}} </td>
                    </ng-container>


                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                        <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                    </ng-container>

                    <ng-container matColumnDef="enterprises">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Empresa</th>
                        <td mat-cell *matCellDef="let row"> {{row.enterprises}} </td>
                    </ng-container>

                    <ng-container matColumnDef="campaigns">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Campaña</th>
                        <td mat-cell *matCellDef="let row"> {{row.campaigns}} </td>
                    </ng-container>

                    <ng-container matColumnDef="case_glpi">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ticket GLPI</th>
                        <td mat-cell *matCellDef="let row"> {{row.case_glpi}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="9">No hay candidatos asociados al grupos de formación.</td>
                    </tr>
                </table>

                <mat-paginator [length]="length" [pageSize]="pageSize" (page)="getPaginatorData($event)" showFirstLastButtons></mat-paginator>

            </div>
        </mat-tab>
    </mat-tab-group>
</div>