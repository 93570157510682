<div class="container-fluid">
  <div class="row my-5 justify-content-start">
    <div class="col-md-4">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Buscar usuarios</mat-label>
        <input type="text" matInput [formControl]="userSearchControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let user of filteredUsers" [value]="user">
            <div (click)="optionClicked($event, user)">
              <mat-checkbox [checked]="user.selected" (change)="toggleSelection(user)" (click)="$event.stopPropagation()">
                <span style="font-weight: normal;">{{ user.name }}</span>
              </mat-checkbox>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="col-2">
      <button class="heaight-buttons w-100"  matTooltip='Buscar' matTooltipPosition="above" mat-flat-button color="primary"  (click)="getInfoUserSelection()" [disabled]="!userSelected.length">
        Buscar &nbsp; <i class="fi-rr-search"></i>
    </button>
    </div>
  </div>
  
  <app-exchange-lists
    *ngIf="showExchangeLists && userSelected.length"
    [taskDescription]="'Nota: Recuerda que para asignar campañas a los usuarios, deberás seleccionarlas con los checks del lado izquierdo (campañas disponibles) y luego dar clic en el botón de la flecha para pasarlas al lado derecho (campañas asignadas).'"
    [titleFirstList]="'Campañas disponibles'" [titleSecondList]="'Campañas asignadas'"
    [textInputFirstList]="'Buscar campaña disponible'" [textInputSecondList]="'Buscar campañas asignadas'"
    [firstList]="firstList" [secondList]="secondList" (runFilter)="runFilterList($event)"
    (updateList)="updateList($event)" (saveConfig)="saveConfig($event)" (cancelProcess)="cancelProcess()">
  </app-exchange-lists>
</div>