import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { Observable } from 'rxjs';

const CRM_URL = environment.CRM_NSDP;
const CIU_URL = environment.CIU_URL;

@Injectable({
  providedIn: 'root'
})

export class FormsRequestService {

  // private URL = environment.local_URL
  constructor(private http: HttpClient, private AuthService:AuthService) { }


  getForm(direction, size, page){
    if (size === 0 || page === 0) {
      return this.http.get<any>(`${CRM_URL}${direction}`);
    } else {
      return this.http.get<any>(`${CRM_URL}${direction}?n=${size}&page=${page}`);
    }
   }
  postForm(direction, form){
    return this.http.post<any>(`${CRM_URL}${direction}/`, form);
  }

  postForm1(direction, form){
    return this.http.post<any>(`${CRM_URL}${direction}`, form);
  }
  putForm(direction, form){
    return this.http.put<any>(`${CRM_URL}${direction}/`, form);
  }
  getRols(direction){
    return this.http.get<any>(`${CIU_URL}${direction}/`);
   }


   getHistory(id){
    return this.http.get<any>(`${CRM_URL}formanswer/historic/${id}`);
   }

   getPageHistory(url){
    return this.http.get<any>(`${url}`);
   }

   getFormByUser(direction, id, size, page){
    return this.http.get<any>(`${CRM_URL}${direction}${id}?n=${size}&page=${page}`);
  }

  postReport(obj){
    return this.http.post<any>(`${CRM_URL}report`, obj, {responseType: 'blob' as 'json'});
  }



  updateInfo(form, id){
    return this.http.post(`${CRM_URL}formanswer/update/${id}`, form);
  }

  getPrechargeField(id) {
    return this.http.get(`${CRM_URL}searchPrechargeFields/${id}`)
  }

  getPlantilla(parameters){
    return this.http.get<any>(`${CRM_URL}form/dowload/${parameters}`,{responseType: 'blob' as 'json'});
  }

  getAssignations(formId) {
    return this.http.get(`${CRM_URL}tray/assignedClients/${formId}`)
  }

  getData(endpoint){
    return this.http.get<any>(`${CRM_URL}${endpoint}`);
   }

   addSection(request){
    return this.http.post<any>(`${CRM_URL}addSection/`, request);
  }

  /**
   * 
   * @param formDataMetodo que se encarga de subir el archivos para las opciones de un selector cargable por archivos excel 
   * @author Juan David Guerrero Vargas 
   * @returns Observable
   */
  uploadFileOptionsToField(formData:FormData,){
    return this.http.post<any>(`${CRM_URL}field-options/import`, formData);
  }

  /**
   * Metodo que se encarga de listarlas opciones cargadas para el despelgable por carga
   * @author Juan David Guerrero Vargas 
   * @param formId:string {string} id del formulario
   * @param fieldId:string {string} id el campo a cargar
   * @param nameOption?:FormData { FormData} nombre del campo a buscar, parametro opcional de filtrado
   */
  getOptionsUploadTofield(formId:string, fieldId:string, filter:boolean, nameOption?:FormData){
    if(filter == false){
      return this.http.get<any>(`${CRM_URL}field-options/index/${formId}/${fieldId}`);
    }else{
      return this.http.post<any>(`${CRM_URL}field-options/index/${formId}/${fieldId}`, nameOption );
    }
  }

  getOptionsUploadFileAll(formId:string, fieldId:string, filter:boolean, nameOption?:FormData){
    if(filter == false){
      return this.http.get<any>(`${CRM_URL}field-options/actives/${formId}/${fieldId}`);
    }else{
      return this.http.post<any>(`${CRM_URL}field-options/actives/${formId}/${fieldId}`, nameOption );
    }
  }


  /**
   * Metodo que elimina las opciones de un selector cargado por Excel
   * @author Juan David Guerrero Vargas 
   * @param listOptions:any[] {any[]}
   * @returns {Observable} 
   */
  deleteToOptionsLoad(listOptions:any){
    return this.http.post<any>(`${CRM_URL}field-options/delete-masive`, listOptions );
  }

  /**
   * Metodo que se encarga de desbloquear la gestion
   * @param formAnswerId:string {string} numero de formanswerid a desbloquear
   * @returns 
   */
  unLockManage(formAnswerId:string){
    return this.http.post<any>(`${CRM_URL}formanswer/unblock`,{ formAnswerId:formAnswerId }  );
  }


  updateToOptionsField(optionsUpdate:any, formId:string, fieldId:string){
    return this.http.post<any>(`${CRM_URL}field-options/change/state/${formId}/${fieldId}`, optionsUpdate );

  }

  /**
   * Metodo que se encarga de consultar los valores para obtenidos por API
   * @param id_api:strin {string} id del API a consultar
   * @param bodyRequest:any {any} cuerpo de la peticion
   * @returns {Observable<any>} Observable<any>
   */
  getValuesConsumeAPI(id_api:string, bodyRequest:any):Observable<any>{
    return this.http.post<any>(`${CRM_URL}consumir/${id_api}`, bodyRequest);
  }

  /**
   * Metodo que retorna los enpoinst configurables 
   * @returns {Observable<any[]>} Observable<any[]> Observable de enpoints
   */
  getEnpoinst():Observable<any[]>{
    return this.http.get<any[]>(`${CRM_URL}consumir/endpoints`);
  }

}
