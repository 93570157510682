import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { ResourcesService } from '../../../services/resources.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-bookings-main',
  templateUrl: './bookings-main.component.html',
  styleUrls: ['./bookings-main.component.sass']
})
export class BookingsMainComponent implements OnInit {
  resourceTypes =[];
  bookingList =[];
  dataSource:any
  displayedColumns = ['resource', 'created_at', 'start', 'status'];
  @Output() prop_type = new EventEmitter<any>();


  constructor( public resourcesService:ResourcesService,  private dialog: MatDialog,
    public router: Router,  public auth:AuthService) { }

  ngOnInit(): void {
    let userId = this.auth.getUser().rrhh_id
    this.resourcesService.getResources('booking/user', {rrhhId:userId }).subscribe(res=>{
      this.bookingList = res
      this.dataSource = new MatTableDataSource(res);
    })
  }
  dialogTypes(){
    this.resourcesService.getResources('resourcetypes', {}).subscribe(res=>{
      this.resourceTypes = res
      const resourcesDialog = this.dialog.open( TypeResources, {
        data: { types: this.resourceTypes}
      });
      resourcesDialog.afterClosed().subscribe(type=>{
        if(type){
          this.prop_type.emit(type);
          this.router.navigate([`/mios/reservas/reservar/${type[0].id}/${type[0].name}`]);
        }
      })
    })
  }

}
@Component({
  selector: 'type-resources',
  templateUrl: './type-resources.html',
  styleUrls: ['./bookings-main.component.sass']
})
export class TypeResources implements OnInit {
  type:any
  constructor( private dialogRef: MatDialogRef<TypeResources>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

   ngOnInit(): void { }

}
