<div class="row col-12 filter" style="padding-top:40px;">

    <mat-form-field class="col-3" appearance="fill">
        <mat-label>Filtro de búsqueda</mat-label>
        <input matInput (keydown.enter)="applyFilter($event)" (blur)="applyFilter($event)" #input>
        <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
    </mat-form-field>

    <div class="col-6"></div>

    <div class="col-3">
        <button mat-raised-button color="primary" (click)="newSolicitud()">Crear nueva solicitud <i class="fi-rr-add" ></i></button>
    </div>

</div>

<div class="table-container">
    <div >
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="acciones">
                <th class="start" mat-header-cell *matHeaderCellDef> Acciones</th>
                <td mat-cell *matCellDef="let row" align="left">
                    <button mat-icon-button (click)="viewSolicitud(row)" *ngIf="row.request_state_id != 1">
                            <i class="fi-rr-eye"></i>
                        </button>
                    <button mat-icon-button (click)="viewSolicitudAprobada(row)" *ngIf="row.request_state_id == 4">
                            <i class="fi-rr-document-signed"></i>
                        </button>
                    <!-- <button mat-icon-button (click)="deleteCampaign(row)">
                        <i class="fi-rr-trash"></i>
                        </button> -->
                </td>
            </ng-container>
            <ng-container matColumnDef="request_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Creación </th>
                <td mat-cell *matCellDef="let row"> {{row.request_date|date:'yyyy-MM-dd'}} </td>
            </ng-container>
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre de solicitud </th>
                <td mat-cell *matCellDef="let row"> {{row.title}} </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción de solicitud </th>
                <td mat-cell *matCellDef="let row" matTooltip="{{row.description}}" [matTooltipClass]="'my-tooltip'">
                    {{row.description|slice:0:50}}<small *ngIf="row.description.length > 50">...</small>
                </td>
            </ng-container>
            <ng-container matColumnDef="created_by">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre del solicitante </th>
                <td mat-cell *matCellDef="let row"> {{row.created_by}} </td>
            </ng-container>
            <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre del olicitante </th>
                <td mat-cell *matCellDef="let row"> {{row.company.name}} </td>
            </ng-container>

            <ng-container matColumnDef="state">
                <th class="end" mat-header-cell *matHeaderCellDef mat-sort-header>Estado </th>
                <td mat-cell *matCellDef="let row" align="left"><small><i class="span" style="align-items: initial;" [style.backgroundColor]= "row.request_state.color" [style.color]= "row.request_state.color">.</i></small> {{row.request_state.name}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro: "{{input.value}}"</td>
            </tr>
        </table>

        <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)" [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>


    </div>
</div>
