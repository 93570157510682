import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../core/services/rest/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrainingGroupsService {

  private server =  environment.RRHH_URL;
  user;

  constructor(private http: HttpClient,
              private activeRoute: ActivatedRoute,
              private authService: AuthService) {
                this.user = this.authService.getUser();
              }
              httpOptions = {
                headers: new HttpHeaders({
                  'Content-Type': 'application/json'
                })
              };

  getGroups(entries) {
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}formation/groups`, {params});
  }

  getGroup(groupId) {
    return this.http.get(`${this.server}formationgroup/${groupId}`, this.httpOptions);
  }

  getSites() {
    return this.http.get(`${this.server}sites`, this.httpOptions);
  }

  createGroup(params){
    return this.http.post(`${this.server}formation/groups`, params);
  }

  updateGroup(groupId, params){
    return this.http.post(`${this.server}formation/groups/${groupId}`, params);
  }

  getCandidatesByGroup(groupId,entries){

    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}formationgroup/candidate/${groupId}`, {params});
  }

  assignCandidatesToGroup(params){
    return this.http.post(`${this.server}formation/assign/candidates`, params);
  }

  candidateComments(entries){
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}formationgroup/commments/candidate`,{params});
  }

  createCommentOrEvaluations(entries){
  const params = new HttpParams( { fromObject: entries } );
  return this.http.post(`${this.server}formation/candidate/data`,params);

  }

  uploadAttachments(entries,candidateId){
    const formData = new FormData();
    formData.append('attachments', entries);
    formData.append('candidateId', candidateId);

    return this.http.post(`${this.server}formationgroup/attachments`,formData);
  }

  stateToFilter(){
    return this.http.get(`${this.server}formationgroup/filter/list`);
  }

  downloadFormat(formationGroupId:number,format:string): Observable<any>{
  // const params = {'format':format,responseType:'blob'};
    return this.http.get(`${this.server}candidate/export/format/${formationGroupId}/${format}`, {responseType:'blob'});
  }

  candidatesByGroup(formationGroupId){
    return this.http.get(`${this.server}formationgroups/candidates/${formationGroupId}`);
  }
  commentsMultiples(entries){
    const params = new HttpParams( { fromObject: entries } );
    return this.http.post(`${this.server}formation/comment/candidates`,params);
  }

  scheduleDateToCandidates(entries){
    const params = new HttpParams( { fromObject: entries } );
    return this.http.post(`${this.server}candidates/schedule/date`,params);
  }
  getValidGroups(direction){
    return this.http.get(`${this.server}${direction}`);
  }
  postReassignment(direction, data){
    return this.http.post(`${this.server}${direction}`, data);
  }
  postProBording(direction, data){
    return this.http.post(`${this.server}${direction}`, data);
  }
  getDataFilters(direction){
    return this.http.get(`${this.server}${direction}`);
  }
  getUsersByRols(direction, rol){
    return this.http.get<any>(`${this.server}${direction}/${rol}`);
  }
}
