<div class="details" *ngIf="request" fxLayout="row" >
    <mat-card fxFlex="45" class="info-card">
        <mat-card-header></mat-card-header> <br>
        <h4>Información General</h4>
        <mat-card-content fxLayout="column">
            <div fxLayout="row wrap">
                <div fxFlex="50" *ngIf="request?.state">
                    <strong>Estado</strong>
                    <p>{{request.state}}</p>
                 </div>
                 <div fxFlex="50">
                    <strong>Tipo de solicitud</strong>
                    <p>{{request?.requestType}}</p>
                 </div>

                 <div fxFlex="50" *ngIf="request?.currentTracing">
                    <strong>Proceso actual</strong>
                    <p>{{request?.currentTracing}}</p>
                 </div>
                 <div fxFlex="50" *ngIf="request?.currentTracingUser">
                    <strong>Responsable actual</strong>
                    <p>{{request?.currentTracingUser}}</p>
                 </div>
                 <div fxFlex="50" *ngIf="request?.request_data?.iniciooperacion">
                    <strong>Inicio de Operación</strong>
                    <p>{{request.request_data.iniciooperacion | date:'dd/MM/yyyy'}}</p>
                </div>
                <div fxFlex="50">
                    <strong>Cargo</strong>
                    <p>{{request?.request_data.cargo}}</p>
                 </div>

            </div>
            <hr>
            <div fxLayout="row wrap">
              <div fxFlex="50" *ngIf="request?.request_data['motivo-solicitud0']">
                <b>Motivo</b >
              <p *ngIf="request?.request_data['motivo-solicitud0'] == 1">Incremento</p>
              <p *ngIf="request?.request_data['motivo-solicitud0'] == 2">Reemplezo  </p>
            </div>
              <div fxFlex="50" *ngIf="request?.request_data['motivo-solicitud0']">
                <strong *ngIf="request?.request_data['motivo-solicitud0'] == 2">A quién reemplaza</strong >
               <strong *ngIf="request?.request_data['motivo-solicitud0'] == 1">Correo de aprobación</strong >
                <p *ngIf="request?.request_data['motivo-solicitud0'] == 1">{{request?.request_data['correo-de-aprobacion2']}}</p>
               <p *ngIf="request?.request_data['motivo-solicitud0'] == 2">{{request?.request_data['a-quien-reemplaza1']}}</p>
              </div>
                <div fxFlex="50" *ngIf="request?.request_data?.jornadaTrabajo">
                    <strong>Jornada de Trabajo</strong>
                    <p>{{request.request_data.jornadaTrabajo.name}}</p>
                </div>

                <div fxFlex="50" *ngIf="request?.request_data?.modalidadLabor">
                    <strong>Modalidad de labor</strong>
                    <p>{{request.request_data.modalidadLabor.name }}</p>
                </div>
                <div fxFlex="50" *ngIf="request?.request_data?.asignacionSalarial">
                    <strong>Salario</strong>
                    <p>{{request.request_data.asignacionSalarial | currency:'COP':'symbol':'3.0' }}</p>
                </div>
                <div fxFlex="50" *ngIf="request?.request_data?.porcentajeComision">
                    <strong>Porcentaje comisión</strong>
                    <p>{{request.request_data.porcentajeComision }}%</p>
                </div>
                <div fxFlex="50" *ngIf="request?.request_data?.campana">
                    <strong>Campaña</strong>
                    <p>{{request.request_data.campana.name}}</p>
                </div>
                <div fxFlex="50" *ngIf="request?.request_data?.sites">
                    <strong>Lugar de Trabajo</strong>
                    <p>{{request.request_data.sites.name }}</p>
                </div>
                <div fxFlex="50"  *ngIf="request?.request_data?.tipoContrato">
                    <strong>Tipo de contrato</strong>
                    <p>{{request.request_data.tipoContrato.name }}</p>
                </div>
                <div fxFlex="50"  *ngIf="request?.request_data?.disponibilidad">
                    <strong>Disponibilidad</strong>
                    <p>{{request.request_data.disponibilidad.name}}</p>
                </div>
                <div fxFlex="50"  *ngIf="request?.request_data?.funciones" style="display: none;">
                    <strong>Funciones</strong>
                    <p>{{request.request_data.funciones}}</p>
                </div>
                <div fxFlex="50" *ngIf="request?.request_data?.horariosTrabajo">
                    <strong>Horarios Trabajo</strong>
                    <p>{{request.request_data.horariosTrabajo}}</p>
                </div>
                <div *ngIf="request?.request_data.horarioTrabajoInicio" fxFlex="50">
                  <strong>Jornada de Trabajo Inicio</strong>
                  <p>{{request?.request_data.horarioTrabajoInicio}}</p>
              </div>
              <div *ngIf="request?.request_data.horarioTrabajoFin" fxFlex="50">
                <strong>Jornada de Trabajo Fin</strong>
                <p>{{request?.request_data.horarioTrabajoFin}}</p>
            </div>
                <div fxFlex="50" *ngIf="request?.request_data?.cities">
                    <strong>Ciudad</strong>
                    <p>{{request.request_data.cities.name}}</p>
                </div>

                <div fxFlex="50" *ngIf="request?.request_data?.companies">
                    <strong>Empresa</strong>
                    <p>{{request.request_data.companies.name}}</p>
                </div>
            </div>
            <hr>
            <div fxLayout="row wrap">
                <div fxFlex="50" *ngIf="request?.applicant">
                    <strong>Solicitante</strong>
                    <p>{{request.applicant}}</p>
                </div>
                <div  fxFlex="50" *ngIf="request?.request_data?.cargoSolicitante">
                    <strong>Área Solicitante</strong>
                    <p>{{ request.request_data.cargoSolicitante.name }}</p>
                </div>
                <div fxFlex="50" *ngIf="request?.request_data?.areaSolicitante">
                    <strong>Área Solicitante</strong>
                    <p>{{request.request_data.areaSolicitante}}</p>
                </div>
            </div>

        </mat-card-content>
    </mat-card>

     <div fxFlex="52" fxLayout="column" >
             <mat-card class="info-card" *ngIf="request &&
             (request.request_data.remuneracionCapacitacion ||
             request.request_data.duracionCapacitacion ||
             request.request_data.modalidadFormacion ||
             request.request_data.inicioCapacitacion ||
             request.request_data.grupoFormacion)">
              <mat-card-header></mat-card-header> <br>
                <h4>Capacitación</h4>
                  <mat-card-content>
                    <div *ngIf="request?.request_data.inicioCapacitacion">
                        <strong>Inicio Capacitación</strong>
                        <p>{{request.request_data.inicioCapacitacion | date:'dd/MM/yyyy'}}</p>
                    </div>
                    <div *ngIf="request?.request_data.modalidadFormacion">
                        <strong>Modalidad Formación</strong>
                        <p>{{request.request_data.modalidadFormacion.name }}</p>
                    </div>
                    <div *ngIf="request?.request_data.duracionCapacitacion">
                        <strong>Duración Capacitación</strong>
                        <p>{{request.request_data.duracionCapacitacion.name}} días</p>
                    </div>
                    <div *ngIf="request?.request_data.grupoFormacion">
                        <strong>Grupo Formación</strong>
                        <p>{{request.request_data.grupoFormacion}}</p>
                    </div>
                    <div *ngIf="request?.request_data.remuneracionCapacitacion">
                        <strong>Remuneración Capacitación</strong>
                        <p>{{request.request_data.remuneracionCapacitacion.name }}</p>
                    </div>
                </mat-card-content>
            </mat-card>

        <mat-card class="info-card">
            <mat-card-header></mat-card-header> <br>
            <h4>Seguimiento de la Solicitud</h4>
            <mat-card-content fxLayout="row wrap">
                    <div fxFlex="50"  *ngIf="request">
                        <strong>Candidatos requeridos</strong>
                        <p>{{request.request_data.personalRequerido}} </p>
                    </div>
                   <div fxFlex="50"  *ngIf="request">
                        <strong>Candidatos agregados</strong>
                        <p>{{request.totalCandidates}}</p>
                    </div>
                      <div fxFlex="50"  *ngIf="request">
                        <strong>Candidatos solicitados</strong>
                        <p>{{request.request_data.personalSolicitado}} </p>
                    </div>
                    <div fxFlex="50"  *ngIf="request">
                        <strong>Candidatos contactados</strong>
                        <p>{{request.candidatesContacted}} </p>
                    </div>
                    <div fxFlex="50"  *ngIf="request">
                        <strong>Responsable del Seguimiento</strong>
                        <p>{{request.userResponsable}} </p>
                    </div>
            </mat-card-content>
        </mat-card>
    </div>

</div>
