import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LeftNavService } from '../services/rest/left-nav.service';

@Injectable({
  providedIn: 'root'
})
export class GroupsGuard implements CanActivate {
  
  constructor(private leftNavService: LeftNavService, private router: Router) {}

  async canActivate(): Promise<boolean> {

    if (await this.verifyRol() as boolean) {
      return true;
    } else {
      this.router.navigate(['/mios/crm2']);
      return false;
    }

  }

  public async verifyRol(): Promise<boolean> {

    let band = false;
    const vari = await this.leftNavService.getLeftMenu('crm2').toPromise();

    for (let element of vari.data) {

      if ( element.name === 'Grupos') {
        band = true;
        break;
      }
    }

    return band;
  }
  
}
