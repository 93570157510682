import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

const CIU_URL = environment.CIU_URL;
const CRM_URL = environment.CRM_URL;
const CRM2_URL = environment.CRM2_URL;
const CRMNSDP_URL = environment.CRM_NSDP;
@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-02
   * Servicio que lista todos los roles del aplicativo ciu
   * @param size tamaño de los datos a traer
   * @param page numero de pagina en la que se ubicara la tabla
   */
  getRoles(size: number, page: number): Observable<any>{
    return this.http.get<any>(`${CIU_URL}roles?n=` + size + `&page=` + page)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-03
   * Servicio que realiza una busqueda avanzada de roles por un valor
   * @param size tamaño de los datos a traer
   * @param value valor a buscar
   */
  searchRol(size: number, value: any): Observable<any>{
    return this.http.get<any>(`${CIU_URL}roles/search?n=` + size + `&q=` + value)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-03
   * Servicio que guarda un rol en su respectiva tabla
   * @param rol rol a guardarse
   */
  saveRol(rol: any): Observable<any>{
    return this.http.put<any>(`${CIU_URL}roles`, rol)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Servicio que edita un rol en su respectiva tabla
   * @param rol rol a editarse
   */
  editRol(id: any, rol: any): Observable<any>{
    return this.http.put<any>(`${CIU_URL}roles/` + id, rol)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Servicio que borra un rol en su respectiva tabla
   * @param idRol id del rol a eliminarse
   */
  deleteRol(idRol: any): Observable<any>{
    return this.http.delete<any>(`${CIU_URL}roles/` + idRol)
    .pipe(
      catchError(this.handleError)
      );
  }

  getModulesCrm(): Observable<any>{
    return this.http.get<any>(`${CRM_URL}moduleCrm`)
    .pipe(
      catchError(this.handleError)
      );
  }

  getModulesCrm2(): Observable<any>{
    return this.http.get<any>(`${CRM2_URL}moduleCrm`)
    .pipe(
      catchError(this.handleError)
      );
  }

  getModulesCrmNSDP(): Observable<any>{
    return this.http.get<any>(`${CRMNSDP_URL}moduleCrm`)
    .pipe(
      catchError(this.handleError)
      );
  }

  getPermissionsByIdRol(idRol: any): Observable<any>{
    return this.http.get<any>(`${CRM_URL}getPermissionsByIdRole/${idRol}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  getPermissionsByIdRolCRM2(idRol: any): Observable<any>{
    return this.http.get<any>(`${CRM2_URL}getPermissionsByIdRole/${idRol}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  getPermissionsByIdRolCRMNSDP(idRol: any): Observable<any>{
    return this.http.get<any>(`${CRMNSDP_URL}getPermissionsByIdRole/${idRol}`)
    .pipe(
      catchError(this.handleError)
      );
  }

}
