<div class="row py-3">
  <mat-form-field class="col-2" appearance="fill">
    <mat-label>Tipos de contrato</mat-label>
    <mat-select [formControl]="contractType">
      <mat-option *ngFor="let item of contractTypes" [value]="item?.contract_type_id">{{item?.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="col-2" appearance="fill">
    <mat-label>Origen</mat-label>
    <mat-select [formControl]="contractOrigin">
      <mat-option *ngFor="let item of contractOrigins" [value]="item?.id">{{item?.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="col-2 clear">
    <button mat-raised-button class="btnClear mr-15" (click)="resetFilters()">
      <i class="fi fi-rr-refresh"></i>
    </button>
    <button mat-raised-button class="btnClear" color="primary" (click)="downloadTagsReport()">
      <i class="fi fi-rr-download"></i>
    </button>
  </div>
  <div class="col-2"></div>
  <div class="col-2"></div>
  <div class="col-2">
    <button mat-raised-button class="btnClear" color="primary" (click)="newTag()">
      Crear nueva variable
    </button>
  </div>
</div>
<p *ngIf="!TagsSource">No existen coincidencias</p>
<div class="row" *ngIf="TagsSource">
  <br>
  <table mat-table [dataSource]="TagsSource" class="mat-elevation-z8">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Acciones</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="onClickEditTag(element)">
          <i class="fi-rr-edit"></i>
        </button>
      </td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="origin">
      <th mat-header-cell *matHeaderCellDef> Origen </th>
      <td mat-cell *matCellDef="let element"> {{element.origin}} </td>
    </ng-container>
    <!-- Version Column -->
    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef> Variable </th>
      <td mat-cell *matCellDef="let element"> {{element.key}} </td>
    </ng-container>
    <!-- Date Column -->
    <ng-container matColumnDef="contrato">
      <th mat-header-cell *matHeaderCellDef> Contrato relacionado </th>
      <td mat-cell *matCellDef="let element"> {{ element.contrato ? element.contrato : 'TODOS' }} </td>
    </ng-container>
    <!-- Version Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef class="descStyle"> Descripción </th>
      <td mat-cell *matCellDef="let element" class="descStyle"> {{element.description }} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="TagsColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: TagsColumns;"></tr>
  </table>
  <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
    (page)="onPageChange($event)"></mat-paginator>
  <br><br><br><br>
</div>