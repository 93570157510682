// This file can be replaced during build by using the fileReplacements array.
// ng build --prod replaces environment.ts with environment.prod.ts.
// The list of file replacements can be found in angular.json.

export const environment = {
    production: true,
    
        CIU_URL: 'https://backends.mysoul.software/ciu/api/',  
        BPMS_URL: 'https://backends.mysoul.software/bpms/api/',
        RRHH_URL: 'https://backends.mysoul.software/rrhh/api/',
        CRM_URL: 'https://backends.mysoul.software/crm/api/',
        PQRS_URL: '',
        CARTERA_URL: '',
        NOMINA_URL: 'https://backends.mysoul.software/nomina/api/',
        REPORTS_URL: '',
        REPORTSS_URL: '',
        MAIL_URL: '',
        PROVEEDOR_URL: '',
        COMPRAS_URL: '',
        CALIDAD_URL: '',
        CHAT_URL: '',
        NOTIFICATION_URL: 'https://backends.mysoul.software/notificaciones/api/',
        AGENDAMIENTOS_URL: '',
        JURIDICA_URL: '',
        VICIDIAL_URL: '',
        SOCKET_LIVECHAT: '',
        LIVECHAT_URL: '',
        pusher_app: '6ece382117e7b0852298',
        COACHING_URL: '',
        MODULAR_URL:'', //'https://apimodulards.groupcos.com/',
        // VOICEBOT_URL: 'https://voicebot.groupcos.com/api/',
        pusher_cluster: 'us2',
        public_back: 'https://bothuman.montechelo.com.co/backend',
        pusher_app_global: '72d7cf4e2090bde0d114',
        HEALTH_URL: '',
        CRM_AXA_COLPATRIA_URL: '',
        PUSHER_TRAFFIC_APP: '',
        PUSHER_CHAT_APP_KEY: '',  
        MEETINGS_URL: '',
        OJT_URL: '',
        AUDITORIA_URL: '',
        CARTERA_WOM_URL: '',
        CRM_CIAL: '',
        COSCOIN_URL: '',
        ID_FORM_PQRS: '359',
        STANDARD_URL: '',
        CARTERA_AIRE_URL: '',
        ID_FORM_SERVICEQUESTION: '398',
        CARTERA_AAA_URL:'',
        CARGUES_MASIVOS_CRM:'',
        // VOICEBOT_URL: 'http://172.10.7.20:8001/',
        VOICEBOT_URL: '',
        GESTION_CORREOS:'',
        SABLE_URL: '',
        ENCUESTAS: '',
        CRM2_URL:'https://backends.mysoul.software/crm/api/',
        RPA_INBOUND:'',
        RPA_OUTBOUND:'',
        GESTIONADOR_CORREOS:'',
        CARTERA_POPULAR_2023_URL:'',
        PQRS_SOUL: '',
        ENCUESTAS_INTERNAS: '',
        CARTERA_SERFINANZA_2023: '',
        CARTERA_FALABELLA_2021: '',
        CARTERA_FALABELLA_2020: '',
        CARTERA_PROPIA_TUYA_COMERCIAL_AGOSTO_2022: '',
        CARTERA_PROPIA_TUYA_COMERCIAL_JUNIO_2022: '',
        CARTERA_SERFINANZA_BOGOTA: '',
        CARTERA_PROPIA_TUYA_COMERCIAL_2021: '',
        CARTERA_PROPIA_TUYA_JUDICIALIZADA_2022: 'https://backends.mysoul.software/carteras/api/',
        CARTERA_PROPIA_BANCO_POPULAR_2021: '',
        CARTERA_PROPIA_BANCO_POPULAR_2020: '',
        CARTERA_PROPIA_TUYA_JUDICIALIZADA_2021: 'https://backends.mysoul.software/carteras/api/',
        CARTERA_NATURA_URL: '',
        CARTERA_ENTREAMIGOS_URL: '',
        CRM_NSDP: '',
        CARTERA_MOVISTAR: '',
        CARTERA_DIRECTV_URL: '',
        GESTIONADOR_CORREOS_PYTHON:'',
        CARTERA_PROPIA_BANCO_FALABELLA_2023: '',
        CARTERA_PROPIA_BANCO_FALABELLA_2024: '',
        SPEECH_URL:'',
        CARTERA_MOVISTAR_PREMIUM:'',
        CHAT_SOUL:'',
        JUPYTER_URL: '',
        CRM2_REPORTS_QUEUE: '',
        CARTERA_PROPIA_BANCO_BOGOTA_2024: '/',
        CARTERA_PROPIA_SERFINANZA_2024: '',
        SITE_MAPPING_URL: '',
        CARGUES_MASIVOS_VOICEBOT: '',
        CARTERA_DIRECTV_COBRANZA_URL: '',
        CARTERA_FIDUPOPULAR_URL: '',
        UNIFIER_AIOC_URL: '',
        CARTERA_BANCO_NU: '',
        MQTT_CONFIG: {
        SERVER:"mosquittoback.groupcos.com",
        PORT:443,
        USERNAME:"admin",
        PASSWORD:"Montechelo2021*"
        }
    
    };
  
  /*
  * For easier debugging in development mode, you can import the following file
  * to ignore zone related error stack frames such as zone.run, zoneDelegate.invokeTask.
  *
  * This import should be commented out in production mode because it will have a negative impact
  * on performance if an error is thrown.
  */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
