import { DisabilitiesService } from 'src/app/modules/nomina/services/rest/disabilities.service';
import { NoveltiesService } from 'src/app/modules/nomina/services/novelties.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { VacationsService } from 'src/app/modules/nomina/services/rest/vacations.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-disabilities-view',
  templateUrl: './disabilities-view.component.html',
  styleUrls: ['./disabilities-view.component.sass']
})
export class DisabilitiesViewComponent implements OnInit {
  dataInfo: any;
  public realTime: string;
  public scheduleTime: string;
  public endTime: string;
  public noveltyDetailHeight: number;

  public showJustifyForm: boolean;
  public showJustifyInfo: boolean;
  public showInput: boolean;
  files: any = [];

  constructor(    @Inject(MAT_DIALOG_DATA) public data: any,
  private alert: AlertsService,
  public dialog: MatDialog,
  private disabilities: DisabilitiesService
  ) { }


  ngOnInit() {
    console.log(this.data)
    this.disabilities.getInfoByPayrollNoveltyId(this.data.data.idNovelty).subscribe(resp => {
      this.dataInfo = resp.data;
      this.files = resp.data.files;
        
      if (this.dataInfo.validForMoreResponses) {
        this.showJustifyForm = true;
      }
      this.showJustifyInfo = true;
      this.changeDetailHeight(this.dataInfo);

    });
  }

   /**
   * @author Daniel Dominguez 
   * @createdate 2021-05-11
   * Metodo para asignacion de detalle en primera instancia
   */
    changeDetailHeight(novelty): void {
      if (novelty.responseFirstInstance && (novelty.responseFirstInstance.justify || novelty.responseFirstInstance.response)) { this.noveltyDetailHeight = 240; }
    }

}
