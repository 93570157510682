
 <div fxLayout="row" fxLayoutAlign="space-between start" class="main-data" >
  <div  fxLayout="row" fxLayoutAlign="center center">
      <div class="photo-decore">
          <img class="header-image" [src]="photo | userPhoto" onerror="this.src='../../../assets/images/dummy-user.jpg';" >
      </div>

      <div>
          <h2>{{_person?.fullname}}</h2>
          <h3>{{_person?.documentId}}</h3>
          <div *ngIf="!showEdit" fxLayout="row wrap" fxLayoutGap="20px grid" style="padding-top: 16px;">
            <div #target>
              <div >
                <ng-container *ngIf="candidateStatus === 6">
                  <strong *ngIf="!emailInput">Email Corporativo</strong>
                </ng-container>
                <ng-container *ngIf="candidateStatus !== 6">
                  <strong *ngIf="!emailInput">Email</strong>
                </ng-container>
                
                <button *ngIf="canUpdateEmail" [ngStyle]="{'display': emailInput ? 'none' : 'inline'}" matTooltip="Editar" mat-icon-button >
                  <span (click)="emailInput = true" class="fi-rr-edit"></span>
                </button> 
                <p *ngIf="!emailInput">{{_person?.email}}</p>
                <small *ngIf="_person?.email === _person?.email2 && candidateStatus === 6" class="advice">Si existe un email corporativo ¡actualízalo!</small>
              </div>
              <div *ngIf="emailInput" >
                <mat-form-field  appearance="standard">
                  <mat-label>Email corporativo</mat-label>
                  <input [formControl]="email" matInput placeholder="correo@ejemplo.com" [value]="_person?.email">
                  <mat-error *ngIf="email.hasError('email')"> Por favor ingrese un email válido </mat-error>
                </mat-form-field>
              </div>
                
          </div>
              <div >
                  <strong>Celular</strong>
                  <p>{{_person?.mobile_phone}}</p>
              </div >
              <div >
                  <strong>Fecha de nacimiento</strong>
                  <p>{{_person?.birth_date | date}} </p>
              </div>
              <div >
                  <strong>Estado civil</strong>
                  <p>{{_person?.civil_name}}</p>
              </div>
          </div>
          <div *ngIf="showEdit" fxLayout="row wrap" fxLayoutGap="20px grid" style="padding-top: 16px;">
              <mat-form-field appearance="fill" >
                  <mat-label>Email</mat-label>
                  <input matInput [(ngModel)]="_person.email" name="email">
              </mat-form-field>
              
              <mat-form-field appearance="fill" >
                  <mat-label>Celular</mat-label>
                  <input matInput type="number" [(ngModel)]="_person.mobile_phone" name="mobile_phone">
              </mat-form-field>
              <mat-form-field appearance="fill">
                  <mat-label>Fecha de nacimiento</mat-label>
                  <input matInput [matDatepicker]="picker" [(ngModel)]="_person.birth_date" name="birth_date" disabled>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="fill">
                  <mat-label>estado civil</mat-label>
                  <mat-select matNativeControl [(ngModel)]="_person.civil_state_id" name="civil_state_id">
                    <mat-option *ngFor="let state of _civilStates" [value]="state.id">
                      {{state.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
            </div>
 
      </div>
  </div>
  <button mat-button *ngIf="edit && !showEdit" (click)="editData()">
      <strong>Editar hoja de vida &nbsp;</strong>
      <span class="fi-rr-edit"></span>
  </button>

</div>
<mat-accordion class="info-section">
  <mat-expansion-panel expanded>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3>Datos personales</h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <div *ngIf="!showEdit" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="20px grid">
       <div fxFlex=16>
          <strong>Fecha expedición doc.</strong>
          <p>{{_person.personal_data?.expedition_date_id | date}}</p>
        </div>
        <div fxFlex=16>
          <strong>Ciudad exped. doc.</strong>
          <p>{{_person.personal_data?.city_document_id}}</p>
        </div>
        <div fxFlex=16 *ngIf="candidateStatus === 6">
          <strong>Email Personal</strong>
          <p>{{_person.email2}}</p>
        </div>
        <div fxFlex=16>
          <strong>Edad</strong>
          <p>{{_person.personal_data?.age}}</p>
        </div>
        <div fxFlex=16>
          <strong>Género</strong>
          <p>{{_person.personal_data?.gender}}</p>
        </div>
        <div fxFlex=16>
          <strong>Grupo social</strong>
          <p>{{_person.personal_data?.social_group}}</p>
        </div>
        <div fxFlex=16>
          <strong>Lugar de nacimiento</strong>
          <p>{{_person.personal_data?.place_of_birth}}</p>
        </div>
        <div fxFlex=16>
          <strong>Nacionalidad</strong>
          <p>{{_person.personal_data?.nationality}}</p>
        </div>
        <div fxFlex=16>
          <strong>Ciudad de residencia</strong>
          <p>{{_person.personal_data?.city_of_residence}}</p>
        </div>
        <div fxFlex=16>
          <strong>Dirección</strong>
          <p>{{_person.personal_data?.adress}}</p>
        </div>
        <div fxFlex=16>
          <strong>Localidad</strong>
          <p>{{_person.personal_data?.location}}</p>
        </div>
        <div fxFlex=16>
          <strong>Barrio</strong>
          <p>{{_person.personal_data?.neighborhood}}</p>
        </div>
        <div fxFlex=16>
          <strong>Estrato</strong>
          <p>{{_person.personal_data?.stratum}}</p>
        </div>
        <div fxFlex=16>
          <strong>Tipo de vivienda</strong>
          <p>{{_person.personal_data?.housing_Type}}</p>
        </div>
        <div fxFlex=16>
          <strong>Grupo sanguineo</strong>
          <p>{{_person.personal_data?.blood_type}} {{_person.personal_data?.rh}}</p>
        </div>
        <div fxFlex=16>
          <strong>Contacto de emergencia</strong>
          <p>{{_person.personal_data?.emergency_contact}}</p>
        </div>
        <div fxFlex=16>
          <strong>Número contacto emerg.</strong>
          <p>{{_person.personal_data?.emergency_number}}</p>
        </div>
        <div fxFlex=16>
          <strong>Situación Militar</strong>
          <p>{{_person.personal_data?.military_situation}}</p>
        </div>
        <div fxFlex=16 *ngIf="_person.personal_data?.notebook_class">
          <strong> Clase de Libreta</strong>
          <p>{{_person.personal_data?.notebook_class}}</p>
        </div>
        <div fxFlex=16 *ngIf="_person.personal_data?.notebook_number">
          <strong>Número de libreta</strong>
          <p>{{_person.personal_data?.notebook_number}}</p>
        </div>
        <div fxFlex=16 *ngIf="_person.personal_data?.military_district_number">
          <strong>Distrito Militar</strong>
          <p>{{_person.personal_data?.military_district_number}}</p>
        </div>
        <div fxFlex=16>
          <strong>Tiempo de Desplazamiento</strong>
          <p>{{_person.personal_data?.travel_time}}</p>
        </div>
        <div fxFlex=16>
          <strong>Tiene vehículo</strong>
          <p>{{_person.personal_data?.vehicle}}</p>
        </div>
        <div fxFlex=16 *ngIf="_person.personal_data?.vehicle_type">
          <strong>Tipo de desplazamiento</strong>
          <p>{{_person.personal_data?.vehicle_type}}</p>
        </div>
        <div fxFlex=16 *ngIf="_person.personal_data?.licence_number ">
          <strong>Número de licencia</strong>
          <p>{{_person.personal_data?.licence_number}}</p>
        </div>
        <div fxFlex=16 *ngIf="_person.personal_data?.licence_type">
          <strong>Tipo de licencia</strong>
          <p>{{_person.personal_data?.licence_type}}</p>
        </div>
        <div fxFlex=16>
          <strong>Número de cuenta</strong>
          <p>{{_person.personal_data?.account_number}} </p> <p>{{_person.personal_data?.bank}}</p>
        </div>
        <div fxFlex=16>
          <strong>Hobbies</strong>
          <p>{{_person.personal_data?.hobbie}}</p>
        </div>
        <div fxFlex=16>
          <strong>Talla de pantalón</strong>
          <p>{{_person.personal_data?.pants_size}}</p>
        </div>
        <div fxFlex=16>
          <strong>Talla de camisa</strong>
          <p>{{_person.personal_data?.shirt_size}}</p>
        </div>
        <div fxFlex=16>
          <strong>Talla de zapatos</strong>
          <p>{{_person.personal_data?.shoe_size}}</p>
        </div>
        <div fxFlex=16>
          <strong>Número de personas a cargo</strong>
          <p>{{_person.personal_data?.number_people_charge}}</p>
        </div>
        <div fxFlex=16>
          <strong>Número de personas que viven en su residencia</strong>
          <p>{{_person.personal_data?.number_people_at_home}}</p>
        </div>
        <div fxFlex=16>
          <strong>EPS</strong>
          <p>{{_person.personal_data?.eps?.name}}</p>
        </div>
        <div fxFlex=16>
          <strong>Fondo de pensiones</strong>
          <p>{{_person.personal_data?.caja_de_pensiones?.name}}</p>
        </div>
        <!-- <div fxFlex=16>
          <strong>Número de hijos</strong>
          <p>{{_person.personal_data?.children_number}}</p>
        </div> -->
    </div>
    <div *ngIf="showEdit" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="20px grid">
      <div *ngFor="let question of _secondSection; index as y" fxFlex=16 >
         
              <shared-form-questions [question]="question"  
              [form]="secordGroup" (addDependece)="addDependencies($event)"></shared-form-questions>
          
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="_person.personal_data?.children_number > 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
            <h3>Hijos</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngIf="!showEdit" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="20px grid">
          <div fxFlex=100 *ngFor="let child of _person.personal_data?.children">
              <div fxFlex=50>
                  <strong>Nombre</strong>
                  <p>{{child?.name}}</p>
                </div>
                <div fxFlex=50>
                  <strong>Edad</strong>
                  <p>{{child?.age}}</p>
                </div>
          </div>
      </div>

    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="startDate">
      <mat-expansion-panel-header >
        <mat-panel-title>
            <h3>Datos de contratación</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngIf="!showEdit" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="20px grid">
          <div fxFlex=16>
              <strong>Cargo</strong>
              <p>{{_person.agreementRules?.cargo?.name}}</p>
          </div>
          <div fxFlex=16>
              <strong>Área</strong>
              <p>{{_person.agreementRules?.area?.name}}</p>
          </div>
          <div fxFlex=16>
              <strong>Empresa</strong>
              <p>{{_person.agreementRules?.companies?.name}}</p>
          </div>
          <div fxFlex=16>
              <strong>Tipo de contrato</strong>
              <p>{{_person.agreementRules?.contractType?.name}}</p>
          </div>
          <div fxFlex=16>
              <strong>Fecha de ingreso</strong>
              <p>{{_person.agreementRules['fecha_de_ingreso'] | date}}</p>
          </div>
          <div fxFlex=16>
              <strong>Salario</strong>
              <p>${{_person.agreementRules?.salario | number}}</p>
          </div>
          <div fxFlex=16>
              <strong>EPS</strong>
              <p>{{_person.agreementRules?.eps?.name}}</p>
            </div>
            <div fxFlex=16>
              <strong>Fondo de pensiones</strong>
              <p>{{_person.agreementRules['caja_de_pensiones']?.name}}</p>
            </div>
             <div fxFlex=16>
              <strong>ARL</strong>
              <p>{{_person.agreementRules?.arl?.name}}</p>
            </div>
            <div fxFlex=16>
              <strong>Caja de compensación</strong>
              <p>{{_person.agreementRules['caja_de_compensacion']?.name}}</p>
            </div>
            <div fxFlex=16>
              <strong>Cesantías</strong>
              <p>{{_person.agreementRules?.cesantias?.name}}</p>
            </div>
            <div fxFlex=16>
              <strong>Banco</strong>
              <p>{{_person.agreementRules?.banco?.name}}</p>
            </div>
             <div fxFlex=16>
              <strong>Número de cuenta</strong>
              <p>{{_person.agreementRules?.bank_account}}</p>
            </div>
      </div>

    </mat-expansion-panel>
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
          <h3>Experiencia Laboral</h3>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div  *ngIf="!showEdit" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="30px grid">
      <div fxFlex=50 fxLayout="row" *ngFor="let job of _person.jobs_references">
          <div class="arrow">
              <span color="primary" class="fi-rr-angle-right"></span>
          </div>
          <div>
              <h4>{{job.company_name}}</h4>
              <h5>{{job.position_hired}}</h5>
              <p> <strong>{{job.start_date | date}} - {{job.end_date | date}}</strong> </p>
              <h5> <strong>Jefe Directo</strong>&nbsp;{{job.boss_name}} &nbsp; &nbsp; {{job.boss_phone}}</h5>
          </div>
      </div>
    </div>
    <div *ngIf="showEdit" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="30px grid">
      <div fxFlex=50 fxLayout="row" *ngFor="let job of _person.jobs_references; index as i">
          <div class="arrow">
              <span color="primary" class="fi-rr-angle-right"></span>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-between center">
              
                  <mat-form-field appearance="fill" fxFlex="grow">
                      <mat-label>Empresa</mat-label>
                      <input matInput [(ngModel)]="job.company_name" name="company_name">
                  </mat-form-field>
                  <mat-form-field appearance="fill" fxFlex="grow">
                      <mat-label>Cargo</mat-label>
                      <input matInput [(ngModel)]="job.position_hired" name="position_hired">
                  </mat-form-field>
                  <mat-form-field appearance="fill" fxFlex=48>
                      <mat-label>Fecha Inicio</mat-label>
                      <input matInput [matDatepicker]="picker1" [(ngModel)]="job.start_date" name="start_date">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field appearance="fill" fxFlex=48>
                      <mat-label>Fecha Fin</mat-label>
                      <input matInput [matDatepicker]="picker2" [(ngModel)]="job.end_date" name="end_date">
                      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field appearance="fill" fxFlex=48>
                      <mat-label>Jefe directo</mat-label>
                      <input matInput [(ngModel)]="job.boss_name" name="boss_name">
                  </mat-form-field>
                  <mat-form-field appearance="fill" fxFlex=48>
                      <mat-label>Teléfono del contacto</mat-label>
                      <input matInput [(ngModel)]="job.boss_phone" name="boss_phone">
                  </mat-form-field>
              </div>
              <button color="warn" mat-icon-button matSuffix (click)="removeItem('jobs_references', i)">
                  <span class="fi-rr-trash"></span>
              </button>
      </div>
      <div fxFlex=100 fxLayoutAlign="center center" >
          <button color="primary" mat-stroked-button (click)="addItem('jobs_references')">Añadir experiencia</button>
      </div>
    </div>

  </mat-expansion-panel>

  
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
          <h3>Estudios</h3>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div  *ngIf="!showEdit" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="30px grid">
      <div fxFlex=50 fxLayout="row" *ngFor="let education of _person.education; index as i">
          <div class="arrow">
              <span color="primary" class="fi-rr-angle-right"></span>
          </div>
          <div>
              <h4>{{education.obtained_title}}</h4>
              <h5>{{education.institution}}</h5>
              <p> <strong>{{education.finish_date | date}} &nbsp; &nbsp; {{education.periods_completed}} Semestres</strong> </p>
              <h5> <strong>Nivel</strong> {{education.education_level}} | {{education.study_city}}</h5>
          </div>
      </div>
    </div>
    <div  *ngIf="showEdit" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="30px grid">
      <div fxFlex=50 fxLayout="row" *ngFor="let education of _person.education; index as i">
          <div class="arrow">
              <span color="primary" class="fi-rr-angle-right"></span>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <mat-form-field appearance="fill"  fxFlex="grow">
                      <mat-label>Título obtenido</mat-label>
                      <input matInput  [(ngModel)]="education.obtained_title" name="obtained_title">
                  </mat-form-field>
                  <mat-form-field appearance="fill" fxFlex="grow">
                      <mat-label>Institución</mat-label>
                      <input matInput [(ngModel)]="education.institution" name="institution">
                  </mat-form-field>
           
                  <mat-form-field appearance="fill" fxFlex=48>
                      <mat-label>Fecha Inicio</mat-label>
                      <input matInput [matDatepicker]="picker3">
                      <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                      <mat-datepicker #picker3></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field appearance="fill" fxFlex=48>
                      <mat-label>Fecha Fin</mat-label >
                      <input matInput [matDatepicker]="picker4" [(ngModel)]="education.finish_date" name="finish_date">
                      <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                      <mat-datepicker #picker4></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field appearance="fill" fxFlex=48>
                      <mat-label>Nivel</mat-label>
                      <input matInput [(ngModel)]="education.education_level" name="education_level">
                  </mat-form-field>
                  <mat-form-field appearance="fill" fxFlex=48>
                      <mat-label>Ciudad</mat-label>
                      <input matInput [(ngModel)]="education.study_city" name="study_city">
                  </mat-form-field>
          </div>
       
          <button color="warn" mat-icon-button matSuffix (click)="removeItem('education', i)">
              <span class="fi-rr-trash"></span>
          </button>
        
      </div>
      <div fxFlex=100 fxLayoutAlign="center center" >
          <button color="primary" mat-stroked-button (click)="addItem('education')">
              Añadir estudios</button>
      </div>
    </div>

  </mat-expansion-panel>
  <mat-expansion-panel  >
      <mat-expansion-panel-header>
        <mat-panel-title>
            <h3>Referencias Personales</h3>
        </mat-panel-title>
      
      </mat-expansion-panel-header>
      <div *ngIf="!showEdit" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="30px grid">
         
          <div fxFlex=30 fxLayout="row" *ngFor="let reference of _person.personal_references">
              <div class="arrow">
                  <span color="primary" class="fi-rr-angle-right"></span>
              </div>
              <div>
                  <h4>{{reference.first_name}} {{reference.last_name}}</h4>
                  <h5>{{reference.occupation}}</h5>
                  <p> <strong>{{reference.relationship}}</strong> </p>
                  <h5> {{reference.mobile_phone}} | {{reference.city}}</h5>
              </div>
          </div>
        </div>
        <div *ngIf="showEdit" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="30px grid">
          <div fxFlex=30 fxLayout="row" *ngFor="let reference of _person.personal_references; index as i">
              <div class="arrow">
                  <span color="primary" class="fi-rr-angle-right"></span>
              </div>
              <div style="width: 100%;" fxLayout="column">
                  <mat-form-field appearance="fill">
                      <mat-label> Nombres</mat-label>
                      <input matInput [(ngModel)]="reference.first_name" name="first_name">
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                      <mat-label> Apellidos</mat-label>
                      <input matInput [(ngModel)]="reference.last_name" name="last_name">
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                      <mat-label>Ocupación</mat-label>
                      <input matInput  [(ngModel)]="reference.occupation" name="occupation">
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                      <mat-label>Teléfono</mat-label>
                      <input matInput [(ngModel)]="reference.mobile_phon" name="mobile_phon">
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                      <mat-label>Ciudad</mat-label>
                      <input matInput [(ngModel)]="reference.city" name="city">
                  </mat-form-field>
              </div>
              <button color="warn" mat-icon-button matSuffix (click)="removeItem('personal_references', i)">
                  <span class="fi-rr-trash"></span>
              </button>
          </div>
          <div fxFlex=100 fxLayoutAlign="center center" >
              <button color="primary" mat-stroked-button (click)="addItem('personal_references')">Añadir referencia</button>
          </div>
        </div>
 
    </mat-expansion-panel>

</mat-accordion>
<div *ngIf="showEdit" fxLayoutAlign="center center" class="action-btns">
  <button mat-stroked-button (click)="cancelAction()">cancelar</button>
  <button mat-flat-button color="primary" (click)="submitData()" >Actualizar</button>
</div>


