<div class="novelities">
  <div class="header">
    <h1>Novedades de nómina</h1>
    <p>En este espacio podrás revisar y/o gestionar las novedades de<br>los trabajadores, no olvides,"El respeto y la
      empatiá son clave"</p>
  </div>

  <mat-tab-group>
    <mat-tab label="Pendientes">

      <mat-form-field appearance="fill" class="col-2 filter">
        <mat-label>Filtrar por fecha</mat-label>
        <mat-date-range-input [formGroup]="reportForm" [rangePicker]="picker">
          <input matStartDate [formControl]="reportForm.controls['start']" placeholder="Start date" #startDate>
          <input matEndDate [formControl]="reportForm.controls['end']" placeholder="End date" #endDate
                 (dateChange)="dateRangeChange()">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-2 filter">
        <mat-label>Filtrar por identificación</mat-label>
        <input matInput type="text" #input autocomplete="off" (keydown.enter)="onBlurMethod($event.target.value)">
        <mat-select name="ampm" class="ampm" matSuffix [formControl]="typeDocument"
                    style="width: 60px; margin-left: 5px;">
          <mat-option *ngFor="let item of documents" [value]="item?.id">{{item.key}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-2 filter">
        <mat-label>Filtrar por campaña</mat-label>
        <mat-select [formControl]="filterControl">
          <mat-option value="">Seleccionar</mat-option>
          <mat-option *ngFor="let item of Campaings" [value]="item.id">
            {{item?.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-2 filter">
        <mat-label>Filtrar Novedad</mat-label>
        <mat-select [formControl]="filterControlNovelty">
          <mat-option value="">Seleccionar</mat-option>
          <mat-option *ngFor="let item of noveltyList" [value]="item.key">
            {{item?.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="downloadReport()" class="download">Descargar reporte
        <span class="fi-rr-download"></span>
      </button>

      <div class="table">
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="check">
            <th class="start" mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox *ngIf="row.novelty_type.key == '39' && (row.state_key !== 'revision' && row.state_key !== 'segunda-instancia')" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef> Fecha</th>
            <td mat-cell *matCellDef="let element"> {{element.novelty_date_start | date:"dd-MM-yyyy"}} </td>
          </ng-container>

          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef> Nombre trabajador</th>
            <td mat-cell *matCellDef="let element"> {{element.name}}</td>
          </ng-container>

          <ng-container matColumnDef="numero">
            <th mat-header-cell *matHeaderCellDef>Número de identificación</th>
            <td mat-cell *matCellDef="let element">{{element.id_number}}</td>
          </ng-container>

          <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef>Tipo de novedad</th>
            <td mat-cell *matCellDef="let element">{{element.noveltyTypeName}}</td>
          </ng-container>

          <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef> Estado</th>
            <td mat-cell *matCellDef="let element">{{element.stateName}}</td>
          </ng-container>

          <ng-container matColumnDef="campana">
            <th mat-header-cell *matHeaderCellDef> Campaña</th>
            <td mat-cell *matCellDef="let element">{{element.campaignName}}</td>
          </ng-container>

          <ng-container matColumnDef="acciones">
            <th class="end" mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="viewJustification(element)" *ngIf="( element.novelty_type.key == '39' && (element.state_key !== 'revision' && element.state_key !== 'segunda-instancia')) || ( element.novelty_type.key == '44' && element.state_key !== 'novedad-sistema')">
                <i class="fi-rr-settings"></i>
              </button>
              <button mat-icon-button (click)="viewJustification2(element)" *ngIf="( element.novelty_type.key == '39' && (element.state_key == 'revision' || element.state_key == 'segunda-instancia') ) || ( element.novelty_type.key == '44' && element.state_key == 'novedad-sistema')">
                <i class="fi-rr-eye"></i>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8">No hay datos que coincidan con el filtro: "{{input.value}}"</td>
          </tr>

        </table>
        <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)"
                       [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>

      </div>
      <button mat-raised-button color="ligth" class="myButton2" style="width: 192px; height: 48px; color:white;" (click)="saveMassive(1)" [disabled]="selection.selected.length == 0">Justificar</button>

    </mat-tab>
    <mat-tab label="Rechazadas">

      <mat-form-field appearance="fill" class="col-3 filter">
        <mat-label>Filtrar por fecha</mat-label>
        <mat-date-range-input [formGroup]="reportForm2" [rangePicker]="picker2">
          <input matStartDate [formControl]="reportForm2.controls['start2']" placeholder="Start date" #startDate2>
          <input matEndDate [formControl]="reportForm2.controls['end2']" placeholder="End date" #endDate2
                 (dateChange)="dateRangeChange2()">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-date-range-picker #picker2></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-3 filter">
        <mat-label>Filtrar por identificación</mat-label>
        <input matInput type="text" #input2 autocomplete="off" (keydown.enter)="onBlurMethod2($event.target.value)">
        <mat-select name="ampm" class="ampm" matSuffix [formControl]="typeDocument2"
                    style="width: 60px; margin-left: 5px;">
          <mat-option *ngFor="let item of documents" [value]="item?.id">{{item.key}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-3 filter">
        <mat-label>Filtrar por campaña</mat-label>
        <mat-select [formControl]="filterControl2">
          <mat-option value="">Seleccionar</mat-option>
          <mat-option *ngFor="let item of Campaings" [value]="item?.id">
            {{item?.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button color="primary" (click)="downloadReport2()" class="download">Descargar reporte
        <span class="fi-rr-download"></span>
      </button>


      <div class="table">

        <table mat-table [dataSource]="dataSource2">

          <ng-container matColumnDef="check">
            <th class="start" mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle2() : null"
                [checked]="selection2.hasValue() && isAllSelected2()"
                [indeterminate]="selection2.hasValue() && !isAllSelected2()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox *ngIf="row.novelty_type.key == '39' && row.state_key !== 'revision' && row.num_instance !== null" (click)="$event.stopPropagation()" (change)="$event ? selection2.toggle(row) : null" [checked]="selection2.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef> Fecha</th>
            <td mat-cell *matCellDef="let element"> {{element.novelty_date_start | date:"dd-MM-yyyy"}} </td>
          </ng-container>

          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef> Nombre trabajador</th>
            <td mat-cell *matCellDef="let element"> {{element.name}}</td>
          </ng-container>

          <ng-container matColumnDef="numero">
            <th mat-header-cell *matHeaderCellDef>Número de identificación</th>
            <td mat-cell *matCellDef="let element">{{element.id_number}}</td>
          </ng-container>

          <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef>Tipo de novedad</th>
            <td mat-cell *matCellDef="let element">{{element.noveltyTypeName}}</td>
          </ng-container>

          <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef> Estado</th>
            <td mat-cell *matCellDef="let element">{{element.stateName}}</td>
          </ng-container>

          <ng-container matColumnDef="campana">
            <th mat-header-cell *matHeaderCellDef> Campaña</th>
            <td mat-cell *matCellDef="let element">{{element.campaignName}}</td>
          </ng-container>

          <ng-container matColumnDef="acciones">
            <th class="end" mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="viewJustification(element)" *ngIf="( element.novelty_type.key == '39' && element.state_key !== 'revision' && element.num_instance == 1)">
                <i class="fi-rr-settings"></i>
              </button>
              <button mat-icon-button (click)="viewJustification2(element)" *ngIf="element.num_instance == null">
                <i class="fi-rr-eye"></i>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8">No hay datos que coincidan con el filtro: "{{input.value}}"</td>
          </tr>

        </table>
        <mat-paginator #paginator2 [length]="length2" [pageSize]="pageSize2" (page)="pageEvent2($event)"
                       [pageSizeOptions]="pageSizeOptions2">
        </mat-paginator>
      </div>
      <button mat-raised-button color="ligth" class="myButton2" style="width: 192px; height: 48px; color:white;" (click)="saveMassive2(1)" [disabled]="selection2.selected.length == 0">Justificar</button>


    </mat-tab>
    <!--<mat-tab label="Reporte Novedades">
      <div class="row col-12 filter" style="padding-top: 2rem">
        <form [formGroup]="reportForm3" (ngSubmit)="downloadReportNovelties()">
          <mat-form-field appearance="fill" class="col-3" style="padding-right: 1rem">
            <mat-label>Fecha Inicio y Fin</mat-label>
            <mat-date-range-input [rangePicker]="pickerRange3">
              <input matStartDate formControlName="startDate" placeholder="Fecha Inicio" #startDate3>
              <input matEndDate formControlName="endDate" placeholder="Fecha Fin" #endDate3>
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerRange3"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerRange3></mat-date-range-picker>
          </mat-form-field>

          <mat-form-field class="col-3" appearance="fill" style="padding-right: 1rem">
            <mat-label>Campaña:</mat-label>
            <input type="text" matInput
                   formControlName="campaignId" [matAutocomplete]="campaignList2">
            <mat-autocomplete #campaignList2="matAutocomplete" [displayWith]='displayFnCampaigns.bind(this)' required>
              <mat-option *ngFor="let campaign of filteredOptionsCampaigns | async" [value]="campaign.id">
                {{campaign.name}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="errorReport.campaignId.errors"><span *ngIf="errorReport.campaignId.errors.required">Campo es
                  requerido</span></mat-error>
            <mat-error *ngIf="errorReport.campaignId.errors">Opción no válida</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="col-3 filter">
            <mat-label>Filtrar por identificación</mat-label>
            <input matInput type="text" #input3 formControlName="docNumber" autocomplete="off">
            <mat-select name="ampm3" class="ampm3" matSuffix formControlName="docType"
                        style="width: 60px; margin-left: 5px;">
              <mat-option *ngFor="let item of documents" [value]="item?.id">{{item.key}}</mat-option>
            </mat-select>
          </mat-form-field>

          <button mat-raised-button color="primary" class="download"
                  [disabled]="reportForm3.controls.startDate.invalid || reportForm3.controls.endDate.invalid">Generar reporte
            <span class="fi-rr-download"></span>
          </button>

          <p>
            <b>Nota:</b> Genera el reporte de novedades en base a filtros
          </p>

        </form>
      </div>
    </mat-tab>-->
  </mat-tab-group>

</div>
