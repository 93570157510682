import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../../../core/services/rest/auth.service';
import { environment } from 'src/environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

const CIU_URL = environment.CIU_URL;

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient, private authService: AuthService) { }


  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

 /**
   * @author Carlos Nieto
   * @createdate 2022-01-20
   * Servicio que lista los  reportes
   */
  getReports(): Observable<any>{
    return this.http.get<any>(`${CIU_URL}reports/all`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-01-20
   * Servicio que guarda un report en su respectiva tabla
   * @param report a guardarse
   */
  saveReport(report: any): Observable<any>{
    return this.http.post<any>(`${CIU_URL}reports/insert`, report)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-01-20
   * Servicio que actualiza un report en su respectiva tabla
   * @param report a guardarse
   */
   updateReport(report: any): Observable<any>{
    return this.http.put<any>(`${CIU_URL}reports/update`, report)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
     * @author Carlos Nieto
     * @createdate 2022-01-20
     * Servicio que borra un report en su respectiva tabla
     * @param id a eliminar
     */
  deleteReport(id: any): Observable<any>{
    return this.http.delete<any>(`${CIU_URL}reports/delete/${id}`)
    .pipe(
      catchError(this.handleError)
      );
  }
}
