import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ChatRestService } from '../../services/chat-rest.service';

@Component({
  selector: 'app-view-users-group',
  templateUrl: './view-users-group.component.html',
  styleUrls: ['./view-users-group.component.sass']
})
export class ViewUsersGroupComponent implements OnInit {

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any, private chatRestService:ChatRestService, private cdr: ChangeDetectorRef) { }
  users_group:any;

  ngOnInit(): void {
   this.getUsersGroup()
  }

  /**
  * Método que obtiene un grupo por su id y asigna el valor al formulario de grupo
  * @author Juan Carlos Alonso
  * @createdate 05-06-2024
  * @returns {void} void
  */
  getUsersGroup(){
    this.chatRestService.getGroupById(this.bottomSheetData.idGroup, false).subscribe((resp:any) => {
      this.users_group = resp.group?.participants
      this.cdr.detectChanges()
    })
  }

}
