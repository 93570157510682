import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UsersService } from 'src/app/modules/ciu/services/rest/users.service';
import { CampaignsService } from 'src/app/modules/nomina/services/rest/campaigns.service';
import { VacationsResponseComponent } from '../vacations-response/vacations-response.component';
import { VacationsViewComponent } from '../vacations-view/vacations-view.component';
import { VacationsService } from 'src/app/modules/nomina/services/rest/vacations.service';
import { CandidatesService } from 'src/app/modules/rrhh/services/candidates.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { ReverseVacationsComponent } from '../reverse-vacations/reverse-vacations.component';

@Component({
  selector: 'app-vacation-operation-manager',
  templateUrl: './vacation-operation-manager.component.html',
  styleUrls: ['./vacation-operation-manager.component.sass']
})
export class VacationOperationManagerComponent implements OnInit {
// MatPaginator Inputs
length;
pageSize = 5;
page = 1;
isPage = 1;
pageSizeOptions: number[] = [ 5, 10, 25, 100 ];

displayedColumns: string[] = [ 'acciones', 'fecha', 'nombre', 'numero','tipo', 'estado', 'campana' ];
dataSource = new MatTableDataSource<any>();

reportForm: FormGroup;
Campaings: any;
filterControl = new FormControl();
filterControl2 = new FormControl();
states: any = [ { id: 1, name: 'En revisión' }, { id: 2, name: 'Aprobado' }, { id: 3, name: 'Rechazado' } ];
@ViewChild(MatSort, { static: true }) sort: MatSort;
@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

document: any = '';
startDate: any = '';
endDate: any = '';
idCampaing: any;
idState: any = 1;

documents: any = [];
idTypeDoc: any;
idTypeDoc2: any;

typeDocument = new FormControl();
typeDocument2 = new FormControl();

constructor(public dialog: MatDialog,
            private service: CampaignsService,
            public userService: UsersService,
            private vacation: VacationsService,
            private users: CandidatesService
) {
}

ngOnInit() {
  this.filterControl2.setValue(1);
  this.service.getAllCampaigns().subscribe(resp => {
    this.Campaings = resp.data;
  });
  this.reportForm = new FormGroup({
    start: new FormControl(''),
    end: new FormControl('')
  });
  this.users.getIdTypes().subscribe(resp => {
    this.documents = resp;
  });
  this.typeDocument.valueChanges.subscribe(resp => {
    this.idTypeDoc = resp;
    this.getExteporal(1, this.pageSize, this.page);
  });
  this.typeDocument.setValue(1);

  this.filterControl.valueChanges.subscribe(resp => {
    this.resetPaginator(1);
    this.idCampaing = resp;
    this.getExteporal(this.idState, this.pageSize, this.page);
  });
  this.filterControl2.valueChanges.subscribe(resp => {
    this.resetPaginator(1);
    this.idState = resp;
    this.getExteporal(this.idState, this.pageSize, this.page);
  });
}


/**
 * @author Javier Castañeda
 * @createdate 2021-04-29
 * Metodo de paginación
 */
pageEvent(event: any): void {

  this.pageSize = event.pageSize;
  this.page = event.pageIndex + 1;
  this.getExteporal(1, this.pageSize, this.page);
}

/**
 * @author Javier Castañeda
 * @createdate 2021-04-29
 * Modal de justificación
 */
viewJustification(element?): void {
  const dialogNewgroup = this.dialog.open(VacationsResponseComponent, {
    width: '60%',
    disableClose: true,
    data: {
      type: 'crear',
      title: 'Juan Manuel Gonzales',
      data: element,
      vacationType: 'extemporal'
    },
    panelClass: 'custom-dialog-container',
  });

  dialogNewgroup.afterClosed().subscribe(() => {
    this.getExteporal(this.idState, this.pageSize, this.page);
  });

}

/**
 * @author Javier Castañeda
 * @createdate 2021-04-29
 * Modal de justificación rechazada
 */
viewJustification2(element?): void {
  const dialogNewgroup = this.dialog.open(VacationsViewComponent, {
    width: '60%',
    disableClose: true,
    data: {
      type: 'crear',
      title: 'Juan Manuel Gonzales',
      data: element
    },
    panelClass: 'custom-dialog-container',
  });

  dialogNewgroup.afterClosed().subscribe(() => {
    this.getExteporal(this.idState, this.pageSize, this.page);
  });

}

/**
 * @author Javier Castañeda
 * @createdate 2021-10-22
 * Modal de reversion de vacaciones
 */
showModalToReverseVacation(element): void {
  const dialogReversion = this.dialog.open(ReverseVacationsComponent, {
    width: '60%',
    disableClose: true,
    data: {
      type: 'edit',
      title: 'Proceso de reversion',
      data: element
    },
    panelClass: 'custom-dialog-container',
  });

  dialogReversion.afterClosed().subscribe(() => {
    this.getExteporal(this.idState, this.pageSize, this.page);
  });

}

/**
 * @author Javier Castañeda
 * @createdate 2021-04-29
 * Metodo para capturar el documento a filtrar
 */
onBlurMethod(e) {
  this.resetPaginator(1);
  this.document = e;
  this.getExteporal(this.idState, this.pageSize, this.page);
}

/**
 * @author Javier Castañeda
 * @createdate 2021-04-29
 * Metodo para capturar el rango de fechas a filtrar
 */
dateRangeChange() {
  this.resetPaginator(1);
  const date1: Date = this.reportForm.controls.start.value;
  const date2: Date = this.reportForm.controls.end.value;
  const dateStart: string | null = new DatePipe('en-US').transform(date1, 'yyyy-MM-dd');
  const dateEnd: string | null = new DatePipe('en-US').transform(date2, 'yyyy-MM-dd');
  this.startDate = dateStart;
  this.endDate = dateEnd;
  this.getExteporal(this.idState, this.pageSize, this.page);
}

/**
 * @author Javier Castañeda
 * @createdate 2021-04-29
 * Metodo para filtrar fecha, campaña y rango de fechas
 */
getExteporal(state, pageSi, page, exportData: boolean = false) {
  this.vacation.getVacationsExtemporal({
    state,
    idTipDoc: this.idTypeDoc,
    document: this.document,
    campaign_id: this.idCampaing,
    startDate: this.startDate,
    endDate: this.endDate,
    forBossView: 1,
    exportData
  }, pageSi, page).subscribe(resp => {
    if (!exportData) {
      this.dataSource = resp.data.data;
      this.length = resp.data.total;
    } else {
      importedSaveAs(resp, 'reporte_vacaciones_jefe.xlsx');
    }
  });
}

resetPaginator(numPaginator): void {
  if (numPaginator === 2) {
    this.length = 0;
    this.pageSize = 5;
    this.page = 1;
    this.paginator.firstPage();
  }
}
}
