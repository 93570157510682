<button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<form mat-dialog-content class="containerDialog" [formGroup]="questionForm">
    <div class="row">
        <h2 class="col-11">{{config.title}}</h2>
        <button mat-icon-button class="col-1" *ngIf="!datos.edit" (click)="btnEdit()">
            <mat-icon style="font-size: 16px;"><i class="fi-rr-edit"></i></mat-icon>
        </button>
        <mat-divider class="mb-4"></mat-divider>
    </div>
    <mat-form-field appearance="fill">
        <mat-label>{{config.questionType}}</mat-label>
        <mat-select #select formControlName="question_type_id">
            <mat-option *ngFor="let tipo of tiposPreguntas" [value]="tipo.id">{{tipo.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{config.question}}</mat-label>
        <textarea  matInput formControlName="question" cdkFocusInitial></textarea>
    </mat-form-field>
    <div *ngIf="select.value == 2">
        <div formArrayName="multiple" *ngFor="let opcion of getOptions.controls; let i = index">
            <div [formGroupName]="i">
                <mat-form-field appearance="fill">
                    <mat-label>{{config.option}} {{i+1}}</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon matSuffix *ngIf="i>1" [hidden]="!datos.edit" (click)="removeOption(i,opcion.value.id)"><i class="fi-rr-trash"></i></mat-icon>
                </mat-form-field>
            </div>
        </div>
        <button class="btn-agregar" [hidden]="!datos.edit" (click)="addOption()"> {{config.addOption}} <i class="fi-rr-add"></i></button>
    </div>
    <div *ngIf="select.value == '3'" style="margin-bottom: 3em;">
        <mat-radio-group aria-label="Lineal" formControlName="question_type_item_id">
                <mat-radio-button *ngFor="let opcion of items_tipo_pregunta" [value]="opcion.id">{{config.scale}} {{opcion.name}}</mat-radio-button>
        </mat-radio-group>
    </div>
    <div *ngIf="select.value == '4'" style="margin-bottom: 3em;">
        <div formArrayName="multiple" *ngFor="let opcion of getOptions.controls; let i = index">
            <div [formGroupName]="i">
                <mat-form-field appearance="fill">
                    <mat-label>{{config.option}} {{i+1}}</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon matSuffix *ngIf="i>1" [hidden]="!datos.edit" (click)="removeOption(i,opcion.value.id)"><i class="fi-rr-trash"></i></mat-icon>
                </mat-form-field>
            </div>
        </div>
        <button class="btn-agregar" [hidden]="!datos.edit" (click)="addOption()">{{config.scale}} <i class="fi-rr-add"></i></button>
    </div>
    <mat-checkbox formControlName="requerida" class="mt-3">{{config.answerMandatory}}</mat-checkbox>
    <div class="btns-dialog"  *ngIf="datos.edit">
        <button mat-stroked-button (click)="questionRef.close()">{{config.btnCancel}}</button>
        <button mat-raised-button color="primary" (click)="updateQuestion()">{{config.btnSave}}</button>
    </div>
</form>
