<div class="main-container">
    <h1>Salas de Assessments</h1>
    <p>Bienvenido a las salas de Assessments, aquí podrás evaluar los candidatos por competecias.</p>
    <p *ngIf="!showRequests">No tienes solicitudes disponibles</p>
    <div class="table" *ngIf="showRequests">

        <table mat-table [dataSource]="dataSource" matSort >
    
            <!-- View -->
            <ng-container matColumnDef="actions">
                <th class="start" mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button (click)="viewDetails(row.id)" matTooltip="Ver sala">
                        <span  class="fi-rr-eye"></span></button>
                </td>
            </ng-container>
    
            <!-- Code -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Nombre Sala</th>
                <td mat-cell *matCellDef="let row"> {{row.name}} </td>
            </ng-container>
    
            <!-- Campaign Column -->
            <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Duración (días) </th>
                <td mat-cell *matCellDef="let row"> {{row.duration}} </td>
            </ng-container>
    
            <!-- Position Column -->
            <ng-container matColumnDef="creator">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Creador </th>
                <td mat-cell *matCellDef="let row"> {{row.creator}} </td>
            </ng-container>

            <ng-container matColumnDef="end">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Finalización</th>
                <td mat-cell *matCellDef="let row"> {{row.end | date}} </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="7">No hay datos que coincidan</td>
            </tr>
        </table>
    
        <mat-paginator  [length]="length" [pageSize]="pageSize" (page)="getPaginatorData($event)" showFirstLastButtons></mat-paginator>
    
    </div>
    
</div>
