import { NoveltiesCodesComponent } from './../novelties-codes/novelties-codes.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { delay } from 'rxjs/operators';

// FullCalendar options
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import esLocale from '@fullcalendar/core/locales/es';
// MomentJS
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';

import { NoveltiesService } from '../../../../services/novelties.service';
import { AuthService } from '../../../../../../core/services/rest/auth.service';

import { NoveltiesResponseComponent } from '../novelties-response/novelties-response.component';
import { CreateNoveltiesComponent } from '../create-novelties/create-novelties.component';
import { VacationsViewComponent } from '../../../vacations/page/vacations-view/vacations-view.component';
import { InactiveDatesService } from '../../../../services/rest/inactive-dates.service';
import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import Swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DisabilitiesViewComponent } from '../../../disabilities/pages/disabilities-view/disabilities-view.component';
import { PermitViewComponent } from '../../../../../juridica/modules/permit/pages/permit-view/permit-view.component';
import { SuspensionBossViewComponent } from '../../../../../juridica/modules/suspension-boss/pages/suspension-boss-view/suspension-boss-view.component';
import { ResumNominaComponent } from '../resum-nomina/resum-nomina.component';


@Component({
  selector: 'app-novelties-list',
  templateUrl: './novelties-list.component.html',
  styleUrls: [ './novelties-list.component.sass' ]
})

export class NoveltiesListComponent implements OnInit {
  public innerWidth: number;
  public negativeNovelties: number;
  public currentDate: string;
  public noveltyTypes: any[] = [];
  public commissions: any[] = [];
  public inactivesInCurrentYear: any[] = [];
  public calendarOptions: CalendarOptions = {};
  public durationInSeconds = 10;
  public events = [];
  @ViewChild('calendar') calendar: FullCalendarComponent;
  public salary: any;
  public noveltiesCount: any;
  public commissionsCount: any;
  public Month: any = '';
  public Year: any = '';

  constructor(public dialog: MatDialog,
              private noveltiesService: NoveltiesService,
              private alertService: AlertsService,
              private inactiveDatesService: InactiveDatesService,
              private authService: AuthService,
              private snackBar: MatSnackBar) {
    this.currentDate = moment().locale('es').format('D [de] MMMM, YYYY');
    this.negativeNovelties = 0;
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.initCalendar();
    this.getPayrollNovelties();
    this.getNoveltyTypes();

    this.inactiveDatesService.getInactiveInCurrentYear()
      .subscribe(resp => {
        this.inactivesInCurrentYear = resp.data;
      });

    const user = this.authService.getUser();
    // Mensaje sin asignación de horarios
    if (user.schedule_start == null) {
      let str = 'Nombre: ' + user.rrhh.name + '\n' +
        'número de idenficación: ' + user.rrhh.ID_number + '\n' +
        'Campaña: ' + user.rrhh.campaign;
      Swal.fire({
        icon: 'error',
        title: 'Sin asignación de horarios',
        html: '<pre style="font-family: Poppins">' + str + '</pre>',
        confirmButtonText: `Enviar notificación`,
        confirmButtonColor: '#2CABBC',
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.noveltiesService.sendEmail().subscribe(resp => {
            this.snackBar.open(resp.data, 'cerrar', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: this.durationInSeconds * 1000,
            });
          }, error => {
            this.snackBar.open('No fue posible enviar la solicitud para cargue de mallas', 'cerrar', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: this.durationInSeconds * 1000,
            });
          });
        }
      });
    }

  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Obtiene las novedades del usuario actual
   */
  getPayrollNovelties(): void {
    this.noveltiesService.getPayrollNoveltiesForCurrentUser(this.Month, this.Year)
      .pipe(
        delay(1000)
      )
      .subscribe(resp => {
        this.events = resp.data.events;
        this.negativeNovelties = resp.data.negativeNovelties;

        // configure calendar options
        this.calendarOptions = {
          initialView: 'dayGridMonth',
          locales: [ esLocale ],
          locale: 'es',
          headerToolbar: {
            left: '',
            center: 'title',
            right: 'prev,today,next'
          },
          titleFormat: {
            month: 'long',
            year: 'numeric'
          },
          customButtons: {
            prev: {
              click: this.goPrev.bind(this),
            },
            today: {
              text: 'Hoy',
              click: this.goToday.bind(this),
            },
            next: {
              click: this.goNext.bind(this),
            },
          },
          showNonCurrentDates: false,
          //eventClick: this.eventClick.bind(this),
          //dateClick: this.dateClick.bind(this),
          events: resp.data.events,
          handleWindowResize: true,
          windowResizeDelay: 200
        };
      });
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Abre el modal para ver el detalle de la novedad seleccionada
   */
  eventClick(arg): void {
    const { extraData } = arg.event._def.extendedProps;
    extraData.currentDate = this.currentDate;
    extraData.idHoliday = extraData.id;
    extraData.idNovelty = extraData.id;
    extraData.name = this.authService.getUser().rrhh.name;
    extraData.id_number = this.authService.getUser().rrhh.ID_number;
    // si es asistencia o asistencia dominical no mostrar nada
    if (extraData.novelty_type.key == 2 || extraData.novelty_type.key == 5) {
      this.alertService.alertSuccess('Asistencia', '¡El sistema reportó tu asistencia!');
      return;
    }

    // ausencias ahora se justificaran mediante incapacidades
    if (extraData.novelty_type.key == 45) {
      this.alertService.alertWarningV2('Ausencia reportada', 'Recuerda que si deseas justificar esta o más ausencias debes hacerlo mediante el registro de una <b>Incapacidad, Permiso o Descanso compensatorio</b>.');
      return;
    }

    const noveltyKey = Number(extraData.novelty_type.key);
    switch (noveltyKey) {
      // incapacidad
      case 27:
        const dialogDisability = this.dialog.open(DisabilitiesViewComponent, {
          width: '70%',
          disableClose: true,
          data: {
            type: 'incapacidades',
            title: 'Detalle Incapacidad',
            data: extraData
          },
          panelClass: 'custom-dialog-container',
        });

        dialogDisability.afterClosed().subscribe(() => {
          this.getPayrollNovelties();
          this.getNoveltyTypes();
        });
        break;
      // permiso
      case 40:
      case 41:
      case 42:
        const dialogPermit = this.dialog.open(PermitViewComponent, {
          width: '60%',
          disableClose: true,
          data: {
            type: 'permiso',
            title: 'Detalle Permiso',
            data: extraData
          },
          panelClass: 'custom-dialog-container',
        });

        dialogPermit.afterClosed().subscribe(() => {
          this.getPayrollNovelties();
          this.getNoveltyTypes();
        });
        break;
      // vacaciones y vacaciones pagas
      case 90:
      case 91:
        const dialogVacation = this.dialog.open(VacationsViewComponent, {
          width: '60%',
          disableClose: true,
          data: {
            type: 'vacaciones',
            title: 'Detalle solicitud de vacaciones',
            data: extraData
          },
          panelClass: 'custom-dialog-container',
        });

        dialogVacation.afterClosed().subscribe(() => {
          this.getPayrollNovelties();
          this.getNoveltyTypes();
        });
        break;
      case 3:
        extraData.idProcess = extraData.extra.processInJuridica;
        const dialogNewComponent = this.dialog.open(SuspensionBossViewComponent, {
          width: '70%',
          disableClose: false,
          data: {
            type: 'ver',
            title: 'Detalle solicitud de suspension',
            data: extraData
          },
          panelClass: 'custom-dialog-container',
        });

        dialogNewComponent.afterClosed().subscribe(() => {
          this.getPayrollNovelties();
          this.getNoveltyTypes();
        });
        break;
      default:
        const dialogDetailNovelty = this.dialog.open(NoveltiesResponseComponent, {
          width: '70%',
          disableClose: true,
          data: {
            type: 'crear',
            title: 'Detalle novedad',
            extraData
          },
          panelClass: 'custom-dialog-container',
        });

        dialogDetailNovelty.afterClosed().subscribe(() => {
          this.getPayrollNovelties();
          this.getNoveltyTypes();
        });
        break;
    }
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Inicializar el calendario
   */
  initCalendar(): void {
    this.calendarOptions = {
      initialView: 'dayGridMonth',
      locales: [ esLocale ],
      showNonCurrentDates: false,
      locale: 'es', // the initial locale. of not specified, uses the first one
      headerToolbar: {
        left: '',
        center: 'title',
        right: 'prev,today,next'
      },
      handleWindowResize: true,
      windowResizeDelay: 200, // milliseconds before a rerender happens
      events: [],
      customButtons: {
        prev: {
          click: this.goPrev.bind(this),
        },
        today: {
          text: 'Hoy',
          click: this.goToday.bind(this),
        },
        next: {
          click: this.goNext.bind(this),
        },
      }
    };
  }

  goNext(element) {
    const calendarApi = this.calendar.getApi();
    calendarApi.next();
    let date = new Date(calendarApi.getDate());
    this.Month = moment(date).locale('es').format('MM');
    this.Year = moment(date).locale('es').format('YYYY');
    this.getPayrollNovelties();
    this.getNoveltyTypes();
  }

  goPrev(element) {
    const calendarApi = this.calendar.getApi();
    calendarApi.prev();
    let date = new Date(calendarApi.getDate());
    this.Month = moment(date).locale('es').format('MM');
    this.Year = moment(date).locale('es').format('YYYY');
    this.getPayrollNovelties();
    this.getNoveltyTypes();
  }

  goToday(element) {
    const calendarApi = this.calendar.getApi();
    calendarApi.today();
    let date = new Date(calendarApi.getDate());
    this.Month = moment(date).locale('es').format('MM');
    this.Year = moment(date).locale('es').format('YYYY');
    this.getPayrollNovelties();
    this.getNoveltyTypes();
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Abre el modal para realizar la creación de una novedad por parte del trabajador
   */
  dateClick(arg): void {
    if (this.inactivesInCurrentYear[arg.dateStr]) {
      this.alertService.alertWarningV2('Día no Hábil', 'El día seleccionado no es hábil, por favor séleccione una fecha diferente');
      return;
    }

    const dialogDetailNovelty = this.dialog.open(CreateNoveltiesComponent, {
      width: '70%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Creación de novedades',
        extraData: arg
      },
      panelClass: 'custom-dialog-container',
    });

    dialogDetailNovelty.afterClosed().subscribe(() => {
      this.getPayrollNovelties();
      this.getNoveltyTypes();
    });
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Obtiene los tipos de novedad con sus subtipos
   */
  getNoveltyTypes(): void {
    this.noveltyTypes = [];
    this.noveltiesService.getSumary(this.Month, this.Year)
      .subscribe(resp => {
        this.commissions = resp.data.commissions;
        this.commissionsCount = resp.data.valueCommissions;
        this.salary = resp.data.salary;
        this.noveltiesCount = resp.data.cantNovelties;
        for (let nType in resp.data.novelties) {
          if (resp.data.novelties.hasOwnProperty(nType)) {
            let type = resp.data.novelties[nType];
            type.key = (type.key < 10) ? `0${ type.key }` : type.key;
            this.noveltyTypes.push(type);
          }
        }
      });
  }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-05-10
   * Modal de justificación
   */
  viewCodes(): void {
    const dialogNewgroup = this.dialog.open(NoveltiesCodesComponent, {
      width: '70%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Códigos novedades de nómina',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewgroup.afterClosed().subscribe(() => {
      this.ngOnInit();
    });

  }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-09-14
   * abrir modal
   */
  modalResum(): void {
    const dialogNewTem = this.dialog.open(ResumNominaComponent, {
      width: '70%',
      disableClose: true,
      panelClass: 'custom-dialog-container',
    });
  }


}
