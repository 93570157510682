import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { MatrizService } from '../../services/matriz.service';
import { ReportsService } from '../../services/reports.service';
import { AuditoriaService } from 'src/app/modules/auditoria/services/auditoria.service';
import { Tools } from '../../utils/tools.utils';

@Component({
  selector: 'app-reporteria',
  templateUrl: './reporteria.component.html',
  styleUrls: ['./reporteria.component.sass']
})
export class ReporteriaComponent implements OnInit {
  //Filtros
  filtroTipo_matriz:string = '';
  filtroFecha = new FormGroup({
    fechaInicial: new FormControl(),
    fechaFinal: new FormControl(),
  });
  filtroNombre:string = '';


  tiposMatrices = [];

  constructor(
    private matrizService:MatrizService,
    private reporService:ReportsService,
  ) { }

  ngOnInit(): void {
    this.getTypeMatrix();
  }

  filtroFechas(){
    if (this.filtroFecha.get('fechaInicial').value != null && this.filtroFecha.get('fechaFinal').value != null) {
      this.filtrar();
    }
  }

  filtrar(){
    
  }

  getReportGeneral(){
    const params = {
      fechaInicial: '',
      fechaFinal: '',
      tipoMatriz: '',
      nombre: '',
    }

    if (this.filtroNombre != '') {
      params.nombre = this.filtroNombre;
    }

    if (this.filtroFecha.get('fechaInicial').value != '' && this.filtroFecha.get('fechaInicial').value != null) {
      params.fechaInicial = moment(this.filtroFecha.get('fechaInicial').value).format('yyyy-MM-DD');
    }
    if (this.filtroFecha.get('fechaFinal').value != '' && this.filtroFecha.get('fechaFinal').value != null) {
      params.fechaFinal = moment(this.filtroFecha.get('fechaFinal').value).format('yyyy-MM-DD');
    }

    if (this.filtroTipo_matriz != '') {
      params.tipoMatriz = this.filtroTipo_matriz;
    }

    this.reporService.getReportGeneral(params).subscribe((resp:any)=>{
      Tools.downloadFile(resp,'General_'+moment(new Date).format('DD_MM_yyy_hh_mm_ss'));
    });

  }

  getTypeMatrix(){
    this.matrizService.getTypeMatrix().subscribe((resp:any)=>{
      this.tiposMatrices = resp;
    })
}

}
