import { Component, OnInit, Inject } from '@angular/core';
import { TrainingGroupsService } from '../../../services/training-groups.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-assign-users',
  templateUrl: './assign-users.component.html',
  styleUrls: ['./assign-users.component.sass']
})
export class AssignUsersComponent implements OnInit {
  analistUsers:any
  analistUser= new FormControl(null,Validators.required)
  constructor(private TrainingGroups:TrainingGroupsService, @Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<AssignUsersComponent>,
  private alert: AlertsService) { }

  ngOnInit(): void {
    this.TrainingGroups.getUsersByRols(`users/role`, `analista-de-usuarios`).subscribe(res=>{
      this.analistUsers = res
    })
    this.analistUser.setValue(this.data.group.analyst_user_id)
  }
  sendAssign(){
    let user={
      analyst_user_id : this.analistUser.value
    }
    this.TrainingGroups.updateGroup(this.data.group.id, user ).subscribe(res=>{
      this.alert.alertSuccess('¡Asignado!', 'Analista de usuario asignado con éxito');
      this.dialogRef.close(true);
    })

  }
}
