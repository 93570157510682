import { element } from 'protractor';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { SchedulesService } from '../../../services/rest/schedules.service';
import { AuthService } from '../../../../../core/services/rest/auth.service';
import * as moment from 'moment';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-edit-schedules',
  templateUrl: './edit-schedules.component.html',
  styleUrls: ['./edit-schedules.component.sass']
})
export class EditSchedulesComponent implements OnInit {
  public uploadFile;
  public nameFile: any = '';
  public filters: any;
  FileData: any = 0;
  btnUpload = true;
  usersArray: any;
  editDay = false;
  editDay2 = false;
  DaysWeek: any = [];
  filterControl = new FormControl();
  jornada: FormGroup;
  selectedWeek: any;
  olData: any;
  length: any = [];
  checked: boolean = false;

  constructor(private alert: AlertsService,
              private scheduleService: SchedulesService,
              private authService: AuthService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<EditSchedulesComponent>) {
  }

  ngOnInit(): void {
    this.jornada = new FormGroup({
      startJ: new FormControl(''),
      endJ: new FormControl(''),
      startA: new FormControl(''),
      endA: new FormControl(''),
      startC: new FormControl(''),
      endC: new FormControl(''),
      start1: new FormControl(''),
      end1: new FormControl(''),
      start2: new FormControl(''),
      end2: new FormControl('')
    });

    this.filters = this.data.filters;
    const array = [];
    if (this.data.data.length > 0) {
      this.data.data.forEach(element => {
        array.push(element.rrhh_id);
      });
      this.usersArray = array;
    } else {
      array.push(this.data.data2.rrhh_id);
      this.usersArray = array;
    }
    const arrayWeek = this.data.week;
    this.DaysWeek.push({ day: arrayWeek.monday, name_day: 'Lunes' });
    this.DaysWeek.push({ day: arrayWeek.tuesday, name_day: 'Martes' });
    this.DaysWeek.push({ day: arrayWeek.wednesday, name_day: 'Miércoles' });
    this.DaysWeek.push({ day: arrayWeek.thursday, name_day: 'Jueves' });
    this.DaysWeek.push({ day: arrayWeek.friday, name_day: 'Viernes' });
    this.DaysWeek.push({ day: arrayWeek.saturday, name_day: 'Sábado' });
    this.DaysWeek.push({ day: arrayWeek.sunday, name_day: 'Domingo' });
    this.filterControl.valueChanges.subscribe(resp => {
      this.selectedWeek = resp;
    });
    let Data = [];
    if (this.data.data.length > 0) {
      Data = [...this.data.data];
      this.length = Data.length;
    } else if (this.data.data2 != []) {
      Data.push(this.data.data2);
      this.length = Data.length;
    }

  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-05-06
   * Metodo carga de archivo
   */
  public onChangeFile(e): void {
    const currentDate = moment().format('YYYY-MM-DD');
    this.nameFile = e.target.files[0].name;
    this.FileData = e.target.files;
    if (this.FileData.length > 0) {
      if (e.target.files[0].type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || e.target.files[0].type == 'application/vnd.ms-excel') {
        this.btnUpload = false;
        const formData = new FormData();
        formData.append('groupId', this.filters.groupId);
        formData.append('userIds', JSON.stringify(this.usersArray));
        formData.append('startWeek', this.filters.startWeek);
        formData.append('endWeek', this.filters.endWeek);
        formData.append('upload', e.target.files[0]);
        this.scheduleService.updateScheduleSelected(formData)
          .subscribe(resp => {
            e.srcElement.value = null;
            this.nameFile = '';
            this.btnUpload = true;
            if (resp.data.possibleErrors.length === 0) {
              this.alert.alertSuccess('¡Archivo cargado!', `
              <h4><b>Nota:</b></h4>
              <p>
              <b>Archivo subido por:</b> ${ this.authService.getUser().rrhh.name ?? 'NA' } <br>
              <b>Fecha: </b> ${ currentDate }
              </p>
            `);
            } else {
              let buildMessages = '';
              for (const property in resp.data.possibleErrors) {
                if (resp.data.possibleErrors.hasOwnProperty(property)) {
                  buildMessages += resp.data.possibleErrors[property];
                }
              }
              this.alert.alertWarning('Proceso realizado', `
              <h4><b>Nota:</b></h4>
              <p>
              <b>Archivo subido por:</b> ${ this.authService.getUser().rrhh.name ?? 'NA' } <br>
              <b>Fecha: </b> ${ currentDate } <br><br>
              <b>Inconvenientes presentados: </b><br> ${ buildMessages }
              </p>
            `);
            }
          }, error => {
            e.srcElement.value = null;
            this.nameFile = '';
            this.btnUpload = true;
            this.alert.alertError('Error', 'Ocurrio un error al intentar actualizar masivamente');
          });
      } else {
        this.btnUpload = true;
        this.alert.alertError('Error', 'La extensión del archivo no es valida, solo se permiten archivos excel');
      }
    } else {
      this.btnUpload = false;
    }
  }

  /**
   * Metodo que obtiene la plantilla con información a actualizar
   * @author Daniel Dominguez 
   */
  getScheduleFileToEdit(): void {
    this.filters.userIds = this.usersArray;
    this.scheduleService.getScheduleFileToEdit(this.filters)
      .subscribe(resp => importedSaveAs(resp, 'edicion_horarios.xlsx'));
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-05-19
   * Metodo de modificar un dia de la semana
   */
  saveDays(): void {
    if (!this.filterControl.value) {
      return;
    }
    const jornadaToEdit = {
      groupId: this.filters.groupId,
      startWeek: this.filters.startWeek,
      endWeek: this.filters.endWeek,
      userIds: this.usersArray,
      dateSelected: this.selectedWeek,
      workday: [{
        hourStart: this.jornada.controls.startJ.value,
        hourEnd: this.jornada.controls.endJ.value
      }],
      break: [{
        hourStart: this.jornada.controls.start1.value,
        hourEnd: this.jornada.controls.end1.value
      }, {
        hourStart: this.jornada.controls.start2.value,
        hourEnd: this.jornada.controls.end2.value
      }],
      lunch: [{
        hourStart: this.jornada.controls.startA.value,
        hourEnd: this.jornada.controls.endA.value
      }],
      training: [{
        hourStart: this.jornada.controls.startC.value,
        hourEnd: this.jornada.controls.endC.value
      }]
    };

    this.scheduleService.updateScheduleDateSelected(jornadaToEdit)
      .subscribe(resp => {
        this.alert.alertSuccess('Actualización realizada!', 'proceso realizado correctamente');
        // this.dialogRef.close('');
      });


  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-05-19
   * Metodo de para mostrar la vista de modificar turno
   */
  showEdit(): void {
    if (this.editDay == false) {
      this.editDay = true;
    } else {
      this.editDay = false;
    }
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-05-19
   * Metodo de para mostrar la vista de modificar turno por dia
   */
  showEdit2(): void {
    if (this.editDay2 == false) {
      this.editDay2 = true;
    } else {
      this.editDay2 = false;
    }
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-07-28
   * Metodo de para limpiar campos de hora
   */
  clearFills(e): void {
    if (e) {
      this.jornada.disable();
      this.checked = true;
    } else {
      this.jornada.enable();
      this.checked = false;
    }

    if (this.olData) {
      Object.keys(this.olData.schedules).forEach(key => {
        if (this.olData.descanso) {
          this.jornada.controls[key].setValue((this.checked) ? '00:00:00' : '');
        } else {
          this.jornada.controls[key].setValue((this.checked) ? '00:00:00' : this.olData.schedules[key] ?? '');
        }
      });
    } else {
      // cuando son mas de 1 un usuario
      Object.keys(this.jornada.controls).forEach(key => {
        this.jornada.controls[key].setValue((this.checked) ? '00:00:00' : '');
      });
    }
  }

  /**
   * Busca la configuracion que pudo haber cargado en un dia especifico
   * @author Andres Buitrago
   * @created 2021-08-05
   */
  searchPossibleSchedule(): void {
    if (this.usersArray.length > 1) return;

    this.checked = false;
    this.jornada.enable();
    // limpiar campos
    Object.keys(this.jornada.controls).forEach(key => {
      this.jornada.controls[key].setValue('');
    });

    this.scheduleService.getScheduleToEdit(this.usersArray[0], this.selectedWeek)
      .subscribe(resp => {
        this.olData = resp.data;
        if (resp.data.descanso) {
          this.checked = true;
          this.clearFills(true);
        } else {
          Object.keys(resp.data.schedules).forEach(key => {
            this.jornada.controls[key].setValue(resp.data.schedules[key] ?? '');
          });
        }
      }, error => {
        this.olData = {descanso: false, schedules: []};
      });
  }


}
