import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxText'
})
export class MaxTextPipe implements PipeTransform {

  transform(str:string, limit:number, endDots?:boolean): string {

    if(str.length >= limit){
      let stringFormat = str.substring(0, (limit - 3));
      if(endDots){
        return stringFormat + "...";
      }else{
        return stringFormat 
      }
    }else{

      return str;
    }
  }

}
