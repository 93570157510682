import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ClientesService } from './services/rest/clientes.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';

@Component({
  selector: 'app-crm-cial',
  templateUrl: './crm-cial.component.html',
  styleUrls: ['./crm-cial.component.sass']
})
export class CrmCialComponent implements OnInit {
  userName: string;
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.userName = this.authService.getUser().rrhh.name;
  }

}
