<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<h3 >Tipos de recurso</h3>
<mat-divider></mat-divider>
<mat-dialog-content >

    <div class="details">
        <div class="resource-type-btns">
            <p><strong> Lista de tipos de recurso</strong></p>
            <button  *ngIf="!createType" mat-button
                    (click)="createType = !createType">Crear nuevo tipo &nbsp;<span class="fi-rr-add"></span></button>
        </div>
        <form [formGroup]="typeForm" *ngIf="createType" class="resource-type-form">
                <mat-form-field appearance="fill" fxFlex *ngIf="!states">
                    <mat-label>Nombre</mat-label>
                    <input matInput formControlName="name">
                    <mat-error *ngIf="typeForm.get('name').hasError('required')">Este campo es obligatorio</mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex *ngIf="!states">
                    <mat-label>Identificador</mat-label>
                    <input matInput formControlName="key">
                    <mat-hint align="start">No debe llevar puntuación ni espacios</mat-hint>
                    <mat-error *ngIf="typeForm.get('key').hasError('required')">Este campo es obligatorio</mat-error>
                    <mat-error *ngIf="typeForm.get('key').hasError('pattern')">No se admiten espacios o puntuación</mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex *ngIf="!states">
                    <mat-label>Tiempo de reserva</mat-label>
                     <input matInput type="number" formControlName="booking_time">
                     <span matSuffix>Mins &nbsp;</span>
                    <mat-error >{{getBookingTimeError()}}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" *ngIf="crmForm" >
                  <mat-label>Formulario CRM1</mat-label>
                  <mat-select  formControlName="id_form_cr" (selectionChange)="changeStateForms('crm')" >
                    <mat-option value="" >Ninguno</mat-option>
                    <mat-option *ngFor="let form of crmForm"  [value]="form['id']">
                      {{form["name_form"]}} </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" *ngIf="crmForm" >
                    <mat-label>Formulario CRM2</mat-label>
                    <mat-select  formControlName="id_form_crm_two" (selectionChange)="changeStateForms('crm2')" >
                      <mat-option value="" >Ninguno</mat-option>
                      <mat-option *ngFor="let formTwo of crmTwoForm"  [value]="formTwo['id']">
                        {{formTwo["name_form"]}} </mat-option>
                    </mat-select>
                  </mat-form-field>

                <div class="resource-type-form_btns">
                    <button mat-stroked-button type="button" (click)="cancelForm('typeForm','createType')">Cancelar</button>
                    <button mat-flat-button color="primary" (click)="saveResourceType()">{{action}}</button>
                </div>

        </form>
        <mat-divider></mat-divider>
        <div class="resource-type-list">
            <p *ngIf="resourceTypes.length == 0" >No existen tipos de recurso</p>
            <mat-list>
                <mat-list-item class="resource-type-item" *ngFor="let type of resourceTypes">
                        <div class="resource-type-info">
                            <br>
                            <div matLine><b>{{type.name}}</b> </div>
                            <p mat-line matSubheader>
                                <span><strong>Tiempo de reserva:</strong>&nbsp;
                                {{type.booking_time}} &nbsp; Minutos</span>
                            </p>
                        </div>

                        <div class="resource-type-action">
                            <button mat-icon-button color="primary" (click)="editTypeInput(type)"><span class="fi fi-rr-edit"></span></button>
                        </div>

                    <mat-divider></mat-divider>
                </mat-list-item>
            </mat-list>

        </div>
    </div>
</mat-dialog-content>
