import { Component, Input, OnInit, Output, ViewChild, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'app-element-selector',
  templateUrl: './element-selector.component.html',
  styleUrls: ['./element-selector.component.sass']
})
export class ElementSelectorComponent implements OnInit, OnChanges {
  // Inputs que se reciben del componente padre
  // Titulos
  @Input() first_list_title: string = "Disponibles";
  @Input() second_list_title: string = "Seleccionados";
  // Arreglos que almacenan las opciones de las listas
  @Input() available_elements: any[] = [
    { id: 1, name: "a" },
    { id: 2, name: "b" },
    { id: 3, name: "c" },
    { id: 4, name: "d" },
    { id: 5, name: "e" },
    { id: 6, name: "f" }
  ];
  @Input() selected_elements: any[] = [];
  // Outputs que se envian al componente padre
  @Output() updated_available_elements: EventEmitter<any[]> = new EventEmitter();
  @Output() updated_selected_elements: EventEmitter<any[]> = new EventEmitter();
  @Output() confirmation_foreign_element: EventEmitter<boolean> = new EventEmitter();
  // Referencias a la lista de los objetos que se pueden seleccionar
  @ViewChild('selection_list') selection_list: MatSelectionList;
  @ViewChild('selected_list') selected_list: MatSelectionList;

  // Almacenan los valores originales de las listas
  original_available_elements: any[] = [];
  original_selected_elements: any[] = [];
  // Se guardan los filtros ingresados para mantenerlos
  availableFilter: string = "";
  selectedFilter: string = "";
  // Checkbox seleccionar todo
  checked_all_available = false;
  checked_all_selected = false;
  //Elemento añadido desde otro medio
  @Input() foreign_element:any = {}

  constructor() { }

  ngOnInit(): void {
    this.original_available_elements = [...this.available_elements];
    this.original_selected_elements = [...this.selected_elements];

  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.foreign_element){
      if(Object.keys(this.foreign_element).length > 0){
        this.moveForeignToSelected()
      }
    }
  }

  /**
   * @author Juan Carlos Alonso
   * @createdate 2026-05-31
   * Método que mueve los elementos de la lista 1 a la lista 2
  */
  moveToSelected(): void {
    const selectedOptions = this.selection_list.selectedOptions.selected.map(option => option.value);

    selectedOptions.forEach(option => {
      if (!this.selected_elements.find(element => element.rrhh_id === option.rrhh_id)) {
        this.selected_elements.push(option);
      }
      // Eliminar el elemento seleccionado de available_elements
      this.available_elements = this.available_elements.filter(element => element !== option);
      option.isHidden = false; // Actualizar el estado isHidden
    });

    this.selection_list.selectedOptions.clear();
    this.availableFilter = "";

    this.resultAvailableElements();
    this.resultSelectedElements();
  }

  /**
   * @author Juan Carlos Alonso
   * @createdate 2026-05-31
   * Método que mueve los elementos de la lista 2 a la lista 1
  */
  moveToAvailable(): void {
    const selectedOptions = this.selected_list.selectedOptions.selected.map(option => option.value);

    selectedOptions.forEach(option => {
      if (!this.available_elements.find(element => element.rrhh_id === option.rrhh_id)) {
        this.available_elements.push(option);
      }
      // Eliminar el elemento seleccionado de selected_elements
      this.selected_elements = this.selected_elements.filter(element => element !== option);
      option.isHidden = false; // Actualizar el estado isHidden
    });

    this.selected_list.selectedOptions.clear();
    this.selectedFilter = "";

    this.resultAvailableElements();
    this.resultSelectedElements();
  }

   /**
   * @author Juan Carlos Alonso
   * @createdate 2026-05-31
   * Método que mueve un elemento seleccionado alternamente a la lista 2
  */
  moveForeignToSelected(){
    if (!this.selected_elements.find(element => element.rrhh_id === this.foreign_element.rrhh_id)) {
      this.selected_elements.push(this.foreign_element);
    }
    this.addedForeignElement()
    this.resultSelectedElements()
  }

  /**
   * @author Juan Carlos Alonso
   * @createdate 2026-05-31
   * Método que selecciona todos los elementos de la lista 1
  */
  selectAllAvailable(): void {
    if (this.checked_all_available) {
      this.selection_list.selectAll();
    } else {
      this.selection_list.deselectAll();
    }
  }

  /**
   * @author Juan Carlos Alonso
   * @createdate 2026-05-31
   * Método que selecciona todos los elementos de la lista 2
  */
  selectAllSelected(): void {
    if (this.checked_all_selected) {
      this.selected_list.selectAll();
    } else {
      this.selected_list.deselectAll();
    }
  }

  /**
   * @author Juan Carlos Alonso
   * @createdate 2026-05-31
   * Método que filtra los elementos de la lista 1
   * @param filterValue parametro de filtración
  */
  filterAvailableElements(filterValue: string): void {
    this.availableFilter = filterValue.trim().toLowerCase();
    this.available_elements.forEach(element => {
      if (element.name.toLowerCase().includes(this.availableFilter)) {
        element.isHidden = false;
      } else {
        element.isHidden = true;
      }
    });
  }

  /**
   * @author Juan Carlos Alonso
   * @createdate 2026-05-31
   * Método que filtra los elementos de la lista 2
   * @param filterValue parametro de filtración
  */
  filterSelectedElements(filterValue: string): void {
    this.selectedFilter = filterValue.trim().toLowerCase();
    this.selected_elements.forEach(element => {
      if (element.name.toLowerCase().includes(this.selectedFilter)) {
        element.isHidden = false;
      } else {
        element.isHidden = true;
      }
    });
  }

  /**
   * @author Juan Carlos Alonso
   * @createdate 2026-05-31
   * Método que emite al componente padre el valor de la lista 1 final
  */
  resultAvailableElements() {
    this.updated_available_elements.emit(this.available_elements);
  }

  /**
   * @author Juan Carlos Alonso
   * @createdate 2026-05-31
   * Método que emite al componente padre el valor de la lista 2 final
  */
  resultSelectedElements() {
    this.updated_selected_elements.emit(this.selected_elements);
  }

    /**
   * @author Juan Carlos Alonso
   * @createdate 2026-05-31
   * Método que emite al componente padre una confirmación de un elemento alerno agregado
  */
  addedForeignElement(){
    this.confirmation_foreign_element.emit(true)
  }
}
