<div class="row novelty-row-padding" style="overflow-x: hidden;">

  <h2 mat-dialog-title>
    <span class="gray-color" style="margin-left: -9px"></span>

      <i class="fi-rr-cross-small" style="float: right; font-size: 24px;" mat-icon-button mat-dialog-close></i>

    <div class="title content" style="width: 100%; margin-left: 93px;">
      <img src="./assets/images/novelties.png" alt="noveltyIcon" class="icon">
      <span>
        <h1 style="color: #353535;">{{data.data?.name || data.title}}</h1>
        <h5 style="font-size: 16px; font-family: 'Poppins', Regular!important; color: #A9A9A9;">{{data.data?.id_number}}</h5>
      </span>
    </div>
  </h2>
  
  <mat-dialog-content style="padding: 3%; overflow-x: hidden;" class="mat-typography">

    <div class="row">
      <div class="col-12">
        <p class="p-color" style="padding-bottom: 30px;"> <strong>Fecha de creación: </strong> {{dataInfo?.fechaCreacion | date:'dd/MM/yyyy' }}</p>
      </div>
      <div class="col-6" style="padding-top: 10px;">
        <div class="col-12" style="margin-bottom: 32px;">
          <h3 style="margin-top: -40px;">
            <span class="gray-color" style="margin-left: -9px; color: #353535;">{{ (dataInfo?.novelty_date_start | date:'EEEE' | titlecase) + ' ' + (dataInfo?.novelty_date_start | date:'dd')+ ' de ' + (dataInfo?.novelty_date_start | date:'MMMM') }}</span>
          </h3>
          <span class="badge" [style.background]="dataInfo?.noveltyTypeToPrint?.backgroundColor">{{
            dataInfo?.novelty_type?.key }}</span> {{ dataInfo?.noveltyTypeToPrint?.title }}
        </div>
        <div class="col-12">
            <h3>
              <span class="title-marg" style="margin-left: -9px; color: #353535;">Permiso</span>
            </h3>
            <p class="p-color"> <strong>Fecha inicio: </strong> {{ dataInfo?.novelty_date_start | date:'dd/MM/yyyy'  }}</p>
            <p class="p-color"> <strong>Fecha fin: </strong> {{dataInfo?.novelty_date_end | date:'dd/MM/yyyy' }}</p>
            <br>
          <h4 class="title-marg" style="color: #353535; font-size: 18px !important;"><b>Justificación del trabajador</b></h4>
          <p class="p-color">{{ dataInfo?.justificacionTrabajador}}</p>
          <div style="margin-top: 32px;">
            <p style="color: #2CABBC; margin-top: 5px; margin-left: 93px;" *ngFor="let item of files">
               <span class="fi-rr-download" (click)="file.click()">
                <a #file [href]="item?.fullPath" target="_blank"></a> {{item?.name}}
               </span>
            </p>
          </div>
        </div>
      </div>


      <div class="col-6 text-left">
        <h4 class="form-title">
         <strong style="color: #353535; font-size: 18px !important;"><b>Gestión</b></strong>
        </h4>
        <form style="margin-left: 60px;" [formGroup]="noveltiesForm">

          <mat-form-field appearance="fill" style="width: 100%; margin-bottom: 13.2px;">
            <mat-label>Estado</mat-label>
            <mat-select formControlName="state" (selectionChange)="changeState($event)">
              <mat-option *ngFor="let item of options" [value]="item.id">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Observaciones</mat-label>
            <textarea matInput formControlName="response"></textarea>
          </mat-form-field>

          <button mat-raised-button style="width: 100%; margin-top: 30px; height: 48px;" color="primary" [disabled]="noveltiesForm.invalid" (click)="updateState()">
            Guardar
          </button>
        </form>
      </div>
    </div>
  </mat-dialog-content>

</div>
