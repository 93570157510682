import { Component, OnInit } from "@angular/core";
import Swal from "sweetalert2";
import { AuthService } from "../../core/services/rest/auth.service";
import { NoveltiesService } from "./services/novelties.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-nomina",
  templateUrl: "./nomina.component.html",
  styleUrls: ["./nomina.component.sass"],
})
export class NominaComponent implements OnInit {
  public durationInSeconds = 10;
  nameUser = "";

  constructor(
    private snackBar: MatSnackBar,
    private noveltiesService: NoveltiesService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    const user = this.authService.getUser();
    this.nameUser = user.rrhh.name;
    if (user.schedule_start == null) {
      let str =
        "Nombre: " +
        user.rrhh.name +
        "\n" +
        "número de idenficación: " +
        user.rrhh.ID_number +
        "\n" +
        "Campaña: " +
        user.rrhh.campaign;
      Swal.fire({
        icon: "error",
        title: "Sin asignación de horarios",
        html: '<pre style="font-family: Poppins">' + str + "</pre>",
        confirmButtonText: `Enviar notificación`,
        confirmButtonColor: "#2CABBC",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.noveltiesService.sendEmail().subscribe(
            (resp) => {
              this.snackBar.open(resp.data, "cerrar", {
                horizontalPosition: "center",
                verticalPosition: "top",
                duration: this.durationInSeconds * 1000,
              });
            },
            (error) => {
              this.snackBar.open(
                "No fue posible enviar la solicitud para cargue de mallas",
                "cerrar",
                {
                  horizontalPosition: "center",
                  verticalPosition: "top",
                  duration: this.durationInSeconds * 1000,
                }
              );
            }
          );
        }
      });
    }
  }
}
