import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ControlService } from 'src/app/modules/calidad/services/control.service';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
  selector: 'app-detalle-grupo',
  templateUrl: './detalle-grupo.component.html',
  styleUrls: ['./detalle-grupo.component.sass']
})
export class DetalleGrupoComponent implements OnInit {
  private uuid = 'c8766f6a-9306-11ee-b9d1-0242ac120002';
  public config: any;

  titulo:string = '';
  supervisor:string = '';
  creacion;
  id_grupo;
  id_matriz;
  constructor(
    private controlService:ControlService,
    private aRoute:ActivatedRoute,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
    this.aRoute.paramMap.subscribe(params=>{
      this.id_grupo = params.get('id');
    });
    this.getGrupoById();
  }

  getGrupoById(){
    this.controlService.getGrupoById(this.id_grupo).subscribe((resp:any)=>{
      this.titulo = resp[0].nombre;
      this.supervisor = resp[0].nombre_supervisor;
      this.creacion = resp[0].created_at;
      this.id_matriz = resp[0].matriz_id;
    });
  }

}
