import {Component, Input, OnInit} from '@angular/core';
import {NotesService} from '../../../services/rest/notes.service';
import {MatDialog} from '@angular/material/dialog';
import {UpdateNotesComponent} from '../update-notes/update-notes.component';
import {AlertsService} from '../../../../../shared/alerts/alerts.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';

@Component({
  selector: 'app-by-step',
  templateUrl: './by-step.component.html',
  styleUrls: ['./by-step.component.sass']
})
export class ByStepComponent implements OnInit {

  @Input() notes;
  @Input() rolUser;
  @Input() oportunity;
  @Input() step;
  @Input() typeView;
  user: any;

  constructor(
    private notesService: NotesService,
    public dialog: MatDialog,
    private alertsService: AlertsService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.decryptToken().rrhh_id;
  }
  /**
   * @author Gabriel Garcia
   * @createddate 2022-10-29 
   * Metodo para actualizar nota por id
   * @param {number} id id de la nota
   * @returns Json
   */
  update(id) {
    this.notesService.show(id).subscribe(response => {
      const dialogRef = this.dialog.open(UpdateNotesComponent, {
        width: '60%',
        disableClose: true,
        data: {
          value: response['data']
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getNotes();
      });
    });
  }
  /**
   * @author Gabriel Garcia
   * @createddate 2022-10-29 
   * Metodo para eliminar una nota por id
   * @param {number} id id de la nota
   * @returns Json
   */
  delete(id) {
    this.alertsService
      .alertWarning(
        '¿Estás seguro?',
        '¿En verdad desea eliminar esta nota?, recuerda que esta acción será definitiva y no se podrá deshacer.'
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.notesService.delete(id).subscribe();
          this.alertsService.alertSuccessWithoutButton(
            'Excelente',
            'Se ha eliminado la nota exitosamente'
          );
          this.getNotes();
        }
      });
  }
  /**
   * @author Gabriel Garcia
   * @createddate 2022-10-29 
   * Metodo para actualizar nota por id
   * @param {number} id id de la nota
   * @returns Json
   */
  getNotes() {
    this.notesService.search(this.oportunity).subscribe((resp) => {
      this.notes = resp['data'][this.step];
    });
  }
}
