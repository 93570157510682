import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})

export class PusherService {

  private pusherClient: Pusher;
  private pusherClient_global: Pusher;

  constructor( private authService:AuthService ) {
    this.pusherClient = new Pusher(environment.pusher_app, { cluster: environment.pusher_cluster });
    this.pusherClient_global = new Pusher(environment.pusher_app_global, { cluster: environment.pusher_cluster });

  }

  getNotificationsLeads(){
    return this.pusherClient.subscribe('notification-lead');
  }

  getNotificationsGlobal(){
    return this.pusherClient_global.subscribe('notification'+this.authService.getUser().rrhh_id);
  }
}
