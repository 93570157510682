import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';

const AUDITORIA_URL = environment.AUDITORIA_URL;

@Injectable({
  providedIn: 'root'
})
export class InteligenciaService {

  private _refresh$ = new Subject<void>();

  constructor(
    private http:HttpClient,
    private authService:AuthService
  ) { }

  get refresh$(){
    return this._refresh$;
  }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  createIntelligence(params){
    return this.http.post<any>(`${AUDITORIA_URL}crearFormulario/${this.authService.getUser().rrhh_id}`,params)
    .pipe(
      catchError(this.handleError)
      );
  }

  getForms(params){
    return this.http.get(`${AUDITORIA_URL}listarFormularios`,{params})
    .pipe(
      catchError(this.handleError)
      );
  }

  getFormById(id){
    return this.http.get(`${AUDITORIA_URL}verFormulario/${id}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  questionsForm(id_form,params,id_question){
    return this.http.post<any>(`${AUDITORIA_URL}crearPreguntasForm`,{idForm:id_form,dataform:params,idPregunta:id_question})
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

  removeForm(id_form){
    return this.http.post<any>(`${AUDITORIA_URL}eliminarFormulario/${id_form}`,{})
    .pipe(
      catchError(this.handleError)
      );
  }

  changePosition(id_form,params){
    return this.http.post<any>(`${AUDITORIA_URL}dragdrop`,{idForm:id_form,dataform:params})
    .pipe(
      catchError(this.handleError)
      );
  }

  getMatrix(idForm){
    return this.http.get(`${AUDITORIA_URL}getMatricesForm?idForm=${idForm}`)
    .pipe(
      catchError(this.handleError)
      );
  }

}
