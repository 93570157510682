import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuditoriaService } from '../../../../services/auditoria.service';
import { Tools } from '../../../../utils/tools.utils';

@Component({
  selector: 'app-dialog-eliminacion',
  templateUrl: './dialog-eliminacion.component.html',
  styleUrls: ['./dialog-eliminacion.component.sass']
})
export class DialogEliminacionComponent implements OnInit {

  formEliminar:FormGroup;

  constructor(
    private fb:FormBuilder,
    @Inject(MAT_DIALOG_DATA) public datos:any,
    private eliminarRef:MatDialogRef<DialogEliminacionComponent>,
    private auditoriaService:AuditoriaService,
  ) { }

  ngOnInit(): void {
    console.log(this.datos);
    
    this.createForm();
  }

  createForm(){
    this.formEliminar = this.fb.group({
      observacion:['',Validators.required],
    });
  }

  eliminar(){
    this.auditoriaService.deleteAuditoria(this.datos.id,this.formEliminar.value).subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202) {
        Tools.swalSuccess('¡Excelente!','La auditoría se ha eliminado con éxito');
        this.eliminarRef.close();
      }else{
        Tools.swalError('¡Oops!','Lo sentimos ha sucedio un error')
      }
    });
  }

}
