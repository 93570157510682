<div class="main-head">
  <button mat-button (click)="_location.back()" class="back-btn">
    <span class="fi-sr-angle-small-left"></span>
    <strong>Regresar &nbsp;</strong>
  </button>
  <h2>Hoja de Vida del colaborador</h2>
  <p>En este espacio podrás visualizar los detalles del candidato y completar su proceso de contratación.</p>
  <mat-tab-group (selectedTabChange)="onTabChanged($event);">
    <mat-tab label="Datos Personales">
      <shared-cv-view [person]="candidate" [canUpdateEmail]="checkCanUpdateEmail()" [candidateStatus]="candidateStatus">
      </shared-cv-view>
    </mat-tab>
    <mat-tab *ngIf="hiring || validator" label="Documentos">
      <div class="info-section">
        <mat-accordion multi>
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3>Documentos suministrados por el candidato</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <table mat-table [dataSource]="candidateDocuments">
              <ng-container matColumnDef="actions">
                <th class="start" mat-header-cell *matHeaderCellDef> Acciones </th>
                <td mat-cell *matCellDef="let row" style="text-align: left;">
                  <button mat-icon-button *ngIf="row.state == 'Pendiente' && row.uploaded" (click)="checkItem(row)"
                    matTooltip="Verificar documento"><span class="fi-rr-file-check"></span></button>
                  <button mat-icon-button *ngIf="row.state == 'Aprobado'" [matTooltip]="row.dataExtraJson.comment"
                    class="check-btn"><span class="fi-rr-check"></span> </button>
                  <button mat-icon-button *ngIf="!row.uploaded || row.state == 'Rechazado'" (click)="fileInput.click()"
                    matTooltip="Cargar Documento"><span class="fi-rr-cloud-upload"></span></button>
                  <input hidden (change)="onFileSelected($event.target.files, row.file_attachment_id,row.id)" #fileInput
                    type="file" id="file" [accept]="row.fileKey =='photo'? 'image/png, image/jpeg' : 'application/pdf'">
                  <button mat-icon-button *ngIf="row.uploaded && row.name != 'No Aplica'"
                    (click)="downdloadFile(row, true)" matTooltip="Ver documento"><span
                      class="fi-rr-eye"></span></button>
                  <button mat-icon-button *ngIf="row.uploaded && row.name != 'No Aplica'"
                    matTooltip="Descargar documento" (click)="downdloadFile(row)"><span
                      class="fi-rr-cloud-download"></span></button>
                  <button mat-icon-button *ngIf="!row.uploaded" matTooltip="No aplicar este documento"
                    (click)="notApply(row)"><span class="fi-rr-lock"></span></button>

                  <button mat-icon-button
                          [hidden]=" row.fileKey == 'contrato' && truora_aprobada==true "
                          *ngIf="row.uploaded && row.state == 'Aprobado'" matTooltip="Eliminar documento"
                          (click)="deleteFile(row)">
                    <span class="fi fi-rr-trash"></span>
                  </button>

                </td>
              </ng-container>
              <ng-container matColumnDef="file">
                <th mat-header-cell *matHeaderCellDef> Tipo </th>
                <td mat-cell *matCellDef="let element"> {{element.file}} </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Archivo cargado</th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>

              <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef> Estado</th>
                <td mat-cell *matCellDef="let element"> {{element.state}} </td>
              </ng-container>

              <ng-container matColumnDef="templates">
                <th class="end" mat-header-cell *matHeaderCellDef> Plantillas </th>
                <td mat-cell *matCellDef="let row">
                  <button mat-raised-button color="primary"
                          *ngIf=" row.fileKey == 'personal_reference'  "
                          matTooltip="Cargar plantilla"
                          (click)="uploadTemplate(row)">
                    <span>Upload</span>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedDocumentsColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedDocumentsColumns;"></tr>
            </table>

          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3>Documentos suministrados por la operación</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <table mat-table [dataSource]="candidateOtherDocuments">

              <ng-container matColumnDef="actions">
                <th class="start" mat-header-cell *matHeaderCellDef> Acciones </th>
                <td mat-cell *matCellDef="let row" style="text-align: left;">
                  <button mat-icon-button *ngIf="row.state == 'Pendiente' && row.uploaded" (click)="checkItem(row)"
                    matTooltip="Verificar documento"><span class="fi-rr-file-check"></span></button>
                  <button mat-icon-button *ngIf="row.state == 'Aprobado'" [matTooltip]="row.dataExtraJson.comment"
                    class="check-btn"><span class="fi-rr-check"></span> </button>
                  <button mat-icon-button *ngIf="!row.uploaded || row.state == 'Rechazado'" (click)="fileInput.click()"
                    matTooltip="Cargar Documento"><span class="fi-rr-cloud-upload"></span></button>
                  <input hidden (change)="onFileSelected($event.target.files, row.file_attachment_id,row.id)" #fileInput
                    type="file" id="file" [accept]="row.fileKey =='firma'? 'image/png, image/jpeg' : 'application/pdf'">
                  <button mat-icon-button *ngIf="row.uploaded && row.name != 'No Aplica'"
                    (click)="downdloadFile(row, true)" matTooltip="Ver documento"><span
                      class="fi-rr-eye"></span></button>
                  <button mat-icon-button *ngIf="row.uploaded && row.name != 'No Aplica'"
                    matTooltip="Descargar documento" (click)="downdloadFile(row)"><span
                      class="fi-rr-cloud-download"></span></button>
                  <button mat-icon-button *ngIf="!row.uploaded" matTooltip="No aplicar este documento"
                    (click)="notApply(row)"><span class="fi-rr-lock"></span></button>
                </td>
              </ng-container>

              <ng-container matColumnDef="file">
                <th mat-header-cell *matHeaderCellDef> Tipo </th>
                <td mat-cell *matCellDef="let element"> {{element.file}} </td>
              </ng-container>


              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Archivo cargado</th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>

              <ng-container matColumnDef="state">
                <th class="end" mat-header-cell *matHeaderCellDef> Estado</th>
                <td mat-cell *matCellDef="let element"> {{element.state}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3>Documentos eliminados</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <table mat-table [dataSource]="candidateDocumentosEliminados">

              <ng-container matColumnDef="actions">
                <th class="start" mat-header-cell *matHeaderCellDef> Acciones </th>
                <td mat-cell *matCellDef="let row" style="text-align: left;">


                  <button mat-icon-button (click)="activar_documento(row)"
                    matTooltip="Activar documento" style="display:none"><span class="fi-rr-file-check"></span></button>

                    <button mat-icon-button (click)="downdloadFile(row, true)" matTooltip="Ver documento"><span
                      class="fi-rr-eye"></span></button>

                  <button mat-icon-button
                    matTooltip="Descargar documento" (click)="downdloadFile(row)"><span
                      class="fi-rr-cloud-download"></span></button>

                </td>
              </ng-container>

              <ng-container matColumnDef="file">
                <th mat-header-cell *matHeaderCellDef> Tipo </th>
                <td mat-cell *matCellDef="let element"> {{element.type}} </td>
              </ng-container>


              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Archivo cargado</th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>

              <ng-container matColumnDef="state">
                <th class="end" mat-header-cell *matHeaderCellDef> Estado</th>
                <td mat-cell *matCellDef="let element"> Eliminado </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </mat-expansion-panel>

        </mat-accordion>
      </div>
    </mat-tab>

    <mat-tab label="Histórico">
      <div class="info-section">
        <div *ngFor="let request of candidateHistory;  last as Last ">
          <h3>{{request.requestCode}} {{request.campaign}}</h3>
          <h4>{{request.createdAt | date}}</h4>
          <div fxLayout="row" class="credentials" style="padding: 7px 0;">
            <div>
              <strong>Solicitante</strong>
              <p>{{request.applicant}}</p>
            </div>
            <div>
              <strong>Cargo</strong>
              <p>{{request.position}}</p>
            </div>
          </div>
          <div fxLayout="row wrap" style="padding-bottom:25px;">
            <div fxLayout="row" fxLayoutAlign=" center" class="history-card"
              *ngFor="let state of request.states; last as isLast">
              <mat-card [ngStyle]="{'border-color': isLast ? '#2CABBC':'#A9A9A9' }" fxFlexAlign="stretch">
                <strong>Estado</strong>
                <p><strong>{{state.state}}</strong></p>
                <div *ngIf="state.commentary.length > 0 && state.see_comment">
                  <strong>Comentario</strong>
                  <br>
                  <div *ngFor="let commentary of state.commentary">
                    <ul>
                      <li type="disc">
                        <p>{{commentary.comment}}</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div fxLayout="row">
                  <strong>Responsable</strong> &nbsp; &nbsp;
                  <p>{{state.responsable}}</p>
                </div>
                <div fxLayout="row">
                  <strong>Fecha</strong> &nbsp; &nbsp;
                  <p>{{state.createdAt | date}}</p>
                </div>

              </mat-card>

              <span *ngIf="!isLast" style="color: #A9A9A9;" class="fi-rr-arrow-small-right"></span>
            </div>

          </div>
          <h4><b>Exámenes médicos</b></h4>
          <div fxLayout="row wrap" style="padding-bottom:25px;">
            <div fxLayout="row" fxLayoutAlign=" center" class="history-card" *ngFor="let test of request.medicalTest">
              <mat-card [ngStyle]="{'border-color': test.status=='Aprobado'? '#2CABBC':'#A9A9A9' }">
                <strong>Estado</strong>
                <p><strong>{{test.status}}</strong></p>
                <div fxLayout="row">
                  <strong>Prueba</strong> &nbsp; &nbsp;
                  <p>{{test.medicalTypeTest}}</p>
                </div>
                <div fxLayout="row">
                  <strong>Responsable</strong> &nbsp; &nbsp;
                  <p>{{test.responsable}}</p>
                </div>
                <div fxLayout="row">
                  <strong>Fecha</strong> &nbsp; &nbsp;
                  <p>{{test.created_at | date}}</p>
                </div>
              </mat-card>
            </div>
          </div>
          <h4><b>Reglas de Contratación</b></h4>
          <div fxLayout="row wrap" style="padding-bottom:25px;">
            <div fxLayout="row" fxLayoutAlign=" center" class="history-card"
              *ngFor="let agreement of request.agreementLog; last as isLast2">
              <mat-card [ngStyle]="{'border-color': agreement.action.name=='Aprobado'? '#2CABBC':'#A9A9A9' }">
                <strong>Estado</strong>
                <p><strong>{{agreement.action.name}}</strong></p>
                <div fxLayout="column" *ngIf="agreement.action.comment">
                  <strong>Comentario</strong>
                  <p>{{agreement.action.comment}}</p>
                </div>
                <div fxLayout="row">
                  <strong>Responsable</strong> &nbsp; &nbsp;
                  <p>{{agreement.responsable}}</p>
                </div>
                <div fxLayout="row">
                  <strong>Fecha</strong> &nbsp; &nbsp;
                  <p>{{agreement.created_at | date}}</p>
                </div>

              </mat-card>
              <span *ngIf="!isLast2" style="color: #A9A9A9;" class="fi-rr-arrow-small-right"></span>
            </div>
          </div>

          <hr *ngIf="Last">

        </div>
      </div>
    </mat-tab>

    <mat-tab *ngIf="hiring" label="Acciones">
      <div class="info-section" fxL ayout="column">
        <h3>Agendar Cita, credenciales y reglas de contratación</h3>
        <div fxLayout="row wrap " fxLayoutAlign="space-between center">
          <div class="date-section" id="dateSection">
            <mat-form-field appearance="fill">
              <mat-label>Seleccione la fecha y hora a asignar</mat-label>
              <input matInput type="datetime-local" [min]="now" [(ngModel)]="fechaCita">
            </mat-form-field>

            <button mat-stroked-button *ngIf="fechaCita" color="primary" (click)="agendarCita()">
              {{ scheduledBtn }}
            </button>
          </div>
          <mat-checkbox [checked]="candidate.passive_holiday" (change)="generateHoliday($event)">
            Aplica sábado para pasivo <br> vacacional
          </mat-checkbox>
          <button mat-stroked-button color="accent" (click)="openContractForm()">
            {{contractPhase}}
          </button>
          <button mat-raised-button color="primary" (click)="generateCredentials()" [disabled]="checkCredencials">
            Generar Credenciales
          </button>
          <button mat-stroked-button color="warn" *ngIf="contract?.state !='Aprobado'" (click)="getComments()">
            Cancelar proceso
          </button>
          <button mat-stroked-button color="primary" (click)="showModalCampaignEdit()">
            Cambiar campaña
          </button>
        </div>



        <div class="section-action">
          <h3>Generar Contrato, acuerdos y documentos</h3>
          <hr>
          <p>Recuerda que para poder generar <b>El Contrato</b>, todos los documentos deben estar completos y validados,
            al igual que todos los exámenes médicos aprobados y <b>las reglas de contratación generadas</b>.</p>
          <!--  <div fxLayoutAlign="end center" >
                  <button mat-button class="download-btn" (click)="downdloadFile()">
                    <strong> Descargar todos los archivos &nbsp;
                    <span class="fi-rr-download"></span></strong>
                  </button>
                </div> -->

          <table mat-table [dataSource]="documentGeneratedByOperation">

            <ng-container matColumnDef="actions">
              <th class="start" mat-header-cell *matHeaderCellDef> Acciones </th>
              <td mat-cell *matCellDef="let row" style="text-align: left;">
                <button mat-icon-button [hidden]=" row.fileKey == 'contrato' && truora_aprobada==false "
                  *ngIf="row.state == 'Pendiente' && row.uploaded || row.state == 'Rechazado' && row.uploaded"
                  (click)="checkItem(row)" matTooltip="Verificar documento" [disabled]="row.disabled"><span
                    class="fi-rr-file-check"></span></button>
                <button mat-icon-button  [hidden]=" row.fileKey == 'contrato' && truora_aprobada==false "
                *ngIf="row.state == 'Aprobado'" [matTooltip]="row.dataExtraJson.comment"
                  class="check-btn"><span class="fi-rr-check"></span> </button>
                <button mat-icon-button [hidden]=" row.fileKey == 'contrato' && truora_aprobada==false "
                *ngIf="!row.uploaded || row.state == 'Rechazado' || row.fileKey != 'codigo_qr'"
                  (click)="generateDocument(row.fileKey)"
                  [matTooltip]="row.fileKey =='contrato' ? 'Generar contrato':'Generar documento'"
                  [disabled]="disabledDocument"><span class="fi-rr-file-add"></span></button>
                <button mat-icon-button  [hidden]=" row.fileKey == 'contrato' && truora_aprobada==false "
                *ngIf="row.uploaded" (click)="downdloadFile(row, true)"
                  matTooltip="Ver documento"><span class="fi-rr-eye"></span></button>
                <button mat-icon-button  [hidden]=" row.fileKey == 'contrato' && truora_aprobada==false "
                *ngIf="row.uploaded" matTooltip="Descargar documento"
                  (click)="downdloadFile(row)"><span class="fi-rr-cloud-download"></span></button>
                  <span *ngIf=" row.fileKey == 'contrato' && truora_aprobada==false " style="color: #af1106;">
                    Por favor validar documento <b>Truora</b>.
                  </span>
              </td>
            </ng-container>


            <ng-container matColumnDef="file">
              <th mat-header-cell *matHeaderCellDef> Tipo </th>
              <td mat-cell *matCellDef="let element"> {{element.file}} </td>
            </ng-container>


            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Archivo cargado</th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th class="end" mat-header-cell *matHeaderCellDef> Estado</th>
              <td mat-cell *matCellDef="let element"> {{element.state}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <div class="section-action">
          <h3>Firmas</h3>
          <hr>
          <table mat-table [dataSource]="documentsToUploadToOperation">

            <ng-container matColumnDef="actions">
              <th class="start" mat-header-cell *matHeaderCellDef> Acciones </th>
              <td mat-cell *matCellDef="let row" style="text-align: left;">
                <button mat-icon-button *ngIf="row.state == 'Pendiente' && row.uploaded" (click)="checkItem(row)"><span
                    class="fi-rr-file-check" matTooltip="Verificar documento"></span></button>
                <button mat-icon-button *ngIf="row.state == 'Aprobado'" [matTooltip]="row.dataExtraJson.comment"
                  class="check-btn"><span class="fi-rr-check"></span> </button>
                <button mat-icon-button *ngIf="!row.uploaded || row.state == 'Rechazado'"
                  (click)="fileInput.click()"><span class="fi-rr-cloud-upload"
                    matTooltip="Cargar Documento"></span></button>
                <button mat-icon-button *ngIf="(row.fileKey != 'encuesta') && !row.uploaded || row.state == 'Rechazado'"
                  (click)="crateSign( row.file_attachment_id,row.id)"><span class="fi-rr-pencil"
                    matTooltip="Firmar digitalmente"></span></button>
                <input hidden (change)="onFileSelected($event.target.files, row.file_attachment_id,row.id)" #fileInput
                  type="file" id="file" accept="image/png, image/jpeg">
                <button mat-icon-button *ngIf="row.uploaded" (click)="downdloadFile(row, true)"
                  matTooltip="Ver documento"><span class="fi-rr-eye"></span></button>
                <button mat-icon-button *ngIf="row.uploaded" matTooltip="Descargar documento"
                  (click)="downdloadFile(row)"><span class="fi-rr-cloud-download"></span></button>
              </td>
            </ng-container>


            <ng-container matColumnDef="file">
              <th mat-header-cell *matHeaderCellDef> Tipo </th>
              <td mat-cell *matCellDef="let element"> {{element.file}} </td>
            </ng-container>


            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Archivo cargado</th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th class="end" mat-header-cell *matHeaderCellDef> Estado</th>
              <td mat-cell *matCellDef="let element"> {{element.state}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>


        <div class="section-action" *ngIf="candidate.username !='' && candidate.username != null">
          <h3>Credenciales del colaborador</h3>
          <hr>
          <div fxLayout="row" fxLayoutAlign="space-between center" id="credentials">
            <div fxLayout="row" class="credentials">
              <div>
                <strong>Nombre de usuario</strong>
                <p>{{candidate.username}}</p>
              </div>
              <div>
                <strong>Contraseña temporal</strong>
                <p>{{candidate.id_number}}</p>
              </div>
            </div>



            <button mat-raised-button color="primary" (click)="sendEmail()">
              Enviar contrato
            </button>
          </div>


        </div>
      </div>

    </mat-tab>

  <!--   <mat-tab *ngIf="hiring" label="Desprendibles">
      <div class="row mt-3">
        <mat-form-field appearance="fill" class="col-3 pr-3">
          <mat-label>Certificados Disponibles</mat-label>
          <mat-select [formControl]="selectCertifications" #input autocomplete="off">
            <mat-option *ngFor="let item of certificationsType" [value]="item.key">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-3 pr-3" *ngIf="selectCertifications.value == 'desprendible-de-pago'">
          <mat-label>Año</mat-label>
          <mat-select [formControl]="selectYear" #input autocomplete="off">
            <mat-option *ngFor="let item of years" [value]="item.id">
              {{item?.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row col-12" *ngIf="selectCertifications.value == 'certificado-laboral'">
        <div *ngIf="laborCertificates.length < 1" >No hay certificados disponibles.</div>
        <div class="col-3" style="padding-bottom: 24px;" *ngFor="let item of laborCertificates">
          <mat-card class="form-card" (click)="openCertification(selectCertifications.value, item.rrhh_id,'','')">
            <div class="row">
              <div class="row col-12">
                <div class="col-3 row" style="padding: 0px; position:relative;">
                  <img src="../../../../../../assets/images/calendar.svg">
                </div>

                <mat-card-header class="card-view col">
                  <mat-card-title>
                    <p style="font-size: 12px">Fecha Ingreso {{item?.start | date:'yyyy/MM/dd' }}</p>
                  </mat-card-title>
                  <mat-card-subtitle>
                    <p style="font-size: 20px; color: #51B0CB;">{{item.stateName}}</p>
                  </mat-card-subtitle>
                </mat-card-header>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
      <div class="row col-12" *ngIf="selectCertifications.value == 'desprendible-de-pago'">
        <div *ngIf="paymentCertificates.length < 1" >No hay certificados disponibles.</div>
        <div class="col-3" style="padding-bottom: 24px;" *ngFor="let item of paymentCertificates">
          <mat-card class="form-card" (click)="openCertification(selectCertifications.value, '',item.month, item.year)">
            <div class="row">
              <div class="row col-12">
                <div class="col-3 row" style="padding: 0px; position:relative;">
                  <img src="../../../../../../assets/images/calendar.svg">
                </div>
                <mat-card-header class="card-view col">
                  <mat-card-title>
                    <p style="font-size: 12px">Desprendible mes</p>
                  </mat-card-title>
                  <mat-card-subtitle>
                    <p style="font-size: 20px; color: #51B0CB;">{{getNameMount(item.month)}} </p>
                  </mat-card-subtitle>
                </mat-card-header>
              </div>
            </div>
          </mat-card>
        </div>
      </div>

    </mat-tab> -->
  </mat-tab-group>
</div>
