<button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<form mat-dialog-content class="containerDialog" [formGroup]="matrixForm">
    <h2>Crear matriz</h2>
    <mat-divider></mat-divider>
    <div class="fields">
        <mat-form-field appearance="fill">
            <mat-label>Nombre matriz</mat-label>
            <input  matInput formControlName="nombre" cdkFocusInitial>
            <mat-error *ngIf="matrixForm.get('nombre').hasError('required')">Por favor ingrese un nombre de matriz.</mat-error>
        </mat-form-field>    
        <mat-form-field appearance="fill">
            <mat-label>Tipo de matriz</mat-label>
            <mat-select formControlName="tipo_matriz_id" #tipo_matriz (selectionChange)="changeSelect()">
                <mat-option *ngFor="let tipo of tiposMatrices" [value]="tipo.id">{{tipo.nombre}}</mat-option>
            </mat-select>
            <mat-error *ngIf="matrixForm.get('tipo_matriz_id').hasError('required')">Por favor seleccione una opción.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="tipo_matriz.value == '2'">
            <mat-label>Rango del peso</mat-label>
            <mat-select formControlName="rango_id">
                <mat-option *ngFor="let rango of rangos" [value]="rango.id">{{rango.nombre}}</mat-option>
            </mat-select>
            <mat-error *ngIf="matrixForm.get('rango_id').hasError('required')">Por favor seleccione una opción.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="tipo_matriz.value == '2'">
            <mat-label>¿Peso en módulo o en ítems?</mat-label>
            <mat-select formControlName="tipo_peso_id">
                <mat-option *ngFor="let peso of tipos_peso" [value]="peso.id">{{peso.nombre}}</mat-option>
            </mat-select>
            <mat-error *ngIf="matrixForm.get('tipo_peso_id').hasError('required')">Por favor seleccione una opción.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Campaña asociada</mat-label>
            <mat-select formControlName="campania_asociada" #asociada (selectionChange)="changeSelect()">
                <mat-option [value]="1">Si</mat-option>
                <mat-option [value]="0">No</mat-option>
            </mat-select>
            <mat-error *ngIf="matrixForm.get('campania_asociada').hasError('required')">Por favor seleccione una opción</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="asociada.value == '1'">
            <mat-label >Seleccionar CRM</mat-label>
            <mat-select formControlName="crm_id">
                <mat-option *ngFor="let campaign of campaigns" [value]="campaign.id">{{campaign.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="matrixForm.get('crm_id').hasError('required')">Por favor seleccione una opción</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Aplicar inteligencia de Negocio</mat-label>
            <mat-select formControlName="aplicar_inteligenciaNegocio" #inteligencia (selectionChange)="changeSelect()">
                <mat-option [value]="1">Si</mat-option>
                <mat-option [value]="0">No</mat-option>
            </mat-select>
            <mat-error *ngIf="matrixForm.get('aplicar_inteligenciaNegocio').hasError('required')">Por favor seleccione una opción</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="inteligencia.value == 1">
            <mat-label>Seleccione Inteligencia de N.</mat-label>
            <mat-select formControlName="inteligenciaNegocio_id">
                <mat-option *ngFor="let inteligencia of inteligencias" [value]="inteligencia.id">{{inteligencia.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="matrixForm.get('inteligenciaNegocio_id').hasError('required')">Por favor seleccione una opción</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>¿Es una matriz de retención?</mat-label>
            <mat-select formControlName="isRetentionMatrix">
                <mat-option [value]="1">Si</mat-option>
                <mat-option [value]="0">No</mat-option>
            </mat-select>
            <mat-error *ngIf="matrixForm.get('isRetentionMatrix').hasError('required')">Por favor seleccione una opción</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>¿Ver campo de duración?</mat-label>
            <mat-select formControlName="showFieldDuration">
                <mat-option [value]="1">Si</mat-option>
                <mat-option [value]="0">No</mat-option>
            </mat-select>
            <mat-error *ngIf="matrixForm.get('showFieldDuration').hasError('required')">Por favor seleccione una opción</mat-error>
        </mat-form-field>
    </div>
   
    <div class="btns-dialog">
        <button mat-stroked-button (click)="matrizRef.close()">Cancelar</button>
        <button mat-raised-button color="primary" (click)="save()">Guardar</button>
    </div>
</form>