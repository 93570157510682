
<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>
<h3>Pre Vinculación</h3>
<hr>
<mat-dialog-content >
    <form fxLayout="row wrap" fxLayout.xs="column"  fxLayoutGap="34px" [formGroup]="proBording">
        <mat-form-field appearance="fill" fxFlex=30 fxFlex.md=45>
            <mat-label>Aliado</mat-label>
            <input matInput formControlName="ally">
          </mat-form-field>
        <mat-form-field appearance="fill" fxFlex=30 fxFlex.md=45>
            <mat-label>Canal</mat-label>
            <input matInput formControlName="channel" >
          </mat-form-field>
        <mat-form-field appearance="fill" fxFlex=30 fxFlex.md=45>
            <mat-label>Operativo</mat-label>
            <input matInput formControlName="operative" >
          </mat-form-field>
         <mat-form-field appearance="fill" fxFlex=30 fxFlex.md=45>
            <mat-label>Familiar Claro</mat-label>
            <input matInput  formControlName="familiar_claro">
          </mat-form-field>
       <mat-form-field appearance="fill" fxFlex=30 fxFlex.md=45>
            <mat-label>Cargo Familiar</mat-label>
            <input matInput formControlName="family_charge" >
          </mat-form-field>
        <mat-form-field appearance="fill" fxFlex=30 fxFlex.md=45>
            <mat-label>Móvil</mat-label>
            <mat-select formControlName="mobile">
              <mat-option [value]="true">Si</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex=30 fxFlex.md=45>
            <mat-label>Fijo</mat-label>
            <mat-select formControlName="permanent">
              <mat-option [value]="true">Si</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex=30 fxFlex.md=45>
            <mat-label>Capacitador</mat-label>
            <input matInput formControlName="trainer">
        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex=30 fxFlex.md=45>
            <mat-label>Unidad de negocio</mat-label>
            <input matInput formControlName="business_unit" >
          </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button mat-flat-button color="primary" (click)="sendPreBonding()">Guardar</button>
</mat-dialog-actions>