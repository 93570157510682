import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../core/services/rest/auth.service';
import { catchError } from 'rxjs/operators';
import * as moment from 'moment';

const CIU_URL = environment.CIU_URL;

@Injectable({
  providedIn: 'root'
})
export class SchedulesService {

  constructor(private http: HttpClient,
    private authService: AuthService) {
  }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * @author Jose Vicente Silva Rivera
   * @createdate 2021-02-02
   * Servicio que lista los schedules del tipo workday
   */
  getSchedule(size: number, page: number, id: any): Observable<any> {
    return this.http.get<any>(`${CIU_URL}schedule/?config_id=${id}&n=${size}&page=${page}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Jose Vicente Silva Rivera
   * @createdate 2021-02-02
   * Servicio que lista todos los groupes del aplicativo por campaña
   * @param data contiene la ruta y la configuracion que se va a cargar
   */
  postConfigs(data: any): Observable<any> {
    return this.http.post<any>(`${CIU_URL}schedule/massive`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadFile(formData: any): Observable<any> {
    return this.http.post<any>(`${CIU_URL}uploadfile`, formData)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @param filters filtros para busqueda
   * @author Andres Buitrago
   * @created 2021-05-05
   * @purpose Servicio para obtener los diferentes schedules por usuario en base a filtros
   */
  getSchedulesByFilters(filters: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startWeek', filters.startWeek || '')
        .set('endWeek', filters.endWeek || '')
        .set('campaignId', filters.campaignId || '')
        .set('groupId', filters.groupId || '')
        .set('document', filters.document || '')
        .set('documentType', filters.type || '')
        .set('perPage', filters.perPage || 5)
        .set('stateUserSchedule', filters.stateUserSchedule || '')
        .set('paginate', filters.paginate || 1)
        .set('page', filters.page || 1)
    };
    return this.http.get<any>(`${CIU_URL}schedule/all`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @param filters filtros para busqueda
   * @author Andres Buitrago
   * @created 2021-05-05
   * @purpose Servicio para obtener los diferentes schedules por usuario en base a filtros
   */
  exportScheduleByFilters(filters: any): Observable<any> {
    return this.http.post<any>(`${CIU_URL}schedule/toEdit`, filters, { responseType: 'blob' as 'json' })
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Obtiene la plantilla de horarios para las modificaciones respectivas
   * @author Andres Buitrago
   * @createdate 2021-05-08
   * @param data filtros aplicados para generacion de excel con horarios y posterior modificacion
   */
  getScheduleFileToEdit(data: any): Observable<any> {
    return this.http.post<any>(`${CIU_URL}schedule/toEdit`, data, { responseType: 'blob' as 'json' })
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Servicio que realiza la actualizacion de una fecha especifica
   * @author Andres Buitrago
   * @createdate 2021-05-08
   * @param data archivo importado para actualizacion masiva de horarios
   */
  updateScheduleSelected(data: any): Observable<any> {
    return this.http.post<any>(`${CIU_URL}schedule/massiveUpdate`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Elimina el horario de un usuario seleccionado
   * @author Andres Buitrago
   * @createdate 2021-05-08
   * @param data archivo importado para actualizacion masiva de horarios
   */
  deleteScheduleByParams(data: any): Observable<any> {
    return this.http.put<any>(`${CIU_URL}schedule/deleteSchedule`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Obtiene el listado de marcaciones realizadas
   * @author Daniel Dominguez
   * @createdate 2021-05-20
   * @param filters filtros a aplicar
   */
  getMarkedSchedulesByFilters(filters: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startWeek', filters.startWeek || '')
        .set('endWeek', filters.endWeek || '')
        .set('campaignId', filters.campaignId || '')
        .set('groupId', filters.groupId || '')
        .set('document', filters.document || '')
        .set('documentType', filters.type || '')
        .set('perPage', filters.perPage || 5)
        .set('paginate', filters.paginate || 1)
        .set('page', filters.page || 1)
    };
    return this.http.get<any>(`${CIU_URL}schedule/allMarked`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * exporta el listado de marcaciones realizadas
   * @author Daniel Dominguez
   * @createdate 2021-05-20
   * @param filters filtros a aplicar
   */
  exportMarkedSchedulesByFilters(filters: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startWeek', filters.startWeek || '')
        .set('endWeek', filters.endWeek || '')
        .set('campaignId', filters.campaignId || '')
        .set('groupId', filters.groupId || '')
        .set('document', filters.document || '')
        .set('documentType', filters.type || '')
        .set('perPage', filters.perPage || 5)
        .set('paginate', filters.paginate || 1)
        .set('page', filters.page || 1),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${CIU_URL}schedule/exportAllMarked`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * exporta el listado de marcaciones realizadas
   * @author Andres Buitrago
   * @createdate 2021-10-01
   * @param filters filtros a aplicar
   */
  exportEventsCiuAndVicidialByFilters(filters: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', moment(filters.startDate).format('YYYY-MM-DD') || '')
        .set('endDate', moment(filters.endDate).format('YYYY-MM-DD') || '')
        .set('groupId', filters.groupId || '')
        .set('userId', filters.userId || ''),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${CIU_URL}schedule/reportCiuAndVicidial`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Servicio que realiza la actualizacion de una fecha especifica
   * @author Andres Buitrago
   * @createdate 2021-05-23
   * @param data info con turnos a actualizar
   */
  updateScheduleDateSelected(data: any): Observable<any> {
    return this.http.put<any>(`${CIU_URL}schedule/updateSpecificDate`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Servicio que obtiene un listado de semanas para el filtro de horarios
   * @author Andres Buitrago
   * @createdate 2021-06-24
   * @param data info con parametros requeridos para el filtro (numWeeks, startDate)
   */
  getWeeksRangesForScheduleFilters(data: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', data.to || '')
        .set('numWeeks', data.numWeeks || '')
    };
    return this.http.get<any>(`${CIU_URL}schedule/getWeeks`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Servicio que obtiene un listado de semanas para el filtro de horarios por grupo
   * @author Andres Buitrago
   * @createdate 2021-06-24
   * @param groupId number ID del grupo
   */
  getWeeksRangesByGroupId(groupId: number): Observable<any> {
    return this.http.get<any>(`${CIU_URL}groups/${groupId}/getWeeks`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Obtiene los turnos de un día para su modificación
   * @param rrhhUserId RRHH user
   * @param dateSelected fecha seleccionada
   * @author Andres Buitrago
   */
  getScheduleToEdit(rrhhUserId: any, dateSelected: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('rrhhId', rrhhUserId || '')
        .set('scheduleDate', dateSelected || '')
    };
    return this.http.get<any>(`${CIU_URL}schedule/toEdit`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * exporta el listado de marcaciones realizadas
   * @author Daniel Dominguez
   * @createdate 2021-06-08
   * @param date filtro de fecha para la consulta del horario
   */
  getSchedulesGraph(date?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('date', date || '')
    };
    return this.http.get<any>(`${CIU_URL}schedule/getScheduleGraphic`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * exporta el listado de marcaciones realizadas
   * @author Daniel Dominguez
   * @createdate 2021-06-08
   */
  getSchedulesUser(date?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('date', date || '')
    };
    return this.http.get<any>(`${CIU_URL}schedule/getDiarySchedule`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
  * exporta el listado de marcaciones realizadas
  * @author Carlos Nieto
  * @createdate 2022-02-25
  */
  getUserWeekSchedule(idCiu) {
    return this.http.get<any>(`${CIU_URL}schedule/workdayByAgent/${idCiu}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
    * @author Fabian Duran
    * @createdate 2022-05-30
    * Metodo que consulta los horarios de la semana actual y la proxima. 
  */
  getWeekAndNextWeekSchedule(idUserCiu: number, date: string): Observable<any> {
    return this.http.get<any>(`${CIU_URL}schedule/workdayByAgentV2/${idUserCiu}?date=${date}`).pipe(
      catchError(this.handleError)
    );
  }
}
