import { Component, OnInit, Inject } from '@angular/core';
import { ResourcesService } from '../../../services/resources.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { ResourcesCreateComponent } from '../resources-create/resources-create.component';
import { ResourcesRulesComponent } from '../resources-rules/resources-rules.component';
import { ResourcesTypesComponent } from '../resources-types/resources-types.component';


@Component({
  selector: 'app-resources-main',
  templateUrl: './resources-main.component.html',
  styleUrls: ['./resources-main.component.sass']
})
export class ResourcesMainComponent implements OnInit {

  displayedColumns = ['actions', 'name', 'type'];
  resourceList = [];
  dataSource: any;
  currentPage = 1;
  length = 0;
  pageSize = 0;
  typeStatus: any = '';

  constructor(public resourcesService: ResourcesService,  private dialog: MatDialog,
    public router: Router,  public auth: AuthService, private alertsService: AlertsService) { }

  ngOnInit(): void {
   this.getResources();
  }
  getResources() {
    this.resourcesService.getResources('resources', {}).subscribe(res => {
      this.length = res.total;
      this.pageSize = res.per_page;
      this.resourceList = res;
      this.dataSource = new MatTableDataSource(res);
    });
  }

  createResource() {
    this.dialog.open( ResourcesCreateComponent, {
      width: '350px',
      data: {action: 'Crear'}
    }).afterClosed().subscribe(res => {
      this.getResources();
    });
  }


  editResource(row) {
    this.dialog.open( ResourcesCreateComponent, {
      width: '350px',
      data: {action: 'Editar', row}
    }).afterClosed().subscribe(res => {
      if (res) {
        this.getResources();
      }
    });
  }

  removeResource(id) {
    this.alertsService.alertConfirm('¿Deseas eliminar este recurso?').then((result) => {
      if (result.isConfirmed) {
        this.resourcesService.removeResources(id).subscribe((res: any) => {
          this.alertsService.alertSuccess('Eliminado', res);
          this.getResources();
        });
      }
    });
  }

  createResourceType() {
    this.dialog.open( ResourcesTypesComponent, {
      width: '500px',
      data: {action: 'Crear'}
    }).afterClosed().subscribe(res => {
      if (res) {
        this.getResources();
      }
    });
  }

  createResourceRules(id) {
    this.dialog.open( ResourcesRulesComponent, {
      width: '500px',
      data: {action: 'Crear', id}
    }).afterClosed().subscribe(res => {
      if (res) {
        this.getResources();
      }
    });
  }

  getPaginatorData(e) {
    this.currentPage = e.pageIndex + 1;
    this.getResources();
  }

}
