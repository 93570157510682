import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { QuestionBase } from 'src/app/core/models/questions-base';
import { AuthService } from 'src/app/core/services/rest/auth.service';

QuestionBase;
@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.sass']
})
export class CrmComponent implements OnInit {
  currentDate;
  constructor() {

  }

  ngOnInit(): void {
  }

}
