import { Component, Inject, OnInit, OnDestroy} from '@angular/core';
import { CandidatesService } from '../../../services/candidates.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormBuilder, FormGroup, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { Subscription } from 'rxjs';
/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-candidate-create',
  templateUrl: './candidate-create.component.html',
  styleUrls: ['./candidate-create.component.sass']
})
export class CandidateCreateComponent implements OnInit, OnDestroy {

  buttonRegister = false;
  isReferredValue = false;

  candidateForm: FormGroup;

  matcher = new MyErrorStateMatcher();
  selected = new FormControl('false');

  requestId = 1;
  idTypes:any;
  keyType: string;
  showReferendInput: boolean = false;
  isNewUser: boolean = true;
  referendList = [];
  userName;
  getReferredByDocumentSuscription: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public datos: any,
              private candidatesService: CandidatesService,
              private fb: FormBuilder,
              private dialogRef: MatDialogRef<CandidateCreateComponent>,
              private alert: AlertsService,
              private authService: AuthService) {
                this.requestId = datos.requestId;
              }

  ngOnInit(): void {
    this.getTypes();
    this.createCandidateForm();
    this.userName = this.authService.decryptToken();
  }
  createCandidateForm(){
    this.candidateForm = this.fb.group({

      id_type_id: ['', {disabled: false, validators: [Validators.required]}],
      id_number: ['', {disabled: false, validators: [Validators.required, Validators.maxLength(15)]}],
      first_name: [{value: '', disabled: true}, [Validators.required, Validators.maxLength(15)]],
      middle_name: [{value: '', disabled: true}, Validators.maxLength(15)],
      last_name: [{value: '', disabled: true}, [Validators.required, Validators.maxLength(15)]],
      second_last_name: [{value: '', disabled: true}, Validators.maxLength(15)],
      email: [{value: '', disabled: true}, [Validators.required, Validators.email, Validators.maxLength(40)]],
      email_2: [{value: '', disabled: true}, [Validators.email, Validators.maxLength(40)]],
      phone: [{value: '', disabled: true}, [Validators.min(1), Validators.max(9999999999)]],
      mobile_phone: [{value: '', disabled: true}, [Validators.required, Validators.min(1), Validators.max(9999999999)]],
      referred_document:[''],
      referred_id:['']
    });
  }
  candidateRegister(): any {
    if (this.candidateForm.valid){
      if(this.showReferendInput)
      {
        this.alert.alertConfirm('¿Deseas continúar?',`Esta acción de referido quedará asignada a ${this.candidateForm.controls.referred_id.value.Primer_Nombre} ${this.candidateForm.controls.referred_id.value.Primer_Apellido} y validada por ${this.userName.rrhh.name} y no podrá ser modificada.`).then(res =>{
          if(res.isConfirmed)
          {
            this.candidateForm.controls.referred_id.setValue(this.candidateForm.controls.referred_id.value.rrhh_id);
            this.candidatesService.registerCandidate(this.requestId, this.candidateForm.getRawValue()).subscribe(  (resp) => {
              this.alert.alertSuccess('¡Hecho!', 'Candidato creado con éxito');
              this.dialogRef.close(true);
            });
          }
        })
      }else{
        this.candidatesService.registerCandidate(this.requestId, this.candidateForm.getRawValue()).subscribe(  (resp) => {
          this.alert.alertSuccess('¡Hecho!', 'Candidato creado con éxito');
          this.dialogRef.close(true);
        });
      }
    }
  }
  buttonTextDynamic = 'Registrar';
  getCandidateById(){
     if (this.candidateForm.controls.id_type_id.valid && this.candidateForm.controls.id_number.valid){
      this.candidatesService.candidateById(this.candidateForm.controls.id_type_id.value, this.candidateForm.controls.id_number.value, this.requestId).subscribe( (resp: any) => {
        if (resp && resp.id){
          this.isReferred('falso');
          this.isNewUser = false;
          this.candidateForm.patchValue(resp);
          this.buttonTextDynamic = 'Asignar a Solicitud';
          this.enableForm();
        }else{
          this.isNewUser = true;
          this.enableForm();
          this.buttonTextDynamic = 'Registrar';
        }
     });
    }
  }
  cleanCandidate = false;
  enableForm(){
    this.buttonRegister = true;
    this.cleanCandidate = true;
    this.candidateForm.controls.id_type_id.disable();
    this.candidateForm.controls.id_number.disable();
    this.candidateForm.controls.first_name.enable();
    this.candidateForm.controls.middle_name.enable();
    this.candidateForm.controls.last_name.enable();
    this.candidateForm.controls.second_last_name.enable();
    this.candidateForm.controls.email.enable();
    this.candidateForm.controls.phone.enable();
    this.candidateForm.controls.mobile_phone.enable();
    this.candidateForm.controls.email_2.enable();
    this.candidateForm.untouched;
    this.candidateForm.pristine;
    this.candidateForm.dirty;
  }
  disaleForm(){
    this.buttonRegister = false;
    this.cleanCandidate = false;
    this.candidateForm.controls.id_type_id.enable();
    this.candidateForm.controls.id_number.enable();
    this.candidateForm.controls.first_name.disable();
    this.candidateForm.controls.middle_name.disable();
    this.candidateForm.controls.last_name.disable();
    this.candidateForm.controls.second_last_name.disable();
    this.candidateForm.controls.email.disable();
    this.candidateForm.controls.phone.disable();
    this.candidateForm.controls.mobile_phone.disable();
    this.candidateForm.controls.email_2.disable();
    this.candidateForm.untouched;
    this.candidateForm.pristine;

  }
  cleanCandidateFrom(){
    this.candidateForm.reset();
    this.disaleForm();
  }
  /**
     * Metodo el cual lista dinamicamente los tipos de documentos
     * @author Lina Hueso
     * @return json
     */
   getTypes() {
    this.candidatesService.getIdTypes().subscribe(resp => {
      this.idTypes = resp;

      if(this.candidateForm.controls['id_type_id'].value)
      {
        let json = this.idTypes.find(element => element.id == this.candidateForm.controls['id_type_id'].value);
        this.keyType = json.key;
      }
    }
    );
  }

  /**
     * Metodo el cual setea el nuevo valos del key type segun seleccion del usuario
     * @author Lina Hueso
     * @return string
     */
   selectedIdtype(value) {
    this.keyType = value;
  }

  /**
     * Metodo que muestra u oculta el inpunt de referido
     * ademas de setear nuevas validaciones o quitarlas segun sea el caso
     * @author Carlos Nieto
     *
     */

  isReferred(value)
  {
    this.isReferredValue = value === 'true' ? true : false;
    if(this.isReferredValue)
    {
      this.candidateForm.get('referred_document').setValidators([Validators.required]);
      this.candidateForm.get('referred_id').setValidators([Validators.required]);
      this.candidateForm.get('referred_document').updateValueAndValidity();
      this.candidateForm.get('referred_id').updateValueAndValidity();
    }else{
      this.showReferendInput = false;
      this.candidateForm.controls['referred_document'].setValue('');
      this.candidateForm.controls['referred_id'].setValue('');
      this.candidateForm.get('referred_document').clearValidators();
      this.candidateForm.get('referred_id').clearValidators();
      this.candidateForm.get('referred_document').updateValueAndValidity();
      this.candidateForm.get('referred_id').updateValueAndValidity();
    }
  }

   /**
     * Metodo que consulta por medio de numero de cedula o documento de estados activos
     * @author Carlos Nieto
     * @return json
     */
  getReferredByDocument(){
    if(this.candidateForm.controls.referred_document.value == '' || this.candidateForm.controls.referred_document.value == null)
    {
      this.alert.alertInfo('Alerta','No existen coincidencias con este documento');
      return;
    }
    let data = {
      id_documents : [this.candidateForm.controls.referred_document.value],
      state: true
    }
    this.showReferendInput = false;
    this.getReferredByDocumentSuscription = this.candidatesService.findbyiddocument(data).subscribe( (resp: any) => {
      if(resp.length > 0)
      {
        this.referendList = resp;
        this.showReferendInput = true;
      }else{
        this.alert.alertInfo('Alerta','No existen coincidencias con este documento')
        this.candidateForm.controls['referred_document'].setValue('');
        this.candidateForm.get('referred_document').updateValueAndValidity();
      }
   });

 }

  /**
     * Metodo muestra un alert al usuario de responsabilidad
     * @author Carlos Nieto
     */
   showInfoAlert(event){
     console.log(event);
     
    this.alert.alertInfo('Recuerda',`Esta acción de referido quedará asignada a ${event.Primer_Nombre} ${event.Primer_Apellido} y validada por ${this.userName.rrhh.name} y no podrá ser modificada.`);
   }

   ngOnDestroy(): void {
       this.getReferredByDocumentSuscription.unsubscribe();
   }
}
