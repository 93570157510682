<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>

<h3>Asignar Reclutador</h3>
<hr>
<mat-dialog-content>
    <div  fxLayout="column" fxLayoutAlign=" none" >
        <mat-form-field appearance="fill">
            <mat-label>Reclutador</mat-label>
            <mat-select #recluter (selectionChange)="setRecluter(recluter.value)">
                <mat-option *ngFor="let row of recluters" [value]="row.id">
                    {{row.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-stroked-button type="button" mat-dialog-close>Cancelar</button>
    <button mat-flat-button color="primary" (click)="assign()" [disabled]="!recluterSelected">Asignar</button>
</mat-dialog-actions>