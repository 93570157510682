<div class="row">
  <div class="col-10">
    <h3 class="title" mat-dialog-title>Actualizar usuario de red</h3>
  </div>
  <div class="col-2 d-flex justify-content-end">
    <i class="fi-rr-cross-small" style="cursor:pointer; font-size: 20px; color: #353535;" mat-icon-button mat-dialog-close></i>
  </div>
</div>


<mat-dialog-content class="mat-typography">
  <mat-divider></mat-divider>

  <form [formGroup]="form" class="mt-4">
    <div class="row">
      <div class="col">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Usuario de red</mat-label>
          <input matInput formControlName="network_user" type="text" required />
          <mat-error *ngIf="form.get('network_user').hasError('required')">¡Advertencia! Este campo es requerido</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

</mat-dialog-content>

<mat-dialog-actions align="end" class="mt-4 mb-4 actions" style="padding-right: 0 !important;">
  <button mat-stroked-button color="primary" mat-dialog-close class="me-2 button-dimensions">Cancelar</button>
  <button mat-raised-button color="primary" class=" button-dimensions" (click)="saveData()">Enviar</button>
</mat-dialog-actions>
