import { Component, OnInit } from '@angular/core';
import {TranslatesService} from '../../services/translates.service';

@Component({
  selector: 'app-eliminar-auditoria',
  templateUrl: './eliminar-auditoria.component.html',
  styleUrls: ['./eliminar-auditoria.component.sass']
})
export class EliminarAuditoriaComponent implements OnInit {
  private uuid = '8f87d0e0-92d3-11ee-b9d1-0242ac120003';
  public config: any;

  constructor(
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
  }

}
