import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuditoriaService } from '../../../../services/auditoria.service';
import { Tools } from '../../../../utils/tools.utils';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
  selector: 'app-dialog-eliminacion',
  templateUrl: './dialog-eliminacion.component.html',
  styleUrls: ['./dialog-eliminacion.component.sass']
})
export class DialogEliminacionComponent implements OnInit {
  private uuid = '8f87d0e0-92d3-11ee-b9d1-0242ac120004';
  public config: any;
  formEliminar:FormGroup;

  constructor(
    private fb:FormBuilder,
    @Inject(MAT_DIALOG_DATA) public datos:any,
    private eliminarRef:MatDialogRef<DialogEliminacionComponent>,
    private auditoriaService:AuditoriaService,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
    this.createForm();
  }

  createForm(){
    this.formEliminar = this.fb.group({
      observacion:['',Validators.required],
    });
  }

  eliminar(){
    this.auditoriaService.deleteAuditoria(this.datos.id,this.formEliminar.value).subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202) {
        Tools.swalSuccess(this.config.modal.excellent, this.config.modal.deleteOk);
        this.eliminarRef.close();
      } else {
        Tools.swalError('¡Oops!',this.config.modal.deleteError);
      }
    });
  }

}
