import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InteligenciaService } from 'src/app/modules/calidad/services/inteligencia.service';
import Swal from 'sweetalert2';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
  selector: 'app-crear-formulario',
  templateUrl: './crear-formulario.component.html',
  styleUrls: ['./crear-formulario.component.sass']
})
export class CrearFormularioComponent implements OnInit {
  private uuid = 'f02fc8b4-8ff1-11ee-b9d1-0242ac120002';
  public config: any;

  formularioForm:FormGroup
  constructor(
    public formularioRef:MatDialogRef<CrearFormularioComponent>, @Inject(MAT_DIALOG_DATA) datos:any,
    private fb:FormBuilder,
    private inteligenciaS:InteligenciaService,
    private router:Router,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
  }

  createForm(){
    this.formularioForm = this.fb.group({
      name: ['',[Validators.required]],
      descripcionform: ['',[Validators.required]],
    });
  }

  /**
  * @author Cristian Gallo
  * Función para guardar la inteligencia de negocio
  */
  save(){
    if (this.formularioForm.invalid) {
      return
    }
    this.inteligenciaS.createIntelligence(this.formularioForm.value).subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202) {
        this.router.navigateByUrl(`/mios/calidad/formulario/edit/${resp.respuesta.data.id}`);
        this.formularioRef.close();
        Swal.fire({
          icon:'success',
          title: this.config.confirm.successTitle,
          text: this.config.confirm.successText,
          showConfirmButton:false,
          timer: 2500
        });
      }
    });
  }

}
