import { Component, OnInit, Inject, ViewChild, QueryList } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { MatSelect } from '@angular/material/select';
import { CoachingService } from 'src/app/modules/coaching/services/coaching.service';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-modal-asignaciones',
	templateUrl: './modal-asignaciones.component.html',
	styleUrls: ['./modal-asignaciones.component.sass']
})
export class ModalAsignacionesComponent implements OnInit {

	formGroup: FormGroup;
	//Matriz con la data de campañas
	dataSelectMultipleCampaigns: any[];
	//Matriz con la data de Agentes Dispoboles
	dataAgentesDiponibles: any[] = [];
	//Matriz para agentes Asignados 
	dataAgentesAsignados: any[] = [];

	//agente añadido aravez de la opcion añadior agente
	addAgente: any | undefined;

	//Variables asignadas manejo de mat-list  en la vista 
	@ViewChild('asesoresDisponibles') asesoresDisponibles: MatSelectionList;
	@ViewChild('asesoresAsignados') asesoresAsignados: MatSelectionList;
	@ViewChild('listCampaigns') private listCampaigns: MatSelect;

	jefeInmediato: any = {
		name: '',
		campaign: ''
	};

	
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private coachingService: CoachingService,
		public dialogRef: MatDialogRef<ModalAsignacionesComponent>
	) { }

	ngOnInit(): void {

		this.initForm();
		this.initDataSelects();
		if (this.data.type == 'editar') {
			this.dataAgentesAsignados = this.data.agentesAsignados.agents.filter(filter => filter.type = "loaded")
			if (this.data.agentesAsignados.doc_boss !== undefined && this.data.agentesAsignados.doc_boss !== null && this.data.agentesAsignados.doc_boss !== "") {
				this.jefeInmediato = {
					doc: this.data.agentesAsignados.doc_boss,
					name: this.data.agentesAsignados.name_boss,
					id: this.data.agentesAsignados.id,
					campaings: this.data.agentesAsignados.name_campaign,
					no_campaign: this.data.agentesAsignados.no_campaign
				}
				this.formGroup.get('doc_jefexx').setValue(this.data.agentesAsignados.doc_boss);
				this.formGroup.controls['doc_jefexx'].disable();
			}
			this.cargarAgenetes(false);
			
		}

	}


	/**
	 * Metodo que inicializa el formulario y sus controles de validacion
	 * @author Juan David Guerrero Vargas
	 * @create_at 13/05/2022
	 * @return void   
	 */
	initForm(): void {
		this.formGroup = this.fb.group(
			{
				campaigns: new FormControl('',[Validators.required]),
				doc_jefexx: new FormControl('', [Validators.required]),
				doc_newage: new FormControl('', [Validators.minLength(5)]),
				listagentesDisponibles: new FormControl(''),
				listaAgentesAsignados: new FormControl('')
			}
		);
	}


	/**
	 * Metodo que carga todos los datos de los selectores o campos dentro del formulario
	 * @author Juan David Guerrero Vargas
	 * @create_at 13/05/2022
	 * @return void 
	 */
	initDataSelects(): void {
		this.coachingService.getAllCampaigns().subscribe(
			(params: any[]) => {
				//Se realiza carga de campañas ordenadas alfaveticamente
				this.dataSelectMultipleCampaigns = params.sort(function (x, y) {
					if (x.name < y.name) { return -1; }
					if (x.name > y.name) { return 1; }
					return 0;
				});
				this.dataSelectMultipleCampaigns.filter(filter => filter.rrhh_id = '');
				//Si se abre el modal desde el icono de edicion se cargaran y se se asignaran las campañas ya seleciconadas por el jefe
				if (this.data.type === 'editar') {
					let temp: any[] = [];
					params.forEach(element => {
						if (this.data.agentesAsignados.id_campaign.find(elem => elem === element.id)) {
							temp.push(element)
						}
					});
					this.formGroup.get('campaigns').setValue(temp);
				}
			}
		);
	}

	/**
	 * Metodo que realiza el movimiento de agantes de seccion a seccion segun el tipo de movimiento requerido
	 * @param type:string sipo de movimiento de aganta a realziar (Agregar agente a agentes de grupo -Remover agentes de grupo  a agentes disponibles)
	 * @author Juan David Guerrero Vargas
	 * @create_at 13/05/2022
	 * @return:void   
	 */
	moveAgente(type: string): void {

		if (type === 'add') {
			let agentes = this.formGroup.get('listagentesDisponibles').value
			agentes.forEach(agente => {
				if (!this.dataAgentesAsignados.find(agentefind => agentefind.id == agente.id)) {
					this.dataAgentesAsignados.push(agente);
					this.dataAgentesDiponibles = this.dataAgentesDiponibles.filter(agentefilter => agentefilter.id !== agente.id);
				}
			});
		} else if (type === "remove") {
			let agentes = this.formGroup.get('listaAgentesAsignados').value
			agentes.forEach(agente => {
				if ( !this.dataAgentesDiponibles.find(agentefind => agentefind.id == agente.id)) {
					if(agente.type !=='loaded'){
						this.dataAgentesDiponibles.push(agente);
						this.dataAgentesAsignados = this.dataAgentesAsignados.filter(agentefilter => agentefilter.id !== agente.id);

					}else{
						Swal.fire({
							title: "No se puede reasignar el/los agentes",
							text: "El/los usuario indicado no puede quedar sin un jefe asignado",
							icon: 'warning',
							confirmButtonText: 'Aceptar',
							showCancelButton: false,
	
						});
					}
				}
			});
		}

	}

	/**
	 * Metodo apra cargas los  agentes disponibles por asignacion desde 
	 * @author Juan David Guerrero Vargas  
	   * @create_at 19/05/2022 
	 * @param event:EventClose evento de cierre de selecteor multiple
	 * @return void 
	 */

	cargarAgenetes(event: any): void {


		//Si event es true se abrio el selector
		//si event esta en false se cerro el selector
		if (!event) {
			let campaignsSelection:any[] =[]
			campaignsSelection = this.getMatrizCampaingSelection();
		
			let str_campaigns = '';
			if (this.data.type == "crear" && campaignsSelection.length > 0) {

				campaignsSelection.forEach(campaign => {
					str_campaigns += `campaignIds[]=${campaign.id}&`
				});
				this.coachingService.getAsesoresByCampaign(str_campaigns).subscribe(
					(resp: any) => {
						this.validateAgentesForCampaign(resp);
					}
				);
			} else if (this.data.type == "editar" && this.data.agentesAsignados.id_campaign.length > 0) {
				
				
				campaignsSelection.forEach(campaign => {
					str_campaigns += `campaignIds[]=${campaign}&`
				});

				this.coachingService.getAsesoresByCampaign(str_campaigns).subscribe(
					(resp: any) => {
						this.validateAgentesForCampaign(resp);
					});
			} else {
				this.dataAgentesAsignados = [];
				this.dataAgentesDiponibles = [];
			}
		
		}
	}

	/**
	 * Metodo que obtiene las campañas a usar para la consulta de agentes
	 * @author Juan David Guerrero Vargas
	 * @create_date 15/06/2022  
	 * @returns any[] matriz con ids de las campañas seleccionadas
	 */
	getMatrizCampaingSelection():any[]{
		let campaignsSelection:any[] =[]
		if(this.data.type == "editar"){
			let select = this.formGroup.get('campaigns').value
			if(select.length < 1){

				campaignsSelection = this.data.agentesAsignados.id_campaign
			}else{
				select.forEach(campaign => {
					if(!campaignsSelection.find(finded => finded === campaign.id)){
						campaignsSelection.push(campaign.id)
					}
				});
			}
			
		}else{

			campaignsSelection = this.formGroup.get('campaigns').value;
		}
		return campaignsSelection;
	}

	/**
	 * Metodo que carga los datos del jefe inmeditado segun lo requirea el formulario de editar o de crear
	 * @author Juan David Guerrero Vargas
	 * @create_date 23/05/2022
	 * @return void     
	 */
	getJefeInmediato(): void {
		if (this.data.type !== 'editar') {

			let cedula = this.formGroup.get('doc_jefexx').value
			this.coachingService.getJefeInmediato(cedula).subscribe(
				(resp: any) => {
					if (!resp.respuesta) {
						this.jefeInmediato = resp[0]
						this.excludeAgentesList(cedula);
					} else {
						this.jefeInmediato = {
							name: '',
							campaign: ''
						};
						this.formGroup.get('doc_jefexx').setValue('');
						Swal.fire({
							title: "Usuario no identificado",
							text: "El usuario no tiene los permisos suficientes para realizar está acción.",
							icon: 'warning',
							confirmButtonText: 'Aceptar',
							showCancelButton: false,
	
						});
					}
				}
			);
		}
	}
	/**
	 * Metodo que carga los asesores disponibles segun las campañas seleccionadas
	 * @author Juan David Guerrero Vargas  
	 * @create_date 17/05/22
	 * @return void  
	 */
	getAsesor(): void {
		let document = String(this.formGroup.get("doc_newage").value);
		
		let boss = String(this.formGroup.get('doc_jefexx').value);
		if(document && document !== ""){
			if(boss !== ""  ){
				//Validamos que el No de cedula a consultar no se ni una ceula que ya se encuentre dentro de los agentes asignados 
				//y que sea una cedula diferente del jefe inmediato
				if(!this.dataAgentesAsignados.find((finded) => finded.doc == document) 
				&& !this.dataAgentesAsignados.find((finded) => finded.doc == boss) 
				&& boss !== document){
					
					this.coachingService.getAsesorByDocument(document).subscribe(
						(resp: any) => {
							this.addAgente = resp[0];
						}
					);
				}
			}else{
				if(!this.dataAgentesAsignados.find((finded) => finded.doc == document) ){
					this.coachingService.getAsesorByDocument(document).subscribe(
						(resp: any) => {
							this.addAgente = resp[0];
						}
					);
				}
			}
		}
		
	}

	/**
	 * Metodo que agrega el agente seleccionado desde el buscador por documento
	 * @author Juan David Guerrero Vargas 
	 * @create_date 17/05/22
	 * @return void  
	 */
	agregarAgenteNuevo(): void {

		Swal.fire({
			title: '¿Estás seguro?',
			icon: 'warning',
			html: '<p style="font-size: 17px;"> ¿En verdad deseas asignar a este  colaborador al jefe inmediato ' + this.jefeInmediato.name + '?, recuerda  que esta acción será definitiva y no lo  podrás deshacer</p>',
			showDenyButton: true,
			confirmButtonColor: '#00acc1',
			confirmButtonText: 'Aceptar',
			denyButtonText: `Cancelar`,
		}).then(
			(result) => {
				if (result.isConfirmed) {
					this.formGroup.get('doc_newage').setValue('');
					let agenteNuevo = this.addAgente;
					agenteNuevo.type = "new"
					this.dataAgentesAsignados.push(agenteNuevo);
					this.addAgente = undefined;
				} else {
					this.formGroup.get('doc_newage').setValue('');
					this.addAgente = undefined;
				}
			}
		);


	}

	/**
	 * Metodo que valida si los agesnte de los listados corresponden a una campaña activa y a agentes disponibles
	 * @author Juan David Guerrero Vargas
	 * @param listaAgentesRest:any[] Listado de agentes obtenido mediante peticion u otra forma que representa el istado de agentes dispobiles sin formateo de data 
	 * @create_date 17/05/22
	 * @return void   
	 */
	validateAgentesForCampaign(listaAgentesRest: any[]): void {
		//Si se tienen agentes asignados a jefe, primero se valida que la informacion traida no tenga datso repetidos con los asignados
		if (this.dataAgentesAsignados.length > 0) {
			listaAgentesRest.forEach(
				agenteList => {
					if (this.dataAgentesAsignados.find(finded => finded.id === agenteList.id)) {
						listaAgentesRest = listaAgentesRest.filter(filter => filter.id !== agenteList.id)
					}
				});
			this.dataAgentesDiponibles = listaAgentesRest;
		} else { //En caso de no tener agentes asignados simplemente se asume que todos los obtenidos son dispobibles
			this.dataAgentesDiponibles = listaAgentesRest;
		}

		//Iniciamos validacion de que los agentes en las listas, pertenezcan auna campaña seleccionada, y no haya agentes de campañas distintas
		let campanas = this.formGroup.get('campaigns').value
		//Validamos si hay campañas que comprobar para el listado de agentes
		if (campanas.length > 0) {
			//Si hay datos en la lista de agentes Asignados para validar
			if (this.dataAgentesAsignados.length > 0) {
				//recorremos la lista de agentes asignados
				this.dataAgentesAsignados.forEach(asesorAsignado => {
					//si el id_campaign del asesor en iteracion no se encuentra dentro del arreglo de campañas seleccionadas 
					if (!campanas.find(finded => finded.id === asesorAsignado.id_campaign) && asesorAsignado.type === undefined) {
						//En caso de no ser una campaña activa, este agente se retira de la lista
						this.dataAgentesAsignados = this.dataAgentesAsignados.filter(filter => filter.doc !== asesorAsignado.doc);
					}
				});
			}
			if(this.jefeInmediato.doc){	
				this.excludeAgentesList(String(this.jefeInmediato.doc));
			}
		} else { //en caso de que no hayan campañas seleccionadas, se asume que ningun asesor cumple con los 
			//criterios minimos para mantenerse dentro de las listas
			this.dataAgentesDiponibles = [];
			this.dataAgentesAsignados = [];
		}
	}


	/**
	 * Metodo que marca o desmarca  a todos los elementos seleccionables de los agentes
	 * @param event:MatCheckboxChange varaible con la que determinamos el estado actual de checkbox desde 
	 * @param type:string Variable que contiene el tipo de lista a seleccionar o des seleccionar 
	 */
	selectAll(event: MatCheckboxChange, type: string): void {
		if (type === "disponibles") {
			if (this.asesoresDisponibles.selectedOptions.selected.length < this.dataAgentesDiponibles.length) {
				this.asesoresDisponibles.selectAll();
			} else {
				this.asesoresDisponibles.deselectAll()
			}
		} else if (type === "asignados") {
			if (this.asesoresAsignados.selectedOptions.selected.length < this.dataAgentesAsignados.length) {
				this.asesoresAsignados.selectAll();
			} else {
				this.asesoresAsignados.deselectAll();
			}
		}

	}

	/**
	 * Metodo que realiza la peticion de creacion o actualizaicon segun sea el caso
	 * @author Juan David Guerrero Vargas
	 * @create_date 20/05/22
	 * @return void   
	 */
	createUpdate(): void | boolean {
		let campaigns = this.formGroup.get('campaigns').value;
		let campaignsSend = [];
		if (campaigns.length > 0) {
			campaigns.forEach(campaign => {
				campaignsSend.push(campaign.id)
			});

			if (!this.jefeInmediato || this.jefeInmediato.id === "") {
				Swal.fire({
					title: "Falta seleccionar el jefe inmediato",
					text: "Es necesario ingresar la cedula del jefe inmediato al cual se le van a asignar los agentes",
					icon: 'warning',
					confirmButtonText: 'Aceptar',
					showCancelButton: false,

				});
				return false;
			}

			let asesoresAsignados = this.formGroup.get('listaAgentesAsignados').value

			if (asesoresAsignados.length <= 0) {
				Swal.fire({
					title: "Debe seleccionar al menos un agente asignado para poder registrarlo(s) al jefe inmediato",
					text: "Es necesario ingresar la cedula del jefe inmediato al cual se le van a asignar los agentes",
					icon: 'warning',
					confirmButtonText: 'Aceptar',
					showCancelButton: false,

				});
				return false;
			}
		
			this.asignarAgentes(asesoresAsignados, campaignsSend);
			
		} else {
			Swal.fire({
				title: "Falta seleccionar campañas",
				text: "Es necesario seleccionar al menos una campaña para poder asignar los agentes al jefe inmediato",
				icon: 'warning',
				confirmButtonText: 'Aceptar',
				showCancelButton: false,

			});
			return false;
		}

		
	}

	/**
	 * Metodo que envias los datos apra la asignacion de agentes a jefe inmediato
	 * @author Juan David Guerrero Vargas
	 * @create_date 23/05/2022  
	 * @returns void|boolean en caso de fallo retorna false, y en caso de un proceso exitoso , quedara en void
	 */
	asignarAgentes(asesoresAsignados:any[],campaignsSend:any[] ): void | boolean {
			
			Swal.fire({
				title: '¿Estás seguro?',
				icon: 'warning',
				html: '<p style="font-size: 17px;"> ¿En verdad deseas asignar los colaboradores al jefe inmediato <b>' + this.jefeInmediato.name + '</b>?, recuerda  que esta acción será definitiva y no lo  podrás deshacer</p>',
				showDenyButton: true,
				confirmButtonColor: '#00acc1',
				confirmButtonText: 'Aceptar',
				denyButtonText: `Cancelar`,
			}).then(
				(result) => {
					if (result.isConfirmed) {

						if(this.data.type == "crear"){
							let modelCreate = {
								id_campaign: JSON.stringify(this.getCampaignsModel(asesoresAsignados)),
								id_boss: this.jefeInmediato.id,
								doc_boss: this.jefeInmediato.doc,
								name_boss: this.jefeInmediato.name,
								agents: JSON.stringify(asesoresAsignados),
								ruta:'/mios/coaching/coaching/0/reasignacion'
							};
							this.coachingService.asignarAgentesJefeInmediato(modelCreate).subscribe(
								(resp) => {
									
									
									Swal.fire({
										icon: 'success',
										title: '¡Excelente!',
										html: `<p style="font-size: 17px;" >Se han asignado los colaboradores al jefe inmediato <b>${this.jefeInmediato.name}</b> con éxito</p>`,
										showConfirmButton: false,
										timer: 4000
									});
									this.dialogRef.close();
	
								},
								(error) => {
									Swal.fire({
										icon: 'warning',
										title: 'Error en creacion',
										html: `<p style="font-size: 17px;" >${error.error.error}</p>`,
										showConfirmButton: false,
										timer: 4000
									});
	
	
									//this.dialogRef.close();
								}
							);
						}else if (this.data.type == "editar"){
							let modelUpdate = {
								id_campaign: JSON.stringify(this.getCampaignsModel(asesoresAsignados)),
								agents: JSON.stringify(asesoresAsignados),
								ruta:'/mios/coaching/coaching/0/reasignacion'
							};

							this.coachingService.updateAssignedBoss(this.data.agentesAsignados.id , modelUpdate).subscribe(
								(resp)=>{
									Swal.fire({
										icon: 'success',
										title: '¡Excelente!',
										html: `<p style="font-size: 17px;" >Se han asignado los colaboradores al jefe inmediato <b>${this.jefeInmediato.name}</b> con éxito</p>`,
										showConfirmButton: false,
										timer: 4000
									});
									this.dialogRef.close();
								},
								(error) => {
									Swal.fire({
										icon: 'warning',
										title: 'Error en actualización',
										html: `<p style="font-size: 17px;" >${error.error.error}</p>`,
										showConfirmButton: false,
										timer: 4000
									});
	
	
									//this.dialogRef.close();
								}
							);
						}

					
					}
				}
			);
	}

	/**
	 * Metodo que permite excuir determinado usuario atravez de su cedula de las listas de agentes disponibles o cargados(asignados)
	 * @param cedula:string Cedula del agente a excluir de los arreglos
	 * @author Juan David Guerrero Vargas
	 * @create_date 03/06/2022
	 * @return void   
	 */
	excludeAgentesList(cedula:string):void{
		if(cedula && typeof cedula  == "string" && cedula.trim() !== ""){
			this.dataAgentesAsignados = this.dataAgentesAsignados.filter(filtered => filtered.doc !== cedula  );
			this.dataAgentesDiponibles = this.dataAgentesDiponibles.filter(filtered => filtered.doc !== cedula  );	
		}
	}

	  /** Metodo que permte solo numeros ene l ingreso del input
   * @author Juan David Guerrero Vargas
   * @createdate 2021-01-27
   * @param event evento del keypress del input usuario
   */
	   soloNumeros(event): any {
        let k;
        k = event.charCode;
        return ((k > 47 && k < 58));
    }
	
	/**
	 * Metodo que obtiene un array con los ids de las campañas seleccionadas
	 * @author Juan David Guerrero Vargas
	 * @create_date 13/06/2022  
	 * @param asesores:any[] arreglo con los agentes asignados a guardar
	 * @returns any[]  arregelo de ids con las campañas de lso asesores asignados
	 */
	getCampaignsModel(asesores:any[]):any[] {
		let campanas:any[]=[];
		if(asesores.length  > 0){
			asesores.forEach(asesor => {
				if(campanas.length > 0){
					if(!campanas.find(finded => finded === asesor.id_campaign)){
						campanas.push(asesor.id_campaign)
					}
				}else{
					campanas.push(asesor.id_campaign)
				}
			});
		}
		
		
		return campanas;
	}


}
