<div class="h-100 py-2"> 
    <button
        mat-stroked-button
        color="accent"
        class="w-100"
        (click)="inputFile.click()"
        type="button">
        Adjuntar archivos &nbsp;
        <span class="fi-rr-upload"></span>
    </button>
    <input
        id="inputFile"
        #inputFile
        type="file"
        name="inputFile"
        class="d-none"
        multiple="multiple"
        (change)="addFile()" />
    <div *ngIf="files.length > 0" class="py-1">
        <mat-chip-list>
            <mat-chip
                *ngFor="let item of files"
                (removed)="removeFile(item.file.name)">
                {{ item.file.name }}
                <mat-icon 
                    matChipRemove>
                    cancel
                </mat-icon>
            </mat-chip>
        </mat-chip-list>
    </div>
</div>

