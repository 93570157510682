import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-security-questions',
  templateUrl: './security-questions.component.html',
  styleUrls: ['./security-questions.component.sass']
})
export class SecurityQuestionsComponent implements OnInit {
  form: FormGroup
  questions: any[] = []

  constructor(private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, private authService: AuthService, private dialogRef: MatDialogRef<SecurityQuestionsComponent>) { }

  ngOnInit(): void {
    this.initializeForm()
    this.getQuestionsList()
  }

  /**
 * @author Juan Carlos Alonso
 * @createdate 29-05-2024
 * Método que inicializa el formulario
 */
  initializeForm() {
    this.form = this.formBuilder.group({
      first_question: this.formBuilder.group({
        question_id: ['', [Validators.required]],
        answer: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
        order: [1, []]
      }),
      second_question: this.formBuilder.group({
        question_id: ['', [Validators.required]],
        answer: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
        order: [2, []]
      }),
      third_question: this.formBuilder.group({
        question_id: ['', [Validators.required]],
        answer: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
        order: [3, []]
      }),
      fourth_question: this.formBuilder.group({
        question_id: ['', [Validators.required]],
        answer: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
        order: [4, []]
      }),
      fifth_question: this.formBuilder.group({
        question_id: ['', [Validators.required]],
        answer: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
        order: [5, []]
      }),
      sixth_question: this.formBuilder.group({
        question_id: ['', [Validators.required]],
        answer: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
        order: [6, []]
      }),
      seventh_question: this.formBuilder.group({
        question_id: ['', [Validators.required]],
        answer: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
        order: [7, []]
      }),
      eighth_question: this.formBuilder.group({
        question_id: ['', [Validators.required]],
        answer: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
        order: [8, []]
      }),
      user_id: ['', []],
      rrhh_id: ['', []],
    })

    this.form.valueChanges.subscribe(() => {
      this.updateOptions()
    });

  }

  /**
 * @author Juan Carlos Alonso
 * @createdate 29-05-2024
 * Método que obtiene la lista de preguntas de seguridad
 */
  getQuestionsList() {
    this.authService.getSecurityQuestionsList().subscribe(resp => {
      this.questions = resp
    })
  }

  /**
 * @author Juan Carlos Alonso
 * @createdate 29-05-2024
 * Método que retorna los controles de un grupo de preguntas
 * @param group nombre del grupo del formulario
 */
getQuestionControls(group:string){
  const QuestionGroup = this.form.get(group) as FormGroup
  return QuestionGroup ? QuestionGroup.controls : {}
}

  /**
* @author Juan Carlos Alonso
* @createdate 29-05-2024
* Método que desactiva las opciones de las preguntas que ya han sido seleccionadas, y asi mismo las activa al deseleccionar
*/
  updateOptions() {
    const selectedValues: string[] = [];
    const formControls = this.form.controls;

    for (const key in formControls) {
      if (formControls.hasOwnProperty(key) && key.endsWith('_question')) {
        const questionGroup = formControls[key] as FormGroup;
        const questionIdControl = questionGroup.controls['question_id'];
        if (questionIdControl) {
          selectedValues.push(questionIdControl.value);
        }
      }
    }

    this.questions.forEach(question => {
      question['disabled'] = selectedValues.includes(question.id);
    });
  }


  /**
* @author Juan Carlos Alonso
* @createdate 29-05-2024
* Método que envía y almacena las preguntas de seguridad
*/
  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched()
      return
    }

    const user = new FormData();
    user.append('username', this.data.userName);
    user.append('password', this.data.password);

    this.authService.passwordVerifyLogin(user).subscribe((resp) => {
      if (resp.data?.message === 'OK') {
        var user_data = resp.data.userId
        this.form.get('user_id').setValue(user_data.id)
        this.form.get('rrhh_id').setValue(user_data.rrhh_id)
        this.authService.saveSecurityQuestions(this.form.value).subscribe((resp) => {
          Swal.fire({
            html: `${resp.data?.message}`,
            icon: 'success',
            confirmButtonText: 'Aceptar'
          });
          this.dialogRef.close();
        }, (error) => {
          Swal.fire({
            html: `${error.error.error}`,
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        }
        )
      } else if (resp.error_code == 401) {
        Swal.fire({
          html: `${resp.error}`,
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    });
  }

}
