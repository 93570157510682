<button style="float: right;" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross"></i>
</button>
<form mat-dialog-content class="contentForm" [formGroup]="inteligenciaForm">
  <h2>{{config.labels.inteligenciaDeNegocio}}</h2>
  <mat-divider></mat-divider>
  <div class="mt-3">
    <div class="mb-3" formArrayName="preguntas_activas" *ngFor="let pregunta of inteligenciaQuestions.controls; let i = index">
      <div [formGroupName]="i">
        <p>{{pregunta.get('name').value}}</p>
        <div *ngIf="pregunta.get('tipo_pregunta').value == 'selección_múltiple'">
          <mat-radio-group class="multiple" formControlName="respuesta_usuario" (change)="selectMultiple(pregunta)">
            <mat-radio-button class="example-radio-button"
                              *ngFor="let opcion of pregunta.get('opciones').value"
                              [value]="opcion.id" style="margin-bottom: 1em;">
              {{opcion.name}}
            </mat-radio-button>
            <mat-error *ngIf="pregunta.get('respuesta_usuario').hasError('required')">{{config.labels.respuestaObligatoria}}</mat-error>
          </mat-radio-group>
          <mat-form-field appearance="fill" [hidden]="true">
            <mat-label>{{config.labels.cual}}</mat-label>
            <input matInput formControlName="respuesta_usuario_otra">
            <mat-error *ngIf="pregunta.get('respuesta_usuario_otra').hasError('required')">{{config.labels.respuestaObligatoria}}</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="pregunta.get('tipo_pregunta').value == 'respuesta_corta'">
          <mat-form-field appearance="fill">
            <mat-label>{{config.labels.respuesta}}</mat-label>
            <input matInput formControlName="respuesta_usuario">
            <mat-error *ngIf="pregunta.get('respuesta_usuario').hasError('required')">{{config.labels.respuestaObligatoria}}</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="pregunta.get('tipo_pregunta').value == 'escala_lineal'">
          <mat-radio-group aria-label="lineal" formControlName="respuesta_usuario">
            <mat-radio-button *ngFor="let escala of pregunta.get('escalas').value"
                              [value]="escala">
              {{escala}}
            </mat-radio-button>
            <mat-error *ngIf="pregunta.get('respuesta_usuario').hasError('required')">{{config.labels.respuestaObligatoria}}</mat-error>
          </mat-radio-group>
        </div>
        <div *ngIf="pregunta.get('tipo_pregunta').value == 'lista_desplegable'">
          <mat-form-field appearance="fill">
            <mat-label>{{config.labels.opciones}}</mat-label>
            <mat-select multiple formControlName="respuesta_usuario">
              <mat-option *ngFor="let opcion of pregunta.get('opciones').value"
                          [value]="opcion.id">
                {{opcion.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="pregunta.get('respuesta_usuario').hasError('required')">{{config.labels.respuestaObligatoria}}</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="pregunta.get('tipo_pregunta').value == 'fecha'">
          <mat-form-field appearance="fill">
            <mat-label>{{config.labels.fecha}}</mat-label>
            <input matInput [matDatepicker]="fechaPregunta" formControlName="respuesta_usuario" (dateChange)="cambioFecha(pregunta)">
            <mat-datepicker-toggle matSuffix [for]="fechaPregunta">
              <mat-icon matDatepickerToggleIcon><span class="fi-rr-calendar"></span></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #fechaPregunta></mat-datepicker>
            <mat-error *ngIf="pregunta.get('respuesta_usuario').hasError('required')">{{config.labels.respuestaObligatoria}}</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="pregunta.get('tipo_pregunta').value == 'hora'">
          <mat-form-field appearance="fill">
            <mat-label>{{config.labels.hora}}</mat-label>
            <input matInput type="time" formControlName="respuesta_usuario">
            <mat-error *ngIf="pregunta.get('respuesta_usuario').hasError('required')">{{config.labels.respuestaObligatoria}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 text-center mb-4 mt-4">
    <button class="col-3 btn-cancelar" mat-stroked-button (click)="inteligenciaRef.close()">
      {{config.labels.cancelar}}
    </button>
    <button class="col-3" mat-raised-button color="primary" (click)="sendRequest()">
      {{config.labels.enviarRespuesta}}
    </button>
  </div>
</form>
