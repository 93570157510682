<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<h3 mat-dialog-title>Agregar candidato</h3>
<hr>
<mat-dialog-content >
      <p>Seleccione los candidatos a cargar a este formador</p>
      
      <mat-selection-list #candidateSelected>
          <mat-list-option *ngFor="let candidate of candidates" [selected]="candidate.selected" (click)="pushArray(candidateSelected)" [value]="candidate.id">
              {{candidate.fullname}} 
          </mat-list-option>
      </mat-selection-list>
  

</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-raised-button  color="primary" (click)="assignCandidates()" [disabled]="candidatesSelected.length === 0">Asignar</button>
</mat-dialog-actions>
