import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { SPEECH_URL } = environment;

@Injectable({
  providedIn: 'root'
})
export class NoDirigidoService {
  constructor(private httpClient: HttpClient) { }

  /**
   * Metodo que retorna los errores generados por HTTP. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * Metodo obtener listado de los audios cargados paginados
   * @author Yeison Sepulveda
   * @createdate 2024-05-02
   * @param paginator configuración de paginación (pageIndex).
   * @param per_page cantidad por pagina
  */
  getAudios(paginator: any, ids: any[]): Observable<any> {
    const body = { ids: ids};
    return this.httpClient.post<any>(`${SPEECH_URL}uploadAudios/paginate/${paginator.pageSize}?page=${(paginator.pageIndex)}`, body).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Cargar los audios al storage del back
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param audios array de audios cargados
  */

  uploadAudios(formData: any): Observable<any> {
    console.log('data',formData)
    return this.httpClient.post<any>(`${SPEECH_URL}uploadAudios/store`, formData).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Descargar reporte vista no dirigido
   * @author Yeison Sepulveda
   * @createdate 2024-04-04
   * @param filters json de la informacion
  */
  downloadReport(filters){
    return this.httpClient.post<any>(`${SPEECH_URL}uploadAudios/downloadReportLocal`,filters, {responseType: 'blob' as 'json'}).pipe(
      catchError(this.handleError)
    );
  }
}