import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SableComponent } from './sable.component';
import { GestorComponent } from './modules/gestor_descarga/gestor.component';
import { CrearComponent } from './modules/crear_descarga/crear.component';
import { AdminServersComponent } from './modules/admin-servers/admin-servers.component';
import { ComandosPageComponent } from './modules/comandos-page/comandos-page.component';

const routes: Routes = [
  { path: '', component: SableComponent },
  { path: 'gestor_descarga', component: GestorComponent },
  { path: 'crear_descarga', component: CrearComponent },
  { path: 'admin_servers', component: AdminServersComponent },
  { path: 'commands', component: ComandosPageComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SableRoutingModule {

}