import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NotesService} from '../../../services/rest/notes.service';
import {AlertsService} from '../../../../../shared/alerts/alerts.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-update-notes',
  templateUrl: './update-notes.component.html',
  styleUrls: ['./update-notes.component.sass']
})
export class UpdateNotesComponent implements OnInit {

  noteValue: any;
  name: any;
  notasForm: any;
  noteId: any;

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notesService: NotesService,
    private alertsService: AlertsService,
    public dialogRef: MatDialogRef<any>,
  ) {
    this.noteValue = this.data['value']['note'];
    this.noteId = this.data['value']['id'];
  }

  ngOnInit(): void {
    this.formControl();
  }
  /**
   * @author Gabriel Garcia
   * @createddate 2022-10-29 
   * Metodo para definición de los controles del formulario
   */
  formControl(): void {
    this.notasForm = new FormGroup({
      anotacion: new FormControl(this.noteValue, [Validators.required]),
    });
  }
  /**
   * @author Gabriel Garcia
   * @createddate 2022-10-29 
   * Metodo para actualizar una nota
   * @param {any} notasForm Valores capturados de formulario de notas 
   * @returns Json
   */
  update(notasForm: any) {
    const body = {
      note: notasForm.value.anotacion,
    };
    this.notesService.update(this.noteId, body).subscribe(response => {
      this.alertsService.alertSuccessWithoutButton('Excelente', 'Nota Actualizada');
      this.dialogRef.close();
    });
  }
  /**
   * @author Gabriel Garcia
   * @createddate 2022-10-29 
   * Metodo para cancelar la edición de la nota
   */
  opcionCancelar(){
    this.alertsService.alertWarning('¿Estás seguro?','¿Estás seguro de que <b>deseas cancelar</b> este proceso? Recuerda que la información se borrará automáticamente si realizas esta acción.').then((result)=>{
      if(result.isConfirmed){
      this.dialogRef.close();
      }
    });
  }
  
}
