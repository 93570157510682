<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross"></i>
</button>

<h3>Registrar estado</h3>
<hr>
<mat-dialog-content fxLayout="column">

    <mat-form-field appearance="fill">
      <mat-label>Estado</mat-label>
      <mat-select #state (selectionChange)="selectState(state.value)">
        <mat-option value="Aprobado">Aprobado</mat-option>
        <mat-option value="Rechazado">Rechazado</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf = "selectedState == 'Rechazado'">
      <mat-label >Causa</mat-label>
      <mat-select  #typification (selectionChange)="selectTypification(typification.value)">
        <mat-option value="Documento incompleto">Documento incompleto </mat-option>
        <mat-option value="Falsificación en documento">Falsificación en documento</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Comentarios</mat-label>
      <textarea matInput placeholder="Comentarios..." [(ngModel)]="comment"></textarea>
    </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-stroked-button type="button" mat-dialog-close>Cancelar</button>
    <button mat-raised-button color="primary" (click)="register()" [disabled]="selectedState == ''">Registrar</button>
</mat-dialog-actions>
