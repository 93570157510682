import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { NoveltiesService } from 'src/app/modules/nomina/services/novelties.service';

@Component({
  selector: 'app-novedades-view',
  templateUrl: './novedades-view.component.html',
  styleUrls: ['./novedades-view.component.sass']
})
export class NovedadesViewComponent implements OnInit {
  dataInfo: any;
  public realTime: string;
  public scheduleTime: string;
  public endTime: string;
  public noveltyDetailHeight: number;

  public showJustifyForm: boolean;
  public showJustifyInfo: boolean;
  public showInput: boolean;

  constructor(private noveltiesService: NoveltiesService, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.noveltiesService.getEventInfoByPayrollNoveltyId(this.data.data.novelty_user_id, this.data.data.id).subscribe(resp => {
      this.dataInfo = resp.data;
      this.realTime = moment(resp.data.real_start_time).locale('es').format('hh:mm a').toString();
      this.scheduleTime = moment(`${ resp.data.schedule_info.schedule_date } ${ resp.data.schedule_info.scheduled_start_time }`).locale('es').format('hh:mm a').toString();
      this.endTime = moment(`${ resp.data.schedule_info.schedule_date } ${ resp.data.schedule_info.scheduled_end_time }`).locale('es').format('hh:mm a').toString();

      if (this.dataInfo.validForMoreResponses) {
        this.showJustifyForm = true;
      }
      this.showJustifyInfo = true;
      this.changeDetailHeight(this.dataInfo);

    });
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-04-15
   * Metodo para asignacion de detalle en primera instancia
   */
  changeDetailHeight(novelty): void {
    if (novelty.responseFirstInstance && (novelty.responseFirstInstance.justify || novelty.responseFirstInstance.response)) {
      this.noveltyDetailHeight = 240;
    }
  }

}
