<i class="fi-rr-cross-small" style="cursor: pointer; float: right; font-size: 32px" mat-icon-button mat-dialog-close></i>
<div class="row p-4">
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <h2 class="text-center">Asignación masiva de exámenes médicos</h2>
      </div>
      <div class="col-12 mt-4">
        <div class="row d-flex justify-content-center">
          <div class="col-6">
            <button mat-raised-button color="ligth" class="mr-2 myButton1 w-100" (click)="downloadTemplate()">
              Descargar plantilla <span class="fi-rr-download"></span>
            </button>
          </div>
          <div class="col-6">
            <input hidden #fileInput (change)="onChangeFile($event)" name="file" type="file" id="file" size="60000"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
            <button mat-raised-button type="button" color="primary" (click)="fileInput.click()"
              class="ml-2 myButton2 w-100">
              Cargar plantilla <span class="fi-rr-upload"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions class="d-flex justify-content-center mt-5">
    <button mat-stroked-button mat-dialog-close class="w-50 mt-1">
      Cancelar
    </button>
  </div>

</div>
