import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { TemplatesService } from '../../../services/templates.service';
import { TagsNewComponent } from '../tags-new/tags-new.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.sass']
})
export class TagsListComponent implements OnInit {
  TagsColumns: string[] = ['actions', 'key', 'description', 'contrato', 'origin'];
  TagsSource: any;
  // Paginate
  @ViewChild(MatPaginator) paginator: MatPaginator;
  page = 1;
  length = 0;
  pageSize = 5;
  pageSizeOptions: number[] = [5, 15, 25, 50, 100];
  // Filters
  contractType = new FormControl();
  contractTypes: any[] = [];
  contractTypeSelected: number;
  contractOrigin = new FormControl();
  contractOrigins: any[] = [];
  contractOriginSelected: number;

  constructor(
    private templateService: TemplatesService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getTags();

    this.templateService.getTemplates().subscribe((response: any) => {
      this.contractTypes = response;
    });

    this.templateService.getOrigins().subscribe((response: any) => {
      this.contractOrigins = response;
    });

    // Filters
    this.contractType.valueChanges.subscribe(resp => {
      this.resetPaginator();
      this.contractTypeSelected = resp;
      this.getTags();
    });

    this.contractOrigin.valueChanges.subscribe(resp => {
      this.resetPaginator();
      this.contractOriginSelected = resp;
      this.getTags();
    });
  }

  getTags() {
    const body = {
      perPage: this.pageSize || 5,
      page: this.page || 1,
      contract_type_id: this.contractTypeSelected,
      origin: this.contractOriginSelected,
    };
    this.templateService.getTags(body).subscribe((resp: any) => {
      if (resp.data.length === 0) {
        this.TagsSource = null;
      } else {
        this.TagsSource = resp.data;
      }
      this.length = resp.total;
    });
  }

  onPageChange(event: any) {
    this.page = event.pageIndex + 1;
    this.pageSize = event.pageSize;

    this.getTags();
  }

  resetPaginator(): void {
    this.length = 0;
    this.pageSize = 5;
    this.page = 1;
    if (this.paginator) {
      this.paginator.firstPage();
    }
  }

  resetFilters() {
    this.contractType.setValue(null);
    this.contractOrigin.setValue(null);

    this.contractTypeSelected = null;
    this.contractOriginSelected = null;

    this.getTags();
  }

  downloadTagsReport() {
    const body = {
      perPage: this.pageSize || 5,
      page: this.page || 1,
      contract_type_id: this.contractTypeSelected,
      origin: this.contractOriginSelected,
    };
    this.templateService.getTagsReport(body).subscribe((resp: Blob) => {
      // Crea un Blob URL para el objeto Blob
      const blobUrl = URL.createObjectURL(resp);

      // Crea un elemento <a> oculto para iniciar la descarga
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = blobUrl;
      a.download = 'contract_template_tags.xlsx';
      document.body.appendChild(a);

      // Inicia la descarga
      a.click();

      // Limpia el elemento <a> y libera el Blob URL
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(a);
    });
  }

  newTag() {
    const dialogRef = this.dialog.open(TagsNewComponent, {
      width: '800px',
      data: {
        tag: null,
        contractOrigins: this.contractOrigins
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) this.ngOnInit();
    });
  }

  onClickEditTag(tag: any): void {
    this.templateService.getTag(tag.id).subscribe(res => {
      this.dialog.open(TagsNewComponent, {
        width: '800px',
        data: {
          tag: res,
          contractOrigins: this.contractOrigins
        }
      }).afterClosed().subscribe(res => {
        if (res) this.getTags();
      });
    });
  }
}