import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { delay } from 'rxjs/operators';
import { NoveltiesService } from 'src/app/modules/nomina/services/novelties.service';
import { VacationsService } from 'src/app/modules/nomina/services/rest/vacations.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-vacations-view',
  templateUrl: './vacations-view.component.html',
  styleUrls: ['./vacations-view.component.sass']
})
export class VacationsViewComponent implements OnInit {
  dataInfo: any;
  public realTime: string;
  public scheduleTime: string;
  public endTime: string;
  public noveltyDetailHeight: number;

  public showJustifyForm: boolean;
  public showJustifyInfo: boolean;
  public showInput: boolean;
  availableDays = 0;
  businessDays = 0;
  public validDates: boolean;

  constructor(    @Inject(MAT_DIALOG_DATA) public data: any,
  private alert: AlertsService,
  public dialog: MatDialog,
  private vacation: VacationsService,
  private noveltiesService: NoveltiesService,) { }

  ngOnInit() {
    this.vacation.getInfoVacationId(this.data.data.idHoliday).subscribe(resp => {
      this.dataInfo = resp.data;
      this.dataInfo.novelty_type.key =  (this.dataInfo.novelty_type.key < 10) ? `0${this.dataInfo.novelty_type.key}` : this.dataInfo.novelty_type.key;
      this.showJustifyInfo = true;
      this.changeDetailHeight(this.dataInfo);
      this.getCalendarWithAvailableDays();
    });

  }
    /**
   * @author Daniel Dominguez 
   * @createdate 2021-04-27
   * Metodo para asignacion de detalle en primera instancia
   */
     changeDetailHeight(novelty): void {
      if (novelty.responseFirstInstance && (novelty.responseFirstInstance.justify || novelty.responseFirstInstance.response)) { this.noveltyDetailHeight = 240; }
    }

        /**
   * @author Javier Castañeda
   * @createdate 2021-05-11
   * Metodo para obtener los dias disponibles y los solicitados
   */
  getCalendarWithAvailableDays(): void {
    let startDate = this.dataInfo?.start_date;
    let endDate = this.dataInfo?.end_date;
    this.noveltiesService.getAvailableDaysForVacationsByUser({ startDate: startDate, endDate: endDate, rrhh_id: this.dataInfo?.rrhh_id}).pipe( delay(1000)).subscribe((respDates) => {
      this.validDates = respDates.data.validDates;
      this.availableDays = respDates.data.availableDays < 0 ? 0 : respDates.data.availableDays;
      this.businessDays = respDates.data.businessDays;
    });
  
  }
}
