<div class="row">
  <br>
  <mat-form-field class="col-2" appearance="fill">
    <mat-label>Tipos de contrato</mat-label>
    <mat-select [formControl]="contractType">
      <mat-option *ngFor="let item of contractTypes" [value]="item?.contract_type_id">{{item?.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-2" appearance="fill">
    <mat-label>Versión</mat-label>
    <input type="text" matInput [formControl]="templateVersion">
    <mat-error *ngIf="templateVersion.hasError('nonNegative')">Solo se permiten números sin signo.</mat-error>
  </mat-form-field>

  <mat-form-field class="col-2" appearance="fill">
    <mat-label>Fecha</mat-label>
    <input matInput [matDatepicker]="picker" [formControl]="templateDate">
    <mat-datepicker-toggle matSuffix [for]="picker">
      <span matDatepickerToggleIcon class="fi-rr-calendar" style="font-size: 22px !important;"></span>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error *ngIf="templateDate.hasError('futureDate')">Solo se permiten fechas previas.</mat-error>
  </mat-form-field>

  <mat-form-field class="col-2" appearance="fill">
    <mat-label>Estado</mat-label>
    <mat-select [formControl]="templateStatus">
      <mat-option value = "0"> Inactivo </mat-option>
      <mat-option value = "1"> Activo </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="col-1 mr-15 clear">
    <button mat-raised-button class="btnClear" (click)="resetFilters()">
        <i class="fi fi-rr-refresh"></i>
    </button>
  </div>

  <br>
</div>
<p *ngIf="HistorySource && HistorySource.length === 0">No se encontraron resultados con los criterios definidos,
  inténtalo con otros criterios de búsqueda</p>
<div class="row" *ngIf="HistorySource && HistorySource.length > 0">
    <br>
  <table mat-table [dataSource]="HistorySource" class="mat-elevation-z8">

    <!-- Name Column -->
    <ng-container matColumnDef="updated_at">
      <th  class="start" mat-header-cell *matHeaderCellDef> Fecha </th>
      <td mat-cell *matCellDef="let element"> {{element.created_at_formatted}} </td>
    </ng-container>

    <!-- Version Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Nombre </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Estado </th>
      <td mat-cell *matCellDef="let element"> {{ element.status  === 1 ? 'Activo' : 'Inactivo' }} </td>
    </ng-container>

    <!-- Version Column -->
    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef> Version </th>
      <td mat-cell *matCellDef="let element"> {{element.version }} </td>
    </ng-container>

    <!-- Responsable Column -->
    <ng-container matColumnDef="responsable">
      <th mat-header-cell *matHeaderCellDef> Responsable </th>
      <td mat-cell *matCellDef="let element"> {{element.responsable}} </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th class="end" mat-header-cell *matHeaderCellDef> Acciones </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button
                (click)="upgrade(element.contract_template_id)"
                matTooltip="Activar ésta plantilla">
          <i class="fi fi-rr-power" [ngClass]="getStatusIconClass(element.status)"></i>
        </button>
        <button mat-icon-button
                (click)="preview(element.contract_template_id)"
                matTooltip="Previsualizar plantilla">
          <span  class="fi-rr-eye"></span>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="HistoryColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: HistoryColumns;"></tr>
  </table>

  <mat-paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="onPageChange($event)"
  ></mat-paginator>

  <br><br><br><br>
</div>

