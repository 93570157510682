<mat-dialog-content class="padding-mat-dialog-content" style="padding-bottom: 10px;">
  <i class="fi-rr-cross-small" style="float: right; font-size: 32px; color: #353535;" mat-icon-button
      mat-dialog-close></i>
  <div id="containerViewReport" class="avoid-page-break" #containerViewReport>
    <h3 mat-dialog-title>Detalle de la Conversación</h3>
    <mat-divider class="w-100"></mat-divider>

    <mat-expansion-panel #caseDetails closed>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p>Detalles del Caso</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 case-details" style="font-size: 14px;">
            <p><strong>Número de Caso:</strong> {{ chatData?.case_number || 'No disponible' }}</p>
            <p><strong>Proceso:</strong> {{ chatData?.process || 'No disponible' }}</p>
            <p><strong>Teléfono:</strong> {{ chatData?.phone || 'No disponible' }}</p>
            <p><strong>Estado:</strong> {{ chatData?.state || 'No disponible' }}</p>
            <p *ngIf="uniqueAgentNames?.length > 0"><strong>Asesores involucrados:</strong></p>
            <ul *ngIf="uniqueAgentNames?.length > 0">
              <li *ngFor="let name of uniqueAgentNames">{{ name }}</li>
            </ul>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel #conversation closed>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p>Conversación</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row">
        <div class="col-md-12" *ngFor="let detail of chatData?.chat_details || []; let i = index">
          <p style="font-size: 14px;">
            <button class="btn btn-custom rounded-circle p-2"
              style="width: 26px; height: 26px; background-color: #2cabbc; border-color: #2cabbc; display: inline-flex; align-items: center;">
              <i class="fi-sr-user" style="color: white; font-size: 10px;"></i>
            </button>
            <strong style="vertical-align: middle;" [matTooltip]="detail?.tooltip">
              {{ detail?.answered_by ? detail?.agent_name : 'Cliente (' + chatData?.phone + ')' }}
            </strong>
            <span style="vertical-align: middle;"> ({{ detail?.entry_date_time }}): </span>
            <span>{{ detail?.content }}</span>
          </p>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</mat-dialog-content>

<div class="col-md-12 pt-0">
  <div class="row py-3 d-flex justify-content-end">
      <div class="col-md-4">
          <button type="button" mat-raised-button color="primary" class="w-100"
              style="height: 48px; font-weight: 600;" (click)="onClickPrintPdf()">Descargar informe</button>
      </div>
  </div>
</div>
