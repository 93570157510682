import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { UsersService } from 'src/app/modules/ciu/services/rest/users.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.sass']
})
export class ForgotPasswordComponent implements OnInit {

  public passForm: FormGroup;
  public subscription : Subscription
  public documentList : any;
  public selectedValue: string;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService : AuthService,
    private dialogRef:MatDialogRef<ForgotPasswordComponent>,
    private userService : UsersService,


    ) { }

  ngOnInit(): void {
    this.formControl();
    this.getDocumentList();
  }

get error(): any { return this.passForm.controls; }

/**
   * @author Carlos Nieto
   * @createdate 2021-12-07
   * Metodo que crea el formGroup con cada campo y su respectiva validacion
   */
 formControl(): void{

    this.passForm = new FormGroup({
      id_type_id: new FormControl('', [Validators.required]),
      id_number: new FormControl('', [Validators.required,Validators.pattern('^([0-9])*$'),Validators.maxLength(15), Validators.minLength(5)])
    });

}
getDocumentList(): void{
  this.userService.getIdTypes().subscribe(
      response => {
       this.documentList = response;
      }
  );
}

  /**
   * @author Carlos Nieto
   * @createdate 2021-12-07
   * @param passForm Formulario diligenciado con el campo email an verifyEmail
   * Metodo que envia el formulario al servicio
   */
  sendEmail(passForm): void{
    this.subscription = this.authService.validateEmail(passForm.value).subscribe(
      response => {
        if (response.data.response === 'OK') {
           Swal.fire({
            html: `Por favor revisa tu correo ${response.data.mail}`,
            icon: 'success',
            title : 'Enviado',
            confirmButtonText: 'Aceptar'
          });
          this.dialogRef.close();

        }
      }
    );
  }


  /**
   * @author Carlos Nieto
   * @createdate 2021-12-07
   * Metodo que regula las extenciones de los correos ingresados al formulario
   */
   verifyEmail(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (/@hotmail/.test(control.value)) {
        return { hasWrongEmail: { value: control.value } };
      }
      else if (/@gmail/.test(control.value)) {
        return { hasWrongEmail: { value: control.value } };
      }
      else if (/@outlook/.test(control.value)) {
        return { hasWrongEmail: { value: control.value } };
      }
      else if (/@yahoo/.test(control.value)) {
        return { hasWrongEmail: { value: control.value } };
      }
      else if (/@icloud/.test(control.value)) {
        return { hasWrongEmail: { value: control.value } };
      }
      return null;
    };
  }
}
