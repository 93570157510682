import { Component, OnInit, Inject } from '@angular/core';
import { MedicaltestService } from '../../../services/medicaltest.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CreateMedicalTestComponent } from '../create-medical-test/create-medical-test.component';
import { MedicalTestsComponent } from '../medical-tests/medical-tests.component';
import { saveAs as importedSaveAs } from "file-saver";
import { AuthService } from 'src/app/core/services/rest/auth.service';

@Component({
  selector: 'app-medical-test-action',
  templateUrl: './medical-test-action.component.html',
  styleUrls: ['./medical-test-action.component.sass']
})
export class MedicalTestActionComponent implements OnInit {

  status:any;
  candidate:any;
  medicalTest:any;
  attachment:any;
  statusOptions:any = [];
  userData;
  constructor(private medicalService:MedicaltestService,private alertServices:AlertsService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<MedicalTestActionComponent>,
              private dialog:MatDialog,
              private authService : AuthService,
              private dialogRefCandidateTestsList:MatDialogRef<MedicalTestsComponent>
             ) {
                this.candidate = this.data.candidate;
                this.medicalTest = this.data.medicalTest;


              }

  ngOnInit(): void {
    this.userData = this.authService.getUser();
    
    this.status = this.medicalTest.status;
    this.attachment = this.medicalTest.attachment;
    this.medicalService.getStatus(`medical/list/status`).subscribe(res=>{
      this.statusOptions= res
    })
  }
  saveStatus(){
    this.alertServices.alertConfirm('¿Estas seguro que desea asignar el estado seleccionado?').then( e => {
      if(e.isConfirmed){
        this.medicalService.changeStatus(this.medicalTest.id,this.status,this.userData.rrhh_id).subscribe( (e:any) => {
            this.alertServices.alertSuccess('¡Hecho!',e);
                if(this.status == 34){
                  this.alertServices.alertConfirm('¿Deseas agendar nuevamente este exámen médico?').then( (re) => {
                    if(re.isConfirmed){

                      this.createMedicalTest();
                    }
                    if(re.isDenied){
                      this.dialogRef.close(true);
                    }
                  })
                }
                else{
                  this.dialogRef.close(true);
                }


        });
      }
    } )
  }


  createMedicalTest(){
    this.medicalTest.date = null;
    const dialogCreateMedicalTests = this.dialog.open( CreateMedicalTestComponent ,{
      data:{
        'candidate':this.candidate,
        'medicalTest':this.medicalTest
      },
      minWidth:'700px',
    });
    dialogCreateMedicalTests.afterClosed().subscribe( e => {
      this.dialogRef.close(true);
    })
  }

  onFileSelected(files) {
    this.medicalService.uploadAttachment(this.medicalTest.id,files[0]).subscribe( e => {
      this.attachment = e;
      this.alertServices.alertSuccess('¡Cargado!', 'El exámen médico se ha cargado correctamente');
    })

  }

  downloadAttachment(){
      this.medicalService.downloadAttachment(this.medicalTest.id).subscribe( (e) => {
        importedSaveAs(e,'examen_medico');

      })
  }


}
