import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourcesCreateComponent } from './resources-create/resources-create.component';
import { ResourcesMainComponent } from './resources-main/resources-main.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { ResourcesRulesComponent } from './resources-rules/resources-rules.component';
import { DaysPipe } from '../bookings/booking-create/days.pipe';
import { WeekDaysPipe } from './resources-rules/week-days.pipe';
import { ResourcesTypesComponent } from './resources-types/resources-types.component'




@NgModule({
  declarations: [ResourcesCreateComponent, ResourcesMainComponent, ResourcesRulesComponent, WeekDaysPipe, ResourcesTypesComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule
  ],
  providers: [ DaysPipe],
})
export class ResourcesModule { }
