<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>

<h3 mat-dialog-title>Crear solicitud</h3>
<hr>
<mat-dialog-content class="modal-crear-solicitud">

    <div class="modal-crear-solicitud">
        <div class="row">
            <mat-form-field appearance="fill" fxFlex="40">
                <mat-label>Tipo de Solicitud</mat-label>
                <mat-select [(ngModel)]="tipoSeleccionado" name="tipoSolicitud" (ngModelChange)="filtrarTipo()"
                    [disabled]="tipoSeleccionado">
                    <mat-option *ngFor="let tipo of tipos" [value]="tipo">
                        {{tipo.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="40" *ngIf="tipoSeleccionado">
                <mat-label>Cargo que buscas</mat-label>
                <input type="text" placeholder="Cargo que buscas" matInput [formControl]="myControl"
                    [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"
                    (optionSelected)="optionSelected($event)">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{ option.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div *ngIf="formSection[0].fields != null">
            <div class="mb-3">
                <div fxLayout="row" fxLayoutGap="20px" style="padding: 0 23px;">
                    <h3> Politicas especiales</h3>
                </div>
    
                <form [formGroup]="formGroup" class="row" style="padding: 0 23px;">
                    <div class="col-4">
                        <mat-form-field appearance="fill" style="width: 100%;">
                            <mat-label>Horas semanales</mat-label>
                            <input matInput type="text" formControlName="hoursPerWeek">
                        </mat-form-field>
                    </div>
                    <div class="col-8">
                        <div class="row">
                            <div class="col-6">
                                <div appearance="fill" fxFlex=50>
                                    <mat-checkbox class="example-margin" (click)="hasComition()"
                                        formControlName="hasComitionFrom">¿Tiene
                                        comisión?</mat-checkbox>
                                </div>
                            </div>
                            <div class="col-6">
                                <div appearance="fill" fxFlex=50 *ngIf="comition">
                                    <mat-checkbox class="example-margin" (click)="hasSpecialComition()"
                                        formControlName="hasSpecialComitionFrom">¿Tiene comisión especial?</mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="specialComition">
                        <mat-form-field appearance="fill" style="width: 100%;">
                            <mat-label>Detalles de la comisión especial</mat-label>
                            <textarea class="col-12" matInput placeholder="Describe los detalles de la comisión."
                                formControlName="specialComitionDetails"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-checkbox class="example-margin" formControlName="comprehensiveSalary">¿Tiene salario integral?
                        </mat-checkbox>
                    </div>
                    <div class="col-4">
                        <mat-checkbox class="example-margin" (click)="hasboification()"
                            formControlName="hasBonificationFrom">¿Tiene bonos?</mat-checkbox>
                    </div>
                    <div class="col-12" *ngIf="boification">
                        <mat-form-field appearance="fill" style="width: 100%;">
                            <mat-label>Detalles de la bonificación</mat-label>
                            <textarea matInput placeholder="Describe los detalles de la bonificación..."
                                formControlName="bonificationDetailsrom"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <mat-form-field style="width: 100%;" appearance="fill">
                            <mat-label>Finalidad</mat-label>
                            <textarea matInput placeholder="Describe la finalidad..."
                                formControlName="finalidad"></textarea>
                        </mat-form-field>
                    </div>
                </form>
            </div>
            <dynamic-form-2 [sections]="{sections:formSection, app:'bpms'}" (denyForm)="close($event)"
                (submitForm)="createRequest($event)"></dynamic-form-2>
        </div>
    </div>
</mat-dialog-content>