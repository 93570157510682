import { AprobarSolcitudComponent } from './../aprobar-solcitud/aprobar-solcitud.component';
import { DescargaComponent } from './descarga/descarga.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SolicitudesService } from '../../../../services/rest/solicitudes.service';
import { DestroyComponentService } from '../../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import { MatDialog } from '@angular/material/dialog';
import { Solicitud } from '../../../Class/solicitud';
import { CrearSolicitudComponent } from '../crear-solicitud/crear-solicitud.component';
import { DatePipe } from '@angular/common'
import { ProcesarSolicitudComponent } from '../procesar-solicitud/procesar-solicitud.component';

@Component({
  selector: 'app-historial-aprobador',
  templateUrl: './historial-aprobador.component.html',
  styleUrls: ['./historial-aprobador.component.sass']
})
export class HistorialAprobadorComponent implements OnInit {

  length;
  rol               = 3;
  pageSize          = 5;
  page              = 1;
  isPage            = 1;
  pageSizeOptions   : number[] = [5, 10, 25, 100];
  newRolComponent   : boolean = false;
  requestStates     : any[]
  filtroFecha       : string = null ;
  filterStates      : any[];
  displayedColumns  : string[] = ['acciones','request_date', 'title', 'description','value','created_by','company','state'];
  dataSource        = new MatTableDataSource<any>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private solicitudService: SolicitudesService,
              private destroyService: DestroyComponentService,
              private alertsService: AlertsService,
              public dialog: MatDialog,
              public datepipe: DatePipe
              ) { }


   ngOnInit(): void {
    this.getSolicitudes();
    this.getStates();
  }

  private getStates(){
    this.solicitudService.getRequestStates()
      .subscribe( states => {
        this.requestStates = states["data"].filter(e => [4,5].includes(e.id));
      })
  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que trae los roles paginados
   * @param size cantidad de roles a traer en la tabla
   * @param page pagina que va a mostrar la tabla
   */
   getSolicitudes(): void {

    if (this.dataSource.filter === ''){
      this.dataSource.filter = null;
    }

    const params = {
      isPage        : this.isPage,
      pageSize      : this.pageSize,
      page          : this.page,
      rol           : this.rol,
      date          : this.filtroFecha,
      estados       : this.filterStates
    }

    this.solicitudService.getSoliciudesPaginate(params,true,true).subscribe((Response) => {

      for (var i = 0; i < Response.data.length; i++){
        let item = Response.data[i]
        item = Object.assign(item, { orderBy: new Date(item.created_at)})
      }

      this.dataSource.data  = Response.data.map(item=>{

        item.cotizations = (item.cotizations || []).map((cotization)=>{
          cotization.state = cotization.state == 1;
          if(cotization.value)
            cotization.value = Number(cotization.value)
          return cotization;
        })

        return item;
      }).sort((a, b) => b.orderBy - a.orderBy);
      this.dataSource.sort  = this.sort;
      this.length           = Response.total;
      this.pageSize         = Response.per_page;

    });
  }

  /**
  * @author Andres Giraldo
  * @createdate 2022-03-08
  * Metodo que devuelve la cotización seleccionada de una solicitud
  */
   getSelectedCotization(row) {
    const selectedCotization = row.cotizations.find((cotization)=>cotization.state)

    if(selectedCotization?.value)
      return `$${Number(selectedCotization.value).toLocaleString()}`.replace(',','.');
    else return 'N/A';
  }


  filterByState(event) {
    this.filterStates = event;
    this.getSolicitudes();

  }

 /**
  * @author Jose Vicente Silva
  * @createdate 2021-02-04
  * Metodo que realiza el filtro
  * @param event datos para filtrar que son enviados desde el input
  */
  applyFilter(event: Event): void {

    const filterValue = (event.target as HTMLInputElement).value;
    this.filtroFecha  = this.datepipe.transform(filterValue, 'yyyy-MM-dd');
    this.getSolicitudes();
  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que se encarga del movimiento del paginador
   * @param event cuando se da click en el paginador trae este evento
   */
  pageEvent(event: any): void {

    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getSolicitudes();

  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que abre el modal de crear un nuevo rol, al cerral el modal se refresca la tabla
   */
  newSolicitud(): void{
    const dialogNewRol = this.dialog.open(CrearSolicitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Crear Solicitud',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.pageSize=5;
      this.ngOnInit();
    });

  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que abre el modal de editar usuario, envia un usuario, al cerrar el modal se refresca la tabla
   * @param rol rol a editar
   */
  editSolicitud(solicitud: Solicitud): void{
    const dialogNewRol = this.dialog.open(AprobarSolcitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Detalles solicitud',
        coordinador: true,
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      setTimeout(()=>{
        this.ngOnInit();
      },1500);
      
    });
  }

  viewSolicitud(solicitud: Solicitud) {
    //const dialogNewRol = this.dialog.open(ProcesarSolicitudComponent , {
      const dialogNewRol = this.dialog.open(AprobarSolcitudComponent , {
      width: '80%',
      disableClose: true,
      data: {
        type: 'view',
        title: 'Detalles solicitud',
        coordinador: true,
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.pageSize = 5;
      this.ngOnInit();
    });
  }

  viewCampaign(){

  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }


  descargaGestion(){
    this.dialog.open( DescargaComponent, {
      width : '60%',
      data:{
        type : "historico"
      }
    } )

  }

}
