import { LiquidationsService } from 'src/app/modules/nomina/services/rest/liquidations.service';
import { LiquidationsResponseComponent } from './../liquidations-response/liquidations-response.component';
import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CampaignsService } from './../../../../services/rest/campaigns.service';
import { UsersService } from 'src/app/modules/ciu/services/rest/users.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { CandidatesService } from 'src/app/modules/rrhh/services/candidates.service';

@Component({
  selector: 'app-liquidations',
  templateUrl: './liquidations.component.html',
  styleUrls: ['./liquidations.component.sass']
})
export class LiquidationsComponent implements OnInit {

  // MatPaginator Inputs
  length;
  pageSize = 5;
  page = 1;
  isPage = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  displayedColumns: string[] = ['acciones', 'nombre', 'numero', 'email', 'campana', 'state'];
  dataSource = new MatTableDataSource<any>();
  reportForm: FormGroup;
  Campaings: any;
  filterControl = new FormControl();
  filterControl2 = new FormControl();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  document: any = '';
  startDate: any = '';
  endDate: any;
  idCampaing: any = 1;
  states: any = [{ id: 1, name: 'Activo' }, { id: 3, name: 'Inactivo' }];
  idState: any = '';
  selected: any = 1;

  documents: any = [];
  idTypeDoc: any;
  idTypeDoc2: any;

  typeDocument = new FormControl();
  typeDocument2 = new FormControl();

  constructor(public dialog: MatDialog,
    private service: CampaignsService,
    public userService: UsersService,
    private liquidations: LiquidationsService,
    private users: CandidatesService) {
  }

  ngOnInit() {
    this.service.getAllCampaigns().subscribe(resp => {
      this.Campaings = resp.data;
    });
    this.reportForm = new FormGroup({
      start: new FormControl(''),
      end: new FormControl('')
    });
    //documento
    this.users.getIdTypes().subscribe(resp => {
      this.documents = resp;
    });
    this.typeDocument.valueChanges.subscribe(resp => {
      this.idTypeDoc = resp;
      this.getUsers(this.pageSize, this.page);
    });
    this.typeDocument.setValue(1);

    this.filterControl.setValue(1);

    this.filterControl.valueChanges.subscribe(resp => {
      this.resetPaginator();
      this.idCampaing = resp;
      this.getUsers(this.pageSize, this.page);
    });
    this.filterControl2.valueChanges.subscribe(resp => {
      this.resetPaginator();
      this.idState = resp;
      this.getUsers(this.pageSize, this.page);
    });
    this.service.getAllCampaigns().subscribe(resp => {
      this.Campaings = resp.data;
    });
    this.getUsers(this.pageSize, this.page);
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-04-12
   * Metodo de paginación
   */
  getUsers(size: number, page: number): void {

    this.liquidations.getLiquidationsBy(this.idState, this.idTypeDoc, this.document, this.idCampaing, size, page).subscribe((Response) => {
      this.dataSource.data = Response.data.data;
      this.dataSource.sort = this.sort;
      this.length = Response.data.total;
      this.pageSize = Response.data.per_page;
    });

  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-04-12
   * Metodo de paginación
   */
  pageEvent(event: any): void {

    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getUsers(this.pageSize, this.page);
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-04-12
   * Metodo para ver la HV y otra info del usuario
   */
  viewJustification(element?): void {
    const dialogNewgroup = this.dialog.open(LiquidationsResponseComponent, {
      width: '60%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Juan Manuel Gonzales',
        data: element
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewgroup.afterClosed().subscribe(() => {
      this.getUsers(this.pageSize, this.page);
    });

  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-04-14
   * Metodo para capturar el documento a filtrar
   */
  onBlurMethod(e) {
    this.resetPaginator();
    this.document = e;
    this.getUsers(this.pageSize, this.page);
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-04-14
   * Metodo de descarga de reporte
   */
  downloadReport() {
    this.liquidations.getReport(this.idState, this.idTypeDoc, this.document, this.idCampaing).subscribe(resp => {
      importedSaveAs(resp, 'reporte_liquidaciones.xlsx');
    });
  }

  resetPaginator(): void {
    this.length = 0;
    this.pageSize = 5;
    this.page = 1;
    this.paginator.firstPage();
  }

}
