import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SpeechAnalyticsComponent } from './speech-analytics.component';
import { StrategyMenuComponent } from './modules/strategy/strategy-menu/strategy-menu.component';
import { AdminComponent } from './modules/configuration-administrator/admin/admin.component';
import { NodirigidoComponent } from './modules/nodirigido/nodirigido.component';
import { SpeechmenuComponent } from './modules/search-speech/speech-menu/speech-menu.component';
import { SearchHistoryMenuComponent } from './modules/search-speech/search-history/search-history-menu/search-history-menu.component';
import { SpeechChatMainComponent } from './modules/chat-speech/speech-chat-main/speech-chat-main.component';


const routes: Routes = [
  { path: '', component:  SpeechAnalyticsComponent },
  { path: 'strategy', component: StrategyMenuComponent},
  { path: 'admin', component: AdminComponent},
  { path: 'search', component: SpeechmenuComponent},
  { path: 'no-dir', component: NodirigidoComponent},
  { path: 'history', component: SearchHistoryMenuComponent},
  { path: 'chat', component: SpeechChatMainComponent},
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SpeechAnalyticsRoutingModule { }
