<div [formGroup]="form">
  <mat-form-field appearance="fill" style="width: 100%;" *ngIf="question.type != 'others'; else other_field">
      <mat-label [attr.for]="question.key">{{question.label}}</mat-label>
      <span *ngIf="question.controlType =='currency'" matPrefix>$ &nbsp;</span>
      <div [ngSwitch]="question.controlType">
          <div *ngSwitchCase="'textbox'">
              <input [type]="question.type" step="1" matInput [formControlName]="question.key" autocomplete="off" [required]="question.required">
          </div>
          <div *ngSwitchCase="'currency'">
              <input type="text" matInput [formControlName]="question.key" autocomplete="off" [required]="question.required" appNumber>
          </div>
          <div *ngSwitchCase="'dropdown'">
              <mat-select [formControlName]="question.key" [required]="question.required" (ngModelChange)="optionSelected($event, question)">
                  <mat-option *ngFor="let option of question.options" [value]="option.id">
                      {{option.name}}
                  </mat-option>
              </mat-select>
          </div>
          <div *ngSwitchCase="'dropdown-request'">
              <mat-select [formControlName]="question.key" [id]="question.key" [required]="question.required">
                  <mat-option *ngFor="let option of  question.options" [value]="option">
                      {{option.name}}
                  </mat-option>
              </mat-select>
          </div>
          <div *ngSwitchCase="'dropdown2'">
              <mat-select [formControlName]="question.key" [required]="question.required">
                  <mat-option *ngFor="let option of question.options" [value]="option">
                      {{option.name}}
                  </mat-option>
              </mat-select>
          </div>
          <div *ngSwitchCase="'autocomplete2'">
              <input matInput [matAutocomplete]="auto" [formControlName]="question.key" autocomplete="off" [required]="question.required" placeholder="Buscar {{question.label}}">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn2.bind(this)">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                      <span>{{option.name}}</span>
                  </mat-option>
              </mat-autocomplete>
          </div>
          <div *ngSwitchCase="'textarea'">
              <textarea matInput [formControlName]="question.key" [id]="question.key" [name]="question.key">
          </textarea>
          </div>
          <ng-container *ngSwitchCase="'datepicker'">
              <input matInput [matDatepicker]="question.type === 'date' ? picker : null" autocomplete="off" [formControlName]="question.key" [min]="question.minLength" [max]="question.maxLength" [required]="question.required">
          </ng-container>
          <div *ngSwitchCase="'autocomplete'">
              <input matInput [matAutocomplete]="auto" [formControlName]="question.key" autocomplete="off" [required]="question.required" placeholder="Seleccione una opción válida">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)" (optionSelected)="optionSelected($event.option.value, question)">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                      <span>{{option.name}}</span>
                  </mat-option>
              </mat-autocomplete>
          </div>
          <div *ngSwitchCase="'datetime'">

          </div>
          <div *ngSwitchCase="'multiselect'">
              <mat-select [formControlName]="question.key" multiple [required]="question.required">
                  <mat-option *ngFor="let option of question.options" [value]="option.id">
                      {{option.name}}
                  </mat-option>
              </mat-select>
          </div>

      </div>
      <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="question.type === 'date'"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <button mat-button matSuffix mat-icon-button aria-label="Agregar" type="button" *ngIf="question.canAdd" (click)="addQuestion(question)">
    <mat-icon>add</mat-icon>
  </button>
      <span *ngIf="question.tooltip?.have" matTooltip="{{question.tooltip?.content}}" class="fi-rr-interrogation" matSuffix> </span>
      <mat-error>{{getErrorMessage()}}</mat-error>


  </mat-form-field>
  <ng-template #other_field>

      <div [ngSwitch]="question.controlType">
          <div *ngSwitchCase="'radiobutton'">
              <p>{{question.label}}</p>
              <section fxLayout="row" fxLayoutAlign="start center">
                  <mat-radio-group [formControlName]="question.key" (change)="optionSelected($event, question)">
                      <mat-radio-button class="radio-button" *ngFor="let option of question.options" [value]="option.id">
                          <span> {{option.name}}</span>
                      </mat-radio-button>
                  </mat-radio-group>
              </section>
          </div>
          <div *ngSwitchCase="'file'">
             <mat-card-subtitle style="cursor: pointer;"></mat-card-subtitle>
              <button type="button" mat-stroked-button color="primary" (click)="fileInput.click()">Cargar archivo <i class="fi-rr-download"></i></button><br>

              <span>{{question.label}}</span><br>

               <p class="download" (click)="downloadFile(question)">{{question.nameFile}} <i *ngIf="question.value" class="fi-rr-download"></i> </p>
              <input hidden (change)="onFileSelected($event, question.id)" #fileInput type="file" [formControlName]="question.key"><!---->
          </div>
      </div>

  </ng-template>
</div>
