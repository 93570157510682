<div class="main-container">
    <div class="mt-5">
        <button mat-icon-button class="btn-regresar" (click)="regresar()">
            <mat-icon matPrefix style="background-color: #000; border-radius: 50%;"><i class="fi-sr-angle-small-left" style="color: #FFF;"></i></mat-icon>
            Regresar
        </button>
    </div>
    <div class="mt-4">
        <h1>Coaching</h1>
        <p>Aquí podrás crear un nuevo acompañamiento. No olvides,</p>
        <p>“El respeto y empatía son clave”.</p>
    </div>
    <div fxLayout="row" class="mt-5">
        <div fxFlex.gt-lg="60%" fxFlex.gt-md="60%">
            <app-auditoria *ngIf="this.type == 'auditoria'"></app-auditoria>
            <app-info-solicitud *ngIf="this.type == 'solicitud' || this.type == 'finalizado'"></app-info-solicitud>
        </div>
        <div fxFlex.gt-lg="40%" fxFlex.gt-md="40%">
            <app-form-solicitud *ngIf="this.type == 'solicitud' && !is_asesor"></app-form-solicitud>
            <app-form-asesor *ngIf="this.type == 'solicitud' && is_asesor"></app-form-asesor>
            <app-form-auditoria-coaching *ngIf="this.type == 'auditoria'"></app-form-auditoria-coaching>
            <div *ngIf="this.type == 'finalizado'" class="final">
                <h4><i class="fi-rr-settings"></i> Indicador y Cuartil final</h4>
                <mat-list>
                    <div fxLayout="row">
                        <mat-list-item fxFlex.gt-lg="33%" fxFlex.gt-md="33%"> 
                            <div mat-line class="encabezado">Indicador final</div>
                            <div mat-line>{{ indicador_final | number:'1.0-3'}}</div>
                        </mat-list-item>
                        <mat-list-item fxFlex.gt-lg="33%" fxFlex.gt-md="33%">
                            <div mat-line class="encabezado">Cuartil final</div>
                            <div mat-line>{{ cuartil_final }}</div>
                        </mat-list-item>
                    </div>
                </mat-list>
            </div>
        </div>
    </div>
    <div *ngIf="this.type == 'solicitud' || this.type == 'finalizado'" class="mt-5">
        <app-tabla-historico></app-tabla-historico>
    </div>
</div>