import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userGroupFilter'
})
export class UserGroupPipe implements PipeTransform {

  /**
   * Pipe para realizar la busqueda de usuarios a asignar en el grupo
   * @param usersInGroup
   * @param searchText
   */
  transform(usersInGroup: any[], searchText: any): any {
    if ((searchText == null || searchText === '') || usersInGroup == null) {
      return usersInGroup;
    }
    return usersInGroup.filter(user => user.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 || user.documento.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
  }

}
