<div class="row novelty-row-padding">

  <h2 mat-dialog-title>
    <h1>Reversión de Vacaciones</h1>
    <span class="gray-color" style="margin-left: -9px"></span>
    <button style="float: right; margin-top: -50px" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
    <div class="title content" style="width: 100%;">
      <img src="./assets/images/novelties.png" alt="noveltyIcon" class="icon">
      <span>
        <h2>{{vacation.name}}</h2>
        <h5 style="font-size: 15px; font-family: 'Poppins', sans-serif!important;">{{vacation.id_number}}</h5>
      </span>
    </div>
  </h2>

  <mat-dialog-content style="padding: 3%; overflow-x: hidden" class="mat-typography">
    <div class="row">
      <div class="col-6">
        <div class="col-12">
          <h3>
            <span class="gray-color">{{ vacation.fechaSolicitud | date:'EEEE d, MMMM' }}</span>
          </h3>
          <div class="col-12">
            <div class="col-7">
            <span
              class="badge"
              [style.background]="vacation.novelty_type?.calendar_config.backgroundColor">{{ vacation.novelty_type?.key }}</span> {{ vacation.novelty_type?.name }}
            </div>
          </div>
        </div>
        <br>
        <div class="col-12">
          <h4 class="gray-color"><b>Vacaciones solicitadas</b></h4>
          <p class="p-color"><strong>Desde: </strong> {{ vacation?.fechaSolicitud | date:'dd/MM/yyyy'  }}
            <strong>Hasta: </strong> {{vacation?.fechaFinSolicitud | date:'dd/MM/yyyy' }}</p>
        </div>

        <!-- <div class="col-12 text-center">
          <div *ngIf="showCalendar" style="height: 250px">
            <full-calendar [options]="calendarOptions"></full-calendar>
          </div>
        </div> -->

        <div class="col-12"
        *ngIf="vacationsForm.controls.action.value == 'modify'">
        <p style="font-size: 15px!important;">
          <b>Días disponibles: </b> {{ availableDays }} | <b>Días hábiles solicitados: </b> {{ businessDays }}
        </p>
      </div>

      </div>


      <div class="col-6 text-left">
        <h4 style="float: inline-start; margin-left: 60px;"><b>Gestión Novedad</b></h4>
        <form style="margin-left: 60px;" [formGroup]="vacationsForm">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Tipificación</mat-label>
            <mat-select formControlName="action">
              <mat-option *ngFor="let item of options" [value]="item.id">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>



           <mat-form-field appearance="fill" style="width: 100%" *ngIf="vacationsForm.controls.action.value == 'modify'">
            <mat-label>Nuevas Fechas</mat-label>
            <mat-date-range-input [rangePicker]="pickerRever" [min]="minDate">
              <input matStartDate  [formControl]="vacationsForm.controls.startDate" placeholder="Fecha Inicio"
                     #startDate>
              <input matEndDate [formControl]="vacationsForm.controls.endDate" placeholder="Fecha Fin" #endDate>
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerRever"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerRever></mat-date-range-picker>
          </mat-form-field> 

          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Observaciones</mat-label>
            <textarea matInput formControlName="observation"></textarea>
          </mat-form-field>

          <button mat-raised-button style="width: 100%" color="primary" (click)="updateVacationToReverse()"
                  [disabled]="sending || vacationsForm.invalid">
            Enviar solicitud
          </button>
        </form>
      </div>
    </div>
  </mat-dialog-content>

</div>
