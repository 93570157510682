import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InteligenciaService } from 'src/app/modules/auditoria/services/inteligencia.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-crear-formulario',
  templateUrl: './crear-formulario.component.html',
  styleUrls: ['./crear-formulario.component.sass']
})
export class CrearFormularioComponent implements OnInit {

  formularioForm:FormGroup
  constructor(
    public formularioRef:MatDialogRef<CrearFormularioComponent>, @Inject(MAT_DIALOG_DATA) datos:any,
    private fb:FormBuilder,
    private inteligenciaS:InteligenciaService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(){
    this.formularioForm = this.fb.group({
      name: ['',[Validators.required]],
      descripcionform: ['',[Validators.required]],
    });
  }

  /**
  * @author Cristian Gallo
  * Función para guardar la inteligencia de negocio
  */
  save(){
    if (this.formularioForm.invalid) {
      return
    }
    this.inteligenciaS.createIntelligence(this.formularioForm.value).subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202) {
        this.router.navigateByUrl(`/mios/auditoria/formulario/edit/${resp.respuesta.data.id}`);
        this.formularioRef.close();
        Swal.fire({
          icon:'success',
          title: '¡Excelente!',
          text:'Se ha creado el formulario de forma correcta',
          showConfirmButton:false,
          timer: 2500
        });
      }
    });
  }

}
