import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import {NotesService} from '../../../services/rest/notes.service';

@Component({
  selector: 'app-notas',
  templateUrl: './notas.component.html',
  styleUrls: ['./notas.component.sass']
})
export class NotasComponent implements OnInit {

  notasForm: FormGroup;
  oportunity: any;
  step: any;
  userName: string;
  userId: number;

  constructor(private alertsService: AlertsService,
    private form: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef:MatDialogRef<NotasComponent>,
    private notesService: NotesService,
    private authService: AuthService
    ) { }

  ngOnInit(): void {
    this.formControl();
    this.userName = this.authService.getUser().rrhh.name;
    this.userId = this.authService.getUser().rrhh_id;
    this.oportunity = this.data.oportunidad;
    this.step = this.data.step;
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para la definición de los controles del formulario
  */
  formControl(): void {
    this.notasForm = new FormGroup({
      anotacion: new FormControl('', [Validators.required]),
     });
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para la creación de notas
  */
  store(notasForm) {
    const body = {
      note: notasForm.value.anotacion,
      oportunity_id: this.oportunity,
      step: this.step,
      responsible_id: this.userId,
    };

    this.notesService.store(body).subscribe((resp) => {
      this.alertsService.alertSuccessWithoutButton('Excelente', 'Nota Registrada');
      this.dialogRef.close();
    });
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para la cancelación de la creación de la nota
  */
  opcionCancelar(){
    this.alertsService.alertWarning('¿Estás seguro?','¿Estás seguro de que <b>deseas cancelar</b> este proceso? Recuerda que la información se borrará automáticamente si realizas esta acción.').then((result)=>{
      if(result.isConfirmed){
      this.dialogRef.close();
      }
    });
  }
}
