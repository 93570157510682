<div class="main-container">
    <h1>{{room?.name}}</h1>
    <p><strong>Solicitud {{room?.request.code}}</strong></p>
    <p><strong>Creador por </strong>{{room?.user_creator}}</p>
    <p>En este espacio podrás evaluar a los candidatos con las pruebas asignadas con anterioridad. <br> No olvides, “El respeto y empatía son clave”.</p>
    <mat-tab-group >
        <mat-tab label="Detalles">
         <div fxLayout="column" fxLayoutAlign="space-around center">
            <div  class="room_actions">
               
                <button  mat-button (click)="activeEdit()" *ngIf="roomForm.disabled && noEdit">
                    Editar detalles de la sala &nbsp;
                    <span class="fi fi-rr-edit"></span>
                </button>
            </div>
            <form [formGroup]="roomForm"  fxLayout="row wrap" fxLayout.lt-sm="column"  fxLayoutGap="32px" fxLayoutAlign="flex-start">

                <mat-form-field appearance="fill" fxFlex="0 1 calc(25% - 32px)">
                    <mat-label>Nombre</mat-label>
                    <input matInput formControlName="name" type="text">
                </mat-form-field>
                 <mat-form-field appearance="fill" fxFlex="0 1 calc(25% - 32px)">
                    <mat-label>Capacidad</mat-label>
                    <input matInput formControlName="capacity" type="number">
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex="0 1 calc(25% - 32px)">
                    <mat-label>Fecha Inicio</mat-label>
                    <input matInput [matDatepicker]="picker1" formControlName="start" [value]="room?.start">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="fill"  fxFlex="0 1 calc(25% - 32px)">
                    <mat-label>Fecha Fin</mat-label>
                    <input matInput [matDatepicker]="picker2" formControlName="end">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex="0 1 calc(100% - 32px)">
                    <mat-label>Descripción</mat-label>
                    <textarea matInput placeholder="Descripción..." formControlName="description"></textarea>
                </mat-form-field> 
                <mat-form-field appearance="fill" fxFlex="0 1 calc(25% - 32px)">
                    <mat-label>Responsable de selección</mat-label >
                        <input matInput [(ngModel)]="user_selection_name" [ngModelOptions]="{standalone: true}" disabled>
                    
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex="0 1 calc(25% - 32px)"> 
                    <mat-label>Responsable de operacíon</mat-label>
                    <input matInput [(ngModel)]="user_operation_name" [ngModelOptions]="{standalone: true}" disabled>
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex="0 1 calc(25% - 32px)">
                    <mat-label>Responsable de formación</mat-label>
                    <input matInput [(ngModel)]="user_formation_name" [ngModelOptions]="{standalone: true}" disabled>
                    
                </mat-form-field>

               
            </form> 
            <div class="action-btns" *ngIf="!roomForm.disabled">
                <button mat-stroked-button (click)="activeEdit()">Cancelar</button>
                <button mat-raised-button color="primary"  (click)="updateRomm()">Actualizar</button>

            </div>
            
        </div>  
        </mat-tab>

        <mat-tab label="Candidatos">
           
            <div  class="table">
                 <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button *ngIf="noEdit" matTooltip="Eliminar candidato" (click)="deleteCandidate(row.id)">  <span class="fi fi-rr-trash"></span> </button>
                            <button mat-icon-button matTooltip="Calificar" (click)="assessmentDialog(row.id)">  <span class="fi-rr-scale"></span> </button>
                        </td>
                    </ng-container> 

                
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Nombre </th>
                        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                    </ng-container>

                
                    <ng-container matColumnDef="document">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>No. Documento </th>
                        <td mat-cell *matCellDef="let row"> {{row.document}} </td>
                    </ng-container>

                   
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Celular </th>
                        <td mat-cell *matCellDef="let row"> {{row.phone}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="9">No hay candidatos asociados al grupos de formación.</td>
                    </tr>
                </table>

                <mat-paginator  [length]="length" [pageSize]="pageSize" (page)="getPaginatorData($event)" showFirstLastButtons></mat-paginator>

            </div>
        </mat-tab>
    </mat-tab-group>
</div>
