import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InactiveDaysRoutingModule } from './inactive-days-routing.module';
import { InactiveDatesListComponent } from './pages/inactive-dates-list/inactive-dates-list.component';
import { CreateInactiveDatesComponent } from './pages/create-inactive-dates/create-inactive-dates.component';
import { MaterialModule } from '../../../../shared/material/material.module';


@NgModule({
  declarations: [InactiveDatesListComponent, CreateInactiveDatesComponent],
  imports: [
    CommonModule,
    InactiveDaysRoutingModule,
    MaterialModule
  ]
})
export class InactiveDaysModule { }
