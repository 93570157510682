import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { OnBoardingComponent } from '../../shared/on-boarding/on-boarding.component';

@Component({
  selector: 'app-auditoria',
  templateUrl: './auditoria.component.html',
  styleUrls: ['./auditoria.component.sass']
})
export class AuditoriaComponent implements OnInit {

  public userName : any;

  constructor(
    private dialog: MatDialog, 
    private authService : AuthService,
  ) { }

  ngOnInit(): void {
    this.userName = this.authService.decryptToken();
  }

  onBoadingAuditoria(): void{
    const landingRef = this.dialog.open(OnBoardingComponent,{
      disableClose: true,
      panelClass: 'full-screen-modal',
    })
  }

}
