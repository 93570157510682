import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { RequestService } from 'src/app/modules/speech-analytics/services/request.service';
import { ViewService } from 'src/app/modules/speech-analytics/services/view.service';
import * as moment from 'moment';
import { AudioPlayerComponent } from '../audio-player-modal/audio-player-modal.component';
import { Observable } from 'rxjs';
import { FormsCrmRequestService } from 'src/app/modules/speech-analytics/services/forms-request.service';
import { SelectionModel } from '@angular/cdk/collections';
import { StrategyServersService } from 'src/app/modules/speech-analytics/services/strategy.service';
import { CampaignService } from 'src/app/modules/speech-analytics/services/campaign.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { AdherenciaModalComponent } from '../adherencia-modal/adherencia-modal.component';
import { TranscricpcionStrategyComponent } from '../transcription-strategy-modal/transcription-strategy-modal.component';
import { AnalyticsModalComponent } from '../analytics-modal/analytics-modal.component';
import { AnalyticsVoiceGeneralComponent } from '../analytics-voice-general-modal/analytics-voice-general-modal.component';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-search-module',
  templateUrl: './search-module.component.html',
  styleUrls: ['./search-module.component.sass']
})
export class ModuleComponent implements OnInit, OnDestroy {

  /**
   * formulario
   */
  formFilters:FormGroup;
  isFiltersAdvancedActive:boolean = false;
  
  /**
   * Variables de paginacion
   */
  configPaginator : any = { length: 0, pageSize: 5, pageIndex: 1 };
  configPaginatorRecording : any ={ pageSize : 5, length:0,  pageIndex:1 }
  configPaginatorStrategy : any ={ pageSize : 5, length:0,  pageIndex:1 }
  pageSizeOptions : number[] = [5, 10, 20, 50, 100];


  /**
   * Selectores de filtros
   */
  formularios: any[] = [];
  seccion: any[] = [];
  fields: any[] = [];
  opciones: any[] = [];
  campaigns_cartera:any[]=[];
  carterasLists:any[] = [];
  listStrategys:any[] = [];
  campaigns_user: any[] = [];
  filteredOptionsCampaign: Observable<any[]>;

  groups: any[] = [];
  skills: any[] = [];
  tipifications: any[] = [];
  type_call: any[] = [ {type_call:'Inbound'}, {type_call:'Outbound'} ];

  /**
   * Columnas tablas
   */
  displayedColumns:string[] = ['checbox','recording_id', 'start_time', 'user', 'full_name', 'phone_number', 'user_group','time_difference'];
  displayedColumnsRecordings:string[] = ['checbox','recording_id', 'start_time', 'user', 'full_name', 'phone_number', 'user_group','time_difference', 'audio'];
  displayedColumnsStrategy: string[] = ['checbox','recording_id', 'start_time', 'user', 'full_name', 'phone_number', 'user_group', 'time_difference','audio','analisis'];


  /**
   * Cabeceras de tablas
   */
  tHeadersRecordings:any={
    checbox: "", recording_id:"ID Caller",
    start_time:'Fecha grabación', user: "No. documento", 
    full_name: 'Nombre agente', phone_number:'Teléfono', user_group: 'Skill', 
    time_difference: "Tiempo", audio:'Audio', analisis:'Análisis' , adherencia:'Adherencia'
  }

  dataSource = new MatTableDataSource<any>(); 
  dataSourceRecordings = new MatTableDataSource<any>(); 
  dataSourceStrategy = new MatTableDataSource<any>(); 

  //Variables de control con Array original de datos para tablas que requiren Paginacion estatica
  listrecordingsAnalising:any[] = []
  listRecordingsStrategys:any[] = []

  //ordenamiento tablas 
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('sortRecordings') sortRecordings: MatSort;
  @ViewChild('sortStrategy') sortStrategy: MatSort;

  /**
   * Varibales de seleccions
   */
  selection = new SelectionModel<any>(true, []);
  selectionRecordings = new SelectionModel<any>(true, []);
  selectionStrategy = new SelectionModel<any>(true, []);

  //Tipo de modulo a consultar CRM1-CRM2-Carteras
  selectedCRM: any;
  //Control para seleccion de estrategias
  strategyControl:FormControl = new FormControl(null)

  //
  rrhh_id_user: any;

  isAllComplete: boolean;
  private isActive = true;
  startegyComplete: boolean;

  //parar el metodo obtener estados
  private abortController: AbortController | null = null;

  //selectores 
  selectedSearch: Set<number> = new Set<number>(); //selest activos busqueda
  selectedDownload: Map<number, any> = new Map(); //selects activos descarga audios
  selectedAnalytics: Map<number, any> = new Map(); //selects activos analisis
  selectedStrategy: Map<number, any> = new Map(); //selects activos strategias
  
  @ViewChild('stepperStrategys') stepperStrategys: any;
  paginaActual: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.initFormFilters();
    this.filterUserCampaign();
    this.cargarSelects();
  }

  ngOnDestroy() {
    this.isActive = false; 
  }
  
  constructor(
      private dialog:MatDialog,
      private requestService: RequestService,
      private viewservice: ViewService,
      private formsCrmRequestService: FormsCrmRequestService,
      private strategyService: StrategyServersService,
      private campaignService: CampaignService,
      private authService: AuthService,
  ) {}

  /**
   * Método donde se establecen las validaciones del formulario y adviarte antes de refrescar 
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @method unloadNotification
   * @param {Event} $event - El evento `beforeunload` del navegador.
   * @returns {void}
  */

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.formFilters.dirty) {
      $event.returnValue = true;
    }
  }

  /**
   * Metodo donde se establecen las validaciones del formulario
   * @author Yeison sepulveda
   * @createdate    14/03/2023
   * @returns void {void} 
   */

  initFormFilters(): void {
    this.formFilters = new FormGroup({
      campaign: new FormControl(null, [Validators.required, this.campaignValidator.bind(this)]),
      module: new FormControl(null, Validators.required),
      form: new FormControl(null),
      section: new FormControl(null),
      field: new FormControl(null),
      option: new FormControl(null),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      phone_number: new FormControl(null),
      usergroup: new FormControl(null),
      skill_id: new FormControl(null),
      status: new FormControl(null),
      user: new FormControl(null), 
      type_call: new FormControl(null), 
      duration: new FormControl(null, [this.timeGreaterThanOneMinuteValidator()])
    });
  }

  clearField(): void {
    this.resetFilters(['campaign', 'start_date', 'end_date']);
    this.groups = []; 
    this.tipifications = [];
  }

  /**
   * Metodo validar rango de fecha para desabilitar als fechas personalizadas
   * @author Yeison sepulveda
   * @createdate    14/03/2024
   * @returns void {void} 
   */

  dateFilter = (date: Date | null): boolean => {
    const campaign_id = this.formFilters.get("campaign").value;
    const selectedCampaign = this.campaigns_user.find(campaign => campaign.id === campaign_id);
    let newDelimitDate: Date | null = null;

    //si hay una campaña seleccionada y tiene una fecha delimitada agregar 1 día a la fecha delimitada
    if (selectedCampaign && selectedCampaign.delimit_date) {
        newDelimitDate = new Date(selectedCampaign.delimit_date);
        newDelimitDate.setDate(newDelimitDate.getDate() + 1);
    }

    const today = new Date();
    let twoMonthsAgo: Date;

    if (newDelimitDate) {
        //delimitado con fecha
        twoMonthsAgo = new Date(newDelimitDate);
        twoMonthsAgo.setMonth(newDelimitDate.getMonth() - 2);
        return date >= twoMonthsAgo && date <= newDelimitDate;
    } else {
        //pretermiando
        twoMonthsAgo = new Date(today);
        twoMonthsAgo.setMonth(today.getMonth() - 2);
        return date >= twoMonthsAgo && date <= today;
    }
  }

  /**
   * Validador personalizado para el campo 'campaign'.
   * @author Yeison Sepulveda
   * @createdate 2024-07-19
   * @param formGroup El grupo de formularios que contiene el campo 'campaign'.
   * @returns objeto con el error 'invalidCampaign' si el valor es inválido, o null si es válido.
   */
  campaignValidator(control: FormControl): { [key: string]: boolean } | null {
    const isValid = this.campaigns_user.some(c => c.id === control.value);
    return isValid ? null : { invalidCampaign: true };
  }

  /**
   * Metodo solo permite numeros
   * @author Yeison Sepulveda
   * @date 2024-07-31
   */
  stripText(control: string) {
    this.formFilters.controls[control].setValue(
        this.formFilters.controls[control].value.replace(/[^0-9]/g, '')
    );
  }
  
  /**
   * Método para limpiar la selección basado en un parámetro
   * @author Yeison Sepulveda
   * @param param
   */
  searchSelection(param: string) {
    switch (param) {
      case 'search':
        // Limpiar todos los selectores relevantes
        this.selectedSearch.clear();
        this.selectedDownload.clear();
        this.selectedAnalytics.clear();
        this.selectedStrategy.clear();
        this.selectionRecordings.clear();
        this.selectionStrategy.clear();
        this.configPaginator.pageIndex = 1;
        this.configPaginatorRecording.pageIndex = 1;
        this.listRecordingsStrategys = [];
        this.search();
        break;
  
      case 'descarga':
        this.selectedDownload.clear();
        this.paginator.firstPage();
        this.configPaginatorRecording.pageIndex = 1;
        this.dataSourceRecordings.data = [];
        this.configPaginatorRecording.pageSize = this.configPaginator.pageSize;
        this.deleteColumnAnalytics();
        this.descargarAudios(this.stepperStrategys);
        break;
  
      case 'analisis':
        if (this.selectedDownload.size === 0) {
          Swal.fire({
            title: 'No hay registros seleccionados',
            text: 'Por favor, seleccione al menos un registro para analizar.',
            icon: 'warning',
            confirmButtonText: '¡Entendido!',
            confirmButtonColor: '#2CABBC',
          });
          return;
        }
        Swal.fire({
          title: '¿Estás seguro?',
          text: `Se iniciará el análisis de ${this.selectedDownload.size} grabaciones.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí, continuar',
          confirmButtonColor: '#2CABBC',
          cancelButtonText: 'Cancelar',
          cancelButtonColor: '#FFFFFF',
          reverseButtons: true
      }).then((result) => {
          if (result.isConfirmed) {
              // Realizar análisis
              this.selectedAnalytics = new Map(this.selectedDownload);
              this.selectedDownload.clear();
              this.selectionRecordings.clear();
              this.listRecordingsStrategys = [];
              this.dataSourceRecordings.data = [];
              this.analytics();
          }
      });
      break;
      case 'analisis_sentimientos':
        if (this.selectedDownload.size === 0) {
          Swal.fire({
            title: 'No hay registros seleccionados',
            text: 'Por favor, seleccione al menos un registro para analizar.',
            icon: 'warning',
            confirmButtonText: '¡Entendido!',
            confirmButtonColor: '#2CABBC',
          });
          return;
        }
        Swal.fire({
          title: '¿Estás seguro?',
          text: `Se iniciará el análisis de sentientos de ${this.selectedDownload.size} grabaciones.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí, continuar',
          confirmButtonColor: '#2CABBC',
          cancelButtonText: 'Cancelar',
          cancelButtonColor: '#FFFFFF',
          reverseButtons: true
      }).then((result) => {
          if (result.isConfirmed) {
            this.selectedAnalytics = new Map(this.selectedDownload);
            this.analytics_emociones();
            this.selectionRecordings.clear();
            this.selectedDownload.clear();
          }
      });
      break;
  
      case 'strategia':
        if (this.selectedAnalytics.size === 0) {
          Swal.fire({
            title: 'No hay registros seleccionados',
            text: 'Por favor, seleccione al menos un registro para aplicarle estrategias.',
            icon: 'warning',
            confirmButtonText: '¡Entendido!',
            confirmButtonColor: '#2CABBC',
          });
          return;
        }
        this.dataSourceStrategy.data = [];
        this.configPaginatorStrategy.pageSize = this.configPaginatorRecording.pageSize;
        this.selectedAnalytics = new Map(this.selectedDownload);
        this.selectedStrategy.clear();
        this.selectionStrategy.clear();
        this.applyStrategy(this.stepperStrategys);
        break;
    }
  }

  /**
   * Recuperar los selec seleccionados en distintas paginas
   * @author Yeison Sepulveda
   * @param param
   */
  syncSelectionWithStored(param: string) {
    switch (param) {
      case 'search':
        this.selection.clear();
        this.dataSource.data.forEach((row) => {
          if (this.selectedSearch.has(row.recording_id)) {
            this.selection.select(row);
          }
        });
        break;
  
      case 'descarga':
      case 'analisis':
        this.selectionRecordings.clear();
        this.dataSourceRecordings.data.forEach((row) => {
          if (this.selectedDownload.has(row.recording_id)) {
            this.selectionRecordings.select(row);
          }
        });
        break;
  
      case 'strategy':
        this.selectionStrategy.clear();
        this.dataSourceStrategy.data.forEach((row) => {
          if (this.selectedStrategy.has(row.recording_id)) {
            this.selectionStrategy.select(row);
          }
        });
        break;
  
      default:
        console.error(`Error de parametro: ${param}`);
        break;
    }
  }

  /**
   * Metodo que determinao si los checbox estan seleccionados o no
   * @author Juan David Guerrero Vargas
   * @returns {Boolean}
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /**
   * Metodo para para seleccionar todos los elementos en la página actual
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
  */
  selectAll(): void {
    if (this.isAllSelected()) {
      this.dataSource.data.forEach((row) => {
        this.selection.deselect(row);
        this.selectedSearch.delete(row.recording_id);
      });
    } else {
      this.dataSource.data.forEach((row) => {
        if (!this.selection.selected.some((selectedItem: any) => selectedItem.recording_id === row.recording_id)) {
          this.selection.select(row);
          this.selectedSearch.add(row.recording_id);
        }
      });
    }
  }

  /**
   * Método para alternar la selección de una grabación en la lista.
   * @author Yeison Sepulveda
   * @param row - El objeto que representa una grabación en la lista.
  */

  toggleSelection(row: any): void {
    if (this.selection.isSelected(row)) {
      this.selection.deselect(row);
      this.selectedSearch.delete(row.recording_id);
    } else {
      this.selection.select(row);
      this.selectedSearch.add(row.recording_id);
    }
  }

  /**
   * Método que determinao si los checbox estan seleccionados o no en la tabal de analisis
   * @author Juan David Guerrero Vargas
   * @returns {Boolean}
   */
  isAllSelectedRecordings() {
    const numSelected = this.selectionRecordings.selected.length;
    const numRows = this.dataSourceRecordings.data.filter(row => row.analisis !== 'ERROR' && row.analisis !== 'PENDIENTE' && row.analisis !== 'PRIORITARIO').length;
    return numSelected === numRows;
  }

  /**
   * Método para seleccionar todos los elementos en la tabla de analsis
   * @modificado Yeison Sepulveda
   * @author Juan David Guerrero Vargas
   */
  selectAllRecordings(): void {
    if (this.isAllSelectedRecordings()) {
      this.dataSourceRecordings.data.forEach((row: any) => {
        if (row.analisis !== 'ERROR' && row.analisis !== 'PENDIENTE' && row.analisis !== 'PRIORITARIO') {
          this.selectionRecordings.deselect(row);
          this.selectedDownload.delete(row.recording_id);
        }
      });
    } else {
      this.dataSourceRecordings.data.forEach((row: any) => {
        if (row.analisis !== 'ERROR' && row.analisis !== 'PENDIENTE' && row.analisis !== 'PRIORITARIO') {
          if (!this.selectionRecordings.isSelected(row)) {
            this.selectionRecordings.select(row);
            this.selectedDownload.set(row.recording_id, row);
          }
        }
      });
    }
  }
  

  /**
   * Método para alternar la selección de una grabación en la lista.
   * @author Yeison Sepulveda
   * @param row - El objeto que representa una grabación en la lista.
  */

  toggleRecordingSelection(row: any): void {
    if (this.selectionRecordings.isSelected(row)) {
      this.selectionRecordings.deselect(row);
      this.selectedDownload.delete(row.recording_id);
    } else {
      if (row.analisis !== 'ERROR' && row.analisis !== 'PENDIENTE' && row.analisis !== 'PRIORITARIO') {
        this.selectionRecordings.select(row);
        this.selectedDownload.set(row.recording_id, row);  // Almacena el objeto completo
      }
    }
  }

  /**
   * Metodo que se encarga de determinar si ya estan seleccionadas todas las grbaciones apra aplicar estrategias
   * @author Juan David Guerrero Vaegas
   */
  isAllSelectedStrategy() {
    const numSelected = this.selectionStrategy.selected.length;
    const numRows = this.dataSourceStrategy.data.length;
    return numSelected === numRows;
  }

  /**
   * Método para seleccionar todos los elementos en la página actual
   * @author Juan David Guerrero Vargas
   */

  selectAllStrategy(): void {
    if (this.isAllSelectedStrategy()) {
      this.dataSourceStrategy.data.forEach((row) => {
        this.selectionStrategy.deselect(row);
        this.selectedStrategy.delete(row.recording_id);
      });
    } else {
      this.dataSourceStrategy.data.forEach((row) => {
        if (!this.selectionStrategy.isSelected(row)) {
          this.selectionStrategy.select(row);
          this.selectedStrategy.set(row.recording_id, row);
        }
      });
    }
  }

  /**
   * Método para alternar la selección de una grabación en la lista.
   * @author Yeison Sepulveda
   * @param row - El objeto que representa una grabación en la lista.
  */
  
  toggleSelectionStrategy(strategyRow: any): void {
    if (this.selectionStrategy.isSelected(strategyRow)) {
      this.selectionStrategy.deselect(strategyRow);
      this.selectedStrategy.delete(strategyRow.recording_id);
    } else {
      this.selectionStrategy.select(strategyRow);
      this.selectedStrategy.set(strategyRow.recording_id, strategyRow);
    }
  }
  
  /**
   * Metodo que se encarga de retonar la cantidad de items seleccioandos
   * @author Juan David Guerrero Vargas
   * @returns {number} Cantidad de items seleccionados
   */
  get getTotalItemSelected():number{
    if(this.selection && this.selection?.selected){
      return this.selection?.selected.length
    }
    return  0
  }

  /**
   * Método que se encarga de retonar la cantidad de items seleccioandos en la tabal de Analsis
   * @author Juan David Guerrero Vargas
   * @returns {number} Cantidad de items seleccionados
   */
  getTotalItemSelectedRecordings(): number {
    if (this.selectionRecordings && this.selectionRecordings.selected) {
      return this.selectionRecordings.selected.length;
    }
    return 0;
  }

  /**
   * Metodo que se encarga de obtener la cantidad de items que ya fueron analizados
   * @author Juan David Guerrero Vargas
   * @returns {number} number cantidad de items que ya estan analizados
   */
  get getTotalItemsAnalized():number{
    let totalRecordingsAnalized = this.selectionRecordings?.selected.filter( ffll =>  ffll?.analisis !== undefined &&  (ffll?.analisis == 'COMPLETADO' ||  ffll?.analisis == 'ERROR') ).length
    if( totalRecordingsAnalized > 0 ){
      return totalRecordingsAnalized;
    }
    return 0
  }
  
  /**
   * Metdodo que se encarga de obtener la cantidad de registros en la tabla 
   * @author Yeison Sepulveda
   */
  get getTotalDataSource():number{
    return this.dataSource.data.length
  }

  /**
   * Metodo trae las campañas asociadas al usuario
   * @author Yeison sepulveda
   * @createdate    06/05/2023
   * @returns void {void} 
   */
  filterUserCampaign(): void {
    const user = this.authService.getUser();
    this.rrhh_id_user = [user.rrhh_id];
  
    this.campaignService.getUserAssociatedCampaigns(this.rrhh_id_user).subscribe(
      res => {
        this.campaigns_user = res.data;
        
        this.filteredOptionsCampaign = this.formFilters.get('campaign')!.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : this.displayFnCampaign(value)),
          map(name => name ? this._filter(name) : this.campaigns_user.slice())
        );
      },
      error => {
        this.campaigns_user = [];
        console.error('Error al encontrar campaña asociada al usuario', error.message);
        Swal.fire({
          title: 'Error al encontrar campaña asociada al usuario',
          text: error.message,
          icon: 'error',
          confirmButtonText: '¡Entendido!',
          confirmButtonColor: '#2CABBC',
        });
      }
    );
  }

  /**
   * Filtra por nombre de rol, se usa para el autocompletable
   * @author Yeison Sepulveda
   * @createdate 2024-08-30
   * @param value valor a filtrar
   */
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.campaigns_user.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  /**
   * Metodo que devuelve el nombre al momento de seleccionar una opcion
   * @author Yeison sepulveda
   * @createdate 2024-08-30
   */
  displayFnCampaign(id: number): string {
    if (!id || !this.campaigns_user) {
      return '';
    }
    const campaign = this.campaigns_user.find(c => c.id === id);
    return campaign ? campaign.name : '';
  }
  
  /**
   * Metodo para obtener la data de lso selctores segun el tipo de llamada
   * @author Yeison Sepulveda
   */
  typeCallchage():void{
    if(this.formFilters.get("type_call").value !== null && this.formFilters.get("type_call").value !== "" ){
      this.getOtherSelectors();
    }
    this.resetFilters(['usergroup', 'skill_id', 'status']);
  }

  /**
   * Metodo que se encarga de resetear los controles indicados
   * @author Yeison Sepulveda
   * @param controls:string[] array de name_controls a resetear e inhbilitar o habilitar
   */
    resetFilters(controls:string[]):void{
      controls.forEach( (control_name:string) => {
          this.formFilters.get(control_name).reset();
      });
    }

  /**
   * Método cambiar los estados de los filtros avanzados select carteras
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
  */

  changeStateFiltersAdvanced(event): void {
    this.evenPrevenDefault(event);
    if (this.isFiltersAdvancedActive) {
      let listFiltersClean = ["usergroup", "status", "user", "duration", "skill_id" ];
      listFiltersClean.forEach((nameControl: string) => {
        const control = this.formFilters.get(nameControl);
        if (control) {
          control.setValue(null);
        }
      });
      this.isFiltersAdvancedActive = false;
    } else {
      this.isFiltersAdvancedActive = true;
    }
  }

  /**
   * Metodo que se encarga de valdiar si se debe mostrar o no el selctor de skills select carteras
   * @author Yeison Sepulveda
   */
    get isAviableSkills():boolean{
      let type_call = this.formFilters.get("type_call").value
      if( (type_call !== null && type_call !== "" && type_call == "Inbound") ){
        return true
      }
      return false
    }

  /**
   * Metodo que se encarga de determinar si es posible o no realziar la consulta de de grabaciones
   * @author Yeison Sepulveda
   * @returns {void} void
   */
  get isSerachAviable():boolean{
    const campaign = this.formFilters.get('campaign').value;
    const selectedCRM = this.formFilters.get('module').value;
    if(selectedCRM == 'crm1' ||  selectedCRM =='crm2'){
    let form_id   =  this.formFilters.get('form').value;
    let object_id =  this.formFilters.get('field').value;
    let value     =  this.formFilters.get('option').value;
    if((campaign !== null && campaign !== "") && (form_id !== null && form_id !== "") && ( object_id !== null && object_id !== "" ) && ( value !== null && value !== "" )  ){
      return true
    }

    }else if (selectedCRM == 'carteras' ){
      let start_date = this.formFilters.get('start_date').value
      let end_date = this.formFilters.get('end_date').value
      if( (campaign !== null && campaign !== "") && (start_date !== null && start_date !== "")  && (end_date !== null && end_date !== "") ){
        return true;
      }
      return false
    }
    return false;
  }

  /**
   * Metodo que se encarga de obtener el total de recordings que se seleccionaron pra aplciar la estrategia
   * @author Yeison Sepulveda
   * @returns {number} number
   */
  get getTotalRecordingsForStrategy():number{
    if(this.selectionStrategy && this.selectionStrategy.selected.length > 0 ){
      return this.selectionStrategy.selected.length;
    }
    return 0
  }

  /**
   * Metodo que se encarga de determinar la cantidad de itms que ya han sido analisados
   */
  get getTotalcompletedAnalisis():number{
    return this.dataSourceRecordings.data.filter( ffll =>  ffll?.analisis !== undefined &&  ffll?.analisis == 'COMPLETADO' ).length
  }


  /**
   * Metodo que la data segun el modulo de CRM
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
  */
  cargarSelects(): void {
    ///CONSULTA FORMULARIOS
    this.formFilters.get('module').valueChanges.subscribe((selectedModule) => {
      switch (selectedModule) {
        //en caso de sr CRM1 - CRM2 se ahce uso del mismo endpoint
        case "crm1" : 
        case "crm2" :
          this.getForms(selectedModule);
        break;
        case "carteras":
        /// ----------------------------------------> QUITAR   this.getCampaignsCarteras();
        default:
        break;
      }
      this.resetFormFilters(['form','section','field','option']);
    });
    ///CONSULTA FORMULARIOS

    //CONSULTA SECCIIONS
    this.formFilters.get('form').valueChanges.subscribe((selectedFormId) => {
      if(selectedFormId){
        this.getSections(selectedFormId);
      }
        
      this.resetFormFilters(['section','field','option']);
    });
    //CONSULTA SECCIIONS

    //CONSULTA FIELDS POR SECCION
    this.formFilters.get('section').valueChanges.subscribe((selectedSectionIndex) => {
      const selectedSection = this.seccion[selectedSectionIndex];
      if(selectedSection){
        this.getFields(selectedSection);
      }
      this.resetFormFilters(['field','option']);
    });
    //CONSULTA FIELDS POR SECCION
    //CONSULTA OPTIONS POR FIELD
    this.formFilters.get('field').valueChanges.subscribe((selectedFieldId) => {
      const selectedField = this.fields.find((field: any) => field.id === selectedFieldId);
      if(selectedField){
        this.getOptions(selectedField.options);
      }
      this.resetFormFilters(['option']);
    });
    //CONSULTA OPTIONS POR FIELD
  }

  /**
   * Metodo que se encarga de obtner las cmapañs de carteras
   * @author Juan David Guerrero Vargas
   */
  getCampaignsCarteras():void{
    this.viewservice.getCampaignsCarteras().subscribe(
      (restReponse)=>{
        this.campaigns_cartera = restReponse.data
      }
    );
  }

  /**
   * Metodo que se encarg de obtener los selectrores por tipo de llamada
   * @author juan David Guerrero Vargas
   */
    getOtherSelectors():void{
      const typeCall = this.formFilters.get("type_call").value
      const campaign_id = this.formFilters.get("campaign").value

      this.viewservice.getSkills(typeCall, campaign_id).subscribe(res => {
        this.skills = res.data;
      },
      (error)=>{
        this.skills = []
      });  
      this.getStrategys();
    }

  /**
   * Metodo que se encarga de obtenr los sectores de vicidial segun la campaña
   * @author Yeison Sepulveda
   * @date 24-06-2024
  */
  selectorsCampaigns(id: any): void {
    const campaign_id = id;

    // Obtener grupos relacionados con la campaña
    this.viewservice.getGroups(campaign_id).subscribe(
        res => {
            this.groups = res.data;
        },
        error => {
            this.groups = [];
            console.error('Error al obtener grupos:', error);
        }
    );

    // Obtener tipificaciones relacionadas con la campaña
    this.viewservice.getTipifications(campaign_id).subscribe(
        res => {
            this.tipifications = res.data;
        },
        error => {
            this.tipifications = [];
            console.error('Error al obtener tipificaciones:', error);
        }
    );
  }

  /**
   * Metodo que se encarga de rinicioar los filtros segun seleccion
   * @author Juan David Guerrero Vargas
   * @param controls:String[] {string[]} array de string con los nombrtes de los controles a reiniciar
   */
  resetFormFilters(controls:string[]) {
    controls.forEach( 
        (nameControl:string)=>{
          this.formFilters.get(nameControl).reset();
        }
    )

  }

  /**
   * Metodo que se encarga de onbtener los formularios segun el modulo seleccionado
   * @author Juan David Guerrero Vargas
   */
  getForms(selectedModule:string):void{
    const formRequestServiceMethod = selectedModule === 'crm1' ? 'getForm' : 'getFormCrm2';
    this.formsCrmRequestService[formRequestServiceMethod]('formslist', 100, 1).subscribe((rest) => {
      this.formularios = rest.data.map(form => ({ id: form.id, name: form.name_form }));
    });
  }

  /**
   * Metodo que se encarga de obtener las secciones de los formularios
   * @param idFormulario:any {string|number} id del formulario al que se le consultaran las secciones
   * @author Juan David Guerrero Vargas
   */
  getSections(idFormulario:any):void{
    const searchFormMethod = this.formFilters.get('module').value === 'crm1' ? 'getsearchform' : 'getsearchformCrm2';
  
    this.formsCrmRequestService[searchFormMethod]('searchform', idFormulario).subscribe((rest) => {
      const sections = this.formFilters.get('module').value === 'crm1' ? rest.section : rest.sections;
      this.seccion = sections.map((section: any, index: number) => ({ id: index, name: section.name_section, fields: section.fields }));

    });
  }

  /**
   * Metodo que se encarga de obtener los fields de una secccion
   * @param selectedSection:any {any} seccion seleccioanda
   */
  getFields(selectedSection:any):void{
    if(selectedSection?.fields !== undefined && Array.isArray(selectedSection?.fields) == true){
      const viewFields = selectedSection.fields.filter((field: any) => field.type === 'options');
      this.fields = viewFields.map((field: any) => ({ id: field.id, name: field.label, options: field.options }));

    }
  }

  /**
   * Metodo que se encarga de obtener el listado de opciones de un campo del formulario
   * @param option:any {any}
   */ 
  getOptions(options:any):void{
    this.opciones = options
  }

  /**
   * Metodo que se encarga de obtenre el listado de estrategias
   * @author Yeison Sepulveda
   * @date 2024-08-08
  */
  getStrategys():void{
    const type_channel= [1];
    this.strategyService.listStrategies(type_channel).subscribe(res => {
        this.listStrategys = res.data;
    }); 
  }

  /**
   * Metodo accesor que permite determinar si se debe o nomostrar los filtros para formularos
   * @author Juan David Guerrero Varga
   * @returns {boolean} boolean
   */
  get showFilterByForms():boolean{
    const module = this.formFilters.get('module').value
    if( module == "crm1" || module == "crm2"){
      return true
    }
    return false;
  }

  /**
   * Metodo accesor que permite determinar si se debe o nomostrar los filtros para carteras
   * @author Juan David Guerrero Varga
   * @returns {boolean} boolean
   */
  get showFilterByCarteras():boolean{
    const module = this.formFilters.get('module').value
    if( module == "carteras" ){
      return true
    }
    return false
  }

  /**
   * Metodo para el consumo y busqueda por los selectores
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
   */

  search() {
    this.selectedCRM = this.formFilters.get('module').value;
    switch (this.selectedCRM) {
      case "crm1":
      case "crm2":
        if (!this.validateRangeDates()){
          this.deleteColumnAnalytics();
          const filters = {
            campaign_id: this.formFilters.get('campaign').value,
            form_id: this.formFilters.get('form').value.toString(),
            object_id: this.formFilters.get('field').value.toString(),
            value: this.formFilters.get('option').value.toString(),
            start_date: this.formFilters.get('start_date').value,
            end_date: this.formFilters.get('end_date').value
          };
          const $searchObservable:Observable<any> = this.selectedCRM == "crm1" ? this.viewservice.getFilter(this.configPaginator, filters) : this.viewservice.getFilter2(this.configPaginator, filters)
          $searchObservable.subscribe(
            (response) => {

              //calcular tiempo
              response.data.data.forEach((registro: any) => {
                const startTime = new Date(registro.start_time);
                const endTime = new Date(registro.end_time);
                registro.time_difference = this.convertTimeToHHMMSS(startTime, endTime);
              });

              this.dataSource.data = response.data.data;
              this.dataSource.sort = this.sort;
              this.configPaginator.length = response.data.total;
              this.syncSelectionWithStored('search');
  
            },
            (error) => {
              console.error('Ha ocurrido un error al consultar:', error.message);
              Swal.fire({
                title: 'Ha ocurrido un error',
                text: error.error.error.error,
                icon: 'error',
                confirmButtonText: '¡Entendido!',
                confirmButtonColor: '#2CABBC',
              });
            }
          );

        }else {
          Swal.fire({
            title: '¡Ten encuenta!',
            text: 'Debe estar en un rango de 7 días',
            icon: 'warning',
            confirmButtonText: '¡Entendido!',
            confirmButtonColor: '#2CABBC',
          });
        }
      break;
      case "carteras":
        if (!this.validateRangeDates()){
          this.deleteColumnAnalytics();
          
          const filters = {
            campaign_id: this.formFilters.get('campaign').value,
            type_call: this.formFilters.get('type_call').value,
            user_group: this.formFilters.get('usergroup').value,
            skill_id: this.formFilters.get('skill_id').value,
            start_time: this.formFilters.get('start_date').value,
            endtime: this.formFilters.get('end_date').value,
            status: this.formFilters.get('status').value,
            user: this.formFilters.get('user').value,
            phone_number: this.formFilters.get('phone_number').value,
            duration: this.formFilters.get('duration').value,
          };

          this.viewservice.searchVicidial(this.configPaginator, filters).subscribe(
            (restResponse)=>{
              
              //calcular tiempo
              restResponse.data.data.forEach((registro: any) => {
                const startTime = new Date(registro.start_time);
                const endTime = new Date(registro.end_time);
                registro.time_difference = this.convertTimeToHHMMSS(startTime, endTime);
              });

              this.dataSource.data = restResponse.data.data;
              this.dataSource.sort = this.sort;
              this.configPaginator.length = restResponse.data.total;
              this.syncSelectionWithStored('search');
            },
            (error) => {
              console.error('Ha ocurrido un error al consultar la información:', error.message);
              Swal.fire({
                icon: 'error',
                title: 'Ha ocurrido un error',
                text: error.error.error.error,
                showConfirmButton: true,
                confirmButtonColor: '#2CABBC',
                confirmButtonText: 'Aceptar',
              });
            }
          );
        }else {
          Swal.fire({
            title: '¡Ten encuenta!',
            text: 'Debe estar en un rango de 7 días',
            icon: 'warning',
            confirmButtonText: '¡Entendido!',
            confirmButtonColor: '#2CABBC',
        });
        }
      break;
    
      default:
        break;
    }

  }


  /**
   * Método para convertir la diferencia de tiempo entre dos fechas en formato HH:mm:ss.
   * @author Yeison Sepulveda
   * @createdate 2024-04-23
   * @param startTime La fecha de inicio.
   * @param endTime La fecha de fin.
   * @returns La diferencia de tiempo formateada en HH:mm:ss.
  */
  
  convertTimeToHHMMSS(startTime: Date, endTime: Date): string {

    const diferenciaMs = Math.abs(endTime.getTime() - startTime.getTime());
    const tiempoDiferencia = new Date(diferenciaMs);

    //extraer
    const horas = tiempoDiferencia.getUTCHours().toString().padStart(2, '0');
    const minutos = tiempoDiferencia.getUTCMinutes().toString().padStart(2, '0');
    const segundos = tiempoDiferencia.getUTCSeconds().toString().padStart(2, '0');

    return `${horas}:${minutos}:${segundos}`;
  }

  /**
   * Descargar reporte de busqueda excel
   * @author Yeison Sepulveda
   * @createdate 2024-04-04
   */
  downloadReport(type: 'analysis' | 'strategy') {
    let filters: any = {};
  
    if (type === 'analysis') {
      filters = {
        recording_ids: this.dataSourceRecordings.data.filter(record => record?.analisis === 'COMPLETADO').map(record => record['recording_id']),
        type: 1
      };
    } else if (type === 'strategy') {
      filters = {
        recording_ids: this.selectionStrategy.selected.map(recording => recording['recording_id']),
        type: 2
      };
    }
  
    this.viewservice.downloadReport(filters).subscribe(
      (response: any) => {
        const filename = this.generateFilename();
        this.fileTransition(response,filename);
      },
      (error) => {
        console.error('Error en la solicitud:', error);
      }
    );
  }

	/**
	 * Método para tratar los datos que retorna el servicio y descargar el archivo.
	 * @param data data respondida por el servicio
	 * @param fileName nombre con el que se va a descargar el archivo.
	 * @param file_type Tipo del archivo 
	 * @author Juan Carlos Alonso
	 * @createdate 2021-07-27
	 * @updatedate 2022-12-13  
	 */
	fileTransition(data: any, fileName: string, file_type:string = ""): void {
		let blob = new Blob([data], { type: file_type});
		let downloadURL = (file_type) ? window.URL.createObjectURL(blob) : window.URL.createObjectURL(data);
		let link = document.createElement('a');
		link.href = downloadURL;
		link.download = fileName;
		link.click();
	}

    /**
   * Metodo generar el nombre del documento a descargar
   * @author Yeison Sepulveda
   * @createdate 2024-0-03
   * @return nombre del archivo excel
  */
  
  generateFilename(): string {
    const currentDate = new Date().toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '');
  
    const currentTime = new Date().toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).replace(/:/g, '');
  
    const dateTimeString = `${currentDate}${currentTime}`;
    return `reporte_speech_${dateTimeString}.xlsx`;
  }

/**
 * Metodo para reiniciar tabla eliminando colimna de analisis
 * @author Yeison Sepulveda
 * @createdate 2024-02-04
 */

  deleteColumnAnalytics(): void {
    const indexConfigAudio = this.displayedColumnsRecordings.findIndex(column => column === 'analisis');
    if (indexConfigAudio !== -1) {
      this.displayedColumnsRecordings.splice(indexConfigAudio, 1);
    }
  }

/**
 * Metodo para reiniciar tabla eliminando columna adherencia
 * @author Yeison Sepulveda
 * @createdate 2024-02-04
 */

  deleteColumnAdherencia(): void {
    const indexConfigAudio = this.displayedColumnsStrategy.findIndex(column => column === 'adherencia');
    if (indexConfigAudio !== -1) {
      this.displayedColumnsStrategy.splice(indexConfigAudio, 1);
    }
  }

  /**
   * Método descargar audios seleccionados
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
  */
  descargarAudios(stepperReference:any): void {
    if (this.getTotalItemSelected <= 0) {
        Swal.fire({
          title: 'No hay registros seleccionados',
          text: 'Por favor, seleccione al menos un registro para descargar.',
          icon: 'warning',
          confirmButtonText: '¡Entendido!',
          confirmButtonColor: '#2CABBC',
        });
        return;
    }
    this.buscarConDescarga(true);
    stepperReference.next()
  }

  /**
   * Método consumo consulta y descarga de los audios
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
  */
  buscarConDescarga(descargarAudios: boolean): void {
    let campaign_id = this.formFilters.get("campaign").value;
    const selectedRecordingIds = Array.from(this.selectedSearch).map(id => (id));
    this.viewservice.searchDownload(this.configPaginatorRecording, selectedRecordingIds, descargarAudios, campaign_id).subscribe(
        (response) => {

          //calcular tiempo
          response.data.data.forEach((registro: any) => {
            const startTime = new Date(registro.start_time);
            const endTime = new Date(registro.end_time);
            registro.time_difference = this.convertTimeToHHMMSS(startTime, endTime);
          });

          this.dataSourceRecordings.data = response.data.data;
          this.listrecordingsAnalising = this.dataSourceRecordings.data
          this.dataSourceRecordings.sort = this.sortRecordings;
          this.configPaginatorRecording.length = response.data.total;
          this.syncSelectionWithStored('descarga');
        },
        (error) => {
            if (error.error && error.error.error === "No se realizó ninguna descarga ni registro debido a la existencia previa de registros.") {
                console.warn("Error específico omitido:", error);
                
            } else {
              this.dataSourceRecordings.data = [];
              this.configPaginatorRecording.length = 0
              console.error('Error en la solicitud:', error);
            }
        },
    );
  }

  /**
   * Metodo consume servicio analizar y asigna los valores al datasource
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */

  analytics(): void {
    const grabaciones = Array.from(this.selectedAnalytics.values());

    this.requestService.newRequestAnalytics(grabaciones).subscribe(response => {
        // Actualizar configuraciones y datos después del análisis
        this.configPaginatorRecording.length = grabaciones.length;
        this.listrecordingsAnalising = grabaciones;

        this.dataSourceRecordings.data = this.paginateAnalyticsStaticTable(grabaciones, this.configPaginatorRecording);
        this.dataSourceRecordings.sort = this.sortRecordings;

        if (this.displayedColumnsRecordings.indexOf('analisis') === -1) {
            this.displayedColumnsRecordings.push('analisis');
        }

        this.obtenerEstadosRegistros(this.configPaginatorRecording.pageIndex);

    }, error => {
        console.error('Error al enviar la solicitud:', error);
    });
  }

  /**
   * Metodo que limita las acciones de botones por defecto
   */
  evenPrevenDefault($event){
    $event.preventDefault();
  }

  /**
  * Metodo que valida el rango de fechas seleccionadas del formulario.
  * @author Yeison Sepulveda
  * @createdate 2024-02-15
  * @returns Booleano con la validacion de las fechas.
  */
  validateRangeDates(): boolean {
    const { start_date, end_date } = this.formFilters.value;
    const momentStart = moment(start_date);
    const momentEnd = moment(end_date);
    const numberDays = momentEnd.diff(momentStart, 'days');
    return numberDays >= 7 ? true : false;
  }

  /**
   * Método que pagina la informacion de la tabla.
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
   * @param $event Evento emitido por el paginador.
  */
  changePage($event: PageEvent, tablePaginate: string): void {
    if (tablePaginate === 'recordings') {
        this.configPaginator.pageSize = $event.pageSize;
        this.configPaginator.pageIndex = $event.pageIndex + 1;
        this.search();
    } else if (tablePaginate === 'analisis' && this.displayedColumnsRecordings.find(ffnn => ffnn === 'analisis') === undefined) {
        this.configPaginatorRecording.pageSize = $event.pageSize;
        this.configPaginatorRecording.pageIndex = $event.pageIndex + 1;
        this.buscarConDescarga(false);
    } else if (tablePaginate === 'analisis' && this.displayedColumnsRecordings.find(ffnn => ffnn === 'analisis') !== undefined) {
        this.configPaginatorRecording.pageSize = $event.pageSize;
        this.configPaginatorRecording.pageIndex = $event.pageIndex + 1;
        this.dataSourceRecordings.data = this.paginateAnalyticsStaticTable(this.listrecordingsAnalising, this.configPaginatorRecording);
        this.obtenerEstadosRegistros(this.configPaginatorRecording.pageIndex);
        this.syncSelectionWithStored('analisis');
    } else if (tablePaginate === 'strategy' && this.displayedColumnsRecordings.find(ffnn => ffnn === 'analisis') !== undefined) {
        this.configPaginatorStrategy.pageSize = $event.pageSize;
        this.configPaginatorStrategy.pageIndex = $event.pageIndex + 1;
        this.dataSourceStrategy.data = this.paginateStaticTable(this.listRecordingsStrategys, this.configPaginatorStrategy);
        this.syncSelectionWithStored('strategy');
    }
  }

  /**
   * Metodo que se encarg de realziar paginacion estatica para tablas 
   * @author Yeison Sepulveda
   * @param dataTable:any[] {any[]} Listado original de datos a paginar
   * @param configPaginator:any {any} Configuracion de paginacion a aplicar de manera estatica
   * @returns {any[]} any[]
   */

  paginateAnalyticsStaticTable(dataTable: any[], configPaginator: any): any[] {
    const startIndex = (configPaginator.pageIndex - 1) * configPaginator.pageSize;
    return dataTable.slice(startIndex, startIndex + configPaginator.pageSize);
  }

  /**
   * Metodo que se encarg de realziar paginacion estatica para tablas 
   * @author Yeison Sepulveda
   * @param dataTable:any[] {any[]} Listado original de datos a paginar
   * @param configPaginator:any {any} Configuracion de paginacion a aplicar de manera estatica
   * @returns {any[]} any[]
  */
  paginateStaticTable(dataTable: any[], configPaginator: any): any[] {
    const startIndex = (configPaginator.pageIndex - 1) * configPaginator.pageSize;
    return dataTable.slice(startIndex, startIndex + configPaginator.pageSize);
  }
    
  /**
   * Metodo para abrir el modal de analisis
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  openAnalyticsModal(recording_id: any): void {
    const campaign = this.formFilters.get('campaign').value;
    const dialogRef = this.dialog.open(AnalyticsModalComponent, {
      width: '800px',
      data: { recordingId: recording_id, campaignVicidial_id: campaign } 
    });
    dialogRef.componentInstance.onClose.subscribe((hasChanges: boolean) => {
      if (hasChanges) {
        this.obtenerEstadosRegistros(this.configPaginatorRecording.pageIndex);
      }
    });
  }

  
  /**
   * Metodo para abrir el modal reproductor audio
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  openAudioModal(recording_id: any): void {
    const dialogRef = this.dialog.open(AudioPlayerComponent, {
      width: '800px',
      data: { recordingId: recording_id } 
    });
  }

  /**
   * Validar el estado de los análisi
   * @author Yeison Sepulveda
   * @update Juan DAvid Guerrero Vargas
   * @createdate 2024-03-07
  */

  async obtenerEstadosRegistros(paginaActual: number) {
    if (this.abortController) {
        this.abortController.abort();
    }
    
    this.abortController = new AbortController();
    const signal = this.abortController.signal;

    try {
        let registrosPendientes = this.dataSourceRecordings.data.filter(ffll => ffll?.analisis !== 'COMPLETADO');
        this.selectionRecordings.clear();

        while (registrosPendientes.length > 0 && this.isActive) {
            if (signal.aborted) {
                console.log('Proceso cancelado');
                return;
            }

            const response: any = await this.viewservice.analyticsState(registrosPendientes).toPromise();

            if (response.data) {
                const estados = {};
                response.data.forEach(item => {
                    estados[item.recording_id] = item.status.toUpperCase();
                });

                this.dataSourceRecordings.data.forEach(record => {
                    if (estados[record.recording_id]) {
                        record.analisis = estados[record.recording_id];
                    }
                });
            }

            registrosPendientes = this.dataSourceRecordings.data.filter(ffll => ffll.analisis !== 'COMPLETADO' && ffll.analisis !== 'ERROR');

            if (registrosPendientes.length > 0) {
                await new Promise(resolve => setTimeout(resolve, 30000));
            }
        }

        this.isAllComplete = true;
    } catch (error) {
        console.error('Error al obtener estados de registros pendientes:', error);
    }
  }

  /**
	 * Método enviar mensaje swal de error de la grabación
	 * @author Yeison Sepulveda
	 * @createdate 2024-04-23
	 */
  errorAnalyticModal(recordingId: number) {
    this.viewservice.analyticsStateDetail(recordingId).subscribe(
      (response: any) => {
        Swal.fire({
          title: '¡Aviso!',
          text: response.data.message,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Reprocesar análisis',
          cancelButtonText: 'Cancelar',
          confirmButtonColor: '#2CABBC',
          cancelButtonColor: '#FFFFFF',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.confirmReprocess(recordingId);
          }
        });
      },
      (error) => {
          console.error('Error en la solicitud:', error.error.message);
      });
  }

  /**
	 * Metodo para reprocesar análisis con error
	 * @author Yeison Sepulveda
	 * @createdate 2024-09-03
	 */
  confirmReprocess(recordingId: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: `¿Quieres volver a intentar analizar el audio ${recordingId}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, continuar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.requestService.duplicateUpdateAnalytics({ recording_id: recordingId }).subscribe(
          response => {
            Swal.fire({
              title: '¡Excelente!',
              text: 'La solicitud ha sido procesada correctamente.',
              icon: 'success',
              confirmButtonText: '¡Entendido!',
              confirmButtonColor: '#2CABBC'
            }).then((result) => {
              if (result.isConfirmed) {
                this.obtenerEstadosRegistros(this.configPaginatorRecording.pageIndex);
              }
            });
          },
          error => {
            Swal.fire({
              title: 'Opss ...',
              text: error.message,
              icon: 'error',
              confirmButtonText: '¡Entendido!',
              confirmButtonColor: '#2CABBC'
            });
          }
        );
      }
    });
  }

  /**
   * Metodo que se encarga de redirigir al metodo de aplicar estrategias
   * @author Yeison Sepulveda
   * @param stepperReference:any {any} Referencia del steper para realizar la accion de continuar el paso siguiente
   */
  applyStrategy(stepperReference?: any): void {
    this.deleteColumnAdherencia();
    this.getStrategys();
    const grabaciones = Array.from(this.selectedDownload.values());
    this.configPaginatorStrategy.length = grabaciones.length;
    this.listRecordingsStrategys= grabaciones;
    this.dataSourceStrategy.data = this.paginateStaticTable(this.listRecordingsStrategys, this.configPaginatorStrategy);
    this.dataSourceStrategy.sort = this.sortStrategy;
    this.selectionStrategy.clear();
    
    if (stepperReference !== undefined) {
        stepperReference.next();
    }
    this.startegyComplete = false;
  }
    
  /**
   * Metodo que se encarga de enviar la informacion apra aplciar la estrategia seleccionada.
   * Actualiza los registros con la información de adherencia calculada y actualiza el paginador.
   * @author Juan David Guerrero Vargas
   * @Update Yeison Sepulveda
   * @dateupdate 03-07-2024
   */

  applyStrategySelected(): void {
    this.deleteColumnAdherencia();
    
    if (this.selectedStrategy.size <= 0) {
        Swal.fire({
          title: 'No hay registros seleccionados',
          text: 'Por favor, seleccione al menos un registro para aplicar la estrategia.',
          icon: 'warning',
          confirmButtonText: '¡Entendido!',
          confirmButtonColor: '#2CABBC',
        });
        return;
    }

    const selectedRecordingIds = Array.from(this.selectedStrategy.keys());

    Swal.fire({
        title: '¿Está seguro?',
        text: `Se aplicará la estrategia a ${this.selectedStrategy.size} grabaciones.`,
        icon: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#2CABBC',
        cancelButtonColor: '#FFFFFF',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
            this.startegyComplete = true;
            
            let strategyForms = {
                id_recordings: selectedRecordingIds,
                id_strategy: this.strategyControl.value
            };

            this.strategyService.assignStrategys(strategyForms).subscribe(
                (response) => {

                    this.strategyService.applyAdherencia(strategyForms.id_recordings).subscribe(
                        (adherenciaResponse: any[]) => {
                            if (this.displayedColumnsStrategy.indexOf('adherencia') === -1) {
                                this.displayedColumnsStrategy.push('adherencia');
                            }

                            const filteredRecordings = this.listRecordingsStrategys.filter(record => selectedRecordingIds.includes(record.recording_id));
                    
                            // Actualizar información por recording id, la adherencia agregandolo como campo de adherencia
                            adherenciaResponse.forEach((item) => {
                                const adherencia = `${item.adherence_percentage} / ${item.not_adherence_percentage}`;
                                const item_recording_id = item.recording_id;
                                const recordToUpdate = filteredRecordings.find(record => record.recording_id === item_recording_id);
                                if (recordToUpdate) {
                                    recordToUpdate.adherencia = adherencia;
                                }
                            });

                            // Actualizar información
                            this.listRecordingsStrategys = filteredRecordings;

                            this.configPaginatorStrategy.length = this.listRecordingsStrategys.length;

                            // Nuevo MatTableDataSource para evitar problemas de paginado
                            this.dataSourceStrategy = new MatTableDataSource(this.paginateStaticTable(this.listRecordingsStrategys, this.configPaginatorStrategy));
                            this.dataSourceStrategy.sort = this.sortStrategy;

                        },
                        (adherenciaError: any) => {
                            console.error("Error al calcular la adherencia:", adherenciaError.error);
                        }
                    );
                },
                (error: any) => {
                    console.error("Error al asignar la estrategia:", error);
                }
            );
        }
    });
  }

  /**
   * Método para abrir el modal de resultados de adherencia
   * @author Yeison Sepulveda
   * @createdate 2024-04-30
  */
  AdherenciaModal(recording_id: any): void {
    const recording = this.listRecordingsStrategys.find(record => record.recording_id === recording_id);
    if (recording && recording.adherencia) {
        const dialogRef = this.dialog.open(AdherenciaModalComponent, {
            width: '800px',
            data: { recordingId: recording_id } 
        });
    } 
  }

  /**
   * Metodo validador para revisar campo duration
   * @author Yeison Sepulveda
   * @createdate 2024-05-23
  */
  timeGreaterThanOneMinuteValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      
      if (value === null || value === '') {
        return null;
      }
  
      if (typeof value !== 'string' || !/^\d{2}:\d{2}$/.test(value)) {
        return { 'invalidFormat': true };
      }
  
      const [hours, minutes] = value.split(':').map(Number);
  
      if (hours === 0 && minutes < 1) {
        return { 'timeGreaterThanOneMinute': true };
      }
  
      return null;
    };
  }

  /**
   * Método validar si ya esta la columna de analisis y si el total es mayor
   * @author Yeison Sepulveda
   * @createdate 2024-06-18
   * @returns El valor true o false
  */

  get ColumnVisibleTotal(): boolean {
    const isVisible = this.displayedColumnsRecordings.includes('analisis');
    const hasSelectedItems = this.getTotalItemSelectedRecordings() > 0;
    return isVisible && hasSelectedItems;
  }
  
  /**
   * Método validar si ya esta la columna de analisis
   * @author Yeison Sepulveda
   * @createdate 2024-06-18
   * @returns El valor true o false
  */

  get isAnalisisColumnVisible(): boolean {
    return this.displayedColumnsRecordings.includes('analisis');
  }

  /**
   * Metodo que se encarga de abirir el modal apra poder abrir el modal de creacion de estrategias
   * @author Yeison Sepulveda
   * @date 2024-06-19
   */
  createStrategyTranscription(): void {
    const selectedRecordingIds = Array.from(this.selectedStrategy.keys());

    if (selectedRecordingIds.length === 1) {
        const selectedRecordingId = selectedRecordingIds[0];

        const campaign = this.formFilters.get('campaign').value;

        const dialogRef = this.dialog.open(TranscricpcionStrategyComponent, {
            width: '100%',
            maxHeight: '95vh',
            data: { modo: 'crear', recordingId: selectedRecordingId, campaignVicidial_id: campaign }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.getStrategys();
        });
    } else if (selectedRecordingIds.length > 1) {
        Swal.fire({
          title: 'Advertencia',
          text: 'Seleccionaste más de un registro. Por favor, selecciona solo uno.',
          icon: 'warning',
          confirmButtonText: '¡Entendido!',
          confirmButtonColor: '#2CABBC',
        });
    } else {
        Swal.fire({
          title: 'Advertencia',
          text: 'Selecciona al menos un registro antes de continuar.',
          icon: 'warning',
          confirmButtonText: '¡Entendido!',
          confirmButtonColor: '#2CABBC',
        });
    }
  }


  /**
   * Modal para abrir resultados de analisis de sentiemientos masivo
   * @author Yeison Sepulveda
   * @date 2024-08-08
  */

  analytics_emociones(): void {
    const selectedRecordingIds = Array.from(this.selectedAnalytics.keys());
    console.log(' la data envio emociones segunda', selectedRecordingIds);

    const dialogRef = this.dialog.open(AnalyticsVoiceGeneralComponent, {
      width: '800px',
      data: { id_recordings: selectedRecordingIds } 
    });
  }

  
}
