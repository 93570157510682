import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../core/services/rest/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UpdatesService {
  private server =  environment.RRHH_URL;
  constructor(private http:HttpClient, private activeRoute: ActivatedRoute, private authService:AuthService) { }
  getUpdates(){

  }
  postUpdates(update, file:File){
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${this.server}${update}/import`, formData);
  }
  downloadFormat(update){
    return this.http.get(`${this.server}${update}/export/format`, {responseType:'blob'});
  }
}
