import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const MEETINGS_URL = environment.MEETINGS_URL;

@Injectable({
  providedIn: 'root'
})
export class VideollamadaService {

constructor(private http: HttpClient) { }

handleError(error: HttpErrorResponse): any {
  return throwError(error);
}

  private MeetResponseSubject = new Subject<any>();
  MeetResponseObservable = this.MeetResponseSubject.asObservable();

  private stateSubject = new Subject<any>();
  stateObservable = this.stateSubject.asObservable();

  /**
   * @author Javier Castañeda
   * @purpose Servicio para actualizar Estado del asesor
   */
   UpdateAdviserState(data: any): Observable<any> {
    return this.http.put<any>(`${MEETINGS_URL}Adviser`, data)
      .pipe(
        catchError(this.handleError) 
      );
  }

  getAdviserById(id: number){
    return this.http.get<any>(`${MEETINGS_URL}AdviserBy/${id}`)
    .pipe(
      catchError(this.handleError) 
    );
  }

    /**
   * @author Javier Castañeda
   * @purpose Servicio para actualizar Estado de la sala
   */
    UpdateRoomState(data: any): Observable<any> {
      return this.http.put<any>(`${MEETINGS_URL}Room`, data)
        .pipe(
          catchError(this.handleError) 
        );
    }

        /**
   * @author Javier Castañeda
   * @purpose Servicio para envio de archivo de una sala
   */
    SendFileRoom(data: any): Observable<any> {
      return this.http.post<any>(`${MEETINGS_URL}RoomFile`, data)
      .pipe(
        catchError(this.handleError) 
      );
    }

    /**
   * @author Javier Castañeda
   * @purpose Servicio para obtener los archivos de una sala
   */
    getFilesByRoomId(id: number){
      return this.http.get<any>(`${MEETINGS_URL}GetRoomFiles/${id}`)
      .pipe(
        catchError(this.handleError) 
      );
    }
    
    sendEncolamiento(data: any){
      return this.http.post<any>(`${MEETINGS_URL}Encolamiento`, data)
      .pipe(
        catchError(this.handleError) 
      );
    }

    /**
   * @author Javier Castañeda
   * @purpose Servicio para obtener los archivos de una sala
   */
    getRoomById(id: number){
      return this.http.get<any>(`${MEETINGS_URL}RoomBy/${id}`)
        .pipe(
          catchError(this.handleError) 
        );
    }

        /**
   * @author Javier Castañeda
   * @purpose Observable para el envio de nic al formulario
   */
  setMeetResponse(value) {
      this.MeetResponseSubject.next(value);
  }

    /**
   * @author Javier Castañeda
   * @purpose Observable para el envio de estado del asesor
   */
  setStateCall(value) {
    this.stateSubject.next(value);
  }

}
