import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatrizService } from 'src/app/modules/calidad/services/matriz.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
	selector: 'app-crear-modulo',
	templateUrl: './crear-modulo.component.html',
	styleUrls: ['./crear-modulo.component.sass']
})
export class CrearModuloComponent implements OnInit {
  private uuid = 'eff904ae-908d-11ee-b9d1-0242ac120002';
  public config: any;

	moduleForm: FormGroup;
	modulos = [];
	filteredOptions: Observable<any[]>;
	verError: boolean = true;
	continue: boolean = true;
	nuevo: boolean = false;
	peso: boolean = true;

	constructor(
		private fb: FormBuilder,
		public moduleRef: MatDialogRef<CrearModuloComponent>,
		@Inject(MAT_DIALOG_DATA) public datos: any,
		private matrizService: MatrizService,
    private configService: TranslatesService,
	) { }

	ngOnInit(): void {
		this.createForm();
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
	}

	createForm() {
		this.moduleForm = this.fb.group({
			nombre: ['', [Validators.required]],
			peso: ['', [Validators.min(this.datos.minimo), Validators.max(this.datos.maximo), Validators.required]],
			is_total: [{ value: false, disabled: true }, []],
			error_critico: [{ value: false, disabled: true }, Validators.required],
		});

		if (this.datos.tipo_matriz == "Valor" || this.datos.tipo_matriz == "Value" ) {
			if (this.datos.tipo_peso) {
				if (this.datos.tipo_peso == 'Peso en Items' || this.datos.tipo_peso == 'Weight in Items') {
					this.peso = true;
					this.moduleForm.get('peso').setValue(null);
					this.moduleForm.get('peso').disable();
					this.moduleForm.get('peso').setErrors(null);
				}
			}
		}

	}

	private _filter(value: string): any[] {
		const filterValue = value.toLowerCase();
		return this.modulos.filter(option => option.nombre.toLowerCase().includes(filterValue));
	}

	displayFn(module: any): string {
		return module && module ? module : '';
	}

	getModules() {
		this.matrizService.getModules(this.moduleForm.get('nombre').value).subscribe((resp: any) => {
			if (resp.length == 0) {
				this.verError = true
				this.nuevo = true;
				this.moduleForm.get('nombre').setErrors({ invalid: true });
				this.peso = true;
				this.moduleForm.get('error_critico').disable();
				this.continue = true;
			} else {
				this.verError = false;
				this.nuevo = false;
				this.modulos = resp
				this.peso = true;
				this.filteredOptions = this.moduleForm.get('nombre').valueChanges.pipe(
					startWith(''),
					map(value => this._filter(value))
				);
			}
		});
	}

	newModule() {
		this.nuevo = false;
		this.moduleForm.get('nombre').setErrors(null);
		if (this.datos.tipo_matriz == "Cumplimiento" || this.datos.tipo_matriz == "Compliance") {
			this.peso = true
		} else {
			if (this.datos.tipo_peso) {
				if (this.datos.tipo_peso == 'Peso en Módulos' || this.datos.tipo_peso == 'Weight in Modules') {
					this.peso = true;
				} else {
					this.peso = true;
					this.moduleForm.get('peso').setValue(null);
					this.moduleForm.get('peso').setErrors(null);
				}
			}
		}
		this.continue = false;
		this.moduleForm.get('error_critico').enable();
	}

	saveModule() {
		if (this.moduleForm.invalid) {
			return
		}
		if (
			this.moduleForm.get('error_critico').value
			&& (this.moduleForm.get('peso').value == "" || this.moduleForm.get('peso').value == undefined || this.moduleForm.get('peso').value == null)
			&& this.moduleForm.get('is_total').value == false) {
			this.moduleForm.get('peso').setErrors({ required: true });
			return;
		}

    const data = {
      idMatriz: this.datos.id_matriz,
      lang: this.configService.lang
    };

		Object.assign(data, this.moduleForm.value);
		this.matrizService.newModule(data).subscribe((resp: any) => {
			if (resp.respuesta.codigo == 202) {
				Tools.swalSuccess(this.config.modal.excellent, this.config.modal.saveOk);
				this.moduleRef.close();
			} else if (resp.respuesta.codigo > 400) {
				Tools.swalError('¡Oops!', this.config.modal.saveError);
			}
		}
		);
	}

	//Obtener los controles del formulario
	get errors(): any {
		return this.moduleForm.controls;
	}

	/** Metodo que se encarga de habilit el peso
	 * @author  Juan David Guerrero Vargas
	 * @create_date 16/06/2022
	 * @param event:any evento de HTML
	 */
	habilitarPeso(event: any): void {
		if (event.checked) {
			this.moduleForm.get('peso').setValue('');
			this.moduleForm.get('peso').disable();
			this.moduleForm.get('peso').setErrors(null);
			this.moduleForm.get('peso').setValidators(null)
		} else {
			this.moduleForm.get('peso').setValue('');
			this.moduleForm.get('peso').enable();
			if (this.moduleForm.get('error_critico').value) {
				this.moduleForm.get('peso').setValidators([Validators.min(this.datos.minimo), Validators.max(this.datos.maximo), Validators.required])
				this.moduleForm.get('peso').setErrors({ required: true });
			} else {
				this.moduleForm.get('peso').setErrors(null);
				this.moduleForm.get('peso').setValidators([Validators.min(this.datos.minimo), Validators.max(this.datos.maximo)])
			}
		}

	}

	/**
	 * Metodo que habilita check de afectacion total
	 * @author Juan David Guerrero Vargas
	 * @create_date 16/06/2022
	 * @param event :evento de cambio de estado en changue
	 * @return void
	 */
	habilitaCheck(event: any): void {
		if (event.checked) {
			this.moduleForm.get('peso').setValidators([Validators.min(this.datos.minimo), Validators.max(this.datos.maximo), Validators.required])
			this.moduleForm.get('peso').enable()
			this.moduleForm.get('is_total').enable();
		} else {
			this.moduleForm.get('peso').setValidators(null)
			this.moduleForm.get('is_total').disable();
			if (this.datos.tipo_matriz == "Valor" || this.datos.tipo_matriz == "Value") {
				if (this.datos.tipo_peso) {
					if (this.datos.tipo_peso == 'Peso en Items' || this.datos.tipo_peso == 'Weight in Items') {
						this.peso = true;
						this.moduleForm.get('peso').setValue(null);
						this.moduleForm.get('peso').disable();
						this.moduleForm.get('peso').setErrors(null);
					} else {
						this.moduleForm.get('peso').setValue([Validators.min(this.datos.minimo), Validators.max(this.datos.maximo), Validators.required]);
						this.moduleForm.get('peso').enable()
					}
				}
			}

		}
	}

}
