import { Component, OnInit, Inject } from '@angular/core';
import { CandidatesService } from '../../../services/candidates.service';
import { TestsService } from '../../../services/tests.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { UploadTestComponent } from '../upload-test/upload-test.component';

@Component({
  selector: 'app-massive-tests',
  templateUrl: './massive-tests.component.html',
  styleUrls: ['./massive-tests.component.sass']
})
export class MassiveTestsComponent implements OnInit {
  testsList: any = [];
  allCandidateSelected = false;
  candidates:any = [];
  candidatesSelected:any = [];
  selectedTestsNames = [];
  tests: FormGroup = this._fb.group({
    requestId:[this.data.requestId],
    testIds:[[],Validators.required],
    candidateIds:[[],Validators.required]
  });
 
  constructor(private testService:TestsService, 
    private candidatesService: CandidatesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private _fb: FormBuilder, public alertService:AlertsService,
    public dialogRef: MatDialogRef<MassiveTestsComponent>) { }

  ngOnInit(): void {
    this.testService.getTestsList().subscribe( resp => {
      this.testsList = resp;
    });
    this.getCandidates()
  }
  getCandidates(){
    this.candidatesService.getCandidatesByRequest(this.data.requestId,
      {page: 'all', state: 22}).
        subscribe((resp: any) => {
        this.candidates = resp
        });
  }
 
  finishActions(){
  
    this.testService.saveTests({candidateIds:this.tests.get('candidateIds').value.toString(), testIds: this.tests.get('testIds').value.toString(), requestId: this.data.requestId})
    .subscribe( resp => {
      if(this.tests.get('testIds').value.includes(3) || this.tests.get('testIds').value.includes(1)){
        const uploadTestDialog = this.dialog.open ( UploadTestComponent, {
          minWidth: '55%',
          disableClose: true,
          data: {
            testsNames:this.selectedTestsNames,
            candidateId: this.tests.get('candidateIds').value.toString(),
            requestId: this.data.requestId
          }
        });
        uploadTestDialog.afterClosed().subscribe(res=>{
          this.alertService.alertSuccess('¡Hecho!', 'Pruebas asignadas correctamente');
          this.dialogRef.close(true)
        })
      }
       
      });
  }
  pushArray(event) {
    this.tests.get('testIds').setValue(event.selectedOptions.selected.map(item => item.value.id))  
    this.selectedTestsNames = event.selectedOptions.selected.map(item => item.value);
  }
  pushCandidates(event){
    this.tests.get('candidateIds').setValue(event.selectedOptions.selected.map(item => item.value.id))
    this.candidatesSelected= event.selectedOptions.selected.map(item => item.value.name);
  }

}
