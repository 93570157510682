<div class="container-fluid p-5">
  <div class="row">
    <div class="col-md-12">
      <span style="font-size: 32px; font-weight: 700;">Gestor de descargas</span>
    </div>
    <div class="col-md-12 mt-4">
      <p class="lh-base" style="color: #353535">
        En este espacio podrás revisar y/o realizar la gestión para programar descargas <br />
        de las llamadas registradas en Vicidial, no olvides, “El respeto y empatía son clave”.
      </p>
    </div>
  </div>
  <div class="row justify-content-between mt-5">
    <div class="d-flex col-md-6">
      <div class="col-md-6">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Fecha</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="dateSelected" (dateChange)="onChangeDate($event)">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-6 ms-4">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Estado</mat-label>
          <mat-select (selectionChange)="onChangeState($event)" [(ngModel)]="stateSelected">
            <mat-option *ngFor="let rw of estados" [value]="rw.id">{{ rw.nombre }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-6 d-flex justify-content-end">
      <div class="col-md-3">
        <button style="height: 48px; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;"
          class="w-100" (click)="clearFilters()" mat-raised-button>
          Limpiar
          <span class="fi-rr-cross-circle ms-2"></span>
        </button>
      </div>
      <div class="col-md-6 ms-4">
        <button class="w-100" mat-raised-button color="primary" style="height: 48px;"
          (click)="goToCreateDownloadPage()">
          Crear descarga
          <span class="fi-rr-add ms-2"></span>
        </button>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-md-12">
      <p class="lh-base" style="color: #717171">
        <b>Nota:</b> Recuerda que <b>cuando la descarga este completa tienes solo
          <span color="primary" class="blue">24 horas </span>
          para realizar la descarga,</b> después de esto se eliminara automáticamente. Ten presente que cuando la
        descarga es fallida no se completara la descarga y podrás ver el detalle de los errores.
      </p>
    </div>
  </div>
  <div *ngIf="downloads.length > 0; else notRegisters">
    <div class="row mt-4">
      <div *ngFor="let item of downloads" class="col-md-3 pt-5">
        <div class="card text-center">
          <div class="card-header">
            <div class="icono_gestor">
              <div class="icono_gestor_hijo"
                [ngClass]="{ 'color-completa': item.state_download === namesStatesDownloads[0], 'color-descargando': item.state_download === namesStatesDownloads[1], 'color-eliminada': item.state_download === namesStatesDownloads[2], 'color-fallida': item.state_download === namesStatesDownloads[3] }">
                <span
                  [ngClass]="{ 'fi-rr-checkbox': item.state_download === namesStatesDownloads[0], 'fi-rr-download': item.state_download === namesStatesDownloads[1], 'fi-rr-trash': item.state_download === namesStatesDownloads[2],  'fi-rr-cross-circle': item.state_download === namesStatesDownloads[3] }"></span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="card-body bb-1">
              <p class="card_gestor_texto">{{ item.state_download }}</p>
              <p class="card_gestor_detalles">{{ item.name_file }}</p>
            </div>
            <ul class="list-group list-group-flush pt-3">
              <li class="list-group-item"><span>Fecha de creación: <span class="fw-normal">{{ item.created_at | date:
                    'dd/MM/yyyy - HH:mm' }}</span></span></li>
              <li class="list-group-item"><span>Nombre del solicitante: <span class="fw-normal">{{ item.userdata.name
                    }}</span></span>
              </li>
              <li class="list-group-item"><span>Camapaña: <span class="fw-normal">{{
                    item.name_file.split('-')[0] }}</span></span></li>
            </ul>
          </div>
          <div class="card-footer text-muted py-3">
            <button *ngIf="item.id_state_download !== 2" mat-button (click)="downloadFile(item.id)"><span
                class="fi-rr-download me-2"></span>{{ item.id_state_download === 1 ? 'Descargar' : 'Ver detalle'
              }}</button>
            <button *ngIf="item.id_state_download !== 3" mat-button (click)="deleteFile(item.id)"><span
                class="fi-rr-delete me-2"></span>Eliminar</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-end mt-4">
      <div class="col-md-4">
        <mat-paginator [length]="paginator.length" [pageSize]="paginator.pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="onChangePage($event)">
        </mat-paginator>
      </div>
    </div>
  </div>
  <ng-template #notRegisters>
    <div class="row d-flex align-items-center" style="height: 50vh;">
      <div class="col-md-12 text-center">
        <span style="font-size: 32px; font-weight: 700; color: #A9A9A9;">No existen registros almacenados</span>
      </div>
    </div>
  </ng-template>
</div>