import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MailService } from '../../../services/rest/mail.service';

@Component({
  selector: 'app-view-mail',
  templateUrl: './view-mail.component.html',
  styleUrls: ['./view-mail.component.sass']
})
export class ViewMailComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public mailService: MailService) { }

  ngOnInit(): void {
    const data = new FormData();
    if (this.data.menu === 'sent') {
      data.append ( 'emailId', this.data.data.emailId);
    } else if (this.data.menu === 'queue' || this.data.menu === 'historic' || this.data.menu === 'search'){
      data.append ( 'emailId', this.data.data.id);
    }
    this.mailService.postObtenerCorreo(data).subscribe((respo) => {
      if (!this.data.data.cuerpo){
        this.data.data.cuerpo = respo[0].cuerpo
      }
    })
  }

}
