import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { ProfileService } from 'src/app/modules/ciu/services/rest/profile.service';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';


@Component({
  selector: 'app-forgot-change-password',
  templateUrl: './forgot-change-password.component.html',
  styleUrls: ['./forgot-change-password.component.sass']
})
export class ForgotChangePasswordComponent implements OnInit {
  passForm: FormGroup;
  hide: boolean = true;
  hideDos: boolean = true;
  hideTres: boolean = true;
  id : any;

  constructor(
              public authService: AuthService,
              private profileService: ProfileService,
              private snack: MatSnackBar,
              private destroyService: DestroyComponentService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe( params => this.id = params['id']);
    this.formControl();
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-05-19
   * Metodo que regula las validaciones de cada campo del formulario de cambio de contraseña
   */
  formControl(): void{

    this.passForm = new FormGroup({
      nueva: new FormControl('', [this.passwordValidate(),Validators.required,Validators.maxLength(15),Validators.minLength(5)]),
      confirma: new FormControl('', [this.passwordValidate(),Validators.required,Validators.maxLength(15),Validators.minLength(5), this.matchValues('nueva')])
    });

  }

  get error(): any { return this.passForm.controls; }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que se encarga de las diferentes validaciones de los campos contraseña
   * @returns control de error
   */
  passwordValidate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!/[A-Z]/.test(control.value)) {
        return { hasCapitalCase: { value: control.value } };
      }
      else if (!/[a-z]/.test(control.value)) {
        return { hasSmallCase: { value: control.value } };
      }
      else if (!/[!@#$%^&*()_+=[{};':"|,.<>/?/{};':"|,.<>/?-]/.test(control.value)) {
        return { hasSpecialCharacters: { value: control.value } };
      }
      else if (!/\d/.test(control.value)) {
        return { hasNumber: { value: control.value } };
      }
      return null;
    };
  }

/**
 * @author Daniel Martinez
 * @createdate 2021-01-27
 * Metodo que se encarga de verificar si la contraseña nueva y el campo de validacion son iguales
 * @param matchTo contraseña en el campo de validacion
 * @returns control de error
 */
  matchValues( matchTo: string ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que se encarga de omitir algunos caracteres en el campo de contraseña
   * @param event valor a evaluar
   * @returns boolean
   */
  omitSpecialChar(event): any{
    let k;
    k = event.charCode;
    return((k > 32 && k < 126));
  }

  /**
   * @author Carlos Nieto
   * @createdate 2021-12-10
   * Metodo que se encarga de realizar el cambio de la contraseña
   */
  changePass(): void{

    const formData =  new FormData();

    formData.append( 'token' , this.id);
    formData.append( 'new_password' , this.passForm.value.confirma);

    this.authService.changePasswordAuth(formData).subscribe((resp) => {

      if (resp?.data === 'OK' || resp?.data?.ciu === "OK") {
        Swal.fire({
          html: `Tu contraseña se ha cambiado con éxito`,
          icon: 'success',
          confirmButtonText: 'Aceptar'
        }).then(() =>  {
            this.router.navigateByUrl('/login');
          });
      } else if(resp?.error_code == 401 || resp?.error_code == 403)
      {
        Swal.fire({
          html: `${resp.error}`,
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

}
