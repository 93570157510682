import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CoachingState } from "../coaching.state";

const getCoachingState = createFeatureSelector<CoachingState>('COACHING');

export const historialSolicitud = createSelector(
    getCoachingState,
    (state) => state.historial.historial,
);

export const selectHistorial = createSelector(
    getCoachingState,
    (state) => state.historialById.historial,
);