import { Component, OnInit, AfterViewInit, ViewChild,OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { RequestsService } from '../../../services/requests.service';
import { DestroyComponentService } from '../../../../../core/services/utils/destroy-component.service';

@Component({
  selector: 'app-requests-main',
  templateUrl: './requests-main.component.html',
  styleUrls: ['./requests-main.component.sass']
})
export class RequestsMainComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['view', 'code', 'campaign', 'position', 'personalRequest', 'tracingAvg', 'state'];
  dataSource: MatTableDataSource<any>;

  length: number;
  pageSize: number;
  currentPage = 1;
  showRequests = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  code: any = '';
  position: any = '';

  constructor(private requestsService: RequestsService,
              private router: Router,
              private activatedRoute:ActivatedRoute,
              private destroyService:DestroyComponentService) {
                this.getRequests();
              }
  ngOnInit(): void {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  viewDetails(id) {
   
     this.router.navigate(['../solicitud', id], {relativeTo: this.activatedRoute});

  }

  getRequests(){
    this.requestsService.getRequestsByUser({page: this.currentPage , code: this.code, position: this.position}).subscribe( (requests: any) => {
      if (requests.total > 0) this.showRequests = true;
      this.length = requests.total;
      this.pageSize = requests.per_page;
      let dataSorted = requests.data;
      this.dataSource = new MatTableDataSource(requests.data);
    });
  }

  getPaginatorData(e){
    this.currentPage = e.pageIndex + 1;
    this.getRequests();
  }

  filterByCode(code){
    this.code = code;
    this.getRequests();
  }
  filterByPosition(position){
    this.position = position;
    this.getRequests();
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }
}
