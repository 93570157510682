<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<h3>Pruebas por lotes</h3>
<hr>
<mat-dialog-content>
    <mat-horizontal-stepper linear #stepper>
        <mat-step label="Pruebas disponibles" [completed]="tests.value.testIds.length > 0" >
            <div  style="padding: 15px 25px 0;">
                <p><strong>Seleccione las pruebas que desea asignar</strong> </p>
                <mat-selection-list #testSelected>
                    <mat-list-option checkboxPosition *ngFor="let test of testsList" [selected]="test.selected" (click)="pushArray(testSelected)" [value]="test">
                        {{test.name}}
                    </mat-list-option>
                </mat-selection-list>
                <div align="center" class="btns">
                    <button mat-flat-button matStepperNext color="primary">Siguiente</button>
                </div>
            </div> 
        </mat-step>
        <mat-step label="Candidatos" [completed]="tests.value.candidateIds.length > 0">
            <p><strong>Seleccione a los candidatos</strong> </p> 
            <div fxLayout="row" fxLayoutAlign="end center"  *ngIf="candidates.length > 0"> 
                <mat-checkbox  
                                [checked]="allCandidateSelected"
                                (change)="allCandidateSelected= $event.checked; 
                                allCandidateSelected? candidateSelected.selectAll():candidateSelected.deselectAll(); 
                                pushCandidates(candidateSelected)">
                                Seleccionar todos
                </mat-checkbox>
                   
            </div>
            <p *ngIf="candidates.length == 0">No hay candidatos disponibles</p>
            <div class="list_items">
                <mat-selection-list #candidateSelected>
                    <mat-list-option checkboxPosition *ngFor="let candidate of candidates" [selected]="candidate.selected" (click)="pushCandidates(candidateSelected)" [value]="candidate">
                        {{candidate.name}}
                    </mat-list-option>
    
                </mat-selection-list>
            </div>
            
            
            <div align="center" class="btns">
                <button mat-stroked-button matStepperPrevious>Anterior</button>
                <button mat-raised-button color="primary" matStepperNext >Siguiente</button>
            </div> 
        </mat-step>
        <mat-step label="Confirmar" >
            <p> <strong>Resumen de Acción</strong> </p>
            <mat-divider></mat-divider>
            <br>
            <b>Pruebas asigandas</b>
            <br>
            <div fxLayout="column" style="padding-left: 5px;">
                <p *ngFor="let row of selectedTestsNames"> {{row.name}}</p>
            </div>
            <b>Candidatos Seleccionados</b>
            <div  fxLayout="row wrap" fxLayoutGap="20px grid">
                    <p fxFlex=30 *ngFor="let row of candidatesSelected;let i = index">{{ i+1 }}. {{row}}</p>
            </div>
            <div align="center" class="btns">
                <button mat-stroked-button matStepperPrevious>Anterior</button>
                <button mat-raised-button color="primary"(click)="finishActions()">Guardar </button>
            </div>
            
        </mat-step>
    </mat-horizontal-stepper>
</mat-dialog-content>