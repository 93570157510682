<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<h3>{{data.action}} sala de assessment</h3>
<hr>
<mat-dialog-content>
  
    <mat-horizontal-stepper linear #stepper>
        <mat-step label="Sala" [completed]="assessmentForm.value.description" >
        <form [formGroup]="assessmentForm" fxLayout="row wrap"  fxLayoutGap="20px" style="padding: 0 23px;">
            <mat-form-field appearance="fill" fxFlex="calc(50% - 20px)">
                <mat-label>Nombre</mat-label>
                <input type="text" matInput autocomplete="off" formControlName="name" required>
            </mat-form-field>
        
            <mat-form-field appearance="fill" fxFlex="calc(50% - 20px)">
                <mat-label>Capacidad</mat-label>
                <input type="number" matInput autocomplete="off" formControlName="capacity" required>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="calc(50% - 20px)">
                <mat-label>Fecha Inicio</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="start">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="calc(50% - 20px)">
                <mat-label>Fecha Fin</mat-label>
                <input matInput [matDatepicker]="picker2" [min]="assessmentForm.value.start" formControlName="end">
                <mat-datepicker-toggle matSuffix [for]="picker2" ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
           
            <mat-form-field appearance="fill" fxFlex="calc(50% - 20px)">
                <mat-label>Responsable de selección</mat-label >
                    <input  matInput formControlName="selection_name" [disabled]="true" >
                    {{user_selection_name}}
                <button mat-icon-button type="button" matSuffix matTooltip="Buscar Responsable"
                (click)="searchUsers('evaluador', 'selection')">
                    <i class="fi-rr-search" ></i>
                  </button>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="calc(50% - 20px)"> 
                <mat-label>Responsable de operación</mat-label>
                <input matInput formControlName="operation_name" [disabled]="true">
                {{user_operation_name}} 
                <button mat-icon-button type="button" matSuffix matTooltip="Buscar Responsable"
                (click)="searchUsers('evaluador','operation')">
                    <i class="fi-rr-search" ></i>
                  </button>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="48">
                <mat-label>Responsable de formación</mat-label>
                <input matInput formControlName="formation_name"  [disabled]="true">
                {{user_formation_name}}
                <button mat-icon-button type="button" matSuffix matTooltip="Buscar Responsable"
                (click)="searchUsers('evaluador', 'formation')">
                    <i class="fi-rr-search" ></i>
                  </button>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="48">
                <mat-label>Descripción</mat-label>
                <textarea matInput required formControlName="description"></textarea>
            </mat-form-field>
            
        </form>
        <div align="center" class="btns">
            <button mat-stroked-button mat-dialog-close>Cancelar</button>
            <button mat-flat-button matStepperNext color="primary">Siguiente</button>
            
        </div>

        </mat-step>
        <mat-step label="Candidatos" [completed]="assessmentForm.value.candidate_ids">
            <p><strong>Seleccione a los candidatos</strong> </p> 
            <div fxLayout="row" fxLayoutAlign="end center"  *ngIf="candidates.length > 0"> 
                <mat-checkbox  
                                [checked]="allCandidateSelected"
                                (change)="allCandidateSelected= $event.checked; 
                                allCandidateSelected? candidateSelected.selectAll():candidateSelected.deselectAll(); 
                                pushCandidates(candidateSelected)">
                                Seleccionar todos
                </mat-checkbox>
                   
            </div>
            <p *ngIf="candidates.length == 0">No hay candidatos disponibles</p>
            <div class="list_items">
                <mat-selection-list #candidateSelected>
                    <mat-list-option checkboxPosition *ngFor="let candidate of candidates" [selected]="candidate.selected" (click)="pushCandidates(candidateSelected)" [value]="candidate">
                        {{candidate.name}}
                    </mat-list-option>
    
                </mat-selection-list>
            </div> 
            
            <div align="center" class="btns">
                <button mat-stroked-button matStepperPrevious>Anterior</button>
                <button mat-flat-button color="primary" matStepperNext >Siguiente</button>
            </div> 
        </mat-step>
        <mat-step label="Confirmar" >
          <p> <strong>Resumen de Acción</strong> </p>
            <mat-divider></mat-divider>
            <br>
            <p> <b>Sala de assessment</b> {{assessmentForm.value.name}}</p>
            <div fxLayout="column" style="padding-left: 5px;">
                
                <p> <b>Responsable de selección</b> {{user_selection_name}}</p>
                <p> <b>Responsable de operación</b>{{user_operation_name}} </p>
                <p> <b>Responsable de formación</b> {{user_formation_name}}</p>
            </div>
            <p><b>Candidatos Seleccionados</b></p>
            <div  fxLayout="row wrap" fxLayoutGap="20px grid">
                    <p fxFlex=30 *ngFor="let row of candidatesSelected;let i = index">{{ i+1 }}. {{row}}</p>
            </div>
            <div align="center" class="btns">
                <button mat-stroked-button matStepperPrevious>Anterior</button>
                <button mat-raised-button color="primary" [disabled]="assessmentForm.invalid" (click)="createAssessment()">{{data.action}}</button>
            </div> 
            
        </mat-step>
    </mat-horizontal-stepper>
</mat-dialog-content>