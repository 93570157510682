import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InteligenciaService } from 'src/app/modules/auditoria/services/inteligencia.service';

@Component({
  selector: 'app-matrices-asignadas',
  templateUrl: './matrices-asignadas.component.html',
  styleUrls: ['./matrices-asignadas.component.sass']
})
export class MatricesAsignadasComponent implements OnInit {

  matrices = [];
  constructor(
    private inteligenciaS:InteligenciaService,
    @Inject(MAT_DIALOG_DATA) public datos:any,
  ) { }

  ngOnInit(): void {
    this.getMatrix();
  }

  getMatrix(){
    this.inteligenciaS.getMatrix(this.datos.id).subscribe((resp:any)=>{
      this.matrices = resp;
    });
  }

}
