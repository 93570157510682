import { Injectable } from '@angular/core';

// Models
import { Campaign } from '../models/campaign';
import { CampaignUser } from '../models/campaign-user';

// Services
import { AuthService } from '../../../../../core/services/rest/auth.service';

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {
	cartera = 'cartera_user';
	simulator = 'simuladorData';
	gestionConsecuencia = 'consecuenciaData'

	constructor(private authService: AuthService) {}

	/**
	 * Método para extraer del localStorage el id de rrhh del usuario logeado.
	 * @returns id del usuario logeado.
	 * @author Carlos Gómez
	 * @createdate 2021-04-26
	 * @update 2021-09-08
	 */
	getRRHHID(): number {
		return this.authService.getUser().rrhh_id;
	}

	/**
	 * Método para extraer del localStorage el id de la campaña del usuario logeado.
	 * @returns id de la campaña a la que pertenece el usuario logeado.
	 * @author Carlos Gómez
	 * @createdate 2021-05-07
	 */
	getCampaignID(): number {
		return JSON.parse(localStorage.getItem(this.cartera))?.campaign?.id;
	}

	/**
	 * Método para extraer del localStorage el id de la campaña del usuario logeado.
	 * @returns id de la campaña a la que pertenece el usuario logeado.
	 * @author Carlos Gómez
	 * @createdate 2021-05-07
	 */
	getCampaignCode(): number {
		return JSON.parse(localStorage.getItem(this.cartera))?.campaign?.code;
	}

	/**
	 * Método para extraer del localStorage el id de la campaña del usuario logeado.
	 * @returns id de la campaña a la que pertenece el usuario logeado.
	 * @author Carlos Gómez
	 * @createdate 2021-05-07
	 */
	getCampaign(): Campaign {
		return JSON.parse(localStorage.getItem(this.cartera))?.campaign;
	}

	/**
	 * Método para almacenar la campaña del usuario en el localStorage
	 * @param campaign campaña del usuario
	 * @author Carlos Gomez
	 * @createdate 2021-05-24
	 */
	setCampaignUser(campaign: CampaignUser): void {
		localStorage.setItem(this.cartera, JSON.stringify(campaign));
	}

	/**
	 * Método para eliminar la cartera del localStorage
	 * @author Carlos Gomez
	 * @createdate 2021-05-24
	 */
	removeCampaign(): void {
		localStorage.removeItem(this.cartera);
	}

	/**
	 * Método para obtener los valores previamente simulados.
	 * @author Carlos Gómez
	 * @createdate 2021-06-09
	 */
	getSimulatorValues(): any {
		return JSON.parse(localStorage.getItem(this.simulator));
	}

	/**
	 * Método para modificar los valores simulados
	 * @param form datos del formulario de atributos de la amortización
	 * @param dues arreglo de ids de las deudas seleccionadas en la simuladión
	 * @author Carlos Gómez
	 * @createdate 2021-06-09
	 */
	setSimulatorValues(form: any, dues: number[]): void {
		localStorage.setItem(this.simulator, JSON.stringify({ form, dues }));
	}

	/**
	 * Método para eliminar los datos simulados.
	 * @author Carlos Gómez
	 * @createdate 2021-06-09
	 */
	removeSimulatorValues(): void {
		localStorage.removeItem(this.simulator);
	}

	/**
	 * Método para obtener el nombre del rol de cartera que esta asociado al usuario logueado.
	 * @returns nombre del rol
	 * @author Carlos Gómez
	 * @createdate 2021-06-22
	 * @update 2021-09-08
	 */
	getRolCartera(): string {
		return this.authService
			.getUser()
			.roles.find((rol) => rol.includes('cartera'))
			?.split('::')[1];
	}

	getGestionConsecuenciaValues(): any {
		return JSON.parse(localStorage.getItem(this.gestionConsecuencia));
	}

	setGestionConsecuenciaValues(data): void {
		localStorage.setItem(this.gestionConsecuencia, JSON.stringify(data));
	}

	removeGestionConsecuenciaValues(): void {
		localStorage.removeItem(this.gestionConsecuencia);
	}

}
