<div class="admin-groups">

  <div class="header">
    <h1>Mis Novedades</h1>
    <p>En este espacio podrás revisar y/o justificar tus novedades de nómina que <br>tengas en el mes, no olvides, “El
      respeto y empatía son clave”.</p>

    <div style="margin-top: 48px;">
      <span class="fi-rr-calendar" style="color: #2CABBC;"> <u (click)="viewCodes()"
          style="margin-left: 16px; font-size: 16px !important; font-weight: bold; color: #2CABBC; cursor: pointer;">Conoce
          los códigos
          de novedades</u></span>
    </div>
  </div>

  <div class="row col-12" style="margin-top: 16px">
    <div class="col-4">
      <div class="col-12">
        <h1 class="gray-color">{{ currentDate }}</h1>
      </div>
      <div class="col-12" style="margin-top: 16px">
        <mat-card fxFlex="100"
          style="border-radius: 10px!important; -moz-border-radius: 10px; -webkit-border-radius: 10px;">
          <!-- like -->
          <div class="row" *ngIf="negativeNovelties === 0">
            <div style="display: inline-block; width: 20%;">
              <img src="./assets/images/like.svg" alt="Icon tiene novedades">
            </div>
            <div style="display: inline-block; width: 80%;">
              <h3 class="novelty-target-ok">¡Felicidades!</h3>
              <span style="font-size: 11px !important; color: #717171">No presentas <b>novedades negativas</b> hasta la
                fecha</span>
            </div>
          </div>
          <!-- end -->

          <!-- not like -->
          <div class="row" *ngIf="negativeNovelties > 0"
            title="Novedades Negativas: Retardo, Ausencias e Inasistencias productivas">
            <div style="display: inline-block; width: 20%;">
              <img src="./assets/images/notlike.svg" alt="Icon pulgar abajo">
            </div>
            <div style="display: inline-block; width: 80%;">
              <h3 class="novelty-target-fail">¡Ten cuidado!</h3>
              <span *ngIf="negativeNovelties === 1" style="font-size: 11px !important; color: #717171">Presentas 1 <b>
                  novedad negativa</b> hasta la fecha</span>
              <span *ngIf="negativeNovelties > 1" style="font-size: 11px !important; color: #717171">Presentas {{
                negativeNovelties }} <b> novedades
                  negativas</b> hasta la fecha</span>
            </div>
          </div>
        </mat-card>
      </div>

    </div>
    <!-- Conventions -->
    <!--  TEMPORALMENTE DESHABILITADO EL BOTON DE RESUMEN DE MI NOMINA -->
    <div class="col-8" style="margin-top: -53px;">
      <span class="info-title fi-rr-eye" style="color: #2CABBC; " *ngIf="false"><b
          style="padding-left: 14px;  text-decoration: underline; cursor: pointer;" (click)="modalResum()">Resumen de mi
          nómina</b></span><br>
      <div class="row" style="display: flex !important; ">

        <div class="row col-12">
          <div class="col-9">
            <div class="row" style="display: grid;">
              <div style="width: 30%;" *ngIf="false">
                <p class="info-subtitle" style="margin-bottom: -6px; width: 1000%;"><b>Resumen de mis novedades</b></p>
                <p style="font-weight: 100; width: 297%;  font-size: 10px;">Recuerda que esta informacion es del mes en
                  curso</p>
              </div>
              <div style="width: 40%;">

                <ng-template #HtmlContent>
                  <div style="padding: 10px;">
                    <div *ngFor="let convention of noveltyTypes" class="novelty-types" style="text-align: left;">
                      <div style="display: inline-flex;">
                        <div style="margin-right: 8px;">
                          <span class="badge" [title]="convention?.type"
                            [style.background]="convention?.extra?.backgroundColor">{{ convention?.key }}
                          </span>&nbsp;
                        </div>
                        <div>
                          <span class="novelty-types" style="display: inline;">
                            {{ convention?.type }}:
                            <span
                              style="font-size: 14px !important; color: #A9A9A9; font-weight: 100!important;">{{convention?.cant}}
                              {{ convention?.cant == 1 ? 'día' : 'días' }}</span></span>
                        </div>
                      </div>
                    </div>
                  </div>

                </ng-template>

                <p class="info-subtitle">Cantidad de
                  novedades
                  <i class="fi-rr-info" style="font-size: 15px;" [offset]="20" placement="right" theme="light"
                    max-width="900" [tooltip]="HtmlContent" content-type="template"></i>
                </p>
                <p class="info-text" style="font-weight: 100;">{{noveltiesCount}}</p>
              </div>

              <div style="width: 30%;">

                <ng-template #Bonus>
                  <div style="padding: 10px;">
                    <div *ngFor="let bonus of commissions" class="novelty-types" style="text-align: left;">
                      <div style="display: inline-flex;">
                        <div style="margin-right: 8px;">
                          <span class="badge" [style.background]="bonus?.extra.backgroundColor" [title]="bonus?.type">{{
                            bonus?.key}}
                          </span>&nbsp;
                        </div>
                        <div>
                          <span class="novelty-types" style="display: inline;">
                            {{ bonus?.type}}:
                            <span
                              style="font-size: 14px !important; color: #A9A9A9; font-weight: 100!important;">{{bonus?.cant
                              | currency: '$':true:'2.0'}}</span></span>
                        </div>
                      </div>
                    </div>
                  </div>

                </ng-template>

                <!-- <p class="info-subtitle"> Comisión
                  <i class="fi-rr-info" style="font-size: 10px;" [offset]="20" placement="right" theme="light" max-width="900"
                    [tooltip]="Bonus" content-type="template"></i>
                </p>-->

              </div>

            </div>

            <p style="font-weight: bold; font-size: 11px !important;">NOTA: <span class="info-text2"
                style="display: inline; font-weight: 100">“Esta información puede variar en la liquidación <br> mensual
                del trabajador”</span>
            </p>
          </div>
          <div class="col-3"></div>

        </div>

      </div>

    </div>
    <!-- End -->

  </div>

  <div class="row col-12">
    <br>
    <br>
    <full-calendar [options]="calendarOptions" #calendar id="calendar"></full-calendar>
  </div>

</div>