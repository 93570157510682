import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { saveAs as importedSaveAs } from 'file-saver';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MedicaltestService } from '../../../services/medicaltest.service';

@Component({
  selector: 'app-medical-test-import',
  templateUrl: './medical-test-import.component.html',
  styleUrls: ['./medical-test-import.component.sass']
})
export class MedicalTestImportComponent implements OnInit {

  private uploadFile;
  @ViewChild('fileInput') myInputVariable: ElementRef;
  nameFile: any;
  FileData: any;
  constructor(
    private formBuilder: FormBuilder,
    private medicalTestServices:MedicaltestService,
    private alert: AlertsService,
    public dialogRef: MatDialogRef<MedicalTestImportComponent>
  ) { }

  ngOnInit() {
    this.uploadFile = this.formBuilder.group({
      file: ['']
    });
  }

  /**
   * @author David Reinoso
   * Metodo para descargar plantilla 
   */
  downloadTemplate(){
    this.medicalTestServices.downloadTemplateMedicalTest().subscribe(resp => {
      importedSaveAs(resp, 'plantilla_de_asignacion_masiva_de_examenes_medicos.xlsx');
    });
  }

  /**
   * @author David Reinoso
   * Metodo para cargar el archivo 
   */
  onChangeFile(e) {
    this.uploadFile.get('file').setValue('');
    this.nameFile = e.target.files[0].name;
    this.FileData = e.target.files[0];
    this.uploadFile.get('file').setValue(this.FileData);
    this.uploadData();
    this.myInputVariable.nativeElement.value = "";
  }

  uploadData(){
    const formData = new FormData();
    formData.append('file', this.uploadFile.get('file').value);
    formData.append('nameFile', this.nameFile);
    
    this.medicalTestServices.uploadMedicalTest(formData).subscribe( (resp:any) => {
      if (resp?.errors) {
        let message = '';
        let titleErros = '';
        for (let i = 0; i < resp?.errors.length; i++) {
          if (resp?.errors[i] != 'El archivo seleccionado no cuenta con la estructura necesaria.') titleErros = '<b>Cédulas con errores:</b>';
          message += '<p>' + resp?.errors[i] + '</p> \n';
        }

        this.alert.alertError('¡Oops!', ``
          + 'Lo sentimos, ha ocurrido un error inesperado y no' + '\n'
          + 'fue posible completar la acción, inténtalo nuevamente.' + '\n'
          + '\n' + '<br><br> ' + titleErros + '\n'
          + '\n' + '<div class="m-auto" style="overflow-y: scroll; width: 90%; height: 150px;font-size:12px">' + message + '</div>' + '\n'
          + '\n'
        ); 
      }else{
        this.alert.alertSuccess("Excelente","Proceso realizado correctamente")
        this.dialogRef.close('');
      }
      
    });
  }

}
