<div class="content-chat" *ngIf="isSeeChat">
    <div class="w-100 header-chat" >
        <div class="row" > 
            <div class="col-10" (click)="toggleChat()">
                <span>{{ userChat !== null ? userChat.name : '' }}</span>
            </div>    
            <div class="col-2 d-flex justify-content-end">
                <span class="closed_span" (click)="closeChat()" >X</span>
            </div>    
        </div>        
    </div> 

    <div class="w-100 body-chat" #scrollContainer *ngIf="isToggleChat == false"> 
        <span  [ngClass]="styleChat(message)" *ngFor="let message of listChat;" > 
            <div class="row" *ngIf="userChat.recipient_type == 2"> 
                <div class="col-12">
                    <i class="fi-rr-crown" *ngIf="getIsOwnerChar(message)"></i> &nbsp;  
                    <b>{{ message.sender_name }}</b>
                </div>         
            </div>         
            <div class="row"> 
                <div class="col-12">
                    <p>{{ message.content }}</p>
                </div>         
            </div>         
            <div class="row"> 
                <div class="col-4 justify-content-start">
                    <span>{{validateSendeMessages(message)}}</span>
                
                </div>
                <div class="col-8 d-flex justify-content-end">
                    <span>{{ getMessageTime(message.created_at) }}</span>
                </div>
            </div>         
            
        </span>
    </div> 
    <div class="w-100 write_msg " *ngIf="isToggleChat == false"  > 
        <div class="row" > 
            <div class="col-10" > 
                <mat-form-field class="w-100" appearance="legacy">
                    <mat-label>Escribe un mensaje</mat-label>
                    <textarea (keyup.enter)="sendMessage()" [formControl]="messageControl" matInput placeholder=""></textarea>
                </mat-form-field>                
            </div>     
            <div class="col-2 d-flex justify-content-center align-items-center" > 
                <button (click)="sendMessage()" mat-mini-fab color="primary" aria-label="Enviar mensaje">
                    <mat-icon>send</mat-icon>
                </button>
            </div>     
        </div> 
        
    </div> 

</div>