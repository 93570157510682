<div class="row">
  <div class="col-12" style="text-align: right;" *ngIf="currentAddress">
    <span class="previewAddress">Direccion actual: {{currentAddress}}</span>
  </div>
</div>

<div>
  <h3>Principal</h3>
  <form (ngSubmit)="onSubmit()" [formGroup]="myForm">

    <div class="row">
      <mat-form-field class="col-3">
        <mat-label for="hasName">Calle con nombre? *</mat-label>
        <mat-select formControlName="hasName">
          <mat-option [value]="false"> NO </mat-option>
          <mat-option [value]="true"> SI </mat-option>
        </mat-select>
        <mat-hint>Por ej: Avenida el Dorado  </mat-hint>
        <mat-error *ngIf="error.partOne.errors?.required">Éste campo es requerido</mat-error>
      </mat-form-field>
    </div>

    <br>

    <div class="row">
      <mat-form-field class="col-3">
        <mat-label for="partOne">Dirección *</mat-label>
        <mat-select formControlName="partOne">
          <mat-option *ngFor="let item of partOneOptions" [value]="item.code">{{ item.name }}</mat-option>
        </mat-select>
        <mat-hint>Por ej: Calle </mat-hint>
        <mat-error *ngIf="error.partOne.errors?.required">Éste campo es requerido</mat-error>
      </mat-form-field>

      <mat-form-field class="col-2">
        <mat-label>*</mat-label>
        <input type="text" matInput formControlName="partTwo" placeholder="26 / EL DORADO">
        <mat-hint>Por ej: 26 / EL DORADO</mat-hint>
        <mat-error *ngIf="error.partTwo.errors?.required">Éste campo es requerido</mat-error>
        <mat-error *ngIf="error.partTwo.errors?.pattern && hasNameSelected">
          EL campo debe ser alfabético en mayuscúlas
        </mat-error>
        <mat-error *ngIf="!hasNameSelected">
          EL campo debe ser númerico
        </mat-error>
      </mat-form-field>

      <mat-form-field class="col-1">
        <mat-label></mat-label>
        <input type="text" matInput formControlName="partThree" placeholder="Letra">
        <mat-hint>Por ej: A </mat-hint>
        <mat-error *ngIf="error.partThree.errors?.required">Éste campo es requerido</mat-error>
        <mat-error *ngIf="error.partThree.errors?.pattern">Éste campo debe ser una letra mayúscula</mat-error>
      </mat-form-field>

      <div class="col-1" style="padding-top: 18px;">
        <mat-checkbox
          name="partFour"
          value="first-value"
          formControlName="partFour"
          aria-label="First checkbox">
          BIS
        </mat-checkbox>
      </div>

      <mat-form-field class="col-1">
        <mat-label>Orientación</mat-label>
        <mat-select formControlName="partFive">
          <mat-option [value]=null>--</mat-option>
          <mat-option [value]="'NORTE'">NORTE</mat-option>
          <mat-option [value]="'SUR'">SUR</mat-option>
          <mat-option [value]="'ESTE'">ESTE</mat-option>
          <mat-option [value]="'OESTE'">OESTE</mat-option>
          <mat-option [value]="'O'">ORIENTE</mat-option>
          <mat-option [value]="'OCC'">OCCIDENTE</mat-option>
        </mat-select>
        <mat-hint>Por ej: SUR </mat-hint>
      </mat-form-field>

      <mat-form-field class="col-1">
        <mat-label></mat-label>
        <input type="text" matInput value="#" readonly>
      </mat-form-field>

      <mat-form-field class="col-1">
        <mat-label>Número *</mat-label>
        <input type="text" matInput formControlName="partSix">
        <mat-hint>Por ej: 85 </mat-hint>
        <mat-error *ngIf="error.partThree.errors?.required">Éste campo es requerido</mat-error>
        <mat-error *ngIf="error.partFive.errors?.pattern">Éste campo debe ser númerico </mat-error>
      </mat-form-field>

      <mat-form-field class="col-1">
        <mat-label>Letra</mat-label>
        <input type="text" matInput formControlName="partSeven">
        <mat-hint>Por ej: Q </mat-hint>
        <mat-error *ngIf="error.partThree.errors?.pattern">Éste campo debe ser una letra mayúscula</mat-error>
      </mat-form-field>

      <div class="col-1" style="padding-top: 18px;">
        <mat-checkbox
          name="partSeven"
          value="BIS"
          formControlName="partEight"
          aria-label="First checkbox">
          BIS
        </mat-checkbox>
      </div>

      <mat-form-field class="col-1">
        <mat-label>Orientación</mat-label>
        <mat-select formControlName="partNine">
          <mat-option [value]= "null">--</mat-option>
          <mat-option [value]="'NORTE'">NORTE</mat-option>
          <mat-option [value]="'SUR'">SUR</mat-option>
          <mat-option [value]="'ESTE'">ESTE</mat-option>
          <mat-option [value]="'OESTE'">OESTE</mat-option>
          <mat-option [value]="'O'">ORIENTE</mat-option>
          <mat-option [value]="'OCC'">OCCIDENTE</mat-option>
        </mat-select>
        <mat-hint>Por ej: NORTE </mat-hint>
      </mat-form-field>

      <mat-form-field class="col-1">
        <mat-label></mat-label>
        <input type="text" matInput value="-" readonly>
      </mat-form-field>

      <mat-form-field class="col-1">
        <mat-label>Número *</mat-label>
        <input type="text" matInput formControlName="partTen">
        <mat-hint>Por ej: 20 </mat-hint>
        <mat-error *ngIf="error.partFive.errors?.required">Éste campo es requerido</mat-error>
      </mat-form-field>
    </div>
    <br>
    <br>
    <h3>Complemento</h3>
    <div class="row">
      <mat-form-field class="col-3">
        <mat-label for="partOne">Complemento</mat-label>
        <mat-select formControlName="partEleven">
          <mat-option *ngFor="let item of partOneOptions" [value]="item.code">{{ item.name }}</mat-option>
        </mat-select>
        <mat-hint>Por ej: Bloque / Interior / Manzana </mat-hint>
      </mat-form-field>

      <mat-form-field class="col-1">
        <mat-label>Número</mat-label>
        <input type="text" matInput formControlName="partTwelve">
        <mat-hint>Por ej: 85 </mat-hint>
        <mat-error *ngIf="error.partFive.errors?.pattern">Éste campo debe ser númerico </mat-error>
      </mat-form-field>

      <mat-form-field class="col-3">
        <mat-label for="partOne"></mat-label>
        <mat-select formControlName="partThirteen">
          <mat-option *ngFor="let item of partOneOptions" [value]="item.code">{{ item.name }}</mat-option>
        </mat-select>
        <mat-hint>Por ej: Casa / Apartamento </mat-hint>
        <mat-error *ngIf="error.partOne.errors?.required">Éste campo es requerido</mat-error>
      </mat-form-field>
    </div>

    <div class="row">
      <div class="col-12" style="text-align: right;" *ngIf="selectedAddress">
        <span class="previewNewAddress">Previsualización: {{selectedAddress}}</span>
      </div>
    </div>

    <div style="text-align: right">

      <button type="button" mat-flat-button style="margin-right: 1em; margin-top: 3em;"
              [disabled]="myForm.invalid" (click)="preview()">Previsualizar</button>

      <button type="submit" mat-flat-button color="primary" style="margin-right: 1em; margin-top: 3em;"
              [disabled]="myForm.invalid">Guardar</button>

      <button type="button" mat-stroked-button (click)="onCancel()">Cancelar</button>
    </div>

  </form>
</div>

