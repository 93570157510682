import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatrizService } from 'src/app/modules/auditoria/services/matriz.service';
import { Tools } from 'src/app/modules/auditoria/utils/tools.utils';
import Swal from 'sweetalert2';
import { AnalistasAuditoriaComponent } from '../analistas-auditoria/analistas-auditoria.component';
import { CrearItemComponent } from '../crear-item/crear-item.component';
import { CrearModuloComponent } from '../crear-modulo/crear-modulo.component';
import { CrearSubItemComponent } from '../crear-sub-item/crear-sub-item.component';
import { EditarItemComponent } from '../editar-item/editar-item.component';
import { EditarModuloComponent } from '../editar-modulo/editar-modulo.component';
import { EditarSubItemComponent } from '../editar-sub-item/editar-sub-item.component';

@Component({
  selector: 'app-detalle-matriz',
  templateUrl: './detalle-matriz.component.html',
  styleUrls: ['./detalle-matriz.component.sass']
})
export class DetalleMatrizComponent implements OnInit, OnDestroy {

  filtroModulo:string = '';
  checked:boolean = true;

  title:string = '';
  tipo:string = '';
  creacion:string = '';
  minimo = 0;
  maximo = 0;
  peso_actual = 0;

  id_matriz;
  tipo_peso;


  modulos_matriz = [];
  items_modulo = [];
  subItems_item = [];

  //Observable para los cambios de la matriz
  subscription:Subscription;

  constructor(
    private dialog:MatDialog,
    private aRoute:ActivatedRoute,
    private matrizService:MatrizService,
  ) {
    this.aRoute.paramMap.subscribe(params=>{
     this.id_matriz = params.get('id');
    });
   }

  ngOnInit(): void {
    this.getMatrixById();
    this.subscription = this.matrizService.refresh$.subscribe(()=>{
      this.getMatrixById();
    })
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  
  newModule(){
    const moduleRef = this.dialog.open(CrearModuloComponent,{
      width: '624px',
      disableClose: true,
      data: {
        id_matriz: this.id_matriz,
        tipo_peso:this.tipo_peso,
        tipo_matriz:this.tipo,
        minimo:this.minimo,
        maximo:this.maximo
      }
    })
  }

  newItem(id_modulo,sigla,error_critico){
    const itemRef = this.dialog.open(CrearItemComponent,{
      width: '624px',
      disableClose: true,
      data: {
        id_matriz: this.id_matriz, 
        id_modulo: id_modulo, 
        tipo_peso: this.tipo_peso,
        sigla: sigla,
        error_critico: error_critico,
        tipo_matriz:this.tipo,
      },
    })
  }


  newSubItem(id_item,sigla,error_critico){
    const subItemRef = this.dialog.open(CrearSubItemComponent,{
      width: '624px',
      disableClose: true,
      data: {
        id_matriz: this.id_matriz, 
        id_item: id_item, 
        tipo_peso: this.tipo_peso,
        sigla: sigla,
        error_critico: error_critico,
      },
    })
  }

  disableModule(id,estado,nombre){
    let mensaje:string = '';
    if (estado) {
      mensaje = `¿Estas seguro de deshabilitar este ${nombre}?`
    }else{
      mensaje = `¿Estas seguro de habilitar este ${nombre}?`
    }
    Swal.fire({
      title:mensaje,
      icon: 'warning',
      showCancelButton:true,
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      width: '836px',
      padding: '2em 7em'
    }).then(result=>{
      if (result.isConfirmed) {
        const data = {
          id: id,
          estado: !estado,
          pesoMaxMatriz: this.maximo,
          pesoActual: this.peso_actual,
          idMatriz: this.id_matriz
        }
        this.matrizService.disableModule(data).subscribe((resp:any)=>{
          if (resp) {            
            if (resp.respuesta.codigo != 202) {
              Tools.swalError('¡Ups!',resp.respuesta.text)
            }
          }
        });
      }
    })
  }

  getMatrixById(){
    this.matrizService.getMatrixById(this.id_matriz).subscribe((resp:any)=>{
      this.title = resp[0].matriz.nombre;
      this.tipo = resp[0].matriz.tipo_matriz.nombre;
      this.creacion = resp[0].matriz.created_at;
      this.minimo = resp[0].matriz.valor_peso_validacion.minimo;
      this.maximo = resp[0].matriz.valor_peso_validacion.maximo;
      if (resp[0].matriz.tipo_peso != null) {
        this.tipo_peso = resp[0].matriz.tipo_peso.nombre;
      }
      this.modulos_matriz = [];
      resp[0].matriz.modulo.forEach(modulo=>{
        if (modulo.nivel == 1) {
          this.modulos_matriz.push(modulo);
        }
      });
      this.peso_actual = resp[0].matriz.peso_actual;
    });
  }

  filtrar(){
    const params = {
      modulo: '',
      idMatriz: this.id_matriz
    }

    if (this.filtroModulo != '') {
      params.modulo = this.filtroModulo
    }
    this.matrizService.getModulesMatrix(params).subscribe((resp:any)=>{
      this.modulos_matriz = resp;
    });
  }

  editModule(id){
    const moduleRef = this.dialog.open(EditarModuloComponent,{
      width: '624px',
      disableClose: true,
      data: {
        id_module: id,
        tipo_peso:this.tipo_peso,
        tipo_matriz:this.tipo,
        minimo:this.minimo,
        maximo:this.maximo
      }
    })
  }

  editItem(id,error_critico){
    const itemRef = this.dialog.open(EditarItemComponent,{
      width: '624px',
      disableClose: true,
      data: {
        id_item: id,
        tipo_peso:this.tipo_peso,
        error_critico}
    });
  }

  editSubItem(id){
    const subitemRef = this.dialog.open(EditarSubItemComponent,{
      width: '624px',
      disableClose: true,
      data: {id_subItem: id,tipo_peso:this.tipo_peso}
    });
  }

  analyst(type){
    const analystRef = this.dialog.open(AnalistasAuditoriaComponent,{
      width: '732px',
      disableClose: true,
      data: {
        id_matriz: this.id_matriz,
        type,
      },
    })
  }

}
