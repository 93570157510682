import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { AuditoriaService } from '../../../../services/auditoria.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogEliminacionComponent } from '../dialog-eliminacion/dialog-eliminacion.component';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tabla-eliminacion',
  templateUrl: './tabla-eliminacion.component.html',
  styleUrls: ['./tabla-eliminacion.component.sass']
})
export class TablaEliminacionComponent implements OnInit, OnDestroy {
  dataSource = [];
  displayedColumns:string[] = ['acciones','id','fecha_creacion','hora_creacion','nombre_auditor'];

  filtroId = '';

  length;
  pageSize = 5;
  page = 1;
  pageSizeOptions = [5,10,25,50,100];
  @ViewChild(MatPaginator) paginator:MatPaginator;

  //Observable para los cambios de la tabla
  subscription:Subscription;

  constructor(
    private auditoriaService:AuditoriaService,
    private dialog:MatDialog,
  ) { }

  ngOnInit(): void {
    this.cargarTabla(this.page,this.pageSize);
    this.subscription = this.auditoriaService.refresh$.subscribe(()=>{
      this.cargarTabla(this.page,this.pageSize);
    });
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }

  /**
  * @author Cristian Gallo
  * @createdate 2022-03-02
  * Metodo que se encarga de cargar la tabla.
  */
  cargarTabla(page,pageSize){
    const params = {
      page: page,
      numeroReg: pageSize,
      id:''
    };
    if (this.filtroId != '' && this.filtroId != null) {
      params.id = this.filtroId;
    }
    this.auditoriaService.getAllAuditorias(params).subscribe((resp:any)=>{
      this.dataSource = resp.data;
      this.length = resp.total
      this.pageSize = resp.per_page;
    });
  }

  filtro(){
    this.cargarTabla(this.page,this.pageSize);
  }

  pageEvent(event:any){
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.cargarTabla(this.page,this.pageSize);
  }

  /**
  * @author Cristian Gallo
  * @createdate 2022-03-02
  * Metodo que se encarga de abrir el dialog para eliminar la auditoría.
  */
  eliminarAuditoria(auditoria){
    Swal.fire({
      icon:'warning',
      title: '¿Estas seguro de eliminar esta auditoría?',
      html: '<p>¿Deseas eliminar permanentemente la auditoría?</p><p style="margin-top:-1em;"> Recuerda que esta acción será definitiva y no se podrá deshacer.</p>',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true,
      width: '810px',
      padding: '2em 7em'
    }).then(result=>{
      if (result.isConfirmed) {        
        const eliminarRef = this.dialog.open(DialogEliminacionComponent,{
          disableClose: true,
          width: '820px',
          data: auditoria
        });
      }
    });
  }

}
