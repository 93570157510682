import { Component, OnInit, Inject } from '@angular/core';
import { CreateObservationItemComponent } from '../create-observation-item/create-observation-item.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-view-observation-item',
  templateUrl: './view-observation-item.component.html',
  styleUrls: ['./view-observation-item.component.sass']
})
export class ViewObservationItemComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { observation, indexItem },
    private matDialog: MatDialog,
    private matDialogRef: MatDialogRef<ViewObservationItemComponent>) { }

  ngOnInit(): void {
    
  }

  viewModalEdit(): void {
    const openModalUpdate = this.matDialog.open(CreateObservationItemComponent, {
      data: {
        indexItem: this.data.indexItem,
        observation: this.data.observation
      },
      width: '60%',
      autoFocus: false
    });
    openModalUpdate.afterClosed().subscribe(resUpdate => {
      this.matDialogRef.close({ resUpdate });
    });
  }
}