import { Component, Inject, OnInit } from '@angular/core';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

// Models
import { CampaignUser } from '../../../../modules/shared/models/campaign-user';

// Services
import { LocalStorageService } from '../../services/localStorage.service';

@Component({
	selector: 'app-choose-campaign',
	templateUrl: './choose-campaign.component.html',
	styleUrls: ['./choose-campaign.component.sass']
})
export class ChooseCampaignComponent implements OnInit {
	columns = ['index', 'code', 'name']; // Columnas de la tabla de campañas
	dataSource = new MatTableDataSource<CampaignUser>(); // Datos de la tabla de campañas
	selectedRowIndex = null; // Index seleccionado
	selectedCampaign: CampaignUser = null; // Campaña selecionada de la tabla de campañas

	constructor(@Inject(MAT_DIALOG_DATA) public campaigns: any, private matDialog: MatDialog, private localStorageService: LocalStorageService) {}

	ngOnInit(): void {
		this.dataSource.data = this.campaigns.campaigns;
	}

	/**
	 * Método que se llama cuando se selecciona una campaña.
	 * Esto se hace para agregar el foco a la campaña seleccionada.
	 * @param row campaña seleccionada
	 * @param index index de la campaña seleccionada.
	 * @author Carlos Gómez
	 * @createdate 2021-05-21
	 */
	highlight(row: any, index: number): void {
		this.selectedRowIndex = index;
		this.selectedCampaign = row;
	}

	/**
	 * Método para guardar la campaña seleccionada en el localStorage y cerrar el popup
	 * @author Carlos Gomez
	 * @createdate 2021-05-21
	 */
	chooseCampaign(): void {
		this.localStorageService.setCampaignUser(this.selectedCampaign);
		this.matDialog.getDialogById('dialog_chooseCampaign').close(null);
	}
}
