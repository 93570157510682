<div fxLayout="column">
  <div fxFlex="100%" fxLayout="row">
    <mat-form-field appearance="fill" class="col-2">
      <mat-label>{{ config.labels.searchById }}</mat-label>
      <input type="number" matInput [(ngModel)]="filtroId" (blur)="filtro()" (keydown.enter)="filtro()">
    </mat-form-field>
  </div>
  <div class="mat-elevation-z0 mt-2" fxFlex="100%">
    <table  [dataSource]="dataSource" mat-table  matSort>

      <!-- Columna con botónes de acciones -->
      <ng-container matColumnDef="acciones">
        <div>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <i class="fi-rr-trash" (click)="eliminarAuditoria(element)" style="cursor: pointer;"></i>
          </td>
        </div>
      </ng-container>

      <!-- Columna ID -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> {{ config.labels.idColumn }} </th>
        <td mat-cell *matCellDef="let element"> {{ element.id}} </td>
      </ng-container>

      <!-- Columna fecha creación -->
      <ng-container matColumnDef="fecha_creacion">
        <th mat-header-cell *matHeaderCellDef> {{ config.labels.creationDateColumn }} </th>
        <td mat-cell *matCellDef="let element"> {{ element.created_at  | date:'dd/MM/yyyy' }} </td>
      </ng-container>

      <!-- Columna Hora creación -->
      <ng-container matColumnDef="hora_creacion">
        <th mat-header-cell *matHeaderCellDef> {{ config.labels.creationTimeColumn }} </th>
        <td mat-cell *matCellDef="let element"> {{ element.created_at  | date:'mediumTime' }} </td>
      </ng-container>

      <!-- Columna fecha auditoría -->
      <ng-container matColumnDef="nombre_auditor">
        <th mat-header-cell *matHeaderCellDef> {{ config.labels.auditorNameColumn }} </th>
        <td mat-cell *matCellDef="let element"> {{ element.nombreAuditor }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="5">{{ config.labels.noRecords }}</td>
      </tr>
    </table>


    <mat-paginator [length]="length"
                   [pageSize]="pageSize"
                   (page) ="pageEvent($event)"
                   [pageSizeOptions] = "pageSizeOptions"
    >
    </mat-paginator>
  </div>
</div>
