<div class="row popover-wd" *ngFor="let scheduleFull of weekScheduleList; let i = index">
    <div *ngIf="scheduleFull.schedule_type_id == 1">
      <div class="col-12">
        <h5 class="landing-sub-tittle">
          <p><b>{{ scheduleFull.date | date :'EEEE'}}</b></p>
        </h5>
      </div>

      <div *ngIf="scheduleFull.start_time === '00:00:00' && scheduleFull.end_time === '00:00:00'">
        <div class="col-12">
          <h5 class="landing-sub-tittle">
            <span class="hour-sh hour-init">Día de descanso</span>
          </h5>
        </div>
      </div>

      <div *ngIf="scheduleFull.start_time != '00:00:00' && scheduleFull.end_time != '00:00:00'">
        <div class="col-12">
          <h5 class="landing-sub-tittle">
            <b>Hora inicio</b><span class="hour-sh hour-init">{{scheduleFull.date+" "+scheduleFull.start_time | date:'shortTime'}}</span>
          </h5>
        </div>
        <div class="col-12">
          <h5 class="landing-sub-tittle last-hour-txt">
            <b>Hora fin</b><span class="hour-sh hour-finish-pop">{{scheduleFull.date+" "+scheduleFull.end_time | date:'shortTime'}}</span>
          </h5>
        </div>
      </div>
      
      <div class="col-12">
        <mat-divider class="mat-divider-wd"></mat-divider>
      </div>
    </div>
  </div>