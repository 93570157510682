import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { DataAuditoria } from 'src/app/modules/auditoria/interfaces/dataAuditoria.interface';
import { AuditoriaService } from 'src/app/modules/auditoria/services/auditoria.service';
import { MatrizService } from 'src/app/modules/auditoria/services/matriz.service';
import { Tools } from 'src/app/modules/auditoria/utils/tools.utils';
import { CoachingService } from 'src/app/modules/coaching/services/coaching.service';
import {Location} from '@angular/common';


@Component({
  selector: 'app-detalle-retroalimentacion',
  templateUrl: './detalle-retroalimentacion.component.html',
  styleUrls: ['./detalle-retroalimentacion.component.sass']
})
export class DetalleRetroalimentacionComponent implements OnInit {

  dataSource = [];
  displayedColumns:string[] = ['matriz','fecha_auditoria','calificacion'];
  tipo_matriz:string = '';
  tipo_peso:string = '';
  idMatriz;
  calificacionesForm:FormGroup;
  roles = [];
  is_asesor:boolean = false;
  analista:boolean = false;
  is_jefe:boolean = false;
  is_coaching:boolean = false;
  supervisor:boolean = false;
  verBtns:boolean = false;
  datos;
  data_general:any = [];
  isRetentionMatrix: number = 0;
  showFieldDuration: number = 0;
  constructor(
    private _location: Location,
    private auditoriaService:AuditoriaService,
    private fb:FormBuilder,
   // public retroalimentacionRef:MatDialogRef<DetalleRetroalimentacionComponent>,
    //@Inject(MAT_DIALOG_DATA) public datos:any,
    private authService:AuthService,
    private router:Router,
    private aRoute:ActivatedRoute,
    private coachService:CoachingService,
  ) { 
    
  }
  ngOnInit(): void {
    this.roles = this.authService.getUser().roles;
    //console.log(this.roles);
    this.createForm();
   
    this.aRoute.paramMap.subscribe(params=>{
      this.datos = params.get('id');
      this.getRetroalimentacionById();

    });


    
  }

  createForm(){
    this.calificacionesForm = this.fb.group({
      modulos:this.fb.array([]),
      txtAuditoria:[{value:'',disabled:true}],
      retroalimentacion_auditor:[{value:''},[Validators.required]],
      retroalimentacion_supervisor:[{value:'',disabled:true}],
      compromiso_calidad:[{value:'',disabled:true}],
      compromiso_operacion:[{value:'',disabled:true}],
    });
    this.roles.forEach(rol => {
      if (rol == 'auditoria::analista') {
        this.analista = true;
      }
      if (rol == 'auditoria::supervisor') {
        this.supervisor = true;
      }
      if (rol == 'auditoria::asesor') {
        this.is_asesor = true;
        const index = this.displayedColumns.indexOf('tipo_auditoria');
        this.displayedColumns.splice(index,1);
      }
      if (rol == 'auditoria::jefe') {
        this.is_jefe = true;
      }
      
      if (rol == 'coaching::asesor') {
        this.is_coaching = false;
      }else{
        this.is_coaching = true;
      }

    });
    if ((this.analista && this.supervisor)) {
      this.calificacionesForm.get('retroalimentacion_auditor').enable();
      this.calificacionesForm.get('retroalimentacion_supervisor').enable();
    }else if (this.analista) {
      this.calificacionesForm.get('retroalimentacion_auditor').enable();
      this.calificacionesForm.get('retroalimentacion_supervisor').setErrors(null);
    }else if (this.supervisor) {
      this.calificacionesForm.get('retroalimentacion_supervisor').enable();
      this.calificacionesForm.get('retroalimentacion_auditor').setErrors(null);
    }else{
      this.calificacionesForm.get('retroalimentacion_auditor').enable();
      this.calificacionesForm.get('retroalimentacion_supervisor').disable();
      this.calificacionesForm.get('retroalimentacion_auditor').setErrors(null);
      this.calificacionesForm.get('retroalimentacion_supervisor').setErrors(null);
    }
    if (this.is_jefe) {
      this.verBtns = false;
    }
  }

  getRetroalimentacionById(){
    const modulosForm = <FormArray>this.calificacionesForm.controls['modulos'];
    modulosForm.clear();
    this.auditoriaService.getRetroalimentacionById(this.datos).subscribe((resp:any)=>{
      let datos = [];
      this.isRetentionMatrix = resp[0].auditoria.detalle_matriz.is_retencion_matrix;
      this.showFieldDuration = resp[0].auditoria.detalle_matriz.showFieldDuration;
      this.data_general= resp[0].data_general[0];
      this.idMatriz = resp[0].auditoria.detalle_matriz.id;
      this.tipo_matriz = resp[0].auditoria.detalle_matriz.tipo_matriz.nombre;
      if (resp[0].auditoria.detalle_matriz.tipo_peso) {
        this.tipo_peso = resp[0].auditoria.detalle_matriz.
        tipo_peso.nombre;
      }
      datos.push({
        nombre_matriz: resp[0].auditoria.detalle_matriz.nombre,
        fecha_auditoria: resp[0].auditoria.fecha_llamada,
        tipo_audioria: resp[0].auditoria.tipo_audioria.nombre,
        calificacion: resp[0].auditoria.total_punaje
      });
      this.dataSource = datos;
      resp[0].auditoria.items_afectados.forEach((modulo,iModule) => {
        modulosForm.push(this.fb.group({
          calificacion: [{value:1,disabled:true}],
          created_at: modulo.created_at,
          cumple: modulo.cumple,
          deleted_at: modulo.deleted_at,
          detalle_item: modulo.detalle_item,
          id: modulo.id,
          items: this.fb.array([]),
          matriz_auditoria_id: modulo.matriz_auditoria_id,
          modulo_id: modulo.modulo_id,
          nivel: modulo.nivel,
          no: modulo.no,
          no_aplica: modulo.no_aplica,
          no_cumple: modulo.no_cumple,
          padre_id: modulo.padre_id,
          puntaje: modulo.puntaje,
          puntaje_obtenido: modulo.puntaje_obtenido,
          si: modulo.si,
          tipo_matriz_id: modulo.tipo_matriz_id,
          updated_at: modulo.updated_at,
        }));
        modulo.items.forEach((item) => {
          item.subitems.forEach(subItem => {
            Object.assign(subItem,{calificacion: [{value:1,disabled:true}]});
          });
          (this.modulesForm.at(iModule).get('items') as FormArray).push(this.fb.group({
            calificacion: [{value:1,disabled:true}],
            created_at: item.created_at,
            cumple: item.cumple,
            deleted_at: item.deleted_at,
            detalle_item: item.detalle_item,
            id: item.id,
            matriz_auditoria_id: item.matriz_auditoria_id,
            modulo_id: item.modulo_id,
            nivel: item.nivel,
            no: item.no,
            observacion: item.observacion,
            no_aplica: item.no_aplica,
            no_cumple: item.no_cumple,
            padre_id: item.padre_id,
            puntaje: item.puntaje,
            puntaje_obtenido: item.puntaje_obtenido,
            si: item.si,
            subitems: this.fb.array(item.subitems.map(subItem =>this.fb.group(subItem))),
            tipo_matriz_id: item.tipo_matriz_id,
            updated_at: item.updated_at,
          }));
        });
      });
      this.calificacionesForm.get('txtAuditoria').setValue(resp[0].auditoria.observacion_auditor);
      this.calificacionesForm.get('retroalimentacion_auditor').setValue(resp[0].auditoria.retroalimentacion_auditor);
      this.calificacionesForm.get('retroalimentacion_supervisor').setValue(resp[0].auditoria.retroalimentacion_supervisor);
      this.calificacionesForm.get('compromiso_calidad').setValue(resp[0].auditoria.compromiso_calidad);
      this.calificacionesForm.get('compromiso_operacion').setValue(resp[0].auditoria.compromiso);

      if (this.is_asesor && resp[0].auditoria.retroalimentacion_auditor) {
        this.calificacionesForm.get('compromiso_calidad').enable();
      }
      if (this.is_asesor && resp[0].auditoria.retroalimentacion_supervisor) {
        this.calificacionesForm.get('compromiso_operacion').enable();
      }
      if (this.analista && resp[0].auditoria.retroalimentacion_auditor) {
        this.calificacionesForm.get('retroalimentacion_auditor').enable();
        this.verBtns = false;
      }
      if (this.supervisor && resp[0].auditoria.retroalimentacion_supervisor) {
        this.calificacionesForm.get('retroalimentacion_supervisor').disable();
        this.verBtns = false;
      }

      if (resp[0].auditoria.compromiso_calidad && resp[0].auditoria.compromiso) {
        this.verBtns = false;
        this.calificacionesForm.get('compromiso_calidad').disable();
        this.calificacionesForm.get('compromiso_operacion').disable();
      }
      if (resp[0].auditoria.compromiso_calidad) {
        this.calificacionesForm.get('compromiso_calidad').disable();
      }
      if (resp[0].auditoria.compromiso) {
        this.calificacionesForm.get('compromiso_operacion').disable();
      }


      if (this.supervisor) {
        this.calificacionesForm.get('retroalimentacion_auditor').enable();
        this.verBtns = true;
      }else{
        this.calificacionesForm.get('retroalimentacion_auditor').disable();
      }

      if(resp[0].auditoria.retroalimentada==1){
         this.verBtns = false;
         this.calificacionesForm.get('retroalimentacion_auditor').disable();
      }


    });
  }

  get modulesForm(){
    return this.calificacionesForm.get('modulos') as FormArray;
  }

  updateRetroalimentacionById(){
    this.calificacionesForm.markAllAsTouched();
    if (this.calificacionesForm.invalid) {
      return
    }
    Object.assign(this.calificacionesForm.value,{
      mensaje: `Tienes una nueva retroalimentación en la auditoría ID ${this.datos}.`,
      ruta: '/mios/auditoria/mis-auditorias',
    })
    this.auditoriaService.updateRetroalimentacionById(this.datos,this.calificacionesForm.value).subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202) {
        Tools.swalSuccess('¡Excelente!','La retroalimentación se ha guardado con éxito.');
        //this.retroalimentacionRef.close();
        this.ngOnInit();
      }else if (resp.respuesta.codigo != 202) {
        Tools.swalError('¡Oops!','Lo sentimos, ha ocurrido un error inesperado y no fue posible guardar la retroalimentación, por favor intenta nuevamente.');
      }
    });
  }

  updateAuditoria(){
    let mensaje:string = '';
    if (this.calificacionesForm.get('compromiso_calidad').touched && this.calificacionesForm.get('compromiso_operacion').touched) {
      mensaje = `Compromiso de calidad y de operación registrado en la auditoría ID-${this.datos}.`
    }else if (this.calificacionesForm.get('compromiso_calidad').touched) {
      mensaje = `Compromiso de calidad registrado en la auditoría ID-${this.datos}.`
    }else if (this.calificacionesForm.get('compromiso_operacion').touched) {
      mensaje = `Compromiso operación registrado en la auditoría ID-${this.datos}.`
    }
    this.calificacionesForm.get('compromiso_calidad').enable();
    this.calificacionesForm.get('compromiso_operacion').enable();
    Object.assign(this.calificacionesForm.value,{
      mensaje,
      ruta: '/mios/auditoria/auditoria/detail/1/'+this.idMatriz,
    })
    this.auditoriaService.updateAuditoria(this.datos,this.calificacionesForm.value).subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202) {
        Tools.swalSuccess('¡Excelente!','La retroalimentación se ha guardado con éxito.');
        //this.retroalimentacionRef.close();
        this.ngOnInit();
      }else if (resp.respuesta.codigo != 202) {
        if (this.calificacionesForm.get('compromiso_calidad').touched && this.calificacionesForm.get('compromiso_operacion').touched) {
          this.calificacionesForm.get('compromiso_calidad').disable();
          this.calificacionesForm.get('compromiso_operacion').disable();
        }else if (this.calificacionesForm.get('compromiso_calidad').touched) {
          this.calificacionesForm.get('compromiso_calidad').disable();
        }else if (this.calificacionesForm.get('compromiso_operacion').touched) {
          this.calificacionesForm.get('compromiso_operacion').disable();
        }
        Tools.swalError('¡Oops!','Lo sentimos, ha ocurrido un error inesperado y no fue posible guardar la retroalimentación, por favor intenta nuevamente.');
      }
    });
  }

  nuevoCoaching(){
    this.coachService.coachCalidad(this.datos).subscribe((resp:any)=>{
      if (resp.respuesta.status) {
        if (resp.respuesta.estado === 'Abierto' || resp.respuesta.estado === 'Vencido') {
          window.location.replace('/mios/coaching/solicitud/'+resp.respuesta.id);
        }else{
          window.location.replace('/mios/coaching/finalizado/'+resp.respuesta.id);
        }
      }else{
        window.location.replace('/mios/coaching/auditoria/'+this.datos);
      }
    });
  }

  backClicked() {
    this._location.back();
  }

}
