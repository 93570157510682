<div [@toggleContactBar]="isOpenListContact" class="content-contacts" >
    <button class="position_close_panel"  color="primary" (click)="closePanelListContact()" mat-mini-fab  aria-label="Cerrrar lista de contactos">
        <mat-icon>close</mat-icon>
    </button>
    <div class="row mt-4" > 
        <div class="col-12 mt-3" > 
            <h2>Chat soul</h2>
            <p class="text_explain">Recuerda que el uso de este chat está restringido al ámbito laboral.</p>
        </div>
    </div>

    <div class="row mt-2" > 
        <div class="col-12 mt-3" > 
            <mat-form-field class="w-100" appearance="legacy">
                <mat-label>Buscar chat</mat-label>
                <input [formControl]="searchControl" matInput >
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="container-filters">
                <span [ngClass]="styleFilterState(0)" (click)="activeFilter(0)">Todos</span>
                <span [ngClass]="styleFilterState(1)" (click)="activeFilter(1)" >No leídos</span>
                <span [ngClass]="styleFilterState(2)" (click)="activeFilter(2)" >Grupos</span>
            </div>
        </div>
    </div>
    <div class="row mt-4 section-list-contact" >
        <ul class="list-contacts-chats">
            <li  [ngClass]="notificationHaveNewMessage(contact)"  *ngFor="let contact of $filterContact | async; let i = index">
                <div class="row w-100">
                    <div class="col-2 d-flex justify-content-center align-items-center" (click)="setNewChat(contact, i)">
                        <div class="d-flex justify-content-center align-items-center letter-name"> {{ contact.name[0] }}</div>
                    </div>
                    <div class="col-10">
                        <div class="row w-100">          
                            <div class="col-11" (click)="setNewChat(contact, i)">
                                <p class="name_person" >{{ contact.name }}</p>
                                <p class="type_user" >{{ contact.recipient_type == 1 ? "Usuario" : "Grupo" }}</p>
                            </div>
                            <div class="col-1 d-flex justify-content-end align-items-center">
                                <mat-icon [matBadge]="contact.countMessageNotRead" matBadgeColor="primary" (click)="setNewChat(contact, i)" [matBadgeHidden]="contact.countMessageNotRead <= 0">comment</mat-icon>
                                &nbsp;&nbsp;
                                <button  matTooltip="Configuración de grupo" *ngIf="contact.recipient_type == 2 " mat-icon-button [matMenuTriggerFor]="menu" aria-label="Opciones de grupo">
                                    <mat-icon>more_vert</mat-icon>
                                </button>

                                <mat-menu #menu="matMenu">
                                    <button *ngIf="contact.recipient_type == 2 && isOwnerGroup(contact.onwer_id) == true " (click)="openModalEditGroups(contact)" mat-menu-item aria-label="Editar grupo">
                                        <mat-icon>edit</mat-icon>
                                        <span>Editar</span>
                                    </button>
                                    <button  *ngIf="contact.recipient_type == 2 " (click)="seeDetailGroup(contact)" mat-menu-item aria-label="Ver grupo" >
                                        <mat-icon>visibility</mat-icon>
                                        <span>Ver grupo</span>
                                    </button>
                                </mat-menu>
                                  
                            </div>
                        </div>
                    </div>
                </div>
            </li>    
            <li class="text-center mt-4" *ngIf="($filterContact | async)?.length <= 0" > 
                <p>Aún no tienes chats activos, busca contactos o crea un grupo para comunicarte con ellos.</p>  
                <button class="w-100 mt-2" mat-raised-button (click)="openMoalContacts()"  color="primary">Nuevo chat &nbsp; <mat-icon>add_comment</mat-icon></button>
                <button class="w-100 mt-1" mat-raised-button (click)="openModalChatGroup()" color="primary">Nuevo grupo &nbsp; <mat-icon>group_add</mat-icon></button>
            </li>   
        </ul>
    </div>
    <button class="position_buttton position_buttton_new_chat"
        matTooltip="Nuevo chat" matTooltipPosition="left"  
        color="primary" (click)="openMoalContacts()" mat-mini-fab  aria-label="Crear grupo">
        <mat-icon>add_comment</mat-icon>
    </button>

    <button class=" position_buttton position_create_group"
        matTooltip="Crear grupo de chat" matTooltipPosition="left"  
        color="primary" (click)="openModalChatGroup()" mat-mini-fab  aria-label="Crear grupo">
        <mat-icon>group_add</mat-icon>
    </button>
</div>