<div class="container-fluid p-5">
  <div class="row">
    <div class="col-md-12">
      <span style="font-size: 32px; font-weight: 700;">Administrador de conexiones</span>
    </div>
    <div class="col-md-12 mt-4">
      <p class="lh-base" style="color: #353535">
        En este espacio podrás realizar la gestión de servidores, conexiones y campañas para la configuración <br />
        de Speech to text. No olvides, “El respeto y empatía son clave”.
      </p>
    </div>
  </div>
  <div class="row justify-content-between mt-5">
    <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabChange($event)">
      <mat-tab label="Conexiones">
        <app-connects></app-connects>
      </mat-tab>
      <mat-tab label="Campañas">
        <app-config-campain></app-config-campain>
      </mat-tab>
      <mat-tab label="Asignar campañas">
        <app-assign-campaign></app-assign-campaign>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>