import { AuthService } from './../../core/services/rest/auth.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ModalLandingComponent } from './modal-landing/modal-landing.component';
import { MatDialog } from '@angular/material/dialog';
import { OnBoardingComponent } from '../on-boarding/on-boarding.component';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.sass'],
})
export class LandingPageComponent implements OnInit {

  @ViewChild('videoPlayer') videoplayer: ElementRef;

  public userName : any;

  constructor(public dialog: MatDialog, private authService : AuthService) { }

  ngOnInit(): void {
    this.userName = this.authService.decryptToken();
  }

  modalLanding(): void{
    const dialogLanding = this.dialog.open(ModalLandingComponent, {
      width: '60%',
      disableClose: true,
      data: {
        type: 'ver',
        title: 'Visualizacion',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogLanding.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }

   /**
   * @author Carlos Nieto
   * @createdate 2021-01-31
   *función onlandingBoar abre el modal, se debe pasar el array
   *con la especificacion de imagenes
   */
  onBoadingCiu(): void{
    const dialogLanding = this.dialog.open(OnBoardingComponent, {
      data: [{
        img: 'ciu/Onboarding-1.svg',
      },
      {
        img: 'ciu/Onboarding-2.svg',
      },
      {
        img: 'ciu/Onboarding-3.svg',
      },
      {
        img: 'ciu/Onboarding-4.svg',
      },
      {
        img: 'ciu/Onboarding-5.svg',
      },
      {
        img: 'ciu/Onboarding-6.svg',
      },
      {
        img: 'ciu/Onboarding-7.svg',
      },
      {
        img: 'ciu/Onboarding-8.svg',
      }
    ],
      disableClose: true,
      panelClass: 'full-screen-modal',
    });

  }

  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
}

}
