<div class="main-container">
	<h1>Reservas CRM</h1>

	<br>
	<br>
	<div class="row mt-4">
		<div class="col-6">
			<div class="filter w-100">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Seleccione un formulario CRM1</mat-label>
					<mat-select #filterForm (selectionChange)="filter(filterForm.value, 'crm')">
						<mat-option *ngFor="let form of formList" [value]="form['id']">
							{{ form["name_form"] }}</mat-option>
	
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="col-6">
			<div class="filter w-100">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Seleccione un formulario CRM2</mat-label>
					<mat-select #crmtwo (selectionChange)="filter(crmtwo.value, 'crm2')">
						<mat-option *ngFor="let form of formListCrmTwo" [value]="form['id']">
							{{ form["name_form"] }}</mat-option>
	
					</mat-select>
				</mat-form-field>

			</div>
		</div>
	</div>


	


  <div *ngIf="isBookingListLoad === true" class="table" >

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="resource">
        <th mat-header-cell *matHeaderCellDef class="start"> Recursos </th>
        <td mat-cell *matCellDef="let element"><span
            *ngFor="let item of element.resources_associated; last as isLast">{{item.resource.name}}<span
              *ngIf="!isLast">,</span> </span> </td>
      </ng-container>

      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef> Fecha de reserva </th>
        <td mat-cell *matCellDef="let element"> {{element.created_at | date}} </td>
      </ng-container>

      <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef> Horas resevada </th>
        <td mat-cell *matCellDef="let element"> {{element.start |date: 'shortTime'}} a {{element.end |date:
          'shortTime'}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="end">Estado</th>
        <td mat-cell *matCellDef="let element">{{element.status | status}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="5">No hay reservas disponibles</td>
      </tr>
    </table> 
	<!--
		<mat-paginator  [length]="length" [pageSize]="pageSize" (page)="getPaginatorData($event)" showFirstLastButtons></mat-paginator>
	 -->
    
    </div>



</div>