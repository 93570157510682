import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const RRHH_URL = environment.RRHH_URL;

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(private http: HttpClient) { }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * @author Johan Soler
   * @createDate 2022-07-25
   * Servicio para listar todas las empresas
   */
  getAllBusiness(body): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('page', body.page || '')
        .set('per_page', body.per_page || '')
        .set('filter', body.filter || '')
    };
    return this.http.get<any>(`${RRHH_URL}companies`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Johan Soler
   * @createDate 2022-07-29
   * Servicio para descargar el reporte de todas las empresas
   */
  downloadReport(): Observable<any> {
    return this.http.get<any>(`${RRHH_URL}downloadCompanies`, { responseType: 'blob' as 'json' })
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Johan Soler
   * @createDate 2022-07-29
   * Servicio para crear empresa
   */
  createBusiness(body): Observable<any> {
    return this.http.post<any>(`${RRHH_URL}createCompany`, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Johan Soler
   * @createDate 2022-07-29
   * Servicio para crear empresa
   */
  editBusiness(body): Observable<any> {
    return this.http.post<any>(`${RRHH_URL}editCompany`, body)
      .pipe(
        catchError(this.handleError)
      );
  }
}
