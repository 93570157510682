import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { SPEECH_URL } = environment;

@Injectable({
  providedIn: 'root'
})
export class AdminServersService {
  constructor(private httpClient: HttpClient) { }

  /**
   * Metodo que retorna los errores generados por HTTP. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * Metodo que retorna las conexiones paginadas
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param filter filtros para la búsqueda.
   * @param paginator configuración de paginación (pageSize y pageIndex).
  */
  getServers(paginator: any, filter: string): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}connection?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&filter=${filter}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo retorna la informacion de la conexion por id
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
  */
  showServer(id: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}connection/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo que crea o actualiza la conexion seleccionado. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param connection Informacion proveniente del servidor. 
  */
  UpdateServer(connection: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}connection/${connection.id}`, connection).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo que actualiza la data de la conexion
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param connection
  */
  CreaServer(connection: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}connection/`, connection).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo lista las conexiones que estan en estado de activo para selector
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
  */
  filterServe(): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}getConnection`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Habilitar/inhabilitar la conexion
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param connection_id Id del servidor seleccionado. 
  */
  StateServer(connection_id: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}connectionState`, connection_id).pipe(
      catchError(this.handleError)
    );
  }
}