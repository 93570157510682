<div class="row novelty-row-padding" style="overflow-x: hidden;">

  <h2 mat-dialog-title style="width: 90%; margin-right: 20%;">
    <i class="fi-rr-cross-small" style="float: right; font-size: 24px;" mat-icon-button mat-dialog-close></i>
    <div class="title content" style="width: 100%; margin-top: 24px;">
      <img src="./assets/images/novelties.png" alt="noveltyIcon" class="icon">
      <span>
        <h1 style="color: #353535;">{{data.data?.name || data.title}}</h1>
        <h5 style="font-size: 16px !important; font-family: 'Poppins', sans-serif!important; color: #A9A9A9;">{{data.data?.id_number}}</h5>
      </span>
    </div>
  </h2>

  <h3 style="margin-left: -16px;">
    <span class="gray-color">{{ (dataInfo?.novelty_date_start | date:'EEEE' | titlecase) + ' ' + (dataInfo?.novelty_date_start | date:'dd')+ ' de ' + (dataInfo?.novelty_date_start | date:'MMMM') }}</span>
    <span class="fi-rr-pencil" style="margin-left: 10px; font-size: 18px;" (click)="show()"></span>
  </h3>

  <mat-dialog-content style="padding: 108px 48 108px 48px !important; overflow-x: hidden;" class="mat-typography">
   <div class="row">
      <div class="col-6" style="padding-top: 10px;">

        <div class="col-12">
          <span class="badge" style="margin-left: 93px;"[style.background]="dataInfo?.noveltyTypeToPrint?.backgroundColor">{{
            dataInfo?.novelty_type?.key }}</span> {{ dataInfo?.noveltyTypeToPrint?.title }}
  
            <mat-form-field appearance="fill" class="filter" *ngIf="fieldate == true" style="margin-left: 93px; margin-top: 32px;">
              <mat-label>Fecha incapacidad</mat-label>
              <mat-date-range-input [formGroup]="dateForm" [rangePicker]="picker">
                <input matStartDate [formControl]="dateForm.controls['start']" placeholder="Desde" #startDate>
                <input matEndDate [formControl]="dateForm.controls['end']" placeholder="Hasta" #endDate
                       (dateChange)="dateRangeChange()">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>

        <div class=" row col-12">
          <br>
          <h4 class="gray-color" style="font-size: 28px !important;"><b>Fechas por incapacidad</b></h4>
          <p class="p-color2" style="width: 35%; margin-left: 93px;"> <strong>Desde: </strong> {{ dataInfo?.novelty_date_start | date:'dd/MM/yyyy' }}</p>
          <p class="p-color2" style="width: 40%;"> <strong>Hasta: </strong> {{dataInfo?.novelty_date_end | date:'dd/MM/yyyy' }}</p>
        </div>
        <div class="row col-12">
          <div class="col-6" *ngIf="dataInfo?.extra">
            <br>
            <h4 class="gray-color" style="font-size: 18px !important;"><b>Empresa</b></h4>
            <p class="p-color">{{ dataInfo?.extra?.disability?.empresa?.name}}</p>
          </div>
          <div class="col-6" *ngIf="dataInfo?.extra">
            <br>
            <h4 class="gray-color" style="font-size: 18px !important;"><b>Eps</b></h4>
            <p class="p-color">{{ dataInfo?.extra?.disability?.eps?.name}}</p>
          </div>
        </div>
        <div class="col-12" *ngIf="dataInfo?.diagnosticName">
          <br>
          <h4 class="gray-color" style="font-size: 18px !important;"><b>Diagnóstico</b></h4>
          <p class="p-color">{{ dataInfo?.diagnosticName }}</p>
        </div>
        <div class="col-12">
          <br>
          <h4 class="gray-color" style="font-size: 18px !important;"><b>Justificación del trabajador</b></h4>
          <p class="p-color">{{ dataInfo?.justificacionTrabajador}}</p>
          <div style="margin-top: 20px; margin-left: 93px;">
            <p style="color: #2CABBC; margin-top: 5px;" *ngFor="let item of files">
               <span class="fi-rr-cloud-download" (click)="file.click()">
                <a #file [href]="item?.fullPath" target="_blank"></a> {{item?.name}}
               </span>
            </p>
          </div>
        </div>
      </div>


      <div class="col-6 text-left">
        <h4 style="float: inline-start; margin-left: 60px; font-size: 18px !important; color: #353535 !important;"><b>Gestión</b></h4>
        <form  style="margin-left: 60px;" [formGroup]="noveltiesForm">
          <mat-form-field appearance="fill" style="width: 90%; margin-bottom: 13.2px;">
            <mat-label>Estado</mat-label>
            <mat-select formControlName="state" (selectionChange)="changeState($event)">
              <mat-option *ngFor="let item of options" [value]="item.id">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 90%; margin-bottom: 13.2px;">
            <mat-label>Respuesta</mat-label>
            <textarea matInput formControlName="response"></textarea>
          </mat-form-field>

          <button mat-raised-button color="primary" style="margin-top: 32px; width: 90%; height: 48px;" [disabled]="noveltiesForm.invalid" (click)="UpdateState()">
            Guardar
          </button>
        </form>
      </div>
    </div>
  </mat-dialog-content>

</div>
