import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ControlService } from 'src/app/modules/calidad/services/control.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
  selector: 'app-agregar-agente',
  templateUrl: './agregar-agente.component.html',
  styleUrls: ['./agregar-agente.component.sass']
})
export class AgregarAgenteComponent implements OnInit {
  private uuid = 'b46ef9b8-92bb-11ee-b9d1-0242ac120003';
  public config: any;

  agentes = [];
  listAgentes = [];
  seleccion = [];
  selectable = false;
  removable = true;

  filtroCedula:string = '';
  @ViewChild('matSeleccion') matSeleccion:MatSelect;
  constructor(
    private controlService:ControlService,
    @Inject(MAT_DIALOG_DATA) public datos:any,
    private agenteRef:MatDialogRef<AgregarAgenteComponent>,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
    this.getListAgentes();
  }

  getListAgentes(){
    this.listAgentes = [];
    const params = {
      idGrupo: this.datos.idGrupo,
      cedula:''
    }
    if (this.filtroCedula != '' && this.filtroCedula != null) {
      params.cedula = this.filtroCedula;
    }
    this.controlService.getAsesores(params).subscribe((resp:any)=>{
      if (this.filtroCedula != '' && this.filtroCedula != null) {
        this.listAgentes.push(resp);
      }else if (this.datos.agentes.length == 0 ) {
        this.listAgentes = resp;
      }else{
        this.listAgentes = resp;
        this.datos.agentes.forEach(agenteExistente => {
          this.listAgentes.forEach((agente,index) => {
            if (agenteExistente.usuario_id == agente.id) {
              this.listAgentes.splice(index,1);
            }
          });
        });
      }
    });
  }

  closeDialog(){
    this.agenteRef.close();
  }

  addAsesor(){
    const data = {
      idGrupo: this.datos.idGrupo,
      data: this.seleccion.map(agente=>{
        return {id_asesor: agente.id,nombre_asesor:agente.nombre,cedula_asesor:agente.cedula}
      }),
    }
    let mensaje:string = '';
    if (data.data.length == 1) {
      mensaje = this.config.modal.addAsesor;
    }else if (data.data.length > 1) {
      mensaje = this.config.modal.addAsesors;
    }
    this.controlService.addAsesor(data).subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202) {
        Tools.swalSuccess(this.config.modal.excellent ,mensaje);
        this.closeDialog();
      }
      if (resp.respuesta.codigo != 202) {
        Tools.swalError('¡Oops!', this.config.modal.addAsesorError)
      }
    });
  }

  findById(){
    this.getListAgentes()
  }
}
