import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EscalationService } from '../../../services/rest/escalation.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';

@Component({
  selector: 'app-create-affair',
  templateUrl: './create-affair.component.html',
  styleUrls: ['./create-affair.component.sass']
})
export class CreateAffairComponent implements OnInit {

  rolAsunto: FormGroup;
  areas: any;
  jefes: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public escalationService: EscalationService,
              private alertsService: AlertsService,
              private destroyService: DestroyComponentService) { }

  ngOnInit(): void {

    this.escalationService.getArea().subscribe((resp) => {
      this.areas = resp;
    });

    this.formControl();
    this.setEdit();
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que se encarga de crear los controles del formulario
   */
  formControl(): void{

    this.rolAsunto = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      area: new FormControl('', [Validators.required]),
      jefeDirecto: new FormControl('', [Validators.required]),
      jefeInmediato: new FormControl('', Validators.required),
      horaJefeDirecto: new FormControl('', [Validators.required]),
      horaJefeInmediato: new FormControl('', [Validators.required]),
      prioridadAlta: new FormControl('', [Validators.required]),
      prioridadMedia: new FormControl('', [Validators.required]),
      prioridadBaja: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', [Validators.required]),
    });

  }

  get error(): any { return this.rolAsunto.controls; }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Trae los jefes dependiendo del area
   * @param id id del area
   */
  jefeByArea(id: any): void {
    this.escalationService.getSupervisorByArea(id).subscribe((resp) => {
      this.jefes = resp;
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que guarda un asunto
   * @param rolAsunto asunto a guardar
   */
  saveAsunto(rolAsunto: any): void {

    if (this.data.type === 'crear'){
      const asunto = {
        name: rolAsunto.value.nombre,
        description: rolAsunto.value.descripcion,
        area_id: rolAsunto.value.area,
        jefe1_id: rolAsunto.value.jefeDirecto,
        jefe2_id: rolAsunto.value.jefeInmediato,
        tiempo_jefe1: rolAsunto.value.horaJefeDirecto,
        tiempo_jefe2: rolAsunto.value.horaJefeInmediato,
        alta: rolAsunto.value.prioridadAlta,
        media: rolAsunto.value.prioridadMedia,
        baja: rolAsunto.value.prioridadBaja
      };

      this.escalationService.postAsunto(asunto).subscribe((resp) => {
        this.alertsService.alertSuccess('Guardado', resp.data);
      });
    } else if (this.data.type === 'editar'){
      this.editAsunto(rolAsunto);
    }

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que en caso que el modal sea de edicion setea los campos
   */
  setEdit(): void {
    if (this.data.type === 'editar') {

      this.jefeByArea(this.data.data.area_id);

      this.rolAsunto.controls.nombre.setValue(this.data.data.name);
      this.rolAsunto.controls.descripcion.setValue(this.data.data.description);
      this.rolAsunto.controls.area.setValue(this.data.data.area_id);
      this.rolAsunto.controls.jefeDirecto.setValue(this.data.data.jefe1_id);
      this.rolAsunto.controls.jefeInmediato.setValue(this.data.data.jefe2_id);
      this.rolAsunto.controls.horaJefeDirecto.setValue(this.data.data.tiempo_jefe1);
      this.rolAsunto.controls.horaJefeInmediato.setValue(this.data.data.tiempo_jefe2);
      this.rolAsunto.controls.prioridadAlta.setValue(this.data.data.alta);
      this.rolAsunto.controls.prioridadMedia.setValue(this.data.data.media);
      this.rolAsunto.controls.prioridadBaja.setValue(this.data.data.baja);

    }
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que se encarga de la edicion de un registro
   * @param rolAsunto registro a editar
   */
  editAsunto(rolAsunto): void{

    const asunto = {
      name: rolAsunto.value.nombre,
      description: rolAsunto.value.descripcion,
      area_id: rolAsunto.value.area,
      jefe1_id: rolAsunto.value.jefeDirecto,
      jefe2_id: rolAsunto.value.jefeInmediato,
      tiempo_jefe1: rolAsunto.value.horaJefeDirecto,
      tiempo_jefe2: rolAsunto.value.horaJefeInmediato,
      alta: rolAsunto.value.prioridadAlta,
      media: rolAsunto.value.prioridadMedia,
      baja: rolAsunto.value.prioridadBaja
    };

    this.escalationService.postEditAsunto(this.data.data.id, asunto).subscribe((resp) => {
      this.alertsService.alertSuccess('Editado', resp.data);
    });

  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

}
