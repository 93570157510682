<div mat-dialog-title>
    <button mat-icon-button style="float: right;" mat-dialog-close> <i class="fi-rr-cross"></i> </button>
    <h3>Editar información</h3>
</div>
<mat-dialog-content>
    <form [formGroup]="profileForm">
        <div fxLayout="row wrap" fxLayoutGap="32px">
            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
                <mat-label>Tipo Documento</mat-label>
                <input matInput formControlName="id_type_id">
            </mat-form-field>
            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
                <mat-label>Número de Identificación</mat-label>
                <input matInput formControlName="id_number">
            </mat-form-field>
            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
                <mat-label>Primer Nombre</mat-label>
                <input matInput formControlName="first_name">
            </mat-form-field>
            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
                <mat-label>Segundo Nombre</mat-label>
                <input matInput formControlName="middle_name">
            </mat-form-field>
            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
                <mat-label>Primer Apellido</mat-label>
                <input matInput formControlName="last_name">
            </mat-form-field>
            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
                <mat-label>Segundo Apellido</mat-label>
                <input matInput formControlName="second_last_name">
            </mat-form-field>
            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
                <mat-error *ngIf="error.email.errors"><span *ngIf="error.email.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                <mat-error *ngIf="error.email.errors"><span *ngIf="error.email.errors.email">¡¡Advertencia! Recibimos un email</span></mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
                <mat-label>Email Secundario</mat-label>
                <input matInput formControlName="email2">
                <mat-error *ngIf="error.email2.errors"><span *ngIf="error.email2.errors.email">¡¡Advertencia! Recibimos un email</span></mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
                <mat-label>Teléfono</mat-label>
                <input matInput formControlName="phone">
                <mat-error *ngIf="error.phone.errors"><span *ngIf="error.phone.errors.minlength">¡Aún falta! Recibimos mínimo 7 caracteres</span></mat-error>
                <mat-error *ngIf="error.phone.errors"><span *ngIf="error.phone.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 12 caracteres</span></mat-error>
                <mat-error *ngIf="error.phone.errors"><span *ngIf="error.phone.errors.pattern">¡Cuidado! Solo puedes ingresar números</span></mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
                <mat-label>Teléfono Movil</mat-label>
                <input matInput formControlName="mobile_phone">
                <mat-error *ngIf="error.mobile_phone.errors"><span *ngIf="error.mobile_phone.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                <mat-error *ngIf="error.mobile_phone.errors"><span *ngIf="error.mobile_phone.errors.minlength">¡Aún falta! Recibimos mínimo 10 caracteres</span></mat-error>
                <mat-error *ngIf="error.mobile_phone.errors"><span *ngIf="error.mobile_phone.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 13 caracteres</span></mat-error>
                <mat-error *ngIf="error.mobile_phone.errors"><span *ngIf="error.mobile_phone.errors.pattern">¡Cuidado! Solo puedes ingresar números</span></mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
                <mat-label>Fecha de Nacimiento</mat-label>
                <input matInput formControlName="birth_date">
            </mat-form-field>
        </div>
        <div class="text-center">
            <button mat-stroked-button class="mr" color="primary" mat-dialog-close>Cancelar</button>
            <button mat-raised-button type="submit" color="primary" [disabled]="profileForm.invalid" (click)="edit()">Guardar</button>
        </div>
    </form>
</mat-dialog-content>