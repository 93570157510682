import { createReducer, on } from "@ngrx/store";
import { SolicitudState } from "../../core/states/solicitud.state";
import { loadedSolicitud } from "../actions/solicitud.actions";

export const initialState: SolicitudState = { solicitud:null };

export const solicitudReducer = createReducer(
    initialState,
    on(loadedSolicitud,( state, { solicitud } )=>{
        return {...state, solicitud}
    })
);