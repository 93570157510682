<div class="w-100 d-flex justify-content-end" >
  <button style="float: right" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross-small"></i>
  </button>
</div>
<div class="admin-solicitudes" class="ms-5 me-5">

    <div class="header row">
      <div class="col-8">
        <h2>Orden de compra <span class="text-title-info">OC-{{data.ordenCompra.purchase_order_number}}{{ data.frag ?  '-' + data.frag.purchase_order_number : '' }}</span></h2>
      </div>
      <div *ngIf="dataOrder.path" class="col-4 d-flex justify-content-end align-items-center">
        <span class="me-1" style="text-decoration-line: underline;font-weight: 500;">Descarga orden de compra</span><button (click)="downloadOrder(dataOrder)" class=" action" type="button"  mat-icon-button>
          <i class="fi-rr-download"></i>
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>
    <br>
        <mat-tab-group class="remove-border-bottom" animationDuration="0ms">
            <mat-tab label="Datos generales">
              <mat-dialog-content class="mat-typography">

                <br>
                <h3>Datos de la empresa</h3>
                <mat-divider></mat-divider>
                <br>
                <div class="row">
                  <div class="col-4">
                    <mat-label style="font-weight: bold;">Empresa</mat-label>
                    <p>{{data.solicitud.company.name}}</p>
                  </div>
                  <div class="col-4">
                    <mat-label style="font-weight: bold;">Nit</mat-label>
                    <p>{{data.solicitud.company.nit}}</p>
                  </div>
                </div>
                <br>
                <h3>Documento controlado</h3>
                <mat-divider></mat-divider>
                <br>

                <div class="row">


                  <div class="col-4">
                    <mat-label style="font-weight: bold;">Fecha emisión</mat-label>
                    <p>{{data.solicitud.created_at | date}}</p>
                  </div>
                  <div class="col-4">
                    <mat-label style="font-weight: bold;">Tipo de adquisición</mat-label>
                    <p>{{data.solicitud.product_type.name}}</p>
                  </div>
                </div>
                <br>
                <h3>Datos del proveedor</h3>
                <hr>
                <div class="row">

                  <div class="col-4">
                    <mat-label style="font-weight: bold;">Razón social</mat-label>
                    <p>{{data.proveedor.razon_social}}</p>
                  </div>
                  <div class="col-4">
                    <mat-label style="font-weight: bold;">Nit</mat-label>
                    <p>{{data.proveedor.nit}}</p>
                  </div>
                  <div class="col-4">
                    <mat-label style="font-weight: bold;">Dirección</mat-label>
                    <p>{{data.proveedor.direccion}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <mat-label style="font-weight: bold;">Celular</mat-label>
                    <p>{{data.proveedor.user.celular}}</p>
                  </div>
                  <div class="col-4">
                    <mat-label style="font-weight: bold;">Teléfono</mat-label>
                    <p>{{data.proveedor.telefono_proveedor}}</p>
                  </div>
                  <div class="col-4">
                    <mat-label style="font-weight: bold;">E-mail</mat-label>
                    <p>{{data.proveedor.user.correo}}</p>
                  </div>
                </div>

                <br>
                <h3>Datos del solicitante</h3>
                <mat-divider></mat-divider>
                <br>

                <div class="row">
                  <div class="col-4">
                    <mat-label style="font-weight: bold;">Nombre del solicitante</mat-label>
                    <p>{{data.solicitud.created_by}}</p>
                  </div>

                </div>

              </mat-dialog-content>

            </mat-tab>

            <mat-tab label="Remisión">
              <mat-dialog-content class="mat-typography">
              <br>
              <div><b>Fecha de la compra:</b> {{data.solicitud.created_at | date}}</div>
              <div><b>Nombre del solicitante:</b> {{ data.solicitud.created_by }}</div>
              <br>
              <h3>Ítems</h3>
              <div class="my-1">
                Nota: Recuerda que la cantidad recibida no puede ser mayor a la faltante.
              </div>
              <mat-divider></mat-divider>
              <form [formGroup]="firstFormGroup">
               <div formArrayName="items" class="row col-12">
              <div class="col-12" *ngFor="let applet of items.controls; let i = index">
                <div>
                  <br>
                  <div class="row col-12" [formGroupName]="i">
                    <mat-checkbox matInput color="primary" formControlName="state"><strong>Ítem {{i+1}}</strong></mat-checkbox>
                      <mat-error *ngIf="errorItem.items.controls[i].controls.description.errors"><span *ngIf="errorItem.items.controls[i].controls.state.errors.required">¡Falta checkear el Ítem!</span></mat-error>
                    <mat-form-field style="padding: 1%;" class="col-6" appearance="fill">
                      <mat-label>Descripción</mat-label>
                      <input  style="text-transform: none;" matInput type="text" placeholder="Escribe las especificaciones" formControlName="description" required [readonly]="true">
                      <mat-error *ngIf="errorItem.items.controls[i].controls.description.errors"><span *ngIf="errorItem.items.controls[i].controls.description.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                      <mat-error *ngIf="errorItem.items.controls[i].controls.description.errors"><span *ngIf="errorItem.items.controls[i].controls.description.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                      <mat-error *ngIf="errorItem.items.controls[i].controls.description.errors"><span *ngIf="errorItem.items.controls[i].controls.description.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 255 caracteres</span></mat-error>
                    </mat-form-field>



                    <mat-form-field style="padding: 1%;" class="col-2" appearance="fill">
                      <mat-label>Cantidad solicitada</mat-label>
                      <input  style="text-transform: none;" matInput type="number" placeholder="Escribe la cantidad" min="0" formControlName="unit" required [readonly]="true">
                      <mat-error *ngIf="errorItem.items.controls[i].controls.unit.errors"><span *ngIf="errorItem.items.controls[i].controls.unit.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                      <mat-error *ngIf="errorItem.items.controls[i].controls.unit.errors"><span *ngIf="errorItem.items.controls[i].controls.unit.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                      <mat-error *ngIf="errorItem.items.controls[i].controls.unit.errors"><span *ngIf="errorItem.items.controls[i].controls.unit.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 3 caracteres</span></mat-error>
                    </mat-form-field>

                    <mat-form-field style="padding: 1%;" class="col-2" appearance="fill">
                      <mat-label>Cantidad recibida</mat-label>
                      <input  style="text-transform: none;" matInput type="number" placeholder="Escribe la cantidad" min="0" formControlName="unitReceived" (blur)="calcularCantidadFaltante(i)" required>
                      <mat-error *ngIf="errorItem.items.controls[i].controls.unitReceived.errors"><span *ngIf="errorItem.items.controls[i].controls.unitReceived.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                      <mat-error *ngIf="errorItem.items.controls[i].controls.unitReceived.errors"><span *ngIf="errorItem.items.controls[i].controls.unitReceived.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                      <mat-error *ngIf="errorItem.items.controls[i].controls.unitReceived.errors"><span *ngIf="errorItem.items.controls[i].controls.unitReceived.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 3 caracteres</span></mat-error>
                    </mat-form-field>

                    <mat-form-field style="padding: 1%;" class="col-2" appearance="fill">
                      <mat-label>Cantidad faltante</mat-label>
                      <input  style="text-transform: none;" matInput type="number" placeholder="Escribe la cantidad" min="0" formControlName="pending" required [readonly]="true">
                      <mat-error *ngIf="errorItem.items.controls[i].controls.pending.errors"><span *ngIf="errorItem.items.controls[i].controls.pending.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                      <mat-error *ngIf="errorItem.items.controls[i].controls.pending.errors"><span *ngIf="errorItem.items.controls[i].controls.pending.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                      <mat-error *ngIf="errorItem.items.controls[i].controls.pending.errors"><span *ngIf="errorItem.items.controls[i].controls.pending.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 3 caracteres</span></mat-error>
                    </mat-form-field>

                    <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                      <mat-label>Valor unitario</mat-label>
                      <input style="text-transform: none;" matInput currencyMask [options]="options" placeholder="Escribe el valor unitario " min="0" formControlName="unit_value" (blur)="calcularValorTotal(i)"  required [readonly]="true">
                      <mat-error *ngIf="errorItem.items.controls[i].controls.unit_value.errors"><span *ngIf="errorItem.items.controls[i].controls.unit_value.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                      <mat-error *ngIf="errorItem.items.controls[i].controls.unit_value.errors"><span *ngIf="errorItem.items.controls[i].controls.unit_value.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                      <mat-error *ngIf="errorItem.items.controls[i].controls.unit_value.errors"><span *ngIf="errorItem.items.controls[i].controls.unit_value.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 3 caracteres</span></mat-error>
                    </mat-form-field>

                    <mat-form-field style="padding: 1%;" class="col-9" appearance="fill">
                      <mat-label>Observaciones</mat-label>
                      <input  style="text-transform: none;" matInput type="text" placeholder="Escribe las especificaciones" formControlName="observation" required>
                      <mat-error *ngIf="errorItem.items.controls[i].controls.observation.errors"><span *ngIf="errorItem.items.controls[i].controls.observation.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                      <mat-error *ngIf="errorItem.items.controls[i].controls.observation.errors"><span *ngIf="errorItem.items.controls[i].controls.observation.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                      <mat-error *ngIf="errorItem.items.controls[i].controls.observation.errors"><span *ngIf="errorItem.items.controls[i].controls.observation.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 255 caracteres</span></mat-error>
                    </mat-form-field>
                      <input  type="hidden" formControlName="id">
                  </div>
                  <br />
                  <br />
                  </div>
                </div>
                <div class="col-12">
                  <div style="float: right;" *ngIf="!viewRemission">
                    <button class="bold compras-modal-button"  mat-stroked-button mat-dialog-close>Cancelar</button>
                    <button  mat-stroked-button

                      color   = "primary"
                      (click) = "sendRemission()"
                      *ngIf="this.data.ordenCompra"
                      class="ms-2 me-2 bold compras-modal-button"
                      [disabled]="faltanteOk"
                      >Enviar</button>
                    <button mat-raised-button
                      color                 = "primary"
                      [disabled]="envioOk"
                      (click)               = "saveRemission()"
                      class="bold compras-modal-button"
                    >Guardar</button>
                  </div>
                </div>

                </div>



          </form>

        </mat-dialog-content>
            </mat-tab>
        </mat-tab-group>



</div>
