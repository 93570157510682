import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weekDays'
})
export class WeekDaysPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let object =  results.find(e=>{return e.key === String(value)})
    return object? object.name : 'N/A';
  }

}
const results=[
  {key: 'Monday', name: 'Lunes'},
  {key: 'Tuesday', name: 'Martes'},
  {key: 'Wednesday', name: 'Miércoles'},
  {key: 'Thursday', name: 'Jueves'},
  {key: 'Friday', name: 'Viernes'},
  {key: 'Saturday', name: 'Sábado'},
  {key: 'Sunday', name: 'Domingo'},
]
