import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListContactComponent } from './componentes/list-contact/list-contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { ActiveChatComponent } from './componentes/active-chat/active-chat.component';
import { ChatRestService } from './services/chat-rest.service';
import { ManagerChatService } from './services/manager-chat.service';
import { ChatGroupsComponent } from './componentes/chat-groups/chat-groups.component';
import { ElementSelectorComponent } from './componentes/element-selector/element-selector.component';
import { AllContactsComponent } from './componentes/all-contacts/all-contacts.component';
import { ViewUsersGroupComponent } from './componentes/view-users-group/view-users-group.component';



@NgModule({
  declarations: [
    ListContactComponent,
    ActiveChatComponent,
    ChatGroupsComponent,
    ElementSelectorComponent,
    AllContactsComponent,
    ViewUsersGroupComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  exports:[
    ListContactComponent,
    ActiveChatComponent
  ],
  providers:[
    ChatRestService,
    ManagerChatService
  ]
})
export class ChatSoulModule { }
