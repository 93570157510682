import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { ArchivosService } from 'src/app/modules/compras/services/rest/archivos.service';
import { CompaniaService } from 'src/app/modules/compras/services/rest/compania.service';
import { SolicitudesService } from 'src/app/modules/compras/services/rest/solicitudes.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import Swal from 'sweetalert2';
import { AprobarSolcitudComponent } from '../aprobar-solcitud/aprobar-solcitud.component';
import { ProcesarSolicitudComponent } from '../procesar-solicitud/procesar-solicitud.component';

@Component({
  selector: 'app-procesar-solicitud-control',
  templateUrl: './procesar-solicitud-control.component.html',
  styleUrls: ['./procesar-solicitud-control.component.sass']
})
export class ProcesarSolicitudControlComponent implements OnInit {

  solicitudesForm         : FormGroup;
  itemsSolciitud          : any = [];
  archivosPorSolicitud    : any;
  archivo                 : any;
  aprobada                : boolean;
  archivosCargados        : any[] = [];
  registrosPorCargar      : any[] = new Array(3);
  sinAdjuntos             : boolean = true;
  file                    : any;
  viewSolciitud           = false;
  myFiles                 : string [] = [];
  filteredOptionsCompania: Observable<any[]>;
  compania:any;

  constructor(private solicitudService: SolicitudesService,
    private archivoService                  : ArchivosService,
    private destroyService                  : DestroyComponentService,
    private form                            : FormBuilder,
    private alertsService                   : AlertsService,
    private athService                      : AuthService,
    @Inject(MAT_DIALOG_DATA) public data    : any,
    private dialogRef                       : MatDialogRef< ProcesarSolicitudComponent >,
    public dialog: MatDialog,
    private companiaService:CompaniaService,
    ) { }

  ngOnInit(): void {
    this.archivosCargados = [];
    this.itemsSolciitud   = this.data.solicitud.request_items;


    this.formControl();
    if (this.data.solicitud.request_state_id == '4' ||
        this.data.solicitud.request_state_id == '6'
        ){

      this.aprobada=true;
    }

    if (this.data.type === 'editar' || this.data.type === 'ver' ) {
      this.solicitudesForm.controls.estado.setValue( parseInt(this.data.solicitud.request_state_id) );


      this.solicitudesForm.controls.observacion.setValue(this.data.solicitud.request_observation ? this.data.solicitud.request_observation[0].observation : "");
      this.archivoService.getArchivosSolicitud(this.data.solicitud.id)
        .subscribe(resp => {
          resp.data.forEach((element, idx) => {
            this.registrosPorCargar[idx] = element;
            let control;
            if( idx ==0 )
            {
              control = this.solicitudesForm.get("cot1_txt");
            }
            else if( idx == 1 )
            {
              control = this.solicitudesForm.get("cot2_txt");

            }
            else if( idx == 2 )
            {
              control = this.solicitudesForm.get("cot3_txt");
            }
            control.setValue( this.registrosPorCargar[idx].name );
          });


          this.sinAdjuntos = this.checkFiles();

      });
    }


    this.companiaService.getCompany().subscribe(comp=>{
      this.compania = comp.data;
      if(this.data.type ==='editar' || this.data.type ==='Procesar'){
        this.solicitudesForm.controls.companias.setValue(Number(this.data.solicitud.company_id));
      }

      this.filteredOptionsCompania = this.solicitudesForm.get('companias').valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(companias => companias ? this._filterCompany(companias) : this.compania.slice())
        );
      });

    if (this.data.type === 'ver') {
      this.viewSolciitud = true;
    }


  }

  private editasolicitud(estado ){
    //this.uploadFiles();

    this.modificarSolicitud(estado);
  }

  saveSolicitud(solicitudesForm)  {
    if( solicitudesForm.value.estado == 8 ){
      Swal.fire({
        title               : '¿Estás seguro?',
        text                : "¿Deseas devolver la solicitud?, cambiará a estado devuelta, recuerda esto será definitivo y no se podrá deshacer.",
        showCancelButton    : true,
        cancelButtonText    : "Cancelar",
        icon                : 'warning',
        confirmButtonText   : 'Aceptar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.editasolicitud(null);
        }

      })
    }
    else{
      this.editasolicitud(null)
    }





  }

  sendSolicitud() {
    Swal.fire({
      title               : '¿Estás seguro?',
      text                : "¿Deseas enviar la soliciud?, Cambiará de estado a disponible, recuerda que esto será definitivo y no se podrá deshacer",
      showCancelButton    : true,
      cancelButtonText    : "Cancelar",
      icon                : 'warning',
      confirmButtonText   : 'Aceptar',
    }).then((result) => {
      if (result.isConfirmed) {
        //this.saveSolicitud(this.solicitudesForm);


        const estado = {
          state: this.solicitudesForm.value.estado,
          company : this.solicitudesForm.value.companias
        };

        //if (this.registrosPorCargar.length > 0) {
          this.editasolicitud(null);
          this.dialogRef.close();
        //} else {
        //  this.alertsService.alertError('¡Oops!', 'Debe añadir al menos una cotización' );
        //}

      }
    })



  }

  private modificarSolicitud(estado) {
    if( estado == null){
      estado = {
        state: this.solicitudesForm.value.estado,
        company : this.solicitudesForm.value.companias
      }
    }
    const observacion = new FormData();
    observacion.append('observacion', this.solicitudesForm.value.observacion);
    observacion.append('idSolicitud', this.data.solicitud.id);
    observacion.append('type', "control");
    this.solicitudService.updateSolicitudEstado(estado, this.data.solicitud.id  ).subscribe(resp => {
      this.solicitudService.saveObservacion(observacion)
        .subscribe( resp => {
          this.dialogRef.close();
          this.alertsService.alertSuccess('Guardado', 'La solicitud fue guardada y esta en proceso');
          return true;
        });
   });
  }

  get error(): any { return this.solicitudesForm.controls; }

  /**
  * @author Jose Vicente Silva
  * @createdate 2021-01-27
  * Metodo donde se establecen las validaciones del formulario
  */
   formControl(): void {
    const stateId = parseInt(this.data.solicitud.request_state_id);

     this.solicitudesForm = new FormGroup({
        cotizacion    : new FormControl(''),
        estado        : new FormControl("", [ Validators.required ]),
        companias       : new FormControl('', [this.autocompleteObjectValidator(), Validators.required]),
        observacion   : new FormControl('', [Validators.required]),
        cot1_txt      : new FormControl(''),
        cot2_txt      : new FormControl(''),
        cot3_txt      : new FormControl(''),


    });

  }

  cambioEstado(event) {
    if (event == 2 ) {
      this.aprobada = true;

    } else {
      this.aprobada = false;
    }
  }

  downloadFile(e: any) {
    const archivo = {
      path: e.path
    };

    this.archivoService.downloadOrder(archivo).subscribe( (data) => {
      const a = document.createElement('a');
      const file = new Blob([data], {type: e.fileContentType});
      a.href = URL.createObjectURL(file);
      a.download = e.name;
      a.click();
    });
  }

  getFileDetails (e) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.registrosPorCargar.push(e.target.files[i]);
    }

  }

  /**
   * Agrega un archivo al arreglo de archivos
   * @param e
   * @param index
   */
  setFile(e,index:number ){
    this.registrosPorCargar[index] = e.target.files[0];
    this.sinAdjuntos = this.checkFiles();
    let control;
    if( index ==0 )
    {
      control = this.solicitudesForm.get("cot1_txt");
    }
    else if( index == 1 )
    {
      control = this.solicitudesForm.get("cot2_txt");

    }
    else if( index == 2 )
    {
      control = this.solicitudesForm.get("cot3_txt");
    }
    control.setValue( this.registrosPorCargar[index].name );


  }

  removeFileDetails(file, i) {
    let control;
    if (file.id) {
      this.archivoService.eliminarArchivo(file.id).subscribe(resp => {
        this.registrosPorCargar[i] = null;
        this.sinAdjuntos = this.checkFiles();
        //this.registrosPorCargar.splice(i, 1);
      });
    } else {
      this.registrosPorCargar[i] = null;
      this.sinAdjuntos = this.checkFiles();
      //this.registrosPorCargar.splice(i, 1);
    }

    if( i ==0 )
    {
      control = this.solicitudesForm.get("cot1_txt");
    }
    else if( i == 1 )
    {
      control = this.solicitudesForm.get("cot2_txt");

    }
    else if( i == 2 )
    {
      control = this.solicitudesForm.get("cot3_txt");
    }
    control.setValue( "" );
  }

  uploadFiles () {
    this.registrosPorCargar.forEach((registro) => {
      if (registro && !registro.id)
      {
        let formData = new FormData();
        formData.append('archivoCarga', registro);
        formData.append('type', 'cotizacion');
        formData.append('idSolicitud', this.data.solicitud.id);
        this.archivoService.postCargarArchivos(formData).subscribe((resp) => {
          const data = {
            id: resp.data.id,
            path: resp.data.path,
            name: resp.data.name,
            content_type: resp.data.contentType
          };
          this.archivosCargados.push(data);
        });
      }
    });
  }

  handleFileInput(event) {
    this.file = event.target.files[0];
    const formData = new FormData();
    formData.append('archivoCarga', this.file);
    formData.append('type','cotizacion')
    this.archivoService.postCargarArchivos(formData).subscribe((resp) => {
      const data = {
        id: 0,
        path: resp.data.path,
        name: resp.data.name,
        content_type: resp.data.contentType
      };
      this.archivosCargados.push(data);
    });
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }


  editSolicitud(){
    this.dialog.open(AprobarSolcitudComponent , {
      width: '80%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Detalles solicitud',
        solicitud:this.data.solicitud
      },
      panelClass: 'custom-dialog-container',
    });
    this.dialogRef.close();
  }


  /**
   * Verifica si hay cotizaciones cargadas
   * @returns
   */
  private checkFiles(): boolean {
    let isEmpty = true;

    this.registrosPorCargar.forEach(element => {
      if( element !== null ){
        isEmpty = false;
      }
    });
    return isEmpty;
  }


  /**
  * @author Jose Vicente Silva
  * @createdate 2021-08-27
  * filtra por nombre, se usa para el autocompletable
  * @param value valor a filtrar
  */
   private _filterCompany(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.compania.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }


  displayFnCompania(id: number): string{
    if (!id) { return ''; }
    let index = this.compania.findIndex(resp => resp.id === id);
    return this.compania[index].name;

  }


  /**
  * @author Jose Vicente Silva
  * @createdate 2021-08-27
  * Metodo que valida las funciones del autocompetable
  */
   autocompleteObjectValidator(): ValidatorFn {
    return (control: AbstractControl ): { [key: string]: any } | null => {
      if (typeof control.value === 'string') {
        return { invalidAutocompleteObject: { value: control.value } };
      }
      return null;
    };
  }


}
