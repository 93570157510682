import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CoachingState } from "../coaching.state";

const getCoachingState = createFeatureSelector<CoachingState>('COACHING');


export const listCampañas = createSelector(
    getCoachingState,
    (state) => state.selects.campañas,
    );
    
export const listIndicadores = createSelector(
    getCoachingState,
    (state) => state.selects.selects.indicadores,
);
export const listCuartiles = createSelector(
    getCoachingState,
    (state) => state.selects.selects.cuartilInicial,
);

export const listCausas = createSelector(
    getCoachingState,
    (state) => state.selects.selects.causaRaiz,
);

export const listEstados = createSelector(
    getCoachingState,
    (state) => state.selects.selects.estado,
);

export const listCompromisos = createSelector(
    getCoachingState,
    (state) => state.selects.selects.cumpleCompromiso,
);

export const listEvoluciones = createSelector(
    getCoachingState,
    (state) => state.selects.selects.presentaEvolucion,
);

export const listProcesos = createSelector(
    getCoachingState,
    (state) => state.selects.selects.tipoProceso,
);