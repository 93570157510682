<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross"></i>
</button>

<h3 >Carga masiva de candidatos</h3>
<hr>
<div class="btns" >
  <button mat-button matSuffix (click)="exportFormats()" matTooltip="Formato obligatorio para carga de candidatos"> Descargar plantilla &nbsp; <i class=" fi-rr-document"></i> </button>

  <button mat-flat-button color="primary" (click)="onClick()">Cargar registros &nbsp;<span class="fi-rr-upload"></span></button>
  <input type="file" hidden #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>

</div>
<hr>
<mat-dialog-content>

  <div class="info-candidate" *ngIf="fileUpdaloaded">
      <p><strong>Resumen</strong></p>
      <p><i class="fi-rr-users"></i> &nbsp; <b>Total de Candidatos a Importar</b> &nbsp;&nbsp; <span>{{ candidateSummaryUpdated.totalCandidates }}</span> </p>
      <p><i class="fi-rr-check"></i> &nbsp; <b>Candidatos registrados con éxito </b> &nbsp;&nbsp;<span>{{ candidateSummaryUpdated.candidateToSave }}</span></p>
      <p> <i class="fi-rr-exclamation"></i> &nbsp; <b>Candidatos ignorados para este proceso</b>&nbsp;&nbsp;  <span>{{ candidateSummaryUpdated.candidatesToIgnore.length }}</span></p>
      <div *ngIf="candidateSummaryUpdated && candidateSummaryUpdated.candidatesToIgnore.length">
          <p><i class="fi-rr-exclamation"></i> &nbsp; <b>Candidatos Ignorados</b> &nbsp;&nbsp;</p>
          <ul>
              <li *ngFor="let row of candidateSummaryUpdated.candidatesToIgnore"> {{row.tipo_documento}} - {{row.numero}} | {{ row.primer_nombre }} {{ row.apellido }} <b>({{ row.reason }})</b></li>
          </ul>
      </div>
      <p><i class="fi-rr-check"></i> &nbsp; <b>Candidatos referidos con éxito </b> &nbsp;&nbsp;<span>{{ candidateSummaryUpdated.referdToSave }}</span></p>
      <div *ngIf="candidateSummaryUpdated.referdFail.length > 0">
          <p><i class="fi-rr-exclamation"></i> &nbsp; <b>Documentos referidos no encontrados</b> &nbsp;&nbsp;</p>
          <ul>
              <li *ngFor="let row of candidateSummaryUpdated.referdFail">{{row}}</li>
          </ul>
      </div>
  </div>


</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-stroked-button type="button" mat-dialog-close>Cancelar</button>
  <button mat-flat-button color="primary" mat-dialog-close>Terminar</button>
</mat-dialog-actions>
