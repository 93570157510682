import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paso-paso',
  templateUrl: './paso-paso.component.html',
  styleUrls: ['./paso-paso.component.sass']
})
export class PasoPasoComponent implements OnInit {
  pasoPaso:boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
