import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { DomSanitizer } from '@angular/platform-browser';
import * as html2pdf from 'html2pdf.js';
import { ChatService } from '../../../services/chat.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-transc-chat-modal',
  templateUrl: './transc-chat-modal.component.html',
  styleUrls: ['./transc-chat-modal.component.sass']
})
export class TranscChatModalComponent implements OnInit {

  @ViewChild('containerViewReport') containerViewReport: ElementRef;
  @ViewChild('transcripcionPanel') transcripcionPanel: MatExpansionPanel;
  @ViewChild('resumenPanel') resumenPanel: MatExpansionPanel;
  @ViewChild('analisisTexto') analisisTexto: MatExpansionPanel;

  highlightedTerms: Set<string> = new Set();
  interactions: any[] = [];
  palabrasClaveArray: string[] = [];
  data: any;
  case_id: any;
  llmResponseObject: any;
  editMode = false;

  chatData: any;
  interactionsFormat: any[];
  sentimientosDataPorSpeaker: any[];
  sentimientosData: { name: string; value: number; label: string; }[];


  //propiedades grafica
  view: any[] = [700, 400];  // Tamaño de la gráfica
  showLegend = true;
  showLabels = true;
  isDoughnut = false;
  gradient = false;
  colorScheme2 = {
    domain: ['#1565C0', '#B0BEC5', '#FFF176']
  };

  constructor(  
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<TranscChatModalComponent>,
    private chatService: ChatService,
    private sanitazer: DomSanitizer) 
    {}
    
  ngOnInit(): void {
    this.case_id = this.dialogData.id;
    this.loadChatData();
  }

  /**
   * Metodo para cargar los datos del chat y las interacciones formatadas.
   * @author Yeison Sepulveda
   * @createdate 2024-07-16
 */
  
  loadChatData(): void {
    this.chatService.getChatById(this.case_id).subscribe(
      (response) => {
        this.chatData = response.data[0];
  
        this.chatService.showResultsChat(this.case_id).subscribe(res => {
          this.llmResponseObject = res.data[0].llm_response;
          this.palabrasClaveArray = this.llmResponseObject.palabras_clave;
          this.interactions = this.mergeInteractions(this.chatData.chat_details, res.data[0].analysis_feelings);
  
          this.interactionsFormat = this.interactions;
          console.log('data de interactionformat', this.interactionsFormat)

        });
      },
      (error) => {
        console.error('Error al consultar los resultados de análisis', error);
      }
    );
  }
  

  /**
   * Combina los detalles del chat con los sentimientos analizados basados en el ID.
   * @updated Yeison Sepulveda
   * @createdate 2024-07-16
   * @param {any[]} chatDetails - Detalles del chat a ser combinados.
   * @param {any[]} analysisFeelings - Sentimientos analizados a ser combinados.
   * @returns {any[]} Un arreglo de interacciones combinadas.
 */

  mergeInteractions(chatDetails: any[], analysisFeelings: any[]): any[] {
    return chatDetails.reduce((mergedInteractions, detail) => {
      const matchingAnalysis = analysisFeelings.find(analysis => analysis.id === detail.id);
      if (matchingAnalysis) {
        const palabrasClave = this.palabrasClaveArray.filter(palabra => detail.content.includes(palabra));
        mergedInteractions.push({
          ...detail,
          ...matchingAnalysis,
          text: detail.content,
          palabrasClave: palabrasClave,
          probabilidadSentimiento: matchingAnalysis,
        });
      }
      return mergedInteractions;
    }, []);
  }
  
  /**
   * Metodo para los chips en los cuales busca las palabras y los subraya
   * @author Yeison Sepulveda
   * @createdate 27/02/2024
   */

  toggleHighlight(palabra: string): void {
    if (this.highlightedTerms.has(palabra)) {
      this.highlightedTerms.delete(palabra);
    } else {
      this.highlightedTerms.add(palabra);
    }
  
    this.interactionsFormat = this.interactions.map(interaction => {
      let text = interaction.text;
  
      text = this.removeHighlight(text);
  
      text = this.highlightedText(text, this.highlightedTerms);
  
      return { ...interaction, text: this.sanitazer.bypassSecurityTrustHtml(text) };
    });
  
  }
  

  /**
   * Elimina el resaltado de las palabras clave dentro de un texto resaltado.
   * @author Yeison Sepulveda
   * @createdate 20/05/2024
   * @param {string} text - El texto con las palabras clave resaltadas.
   * @returns {string} El texto sin el resaltado de las palabras clave.
  */
  
  removeHighlight(text: string): string {
    const spanRegex = /<span style='padding: 0.1rem 0.4rem; background-color: #00acc1; color: #fff; border-radius: 50px;'>(.*?)<\/span>/g;
    return text.replace(spanRegex, '$1');
  }


  /**
   * Subraya las palabras clave dentro de un texto.
   * @author Yeison Sepulveda
   * @createdate 27/02/2024
   * @param {string} text - El texto en el que se buscarán y resaltarán las palabras clave.
   * @param {string} term - La palabra clave que se debe resaltar.
   * @returns {string} El texto con las palabras clave resaltadas.
  */

  highlightedText(text: string, terms: Set<string>): string {
    if (!terms || terms.size === 0) {
      return text;
    }

    const styleCss = 'padding: 0.1rem 0.4rem; background-color: #00acc1; color: #fff; border-radius: 50px;';
    terms.forEach(term => {
      if (term && term.trim()) {
        const regex = new RegExp(`\\b${term}\\b`, 'gi');
        text = text.replace(regex, `<span style='${styleCss}'>$&</span>`);
      }
    });

    return text;
  }

  /**
   * Metodo generar el nombre del documento a descargar 
   * @author Yeison Sepulveda
   * @createdate 2024-0-03
   * @return nombre del archivo PDF
  */
  
  generateFilename(): string {
    const currentDate = new Date().toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '');
  
    const currentTime = new Date().toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).replace(/:/g, '');
  
    const dateTimeString = `${currentDate}${currentTime}`;
    return `reporte_analisis_${this.case_id}_${dateTimeString}.pdf`;
  }

  /**
   * Metodo que reinicia los valores del modal. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  cancelSave(): void {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Si cancelas el proceso, perderás toda la información no guardada.',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.dialogRef.close();
      }
    });
  }

}
