import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MiosRoutingModule } from './mios-routing.module';
import { MiosComponent } from './mios.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { MaterialModule } from '../shared/material/material.module';
import { LeftNavComponent } from './left-nav/left-nav.component';
import { ModalPasswordComponent } from './modal-password/modal-password.component';
import { SharedModule } from '../shared/shared.module';
import { ModalSurveysUserComponent } from './modal-surveys-user/modal-surveys-user.component';

@NgModule({
  declarations: [
    MiosComponent,
    SideBarComponent,
    LeftNavComponent,
    ModalPasswordComponent,
    ModalSurveysUserComponent,
  ],
  imports: [
    CommonModule,
    MiosRoutingModule,
    MaterialModule,
    SharedModule
  ]
})
export class MiosModule { }
