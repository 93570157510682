<div class="admin-solicitudes">

    <div class="header col-4">
        <h1>Solicitudes<i class="fas fa-container-storage    "></i></h1>
        <p>Aquí puedes revisar y/o gestionar las solicitudes con sus respectivas cotizaciones.</p>
    </div>


    <mat-tab-group class="remove-border-bottom" animationDuration="0ms" (selectedTabChange)="selectedTabChange($event)">
        <mat-tab label="Gestión">
            <app-gestion-aprobador></app-gestion-aprobador>
        </mat-tab>
        <mat-tab label="Histórico">
           <app-historial-aprobador></app-historial-aprobador>
        </mat-tab>
        
      </mat-tab-group>
    
   

</div>
