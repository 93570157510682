import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../../../core/services/rest/auth.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';


const REPORTS_URL = environment.REPORTS_URL;

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

   /**
   * @author José Vicente Silva
   * @createdate 2021-02-02
   * Servicio que lista todos los groupes del aplicativo ciu
   * @param size tamaño de los datos a traer
   * @param page numero de pagina en la que se ubicara la tabla
   */
  getReport(reportKey:string): Observable<any>{
    return this.http.get<any>(`${REPORTS_URL}report/tokens?key=${reportKey}` )
    .pipe(
      catchError(this.handleError)
      );
  }
}
