import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { CoachingService } from 'src/app/modules/coaching/services/coaching.service';
import { loadAuditoria } from 'src/app/modules/coaching/state/actions/auditoria.actions';
import { loadHistorial } from 'src/app/modules/coaching/state/actions/historial.actions';
import { loadSelects } from 'src/app/modules/coaching/state/actions/selects.actions';
import { loadSolicitud } from 'src/app/modules/coaching/state/actions/solicitud.actions';
import { solicitudSelect } from 'src/app/modules/coaching/state/selectors/solicitud.selector';

@Component({
  selector: 'app-detalle-coaching',
  templateUrl: './detalle-coaching.component.html',
  styleUrls: ['./detalle-coaching.component.sass']
})
export class DetalleCoachingComponent implements OnInit, OnDestroy {
  type:string;
  id:string;
  solicitud$:Observable<any> = new Observable();
  indicador_final:number = 0;
  cuartil_final:string = '';
  is_asesor:boolean = false;
  rol;
  //Observable para los cambios de la tabla
  subscription:Subscription; 
  constructor(
    private store:Store<any>,
    private aRoute:ActivatedRoute,
    private coachingService:CoachingService,
    private router:Router,
    private authService:AuthService,
  ) { }

  ngOnInit(): void {
    this.aRoute.paramMap.subscribe(params=>{
      this.type = params.get('type');
      this.id = params.get('id');
    });
    this.authService.getUser().roles.forEach(rol => {
      if (rol == 'coaching::asesor') {
        this.is_asesor = true;
      }
      if (rol.includes('coaching::')) {     
        this.rol = rol;
      }
    });
    this.dispatch();
    this.subscription = this.coachingService.refresh$.subscribe(()=>{
      this.dispatch();
    });
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }

  /**
  * @author Cristian Gallo
  * @createdate 2021-01-17
  * Metodo para validar a que tab debe regresar.
  */
  regresar(){
    if (this.type === "solicitud" || this.type === "auditoria") {
      this.router.navigateByUrl('/mios/coaching/coaching/0')
    }else{
      this.router.navigateByUrl('/mios/coaching/coaching/1')
    }
  }

  /**
  * @author Cristian Gallo
  * @createdate 2021-12-17
  * Metodo para realizar la ajecución de las acciones.
  */
  dispatch(){
    this.store.dispatch(loadSelects({rol:this.rol}));
    if (this.type === "auditoria") {
      this.store.dispatch(loadAuditoria({idAuditoria:this.id}));
    }else if (this.type === "solicitud" || this.type === "finalizado") {
      this.store.dispatch(loadSolicitud({idSolicitud:this.id}));
      this.store.dispatch(loadHistorial({idSolicitud:this.id}));
    }

    if (this.type === "finalizado") {
      this.solicitud$ = this.store.select(solicitudSelect);
      this.solicitud$.forEach(element => {
        if (element) {
          this.indicador_final = element[0].acompanamiento.indicadorFinal;
          this.cuartil_final = element[0].acompanamiento.cuartil_final.nombre;
        }
      });
    }
  }

}
