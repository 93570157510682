<div fxLayout="row">
    <mat-form-field appearance="fill" class="me-3" fxFlex.gt-lg="15%" fxFlex.gt-md="15%">
        <mat-label>Fecha</mat-label>
        <mat-date-range-input [rangePicker]="rango" [formGroup]="filtroFecha">
            <input matStartDate placeholder="Fecha inicio" formControlName="fechaInicial">
            <input matEndDate placeholder="Fecha fin" formControlName="fechaFinal">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rango">
            <mat-icon matDatepickerToggleIcon><i class="fi-rr-calendar"></i></mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker #rango></mat-date-range-picker>
    </mat-form-field>
    <mat-form-field appearance="fill" class="me-3" fxFlex.gt-lg="15%" fxFlex.gt-md="15%">
        <mat-label>Creación seguimiento</mat-label>
        <mat-date-range-input [rangePicker]="rangoSegui" [formGroup]="filtroFechaSegui">
            <input matStartDate placeholder="Fecha inicio" formControlName="fechaInicialSegui">
            <input matEndDate placeholder="Fecha fin" formControlName="fechaFinalSegui">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rangoSegui">
            <mat-icon matDatepickerToggleIcon><i class="fi-rr-calendar"></i></mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker #rangoSegui></mat-date-range-picker>
    </mat-form-field>    
    <mat-form-field appearance="fill" class="me-3" fxFlex.gt-lg="15%" fxFlex.gt-md="15%">
        <mat-label>Campaña</mat-label>
        <mat-select [(value)]="filtroCampaign">
            <mat-option>Ninguno</mat-option>
            <ng-container *ngIf="campaignJefe; else noJefe">
                <mat-option *ngFor="let campaign of campaigns$ | async" [value]="campaign.id">{{campaign.name | uppercase }}</mat-option>
            </ng-container>
            <ng-template #noJefe>
                <mat-option *ngFor="let campaign of campaigns$ | async" [value]="campaign.id">{{campaign.name | uppercase }}</mat-option>
            </ng-template>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="me-3" fxFlex.gt-lg="20%" fxFlex.gt-md="18%" *ngIf="!is_supervisor">
        <mat-label>Nombre o cédula coach</mat-label>
        <input type="text" matInput [(ngModel)]="filtroParametro">
    </mat-form-field>
    <div fxFlex.gt-lg="20%" fxFlex.gt-md="18%" *ngIf="is_supervisor"></div>
    <div fxLayoutAlign="end none" fxFlex.gt-lg="35%"  fxFlex.gt-md="35%">
        <button mat-raised-button color="primary" style="height: 48px; width: 232px;" (click)="download()">
            Descargar reporte <mat-icon style="font-size: 16px;" class="mt-2"><i class="fi-rr-download"></i></mat-icon> 
        </button>
    </div>
</div>