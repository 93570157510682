import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplatesListComponent } from './templates-list/templates-list.component';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgCircleProgressModule} from 'ng-circle-progress';
import { TemplateNewComponent } from './template-new/template-new.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';
import { TemplateIndexComponent } from './template-index/template-index.component';
import { TemplateHistoryComponent } from './template-history/template-history.component';
import { TemplatePreviewComponent } from './template-preview/template-preview.component';
import { TemplateConfirmationComponent } from './template-confirmation/template-confirmation.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { TagsListComponent } from './tags-list/tags-list.component';
import { TagsNewComponent } from './tags-new/tags-new.component';



@NgModule({
  declarations: [TemplatesListComponent, TemplateNewComponent, TemplateIndexComponent, TemplateHistoryComponent, TemplatePreviewComponent, TemplateConfirmationComponent, TagsListComponent, TagsNewComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        NgCircleProgressModule,
        FormsModule,
        MatTabsModule,
        MatDialogModule,
        MatOptionModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatDatepickerModule,
    ]
})
export class TemplatesModule { }
