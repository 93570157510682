import { startWith, map } from 'rxjs/operators';
import { CompaniaService } from '../../../../services/rest/compania.service';
import { Observable } from 'rxjs';
import { AprobarSolcitudComponent } from './../aprobar-solcitud/aprobar-solcitud.component';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { SolicitudesService } from '../../../../services/rest/solicitudes.service';
import { ArchivosService } from '../../../../services/rest/archivos.service';
import { DestroyComponentService } from '../../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import { AuthService } from '../../../../../../core/services/rest/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { AssociatedRequestComponent } from '../associated-request/associated-request.component';
import { CreateObservationItemComponent } from '../create-observation-item/create-observation-item.component';
import { ViewObservationItemComponent } from '../view-observation-item/view-observation-item.component';
import * as moment from 'moment';

@Component({
  selector: 'app-procesar-solicitud',
  templateUrl: './procesar-solicitud.component.html',
  styleUrls: ['./procesar-solicitud.component.sass']
})
export class ProcesarSolicitudComponent implements OnInit {

  solicitudesForm: FormGroup;
  itemsSolciitud: any = [];
  archivosPorSolicitud: any;
  archivo: any;
  aprobada: boolean;
  archivosCargados: any[] = [];
  registrosPorCargar: any[] = new Array(3);
  sinAdjuntos: boolean = true;
  file: any;
  viewSolciitud = false;
  myFiles: string[] = [];
  filteredOptionsCompania: Observable<any[]>;
  compania: any;
  changedCotizationValues: any = {};
  edit: boolean = false;
  options = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    inputMode: CurrencyMaskInputMode.NATURAL
  };
  solicitud_asociada: any = [];
  validateAssociated: boolean = false;

  constructor(private solicitudService: SolicitudesService,
    private archivoService: ArchivosService,
    private destroyService: DestroyComponentService,
    private form: FormBuilder,
    private alertsService: AlertsService,
    private athService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProcesarSolicitudComponent>,
    public dialog: MatDialog,
    private companiaService: CompaniaService,
  ) { }

  ngOnInit(): void {
    this.archivosCargados = [];
    this.itemsSolciitud   = this.data.solicitud.request_items;
    this.itemsSolciitud = this.itemsSolciitud.map(item => {
      if (item.observations)  {
        const parseObservation = JSON.parse(item.observations);
        parseObservation.date_observations = moment(parseObservation.date_observations).format('DD/MM/YYYY - hh:mm a');
        item.observations = parseObservation;
      }
      return item;
    });
    this.data.solicitud.request_items = this.itemsSolciitud;
    this.solicitud_asociada = this.data.solicitud;
    this.solicitudService.validateAssociated(this.data.solicitud.id).subscribe(resp => {
      this.validateAssociated = resp.data
    })
    this.formControl();
    if (this.data.solicitud.request_state_id == '4' ||
      this.data.solicitud.request_state_id == '6'
    ) {

      this.aprobada = true;
    }
    if (this.data.type === 'editar' || this.data.type === 'ver') {
      this.solicitudesForm.controls.estado.setValue(parseInt(this.data.solicitud.request_state_id));


      if (this.data.type === 'editar')
        this.edit = true;


      this.solicitudesForm.controls.observacion.setValue(this.data.solicitud.request_observation ? (this.data.solicitud.request_observation.find(o => o.type == 'analista') || {})?.observation || '' : "");
      this.archivoService.getArchivosSolicitud(this.data.solicitud.id)
        .subscribe(resp => {
          resp.data.forEach((element, idx) => {
            this.registrosPorCargar[idx] = element;
            let control;
            let valueControl;
            if (idx == 0) {
              control = this.solicitudesForm.get("cot1_txt");
              valueControl = this.solicitudesForm.get("cot1_val_txt");
            }
            else if (idx == 1) {
              control = this.solicitudesForm.get("cot2_txt");
              valueControl = this.solicitudesForm.get("cot2_val_txt");

            }
            else if (idx == 2) {
              control = this.solicitudesForm.get("cot3_txt");
              valueControl = this.solicitudesForm.get("cot3_val_txt");
            }
            control.setValue(this.registrosPorCargar[idx].name);
            valueControl.setValue(this.registrosPorCargar[idx]?.value || '');
          });
          this.sinAdjuntos = this.checkFiles();

        });
    }


    this.companiaService.getCompany().subscribe(comp => {
      this.compania = comp.data;
      if (this.data.type === 'editar' || this.data.type === 'Procesar') {
        this.solicitudesForm.controls.companias.setValue(Number(this.data.solicitud.company_id));
      }

      this.filteredOptionsCompania = this.solicitudesForm.get('companias').valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(companias => companias ? this._filterCompany(companias) : this.compania.slice())
      );
    });

    if (this.data.type === 'ver') {
      this.viewSolciitud = true;
    }
  }

  private editasolicitud(estado) {
    this.uploadFiles(estado);
  }

  validate_latesObservation(data) {
    return data == undefined ? 'No hay informacion del usuario' : data;
  }


  saveSolicitud(solicitudesForm) {
    if (solicitudesForm.value.estado == 8) {
      Swal.fire({
        title: '¿Estás seguro?',
        text: "¿Deseas devolver la solicitud?, cambiará a estado devuelta, recuerda esto será definitivo y no se podrá deshacer.",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        icon: 'warning',
        confirmButtonText: 'Aceptar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.editasolicitud(null);
        }

      })
    }
    else {
      this.editasolicitud(null)
    }
  }

  sendSolicitud() {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¿Deseas enviar la solicitud?, Cambiará de estado a pendiente, recuerda que esto será definitivo y no se podrá deshacer",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      icon: 'warning',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      if (result.isConfirmed) {
        const estado = {
          state: 3,
          company: this.solicitudesForm.value.companias
        };

        if (this.registrosPorCargar.length > 0) {
          this.editasolicitud(estado);
          // this.dialogRef.close();
        } else {
          this.alertsService.alertError('¡Oops!', 'Debe añadir al menos una cotización');
        }
      }
    })
  }

  private modificarSolicitud(estado) {
    if (estado == null) {
      estado = {
        state: this.solicitudesForm.value.estado,
        company: this.solicitudesForm.value.companias
      }
    }
    const observacion = new FormData();
    observacion.append('observacion', this.solicitudesForm.value.observacion);
    observacion.append('idSolicitud', this.data.solicitud.id);
    observacion.append('type', "analista");
    observacion.append('items_observations', JSON.stringify(this.data.solicitud.request_items));
    this.solicitudService.updateSolicitudEstado(estado, this.data.solicitud.id  ).subscribe(resp => {
      this.solicitudService.saveObservacion(observacion)
        .subscribe(resp => {
          this.alertsService.alertWithAnyProperties('Guardado', 'La solicitud se ha guardado y está en proceso', 'success', 'Continuar').then(confirm => {
            if (confirm.isConfirmed) this.dialogRef.close();
          });
        });
    });
  }

  get error(): any { return this.solicitudesForm.controls; }

  /**
  * @author Jose Vicente Silva
  * @createdate 2021-01-27
  * Metodo donde se establecen las validaciones del formulario
  */
  formControl(): void {
    const stateId = parseInt(this.data.solicitud.request_state_id);

    this.solicitudesForm = new FormGroup({
      cotizacion: new FormControl(''),
      estado: new FormControl("", [Validators.required]),
      companias: new FormControl('', [this.autocompleteObjectValidator(), Validators.required]),
      observacion: new FormControl('', [Validators.required]),
      cot1_txt: new FormControl(''),
      cot2_txt: new FormControl(''),
      cot3_txt: new FormControl(''),
      cot1_val_txt: new FormControl('', [Validators.max(9999999999999)]),
      cot2_val_txt: new FormControl('', [Validators.max(9999999999999)]),
      cot3_val_txt: new FormControl('', [Validators.max(9999999999999)])
    });

  }

  cambioEstado(event) {
    if (event == 2) {
      this.aprobada = true;

    } else {
      this.aprobada = false;
    }
  }

  /*downloadFile(e: any) {
    const archivo = {
      path: e.path
    };
    console.log(e);

    this.archivoService.downloadOrder(archivo).subscribe( (data) => {
      const a = document.createElement('a');
      const file = new Blob([data], {type: e.fileContentType});
      a.href = URL.createObjectURL(file);
      a.download = e.name;
      a.click();
    });
  }*/

  /**
  * @author Juan Carlos Alonso
  * @createdate 2022-03-28
  * Descarga la cotización adjuntada
  * @param e Archivo u objeto recibido en el input type file
  */

  downloadFile(e: any) {
    if (e.path) {
      const archivo = {
        path: e.path
      };

      this.archivoService.downloadOrder(archivo).subscribe((data) => {
        const a = document.createElement('a');
        const file = new Blob([data], { type: e.fileContentType });
        a.href = URL.createObjectURL(file);
        a.download = e.name;
        a.click();
      });

    } else {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(e);
      a.download = e.name;
      a.click();
    }

  }


  getFileDetails(e) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.registrosPorCargar.push(e.target.files[i]);
    }
  }

  associated_request() {
    const dialogNewRol = this.dialog.open(AssociatedRequestComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'Procesar',
        title: 'Detalles de la solicitud (asociada)',
        solicitud: this.solicitud_asociada
      },
      panelClass: 'custom-dialog-container',
    });
    dialogNewRol.afterClosed().subscribe(() => {
      this.dialogRef.close();
    });
  }

  /**
   * Agrega un archivo al arreglo de archivos
   * @param e
   * @param index
   */
  setFile(e, index: number) {

    this.registrosPorCargar[index] = e.target.files[0];
    this.sinAdjuntos = this.checkFiles();
    let control;
    let valueControl;
    if (index == 0) {
      control = this.solicitudesForm.get("cot1_txt");
      valueControl = this.solicitudesForm.get("cot1_val_txt");
    }
    else if (index == 1) {
      control = this.solicitudesForm.get("cot2_txt");
      valueControl = this.solicitudesForm.get("cot2_val_txt");

    }
    else if (index == 2) {
      control = this.solicitudesForm.get("cot3_txt");
      valueControl = this.solicitudesForm.get("cot3_val_txt");
    }
    control.setValue(this.registrosPorCargar[index].name);
    valueControl.setValue();

  }

  setFileValue(e,index:number ){
    this.registrosPorCargar[index].value = e;
    if (this.registrosPorCargar[index].id)
      this.changedCotizationValues[this.registrosPorCargar[index].id] = e;
  }

  removeFileDetails(file, i) {
    if (file.id) {

      Swal.fire({
        title: '¿Estás seguro?',
        text: "¿Deseas eliminar esta cotización?, recuerda esto será definitivo y no se podrá deshacer.",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        icon: 'warning',
        confirmButtonText: 'Aceptar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.archivoService.eliminarArchivo(file.id).subscribe(resp => {
            this.registrosPorCargar[i] = null;
            this.sinAdjuntos = this.checkFiles();
            //this.registrosPorCargar.splice(i, 1);
          });

          this.removeFileInfo(i);
        }

      })

    } else {
      this.registrosPorCargar[i] = null;
      this.sinAdjuntos = this.checkFiles();
      //this.registrosPorCargar.splice(i, 1);

      this.removeFileInfo(i);
    }

  }

  removeFileInfo(i) {
    let control;
    if (i == 0) {
      control = this.solicitudesForm.get("cot1_txt");
    }
    else if (i == 1) {
      control = this.solicitudesForm.get("cot2_txt");

    }
    else if (i == 2) {
      control = this.solicitudesForm.get("cot3_txt");
    }
    control.setValue("");
  }

  uploadFiles(estado) {
    let formData = new FormData();
    formData.append('type', 'cotizacion');
    formData.append('idSolicitud', this.data.solicitud.id);
    for (let i = 0; i < this.registrosPorCargar.length; i++) {
      if (this.registrosPorCargar[i] && !this.registrosPorCargar[i].id) {
        formData.append(`archivoCarga${i}`, this.registrosPorCargar[i]);
        formData.append(`value${i}`, this.registrosPorCargar[i].value);
      } else if (this.registrosPorCargar[i] && this.changedCotizationValues[this.registrosPorCargar[i]?.id]) {
        this.archivoService.updateArchivosSolicitud({ value: this.changedCotizationValues[this.registrosPorCargar[i]?.id] }, this.registrosPorCargar[i]?.id).subscribe();
      }
    }
    this.archivoService.postCargarArchivos(formData).subscribe((resp) => {
      resp.id == 200 ? this.modificarSolicitud(estado) : null;
    });
  }

  handleFileInput(event) {
    this.file = event.target.files[0];
    const formData = new FormData();
    formData.append('archivoCarga', this.file);
    formData.append('type', 'cotizacion')
    this.archivoService.postCargarArchivos(formData).subscribe((resp) => {
      const data = {
        id: 0,
        path: resp.data.path,
        name: resp.data.name,
        content_type: resp.data.contentType
      };
      this.archivosCargados.push(data);
    });
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }


  editSolicitud() {
    this.dialog.open(AprobarSolcitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Detalles solicitud',
        solicitud: this.data.solicitud
      },
      panelClass: 'custom-dialog-container',
    });
    this.dialogRef.close();
  }


  /**
   * Verifica si hay cotizaciones cargadas
   * @returns
   */
  private checkFiles(): boolean {
    let isEmpty = true;

    this.registrosPorCargar.forEach(element => {
      if (element !== null) {
        isEmpty = false;
      }
    });
    return isEmpty;
  }


  /**
  * @author Jose Vicente Silva
  * @createdate 2021-08-27
  * filtra por nombre, se usa para el autocompletable
  * @param value valor a filtrar
  */
  private _filterCompany(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.compania.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }


  displayFnCompania(id: number): string {
    if (!id) { return ''; }
    let index = this.compania.findIndex(resp => resp.id === id);
    return this.compania[index].name;

  }


  /**
  * @author Jose Vicente Silva
  * @createdate 2021-08-27
  * Metodo que valida las funciones del autocompetable
  */
  autocompleteObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string') {
        return { invalidAutocompleteObject: { value: control.value } };
      }
      return null;
    };
  }

  /**
   * @author Fabian Duran
   * @createdate 2023-04-17
   * Abre la modal para crear la observación sobre un item
  */
  openCreateObservation(indexItem): void {
    const modalCreateObservation = this.dialog.open(CreateObservationItemComponent, {
      data: {
        indexItem: indexItem
      },
      width: '60%',
      autoFocus: false
    });
    modalCreateObservation.afterClosed().subscribe(res => {
      const itemsRequest = this.data.solicitud.request_items;
      const setItemsRequest = itemsRequest.map(item => {
        if (item.id === res.indexItem) item.observations = res.observation;
        return item;
      });
      this.data.solicitud.request_items = setItemsRequest;
    });
  }
  /**
   * @author Fabian Duran
   * @createdate 2023-04-17
   * Abre la modal de la vista de observaciones de los items.
  */
  viewObservation(observation, indexItem): void {
    const modalViewObservation = this.dialog.open(ViewObservationItemComponent, {
      data: {
        observation: observation,
        indexItem: indexItem
      },
      width: '50%'
    });
    modalViewObservation.afterClosed().subscribe(res => {
      if (typeof res !== 'string' && res !== undefined) {
        const itemsRequest = this.data.solicitud.request_items;
        const setItemsRequest = itemsRequest.map(item => {
          if (item.id === res.resUpdate.indexItem) item.observations = res.resUpdate.observation;
          return item;
        });
        this.data.solicitud.request_items = setItemsRequest;
      }
    });
  }
}
