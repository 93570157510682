import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { AuditoriaService } from 'src/app/modules/calidad/services/auditoria.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import { CoachingService } from 'src/app/modules/coaching/services/coaching.service';
import {TranslatesService} from '../../../../../../services/translates.service';

@Component({
  selector: 'app-detalle-retroalimentacion',
  templateUrl: './detalle-retroalimentacion.component.html',
  styleUrls: ['./detalle-retroalimentacion.component.sass']
})
export class DetalleRetroalimentacionComponent implements OnInit {
  private uuid = '6111a21c-74b7-42dd-aac4-992da45dc867';
  public config: any;

  dataSource = [];
  displayedColumns: string[] = ['matriz', 'fecha_auditoria', 'tipo_auditoria', 'calificacion'];
  tipo_matriz = '';
  tipo_peso = '';
  idMatriz;
  calificacionesForm: FormGroup;
  roles = [];
  is_asesor = false;
  analista = false;
  is_jefe = false;
  is_coaching = false;
  supervisor = false;
  verBtns = true;
  aplica_para_cp: number = null;
  commentApply= false;

  constructor(
    private auditoriaService: AuditoriaService,
    private fb: FormBuilder,
    public retroalimentacionRef: MatDialogRef<DetalleRetroalimentacionComponent>,
    @Inject(MAT_DIALOG_DATA) public datos: any,
    private authService: AuthService,
    private router: Router,
    private coachService: CoachingService,
    private configService: TranslatesService,
  ) {
    this.roles = authService.getUser().roles;
  }
  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
    this.createForm();
    this.getRetroalimentacionById();
  }

  createForm() {
    this.calificacionesForm = this.fb.group({
      modulos: this.fb.array([]),
      txtAuditoria: [{value: '', disabled: true}],
      retroalimentacion_auditor: [{value: '', disabled: true}, [Validators.required]],
      retroalimentacion_supervisor: [{value: '', disabled: true}, [Validators.required]],
      compromiso_calidad: [{value: '', disabled: true}],
      compromiso_operacion: [{value: '', disabled: true}],
    });
    this.roles.forEach(rol => {
      if (rol == 'calidad::analista') {
        this.analista = true;
      }
      if (rol == 'calidad::supervisor') {
        this.supervisor = true;
      }
      if (rol == 'calidad::asesor') {
        this.is_asesor = true;
        const index = this.displayedColumns.indexOf('tipo_auditoria');
        this.displayedColumns.splice(index, 1);
      }
      if (rol == 'calidad::jefe') {
        this.is_jefe = true;
      }

      if (rol == 'coaching::asesor') {
        this.is_coaching = false;
      } else {
        this.is_coaching = true;
      }

    });
    if ((this.analista && this.supervisor)) {
      this.calificacionesForm.get('retroalimentacion_auditor').enable();
      this.calificacionesForm.get('retroalimentacion_supervisor').enable();
    } else if (this.analista) {
      this.calificacionesForm.get('retroalimentacion_auditor').enable();
      this.calificacionesForm.get('retroalimentacion_supervisor').setErrors(null);
    } else if (this.supervisor) {
      this.calificacionesForm.get('retroalimentacion_supervisor').enable();
      this.calificacionesForm.get('retroalimentacion_auditor').setErrors(null);
    } else {
      this.calificacionesForm.get('retroalimentacion_auditor').disable();
      this.calificacionesForm.get('retroalimentacion_supervisor').disable();
      this.calificacionesForm.get('retroalimentacion_auditor').setErrors(null);
      this.calificacionesForm.get('retroalimentacion_supervisor').setErrors(null);
    }
    if (this.is_jefe) {
      this.verBtns = false;
    }
  }

  getRetroalimentacionById() {
    const modulosForm = <FormArray>this.calificacionesForm.controls['modulos'];
    modulosForm.clear();
    this.auditoriaService.getRetroalimentacionById(this.datos).subscribe((resp: any) => {
      const datos = [];
      this.idMatriz = resp[0].auditoria.detalle_matriz.id;
      this.tipo_matriz = resp[0].auditoria.detalle_matriz.tipo_matriz.nombre;
      this.aplica_para_cp = resp[0].auditoria.detalle_matriz.aplica_para_cp;
      this.commentApply = resp[0].auditoria.detalle_matriz.comment_apply;
      if (resp[0].auditoria.detalle_matriz.tipo_peso) {
        this.tipo_peso = resp[0].auditoria.detalle_matriz.tipo_peso.nombre;
      }
      datos.push({
        nombre_matriz: resp[0].auditoria.detalle_matriz.nombre,
        fecha_auditoria: resp[0].auditoria.fecha_llamada,
        tipo_audioria: resp[0].auditoria.tipo_audioria.nombre,
        calificacion: resp[0].auditoria.total_punaje
      });
      this.dataSource = datos;
      resp[0].auditoria.items_afectados.forEach((modulo, iModule) => {
        modulosForm.push(this.fb.group({
          calificacion: [{value: 1, disabled: true}],
          created_at: modulo.created_at,
          cumple: modulo.cumple,
          deleted_at: modulo.deleted_at,
          detalle_item: modulo.detalle_item,
          id: modulo.id,
          items: this.fb.array([]),
          matriz_auditoria_id: modulo.matriz_auditoria_id,
          modulo_id: modulo.modulo_id,
          nivel: modulo.nivel,
          no: modulo.no,
          no_aplica: modulo.no_aplica,
          no_cumple: modulo.no_cumple,
          padre_id: modulo.padre_id,
          puntaje: modulo.puntaje,
          puntaje_obtenido: modulo.puntaje_obtenido,
          si: modulo.si,
          tipo_matriz_id: modulo.tipo_matriz_id,
          updated_at: modulo.updated_at,
          cp: modulo.cumple_parcial,
          error_critico: modulo.detalle_item.error_critico,
          
        }));
        modulo.items.forEach((item) => {
          item.subitems.forEach(subItem => {
            Object.assign(subItem, {calificacion: [{value: 1, disabled: true}],
                                    comments: [{value: subItem.comments, disabled: true}]});
          });
          (this.modulesForm.at(iModule).get('items') as FormArray).push(this.fb.group({
            calificacion: [{value: 1, disabled: true}],
            created_at: item.created_at,
            cumple: item.cumple,
            deleted_at: item.deleted_at,
            detalle_item: item.detalle_item,
            id: item.id,
            matriz_auditoria_id: item.matriz_auditoria_id,
            modulo_id: item.modulo_id,
            nivel: item.nivel,
            no: item.no,
            no_aplica: item.no_aplica,
            no_cumple: item.no_cumple,
            padre_id: item.padre_id,
            puntaje: item.puntaje,
            puntaje_obtenido: item.puntaje_obtenido,
            si: item.si,
            comments: [{value: item.comments, disabled: true}],
            subitems: this.fb.array(item.subitems.map(subItem => this.fb.group(subItem))),
            tipo_matriz_id: item.tipo_matriz_id,
            updated_at: item.updated_at,
            cp: item.cumple_parcial,
            error_critico: modulo.detalle_item.error_critico
          }));
        });
      });
      this.calificacionesForm.get('txtAuditoria').setValue(resp[0].auditoria.observacion_auditor);
      this.calificacionesForm.get('retroalimentacion_auditor').setValue(resp[0].auditoria.retroalimentacion_auditor);
      this.calificacionesForm.get('retroalimentacion_supervisor').setValue(resp[0].auditoria.retroalimentacion_supervisor);
      this.calificacionesForm.get('compromiso_calidad').setValue(resp[0].auditoria.compromiso_calidad);
      this.calificacionesForm.get('compromiso_operacion').setValue(resp[0].auditoria.compromiso);

      if (this.is_asesor && resp[0].auditoria.retroalimentacion_auditor) {
        this.calificacionesForm.get('compromiso_calidad').enable();
      }
      if (this.is_asesor && resp[0].auditoria.retroalimentacion_supervisor) {
        this.calificacionesForm.get('compromiso_operacion').enable();
      }
      if (this.analista && resp[0].auditoria.retroalimentacion_auditor) {
        this.calificacionesForm.get('retroalimentacion_auditor').disable();
        this.verBtns = false;
      }
      if (this.supervisor && resp[0].auditoria.retroalimentacion_supervisor) {
        this.calificacionesForm.get('retroalimentacion_supervisor').disable();
        this.verBtns = false;
      }

      if (resp[0].auditoria.compromiso_calidad && resp[0].auditoria.compromiso) {
        this.verBtns = false;
        this.calificacionesForm.get('compromiso_calidad').disable();
        this.calificacionesForm.get('compromiso_operacion').disable();
      }
      if (resp[0].auditoria.compromiso_calidad) {
        this.calificacionesForm.get('compromiso_calidad').disable();
      }
      if (resp[0].auditoria.compromiso) {
        this.calificacionesForm.get('compromiso_operacion').disable();
      }
    });
  }

  get modulesForm() {
    return this.calificacionesForm.get('modulos') as FormArray;
  }

  updateRetroalimentacionById() {
    this.calificacionesForm.markAllAsTouched();
    if (this.calificacionesForm.invalid) {
      return;
    }
    Object.assign(this.calificacionesForm.value, {
      mensaje: `Tienes una nueva retroalimentación en la auditoría ID ${this.datos}.`,
      ruta: '/mios/calidad/mis-auditorias',
    });
    this.auditoriaService.updateRetroalimentacionById(this.datos, this.calificacionesForm.value).subscribe((resp: any) => {
      if (resp.respuesta.codigo == 202) {
        Tools.swalSuccess(this.config.modal.excellent, this.config.modal.responseOk);
        this.retroalimentacionRef.close();
      } else if (resp.respuesta.codigo != 202) {
        Tools.swalError('¡Oops!', this.config.modal.responseError);
      }
    });
  }

  updateAuditoria() {
    let mensaje = '';
    if (this.calificacionesForm.get('compromiso_calidad').touched && this.calificacionesForm.get('compromiso_operacion').touched) {
      mensaje = this.config.modal.message1 + `${this.datos}.`;
    } else if (this.calificacionesForm.get('compromiso_calidad').touched) {
      mensaje = this.config.modal.message2 + `${this.datos}.`;
    } else if (this.calificacionesForm.get('compromiso_operacion').touched) {
      mensaje = this.config.modal.message3 + `${this.datos}.`;
    }
    this.calificacionesForm.get('compromiso_calidad').enable();
    this.calificacionesForm.get('compromiso_operacion').enable();
    Object.assign(this.calificacionesForm.value, {
      mensaje,
      ruta: '/mios/calidad/auditoria/detail/1/' + this.idMatriz,
    });
    this.auditoriaService.updateAuditoria(this.datos, this.calificacionesForm.value).subscribe((resp: any) => {
      if (resp.respuesta.codigo == 202) {
        Tools.swalSuccess(this.config.modal.excellent, this.config.modal.responseOk);
        this.retroalimentacionRef.close();
      } else if (resp.respuesta.codigo != 202) {
        if (this.calificacionesForm.get('compromiso_calidad').touched && this.calificacionesForm.get('compromiso_operacion').touched) {
          this.calificacionesForm.get('compromiso_calidad').disable();
          this.calificacionesForm.get('compromiso_operacion').disable();
        } else if (this.calificacionesForm.get('compromiso_calidad').touched) {
          this.calificacionesForm.get('compromiso_calidad').disable();
        } else if (this.calificacionesForm.get('compromiso_operacion').touched) {
          this.calificacionesForm.get('compromiso_operacion').disable();
        }
        Tools.swalError('¡Oops!', this.config.modal.responseError);
      }
    });
  }

  nuevoCoaching() {
    this.coachService.coachCalidad(this.datos).subscribe((resp: any) => {
      if (resp.respuesta.status) {
        if (resp.respuesta.estado === 'Abierto' || resp.respuesta.estado === 'Vencido') {
          window.location.replace('/mios/coaching/solicitud/' + resp.respuesta.id);
        } else {
          window.location.replace('/mios/coaching/finalizado/' + resp.respuesta.id);
        }
      } else {
        window.location.replace('/mios/coaching/auditoria/' + this.datos);
      }
    });
  }

}
