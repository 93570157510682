import { createAction, props } from "@ngrx/store";
import { AcompañamientoModel } from "../../core/interfaces/acompañamiento.interface";
import { FiltroModel } from "../../core/interfaces/filtros.interface";

/**
 * @author Cristian Gallo
 * @createdate 2021-12-10
 * Action que cargara la información para la tabla.
*/

export const loadAcompañamientos = createAction(
    '[Acompañamiento] Load acompañamientos',
    props<{
        parametro?:string,
        fechaInicial?:string,
        fechaFinal?:string,
        indicador?:string,
        numeroReg?:number,
        estado?:string,
        tab?:number,
        page?:number,
    }>()
);

export const loadedAcompañamientos = createAction(
    '[Acompañamientos] Loaded acompañamientos',
    props<{ acompañamientos:AcompañamientoModel }>()
);