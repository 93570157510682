<div [formGroup]="form">
    <ng-container *ngIf="this.form.controls[this.question.key]">
        <mat-form-field appearance="fill" style="width: 100%;" *ngIf="question.type != 'others'; else other_field">
            <mat-label [attr.for]="question.key">{{question.label}}</mat-label>
            <span *ngIf="question.controlType =='currency'" matPrefix>$ &nbsp;</span>
            <div [ngSwitch]="question.controlType">
                <div *ngSwitchCase="'textbox'">
                    <input [type]="question.type" step="1" matInput [formControlName]="question.key" (ngModelChange)="editedAdviser($event)" autocomplete="off" [required]="question.required">
                </div>
                <div *ngSwitchCase="'currency'">
                    <input type="text" matInput [formControlName]="question.key" autocomplete="off" (change)="editedAdviser($event)" [required]="question.required" appNumber>
                </div>
                <div *ngSwitchCase="'dropdown'">
                    <mat-select [formControlName]="question.key" [required]="question.required" (selectionChange)="editedAdviser($event.value)" (ngModelChange)="optionSelected($event, question)">
                        <mat-option *ngFor="let option of question.options" [value]="option.id">
                            {{option.name}}
                        </mat-option>
                    </mat-select>
                </div>
                <div *ngSwitchCase="'dropdown-request'">
                    <mat-select [formControlName]="question.key" [id]="question.key" (selectionChange)="editedAdviser($event.value)" [required]="question.required">
                        <mat-option *ngFor="let option of  question.options" [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-select>
                </div>
                <div *ngSwitchCase="'dropdown2'">
                    <mat-select [formControlName]="question.key" (selectionChange)="editedAdviser($event.value)" [required]="question.required">
                        <mat-option *ngFor="let option of question.options" [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-select>
                </div>
                <div *ngSwitchCase="'autocomplete2'">
                    <input matInput [matAutocomplete]="auto" [formControlName]="question.key" autocomplete="off" [required]="question.required" placeholder="Buscar {{question.label}}">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="editedAdviser($event)" [displayWith]="displayFn2.bind(this)">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            <span>{{option.name}}</span>
                        </mat-option>
                    </mat-autocomplete>
                </div>
                <div *ngSwitchCase="'textarea'">
                    <textarea cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="3" [required]="question.required" matInput [formControlName]="question.key" (ngModelChange)="editedAdviser($event)" [id]="question.key" [name]="question.key">
                </textarea>
                </div>
                <ng-container *ngSwitchCase="'datepicker'">
                    <input matInput [matDatepicker]="question.type === 'date' ? picker : null" autocomplete="off" [formControlName]="question.key" (dateChange)="editedAdviser($event.value)" [min]="question.minLength" [max]="question.maxLength" [required]="question.required">
                </ng-container>
    
                <div *ngSwitchCase="'textbox_ag'">
    
                  <input
                      matInput
                      autocomplete      = "off"
                      [formControlName] = "question.key"
                      [min]             = "question.minLength"
                      [max]             = "question.maxLength"
                      [required]        = "question.required"
    
                      >
    
                </div>
                <div *ngSwitchCase="'autocomplete'">
                    <input matInput [matAutocomplete]="auto" [formControlName]="question.key" autocomplete="off" [required]="question.required" placeholder="Seleccione una opción válida">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)" (optionSelected)="optionSelected($event.option.value, question)">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                            <span>{{option.name}}</span>
                        </mat-option>
                    </mat-autocomplete>
                </div>
                <div *ngSwitchCase="'datetime'">
    
                </div>
                <div *ngSwitchCase="'multiselect'">
                    <mat-select [formControlName]="question.key" multiple (selectionChange)="editedAdviser($event.value)" [required]="question.required">
                        <mat-option *ngFor="let option of question.options" [value]="option.id">
                            {{option.name}}
                        </mat-option>
                    </mat-select>
                </div>
    
            </div>
    
            <mat-icon
                matSuffix
                mat-icon-button
                *ngIf   = "question.type === 'agendamiento'"
                [for]   = "picker_agenda"
                (click) = "openAgenda()"
                >calendar_today</mat-icon>
    
            <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="question.type === 'date'"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
                <span *ngIf="question.tooltip?.have" matTooltip="{{question.tooltip?.content}}" class="fi-rr-interrogation" matSuffix> </span>
                <button class="add-button" mat-button matSuffix mat-icon-button aria-label="Agregar" type="button" *ngIf="question.canAdd" (click)="addQuestion(question)">
                    <span class="fi-rr-add"></span>
                </button>
    
            <mat-error>{{getErrorMessage()}}</mat-error>
    
    
        </mat-form-field>
        <ng-template #other_field>
    
            <div [ngSwitch]="question.controlType">
                <div *ngSwitchCase="'radiobutton'" >
                    <p>{{question.label}}</p>
                    <section fxLayout="row" fxLayoutAlign="start center">
                        <mat-radio-group [formControlName]="question.key" (ngModelChange)="optionSelected($event, question)">
                            <mat-radio-button class="radio-button" *ngFor="let option of question.options" [value]="option.id">
                                <span> {{option.name}}</span>
                            </mat-radio-button>
                        </mat-radio-group>
                    </section>
                </div>
                <div  *ngSwitchCase="'file'">
                        <div class="file-input">
                            <button type="button" style="padding: 0 8px;" [disabled]="form.controls[question.key].disabled" mat-stroked-button color="primary" (click)="fileInput.click()">Cargar archivo <sup *ngIf="question.required">*</sup><i class="fi-rr-download"></i></button>
                            <button class="add-button" mat-button matSuffix mat-icon-button aria-label="Agregar" type="button" *ngIf="question.canAdd" (click)="addQuestion(question)">
                                <span class="fi-rr-add"></span>
                            </button>
                            <span class="tooltip-file" *ngIf="question.tooltip?.have" matTooltip="{{question.tooltip?.content}}" class="fi-rr-interrogation" matSuffix> </span>
                        </div>
                        <span *ngIf="question.label" [matTooltip]="question.label">
                            <ng-container *ngIf="question.label.length < 24">
                                {{question.label}}
                            </ng-container>
                            <ng-container *ngIf="question.label.length >= 24">
                                {{question.label | slice:0:23}}...
                            </ng-container>
                        </span><br>
                        <p *ngIf="question.nameFile" class="download" [matTooltip]="question.nameFile" (click)="downloadFile(question)">
                            <ng-container *ngIf="question.nameFile.length < 17">
                                {{question.nameFile}}
                            </ng-container>
                            <ng-container *ngIf="question.nameFile.length >= 17">
                                {{question.nameFile | slice:0:16}}...
                            </ng-container>
                            <i *ngIf="question.nameFile" class="fi-rr-download"></i> </p>
                        <input hidden (change)="onFileSelected($event, question.id)" #fileInput type="file" [formControlName]="question.key">
    
                </div>
            </div>
    
        </ng-template>
    </ng-container>
    
</div>
