<div class="avatar row  admin-dialog me-5 ms-5">
  <!-- <form class="row form " [formGroup]="solicitudesForm"  (ngSubmit)="saveSolicitud(solicitudesForm)"> -->
  <div class="w-100 d-flex justify-content-end">
    <button style="float: right" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
  </div>


  <mat-dialog-content class="mat-typography pl-5">
    <div class="row">
      <div class="col-9">
        <h2 mat-dialog-title style="margin-left: 24px;" align="left" >
          {{data.title}} <span class="text-title-info">OC-{{data.ordenCompra.purchase_order_number}}{{ data.frag ?  '-' + data.frag.purchase_order_number : '' }}</span>
        </h2>


      </div>
      <div class="col-3">
        <button  class="btn_dowload_cotiza" mat-button (click)="downloadCotizacion(data.ordenCompra.request.id)" >Cotización &nbsp;  <i class="fi-rr-download"></i></button>
      </div>

    </div>

      <hr>



    <mat-horizontal-stepper #stepper (selectionChange)="onStepChange($event)">
      <mat-step [editable]="isEditable" labelPosition="bottom">

        <ng-template matStepLabel>Datos generales</ng-template>
        <div class="row mt-3">
          <div class="col-12 subtitle">
            Datos de la empresa
            <hr class="mt-1">
          </div>
        </div>
        <div class="row mt-3">

          <div class="col-4">
            <mat-label class="fw-bold">Empresa</mat-label><br>
            {{data.ordenCompra.company.name}}
          </div>
          <div class="col-4">
            <mat-label class="fw-bold">Nit</mat-label><br>
            {{data.ordenCompra.company.nit}}
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12 subtitle">
            Documento controlado
            <hr class="mt-1">
          </div>
        </div>

        <div class="row mt-3">


          <div class="col-4">
            <mat-label class="fw-bold">Fecha emisión</mat-label><br>
            {{data.ordenCompra.request.created_at | date:'yyyy-MM-dd': 'UTC'}}
          </div>
          <div class="col-4">
            <mat-label class="fw-bold">Tipo de adquisición</mat-label><br>
            {{ data.ordenCompra.product_type.name }}
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12 subtitle">
            Datos del proveedor
            <hr class="mt-1">
          </div>
        </div>
        <div class="row mt-3">

          <div class="col-4">
            <mat-label class="fw-bold">Razón social</mat-label><br>
            {{data.ordenCompra.provider.razon_social}}
          </div>
          <div class="col-4">
            <mat-label class="fw-bold">Nit</mat-label><br>
            {{data.ordenCompra.provider.nit}}
          </div>
          <div class="col-4">
            <mat-label class="fw-bold">Dirección</mat-label><br>
            {{data.ordenCompra.provider.direccion}}
          </div>
        </div>
        <div class="row" *ngIf="proveedor">
          <div class="col-4">
            <mat-label class="fw-bold">Celular</mat-label><br>
            {{proveedor.user.celular}}
          </div>
          <div class="col-4">
            <mat-label class="fw-bold">Teléfono</mat-label><br>
            {{proveedor.telefono_proveedor}}
          </div>
          <div class="col-4">
            <mat-label class="fw-bold">E-mail</mat-label><br>
            {{proveedor.user.correo}}
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 subtitle">
            Datos del solicitante
            <hr class="mt-1">
          </div>
        </div>
        <div class="row mt-3">

          <div class="col-4">
            <mat-label class="fw-bold">Nombre del solicitante</mat-label><br>
            {{data.ordenCompra.created_by}}
          </div>
        </div>


        <div style="float: right;" *ngIf="this.fragmentar">
          <button class="bold compras-modal-button" mat-raised-button color="primary" type="button" matStepperNext>Siguiente</button>
        </div>

      </mat-step>
      <mat-step [stepControl]="firstFormGroup" [editable]="isEditable">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Detalle compra</ng-template>
          <!--<div class="row col-12">
            <div class="row mt-3">
              <div class="col-12">
                <h3>Cotización de compra</h3>
                <hr class="mt-1">
              </div>
            </div>
            <div class="col-12">
              <div class="row col-12">
                <mat-form-field style="padding: 1%;" class="col-12" appearance="fill">
                  <mat-label>Cotización</mat-label>
                  <input type="text" readonly="true" formControlName="data" matInput />
                  <i style="cursor: pointer;" *ngIf="archivoSolicitud != undefined" (click)="downloadFile()" matSuffix
                    class="fi fi-rr-download"></i>
                </mat-form-field>
              </div>
            </div>
          </div>-->

          <div formArrayName="items" class="row col-12">
            <h3>
              Por los siguientes ítems

            </h3>

            <div class="col-12" *ngFor="let applet of items.controls; let i = index">
              <strong>ítem {{i+1}}</strong>
              <div class="row col-12" [formGroupName]="i">

                <mat-form-field style="padding: 1%;" class="col-12" appearance="fill">
                  <mat-label>Descripción</mat-label>
                  <input style="text-transform: none;" matInput type="text" placeholder="Escribe el detalle"
                    formControlName="description" readOnly>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.description.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.description.errors.required">¡Advertencia! Este campo
                      es requerido</span></mat-error>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.description.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.description.errors.minlength">¡Aún falta! Recibimos
                      mínimo 3 caracteres</span></mat-error>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.description.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.description.errors.maxlength">¡Cuidado! Solo puedes
                      tener un máximo de 255 caracteres</span></mat-error>
                </mat-form-field>

                <mat-form-field style="padding: 1%;" class="col-12" appearance="fill">
                  <mat-label>Especificación</mat-label>
                  <input style="text-transform: none;" matInput type="text" placeholder="Escribe las especificaciones"
                    formControlName="specs" readOnly>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.specs.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.specs.errors.required">¡Advertencia! Este campo es
                      requerido</span></mat-error>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.specs.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.specs.errors.minlength">¡Aún falta! Recibimos mínimo 3
                      caracteres</span></mat-error>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.specs.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.specs.errors.maxlength">¡Cuidado! Solo puedes tener un
                      máximo de 255 caracteres</span></mat-error>
                </mat-form-field>

                <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                  <mat-label>Cantidad</mat-label>
                  <input style="text-transform: none;" matInput type="number" placeholder="Escribe la cantidad"
                    formControlName="unit" readonly>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.unit.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.unit.errors.required">¡Advertencia! Este campo es
                      requerido</span></mat-error>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.unit.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.unit.errors.minlength">¡Aún falta! Recibimos mínimo 3
                      caracteres</span></mat-error>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.unit.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.unit.errors.maxlength">¡Cuidado! Solo puedes tener un
                      máximo de 3 caracteres</span></mat-error>
                </mat-form-field>
                <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                  <mat-label>Tipo de moneda</mat-label>
                  <mat-select formControlName="coin_type" readonly>
                    <mat-option *ngFor="let typeCoin of ['COP','USD','MXN']" [value]="typeCoin"> {{typeCoin}} </mat-option>
                  </mat-select>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.coin_type.errors"><span *ngIf="errorItem.items.controls[i].controls.coin_type.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                </mat-form-field>
                <mat-form-field style="padding: 1%;" class="col-3" appearance="fill" *ngIf="fragmentar">
                  <mat-label>Pendiente</mat-label>
                  <input style="text-transform: none;" matInput type="number" placeholder="Pendiente"
                    formControlName="pendiente" readonly>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.pendiente.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.pendiente.errors.required">¡Advertencia! Este campo es
                      requerido</span></mat-error>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.pendiente.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.pendiente.errors.minlength">¡Aún falta! Recibimos
                      mínimo 3 caracteres</span></mat-error>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.pendiente.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.pendiente.errors.maxlength">¡Cuidado! Solo puedes
                      tener un máximo de 3 caracteres</span></mat-error>
                </mat-form-field>
                <mat-form-field style="padding: 1%;" class="col-3" appearance="fill" *ngIf="fragmentar">
                  <mat-label>Nueva Cantidad</mat-label>
                  <input style="text-transform: none;" matInput type="number" placeholder="Nueva cantidad"
                    formControlName="newUnit" (blur)="calcularValorTotal(i)">
                  <mat-error *ngIf="errorItem.items.controls[i].controls.newUnit.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.newUnit.errors.required">¡Advertencia! Este campo es
                      requerido</span></mat-error>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.newUnit.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.newUnit.errors.minlength">¡Aún falta! Recibimos mínimo
                      3 caracteres</span></mat-error>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.newUnit.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.newUnit.errors.maxlength">¡Cuidado! Solo puedes tener
                      un máximo de 3 caracteres</span></mat-error>
                </mat-form-field>
                <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                  <mat-label>Valor unitario</mat-label>
                  <input style="text-transform: none;" matInput currencyMask [options]="options" placeholder="Valor unitario"
                    formControlName="unit_value" readonly>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.unit_value.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.unit_value.errors.required">¡Advertencia! Este campo
                      es requerido</span></mat-error>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.unit_value.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.unit_value.errors.minlength">¡Aún falta! Recibimos
                      mínimo 3 caracteres</span></mat-error>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.unit_value.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.unit_value.errors.maxlength">¡Cuidado! Solo puedes
                      tener un máximo de 3 caracteres</span></mat-error>
                </mat-form-field>

                <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                  <mat-label>Otro IVA</mat-label>
                  <input style="text-transform: none;" matInput type="number" placeholder="Escribe el IVA"
                    formControlName="valorIva" readonly>
                </mat-form-field>
                <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                  <mat-label>Valor total</mat-label>
                  <input style="text-transform: none;" matInput currencyMask [options]="options" placeholder="Valor total"
                    formControlName="total_value" readonly>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.total_value.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.total_value.errors.required">¡Advertencia! Este campo
                      es requerido</span></mat-error>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.total_value.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.total_value.errors.minlength">¡Aún falta! Recibimos
                      mínimo 3 caracteres</span></mat-error>
                  <mat-error *ngIf="errorItem.items.controls[i].controls.total_value.errors"><span
                      *ngIf="errorItem.items.controls[i].controls.total_value.errors.maxlength">¡Cuidado! Solo puedes
                      tener un máximo de 3 caracteres</span></mat-error>
                </mat-form-field>

                <input type="hidden" formControlName="id">

                <div class="row" *ngFor="let item of filesUpload; let j = index;">
                  <div style="vertical-align: bottom;" class="col-3 ">
                    {{item.name}}
                  </div>
                  <div class="col-3 ">
                    <button mat-icon-button class="action " (click)="EliminarArchivoLista(j) ">
                      <i class="fi-rr-trash" style="position:initial"></i>
                    </button>
                  </div>
                </div>
              </div>



              <br />
              <br />
            </div>
          </div>
          <div style="float: right;" *ngIf="this.fragmentar">
            <button class="bold compras-modal-button" mat-button mat-stroked-button matStepperPrevious>Anterior</button>
            <button class="bold compras-modal-button ms-2" mat-raised-button color="primary" type="button" disabled='{{firstFormGroup.invalid}}'
              matStepperNext>Siguiente</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup" [editable]="isEditable">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>Finalizar orden</ng-template>
          <h3>Condiciones comerciales</h3>
          <div>

            <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
              <mat-label>Sub-total</mat-label>
              <input matInput currencyMask [options]="options" placeholder="Escribe el nombre de la aplicación" formControlName="sub_total"
                readonly>
              <mat-error *ngIf="error.sub_total.errors"><span *ngIf="error.sub_total.errors.required">¡Advertencia! Este
                  campo es requerido</span></mat-error>
              <mat-error *ngIf="error.sub_total.errors"><span *ngIf="error.sub_total.errors.minlength">¡Aún falta!
                  Recibimos mínimo 3 caracteres</span></mat-error>
              <mat-error *ngIf="error.sub_total.errors"><span *ngIf="error.sub_total.errors.maxlength">¡Cuidado! Solo
                  puedes tener un máximo de 40 caracteres</span></mat-error>
            </mat-form-field>



            <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
              <mat-label>Descuento</mat-label>
              <input style="text-transform: none;" matInput currencyMask [options]="options" placeholder="Escribe el descuento"
                formControlName="discount" (keydown.enter)="calculardiscount()" (blur)="calculardiscount()" required>
              <mat-error *ngIf="error.discount.errors"><span *ngIf="error.discount.errors.required">¡Advertencia! Este
                  campo es requerido</span></mat-error>
              <mat-error *ngIf="error.discount.errors"><span *ngIf="error.discount.errors.minlength">¡Aún falta!
                  Recibimos mínimo 3 caracteres</span></mat-error>
              <mat-error *ngIf="error.discount.errors"><span *ngIf="error.discount.errors.maxlength">¡Cuidado! Solo
                  puedes tener un máximo de 3 caracteres</span></mat-error>
            </mat-form-field>

            <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
              <mat-label>Total IVA</mat-label>
              <input matInput currencyMask [options]="options" placeholder="Escribe el nombre de la aplicación" formControlName="iva"
                (keydown.enter)="calculardiscount()" (blur)="calculardiscount()" required>
              <mat-error *ngIf="error.iva.errors"><span *ngIf="error.iva.errors.required">¡Advertencia! Este campo es
                  requerido</span></mat-error>
              <mat-error *ngIf="error.iva.errors"><span *ngIf="error.iva.errors.minlength">¡Aún falta! Recibimos mínimo
                  3 caracteres</span></mat-error>
              <mat-error *ngIf="error.iva.errors"><span *ngIf="error.iva.errors.maxlength">¡Cuidado! Solo puedes tener
                  un máximo de 40 caracteres</span></mat-error>
            </mat-form-field>



            <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
              <mat-label>Total</mat-label>
              <input style="text-transform: none;" matInput currencyMask [options]="options" placeholder="Total" formControlName="total"
                readonly>
              <mat-error *ngIf="error.total.errors"><span *ngIf="error.total.errors.required">¡Advertencia! Este campo
                  es requerido</span></mat-error>
              <mat-error *ngIf="error.total.errors"><span *ngIf="error.total.errors.minlength">¡Aún falta! Recibimos
                  mínimo 3 caracteres</span></mat-error>
              <mat-error *ngIf="error.total.errors"><span *ngIf="error.total.errors.maxlength">¡Cuidado! Solo puedes
                  tener un máximo de 3 caracteres</span></mat-error>
            </mat-form-field>

            <mat-form-field style="padding: 1%;" class="col-8" appearance="fill">
              <mat-label>Valor en Letras</mat-label>
              <input style="text-transform: none;" matInput type="text" placeholder="Valor en letras"
                formControlName="value_in_letters" readonly>
              <mat-error *ngIf="error.value_in_letters.errors"><span
                  *ngIf="error.value_in_letters.errors.required">¡Advertencia! Este campo es requerido</span>
              </mat-error>
              <mat-error *ngIf="error.value_in_letters.errors"><span
                  *ngIf="error.value_in_letters.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span>
              </mat-error>
              <mat-error *ngIf="error.value_in_letters.errors"><span
                  *ngIf="error.value_in_letters.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 3
                  caracteres</span></mat-error>
            </mat-form-field>

            <mat-form-field style="padding: 1%;" class="col-12" appearance="fill">
              <mat-label>Observaciones</mat-label>
              <input style="text-transform: none;" matInput type="text" placeholder="Escribe la observación"
                formControlName="observations" required>
              <mat-error *ngIf="error.observations.errors"><span
                  *ngIf="error.observations.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
              <mat-error *ngIf="error.observations.errors"><span *ngIf="error.observations.errors.minlength">¡Aún falta!
                  Recibimos mínimo 3 caracteres</span></mat-error>
              <mat-error *ngIf="error.observations.errors"><span *ngIf="error.observations.errors.maxlength">¡Cuidado!
                  Solo puedes tener un máximo de 255 caracteres</span></mat-error>
            </mat-form-field>

            <hr>

            <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
              <mat-label>Fecha de Entrega</mat-label>
              <input matInput type="date" placeholder="Escribe el nombre de la aplicación"
                formControlName="delivery_date" required>
              <mat-error *ngIf="error.delivery_date.errors"><span
                  *ngIf="error.delivery_date.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
              <mat-error *ngIf="error.delivery_date.errors"><span *ngIf="error.delivery_date.errors.minlength">¡Aún
                  falta! Recibimos mínimo 3 caracteres</span></mat-error>
              <mat-error *ngIf="error.delivery_date.errors"><span *ngIf="error.delivery_date.errors.maxlength">¡Cuidado!
                  Solo puedes tener un máximo de 40 caracteres</span></mat-error>
            </mat-form-field>


            <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
              <mat-label>Método de Pago</mat-label>
              <mat-select  formControlName="pay" (valueChange)="cambioPagoValue($event)" required>
                      <mat-option *ngFor="let item of ['Efectivo','Transferencia','Cheque','Tarjeta de crédito']"  [value]="item"> {{item}} </mat-option>
                  </mat-select>
                  <mat-error *ngIf="error.pay.errors"><span *ngIf="error.pay.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            </mat-form-field>


            <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
              <mat-label>Condición de Pago</mat-label>
              <mat-select  formControlName="payment_condition" required>
                <mat-option *ngFor="let item of ['Abono','Anticipo','Crédito']"  [value]="item"> {{item}} </mat-option>
            </mat-select>
            </mat-form-field>

            <mat-form-field style="padding: 1%;" class="col-3"
              *ngIf="['Abono','Anticipo'].includes(secondFormGroup.get('payment_condition').value)" appearance="fill">
              <mat-label>Valor {{secondFormGroup.get('payment_condition').value}} </mat-label>
              <input matInput currencyMask [options]="options" placeholder="Escribe el valor" formControlName="payment_deb" required>
              <mat-error *ngIf="error.payment_deb.errors"><span *ngIf="error.payment_deb.errors.required">¡Advertencia!
                  Este campo es requerido</span></mat-error>
            </mat-form-field>

            <mat-form-field style="padding: 1%;" class="col-3"
              *ngIf="['Abono','Anticipo'].includes(secondFormGroup.get('payment_condition').value)" appearance="fill">
              <mat-label>Porcentaje de {{secondFormGroup.get('payment_condition').value}} </mat-label>
              <input matInput type="number" placeholder="50%" formControlName="payment_percent" required>
              <span matPrefix>% &nbsp;</span>
              <mat-error *ngIf="error.sub_total.errors"><span *ngIf="error.sub_total.errors.required">¡Advertencia! Este
                  campo es requerido</span></mat-error>
            </mat-form-field>


            <mat-form-field style="padding: 1%;" class="col-3" appearance="fill"
              *ngIf=" secondFormGroup.get('payment_condition').value == 'Crédito' ">
              <mat-label>Condición de crédito</mat-label>
              <mat-select  formControlName="credit_condition" required>
                <mat-option *ngFor="let item of ['30 Días','60 Días','90 Días']"  [value]="item"> {{item}} </mat-option>
            </mat-select>
              <mat-error *ngIf="error.pay.errors"><span *ngIf="error.pay.errors.required">¡Advertencia! Este campo es
                  requerido</span></mat-error>
            </mat-form-field>

            <mat-form-field style="padding: 1%;" [ngClass]="secondFormGroup.get('payment_condition').value == 'Crédito' ? 'col-6' : 'col-3'" appearance="fill" readonly>
              <mat-label>Garantía</mat-label>
              <mat-select  formControlName="warranty" required >
                <mat-option value="Si">Si</mat-option>
                <mat-option value=No>No</mat-option>
            </mat-select>
              <mat-error *ngIf="error.warranty.errors"><span *ngIf="error.warranty.errors.required">¡Advertencia! Este
                  campo es requerido</span></mat-error>
              <mat-error *ngIf="error.warranty.errors"><span *ngIf="error.warranty.errors.minlength">¡Aún falta!
                  Recibimos mínimo 3 caracteres</span></mat-error>
              <mat-error *ngIf="error.warranty.errors"><span *ngIf="error.warranty.errors.maxlength">¡Cuidado! Solo
                  puedes tener un máximo de 3 caracteres</span></mat-error>
            </mat-form-field>

            <mat-form-field style="padding: 1%;" class="col-6" appearance="fill" readonly>
              <mat-label>Póliza</mat-label>
              <mat-select  formControlName="policy" required >
                <mat-option value="Si">Si</mat-option>
                <mat-option value=No>No</mat-option>
            </mat-select>
              <mat-error *ngIf="error.policy.errors"><span *ngIf="error.policy.errors.required">¡Advertencia! Este campo
                  es requerido</span></mat-error>
              <mat-error *ngIf="error.policy.errors"><span *ngIf="error.policy.errors.minlength">¡Aún falta! Recibimos
                  mínimo 3 caracteres</span></mat-error>
              <mat-error *ngIf="error.policy.errors"><span *ngIf="error.policy.errors.maxlength">¡Cuidado! Solo puedes
                  tener un máximo de 3 caracteres</span></mat-error>
            </mat-form-field>

            <mat-form-field style="padding: 1%;" class="col-6" appearance="fill">
              <mat-label>Ciudad de Entrega</mat-label>
                <input matInput type="text" placeholder="Indique la ciudad de entrega" formControlName="ciudad" required>
                <mat-error *ngIf="error.ciudad.errors"><span *ngIf="error.ciudad.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                <mat-error *ngIf="error.ciudad.errors"><span *ngIf="error.ciudad.errors.minlength">¡Advertencia! No puede ser inferior a 4 caracteres</span></mat-error>
                <mat-error *ngIf="error.ciudad.errors"><span *ngIf="error.ciudad.errors.maxlength">¡Advertencia! No puede ser superior a 50 caracteres</span></mat-error>
            </mat-form-field>
            <mat-form-field style="padding: 1%;" class="col-6" appearance="fill">
              <mat-label>Dirección</mat-label>
                <input matInput type="text" placeholder="Indique la dirección" formControlName="direccion" required>
                <mat-error *ngIf="error.direccion.errors"><span *ngIf="error.direccion.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                <mat-error *ngIf="error.direccion.errors"><span *ngIf="error.direccion.errors.minlength">¡Advertencia! No puede ser inferior a 4 caracteres</span></mat-error>
                <mat-error *ngIf="error.direccion.errors"><span *ngIf="error.direccion.errors.maxlength">¡Advertencia! No puede ser superior a 20 caracteres</span></mat-error>
            </mat-form-field>
            <mat-form-field style="padding: 1%;" [ngClass]="secondFormGroup.get('payment_condition').value == '' ? 'col-6' : 'col-12'" appearance="fill">
              <mat-label >Observaciones de condiciones</mat-label>
              <textarea matInput rows="1" placeholder="Registre las observaciones de las condiciones" formControlName="observacionCondiciones" required></textarea>
                <mat-error *ngIf="error.observacionCondiciones.errors"><span *ngIf="error.observacionCondiciones.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                <mat-error *ngIf="error.observacionCondiciones.errors"><span *ngIf="error.observacionCondiciones.errors.minlength">¡Advertencia! No puede ser inferior a 20 caracteres</span></mat-error>
                <mat-error *ngIf="error.observacionCondiciones.errors"><span *ngIf="error.observacionCondiciones.errors.maxlength">¡Advertencia! No puede ser superior a 100 caracteres</span></mat-error>
            </mat-form-field>

            <ng-container *ngIf="(data.ordenCompra.purchase_order_state_id == 5 || data.ordenCompra.purchase_order_state_id == 7) && view && !fragmentar">
              <div>
                <h4>
                  Observación para cancelación <ng-container *ngIf="data.ordenCompra.purchase_order_state_id != 7">(Opcional)</ng-container>
                </h4>
              </div>

              <mat-form-field style="padding:1% 0;" class="col-12" appearance="fill" >
                <mat-label>Observación</mat-label>
                <input matInput  [attr.disabled]="Observacion_cancel.disabled" style="text-transform: lowercase;" value="{{Observacion_cancel.valueInput}}" type="text" placeholder="Escribe la observación"
                formControlName="observ_cancel">
                <mat-error *ngIf="error.observ_cancel.errors">

                  <span *ngIf="error.observ_cancel.errors.minlength">¡Aún falta! Recibimos mínimo 5 caracteres</span>
                  <span *ngIf="error.observ_cancel.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 200 caracteres</span>
                </mat-error>

              </mat-form-field>

              <div style="float: right;"
              *ngIf="!Observacion_cancel.disabled">

                <button class="bold compras-modal-button"   mat-stroked-button mat-dialog-close>Cancelar</button>

                <button class="ms-2 bold compras-modal-button" disabled='{{secondFormGroup.invalid}}' mat-flat-button color="primary" type="button"
                  (click)="sendObservCancel()">
                  Enviar</button>

              </div>

            </ng-container>


          <div class="row">
            <div class="col-12">
              <div style="float: right;" *ngIf="fragmentar">
                <button class="bold compras-modal-button" mat-button mat-stroked-button matStepperPrevious>Anterior</button>
                <button class="bold compras-modal-button ms-2" *ngIf="itemsOrder.length > 0" mat-raised-button color="primary" (click)="saveOrder()"
                 [disabled]="disableButton">Guardar</button>
              </div>
            </div>
          </div>




          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-dialog-content>
  <!-- </form> -->
</div>
