import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.sass']
})
export class TemplatePreviewComponent implements OnInit {

  public filepath: SafeResourceUrl;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TemplatePreviewComponent>,
    private sanitizer: DomSanitizer,
  ) {
    this.filepath = this.sanitizer.bypassSecurityTrustResourceUrl(data.filepath);
  }

  ngOnInit(): void {  }

  closeModal() {
    this.dialogRef.close();
  }
}
