import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
const AUDITORIA_URL = environment.AUDITORIA_URL;
@Injectable({
  providedIn: 'root'
})
export class PreguntasService {
  constructor(
    private http:HttpClient,
    private authService:AuthService
  ) { }
  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  /**
   * @author Cristian Gallo
   * @createdate 2021-10-27
   * Servicio que trae los tipos de pregunta bien sea para usarlos como filtro o creación de preguntas.
   */
  getTypeQuestions(){
    return this.http.post<any>(`${AUDITORIA_URL}getTiposPreguntas`,{})
    .pipe(
      catchError(this.handleError)
      );
  }
  /**
   * @author Cristian Gallo
   * @createdate 2021-10-27
   * Servicio que guarda una nueva pregunta.
   * @param data datos de la pregunta a guardar
  */
  saveQuestion(data:Object){
    let dataForm = {dataform:[data]}
    return this.http.post<any>(`${AUDITORIA_URL}crearPregunta/${this.authService.getUser().rrhh_id}`,dataForm)
    .pipe(
      catchError(this.handleError)
      );
  }
  /**
   * @author Cristian Gallo
   * @createdate 2021-10-27
   * Servicio que trae todas las preguntas creadas para mostrar en una tabla.
   * @param params parametros como N° de pagina, cantidad de registros y filtros.
  */
  getQuestions(params){
    return this.http.get<any>(`${AUDITORIA_URL}listarPreguntas`,{params})
    .pipe(
      catchError(this.handleError)
      );
  }
  /**
   * @author Cristian Gallo
   * @createdate 2021-10-27
   * Servicio que actualiza una pregunta.
   * @param data datos de la pregunta a actualizar
   * @param id_question id de la pregunta a actualizar
  */
  updateQuestion(id_question,data){
    return this.http.post<any>(`${AUDITORIA_URL}updatePregunta/${id_question}/${this.authService.getUser().rrhh_id}`,{dataform:[data]})
    .pipe(
      catchError(this.handleError)
      );
  }
  /**
   * @author Cristian Gallo
   * @createdate 2021-10-27
   * Servicio que actualiza una pregunta.
   * @param data datos de la pregunta a actualizar
   * @param id_question id de la pregunta a actualizar
  */
  getQuestionByID(id_question){
    return this.http.get<any>(`${AUDITORIA_URL}showPregunta/${id_question}`)
    .pipe(
      catchError(this.handleError)
      );
  }
  /**
   * @author Cristian Gallo
   * @createdate 2021-10-27
   * Servicio que elimina una pregunta.
   * @param id_question id de la pregunta a eliminar.
  */
  removeQuestion(id_question){
    return this.http.post<any>(`${AUDITORIA_URL}eliminarPregunta/${id_question}`,{})
    .pipe(
      catchError(this.handleError)
      );
  }
  deleteOpcion(data){
    return this.http.post<any>(`${AUDITORIA_URL}deleteOpcion`,data)
    .pipe(
      catchError(this.handleError)
      );
  }
}