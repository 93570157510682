<div class="landing">

  <div class="row">
    <div class="col-6">
      <div class="landing-div-text">
        <br>
        <br>
        <br>
        <p class="landing-tittle">Control de usuarios</p>
        <br>
        <p class="landing-welcome">¡Bienvenid@<br> {{userName.rrhh.name | titlecase}}!</p>
        <br>
        <div class="col-8 mt-3">
          <p class="landing-sub-tittle">
            Este es el módulo de <b>CIU</b>, donde podrás gestionar las mallas de horario y la administración de roles,
            usuarios y permisos
          </p>
        </div>
        <br>
        <div style="width: 429px;">
          <button class="btnlanding" mat-stroked-button color="primary" (click)="onBoadingCiu()">Quiero ver el
            funcionamiento del módulo</button>
        </div>
      </div>
    </div>

    <div class="col-6" style="text-align: right;">
      <img src="../../../assets/images/landing-pages/ciu/Ilustracion_CIU.svg" style="height:90%;" alt="">
    </div>
  </div>
  <div class="row">
    <div class="col-md-9">
      <!-- <app-user-schedule></app-user-schedule> -->
      <app-user-schedule-v2></app-user-schedule-v2>
    </div>
  </div>
</div>