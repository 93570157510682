import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../../core/services/rest/auth.service';
import { environment } from 'src/environments/environment';

const CIU_URL = environment.CIU_URL;

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  rol: any;
  user: any;
  headers;
  headersFile;
  constructor(private http: HttpClient, private authService: AuthService) {
    this.leerStorage();
  }

  getInfo() {
    return this.http.post(`${CIU_URL}schedule/adherencia`, this.headers)
  }

  leerStorage() {
    this.user = this.authService.getToken();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' + this.user
    });
  }


  /**
   * @author Javier Castañeda <javier.c@montechelo.com.co>
   * @purpose Servicio obtener rango de fechas en la semana actual
   */
  getcurrentDatesInTheWeek() {
    return this.http.get(`${CIU_URL}schedule/getCurrentConfigWeek`, this.headers)
  }

  /**
 * @author Javier Castañeda <javier.c@montechelo.com.co>
 * @purpose Servicio obtener rango de fechas en la semana actual
 */
  getgraphBars() {
    return this.http.get(`${CIU_URL}schedule/getGraphBars`, this.headers)
  }
  

  getCalculosSchedule(date) {
    return this.http.get(`${CIU_URL}schedule/getCalculos/${date}`, this.headers)
  }

}
