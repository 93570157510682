<div class="main-container">
    <h1>Recursos</h1>
    <div fxLayout="row" fxLayoutAlign="end center">
      <button class="type-button" mat-stroked-button (click)="createResourceType()" color="accent">Crear tipo de recurso</button>
      <button mat-flat-button (click)="createResource()" color="primary">Crear recurso</button>
        
    </div>
    <br>
    <br>
    <!-- <div class="filter">
      <mat-form-field appearance="fill">
          <mat-label>Filtrar por estado</mat-label>
          <mat-select [(ngModel)]="typeStatus" (selectionChange)="getResources()">
            <mat-option value="2">Aprobado</mat-option>
            <mat-option value="1">Pendiente</mat-option>
            <mat-option value="3">Rechazado</mat-option>
            <mat-option value="">Ninguno</mat-option>
          </mat-select>
      </mat-form-field>
    </div> -->
  
    <div class="table" *ngIf="resourceList">
  
        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                          <td mat-cell *matCellDef="let row">
                            <button mat-icon-button matTooltip="Editar" (click)="editResource(row)"> <span class="fi fi-rr-edit"></span> </button>
                            <!-- <button mat-icon-button matTooltip="Eliminar" (click)="removeResource(row.id)"> <span class="fi-rr-trash"></span> </button> -->
                            <button mat-icon-button matTooltip="Horarios" (click)="createResourceRules(row.id)"> <span class="fi-rr-time-add"></span> </button>
                          </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef > Nombre</th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef > Tipo</th>
              <td mat-cell *matCellDef="let element"> {{element.type.name}} </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="5">No hay reservas disponibles</td>
          </tr>
        </table>
  <!-- <mat-paginator [length]="length" [pageSize]="pageSize" (page)="getPaginatorData($event)" showFirstLastButtons></mat-paginator> -->
  
  
    </div>
  </div>