<div class="row">
  <div class="col-10">
    <h2 mat-dialog-title class="title">{{ data.title }}</h2>
  </div>
  <div class="col-2 d-flex justify-content-end">
    <i class="fi-rr-cross-small" style="cursor:pointer; font-size: 20px; color: #353535;" mat-icon-button
      mat-dialog-close></i>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <mat-horizontal-stepper linear #stepper>
    <mat-step [stepControl]="match_answers" [editable]="false" [completed]="match_answers">
      <form [formGroup]="questions_form" class="mt-3">
        <ng-template matStepLabel>Responde las preguntas de seguridad</ng-template>
        <div class="row" formGroupName="first_question">
          <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>{{ questions_labels.first }}</mat-label>
              <input type="text" matInput formControlName="answer" required />
              <mat-error *ngIf="first_question_controls.answer.hasError('required')">Ingresa una respuesta</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row" formGroupName="second_question">
          <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>{{ questions_labels.second }}</mat-label>
              <input type="text" matInput formControlName="answer" required />
              <mat-error *ngIf="second_question_controls.answer.hasError('required')">Ingresa una respuesta</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row" formGroupName="third_question">
          <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>{{ questions_labels.third }}</mat-label>
              <input type="text" matInput formControlName="answer" required />
              <mat-error *ngIf="third_question_controls.answer.hasError('required')">Ingresa una respuesta</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="d-flex justify-content-end">
            <button color="primary" mat-raised-button (click)="validateAnswers(stepper)">Validar</button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="password_form" [editable]="false">
      <form [formGroup]="password_form">
        <ng-template matStepLabel>Ingresa la nueva contraseña</ng-template>
        <!--div class="row">
            <div class="col-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Usuario de red</mat-label>
                <input type="text" matInput formControlName="network_user" required/>
                <mat-error *ngIf="pass_form_controls.network_user.hasError('required')">Ingresa tu usuario de red</mat-error>
              </mat-form-field>
            </div>
          </div>-->
        <div class="row">
          <div class="col-12">
            <div class="alert alert-primary" role="alert">
              <h4 class="alert-heading fs-5 fw-bold">Configuración de la contraseña</h4>
              <ul>
                <li>La contraseña debe contener al menos una letra mayúscula.</li>
                <li>La contraseña debe contener al menos una letra minúscula.</li>
                <li>La contraseña debe contener al menos un dígito.</li>
                <li>La contraseña debe contener al menos un caracter especial.</li>
              </ul>
              <div>
                <b>Estado de la contraseña: </b><span class="fw-bold" [ngStyle]="{'color': checkPassword.color}">{{
                  checkPassword.text }}</span>
              </div>
            </div>
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Nueva contraseña</mat-label>
              <input [type]="hide_pass ? 'password' : 'text'" matInput formControlName="new_password" required />
              <button mat-icon-button matSuffix (click)="hide_pass = !hide_pass"
                [attr.aria-label]="'Ocultar contraseña'">
                <i [class]="hide_pass ? 'fi-rr-eye-crossed' : 'fi-rr-eye'"></i>
              </button>
              <mat-error *ngIf="pass_form_controls.new_password.hasError('required')">Ingresa la nueva
                contraseña</mat-error>
              <mat-error *ngIf="pass_form_controls.new_password.hasError('pattern')">La contraseña debe contener minimo
                una mayúscula, una minúscula, un número y un caracter especial</mat-error>
              <mat-error *ngIf="pass_form_controls.new_password.hasError('minlength')">La contraseña debe tener una
                longitud mínima de 12 caracteres</mat-error>
              <mat-error *ngIf="pass_form_controls.new_password.hasError('maxlength')">La contraseña debe tener una
                longitud máxima 20 caracteres</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Confirma la contraseña</mat-label>
              <input [type]="hide_repeat ? 'password' : 'text'" matInput formControlName="repeat_password" required />
              <button mat-icon-button matSuffix (click)="hide_repeat = !hide_repeat"
                [attr.aria-label]="'Ocultar contraseña'">
                <i [class]="hide_repeat ? 'fi-rr-eye-crossed' : 'fi-rr-eye'"></i>
              </button>
              <mat-error *ngIf="pass_form_controls.repeat_password.hasError('required')">Ingresa la
                contraseña</mat-error>
              <mat-error *ngIf="pass_form_controls.repeat_password.hasError('not_match')">El valor no coincide con la
                contraseña ingresada</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex justify-content-end">
            <button color="primary" class="ms-2" mat-raised-button (click)="changePassword()">Guardar</button>
          </div>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</mat-dialog-content>