import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { DynamicFormQuestionComponent } from './dynamic-form-question/dynamic-form-question.component';
import { SharedModule } from '../../../../shared/shared.module';
import { CopCurrencyDirective } from '../../directives/cop-currency.directive';
import { MaterialModule } from 'src/app/shared/material/material.module';

@NgModule({
  declarations: [DynamicFormComponent, DynamicFormQuestionComponent, CopCurrencyDirective],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule
  ],
  exports : [
    DynamicFormComponent
  ],
  providers: [CurrencyPipe, DecimalPipe]
})
export class SharedFormModule { }
