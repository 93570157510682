<div class="container">
  <div>
    <h1>Reportes de nómina</h1>
    <p>En este espacio podrás descargar los reportes de <br>
       nomina, no olvides,"El respeto y la
      empatiá son clave"</p>
  </div>
  <div class="row col-12">
    <form [formGroup]="reportForm" (ngSubmit)="downloadReportNovelties()">

      <mat-form-field appearance="fill" class="col-2 filter">
        <mat-label>Tipo de reporte</mat-label>
        <mat-select formControlName="typeReport">
          <mat-option *ngFor="let item of typeReports" [value]="item.id">
            {{item?.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="reportForm.controls.typeReport.hasError('required')"> ¡Advertencia! Este campo es
          requerido
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-2" style="padding-right: 1rem">
        <mat-label>Fecha Inicio y Fin</mat-label>
        <mat-date-range-input [rangePicker]="pickerRange3">
          <input matStartDate formControlName="startDate" placeholder="Fecha Inicio" #startDate3>
          <input matEndDate formControlName="endDate" placeholder="Fecha Fin" #endDate3>
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="pickerRange3"></mat-datepicker-toggle>
        <mat-date-range-picker #pickerRange3></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field class="col-2" appearance="fill" style="padding-right: 1rem">
        <mat-label>Campaña:</mat-label>
        <input type="text" matInput
               formControlName="campaignId" [matAutocomplete]="campaignList2">
        <mat-autocomplete #campaignList2="matAutocomplete" [displayWith]='displayFnCampaigns.bind(this)' required>
          <mat-option *ngFor="let campaign of filteredOptionsCampaigns | async" [value]="campaign.id">
            {{campaign.name}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="errorReport.campaignId.errors"><span *ngIf="errorReport.campaignId.errors.required">Campo es
              requerido</span></mat-error>
        <mat-error *ngIf="errorReport.campaignId.errors">Opción no válida</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-3 filter">
        <mat-label>Filtrar por identificación</mat-label>
        <input matInput type="text" #input3 formControlName="docNumber" autocomplete="off">
        <mat-select name="ampm3" class="ampm3" matSuffix formControlName="docType"
                    style="width: 60px; margin-left: 5px;">
          <mat-option *ngFor="let item of documents" [value]="item?.id">{{item.key}}</mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button color="primary" class="download"
              [disabled]="reportForm.controls.startDate.invalid || reportForm.controls.endDate.invalid">Generar reporte
        <span class="fi-rr-download"></span>
      </button>

      <p *ngIf="reportForm.controls.typeReport.value">
        <b>Nota:</b> {{ reportDescription }}
      </p>

    </form>
  </div>
</div>
