import { FormControl } from '@angular/forms';

/**
  * @author Fabian Duran
  * @createdate 2023-07-18
  * Metodo valida si el numero es positivo sobre los campos tipo texto.
  * @param control Control de formulario.  
*/
export const validateNumbersNegative = (control: FormControl) => {
  if (control.value && parseInt(control.value) < 0) return { validateNumbersNegative: { valid: false } };
  return null;
};