<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<h3 >Horarios de reserva</h3>
<mat-divider></mat-divider>
<mat-dialog-content >

    <div class="details">
        <div class="rule-btns">
            <p><strong> Lista de horarios</strong></p>
            <button  *ngIf="!createRule" mat-button
                    (click)="createRule = !createRule">Crear nuevo horario &nbsp;<span class="fi-rr-add"></span></button>
        </div>
        <form [formGroup]="ruleForm" *ngIf="createRule" class="rule-form">
                <mat-form-field appearance="fill" fxFlex *ngIf="!states">
                    <mat-label>Día de la semana</mat-label>
                    <mat-select formControlName="day">
                        <mat-option *ngFor="let day of days" [value]="day.value">
                            {{day.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="ruleForm.get('day').hasError('required')">Este campo es obligatorio</mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex *ngIf="!states">
                    <mat-label>Hora de inicio</mat-label>
                     <input matInput type="time" formControlName="start">
                    <mat-error *ngIf="ruleForm.get('start').hasError('required')">Este campo es obligatorio</mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex *ngIf="!states">
                    <mat-label>Hora de fin</mat-label>
                     <input matInput type="time" min="13:00" formControlName="end">
                     <mat-error *ngIf="ruleForm.get('end').hasError('min')">La hora fin no puede ser inferior a la hora de inicio</mat-error>
                    <mat-error *ngIf="ruleForm.get('end').hasError('required')">Este campo es obligatorio</mat-error>
                </mat-form-field>


                <!--Fechas de inicio y fin-->
                <mat-form-field appearance="fill" fxFlex *ngIf="!states">
                    <mat-label>Rango de fechas</mat-label>
                    <mat-date-range-input  [rangePicker]="picker">
                        <input matStartDate formControlName="date_start" >
                        <input matEndDate formControlName="date_end" >
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>


                </mat-form-field>


                <div class="rule-form_btns">
                    <button mat-stroked-button type="button" (click)="cancelForm('ruleForm','createRule')">Cancelar</button>
                    <button mat-flat-button color="primary" (click)="saveRule()">Guardar</button>
                </div>

        </form>
        <mat-divider></mat-divider>
        <div class="rule-list">
            <p *ngIf="rules.length == 0" >No hay horarios relacionados a este recurso</p>
            <mat-list>
                <mat-list-item class="rule-item" *ngFor="let rule of rules">
                        <div class="rule-info">
                            <br>
                            <div matLine><b>{{rule.day |weekDays}}</b> </div>
                            <p mat-line matSubheader>
                                <span><strong>Desde</strong> &nbsp;{{rule.start}}
                                <strong>Hasta</strong> &nbsp;{{rule.end}}</span>
                            </p>
                        </div>

                        <div class="rule-action">
                            <button mat-icon-button color="primary" (click)="removeRule(rule.id)"><span class="fi-rr-trash"></span></button>
                        </div>

                    <mat-divider></mat-divider>
                </mat-list-item>
            </mat-list>

        </div>
    </div>
</mat-dialog-content>
