import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { debounce, debounceTime, map, startWith, tap } from 'rxjs/operators';
import { CampaingsService } from './../../../../../crm_v2/services/rest/campaings.service';
import { GroupsService } from 'src/app/modules/crm_v2/services/rest/groups.service';
import { ActivatedRoute } from '@angular/router';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSelectionList } from '@angular/material/list';
import { CandidatesService } from 'src/app/modules/rrhh/services/candidates.service';
import Swal from 'sweetalert2';
import { MatSelectChange } from '@angular/material/select';
@Component({
  selector: 'app-admin-groups',
  templateUrl: './admin-groups.component.html',
  styleUrls: ['./admin-groups.component.sass']
})
export class AdminGroupsComponent implements OnInit {

  groupForm: FormGroup;
  filteredOptions: Observable<any[]>;
  campaigns: any;
  userSinGroup: any[] = [];
  userSinGroupNuevo: any[] = [];
  userGroup: any[] = [];
  userSelected: any[] = [];
  usersId: any[];
  campaingId: string;
  @ViewChild('shoes', {static: true}) private allSelected: MatSelectionList;
  @ViewChild('shoes2', {static: false}) private allSelected2: MatSelectionList;

  listTypeDocuments:any=[]
  documentNumber:FormControl =  new FormControl([''])
  typeDocument:FormControl =  new FormControl(1)
  userData:any = []

  listCampaign:any[] = []
  idGroup:number = 0
  constructor(
    public group: GroupsService,
    private route: ActivatedRoute,
    private alertsService: AlertsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private users: CandidatesService,
    public CampaignService: CampaingsService,
  ) { }

  ngOnInit() {

    this.formControl();
    this.getTypesDocuemnt();
    this.getCampaigns();

    if(this.data.hasOwnProperty('group')){

      if(this.data.group.id > 0){
        this.idGroup = this.data.group.id
        this.setEdit();
      }

    }


  }

  /**
   * Metodo que se encarga de obtener la lista de campanas
   * @author Juan David Guerrero Vargas
   */
  getCampaigns(){
    this.CampaignService.getCampaings('campaigns/list').subscribe(
      restData => {
        this.listCampaign = restData.data.map( dataCampaign => {
          dataCampaign['isDisabled'] = false
          return dataCampaign;
        } )
      }
    );
  }


  /**
   * Metodo que caga los tipos de documento apra selector e anadir usuario
   * @author Juan David Guerrero Vargas
   */
  getTypesDocuemnt(){
    this.users.getIdTypes().subscribe(resp => {
      this.listTypeDocuments = resp;
    });
  }

  get error(): any { return this.groupForm.controls; }
  /**
   * @author Daniel Dominguez
   * @createdate 2021-03-04
   * Form control de grupos
   */
  formControl(): void {

    this.groupForm = new FormGroup({
      group: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
      campaigns: new FormControl(null,[])
    });


    this.groupForm.get('campaigns').valueChanges
    .pipe(
      tap(()=> this.enableCampaigns()),
      debounceTime(700))
    .subscribe( valueCampaigns =>{
      
      this.loadUsersByCampaign();
    })

  }

  /**
   * @author Daniel Dominguez
   * @update Juan David Guerrero Vargas
   * @createdate 2021-03-03
   * Metodo Cargar datos modal de editar
   */
  setEdit(): void {
    if (this.data.type === 'editar') {
      this.groupForm.controls.group.setValue(this.data.group.name_group);
      this.groupForm.controls.description.setValue(this.data.group.description);
      this.group.getGroupById(this.data.group.id).subscribe(res => {
        let dataGroup = res.members.map( function (userMapped) {
          userMapped['oldUser'] = true
          return userMapped
        }  );
        this.userGroup = dataGroup;
      });

    }
  }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-03-03
   * COntrol de individual de checkbox de usuarios sin grupo
   */
  lstInicial = new FormGroup({
    selected: new FormControl([])
  });

  /**
   * @author Daniel Dominguez
   * @createdate 2021-03-03
   * COntrol de individual de checkbox de usuarios en el grupo
   */
  lstfinal = new FormGroup({
    selected: new FormControl([])
  });

  /**
   * @author Daniel Dominguez
   * @createdate 2021-03-05
   * COntrol de checkbox de todos los usarios sin grupo
   */
  checkAll1 = new FormGroup({
    selected: new FormControl(false,[])
  });

  /**
   * @author Daniel Dominguez
   * @createdate 2021-03-05
   * COntrol de checkbox de todos los usarios en el grupo
   */
  checkAll2 = new FormGroup({
    selected: new FormControl(false,[])
  });

  /**
   * @author Daniel Dominguez
   * @createdate 2021-03-03
   * Metodo carga datos modal de crear grupo
   */
  saveGroup(groupForm): void {
    if (this.data.type === 'crear') {
      let users = [];
      this.userGroup.map(function (user) {
        let id = { 'idRrhh': user.id_rhh };
        users = [...users, id];
      });


      const group = {
        name_group: groupForm.value.group,
        description: groupForm.value.description,
        state: 1,
        users: users,
      };
      this.group.createGroup(group).subscribe(res => {
        this.alertsService.alertSuccess('Guardado', res.data);
      });

    }
    else if (this.data.type === 'editar') {

      let listNewUsers =  this.userGroup.filter(filtered => filtered['newState'] == 'aggregate' && filtered['oldUser'] == undefined )
      let listDeleteUsers = this.userSinGroup.filter( filtered => filtered['newState'] == 'removed' && filtered['oldUser'] == true )

      listNewUsers = listNewUsers.map(function (user) {
        return  { 'idRrhh': user.id_rhh };

      });
      listDeleteUsers = listDeleteUsers.map(function (user) {
        return  { 'idRrhh': user.id_rhh };

      });

      const group = {
        name_group: groupForm.value.group,
        description: groupForm.value.description,
        state: this.data.group.state,
        users_to_delete : listDeleteUsers,
        users_to_add:listNewUsers
      };

      this.editGroup(group);
    }

  }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-03-04
   * Metodo editar grupo
   */
  editGroup(group): void {


    this.group.updateGroup(this.data.group.id, group).subscribe(res => {
      this.alertsService.alertSuccess('Guardado', res.data);
    });
  }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-03-05
   * Metodo selecionar o deseleccionar todos los usuarios
   */
  onChangeCheck(event){
    if (event.checked == true) {
      this.allSelected.selectAll();
    }else{
      this.allSelected.deselectAll();
    }
  }

  onChangeCheck2(event){
    if (event.checked == true) {
      this.allSelected2.selectAll();
    }else{
      this.allSelected2.deselectAll();
    }
  }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-03-04
   * Metodo asignación de usarios al grupo
   */
  asingarGrupo() {
    this.userSelected = this.lstInicial.value.selected;
    this.userSinGroupNuevo = this.userSinGroup;
    this.userSelected.forEach(select => {

      if (this.data.type === 'editar' && select['oldUser'] == undefined ){
        select['newState'] = 'aggregate'
      }
      this.userGroup.push(select);
      this.userSinGroupNuevo = this.userSinGroupNuevo.filter((i) => i.id_rhh !== select.id_rhh || i.id !== select.id  )
    });

    this.lstInicial.value.selected = [];
    this.checkAll1.controls.selected.setValue(false,[]);
    this.userSinGroup = this.userSinGroupNuevo;
    this.lstInicial.controls.selected.setValue([]);
  }
  /**
   * @author Daniel Dominguez
   * @createdate 2021-03-04
   * Metodo remover de usarios al grupo
   */
  quitarGrupo() {
    this.userSelected = this.lstfinal.value.selected;
    this.userSinGroupNuevo = this.userGroup;
    this.userSelected.forEach(select => {
      if (this.data.type === 'editar' && select['oldUser'] == true){
        select['newState'] = 'removed'
      }
      this.userSinGroup.push(select);
      this.userSinGroupNuevo = this.userSinGroupNuevo.filter((i) => i.id_rhh !== select.id_rhh || i.id !== select.id )
    });
    this.lstfinal.value.selected = [];
    this.checkAll2.controls.selected.setValue(false,[]);
    this.userGroup = this.userSinGroupNuevo;
    this.lstfinal.controls.selected.setValue([]);
  }

  /**
   * Metodo que se encarga de obtener usuario por documento y tipo de doumento
   * @author Juan David Guerrero Vargas
   * @param e:string valor del input desde donde se dispara el evento blur
   * @return void
   */

  onBlurMethod(e) {
    if(e !== null && e !== ""){
      this.getUserByDocument(e,this.typeDocument.value);
    }
   
  }

  /**
   * Metodo que se encarga de obtener
   * @autor Juan David Guerrero Vargas
   * @param document :String numero de doumento aa consultar
   * @param idTypeDocument:string tipo de documento a consultar
   * @returns void {void}
   */
  getUserByDocument(document:string, idTypeDocument:string){
    this.group.getUserByDocument(document,idTypeDocument).subscribe(
      restData =>{
        if(Array.isArray(restData.user)){
          this.userData.id_rhh = null
          this.userData.name = null
          this.userData.campaing = null
        }else{
          this.userData.id_rhh = restData.user.rrhh_id
          this.userData.name = restData.user.name
          this.userData.campaing = restData.user.campaing
        }

    })
  }

  /**
   * Metodo que se encarga de agregar el usuario al listado miembros
   * @author Juan David Guerrero Vargas
   * @returns void {void}
   */

  addUserToGroup() {
    this.userData['newState'] = 'aggregate'
    this.userGroup.push(this.userData);
    this.userData = {};
    this.documentNumber.setValue('');
  }

  enableCampaigns(){
    const campaignsSelected = this.groupForm.get('campaigns').value
    if(campaignsSelected.length  == 3  ){
      this.listCampaign.map( campaigns => {
        if( campaignsSelected.find( selectCamaing => selectCamaing.id == campaigns.id )  == undefined){
          campaigns['isDisabled'] =  true
        }else{
          campaigns['isDisabled'] =  false
        }
      });
    }else{
      this.listCampaign.map( campaigns => {
        campaigns['isDisabled'] = false
        return campaigns;
      })
    }

  }

  /**
   * Metodo que se encarga e listar los usuarios por camapna seleccionada
   * @author Juan dav Guerrero Varas
   */
  loadUsersByCampaign(){
    const campaignsSelected = this.groupForm.get('campaigns').value
    if(Array.isArray(campaignsSelected) && campaignsSelected.length > 0){
      this.group.getUsersByCampaign(campaignsSelected.map( campaign => String(campaign.id) ), this.idGroup).subscribe(
        (restResponse:any) =>{
          this.userSinGroup = restResponse.users
        }
      );
    }else{
      this.userSinGroup = []
    }
    

  }
}
