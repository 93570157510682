export class inicialFieldsContract {
  value: any[];
  constructor() {
    this.value = [

      {
        id: 301,
        type: 'options',
        key: 'empresa',
        controlType: 'dropdown-request',
        label: 'Empresa',
        value: '',
        apiServe: 'rrhh',
        required: true,
        canAdd: false,
        options: [{ id: null, name: null }],
        minLength: 1,
        maxLength: 1000,
        inReport: false,
        preloaded: false,
        isFather: false,
        dependencies: [],
        disabled: false,
        cols: 1,
        editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
        seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
      },
      {
        id: 304,
        type: 'date',
        key: 'fecha_de_ingreso',
        controlType: 'datepicker',
        label: 'Fecha de ingreso',
        value: '',
        disabled: false,
        required: true,
        canAdd: false,
        options: [],
        minLength: 1,
        maxLength: 100,
        inReport: false,
        preloaded: false,
        isFather: false,
        dependencies: [],
        cols: 1,
        editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
        seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
      },
      {
        id: 305,
        type: 'options',
        key: 'area',
        controlType: 'dropdown-request',
        label: 'Área',
        apiServe: 'rrhh',
        value: '',
        required: true,
        canAdd: false,
        options: [{ id: null, name: null }],
        minLength: 1,
        maxLength: 1000,
        inReport: false,
        preloaded: false,
        isFather: false,
        dependencies: [],
        disabled: false,
        cols: 1,
        editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
        seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
      },
      {
        id: 306,
        type: 'options',
        key: 'cargo',
        controlType: 'dropdown-request',
        label: 'Cargo',
        apiServe: 'rrhh',
        value: '',
        required: true,
        canAdd: false,
        options: [{ id: null, name: null }],
        minLength: 1,
        maxLength: 1000,
        inReport: false,
        preloaded: false,
        isFather: false,
        dependencies: [],
        disabled: false,
        cols: 1,
        editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
        seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
      },
      /*{
          id: 307,
          type: 'options',
          key: 'banco',
          controlType: 'dropdown-request',
          label: 'Banco',
          apiServe:'rrhh',
          value: '',
          required: true,
          canAdd: false,
          options:[{id:null,name:null}],
          minLength: 1,
          maxLength: 1000,
          inReport: false,
          preloaded: false,
          isFather: false,
          dependencies:[],
          disabled:false,
          cols:1,
          editRoles:  ['coordinador-de-contratacion','analista-de-contratacion'],
          seeRoles: ['coordinador-de-contratacion','analista-de-contratacion'],
      },
      {
          id: 307,
          type: 'options',
          key: 'kind_account',
          controlType: 'dropdown-request',
          label: 'Tipo de cuenta',
          apiServe:'rrhh',
          value: '',
          required: true,
          canAdd: false,
          options:[{id:null, key:null, name:null}],
          minLength: 1,
          maxLength: 1000,
          inReport: false,
          preloaded: false,
          isFather: false,
          dependencies:[],
          disabled:false,
          cols:1,
          editRoles:  ['coordinador-de-contratacion','analista-de-contratacion'],
          seeRoles: ['coordinador-de-contratacion','analista-de-contratacion'],
      },
      {
          id: 300,
          type: 'number',
          key: 'bank_account',
          controlType: 'textbox',
          label: 'Número de cuenta',
          value: '',
          required: true,
          canAdd: false,
          options:[],
          minLength: 1,
          maxLength: 10000,
          inReport: false,
          preloaded: false,
          isFather: false,
          dependencies:[],
          disabled:false,
          cols:1,
          editRoles: ['coordinador-de-contratacion','analista-de-contratacion'],
          seeRoles: ['coordinador-de-contratacion','analista-de-contratacion'],
      },
       {
          id: 308,
          type: 'options',
          key: 'eps',
          controlType: 'dropdown-request',
          label: 'EPS',
          apiServe:'rrhh',
          value: '',
          required: true,
          canAdd: false,
          options:[{id:null,name:null}],
          minLength: 1,
          maxLength: 1000,
          inReport: false,
          preloaded: false,
          isFather: false,
          dependencies:[],
          disabled:false,
          cols:1,
          editRoles:  ['coordinador-de-contratacion','analista-de-contratacion'],
          seeRoles: ['coordinador-de-contratacion','analista-de-contratacion'],
      }, */
      {
        id: 309,
        type: 'options',
        key: 'caja_de_compensacion',
        controlType: 'dropdown-request',
        label: 'Caja de compensación',
        apiServe: 'rrhh',
        value: '',
        required: true,
        canAdd: false,
        options: [{ id: null, name: null }],
        minLength: 1,
        maxLength: 1000,
        inReport: false,
        preloaded: false,
        isFather: false,
        dependencies: [],
        disabled: false,
        cols: 1,
        editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
        seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
      },
      {
        id: 310,
        type: 'options',
        key: 'arl',
        controlType: 'dropdown-request',
        label: 'ARL',
        apiServe: 'rrhh',
        value: '',
        required: true,
        canAdd: false,
        options: [{ id: null, name: null }],
        minLength: 1,
        maxLength: 1000,
        inReport: false,
        preloaded: false,
        isFather: false,
        dependencies: [],
        disabled: false,
        cols: 1,
        editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
        seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
      },
      {
        id: 311,
        type: 'options',
        key: 'lugar_contratacion',
        controlType: 'dropdown-request',
        label: 'Lugar de contratación',
        apiServe: 'rrhh',
        value: '',
        required: true,
        canAdd: false,
        options: [{ id: null, name: null }],
        minLength: 1,
        maxLength: 1000,
        inReport: false,
        preloaded: false,
        isFather: false,
        dependencies: [],
        disabled: false,
        cols: 1,
        editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
        seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
      },
    ]
  }
}


export const AgreementsType = [
  { id: 1, key: 'obra-labor', name: 'Obra labor', type: 'TYPE_E' },
  // {id:2, key:'integral',  name: 'Integral', type:'TYPE_A'},
  //{id:3, key:'prestacion_de_servicios',  name: 'Prestación de servicios', type:'TYPE_A'},
  { id: 4, key: 'aprendizaje_sena', name: 'Aprendizaje Sena', type: 'TYPE_B' },
  { id: 5, key: 'termino_fijo', name: 'Termino fijo', type: 'TYPE_C' },
  //{id:6, key:'corretaje', name: 'Corretaje', type:'TYPE_A'},
  //{id:7, key:'indefinido',  name: 'Indefinido', type:'TYPE_A'},
  //{id:8, key:'cesion-de-contrato',  name: 'Cesion de contrato', type:'TYPE_D'},
  { id: 9, key: 'aprendizaje-universitario', name: 'Aprendizaje Universitario', type: 'TYPE_B' },
  { id: 10, key: 'indefinido-v2', name: 'Contrato Laboral  Termino - Indefinido', type: 'TYPE_A' },
  { id: 11, key: 'manejo_confianza', name: 'Manejo y Confianza', type: 'TYPE_A' },
  //   {id:11, key:'indefinido_parcial', name: 'Indefinido parcial', type:'TYPE_A'},
  //   {id:11, key:'obra_labor_parcial', name: 'Obra labor parcial', type:'TYPE_A'}
  { id: 12, key: 'obra-labor-por-dias', name: 'Obra labor staff operativo', type: 'TYPE_E' },
  { id: 13, key: 'obra-labor-por-hora', name: 'Contrato laboral indefinido AT&T', type: 'TYPE_E' },
  { id: 14, key: 'termino-indefinido-dias', name: 'Contrato Laboral Término indefinido', type: 'TYPE_A' },
  { id: 15, key: 'termino-indefinido-mc', name: 'Término indefinido manejo y confianza', type: 'TYPE_A' },
  { id: 18, key: 'obra-labor-vanti', name: 'Contrato obra o labor vanti', type: 'TYPE_E' },
]
export class typeForms {
  value: any[];
  constructor() {
    this.value = [
      {
        key: 'TYPE_A',
        form: [
          {
            id: 312,
            type: 'options',
            key: 'cesantias',
            controlType: 'dropdown-request',
            label: 'Cesantías',
            apiServe: 'rrhh',
            value: '',
            required: true,
            canAdd: false,
            options: [{ id: null, name: null }],
            minLength: 1,
            maxLength: 1000,
            inReport: false,
            preloaded: false,
            isFather: false,
            dependencies: [],
            disabled: false,
            cols: 1,
            editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
            seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
          },
          /* Cambios de acuerdo a solicitud: https://trello.com/c/TMOHZn6x/106-mj00028-cambiar-de-interfaz-campos-de-eps-y-fondo-de-pensiones-en-soul
          {
              id: 313,
              type: 'options',
              key: 'caja_de_pensiones',
              controlType: 'dropdown-request',
              label: 'Fondo de pensiones',
              apiServe:'rrhh',
              value: '',
              required: true,
              canAdd: false,
              options:[{id:null,name:null}],
              minLength: 1,
              maxLength: 1000,
              inReport: false,
              preloaded: false,
              isFather: false,
              dependencies:[],
              disabled:false,
              cols:1,
              editRoles:  ['coordinador-de-contratacion','analista-de-contratacion'],
              seeRoles: ['coordinador-de-contratacion','analista-de-contratacion'],
          }, */
        ]
      },
      {
        key: 'TYPE_B',
        form: [
          {
            id: 314,
            type: 'date',
            key: 'fecha_de_vencimiento',
            controlType: 'datepicker',
            label: 'Fecha de vencimiento',
            value: '',
            disabled: false,
            required: true,
            canAdd: false,
            options: [],
            minLength: 1,
            maxLength: 100,
            inReport: false,
            preloaded: false,
            isFather: false,
            dependencies: [],
            cols: 1,
            editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
            seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
          },
          {
            id: 315,
            type: 'text',
            key: 'grupo',
            controlType: 'textbox',
            label: 'Grupo',
            value: '',
            disabled: false,
            required: true,
            canAdd: false,
            options: [],
            minLength: 1,
            maxLength: 50,
            inReport: false,
            preloaded: false,
            isFather: false,
            dependencies: [],
            cols: 1,
            editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
            seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion']
          },
          {
            id: 316,
            type: 'text',
            key: 'especialidad-o-curso',
            controlType: 'textbox',
            label: 'Especialidad o curso',
            value: '',
            disabled: false,
            required: true,
            canAdd: false,
            options: [],
            minLength: 1,
            maxLength: 50,
            inReport: false,
            preloaded: false,
            isFather: false,
            dependencies: [],
            cols: 2,
            editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
            seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion']
          },
          {
            id: 317,
            type: 'date',
            key: '1er-periodo-fecha-inicio',
            controlType: 'datepicker',
            label: '1er Periodo-Fecha inicio',
            value: '',
            disabled: false,
            required: true,
            canAdd: false,
            options: [],
            minLength: 1,
            maxLength: 100,
            inReport: false,
            preloaded: false,
            isFather: false,
            dependencies: [],
            cols: 1,
            editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
            seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
          },
          {
            id: 318,
            type: 'date',
            key: '1er-periodo-fecha-fin',
            controlType: 'datepicker',
            label: '1er Periodo-Fecha fin',
            value: '',
            disabled: false,
            required: true,
            canAdd: false,
            options: [],
            minLength: 1,
            maxLength: 100,
            inReport: false,
            preloaded: false,
            isFather: false,
            dependencies: [],
            cols: 1,
            editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
            seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
          },
          {
            id: 319,
            type: 'date',
            key: '2do-periodo-fecha-inicio',
            controlType: 'datepicker',
            label: '2do Periodo-Fecha inicio',
            value: '',
            disabled: false,
            required: true,
            canAdd: false,
            options: [],
            minLength: 1,
            maxLength: 100,
            inReport: false,
            preloaded: false,
            isFather: false,
            dependencies: [],
            cols: 1,
            editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
            seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
          },
          {
            id: 320,
            type: 'date',
            key: '2do-periodo-fecha-fin',
            controlType: 'datepicker',
            label: '2do Periodo-Fecha fin',
            value: '',
            disabled: false,
            required: true,
            canAdd: false,
            options: [],
            minLength: 1,
            maxLength: 100,
            inReport: false,
            preloaded: false,
            isFather: false,
            dependencies: [],
            cols: 1,
            editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
            seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
          },
          {
            id: 388,
            type: 'text',
            key: 'instituto_name',
            controlType: 'textbox',
            label: 'Instituto de formación',
            value: '',
            disabled: false,
            required: true,
            canAdd: false,
            options: [],
            minLength: 1,
            maxLength: 50,
            inReport: false,
            preloaded: false,
            isFather: false,
            dependencies: [],
            cols: 1,
            editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
            seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion']
          },
          {
            id: 389,
            type: 'text',
            key: 'instituto_nit',
            controlType: 'textbox',
            label: 'Nit del instituto de formación',
            value: '',
            disabled: false,
            required: true,
            canAdd: false,
            options: [],
            minLength: 1,
            maxLength: 50,
            inReport: false,
            preloaded: false,
            isFather: false,
            dependencies: [],
            cols: 1,
            editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
            seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion']
          },
        ]
      },
      {
        key: 'TYPE_C',
        form: [
          {
            id: 321,
            type: 'date',
            key: 'fecha_de_vencimiento',
            controlType: 'datepicker',
            label: 'Fecha de vencimiento',
            value: '',
            disabled: false,
            required: true,
            canAdd: false,
            options: [],
            minLength: 1,
            maxLength: 100,
            inReport: false,
            preloaded: false,
            isFather: false,
            dependencies: [],
            cols: 1,
            editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
            seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
          },
          {
            id: 322,
            type: 'number',
            key: 'cuantos-meses',
            controlType: 'textbox',
            label: '¿Cuántos meses?',
            value: '',
            required: true,
            canAdd: false,
            options: [],
            minLength: 1,
            maxLength: 1000,
            inReport: false,
            preloaded: false,
            isFather: false,
            dependencies: [],
            disabled: false,
            cols: 1,
            editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
            seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
          },
          {
            id: 323,
            type: 'options',
            key: 'cesantias',
            controlType: 'dropdown-request',
            label: 'Cesantias',
            apiServe: 'rrhh',
            value: '',
            required: true,
            canAdd: false,
            options: [{ id: null, name: null }],
            minLength: 1,
            maxLength: 1000,
            inReport: false,
            preloaded: false,
            isFather: false,
            dependencies: [],
            disabled: false,
            cols: 1,
            editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
            seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
          },
          /* Cambios de acuerdo a solicitud: https://trello.com/c/TMOHZn6x/106-mj00028-cambiar-de-interfaz-campos-de-eps-y-fondo-de-pensiones-en-soul
          {
              id: 324,
              type: 'options',
              key: 'caja_de_pensiones',
              controlType: 'dropdown-request',
              label: 'Fondo de pensiones',
              apiServe:'rrhh',
              value: '',
              required: true,
              canAdd: false,
              options:[{id:null,name:null}],
              minLength: 1,
              maxLength: 1000,
              inReport: false,
              preloaded: false,
              isFather: false,
              dependencies:[],
              disabled:false,
              cols:1,
              editRoles:  ['coordinador-de-contratacion','analista-de-contratacion'],
              seeRoles: ['coordinador-de-contratacion','analista-de-contratacion'],
          }, */
        ]
      },
      {
        key: 'TYPE_D',
        form: [
          {
            id: 325,
            type: 'date',
            key: 'fecha-top-factory',
            controlType: 'datepicker',
            label: 'Fecha top factory',
            value: '',
            disabled: false,
            required: true,
            canAdd: false,
            options: [],
            minLength: 1,
            maxLength: 100,
            inReport: false,
            preloaded: false,
            isFather: false,
            dependencies: [],
            cols: 1,
            editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
            seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
          },
          /* Cambios de acuerdo a solicitud: https://trello.com/c/TMOHZn6x/106-mj00028-cambiar-de-interfaz-campos-de-eps-y-fondo-de-pensiones-en-soul
          {
              id: 326,
              type: 'options',
              key: 'caja_de_pensiones',
              controlType: 'dropdown-request',
              label: 'Fondo de pensiones',
              apiServe:'rrhh',
              value: '',
              required: true,
              canAdd: false,
              options:[{id:null,name:null}],
              minLength: 1,
              maxLength: 1000,
              inReport: false,
              preloaded: false,
              isFather: false,
              dependencies:[],
              disabled:false,
              cols:1,
              editRoles:  ['coordinador-de-contratacion','analista-de-contratacion'],
              seeRoles: ['coordinador-de-contratacion','analista-de-contratacion'],
          }, */
        ]
      },
      {
        key: 'TYPE_E',
        form: [
          {
            id: 312,
            type: 'options',
            key: 'cesantias',
            controlType: 'dropdown-request',
            label: 'Cesantías',
            apiServe: 'rrhh',
            value: '',
            required: true,
            canAdd: false,
            options: [{ id: null, name: null }],
            minLength: 1,
            maxLength: 1000,
            inReport: false,
            preloaded: false,
            isFather: false,
            dependencies: [],
            disabled: false,
            cols: 1,
            editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
            seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
          },
          {
            id: 315,
            type: 'text',
            key: 'cliente_corporativo',
            controlType: 'textbox',
            label: 'Cliente corporativo',
            apiServe: 'rrhh',
            value: '',
            required: false,
            canAdd: false,
            options: [],
            minLength: 1,
            maxLength: 50,
            inReport: false,
            preloaded: false,
            isFather: false,
            dependencies: [],
            disabled: false,
            cols: 1,
            editRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
            seeRoles: ['coordinador-de-contratacion', 'analista-de-contratacion'],
          }
        ]
      }
    ]
  }
}
