import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ModalAsignacionesComponent } from './componentes/modal-asignaciones/modal-asignaciones.component';
import { CoachingService } from '../../services/coaching.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-jefe-inmediato',
    templateUrl: './jefe-inmediato.component.html',
    styleUrls: ['./jefe-inmediato.component.sass']
})
export class JefeInmediatoComponent implements OnInit {

    formGroup: FormGroup;
    //Variable que contiene el array de informacion a cargar dentro de la tabla
	dataSource = new MatTableDataSource<any>();

    //elemento de entrada para ordenamiento de tabla
	@ViewChild(MatSort) sort: MatSort;


    displayColumns: string[] = [
        'no',
        'assigned_date',
        'name_boss',
        'doc_boss',
        'assigned_people',
        'campanas'
    ];
    pageSizeOptions = [5, 10, 25, 50, 100];

    @ViewChild(MatPaginator) paginator: MatPaginator;

    //tamaño total de registros
    length: Number = 5;
    //Cantidad de registros a mostrar por pagina
    pageSize: Number = 5;
    //Pagina de inicio
    page: Number = 1;

    filters: string = "";
    constructor(
        private fb: FormBuilder,
        public modal: MatDialog, //Variable para creacion de modal,
        private coachingService: CoachingService
    ) { }

    ngOnInit(): void {
        this.initForm();
        this.loadTable();
    }

    /**
	 * Metodo que se carga despues de inicializar la vista para eventos Sort sobre la tabla  
	 */
	ngAfterViewInit() {
		this.dataSource.sort = this.sort;
	}


    /**
     * Metodo que inicializa los campos del formulario para agregarles el control
     * @author Juan David Guerrero Vargas
     * @create_at 11/05/2022
     * @return void   
     */
    initForm(): void {
        this.formGroup = this.fb.group(
            {
                documento: new FormControl('', []),
                name_campana: new FormControl('', []),
            }
        );
    }

    /**
     * Metodo para paginacion de tabla 
     * @author Juan David Guerrero Vargas
     * @create_at 12/05/2022  
     * @param event:any ecento con los datos de paginación 
     * @return void 
     */
    pageEvent(event: any): void {
        this.pageSize = event.pageSize;
        this.page = event.pageIndex + 1;

        this.loadTable();
    }

    /**
     * Metodo que habre el modal para asignaciones de agentes a jefe inmediato
     * @author Juan David Guerrero Vargas
     * @create_at 23/05/2022
     * @return void   
     */
    openModal(): void {
        const modalRef = this.modal.open(ModalAsignacionesComponent, {
            width: '1000px',
            disableClose: true,
            data: {
                type: 'crear',
                title: 'Asignación jefe inmediato',
            }
        })
        modalRef.afterClosed().subscribe(
            (resp) => {
                this.loadTable();
            }
        );
    }

    /**
     * Metodo que carga los datos de la tabla jefe inmediato
     * @author Juan David Guerrero Vargas
     * @create_date 23/05/2022
     * @return void   
     */
    loadTable($event?:any): void {
        if($event){
            $event.preventDefault();
        }

        this.initFilters();
        this.coachingService.getListaJefeInmediato(this.filters).subscribe(
            (resp) => {

                this.dataSource.data = resp.data;
                this.length = resp.total;
                this.page = resp.current_page
                this.pageSize = resp.per_page

            }
        );
    }

    /**
     * Metodo que se encarga de cargar los filtros
     * @author Juan David Guerrero Vargas
     * @create_date 23/05/2022
     * @return void   
     */
    initFilters(): void {
        this.filters = "";
        //Validacion filtros por documento
        if (
            this.formGroup.get('documento').value !== "" &&
            this.formGroup.get('documento').value !== undefined
        ) {
            this.filters += `doc=${this.formGroup.get('documento').value}&`
        } else {
            this.filters += "";
        }

        //Validaicon filtro por campañas
        if (
            this.formGroup.get('name_campana').value !== "" &&
            this.formGroup.get('name_campana').value !== undefined
        ) {
            this.filters += `campaign=${this.formGroup.get('name_campana').value}&`;
        } else {
            this.filters += "";
        }

        //Paginacion
        this.filters += `per_page=${String(this.pageSize)}&from_page=${String(this.page)}`;

    }

    /**
     * Metodo que carga los datos de los agentes a actualizar apra el agente
     * @author Juan David Guerrero Vargas
     * @create_date 23/05/2022  
     * @param jefe:any onjeto de la iteracion con el que se relaizrn las consultas de los datos a actualzar
     * @return void 
     */
    editarAsignacion(jefe: any): void {
    
        this.coachingService.getAsignaciones(String(jefe.id)).subscribe(
            (resp) => {
                const modalRef = this.modal.open(ModalAsignacionesComponent, {
                    width: '1000px',
                    disableClose: true,
                    data: {
                        type: 'editar',
                        title: 'Asignación jefe inmediato',
                        agentesAsignados: resp
                    }
                });

                modalRef.afterClosed().subscribe(
                    (resp) => {
                        this.loadTable();
                    }
                );
                
                
            }
        );
        

       

    }


    /** Metodo que permte solo numeros ene l ingreso del input
   * @author Juan David Guerrero Vargas
   * @createdate 2021-01-27
   * @param event evento del keypress del input usuario
   */
     soloNumeros(event): any {
        let k;
        k = event.charCode;
        return ((k > 47 && k < 58));
    }

}
