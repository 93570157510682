<div class="admin-auditoria" >

    <div class="header">
  
      <h2 style="height: 33px !important;
      font: normal normal bold 45px/35px Poppins !important;
      letter-spacing: 0px !important;color: #353535 !important;opacity: 1 !important;">Eliminación de auditorías</h2>
      <br>
      <p>Aquí podrás eliminar las audotorías que se soliciten. No</p>
      <p>olvides, “El respeto y empatía son clave”.</p>
    </div>

    <app-tabla-eliminacion></app-tabla-eliminacion>

</div>