import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { EscalationService } from '../../../services/rest/escalation.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { saveAs as importedSaveAs } from 'file-saver';
import Swal from 'sweetalert2';
import { FormsRequestService } from '../../../services/rest/forms-request.service';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { ViewPlantillasComponent } from '../view-plantillas/view-plantillas.component';

@Component({
  selector: 'app-view-escalation',
  templateUrl: './view-escalation.component.html',
  styleUrls: ['./view-escalation.component.sass']
})
export class ViewEscalationComponent implements OnInit {

  formEscalamiento = new FormGroup({
    bodyHtml: new FormControl('Mensaje html', [])
  });
  estado: any;
  user: any;
  area: any;
  userDependence: any;
  resendDependence: any;
  otherEmailDependence: any;
  filteredOptionsUser: Observable<any[]>;
  rol: string;
  sectionForm: any;
  pintar: boolean = false;
  userJson: any;
  isNewClient = false;
  adjuntosPqrs: any[] = [];
  length;
  pageSize = 5;
  page = 1;
  form = null;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  escalation = null;
  externalEmailContacts = [];
  displayedColumns: string[] = ['updated_at', 'observacion_user', 'observacion_estado', 'observacion'];
  dataSource = new MatTableDataSource<any>();
  escalationTemp = null;
  customerSection = null;
  htmlTemplate = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public escalationService: EscalationService,
              private authService: AuthService,
              private destroyService: DestroyComponentService,
              private alertsService: AlertsService,
              public dialogRef: MatDialogRef<ViewEscalationComponent>,
              private formsRequestService: FormsRequestService,
              private dialog: MatDialog,
              public dialogRefViewPlantillas: MatDialogRef<ViewPlantillasComponent>) { }

  ngOnInit(): void {
    let user = this.authService.getUser();
    user.roles.forEach(rol => {
      let name = rol.split('::');
      if (name[0] === 'pqrs') {
        this.rol = name[1];
      }
    });

    this.getHistory(this.pageSize, this.page);
    this.escalationService.getEstado().subscribe((responseEsta) => {
      this.estado = responseEsta;
    });
    this.obtenerAdjuntos();
    this.getEscalation();
  }

  getEscalation(){
    this.escalationService.getEscalation(this.data.id).subscribe(res => {
      this.escalationTemp = res.pqrs[0];
      this.escalation = res.pqrs[0];
      this.externalEmailContacts = res.external_email_contacts;
      this.resendDependence = [{label: "Reenviar", idField: 5, options: this.externalEmailContacts.concat({id: 9999999, name: 'Otro'}), activators: [{id: 1, name: 'Sí'}]}];
      this.otherEmailDependence = [{label: "Correo de destino", idField: 6, options: [], activators: [{id: 9999999, name: 'Otro'}]}];
      this.formsRequestService.getData(`searchform/${this.escalation.form_id}`).subscribe(form => {
        this.form = form;

        if(this.rol != 'solucionador'){
          let section = this.form.sections;
          section.pop();
          this.form.sections = section;
        }
        this.escalationService.getAreaAndUsers(this.escalation.form_id, this.form.campaign_id).subscribe((respo) => {
          this.escalationService.getEmailsByExit().subscribe((dataEmails) => {
            this.area = respo.areas;
            this.userDependence = [];
            this.userJson = [{name: '', id: null}];
            this.area.forEach(element => {
              this.userJson = this.userJson.concat(element.users);
              this.userDependence.push({label: "Reasignar", idField: 2, options: [{name:'', id: null}].concat(element.users), activators: [{id: element.id, name: element.name}]});
            });
            this.htmlTemplate = dataEmails.emailTemplates;
            if(this.escalation.is_anonimous == 1) {
              this.customerSection = this.escalation.preguntas.sections.shift();
              this.form.sections.shift();
            }
            if ( this.escalation.preguntas) {
              for (let i = 0; i < this.form.sections.length; i ++) {
                this.escalation.preguntas.sections[i].fields.forEach((fieldQuestion) => {
                  this.form.sections[i].fields.forEach(fieldForm => {
                    if (fieldForm.preloaded) {
                      if (fieldQuestion.id === fieldForm.id) {
                        if (fieldForm.controlType === 'file'){
                          fieldForm.nameFile = fieldQuestion.nameFile;
                          fieldForm.idValue = fieldQuestion.idValue;
                          if (fieldQuestion.value !== '') fieldForm.value = fieldQuestion.value;
                        } else {
                          fieldForm.value = fieldQuestion.value;
                        }
                      }
                    }
                  });
                });
              }
              this.escalation.preguntas.sections = this.form.sections;
            }else {
              this.isNewClient = true;
              this.escalation.preguntas = { sections: this.form.sections }
            }
            this.creationForm();
          })
        });
      });
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-17
   * Creacion del json de la seccion del formulario requerida obligatoriamente para cada campaña
   */
  creationForm(): void {
    this.sectionForm = {
      "id": null,
      "fields": [
        {
          "id": 1,
          "see": true,
          "dependencies":[],
          "key": "estado",
          "cols": 1,
          "type": "options",
          "label": "Estado",
          "preloaded": false,
          "value": this.escalation.estado_id,
          "options": this.estado,
          "required": false,
          "isSon": true,
          "seeRoles": [
            "solucionador",
            "supervisor_esca",
            "administrador"
          ],
          "editRoles": [
            "solucionador",
            "supervisor_esca",
            'administrador'
          ],
          "maxLength": 20,
          "minLength": 2,
          "controlType": "dropdown"
        },
        {
          "id": 2,
          "see": true,
          "dependencies":[],
          "key": "reasignar",
          "cols": 1,
          "type": "options",
          "label": "Reasignar",
          "preloaded": false,
          "value": 1,
          "options": this.area,
          "required": false,
          "isSon": true,
          "seeRoles": [
            "solucionador",
            "supervisor_esca",
            "administrador"
          ],
          "editRoles": [
            "solucionador",
            "supervisor_esca",
            'administrador'
          ],
          "maxLength": 20,
          "minLength": 2,
          "controlType": "dropdown"
        },
        {
          "id": 3,
          "see": true,
          "dependencies":this.userDependence,
          "key": "responsable",
          "cols": 2,
          "type": "options",
          "label": "Responsable",
          "preloaded": false,
          "value": this.escalation.p_q_r_s_solucionador.id,
          "options": this.userJson,
          "required": false,
          "isSon": true,
          "seeRoles": [
            "solucionador",
            "supervisor_esca",
            "administrador"
          ],
          "editRoles": [
            "solucionador",
            "supervisor_esca",
            'administrador'
          ],
          "maxLength": 20,
          "minLength": 2,
          "controlType": "dropdown"
        },
        {
          "id": 5,
          "see": true,
          "dependencies": [],
          "key": "reenviar",
          "cols": 1,
          "type": "others",
          "label": "Reenviar",
          "preloaded": false,
          "value": 2,
          "options": [{id: 2, name: 'No'}, {id: 1, name: 'Sí'}],
          "required": false,
          "isSon": true,
          "seeRoles": [
            "solucionador",
            "supervisor_esca",
            "administrador"
          ],
          "editRoles": [
            "solucionador",
            "supervisor_esca",
            'administrador'
          ],
          "maxLength": 20,
          "minLength": 2,
          "controlType": "radiobutton"
        },
        {
          "id": 6,
          "see": true,
          "dependencies": this.resendDependence,
          "key": "correo_reenvio",
          "cols": 2,
          "type": "options",
          "label": "Correo de destino",
          "preloaded": false,
          "value": null,
          "options": this.externalEmailContacts.concat({id: 9999999, name: 'Otro'}),
          "required": false,
          "isSon": true,
          "seeRoles": [
            "solucionador",
            "supervisor_esca",
            "administrador"
          ],
          "editRoles": [
            "solucionador",
            "supervisor_esca",
            'administrador'
          ],
          "maxLength": 100,
          "minLength": 2,
          "controlType": "dropdown"
        },
        {
          "id": 7,
          "see": true,
          "dependencies": this.otherEmailDependence,
          "key": "correo_reenvio_otro",
          "cols": 1,
          "type": "text",
          "label": "Ingrese un correo",
          "preloaded": false,
          "value": null,
          "options": [],
          "required": false,
          "isSon": true,
          "seeRoles": [
            "solucionador",
            "supervisor_esca",
            "administrador"
          ],
          "editRoles": [
            "solucionador",
            "supervisor_esca",
            'administrador'
          ],
          "maxLength": 100,
          "minLength": 2,
          "controlType": "textbox"
        },
        {
          "id": 4,
          "see": true,
          "dependencies":[],
          "key": "respuestaCliente",
          "cols": 4,
          "type": "text",
          "label": "Observaciones",
          "preloaded": false,
          "value": this.escalation.observacion,
          "options": [],
          "required": false,
          "isSon": true,
          "seeRoles": [
            "solucionador",
            "supervisor_esca",
            "administrador"
          ],
          "editRoles": [
            "solucionador",
            "supervisor_esca",
            'administrador'
          ],
          "maxLength": 2000,
          "minLength": 2,
          "controlType": "textarea"
        }
      ],
      "name_section": "Gestión Escalamientos",
      "type_section": 1
    };
    if (this.escalation.preguntas) {
      this.escalation.preguntas.sections.push(this.sectionForm);
    }
    else {
      this.escalation["preguntas"] = {sections: [this.sectionForm]};
    }
    this.pintar = true;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-17
   * Metodo que trae el historico de las pqrs
   * @param size tamaño de los datos a traer
   * @param page pagina a traer
   */
  getHistory(size: number, page: number): void {
    this.escalationService.getHistory(this.data.id, size, page).subscribe((respGrid) => {
      this.dataSource.data = respGrid.data;
      this.dataSource.sort = this.sort;
      this.length = respGrid.total;
      this.pageSize = respGrid.per_page;
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-17
   * Metodo que envia el formulario ya editado
   * @param event evento que trae desde el componente del formulario dinamico
   */
  sendForm(event: any): void{
    this.escalation.preguntas.sections.forEach((element, index) => {
      element.fields.forEach((elementInt) => {
        // tslint:disable-next-line: forin
        for ( const key in event.answer[index]) {
          if ( key === elementInt.key) {
            elementInt.value = event.answer[index][key];
          }
        }
        if(elementInt.id === this.form.client_unique[0].id){
          if(element.required === false){
            if ((elementInt.value === '' || elementInt.value === null) && elementInt.type === 'text'){
              elementInt.value = 'sin-identificador';
            }
            if ((elementInt.value === '' || elementInt.value === null) && elementInt.type === 'number'){
              elementInt.value = 999999999;
            }
            if ((elementInt.value === '' || elementInt.value === null) && elementInt.type === 'email'){
              elementInt.value = 'sin-identificador@mysoul.com';
            }
          }
        }
      });
    });
    const form = new FormData();
    form.append('form_id', String(this.form.id));
    form.append('client_unique', JSON.stringify(this.form['client_unique']));
    if (this.escalation.is_anonimous === 1) {
      event.sections.unshift(this.customerSection);
      form.append('sections', JSON.stringify(event.sections));
    } else {
      form.append('sections', JSON.stringify(event.sections));
    }
    event.files.forEach((element, index) => {
      form.append(element.id, element.file);
    });
    if(this.rol == 'solucionador'){
      const urlPqrs = this.escalation.form_answer_id ? `formanswer/update/${this.escalation.form_answer_id}` : 'formanswer/saveinfo';
      this.formsRequestService.postForm(urlPqrs, form).subscribe( res => {
        if(urlPqrs === 'formanswer/saveinfo'){
          this.escalation.form_answer_id = res.data.formAsnwerId;
        }
        const sectionFormEscalamientos = event.sections.filter(section => section.name_section === 'Gestión Escalamientos');
        const fieldEstado = sectionFormEscalamientos[0].fields.find(field => field.key === 'estado');
        const nameEstado = fieldEstado.options.find(option => option.id === fieldEstado.value);
        if (nameEstado.name === 'Cerrado') {
          const dialogPlantillas = this.dialog.open(ViewPlantillasComponent, {
            data: {
              id: this.data.id,
              escalamiento: this.escalation,
              plantillas: this.htmlTemplate
            },
            autoFocus: false
          });
          dialogPlantillas.afterClosed().subscribe(() => {
            this.dialogRef.close();
          });
        } else {
          if (event.answer[1].reenviar === 1) {
            this.alertsService.alertWarning('¿Estás seguro?', 'Ten en cuenta que una vez reenviado el correo, el estado del escalamiento se cerrará')
            .then(res => {
              if (res.isConfirmed) {
                const editForm = new FormData();
                editForm.append('solucionador_id', this.escalation.solucionador_id);
                editForm.append('estado_id', this.escalation.estado_id);
                editForm.append('preguntas', JSON.stringify(this.escalation.preguntas));
                editForm.append('form_answer_id', this.escalation.form_answer_id);
                this.escalationService.postFormEscalation(editForm, this.data.id).subscribe((respo) => {
                  if (respo.length !== 0) {
                    this.alertsService.alertSuccess('Editado', 'La pqrs fue editada correctamente');
                    this.dialogRef.close();
                  }
                });
              }
            })
          } else {
            const editForm = new FormData();
            editForm.append('solucionador_id', this.escalation.solucionador_id);
            editForm.append('estado_id', this.escalation.estado_id);
            editForm.append('preguntas', JSON.stringify(this.escalation.preguntas));
            editForm.append('form_answer_id', this.escalation.form_answer_id);
            this.escalationService.postFormEscalation(editForm, this.data.id).subscribe((respo) => {
              if (respo.length !== 0) {
                this.alertsService.alertSuccess('Editado', 'La pqrs fue editada correctamente');
                this.dialogRef.close();
              }
            });
          }
        }
      });
    }else{
      const sectionFormEscalamientos = event.sections.filter(section => section.name_section === 'Gestión Escalamientos');
        const fieldEstado = sectionFormEscalamientos[0].fields.find(field => field.key === 'estado');
        const nameEstado = fieldEstado.options.find(option => option.id === fieldEstado.value);
        if (nameEstado.name === 'Cerrado') {
          const dialogPlantillas = this.dialog.open(ViewPlantillasComponent, {
            data: {
              id: this.data.id,
              escalamiento: this.escalation,
              plantillas: this.htmlTemplate
            },
            autoFocus: false
          });
          dialogPlantillas.afterClosed().subscribe(() => {
            this.dialogRef.close();
          });
        } else {
          if (event.answer[0].reenviar === 1) {
            this.alertsService.alertWarning('¿Estás seguro?', 'Ten en cuenta que una vez reenviado el correo, el estado del escalamiento se cerrará')
            .then(res => {
              if (res.isConfirmed) {
                const editForm = new FormData();
                editForm.append('solucionador_id', this.escalation.solucionador_id);
                editForm.append('estado_id', this.escalation.estado_id);
                editForm.append('preguntas', JSON.stringify(this.escalation.preguntas));
                editForm.append('form_answer_id', this.escalation.form_answer_id);
                this.escalationService.postFormEscalation(editForm, this.data.id).subscribe((respo) => {
                  if (respo.length !== 0) {
                    this.alertsService.alertSuccess('Editado', 'La pqrs fue editada correctamente');
                    this.dialogRef.close();
                  }
                });
              }
            })
          } else {
            const editForm = new FormData();
            editForm.append('solucionador_id', this.escalation.solucionador_id);
            editForm.append('estado_id', this.escalation.estado_id);
            editForm.append('preguntas', JSON.stringify(this.escalation.preguntas));
            editForm.append('form_answer_id', this.escalation.form_answer_id);
            this.escalationService.postFormEscalation(editForm, this.data.id).subscribe((respo) => {
              if (respo.length !== 0) {
                this.alertsService.alertSuccess('Editado', 'La pqrs fue editada correctamente');
                this.dialogRef.close();
              }
            });
          }
        }
    }
  }
  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * Metodo que se encarga del paginador al momento de cambiar de pagina
   * @param event evento que trae la pagina a listar
   */
   pageEvent(event: any): void {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getHistory(this.pageSize, this.page);
  }

  ngOnDestroy(): void {
    this.escalation.preguntas.sections.pop();
    this.pintar = false;
    this.destroyService.destroyComponent();
  }

  guardarAdjunto(e){
    let adjunto = e.target.files[0];
    let formData = new FormData();
    formData.append('adjunto', adjunto);
    formData.append('pqrs_id', this.data.id);
    formData.append('nombre_adjunto', adjunto.name);
    this.escalationService.savePqrsAttachment(formData).subscribe((resp) => {
      this.obtenerAdjuntos();
    }, error =>{
      Swal.fire('!Atención¡', "Ha ocurrido un error al intentar guardar el adjunto", "error");
    });

  }

  obtenerAdjuntos(){
    this.escalationService.getPqrsAttachments(this.data.id).subscribe((resp) => {
      this.adjuntosPqrs = resp.adjuntos;
    });
  }

  eliminarAdjunto(adjunto_id:any, ruta:any){
    this.escalationService.deletePqrsAttachment({id: adjunto_id, url: ruta}).subscribe((resp) => {
      this.obtenerAdjuntos();
    }, error =>{
      Swal.fire('!Atención¡', "Ha ocurrido un error al intentar eliminar el adjunto", "error");
    });
  }

  descargarAdjunto(route:any, nombre:any){
    this.escalationService.downloadEmailAttachment({url: route}).subscribe(resp => {
      importedSaveAs(resp, nombre);
    },
    error =>{
      Swal.fire('!Atención¡', "Ha ocurrido un error al intentar descargar el adjunto", "error");
    });
  }

   //printMail(){
  /*   let pdf = new jsPDF('p','mm','a4', true);
    var width = pdf.internal.pageSize.getWidth();
    let componentHTML = document.getElementById("mail-scalation").childNodes
    let encabezado = ""
    let newElementEnc = document.createElement("div")
    let emailContent = document.createElement("div")
    for (let i = 0; i < componentHTML.length; i++) {

      if(componentHTML[i].nodeName !== "APP-MAIL-ESCALATION" && componentHTML[i].nodeName !== "#comment" ){

        encabezado += componentHTML[i].textContent
        let elementTemp = document.createElement(componentHTML[i].nodeName.toLowerCase())
        elementTemp.innerHTML = componentHTML[i].textContent
        newElementEnc.appendChild(elementTemp)

      }else if (componentHTML[i].nodeName == "APP-MAIL-ESCALATION"){
        let detailEmail = document.getElementById("detail-mail").childNodes
        let titulo = detailEmail[0].childNodes[0]
        let bodyEmail = detailEmail[0].childNodes[1].childNodes[0].childNodes

        console.log("*************");
          console.log("Componente", detailEmail[0] );
          console.log("Componente[0] ", titulo );
          console.log("Componente[1] ", bodyEmail );
          console.log("ComponenteType", detailEmail[0].nodeType );
          console.log("ComponenteName", detailEmail[0].nodeName );
          console.log("ComponenteValue", detailEmail[0].nodeValue );
          console.log("*************");

      } */




    //}

  /*   newElementEnc.innerHTML = encabezado
    console.log("Creat HTML Element",  newElementEnc );
    console.log("Creat HTML Element",  newElementEnc.innerHTML );
    */
  /*     console.log("Element", document.querySelector("#mail-scalation") );

    html2canvas(document.querySelector("#mail-scalation"),{scale:3}).then(canvas => {
      let pdf = new jsPDF('p','mm','a4', true);
      var width = pdf.internal.pageSize.getWidth();
      var height = canvas.height * width / canvas.width;
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 10, 10, width - 15, height - 15);
      pdf.save('Escalamiento.pdf')
  });

 } */
 printMail(){
  const data = document.getElementById('mail-scalation');
  html2canvas(data).then((canvas:any) => {
    const PAGE_MARGIN = 10
    const imgWidth = 208;
    const pageHeight = 295;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;
    heightLeft -= pageHeight;
    const doc = new jsPDF('p', 'mm', 'a4', true);
    doc.addImage(canvas, 'PNG', 0, position, imgWidth - 15 , imgHeight - 15 , '', 'FAST');
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      heightLeft -= pageHeight;
    }
    doc.save('Escalamiento.pdf');
  });
 }


}
