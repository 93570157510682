<div class="landing">
    <div class="row" >
        <div class="col-6">
          <div class="landing-div-text">
            <br>
            <br>
            <br>
            <p class="landing-tittle">{{config.title}}</p>
            <br>
            <p class="landing-welcome">{{config.welcome}}<br> {{userName.rrhh.name | titlecase}}!</p>
            <br>
            <div class="col-8 mt-3">
              <p class="landing-sub-tittle">
                {{config.subtittle}}
              </p>
            </div>
            <br>
            
            <div style="width: 429px;">
                <button class="btnlanding"
                mat-stroked-button color="primary"
                (click)="onBoadingCalidad()"
                [disabled]="true">
                {{config.btnlanding}}
              </button>
            </div>

            <br>
            <br>
            <br>
          </div>
        </div>

        <div class="col-6" style="text-align: right;">
            <img src="../../../assets/images/landing/landing-pages/calidad/Ilustración_Calidad.svg" style="height:90%;" alt="">
        </div>
    </div>
</div>
