<div class="main-head">
  <button mat-button (click)="_location.back()" class="back-btn">
    <span class="fi-sr-angle-small-left"></span>
    <strong>Regresar &nbsp;</strong>
  </button>
  <h2>Hoja de Vida del colaborador</h2>
  <p>En este espacio podrás visualizar la información y la documentación del colaborador o de un candidato sin embargo recuerda
    que  de este último podrá faltar información, ya que no ha culminado su proceso de contratación.</p>
  <mat-tab-group (selectedTabChange)="onTabChanged($event);">
    <mat-tab label="Datos Personales">
      <shared-cv-view [person]="candidate" [canUpdateEmail]="checkCanUpdateEmail()" [candidateStatus]="candidateStatus">
      </shared-cv-view>
    </mat-tab>
    <mat-tab *ngIf="hiring || validator" label="Documentos">
      <div class="info-section">
        <mat-accordion multi>

           <!-- CERTIFICADOS -->
           <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3>Certificados del trabajador</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <table mat-table [dataSource]="candidateCertificate" matSort> <!-- Se utiliza la funcionalidad existente de certificados-->
              <ng-container *ngFor="let column of displayColumnsName; index as i">
                <ng-container matColumnDef="{{column.field}}" *ngIf="column.field == 'file'">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column.name}} </th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button matTooltip="Ver documento" [disabled]="!element.file"
                      (click)="getCertificate('certificado_laboral',element, true)">
                      <span class="fi-rr-eye"></span>
                    </button>
                    <button mat-icon-button matTooltip="Descargar documento" [disabled]="!element.file"
                      (click)="getCertificate('certificado_laboral',element, false)">
                      <span class="fi-rr-cloud-download"></span>
                    </button>
                  </td>
                </ng-container>
                <ng-container matColumnDef="{{column.field}}" *ngIf="column.field != 'file'">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column.name}} </th>
                  <td mat-cell *matCellDef="let element"> {{element[column.field]}}</td>
                </ng-container>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
            </table>
          </mat-expansion-panel>


          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3>Documentos suministrados por el trabajador</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <!--  <div fxLayoutAlign="end center">
                <button mat-button class="download-btn" (click)="downdloadFile()">
                  <strong> Descargar todos los archivos &nbsp;
                    <span class="fi-rr-download"></span></strong>
                </button>
              </div> -->
            <table mat-table [dataSource]="candidateDocuments">
              <ng-container matColumnDef="actions">
                <th class="start" mat-header-cell *matHeaderCellDef> Acciones </th>
                <td mat-cell *matCellDef="let row" style="text-align: left;">
                  <button mat-icon-button *ngIf="row.uploaded && row.name != 'No Aplica'"
                    (click)="downdloadFile(row, true)" matTooltip="Ver documento"><span
                      class="fi-rr-eye"></span></button>
                  <button mat-icon-button *ngIf="row.uploaded && row.name != 'No Aplica'"
                    matTooltip="Descargar documento" (click)="downdloadFile(row)"><span
                      class="fi-rr-cloud-download"></span></button>
                </td>
              </ng-container>
              <ng-container matColumnDef="file">
                <th mat-header-cell *matHeaderCellDef> Tipo </th>
                <td mat-cell *matCellDef="let element"> {{element.file}} </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Archivo cargado</th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>

              <ng-container matColumnDef="state">
                <th class="end" mat-header-cell *matHeaderCellDef> Estado</th>
                <td mat-cell *matCellDef="let element"> {{element.state}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>


          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3>Documentos suministrados por la operación</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <table mat-table [dataSource]="candidateOtherDocuments">

              <ng-container matColumnDef="actions">
                <th class="start" mat-header-cell *matHeaderCellDef> Acciones </th>
                <td mat-cell *matCellDef="let row" style="text-align: left;">
                  <button mat-icon-button *ngIf="row.uploaded && row.name != 'No Aplica'"
                    (click)="downdloadFile(row, true)" matTooltip="Ver documento"><span
                      class="fi-rr-eye"></span></button>
                  <button mat-icon-button *ngIf="row.uploaded && row.name != 'No Aplica'"
                    matTooltip="Descargar documento" (click)="downdloadFile(row)"><span
                      class="fi-rr-cloud-download"></span></button>
                </td>
              </ng-container>

              <ng-container matColumnDef="file">
                <th mat-header-cell *matHeaderCellDef> Tipo </th>
                <td mat-cell *matCellDef="let element"> {{element.file}} </td>
              </ng-container>


              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Archivo cargado</th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>

              <ng-container matColumnDef="state">
                <th class="end" mat-header-cell *matHeaderCellDef> Estado</th>
                <td mat-cell *matCellDef="let element"> {{element.state}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

    </mat-tab>
    <mat-tab label="Histórico">
      <div class="info-section">
        <div *ngFor="let request of candidateHistory;  last as Last ">
          <h3>{{request.requestCode}} {{request.campaign}}</h3>
          <h4>{{request.createdAt | date}}</h4>
          <div fxLayout="row" class="credentials" style="padding: 7px 0;">
            <div>
              <strong>Solicitante</strong>
              <p>{{request.applicant}}</p>
            </div>
            <div>
              <strong>Cargo</strong>
              <p>{{request.position}}</p>
            </div>
          </div>
          <div fxLayout="row wrap" style="padding-bottom:25px;">
            <div fxLayout="row" fxLayoutAlign=" center" class="history-card"
              *ngFor="let state of request.states; last as isLast">
              <mat-card [ngStyle]="{'border-color': isLast ? '#2CABBC':'#A9A9A9' }">
                <strong>Estado</strong>
                <p><strong>{{state.state}}</strong></p>
                <div fxLayout="row">
                  <strong>Responsable</strong> &nbsp; &nbsp;
                  <p>{{state.responsable}}</p>
                </div>
                <div fxLayout="row">
                  <strong>Fecha</strong> &nbsp; &nbsp;
                  <p>{{state.createdAt | date}}</p>
                </div>

              </mat-card>

              <span *ngIf="!isLast" style="color: #A9A9A9;" class="fi-rr-arrow-small-right"></span>
            </div>

          </div>
          <h4><b>Exámenes médicos</b></h4>
          <div fxLayout="row wrap" style="padding-bottom:25px;">
            <div fxLayout="row" fxLayoutAlign=" center" class="history-card" *ngFor="let test of request.medicalTest">
              <mat-card [ngStyle]="{'border-color': test.status=='Aprobado'? '#2CABBC':'#A9A9A9' }">
                <strong>Estado</strong>
                <p><strong>{{test.status}}</strong></p>
                <div fxLayout="row">
                  <strong>Prueba</strong> &nbsp; &nbsp;
                  <p>{{test.medicalTypeTest}}</p>
                </div>
              </mat-card>
            </div>
          </div>
          <h4><b>Reglas de Contratación</b></h4>
          <div fxLayout="row wrap" style="padding-bottom:25px;">
            <div fxLayout="row" fxLayoutAlign=" center" class="history-card"
              *ngFor="let agreement of request.agreementLog; last as isLast2">
              <mat-card [ngStyle]="{'border-color': agreement.action.name=='Aprobado'? '#2CABBC':'#A9A9A9' }">
                <strong>Estado</strong>
                <p><strong>{{agreement.action.name}}</strong></p>
                <div fxLayout="column" *ngIf="agreement.action.comment">
                  <strong>Comentario</strong>
                  <p>{{agreement.action.comment}}</p>
                </div>
                <div fxLayout="row">
                  <strong>Responsable</strong> &nbsp; &nbsp;
                  <p>{{agreement.responsable}}</p>
                </div>
                <div fxLayout="row">
                  <strong>Fecha</strong> &nbsp; &nbsp;
                  <p>{{agreement.created_at | date}}</p>
                </div>

              </mat-card>
              <span *ngIf="!isLast2" style="color: #A9A9A9;" class="fi-rr-arrow-small-right"></span>
            </div>
          </div>
          <hr *ngIf="Last">

        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="hiring" label="Proceso de contratación">
      <div class="info-section" fxL ayout="column">
        <div class="section-action">
          <h3>Ver Contrato, acuerdos y documentos</h3>
          <hr>
          <p>Recuerda que para poder ver <b>los documentos</b>, estos deben estar completos y validados por el equipo de <b>RRHH</b>.</p>

          <table mat-table [dataSource]="documentGeneratedByOperation">

            <ng-container matColumnDef="actions">
              <th class="start" mat-header-cell *matHeaderCellDef> Acciones </th>
              <td mat-cell *matCellDef="let row" style="text-align: left;">
                <button mat-icon-button *ngIf="row.uploaded" (click)="downdloadFile(row, true)"
                  matTooltip="Ver documento"><span class="fi-rr-eye"></span></button>
                <button mat-icon-button *ngIf="row.uploaded" matTooltip="Descargar documento"
                  (click)="downdloadFile(row)"><span class="fi-rr-cloud-download"></span></button>
              </td>
            </ng-container>


            <ng-container matColumnDef="file">
              <th mat-header-cell *matHeaderCellDef> Tipo </th>
              <td mat-cell *matCellDef="let element"> {{element.file}} </td>
            </ng-container>


            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Archivo cargado</th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th class="end" mat-header-cell *matHeaderCellDef> Estado</th>
              <td mat-cell *matCellDef="let element"> {{element.state}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>

    </mat-tab>
    <mat-tab label="Histórico de cambios">

      <p style="margin-top:48px;"><b>Nota: </b>En este espacio podrás visualizar las actualizaciones realizados en la hoja de vida.</p>

      <table mat-table [dataSource]="dataSource2" class="mat-elevation-z8">

        <ng-container matColumnDef="date">
          <th class="start" mat-header-cell *matHeaderCellDef> Fecha de actualización </th>
          <td mat-cell *matCellDef="let element"> {{element.date}} </td>
        </ng-container>
      
        <ng-container matColumnDef="hour">
          <th mat-header-cell *matHeaderCellDef> Hora de actualización </th>
          <td mat-cell *matCellDef="let element"> {{element.hour}} </td>
        </ng-container>
      
        <ng-container matColumnDef="responsible">
          <th mat-header-cell *matHeaderCellDef> Nombre responsable del cambio </th>
          <td mat-cell *matCellDef="let element"> {{element.responsible}} </td>
        </ng-container>
      
        <ng-container matColumnDef="update">
          <th class="end" mat-header-cell *matHeaderCellDef> Actualización </th>
          <td mat-cell *matCellDef="let element"> {{element.updating}} </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

      </table>
      <mat-paginator [length]="length2" [pageSize]="pageSize2" [pageSizeOptions]="pageSizeOptions2"
      (page)="getPaginatorData($event)" showFirstLastButtons></mat-paginator>
    </mat-tab>

    <mat-tab *ngIf="hiring" label="Desprendibles">
      <div class="row mt-3">
        <mat-form-field appearance="fill" class="col-3 pr-3">
          <mat-label>Certificados Disponibles</mat-label>
          <mat-select [formControl]="selectCertifications" #input autocomplete="off">
            <mat-option *ngFor="let item of certificationsType" [value]="item.key">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-3 pr-3" *ngIf="selectCertifications.value == 'desprendible-de-pago'">
          <mat-label>Año</mat-label>
          <mat-select [formControl]="selectYear" #input autocomplete="off">
            <mat-option *ngFor="let item of years" [value]="item.id">
              {{item?.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row col-12" *ngIf="selectCertifications.value == 'certificado-laboral'">
        <div *ngIf="laborCertificates.length < 1" >No hay certificados disponibles.</div>
        <div class="col-3" style="padding-bottom: 24px;" *ngFor="let item of laborCertificates">
          <mat-card class="form-card" (click)="openCertification(selectCertifications.value, item.rrhh_id,'','')">
            <div class="row">
              <div class="row col-12">
                <div class="col-3 row" style="padding: 0px; position:relative;">
                  <img src="../../../../../../assets/images/calendar.svg">
                </div>

                <mat-card-header class="card-view col">
                  <mat-card-title>
                    <p style="font-size: 12px">Fecha Ingreso {{item?.start | date:'yyyy/MM/dd' }}</p>
                  </mat-card-title>
                  <mat-card-subtitle>
                    <p style="font-size: 20px; color: #51B0CB;">{{item.stateName}}</p>
                  </mat-card-subtitle>
                </mat-card-header>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
      <div class="row col-12" *ngIf="selectCertifications.value == 'desprendible-de-pago'">
        <div *ngIf="paymentCertificates.length < 1" >No hay certificados disponibles.</div>
        <div class="col-3" style="padding-bottom: 24px;" *ngFor="let item of paymentCertificates">
          <mat-card class="form-card" (click)="openCertification(selectCertifications.value, '',item.month, item.year)">
            <div class="row">
              <div class="row col-12">
                <div class="col-3 row" style="padding: 0px; position:relative;">
                  <img src="../../../../../../assets/images/calendar.svg">
                </div>
                <mat-card-header class="card-view col">
                  <mat-card-title>
                    <p style="font-size: 12px">Desprendible mes</p>
                  </mat-card-title>
                  <mat-card-subtitle>
                    <p style="font-size: 20px; color: #51B0CB;">{{getNameMount(item.month)}} </p>
                  </mat-card-subtitle>
                </mat-card-header>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
      
    </mat-tab>
  </mat-tab-group>
</div>
