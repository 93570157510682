import { DisabilitiesService } from 'src/app/modules/nomina/services/rest/disabilities.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { NoveltiesService } from 'src/app/modules/nomina/services/novelties.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-disabilities-response',
  templateUrl: './disabilities-response.component.html',
  styleUrls: ['./disabilities-response.component.sass']
})
export class DisabilitiesResponseComponent implements OnInit {

  dataInfo: any;
  public realTime: string;
  public scheduleTime: string;
  public endTime: string;
  public noveltyDetailHeight: number;

  public showJustifyForm: boolean;
  public showJustifyInfo: boolean;
  public showInput: boolean;
  noveltiesForm: FormGroup;
  dateForm: FormGroup;

  options:any = [
    {id: 0, name: 'Rechazar'},
    {id: 1, name: 'Aprobar'}
  ];
  selectedOption: any;
  subTypes: any;

  startDate: any;
  endDate: any;
  fieldate: boolean = false;
  files: any = [];

  constructor(
    private disabilities: DisabilitiesService,
    public dialogRef: MatDialogRef<DisabilitiesResponseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alert: AlertsService,
    public dialog: MatDialog,
    
  ) { }

  ngOnInit() {
    console.log(this.data.data)
    this.noveltiesForm = new FormGroup({
      state: new FormControl('', [Validators.required]),
      response: new FormControl('', [Validators.required]),
      novelty_subtypes_id: new FormControl('')
    });
    
    this.dateForm = new FormGroup({
      start: new FormControl(''),
      end: new FormControl('')
    });

    this.disabilities.getSubTypes().subscribe( resp => {
      this.subTypes = resp.data;
    });

    this.disabilities.getEventInfoByPayrollNoveltyId(this.data.data.idNovelty).subscribe(resp => {
      this.dataInfo = resp.data;
      this.files = resp.data.files;
      console.log(this.dataInfo);
      this.dataInfo.novelty_type.key =  (this.dataInfo.novelty_type.key < 10) ? `0${this.dataInfo.novelty_type.key}` : this.dataInfo.novelty_type.key;

      //console.log(resp.data);
      if (this.dataInfo.validForMoreResponses) {
        this.showJustifyForm = true;
      }
      this.showJustifyInfo = true;
      this.changeDetailHeight(this.dataInfo);
    });
  }

   /**
   * @author Daniel Dominguez 
   * @createdate 2021-05-10
   * Metodo para asignacion de detalle en primera instancia
   */
      changeDetailHeight(novelty): void {
        if (novelty.responseFirstInstance && (novelty.responseFirstInstance.justify || novelty.responseFirstInstance.response)) { this.noveltyDetailHeight = 240; }
      }
   /**
   * @author Daniel Dominguez 
   * @createdate 2021-05-12
   * Metodo para detectar la opcion seleccionada
   */
    changeState(e){
      this.selectedOption = e.value;
      console.log(this.selectedOption)
    }
   /**
   * @author Daniel Dominguez 
   * @createdate 2021-05-12
   * Metodo para actualizar el estado de la novedad
   */
    UpdateState(){
      const body = {
        state: this.noveltiesForm.controls['state'].value,
        response: this.noveltiesForm.controls['response'].value,
        noveltyDate: this.startDate,
        noveltyDateEnd: this.endDate
      }
      console.log(this.noveltiesForm.value)

      this.disabilities.changeStatus(this.data.data.idNovelty, body).subscribe(resp => {
        if(this.selectedOption == 1){
          this.alert.alertSuccessWithoutButton('¡Excelente!', 'Su solicitud ha sido enviada satisfactoriamente');
          this.dialogRef.close('');
        }else {
          this.alert.alertSuccessWithoutButton('¡Excelente!', 'Su solicitud ha sido enviada satisfactoriamente');
          this.dialogRef.close('');
        }
  
      }, error => {
        this.alert.alertError('Error!', 'Ocurrio un error al intentar actualizar la novedad');
      });
    }

       /**
   * @author Daniel Dominguez 
   * @createdate 2021-05-27
   * Metodo para actualizar el estado de la novedad
   */
    dateRangeChange() {
      const date1: Date = this.dateForm.controls.start.value;
      const date2: Date = this.dateForm.controls.end.value;
      const dateStart: string|null = new DatePipe('en-US').transform(date1, 'yyyy-MM-dd');
      const dateEnd: string|null = new DatePipe('en-US').transform(date2, 'yyyy-MM-dd');
      this.startDate = dateStart;
      this.endDate = dateEnd;
    }

    show(){
      if(this.fieldate == false){
        this.fieldate = true;
      }else{
        this.fieldate = false;
      }
    }

    download(path: any){
     // const path = "https://people.sc.fsu.edu/~jburkardt/data/csv/addresses.csv";
     console.log(path)
      window.open(path);
    }
}
