import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/shared/material/material.module';

import { PermitBossRoutingModule } from './permit-boss-routing.module';
import { PermitResponseBossComponent } from './components/permit-response-boss/permit-response-boss.component';
import { PermitViewBossComponent } from './components/permit-view-boss/permit-view-boss.component';
import { PermitListBossComponent } from './components/permit-list-boss/permit-list-boss.component';


@NgModule({
  declarations: [PermitResponseBossComponent, PermitViewBossComponent, PermitListBossComponent],
  imports: [
    CommonModule,
    MaterialModule,
    PermitBossRoutingModule
  ]
})
export class PermitBossModule { }
