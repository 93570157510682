import { Component, OnInit } from '@angular/core';
import { SolicitudesService } from '../../../services/rest/solicitudes.service';
import { DestroyComponentService } from '../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { MatDialog } from '@angular/material/dialog';
import { CrearSolicitudComponent } from '../componentes/crear-solicitud/crear-solicitud.component';
import { Solicitud } from '../../Class/solicitud';
import { AuthService } from '../../../../../core/services/rest/auth.service';
import { AprobarSolcitudComponent } from '../componentes/aprobar-solcitud/aprobar-solcitud.component';

@Component({
  selector: 'app-solicitudes-cards',
  templateUrl: './solicitudes-cards.component.html',
  styleUrls: ['./solicitudes-cards.component.sass']
})
export class SolicitudesCardsComponent implements OnInit {
  user                  : any;
  length                = 0;
  rol                   = 1;
  pageSize              = 8;
  page                  = 1;
  isPage                = 1;
  pageSizeOptions       : number[] = [4, 8, 24, 100];
  newRolComponent       : boolean = false;
  requestStates         : any = [];
  filterDate            : string = null;
  filterStates          : any[] = null;
  state_block_btnCreate : boolean =false;
  public solicitudes    : [] =[];

  constructor(
    private solicitudService    : SolicitudesService,
    private destroyService      : DestroyComponentService,
    private alertsService       : AlertsService,
    private authService         : AuthService,
    public dialog               : MatDialog
    ) { }

  ngOnInit(): void {
    this.solicitudService.validatorsBlocks().subscribe(resp=>{
      let exist_block = resp.data.exist_block
      if(exist_block){
        this.alertsService.alertQuestion(
          "<div style='margin-top: 32px'><b>¡Ten en cuenta!</b></div>",
          `<div style='padding-left: 50px;padding-right: 50px;padding-bottom: 10px'>Señor usuario, la plataforma estará en cierre de facturación del ${resp.data.dateIni} a ${resp.data.dateEnd}. Si llega a presentar una solicitud con prioridad por favor comuníquese con <b>compras@groupcos.com.co</b> para evaluar la prioridad y sea tomada la solicitud dentro de los tiempos de cierre de facturación.<br><br>Recuerde que después de la fecha de cierre podrá seguir montando solicitudes.</div>`
        )
        this.state_block_btnCreate = exist_block
      }
    })

    this.user=this.authService.getUser().rrhh_id;
    this.solicitudService.getRequestStates().subscribe(resp => {
      this.requestStates = resp.data;
    });
   this.getSolicitudes();
   localStorage.removeItem('filter_request')
  }


  getSolicitudes(){
    if(this.filterStates !== null) for (let index = 0; index < this.filterStates.length; index++) this.filterStates = this.filterStates[index] == 12 ? [] : this.filterStates;
    const params = {
      isPage    : this.isPage,
      size      : this.pageSize,
      page      : this.page,
      states    : this.filterStates,
      fecha     : this.filterDate,
      rol       : this.rol
    }
    this.solicitudService.getSolicitudesUser( this.user,  params)
      .subscribe(resp =>{
        this.solicitudes    = resp.data.data.sort((a, b) => {
          if (Number(a.id) > Number(b.id)) {
            return -1;
          }
          if (Number(a.id) < Number(b.id)) {
            return 1;
          }
          return 0;
        });
        this.length         = resp.data.total;
        this.pageSize       = resp.data.per_page;
    });
  }
  newSolicitud(){
    const dialogNewRol = this.dialog.open(CrearSolicitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Crear Solicitud',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      //this.pageSize=5;
      this.ngOnInit();
    });
  }

  viewSolicitud(solicitud: Solicitud){

    const dialogNewRol = this.dialog.open(AprobarSolcitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'view',
        viewInfo: false,
        title: 'Detalle solicitud',
        solicitud,
        rol : 'solicitante'
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      //this.pageSize=5;
      //this.ngOnInit();
    });
  }

  applyFilter($event){

  }

  filterBydate(event) {
    this.filterDate = (event.target as HTMLInputElement).value;
    this.getSolicitudes();
  }

  filterByState(event) {
    this.filterStates = event;
    this.getSolicitudes();

  }

    /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que se encarga del movimiento del paginador
   * @param event cuando se da click en el paginador trae este evento
   */
     pageEvent(event: any): void {

      this.pageSize = event.pageSize;
      this.page = event.pageIndex + 1;
      this.getSolicitudes();

    }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

}
