import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PositionsViewComponent, PositionsViewDialog } from './positions-view/positions-view.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { PositionCreateComponent } from './position-create/position-create.component';
import { CreateChargeComponent } from './create-charge/create-charge.component';



@NgModule({
  declarations: [PositionsViewComponent, PositionCreateComponent, PositionsViewDialog, CreateChargeComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule
  ]
})
export class PositionsModule { }
