<h2 mat-dialog-title>{{data.title}}
  <button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>
</h2>

<form class="row form" [formGroup]="userForm" (ngSubmit)="editUser(userForm)" style="padding-left: 10px;">

  <mat-dialog-content style="padding-left: 3%;" class="mat-typography">

    <div class="col-12">
      <mat-form-field class="col-4" appearance="fill">
        <mat-label>Contraseña</mat-label>
        <input appBlockCopyPaste matInput (change)="validatorEvent()" (keypress)="omitSpecialChar($event)"
          [type]="hide ? 'password' : 'text'" matInput formControlName="password">
        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.minlength">¡Aún falta! Recibimos
            mínimo 5 caracteres</span></mat-error>
        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.maxlength">¡Cuidado! Solo puedes
            tener un máximo de 15 caracteres</span></mat-error>
        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasNumber">Su contraseña debe tener
            un número</span></mat-error>
        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasCapitalCase">Su contraseña debe
            tener una mayuscula</span></mat-error>
        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasSmallCase">Su contraseña debe
            tener una minuscula</span></mat-error>
        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasSpecialCharacters">Su contraseña
            debe tener un caracter especial</span></mat-error>
        <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
        </a>
      </mat-form-field>

      <mat-form-field class="col-4" style="padding: 1%;" appearance="fill">
        <mat-label>Teléfono Celular</mat-label>
        <span matPrefix>+&nbsp;</span>
        <input matInput (keypress)="omitSpecialCharPhone($event)" (keyup)="enableCheckBox()" type="text"
          formControlName="mobile_phone" [(ngModel)]="data.user.phone" />
        <mat-error *ngIf="error.mobile_phone.errors"><span *ngIf="error.mobile_phone.errors.minlength">¡Aún falta!
            Recibimos mínimo 10 caracteres</span></mat-error>
        <mat-error *ngIf="error.mobile_phone.errors"><span *ngIf="error.mobile_phone.errors.maxlength">¡Cuidado! Solo
            puedes tener un máximo de 15 caracteres</span></mat-error>
      </mat-form-field>

      <mat-form-field class="col-4" style="padding: 1%;" appearance="fill">
        <mat-label>Correo</mat-label>
        <input matInput type="email" formControlName="email" (keyup)="enableCheckBox()" [(ngModel)]="data.user.email" />
        <mat-error *ngIf="error.email.errors"><span *ngIf="error.email.errors.minlength">¡Aún falta! Recibimos mínimo 7
            caracteres</span></mat-error>
        <mat-error *ngIf="error.email.errors"><span *ngIf="error.email.errors.maxlength">¡Cuidado! Solo puedes tener un
            máximo de 50 caracteres</span>
          <mat-error *ngIf="error.email.errors"><span *ngIf="error.email.errors.email">¡Advertencia! Debe ingresar un
              correo valido</span></mat-error>
        </mat-error>
      </mat-form-field>
      <section class="col-4" style="padding: 1%;" appearance="fill">
        <mat-checkbox formControlName="dobAuth" (change)="DobAuthValidate($event)" [(ngModel)]="dobAuthCheck"
          [disabled]="!enableCheck">¿Requiere doble autentificación?</mat-checkbox>
      </section>
    </div>
    <br><br>
    <div formArrayName="appxrol" class="row col-12">

      <h3>Editar permisos</h3>

      <div class="col-6" *ngFor="let applet of appxrol.controls; let i = index;">

        <mat-card style="background-color: #F0F0F0;">

          <div class="row col-12" [formGroupName]="i">
            <mat-form-field style="padding: 1%;" class="col-5" appearance="fill">
              <mat-label>Aplicación</mat-label>
              <input type="text" matInput formControlName="application_id" [matAutocomplete]="auto" required
                (focus)="getFilter(i)">
              <mat-autocomplete (optionSelected)="appSelected($event.option.value, i)" #auto="matAutocomplete"
                [displayWith]='displayFn.bind(this)' required>
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
              <mat-error>¡Advertencia! Este campo es requerido</mat-error>
            </mat-form-field>

            <mat-form-field style="padding: 1%;margin-left:5px" class="col-5" appearance="fill">
              <mat-label>Rol</mat-label>
              <mat-select formControlName="role_id" (selectionChange)="verifyRoles(i)" required>
                <mat-option *ngFor="let rol of roles[i]" [value]="rol.id">
                  {{rol.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="error.appxrol.controls[i].controls.role_id.errors"><span
                  *ngIf="error.appxrol.controls[i].controls.role_id.errors.required">¡Advertencia! Este campo es
                  requerido</span></mat-error>
              <mat-error *ngIf="error.appxrol.controls[i].controls.role_id.errors"><span
                  *ngIf="error.appxrol.controls[i].controls.role_id.errors.invalidRol">¡Advertencia! Este rol ya fue
                  seleccionado para esta aplicación</span></mat-error>
            </mat-form-field>
            <div class="col-1">
              <button mat-icon-button (click)="deleteappxrol(i)" style="color:#BE0F0F;font-size: 20px;"><i
                  class="fi-rr-trash" style="position:absolute"></i></button>
            </div>

          </div>



        </mat-card>
        <br>
        <br>
      </div>

    </div>

    <div class="col-6">
      <button mat-stroked-button type="button" class="col-4" color="primary" style="border-color: #2CABBC;"
        (click)="newappxrol()"> Añadir <i class="fi-rr-add"></i></button>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions style="height: 48px; padding-bottom: 70px;" align="end">
    <button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button mat-raised-button type="submit" color="primary" disabled="{{ userForm.invalid }}">Guardar</button>
  </mat-dialog-actions>

</form>