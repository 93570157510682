export const secondSection=[
    {
        id: 105,
        type: 'date',
        key: 'expedition_date_id',
        controlType: 'datepicker',
        label: 'Fecha expedición doc.',
        value: '',
        disabled: true,
        required: true,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 50,
        cols:1,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
    },
    {
        id: 106,
        type: 'text',
        key: 'city_document_id',
        controlType: 'textbox',
        label: 'Ciudad exped. doc.',
        value: '',
        disabled: true,
        required: true,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
        cols:1,
    },
    {
        id: 107,
        type: 'options',
        key: 'gender',
        controlType: 'dropdown',
        label: 'Género',
        value: '',
        required: false,
        canAdd: false,
        options:[ {id:'Femenino', name:'Femenino'},
                  {id:'Masculino', name:'Masculino'}, 
                ],
        minLength: 1,
        maxLength: 20,
        inReport: true,
        preloaded: false,
        isFather: true,
        dependencies:[],
        disabled:false,
        cols:1,
    },
    {
        id: 108,
        type: 'options',
        key: 'military_situation',
        controlType: 'dropdown',
        label: 'Situación Militar definida',
        value: '',
        required: false,
        canAdd: false,
        options:[ {id:'Si', name:'Si'},
                  {id:'No', name:'No'}
                ],
        minLength:1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: true,
        dependencies:[{idField:107, name:'Masculino'}],
        disabled:false,
        cols:1,
    },
    {
        id: 109,
        type: 'options',
        key: 'notebook_class',
        controlType: 'dropdown',
        label: 'Libreta clase',
        value: '',
        disabled: false,
        required: false,
        canAdd: false,
        options:[
            {id:'Primera categoría', name:'Primera categoría'},
            {id:'Segunda categoría', name:'Segunda categoría'},
        ],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[{idField:108, name:'Si'}],
        cols:1,
    },
    {
        id: 110,
        type: 'number',
        key: 'notebook_number',
        controlType: 'textbox',
        label: 'Número de Libreta',
        value: '',
        disabled: false,
        required: true,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[{idField:108, name:'Si'}],
        cols:1,
    },
    {
        id: 111,
        type: 'text',
        key: 'military_district_number',
        controlType: 'textbox',
        label: 'Distrito Militar',
        value: '',
        disabled: false,
        required: true,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[{idField:108, name:'Si'}],
        cols:1,
    },
    
    {
        id: 112,
        type: 'number',
        key: 'children_number',
        controlType: 'textbox',
        label: 'Número de hijos',
        value: '',
        disabled: false,
        required: true,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
        cols:1,
    },
    {
        id: 113,
        type: 'options',
        key: 'rh',
        controlType: 'dropdown',
        label: 'RH',
        value: '',
        disabled: true,
        required: false,
        canAdd: false,
        options:[
            {id:'Positivo', name:'Positivo'},
            {id:'Negativo', name:'Negativo'}, 
        ],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
        cols:1,
    },
    {
        id: 114,
        type: 'options',
        key: 'blood_type',
        controlType: 'dropdown',
        label: 'Tipo de sangre',
        value: '',
        disabled: true,
        required: true,
        canAdd: false,
        options:[
            {id:'A', name:'A'},
            {id:'B', name:'B'}, 
            {id:'O', name:'O'},
            {id:'AB', name:'AB'}, 
        ],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
        cols:1,
    },
    
    {
        id: 115,
        type: 'text',
        key: 'nationality',
        controlType: 'textbox',
        label: 'Nacionalidad',
        value: '',
        disabled: false,
        required: true,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
        cols:1,
    },
    {
        id: 116,
        type: 'text',
        key: 'neighborhood',
        controlType: 'textbox',
        label: 'Barrio',
        value: '',
        disabled: false,
        required: true,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
        cols:1,
    },
    {
        id: 117,
        type: 'text',
        key: 'hobbie',
        controlType: 'textbox',
        label: 'Hobbie',
        value: '',
        disabled: false,
        required: true,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 100,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
        cols:1,
    },
    {
        id: 118,
        type: 'number',
        key: 'pants_size',
        controlType: 'textbox',
        label: 'Talla de pantalón',
        value: '',
        disabled: false,
        required: false,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
        cols:1,
    },
    {
        id: 119,
        type: 'text',
        key: 'shirt_size',
        controlType: 'textbox',
        label: 'Talla de camisa',
        value: '',
        required: false,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
        disabled:false,
        cols:1,
    },
    {
        id: 120,
        type: 'number',
        key: 'shoe_size',
        controlType: 'textbox',
        label: 'Talla de zapatos',
        value: '',
        disabled: false,
        required: true,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
        cols:1,
    },
    {
        id: 121,
        type: 'text',
        key: 'eps',
        controlType: 'textbox',
        label: 'EPS',
        value: '',
        required: false,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
        disabled:true,
        cols:1,
    },
    {
        id: 122,
        type: 'text',
        key: 'afc',
        controlType: 'textbox',
        label: 'AFC',
        value: '',
        disabled: true,
        required: false,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
        cols:1,
    },
    {
        id: 123,
        type: 'text',
        key: 'arp',
        controlType: 'textbox',
        label: 'ARP',
        value: '',
        disabled: true,
        required: false,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
        cols:1,
    },
    {
        id: 124,
        type: 'options',
        key: 'vehicle',
        controlType: 'dropdown',
        label: 'Tiene vehículo',
        value: '',
        disabled: false,
        required: true,
        canAdd: false,
        options:[
            {id:'Si', name:'Si'},
            {id:'No', name:'No'}, 
        ],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: true,
        dependencies:[],
        cols:1,
    },
    {
        id: 125,
        type: 'options',
        key: 'vehicle_type',
        controlType: 'dropdown',
        label: 'Tipo de vehículo',
        value: '',
        disabled: false,
        required: true,
        canAdd: false,
        options:[
            {id:'Automovil', name:'Automovil'},
            {id:'Motocicleta', name:'Motocicleta'}, 
        ],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[{idField:124, name:'Si'}],
        cols:1,
    },
    {
        id: 126,
        type: 'text',
        key: 'licence_number',
        controlType: 'textbox',
        label: 'Número de licencia',
        value: '',
        disabled: false,
        required: true,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[{idField:124, name:'Si'}],
        cols:1,
    },
    {
        id: 127,
        type: 'options',
        key: 'licence_type',
        controlType: 'dropdown',
        label: 'Tipo de licencia',
        value: '',
        disabled: false,
        required: true,
        canAdd: false,
        options:[
            {id:'A2', name:'A2'},
            {id:'B1', name:'B1'},
            {id:'B2', name:'B2'},
            {id:'B3', name:'B3'},
            {id:'C1', name:'C1'},
            {id:'C2', name:'C2'},
            {id:'C3', name:'C3'},
        ],
        minLength: 1,
        maxLength: 50,
        cols:1,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[{idField:124, name:'Si'}],
      
    },
    {
        id: 128,
        type: 'text',
        key: 'place_of_birth',
        controlType: 'textbox',
        label: 'Lugar de nacimiento',
        value: '',
        disabled: true,
        required: true,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
        cols:1,
    },
    {
        id: 129,
        type: 'text',
        key: 'city_of_residence',
        controlType: 'textbox',
        label: 'Ciudad de residencia',
        value: '',
        disabled: false,
        required: false,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
        cols:1,
    },
    {
        id: 130,
        type: 'text',
        key: 'emergency_contact',
        controlType: 'textbox',
        label: 'Contacto de emergencia',
        value: '',
        disabled: false,
        required: true,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
        cols:1,
    },
    {
        id: 131,
        type: 'number',
        key: 'emergency_number',
        controlType: 'textbox',
        label: 'Número contacto emerg.',
        value: '',
        disabled: false,
        required: false,
        canAdd: false,
        options:[],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
        cols:1,
    },
    {
        id: 132,
        type: 'options',
        key: 'social_group',
        controlType: 'dropdown',
        label: '¿Pertenece a algún grupo?',
        value: '',
        disabled: false,
        required: true,
        canAdd: false,
        options:[
            {id:1, name:'Ninguno'},
            {id:2, name:'Mujer cabeza de hogar'},
            {id:3, name:'Hombre cabeza de hogar'},
            {id:2, name:'Comunidad LGBTI+Q'},
            {id:1, name:'Victima del conflicto armado'},
            {id:2, name:'Afrodecendiente'}, 
            {id:2, name:'Indigena'}, 
        ],
        minLength: 1,
        maxLength: 50,
        inReport: true,
        preloaded: false,
        isFather: false,
        dependencies:[],
        cols:1,
    },
  ]
export const civilStates = [
    {id: 1, name: 'Soltera(o)'},
    {
      id: 2,
      name: 'Casada(o)'
    },
    {
      id: 3,
      name: 'Unión libre'
    },
    {
      id: 4,
      name: 'Separada(o)'
    },
    {
      id: 5,
      name: 'Divorciada(o)'
    },
    {
      id: 6,
      name: 'Religiosa(o)'
    },
    {
      id: 7,
      name: 'No definida(o)'
    },
    {
      id: 8,
      name: 'Viuda(o)'
    },
    {
      id: 9,
      name: 'Indifernte'
    }
  ];
export const education={
    currently_studying: null,
    education_level: null,
    finish_date: null,
    id: new Date().getTime(),
    institution: null,
    obtained_title: null,
    periods_completed: null,
    study_city: null,
}

export const jobs_references={
    id: new Date().getTime(),
    company_name: null,
    company_phone: null,
    position_hired: null,
    company_city: null,
    boss_name: null,
    boss_phone: null,
    boss_position: null,
    boss_mobile_phone: null,
    current_job: null,
    start_date: null,
    end_date: null,
    earned_salary: null,
    retirement_reason: null,
    functions_achievements: null
}

export const personal_references={
    id: new Date().getTime(),
    first_name:null,
    last_name:null,
    occupation:null,
    relationship: null,
    mobile_phone: null,
    city:null,
}