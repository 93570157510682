import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TemplatesService} from '../../../services/templates.service';
import {DatePipe} from '@angular/common';
import {map} from 'rxjs/operators';
import {ProfileService} from '../../../../ciu/services/rest/profile.service';
import {TemplateNewComponent} from '../template-new/template-new.component';
import {TemplatePreviewComponent} from '../template-preview/template-preview.component';

@Component({
  selector: 'app-templates-list',
  templateUrl: './templates-list.component.html',
  styleUrls: ['./templates-list.component.sass']
})
export class TemplatesListComponent implements OnInit {

  templates: any;
  template: any;
  displayedColumns: string[] = ['path', 'name', 'version', 'updated_at', 'responsable'];
  dataSource: any;

  constructor(
    private templateService: TemplatesService,
    private datePipe: DatePipe,
    private profileService: ProfileService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getTemplates();

    this.templateService.refreshTemplates$.subscribe(() => {
      this.getTemplates();
    });
  }

  getTemplates() {
    this.templateService.getTemplates().pipe(
      map((resp: any[]) => {
        return resp.map(item => {
          item.created_at = this.formatDate(item.created_at);
          item.updated_at = this.formatDate(item.updated_at);
          this.profileService.dataUser(item.responsable_id).subscribe((response => {
            item.responsable = (response as any).fullname;
          }));
          return item;
        });
      })
    ).subscribe((formattedResp: any[]) => {
      this.dataSource = formattedResp;
    });
  }

  formatDate(dateStr: string): string {
    if (!dateStr) { return ''; }
    const date = new Date(dateStr);
    return this.datePipe.transform(date, 'dd/MM/yyyy') || '';
  }

  preview(contract_template_id: any) {
    this.templateService.showTemplate(contract_template_id).subscribe(resp => {
      this.template = resp;
      const filepath = this.templateService.server.replace('/api/', '') + '/storage/previews/' + this.template.pdf_url;

      const dialogRef = this.dialog.open(TemplatePreviewComponent, {
        width: '800px',
        data: {filepath: filepath},
      });
    });
  }

  upload(contract_type_id: any) {
    const dialogRef = this.dialog.open(TemplateNewComponent, {
      width: '600px',
      data: {contract_type_id: contract_type_id},
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getTemplates();
    });
  }
}
