<h2 mat-dialog-title>{{data.title}}
    <button style="float: right;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
</h2>

<mat-dialog-content class="mat-typography">

    <p>
        Lorem ipsum dolor sit amet consectetur adipiscing elit vehicula, class nisl quis imperdiet integer quam iaculis nam ad, platea per neque ut nisi nec dictum. Nam faucibus luctus primis posuere pharetra elementum, curabitur arcu hendrerit sociosqu. Turpis faucibus tempor nisi pretium ornare facilisis accumsan vulputate, bibendum tellus inceptos aptent dictum magnis rutrum, est imperdiet himenaeos lectus suscipit nostra pellentesque.

Quis felis tellus placerat lobortis ultrices quam suspendisse fusce, conubia posuere massa ad iaculis tincidunt duis, ridiculus enim volutpat luctus interdum tempor donec. Auctor metus neque pulvinar ultrices iaculis convallis nullam eros vulputate vestibulum, mus dis nostra augue lobortis semper cras in urna venenatis, parturient lacinia sed turpis commodo fames ut malesuada primis. Magna penatibus nascetur ut tempus id rutrum fames praesent etiam lacus proin ligula, aenean lacinia torquent varius nisl libero curae nisi eros montes facilisi, viverra felis vestibulum mi quisque inceptos blandit vulputate gravida placerat habitasse.
    </p>

</mat-dialog-content>
