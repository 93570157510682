<form [formGroup]="form">
  <h2 mat-dialog-title class="title">{{ data.title }}</h2>
  <mat-dialog-content class="mat-typography">
    <div class="row">
      <div class="col-12">
        <p cdkFocusInitial>Estas preguntas son necesarias para poder restaurar tus credenciales en caso de necesitarlo, las mayúsculas, minúsculas y acentos de tus respuestas se tendrán en cuenta.</p>
      </div>
    </div>
    <div class="row" formGroupName="first_question">
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Primera pregunta</mat-label>
          <mat-select formControlName="question_id" required>
           <mat-option value="">---Selecciona una opción---</mat-option>
           <mat-option *ngFor="let question of questions" [value]="question.id" [disabled]="question.disabled">{{ question.question }}</mat-option>
          </mat-select>
          <mat-error *ngIf="getQuestionControls('first_question').question_id.hasError('required')">Selecciona una opción</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Respuesta</mat-label>
          <input type="text" matInput formControlName="answer" required/>
          <mat-error *ngIf="getQuestionControls('first_question').answer.hasError('required')">Ingresa una respuesta</mat-error>
          <mat-error *ngIf="getQuestionControls('first_question').answer.hasError('minlength')">La respuesta debe contener mínimo 2 caracteres</mat-error>
          <mat-error *ngIf="getQuestionControls('first_question').answer.hasError('maxlength')">La respuesta debe contener máximo 200 caracteres</mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="row mt-3" formGroupName="second_question">
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Segunda pregunta</mat-label>
          <mat-select formControlName="question_id" required>
           <mat-option value="">---Selecciona una opción---</mat-option>
           <mat-option *ngFor="let question of questions" [value]="question.id" [disabled]="question.disabled">{{ question.question }}</mat-option>
          </mat-select>
          <mat-error *ngIf="getQuestionControls('second_question').question_id.hasError('required')">Selecciona una opción</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Respuesta</mat-label>
          <input type="text" matInput formControlName="answer" required/>
          <mat-error *ngIf="getQuestionControls('second_question').answer.hasError('required')">Ingresa una respuesta</mat-error>
          <mat-error *ngIf="getQuestionControls('second_question').answer.hasError('minlength')">La respuesta debe contener mínimo 2 caracteres</mat-error>
          <mat-error *ngIf="getQuestionControls('second_question').answer.hasError('maxlength')">La respuesta debe contener máximo 200 caracteres</mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-divider></mat-divider>


    <div class="row mt-3" formGroupName="third_question">
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Tercera pregunta</mat-label>
          <mat-select formControlName="question_id" required>
           <mat-option value="">---Selecciona una opción---</mat-option>
           <mat-option *ngFor="let question of questions" [value]="question.id" [disabled]="question.disabled">{{ question.question }}</mat-option>
          </mat-select>
          <mat-error *ngIf="getQuestionControls('third_question').question_id.hasError('required')">Selecciona una opción</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Respuesta</mat-label>
          <input type="text" matInput formControlName="answer" required/>
          <mat-error *ngIf="getQuestionControls('third_question').answer.hasError('required')">Ingresa una respuesta</mat-error>
          <mat-error *ngIf="getQuestionControls('third_question').answer.hasError('minlength')">La respuesta debe contener mínimo 2 caracteres</mat-error>
          <mat-error *ngIf="getQuestionControls('third_question').answer.hasError('maxlength')">La respuesta debe contener máximo 200 caracteres</mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-divider></mat-divider>


    <div class="row mt-3" formGroupName="fourth_question">
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Cuarta pregunta</mat-label>
          <mat-select formControlName="question_id" required>
           <mat-option value="">---Selecciona una opción---</mat-option>
           <mat-option *ngFor="let question of questions" [value]="question.id" [disabled]="question.disabled">{{ question.question }}</mat-option>
          </mat-select>
          <mat-error *ngIf="getQuestionControls('fourth_question').question_id.hasError('required')">Selecciona una opción</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Respuesta</mat-label>
          <input type="text" matInput formControlName="answer" required/>
          <mat-error *ngIf="getQuestionControls('fourth_question').answer.hasError('required')">Ingresa una respuesta</mat-error>
          <mat-error *ngIf="getQuestionControls('fourth_question').answer.hasError('minlength')">La respuesta debe contener mínimo 2 caracteres</mat-error>
          <mat-error *ngIf="getQuestionControls('fourth_question').answer.hasError('maxlength')">La respuesta debe contener máximo 200 caracteres</mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-divider></mat-divider>


    <div class="row mt-3" formGroupName="fifth_question">
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Quinta pregunta</mat-label>
          <mat-select formControlName="question_id" required>
           <mat-option value="">---Selecciona una opción---</mat-option>
           <mat-option *ngFor="let question of questions" [value]="question.id" [disabled]="question.disabled">{{ question.question }}</mat-option>
          </mat-select>
          <mat-error *ngIf="getQuestionControls('fifth_question').question_id.hasError('required')">Selecciona una opción</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Respuesta</mat-label>
          <input type="text" matInput formControlName="answer" required/>
          <mat-error *ngIf="getQuestionControls('fifth_question').answer.hasError('required')">Ingresa una respuesta</mat-error>
          <mat-error *ngIf="getQuestionControls('fifth_question').answer.hasError('minlength')">La respuesta debe contener mínimo 2 caracteres</mat-error>
          <mat-error *ngIf="getQuestionControls('fifth_question').answer.hasError('maxlength')">La respuesta debe contener máximo 200 caracteres</mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-divider></mat-divider>


    <div class="row mt-3" formGroupName="sixth_question">
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Sexta pregunta</mat-label>
          <mat-select formControlName="question_id" required>
           <mat-option value="">---Selecciona una opción---</mat-option>
           <mat-option *ngFor="let question of questions" [value]="question.id" [disabled]="question.disabled">{{ question.question }}</mat-option>
          </mat-select>
          <mat-error *ngIf="getQuestionControls('sixth_question').question_id.hasError('required')">Selecciona una opción</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Respuesta</mat-label>
          <input type="text" matInput formControlName="answer" required/>
          <mat-error *ngIf="getQuestionControls('sixth_question').answer.hasError('required')">Ingresa una respuesta</mat-error>
          <mat-error *ngIf="getQuestionControls('sixth_question').answer.hasError('minlength')">La respuesta debe contener mínimo 2 caracteres</mat-error>
          <mat-error *ngIf="getQuestionControls('sixth_question').answer.hasError('maxlength')">La respuesta debe contener máximo 200 caracteres</mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-divider></mat-divider>


    <div class="row mt-3" formGroupName="seventh_question">
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Séptima pregunta</mat-label>
          <mat-select formControlName="question_id" required>
           <mat-option value="">---Selecciona una opción---</mat-option>
           <mat-option *ngFor="let question of questions" [value]="question.id" [disabled]="question.disabled">{{ question.question }}</mat-option>
          </mat-select>
          <mat-error *ngIf="getQuestionControls('seventh_question').question_id.hasError('required')">Selecciona una opción</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Respuesta</mat-label>
          <input type="text" matInput formControlName="answer" required/>
          <mat-error *ngIf="getQuestionControls('seventh_question').answer.hasError('required')">Ingresa una respuesta</mat-error>
          <mat-error *ngIf="getQuestionControls('seventh_question').answer.hasError('minlength')">La respuesta debe contener mínimo 2 caracteres</mat-error>
          <mat-error *ngIf="getQuestionControls('seventh_question').answer.hasError('maxlength')">La respuesta debe contener máximo 200 caracteres</mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-divider></mat-divider>


    <div class="row mt-3" formGroupName="eighth_question">
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Octava pregunta</mat-label>
          <mat-select formControlName="question_id" required>
           <mat-option value="">---Selecciona una opción---</mat-option>
           <mat-option *ngFor="let question of questions" [value]="question.id" [disabled]="question.disabled">{{ question.question }}</mat-option>
          </mat-select>
          <mat-error *ngIf="getQuestionControls('eighth_question').question_id.hasError('required')">Selecciona una opción</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Respuesta</mat-label>
          <input type="text" matInput formControlName="answer" required/>
          <mat-error *ngIf="getQuestionControls('eighth_question').answer.hasError('required')">Ingresa una respuesta</mat-error>
          <mat-error *ngIf="getQuestionControls('eighth_question').answer.hasError('minlength')">La respuesta debe contener mínimo 2 caracteres</mat-error>
          <mat-error *ngIf="getQuestionControls('eighth_question').answer.hasError('maxlength')">La respuesta debe contener máximo 200 caracteres</mat-error>
        </mat-form-field>
      </div>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end" class="actions mt-2 mb-2">
    <button mat-raised-button class="w-100" color="primary" (click)="save()">Guardar</button>
  </mat-dialog-actions>

</form>
