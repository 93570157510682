import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import { EstadoModel } from 'src/app/modules/coaching/core/interfaces/estado.interface';
import { CoachingService } from 'src/app/modules/coaching/services/coaching.service';
import { CoachingState } from 'src/app/modules/coaching/state/coaching.state';
import { listCausas, listCompromisos, listCuartiles, listEstados, listEvoluciones } from 'src/app/modules/coaching/state/selectors/selects.selector';
import { solicitudSelect } from 'src/app/modules/coaching/state/selectors/solicitud.selector';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-form-solicitud',
  templateUrl: './form-solicitud.component.html',
  styleUrls: ['./form-solicitud.component.sass']
})
export class FormSolicitudComponent implements OnInit {
  id:string;
  solicitudForm:FormGroup;
  startDate;
  endDate;
  fecha_seguimiento;
  KPI;
  obj_planteado;
  filtros;
  ruta_envio;

  //Selects
  estados$:Observable<any> = new Observable();
  estados = [];
  causas$:Observable<any> = new Observable();
  compromisos$:Observable<any> = new Observable();
  evoluciones$:Observable<any> = new Observable();
  cuartiles$:Observable<any> = new Observable();

  constructor(
    private fb:FormBuilder,
    private store:Store<CoachingState>,
    private coachingService:CoachingService,
    private aRoute:ActivatedRoute,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.getSelects();
    this.aRoute.paramMap.subscribe(params=>{
      this.id = params.get('id');
    });
    this.store.select(solicitudSelect).forEach(solicitud => {
      if (solicitud) {
        if ( moment(solicitud[0].acompanamiento.proximoSeguimiento).isAfter(new Date())) {
          this.startDate = moment(solicitud[0].acompanamiento.proximoSeguimiento).add(1,'d').format();
        }else{
          this.startDate = new Date();
        }
        this.endDate = moment(solicitud[0].acompanamiento.created_at).add(30,'d').format();
        this.fecha_seguimiento = solicitud[0].acompanamiento.proximoSeguimiento;
        this.KPI = solicitud[0].acompanamiento.objetivoKPI;
        this.obj_planteado = solicitud[0].acompanamiento.objetivoPlanteado;
      }
    });
  }

  createForm(){
    this.solicitudForm = this.fb.group({
      estado:['',Validators.required],
      proximo_seguimiento:[''],
      objetivo_planteado:[''],
      causa_raiz:['',Validators.required],
      cumple_compromiso:['',Validators.required],
      presenta_evolucion:['',Validators.required],
      kpi:[''],
      indicador_final:[''],
      cuartil_final:[''],
      conclusion_sesion:['',Validators.required],
    });
  }

  changeSelect(){
    if (this.solicitudForm.get('estado').value == 50) {
      this.solicitudForm.get('proximo_seguimiento').setValidators(Validators.required);
      this.solicitudForm.get('objetivo_planteado').setValidators(Validators.required);
      this.solicitudForm.get('kpi').setValidators(Validators.required);
      this.solicitudForm.get('indicador_final').disable();
      this.ruta_envio = `/mios/coaching/solicitud/${this.id}`;
    }else{
      this.solicitudForm.get('proximo_seguimiento').setValidators(null);
      this.solicitudForm.get('objetivo_planteado').setValidators(null);
      this.solicitudForm.get('kpi').setValidators(null);
      this.solicitudForm.get('proximo_seguimiento').setErrors(null);
      this.solicitudForm.get('objetivo_planteado').setErrors(null);
      this.solicitudForm.get('kpi').setErrors(null);
    }
    if (this.solicitudForm.get('estado').value == 52){
      this.ruta_envio = `/mios/coaching/finalizado/${this.id}`;
      this.solicitudForm.get('cuartil_final').setValidators(Validators.required);
      this.solicitudForm.get('indicador_final').enable();
      this.solicitudForm.get('proximo_seguimiento').setValue(this.fecha_seguimiento);
      this.solicitudForm.get('objetivo_planteado').setValue(0);
      this.solicitudForm.get('kpi').setValue(this.KPI);
    }else{
      this.solicitudForm.get('cuartil_final').setValidators(null);
      this.solicitudForm.get('cuartil_final').setErrors(null);
      this.solicitudForm.get('proximo_seguimiento').setValue(null);
      this.solicitudForm.get('objetivo_planteado').setValue(null);
      this.solicitudForm.get('kpi').setValue(null);
    }
  }

  getSelects(){
    this.estados$ = this.store.select(listEstados);
    //Seleccionando solo los campos abierto y finalizado
    this.estados$.forEach((element:EstadoModel[]) => {
      this.estados = [];
      element.forEach((estado:EstadoModel) => {
        if (estado.nombre == 'Finalizado') {
          this.estados.push(estado);
        }
        if (estado.nombre == 'Abierto') {
          this.estados.push(estado);
        }
      });

      this.estados.sort(function (a, b) {
        if (a.nombre > b.nombre) {
          return 1;
        }
        if (a.nombre < b.nombre) {
          return -1;
        }
        return 0;
      });

    });

    this.causas$ = this.store.select(listCausas);
    this.compromisos$ = this.store.select(listCompromisos);
    this.evoluciones$ = this.store.select(listEvoluciones);
    this.cuartiles$ = this.store.select(listCuartiles);
  }

  saveSeguimiento(){
    if (this.solicitudForm.invalid) {
      return;
    }
    this.solicitudForm.get('proximo_seguimiento').setValue(moment(this.solicitudForm.get('proximo_seguimiento').value).format('yyyy-MM-DD'));
    if (this.solicitudForm.get('proximo_seguimiento').value == "Invalid date") {
      this.solicitudForm.get('proximo_seguimiento').setValue(null);
    }
    Object.assign(this.solicitudForm.value,{
        id:this.id,
        mensaje:`Te han realizado un cambio en el acompañamiento ID-${this.id}`,
        ruta: this.ruta_envio
      });
    this.coachingService.cambiarSolicitud(this.solicitudForm.value).subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202) {        
        Swal.fire({
          icon:'success',
          title:'¡Excelente!',
          html:`<p>El estado de solicitud se ha cambiado con exito.</p><p><b>ID Coaching ${resp.respuesta.id}</b></p>`,
          showConfirmButton:false,
          timer: 4500
        })
        if (this.solicitudForm.get('estado').value == 52){
          this.router.navigateByUrl('/mios/coaching/coaching/1');
        }else{
          this.cancelar();
        }
      }else{
        Tools.swalError('¡Oops!','Lo sentimos, ha ocurrido un error inesperado y no fue posible cambiar el estado de la solicitud, por favor intenta nuevamente.');
      }
    },(err)=>{
      Tools.swalError('¡Oops!','Lo sentimos, ha ocurrido un error inesperado y no fue posible cambiar el estado de la solicitud, por favor intenta nuevamente.');
    }
    );
  }

  cancelar(){
    this.solicitudForm.reset();
    this.solicitudForm.get('estado').setErrors(null);
    this.solicitudForm.get('proximo_seguimiento').setErrors(null);
    this.solicitudForm.get('objetivo_planteado').setErrors(null);
    this.solicitudForm.get('causa_raiz').setErrors(null);
    this.solicitudForm.get('cumple_compromiso').setErrors(null);
    this.solicitudForm.get('presenta_evolucion').setErrors(null);
    this.solicitudForm.get('kpi').setErrors(null);
    this.solicitudForm.get('indicador_final').setErrors(null);
    this.solicitudForm.get('conclusion_sesion').setErrors(null);
  }

}
