import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MeetingsComponent } from '../meetings/meetings.component';
import { MatDialog } from '@angular/material/dialog';
import { VideollamadaService } from './services/videollamada.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-videollamada',
  templateUrl: './videollamada.component.html',
  styleUrls: ['./videollamada.component.sass']
})
export class VideollamadaComponent implements OnInit {

  videoLlamadaButton = true;
  VideoStartButton:boolean = true;
  @Output() viewVideoButton: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() VideoResponse: EventEmitter<any> = new EventEmitter<any>();
	viewVideoState = true;
  videoState = 'register';
  toggleCheck: boolean = false;
  user: any;

  constructor(public dialog: MatDialog,
    private videoService: VideollamadaService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.user = this.authService.getUser();
    this.videoService.getAdviserById(this.user.rrhh_id).subscribe(res =>{
      const body = {
        id: res.id, 
        state_id: 1
      };
      if(res.state_id == 1){
        this.videoState = 'register';      
        this.VideoStartButton = true;
      } else if(res.state_id == 2) {
        this.videoState = 'ready';
        this.videoLlamadaButton = true;
        this.viewVideoState = false;
        this.VideoStartButton = false;
        this.toggleCheck = true;
      } else if(res.state_id == 3) {
        this.videoState = 'incall';
        this.videoLlamadaButton = true;
        this.viewVideoState = false;
        this.toggleCheck = true;
        this.VideoStartButton = false;
      }
      this.videoService.stateObservable.subscribe(resp => {
        this.videoState = resp;
      });
    });
  }

  viewPhone() {
		this.viewVideoState = !this.viewVideoState;
	}

  onValChange(event) {
    //console.log(event);
    this.toggleCheck = event.checked
    if(event.checked == true){  
      this.VideoStartButton = false;
    } else {
      this.VideoStartButton = true;
      this.videoState = 'register';
      this.videoService.getAdviserById(this.user.rrhh_id).subscribe(res =>{
        const body = {
          id: res.id, 
          state_id: 1
        };
        this.videoService.UpdateAdviserState(body).subscribe(resp => { });
      });
    }
    
    
   }

   startCalls(){
    this.videoState = 'ready';
    this.videoLlamadaButton = true;
    this.viewVideoState = false;

    this.videoService.getAdviserById(this.user.rrhh_id).subscribe(res =>{
      const body = {
        campaign_id: res.campaign_id, 
        adviser_id: res.id
      };
      this.videoService.sendEncolamiento(body).subscribe(resp => { });
    });

   }

}
