<div class="admin-coaching" >
  
  <div class="header">
    
    <h2 style="height: 33px !important;
      font: normal normal bold 45px/35px Poppins !important;
      letter-spacing: 0px !important;color: #353535 !important;opacity: 1 !important;">Coaching</h2>
      <br>
      <p>Aquí podrás ver o crear un nuevo acompañamiento. No olvides,</p>
      <p>“El respeto y empatía son clave”.</p>
    </div>
    <div class="paso-paso">
      <app-paso-paso></app-paso-paso>
    </div>
    <mat-tab-group [selectedIndex]="selected.value"
    (selectedIndexChange)="changeTab($event)">
        <mat-tab label="Abierto"> 
          <div class="mt-4" style="overflow:auto ;">
            <app-filtro-tabla></app-filtro-tabla>
            <app-tabla-coaching></app-tabla-coaching>
          </div>
        </mat-tab>
        <mat-tab label="Finalizado">
          <div class="mt-4">
            <app-filtro-tabla></app-filtro-tabla>
            <app-tabla-coaching></app-tabla-coaching>
          </div>
        </mat-tab>
    </mat-tab-group>      
</div>

