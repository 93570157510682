import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrmComponent } from './crm.component';
import { CrmRoutingModule } from './crm-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { GroupsModule } from './modules/groups/groups.module';
import { FormsModule } from '@angular/forms';
import { AdminEscalationComponent } from './modules/escalation/admin-escalation/admin-escalation.component';
import { ViewEscalationComponent } from './modules/escalation/view-escalation/view-escalation.component';
import { AdminEmailComponent } from './modules/email/admin-email/admin-email.component';
import { ViewMailComponent } from './modules/email/view-mail/view-mail.component';
import { CreateStateComponent } from './modules/escalation/create-state/create-state.component';
import { CreateAffairComponent } from './modules/escalation/create-affair/create-affair.component';
import { EditUserComponent } from './modules/escalation/edit-user/edit-user.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ManageEmailComponent } from './modules/email/manage-email/manage-email.component';
import { NewEmailComponent } from './modules/email/new-email/new-email.component';
import { TemplatesModule } from './modules/templates/templates.module';
import { LandingPageCrmComponent } from './modules/landing-page-crm/landing-page-crm.component';
import { MailEscalationComponent } from './modules/escalation/mail-escalation/mail-escalation.component';
import { GestionadorCorreosComponent } from "./modules/escalation/gestionador-correos/gestionador-correos.component";

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { ViewPlantillasComponent } from './modules/escalation/view-plantillas/view-plantillas.component';
import { FilesUploadComponent } from './modules/escalation/files-upload/files-upload.component';
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    CrmComponent,
    AdminEscalationComponent,
    ViewEscalationComponent,
    AdminEmailComponent,
    ViewMailComponent,
    CreateStateComponent,
    CreateAffairComponent,
    EditUserComponent,
    ManageEmailComponent,
    NewEmailComponent,
    LandingPageCrmComponent,
    MailEscalationComponent,
    GestionadorCorreosComponent,
    ViewPlantillasComponent,
    FilesUploadComponent],
  imports: [
    CommonModule,
    CrmRoutingModule,
    SharedModule,
    GroupsModule,
    FormsModule,
    TemplatesModule,
    AngularEditorModule
  ],
  exports: [
  ],
  providers: [{provide: LOCALE_ID, useValue: 'es'}],
})
export class CrmModule { }
