import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../../services/users.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TrainingGroupsService } from '../../../services/training-groups.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../../../core/services/rest/auth.service';
import { AssingUserComponent } from '../../assign-user/assing-user/assing-user.component';

@Component({
  selector: 'app-groups-create',
  templateUrl: './groups-create.component.html',
  styleUrls: ['./groups-create.component.sass']
})
export class GroupsCreateComponent implements OnInit {
  trainerGroupForm: FormGroup;

  trainers: any = [];
  sites: any = [];
  groupType:any = [];
  constructor(private usersService: UsersService,
              private groupsService: TrainingGroupsService,
              private fb: FormBuilder,
              private alert: AlertsService,
              private dialogRef: MatDialogRef<GroupsCreateComponent>,
              private auth:AuthService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.groupsService.getValidGroups(`formation/grouptype`).subscribe(res=>{
      this.groupType= res
    })
    this.getTrainerList();
    this.getSites();
    this.validateForm();
  }

  validateForm(){
    this.trainerGroupForm = this.fb.group({
      name: ['', [Validators.required, ]],
      modality: ['', [Validators.required, ]],
      capacity: ['', [Validators.required, ]],
      mode_rescue: ['', [Validators.required, ]],
      start: ['', [Validators.required, ]],
      end: ['', [Validators.required, ]],
      ubication: ['', [Validators.required, ]],
      user_trainer_id: ['', [Validators.required, ]],
      description: ['', [Validators.required, ]],
      group_type_id:[null],
      segment:[null],
      case_glpi:[null],
      user_responsable_id: [this.auth.getUser().rrhh_id],
    });
  }

  getTrainerList() {
    this.usersService.getUserByRole('formador').subscribe(resp => {
      this.trainers = resp;
    });
  }

  getSites(){
    this.usersService.getSites().subscribe(resp => {
      this.sites = resp;
    });
  }

  groupRegister() {
    this.groupsService.createGroup(this.trainerGroupForm.getRawValue()).subscribe( resp => {
      this.alert.alertSuccess('¡Hecho!', 'Grupo creado con éxito!');
      this.dialogRef.close(true);
    });
  }
  user_trainer_name = null;
  searchUsers(rol){
    const saerchDialog = this.dialog.open( AssingUserComponent, {
      data: { role: rol}
    });
    saerchDialog.afterClosed().subscribe(res=>{
      if(res){
        this.trainerGroupForm.get(`user_trainer_id`).setValue(res.user.rrhh_id)
        this[`user_trainer_name`]= res.user.name
      }
    })
  }

}
