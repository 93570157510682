<div class="main-container">
  <h1>Reservas</h1>

  <br>
  <br>
  <div class="filter">
    <mat-form-field appearance="fill">
        <mat-label>Filtrar por estado</mat-label>
        <mat-select [(ngModel)]="typeStatus" (selectionChange)="getResources()">
          <mat-option value="2">Aprobado</mat-option>
          <mat-option value="1">Pendiente</mat-option>
          <mat-option value="3">Rechazado</mat-option>
          <mat-option value="">Ninguno</mat-option>
        </mat-select>
    </mat-form-field>
  </div>

  <div class="table" *ngIf="bookingList">

      <table mat-table [dataSource]="dataSource" matSort >
          <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                        <td mat-cell *matCellDef="let row">
                          <button mat-icon-button matTooltip="Cambiar de Estado" (click)="changeStatus(row)"> <i class="fi-rr-checkbox"></i> </button>

                        </td>
          </ng-container>
          <ng-container matColumnDef="resources_associated">
            <th mat-header-cell *matHeaderCellDef > Recursos</th>
            <td mat-cell *matCellDef="let element"> <span *ngFor="let item of element.resources_associated; last as isLast">{{item.resource.name}}<span *ngIf="!isLast">,</span> </span> </td>
        </ng-container>
        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef >  Fecha de reserva </th>
          <td mat-cell *matCellDef="let element"> {{element.created_at | date}} </td>
      </ng-container>
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef> Solicitante  </th>
        <td mat-cell *matCellDef="let element"> {{element.user[0].name}} </td>
    </ng-container>

        <ng-container matColumnDef="start">
          <th mat-header-cell *matHeaderCellDef>Horas resevadas </th>
          <td mat-cell *matCellDef="let element"> {{element.start |date: 'shortTime'}} a  {{element.end |date: 'shortTime'}} </td>
      </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Estado</th>
            <td mat-cell *matCellDef="let element">{{element.status | status}}  </td>
          </ng-container>



          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="5">No hay reservas disponibles</td>
        </tr>
      </table>
<mat-paginator [length]="length" [pageSize]="pageSize" (page)="getPaginatorData($event)" showFirstLastButtons></mat-paginator>


  </div>
</div>


