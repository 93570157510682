import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AdminServersService } from '../../../../services/admin-servers.service';
import { EditConnectionComponent } from '../edit-connection/edit-connection.component';
import { CretateConnectionsComponent } from '../create-connection/create-connection.component';

@Component({
  selector: 'app-connects',
  templateUrl: './connects.component.html',
  styleUrls: ['./connects.component.sass']
})
export class ConnectsComponent implements OnInit {
  
  formFilters:FormGroup; 
  filter: string = '';
  newStateToggle: any;
  textInputFilter: string = '';
  pageSizeOptions: any = [5, 10, 25, 50, 100];
  public queryParams : string = 'page=1&per_page=5';
  configPaginator: any = { length: 0, pageSize: 5, pageIndex: 0, previousPageIndex: 0 };

  dataSource = new MatTableDataSource<any>(); 
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  //configuracion tabla
  keysColumnsTable: string[] = [];
  displayedColumns: string[] = ['slide', 'name', 'server', 'database'];

  configColumnsTable: any[] = [
    { key: 'slide', name: 'Acciones' },
    { key: 'name', name: 'Nombre' },
    { key: 'server_host', name: 'Servidor' },
    { key: 'database_name', name: 'Base de datos' },
  ]; 

  ngOnInit(): void {
    this.getServers();
    this.keysColumnsTable = this.configColumnsTable.map((item: any) => item.key);
  }

  constructor(
      private dialog:MatDialog,
      private adminServersService: AdminServersService,
  ) { }


  /**
   * Metodo abir modal agregar conexion-
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  addConnection(): void {
    const dialogRef= this.dialog.open(CretateConnectionsComponent, {
      width: '50%',
      maxHeight: '95vh',
      autoFocus: false,
      panelClass: 'dialog-padding-category'
    });
  }

  /**
   * Metodo para modal editar
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  openEditModal(id: number): void {
    const dialogRef = this.dialog.open(EditConnectionComponent, {
      width: '50%',
      maxHeight: '95vh',
      autoFocus: false,
      panelClass: 'dialog-padding-category',
      data: { id: id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getServers();  
    });
  }

  /**
   * Metodo que pagina la informacion de la tabla.
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
   * @param $event Evento emitido por el paginador.
  */
  changePage($event: PageEvent): void {
    this.configPaginator = $event;
    this.getServers();
  }

  /**
   * Metodo que filtra los datos de la tabla.
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
   * @param $event Evento emitido por el input.
  */
  onSearchClick(value: string): void {
    this.filter = value;
    this.configPaginator.pageIndex= 0;
    this.paginator.firstPage();
    this.getServers();  
  }


  /**
   * Metodo que trae todos los servidores registrados. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  getServers(): void {
    this.adminServersService.getServers(this.configPaginator, this.filter).subscribe(res => {
      this.dataSource.data = res.data.data;
      this.dataSource.sort = this.sort;
      this.configPaginator.length = res.data.total;
    });
  }

  /**
   * Metodo cambiar de estado el toggle
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  toggleStateChanged(element: any): void {
    let confirmationMessage = '';
    if (!element.state) {
      confirmationMessage = '¿Estás seguro de deshabilitar la conexión seleccionada?';
    } else {
      confirmationMessage = '¿Estás seguro de habilitar la conexión seleccionada?';
    }
  
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      text: confirmationMessage,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.changeState(element);
      } else {
        element.state = !element.state;
      }
    });
  }
  
  /**
   * Metodo actualizar campaña activa
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  changeState(element: any): void {   
    this.adminServersService.StateServer({ id: element.id })
      .subscribe(
        () => {
          let confirmationMessage = '';
          if (!element.active) {
            confirmationMessage = 'Se ha deshabilitado la conexión con éxito.';
          } else {
            confirmationMessage = 'Se ha habilitado la conexión con éxito.';
          }
          Swal.fire({
            icon: 'success',
            title: '¡Excelente!',
            text: confirmationMessage,
            timer: 3000,
            showConfirmButton: false
          }).then(() => {
            this.getServers();
          });
        },
        (error) => {
          console.error('No se pudo actualizar el estado de la conexión', error.error);
          Swal.fire({
            icon: 'error',
            title: 'No se pudo actualizar el estado de la conexión',
            text: error.error.error,
            confirmButtonText: '¡Entendido!',
            confirmButtonColor: '#2CABBC',
          }).then(() => {
            
          });
        }
      );
  }

}
