<div class='app-clientes-crear' >
  <h2 mat-dialog-title *ngIf="data.type == 'crear'">
    Crear Cliente
    <button style="float: right" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
  </h2>
  <h2 mat-dialog-title *ngIf="data?.type == 'editar'">
    {{data?.title || ''}}
    <button style="float: right" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
  </h2>

  <form class="row form" [formGroup]="clientesForm" (ngSubmit)="saveClientes()">

    <mat-dialog-content class="mat-typography" class="row col-12">
      <mat-form-field appearance="fill" class="col-3">
        <mat-label> Tipo de identificación </mat-label>
        <mat-select formControlName="id_type">
          <mat-option value="0">Nacional</mat-option>
          <mat-option value="1">Internacional</mat-option>
        </mat-select>
        <mat-error *ngIf="error.id_type.errors"><span
            *ngIf="error.id_type.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-4">
        <mat-label> Número de identificación </mat-label>
        <input style="text-transform: capitalize;" matInput type="text" formControlName="id_number"
          matTooltip="En este espacio se debe colocar el número de identificación de la empresa,
          incluyendo el número de verificación separado por un guión (-)."
          (keypress)="keyAlphanumericPress($event)"
        >
        <mat-error *ngIf="error.id_number.errors"><span
          *ngIf="error.id_number.errors.maxlength">¡Advertencia! Solo se permite maximo 20 caracteres</span>
        </mat-error>
        <mat-error *ngIf="error.id_number.errors"><span
          *ngIf="error.id_number.errors.minlength">¡Advertencia! Debe tener mínimo 4 caracteres</span>
        </mat-error>
        <mat-error *ngIf="error.id_number.errors"><span
            *ngIf="error.id_number.errors.required">¡Advertencia! Este campo es requerido</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-5">
        <mat-label> Razón Social </mat-label>
        <input id="business_name" formControlName="business_name" matInput type="text" #input
          autocomplete="off">
        <mat-error *ngIf="error.business_name.errors"><span
            *ngIf="error.business_name.errors.required">¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="error.business_name.errors"><span
          *ngIf="error.business_name.errors.maxlength">¡Advertencia! Solo se permite maximo 255 caracteres</span>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field appearance="fill" class="col-7">
        <mat-label> Nombre comercial </mat-label>
        <input id="name" formControlName="name" matInput type="text" #input
          autocomplete="off">
          <mat-error *ngIf="error.name.errors"><span
            *ngIf="error.name.errors.required">¡Advertencia! Este campo es requerido</span>
        </mat-error>
      </mat-form-field>


      <mat-form-field appearance="fill" class="col-5">
        <mat-label> Industria </mat-label>
        <mat-select id="sector_id" formControlName="sector_id">
          <mat-option *ngFor="let item of clientesectorarray" [value]="item?.id">{{item.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="error.sector_id.errors"><span
            *ngIf="error.sector_id.errors.required">¡Advertencia! Este campo es requerido</span>
        </mat-error>
      </mat-form-field>
      <br>

      <mat-form-field appearance="fill" class="col-4">
        <mat-label> País </mat-label>
        <mat-select id="country_id" formControlName="country_id" (valueChange)="getCities($event)">
          <mat-option *ngFor="let item of countries" [value]="item?.id">{{item.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="error.country_id.errors"><span *ngIf="error.country_id.errors.required">¡Advertencia!
            Este campo es requerido</span></mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-3">
        <mat-label> Ciudad </mat-label>
        <mat-select id="municipality_id" formControlName="municipality_id">
          <mat-option *ngFor="let item of cities" [value]="item?.id">{{item.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="error.municipality_id.errors"><span
            *ngIf="error.municipality_id.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
      </mat-form-field>


      <mat-form-field appearance="fill" class="col-5">
        <mat-label>Dirección</mat-label>
        <input id="address" formControlName="address" matInput type="text" #input
          autocomplete="off">
        <mat-error *ngIf="error.address.errors"><span
            *ngIf="error.address.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
      </mat-form-field>
      <br>
      <mat-form-field appearance="fill" class="col-3">
        <mat-label> Teléfono </mat-label>
        <input id="phone_number" formControlName="phone_number" matInput type="text" #input autocomplete="off"
          (keypress)="keyPress($event)" ngPattern="/^@[0-9,-]+[0-9]\1*$/">
        <mat-error *ngIf="error.phone_number.errors"><span
            *ngIf="error.phone_number.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
        <mat-error *ngIf="error.phone_number.errors"><span
            *ngIf="error.phone_number.errors.minlength">¡Advertencia! Este campo es minimo 7 caracteres</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-4">
        <mat-label> Sitio web (opcional) </mat-label>
        <input formControlName="website" id="website" matInput type="text" #input autocomplete="off">
        <mat-error *ngIf="error.website.errors"><span
          *ngIf="error.website.errors.maxlength">¡Advertencia! Solo se permite maximo 255 caracteres</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-5">
        <mat-label> Tamaño empresarial </mat-label>
        <mat-select formControlName="company_size" id="company_size">
          <mat-option *ngFor="let item of tipoInarray" [value]="item?.id">{{item.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="error.company_size.errors"><span
            *ngIf="error.company_size.errors.required">¡Advertencia! Este campo es requerido</span>
        </mat-error>
      </mat-form-field>
      <br>
      <mat-form-field appearance="fill" class="col-12">
        <mat-label> Observaciones (opcional) </mat-label>
        <input formControlName="observations" id="observations" matInput type="text" #input
          autocomplete="off">
          <mat-error *ngIf="error.observations.errors"><span
            *ngIf="error.observations.errors.maxlength">¡Advertencia! Solo se permite maximo 255 caracteres</span>
          </mat-error>
      </mat-form-field>
      <br>

      <mat-label style="font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 42px;">
        Contactos comerciales
      </mat-label>
      <div formArrayName="items" class="row col-12" style="margin-top: 24px;">
        <div class="col-12" *ngFor="let applet of items.controls; let i = index" style="margin-bottom: 24px;">

          <div class="row col-12" [formGroupName]="i">

            <mat-form-field class="col-4" appearance="fill">
              <mat-label> Nombre </mat-label>
              <input style="text-transform: none;" matInput type="text" placeholder="Nombre del contacto"
                formControlName="name" maxlength="255">
              <mat-error *ngIf="error.items.controls[i].controls.name.errors"><span
                  *ngIf="error.items.controls[i].controls.name.errors.required">¡Advertencia! Este campo es
                  requerido</span></mat-error>
              <mat-error *ngIf="error.items.controls[i].controls.name.errors"><span
                  *ngIf="error.items.controls[i].controls.name.errors.minlength">¡Aún falta! Recibimos mínimo
                  3 caracteres</span></mat-error>
              <mat-error *ngIf="error.items.controls[i].controls.name.errors"><span
                  *ngIf="error.items.controls[i].controls.name.errors.maxlength">¡Cuidado! Solo puedes tener
                  un máximo de 255 caracteres</span></mat-error>
            </mat-form-field>

            <mat-form-field class="col-3" appearance="fill">
              <mat-label> Cargo </mat-label>
              <input style="text-transform: none;" matInput type="text" min="0" placeholder="Escribe el cargo"
                formControlName="charge">
              <mat-error *ngIf="error.items.controls[i].controls.charge.errors"><span
                  *ngIf="error.items.controls[i].controls.charge.errors.required">¡Advertencia! Este campo es
                  requerido</span></mat-error>
              <mat-error *ngIf="error.items.controls[i].controls.charge.errors"><span
                  *ngIf="error.items.controls[i].controls.charge.errors.minlength">¡Aún falta! Recibimos mínimo 3
                  caracteres</span></mat-error>
            </mat-form-field>

            <mat-form-field class="col-4" appearance="fill">
              <mat-label> Celular </mat-label>
              <input style="text-transform: none;" matInput type="text" placeholder="" formControlName="phone"
                (keypress)="keyPress($event)" >
              <mat-error *ngIf="error.items.controls[i].controls.phone.errors"><span
                  *ngIf="error.items.controls[i].controls.phone.errors.required">¡Advertencia! Este campo es
                  requerido</span></mat-error>
              <mat-error *ngIf="error.items.controls[i].controls.phone.errors"><span
                  *ngIf="error.items.controls[i].controls.phone.errors.minlength">¡Aún falta! Recibimos mínimo 7
                  caracteres</span></mat-error>

            </mat-form-field>

            <mat-form-field class="col-7" appearance="fill">
              <mat-label> Correo electrónico </mat-label>
              <input style="text-transform: none;" matInput type="text" placeholder=""
                formControlName="email" >
              <mat-error *ngIf="error.items.controls[i].controls.email.errors"><span
                  *ngIf="error.items.controls[i].controls.email.errors.required">¡Advertencia! Este campo es
                  requerido</span></mat-error>
              <mat-error *ngIf="error.items.controls[i].controls.email.errors"><span
                  *ngIf="error.items.controls[i].controls.email.errors.minlength">¡Aún falta! Recibimos mínimo 7
                  caracteres</span></mat-error>
              <mat-error *ngIf="error.items.controls[i].controls.email.errors"><span
                  *ngIf="error.items.controls[i].controls.email.errors.pattern">¡Aún falta! no cumple con la
                  estructura de correo electronico</span></mat-error>
            </mat-form-field>

            <mat-form-field class="col-4" appearance="fill">
              <mat-label> Fecha de cumpleaños (opcional)</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="birthday">
              <mat-datepicker-toggle matSuffix [for]="picker">
                <span matDatepickerToggleIcon class="fi-rr-calendar" style="font-size: 22px !important;"></span>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>


            <div class="col-1">
              <button mat-icon-button *ngIf="i > 0" (click)="deleteItems(i, items.controls[i].controls.id.value)" style="color:#BE0F0F;font-size: 20px;"><i
                  class="fi-rr-trash" style="position:absolute"></i></button>
            </div>
          </div>
        </div>
        <div class="col-12" align="center" style="border-style:dotted; border-color:#A9A9A9;" (click)="newItems()">
          <button mat-icon-button type="button" class="col-4" style="font-size: 14px; color:#A9A9A9" [hidden]="items.controls?.length == 6">
            Agregar nuevo contacto
            <i class="fi-rr-add" style="vertical-align:middle;"></i>
          </button>
        </div>

      </div>

    </mat-dialog-content>

    <mat-dialog-actions align="end">

      <button mat-stroked-button type="button" style="margin-left: 10px;" class="compras-modal-button"
        (click)="opcionCancelar()">Cancelar</button>
      <button mat-raised-button type="submit" style="margin-left: 10px;" color="primary"
        [disabled]="clientesForm.invalid" class="bold compras-modal-button">Guardar</button>
    </mat-dialog-actions>

  </form>
</div>
