<h2 mat-dialog-title>
  {{ data.title }}
  <button style="float: right" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross-small"></i>
  </button>
</h2>

<form class="row form" [formGroup]="filterForm" (ngSubmit)="findConfigs()">
  <mat-dialog-content style="padding: 3%" class="mat-typography">
    <div class="row">
      <mat-form-field style="padding: 1%" class="col-3" appearance="fill">
        <mat-label>Semana:</mat-label>
        <input type="text" matInput formControlName="rangeDate" [matAutocomplete]="rangeListDate" required
               #inputAutoCompleteScCreate>
        <mat-autocomplete #rangeListDate="matAutocomplete"
                          (optionSelected)="onChange($event)"
                          [displayWith]='displayFnRanges.bind(this)' required>
          <mat-option *ngFor="let range of filteredOptionsRanges | async" [value]="range.id"
                      style="font-size: 12px!important; text-transform: uppercase"
                      matTooltip="{{ range.name }}"
                      matTooltipPosition="right">
            {{ range.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="error.rangeDate.errors"><span *ngIf="error.rangeDate.errors.required">Campo es
                  requerido</span></mat-error>
        <mat-error *ngIf="error.rangeDate.errors">Opción no válida</mat-error>
      </mat-form-field>

<!--       <mat-form-field style="padding: 1%" class="col-3" appearance="fill">
        <mat-label>Campaña:</mat-label>
        <input
          type="text"
          matInput
          [readonly]="!rangeDateInit"
          formControlName="campaigns"
          [matAutocomplete]="campaignList"
          required
        />
        <mat-autocomplete
          #campaignList="matAutocomplete"
          [displayWith]="displayFnCampaigns.bind(this)"
          (optionSelected)="consultarGrupos()"
          required
        >
          <mat-option
            *ngFor="let campaign of filteredOptionsCampaigns | async"
            [value]="campaign.id"
          >
            {{ campaign.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="error.campaigns.errors"
        ><span *ngIf="error.campaigns.errors.required"
        >Campo es requerido</span
        ></mat-error
        >
        <mat-error *ngIf="error.campaigns.errors">Opción no válida</mat-error>
      </mat-form-field> -->

      <mat-form-field style="padding: 1%" class="col-3" appearance="fill">
        <mat-label>Grupo:</mat-label>
        <input
          type="text"
          matInput
          [readonly]="!rangeDateInit"
          formControlName="groups"
          [matAutocomplete]="groupsList"
          required
        />
        <mat-autocomplete
          #groupsList="matAutocomplete"
          [displayWith]="displayFnGroups.bind(this)"
          required
        >
          <mat-option
            *ngFor="let group of filteredOptionsGroups | async"
            [value]="group.id"
          >
            {{ group.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="error.groups.errors"
        ><span *ngIf="error.groups.errors.required"
        >Campo es requerido</span
        ></mat-error
        >
        <mat-error *ngIf="error.groups.errors">Opción no válida</mat-error>
      </mat-form-field>

      <div style="padding: 1%" class="col-3" appearance="fill">
        <button
          mat-raised-button
          type="submit"
          color="primary"
          disabled="{{ filterForm.invalid }}"
        >
          Buscar
        </button>


      </div>
    </div>
    <div class="row" *ngIf="botonesCarga">
      <div style="padding: 1%" class="col-3" appearance="fill">
        <button mat-raised-button (click)="descargarArchivoBase()"><i class="fi-rr-cloud-download"></i> Descargar
          plantilla base
        </button>

      </div>
      <div style="padding: 1%" class="col-6" appearance="fill">
        <input type="file" formControlName="upload" class="upload"
               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
               (change)="cargarArchivoBase($event)"/>

      </div>
    </div>

    <div class="table-container">
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="Tipo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
            <td mat-cell *matCellDef="let row" style="text-transform: capitalize">{{ row.name }}</td>
          </ng-container>

          <ng-container matColumnDef="Observaciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Observaciones</th>
            <td mat-cell *matCellDef="let row" style="text-transform: capitalize">{{ (row.open_dial == 0) ? 'Requiere configuración' : 'No requiere configuración' }}</td>
          </ng-container>

          <ng-container matColumnDef="Cantidad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cantidad</th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.editable == 1">
                <input
                  style="text-transform: capitalize; text-align: center; margin-left: 8px"
                  matInput
                  [min]="row.quantity"
                  max="5"
                  (keypress)="omitSpecialChar($event)"
                  type="number"
                  placeholder="Escribe la cantidad"
                  required
                  value="{{ row.quantity }}"
                  (focusout)="updateValue($event, row)"
                />
              </span>
              <span *ngIf="row.editable == 0">
                {{ row.quantity }}
              </span>

            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      mat-raised-button
      type="button"
      (click)="saveConfig()"
      color="primary"
      disabled="{{ !Consultados}}">
      Guardar
    </button>
    <button mat-stroked-button mat-dialog-close>Cerrar</button>
  </mat-dialog-actions>
</form>
