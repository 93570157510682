<div class="coaching-main">
    <h2 mat-dialog-title style="font-weight: 700 !important;">Nuevo acompañamiento
        <button style="float: right; margin-top: -.5rem;" mat-icon-button mat-dialog-close>
            <i class="fi-rr-cross" style="font-size: 16px;"></i>
        </button>
    </h2>
    <div style="padding-right: 3.8rem;">
        <mat-divider></mat-divider>
    </div>
    <form mat-dialog-content class="mt-4" [formGroup]="coachingForm">
        <div fxLayout="row">
            <mat-form-field appearance="fill" fxFlex="50%" class="me-3">
                <mat-label>Indicador</mat-label>
                <mat-select cdkFocusInitial formControlName="indicador">
                    <mat-option *ngFor="let indicador of indicadores$ | async" [value]="indicador.id">
                        {{indicador.nombre}}</mat-option>
                </mat-select>
                <mat-error>Por favor seleccione una opción.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="50%">
                <mat-label>Tipo de proceso</mat-label>
                <mat-select (selectionChange)="changeTypeProcess($event)" formControlName="tipo_proceso">
                    <mat-option *ngFor="let proceso of procesos$ | async" [value]="proceso.id">{{proceso.nombre}}
                    </mat-option>
                </mat-select>
                <mat-error>Por favor seleccione una opción.</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row">
            <mat-form-field appearance="fill" fxFlex="50%" class="me-3">
                <mat-label>Cédula coachee</mat-label>
                <input type="number" matInput formControlName="cedula_coachee" (blur)="getAsesor()">
                <mat-error *ngIf="coachingForm.get('cedula_coachee').hasError('required')">Por favor ingrese un número
                    de documento.</mat-error>
                <mat-error *ngIf="coachingForm.get('cedula_coachee').hasError('no_resultado')">Por favor intente con
                    otro número de documento.</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="50%" *ngIf="hiddenByProcess">
                <mat-label>Nombre coachee</mat-label>
                <input type="text" matInput formControlName="nombre_coachee">
            </mat-form-field>
            <!--Seccion oculta solo  para procesos Felicitaciones -->
            <mat-form-field appearance="fill" fxFlex="50%" *ngIf="!hiddenByProcess">
                <mat-label>Campaña</mat-label>
                <mat-select formControlName="campaing">
                    <ng-container *ngIf="campaignJefe; else noJefe">
                        <mat-option *ngFor="let campaign of campaigns$ | async" [value]="campaign.id">{{campaign.name |
                            uppercase}}</mat-option>
                    </ng-container>
                    <ng-template #noJefe>
                        <mat-option *ngFor="let campaign of campaigns$ | async" [value]="campaign.id">{{campaign.name |
                            uppercase }}</mat-option>
                    </ng-template>
                </mat-select>
                <mat-error>Por favor seleccione una opción.</mat-error>
            </mat-form-field>
            <!--Seccion oculta solo  para procesos Felicitaciones -->

        </div>
        <!--Seccion oculta solo  para procesos Felicitaciones -->
        <div fxLayout="row" *ngIf="!hiddenByProcess">
            <mat-form-field appearance="fill" fxFlex="100%">
                <mat-label>Nombre coachee</mat-label>
                <input type="text" matInput formControlName="nombre_coachee">
            </mat-form-field>
        </div>
        <!--Seccion oculta solo  para procesos Felicitaciones -->
        <div fxLayout="row" *ngIf="hiddenByProcess">
            <mat-form-field appearance="fill" fxFlex="50%" class="me-3" *ngIf="hiddenByProcess">
                <mat-label>Campaña</mat-label>
                <mat-select formControlName="campaing">
                    <ng-container *ngIf="campaignJefe; else noJefe">
                        <mat-option *ngFor="let campaign of campaigns$ | async" [value]="campaign.id">{{campaign.name |
                            uppercase}}</mat-option>
                    </ng-container>
                    <ng-template #noJefe>
                        <mat-option *ngFor="let campaign of campaigns$ | async" [value]="campaign.id">{{campaign.name |
                            uppercase }}</mat-option>
                    </ng-template>
                </mat-select>
                <mat-error>Por favor seleccione una opción.</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="50%">
                <mat-label>Indicador inicial</mat-label>
                <input type="number" matInput formControlName="indicador_inicial">
                <mat-error>Por favor ingrese un indicador.</mat-error>
            </mat-form-field>
        </div>
        <!--Seccion oculta solo  para procesos Felicitaciones -->
        <div fxLayout="row" *ngIf="!hiddenByProcess">
            <mat-form-field appearance="fill" fxFlex="50%" class="me-3">
                <mat-label>Indicador inicial</mat-label>
                <input type="number" matInput formControlName="indicador_inicial">
                <mat-error>Por favor ingrese un indicador.</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="50%" >
                <mat-label>Cuartil inicial</mat-label>
                <mat-select formControlName="cuartil_inicial">
                    <mat-option *ngFor="let cuartil of cuartiles$ | async" [value]="cuartil.id">{{cuartil.nombre}}
                    </mat-option>
                </mat-select>
                <mat-error>Por favor seleccione una opción.</mat-error>
            </mat-form-field>
        </div>
        <!--Seccion oculta solo  para procesos Felicitaciones -->
        <div fxLayout="row" *ngIf="hiddenByProcess">
            <mat-form-field appearance="fill" fxFlex="50%" class="me-3">
                <mat-label>Cuartil inicial</mat-label>
                <mat-select formControlName="cuartil_inicial">
                    <mat-option *ngFor="let cuartil of cuartiles$ | async" [value]="cuartil.id">{{cuartil.nombre}}
                    </mat-option>
                </mat-select>
                <mat-error>Por favor seleccione una opción.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="50%">
                <mat-label>Próximo seguimiento</mat-label>
                <input matInput [matDatepicker]="fecha_seguimiento" formControlName="proximo_seguimiento"
                    [min]="startDate" [max]="endDate">
                <mat-datepicker-toggle matSuffix [for]="fecha_seguimiento">
                    <mat-icon matDatepickerToggleIcon><i class="fi-rr-calendar"></i></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #fecha_seguimiento></mat-datepicker>
                <mat-error>Por favor ingrese una fecha.</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" *ngIf="hiddenByProcess">
            <mat-form-field appearance="fill" fxFlex="50%" class="me-3">
                <mat-label>Objetivo KPI</mat-label>
                <input type="number" matInput formControlName="objetivo_kpi">
                <mat-error>Por favor seleccione una opción.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="50%">
                <mat-label>Causa raíz</mat-label>
                <mat-select formControlName="causa_raiz">
                    <mat-option *ngFor="let causa of causas$ | async" [value]="causa.id">{{causa.nombre}}</mat-option>
                </mat-select>
                <mat-error>Por favor seleccione una opción.</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row">
            <mat-form-field appearance="fill" fxFlex="100%">
                <mat-label>Conclusiones de la sesión</mat-label>
                <textarea type="text" matInput formControlName="conclusion_sesion"></textarea>
                <mat-error>Por favor ingrese una conclusión.</mat-error>
            </mat-form-field>
        </div>
        <div fxLayoutAlign="center none" class="mt-4">
            <button mat-stroked-button mat-dialog-close class="btn-cancelar">Cancelar</button>
            <button mat-raised-button color="primary" style="height: 48px;width: 192px;" (click)="saveCoaching()"
                [disabled]="coachingForm.invalid">
                Guardar
            </button>
        </div>
    </form>
</div>