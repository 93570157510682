import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import { CoachingService } from 'src/app/modules/coaching/services/coaching.service';
import { CoachingState } from 'src/app/modules/coaching/state/coaching.state';
import { listEstados } from 'src/app/modules/coaching/state/selectors/selects.selector';
import { solicitudSelect } from 'src/app/modules/coaching/state/selectors/solicitud.selector';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-form-asesor',
  templateUrl: './form-asesor.component.html',
  styleUrls: ['./form-asesor.component.sass']
})
export class FormAsesorComponent implements OnInit {

  estados$:Observable<any> = new Observable();
  estados = [];
  solicitud$:Observable<any> = new Observable();
  contestado:boolean = false;

  estadoForm:FormGroup

  id_coach;

  constructor(
    private store:Store<CoachingState>,
    private fb:FormBuilder,
    private coachService:CoachingService,
    private aRoute:ActivatedRoute,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.aRoute.paramMap.subscribe(params => {
      this.id_coach = params.get('id');
    })
    this.loadState();
    this.createForm();
  }

  createForm(){
    this.estadoForm = this.fb.group({
      estado:['',Validators.required],
      comentario_asesor:['',Validators.required]
    })
  }

  loadState(){
    this.estados$ = this.store.select(listEstados);
    this.estados$.forEach((element) => {
      if (element) {
        this.estados = [];
        element.forEach(estado =>{
          if (estado.nombre == 'Aceptado') {
            this.estados.push(estado);
          }
          if (estado.nombre == 'Rechazado') {
            this.estados.push(estado);
          }
        })
      }
    });
    this.solicitud$ = this.store.select(solicitudSelect);
    this.solicitud$.forEach(solicitud => {
      if (solicitud) {
        if (solicitud[0].acompanamiento.estado_asesor != null) {
          this.estadoForm.disable();
        }else{
          this.estadoForm.enable();
        }
      }
    });
  }

    /**
     * @author Cristian Gallo
     * @createdate 2022-01-26
     * Se encargar de responder el acompañamiento asignado.
    */

  saveSeguimiento(){
    if (this.estadoForm.invalid) {
      return
    }
    Object.assign(this.estadoForm.value,{
      id:this.id_coach,
      ruta:`/mios/coaching/solicitud/${this.id_coach}`,
      mensaje:`El asesor ha respondido el acompañamiento ID-${this.id_coach}`
    });
    this.coachService.solicitudAsesor(this.estadoForm.value).subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202){
        Swal.fire({
          icon:'success',
          title:'¡Excelente!',
          html:`<p>El estado de solicitud se ha gestionado con exito.</p><p><b>ID Coaching ${this.id_coach}</b></p>`,
          showConfirmButton:false,
          timer: 4500
        })
        this.cancelar();
        this.router.navigateByUrl('/mios/coaching/coaching/0');
      }else{
        Tools.swalError('¡Oops!','Lo sentimos, ha ocurrido un error inesperado y no fue posible cambiar el estado de la solicitud, por favor intenta nuevamente.');
      }
    });
  }

  cancelar(){
    this.estadoForm.reset();
    this.estadoForm.get('estado').setErrors(null);
    this.estadoForm.get('comentario_asesor').setErrors(null);
  }

}
