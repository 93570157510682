<div class="admin-groups">

    <div class="header">
        <button mat-icon-button (click)="backRoute()">
            <div class="display:flex">
                <i class="fi-rr-angle-small-left" style="font-size:24px;"></i>
                <span style="vertical-align: super;font: normal normal 600 16px/25px Poppins;letter-spacing: 0px;color: #353535;opacity: 1;">Regresar</span>
            </div>
        </button>
          <br>
          <br>
        <h1>Administración de Grupos</h1>
        <p>Bienvenido al módulo administración de grupos, recuerda que puedes<br>filtrar por<strong> cualquier criterio que encuentres en la tabla.</strong></p>
    </div>

    <div class="row col-12 filter" style="--bs-gutter-x: 0 rem;">

        <mat-form-field class="col-3" appearance="fill">
            <mat-label>Filtro de búsqueda</mat-label>
            <input matInput (keydown.enter)="applyFilter($event)" (blur)="applyFilter($event)" #input>
            <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
        </mat-form-field>

        <div class="col-3"></div>

        <div class="col-4">
            <button mat-raised-button color="primary" (click)="newgroup()" style="height:48px; float: right">Crear nuevo Grupo</button>
        </div>
        <div class="col-2" style="text-align: right; float: right;">
            <button mat-raised-button color="primary" (click)="downloadReport()" style="height:48px"><span class="fi-rr-download"></span> Descargar Reporte</button>
        </div>

    </div>

    <div class="table-container">
        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" matSort>

                  <ng-container matColumnDef="acciones">
                        <th class="start" mat-header-cell *matHeaderCellDef> Acciones</th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button (click)="editgroup(row)" *ngIf="row.state==1">
                                <i class="fi-rr-edit"></i>
                            </button>
                            <button mat-icon-button (click)="changeState(row)">
                                <i class="fi-rr-trash" *ngIf="row.state==1" matTooltip="Desactivar grupo"></i>
                                <i class="fi-rr-check" *ngIf="row.state==0" matTooltip="Activar grupo"></i>
                            </button>
                            <button mat-icon-button (click)="downloadGroup(row)" *ngIf="row.state==1">
                                <i class="fi-rr-download"></i>
                            </button>
                        </td>
                  </ng-container>

                  <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Grupo </th>
                      <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="description">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                      <td mat-cell *matCellDef="let row"> {{row.description}} </td>
                  </ng-container>

                  <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="row.state==0">Inactivo</ng-container>
                        <ng-container *ngIf="row.state==1">Activo</ng-container>
                    </td>
                </ng-container>

                  <ng-container matColumnDef="created_at">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de creación </th>
                      <td mat-cell *matCellDef="let row"> {{row.created_at | date}} </td>
                  </ng-container>

                  <ng-container matColumnDef="nameUser">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Creado por </th>
                      <td mat-cell *matCellDef="let row"> {{row.nameUser}} </td>
                  </ng-container>

                  <ng-container matColumnDef="noUsers">
                      <th class="end" mat-header-cell *matHeaderCellDef mat-sort-header> No. Usuarios </th>
                      <td mat-cell *matCellDef="let row"> {{row.noUsers}} </td>
                  </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro: "{{input.value}}"</td>
            </tr>

          </table>

          <mat-paginator [length]="length"
                         [pageSize]="pageSize"
                         (page)="pageEvent($event)"
                         [pageSizeOptions]="pageSizeOptions">
          </mat-paginator>


        </div>
    </div>
</div>
