import { createAction, props } from "@ngrx/store";
import { SolicitudModel } from "../../core/interfaces/solicitud.interface";

/**
 * @author Cristian Gallo
 * @createdate 2021-12-20
 * Action que cargara la información de la solicitud.
*/

export const loadSolicitud = createAction(
    '[Solicitud] Load solicitud',
    props<{idSolicitud}>()
);

export const loadedSolicitud = createAction(
    '[Solicitud] Loaded solicitud',
    props<{ solicitud:SolicitudModel }>()
)