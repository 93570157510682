import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild, Output, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AlertsService } from '../../../../shared/alerts/alerts.service';
import { saveAs as importedSaveAs } from 'file-saver';
import Swal from 'sweetalert2';
import { CandidatesService } from 'src/app/modules/rrhh/services/candidates.service';
import { GreenBookService } from '../../services/rest/green-book.service';

@Component({
  selector: 'app-import-green-book',
  templateUrl: './import-green-book.component.html',
  styleUrls: ['./import-green-book.component.sass']
})
export class ImportGreenBookComponent implements OnInit {

 
  // MatPaginator Inputs
  dataSource = new MatTableDataSource<any>();
  documento_work = new FormControl('');
  typeDocument = new FormControl('');
  data_name_work = [];
  FrmFormulario: FormGroup;
  length = 100;
  pageSize = 5;
  page = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  total_registros = 5;

  displayedColumns: string[] = ['fecha_carga', 'nombre', 'documento', 'sueldo', 'fecha_ingreso', 'contrato','cod_centro_de_costo']; //Tabla inicial

  documents: any = [];

  private month: string = '';
  private year: string = '';
  private document: string = '';
  private typeDoc: string = '';
  public uploadFile;
  nameFile: any;
  FileData: any;
  @ViewChild('fileInput') fileInput: ElementRef;
  selectMonths = new FormControl('');
  selectYears = new FormControl('');

  constructor(private importGreenBook: GreenBookService,
    private alert: AlertsService,
    private formBuilder: FormBuilder,
    private users: CandidatesService) { }

  ngOnInit(): void {
    //se listan los tipos de documentos
    this.users.getIdTypes().subscribe(resp => {
      this.documents = resp;
    });
    //Tabla historico de desprendibles
    this.get_data(this.pageSize, this.page);
    this.uploadFile = this.formBuilder.group({
      file: ['']
    });
    this.typeDocument.valueChanges.subscribe(resp => {
      this.typeDoc = resp;
    });
  }

  pageEvent(event: any): void {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.get_data(this.pageSize, this.page);
  }

  /**
   * @author David Reinoso
   * Metodo para capturar el documento a filtrar
   */
  onBlurMethod(e) {
    this.document = e;
    this.get_data(this.pageSize, this.page);
  }

  /**
  * @author David Reinoso
  * Metodo para cargar el archivo 
  */
  onChangeFile(e) {
    this.uploadFile.get('file').setValue('');
    this.nameFile = e.target.files[0].name;
    this.FileData = e.target.files[0];
    this.uploadFile.get('file').setValue(this.FileData);
    this.upload();
    e.target.value = null;
  }


  /**
   * @author David Reinoso
   * @createdate 07-Mar-23
  */
  get_data(pageSize, page) {
    this.importGreenBook.getDataGreenBook({
      document: this.document,
      typeDoc: this.typeDoc,
    }, pageSize, page).subscribe(resp => {
      this.dataSource = resp.data.data;
      this.length = resp.data.total;
    });
  }

  /**
  * @author David Reinoso
  * @createdate 07-Mar-23
  */
  downloadTemplate() {
    this.importGreenBook.downloadTemplate().subscribe(resp => {
      importedSaveAs(resp, 'plantilla_libro_verde_nomina.xlsx');
    });
  }

  /**
  * @author David Reinoso
  * @createdate 07-Mar-23
  */
  upload() {
    const formData = new FormData();
    formData.append('file', this.uploadFile.get('file').value);
    console.log( this.uploadFile.get('file').value)
    let str = '¿En verdad deseas <b>cargar</b> el archivo?,' + '\n' + '<br><br><b style="text-align:center;">Nombre del archivo</b><br>' + this.nameFile;
    Swal.fire({
      title: '¿Estás seguro?',
      html: '<pre style="font-family: Poppins; font-size: 14px;">' + str + '</pre>',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.get_data(this.pageSize, this.page);
        this.importGreenBook.uploadFile(formData).subscribe(resp => {
          if (resp?.data?.msg == 'Se generó un Excel con los errores presentados') {
            this.alert_error(resp?.data?.file_url, resp?.data?.count_good, resp?.data?.count_bad);
          }
          if (resp.data == "Proceso realizado correctamente") {
            this.alert.alertSuccessWithoutButton('¡Excelente!', 'Se ha cargado el archivo con éxito.');
          }
        });
      }
    });
    this.uploadFile.get('file').setValue('');
    this.nameFile = '';
    this.FileData = '';
  }


  alert_error(file_error, good, bad) {

    let mgs = "Lo sentimos, ha ocurrido un error inesperado y no <br>fue posible cargar todos los desprendibles.";
    mgs = mgs.concat("</br></br><b>Resumen de la gestión</b> </br>");
    mgs = mgs.concat("</br><b>Desprendibles cargados: " + good + "</b>");
    mgs = mgs.concat("</br><b>Desprendibles con error: " + bad + "</b></br>");

    let btn_import = '</br><button  ';
    btn_import = btn_import.concat(' style="box-shadow:none !important;width: 300px;height: 48px;border-radius: 6px;border: 1px solid #51B0CB;background : #51B0CB; color:white !important"> ');
    btn_import = btn_import.concat(' Cargar reporte <span class="fi-rr-upload"></span></button>');

    let btn_dowload = '</br><button ';
    btn_dowload = btn_dowload.concat(' style="box-shadow:none !important;width: 300px;height: 48px;border-radius: 6px;border: 1px solid #51B0CB;background : white; color: #51B0CB  !important"> ');
    btn_dowload = btn_dowload.concat(' Descargar reporte <span class="fi-rr-download"></span> </button><br>');

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn',
        cancelButton: 'btn'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      icon: 'error',
      title: '¡Oops!',
      showCloseButton: true,
      width: '628px',
      html: mgs,
      showCancelButton: true,
      confirmButtonText: btn_dowload,
      cancelButtonText: btn_import,
      reverseButtons: false,
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(file_error, '_blank');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.fileInput.nativeElement.click();
      }
    })
  }
}
