<div class="main-container">
    <div class="left-section_title">
        <button mat-icon-button (click)="backRoute()">
        <div class="display:flex">
            <i class="fi-rr-angle-small-left" style="font-size:24px;"></i>
            <span style="vertical-align: super;font: normal normal 600 16px/25px Poppins;letter-spacing: 0px;color: #353535;opacity: 1;">Regresar</span>
        </div>
    </button>
    </div>
    <H1>Formulario:</H1>
    <div class="html" [innerHTML]="data"></div>
</div>
