import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClienteSectorService {

  CRM_CIAL = environment.CRM_CIAL;
  COMPRAS_URL = environment.COMPRAS_URL;

  constructor(private http: HttpClient, private authService: AuthService) {


  }
  /**
  * @author javier Castañeda
  * @createdate 2022-10-29
  * Captura los errores en las peticiones 
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  /**
  * @author javier Castañeda
  * @createdate 2022-10-29
  * Servicio que lista el tipo de sector registrados.
  * @returns JSON
  */
  getSector(): Observable<Object> {
    let heades = new HttpHeaders().set('Type-content', 'aplication/json')
    return this.http.get(this.CRM_CIAL + 'sector', {
      headers: heades
    });
  }

}
