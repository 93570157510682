import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NetworkUsersService } from '../../services/rest/network-users.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

interface PasswordValidate {
  uppercase: boolean
  lowercase: boolean
  digits: boolean
  specialCharacters: boolean
};

interface StatePassword {
  text: string,
  color: string
};

@Component({
  selector: 'app-change-network-user-pass',
  templateUrl: './change-network-user-pass.component.html',
  styleUrls: ['./change-network-user-pass.component.sass']
})
export class ChangeNetworkUserPassComponent implements OnInit {
  questions_form: FormGroup
  password_form: FormGroup
  logged_user: any
  questions_labels: any = { first: "", second: "", third: "" }
  match_answers: boolean = false
  hide_pass: boolean = true
  hide_repeat: boolean = true

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private networkUsersService: NetworkUsersService, private alertService: AlertsService,
    private cdr: ChangeDetectorRef, private dialogRef: MatDialogRef<ChangeNetworkUserPassComponent>) { }

  ngOnInit(): void {
    this.validateLastPasswordUpdate()
    this.initializeQuestionsForm()
    this.initializePasswordForm()
  }

  /**
    * Metódo que inicializa el formulario de las preguntas
    * @param event
    * @author Juan Carlos Alonso
    * @createdate 15-05-2024
  */
  initializeQuestionsForm() {
    this.questions_form = this.fb.group({
      first_question: this.fb.group({
        id: ['', []],
        answer: ['', [Validators.required]]
      }),
      second_question: this.fb.group({
        id: ['', []],
        answer: ['', [Validators.required]]
      }),
      third_question: this.fb.group({
        id: ['', []],
        answer: ['', [Validators.required]]
      })
    })
    this.setQuestionFormValue();
  }
  /**
    * Metódo que obtiene las preguntas almacenadas y las asigna al formulario y a los labels
    * @param event
    * @author Juan Carlos Alonso
    * @createdate 15-05-2024
  */
  setQuestionFormValue() {
    this.networkUsersService.getUserSecurityQuestions(0).subscribe(resp => {
      //Se asigna el valor de las ids de las preguntas asociadas al usuario
      this.first_question_controls.id.setValue(resp[0]?.id)
      this.second_question_controls.id.setValue(resp[1]?.id)
      this.third_question_controls.id.setValue(resp[2]?.id)
      //Labels
      this.questions_labels = { first: resp[0]?.question_text, second: resp[1]?.question_text, third: resp[2]?.question_text }

    })
  }
  /**
    * Metódo que valida si la contraseña ya ha sido actualizada el día de hoy, si es asi restringe el acceso al formulario hasta que pasen 24 horas
    * @param event
    * @author Juan Carlos Alonso
    * @createdate 15-05-2024
  */
  validateLastPasswordUpdate() {
    this.networkUsersService.validateLastUpdates(0).subscribe(resp => {
      if (resp.data?.restriction) {
        this.alertService.alertInfo('¡Atención!', resp.data?.message)
        this.dialogRef.close()
      }
    })
  }
  /**
    * Metódo que inicializa el formulario de cambio de contraseña
    * @param event
    * @author Juan Carlos Alonso
    * @createdate 15-05-2024
  */
  initializePasswordForm() {
    this.password_form = this.fb.group({
      user_name: [this.logged_user?.rrhh?.name, []],
      new_password: ['', [Validators.required, Validators.pattern('^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]+'), Validators.minLength(12), Validators.maxLength(20)]],
      repeat_password: ['', [Validators.required, this.matchPasswords('new_password')]]
    })
  }
  /**
    * Metódo que retorna los controles de la primera pregunta
    * @param event
    * @author Juan Carlos Alonso
    * @createdate 15-05-2024
  */
  get first_question_controls() {
    const firstQuestionGroup = this.questions_form.get('first_question') as FormGroup
    return firstQuestionGroup ? firstQuestionGroup.controls : {}
  }
  /**
    * Metódo que retorna los controles de la segunda pregunta
    * @param event
    * @author Juan Carlos Alonso
    * @createdate 15-05-2024
  */
  get second_question_controls() {
    const secondQuestionGroup = this.questions_form.get('second_question') as FormGroup
    return secondQuestionGroup ? secondQuestionGroup.controls : {}
  }
  /**
    * Metódo que retorna los controles de la tercera pregunta
    * @param event
    * @author Juan Carlos Alonso
    * @createdate 15-05-2024
  */
  get third_question_controls() {
    const thirdQuestionGroup = this.questions_form.get('third_question') as FormGroup
    return thirdQuestionGroup ? thirdQuestionGroup.controls : {}
  }
  /**
    * Metódo que retorna los controles del formulario de contraseña
    * @param event
    * @author Juan Carlos Alonso
    * @createdate 15-05-2024
  */
  get pass_form_controls() {
    return this.password_form.controls;
  }
  /**
    * Validación personalizada para verificar que el campo repetir contraseña coincide con la nueva contraseña
    * @param event
    * @author Juan Carlos Alonso
    * @createdate 15-05-2024
  */
  matchPasswords(pass_value: string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[pass_value].value
        ? null
        : { not_match: true };
    };
  }
  /**
    * Metódo envía las respuestas ingresadas y valida si se puede continuar al cambio de contraseña
    * @param event
    * @author Juan Carlos Alonso
    * @createdate 15-05-2024
  */
  validateAnswers(stepper) {
    if (this.questions_form.invalid) {
      this.questions_form.markAllAsTouched()
      return
    }
    this.networkUsersService.validateSecurityQuestionsAnswers(this.questions_form.value).subscribe((resp: any) => {
      if (resp.data?.confirmation) {
        this.match_answers = true
        this.cdr.detectChanges();
        stepper.next()
      }
    },
      (error) => {
        this.alertService.alertError('¡Atención!', error.error.error)
      }
    )
  }
  /**
    * Metódo que envía la nueva contraseña y la actualiza
    * @param event
    * @author Juan Carlos Alonso
    * @createdate 15-05-2024
  */
  changePassword() {
    if (this.password_form.invalid) {
      this.password_form.markAllAsTouched()
      return
    }
    this.networkUsersService.updateNetworkUserPassword(this.password_form.value).subscribe((resp: any) => {
      this.alertService.alertSuccess('', resp.data?.message)
      this.dialogRef.close();
    },
      (error) => {
        this.alertService.alertError('¡Atención!', error.error.error)
      }
    )
  }
  /**
    * Metódo valida la cadena de texto con los regex establecidos.
    * @param password Cadena de texto a validar.
    * @author Fabian Duran
    * @createdate 16-08-2024
  */
  validatePassword(password: string): PasswordValidate {
    return {
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digits: /\d/.test(password),
      specialCharacters: /[@$!%*?&]/.test(password)
    };
  }
  /**
    * Metódo que retorna la configuracion de la semaforizacion generada por el metodo validatePassword.
    * @author Fabian Duran
    * @createdate 16-08-2024
  */
  get checkPassword(): StatePassword {
    const validatePassword = this.validatePassword(this.password_form.get('new_password').value);
    const passedValidations = Object.values(validatePassword).filter(item => item).length;
    switch (passedValidations) {
      case 4: 
        return { text: 'Strong', color: '#4CAF50' };
      case 3: 
        return { text: 'Medium', color: '#FFC107' };
      case 2: 
        return { text: 'Weak', color: '#FF4C4C' };
      default: 
        return { text: 'Very Weak', color: '#FF4C4C' };
    }
  }
}