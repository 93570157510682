<div class="main-container">
    <div class="mt-5">
        <button mat-icon-button class="btn-regresar" [routerLink]="['/mios/auditoria/auditorias']">
            <mat-icon matPrefix style="background-color: #000; border-radius: 50%;"><i class="fi-sr-angle-small-left" style="color: #FFF;"></i></mat-icon>
            Regresar
        </button>
    </div>

    <div class="row mt-5">
        <div class="col-12"><h1>{{title}}</h1></div>

        <div class="col-8">
             <p>Matriz de {{dataHijo.tipo_matriz}}</p>
                <div *ngIf="dataHijo.tipo_peso != 'Cumplimiento'">
                  <h5 *ngIf="dataHijo.tipo_peso == 'Peso en Items'">Peso en Ítems</h5>
                  <h5 *ngIf="dataHijo.tipo_peso == 'Peso en Módulos'">{{ dataHijo.tipo_peso }}</h5>
                </div>
             <h5>Creada el {{creacion}}</h5>
              <p class="primary mt-4">Rango de peso ( {{maximo}} - {{dataHijo.pesoMinimo}})</p>
              <p class="blue mb-4">Peso actual ({{peso_actual | number:'1.0-3'}})</p>
    
        </div>

        <div class="col-4"  id="su_gestion">


         <mat-card class="col-12 subCard">
            <div class="div1">
                <p>Su gestión esta resumida en</p>
            </div>
            <div class="div2">
                <div>
                    <p>Auditoria de hoy</p>
                    <h2>{{hoy_puntaje}}</h2>
                </div>
                <div>
                    <p>Auditoria del mes</p>
                    <h2>{{mes_puntaje}}</h2>
                </div>
            </div>
        </mat-card>


        </div>
    </div>

       <mat-tab-group [selectedIndex]="selected.value"
    (selectedIndexChange)="changePage($event)">
      <mat-tab label="Auditar">
          <div class="row col-12">
              <div fxFlex.gt-lg="100%" fxFlex.gt-md="100%"  >
                  <app-form-auditar [tiene_inteligencia]="inteligencia" [dataCalificacion]="dataCalificacion"
                  [id_matriz]="idMatriz" (envioTipoAuditar)="tipo_auditar=$event" [isRetentionMatrix]="isRetentionMatrix" [showFieldDuration]="showFieldDuration"
                  (envioTab)="selected.setValue($event);dataHijo.calificacion = ''"></app-form-auditar>
              </div>
              <div fxFlex.gt-lg="100%" fxFlex.gt-md="100%"  >
                  <app-calificacion-auditoria [data]="dataHijo" *ngIf="verCalificacion"
                  (calificacionModulos)="dataCalificacion = $event" [tipo_auditar]="tipo_auditar"></app-calificacion-auditoria>
              </div>
          </div>
          <div class="col-12 text-end" >
                <button mat-stroked-button style="color: gray;width: 135px; padding: 2px;" class="col-2 btn-cancelar btn" ngClass.gt-lg="btn-lg" ngClass.gt-md="btn-md" (click)="cancelar()">Cancelar</button>
                <button mat-raised-button color="primary" class="btn" (click)="saveForm()" ngClass.gt-lg="btn-lg" ngClass.gt-md="btn-md">Guardar</button>
          </div>
      </mat-tab>
      <mat-tab label="Retroalimentar">
            <app-retroalimentar [idMatriz]="idMatriz"></app-retroalimentar>
      </mat-tab>
    </mat-tab-group> 


</div>