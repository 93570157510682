import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/rest/auth.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.sass']
})
export class IndexComponent implements OnInit {

  public userName   : any;
  onBoarding        : boolean = false;
  images            : any = [
    "onboarding_1.svg",
    "onboarding_2.svg",
    "onboarding_3.svg",
  ];
  index: number = 0;




  constructor(
    private authService                                 : AuthService,
    public dialog                                       : MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data    : any
  ) { }

  ngOnInit(): void {
    this.userName = this.authService.decryptToken();
    
    if(this.data !== null){
      this.onBoarding = true
    }
  }

  onBoadingModular(){

    const dialogRef = this.dialog.open( IndexComponent ,{
      data : {
        onBoarding : true
      }
    } );

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });

  }

  changeBack(){
    this.index = this.index - 1;
  }
  changeNext(){
    this.index = this.index + 1;

  }

}
