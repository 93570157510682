import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TrainingGroupsService } from '../../../services/training-groups.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { UsersService } from '../../../services/users.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { GroupsCandidateAssignComponent } from '../groups-candidate-assign/groups-candidate-assign.component';
import { TrainingCandidateComponent } from '../training-candidate/training-candidate.component';
import { AuthService } from '../../../../../core/services/rest/auth.service';
import { saveAs as importedSaveAs } from "file-saver";
import { CandidateActionsComponent } from '../candidate-actions/candidate-actions.component';
import { PrebondingComponent } from '../prebonding/prebonding.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import { CandidatesService } from '../../../services/candidates.service';
import { AssingUserComponent } from '../../assign-user/assing-user/assing-user.component';

@Component({
  selector: 'app-groups-view',
  templateUrl: './groups-view.component.html',
  styleUrls: ['./groups-view.component.sass']
})
export class GroupsViewComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['actions', 'name','state', 'id_number', 'mobile_phone', 'email', 'enterprises','campaigns', 'case_glpi'];
  dataSource: MatTableDataSource<any>;
  length: number;
  pageSize: number;
  currentPage = 1;
  showCandidates = false;

  noEdit = false;

  trainerGroupForm: FormGroup;

  groupId;
  trainers: any = [];
  sites: any = [];
  group: any = [];
  groupType:any
  candidates = [];

  statesToFilter:any = [];

  stateSelected = '';
  identification: any = '';
  email: any = '';
  mobile: any = '';
  bonding:boolean= false
  typeFilter:string = null
  constructor(private activatedRoute: ActivatedRoute,
              private groupsService: TrainingGroupsService,
              private fb: FormBuilder,
              private alert: AlertsService,
              private usersService: UsersService,
              public dialog: MatDialog,
              public auth:AuthService,
              private _snackBar: MatSnackBar,
              private candidatesService:CandidatesService) {
    this.groupId = this.activatedRoute.snapshot.paramMap.get('id');
    this.validateForm();
   }

  ngOnInit(): void {


    this.auth.getUser().roles.forEach(element => {
      if(element == 'rrhh::lider-de-usuarios' || element == 'rrhh::coordinador-de-usuarios'){
        this.bonding = true
      }
      if(element == 'rrhh::lider-formador'){
        this.noEdit = true
      }
    });
    this.groupsService.getValidGroups(`formation/grouptype`).subscribe(res=>{
      this.groupType= res
    })
    this.getTrainers();
    this.getSites();
    this.getGroup();
    this.getStatesToFilter();
  }

  getStatesToFilter(){
    this.groupsService.stateToFilter().subscribe( (resp:any) => {
      this.statesToFilter = resp;
    })
  }
  filterByValue(event){
    this.identification=''
    this.email=''
    this.mobile = ''
    this.stateSelected =''
    switch (this.typeFilter) {
      case "Identificación":
        this.identification = event;
       break;
      case "Email":
        this.email= event
       break;
      case "Teléfono":
        this.mobile = event
        break;
      case "Estado":
        this.stateSelected = event;
        break;
    }
    this.getCandidates();
  }
  validateForm(){
    this.trainerGroupForm = this.fb.group({
      name:[{value:null,disabled:true},[Validators.required]],
      modality: [{value:null,disabled:true},[Validators.required]],
      capacity:[{value:null,disabled:true},[Validators.required]],
      mode_rescue: [{value:null,disabled:true},[Validators.required]],
      start: [{value:null,disabled:true},[Validators.required]],
      end: [{value:null,disabled:true},[Validators.required]],
      ubication: [{value:null,disabled:true},[Validators.required]],
      description: [{value:null,disabled:true},[Validators.required]],
      user_trainer_id: [{value:null,disabled:true},[Validators.required]],
      user_responsable_id: [{value:null,disabled:true},[Validators.required]],
      medicalTests:[{value:null,disabled:true}],
      group_type_id:[{value:null,disabled:true}],
      segment:[{value:null,disabled:true}],
      case_glpi:[{value:null,disabled:true}],
    });
  }

  getGroup() {
    this.groupsService.getGroup(this.groupId).subscribe((resp: any) => {
      this.group = resp;
      this.group.start = new Date(resp.start);
      this.group.end = new Date(resp.end);
      this.group.ubication = parseInt(this.group.ubication)
      this.group.modality = parseInt(this.group.modality)
      this.user_trainer_name = this.group.trainerName;
      this.trainerGroupForm.patchValue(this.group);
    });
  }

  getTrainers() {
    this.usersService.getUserByRole('formador').subscribe(resp => {
      this.trainers = resp;
    });
  }

  getSites(){
    this.usersService.getSites().subscribe(resp => {
      this.sites = resp;
    });
  }

  activeEdit() {
    this.trainerGroupForm.disabled? this.trainerGroupForm.enable():this.trainerGroupForm.disable()

  }

  onTabChanged (event) {
    if (event.index === 1){
      this.getCandidates();
    }
  }

  updateGroup() {

    this.alert.alertConfirm('¿Desea actualizar los datos de este grupo?').then( e => {
      if(e.isConfirmed){
        this.groupsService.updateGroup(this.groupId, this.trainerGroupForm.getRawValue()).subscribe( resp => {
          this.ngOnInit();
          this.trainerGroupForm.disable();
          this.alert.alertSuccess('¡Actualizado!','Grupo de Formación editado con éxito');
        });
      }
    })

  }

  getCandidates() {
    this.groupsService.getCandidatesByGroup(this.groupId,{'page':this.currentPage,'state':this.stateSelected,'identification': this.identification, 'email': this.email, 'mobile': this.mobile}).subscribe((resp: any) => {
      this.length = resp.total;
      this.pageSize = resp.per_page;
      this.dataSource = new MatTableDataSource(resp.data);
      this.dataSource.sort = this.sort;
      // this.candidatesByRequest = resp.data;
    });
  }

  registerCandidates() {
    const assignGroupDialog = this.dialog.open( GroupsCandidateAssignComponent, {
      minWidth: '80%',
      disableClose: true,
      data: { group: this.group }
    });
    assignGroupDialog.afterClosed().subscribe( (params) => {
      if(params){
        this.getCandidates();
      }

    });
  }

  getPaginatorData(e){
    this.currentPage = e.pageIndex + 1;
    this.getCandidates();
  }
  commentCandidates(candidate){
      const dialogComment = this.dialog.open( TrainingCandidateComponent,{
         data:{'candidate':candidate,'groupId':this.groupId },
         minWidth:'600px',
         minHeight:'400px'
      })
      dialogComment.afterClosed().subscribe(e => {
        this.getCandidates();
      })
  }
  seeFiles(){
    const dialogRef = this.dialog.open( ExportFiles,{
      width: '516px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.groupsService.downloadFormat(this.groupId,result).subscribe( (e: any) => {
          importedSaveAs(e, `${result.toUpperCase()}.xlsx`);
          this._snackBar.open('El documento se descargó de manera satisfactoria, revisa en tus descargas.', '', {
            duration: 3200,
            verticalPosition: 'top',
            panelClass : ['green-snackbar']
          });
        })
      }
    });
  }

  actionsToCandidates(){
    const candidateActions = this.dialog.open( CandidateActionsComponent, {
      minWidth: '70%',
      data: { group: this.group }
    });
    candidateActions.afterClosed().subscribe( params => {
      if(params == true){
         this.getCandidates();
      }

    });
  }
  openPreBonding(){
    this.dialog.open(PrebondingComponent,{
      minWidth: '80%',
      data: { group: this.group }
    })
  }
  getDocuments(id){
    this.candidatesService.getDocumentSummary(id).subscribe(res=>{
      this.dialog.open(DocsSsummary,{
        minWidth: '80%',
        data: { docs: res }
      })
    })

  }
  user_trainer_name = null;
  searchUsers(rol){
    const saerchDialog = this.dialog.open( AssingUserComponent, {
      data: { role: rol}
    });
    saerchDialog.afterClosed().subscribe(res=>{
      if(res){
        this.trainerGroupForm.get(`user_trainer_id`).setValue(res.user.rrhh_id)
        this[`user_trainer_name`]= res.user.name
      }
    })
  }

}


@Component({
  selector: 'export-files',
  templateUrl: 'export-files.html',
  styleUrls: ['./groups-view.component.sass']
})
export class ExportFiles {
  option:any
}

@Component({
  selector: 'docs-summary',
  templateUrl: 'docs-summary.html',
  styleUrls: ['./groups-view.component.sass']
})
export class DocsSsummary {
  displayedColumns: string[] = [ 'name', 'attached', 'verified'];
  dataSource
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {this.dataSource = new MatTableDataSource(this.data.docs);}

}
