<div class="row col-12 filter" style="padding-top: 40px;">

    <mat-form-field class="col-3" appearance="fill">
        <mat-label>Estado</mat-label>
        <mat-select (selectionChange)="filterByState($event.value)" multiple [(ngModel)]="filterState">
            <mat-option *ngFor="let state of requestStates" [value]="state.id">{{state.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="col-3" appearance="fill">
        <mat-label>Empresa</mat-label>
        <input type="text"
        matInput
        [matAutocomplete]="autoCom"
        (input)="filterCompanies($event.target.value)"
        >
        <mat-autocomplete #autoCom="matAutocomplete" (optionSelected)="filterByCompany($event.option.value)" [displayWith]='displayFnCompania.bind(this)'>
          <mat-option *ngFor="let comp of filteredOptionsCompania" [value]="comp.id">
            {{comp.name}}
          </mat-option>
        </mat-autocomplete>
    </mat-form-field>


    <div class="col-3" >
      <button class="w-100" mat-stroked-button color="primary" (click)="descargaGestion()">
        Descargar gestión <i class="fi-rr-download" ></i></button>
    </div>
    <div class="col-3" >
      <button class="w-100" mat-raised-button color="primary" (click)="newSolicitud()">
        Crear solicitud <i class="fi-rr-add" ></i></button>
    </div>



</div>


<div class="table-container">
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="acciones">
                <th class="start" mat-header-cell *matHeaderCellDef> Acciones</th>
                <td mat-cell *matCellDef="let row" align="left">
                    <div  class="action-icons" >
                      <button mat-icon-button (click)="viewSolicitudAprobada(row)">
                            <i class="fi-rr-eye"></i>
                        </button>
                        <button mat-icon-button (click)="editSolicitud(row)" *ngIf="row.request_state_id == 2">
                                <i class="fi-rr-edit"></i>
                            </button>
                        <button mat-icon-button (click)="procesarSolictud(row)" *ngIf="row.request_state_id == 1">
                            <i class="fi-rr-settings"></i>
                            </button>
                        <button mat-icon-button (click)="generarSolicitud(row)" *ngIf="row.request_state_id == 4">
                              <i class="fi-rr-document-signed"></i>
                        </button>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="request_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de creación </th>
                <td mat-cell *matCellDef="let row"> {{row.created_at|date:'yyyy-MM-dd': 'UTC'}} </td>
            </ng-container>
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Título de solicitud </th>
                <td mat-cell *matCellDef="let row"> {{row.title|slice:0:30}} <small *ngIf="row.description.length > 50">...</small></td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción de solicitud </th>
                <td mat-cell *matCellDef="let row" matTooltip="{{row.description}}" [matTooltipClass]="'my-tooltip'">
                    {{row.description|slice:0:30}}<small *ngIf="row.description.length > 50">...</small>
                </td>
            </ng-container>
            <ng-container matColumnDef="created_by">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre del solicitante </th>
                <td mat-cell *matCellDef="let row"> {{row.created_by}} </td>
            </ng-container>
            <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Empresa </th>
                <td mat-cell *matCellDef="let row"> {{row.company.name}} </td>
            </ng-container>

            <ng-container matColumnDef="state">
                <th class="end" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td mat-cell *matCellDef="let row" align="left">
                  <div style="white-space: nowrap">
                      <i
                        class                     = "span"
                        [style.backgroundColor]   = "row.request_state.color"
                        [style.color]             = "row.request_state.color"
                        >&nbsp;</i> {{row.request_state.name}}

                  </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="7">No hay datos que coincidan con el filtro</td>
            </tr>

        </table>

        <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)" [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>


    </div>
</div>
