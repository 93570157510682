import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soul_bi',
  templateUrl: './soul_bi.component.html',
  styleUrls: ['./soul_bi.component.sass']
})
export class SoulBiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
