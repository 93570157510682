import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AdminServersService } from 'src/app/modules/speech-analytics/services/admin-servers.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-connections',
  templateUrl: './create-connection.component.html',
  styleUrls: ['./create-connection.component.sass']
})
export class CretateConnectionsComponent implements OnInit {

  addConnectionForm: FormGroup;
  dataSource: any; 
  hide: boolean = true;
  type_call: any[] = [ {type_call:'Inbound'}, {type_call:'Outbound'} ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<CretateConnectionsComponent>,
    private form: FormBuilder,
    private adminServersService: AdminServersService
  ) { }

  ngOnInit(): void {
    this.formControl();
  }

  /**
   * Método donde se establecen las validaciones del formulario y adviarte antes de refrescar 
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @method unloadNotification
   * @param {Event} $event - El evento `beforeunload` del navegador.
   * @returns {void}
  */

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.addConnectionForm.dirty) {
      $event.returnValue = true;
    }
  }

  /**
   * Metodo donde se establecen las validaciones del formulario
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */

  formControl(): void {
    this.addConnectionForm = this.form.group({
      name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      server_host: new FormControl('', [Validators.required,Validators.minLength(5), Validators.maxLength(16)]),
      port: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]+$/),Validators.minLength(1), Validators.maxLength(5)]),
      type_connection: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(10)]),
      database_name: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(100)]),
      view: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(100)]),
      user: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(100)]),
      password: new FormControl('', [Validators.required,Validators.minLength(5), Validators.maxLength(100)]),
      web_server: new FormControl('', [Validators.required,Validators.minLength(1)]),
      type_call: new FormControl('', [Validators.required,Validators.minLength(1)]),
    });
  }




  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que evalua en tiempo real los caracteres ingresados en el input, no deja ingresar espacios
   * @param event evento del keypress del input usuario
   */
  omitSpecialChar(event): any {
    let k;
    k = event.charCode;
    return ((k > 32 && k < 126));
  }


  /**
   * Metodo que guarda o actualiza un servidor. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  saveServe() {
    if (this.addConnectionForm.valid) {
      Swal.fire({
        title: '¿Está seguro?',
        text: '¿De continuar con la gestión?',
        icon: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#2CABBC',
        cancelButtonColor: '#FFFFFF',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.adminServersService.CreaServer(this.addConnectionForm.value).subscribe(
            res => {
              Swal.fire({
                title: '¡Exelente!',
                text: 'Se ha creado una nueva conexión',
                icon: 'success',
                confirmButtonColor: '#2CABBC',
                confirmButtonText: 'Continuar'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.dialogRef.close();
                }
              });
            },
            error => {
              console.error('Ha ocurrido un error al crear la conexión', error.message);
              Swal.fire({
                icon: 'error',
                title: 'Ha ocurrido un error al crear la conexión.',
                text: error.message,
                showConfirmButton: true,
                confirmButtonColor: '#2CABBC',
                confirmButtonText: 'Aceptar',
              });
            }
          );
        }
      });
    } else {
      this.addConnectionForm.markAllAsTouched();
    }
  }

  /**
   * Metodo que reinicia los valores del modal. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  cancelSave(): void {
    if (this.addConnectionForm.dirty) {
      Swal.fire({
        title: '¿Estás seguro?',
        text: 'Si cancelas el proceso, perderás toda la información no guardada.',
        icon: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#2CABBC',
        cancelButtonColor: '#FFFFFF',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.addConnectionForm.reset();
          this.dialogRef.close();
        }
      });
    }else {
      this.dialogRef.close();
    }
  }
}
