import { Component, OnInit, ViewChild } from '@angular/core';
import { TestsService } from '../../../services/tests.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-assesments-view',
  templateUrl: './assesments-view.component.html',
  styleUrls: ['./assesments-view.component.sass']
})
export class AssesmentsViewComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['actions', 'name', 'creator', 'duration', 'end'];
  dataSource = new MatTableDataSource();

  length: number;
  pageSize: number;
  currentPage = 1;
  showRequests= false
  constructor(private testsService:TestsService, private router: Router,
    private activatedRoute:ActivatedRoute,) { }

  ngOnInit(): void {
    this.testsService.getRoomsAssessmets().subscribe((resp:any)=>{
    
     this.dataSource = new MatTableDataSource(resp.data);
     this.dataSource.sort = this.sort;
     this.showRequests= true
     this.length = resp.total;
     this.pageSize = resp.per_page;
    })
  }
  viewDetails(id) {
     this.router.navigate(['../assessment', id], {relativeTo: this.activatedRoute});

  }
  getPaginatorData(e){
    this.currentPage = e.pageIndex + 1;
    this.ngOnInit();
  }

}
