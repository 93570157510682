import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicalCandidatesComponent } from './medical-candidates/medical-candidates.component';
import { SharedModule } from '../../../../shared/shared.module';
import { MedicalTestsComponent } from './medical-tests/medical-tests.component';
import { CreateMedicalTestComponent } from './create-medical-test/create-medical-test.component';
import { MedicalTestActionComponent } from './medical-test-action/medical-test-action.component';
import { MedicalTestImportComponent } from './medical-test-import/medical-test-import.component';



@NgModule({
  declarations: [MedicalCandidatesComponent, MedicalTestsComponent, CreateMedicalTestComponent, MedicalTestActionComponent, MedicalTestImportComponent],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class MedicalTestModule { }
