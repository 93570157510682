import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChatService } from '../../../services/chat.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-chat-view-modal',
  templateUrl: './chat-view-modal.component.html',
  styleUrls: ['./chat-view-modal.component.sass']
})
export class ChatViewModalComponent implements OnInit {
  chat_id: any;
  chatData: any;
  uniqueAgentNames: any;

  @ViewChild('containerViewReport') containerViewReport: ElementRef;
  @ViewChild('caseDetails') caseDetails: MatExpansionPanel;
  @ViewChild('conversation') conversation: MatExpansionPanel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private chatService: ChatService,
  ) {}

  ngOnInit(): void {
    this.chat_id = this.data.id;
    this.loadDataConversation();
  }

  /**
   * Obtener la infor,acion de la conversacion
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  loadDataConversation(): void {
    this.chatService.getChatById(this.chat_id).subscribe(
      (response) => {
        this.chatData = response.data[0];
        this.extractUniqueAgentNames();
      },
      (error) => {
        console.error('Erroral consultar la conversación', error);
      }
    );
  }
  
  /**
   * Metodo estraer el nombre dde los agentes que hablan en la conversacion
   * @author Yeison sepulveda
   * @createdate 2024-07-15
   * @returns void {void} 
   */

  extractUniqueAgentNames(): void {
    if (this.chatData && this.chatData.chat_details) {
      const agentNames = this.chatData.chat_details
        .filter(detail => detail.answered_by && detail.agent_name)
        .map(detail => detail.agent_name)
        .filter((value, index, self) => self.indexOf(value) === index);

      this.uniqueAgentNames = agentNames;
    }
  }

  /**
   * Metodo descarga e imprime pantallazo del modal
   * @update Yeison Sepulveda
   * @updatedate 29-05-2024
  */
  async onClickPrintPdf() {
    this.caseDetails.open();
    this.conversation.open();
    await new Promise(resolve => setTimeout(resolve, 1000));
    const content = this.containerViewReport.nativeElement;
  
    const margin = 13;
    const filename = this.generateFilename();
  
    const options = {
      margin: margin,
      filename: filename,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 2,
      },
      jsPDF: {
        unit: 'mm',
        format: 'a4',
        orientation: 'portrait'
      },
      pagebreak: {
        mode: ['avoid-all', 'css', 'legacy']
      }
    };
  
    const testPDF = html2pdf().from(content).set(options).toContainer().toCanvas().toImg().toPdf();
    testPDF.get('pdf').then(function (pdf) {
      const totalHeight = content.scrollHeight * 0.264583 + 2 * margin;
      pdf.internal.pageSize = { width: 210, height: totalHeight };
    });
  
    testPDF.save();
  }


  /**
   * Metodo generar el nombre del documento a descargar 
   * @author Yeison Sepulveda
   * @createdate 2024-0-03
   * @return nombre del archivo PDF
  */
  
  generateFilename(): string {
    const currentDate = new Date().toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '');
  
    const currentTime = new Date().toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).replace(/:/g, '');
  
    const dateTimeString = `${currentDate}${currentTime}`;
    return `conversacion_${this.chatData.phone}_${dateTimeString}.pdf`;
  }

}
