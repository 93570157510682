<div id="dialog">
    <button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
        <i class="fi-rr-cross"></i>
    </button>
    <ng-container *ngIf="data">
        <h3 mat-dialog-title>{{data.name}}</h3>
        <hr>
        <mat-dialog-content>
          <ol>
              <li *ngFor="let funtion of data.function">
                  {{funtion.name}}
              </li>
          </ol>

        </mat-dialog-content>

    </ng-container>
</div>

