import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { Observable } from 'rxjs';

const CRM_URL = environment.CRM2_URL;

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  constructor(private http: HttpClient, private AuthService: AuthService) { }

  getGroups(direction, campaignid) {

    const httpOptions = {
      params: new HttpParams().set("campaign_id", campaignid),
      headers: new HttpHeaders({

        Authorization: 'Bearer' + this.AuthService.getUser().access_token,
      }),
    };
    return this.http.get<any>(`${CRM_URL}${direction}`, httpOptions);
  }
  getGroupsByUser(direction, id,per_page,page) {
    return this.http.get<any>(`${CRM_URL}${direction}${id}?per_page=${per_page}&page=${page}`);
  }

  /**
   * Metodo que se encarga de consultar un usuario por numero y tipo de documento
   * @param documen:string {string} numero de documetno a consultar
   * @param typeDocument:string {string} id tipo de documento
   * @returns Observable {Observable}
   */
  getUserByDocument(document:string  , typeDocument:string) {
    return this.http.get<any>(`${CRM_URL}user-by-doc/${document}/${typeDocument}`);
  }

  putState(id, state) {
    return this.http.put(`${CRM_URL}deletegroup/` + id, state);

  }


  createGroup( body) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer' + this.AuthService.getUser().access_token,
      }),
    };
    return this.http.post<any>(`${CRM_URL}savegroup`, body, httpOptions);
  }

  getUsers(id) {
    return this.http.get<any>(`${CRM_URL}searchUser/${id}`);
  }

  getGroupById(id) {
    return this.http.get<any>(`${CRM_URL}searchgroup/${id}`);
  }

  updateGroup(id, body) {
    return this.http.put<any>(`${CRM_URL}updategroup/${id}`, body);
  }

  /**
   * Metodo que se encarga e obtener los usuarios por los id de campa;a que se le envien
   * @author Juan David Guerrero Vargas
   * @param campaigns:string[] arreglo con los id de campana a consultar
   */
  getUsersByCampaign(campaigns:string[], idGrupo:number):Observable<any>{
    return this.http.post<any>(`${CRM_URL}searchUsers/${idGrupo}`, {  campaings:campaigns  })
  }

}
