<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<h3>Comentarios globales de proceso</h3>
<mat-divider></mat-divider>
<div class="name-candidate">
        <h4>{{candidate?.fullname}}</h4>
        <button style="margin-left: 20px;" *ngIf="isRolOPerative || isRoleAdministrative || isRoleAnalyst || isRoleRecruiter" mat-button matSuffix matTooltip="Transferir candidato a otra solicitud"
         (click)="transferCandidate()">Transferir candidato &nbsp; <mat-icon>compare_arrows</mat-icon> </button>
</div>
<mat-dialog-content>


    <mat-tab-group mat-align-tabs="right" #tabGroup>
        <mat-tab label="Comentarios">
            <div class="details">
                <div class="comment-list">
                    <p *ngIf="comments.length == 0" >No hay comentarios creados relacionados a este candidato</p>
                    <mat-list>
                        <mat-list-item *ngFor="let row of comments">
                            <mat-divider></mat-divider>
                            <div mat-line><b>{{row.comment_type}}</b> </div>
                            <div mat-line matSubheader>{{row.comment}} <br><span>{{row.user_creator}} |  <strong>Creado</strong>  {{row.created_at | date:'dd/MM/yyyy'}}</span>
                                <span *ngIf="row.updated"> &nbsp; <strong>Editado</strong>  {{row.updated | date:'dd/MM/yyyy'}}</span> </div>
                            <button mat-icon-button matSuffix *ngIf="row.user_creator_id == userLogged.id" (click)="editComment(row)"> <i class="fi-rr-edit"></i> </button>

                        </mat-list-item>
                    </mat-list>

                </div>
            </div>
        </mat-tab>
        <mat-tab label="Otros Comentarios">
            <div class="details">
                <div class="comment-list">
                    <p><strong> Comentarios asociados a otros procesos previos</strong></p>
                    <p *ngIf="otherComments.length == 0">No hay comentarios creados relacionados a otras solicitudes</p>
                        <mat-list>
                            <mat-list-item *ngFor="let row of otherComments">
                                <div mat-line><b>{{row.comment_type}}</b></div>
                                <div mat-line matSubheader>{{row.comment}} <br> <span>{{row.user_creator}} | <strong>Creado</strong>{{row.created_at | date:'dd/MM/yyyy'}}</span>
                                   <span *ngIf="row.updated">  &nbsp; <strong>Editado</strong>  &nbsp; {{row.updated | date:'dd/MM/yyyy'}}</span>
                                </div>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </mat-list>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="Crear Comentario" [disabled]="!canCreateComments">
            <div class="details">
                <p><strong> Crear comentarios y asignar estados a candidato</strong></p>
                <form [formGroup]="commentForm" class="comment-form">
                    <mat-form-field appearance="fill" >
                        <mat-label>Tipo de Comentario</mat-label>
                        <mat-select formControlName="comment_type_id"  (ngModelChange)="changeComment($event)">
                            <mat-option *ngFor="let row of commentsType" [value]="row.id">
                                {{row.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                     <!-- Para Excluir (Mejorar esto) -->
                    <mat-form-field appearance="fill" *ngIf="statusComment == 'excluir'">
                        <mat-label>Cualquier proceso</mat-label>
                        <mat-select tipoDocumento formControlName="allProcess">
                            <mat-option value="1">Si</mat-option>
                            <!-- La option NO sera 2 para poder idenficarlo, si fuese 0 entonces lo asume como false, null, empty -->
                            <mat-option value="2">No</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <p color="warn" *ngIf="commentForm.value.allProcess == 2 && statusComment == 'excluir'">Será excluido únicamente de esta campaña</p>
                    <mat-form-field appearance="fill" >
                        <mat-label>Comentario</mat-label>
                        <textarea matInput formControlName="comment" rows="5"></textarea>

                    </mat-form-field>
                    <button mat-flat-button color="primary" (click)="saveComment();tabGroup.selectedIndex = 0">Guardar</button>
                </form>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
