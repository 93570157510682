import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { SABLE_URL } = environment;

@Injectable({
  providedIn: 'root'
})
export class AdminServersService {
  constructor(private httpClient: HttpClient) { }

  /**
    * @author Fabian Duran
    * @createdate 2023-06-20
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-20
    * Metodo que retorna los servidores registrados en el sistema.  
  */
  getServers(paginator: any, filter: string): Observable<any> {
    return this.httpClient.get<any>(`${SABLE_URL}server?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&filter=${filter}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-20
    * Metodo que retorna el servidor seleccionado por ID.  
  */
  showServer(id: number): Observable<any> {
    return this.httpClient.get<any>(`${SABLE_URL}server/${id}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-20
    * Metodo que crea o actualiza el servidor seleccionado. 
    * @param server Informacion proveniente del servidor. 
    * @param typeManagmentServer Tipo de gestion con la cual se va a trabajar la información del servidor.
  */
  saveOrUpdateServer(server: any, typeManagmentServer: string): Observable<any> {
    const urlString = typeManagmentServer === 'create' ? `${SABLE_URL}server` : `${SABLE_URL}server/${server.id}`;
    return this.httpClient.post<any>(urlString, server).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-20
    * Metodo que elimina un servidor del sistema. 
    * @param id Id del servidor seleccionado. 
  */
  deleteServer(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${SABLE_URL}server/${id}`).pipe(
      catchError(this.handleError)
    );
  }
}