<div class="main-container">
    <div class="mt-5">
        <button mat-icon-button class="btn-regresar" [routerLink]="['/mios/calidad/inteligencia-negocios/1']">
            <mat-icon matPrefix style="background-color: #000; border-radius: 50%;"><i class="fi-sr-angle-small-left" style="color: #FFF;"></i></mat-icon>
          {{config.btnBack}}
        </button>
    </div>
    <div class="mt-5">
        <h1>{{nameForm}}</h1>
        <p>{{config.subtitle}}</p>
        <button mat-icon-button class="ver-matrices" (click)="matrices()">{{config.btnShow}}
            <mat-icon><i class="fi-rr-arrow-small-right"></i></mat-icon>
        </button>
    </div>
    <div class="mt-5" *ngIf="questions">
        <h3>{{config.questions}}</h3>
        <mat-divider></mat-divider>
        <app-tabla-preguntas [checkbox]="questions" [idForm]="id_form" (arraySelection)="selection($event)"
        (cancelarEvent)="cancelar()">
        </app-tabla-preguntas>
    </div>
    <div class="mt-3 btn-row" *ngIf="!questions">
        <div></div>
        <button mat-raised-button color="primary" class="btn-edit" (click)="edit()">
            {{config.btnUpdate}}
            <i class="fi-rr-edit"></i>
        </button>
    </div>
    <div class="mt-5" *ngIf="showForm" style="padding-bottom: 3em;">
        <h3>{{config.form}}</h3>
        <mat-divider style="margin-bottom: 2em;"></mat-divider>
        <div #listQuestion cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListDisabled]="!questions"
        [cdkDropListData]="questionsForm">
            <div class="question-box" *ngFor="let selection of questionsForm; let i = index" cdkDrag>
                <button mat-icon-button *ngIf="questions" (click)="removeQuestion(selection.pregunta.id)">
                    <mat-icon style="color: #717171;"><i class="fi-rr-trash"></i></mat-icon>
                </button>
                <p>{{selection.pregunta.question}}</p>
                <div *ngIf="selection.pregunta.tipo_pregunta.key == 'respuesta_corta'">
                    <mat-form-field appearance="fill">
                        <mat-label>{{config.fields.answer}}</mat-label>
                        <input matInput [disabled]="!questions">
                    </mat-form-field>
                </div>
                <div *ngIf="selection.pregunta.tipo_pregunta.key == 'selección_múltiple'">
                    <label id="multiple"></label>
                    <mat-radio-group
                    aria-labelledby="multiple"
                    class="radio-group"
                    [(ngModel)]="favoriteSeason">
                    <mat-radio-button class="example-radio-button"
                    *ngFor="let opcion of selection.pregunta.opciones_respuesta_form_pregunta_activos"
                    [value]="opcion.id" style="margin-bottom: 1em;"
                    [disabled]="!questions">
                        {{opcion.name}}
                    </mat-radio-button>
                    <mat-radio-button class="example-radio-button" style="margin-bottom: 1em;"
                    [disabled]="!questions">
                        {{config.fields.other}}
                    </mat-radio-button>
                    <mat-form-field appearance="fill">
                        <mat-label>{{config.fields.cual}}</mat-label>
                        <input matInput [disabled]="!questions">
                    </mat-form-field>
                    </mat-radio-group>
                </div>
                <div *ngIf="selection.pregunta.tipo_pregunta.key == 'escala_lineal'">
                    <mat-radio-group aria-label="lineal">
                        <mat-radio-button *ngFor="let escala of selection.pregunta.escala"
                        [value]="escala"
                        [disabled]="!questions">{{escala}}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="selection.pregunta.tipo_pregunta.key == 'lista_desplegable'">
                    <mat-form-field appearance="fill">
                        <mat-label>{{config.fields.options}}</mat-label>
                        <mat-select multiple>
                          <mat-option *ngFor="let opcion of selection.pregunta.opciones_respuesta_form_pregunta_activos"
                          [value]="opcion.id"
                          [disabled]="!questions">
                              {{opcion.name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
                <div *ngIf="selection.pregunta.tipo_pregunta.key == 'fecha'">
                    <mat-form-field appearance="fill">
                        <mat-label>{{config.fields.date}}</mat-label>
                        <input matInput [matDatepicker]="fechaPregunta" [disabled]="!questions">
                        <mat-datepicker-toggle matSuffix [for]="fechaPregunta">
                            <mat-icon matDatepickerToggleIcon><span class="fi-rr-calendar"></span></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #fechaPregunta></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="selection.pregunta.tipo_pregunta.key == 'hora'">
                    <mat-form-field appearance="fill">
                        <mat-label>Hora</mat-label>
                        <input matInput type="time" [disabled]="!questions">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>
