import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { ActivatedRoute } from '@angular/router';
import { BookingCreateComponent } from './../../../modules/reservas/modules/bookings/booking-create/booking-create.component';
import { TypeResources } from './../../../modules/reservas/modules/bookings/bookings-main/bookings-main.component';
import { MatDialog } from '@angular/material/dialog';
import { ResourcesService } from './../../../modules/reservas/services/resources.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, filter } from 'rxjs/operators';
import { QuestionBase } from 'src/app/core/models/questions-base';
import { DateAdapter } from '@angular/material/core';
import { DynamicFormServiceService } from '../../../core/services/rest/dynamic-form-service.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { RequireMatch } from 'src/app/core/services/utils/question-control.service';

interface Option {
  id: string,
  name: string
}

@Component({
  selector: 'shared-form-questions',
  templateUrl: './form-questions.component.html',
  styleUrls: ['./form-questions.component.sass']
})
export class FormQuestionsComponent implements OnInit {
  myControl = new FormControl();
  options: any = [];
  filteredOptions: Observable<any[]>;
  @Output() addField = new EventEmitter<any>();
  @Output() addDependece = new EventEmitter<any>();
  @Input() question: QuestionBase<any>;
  @Input() idSection: QuestionBase<any>;
  @Input() form: FormGroup;
  nameFile: string;
  oldValue: string;

  @Output() addFile = new EventEmitter<any>();


  constructor(private fb: FormBuilder,
              private adapter: DateAdapter<any>,
              private dynamicService: DynamicFormServiceService,
              private destroyService: DestroyComponentService,
              public resourcesService:ResourcesService,
              private dialog_agenda: MatDialog,
              private route: ActivatedRoute,
              public alertService:AlertsService
              ) {
                this.adapter.setLocale('es');
   }


   ngOnInit(): void {
     if (this.form.get(this.question.key) !== null) {
      this.oldValue = this.question.value;
      if(this.question.controlType == 'dropdown-request'){
        this.getOptions(this.question)
       .then(() =>{
         let index = this.question.options.findIndex(state => JSON.stringify(state) === JSON.stringify(this.question.value));
         if(index >= 0){
          this.form.controls[this.question.key].setValue(this.question.options[index])
         }
        });
      }
  
      if (this.question.controlType === 'file') {
        if ((this.question.value === null || this.question.value === '') && this.question.nameFile) {
          this.form.controls[this.question.key].setValidators(null);
          this.form.controls[this.question.key].updateValueAndValidity();
        }
      }
      this.filteredOptions = this.form.get(this.question.key).valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(state => state ? this._filter(state) : this.question.options.slice(0, 5))
      );
     }
     
  }
  private _filter(value: string): any[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.question.options.filter(state => state.name.toLowerCase().includes(filterValue));
    }
  }

  displayFn(id): any {
    if (!id) {
      return '';
    }
    const index = this.question.options.findIndex(state => state.id === id);
    return this.question.options[index].name;
  }

  /**
   * @author Karol García
   * @createdate 2021-04-12
   * Método que devuelve el nombre elegido en loa autocomplete
   */
  displayFn2(option): any {
    if (!option) {
      return '';
    }
    let index = this.question.options.findIndex(state => JSON.stringify(state) === JSON.stringify(option));
    return this.question.options[index].name;
  }


  getErrorMessage() {
    if (this.form.controls[this.question.key]) {
      if (this.form.controls[this.question.key].hasError('required')) {
        return `${this.question.label} es requerido`;
      }
      if (this.form.get(this.question.key).hasError('invalidAutocompleteObject')) {
        return `No es una opción válida`;
      }
      if (this.form.get(this.question.key).hasError('maxlength')) {
        return `${this.question.label} supera el límite de ${this.question.maxLength} caracteres`;
      }
      if (this.form.get(this.question.key).hasError('minlength')) {
        return `${this.question.label} debe tener ${this.question.minLength} mínimo caracteres`;
      }
      if (this.form.get(this.question.key).hasError('email')) {
        return `No es un correo electrónico válido`;
      }
    }
      
  }
  addQuestion(question) {
    this.addField.emit(question);
  }
  optionSelected(event, question) {
    this.editedAdviser(event);
    
    let aux: any;
    aux = {
      idFather: question.id,
      idValue: event,
      idSection: this.idSection
    };
    this.addDependece.emit(aux);
  }

  onFileSelected(e, id) {
    this.editedAdviser(e);
    this.question.value = e.target.files[0].name;
    this.question.nameFile = e.target.files[0].name;
    const data = {
      id: id,
      file: e.target.files[0]
    };
    this.addFile.emit(data);

  }

   /**
   * @author Karol García
   * @createdate 2021-04-13
   * Metodo que consulta las opciones por campo tipo dropdown-request
   * ***  IMPORTANTE  ********
   * La respuesta debe ser {  id  : " STRING " , name: "Test"} Ex. { "id":"1","name":"Test"}
   *
   */
   getOptions(question) {
    var promise = new Promise((resolve) => {
      if(question.apiServe){
        this.dynamicService.getData(question.apiServe,`dropdown/options/${question.key}`).subscribe( (options:any) => {
          if(options.length > 7){
              this.question.controlType ='autocomplete2'
              this.form.controls[this.question.key].setValidators([
                RequireMatch,
              ]);
            }
            this.question.options = options;
           resolve(options);
         });
        }
  });
  return promise;
  }

  downloadFile(value: any) {
    if (typeof value.value === 'string' && value.value !== '') {
      const url = value.value.split('/');
      const urlId = url[url.length - 1];
      const data = new FormData();
      data.append( 'url', urlId);
      this.dynamicService.downloadFile(data).subscribe((resp: any) => {
        importedSaveAs(resp, value.nameFile);
      });
    }
    else {
      let urlId = null;
      const data = new FormData();
      if (!value.idValue) {
        urlId = value.value;
      } else {
        urlId = value.idValue;
      }
      data.append( 'url', urlId);
      this.dynamicService.downloadFile(data).subscribe((resp: any) => {
        importedSaveAs(resp, value.nameFile);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

  //Valida si el campo fue editado por el asesor y asigna el valor
  editedAdviser(event){
    if (event !== this.oldValue) {
      this.question['edit_by_adviser'] = true;
    }else{
      this.question['edit_by_adviser'] = false;
    }
  }

  openAgenda( ){

    this.route.params.subscribe(params => {
      this.resourcesService.getResources('resourcetypes', {idForm:params['id']}).subscribe(res=>{
        const resourceTypes = res;
        if( resourceTypes.length == 0 ){
          this.alertService.alertWarning('¡Atención!', "No hay recursos creados para este formulario");
        }
        else
        {
          const resourcesDialog = this.dialog_agenda.open( TypeResources, {
            data: { types: resourceTypes}
          });
          resourcesDialog.afterClosed().subscribe(type=>{


            const reservasDialog = this.dialog_agenda.open( BookingCreateComponent, {
              data: {
                id: type[0]["id"],
                type: type[0]["name"]
              }
            });
            reservasDialog.afterClosed().subscribe(dateResult => {
              this.form.controls[this.question.key].setValue(dateResult);
            })


          })

        }


      })
    });

  }

}
