import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../core/services/rest/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  private server =  environment.RRHH_URL;

  user;
  constructor(private http:HttpClient, private activeRoute: ActivatedRoute, private authService:AuthService) {
    this.user = this.authService.getUser();

   }

  getRequestsByUser(entries) {
    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}requests/role`, {params});

  }

  getCampaignsByUser(){
    const entries = {};
    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}campaigns/role`, {params});
  }

  getRequestDetail(id) {
    const entries = {};
    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}request/${id}`, {params});
  }
  assignRecluter(requestId,entries){
    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.post(`${this.server}request/assign/recluter/${requestId}`, params);
  }

  getAllRequests(oldRequestId?){
     const entries = {};
     entries['userId'] = this.user.rrhh_id;
     entries['requestId'] = oldRequestId;
     const params = new HttpParams( { fromObject: entries } );
     return this.http.get(`${this.server}requests/all`, {params});
  }
  getRecord(direction){
    return this.http.get<any>(`${this.server}${direction}`);
  }
}
