<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>

<h3 mat-dialog-title>Rechazar Solicitud</h3>
<hr>
<mat-dialog-content >
    <div class="content">
        <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M47 0C21.0441 0 0 21.0441 0 47C0 72.9588 21.0441 94 47 94C72.9588 94 94 72.9618 94 47C94 21.0441 72.9585 0 47 0ZM47 85.1874C25.9089 85.1874 8.81264 68.0911 8.81264 47C8.81264 25.9089 25.9089 8.81264 47 8.81264C68.0911 8.81264 85.1874 25.9089 85.1874 47C85.1874 68.0911 68.0911 85.1874 47 85.1874ZM47.003 20.6123C43.6367 20.6123 41.0896 22.3691 41.0896 25.2066V51.2328C41.0896 54.0733 43.6364 55.8241 47.003 55.8241C50.2871 55.8241 52.9163 54 52.9163 51.2328V25.2066C52.916 22.4365 50.2871 20.6123 47.003 20.6123ZM47.003 61.6875C43.7688 61.6875 41.1366 64.3197 41.1366 67.5565C41.1366 70.7877 43.7688 73.4199 47.003 73.4199C50.2371 73.4199 52.8663 70.7877 52.8663 67.5565C52.866 64.3194 50.2371 61.6875 47.003 61.6875Z" fill="#EFBD8E"/>
        </svg>

        <br>
        <br>

        <div class="texto">
            <p>¿Estás seguro que deseas Rechazar la Solicitud?</p>
        </div>
        
        <form (ngSubmit)="onSubmit(formulario)" [formGroup]="formulario">

            <mat-form-field class="example-full-width" class="col-12">
            <mat-label>¿Por qué? *</mat-label>
            <textarea matInput placeholder="Escribe tu comentario" formControlName="campo" rows="2"></textarea>
            </mat-form-field>

            <br>
            <br>
            <br>
            <div class="action_btns">
                <button mat-stroked-button color="warn" mat-dialog-close type="button">No, Cancelar</button>
                <button mat-flat-button color="primary" [ngClass]="{'primary': formulario.valid, 'secundary': !formulario.valid}" type="submit" [disabled]="!formulario.valid">Si, Rechazar</button>
            </div>
        </form>
    </div>
</mat-dialog-content>
