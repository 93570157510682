<button class="position-close" mat-icon-button aria-label="close dialog" mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>
<div class="content">
  <div class="position" fxLayout="row" fxLayoutAlign="center">
    <img src="../../../assets/images/lockOutline.png" width="100" height="100">
  </div>
  <div class="col-12" *ngIf="!showCode">
    <mat-dialog-content class="mat-typography text-center mt-4">
      <h3 mat-dialog-title>Verifica tu ingreso</h3>
      <p cdkFocusInitial>Selecciona el metodo de verificación que prefieras
        para acceder a tu cuenta.
      </p>
      <br>
      <br>
    </mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <button *ngIf="validEmail != false" mat-stroked-button class="btn-track" style="width: 100%;padding: 0;"
          color="primary" (click)="sendEmail()">
          <mat-icon class="--icon">email</mat-icon>
          <span class="color">Envíame un correo a {{email}}</span>
        </button>
        <button *ngIf="validEmail == false" mat-stroked-button class="btn-track" style="width: 100%;padding: 0;"
          color="primary" (click)="sendEmail()" disabled>
          <mat-icon class="--icon">email</mat-icon>
          <span class="color">{{email}}</span>
        </button>
      </div>
      <div class="col-12">
        <button *ngIf="data.phoneNumber != false" mat-stroked-button style="width: 100%;padding: 0;"
          class="btn-track mt-3" color="primary" (click)="sendSms()">
          <mat-icon class="--icon">sms</mat-icon>
          <span class="color">Envíame un sms a {{cellphone}}</span>
        </button>
        <button *ngIf="data.phoneNumber == false" mat-stroked-button style="width: 100%;padding: 0;"
          class="btn-track mt-3" color="primary" (click)="sendSms()" disabled>
          <mat-icon class="--icon">sms</mat-icon>
          <span class="color">{{cellphone}}</span>
        </button>
      </div>
      <span *ngIf="validEmail == false && data.phoneNumber == false" class="text-center mt-3">
        Por favor comunicate con el administrador para validar tus datos.
      </span>
    </div>
  </div>
  <form class="row form" [formGroup]="codeForm" (ngSubmit)="sendCode(codeForm)" *ngIf="showCode">
    <div class="col-12">
      <mat-dialog-content class="mat-typography text-center mt-4">
        <h3 mat-dialog-title>Verifica tu ingreso</h3>
        <p cdkFocusInitial *ngIf="showEmail">Ingresa el codigo que te hemos enviado al correo {{email}}</p>
        <p cdkFocusInitial *ngIf="showSms">Ingresa el codigo que te hemos envíado por SMS al {{cellphone}}</p>
        <br>
        <mat-form-field class="col-12" appearance="fill">
          <mat-label>Codigo de Ingreso</mat-label>
          <input matInput #Code maxlength="5" formControlName="code" placeholder="Ex. 94105" required>
          <mat-hint align="end">{{Code.value.length}} / 5</mat-hint>
          <mat-error *ngIf="error.code.errors"><span *ngIf="error.code.errors.required">¡Advertencia! Este campo es
              requerido</span></mat-error>
          <mat-error *ngIf="error.code.errors"><span *ngIf="error.code.errors.minlength">¡Aún falta! Recibimos mínimo 5
              caracteres</span></mat-error>
        </mat-form-field>
        <br>
        <div class="col-12 forgotPass">
          <a (click)="resendCode()"><b class="link">Reenviar codigo</b></a>
        </div>
      </mat-dialog-content>
      <div class="row mt-3">
        <div class="col">
          <button mat-stroked-button style="width: 100%;" (click)="back()">Volver</button>
        </div>
        <div class="col">
          <button mat-raised-button type="submit" style="width: 100%;" color="primary"
            [disabled]="codeForm.invalid">Enviar</button>
        </div>
      </div>
    </div>
  </form>
</div>