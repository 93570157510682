import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

const CRM_URL = environment.CRM2_URL;

@Injectable({
  providedIn: 'root'
})
export class OutboundManagerService {

  constructor(private http: HttpClient) { }

  getDiffusionInfo(formId){
    return this.http.get<any>(`${CRM_URL}managements/outbound/create/${formId}`);
  }

  listDiffusions(formId){
    return this.http.get<any>(`${CRM_URL}managements/outbound/${formId}`);
  }

  filterDiffusion(formId, filter){
    return this.http.post<any>(`${CRM_URL}managements/outbound/${formId}`, filter);
  }

  showDiffusion(diffusion_id){
    return this.http.get<any>(`${CRM_URL}managements/outbound/show/${diffusion_id}`);
  }

  saveDiffusion(diffusion){
    return this.http.post<any>(`${CRM_URL}managements/outbound/save`, diffusion);
  }

  downloadDiffusionEmailFile(id){
    return this.http.get<any>(`${CRM_URL}managements/outbound/attachments/download/${id}`, {responseType: 'blob' as 'json'});
  }

  removeDiffusionEmailFile(id){
    return this.http.delete<any>(`${CRM_URL}managements/outbound/attachments/delete/${id}`);
  }

  sendDiffusion(diffusion){
    return this.http.post<any>(`${CRM_URL}managements/outbound/send`, diffusion);
  }

  sendEmailTest(emailTest){
    return this.http.post<any>(`${CRM_URL}managements/outbound/send/email/test`, emailTest);
  }

  getWhatsappTemplates(accountId){
    return this.http.get<any>(`${CRM_URL}managements/outbound/whatsapp/templates/${accountId}`);
  }

  getFieldForms(formId, tags){
    return this.http.post<any>(`${CRM_URL}managements/outbound/${formId}/fields`, tags);
  }
  
}