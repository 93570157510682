import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { QuestionBase } from 'src/app/core/models/questions-base';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { PermitService } from 'src/app/core/services/utils/permit.service';
import { QuestionControlService } from 'src/app/core/services/utils/question-control.service';
import { FormsRequestService } from 'src/app/modules/crm_v2/services/rest/forms-request.service';
import { storageService } from 'src/app/modules/livechat/services/storage/storage.service';
import { AlertsService } from '../../alerts/alerts.service';
import { id } from '@swimlane/ngx-charts';


@Component({
	selector: 'shared-dynamic-form-v3',
	templateUrl: './dynamic-form.component.html',
	styleUrls: ['./dynamic-form.component.sass']
})
export class DynamicFormV3Component implements OnInit {
	@Output() submitForm = new EventEmitter<any>();
	@Output() denyForm = new EventEmitter<any>();
	@Output() enableTemplate = new EventEmitter<any>();
	_sections: any;
	forms = new FormArray([]);
	constructor(
		private qcs: QuestionControlService,
		private authService: AuthService,
		private permit: PermitService,
		private router: Router,
		private formsRequestService: FormsRequestService,
		public alertsService: AlertsService,
		private destroyService: DestroyComponentService,
		public storage: storageService
	) { }
	@Input() dependencies;
	@Input() formId;
	@Input() files;
	@Input() template;
	@Input() showButtonsActions: boolean = true;
	Files: any = [];
	@Input()
	public set sections(form: any) {
		if (form.app) {
			this.appOrigen = form.app
		}

		this.forms = new FormArray([]);
		this.getRolUser(form.app);
		this._sections = form.sections;



		if (this._sections) {
			this._sections.forEach((section, index) => {
				section.fields.map(
					mapp => {
						mapp['value'] = mapp.newValue

						if (form.app && form.app == 'livechat') {

							mapp['see'] = true;
							mapp['seeSons'] = true;
							mapp['cols'] = "4";

						}
						if (mapp['value'] == undefined && mapp.options.length > 0) {
							const optionDefault = mapp.options.find(finded => finded.isDefaultOption == true)
							if (mapp['controlType'] == "multiselect" && optionDefault) {
								mapp['value'] = [optionDefault.id]
							} else if (optionDefault) {
								mapp['value'] = optionDefault.id
							}

						}

						if (mapp.hasOwnProperty('defaultCommentValue') && mapp.hasOwnProperty('indDefaultValue')) {

							if (mapp.indDefaultValue == true) {
								mapp['value'] = mapp.defaultCommentValue
							}

						}

						if (mapp.hasOwnProperty('isCalculated') && mapp.isCalculated == true) {

							this.activatorsCalculatefield.push({ field: mapp, idSectionOrigin: index })
						}


					})

				if (form.app && form.app == 'crm') {
					this.checkSeeRol(section);
					this.fieldsInTrays(section);
				} else if (form.app && form.app == 'livechat') {
					section['see'] = true;
				}

				const group = this.qcs.toFormGroup(section.fields);
				this.forms.push(group);

			});
		}
	}

	@Input() public set clearControl(clear: any) {
		if (clear === true) {
			/* this.forms.removeAt(1); */
		}
	}

	rol: any = [];
	fieldsPreloaded: any[] = [];
	questionPush: any;
	evento: any;
	plantilla: any = '';
	tab: any;
	tray: any;
	seePermit: any;
	editPermit: any;
	appOrigen: string = "crm"
	activatorsCalculatefield: any[] = []

	ngOnInit(): void {
		this.plantilla = this.router.url.substring(6).split('/', 3)[2];
	}
	/**
	 * @author Karol García
	 * @createdate 2021-03-29
	 * Metodo que captura el rol del usuario
	 */
	getRolUser(app) {
		let user = this.authService.getUser();
		user.roles.forEach((rol) => {
			let name = rol.split('::');
			if (name[0] === app) {
				this.rol.push(name[1]);
			}
		});
	}
	/**
	 * @author Karol García
	 * @createdate 2021-03-29
	 * Metodo que verifica si tiene el permiso
	 */
	checkSeeRol(section) {

		section['see'] = false;
		section.fields.forEach((question) => {
			this.seePermit = this.permit.permit(this.rol, question.seeRoles);
			this.editPermit = this.permit.permit(this.rol, question.editRoles);
			question.disabled = !this.editPermit;
			question['see'] = this.seePermit;
			question['seeSons'] = true;
			question['edit_by_adviser'] = false;
			if (question.preloaded == true) {
				this.fieldsPreloaded.push(question);
			}
			if (this.editPermit && question.isSon) {

				question = this.checkDependence(question);
			}
			section.see = true;
		});
	}

	/**
	 * @author Miguel Restrepo
	 * @createdate 2022-03-01
	 * Metodo que permite validar las dependencias de un campo
	 */

	checkDependence(question) {

		this._sections.forEach(section => { // Busca si tiene dependencias de campos de otras secciones
			section.fields.forEach(field => {
				const dependences = question.dependencies.filter(dependence => dependence.idField === field.id);
				if (dependences.length != 0) {
					question.disabled = true;
					question.seeSons = false;
					dependences.forEach(dependence => {
						if (question.seeSons === false) {
							if (Array.isArray(field.value)) {
								question.seeSons = dependence.activators.some(activator => field.value.includes(activator.id));
								question.disabled = !dependence.activators.some(activator => field.value.includes(activator.id));
							} else {
								question.seeSons = dependence.activators.some(activator => activator.id === field.value);
								question.disabled = !dependence.activators.some(activator => activator.id === field.value);
							}
						}
						if (dependence.disabled) {
							question.disabled = true;
						}
					})
				}
			})
		})
		return question;
	}
	/**
	 * @author Jair Celis
	 * @createdate 2021-08-30
	 * Metodo que añade las dependecias, dependiendo de la opciones del padre
	 */
	addDependencies(event: any) {
		const mySons = this.findSons(event.idFather);
		if (mySons.length != 0) {
			mySons.forEach((son) => {
				if (this.forms.controls[son.sectionIndex].get(son.key).value != '') {
					this.forms.controls[son.sectionIndex].get(son.key).setValue('');
					this.forms.controls[son.sectionIndex].get(son.key).disable();
					this.disableField(son.id);
				}
			});
		}

		if (event.hasOwnProperty('isMultiple') == true && event.isMultiple == true) {// propiead que indica si es una carga e ependencias multiple

			this._sections.forEach((section, index) => {
				section.fields.forEach(async (field) => {
					if (field.isSon === true) {//Se valida que sea un campo hijo o capo dependiente

						let idActivadores = [] //variable done se almacenara los ids de todos los activadores
						field.dependencies.forEach(dependence => {
							idActivadores = idActivadores.concat(dependence.activators.map(activator => activator.id))
						});
						// variable donde se almacenan los ids de los activadores que estan e el valor seleccionado en el multiselect
						const activadorIsFinded = idActivadores.filter(filtered => event.idValue.includes(filtered))
						//Se obtienen los id del padre de todas las dependencias
						const idFatherdependence = field.dependencies.map(dep => dep.idField)
						if (idFatherdependence.find(fnd => fnd == event.idFather)) {//si el campo  es padre e alguna dependencia se limpia las opciones a marcar
							field.options = [];
						}

						if (field.controlType == 'dropdown' && field?.isChargeableOption == true) {
							await this.formsRequestService.getOptionsUploadFileAll(String(this.formId), String(field.id), false).toPromise()
								.then((restResponse) => {
									if (restResponse.options) {
										field.options = [];
										for (const key in restResponse.options) {
											field.options.push({ id: key, name: restResponse.options[key] })
										}
									}
								})
						}
						let options = []
						field.dependencies.forEach(dependence => {//Iteracion para mostrar campos de dependientes

							if (dependence.idField === event.idFather) { //Validamos que la depencia a  agregar sea del cmap padre
								//desactivar hermanos de dependencia
								this.forms.controls[index].get(field.key).disable();
								field.disabled = true;
								field.seeSons = false;

								dependence.activators.forEach((activator) => {
									if (activadorIsFinded.length > 0) { //Validamos que el activador que se itera si este entro de los marcado en el valor el multiselect
										this.forms.controls[index].get(field.key).setValue('');
										field.seeSons = true;
										this.forms.controls[index].get(field.key).enable();
										field.disabled = false;
										//activa seccion dependiente
										if (section.see === false) {
											section.see = true;
										}

										if (activadorIsFinded.find(fined => fined == activator.id)) {//Se valida que e activador se encuentre dentro e los activadores e la epenencia
											if (dependence.options !== '') {
												dependence.options.forEach((option) => {
													if (field.controlType == 'dropdown' && field?.isChargeableOption == true) {
														if (field.options.find(finded => finded.id == option.id) != undefined) {
															options.push(option);
														}
													} else {
														if (field.options.find(finded => finded.id == option.id) == undefined) {
															field.options.push(option);
														}
													}
												});



											}
										}
									}
								});

							}
						});

						if (field.controlType == 'dropdown' && field?.isChargeableOption == true) {
							field.options = options
						}

					}
				});

			});

		} else {

			this._sections.forEach((section, index) => {
				section.fields.forEach((field) => {
					if (field.isSon === true) {
						var bandera = 0;
						field.dependencies.forEach((dependencie) => {

							if (dependencie.idField === event.idFather && bandera === 0) {
								//desactivar hermanos
								this.forms.controls[index].get(field.key).disable();
								field.disabled = true;
								field.seeSons = false;

								dependencie.activators.forEach((activator) => {
									if (activator.id == event.idValue) {
										bandera = bandera + 1;
										if (field.hasOwnProperty('defaultCommentValue') && field.hasOwnProperty('indDefaultValue')) {
											if (field.indDefaultValue == true) {

												this.forms.controls[index].get(field.key).setValue(field.defaultCommentValue);
											} else {
												this.forms.controls[index].get(field.key).setValue('');
											}
										} else {
											this.forms.controls[index].get(field.key).setValue('');
										}
										field.seeSons = true;
										this.forms.controls[index].get(field.key).enable();
										field.disabled = false;
										//activa seccion dependiente
										if (section.see === false) {
											section.see = true;
											// this.forms.controls[index].enable();
										}

										field.options = [];
										if (dependencie.options !== '') {
											dependencie.options.forEach((option) => {
												field.options.push(option);
											});
										}
									}
								});
							}
						});
					}
				});
				if (section.fields.some(field => field.seeSons == true)) {
					section.see = true;
				} else {
					section.see = false;
				}

			});
		}

	}

	/**
	 * @author Jair Celis
	 * @createdate 2021-08-30
	 * Metodo que desactiva la visualizacion de un campo
	 * @param idField id del campo a desactivar
	 */

	disableField(idField) {
		this._sections.forEach((section, index) => {
			section.fields.forEach((field) => {
				if (field.id == idField) {
					field.seeSons = false;
				}
			});
		});
	}

	/**
	 * @author Jair Celis
	 * @createdate 2021-08-30
	 * Metodo que devuelve un arreglo de campos hijos
	 * @param idField id del campo a buscar los hijos
	 */

	findSons(idField) {
		const mySons = [];
		this._sections.forEach((section, index) => {
			section.fields.forEach((field) => {
				if (field.isSon == true) {
					field.dependencies.forEach((dependencie) => {
						if (dependencie.idField == idField) {
							field.sectionIndex = index;
							mySons.push(field);
						}
					});
				}
			});
		});
		return mySons;
	}
	/**
	 * @author Karol García
	 * @createdate 2021-02-25
	 * Metodo que añade otro campo
	 */
	addQuestion(event, nameSection) {
		this.evento = Object.assign({}, event);
		this.questionPush = this.evento;
		let cont = 0;
		let indexAuxField = 0;
		let indexAuxSec = 0;

		this._sections.forEach((section, indexSec) => {
			section.fields.forEach((question, indexField) => {
				if (question.key.includes(this.evento.key) && nameSection === section.name_section) {
					cont++;
					indexAuxField = indexField;
					indexAuxSec = indexSec;
				}
			});
		});

		cont = cont + 1;

		this.questionPush.id = new Date().getTime();
		this.questionPush.key = this.evento.key + '_' + cont;
		this.questionPush.value = '';
		this.questionPush.label = this.evento.label + ' ' + cont;
		this.questionPush.canAdd = false;
		this.questionPush.duplicated = {
			idOriginal: event.id,
			type: 'field'
		};

		if (this.questionPush.nameFile) {
			this.questionPush.nameFile = '';
		}

		if (this.questionPush.idValue) {
			this.questionPush.idValue = '';
		}

		if (this._sections[indexAuxSec].name_section === nameSection) {
			this._sections[indexAuxSec].fields.splice(indexAuxField + 1, 0, this.questionPush);
		}

		let formAux = this.forms.controls[indexAuxSec] as FormGroup;

		let controlAux = this.qcs.toFormControl(this.questionPush);
		formAux.addControl(this.questionPush.key, controlAux);
	}

	/**
	 * @author Miguel Restrepo
	 * @createdate 2021-02-25
	 * @updatedate 2022-05-18
	 * Metodo que añade una nueva sección duplicada
	 */

	addSection(event, i) {
		//let evento = Object.assign({}, event);
		let evento = JSON.parse(JSON.stringify(event))
		let section = evento;
		let cont = 0;
		this._sections.forEach((element) => {
			if (element.name_section.includes(section.name_section)) {
				cont++;
			}
		});
		cont = cont + 1;

		section['duplicated_section'] = true
		section.fields.forEach((field) => {
			field.id = Number(field.id + "" + cont)
			field.key = field.key + "_" + cont
			field.label = field.label + "" + cont
			field.disabled = false
			if (field.dependencies) {
				field.dependencies.forEach((dependence) => {
					dependence.idField = Number(dependence.idField + "" + cont)
					dependence.seeDepen = false
				});

			}
		});
		this._sections.push(section)
		const group = this.qcs.toFormGroup(section.fields);
		this.forms.push(group);
		/* this.formsRequestService.addSection(sendSection).subscribe((resp) => {
			resp["duplicated_section"] = true;
			this._sections.push(resp);
			const group = this.qcs.toFormGroup(resp.fields);
			this.forms.push(group);
		}); */
	}

	removeSection(event, index) {
		this.forms.removeAt(index)
		this._sections.splice(index, 1)

	}

	/**
	 * @author Karol García
	 * @createdate 2021-02-17
	 * Metodo que emite el formulario resuelto
	 */
	onSubmit() {
		let response = {
			sections: this._sections,
			answer: this.forms.getRawValue(),
			files: this.Files,
			forms: this.forms,
			isValid: this.forms.valid
		};
		this.submitForm.emit(response);
		this.fieldsPreloaded.forEach((element) => {
			this.forms.controls.forEach((sections) => {
				if (sections.get(element.key) != null) {
					sections.get(element.key).setValue(element.value);
				}
			});
		});
	}

	/**
	 * @author Karol García
	 * @createdate 2021-03-30
	 * Metodo que cancela el formulario
	 */
	cancelForm() {
		let saveconfirm = this.alertsService.alertConfirm('¿Desea cancelar?');
		saveconfirm.then((res) => {
			if (res.isConfirmed) {
				this.denyForm.emit(true);
			}
		});
	}
	/**
	 * @author Daniel Dominguez
	 * @createdate 2021-04-06
	 * Metodo recibe los archivos cargados en los campos tipo file
	 */
	addFiles(e) {
		this.Files.push(e);
	}

	ngOnDestroy(): void {
		this.destroyService.destroyComponent();
	}

	// Boton para generar las plantillas
	generateTemplate() {
		let responseTemplate = {
			sections: this._sections,
			answer: this.forms.getRawValue(),
			files: this.Files,
			forms: this.forms
		};

		this.enableTemplate.emit(responseTemplate);
	}

	fieldsInTrays(section) {

		this.tab = this.storage.getItem('tab');
		this.tray = this.storage.getItem('tray');
		section.fields.forEach((question) => {
			if (question.tray) {
				var band = false;
				question.tray.forEach(element => {

					question.disabled = true;
					question['see'] = true;

					if (element.id !== +this.tray && band === false) {
						question.disabled = false;
						question['see'] = false;
						this._sections.forEach((sectionArray, index) => {
							if (sectionArray.id === section.id) {
								this.forms.controls[index].get(question.key).disable();
							}
						});
					} else {
						question.disabled = true;
						question['see'] = true;
						band = true;

						if (element.preloaded === false) {

							this._sections.forEach((sectionArray, index) => {
								if (sectionArray.id === section.id) {
									this.forms.controls[index].get(question.key).setValue('');
								}
							});
						}
					}
				});

				if (this.template) {
					if (+this.tab < 2) {
						question.disabled = !this.editPermit;
						question['see'] = false;
						question['seeSons'] = true;
						this._sections.forEach((sectionArray, index) => {
							if (sectionArray.id === section.id) {
								this.forms.controls[index].get(question.key).disable();
							}
						});
					}
				} else {
					if (+this.tab < 1) {
						question.disabled = !this.editPermit;
						question['see'] = false;
						question['seeSons'] = true;
						this._sections.forEach((sectionArray, index) => {
							if (sectionArray.id === section.id) {
								this.forms.controls[index].get(question.key).disable();
							}
						});
					}
				}
			}
		});
		section.see = section.fields.some(field => field.see === true && field.seeSons === true);
	}

	/**
	 * Metodo que se encarga de recalcular los valores de los campos calculados y asignar su valor 
	 * @author Juan David Guerrero Vargas 
	 * @param event:any {any} evento de cambio para inputs tipo currency y number
	 */
	calculatedFields(event: any) {
		let findActivator = this.activatorsCalculatefield.find(finded => finded.field.calculatedFields.includes(event.question.id))
		let totalOperation = 0; //Total del a operacion 
		let stateCalculate = false; //elemento apra terminar las iteracion 
		let valuesToCalculate: number[] = [] //valores recogidos del formulario apra realzair operacion
		if (findActivator) {//Se valida que el campo pertenezca un activador de calculo
			for (let i = 0; i < this._sections.length; i++) {//Iteracion sections
				let sectionFinded = this._sections[i];
				for (let e = 0; e < sectionFinded.fields.length; e++) {
					let fieldFinded = sectionFinded.fields[e];
					if (findActivator.field.calculatedFields.includes(fieldFinded.id)) {	//se identifica si el campo pertenece a un activador
						if (!isNaN(this.forms.controls[i].get(fieldFinded.key).value.replace(/,/g, ""))) { //Validamos si la entrada retirando las (,) es un numero 
							valuesToCalculate.push(Number(this.forms.controls[i].get(fieldFinded.key).value))
						}
					}
					if (valuesToCalculate.length >= findActivator.field.calculatedFields.length) { //En caso de igualarse es por que ya no hay mas activadores que recorre se detiene iteraciones
						stateCalculate = true;
						break;
					}
				}
				if (stateCalculate) {
					break;
				}
			}//Fin iteracion sections

			if (Array.isArray(valuesToCalculate)) {
				switch (findActivator.field.typeArithmeticOperation) {
					case 'suma':
						totalOperation = valuesToCalculate.reduce((acumlador, valorActual) => acumlador + valorActual, 0)
						break;
					case 'resta':
						totalOperation = valuesToCalculate.sort((a, b) => a + b).reduce(function (acumlador, valorActual) {
							return acumlador - valorActual;
						});
						break;
					case 'multiplicacion':
						totalOperation = valuesToCalculate.reduce((acumlador, valorActual) => acumlador * valorActual, 1)
						break;
					case 'division'://desde la confoguracion general del calculo se determina que posicon toma el dividendo y el divisor
						let dividendo = valuesToCalculate[0] ? valuesToCalculate[0] : 0 //dividendo
						let divisor = valuesToCalculate[1] ? valuesToCalculate[1] : 0//divisor
						if (divisor !== 0) {
							totalOperation = (dividendo / divisor)
						} else {
							totalOperation = 0
						}
						break;
				}
			}
			this.forms.controls[findActivator.idSectionOrigin].get(findActivator.field.key).setValue(totalOperation);
		}
	}

	/**
	 * Valida si se peude o no ver la Question
	 * @author Juan David Guerrero Vargas 
	 * @param question:any {any} field o  campo  a mostrar
	 * @param controls:any {any} control del formulario
	 */
	validateSeeQuestion(question: any, controls: any, type: string) {

		if (type == 'question') {
			if (question.see && question.seeSons && controls) {
				if (question.hasOwnProperty('indDefaultValue') == false || question.indDefaultValue == false) {
					return true;
				} else {
					return false;
				}
			}
		}

		if (type == 'title') {

			if (question.see && question.seeSons && controls) {

				if (question.hasOwnProperty('indDefaultValue') == true && question.indDefaultValue == true) {
					return true;
				} else {
					return false;
				}
			}
		}


		return false;
	}

}
