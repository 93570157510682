import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Servicio que obtiene un elemento del local storage
   * @param param nombre del elemento a obtener
   */
  getStorage(param: string): any{

    return JSON.parse(localStorage.getItem(param));

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Servicio que guarda un elemento en el local storage
   * @param param nombre del elemento a guardar
   * @param data data a guardar
   */
  setStorage(param: string, data: any): any{

    localStorage.setItem(param, JSON.stringify(data));

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Servicio que elimina un elemento en el local storage
   * @param param nombre del elemento a eliminar
   */
  removeStorage(param: string): any{

    localStorage.removeItem(param);
  }


  /**
   * Metodo que se encarga de obtener datos subidos al session storague
   * @author  Juan David Guerrero Vargas
   * @param param:string {string} llave a buscar dentro delos datos de session
   * @returns {any} any  objeto guarado en el sessionStorague
   */
  getSessionStorage(param: string): any{
    return JSON.parse(sessionStorage.getItem(param));
  }

  /**
   * Metodo que se encarga de asignar un valor a la llave indicada entro del sessionStorague
   * @author Juan David Guerrero Vargas
   * @param param:string {string} llave a la que se le encarga 
   * @param data:any {any} objeto  a guardar 
   */
  setSessionStorage(param: string, data: any): any{
    sessionStorage.setItem(param, JSON.stringify(data));
  }

  /**
   * Metodo que se encarga de remover un item de la session
   * @author Juan David Guerrero Vargas
   * @param param:string {string} llave de parametro
   * @returns any {any} 
   */
  removeSessionStorague(param: string): any{
    localStorage.removeItem(param);
  }

  /**
   * Metodo que se encarga de limpiar lo valores guardos en la session
   * @author Juan David Guerrero Vargas
   * @returns {void} void
   */
  clearSessionStorague():void{
    sessionStorage.clear();
  }

}
