<div fxLayout="column">
  <div fxFlex="100%" fxLayout="row">
    <mat-form-field appearance="fill" class="me-3">
      <mat-label>{{config.auditoria.fecha}}</mat-label>
      <mat-date-range-input [rangePicker]="rango" [formGroup]="filtroFecha">
        <input matStartDate placeholder="{{config.auditoria.fechaInicio}}" formControlName="fechaInicial">
        <input matEndDate placeholder="{{config.auditoria.fechaFin}}" formControlName="fechaFinal" (dateChange)="filtroFechas()">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="rango">
        <mat-icon matDatepickerToggleIcon><i class="fi-rr-calendar"></i></mat-icon>
      </mat-datepicker-toggle>
      <mat-date-range-picker #rango></mat-date-range-picker>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 300px;">
      <mat-label>{{config.auditoria.filtrarPorAuditoria}}</mat-label>
      <mat-select [(value)]="filtroGestion" (selectionChange)="filtro()">
        <mat-option>-------</mat-option>
        <mat-option value="1">{{config.auditoria.retroalimentada}}</mat-option>
        <mat-option value="0">{{config.auditoria.sinRetroalimentacion}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="mat-elevation-z0 mt-2" fxFlex="100%">
    <table [dataSource]="dataSource" mat-table matSort>

      <!-- Columna con botónes de acciones -->
      <ng-container matColumnDef="ac">
        <div>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <i class="fi-rr-eye" (click)="detailRetroAlimentacion(element.id)" style="cursor: pointer;"></i>
          </td>
        </div>
      </ng-container>

      <!-- Columna ID -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element">{{config.auditoria.id}} {{ element.id}}</td>
      </ng-container>

      <!-- Columna fecha auditoría -->
      <ng-container matColumnDef="campania">
        <th mat-header-cell *matHeaderCellDef>{{config.auditoria.campanha}}</th>
        <td mat-cell *matCellDef="let element">{{config.auditoria.nombreCampanha}} {{ element.campaing.name ? element.campaing.name: config.auditoria.ninguna}}</td>
      </ng-container>

      <!-- Columna fecha auditoría -->
      <ng-container matColumnDef="fecha_auditoria">
        <th mat-header-cell *matHeaderCellDef>{{config.auditoria.fechaAuditoria}}</th>
        <td mat-cell *matCellDef="let element">{{ element.fecha | date:'dd/MM/yyyy' }}</td>
      </ng-container>

      <!-- Columna Campaña -->
      <ng-container matColumnDef="nombre_matriz">
        <th mat-header-cell *matHeaderCellDef>{{config.auditoria.nombreMatriz}}</th>
        <td mat-cell *matCellDef="let element">{{ element.nombre_matriz }}</td>
      </ng-container>

      <!-- Columna cedula auditado -->
      <ng-container matColumnDef="auditor">
        <th mat-header-cell *matHeaderCellDef>{{config.auditoria.auditadoPor}}</th>
        <td mat-cell *matCellDef="let element">{{ element.auditor }}</td>
      </ng-container>

      <!-- Columna nombre auditado -->
      <ng-container matColumnDef="calificacion">
        <th mat-header-cell *matHeaderCellDef>{{config.auditoria.calificacion}}</th>
        <td mat-cell *matCellDef="let element">{{ element.calidicacion | number:'1.0-3'}}</td>
      </ng-container>

      <!-- Columna UCID -->
      <ng-container matColumnDef="gestion">
        <th mat-header-cell *matHeaderCellDef>{{config.auditoria.gestion}}</th>
        <td mat-cell *matCellDef="let element">{{ element.gestion }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{config.auditoria.noRegistros}}</td>
      </tr>
    </table>

    <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)"
                   [pageSizeOptions]="pageSizeOptions">
    </mat-paginator>
  </div>
</div>
