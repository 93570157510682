import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Aplication } from '../../modules/class/aplications';

const CIU_URL = environment.CIU_URL;

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-03
   * Servicio que lista todos los aplicativos del aplicativo ciu
   */
  getApplications(): Observable<any>{
    return this.http.get<any>(`${CIU_URL}applications/?paginate=0`)
    .pipe(
      catchError(this.handleError)
      );
  }

   /**
   * @author jose vicente silva
   * @createdate 2021-02-03
   * Servicio que lista todos los aplicativos del aplicativo ciu paginados
   */
    getApplicationsPaginate(isPage: number,size: number, page: number ): Observable<any>{
      return this.http.get<any>(`${CIU_URL}applications/?paginate=${isPage}&n=${size}&page=${page}`)
      .pipe(
        catchError(this.handleError)
        );
    }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-10
   * Servicio que lista todos los roles por aplicacion
   */
  getRolesxApp(id: any): Observable<any>{
    return this.http.get<any>(`${CIU_URL}applications/`+ id + `/roles`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author José Vicente Silva Rivera
   * @createdate 2021-03-12
   * Servicio que realiza una busqueda avanzada de groupes por un valor
   * @param size tamaño de los datos a traer
   * @param value valor a buscar
   */
   searchApplications(size: number, page:number, value: any): Observable<any>{
    return this.http.get<any>(`${CIU_URL}applications/search?n=${size}&page=${page}&q=${value}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author José Vicente Silva Rivera
   * @createdate 2021-02-03
   * Servicio que guarda un group en su respectiva tabla
   * @param Apliactions Apliactions a guardarse
   */
  saveApplication(app: Aplication): Observable<any>{
    
    return this.http.post<any>(`${CIU_URL}applications`, app)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author José Vicente Silva Rivera
   * @createdate 2021-03-12
   * Servicio que edita un group en su respectiva tabla
   * @param Apliactions Apliactions a editarse
   */
  editApplication(app: Aplication): Observable<any>{
    return this.http.put<any>(`${CIU_URL}applications`, app)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author José Vicente Silva Rivera
   * @createdate 2021-02-04
   * Servicio que borra una campaña en su respectiva tabla modo logico
   * @param Apliactions id del group a eliminarse
   * @param idApliactions id del group a eliminarse
   */
  deleteApplication(app: Aplication,idAplication: number): Observable<any>{
    return this.http.put<any>(`${CIU_URL}applications/${idAplication}/updateState`, app)
    .pipe(
      catchError(this.handleError)
      );
  }
}
