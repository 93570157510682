import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';

const urlRRHH = environment.RRHH_URL;

@Injectable({
  providedIn: 'root'
})
export class RecruitersService {

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  getReclutadores(role) {
    // return this.reclutadores;

    return this.http.get(`${urlRRHH}users/role/${role}`, this.httpOptions);
  }
}