import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  COMERCIAL = environment.CRM_CIAL;

  constructor(
    private http: HttpClient,
  ) { }
  /**
  * @author javier Castañeda
  * @createdate 2022-10-29
  * Captura los errores en las peticiones
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  /**
  * @author javier Castañeda
  * @createdate 2022-10-29
  * Servicio creacion de notas
  * @param {JSON} body json de creación
  * @returns JSON
  */
  store(body) {
    return this.http.post<any>(`${this.COMERCIAL}notes`, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  /**
  * @author javier Castañeda
  * @createdate 2022-10-29
  * Servicio busqueda de notas
  * @param {number} oportunity id oportunidad
  * @returns JSON
  */
  search(oportunity) {
    return this.http.get<any>(`${this.COMERCIAL}notes/search?oportunity_id=${oportunity}`)
      .pipe(
        catchError(this.handleError)
      );
  }
  /**
  * @author javier Castañeda
  * @createdate 2022-10-29
  * Servicio eliminación de notas
  * @param {number} id id de la oportunidad
  * @returns JSON
  */
  delete(id) {
    return this.http.delete<any>(`${this.COMERCIAL}notes/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }
  /**
  * @author javier Castañeda
  * @createdate 2022-10-29
  * Servicio de consulta de notas
  * @param {number} id id de la oportunidad
  * @returns JSON
  */
  show(id) {
    return this.http.get<any>(`${this.COMERCIAL}notes/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }
  /**
  * @author javier Castañeda
  * @createdate 2022-10-29
  * Servicio de actualización de notas
  * @param {number} id id de la oportunidad
  * @returns JSON
  */
  update(id, body) {
    return this.http.put<any>(`${this.COMERCIAL}notes/${id}`, body)
      .pipe(
        catchError(this.handleError)
      );
  }
}
