<button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<form mat-dialog-content class="containerDialog" [formGroup]="formularioForm">
    <h2>{{config.title}}</h2>
    <mat-divider class="mb-4"></mat-divider>
    <mat-form-field appearance="fill">
        <mat-label>{{config.fields.biName}}</mat-label>
        <input  matInput formControlName="name" cdkFocusInitial>
        <mat-error *ngIf="formularioForm.get('name').hasError('required')">{{config.rules.form}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{config.fields.description}}</mat-label>
        <textarea  matInput formControlName="descripcionform"></textarea>
        <mat-error *ngIf="formularioForm.get('descripcionform').hasError('required')">{{config.rules.description}}</mat-error>
    </mat-form-field>

    <div class="btns-dialog">
        <button mat-stroked-button (click)="formularioRef.close()">{{config.fields.btnCancel}}</button>
        <button mat-raised-button color="primary" (click)="save()">{{config.fields.btnConfirm}}</button>
    </div>
</form>
