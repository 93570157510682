import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestAssignComponent } from './request-assign/request-assign.component';
import { RequestDetailsComponent } from './request-details/request-details.component';
import { RequestsAllComponent } from './requests-all/requests-all.component';
import { RequestsMainComponent } from './requests-main/requests-main.component';
import { SharedModule } from '../../../../shared/shared.module';
import { RequestDetailsDialogComponent } from './request-details-dialog/request-details-dialog.component';
import { RequestInfoComponent } from './request-info/request-info.component';



@NgModule({
  declarations: [
    RequestAssignComponent,
    RequestDetailsComponent,
    RequestsAllComponent,
    RequestsMainComponent,
    RequestDetailsDialogComponent,
    RequestInfoComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ], 
  exports:[
    RequestInfoComponent
  ]
})
export class RequestsModule { }
