<div class="dialog-email">
    <button style="float: right;" mat-icon-button mat-dialog-close>
        <i class="fi-rr-cross-small"></i>
    </button>
    
    <mat-dialog-content class="mat-typography email-content">
    
        <div class="row">
            <div class="col-8">
                <h1>Email</h1>
                <p>Bienvenido a tu bandeja de correos</p>
            </div>
            <div class="col-2">
                <button mat-raised-button type="button" (click)="nuevoCorreo()" color="primary">Nuevo Correo</button>
            </div>
            <div class="col-2">
                <button mat-raised-button type="button" (click)="actualizar()" color="primary">Actualizar</button>
            </div>
        </div>
    
        <br>
    
        <mat-tab-group mat-align-tabs="start">
    
            <mat-tab label="Activos: ({{cantidadActivos}})">
    
                <mat-list>
                    <div *ngFor="let mail of mailActivos">
                        <mat-list-item >
    
                            <div class="col-1">
                                <div *ngIf="dateDiff(mail.email.email_usuario[0], 1, mail)" class="circleGreen"></div>
                                <div *ngIf="dateDiff(mail.email.email_usuario[0], 2, mail )" class="circleYellow"></div>
                                <div *ngIf="dateDiff(mail.email.email_usuario[0], 3, mail)" class="circleRed"></div>
                            </div>
    
                            <div class="col-7" style="cursor: pointer;" (click)="manageMail(mail)">{{mail.email.de}}<br>{{ (mail.email.asunto.length>50)? (mail.asunto | slice:0:50)+' ...':(mail.email.asunto) }}</div>
    
                            <div class="col-3">{{mail.email.cantidadAdjuntos}}
                                <span class="fi-rr-clip"></span>
                              Fecha: {{mail.email.fechaHora | date}}
                            </div>
    
                            <div class="col-1">
                                <button mat-icon-button color="primary" (click)="devolverBandeja(mail.emailId)">
                                    <span class="fi-rr-rotate-right"></span>
                                </button>
                            </div>
    
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </div>
                </mat-list>
    
                <mat-paginator [length]="lengthM"
                             [pageSize]="pageSizeM"
                             (page)="pageEventM($event)"
                             [pageSizeOptions]="pageSizeOptions">
                </mat-paginator>
    
            </mat-tab>
    
            <mat-tab label="En Cola ({{cantidadCola}})">
    
                <mat-list>
                    <div *ngFor="let mail of mailCola">
                        <mat-list-item >
    
                            <div class="col-1">
                                <div *ngIf="dateDiff(mail.email_usuario[0], 1, mail)" class="circleGreen"></div>
                                <div *ngIf="dateDiff(mail.email_usuario[0], 2, mail )" class="circleYellow"></div>
                                <div *ngIf="dateDiff(mail.email_usuario[0], 3, mail)" class="circleRed"></div>
                            </div>
    
                            <div class="col-7" style="cursor: pointer;" (click)="viewMail(mail, 'queue')">{{mail.de}}<br>{{ (mail.asunto.length>50)? (mail.asunto | slice:0:50)+' ...':(mail.asunto) }}</div>
    
                            <div class="col-3">{{mail.cantidadAdjuntos}}
                                <span class="fi-rr-clip"></span>
                              Fecha: {{mail.fechaHora | date}}
                            </div>
    
                            <div class="col-1">
                                <button mat-icon-button color="primary" (click)="asignacion(mail.id)">
                                    <span class="fi-rr-download"></span>
                                </button>
                                <button mat-icon-button color="primary" (click)="descartar(mail.id)">
                                    <span class="fi-rr-rotate-right"></span>
                                </button>
                            </div>
    
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </div>
                </mat-list>
    
                <mat-paginator [length]="lengthC"
                             [pageSize]="pageSizeC"
                             (page)="pageEventC($event)"
                             [pageSizeOptions]="pageSizeOptions">
                </mat-paginator>
                
    
            </mat-tab>
    
            <mat-tab label="Historico ({{cantidadHistorico}})">
    
                <mat-list>
                    <div *ngFor="let mail of mailHistorico">
                        <mat-list-item >
    
                            <div class="col-1">
                                <div *ngIf="dateDiff(mail.email_usuario[0], 1, mail)" class="circleGreen"></div>
                                <div *ngIf="dateDiff(mail.email_usuario[0], 2, mail )" class="circleYellow"></div>
                                <div *ngIf="dateDiff(mail.email_usuario[0], 3, mail)" class="circleRed"></div>
                            </div>
    
                            <div class="col-7" style="cursor: pointer;" (click)="viewMail(mail, 'historic')">{{mail.de}}<br>{{ (mail.asunto.length>50)? (mail.asunto | slice:0:50)+' ...':(mail.asunto) }}</div>
    
                            <div class="col-3">{{mail.cantidadAdjuntos}}
                                <span class="fi-rr-clip"></span>
                              Fecha: {{mail.fechaHora | date}}
                            </div>
    
                            <div class="col-1">
                                <button mat-icon-button color="primary" (click)="devolver(mail.id)">
                                    <span class="fi-rr-rotate-right"></span>
                                </button>
                            </div>
    
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </div>
                </mat-list>
    
                <mat-paginator [length]="lengthH"
                             [pageSize]="pageSizeH"
                             (page)="pageEventH($event)"
                             [pageSizeOptions]="pageSizeOptions">
                </mat-paginator>
    
            </mat-tab>
    
    
            <mat-tab label="Enviados ({{cantidadEnviados}})">
    
                <mat-list>
                    <div *ngFor="let mail of mailEnviados">
                        <mat-list-item >
    
                            <div class="col-1">
                            </div>
    
                            <div class="col-7" style="cursor: pointer;" (click)="viewMail(mail, 'sent')">{{mail.de}}<br>{{ (mail.asunto.length>50)? (mail.asunto | slice:0:50)+' ...':(mail.asunto) }}</div>
    
                            <div class="col-1">
                            </div>
    
                            <div class="col-3">
                              Fecha: {{mail.created_at | date}}
                            </div>
    
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </div>
                </mat-list>
    
                <mat-paginator [length]="lengthE"
                             [pageSize]="pageSizeE"
                             (page)="pageEventE($event)"
                             [pageSizeOptions]="pageSizeOptions">
                </mat-paginator>
    
            </mat-tab>
    
            <mat-tab label="Búsqueda">
    
                <br>
    
                <form class="row form" [formGroup]="filterForm" (ngSubmit)="filter(filterForm)">
    
                    <div class="row col-12">
            
                        <mat-form-field style="padding: 1%;" class="col-3" *ngIf="advancedFilters === true" appearance="fill">
                            <mat-label>De</mat-label>
                            <input type="email" matInput formControlName="de" (blur)="filter(filterForm)">
                            <mat-error>Formato de correo no valido</mat-error>
                        </mat-form-field>
    
                        <mat-form-field style="padding: 1%;" class="col-3" *ngIf="advancedFilters === true" appearance="fill">
                            <mat-label>Para</mat-label>
                            <input type="email" matInput formControlName="para" (blur)="filter(filterForm)">
                            <mat-error>Formato de correo no valido</mat-error>
                        </mat-form-field>
    
                        <mat-form-field style="padding: 1%;" class="col-3" *ngIf="advancedFilters === true" appearance="fill">
                            <mat-label>Asunto</mat-label>
                            <input type="text" matInput formControlName="asunto" (blur)="filter(filterForm)">
                        </mat-form-field>
    
                        <mat-form-field style="padding: 1%;" class="col-3" *ngIf="advancedFilters === true" appearance="fill">
                            <mat-label>Fecha Desde</mat-label>
                            <input matInput [matDatepicker]="dp3" formControlName="fechaDesde" (blur)="filter(filterForm)" (dateChange)="filter(filterForm)">
                            <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                            <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                            <mat-error>No estas incluyendo una fecha</mat-error>
                        </mat-form-field>
    
                        <mat-form-field style="padding: 1%;" class="col-3" *ngIf="advancedFilters === true" appearance="fill">
                            <mat-label>Fecha Hasta</mat-label>
                            <input matInput [matDatepicker]="dp3" formControlName="fechaHasta" (blur)="filter(filterForm)" (dateChange)="filter(filterForm)">
                            <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                            <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                            <mat-error>No estas incluyendo una fecha</mat-error>
                        </mat-form-field>
    
                        <mat-form-field style="padding: 1%;" class="col-3" *ngIf="advancedFilters === true" appearance="fill">
                            <mat-label>Cuerpo</mat-label>
                            <input type="text" matInput formControlName="cuerpo" (blur)="filter(filterForm)">
                        </mat-form-field>
    
                        <div class="col-3" style="padding-top: 20px;">
                            <button mat-raised-button type="button" (click)="showButtonMessage()" style="width: 100%" color="primary">{{message}}</button>
                            <br>
                            <br>
                        </div>
    
                    </div>
            
                </form>
    
                <mat-list>
                    <div *ngFor="let mail of mailBuscados">
                        <mat-list-item >
    
                            <div class="col-1">
                            </div>
    
                            <div class="col-7" style="cursor: pointer;" (click)="viewMail(mail, 'search')">{{mail.de}}<br>{{ (mail.asunto.length>50)? (mail.asunto | slice:0:50)+' ...':(mail.asunto) }}</div>
    
                            <div class="col-2">
                            </div>
    
                            <div class="col-3">{{mail.cantidadAdjuntos}}
                                <span class="fi-rr-clip"></span>
                              Fecha: {{mail.created_at | date}}
                            </div>
    
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </div>
                </mat-list>
    
                <mat-paginator [length]="lengthB"
                             [pageSize]="pageSizeB"
                             (page)="pageEventB($event)"
                             [pageSizeOptions]="pageSizeOptions">
                </mat-paginator>
    
            </mat-tab>
    
        </mat-tab-group>
    
    </mat-dialog-content>
</div>
