import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { ToWords } from 'src/app/core/to-words/to-words';
import { OrdenesService } from 'src/app/modules/compras/services/rest/ordenes.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import Swal from 'sweetalert2';
import { OrdenCompra } from '../../../Class/orden-compras';
import { Remission } from '../../../Class/remission';
import { RemissionItem } from '../../../Class/remission-item';
import { OrdenCompraComponent } from '../orden-compra/orden-compra.component';
import { ArchivosService } from 'src/app/modules/compras/services/rest/archivos.service';

@Component({
  selector: 'app-remision-control',
  templateUrl: './remision-control.component.html',
  styleUrls: ['./remision-control.component.sass']
})
export class RemisionControlComponent implements OnInit {

  firstFormGroup: FormGroup;
  isEditable = true;
  toWords = new ToWords();
  valorFinal=0;
  valorIva =0;
  itemsOrder:any[]=[];
  itemsRemission:any[]=[];
  itemsSend:any[]=[];
  viewRemission = false;
  remissionId = 0;
  cantFaltanteItem = [];
  faltanteOk = true;
  envioOk = false;
  dataOrder: any = [];
  existPath: false;

  options = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    inputMode: CurrencyMaskInputMode.NATURAL
  };




  constructor(
      private formBuilder                     : FormBuilder,
      private form                            : FormBuilder,
      private ordenService                    : OrdenesService,
      private destroyService                  : DestroyComponentService,
      private alertsService                   : AlertsService,
      @Inject(MAT_DIALOG_DATA) public data    : any,
      private dialogRef                       : MatDialogRef<OrdenCompraComponent>,
      private archivoService                  : ArchivosService
    ) { }

    ngOnInit(): void {
      if(this.data.frag){
        this.dataOrder = this.data.frag
      }else{
        this.dataOrder = this.data.ordenCompra
      }

      this.firstFormGroup = this.formBuilder.group({
        items: this.form.array([]),
        sub_total           : new FormControl({value:'', disabled:true}, [Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
        discount            : new FormControl('', [Validators.required, Validators.maxLength(300), Validators.minLength(3)]),
        iva                 : new FormControl({value:'', disabled:false}, [Validators.required, Validators.maxLength(300), Validators.minLength(3)]),
        total               : new FormControl({value:'', disabled:true}, [Validators.required, Validators.maxLength(300), Validators.minLength(3)]),
        value_in_letters    : new FormControl({value:'', disabled:true}, [Validators.required, Validators.maxLength(300), Validators.minLength(3)]),
        delivery_date       : new FormControl('', [Validators.required, Validators.maxLength(300), Validators.minLength(3)]),
        pay                 : new FormControl('', [Validators.required, Validators.maxLength(300), Validators.minLength(3)]),
        payment_condition   : new FormControl('', [Validators.required]),
        payment_deb         : new FormControl(''),
        credit_condition    : new FormControl(''),
        pay_value           : new FormControl({value:'', disabled:true},[]),
        warranty            : new FormControl('', [Validators.required]),
        policy              : new FormControl('', [Validators.required]),
      });


      this.setEdit();


      if(this.data["type"] == "view"){
        this.viewRemission = true;
      }

    }
    get error(): any { return this.firstFormGroup.controls; }
    get errorItem():any{ return this.firstFormGroup.controls}

    get items(): FormArray {
      return this.firstFormGroup.get('items') as FormArray;
    }

    downloadOrder (order) {
      const archivo ={
        path:order.path
      };
      this.archivoService.downloadOrder(archivo).subscribe( resp=>{
        const a = document.createElement('a');
        const file = new Blob([resp], { type: 'application/pdf' });
        a.href = window.URL.createObjectURL(file);
        a.download = 'Orden_Compra.pdf';
        a.click();

      }, error =>{

      });
    }

    /**
    * @author Daniel Martinez
    * @createdate 2021-02-16
    * Metodo que estable las validaciones del formarray dentro del formulario
    */
    newItems(): void{
      const newItems = this.form.group({
        id:new FormControl('',[]),
        state: new FormControl('',[Validators.required]),
        observation: new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
        description: new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
        unit: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.minLength(1), Validators.min(0)]),
        unitReceived: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.minLength(1), Validators.min(0)]),
        pending: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.minLength(1), Validators.min(0)]),
        unit_value: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.minLength(1), Validators.min(0)]),
      });
      this.items.push(newItems);
    }
    /**
    * @author Daniel Martinez
    * @createdate 2021-02-16
    * Metodo que se encarga de la eliminacion de un grupo de aplicacion y rol
    * @param indice indice del grupo de aplicacion y rol a eliminar
    */
    deleteItems(indice: number): void {
      this.items.removeAt(indice);
    }

    /**
    * validar la existencia de al menos un item al avance.
    */
    onStepChange(event){
      if(event.selectedIndex == 1)
      {
        this.validarItems()
      }
      if(event.selectedIndex == 2 ){
        this.calcularValores()
      }
   }
    validarItems(){

      if (this.items.length===0) {
        this.newItems();
      }
    }

    calcularValores(){
      let valorTotal=0;
      this.valorIva=0;

      this.firstFormGroup.getRawValue().items.forEach(element => {
          valorTotal+=element['total_value'];
        if(element['selectIva']===100){

          this.valorIva+=(element['total_value']*element['valorIva'])/100;
        }else {
          this.valorIva+=(element['total_value']*element['selectIva'])/100;
        }
      });
      this.valorFinal= valorTotal+this.valorIva;
      const valorfinalletras = this.toWords.convert( this.valorFinal);
      this.firstFormGroup.controls.sub_total.setValue(valorTotal);
      //this.firstFormGroup.controls.discount.setValue(0);
      if(this.data["type"] != "edit"){
        this.firstFormGroup.controls.iva.setValue(this.valorIva);
        this.firstFormGroup.controls.total.setValue( this.valorFinal);
        this.firstFormGroup.controls.value_in_letters.setValue(valorfinalletras);
      }
    }

    /**
    * @author Juan Carlos Alonso
    * @createdate 2022-07-03
    * Metodo que calcula la cantidad restante respecto a la cantidad recibida
    */
    calcularCantidadFaltante(i){
      let cantFaltanteOrg = 0;
      let id_item = this.firstFormGroup.value.items[i].id;

      this.cantFaltanteItem.map((val)=>{
        if(val.id == id_item){
         cantFaltanteOrg =  val.unitPending;
        }
    });
    this.firstFormGroup.controls.items["controls"][i].controls.state.setValue(true)
      let cantFaltante = 0;
      let cantSol = cantFaltanteOrg;
      let cantReciv = this.firstFormGroup.value.items[i].unitReceived;

      let cantidadReal = cantSol - cantReciv

      if(cantidadReal < 0){
        this.alertsService.alertError(
          'Oppss',
          'Recuerda que la cantidad recibida no puede ser mayor a la faltante'
        )
        this.firstFormGroup.controls.items["controls"][i].controls.unitReceived.setValue(0);
      }else{
        if(cantReciv > cantSol){
          cantFaltante = 0;
        }else{
          cantFaltante = cantSol - cantReciv;
        }
        this.firstFormGroup.controls.items["controls"][i].controls.pending.setValue(cantFaltante);
      }


      this.validateInput()
    }

    validateInput(){
      const longitudItems = this.firstFormGroup.value.items.length;
      const isOkFaltante = this.firstFormGroup.value.items.filter(item => item.pending === 0);
      //this.faltanteOk = longitudItems === isOkFaltante.length ? false : true;
      if(longitudItems === isOkFaltante.length){
        this.faltanteOk = false;
        this.envioOk = true;
      }else{
        this.faltanteOk = true;
        this.envioOk = false;
      }
    }


    calcularValorTotal(indice){
      let cantidad = this.firstFormGroup.value.items[indice].unit;
      let valor=this.firstFormGroup.value.items[indice].unit_value;

      let valorTotal = (cantidad == undefined ? 0 :cantidad)* (valor == undefined ? 0:valor);
      this.firstFormGroup.controls.items["controls"][indice].controls.total_value.setValue(valorTotal);


    }

    calculardiscount(){

      const descuento= this.firstFormGroup.get('discount').value;
      const iva =this.firstFormGroup.get('iva').value;
      let valorFinal;

      if(iva != this.valorIva){
        valorFinal = (this.valorFinal-this.valorIva)-descuento+iva;
      }else{
        valorFinal = this.valorFinal-descuento;
      }

      const valorfinalletras = this.toWords.convert(valorFinal);
      if (valorFinal> 0 ){
        this.firstFormGroup.controls.total.setValue(valorFinal);
        this.firstFormGroup.controls.value_in_letters.setValue(valorfinalletras);
      } else{
        this.alertsService.alertError('Error', 'No se puede aplicar un descuento superior al valor total')
      }
    }

    cambioEstado(event, index){


      if (event === 100){
        this.firstFormGroup.controls.items["controls"][index].controls.valorIva.enable();
      } else {
        this.firstFormGroup.controls.items["controls"][index].controls.valorIva.disable();
      }

    }

    cambioPagoValue(event){
      if (event === 'Abono'|| event === 'Anticipo' || event === 'Credito'){
        this.firstFormGroup.controls.pay_value.enable();
      } else {
        this.firstFormGroup.controls.pay_value.disable();
      }

    }

    setEdit(){
      this.ordenService.getOrderItems(this.data.ordenCompra.id).subscribe((resp)=>{
        this.itemsOrder=resp.data;
        this.firstFormGroup.controls.pay.setValue(this.data.ordenCompra.pay);
        this.firstFormGroup.controls.payment_condition.setValue(this.data.ordenCompra.payment_condition);
        this.firstFormGroup.controls.payment_deb.setValue(this.data.ordenCompra.payment_deb);
        this.firstFormGroup.controls.credit_condition.setValue(this.data.ordenCompra.credit_condition);
        this.firstFormGroup.controls.pay_value.setValue(this.data.ordenCompra.pay_value);
        this.firstFormGroup.controls.warranty.setValue(this.data.ordenCompra.warranty);
        this.firstFormGroup.controls.policy.setValue(this.data.ordenCompra.policy);
        this.firstFormGroup.controls.sub_total.setValue(this.data.ordenCompra.sub_total);
        this.firstFormGroup.controls.discount.setValue(this.data.ordenCompra.discount);
        this.firstFormGroup.controls.iva.setValue(this.data.ordenCompra.iva);
        this.firstFormGroup.controls.total.setValue(this.data.ordenCompra.total);
        this.firstFormGroup.controls.value_in_letters.setValue(this.data.ordenCompra.value_in_letters);
        this.firstFormGroup.controls.delivery_date.setValue(this.data.ordenCompra.delivery_date);
        this.setRemision();
      });
    }

     /**
    * @author Juan Carlos Alonso
    * @createdate 2022-07-03
    * Metodo que asigna los valores guardados de la remisión a los input del formulario
    */

  setRemision(){
    this.ordenService.getRemission(this.data.ordenCompra.id, this.data.frag?.id).subscribe((resp)=>{
      this.remissionId = resp.id;
      this.itemsRemission = resp.items;
      const setItemsRemision = [];
      this.itemsOrder.forEach((element, index) => {
        const itemExist = this.itemsRemission.find(item => item.purchase_order_item_id == element.id);
        if (itemExist !== undefined) setItemsRemision.push(element);
      });
      setItemsRemision.forEach((element, index) => {
        this.newItems();
        this.items.controls[index].get('unit_value').setValue(element.unit_value);
        this.items.controls[index].get('description').setValue(element.description);
      });
      this.itemsRemission.forEach((element, index) => {
        this.items.controls[index].get('id').setValue(element.id);
        this.items.controls[index].get('observation').setValue(element.observation);
        this.items.controls[index].get('unit').setValue(element.unit);
        this.items.controls[index].get('pending').setValue(element.pending);
        if(element.pending == 0){
          this.items.controls[index].get('unitReceived').setValue(0)
          this.items.controls[index]['controls'].unitReceived.disable()
        }
        this.items.controls[index].get('state').setValue(element.state);
        this.cantFaltanteItem.push({id: element.id, unitPending: element.pending});
      });
      if(this.viewRemission) this.disableControls();
    });
  }

  private disableControls() {
    var arrayControl = this.firstFormGroup.get('items') as FormArray;
    for (let i = 0; i < arrayControl.length; i++) {
      const controlItems = arrayControl.at(i) as FormArray;
      for (var controlItem in controlItems.controls) {
        controlItems.controls[controlItem].disable();
      }
    }
  }

   /**
    * @author Juan Carlos Alonso
    * @createdate 2022-07-03
    * Metodo que se counica con el backend para almacenar las remisiones en base de datos
    */
    saveRemissionItems(remissionItems:any, alertResponse:boolean ){
      this.ordenService.updateRemissionItems({items: remissionItems}).subscribe(resp=>{
        if(alertResponse){
          this.alertsService.alertSuccess('Guardado', resp.data);
        }
      });

    }

    /**
    * @author Juan Carlos Alonso
    * @createdate 2022-07-03
    * Metodo que guarda los cambios de la remisión y la deja en estado "en proceso"
    */
    saveRemission(){

      if(this.firstFormGroup.status !== 'INVALID'){
        let typeEstado = 2;

        const estado ={
          purchase_order_remission_state:typeEstado
        }

        this.firstFormGroup.getRawValue().items.forEach((element, index) => {
          const items = {
            id: element.id,
            state: element.state,
            observation: element.observation,
            pending: element.pending
           }
           this.itemsSend.push(items);

        });

        this.ordenService.updateRemissionItems({items: this.itemsSend}).subscribe(respitems=>{
          this.ordenService.updateRemission(estado, this.remissionId).subscribe(respremission=>{
            this.alertsService.alertSuccess('Guardado', respremission.data);
           this.dialogRef.close()
          });
        });

        //this.saveRemissionItems(this.itemsSend, true);

      }else{
        this.firstFormGroup.markAllAsTouched();
      }
    }

    /**
    * @author Juan Carlos Alonso
    * @createdate 2022-07-03
    * Metodo que guarda la información de las remisiones y deja la orden en estado "Finalizado"
    */
    sendRemission(){
      if (this.firstFormGroup.status === 'VALID' && !this.faltanteOk) {

        var estadoRem = 3;

        if(this.data.frag){
          estadoRem = 4
        }

         this.firstFormGroup.getRawValue().items.forEach((element, index) => {

           if(element.state === 0){
             estadoRem = 4

           }

           const items = {
             id: element.id,
             state: element.state,
             observation: element.observation,
             pending: element.pending
            }
            this.itemsSend.push(items);

         });

         const estado ={
           purchase_order_remission_state: estadoRem
         }


         Swal.fire({
           icon                : 'warning',
           title               : '¿Estás seguro?',
           text                : "¿Deseas enviar la orden de compra?, recuerda que esto será definitivo y no se podrá deshacer",
           showCancelButton    : true,
           focusConfirm        : false,
           confirmButtonText   : 'Aceptar',
           cancelButtonText    : "Cancelar"
         }).then( (result) => {
           if(result.isConfirmed){

            this.ordenService.updateRemissionItems({items: this.itemsSend}).subscribe(resp=>{
              this.ordenService.updateRemission(estado, this.remissionId).subscribe((resp)=>{
                this.alertsService.alertSuccess('Guardado', resp.data);
                this.dialogRef.close();
              },
              error => {
                this.alertsService.alertWarning('Atención',error.error.error);
              }
              );
            });
           }
         } )
      } else {
        this.firstFormGroup.markAllAsTouched();
      }
    }

    ngOnDestroy(): void {
      this.destroyService.destroyComponent();
    }
}
