import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CarteraWomComponent } from './cartera-wom.component';

const routes: Routes = [
	{ path: '', component: CarteraWomComponent },
	{ path: 'gestiones', loadChildren: () => import('./modules/gestiones/gestiones.module').then((m) => m.GestionesModule) },
	{ path: 'databases', loadChildren: () => import('./modules/databases/databases.module').then((m) => m.DatabasesModule) },
	{ path: 'asignaciones', loadChildren: () => import('./modules/asignaciones/asignaciones.module').then((m) => m.AsignacionesModule) },
	{ path: 'reportes-cartera', loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule) },
  { path: 'massive-messages', loadChildren: () => import('./modules/massive-messages/massive-messages.module').then((m) => m.MassiveMessagesModule) },



];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CarteraWomRoutingModule {}
