import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssingUserComponent } from './assing-user/assing-user.component';
import { SharedModule } from '../../../../shared/shared.module';
import { MaterialModule } from '../../../../shared/material/material.module';



@NgModule({
  declarations: [AssingUserComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule
  ]
})
export class AssignUserModule { }
