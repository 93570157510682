import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ApplicationsService } from '../../../services/rest/applications.service';
import { DestroyComponentService } from '../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { MatDialog } from '@angular/material/dialog';
import { SaveAplicationsComponent } from '../save-aplications/save-aplications.component';
import { Aplication } from '../../class/aplications';

@Component({
  selector: 'app-admin-applications',
  templateUrl: './admin-applications.component.html',
  styleUrls: ['./admin-applications.component.sass']
})
export class AdminApplicationsComponent implements OnInit {

  length;
  pageSize = 5;
  page = 1;
  isPage = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  newRolComponent: boolean = false;

  displayedColumns: string[] = ['acciones', 'name', 'key', 'endpoint', 'enforce_schedule'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(public applicationService: ApplicationsService,
              private destroyService: DestroyComponentService,
              private alertsService: AlertsService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getApplications();
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que trae los roles paginados
   * @param size cantidad de roles a traer en la tabla
   * @param page pagina que va a mostrar la tabla
   */
   getApplications(): void {

    if (this.dataSource.filter === ''){
      this.dataSource.filter = null;
    }
    this.applicationService.getApplicationsPaginate(this.isPage,this.pageSize, this.page).subscribe((Response) => {
      this.dataSource.data = Response.data.data;
      
      this.dataSource.sort = this.sort;
      this.length = Response.data.total;
      this.pageSize = Response.data.per_page;

    });

  }
 /**
  * @author Daniel Martinez
  * @createdate 2021-02-04
  * Metodo que realiza el filtro
  * @param event datos para filtrar que son enviados desde el input
  */
  applyFilter(event: Event): void {

    const filterValue = (event.target as HTMLInputElement).value;

    this.applicationService.searchApplications(this.pageSize, this.page, filterValue).subscribe((Response) => {
      this.dataSource.data = Response.data.data;
      this.dataSource.sort = this.sort;
      this.length = Response.data.total;
      this.pageSize = Response.data.per_page;
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que se encarga del movimiento del paginador
   * @param event cuando se da click en el paginador trae este evento
   */
  pageEvent(event: any): void {

    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getApplications();

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que abre el modal de crear un nuevo rol, al cerral el modal se refresca la tabla
   */
  newApplication(): void{
    const dialogNewRol = this.dialog.open(SaveAplicationsComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Crear Aplicaciones',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.pageSize=5;
      this.ngOnInit();
    });

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que abre el modal de editar usuario, envia un usuario, al cerrar el modal se refresca la tabla
   * @param rol rol a editar
   */
  editApplication(app: any): void{
    const dialogNewRol = this.dialog.open(SaveAplicationsComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Editar Aplicaciones',
        app
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.pageSize =5;
      this.ngOnInit();
    });
  }

  // /**
  //  * @author Daniel Martinez
  //  * @createdate 2021-02-04
  //  * Metodo que elimina un rol
  //  * @param rol rol a eliminar
  //  */
  //  deleteCampaign(app: Aplication): void{
  //   app.state =0;

  //   this.applicationService.deleteApplication(app,app.id).subscribe((resp) => {
  //     this.alertsService.alertSuccess('Eliminado', resp.data);
  //     this.ngOnInit();
  //   });
  // }

  viewCampaign(){

  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }
}
