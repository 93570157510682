import { createReducer, on } from "@ngrx/store";
import { AcompañamientoState } from "../../core/states/acompañamiento.state";
import { loadedAcompañamientos } from "../actions/acompañamiento.actions";

export const initialState: AcompañamientoState = { acompañamientos:null };

export const acompañamientoReducer = createReducer(
    initialState,
    on(loadedAcompañamientos,( state, { acompañamientos } )=>{
        return {...state, acompañamientos}
    })
);