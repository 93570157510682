<div class="main">
	<div class="pn-lateral-56-24 mtn-64">
		<div class="col-12">
			<h1>Usuarios de red</h1>
		</div>
		<div class="col-4">
			<p>En este espacio podrás gestionar los usuarios de red. No olvides, "El respeto y la empatía son claves".</p>
		</div>

		<mat-tab-group dynamicHeight class="mtn-56" (selectedTabChange)="setTabType($event)">
      <mat-tab label="Activar usuarios">
				<app-network-users *ngIf="tab_type === 1" [tab_type]="tab_type"></app-network-users>
			</mat-tab>
      <mat-tab label="Usuarios de red">
				<app-users-list *ngIf="tab_type === 2" [tab_type]="tab_type"></app-users-list>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>
