<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>

<h3 mat-card-title>Agendar Examen Médico</h3>
<hr>
<mat-dialog-content>
    <form fxLayout="row wrap" [formGroup]="medicalForm" fxLayoutGap="20px">

        <mat-form-field appearance="fill" fxFlex="47">
            <mat-label>Fecha</mat-label>
            <input #fecha matInput type="datetime-local" [min]="now" formControlName="date">
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex="47">
            <mat-label>IPS</mat-label>
            <mat-select formControlName="ips_key" (selectionChange)="showAddres($event.value)">
                <mat-option *ngFor="let row of ipsOptions" [value]="row.key" >{{row.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex="47">
            <mat-label>Tipo de Examen</mat-label>
            <mat-select formControlName="medical_type_test_id">
                <mat-option *ngFor="let row of medicalTestsOptions" [value]="row.id">{{row.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex="47">
            <mat-label>Tipo de cargo</mat-label>
            <mat-select formControlName="position_category_id">
                <mat-option *ngFor="let row of positionsOptions" [value]="row.id">{{row.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex="96" *ngIf="addres == true">
          <mat-label>Dirección</mat-label>
            <input  matInput type="text"  formControlName="addres" required>
      </mat-form-field>
    </form>


</mat-dialog-content>
<br>
<mat-dialog-actions align="center">
    <button mat-stroked-button mat-dialog-close >Cancelar</button>
    <button mat-raised-button color="primary" (click)="saveTest()" [disabled]="medicalForm.invalid">Crear</button>
</mat-dialog-actions>
