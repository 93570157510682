import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { CoachingService } from '../../services/coaching.service';

@Injectable()
export class AcompañamientoEffects {
 
  loadAuditorias$ = createEffect(() => this.actions$.pipe(
    ofType('[Acompañamiento] Load acompañamientos'),
    mergeMap((params) => this.coachingSerivce.getAcompanamientos(params)
      .pipe(
        map((acompañamientos:any) => ( { type: '[Acompañamientos] Loaded acompañamientos', acompañamientos }))
      ))
    )
  );
 
  constructor(
    private actions$: Actions,
    private coachingSerivce:CoachingService,
  ) {}
}