import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: null
})
export class ChatRestService {
  private chatUrl =   environment.CHAT_SOUL;
  private rrhhUrl =   environment.RRHH_URL
  private nominaUrl = environment.NOMINA_URL


  constructor(
    private http:HttpClient
  ) { }

  /**
   * Metodo que se encarga de obtener el historico de chat con un usuario
   * @author Juan David Guerrero Vargas
   * @param idUserHistory:number {number} Id del historico de chat de un usuario
   * @return {Observable<any>} Observable<any>
   */
  getHistoryChat(idUserHistory:number, type:string):Observable<any>{
    return this.http.get(`${this.chatUrl}message/get-history`,{ params: { id: String(idUserHistory), type:type} })
  }

  /**
   * Metodo que se encarga de enviar mensajes de chat
   * @author Juan David Guerrero Vargas
   * @param message:any Contenido del mensaje a enviar
   * @returns :Observable<any>
   */
  sendMessage(message:any):Observable<any>{
    return this.http.post(`${this.chatUrl}message/send`,message);
  }

  /**
   * Metodo que se encarga de consultar la lista de contactos por segun la campaña del usurio
   * @author Juan David Guerrero Vargas
   * @param campaign_id:string {string} ide de la campaña
   * @returns {Observable} Observable con respuesta REST
   */
  getListContact():Observable<any>{
    return this.http.get(`${this.chatUrl}users/chats`);
  }
  /**
   * Metodo que se encarga marcar un mensaje como leido
   * @param idMesage:String {string}
   * @returns
   */
  markAllMessageRead(idUser:string , typeChat:string):Observable<any>{
    return this.http.post(`${this.chatUrl}message/markAllMessagesAsRead`,{id_sender:idUser, type:typeChat });
  }

  /**
   * Metodo que se encarga de obtener el listado de grupos asociados al suuario en session
   * @return {Observable<any>} Observable<any>
   */
  getGroupsUser():Observable<any>{
    return this.http.get(`${this.chatUrl}group/get`);
  }

  /**
   * Metodo que se encarga de crear neuvos grupos
   * @param newGroup:any {name:string, participants:number[]}
   * @returns {Observable<any>} Observable<any>
   */
  createNewGroup(newGroup:any):Observable<any>{
    return this.http.post(`${this.chatUrl}group/create`,newGroup);
  }

  /**
   * Metodo que se encarga de obtener el listado de participantes de un grupo
   * @author Juan David Guerrero Vargas
   * @param idGroup:any {any} id del grupo a consultar
   */
  getParticipants(idGroup:any):Observable<any>{
    return this.http.get(`${this.chatUrl}group/get-participants-for-group`,{ params: { id: String(idGroup)} });
  }

    /**
   * Método que retorna la lista de campañas
   * @author Juan Carlos Alonso
   * @createdate 05-06-2024
   * @returns {Observable<any>} Observable<any>
   */
  getCampaigns():Observable<any>{
    return this.http.get(`${this.nominaUrl}campaigns/list`);
  }

   /**
   * Método que retorna la lista de usuarios por campañas
   * @author Juan Carlos Alonso
   * @createdate 05-06-2024
   * @param campaigns Arreglo de ids de campañas
   * @returns {Observable<any>} Observable<any>
   */
  getCampaignsUsers(campaigns:any):Observable<any>{
    return this.http.post(`${this.chatUrl}users/search/by/campaigns`, campaigns)
  }

   /**
   * Método que obtiene un usuario por documento y tipo de documento
   * @author Juan Carlos Alonso
   * @createdate 05-06-2024
   * @param document_type tipo de documento
   * @param document número de documento
   * @returns {Observable<any>} Observable<any>
   */
  getUserByDocument(document_type:string,document:string):Observable<any>{
    return this.http.post(`${this.chatUrl}users/search/by/document`, {document: document, type_doc: document_type})
  }

   /**
   * Método que obtiene la lista de tipos de documento
   * @author Juan Carlos Alonso
   * @createdate 05-06-2024
   * @returns {Observable<any>} Observable<any>
   */
  getDocumentTypes():Observable<any>{
    return this.http.get(`${this.rrhhUrl}idtypes`)
  }

   /**
   * Método que envía y actualiza un grupo
   * @author Juan Carlos Alonso
   * @createdate 05-06-2024
   * @group grupo con sus participantes
   * @returns {Observable<any>} Observable<any>
   */
  updateGroup(group:any):Observable<any>{
    return this.http.put(`${this.chatUrl}group/update`,group);
  }

   /**
   * Método que obtiene un grupo y sus participates por id
   * @author Juan Carlos Alonso
   * @createdate 05-06-2024
   * @param id id del grupo
   * @returns {Observable<any>} Observable<any>
   */
  getGroupById(id:number, with_owner:boolean){
    return this.http.get(`${this.chatUrl}group/getGroupById?id=${id}&with_owner=${with_owner}`)
  }

  /**
   * Metodo que se encarga de obtener el listado de tipos de documento
   * @author Juan David Guerrero Vargas
   * @returns {Observable<any>} Observable<any>
   */
  getIdTypesdocuments(){
    return this.http.get(`${this.rrhhUrl}idtypes`);
  }

  /**
   * Metodo que se encarga de obtener los usuarios por tipo de docuemtno y documento
   * @author Juan David Guerrero Vargas
   * @param searchValue:any {document:string, type_doc:string}
   * @returns {Observable<any>} Observable<any>
   */
  getUsersBydocumentAndType(searchValue:any):Observable<any>{
    return this.http.post(`${this.chatUrl}users/search/by/document`,searchValue);
  }

  /**
   * Metodo que se encarga de obtener un lsitado de usuarios por nombre
   * @author Juan David Guerrero Vargas
   * @param name:string {name} nombre a buscar
   * @returns {Observable<any>} Observable<any>
   */
  getUserByName(name:string):Observable<any>{
    return this.http.get(`${this.chatUrl}users`,{ params: { name: name} });
  }
}
