import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { AssesmentsViewComponent } from './assesments-view/assesments-view.component';
import { AssessmentsCreateComponent } from './assessments-create/assessments-create.component';
import { AssessmentsDetailComponent } from './assessments-detail/assessments-detail.component';
import { AssessmentScoreComponent } from './assessment-score/assessment-score.component';



@NgModule({
  declarations: [AssesmentsViewComponent, AssessmentsCreateComponent, AssessmentsDetailComponent, AssessmentScoreComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class AssesmentsModule { }
