import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { TrainingGroupsService } from '../../../services/training-groups.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';

@Component({
  selector: 'app-prebonding',
  templateUrl: './prebonding.component.html',
  styleUrls: ['./prebonding.component.sass']
})
export class PrebondingComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _fb: FormBuilder,
  private groupsService:TrainingGroupsService,  private dialogRef: MatDialogRef<PrebondingComponent>,
  private alert: AlertsService) { }
  proBording= this._fb.group({
    ally: [''],
    channel: [''],
    operative: ['', [Validators.maxLength(100)]],
    familiar_claro: ['', Validators.maxLength(100)],
    family_charge: ['', [Validators.maxLength(100)]],
    mobile : ['', Validators.maxLength(100)],
    permanent: [null],
    trainer: [null],
    formation_group_id: [this.data.group.id],
    business_unit: [''],
  })

  ngOnInit(): void {
  }
  sendPreBonding(){
    this.groupsService.postProBording(`formation/bonding`,this.proBording.getRawValue())
    .subscribe(res=>{
      this.alert.alertSuccess('¡Hecho!', 'Pre Vinculación creada con éxito.');
      this.dialogRef.close();
    })
  }

}
