<mat-dialog-content>
    <div class="row" >
        <div class="col-12">
            <h2>Listado de contactos</h2>
        </div>
    </div>

    <div class="row mt-5" >
        <div class="col-12">
            <form [formGroup]="filterForm" >
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Busqueda por documento o usuario</mat-label>
                    <input (keydown.enter)="searchUsers()" matInput type="text" #input   formControlName="searchControl" >
                    <mat-select *ngIf="typeSearch == 'document'"  matSuffix  formControlName="typeDocument" 
                      style="width: 60px; margin-left: 5px;">
                      <mat-option *ngFor="let item of listTypeDocuments" [value]="item?.id">{{item.key}}</mat-option>
                    </mat-select>
                    <mat-hint>Presiona "ENTER" para buscar por número de documento o por nombre de usuario.</mat-hint>
                </mat-form-field>
            </form>
        </div>
    </div>

    <div class="row mt-2" >
        <div class="col-12 container-users">
            <ul class="list-users" *ngIf="listUsers.length > 0">
                <li *ngFor="let user of listUsers" (click)="newChat(user)" > {{ user.name }} </li>
            </ul>
            <p class="text_explain" *ngIf="listUsers.length <= 0" >Debes ingresar un criterio de búsqueda y presionar "ENTER" para consultar la lista de contactos.</p>
        </div>
    </div>


</mat-dialog-content>