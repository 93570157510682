<div>
  <i class="fi-rr-cross-small" style="float: right; font-size: 24px; margin-top: -24px !important;" mat-icon-button mat-dialog-close></i>
</div>
<div style="margin: 24px 26px 24px 84px;">

  <h2 mat-dialog-title style="color: #353535; margin-left: -9px;"><strong style="font-size: 38px !important;">{{data.title}}</strong></h2>

  <mat-dialog-content style="overflow-x: hidden;" class="mat-typography">
    <div class="row" style="display: flex !important; ">

      <div *ngFor="let convention of noveltyTypes" class="col-3 novelty-types" style="margin-right: 30px; margin-top: 8px;">
        <div style="display:inline-flex;">
          <div style="margin-right: 8px;">
            <span class="badge"
            [title]="convention.name"
            [style.background]="convention.calendar_config.backgroundColor">{{ convention.key }} </span>&nbsp;
          </div>
          <div >
            <span class="novelty-types">{{ convention.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

</div>