import { DecimalPipe } from '@angular/common';
import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appCopCurrency]'
})
export class CopCurrencyDirective {

  currencyChars = new RegExp('[\.,]', 'g');

  @HostListener('input', ["$event.target.value"]) onInput(e: string) {
    this.format(e);
  };

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    event.preventDefault();
    this.format(event.clipboardData.getData('text/plain'));
  }

  constructor(public el: ElementRef, public renderer: Renderer2, private decimalPipe: DecimalPipe) {
   }


  format(val: string) {
    const numberFormat = parseInt(String(val).replace(this.currencyChars, ''));
    const usd = this.decimalPipe.transform(numberFormat, '1.0', 'en-US');
    this.renderer.setProperty(this.el.nativeElement, 'value', usd);
  }

}
