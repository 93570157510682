<div class="row col-12 filter mx-0" style="padding-top: 40px;">


    <mat-form-field appearance="fill" class="col-3 ps-2">
      <mat-label>Fecha de creación</mat-label>
      <input matInput
        [matDatepicker]   = "picker"
        autocomplete      = "off"
        readonly          = "true"
        (dateChange)      = "applyFilter($event)"
        #input
        >

        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon><span class="fi fi-rr-calendar"></span></mat-icon>
        </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="col-3">
      <mat-label>Empresa</mat-label>
      <input type="text"
      matInput
      [matAutocomplete]="autoCom"
      (input)="filterCompanies($event.target.value)"
      >
      <mat-autocomplete #autoCom="matAutocomplete" (optionSelected)="filterByCompany($event.option.value)" [displayWith]='displayFnCompania.bind(this)'>
        <mat-option *ngFor="let comp of filteredOptionsCompania" [value]="comp.id">
          {{comp.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>


    <div class="col-3 offset-3  buttons pe-2">
      <button mat-raised-button color="primary" (click)="descargaGestion()" class="w-100 bold">
        Descargar Gestión &nbsp; <i class="fi-rr-download" ></i></button>

    </div>

</div>


<div class="table-container">
    <div class="mat-elevation-z8">
      <table mat-table  [dataSource]="dataSource" matSort multiTemplateDataRows>
              <ng-container matColumnDef="check">
                    <th mat-header-cell *matHeaderCellDef class="start">
                      <mat-checkbox  [checked]="allToApprove" [indeterminate]="someToApprove()" (change)="setAllToApprove($event.checked)"></mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox [(ngModel)]="row.toApprove" [checked]="row.toApprove" (ngModelChange)="updateAllToApprove(row)"></mat-checkbox>
                    </td>
              </ng-container>

              <ng-container matColumnDef="request_date">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de creación </th>
                  <td mat-cell *matCellDef="let row"> {{row.request_date|date:'yyyy-MM-dd'}} </td>
              </ng-container>
              <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Título de solicitud </th>
                  <td mat-cell *matCellDef="let row">
                    {{row.title | slice : 0:30}}<small *ngIf="row.title.length > 30">...</small>
                   </td>
              </ng-container>

              <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción de solicitud </th>
                  <td mat-cell *matCellDef="let row" matTooltip="{{row.description}}"[matTooltipClass]="'my-tooltip'">
                       {{row.description | slice : 0:30}}<small *ngIf="row.description.length > 30">...</small>
                </td>
              </ng-container>
              <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef>Valor</th>
                <td mat-cell *matCellDef="let row" >{{getSelectedCotization(row)}}</td>
              </ng-container>
              <ng-container matColumnDef="created_by">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Solicitante </th>
                <td mat-cell *matCellDef="let row"> {{row.created_by}} </td>
              </ng-container>
              <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Empresa </th>
                <td mat-cell *matCellDef="let row" > {{row.company.name}} </td>
              </ng-container>
              <ng-container matColumnDef="acciones">
                <th  mat-header-cell *matHeaderCellDef class="end"></th>
                <td mat-cell *matCellDef="let row">
                    <!-- <button mat-icon-button (click)="viewCampaign(row)">
                        <i class="fi-rr-eye"></i>
                    </button> -->
                    <button mat-icon-button (click)="editSolicitud(row)">
                        <i class="fi-rr-settings"></i>
                    </button>

                    <button mat-icon-button (click)="masiveSubmit(true,row.id)">
                      <i class="fi-rr-check" style="color:#60bf66"></i>
                    </button>
                    <button mat-icon-button (click)="masiveSubmit(false,row.id)">
                      <i class="fi-rr-cross" style="color:#d14a4a"></i>
                    </button>
                </td>
              </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div class="example-element-detail col-12 col-sm-10 col-md-8" [@detailExpand]="element.toApprove ? 'expanded' : 'collapsed'">
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group column w-100">
                  <table class="">
                    <tr *ngFor="let archivo of element.cotizations; let i = index">
                      <td>
                        <button class=" action" type="button" (click)="downloadFile(archivo)" mat-icon-button>
                        <i class="fi-rr-eye"></i>
                        </button>
                        <mat-radio-button class="col-4"  [checked]="archivo.state" [value]="archivo.id" (click)="updateSelectedCotization(element,archivo)" >
                        </mat-radio-button>
                      </td>
                      <td>
                          Cotización  {{i + 1}}
                      </td>

                      <td>
                        {{ ( archivo.name.length > 15 ) ? archivo.name.slice(0,12)+"..." : archivo.name }}
                      </td>

                      <td>

                        <span *ngIf="archivo.value != null">
                          {{formatNumber(archivo.value)}}
                        </span>
                        <span *ngIf="archivo.value == null">
                         N/A
                        </span>

                      </td>
                    </tr>

                  </table>
                </mat-radio-group>
              </div>
          </td>
      </ng-container>

              <!-- <ng-container matColumnDef="state">
                  <th class="end" mat-header-cell *matHeaderCellDef mat-sort-header>Estado </th>
                  <td mat-cell *matCellDef="let row" align="left" ><small><i class="span" style="align-items: initial;" [style.backgroundColor]= "row.request_state.color" [style.color]= "row.request_state.color">.</i></small> {{row.request_state.name}} </td>
              </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row" [class.example-expanded-row]="element.toApprove" (click)="element.toApprove = !element.toApprove"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro: "{{input.value}}"</td>
        </tr>

      </table>

      <mat-paginator [length]="length"
                     [pageSize]="pageSize"
                     (page)="pageEvent($event)"
                     [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>


      <div class="row justify-content-end mt-5" *ngIf="!anyToApprove()">
        <div class="col-3 col-md-4 col-lg-3 buttons">
          <button class="w-100 bold" mat-stroked-button color="primary" (click)="masiveSubmit(false)" [disabled]="!anyToApprove()">
            Rechazar Solicitudes
          </button>
        </div>
        <div class="col-12 col-md-4 col-lg-3 buttons">
          <button class="w-100 bold" mat-stroked-button color="primary" (click)="masiveSubmit(true)" [disabled]="!anyToApprove()">
            Aprobar Solicitudes
          </button>
        </div>
      </div>

      <div class="row justify-content-end mt-5" *ngIf="anyToApprove()">
        <div class="col-3 col-md-4 col-lg-3 buttons">
          <button class="w-100 bold" mat-raised-button  color="primary" (click)="masiveSubmit(false)" [disabled]="!anyToApprove()">
            Rechazar Solicitudes
          </button>
        </div>
        <div class="col-12 col-md-4 col-lg-3 buttons">
          <button class="w-100 bold" mat-raised-button  color="primary" (click)="masiveSubmit(true)" [disabled]="!anyToApprove()">
            Aprobar Solicitudes
          </button>
        </div>
      </div>


    </div>
</div>
