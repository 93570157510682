import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MailService } from '../../../services/rest/mail.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-email',
  templateUrl: './new-email.component.html',
  styleUrls: ['./new-email.component.sass']
})
export class NewEmailComponent implements OnInit {

  mailForm: FormGroup;
  cuentaEmailId: any;

  // tslint:disable-next-line: member-ordering
  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5%',
    minHeight: '0',
    maxHeight: '20%',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  archivosCargados: any[] = [];
  file: any;

  constructor(private form: FormBuilder,
              public mailService: MailService,
              public authService: AuthService,
              private alertsService: AlertsService,
              public dialog: MatDialogRef<NewEmailComponent>) { }

  ngOnInit(): void {
    this.formControl();

    this.mailService.postCuentaEmailConfig().subscribe((respo) => {
      this.cuentaEmailId = respo[0].cuentaEmailId;
      this.mailForm.patchValue({
        respuesta: '<br/><br/><br/>' + respo[0].email.firma[0].firmaHtml + '<br/><br/><br/>'
      });
    });

  }

  formControl(): void {
    this.mailForm = this.form.group({
      para: new FormControl('', [Validators.required]),
      cc: new FormControl('', []),
      cco: new FormControl('', []),
      asunto: new FormControl('', [Validators.required]),
      respuesta: new FormControl('', []),
    });
  }

  get error(): any { return this.mailForm.controls; }

  CargarArchivo(event): void {
    this.file = event.target.files[0];
    const formData = new FormData();
    formData.append('archivoCarga', this.file);

    this.mailService.postCargarArchivos(formData).subscribe((resp) => {
      this.archivosCargados.push({
        path: resp.data.path,
        name: resp.data.name
      });
    });
  }

  saveEmail(mailForm): void {

    const newMail = new FormData();

    newMail.append( 'asunto' , mailForm.value.asunto );
    newMail.append( 'cuentaEmailId' , this.cuentaEmailId );
    newMail.append( 'emailHtml' , mailForm.value.respuesta );
    newMail.append( 'adjuntos' , JSON.stringify(this.archivosCargados) );
    newMail.append( 'para' , mailForm.value.para );
    newMail.append( 'cc' , mailForm.value.cc );
    newMail.append( 'cco' , mailForm.value.cco );

    this.mailService.postCorreoNuevo(newMail).subscribe((resp) => {
      this.alertsService.alertSuccess('Enviado', resp.data);
      this.dialog.close();
    });
  }

}
