import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-document-signature-dialog',
  templateUrl: './document-signature-dialog.component.html',
  styleUrls: ['./document-signature-dialog.component.sass']
})
export class DocumentSignatureDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(SignaturePad) public signatureElement: SignaturePad;
  @ViewChild('sigContainer', { static: true }) signatureContainer: ElementRef;
  // URL de prueba para el PDF 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf'
  pdf: string | ArrayBuffer | null = null;
  config = { minWidth: 2, canvasWidth: 0, canvasHeight: 0 };

  constructor(
    private authService: AuthService,
    private alertService: AlertsService,
    private dialogRef: MatDialogRef<DocumentSignatureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getDocumentToVerifyDescount();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.resizeSignaturePad();
      window.addEventListener('resize', this.resizeSignaturePad.bind(this));
    });
  }

  /**
   * @author Fabian Duran
   * @createdate 2024-07-29
   * Metodo que setea las dimensiones del canvas.
  */
  resizeSignaturePad(): void {
    if (this.signatureElement && this.signatureContainer) {
      const canvas = this.getCanvasElement();
      if (canvas) {
        canvas.width = this.signatureContainer.nativeElement.clientWidth;
        canvas.height = this.signatureContainer.nativeElement.clientHeight;
        this.signatureElement.clear();
      }
    }
  }
  /**
   * @author Fabian Duran
   * @createdate 2024-07-29
   * Metodo que retorna el canvas del contenedor.
  */
  getCanvasElement(): HTMLCanvasElement | null {
    const canvas = this.signatureContainer.nativeElement.querySelector('canvas');
    if (!canvas) {
      setTimeout(() => this.resizeSignaturePad(), 50);
      return null;
    }
    return canvas;
  }
  /**
   * @author Fabian Duran
   * @createdate 2024-07-29
   * Metodo que genera el documento a firmar.
  */
  getDocumentToVerifyDescount(): void {
    this.authService.getDocumentToVerifyDescount(this.data.document).subscribe(res => {
      const fileReader = new FileReader();
      fileReader.onload = () => this.pdf = fileReader.result;
      fileReader.readAsArrayBuffer(res);
    });
  }
  /**
   * @author Fabian Duran
   * @createdate 2024-07-29
   * Metodo que genera el blob de la firma.
   * @param dataURL Base 64 de la firma.
  */
  generateFile(dataURL: string): Blob {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: mimeString });
  }
  /**
   * @author Fabian Duran
   * @createdate 2024-07-29
   * Metodo que guarda la firma del documento.
  */
  saveSignatureDocument(): void {
    if (this.pdf && !this.signatureElement.isEmpty()) {
      this.alertService.alertWarning('¿Está seguro?', '¿De continuar con la gestión?').then(confirm => {
        if (confirm.isConfirmed) {
          const dataSignature = this.signatureElement.toDataURL();
          const file = this.generateFile(dataSignature);
          const formData = new FormData();
          formData.append('documento', this.data.document);
          formData.append('firma', file, 'firma.png');
          this.authService.saveSignatureDocument(formData).subscribe(res => {
            this.alertService.alertSuccess('¡Excelente!', res.msg);
            this.dialogRef.close();
          });
        }
      });
    } else this.alertService.alertError('Error', 'PDF o firma no cargada');
  }
}