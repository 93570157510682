import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';

const CRM_URL = environment.CRM_URL;
const CARGUES_MASIVOS_CRM = environment.CARGUES_MASIVOS_CRM;

@Injectable({
  providedIn: 'root'
})
export class BDService {

  private headers: HttpHeaders;
  constructor(private http: HttpClient, private AuthService: AuthService) { }



  /**
   * @desc
   * @param formData
   * @returns
   * @author
   */


  public extractColumnsNames(formData) {
    return this.http.post<any>(`${CRM_URL}form/upload/extractColumnsNames`, formData);
    //return this.http.post<any>(`${CARGUES_MASIVOS_CRM}etl_crm/get_load_fields/`, formData);
  }

  public excelClients(formData) {
    return this.http.post<any>(`${CRM_URL}form/upload/excelClients`, formData);
    // return this.http.post<any>(`${CARGUES_MASIVOS_CRM}etl_crm/load_data/`, formData);
  }

  public isPending(form_id): Observable<any> {
    return this.http.get<any>(`${CARGUES_MASIVOS_CRM}etl_crm/is_pending/${form_id}/`);
    // return this.http.post<any>(`${MODULAR_URL}crm_loads/loadExcel`, formData);
  }

  public isErroFile(form_id): Observable<any> {
    return this.http.get<any>(`${CARGUES_MASIVOS_CRM}etl_crm/download_error_exist/${form_id}/`);
    // return this.http.post<any>(`${MODULAR_URL}crm_loads/loadExcel`, formData);
  }

  downloadErrorFile(formId): Observable<any>{
    return this.http.get<any>(`${CARGUES_MASIVOS_CRM}etl_crm/download_error/${formId}/`, {responseType: 'arraybuffer' as 'json'});
  }

  public downloadUploadManangment(formData) {

    return this.http.post<any>(`${CRM_URL}form/upload/downloadManagement`, formData, { responseType: 'text' as 'json'});
  }

  public getDatabaseClients(id, page, paginate = 50) {
    return this.http.get<any>(`${CRM_URL}managements/database/${id}?page=${page}&paginate=${paginate}`);
  }

  public filterDatabaseClients(id, page, paginate = 50, filter) {
    return this.http.post<any>(`${CRM_URL}managements/database/${id}?page=${page}&paginate=${paginate}`, filter);
  }

  public getClient(id) {
    return this.http.get<any>(`${CRM_URL}managements/outbound/client/show/${id}`);
  }
}
