import { Component, OnInit } from '@angular/core';
import { ConfigTable, Paginator } from 'src/app/shared/basic-crud-page/type';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { ComandosCreateComponent } from '../comandos-create/comandos-create.component';
import { CommandsService } from '../services/commands.service';

@Component({
  selector: 'app-comandos-page',
  templateUrl: './comandos-page.component.html',
  styleUrls: ['./comandos-page.component.sass']
})
export class ComandosPageComponent implements OnInit {
  commands: any[] = [];
  filter: string = '';
  configColumnsTable: ConfigTable[] = [
    { name: '', key: 'actions', type: 'events' },
    { name: 'Nombre', key: 'nombre' },
    { name: 'Comando', key: 'comando' },
  ];
  configPaginator: Paginator = { length: 0, pageIndex: 0, pageSize: 5 };

  constructor(
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private commandsService: CommandsService
  ) { }

  ngOnInit(): void {
    this.getCommands();
  }

  /**
    * @author Fabian Duran
    * @createdate 2024-02-22
    * Metodo que trae los comandos registrados en el sistema. 
  */
  getCommands(): void {
    this.commandsService.getCommands(this.configPaginator, this.filter).subscribe(res => {
      this.commands = res.data.data;
      this.configPaginator.length = res.data.total;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-22
    * Metodo que setea el frltro emitido por el componente hijo. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeFilter($event: string) {
    this.filter = $event;
    this.configPaginator.pageIndex = 0;
    this.getCommands();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-22
    * Metodo que actualiza el estado de un comando. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeStateCommand($event: any): void {
    this.commandsService.deleteCommand($event.item.id).subscribe(res => {
      this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
        data: {
          title: '¡Excelente!',
          message: res.data
        },
        duration: 5000,
        verticalPosition: 'top',
        panelClass: ['green-snackbar']
      });
      this.getCommands();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-22
    * Metodo que permite ejecutar la modal para crear o actualizar un comando. 
    * @param command Comando seleccionado de la tabla.  
  */
  onClickShowCommand(command: any = null): void {
    this.matDialog.open(ComandosCreateComponent, {
      width: '40%',
      autoFocus: false,
      panelClass: 'dialog-padding-tray',
      data: {
        command
      }
    }).afterClosed().subscribe(res => {
      if (res) this.getCommands();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-22
    * Metodo que permite actualizar la configuracion de la paginacion.
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangePage($event: Paginator): void {
    this.configPaginator = $event;
    this.getCommands();
  }
}