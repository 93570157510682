<div class="main-container">
  <div class="mt-5">
    <button mat-icon-button class="btn-regresar" [routerLink]="['/mios/calidad/auditorias']">
      <mat-icon matPrefix style="background-color: #000; border-radius: 50%;"><i class="fi-sr-angle-small-left" style="color: #FFF;"></i></mat-icon>
      {{config.regresar}}
    </button>
  </div>
  <header class="mt-5">
    <h1>{{title}}</h1>
    <p>{{config.matrizDe}} {{dataHijo.tipo_matriz}}</p>
    <div *ngIf="dataHijo.tipo_peso != 'Cumplimiento' && dataHijo.tipo_peso != 'Compliance'">
      <h5 *ngIf="dataHijo.tipo_peso == 'Peso en Items' || dataHijo.tipo_peso == 'Weight in Items'">{{config.pesoEnItems}}</h5>
      <h5 *ngIf="dataHijo.tipo_peso == 'Peso en Módulos' || dataHijo.tipo_peso == 'Weight in Modules'">{{config.pesoEnModulos}}</h5>
    </div>
    <h5>{{config.creadaEl}} {{creacion}}</h5>
  </header>
  <p class="primary mt-4">{{config.rangoPeso}} ({{dataHijo.pesoMinimo}} - {{maximo}})</p>
  <p class="blue mb-4">{{config.pesoActual}} ({{peso_actual | number:'1.0-3'}})</p>
  <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="changePage($event)">
    <mat-tab label="{{config.tabLabels.auditar}}">
      <div class="row col-12">
        <div fxFlex.gt-lg="45%" fxFlex.gt-md="35%">
          <app-form-auditar [tiene_inteligencia]="inteligencia" [data]="dataHijo" [dataCalificacion]="dataCalificacion"
                            [id_matriz]="idMatriz" (envioTipoAuditar)="tipo_auditar=$event"
                            (envioTab)="selected.setValue($event);dataHijo.calificacion = ''"></app-form-auditar>
        </div>
        <div fxFlex.gt-lg="55%" fxFlex.gt-md="65%">
          <app-calificacion-auditoria [data]="dataHijo" *ngIf="verCalificacion"
                                      (calificacionModulos)="dataCalificacion = $event" [tipo_auditar]="tipo_auditar"></app-calificacion-auditoria>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{config.tabLabels.retroalimentar}}">
      <app-retroalimentar [idMatriz]="idMatriz"></app-retroalimentar>
    </mat-tab>
  </mat-tab-group>
</div>
