import { D } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, FormBuilder, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClienteSectorService } from '../../services/rest/cliente-sector.service';
import { ClientesService } from '../../services/rest/clientes.service';
import { AlertsService } from '../../../../shared/alerts/alerts.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { CountriesService } from '../../services/rest/countries.service';
import { CitiesService } from '../../services/rest/cities.service';
import * as moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'app-clientes-crear',
  templateUrl: './clientes-crear.component.html',
  styleUrls: ['./clientes-crear.component.sass']
})
export class ClientesCrearComponent implements OnInit {

  clientesForm: FormGroup;
  clientesectorarray: [] = [];
  paisesarray: [] = [];
  element = false;
  idt: any;
  ciudadarray: any = [];
  countries: any;
  cities: any;
  formData = new FormData();
  tipoInarray: any[] = [
    { name: 'Grande', id: 0 },
    { name: 'Mediana', id: 1 },
    { name: 'Pequeña', id: 2 },
    { name: 'Micro', id: 3 }
  ];
  titulo: any;
  contactoarray: any[] = [];
  clientInfo: any;

  constructor(
    private clienteservice: ClientesService,
    private clientesector: ClienteSectorService,
    public dialogRef: MatDialogRef<ClientesCrearComponent>,
    private alertsService: AlertsService,
    private form: FormBuilder,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private countriesService: CountriesService,
    private citiesService: CitiesService,
  ) { }

  ngOnInit(): void {
    this.formControl();
    this.getCountries();
    if(this.data.type == 'crear'){
      this.newItems();
    }
    if(this.data.type == 'editar'){
      this.titulo=this.data.title;
      this.clientesForm.controls['business_name'].disable() ;
      this.clientesForm.controls['id_type'].disable();

      this.clienteservice.searchId(this.data.info.id).subscribe(resp => {
        this.clientInfo = resp.data;
        this.getPaises(resp?.data?.id_type);
        this.getCities(resp?.data?.idCountry);
        this.clientesForm.get("sector_id").setValue(resp?.data?.idSector);
        this.clientesForm.get("country_id").setValue(resp?.data?.idCountry);
        setTimeout(()=>{
          this.clientesForm.get("municipality_id").setValue(resp?.data?.idMunicipality);
        },2000);
        this.clientesForm.get("address").setValue(resp?.data?.address);
        this.clientesForm.get("phone_number").setValue(resp?.data?.phone_number);
        this.clientesForm.get("website").setValue(resp?.data?.website);
        this.clientesForm.get("company_size").setValue(resp?.data?.company_size);
        this.clientesForm.get("observations").setValue(resp?.data?.observations);
        this.clientesForm.get("id_type").setValue(''+resp?.data?.id_type);
        this.clientesForm.get("id_number").setValue(resp?.data?.id_number);
        this.clientesForm.get("name").setValue(resp?.data?.nameClient);
        this.clientesForm.get("business_name").setValue(resp?.data?.business_name);

        this.contactoarray = [];
        this.contactoarray = resp.data?.contacts;
        this.contactoarray.forEach((element:any,index)=>{
            this.newItems();
            this.items.controls[index].get('id').setValue(element.id);
            this.items.controls[index].get('name').setValue(element.name);
            this.items.controls[index].get('charge').setValue(element.charge);
            this.items.controls[index].get('phone').setValue(element.phone);
            this.items.controls[index].get('email').setValue(element.email);
            this.items.controls[index].get('birthday').setValue(element.birthday);
            this.items.controls[index].get('client_id').setValue(element.client_id);
        });
      });
    }
    this.clientesector.getSector().subscribe((resp: any) => {
      this.clientesectorarray = resp;
    });
    //control cambios campo tipo
    this.clientesForm.controls.id_type.valueChanges.subscribe(res => {
      if (res) {
        this.getPaises(res);
        if(this.data.type == 'crear'){
          this.clientesForm.controls.country_id.setValue('');
        }
      }
    });
    //control cambios campo pais
    this.clientesForm.controls.country_id.valueChanges.subscribe(res => {
      if (res) {
        this.getCities(res);
        if(this.data.type == 'crear'){
          this.clientesForm.controls.municipality_id.setValue('');
        }
      }
    });


  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para consultar las ciudades por id de un pais
  * @param {number} countryId id del pais
  */
  getCities(countryId) {
    this.citiesService.searchCities(countryId).subscribe(cities => {
      this.cities = cities.data;
      this.clientesForm.controls.municipality_id.setValue('');
    });
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para consultar los paises
  */
  getCountries() {
    this.countriesService.getCountries().subscribe(countries => {
      this.countries = countries.data;
    });
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para consultar de los paises por tipo nacional o internacional
  * @param {number} type id del tipo de pais
  */
  getPaises(type) {
    this.clienteservice.getPaises(type).subscribe((resp: any) => {
      this.countries = resp.data
    });

  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para la definición de controles del formulario
  */
  formControl(): void {
    if(this.data.type == 'crear'){
      this.clientesForm = new FormGroup({
        id_number: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]),
        business_name: new FormControl('', [Validators.required,Validators.maxLength(255)]),
        id_type: new FormControl('', [Validators.required]),
        name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
        sector_id: new FormControl('', [Validators.required]),
        address: new FormControl('', [Validators.required]),
        phone_number: new FormControl('', [Validators.required, Validators.minLength(7), Validators.pattern("^[0-9]*$"),]),
        website: new FormControl('',[Validators.maxLength(255)]),
        company_size: new FormControl('', [Validators.required]),
        country_id: new FormControl('', [Validators.required]),
        municipality_id: new FormControl('', [Validators.required]),
        observations: new FormControl('',[Validators.maxLength(255)]),
        items: this.form.array([])

      });
    } else if (this.data.type == 'editar') {
      this.clientesForm = new FormGroup({
        id_number: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]),
        business_name: new FormControl('', [Validators.maxLength(255)]),
        id_type: new FormControl(''),
        name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
        sector_id: new FormControl('', [Validators.required]),
        address: new FormControl('', [Validators.required]),
        phone_number: new FormControl('', [Validators.required, Validators.minLength(7), Validators.pattern("^[0-9]*$"),]),
        website: new FormControl('',[Validators.maxLength(255)]),
        company_size: new FormControl('', [Validators.required]),
        country_id: new FormControl('', [Validators.required]),
        municipality_id: new FormControl('', [Validators.required]),
        observations: new FormControl('',[Validators.maxLength(255)]),
        items: this.form.array([])

      });
    }
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para la captura de items de los contactos a crear o editar
  */
  get items(): FormArray {
    return this.clientesForm.get('items') as FormArray;
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para la agrega campos de contacto
  */
  newItems(): void {
    const newItems = this.form.group({
      id: new FormControl('', []),
      name: new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
      charge: new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(1)]),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,6}$"), Validators.maxLength(255), Validators.minLength(1)]),
      phone: new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(7), Validators.pattern("^[0-9]*$")]),
      birthday: new FormControl(''),
      client_id: new FormControl(''),
      itemsFiles: [],
    });
    this.items.push(newItems);
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para la eliminación de sección de campos de contacto
  * @param {number} indice posición a borrar
  * @param {number} id id del contacto a borrar en caso de edición
  */
  deleteItems(indice: number, id: number) {
    this.items.removeAt(indice);
    if(id) {
      if(this.data.type == 'editar') {
        this.clienteservice.deleteComercialContact(id).subscribe(res => {});
      }
    }
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para la creación o edición del cliente
  */
  saveClientes(): void {

    this.formData = new FormData();
    let id_number = this.clientesForm.value.id_number;
    let business_name = this.data.type == 'crear' ? this.clientesForm.value.business_name  : this.clientInfo.business_name;
    let id_type = this.data.type == 'crear' ? this.clientesForm.value.id_type : ''+this.clientInfo.id_type;
    this.formData.append('id_number', id_number || '');
    this.formData.append('business_name', business_name|| '');
    this.formData.append('id_type',id_type|| '');
    this.formData.append('name', this.clientesForm.value.name);
    this.formData.append('sector_id', this.clientesForm.value.sector_id);
    this.formData.append('address', this.clientesForm.value.address);
    this.formData.append('phone_number', this.clientesForm.value.phone_number);
    this.formData.append('website', this.clientesForm.value.website ? this.clientesForm.value.website : '');
    this.formData.append('company_size', this.clientesForm.value.company_size);
    this.formData.append('country_id', this.clientesForm.value.country_id);
    this.formData.append('municipality_id', this.clientesForm.value.municipality_id);
    this.formData.append('observations', this.clientesForm.value.observations ? this.clientesForm.value.observations : '');
    if (this.clientesForm.value.items) {
      this.clientesForm.value.items.forEach((element, index) => {
        let birthday = element.birthday ? moment(moment(`${element.birthday}`).add(1, 'days')).tz('America/Bogota').format('YYYY-MM-DD') : '';
        console.log(element);
        this.formData.append('contactoComercial[' + index + '][id]', element.id);
        this.formData.append('contactoComercial[' + index + '][name]', element.name);
        this.formData.append('contactoComercial[' + index + '][charge]', element.charge);
        this.formData.append('contactoComercial[' + index + '][phone]', element.phone);
        this.formData.append('contactoComercial[' + index + '][email]', element.email);
        this.formData.append('contactoComercial[' + index + '][birthday]', birthday);
      });
    }
    if (this.data.type === 'crear') {
      this.clienteservice.saveCliente(this.formData).subscribe((resp) => {
        if(resp.data.client) {
          this.alertsService.alertWarning('¡Oops!', resp?.data?.message).then((result) => {
            if (result.isConfirmed) {
              this.dialogRef.close();
              this.clienteservice.updateCliente(resp.data.client, this.formData).subscribe((resp) => {
                this.alertsService.alertSuccessWithoutButton('Excelente',`El cliente ${this.clientesForm.value.name || ''} se ha guardado con éxito.`);
                this.dialogRef.close();
              }, error => {
                this.alertsService.alertError('¡Oops!', error?.error);
                this.dialogRef.close();
              });
            }
          });
        } else {
          this.alertsService.alertSuccessWithoutButton('Excelente',`El cliente ${this.clientesForm.value.name || ''} se ha creado con éxito.`);
          this.dialogRef.close();
        }
      }, error => {
        this.alertsService.alertError('¡Oops!', error?.error);
      });
    } else if (this.data.type == 'editar') {
      this.clienteservice.updateCliente(this.data.info.id, this.formData).subscribe((resp) => {
        this.alertsService.alertSuccessWithoutButton('Excelente',`El cliente ${this.clientesForm.value.name || ''} se ha guardado con éxito.`);
        this.dialogRef.close();
      }, error => {
        this.alertsService.alertError('¡Oops!', error?.error);
        this.dialogRef.close();
      });
    }

  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para validar lo digitado por el usuario en el campo de identificación
  */
  keyPress(event: any) {

    const pattern = /[0-9\+\-\,1 ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  /**
   * @author Javier Castañeda
   * @createdate 2022-11-03
   * Metodo para validar lo digitado por el usuario en el campo de identificación
   */
  keyAlphanumericPress(event: any) {
    const pattern = /[a-zA-Z0-9]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para la mostrar contactos
  */
  showContactos() {
    return (this.element = true);
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para cancelar la creación o edición
  */
  opcionCancelar() {
    this.alertsService.alertWarning('¿Estás seguro?', '¿Estás seguro de que <b>deseas cancelar</b> este proceso? Recuerda que la información se borrará automáticamente si realizas esta acción.').then((result) => {
      if (result.isConfirmed) {
        this.dialogRef.close();
      }
    });
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para la consulta de sectores
  */
  getSector() {
    this.clientesector.getSector().subscribe((resp: any) => {
      this.clientesectorarray = resp
    });
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para la captura de errores de los controles del formulario
  */
  get error(): any { return this.clientesForm.controls; }


}
