import { Injectable } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CitiesService {
  COMERCIALURL = environment.CRM_CIAL;

  constructor(
    private http: HttpClient
  ) { }
  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Servicio de consulta de ciudades por id del pais
   * @param {number} countryId id del pais 
   * @returns json
   */
  searchCities(countryId): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('country_id', countryId || '')
    };
    return this.http.get<any>(`${this.COMERCIALURL}municipalityByCountry/`, httpOptions);
  }
}
