import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-inteligencia-negocios',
  templateUrl: './inteligencia-negocios.component.html',
  styleUrls: ['./inteligencia-negocios.component.sass']
})
export class InteligenciaNegociosComponent implements OnInit {
  selected = new FormControl(0);
  tab;
  constructor(
    private aRouter:ActivatedRoute,
    private router:Router,
  ) { }

  ngOnInit(): void {
    this.aRouter.paramMap.subscribe(params=>{
      this.selected.setValue(params.get('tab'));
    })
  }

  changeTab(event){
    this.router.navigateByUrl(`/mios/auditoria/inteligencia-negocios/${event}`);
    this.selected.setValue(event);
  }

}
