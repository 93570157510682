
export class Campaings{
    id: number;
    name: string;
    description: string;
    code: string;
    rrhh_id:number;
    creeated_by: number;
    state: number;
    created_at: Date;
    company_id: number;
}
