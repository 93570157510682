import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/services/rest/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermitService {

  constructor(
    private http: HttpClient, private AuthService: AuthService
  ) { }

  permit(roles, array) {
    let permit = false;
    array.forEach(element => {
      roles.forEach(rol => {
        if (element === rol) {
          permit = true;
          return permit;
        }
      });
    });
    return permit;
  }

}
