import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

const CIU_URL = environment.CIU_URL;
@Injectable({
  providedIn: 'root'
})

export class ScheduleService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-23
   * Servicio que las marcaciones de horarios del usuario logueado
   */
  getSchedule(): Observable<any>{
    return this.http.get<any>(`${CIU_URL}ciu/mySchedule`)
    .pipe(
      catchError(this.handleError)
      );
  }

  postSchedule(tips: FormData): Observable<any>{
    return this.http.post<any>(`${CIU_URL}ciu/logSchedule`, tips)
    .pipe(
      catchError(this.handleError)
      );
  }

  getScheduleGuard(): Observable<any> {
    return this.http.get<any>(`${CIU_URL}ciu/showScheduleCiuStatus`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-02-01
   * Servicio que reinicia el horario de un usuario
   * @param data {"responsible_id":"2","affected_user":"15"}
   */
   scheduleReset(data): Observable<any>{
    return this.http.post<any>(`${CIU_URL}schedule/reset`,data)
    .pipe(
      catchError(this.handleError)
      );
  }

}
