import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ResultService } from '../../../services/result.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import Swal from 'sweetalert2';
import { CampaignService } from '../../../services/campaign.service';
import { RequestService } from '../../../services/request.service';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-analytics-modal',
  templateUrl: './analytics-modal.component.html',
  styleUrls: ['./analytics-modal.component.sass']
})
export class AnalyticsModalComponent implements OnInit {


  @Input() palabrasClave: string[] = [];
  @ViewChild('audioPlayer') audioPlayer: ElementRef<HTMLAudioElement>;
  @ViewChild('containerViewReport') containerViewReport: ElementRef;
  @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef;
  
  @ViewChild('transcripcionPanel') transcripcionPanel: MatExpansionPanel;
  @ViewChild('tiemposMuertosPanel') tiemposMuertosPanel: MatExpansionPanel;
  @ViewChild('resumenPanel') resumenPanel: MatExpansionPanel;
  @ViewChild('analisisTono') analisisTono: MatExpansionPanel;
  @ViewChild('analisisTexto') analisisTexto: MatExpansionPanel;

  @Output() onClose = new EventEmitter<boolean>(); // Emite true si se modifica la transcripción
  
  editMode: boolean = false;
  
  diarization: any;
  llmResponse: any;
  private interactions:any[]=[]
  interactionsFormat:any[] = []

  dataResponse: any;
  jsonInteraccion: any;
  items: string[] = [];  

  audioUrl: any;
  recordingID: any;

  id: any; //id resultados

  //duracion del audio
  audioDuration: number;
  audioDurationMinutos = 0;
  audioDurationSegundos = 0;
  deadTimeaudioDurationMinutos = 0;
  deadTimeaudioDurationSegundos = 0;

  //audio
  start: number=0;
  end: number=0;
  audioEventListener: any;
  dead_time:any;
  
  //dirizacion, tiempos muertos 
  palabrasClaveArray: string[] = [];
  totalDuracionTiemposMuertos: number = 0;
  tiemposMuertos: any[] = [];
  tiemposMuertosMayores: any[] = [];

  
  campaign_id: any;
  deadTimeaudioDurationFormatted: string;
  audioDurationHoras: number;
  audioDurationFormatted: string;

  campaignViciadial: any; //id de la campaña seleccionada
  llmResponseObject: any; // respuesta del resumen
  //variables respuestas
  resumen: any;
  entidadesPrincipales: any;
  temasPrincipales: any;
  puntosClave: any;
  analisisDeSentimientos: any;
  cursorPos: number;

  // graficas torta info
  
  view: any[] = [700, 400];  // Tamaño de la gráfica
  showLegend = true;
  showLabels = true;
  isDoughnut = false;
  gradient = false;
  colorScheme = {
    domain: ['#E57373', '#FFF176', '#B0BEC5', '#1565C0']
  };
  colorScheme2 = {
    domain: ['#1565C0', '#B0BEC5', '#FFF176']
  };

  emocionesData: any[] = [];
  sentimientosData: any[] = [];
  emocionesDataPorSpeaker: any[];
  sentimientosDataPorSpeaker: any[];

  
  modificarInteracciones: boolean = false; //modificar iteraciones para anlisis
  selectAll: boolean = true; //sekecciona todas las iteracciones
  indeterminate: boolean = false; //validas si hay interacciones indeterminadas
  selectedInteractions: any[] = []; //darle manejo a la iteraciones que se tienen encuenta para emociones y sentimeintos


  constructor(
  public dialogRef: MatDialogRef<AnalyticsModalComponent>,
  private resultService: ResultService,
  private requestService: RequestService,
  private authService: AuthService,
  private campaignServdce: CampaignService,
  private sanitazer:DomSanitizer,
  @Inject(MAT_DIALOG_DATA) 
  private date: any ) 
  {}

  ngOnInit(): void {
    this.onClose.emit(false);// preterminado si no se ha modificado

    this.recordingID=this.date.recordingId;
    this.campaignViciadial= this.date.campaignVicidial_id;

    if (this.campaignViciadial > 0) {
      this.getdataCampaign();
    } else {
      this.dead_time = 30;
      this.getResultados();
    }

    this.audioUrl = this.requestService.getAudioUrl(this.recordingID);
    const user = this.authService.getUser();
    this.campaign_id=user.rrhh.campaign_id;
    this.obtenerDuracionTotalAudio();
  }


  /**
   * Metodo obtener la data para establecer los segmentos de los tiempos muertos por campaña
   * @author Yeison Sepulveda
   * @createdate 27/02/2024
  */

  getdataCampaign(): void {
    this.campaignServdce.showCampaign(this.campaignViciadial).subscribe(
      (rest) => {
        this.dead_time = rest.data.dead_time;
        this.getResultados();
      },
      (error) => {
        console.error('Error al recuperar la campaña:', error.message);
        Swal.fire({
          title: 'Error al recuperar la campaña',
          text: error.message,
          icon: 'error',
          confirmButtonText: '¡Entendido!',
          confirmButtonColor: '#2CABBC',
        });
      }
    );
  }

  /**
  * Metodo que establece los tiempos de inicio y fin y controla la reproducción de un audio.
  * @param {number} start - Tiempo de inicio en segundos.
  * @param {number} end - Tiempo de fin en segundos.
  * @returns {void}
  * @author Yeison Sepulveda
  * @createdate 27/02/2024
  */

  logStartAndEnd(start: number, end: number): void {
    this.start = start;
    this.end = end;
  
    if (this.audioPlayer) {
      this.audioPlayer.nativeElement.currentTime = this.start;
      this.audioPlayer.nativeElement.play();
  
      const timeUpdateListener = () => {
        if (this.audioPlayer.nativeElement.currentTime >= this.end) {
          this.audioPlayer.nativeElement.pause();
          //despues de pausar crea un nuevo objeto resetea el tiempo limite al total del audio
          const audio = new Audio(this.audioUrl);
          audio.addEventListener('loadedmetadata', () => {
            const durationInSeconds = audio.duration;
            this.end=durationInSeconds;
          })
        }
      };
      this.audioPlayer.nativeElement.addEventListener('timeupdate', timeUpdateListener);
    } else {
    }
  }

  /**
   * Subraya las palabras clave dentro de un texto.
   * @author Yeison Sepulveda
   * @createdate 27/02/2024
   * @param {string} text - El texto en el que se buscarán y resaltarán las palabras clave.
   * @param {string} term - La palabra clave que se debe resaltar.
   * @returns {string} El texto con las palabras clave resaltadas.
  */
  highlightedTerms: Set<string> = new Set();

  highlightedText(text: string, terms: Set<string>): string {
    if (!terms || terms.size === 0) {
      return text;
    }

    const styleCss = 'padding: 0.1rem 0.4rem; background-color: #00acc1; color: #fff; border-radius: 50px;';
    terms.forEach(term => {
      if (term && term.trim()) {
        const regex = new RegExp(`\\b${term}\\b`, 'gi');
        text = text.replace(regex, `<span style='${styleCss}'>$&</span>`);
      }
    });

    return text;
  }

  /**
   * Elimina el resaltado de las palabras clave dentro de un texto resaltado.
   * @author Yeison Sepulveda
   * @createdate 20/05/2024
   * @param {string} text - El texto con las palabras clave resaltadas.
   * @returns {string} El texto sin el resaltado de las palabras clave.
  */
  
  removeHighlight(text: string): string {
    const spanRegex = /<span style='padding: 0.1rem 0.4rem; background-color: #00acc1; color: #fff; border-radius: 50px;'>(.*?)<\/span>/g;
    return text.replace(spanRegex, '$1');
  }

  /**
 * Verifica si un texto está resaltado.
 * @author Yeison Sepulveda
 * @createdate 20/05/2024
 * @param {string} text - El texto que se quiere verificar si está resaltado.
 * @returns {boolean} Verdadero si el texto está resaltado, falso si no lo está.
 */

  isTextHighlighted(text: string): boolean {
    const styleCss = 'padding: 0.1rem 0.4rem; background-color: #00acc1; color: #fff; border-radius: 50px;';
    return text.includes(styleCss);
  }

    /**
   * Metodo para los chips en los cuales busca las palabras y los subraya
   * @author Yeison Sepulveda
   * @createdate 27/02/2024
   */

  toggleHighlight(palabra: string): void {
    if (this.highlightedTerms.has(palabra)) {
      this.highlightedTerms.delete(palabra);
    } else {
      this.highlightedTerms.add(palabra);
    }

    this.interactionsFormat = this.interactions.map(interaction => {
      let text = interaction.text;

      // Primero elimina todos los resaltados para una actualización completa
      text = this.removeHighlight(text);

      // Aplica los resaltados actuales
      text = this.highlightedText(text, this.highlightedTerms);

      return { ...interaction, text: this.sanitazer.bypassSecurityTrustHtml(text) };
    });
  }

  /**
   * Método que consume un servicio para obtener resultados de análisis
   * @author Yeison Sepulveda
   * @createdate 27/02/2024
   */

  getResultados(): void {
    this.resultService.showAnalyticsResults(this.recordingID).subscribe(
        result => {
            const jsonData = JSON.parse(result.data.diarization.interaccion); 
            this.interactions = Array.isArray(jsonData) ? jsonData : [jsonData];
            this.interactionsFormat = Array.isArray(jsonData) ? jsonData : [jsonData];

            // Añadir el emoji correspondiente a cada interacción
            this.interactionsFormat.forEach(interaction => {
              const tooltipData = this.getSentimentTooltip(interaction.sentimiento);
              interaction.emoji = tooltipData.emoji;
              interaction.tooltip = `${tooltipData.message} ${tooltipData.emoji} `;
            });

            const jsonDataDeadTimes = JSON.parse(result.data.deadtimes.tiempos_muertos); 
            this.tiemposMuertos = Array.isArray(jsonDataDeadTimes) ? jsonDataDeadTimes : [jsonDataDeadTimes]; 

            this.llmResponseObject = result.data.llm_response; 
            this.palabrasClaveArray = this.llmResponseObject.palabras_clave;

            this.resumen = result.data.llm_response.resumen;
            this.palabrasClave = result.data.llm_response.palabras_clave;
            this.entidadesPrincipales = result.data.llm_response.entidades_principales;
            this.temasPrincipales = result.data.llm_response.temas_principales;
            this.puntosClave = result.data.llm_response.puntos_clave;
            this.analisisDeSentimientos = result.data.llm_response.analisis_de_sentimientos;

            this.id = result.data.id;

            // Inicializar el estado de los checkboxes
            this.interactionsFormat.forEach(interaction => {
              interaction.selected = true; // Inicialmente todas seleccionadas
            });

            // Calcular porcentajes de emociones y sentimientos
            this.calcularPorcentajes(this.interactionsFormat);

            // Comparacion de los tiempos muertos de la campana con los segmentos de audio
            const deadTimeFloat = parseFloat(this.dead_time);
            this.tiemposMuertosMayores = this.tiemposMuertos.filter(tiempo => {
                const duracion = tiempo.end - tiempo.start;
                return duracion > deadTimeFloat;
            });

            this.totalDuracionTiemposMuertos = parseFloat(this.tiemposMuertos.reduce((total, tiempo) => {
                return total + (tiempo.end - tiempo.start);
            }, 0).toFixed(3));

            this.deadTimeaudioDurationMinutos = Math.floor(this.totalDuracionTiemposMuertos / 60);
            this.deadTimeaudioDurationSegundos = Math.round(this.totalDuracionTiemposMuertos % 60);

            // Formatear el tiempo en el formato hh:mm:ss
            const formatTime = (time: number): string => {
                const hours = Math.floor(time / 3600);
                const minutes = Math.floor((time % 3600) / 60);
                const seconds = Math.floor(time % 60);
                return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
            };

            // Formatear los tiempos muertos
            this.deadTimeaudioDurationFormatted = formatTime(this.totalDuracionTiemposMuertos);

        },
        error => {
            console.error('Error al recuperar los resultados del análisis:', error.message);
              Swal.fire({
                title: 'Error al recuperar los resultados del análisis:',
                icon: 'error',
                text: error.message,
                confirmButtonText: '¡Entendido!',
                showConfirmButton: true,
                confirmButtonColor: '#2CABBC',
            }).then((result) => {
              if (result.isConfirmed) {
                this.dialogRef.close();
              }
            });
        }
    );
  }

  /**
   * Método que obtiene y valida la duración total del archivo de audio especificado por audioUr.
   * @author Yeison Sepulveda
   * @createdate 27/02/2024
   */
  
  obtenerDuracionTotalAudio() {
    const audio = new Audio();
    audio.src = this.audioUrl;
    audio.onloadedmetadata = () => {
      this.audioDuration = audio.duration;
  
      //formatear la duración total del audio en el formato hh:mm:ss
      this.audioDurationFormatted = this.formatTime(this.audioDuration);
    };
  }

  /**
   * Formatea un objeto de tiempo en una cadena de texto en formato de hora (HH:MM:SS).
   * @author Yeison Sepulveda
   * @createdate 22/04/2024
   * @param {Object} tiempo - Objeto que contiene los campos 'start' y 'end' que representan los tiempos de inicio y fin en segundos.
   * @param {number} tiempo.start - Tiempo de inicio en segundos.
   * @param {number} tiempo.end - Tiempo de fin en segundos.
   * @returns {string} Una cadena de texto en formato de hora (HH:MM:SS) que representa el intervalo de tiempo entre el tiempo de inicio y el tiempo de fin.
   */
  
  formatTiempo(tiempo: any): string {
    const formatTime = (time: number): string => {
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time % 3600) / 60);
      const seconds = Math.floor(time % 60);
      return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    };
  
    const startFormatted = formatTime(tiempo.start);
    const endFormatted = formatTime(tiempo.end);
  
    return `${startFormatted} - ${endFormatted}`;
  }

  calcularDuracion(start: number, end: number): string {
    const durationInSeconds = end - start;
    return this.formatTime(durationInSeconds);
  }


  /**
   * Formatea un número de segundos en una cadena de texto en formato de hora (HH:MM:SS).
   * @author Yeison Sepulveda
   * @createdate 22/04/2024
   * @param {number} time - El número de segundos a formatear.
   * @returns {string} Una cadena de texto en formato de hora (HH:MM:SS).
   */
  
  formatTime(time: number): string {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  }
  

  /**
   * Validar el posicionamiento para graficar los tiempos muertos
   * @author Yeison Sepulveda
   * @createdate 27/02/2024
   * @returns void {left: posicionInicio + '%', width: longitud + '%'}    
   */

  calcularPosicionSegmento(segmento: any): { left: string, width: string } {
    const duracionTotal = this.audioDuration;
    const posicionInicio = (segmento.start / duracionTotal) * 100;
    const longitud = ((segmento.end - segmento.start) / duracionTotal) * 100;
    return { left: posicionInicio + '%', width: longitud + '%' };
  }


  /**
   * Metodo descarga e imprime pantallazo del modal
   * @update Yeison Sepulveda
   * @updatedate 29-05-2024
  */
  async onClickPrintPdf() {
    if (this.modificarInteracciones) {
      this.modificarInteracciones = false;
    }
    this.transcripcionPanel.open();
    this.tiemposMuertosPanel.open();
    this.resumenPanel.open();
    this.analisisTono.open();
    this.analisisTexto.open();
    await new Promise(resolve => setTimeout(resolve, 1000));
    const content = this.containerViewReport.nativeElement;

    const filename = this.generateFilename();
    const margin = 13;

    const options = {
        margin: margin,
        filename: filename,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
            scale: 2,
            useCORS: true,
            logging: true
        },
        jsPDF: {
            unit: 'mm',
            format: 'tabloid',
            orientation: 'portrait'
        },
        pagebreak: {
            mode: ['avoid-all', 'css', 'legacy']
        }
    };

    const testPDF = html2pdf().from(content).set(options).toContainer().toCanvas().toImg().toPdf(); 
    testPDF.get('pdf').then(function (pdf) {
        const totalHeight = content.scrollHeight * 0.264583 + 2 * margin; 
        pdf.internal.pageSize = { width: 430, height: totalHeight }; 
    });

    testPDF.save()
  }


  /**
   * Metodo generar el nombre del documento a descargar
   * @author Yeison Sepulveda
   * @createdate 2024-0-03
   * @return nombre del archivo
  */

  generateFilename(): string {
    const currentDate = new Date().toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '');
  
    const currentTime = new Date().toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).replace(/:/g, '');
  
    const dateTimeString = `${currentDate}${currentTime}`;
    return `reporte_analisis_${this.recordingID}_${dateTimeString}.pdf`;
  }

  /**
   * Metodod habila la edicion e la trascripcion
    * @author Yeison Sepulveda
    * @createdate 14-05-2024
    * @update Yeison Sepulveda
  */
  toggleEditMode() {
    this.editMode = !this.editMode;
    this.highlightedTerms.clear();
    if (this.modificarInteracciones) {
      this.modificarInteracciones = false;
    }
    if (!this.editMode) {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¿Quieres guardar los cambios?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2CABBC',
            cancelButtonColor: '#FFFFFF',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Aceptar',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                this.saveChanges();
                this.onClose.emit(true);
            } else {
                this.editMode = true;
                this.onClose.emit(false);
            }
        });
    }
  }

  /**
   * Método que guarda los cambios realizados en los textos editables y los envía al backend a través de un servicio.
   * @author Yeison Sepulveda
   * @createdate 14-06-2024
  */

  saveChanges(): void {
    // json envio
    const dataForm = {
      id: this.id, 
      diarization: { interaccion: this.interactions } 
    };

    this.resultService.updateTranscription(dataForm).subscribe(
      response => {
        console.log('Respuesta:', response);
        Swal.fire({
          title: 'Excelente',
          text: 'Los cambios se han guardado satisfactoriamente.',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false
        });

      },
      error => {
        console.error('Ha ocurrido un error al guardar los cambios:', error.message);
        Swal.fire({
          title: 'Ha ocurrido un error al guardar',
          icon: 'error',
          text: error.message,
          confirmButtonText: '¡Entendido!',
          showConfirmButton: true,
          confirmButtonColor: '#2CABBC',
        });
      }
    );
    
  }

  /**
   * Método que actualiza el contenido del texto en tiempo real mientras se edita,
   * @autor Yeison Sepulveda
   * @createdate 19-06-2024
   * @param {Event} event - El evento de entrada que se dispara cuando el usuario edita el contenido.
   * @param {number} index - El índice del elemento en el array interactionsFormat que está siendo editado.
  */

  saveTextContent(event: any, index: number): void {
    const updatedText = event.target.innerHTML;
    this.interactionsFormat[index].text = updatedText;
    this.interactions[index].text = updatedText;
  }

  /**
   * Método que devuelve un tooltip con un emoji y un mensaje correspondiente a un sentimiento dado.
   * @author Yeison Sepulveda
   * @createdate 27-06-2024
   * @param {string} sentimiento - El sentimiento para el cual se desea obtener el tooltip.
   * @returns {{ emoji: string, message: string }} - Un objeto con un emoji y un mensaje correspondientes al sentimiento.
   */
  getSentimentTooltip(sentimiento: string): { emoji: string, message: string } {
    switch (sentimiento) {
      case 'NEG':
        return { emoji: '😡', message: 'Negativo' };
      case 'NEU':
        return { emoji: '😐', message: 'Neutral' };
      case 'POS':
        return { emoji: '😊', message: 'Positivo' };
      default:
        return { emoji: '😐', message: 'Neutral' };
    }
  }

  /**
   * Método que formatea los datos por speaker en base a las emociones.
   * @author Yeison Sepulveda
   * @createdate 25-06-2024
   * @param {{ [key: string]: any }} data - Los datos de las emociones por speaker.
   * @param {number} totalInteracciones - El número total de interacciones.
   * @returns {any[]} - Un array de datos formateados por speaker.
   */
  private formatDataPorSpeaker(data: { [key: string]: any }, totalInteraccionesPorSpeaker: { [key: string]: number }): any[] {
    const formattedData = [];
  
    Object.keys(data).forEach(speaker => {
      if (speaker) {
        const speakerTotalInteracciones = totalInteraccionesPorSpeaker[speaker];
        formattedData.push(
          {
            name: speaker,
            series: [
              { name: 'Enojo', value: +(data[speaker].ang / speakerTotalInteracciones * 100).toFixed(2)},
              { name: 'Felicidad', value: +(data[speaker].hap / speakerTotalInteracciones * 100).toFixed(2)},
              { name: 'Neutral', value: +(data[speaker].neu / speakerTotalInteracciones * 100).toFixed(2)},
              { name: 'Tristeza', value: +(data[speaker].sad / speakerTotalInteracciones * 100).toFixed(2)}
            ]
          }
        );
      }
    });
  
    return formattedData;
  }
  
  /**
   * Método para formatear datos por speaker en base a los sentimientos.
   * @param data - Los datos de sentimientos por speaker.
   * @param totalInteraccionesPorSpeaker - El número total de interacciones por speaker.
   * @returns Un array de datos formateados por speaker.
   */
  private formatSentimientosPorSpeaker(data: { [key: string]: any }, totalInteraccionesPorSpeaker: { [key: string]: number }): any[] {
    const formattedData = [];
  
    Object.keys(data).forEach(speaker => {
      if (speaker) {
        const speakerTotalInteracciones = totalInteraccionesPorSpeaker[speaker];
        formattedData.push(
          {
            name: speaker,
            series: [
              { name: 'Negativo', value: +(data[speaker].NEG / speakerTotalInteracciones * 100).toFixed(2)},
              { name: 'Neutral', value: +(data[speaker].NEU / speakerTotalInteracciones * 100).toFixed(2)},
              { name: 'Positivo', value: +(data[speaker].POS / speakerTotalInteracciones * 100).toFixed(2)}
            ]
          }
        );
      }
    });
  
    return formattedData;
  }

  /**
   * Método que calcula los porcentajes de emociones y sentimientos en las interacciones.
   * @author Yeison Sepulveda
   * @createdate 28-06-2024
   * @param {any[]} interactions - Un array de interacciones que contiene emociones y sentimientos.
   */
  calcularPorcentajes(interactions: any[]): void {
    const emocionContadores = { ang: 0, hap: 0, neu: 0, sad: 0 };
    const sentimientoContadores = { NEG: 0, NEU: 0, POS: 0 };
    const totalInteracciones = interactions.length;

    if (totalInteracciones === 0) {
      // Si no hay interacciones, se asigna valor neutral a todos los sentimientos y emociones 
      this.sentimientosData = [
        { name: 'Negativo', value: 0, label: '0%' },
        { name: 'Neutral', value: 100, label: '100%' },
        { name: 'Positivo', value: 0, label: '0%' }
      ];
      this.emocionesData = [
        { name: 'Enojo', value:  0},
        { name: 'Felicidad', value: 0},
        { name: 'Neutral', value: 100},
        { name: 'Tristeza', value: 0}
      ];
      this.sentimientosDataPorSpeaker = []; 
      this.emocionesDataPorSpeaker = []; 
      return;
    }
  
    // Contadores por speaker y sus totales
    const emocionContadoresPorSpeaker: { [key: string]: any } = {};
    const sentimientoContadoresPorSpeaker: { [key: string]: any } = {};
    const totalInteraccionesPorSpeaker: { [key: string]: number } = {};
  
    interactions.forEach(interaction => {
      const emocion = interaction.emocion;
      const sentimiento = interaction.sentimiento;
      const speaker = interaction.speaker || 'SPEAKER';
  
      if (speaker) {
        // Contadores generales
        if (emocionContadores[emocion] !== undefined) {
          emocionContadores[emocion]++;
        }
  
        if (sentimientoContadores[sentimiento] !== undefined) {
          sentimientoContadores[sentimiento]++;
        }
  
        // Contadores por speaker
        if (!emocionContadoresPorSpeaker[speaker]) {
          emocionContadoresPorSpeaker[speaker] = { ang: 0, hap: 0, neu: 0, sad: 0 };
        }
        if (!sentimientoContadoresPorSpeaker[speaker]) {
          sentimientoContadoresPorSpeaker[speaker] = { NEG: 0, NEU: 0, POS: 0 };
        }
        if (!totalInteraccionesPorSpeaker[speaker]) {
          totalInteraccionesPorSpeaker[speaker] = 0;
        }
  
        if (emocionContadoresPorSpeaker[speaker][emocion] !== undefined) {
          emocionContadoresPorSpeaker[speaker][emocion]++;
        }
  
        if (sentimientoContadoresPorSpeaker[speaker][sentimiento] !== undefined) {
          sentimientoContadoresPorSpeaker[speaker][sentimiento]++;
        }
  
        // Incrementar el total de interacciones por speaker
        totalInteraccionesPorSpeaker[speaker]++;
      }
    });

      // Datos generales
  this.emocionesData = [
    { name: 'Enojo', value: (emocionContadores.ang / totalInteracciones) * 100},
    { name: 'Felicidad', value: (emocionContadores.hap / totalInteracciones) * 100},
    { name: 'Neutral', value: (emocionContadores.neu / totalInteracciones) * 100},
    { name: 'Tristeza', value: (emocionContadores.sad / totalInteracciones) * 100}
  ];

  this.sentimientosData = [
    { name: 'Negativo', value: (sentimientoContadores.NEG / totalInteracciones) * 100},
    { name: 'Neutral', value: (sentimientoContadores.NEU / totalInteracciones) * 100},
    { name: 'Positivo', value: (sentimientoContadores.POS / totalInteracciones) * 100}
  ];
  
  
    // Datos para gráficas por speaker
    this.emocionesDataPorSpeaker = this.formatDataPorSpeaker(emocionContadoresPorSpeaker, totalInteraccionesPorSpeaker);
    this.sentimientosDataPorSpeaker = this.formatSentimientosPorSpeaker(sentimientoContadoresPorSpeaker, totalInteraccionesPorSpeaker);
  }

  /**
   * Método asignar valor del label a la grafica de torta
   * @author Yeison Sepulveda
   * @createdate 2024-07-17
   */
  pieChartLabel(series: any[], name: string): string {
    const item = series.find(data => data.name === name);
    
    const value = item.value;
    const formattedValue = value % 1 !== 0 ? value.toFixed(2) : value.toString();
    return `${formattedValue}%`;
  }

  /**
   * Metodo para manejar los cambios de selección en las interacciones.
   * @author Yeison Sepulveda
   * @createdate 2024-08-27
   */
  onSelectionChange(): void {
    this.selectedInteractions = this.interactionsFormat.filter(item => item.selected);
    console.log('select interacciones', this.selectedInteractions)
    this.updateGraphs();
    this.updateCheckboxState(); 
  }

  /**
   * Metodo para actualizar la informacion de las graficas basadas en las interacciones seleccionadas
   * @author Yeison Sepulveda
   * @createdate 2024-08-27
   */
  updateGraphs() {
    const selectedInteractions = this.selectedInteractions; 
    this.calcularPorcentajes(selectedInteractions);
  }

  /**
   * Metodo para actualizar la informacion de las graficas basadas en las interacciones seleccionadas
   * @author Yeison Sepulveda
   * @createdate 2024-08-27
   */
  toggleModificacion() {
    if (!this.modificarInteracciones) {
      Swal.fire({
        title: '¿Estás seguro?',
        text: 'Podrás desactivar interacciones y no se tendrán en cuenta en el análisis de las gráficas. Estos cambios son temporales.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2CABBC',
        cancelButtonColor: '#FFFFFF',
        confirmButtonText: 'Sí, continuar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.modificarInteracciones = true;
        }
      });
    } else {
      Swal.fire({
        title: 'Advertencia',
        text: 'Recuerda que si deseleccionas algo, no se tendrá en cuenta para el análisis de las gráficas. Los cambios son temporales y puedes reactivarlos si lo deseas.',
        icon: 'warning',
        confirmButtonColor: '#2CABBC',
        confirmButtonText: '¡Entendido!'
      }).then(() => {
        this.modificarInteracciones = false;
      });
    }
  }

  /**
   * Metodo para Manejar el cambio en el checkbox global
   * @author Yeison Sepulveda
   * @createdate 2024-08-28
   */
  toggleSelectAll(checked: boolean): void {
    this.interactionsFormat.forEach(item => item.selected = checked);
    this.onSelectionChange(); 
    this.updateCheckboxState();
  }
  
  /**
   * Metodo para actualizar el estado del checkbox global
   * @author Yeison Sepulveda
   * @createdate 2024-08-28
   */
  updateCheckboxState(): void {
    const allSelected = this.interactionsFormat.every(item => item.selected);
    const noneSelected = this.interactionsFormat.every(item => !item.selected);

    this.selectAll = allSelected;
    this.indeterminate = !allSelected && !noneSelected;
  }

  /**
   * Metodo para manejar estado indeterminado 
   * @author Yeison Sepulveda
   * @createdate 2024-08-28
   */
  isIndeterminate(): boolean {
    return this.indeterminate;
  }
  
}
