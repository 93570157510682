import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { NoveltiesService } from 'src/app/modules/nomina/services/novelties.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-novedades-response',
  templateUrl: './novedades-response.component.html',
  styleUrls: ['./novedades-response.component.sass']
})
export class NovedadesResponseComponent implements OnInit {

  dataInfo: any;
  public realTime: string;
  public scheduleTime: string;
  public endTime: string;
  public noveltyDetailHeight: number;

  public showJustifyForm: boolean;
  public showJustifyInfo: boolean;
  public showInput: boolean;
  noveltiesForm: FormGroup;
  justifyForm: FormGroup;

  options: any = [
    { id: 0, name: 'Rechazar' },
    { id: 1, name: 'Aprobar' }
  ];

  justifyTipes: any = [
    { id: 'sistema_fuera_linea', name: 'Sistema fuera de línea' },
    { id: 'protesta_evento', name: 'Protesta o evento social' },
    { id: 'compensatorio', name: 'Compensación de tiempo' },
    { id: 'sin_justificacion', name: 'Sin justificación' }
  ];
  formData = new FormData();
  fieldLength: any;
  boolLength: boolean = false;

  constructor(private noveltiesService: NoveltiesService,
              public dialogRef: MatDialogRef<NovedadesResponseComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private alert: AlertsService,
              public dialog: MatDialog) {
  }

  ngOnInit() {

    this.noveltiesForm = new FormGroup({
      state: new FormControl('', [Validators.required]),
      response: new FormControl('', [Validators.required])
    });
    this.justifyForm = new FormGroup({
      type: new FormControl('', [Validators.required]),
      response: new FormControl('', [Validators.required])
    });
    this.noveltiesService.getEventInfoByPayrollNoveltyId(this.data.data.novelty_user_id, this.data.data.id).subscribe(resp => {
      this.dataInfo = resp.data;
      this.dataInfo.novelty_type.key = (this.dataInfo.novelty_type.key < 10) ? `0${ this.dataInfo.novelty_type.key }` : this.dataInfo.novelty_type.key;
      this.realTime = moment(resp.data.real_start_time).locale('es').format('hh:mm a').toString();
      this.scheduleTime = moment(`${ resp.data.schedule_info.schedule_date } ${ resp.data.schedule_info.scheduled_start_time }`).locale('es').format('hh:mm a').toString();
      this.endTime = moment(`${ resp.data.schedule_info.schedule_date } ${ resp.data.schedule_info.scheduled_end_time }`).locale('es').format('hh:mm a').toString();

      if (this.dataInfo.validForMoreResponses) {
        this.showJustifyForm = true;
      }
      this.showJustifyInfo = true;
      this.changeDetailHeight(this.dataInfo);
    });

    this.justifyForm.controls.type.valueChanges.subscribe(resp => {
      if(resp == 'sistema_fuera_linea'){
        this.justifyForm.controls.response.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(10)]);
        this.justifyForm.controls.response.updateValueAndValidity();
        this.justifyForm.controls.response.setValue('');
      } else {
        this.justifyForm.controls.response.setValidators([Validators.required, Validators.maxLength(250)]);
        this.justifyForm.controls.response.updateValueAndValidity();
        this.justifyForm.controls.response.setValue('');
        this.boolLength = false;
      }
    });
    
    this.justifyForm.controls.response.valueChanges.subscribe(resp => {
      let value = '';
      value = resp?.toString();
      if(this.justifyForm.controls.type.value == 'sistema_fuera_linea'){
        this.fieldLength = value?.length;
        if(this.fieldLength > 0) {
          if(this.fieldLength < 3 ) {
            this.boolLength = true;
          } else if(this.fieldLength > 10) {
            this.boolLength = true;
          } else {
            this.boolLength = false;
          }
        }

      } else {
        this.boolLength = false;
        this.justifyForm.controls.response.setValue('');
      }
    });
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-04-15
   * Metodo para asignacion de detalle en primera instancia
   */
  changeDetailHeight(novelty): void {
    if (novelty.responseFirstInstance && (novelty.responseFirstInstance.justify || novelty.responseFirstInstance.response)) {
      this.noveltyDetailHeight = 240;
    }
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-04-15
   * Metodo Aprobar o rechazar novedad
   */
  UpdateState() {
    const state = this.noveltiesForm.controls['state'].value;
    this.noveltiesService.changeStatus(this.data.data.id, this.noveltiesForm.value).subscribe(resp => {
      if (state == 1) {
        this.alert.alertSuccess('Novedad actualizada!', 'La novedad ha sido aprobada');
        this.dialogRef.close('');
      } else {
        this.alert.alertSuccess('Novedad actualizada!', 'La novedad ha sido rechazada');
        this.dialogRef.close('');
      }

    }, error => {
      this.alert.alertError('Error!', 'Ocurrio un error al intentar actualizar la novedad');
    });
  }
  /**
   * @author Javier Castañeda 
   * @createdate 2022-04-07
   * Metodo justicar retardos
   */
  saveObservation() {
    this.formData = new FormData();
    this.formData.append('justify_type', this.justifyForm.controls.type.value);
    this.formData.append('justify', this.justifyForm.controls.response.value);
    this.formData.append('type_key', this.data.data?.novelty_type?.key);
    this.formData.append('Ids[]', this.data.data.id);
  
    this.noveltiesService.justifyNovelties(this.formData).subscribe(resp => {
      this.alert.alertSuccessWithoutButton('¡Excelente!', 'La novedad se ha justificado con éxito.');
      this.dialogRef.close();
    });
  }

}
