<div class="d-flex justify-content-end">
  <div style="color: #353535;">
    <button mat-dialog-close mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="p-5">
  <div class="mb-4" style="font-weight: 700; font-size: 28px;">
    {{ data.command === null ? 'Crear comando' : 'Editar comando' }}
  </div>
  <mat-divider></mat-divider>
  <form class="mt-4" [formGroup]="formCommand">
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Nombre del comando</mat-label>
          <input matInput placeholder="Nombre del comando" formControlName="nombre">
          <mat-error *ngIf="error.nombre.errors">
            <span *ngIf="error.nombre.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Detalle del comando</mat-label>
          <input matInput placeholder="Detalle del comando" formControlName="comando">
          <mat-error *ngIf="error.comando.errors">
            <span *ngIf="error.comando.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-6">
        <button mat-dialog-close type="button" mat-raised-button class="w-100"
          style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Cancelar</button>
      </div>
      <div class="col-md-6">
        <button (click)="saveCommand()" type="button" mat-raised-button color="primary" class="w-100"
          style="height: 48px; font-weight: 600;">{{ data.command === null ? 'Crear' : 'Guardar' }}</button>
      </div>
    </div>
  </form>
</div>