import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from '../shared/material/material.module';
import { BlockCopyPasteDirective } from './block-copy-paste.directive';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotChangePasswordComponent } from './forgot-change-password/forgot-change-password.component';
import { DobbleAuthComponent } from './dobble-auth/dobble-auth.component';
import { ForgotUserComponent } from './forgot-user/forgot-user.component';
import { DocumentSignatureDialogComponent } from './document-signature-dialog/document-signature-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SecurityQuestionsComponent } from './security-questions/security-questions.component';

@NgModule({
  declarations: [
    LoginComponent,
    BlockCopyPasteDirective,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    ForgotChangePasswordComponent,
    DobbleAuthComponent,
    ForgotUserComponent,
    DocumentSignatureDialogComponent,
    SecurityQuestionsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PdfViewerModule,
    SignaturePadModule
  ],
  exports: [
    LoginComponent
  ]
})
export class AuthModule { }
