import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { GroupsService } from '../../../services/rest/groups.service';
import { DestroyComponentService } from '../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CampaignService } from '../../../services/rest/campaign.service';
import { startWith, map, delay, debounceTime, takeUntil, tap, filter } from 'rxjs/operators';
import { UsersService } from '../../../services/rest/users.service';
import { CandidatesService } from 'src/app/modules/rrhh/services/candidates.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-save-groups',
  templateUrl: './save-groups.component.html',
  styleUrls: ['./save-groups.component.sass']
})
export class SaveGroupsComponent implements OnInit {

  groupForm: FormGroup;
  filteredOptions: Observable<any[]>;
  userRRHH: any;
  userSinGroup: any[] = [];
  userSinGroupNuevo: any[] = [];
  userGroup: any[] = [];
  userSelected: any[] = [];

  /** list of campaigns */
  protected campaigns: any[] = [];
  /** control for the selected campaign for server side filtering */
  public CampaignServerSideCtrl: FormControl = new FormControl();
  /** control for filter for server side. */
  public campaignServerSideFilteringCtrl: FormControl = new FormControl('');
  /** indicate search operation is in progress */
  public searching: boolean = false;
  /** list of campaigns filtered after simulating server side search */
  public filteredServerSideCampaigns: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  //documento
  documentNumber = new FormControl();
  typeDocument = new FormControl(1);
  documents: any = [];
  idTypeDoc: any = 1;
  document: any;
  userInfo: any = [];

  constructor(private campaignService: CampaignService,
    private groupService: GroupsService,
    private userServce: UsersService,
    private destroyService: DestroyComponentService,
    private alertsService: AlertsService,
    public dialogRef: MatDialogRef<SaveGroupsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private users: CandidatesService,) {
  }

  ngOnInit(): void {
    this.userInfo = [];
    this.formControl();
    if (this.data.type === 'editar') {
      this.groupService.getUsersByGroup(this.data.group.id).subscribe((usuGroup) => {
        this.userGroup = usuGroup;
      });
    }
    this.campaignService.getCampaigns().subscribe((resp) => {
      this.campaigns = resp.data;
      this.setEdit();
          //select multiple
    this.campaignServerSideFilteringCtrl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searching = true),
      takeUntil(this._onDestroy),
      debounceTime(200),
      startWith(''),
      map(search => {
        if (!this.campaigns) {
          return [];
        }

        // simulate server fetching and filtering data
        return this.campaigns.filter(item => item.name.toLowerCase().indexOf(search) > -1);
      }),
      delay(500)
    )
      .subscribe(filteredCamapaings => {
        this.searching = false;
        this.filteredServerSideCampaigns.next(filteredCamapaings);
      }, error => {
        // no errors in our simulated example
        this.searching = false;
        // handle error...
      });
    });


    // camapañas seleccionadas
    this.CampaignServerSideCtrl.valueChanges.subscribe(resp => {
      const campaingsSelected = resp;
      const body = {
        campaignIds: [],
        group_id: 0
      };
      campaingsSelected.forEach(element => {
        body.campaignIds.push(element.id);
      });
      
      this.userServce.getUserByGroup(body).subscribe((usuGroup) => {
        this.userSinGroup = usuGroup.data;
      });
      if (this.data.type === 'editar') {
        this.groupService.getUsersByGroup(this.data.group.id).subscribe((usuGroup) => {
          this.userGroup = usuGroup;
        });
      } else if (this.data.type === 'crear') {
        this.userGroup = [];
      }
    });

    // Tipo documento
    this.users.getIdTypes().subscribe(resp => {
      this.documents = resp;
    });
    this.typeDocument.valueChanges.subscribe(resp => {
      this.idTypeDoc = resp;
      this.getUserByDocument();
    });

    // N° documento
    this.documentNumber.valueChanges.pipe(debounceTime(1000)).subscribe(resp => {
      this.document = resp;
      this.getUserByDocument();
    });

  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * filtra por nombre, se usa para el autocompletable
   * @param value valor a filtrar
   */
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.campaigns.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   */
  displayFn(id: number): string {
    if (!id) { return ''; }
    let index = this.campaigns.findIndex(resp => resp.id === id);
    return this.campaigns[index].name;

  }

  get error(): any { return this.groupForm.controls; }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-01-27
   * Metodo donde se establecen las validaciones del formulario
   */
  formControl(): void {

    this.groupForm = new FormGroup({
      group: new FormControl('', [Validators.required, Validators.maxLength(45), Validators.minLength(3)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(3)]),
      //campaigns: new FormControl('', [this.autocompleteObjectValidator(), Validators.required])
    });

  }


  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que valida las funciones del autocompetable
   */
  autocompleteObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string') {
        return { invalidAutocompleteObject: { value: control.value } };
      }
      return null;
    };
  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que setea los valores al momento de editar
   */
  setEdit(): void {
    if (this.data.type === 'editar') {
      this.groupForm.controls.group.setValue(this.data.group.name);
      this.groupForm.controls.description.setValue(this.data.group.description);
    }
  }

  lstInicial = new FormGroup({
    selected: new FormControl([])
  });

  lstfinal = new FormGroup({
    selected: new FormControl([])
  });

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que a partir de una variable enviada desde el administrador de roles crea un nuevo rol o edita un rol
   * @param groupForm datos que recolecta el formulario
   */
  saveGroup(groupForm): void {

    if (this.data.type === 'crear') {
      const group = {
        name: groupForm.value.group,
        description: groupForm.value.description,
        key: groupForm.value.description
      };

      this.groupService.saveGroup(group).subscribe((resp) => {
        this.userServce.saveUserWithGroup(this.userGroup, resp.id).subscribe();
        this.userServce.saveUserWithoutGroup(this.userSinGroup).subscribe();
        this.alertsService.alertSuccess('Guardado', resp.data);
        this.dialogRef.close('');
      });
    }
    else if (this.data.type === 'editar') {
      this.editGroup(groupForm);
    }

  }

  /**
   * Metodo para la inactivacion del grupo cuando al editar quiten los usuarios vinculados
   * @param group objeto del grupo
   * @author Andres Buitrago
   */
  inactiveGroup(): void {
    this.groupService.deleteGroup(this.data.group.id)
      .subscribe(resp => {
        this.alertsService.alertSuccess('Grupo Inactivo', 'Cambios realizados correctamente');
        this.dialogRef.close('');
      });
  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que edita un rol
   * @param groupForm datos que recolecta el formulario
   */
  editGroup(groupForm): void {
    const group = {
      name: groupForm.value.group,
      description: groupForm.value.description,
      key: groupForm.value.description
    };

    this.groupService.editGroup(this.data.group.id, group).subscribe((resp) => {
      this.alertsService.alertSuccess('Editado', resp.data);
    });
    this.userServce.saveUserWithGroup(this.userGroup, this.data.group.id).subscribe();
    this.userServce.saveUserWithoutGroup(this.userSinGroup).subscribe();
    this.dialogRef.close('');
  }

  asingarGrupo() {
    this.userSelected = this.lstInicial.value.selected;
    this.userSinGroupNuevo = this.userSinGroup;
    this.userSelected.forEach(select => {
      this.userGroup.push(select);
      this.userSinGroupNuevo = this.userSinGroupNuevo.filter((i) => i.id !== select.id)
    });
    this.lstInicial.value.selected = [];

    this.userSinGroup = this.userSinGroupNuevo;
    this.lstInicial.controls.selected.setValue([]);

  }

  quitarGrupo() {
    this.userSelected = this.lstfinal.value.selected;
    this.userSinGroupNuevo = this.userGroup;

    this.userSelected.forEach(select => {
      this.userSinGroup.push(select);
      this.userSinGroupNuevo = this.userSinGroupNuevo.filter((i) => i.id !== select.id)
    });
    this.lstfinal.value.selected = [];

    this.userGroup = this.userSinGroupNuevo;
    this.lstfinal.controls.selected.setValue([]);
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }
  /**
* @author Javier Castañeda
* @createdate 2022-02-11
* Metodo para capturar el documento a filtrar
*/
  onBlurMethod(e) {
    this.document = e;
    this.getUserByDocument();
  }
  /**
* @author Javier Castañeda
* @createdate 2022-02-11
* Metodo para obtener la información de un usuario por documento y tipo de documento
*/
  getUserByDocument() {
    this.userInfo = [];
    if( this.document != ''){
      this.userServce.getUserByDocument(this.document, this.idTypeDoc).subscribe(resp => {
        this.userInfo.push(resp.data);
      });
    }

  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-02-11
  * Metodo para agregar el usuario consultado al grupo
  */
  addUserToGroup() {
    if (this.userInfo[0]?.group == null) {      
      this.userGroup.push(this.userInfo[0]);
      this.userInfo = [];
      this.documentNumber.setValue('');
    } else if (this.userInfo[0]?.group !== null){      
      let str =
        '¿En verdad deseas cambiar al usuario de grupo?, ' +
        '\n' +
        'recuerda esto será definitivo y no se podrá deshacer.';
      Swal.fire({
        title: '¿Estás seguro?',
        html:
          '<pre style="font-family: Poppins; font-size: 14px;">' + str + '</pre>',
        icon: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#2CABBC',
        cancelButtonColor: '#FFFFFF',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.userGroup.push(this.userInfo[0]);
          this.userInfo = [];
          this.documentNumber.setValue('');
        }
      });
    }
  }

}
