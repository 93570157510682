import { AddReportFormComponent } from './../add-report-form/add-report-form.component';
import { UsersService } from 'src/app/modules/ciu/services/rest/users.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from './../../../../../core/services/rest/auth.service';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-user-assignment-report',
  templateUrl: './user-assignment-report.component.html',
  styleUrls: ['./user-assignment-report.component.sass'],
})
export class UserAssignmentReportComponent implements OnInit {

  // MatPaginator Inputs
  length;
  pageSize = 5;
  page = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  show = false;
  filterValue;
  public reactiveForm: FormGroup;
  public queryParams: string = 'page=1&per_page=5';
  public validInit: boolean = true;
  public panelOpenState = false;




  displayedColumns: string[] = ['accion', 'name', 'id_number', 'userName', 'email', 'campaign'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(public dialog: MatDialog,
    public userService: UsersService,
    private destroyService: DestroyComponentService,
    private location: Location,
    private authService: AuthService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.defineReactiveForm();
    this.getUsers();
  }

  get userLog() { return this.authService.decryptToken(); }

  /**
   * @author Carlos Nieto
   * @createdate 2022-03-04
   * Metodo que consulta todos los usuarios debidamente paginados para mostrarlos en la tabla
   * @param size tamaño de datos a traer
   * @param page pagina a traer
   */
  getUsers(): void {

    if (this.dataSource.filter === '') {
      this.dataSource.filter = null;
    }
    this.userService.getUsersFilter(this.queryParams).subscribe((Response) => {
      if (typeof Response.data.data !== 'undefined') {
        this.dataSource = new MatTableDataSource(Object.values(Response.data.data));
      } else {
        this.dataSource.data = [];
      }
      this.dataSource.sort = this.sort;
      this.length = Response.data.total;
      this.pageSize = Response.data.per_page;
    });

  }


  /**
   * @author Carlos Nieto
   * @createdate 2022-03-04
   * Metodo que abre el modal para editar permisos de un usuario
   * @param rrhh_id datos del usuario a editar
   */
  addPermisions(rrhh_id: any): void {

    const dialogRefEdit = this.dialog.open(AddReportFormComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Editar permisos de reportes',
        rrhh_id: rrhh_id
      },
      panelClass: 'custom-dialog-container',
    });

    dialogRefEdit.afterClosed().subscribe(() => {
      this.getUsers();
    });

  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-03-04
   * Metodo que se encarga del paginador al momento de cambiar de pagina
   * @param event evento que trae la pagina a listar
   */
  pageEvent(event: any): void {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
  }

  /**
* @author Carlos Nieto
* @createdate 2022-03-04
* Metodo para mostrar o ocultar filtros avanzados
*/
  Hide() {
    if (this.show == false) {
      this.show = true;
    } else {
      this.show = false;
    }
  }

  /**
     * Primero valida si existe en la variable el string filter,
     * en cuyo caso obtiene 'filter={info digitada}', limpiando los demas datos,
     * luego vuelve a evaluar si existe filter en cuyo caso lo concatena al string
     * en caso de que no exista arma el string solo con con page y pagezise
     * @author Carlos Nieto
     * @createdate 2022-03-04
     * @param event
     * @returns string
     /**/
  public ValidateFilterParams(event): string {
    this.queryParams = this.queryParams.includes("filter") ?
      this.queryParams.substring(this.queryParams.indexOf('filter')) : this.queryParams;
    this.queryParams = this.queryParams.includes("filter") ?
      `page=${event.pageIndex + 1}&per_page=${event.pageSize}&${this.queryParams}` :
      `page=${event.pageIndex + 1}&per_page=${event.pageSize}`;

    return this.queryParams;
  }

  public handleChangePage(event) {
    this.queryParams = this.ValidateFilterParams(event);
    this.validInit = false;
    this.destroyService.destroyComponent();
    this.getUsers();
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-03-04
   * Metodo que se encarga de validar los datos a enviar en onfilter
   * @param row dato a convertir
   */
  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.queryParams = `filter=${filterValue}`;
    this.destroyService.destroyComponent();
    this.getUsers();
    this.dataSource.filter = filterValue;
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-03-04
   * Metodo que se encarga de pasar todos los valores objetos a array
   * @param row dato a convertir
   */
  vaidateData(row) {
    return Object.values(row);
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-03-04
   * Metodo que se crea el control para el filtro
   * @param row dato a convertir
   */
  defineReactiveForm(): void {
    this.reactiveForm = this.formBuilder.group({
      'filter': ['']
    });
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

  backRoute() {
    this.location.back()
  }
}
