import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectionListChange } from '@angular/material/list';

@Component({
  selector: 'app-exchange-lists',
  templateUrl: './exchange-lists.component.html',
  styleUrls: ['./exchange-lists.component.css']
})
export class ExchangeListsComponent implements OnInit {
  selectedItemsFirstList: any[] = [];
  selectedItemsSecondList: any[] = [];
  checkAllFirstList: boolean = false;
  checkAllSecondList: boolean = false;
  @Input() taskDescription: string = '';
  @Input() titleFirstList: string = '';
  @Input() titleSecondList: string = '';
  @Input() textInputFirstList: string = '';
  @Input() textInputSecondList: string = '';
  @Input() firstList: any[] = [];
  @Input() secondList: any[] = [];
  @Output() runFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateList: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveConfig: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelProcess: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {

  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-26
    * Metodo que envia el valor del filtro de las listas.
    * @param $event Evento emitido por el input. 
    * @param name Filtro de la lista seleccionado.      
  */
  onChangeFilter($event: any, name: string): void {
    this.runFilter.emit({ value: $event.target.value, name });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-26
    * Metodo que envia items seleccionados al componente padre.
    * @param $event Evento emitido por el checkbox.
    * @param name Nombre de la lista donde se ubica el checkbox.     
 */
  selectAllItems($event: MatCheckboxChange, name: string): void {
    if (name === 'FIRST') {
      if ($event.checked) this.selectedItemsFirstList = this.firstList;
      else this.selectedItemsFirstList = [];
    } else {
      if ($event.checked) this.selectedItemsSecondList = this.secondList;
      else this.selectedItemsSecondList = [];
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-26
    * Metodo que envia items seleccionados al componente padre.
    * @param name Nombre de la accion a ejecutar.    
  */
  transferItemsBetweenList(name: string): void {
    if (name === 'FIRST') {
      this.checkAllFirstList = false;
      this.updateList.emit({ list: this.selectedItemsFirstList, name });
    } else {
      this.checkAllSecondList = false;
      this.updateList.emit({ list: this.selectedItemsSecondList, name });
    } 
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-26
    * Metodo que emite la configuracion final de las listas.     
  */
  onClickButtonSave(): void {
    this.saveConfig.emit({ firstList: this.firstList, secondList: this.secondList });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-26
    * Metodo que emite la cancelacion del proceso.    
  */
  onClickButtonCancel(): void {
    this.cancelProcess.emit();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-10-12
    * Metodo que emite el evento check en las respectivas listas.
    * @param $event Evento emitido por la lista. 
    * @param name Nombre de la accion a ejecutar. 
  */
  onSelectionList($event: MatSelectionListChange, name: string): void {
    if (name === 'FIRST') this.checkAllFirstList = $event.source._value.length === this.firstList.length ? true : false;
    else this.checkAllSecondList = $event.source._value.length === this.secondList.length ? true : false;
  }
}