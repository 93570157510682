<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<form [formGroup]="formProv">
    <div class="admin-dialog">
        <h2 mat-dialog-title>
            Orden de compra
        </h2>
        <hr>
        <div class="row">
            <div class="col-12">
                <mat-label style="font-weight: bold;">Empresa </mat-label>
                <p>{{data.solicitud.company.name}}</p>
            </div>
        </div>
        <div class="row">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label> Nit Proveedor</mat-label>
                <input matInput (keydown.enter)="consultaProveedor($event);input.blur()"
                    (blur)="consultaProveedor($event)" formControlName="nitProveedor" #input required>
                <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
                <mat-error>Campo requerido</mat-error>
            </mat-form-field>
            <mat-form-field class="col-12" appearance="fill">
                <mat-label>Proveedor</mat-label>
                <input
                    [formControl]="textProveedor"
                    type="text"
                    aria-label="Number"
                    matInput
                    [matAutocomplete]="auto"
                    (blur)="deleteProveedor($event)">
                <mat-autocomplete
                    autoActiveFirstOption
                    #auto="matAutocomplete"
                    [displayWith]="displayFn"
                    (optionSelected)="filterProveedores($event)">
                    <mat-option *ngFor="let option of filterListProveedores | async" [value]="option.razon_social">
                        {{ option.razon_social }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <p>Presiona Enter para consultar</p>
        <mat-dialog-actions align="center">
            <button class="bold compras-modal-button" mat-stroked-button mat-dialog-close>Cancelar</button>
            <button mat-raised-button type="button" (click)="crearOrdenCompra()" [disabled]="!generarOrden"
                color="primary" [mat-dialog-close]="true" class="bold compras-modal-button">Generar Orden</button>
        </mat-dialog-actions>
    </div>
</form>
