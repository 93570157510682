<div class="main-nuevo">
    <h3 class="title-acompañamiento">Nuevo acompañamiento</h3>
    <mat-divider class="mb-4"></mat-divider>
    <form class="row" [formGroup]="acompanamientoForm">
        <mat-form-field appearance="fill" class="col-12">
            <mat-label>Tipo de proceso</mat-label>
            <mat-select formControlName="tipo_proceso">
                <mat-option *ngFor="let proceso of procesos$ | async" [value]="proceso.id">{{proceso.nombre}}</mat-option>
            </mat-select>
            <mat-error>Por favor seleccione una opción</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6">
            <mat-label>Id auditoría</mat-label>
            <input type="number" matInput formControlName="idAuditoria">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6">
            <mat-label>Nombre</mat-label>
            <input type="text" matInput formControlName="nombre_coachee">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6">
            <mat-label>Cédula</mat-label>
            <input type="number" matInput formControlName="cedula_coachee">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6">
            <mat-label>Campaña</mat-label>
            <mat-select formControlName="campaing">
                <ng-container *ngIf="campaignJefe; else noJefe">
                    <mat-option *ngFor="let campaign of campaigns$ | async" [value]="campaign.id">{{campaign.name | uppercase }}</mat-option>
                </ng-container>
                <ng-template #noJefe>
                    <mat-option *ngFor="let campaign of campaigns$ | async" [value]="campaign.id">{{campaign.name | uppercase }}</mat-option>
                </ng-template>
            </mat-select>
            <mat-error>Por favor seleccione una opción</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6">
            <mat-label>Matriz</mat-label>
            <input type="text" matInput formControlName="matriz">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6">
            <mat-label>Indicador a trabajar</mat-label>
            <mat-select formControlName="indicador">
                <mat-option *ngFor="let indicador of indicadores$ | async" [value]="indicador.id">{{indicador.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6">
            <mat-label>Indicador inicial</mat-label>
            <input type="text" matInput formControlName="indicador_inicial" 
            [value]="acompanamientoForm.get('indicador_inicial').value | number:'1.0-3'">
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6">
            <mat-label>Cuartil inicial</mat-label>
            <mat-select formControlName="cuartil_inicial">
                <mat-option *ngFor="let cuartil of cuartiles$ | async" [value]="cuartil.id">{{cuartil.nombre}}</mat-option>
            </mat-select>
            <mat-error>Por favor seleccione una opción</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6">
            <mat-label>Próximo seguimiento</mat-label>
            <input matInput [matDatepicker]="seguimiento" [min]="startDate" [max]="endtDate" formControlName="proximo_seguimiento">
            <mat-datepicker-toggle matSuffix [for]="seguimiento">
              <mat-icon matDatepickerToggleIcon><i class="fi-rr-calendar"></i></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #seguimiento></mat-datepicker>
            <mat-error>Por ingresa una fecha de seguimiento.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6">
            <mat-label>Objetivo KPI</mat-label>
            <input type="number" matInput formControlName="objetivo_kpi">
            <mat-error>Por favor ingresa un valor de KPI.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12">
            <mat-label>Causa raíz</mat-label>
            <mat-select formControlName="causa_raiz">
                <mat-option *ngFor="let causa of causas$ | async" [value]="causa.id">{{causa.nombre}}</mat-option>
            </mat-select>
            <mat-error>Por favor seleccione una opción</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12"> 
            <mat-label>Conclusiones</mat-label>
            <textarea matInput formControlName="conclusion_sesion"></textarea>
            <mat-error>Por favor seleccione una opción</mat-error>
        </mat-form-field>
        <div class="col-12 text-center">
            <button mat-stroked-button class="btn-cancelar" (click)="cancel()">Cancelar</button>
            <button mat-raised-button color="primary" class="btn-guardar" (click)="save()">Guardar</button>
        </div>
    </form>
</div>