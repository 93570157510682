<h3 class="mt-3">ID Auditoría - {{ dataAuditoria ? dataAuditoria.id : ''}}</h3>
<mat-divider></mat-divider>
    <div class="mat-elevation-z0 mt-5 mb-4">
        <table  [dataSource]="dataSource" mat-table  matSort>
    
            <!-- Columna matriz -->
            <ng-container matColumnDef="matriz">
              <th mat-header-cell *matHeaderCellDef> Matriz </th>
              <td mat-cell *matCellDef="let element"> {{ element.nombre_matriz }}</td>
            </ng-container>
      
            <!-- Columna Fecha auditoría -->
            <ng-container matColumnDef="fecha_auditoria">
              <th mat-header-cell *matHeaderCellDef> Fecha de la gestión </th>
              <td mat-cell *matCellDef="let element"> {{ element.fecha_auditoria | date:'dd/MM/yyyy'}} </td>
            </ng-container>
      
            <!-- Columna Calificación -->
            <ng-container matColumnDef="calificacion">
              <th mat-header-cell *matHeaderCellDef> Calificación </th>
              <td mat-cell *matCellDef="let element"> {{ element.calificacion | number:'1.0-3'}} </td>
            </ng-container>
      
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No hay registros para mostrar</td>
            </tr>
          </table>
    </div>
    <div [formGroup]="calificacionesForm" class="mb-4">
        <div fxFlex="60%" style="overflow: auto; height: 350px;">
            <div formArrayName="modulos" *ngFor="let modulo of modulesForm.controls; let iModulo = index">
                <div [formGroupName]="iModulo">
                    <mat-expansion-panel togglePosition="before" [expanded]="iModulo == 0">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="sigla">{{modulo.get('detalle_item').value.sigla}}</p>
                                <p>{{modulo.get('detalle_item').value.nombre}}</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div formArrayName="items" *ngFor="let item of modulo.get('items').controls;let iItem= index">
                            <div [formGroupName]="iItem">
                                <mat-expansion-panel hideToggle>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title fxFlex="33%">
                                            <p class="sigla">{{item.get('detalle_item').value.sigla}}</p>
                                            <p [matTooltip]="item.get('detalle_item').value.nombre" 
                                            [matTooltipPosition]="'above'">{{item.get('detalle_item').value.nombre |cutText:0:9}}</p>
                                        </mat-panel-title>
                                        <mat-panel-description fxFlex="66%">
                                            <div *ngIf="tipo_matriz == 'Cumplimiento'">
                                                <mat-radio-group aria-label="Select an option" (change)="calificacionItem(item)" formControlName="calificacion">
                                                    <mat-radio-button [value]="item.get('cumple').value" (click)="$event.stopPropagation()">Cumple</mat-radio-button>
                                                    <mat-radio-button [value]="item.get('no_cumple').value" (click)="$event.stopPropagation()">No cumple</mat-radio-button>
                                                    <mat-radio-button [value]="item.get('no_aplica').value" (click)="$event.stopPropagation()">N/A</mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div *ngIf="tipo_peso == 'Peso en Items'">
                                                <mat-radio-group aria-label="Select an option" (change)="calificacionItem(item)" formControlName="calificacion">
                                                    <mat-radio-button [value]="item.get('si').value" (click)="$event.stopPropagation()">Si</mat-radio-button>
                                                    <mat-radio-button [value]="item.get('no').value" (click)="$event.stopPropagation()">No</mat-radio-button>
                                                    <mat-radio-button [value]="item.get('no_aplica').value" (click)="$event.stopPropagation()">N/A</mat-radio-button>
                                                </mat-radio-group>              
                                            </div>
                                            <div *ngIf="tipo_peso  == 'Peso en Módulos'">
                                                <mat-radio-group aria-label="Select an option" (change)="calificacionItem(item)" formControlName="calificacion">
                                                    <mat-radio-button [value]="item.get('si').value" (click)="$event.stopPropagation()">Si</mat-radio-button>
                                                    <mat-radio-button [value]="item.get('no').value" (click)="$event.stopPropagation()">No</mat-radio-button>
                                                    <mat-radio-button [value]="item.get('no_aplica').value" (click)="$event.stopPropagation()">N/A</mat-radio-button>
                                                </mat-radio-group>              
                                            </div>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div formArrayName="subitems" *ngFor="let subtItem of item.get('subitems').controls;let iSubItem = index">
                                        <div [formGroupName]="iSubItem">
                                            <mat-expansion-panel  hideToggle>
                                                <mat-expansion-panel-header class="sub-item">
                                                    <mat-panel-title fxFlex="46%">
                                                        <p class="sigla-sub ms-4">{{subtItem.get('detalle_item').value.sigla}}</p>
                                                        <button mat-icon-button [disabled]="!subtItem.get('detalle_item').value.descripcion">
                                                            <mat-icon style="font-size: 1.3em; margin-top: -.7em;"
                                                            [matTooltip]="subtItem.get('detalle_item').value.descripcion" [matTooltipPosition]="'above'"><i class="fi-rr-document"></i></mat-icon>
                                                        </button>
                                                        <p class="sub-item"
                                                        [matTooltip]="subtItem.get('detalle_item').value.nombre" 
                                                        [matTooltipPosition]="'above'">{{subtItem.get('detalle_item').value.nombre | cutText:0:7}}</p>
                                                    </mat-panel-title>
                                                    <mat-panel-description fxFlex="54%">
                                                        <mat-radio-group aria-label="Select an option" formControlName="calificacion" class="calificacion-sub">
                                                            <mat-radio-button [value]="subtItem.get('cumple').value" (click)="$event.stopPropagation()">Cumple</mat-radio-button>
                                                            <mat-radio-button [value]="subtItem.get('no_cumple').value" (click)="$event.stopPropagation()">No cumple</mat-radio-button>
                                                            <mat-radio-button [value]="subtItem.get('no_aplica').value" (click)="$event.stopPropagation()">N/A</mat-radio-button>
                                                        </mat-radio-group>
                                                    </mat-panel-description>
                                                </mat-expansion-panel-header>
                                            </mat-expansion-panel>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>
            </div>
        </div>
        <div fxFlex="40%" fxLayout="column" class="ms-3" mat-dialog-content style="overflow: auto; height: 350px;">
            <mat-form-field appearance="fill">
                <mat-label>Observación de la auditoría</mat-label>
                <textarea matInput formControlName="txtAuditoria"></textarea>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Retroalimentación Auditor</mat-label>
                <textarea matInput formControlName="retroalimentacion_auditor"></textarea>
                <mat-error>Por favor ingrese una retroalimentación.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Retroalimentación Supervisor</mat-label>
                <textarea matInput formControlName="retroalimentacion_supervisor"></textarea>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Compromiso calidad</mat-label>
                <textarea matInput formControlName="compromiso_calidad"></textarea>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Compromiso operación</mat-label>
                <textarea matInput formControlName="compromiso_operacion"></textarea>
            </mat-form-field>
        </div>
    </div>