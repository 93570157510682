import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment'

const NOMINA_URL = environment.NOMINA_URL;
const RRHH_URL = environment.RRHH_URL;

@Injectable({
    providedIn: 'root'
})
export class ImportDesprendibleService {

    constructor(private http: HttpClient) { }

    handleError(error: HttpErrorResponse): any {
        return throwError(error);
    }

    /**
     * Obtiene las novedades de incapacidad
     * @param filters
     * @param perPage
     * @param Page
     * @author David Reinoso
   */
    getPaymentSlipData(filters?: any, perPage: any = 5, Page?: any) {
        const httpOptions = {
            params: new HttpParams()
                .set('liquidations', filters.liquidations || '')
                .set('month', filters.month || '')
                .set('year', filters.year || '')
                .set('document', filters.document || '')
                .set('typeDoc', filters.typeDoc || '')
                .set('perPage', perPage)
                .set('page', Page || 1)
        };
        return this.http.get<any>(`${NOMINA_URL}paymentSlip`, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    /**
    * @author David Reinoso
    * @purpose Servicio para descargar la plantilla de desprendibles 
    */
    downloadTemplate(liquidations?: any) {
        const httpOptions = {
            params: new HttpParams()
                .set('liquidations', liquidations || ''),
            responseType: 'blob' as 'json'
        };
        return this.http.get<any>(`${NOMINA_URL}paymentSlip/downloadTemplate`, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    /**
    * @author David Reinoso
    * @purpose  Servicio para enviar archivo de desprendibles
    */
    uploadFile(data) {
        return this.http.post<any>(`${NOMINA_URL}paymentSlip/importData`, data)
            .pipe(
                catchError(this.handleError)
            );
    }
}
