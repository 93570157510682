import { Component, OnInit, Inject } from '@angular/core';
import { UsersService } from '../../../services/users.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { CandidatesService } from '../../../services/candidates.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-candidate-assign-trainer',
  templateUrl: './candidate-assign-trainer.component.html',
  styleUrls: ['./candidate-assign-trainer.component.sass']
})
export class CandidateAssignTrainerComponent implements OnInit {

  requestId;

  trainers = [];
  trainerSelected;

  candidates = [];
  candidatesSelected = [];

  constructor(private userService: UsersService,
              private candidateService: CandidatesService,
              private notification: AlertsService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialogRef<CandidateAssignTrainerComponent>) {
                this.requestId = data.requestId;
               }

  ngOnInit(): void {
    this.getTrainers();
  }

  getTrainers() {
    this.userService.getUserByRole('lider-formador').subscribe((resp: any) => {
      this.trainers = resp;
    });
  }

  getCandidates() {
    this.candidateService.candidateByState(this.requestId, {state: 'pruebas_aprobadas'}).subscribe( (resp: any) => {
      this.candidates = resp;
    });
  }

  selectTrainer(trainerId) {
    this.trainerSelected = trainerId;
    this.getCandidates();
  }

  pushArray(event) {
    this.candidatesSelected = event.selectedOptions.selected.map(item => item.value);
  }

  assign(trainerId) {
    this.notification.alertConfirm('¿Está seguro que desea asignar el/los candidatos al formador seleccionado?').then(e => {
      if (e.isConfirmed){
        const params = {
          userAssignedId: this.trainerSelected,
          userApplicantId: this.data.userApplicantId
        };
        this.candidateService.assignCandidateToLeaderTrainer(this.trainerSelected,
                              {candidateIds: this.candidatesSelected.toString(), stateToAssign: 'seleccion', requestId: this.requestId})
                              .subscribe( resp => {
          this.notification.alertSuccess('¡Hecho!', 'Candidatos Asignados con éxito');
          this.dialog.close(true);
        });
      }
    });
  }

}
