import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const NOMINA_URL = environment.NOMINA_URL;

@Injectable({
  providedIn: 'root'
})
export class DisabilitiesService {


  constructor(private http: HttpClient) {
  }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * Obtiene las novedades de incapacidad
   * @param filters
   * @param perPage
   * @param Page
   * @param exportData
   * @author Daniel Dominguez 
   */
  getDisabilitiesBy(filters: any, perPage: any = 5, Page?: any, exportData: boolean = false) {
    const httpOptions = {
      params: new HttpParams()
        .set('state', filters.state)
        .set('documentType', filters.idTipDoc || '')
        .set('document', filters.document || '')
        .set('campaign_id', filters.campaign || '')
        .set('novelty_date1', filters.novelty_date1 || '')
        .set('novelty_date2', filters.novelty_date2 || '')
        .set('perPage', perPage)
        .set('page', Page || 1)
        .set('export', exportData ? '1' : '0'),
      responseType: exportData ? 'blob' as 'json' : 'json'
    };
    return this.http.get<any>(`${ NOMINA_URL }disabilities/all`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Obtiene los permisos aprobados por juridica
   * @param filters Objeto de los posibles filtros usados
   * @param perPage Items por pagina
   * @param page pagina a visualizar
   * @author Andres Buitrago
   */
  getPermitsByFilters(filters: any, perPage: any = 5, page?: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('documentType', filters.idTipDoc || '')
        .set('document', filters.document || '')
        .set('campaign_id', filters.campaign || '')
        .set('novelty_date1', filters.novelty_date1 || '')
        .set('novelty_date2', filters.novelty_date2 || '')
        .set('perPage', perPage)
        .set('export', filters.export ? '1' : '0')
        .set('page', page || 1),
      responseType: filters.export ? 'blob' as 'json' : 'json'
    };
    return this.http.get<any>(`${ NOMINA_URL }permissions/getApprovedPermitsForNomina`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }


  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para aprobar o rechazar una novedad
   */
  changeStatus(id: any, body: any) {
    return this.http.put<any>(`${ NOMINA_URL }disabilities/managementdisabilities/${ id }`, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para consultar los subtipos de incapacidad
   */
  getSubTypes() {
    return this.http.get<any>(`${ NOMINA_URL }getSubtypes`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio obtiene la información de una novedad a gestionar
   */
  getEventInfoByPayrollNoveltyId(id: any) {
    return this.http.get<any>(`${ NOMINA_URL }getDisability/${ id }`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio obtiene la información de una novedad gestionada
   */
  getInfoByPayrollNoveltyId(id: any) {
    return this.http.get<any>(`${ NOMINA_URL }getDisabilityManaged/${ id }`)
      .pipe(
        catchError(this.handleError)
      );
  }
}
