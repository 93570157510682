import { Pipe } from '@angular/core';

@Pipe({
  name: 'filterTypes'
})
export class FilterTypesPipe {
  transform(arr: any, filterString: string) {
    
    if(arr.length === 0 || filterString == '' || filterString === undefined){
        return arr;
    }
    
    const data= [];
    
    arr.forEach(element => {
        if (element.value.name.toLowerCase().indexOf(filterString.toLowerCase()) > -1) {
            data.push(element);            
        }
    });
    return data;
  }
}