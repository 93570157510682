import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MedicaltestService } from '../../../services/medicaltest.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrainingGroupsService } from '../../../services/training-groups.service';
import { PositionsService } from '../../../services/positions.service';

@Component({
  selector: 'app-create-medical-test',
  templateUrl: './create-medical-test.component.html',
  styleUrls: ['./create-medical-test.component.sass']
})
export class CreateMedicalTestComponent implements OnInit {

  medicalForm:FormGroup
  ipsOptions:any = [];
  medicalTestsOptions:any = [];
  now = new Date().toISOString().split('.')[0];
  candidate:any;
  medicalTestSelected;
  positionsOptions:any=[]
  addres:boolean = false;


  constructor(private medicalService:MedicaltestService,
              private alertService:AlertsService,
              private fb:FormBuilder,@Inject(MAT_DIALOG_DATA) public data: any,
              private serviceGroups: TrainingGroupsService,
              public dialogRef: MatDialogRef<CreateMedicalTestComponent>,
              private positionsService: PositionsService,

              )  {
                this.candidate = this.data.candidate;
                this.medicalTestSelected = this.data.medicalTest;
                    this.medicalService.getIps(1).subscribe( (resp:any) => {
                      this.ipsOptions = resp;
                    } )

                    this.medicalService.getMedicalTestTypes().subscribe( (resp:any) => {
                      this.medicalTestsOptions = resp;
                    } )
              }

  ngOnInit(): void {
    this.createForm();
    if(this.medicalTestSelected){
      this.medicalForm.patchValue( this.medicalTestSelected );
    }

    this.positionsService.getPositionsCategory().subscribe(res=>{
      this.positionsOptions = res
    })
  }

  createForm(){
    this.medicalForm = this.fb.group({
      'date':['',Validators.required],
      'position_category_id':['',Validators.required],
      'medical_type_test_id':['',Validators.required],
      'ips_key':['',Validators.required],
      'addres':[''],
    })
  }



  saveTest(){
    if(this.medicalForm.valid){
      console.log(this.medicalForm.value);
        this.medicalService.createMedical(this.candidate.id,this.medicalForm.value).subscribe( e => {
          this.alertService.alertSuccess('¡Listo!','Se han agendado los exámenes médicos y se ha enviado una notificación');
          this.dialogRef.close(true);
        })
    }
  }

  showAddres(event){
    if (event == 'otro') {
      this.addres = true;
      this.medicalForm.controls["addres"].setValidators([Validators.required]);
    }else{
      this.addres = false;
      this.medicalForm.controls["addres"].setValidators([]);
    }
  }

}

