<div class="content-data">
    <button mat-button (click)="backClicked()" class="back-btn">
        <span class="fi-sr-angle-small-left"></span>
        <strong>Regresar &nbsp;</strong>
    </button>
    <div class="header">
        <h1>Hoja de Vida</h1>
        <p>En esta sección podrás vistualizar todo los correspondiente a tu hoja de vida</p>
    </div>
    <div class="content" *ngIf="candidate">
        
        <shared-cv-view [person]="candidate" (editEvent)="editPerson($event)"></shared-cv-view>
        <div fxLayoutAlign="end center" *ngIf="!isEdit">
            <button mat-button (click)="editData()">
                <strong>Editar condición médica &nbsp;</strong>
                <span class="fi-rr-edit"></span>
            </button>
        </div>
        <mat-accordion>
            <mat-expansion-panel [expanded]="isEdit">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h3>Condición Médica</h3>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="!isEdit" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="20px grid" class="health-medical">
                    <div fxFlex=16>
                        <strong>Tabaquismo</strong>
                        <p>{{healthForm.value.smoking}}</p>
                      </div>
                       <div fxFlex=16>
                        <strong>Alcohol</strong>
                        <p>{{healthForm.value.Alcohol}}</p>
                      </div>
                      <div fxFlex=16>
                        <strong>Sustancias Psicoactivas</strong>
                        <p>{{healthForm.value.psychoactive_substances}}</p>
                      </div>
                      <div fxFlex=16>
                        <strong>Ejercicio</strong>
                        <p>{{healthForm.value.training}}</p>
                      </div>
                      <div fxFlex=16>
                        <strong>Antecedentes de accidentes de trabajo</strong>
                        <p>{{healthForm.value.work_accident_history}}</p>
                      </div>
                    <div fxFlex=16>
                        <strong>Antecedentes de enfermedades laborales</strong>
                        <p>{{healthForm.value.occupational_disease_history}}</p>
                      </div> 
                      <div fxFlex=16>
                        <strong>Antecedentes de salud mental</strong>
                        <p>{{healthForm.value.mental_health_history}}</p>
                      </div> 
                      <div fxFlex=16>
                        <strong>Antecedentes patologicos personales</strong>
                        <p>{{healthForm.value.personal_pathological_history}}</p>
                      </div> 
                </div>
                    <form [formGroup]="healthForm" *ngIf="isEdit" fxLayout="column" fxLayoutAlign="center center" >
                        <div fxLayout="row wrap" fxLayoutGap="32px">
                            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
                                <mat-label>Tabaquismo</mat-label>
                                <mat-select matInput formControlName="smoking">
                                    <mat-option *ngFor="let opt of smokingOptions" [value]="opt">{{opt}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
                                <mat-label>Alcohol</mat-label>
                                <mat-select matInput formControlName="alcohol">
                                    <mat-option *ngFor="let row of alcoholOptions" [value]="row">{{row}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
                                <mat-label>Sustancias Psicoactivas</mat-label>
                                <mat-select matInput formControlName="psychoactive_substances">
                                    <mat-option *ngFor="let row of psychoactiveSubstancesOptions" [value]="row">{{row}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
                                <mat-label>Ejercicio</mat-label>
                                <mat-select matInput formControlName="training">
                                    <mat-option *ngFor="let row of trainingOptions" [value]="row">{{row}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
                                <mat-label>Antecedentes de accidentes de trabajo</mat-label>
                                <mat-select matInput formControlName="work_accident_history">
                                    <mat-option *ngFor="let row of historyOfAccidentsAtWorkOptions" [value]="row">{{row}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
                                <mat-label>Antecedentes de enfermedades laborales</mat-label>
                                <mat-select matInput formControlName="occupational_disease_history">
                                    <mat-option *ngFor="let row of backgroundOccupationalDiseasesOptions" [value]="row">{{row}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
                                <mat-label>Antecedentes de salud mental</mat-label>
                                <mat-select matInput formControlName="mental_health_history">
                                    <mat-option *ngFor="let row of backgroundMentalOptions" [value]="row">{{row}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill" *ngIf="healthForm.value.mental_health_history == 'Otro'">
                                <mat-label>¿Cuál?</mat-label>
                                <textarea matInput formControlName="mental_health_history"></textarea>
                            </mat-form-field>
                            <mat-form-field fxFlex="0 1 calc(66.6% - 32px)" appearance="fill">
                                <mat-label>Antecedentes patologicos personales</mat-label>
                                <textarea matInput formControlName="personal_pathological_history"></textarea>
                            </mat-form-field>
                        </div>
                        <div fxLayoutAlign="center center" class="action-btns">
                            <button mat-stroked-button>cancelar</button>
                            <button mat-flat-button color="primary" (click)="saveHealt()" [disabled]="healthForm.invalid">Actualizar</button>
                          </div>

                    </form>
           
            </mat-expansion-panel>

        </mat-accordion>






    </div>

</div>