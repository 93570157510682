import { Injectable } from '@angular/core';
import { MqttSoulService } from '../../mqtt/mqtt.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { IMqttMessage } from 'ngx-mqtt';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificacitonMqtt } from '../../mqtt/interfaces/mqtt.response.interface';
import { MessageChatInterface } from '../utils/interfaces.chat';

@Injectable({
  providedIn: null
})
export class ManagerChatService {

  //Envia los datos de confiruacion apra el chat actual enviado
  public $actualChat:Subject<any> = new Subject<any>();;
  //Variable apra capturar la mensajeria en tiempo real
  public $messagePublic:Subject<MessageChatInterface> = new Subject<MessageChatInterface>();
  //Variable que va indicando la lectur de los chats con los que se este comunicando
  public $readerMessages:Subject<any> = new Subject<any>()
  //Indica si la lista de contacto se deba dejar abierta o cerrada
  public $isOpenListContacs:Subject<boolean> = new Subject<boolean>()
  //Variable que va indicando se se publica un mensaje nuevo en un grupo 
  public $messageGroup:Subject<any> = new Subject<any>()
  //Subscripcion de canal de grupos
  private chatGroupSubsciption:Subscription;

  /**
   * Interfaz que se encarga de la obtencion del ultimo emnsaje enviado y transmitirlo
   * @author Juan David Guerrero Vargas
   * @param message:MessageChatInterface|null {MessageChatInterface|null} mensaje enviado
   */
  public interfaceGetMessage =  (message:MessageChatInterface|null)=>{
    if(message !== null ){
      this.newMesage(message);
    }
  }

  /**
   * Este pipe se encarga de transformar la respuesta de emision MQTT en el objeto JSON a utilizar en los diferente Chats
   */
  private  pipeMqttMessage = pipe(
    map( (message:IMqttMessage) => message.payload ? new TextDecoder('utf-8').decode(message.payload) : "" ),
    map( (msg:string) =>  msg ? JSON.parse(msg) as NotificacitonMqtt : null ),
    map( (msg:any) =>  msg.message !== "" ? JSON.parse(msg.message) as MessageChatInterface : null ) ,
  );  


  constructor(
    private authService: AuthService,
    private mqttSoulService:MqttSoulService
  ) { 

    this.mqttSoulService.getChannel("/chat/user/" + this.authService.decryptToken().rrhh_id)
    .pipe(this.pipeMqttMessage)  
    .subscribe(this.interfaceGetMessage);

    this.mqttSoulService.getChannel("/chat/group/0" )
    .pipe(this.pipeMqttMessage)  
    .subscribe(
      (groupMessage:any)=>{
        if(groupMessage !== null){
          this.newMesageInGroup(groupMessage);
        }
      }
    );

    this.mqttSoulService.getChannel("/chat/read/" + this.authService.decryptToken().rrhh_id)
    .pipe(
      map( (message:IMqttMessage) => message.payload ? new TextDecoder('utf-8').decode(message.payload) : "" ),
      map( (msg:string) =>  msg ? JSON.parse(msg) as NotificacitonMqtt : null ),
      map( (msg:any) =>  msg.message !== "" ? JSON.parse(msg.message)  : null ) ,
    )  
    .subscribe(
      (message:any)=>{
        if(message !== null ){
          this.$readerMessages.next(message);
        }
      }
    );

  }

  /**
   * Metodo para publicar nuevos mensajes 
   * @author Juan David Guerrero Vargas
   * @param message:MessageChatInterface {MessageChatInterface} Mensaje piblicado
   */
  private newMesage(message:MessageChatInterface){
    this.$messagePublic.next(message)
  }

  private newMesageInGroup(groupMessa:any){
    this.$messageGroup.next(groupMessa)
  }

  /**
   * Metodo que emite cual es el chat actual a utilizar
   * @author Juan David Guerrero Vargas
   * @param chat:any {any} Emite cual es el usuario o grupo con el que se esta comunicando actualmente
   */
  public setActualChat(chat){
    this.$actualChat.next(chat)
    if(chat !== null && chat.recipient_type == 2){
      if(this.chatGroupSubsciption != undefined){
        this.chatGroupSubsciption.unsubscribe();
      }
      this.chatGroupSubsciption = this.mqttSoulService.getChannel("/chat/group/" + chat.rrhh_id)
      .pipe( this.pipeMqttMessage )  
      .subscribe(this.interfaceGetMessage);
    }
  }

  /**
   * Metodo que se encarga de asignar el estado de a la lsita de conectato
   * @author Juan David Guerrero Vargas
   * @param stateListContact:boolean {boolean} estado de contacto
   */
  set openListContact(stateListContact:boolean){
    this.$isOpenListContacs.next(stateListContact);
  }
}
