import { NoveltiesCodesComponent } from './pages/novelties-codes/novelties-codes.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Fullcalendar
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin

import { NoveltiesRoutingModule } from './novelties-routing.module';
import { NoveltiesListComponent } from './pages/novelties-list/novelties-list.component';
import { MaterialModule } from '../../../../shared/material/material.module';
import { NoveltiesResponseComponent } from './pages/novelties-response/novelties-response.component';
import { CreateNoveltiesComponent } from './pages/create-novelties/create-novelties.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ResumNominaComponent } from './pages/resum-nomina/resum-nomina.component';
import { HttpClientModule } from '@angular/common/http';
import { MaterialFileInputModule } from 'ngx-material-file-input';


// register FullCalendar plugins
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [NoveltiesListComponent, NoveltiesResponseComponent, CreateNoveltiesComponent, NoveltiesCodesComponent, ResumNominaComponent],
  imports: [
    CommonModule,
    NoveltiesRoutingModule,
    MaterialModule,
    FullCalendarModule,
    TooltipModule,
    HttpClientModule,
    MaterialFileInputModule
  ]
})
export class NoveltiesModule { }
