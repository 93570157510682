import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-speech-menu',
  templateUrl: './speech-menu.component.html',
  styleUrls: ['./speech-menu.component.sass']
})
export class SpeechmenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
