import { animate, state, style, transition, trigger } from '@angular/animations';
import { I } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ArchivosService } from 'src/app/modules/compras/services/rest/archivos.service';
import { OrdenesService } from 'src/app/modules/compras/services/rest/ordenes.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import Swal from 'sweetalert2';
import { DescargaComponent } from '../historial-aprobador/descarga/descarga.component';
import { OrdenCompraFragComponent } from '../orden-compra-frag/orden-compra-frag.component';
import { OrdenCompraComponent } from '../orden-compra/orden-compra.component';
import { RemisionControlComponent } from '../remision-control/remision-control.component';
import { saveAs as importedSaveAs } from 'file-saver';

import { environment } from 'src/environments/environment';

const COMPRAS_URL = environment.COMPRAS_URL;

@Component({
  selector: 'app-orden-compra-list-control',
  templateUrl: './orden-compra-list-control.component.html',
  styleUrls: ['./orden-compra-list-control.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OrdenCompraListControlComponent implements OnInit {

  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  columnsToDisplay = ['Acciones', 'ID Orden','Fecha de creación', 'NIT', 'Razón Social','request_title', 'Empresa', 'Estado'];
  expandedElement;
  length;
  filterDate        : string = null ;
  pageSize          = 5;
  page              = 1;
  isPage            = 0;
  pageSizeOptions   : number[] = [5, 10, 25, 100];
  ordersStates      : any = [];
  filterState       : number[] = [];
  filterNit         : string  = null;
  editDisplay       : boolean;

  columns = this.columnsToDisplay;

  constructor( public ordenesService    : OrdenesService,
               private alertsService    : AlertsService,
               private archivoService   : ArchivosService,
               public datepipe          : DatePipe,
               public dialog            : MatDialog
               ) { }

  ngOnInit(): void {
    this.getOrders();
    this.getOrdersStates();
  }


  /**
   * Consulta las órdenes con los filtros correspondientes
   */
  getOrders() {
    this.ordenesService.getOrdersPaginate(
        this.isPage,
        this.pageSize,
        this.page ,
        this.filterNit,
        this.filterState,
        this.filterDate,
        null,
        null,
        true,
        null
         ).subscribe( resp => {
      this.dataSource.data = resp.data.data;
      this.dataSource.sort = this.sort;
      this.length = resp.data.total;
      this.pageSize = resp.data.per_page;
    });
  }
  /**
   * Consulta listado de ordenes de estado
   */
  getOrdersStates() {
    this.ordenesService.getRemissionStates().subscribe( resp => {
      this.ordersStates = resp;
    });
  }


  /**
   * Consulta ordenes aplicando filtro por fecha
   * @param event
   */
  filterBydate (event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterDate  = this.datepipe.transform(filterValue, 'yyyy-MM-dd');
    this.getOrders();
  }

  /**
   * Consulta ordenes aplicando filtro por Estados
   * @param event
   */

  filterByState(event) {
    this.filterState = event;
    this.getOrders();

  }

  /**
   * Consulta ordenes aplicando filtro por nit
   * @param event
   */
  filterByNIT(event) {
    this.filterNit = (event.target as HTMLInputElement).value;
    this.getOrders();
  }


  downloadManagement () {
    this.dialog.open( DescargaComponent , {
      width : '60%',
      data:{
        type:"orden"
      }
    } )


  }

  editOrder (order) {
    const estado ={
      state:5
    }


    //Confirma  cambio de estado de aproba a rechazada
    Swal.fire({
      icon                : 'warning',
      title               : '¿Estás seguro?',
      text                : "¿Deseas enviar la orden de compra?, recuerda que esto será definitivo y no se podrá deshacer, únicamente podrás fragmentar la orden de compra",
      showCancelButton    : true,
      focusConfirm        : false,
      confirmButtonText   : 'Aceptar',
      cancelButtonText    : "Cancelar"
    }).then( (result) => {
      if(result.isConfirmed){
        this.ordenesService.updateState(estado, order.id).subscribe((resp)=>{
          this.alertsService.alertSuccess('Orden Enviada',resp.data);
          this.ngOnInit();
        },
        error => {
          this.alertsService.alertWarning('Atención',error.error.error);
        }
        );
      }
    } )



  }

  editOrderFrag(order){
    const estado ={
      state:5
    }

    //Confirma  cambio de estado de aproba a rechazada
    Swal.fire({
      icon                : 'warning',
      title               : '¿Estás seguro?',
      text                : "¿Deseas enviar la orden de compra?, recuerda que esto será definitivo y no se podrá deshacer, únicamente podrás fragmentar la orden de compra",
      showCancelButton    : true,
      focusConfirm        : false,
      confirmButtonText   : 'Aceptar',
      cancelButtonText    : "Cancelar"
    }).then( (result) => {
      if(result.isConfirmed){
        this.ordenesService.updateStateFrag(estado, order.id).subscribe((resp)=>{
          this.alertsService.alertSuccess('Orden Enviada',resp.data);
          this.ngOnInit();
        });
      }
    } )



  }

  downloadOrder (order) {
    const archivo ={
      path:order.path
    };
    this.archivoService.downloadOrder(archivo).subscribe( resp=>{
      const a = document.createElement('a');
      const file = new Blob([resp], { type: 'application/pdf' });
      a.href = window.URL.createObjectURL(file);
      a.download = 'Orden_Compra.pdf';
      a.click();

    }, error =>{

    });
  }

  downloadRemission(purchaseOrderId:number) {
    this.ordenesService.downloadOrderRemission(purchaseOrderId).subscribe(resp=>{
      const file = new Blob([resp.body], {type: 'application/pdf'})
      importedSaveAs(file, 'RemisionOrdenCompra.pdf')
    }, error =>{
        console.log(error);
    });

  }

  downloadFragRemission(purchaseOrderId:number,FragId:number) {
    this.ordenesService.downloadOrderRemissionFrag(purchaseOrderId,FragId).subscribe(resp=>{
      const file = new Blob([resp.body], {type: 'application/pdf'})
      importedSaveAs(file, 'RemisionFragOrdenCompra.pdf')
      //window.open(window.URL.createObjectURL(file), '_blank');
    }, error =>{
        console.log(error);
    });

  }


  fragmentarOrder(order){
    const dialogNewRol = this.dialog.open(OrdenCompraFragComponent, {
      width: '90%',
      disableClose: true,
      data: {
        type: 'fragmentar',
        title: 'Fragmentar orden de compra',
        ordenCompra:order

      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {

      this.ngOnInit();
    });
  }

  viewOrderRemission(order, frag){
    const dialogNewRol = this.dialog.open(RemisionControlComponent, {
      width: '90%',
      disableClose: true,
      data: {
        type: 'view',
        title: 'Orden de compra',
        ordenCompra:order,
        solicitud:order,
        proveedor: order.provider,
        frag: frag

      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {

      this.ngOnInit();
    });

  }

  editOrderRemission(order, frag){

    //const dialogNewRol = this.dialog.open(OrdenCompraEditComponent, {
    const dialogNewRol = this.dialog.open(RemisionControlComponent , {
      disableClose: true,
      width: '90%',
      data: {
        type: 'edit',
        title: 'Orden de compra',
        ordenCompra:order,
        solicitud:order,
        proveedor: order.provider,
        frag: frag

      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {

      this.ngOnInit();
    });
  }

  pageEvent(event) {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getOrders();
  }




}
