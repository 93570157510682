import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MedicaltestService } from '../../../services/medicaltest.service';
import { MatSort } from '@angular/material/sort';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MedicalTestActionComponent } from '../medical-test-action/medical-test-action.component';

@Component({
  selector: 'app-medical-tests',
  templateUrl: './medical-tests.component.html',
  styleUrls: ['./medical-tests.component.sass']
})
export class MedicalTestsComponent implements OnInit {

  displayedColumns: string[] = ['actions','test', 'state','date','ips'];

  dataSource: MatTableDataSource<any>;
  candidate:any;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private medicalTestServices:MedicaltestService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialogRef<MedicalTestsComponent>,
              private dialogN:MatDialog) {
                this.candidate = this.data.candidate;
               }

  ngOnInit(): void {
    this.getMedicalTests();
  }

  getMedicalTests(){
    this.medicalTestServices.getMedicalTests(this.candidate.id).subscribe( (resp:any) => {
      this.dataSource = new MatTableDataSource(resp);
      this.dataSource.sort = this.sort;
    });
  }

  changeStatus(medical){
    const changeStatus = this.dialogN.open( MedicalTestActionComponent,{
      data:{
        candidate:this.candidate,
        medicalTest:medical
      }
    });
    changeStatus.afterClosed().subscribe( e => {
      if(e){
        this.getMedicalTests();
      }
     
    })
  }


}
