<div class="page">
    <nav *ngIf="toolbar" class="nav">
        <div class="row col-12">
            <div class="col-3">
                <mat-form-field appearance="fill" style="width:100%">
                    <mat-label>Filtrar por Campaña</mat-label>
                    <mat-select (selectionChange)="filterByCampaing($event.value)">
                        <mat-option value="" selected>Todas</mat-option>
                        <mat-option *ngFor="let row of allCampaigns" [value]="row"> {{ row.name }} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-3">
                <mat-form-field appearance="fill" style="width:100%">
                    <mat-label>Filtrar por Estado</mat-label>
                    <mat-select (selectionChange)="filterByState($event.value)">
                        <mat-option value="" selected>Todos</mat-option>
                        <mat-option *ngFor="let row of requestsStates" [value]="row.id">{{row.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-3"></div>
            <div class="col-3">
                <button mat-raised-button color="primary" (click)="createRequest()"> Crear solicitud </button>
            </div>
        </div>
    </nav>

    <div class="head col-12">
        <h1>Solicitudes</h1>
        <p>Aquí puedes revisar las solicitudes que haz hecho a las diferentes dependencias y su estado.</p>
    </div>
    <br>
    <div class="row col-12">
        <div class="col-3">
            <mat-form-field appearance="fill" style="width:100%">
                <mat-label>Filtrar por Campaña</mat-label>
                <mat-select (selectionChange)="filterByCampaing($event.value)">
                    <mat-option value="" selected>Todas</mat-option>
                    <mat-option *ngFor="let row of allCampaigns" [value]="row"> {{ row.name }} </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-3">
            <mat-form-field appearance="fill" style="width:100%">
                <mat-label>Filtrar por Estado</mat-label>
                <mat-select (selectionChange)="filterByState($event.value)">
                    <mat-option value="" selected>Todos</mat-option>
                    <mat-option *ngFor="let row of requestsStates" [value]="row.id">{{row.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-3"></div>
        <div class="col-3">
            <button mat-raised-button color="primary" (click)="createRequest()"> Crear solicitud </button>
        </div>
    </div>

     <div class="list-request">
            <p *ngIf="campaignRequestState">{{campaignRequestState}}</p>
            <div class="row" *ngFor="let item of campaigns; index as i; last as Last">
                <h3>{{item.campaign }}</h3>
                <div class="carousel mt-4" style="width: 95%;">
                    <p *ngIf="item.requestStatus">{{ item.requestStatus}}</p>
                    <div class="card" *ngFor="let request of item.data">
                       <div class="card-header" [ngClass]="request.class"></div>
                          <div class="card-body">
                            <div style="text-align: center;">
                                <circle-progress *ngIf="request.class=='green'" [percent]="(request.tracingAvg) | number " [radius]="60" [space]="-10" [outerStrokeGradient]="true" [outerStrokeWidth]="10" [outerStrokeColor]="'#B2EDAB'" [outerStrokeGradientStopColor]="'#B2EDAB'" [innerStrokeColor]="'#e7e8ea'"
                                [innerStrokeWidth]="10" [title]="request.tracingAvg | number:'1' " [animateTitle]="true" [animationDuration]="1000" [showUnits]="true" [showSubtitle]="false" [showBackground]="false" [clockwise]="true" [startFromZero]="false">
                                </circle-progress>
                                <circle-progress *ngIf="request.class=='gray'" [percent]="(request.tracingAvg) | number " [radius]="60" [space]="-10" [outerStrokeGradient]="true" [outerStrokeWidth]="10" [outerStrokeColor]="'#717171'" [outerStrokeGradientStopColor]="'#717171'" [innerStrokeColor]="'#e7e8ea'"
                                    [innerStrokeWidth]="10" [title]="request.tracingAvg | number:'1' " [animateTitle]="true" [animationDuration]="1000" [showUnits]="true" [showSubtitle]="false" [showBackground]="false" [clockwise]="true" [startFromZero]="false">
                                </circle-progress>
                                <circle-progress *ngIf="request.class=='red'" [percent]="(request.tracingAvg) | number " [radius]="60" [space]="-10" [outerStrokeGradient]="true" [outerStrokeWidth]="10" [outerStrokeColor]="'#CD5E5E'" [outerStrokeGradientStopColor]="'#CD5E5E'" [innerStrokeColor]="'#e7e8ea'"
                                    [innerStrokeWidth]="10" [title]="request.tracingAvg | number:'1' " [animateTitle]="true" [animationDuration]="1000" [showUnits]="true" [showSubtitle]="false" [showBackground]="false" [clockwise]="true" [startFromZero]="false">
                                </circle-progress>
                                <circle-progress *ngIf="request.class=='blue'" [percent]="(request.tracingAvg) | number " [radius]="60" [space]="-10" [outerStrokeGradient]="true" [outerStrokeWidth]="10" [outerStrokeColor]="'#2CABBC'" [outerStrokeGradientStopColor]="'#2CABBC'" [innerStrokeColor]="'#e7e8ea'"
                                    [innerStrokeWidth]="10" [title]="request.tracingAvg | number:'1' " [animateTitle]="true" [animationDuration]="1000" [showUnits]="true" [showSubtitle]="false" [showBackground]="false" [clockwise]="true" [startFromZero]="false">
                                </circle-progress>
                                <h4 matTooltip="Cargo Solicitado">{{request.position}}</h4>
                            </div>
                            <div fxLayout="column" >
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <strong>Consecutivo</strong>
                                    <p>{{request.consecutivo}}</p>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <strong >Cantidad de solicitudes</strong>
                                    <p>{{request.personalRequest}}</p>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <strong>Estado</strong>
                                    <p>{{request.state}}</p> 
                                </div>
                            </div>
                            
                        </div>
                        <div class="card-footer" style="text-align: center;">
                            {{request.applicant}}
                            <button color="primary" mat-icon-button matTooltip="Ver detalles" (click)="modalValidate(request)"><i class="fi fi-rr-arrow-right"></i></button>
                        </div> 
                    </div>
                </div>
                <div class="next float-right" style="width: 5%;" >
                    <button mat-mini-fab color="primary" matTooltip="Ver más solicitudes"
                    [disabled]="!item.nextPage" (click)="paginatorRequests(item,i)">
                        <span class="fi fi-rr-angle-right"></span>
                      </button>
                </div>
                <hr *ngIf="!Last">
            </div>
        <div fxLayout="row" fxLayoutAlign="center center"  *ngIf="nextPageUrlCampaign">
            <button mat-stroked-button color="primary" matTooltip="Ver más campañas" (click)="moreCampaigns()"> Ver más </button>
        </div>
    </div> 
</div>
