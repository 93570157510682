import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const NOMINA_URL = environment.NOMINA_URL;
const RRHH_URL = environment.RRHH_URL;

@Injectable({
  providedIn: 'root'
})
export class SaturdayVacationsService {

  constructor(private http: HttpClient) { }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * @author Johan Soler
   * @purpose Servicio para obtener los empleados con su sabado laboral
   */
  getSaturdayList(filters: any, perPage: any = 5, page: any = 1) {
    const httpOptions = {
      params: new HttpParams()
        .set('document', filters.document || '')
        .set('documentType', filters.documentType || '')
        .set('passiveHoliday', filters.passiveHoliday || '')
        .set('date1', filters.date1 || '')
        .set('date2', filters.date2 || '')
        .set('perPage', perPage)
        .set('page', page),
      responseType: filters.exportData ? 'blob' as 'json' : 'json'
    };
    return this.http.get<any>(`${RRHH_URL}users/all`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
 * @author Johan Soler
 * @purpose Servicio para obtener reporte de usuarios y sus sabados laborales
 */
  getReportSaturday(filters: any) {
    const httpOptions = {
      params: new HttpParams()
      .set('document', filters.document || '')
      .set('documentType', filters.documentType || '')
      .set('passiveHoliday', filters.passiveHoliday || '')
      .set('date1', filters.date1 || '')
      .set('date2', filters.date2 || ''),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${NOMINA_URL}vacations/reportSaturdayVacation`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
 * @author Johan Soler
 * @purpuse Servicio para aprovar o rechazar de forma masiva o individual los retardos
 */
  postChangeSaturday(body) {
    return this.http.post<any>(`${RRHH_URL}candidate/passiveHoliday`, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author David Reinoso
   * @purpuse Servicio para actualizar dias habiles de las novedades actuales
   */
  updateBusinessDaysNoveltiesCurrent() {
    return this.http.post<any>(`${NOMINA_URL}update/updatebusinessdaysnoveltiescurrent`,{})
      .pipe(
        catchError(this.handleError)
      );
  }
}
