import { CandidatesService } from './../../../../../rrhh/services/candidates.service';
import { DisabilitiesViewComponent } from './../disabilities-view/disabilities-view.component';
import { DisabilitiesResponseComponent } from './../disabilities-response/disabilities-response.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NoveltiesService } from 'src/app/modules/nomina/services/novelties.service';
import { CampaignsService } from 'src/app/modules/nomina/services/rest/campaigns.service';
import { DatePipe } from '@angular/common';
import { saveAs as importedSaveAs } from 'file-saver';
import { DisabilitiesService } from 'src/app/modules/nomina/services/rest/disabilities.service';
import { PermitViewComponent } from '../../../../../juridica/modules/permit/pages/permit-view/permit-view.component';

@Component({
  selector: 'app-disabilities-consultant',
  templateUrl: './disabilities-consultant.component.html',
  styleUrls: ['./disabilities-consultant.component.sass']
})
export class DisabilitiesConsultantComponent implements OnInit {
// MatPaginator Inputs
length;
pageSize = 5;
page = 1;
isPage = 1;
pageSizeOptions: number[] = [5, 10, 25, 100];

length3;
pageSize3 = 5;
page3 = 1;
isPage3 = 1;
pageSizeOptions3: number[] = [5, 10, 25, 100];

displayedColumns: string[] = ['acciones', 'fecha', 'nombre', 'numero', 'tipo', 'estado', 'campana'];
dataSource = new MatTableDataSource<any>();
dataSource3 = new MatTableDataSource<any>();

reportForm: FormGroup;
reportForm3: FormGroup;
Campaings: any;
filterControl = new FormControl();
filterControl3 = new FormControl();
stateType = new FormControl('');
stateType2 = new FormControl();
typeDocument = new FormControl();
typeDocument2 = new FormControl();
typeDocument3 = new FormControl();
states = [{ id: 1, name: 'En revisión' }, { id: 2, name: 'Aprobado' }, { id: 3, name: 'Rechazado' }];
idType = '27';
idState = '';
idType3 = '40';
idState3 = 4; // permisos aprobados
@ViewChild(MatSort, { static: true }) sort: MatSort;
@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
@ViewChild(MatPaginator, { static: true }) paginator3: MatPaginator;

document: any;
startDate: any = '';
endDate: any;
idCampaing: any;
idTypeDoc: any;
documents: any = [];

// permisos
document3: any;
startDate3: any = '';
endDate3: any;
idCampaing3: any;
idTypeDoc3: any;

constructor(public dialog: MatDialog, private service: CampaignsService, private disabilities: DisabilitiesService, private users: CandidatesService) {
}

ngOnInit() {
  this.reportForm = new FormGroup({
    start: new FormControl(''),
    end: new FormControl('')
  });
  this.reportForm3 = new FormGroup({
    start3: new FormControl(),
    end3: new FormControl()
  });
  // Estados
  this.stateType.valueChanges.subscribe(resp => {
    this.resetPaginator(2);
    this.idState = resp;
    console.log(resp);
    this.getNovelieties(this.idType, this.pageSize, this.page);
  });

  //documento
  this.users.getIdTypes().subscribe(resp => {
    this.documents = resp;
  });
  this.typeDocument.valueChanges.subscribe(resp => {
    this.idTypeDoc = resp;
    this.getNovelieties(this.idType, this.pageSize, this.page);
  });
  this.typeDocument.setValue(1);

  // tipo doc - tab permisos
  this.typeDocument3.setValue(1);
  this.typeDocument3.valueChanges.subscribe(resp => {
    this.idTypeDoc3 = resp;
    this.getPermits(this.pageSize3, this.page3);
  });


  //campaña
  this.filterControl.valueChanges.subscribe(resp => {
    this.resetPaginator(2);
    this.idCampaing = resp;
    this.getNovelieties(this.idType, this.pageSize, this.page);
  });

  // filtro de campañas para permisos
  this.filterControl3.valueChanges.subscribe(resp => {
    this.resetPaginator(3);
    this.idCampaing3 = resp;
    this.getPermits(this.pageSize3, this.page3);
  });
  this.service.getAllCampaigns().subscribe(resp => {
    this.Campaings = resp.data;
  });
  this.getPermits(this.pageSize3, this.page3);
}

/**
 * @author Daniel Dominguez 
 * @createdate 2021-05-10
 * Metodo de paginación
 */
pageEvent(event: any): void {
  this.pageSize = event.pageSize;
  this.page = event.pageIndex + 1;
  this.getNovelieties(this.idType, this.pageSize, this.page);
}

pageEvent3(event: any): void {
  this.pageSize3 = event.pageSize;
  this.page3 = Number(event.pageIndex + 1);
  this.getPermits(this.pageSize3, this.page3);
}


/**
 * @author Daniel Dominguez 
 * @createdate 2021-05-10
 * Modal de justificación
 */
viewJustification(element?): void {
  const dialogNewgroup = this.dialog.open(DisabilitiesResponseComponent, {
    width: '70%',
    disableClose: true,
    data: {
      type: 'crear',
      title: 'Juan Manuel Gonzales',
      data: element
    },
    panelClass: 'custom-dialog-container',
  });

  dialogNewgroup.afterClosed().subscribe(() => {
    this.getNovelieties(this.idType, this.pageSize, this.page);
  });

}

/**
 * @author Daniel Dominguez 
 * @createdate 2021-05-10
 * Modal de detalle de la incapacidad
 */
viewJustification2(element?): void {
  const dialogNewgroup = this.dialog.open(DisabilitiesViewComponent, {
    width: '70%',
    disableClose: true,
    data: {
      type: 'crear',
      title: 'Juan Manuel Gonzales',
      data: element
    },
    panelClass: 'custom-dialog-container',
  });

  dialogNewgroup.afterClosed().subscribe(() => {
    this.getNovelieties(this.idType, this.pageSize, this.page);
  });

}

/**
 * @author Daniel Dominguez 
 * @createdate 2021-05-10
 * Modal de detalle de permiso
 */
viewJustification3(element?): void {
  const dialogNewgroup = this.dialog.open(PermitViewComponent, {
    width: '60%',
    disableClose: true,
    data: {
      type: 'ver',
      title: 'Detalle permiso',
      data: element
    },
    panelClass: 'custom-dialog-container',
  });

  dialogNewgroup.afterClosed().subscribe(() => {
    this.getPermits(this.pageSize, this.page);
  });

}

/**
 * @author Daniel Dominguez 
 * @createdate 2021-05-10
 * Metodo para capturar el documento a filtrar
 */
onBlurMethod(e) {
  this.resetPaginator(2);
  this.document = e;
  this.getNovelieties(this.idType, this.pageSize, this.page);
}

/**
 * @author Daniel Dominguez 
 * @createdate 2021-05-12
 * Metodo para capturar el documento a filtrar
 */
onBlurMethod3(e) {
  this.resetPaginator(3);
  this.document3 = e;
  this.getPermits(this.pageSize3, this.page3);
}

/**
 * @author Daniel Dominguez 
 * @createdate 2021-05-10
 * Metodo para capturar el rango de fechas a filtrar
 */
dateRangeChange() {
  this.resetPaginator(2);
  const date1: Date = this.reportForm.controls.start.value;
  const date2: Date = this.reportForm.controls.end.value;
  const dateStart: string|null = new DatePipe('en-US').transform(date1, 'yyyy-MM-dd');
  const dateEnd: string|null = new DatePipe('en-US').transform(date2, 'yyyy-MM-dd');
  this.startDate = dateStart;
  this.endDate = dateEnd;
  this.getNovelieties(this.idType, this.pageSize, this.page);
}

/**
 * @author Daniel Dominguez 
 * @createdate 2021-05-12
 * Metodo para capturar el rango de fechas a filtrar
 */
dateRangeChange3(): void {
  this.resetPaginator(3);
  const date1: Date = this.reportForm3.controls.start3.value;
  const date2: Date = this.reportForm3.controls.end3.value;
  const dateStart: string|null = new DatePipe('en-US').transform(date1, 'yyyy-MM-dd');
  const dateEnd: string|null = new DatePipe('en-US').transform(date2, 'yyyy-MM-dd');
  this.startDate3 = dateStart;
  this.endDate3 = dateEnd;
  this.getPermits(this.pageSize3, this.page3);

}

/**
 * @author Daniel Dominguez 
 * @createdate 2021-05-10
 * Metodo para filtrar los pendientes
 */
getNovelieties(type, pageSi, page) {
  this.disabilities.getDisabilitiesBy({
    state: this.idState,
    idTipDoc: this.idTypeDoc,
    document: this.document,
    campaign: this.idCampaing,
    novelty_date1: this.startDate,
    novelty_date2: this.endDate
  }, pageSi, page).subscribe(resp => {
    this.dataSource = resp.data.data;
    console.log(resp.data.data);
    this.length = resp.data.total;
  });
}

/**
 * @author Daniel Dominguez 
 * @createdate 2021-07-02
 * Metodo para filtrar los permisos que fueron aprobados
 */
getPermits(pageSi3, page3, exportData: boolean = false) {
  this.disabilities.getPermitsByFilters({
    idTipDoc: this.idTypeDoc3,
    document: this.document3,
    campaign: this.idCampaing3,
    novelty_date1: this.startDate3,
    novelty_date2: this.endDate3,
    'export': exportData
  }, pageSi3, page3).subscribe(resp => {
    if (exportData) {
      importedSaveAs(resp, 'reporte_permisos_aprobados.xlsx');
    } else {
      this.dataSource3 = resp.data.data;
      this.length3 = resp.data.total;
    }
  });
}


/**
 * @author Daniel Dominguez 
 * @createdate 2021-05-10
 * Metodo de descargar reporte de incapacidades
 */
downloadReport() {
  this.disabilities.getDisabilitiesBy({
    state: this.idState,
    idTipDoc: this.idTypeDoc,
    document: this.document,
    campaign: this.idCampaing,
    novelty_date1: this.startDate,
    novelty_date2: this.endDate
  }, '', '', true).subscribe(resp => {
    importedSaveAs(resp, 'reporte_incapacidades.xlsx');
  });
}

/**
 * @author Daniel Dominguez  <javier.c@montechelo.com.co>
 * @purpose resetea el paginador
 */
resetPaginator(numPaginator): void {
  if (numPaginator === 2) {
    this.length = 0;
    this.pageSize = 5;
    this.page = 1;
    this.paginator.firstPage();
  } else if (numPaginator === 3) {
    this.length3 = 0;
    this.pageSize3 = 5;
    this.page3 = 1;
    this.paginator3.firstPage();
  }
}

}