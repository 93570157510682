import { Component, OnInit } from '@angular/core';
import { MailService } from '../../../services/rest/mail.service';
import { ViewMailComponent } from '../view-mail/view-mail.component';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ManageEmailComponent } from '../manage-email/manage-email.component';
import { NewEmailComponent } from '../new-email/new-email.component';


@Component({
  selector: 'app-admin-email',
  templateUrl: './admin-email.component.html',
  styleUrls: ['./admin-email.component.sass']
})
export class AdminEmailComponent implements OnInit {

  // MatPaginator Inputs
  lengthC;
  lengthH;
  lengthM;
  lengthE;
  lengthB;
  pageSizeM = 5;
  pageM = 1;
  pageSizeC = 5;
  pageC = 1;
  pageSizeH = 5;
  pageH = 1;
  pageSizeE = 5;
  pageE = 1;
  pageSizeB = 5;
  pageB = 1;

  pageSizeOptions: number[] = [5, 10, 25, 100];

  mailActivos: any;
  cantidadActivos: number;
  mailCola: any;
  cantidadCola: number;
  mailHistorico: any;
  cantidadHistorico: number;
  mailEnviados: any;
  cantidadEnviados: number;
  mailBuscados: any;
  cantidadBuscados: number;

  filterForm: FormGroup;

  public advancedFilters: boolean = true;
  message: string;

  constructor(public mailService: MailService,
              public dialog: MatDialog,
              private form: FormBuilder,
              public datePipe: DatePipe,
              private alertsService: AlertsService) { }

  ngOnInit(): void {

    this.getMailCola(this.pageSizeC, this.pageC);
    this.getMailHistoricos(this.pageSizeH, this.pageH);
    this.getMailMios(this.pageSizeM, this.pageM);
    this.getEnviados(this.pageSizeE, this.pageE);
    this.getBusqueda(this.pageSizeB, this.pageB, null);
    this.showButtonMessage();
    this.formControl();

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que inicia los controles de los formularios
   */
  formControl(): void{

    this.filterForm = this.form.group({
      de: new FormControl('', [Validators.email]),
      para: new FormControl('', [Validators.email]),
      cuerpo: new FormControl('', []),
      asunto: new FormControl('', []),
      fechaDesde: new FormControl('', []),
      fechaHasta: new FormControl('', []),
    });

  }

  get errorFilter(): any { return this.filterForm.controls; }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que trae los datos para la grilla de correos
   * @param size
   * @param page
   */
  getMailMios(size: number, page: number): void{
    this.mailService.getActivos(size, page).subscribe((resp) => {
      if(resp){
        this.mailActivos = resp.data;
        this.cantidadActivos = resp.total;
        this.lengthM = resp.total;
        this.pageSizeM = resp.per_page;
      }
    });
  }

  getMailCola(size: number, page: number): void{
    this.mailService.getCola(size, page).subscribe((resp) => {
      if(resp){
        this.mailCola = resp.data;
        this.cantidadCola = resp.total;
        this.lengthC = resp.total;
        this.pageSizeC = resp.per_page;
      }
    });
  }

  getMailHistoricos(size: number, page: number): void{

    this.mailService.getHistoricos(size, page).subscribe((resp) => {
      if(resp){
        this.mailHistorico = resp.data;
        this.cantidadHistorico = resp.total;
        this.lengthH = resp.total;
        this.pageSizeH = resp.per_page;
      }
    });

  }

  getEnviados(size: number, page: number): void{

    this.mailService.getEnviados(size, page).subscribe((resp) => {
      this.mailEnviados = resp.data;
      this.cantidadEnviados = resp.total;
      this.lengthE = resp.total;
      this.pageSizeE = resp.per_page;
    });

  }

  getBusqueda(size: number, page: number, form: FormData): void{

    this.mailService.Busqueda(size, page, form).subscribe((resp) => {
      this.mailBuscados = resp.data;
      this.cantidadBuscados = resp.total;
      this.lengthB = resp.total;
      this.pageSizeB = resp.per_page;
    });

  }

  filter(mailfilter): void{

    const filter = new FormData();

    filter.append( 'de', mailfilter.value.de ? mailfilter.value.de : '');
    filter.append( 'para', mailfilter.value.para ? mailfilter.value.para : '');
    filter.append( 'asunto', mailfilter.value.asunto ? mailfilter.value.asunto : '' );
    filter.append( 'cuerpo', mailfilter.value.cuerpo ? mailfilter.value.cuerpo : '' );
    filter.append( 'fechaDesde', this.datePipe.transform(mailfilter.value.fechaDesde, 'yyyy-MM-dd', 'es-ES') ? this.datePipe.transform(mailfilter.value.fechaDesde, 'yyyy-MM-dd', 'es-ES') : '');
    filter.append( 'fechaHasta', this.datePipe.transform(mailfilter.value.fechaHasta, 'yyyy-MM-dd', 'es-ES') ? this.datePipe.transform(mailfilter.value.fechaHasta, 'yyyy-MM-dd', 'es-ES') : '');

    this.mailService.Busqueda(this.pageSizeB, this.pageB, filter).subscribe((resp) => {
      this.mailBuscados = resp.data;
      this.cantidadBuscados = resp.total;
      this.lengthB = resp.total;
      this.pageSizeB = resp.per_page;
    });

  }

  showButtonMessage(): void {
    this.advancedFilters = !this.advancedFilters;
    if (!this.advancedFilters) {
      this.getBusqueda(this.pageSizeB, this.pageB, null);
      this.message = 'Activar Filtros';
    } else {
      this.message = 'Desactivar Filtros';
    }
  }


  dateDiff(correo: any, prioridad, email?: any): boolean {

    let currentDate: any = new Date();
    let diferencia = 0;

    if (correo === undefined) {
      let fechaCreacion: any = new Date(email.fechaHora);
      diferencia = Math.round(Math.abs(currentDate - fechaCreacion) / 36e5);
    } else {
        let fechaCreacion: any = new Date(correo.created_at);
        if (correo.fechaGestion === null){
          diferencia = Math.round(Math.abs(currentDate - fechaCreacion) / 36e5);
        } else {
          let fechaModificacion: any = new Date(correo.updated_at);
          diferencia = Math.round(Math.abs(fechaModificacion - fechaCreacion) / 36e5);
        }
    }

    if (diferencia <= 2 && prioridad === 1) {
      return true;
    } else if ( diferencia > 2 && diferencia <= 4 && prioridad === 2) {
      return true;
    } else if ( diferencia > 4 && prioridad === 3){
      return true;
    } else  {
      return false;
    }
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * Metodo que se encarga del paginador al momento de cambiar de pagina
   * @param event evento que trae la pagina a listar
   */
  pageEventC(event: any): void {

    this.pageSizeC = event.pageSize;
    this.pageC = event.pageIndex + 1;
    this.getMailCola(this.pageSizeC, this.pageC);

  }

  pageEventH(event: any): void {

    this.pageSizeH = event.pageSize;
    this.pageH = event.pageIndex + 1;
    this.getMailHistoricos(this.pageSizeH, this.pageH);

  }

  pageEventM(event: any): void {

    this.pageSizeM = event.pageSize;
    this.pageM = event.pageIndex + 1;
    this.getMailMios(this.pageSizeM, this.pageM);

  }

  pageEventE(event: any): void {

    this.pageSizeE = event.pageSize;
    this.pageE = event.pageIndex + 1;
    this.getEnviados(this.pageSizeE, this.pageE);

  }

  pageEventB(event: any): void {

    this.pageSizeB = event.pageSize;
    this.pageB = event.pageIndex + 1;
    this.getBusqueda(this.pageSizeB, this.pageB, null);

  }

  viewMail(data: any, menu): void{
    const dialogViewMail = this.dialog.open(ViewMailComponent, {
      width: '100%',
      disableClose: true,
      maxHeight: '85vh',
      data: {
        type: 'viewMail',
        title: 'Visualizar Correo',
        data,
        menu
      },
      panelClass: 'custom-dialog-container',
    });

    dialogViewMail.afterClosed().subscribe(() => {

    });

  }

  manageMail(data: any): void{

    const dialogManageMail = this.dialog.open(ManageEmailComponent, {
      width: '100%',
      disableClose: true,
      maxHeight: '85vh',
      data: {
        type: 'manageMail',
        title: 'Gestion Correo',
        data
      },
      panelClass: 'custom-dialog-container',
    });

    dialogManageMail.afterClosed().subscribe(() => {
      this.ngOnInit();
    });

  }

  nuevoCorreo(): void {
    const dialogNewMail = this.dialog.open(NewEmailComponent, {
      width: '100%',
      disableClose: true,
      maxHeight: '85vh',
      data: {
        type: 'newMail',
        title: 'Gestion Correo',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewMail.afterClosed().subscribe(() => {
      this.ngOnInit();
    });

  }

  actualizar(): void {

    const actualizar = new FormData();

    this.mailService.Actualizar(actualizar).subscribe((resp) => {
      if ( resp.id === 200){
        this.ngOnInit();
      }
    });
  }

  asignacion(idMail: any): void {

    const asigna = new FormData();

    asigna.append( 'emailId', idMail);

    const value = this.alertsService.alertConfirm('¿Desea asignarse este email?');
    value.then((result) => {
      if (result.isConfirmed) {
        this.mailService.Asignar(asigna).subscribe((resp) => {
          this.ngOnInit();
        });
      }
    });

  }

  devolverBandeja(idMail: any): void {

    const asigna = new FormData();

    asigna.append( 'emailId', idMail);

    const value = this.alertsService.alertConfirm('¿Desea devolver este email a la bandeja?');
    value.then((result) => {
      if (result.isConfirmed) {
        this.mailService.devolverBandeja(asigna).subscribe((resp) => {
          this.ngOnInit();
        });
      }
    });

  }

  descartar(idMail: any): void {

    const asigna = new FormData();

    asigna.append( 'emailId', idMail);

    const value = this.alertsService.alertConfirm('¿Desea sacar este email de la cola?');
    value.then((result) => {
      if (result.isConfirmed) {
        this.mailService.VolverHistorico(asigna).subscribe((resp) => {
          this.ngOnInit();
        });
      }
    });

  }


  devolver(idMail: any): void {

    const asigna = new FormData();

    asigna.append( 'emailId', idMail);

    const value = this.alertsService.alertConfirm('¿Desea incluir este email de la cola?');
    value.then((result) => {
      if (result.isConfirmed) {
        this.mailService.VolverCola(asigna).subscribe((resp) => {
          this.ngOnInit();
        });
      }
    });

  }

}