<div fxLayout="column" style="padding-top: 50px; padding-right: 35px">
    <div style="padding-left: 64px;">
        <button mat-icon-button (click)="backRoute()">
        <div class="display:flex">
            <i class="fi-rr-angle-small-left" style="font-size:24px;"></i>
            <span style="vertical-align: super;font: normal normal 600 16px/25px Poppins;letter-spacing: 0px;color: #353535;opacity: 1;">Regresar</span>
        </div>
     </button>
        <br>
        <br>
        <h1><b>Plantillas</b><i class="fas fa-container-storage"></i></h1>
        <p>En este espacio podras personalizar <br> las plantillas a tu gusto</p>
        <br>
        <br>
        
        <div class="row col-12 filter">
    
        <mat-form-field class="col-3" appearance="fill">
            <mat-label>Filtro de búsqueda</mat-label>
            <input matInput #input>
            <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
        </mat-form-field>

        <div class="col-6"></div>

        <div class="col-3">
            <button mat-raised-button color="primary" (click)="newTem()">Crear plantilla<span class="fi-sr-add" style="padding-left: 14px;"></span> </button>
        </div>

        <div class="table-container">
            <div class="mat-elevation-z1">
                <table mat-table [dataSource]="dataSourceTemplate" matSort>

                    <ng-container matColumnDef="accion">
                        <th class="start" mat-header-cell *matHeaderCellDef mat-sort-header> Acciones</th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button (click)="openTemplate(row)">
                              <i class="fi-rr-edit"></i>
                          </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="estado">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                        <td mat-cell *matCellDef="let row"> {{row.state}} </td>
                    </ng-container>

                    <ng-container matColumnDef="fechaCreacion">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de creación </th>
                        <td mat-cell *matCellDef="let row"> {{row.created_at | date: 'dd/MM/yyyy'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="nombrePlantilla">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre de la plantilla </th>
                        <td mat-cell *matCellDef="let row"> {{row.template_name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="camposPlantilla">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Resumen de la plantilla </th>
                        <td mat-cell *matCellDef="let row"> <span *ngFor="let item of row.inputNames"><span>{{item}}, </span></span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsTemplate"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsTemplate;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro </td>
                    </tr>
                </table>

                <mat-paginator [length]="lengthTemplate" [pageSize]="pageSizeTemplate" (page)="pageEventTemplate($event)" [pageSizeOptions]="pageSizeOptionsTemplate">
                </mat-paginator>
            </div>
        </div>

</div>
