import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatrizService } from 'src/app/modules/calidad/services/matriz.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import Swal from 'sweetalert2';
import { AnalistasCalidadComponent } from '../analistas-calidad/analistas-calidad.component';
import { CrearItemComponent } from '../crear-item/crear-item.component';
import { CrearModuloComponent } from '../crear-modulo/crear-modulo.component';
import { CrearSubItemComponent } from '../crear-sub-item/crear-sub-item.component';
import { EditarItemComponent } from '../editar-item/editar-item.component';
import { EditarModuloComponent } from '../editar-modulo/editar-modulo.component';
import { EditarSubItemComponent } from '../editar-sub-item/editar-sub-item.component';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
  selector: 'app-detalle-matriz',
  templateUrl: './detalle-matriz.component.html',
  styleUrls: ['./detalle-matriz.component.sass']
})
export class DetalleMatrizComponent implements OnInit, OnDestroy {
  private uuid = '1bc11cea-90be-11ee-b9d1-0242ac120002';
  public config: any;

  filtroModulo:string = '';
  checked:boolean = true;
  title:string = '';
  tipo:string = '';
  creacion:string = '';
  minimo = 0;
  maximo = 0;
  peso_actual = 0;
  id_matriz;
  tipo_peso;
  openM:number=0;
  openItem:number=0
  modulos_matriz = [];
  items_modulo = [];
  subItems_item = [];
  subscription:Subscription;
  aplica_para_cp: number = null;

  constructor(
    private dialog:MatDialog,
    private aRoute:ActivatedRoute,
    private matrizService:MatrizService,
    private configService: TranslatesService,
  ) {
    this.aRoute.paramMap.subscribe(params=>{
     this.id_matriz = params.get('id');
    });
   }

  ngOnInit(): void {
    this.subscription = this.matrizService.refresh$.subscribe(()=>{
      this.getMatrixById(this.config);
    });
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
      this.getMatrixById(this.config);
    });
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  newModule(){
    const moduleRef = this.dialog.open(CrearModuloComponent,{
      width: '624px',
      disableClose: true,
      data: {
        id_matriz: this.id_matriz,
        tipo_peso:this.tipo_peso,
        tipo_matriz:this.tipo,
        minimo:this.minimo,
        maximo:this.maximo
      }
    })
  }

  newItem(id_modulo,sigla,error_critico){
    const itemRef = this.dialog.open(CrearItemComponent,{
      width: '624px',
      disableClose: true,
      data: {
        id_matriz: this.id_matriz,
        id_modulo: id_modulo,
        tipo_peso: this.tipo_peso,
        sigla: sigla,
        error_critico: error_critico,
        tipo_matriz:this.tipo,
        aplica_para_cp: this.aplica_para_cp
      },
    })
  }


  newSubItem(id_item,sigla,error_critico){
    const subItemRef = this.dialog.open(CrearSubItemComponent,{
      width: '624px',
      disableClose: true,
      data: {
        id_matriz: this.id_matriz,
        id_item: id_item,
        tipo_peso: this.tipo_peso,
        sigla: sigla,
        error_critico: error_critico,
      },
    })
  }

  disableModule(id,estado,nombre){
    let mensaje:string = '';
    if (estado) {
      mensaje = this.config.modal.disable;
    }else{
      mensaje = this.config.modal.enable;
    }
    Swal.fire({
      title:mensaje,
      icon: 'warning',
      showCancelButton:true,
      reverseButtons: true,
      cancelButtonText: this.config.modal.cancel,
      confirmButtonText: this.config.modal.accept,
      width: '836px',
      padding: '2em 7em'
    }).then(result=>{
      if (result.isConfirmed) {
        const data = {
          id: id,
          estado: !estado,
          pesoMaxMatriz: this.maximo,
          pesoActual: this.peso_actual,
          idMatriz: this.id_matriz
        }
        this.matrizService.disableModule(data).subscribe((resp:any)=>{
          if (resp) {
            if (resp.respuesta.codigo != 202) {
              Tools.swalError('¡Ups!',resp.respuesta.text)
            }
          }
        });
      }
    })
  }

  getMatrixById(config){
    const lang = this.configService.lang;
    this.matrizService.getMatrixById(this.id_matriz).subscribe((resp:any)=>{
      this.title = resp[0].matriz.nombre;
      this.tipo = lang === 'es' ? resp[0].matriz.tipo_matriz.nombre : resp[0].matriz.tipo_matriz.en;
      this.creacion = resp[0].matriz.created_at;
      this.minimo = resp[0].matriz.valor_peso_validacion.minimo;
      this.maximo = resp[0].matriz.valor_peso_validacion.maximo;
      this.aplica_para_cp = resp[0].matriz.aplica_para_cp;
      if (resp[0].matriz.tipo_peso != null) {
        this.tipo_peso = lang === 'es' ?  resp[0].matriz.tipo_peso.nombre : resp[0].matriz.tipo_peso.en;
      }
      this.modulos_matriz = [];
      resp[0].matriz.modulo.forEach(modulo=>{
        if (modulo.nivel == 1) {
          modulo.items_modulo?.forEach(items => {
            if(!items.descripcion  && !items.aspectoPositivo  && !items.aspectoNegativo  && !items.aspectoNa && !items.aspectocp){
              items.aspectosToltip = '';
            }else{
              items.aspectosToltip = config.labels.modal.decription + (items.descripcion || config.labels.modal.sinDefinir) +
              "\n" + config.labels.modal.positiveAspects + (items.aspectoPositivo || config.labels.modal.sinDefinir) +
              "\n" + config.labels.modal.negativeAspects  + (items.aspectoNegativo  || config.labels.modal.sinDefinir) +
              "\n" + config.labels.modal.naAspects + (items.aspectoNa || config.labels.modal.sinDefinir) +
              "\n" + (this.aplica_para_cp === 1 && items.aspectocp ? `${config.labels.modal.cpAspects} ${items.aspectocp}` : '')
            }
            items.sub_items?.forEach(subitem => {
              if(!subitem.descripcion && !subitem.aspectoPositivo && !subitem.aspectoNegativo && !subitem.aspectoNa){
                subitem.aspectosToltip = '';
              }else{
                subitem.aspectosToltip = config.labels.modal.decription  + (subitem.descripcion || config.labels.modal.sinDefinir) +
                "\n" + config.labels.modal.positiveAspects + (subitem.aspectoPositivo || config.labels.modal.sinDefinir) +
                "\n" + config.labels.modal.negativeAspects + (subitem.aspectoNegativo  || config.labels.modal.sinDefinir) +
                "\n" + config.labels.modal.naAspects + (subitem.aspectoNa || config.labels.modal.sinDefinir)
              }
            });

          });

          this.modulos_matriz.push(modulo);
        }
      });
      this.peso_actual = resp[0].matriz.peso_actual;
    });
  }

  filtrar(){
    const params = {
      modulo: '',
      idMatriz: this.id_matriz
    }

    if (this.filtroModulo != '') {
      params.modulo = this.filtroModulo
    }
    this.matrizService.getModulesMatrix(params).subscribe((resp:any)=>{
      this.modulos_matriz = resp;
    });
  }

  editModule(id){
    const moduleRef = this.dialog.open(EditarModuloComponent,{
      width: '624px',
      disableClose: true,
      data: {
        id_module: id,
        tipo_peso:this.tipo_peso,
        tipo_matriz:this.tipo,
        minimo:this.minimo,
        maximo:this.maximo
      }
    })
  }

  editItem(id,error_critico){
    const itemRef = this.dialog.open(EditarItemComponent,{
      width: '624px',
      disableClose: true,
      data: {
        id_item: id,
        tipo_peso: this.tipo_peso,
        tipo_matriz: this.tipo,
        error_critico,
        aplica_para_cp: this.aplica_para_cp
      }
    });
  }

  editSubItem(id){
    const subitemRef = this.dialog.open(EditarSubItemComponent,{
      width: '624px',
      disableClose: true,
      data: {id_subItem: id,tipo_peso:this.tipo_peso}
    });
  }

  analyst(type){
    const analystRef = this.dialog.open(AnalistasCalidadComponent,{
      width: '732px',
      disableClose: true,
      data: {
        id_matriz: this.id_matriz,
        type,
      },
    })
  }

}
