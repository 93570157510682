<div class="novelities">
  <div class="header">
    <h1>Libro Verde de Nomina</h1>
    <p>En este espacio podrás revisar y cargar los ítems del Libro Verde<br> no olvides, "El respeto y
      empatía son clave".</p>
  </div>

  <div style="margin-top: 20px" class="row">

    <div class="col-4">
      <mat-form-field appearance="fill" class="filter">
        <mat-label>Documento</mat-label>
        <input matInput type="text" #input autocomplete="off" (keydown.enter)="onBlurMethod($event.target.value)">
        <mat-select name="ampm" class="ampm" matSuffix [formControl]="typeDocument"
                    style="width: 50px; margin-left: 5px;">
          <mat-option *ngFor="let item of documents" [value]="item?.id">{{item.key}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-2"></div>
    
    <div class="col-3">
      <button mat-raised-button (click)="downloadTemplate()" class="download btn" style="box-shadow:none !important">
        Descargar plantilla
        <span class="fi-rr-download"></span>
      </button>
    </div>

    <div class="col-3">
      <input #fileInput type="file" accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"      
      (ngModelChange)="onChangeFile($event)" 
      (change)="onChangeFile($event)" style="display: none">

      <button mat-raised-button color="primary" class="btn" (click)="fileInput.click()" class="upload btn" style="box-shadow:none !important">
        Cargar plantilla
        <span class="fi-rr-upload"></span>
      </button>
    </div>
  </div>


  <div class="table-container">
    <div class="mat-elevation-z1">


      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="fecha_carga">
          <th class="start negrita" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de carga </th>
          <td mat-cell *matCellDef="let row"> {{row.created_at | date:"dd-MM-yyyy"}}
        </ng-container>

        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="negrita"> Nombre </th>
          <td mat-cell *matCellDef="let row"> {{row.nombre_completo}} </td>
        </ng-container>

        <ng-container matColumnDef="documento">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="negrita"> Documento </th>
          <td mat-cell *matCellDef="let row"> {{row.documento}} </td>
        </ng-container>

        <ng-container matColumnDef="sueldo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="negrita"> Sueldo </th>
          <td mat-cell *matCellDef="let row"> {{row.sueldo}} </td>
        </ng-container>

        <ng-container matColumnDef="fecha_ingreso">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="negrita"> Fecha Ingreso </th>
          <td mat-cell *matCellDef="let row"> {{row.fecha_ingreso}} </td>
        </ng-container>

        <ng-container matColumnDef="contrato">
          <th class="negrita" mat-header-cell *matHeaderCellDef mat-sort-header> Contrato </th>
          <td mat-cell *matCellDef="let row"> {{row.contrato}} </td>
        </ng-container>

        <ng-container matColumnDef="cod_centro_de_costo">
          <th class="end negrita" mat-header-cell *matHeaderCellDef mat-sort-header> Centro de Costo </th>
          <td mat-cell *matCellDef="let row"> {{row.cod_centro_de_costo}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="7">No hay datos</td>
        </tr>
      </table>

      <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)"
        [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>

    </div>
  </div>
</div>