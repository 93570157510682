import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';

const CIU_URL = environment.CIU_URL;

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }


  /**
   * @author Jose Vicente Silva Rivera
   * @createdate 2021-02-02
   * Servicio que lista todos los groupes del aplicativo por campaña
   * @param size tamaño de los datos a traer
   * @param page numero de pagina en la que se ubicara la tabla
   */
  getConfigByDateGroup(data: any): Observable<any> {
    return this.http.get<any>(`${CIU_URL}config/search?date_start=${data.rangeDate}&group_id=${data.groups}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Jose Vicente Silva Rivera
   * @createdate 2021-02-02
   * Servicio que lista todos los groupes del aplicativo por campaña
   * @param size tamaño de los datos a traer
   * @param page numero de pagina en la que se ubicara la tabla
   */
  patchConfigs(data: any): Observable<any> {
    return this.http.put<any>(`${CIU_URL}config`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Jose Vicente Silva Rivera
   * @createdate 2021-02-02
   * Servicio que lista todos los groupes del aplicativo por campaña
   * @param size tamaño de los datos a traer
   * @param page numero de pagina en la que se ubicara la tabla
   */
  postConfigs(data: any): Observable<any> {
    return this.http.post<any>(`${CIU_URL}config`, data)
      .pipe(
        catchError(this.handleError)
      );
  }
}
