import { ReportsServiceCiu } from './../../../services/rest/report-service-ciu.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-report-users-app',
  templateUrl: './report-users-app.component.html',
  styleUrls: ['./report-users-app.component.sass']
})
export class ReportUsersAppComponent implements OnInit {

  public token: string='';
  public report_id:string='';
  public generar = false;
  public type : number;
  public tittle :string;
  public subTittle :string;

  constructor(
    private reportsServiceCiu : ReportsServiceCiu,
    private route : ActivatedRoute
    ) {}

  ngOnInit(): void {
    this.type = this.route.snapshot.params['id'];
    this.validateText(this.type);
    this.getReport(this.type == 1 ? '07_DBS_COS_ADHERENCI' : '010_DBS_ADH_PBI');
  }

  getReport(key)
  {
    this.reportsServiceCiu.getReport(key).subscribe((resp)=>{
      if(resp.data.token !== ''){
        this.token = resp.data.token;
        this.report_id =resp.data.reporte_id;
        console.log(resp.data);
        this.generar=true;
      }
    });
  }

  validateText(valid)
  {
    if(valid == 1)
    {
      this.tittle = 'Reporte de usuarios';
      this.subTittle = 'En este espacio podrás encontrar el informe de usuarios PBI.';
    }else
    {
      this.tittle = 'Reporte de usuarios';
      this.subTittle = 'En este espacio podrás encontrar el informe de usuarios PBI.';
    }
  }

}
