import { element } from 'protractor';
import { NoveltiesService } from './../../../../services/novelties.service';
import { CampaignsService } from './../../../../services/rest/campaigns.service';
import { NovedadesResponseComponent } from './../novedades-response/novedades-response.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { NovedadesViewComponent } from '../novedades-view/novedades-view.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { saveAs as importedSaveAs } from 'file-saver';
import { CandidatesService } from 'src/app/modules/rrhh/services/candidates.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as moment from 'moment';
import { SelectionModel } from '@angular/cdk/collections';
import Swal from 'sweetalert2';
import { NovedadesObservationComponent } from '../novedades-observation/novedades-observation.component';

@Component({
    selector: 'app-novedades',
    templateUrl: './novedades.component.html',
    styleUrls: ['./novedades.component.sass']
})
export class NovedadesComponent implements OnInit {

    // MatPaginator Inputs
    length;
    pageSize = 5;
    page = 1;
    isPage = 1;
    pageSizeOptions: number[] = [5, 10, 25, 100];

    length2;
    pageSize2 = 5;
    page2 = 1;
    isPage2 = 1;
    pageSizeOptions2: number[] = [5, 10, 25, 100];

    displayedColumns: string[] = ['check', 'fecha', 'nombre', 'numero', 'tipo', 'estado', 'campana', 'acciones'];
    displayedColumns2: string[] = ['check', 'fecha', 'nombre', 'numero', 'tipo', 'estado', 'campana', 'acciones'];
    dataSource = new MatTableDataSource<any>();
    dataSource2 = new MatTableDataSource<any>();

    reportForm: FormGroup;
    reportForm2: FormGroup;
    reportForm3: FormGroup;
    Campaings: any;
    Campaings3: any;
    noveltyList: any;

    filterControl = new FormControl();
    filterControl2 = new FormControl();
    filterControlNovelty = new FormControl();
    typeDocument = new FormControl();
    typeDocument2 = new FormControl();
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatPaginator, { static: true }) paginator2: MatPaginator;

    document: any;
    startDate: any = '';
    endDate: any;
    idCampaing: any;
    noveltyType: any; 

    document2: any;
    startDate2: any = '';
    endDate2: any;
    idCampaing2: any;

    documents: any = [];
    idTypeDoc: any;
    idTypeDoc2: any;

    filteredOptionsCampaigns: Observable<any[]>;
    selection = new SelectionModel(true, []);
    selection2 = new SelectionModel(true, []);

    constructor(public dialog: MatDialog, private service: CampaignsService, private novelties: NoveltiesService, private users: CandidatesService) {
    }

    ngOnInit(): void {
        this.reportForm = new FormGroup({
            start: new FormControl(''),
            end: new FormControl('')
        });
        this.reportForm2 = new FormGroup({
            start2: new FormControl(),
            end2: new FormControl()
        });

        this.reportForm3 = new FormGroup({
            startDate: new FormControl('', Validators.required),
            endDate: new FormControl('', Validators.required),
            campaignId: new FormControl(''),
            docNumber: new FormControl(''),
            docType: new FormControl()
        });
        this.reportForm3.controls.docType.setValue(1);
        this.selection.clear();
        //documento
        this.users.getIdTypes().subscribe(resp => {
            this.documents = resp;
        });
        this.typeDocument.valueChanges.subscribe(resp => {
            this.idTypeDoc = resp;
            this.getNovelieties(1, this.pageSize, this.page);
        });
        this.typeDocument.setValue(1);
        this.typeDocument2.valueChanges.subscribe(resp => {
            this.idTypeDoc2 = resp;
            this.getNovelieties2(2, this.pageSize2, this.page2);
        });
        this.typeDocument2.setValue(1);

        // Campaña
        this.filterControl.valueChanges.subscribe(resp => {
            this.resetPaginator(1);
            this.idCampaing = resp;
            this.getNovelieties(1, this.pageSize, this.page);
        });
        this.filterControl2.valueChanges.subscribe(resp => {
            this.resetPaginator(2);
            this.idCampaing2 = resp;
            this.getNovelieties2(2, this.pageSize2, this.page2);
        });
        // Tipo de novedad
        this.novelties.getFilterNoveltyTypes().subscribe(resp => {
            let data = [];
            var temp = Object.keys(resp.data).map(function (key) { return [Number(key), resp.data[key]]; });
            temp.forEach(element => data.push(element[1]))
            this.noveltyList = data;
        });

        this.filterControlNovelty.valueChanges.subscribe(resp => {
            this.noveltyType = resp;
            this.getNovelieties(1, this.pageSize, this.page);
        });
        this.filterControlNovelty.setValue('39'); // Por predeterminado traer los retardos

        this.service.getAllCampaigns().subscribe(resp => {
            this.Campaings = resp.data;
            this.Campaings3 = resp.data;

            this.filteredOptionsCampaigns = this.reportForm3.get('campaignId').valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                map(x => x ? this._filterCampaigns(x) : this.Campaings3.slice())
            );

        });
        this.novelties.getNovelties(1).subscribe(resp => {
            this.dataSource.data = resp.data.data;
            this.length = resp.data.total;
        }, error => {
            this.dataSource = new MatTableDataSource<any>();
            this.resetPaginator(2);
        });
        this.novelties.getNovelties(2).subscribe(resp => {
            this.dataSource2.data = resp.data.data;
            this.length2 = resp.data.total;
        }, error => {
            this.dataSource2 = new MatTableDataSource<any>();
            this.resetPaginator(1);
        });        
    }

    /**
     * @author Daniel Dominguez
     * @createdate 2021-04-12
     * Metodo de paginación
     */
    pageEvent(event: any): void {
        this.pageSize = event.pageSize;
        this.page = event.pageIndex + 1;
        this.getNovelieties(1, this.pageSize, this.page);
    }

    pageEvent2(event: any): void {
        this.pageSize2 = event.pageSize;
        this.page2 = Number(event.pageIndex + 1);
        this.getNovelieties2(2, this.pageSize2, this.page2);
    }

    /**
     * @author Daniel Dominguez
     * @createdate 2021-04-12
     * Modal de justificación
     */
    viewJustification(element): void {
        const dialogNewgroup = this.dialog.open(NovedadesResponseComponent, {
            width: '60%',
            disableClose: true,
            data: {
                type: 'crear',
                title: 'Juan Manuel Gonzales',
                data: element
            },
            panelClass: 'custom-dialog-container',
        });

        dialogNewgroup.afterClosed().subscribe(() => {
            this.getNovelieties(1, this.pageSize, this.page);
            this.getNovelieties2(2, this.pageSize2, this.page2);
        });

    }

    /**
     * @author Daniel Dominguez
     * @createdate 2021-04-12
     * Modal de visualizar justificación
     */
    viewJustification2(element): void {
        const dialogNewgroup = this.dialog.open(NovedadesViewComponent, {
            width: '60%',
            disableClose: true,
            data: {
                type: 'crear',
                title: 'Juan Manuel Gonzales',
                data: element
            },
            panelClass: 'custom-dialog-container',
        });

        dialogNewgroup.afterClosed().subscribe(() => {
            this.getNovelieties2(2, this.pageSize2, this.page2);
        });

    }

    /**
     * @author Daniel Dominguez
     * @createdate 2021-04-14
     * Metodo para capturar el documento a filtrar
     */
    onBlurMethod(e) {
        this.resetPaginator(1);
        this.document = e;
        this.getNovelieties(1, this.pageSize, this.page);
    }

    /**
     * @author Daniel Dominguez
     * @createdate 2021-04-14
     * Metodo para capturar el documento a filtrar
     */
    onBlurMethod2(e) {
        this.resetPaginator(2);
        this.document2 = e;
        this.getNovelieties2(2, this.pageSize2, this.page2);
    }

    /**
     * @author Daniel Dominguez
     * @createdate 2021-04-14
     * Metodo para capturar el rango de fechas a filtrar
     */
    dateRangeChange() {
        this.resetPaginator(1);
        const date1: Date = this.reportForm.controls.start.value;
        const date2: Date = this.reportForm.controls.end.value;
        const dateStart: string | null = new DatePipe('en-US').transform(date1, 'yyyy-MM-dd');
        const dateEnd: string | null = new DatePipe('en-US').transform(date2, 'yyyy-MM-dd');
        this.startDate = dateStart;
        this.endDate = dateEnd;
        this.getNovelieties(1, this.pageSize, this.page);
    }

    /**
     * @author Daniel Dominguez
     * @createdate 2021-04-14
     * Metodo para capturar el rango de fechas a filtrar
     */
    dateRangeChange2() {
        this.resetPaginator(2);
        const date1: Date = this.reportForm2.controls.start2.value;
        const date2: Date = this.reportForm2.controls.end2.value;
        const dateStart: string | null = new DatePipe('en-US').transform(date1, 'yyyy-MM-dd');
        const dateEnd: string | null = new DatePipe('en-US').transform(date2, 'yyyy-MM-dd');
        this.startDate2 = dateStart;
        this.endDate2 = dateEnd;
        this.getNovelieties2(2, this.pageSize2, this.page2);

    }

    /**
     * @author Daniel Dominguez
     * @createdate 2021-04-14
     * Metodo para filtrar los pendientes
     */
    getNovelieties(type, pageSi, page) {
        if (this.document != null || this.idCampaing != null || page != null || pageSi != null || this.startDate != null && this.endDate != null || this.noveltyType != null) {
            this.novelties.getNoveltiesBy(type, this.idTypeDoc, this.document, this.idCampaing, this.startDate, this.endDate, this.noveltyType ,pageSi, page).subscribe(resp => {
                this.dataSource.data = resp.data.data;
                this.length = resp.data.total;
            }, error => {
                this.dataSource = new MatTableDataSource<any>();
                this.resetPaginator(2);
            });
        }

    }

    /**
     * @author Daniel Dominguez
     * @createdate 2021-04-14
     * Metodo para filtrar los rechazados
     */
    getNovelieties2(type, pageSi2, page2) {
        if (this.document2 != null || this.idCampaing2 != null || page2 != null || pageSi2 != null || this.startDate2 != null && this.endDate2 != null) {
            this.novelties.getNoveltiesBy(type, this.idTypeDoc2, this.document2, this.idCampaing2, this.startDate2, this.endDate2,  '',pageSi2, page2).subscribe(resp => {
                this.dataSource2.data = resp.data.data;
                this.length2 = resp.data.total;
            }, error => {                
                this.dataSource2 = new MatTableDataSource<any>();
                this.resetPaginator(1);
            });
        }
    }

    /**
     * @author Daniel Dominguez
     * @createdate 2021-04-14
     * Metodo de descarga de reporte
     */
    downloadReport() {
        this.novelties.getReport(1, this.idTypeDoc, this.document, this.idCampaing, this.startDate, this.endDate, this.noveltyType, 0).subscribe(resp => {
            importedSaveAs(resp, 'reporte_novedades.xlsx');
        });
    }

    /**
     * @author Daniel Dominguez
     * @createdate 2021-04-14
     * Metodo de descarga de reporte de novedades rechazdas
     */
    downloadReport2() {
        this.novelties.getReport(2, this.idTypeDoc2, this.document2, this.idCampaing2, this.startDate2, this.endDate2, 0).subscribe(resp => {
            importedSaveAs(resp, 'reporte_novedades.xlsx');
        });
    }

    /**
     * @author Andrés Buitrago <andres.b@montechelo.com.co>
     * @purpose resetea el paginador
     */
    resetPaginator(numPaginator): void {
        if (numPaginator === 2) {
            this.length = 0;
            this.pageSize = 5;
            this.page = 1;
            this.paginator.firstPage();
        } else {
            this.length2 = 0;
            this.pageSize2 = 5;
            this.page2 = 1;
            this.paginator2.firstPage();
        }
    }

    get errorReport(): any {
        return this.reportForm3.controls;
    }

    /**
     * @author Andres Buitrago
     * @createdate 2021-02-04
     * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
     */
    displayFnCampaigns(id: number): string {
        if (!id) {
            return '';
        }
        const index = this.Campaings3.findIndex(resp => resp.id === id);
        return this.Campaings3[index].name;
    }

    /**
     * Reporte de novedades
     * @author Andres Buitrago
     */
    public downloadReportNovelties(): void {
        this.novelties.downloadReportNovelties(this.reportForm3.value)
            .subscribe(resp => {
                const dateToFile = moment().format('YYYY-MM-DD HH:MM:SS');
                importedSaveAs(resp, `reporte_novedades_${dateToFile}.csv`);
            });
    }

    /**
     * @author Daniel Martinez
     * @createdate 2021-02-04
     * filtra por nombre, se usa para el autocompletable
     * @param value valor a filtrar
     */
    private _filterCampaigns(value: string): any[] {
        const filterValue = value.toLowerCase();
        return this.Campaings3.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }



    /**
    * @author Javier Castañeda
    * @createdate 2022-04-06
    * Metodo para seleccionar las filas en la tabla
    */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /**
     * @author Javier Castañeda
     * @createdate 2022-04-06
     * Selecciona todas las filas si no están todas seleccionadas; de lo contrario la selección se limpia.
     */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data?.forEach(row => this.selection.select(row));
    }

    /**
   * @author Javier Castañeda
   * @createdate 2022-04-06
   * Metodo para justificar retardos masivamente
   */
    saveMassive(state) {
        let str = '¿En verdad deseas justificar las novedades?, recuerda esto' + '\n' + 'será definitivo y no se podrá deshacer.';
        Swal.fire({
            title: '¿Estás seguro?',
            html: '<pre style="font-family: Poppins; font-size: 14px;">' + str + '</pre>',
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: '#2CABBC',
            cancelButtonColor: '#FFFFFF',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Aceptar',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                let body = {
                    state: state,
                    type: 39,
                    Ids: []
                };
                for (let index = 0; index < this.selection.selected.length; index++) {
                    const element = this.selection.selected[index];
                    if (element.novelty_type.key == '39' && (element.state_key !== 'revision' && element.state_key !== 'segunda-instancia')) {
                        body.Ids.push(element.id);
                    }
                }
                this.createRequest(body, 1);
            }
        });
    }


    /**
* @author Javier Castañeda
* @createdate 2022-04-06
* Modal para la justificación de retardos
*/
    createRequest(element?, type?) {
        const dialogNewgroup = this.dialog.open(NovedadesObservationComponent, {
            width: '50%',
            disableClose: true,
            data: {
                data: element,
                type: type,
                rol: 'jefe-inmediato',
                title: 'Agregar motivo de aprobacion'
            },
            panelClass: 'custom-dialog-container',
        });

        dialogNewgroup.afterClosed().subscribe(() => {
            this.getNovelieties(1, this.pageSize, this.page);
            this.getNovelieties2(2, this.pageSize2, this.page2);
            this.selection.clear();
        });

    }

    /**
  * @author Javier Castañeda
  * @createdate 2022-04-06
  * Metodo para seleccionar las filas en la tabla
  */
    isAllSelected2() {
        const numSelected = this.selection2.selected.length;
        const numRows = this.dataSource2.data.length;
        return numSelected === numRows;
    }

    /**
     * @author Javier Castañeda
     * @createdate 2022-04-06
     * Selecciona todas las filas si no están todas seleccionadas; de lo contrario la selección se limpia.
     */
    masterToggle2() {
        this.isAllSelected2() ?
            this.selection2.clear() :
            this.dataSource2.data?.forEach(row => this.selection2.select(row));
    }

    /**
* @author Javier Castañeda
* @createdate 2022-04-06
* Metodo para justificar retardos masivamente
*/
    saveMassive2(state) {
        let str = '¿En verdad deseas justificar las novedades?, recuerda esto' + '\n' + 'será definitivo y no se podrá deshacer.';
        Swal.fire({
            title: '¿Estás seguro?',
            html: '<pre style="font-family: Poppins; font-size: 14px;">' + str + '</pre>',
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: '#2CABBC',
            cancelButtonColor: '#FFFFFF',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Aceptar',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                let body = {
                    state: state,
                    type: 39,
                    Ids: []
                };
                for (let index = 0; index < this.selection2.selected.length; index++) {
                    const element = this.selection2.selected[index];
                    if (element.novelty_type.key == '39' && element.state_key !== 'revision' && element.num_instance !== null) {
                        body.Ids.push(element.id);
                    }
                }
                this.createRequest(body, 1);
            }
        });
    }


}
