import { M } from '@angular/cdk/keycodes';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { JuridicaComponent } from './juridica.component';

const routes: Routes = [
  { path: '', component: JuridicaComponent },
  { path: 'permit', loadChildren: () => import('../juridica/modules/permit/permit.module').then((m) => m.PermitModule) },
  { path: 'boss-disciplinary-process', loadChildren: () => import('./modules/boss-disciplinary-process/suspension.module').then((m) => m.SuspensionModule) },
  { path: 'reminder', loadChildren: () => import('../juridica/modules/reminder/reminder.module').then((m) => m.ReminderModule) },
  { path: 'certificate', loadChildren:  () => import('./modules/boss-disciplinary-process/suspension.module').then((m) => m.SuspensionModule) },
  { path: 'cases', loadChildren: () => import('../juridica/modules/cases/cases.module').then((m) => m.CasesModule) },
  { path: 'finishedcases', loadChildren: () => import('../juridica/modules/finished-cases/finished-cases.module').then((m) => m.FinishedCasesModule) },
  { path: 'disciplinary-process', loadChildren: () => import('../juridica/modules/disciplinary-process/disciplinary-process.module').then((m) => m.DisciplinaryProcessModule) },
  { path: 'suspension-process', loadChildren: () => import('../juridica/modules/suspension-boss/suspension-boss.module').then((m) => m.SuspensionBossModule) },
  { path: 'otrosi', loadChildren: () => import('../juridica/modules/otroSi/otroSi.module').then((m) => m.OtroSiModule) },
  { path: 'cesion', loadChildren: () => import('../juridica/modules/cesion/cesion.module').then((m) => m.CesionModule) },
  { path: 'permit-boss', loadChildren: () => import('../juridica/modules/permit-boss/permit-boss.module').then((m) => m.PermitBossModule) },
  { path: 'endcontract', loadChildren: () => import('../juridica/modules/end-contract/end-contract.module').then((m) => m.EndContractModule) },
  { path: 'pazysalvo', loadChildren: () => import('../juridica/modules/pazysalvo/pazysalvo.module').then((m) => m.PazysalvoModule) },
  { path: 'domestic-calamity', loadChildren: () => import('../juridica/modules/domesticCalamity/domesticCalamity.module').then((m) => m.DomesticCalamityModule) },
  { path: 'suspensions-novelties', loadChildren: () => import('../juridica/modules/suspensions/suspensions.module').then((m) => m.SuspensionsModule) },
  { path: 'function-reminder', loadChildren: () => import('../juridica/modules/function-reminder/function-reminder.module').then((m) => m.FunctionReminderModule) },
  { path: 'assignment-campaign', loadChildren: () => import('./modules/assignment-campaign/assignment-campaign.module').then((m) => m.AssignmentCampaignModule) },
  { path: 'request-cases', loadChildren: () => import('./modules/boss-disciplinary-process/suspension.module').then((m) => m.SuspensionModule) },
  { path: 'validate-process', loadChildren: () => import('./modules/boss-disciplinary-process/suspension.module').then((m) => m.SuspensionModule) },
  { path: 'paz-y-salvos', loadChildren: () => import('./modules/cargues-paz-y-salvos/cargues-paz-y-salvos.module').then((m) => m.CarguesPazYSalvosModule) },
  { path: 'document-signature', loadChildren: () => import('./modules/document-signature/document-signature.module').then((m) => m.DocumentSignatureModule) },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JuridicaRoutingModule {

}