<div class="admin-auditoria" fxLayout="column">
    <div fxLayout="row">
      <div fxFlex="50%">
        <h2 style="height: 33px !important;
        font: normal normal bold 45px/35px Poppins !important;
        letter-spacing: 0px !important;color: #353535 !important;opacity: 1 !important;">Auditoría</h2>
        <br>
        <p>Aquí podrás visualizar las auditorias que te han realizado. No</p>
        <p>olvides, “El respeto y empatía son clave”.</p>
      </div>
      <div fxFlex="50%" fxLayout="row" fxLayoutAlign="end end" class="pt-5">
        <mat-card class="puntos" fxLayout="column">
          <p class="puntaje">{{trimestre_puntaje}}</p>
          <p class="titulo-puntaje">Auditorias del último trimestre</p>
        </mat-card>
        <mat-card class="puntos">
          <p class="puntaje">{{mes_puntaje}}</p>
          <p class="titulo-puntaje">Auditorias del mes</p>
        </mat-card>
        <mat-card class="puntos">
          <p class="puntaje">{{hoy_puntaje}}</p>
          <p class="titulo-puntaje">Auditorias de Hoy</p>
        </mat-card>
      </div>
    </div>
    <div fxFlex.gt-lg="100%" class="mt-5">
      <app-tabla-auditado></app-tabla-auditado>
    </div>
    </div>