import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { CampaignInterface } from '../interfaces/CampaignInterface';
import { PositionInterface } from '../interfaces/PositionInterface';


const NOMINA_URL = environment.NOMINA_URL;

@Injectable({
  providedIn: 'root'
})
export class NominaService {

  headersFile;
  headers;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.headersFile = {
      responseType: "blob"
    };
  }

  /**
   * Consulta las campañas activas en recursos humanos
   * @returns
   */
  getCampaings(): Observable<CampaignInterface[]> {
    return this.http.get(`${NOMINA_URL}campaigns/showAll?paginate=0`)
      .pipe( map( (campaigns:CampaignInterface[]) => campaigns["data"] ));
  }



}
