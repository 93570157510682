<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<h2 mat-dialog-title>Descargas</h2>
<hr>
<mat-dialog-content>
    <div  style="padding: 15px 25px 32px;;">
        <mat-radio-group class="example-radio-group"   fxLayout="column" [(ngModel)]="option">
            <mat-radio-button value="previnculacion" class="example-radio-button" ><span>Descargar previnculación</span> </mat-radio-button> 
           <mat-radio-button value="vinculacion" class="example-radio-button" > <span>Descargar vinculación</span> </mat-radio-button>
         </mat-radio-group>
    </div>
  
</mat-dialog-content>
<mat-dialog-actions align="center" style="margin-bottom:10px ;">
  <button mat-flat-button color="primary" [mat-dialog-close]="option">Descargar</button>
</mat-dialog-actions>