import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UsersService } from 'src/app/modules/ciu/services/rest/users.service';
import { NoveltiesService } from 'src/app/modules/nomina/services/novelties.service';
import { LiquidationsService } from 'src/app/modules/nomina/services/rest/liquidations.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { civilStates } from '../../../../../../shared/cv-view/edit-questions';
import { CandidatesService } from '../../../../../rrhh/services/candidates.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-liquidations-response',
  templateUrl: './liquidations-response.component.html',
  styleUrls: ['./liquidations-response.component.sass']
})
export class LiquidationsResponseComponent implements OnInit {
  // MatPaginator Inputs
  length;
  pageSize = 5;
  page = 1;
  isPage = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  displayedColumns: string[] = ['fecha', 'nombre', 'numero', 'tipo', 'estado', 'campana'];
  dataSource = new MatTableDataSource<any>();
  reportForm: FormGroup;
  Campaings: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  // Hoja de vida
  candidate: any = null;
  healthForm: FormGroup;

  // liquidación
  startDate: any = '';
  endDate: any;
  date: any;
  dateLiquidation = new FormControl();
  document: any;
  idTypeDoc: any;
  idCampaing: any;
  filterControl2 = new FormControl();
  photo: any;
  civilStates = civilStates;

  constructor(private liquidationService: LiquidationsService,
              private fb: FormBuilder,
              private alert: AlertsService,
              public userService: UsersService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private novelties: NoveltiesService,
              private serviceCandidate: CandidatesService,
              public dialog: MatDialog,
              private snack: MatSnackBar,
              public dialogRef: MatDialogRef<LiquidationsResponseComponent>) {
  }

  ngOnInit(): void {
    this.reportForm = new FormGroup({
      start: new FormControl(''),
      end: new FormControl('')
    });
    this.dateLiquidation.valueChanges.subscribe(resp => {
      const date1: string|null = new DatePipe('en-US').transform(resp, 'yyyy-MM-dd');
      this.date = date1;
    });

    this.healthForm = this.fb.group({
      'tabaquismo': [null, Validators.required],
      'alcohol': [null, Validators.required],
      'sustancias_psicoactivas': [null, Validators.required],
      'ejecicio': [null, Validators.required],
      'antecedentes_de_accidentes_trabajo': [null, Validators.required],
      'antecedentes_enfermedades_laborales': [null, Validators.required],
      'antecedentes_patologicos_personales': [null, Validators.required]
    });
    this.getData();
  }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-04-12
   * Metodo de paginación
   */
  pageEvent(event: any): void {

    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getNoveltiesRelatedToUser(this.pageSize, this.page);
  }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-04-19
   * Metodo para obtener las novedades del usuario
   */
  getNoveltiesRelatedToUser(size: number, page: number): void {
    this.novelties.getNoveltiesBy(3, this.idTypeDoc, this.data.data.documento, this.data.data.campaign.id, this.startDate, this.endDate, size, page).subscribe(resp => {
      this.dataSource = resp.data.data;
      this.length = resp.data.total;
    });
  }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-04-12
   * Metodo para conseguir hoja vida del usuario
   */
  getData(): void {
    this.liquidationService.getCVUserLoggued(this.data.data.id).subscribe((resp: any) => {
      this.candidate = resp;
      this.idTypeDoc = resp.id_type.id;
      this.candidate.civilState = civilStates.find(element => element.id = resp.civil_state_id);
      this.findPhoto(resp?.attachements);
      this.getNoveltiesRelatedToUser(this.pageSize, this.page);
    });
  }


  /**
   * @author Daniel Dominguez
   * @createdate 2021-04-16
   * Metodo para liquidar un usuario
   */
  liquidations(): void {
    const body = {
      liquidation_date: this.date,
      user_id: this.data.data.id,
      campaign_id: this.data.data.campaign.id
    };
    const confirm = this.alert.alertConfirm('¿Esta seguro que desea liquidar el usuario ?');
    confirm.then(res => {
      if (res.isConfirmed) {
        this.liquidationService.createLiquidations(body).subscribe(resp => {
          this.alert.alertSuccess('Liquidación realizada', resp.data);
          this.dialogRef.close('');
        }, error => {
          this.alert.alertError('Error!', 'No se pudo realizar la gestión de liquidación correctamente');
        });
      }
    });
  }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-04-12
   * Metodo para capturar el documento a filtrar
   */
  onBlurMethod(e): void {
    this.document = e;
    this.getNoveltiesRelatedToUser(this.pageSize, this.page);
  }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-05-13
   * Metodo para capturar el rango de fechas a filtrar
   */
  dateRangeChange(): void {
    const date1: Date = this.reportForm.controls.start.value;
    const date2: Date = this.reportForm.controls.end.value;
    const dateStart: string|null = new DatePipe('en-US').transform(date1, 'yyyy-MM-dd');
    const dateEnd: string|null = new DatePipe('en-US').transform(date2, 'yyyy-MM-dd');
    this.startDate = dateStart;
    this.endDate = dateEnd;
  }

  /**
   * @author Andres Buitrago
   * @createdate 2021-12-27
   * Metodo para la descarga de novedades del usuario por rango de fecha
   */
  downloadReport(): void {
    const docNumber = this.data.data.documento;
    const date1: Date = this.reportForm.controls.start.value;
    const date2: Date = this.reportForm.controls.end.value;
    const dateStart: string | null = new DatePipe('en-US').transform(date1, 'yyyy-MM-dd');
    const dateEnd: string | null = new DatePipe('en-US').transform(date2, 'yyyy-MM-dd');
    this.novelties.getNoveltiesReportForLiquidation(docNumber, this.idTypeDoc, dateStart, dateEnd).subscribe(resp => {
      importedSaveAs(resp, `reporte_novedades_nomina_${ docNumber }_.xlsx`);
    });
  }


  /**
   * Resetea el paginador
   */
  resetPaginator(): void {
    this.length = 0;
    this.pageSize = 5;
    this.page = 1;
    this.paginator.firstPage();
  }

  /**
   * Busca la foto entre los documentos adjuntos
   * @param documents adjuntos
   */
  findPhoto(documents): any {
    if (documents) {
      const found = documents.find(element => element.file_attachment_id == 8);
      if (found) {
        const direction = `candidate/download/attachment/${ this.candidate.id }/${ found.id }`;
        this.serviceCandidate.downdloadFile(direction).subscribe((file) => {
          this.photo = file;
        }, error => {
          setTimeout(() => {
            this.snack.open('No tiene imagen asociada', '', {
              duration: 3500,
              verticalPosition: 'top',
              panelClass : ['red-snackbar']
            });
          }, 400);
        });
      }
    }
  }

}
