import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { format } from 'util';

const AGENDAMIENTOS_URL = environment.AGENDAMIENTOS_URL;

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private http: HttpClient) { }
  getBooking(isPage: number,size: number, page: number){
    return this.http.get<any>(`${AGENDAMIENTOS_URL}campaigns/?paginate=${isPage}&n=${size}&page=${page}` )
  }
  postBooking(direction, form){
    return this.http.post<any>(`${AGENDAMIENTOS_URL}${direction}`, form )
  }
  putBooking(direction, form){
    return this.http.put<any>(`${AGENDAMIENTOS_URL}${direction}`, form )
  }
}
