import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { EscalationService } from '../../../services/rest/escalation.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.sass']
})
export class EditUserComponent implements OnInit {

  userArea: FormGroup;
  areas: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public escalationService: EscalationService,
              private alertsService: AlertsService,
              private destroyService: DestroyComponentService) { }

  ngOnInit(): void {

    this.escalationService.getArea().subscribe((resp) => {
      this.areas = resp;
    });

    this.formControl();
    this.setEdit();
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que crea los controles del formulario
   */
  formControl(): void{

    this.userArea = new FormGroup({
      area: new FormControl('', [Validators.required]),
    });

  }

  get error(): any { return this.userArea.controls; }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que en caso que el modal sea de edicion realiza el seteo de los campos
   */
  setEdit(): void {
    if (this.data.type === 'editar') {

      this.userArea.controls.area.setValue(this.data.data.user_areas[0].id);

    }
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que se encarga de guardar un area
   * @param userArea area a guardar
   */
  saveArea(userArea): void {

    const area = new FormData();

    area.append( 'area_id', userArea.value.area);

    this.escalationService.editUsuario(this.data.data.id, area).subscribe((resp) => {
      this.alertsService.alertSuccess('Editado', 'Area editada correctamente');
    });

  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

}
