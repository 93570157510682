<form (ngSubmit)="onSubmit()">
    <mat-accordion class="example-headers-align" multi>
        <div *ngFor="let section of _sections; index as i; let first = first;">
            <mat-expansion-panel *ngIf="section.see" [expanded]="!section?.collapse">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h3> {{section.name_section}}</h3>
                        <button mat-button matSuffix mat-icon-button aria-label="Agregar" *ngIf="section.duplicate" type="button" (click)="addSection(section, i)" (click)="$event.stopPropagation();">
                            <span class="fi-rr-add"></span>
                        </button>
                        <button mat-button matSuffix mat-icon-button aria-label="Eliminar" *ngIf="section.duplicated_section" type="button" (click)="removeSection(section, i)" (click)="$event.stopPropagation();">
                            <span class="fi-rr-trash"></span>
                        </button>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-grid-list cols="4" rowHeight="100px" [gutterSize]="'20px'">
                    <div *ngFor="let question of section.fields ; index as y">
                         <mat-grid-tile [colspan]="question.cols" *ngIf="question.see && question.seeSons && forms.controls[i]"> <!-- IMPORTANTE, se agrega una validación de forms.control[i] provicional, mientras se resuelve el bug que realiza una doble asignación del formualrio -->
                            <shared-form-questions style="width: 100%" (addField)="addQuestion($event, section.name_section)" [question]="question" [idSection]="section.id" [form]="forms.controls[i]" (addDependece)="addDependencies($event)" (addFile)="addFiles($event)"></shared-form-questions>
                        </mat-grid-tile>
                    </div>
                </mat-grid-list>

            </mat-expansion-panel>
        </div>
    </mat-accordion>
    <div fxLayout="row" fxLayoutAlign="end center" class="action-btns">
        <button mat-stroked-button *ngIf="plantilla === 'formView' && template" type="button" [disabled]="!forms.valid" (click)="generateTemplate()">Generar plantilla</button>
        <button mat-stroked-button type="button" (click)="cancelForm()">Cancelar</button>
        <button mat-flat-button color="primary" [disabled]="!forms.valid" type="submit">Guardar</button>
    </div>
</form>
