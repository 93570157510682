<div class="container-fluid p-5">
  <div class="pb-4">
    <button (click)="backToPreviousPage()" mat-mini-fab aria-label="Boton para regresar a la página anterior"
      style="background-color: #353535;">
      <i class="fi-rr-angle-small-left fs-4" style="color: #FBFBFB;"></i>
    </button>
    <span class="ms-2" style="font-weight: 600; font-size: 16px;">Regresar</span>
  </div>
  <div class="row">
    <div class="col-md-12 pb-3">
      <span style="font-size: 32px; font-weight: 700;">Gestor de descargas</span>
    </div>
    <div class="col-md-12">
      <p class="lh-base" style="color: #353535">
        En este espacio podrás realizar la gestión para programar descargas de las <br />
        llamadas registradas en Vicidial, no olvides, “El respeto y empatía son clave”.
      </p>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-12">
      <span style="font-weight: 700;">Descarga de llamadas</span>
    </div>
  </div>
  <form [formGroup]="formCreateDownload">
    <div class="row">
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Servidor</mat-label>
          <mat-select formControlName="idserver" (ngModelChange)="CargarSelects($event)">
            <mat-option *ngFor="let rw of servidores" [value]="rw.id">{{ rw.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="errorsForm.idserver.errors">
            <span *ngIf="errorsForm.idserver.errors.required">Este campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Campaña</mat-label>
          <mat-select formControlName="campaign">
            <mat-option *ngFor="let rw of campaign" [value]="rw.id">{{ rw.nombre }}</mat-option>
          </mat-select>
          <mat-error *ngIf="errorsForm.campaign.errors">
            <span *ngIf="errorsForm.campaign.errors.required">Este campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Llamada</mat-label>
          <mat-select formControlName="call">
            <mat-option *ngFor="let rw of llamadas" [value]="rw.id">{{ rw.nombre }}</mat-option>
          </mat-select>
          <mat-error *ngIf="errorsForm.call.errors">
            <span *ngIf="errorsForm.call.errors.required">Este campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Fecha de las llamadas</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="dateStart" placeholder="Fecha inicial">
            <input matEndDate formControlName="dateEnd" placeholder="Fecha final">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error *ngIf="errorsForm.dateStart.errors || errorsForm.dateEnd.errors">
            <span *ngIf="errorsForm.dateStart.errors.required || errorsForm.dateEnd.errors.required">Por favor ingrese
              un rango de fecha</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Skills de las llamadas </mat-label>
          <mat-select formControlName="skillCalls" multiple>
            <mat-option *ngFor="let rw of skill_llamadas" [value]="rw.id">{{ rw.nombre }}</mat-option>
          </mat-select>
          <mat-error *ngIf="errorsForm.skillCalls.errors">
            <span *ngIf="errorsForm.skillCalls.errors.required">Este campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Registros o descargas (opcional)</mat-label>
          <input type="number" formControlName="recordsOrDownloads" matInput
            placeholder="Registros o descargas (opcional)">
          <mat-error *ngIf="errorsForm.recordsOrDownloads.errors">
            <span *ngIf="errorsForm.recordsOrDownloads.errors.validateNumbersNegative">Solo números positivos</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>ID llamada (opcional)</mat-label>
          <input type="number" formControlName="idCall" matInput placeholder="ID llamada (opcional)">
          <mat-error *ngIf="errorsForm.idCall.errors">
            <span *ngIf="errorsForm.idCall.errors.validateNumbersNegative">Solo números positivos</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <button type="button" [disabled]="validateFirstStepForm()" (click)="GetColumns()" mat-raised-button
          color="primary" class="w-100" style="height: 48px;">Consultar</button>
      </div>
    </div>
    <div *ngIf="countRegisters > 0; else notRegisters">
      <div class="row">
        <div class="col-md-12 mb-3">
          <b>Registros encontrados: </b> {{ countRegisters }}
        </div>
        <div class="col-md-12 mb-3">
          <span style="font-weight: 700;">Orden de etiquetado de los audios</span>
        </div>
        <div class="col-md-12">
          <p>Elige y organiza los campos que se usan para el etiquetado de los audios.zip</p>
        </div>
      </div>
      <div class="row py-2">
        <div class="col-md-12">
          <span style="font-size: 20px; font-weight: 700;">Configuración de etiquetado</span>
          <div cdkDropList [cdkDropListData]="columnsAutomatic" class="example-list mt-3"
            (cdkDropListDropped)="dragAndDropItem($event)">
            <div formArrayName="labels" class="row d-flex align-items-center example-box border rounded-3"
              *ngFor="let item of labelsControls.controls; let i = index" cdkDrag>
              <ng-container [formGroupName]="i">
                <div class="col-md-1 d-flex justify-content-center mb-3">
                  <div><i class="fi-rr-menu-dots-vertical" style="color: #353535;"></i></div>
                  <div class="ms-3">
                    <span class="px-2 py-1"
                      style="background-color: #EBEBEB; color: #353535; font-weight: 700; border-radius: 5px;">{{ i + 1
                      }}</span>
                  </div>
                </div>
                <div class="col-md-5">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Columnas automaticas</mat-label>
                    <mat-select (selectionChange)="onChangeSelectConector($event)" formControlName="columnAutomatic">
                      <mat-option *ngFor="let columns of columnsAutomatic" [value]="columns">{{ columns }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="labelsControls.controls[i].get('columnAutomatic').errors">
                      <span *ngIf="labelsControls.controls[i].get('columnAutomatic').errors.required">Este campo es
                        requerido</span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-5">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Conectores</mat-label>
                    <mat-select (selectionChange)="onChangeSelectConector($event)" formControlName="connector">
                      <mat-option *ngFor="let connector of connectors" [value]="connector">{{ connector }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="labelsControls.controls[i].get('connector').errors">
                      <span *ngIf="labelsControls.controls[i].get('connector').errors.required">Este campo es
                        requerido</span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-1 d-flex justify-content-center mb-3">
                  <button type="button" mat-icon-button (click)="addLabel()">
                    <i class="fi-rr-add fs-4" style="color: #51B0CB"></i>
                  </button>
                  <button *ngIf="i !== 0" type="button" mat-icon-button (click)="deleteLabel(i)">
                    <i class="fi-rr-trash fs-4" style="color: #D14A4A"></i>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <button type="button" class="w-100" (click)="openDialogCreateLabel()"
            style="height: 60px; background-color: transparent; color: #A9A9A9; border: 1px solid #A9A9A9; border-style: dashed; border-radius: 10px;">Agregar
            Nueva etiqueta</button>
        </div>
      </div>
      <div class="mt-3" style="font-size: 16px; font-weight: 700; color: #51B0CB">
        Estructura etiquetado del audio: {{ audioLabelingStructure }}.zip
      </div>
      <div class="row justify-content-end">
        <div class="col-md-2">
          <button type="button" (click)="backToPreviousPage()" mat-raised-button class="w-100"
            style="height: 48px;">Cancelar</button>
        </div>
        <div class="col-md-2">
          <button type="button" [disabled]="formCreateDownload.invalid" (click)="generateDownload()" mat-raised-button
            color="primary" class="w-100" style="height: 48px;">Generar descargas</button>
        </div>
      </div>
    </div>
    <ng-template #notRegisters>
      <div class="row d-flex align-items-center" style="height: 50vh;">
        <div class="col-md-12 text-center">
          <span style="font-size: 32px; font-weight: 700; color: #A9A9A9;">No existen registros para descargar</span>
        </div>
      </div>
    </ng-template>
  </form>
</div>