<div class="admin-reporte">

  <div class="header">

    <h2 style="height: 33px !important;
      font: normal normal bold 45px/35px Poppins !important;
      letter-spacing: 0px !important;color: #353535 !important;opacity: 1 !important;">{{ config.labels.reporting }}</h2>
    <br>
    <p>{{ config.labels.reportingDescription }}</p>
    <p>{{ config.labels.reportingKeyMessage }}</p>
  </div>
  <div class="mt-5">
    <app-main-matrices></app-main-matrices>
  </div>
</div>
