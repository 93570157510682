<div class="main-container">

  <div class="row">
    <div class="head col-6">
      <button mat-icon-button (click)="backRoute()">
          <div class="display:flex">
              <i class="fi-rr-angle-small-left" style="font-size:24px;"></i>
              <span style="vertical-align: super;font: normal normal 600 16px/25px Poppins;letter-spacing: 0px;color: #353535;opacity: 1;">Regresar</span>
          </div>
      </button>
      <br>
      <br>
      <h1>Gestión de reportes</h1>
      <p>Bienvenido al módulo de reportes de ciu.</p>
    </div>
    <br>
  </div>

<div class="row">
  <mat-form-field class="col-3" appearance="fill">
    <mat-label>Reporte</mat-label>
    <mat-select (selectionChange)="select($event.value)">
      <mat-select-trigger>{{selectTitle}}</mat-select-trigger>

      <mat-option *ngFor="let item of reports; let i = index" [value]="i">
        <div style="display:flex; justify-content: space-between">
            <span style="margin-top: 5px;">{{item.name}}</span>
          <span class="icon">
            <mat-icon (click)="editReport(item)">edit</mat-icon>
          </span>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="col-6"></div>
  <div class="col-3" style="text-align: right;">
    <button mat-raised-button color="primary" (click)="saveReport()" style="height:48px">Crear nuevo reporte</button>
  </div>
</div>

<div class="row" *ngIf="key != null">
  <iframe class="col-lg-12 col-md-12 col-sm-12" height="700px" [src]="key"></iframe>
</div>

</div>
