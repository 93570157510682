import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/rest/auth.service';

@Component({
  selector: 'app-sable',
  templateUrl: './sable.component.html',
  styleUrls: ['./sable.component.sass']
})

export class SableComponent implements OnInit {
  nameUser: string = '';

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    const user = this.authService.getUser();
    this.nameUser = user.rrhh.name;
  }
}