<div class="admin-groups">

  <div class="header">
      <button mat-icon-button (click)="backRoute()">
          <div class="display:flex">
              <i class="fi-rr-angle-small-left" style="font-size:24px;"></i>
              <span
                  style="vertical-align: super;font: normal normal 600 16px/25px Poppins;letter-spacing: 0px;color: #353535;opacity: 1;">Regresar</span>
          </div>
      </button>
      <br>
      <br>
      <h1>Dahsboard</h1>
      <p>En este espacio podrás visualizar graficas con datos importantes</p>
      </div>
      <form class="form" [formGroup]="reportForm">
          <div class="row mt-5">
  
              <mat-form-field appearance="fill" class="col-4">
                  <mat-label>Reporte</mat-label>
                  <mat-select formControlName="reportSelect" id="reportSelect">
                      <mat-option *ngFor="let report of reports; let i = index" [value]="i">
                          {{report.nombre}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
              <div appearance="fill" class="col-4">
                <button mat-raised-button
                    color   = "primary"
                    class   ="h-75"
                    title   = "Pantalla Completa"
                    (click) = "fullScreen()">
                    <i class="fi fi-rr-expand"></i>
                </button>
              </div>
          </div>
      </form>
      <div id="iframe_dashboard">
          <div class="loader" *ngIf="isLoading">
              <div class="loader__container loader__container_1">
                  <div class="loader__circle loader__circle_1 loader__circle_animation-1"></div>
                  <div class="loader__circle loader__circle_2 loader__circle_animation-3"></div>
                  <div class="loader__circle loader__circle_3 loader__circle_animation-5"></div>
                  <div class="loader__circle loader__circle_4 loader__circle_animation-7"></div>
                </div>
                <div class="loader__container loader__container_2">
                  <div class="loader__circle loader__circle_1 loader__circle_animation-2"></div>
                  <div class="loader__circle loader__circle_2 loader__circle_animation-4"></div>
                  <div class="loader__circle loader__circle_3 loader__circle_animation-6"></div>
                  <div class="loader__circle loader__circle_4 loader__circle_animation-8"></div>
                </div>
          </div>
          <iframe [src]="actual_url" title="description" (load)="onIframeLoad()"></iframe>
      </div>
</div>