import { CrmService } from './../../../services/crm.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { ResourcesService } from '../../../services/resources.service';
import { Crm2Service } from '../../../services/crm2.service';

@Component({
  selector: 'app-resources-types',
  templateUrl: './resources-types.component.html',
  styleUrls: ['./resources-types.component.sass']
})
export class ResourcesTypesComponent implements OnInit {


  time            = new FormControl(null, [Validators.required, Validators.min(1), Validators.max(1440)]);
  resourceTypes   : any[] = [];
  states          = false;
  action          = 'Guardar';
  typeId          = null;
  crmForm         : any[] = [];
  crmTwoForm      : any[] = [];
  createType      = false;

  typeForm: FormGroup = this.fb.group({
    'name'            : [null, Validators.required],
    'key'             : [null, [Validators.required, Validators.pattern(/[^ .,\/#!$%\^&\*;:{}=\-_`~()?¿!¡]/g)]],
    'booking_time'    : [null, [Validators.required, Validators.min(1), Validators.max(1440)]],
    'id_form_cr'      : [],
    'id_form_crm_two' : [],
  });


  constructor(private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private alertNotification   : AlertsService,
              private auth                : AuthService,
              private resourcesService    : ResourcesService,
              private crmService          : CrmService,
              private crmTwoService          : Crm2Service,
              ) {
              }
  ngOnInit(): void {
    this.getResourceTypes();
    this.getFormsCrm();

    this.auth.getUser().roles.forEach(element => {
      if (element === 'rrhh::usuario') {
        this.states = true;
      }
    });
  }

  saveResourceType() {
      if (this.typeForm.valid) {
        if (this.action === 'Guardar') {
          this.alertNotification.alertConfirm('¿Estás seguro? Una vez creado el tipo de recurso, este no se podrá eliminar').then((result) => {
            if (result.isConfirmed) {
              let form = this.typeForm.value;
              
              if(form['id_form_crm_two'] !== "" && form['id_form_crm_two'] !== null && form['id_form_crm_two'] !== undefined ){
                form['id_form_cr'] = form['id_form_crm_two']
                form['crm_service'] = 2
                form['id_form_crm_two'] = undefined
              }else{
                form['crm_service'] = 1
              }
              this.resourcesService.createResourceTypes(form).subscribe( res => {
                this.alertNotification.alertSuccess('Tipo de recurso creado con éxito', '');
                this.resourceTypes.push(form);
                this.cancelForm('typeForm', 'createType');
              });
              
            }
          });
        }

        if (this.action === 'Editar') {
          let form = this.typeForm.value;
              
            if(form['id_form_crm_two'] !== "" && form['id_form_crm_two'] !== null && form['id_form_crm_two'] !== undefined ){
              form['id_form_cr'] = form['id_form_crm_two']
              form['crm_service'] = 2
              form['id_form_crm_two'] = undefined
            }else{
              form['crm_service'] = 1
            }

            this.resourcesService.updateResourceTypes(this.typeId, form).subscribe( res => {
            this.alertNotification.alertSuccess('Tipo de recurso editado con éxito', '');
            this.getResourceTypes();
            this.cancelForm('typeForm', 'createType');
            this.action = 'Guardar';
          });
        }
      } else {
        this.typeForm.markAllAsTouched();
      }
  }
  cancelForm(form, arg) {
    this[form].reset();
    this[arg] = !this[arg];
  }

  getResourceTypes() {
    this.resourcesService.getResourceTypes(true).subscribe((res: any) => {
      if (res != null) {
        this.resourceTypes = res;
        this.resourceTypes.reverse();
      }
    });
  }

  getFormsCrm() {
    this.crmService.getFormsCRM().subscribe((res: any) => {
      this.crmForm = res;
    });

    this.crmTwoService.getFormsCRM().subscribe((res: any) => {
      if(Array.isArray(res) == true){
        this.crmTwoForm = res;
      }else{
        for (const key in res) {
          this.crmTwoForm.push(res[key]);
        }
      }

    });

  }

  editTypeInput(type) {
    this.action = 'Editar';
    this.createType = true;
    this.typeId = type.id;
    if(type['crm_service'] == 2){
      type['id_form_crm_two'] = type['id_form_cr']
      this.changeStateForms('crm2');
    }else{
      this.changeStateForms('crm1');
    }
    this.typeForm.patchValue(type);
    this.typeForm.get('name').disable();
    this.typeForm.get('key').disable();
  }


  getBookingTimeError() {
    if (this.typeForm.get('booking_time').hasError('required')) {
      return 'Este campo es requerido';
    }
    if (this.typeForm.get('booking_time').hasError('min')) {
      return 'El tiempo mínimo es 1 minuto';
    }
    if (this.typeForm.get('booking_time').hasError('max')) {
      return 'El tiempo no debe sobrepasar un día';
    }
  }

  /**
   * Metodo que se encarga de cambiar los estados de un selctor
   * @author Juan David Guerrero Vargas
   * @param typeCrm:string {string} tipo de CRM a habilitar
   */
  changeStateForms(typeCrm:string):void{
    let formControlEnable:string = "";
    let formControlDisable:string = "";
    switch (typeCrm) {
      case 'crm':
        formControlEnable = "id_form_cr"
        formControlDisable = "id_form_crm_two"
      break;
      case 'crm2':
        formControlEnable = "id_form_crm_two"
        formControlDisable = "id_form_cr"
      break;
    
      default:
        formControlEnable = "id_form_cr"
        formControlDisable = "id_form_crm_two"
      break;
    }

    if(formControlEnable !== ""){
      this.typeForm.get(formControlEnable).enable();
    }

    if(formControlDisable !== "" && (this.typeForm.get(formControlEnable).value != "" && this.typeForm.get(formControlEnable).value != null)  ){
      this.typeForm.get(formControlDisable).disable();
      this.typeForm.get(formControlDisable).setValue(null);
    }

    if(this.typeForm.get(formControlEnable).value == "" || this.typeForm.get(formControlEnable).value == null ){
      this.typeForm.get(formControlDisable).enable();
      this.typeForm.get(formControlDisable).setValue(null);
    }

  }

}
