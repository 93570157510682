import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupsMainComponent } from './groups-main/groups-main.component';
import { GroupsCreateComponent } from './groups-create/groups-create.component';
import { SharedModule } from '../../../../shared/shared.module';
import { ExportFiles, DocsSsummary , GroupsViewComponent } from './groups-view/groups-view.component';
import { GroupsCandidateAssignComponent } from './groups-candidate-assign/groups-candidate-assign.component';
import { TrainingCandidateComponent } from './training-candidate/training-candidate.component';
import { CandidateActionsComponent } from './candidate-actions/candidate-actions.component';
import { PrebondingComponent } from './prebonding/prebonding.component';
import { AssignUsersComponent } from './assign-users/assign-users.component';




@NgModule({
  declarations: [
    GroupsMainComponent,
    GroupsCreateComponent,
    GroupsViewComponent,
    GroupsCandidateAssignComponent,
    TrainingCandidateComponent,
    CandidateActionsComponent,
    PrebondingComponent,
    AssignUsersComponent,
    ExportFiles,
    DocsSsummary 
  ],
  imports: [
    CommonModule,
    SharedModule,
 
  ],
  entryComponents: [
  
  ],
 

})
export class TrainingGroupsModule { }
