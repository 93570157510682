import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MedicaltestService } from '../../../services/medicaltest.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MedicalTestsComponent } from '../medical-tests/medical-tests.component';
import { CreateMedicalTestComponent } from '../create-medical-test/create-medical-test.component';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { MedicalTestImportComponent } from '../medical-test-import/medical-test-import.component';
import {AuthService} from '../../../../../core/services/rest/auth.service';

@Component({
  selector: 'app-medical-candidates',
  templateUrl: './medical-candidates.component.html',
  styleUrls: ['./medical-candidates.component.sass']
})
export class MedicalCandidatesComponent implements OnInit {

  displayedColumns: string[] = ['actions', 'state','group','name','id_number','mobilephone'];

  dataSource: MatTableDataSource<any>;
  dataSourceDisabbledUsers: MatTableDataSource<any>;
  length: number;
  pageSize: number;
  currentPage = 1;
  showCandidates = false;

  identification: any = '';
  email: any = '';
  mobile: any = '';
  typeFilter:any
  userData: any;
  isNurse: boolean;

  pageSizeOptions: number[] = [10, 50, 100, 150];
  public page : number = 1;
  public per_page : number = 10;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private medicalTestServices:MedicaltestService,private alertService:AlertsService,
              private dialog:MatDialog,
              private destroyService: DestroyComponentService,
              private authService: AuthService,
              ) {
    this.userData = this.authService.decryptToken();
  }

  ngOnInit(): void {
    this.getCandidates();
    this.isNurse = this.existRol('rrhh::enfermero');
  }

  existRol(rolToFind: string): boolean {
    return this.userData.roles.includes(rolToFind);
  }

  getCandidates(){
      this.medicalTestServices.getCandidates({page: this.page, per_page: this.per_page, identification: this.identification, email: this.email, mobile: this.mobile}).subscribe( (resp:any) => {
        if (resp.total > 0) this.showCandidates = true;
        this.length = resp.total;
        this.pageSize = resp.per_page;
        this.dataSource = new MatTableDataSource(resp.data);
        this.dataSource.sort = this.sort;
      })
  };

  filterByValue(event){
    this.identification=''
    this.email=''
    this.mobile = ''

    switch (this.typeFilter) {
      case "Identificación":
        this.identification = event;
       break;
      case "Email":
        this.email= event
       break;
      case "Teléfono":
        this.mobile = event
        break;
    }
    this.getCandidates();
  }

  getPaginatorData(event){
    this.ValidateFilterParams(event);
    this.destroyService.destroyComponent();
    this.getCandidates();
  }
  medicalTests(candidate){
    const dialogMedicalTests = this.dialog.open( MedicalTestsComponent ,{
      data:{
        'candidate':candidate
      },
      minWidth:'700px',
    });
    dialogMedicalTests.afterClosed().subscribe( e => {
      this.getCandidates();
    })
  }

  createMedicalTest(candidate){
    const dialogCreateMedicalTests = this.dialog.open( CreateMedicalTestComponent ,{
      data:{
        'candidate':candidate,
        'medicalTest':null
      },
      minWidth:'700px',
    });
    dialogCreateMedicalTests.afterClosed().subscribe( e => {
      if(e){
        this.getCandidates();
      }

    })
  }

  /**
	   * valida el paginado
	   * @author Carlos Nieto
	   * @param event
	   * @returns string
	   /**/
	public ValidateFilterParams(event)
	{
    this.page =  event.pageIndex + 1,
    this.per_page = event.pageSize
	}

  /**
	  * Abre modal para la asignacion masiva de examenes medicos
	  * @author David Reinoso
	/**/
  openModalAssignmentMassive() {
    const dialogCreateMedicalTests = this.dialog.open( MedicalTestImportComponent ,{
      width:'60%',
    });
    dialogCreateMedicalTests.afterClosed().subscribe( e => {
      if(e){
        this.getCandidates();
      }
    });
  }

}
