<div class="app-clientes-proceso">

  <div class="row col-lg-12" style="margin-top: 48px;">

    <mat-form-field class="col-3" appearance="fill">
      <mat-label>Cliente</mat-label>
      <input matInput [formControl]="nameControl">
      <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
    </mat-form-field>

    <mat-form-field class="col-3" appearance="fill">
      <mat-label>Número de identificacion</mat-label>
      <input matInput [formControl]="documentControl">
      <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
    </mat-form-field>

    <mat-form-field class="col-3" appearance="fill" *ngIf="element == true">
      <mat-label>Tipo de identificacion</mat-label>
      <mat-select [formControl]="typeControl">
        <mat-option value="">Todos</mat-option>
        <mat-option value="0">Nacional</mat-option>
        <mat-option value="1">Internacional</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-3" appearance="fill" *ngIf="element == true">
      <mat-label>Comercial asignado</mat-label>
      <mat-select [formControl]="comercialControl">
        <mat-option value="">Todos</mat-option>
        <mat-option *ngFor="let item of arraycomercial" [value]="item?.rrhh_id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <br><br>
    <mat-form-field class="col-3" appearance="fill" *ngIf="element == true">
      <mat-label>Linea de servicio</mat-label>
      <mat-select [formControl]="lineServiceControl">
        <mat-option value="">Todos</mat-option>
        <mat-option *ngFor="let item of arrayservicios" [value]="item?.name">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-3" appearance="fill" *ngIf="element == true">
      <mat-label>Estado</mat-label>
      <mat-select [formControl]="stateControl">
        <mat-option value="">Todos</mat-option>
        <mat-option *ngFor="let item of stateData" [value]="item?.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-3" appearance="fill" *ngIf="element == true">
      <mat-label>Canales</mat-label>
      <mat-select [formControl]="channelControl">
        <mat-option value="">Todos</mat-option>
        <mat-option *ngFor="let item of channelsOptions" [value]="item?.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="col-3">
      <button mat-raised-button type="submit" color="light" class="filterButton w-100" (click)="showData()">
        {{element == true ? 'Ocultar filtros' : 'Mostrar filtros'}}<i class="fi-rr-filter" style="margin-left: 10px;"></i>
      </button>
    </div>
    <div class="col-3">
      <button mat-raised-button style="height:48px;font-weight: 700;" color="primary" class="w-100" (click)="downloadReport()">
        Descargar Gestión<i class="fi-rr-download" style="margin-left: 10px;"></i>
      </button>
    </div>

  </div>
  <!-- Tabla de oportunidades en proceso -->
  <div class="col-12" style="margin-top: 30px;">
    <table class="col-12" mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="actions">
        <th class="start" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button (click)="manageOpportunities(row?.client_id,row?.id)">
            <i class="fi-rr-eye"></i>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="cliente">
        <th mat-header-cell *matHeaderCellDef class="compacStyle"> Cliente </th>
        <td mat-cell *matCellDef="let row" class="compacStyle" [matTooltip]="row?.nameClient" [matTooltipPosition]="'before'">
          {{row?.nameClient}}
        </td>
      </ng-container>

      <ng-container matColumnDef="documento" class="compacStyle">
        <th mat-header-cell *matHeaderCellDef class="compacStyle"> Identificación </th>
        <td mat-cell *matCellDef="let row" [matTooltip]="row?.id_number" [matTooltipPosition]="'before'">
          {{row?.id_number}}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="compacStyle"> Nombre de la <br> oportunidad </th>
        <td mat-cell *matCellDef="let row" class="compacStyle" [matTooltip]="row?.name_oportunity" [matTooltipPosition]="'before'">
          {{row?.name_oportunity}}
        </td>
      </ng-container>

      <ng-container matColumnDef="comercial">
        <th mat-header-cell *matHeaderCellDef class="compacStyle"> Comercial <br> asignado </th>
        <td mat-cell *matCellDef="let row" class="compacStyle" [matTooltip]="row?.comercial" [matTooltipPosition]="'before'">
          {{row?.comercial}}
        </td>
      </ng-container>

      <ng-container matColumnDef="linea">
        <th mat-header-cell *matHeaderCellDef class="compacStyle"> Línea de servicio </th>
        <td mat-cell *matCellDef="let row" [matTooltip]="row?.lines_names" [matTooltipPosition]="'before'" class="compacStyle">
          {{row?.lines_names| slice:0:20}} <small *ngIf="row?.lines_names > 20">...</small>
        </td>
      </ng-container>

      <ng-container matColumnDef="meses">
        <th mat-header-cell *matHeaderCellDef class="compacStyle"> Duración contrato </th>
        <td mat-cell *matCellDef="let row" class="compacStyle" [matTooltip]="row?.tiempo_contrato" [matTooltipPosition]="'before'">
          {{row?.tiempo_contrato}} {{ row?.tiempo_contrato ? (row?.tiempo_contrato > 1 ? 'meses' : 'mes') : ''}}
        </td>
      </ng-container>

      <ng-container matColumnDef="valor">
        <th mat-header-cell *matHeaderCellDef class="compacStyle"> Valor contrato </th>
        <td mat-cell *matCellDef="let row" class="compacStyle">
          {{row?.moneda ? (row?.moneda == 1 ? 'US' : 'COP') : 'COP'}} {{row?.moneda ? '$' :'$'}}{{row?.val_oportunidad || '0'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="channel">
        <th mat-header-cell *matHeaderCellDef class="compacStyle" > Canal </th>
        <td mat-cell *matCellDef="let row" class="compacStyle" [matTooltip]="row?.channel_name" [matTooltipPosition]="'before'">
          {{row?.channel_name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th class="end" mat-header-cell *matHeaderCellDef class="compacStyle"> Estado </th>
        <td mat-cell *matCellDef="let row" class="compacStyle" [matTooltip]="row?.step" [matTooltipPosition]="'before'">
          {{row?.step}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


    </table>

    <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)"
      [pageSizeOptions]="pageSizeOptions">
    </mat-paginator>
  </div>

</div>
