<button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<form mat-dialog-content class="containerDialog" [formGroup]="formularioForm">
    <h2>Nuevo formulario</h2>
    <mat-divider class="mb-4"></mat-divider>
    <mat-form-field appearance="fill">
        <mat-label>Nombre Inteligencia de Negocio</mat-label>
        <input  matInput formControlName="name" cdkFocusInitial>
        <mat-error *ngIf="formularioForm.get('name').hasError('required')">Por favor ingrese un nombre para el fomulario.</mat-error>
    </mat-form-field>
    
    <mat-form-field appearance="fill">
        <mat-label>Descripción</mat-label>
        <textarea  matInput formControlName="descripcionform"></textarea>
        <mat-error *ngIf="formularioForm.get('descripcionform').hasError('required')">Por favor ingrese una descripción para el fomulario.</mat-error>
    </mat-form-field>
   
    <div class="btns-dialog">
        <button mat-stroked-button (click)="formularioRef.close()">Cancelar</button>
        <button mat-raised-button color="primary" (click)="save()">Guardar</button>
    </div>
</form>