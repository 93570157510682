import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoachingAppComponent } from './coaching-app.component';
import { CoachingComponent } from './modules/coaching/coaching.component';
import { DetalleCoachingComponent } from './modules/coaching/components/detalle-coaching/detalle-coaching.component';
import { ReporteComponent } from './modules/reporte/reporte.component';
import { JefeInmediatoComponent } from "./modules/jefe-inmediato/jefe-inmediato.component";

const routes: Routes = [
  {path:'',component:CoachingAppComponent},
  {path:'coaching/:tab',component:CoachingComponent},
  {path:'coaching/:tab/:filter',component:CoachingComponent},
  {path:'reporte',component:ReporteComponent},
  {path:':type/:id',component:DetalleCoachingComponent},
  {path:'jefe-inmediato',component:JefeInmediatoComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoachingRoutingModule { }
