import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CiuComponent } from './ciu.component';
import { CiuRoutingModule } from './ciu-routing.module';
import { MaterialModule } from '../../shared/material/material.module';
import { AdminUsersComponent } from './modules/users/admin-users/admin-users.component';
import { AdminRolesComponent } from './modules/roles/admin-roles/admin-roles.component';
import { SaveRolComponent } from './modules/roles/save-rol/save-rol.component';
import { SaveUsersComponent } from './modules/users/save-users/save-users.component';
import { EditUsersComponent } from './modules/users/edit-users/edit-users.component';
import { BlockCopyPasteDirective } from './block-copy-paste.directive';

import { AdminGroupsComponent } from './modules/groups/admin-groups/admin-groups.component';
import { SaveGroupsComponent } from './modules/groups/save-groups/save-groups.component';
import { AdminSchedulesComponent } from './modules/schedules/admin-schedules/admin-schedules.component';
import { ConfigSchedulesComponent } from './modules/schedules/config-schedules/config-schedules.component';
import { EditSchedulesComponent } from './modules/schedules/edit-schedules/edit-schedules.component';
import { SharedModule } from '../../shared/shared.module';
import { AdminApplicationsComponent } from './modules/aplications/admin-applications/admin-applications.component';
import { SaveAplicationsComponent } from './modules/aplications/save-aplications/save-aplications.component';
import { SaveMenusComponent } from './modules/menus/save-menus/save-menus.component';
import { AdminMenusComponent } from './modules/menus/admin-menus/admin-menus.component';
import { ProfileComponent } from './modules/profile/profile.component';
import { ChangePasswordComponent } from './modules/change-password/change-password.component';
import { UserGroupPipe } from './modules/groups/save-groups/user-group.pipe';
import { ReportAdhesionComponent } from './modules/reports/report-adhesion/report-adhesion.component';
import { MassMigrationUsersComponent } from './modules/users/mass-migration-users/mass-migration-users.component';
import { ReportUsersAppComponent } from './modules/reports/report-users-app/report-users-app.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UsersReportsComponent } from './modules/users/users-reports/users-reports.component';
import { UsersReportsCreateComponent } from './modules/users/users-reports-create/users-reports-create.component';
import { UserStatusResponseComponent } from './modules/users/userStatus-response/userStatus-response.component';
import { ProfileUpdateComponent } from './modules/profile-update/profile-update.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ManageNetworkUsersComponent } from './modules/manage-network-users/manage-network-users.component';
import { NetworkUsersComponent } from './modules/manage-network-users/components/network-users/network-users.component';
import { ActivateUserFormComponent } from './modules/manage-network-users/components/network-users/dialogs/activate-user-form/activate-user-form.component';
import { ChangeNetworkUserPassComponent } from './modules/change-network-user-pass/change-network-user-pass.component';
import { UpdateSecurityQuestionsComponent } from './modules/update-security-questions/update-security-questions.component';
import { UsersListComponent } from './modules/manage-network-users/components/users-list/users-list.component';
import { UpdateNetworkUserFormComponent } from './modules/manage-network-users/components/users-list/dialogs/update-network-user-form/update-network-user-form.component';


@NgModule({
  declarations: [CiuComponent,
    AdminUsersComponent,
    EditUsersComponent,
    AdminRolesComponent,
    SaveRolComponent,
    AdminGroupsComponent,
    SaveGroupsComponent,
    AdminSchedulesComponent,
    ConfigSchedulesComponent,
    EditSchedulesComponent,
    SaveUsersComponent,
    BlockCopyPasteDirective,
    AdminApplicationsComponent,
    SaveAplicationsComponent,
    SaveMenusComponent,
    AdminMenusComponent,
    ProfileComponent,
    ChangePasswordComponent,
    UserGroupPipe,
    MassMigrationUsersComponent,
    ReportAdhesionComponent,
    UsersReportsComponent,
    UsersReportsCreateComponent,
    ReportUsersAppComponent,
    ProfileUpdateComponent,
    UserStatusResponseComponent,
    ManageNetworkUsersComponent,
    NetworkUsersComponent,
    ActivateUserFormComponent,
    ChangeNetworkUserPassComponent,
    UpdateSecurityQuestionsComponent,
    UsersListComponent,
    UpdateNetworkUserFormComponent
  ],
  imports: [
    CommonModule,
    CiuRoutingModule,
    MaterialModule,
    SharedModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxChartsModule,

  ],
  providers: [
    DatePipe
  ]
})
export class CiuModule { }
