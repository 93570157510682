<div class="row container-sh">
  <h4 class="landing-sub-tittle title-sh">
    <b *ngIf="count > 0">Mi horario laboral</b>
    <b *ngIf="count == 0">Sin horario laboral asignado</b>
  </h4>

  <div class="row">    
    <ng-container *ngFor="let schedule of weekScheduleList; let ind = index">
      <div *ngIf="schedule.current_day === true" class="col-3">
        <mat-card class="card-sh" *ngIf="schedule.current_day === true">
          <div class="row lft">
            <div>
              <div class="icon-text">
                <span *ngIf="schedule.schedule_type_id === 1">ver horario</span>
              </div>
              <i class="fi-rr-info icon-sh"
                matTooltip="Haz clic aquí"
                matTooltipPosition="above"
                *ngIf="schedule.schedule_type_id === 1"
                (click)="openCreateForm()"
                >
              </i>
              <div class="margin-div-none" *ngIf="schedule.schedule_type_id != 1">
              </div>
            </div>
    
            <!-- <popover-content class="popover-over" #myPopover [closeOnClickOutside]="true">
              <div class="row popover-wd" *ngFor="let scheduleFull of weekScheduleList; let i = index">
                <div *ngIf="schedule.schedule_type_id === scheduleFull.schedule_type_id">
                  <div class="col-12">
                    <h5 class="landing-sub-tittle">
                      <p><b>{{ scheduleFull.date | date :'EEEE'}}</b></p>
                    </h5>
                  </div>

                  <div *ngIf="scheduleFull.start_time === '00:00:00' && scheduleFull.end_time === '00:00:00'">
                    <div class="col-12">
                      <h5 class="landing-sub-tittle">
                        <span class="hour-sh hour-init">Día de descanso</span>
                      </h5>
                    </div>
                  </div>

                  <div *ngIf="scheduleFull.start_time != '00:00:00' && scheduleFull.end_time != '00:00:00'">
                    <div class="col-12">
                      <h5 class="landing-sub-tittle">
                        <b>Hora inicio</b><span class="hour-sh hour-init">{{scheduleFull.date+" "+scheduleFull.start_time | date:'shortTime'}}</span>
                      </h5>
                    </div>
                    <div class="col-12">
                      <h5 class="landing-sub-tittle last-hour-txt">
                        <b>Hora fin</b><span class="hour-sh hour-finish-pop">{{scheduleFull.date+" "+scheduleFull.end_time | date:'shortTime'}}</span>
                      </h5>
                    </div>
                  </div>
                  
                  <div class="col-12">
                    <mat-divider class="mat-divider-wd"></mat-divider>
                  </div>
                </div>
              </div>
            </popover-content> -->
          </div>
    
          <mat-card-content>
            <img  class="icon-sh-w center-sh" src="../../../../assets/images/landing-pages/ciu/{{schedule.schedule_type_key}}.png">
          </mat-card-content>
          <div class="row center-sh">
            <div class="col-12 mt-4">
              <h4 class="landing-sub-tittle">
                <b>{{schedule.schedule_type_name}}</b>
              </h4>
            </div>
            <div class="col-12">
              <h4 class="landing-sub-tittle">
                <p><b>Hora inicio</b><span class="hour-sh hour-init">{{schedule.date+" "+schedule.start_time | date:'shortTime'}}</span></p>
              </h4>
            </div>
            <div class="col-12">
              <h4 class="landing-sub-tittle last-hour-txt">
                <p><b>Hora fin</b><span class="hour-sh hour-finish">{{schedule.date+" "+schedule.end_time | date:'shortTime'}}</span></p>
              </h4>
            </div>
          </div>
        </mat-card>
      </div>
    </ng-container>    
  </div>
</div>
