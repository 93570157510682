import { DescargaComponent } from './../historial-aprobador/descarga/descarga.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SolicitudesService } from '../../../../services/rest/solicitudes.service';
import { DestroyComponentService } from '../../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import { MatDialog } from '@angular/material/dialog';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { CrearSolicitudComponent } from '../crear-solicitud/crear-solicitud.component';
import { Solicitud } from '../../../Class/solicitud';
import { AprobarSolcitudComponent } from '../aprobar-solcitud/aprobar-solcitud.component';
import { DatePipe } from '@angular/common'
import { saveAs as importedSaveAs } from 'file-saver';
import { CompaniaService } from 'src/app/modules/compras/services/rest/compania.service';
import { ArchivosService } from 'src/app/modules/compras/services/rest/archivos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gestion-aprobador',
  templateUrl: './gestion-aprobador.component.html',
  styleUrls: ['./gestion-aprobador.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GestionAprobadorComponent implements OnInit {
  companias : any[] = [];
  filteredOptionsCompania : any[] = [];
  length;
  rol=3;
  pageSize = 5;
  page = 1;
  isPage = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  newRolComponent: boolean = false;
  firstTimeTabChanged: boolean = false;
  allToApprove: boolean = false;
  filtroFecha : string = null ;
  filterCompany;

  displayedColumns: string[] = ['check','request_date', 'title', 'description','value','created_by','company','acciones'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private solicitudService: SolicitudesService,
              private destroyService: DestroyComponentService,
              private alertsService: AlertsService,
              public dialog: MatDialog,
              public datepipe: DatePipe,
              private archivoService : ArchivosService,
              private companiaService   : CompaniaService
              ) {}


   ngOnInit(): void {
     const self = this;
    this.getCompanies();
    this.getSolicitudes();
    window.addEventListener('coordinator-gestion-called',()=>{
      if(!self.firstTimeTabChanged){
        self.firstTimeTabChanged = true;
        self.dataSource.data = self.dataSource.data.map(item=>{
          item.toApprove = true;
          return item;
        })
        setTimeout(()=>{
          self.dataSource.data = self.dataSource.data.map(item=>{
            item.toApprove = false;
            return item;
          })
        },5)
      }
    })
  }

  /**
   * Consulta las companias
   */
   getCompanies() {
    this.companiaService.getCompany().subscribe(comp=>{
      this.companias = comp.data;
      this.filteredOptionsCompania = this.companias;
    });
  }

  /*
  **
  * @author Andres Giraldo
  * @createdate 2022-03-10
  * Metodo que filtra las companias segun el autocomplete
  */
  filterByCompany(company) {
    this.filterCompany  = company;
    this.getSolicitudes();
  }

   /**
  * @author Andres Giraldo
  * @createdate 2022-03-10
  * Metodo que muestra el nombre de la empresa seleccionada
  */
    displayFnCompania(id: number): string{
      if (!id) { return ''; }
      let index = this.companias.findIndex(resp => resp.id === id);
      return this.companias[index].name;

    }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que trae los roles paginados
   * @param size cantidad de roles a traer en la tabla
   * @param page pagina que va a mostrar la tabla
   */
   getSolicitudes(): void {

    if (this.dataSource.filter === ''){
      this.dataSource.filter = null;
    }
    const params = {
      isPage    : this.isPage,
      pageSize  : this.pageSize,
      page      : this.page,
      rol       : this.rol,
      date      : this.filtroFecha,
      company   : this.filterCompany,
    }
    this.solicitudService.getSoliciudesPaginate(params, true).subscribe((Response) => {
      this.dataSource.data = Response.data.map(item=>{
        item.toApprove = false;

        // Seleccionar por defecto la cotización con el menor valor
        let cheapestCotization : number | string = 'N/A';
        let cheapestCotizationId = null;

        item.cotizations = (item.cotizations || []).map((cotization)=>{
          if(cotization.value)
            if((typeof cheapestCotization != 'number' && cotization.value) || (Number(cotization.value) < Number(cheapestCotization))){
              cheapestCotization = Number(cotization.value);
              cheapestCotizationId  = cotization.id;
            }
          return cotization;
        })

        if(cheapestCotization != 'N/A')
          item.cheapestCotization =`$${cheapestCotization.toLocaleString()}`.replace(',','.');
          else item.cheapestCotization = cheapestCotization;

        if(cheapestCotizationId)
          item.cotizations = (item.cotizations || []).map((c) =>{
            c.state = c.id == cheapestCotizationId;
            return c;
          })
        else if(item.cotizations[0]) item.cotizations[0].state = true;
        // FIN Seleccionar por defecto la cotización con el menor valor

        return item;
      }).sort((a, b) => {
        if (Number(a.id) > Number(b.id)) {
          return -1;
        }
        if (Number(a.id) < Number(b.id)) {
          return 1;
        }
        return 0;
      });

      this.dataSource.sort = this.sort;
      this.length = Response.total;
      this.pageSize = Response.per_page;

    });

  }


  /**
  * @author Andres Giraldo
  * @createdate 2022-03-08
  * Metodo que checkea o descheckea todas las solicitudes
  */
   setAllToApprove(toApprove: boolean) {
    this.allToApprove = toApprove;

    if (this.dataSource.data == null)
      return;

    this.dataSource.data = this.dataSource.data.map(item=>{
      item.toApprove = toApprove;
      return item;
    });
  }

  /**
  * @author Andres Giraldo
  * @createdate 2022-03-08
  * Metodo que devuelve si hay alguina solicitud checkeada
  */
  someToApprove(): boolean {
    if (this.dataSource.data == null) {
      return false;
    }
    return this.dataSource.data.filter(t => t.toApprove).length > 0 && !this.allToApprove;
  }

  /**
  * @author Andres Giraldo
  * @createdate 2022-03-08
  * Metodo que devuelve si hay alguina solicitud checkeada
  */
  anyToApprove(): boolean {
    if (this.dataSource.data == null) {
      return false;
    }
    return this.dataSource.data.filter(t => t.toApprove).length > 0;
  }

  /**
  * @author Andres Giraldo
  * @createdate 2022-03-07
  * Metodo que checkea si todas las solicitudes estan checkeadas
  */
  updateAllToApprove(row) {
    const self =  this;
    setTimeout(()=>{
      row.toApprove =  !row.toApprove ;
      self.allToApprove = self.dataSource.data != null && self.dataSource.data.every(t => t.toApprove);
    },100)
  }

  /**
  * @author Andres Giraldo
  * @createdate 2022-03-07
  * Metodo que descarga archivo de cotizacion
  */
  downloadFile(e: any) {
    const archivo = {
      path: e.path
    };

    this.archivoService.downloadOrder(archivo).subscribe( (data) => {
      const a = document.createElement('a');
      const file = new Blob([data], {type: e.fileContentType});
      a.href = URL.createObjectURL(file);
      a.download = e.name;
      a.click();
    });
  }


  /**
  * @author Andres Giraldo
  * @createdate 2022-03-08
  * Metodo que devuelve la cotización seleccionada de una solicitud
  */
  getSelectedCotization(row) {
    const cheapestCotization = row.cotizations.find((cotization)=>cotization.state)

    if(cheapestCotization?.value)
      return this.formatNumber(cheapestCotization.value);
    else return 'N/A';
  }

  /**
  * @author Andres Giraldo
  * @createdate 2022-03-08
  * Metodo que actualiza la cotización seleccionada de una solicitud
  */
   updateSelectedCotization(element,cotization) {
    element.cotizations = element.cotizations.map(c =>{
      c.state = c.id == cotization.id;
      return c;
    })
  }


   /**
  * @author Andres Giraldo
  * @createdate 2022-03-09
  * Metodo que llama a la api para rechazar las solicitudes, o aceptarlas y seleccionar la cotización
  */
    gestion(approved : boolean, selected = null, observation = '') {
      const action = approved
        ? selected ? 'ha <strong>aprobado</strong>' : 'han <strong>aprobado</strong>'
        : selected ? 'ha <strong>rechazado<strong>' : 'han <strong>rechazado</strong>'
      const action2 = approved ? '<strong>aprobar</strong>' : '<strong>rechazar</strong>';
      const subject = selected ? 'esta solicitud' : 'estas solicitudes';
      const subject2 = selected ? 'a solicitud seleccionada' : 'as solicitudes seleccionadas';

      try {
        const state = approved ? 4 : 5;

        Swal.showLoading()
        const requestsToApprove = this.dataSource.data.filter(({toApprove,id})=>{
          if(selected)
            return selected == id;
          else return toApprove;
        }).map(request=>{
          const selectedCotization = request.cotizations.find(({state})=>state);
          return {id:request.id,cotization:selectedCotization?.id}
        });

        this.solicitudService.massiveRequestApproveOrDecline({
          state,
          requests:requestsToApprove,
          observation
        }).subscribe(r=>{
          Swal.hideLoading()
          this.getSolicitudes();

          this.alertsService.alertSuccess('¡Excelente!',`Se ${action} ${subject} satisfactoriamente.`,{width: 450});
        },err=>{
          this.alertsService.alertError('Error',`Error al intentar ${action2} l${subject2}`, {width: 450});
          this.getSolicitudes();
          Swal.hideLoading()
        });
      } catch (error) {
        console.error({error})
        Swal.hideLoading()
        this.alertsService.alertError('Error',`Error al intentar ${action2} l${subject2}`, {width: 450});

      }


    }

    /**
  * @author Andres Giraldo
  * @createdate 2022-03-09
  * Metodo que llama a la api para rechazar las solicitudes, o aceptarlas y seleccionar la
  */
     masiveSubmit(approved : boolean, selected = null) {
      try {
        const action = approved ? '<strong>aprobar</strong>' : '<strong>rechazar</strong>'
        const inputPlaceholder = approved ? 'Observaciones' : 'Motivo de rechazo'
        const subject = selected ? 'esta solicitud' : 'estas solicitudes'
        const respuesta = approved ? 'Por favor ingrese una observación' : 'Indique el motivo del rechazo'


        Swal.fire({
          icon:'warning',
          title:'¿Estas Seguro?',
          html:`¿En verdad deseas ${action} ${subject}? <br> Recuerda que puedes editar las solicitudes en el histórico.` ,
          showCancelButton: true,
          showCloseButton: true,
          reverseButtons: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: `Cancelar`,
          width: 650,
          input: 'text',
          confirmButtonColor: '#00ACC1',
          inputValidator: function(value) {
            if(value === '') {
              return respuesta
            }},
          inputAttributes: {
            autocapitalize: 'off',
            placeholder:inputPlaceholder,
            class:'alert-input',
            autofocus:'false',
            required: 'true'
          },
          customClass: {
            confirmButton: 'mat-raised-button mat-primary alert-btn',
            cancelButton: 'mat-stroked-button mat-primary mat-button-disabled alert-btn'
          },
          buttonsStyling: false,
          onOpen:()=> Swal.getInput().blur(),
          preConfirm: (observation) => {
           return  this.gestion(approved,selected,observation);
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        })
      } catch (error) {
        console.error({error})
      }


    }

 /**
  * @author Jose Vicente Silva
  * @createdate 2021-02-04
  * Metodo que realiza el filtro
  * @param event datos para filtrar que son enviados desde el input
  */
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filtroFecha  = this.datepipe.transform(filterValue, 'yyyy-MM-dd');
    this.getSolicitudes();

    // this.solicitudService.searchApplications(this.pageSize, this.page, filterValue).subscribe((Response) => {
    //   this.dataSource.data = Response.data.data;
    //   this.dataSource.sort = this.sort;
    //   this.length = Response.data.total;
    //   this.pageSize = Response.data.per_page;
    // });
  }


  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que se encarga del movimiento del paginador
   * @param event cuando se da click en el paginador trae este evento
   */
  pageEvent(event: any): void {

    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getSolicitudes();

  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que abre el modal de crear un nuevo rol, al cerral el modal se refresca la tabla
   */
  newSolicitud(): void{
    const dialogNewRol = this.dialog.open(CrearSolicitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Crear Solicitud',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.pageSize=5;
      this.ngOnInit();
    });

  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que abre el modal de editar usuario, envia un usuario, al cerrar el modal se refresca la tabla
   * @param rol rol a editar
   */
  editSolicitud(solicitud: Solicitud): void{
    const dialogNewRol = this.dialog.open(AprobarSolcitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Detalles solicitud',
        coordinador: true,
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.pageSize =5;
      this.ngOnInit();
    });
  }

  formatNumber(number){
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      minimumFractionDigits: 2,
      currency: 'USD'
    })
    return formatter.format(number)
  }

  viewCampaign(){

  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }


  descargaGestion(){
    this.dialog.open( DescargaComponent , {
      width : '60%',
      data:{
        type : "solicitud_aprobador"
      }
    } )

    /*
    this.solicitudService.downloadRequest( this.rol ).subscribe( resp => {
      importedSaveAs(resp, 'reporte');
    });
    */

  }

  /**
  * @author Andres Giraldo
  * @createdate 2022-03-10
  * Metodo que filtra las companias segun el autocomplete
  */
   filterCompanies(v){
    const filterValue = v.toLowerCase();
    this.filteredOptionsCompania  = this.companias.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
}
