import { Component, ChangeDetectorRef, HostListener, Inject, OnInit, NgZone, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { StrategyServersService } from '../../../services/strategy.service';

@Component({
  selector: 'app-strategy-modal',
  templateUrl: './strategy-modal.component.html',
  styleUrls: ['./strategy-modal.component.sass']
})
export class StrategyModalComponent implements OnInit {

  @Input() showCloseIcon: boolean = true; //manejo ocultar la x para cerrarlo
  @Input() selectedChannel: number | null = null; //manejo preseleccione el canal segun el casos

  strategyForm: FormGroup;
  mostrarContenido: boolean = false;
  items: string[] = [];
  modo: 'crear' | 'editar' | 'visualizar';
  chips: any[] = [];
  selectedChips: any[] = [];
  selectedText: string = '';
  currentSectionIndex: number = 0;
  listChannel: any;
  tagMode: 'recomendadas' | 'personalizadas' = 'recomendadas';
  tagModeSelected: boolean = false;
  private punctuationPattern = /[.,\/#!^&*;:{}=\-_`~()¡¿!?'"|]/g
  private excludedWords = new Set([
    "el", "la", "los", "las", "un", "una", "unos", "unas", "este", "ese", "aquel", "esta", "esa", "es", "si",
    "estos", "esos", "estas", "esas", "con", "que", "por", "el", "ella", "ellos", "ellas", "nos", "os",
    "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "ñ", "o", "p", "q", "r", "s", "t",
    "u", "v", "w", "x", "y", "z", "ab", "ac", "ad", "ae", "af", "ag", "ah", "ai", "aj", "ak", "al", "am",
    "an", "ao", "ap", "aq", "ar", "as", "at", "au", "av", "aw", "ax", "ay", "az", "ba", "be", "bi", "bo",
    "bu", "ca", "ce", "ci", "co", "cu", "da", "de", "di", "do", "du", "ea", "ee", "ei", "eo", "eu", "fa",
    "fe", "fi", "fo", "fu", "ga", "ge", "gi", "go", "gu", "ha", "he", "hi", "ho", "hu", "ia", "ie", "ii",
    "io", "iu", "ja", "je", "ji", "jo", "ju", "ka", "ke", "ki", "ko", "ku", "la", "le", "li", "lo", "lu",
    "ma", "me", "mi", "mo", "mu", "na", "ne", "ni", "no", "nu", "ña", "ñe", "ñi", "ño", "ñu", "oa", "oe",
    "oi", "oo", "ou", "pa", "pe", "pi", "po", "pu", "qa", "qe", "qi", "qo", "qu", "ra", "re", "ri", "ro",
    "ru", "sa", "se", "si", "so", "su", "ta", "te", "ti", "to", "tu", "ua", "ue", "ui", "uo", "uu", "va",
    "ve", "vi", "vo", "vu", "wa", "we", "wi", "wo", "wu", "xa", "xe", "xi", "xo", "xu", "ya", "ye", "yi",
    "yo", "yu", "za", "ze", "zi", "zo", "zu"
  ]);



  constructor( 
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<StrategyModalComponent>,
    private strategyService: StrategyServersService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private zone: NgZone
  ) { }

  ngOnInit(): void {
    this.formControl();
    this.agregarSeccion();
    this.ListChannel();

    this.modo = this.data.modo;
    const strategyId = this.data.id; 
    
    if (this.selectedChannel != null) {
      this.strategyForm.get('channel').setValue([this.selectedChannel]);
    }

    if (this.modo === 'visualizar') {
      this.strategyForm.disable();
      this.loadStrategy(strategyId);
    } else if (this.modo === 'editar') {
      this.loadStrategy(strategyId);
    }

    document.addEventListener('mouseup', () => this.getSelectedText(this.currentSectionIndex));
    this.strategyForm.get('channel').valueChanges.subscribe(); //observar el valor del canal
  }

  ngOnDestroy() {
    document.removeEventListener('mouseup', () => this.getSelectedText(this.currentSectionIndex));
  }

  /**
   * Método donde se establecen las validaciones del formulario y adviarte antes de refrescar 
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @method unloadNotification
   * @param {Event} $event - El evento `beforeunload` del navegador.
   * @returns {void}
  */

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.strategyForm.dirty) {
      $event.returnValue = true;
    }
  }

  /**
   * Método donde se establecen las validaciones del formulario.
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
   */
  formControl(): void {
    this.strategyForm = this.formBuilder.group({
      name: ['', Validators.required],
      channel: ['', Validators.required],
      sections: this.formBuilder.array([]),
    });
  }

  /**
   * Metodo para cargar la lista de estartegias 
   * @createdate 2024-04-08
   * @author Yeison Sepulveda
  */
  ListChannel(): void {
    this.strategyService.listChannel().subscribe(
      (response) => {
        this.listChannel = response.data;
      },
      (error) => {
        console.error('Error al cargar:', error.message);
      }
    );
  }

  normalizeText(text: string): string {
    //return text.trim().toLowerCase().replace(this.punctuationPattern, '');
    return text.trim().toLowerCase();
  }
    
  /**
    * Convertir el texto a minúsculas, eliminar caracteres de puntuación y luego dividir el texto en palabras para convertirlos en chips
    * @author Yeison Sepulveda
    * @createdate 2024-04-08
  */
  
  createChips(value: string, indexSection: number) {
    const trimmedValue = value.trim();

    const numberPattern = /(\d+(?:[.,]\d+)?)/g;
    let numbers: string[] = [];
    let protectedValue = trimmedValue.replace(numberPattern, (match) => {
        numbers.push(match);
        return `NUMBER${numbers.length - 1}`;
    });

    protectedValue = protectedValue.toLowerCase().replace(/[.,\/#!^&*;:{}=\-_`~()¡¿!?'"|]/g, "");

    protectedValue = protectedValue.replace(/number(\d+)/g, (match, index) => numbers[index]);

    const words = protectedValue.split(/\s+|\n/);

    const validWords = words.filter(word => word.trim() !== "" && word.length > 1 && !this.excludedWords.has(word));
    const existingChips = this.chips[indexSection].filter(chip => validWords.includes(chip.text));

    const newChips = validWords
      .filter(word => !this.chips[indexSection].some(chip => chip.text === word))
      .map(word => ({ text: word, selected: false }));

    this.chips[indexSection] = [...existingChips, ...newChips];
  }

  /**
   * Obtener las palabras de los chip seleccionados
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
   */

  chipSelection(chip: any, indexSection: number) {
    // Comprobar si el chip ya está seleccionado en otra sección
    const alreadySelected = this.selectedChips.some((section, index) => index !== indexSection && section.some(selectedChip => selectedChip.text === chip.text));

    if (!alreadySelected) {
      chip.selected = !chip.selected;
      if (chip.selected) {
        this.selectedChips[indexSection].push(chip);
      } else {
        this.selectedChips[indexSection] = this.selectedChips[indexSection].filter(c => c !== chip);
      }

        // Validar si hay chips seleccionados o no
        this.chips[indexSection].forEach((chip: any) => {
            const etiquetaSeleccionada = this.selectedChips[indexSection].find((selectedChip: any) => selectedChip.text === chip.text);
            if (etiquetaSeleccionada) {
                chip.selected = true;
            }
        });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Esta etiqueta ya está seleccionada en otra sección.',
        confirmButtonText: '¡Entendido!',
        confirmButtonColor: '#2CABBC',
      });
    }
  }
  

  /**
   * Metodo que se encarga de eliminar los chips seleccionados
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
   * @param chipDeleted:any {text:"", selected:true|false}
   * @param indexSection:number indice de la seccion
  */
  removeWordSelected(chipDeleted: any, indexSection: number): void {
    // Actualiza selectedChips
    this.selectedChips[indexSection] = this.selectedChips[indexSection].filter(c => c.text !== chipDeleted.text);
    
    // Actualiza chips si es necesario
    this.chips[indexSection] = this.chips[indexSection].map(mmpp => {
      if (mmpp.text === chipDeleted.text) {
        mmpp.selected = false;
      }
      return mmpp;
    });
  }

  /**
   * Metodo para obtener las secciones 
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
  */
  get sections() {
    return this.strategyForm.get('sections') as FormArray;
  }

  /**
   * Crear una nueva seccion
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
   */
  createSection(index: number): FormGroup {
    this.chips[index] = [];
    this.selectedChips[index] = [];
    
    const section = this.formBuilder.group({
      section_name: ['', Validators.required],
      guion: ['', Validators.required],
      etiquetas: [''],
      percentage_section: ['', Validators.compose([
        Validators.min(0),
        Validators.max(100)
      ])]
    });
  
    this.strategyForm.get('channel').valueChanges.subscribe(selectedChannels => {
      const percentageControl = section.get('percentage_section');
      
      if (selectedChannels.includes(1)) {
        percentageControl.setValidators([
          Validators.required,
          Validators.min(0),
          Validators.max(100)
        ]);
      } else {
        percentageControl.clearValidators();
      }
  
      percentageControl.updateValueAndValidity();
    });
  
    return section;
  }
  
  /**
   * Agragar una seccion
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
   */
  agregarSeccion(): void {
    const index = this.sections.length;
    this.sections.push(this.createSection(index));
  }

  /**
   * Eliminar una seccion
   * @createdate 2024-04-08
   * @author Yeison Sepulveda
  */
  eliminarSeccion(index: number): void {
    if(this.sections.controls.length > 1) {
      this.sections.removeAt(index);
      this.chips.splice(index, 1);
      this.selectedChips.splice(index, 1);
    }
  }

  /**
   * Guardar estrategia
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
  */
  saveStrategy() {
    if (this.strategyForm.valid) {
      //validar si debe mostrar la sección de calificación, se valida el porcentaje total
      if (this.showRatingSection) {
        const totalPercentage = this.sections.controls
          .map(section => section.get('percentage_section').value)
          .reduce((acc, value) => acc + value, 0);
  
        if (totalPercentage !== 100) {
          Swal.fire({
            icon: 'warning',
            title: '¡Ten en cuenta!',
            text: totalPercentage > 100 
              ? 'La suma de los porcentajes de las secciones supera el 100% del total. Por favor, ajuste los valores.' 
              : 'La suma de los porcentajes de las secciones es menor al 100%. Por favor, ajuste los valores.',
            confirmButtonColor: '#2CABBC',
            confirmButtonText: '¡Entendido!',
          });
          return;
        }
      }
  
      // Mostrar confirmación y proceder con el guardado
      Swal.fire({
        title: '¿Está seguro?',
        text: 'Se va a guardar el contenido',
        icon: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#2CABBC',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          let formData = { 
            name: this.strategyForm.get('name').value,
            channel: this.strategyForm.get('channel').value,
            sections: [] 
          };
          this.sections.controls.forEach((section: any) => {
            const section_name = section.get('section_name').value;
            const guion = section.get('guion').value;
            const percentage_section = section.get('percentage_section').value;
            const selectedChipsForSection = this.selectedChips[this.sections.controls.indexOf(section)];
            const etiquetas = selectedChipsForSection.map((chip: any) => chip.text);
            formData.sections.push({ section_name, guion, etiquetas, percentage_section });
          });
          this.strategyService.createStrategy(formData).subscribe(res => {
            Swal.fire({
              title: '¡Excelente!',
              text: 'Se ha registrado una nueva estrategia',
              icon: 'success',
              confirmButtonText: 'Continuar',
              confirmButtonColor: '#2CABBC',
            }).then((result) => {
              if (result.isConfirmed) {
                this.dialogRef.close();
              }
            });
          }); 
        }
      });
    } else {
      this.strategyForm.markAllAsTouched();
    }
  }

  /**
   * Actualizar estrategia
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
  */
  updateStrategy() {
    //validar si debe mostrar la sección de calificación, se valida el porcentaje total
    if (this.showRatingSection) {
      const totalPercentage = this.sections.controls
        .map(section => section.get('percentage_section').value)
        .reduce((acc, value) => acc + value, 0);

      if (totalPercentage !== 100) {
        Swal.fire({
          icon: 'warning',
          title: '¡Ten en cuenta!',
          text: totalPercentage > 100 
            ? 'La suma de los porcentajes de las secciones supera el 100% del total. Por favor, ajuste los valores.' 
            : 'La suma de los porcentajes de las secciones es menor al 100%. Por favor, ajuste los valores.',
          confirmButtonColor: '#2CABBC',
          confirmButtonText: '¡Entendido!',
        });
        return;
      }
    }
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Se va a modificar el contenido de la estrategia',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        let formData = { 
          name: this.strategyForm.get('name').value, 
          channel: this.strategyForm.get('channel').value,
          sections: [],
          id: this.data.id };
          this.sections.controls.forEach((section: any) => {
            const section_name = section.get('section_name').value;
            const guion = section.get('guion').value;
            const percentage_section = section.get('percentage_section').value;
            const selectedChipsForSection = this.selectedChips[this.sections.controls.indexOf(section)];
            const etiquetas = selectedChipsForSection.map((chip: any) => chip.text);
            formData.sections.push({ section_name, guion, etiquetas, percentage_section });
          });
          console.log('data a enviar cuando actualizo',formData)
          this.strategyService.updateStrategy(formData).subscribe(res => {
            Swal.fire({
              title: '¡Excelente!',
              text: 'Se ha actualizado la estrategia',
              icon: 'success',
              confirmButtonText: 'Continuar',
              confirmButtonColor: '#2CABBC',
            }).then((result) => {
              if (result.isConfirmed) {
                this.dialogRef.close();
              }
            });
          }); 
      }
    });
  }

  /**
   * Metodo para consumir servicio y guardar la estrategia creada
   * @createdate 2024-04-08
   * @author Yeison Sepulveda
  */
  loadStrategy(strategyId: number): void {
    this.strategyService.getStrategyById(strategyId).subscribe(
      (resp) => {
        this.strategyForm.patchValue({
          name: resp.data.strategy_name,
          channel: JSON.parse(resp.data.channel)
        });
  
        this.chips = [];
        this.selectedChips = [];
  
        while (this.sections.length !== 0) {
          this.sections.removeAt(0);
        }
  
        const sectionsArray = JSON.parse(resp.data.sections); 
        sectionsArray.forEach((section: any, index: number) => {
          const newSection = this.createSection(index);
  
          section.etiquetas.forEach((etiqueta: string) => {
            const words = etiqueta.split(/\s+/);
            const normalizedWords = words.map(word => this.normalizeText(word));
            const normalizedText = normalizedWords.join(' ');
  
            if (!this.selectedChips[index]) {
              this.selectedChips[index] = [];
            }
  
            if (!this.selectedChips[index].some(chip => chip.text === normalizedText)) {
              this.selectedChips[index].push({ text: normalizedText, selected: true });
            }
          });
  
          newSection.patchValue({
            section_name: section.section_name,
            guion: section.guion,
            percentage_section: section.percentage_section
          });
  
          this.sections.push(newSection);
  
          //cargar los chips segun la seccion
          this.createChips(section.guion, index);
        });
      },
      (error) => {
        console.error('Error al cargar:', error);
      }
    );
  }

  /**
   * Metodo que reinicia los valores del modal. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  cancelSave(): void {
    if (this.strategyForm.dirty) {
      Swal.fire({
        title: '¿Estás seguro?',
        text: 'Si cancelas el proceso, perderás toda la información no guardada.',
        icon: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#2CABBC',
        cancelButtonColor: '#FFFFFF',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.strategyForm.reset();
          this.dialogRef.close();
        }
      });
    }else {
      this.dialogRef.close();
    }
  }

  /**
   * Cambiar modo de visualizacion para crear etiquetas
   * @author Yeison Sepulveda
   * @createdate 2024-08-01
  */
  onTagSelectionChange(selectedMode: 'recomendadas' | 'personalizadas') {
    this.tagMode = selectedMode;
    this.tagModeSelected = true; 
}


  /**
   * Obtener el texto seleccionado en la página.
   * @param indexSection - El índice de la sección donde se seleccionó el texto.
   * @author Yeison Sepulveda
   * @createdate 2024-08-01
   */
  getSelectedText(indexSection: number) {
    this.zone.run(() => {
      const selection = window.getSelection();
      if (selection && selection.toString().trim().length > 0) {
        this.selectedText = selection.toString();
      } else {
        this.selectedText = '';
      }
      this.cdr.detectChanges();
    });
  }

  /**
   * Añadir una etiqueta (chip) desde el texto seleccionado.
   * @param selectedText - texto seleccionado que se convertirá en etiqueta.
   * @param indexSection - índice de la sección donde se añadirá la etiqueta.
   * @author Yeison Sepulveda
   * @createdate 2024-08-01
   */
  addChipFromSelection(selectedText: string, indexSection: number) {
    console.log('texto', selectedText);
    if (selectedText.length > 100) {
      Swal.fire({
        title: 'Oops...',
        text: 'El texto seleccionado excede el máximo de 100 caracteres.',
        icon: 'warning',
        confirmButtonText: '¡Entendido!',
        confirmButtonColor: '#2CABBC'
      });
      return;
    }

    const words = selectedText.split(/\s+|\n/);
    let normalizedText = '';

    if (words.length === 1) {
      const word = this.normalizeText(words[0]);
      if (this.excludedWords.has(word) || word.trim().length <= 1) {
        Swal.fire({
          title: 'Oops...',
          text: 'El texto seleccionado no contiene palabras válidas.',
          icon: 'warning',
          confirmButtonText: '¡Entendido!',
          confirmButtonColor: '#2CABBC'
        });
        return;
      }
      normalizedText = word;
    } else {
      //const textWithoutPunctuation = selectedText.replace(this.punctuationPattern, '');
      const textWithoutPunctuation = selectedText;
      const validText = textWithoutPunctuation.trim();

      if (validText.length === 0) {
        Swal.fire({
          title: 'Oops...',
          text: 'El texto seleccionado no contiene palabras válidas.',
          icon: 'warning',
          confirmButtonText: '¡Entendido!',
          confirmButtonColor: '#2CABBC'
        });
        return;
      }

      normalizedText = this.normalizeText(validText);
    }

    const chip = { text: normalizedText, selected: false };

    if (!this.selectedChips[indexSection]) {
      this.selectedChips[indexSection] = [];
    }

    if (!this.selectedChips[indexSection].some(c => c.text === chip.text)) {
      this.selectedChips[indexSection].push(chip);
    } else {
      Swal.fire({
        title:'Excelente',
        text: 'Esta etiqueta ya ha sido agregada.',
        icon: 'warning',
        confirmButtonText: '¡Entendido!',
        confirmButtonColor: '#2CABBC'
      });
    }

    this.selectedText = '';
  }

  /**
   * Manejar la acción de cerrar el diálogo cuando hay cambios no guardados.
   * @author Yeison Sepulveda
   * @createdate 2024-08-01
   */
  onNoClick(): void {
    if (this.strategyForm.dirty) {
      Swal.fire({
        title: '¿Estás seguro de que deseas salir?',
        text: 'Tienes cambios que no han sido guardados.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, salir',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#2CABBC',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Determina si la sección de calificación debe mostrarse en función de los canales seleccionados.
   * @author Yeison Sepulveda
   * @createdate 2024-08-15
   * @returns 
   */
  get showRatingSection(): boolean {
    const selectedChannels = this.strategyForm.get('channel').value || [];
    return selectedChannels.includes(1);
  }
  
}

