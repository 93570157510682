import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolean'
})
export class BooleanPipe implements PipeTransform {

  transform(value: unknown): boolean {
    if (value == 1) {
      return true
    }else if (value == 0) {
      return false;
    }
  }

}
