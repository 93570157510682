<h2 mat-dialog-title>{{datos.title}}
  <button style="float: right;" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross"></i>
  </button>
</h2>

  <mat-divider></mat-divider>

  <mat-dialog-content style="padding: 3%;" class="mat-typography">
 
    <!-- Pasos de plantilla -->
    <mat-horizontal-stepper linear #stepper>
      <!-- Primer step o paso -->
      <mat-step [stepControl]="firstStep">

        <ng-template matStepLabel> Nombre plantilla</ng-template>

          <br>
          <b style="font-size: 28px;">Personaliza la plantilla</b>
          <p>Escribe el nombre que tendrá la plantilla y el caracter que separara los datos del formulario.</p>
          <br>

        <form class="row" [formGroup]="firstStep">
        
          <mat-form-field  appearance="fill" style="padding: 1%;" class="col-6" appearance="fill" >
            <mat-label>Nombre de la plantilla </mat-label>
            <input matInput formControlName="nombre" required>
            <mat-error *ngIf="errorForm1.nombre.errors"><span *ngIf="errorForm1.nombre.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            <mat-error *ngIf="errorForm1.nombre.errors"><span *ngIf="errorForm1.nombre.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
            <mat-error *ngIf="errorForm1.nombre.errors"><span *ngIf="errorForm1.nombre.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 50 caracteres</span></mat-error>
          </mat-form-field>

          <mat-form-field  appearance="fill" style="padding: 1%;" class="col-6" appearance="fill">
            <mat-label>Caracter que separa los datos  </mat-label>
            <input appBlockCopyPaste (keypress)="omitSpecialChar($event)" formControlName="caracter" matInput type="text" required >
            <mat-error *ngIf="errorForm1.caracter.errors"><span *ngIf="errorForm1.caracter.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            <mat-error *ngIf="errorForm1.caracter.errors"><span *ngIf="errorForm1.caracter.errors.minlength">¡Aún falta! Recibimos mínimo 1 caracteres</span></mat-error>
            <mat-error *ngIf="errorForm1.caracter.errors"><span *ngIf="errorForm1.caracter.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 1 caracteres</span></mat-error>
          </mat-form-field>
        
        </form>

        <mat-dialog-actions align="end">
          <button mat-raised-button type="button" mat-dialog-close>Cancelar</button>
          <button mat-raised-button color="primary" disabled='{{firstStep.invalid}}' mat-button matStepperNext >Continuar</button>
        </mat-dialog-actions>

      </mat-step>

      <!-- Segundo step o paso -->
      <mat-step [stepControl]="secondStep" >
        <ng-template matStepLabel> Campos plantilla</ng-template>

        <br>
          <b style="font-size: 28px;">Selección de campos del a plantilla</b>
          <p>Selecciona los campos que deseas que vayan en la plantilla y el caracter que encerrara ese campo.</p>
        <br>

        <form [formGroup]="secondStep">

          <div *ngFor="let section of form" class="row">

            <br><br>

            <h4>Seccion: {{section.name_section}}</h4>

            <div class="col-4"> <b>Seleccionar campo</b> </div>

            <div class="col-4">  </div>

            <div class="col-4"> <b>¿Incluir nombre del campo?</b> </div>

            <br><br>
            <div *ngFor="let item of section.fields" class="row">

              <div class="col-4"> <mat-checkbox class="example-margin" [formControlName]="item.key + 'check'">{{item.label}}</mat-checkbox></div>

              <div class="col-4">
              
                <mat-form-field  appearance="fill" style="padding: 1%;" class="col-10" appearance="fill" >
                  <mat-label>Caracter especial</mat-label>
                  <input matInput appBlockCopyPaste (keypress)="omitSpecialChar($event)" [formControlName]="item.key">
                </mat-form-field>
                
              </div>

              <div class="col-4">
                <mat-radio-group style="padding-left: 5%;" [formControlName]="item.key + 'radio'">
                  <mat-radio-button value="1" class="col-2">Si</mat-radio-button>
                  <mat-radio-button value="2" class="col-2" [checked]="true">No</mat-radio-button>
                </mat-radio-group>
             </div>

              

            </div>
            <br><br>

          </div>
          
        </form>

        <mat-dialog-actions align="end">
          <button mat-raised-button  type="button" mat-dialog-close>Cancelar</button>
          <button  mat-raised-button color="primary" mat-button matStepperNext >Continuar</button>
        </mat-dialog-actions>
      
      </mat-step>

        <!-- Tercer step o paso -->
      <mat-step [stepControl]="thirdStep">
        <ng-template matStepLabel>Campos editables</ng-template>

        <br>
          <b style="font-size: 28px;">Añade campos editables</b>
          <p>Agrega y selecciona los campos editables que quieras para el formulario y el caracter que encerrara ese campo.</p>
        <br>

        <div></div>
          
          <form class="row" [formGroup]="thirdStep">

            <div class="col-3"></div>
            <div class="col-3"></div>
            <div class="col-3"><b>¿Incluir nombre del campo?</b></div>

            <div formArrayName="fields">

              <div class="col-12" *ngFor="let field of fields.controls; let i = index">

                  <div class="row col-12" [formGroupName]="i">

                    <mat-form-field style="padding: 1%" class="col-3" appearance="fill" >
                      <mat-label>Nombre del campo</mat-label>
                      <input matInput formControlName="name">
                    </mat-form-field>

                    <mat-form-field style="padding: 1%" class="col-3" appearance="fill" >
                      <mat-label>Caracter especial</mat-label>
                      <input matInput appBlockCopyPaste (keypress)="omitSpecialChar($event)" formControlName="caracter">
                    </mat-form-field>

                    <mat-radio-group style="padding-left: 1%; padding-top: 2%;" class="col-2" formControlName="option">
                      <mat-radio-button value="1" class="col-4">Si</mat-radio-button>
                      <mat-radio-button value="2" class="col-4" [checked]="true">No</mat-radio-button>
                    </mat-radio-group>

                    <div class="col-1">
                      <button mat-icon-button (click)="deleteField(i)" style="color:#BE0F0F;font-size: 20px;"><i class="fi-rr-trash" style="position:absolute"></i></button>
                    </div>

                  </div>
                
              </div>

              <div class="col-6">
                <button mat-stroked-button type="button" class="col-4" color="primary" style="border-color: #2CABBC;" (click)="newFields()">Añadir <i class="fi-rr-add"></i></button>
              </div>

            </div>

          </form>

        <mat-dialog-actions align="end">
          <button mat-raised-button  type="button" mat-dialog-close>Cancelar</button>
          <button  mat-raised-button color="primary" mat-button (click)="guardar()">Guardar</button>
        </mat-dialog-actions>
      </mat-step>

    </mat-horizontal-stepper>

  </mat-dialog-content>




 
              
