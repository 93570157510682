import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CalendarOptions } from '@fullcalendar/angular';
import * as moment from 'moment';
import { delay } from 'rxjs/operators';
import { NoveltiesService } from 'src/app/modules/nomina/services/novelties.service';
import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import { VacationsService } from '../../../../services/rest/vacations.service';
import esLocale from '@fullcalendar/core/locales/es';

@Component({
  selector: 'app-reverse-vacations',
  templateUrl: './reverse-vacations.component.html',
  styleUrls: [ './reverse-vacations.component.sass' ]
})
export class ReverseVacationsComponent implements OnInit {

  public vacation: any;
  public noveltyDetailHeight: number;

  public showJustifyForm: boolean;
  public showJustifyInfo: boolean;
  public showInput: boolean;
  vacationsForm: FormGroup;

  options: any = [
    { id: 'modify', name: 'Nuevas fechas' },
    { id: 'delete', name: 'Cancelar solicitud' }
  ];
  public calendarOptions: CalendarOptions = {};
  public availableDays: number;
  public businessDays: number;
  public validDates: boolean;
  public noveltyTypes: any[] = [];
  public noveltyTypesOld: any[] = [];
  public showCalendar: boolean;
  public vacationCode: number;
  public inactivesInCurrentYear: any[] = [];
  currentDate;
  public sending: boolean;
  public minDate;

  constructor(
    public dialogRef: MatDialogRef<ReverseVacationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alert: AlertsService,
    public dialog: MatDialog,
    private vacationService: VacationsService,
    private noveltiesService: NoveltiesService,
  ) {
  }

  ngOnInit() {
    this.currentDate = new Date();
    let dateAux = moment(this.currentDate, 'YYYY-MM-DD').add(2, 'days');
    this.minDate = moment(dateAux).locale('es').format('YYYY-MM-DD');
    this.vacation = this.data.data;
    this.vacationsForm = new FormGroup({
      action: new FormControl('', [ Validators.required ]),
      startDate: new FormControl('', [ Validators.required ]),
      endDate: new FormControl('', [ Validators.required ]),
      observation: new FormControl('', [ Validators.required ])
    });
    this.vacationsForm.controls.action.valueChanges.subscribe(resp => {
      if(resp == 'delete'){
        this.vacationsForm.controls.startDate.setValidators([]);
        this.vacationsForm.controls.startDate.updateValueAndValidity();
        this.vacationsForm.controls.endDate.setValidators([]);
        this.vacationsForm.controls.endDate.updateValueAndValidity();
        //this.vacationsForm.controls.action.setValue('');
        // this.showCalendar = false;
      }else if(resp == 'modify'){
        this.vacationsForm.controls.startDate.setValidators([Validators.required]);
        this.vacationsForm.controls.startDate.updateValueAndValidity();
        this.vacationsForm.controls.startDate.setValue('');        
        this.vacationsForm.controls.endDate.setValidators([Validators.required]);
        this.vacationsForm.controls.endDate.updateValueAndValidity();
        this.vacationsForm.controls.endDate.setValue('');
        // this.showCalendar = true;
      }
    });

    this.vacationsForm.controls.endDate.valueChanges.subscribe(resp => {
      if(resp){
        this.getCalendarWithAvailableDays();
      }

    });
  }

  /**
   * @author Javier Castañeda
   * @createdate 2021-04-27
   * Metodo para asignacion de detalle en primera instancia
   */
  changeDetailHeight(novelty): void {
    if (novelty.first_response && (novelty.first_response.justify || novelty.first_response.response)) {
      this.noveltyDetailHeight = 240;
    }
  }


  /**
   * @author Javier Castañeda
   * @createdate 2021-04-29
   * Metodo Aprobar o rechazar novedad
   */
  updateVacationToReverse() {
    const state = this.vacationsForm.controls.action.value;
    if(state === 'modify'){
      if (this.inactivesInCurrentYear[moment(this.vacationsForm.controls.startDate.value).locale('es').format('YYYY-MM-DD')]) {
        this.alert.alertWarning('Proceso detenido', 'La fecha inicial no empezar en un día no hábil, por favor seleccione una fecha diferente');
        this.sending = false;
        return;
      }
  
      if (this.inactivesInCurrentYear[moment(this.vacationsForm.controls.endDate.value).locale('es').format('YYYY-MM-DD')]) {
        this.alert.alertWarning('Proceso detenido', 'La fecha final no puede terminar en un día no hábil, por favor seleccione una fecha diferente');
        this.sending = false;
        return;
      }
  
      // validamos que para pedir las vacaciones sea a partir del dia siguiente
      if (moment(this.vacationsForm.controls.startDate.value).locale('es').format('YYYY-MM-DD') <= moment(this.currentDate).format('YYYY-MM-DD')) {
        this.alert.alertWarning('Proceso detenido', 'Solo puede solicitar vacaciones a partir del día siguiente');
        this.sending = false;
        return;
      }
  
      if (!this.validDates) {
        this.alert.alertWarning('Proceso detenido', 'Verifique las fechas de su solicitud de vacaciones');
        this.sending = false;
        return;
      }
    }


    this.vacationService.requestReverseVacation(this.vacation.idHoliday, this.vacationsForm.value).subscribe(resp => {
      if (state === 'modify') {
        this.alert.alertSuccess('Novedad actualizada!', 'La solicitud con las nuevas fechas ha sido creada, por favor espere respuesta por parte de nómina');
        this.dialogRef.close('');
      } else {
        this.alert.alertSuccess('Novedad actualizada!', 'La solicitud se ha creado correctamente');
        this.dialogRef.close('');
      }

    }, error => {
      this.alert.alertError('Error!', 'Ocurrio un error al intentar actualizar la novedad');
    });
  }

  /**
   * Obtiene el calendario con los dias habiles para la solicitud vacaciones
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   */
   getCalendarWithAvailableDays(): void {
     let formData = new FormData();
     formData.append('startDate', moment(this.vacationsForm.controls.startDate.value).locale('es').format('YYYY-MM-DD') );
     formData.append('endDate', moment(this.vacationsForm.controls.endDate.value).locale('es').format('YYYY-MM-DD'));
     formData.append('vacationId', this.data.data.idHoliday );
    this.vacationService.getAvailableDaysForVacations(formData).pipe(
      delay(1000)
    ).subscribe((respDates) => {
      this.validDates = respDates.data.validDates;
      this.availableDays = respDates.data.availableDays < 0 ? 0 : respDates.data.availableDays;
      this.businessDays = respDates.data.businessDays;
      // configure calendar options
      this.calendarOptions = {
        initialView: 'dayGridMonth',
        locales: [esLocale],
        locale: 'es',
        headerToolbar: {
          left: 'title',
          center: '',
          right: 'prev,next'
        },
        titleFormat: {
          month: 'long',
          year: 'numeric'
        },
        events: respDates.data.dates,
        handleWindowResize: true,
        windowResizeDelay: 200
      };
      if(this.vacationsForm.controls.action.value == 'modify'){
        this.showCalendar = true;
      }else{
        this.showCalendar = false;
      }
      
    });
  }
}
