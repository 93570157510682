import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const PQRS_URL = environment.PQRS_URL;
const CRM_URL = environment.CRM2_URL;
const GESTION_CORREOS = environment.GESTION_CORREOS;

@Injectable({
  providedIn: 'root'
})
export class EscalationService {

  constructor(private http: HttpClient) { }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * Metodo que trae los canales de escalamiento
   * @returns canales
   */
  getCanal(): Observable<any>{
    return this.http.get<any>(`${PQRS_URL}canal/`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * Metodo que trae las areas de escalamiento
   * @returns areas
   */
  getArea(): Observable<any>{
    return this.http.get<any>(`${PQRS_URL}area/`)
    .pipe(
      catchError(this.handleError)
      );
  }

  getAreaAndUsers(idForm, idCampania): Observable<any>{
    return this.http.get<any>(`${PQRS_URL}area/list-with-responsible/${idForm}/${idCampania}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * Metodo que trae los asuntos de escalamiento
   * @returns asuntos
   */
  getAsunto(): Observable<any>{
    return this.http.get<any>(`${PQRS_URL}asunto/`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * Metodo que trae los estados del escalamiento
   * @returns estados
   */
  getEstado(): Observable<any>{
    return this.http.get<any>(`${PQRS_URL}estado/`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * Metodo que trae los canales de escalamiento
   * @returns canales
   */
  getUsers(): Observable<any>{
    return this.http.get<any>(`${PQRS_URL}usuario/solucionadores`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * Metodo que trae los datos de la grilla
   * @returns datos a mostrar en la grilla
   */
  getUsersGrid(perPage, page): Observable<any>{
    return this.http.get<any>(`${PQRS_URL}PQRS?page=${page}&perPage=${perPage}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * Metodo que trae los datos filtrados a mostrar en la grilla
   * @returns datos de grilla
   */
  getFilter(data: any, perPage, page): Observable<any>{
    return this.http.post<any>(`${PQRS_URL}PQRS/avanzada?page=${page}&perPage=${perPage}`, data)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-17
   * Metodo que trae el historico de una pqrs
   * @param idPqrs identificador de la pqrs
   * @param size tamaño de datos a traer
   * @param page pagina a traer
   * @returns retorna el historico de datos
   */
  getHistory(idPqrs: number, size: number, page: number): Observable<any>{
    return this.http.get<any>(`${PQRS_URL}observacion/showByPqrsId/${idPqrs}?&n=${size}&page=${page}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-17
   * Metodo que trae los usuarios por area seleccionada
   * @param id identificador del area
   * @returns retorna una lista de usuarios
   */
  getUserbyArea(id: number): Observable<any>{
    return this.http.get<any>(`${PQRS_URL}PQRS/showSolucionadoresByArea/${id}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-17
   * Metodo que inserta la edicion del formulario de un escalamiento
   * @param data data a enviar
   * @param id identificador de un pqrs
   * @returns retorna un estado
   */
  postFormEscalation(data: FormData, id: number): Observable<any>{
    return this.http.post<any>(`${PQRS_URL}PQRS/${id}`, data)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que se encarga de traer los datos de un estado
   * @param size tamaño de los datos a traer
   * @param page pagina a traer
   * @returns lista de estados
   */
  getState(size: number, page: number): Observable<any>{
    return this.http.get<any>(`${PQRS_URL}estado/indexAll?&n=` + size + `&page=` + page)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que se encarga de guardar un estado
   * @param data data a guardar
   * @returns mensaje de exito
   */
  putFormState(data: any): Observable<any>{
    return this.http.put<any>(`${PQRS_URL}estado`, data)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que se encarga de editar un estado
   * @param id id del estado
   * @param data data del estado
   * @returns mensaje de exito
   */
  editState(id: any, data: any): Observable<any>{
    return this.http.put<any>(`${PQRS_URL}estado/${id}`, data)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * servicio encargado de inactivar o activar un estado
   * @param id id del estado
   * @param data true o false
   * @returns mensaje de exito
   */
  updateState(id: any, data: any): Observable<any>{
    return this.http.post<any>(`${PQRS_URL}estado/${id}/updateState`, data)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Servicio que trae los datos de un asunto
   * @param size tamaño de datos a traer
   * @param page pagina a traer
   * @returns lista de asuntos
   */
  getAsuntoIndex(size: number, page: number): Observable<any>{
    return this.http.get<any>(`${PQRS_URL}asunto/indexAll?&n=` + size + `&page=` + page)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Servicio encargado de activar o inactivar un asunto
   * @param id id del asunto a activar
   * @param data true o false
   * @returns mensaje de exito
   */
  updateAsunto(id: any, data: any): Observable<any>{
    return this.http.post<any>(`${PQRS_URL}asunto/${id}/updateState`, data)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Servicio que trae los supervisores dependiendo del area
   * @param id id del area
   * @returns lista de supervisores
   */
  getSupervisorByArea(id: any): Observable<any>{
    return this.http.get<any>(`${PQRS_URL}PQRS/showSupervisoresByArea/${id}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Servicio que guarda un asunto
   * @param asunto asunto a guardar
   * @returns mensaje de exito
   */
  postAsunto(asunto: any): Observable<any>{
    return this.http.put<any>(`${PQRS_URL}asunto`, asunto)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Servicio que edita un asunto
   * @param id id del asunto a editarr
   * @param asunto contenido del asunto
   * @returns mensaje de exito
   */
  postEditAsunto(id: any, asunto: any): Observable<any>{
    return this.http.put<any>(`${PQRS_URL}asunto/${id}`, asunto)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Servicio que lista usuarios
   * @param size tamaño de datos a listar
   * @param page pagina a listar
   * @returns lista de usuarios
   */
  getUsuario(size: number, page: number): Observable<any>{
    return this.http.get<any>(`${PQRS_URL}usuario?&n=` + size + `&page=` + page)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Servicio que edita un usuario
   * @param id id del usuario
   * @param data data a editar
   * @returns mensaje de exito
   */
  updateUsuario(id: any, data: any): Observable<any>{
    return this.http.post<any>(`${PQRS_URL}usuario/${id}/updateState`, data)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Servicio que edita un usuario
   * @param id id del usuario
   * @param data data a editar
   * @returns mensaje de exito
   */
  editUsuario(id: any, data: FormData): Observable<any>{
    return this.http.post<any>(`${PQRS_URL}usuario/${id}/attachArea`, data)
    .pipe(
      catchError(this.handleError)
      );
  }

  createPqrs(data: FormData): Observable<any>{
    return this.http.post<any>(`${CRM_URL}escalations`, data)
    .pipe(
      catchError(this.handleError)
      );
  }

    /**
   * @author Javier Castañeda
   * @createdate 2022-06-16
   * Servicio para obtener correo de un escalamiento
   * @param id id del email del escalamiento
   * @returns datos del correo y adjuntos
   */
    getEmailPQRS(id: any): Observable<any>{
      return this.http.get<any>(`${PQRS_URL}email/${id}`)
      .pipe(
        catchError(this.handleError)
        );
    }

     /**
   * @author Juan Carlos Alonso
   * @createdate 2022-07-07
   * Servicio para descargar adjunto de los correos
   * @param url del archivo en storage
   * @returns descarga del archivo
   */
      downloadEmailAttachment(data: any): Observable<any>{
        const httpOptions = {responseType: 'blob' as 'json'};
        return this.http.get<any>(`${PQRS_URL}email/descargarAdjunto?url=${data.url}`, httpOptions)
        .pipe(
          catchError(this.handleError)
          );
      }
  
      /**
   * @author Juan Carlos Alonso
   * @createdate 2022-07-08
   * Servicio para guardar adjunto de pqrs
   * @returns descarga del archivo
   */
  savePqrsAttachment(data: FormData): Observable<any>{
    return this.http.post<any>(`${PQRS_URL}PQRS/adjuntarArchivoPqrs`, data)
    .pipe(
      catchError(this.handleError)
      );
  }

    /** 
   * @author Juan Carlos Alonso
   * @createdate 2022-07-08
   * Servicio para obtener los adjuntos del escalamiento
   * @param pqrs_id id del escalamiento para obtener los adjuntos correspondientes
   * @returns arreglo con los adjuntos
   */
     getPqrsAttachments(pqrs_id: any): Observable<any>{
      return this.http.get<any>(`${PQRS_URL}PQRS/obtenerAdjuntosPqrs?pqrs_id=${pqrs_id}`)
      .pipe(
        catchError(this.handleError)
        );
    }

    /**
     * Metodo que indica los adjuntos pára los adjuntos el correo de escalamientos
     * @param id_mail 
     * @returns Observable
     */
    getAttachments(id_mail:any):Observable<any>{
      return this.http.get<any>(`${GESTION_CORREOS}email/${id_mail}/attachments`)
      .pipe(
        catchError(this.handleError)
        );
    }

    /** 
   * @author Juan Carlos Alonso
   * @createdate 2022-07-08
   * Servicio para eliminar el adjunto seleccionado del escalamiento
   * @returns mensaje de confirmación o error
   */
     deletePqrsAttachment(data: any): Observable<any>{
      return this.http.post<any>(`${PQRS_URL}PQRS/eliminarAdjuntoPqrs`, data)
      .pipe(
        catchError(this.handleError)
        );
    }

    getEscalationInfo(): Observable<any>{
      return this.http.get<any>(`${PQRS_URL}PQRS/infoEscalamientos`)
      .pipe(
        catchError(this.handleError)
        );
    }

    getEscalation(id): Observable<any>{
      return this.http.get<any>(`${PQRS_URL}PQRS/${id}`)
      .pipe(
        catchError(this.handleError)
        );
    }
    /**
    * @author Fabian Duran
    * @createdate 2022-11-02
    * Metodo que retorna la lista de plantillas almacenadas. 
    */
    getEmailsByExit(): Observable<any> {
      return this.http.get<any>(`${PQRS_URL}emails/external`).pipe(
        catchError(this.handleError)
      )
    }
}