import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatrizService } from 'src/app/modules/calidad/services/matriz.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
  selector: 'app-editar-sub-item',
  templateUrl: './editar-sub-item.component.html',
  styleUrls: ['./editar-sub-item.component.sass']
})
export class EditarSubItemComponent implements OnInit {
  private uuid = '65a6a292-915b-11ee-b9d1-0242ac120002';
  public config: any;

  subItemForm:FormGroup;
  items = [];
  filteredOptions: Observable<any[]>;
  verError:boolean = true;
  continue:boolean = true;
  nuevo:boolean = false;
  descripcion:boolean = true;
  mostarDescripciones=true;
  constructor(
    private fb:FormBuilder,
    public subItemRef:MatDialogRef<EditarSubItemComponent>,
    @Inject(MAT_DIALOG_DATA) public datos:any,
    private matrizService:MatrizService,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
    this.createForm();
    this.getSubItemById();
  }


  createForm(){
    this.subItemForm = this.fb.group({
      nombre:['',Validators.required],
      descripcion:[''],
      aspectoPositivo:['',Validators.maxLength(100)],
      aspectoNegativo:['',Validators.maxLength(100)],
      aspectoNa:['',Validators.maxLength(100)]

    })
  }

  getSubItemById(){
    this.matrizService.getModuleById(this.datos.id_subItem).subscribe((resp:any)=>{
      this.subItemForm.setValue({
        nombre: resp.nombre,
        descripcion: resp.descripcion,
        aspectoPositivo:resp.aspectoPositivo,
        aspectoNegativo:resp.aspectoNegativo,
        aspectoNa:resp.aspectoNa
      })
    });
  }

  updateSubItem(){
    if (this.subItemForm.invalid) {
      return
    }
    const data = {
      idModulo: this.datos.id_subItem,
    }
    Object.assign(data,this.subItemForm.value);
    this.matrizService.updateSubItem(data).subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202) {
        Tools.swalSuccess(this.config.modal.excellent, this.config.modal.updatedOk);
        this.subItemRef.close();
      }
    });
  }
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.items.filter(option => option.nombre.toLowerCase().includes(filterValue));
  }

  displayFn(module: any): string {
    return module && module ? module : '';
  }

  getSubItems(){
    this.matrizService.getItems(this.subItemForm.get('nombre').value).subscribe((resp:any)=>{
      if (resp.length == 0) {
        this.verError = true
        this.subItemForm.get('nombre').setErrors({invalid:true});
        this.nuevo = true;
        this.descripcion = false;
        this.mostarDescripciones=false;
        this.continue = false;
      }else{
        this.verError = false;
        this.nuevo = false;
        this.items = resp
        this.filteredOptions = this.subItemForm.get('nombre').valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      }
    })
  }
    newSubItem(){
      this.nuevo = false;
      this.subItemForm.get('nombre').setErrors(null);
      this.continue = true;
      this.descripcion = true;
    }


}
