<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<h3>Assessment</h3>
<hr>
<mat-dialog-content>
    <mat-tab-group [selectedIndex]="TabIndex" linear>
        <div *ngFor="let item of test">
            <mat-tab *ngIf="item.assessments.length > 0 && item.user_creator_id == userId" [label]="item.from">
            <br>
            <table>
                <tr>
                    <th>Competencias</th>
                    <th>Calificación</th> 
                 
                </tr>
                <tr *ngFor="let competence of item.assessments">
                    <td>{{competence.name}}</td>
                      <td class="field-score">
                       <mat-form-field appearance="fill">
                           <mat-label>Calificación</mat-label>
                           <mat-select [(ngModel)]="competence.calification">
                               <mat-option [value]="0"><b>0.</b> No se evidencia la competencia.</mat-option>
                               <mat-option [value]="1"><b>1.</b> Se evidencia parcialmente la competencia.</mat-option>
                               <mat-option [value]="2"><b>2.</b> Se evidencia una competencia desarrollada.</mat-option>
                           </mat-select>
                       </mat-form-field> 
                   </td> 
               </tr>
             </table>
             <div align="center" class="btns">
                <button mat-flat-button color="primary"  (click)="giveTest(item)">Evaluar</button>
                <button mat-stroked-button color="accent" (click)="saveTest(item)">Guardar</button>
             </div>
            
        </mat-tab>
        </div>
        <mat-tab label="Resumen evaluativo">
            <br> 
            <p><strong>Calificaciones por área </strong></p> 
            <p > <b>Área de selección</b> <span matCardSubtitle> {{test[0].evaluation}}%</span></p>
            <p > <b>Área de operación</b> <span matCardSubtitle> {{test[1].evaluation}}%</span></p>
            <p > <b>Área de formación</b> <span matCardSubtitle> {{test[2].evaluation}}%</span></p>
        </mat-tab>
        
    </mat-tab-group>
    
</mat-dialog-content>
