<button style="float: right;" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross"></i>
</button>
<div class="container-dialog">
  <h2>{{config.addAgent}}</h2>
  <mat-divider class="mb-3"></mat-divider>
  <div class="row">
    <mat-form-field appearance="fill" class="col-7">
      <mat-label>{{config.agentName}}</mat-label>
      <mat-select multiple [(value)]="seleccion" #matSeleccion cdkFocusInitial>
        <mat-option *ngFor="let agente of listAgentes" [value]="agente">{{agente.nombre}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="col-5">
      <mat-label>{{config.idNumber}}</mat-label>
      <input type="number" matInput [(ngModel)]="filtroCedula" (blur)="findById()">
      <mat-icon matSuffix><i class="fi-rr-search"></i></mat-icon>
    </mat-form-field>
  </div>
  <div class="col-12 mt-3" style="text-align: center;">
    <button mat-stroked-button class="btn-dialog me-4" (click)="closeDialog()">{{config.cancel}}</button>
    <button mat-raised-button color="primary" class="btn-dialog" (click)="addAsesor()" [disabled]="this.seleccion.length == 0">{{config.save}}</button>
  </div>
</div>
