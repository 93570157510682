import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingsMainComponent, TypeResources } from './bookings-main/bookings-main.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { BookingCreateComponent, ReserveResource } from './booking-create/booking-create.component';
import { BookingsAdminComponent , StatusBooking} from './bookings-admin/bookings-admin.component';
import { StatusPipe } from './bookings-main/status.pipe';
import { DaysPipe } from './booking-create/days.pipe';
import { BookingCrmComponent } from './booking-crm/booking-crm.component';



@NgModule({
  declarations: 
  [BookingsMainComponent, 
    TypeResources, 
    BookingCreateComponent, 
    ReserveResource, 
    BookingsAdminComponent, 
    StatusPipe, 
    StatusBooking, 
    DaysPipe, 
    BookingCrmComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule
  ]
})
export class BookingsModule { }
