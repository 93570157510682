import { Component, OnInit, Inject } from '@angular/core';
import { TestsService } from '../../../services/tests.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';


@Component({
  selector: 'app-upload-test',
  templateUrl: './upload-test.component.html',
  styleUrls: ['./upload-test.component.sass']
})
export class UploadTestComponent implements OnInit {

  attachments: any = [];
  description = '';
  sources: any[] = [];
  uploadedFile = false;
  candidateId;
  requestId;
  competences: any[];
  selected = true
  competencesTest:any = [];
  allCompetencesSelected = false;
  newCompetence: string=''
  public TabIndex : number = 0;
  tabConfim:boolean[]=[]
  constructor(private testService: TestsService,
              public dialogRef: MatDialogRef<UploadTestComponent>,
              private alertService: AlertsService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                this.candidateId = data.candidateId;
                this.requestId = data.requestId;

               }

  ngOnInit(): void {
    const IndexFound = this.data.testsNames.findIndex(element => element.key === 'disc');
    if(IndexFound >= 0 ){
      this.data.testsNames.splice(IndexFound, 1)
    }
    const found = this.data.testsNames.find(element => element.key === 'clinica_assesment');
    if(found){
      this.getAssessments()

    }
    this.data.testsNames.forEach(element => {
      this.tabConfim.push(false)
    });

   }
  getAssessments(){
    this.testService.getAssessmets().subscribe((res:any)=>{

      if(typeof res == 'string'){
        this.competences = JSON.parse(res)
      }
      else{
        this.competences = res
      }

    })
  }
  uploadFiles(type, pos) {
    this.TabIndex ++
    switch (type) {
      case 'assessment':
        this.selected = false
        break;
      case 'attachment':
        this. uploadedFile = false;
        break;

    }

    this.testService.uploadFiles({attachment: this.attachments, description: this.description, source: JSON.stringify(this.competencesTest),
      type: type, candidateId: this.candidateId, requestId: this.requestId})
                    .subscribe(resp => {

                      this.tabConfim[pos]= true
                      if(this.tabConfim.filter(r=>!r).length == 0){
                         this.alertService.alertSuccess('¡Hecho!', 'Tus pruebas han sido diseñadas correctamente');
                        this.dialogRef.close();
                      }
    });
  }
  onFileSelected(files) {
    const idx = this.attachments.length;
    this.attachments = files[0];
    this.uploadedFile = true;
  }
  addCompetence(){
    this.alertService.alertConfirm('¿Estas seguro que desea agregar una nueva competencia?').then(e => {
      if(e.isConfirmed){
        this.testService.updateAssessmentTest(1,  {name:this.newCompetence})
                        .subscribe( (resp: any) => {
          this.alertService.alertSuccess('¡Hecho!', 'Se agregó una nueva competencia');
          this.newCompetence = null
          this.getAssessments();

         });
      }
  });
  }
  pushArray(event) {
    this.competencesTest = event.selectedOptions.selected.map(item => item.value);
  }


}
