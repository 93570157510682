import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NoveltiesService } from 'src/app/modules/nomina/services/novelties.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-novedades-observation',
  templateUrl: './novedades-observation.component.html',
  styleUrls: ['./novedades-observation.component.sass']
})
export class NovedadesObservationComponent implements OnInit {
  justifyForm: FormGroup;
  formData = new FormData();
  justifyTipes: any = [
    { id: 'sistema_fuera_linea', name: 'Sistema fuera de línea' },
    { id: 'protesta_evento', name: 'Protesta o evento social' },
    { id: 'compensatorio', name: 'Compensación de tiempo' },
    { id: 'sin_justificacion', name: 'Sin justificación' }
  ];
  fieldLength: any;
  boolLength: boolean = false;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NovedadesObservationComponent>,
    private alert: AlertsService,
    private noveltieService: NoveltiesService
  ) { }

  ngOnInit() {
    this.justifyForm = new FormGroup({
      type: new FormControl('', [Validators.required]),
      response: new FormControl('', [Validators.required, Validators.maxLength(250)])
    });

    
    this.justifyForm.controls.type.valueChanges.subscribe(resp => {
      if(resp == 'sistema_fuera_linea'){
        this.justifyForm.controls.response.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(10)]);
        this.justifyForm.controls.response.updateValueAndValidity();
        this.justifyForm.controls.response.setValue('');
      } else {
        this.justifyForm.controls.response.setValidators([Validators.required, Validators.maxLength(250)]);
        this.justifyForm.controls.response.updateValueAndValidity();
        this.justifyForm.controls.response.setValue('');
        this.boolLength = false;
      }
    });
    
    this.justifyForm.controls.response.valueChanges.subscribe(resp => {
      let value = '';
      value = resp?.toString();
      if(this.justifyForm.controls.type.value == 'sistema_fuera_linea'){
        this.fieldLength = value?.length;
        if(this.fieldLength > 0) {
          if(this.fieldLength < 6 ) {
            this.boolLength = true;
          } else if(this.fieldLength > 10) {
            this.boolLength = true;
          } else {
            this.boolLength = false;
          }
        }

      } else {
        this.boolLength = false;
        this.justifyForm.controls.response.setValue('');
      }
    });
  }
  /**
   * @author Javier Castañeda 
   * @createdate 2022-04-07
   * Metodo justicar retardos
   */
  saveObservation() {
    this.formData = new FormData();
    this.formData.append('justify_type', this.justifyForm.controls.type.value);
    this.formData.append('justify', this.justifyForm.controls.response.value);
    this.formData.append('type_key', this.data.data.type);
    this.data.data.Ids.forEach(item => {
      this.formData.append('Ids[]', item);
    });    
    this.noveltieService.justifyNovelties(this.formData).subscribe(resp => {
      this.alert.alertSuccessWithoutButton('¡Excelente!', 'La novedad se ha justificado con éxito.');
      this.dialogRef.close();
    });
  }

}
