import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatrizService } from 'src/app/modules/calidad/services/matriz.service';

@Component({
  selector: 'app-detalle-control',
  templateUrl: './detalle-control.component.html',
  styleUrls: ['./detalle-control.component.sass']
})
export class DetalleControlComponent implements OnInit {

  title:string = '';
  tipo:string = '';
  creacion:string = '';
  minimo = 0;
  maximo = 0;
  peso_actual = 0;

  id_matriz;
  tipo_peso;
  campania;
  constructor(
    private aRoute:ActivatedRoute,
    private matrizService:MatrizService
  ) { }

  ngOnInit(): void {
    this.aRoute.paramMap.subscribe(params=>{
      this.id_matriz = params.get('id');
    });
    this.getMatrixById();
  }


  getMatrixById(){
    this.matrizService.getMatrixById(this.id_matriz).subscribe((resp:any)=>{
      this.title = resp[0].matriz.nombre;
      this.tipo = resp[0].matriz.tipo_matriz.nombre;
      this.creacion = resp[0].matriz.created_at;
      this.minimo = resp[0].matriz.valor_peso_validacion.minimo;
      this.maximo = resp[0].matriz.valor_peso_validacion.maximo;
      this.peso_actual = resp[0].matriz.peso_actual;
      if (resp[0].campaign.length == 0) {
        this.campania = {name: 'Ninguna'}
      }else{
        this.campania = resp[0].campaign;
      }
    });
  }

}
