<div class="admin-applications">

    <div class="header">
        <h1>Aplicaciones<i class="fas fa-container-storage    "></i></h1>
        <p>En este espacio podrás revisar y/o crear aplicaciones<br>no olvides, "El respeto y la empatía son clave"</p>
    </div>

    <div class="row col-12 filter">
    
        <mat-form-field class="col-3" appearance="fill">
            <mat-label>Filtro de búsqueda</mat-label>
            <input matInput (keydown.enter)="applyFilter($event)" (blur)="applyFilter($event)" #input>
            <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
        </mat-form-field>

        <div class="col-6"></div>

        <div class="col-3">
            <button mat-raised-button color="primary" (click)="newApplication()">Crear nueva aplicación</button>
        </div>

    </div>

    
    <div class="table-container">
        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" matSort>
                    
                  <ng-container matColumnDef="acciones">
                        <th class="start" mat-header-cell *matHeaderCellDef> Acciones</th>
                        <td mat-cell *matCellDef="let row">
                            <!-- <button mat-icon-button (click)="viewCampaign(row)">
                                <i class="fi-rr-eye"></i>
                            </button> -->
                            <button mat-icon-button (click)="editApplication(row)">
                                <i class="fi-rr-edit"></i>
                            </button>
                            <!-- <button mat-icon-button (click)="deleteCampaign(row)">
                            <i class="fi-rr-trash"></i>
                            </button> -->
                        </td>
                  </ng-container>

                  <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                      <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                  </ng-container>
  
                  <ng-container matColumnDef="key">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Llave </th>
                      <td mat-cell *matCellDef="let row"> {{row.key}} </td>
                  </ng-container>
  
                  <ng-container matColumnDef="endpoint">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> EndPoint </th>
                      <td mat-cell *matCellDef="let row"> {{row.endpoint}} </td>
                  </ng-container>
  
                  <ng-container matColumnDef="enforce_schedule">
                      <th class="end" mat-header-cell *matHeaderCellDef mat-sort-header>Requiere Horiario </th>
                      <td mat-cell *matCellDef="let row" with> <p *ngIf="row.enforce_schedule==0">No </p> <p *ngIf="row.enforce_schedule==1">Si </p></td>
                  </ng-container>
                
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro: "{{input.value}}"</td>
            </tr>

          </table>

          <mat-paginator [length]="length"
                         [pageSize]="pageSize"
                         (page)="pageEvent($event)"
                         [pageSizeOptions]="pageSizeOptions">
          </mat-paginator>
        
            
        </div>
    </div>

</div>
