  <div class="row mt-4">
    <div class="col-md-2">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Filtro de búsqueda</mat-label>
        <input type="text" matInput [formControl]="filter" (keydown.enter)="filterInfo($event.target.value)">
        <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
      </mat-form-field>
    </div>
  </div>

<div class="row mt-3">
  <div class="col-12 table-border">
    <table mat-table [dataSource]="data" matSort>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="formDialog(element.id, element.network_user)"><i class="fi-rr-pencil"></i></button>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="document">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Identificación</th>
        <td mat-cell *matCellDef="let element">{{ element.id_number }}</td>
      </ng-container>

      <ng-container matColumnDef="network_user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario de red</th>
        <td mat-cell *matCellDef="let element">{{ element.network_user }}</td>
      </ng-container>

      <ng-container matColumnDef="updated_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de última actualización</th>
        <td mat-cell *matCellDef="let element">{{ element.updated_at | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro: "{{filter.value}}"</td>
      </tr>
    </table>
    <mat-paginator [length]="length" [pageSize]="pageSize" (page)="paginatorEvent($event)"
      [pageSizeOptions]="pageSizeOptions"> </mat-paginator>
  </div>
</div>
