import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpeechAnalyticsRoutingModule } from './speech-analytics-routing.module';
import { SpeechAnalyticsComponent } from './speech-analytics.component';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from "../../shared/shared.module";
import { StrategyMenuComponent } from './modules/strategy/strategy-menu/strategy-menu.component';
import { MatSortModule } from '@angular/material/sort';
import { AdherenciaModalComponent } from './modules/search-speech/adherencia-modal/adherencia-modal.component';
import { AudioPlayerComponent } from './modules/search-speech/audio-player-modal/audio-player-modal.component';
import { AdminComponent } from './modules/configuration-administrator/admin/admin.component';
import { ConnectsComponent } from './modules/configuration-administrator/connection/connects/connects.component';
import { ConfigCampainComponent } from './modules/configuration-administrator/campaign/config-campain/config-campain.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { StrategyModalComponent } from './modules/strategy/strategy-modal/strategy-modal.component';
import { EditConnectionComponent } from './modules/configuration-administrator/connection/edit-connection/edit-connection.component';
import { EditCampaignComponent } from './modules/configuration-administrator/campaign/edit-campaign/edit-campaign.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NodirigidoComponent } from './modules/nodirigido/nodirigido.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ModuleComponent } from './modules/search-speech/search-module/search-module.component';
import { MatTabsModule } from '@angular/material/tabs';
import { SpeechmenuComponent } from './modules/search-speech/speech-menu/speech-menu.component';
import { CreateCampaignComponent } from './modules/configuration-administrator/campaign/create-campaign/create-campaign.component';
import { AssignCampaignComponent } from './modules/configuration-administrator/assign-campaign/assign-campaign.component';
import { DndDirective } from './modules/nodirigido/direcitves/dnd.directive';
import { TranscricpcionStrategyComponent } from './modules/search-speech/transcription-strategy-modal/transcription-strategy-modal.component';
import { CretateConnectionsComponent } from './modules/configuration-administrator/connection/create-connection/create-connection.component';
import { SearchHistoryMenuComponent } from './modules/search-speech/search-history/search-history-menu/search-history-menu.component';
import { SearchHistoryNodirigidoComponent } from './modules/search-speech/search-history/search-history-nodirigido/search-history-nodirigido.component';
import { SearchHistoryhModuleComponent } from './modules/search-speech/search-history/search-history-module/search-history-module.component';
import { SpeechUploadchatComponent } from './modules/chat-speech/speech-uploadchat/speech-uploadchat.component';
import { SpeechChatMainComponent } from './modules/chat-speech/speech-chat-main/speech-chat-main.component';
import { SearchChatComponent } from './modules/chat-speech/search-chat/search-chat.component';
import { UploadChatsModalComponent } from './modules/chat-speech/upload-chats-modal/upload-chats-modal.component';
import { ChatViewModalComponent } from './modules/chat-speech/chat-view-modal/chat-view-modal.component';
import { MatChipsModule } from '@angular/material/chips';
import { AnalyticsModalComponent } from './modules/search-speech/analytics-modal/analytics-modal.component';
import { AnalyticsChatModalComponent } from './modules/chat-speech/analytics-chat-modal/analytics-chat-modal.component';
import { AnalyticsVoiceGeneralComponent } from './modules/search-speech/analytics-voice-general-modal/analytics-voice-general-modal.component';
import { AdherenciaChatModalComponent } from './modules/chat-speech/adherencia-chat-modal/adherencia-chat-modal.component';
import { TranscChatModalComponent } from './modules/chat-speech/transc-chat-modal/transc-chat-modal.component';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
    declarations: [
        SpeechAnalyticsComponent,
        CretateConnectionsComponent,
        CreateCampaignComponent,
        StrategyMenuComponent,
        AdherenciaModalComponent,
        StrategyModalComponent,
        AudioPlayerComponent,
        AdminComponent,
        ConnectsComponent,
        ConfigCampainComponent,
        EditConnectionComponent,
        EditCampaignComponent,
        NodirigidoComponent,
        ModuleComponent,
        SpeechmenuComponent,
        AssignCampaignComponent,
        DndDirective,
        TranscricpcionStrategyComponent,
        SearchHistoryhModuleComponent,
        SearchHistoryMenuComponent,
        SearchHistoryNodirigidoComponent,
        SpeechUploadchatComponent,
        SpeechChatMainComponent,
        SearchChatComponent,
        UploadChatsModalComponent,
        ChatViewModalComponent,
        AnalyticsModalComponent,
        AnalyticsChatModalComponent,
        AnalyticsVoiceGeneralComponent,
        AdherenciaChatModalComponent,
        TranscChatModalComponent
    ],
    imports: [
        CommonModule,
        SpeechAnalyticsRoutingModule,
        MaterialModule,
        MatDialogModule,
        SharedModule,
        MatSortModule,
        MatExpansionModule,
        FormsModule,
        NgxChartsModule,
        MatTabsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatChipsModule,
        MatTooltipModule
    ]
})
export class SpeechAnalyticsModule { }
