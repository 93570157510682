import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

const MAIL_URL = environment.MAIL_URL;

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private http: HttpClient) { }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  getCola(size: number, page: number): Observable<any>{
    return this.http.get<any>(`${MAIL_URL}correos-en-cola?&n=` + size + `&page=` + page)
    .pipe(
      catchError(this.handleError)
      );
  }

  getHistoricos(size: number, page: number): Observable<any>{
    return this.http.get<any>(`${MAIL_URL}correos-historicos?&n=` + size + `&page=` + page)
    .pipe(
      catchError(this.handleError)
      );
  }

  getActivos(size: number, page: number): Observable<any>{
    return this.http.get<any>(`${MAIL_URL}mis-correos?&n=` + size + `&page=` + page)
    .pipe(
      catchError(this.handleError)
      );
  }

  getEnviados(size: number, page: number): Observable<any>{
    return this.http.get<any>(`${MAIL_URL}correos-respuestas?&n=` + size + `&page=` + page)
    .pipe(
      catchError(this.handleError)
      );
  }

  Busqueda(size: number, page: number, form: FormData): Observable<any>{
    return this.http.post<any>(`${MAIL_URL}avanzada?&n=` + size + `&page=` + page, form)
    .pipe(
      catchError(this.handleError)
      );
  }

  Actualizar(form: FormData): Observable<any>{
    return this.http.post<any>(`${MAIL_URL}recibecorreo`, form)
    .pipe(
      catchError(this.handleError)
      );
  }

  Asignar(form: FormData): Observable<any>{
    return this.http.post<any>(`${MAIL_URL}autoasignar-email`, form)
    .pipe(
      catchError(this.handleError)
      );
  }

  devolverBandeja(form: FormData): Observable<any>{
    return this.http.post<any>(`${MAIL_URL}devolver-bandeja`, form)
    .pipe(
      catchError(this.handleError)
      );
  }

  VolverHistorico(form: FormData): Observable<any>{
    return this.http.post<any>(`${MAIL_URL}volver-historico`, form)
    .pipe(
      catchError(this.handleError)
      );
  }

  VolverCola(form: FormData): Observable<any>{
    return this.http.post<any>(`${MAIL_URL}volver-cola`, form)
    .pipe(
      catchError(this.handleError)
      );
  }

  postCargarArchivos(params: FormData): Observable<any> {
    return this.http.post<any>(`${MAIL_URL}cargar-adjuntos`, params)
    .pipe(
      catchError(this.handleError)
      );
  }

  postCuentaEmailConfig(): Observable<any> {
    return this.http.get<any>(`${MAIL_URL}cuenta-email-config`)
    .pipe(
      catchError(this.handleError)
      );
  }

  postCorreoNuevo(params: FormData): Observable<any> {
    return this.http.post<any>(`${MAIL_URL}nuevo-correo`, params)
    .pipe(
      catchError(this.handleError)
      );
  }

  postresponderCorreo(params: FormData): Observable<any> {
    return this.http.post<any>(`${MAIL_URL}responder-correo`, params)
    .pipe(
      catchError(this.handleError)
      );
  }

  postObtenerCorreo(params: FormData): Observable<any> {
    return this.http.post<any>(`${MAIL_URL}obtener-correo`, params)
    .pipe(
      catchError(this.handleError)
      );
  }

  download(params: FormData){
    return this.http.post(`${MAIL_URL}descargar-adjuntos`, params, {responseType: 'blob'});
  }


}
