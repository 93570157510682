import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ScheduleService } from '../services/rest/schedule.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleGuard implements CanActivate {

  constructor(private scheduleService: ScheduleService, private router: Router) {

  }

  async canActivate(): Promise<boolean> {

    if (await this.verifyRol()) {
      return true;
    } else {
      this.router.navigate(['/mios/crm']);
      return false;
    }
  }

  public async verifyRol(): Promise<boolean> {

    const vari = await this.scheduleService.getScheduleGuard().toPromise();

    if (vari === 'started') {
      return true;
    } else {
      return false;
    }

  }

}
