import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TimePipe } from 'src/app/modules/calidad/pipes/time.pipe';
import { AuditoriaService } from 'src/app/modules/calidad/services/auditoria.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import Swal from 'sweetalert2';
import { FormInteligenciaComponent } from '../detalle-auditoria/components/form-inteligencia/form-inteligencia.component';
import {TranslatesService} from '../../../../services/translates.service';
import { DataAuditoria } from 'src/app/modules/calidad/interfaces/dataAuditoria.interface';

@Component({
	selector: 'app-form-auditar',
	templateUrl: './form-auditar.component.html',
	styleUrls: ['./form-auditar.component.sass']
})
export class FormAuditarComponent implements OnInit, OnDestroy {
  private uuid = 'ae5de06-92b4-11ee-b9d1-0242ac120002';
  public config: any;

	@Input() tiene_inteligencia;
	@Input() dataCalificacion: any = {};
	@Input() id_matriz;
	@Input() data: DataAuditoria;
	@Output() envioTipoAuditar = new EventEmitter();
	@Output() envioTab = new EventEmitter();
	auditarForm: FormGroup;
	cedula: string = "";
	tiempo = '';
	id_asesor;
	//Selects
	monitoreos = [];
	servicios = [];
	auditorias = [];
	soluciones = [];
	tipoMuestra = [];
	ojts = [];
	no_soluciones = [];
	dias = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
	endDate = new Date();

	//Observable para los cambios en los servicios de auditoria
	subscription: Subscription;

	constructor(
		private dialog: MatDialog,
		private auditoriaService: AuditoriaService,
		private fb: FormBuilder,
		private router: Router,
		private pipeTime: TimePipe,
    private configService: TranslatesService,
	) { }

	ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
		this.getSelects();
		this.createForm();

		this.subscription = this.auditoriaService.refresh$.subscribe(() => {
			this.dataCalificacion.modulos = [];
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	saveForm() {
		let num_items = 0;
		let num_items_calificados = 0;

		let commentacreado =false;
		this.dataCalificacion.modulos.forEach(modulo => {
			// num_items = num_items + modulo.items_modulo.length
			modulo.items_modulo.forEach(item => {
				if (item.activo == 1) {
					num_items++;
				}
				if (item.calificacion || item.calificacion === null) {
					num_items_calificados++
				}
				
				if((item.comments == null) && (this.data.comment_is_mandatory_for.includes('0'))&& (item.calificacion=="no")){
					commentacreado=true;
					Swal.fire({
						icon: 'warning',
						title: 'El comentario de la calificacion es requerido',
						confirmButtonText: 'Entendido',
						width: '750px',
					});
					return;
				} 
				if((item.comments == null) && (this.data.comment_is_mandatory_for.includes('1'))&& (item.calificacion=="si")){
					commentacreado=true;

					Swal.fire({
						icon: 'warning',
						title: 'El comentario de la calificacion es requerido ',
						confirmButtonText: 'Entendido',
						width: '750px',
					});
					return;
				}
				if((item.comments == null) && (this.data.comment_is_mandatory_for.includes('2')) && (item.calificacion=="na")){
					commentacreado=true;

					Swal.fire({
						icon: 'warning',
						title: 'El comentario de la calificacion es requerido ',
						confirmButtonText: 'Entendido',
						width: '750px',
					});
					return;
				}
				if((item.comments == null) && (this.data.comment_is_mandatory_for.includes('3'))&& (item.calificacion=="cp")){
					commentacreado=true;

					Swal.fire({
						icon: 'warning',
						title: 'El comentario de la calificacion es requerido ',
						confirmButtonText: 'Entendido',
						width: '750px',
					});
					return;
				}
				
				if (item.comments != null) {
					let comentario: string = item.comments
					console.log( comentario.length,this.data.comment_size, comentario);
					if (item.comments.length<this.data.comment_size){
					    
						commentacreado=true;
						Swal.fire({
							icon: 'warning',
							title: 'El comentario es demasiado corto',
							confirmButtonText: 'Entendido',
							width: '750px',
						});
						return;
					}
				}
			});

		});

		if (num_items == 0 || num_items != num_items_calificados) {
			Swal.fire({
				icon: 'warning',
				title: 'Aun te falta calificar módulos.',
				confirmButtonText: 'Entendido',
				width: '750px',
			});
			return;
		}
		this.changeSelect();
		if (this.auditarForm.invalid === true) {
			return;
		}
		this.auditarForm.enable();
		this.auditarForm.get('fecha').setValue(moment(this.auditarForm.get('fecha').value).format('yyyy-MM-DD'))
		Object.assign(this.auditarForm.value, {
			total_calificacion: this.dataCalificacion.calificacion,
			id_asesor: this.id_asesor,
		})
		const data = {
			inteligencia_id: this.tiene_inteligencia,
			formAsesor: [this.auditarForm.value],
			matriz_id: this.id_matriz,
			seleccionItemsAfectacion: [],
			mensaje: 'Tiene una nueva auditoría con ID-',
			ruta: '/mios/calidad/mis-auditorias',
		}

		this.dataCalificacion.modulos.forEach((modulo, index) => {
			data.seleccionItemsAfectacion.push({
				modulo_id: modulo.id,
				padre_id: modulo.padre_id,
				nivel: modulo.nivel,
				puntaje: "",
				itemsAfectados: [],
			})
			modulo.items_modulo.forEach(item => {
				data.seleccionItemsAfectacion[index].itemsAfectados.push({
					item_id: item.id,
					padreitem: item.padre_id,
					nivelItem: item.nivel,
					calificacion: item.calificacion,
					puntaje: item.puntaje,
					comments: item.comments
				});
				item.sub_items.forEach(subItem => {
					data.seleccionItemsAfectacion[index].itemsAfectados.push({
						item_id: subItem.id,
						padreitem: subItem.padre_id,
						nivelItem: subItem.nivel,
						calificacion: subItem.calificacion === undefined ? '0' : subItem.calificacion,
						puntaje: subItem.puntaje === undefined ? '0' : subItem.puntaje,
						comments: subItem.comments
					});
				});
			});
		});
		if (this.tiene_inteligencia) {
			if(commentacreado){
				return;
			} else{
				this.openFormInteligencia(data);
			}
			
		} else {
			if(!commentacreado){
				console.log("enviado",data);
				this.auditoriaService.createAuditoria(data).subscribe((resp: any) => {
					if (resp.respuesta.codigo == 202) {
						Tools.swalSuccess(this.config.modal.excellent, this.config.modal.responseOk);
						this.tiempo = '';
						this.auditarForm.get('duracion_grabacion').valueChanges.subscribe();
						this.ngOnInit();
						this.cancelar();
						this.envioTab.emit(1);
					} else if (resp.respuesta.codigo != 202) {
						this.auditarForm.get('nombre_asesor').disable();
						this.changeSelect();
						Tools.swalError('¡Oops!', this.config.modal.responseError);
					}
				});
			}
		}
	}

	cancelar() {
		this.auditarForm.reset();
		this.auditarForm.get('cedula_asesor').setErrors(null);
		this.auditarForm.get('nombre_asesor').setErrors(null);
		this.auditarForm.get('fecha').setErrors(null);
		this.auditarForm.get('telefono').setErrors(null);
		this.auditarForm.get('hora').setErrors(null);
		this.auditarForm.get('duracion_grabacion').setErrors(null);
		this.auditarForm.get('tipo_monitoreo').setErrors(null);
		this.auditarForm.get('tipo_servicio').setErrors(null);
		this.auditarForm.get('ucid').setErrors(null);
		this.auditarForm.get('tipo_auditoria').setErrors(null);
		this.auditarForm.get('solucion_primer_contacto_id').setErrors(null);
		this.auditarForm.get('razon_solucion_id').setErrors(null);
		this.auditarForm.get('porque_no').setErrors(null);
		this.auditarForm.get('ojt_id').setErrors(null);
		this.auditarForm.get('dias').setErrors(null);
		this.auditarForm.get('observacion').setErrors(null);
	}

	getSelects() {
		this.auditoriaService.getTypeMuestra().subscribe((resp: any) => {
			this.tipoMuestra = resp

		});

		this.auditoriaService.getTypeMonitoring().subscribe((resp: any) => {
			this.monitoreos = resp
			this.monitoreos.sort(function (a, b) {
				if (a.nombre > b.nombre) {
					return 1;
				}
				if (a.nombre < b.nombre) {
					return -1;
				}
				return 0;
			})
		});
		this.auditoriaService.getTypeService().subscribe((resp: any) => {
			this.servicios = resp
			this.servicios.sort(function (a, b) {
				if (a.nombre > b.nombre) {
					return 1;
				}
				if (a.nombre < b.nombre) {
					return -1;
				}
				return 0;
			})
		});
		this.auditoriaService.getTypeAudit().subscribe((resp: any) => {
			this.auditorias = resp
			this.auditorias.sort(function (a, b) {
				if (a.nombre > b.nombre) {
					return 1;
				}
				if (a.nombre < b.nombre) {
					return -1;
				}
				return 0;
			})
		});
		this.auditoriaService.getTypeSolution().subscribe((resp: any) => {
			this.soluciones = resp
		});
		this.auditoriaService.getTypegetOjt().subscribe((resp: any) => {
			this.ojts = resp
		});
		this.auditoriaService.getNoSolucion().subscribe((resp: any) => {
			this.no_soluciones = resp
			this.no_soluciones.sort(function (a, b) {
				if (a.nombre > b.nombre) {
					return 1;
				}
				if (a.nombre < b.nombre) {
					return -1;
				}
				return 0;
			})
		});
	}

	createForm() {
		this.auditarForm = this.fb.group({
			cedula_asesor: ['', Validators.required],
			nombre_asesor: [{ value: '', disabled: true }, Validators.required],
			fecha: ['', Validators.required],
			telefono: ['', [Validators.required, Validators.max(9999999999)]],
			hora: ['', Validators.required],
			duracion_grabacion: [this.tiempo, [Validators.required, Validators.pattern('[0-9]?[0-9]:[0-9][0-9]:[0-9][0-9]')]],
			tipo_monitoreo: ['', Validators.required],
			tipo_servicio: ['', Validators.required],
			ucid: ['', Validators.required],
			tipo_auditoria: ['', Validators.required],
			solucion_primer_contacto_id: ['', Validators.required],
			razon_solucion_id: [{ value: '', disabled: true }, Validators.required],
			porque_no: [{ value: '', disabled: true }, Validators.required],
			ojt_id: ['', Validators.required],
			dias: [{ value: '0', disabled: true }, Validators.required],
			observacion: ['', Validators.required],
			doc_boss: ['', Validators.required],
			tipoMuestra: ['', Validators.required],
			observacionMuestra: ['', [Validators.required , Validators.maxLength(100)]],
			name_boss: [{ value: '', disabled: true }],
		});
	}
	/**
	 * Metodo que consulta el nombre y id del asesor de la auditoria
	 * @author Juan David Guerrero Vargas
	 * @create_date 14/06/2022
	 * @param type:string  tipo de documento a buscar [asesor-boss]
	 * @return void
	 */
	getAsesor(type: string):void {
		let cedula:string="";
		if (type === 'asesor') {
			cedula = this.auditarForm.get('cedula_asesor').value
		}else if(type === 'boss'){
			cedula = this.auditarForm.get('doc_boss').value
		}
			this.auditoriaService.getAsesor(cedula).subscribe((resp: any) => {
				if (type === 'asesor') {
					this.id_asesor = resp[0].id;
					this.auditarForm.get('nombre_asesor').setValue(resp[0].nombre)
					this.auditarForm.get('cedula_asesor').setErrors(null);
				}else if(type === 'boss'){
					this.auditarForm.get('name_boss').setValue(resp[0].nombre)
					this.auditarForm.get('doc_boss').setErrors(null);
				}

			}, () => {
				if (type === 'asesor') {
					this.auditarForm.get('nombre_asesor').setValue('');
					this.auditarForm.get('cedula_asesor').setErrors({ no_resultado: true });
				}else if(type === 'boss'){
					this.auditarForm.get('name_boss').setValue('')
					this.auditarForm.get('doc_boss').setErrors({ no_resultado: true });
				}
			}
			);
	}

	changeSelect() {
		if (this.auditarForm.get('solucion_primer_contacto_id').value == 17) {
			this.auditarForm.get('ojt_id').enable();
			this.auditarForm.get('razon_solucion_id').disable();
			this.auditarForm.get('razon_solucion_id').setValue(null);
			this.auditarForm.get('razon_solucion_id').setErrors(null);
			this.auditarForm.get('porque_no').disable();
			this.auditarForm.get('porque_no').setValue(null);
			this.auditarForm.get('porque_no').setErrors(null);
		}
		if (this.auditarForm.get('solucion_primer_contacto_id').value == 18) {
			this.auditarForm.get('razon_solucion_id').enable();
			this.auditarForm.get('porque_no').enable();
		}
		if (this.auditarForm.get('ojt_id').value == 20 || this.auditarForm.get('ojt_id').value == null) {
			this.auditarForm.get('dias').disable();
			this.auditarForm.get('dias').setValue('100');
			this.auditarForm.get('dias').setErrors(null);
		}
		if (this.auditarForm.get('ojt_id').value == 19) {
			this.auditarForm.get('dias').enable();
		}
		if (this.auditarForm.get('tipoMuestra').value !=="" || this.auditarForm.get('tipoMuestra').value == null) {
			this.auditarForm.get('observacionMuestra').enable();
		}

	}

	openFormInteligencia(data) {
		const inteligenciaRef = this.dialog.open(FormInteligenciaComponent, {
			width: '840px',
			disableClose: true,
			data: data,
		});
		inteligenciaRef.afterClosed().subscribe((result) => {
			this.auditarForm.get('nombre_asesor').disable();
			if (result == 202) {
				this.tiempo = '';
				this.auditarForm.get('duracion_grabacion').valueChanges.subscribe();
				this.ngOnInit();
				this.auditarForm.reset();
				this.envioTab.emit(1);
			}
		})
	}

	auditorio() {
		if (this.auditarForm.get('tipo_auditoria').value == 15) {
			this.envioTipoAuditar.emit({ interna: 1, cliente: 0 })
		} else if (this.auditarForm.get('tipo_auditoria').value == 16) {
			this.envioTipoAuditar.emit({ interna: 0, cliente: 1 })
		}
	}

	validaTemporizador(e){
		let value = e.target.value;
		let is_number = Number.parseInt(value);
			if (Number.isInteger(is_number)) {
				this.tiempo = this.pipeTime.transform(value);
			} else {
				this.tiempo = value;
			}
			if(value.length > 0){
				let dataFormat = this.tiempo.split(':');
				console.log(dataFormat);
				//Se setea la validacion de hh:mm:ss
				if(dataFormat[0] > '24') dataFormat[0] = '24';
				if(dataFormat[1] > '59') dataFormat[1] = '59';
				if(dataFormat[2] > '59') dataFormat[2] = '59';

				let dataComplet = dataFormat.join(':');

				this.tiempo = dataComplet;
			}
	}


}
