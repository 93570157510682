<button style="float: right" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross-small" style="font-size: 20px !important; color: #353535;"></i>
</button>
<div class="app-historialn" style="padding: 48px 108px 48px 108px;">
  <h2 mat-dialog-title style="font-size: 28px !important; color:#353535;">
    Histórico de notas
  </h2>


  <mat-divider style="border: 1px solid #D8D8D8;margin-bottom: 20px;"></mat-divider>
  <mat-accordion style="padding-left: 0;padding-right:0;">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" style="box-shadow: none;padding-left: 0;padding-right:0;">
      <mat-expansion-panel-header style="padding-left: 0;padding-right:0;">
        <mat-panel-title style="color:#353535;font-weight:700;font-size:20px;">
          Prospecto
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-by-step [notes]="notes?.data['1']" [rolUser]=null [oportunity]="oportunity" [step]=null [typeView]="'history'"></app-by-step>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion style="padding-left: 0;padding-right:0;">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" style="box-shadow: none;padding-left: 0;padding-right:0;">
      <mat-expansion-panel-header style="padding-left: 0;padding-right:0;">
        <mat-panel-title style="color:#353535;font-weight:700;font-size:20px;">
          Acercamiento comercial
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-by-step [notes]="notes?.data['2']" [rolUser]=null [oportunity]="oportunity" [step]=null [typeView]="'history'"></app-by-step>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion style="padding-left: 0;padding-right:0;">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" style="box-shadow: none;padding-left: 0;padding-right:0;">
      <mat-expansion-panel-header style="padding-left: 0;padding-right:0;">
        <mat-panel-title style="color:#353535;font-weight:700;font-size:20px;">
          Construcción de propuesta
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-by-step [notes]="notes?.data['3']" [rolUser]=null [oportunity]="oportunity" [step]=null [typeView]="'history'"></app-by-step>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion style="padding-left: 0;padding-right:0;">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" style="box-shadow: none;padding-left: 0;padding-right:0;">
      <mat-expansion-panel-header style="padding-left: 0;padding-right:0;">
        <mat-panel-title style="color:#353535;font-weight:700;font-size:20px;">
          Enviada
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-by-step [notes]="notes?.data['4']" [rolUser]=null [oportunity]="oportunity" [step]=null [typeView]="'history'"></app-by-step>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion style="padding-left: 0;padding-right:0;">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" style="box-shadow: none;padding-left: 0;padding-right:0;">
      <mat-expansion-panel-header style="padding-left: 0;padding-right:0;">
        <mat-panel-title style="color:#353535;font-weight:700;font-size:20px;">
          Negociación/Sustentación
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-by-step [notes]="notes?.data['5']" [rolUser]=null [oportunity]="oportunity" [step]=null [typeView]="'history'"></app-by-step>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion style="padding-left: 0;padding-right:0;">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" style="box-shadow: none;padding-left: 0;padding-right:0;">
      <mat-expansion-panel-header style="padding-left: 0;padding-right:0;">
        <mat-panel-title style="color:#353535;font-weight:700;font-size:20px;">
          Cierre
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-by-step [notes]="notes?.data['6']" [rolUser]=null [oportunity]="oportunity" [step]=null [typeView]="'history'"></app-by-step>
    </mat-expansion-panel>
  </mat-accordion>

</div>