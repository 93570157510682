import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-create-observation-item',
  templateUrl: './create-observation-item.component.html',
  styleUrls: ['./create-observation-item.component.sass']
})
export class CreateObservationItemComponent implements OnInit {
  formCreateObservation = new FormGroup({
    observation: new FormControl('', [Validators.required, Validators.maxLength(200)]),
  });

  constructor(
    public dialogRef: MatDialogRef<CreateObservationItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { indexItem, observation }) { }

  ngOnInit(): void {
    if (this.data.observation) this.formCreateObservation.get('observation').setValue(this.data.observation);
  }

  sendObservationItem(): void {
    if (this.formCreateObservation.valid) {
      this.dialogRef.close({ observation: this.formCreateObservation.get('observation').value, indexItem: this.data.indexItem });
    } else {
      this.formCreateObservation.markAllAsTouched();
    }
  }
}
