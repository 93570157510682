import { Component, OnInit } from '@angular/core';
import { SurveyService } from '../modules/encuestas/services/suervey.service';
import { ModalSurveysUserComponent } from './modal-surveys-user/modal-surveys-user.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-mios',
  templateUrl: './mios.component.html',
  styleUrls: ['./mios.component.sass']
})
export class MiosComponent implements OnInit {

  constructor(
    private surveyService: SurveyService,
    public dialog: MatDialog,

  ) { }

  ngOnInit(): void {
    //this.openModalSurveys();

  }
  openModalSurveys(): void {

    this.surveyService.getSurveysUser().subscribe(
      (restResponse: any) => {
        if (restResponse?.surveys !== undefined && Array.isArray(restResponse.surveys) == true && restResponse.surveys.length > 0) {

          const $refDialog = this.dialog.open(ModalSurveysUserComponent, {
            width: '95vw',
            height: 'auto',
            disableClose: true,
            data: {
              surveys: restResponse?.surveys
            }
          });

        }

      }
    );


  }

}
