import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatrizService } from 'src/app/modules/calidad/services/matriz.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
  selector: 'app-crear-item',
  templateUrl: './crear-item.component.html',
  styleUrls: ['./crear-item.component.sass']
})
export class CrearItemComponent implements OnInit {
  private uuid = '81a84074-9131-11ee-b9d1-0242ac120002';
  public config: any;

  itemForm:FormGroup;
  items = [];
  filteredOptions: Observable<any[]>;
  verError:boolean = true;
  continue:boolean = true;
  nuevo:boolean = false;
  peso:boolean = false;
  descripcion:boolean = false;
  mostarDescripciones:boolean = false

  constructor(
    public itemRef:MatDialogRef<CrearItemComponent>,
    @Inject(MAT_DIALOG_DATA) public datos:any,
    private fb:FormBuilder,
    private matrizService:MatrizService,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
    this.createForm();
    console.log(this.datos);

  }

  createForm(){
    this.itemForm = this.fb.group({
      nombre:['',Validators.required],
      peso:['',[Validators.required]],
      descripcion:[''],
      aspectoPositivo:['',Validators.maxLength(100)],
      aspectoNegativo:['',Validators.maxLength(100)],
      aspectoNa:['',Validators.maxLength(100)],
      aspectoCp: ['', Validators.maxLength(100)]
    })
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.items.filter(option => option.nombre.toLowerCase().includes(filterValue));
  }

  displayFn(module: any): string {
    return module && module ? module : '';
  }

  getItems(){
    this.matrizService.getItems(this.itemForm.get('nombre').value).subscribe((resp:any)=>{
      if (resp.length == 0) {
        this.verError = true
        this.itemForm.get('nombre').setErrors({invalid:true});
        this.nuevo = true;
        this.continue = true;
        this.descripcion = false;
        this.peso = false;
        this.mostarDescripciones=false
      }else{
        this.newItem();
        this.mostarDescripciones=true
        this.descripcion = true;
        this.verError = false;
        this.nuevo = false;
        this.items = resp
        this.filteredOptions = this.itemForm.get('nombre').valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      }
    })
  }

  newItem(){
    this.nuevo = false;
    this.itemForm.get('nombre').setErrors(null);
    this.descripcion = true;
    this.mostarDescripciones =true;
    if (this.datos.tipo_matriz == "Cumplimiento" || this.datos.tipo_matriz == "Compliance") {
      this.peso = false
      this.itemForm.get('peso').setValue(null);
      this.itemForm.get('peso').setErrors(null);
    }else{
      if (this.datos.tipo_peso == 'Peso en Items' || this.datos.tipo_peso == 'Weight in Items') {
        if (this.datos.error_critico) {
          this.peso = false;
          this.itemForm.get('peso').setValue(null);
          this.itemForm.get('peso').setErrors(null);
        }else{
          this.peso = true;
        }
      }else{
        this.peso = false;
        this.itemForm.get('peso').setValue(null);
        this.itemForm.get('peso').setErrors(null);
      }
    }
    this.descripcion = true;
    this.mostarDescripciones =true;
    this.continue = false;
  }

  saveItem(){
    if (this.itemForm.invalid) {
      return
    }
    const data = {
      idMatriz: this.datos.id_matriz,
      nivel: 2,
      sigla: this.datos.sigla,
      padre_id: this.datos.id_modulo,
      error_critico: this.datos.error_critico,
    }
    Object.assign(data,this.itemForm.value);
    this.matrizService.newItem(data).subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202) {
        Tools.swalSuccess(this.config.modal.excellent,this.config.modal.createOk);
        this.itemRef.close();
      }else if (resp.respuesta.codigo > 400) {
        Tools.swalError('¡Oops!', this.config.modal.createError);
      }
    },(err:any)=>{
    }
    );
  }
}
