export class Message {
	message_type?: string;
	message?: string;
	sender_name?: string;
	sender_rrhh_id?: number;
	conversation_id?: string;
	users_mentio_chat?: [];

	constructor(message_type?: string,
        message?: string,
        sender_name?: string,
        sender_rrhh_id?: number,
        conversation_id?: string,
        users_mentio_chat?: []) {

		this.message_type = message_type;
		this.message = message;
		this.sender_name = sender_name;
		this.sender_rrhh_id = sender_rrhh_id;
		this.conversation_id = conversation_id;
		this.users_mentio_chat = users_mentio_chat;
	}
}
