<div class="row novelty-row-padding">

  <h2 mat-dialog-title>
    <span class="gray-color" style="margin-left: -9px;"></span>
    <button style="float: right;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
    <div class="title content" style="width: 100%; margin-left: 55px;">
      <span>
        <h1 style="color: #353535;">{{data.data?.name}}</h1>
        <h5 style="font-size: 16px; font-family: 'Poppins', sans-serif!important; color: #A9A9A9;">{{data.data?.id_number}}</h5>
      </span>
    </div>
  </h2>

  <mat-dialog-content style="padding: 3%; overflow-x: hidden !important;" class="mat-typography">
    <div class="row" style="margin-left: 50px;">

      <div class="col-6">
        <div class="row">
          <div class="col-12">

              <div class="col-12">

                <div class="col-12">
                  <h3 style="font-size: 28px !important;"> <b>Solicitud de incapacidad</b> </h3>
                  <h4 class="p-color">{{dataInfo?.subtypeName}}</h4>
                </div>
                <br>

              </div>

              <div class="col-12">

                <div class="row col-12">
                  <h3 class="gray-color"><b>Fechas por incapacidad</b> </h3>
                  <p class="p-color" style="font-size: 16px; width: 50%;">
                    <strong>Fecha Inicio: </strong> {{ dataInfo?.novelty_date_start}}</p>
                    
                  <p class="p-color" style="width: 50%;"><strong style="margin-top: 5px;">Fecha Fin: </strong> {{ dataInfo?.novelty_date_end }}</p>  
                </div>
                <br>
              </div>
              <div class="row col-12">
                <div class="col-6" *ngIf="dataInfo?.extra">
                  <br>
                  <h4 class="gray-color" style="font-size: 18px !important;"><b>Empresa</b></h4>
                  <p class="p-color">{{ dataInfo?.extra?.disability?.empresa?.name}}</p>
                </div>
                <div class="col-6" *ngIf="dataInfo?.extra">
                  <br>
                  <h4 class="gray-color" style="font-size: 18px !important;"><b>Eps</b></h4>
                  <p class="p-color">{{ dataInfo?.extra?.disability?.eps?.name}}</p>
                </div>
              </div>
              <div class="col-12" *ngIf="dataInfo?.diagnosticName">
                <br>
                <h4 class="gray-color" style="font-size: 18px !important;"><b>Diagnóstico</b></h4>
                <p class="p-color">{{ dataInfo?.diagnosticName}}</p>
              </div>


              <div class="col-12">
                <br>
                <h4 class="gray-color"><b>Justificación del trabajador</b></h4>
                <p class="p-color">{{ dataInfo?.justificacionTrabajador }}</p>

                <div style="margin-top: 20px;">
                  <p style="color: #2CABBC; margin-top: 5px;" *ngFor="let item of files">
                     <span class="fi-rr-cloud-download" (click)="file.click()">
                      <a #file [href]="item?.fullPath" target="_blank"></a> {{item?.name}}
                     </span>
                  </p>
                </div>

                <div class="col-12" style="margin-top: 32px;" *ngIf="showJustifyInfo">
                  <h4 class="gray-color"><b>Estado</b></h4>
                  <p class="p-color">{{ dataInfo?.stateName }}</p>
                </div>
              </div>

              <!-- payroll response -->
              <div class="col-12" *ngIf="dataInfo?.RespuestaIncapacidades">
                <br>
                <h4 class="gray-color"><b>Respuesta</b></h4>
                <p class="p-color">{{ dataInfo?.RespuestaIncapacidades }}</p>
                <p class="p-color"><b>Fecha de aprobación:</b> {{ dataInfo?.fechaAprobacion | date:'dd/MM/yyyy' }}</p>
                <p class="p-color"><b>Gestionado por:</b></p>
                <p class="p-color">{{ dataInfo?.aprovationUser }}</p>

              </div>



          </div>
        </div>

      </div>

      <div class="col-6 text-center">
        <img src="./assets/images/suspension.png" alt="noveltyIcon">
      </div>
    </div>
  </mat-dialog-content>

</div>