<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross"></i>
</button>

<h2 mat-dialog-title>{{candidate?.first_name}} {{candidate?.last_name}}</h2>
<hr>
<mat-dialog-content>
  <mat-tab-group mat-align-tabs="left" (selectedTabChange)="onTabChanged($event);">
      <mat-tab label="Datos Basicos">
          <div class="info-section">
              <form [formGroup]="basicFormGroup">
                  <h4>Datos Básicos</h4>
                  <div class="forms">
                      <mat-form-field appearance="fill">
                          <mat-label>Tipo de documento</mat-label>
                          <mat-select formControlName="id_type_id" required
                              [disabled]="this.state == 'contratado'|| this.state == 'activo' || (basicFormGroup.controls.id_type_id.value && id?.type == 'edit') ">
                              <mat-option *ngFor="let idType of idTypes" [value]="idType.id"
                                  (click)="selectedIdtype(idType.key)">
                                  {{idType.name}}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>

                      <mat-form-field appearance="fill">
                          <mat-label
                              *ngIf="keyType != 'PET' && keyType != 'PPT' && keyType != 'PEP' && keyType != 'CE' && keyType != 'PTT'">Número
                              de documento</mat-label>
                          <mat-label
                              *ngIf="keyType == 'PET' || keyType == 'PPT' || keyType == 'PEP' || keyType == 'CE' || keyType == 'PTT'">{{keyType}}</mat-label>
                          <input matInput formControlName="id_number"
                              [readonly]="this.state == 'contratado'|| this.state == 'activo' || (basicFormGroup.controls.id_number.value && id?.type == 'edit') ">
                      </mat-form-field>

                      <mat-form-field appearance="fill" fxFlex="30" fxFlex.sm=44
                          *ngIf="keyType == 'PET' || keyType == 'PPT' || keyType == 'PEP' || keyType == 'CE' || keyType == 'PTT'">
                          <mat-label>Número de pasaporte</mat-label>
                          <input matInput formControlName="number_PA" required
                              [readonly]="this.state == 'contratado'|| this.state == 'activo' || (basicFormGroup.controls.number_PA.value && id?.type == 'edit') ">
                      </mat-form-field>

                      <mat-form-field appearance="fill">
                          <mat-label>Primer Nombre</mat-label>
                          <input matInput formControlName="first_name">
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Segundo Nombre</mat-label>
                          <input matInput formControlName="middle_name">
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Primer Apellido</mat-label>
                          <input matInput formControlName="last_name">
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Segundo Apellido</mat-label>
                          <input matInput formControlName="second_last_name">
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Fecha Nacimiento</mat-label>
                          <input matInput [matDatepicker]="picker" formControlName="birth_date" [max]="now"
                              min="2005-01-02">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Estado Civil</mat-label>
                          <mat-select formControlName="civil_state_id">
                              <mat-option *ngFor="let civilState of civilStates" [value]="civilState.id">
                                  {{civilState.name}}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Email</mat-label>
                          <input type="email" matInput formControlName="email">
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Email(Opcional)</mat-label>
                          <input type="email" matInput formControlName="email2">
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Celular</mat-label>
                          <input type="number" matInput formControlName="mobile_phone">
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Teléfono (Opcional)</mat-label>
                          <input type="number" matInput formControlName="phone">
                      </mat-form-field>
                      <mat-form-field class="example-full-width" appearance="fill" *ngIf="id?.campaignShow == true">
                          <mat-label>Centro de costos</mat-label>
                          <input type="text" placeholder="Centro de costos" name="" matInput
                              formControlName="cost_center" [matAutocomplete]="auto">
                          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                              [displayWith]="displayFn.bind(this)">
                              <mat-option *ngFor="let item of filteredOptions  | async" [value]="item.id">
                                  {{item.name}}
                              </mat-option>
                          </mat-autocomplete>
                      </mat-form-field>
                  </div>

              </form>
          </div>

      </mat-tab>
      <mat-tab label="Datos Personales">
          <div class="info-section">
              <form [formGroup]="firstFormGroup">
                  <mat-accordion>
                      <mat-expansion-panel  [expanded]="true">
                          <mat-expansion-panel-header>
                              <mat-panel-title>
                                  <h4 class="pt-3">Información Personal</h4>
                              </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="forms">

                              <mat-form-field appearance="fill">
                                  <mat-label>Edad</mat-label>
                                  <input matInput formControlName="age" required>
                                  <mat-error *ngIf="error.age.errors">
                                      <span *ngIf="error.age.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Ciudad de expedición documento</mat-label>
                                  <input matInput formControlName="city_document_id" required
                                      [readonly]="read_only_city_document_id">
                                  <mat-error *ngIf="error.city_document_id.errors">
                                      <span *ngIf="error.city_document_id.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                                  <mat-error *ngIf="error.city_document_id.errors">
                                      <span *ngIf="error.city_document_id.errors.maxlength">¡Advertencia! Este campo
                                          acepta maximo 20 caracteres</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Fecha Expedición</mat-label>
                                  <input matInput [matDatepicker]="picker1" formControlName="expedition_date_id"
                                      [max]="now" required>
                                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                  <mat-datepicker #picker1></mat-datepicker>
                                  <mat-error *ngIf="error.expedition_date_id.errors">
                                      <span *ngIf="error.expedition_date_id.errors.required">¡Advertencia! Este campo
                                          es requerido</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Lugar de Nacimiento</mat-label>
                                  <input matInput formControlName="place_of_birth" required>
                                  <mat-error *ngIf="error.place_of_birth.errors">
                                      <span *ngIf="error.place_of_birth.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                                  <mat-error *ngIf="error.place_of_birth.errors">
                                      <span *ngIf="error.place_of_birth.errors.maxlength">¡Advertencia! Este campo
                                          acepta maximo 20 caracteres</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Nacionalidad</mat-label>
                                  <input matInput formControlName="nationality" required
                                      [readonly]="read_only_nationality">
                                  <mat-error *ngIf="error.nationality.errors">
                                      <span *ngIf="error.nationality.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                                  <mat-error *ngIf="error.nationality.errors">
                                      <span *ngIf="error.nationality.errors.maxlength">¡Advertencia! Este campo acepta
                                          maximo 20 caracteres</span>
                                  </mat-error>
                              </mat-form-field>
                              <mat-form-field appearance="fill">
                                  <mat-label>Alias</mat-label>
                                  <input matInput formControlName="alias" required>
                                  <mat-error *ngIf="error.alias.errors">
                                      <span *ngIf="error.alias.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                                  <mat-error *ngIf="error.alias.errors">
                                      <span *ngIf="error.alias.errors.maxlength">¡Advertencia! Este campo acepta
                                          maximo 20 caracteres</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                <mat-label>Género</mat-label>
                                <mat-select matNativeControl formControlName="gender" required (selectionChange)="genderFields($event.value)">
                                    <mat-option value="Femenino">Femenino</mat-option>
                                    <mat-option value="Masculino">Masculino</mat-option>
                                    <mat-option value="Transgénero">Transgénero</mat-option>
                                    <mat-option value="No binario">No binario</mat-option>
                                    <mat-option value="Otro">Otro</mat-option>
                                    <mat-option value="Prefiere no decirlo">Prefiere no decirlo</mat-option>
                                </mat-select>
                                <mat-error *ngIf="error.gender.errors">
                                    <span *ngIf="error.gender.errors.required">¡Advertencia! Este campo es
                                        requerido</span>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="fill" *ngIf="show_other_field">
                              <mat-label>¿Cual?</mat-label>
                              <input type="text" matInput formControlName="other_gender"/>
                              <mat-error *ngIf="error.other_gender.errors">
                                <span *ngIf="error.other_gender.errors.required">¡Advertencia! Este campo es
                                    requerido</span>
                            </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="fill" *ngIf="show_identity_name_field">
                              <mat-label>Nombre identitario</mat-label>
                              <mat-icon matSuffix matTooltip='Información para el candidato: "Sabías que de acuerdo con tu género puedes ser reconocid@ con tu nombre identitario, agradecemos brindarnos esta información."'><i class="fi-rr-info"></i></mat-icon>
                              <input type="text" matInput formControlName="identity_name"/>
                            </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Grupo Sanguineo</mat-label>
                                  <mat-select matNativeControl formControlName="blood_type" required
                                      [disabled]="read_only_blood_type">
                                      <mat-option value="A">A</mat-option>
                                      <mat-option value="B">B</mat-option>
                                      <mat-option value="O">O</mat-option>
                                      <mat-option value="AB">AB</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="error.blood_type.errors">
                                      <span *ngIf="error.blood_type.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>RH</mat-label>
                                  <mat-select matNativeControl formControlName="rh" required
                                      [disabled]="read_only_rh">
                                      <mat-option value="Positivo">Positivo</mat-option>
                                      <mat-option value="Negativo">Negativo</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="error.rh.errors">
                                      <span *ngIf="error.rh.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>¿Pertenece a algún grupo?</mat-label>
                                  <mat-select formControlName="social_group">
                                      <mat-option value=1>Ninguno</mat-option>
                                      <mat-option value=2>Mujer cabeza de hogar</mat-option>
                                      <mat-option value=3>Hombre cabeza de hogar</mat-option>
                                      <mat-option value=2>Comunidad LGBTI+Q</mat-option>
                                      <mat-option value=1>Victima del conflicto armado</mat-option>
                                      <mat-option value=2>Afrodecendiente</mat-option>
                                      <mat-option value=2>Indigena</mat-option>
                                  </mat-select>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>EPS</mat-label>
                                  <mat-select formControlName="eps" (selectionChange)="epsChange($event.value)" required>
                                      <mat-option *ngFor="let option of epsData" [value]="option.name">
                                          {{option.name}}
                                      </mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="error.eps.errors">
                                      <span *ngIf="error.eps.errors.required">¡Advertencia! Este campo es requerido</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Fondo de pensiones</mat-label>
                                  <mat-select formControlName="caja_de_pensiones"
                                      (selectionChange)="pensionesChange($event.value)" required>
                                      <mat-option *ngFor="let option of pensionesData" [value]="option.name">
                                          {{option.name}}
                                      </mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="error.caja_de_pensiones.errors">
                                      <span *ngIf="error.caja_de_pensiones.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Ciudad de Residencia</mat-label>
                                  <input matInput formControlName="city_of_residence" required>
                                  <mat-error *ngIf="error.city_of_residence.errors">
                                      <span *ngIf="error.city_of_residence.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                                  <mat-error *ngIf="error.city_of_residence.errors">
                                      <span *ngIf="error.city_of_residence.errors.maxlength">¡Advertencia! Este campo acepta
                                          maximo 20 caracteres</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Localidad</mat-label>
                                  <input matInput formControlName="location" required>
                                  <mat-error *ngIf="error.location.errors">
                                      <span *ngIf="error.location.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Barrio</mat-label>
                                  <input matInput formControlName="neighborhood" required>
                                  <mat-error *ngIf="error.neighborhood.errors">
                                      <span *ngIf="error.neighborhood.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                                  <mat-error *ngIf="error.neighborhood.errors">
                                      <span *ngIf="error.neighborhood.errors.maxlength">¡Advertencia! Este campo acepta maximo
                                          20 caracteres</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Estrato</mat-label>
                                  <mat-select formControlName="stratum" required>
                                      <mat-option value=1>1</mat-option>
                                      <mat-option value=2>2</mat-option>
                                      <mat-option value=3>3</mat-option>
                                      <mat-option value=4>4</mat-option>
                                      <mat-option value=5>5</mat-option>
                                      <mat-option value=6>6</mat-option>
                                      <mat-option value=7>7</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="error.stratum.errors">
                                      <span *ngIf="error.stratum.errors.required">¡Advertencia! Este campo es requerido</span>
                                  </mat-error>
                              </mat-form-field>
                              <mat-form-field appearance="fill">
                                  <mat-label>Direccion</mat-label>
                                  <input matInput formControlName="adress" required readonly (click)="showAddressForm()">
                                  <mat-error *ngIf="error.adress.errors">
                                      <span *ngIf="error.adress.errors.required">¡Advertencia! Este campo es requerido</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Tipo de Vivienda</mat-label>
                                  <mat-select formControlName="housing_type" required>
                                      <mat-option value=Familiar>Familiar</mat-option>
                                      <mat-option value=Arriendo>Arriendo</mat-option>
                                      <mat-option value=Propia>Propia</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="error.housing_type.errors">
                                      <span *ngIf="error.housing_type.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Aspiración Salarial</mat-label>
                                  <mat-select matNativeControl formControlName="wage_aspiration" required>
                                      <mat-option value="1 a 2 Salarios mínimos mensuales">1 a 2 Salarios mínimos
                                          mensuales</mat-option>
                                      <mat-option value="2 a 3 Salarios mínimos mensuales">2 a 3 Salarios mínimos
                                          mensuales</mat-option>
                                      <mat-option value="3 a 4 Salarios mínimos mensuales">3 a 4 Salarios mínimos
                                          mensuales</mat-option>
                                      <mat-option value="4 a 5 Salarios mínimos mensuales">4 a 5 Salarios mínimos
                                          mensuales</mat-option>
                                      <mat-option value="5 a 6 Salarios mínimos mensuales">5 a 6 Salarios mínimos
                                          mensuales</mat-option>
                                      <mat-option value="6 a 7 Salarios mínimos mensuales">6 a 7 Salarios mínimos
                                          mensuales</mat-option>
                                      <mat-option value="7 a 8 Salarios mínimos mensuales">7 a 8 Salarios mínimos
                                          mensuales</mat-option>
                                      <mat-option value="8 a 9 Salarios mínimos mensuales">8 a 9 Salarios mínimos
                                          mensuales</mat-option>
                                      <mat-option value="9 a 10 Salarios mínimos mensuales">9 a 10 Salarios mínimos
                                          mensuales</mat-option>
                                      <mat-option value="10 a 11 Salarios mínimos mensuales">10 a 11 Salarios mínimos
                                          mensuales</mat-option>
                                      <mat-option value="11 a 12 Salarios mínimos mensuales">11 a 12 Salarios mínimos
                                          mensuales</mat-option>
                                      <mat-option value="12 a 13 Salarios mínimos mensuales">12 a 13 Salarios mínimos
                                          mensuales</mat-option>
                                      <mat-option value="13 a 14 Salarios mínimos mensuales">13 a 14 Salarios mínimos
                                          mensuales</mat-option>
                                      <mat-option value="14 o más Salarios mínimos mensuales">14 o más Salarios mínimos
                                          mensuales</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="error.wage_aspiration.errors">
                                      <span *ngIf="error.wage_aspiration.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill" style="width: 66%;">
                                  <mat-label>Hobbies</mat-label>
                                  <input matInput formControlName="hobbie" required>
                                  <mat-error *ngIf="error.hobbie.errors">
                                      <span *ngIf="error.hobbie.errors.required">¡Advertencia! Este campo es requerido</span>
                                  </mat-error>
                                  <mat-error *ngIf="error.hobbie.errors">
                                      <span *ngIf="error.hobbie.errors.maxlength">¡Advertencia! Este campo acepta maximo 30
                                          caracteres</span>
                                  </mat-error>
                              </mat-form-field>


                              <mat-form-field appearance="fill">
                                  <mat-label>En caso de emergencia contactar a</mat-label>
                                  <input matInput formControlName="emergency_contact" required>
                                  <mat-error *ngIf="error.emergency_contact.errors">
                                      <span *ngIf="error.emergency_contact.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                                  <mat-error *ngIf="error.emergency_contact.errors">
                                      <span *ngIf="error.emergency_contact.errors.maxlength">¡Advertencia! Este campo acepta
                                          maximo 30 caracteres</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Teléfono de contacto</mat-label>
                                  <input matInput formControlName="emergency_number" type="number" required>
                                  <mat-error *ngIf="error.emergency_number.errors">
                                      <span *ngIf="error.emergency_number.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Talla Zapatos</mat-label>
                                  <input matInput formControlName="shoe_size" type="number" required>
                                  <mat-error *ngIf="error.shoe_size.errors">
                                      <span *ngIf="error.shoe_size.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                                  <mat-error *ngIf="error.shoe_size.errors">
                                      <span *ngIf="error.shoe_size.errors.maxlength">¡Advertencia! Este campo acepta maximo 10
                                          caracteres</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Talla de Camisa</mat-label>
                                  <input matInput formControlName="shirt_size" required>
                                  <mat-error *ngIf="error.shirt_size.errors">
                                      <span *ngIf="error.shirt_size.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                                  <mat-error *ngIf="error.shirt_size.errors">
                                      <span *ngIf="error.shirt_size.errors.maxlength">¡Advertencia! Este campo acepta maximo
                                          10 caracteres</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Talla de Pantalón</mat-label>
                                  <input matInput formControlName="pants_size" required>
                                  <mat-error *ngIf="error.pants_size.errors">
                                      <span *ngIf="error.pants_size.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                                  <mat-error *ngIf="error.pants_size.errors">
                                      <span *ngIf="error.pants_size.errors.maxlength">¡Advertencia! Este campo acepta maximo
                                          10 caracteres</span>
                                  </mat-error>
                              </mat-form-field>

                          </div>
                      </mat-expansion-panel>
                  </mat-accordion>
                  <mat-accordion>
                      <mat-expansion-panel>
                          <mat-expansion-panel-header>
                              <mat-panel-title>
                                  <h4 class="pt-3">Información bancaria</h4>
                              </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="forms">
                              <p class="w-100 p-1" style="font-size: 13px;">Si su número de cuenta tiene ceros al
                                  inicio éstos también deberán ser
                                  diligenciados</p>

                              <mat-form-field appearance="fill">
                                  <mat-label>Banco</mat-label>
                                  <mat-select formControlName="banco" (selectionChange)="bancoChange($event.value)"
                                      required>
                                      <mat-option *ngFor="let option of bankData" [value]="option.name">
                                          {{option.name}}
                                      </mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="error.banco.errors">
                                      <span *ngIf="error.banco.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Tipo de cuenta</mat-label>
                                  <mat-select formControlName="kind_account"
                                      (selectionChange)="kindAccountChange($event.value)" required>
                                      <mat-option *ngFor="let option of kindAccountList" [value]="option.name">
                                          {{option.name}}
                                      </mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="error.kind_account.errors">
                                      <span *ngIf="error.kind_account.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Número de cuenta</mat-label>
                                  <input matInput formControlName="bank_account" required [min]="0"
                                      (keypress)="keyPress($event)" onpaste="return false">
                                  <mat-error *ngIf="error.bank_account.errors">
                                      <span *ngIf="error.bank_account.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Confirmar numero de cuenta</mat-label>
                                  <input matInput formControlName="bank_account_comfirm" required [min]="0"
                                      (keypress)="keyPress($event)" onpaste="return false">
                                  <mat-error *ngIf="error.bank_account_comfirm.errors"><span
                                          *ngIf="!error.bank_account_comfirm.errors.isMatching">Su cuenta no es igual
                                          a la ingresada
                                          previamente</span></mat-error>
                              </mat-form-field>

                          </div>
                      </mat-expansion-panel>
                  </mat-accordion>
                  <mat-accordion>
                      <mat-expansion-panel>
                          <mat-expansion-panel-header>
                              <mat-panel-title>
                                  <h4 class="pt-3">Información familiar</h4>
                              </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="forms">

                              <mat-form-field appearance="fill">
                                  <mat-label>Número de personas a cargo</mat-label>
                                  <input matInput type="number" formControlName="number_people_charge" required>
                                  <mat-error *ngIf="error.number_people_charge.errors">
                                      <span *ngIf="error.number_people_charge.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>¿Cuántas personas viven en su residencia?</mat-label>
                                  <input matInput type="number" formControlName="number_people_at_home" required>
                                  <mat-error *ngIf="error.number_people_at_home.errors">
                                      <span *ngIf="error.number_people_at_home.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Número de Hijos</mat-label>
                                  <input matInput type="number" formControlName="children_number"
                                      (ngModelChange)="getChildrensInputs()" required>
                                  <mat-error *ngIf="error.children_number.errors">
                                      <span *ngIf="error.children_number.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                              </mat-form-field>

                              <div [formGroup]="children" fxFlex=100>
                                  <div fxLayout="row wrap" *ngFor="let child of children.controls; let i = index"
                                      [formGroupName]="i">
                                      <mat-form-field appearance="fill">
                                          <mat-label>Nombre completo</mat-label>
                                          <input matInput type="text" formControlName="name"
                                              placeholder="Nombre completo de su hijo">
                                      </mat-form-field>
                                      <mat-form-field appearance="fill">
                                          <mat-label>Edad</mat-label>
                                          <input matInput type="number" formControlName="age" placeholder="Edad de su hijo">
                                      </mat-form-field>
                                  </div>
                              </div>

                          </div>
                      </mat-expansion-panel>
                  </mat-accordion>

                  <mat-accordion>
                      <mat-expansion-panel>
                          <mat-expansion-panel-header>
                              <mat-panel-title>
                                  <h4 class="pt-3">Situación militar</h4>
                              </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="forms">
                              <mat-form-field appearance="fill">
                                  <mat-label>Situación Militar Definida</mat-label>
                                  <mat-select matNativeControl formControlName="military_situation" required>
                                      <mat-option value="Si">Si</mat-option>
                                      <mat-option value="No">No</mat-option>
                                      <mat-option value="No aplica">No aplica</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="error.military_situation.errors">
                                      <span *ngIf="error.military_situation.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                              </mat-form-field>

                             <!-- <mat-form-field appearance="fill">
                                  <mat-label>Número de Libreta</mat-label>
                                  <input type="number" matInput formControlName="notebook_number" type="notebook_number">
                                  <mat-error *ngIf="error.notebook_number.errors">
                                      <span *ngIf="error.notebook_number.errors.maxlength">¡Advertencia! Este campo acepta
                                          maximo 20 caracteres</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Clase de Libreta</mat-label>
                                  <mat-select matNativeControl formControlName="notebook_class">
                                      <mat-option value="Primera categoría">Primera categoría</mat-option>
                                      <mat-option value="Segunda categoría">Segunda categoría</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="error.notebook_class.errors">
                                      <span *ngIf="error.notebook_class.errors.maxlength">¡Advertencia! Este campo acepta
                                          maximo 20 caracteres</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Número de Distrito Militar</mat-label>
                                  <input matInput formControlName="notebook_number" type="military_district_number">
                                  <mat-error *ngIf="error.notebook_number.errors">
                                      <span *ngIf="error.notebook_number.errors.maxlength">¡Advertencia! Este campo acepta
                                          maximo 20 caracteres</span>
                                  </mat-error>
                              </mat-form-field>
                              -->
                          </div>
                      </mat-expansion-panel>
                  </mat-accordion>
                  <mat-accordion>
                      <mat-expansion-panel>
                          <mat-expansion-panel-header>
                              <mat-panel-title>
                                  <h4 class="pt-3">Dezplazamiento</h4>
                              </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="forms">

                              <mat-form-field appearance="fill">
                                  <mat-label>Tiempo de Desplazamiento</mat-label>
                                  <mat-select matNativeControl formControlName="travel_time" required>
                                      <mat-option value="1 a 30 minutos">1 a 30 minutos</mat-option>
                                      <mat-option value="30 a 60 minutos">30 a 60 minutos</mat-option>
                                      <mat-option value="60 a 90 minutos">60 a 90 minutos</mat-option>
                                      <mat-option value="más de 120 minutos">más de 120 minutos</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="error.travel_time.errors">
                                      <span *ngIf="error.travel_time.errors.required">¡Advertencia! Este campo es
                                          requerido</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Tiene Vehículo</mat-label>
                                  <mat-select matNativeControl formControlName="vehicle">
                                      <mat-option value="Si">Si</mat-option>
                                      <mat-option value="No">No</mat-option>
                                  </mat-select>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Tipo de Vehículo</mat-label>
                                  <mat-select matNativeControl formControlName="vehicle_type">
                                      <mat-option value="Automovil">Automovil</mat-option>
                                      <mat-option value="Motocicleta">Motocicleta</mat-option>
                                      <mat-option value="Transporte público">Transporte público</mat-option>
                                  </mat-select>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label># Licencia de conducción</mat-label>
                                  <input matInput type="text" formControlName="licence_number">
                                  <mat-error *ngIf="error.licence_number.errors">
                                      <span *ngIf="error.licence_number.errors.maxlength">¡Advertencia! Este campo acepta
                                          maximo 20 caracteres</span>
                                  </mat-error>
                              </mat-form-field>

                              <mat-form-field appearance="fill">
                                  <mat-label>Categoría Licencia</mat-label>
                                  <mat-select matNativeControl formControlName="licence_type">
                                      <mat-option value="A1">A1</mat-option>
                                      <mat-option value="A2">A2</mat-option>
                                      <mat-option value="B1">B1</mat-option>
                                      <mat-option value="B2">B2</mat-option>
                                      <mat-option value="B3">B3</mat-option>
                                      <mat-option value="C1">C1</mat-option>
                                      <mat-option value="C2">C2</mat-option>
                                      <mat-option value="C3">C3</mat-option>
                                  </mat-select>
                              </mat-form-field>
                          </div>
                      </mat-expansion-panel>
                  </mat-accordion>
              </form>
          </div>

      </mat-tab>
      <mat-tab label="Experiencia Laboral">
          <div class="info-section">
              <h4>Experiencia Laboral</h4>
              <div *ngIf="workExperiencies.length != 0">
                  <table mat-table [dataSource]="dataSourceWorkExperiences">
                      <ng-container matColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef class="start"> Acciones </th>
                          <td mat-cell *matCellDef="let element">
                              <button mat-icon-button (click)="editFill(element,'secondFormGroup')"><span
                                      class="fi-rr-edit"></span></button>
                              <button mat-icon-button
                                  (click)="deleteFill(element.id,'workExperiencies','dataSourceWorkExperiences','jobs_references')"><span
                                      class="fi-rr-trash"></span></button>
                          </td>
                      </ng-container>
                      <ng-container matColumnDef="company_name">
                          <th mat-header-cell *matHeaderCellDef> Empresa </th>
                          <td mat-cell *matCellDef="let element"> {{element.company_name}} </td>
                      </ng-container>

                      <ng-container matColumnDef="position_hired">
                          <th mat-header-cell *matHeaderCellDef> Cargo </th>
                          <td mat-cell *matCellDef="let element"> {{element.position_hired}} </td>
                      </ng-container>

                      <ng-container matColumnDef="boss_name">
                          <th mat-header-cell *matHeaderCellDef> Jefe </th>
                          <td mat-cell *matCellDef="let element"> {{element.boss_name}} </td>
                      </ng-container>
                      <ng-container matColumnDef="boss_mobile_phone">
                          <th mat-header-cell *matHeaderCellDef> Celular </th>
                          <td mat-cell *matCellDef="let element"> {{element.boss_mobile_phone}} </td>
                      </ng-container>
                      <ng-container matColumnDef="start_date">
                          <th mat-header-cell *matHeaderCellDef> Fecha Inicio </th>
                          <td mat-cell *matCellDef="let element"> {{element.start_date | date}} </td>
                      </ng-container>

                      <ng-container matColumnDef="end_date">
                          <th mat-header-cell *matHeaderCellDef class="end"> Fecha Final </th>
                          <td mat-cell *matCellDef="let element"> {{element.end_date | date}} </td>
                      </ng-container>



                      <tr mat-header-row *matHeaderRowDef="displayedColumnsWorkExperiencies"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsWorkExperiencies;"></tr>
                  </table>
              </div>
          </div>
          <div class="info-section">
              <form [formGroup]="secondFormGroup">
                  <div class="forms">
                      <mat-form-field appearance="fill">
                          <mat-label>Nombre Empresa</mat-label>
                          <input matInput formControlName="company_name" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Teléfono Empresa</mat-label>
                          <input type="number" matInput formControlName="company_phone" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Cargo Desempeñado</mat-label>
                          <input matInput formControlName="position_hired" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Ciudad</mat-label>
                          <input matInput formControlName="company_city" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Nombre del Jefe</mat-label>
                          <input matInput formControlName="boss_name" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Cargo del Jefe</mat-label>
                          <input matInput formControlName="boss_position" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Teléfono movil del Jefe</mat-label>
                          <input matInput formControlName="boss_mobile_phone" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Teléfono del Jefe</mat-label>
                          <input matInput formControlName="boss_phone" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Trabajo Actual</mat-label>
                          <mat-select matNativeControl formControlName="current_job" required>
                              <mat-option value="Si">Si</mat-option>
                              <mat-option value="No">No</mat-option>
                          </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Fecha de inicio</mat-label>
                          <input matInput [matDatepicker]="picker2" formControlName="start_date" [max]="now" required>
                          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                          <mat-datepicker #picker2></mat-datepicker>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Fecha de finalización</mat-label>
                          <input matInput [matDatepicker]="picker3" formControlName="end_date" [max]="now">
                          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                          <mat-datepicker #picker3></mat-datepicker>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Salario Devengado</mat-label>
                          <mat-select matNativeControl formControlName="earned_salary" required>
                              <mat-option value="1 a 2 Salarios mínimos mensuales">1 a 2 Salarios mínimos
                                  mensuales</mat-option>
                              <mat-option value="2 a 3 Salarios mínimos mensuales">2 a 3 Salarios mínimos
                                  mensuales</mat-option>
                              <mat-option value="3 a 4 Salarios mínimos mensuales">3 a 4 Salarios mínimos
                                  mensuales</mat-option>
                              <mat-option value="4 a 5 Salarios mínimos mensuales">4 a 5 Salarios mínimos
                                  mensuales</mat-option>
                              <mat-option value="5 a 6 Salarios mínimos mensuales">5 a 6 Salarios mínimos
                                  mensuales</mat-option>
                              <mat-option value="6 a 7 Salarios mínimos mensuales">6 a 7 Salarios mínimos
                                  mensuales</mat-option>
                              <mat-option value="7 a 8 Salarios mínimos mensuales">7 a 8 Salarios mínimos
                                  mensuales</mat-option>
                              <mat-option value="8 a 9 Salarios mínimos mensuales">8 a 9 Salarios mínimos
                                  mensuales</mat-option>
                              <mat-option value="9 a 10 Salarios mínimos mensuales">9 a 10 Salarios mínimos
                                  mensuales</mat-option>
                              <mat-option value="10 a 11 Salarios mínimos mensuales">10 a 11 Salarios mínimos
                                  mensuales</mat-option>
                              <mat-option value="11 a 12 Salarios mínimos mensuales">11 a 12 Salarios mínimos
                                  mensuales</mat-option>
                              <mat-option value="12 a 13 Salarios mínimos mensuales">12 a 13 Salarios mínimos
                                  mensuales</mat-option>
                              <mat-option value="13 a 14 Salarios mínimos mensuales">13 a 14 Salarios mínimos
                                  mensuales</mat-option>
                              <mat-option value="14 o más Salarios mínimos mensuales">14 o más Salarios mínimos
                                  mensuales</mat-option>
                          </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Motivo del retiro</mat-label>
                          <mat-select matNativeControl formControlName="retirement_reason" required>
                              <mat-option value="Terminación del contrato">Terminación del contrato</mat-option>
                              <mat-option value="Renuncia voluntaria" selected>Renuncia voluntaria</mat-option>
                              <mat-option value="Terminación del contrato con justa causa" selected>Terminación del
                                  contrato con justa causa</mat-option>
                              <mat-option value="Terminación del contrato sin justa causa" selected>Terminación del
                                  contrato sin justa causa</mat-option>
                              <mat-option value="Retiro no negativo" selected>Retiro no negativo</mat-option>
                              <mat-option value="Otro" selected>Otro</mat-option>
                          </mat-select>
                      </mat-form-field>

                      <mat-form-field appearance="fill">
                          <mat-label>Funciones y logros</mat-label>
                          <textarea matInput formControlName="functions_achievements" required></textarea>
                      </mat-form-field>
                      <div style="padding: 10px">
                          <button mat-stroked-button color="primary"
                              (click)="add('secondFormGroup', 'workExperiencies', 'dataSourceWorkExperiences','jobs_references')"
                              [disabled]="!secondFormGroup.valid">Agregar</button>
                      </div>

                  </div>

              </form>
          </div>
      </mat-tab>
      <mat-tab label="Estudios">
          <div class="info-section">
              <h4>Estudios</h4>
              <div *ngIf="studies.length != 0">
                  <table mat-table [dataSource]="dataSourceStudies">
                      <ng-container matColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef class="start"> Acciones </th>
                          <td mat-cell *matCellDef="let element">
                              <button mat-icon-button (click)="editFill(element, 'thirdFormGroup')"><span
                                      class="fi-rr-edit"></span></button>
                              <button mat-icon-button
                                  (click)="deleteFill(element.id, 'studies','dataSourceStudies', 'education')"><span
                                      class="fi-rr-trash"></span></button>
                          </td>
                      </ng-container>
                      <ng-container matColumnDef="education_level">
                          <th mat-header-cell *matHeaderCellDef> Nivel </th>
                          <td mat-cell *matCellDef="let element"> {{element.education_level}} </td>
                      </ng-container>

                      <ng-container matColumnDef="institution">
                          <th mat-header-cell *matHeaderCellDef> Institución </th>
                          <td mat-cell *matCellDef="let element"> {{element.institution}} </td>
                      </ng-container>

                      <ng-container matColumnDef="obtained_title">
                          <th mat-header-cell *matHeaderCellDef> Título </th>
                          <td mat-cell *matCellDef="let element"> {{element.obtained_title}} </td>
                      </ng-container>

                      <ng-container matColumnDef="study_city">
                          <th mat-header-cell *matHeaderCellDef> Ciudad </th>
                          <td mat-cell *matCellDef="let element"> {{element.study_city}} </td>
                      </ng-container>

                      <ng-container matColumnDef="finish_date">
                          <th mat-header-cell *matHeaderCellDef> Fecha Fianlización </th>
                          <td mat-cell *matCellDef="let element"> {{element.finish_date | date}} </td>
                      </ng-container>

                      <ng-container matColumnDef="periods_completed">
                          <th mat-header-cell *matHeaderCellDef class="end"> Periodos Cursados </th>
                          <td mat-cell *matCellDef="let element"> {{element.periods_completed}} </td>
                      </ng-container>



                      <tr mat-header-row *matHeaderRowDef="displayedColumnsStudies"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsStudies;"></tr>
                  </table>
              </div>
          </div>
          <div class="info-section">
              <form [formGroup]="thirdFormGroup">
                  <div class="forms">
                      <mat-form-field appearance="fill">
                          <mat-label>Estudia Actualmente</mat-label>
                          <mat-select matNativeControl formControlName="currently_studying" required>
                              <mat-option value="Si">Si</mat-option>
                              <mat-option value="No">No</mat-option>
                          </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Nivel de Estudios</mat-label>
                          <mat-select matNativeControl formControlName="education_level" required>
                              <mat-option value="Primaria">Primaria</mat-option>
                              <mat-option value="Bachillerato">Bachillerato</mat-option>
                              <mat-option value="Técnico">Técnico</mat-option>
                              <mat-option value="Tecnológico">Tecnológico</mat-option>
                              <mat-option value="Universitario">Universitario</mat-option>
                              <mat-option value="Especialización">Especialización</mat-option>
                              <mat-option value="Magister">Magister</mat-option>
                              <mat-option value="PHD">PHD</mat-option>
                              <mat-option value="Doctorado">Doctorado</mat-option>
                              <mat-option value="Diplomado">Diplomado</mat-option>
                              <mat-option value="Sena">Sena</mat-option>
                              <mat-option value="Otro">Otro</mat-option>
                          </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Institución</mat-label>
                          <input matInput formControlName="institution" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Título Obtenido</mat-label>
                          <input matInput formControlName="obtained_title" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Ciudad de Estudio</mat-label>
                          <input matInput formControlName="study_city" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Fecha de Finalización</mat-label>
                          <input matInput [matDatepicker]="picker4" formControlName="finish_date" [max]="now">
                          <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                          <mat-datepicker #picker4></mat-datepicker>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Periodos Cursados</mat-label>
                          <input matInput formControlName="periods_completed" required>
                      </mat-form-field>
                      <div style="padding: 10px">
                          <button mat-stroked-button color="primary"
                              (click)="add('thirdFormGroup','studies','dataSourceStudies', 'education')"
                              [disabled]="!thirdFormGroup.valid">Agregar</button>
                      </div>
                  </div>

              </form>

          </div>
      </mat-tab>
      <mat-tab label="Grupo Familiar">
          <div class="info-section">
              <h4>Grupo Familiar</h4>
              <div *ngIf="familyGroup.length !=0">
                  <table mat-table [dataSource]="dataSourceFamilyGoup">

                      <ng-container matColumnDef="first_name">
                          <th mat-header-cell *matHeaderCellDef> Nombre </th>
                          <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
                      </ng-container>

                      <ng-container matColumnDef="last_name">
                          <th mat-header-cell *matHeaderCellDef> Apellido </th>
                          <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
                      </ng-container>

                      <ng-container matColumnDef="id_document_number">
                          <th mat-header-cell *matHeaderCellDef> Documento </th>
                          <td mat-cell *matCellDef="let element"> {{element.id_document_number}} </td>
                      </ng-container>

                      <ng-container matColumnDef="education_level">
                          <th mat-header-cell *matHeaderCellDef> Educación </th>
                          <td mat-cell *matCellDef="let element"> {{element.education_level}} </td>
                      </ng-container>

                      <ng-container matColumnDef="relationship">
                          <th mat-header-cell *matHeaderCellDef> Parentesco </th>
                          <td mat-cell *matCellDef="let element"> {{element.relationship}} </td>
                      </ng-container>

                      <ng-container matColumnDef="profession">
                          <th mat-header-cell *matHeaderCellDef class="end"> Profesión </th>
                          <td mat-cell *matCellDef="let element"> {{element.profession}} </td>
                      </ng-container>

                      <ng-container matColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef class="start"> Acciones </th>
                          <td mat-cell *matCellDef="let element">
                              <button mat-icon-button (click)="editFill(element, 'fourthFormGroup')"><span
                                      class="fi-rr-edit"></span></button>
                              <button mat-icon-button
                                  (click)="deleteFill(element.id, 'familyGroup','dataSourceFamilyGoup','group_family')"><span
                                      class="fi-rr-trash"></span></button>
                          </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumnsFamily"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsFamily;"></tr>
                  </table>
              </div>
          </div>
          <div class="info-section">
              <form [formGroup]="fourthFormGroup">
                  <div class="forms">
                      <mat-form-field appearance="fill">
                          <mat-label>Tipo Documento</mat-label>
                          <mat-select matNativeControl formControlName="id_document_type" required>
                              <mat-option value="Cédula de ciudadanía">Cédula de ciudadanía</mat-option>
                              <mat-option value="Cédula de Extranjería">Cédula de Extranjería</mat-option>
                              <mat-option value="Pasaporte">Pasaporte</mat-option>
                              <mat-option value="Permiso especial de permanencia">Permiso especial de
                                  permanencia</mat-option>
                          </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Número de documento</mat-label>
                          <input matInput type="number" formControlName="id_document_number" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Ciudad de expedición documento</mat-label>
                          <input matInput formControlName="city_document_id" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Fecha Expedición</mat-label>
                          <input matInput [matDatepicker]="picker5" formControlName="expedition_date_id" [max]="now"
                              required>
                          <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                          <mat-datepicker #picker5></mat-datepicker>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Primer Nombre</mat-label>
                          <input matInput formControlName="first_name" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Segundo Nombre</mat-label>
                          <input matInput formControlName="middle_name">
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Primer Apellido</mat-label>
                          <input matInput formControlName="last_name" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Segundo Apellido</mat-label>
                          <input matInput formControlName="second_last_name">
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Nivel de Estudios</mat-label>
                          <mat-select matNativeControl formControlName="education_level" required>
                              <mat-option value="Primaria">Primaria</mat-option>
                              <mat-option value="Bachillerato">Bachillerato</mat-option>
                              <mat-option value="Técnico">Técnico</mat-option>
                              <mat-option value="Tecnológico">Tecnológico</mat-option>
                              <mat-option value="Universitario">Universitario</mat-option>
                              <mat-option value="Especialización">Especialización</mat-option>
                              <mat-option value="Magister">Magister</mat-option>
                              <mat-option value="PHD">PHD</mat-option>
                              <mat-option value="Doctorado">Doctorado</mat-option>
                              <mat-option value="Diplomado">Diplomado</mat-option>
                              <mat-option value="Sena">Sena</mat-option>
                              <mat-option value="Otro">Otro</mat-option>
                          </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Parentesco</mat-label>
                          <mat-select matNativeControl formControlName="relationship" required>
                              <mat-option value="Madre">Madre</mat-option>
                              <mat-option value="Padre">Padre</mat-option>
                              <mat-option value="Hijo">Hijo</mat-option>
                              <mat-option value="Hijastro">Hijastro</mat-option>
                              <mat-option value="Conyuge">Conyuge</mat-option>
                              <mat-option value="Padrastro">Padrastro</mat-option>
                              <mat-option value="Compañero permanente">Compañero permanente</mat-option>
                              <mat-option value="Tío">Tío</mat-option>
                              <mat-option value="Sobrino">Sobrino</mat-option>
                              <mat-option value="Abuelo">Abuelo</mat-option>
                              <mat-option value="Hermano">Hermano</mat-option>
                              <mat-option value="Nieto">Nieto</mat-option>
                              <mat-option value="Cuñado">Cuñado</mat-option>
                              <mat-option value="Suegro">Suegro</mat-option>
                              <mat-option value="Primo">Primo</mat-option>
                          </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Género</mat-label>
                          <mat-select matNativeControl formControlName="gender" required>
                              <mat-option value="Femenino">Femenino</mat-option>
                              <mat-option value="Masculino">Masculino</mat-option>
                          </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Fecha de Nacimiento</mat-label>
                          <input matInput [matDatepicker]="picker6" formControlName="birthdate" [max]="now" required>
                          <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                          <mat-datepicker #picker6></mat-datepicker>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Profesión</mat-label>
                          <input matInput formControlName="profession">
                      </mat-form-field>
                      <div style="padding: 10px">
                          <button mat-stroked-button color="primary"
                              (click)="add('fourthFormGroup','familyGroup','dataSourceFamilyGoup','group_family')"
                              [disabled]="!fourthFormGroup.valid">Agregar</button>
                      </div>
                  </div>
              </form>
          </div>

      </mat-tab>
      <mat-tab label="Referencias Personales">
          <div class="info-section">
              <h4>Referencias Personales</h4>
              <div *ngIf="personalReferences.length != 0">
                  <table mat-table [dataSource]="dataSourceReferences">
                      <ng-container matColumnDef="first_name">
                          <th mat-header-cell *matHeaderCellDef> Nombre </th>
                          <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
                      </ng-container>

                      <ng-container matColumnDef="last_name">
                          <th mat-header-cell *matHeaderCellDef> Apellido </th>
                          <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
                      </ng-container>

                      <ng-container matColumnDef="relationship">
                          <th mat-header-cell *matHeaderCellDef class="end">Parentesco </th>
                          <td mat-cell *matCellDef="let element"> {{element.relationship}} </td>
                      </ng-container>

                      <ng-container matColumnDef="mobile_phone">
                          <th mat-header-cell *matHeaderCellDef> Celular </th>
                          <td mat-cell *matCellDef="let element"> {{element.mobile_phone}} </td>
                      </ng-container>

                      <ng-container matColumnDef="city">
                          <th mat-header-cell *matHeaderCellDef> Ciudad </th>
                          <td mat-cell *matCellDef="let element"> {{element.city}} </td>
                      </ng-container>

                      <ng-container matColumnDef="occupation">
                          <th mat-header-cell *matHeaderCellDef> Ocupación </th>
                          <td mat-cell *matCellDef="let element"> {{element.occupation}} </td>
                      </ng-container>

                      <ng-container matColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef class="start"> Acciones </th>
                          <td mat-cell *matCellDef="let element">
                              <button mat-icon-button (click)="editFill(element, 'fifthFormGroup')"><span
                                      class="fi-rr-edit"></span></button>
                              <button mat-icon-button
                                  (click)="deleteFill(element.id, 'personalReferences','dataSourceReferences', 'personal_references')"><span
                                      class="fi-rr-trash"></span></button>
                          </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedPersonalReference"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedPersonalReference;"></tr>
                  </table>
              </div>
          </div>
          <div class="info-section">
              <form [formGroup]="fifthFormGroup">
                  <div class="forms">
                      <mat-form-field appearance="fill">
                          <mat-label>Primer Nombre</mat-label>
                          <input matInput formControlName="first_name" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Segundo Nombre</mat-label>
                          <input matInput formControlName="middle_name">
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Primer Apellido</mat-label>
                          <input matInput formControlName="last_name" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Segundo Apellido</mat-label>
                          <input matInput formControlName="second_last_name">
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Parentesco</mat-label>
                          <mat-select matNativeControl formControlName="relationship" required>
                              <mat-option value="Madre">Madre</mat-option>
                              <mat-option value="Padre">Padre</mat-option>
                              <mat-option value="Hijo">Hijo</mat-option>
                              <mat-option value="Hijastro">Hijastro</mat-option>
                              <mat-option value="Conyuge">Conyuge</mat-option>
                              <mat-option value="Padrastro">Padrastro</mat-option>
                              <mat-option value="Compañero permanente">Compañero permanente</mat-option>
                              <mat-option value="Tío">Tío</mat-option>
                              <mat-option value="Sobrino">Sobrino</mat-option>
                              <mat-option value="Abuelo">Abuelo</mat-option>
                              <mat-option value="Hermano">Hermano</mat-option>
                              <mat-option value="Nieto">Nieto</mat-option>
                              <mat-option value="Cuñado">Cuñado</mat-option>
                              <mat-option value="Suegro">Suegro</mat-option>
                              <mat-option value="Primo">Primo</mat-option>
                          </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Celular</mat-label>
                          <input type="number" matInput formControlName="mobile_phone" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Teléfono (Opcional)</mat-label>
                          <input type="number" matInput formControlName="phone">
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Ciudad</mat-label>
                          <input matInput formControlName="city" required>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Ocupación</mat-label>
                          <mat-select matNativeControl formControlName="occupation" required>
                              <mat-option value="Empleado">Empleado</mat-option>
                              <mat-option value="Desempleado">Desempleado</mat-option>
                          </mat-select>
                      </mat-form-field>
                      <div style="padding: 10px">
                          <button color="primary" mat-stroked-button
                              (click)="add('fifthFormGroup', 'personalReferences','dataSourceReferences', 'personal_references')"
                              [disabled]="!fifthFormGroup.valid">Agregar</button>
                      </div>
                  </div>
              </form>

          </div>

      </mat-tab>
      <mat-tab label="Herramientas Tecnológicas">
          <div class="info-section">
              <h4>Herramientas Tecnológicas</h4>
              <form [formGroup]="sixthFormGroup">
                  <div class="forms">
                      <mat-form-field appearance="fill">
                          <mat-label>Tiene Computador</mat-label>
                          <mat-select matNativeControl formControlName="computer">
                              <mat-option value="Si">Si</mat-option>
                              <mat-option value="No">No</mat-option>
                          </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Tipo de Computador</mat-label>
                          <mat-select matNativeControl formControlName="computer_type">
                              <mat-option value="Móvil">Móvil</mat-option>
                              <mat-option value="Fijo">Fijo</mat-option>
                          </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Memoria Ram</mat-label>
                          <mat-select matNativeControl formControlName="ram">
                              <mat-option value="1Gb">1Gb</mat-option>
                              <mat-option value="2Gb">2Gb</mat-option>
                              <mat-option value="4Gb">4Gb</mat-option>
                              <mat-option value="8Gb">8Gb</mat-option>
                              <mat-option value="16Gb">16Gb</mat-option>
                          </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Procesador</mat-label>
                          <input matInput formControlName="processor">
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Internet</mat-label>
                          <mat-select matNativeControl formControlName="internet">
                              <mat-option value="Si">Si</mat-option>
                              <mat-option value="No">No</mat-option>
                          </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                          <mat-label>Capacidad de Internet</mat-label>
                          <input matInput formControlName="internet_capacity">
                      </mat-form-field>
                  </div>
              </form>
          </div>

      </mat-tab>
      <mat-tab label="Documentos">
          <div class="info-section">
              <h4>Documentos</h4>

              <table mat-table [dataSource]="dataSourceDocuments">

                  <ng-container matColumnDef="file">
                      <th mat-header-cell *matHeaderCellDef> Documento </th>
                      <td mat-cell *matCellDef="let element"> {{element.file}} </td>
                  </ng-container>

                  <ng-container matColumnDef="state">
                      <th mat-header-cell *matHeaderCellDef class="end"> Estado </th>
                      <td mat-cell *matCellDef="let element"> <span *ngIf="element.source">Cargado</span> <span
                              *ngIf="!element.source">Pendiente por Cargar</span> </td>
                  </ng-container>


                  <!-- actions Column -->
                  <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef class="start"> Acciones </th>
                      <td mat-cell *matCellDef="let row">
                          <input hidden (change)="onFileSelected($event.target.files, row.file_attachment_id,row.id)"
                              #fileInput type="file" id="file">
                          <button *ngIf="row.source" mat-icon-button matTooltip="Descargar Documento"
                              (click)="downdloadFile(row.id, row.name)">
                              <i class=" fi-rr-cloud-download"></i>
                          </button>

                      </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsDocuments"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsDocuments;"></tr>
              </table>

          </div>
      </mat-tab>

  </mat-tab-group>
  <!-- (selectedTabChange)="getCandidates($event)" -->
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-flat-button color="primary" (click)="updated()">Actualizar</button>
</mat-dialog-actions>
