<div class="admin-solicitudes">

    <div class="header col-4">
        <h1>Solicitudes<i class="fas fa-container-storage    "></i></h1>
        <p>Revisa y/o gestiona las solicitudes, realiza cotizaciones y órdenes de compras</p>
    </div>

    <mat-tab-group class="remove-border-bottom" animationDuration="0ms">
        <mat-tab label="Solicitudes">
                <app-gestion-inicial-control></app-gestion-inicial-control>
        </mat-tab>
        <!-- <mat-tab label="Aprobadas/No aprobadas">
            <app-gestion-aprobacion></app-gestion-aprobacion>
        </mat-tab> -->
        <mat-tab label="Ingresos">
            <app-orden-compra-list-control></app-orden-compra-list-control>
        </mat-tab>
    </mat-tab-group>

</div>
