<div>

    <div *ngIf="editDay == false && editDay2 == false">
        <h2 mat-dialog-title style="padding: 0px 12px 0px 6px; margin: 0px;">
            <button style="float: right;" mat-icon-button mat-dialog-close>
                <i class="fi-rr-cross-small"></i>
            </button>
        </h2>
        <mat-dialog-content style=" overflow-x: hidden" class="mat-typography">
            <div style="text-align: center; margin-top: 70px;">
                <h1>Modificar turno</h1>
            </div>

            <div class="col-12" style="margin-top: 70px;">
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-4">
                        <button mat-raised-button color="ligth" (click)="showEdit2()" class="myButton2">
                            <strong>Modificar turno día</strong>
                        </button>
                        <h5></h5>
                    </div>

                    <div class="col-4">
                        <button mat-raised-button type="button" color="primary" class="myButton" (click)="showEdit()">
                            <strong>Modificar turnos</strong>
                        </button>
                    </div>
                </div>

            </div>

        </mat-dialog-content>


    </div>
    <!-- Vista de modificar turnos -->
    <div class="row schedules-row-padding" *ngIf="editDay == true">

        <h2 mat-dialog-title style="padding: 0px 12px 0px 6px; margin: 0px;">
            <button style="float: right;" mat-icon-button mat-dialog-close>
                <i class="fi-rr-cross-small"></i>
            </button>
        </h2>
        <mat-dialog-content style="padding: 0px 48px 48px 48px; overflow-x: hidden" class="mat-typography">
            <div style="text-align: center;">
                <h1>Cómo subir tus archivos</h1>
            </div>

            <div class="row" style="margin-top: 70px">
                <div class="col-1"></div>
                <div class="col-4" style="font-size: 14px">
                    <mat-list role="list" style="margin-top: 10%">
                        <mat-list-item style="color: #717171 !important" role="listitem">- Realice las modificaciones
                            necesarias, si el usuario no debe asistir en una fecha, dejar vacías las celdas de horas
                            respectivas.</mat-list-item>
                    </mat-list>
                </div>
                <div class="col-4">
                    <div class="text-center">
                        <img src="./assets/images/schedules.png" alt="vacationIcon">
                    </div>
                </div>
                <div class="col-1"></div>
            </div>

            <div class="col-12" style="margin-top: 70px;">
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-4">
                        <button mat-raised-button color="ligth" (click)="getScheduleFileToEdit()"
                            class="myButton2">Descargar plantilla
                            <span class="fi-rr-cloud-download"></span>
                        </button>
                        <h5></h5>
                    </div>

                    <div class="col-4">
                        <button mat-raised-button type="button" color="primary" class="myButton"
                            (click)="fileInput.click()">
                            Subir Archivo
                            <span class="fi-rr-cloud-upload"></span>
                        </button>
                        <input hidden (change)="onChangeFile($event)" name="file" #fileInput type="file" id="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
                        <h5 style="margin-top: 10px;">{{nameFile}}</h5>
                    </div>
                </div>

            </div>

        </mat-dialog-content>

    </div>
    <!-- Modificar turno día -->
    <div class="row schedules-row-padding" *ngIf="editDay2 == true">
        <h2 mat-dialog-title style="padding: 0px 12px 0px 6px; margin: 0px;">
            <button style="float: right;" mat-icon-button mat-dialog-close>
                <i class="fi-rr-cross-small"></i>
            </button>
        </h2>

        <mat-dialog-content style="padding: 0px 48px 48px 48px; overflow-x: hidden" class="mat-typography">
            <div class="title content" style="width: 100%;">
                <span class="fi-rr-user" style="font-size: 24px; margin-right: 5px;"></span>
                <span>
                    <h4> {{length}} usuarios seleccionados</h4>
                </span>
            </div>
            <div>
                <h3>Modificar turno</h3>
                <div class="row">
                    <div class="col-4">
                        <mat-form-field appearance="fill" class="col-12">
                            <mat-select [formControl]="filterControl" placeholder="Día a modificar" (selectionChange)="searchPossibleSchedule()">
                                <mat-option value="" disabled>Seleccionar</mat-option>
                                <mat-option *ngFor="let item of DaysWeek" [value]="item?.day | date:'yyyy-MM-dd'" >
                                    {{item?.name_day}} {{item?.day | date:'dd'}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field></div>
                    <div class="col-8">
                        <mat-checkbox #checkbox [checked]="checked"  (change)="clearFills(checkbox.checked)" value=''>Asignar turno de descanso</mat-checkbox>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12" [formGroup]="jornada">
                        <div class="row" >
                            <div class="col-4">
                                <h4> <strong>Workday</strong> </h4>
                                <mat-form-field  style="width: 130px; margin-right: 5px;" appearance="fill">
                                    <mat-label>Inicio</mat-label>
                                    <input matInput type="time" placeholder="HH:mm:ss" min="00:00:00" max="23:59:59" formControlName="startJ">
                                </mat-form-field>
                                <mat-form-field style="width: 130px;" appearance="fill">
                                    <mat-label>Fin</mat-label>
                                    <input matInput type="time" placeholder="HH:mm:ss" min="00:00:00" max="23:59:59" formControlName="endJ">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <h4> <strong>Lunch</strong> </h4>
                                <mat-form-field style="width: 130px; margin-right: 5px;" appearance="fill">
                                    <mat-label>Inicio</mat-label>
                                    <input matInput type="time" placeholder="HH:mm:ss" min="00:00:00" max="23:59:59" formControlName="startA">
                                </mat-form-field>
                                <mat-form-field style="width: 130px;" appearance="fill">
                                    <mat-label>Fin</mat-label>
                                    <input matInput type="time" placeholder="HH:mm:ss" min="00:00:00" max="23:59:59"  formControlName="endA">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <h4> <strong>Training</strong> </h4>
                                <mat-form-field style="width: 130px; margin-right: 5px;" appearance="fill">
                                    <mat-label>Inicio</mat-label>
                                    <input matInput type="time" placeholder="HH:mm:ss" min="00:00:00" max="23:59:59" formControlName="startC">
                                </mat-form-field>
                                <mat-form-field style="width: 130px;" appearance="fill">
                                    <mat-label>Fin</mat-label>
                                    <input matInput type="time" placeholder="HH:mm:ss" min="00:00:00" max="23:59:59" formControlName="endC">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <h4> <strong>Break 1</strong> </h4>
                                <mat-form-field style="width: 130px; margin-right: 5px;" appearance="fill">
                                    <mat-label>Inicio</mat-label>
                                    <input matInput type="time" placeholder="HH:mm:ss" min="00:00:00" max="23:59:59" formControlName="start1">
                                </mat-form-field>
                                <mat-form-field style="width: 130px;" appearance="fill">
                                    <mat-label>Fin</mat-label>
                                    <input matInput type="time" placeholder="HH:mm:ss" min="00:00:00" max="23:59:59" formControlName="end1">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <h4> <strong>Break 2</strong> </h4>
                                <mat-form-field style="width: 130px; margin-right: 5px;" appearance="fill">
                                    <mat-label>Inicio</mat-label>
                                    <input matInput type="time" placeholder="HH:mm:ss" min="00:00:00" max="23:59:59" formControlName="start2">
                                </mat-form-field>
                                <mat-form-field style="width: 130px;" appearance="fill">
                                    <mat-label>Fin</mat-label>
                                    <input matInput type="time" placeholder="HH:mm:ss" min="00:00:00" max="23:59:59" formControlName="end2">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-4">
                                <button mat-raised-button color="ligth" style="margin-top: 30px; height: 50px; width: 100%" mat-dialog-close>Cancelar
                                </button>
                            </div>

                            <div class="col-4">
                                <button mat-raised-button type="button" color="primary" style="margin-top: 30px; height: 50px; width: 100%" (click)="saveDays()">
                                    Guardar
                                </button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </mat-dialog-content>
    </div>

</div>
