import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DataAuditoria } from 'src/app/modules/calidad/interfaces/dataAuditoria.interface';
import { AuditoriaService } from 'src/app/modules/calidad/services/auditoria.service';
import { MatrizService } from 'src/app/modules/calidad/services/matriz.service';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
  selector: 'app-detalle-auditoria',
  templateUrl: './detalle-auditoria.component.html',
  styleUrls: ['./detalle-auditoria.component.sass']
})
export class DetalleAuditoriaComponent implements OnInit, OnDestroy {
  private uuid = '1475d242-92b6-11ee-b9d1-0242ac120002';
  public config: any;

  title:string = '';
  creacion:string = '';
  tipo_auditar = {interna:0,cliente:0};
  verCalificacion:boolean = false;
  dataHijo:DataAuditoria={
    modulos:[],
    tipo_matriz:'',
    tipo_peso:'',
    pesoMinimo:0,
    calificacion:'',
    aplica_para_cp: null,
    comment_apply: false,
    comment_size:0,
    comment_is_mandatory_for:''
  }
  inteligencia;
  idMatriz;
  maximo = 0;
  peso_actual = 0;
  calificacion = '';
  dataCalificacion = {modulos:[],calificacion:0};
  selected = new FormControl(0);

  //Observable para los cambios en los servicios de auditoria
  subscription:Subscription;
  constructor(
    private matrizService:MatrizService,
    private aRoute:ActivatedRoute,
    private auditoriaService:AuditoriaService,
    private router:Router,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
    this.aRoute.paramMap.subscribe(params=>{
      this.idMatriz = params.get('id');
      this.selected.setValue(params.get('tab'));
    });
    this.getMatrixById();
    this.subscription = this.auditoriaService.refresh$.subscribe(()=>{
      this.getMatrixById();
    });
  }


  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  changePage(event){
    this.selected.setValue(event);
    this.router.navigateByUrl(`/mios/calidad/auditoria/detail/${event}/${this.idMatriz}`)
  }

  getMatrixById(){
    let items = [];
    const lang = this.configService.lang;
    this.matrizService.getMatrixById(this.idMatriz).subscribe((resp:any)=>{
      console.log(resp);
      this.title = resp[0].matriz.nombre;
      this.dataHijo.tipo_matriz = lang === 'es' ? resp[0].matriz.tipo_matriz.nombre : resp[0].matriz.tipo_matriz.en;
      this.creacion = moment(resp[0].matriz.created_at).format('DD/MM/yyyy hh:mm a');
      this.dataHijo.pesoMinimo = resp[0].matriz.valor_peso_validacion.minimo;
      this.maximo = resp[0].matriz.valor_peso_validacion.maximo;
      this.peso_actual = resp[0].matriz.peso_actual;
      this.dataHijo.aplica_para_cp = resp[0].matriz.aplica_para_cp;
      this.dataHijo.comment_apply = resp[0].matriz.comment_apply;
      this.dataHijo.comment_size = resp[0].matriz.comment_size;
      this.dataHijo.comment_is_mandatory_for = resp[0].matriz.comment_is_mandatory_for;
      if (resp[0].matriz.tipo_peso) {
        this.dataHijo.tipo_peso = lang === 'es' ?  resp[0].matriz.tipo_peso.nombre : resp[0].matriz.tipo_peso.en;
      }
      this.dataHijo.modulos = [];
      if (resp[0].matriz.tiene_inteligenciaNegocio == 1) {
        this.inteligencia = resp[0].matriz.inteligenciaNegocio_id;
      }else{
        this.inteligencia = null
      }
      resp[0].matriz.modulo.forEach(modulo => {
        if (modulo.nivel == 1 && modulo.activo == 1) {
          this.dataHijo.modulos.push(modulo);
        }
      });
      this.dataHijo.modulos.forEach(modulo => {
        modulo.items_modulo.forEach((item,index) => {
          if (item.activo == 1) {
            // modulo.items_modulo.splice(index,1);
            items.push(item);
          }
        });
        modulo.items_modulo = items;
        items = [];
      });
      this.verCalificacion = true;
    });
  }

}
