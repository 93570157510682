import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { QuestionBase } from 'src/app/core/models/questions-base';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { PermitService } from 'src/app/core/services/utils/permit.service';
import { QuestionControlService } from 'src/app/core/services/utils/question-control.service';
import { FormsRequestService } from 'src/app/modules/crm/services/rest/forms-request.service';
import { storageService } from 'src/app/modules/livechat/services/storage/storage.service';
import { AlertsService } from '../../alerts/alerts.service';


@Component({
	selector: 'shared-dynamic-form',
	templateUrl: './dynamic-form.component.html',
	styleUrls: ['./dynamic-form.component.sass']
})
export class DynamicFormComponent implements OnInit {
	@Output() submitForm = new EventEmitter<any>();
	@Output() denyForm = new EventEmitter<any>();
	@Output() enableTemplate = new EventEmitter<any>();
	_sections: any;
	forms = new FormArray([]);
	constructor(
		private qcs: QuestionControlService,
		private authService: AuthService,
		private permit: PermitService,
		private router: Router,
		private formsRequestService: FormsRequestService,
		public alertsService: AlertsService,
		private destroyService: DestroyComponentService,
		public storage: storageService
	) {}
	@Input() dependencies;
	@Input() formId;
	@Input() files;
	@Input() template;
	Files: any = [];
	@Input()
	public set sections(form: any) {
		this.forms = new FormArray([]);
		this.getRolUser(form.app);
		this._sections = form.sections;
		if (this._sections) {
			this._sections.forEach((section) => {
				this.checkSeeRol(section);
				const group = this.qcs.toFormGroup(section.fields);
				this.forms.push(group);
				this.fieldsInTrays(section);
				
			});
		}
	}

	@Input() public set clearControl(clear: any) {
		if (clear === true) {
			/* this.forms.removeAt(1); */
		}
	}

	rol: any = [];
	fieldsPreloaded: any[] = [];
	questionPush: any;
	evento: any;
	plantilla: any = '';
	tab: any;
	tray: any;
	seePermit: any;
	editPermit: any;

	ngOnInit(): void {
		this.plantilla = this.router.url.substring(6).split('/', 3)[2];
	}
	/**
	 * @author Karol García
	 * @createdate 2021-03-29 
	 * Metodo que captura el rol del usuario
	 */
	getRolUser(app) {
		let user = this.authService.getUser();
		user.roles.forEach((rol) => {
			let name = rol.split('::');
			if (name[0] === app) {
				this.rol.push(name[1]);
			}
		});
	}
	/**
	 * @author Karol García
	 * @createdate 2021-03-29
	 * Metodo que verifica si tiene el permiso
	 */
	checkSeeRol(section) {
		
		section['see'] = false;
		section.fields.forEach((question) => {
			this.seePermit = this.permit.permit(this.rol, question.seeRoles);
			this.editPermit = this.permit.permit(this.rol, question.editRoles);
			question.disabled = !this.editPermit;
			question['see'] = this.seePermit;
			question['seeSons'] = true;
			question['edit_by_adviser'] = false;
			if (question.preloaded == true) {
				this.fieldsPreloaded.push(question);
			}
			if (this.editPermit && question.isSon) {
				
				question = this.checkDependence(question);
			}
			section.see = true;
		});
	}

	/**
	 * @author Miguel Restrepo
	 * @createdate 2022-03-01
	 * Metodo que permite validar las dependencias de un campo
	 */

	checkDependence(question){
		
		this._sections.forEach(section => { // Busca si tiene dependencias de campos de otras secciones
			section.fields.forEach(field => {
				const dependences = question.dependencies.filter(dependence => dependence.idField === field.id);
				if (dependences.length != 0) {
					question.disabled = true;
					question.seeSons = false;
					dependences.forEach(dependence => {
						if(question.seeSons === false) {
							question.seeSons = dependence.activators.some(activator => activator.id === field.value);
							question.disabled = !dependence.activators.some(activator => activator.id === field.value);
						}	
						if (dependence.disabled) {
							question.disabled = true;
						}
					})
				}
			})
		})
		return question;
	}
	/**
	 * @author Jair Celis
	 * @createdate 2021-08-30
	 * Metodo que añade las dependecias, dependiendo de la opciones del padre
	 */
	addDependencies(event: any) {

		const mySons = this.findSons(event.idFather);
		if (mySons.length != 0) {
			mySons.forEach((son) => {
				if (this.forms.controls[son.sectionIndex].get(son.key).value != '') {
					this.forms.controls[son.sectionIndex].get(son.key).setValue('');
					this.forms.controls[son.sectionIndex].get(son.key).disable();
					this.disableField(son.id);
				}
			});
		}

		this._sections.forEach((section, index) => {
			section.fields.forEach((field) => {
				if (field.isSon === true) {
					var bandera = 0;
					field.dependencies.forEach((dependencie) => {

						if (dependencie.idField === event.idFather && bandera === 0) {
						//desactivar hermanos

							this.forms.controls[index].get(field.key).disable();
							field.disabled = true;
							field.seeSons = false;

							dependencie.activators.forEach((activator) => {
								if (activator.id == event.idValue) {
									bandera = bandera + 1;
									this.forms.controls[index].get(field.key).setValue('');
									field.seeSons = true;
									this.forms.controls[index].get(field.key).enable();
									field.disabled = false;
									//activa seccion dependiente
									if (section.see === false) {
										section.see = true;
										// this.forms.controls[index].enable();
									}

									field.options = [];
									if (dependencie.options !== '') {
										dependencie.options.forEach((option) => {
											field.options.push(option);
										});
									}
								}
							});
						}
					});
				}
			});
			if (section.fields.some(field => field.seeSons == true)) {
				section.see = true;
			} else {
				section.see = false;
			}
			// console.log(section.fields.some(field => field.seeSons == true));
		});
	}

	/**
	 * @author Jair Celis
	 * @createdate 2021-08-30
	 * Metodo que desactiva la visualizacion de un campo
	 * @param idField id del campo a desactivar
	 */

	disableField(idField) {
		this._sections.forEach((section, index) => {
			section.fields.forEach((field) => {
				if (field.id == idField) {
					field.seeSons = false;
				}
			});
		});
	}

	/**
	 * @author Jair Celis
	 * @createdate 2021-08-30
	 * Metodo que devuelve un arreglo de campos hijos
	 * @param idField id del campo a buscar los hijos
	 */

	findSons(idField) {
		const mySons = [];
		this._sections.forEach((section, index) => {
			section.fields.forEach((field) => {
				if (field.isSon == true) {
					field.dependencies.forEach((dependencie) => {
						if (dependencie.idField == idField) {
							field.sectionIndex = index;
							mySons.push(field);
						}
					});
				}
			});
		});
		return mySons;
	}
	/**
	 * @author Karol García
	 * @createdate 2021-02-25
	 * Metodo que añade otro campo
	 */
	addQuestion(event, nameSection) {
		this.evento = Object.assign({}, event);
		this.questionPush = this.evento;
		let cont = 0;
		let indexAuxField = 0;
		let indexAuxSec = 0;

		this._sections.forEach((section, indexSec) => {
			section.fields.forEach((question, indexField) => {
				if (question.key.includes(this.evento.key) && nameSection === section.name_section) {
					cont++;
					indexAuxField = indexField;
					indexAuxSec = indexSec;
				}
			});
		});

		cont = cont + 1;

		this.questionPush.id = new Date().getTime();
		this.questionPush.key = this.evento.key + '_' + cont;
		this.questionPush.value = '';
		this.questionPush.label = this.evento.label + ' ' + cont;
		this.questionPush.canAdd = false;
		this.questionPush.duplicated = {
			idOriginal: event.id,
			type: 'field'
		};

		if (this.questionPush.nameFile) {
			this.questionPush.nameFile = '';
		}

		if (this.questionPush.idValue) {
			this.questionPush.idValue = '';
		}

		if (this._sections[indexAuxSec].name_section === nameSection) {
			this._sections[indexAuxSec].fields.splice(indexAuxField + 1, 0, this.questionPush);
		}

		let formAux = this.forms.controls[indexAuxSec] as FormGroup;

		let controlAux = this.qcs.toFormControl(this.questionPush);
		formAux.addControl(this.questionPush.key, controlAux);
	}

	/**
	 * @author Miguel Restrepo
	 * @createdate 2021-02-25
	 * @updatedate 2022-05-18 
	 * Metodo que añade una nueva sección duplicada
	 */

	addSection(event, i) {
		let evento = Object.assign({}, event);
		let section = evento;
		let cont = 0;
		this._sections.forEach((element) => {
			if (element.name_section.includes(section.name_section)) {
				cont++;
			}
		});
		cont = cont + 1;
		let sendSection = new FormData();
		sendSection.append('section', JSON.stringify(section));
		sendSection.append('cont', cont.toString());
		this.formsRequestService.addSection(sendSection).subscribe((resp) => {
			resp["duplicated_section"] = true;
			this._sections.push(resp);
			const group = this.qcs.toFormGroup(resp.fields);
			this.forms.push(group);
		});
	}

	removeSection(event, index) {
		this.forms.removeAt(index)
		this._sections.splice(index, 1)

	}

	/**
	 * @author Karol García
	 * @createdate 2021-02-17
	 * Metodo que emite el formulario resuelto
	 */
	onSubmit() {
		let response = {
			sections: this._sections,
			answer: this.forms.getRawValue(),
			files: this.Files,
			forms: this.forms
		};
		this.submitForm.emit(response);
		this.fieldsPreloaded.forEach((element) => {
			this.forms.controls.forEach((sections) => {
				if (sections.get(element.key) != null) {
					sections.get(element.key).setValue(element.value);
				}
			});
		});
	}

	/**
	 * @author Karol García
	 * @createdate 2021-03-30
	 * Metodo que cancela el formulario
	 */
	cancelForm() {
		let saveconfirm = this.alertsService.alertConfirm('¿Desea cancelar?');
		saveconfirm.then((res) => {
			if (res.isConfirmed) {
				this.denyForm.emit(true);
			}
		});
	}
	/**
	 * @author Daniel Dominguez
	 * @createdate 2021-04-06
	 * Metodo recibe los archivos cargados en los campos tipo file
	 */
	addFiles(e) {
		this.Files.push(e);
	}

	ngOnDestroy(): void {
		this.destroyService.destroyComponent();
	}

	// Boton para generar las plantillas
	generateTemplate() {
		let responseTemplate = {
			sections: this._sections,
			answer: this.forms.getRawValue(),
			files: this.Files,
			forms: this.forms
		};

		this.enableTemplate.emit(responseTemplate);
	}

	fieldsInTrays(section){

		this.tab = this.storage.getItem('tab');
		this.tray = this.storage.getItem('tray');
		section.fields.forEach((question) => {
			if (question.tray) {
				var band = false;
				question.tray.forEach(element => {

					question.disabled = true;
					question['see'] = true;

					if (element.id !== +this.tray && band === false) {
						question.disabled = false;
						question['see'] = false;
						this._sections.forEach((sectionArray, index) => {
							if (sectionArray.id === section.id ) {
								this.forms.controls[index].get(question.key).disable();
							}
						});
					} else {
						question.disabled = true;
						question['see'] = true;
						band = true;

						if (element.preloaded === false ){

							this._sections.forEach((sectionArray, index) => {
								if (sectionArray.id === section.id ) {
									this.forms.controls[index].get(question.key).setValue('');
								}
							});
						}
					}
				});

				if (this.template) {
					if (+this.tab < 2) {
						question.disabled = !this.editPermit;
						question['see'] = false;
						question['seeSons'] = true;
						this._sections.forEach((sectionArray, index) => {
							if (sectionArray.id === section.id ) {
								this.forms.controls[index].get(question.key).disable();
							}
						});
					}
				} else {
					if (+this.tab < 1) {
						question.disabled = !this.editPermit;
						question['see'] = false;
						question['seeSons'] = true;
						this._sections.forEach((sectionArray, index) => {
							if (sectionArray.id === section.id ) {
								this.forms.controls[index].get(question.key).disable();
							}
						});
					}
				}
			}
		});
		section.see = section.fields.some(field => field.see === true && field.seeSons === true);
	}

}
