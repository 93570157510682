<div class="graph">
  <div class="header">
    <div class="row justify-content-between">
      <div class="col-md-6 header">
        <h1>Reporte de Adherencia<i class="fas fa-container-storage"></i></h1>
        <p>En este espacio podrás encontrar el informe de adherencia"</p>
      </div>
      <div class="col-md-2">
        <button style="height: 48px;" mat-raised-button class="w-100" color="primary" (click)="goToHome()">
          Ir al home
        </button>
      </div>
    </div>
    <div style="margin-bottom: 32px; display: flex;" *ngIf="tabId === 'Semanal'">
      <h3 style="font-size: 28px !important;">{{titleDate}}</h3>
      <button mat-icon-button (click)="prevDate()" [disabled]="indexDate === 0">
        <span class="fi-rr-angle-left" style="font-size: 30px !important;"></span>
      </button>
      <button mat-icon-button (click)="nextDate()" [disabled]="indexDate === 6">
        <span class="fi-rr-angle-right" style="font-size: 30px !important;"></span>
      </button>
    </div>
    <mat-card>
      <mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChange($event)">
        <mat-tab label="Semanal">
          <ng-template matTabContent>
            <div #semanal *ngIf="tabId === 'Semanal'">
              <canvas id="barChart" #barChart width="500" height="300"></canvas>
            </div>
          </ng-template>
        </mat-tab>
        <!-- <mat-tab label="Mensual">
          <ng-template matTabContent>
            <div #mensual *ngIf="tabId === 'Mensual'">
              <h4 class="fw-bold">{{newChart?.grafica.title}}</h4>
              <canvas id="barras" width="500" height="300"></canvas>
            </div>
          </ng-template>
        </mat-tab> -->
      </mat-tab-group>
    </mat-card>
    <div class="row" style="padding-top: 60px;" *ngIf="tabId === 'Semanal'">
      <div class="col-3 row hr-container" *ngFor="let item of resumSchedule" [hidden]="item.schedule_type_id !== 1">
        <span class="hr-title"> <b>Horas programadas</b> </span>
        <h1 class="hr-hours turn-color"> {{item.total_horas}} <span class="hr-subtitle">Hrs</span></h1>
      </div>
      <div class="col-3 row hr-container" *ngFor="let item of resumSchedule" [hidden]="item.schedule_type_id !== 1">
        <span class="hr-title"> <b>Horas de conexión</b> </span>
        <h1 class="hr-hours turn-mark"> {{item.total_conexion + ' '}} <span class="hr-subtitle">Hrs</span></h1>
      </div>
      <div class="col-3 row hr-container" *ngFor="let item of resumSchedule" [hidden]="item.schedule_type_id !== 1">
        <span class="hr-title"> <b>Diferencia Conexión</b> </span>
        <h1 class="hr-hours turn-dif"> {{item.diferencia_conexion + ' '}} <span class="hr-subtitle">Hrs</span></h1>
      </div>
      <div class="col-3 row hr-container" *ngFor="let item of resumSchedule" [hidden]="item.schedule_type_id !== 1">
        <span class="hr-title"> <b>Conformance</b> </span>
        <h1 class="hr-hours turn-conformance"> {{item.conformance_conexion + ' '}} </h1>
      </div>
    </div>
    <div class="row" style="margin-top: 72px;" *ngIf="tabId === 'Semanal'">
      <div class="col-12 row" *ngFor="let item of resumSchedule" style="padding-bottom: 48px;">
        <div class="col-3">
          <p> <span class="badge"
              [ngClass]="{'badgeT': item.schedule_type_id === 1, 'badgeB' : item.schedule_type_id  === 2 , 'badgeL' : item.schedule_type_id  === 3 , 'badgeC' : item.schedule_type_id  === 4 }"></span>
            <b>{{item.schedule_name | titlecase}} programado</b>
          </p>
          <p> <b>Inicio {{item.schedule_name}} :</b> {{item.hora_progra_inicial || '--:--'}} </p>
          <p> <b>Fin {{item.schedule_name}} :</b> {{item.hora_progra_final || '--:--'}} </p>
        </div>
        <div class="col-3">
          <p> <span class="badge"
              [ngClass]="{'badgeTR': item.schedule_type_id === 1, 'badgeBR' : item.schedule_type_id  === 2 , 'badgeLR' : item.schedule_type_id  === 3 , 'badgeCR' : item.schedule_type_id  === 4 }"></span><b>{{item.schedule_name
              | titlecase}} realizado</b></p>
          <p> <b>Inicio {{item.schedule_name}} :</b> {{item.hora_real_inicial || '--:--'}} </p>
          <p> <b> Fin {{item.schedule_name}} :</b> {{item.hora_real_final || '--:--'}} </p>
        </div>
        <div class="col-3">
          <p> <span class="badge" [style.background]="'#FF585E'"></span><b>Diferencia</b></p>
          <p>{{item.diferencia_inicial || '--:--'}} Hrs </p>
          <p>{{item.diferencia_real || '--:--'}} Hrs</p>
        </div>
        <div class="col-3" [hidden]="item.schedule_type_id == 3">
          <p> <span class="badge" [style.background]="'#60BF66'"></span><b>Conformance</b></p>
          <p>{{item.conformance || ''}} </p>
        </div>
      </div>
    </div>
  </div>
</div>