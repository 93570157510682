import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../../../environments/environment';

// Models
import { GeneralParameter } from '../models/general-parameter';

@Injectable({
	providedIn: 'root'
})
export class GeneralParametersService {
	API_URL = environment.CARTERA_MOVISTAR;
	API_URL_RRHH = environment.RRHH_URL;
	PARAMETROS = this.API_URL + 'general_parameter/type/';

	constructor(private http: HttpClient) {}

	handleError(error: HttpErrorResponse): Observable<any> {
		return throwError(error);
	}

	/**
	 * Método para traer los parametros por tipo
	 * @param type Tipo del parametro a traer (TTE, TNE, LUG, TPR, etc...)
	 * @author Carlos Gomez
	 * @createdate 2021-05-09
	 */
	getParametersByType(type: string): Observable<GeneralParameter[]> {
		return this.http.get<GeneralParameter[]>(this.PARAMETROS + type).pipe(catchError(this.handleError));
	}

	/**
	 * Método para traer los parametros por tipo
	 * @param type Tipo del parametro a traer (TTE, TNE, LUG, TPR, etc...)
	 * @author Carlos Gomez
	 * @createdate 2021-05-09
	 */
	downloadManagements(data:any) {
		return this.http.post(this.API_URL + `management/downloadManagement`, data, {responseType: 'blob'}).pipe(catchError(this.handleError));
}
}
