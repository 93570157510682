import { VacationsService } from './../../../../services/rest/vacations.service';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UsersService } from 'src/app/modules/ciu/services/rest/users.service';
import { CampaignsService } from 'src/app/modules/nomina/services/rest/campaigns.service';
import { VacationsResponseComponent } from '../vacations-response/vacations-response.component';
import { VacationsViewComponent } from '../vacations-view/vacations-view.component';
import { CandidatesService } from 'src/app/modules/rrhh/services/candidates.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import * as moment from 'moment';

@Component({
  selector: 'app-vacations',
  templateUrl: './vacations.component.html',
  styleUrls: [ './vacations.component.sass' ]
})
export class VacationsComponent implements OnInit {
  // MatPaginator Inputs
  length;
  pageSize = 5;
  page = 1;
  isPage = 1;
  pageSizeOptions: number[] = [ 5, 10, 25, 100 ];

  // paginator for reversions
  length2;
  pageSize2 = 5;
  page2 = 1;
  isPage2 = 1;
  pageSizeOptions2: number[] = [ 5, 10, 25, 100 ];

  displayedColumns: string[] = [ 'acciones', 'fecha', 'nombre', 'numero', 'estado', 'campana' ];
  displayedColumns2: string[] = [ 'acciones', 'fecha', 'nombre', 'numero', 'campana', 'codigo', 'action', 'value', 'estado' ];
  dataSource = new MatTableDataSource<any>();
  dataSourceReversions = new MatTableDataSource<any>();

  reportForm: FormGroup;
  reversionForm: FormGroup;
  Campaings: any;
  filterControl = new FormControl();
  filterControl2 = new FormControl();
  states: any = [ { id: 1, name: 'En revisión' }, { id: 2, name: 'Aprobado' }, { id: 3, name: 'Rechazado' }, {id: 4, name: 'Pendiente aprobación jefe'} ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator2: MatPaginator;

  document: any = '';
  startDate: any = '';
  endDate: any = '';
  idCampaing: any;
  idState: any = 1;

  documents: any = [];
  idTypeDoc: any;
  idTypeDoc2: any;

  typeDocument = new FormControl();
  typeDocument2 = new FormControl();

  startDateRever: any;
  endDateRever: any;

  constructor(public dialog: MatDialog,
              private service: CampaignsService,
              public userService: UsersService,
              private vacation: VacationsService,
              private alertsService: AlertsService,
              private users: CandidatesService
  ) {
  }

  ngOnInit() {
    this.reportForm = new FormGroup({
      start: new FormControl(''),
      end: new FormControl('')
    });

    this.reversionForm = new FormGroup({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      campaignId: new FormControl(''),
      docType: new FormControl(1),
      document: new FormControl('')
    });

    this.filterControl2.setValue(1);
    this.service.getAllCampaigns().subscribe(resp => {
      this.Campaings = resp.data;
    });

    this.getVacationsForReversions(this.pageSize2, this.page2);

    this.users.getIdTypes().subscribe(resp => {
      this.documents = resp;
    });
    this.typeDocument.valueChanges.subscribe(resp => {
      this.idTypeDoc = resp;
      this.getVacations(1, this.pageSize, this.page);
    });
    this.typeDocument.setValue(1);

    // filtro de campania | tab: solicitudes
    this.filterControl.valueChanges.subscribe(resp => {
      this.resetPaginator(1);
      this.idCampaing = resp;
      this.getVacations(this.idState, this.pageSize, this.page);
    });
    // filtro de estado | tab: solicitudes
    this.filterControl2.valueChanges.subscribe(resp => {
      this.resetPaginator(1);
      this.idState = resp;
      this.getVacations(this.idState, this.pageSize, this.page);
    });

    // form reversiones
    // filtro campania | tab: reversiones
    this.reversionFormControls.campaignId.valueChanges.subscribe(resp => {
      this.resetPaginator(2);
      this.getVacationsForReversions(this.pageSize, this.page);
    });

    // filtro tipo doc | tab: reversiones
    this.reversionFormControls.docType.valueChanges.subscribe(resp => {
      if (this.reversionFormControls.document.value) {
        this.getVacationsForReversions(this.pageSize, this.page);
      }
    });
  }

  get reversionFormControls() {
    return this.reversionForm.controls;
  }


  /**
   * @author Javier Castañeda
   * @createdate 2021-04-26
   * Metodo de paginación
   */
  pageEvent(event: any): void {

    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getVacations(this.idState, this.pageSize, this.page);
  }

  /**
   * @author Javier Castañeda
   * @createdate 2021-04-27
   * Modal de solicitud
   */
  viewJustification(element?): void {
    const dialogNewgroup = this.dialog.open(VacationsResponseComponent, {
      width: '60%',
      disableClose: true,
      data: {
        type: 'vacaciones',
        title: 'Juan Manuel Gonzales',
        data: element
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewgroup.afterClosed().subscribe(() => {
      this.getVacations(this.idState, this.pageSize, this.page);
    });

  }

  /**
   * @author Javier Castañeda
   * @createdate 2021-04-28
   * Modal de solicitud rechazada
   */
  viewJustification2(element?): void {
    const dialogNewgroup = this.dialog.open(VacationsViewComponent, {
      width: '60%',
      disableClose: true,
      data: {
        type: 'vacaciones',
        title: 'Juan Manuel Gonzales',
        data: element
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewgroup.afterClosed().subscribe(() => {
      this.getVacations(this.idState, this.pageSize, this.page);
    });

  }

  /**
   * Modal para cambiar el estado de la reversion de vacaciones
   * @author Andres Buitrago
   * @created 2021-10-22
   */
  changeReversionStatus(vacation, state): void {
    this.alertsService.alertConfirm('¿Está seguro de modificar el estado?').then(result => {
      if (result.isConfirmed) {
        this.vacation.changeStatusReversion(vacation.id, state).subscribe((resp) => {
          this.alertsService.alertSuccess('Modificación realizada', 'Proceso realizado correctamente');
          this.getVacationsForReversions(this.pageSize2, this.page2);
          this.getVacations(this.idState, this.pageSize, this.page);
        });
      }
    });
  }

  /**
   * @author Javier Castañeda
   * @createdate 2021-04-26
   * Metodo para capturar el documento a filtrar
   */
  onBlurMethod(e) {
    this.resetPaginator(1);
    this.document = e;
    this.getVacations(this.idState, this.pageSize, this.page);
  }

  /**
   * @author Javier Castañeda
   * @createdate 2021-04-26
   * Metodo para capturar el documento a filtrar
   */
  onBlurMethodDocumentReversion(e) {
    this.resetPaginator(2);
    this.getVacationsForReversions(this.pageSize, this.page);
  }

  /**
   * @author Javier Castañeda
   * @createdate 2021-04-26
   * Metodo para capturar el rango de fechas a filtrar
   */
  dateRangeChange() {
    this.resetPaginator(1);
    const date1: Date = this.reportForm.controls.start.value;
    const date2: Date = this.reportForm.controls.end.value;
    const dateStart: string | null = new DatePipe('en-US').transform(date1, 'yyyy-MM-dd');
    const dateEnd: string | null = new DatePipe('en-US').transform(date2, 'yyyy-MM-dd');
    this.startDate = dateStart;
    this.endDate = dateEnd;
    this.getVacations(this.idState, this.pageSize, this.page);
  }

  /**
   * @author Andres Buitrago
   * @createdate 2021-10-22
   * Metodo para capturar el rango de fechas a filtrar en el tab de reversiones
   */
  dateRangeChangeReversions() {
    this.resetPaginator(2);
    const date1: Date = this.reversionFormControls.startDate.value;
    const date2: Date = this.reversionFormControls.endDate.value;
    const dateStart: string | null = new DatePipe('en-US').transform(date1, 'yyyy-MM-dd');
    const dateEnd: string | null = new DatePipe('en-US').transform(date2, 'yyyy-MM-dd');
    this.startDateRever = dateStart;
    this.endDateRever =dateEnd;
    this.getVacationsForReversions(this.pageSize, this.page);
  }

  /**
   * @author Javier Castañeda
   * @createdate 2021-04-28
   * Metodo para filtrar fecha, campaña y rango de fechas
   */
  getVacations(state, pageSi, page, exportData: boolean = false): void {
    this.vacation.getVacationsBy({
      state,
      idTipDoc: this.idTypeDoc,
      document: this.document,
      campaign_id: this.idCampaing,
      startDate: this.startDate,
      endDate: this.endDate,
      exportData
    }, pageSi, page).subscribe(resp => {
      if (!exportData) {
        this.dataSource = resp.data.data;
        this.length = resp.data.total;
      } else {
        importedSaveAs(resp, 'reporte_vacaciones_nomina.xlsx');
      }
    });
  }

  /**
   * @author Javier Castañeda
   * @createdate 2021-04-28
   * Metodo para filtrar fecha, campaña y rango de fechas
   */
  getVacationsForReversions(pageSi, page, exportData: boolean = false) {
    this.vacation.getVacationsForReversions({
      docType: this.reversionFormControls.docType.value,
      document: this.reversionFormControls.document.value,
      campaignId: this.reversionFormControls.campaignId.value,
      startDate: this.startDateRever,
      endDate: this.endDateRever,
      exportData
    }, pageSi, page).subscribe(resp => {
      if (!exportData) {
        this.dataSourceReversions = resp.data.data;
        this.length2 = resp.data.total;
      } else {
        importedSaveAs(resp, 'reporte_vacaciones_reversion_nomina.xlsx');
      }
    });
  }

  resetPaginator(numPaginator): void {
    if (numPaginator === 1) {
      this.length = 0;
      this.pageSize = 5;
      this.page = 1;
      this.paginator.firstPage();
    } else {
      this.length2 = 0;
      this.pageSize2 = 5;
      this.page2 = 1;
      this.paginator2.firstPage();
    }
  }

  selectView(element: any){
    if(element.noveltyStateKey == 'aprobada-jefe-area' && element.fullName.includes('extemporáneas')){
      return false;
    }
    if(element.noveltyStateKey  == 'aprobada-jefe-area' || element.noveltyStateKey  == 'cancelacion-vacaciones' || element.noveltyStateKey  == 'aprobada-gerente-operaciones'){
      return true;
    }
    return false;
  }

}
