import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TemplatesService } from '../../../services/templates.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fromEventPattern } from 'rxjs';

@Component({
  selector: 'app-tags-new',
  templateUrl: './tags-new.component.html',
  styleUrls: ['./tags-new.component.sass']
})
export class TagsNewComponent implements OnInit {

  public tagForm: FormGroup;
  contractTypes: any[] = [];
  contractTypeSelected: number;
  templateTags: any[] = [];

  constructor(
    private templateService: TemplatesService,
    public dialogRef: MatDialogRef<TagsNewComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.templateService.getTemplates().subscribe((response: any) => {
      this.contractTypes = response;
    });

    this.tagForm.get('contractType').valueChanges.subscribe(resp => {
      this.contractTypeSelected = resp;
      const body = {
        contract_type_id: this.contractTypeSelected,
        paginateOff: true
      };
      this.templateService.getTags(body).subscribe((response: any) => {
        this.templateTags = response;
      });
    });
  }

  get error(): any { return this.tagForm.controls; }

  buildForm() {
    this.tagForm = new FormGroup({
      id: new FormControl(this.data.tag !== null ? this.data.tag.id : '', []),
      contractOrigin: new FormControl(this.data.tag !== null ? this.data.tag.contract_template_tag_origin_id : '', [Validators.required]),
      contractType: new FormControl(this.data.tag !== null ? this.data.tag.contract_type_id : '', [Validators.required]),
      templateTag: new FormControl({value:this.data.tag !== null ? this.data.tag.contract_template_tag_id : '', disabled:true}, [Validators.required]),
      tagName: new FormControl(this.data.tag !== null ? this.data.tag.key : '', [
        Validators.required,
        Validators.maxLength(20),
        Validators.pattern(/^[a-zA-Z_]+$/)
      ]),
      tagDescription: new FormControl(this.data.tag !== null ? this.data.tag.description : '', [
        Validators.required,
        Validators.maxLength(200)
      ]),
      tagValue: new FormControl({value:this.data.tag !== null ? this.data.tag.value : '', disabled:true}, [Validators.required]),
      source: new FormControl(this.data.tag !== null ? this.data.tag.source : '', [Validators.required]),
      useTemplateTag: new FormControl(this.data.tag && this.data.tag.contract_template_tag_id !== null ? 1 : 2, [Validators.required])
    });
    if (this.data.tag !== null) {
      const body = { contract_type_id: this.tagForm.value.contractType, paginateOff: true };
      this.templateService.getTags(body).subscribe((response: any) => {
        this.templateTags = response;
      });

      this.enableFields(this.tagForm.get('useTemplateTag').value);
    }


  }

  onSubmit() {
    const formData = new FormData();
    formData.append("id", this.tagForm.get('id').value);
    formData.append("origin", this.tagForm.get('contractOrigin').value);
    formData.append("key", this.tagForm.get('tagName').value);
    formData.append("description", this.tagForm.get('tagDescription').value);
    formData.append("value", this.tagForm.get('tagValue').value);
    formData.append("source", this.tagForm.get('source').value);

    if(this.tagForm.get('contractType').value > 0){
      formData.append("contract_type_id", this.tagForm.get('contractType').value);
    }
    if (this.tagForm.get('templateTag').value) {
      formData.append("contract_template_tag_id", this.tagForm.get('templateTag').value);
    }

    if (this.data.tag === null) {
      this.templateService.storeTag(formData).subscribe(
        response => {
          this.snackBar.open('Variable creada satisfactoriamente, ya la puedes usar en tus plantilla', 'cerrar', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['green-snackbar'],
            duration: 20000,
          });
          this.dialogRef.close(response);
        },
        error => {
          this.snackBar.open('Error en el proceso, por favor intenta de nuevo', '', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['red-snackbar'],
            duration: 20000,
          });
          this.dialogRef.close();
        }
      );
    } else {
      const tagFormValues = this.tagForm.value;
      const tag = {
        id: tagFormValues.id,
        key: tagFormValues.tagName,
        description: tagFormValues.tagDescription,
        value: tagFormValues.tagValue,
        contract_type_id: tagFormValues.contractType > 0 ? tagFormValues.contractType : null,
        contract_template_tag_origin_id: tagFormValues.contractOrigin,
        source: tagFormValues.source,
        contract_template_tag_id: tagFormValues.templateTag
      };

      this.templateService.updateTag(tag).subscribe(
        response => {
          this.snackBar.open('Variable actualizada satisfactoriamente, ya la puedes usar en tus plantilla', 'cerrar', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['green-snackbar'],
            duration: 20000,
          });
          this.dialogRef.close(response);
        },
        error => {
          this.snackBar.open('Error en el proceso, por favor intenta de nuevo', '', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['red-snackbar'],
            duration: 20000,
          });
          this.dialogRef.close();
        }
      );
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  /**
   * Método que habilita los campos según si se necesita precondición o no
   * @author Juan Carlos Alonso
   * @createdate 13-09-2023
   */
  enableFields(option:number){
   if(option == 1){
    this.tagForm.get("source").setValue("");
    this.tagForm.get("source").disable();
    this.tagForm.get("templateTag").enable();
    this.tagForm.get("tagValue").enable();
   }else{
    this.tagForm.get("templateTag").setValue("");
    this.tagForm.get("tagValue").setValue("");
    this.tagForm.get("templateTag").disable();
    this.tagForm.get("tagValue").disable();
    this.tagForm.get("source").enable();
   }
  }
}
