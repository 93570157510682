import { ConfigSchedule } from "./config-schedule";



export class Configs {

    constructor() {
        this.config_schedule = Array<ConfigSchedule>();
    }

    id: number;
    name: string;
    date_start: string;
    date_end: string;
    group_id: number;
    config_schedule: ConfigSchedule[];
}