import {Component, OnInit, ViewChild} from '@angular/core';
import {TemplatesService} from '../../../services/templates.service';
import {DatePipe} from '@angular/common';
import {ProfileService} from '../../../../ciu/services/rest/profile.service';
import {MatDialog} from '@angular/material/dialog';
import {map, mergeMap} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {TemplatePreviewComponent} from '../template-preview/template-preview.component';
import {TemplateConfirmationComponent} from '../template-confirmation/template-confirmation.component';
import {AbstractControl, FormControl, ValidationErrors, Validators} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {CandidatesService} from '../../../services/candidates.service';

@Component({
  selector: 'app-template-history',
  templateUrl: './template-history.component.html',
  styleUrls: ['./template-history.component.sass']
})
export class TemplateHistoryComponent implements OnInit {

  templates: any;
  template: any;
  HistoryColumns: string[] = ['updated_at', 'name', 'version', 'responsable', 'status', 'actions'];
  HistorySource: any;

  // Paginate
  @ViewChild(MatPaginator) paginator: MatPaginator;
  page = 1;
  length = 0;
  pageSize = 5;
  pageSizeOptions: number[] = [5, 15, 25, 50, 100];

  // Filters
  contractType = new FormControl();
  contractTypes: any[] = [];
  contractTypeSelected: number;

  templateVersion = new FormControl('', [this.nonNegativeValidator]);
  templateVersionSelected: number;

  templateDate = new FormControl('', [this.futureDateValidator]);
  templateDateSelected: any;

  templateStatus = new FormControl();
  templateStatusSelected: string;

  constructor(
    private templateService: TemplatesService,
    private datePipe: DatePipe,
    private profileService: ProfileService,
    public dialog: MatDialog,
    public candidatesService: CandidatesService,
  ) { }

  ngOnInit(): void {
    this.getHistory();

    this.templateService.refreshTemplates$.subscribe(() => {
      this.getHistory();
    });

    // Getting contract types
    this.templateService.getTemplates().subscribe((response: any) => {
      this.contractTypes = response;
    });

    // Filters
    this.contractType.valueChanges.subscribe(resp => {
      this.resetPaginator();
      this.contractTypeSelected = resp;
      this.getHistory();
    });

    this.templateVersion.valueChanges.subscribe(resp => {
      if (this.templateVersion.valid) {
        this.resetPaginator();
        this.templateVersionSelected = resp;
        this.getHistory();
      }
    });

    this.templateDate.valueChanges.subscribe(resp => {
      if (this.templateDate.valid) {
        this.resetPaginator();
        this.templateDateSelected = this.formatDate(resp);
        this.getHistory();
      }
    });

    this.templateStatus.valueChanges.subscribe(resp => {
      this.resetPaginator();
      this.templateStatusSelected = resp;
      this.getHistory();
    });
  }

  getHistory() {
    const body = {
      perPage: this.pageSize || 5,
      page: this.page || 1,
      contract_type_id: this.contractTypeSelected,
      version: this.templateVersionSelected,
      created_at: this.templateDateSelected,
      status: this.templateStatusSelected,
    };

    this.templateService.getHistory(body)
      .pipe(
        map((resp: any) => {
          if (resp.data.length === 0) { this.HistorySource = null; }
          this.length = resp.total;
          return resp.data;
        }),
        mergeMap((data: any[]) => {
          const userIds: number[] = data.map(item => item.responsable_id);
          const uniqueUserIds = [...new Set(userIds)]; // Convertir a un conjunto y luego de nuevo a un array

          return this.templateService.getInfoRrhhIds(uniqueUserIds).pipe(
            map((response: any[]) => {
              const userMap = new Map<number, string>();
              response.forEach(user => {
                userMap.set(user.rrhh_id, user.first_name + ' ' + user.last_name);
              });

              return data.map(item => {
                item.created_at = this.formatDate(item.created_at);
                item.updated_at = this.formatDate(item.updated_at);
                item.responsable = userMap.get(item.responsable_id);
                return item;
              });
            }),
            map((formattedData: any[]) => {
              const formattedUserIds = uniqueUserIds.join(','); // Convertir los IDs en una cadena separada por comas
              return { formattedData, formattedUserIds };
            })
          );
        })
      )
      .subscribe(({ formattedData, formattedUserIds }) => {
        // Ahora puedes usar formattedUserIds para la URL y formattedData para tus datos formateados
        this.HistorySource = formattedData;
      });
  }

  formatDate(dateStr: string): string {
    if (!dateStr) { return ''; }
    const date = new Date(dateStr);
    return this.datePipe.transform(date, 'dd/MM/yyyy') || '';
  }

  preview(contract_template_id: any) {
    this.templateService.showTemplate(contract_template_id).subscribe(resp => {
      this.template = resp;
      const filepath = this.templateService.server.replace('/api/', '') + '/storage/previews/' + this.template.pdf_url;

      const dialogRef = this.dialog.open(TemplatePreviewComponent, {
        width: '800px',
        data: {filepath: filepath},
      });
    });
  }

  onPageChange(event: any) {
    this.page = event.pageIndex + 1;
    this.pageSize = event.pageSize;

    this.getHistory();
  }

  private applyFilter(param, filterValue: any) {

  }
  getStatusIconClass(status: number): string {
    return status === 1 ? 'green-icon' : 'red-icon' ;
  }

  upgrade(contract_template_id: any) {
    this.openConfirmationDialog(contract_template_id);
  }

  openConfirmationDialog(contract_template_id: any): void {
    const dialogRef = this.dialog.open(TemplateConfirmationComponent, {
      width: '400px',
      data: '¿Estás seguro de que deseas realizar la actualización?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.templateService.upgrade(contract_template_id).subscribe(resp => { this.ngOnInit(); });
      }
    });
  }

  resetPaginator(): void {
    this.length = 0;
    this.pageSize = 5;
    this.page = 1;
    if (this.paginator) {
      this.paginator.firstPage();
    }
  }

  resetFilters() {
    this.contractType.setValue(null);
    this.templateVersion.setValue(null);
    this.templateDate.setValue(null);
    this.templateStatus.setValue(null);

    this.contractTypeSelected = null;
    this.templateVersionSelected = null;
    this.templateDateSelected = null;
    this.templateStatusSelected = null;

    this.getHistory();
  }

  nonNegativeValidator(control) {
    if (control.value !== null && control.value !== '') {
      const value = Number(control.value);
      if (isNaN(value) || value < 0) {
        return { nonNegative: true };
      }
    }
    return null;
  }

  futureDateValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value === null || value === undefined) {
      return null; // No se aplica la validación si el campo está vacío
    }

    const selectedDate = new Date(value);
    const currentDate = new Date();

    if (selectedDate > currentDate) {
      return { futureDate: true }; // Indica que la validación ha fallado
    }

    return null; // La validación pasa
  }
}
