import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AssignCampaignComponent } from '../assign-campaign/assign-campaign.component';
import { ConfigCampainComponent } from '../campaign/config-campain/config-campain.component';
import { ConnectsComponent } from '../connection/connects/connects.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.sass']
})
export class AdminComponent implements OnInit {

  @ViewChild(ConnectsComponent) ConnectsComponent: ConnectsComponent;
  @ViewChild(ConfigCampainComponent) ConfigCampainComponent: ConfigCampainComponent;
  @ViewChild(AssignCampaignComponent) AssignCampaignComponent: AssignCampaignComponent;

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Método que se llama cuando cambia la pestaña en el componente de tabs.
   * @author Yeison Sepulveda
   * @createdate 2024-07-16
   * @param {MatTabChangeEvent} event - El evento de cambio de pestaña de Angular Material.
 */
  onTabChange(event: MatTabChangeEvent) {
    if (event.index === 0) {
      this.ConnectsComponent.getServers();
    } else if (event.index === 1) {
      this.ConfigCampainComponent.getCampaigns();
    } else if (event.index === 2) {
    }
  }

}
