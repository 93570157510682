import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/core/services/rest/auth.service';

const CRM_URL = environment.CRM2_URL;
const NOMINA_URL = environment.NOMINA_URL;
@Injectable({
  providedIn: 'root'
})
export class CampaingsService {
  putState(id: any, data: { state: any; }) {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient, private AuthService: AuthService) { }

  getCampaings(direction) {
    return this.http.get<any>(`${NOMINA_URL}${direction}`);
  }

  getCampaingsByUser(direction, id) {
    return this.http.get<any>(`${CRM_URL}${direction}${id}`);
  }

  /* UpdateState(id, state) {
    return this.http.post<any>(`${CRM_URL}campaigns/${id}/updateState`, state);
  } */
}
