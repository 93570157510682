<form class="row form" [formGroup]="passForm" (ngSubmit)="changePass(passForm)">

    <div class="col-7" style="padding-left: 4%; padding-right: 15%;">
        <mat-dialog-content style="padding: 3%;" class="mat-typography">
            <h2 mat-dialog-title>{{data.title}}</h2>
            <p cdkFocusInitial>Cambiar la contraseña evita una serie de peligros,
              recuerda que la nueva contraseña te permitirá ingresar tanto a SOUL como a gestión talento
            </p>
            <br>

            <mat-form-field class="col-12" appearance="fill">
                <mat-label>Contraseña actual</mat-label>
                <input matInput appBlockCopyPaste (keypress)="omitSpecialChar($event)" formControlName="actual" [type]="hide ? 'password' : 'text'" required>
                <mat-error *ngIf="error.actual.errors"><span *ngIf="error.actual.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                <mat-error *ngIf="error.actual.errors"><span *ngIf="error.actual.errors.minlength">¡Aún falta! Recibimos mínimo 5 caracteres</span></mat-error>
                <mat-error *ngIf="error.actual.errors"><span *ngIf="error.actual.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 15 caracteres</span></mat-error>
                <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ?  'visibility' : 'visibility_off'}}</mat-icon>
                </a>
            </mat-form-field>
            <br>
            <br>
            <mat-form-field class="col-12" appearance="fill">
                <mat-label>Contraseña nueva</mat-label>
                <input matInput appBlockCopyPaste (keypress)="omitSpecialChar($event)" formControlName="nueva" [type]="hideDos ? 'password' : 'text'" required>
                <mat-error *ngIf="error.nueva.errors"><span *ngIf="error.nueva.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                <mat-error *ngIf="error.nueva.errors"><span *ngIf="error.nueva.errors.minlength">¡Aún falta! Recibimos mínimo 5 caracteres</span></mat-error>
                <mat-error *ngIf="error.nueva.errors"><span *ngIf="error.nueva.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 15 caracteres</span></mat-error>
                <mat-error *ngIf="error.nueva.errors"><span *ngIf="error.nueva.errors.hasNumber">Su contraseña debe tener un número</span></mat-error>
                <mat-error *ngIf="error.nueva.errors"><span *ngIf="error.nueva.errors.hasCapitalCase">Su contraseña debe tener una mayuscula</span></mat-error>
                <mat-error *ngIf="error.nueva.errors"><span *ngIf="error.nueva.errors.hasSmallCase">Su contraseña debe tener una minuscula</span></mat-error>
                <mat-error *ngIf="error.nueva.errors"><span *ngIf="error.nueva.errors.hasSpecialCharacters">Su contraseña debe tener un caracter especial</span></mat-error>
                <mat-error *ngIf="error.nueva.errors"><span *ngIf="!error.nueva.errors.isMatching">Su nueva contraseña no puede ser igual a la actual</span></mat-error>
                <a mat-icon-button matSuffix (click)="hideDos = !hideDos" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideDos">
                    <mat-icon>{{hideDos ?  'visibility' : 'visibility_off'}}</mat-icon>
                </a>
            </mat-form-field>
            <br>
            <br>
            <mat-form-field class="col-12" appearance="fill">
                <mat-label>Confirma la contraseña nueva</mat-label>
                <input matInput appBlockCopyPaste (keypress)="omitSpecialChar($event)" formControlName="confirma" [type]="hideTres ? 'password' : 'text'" required>
                <mat-error *ngIf="error.confirma.errors"><span *ngIf="error.confirma.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                <mat-error *ngIf="error.confirma.errors"><span *ngIf="error.confirma.errors.minlength">¡Aún falta! Recibimos mínimo 5 caracteres</span></mat-error>
                <mat-error *ngIf="error.confirma.errors"><span *ngIf="error.confirma.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 15 caracteres</span></mat-error>
                <mat-error *ngIf="error.confirma.errors"><span *ngIf="error.confirma.errors.hasNumber">Su contraseña debe tener un número</span></mat-error>
                <mat-error *ngIf="error.confirma.errors"><span *ngIf="error.confirma.errors.hasCapitalCase">Su contraseña debe tener una mayuscula</span></mat-error>
                <mat-error *ngIf="error.confirma.errors"><span *ngIf="error.confirma.errors.hasSmallCase">Su contraseña debe tener una minuscula</span></mat-error>
                <mat-error *ngIf="error.confirma.errors"><span *ngIf="error.confirma.errors.hasSpecialCharacters">Su contraseña debe tener un caracter especial</span></mat-error>
                <mat-error *ngIf="error.confirma.errors"><span *ngIf="!error.confirma.errors.isMatching">Su contraseña no es igual a la ingresada previamente</span></mat-error>
                <a mat-icon-button matSuffix (click)="hideTres = !hideTres" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideTres">
                    <mat-icon>{{hideTres ?  'visibility' : 'visibility_off'}}</mat-icon>
                </a>
            </mat-form-field>

            <br><br>
            <p><b>Seguridad de la contraseña:</b><br>Tu contraseña debe tener mínimo 8 caracteres y al menos una mayúscula, una minúscula y un número.</p>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-raised-button type="submit" style="width: 100%;" color="primary" [disabled]="passForm.invalid" >Guardar</button>
        </mat-dialog-actions>
    </div>

    <div class="col-5">
        <div style="padding:6.25rem 4.656rem 5.875rem 4.656rem;">
            <img style="height: 90%; width: 90%;" src="../../../assets/images/changePass.png">
        </div>
    </div>

</form>
