<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<h3>Acciones por lotes</h3>
<hr>
<mat-dialog-content>

    <mat-horizontal-stepper linear #stepper>
        <mat-step [stepControl]="action" label="Acciones disponibles">
            <div  style="padding: 15px 25px 32px;">
                <mat-radio-group class="example-radio-group" fxLayout="column" [formControl]="action" (ngModelChange)="checkSelect($event)">
                    <mat-radio-button *ngFor="let row of actionsAvailable"
                    class="example-radio-button" [value]="row"> <span>{{row.name}}</span></mat-radio-button> 
                 </mat-radio-group>
            </div>
            <div align="center">
                <button mat-raised-button matStepperNext color="primary">Siguiente</button>
            </div>
        </mat-step>
        <mat-step label="Datos" [completed]="scheduleDate || formComment.value.comment || formReassignment.value">
            
             <div *ngIf="actionAux == 'schedule'" fxLayout="column">
                 <p> <strong>Agendar cita</strong></p>
                 <div class="row">
                     <div class="col-6">
                         <mat-form-field appearance="fill" style="max-width: 100%;">
                             <mat-label>Seleccione la fecha y hora a asignar</mat-label>
                             <input #fecha matInput type="datetime-local" [min]="now" [(ngModel)]="scheduleDate">
                         </mat-form-field>
                     </div>
                    <div class="col-6">
                        <mat-form-field appearance="fill" >
                            <mat-label>Fecha Inicial Operación</mat-label>
                            <input matInput [matDatepicker]="date1" [formControl]="initialOperation">
                            <mat-datepicker-toggle matSuffix [for]="date1"></mat-datepicker-toggle>
                            <mat-datepicker #date1></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div align="center">
                <button mat-stroked-button matStepperPrevious>Anterior</button>
                <button mat-raised-button color="primary" matStepperNext class="ml-2">Siguiente</button>
                </div>           
            </div>

            <div *ngIf="actionAux == 'comment'">
                <p> <strong>{{this.name}}</strong></p>
                 <form [formGroup]="formComment" fxLayout="column">
                   
                        <mat-form-field appearance="fill" fxFlex>
                            <mat-label>Tipo de Comentario</mat-label>
                            <mat-select formControlName="typeComment">
                                <mat-option *ngFor="let type of types_comments" [value]="type.name">
                                    {{type.name}}</mat-option>
                            </mat-select>   
                        </mat-form-field>
                        <mat-form-field appearance="fill" fxFlex *ngIf="formComment.value.typeComment == 'Retiro'" >
                            <mat-label>Tipo de retiro</mat-label>
                            <mat-select formControlName="comment" >
                             
                              <mat-optgroup *ngFor="let group of types_retreat" [label]="group.name">
                                <mat-option *ngFor="let type of group.types" [value]="type.viewValue">
                                  {{type.viewValue}}
                                </mat-option>
                              </mat-optgroup>
                              <!-- <mat-option >-- Otro --</mat-option> -->
                            </mat-select>
                          </mat-form-field>
                   
                        <mat-form-field appearance="fill" fxFlex>
                            <mat-label>Comentario</mat-label>
                            <textarea matInput rows="5" formControlName="comment"></textarea>
                        </mat-form-field>
                  
                        <div align="center">
                            <button mat-stroked-button matStepperPrevious type="button" (click)="cancelForm('formComment','createComment')">Anterior</button>
                            <button mat-raised-button color="primary" matStepperNext class="ml-2">Siguiente</button>
                        </div>
                   
                </form>
            </div> 
            <div *ngIf="actionAux == 'reassignment'">
                <p> <strong>Grupos disponibles</strong></p>
                    <div style="max-height: 214px; overflow: auto;">
                        <mat-radio-group class="example-radio-group" [formControl]="formReassignment">
                                <mat-radio-button fxLayout="column"  class="example-radio-button"  *ngFor="let groups of validGroups" [value]="groups.id">
                                    <span>{{groups.name}}</span>
                                </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <br>
                    <div align="center">
                        <button mat-stroked-button matStepperPrevious type="button" (click)="cancelForm('formReassignment','createComment')">Anterior</button>
                        <button mat-raised-button color="primary" matStepperNext class="ml-2">Siguiente</button>
                    </div>  
            </div>
        </mat-step>
        <mat-step label="Candidatos" [completed]="candidateSelected.selectedOptions.selected.length > 0">
            <div>
                <p><strong>Seleccione a los candidatos</strong> </p> 
                <div fxLayout="row" fxLayoutAlign="end center" > 
                    <mat-checkbox  
                                    [checked]="allCandidateSelected"
                                    (change)="allCandidateSelected= $event.checked; 
                                    allCandidateSelected? candidateSelected.selectAll():candidateSelected.deselectAll()">
                                    Seleccionar todos
                    </mat-checkbox>
                       
                </div>
                   
               
                <mat-selection-list #candidateSelected>
                    <mat-list-option checkboxPosition *ngFor="let candidate of candidates" [selected]="candidate.selected"  [value]="candidate">
                        {{candidate.name}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
            <div align="center">
                <button mat-stroked-button matStepperPrevious>Anterior</button>
                <button mat-raised-button color="primary" matStepperNext class="ml-2" (click)="saveCandidates(candidateSelected)">Siguiente</button>
            </div>
        </mat-step>
        <mat-step label="Confirmar">
            <p> <strong>Resumen de Acción</strong> </p>
            <mat-divider></mat-divider>
           <br>
           <b>Action </b> <p>{{description}}</p>
           <div *ngIf="actionAux == 'comment' ">
            <b>Tipo </b><p>{{ formComment.value.typeComment }}</p>
            <b>Comentario </b><p>{{ formComment.value.comment }}</p>
           </div>
           <div *ngIf="actionAux == 'schedule' " >
            <b>Fecha Seleccionada </b><p>{{ scheduleDate | date:'dd/MM/yyyy H:mm' }}</p>
           </div>
           <b>Candidatos Seleccionados</b>
           <div  fxLayout="row wrap" fxLayoutGap="20px grid">
                <p fxFlex=30 *ngFor="let row of candidatesSelected;let i = index">{{ i+1 }}. {{row.name}}</p>
           </div>
           
            <div align="center">
                <button mat-stroked-button matStepperPrevious>Anterior</button>
                <button mat-raised-button color="primary" class="ml-2" (click)="finishActions()">Guardar </button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>

</mat-dialog-content>
