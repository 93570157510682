import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { CreateLabelDialogComponent } from '../create-label-dialog/create-label-dialog.component';
import { SableService } from '../services/sable.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import * as moment from 'moment';
import { validateNumbersNegative } from '../constantes/custom-validators';

@Component({
  selector: 'app-crear',
  templateUrl: './crear.component.html',
  styleUrls: ['./crear.component.css']
})
export class CrearComponent implements OnInit {
  formCreateDownload: FormGroup = null;
  columnsAutomatic: string[] = [];
  llamadas: any = [];
  campaign: any = [];
  skill_llamadas: any = [];
  servidores: any = [];
  lista_grabaciones: any = [];
  connectors: string[] = ['@', '-', '_'];
  audioLabelingStructure: string = '';
  countRegisters: number = 0;

  constructor(
    private location: Location,
    private SableService: SableService,
    private matDialog: MatDialog,
    private alertService: AlertsService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.SableService.GetDataSelects('get_servidores').subscribe(resp => {
      this.servidores = resp.data;
    });
    this.formCreateDownload = new FormGroup({
      idserver: new FormControl('', [Validators.required]),
      campaign: new FormControl('', [Validators.required]),
      call: new FormControl('', [Validators.required]),
      dateStart: new FormControl('', [Validators.required]),
      dateEnd: new FormControl('', [Validators.required]),
      skillCalls: new FormControl('', [Validators.required]),
      recordsOrDownloads: new FormControl('', [validateNumbersNegative]),
      idCall: new FormControl('', [validateNumbersNegative]),
      labels: this.formBuilder.array([])
    });
    this.addLabel();
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-06-16
    * Metodo que retorna el form array del formulario principal.  
  */
  get labelsControls(): FormArray {
    return this.formCreateDownload.get('labels') as FormArray;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-16
    * Metodo que añade el form group al form array.  
  */
  addLabel(): void {
    this.labelsControls.push(this.newFormGroupLabel());
    this.updateValidatorsConnectors();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-16
    * Metodo que genera un nuevo form group.  
  */
  newFormGroupLabel(columnAutomaticValue: string = ''): FormGroup {
    return this.formBuilder.group({
      columnAutomatic: new FormControl(columnAutomaticValue, [Validators.required]),
      connector: new FormControl('', []),
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-16
    * Metodo que elimina un form group del form array.
    * @param index Indice del form array seleccionado.   
  */
  deleteLabel(index: number): void {
    this.labelsControls.removeAt(index);
    this.updateValidatorsConnectors();
    this.updateAudioLabelingStructure();
  }
  /**
   * @author Fabian Duran
   * @createdate 2023-06-15
   * Metodo que setea el orden de los elementos dentro del form array. 
   * @param formArray Form array del formulario principal. 
   * @param fromIndex Indice antiguo. 
   * @param toIndex Indice nuevo. 
 */
  moveItemInFormArray(formArray: FormArray, fromIndex: number, toIndex: number): void {
    const dir = toIndex > fromIndex ? 1 : -1;
    const item = formArray.at(fromIndex);
    for (let i = fromIndex; i * dir < toIndex * dir; i = i + dir) {
      const current = formArray.at(i + dir);
      formArray.setControl(i, current);
    }
    formArray.setControl(toIndex, item);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-16
    * Metodo que retorna los controles del formulario. 
  */
  get errorsForm(): any {
    return this.formCreateDownload.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-15
    * Metodo que redirecciona a la pagina anterior visitada. 
  */
  backToPreviousPage(): void {
    this.alertService.alertWarning('¿Estás seguro?', '¿En verdad deseas cancelar el registro para la descarga de las llamadas?').then(confirm => {
      if (confirm.isConfirmed) this.location.back();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-15
    * Metodo que el drag and drop de los campos del formulario. 
    * @param $event Evento emitido por el drag and drop al momento de arastrar elementos. 
  */
  dragAndDropItem($event: CdkDragDrop<string[]>): void {
    this.moveItemInFormArray(this.labelsControls, $event.previousIndex, $event.currentIndex);
    this.updateAudioLabelingStructure();
    this.updateValidatorsConnectors();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-15
    * Metodo muestra la modal para crear una etiqueta.
    * Añade una etiqueta mediante el formulario creado.  
  */
  openDialogCreateLabel(): void {
    this.matDialog.open(CreateLabelDialogComponent, {
      width: '40%',
      autoFocus: false
    }).afterClosed().subscribe(res => {
      if (res) {
        this.columnsAutomatic.push(res.nameLabel);
        this.labelsControls.push(this.newFormGroupLabel(res.nameLabel));
        this.updateAudioLabelingStructure();
        this.updateValidatorsConnectors();
      }
    });
  }
  /**
   * @author Fabian Duran
   * @createdate 2023-06-16
   * Metodo que cambia el valor de la variable audioLabelingStructure, dependiendo de la etiqueta seleccionada. 
   * @param $event Evento emitido por el select dinamico del drag and drop. 
  */
  onChangeSelectConector($event: any) {
    this.updateAudioLabelingStructure();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-15
    * Metodo que actualiza el nombre del etiquetado. 
  */
  updateAudioLabelingStructure(): void {
    const arrayLabels = this.formCreateDownload.value.labels;
    const setArrayLabels = arrayLabels.map((item: any) => `${item.columnAutomatic}${item.connector ? item.connector : ''}`);
    this.audioLabelingStructure = setArrayLabels.join('');
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-16CargarSelects
    * Metodo que guarda la configuracion de las descargas.  
  */
  generateDownload(): void {
    if (this.formCreateDownload.valid) {
      this.alertService.alertWarning(
        '¿Estás seguro?',
        `¿En verdad deseas <b>generar la descarga</b> de las llamadas?, recuerda que esta acción será definitiva y no se podrá deshacer. <br /> <div style="margin-top: 50px"><b>Estructura etiquetado del audio:</b> <br /> ${this.audioLabelingStructure}.zip</div>`).then(async (confirm) => {
          if (confirm.isConfirmed) {
            const dataForm = { ...this.formCreateDownload.value, audioLabel: this.audioLabelingStructure, ListRecord: this.lista_grabaciones };
            const resSave = await this.SableService.saveConfigDownloads(dataForm).toPromise();
            this.alertService.alertWithAnyProperties('Excelente', resSave.data.mensaje, 'success', 'Continuar').then(isContinue => {
              this.location.back();
            });
          }
        });
    } else {
      this.formCreateDownload.markAllAsTouched();
    }
  }
  /**
    * @author Fabio Garcia
    * @createdate 2023-06-20
    * Metodo que las columnas automaticas y el listado de grabaciones. 
  */
  GetColumns(): void {
    if (!this.validateRangeDates()) {
      const dataForm = { ...this.formCreateDownload.value, audioLabel: this.audioLabelingStructure };
      this.SableService.GetDataColumns(dataForm).subscribe(resp => {
        this.columnsAutomatic = resp.data.colums;
        this.lista_grabaciones = resp.data.data;
        this.countRegisters = resp.data.cantidad_registros;
      });
    } else {
      this.alertService.alertError('Error', 'El rango máximo de fechas es de <b>15 días</b>.');
    }
  }
  /**
    * @author Fabio Garcia
    * @createdate 2023-06-20
    * Metodo que trae las configuraciones dependiendo el servidor seleccionado. 
  */
  CargarSelects(server: any): void {
    this.SableService.GetDataSelectsServer('get_campaign', server).subscribe(resp => {
      this.campaign = resp.data;
    });
    this.SableService.GetDataSelectsServer('get_llamadas', server).subscribe(resp => {
      this.llamadas = resp.data;
    });
    this.SableService.GetDataSelectsServer('get_skill_llamadas', server).subscribe(resp => {
      this.skill_llamadas = resp.data;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-21
    * @updatedate 2023-07-18
    * Metodo que valida si los campos de la primera sección estan llenos.
    * Se añade validaciones sobre los campos numericos. 
    * @returns Estado de la validacion.  
  */
  validateFirstStepForm(): boolean {
    if (
      this.formCreateDownload.get('idserver').value !== '' &&
      this.formCreateDownload.get('campaign').value !== '' &&
      this.formCreateDownload.get('call').value !== '' &&
      this.formCreateDownload.get('dateStart').value !== '' &&
      this.formCreateDownload.get('skillCalls').value.length > 0
    ) {
      if (parseInt(this.formCreateDownload.get('recordsOrDownloads').value) < 0 || parseInt(this.formCreateDownload.get('idCall').value) < 0) return true;
      return false;
    }
    return true;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-22
    * Metodo que valida el rango de fechas seleccionadas del formulario.  
    * @returns Booleano con la validacion de la fechas. 
  */
  validateRangeDates(): boolean {
    const { dateStart, dateEnd } = this.formCreateDownload.value;
    const momentStart = moment(dateStart);
    const momentEnd = moment(dateEnd);
    const numberDays = momentEnd.diff(momentStart, 'days');
    return numberDays >= 15 ? true : false;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-10-25
    * Metodo que valida el ultimo conector para agregar o quitar una validacion sobre el campo connector.  
  */
  updateValidatorsConnectors(): void {
    const lengthArrayForm = this.labelsControls.length - 1;
    for (let i = 0; i < this.labelsControls.controls.length; i++) {
      if (i < lengthArrayForm) {
        this.labelsControls.controls[i].get('connector').setValidators([Validators.required]);
        this.labelsControls.controls[i].get('connector').enable();
        this.labelsControls.controls[i].get('connector').updateValueAndValidity();
      } else {
        this.labelsControls.controls[i].get('connector').setValidators([]);
        this.labelsControls.controls[i].get('connector').setValue('');
        this.labelsControls.controls[i].get('connector').disable();
        this.labelsControls.controls[i].get('connector').updateValueAndValidity();
      }
    }
  }
}