<div fxLayout="column">
    <div fxFlex="100%" fxLayout="row">
      <div fxFlex="50%">
        <mat-form-field appearance="fill" class="me-3" style="width: 23em;">
          <mat-label>Nombre agente</mat-label>
          <input matInput type="text" (blur)="filtro()" [(ngModel)]="filtroNombre">
          <mat-icon (click)="filtro()" matSuffix><i class="fi-rr-search"></i></mat-icon>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Cédula agente</mat-label>
          <input matInput type="number" (blur)="filtro()" [(ngModel)]="filtroCedula">
          <mat-icon (click)="filtro()" matSuffix><i class="fi-rr-search"></i></mat-icon>
        </mat-form-field>
      </div>
      <div fxFlex="50%" fxLayoutAlign="end none">
        <button mat-stroked-button color="accent" class="btn-plus" (click)="deleteAsesores()" style="margin-right: 2em;"
        *ngIf="selection.selected.length > 0">Eliminar agente
          <mat-icon style="font-size: 14px;margin-top: .6em;"><i class="fi-rr-trash"></i></mat-icon>
      </button>
        <button mat-raised-button color="primary" class="btn-plus" (click)="addAgent()">Añadir agente
            <mat-icon style="font-size: 14px;margin-top: .6em;"><i class="fi-rr-add"></i></mat-icon>
        </button>
      </div>
    </div>
    <div class="mat-elevation-z0 mt-4" fxFlex="100%">
        <table  [dataSource]="dataSource" mat-table  matSort>
    
          <!-- Checkbox Column -->
          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Columna idAgente -->
          <ng-container matColumnDef="id_agente">
            <th mat-header-cell *matHeaderCellDef> ID Agente </th>
            <td mat-cell *matCellDef="let element"> {{ element.id }} </td>
          </ng-container>
    
          <!-- Columna nombre agente -->
          <ng-container matColumnDef="nombre_agente">
            <th mat-header-cell *matHeaderCellDef> Nombre agente </th>
            <td mat-cell *matCellDef="let element"> {{ element.nombre_usuario }} </td>
          </ng-container>
    
          <!-- Columna nombre de documento -->
          <ng-container matColumnDef="numero_documento">
            <th mat-header-cell *matHeaderCellDef> Número de documento </th>
            <td mat-cell *matCellDef="let element"> {{ element.cedula_usuario }} </td>
          </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No hay registros para mostrar</td>
          </tr>
        </table>
    
        
        <mat-paginator [length]="length"
        [pageSize]="pageSize"
        (page) ="pageEvent($event)"
        [pageSizeOptions] = "pageSizeOptions"
        >
      </mat-paginator>
    </div>
</div>