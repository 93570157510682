<div class="row" *ngIf="Tab == 0">
    <div class="col-8" >

    </div>
    <div class="col-4" >
        <button  mat-raised-button 
        color="primary" 
        style="height: 48px; width: 232px; margin-left: 211px;" (click)="newCoaching()" 
        *ngIf="!is_asesor">
            Nuevo acompañamiento <mat-icon style="font-size: 16px;" class="mt-2"><i class="fi-rr-add"></i></mat-icon>
        </button>
    </div>
</div>
<br>
<div fxLayout="row" >
    <mat-form-field appearance="fill" class="me-3" fxFlex.gt-lg="20%" fxFlex.gt-md="18%">
        <mat-label *ngIf="!is_asesor">Filtrar por id, nombre o cédula</mat-label>
        <mat-label *ngIf="is_asesor">Id coaching</mat-label>
        <input [type]="!is_asesor ? 'text':'number'" matInput [(ngModel)]="filtroParametro" (blur)="filtrar()">
    </mat-form-field>
    <mat-form-field appearance="fill" class="me-3" fxFlex.gt-lg="15%" fxFlex.gt-md="15%">
        <mat-label>Fecha</mat-label>
        <mat-date-range-input [rangePicker]="rango" [formGroup]="filtroFecha">
            <input matStartDate placeholder="Fecha inicio" formControlName="fechaInicial">
            <input matEndDate placeholder="Fecha fin" formControlName="fechaFinal" (dateChange)="filtroFechas()">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rango">
            <mat-icon matDatepickerToggleIcon><i class="fi-rr-calendar"></i></mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker #rango></mat-date-range-picker>
    </mat-form-field>
    <mat-form-field appearance="fill" class="me-3" fxFlex.gt-lg="15%" fxFlex.gt-md="15%">
        <mat-label>Indicador</mat-label>
        <mat-select [(value)]="filtroIndicador" (selectionChange)="filtrar()">
            <mat-option>Todos</mat-option>
            <mat-option *ngFor="let indicador of indicadores$ | async" [value]="indicador.id">{{indicador.nombre}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="me-3" fxFlex.gt-lg="15%" *ngIf="Tab == 0" fxFlex.gt-md="15%">
        <mat-label>Estado</mat-label>
        <mat-select [(value)]="filtroEstado" (selectionChange)="filtrar()">
            <mat-option>Todos</mat-option>
            <mat-option *ngFor="let estado of estados " [value]="estado.id">{{estado.nombre}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="me-3" fxFlex.gt-lg="35%"  fxFlex.gt-md="35%" *ngIf="Tab == 0">
        <mat-label >Reasignado</mat-label>
        <mat-select [(value)]="filtroReasignado" (selectionChange)="filtrar()">
            <mat-option value="-" >Todos</mat-option>
            <mat-option value="1">Sí</mat-option>
            <mat-option value="0">No</mat-option>            
        </mat-select>
    </mat-form-field>

</div>