import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SolicitudModel } from 'src/app/modules/coaching/core/interfaces/solicitud.interface';
import { CoachingState } from 'src/app/modules/coaching/state/coaching.state';
import { solicitudSelect } from 'src/app/modules/coaching/state/selectors/solicitud.selector';

@Component({
  selector: 'app-info-solicitud',
  templateUrl: './info-solicitud.component.html',
  styleUrls: ['./info-solicitud.component.sass']
})
export class InfoSolicitudComponent implements OnInit {
  solicitud$:Observable<any> = new Observable();
  solicitud:SolicitudModel;
  hiddenItems:boolean=true;
  constructor(
    private store:Store<CoachingState>
  ) { }

  ngOnInit(): void {
    this.solicitud$ = this.store.select(solicitudSelect);
    this.solicitud$.forEach(element => {
      if (element) {
        this.solicitud = element[0];
        if(this.solicitud.acompanamiento.tipo_proceso.nombre.toLowerCase().includes("felicitac")){
          this.hiddenItems= false;
        }
      }
    });
  }

}
