import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpBackend } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: "root"
  })
export class HttpService {
    public url: string;

private httpClient: HttpClient;

    constructor(private http: HttpClient, handler: HttpBackend) {
        this.url = environment.LIVECHAT_URL;
        this.httpClient = new HttpClient(handler);
     }

    post(endpoint:string , form: any){
            return this.http.post(`${environment.LIVECHAT_URL}${endpoint}`, form);
    }

    getExternal(endpoint: string){
        return this.http.get(`${endpoint}`)
    }

    get( endpoint: string, params: any = {} ){
      let query:any = {}
      if(Array.isArray(params)){
        if(params.length !== 0){
          query = new HttpParams();
          for(const item of params){
            query = query.append(Object.keys(item)[0], item[Object.keys(item)[0]])
          }
        }

        return this.http.get(`${environment.LIVECHAT_URL}${endpoint}`,{ params: query })
      }

      return this.http.get(`${environment.LIVECHAT_URL}${endpoint}`,{ params })

    }
    delete(endpoint: string,id:string) {
        return this.http.delete(`${this.url}${endpoint}/${id}`);
    }

    getNotes(id_conversation): Observable<any> {
        return this.http.get(this.url +'notes/list/'+id_conversation);
      }

    getTemplate(project_id): Observable<any> {
        return this.http.get(this.url +'shortcut/list/'+project_id);
      }

    update(endpoint:string , form: any){
        return this.http.put(`${environment.LIVECHAT_URL}${endpoint}`, form);
      }

    getOne(endpoint: string,id:string) {
        return this.http.get(`${this.url}${endpoint}/${id}`);
    }

    insertPost(message: any): Observable<any>{
      return this.http.post(`${this.url}comments/insertPost`, message);
    }

    consumoRPS(message:any): Observable<any>{

      return this.httpClient.post(`https://apigateway.outsourcingcos.com/rpa-service/rpa.comment?plataforma=${message.plataforma}&postID=${message.postId}&agent=${message.agente}&comment=${message.comentario}`, null);

    }

    enviarMensajeRPAchannel(message:any): Observable<any>{
      return this.httpClient.post(`${environment.LIVECHAT_URL}channels/sendConversation`, message);
    }

}
