<h3>Crear configuración de reporte</h3>
<hr>
<form action="" [formGroup]="form"  >
  <div class="row">
    <div class="col-6">
      <mat-form-field class="example-full-width w-100" appearance="fill">
        <mat-label>Nombre del reporte *</mat-label>
        <input
          matInput
          placeholder       = "Nombre del reporte"
          formControlName   = "nombre"
          autocomplete      = "off"
        >
        <mat-error>Campo obligatorio</mat-error>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field class="example-full-width w-100" appearance="fill">
        <mat-label>Identificador de reporte*</mat-label>
        <input
          matInput
          placeholder       = "Ej: interactive_dashboard"
          formControlName   = "key"
          autocomplete      = "off"
        >
        <mat-error>Campo obligatorio</mat-error>
        <mat-error>No debe contener espacios ni caracteres especiales</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div>
      <mat-form-field
        appearance      ="fill"
        class           ="w-100"
        >
        <mat-label>Tipo</mat-label>
        <mat-select
          formControlName   = "type_assignment"
          (selectionChange) = "typeChange(type.value)"
          #type>
          <mat-option
            [value] = "type.id"
            *ngFor  = "let type of type_assignment"
              > {{type.name}} </mat-option>
        </mat-select>
        <mat-error>Seleccione un elemento de la lista</mat-error>
      </mat-form-field>
    </div>

      <div *ngIf="type.value == 1" class="col-5">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Número de documento</mat-label>
          <input
            matInput
            #numero_documento
            formControlName = "numero_documento"
            placeholder     = "Número de documento"
            autocomplete    = "off"
            >
            <mat-error>Campo obligatorio</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="type.value == 1" class="col-5">
        <mat-form-field
          appearance   = "fill"
          class        = "w-100"
          >
          <mat-label>Tipo</mat-label>
          <mat-select
            formControlName = "type_document"
            >
            <mat-option [value]="type.id" *ngFor="let type of type_documents" > {{type.name}} </mat-option>
          </mat-select>
          <mat-error>Seleccione un elemento de la lista</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="type.value == 1" class="col-2">
        <button
          mat-raised-button
          (click) ="findPerson()"
          color   ="primary"
          class   ="btn-detalle btn-hover-raised"
        >
          <i class="fi fi-rr-plus-small"></i>
        </button>
      </div>

  </div>



  <div class="row" *ngIf="type.value == 2" >
    <div class="col-6">
      <mat-form-field
        class         = "example-full-width w-100"
        appearance    = "fill"
        *ngIf         = "campaings"
        >

        <mat-label>Campaña</mat-label>
        <input
              matInput
               type               = "text"
               placeholder        = "Seleccione una campaña"
               aria-label         = "Campaña"
               formControlName    = "campaign_id"

               [matAutocomplete]  = "auto">
        <mat-autocomplete
          autoActiveFirstOption
          #auto="matAutocomplete"
          [displayWith]="displayCampaign.bind(this)"
          >
          <mat-option *ngFor="let camp of filteredCampaigns | async" [value]="camp.id">
            {{camp.name}}
          </mat-option>
        </mat-autocomplete>
        <mat-error>Seleccione un elemento de la lista</mat-error>
      </mat-form-field>
    </div>

    <div class="col-6">
      <mat-form-field
        class         = "example-full-width w-100"
        appearance    = "fill"
        *ngIf         = "positions"
        >

        <mat-label>Cargo</mat-label>
        <input
              matInput
               type               = "text"
               placeholder        = "Seleccione un cargo"
               aria-label         = "Cargo"
               formControlName    = "position_id"
               [matAutocomplete]  = "auto">
        <mat-autocomplete
          autoActiveFirstOption
          #auto="matAutocomplete"
          [displayWith]="displayPosition.bind(this)"
          >
          <mat-option *ngFor="let pos of filteredPositions | async" [value]="pos.id">
            {{pos.name}}
          </mat-option>
        </mat-autocomplete>
        <mat-error>Seleccione un elemento de la lista</mat-error>
      </mat-form-field>
    </div>
  </div>




  <div class="row" *ngIf="type.value == 1 && usuariosPermisos.length > 0 ">
    <div class="col-12">
      <mat-selection-list formControlName="usuarios" >
        <mat-list-option
          *ngFor  ="let usuario of usuariosPermisos"
          [value] ="usuario.rrhh_id"
          >
          <span style="font-size: 13px!important;">
            <b>Nombre: </b>{{usuario.name}} | <b>Doc: </b>{{ usuario.document }}
          </span>
        </mat-list-option>
      </mat-selection-list>

    </div>
  </div>
  <hr>



  <div class="row">
    <div class="demo-button-container mb-2" >
      <a class="add-product" [routerLink]="" (click)="addNewItem(null)">
        <i class="fi-rr-add"></i> Añadir filtro </a>
      </div>
      <table  class="table example-form " >
        <tbody>

          <tr  formArrayName="filters" *ngFor="let item of getFiltersControls(); let i = index;">
            <ng-container [formGroupName]="i" >
              <td>
                <mat-form-field appearance="fill"  >
                  <mat-label>Columna</mat-label>
                  <input
                    matInput
                    type              = "text"
                    formControlName   = "column"
                    autocomplete      = "off"
                    placeholder       = "Nombre de la columna">
                    <mat-error> Campo obligatorio </mat-error>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="fill"  >
                  <mat-label>Valor</mat-label>
                  <input
                    matInput
                    type              = "text"
                    formControlName   = "value"
                    autocomplete      = "off"
                    placeholder       = "Separados por una coma(,)">
                    <mat-error> Campo obligatorio </mat-error>
                </mat-form-field>
              </td>

              <td>
                <button mat-icon-button
                    color   = "primary"
                    type    = "button"
                    title   = "delete item"
                    (click) = "removeItem(i)">
                  <i class="fi-rr-trash"></i>
                </button>
              </td>
            </ng-container>
          </tr>

        </tbody>
      </table>
  </div>

    <div class="row">
      <div class="col-12 d-flex flex-row-reverse">
        <button class="m-1 " mat-stroked-button mat-dialog-close>Cancelar</button>
        <button
          mat-raised-button
          class         = "m-1 "
          (click)       = "guardar()"
          color         = "primary"
          [disabled]    = "!form.valid"
        >Guardar</button>
      </div>
    </div>
  </form>
