<div>
  <h2>Creación de nueva variable</h2>
  <p>Aquí podrás crear variables necesarias para complementar la información de tus plantillas a cargar,
    recuerda que una variable es un campo que el sistema reemplazará cuando se genere un contrato,
    ej {{ "${candidate_name}" }} en todas las plantillas será reemplazada por el nombre del candidato al generar el
    archivo.
  </p>
  <br><br>
  <form (ngSubmit)="onSubmit()" [formGroup]="tagForm">
    <div class="row">
      <mat-form-field class="col-6" appearance="fill">
        <mat-label>Origen</mat-label>
        <mat-select formControlName="contractOrigin">
          <mat-option *ngFor="let item of data.contractOrigins" [value]="item.id">{{ item.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="error.contractOrigin.errors?.required">Éste campo es requerido</mat-error>
      </mat-form-field>
      <mat-form-field class="col-6" appearance="fill">
        <mat-label>Key</mat-label>
        <input type="text" matInput formControlName="tagName">
        <mat-error *ngIf="error.tagName.errors?.maxlength">Máximo 20 carácteres</mat-error>
        <mat-error *ngIf="error.tagName.errors?.required">Éste campo es requerido</mat-error>
        <mat-error *ngIf="error.tagName.errors?.pattern">Éste campo no permite espacios en blanco, números ni caracteres especiales a excepción de "_"</mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="col-6" appearance="fill">
        <mat-label>Tipos de contrato</mat-label>
        <mat-select formControlName="contractType">
          <mat-option [value]="0">Todos</mat-option>
          <mat-option *ngFor="let item of contractTypes" [value]="item?.contract_type_id">{{item?.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="error.contractType.errors?.required">Éste campo es requerido</mat-error>
      </mat-form-field>

      <mat-form-field class="col-6" appearance="fill">
        <mat-label>¿Precondición?</mat-label>
        <mat-select formControlName="useTemplateTag" (selectionChange)="enableFields($event.value)">
         <mat-option [value]="1">Si</mat-option>
         <mat-option [value]="2">No</mat-option>
        </mat-select>
        <mat-error *ngIf="error.useTemplateTag.errors?.required">Éste campo es requerido</mat-error>
      </mat-form-field>

    </div>
    <div class="row">
      <mat-form-field class="col-6" appearance="fill">
        <mat-label>Descripción</mat-label>
        <textarea type="text" matInput formControlName="tagDescription"></textarea>
        <mat-error *ngIf="error.tagDescription.errors?.maxlength">Máximo 200 carácteres</mat-error>
        <mat-error *ngIf="error.tagDescription.errors?.required">Éste campo es requerido</mat-error>
      </mat-form-field>

      <mat-form-field class="col-6" appearance="fill" *ngIf="tagForm.get('useTemplateTag').value == 1">
        <mat-label>Selecciona precondición</mat-label>
        <mat-select formControlName="templateTag">
          <mat-option *ngFor="let item of templateTags" [value]="item?.id">{{item?.key}}</mat-option>
        </mat-select>
        <mat-error *ngIf="error.templateTag.errors?.required">Éste campo es requerido</mat-error>
      </mat-form-field>
    </div>

    <div class="row" *ngIf="tagForm.get('useTemplateTag').value == 1">
      <mat-form-field class="col-12" appearance="fill">
        <mat-label>Contenido</mat-label>
        <textarea type="text" matInput formControlName="tagValue"></textarea>
        <mat-error *ngIf="error.tagValue.errors?.required">Éste campo es requerido</mat-error>
      </mat-form-field>
    </div>

    <div class="row" *ngIf="tagForm.get('useTemplateTag').value == 2">
      <mat-form-field class="col-12" appearance="fill">
        <mat-label>Source</mat-label>
        <input type="text" matInput formControlName="source">
        <mat-error *ngIf="error.source.errors?.required">Éste campo es requerido</mat-error>
      </mat-form-field>
    </div>
    <div style="text-align: right">
      <button type="submit" mat-flat-button color="primary" style="margin-right: 1em;"
        [disabled]="tagForm.invalid">Guardar</button>
      <button type="button" mat-stroked-button (click)="onCancel()">Cancelar</button>
    </div>
  </form>
</div>
