import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';

import { CoachingRoutingModule } from './coaching-routing.module';
import { CoachingComponent } from './modules/coaching/coaching.component';
import { ReporteComponent } from './modules/reporte/reporte.component';
import { TablaCoachingComponent } from './modules/coaching/components/tabla-coaching/tabla-coaching.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { CoachingAppComponent } from './coaching-app.component';
import { FiltroTablaComponent } from './modules/coaching/components/filtro-tabla/filtro-tabla.component';
import { NuevoCoachingComponent } from './modules/coaching/components/nuevo-coaching/nuevo-coaching.component';
import { DetalleCoachingComponent } from './modules/coaching/components/detalle-coaching/detalle-coaching.component';
import { AuditoriaComponent } from './modules/coaching/components/detalle-coaching/components/auditoria/auditoria.component';
import { StoreModule } from '@ngrx/store';
import { ROOT_REDUCERS_COACHING } from './state/coaching.state';
import { EffectsModule } from '@ngrx/effects';
import { AuditoriasEffects } from './state/efects/auditoria.efects';
import { CalidadModule } from '../calidad/calidad.module';
import { CutTextPipe } from '../calidad/pipes/cut-text.pipe';
import { SelectsEffects } from './state/efects/selects.efects';
import { AcompañamientoEffects } from './state/efects/acompañamiento.efects';
import { InfoSolicitudComponent } from './modules/coaching/components/detalle-coaching/components/info-solicitud/info-solicitud.component';
import { FormSolicitudComponent } from './modules/coaching/components/detalle-coaching/components/form-solicitud/form-solicitud.component';
import { FormAuditoriaCoachingComponent } from './modules/coaching/components/detalle-coaching/components/form-auditoria-coaching/form-auditoria-coaching.component';
import { TablaHistoricoComponent } from './modules/coaching/components/detalle-coaching/components/tabla-historico/tabla-historico.component';
import { SolicitudEffects } from './state/efects/solicitud.efects';
import { PasoPasoComponent } from './modules/coaching/components/paso-paso/paso-paso.component';
import { Historialffects } from './state/efects/historial.efects';
import { EstadoPipe } from './pipes/estado.pipe';
import { DialogHistoricoComponent } from './modules/coaching/components/detalle-coaching/components/dialog-historico/dialog-historico.component';
import { FiltrosComponent } from './modules/reporte/components/filtros/filtros.component';
import { FormAsesorComponent } from './modules/coaching/components/detalle-coaching/components/form-asesor/form-asesor.component';
import { JefeInmediatoComponent } from './modules/jefe-inmediato/jefe-inmediato.component';
import { ModalAsignacionesComponent } from './modules/jefe-inmediato/componentes/modal-asignaciones/modal-asignaciones.component';
import { MaxTextPipe } from './pipes/max-text.pipe';
import { ArrayToStringPipe } from './pipes/array-to-string.pipe';


@NgModule({
  declarations: [
    CoachingAppComponent,
    CoachingComponent,
    ReporteComponent, 
    TablaCoachingComponent, 
    FiltroTablaComponent, 
    NuevoCoachingComponent, 
    DetalleCoachingComponent, 
    AuditoriaComponent, 
    InfoSolicitudComponent, 
    FormSolicitudComponent, 
    FormAuditoriaCoachingComponent, 
    TablaHistoricoComponent, 
    PasoPasoComponent, 
    EstadoPipe, 
    DialogHistoricoComponent, 
    FiltrosComponent, 
    FormAsesorComponent, 
    JefeInmediatoComponent, ModalAsignacionesComponent, MaxTextPipe, ArrayToStringPipe, 
    
  ],
  imports: [
    CommonModule,
    CoachingRoutingModule,
    SharedModule,
    MaterialModule,
    StoreModule.forFeature('COACHING',ROOT_REDUCERS_COACHING),
    EffectsModule.forFeature([
      AuditoriasEffects,
      SelectsEffects,
      AcompañamientoEffects,
      SolicitudEffects,
      Historialffects
    ]),
    CalidadModule,
  ],
  providers:[
    CutTextPipe,
    AsyncPipe
  ]
})
export class CoachingModule { }
