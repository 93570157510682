import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RequestsService } from './../../../services/requests.service';
import { RecruitersService } from './../../../services/recruiters.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';

@Component({
  selector: 'app-modal-assign-recruiter',
  templateUrl: './modal-assign-recruiter.component.html',
  styleUrls: ['./modal-assign-recruiter.component.sass']
})
export class ModalAssignRecruiterComponent implements OnInit {

  constructor(private reclutadorService: RecruitersService,
              private solicitudService: RequestsService,
              public dialogRef: MatDialogRef<ModalAssignRecruiterComponent>,
              private alertService: AlertsService) { }

  reclutadores = [];
  reclutadorId: number;
  requestId: number;

  ngOnInit(): void {
    this.reclutadorService.getReclutadores('lider').subscribe(( value: any ) => {
      this.reclutadores = value;
    });
  }

  selectReclutador(userSelected) {
    this.reclutadorId = userSelected;
  }

  asignarReclutador() {
    this.alertService.alertSuccess('Asignado', 'Su solicitud se ha asignado correctamente');
  }
}
