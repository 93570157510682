import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { UsersService } from '../../../services/rest/users.service';
import { FormBuilder } from '@angular/forms';
import { FormArray } from '@angular/forms';
import { ApplicationsService } from '../../../services/rest/applications.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { CampaignService } from '../../../services/rest/campaign.service';

@Component({
  selector: 'app-save-users',
  templateUrl: './save-users.component.html',
  styleUrls: ['./save-users.component.sass']
})
export class SaveUsersComponent implements OnInit, OnDestroy {

  userForm: FormGroup;
  hide: boolean = true;
  idTypes: any;
  applications: any;
  roles: any;
  filteredOptionsCampaign: Observable<any[]>;
  filteredOptions: Observable<any[]>;
  filteredOptionsRoles: Observable<any[]>;
  errorUser: any;
  countValidate: any;
  campaigns: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private destroyService: DestroyComponentService,
    public userService: UsersService,
    private form: FormBuilder,
    private appService: ApplicationsService,
    private alertsService: AlertsService,
    private campaignService: CampaignService) { }

  ngOnInit(): void {
    this.formControl();
    this.newappxrol();

    this.userService.getIdTypes().subscribe((resp) => {
      this.idTypes = resp.data;
    });

    this.appService.getApplications().subscribe((resp) => {
      this.applications = resp.data;
    });

    this.campaignService.getCampaignsSelect().subscribe((resp) => {
      this.campaigns = resp.data;
      this.filteredOptionsCampaign = this.userForm.get('campaign').valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(campaigns => campaigns ? this._filterCampaign(campaigns) : this.campaigns.slice())
      );
    })


  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * filtra por nombre, se usa para el autocompletable
   * @param value valor a filtrar
   */
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.applications.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * filtra por nombre de rol, se usa para el autocompletable
   * @param value valor a filtrar
   */
  private _filterRol(value: string): any[] {
    const filterValueRol = value.toLowerCase();
    return this.roles.filter(option => option.name.toLowerCase().indexOf(filterValueRol) === 0);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * filtra por nombre de rol, se usa para el autocompletable
   * @param value valor a filtrar
   */
  private _filterCampaign(value: string): any[] {
    const filterValueCampaign = value.toLowerCase();
    return this.campaigns.filter(option => option.name.toLowerCase().indexOf(filterValueCampaign) === 0);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   */
  displayFn(id: number): string {
    if (!id) { return ''; }
    let index = this.applications.findIndex(resp => resp.id === id);
    return this.applications[index].name;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   */
  displayFnRoles(id: number): string {
    if (!id) { return ''; }
    let index = this.roles.findIndex(resp => resp.id === id);
    return this.roles[index].name;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   */
  displayFnCampaign(id: number): string {
    if (!id) { return ''; }
    let index = this.campaigns.findIndex(resp => resp.id === id);
    return this.campaigns[index].name;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que realiza el filtrado de alguna aplicacion
   * @param pos posicion del control a filtrar
   */
  getFilter(pos: number): void {
    this.filteredOptions = this.appxrol.controls[pos].get('app').valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(applications => applications ? this._filter(applications) : this.applications.slice())
    );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que realiza el filtrado de algun rol
   * @param pos posicion del control a filtrar
   */
  getfilterRol(pos: number): void {
    this.filteredOptionsRoles = this.appxrol.controls[pos].get('rol').valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(roles => roles ? this._filterRol(roles) : this.roles.slice())
    );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que se activa cuando una aplicacion ha sido seleccionada, busca los roles de la aplicacion seleccionada
   * @param id id de la aplicacion
   * @param pos posicion del grupo de app y rol
   */
  appSelected(id: any, pos: number): void {

    this.appxrol.controls[pos].get('rol').setValue('');

    this.appService.getRolesxApp(id).subscribe((resp) => {
      this.roles = resp.data;
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que valida las funciones del autocompetable
   */
  autocompleteObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string') {
        return { invalidAutocompleteObject: { value: control.value } };
      }
      return null;
    };
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo donde se establecen las validaciones del formulario
   */
  formControl(): void {

    this.userForm = this.form.group({
      user: new FormControl('', [Validators.required, Validators.maxLength(15), Validators.minLength(3)]),
      password: new FormControl('', [this.passwordValidate(), Validators.required, Validators.maxLength(15), Validators.minLength(5)]),
      first_name: new FormControl('', [Validators.required, Validators.maxLength(20), Validators.minLength(3)]),
      middle_name: new FormControl('', [Validators.maxLength(20), Validators.minLength(3)]),
      last_name: new FormControl('', [Validators.required, Validators.maxLength(20), Validators.minLength(3)]),
      second_last_name: new FormControl('', [Validators.maxLength(20), Validators.minLength(3)]),
      id: new FormControl('', [Validators.required, Validators.pattern('^([0-9])*$'), Validators.maxLength(15), Validators.minLength(5)]),
      email: new FormControl('', [Validators.maxLength(50), Validators.minLength(5), Validators.email]),
      phone: new FormControl('', [Validators.maxLength(15), Validators.minLength(7)]),
      idType: new FormControl('', [Validators.required]),
      campaign: new FormControl('', [this.autocompleteObjectValidator(), Validators.required]),
      dobAuth: new FormControl(''),
      appxrol: this.form.array([])
    });

  }

  get error(): any { return this.userForm.controls; }

  get appxrol(): FormArray {
    return this.userForm.get('appxrol') as FormArray;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que estable las validaciones del formarray dentro del formulario
   */
  newappxrol(): void {
    const newappxrol = this.form.group({
      app: new FormControl('', [Validators.required]),
      rol: new FormControl('', [Validators.required]),
    });
    this.appxrol.push(newappxrol);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que se encarga de la eliminacion de un grupo de aplicacion y rol
   * @param indice indice del grupo de aplicacion y rol a eliminar
   */
  deleteappxrol(indice: number): void {
    this.appxrol.removeAt(indice);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que se encarga de las validaciones del campo password
   */
  passwordValidate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!/[A-Z]/.test(control.value)) {
        return { hasCapitalCase: { value: control.value } };
      }
      else if (!/[a-z]/.test(control.value)) {
        return { hasSmallCase: { value: control.value } };
      }
      else if (!/[!@#$%^&*()_+=[{};':"|,.<>/?/{};':"|,.<>/?-]/.test(control.value)) {
        return { hasSpecialCharacters: { value: control.value } };
      }
      else if (!/\d/.test(control.value)) {
        return { hasNumber: { value: control.value } };
      }
      return null;
    };
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que evalua en tiempo real los caracteres ingresados en el input, no deja ingresar numeros ni espacios
   * @param event evento del keypress del input usuario
   */
  omitSpecialChar(event): any {
    let k;
    k = event.charCode;
    return ((k > 32 && k < 126));
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que evalua en tiempo real los caracteres ingresados en el input
   * @param event evento del keypress del input usuario
   */
  omitSpecialCharId(event): any {
    let k;
    k = event.charCode;
    return ((k > 47 && k < 58 || k > 64 && k < 91 || k > 96 && k < 122));
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que evalua en tiempo real los caracteres ingresados en el input
   * @param event evento del keypress del input usuario
   */
  omitSpecialCharPhone(event): any {
    let k;
    k = event.charCode;
    return ((k > 47 && k < 58));
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que se encarga de guardar un usuario
   * @param userForm datos del usuario a guardar
   */
  saveUser(userForm): void {

    const user = {
      username: userForm.value.user,
      last_name: userForm.value.last_name,
      middle_name: userForm.value.middle_name,
      second_last_name: userForm.value.second_last_name,
      phone: userForm.value.phone,
      mobile_phone: userForm.value.mobile_phone,
      password: userForm.value.password,
      first_name: userForm.value.first_name,
      id_type_id: userForm.value.idType,
      id_number: userForm.value.id,
      email: userForm.value.email,
      campaign_id: userForm.value.campaign,
      appxrol: userForm.value.appxrol,
      dob_auth: userForm.value.dobAuth
    };
    this.userService.saveUser(user).subscribe((resp) => {
      this.alertsService.alertSuccess('Guardado', resp.data);
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que valida en tiempo real si el usuario a ingresar ya existe o no
   */
  validateUser(): void {

    const userName = new FormData();

    userName.append('username', this.userForm.get('user').value);

    this.userService.searchNameUser(userName).subscribe((resp) => {
      if (resp.data.status === 'error') {
        this.userForm.controls.user.setErrors({ invalidUser: true });
        this.errorUser = resp.data.message;
      }
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que valida si el documento ya esta registrado en la base de datos, si ya existe setea automaticamente la info en los campos
   */
  validateDocumentId(): void {

    const dataId = new FormData();

    if (this.userForm.get('id').value == '') {
      dataId.append('id_number', '0');
    } else {
      dataId.append('id_number', this.userForm.get('id').value);
    }

    dataId.append('id_type_id', this.userForm.get('idType').value);

    this.userService.searchId(dataId).subscribe((resp) => {
      if (resp.data.status === 'found') {
        this.userForm.controls.name.setValue(resp.data.user.rrhh.first_name);
        this.userForm.controls.surname.setValue(resp.data.user.rrhh.last_name);
        this.userForm.controls.email.setValue(resp.data.user.rrhh.email);
        this.userForm.controls.phone.setValue(resp.data.user.rrhh.phone);
      }
    });

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-19
   * Metodo que verifica que un rol no se elija dos veces para la misma aplicacion
   * @param pos posicion del grupo de app y select
   */
  verifyRoles(pos: number): void {

    this.countValidate = 0;

    this.appxrol.value.forEach(element => {

      if (element.rol === this.appxrol.value[pos].rol && element.app === this.appxrol.value[pos].app) {

        this.countValidate++;

        if (this.countValidate > 1) {
          this.appxrol.controls[pos].get('rol').setErrors({ invalidRol: true });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

}

