<button mat-icon-button class="btn-paso" color="primary" (click)="pasoPaso = !pasoPaso" [matMenuTriggerFor]="pasos">
    <!-- <mat-icon><i [ngClass]="{'fi-rr-angle-small-left': !pasoPaso,'fi-rr-angle-small-right': pasoPaso}"></i></mat-icon> -->
    <mat-icon><i class="fi-rr-angle-small-left"></i></mat-icon> Paso a paso
  </button>
<div id="menuPaso" >
    <mat-menu #pasos='matMenu' class="menu-principal">
        <!-- <h3>Paso a paso del coaching</h3> -->
        <button mat-menu-item [matMenuTriggerFor]="ambiente"><mat-icon matPrefix><i class="fi-rr-feather"></i></mat-icon>Crear ambiente</button>
        <button mat-menu-item [matMenuTriggerFor]="hielo"><mat-icon matPrefix><i class="fi-rr-following"></i></mat-icon>Romper el Hielo-Relacionamiento</button>
        <button mat-menu-item [matMenuTriggerFor]="objetivos"><mat-icon matPrefix><i class="fi-rr-life-ring"></i></mat-icon>Definición de objetivos</button>
        <button mat-menu-item [matMenuTriggerFor]="preguntas"><mat-icon matPrefix><i class="fi-rr-interrogation"></i></mat-icon>Preguntas poderosas</button>
        <button mat-menu-item [matMenuTriggerFor]="escucha"><mat-icon matPrefix><i class="fi-rr-feather"></i></mat-icon>Escucha activa</button>
        <button mat-menu-item [matMenuTriggerFor]="responsabilidad"><mat-icon matPrefix><i class="fi-rr-thumbs-up"></i></mat-icon>Responsabilidad</button>
        <button mat-menu-item [matMenuTriggerFor]="acciones"><mat-icon matPrefix><i class="fi-rr-list-check"></i></mat-icon>Diseño de acciones</button>
    </mat-menu>
</div>

<!-- Menús paso a paso -->
<mat-menu #ambiente="matMenu" yPosition="below" xPosition="before" class="ambiente">
    <div >
        <h4><mat-icon matPrefix><i class="fi-rr-feather"></i></mat-icon> Crear ambiente</h4>
        <p>
            Crear el espacio adecuado en donde puedas sostener una conversación de manera privada y confidencial con tu coachee (agente), 
            en donde no se presenten interrupciones por parte de terceros, ya sea de manera virtual o presencial. 
            Esto es muy importante para que el coachee se pueda enfocar en la conversación y te pueda transmitir lo que necesite sin obstáculos. 
            De no ser posible el cumplir con estas condiciones se recomienda reagendar, 
            dado que hay una alta probabilidad de que la conversación no sea productiva.
        </p>
    </div>
</mat-menu>
<mat-menu #hielo="matMenu" yPosition="below" xPosition="before" class="hielo">
    <div >
        <h4><mat-icon matPrefix><i class="fi-rr-following"></i></mat-icon> Romper el Hielo-Relacionamiento</h4>
        <p>
            Esta apertura le permitirá al coachee estar dispuesto y entrar en confianza contigo, 
            te permitirá conocerl@ más, de manera que puedas comprender mejor sus puntos de vista. 
            Conocer más a tu coachee en su área personal, te permitirá acercarte a el/la de una manera más natural y 
            generar el relacionamiento que requieres, para poder hacer el acompañamiento efectivo. 
            Es muy importante en esta apertura rompe hielo generar confianza, mencionar que la conversación será confidencial, 
            es decir, nada de lo que se mencione en ella será compartido con otras personas.   
            * Se amable
            *Busca puntos en común con tu coachee (hobbies, experiencias, etc) 
        </p>
    </div>
</mat-menu>
<mat-menu #objetivos="matMenu" yPosition="below" xPosition="before" class="objetivos">
    <div >
        <h4><mat-icon matPrefix><i class="fi-rr-life-ring"></i></mat-icon> Definición de objetivos</h4>
        <p>
            En la conversación de coaching, uno de los puntos más importantes es definir un objetivo, 
            esto le permitirá al coachee llevarse un resultado concreto y unas acciones enfocadas a lograr ese objetivo. 
            El objetivo puede estar dentro de su vida laboral o su vida personal, recuerda que las dificultades que pueda estar teniendo en su vida personal, 
            también pueden afectar su vida laboral.             
        </p>
        <p>
            Preguntas recomendadas:<br>
            ¿Qué quieres trabajar en esta conversación?<br>
            ¿De qué quieres hablar en esta conversación?<br>
            Una vez el coachee defina el objetivo, es importante saber cual sería la importancia de eso que quiere lograr, 
            para esto te recomendamos las siguientes preguntas<br>
            ¿Para qué sería importante lograr eso?<br>
            ¿Cuál sería la importancia de lograr eso?<br>
            Por último, indaga sobre lo que se quiere llevar de la sesión, por ejemplo: Unas acciones que le ayuden a mejorar, 
            descubrir algunos tips para lograr lo que quiere, etc. 
        </p>
    </div>
</mat-menu>

<mat-menu #preguntas="matMenu" yPosition="below" xPosition="before" class="preguntas">
    <div >
        <h4><mat-icon matPrefix><i class="fi-rr-interrogation"></i></mat-icon> Preguntas poderosas</h4>
        <p>
            Recuerda que el coaching es una dinámica de acompañamiento, por esta razón las preguntas serán claves 
            para que el coachee encuentre las respuestas que necesita por si mismo. Estas preguntas le ayudarán al coachee 
            a desafiar sus propios juicios y creencias.
        </p>
        <p>
            Es muy importante que hagas preguntas abiertas, es decir, preguntas que inviten al coachee a reflexionar.
        </p>
        <p>
            Evita hacer preguntas conducentes, es decir, preguntas que dirijan al coachee hacia alguna respuesta. 
            Por ejemplo ¿No te parece qué lo que deberías hacer sería.... ? 
        </p>
        <p>
            Evita dar consejos ocultos en las preguntas, por ejemplo ¿Lo que yo haría sería....?             
        </p>
        <p>Es importante que confíes en que el coachee es capaz de encontrar las respuestas por si mism@, en esto radica el poder del coaching!</p>
    </div>
</mat-menu>

<mat-menu #escucha="matMenu" yPosition="below" xPosition="before" class="escucha">
    <div >
        <h4><mat-icon matPrefix><i class="fi-rr-feather"></i></mat-icon> Escucha activa</h4>
        <p>
            Es importante que escuches de manera activa a tu coachee, para esto deberás estar absolutamente presente y enfocad@ 
            en la conversación de coaching, debes escuchar muy bien lo siguiente:                                                
        </p>
        <ul>
            <li>Sus palabras</li>
            <li>Sus juicios y creencias</li>
            <li>Su gestos y posición corporal</li>
            <li>Las emociones que expresa tanto en sus palabras como en su corporalidad</li>
        </ul>
        <p><strong>Nota:</strong> Si la conversación es virtual, la cámara deberá estar encendida para mejorar la comunicación.</p>
    </div>
</mat-menu>

<mat-menu #responsabilidad="matMenu" yPosition="below" xPosition="before" class="responsabilidad">
    <div >
        <h4><mat-icon matPrefix><i class="fi-rr-thumbs-up"></i></mat-icon> Responsabilidad</h4>
        <p>
            Si bien es cierto que existen factores externos que afectan de forma directa los indicadores y la gestión de coachee, 
            debe reconocer que la mejora de su realidad, es resposabilidad de si mismo. Hacer consciente al asesor sobre sus oportunidades 
            de mejora y los momentos en donde no ha realizado adecuadamente su gestión, le permitirá entender que sus resultados
            actuales dependen de su desempeño. 
        </p>
    </div>
</mat-menu>

<mat-menu #acciones="matMenu" yPosition="below" xPosition="before" class="acciones">
    <div >
        <h4><mat-icon matPrefix><i class="fi-rr-list-check"></i></mat-icon> Diseño de acciones</h4>
        <p>
            Recuerda que uno de los objetivos de la conversación de coaching, es que el coachee obtenga resultados 
            que mejoren tanto su gestión, como su calidad de vida. Por esto es importante que el resultado de la sesión 
            sean acciones concretas, acciones que el coachee se va a comprometer a realizar y a las cuáles podremos 
            hacerles seguimiento y ver su evolución. 
            Recomendaciones:
        </p>
        <ul>
            <li>Acciones deben ser específicas</li>
            <li>Tener plazos claros</li>
            <li>Identificar un plan alternativo por si la primera opción no da resultados</li>
        </ul>
    </div>
</mat-menu>