import { Component, AfterViewInit, ViewChild, Inject, ElementRef, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CandidateCreateComponent } from '../candidate-create/candidate-create.component';
import { CandidatesService } from '../../../services/candidates.service';
import { CandidateCommentsComponent } from '../candidate-comments/candidate-comments.component';
import { CandidateEditComponent } from '../candidate-edit/candidate-edit.component';
import { RequestDetailsDialogComponent } from '../../requests/request-details-dialog/request-details-dialog.component';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ContractCreationComponent } from '../contract-creation/contract-creation.component';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { CandidateAttachmentsComponent } from '../candidate-attachments/candidate-attachments.component';
import { TrainingGroupsService } from '../../../services/training-groups.service';
import { saveAs as importedSaveAs } from "file-saver";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AgreementsType } from '../contract-creation/contractTypes';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { FormControl } from '@angular/forms';
import { I } from '@angular/cdk/keycodes';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';

@Component({
  selector: 'app-candidate-juridico-nomina-doc',
  templateUrl: './candidate-juridico-nomina-doc.component.html',
  styleUrls: ['./candidate-juridico-nomina-doc.component.sass']
})
export class CandidateJuridicoNominaDocComponent implements OnInit {
  displayedColumns: string[] = ['actions', 'scheduled','state', 'name', 'id_number', 'mobilephone', 'email', 'position', 'campaign', 'tracingAvg'];
  displayedColumnsDisabled: string[] = ['actions','state', 'name', 'id_number', 'mobilephone', 'email', 'position', 'campaign', 'tracingAvg'];


  dataSource: MatTableDataSource<any>;
  dataSourceDisabbledUsers: MatTableDataSource<any>;

  length: number;
  pageSize: number;
  lengthDisabled: number;
  pageSizeDisabled: number;
  currentPage = 1;
  currentPageDisabled = 1;
  showCandidates = false;
  selected = new FormControl(0);

  indexTab = 0;
  identification: any = '';
  email: any = '';
  formador: any = '';
  mobile: any = '';
  position: any = '';
  campaign: any = '';
  campaigns:any = [];
  tab: number = 0;
  status: any = [];
  statusTypes: any = [];
  typeFilter:any
  isLeader:boolean = false
  hiring: boolean = false
  nameFile: string=''
  rolUser: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('fileInput', {static: false})
  fileUpload: ElementRef;
  pageSizeOptions: number[] = [10, 50, 100, 150];
  public page : number = 1;
  public per_page : number = 10;
  public pageDisabled : number = 1;
  public per_pageDisabled : number = 10;
  constructor(public dialog: MatDialog, private _candidate:CandidatesService, public AlertsService: AlertsService,
     public router: Router, public auth:AuthService, private serviceGroups: TrainingGroupsService,
     private authService: AuthService,
     private destroyService: DestroyComponentService) {
      this.auth.getUser().roles.forEach(element => {
        if(element == 'rrhh::coordinador-de-contratacion'){
          this.isLeader = true
        }
        if(element == 'rrhh::coordinador-de-contratacion' || element == 'rrhh::analista-de-contratacion'){
          this.hiring = true
        }
      });
  }
  ngOnInit(): void {

    this.getCandidates();
    const local = this.authService.getUser();
    local.roles.forEach((rol) => {
      const name = rol.split('::');
      if (name[0] == 'nomina' && name[1] == 'admin-nomina') {
        this.rolUser = name[1];
      }
    });
  }
  getCandidates(){    
        this._candidate.candidateByUserLoggued({page: this.page, per_page: this.per_page, identification: this.identification, email: this.email,
           mobile: this.mobile, position:this.position, state:this.status, campaign:this.campaign, disable:0}).subscribe( (candidate:any) => {
          if (candidate.total > 0) this.showCandidates = true;
          this.length = candidate.total;
          this.pageSize = candidate.per_page;
          this.dataSource = new MatTableDataSource(candidate.data);
          this.dataSource.sort = this.sort;
        })
        this.getStates();
  }

  getCandidatesDisabled(){
    this._candidate.candidateByUserLoggued({page: this.pageDisabled, per_page: this.per_pageDisabled, identification: this.identification, email: this.email,
       mobile: this.mobile, position:this.position, state:this.status, campaign:this.campaign, disable:1}).subscribe( (candidate:any) => {
      if (candidate.total > 0) this.showCandidates = true;
      this.lengthDisabled = candidate.total;
      this.pageSizeDisabled = candidate.per_page;
      this.dataSourceDisabbledUsers = new MatTableDataSource(candidate.data);
      this.dataSourceDisabbledUsers.sort = this.sort;
    })
}
changeTab(event?)
{
  this.indexTab = event;
  event == 0 ? this.getCandidates() : this.getCandidatesDisabled();
  this.tab = event;  
}


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  newRegister(): any{
    const registerDialog = this.dialog.open( CandidateCreateComponent, {
      minWidth: '900px',
      minHeight: '400px'
    });

    registerDialog.afterClosed().subscribe( (refreshRequests) => {
    });
  }
  filterByValue(event){
    this.pageDisabled = 1
    this.page = 1
    this.paginator.pageIndex = 0;

    this.identification=''
    this.email=''
    this.mobile = ''
    this.position = '';
    this.campaign = '';
    this.status = '';
    this.formador = '';
    switch (this.typeFilter) {
      case "Identificación":
        this.identification = event;
       break;
      case "Email":
        this.email= event
       break;
      case "Teléfono":
        this.mobile = event
        break;
      case "Cargo":
        this.position= event
       break;
      case "Campaña":
        this.campaign = event
        break;
      case "Estado":
        this.status = event
        break;
      case "Formador":
      this.formador = event
      break;
    }
    this.indexTab == 0 ? this.getCandidates() : this.getCandidatesDisabled();
  }

  getPaginatorData(event){
    this.ValidateFilterParams(event);
    this.destroyService.destroyComponent();
    this.getCandidates();
  }

  getPaginatorDataDisabled(event){
    this.ValidateFilterParams(event);
    this.destroyService.destroyComponent();
    this.getCandidatesDisabled();
  }

  getComments(candidateId,requestId){
    const commentDialog = this.dialog.open( CandidateCommentsComponent, {
      data: { candidateId, requestId: requestId }
    });
    commentDialog.afterClosed().subscribe( (params) => {
      this.getCandidates();

    });
  }

  editCandidate(candidateId){
    const candidateEdit = this.dialog.open( CandidateEditComponent, {
      minWidth: '80%',
      data: { 
        candidateId,
        campaignShow: true,
        type: 'edit'
      }
    });
    candidateEdit.afterClosed().subscribe( (params) => {
      this.tab == 0 ? this.getCandidates() : this.getCandidatesDisabled();
    });
  }

  requestDetail(requestId){
    this.router.navigateByUrl(`/mios/rrhh/candidatos-cv-juridico-nomina/${requestId}`)
  }

  cv(candidateId){
    this._candidate.getCandidateDataJson(candidateId, 'personal_data').subscribe( (resp: any) => {
      if(!Object.keys(resp).length)
      {
        this.AlertsService.alertInfo('Completar datos','Por favor completa los datos personales del usuario');
      }else{
        this.router.navigate([`/mios/rrhh/candidato-cv/${candidateId}`]);
      }
    });
  }

  agreementsView(agreements, id, status){
    const contractForm = this.dialog.open( ContractCreationComponent, {
      minWidth: '80%',
      data: {candidateId: id, agreementRules: agreements, action:'view', isLeader:this.isLeader, state: status}
    })
    contractForm.afterClosed().subscribe(res=>{
      if(res){
        this.getCandidates();
      }
    })
  }
  attachmentsView(candidateId){
    const attachmentsDialog = this.dialog.open( CandidateAttachmentsComponent, {
      data: {candidateId: candidateId}
    })
  }
  getCampaigns(){
    if(this.typeFilter == 'Campaña'){
      this.serviceGroups.getDataFilters(`dropdown/options/campana`).subscribe(res=>{
        this.campaigns= res
      })

    }
  }

  /**
       * @author Carlos Nieto
       * @createdate 2022-07-31
       * funcion que restablece las credenciales de self por defecto y renvia el email al candidato
       */
  resentEmail(candidateId,candidateEmail)
  {
    this.AlertsService.alertConfirm(
      '¿Estás seguro?',
      'Esta solicitud restablecerá la contraseña por defecto a Cos2022* y reenviará el correo al candidato con las credenciales de acceso'
      ).then((result) => {
      if (result.isConfirmed) {
        this._candidate.resentCredentialsByEmail({id:candidateId,email:candidateEmail}).subscribe(
          response =>{
            this.AlertsService.alertSuccess(
              'Email enviado',
              `Hemos restablecido la contraseña del candidato por defecto y reenviado el correo con las credenciales de acceso. Usuario: ${response['user_name']}`)
          }
        )
      }
    });
  }

  /**
	   * valida el paginado
	   * @author Carlos Nieto
	   * @param event
	   * @returns string
	   /**/
	public ValidateFilterParams(event)
	{
    if(this.tab == 0)
    {
      this.page =  event.pageIndex + 1,
      this.per_page = event.pageSize
    }else{
      this.pageDisabled =  event.pageIndex + 1,
      this.per_pageDisabled = event.pageSize
    }
      
	}
  getStates(){
      this._candidate.getStates().subscribe(res=>{
        this.statusTypes= res
      })
    }
}

