<button mat-icon-button mat-dialog-close style="float: right;"> <i class="fi-rr-cross"></i> </button>
<h3 mat-card-title>Cambiar Estado de Exámen Médico</h3>
<br>
<mat-dialog-content>
    <div fxLayout="column">
        <mat-form-field fxFlex="100" appearance="fill">
            <mat-label>Estados</mat-label>
            <mat-select [(ngModel)]="status" name="status">
                <mat-option *ngFor="let row of statusOptions" [value]="row.id">{{row.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="100" appearance="fill">
            <mat-label>Exámen Médico</mat-label>
            <input matInput type="text" [(ngModel)]="attachment" name="attachment" disabled="true">
            <button mat-icon-button  matSuffix (click)="fileInput.click()" *ngIf="!attachment" matTooltip="Cargar Exámen Médico">
              <i class="fi-rr-clip" ></i>
            </button>
            <button mat-icon-button  matSuffix *ngIf="attachment" (click)="downloadAttachment()" matTooltip="Descargar Exámen Médico">
              <i class="fi-rr-cloud-download" ></i>
            </button>

            <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" id="file">

        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-flat-button color="primary" (click)="saveStatus()">Actualizar</button>
</mat-dialog-actions>