import { Component, OnInit, Input } from '@angular/core';
import * as pbi from 'powerbi-client';


@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.sass']
})
export class ReportViewComponent implements OnInit {

  @Input() reportId: string;
  @Input() tokenReport: string;

  constructor() { }

  ngOnInit(){

    console.log(this.reportId);
    console.log(this.tokenReport);

    if (this.tokenReport!==''){

      this.showReport();
    }
  }

  showReport() {
    // Report's Secured Token
    let accessToken = this.tokenReport;

    // Embed URL
    let embedUrl = 'https://app.powerbi.com/reportEmbed?';

    // Report ID
    let embedReportId = this.reportId;

    // Configuration used to describe the what and how to embed.
    // This object is used when calling powerbi.embed.
    // This also includes settings and options such as filters.
    // You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Embed-Configuration-Details.
    let config= {
        type: 'report',
        accessToken: accessToken,
        embedUrl: embedUrl,
        id: embedReportId,
        settings: {
            filterPaneEnabled: true,
            navContentPaneEnabled: true
        }
    };

    // Grab the reference to the div HTML element that will host the report.
    let reportContainer = <HTMLElement>document.getElementById('reportContainer');

    // Embed the report and display it within the div container.
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    let report = powerbi.embed(reportContainer, config);

    // Report.off removes a given event handler if it exists.
    report.off("loaded");

    // Report.on will add an event handler which prints to Log window.
    report.on("loaded", function() {
        console.log("Loaded");
    });
}
}
