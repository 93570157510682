<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<h3 mat-dialog-title>Transferir Usuario</h3>
<mat-card-content>
    <div class="row">
            <mat-form-field appearance="fill" class=" col">
                <mat-label>Solicitudes</mat-label>
                <mat-select #request (selectionChange)="selectRequest(request.value)">
                    <mat-option *ngFor="let row of requests" [value]="row">
                        [{{row.code}}] - {{row.position}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
</mat-card-content>
<mat-dialog-actions align="center">
    <button class="primary" mat-flat-button (click)="transferRequest()">Transferir</button>
</mat-dialog-actions>

<!-- (selectionChange)="selectRequest(request.value)" -->
