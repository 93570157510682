import { Component, OnInit, Inject } from "@angular/core";
import {
  FormGroup,
  ValidatorFn,
  AbstractControl,
  Validators,
  FormControl,
} from "@angular/forms";
import { Observable } from "rxjs";
import { CampaignsService } from "../../../services/rest/campaigns.service";
import { DestroyComponentService } from "../../../../../core/services/utils/destroy-component.service";
import { AlertsService } from "../../../../../shared/alerts/alerts.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Campaings } from "../../class/Campaigns";
import { AuthService } from "../../../../../core/services/rest/auth.service";
import { BusinessService } from 'src/app/modules/nomina/services/rest/business.service';

@Component({
  selector: "app-save-campaigns",
  templateUrl: "./save-campaigns.component.html",
  styleUrls: ["./save-campaigns.component.sass"],
})
export class SaveCampaignsComponent implements OnInit {
  campaignForm: FormGroup;
  filteredOptions: Observable<any[]>;
  applications: any;
  menus: any;
  menusSet: any = [];
  companies: any = [];

  constructor(
    private campaignService: CampaignsService,
    private destroyService: DestroyComponentService,
    private alertsService: AlertsService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<SaveCampaignsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private businessService: BusinessService
  ) {}

  ngOnInit(): void {
    this.formControl();
    this.getCompanies();
  }

  /**
   * @author Fabian Duran
   * @createdate 2023-05-19
   * Metodo que trae las empresas registradas.
   */
  getCompanies(): void {
    const params = { page: '', per_page: '' };
    this.businessService.getAllBusiness(params).subscribe((resp: any) => {
      this.companies = resp;
      this.setEdit();
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * filtra por nombre, se usa para el autocompletable
   * @param value valor a filtrar
   */
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.applications.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   */
  displayFn(id: number): string {
    if (!id) {
      return "";
    }
    let index = this.applications.findIndex((resp) => resp.id === id);
    return this.applications[index].name;
  }

  /**
   * @author Daniel Martinez
   * Obtiene los controles
   */
  get error(): any {
    return this.campaignForm.controls;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo donde se establecen las validaciones del formulario
   */
  formControl(): void {
    this.campaignForm = new FormGroup({
      company: new FormControl("", [Validators.required]),
      name: new FormControl("", [
        Validators.required,
        Validators.maxLength(40),
        Validators.minLength(3),
      ]),
      code: new FormControl("", [
        Validators.required,
        Validators.maxLength(5),
        Validators.minLength(3),
      ]),
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que valida las funciones del autocompetable
   */
  autocompleteObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === "string") {
        return { invalidAutocompleteObject: { value: control.value } };
      }
      return null;
    };
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que setea los valores al momento de editar
   */
  setEdit(): void {
    if (this.data.type === "editar") {
      this.campaignForm.controls.company.setValue(parseInt(this.data.campaign.company_id));
      this.campaignForm.controls.name.setValue(this.data.campaign.name);
      this.campaignForm.controls.code.setValue(this.data.campaign.code);
    }
  }

  /**
   * @author Johan Soler
   * @createdate 2022-05-19
   * Metodo que crea campañas
   * @param rolForm datos que recolecta el formulario de campañas
   */
  saveCampaign(rolForm): void {
    if (this.data.type === "crear") {
      const campaing = new Campaings();
      campaing.id = 0;
      campaing.name = this.campaignForm.value.name.toUpperCase();
      campaing.code = this.campaignForm.value.code;
      campaing.company_id = this.campaignForm.value.company;
      campaing.rrhh_id = this.authService.getUser().rrhh_id;
      campaing.description = "";

      this.campaignService.saveCampaign(campaing).subscribe((resp) => {
        this.alertsService.alertSuccess("Guardado", resp.data);
        this.dialogRef.close("");
      });
    } else if (this.data.type === "editar") {
      this.editCampaign();
    }
  }

  /**
   * @author Johan Soler
   * @createdate 2022-05-19
   * Metodo que edita una campaña
   */
  editCampaign(): void {
    const campaing = new Campaings();
    campaing.id = this.data.campaign.id;
    campaing.name = this.campaignForm.value.name.toUpperCase();
    campaing.code = this.campaignForm.value.code;
    campaing.company_id = this.campaignForm.value.company;
    campaing.rrhh_id = this.data.campaign.rrhh_id;
    campaing.description = "";

    this.campaignService.editCampaign(campaing).subscribe((resp) => {
      this.alertsService.alertSuccess("Editado", resp.data);
      this.dialogRef.close("");
    });
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }
}
