<div *ngIf="!data.isRequiredSignature" class="d-flex justify-content-end">
  <div style="color: #353535;">
    <button mat-dialog-close mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="mb-3" style="font-weight: 700; font-size: 28px;">Firma de documento</div>
<mat-divider></mat-divider>
<br />
<pdf-viewer *ngIf="pdf; else notPdf" [src]="pdf" [render-text]="true">
</pdf-viewer>
<ng-template #notPdf>
  <div class="h-50 d-flex justify-content-center align-items-center">
    <span class="fs-3 fw-bold">PDF no cargado</span>
  </div>
</ng-template>
<div #sigContainer class="w-100 mt-3 border border-2 rounded bg-white">
  <signature-pad [options]="config"></signature-pad>
</div>
<div class="row justify-content-end mt-3">
  <div class="col-md-4">
    <button type="button" mat-raised-button class="w-100"
      (click)="signatureElement.clear()"
      style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Limpiar
      firma</button>
  </div>
  <div class="col-md-4">
    <button (click)="saveSignatureDocument()" [disabled]="!pdf ? true : false" type="button" mat-raised-button color="primary" class="w-100"
      style="height: 48px; font-weight: 600;">Guardar</button>
  </div>
</div>