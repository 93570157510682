import { Component, OnInit } from '@angular/core';
import {TemplatesService} from '../../../services/templates.service';

@Component({
  selector: 'app-template-index',
  templateUrl: './template-index.component.html',
  styleUrls: ['./template-index.component.sass']
})
export class TemplateIndexComponent implements OnInit {

  constructor(
    private templateService: TemplatesService,
  ) { }

  ngOnInit(): void {
  }

  onTabChange(): void {
    this.templateService.triggerRefresh();
  }
}
