<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross"></i>
</button>


<h3>Registrar candidato</h3>
<hr>
<mat-dialog-content >
  <form [formGroup]="candidateForm">
      <div class="form">
          <div class="row">
              <div class="col">
                  <mat-form-field appearance="fill">
                      <mat-label>Tipo de documento</mat-label>
                      <mat-select tipoDocumento formControlName="id_type_id" (selectionChange)="getCandidateById()">
                          <mat-option *ngFor="let idType of idTypes" [value]="idType.id">
                              {{idType.name}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col">
                  <mat-form-field appearance="fill">
                      <mat-label>Número de documento</mat-label>
                      <input matInput type="number" formControlName="id_number" (keyup.enter)="getCandidateById()">
                      <button mat-icon-button matSuffix *ngIf="!cleanCandidate"> <mat-icon  (click)="getCandidateById()" >search</mat-icon></button>
                      <button mat-icon-button matSuffix *ngIf="cleanCandidate"> <mat-icon matSuffix (click)="cleanCandidateFrom()" >close</mat-icon></button>
                  </mat-form-field>
              </div>
          </div>
          <!-- se comenta desarrollo por solicitud de Pablo -->
          <!-- <div class="row" *ngIf="isNewUser">
              <div class="col">
                  <mat-form-field appearance="fill">
                      <mat-label>¿Es referido?</mat-label>
                      <mat-select [formControl]="selected" (selectionChange)="isReferred($event.value)">
                          <mat-option value="true">Si</mat-option>
                          <mat-option value="false">No</mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col">
                  <mat-form-field appearance="fill" *ngIf="isReferredValue == true">
                      <mat-label>Número de documento de quien referencia</mat-label>
                      <input matInput type="number" formControlName="referred_document">
                      <button mat-icon-button matSuffix> <mat-icon  (click)="getReferredByDocument()">search</mat-icon></button>
                  </mat-form-field>
              </div>
          </div> -->
          <div class="row" *ngIf="showReferendInput">
              <div class="col-6">
                  <mat-form-field appearance="fill">
                      <mat-label>Seleccione a quien referencia</mat-label>
                      <mat-select tipoDocumento formControlName="referred_id" (selectionChange)="showInfoAlert($event.value)">
                          <mat-option *ngFor="let referend of referendList" [value]="referend">
                              {{referend.Primer_Nombre}} {{referend.Segundo_Nombre}} {{referend.Primer_Apellido}} {{referend.Segundo_Apellido}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
          </div>

          <div class="row">
              <div class="col">
                  <mat-form-field appearance="fill">
                      <mat-label>Primer nombre</mat-label>
                      <input matInput formControlName="first_name">

                  </mat-form-field>
              </div>
              <div class="col">
                  <mat-form-field appearance="fill">
                      <mat-label>Segundo nombre</mat-label>
                      <input matInput formControlName="middle_name">
                  </mat-form-field>
              </div>
          </div>

          <div class="row">
              <div class="col">
                  <mat-form-field appearance="fill">
                      <mat-label>Primer Apellido</mat-label>
                      <input matInput formControlName="last_name">

                  </mat-form-field>
              </div>
              <div class="col">
                  <mat-form-field appearance="fill">
                      <mat-label>Segundo apellido</mat-label>
                      <input matInput formControlName="second_last_name">
                  </mat-form-field>
              </div>
          </div>

          <div class="row">
              <div class="col">
                  <mat-form-field appearance="fill">
                      <mat-label>Teléfono celular</mat-label>
                      <input matInput type="number" formControlName="mobile_phone">

                  </mat-form-field>
              </div>
              <div class="col">
                  <mat-form-field appearance="fill">
                      <mat-label>Teléfono fijo</mat-label>
                      <input matInput type="number" formControlName="phone">
                  </mat-form-field>
              </div>
          </div>

          <div class="row">
              <div class="col-6">
                  <mat-form-field appearance="fill">
                      <mat-label>Email</mat-label>
                      <input matInput type="email" formControlName="email">

                  </mat-form-field>
              </div>
              <!-- <div class="col">
                  <mat-form-field appearance="fill">
                      <mat-label>Email opcional</mat-label>
                      <input matInput type="email" formControlName="email_2">
                  </mat-form-field>
              </div> -->
          </div>
      </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="buttonRegister">
  <button mat-stroked-button  type="button" mat-dialog-close>Cancelar</button>
  <button mat-flat-button color="primary" (click)="candidateRegister()" [disabled]="candidateForm.invalid">{{buttonTextDynamic}}</button>
</mat-dialog-actions>
