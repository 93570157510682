<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<h3 >{{data.action}} Recurso </h3>
<mat-divider></mat-divider>
<mat-dialog-content >
                <form [formGroup]="resourceForm" class="form">
                        <mat-form-field *ngIf="data.action === 'Crear'" appearance="fill" fxFlex>
                            <mat-label>Nombre</mat-label>
                            <input matInput formControlName="name" required>
                            <mat-error *ngIf="resourceForm.get('name').hasError('required')">Este campo es requerido</mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="data.action === 'Crear'" appearance="fill" fxFlex>
                            <mat-label>Tipo de recurso</mat-label>
                            <mat-select formControlName="type_resource_id" required>
                                <mat-option [value]='null'>--</mat-option>
                                <mat-option *ngFor="let type of resourceTypes" [value]="type.id">
                                    {{type.name}}</mat-option>
                            </mat-select>  
                            <mat-error *ngIf="resourceForm.get('type_resource_id').hasError('required')">Este campo es requerido</mat-error> 
                        </mat-form-field>

                        <ng-container *ngIf="data.action === 'Editar'">
                            <mat-form-field appearance="fill" fxFlex>
                                <mat-label>Estado</mat-label>
                                <mat-select formControlName="status" required>
                                    <mat-option [value]="1">
                                        Activo
                                    </mat-option>
                                    <mat-option [value]="2">
                                        Inactivo
                                    </mat-option>
                                </mat-select>  
                            </mat-form-field>
                        </ng-container>
                   
                </form> 
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-stroked-button type="button" mat-dialog-close>Cancelar</button>
    <button mat-flat-button color="primary" (click)="saveResource()">Guardar</button>
</mat-dialog-actions>
