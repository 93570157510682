import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { CampaignInterface } from '../interfaces/CampaignInterface';
import { PositionInterface } from '../interfaces/PositionInterface';


const RRHH_URL = environment.RRHH_URL;

@Injectable({
  providedIn: 'root'
})
export class RrhhService {

  headersFile;
  headers;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.headersFile = {
      responseType: "blob"
    };
  }



  /**
   * Consulta las campañas activas en recursos humanos
   * @returns
   */
   getPositions(): Observable<PositionInterface[]> {
    return this.http.get(`${RRHH_URL}positions/`)
      .pipe( map( (dashes:PositionInterface[]) => dashes ));
  }

}
