import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { CandidatesService } from '../../../services/candidates.service';

@Component({
  selector: 'app-check-attachment',
  templateUrl: './check-attachment.component.html',
  styleUrls: ['./check-attachment.component.sass']
})
export class CheckAttachmentComponent implements OnInit {

  attachmentId;
  selectedState = '';
  comment = '';
  selectedTypification = '';


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialogRef<CheckAttachmentComponent>,
              private alert: AlertsService,
              private candidateService: CandidatesService) {
                this.attachmentId = data.attachmentId;
               }

  ngOnInit(): void {
  }

  selectState(state: string) {
    this.selectedState = state;
  }

  register () {
    this.alert.alertConfirm('¿Está seguro de asignar este estado?').then(e => {
      if (e.isConfirmed){
        const createdAt = new Date();
        this.candidateService.validateDocumentCandidate(this.attachmentId, this.comment, createdAt, this.selectedState, this.selectedTypification, this.data.key).subscribe( resp => {
          this.alert.alertSuccess('¡Hecho!', 'Registro creado con éxito');
          let sing = this.data?.key.includes('firma_');
          if(this.selectedState == 'Aprobado' && sing)
          {
            this.alert.alertWarning('Importante','No olvides generar nuevamente el documento para asignar las firmas');
          }
          this.dialog.close(true);
        });
      }
    });
  }
  selectTypification(typification: string){
    this.selectedTypification = typification;
  }
}
