import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/rest/auth.service';

@Component({
  selector: 'app-coaching',
  templateUrl: './coaching-app.component.html',
  styleUrls: ['./coaching-app.component.sass']
})
export class CoachingAppComponent implements OnInit {
  public userName : any;
  constructor(
    public dialog: MatDialog, 
    private authService : AuthService
    ) { }

  ngOnInit(): void {
    this.userName = this.authService.decryptToken();
  }

  onBoardingCoach(){

  }

}
