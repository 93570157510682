import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


const calidad_url = environment.CALIDAD_URL;

@Injectable({
  providedIn: 'root'
})
export class ControlService {
  private _refresh$ = new Subject<void>();

  constructor(
    private http:HttpClient,
  ) { }

  get refresh$(){
    return this._refresh$;
  }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  getSupervisores(){
    return this.http.get(`${calidad_url}getSupervisores`)
    .pipe(
      catchError(this.handleError)
      );
  }

  createGroup(data){
    return this.http.post<any>(`${calidad_url}crearGrupo`,data)
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

  getGroups(params){
    return this.http.get(`${calidad_url}getGrupos`,{params})
    .pipe(
      catchError(this.handleError)
      );
  }

  getGrupoById(id){
    return this.http.get(`${calidad_url}getGrupoId/${id}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  updateGroupById(data){
    return this.http.post<any>(`${calidad_url}updateGrupo`,data)
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

  deleteGrupoById(id){
    return this.http.post<any>(`${calidad_url}deleteGrupo/${id}`,{})
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

  getAsesores(params){
    return this.http.get(`${calidad_url}showAsesores`,{params})
    .pipe(
      catchError(this.handleError)
      );
  }

  getConsecutivo(data){
    return this.http.post<any>(`${calidad_url}getConsecutivo`,data)
    .pipe(
      catchError(this.handleError)
    );
  }

  addAsesor(data){
    return this.http.post<any>(`${calidad_url}asignarAsesor`,data)
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

  getAsesoresGrupo(params){
    return this.http.post<any>(`${calidad_url}getAsesoresGrupo`,{},{params})
    .pipe(
      catchError(this.handleError)
    );
  }

  deleteAsesorGrupo(data){
    return this.http.post<any>(`${calidad_url}deleteAsesorGrupo`,data)
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

}
