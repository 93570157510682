<div class="admin-solicitudes">

    <div class="header col-4" >
        <h1>Solicitudes<i class="fas fa-container-storage    "></i></h1>
        <p>
            Aquí puedes revisar las solicitudes que haz hecho
            las diferentes dependencias y su estado
        </p>


    </div>

    <div class="row col-12 filter mx-0" style="padding-top: 40px;">

        <mat-form-field class="col-3 ps-2" appearance="fill">
          <mat-label>Fecha de creación</mat-label>
          <input matInput
            autocomplete    = "off"
            readonly        = "true"
            [matDatepicker] = "picker"
            (dateChange)    = "filterBydate($event)"
            >
          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon><span class="fi fi-rr-calendar"></span></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="col-3" appearance="fill">
            <mat-label>Estado</mat-label>
            <mat-select (selectionChange)="filterByState($event.value)" multiple>
                <mat-option *ngFor="let state of requestStates" [value]="state.id">{{state.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="col-3"></div>

        <div class="col-3 buttons pe-2">
            <button mat-raised-button color="primary" class="w-100 bold" (click)="newSolicitud()" [disabled]="state_block_btnCreate">Crear nueva solicitud &nbsp;<i class="fi-rr-add" ></i></button>
        </div>

    </div>


    <div class="table-container row">

        <div class="col-md-4 col-lg-3 mt-3 mb-3"   *ngFor="let solicitud of solicitudes">
            <mat-card class="solicitud-card    mat-elevation-z8" >
                <mat-card-header style="display: block;" >

                  <div class="badge " >
                    <button  mat-icon-button  [style.backgroundColor]= "solicitud.request_state.color" class="shadowed">
                      <i [class]=" solicitud.request_state.icon" > </i>
                  </button>
                  </div>
                  <div class="header" >
                    <mat-card-subtitle> {{solicitud.request_state.name}} </mat-card-subtitle>
                    <mat-card-title class="text-truncate">
                      {{solicitud.title}}
                      <small *ngIf="solicitud.title.length > 30">...</small>

                    </mat-card-title>

                  </div>
                  <p>
                      {{solicitud.description | slice:0:80}}
                      <small *ngIf="solicitud.description.length > 80">...</small>
                  </p>



                </mat-card-header>
                <mat-card-content >
                    <div>
                      <hr>
                      <mat-card-subtitle><i class="fi-rr-user" ></i> {{solicitud.created_by}} </mat-card-subtitle>
                      <mat-card-subtitle><i class="fi-rr-calendar" ></i>  {{solicitud.created_at|date:'yyyy-MM-dd': 'UTC'}} </mat-card-subtitle>
                      <hr>
                      <button mat-button (click)="viewSolicitud(solicitud)">Detalle solicitud  <i class="fi-rr-arrow-right" ></i></button>
                    </div>
                </mat-card-content>

            </mat-card>
        </div>
        <mat-paginator class="mt-5" [length]="length"
                        [pageSize]="pageSize"
                        (page)="pageEvent($event)"
                        [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>



    </div>

</div>
