import { Component, Inject, OnInit, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatrizService } from 'src/app/modules/calidad/services/matriz.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
  selector: 'app-crear-matriz',
  templateUrl: './crear-matriz.component.html',
  styleUrls: ['./crear-matriz.component.sass']
})
export class CrearMatrizComponent implements OnInit {
  private uuid = '807f3cdc-906b-11ee-b9d1-0242ac120002';
  public config: any;

  matrixForm:FormGroup;
  tiposMatrices = [];
  inteligencias = [];
  empresas = [];
  rangos = [];
  tipos_peso = [];
  campaigns = [];
  filteredOptionsCampaign: Observable<any[]>;
  constructor(
    private fb:FormBuilder,
    public matrizRef:MatDialogRef<CrearMatrizComponent>,
    @Inject(MAT_DIALOG_DATA) public datos:any,
    private matrizService:MatrizService,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.getSelects();
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
  }

  createForm(){
    this.matrixForm = this.fb.group({
      company_id: ['',[Validators.required]],
      nombre: ['',[Validators.required]],
      tipo_matriz_id: ['',Validators.required],
      rango_id: ['',Validators.required],
      tipo_peso_id: ['',Validators.required],
      campania_asociada: ['',Validators.required],
      crm_id: ['',Validators.required],
      aplicar_inteligenciaNegocio: ['',Validators.required],
      inteligenciaNegocio_id: ['',Validators.required],
      aplica_para_cp: [0, Validators.required],
      aplica_comentario:[0,Validators.required],
      comentario_obligatorio_para:['', Validators.required],
      tamano_comentario:['', Validators.required]
    })
  }

  save(){
    if (this.matrixForm.invalid) {
      return
    }
    this.matrixForm.addControl('grupo',new FormControl(1))
    this.matrizService.createMatrix(this.matrixForm.value).subscribe((resp:any)=>{
      if (resp.respuesta.codigo === 202) {
        Tools.swalSuccess(this.config.modal.excellent, this.config.modal.saveOk)
        this.matrizRef.close(resp.respuesta.codigo);
      }else if (resp.respuesta.codigo > 400) {
        Tools.swalError('¡Oops!', this.config.modal.saveError);
      }
    });
  }

  changeSelect(){
    if (this.matrixForm.get('tipo_matriz_id').value == 1) {
      this.matrixForm.get('rango_id').disable();
      this.matrixForm.get('tipo_peso_id').disable();
    }else{
      this.matrixForm.get('rango_id').enable();
      this.matrixForm.get('tipo_peso_id').enable();
    }

    if (this.matrixForm.get('campania_asociada').value == 1) {
      this.matrixForm.get('crm_id').enable();
    }else{
      this.matrixForm.get('crm_id').disable();
    }

    if (this.matrixForm.get('aplicar_inteligenciaNegocio').value == 0) {
      this.matrixForm.get('inteligenciaNegocio_id').disable();
    }else{
      this.matrixForm.get('inteligenciaNegocio_id').enable();
    }
    if (this.matrixForm.get('aplica_comentario').value == 0) {
      this.matrixForm.get('comentario_obligatorio_para').disable();
      this.matrixForm.get('tamano_comentario').disable();
    }else{
      this.matrixForm.get('comentario_obligatorio_para').enable();
      this.matrixForm.get('tamano_comentario').enable();
    }
  }

  getSelects(){

    this.matrizService.getComapanies().subscribe((resp:any)=>{
      this.empresas = resp
    });

    this.matrizService.getTypeMatrix().subscribe((resp:any)=>{
      this.tiposMatrices = resp
    });

    this.matrizService.getIntelligence().subscribe((resp:any)=>{
      this.inteligencias = resp;
    });

    this.matrizService.getRange().subscribe((resp:any)=>{
      this.rangos = resp;
    })

    this.matrizService.getWeight().subscribe((resp:any)=>{
      resp.forEach(tipo => {
        if (tipo.nombre === "Peso en Items") {
          tipo.nombre = 'Peso en Ítems';
        }
      });
      this.tipos_peso = resp;
    })

    this.matrizService.getCampaings().subscribe((resp:any)=>{
      this.campaigns = resp.data;
    });

  }
}
