<form class="form" [formGroup]="formGroup">
    <mat-dialog-content class="mat-typography">
        <div class="modal-main">
            <i class="fi-rr-cross-small" style="cursor: pointer; float: right; font-size: 32px" mat-icon-button
                mat-dialog-close></i>
            <h2 mat-dialog-title>
                <div style="width: 100%; margin-top: 10px;">
                    <h3 style="font-size: 28px !important; color: #353535;">{{data.title}}</h3>
                </div>
            </h2>

            <div style="padding-right: 3.8rem; padding-top: 1rem;">

            </div>


            <div class="row">
                <div class="col-4">
                    <mat-form-field appearance="fill" style="width: 100%; ">
                        <mat-label>Campañas</mat-label>
                        <mat-select formControlName="campaigns" #listCampaigns [multiple]="true"
                            (openedChange)="cargarAgenetes($event)">
                            <mat-option *ngFor="let itemCampana of dataSelectMultipleCampaigns" [value]="itemCampana">
                                {{itemCampana.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-4">
                    <mat-form-field appearance="fill" style="width: 100%;" class="me-3">
                        <mat-label>Documento del jefe</mat-label>
                        <input (keypress)="soloNumeros($event)" (blur)="getJefeInmediato()" formControlName="doc_jefexx" type="text" matInput>
                        <mat-icon matSuffix><i class="fi-rr-search" (click)="getJefeInmediato()"></i></mat-icon>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <br *ngIf="data.type !== 'editar' ">
                    <span class="mt-3"></span>
                    <span *ngIf="jefeInmediato.name"> <b>Nombre del jefe :</b> {{ jefeInmediato.name }} </span> <br>
                    <span *ngIf="data.type == 'editar' && jefeInmediato.campaings "> <b>Campañas:</b> &nbsp;
                        {{ jefeInmediato.no_campaign }}
                        <span #tooltip="matTooltip" matTooltip="{{  jefeInmediato.campaings | arrayToString:true }}"
                            matTooltipPosition="right" matTooltipHideDelay="600">
                            <i class="fi fi-rr-interrogation">
                            </i>
                        </span>
                    </span>

                </div>
                <!--Fin del row-->
            </div>
            <!--Fin del row-->


            <div class="row mb-3">
                <div class="col-5">
                    <div class="row">
                        <div class="col-6">
                            <span class="span-agentes">Agentes disponibles</span>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-4 checkbox-all">
                            <mat-checkbox (change)="selectAll($event,'disponibles')"
                                [checked]="asesoresDisponibles.selectedOptions.selected.length == dataAgentesDiponibles.length &&  dataAgentesDiponibles.length > 0 ">
                                Todos
                            </mat-checkbox>
                        </div>

                    </div>
                </div>
                <div class="col-2"></div>
                <div class="col-5">
                    <div class="row">
                        <div class="col-6">
                            <span class="span-agentes">Agentes asignados</span>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-4 checkbox-all">
                            <mat-checkbox (change)="selectAll($event, 'asignados')"
                                [checked]="asesoresAsignados.selectedOptions.selected.length == dataAgentesAsignados.length &&  dataAgentesAsignados.length > 0 ">
                                Todos
                            </mat-checkbox>
                        </div>

                    </div>
                </div>
                <!--Fin del row-->
            </div>
            <!--Fin del row-->
            <div class="row">
                <div class="col-5 ">
                    <div class="contenedor-lista-agentes">
                        <mat-selection-list formControlName="listagentesDisponibles" #asesoresDisponibles>

                            <mat-list-option *ngFor="let asesor of dataAgentesDiponibles" [value]="asesor">
                                <span style=" font-size: 13px !important; margin-top: 10px; margin-bottom:10px;">
                                    <b>Nombre:</b>{{asesor.name | maxText:23:true }} <br>
                                    <span class="text-gray">Doc: {{ asesor.doc | maxText:12}} | Campaña: {{asesor.name_campaign |
                                        maxText:7:true }} </span>
                                        <span #tooltip="matTooltip"
                                        matTooltip="{{ asesor.name_campaign }}"
                                        matTooltipPosition="right" 
                                        matTooltipHideDelay="700">
                                        <i class="fi fi-rr-interrogation">
                                        </i>
                                    </span>
                                </span>

                            </mat-list-option>

                        </mat-selection-list>
                    </div>

                </div>
                <div class="col-2">
                    <div class="row mt-5">
                        <div class="col-12">
                            <button class="button-pass" mat-raised-button color="primary" (click)="moveAgente('add')">
                                <span class="fi-rr-arrow-right"></span>
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="button-pass" mat-raised-button color="primary"
                                (click)="moveAgente('remove')">
                                <span class="fi-rr-arrow-left"></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-5 ">
                    <div class="contenedor-lista-agentes">
                        <mat-selection-list formControlName="listaAgentesAsignados" #asesoresAsignados>
                            <mat-list-option    *ngFor="let asesorG of dataAgentesAsignados"
                                [value]="asesorG">
                                <span style=" font-size: 13px !important; margin-top: 10px; margin-bottom:10px;">
                                    <b>Nombre:</b>{{asesorG.name | maxText:23:true }} <br>
                                    <span class="text-gray">Doc: {{ asesorG.doc  | maxText:12 }} | Campaña: {{asesorG.name_campaign |
                                        maxText:7:true }}
                                    </span>
                                    <span #tooltip="matTooltip"
                                        matTooltip="{{ asesorG.name_campaign }}"
                                        matTooltipPosition="right" 
                                        matTooltipHideDelay="700">
                                        <i class="fi fi-rr-interrogation"></i>
                                    </span>
                                </span>
                            </mat-list-option>
                        </mat-selection-list>
                    </div>

                </div>
            </div>

            <div class="row mt-3">
                <div class="col-5">
                    <div class="row">
                        <div class="col-8">
                            <span class="span-agentes">Opciones seleccionadas: {{
                                asesoresDisponibles.selectedOptions.selected.length }} </span>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-2 "></div>

                    </div>
                </div>
                <div class="col-2"></div>
                <div class="col-5">
                    <div class="row">
                        <div class="col-8">
                            <span class="span-agentes">Opciones seleccionadas: {{
                                asesoresAsignados.selectedOptions.selected.length }} </span>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-2 "> </div>

                    </div>
                </div>
                <!--Fin del row-->
            </div>
            <!--Fin del row-->

            <div class="row mt-4">
                <h4 mat-dialog-title style="font-weight: 700 !important;">Añadir agente</h4>
                <div class="col-4">
                    <mat-form-field appearance="fill" style="width: 100%;" class="me-3">
                        <mat-label>Documento</mat-label>
                        <input (keypress)="soloNumeros($event)" type="text" formControlName="doc_newage" matInput>
                        <mat-icon class="cursor-pointer" matSuffix (click)="getAsesor()"><i class="fi-rr-search"></i>
                        </mat-icon>


                    </mat-form-field>
                </div>
                <div class="col-6">
                    <div *ngIf="addAgente" class="row">
                        <div class="col-1">
                            <button mat-icon-button (click)="agregarAgenteNuevo()" style="margin-top:15px ;">
                                <span class="fi-rr-add" style="font-size: 24px !important; color: #BD77CE;"></span>
                            </button>
                        </div>
                        <div class="col-11 ml-3">
                            <p class="position-sec">
                                <b>Nombre: </b> {{ addAgente.name }} <br>
                                <b>Jefe inmediato: </b> {{ addAgente.name_boss }} <br>
                                <b>Campaña: </b> {{ addAgente.name_campaign }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-2"></div>
                <!--Fin del row-->
            </div>
            <!--Fin del row-->

        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end" style="padding: 8px 8px 25px 8px !important; margin-right: 40px !important">
        <button class="button-position" mat-stroked-button mat-button mat-dialog-close>Cancelar</button>
        <button class="button-position" [disabled]="formGroup.invalid" (click)="createUpdate()" mat-raised-button
            color="primary">Asignar </button>
    </mat-dialog-actions>
</form>