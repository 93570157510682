<div class="px-5 py-2">
  <button style="float: right" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross-small"></i>
  </button>

<div class="row mt-4">
  <div class="col-12 px-0">
    <h3>Descargar gestión</h3>
  </div>
  <div class="col-12 px-0">
    <span>En este espacio podrás descargar reportes filtrando por rango de fechas y estado de las solicitudes.</span>
  </div>
</div>
  <hr>


  <form [formGroup]="formDescarga" (ngSubmit)="descargar()">




    <div class="row mt-3">
      <div class="col-4">
        <mat-form-field appearance="fill" class="w-100" style="padding-right: 10px;">
          <mat-label>Fecha inicio *</mat-label>
          <input matInput [matDatepicker]="picker2" formControlName="fecha_ini" [max]="formDescarga.value.fecha_fin"
            readonly>
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <mat-error>Campo obligatorio</mat-error>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Fecha fin</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="fecha_fin" [min]="formDescarga.value.fecha_ini"
            readonly>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error>Campo obligatorio</mat-error>
        </mat-form-field>
      </div>
      <div class="col-4 d-flex align-items-end">
        <mat-form-field appearance="fill" class="w-100" *ngIf="states">
          <mat-label>Estado</mat-label>
          <mat-select formControlName='estado'>
            <mat-option *ngFor="let state of  states " [value]="state.id">
              {{state.name}}
            </mat-option>
          </mat-select>

        </mat-form-field>
      </div>
    </div>
    <div class="row d-flex justify-content-end">

      <div class="col-3">
        <button class="w-100 py-2" mat-stroked-button mat-dialog-close>Cancelar</button>
      </div>
      <div class="col-3">
        <button class="w-100 py-2" mat-raised-button type="submit" color="primary">
          Descargar</button>

      </div>

    </div>

  </form>
</div>
