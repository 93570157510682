import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { Location, DatePipe } from '@angular/common';
import { FormControl, Validators, FormBuilder, FormGroup, FormGroupName} from '@angular/forms';
import { ReportsService } from '../../../services/reports.service';
import { saveAs as importedSaveAs } from "file-saver";
import { FileService } from '../../../services/file.service';
import * as moment from 'moment';

@Component({
  selector: 'app-reports-main',
  templateUrl: './reports-main.component.html',
  styleUrls: ['./reports-main.component.sass']
})
export class ReportsMainComponent implements OnInit {

  maxDate
  today
  filterForm:FormGroup;
  public datePipe = new DatePipe('en-US');

  constructor(
    public _location: Location,
    private fb: FormBuilder,
    public ReportsService:ReportsService,
    public fileService : FileService,
    ) { }

  ngOnInit(): void {

    this.filterForm = new FormGroup ({
      report: new FormControl('',[Validators.required]),
      start_date: new FormControl('', [Validators.required]),
      end_date: new FormControl('',[Validators.required]),
    });
    //Restriccion de maximo 30 dias para evitar sobre carga en el serv
    this.filterForm.controls.start_date.valueChanges.subscribe(
      resp =>{
        this.maxDate =  moment(resp).add(30, 'days').format("YYYY-MM-DD");
      }
    )
  }
  getReports(){
    if(this.filterForm.value.report == 'newHireReport' || //ingreso de personal
     this.filterForm.value.report == 'compensationReport' || //caja de compensacion
     this.filterForm.value.report == 'resumeReport' || //hoja de vida
     this.filterForm.value.report == 'arlReport') //reporte arl
    {
      this.export();
    }else{
      let json = {
        report:this.filterForm.value.report,
        start_date : this.datePipe.transform(this.filterForm.value.start_date, 'yyyy-MM-dd'),
        end_date: this.datePipe.transform(this.filterForm.value.end_date, 'yyyy-MM-dd')
      }

      this.ReportsService.downloadReport(json).subscribe((res:any)=>{
        importedSaveAs(res, `${this.filterForm.value.report}.xlsx`);

      })

    }
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-08-04
   * Metodo consulta y exporta
   */
  export()
  {
    let json = {
      report:this.filterForm.value.report,
      start_date : this.datePipe.transform(this.filterForm.value.start_date, 'yyyy-MM-dd'),
      end_date: this.datePipe.transform(this.filterForm.value.end_date, 'yyyy-MM-dd')
    }

    this.ReportsService.downloadNewReports(json).subscribe(
      resp => {
        let data : any = resp;
        data = <JSON>data;
        this.fileService.exportAsExcelFile(data,this.filterForm.value.report);
      }
    );
  }

}
