<div class="row novelty-row-padding">

  <h2 mat-dialog-title>
    <span class="gray-color" style="margin-left: -9px">{{ novelty?.novelty_date_start }}</span>
    <button style="float: right;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
  </h2>

  <mat-dialog-content style="padding: 3%; overflow-x: hidden" class="mat-typography">
    <div class="row">
      <div class="col-7">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <div class="col-12">
                  <span
                    class="badge" [style.background]="novelty?.noveltyTypeToPrint?.backgroundColor">{{ novelty?.novelty_type?.key }}</span> {{ novelty?.noveltyTypeToPrint?.title }}
                </div>
                <br>
                <div class="col-12" *ngIf="showJustifyInfo">
                  <h4 class="gray-color"><b>Estado</b></h4>
                  <p class="p-color">{{ novelty?.novelty_state?.name }}</p>
                </div>
              </div>
              <div class="col-12" [style]="'overflow-y: scroll; height:' + noveltyDetailHeight + 'px;'" *ngIf="showJustifyInfo">

                <div class="col-12" *ngIf="novelty.responseFirstInstance && novelty.responseFirstInstance.justify">
                  <br>
                  <h4 class="gray-color"><b>Mi Justificación</b></h4>
                  <p class="p-color">{{ novelty.responseFirstInstance.justify }}</p>
                </div>

                <!-- payroll response -->
                <div class="col-12" *ngIf="novelty.responseFirstInstance && novelty.responseFirstInstance.response">
                  <br>
                  <h4 class="gray-color"><b>Respuesta Nómina</b></h4>
                  <p class="p-color">{{ novelty.responseFirstInstance.response }}</p>
                </div>
                <!-- end -->

                <!-- user response -->
                <div class="col-12" *ngIf="novelty.responseSecondInstance && novelty.responseSecondInstance.justify">
                  <br>
                  <h4 class="gray-color"><b>Mi respuesta</b></h4>
                  <p class="p-color">{{ novelty.responseSecondInstance.justify }}</p>
                </div>
                <!-- end -->

                <!-- payroll response -->
                <div class="col-12" *ngIf="novelty.responseSecondInstance && novelty.responseSecondInstance.response">
                  <br>
                  <h4 class="gray-color"><b>Respuesta Nómina</b></h4>
                  <p class="p-color">{{ novelty.responseSecondInstance.response }}</p>
                </div>
                <!-- end -->
              </div>
            </div>
          </div>

          <div class="col-12">
            <!-- Without justify -->
            <div class="col-12" *ngIf="showJustifyForm && showInput">
              <h4 class="gray-color"><b>Sin Justificación</b></h4>
              <mat-form-field appearance="fill" class="w100">
                <mat-label>Justificación</mat-label>
                <textarea matInput [(ngModel)]="justify" cols="3" rows="5"></textarea>
              </mat-form-field>
            </div>
            <div class="col-12" *ngIf="showJustifyForm && showInput && noveltyTypeKey != compensatorioKey">
              <mat-form-field appearance="fill" class="col-12">
                <mat-label> Adjuntar soportes </mat-label>
                <ngx-mat-file-input [formControl]="fileControl" multiple [accept]="'.pdf, .png, .jpeg'" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; height: 13px;" color="primary"></ngx-mat-file-input>
                <i class="fi-rr-upload" style="font-size: 16px" matSuffix></i>
                <mat-error *ngIf="fileControl.hasError('maxContentSize')">
                  ¡Advertencia! Los archivos superan los 10 MB.
                </mat-error>
              </mat-form-field>
              <p style="display: block; font-size: 10.5px !important; color: #c62828; font-family: 'Poppins', sans-serif !important;" [hidden]="maxFiles">
                ¡Advertencia! Solo se permite dos archivos máximo.
              </p>

            </div>
            <!-- end -->

            <div class="col-12" *ngIf="showJustifyForm && !showInput">
              <h4 class="gray-color"><b>Sin Justificación</b></h4>
              <button *ngIf="this.novelty.novelty_type.key != 39 && this.novelty.novelty_type.key != 0" mat-raised-button
                      class="w100"
                      type="button" color="primary"
                      (click)="showInput = !(showInput)">Justificar novedad
              </button>
            </div>

            <div class="col-12" *ngIf="showJustifyForm && showInput">
              <button mat-raised-button
                      class="w100"
                      type="button" color="primary"
                      [disabled]="!justify || sending"
                      (click)="saveJustifyForEmployee()">Enviar justificación
              </button>
            </div>
          </div>
        </div>

      </div>


      <div class="col-5 text-center">
        <img src="./assets/images/novelties-1.svg" alt="noveltyIcon">
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="row novelty-footer-hours">
      <div class="col-4">
        <b>Inicio turno</b>
        <p class="gray-color"><b>{{ scheduleTime }}</b></p>
      </div>
      <div class="col-4">
        <b>Fin turno</b>
        <p class="gray-color"><b> {{ endTime }}</b></p>
      </div>
      <div class="col-4" style="color: red" *ngIf="novelty?.novelty_type?.key == 39">
        <b>Hora reporte</b>
        <p><b>{{ realTime }}</b></p>
      </div>
    </div>
  </mat-dialog-actions>

</div>
