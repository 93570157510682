import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})

export class FileService {
 
  constructor() {}

   /**
   * @author Carlos Nieto
   * @createdate 2022-08-04
   * Metodo crea y exporta el json a excel
   */
   public exportAsExcelFile(json: any[],fileName): void {       
    let excelFileName = fileName + '_export_' + this.formatDate(new Date());
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-08-04
   * Metodo guarda el excel
   */
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-08-04
   * Metodo forma la fecha actual en string 
   */
   formatDate(date) {
    return [
      this.padTo2Digits(date.getDate()),
      this.padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('_');
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-08-04
   * convierte un numero a string
   */
   padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

}
