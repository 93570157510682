<div class="d-flex align-items-center">
    <h2 class="mt-4 fw-bold" mat-dialog-title>Ver observación</h2>
    <button
        *ngIf="!data.observation.rrhh_observations && !data.observation.date_observations"
        class="mt-2"
        type="button"
        mat-icon-button
        (click)="viewModalEdit()">
        <i class="fi-rr-edit"></i>
    </button>
</div>
<mat-dialog-content>
    <mat-divider></mat-divider>
    <div class="px-4 py-4">
        <p *ngIf="data.observation.rrhh_observations && data.observation.date_observations" style="color: #717171;">Observación por <span class="fw-bold">{{ data.observation.rrhh_observations }}</span> | {{ data.observation.date_observations }}</p>
        <p *ngIf="data.observation.observations; else elseParrafo" class="mt-3" style="text-align: justify;">
            {{ data.observation.observations }}
        </p>
        <ng-template #elseParrafo >
            <p class="mt-3" style="text-align: justify;">
                {{ data.observation }}
            </p>
        </ng-template>
        <div class="row justify-content-end">
            <div class="col-md-3">
                <button 
                    mat-stroked-button 
                    mat-dialog-close 
                    color="primary" 
                    class="w-100 fw-bold" 
                    type="button">
                    Cancelar
                </button>
            </div>
        </div>
    </div>
</mat-dialog-content>