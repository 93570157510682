import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../../../../environments/environment';

// Models
import { DatabaseCard } from '../models/databaseCard.model';
import { DatabaseFile } from '../models/databaseFile.model';
import { EndPointSelector } from '../../../configs/cartera-settings.config';
import { Router } from '@angular/router';

@Injectable({
	providedIn: DatabasesService
})
export class DatabasesService {
	API_URL:string;
	DATABASES:string;
	UPLOAD:string;
	UPLOAD_TYPE:string;
	UPLOAD_FILE:string;
	ERRORS_PAY:string;


	constructor(private http: HttpClient,
		router: Router) {
		this.API_URL = EndPointSelector.getSelector(router);
		this.DATABASES = this.API_URL + 'databases/';
		this.UPLOAD = this.API_URL + 'upload/';
		this.UPLOAD_TYPE = this.API_URL + 'upload_type/';
		this.UPLOAD_FILE = this.API_URL + 'upload_file/';
		this.ERRORS_PAY  = this.API_URL + 'errors-pay/';
	}

	handleError(error: HttpErrorResponse): Observable<any> {
		return throwError(error);
	}

	/**
	 * Método para consultar los diferentes tipos de bases de datos a visualizar.
	 * @param campaign id de la campaña a la cual se le van a consultar los tipos de bases de datos.
	 * @param rolName Nombre del rol del usuario logueado.
	 * @returns arreglo con los tipos de bases de datos.
	 * @author Carlos Gómez
	 * @createdate 2021-07-27
	 */
	getDataBasesList(campaign: number, rolName: string): Observable<DatabaseCard[]> {
		return this.http.get<DatabaseCard[]>(this.UPLOAD_TYPE + `${campaign}/${rolName}`).pipe(catchError(this.handleError));
	}

	/**
	 * Método para obtener la información de una base de datos en particular.
	 * @param id id de la base de datos a consultar
	 * @author Carlos Gómez
	 * @createdate 2021-07-27
	 */
	getDatabaseByID(id: number): Observable<DatabaseCard> {
		return this.http.get<DatabaseCard>(this.DATABASES + id).pipe(catchError(this.handleError));
	}

	/**
	 * Método para obtener la data de una database
	 * @param campaign campaña asociada a la database
	 * @param databaseId id de la database
	 * @param per_page cantidad de registros por página
	 * @param from_page página de la cual se van a traer los registros.
	 * @param startDate filtro de fecha inicial
	 * @param endDate filtro de fecha final
	 * @param key key de la cartera trabajada
	 * @author Carlos Gómez
	 * @createdate 2021-07-27
	 */
	getDataByFilter(databaseId: number, per_page: number, from_page: number, startDate: string, endDate: string, key: string): Observable<any> {
		// http://localhost:8009/api/upload/paginate/{per_page}/{from_page}/{id_upload_type}/{startDate}/{endDate}

		const data = {
			per_page,
			from_page,
			id_upload_type: databaseId,
			startDate,
			endDate,
			cartera: key
		}
		
		return this.http.post<any>(`${this.UPLOAD}paginate`, data).pipe(catchError(this.handleError))

		// return this.http.get<any>(this.UPLOAD + `paginate/${per_page}/${from_page}/${databaseId}/${startDate}/${endDate}`).pipe(catchError(this.handleError));
	}

	/**
	 * Método para descargar la plantilla de datos de una base de datos
	 * @param id id de la base de datos a descargarle su plantilla
	 * @author Carlos Gómez
	 * @createdate 2021-07-27
	 */
	downloadTemplateById(id: number): Observable<any> {
		const httpOptions = {
			responseType: 'blob' as 'json'
		};
		// http://localhost:8009/api/upload_type/plantilla/download/4
		return this.http.get(this.UPLOAD_TYPE + `plantilla/download/${id}`, httpOptions).pipe(catchError(this.handleError));
	}

	/**
	 * Método para descargar la plantilla de datos de una base de datos
	 * @param id id del registro a descargarle su información
	 * @param card_id id del tipo de cargue
	 * @param key key de la cartera trabajada
	 * @author Juan Carlos Alonso
	 * @updatedate 2023-03-17
	 */
	downloadDataByRegister(id: number, card_id:number, key: string): Observable<any> {
		const httpOptions = {
			responseType: 'blob' as 'json',
		};
		// http://localhost:8009/api/upload/download/4
		return this.http.get(this.UPLOAD + `download/${id}/${card_id}/${key}`, httpOptions).pipe(catchError(this.handleError));
	}

	/**
	 * Método para descargar el archivo de errores de un registro cargado.
	 * @param id id del registro a descargarle su información
	 * @param card_id id del tipo de cargue
	 * @param key key de la cartera trabajada
	 * @author Juan Carlos Alonso
	 * @updatedate 2023-03-17
	 */
	downloadErrorsByRegister(id: number, card_id:number, key: string): Observable<any> {
		const httpOptions = {
			responseType: 'blob' as 'json'
		};

		// http://localhost:8009/api/upload/download_error/265
		return this.http.get(this.UPLOAD + `download_error/${id}/${card_id}/${key}`, httpOptions).pipe(catchError(this.handleError));
	}


	/**
	 * Método para descargar el archivo de errores de un registro de un pago cargado.
	 * @param id id del registro a descargarle su información
	 * @author Yanelis Sardoth Blanchar
	 * @createdate 2021-10-11
	 */
	 downloadErrorsPayByRegister(id: number): Observable<any> {
		const httpOptions = {
			responseType: 'blob' as 'json'
		};

		// http://localhost:8009/api/upload/download_error/265
		return this.http.get(this.UPLOAD + `download_error_pay/${id}`, httpOptions).pipe(catchError(this.handleError));
	}



	/**
	 * Método para agregar un nuevo registro a una base de datos.
	 * @param formData datos del formulario (archivo y observaciones) y otros datos necesarios para la creación del registro
	 * @author Carlos Gómez
	 * @createdate 2021-07-27
	 */
	addDatabaseRegister(formData: FormData): Observable<any> {
		return this.http.post(this.UPLOAD_FILE, formData, { responseType: 'json', observe: 'response' }).pipe(
			catchError(this.handleError),
			map((data) => {
				return data;
			})
		);
	}

	/**
	 * Método para obtener la información de los archivos a cargar para agregar un nuevo registro a la base de datos seleccionada.
	 * @param id id de la base de datos seleccionada.
	 * @author Carlos Gómez
	 * @createdate 2021-08-10
	 */
	getFilesByDatabase(id: number): Observable<DatabaseFile[]> {
		return this.http.get<DatabaseFile[]>(this.UPLOAD_FILE + id).pipe(catchError(this.handleError));
	}


	/**
	 * Método para obtener el número de errores que tiene un archivo subido.
	 * @param id id del archivo seleccionado.
	 * @author Yanelis Sardoth
	 * @createdate 2022-02-09
	 */
	 getErrorsPayCount(id: number): Observable<any> {
		return this.http.get<any>(this.ERRORS_PAY +'count/'+ id).pipe(catchError(this.handleError));
	}


	/**
	 * Método para obtener los errores que tiene un archivo subido.
	 * @param id id del archivo seleccionado.
	 * @author Yanelis Sardoth
	 * @createdate 2022-02-09
	 */
	 getErrorsByDownload(id: number): Observable<any> {
		return this.http.get<any>(this.ERRORS_PAY +'by-download/'+ id).pipe(catchError(this.handleError));
	}


	/**
	 * Método para agregar un nuevo registro a una base de datos.
	 * @param formData datos del formulario (archivo y observaciones) y otros datos necesarios para la creación del registro
	 * @author Yanelis Sardoth
	 * @createdate 2022-02-09
	 */
	 postCorrect(formData: FormData): Observable<any> {
		return this.http.post(this.ERRORS_PAY +'correct', formData, { responseType: 'json', observe: 'response' }).pipe(
			catchError(this.handleError),
			map((data) => {
				return data;
			})
		);
	}


		/**
	 * Método para eliminar un error corregido.
	 * @param formData datos del formulario (archivo y observaciones) y otros datos necesarios para la creación del registro
	 * @author Yanelis Sardoth
	 * @createdate 2022-02-09
	 */
	deleteCorrect(id:number): Observable<any> {
		return this.http.delete(this.ERRORS_PAY +id, { responseType: 'json', observe: 'response' }).pipe(
			catchError(this.handleError),
			map((data) => {
				return data;
			})
		);
	}

	/**
	 * Método para obtener la data necesaria para la cancelación del pago.
	 * @param pay_id id del pago
	 * @author Joel Sánchez
	 * @createdate 2022-03-08
	 */
	getPayInfo(pay_id:number){
		return this.http.get<any>(this.API_URL+'pay/encabezado/'+pay_id).pipe(catchError(this.handleError));
	}

		/**
	 * Método para eliminar un error corregido.
	 * @param formData datos del formulario (archivo y observaciones) y otros datos necesarios para la creación del registro
	 * @author Yanelis Sardoth
	 * @createdate 2022-02-09
	 */
	deleteErrorPayDownloadFile(upload_id:number): Observable<any> {
		return this.http.delete(this.ERRORS_PAY +upload_id, { responseType: 'json', observe: 'response' }).pipe(
			catchError(this.handleError),
			map((data) => {
				return data;
			})
		);
	}

	/**
	 * Método para obtener los pagos relacionados al archivo cargado.
	 * @param upload_id id del archivo
	 * @author Joel Sánchez
	 * @createdate 2022-03-11
	 */
	getInfoPaysUpload(upload_id){
		return this.http.get<any>(this.API_URL+'pay/pays_by_upload/'+upload_id).pipe(catchError(this.handleError));
	}

	/**
	 * Método para guardar los resultados de la cancelación.
	 * @param formData datos del formulario (archivo y observaciones) y otros datos necesarios para la creación del registro
	 * @author Yanelis Sardoth
	 * @createdate 2022-02-09
	 */
	postCancelacionPago(formData): Observable<any> {
		return this.http.post(this.API_URL +'pay/update_tables', formData, { responseType: 'json', observe: 'response' }).pipe(
			catchError(this.handleError),
			map((data) => {
				return data;
			})
		);
	}

}
