import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ReportsService } from '../../../../nomina/services/rest/reports.service';
import { Chart, ChartType, registerables } from 'chart.js';
import { GraphService } from '../../../services/rest/graph.service';
import * as moment from 'moment';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report-adhesion',
  templateUrl: './report-adhesion.component.html',
  styleUrls: ['./report-adhesion.component.sass']
})
export class ReportAdhesionComponent implements OnInit, AfterViewInit {
  chart: any = null; //Variable que crea el grafico que se va a renderizar
  newChart: any = null; // variable que almacena la información del grafico que llega desde el componente padre
  tipyeGraf: any = 'line';
  dataLabel: any[] = [];
  canvas: any;
  ctx: any;

  //Grafica semanal
  multi: any[] = [
    {
      "name": "Lunes",
      "series": [
        {
          "name": "Turno",
          "value": 5421665,
        },
        {
          "name": "Turno realizado",
          "value": 7562125
        }
      ]
    },
    {
      "name": "Martes",
      "series": [
        {
          "name": "2010",
          "value": 7870000
        },
        {
          "name": "2011",
          "value": 8270000
        }
      ]
    },

    {
      "name": "Miercoles",
      "series": [
        {
          "name": "2010",
          "value": 5000002
        },
        {
          "name": "2011",
          "value": 5800000
        }
      ]
    },
    {
      "name": "Jueves",
      "series": [
        {
          "name": "2010",
          "value": 5000002
        },
        {
          "name": "2011",
          "value": 5800000
        }
      ]
    },
    {
      "name": "Viernes",
      "series": [
        {
          "name": "2010",
          "value": 5000002
        },
        {
          "name": "2011",
          "value": 5800000
        }
      ]
    },
    {
      "name": "Sabado",
      "series": [
        {
          "name": "2010",
          "value": 5000002
        },
        {
          "name": "2011",
          "value": 5800000
        }
      ]
    },
    {
      "name": "Domingo",
      "series": [
        {
          "name": "2010",
          "value": 5000002
        },
        {
          "name": "2011",
          "value": 5800000
        }
      ]
    }
  ];
  view: any[] = [900, 400];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = '';
  animations: boolean = true;

  colorScheme = {
    domain: ['#227C9D', '#C7B42C', '#AAAAAA']
  };

  resumSchedule: any[] = [];
  date = new Date();
  titleDate: string = '';

  canvasBarChart: any;
  ctxBarChart: any;
  tabId: any = 'Semanal';
  barChartGraph: any;
  monthChartGraph: any;

  //Arreglo de fechas
  weekDates: any = [];
  weekDatesGraphLabels: any = [];
  indexDate: any = 0;

  constructor(private reportService: ReportsService, private graphService: GraphService, private router: Router) {
    Chart.register(...registerables);
    Object.assign(this.multi);
  }

  ngOnInit(): void {
    let textTitleDate = moment(this.date).locale('es').format('dddd D [de] MMMM [del] YYYY');
    this.titleDate = textTitleDate[0].toUpperCase() + textTitleDate.substr(1).toLowerCase();

    this.graphService.getcurrentDatesInTheWeek().subscribe(resp => {
      const array = Object(resp);
      this.weekDatesGraphLabels = Object(resp);
      array.map((element, index) => {
        let textTitleDate = moment(element).locale('es').format('dddd D [de] MMMM [del] YYYY');
        let titleDate = textTitleDate[0].toUpperCase() + textTitleDate.substr(1).toLowerCase();
        this.weekDates.push({ title: titleDate, date: element });
      });

      this.weekDates.filter((item, index) => {
        if (moment(this.date).locale('es').format('YYYY-MM-D') == moment(item.date).locale('es').format('YYYY-MM-D')) {
          this.indexDate = index;
          this.getInfoSummary(item.date);
        }
      });

    });
  }

  ngAfterViewInit() {
    if (this.tabId == 'Semanal') {
      this.getInfo2();
    } else if (this.tabId == 'Mensual') {
      this.getInfo();
    }
  }

  getInfo() {
    this.graphService.getInfo().subscribe(res => {
      this.renderGraphVoid2(res["dias"], res["horario"], res["inicio_turno"], res['breaks'], res['lunch'], res['training']);
    })
  }

  getInfo2() {
    this.graphService.getgraphBars().subscribe((res) => {
      this.stackedBarsGraph(res);
    })
  }

  renderGraphVoid2(dias, horario, inicioTurno, breaks?, lunch?, training?) {
    this.canvas = <HTMLCanvasElement>document.getElementById('barras');
    this.ctx = this.canvas.getContext('2d');

    let data = dias.map((year, index) => ({
      x: moment(`${year}`),
      y: moment(`1970-02-01 ${horario[index]}`).valueOf()
    }));

    let data2 = dias.map((year, index) => ({
      x: moment(`${year}`),
      y: moment(`1970-02-01 ${inicioTurno[index]}`).valueOf()
    }));

    let dataSets = [
      {
        label: "Horario",
        borderColor: 'rgb(43,170,187)',
        data: data,
      },
      {
        label: "Inicio de turno",
        borderColor: 'rgb(0,153,0)',
        data: data2
      }
    ];

    breaks.map(resp => {
      let breaksData = this.monthBreaks(resp, dias);
      dataSets.push({
        label: "Break",
        borderColor: 'rgb(186,102,204)',
        data: breaksData
      });
    });

    lunch.map(resp => {
      let lunchData = this.monthLunch(resp, dias);
      dataSets.push({
        label: "Almuerzo",
        borderColor: 'rgb(242,189,0)',
        data: lunchData
      });
    });

    training.map(resp => {
      let trainingData = this.monthTraining(resp, dias);
      dataSets.push({
        label: "Capacitación",
        borderColor: 'rgb(255,148,0)',
        data: trainingData
      });
    });


    this.monthChartGraph = new Chart(this.ctx, {
      type: 'line',
      data: {
        datasets: dataSets
      },
      options: {
        plugins: {
          legend: {
            display: true,
            position: 'right',
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let date = moment(context.parsed.y);
                if (date.diff(moment('1970-02-01 23:59:59'), 'minutes') === 0) {
                  return null;
                }

                return date.format('HH:mm A');

              }
            }
          }
        },
        scales: {
          x:
          {
            type: 'category',
            labels: dias
          },
          y:
          {
            type: 'linear',
            position: 'left',
            ticks: {
              stepSize: 3.6e+6,
              callback: value => {
                let date = moment(value);
                if (date.diff(moment('1970-02-01 23:59:59'), 'minutes') === 0) {
                  return null;
                }

                return date.format('HH:mm A');
              }
            }
          }
        },
      }
    });
  }
  /**
* @author Javier Castañeda
* @createdate 2022-05-23
* Metodo para saber la pestaña seleccionada y pintar la grafica correcta
*/
  onTabChange(event: MatTabChangeEvent) {
    this.tabId = event.tab.textLabel;
    if (this.tabId == 'Semanal') {
      this.monthChartGraph.destroy();
      // Dibuja la grafica de Semanal
      this.getInfo2();
    } else if (this.tabId == 'Mensual') {
      this.barChartGraph.destroy();
      // Dibuja la grafica Mensual
      this.getInfo();
    }
  }
  /**
* @author Javier Castañeda
* @createdate 2022-05-20
* Metodo para Pintar grafica semanal
*/
  stackedBarsGraph(dataBarsData) {
    this.canvasBarChart = <HTMLCanvasElement>document.getElementById('barChart');
    this.ctxBarChart = this.canvasBarChart.getContext('2d');
    //data del turno programado y el realizado con sus respectivas diferencias
    let turnoProgramado = this.turnoProgramado(dataBarsData);
    let diferenciaT = this.diferenciaBar(dataBarsData);
    let hours = this.horasTurnoProgramado(dataBarsData);
    let diferenciaT2 = this.diferenciaTurnoProgramado(dataBarsData);
    let diferencia = this.diferenciaTurnoRealizado(dataBarsData);
    let realizado = this.turnoRealizado(dataBarsData);
    let diferencia2 = this.diferenciaTurnoRealizado2(dataBarsData);

    let dataSets = [
      {
        label: "",
        type: "bar",
        stack: "Turn",
        backgroundColor: "#FFFFFF",
        barThickness: 12,
        data: turnoProgramado // posiciona la barra []
      },
      {
        label: "Diferencia",
        type: "bar",
        stack: "Turn",
        backgroundColor: "#FF585E",
        barThickness: 12,
        data: diferenciaT
      },
      {
        label: "Turno programado",
        type: "bar",
        stack: "Turn",
        backgroundColor: "#227C9D",
        barThickness: 12,
        data: hours
      },
      {
        label: "Diferencia",
        type: "bar",
        stack: "Turn",
        backgroundColor: "#FF585E",
        barThickness: 12,
        data: diferenciaT2
      },
      {
        label: "",
        type: "bar",
        stack: "Mark",
        backgroundColor: "#FFFFFF",
        barThickness: 12,
        data: turnoProgramado // posiciona la barra realizado []
      },
      {
        label: "Diferencia",
        type: "bar",
        stack: "Mark",
        backgroundColor: "#FF585E",
        barThickness: 12,
        data: diferencia
      },
      {
        label: "Turno realizado",
        type: "bar",
        stack: "Mark",
        backgroundColor: "#2ECDF0",
        barThickness: 12,
        data: realizado
      },
      {
        label: "Diferencia",
        type: "bar",
        stack: "Mark",
        backgroundColor: "#FF585E",
        barThickness: 12,
        data: diferencia2
      },
    ];
    
    if (dataBarsData.TypesReal) {
      dataBarsData.TypesReal.map((resp, index) => {
        switch (resp.schedule_type) {
          case 2: //Break
            let breakPosition = this.dinamicTypesPositionBar(resp, 'break' + index, 2);
            let breakBar = this.dinamicTypes(resp, 'Break', 'break' + index, '#BA66CC', 2);
            dataSets.push(breakPosition);
            dataSets.push(breakBar);
            break;
          case 3: //Almuerzo
            let lunchPosition = this.dinamicTypesPositionBar(resp, 'lunch' + index, 3);
            let lunchBar = this.dinamicTypes(resp, 'Almuerzo', 'lunch' + index, '#F2BD00', 3);
            dataSets.push(lunchPosition);
            dataSets.push(lunchBar);
            break;
          case 4: // Capacitación
            let capacitacionPosition = this.dinamicTypesPositionBar(resp, 'training' + index, 4);
            let capacitacionBar = this.dinamicTypes(resp, 'Capacitación', 'training' + index, '#FF9400', 4);
            dataSets.push(capacitacionPosition);
            dataSets.push(capacitacionBar);
            break;
          default:

            break;
        }

      });
    }
    this.barChartGraph = new Chart(this.ctxBarChart, {
      type: 'bar' as ChartType,
      data: {
        datasets: Object(dataSets)
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'right',
            labels: {
              generateLabels(chart: any) {
                const newLabels = [
                  { text: 'Turno programado', fillStyle: '#227C9D', strokeStyle: 'transparent', lineWidth: 0 },
                  { text: 'Turno realizado', fillStyle: '#2ECDF0', strokeStyle: 'transparent', lineWidth: 0 },
                  { text: 'Diferencia', fillStyle: '#FF585E', strokeStyle: 'transparent', lineWidth: 0 },
                ];
                return newLabels;
              },
            }
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const title = context.dataset.label;
                let titleWithHours = 'Sin horarios';
                const searchDay = dataBarsData.JornadaProgramada.find((day: any) => {
                  return day.date_start === context.dataset.data[context.dataIndex].x;
                });
                if (searchDay) {
                  if (title === 'Turno programado') {
                    titleWithHours = `${title} ${searchDay.hour_start} - ${searchDay.hour_end}`;
                  } else if (title === 'Turno realizado') {
                    const searchRealDay = dataBarsData.JornadaReal.find((day: any) => {
                      return day.date_start === context.dataset.data[context.dataIndex].x;
                    });
                    titleWithHours = searchRealDay ? `${title} ${searchRealDay.hour_start} - ${searchDay.hour_end}` : 'Sin horarios';
                  } else if (title === 'Diferencia') {
                    const searchRealDay = dataBarsData.JornadaReal.find((day: any) => {
                      return day.date_start === context.dataset.data[context.dataIndex].x;
                    });
                    titleWithHours = searchRealDay ? `${title} ${searchDay.hour_start} - ${searchRealDay.hour_start}` : 'Sin horarios';
                  }
                }
                return titleWithHours;
              },
            },
          }
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            labels: this.weekDatesGraphLabels,
          },
          y: {
            stacked: true,
            grid: {
              display: false,
            },
            position: 'left',
            min: moment('1970-02-01 00:00:00').valueOf(),
            max: moment('1970-02-01 23:59:59').valueOf(),
            beginAtZero: false,
            ticks: {
              stepSize: 3.6e+6,
              callback: value => {
                let date = moment(value);
                if (date.diff(moment('1970-02-01 23:59:59'), 'minutes') === 0) {
                  return null;
                }

                return date.format('HH:mm A');
              }
            }
          }
        }
      }
    });
  }

  /**
 * @author Javier Castañeda
 * @createdate 2022-05-25
 * Metodo de consulta de resumen del horario del usuario logueado
 */
  getInfoSummary(date?) {
    this.graphService.getCalculosSchedule(date).subscribe((res) => {
    let data = Object(res);     
    let format_data = [];
    data.forEach(function(rw, index) {
        if(rw.schedule_type_id==1 || rw.schedule_type_id==3 ){
          format_data.push(rw);
        }
    });
      this.resumSchedule = Object(format_data);
    }, (error) => {
      this.resumSchedule = [];
    });
  }
  /**
   * @author Javier Castañeda
   * @createdate 2022-05-25
   * Metodo para cambio de día hacia adelante en la semanal actual y consulta del resumen del dia.
   */
  nextDate() {
    if (this.indexDate < this.weekDates.length - 1) {
      this.indexDate += 1;
      this.titleDate = '';
      this.titleDate = this.weekDates[this.indexDate]?.title;
      this.getInfoSummary(this.weekDates[this.indexDate]?.date);
    }
  }
  /**
   * @author Javier Castañeda
   * @createdate 2022-05-25
   * Metodo para cambio de día hacia atras en la semanal actual y consulta del resumen del dia.
   */
  prevDate() {
    if (this.indexDate > 0 || this.indexDate < this.weekDates.lenght - 1) {
      this.indexDate = this.indexDate - 1;
      this.titleDate = '';
      this.titleDate = this.weekDates[this.indexDate]?.title;
      this.getInfoSummary(this.weekDates[this.indexDate]?.date);
    }
  }

  /**
 * @author Javier Castañeda
 * @createdate 2022-05-26
 * Metodo para calculo de data en la grafica semanal del turno programado para posicionar las barras
 */
  turnoProgramado(dataBars) {
    let dataBarsTurn = [];
    let RealData = [];
    let turnoProgramado = [];
    //hora de inicio
    dataBarsTurn = dataBars.JornadaReal;
    dataBarsTurn.map(item => {
      RealData.push({
        date_end: item.date_end,
        date_start: item.date_start,
        hour_end: item.hour_end,
        hour_start: item.hour_start,
        schedule_key: item.schedule_key,
        schedule_name: item.schedule_name,
        schedule_type: item.schedule_type
      });
    });
    this.weekDatesGraphLabels.map(item => {
      let programada = dataBars.JornadaProgramada.filter(resp => resp.date_start == item);
      let realObject = RealData.filter(resp => resp.date_start == item);

      if (programada.length > 0 && realObject.length > 0) {
        let start = new Date(`1970-02-01 ${programada[0].hour_start}`).getTime();
        let startDate = new Date(`1970-02-01 ${realObject[0]?.hour_start}`).getTime();
        let hora
        if (start > startDate) {
          hora = realObject[0]?.hour_start;
        } else {
          hora = programada[0].hour_start;
        }
        turnoProgramado.push({
          x: new DatePipe('en-US').transform(programada[0].date_start, 'yyyy-MM-dd'),
          y: moment(`1970-02-01 ${hora}`).valueOf(),
        });
      } else if (programada.length > 0) {
        turnoProgramado.push({
          x: new DatePipe('en-US').transform(programada[0].date_start, 'yyyy-MM-dd'),
          y: moment(`1970-02-01 ${programada[0].hour_start}`).valueOf()
        });
      } else {
        turnoProgramado.push({
          x: new DatePipe('en-US').transform(item, 'yyyy-MM-dd'),
          y: moment(`1970-02-01 00:00:00`).valueOf()
        });
      }
    });
    return turnoProgramado
  }

  /**
* @author Javier Castañeda
* @createdate 2022-05-26
* Metodo para calculo de data en la grafica semanal del turno programado vs el realizado
*/
  diferenciaBar(dataBars) {
    let diferenciaT = [];
    dataBars.JornadaReal.map((year, index) => {
      let start = new Date(`1970-02-01 ${dataBars.JornadaProgramada[index].hour_start}`).getTime();
      let end = new Date(`1970-02-01 ${year.hour_start}`).getTime();
      let resta
      if (start < end) {
        resta = 0
      } else {
        resta = start - end;
      }
      diferenciaT.push({
        x: new DatePipe('en-US').transform(year.date_start, 'yyyy-MM-dd'),
        y: resta
      });
    });
    return diferenciaT
  }
  /**
* @author Javier Castañeda
* @createdate 2022-05-26
* Metodo para calculo de data en la grafica semanal del turno programado
*/
  horasTurnoProgramado(dataBars) {
    let hours = [];
    dataBars.JornadaProgramada.map((year, index) => {
      let start = new Date(`1970-02-01 ${year.hour_start}`).getTime();
      let end = new Date(`1970-02-01 ${year.hour_end}`).getTime();
      let resta = end - start;
      hours.push({
        x: new DatePipe('en-US').transform(year.date_start, 'yyyy-MM-dd'),
        y: resta
      });

    });
    return hours
  }
  /**
* @author Javier Castañeda
* @createdate 2022-05-26
* Metodo para calculo de data en la grafica semanal de la diferencia final del turno programado programado vs el realizado
*/
  diferenciaTurnoProgramado(dataBars) {
    let diferenciaT2 = [];
    dataBars.JornadaReal.map((year, index) => {
      let timeEnd = year.hour_end == null ? dataBars.JornadaProgramada[index].hour_end : year.hour_end;
      let start = new Date(`1970-02-01 ${dataBars.JornadaProgramada[index].hour_end}`).getTime();
      let end = new Date(`1970-02-01 ${timeEnd}`).getTime();
      let resta
      if (start > end) {
        resta = 0
      } else {
        resta = end - start;
      }
      diferenciaT2.push({
        x: new DatePipe('en-US').transform(year.date_start, 'yyyy-MM-dd'),
        y: resta
      });

    });
    return diferenciaT2
  }
  /**
* @author Javier Castañeda
* @createdate 2022-05-26
* Metodo para calculo de data en la grafica semanal de la diferencia final del turno realizado vs el programado
*/
  diferenciaTurnoRealizado(dataBars) {
    let diferencia = [];
    dataBars.JornadaReal.map((year, index) => {
      let start = new Date(`1970-02-01 ${dataBars.JornadaProgramada[index].hour_start}`).getTime();
      let end = new Date(`1970-02-01 ${year.hour_start}`).getTime();
      let resta
      if (start > end) {
        resta = 0
      } else {
        resta = end - start;
      }
      diferencia.push({
        x: new DatePipe('en-US').transform(year.date_start, 'yyyy-MM-dd'),
        y: resta
      });
    });
    return diferencia
  }
  /**
* @author Javier Castañeda
* @createdate 2022-05-26
* Metodo para calculo de data en la grafica semanal del turno realizado
*/
  turnoRealizado(dataBars) {
    let realizado = [];
    dataBars.JornadaReal.map((year, index) => {
      let timeEnd = year.hour_end == null ? dataBars.JornadaProgramada[index].hour_end : year.hour_end;
      let start = new Date(`1970-02-01 ${year.hour_start}`).getTime();
      let end = new Date(`1970-02-01 ${timeEnd}`).getTime();
      let resta = end - start;
      realizado.push({
        x: new DatePipe('en-US').transform(year.date_start, 'yyyy-MM-dd'),
        y: resta
      });
    });
    return realizado
  }
  /**
* @author Javier Castañeda
* @createdate 2022-05-26
* Metodo para calculo de data en la grafica semanal de la diferencia final del turno realizado vs el programado
*/
  diferenciaTurnoRealizado2(dataBars) {
    let diferencia2 = [];
    dataBars.JornadaReal.map((year, index) => {
      let timeEnd = year.hour_end == null ? dataBars.JornadaProgramada[index].hour_end : year.hour_end;
      let start = new Date(`1970-02-01 ${dataBars.JornadaProgramada[index].hour_end}`).getTime();
      let end = new Date(`1970-02-01 ${timeEnd}`).getTime();
      let resta
      if (start < end) {
        resta = 0
      } else {
        resta = start - end;
      }
      diferencia2.push({
        x: new DatePipe('en-US').transform(year.date_start, 'yyyy-MM-dd'),
        y: resta
      });

    });
    return diferencia2
  }

  /**
* @author Javier Castañeda
* @createdate 2022-05-26
* Metodo para posicionar el inicio de la barra del tipo de horarios como almuerzo, etc.
*/
  dinamicTypesPositionBar(resp, stack, type) {
    let typePosition = [];
    typePosition.push({
      x: new DatePipe('en-US').transform(resp.date_start, 'yyyy-MM-dd'),
      y: moment(`1970-02-01 ${resp.hour_start}`).valueOf()
    });
    let Type = {
      label: '',
      type: "bar",
      stack: stack,
      backgroundColor: '#FFFFFF',
      barThickness: 12,
      data: typePosition
    }
    return Type
  }
  /**
* @author Javier Castañeda
* @createdate 2022-05-26
* Metodo para pintar las barras y generar la data de los tipos de horario como almuerzo, etc
*/
  dinamicTypes(resp, label, stack, color, type) {
    let data = [];

    let start = new Date(`1970-02-01 ${resp.hour_start}`).getTime();
    let end = new Date(`1970-02-01 ${resp.hour_end}`).getTime();
    let resta = end - start;
    data.push({
      x: new DatePipe('en-US').transform(resp.date_start, 'yyyy-MM-dd'),
      y: resta
    });
    let Type = {
      label: label,
      type: "bar",
      stack: stack,
      backgroundColor: color,
      barThickness: 12,
      data: data
    }
    return Type
  }
  /**
* @author Javier Castañeda
* @createdate 2022-05-27
* Metodo para pintar el braek en la grafica mensual
*/
  monthBreaks(resp, dias) {
    let hora = "";
    let data = [];
    dias.map((year, index) => {
      if (resp.date_start == year) {
        hora = resp.real_start;
        data.push({
          x: moment(`${year}`),
          y: moment(`1970-02-01 ${hora}`).valueOf()
        });
      } else {
        data.push({
          x: moment(`${year}`),
          y: moment(`1970-02-01 00:00:00`).valueOf()
        });
      }

    });
    return data
  }
  /**
* @author Javier Castañeda
* @createdate 2022-05-27
* Metodo para pintar el almuerzo en la grafica mensual
*/
  monthLunch(resp, dias) {
    let hora = "";
    let data = [];
    dias.map((year, index) => {
      if (resp.date_start == year) {
        hora = resp.real_start;
        data.push({
          x: moment(`${year}`),
          y: moment(`1970-02-01 ${hora}`).valueOf()
        });
      } else {
        data.push({
          x: moment(`${year}`),
          y: moment(`1970-02-01 00:00:00`).valueOf()
        });
      }

    });
    return data
  }
  /**
* @author Javier Castañeda
* @createdate 2022-05-27
* Metodo para pintar el capacitación en la grafica mensual
*/
  monthTraining(resp, dias) {
    let hora = "";
    let data = [];
    dias.map((year, index) => {
      if (resp.date_start == year) {
        hora = resp.real_start;
        data.push({
          x: moment(`${year}`),
          y: moment(`1970-02-01 ${hora}`).valueOf()
        });
      } else {
        data.push({
          x: moment(`${year}`),
          y: moment(`1970-02-01 00:00:00`).valueOf()
        });
      }

    });
    return data
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-05-27
    * Metodo redireccionar al home. 
  */
  goToHome(): void {
    this.router.navigate(['mios/ciu']);
  }
}