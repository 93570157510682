import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const AUDITORIA_URL = environment.AUDITORIA_URL;
const CRM_URL = environment.CRM_URL;
const CRM2_URL = environment.CRM2_URL;

@Injectable({
  providedIn: 'root'
})
export class AuditoriaService {
  protected dataUser;
  private _refresh$ = new Subject<void>();
  constructor(
    private http: HttpClient,
  ) {

  }

  get refresh$() {
    return this._refresh$;
  }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  getTypeService() {
    return this.http.get(`${AUDITORIA_URL}getTipoServicio`)
      .pipe(
        catchError(this.handleError)
      );
  }

  getTypeMonitoring() {
    return this.http.get(`${AUDITORIA_URL}getTipoMonitoreo`)
      .pipe(
        catchError(this.handleError)
      );
  }

  getTypeAudit() {
    return this.http.get(`${AUDITORIA_URL}getTipoAuditoria`)
      .pipe(
        catchError(this.handleError)
      );
  }

  getTypeSolution() {
    return this.http.get(`${AUDITORIA_URL}getSolucion`)
      .pipe(
        catchError(this.handleError)
      );
  }

  getTypegetOjt() {
    return this.http.get(`${AUDITORIA_URL}getOjt`)
      .pipe(
        catchError(this.handleError)
      );
  }

  getNoSolucion() {
    return this.http.get(`${AUDITORIA_URL}getNoSolucion`)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAsesor(cedula) {
    return this.http.get(`${AUDITORIA_URL}getAsesor?cedula=${cedula}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  createAuditoria(data) {
    return this.http.post<any>(`${AUDITORIA_URL}crearAuditoria`, data)
      .pipe(
        catchError(this.handleError),
        tap(() => {
          this._refresh$.next();
        })
      );
  }

  getRetroalimentar(params) {
    return this.http.get(`${AUDITORIA_URL}buscarAsesorAuditoria`, { params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getRetroalimentacionById(id: number) {
    return this.http.get(`${AUDITORIA_URL}auditoriaById/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateRetroalimentacionById(id: number, data) {
    return this.http.post<any>(`${AUDITORIA_URL}updateRetroalimentacion/${id}`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAuditoriasByAsesor(params) {
    return this.http.get(`${AUDITORIA_URL}getAuditorias`, { params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getPuntajes() {
    return this.http.get(`${AUDITORIA_URL}totalAuditorias`)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateAuditoria(id, data) {
    return this.http.post<any>(`${AUDITORIA_URL}updateAuditoriaAsesor/${id}`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllAuditorias(params) {
    return this.http.get(`${AUDITORIA_URL}auditorias`, { params })
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteAuditoria(id, data) {
    return this.http.post<any>(`${AUDITORIA_URL}deleteAuditoria/${id}`, data)
      .pipe(
        catchError(this.handleError),
        tap(() => {
          this._refresh$.next();
        })
      );
  }

  get_tipo_campana() {
    return this.http.get(`${AUDITORIA_URL}get_tipo_campana`)
      .pipe(
        catchError(this.handleError)
      );
  }

  get_aliado() {
    return this.http.get(`${AUDITORIA_URL}get_aliado`)
      .pipe(
        catchError(this.handleError)
      );
  }

  get_skills() {
    return this.http.get(`${AUDITORIA_URL}get_skills`)
      .pipe(
        catchError(this.handleError)
      );
  }

  get_tipificacion() {
    return this.http.get(`${AUDITORIA_URL}get_tipificacion`)
      .pipe(
        catchError(this.handleError)
      );
  }

  get_subtipificacion(id) {
    return this.http.get(`${AUDITORIA_URL}get_subtipificacion?idt=${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  get_tiendas() {
    return this.http.get(`${AUDITORIA_URL}get_tiendas`)
      .pipe(
        catchError(this.handleError)
      );
  }

  get_tipos() {
    return this.http.get(`${AUDITORIA_URL}get_tipos`)
      .pipe(
        catchError(this.handleError)
      );
  }

  get_segmentos() {
    return this.http.get(`${AUDITORIA_URL}get_segmentos`)
      .pipe(
        catchError(this.handleError)
      );
  }

  get_subtipos_pqr() {
    return this.http.get(`${AUDITORIA_URL}get_subtipos_pqr`)
      .pipe(
        catchError(this.handleError)
      );
  }

  get_mes() {
    return this.http.get(`${AUDITORIA_URL}get_mes`)
      .pipe(
        catchError(this.handleError)
      );
  }

  listarCrm(formid, size, page, formAnswerId, fi, ff) {
    return this.http.get(`${CRM_URL}form-answers/list/${formid}?formAnswerId=${formAnswerId}&perPage=${size}&page=${page}&from=${fi}&to=${ff}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  listarFrm() {
    return this.http.get(`${CRM_URL}formslist`)
      .pipe(
        catchError(this.handleError)
      );
  }

  eliminarCrm(id) {
    return this.http.get(`${CRM_URL}form-answers/delete/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-01-11
    * Metodo que lista los formularios de CRM
    * @param crmVersion Version de CRM seleccionada en el formulario
  */
  listFormsCrm(crmVersion: number) {
    const url = crmVersion === 1 ? `${CRM_URL}form-answers/getforms` : `${CRM2_URL}form-answers/getforms`;
    return this.http.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-01-11
    * Metodo que lista las tipificaciones registradas en los formularios de CRM
    * @param formid Id del formulario
    * @param size Tamaño de los registros
    * @param page Pagina en la paginacion
    * @param formAnswerId Id del registro
    * @param fi Fecha inicial
    * @param ff Fecha final
    * @param status Estado del registro
    * @param crmVersion Version de CRM seleccionada en el formulario
  */
  listarCrmV2(formid: number, size: number, page: number, formAnswerId: number, fi: string, ff: string, status: number, crmVersion: number) {
    const url = crmVersion === 1 ? `${CRM_URL}form-answers/listv2/${formid}?formAnswerId=${formAnswerId}&perPage=${size}&page=${page}&from=${fi}&to=${ff}&status=${status}` : `${CRM2_URL}form-answers/listv2/${formid}?formAnswerId=${formAnswerId}&perPage=${size}&page=${page}&from=${fi}&to=${ff}&status=${status}`;
    return this.http.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-01-11
    * Metodo que inactiva un registro de una tipificacion
    * @param id Id de la tipificacion seleccionada
    * @param status Estado del registro seleccionado
    * @param crmVersion Version de CRM seleccionada en el formulario
  */
  eliminarCrmV2(id: number, status: number, crmVersion: number) {
    const url = crmVersion === 1 ? `${CRM_URL}form-answers/deletev2/${id}?status=${status}` : `${CRM2_URL}form-answers/deletev2/${id}?status=${status}`;
    return this.http.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }
}