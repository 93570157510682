import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../../services/users.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CandidatesService } from '../../../services/candidates.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { AuthService } from '../../../../../core/services/rest/auth.service';
import {Location} from '@angular/common';



@Component({
  selector: 'app-cv',
  templateUrl: './cv.component.html',
  styleUrls: ['./cv.component.sass']
})
export class CvComponent implements OnInit {
  panelOpenState = false;
  isEdit= false
  candidate:any = null;
  healthForm = this.fb.group({
    smoking:[null,Validators.required],
    alcohol:[null,Validators.required],
    psychoactive_substances:[null,Validators.required],
    training:[null,Validators.required],
    work_accident_history:[null,Validators.required],  
    occupational_disease_history:[null,Validators.required],
    personal_pathological_history:[null,Validators.required],
    mental_health_history:[null,Validators.required],
  })

  smokingOptions:any = SMOKING_OPTIONS;
  alcoholOptions:any = ALCOHOL_OPTIONS;
  psychoactiveSubstancesOptions:any = PSYCHOACTIVE_SUBSTANCES_OPTIONS;
  trainingOptions:any =TRAINING_OPTIONS;
  historyOfAccidentsAtWorkOptions:any = HISTORY_OF_ACCIDENTS_AT_WORK_OPTIONS;
  backgroundOccupationalDiseasesOptions:any = BACKGROUND_OCCUPATIONAL_DISEASES_OPTIONS;
  backgroundMentalOptions: any = BACKGROUND_MENTAL_OPTIONS



  constructor(private userServices:UsersService,private fb:FormBuilder,private candidateServices:CandidatesService,
              private alertService:AlertsService,private auth:AuthService, private _location: Location,
             ) {
              }

  ngOnInit(): void {
    this.getData();
  }
  backClicked() {
    this._location.back();
  }

  getData() {
    this.userServices.getCVUserLoggued().subscribe( (resp:any) => {
      this.candidate = resp;
      if(this.healthForm != null) this.healthForm.patchValue(resp.health_info);
     
    });
  }
  editData(){
    this.isEdit = true
  }
  saveHealt(){
    this.candidateServices.createJsonCandidate(this.candidate.id, 'health_info', JSON.stringify(this.healthForm.value) ).subscribe(e => {
       this.alertService.alertSuccess('¡Hecho!','Información guardada con éxito');
    });
  }
  editPerson(event){
  //   this.candidateServices.createJsonCandidate(this.candidate.id, 'personal_data', event.personal_data).subscribe(e => {
  //     this.alertService.alertSuccess('¡Excelente!','El documento se ha cargado satisfactoriamente');
  //  });
  }
}
const SMOKING_OPTIONS = ['No Fuma','1 a 4 Diarios','5 a 11 Diarios','11 a 15 Diarios'];
const ALCOHOL_OPTIONS = ['Diario','Semanal','Quincenal','Mensual','Ocasional','Nunca'];
const PSYCHOACTIVE_SUBSTANCES_OPTIONS = ['Nunca','Semanal','Mensual','Suspendido','Semestral','Mayor a un año'];
const TRAINING_OPTIONS = ['Diario','Semanal','Quincenal','Mensual','Ocasional','Nunca'];
const HISTORY_OF_ACCIDENTS_AT_WORK_OPTIONS = ['Si','No'];
const BACKGROUND_OCCUPATIONAL_DISEASES_OPTIONS = ['Si','No'];
const BACKGROUND_MENTAL_OPTIONS = ['Ninguno','Depresion','Ansiedad', 'Otro'];
