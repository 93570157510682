 <button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross"></i>
</button>
<h3 mat-dialog-title>{{data.name}}</h3>
<p>{{data.type.name}}</p>
<hr>
<mat-dialog-content>
  <p>{{data.hours[0].showTime | date}}</p>
  <mat-card-subtitle style="padding-bottom: 8px;">Horarios disponibles</mat-card-subtitle>

  <mat-selection-list
    #list_hours
    [multiple]        = "data['isCrm']"
    [(ngModel)]       = "hours"
    (ngModelChange)   = "checkContinuo(list_hours)"
    >
   <mat-list-option class="option"  *ngFor="let item of data.hours" [disabled]="!item.available" [value]="item" >
            <span>{{item.showTime | date:'shortTime'}}</span>
    </mat-list-option>

</mat-selection-list>

</mat-dialog-content>
<mat-dialog-actions align="center">
   <button
    matSuffix
    mat-flat-button
    [mat-dialog-close]    = "hours"
    [disabled]            = "hours.length == 0   || !isContinuos"
    color                 = "primary"
    >Hacer reservación</button>
</mat-dialog-actions>
