<div class="dialog-email">
    <button style="float: right;" mat-icon-button mat-dialog-close>
        <i class="fi-rr-cross-small"></i>
    </button>
    
    <mat-dialog-content class="email-content">
    
        <h4>Para: {{data.data.para}}</h4>
        <h4>Asunto: {{data.data.asunto}}</h4>
    
        <mat-divider></mat-divider>
        <br>
        <div style="font-size: large" class="emailPreview" innerHTML="{{data.data.cuerpo}}"></div>
        <div style="font-size: large" class="emailPreview" innerHTML="{{data.data.respuestaHtml}}"></div>
    
    </mat-dialog-content>
    
</div>
