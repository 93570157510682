<div class="container-fluid p-5">
    <div class="row">
        <div class="col-md-12">
        <span style="font-size: 32px; font-weight: 700;">Speech to Text</span>
        </div>
        <div class="col-md-12 mt-4">
        <p class="lh-base" style="color: #353535">
            Este es el módulo de Speech to Text, donde podrás administrar la transcripción de <br />
            audios y aplicar estrategias sin segmentar. No olvides, “El respeto y empatía son clave”.
        </p>
        </div>
    </div>

    <div class="row justify-content-between mt-5">
        <mat-vertical-stepper #stepperNoDirigido [linear]="true">
            <!-- STEP SUBIR GRABACIONES -->
            <mat-step>
                <ng-template matStepLabel>Cargar grabaciones</ng-template>
    
                <div class="row mt-4">
                    <div class="col-12">
                        <h3>Cargar grabaciones</h3>
                        <hr/>
                    </div>
                    <div class="col-12 mt-3">
                        <p>En esta sección, podrás buscar y cargar los audios que deseas analizar</p>
                    </div>
                </div>
    
                <div class="container" appDnd (fileDropped)="onFileDropped($event)">
                    <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
                    <span class="fi-rr-cloud-upload" style="font-size: 48px; margin-bottom: 20px;"></span>
                    <h3>Arrastra y suelta el audio aquí</h3>
                    <h3>o</h3>
                    <button class="mat-focus-indicator button-complete mat-flat-button mat-button-base mat-primary" mat-button>
                        <p style="margin-top: 6px;">Cargar audios <span class="fi-rr-upload" style="margin-left: 20px;"></span></p>
                    </button>      
                </div>
                <div class="files-list">
                    <div class="row">
                        <div class="col-3" *ngFor="let file of files; let i = index">
                            <div class="single-file">
                                <span class="fi-rr-file-music" style="font-size: 40px;"></span>
                                <div class="info">
                                    <h5 class="name">
                                        {{ file?.name }}
                                    </h5>
                                    <p class="size">
                                        {{ formatBytes(file?.size) }}
                                    </p>
                                </div>
                                <span class="fi-rr-trash delete" style="font-size: 20px;" (click)="deleteFile(i)"></span>
                            </div>
                        </div>
                    </div>
    
                    <div *ngIf="files.length > 1">
                        <button class="btn btn-danger" (click)="deleteAllFiles()">Limpiar todos los Audios <span class="fi-rr-trash delete" style="font-size: 16px;"></span></button>
                    </div>
                </div>
    
    
                <div class="col-md-12">
                    <p class="lh-base" style="color: #717171">
                    <b>Nota:</b> Solo se procesa formato <b>
                        <span color="primary" class="blue">.wav y .mp3</span></b>.
                    </p>
                </div>
    
                <div class="row mt-5">
                    <div class="col-4 mt-3 ">
                        <button class="w-100"  mat-raised-button color="primary"  (click)="loadAudios(stepperNoDirigido)">Subir audios &nbsp; <i class=" fi-rr-forward "></i> </button>
                    </div>
                </div>
    
            </mat-step>
            <!-- STEP BUSQUEDA DE GRABACIONES -->
    
            <!-- STEP ANALIZAR DE GRABACIONES -->
            <mat-step>
                <ng-template matStepLabel>Audios cargados</ng-template>
                <div class="row mt-4">
                    <div class="col-12">
                        <h3>Analizar las grabaciones</h3>
                        <hr/>
                    </div>
                    <div class="col-9 mt-3">
                        <p>En esta sección, podrás seleccionar las grabaciones que serán analizadas.</p>
                    </div>
                    <div class="col-3 d-flex align-items-end">
                        <button class="w-100 heaight-buttons"
                            mat-flat-button color="primary" (click)="downloadReport('analysis')"
                            [disabled]="!registrosCompletados()">
                            Descargar informe <i class="fi-rr-download"></i>
                        </button>
                    </div>
                </div>
    
                <div class="table-container mt-5">
                    <div class="mat-elevation-z1">
                        <table mat-table [dataSource]="dataSourceRecordings" matSort #sortRecordings="matSort">
                            <ng-container *ngFor="let column of displayedColumnsRecordings ">
                                <ng-container [matColumnDef]="column" *ngIf="column !== 'checbox' && column !== 'audio' && column !== 'analisis' ">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                                    <td mat-cell *matCellDef="let element"> {{ element[column] }} </td>
                                </ng-container>
                                <ng-container [matColumnDef]="column" *ngIf="column === 'checbox'">
                                    <th mat-header-cell *matHeaderCellDef> 
                                        <mat-checkbox (change)="$event ? selectAllRecordings() : null"
                                        [checked]="selectionRecordings.hasValue() && isAllSelectedRecordings()" 
                                        [indeterminate]="selectionRecordings.hasValue() && !isAllSelectedRecordings()"
                                        ></mat-checkbox>
                                    </th>
                                    <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element">
                                        <mat-checkbox 
                                            (click)="$event.stopPropagation()"
                                            (change)="element.analisis !== 'ERROR' ? selectionRecordings.toggle(element) : null"
                                            [checked]="selectionRecordings.isSelected(element)"
                                            [disabled]="element.analisis === 'ERROR' || element.analisis === 'PENDIENTE' || element.analisis === 'PRIORITARIO'">
                                        </mat-checkbox>
                                    </td>
                                </ng-container>
                                <ng-container [matColumnDef]="column" *ngIf="column === 'audio'">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                                    <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element" >
                                    <button  mat-icon-button style="width: 25px !important;" (click)="openAudioModal(element.recording_id)">
                                        <span class="fi-rr-megaphone"></span>
                                    </button>
                                    </td>
                                </ng-container>    
    
                                <ng-container [matColumnDef]="column" *ngIf="column === 'analisis'">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                                    <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element">
                                        <ng-container *ngIf="element.analisis  === 'PENDIENTE' || element.analisis === 'PRIORITARIO'">
                                            Pendiente
                                        </ng-container>
                                        <ng-container *ngIf="element.analisis  === 'ERROR'">
                                            <!-- Si el estado es error, mostrar el modal -->
                                            <button mat-icon-button style="width: 25px !important;" (click)="errorAnalyticModal(element.recording_id)">
                                                <span class="fi fi-sr-exclamation"></span>
                                            </button>
                                            <button mat-icon-button style="width: 25px !important;" (click)="confirmReprocess(element)">
                                                <span class="fi-sr-time-past"></span>
                                            </button>
                                        </ng-container>
                                        <ng-container *ngIf="element.analisis !== 'PENDIENTE' && element.analisis !== 'ERROR' && element.analisis !== 'PRIORITARIO'">
                                            <!-- Si el estado no es pendiente, mostrar el botón de búsqueda -->
                                            <button mat-icon-button style="width: 25px !important;" (click)="openAnalyticsModal(element.recording_id)">
                                            <span class="fi-rr-search"></span>
                                            </button>
                                        </ng-container>
                                    </td>
                                </ng-container>
    
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsRecordings"></tr>
                            <tr style="background: white" mat-row *matRowDef="let row; columns: displayedColumnsRecordings;"></tr>
                            <tr class="mat-row" style="background: white" *matNoDataRow>
                                <td class="mat-cell" [attr.colspan]="displayedColumnsRecordings.length">No existe información relacionada</td>
                            </tr>
                        </table>
    
                        <mat-paginator (page)="changePage($event, 'analisis')" [length]="configPaginatorRecording.length"
                            [pageSize]="configPaginatorRecording.pageSize"
                            [pageSizeOptions]="pageSizeOptions">
                        </mat-paginator>
                        
                    </div>
                </div>
    
                <div class="row mt-5">
                    <div class="col-4 mt-3" *ngIf="isAnalisisColumnVisible">
                        <button class="w-100" mat-raised-button color="primary" (click)="analytics_emociones()">Análisis de sentimientos masivo </button>
                    </div>
                    <div class="col-4 mt-3" *ngIf="!isAnalisisColumnVisible">
                        <button class="w-100"  mat-raised-button color="primary" (click)="analytics()">Analizar grabaciones &nbsp; <i class="fi-rr-chart-histogram"></i> </button>
                    </div>
                    <div class="col-4 mt-3">
                        <button class="w-100"  mat-raised-button color="primary" (click)="applyStrategy(stepperNoDirigido)" [disabled]="!ColumnVisibleTotal">Aplicar estrategia &nbsp; <i class=" fi-rr-forward "></i></button>
                    </div>
                </div>
    
            </mat-step>
            <!-- STEP ANALIZAR DE GRABACIONES -->
            <!-- STEP APLICAR STRATEGIA -->
            <mat-step>
                <ng-template matStepLabel>Aplicar estrategia</ng-template>
                <div class="row mt-4">
                    <div class="col-12">
                        <h3>Aplicar estrategias</h3>
                        <hr/>
                    </div>
                    <div class="col-9 mt-3">
                        <p>En esta sección, podrás seleccionar las grabaciones que fueron analizadas y aplicar o crear una estrategia para estas.</p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-3">
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Estrategias</mat-label>
                            <mat-select [formControl]="strategyControl" required>
                                <ng-container *ngFor="let strategy of listStrategys">
                                    <mat-option [value]="strategy.id" [matTooltip]="strategy.strategy_name">{{ strategy.strategy_name }}</mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-error *ngIf="strategyControl.hasError('required')">La estrategia aplicar es requerida</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <button class="w-100 heaight-buttons"  mat-raised-button color="primary" (click)="createStrategyTranscription()" >Crear estrategia con transcripción &nbsp; <i class="fi-rr-plus"></i></button>
                    </div>
                    <div class="col-2"></div>
                    <div class="col-3">
                        <button class="w-100 heaight-buttons" mat-flat-button color="primary" (click)="downloadReport('strategy')" [disabled]="!startegyComplete" >
                            Descargar informe <i class="fi-rr-download"></i>
                        </button>
                    </div>
                </div>
    
                <div class="table-container mt-5">
                </div>
                <div class="mat-elevation-z1">
                    <table mat-table [dataSource]="dataSourceStrategy" matSort #sortStrategy="matSort">
                        <ng-container *ngFor="let column of displayedColumnsStrategy ">
                            <ng-container [matColumnDef]="column" *ngIf="column !== 'checbox' && column !== 'audio' && column !== 'adherencia' && column !== 'analisis'  ">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                                <td mat-cell *matCellDef="let element"> {{ element[column] }} </td>
                            </ng-container>
                            <ng-container [matColumnDef]="column" *ngIf="column === 'checbox'">
                                <th mat-header-cell *matHeaderCellDef> 
                                    <mat-checkbox (change)="$event ? selectAllStrategy() : null"
                                    [checked]="selectionStrategy.hasValue() && isAllSelectedStrategy()" 
                                    [indeterminate]="selectionStrategy.hasValue() && !isAllSelectedStrategy()"
                                    ></mat-checkbox>
                                </th>
                                <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element">
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selectionStrategy.toggle(element) : null"
                                        [checked]="selectionStrategy.isSelected(element)" ></mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container [matColumnDef]="column" *ngIf="column === 'analisis'">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                                <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element">
                                    <ng-container *ngIf="element.analisis  === 'PENDIENTE' || element.analisis === 'PRIORITARIO'">
                                        Pendiente
                                    </ng-container>
                                    <ng-container *ngIf="element.analisis  === 'ERROR'">
                                        <!-- Si el estado es error, mostrar el modal -->
                                        <button mat-icon-button style="width: 25px !important;" (click)="errorAnalyticModal(element.recording_id)">
                                        <span class="fi fi-sr-exclamation"></span>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="element.analisis !== 'PENDIENTE' && element.analisis !== 'ERROR' && element.analisis !== 'PRIORITARIO'">
                                        <!-- Si el estado no es pendiente, mostrar el botón de búsqueda -->
                                        <button mat-icon-button style="width: 25px !important;" (click)="openAnalyticsModal(element.recording_id)">
                                        <span class="fi-rr-search"></span>
                                        </button>
                                    </ng-container>
                                </td>
                            </ng-container>
                            <ng-container [matColumnDef]="column" *ngIf="column === 'audio'">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                                <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element" >
                                <button  mat-icon-button style="width: 25px !important;" (click)="openAudioModal(element.recording_id)">
                                    <span class="fi-rr-megaphone"></span>
                                </button>
                                </td>
                            </ng-container>    
    
                            <ng-container [matColumnDef]="column" *ngIf="column === 'adherencia'">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ tHeadersRecordings[column] }}</th>
                                <td mat-cell *matCellDef="let element" (click)="AdherenciaModal(element.recording_id)">
                                    <span *ngIf="element.adherencia">{{ element[column] }}</span>
                                    <span *ngIf="!element.adherencia">No Aplica</span>
                                </td>
                            </ng-container>
    
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsStrategy"></tr>
                        <tr style="background: white" mat-row *matRowDef="let row; columns: displayedColumnsStrategy;"></tr>
                        <tr class="mat-row" style="background: white" *matNoDataRow>
                            <td class="mat-cell" [attr.colspan]="displayedColumnsStrategy.length">No existe información relacionada</td>
                        </tr>
                    </table>
    
                    <mat-paginator (page)="changePage($event, 'strategy')" [length]="configPaginatorStrategy.length"
                        [pageSize]="configPaginatorStrategy.pageSize"
                        [pageSizeOptions]="pageSizeOptions">
                    </mat-paginator>
                    
                </div>
                <div class="row mt-5">
                    <div class="col-4 mt-3" >
                        <button class="w-100"  mat-raised-button color="primary" (click)="applyStrategySelected()" [disabled]="getTotalRecordingsForStrategy <=0 && strategyControl.invalid || !strategyControl.value" >Aplicar estrategia</button>
                    </div>
                </div>
            </mat-step>

            <!-- STEP APLICAR STRATEGIA -->
    
        </mat-vertical-stepper>
        
    </div>

</div>
