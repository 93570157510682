import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DetalleRetroalimentacionComponent } from '../detalle-retroalimentacion/detalle-retroalimentacion.component';
import {TranslatesService} from '../../../../../../services/translates.service';

@Component({
  selector: 'app-tabla-retroalimentar',
  templateUrl: './tabla-retroalimentar.component.html',
  styleUrls: ['./tabla-retroalimentar.component.sass']
})
export class TablaRetroalimentarComponent implements OnInit {
  private uuid = 'b46ef9b8-92bb-11ee-b9d1-0242ac120002';
  public config: any;

  @Input() dataSource = [];
  displayedColumns:string[] = ['acciones','id','tipo_auditoria','fecha_auditoria','campaña','cedula_auditado','nombre_auditado','ucid'];

  @Input()length;
  @Input()pageSize = 5;
  @Input() page = 1;
  pageSizeOptions = [5,10,25,50,100];
  @Output() paginacion = new EventEmitter();
  campania:string = '';
  constructor(
    private dialog:MatDialog,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
  }

  detailRetroAlimentacion(id){
    const retroalimentacionRef = this.dialog.open(DetalleRetroalimentacionComponent,{
      width:'1056px',
      disableClose:true,
      data: id,
    });
    retroalimentacionRef.afterClosed().subscribe(()=>{

    });
  }

  pageEvent(event:any){
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.paginacion.emit({
      pagina: this.page,
      registros: this.pageSize,
    });
  }

  get campaign(){
    let campaignMatriz;
    this.dataSource.forEach(element => {
      campaignMatriz = element.campaign;
    });
    if (campaignMatriz.length == 0) {
      return false
    }
    this.campania = campaignMatriz.name;
    return true;
  }

}
