import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { NoveltiesService } from 'src/app/modules/nomina/services/novelties.service';
import { delay } from 'rxjs/operators';
import { VacationsService } from 'src/app/modules/nomina/services/rest/vacations.service';
import Swal from 'sweetalert2';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-cancel-vacations',
  templateUrl: './cancel-vacations.component.html',
  styleUrls: ['./cancel-vacations.component.sass']
})
export class CancelVacationsComponent implements OnInit {

  public currentDay: Date | string;
  public startDay: Date | string;
  public initDay: Date;
  public endDay: Date | string;
  public minDay: Date | string;
  public maxDay: Date | string;
  public vacationForm: FormGroup;
  public showTotalDays: boolean;
  public totalDays: number;
  public cancelTotalDays: number;
  public availableDays: any;
  public businessDays; any;
  public dataInfo: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CancelVacationsComponent>,
    private noveltiesService: NoveltiesService,
    private vacationService: VacationsService,
    private alert: AlertsService
  ){
    }

  ngOnInit() {
    this.startVariables();
    this.getCalendarWithAvailableDays();

    this.vacationForm.controls.startDay.valueChanges.subscribe(resp => {
      this.startDay = moment(this.vacationForm.controls.startDay.value).locale('es').format('YYYY-MM-DD');
      this.betwenDays();
    });
  }

  /**
   * @author Johan Soler 
   * @createDate 15-Jun-2022
   * Metodo para inicializar las variables del componente
   */
  startVariables() {
    this.vacationService.getInfoVacationId(this.data.idHoliday).subscribe(resp => {
      this.dataInfo = resp.data;

    })
    
    this.showTotalDays = false;
    this.totalDays = 0;
    this.cancelTotalDays = 0;
    this.currentDay = moment(new Date()).locale('es').format('YYYY-MM-DD');
    this.startDay = new Date();
    this.vacationForm = new FormGroup({
      startDay: new FormControl('', [Validators.required]),
      justification: new FormControl('', [Validators.required])
    })
    
    if(this.currentDay <= this.data.fechaSolicitud){
      this.minDay = moment(this.data.fechaSolicitud).locale('es').format('YYYY-MM-DD');
    }else{
      this.minDay = moment(this.currentDay).add(1,'d').locale('es').format('YYYY-MM-DD');
    }
    this.maxDay= moment(this.data.fechaFinSolicitud).locale('es').format('YYYY-MM-DD');
    this.endDay = moment(this.data.fechaFinSolicitud).add(1,'d').locale('es').format('YYYY-MM-DD');
    this.initDay = this.data.fechaSolicitud;
  }
  /**
   * @author Johan Soler 
   * @createDate 15-Jun-2022
   * Metodo para calcular los dias existentes entre las dos fechas
   */
  betwenDays() {
    const init = moment(this.initDay);
    const end = moment(this.endDay);
    
    this.noveltiesService.getAvailableDaysForVacations({
      startDate: moment(this.data.fechaSolicitud).locale('es').format('YYYY-MM-DD'),
      endDate: moment(this.data.fechaFinSolicitud).locale('es').format('YYYY-MM-DD')
    }).subscribe(resp => {
      this.totalDays = resp.data.businessDays
    })

    this.noveltiesService.getAvailableDaysForVacations({
      startDate: moment(this.startDay).locale('es').format('YYYY-MM-DD'),
      endDate: moment(this.maxDay).locale('es').format('YYYY-MM-DD')
    }).subscribe(resp => {
      this.cancelTotalDays = resp.data.businessDays
    })
    this.showTotalDays = true;
  }

  /**
 * @author Johan Soler 
 * @createDate 15-Jun-2022
 * Metodo para enviar los datos al back para registrar la cancelacion
 */
  cancelVacation() {
    let str = '¿En verdad deseas <b>Interrumpir</b> las vacaciones del ' + '\n' 
    + 'trabajador?, ten en cuenta que <b>los días no' + '\n' 
    + 'tomados se le devolveran como días disponibles</b>' + '\n' 
    + 'al trabajador para que pueda solicitar de nuevo' + '\n' 
    + 'vacaciones. Recuerda que esta acción será' + '\n' 
    + 'definitiva y no se podrá deshacer. ';

    Swal.fire({
      title: '¿Estás seguro?',
      html: '<pre style="font-family: Poppins; font-size: 14px;">' + str + '</pre>',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          days_not_taken: this.cancelTotalDays,
          days_taken: this.totalDays - this.cancelTotalDays,
          date_start: this.startDay,
          date_end: moment(this.data.fechaFinSolicitud).locale('es').format('YYYY-MM-DD'),
          justify: this.vacationForm.controls['justification'].value
        }
        this.vacationService.cancelVacation(this.data.idHoliday,body).subscribe(resp => {
          if (resp) {
            this.alert.alertSuccessWithoutButton('Excelente', 'Se han <b>interrumpido</b> las vacaciones del'+ '\n' + 'trabajador con exito');
            this.dialogRef.close('');
          }
        }) 

      }
    });
  }

  /**
  * @author Johan soler
  * @createdate 2022-06-17
  * Metodo para obtener los dias disponibles y los solicitados
  */
  getCalendarWithAvailableDays(): void {
    let startDate = this.data?.fechaSolicitud;
    let endDate = this.data?.fechaFinSolicitud;
    this.noveltiesService.getAvailableDaysForVacationsByUser({ startDate: startDate, endDate: endDate, rrhh_id: this.data?.rrhh_id }).pipe(delay(1000)).subscribe((respDates) => {
      if (respDates.data.totalDaysVacations > 0) {
        this.availableDays = respDates.data.totalDaysVacations;
      } else {
        this.availableDays = respDates.data.availableDays < 0 ? 0 : respDates.data.availableDays;
      }
      this.businessDays = respDates.data.businessDays;      
    });
  }
}
