<div class="row col-md-12">
    <i class="fi-rr-cross-small" style="font-size: 32px; color: #353535; display: flex; justify-content: flex-end;" mat-icon-button (click)="cancelSave()"></i>
</div>

<div class="row">
    <div class="col-md-6">
        <app-strategy-modal [showCloseIcon]="false" [selectedChannel]="1"></app-strategy-modal>
    </div>
    <div class="col-md-6">

        <h3 mat-dialog-title> Resultados de análisis.</h3>
        <mat-divider role="separator" class="mat-divider mat-divider-horizontal" aria-orientation="horizontal"></mat-divider>

        <mat-dialog-content class="padding-mat-dialog-content" style="padding-bottom: 10px;">
            <div id="containerViewReport" class="avoid-page-break" #containerViewReport>

                <mat-expansion-panel #transcripcionPanel closed>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <p>Transcripción</p>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row" #scrollContainer>
                        <div class="col-md-12" style="padding-bottom: 10px;">
                            <mat-chip-list>
                                <mat-chip *ngFor="let palabra of palabrasClaveArray" class="chip"
                                    (click)="toggleHighlight(palabra)">
                                    {{ palabra }}
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                        <div class="col-md-12" *ngFor="let item of interactionsFormtat; let i = index">
                            <p style="font-size: 14px;">
                                <button class="btn btn-custom rounded-circle p-2"
                                    style="width: 26px; height: 26px; background-color: #2cabbc; border-color: #2cabbc; display: inline-flex; align-items: center;"
                                    mattooltip="Reproducir" (click)="logStartAndEnd(item.start, item.end)">
                                    <i class="fi-sr-play" style="color: white; font-size: 10px;"></i>
                                </button>
                                <strong style="vertical-align: middle;"> {{ item.speaker ? item.speaker : 'SPEAKER' }}</strong>
                                <span style="vertical-align: middle;"> ({{ item.start_format }} - {{ item.end_format}}): </span>
                                <span [innerHTML]="item.text">{{ item.text }}</span>
                            </p>
                        </div>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel #tiemposMuertosPanel closed>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <p>Tiempos muertos</p>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                        <div class="col col-md-12" style="padding-top: 20px;">
                            <p style="font-size: 14px;">"Dar clic en el segmento requerido sobre la barra, para ir al
                                audio"</p>
                            <div class="recta">
                                <div class="segmento" *ngFor="let segmento of tiemposMuertos"
                                    [ngStyle]="calcularPosicionSegmento(segmento)"
                                    (click)="logStartAndEnd(segmento.start, segmento.end)"></div>
                            </div>
                        </div>
                        <br>
                        <div class="col col-md-12" style="padding-top: 20px; font-size: 14px;">
                            <p>
                                <strong>Total tiempo audio:</strong> {{ audioDurationFormatted }}
                                <strong>Total de tiempo muerto:</strong> {{ deadTimeaudioDurationFormatted }}
                            </p>
                            <br>
                        </div>
                        <div class="col-md-12" style="padding-top: 20px;"
                            *ngFor="let tiempo of tiemposMuertosMayores; let i = index">
                            <p style="font-size: 14px;">
                                <button class="btn btn-custom rounded-circle p-2"
                                    style="width: 26px; height: 26px; background-color: #2cabbc; border-color: #2cabbc; display: inline-flex; align-items: center;"
                                    mattooltip="Reproducir" (click)="logStartAndEnd(tiempo.start, tiempo.end)">
                                    <i class="fi-sr-play" style="color: white; font-size: 10px;"></i>
                                </button>
                                <strong> Tiempo Muerto N°{{ i + 1 }}</strong> ({{ formatTiempo(tiempo) }}) - <strong>
                                    Duración:</strong> {{ calcularDuracion(tiempo.start, tiempo.end) }}
                            </p>
                        </div>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel #resumenPanel closed>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <p>Resumen e Identificación de Objetivo</p>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row" *ngIf="llmResponseObject">
                        <div class="col col-md-12">
                            <div style="font-size: 12px;">
                                <p *ngIf="llmResponseObject.resumen"><strong>Resumen: </strong> {{
                                    llmResponseObject.resumen }}</p>

                                <p
                                    *ngIf="llmResponseObject.palabras_clave && llmResponseObject.palabras_clave.length > 0">
                                    <strong>Palabras Clave: </strong>
                                    <span *ngFor="let palabra of llmResponseObject.palabras_clave">{{ palabra }},
                                    </span>
                                </p>

                                <p
                                    *ngIf="llmResponseObject.entidades_principales && llmResponseObject.entidades_principales.length > 0">
                                    <strong>Entidades principales: </strong>
                                    <span *ngFor="let entidad of llmResponseObject.entidades_principales">{{ entidad }},
                                    </span>
                                </p>

                                <p
                                    *ngIf="llmResponseObject.temas_principales && llmResponseObject.temas_principales.length > 0">
                                    <strong>Temas Principales: </strong>
                                    <span *ngFor="let tema of llmResponseObject.temas_principales">{{ tema }}, </span>
                                </p>

                                <p *ngIf="llmResponseObject.puntos_clave && llmResponseObject.puntos_clave.length > 0">
                                    <strong>Puntos Clave: </strong>
                                    <span *ngFor="let punto of llmResponseObject.puntos_clave">{{ punto }}, </span>
                                </p>
                                <p *ngIf="llmResponseObject.analisis_de_sentimientos"><strong>Análisis de sentimientos:
                                    </strong> {{ llmResponseObject.analisis_de_sentimientos }} </p>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>
        </mat-dialog-content>

        <div class="contenedor" style="text-align: center;">
            <audio #audioPlayer controls style="width: 100%; max-width: 700px;">
                <source [src]="audioUrl" type="audio/mpeg">
                Tu navegador no soporta el elemento de audio.
            </audio>
        </div>

    </div>
</div>