import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPhotoPipe } from './user-photo.pipe';
import { OrderByPipe } from './order-by.pipe';



@NgModule({
  declarations: [UserPhotoPipe, OrderByPipe],
  providers: [UserPhotoPipe, OrderByPipe],
  imports: [
    CommonModule
  ],
  exports:[
    UserPhotoPipe,
    OrderByPipe
  ]
})
export class PipesModule { }
