<div class="container-fluid p-5">
  <div class="row">
    <div class="col-md-12">
      <span style="font-size: 32px; font-weight: 700;">Speech to Text</span>
    </div>
    <div class="row mt-4">
      <div class="col-9 mt-3">
        <p class="lh-base" style="color: #353535">
          Este es el módulo de Speech to Text, donde podrás revisar el histórico de análisis de <br />
          audios y aplicación de estrategias. No olvides, “El respeto y empatía son clave”.
        </p>
      </div>

    </div>

  </div>
  <div class="row justify-content-between mt-5">
    <mat-tab-group mat-align-tabs="start">
      <mat-tab label="Historial de análisis">
        <app-search-history-module></app-search-history-module>
      </mat-tab> 
      <mat-tab label="Historial de análisis sin segmentar">
        <app-search-history-nodirigido></app-search-history-nodirigido>
      </mat-tab>
    </mat-tab-group>
  </div>

</div>