import { Component, OnInit } from '@angular/core';
import { ReportsService } from 'src/app/modules/nomina/services/rest/reports.service';
import {TranslatesService} from '../../services/translates.service';

@Component({
  selector: 'app-powerbi',
  templateUrl: './powerbi.component.html',
  styleUrls: ['./powerbi.component.sass']
})
export class PowerbiComponent implements OnInit {
  private uuid = '8f87d0e0-92d3-11ee-b9d1-0242ac120006';
  public config: any;

  public token: string='';
  public report_id:string='';
  public generar = false;

  constructor(
    private reportService:ReportsService,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
    this.reportService.getReport('11_DBS_CAL_PBI').subscribe((resp)=>{
      if(resp.data.token !== ''){
        this.token = resp.data.token;
        this.report_id =resp.data.reporte_id;
        this.generar=true;
      }
    });
  }

}
