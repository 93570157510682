import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse,  HttpParams, } from '@angular/common/http';

const AGENDAMIENTOS_URL = environment.AGENDAMIENTOS_URL;

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  constructor(private http: HttpClient) { }
  getResources(direction, entries){
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get<any>(`${AGENDAMIENTOS_URL}${direction}`, {params} );
  }


  /**
   * Consulta las reservas realizadas por medio del crm
   * @returns
   */
  getCrmResources(formId:number){
    const params = {}
    return this.http.get<any>(`${AGENDAMIENTOS_URL}booking/crm/${formId}`, params );
  }


  /**
   * Consulta las reservas realizadas por medio del crm
   * @returns
   */
  getCrmResourcesCrm2(formId:number){
    const params = {}
    return this.http.get<any>(`${AGENDAMIENTOS_URL}booking/crm2/${formId}`, params );
  }

  createResources(form){
    return this.http.post<any>(`${AGENDAMIENTOS_URL}resources`, form);
  }

  updateResources(id, form){
    return this.http.put<any>(`${AGENDAMIENTOS_URL}resources/${id}`, form);
  }

  removeResources(id){
    return this.http.delete<any>(`${AGENDAMIENTOS_URL}resources/${id}`);
  }

  getResourceTypes(all:boolean = false){
    let allRec = all ? "?idForm=all" : "";
    return this.http.get<any>(`${AGENDAMIENTOS_URL}resourcetypes${allRec}`);
  }

  createResourceTypes(form){
    return this.http.post<any>(`${AGENDAMIENTOS_URL}resourcetypes`, form);
  }

  updateResourceTypes(id, form){
    return this.http.put<any>(`${AGENDAMIENTOS_URL}resourcetypes/${id}`, form);
  }

  getResourceRules(id){
    return this.http.get<any>(`${AGENDAMIENTOS_URL}resource/rules/${id}`);
  }

  createResourceRules(form){
    return this.http.post<any>(`${AGENDAMIENTOS_URL}resource/rules`, form);
  }

  removeResourceRules(id){
    return this.http.delete<any>(`${AGENDAMIENTOS_URL}resource/rules/${id}`);
  }
}
