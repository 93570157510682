import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { TrainingGroupsService } from '../../../services/training-groups.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { commentsGroup, retreatGroup } from '../training-candidate/commentsTypes';
import * as moment from 'moment';
import * as tz from 'moment-timezone';

@Component({
  selector: 'app-candidate-actions',
  templateUrl: './candidate-actions.component.html',
  styleUrls: ['./candidate-actions.component.sass']
})
export class CandidateActionsComponent implements OnInit {

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  types_retreat=retreatGroup
  candidates:any = [];
  validGroups:any = [];

  candidatesSelected:any = [];

  allCandidateSelected = false;

  actionsAvailable = [
    {
      key:'schedule',
      name:'Agendamiento de Firma',
      description: 'Agendamiento de firma de contrato'
    },
    {
      key:'comment',
      name:'Comentario, Asistencia, Etc...',
      description: 'Comentario, Asistencia, Etc...'
    },
    {
      key:'reassignment',
      name:'Reasignación de grupo',
      description: 'Asignar candidatos a otro grupo'
    }
  ]
  types_comments= commentsGroup
  scheduleDate;
  initialOperation = new FormControl();
  operationDate:any;

  formComment:FormGroup;
  action = new FormControl(null,Validators.required)
  actionAux:string
  description: string
  nameAux: string

  formReassignment = new FormControl(null)

  stepAction = false;
  stepCandidates = false;

  constructor(private _fb: FormBuilder,
              private trainingGroup:TrainingGroupsService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private alertNotification:AlertsService,
              public dialog: MatDialogRef<CandidateActionsComponent>,
              ) {

              }

  ngOnInit(): void {
    this.createCommentForm()
    this.getCandidateByGroup();
    this.initialOperation.valueChanges.subscribe(res => {
      if(res){
        this.operationDate = moment(res).tz('America/Bogota').format('YYYY-MM-DD')
      }
    });
  }

  getCandidateByGroup(){
    this.trainingGroup.candidatesByGroup(this.data.group.id).subscribe( (resp:any) => {
      this.candidates = resp;
    })
  }

  createCommentForm(){
    this.formComment = this._fb.group({
      'typeComment':[null],
      'comment':[null,Validators.required],
      'createdAt':[new Date().toISOString()]
    })
  }
  
   saveComment(){
      if(this.formComment.valid){
        let params = {
          'comment':this.formComment.value,
        }
      }
    }

  cancelForm(form,arg){
    this[form].reset();
    this[arg] = !this[arg]
  }
  checkSelect(event){
    this.actionAux = event.key
    this.description = event.description
    this.nameAux = event.name
    if(event.key == 'reassignment'){
      this.trainingGroup.getValidGroups(`formationgroups/excluded/${this.data.group.id}`).subscribe(
        res=>{
          this.validGroups = res
        })
    }
    
  }

  finishActions(){
    this.alertNotification.alertConfirm('Confirmar acción por lote a candidatos del grupo de formación').then(e => {
      if(e.isConfirmed){
        switch (this.action.value.key) {
          case 'schedule':
            let params1 = {
              'date':this.scheduleDate,
              'initialOperation':this.operationDate || '',
              'groupId':this.data.group.id,
              'stateKey':'contratacion',
              'candidateIds':this.candidatesSelected.map( e => e.id ).toString()
            }
            this.trainingGroup.scheduleDateToCandidates(params1).subscribe( (resp:any) => {
              this.alertNotification.alertSuccess('¡Hecho!',resp);
              this.dialog.close(true);
            })
            break;
          case 'comment':
            let params2 = {
              'comments':JSON.stringify(this.formComment.value),
              'groupId':this.data.group.id,
              'candidateIds':this.candidatesSelected.map( e => e.id ).toString()
            }
            this.trainingGroup.commentsMultiples(params2).subscribe( (resp:any) => {
              this.alertNotification.alertSuccess('¡Hecho!',resp);
              this.dialog.close(true);
            } )
            break;
          case 'reassignment':
            let params3 = {
              candidateIds:this.candidatesSelected.map( e => e.id ),
              groupIdOld:this.data.group.id,
              groupIdLast:this.formReassignment.value,
            }
            this.trainingGroup.postReassignment(`formation/reassign/candidates`, params3).subscribe( (resp:any) => {
              this.alertNotification.alertSuccess('¡Hecho!',resp);
              this.dialog.close(true);
            } )
            break;
        }
      }
    })


  }

  actionsControl(){
    this.stepAction = true;
  }

  saveCandidates(entries:any){
    this.candidatesSelected  = entries._value;

  }



}
