<button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<form mat-dialog-content class="containerDialog" [formGroup]="moduleForm">
    <h2>Crear módulo</h2>
    <mat-divider class="mb-3"></mat-divider>
    <mat-form-field appearance="fill">
        <mat-label>Nombre</mat-label>
        <input type="text" matInput formControlName="nombre" [matAutocomplete]="modulo" cdkFocusInitial>
        <mat-icon matSuffix><span class="fi-rr-search" (click)="getModules()"></span></mat-icon>
        <mat-autocomplete #modulo="matAutocomplete" [displayWith]="displayFn" (optionSelected)="newModule()">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.nombre">
                {{option.nombre}}
            </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="verError">No se encontraron coincidencias con el nombre que acabas de ingresar</mat-error>
    </mat-form-field>
    <button mat-button class="nuevo" *ngIf="nuevo" (click)="newModule()">Nuevo módulo <mat-icon matSuffix><i class="fi-rr-add"></i></mat-icon></button>
    <mat-form-field appearance="fill" [hidden]="!peso">
        <mat-label>Peso</mat-label>
        <input type="number" matInput formControlName="peso">
        <mat-error *ngIf="moduleForm.get('peso').hasError('required')">Por favor ingrese un peso.</mat-error>
        <mat-error *ngIf="moduleForm.get('peso').hasError('min')">Por favor ingresar un peso superior a {{ this.datos.minimo }} .</mat-error>
        <mat-error *ngIf="moduleForm.get('peso').hasError('max')">Por favor ingresar un peso menor a {{ this.datos.maximo }} .</mat-error>
    </mat-form-field>
    <mat-checkbox formControlName="error_critico" (change)="habilitaCheck($event)" >¿Es módulo crítico?</mat-checkbox>
    <br>
    <mat-checkbox formControlName="is_total" (change)="habilitarPeso($event)" >¿Es afectación total?</mat-checkbox>

    <div class="btns-dialog" style="padding-right: 3em;">
        <button mat-stroked-button (click)="moduleRef.close()" [disabled]="continue">Cancelar</button>
        <button mat-raised-button color="primary" (click)="saveModule()" [disabled]="continue">Crear</button>
    </div>
</form>