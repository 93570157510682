import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';
import { empty, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';

@Injectable({
  providedIn: 'root'
})
export class OportunidadesService {
  CRMURL = environment.CRM_CIAL;


  constructor(private http: HttpClient, private authService: AuthService) {

  }
  /**
  * @author Javier Castañeda
  * Captura errores en las peticiones
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * @author Javier Castañeda
   * Servicio para consultar las oportunidades por cliente y filtros
   * @param {number} id del cliente
   * @param {json} filters json
   * @returns json
   */
  getOportunitiesBy(id: number, filters: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('tap',filters.tap || '')
        .set('state', filters.state || '')
        .set('step', filters.step || '')
        .set('company', filters.company || '')
        .set('comercial', filters.comercial || '')
        .set('service_line', filters.service_line || '')
        .set('name_oportunity', filters.name_oportunity || '')
        .set('perPage', filters.perPage || 8)
        .set('page', filters.page || 1)
    };
    return this.http.get<any>(`${this.CRMURL}oportunities/client/${id}`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Javier Castañeda
   * Servicio para consultar las oportunidades por cliente y filtros
   * @param {json} filters json
   * @returns json
   */
  getOportunitiesHistoryBy(filters: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('tap', filters.tap)
        .set('client', filters.client || '')
        .set('service_line', filters.service_line || '')
        .set('comercial', filters.comercial || '')
        .set('document', filters.document || '')
        .set('id_type', filters.id_type || '')
        .set('state', filters.state || '')
        .set('channel', filters.channel || '')
        .set('step', filters.step || '')
        .set('perPage', filters.perPage || 5)
        .set('page', filters.page || 1)
    };
    return this.http.get<any>(`${this.CRMURL}oportunities`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Javier Castañeda
   * Servicio para consultar las oportunidades por cliente y filtros
   * @param {json} filters json
   * @returns json
   */
  getOportunitiesHistoryReportBy(filters: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('tap', filters.tap)
        .set('client', filters.client || '')
        .set('service_line', filters.service_line || '')
        .set('comercial', filters.comercial || '')
        .set('document', filters.document || '')
        .set('id_type', filters.id_type || '')
        .set('step', filters.step || '')
        .set('state', filters.state || ''),
        responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${this.CRMURL}oportunities/report`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Javier Castañeda
   * Servicio para consultar una oportunidad por id
   * @param {number} id id de la  oportunidad
   * @returns json
   */
  getOportunitiesById(id: number) {
    return this.http.get<any>(`${this.CRMURL}oportunity/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Javier Castañeda
   * Servicio para eliminar un archivo ya cargado
   * @param {number} id id del archivo
   * @returns json
   */
  deleteFileById(id: number) {
    return this.http.delete<any>(`${this.CRMURL}oportunity/files/delete/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Javier Castañeda
   * Servicio para eliminar una oportunidad por id
   * @param {number} id id de la oportunidad
   * @returns json
   */
  deleteOpportunityById(id: number) {
    return this.http.delete<any>(`${this.CRMURL}oportunity/delete/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Gabriel García
   * Get the channels list
   * @returns json
   */
  getChannels(): Observable<any> {
    return this.http.get<any>(`${this.CRMURL}getChannel`)
      .pipe(
        catchError(this.handleError)
      );
  }

}
