import { Component,  Inject,  OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { FormsRequestService } from 'src/app/modules/crm/services/rest/forms-request.service';
import { TemplatesService } from 'src/app/modules/crm/services/rest/templates.service';

@Component({
  selector: 'app-save-template',
  templateUrl: './save-template.component.html',
  styleUrls: ['./save-template.component.sass']
})

export class SaveTemplateComponent implements OnInit {
  firstStep: FormGroup;
  secondStep: FormGroup;
  thirdStep: FormGroup;
  template: any[] = [];
  form: any;

  constructor(private _formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public datos: any,
              public formsRequestService: FormsRequestService,
              private destroyService: DestroyComponentService) {
                this.secondStep = this._formBuilder.group({});
    }

  ngOnInit() {

    this.firstStep = this._formBuilder.group({
      nombre: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(3)]),
      caracter: new FormControl('', [Validators.required, Validators.maxLength(1), Validators.minLength(1)]),
    });

    this.secondStep = this._formBuilder.group({});

    this.formsRequestService.getForm(`searchform/${this.datos.idForm}`, 1000, 1).subscribe(res => {

      this.form = res.section;

      this.form.forEach(sections => {
        sections.fields.forEach(element => {
          this.secondStep.addControl(element.key, new FormControl(''));
          this.secondStep.addControl(element.key + 'radio', new FormControl(''));
          this.secondStep.addControl(element.key + 'check', new FormControl(''));
        });
      });

    });

    this.thirdStep = this._formBuilder.group({
      fields: this._formBuilder.array([])
    });

    this.newFields();
  }

  get errorForm1(): any { return this.firstStep.controls; }

  get fields(): FormArray {
    return this.thirdStep.get('fields') as FormArray;
  }

  newFields(): any {
    const newField = this._formBuilder.group({
      name: new FormControl(''),
      caracter: new FormControl(''),
      option: new FormControl('')
    });
    this.fields.push(newField);
  }

  deleteField(indice: number): void {
    this.fields.removeAt(indice);
  }

  omitSpecialChar(event): any {
    let k;
    k = event.charCode;
    return((k > 32 && k < 48 || k > 57 && k < 65 || k > 90 && k < 97 || k > 122 && k < 127));
  }

  guardar(): void {

    /* const template = {

    } */

    console.log('form1', this.firstStep.getRawValue());
    console.log('form2', this.secondStep.getRawValue());
    console.log('form3', this.thirdStep.getRawValue());
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

}