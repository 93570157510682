<button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<form mat-dialog-content class="containerDialog" [formGroup]="subItemForm">
    <h2>{{config.labels.editSubItem}}</h2>
    <mat-divider class="mb-3"></mat-divider>
    <mat-form-field appearance="fill">
        <mat-label>{{config.labels.name}}</mat-label>
        <input  matInput formControlName="nombre" [matAutocomplete]="auto">
        <mat-icon matSuffix><span class="fi-rr-search" (click)="getSubItems()"></span></mat-icon>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" 
        (optionSelected)="newSubItem()">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.nombre">
              {{option.nombre}}
            </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="verError">{{config.labels.noResults}}</mat-error>
        <mat-error *ngIf="subItemForm.get('nombre').hasError('required')">{{config.labels.requiredName}}</mat-error>
    </mat-form-field>
    <button mat-button class="nuevo" *ngIf="nuevo" (click)="newSubItem()">{{config.labels.newSubItem}} <mat-icon matSuffix><i class="fi-rr-add"></i></mat-icon></button>
    <mat-form-field appearance="fill" *ngIf="descripcion">
        <mat-label>{{config.labels.description}}</mat-label>
        <textarea  matInput formControlName="descripcion"></textarea>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="mostarDescripciones">
        <mat-label>{{config.labels.positiveAspect}}</mat-label>
        <textarea  matInput formControlName="aspectoPositivo"  maxlength="101"></textarea>
        <mat-error *ngIf="subItemForm.get('aspectoPositivo').hasError('maxlength')">{{config.labels.maxLengthExceeded}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="mostarDescripciones" maxlength="101">
        <mat-label>{{config.labels.negativeAspect}}</mat-label>
        <textarea  matInput formControlName="aspectoNegativo" maxlength="101" ></textarea>
        <mat-error *ngIf="subItemForm.get('aspectoNegativo').hasError('maxlength')">{{config.labels.maxLengthExceeded}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="mostarDescripciones">
        <mat-label>{{config.labels.naAspect}}</mat-label>
        <textarea  matInput formControlName="aspectoNa" maxlength="101"></textarea>
        <mat-error *ngIf="subItemForm.get('aspectoNa').hasError('maxlength')">{{config.labels.maxLengthExceeded}}</mat-error>
    </mat-form-field>

    <div class="btns-dialog">
        <button mat-stroked-button (click)="subItemRef.close()" [disabled]="!continue">{{config.labels.cancel}}</button>
        <button mat-raised-button color="primary" (click)="updateSubItem()" [disabled]="!continue">{{config.labels.save}}</button>
    </div>
</form>
