export const retreatGroup = [
    {
      name: 'Retiros día 0',
      types: [
        {viewValue: 'No se Conecta'},
        {viewValue: 'Duración de la Capacitación'},
        {viewValue: 'Horarios Campaña'},
        {viewValue: 'Tipo de Contrato'},
        {viewValue: 'Pago Capacitación Inicial'},
        {viewValue: 'Salud'},
        {viewValue: 'Temas Familiares'},
        {viewValue: 'Cuidado de los Hijos'},
        {viewValue: 'Estudio'},
        {viewValue: 'Viaje'},
        {viewValue: 'Mejor Oferta Laboral'},
        {viewValue: 'No tienen equipo de computo '},
        {viewValue: 'Internet'},
        {viewValue: 'Doble proceso Selección'},
        {viewValue: 'Oferta Comision'},
        {viewValue: 'Imagen corporativa'},
        {viewValue: 'Modalidad Trabajo'},
        {viewValue: 'Reubicacion entre Campañas'},
        {viewValue: 'Comunicación Colaborador'}
      ]
    },
    {
      name: 'Retiros COS',
      types: [
        {viewValue: 'Ausencias '},
        {viewValue: 'Pierde Producto'},
        {viewValue: 'Políticas de Seguridad'},
        {viewValue: 'Documentación'},
        {viewValue: 'Previnculacón CLARO'},
        {viewValue: 'Adapatación al Proceso'}
      ]
    },
    {
      name: 'Retiros Voluntarios',
      types: [
        {viewValue: 'Salud'},
        {viewValue: 'Temas Familiares'},
        {viewValue: 'Cuidado de los Hijos'},
        {viewValue: 'Duración de la Capacitación'},
        {viewValue: 'Tipo de Contrato'},
        {viewValue: 'Pago Capacitación Inicial'},
        {viewValue: 'Oferta de Selección'},
        {viewValue: 'Horarios Campaña'},
        {viewValue: 'Estudio'},
        {viewValue: 'Viaje'},
        {viewValue: 'Mejor Oferta Laboral'}
      ]
    },
    {
      name: 'Retiros COVID',
      types: [
        {viewValue: 'Cuidadi de los hijos'},
        {viewValue: 'Se daña equipo de computo '},
        {viewValue: 'No tienen equipo de computo '},
        {viewValue: 'Internet'},
        {viewValue: 'No esta de acuerdo con el proceso de OJT presencial'},
      ]
    },
    {
        name: 'Retiros OJT',
        types: [
          {viewValue: 'Abandono'},
          {viewValue: 'No Aprueba Proceso'},
          {viewValue: 'Políticas de Seguridad'},
          {viewValue: 'Cuidado de los Hijos'},
          {viewValue: 'Código de Ética y Conducta'},
          {viewValue: 'Salud'},
          {viewValue: 'Temas Familiares'},
          {viewValue: 'Problemas Nómina'},
          {viewValue: 'Pago Capacitación  Inicial'},
          {viewValue: 'Horarrios Campaña'},
          {viewValue: 'Estudio'},
          {viewValue: 'Viaje'},
          {viewValue: 'Mejor Oferta Laboral'},
          {viewValue: 'Herramientas de Trabajo'},
          {viewValue: 'Adaptabilidad'}
        ]
      }
  ];

export const commentsGroup=[
    {
      name:'Asistencia',
    },
    {
      name:'Falla',
    },
    {
      name:'Retiro',
    },
    {
      name:'Tarde',
    },
    {
      name:'Incapacidad',
    },
    {
      name:'Migrado',
    },
  ]

export const statusGroup=[
    {
      name:'Viabilidad',
    },
    {
      name:'Alta en SSFF',
    },
    {
      name:'Previnculación',
    },
    {
      name:'Vinculación',
    },
    {
      name:'Validación Usuarios',
    },
    {
      name:'Entrega de Actas Parciales',
    },
    {
      name:'Depuración de Usuarios',
    },
    {
      name:'Entregado',
    }
  ]