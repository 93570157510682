import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanDeactivate, Router } from '@angular/router';
;
import { MatDialog } from '@angular/material/dialog';


@Injectable({
    providedIn: 'root'
})
export class SurverysGuard implements CanActivateChild {

    constructor(
        private dialog: MatDialog) { }

        canActivateChild():boolean {
            const dialogRef = this.dialog.getDialogById('dialog-surveys')
        
        if (dialogRef ) {
            return false;
        } else {
            return true;
        }
    }

   

}  