import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { loadSelects } from '../../state/actions/selects.actions';

@Component({
  selector: 'app-reporte',
  templateUrl: './reporte.component.html',
  styleUrls: ['./reporte.component.sass']
})
export class ReporteComponent implements OnInit {

  rol;
  constructor(
    private store:Store,
    private  authService:AuthService
  ) { }

  ngOnInit(): void {
    this.getRoles();
    this.store.dispatch(loadSelects({rol:this.rol}));
  }

  getRoles(){
    this.authService.getUser().roles.forEach(rol => {
      if (rol.includes('coaching::')) {     
        this.rol = rol;
      }
    });
  }

}
