import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const NOTIFICATION_URL = environment.NOTIFICATION_URL;
const COACHING_URL = environment.COACHING_URL;
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _refresh$ = new Subject<void>();
  constructor(
    private http:HttpClient,
  ) { }

  get refresh$(){
    return this._refresh$;
  }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  getNotifications(id){
    return this.http.get(`${NOTIFICATION_URL}getNotification/${id}`)
    .pipe(
      catchError(this.handleError)
    );
  }

  changeStatus(idNotification){
    return this.http.post<any>(`${NOTIFICATION_URL}updateNotification/${idNotification}`,{})
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

  deleteNotificationById(id){
    return this.http.post<any>(`${NOTIFICATION_URL}deleteNotification/${id}`,{})
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }


  createhistorial(id:number){
    return this.http.post<any>(`${COACHING_URL}notificacionCoaching/store`,{ id:id })
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

}
