import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { empty, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';




@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  CRMURL = environment.CRM_CIAL;
  COMPRAS_URL = environment.COMPRAS_URL;
  RRHH_URL = environment.RRHH_URL;

  constructor(private http: HttpClient, private authService: AuthService) {


  }
  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Captura de errores en las peticiones
   */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Servicio para busqueda de listado de emepresas
   * @returns json
   */
  getCompany(): Observable<any> {
    return this.http.get<any>(`${this.RRHH_URL}companies`)
  }

  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Servicio para busqueda de listado de canales
   * @returns json
   */
  getChannels(): Observable<any> {
    return this.http.get<any>(`${this.CRMURL}getChannel`)
  }

  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Servicio para creación de oportunidades
   * @param {json} body json
   * @returns json
   */
  saveOportunities(body): Observable<any> {
    return this.http.post<any>(`${this.CRMURL}saveOportunity`, body)
  }

  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Servicio para busqueda de listado de paises
   * @returns json
   */
  getPaises(tpais): Observable<Object> {
    let heades = new HttpHeaders().set('Type-content', 'aplication/json')
    return this.http.get(this.CRMURL + 'getCountry/' + tpais, {
      headers: heades
    });
  }

  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Servicio para busqueda de municipios por id del pais
   * @param {number} idpais id del pais
   * @returns json
   */
  getMunicipio(idpais: any): Observable<Object> {
    let heades = new HttpHeaders().set('Type-content', 'aplication/json')
    return this.http.get(this.CRMURL + 'municipio/' + idpais, {
      headers: heades
    });
  }

  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Servicio para busqueda de listado de clientes 
   * @returns json
   */
  getClientes(): Observable<Object> {
    let heades = new HttpHeaders().set('Type-content', 'aplication/json')
    return this.http.get(`${this.CRMURL}clientes`, {
      headers: heades
    });
  }

  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Servicio para busqueda de listado de clientes por filtros
   * @param {string} name nombre cliente 
   * @param {number} id_type id tipo de idetificación
   * @param {number} id_number numero de identificación
   * @returns json
   */
  getClientesBy(name, id_type, id_number): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders().set('Type-content', 'aplication/json'),
      params: new HttpParams()
        .set('name', name || '')
        .set('id_type', id_type || '')
        .set('id_number', id_number || '')
    };
    return this.http.get(`${this.CRMURL}getClientByFilters`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Servicio para busqueda de listado de usuarios con rol comercial
   * @returns json
   */
  getComercialsList(){
    return this.http.get(`${this.CRMURL}getComerciales`).pipe(
      catchError(this.handleError)
    );
  }
  /**
   * @author Javier Castañeda
   * @createdate 2022-10-29 
   * Servicio de consulta de listado de usuarios con el rol comercial
   * @param {id} id de la oportunidad a actualizar 
   * @param {body} body json | formdata  
   * @returns json
   */
  updateOpportunities(id: number, body: any) {
    return this.http.post(`${this.CRMURL}updateOPortunity/${id}`, body).pipe(
      catchError(this.handleError)
    );
  }
  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Servicio para busqueda de lista de contactos por id de cliente
   * @param {number} idClient id cliente
   * @returns json
   */
   getContactsByClient(idClient: number){
    return this.http.get(`${this.CRMURL}getContactByClient/${idClient}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Servicio para busqueda de unidades de facturación
   * @param {number} idOportunidad id oportunidad solo aplica para gestión
   * @returns json
   */
  getUnitFacturation(idOportunidad?) {
    const httpOptions = {
      headers: new HttpHeaders().set('Type-content', 'aplication/json'),
      params: new HttpParams()
        .set('id', idOportunidad || '')
    };
    return this.http.get(`${this.CRMURL}unitfacturation`, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Servicio para busqueda de lineas de servicio
   * @param {number} idOportunidad id de la oportunidad solo aplica para gestión
   * @returns json
   */
  getLineServices(idOportunidad?) {
    const httpOptions = {
      headers: new HttpHeaders().set('Type-content', 'aplication/json'),
      params: new HttpParams()
        .set('id', idOportunidad || '')
    };
    return this.http.get(`${this.CRMURL}serviceline`,httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Servicio para crear un cliente
   * @param {json} app json
   * @returns json
   */
  saveCliente(app: any): Observable<any> {
    return this.http.post<any>(`${this.CRMURL}storeClient`, app)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Servicio para actualizar los datos del cliente
   * @param {any} id id cliente
   * @param {json} app json
   * @returns json
   */
  updateCliente(id: any, app: any): Observable<any> {
    //let heades = new HttpHeaders().set('Type-content','application/x-www-form-urlencoded')
    return this.http.post<any>(`${this.CRMURL}updateClient/${id}`, app)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Servicio de cliente por id
   * @param {any} id id cliente
   * @returns json
   */
  searchId(id: any): Observable<any> {
    return this.http.get<any>(`${this.CRMURL}getClientToEdit/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Servicio de busqueda de contacto
   * @param {any} value id 
   * @returns json
   */
  searchContacto(value: any): Observable<any> {
    return this.http.get<any>(`${this.CRMURL}searchContacto/` + value)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Servicio de creación de contacto
   * @param {any} app id del contacto 
   * @returns json
   */
  saveContacto(app: any) {
    return this.http.post<any>(`${this.CRMURL}contact/create`, app).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * @author Javier Castañeda 
   * @createdate 2022-10-29
   * Servicio para borrar contacto comercial
   * @param {number} id id del contacto
   * @param {json} body json 
   * @returns json
   */
  deleteComercialContact(id: any, body: any = null) {
    return this.http.post<any>(`${this.CRMURL}contact/delete/${id}`, body).pipe(
      catchError(this.handleError)
    );
  }
}



