<div class="row">
  <div class="col-12">
    <p class="lh-base">{{ taskDescription }}</p>
  </div>
</div>
<div class="row mt-4">
  <div class="col-md-5">
    <div class="d-flex justify-content-between align-items-center">
      <div style="font-weight: 700;">{{ titleFirstList }}</div>
      <div>
        <mat-checkbox color="primary" [(ngModel)]="checkAllFirstList" (change)="selectAllItems($event, 'FIRST')">Todos</mat-checkbox>
      </div>
    </div>
    <mat-form-field class="w-100 mt-4" appearance="fill">
      <mat-label>{{ textInputFirstList }}</mat-label>
      <input type="text" matInput (input)="onChangeFilter($event, 'FIRST')">
      <mat-icon matSuffix><i class="fi-rr-search"></i></mat-icon>
    </mat-form-field>
    <div class="container-list">
      <mat-selection-list color="primary" (selectionChange)="onSelectionList($event, 'FIRST')" [(ngModel)]="selectedItemsFirstList">
        <mat-list-option *ngFor="let item of firstList" [value]="item"> {{ item.name }}
        </mat-list-option>
      </mat-selection-list>
    </div>
    <div class="mt-2" style="font-weight: 700;">Opciones seleccionadas: {{ selectedItemsFirstList.length }} </div>
  </div>
  <div class="d-flex align-items-center justify-content-center col-md-2">
    <div class="d-flex flex-column ">
      <button (click)="transferItemsBetweenList('FIRST')" style="height: 48px; font-weight: 700;" mat-raised-button
        color="primary">
        <span class="fi-rr-arrow-right"></span>
      </button>
      <button (click)="transferItemsBetweenList('SECOND')" class="mt-2" style="height: 48px; font-weight: 700;"
        mat-raised-button color="primary">
        <span class="fi-rr-arrow-left"></span>
      </button>
    </div>
  </div>
  <div class="col-md-5">
    <div class="d-flex justify-content-between align-items-center">
      <div style="font-weight: 700;">{{ titleSecondList }}</div>
      <div>
        <mat-checkbox color="primary" [(ngModel)]="checkAllSecondList" (change)="selectAllItems($event, 'SECOND')">Todos</mat-checkbox>
      </div>
    </div>
    <mat-form-field class="w-100 mt-4" appearance="fill">
      <mat-label>{{ textInputSecondList }}</mat-label>
      <input type="text" matInput (input)="onChangeFilter($event, 'SECOND')">
      <mat-icon matSuffix><i class="fi-rr-search"></i></mat-icon>
    </mat-form-field>
    <div class="container-list">
      <mat-selection-list color="primary" (selectionChange)="onSelectionList($event, 'SECOND')" [(ngModel)]="selectedItemsSecondList">
        <mat-list-option *ngFor="let item of secondList" [value]="item"> {{ item.name }}
        </mat-list-option>
      </mat-selection-list>
    </div>
    <div class="mt-2" style="font-weight: 700;">Opciones seleccionadas: {{ selectedItemsSecondList.length }}</div>
  </div>
</div>
<div class="row d-flex justify-content-end mt-5">
  <div class="col-md-3">
    <button (click)="onClickButtonCancel()" mat-dialog-close type="button" mat-raised-button class="w-100"
      style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Cancelar</button>
  </div>
  <div class="col-md-3">
    <button (click)="onClickButtonSave()" type="button" mat-raised-button color="primary" class="w-100"
      style="height: 48px; font-weight: 600;">Guardar</button>
  </div>
</div>