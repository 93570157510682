<div class="main-container">
  <button mat-button (click)="_location.back()" class="back-btn">
      <span class="fi-sr-angle-small-left"></span>
      <strong>Regresar &nbsp;</strong>
  </button>
  <h1>Actualizaciones de contratación</h1>
  <p>En esta sección podrás gestionar las actualizaciones de los contratos</p>


   <div class="filter">
      <mat-form-field appearance="fill" class="col-3">
          <mat-label>Actualizaciones Disponibles</mat-label>
          <mat-select  [formControl]="filterForm"  required>
              <mat-option value="otherif">Otro SI</mat-option>
              <mat-option value="session">Actualización de Sesión</mat-option>
          </mat-select>
      </mat-form-field>
        <button mat-button type="button" [disabled]="filterForm.invalid" (click)="downloadDocument()">Descargar formato &nbsp; <span class="fi-rr-download"></span></button>
        <button mat-flat-button color="primary" (click)=" fileUpload.click();"  [disabled]="filterForm.invalid">Cargar</button>
        <input type="file" hidden #fileUpload (change)="getUpdate($event.target.files)"
         id="fileUpload" name="fileUpload" multiple="multiple" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
    </div>
      <div class="info-candidate" *ngIf="fileUpdaloaded">
         <p><strong>Resumen</strong></p>
        <p><i class="fi-rr-users"></i> &nbsp; <b>Total de Candidatos Importados</b> &nbsp;&nbsp; <span>{{fileUpdaloaded.totalCandidates}}</span> </p>
        <p><i class="fi-rr-check"></i> &nbsp; <b>Candidatos registrados con éxito </b> &nbsp;&nbsp;<span>{{fileUpdaloaded[filterForm.value + 'ToSave']}}</span></p>
        <p> <i class="fi-rr-exclamation"></i> &nbsp; <b>Candidatos o razones para ignorar este proceso</b>&nbsp;&nbsp;  <span>{{ fileUpdaloaded[filterForm.value +'ToIgnore'].length }}</span></p>
         <div *ngIf="fileUpdaloaded[filterForm.value +'ToIgnore'].length">
            <ul>
                <li *ngFor="let row of fileUpdaloaded.otherIfToIgnore"> {{row.name}} <b>({{ row.reason }})</b></li>
            </ul>
        </div>
      </div>
</div>
