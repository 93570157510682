import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TimePipe } from 'src/app/modules/auditoria/pipes/time.pipe';
import { AuditoriaService } from 'src/app/modules/auditoria/services/auditoria.service';
import { Tools } from 'src/app/modules/auditoria/utils/tools.utils';
import Swal from 'sweetalert2';
import { FormInteligenciaComponent } from '../detalle-auditoria/components/form-inteligencia/form-inteligencia.component';
import { CalificacionAuditoriaComponent } from '../calificacion-auditoria/calificacion-auditoria.component';

@Component({
  selector: 'app-form-auditar',
  templateUrl: './form-auditar.component.html',
  styleUrls: ['./form-auditar.component.sass']
})
export class FormAuditarComponent implements OnInit, OnDestroy {

  @Input() tiene_inteligencia;
  @Input() dataCalificacion: any = {};
  @Input() id_matriz;
  @Input() isRetentionMatrix: number = 0;
  @Input() showFieldDuration: number = 0;
  @Output() envioTipoAuditar = new EventEmitter();
  @Output() envioTab = new EventEmitter();
  @ViewChild(CalificacionAuditoriaComponent) calificacionFrm;

  auditarForm: FormGroup;
  cedula: string = "";
  tiempo = '';
  id_asesor;
  id_tipificacion;
  //Selects
  monitoreos = [];
  servicios = [];
  auditorias = [];
  soluciones = [];

  campanas = [];
  aliados = [];
  tipificaciones = [];
  subtipificaciones = [];
  skills = [];

  tiendas = [];
  tipos = [];
  segmentos = [];
  subtipos_pqr = [];


  puntajes: string = "";


  ojts = [];
  no_soluciones = [];
  dias = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  anos = [2021, 2022, 2023, 2024, 2025];
  meses = [];
  endDate = new Date();

  //Observable para los cambios en los servicios de auditoria
  subscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private auditoriaService: AuditoriaService,
    private fb: FormBuilder,
    private router: Router,
    private pipeTime: TimePipe,
  ) { }

  ngOnInit(): void {
    this.getSelects();
    this.createForm();
    this.auditarForm.get('duracion_grabacion').valueChanges.subscribe(value => {
      let is_number = Number.parseInt(value);
      if (Number.isInteger(is_number)) {
        this.tiempo = this.pipeTime.transform(value);
      } else {
        this.tiempo = value;
      }
    });
    this.subscription = this.auditoriaService.refresh$.subscribe(() => {
      this.dataCalificacion.modulos = [];
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  saveForm() {

    let num_items = 0;
    let num_items_calificados = 0;
    this.dataCalificacion.modulos.forEach(modulo => {
      // num_items = num_items + modulo.items_modulo.length
      modulo.items_modulo.forEach(item => {
        if (item.activo == 1) {
          num_items++;
        }
        if (item.calificacion || item.calificacion === null) {
          num_items_calificados++
        }
      });
    });

    if (num_items == 0 || num_items != num_items_calificados) {
      Swal.fire({
        icon: 'warning',
        title: 'Aun te falta calificar módulos.',
        confirmButtonText: 'Entendido',
        width: '750px',
      });
      return;
    }
    //this.changeSelect();

    //this.auditarForm.invalid=true;
    if (this.auditarForm.invalid === true) {
      Swal.fire({
        icon: 'warning',
        title: 'Por favor completar los datos del formulario.',
        confirmButtonText: 'Entendido',
        width: '750px',
      });
      return;
    }


    //validar observaciones
    let errores = false;
    let mjs = "";

    this.dataCalificacion.modulos.forEach((modulo, index) => {

      modulo.items_modulo.forEach(item => {

        let obs = item.padre_id + '_' + item.id + '_obj'; // OBJ
        let txt = item.padre_id + '_' + item.id + '_txt'; // OBJ
        let observacion = document.getElementById(obs) as HTMLInputElement;
        let texto = document.getElementById(txt) as HTMLInputElement;

        let required = observacion.getAttribute("required");
        let text = texto.outerHTML;


        if (required && !observacion.value) {
          errores = true;
          text = text.replace("display", "displays");
          mjs = mjs + text + '<br>';
        }


      });
    });

    if (errores) {
      Swal.fire({
        icon: 'warning',
        title: 'Por favor ingresar las observaciones.',
        html: mjs,
        confirmButtonText: 'Entendido'
      });
      return false;
    }



    this.auditarForm.enable();
    let puntajes = document.getElementById('puntajes') as HTMLInputElement;
    this.puntajes = puntajes.value;
    this.auditarForm.get('fecha').setValue(moment(this.auditarForm.get('fecha').value).format('yyyy-MM-DD'))
    Object.assign(this.auditarForm.value, {
      total_calificacion: this.dataCalificacion.calificacion,
      id_asesor: this.id_asesor,
    })
    const data = {
      inteligencia_id: this.tiene_inteligencia,
      formAsesor: [this.auditarForm.value],
      matriz_id: this.id_matriz,
      puntajes: this.puntajes,
      seleccionItemsAfectacion: [],
      mensaje: 'Tiene una nueva auditoría con ID-',
      ruta: '/mios/auditoria/mis-auditorias',
    }

    this.dataCalificacion.modulos.forEach((modulo, index) => {
      data.seleccionItemsAfectacion.push({
        modulo_id: modulo.id,
        padre_id: modulo.padre_id,
        nivel: modulo.nivel,
        puntaje: "",
        itemsAfectados: [],
      })




      modulo.items_modulo.forEach(item => {

        let obs = item.padre_id + '_' + item.id + '_obj'; // OBJ
        let observacion = document.getElementById(obs) as HTMLInputElement;


        data.seleccionItemsAfectacion[index].itemsAfectados.push({
          item_id: item.id,
          padreitem: item.padre_id,
          nivelItem: item.nivel,
          calificacion: item.calificacion,
          puntaje: item.puntaje,
          observacion: observacion.value
        });
        item.sub_items.forEach(subItem => {
          data.seleccionItemsAfectacion[index].itemsAfectados.push({
            item_id: subItem.id,
            padreitem: subItem.padre_id,
            nivelItem: subItem.nivel,
            calificacion: subItem.calificacion === undefined ? '0' : subItem.calificacion,
            puntaje: subItem.puntaje === undefined ? '0' : subItem.puntaje
          });
        });
      });
    });
    if (this.tiene_inteligencia) {
      this.openFormInteligencia(data);
    } else {
      this.auditoriaService.createAuditoria(data).subscribe((resp: any) => {
        if (resp.respuesta.codigo == 202) {
          Tools.swalSuccess('¡Excelente!', 'La matriz ha sido auditada con éxito');
          this.tiempo = '';
          this.auditarForm.get('duracion_grabacion').valueChanges.subscribe();
          this.ngOnInit();
          this.cancelar();
          this.envioTab.emit(1);
        } else if (resp.respuesta.codigo != 202) {
          //this.auditarForm.get('nombre_asesor').disable();
          //this.changeSelect();
          Tools.swalError('¡Oops!', 'Lo sentimos, ha ocurrido un error inesperado y no fue posible auditar la matriz, por favor intenta nuevamente.');
        }
      });
    }
  }

  cancelar() {
    //this.auditarForm.reset();
    this.auditarForm.get('tipo_campana').setErrors(null);
    this.auditarForm.get('aliado').setErrors(null);
    this.auditarForm.get('nombre_asesor').setErrors(null);
    this.auditarForm.get('etiqueta').setErrors(null);
    this.auditarForm.get('fecha').setErrors(null);
    this.auditarForm.get('telefono').setErrors(null);
    this.auditarForm.get('duracion_grabacion').setErrors(null);
    this.auditarForm.get('skills').setErrors(null);
    this.auditarForm.get('tipificacion').setErrors(null);
    this.auditarForm.get('sub_tipificacion').setErrors(null);
    this.auditarForm.get('observacion').setErrors(null);

    this.auditarForm.get('nur').setErrors(null);
    this.auditarForm.get('tienda').setErrors(null);
    this.auditarForm.get('tipo').setErrors(null);
    this.auditarForm.get('segmento').setErrors(null);
    this.auditarForm.get('subtipo_pqr').setErrors(null);
    this.auditarForm.get('login').setErrors(null);
    this.auditarForm.get('usuario').setErrors(null);
  }

  createForm() {
    this.auditarForm = this.fb.group({
      tipo_campana: ['', Validators.required],
      aliado: [''],
      nombre_asesor: ['', Validators.required],
      etiqueta: ['N/A', Validators.required],
      fecha: ['', Validators.required],
      ano: ['', Validators.required],
      mes: ['', Validators.required],
      telefono: ['', [Validators.required, Validators.max(9999999999)]],
      hora: [''],
      duracion_grabacion: [this.tiempo, [Validators.pattern('[0-9]?[0-9]:[0-9][0-9]:[0-9][0-9]')]],
      skills: [''],
      tipificacion: [''],
      sub_tipificacion: [''],
      observacion: ['', Validators.required],
      nur: [''],
      tienda: [''],
      tipo: [''],
      segmento: [''],
      subtipo_pqr: [''],
      login: [''],
      usuario: [''],
      is_retencion: [''], 
      voz_cliente: ['N/A'],
    });
  }


  cargar_sub_tipificacion() {
    let idt: string = this.auditarForm.get('tipificacion').value;
    this.auditoriaService.get_subtipificacion(idt).subscribe((resp: any) => {
      this.subtipificaciones = resp
    });
  }



  getSelects() {


    this.auditoriaService.get_tipo_campana().subscribe((resp: any) => {
      this.campanas = resp
    });

    this.auditoriaService.get_aliado().subscribe((resp: any) => {
      this.aliados = resp
    });
    this.auditoriaService.get_skills().subscribe((resp: any) => {
      this.skills = resp
    });

    this.auditoriaService.get_tipificacion().subscribe((resp: any) => {
      this.tipificaciones = resp
    });

    this.auditoriaService.get_tiendas().subscribe((resp: any) => {
      this.tiendas = resp
    });

    this.auditoriaService.get_tipos().subscribe((resp: any) => {
      this.tipos = resp
    });

    this.auditoriaService.get_segmentos().subscribe((resp: any) => {
      this.segmentos = resp
    });

    this.auditoriaService.get_subtipos_pqr().subscribe((resp: any) => {
      this.subtipos_pqr = resp
    });

    this.auditoriaService.get_mes().subscribe((resp: any) => {
      this.meses = resp
    });




    /*this.auditoriaService.getTypeMonitoring().subscribe((resp: any) => {
      this.monitoreos = resp
      this.monitoreos.sort(function (a, b) {
        if (a.nombre > b.nombre) {
          return 1;
        }
        if (a.nombre < b.nombre) {
          return -1;
        }
        return 0;
      })
    });
    this.auditoriaService.getTypeService().subscribe((resp: any) => {
      this.servicios = resp
      this.servicios.sort(function (a, b) {
        if (a.nombre > b.nombre) {
          return 1;
        }
        if (a.nombre < b.nombre) {
          return -1;
        }
        return 0;
      })
    });
    this.auditoriaService.getTypeAudit().subscribe((resp: any) => {
      this.auditorias = resp
      this.auditorias.sort(function (a, b) {
        if (a.nombre > b.nombre) {
          return 1;
        }
        if (a.nombre < b.nombre) {
          return -1;
        }
        return 0;
      })
    });
    this.auditoriaService.getTypeSolution().subscribe((resp: any) => {
      this.soluciones = resp
    });
    this.auditoriaService.getTypegetOjt().subscribe((resp: any) => {
      this.ojts = resp
    });
    this.auditoriaService.getNoSolucion().subscribe((resp: any) => {
      this.no_soluciones = resp
      this.no_soluciones.sort(function (a, b) {
        if (a.nombre > b.nombre) {
          return 1;
        }
        if (a.nombre < b.nombre) {
          return -1;
        }
        return 0;
      })
    });*/
  }






  /**
   * Metodo que consulta el nombre y id del asesor de la auditoria
   * @author Juan David Guerrero Vargas
   * @create_date 14/06/2022  
   * @param type:string  tipo de documento a buscar [asesor-boss]
   * @return void 
   */
  getAsesor(type: string): void {
    let cedula: string = "";
    if (type === 'asesor') {
      cedula = this.auditarForm.get('cedula_asesor').value
    } else if (type === 'boss') {
      cedula = this.auditarForm.get('doc_boss').value
    }
    this.auditoriaService.getAsesor(cedula).subscribe((resp: any) => {
      if (type === 'asesor') {
        this.id_asesor = resp[0].id;
        this.auditarForm.get('nombre_asesor').setValue(resp[0].nombre)
        this.auditarForm.get('cedula_asesor').setErrors(null);
      } else if (type === 'boss') {
        this.auditarForm.get('name_boss').setValue(resp[0].nombre)
        this.auditarForm.get('doc_boss').setErrors(null);
      }

    }, () => {
      this.auditarForm.get('nombre_asesor').setValue('');
      this.auditarForm.get('cedula_asesor').setErrors({ no_resultado: true });
    }
    );
  }


















  activar_form() {
    this.auditarForm.get('tipo_campana').enable();
    this.auditarForm.get('aliado').enable();
    this.auditarForm.get('nombre_asesor').enable();
    this.auditarForm.get('nur').enable();
    this.auditarForm.get('tienda').enable();
    this.auditarForm.get('tipo').enable();
    this.auditarForm.get('segmento').enable();
    this.auditarForm.get('subtipo_pqr').enable();

    this.auditarForm.get('etiqueta').enable();
    this.auditarForm.get('llamada').enable();
    this.auditarForm.get('telefono').enable();
    this.auditarForm.get('grabacion').enable();

    this.auditarForm.get('skills').enable();
    this.auditarForm.get('tipificacion').enable();
    this.auditarForm.get('sub_tipificacion').enable();

    this.auditarForm.get('tipificacion').enable();
    this.auditarForm.get('sub_tipificacion').enable();
  }

  limpiar_errores_form() {
    this.auditarForm.get('tipo_campana').setErrors(null);
    this.auditarForm.get('aliado').setErrors(null);
    this.auditarForm.get('nombre_asesor').setErrors(null);
    this.auditarForm.get('nur').setErrors(null);
    this.auditarForm.get('tienda').setErrors(null);
    this.auditarForm.get('tipo').setErrors(null);
    this.auditarForm.get('segmento').setErrors(null);
    this.auditarForm.get('subtipo_pqr').setErrors(null);

    this.auditarForm.get('etiqueta').setErrors(null);
    this.auditarForm.get('llamada').setErrors(null);
    this.auditarForm.get('telefono').setErrors(null);
    this.auditarForm.get('grabacion').setErrors(null);

    this.auditarForm.get('skills').setErrors(null);
    this.auditarForm.get('tipificacion').setErrors(null);
    this.auditarForm.get('sub_tipificacion').setErrors(null);
  }






  changeSelect() {
    let valor_campana = this.auditarForm.get('tipo_campana').value;
    /*this.limpiar_errores_form();
    this.activar_form();


    switch (valor_campana) {
      case 9: // MONITOREO CHAT HOGARES
         this.auditarForm.get('nur').disable();
         this.auditarForm.get('tienda').disable();	
         this.auditarForm.get('tipo').disable();
         this.auditarForm.get('segmento').disable();
         this.auditarForm.get('segmento').disable();
      break;

      case 10: //MONITOREO CHAT MOVIL
         this.auditarForm.get('nur').disable();
         this.auditarForm.get('tienda').disable();	
         this.auditarForm.get('tipo').disable();
         this.auditarForm.get('segmento').disable();
         this.auditarForm.get('segmento').disable();
      break;

      case 11: // MONITOREO HOGARES
         this.auditarForm.get('nur').disable();
         this.auditarForm.get('tienda').disable();	
         this.auditarForm.get('tipo').disable();
         this.auditarForm.get('segmento').disable();
         this.auditarForm.get('segmento').disable();
      break;

      case 12: // MONITOREO MÓVILES
         this.auditarForm.get('nur').disable();
         this.auditarForm.get('tienda').disable();	
         this.auditarForm.get('tipo').disable();
         this.auditarForm.get('segmento').disable();
         this.auditarForm.get('segmento').disable();
      break;

      case 13: // MONITOREO PQRS
         this.auditarForm.get('nur').disable();
         this.auditarForm.get('tienda').disable();	
         this.auditarForm.get('tipo').disable();
         this.auditarForm.get('segmento').disable();
         this.auditarForm.get('segmento').disable();
      break;

      case 14: //MONITOREO PRESENCIAL
         this.auditarForm.get('nur').disable();
         this.auditarForm.get('tienda').disable();	
         this.auditarForm.get('tipo').disable();
         this.auditarForm.get('segmento').disable();
         this.auditarForm.get('segmento').disable();
      break;

      case 15: //MONITOREO REDES SOCIALES
         this.auditarForm.get('nur').disable();
         this.auditarForm.get('tienda').disable();	
         this.auditarForm.get('tipo').disable();
         this.auditarForm.get('segmento').disable();
         this.auditarForm.get('segmento').disable();
      break;

      case 16: //MONITOREO RETENCIÓN 
         this.auditarForm.get('nur').disable();
         this.auditarForm.get('tienda').disable();	
         this.auditarForm.get('tipo').disable();
         this.auditarForm.get('segmento').disable();
         this.auditarForm.get('segmento').disable();
      break;

      case 17: // MONITOREO SOPORTE MOVILES
         this.auditarForm.get('nur').disable();
         this.auditarForm.get('tienda').disable();	
         this.auditarForm.get('tipo').disable();
         this.auditarForm.get('segmento').disable();
         this.auditarForm.get('segmento').disable();
      break;

      case 18: //MONITOREO TRAZABILIDAD
         this.auditarForm.get('nur').disable();
         this.auditarForm.get('tienda').disable();	
         this.auditarForm.get('tipo').disable();
         this.auditarForm.get('segmento').disable();
         this.auditarForm.get('segmento').disable();
      break;
    }

    */


  }

  openFormInteligencia(data) {
    const inteligenciaRef = this.dialog.open(FormInteligenciaComponent, {
      width: '840px',
      disableClose: true,
      data: data,
    });
    inteligenciaRef.afterClosed().subscribe((result) => {
      this.auditarForm.get('nombre_asesor').disable();
      if (result == 202) {
        this.tiempo = '';
        this.auditarForm.get('duracion_grabacion').valueChanges.subscribe();
        this.ngOnInit();
        this.auditarForm.reset();
        this.envioTab.emit(1);
      }
    })
  }

  auditorio() {
    if (this.auditarForm.get('tipo_auditoria').value == 15) {
      this.envioTipoAuditar.emit({ interna: 1, cliente: 0 })
    } else if (this.auditarForm.get('tipo_auditoria').value == 16) {
      this.envioTipoAuditar.emit({ interna: 0, cliente: 1 })
    }
  }

}
