import { Component, OnInit } from '@angular/core';
import { MatChip, MatChipSelectionChange } from '@angular/material/chips';
import { ManagerChatService } from './../../services/manager-chat.service'
import { ChatRestService } from '../../services/chat-rest.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MessageChatInterface, UserChatInterface } from '../../utils/interfaces.chat';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { contacAnimation } from '../../animations/contact.anumations';
import { MatDialog } from '@angular/material/dialog';
import { ChatGroupsComponent } from '../chat-groups/chat-groups.component';
import { AllContactsComponent } from '../all-contacts/all-contacts.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ViewUsersGroupComponent } from '../view-users-group/view-users-group.component';


@Component({
  selector: 'chat-list-contact',
  templateUrl: './list-contact.component.html',
  styleUrls: ['./list-contact.component.sass'],
  animations:[contacAnimation]
})
export class ListContactComponent implements OnInit {
  //Listado de estados tipos de filtro
  //0 => Todos
  //1 => No leidos
  listChip:boolean[] = Array.from(Array(3), (x,i)=> i == 0 ? true : false)
  //Listado de contactos con los cuales peude chatear
  listContacts:any[]=[]
  //Observable con los contactos disponibles apra chatear
  $filterContact:Observable<any[]>= new Observable();

  //Variable de control para filtrado de lista de contactos
  searchControl:FormControl = new FormControl(null,[]);

  actualEnableChat:any=null;

  isOpenListContact:boolean = false

  //INTERFACES DE INTERACCION


  //Interfaz para obtener el ultimo mensaje enviado por la transmision de MQTT
  private getLastMessageSendedUser = (message:MessageChatInterface|null)=>{
    if(message !== null){
      if(message.sender_id !== this.authService.decryptToken().rrhh_id ){
        let index = this.listContacts.findIndex( contact => contact.recipient_type == message.recipient_type && contact.rrhh_id == message.sender_id  )
        if(this.actualEnableChat == null || this.actualEnableChat == undefined || this.listContacts[index].rrhh_id !== this.actualEnableChat.rrhh_id ){
          this.listContacts[index]["countMessageNotRead"] = Number(this.listContacts[index]["countMessageNotRead"]) + 1
          this.$filterContact = of(this.listContacts);
        }

        if(index == -1){
          this.getListContact();
        }

      }
    }
  }

  private getLastMessageSendedGroup = (message:MessageChatInterface|null)=>{
    if(message !== null){
        let index = this.listContacts.findIndex( contact => contact.recipient_type == 2 && contact.rrhh_id == message.recipient_entity_id  )
        if(this.actualEnableChat == null || this.actualEnableChat == undefined || (this.listContacts[index].rrhh_id !== this.actualEnableChat.rrhh_id && this.listContacts[index].recipient_type == 2 ) ){
          this.listContacts[index]["countMessageNotRead"] = Number(this.listContacts[index]["countMessageNotRead"]) + 1
          this.$filterContact = of(this.listContacts);
        }
        if(index == -1){
          this.getListContact();
        }
    }
  }


  constructor(
    private managerChat:ManagerChatService,
    private chatRestService:ChatRestService,
    private authService: AuthService,
    private dialog:MatDialog,
    private bottomSheet: MatBottomSheet
  ) { }

  ngOnInit(): void {
    this.searchControl.valueChanges
    .pipe(
      startWith(''),
      debounceTime(500), // Espera 500 ms después del último evento
      distinctUntilChanged(),
      map(value => this._filterContacs(value))
    ).subscribe(
      (newListContacts:any[])=>{
        this.$filterContact = of(newListContacts);
      }
    );

    this.managerChat.$actualChat.subscribe(
      (actualChat)=>{
        this.actualEnableChat = actualChat
      }
    );


    this.getStateListContactPanel();
  }


  /**
   * Metodo que se encarga obtener los grupos asociados
   *
   */
  private getGroups():void{
    this.chatRestService.getGroupsUser().subscribe(
      (groupsRest)=>{
        if(groupsRest?.groups !== undefined && Array.isArray(groupsRest.groups) == true && groupsRest.groups.length > 0){
          let groups = groupsRest.groups.map((gr:any)=>{
            return {
                rrhh_id:gr.id,
                name:gr.name,
                recipient_type:2,
                onwer_id:gr.onwer_id,
                countMessageNotRead:0
              }

          });
          this.listContacts = this.listContacts.concat(groups)
          this.$filterContact = of(this.listContacts)
        }
      }
    );
  }

  /**
   * Metodo que se encarga de filtrar los nombres de los chats
   * @author Juan David Guerrero Vargas
   * @param nameContact:string {string} nombre del contacto a buscar
   * @returns {any[]}  Listado de contactos
   */
  private _filterContacs(nameContact:string):any[]{
    return this.listContacts.filter( ffll => ffll.name.toLowerCase().includes(nameContact.toLowerCase()) )
  }

  /**
   * Metodo que se encarga de obtener el estado actual del panel Lista contactos
   */
  private getStateListContactPanel():void{
    this.managerChat.$isOpenListContacs.subscribe(
      (statePanel:boolean)=>{
        if(statePanel == true){ //Consultamos el listado de contactos solo cuando se habilite el panel
          this.getListContact();
        }
        this.isOpenListContact = statePanel
      })
  }

  /**
   * Metodo que se encarga de obtener el listado de contactos del usuario
   * @author Juan David Guerrero Vargas 
   * @param idGorup?:any {number|string} Id del grupo a evaluar
   */
  private getListContact(idGorup?:any){
    this.chatRestService.getListContact().subscribe((contacts:any)=>{
      this.listContacts = contacts.data.map(
        (user)=>{
          return {
            rrhh_id:user.rrhh_id,
            name:user.name,
            recipient_type:user.recipient_type == 'user' ? 1 : 2,
            onwer_id:user?.onwer_id,
            countMessageNotRead:user.unread
          }

        }
      ).filter(ffll => ffll.rrhh_id != this.authService.decryptToken().rrhh_id );
      if(idGorup !== undefined && idGorup !== null && idGorup !== ""){
        let groupFindIndex = this.listContacts.findIndex( ffnnI => ffnnI.recipient_type == 2 && ffnnI.rrhh_id == idGorup );
        if(groupFindIndex !== -1){
          this.setNewChat( this.listContacts[groupFindIndex], groupFindIndex)
        }
      }
      this.$filterContact = of(this.listContacts);
      this.managerChat.$messagePublic.subscribe(this.getLastMessageSendedUser);
      this.managerChat.$messageGroup.subscribe(this.getLastMessageSendedGroup);
    });

  }

  /**
   * Metodo que permite determinar el estado actual del filtrado de la opcion
   * @author Juan David Guerrero Vargas
   * @param i:number {number} Indice del filtro a validar
   * @returns {string} string cadena de texto con lsa clases a aplicar
   */
  styleFilterState(i:number):string{
    let span_filter = "filter_span "
    if(this.listChip[i] == true){
      span_filter += "active_span"
    }
    return span_filter
  }

  /**
   * Metodo que se encarga de iniciar los filtros
   * @author Juan David Guerrero Vargas
   * @param i:number filtro a cambiar de estad
   * @return void
   */
  activeFilter( i:number){
    this.listChip[i] = !this.listChip[i]
    switch (i) {
      case 0:
        this.listChip[1] = false
        this.listChip[2] = false
        this.$filterContact = of(this.listContacts);
      break;
      case 1:
        this.listChip[0] = false
        this.listChip[2] = false
        this.$filterContact = of(this.listContacts.filter( ffll => ffll.countMessageNotRead > 0 ));
      break;
      case 2:
        this.listChip[0] = false
        this.listChip[1] = false
        this.$filterContact = of(this.listContacts.filter( ffll => ffll.recipient_type == 2 ));
      break;

      default:
        this.listChip[1] = true
        this.listChip[1] = false
        this.listChip[2] = false
        this.$filterContact = of(this.listContacts);
        break;
    }
  }

  /**
   * Metodo que se encarga de obtener el de asignar el chat a habilitar
   * @author Juan David Guerrero Vargas
   * @param chat:UserChatInterface {UserChatInterface} char ha habilitgar como el chat actual
   * @param i:number  {number} indice del chat a asignar
   */
  setNewChat(chat:UserChatInterface, i:number):void{
    this.managerChat.setActualChat(chat)
    this.listContacts[i].countMessageNotRead = 0

  }

  /**
   * MEtodo que se encarga de determinar si se tienen o no mensajes nuevos en el char evaluado
   * @author Juan David Guerrero Vargas
   * @param contact:any {any}
   * @returns string {string} cadena de texto con las clases aplicar para char con o sin mensajes leidos
   */
  notificationHaveNewMessage(contact:any):string{
    let str = "w-100 d-flex align-items-center item-chat"
    if(contact.countMessageNotRead == 1){
      str += " not_read"
    }
    return str
  }


  /**
   * Metodo que se encarga de cerrar el listado de contactos
   */
  closePanelListContact():void{
    this.managerChat.openListContact = false;
  }

  /**
   * Metodo qie se encarga de abrir el modal para creacion de grupos
   * @author Juan David Guerrero Vargas
   * @return void
   */
  openModalChatGroup():void{
    const dialogGroup = this.dialog.open(ChatGroupsComponent,{
      data:{
        optenTo:'create',
        title:'Crear grupo',
      },
      width: '90vw',
      height: '90vh'
    })

    dialogGroup.afterClosed().subscribe(confirmation => {
      if(confirmation && confirmation !== undefined){
        this.getListContact();
      }
    })
  }

  /**
   * Metodo que se encarga de identificar si el rrhh_id es igual al rrhh_id del usuario
   * @author Juan David Guerrero Vargas
   * @param rrhh_id:number|string {number|string} rrhh_id a evaluar
   * @returns booelan
   */
  isOwnerGroup(rrhh_id):boolean{
    if(rrhh_id == this.authService.decryptToken().rrhh_id){
      return true
    }
    return false
  }

  /**
   * Metodo que se encarga de abrir el modal donde se listan los contactos
   * @author Juan David Guerrero Vargas
   * @return void
   */
  openMoalContacts():void{
    const dialogContacts = this.dialog.open(AllContactsComponent,{
      data:{
        optenTo:'create',
      }
    });

    dialogContacts.afterClosed().subscribe(
      (closedContacts)=>{
        if(closedContacts && closedContacts?.user !== undefined){
          this.managerChat.setActualChat(closedContacts.user)
        }
      }
    );
  }


    /**
   * Metodo que se encarga de abrir el modal apra la edicion del grupo
   * @author Juan David Guerrero Vargas
   * @returns {void} void
   */
  openModalEditGroups(group:any):void{
    const dialogRef = this.dialog.open(ChatGroupsComponent,{
      data:{
        optenTo:'edit',
        idGroup:group.rrhh_id,
        name_group:group.name,
        onwer_id: group?.onwer_id,
        title:'Editar grupo ' + group.name
      }
    });

    dialogRef.afterClosed().subscribe(
      (closeEditGroup)=>{
        if(closeEditGroup && closeEditGroup !== undefined){
          this.getListContact(closeEditGroup?.id_group);
          if(closeEditGroup?.id_group == undefined || closeEditGroup?.id_group == null || closeEditGroup?.id_group == ""){
            this.managerChat.setActualChat(null)
          }
        }
      }
    )
  }

  /**
   * Metodo que se encatga de abrir el detalle del grupo
   * @param contact:any {any}
   */
  seeDetailGroup(contact:any){
    console.log(contact)
    const bottomSheet = this.bottomSheet.open(ViewUsersGroupComponent, {
      data: {
        idGroup:contact.rrhh_id,
      }
    });
  }
}
