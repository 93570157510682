import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/services/rest/auth.service';

@Injectable({
    providedIn: "root"
  })
  export class storageService{  
    
    constructor(public authService: AuthService) {}

    setItem( name: string, data: string){
      return localStorage.setItem(name, data)
    }

    getItem( name: string){
      return localStorage.getItem(name)
    }

    getItemRrhh(){
      const userMios = this.authService.getUser();
      // return JSON.parse(localStorage.getItem('user')).rrhh
      return userMios.rrhh;
    }

    removeItem ( name: string  ){
      return localStorage.removeItem(name)
    }

    getToken() {
      let token_type  = JSON.parse(localStorage.getItem('user')).token_type;
      let token       = this.authService.getToken();
      return `${token_type} ${token}`;
    }

  }