<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross"></i>
</button>
<h3 mat-dialog-title>Tipos de Recursos</h3>
<hr>
<mat-dialog-content>
  <mat-selection-list [multiple]="false" [(ngModel)]="type">
    <mat-list-option class="option" *ngFor="let item of data.types" [value]="item" >
            <span>{{item.name}}</span>
    </mat-list-option>

</mat-selection-list>

</mat-dialog-content>
<mat-dialog-actions align="center">
   <button matSuffix mat-flat-button [mat-dialog-close]="type"  color="primary">Buscar</button>
</mat-dialog-actions>
