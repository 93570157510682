<div class="admin-auditoria">

  <div class="header">

    <h2 style="height: 33px !important;
            font: normal normal bold 45px/35px Poppins !important;
            letter-spacing: 0px !important;color: #353535 !important;opacity: 1 !important;">{{config.adminAuditoria.header.title}}</h2>
    <br>
    <p>{{config.adminAuditoria.header.subtitle1}}</p>
    <p>{{config.adminAuditoria.header.subtitle2}}</p>
  </div>

    <app-main-matrices class="mt-5"></app-main-matrices>

</div>
