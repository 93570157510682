import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef } from '@angular/core';
import Echo from 'laravel-echo';
import { ChatService } from '../../core/services/rest/chat.service';
import { StorageService } from '../../core/services/storage/storage.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Message } from './models/message.model';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { SpeedDialFabComponent } from './../speed-dial-fab/speed-dial-fab.component';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import {PusherChatService} from "../../modules/crm/services/pusher-chat.service";
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.sass']
})
export class ChatComponent implements OnInit {

  newMessage: FormGroup;
  newMessageDiffusion: FormGroup;
  alert: boolean = false;
  resp: any;
  rrhhId: any;
  user: any;
  conversation: any = [];
  @Input() historial;
  chat: boolean = false;
  ver: boolean = true;
  ventanaChat: any;
  mensajes: any = null;
  idConversacion: any;
  size: any = 5;
  counterConversation: any;
  openConversation: any = 0;
  newConversation: boolean = false;


  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: any[] = [];
  allFruits: any[] = [];

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;


  constructor(private chatService: ChatService,
              private storageService: StorageService,
              private parent: SpeedDialFabComponent,
              private pusherChatService: PusherChatService,
              private form: FormBuilder,
              private authService: AuthService) {

                this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
                  startWith(null),
                  map((fruit: string | null) => fruit ? this._filter(fruit) : this.allFruits.slice()));

                  this.pusherChatService.getChatConnection().bind('MessageSendEvent', (message) => this.OnMessageSendEvent(message));
                  this.pusherChatService.getNotifyUserState().bind('NotifyStatusUserChangedEvent', (response) => this.OnNotifyUserState(response));
               }

  ngOnInit(): void {

    if (this.authService.getUser().chat_active !== null) {
      this.chatService.getUser().subscribe((resp) => {
        this.allFruits = resp;
      });
    }

    this.user = this.storageService.getStorage('user');
    this.rrhhId = this.user.rrhh_id;

    this.getCoversation(this.size, this.openConversation);

    this.newMessage = this.form.group({
      message: new FormControl('', []),
    });

    this.newMessageDiffusion = this.form.group({
      messageDiffusion: new FormControl('', []),
    });

  }

  public OnMessageSendEvent(message: any) {
    this.resp = message;
        this.openConversation = 0;
        if (this.historial === false) {
          this.alert = !this.alert;
        }

        if(this.chat === true && this.resp.message.conversation_id === this.idConversacion) {
          this.openConversation = this.idConversacion;
        }

        this.getCoversation(this.size, this.openConversation);

        if(this.mensajes !== null && this.resp.message.conversation_id === this.idConversacion) {
          this.mensajes.push(this.resp.message);
        }

        let objDiv = document.getElementById('parentDiv');
        if (objDiv){
          setTimeout(() => {
            objDiv.scrollTop = objDiv.scrollHeight;
          }, 1);
        }

  }
  public OnNotifyUserState(response) {
        this.conversation.forEach(element => {

          if (element.rrhh_id === response.user.rrhh_id) {
            element.userState = response.user.state;
          }

        });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-07-06
   * Metodo que se usa para hacer un cambio de variable y cerrar la alerta de un nuevo mensaje
   */
  closeAlert(): void{
    this.alert = !this.alert;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-07-06
   * Metodo que se una para ocultar la ventana de chat
   */
  cerrar(): void{
    this.parent.ocultarChat();
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-07-06
   * @param size Tamaño de informacion a traer
   * @param openConversation parametro que especifica si una conversacion esta abierta o cerrado
   * Metodo que lista todas las conversaciones disponibles de un usuario
   */
  getCoversation(size: number, openConversation: any): void {
    if (this.authService.getUser().chat_active !== null) {
      this.chatService.getListConversationByRhhId(size, openConversation).subscribe((resp) => {
        this.counterConversation = resp.conversationUnread;
        this.conversation = resp.conversations;
      });
    }
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-07-06
   * @param size tamaño de la informacion a traer
   * Metodo que se encarga de las acciones de la flecha de ver mas o ver menos
   */
  verMas(size: number): void{
    this.size = size;
    this.getCoversation(this.size, this.openConversation);
    this.ver = !this.ver;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-07-06
   * @param item conversacion seleccionada
   * Metodo que se encarga de abrir una ventana de chat y listar sus mensajes
   */
  abrirConversacion(item: any): void{

    this.chat = true;
    this.ventanaChat = item;
    this.idConversacion = item._id;
    this.newConversation = false;
    this.chatService.getChatConversation(this.idConversacion).subscribe((respo) => {
        this.mensajes = respo;
        if (this.mensajes.length !== 0){
        setTimeout(() => {
          var objDiv = document.getElementById('parentDiv');
          objDiv.scrollTop = objDiv.scrollHeight;
        }, 1);
        this.getCoversation(this.size, this.openConversation);
      }
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-07-06
   * @param newMessage nuevo mensaje a enviar
   * Metodo que se encarga de enviar un nuevo mensaje
   */
  sendMessage(newMessage): void{

    if (newMessage.value.message !== null) {
      const message: Message = new Message('text', newMessage.value.message, this.user.rrhh.name, this.rrhhId, this.idConversacion, []);

      this.chatService.newMessage(message).subscribe((resp) => {
        this.newMessage.reset();
        var objDiv = document.getElementById('parentDiv');
        objDiv.scrollTop = objDiv.scrollHeight;
      });
    }

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-07-06
   * @param newMessageDiffusion mensaje a enviar
   * Metodo que se encarga de enviar un nuevo mensaje masivo
   */
  sendMessageDiffusion(newMessageDiffusion): void{

    const newMessage =  new FormData();

    newMessage.append( 'message' , newMessageDiffusion.value.messageDiffusion);
    newMessage.append( 'message_type' , 'text');
    newMessage.append( 'userReceivedMessage' , JSON.stringify(this.fruits));

    this.chatService.newMessageDiffusion(newMessage).subscribe((resp) => {
      this.newMessageDiffusion.reset();
      this.fruitCtrl.reset();
    });
  }

  applyFilter(event: Event): void {

    const filterValue = (event.target as HTMLInputElement).value;

    if (this.authService.getUser().chat_active !== null) {
      this.chatService.searchConversation(filterValue).subscribe((resp) => {

        if(resp !== null){
          this.counterConversation = resp.conversationUnread;
          this.conversation = resp.conversations;
        } else {
          this.conversation = [];
        }

      });
    }

  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if ((value || '').trim()) {
      this.fruits.push({
        id:Math.random(),
        name:value.trim()
      });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fruitCtrl.setValue(null);
  }

  remove(fruit, indx): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits.push(event.option.value);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  private _filter(value: any): any[] {
    return this.allFruits.filter(fruit => fruit.name.toLowerCase().includes(value.name.toLowerCase()));
  }


}
