<div class="eliminar-main">
  <h2 mat-dialog-title style="font-weight: 700 !important;">{{ config.labels.auditDeletion }} - {{ datos.id }}
    <button style="float: right; margin-top: -.5rem;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross" style="font-size: 16px;"></i>
    </button>
  </h2>
  <form [formGroup]="formEliminar">
    <mat-form-field appearance="fill" class="col-12">
      <mat-label>{{ config.labels.deletionObservation }}</mat-label>
      <textarea matInput cdkFocusInitial formControlName="observacion"></textarea>
    </mat-form-field>
    <div class="row">
      <div class="col-12" style="text-align: center;">
        <button mat-stroked-button mat-dialog-close>{{ config.labels.cancelButton }}</button>
        <button mat-raised-button color="primary" (click)="eliminar()" [disabled]="formEliminar.invalid">{{ config.labels.deleteButton }}</button>
      </div>
    </div>
  </form>
</div>
