<div class="admin-roles">

    <div class="header">
        <h1>Administración de Roles</h1>
        <p>Bienvenido al módulo administración de roles, recuerda que puedes<br>filtrar por<strong> cualquier criterio que encuentres en la tabla.</strong></p>
    </div>

    <div class="row col-12 filter">
    
        <mat-form-field class="col-3" appearance="fill">
            <mat-label>Filtro de búsqueda</mat-label>
            <input matInput (keydown.enter)="applyFilter($event)" (blur)="applyFilter($event)" #input>
            <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
        </mat-form-field>

        <div class="col-6"></div>

        <div class="col-3">
            <button mat-raised-button color="primary" (click)="newRol()">Crear nuevo rol</button>
        </div>

    </div>

    <div class="table-container">
        <div class="mat-elevation-z1">
          <table mat-table [dataSource]="dataSource" matSort>
                    
                  <ng-container matColumnDef="acciones">
                        <th class="start" mat-header-cell *matHeaderCellDef> Acciones</th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button (click)="editRol(row)">
                                <i class="fi-rr-edit"></i>
                            </button>
                            <button mat-icon-button (click)="deleteRol(row)">
                            <i class="fi-rr-trash"></i>
                            </button>
                        </td>
                  </ng-container>

                  <ng-container matColumnDef="application">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Aplicativo </th>
                      <td mat-cell *matCellDef="let row"> {{row.application.name}} </td>
                  </ng-container>
  
                  <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Rol </th>
                      <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                  </ng-container>
  
                  <ng-container matColumnDef="key">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Key </th>
                      <td mat-cell *matCellDef="let row"> {{row.key}} </td>
                  </ng-container>
  
                  <ng-container matColumnDef="created_at">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de creación </th>
                      <td mat-cell *matCellDef="let row"> {{row.created_at | date}} </td>
                  </ng-container>
  
                  <ng-container matColumnDef="creadoPor">
                      <th class="end" mat-header-cell *matHeaderCellDef mat-sort-header> Creado por </th>
                      <td mat-cell *matCellDef="let row"> {{row.created_by}} </td>
                  </ng-container>
                
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro: "{{input.value}}"</td>
            </tr>

          </table>

          <mat-paginator [length]="length"
                         [pageSize]="pageSize"
                         (page)="pageEvent($event)"
                         [pageSizeOptions]="pageSizeOptions">
          </mat-paginator>
        
            
        </div>
    </div>

</div>
