import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { DomSanitizer } from '@angular/platform-browser';
import * as html2pdf from 'html2pdf.js';
import { StrategyServersService } from '../../../services/strategy.service';

@Component({
  selector: 'app-adherencia-chat-modal',
  templateUrl: './adherencia-chat-modal.component.html',
  styleUrls: ['./adherencia-chat-modal.component.sass']
})
export class AdherenciaChatModalComponent implements OnInit {

  recordingID: any;
  adherenciaIA: any;
  adherenciaResponse: { adherencePercentage: number, notAdherencePercentage: number };
  transcripcion: any[] = [];
  keys: any[] = [];
  adherencePercentage: number;
  notAdherencePercentage: number;
  transcripcionFormat: any[] = [];
  @ViewChild('containerViewReport') containerViewReport: ElementRef;
  @ViewChildren(MatExpansionPanel) panels: QueryList<MatExpansionPanel>;
  keysArray: any[];
  percentageSections: any;
  cumplimiento_general: any;
  labels_notfound: any;
  labels_found: any;
  tooltipContent: string;
  isAdherenceTooltip = false;
  formattedAdherenciaIA: string = '';

  constructor(
    private strategyService: StrategyServersService,
    private sanitazer:DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any 
  ) {}
  
  ngOnInit(): void {
    this.recordingID = [this.data.id];
    this.applyStrategySelected(this.recordingID);
  }

  /**
   * Método consuta servicio de la estrategia aplicada
   * @author Yeison Sepulveda
   * @createdate 27/02/2024
   */

  applyStrategySelected(strategy_ids: string[]): void {
    this.strategyService.applyAdherenciaChats(strategy_ids).subscribe(
        (adherenciaResponses) => {
            adherenciaResponses.forEach((response) => {
                const transcripcion = [];
                const diarization = JSON.parse(response.analysis_feelings);

                if (diarization.interaccion && typeof diarization.interaccion === 'string') {
                    const interaccionArray = JSON.parse(diarization.interaccion);
                    interaccionArray.forEach((item: any) => {
                        transcripcion.push({
                            text: this.sanitazer.bypassSecurityTrustHtml(item.text),
                            agent: item.agent
                        });
                    });
                }

                this.transcripcion = transcripcion;
                this.transcripcionFormat = Array.isArray(this.transcripcion) ? this.transcripcion : [this.transcripcion];
                this.adherenciaIA = response.strategy_result;
                this.cumplimiento_general = response.cumplimiento_general;
                this.labels_found = response.labels_found;
                this.labels_notfound = response.labels_notfound;

                this.formatAdherenciaIA(this.adherenciaIA);

                this.adherenciaResponse = {
                    adherencePercentage: response.adherence_percentage,
                    notAdherencePercentage: response.not_adherence_percentage
                };

                this.percentageSections = response.percentage_sections.map((section: any) => ({
                    section: section.section,
                    percentage_section: section.percentage_section,
                    adherence_percentage: section.adherence_percentage,
                    not_adherence_percentage: section.not_adherence_percentage,
                    cumplimiento: section.cumplimiento,
                    strategy_result_section: section.strategy_result_section
                }));
            });
        },
        (error: any) => {
            console.error("Error al calcular la adherencia:", error.message);
        }
    );
  }

  /**
   * Formatea el texto del análisis de la IA para aplicar negrillas al primer subtítulo
   * @author Yeison Sepulveda
   * @createdate 28/08/2024
   */
    formatAdherenciaIA(text: string | null): void {
      if (text) {
        this.formattedAdherenciaIA = text
          .replace(/^.*?(\n|$)/, '<strong>$&</strong>');
      }
    }

  /**
   * Metodo descarga e imprime pantallazo del modal
   * @update Yeison Sepulveda
   * @updatedate 29-05-2024
  */
  async onClickPrintPdf() {
    // Abre todos los paneles de expansión
    this.panels.forEach(panel => panel.open());

    setTimeout(() => {
      const content = this.containerViewReport.nativeElement;
      
      const margin = 13; // milímetros
      const filename = this.generateFilename();
  
      const options = {
          margin: margin, 
          filename: filename,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: {
              scale: 2
          },
          jsPDF: {
              unit: 'mm',
              format: 'a4',
              orientation: 'portrait'
          },
          pagebreak: {
              mode: ['avoid-all', 'css', 'legacy']
          }
      };
  
      html2pdf().from(content).set(options).toContainer().toCanvas().toImg().toPdf().save();
    }, 500); // Ajusta el tiempo según sea necesario
  }

  /**
   * Metodo generar el nombre del documento a descargar
   * @author Yeison Sepulveda
   * @createdate 2024-0-03
   * @return nombre del archivo
  */
  
  generateFilename(): string {
    const currentDate = new Date().toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '');
  
    const currentTime = new Date().toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).replace(/:/g, '');
  
    const dateTimeString = `${currentDate}${currentTime}`;
    return `reporte_adherencia_${this.recordingID}_${dateTimeString}.pdf`;
  }
}
