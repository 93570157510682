import { Component, OnInit, ViewChild } from '@angular/core';
import { Solicitud } from '../../../Class/solicitud';
import { CrearSolicitudComponent } from '../crear-solicitud/crear-solicitud.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SolicitudesService } from '../../../../services/rest/solicitudes.service';
import { DestroyComponentService } from '../../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import { MatDialog } from '@angular/material/dialog';
import { ProcesarSolicitudComponent } from '../procesar-solicitud/procesar-solicitud.component';
import { AprobarSolcitudComponent } from '../aprobar-solcitud/aprobar-solcitud.component';
import { ConsultarProveedorComponent } from '../consultar-proveedor/consultar-proveedor.component';

@Component({
  selector: 'app-gestion-aprobacion',
  templateUrl: './gestion-aprobacion.component.html',
  styleUrls: ['./gestion-aprobacion.component.sass']
})
export class GestionAprobacionComponent implements OnInit {

  length;
  rol=2;
  pageSize = 5;
  page = 1;
  isPage = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  newRolComponent: boolean = false;

  displayedColumns: string[] = ['acciones','request_date', 'title', 'description','created_by','company','state'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private solicitudService: SolicitudesService,
              private destroyService: DestroyComponentService,
              private alertsService: AlertsService,
              public dialog: MatDialog) { }


   ngOnInit(): void {
    this.getSolicitudes();
  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que trae los roles paginados
   * @param size cantidad de roles a traer en la tabla
   * @param page pagina que va a mostrar la tabla
   */
   getSolicitudes(): void {

    if (this.dataSource.filter === ''){
      this.dataSource.filter = null;
    }
    const params = {
      isPage    : this.isPage,
      pageSize  : this.pageSize,
      page      : this.page,
      rol       : this.rol,
      date      : null
    }
    this.solicitudService.getSoliciudesPaginate(params).subscribe((Response) => {
      this.dataSource.data = Response.data;

      this.dataSource.sort = this.sort;
      this.length = Response.data.total;
      this.pageSize = Response.data.per_page;

    });

  }
 /**
  * @author Jose Vicente Silva
  * @createdate 2021-02-04
  * Metodo que realiza el filtro
  * @param event datos para filtrar que son enviados desde el input
  */
  applyFilter(event: Event): void {

    const filterValue = (event.target as HTMLInputElement).value;

    // this.solicitudService.searchApplications(this.pageSize, this.page, filterValue).subscribe((Response) => {
    //   this.dataSource.data = Response.data.data;
    //   this.dataSource.sort = this.sort;
    //   this.length = Response.data.total;
    //   this.pageSize = Response.data.per_page;
    // });
  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que se encarga del movimiento del paginador
   * @param event cuando se da click en el paginador trae este evento
   */
  pageEvent(event: any): void {

    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getSolicitudes();

  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que abre el modal de crear un nuevo rol, al cerral el modal se refresca la tabla
   */
  newSolicitud(): void{
    const dialogNewRol = this.dialog.open(CrearSolicitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Crear Solicitud',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.pageSize=5;
      this.ngOnInit();
    });

  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que abre el modal de editar usuario, envia un usuario, al cerrar el modal se refresca la tabla
   * @param rol rol a editar
   */
  editSolicitud(solicitud: Solicitud): void{
    const dialogNewRol = this.dialog.open(ConsultarProveedorComponent, {
      width: '50%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Editar Solicitudes',
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.pageSize =5;
      this.ngOnInit();
    });
  }

  viewSolicitud(solicitud: Solicitud){
    const dialogNewRol = this.dialog.open(ProcesarSolicitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'ver',
        title: 'Editar Solicitudes',
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.pageSize =5;
      this.ngOnInit();
    });
  }


  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

}
