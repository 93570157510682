<button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<form mat-dialog-content class="containerDialog" [formGroup]="moduleForm">
    <h2>{{config.labels.editModule}}</h2>
    <mat-divider class="mb-3"></mat-divider>
    <mat-form-field appearance="fill">
        <mat-label>{{config.labels.name}}</mat-label>
        <input type="text" matInput formControlName="nombre" [matAutocomplete]="modulo" cdkFocusInitial>
        <mat-icon matSuffix><span class="fi-rr-search" (click)="getModules()"></span></mat-icon>
        <mat-autocomplete #modulo="matAutocomplete" [displayWith]="displayFn" (optionSelected)="newModule()">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.nombre">
                {{option.nombre}}
            </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="moduleForm.get('nombre').hasError('required')">{{config.labels.requiredName}}</mat-error>
        <mat-error *ngIf="verError">{{config.labels.noResults}}</mat-error>
    </mat-form-field>
    <button mat-button class="nuevo" *ngIf="nuevo" (click)="newModule()">{{config.labels.newModule}} <mat-icon matSuffix><i class="fi-rr-add"></i></mat-icon></button>
    <mat-form-field appearance="fill">
        <mat-label>{{config.labels.weight}}</mat-label>
        <input type="number" matInput formControlName="peso">
        <mat-error *ngIf="moduleForm.get('peso').hasError('required')">{{config.labels.enterWeight}}</mat-error>
        <mat-error *ngIf="moduleForm.get('peso').hasError('min')">{{config.labels.minWeight}}</mat-error>
        <mat-error *ngIf="moduleForm.get('peso').hasError('max')">{{config.labels.maxWeight}}</mat-error>
    </mat-form-field>
    <mat-checkbox formControlName="error_critico" (change)="changeCheck($event)">{{config.labels.isCriticalModule}}</mat-checkbox>
    <br>
    <mat-checkbox formControlName="is_total" (change)="habilitarPeso($event)">{{config.labels.isTotalAffectation}}</mat-checkbox>

    <div class="btns-dialog">
        <button mat-stroked-button (click)="moduleRef.close()" [disabled]="continue">{{config.labels.cancel}}</button>
        <button mat-raised-button color="primary" (click)="updateModule()" [disabled]="continue">{{config.labels.save}}</button>
    </div>
</form>
