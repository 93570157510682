import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { AuditoriaService } from 'src/app/modules/auditoria/services/auditoria.service';

@Component({
  selector: 'app-retroalimentar',
  templateUrl: './retroalimentar.component.html',
  styleUrls: ['./retroalimentar.component.sass']
})
export class RetroalimentarComponent implements OnInit {

  @Input() idMatriz;
  filtroAsesor:string = "";
  filtroCedula:string = "";
  filtroFecha = new FormGroup({
    fechaInicial: new FormControl(),
    fechaFinal: new FormControl(),
  });
  filtroGestion:string = "";
  datosTabla = [];
  length;
  pageSize = 5;
  page = 1;
  constructor(
    private auditoriaService:AuditoriaService,
  ) { }

  ngOnInit(): void {
  }

  getRetroalimentaciones(page?,pageSize?){
    const params = {
      cedula: '',
      fechaInicial:'',
      fechaFinal: '',
      asesor: '',
      retroalimentacion:'',
      numeroReg: this.pageSize,
      page: this.page,
      idMatriz: this.idMatriz
    };
    //Filtros
    if (this.filtroAsesor != '') {
      params.asesor = this.filtroAsesor;
    }
    if (this.filtroCedula != '' && this.filtroCedula != null) {
      params.cedula = this.filtroCedula;
    }
    if (this.filtroFecha.get('fechaInicial').value != '' && this.filtroFecha.get('fechaInicial').value != null) {
      params.fechaInicial = moment(this.filtroFecha.get('fechaInicial').value).format('yyyy-MM-DD');
    }
    if (this.filtroFecha.get('fechaFinal').value != '' && this.filtroFecha.get('fechaFinal').value != null) {
      params.fechaFinal = moment(this.filtroFecha.get('fechaFinal').value).format('yyyy-MM-DD');
    }
    if (this.filtroGestion != '') {
      params.retroalimentacion = this.filtroGestion;
    }
    if (page != '' && page > 0) {
      params.page = page
    }
    if (pageSize != '' && pageSize > 0) {
      params.numeroReg = pageSize
    }
    this.auditoriaService.getRetroalimentar(params).subscribe((resp:any)=>{
      this.datosTabla = resp.data;
      this.length = resp.total;
      this.pageSize = resp.per_page;
    });

  }

  filtroFechas(){
    if (this.filtroFecha.get('fechaInicial').value != null && this.filtroFecha.get('fechaFinal').value != null) {
      this.filtrar();
    }
  }

  filtrar(){
    this.getRetroalimentaciones(this.page,this.pageSize);
  }

}
