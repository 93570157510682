<div class="content-report">

  <div class="header">
    <h1>Reporte de Referidos</h1>
    <p>Bienvenido al módulo reporte de <b>Referidos</b></p>
  </div>

  <div class="row col-12 filter">

    <div class="col-12">
      <button mat-raised-button color="primary" class="myButton" (click)="downloadReport()" style="margin-top: 20px;">
        Descargar reporte
        <span class="fi-rr-download"></span>
      </button>
    </div>

    <div class="table-container mt-3">
      <div class="mat-elevation-z8">
        <table class="col-12 schedule-table" mat-table [dataSource]="dataSource" matSort>
          
          <ng-container matColumnDef="date_of_hire">
            <th class="start" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de contratación</th>
            <td mat-cell *matCellDef="let element"> {{ (element.date_of_hire == 'Sin contrato') ? element.date_of_hire : element.date_of_hire | date:"dd-MM-yyyy" }} </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre</th>
            <td mat-cell *matCellDef="let element">{{ element.first_name + ' ' + element.middle_name + ' ' + element.last_name + ' ' + element.second_last_name }} </td>
          </ng-container>

          <ng-container matColumnDef="document">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento</th>
            <td mat-cell *matCellDef="let element">{{ element.documentIdReferral }} </td>
          </ng-container>

          <ng-container matColumnDef="contact_number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Número de contacto</th>
            <td mat-cell *matCellDef="let element">{{ element.contact_number }} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> E-mail </th>
            <td mat-cell *matCellDef="let element">{{ element.email }} </td>
          </ng-container>

          <ng-container matColumnDef="referredBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Referido por: </th>
            <td mat-cell *matCellDef="let element">{{ element.referredByName }} </td>
          </ng-container>

          <ng-container matColumnDef="documentReferredBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento de quien refirio</th>
            <td mat-cell *matCellDef="let element">{{ element.documentId }} </td>
          </ng-container>

          <ng-container matColumnDef="state">
            <th class="end" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
            <td mat-cell *matCellDef="let element">{{ element.Estado }} </td>
          </ng-container>

          
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

        <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)"
          [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>
      </div>
    </div>

  </div>

</div>
