<div class="landing" *ngIf="!onBoarding">

  <div class="row" >
      <div class="col-6">
        <div class="landing-div-text">
          <br>
          <p class="landing-tittle">SOUL BI</p>
          <br>
          <p class="landing-welcome">¡Bienvenid@<br> {{ userName.rrhh.name | titlecase}}!</p>
          <br>

            <p class="mt-3 landing-sub-tittle text">
              Este es el módulo de <b>SOUL BI</b> en donde podrás visualizar tus dashboards asignados y vivas
              la mejor experiencia haciendo seguimiento de tus indicadores en una sola herramienta.
            </p>

          <br>
          <div>
              <button
                class                       = "w-100 mt-3"
                style                       = "height: 48px"
                mat-stroked-button color    = "primary"
                (click)                     = "onBoadingModular()"
                >Quiero ver el onboarding</button>
          </div>
        </div>
      </div>

      <div class="col-6" style="text-align: right;">
          <img src="../../../assets/images/landing/landing-pages/data_science/Ilustracion_modular_ds.svg" style="height:90%;" alt="">
      </div>
  </div>
  <div class="row">
    <div class="col-12">

    </div>
  </div>
</div>



<div class="img row" style="background-image: url('../../../assets/images/on-boarding/soul_bi/{{images[index]}}')" *ngIf="onBoarding">
  <div class="col-12 mt-4 text-end">
    <button class="btnlanding" mat-stroked-button color="primary" [mat-dialog-close]="true">Finalizar onboarding</button>
  </div>
  <div class="col-12 mt-4 text-end">
    <button *ngIf="index > 0" class="btn-back" mat-stroked-button color="primary" (click)="changeBack()">Volver</button>

    <button *ngIf="images.length > index + 1" class="btn-next" mat-raised-button (click)="changeNext()">Siguiente</button>
    <button *ngIf="images.length == index + 1" class="btn-next" mat-raised-button [mat-dialog-close]="true">Terminar</button>
  </div>
</div>
