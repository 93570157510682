import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RrhhComponent } from './rrhh.component';
import { RrhhRoutingModule } from './rrhh-routing.module';
import { RequestsModule } from './modules/requests/requests.module';
import { SharedModule } from '../../shared/shared.module';
import { CandidatesModule } from './modules/candidates/candidates.module';
import { CandidateTestsModule } from './modules/candidate-tests/candidate-tests.module';
import { TrainingGroupsModule } from './modules/training-groups/training-groups.module';
import { EmployeeModule } from './modules/employee/employee.module';
import { MedicalTestModule } from './modules/medical-test/medical-test.module';
import { PositionsModule } from './modules/positions/positions.module';
import { AssesmentsModule } from './modules/assesments/assesments.module';
import { AssignUserModule } from './modules/assign-user/assign-user.module';
import { ReportsModule } from './modules/reports/reports.module';
import { UpdatesModule } from './modules/updates/updates.module';
import {TemplatesModule} from './modules/templates/templates.module';


@NgModule({
  declarations: [RrhhComponent],
  imports: [
    CommonModule,
    RrhhRoutingModule,
    RequestsModule,
    CandidatesModule,
    CandidateTestsModule,
    TrainingGroupsModule,
    EmployeeModule,
    SharedModule,
    MedicalTestModule,
    PositionsModule,
    AssesmentsModule,
    AssignUserModule,
    ReportsModule,
    UpdatesModule,
    TemplatesModule,
  ]
})
export class RrhhModule { }
