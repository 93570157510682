import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../core/services/rest/auth.service';



@Injectable({
  providedIn: 'root'
})
export class MedicaltestService {
  user;
private server =  environment.RRHH_URL;

  constructor(private http:HttpClient,  private authService:AuthService) {this.user = this.authService.getUser(); }

  getIps(state){
    const entries = {};
    entries['state'] = state;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}medical/test/ips`, {params});
  }
  getMedicalTestTypes(){
    return this.http.get(`${this.server}medical/test/types`);
  }

  getCandidates(entries?) {
    // entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}medical/test/candidates`, {params});
  }

  getMedicalTests(candidateId:number){
    return this.http.get(`${this.server}medical/test/candidate/${candidateId}`);
  }

  createMedical(candidateId,entries){
    const params = new HttpParams( { fromObject: entries } );
    return this.http.post(`${this.server}candidate/medical/test/${candidateId}`, params);
  }

  sendMedicalTestNotification(candidateId){
    return this.http.get(`${this.server}candidate/medical/test/notification/${candidateId}`);
  }
  getStatus(direction){
    return this.http.get( `${this.server}${direction}` )
  }
  changeStatus(medicalId,status,hiredUserId){
    return this.http.get( `${this.server}candidate/medical/test/status/${medicalId}/${status}/${hiredUserId}` )
  }

  uploadAttachment(medicalId,file){
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(`${this.server}candidate/medical/test/upload/${medicalId}`, formData);
  }
  downloadAttachment(medicalId){
    return this.http.get( `${this.server}candidate/medical/test/download/${medicalId}`,{responseType:'blob'} );
  }

  /**
   * @author David Reinoso
   * @purpose Servicio para descargar la plantilla de asignacion masiva de examenes medicos
   */
  downloadTemplateMedicalTest() {
    const httpOptions = { responseType: 'blob' as 'json' };
    return this.http.get<any>(`${this.server}candidate/medical/test/template`, httpOptions);
  }

  /**
   * @author David Reinoso
   * @purpose Servicio para enviar archivo de asignacion masiva de examenes medicos
   */
  uploadMedicalTest(data) {
    return this.http.post<any>(`${this.server}candidate/medical-test-import`, data);
  }
}
