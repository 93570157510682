<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>
<h3>Asignar analista de usuarios</h3>
<hr>
<mat-dialog-content >

    <p>Usuarios disponibles</p>
    <mat-radio-group class="example-radio-group" [formControl]="analistUser">
        <mat-radio-button fxLayout="column"  class="example-radio-button"  *ngFor="let user of analistUsers" [value]="user.id">
            <span>{{user.name}}</span>
        </mat-radio-button>
    </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button mat-flat-button color="primary" [disabled]="!analistUser.valid" (click)="sendAssign()">Asignar</button>
</mat-dialog-actions>
