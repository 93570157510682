<table [ngClass]="{'custom-table' : showHorizontalScroll}" class="" mat-table [dataSource]="dataTables"
  [ngStyle]="{'overflow': stickyTable ? '' : 'hidden' }">
  <ng-container *ngFor="let column of columnsTable">
    <ng-container [matColumnDef]="column.key">
      <ng-container [ngSwitch]="column.type">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container *ngSwitchCase="'checkbox'">
            <mat-checkbox color="accent" (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ column.name }}
          </ng-container>
        </th>
      </ng-container>
      <td mat-cell *matCellDef="let element; let i = index">
        <div [ngSwitch]="column.type">
          <div *ngSwitchCase="'currency'">
            <div>S/ {{ element[column.key] | currency: '':'':'1.2-2' }}</div>
          </div>
          <div *ngSwitchCase="'events'" class="d-flex align-items-center justify-content-center">
            <span *ngIf="buttonsOptions.length > 0">
              <ng-container *ngFor="let button of buttonsOptions">
                <button mat-icon-button *ngIf="button.action === 'href'; else buttonBasic" [disabled]="element.hasOwnProperty('link') && element.link.length == 0" (click)="eventButton(element, button.action)"
                  ><span [class]="button.class"></span></button>
                <ng-template #buttonBasic>
                  <button mat-icon-button (click)="eventButton(element, button.action)"
                  ><span [class]="button.class"></span></button>
                </ng-template>
              </ng-container>
            </span>
            <mat-slide-toggle color="primary" *ngIf="isToogle && element.hasOwnProperty('state')"
              (change)="eventSlider($event, element)" [checked]="element.state === 1 ? true : false"
              [disabled]="(element.hasOwnProperty('is_editable') && element.is_editable === 2)"></mat-slide-toggle>
            <mat-radio-group *ngIf="isRadioButton" class="ms-1">
              <mat-radio-button color="primary" [checked]="element.id == checkedRegister" (change)="eventRadio(element)"
                [value]="element.id"></mat-radio-button>
            </mat-radio-group>
          </div>
          <div *ngSwitchCase="'checkbox'" class="d-flex align-items-center justify-content-center">
            <mat-checkbox color="primary" (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(element) : null; changeCheckbox($event, element, i)"
              [checked]="selection.isSelected(element)">
            </mat-checkbox>
          </div>
          <div *ngSwitchCase="'input'" class="d-flex align-items-center justify-content-center">
            <mat-form-field appearance="fill" class="w-100 h-25">
              <mat-label>Valor</mat-label>
              <input type="number" (input)="captureInput()" (blur)="blurInput(element, formInput.controls[i].value)"
                currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0  }" matInput
                [formControl]="formInput.controls[i]">
              <mat-error *ngIf="formInput.controls[i].errors">
                <span *ngIf="formInput.controls[i].errors.required">Este es requerido</span>
                <span *ngIf="formInput.controls[i].errors.min">El valor debe ser superior a 0</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngSwitchDefault>
            <div *ngIf="showTooltipTable && element[column.key]?.length > 30; else sinTootip"
              matTooltipClass="tooltip-text" #tooltip="matTooltip" [matTooltip]="element[column.key]"
              matTooltipPosition="below">
              {{ element[column.key] | slice : 0 : 30 }}<small>...</small>
            </div>
            <ng-template #sinTootip>
              {{ element[column.key] }}
            </ng-template>
          </div>
        </div>
      </td>
    </ng-container>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="keyColumns; sticky: stickyTable"></tr>
  <tr style="background: white" mat-row *matRowDef="let row; columns: keyColumns"></tr>
  <tr style="background: white" class="mat-row" *matNoDataRow="">
    <td class="mat-cell" style="font-weight: 600;" [attr.colspan]="keyColumns.length">
      {{ messageLoading }}
    </td>
  </tr>
</table>
<mat-paginator *ngIf="configPaginator" (page)="onChangePage($event)" [length]="configPaginator.length"
  [pageIndex]="configPaginator.pageIndex" [pageSize]="configPaginator.pageSize"
  [pageSizeOptions]="[5, 10, 20, 50, 100]">
</mat-paginator>
<div *ngIf="showButtonSubmit" class="row justify-content-center mt-3">
  <div class="col-3 justify-content-center">
    <button [disabled]="selection.selected.length == 0" class="custom-button w-100" (click)="submit()" mat-raised-button
      color="primary">
      Guardar
    </button>
  </div>
</div>