<button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<form class="containerDialog">
    <h2>{{config.title}}</h2>
    <mat-divider class="mb-4"></mat-divider>
    <mat-chip-list aria-label="Fish selection">
        <mat-chip *ngFor="let matriz of matrices" class="col-5 text-center">{{matriz.nombre | slice:0:20}}</mat-chip>
    </mat-chip-list>
</form>
