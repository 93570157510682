import { DatePipe } from '@angular/common';
import { DescargaComponent } from './../historial-aprobador/descarga/descarga.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Solicitud } from '../../../Class/solicitud';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SolicitudesService } from '../../../../services/rest/solicitudes.service';
import { DestroyComponentService } from '../../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import { MatDialog } from '@angular/material/dialog';
import { CrearSolicitudComponent } from '../crear-solicitud/crear-solicitud.component';
import { ProcesarSolicitudComponent } from '../procesar-solicitud/procesar-solicitud.component';
import { ConsultarProveedorComponent } from '../consultar-proveedor/consultar-proveedor.component';
import { AprobarSolcitudComponent } from '../aprobar-solcitud/aprobar-solcitud.component';
import { saveAs as importedSaveAs } from 'file-saver';
import { CompaniaService } from 'src/app/modules/compras/services/rest/compania.service';

@Component({
  selector: 'app-gestion-inicial',
  templateUrl: './gestion-inicial.component.html',
  styleUrls: ['./gestion-inicial.component.sass']
})
export class GestionInicialComponent implements OnInit {
  companias : any[] = [];
  filteredOptionsCompania : any[] = [];
  length = 0;
  rol = 1;
  pageSize = 5;
  page = 1;
  isPage = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  newRolComponent = false;
  filterState;
  filterCompany;

  requestStates: any = [];

  displayedColumns: string[] = ['acciones', 'request_date', 'title', 'description', 'created_by', 'company', 'state'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private solicitudService: SolicitudesService,
              private destroyService    : DestroyComponentService,
              private alertsService     : AlertsService,
              public dialog             : MatDialog,
              private datepipe          : DatePipe,
              private companiaService   : CompaniaService,
              ) { }


   ngOnInit(): void {
     this.solicitudService.getRequestStates().subscribe(resp => {
       this.requestStates = resp.data;
     });

     this.companiaService.getCompany().subscribe(comp=>{
        this.companias = comp.data;
        this.filteredOptionsCompania = this.companias;
      });
    this.getSolicitudes();
  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que trae los roles paginados
   * @param size cantidad de roles a traer en la tabla
   * @param page pagina que va a mostrar la tabla
   */
   getSolicitudes(): void {

    if (this.dataSource.filter === '') {
      this.dataSource.filter = null;
    }
    const params = {
      isPage    : this.isPage,
      pageSize  : this.pageSize,
      page      : this.page,
      rol       : this.rol,
      company   : this.filterCompany,
      estados   : this.filterState,

    }
    this.solicitudService.getSoliciudesPaginate(params)
      .subscribe((Response) => {

        for (var i = 0; i < Response.data.length; i++){
          let item = Response.data[i]
          item = Object.assign(item, { orderBy: new Date(item.created_at)})
        }

        this.dataSource.data    = Response.data.sort((a, b) => b.orderBy - a.orderBy);
        this.dataSource.sort    = this.sort;
        this.length             = Response.total;
        this.pageSize           = Response.per_page;

    });

  }

  filterByState(event) {
    this.filterState = event;
    this.getSolicitudes();
  }

  /**
  * @author Andres Giraldo
  * @createdate 2022-03-10
  * Metodo que filtra las companias segun el autocomplete
  */
   filterByCompany(company) {
    this.filterCompany  = company;
    this.getSolicitudes();
  }

   /**
  * @author Andres Giraldo
  * @createdate 2022-03-10
  * Metodo que muestra el nombre de la empresa seleccionada
  */
    displayFnCompania(id: number): string{
      if (!id) { return ''; }
      let index = this.companias.findIndex(resp => resp.id === id);
      return this.companias[index].name;

    }

  /**
  * @author Andres Giraldo
  * @createdate 2022-03-10
  * Metodo que filtra las companias segun el autocomplete
  */
    filterCompanies(v){
      const filterValue = v.toLowerCase();
      this.filteredOptionsCompania  = this.companias.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que se encarga del movimiento del paginador
   * @param event cuando se da click en el paginador trae este evento
   */
  pageEvent(event: any): void {

    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getSolicitudes();

  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que abre el modal de crear un nuevo rol, al cerral el modal se refresca la tabla
   */
  newSolicitud(): void {
    const dialogNewRol = this.dialog.open(CrearSolicitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Crear Solicitud',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.pageSize = 5;
      this.ngOnInit();
    });

  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que abre el modal de editar usuario, envia un usuario, al cerrar el modal se refresca la tabla
   * @param rol rol a editar
   */
  editSolicitud(solicitud: Solicitud): void{
    const dialogNewRol = this.dialog.open(ProcesarSolicitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Detalles solicitud',
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.pageSize = 5;
      this.ngOnInit();
    });
  }

  procesarSolictud(solicitud: Solicitud): void{
    const dialogNewRol = this.dialog.open(ProcesarSolicitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'Procesar',
        title: 'Detalles solicitud',
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.pageSize = 5;
      this.ngOnInit();
    });
  }

  viewSolicitud(solicitud: Solicitud) {
    const dialogNewRol = this.dialog.open(ProcesarSolicitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'ver',
        title: 'Detalles solicitud',
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.pageSize = 5;
      this.ngOnInit();
    });
  }

  generarSolicitud(solicitud: Solicitud): void {
    const dialogNewRol = this.dialog.open(ConsultarProveedorComponent, {
      width: '50%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Editar Solicitudes',
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.pageSize = 5;
      this.ngOnInit();
    });
  }

     /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que abre el modal de editar usuario, envia un usuario, al cerrar el modal se refresca la tabla
   * @param rol rol a editar
   */
      viewSolicitudAprobada(solicitud: Solicitud): void{
        const dialogNewRol = this.dialog.open(AprobarSolcitudComponent, {
          width: '80%',
          disableClose: true,
          data: {
            type: 'view',
            viewInfo: true,
            title: 'Detalles solicitudes',
            coordinador: false,
            solicitud
          },
          panelClass: 'custom-dialog-container',
        });
      }






  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

  clearFilters() {
    this.filterCompany = '';
    this.filterState = '';
  }


  descargaGestion(){
    this.dialog.open( DescargaComponent , {
      width : '60%',
      data:{
        type : "solicitud"
      }
    } )

  }

}
