import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { EscalationService } from '../../../services/rest/escalation.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';

@Component({
  selector: 'app-create-state',
  templateUrl: './create-state.component.html',
  styleUrls: ['./create-state.component.sass']
})
export class CreateStateComponent implements OnInit {

  rolState: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public escalationService: EscalationService,
              private alertsService: AlertsService,
              private destroyService: DestroyComponentService) { }

  ngOnInit(): void {
    this.formControl();
    this.setEdit();
  }

  get error(): any { return this.rolState.controls; }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo donde se establecen las validaciones del formulario
   */
  formControl(): void{

    this.rolState = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', [Validators.required]),
    });

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que se encarga de guardar un estado
   * @param rolState estado a guardar
   */
  saveState(rolState): void{

    if (this.data.type === 'crear'){
      const estado = {
        name: rolState.value.nombre,
        description: rolState.value.descripcion,
        state: 1
      };

      this.escalationService.putFormState(estado).subscribe((resp) => {
        this.alertsService.alertSuccess('Guardado', resp.data);
      });
    }
    else if (this.data.type === 'editar'){
      this.editState(rolState);
    }

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que en caso que el modal sea para editar, setea los campos
   */
  setEdit(): void {
    if (this.data.type === 'editar') {
      this.rolState.controls.nombre.setValue(this.data.data.name);
      this.rolState.controls.descripcion.setValue(this.data.data.description);
    }
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo encargado de realizar la edicion
   * @param rolState estado a editar
   */
  editState(rolState): void {
    const state = {
      name: rolState.value.nombre,
      description: rolState.value.descripcion,
    };

    this.escalationService.editState(this.data.data.id, state).subscribe((resp) => {
      this.alertsService.alertSuccess('Editado', resp.data);
    });
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

}
