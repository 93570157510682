import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Import } from '../interfaces/import';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../core/services/rest/auth.service';
import { stringify } from '@angular/compiler/src/util';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CandidatesService {

  private server =  environment.RRHH_URL;
  user;
  constructor(private http: HttpClient, private activeRoute: ActivatedRoute, private authService: AuthService) {
    this.user = this.authService.getUser();
   }

   httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  candidatesByRequest = [];
  candidateByToken;

  /** Metodo que retorna los tipos de documentos
   * @author Lina Hueso
    */
   getIdTypes(){
    return this.http.get(`${this.server}idtypes`);
  }

  getCandidatesByRequest(id, entries){

    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}candidate/request/${id}`, {params});
  }
  getCandidate(candidateId){
    const entries = {};
    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}candidate/data/${candidateId}`, {params});
  }

  registerCandidate(requestId: number, entries: any){
    entries['userId'] = this.user.rrhh_id;
    let params = new HttpParams( { fromObject: entries } );
    return this.http.post(`${this.server}request/candidate/${requestId}`, params);
  }
  getCandidateByToken(token) {
    const entries = {};
    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}candidate/landing/${token}`, {params});
  }
  importData(file: Import, params?: any){
    const formData = new FormData();
    formData.append('file', file.file);
   if (params) formData.append('userId', this.user.rrhh_id);
   if (params) formData.append('requestId', params.requestId);

    return this.http.post(`${this.server}candidate/import`, formData);
  }

  getComments(candidateId, entries){
    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}candidate/comments/${candidateId}`, {params});
  }
  transferCandidate( entries: any ){
    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.post(`${this.server}transfer/candidate`, params);
  }
  candidateById(idTypeId, idNumber, requestId){
    const entries = {};
    entries['userId'] = this.user.rrhh_id;
    entries['requestId'] = requestId;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}candidate/validation/${idNumber}/${idTypeId}`, {params});
  }

  candidateByState(requestId, state){
    const params = new HttpParams( { fromObject: state } );
    return this.http.get(`${this.server}candidates/state/${requestId}`, {params});
  }

  getCandidateDataJson(candidateId, data) {
    const entries = {};
    entries['userId'] = this.user.rrhh_id;
    entries['data'] = data;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}candidate/json/data/${candidateId}`, {params});
  }
  createJsonCandidate( candidateId, json, entries ){
    return this.http.post(`${this.server}candidate/json/${candidateId}/${json}`, entries, this.httpOptions);
  }

  uploadFiles(candidateId, entries: File, position) {
    //entries['position'] = position;
    const formData = new FormData();
    formData.append('documents', entries);
    formData.append('position', position);
    return this.http.post(`${this.server}candidate/documents/${candidateId}`, formData);
  }

  checkSchedulesCandidates(analystId){
    return this.http.get(`${this.server}candidate/check/schedules/${analystId}`);
  }
  assignCandidateToAnalyst(analystId, entries){
    const params = new HttpParams( { fromObject: entries } );
    return this.http.post(`${this.server}candidate/asign/analyst/${analystId}`, params);
  }

  assignCandidateToLeaderTrainer(trainerId, entries){
    const params = entries;
    return this.http.post(`${this.server}candidate/assign/responsable/${trainerId}`, params);
  }

  candidatesByIds(candidateIds){
    const entries = {
      candidateIds,
      codeRequest: 'true'
    }
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}candidates/by/ids`, {params});
  }
  getCandidatesToAnalystByDaySelected(requestId, entries){
      const params = new HttpParams( { fromObject: entries } );
      return this.http.get(`${this.server}candidates/analyst/date/selected/${requestId}`, {params});
  }
  reAssignCandidateToAnalyst(analystId, entries){
    const params = new HttpParams( { fromObject: entries } );
    return this.http.post(`${this.server}candidate/reasign/analyst/${analystId}`, params);
  }
  cancelSchedulesCandidates(analystId, entries){
    const params = new HttpParams( { fromObject: entries } );
    return this.http.post(`${this.server}candidate/cancel/analyst/${analystId}`, params);
  }
  getDocumentSummary(candidateId){
    return this.http.get(`${this.server}documents/${candidateId}` );
  }

  changeCandidateEmail(candidateId, email){
    return this.http.post(`${this.server}candidate/${candidateId}/businessMail`,  email);
  }

  deleteDocumentCandidate(documentId) {
    const entries = {};
    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}candidate/delete/attachment/${documentId}`, {params});
  }

  deleteDocumentAttachment(body) {
    const formData = new FormData();
    formData.append('id', body.id || '');
    formData.append('cause', body.cause || '');
    formData.append('comment', body.comment || '');

    return this.http.post(`${this.server}candidate/delete/attachment`, formData);
  }

  getCausesToDeleteFile() {
    return this.http.get(`${this.server}candidate/attachment/causes`);
  }

  candidateByUserLoggued(entries){
    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}candidates`, {params});
  }
  candidateByUserLogguedForGroups(groupId){
    const entries = {};
    entries['userId'] = this.user.rrhh_id;
    entries['groupId'] = groupId;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}formationgroup/candidates/availables`, {params});
  }

  candidateAll(candidateId){
    const entries = {};
    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}candidate/all/${candidateId}`, {params});
  }

  validateDocumentCandidate(attachmentId, comment, createdAt, verified, typification, fileKey){
    const entries = {}
    const data = {};
    entries['attachmentId'] = attachmentId;
    entries['key'] = fileKey;
    data['comment'] = comment;
    data['user_creator_id'] = this.user.rrhh_id;
    data['createdAt'] = createdAt;
    data['verified'] = verified;
    data['subtyping'] = typification;


    entries['data'] = JSON.stringify(data);
    const params = new HttpParams( { fromObject: entries } );
    return this.http.post(`${this.server}candidate/document/validate/`, params);
  }

  getHistory(candidateId){
    const entries = {};
    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}candidate/history/${candidateId}`, {params});
  }

  uploadDocumentCandidate(candidateId, entries: File, documentTypeId,attachmentId): any {
    const formData = new FormData();
    formData.append('documents', entries);
    formData.append('id', attachmentId );
    formData.append('file_attachment_id', documentTypeId);
    return this.http.post(`${this.server}candidate/document/upload/${candidateId}`, formData);
  }

  getAttachments(candidateId){
    const entries = {};
    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}candidate/attachments/${candidateId}`, {params});
  }

  getDocumentosInactivos(candidateId){
    return this.http.get(`${this.server}candidate/get_documentos_inactivos/${candidateId}`);
  }

  activarDocumentos(idDocument){
    const formData = new FormData();
    formData.append('idDocument', idDocument);
    return this.http.post(`${this.server}candidate/activar_documentos`,formData);
  }

  agendarCitaFirmaContrato(candidateId, date){
    const entries = {}
    entries['candidateId'] = candidateId;
    entries['stateKey'] = 'contratacion';
    entries['date'] = date;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.post(`${this.server}candidate/schedule/date/`, params);
  }

  generateCandidateContract (candidateId) {
    const entries = {};
    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}candidate/generate/contract/${candidateId}`, {params});
  }

  generateCandidateContractv2 (candidateId) {
    const entries = {};
    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}candidate/generate/contractv2/${candidateId}`, {params});
  }

  generateQR (candidateId) {
    const entries = {};
    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}candidate/generate/qr/${candidateId}`, {params});
  }
  generateDocuments(entries){
    const params = new HttpParams( { fromObject: entries } );
    return this.http.post(`${this.server}candidate/generate/document`, params);

  }
  saveAgreement(direction, data){
    return this.http.post(`${this.server}${direction}`, data);
  }
  downloadFormat(): Observable<any>{
      return this.http.get(`${this.server}candidate/import/format`, {responseType:'blob'});
    }

/**
 * @author Karol García
 * @createdate 2021-05-10
 * servicio que descarga documentos del candidato
 */
  downdloadFile(direction){
    return this.http.get(`${this.server}${direction}`, {responseType:'blob'});
  }
  /**
 * @author Karol García
 * @createdate 2021-05-10
 * servicio envia el email de contrato
 */
  sendContract(direction){
    return this.http.get(`${this.server}${direction}`);
  }
  getCredencials(direction){
    return this.http.get(`${this.server}${direction}`);
  }

     /**
 * @author Daniel Dominguez
 * @createdate 2022-03-31
 * servicio que modifica pasivo vacacional
 */
      PassiveHoliday(data){
        return this.http.post(`${this.server}candidate/passiveHoliday`, data);
      }

     /**
       * @author Carlos Nieto
       * @createdate 2022-07-31
       * @params {id:candidateID, email:candidateEmail}
       * servicio que restablece las credenciales de self por defecto y renvia el email al candidato
       */
      resentCredentialsByEmail(json){
        return this.http.post(`${this.server}candidates/updatePassRrhh`, json);
      }

  /**
   * @author Lina Hueso
   * @createdate 28/07/2022
   * Retornar estados para el candidato
   */
   getStates(){
    return this.http.get(`${this.server}states`);
  }

  /**
       * @author Carlos Nieto
       * @createdate 2022-09-22
       * @params {id_documents:#documento, status:true or false}
       * metodo que trae los usuarios por numero de documento y por estado si se le pasa
       */
   findbyiddocument(json){
    return this.http.post(`${this.server}users/findbyiddocument/multi?`, json);
  }

  /**
   * Método para obtener el historico de candidatos
   * @author Nicoll ramirez
   * @param {filters} JSON datos a filtrar
   */
   getChangesHistorical(filters){
    const httpOptions = {
      params: new HttpParams()
        .set('page', filters.page || '')
        .set('per_page', filters.per_page || 5)
    };
    return this.http.get(`${this.server}candidates/cv/historical/${filters.candidateId}`, httpOptions);
  }
  /**
   * Retornar idrh por id candidato
   */
  getHiredUserID(candidateId){
    return this.http.get(`${this.server}candidate/rrhhId/${candidateId}`);
  }

  /**
   * Servicio para actulizar la campañ{a}
   */
  changeCampaign(hiredUserId,campaign_id){
    return this.http.post(`${this.server}user/changecampaign/${hiredUserId}`,campaign_id);
  }


  /**
   * Servicio para actulizar la campañ{a}
   */
  saveTemporaryCampaignAssignment(data,){
    return this.http.post(`${this.server}user/temporary_campaign_assignment`,data);
  }

  getReferenceFileName() {
    return this.http.get(`${this.server}reference/search`);
  }

  upPersonalReferenceTemplate(data) {
    const formData = new FormData();
    formData.append('template', data.template);
    return this.http.post(`${this.server}reference/upload`, formData);
  }


}
