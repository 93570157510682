<div class="admin-horarios">

  <div class="header">
    <h1>Reporte de Inicio de Sesión</h1>
    <p>Bienvenido al módulo reporte de Horarios, recuerda que puedes<br>realizar el filtrado de información<strong></strong><br><br>
      Este informe muestra los asesores que realizaron marcación en el aplicativo.
    </p>
  </div>

  <div class="row col-12 filter">

    <div class="col-12">
      <div class="row">
        <div class="col-8">

          <form class="form" [formGroup]="filterForm">

            <mat-form-field class="col-3" appearance="fill">
              <mat-label>Semana:</mat-label>
              <input type="text" matInput formControlName="rangeDate" [matAutocomplete]="rangeList" required #inputAutoCompleteScReport>
              <mat-autocomplete #rangeList="matAutocomplete" [displayWith]='displayFnRanges.bind(this)'
                                (optionSelected)="onChange($event)" required>
                <mat-option *ngFor="let range of filteredOptionsRanges | async" [value]="range.id"
                            style="font-size: 12px!important; text-transform: uppercase"
                            matTooltip="{{ range.name }}"
                            matTooltipPosition="right">
                  {{ range.name }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="error.rangeDate.errors"><span *ngIf="error.rangeDate.errors.required">Campo es
                  requerido</span></mat-error>
              <mat-error *ngIf="error.rangeDate.errors">Opción no válida</mat-error>
            </mat-form-field>

            <mat-form-field class="col-3" style="padding: 1%;" appearance="fill">
              <mat-label>Campaña:</mat-label>
              <input type="text" [readonly]="!filters.startWeek" matInput formControlName="campaigns" [matAutocomplete]="campaignList" required>
              <mat-autocomplete #campaignList="matAutocomplete" [displayWith]='displayFnCampaigns.bind(this)'
                (optionSelected)="consultarGrupos($event)" required>
                <mat-option *ngFor="let campaign of filteredOptionsCampaigns | async" [value]="campaign.id">
                  {{campaign.name}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="error.campaigns.errors"><span *ngIf="error.campaigns.errors.required">Campo es
                  requerido</span></mat-error>
              <mat-error *ngIf="error.campaigns.errors">Opción no válida</mat-error>
            </mat-form-field>

            <mat-form-field class="col-3" style="padding: 1%;" appearance="fill">
              <mat-label>Grupo:</mat-label>
              <input type="text" [readonly]="!filters.startWeek" matInput formControlName="groups" [matAutocomplete]="groupsList" required>
              <mat-autocomplete #groupsList="matAutocomplete" [displayWith]='displayFnGroups.bind(this)' required>
                <mat-option *ngFor="let group of filteredOptionsGroups | async" [value]="group.id">
                  {{group.name}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="error.groups.errors"><span *ngIf="error.groups.errors.required">Campo es
                  requerido</span></mat-error>
              <mat-error *ngIf="error.groups.errors">Opción no válida</mat-error>
            </mat-form-field>

            <mat-form-field class="col-3" style="padding: 1%;" appearance="fill">
              <mat-label>Documento</mat-label>
              <input matInput type="text" [readonly]="!filters.startWeek" #input autocomplete="off" (keydown.enter)="onBlurMethod($event.target.value)">
              <mat-select name="ampm" class="ampm" matSuffix [formControl]="typeDocument"
                style="width: 60px; margin-left: 5px;">
                <mat-option *ngFor="let item of documents" [value]="item?.id">{{item.key}}</mat-option>
              </mat-select>
            </mat-form-field>


          </form>
        </div>
        <div class="col-4">
          <button mat-raised-button color="primary" class="myButton" (click)="downloadReport()"
            style="margin-top: 20px;">
            Descargar reporte
            <span class="fi-rr-download"></span>
          </button>
        </div>


      </div>
    </div>

    <div class="table-container">
      <div class="mat-elevation-z8">
        <table class="col-12 schedule-table" mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="name">
            <th class="start" mat-header-cell *matHeaderCellDef mat-sort-header> Nombre</th>
            <td mat-cell *matCellDef="let row"> {{row.name}}</td>
          </ng-container>

          <ng-container matColumnDef="document">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento</th>
            <td mat-cell *matCellDef="let row"> {{row.id_number}} </td>
          </ng-container>

          <ng-container matColumnDef="monday">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Lunes {{week?.monday |date:'dd'}}</th>
            <td mat-cell *matCellDef="let row">
              <p> {{row.monday.jornada}} <span [matTooltipClass]="'my-tooltip'" matTooltip="{{row.monday?.turno}}"
                  class="fi-rr-clock"></span></p>
            </td>
          </ng-container>

          <ng-container matColumnDef="tuesday">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Martes {{week?.tuesday |date:'dd'}} </th>
            <td mat-cell *matCellDef="let row">
              <p> {{row.tuesday?.jornada}} <span [matTooltipClass]="'my-tooltip'" matTooltip="{{row.tuesday?.turno}}"
                  class="fi-rr-clock"></span></p>
            </td>
          </ng-container>

          <ng-container matColumnDef="wednesday">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Miércoles {{week?.wednesday |date:'dd'}} </th>
            <td mat-cell *matCellDef="let row">
              <p> {{row.wednesday?.jornada}} <span [matTooltipClass]="'my-tooltip'"
                  matTooltip="{{row.wednesday?.turno}}" class="fi-rr-clock"></span></p>
            </td>
          </ng-container>

          <ng-container matColumnDef="thursday">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Jueves {{week?.thursday |date:'dd'}} </th>
            <td mat-cell *matCellDef="let row">
              <p> {{row.thursday?.jornada}} <span [matTooltipClass]="'my-tooltip'" matTooltip="{{row.thursday?.turno}}"
                  class="fi-rr-clock"></span></p>
            </td>
          </ng-container>

          <ng-container matColumnDef="friday">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Viernes {{week?.friday |date:'dd'}} </th>
            <td mat-cell *matCellDef="let row">
              <p> {{row.friday?.jornada}} <span [matTooltipClass]="'my-tooltip'" matTooltip="{{row.friday?.turno}}"
                  class="fi-rr-clock"></span> </p>
            </td>
          </ng-container>

          <ng-container matColumnDef="saturday">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Sábado {{week?.saturday |date:'dd'}} </th>
            <td mat-cell *matCellDef="let row">
              <p> {{row.saturday?.jornada}} <span [matTooltipClass]="'my-tooltip'" matTooltip="{{row.saturday?.turno}}"
                  class="fi-rr-clock"></span></p>
            </td>
          </ng-container>

          <ng-container matColumnDef="sunday">
            <th class="end" mat-header-cell *matHeaderCellDef mat-sort-header> Domingo {{week?.sunday |date:'dd'}} </th>
            <td mat-cell *matCellDef="let row">
              <p> {{row.sunday?.jornada}} <span [matTooltipClass]="'my-tooltip'" matTooltip="{{row.sunday?.turno}}"
                  class="fi-rr-clock"></span></p>
            </td>
          </ng-container>
          <!-- (click)="viewJustification(element)" -->

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


        </table>

        <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)"
          [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>

      </div>
    </div>

  </div>

</div>
