import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { SolicitudesService } from 'src/app/modules/compras/services/rest/solicitudes.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { Solicitud } from '../../../Class/solicitud';
import { AprobarSolcitudComponent } from '../aprobar-solcitud/aprobar-solcitud.component';
import { ConsultarProveedorComponent } from '../consultar-proveedor/consultar-proveedor.component';
import { CrearSolicitudComponent } from '../crear-solicitud/crear-solicitud.component';
import { DescargaComponent } from '../historial-aprobador/descarga/descarga.component';
import { ProcesarSolicitudComponent } from '../procesar-solicitud/procesar-solicitud.component';
import { BlockRequestsComponent } from '../block-requests/block-requests.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gestion-inicial-cards',
  templateUrl: './gestion-inicial-cards.component.html',
  styleUrls: ['./gestion-inicial-cards.component.sass']
})
export class GestionInicialCardsComponent implements OnInit {

  user: any;
  length = 0;
  rol = 2;
  pageSize = 8;
  page = 1;
  isPage = 1;
  pageSizeOptions: number[] = [4, 8, 24, 100];
  newRolComponent: boolean = false;
  requestStates: any = [];
  filterDate: string = null;
  filterStates: any[] = null;
  public solicitudes: [] = [];
  defaultStates = [1,2,3,4,5,6,8,9,11,12,13,14];
  filters: any = [];
  filterNameRequest: string = null;
  filterNameApplicant: string = null;
  filterConsecutiveRequests: number = null;


  constructor(
    private solicitudService: SolicitudesService,
    private destroyService: DestroyComponentService,
    private alertsService: AlertsService,
    private authService: AuthService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUser().rrhh_id;
    this.solicitudService.getRequestStates().subscribe(resp => {
      //this.requestStates = resp.data;
      this.requestStates= resp.data.filter(state =>{
        let flag = false;
        this.defaultStates.map(s =>{
            if(s === state.id) flag = true;
        })
        return flag;
      });
    });
    const filterSaveIntoStorage = JSON.parse(localStorage.getItem('filter_request'));
    if (filterSaveIntoStorage && filterSaveIntoStorage.length > 0) {
      this.filterStates = filterSaveIntoStorage;
      this.filters = filterSaveIntoStorage;
    } else {
      this.filterStates = this.defaultStates;
    }
    this.getSolicitudes();
  }

  getSolicitudes() {
    for (let index = 0; index < this.filterStates.length; index++) this.filterStates = this.filterStates[index] == 12 ? [] : this.filterStates;
    const params = {
      isPage: this.isPage,
      size: this.pageSize,
      page: this.page,
      states: this.filterStates,
      fecha: this.filterDate,
      rol : this.rol,
      nameRequest: this.filterNameRequest,
      nameApplicant: this.filterNameApplicant,
      ConsecutiveRequest: this.filterConsecutiveRequests
    }
    this.solicitudService.getSoliciudesPaginate( params)
      .subscribe(resp => {
        this.solicitudes = resp.data.sort((a, b) => {
          if (Number(a.id) > Number(b.id)) {
            return -1;
          }
          if (Number(a.id) < Number(b.id)) {
            return 1;
          }
          return 0;
        });

        this.length = resp.total;
        this.pageSize = resp.per_page;
      });
  }

  newSolicitud() {
    const dialogNewRol = this.dialog.open(CrearSolicitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Crear Solicitud',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      //this.pageSize=5;
      this.ngOnInit();
    });
  }

  viewSolicitud(solicitud: Solicitud) {

    const dialogNewRol = this.dialog.open(AprobarSolcitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'view',
        title: 'Detalle solicitud',
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      //this.pageSize=5;
      //this.ngOnInit();
    });
  }


  editSolicitud(solicitud: Solicitud): void {
    const dialogNewRol = this.dialog.open(ProcesarSolicitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Detalles solicitud',
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.getSolicitudes();
    });
  }

  procesarSolictud(solicitud: Solicitud): void {
    const dialogNewRol = this.dialog.open(ProcesarSolicitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'Procesar',
        title: 'Detalles de la solicitud',
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.getSolicitudes();
    });
  }

anularSolicitud(solicitud){
  Swal.fire({
    icon:'warning',
    title:'¿Estás seguro?',
    html: '¿En verdad deseas <strong>anular esta solicitud</strong>?, recuerda que esta acción será definitiva y no se podrá deshacer.',
    showCancelButton: true,
    showCloseButton: true,
    reverseButtons: true,
    confirmButtonText: "Aceptar",
    cancelButtonText: `Cancelar`,
    width: 650,
    input: 'text',
    confirmButtonColor: '#00ACC1',
    inputValidator: function(value) {
      if(value === '') {
        return 'Por favor ingrese una observación'
      }
      if(value.length >= 600){
        return '¡Advertencia! No puede ser superior a 600 caracteres'
      }
    },
    inputAttributes: {
      autocapitalize: 'off',
      placeholder:'Observaciones',
      class:'alert-input',
      autofocus:'false',
      required: 'true'
    },
    customClass: {
      confirmButton: 'mat-raised-button mat-primary alert-btn',
      cancelButton: 'mat-stroked-button mat-primary mat-button-disabled alert-btn'
    },
    buttonsStyling: false,
  }).then((result) => {
    if(result.isConfirmed){
      const observation = result.value
      const state = 13
      const requests = [{
        cotizacion: null,
        id: solicitud.id
      }];
      this.solicitudService.massiveRequestApproveOrDecline({observation,state,requests}).subscribe(resp => {
        this.alertsService.alertWithAnyProperties(
          '¡Excelente!',
          `La solicitud <strong>${solicitud.title}</strong> ha quedado <strong>anulada</strong> exitosamente`,
          'success',
          'Continuar'
        ).then(res=>{
          if(res.isConfirmed)this.getSolicitudes()
        })
      })
    }
  })
}

  generarSolicitud(solicitud: Solicitud): void {
    const dialogNewRol = this.dialog.open(ConsultarProveedorComponent, {
      width: '50%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Editar Solicitudes',
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.getSolicitudes();
    });
  }


  viewSolicitudAprobada(solicitud: Solicitud): void {
    const dialogNewRol = this.dialog.open(AprobarSolcitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'view',
        title: 'Detalles solicitudes',
        coordinador: false,
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });
  }

  applyFilter($event) {

  }

  filterBydate(event) {
    this.filterDate = (event.target as HTMLInputElement).value;
    this.getSolicitudes();
  }

  filterByState(event) {
    this.filterStates = event;
    localStorage.setItem('filter_request', JSON.stringify(this.filterStates));
    this.getSolicitudes();

  }

  bloqueoSolicitudes () {
    this.dialog.open( BlockRequestsComponent, {
      width: '60%',
      autoFocus:  false
    })
  }

  filterByNameRequest(event) {
    this.filterNameRequest = event.target.value
    this.getSolicitudes();
  }

  filterByNameApplicant(event) {
    this.filterNameApplicant = event.target.value
    this.getSolicitudes();
  }

  filterByConsecutiveRequests(event) {
    this.filterConsecutiveRequests = event.target.value
    this.getSolicitudes();
  }


  /**
 * @author Jose Vicente Silva
 * @createdate 2021-02-04
 * Metodo que se encarga del movimiento del paginador
 * @param event cuando se da click en el paginador trae este evento
 */
  pageEvent(event: any): void {

    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getSolicitudes();

  }


  downloadManagement () {
    this.dialog.open( DescargaComponent , {
      width : '60%',
      data:{
        type:"solicitud"
      }
    } )


  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

}
