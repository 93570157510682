import {Component, Inject, OnInit} from '@angular/core';
import {TemplatesService} from '../../../services/templates.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-template-new',
  templateUrl: './template-new.component.html',
  styleUrls: ['./template-new.component.sass']
})
export class TemplateNewComponent implements OnInit {

  selectedFile: File | null = null;
  contract_type_id: any;

  constructor(
    private templateService: TemplatesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TemplateNewComponent>,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.contract_type_id = this.data.contract_type_id;
  }

  onFileChange(event: any) {
    this.selectedFile = event.target.files[0];
  }

  onSubmit() {
    if (this.selectedFile) {
      this.templateService.storeTemplate(this.selectedFile, this.contract_type_id).subscribe(
        response => {
          this.snackBar.open('Tu plantilla ha sido procesada satisfactoriamente, ' +
            'te recomendamos usar el visualizador para corroborar como se empezaran a generar ' +
            'los contratos a partir de este momento', 'cerrar', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['green-snackbar'],
            duration: 20000,
          });
          console.log('Archivo enviado con éxito:', response);
        },
        error => {
          this.snackBar.open('El documento cargado no ha podido ser procesado correctamente' +
            ' por favor revisa el documento he intenta de nuevo, si persiste la inconsistencia por favor ' +
            'comunícate con soporte', '', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['red-snackbar'],
            duration: 20000,
          });
          console.error('Error al enviar el archivo:', error);
        }
      );
    }
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }
}
