import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { PageEvent } from '@angular/material/paginator';
import { AudioPlayerComponent } from '../search-speech/audio-player-modal/audio-player-modal.component';
import { NoDirigidoService } from '../../services/nodirigido.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { MatSort } from '@angular/material/sort';
import { RequestService } from '../../services/request.service';
import { ViewService } from '../../services/view.service';
import { FormControl } from '@angular/forms';
import { StrategyServersService } from '../../services/strategy.service';
import { AdherenciaModalComponent } from '../search-speech/adherencia-modal/adherencia-modal.component';
import { TranscricpcionStrategyComponent } from '../search-speech/transcription-strategy-modal/transcription-strategy-modal.component';
import { AnalyticsModalComponent } from '../search-speech/analytics-modal/analytics-modal.component';
import { AnalyticsVoiceGeneralComponent } from '../search-speech/analytics-voice-general-modal/analytics-voice-general-modal.component';

interface FileItem {
  file: File; 
  name: string; 
  size: number;
  time_duration?: string;
}
@Component({
  selector: 'app-nodirigido',
  templateUrl: './nodirigido.component.html',
  styleUrls: ['./nodirigido.component.sass']
})
export class NodirigidoComponent implements OnInit, OnDestroy {

  /**
   * Variables de archivos subidos
  */

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  files: FileItem[] = [];


  /**
   * Variables de tabla 
   */
  dataSourceRecordings = new MatTableDataSource<any>();
  dataSourceStrategy = new MatTableDataSource<any>();  

  //ordenamiento tablas 
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('sortRecordings') sortRecordings: MatSort;
  @ViewChild('sortStrategy') sortStrategy: MatSort;

  /**
   * Variables de seleccion
   */
  selectionRecordings = new SelectionModel<any>(true, []);
  selectionStrategy = new SelectionModel<any>(true, []);

  /**
   * Columnas tablas
   */
  displayedColumnsRecordings: string[] = ['checbox', 'created_at', 'user_soul','name_file','time_duration','audio'];
  displayedColumnsStrategy: string[] = ['checbox', 'created_at', 'user_soul','name_file','time_duration','audio','analisis'];
  
  /**
   * Configuracion tabla
   */
  
  configPaginatorRecording:any={ pageSize : 20, length:0,  pageIndex:1 }
  configPaginatorStrategy:any={ pageSize : 20, length:0,  pageIndex:1 }
  
  pageSizeOptions:number[]= [5, 10, 20]

  /**
   * Cabeceras de tablas
   */
  
  tHeadersRecordings:any={
    checbox: "", created_at:'Fecha', user_soul: "Usuario", 
    name_file: "Nombre audio", time_duration: 'Tiempo', audio:'Audio', 
    analisis:'Análisis', adherencia:'Adherencia'
  }

  user: any; // data del usuario segun sesion

  strategyControl:FormControl = new FormControl(null) //formcontrol para selector

  listStrategys:any[] = [] //selector estrategias

  //Variables de control con Array original de datos para tablas que requiren Paginacion estatica
  listrecordingsAnalising:any[] = []
  listRecordingsStrategys:any[] = []
  totalFilesUploaded: number = 0;
  respload: any[] = [];
  startegyComplete: boolean;
  isAllComplete: boolean;
  private isActive = true;

  constructor(
    private dialog: MatDialog,
    private viewservice: ViewService,
    private requestService: RequestService,
    private strategyService: StrategyServersService,
    private noDirigidoService: NoDirigidoService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUser();
    this.getStrategys();
  }

  ngOnDestroy() {
    this.isActive = false; 
  }

  /**
   * Método donde se establecen las validaciones del formulario y adviarte antes de refrescar 
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @method unloadNotification
   * @param {Event} $event - El evento `beforeunload` del navegador.
   * @returns {void}
  */

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.totalFilesUploaded > 0) {
      $event.returnValue = true;
    }
  }

  /**
    * Método que maneja el evento de soltar archivos.
    * @author Yeison Sepulveda
    * @param $event El evento de soltar archivos.
  */

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * Método que maneja la selección de archivos a través de un input de tipo archivo.
   * @author Yeison Sepulveda
   * @createdate 2024-05-08
   * @param files La lista de archivos seleccionados.
  */

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Elimina todos los archivos
   * @author Yeison Sepulveda
   * @createdate 2024-05-08
   * 
  */
  deleteAllFiles() {
    this.files = [];
    this.totalFilesUploaded = 0;
  }

  /**
   * Elimina un archivo de la lista de archivos.
   * @param index El índice del archivo a eliminar.
   * @autor Yeison Sepulveda
  */

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.totalFilesUploaded--;
  }

  /**
   * método para obtener la duración del archivo de audio
   * @param files La lista de archivos a preparar.
   * @autor Yeison Sepulveda
  */
  getAudioDuration(file: File): Promise<number> {
    return new Promise((resolve, reject) => {
      const audioContext = new AudioContext();
      const fileReader = new FileReader();
  
      fileReader.onload = () => {
        audioContext.decodeAudioData(fileReader.result as ArrayBuffer, (buffer) => {
          const duration = buffer.duration;
          resolve(duration);
        }, (error) => {
          reject(error);
        });
      };
  
      fileReader.onerror = (error) => {
        reject(error);
      };
  
      fileReader.readAsArrayBuffer(file);
    });
  }

  /**
   * método para formatear la duración en segundos a hh:mm:ss
   * @param files La lista de archivos a preparar.
   * @autor Yeison Sepulveda
  */
  formatDuration(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return [hours, minutes, secs]
      .map(unit => String(unit).padStart(2, '0'))
      .join(':');
  }

  /**
   * Prepara una lista de archivos para su procesamiento.
   * @param files La lista de archivos a preparar.
   * @autor Yeison Sepulveda
  */
  prepareFilesList(audios: FileList) {
    if (!audios) return;
    for (let i = 0; i < audios.length; i++) {
      const file: File = audios[i];
      const extension = file.name.split('.').pop().toLowerCase();
      if (extension === 'mp3' || extension === 'wav') {
        this.getAudioDuration(file).then(duration => {
          const formattedDuration = this.formatDuration(duration);
          const time_duration = formattedDuration;
          this.files.push({ file: file, name: file.name, size: file.size, time_duration: formattedDuration }); 
          this.totalFilesUploaded++;
        }).catch(error => {
          console.error('Error al obtener la duración del archivo:', error);
        });
      } else {
        Swal.fire({
          title: '¡Aviso!',
          text: 'El o los audios deben estar en formato MP3 o WAV.',
          icon: 'warning',
          confirmButtonText: '¡Entendido!',
          confirmButtonColor: '#2CABBC',
        });
      }
    }
    this.fileDropEl.nativeElement.value = "";
  }
  

  /**
   * Formatea el tamaño de los bytes en una cadena legible.
   * @autor Yeison Sepulveda
   * @createdate 2024-05-08
   * @param bytes El tamaño en bytes a formatear.
   * @param decimals El número de decimales a mostrar (por defecto 2).
   * @returns Una cadena que representa el tamaño formateado.
   */

  formatBytes(bytes: number, decimals: number = 2): string {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  /**
   * Metodo para acargar los audios
   * @author Yeison Sepulveda
   * @createdate 2024-05-08
  */

  loadAudios(stepperReference?: any): void {
    this.deleteColumnAnalytics();
    if (this.totalFilesUploaded === 0) {
      Swal.fire({
        title: 'Alerta',
        text: 'No hay audios para subir.',
        icon: 'warning',
        confirmButtonText: '¡Entendido!',
        confirmButtonColor: '#2CABBC'
      });
    } else if (this.totalFilesUploaded > 20) {
      Swal.fire({
        title: 'Alerta',
        text: 'No se permite cargar más de 20 audios.',
        icon: 'warning',
        confirmButtonText: '¡Entendido!',
        confirmButtonColor: '#2CABBC'
      });
    } else {
      Swal.fire({
        title: `Se consultará ${this.totalFilesUploaded} audios`,
        text: '¿Está seguro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2CABBC',
        confirmButtonText: 'Sí, continuar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {

          //enviar la informacion en el formData
          const formData = new FormData();
          this.files.forEach((fileItem, index) => {
              formData.append(`audios[${index}][file]`, fileItem.file);
              formData.append(`audios[${index}][time_duration]`, fileItem.time_duration);
          });
          formData.append('user', this.user.rrhh_id);
          
          this.noDirigidoService.uploadAudios(formData).subscribe(
            (respUpload) => {
              stepperReference.next();
              this.respload = respUpload;
              this.dataSourceStrategy = new MatTableDataSource<any>();  
              this.processSearchResponse(this.respload);
              this.selectionRecordings.clear();
            },
            (error) => {
              console.error('Error al cargar los audios:', error);
            }
          );
        }
      });
    }
  }

  /**
  * Metodo obtener los audios cargados
  * @author Yeison Sepulveda
  * @createdate 2024-05-08
  */

  processSearchResponse(respSearch): void {
    this.noDirigidoService.getAudios(this.configPaginatorRecording, respSearch).subscribe(
      (respSearch) => {
  
        respSearch.data.data = respSearch.data.data.map((recording) => {
          const otherInfo = JSON.parse(recording.other_information);
          if (otherInfo && otherInfo.user_soul) {
            return { ...recording, user_soul: otherInfo.user_soul };
          }
          return recording;
        });
  
        this.dataSourceRecordings.data = respSearch.data.data.map((recording) => {
          const fecha = new Date(recording.created_at);
          const year = fecha.getFullYear();
          const month = (fecha.getMonth() + 1).toString().padStart(2, '0');
          const day = fecha.getDate().toString().padStart(2, '0');
          return { ...recording, created_at: `${year}-${month}-${day}` };
        });
  
        this.listrecordingsAnalising = this.dataSourceRecordings.data;
        this.dataSourceRecordings.sort = this.sortRecordings;
        this.configPaginatorRecording.length = respSearch.data.total;
      },
      (error) => {
        console.error('Error al cargar la información de los audios:', error);
      }
    );
  }

  /**
   * 
   * Método para seleccionar todos los elementos en la tabla de analsis
    * @author Juan David Guerrero Vargas
    * @update Yeison Sepulveda
  */
  selectAllRecordings(): void {
    this.isAllSelectedRecordings() ?
      this.selectionRecordings.clear() :
      this.dataSourceRecordings.data.forEach(row => {
        if (row.analisis !== 'ERROR' && row.analisis !== 'PENDIENTE' && row.analisis !== 'PRIORITARIO') {
          this.selectionRecordings.select(row);
        }
      });
  }

  /**
   * Método para seleccionar todos los elementos en la página actual
   * @author Juan David Guerrero Vargas
   */
  selectAllStrategy(): void {
    this.isAllSelectedStrategy() ?
          this.selectionStrategy.clear() :
          this.dataSourceStrategy.data.forEach(row => this.selectionStrategy.select(row));
  }

  /**
   * Metodo para reiniciar tabla eliminando colimna de analisis
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
  */

  deleteColumnAnalytics(): void {
    const indexConfigAudio = this.displayedColumnsRecordings.findIndex(column => column === 'analisis');
    if (indexConfigAudio !== -1) {
      this.displayedColumnsRecordings.splice(indexConfigAudio, 1);
    }
  }

  /**
   * Metodo para reiniciar tabla eliminando columna adherencia
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
  */

  deleteColumnAdherencia(): void {
    const indexConfigAudio = this.displayedColumnsStrategy.findIndex(column => column === 'adherencia');
    if (indexConfigAudio !== -1) {
      this.displayedColumnsStrategy.splice(indexConfigAudio, 1);
    }
  }

  /**
   * Método que determinao si los checbox estan seleccionados o no en la tabal de analisis
   * @author Juan David Guerrero Vargas
   * @returns {Boolean}
  */
  isAllSelectedRecordings(){
    const numSelected = this.selectionRecordings.selected.length;
    const numRows = this.dataSourceRecordings.data.length;
    return numSelected === numRows;
  }

  /**
   * Método que determinao si los checbox estan seleccionados o no en la tabla estrategia
   * @author Juan David Guerrero Vargas
   * @returns {Boolean}
  */
  isAllSelectedStrategy(){
    const numSelected = this.selectionStrategy.selected.length;
    const numRows = this.dataSourceStrategy.data.length;
    return numSelected === numRows;
  }

  /**
   * Metodo que se encarg de realziar paginacion estatica apra tablas 
   * @author Juan David Guerrero Vargas
   * @returns {any[]} any[]
   */
  paginateStaticTable(dataTable:any[], configPaginator:any):any[]{
    let  startIndex = (configPaginator.pageIndex - 1 )  * configPaginator.pageSize
    return dataTable.slice( startIndex, startIndex +  configPaginator.pageSize  )
  }

  /**
    * Método que pagina la informacion de la tabla.x
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * @param $event Evento emitido por el paginador.
  */
  changePage($event: PageEvent, tablePaginate:string): void {
    if (tablePaginate == 'analisis' && this.displayedColumnsRecordings.find(ffnn => ffnn == 'analisis') == undefined){
        this.configPaginatorRecording.pageSize  = $event.pageSize;
        this.configPaginatorRecording.pageIndex  = $event.pageIndex + 1;
        this.processSearchResponse(this.respload)
      }else if (tablePaginate == 'strategy' && this.displayedColumnsRecordings.find(ffnn => ffnn == 'analisis') !== undefined){
        this.configPaginatorStrategy.pageSize  = $event.pageSize;
        this.configPaginatorStrategy.pageIndex  = $event.pageIndex + 1;
        this.dataSourceStrategy.data = this.paginateStaticTable(this.listRecordingsStrategys, this.configPaginatorStrategy )
      }
  }

  /**
   * Método para abrir el modal de analisis
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  openAnalyticsModal(recording_id: any): void {
    const dialogRef = this.dialog.open(AnalyticsModalComponent, {
      width: '800px',
      data: { recordingId: recording_id } 
    });
    dialogRef.componentInstance.onClose.subscribe((hasChanges: boolean) => {
      if (hasChanges) {
        this.obtenerEstadosRegistros();
      }
    });
  }

  /**
    * Metodo para abrir el modal reproductor audio
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
  */
  openAudioModal(recording_id: any): void {
    const dialogRef = this.dialog.open(AudioPlayerComponent, {
      width: '700px',
      data: { recordingId: recording_id } 
    });
  }

  /**
   * Método que se encarga de retonar la cantidad de items seleccioandos en la tabal de Analsis
   * @author Juan David Guerrero Vargas
   * @returns {number} Cantidad de items seleccionados
   */
  get getTotalItemSelectedRecordings():number{
    if(this.selectionRecordings && this.selectionRecordings?.selected){
      return this.selectionRecordings?.selected.length
    }
    return  0
  }

  /**
   * Método para analizar
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
   * @update Juan David Guerrero Vargas
  */
  analytics(): void {
    if (this.getTotalItemSelectedRecordings === 0) {
      Swal.fire({
        title: 'No hay registros seleccionados',
        text: 'Por favor, seleccione al menos un registro para analizar.',
        icon: 'warning',
        confirmButtonText: '¡Entendido!',
        confirmButtonColor: '#2CABBC',
        });
        return;
    }
    Swal.fire({
      title: '¿Estás seguro?',
      text: `Se iniciará el análisis de ${this.getTotalItemSelectedRecordings} grabaciones.`,
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí, continuar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.requestService.newRequestAnalytics(this.selectionRecordings.selected).subscribe(response => {
          this.dataSourceRecordings.data =  this.selectionRecordings.selected;
          this.dataSourceRecordings.sort = this.sortRecordings;
          this.configPaginatorRecording.length = this.dataSourceRecordings.data.length 
          if(this.displayedColumnsRecordings.find( ffnn => ffnn == 'analisis' ) == undefined ){
            this.displayedColumnsRecordings.push('analisis');
          }
          this.obtenerEstadosRegistros();
        }, error => {
          console.error('Ha ocurrido un error en la petición de análisis:', error.message);
          Swal.fire({
            icon: 'error',
            title: 'Ha ocurrido un error en la petición de análisis',
            text: error.message,
            confirmButtonText: '¡Entendido!',
            confirmButtonColor: '#2CABBC',
          });
        });
      }
    });
  }

  /**
   * Metodo que se encarga de obtener la cantidad de items que ya fueron analizados
   * @author Juan David Guerrero Vargas
   * @returns {number} number cantidad de items que ya estan analizados
   */
  get getTotalItemsAnalized():number{
    let totalRecordingsAnalized = this.selectionRecordings?.selected.filter( ffll =>  ffll?.analisis !== undefined &&  (ffll?.analisis == 'COMPLETADO' ||  ffll?.analisis == 'ERROR') ).length
    if( totalRecordingsAnalized > 0 ){
      return totalRecordingsAnalized;
    }
    return 0
  }

  /**
   * Metodo que se encarga de obtener el total de recordings que se seleccionaron pra aplciar la estrategia
   * @returns {number} number
   */
  get getTotalRecordingsForStrategy():number{
    if(this.selectionStrategy && this.selectionStrategy.selected.length > 0 ){
      return this.selectionStrategy.selected.length;
    }
    return 0
  }
  
  /**
   * Metodo que se encarga de ir validando si ya se encuentran descargados los analisis de las grabaciones
    * @author Yeison Sepulveda
    * @createdate 2024-03-07
    * Validar el estado de los análisis
  */
  async obtenerEstadosRegistros() {
    try {
        let registrosPendientes = this.dataSourceRecordings.data.filter(ffll => ffll?.analisis !== 'COMPLETADO');
        this.selectionRecordings.clear();

        while (registrosPendientes.length > 0 && this.isActive) {

            const response: any = await this.viewservice.analyticsState(registrosPendientes).toPromise();

            if (response.data) {
                const estados = {};
                response.data.forEach(item => {
                    estados[item.recording_id] = item.status.toUpperCase();
                });

                this.dataSourceRecordings.data.forEach(record => {
                    if (estados[record.recording_id]) {
                        record.analisis = estados[record.recording_id];
                    }
                });
            }

            registrosPendientes = this.dataSourceRecordings.data.filter(ffll => ffll.analisis !== 'COMPLETADO' && ffll.analisis !== 'ERROR');

            if (registrosPendientes.length > 0) {
                await new Promise(resolve => setTimeout(resolve, 30000));
            }
        }

        this.isAllComplete = true;
    } catch (error) {
        console.error('Error al obtener estados de registros pendientes:', error);
    }
  }

  /**
   * Metodo que se encarga de redirigir al metodo de aplicar estrategias
   * @author Yeison Sepulveda
   * @createdate 2024-05-08
   * @param stepperReference:any {any} Referencia del steper para realizar la accion de continuar el paso siguiente
   */
  applyStrategy(stepperReference?:any):void{
    this.dataSourceStrategy.data = this.selectionRecordings.selected;
    this.configPaginatorStrategy.length = this.selectionRecordings.selected.length;
    this.listRecordingsStrategys = this.selectionRecordings.selected;
    this.dataSourceStrategy.sort = this.sortStrategy;
    this.deleteColumnAdherencia();
    if(stepperReference !== undefined){
      stepperReference.next();
      this.selectionStrategy.clear();
    }
    this.startegyComplete = false;
  }

  /**
   * Metodo que se encarga de enviar la informacion apra aplciar la estrategia seleccionada
   * @author Yesion Sepulveda
   * @Update 15-05-2024
  */

  applyStrategySelected(): void {
    this.deleteColumnAdherencia();
    this.startegyComplete = true;
    if (this.getTotalRecordingsForStrategy <= 0) {
        Swal.fire({
            icon: 'warning',
            title: 'No hay registros seleccionados',
            text: 'Por favor, seleccione al menos un registro para aplicar la estrategia.',
            confirmButtonText: '¡Entendido!',
            confirmButtonColor: '#2CABBC',
        });
        return;
    }

    let strategyForms = {
      id_recordings: this.selectionStrategy.selected.map(recording => recording['recording_id']),
      id_strategy: this.strategyControl.value
    }
  
    this.strategyService.assignStrategys(strategyForms).subscribe(
      (assignResponse: any) => {
          console.log("Respuesta de asignación de estrategia:", assignResponse);
  
          this.strategyService.applyAdherencia(strategyForms.id_recordings).subscribe(
            (adherenciaResponse: any[]) => {
  
                if (this.displayedColumnsStrategy.indexOf('adherencia') === -1) {
                    this.displayedColumnsStrategy.push('adherencia');
                }
  
                adherenciaResponse.forEach((item) => {
                  const adherencia = `${item.adherence_percentage} / ${item.not_adherence_percentage}`;
                  const item_recording_id= item.recording_id
                  const matchingRecords = this.dataSourceStrategy.data.filter((record) => record.recording_id === item_recording_id);
                  
                  // Si hay registros coincidentes, asigna la adherencia a dataSourceStrategy.data
                  if (matchingRecords.length > 0) {
                    this.dataSourceStrategy.data.forEach((record) => {
                      if (record.recording_id === item_recording_id) {
                        record.adherencia = adherencia;
                      }
                    });
                  }
                });

                this.dataSourceStrategy.data = [...this.dataSourceStrategy.data];
                this.configPaginatorStrategy.length = this.dataSourceStrategy.data.length;
                this.dataSourceStrategy.sort = this.sortStrategy;
              },
              (adherenciaError: any) => {
                  console.error("Error al calcular la adherencia:", adherenciaError.error);
              }
          );
      },
      (assignError: any) => {
          console.error("Error al asignar la estrategia:", assignError);
      }
  );
        
  }

  /**
   * Metodo que se encarga de obtenre el listado de estrategias
   * @author Yeison Sepulveda
	 * @createdate 2024-04-23
  */
  getStrategys():void{
    const type_channel= [1];
    this.strategyService.listStrategies(type_channel).subscribe(res => {
        this.listStrategys = res.data;
    }); 
  }

  /**
	 * Método enviar mensaje swal de error de la grabación
	 * @author Yeison Sepulveda
	 * @createdate 2024-04-23
	 */
  errorAnalyticModal(recordingId: any) {
    this.viewservice.analyticsStateDetail(recordingId).subscribe(
      (response: any) => {
        Swal.fire({
          title: '¡Aviso!',
          text: response.data.message,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Reprocesar análisis',
          cancelButtonText: 'Cancelar',
          confirmButtonColor: '#2CABBC',
          cancelButtonColor: '#FFFFFF',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.confirmReprocess(recordingId);
          }
        });
      },
      (error) => {
          console.error('Error en la solicitud:', error.error.message);
      });
  }

  /**
	 * Metodo para reprocesar análisis con error
	 * @author Yeison Sepulveda
	 * @createdate 2024-09-03
	 */
  confirmReprocess(element: any) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: `¿Quieres volver a intentar analizar el audio ${element.name_file}?`,
      icon: 'warning',
      showCancelButton: true,                                            
      confirmButtonText: 'Sí, continuar',                            
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.requestService.duplicateUpdateAnalytics({ recording_id: element.recording_id }).subscribe(
          response => {
            Swal.fire({
              title: '¡Excelente!',
              text: 'La solicitud ha sido procesada correctamente.',
              icon: 'success',
              confirmButtonText: '¡Entendido!',
              confirmButtonColor: '#2CABBC'
            }).then((result) => {
              if (result.isConfirmed) {
                this.obtenerEstadosRegistros();
              }
            });
          },
          error => {
            Swal.fire({
              title: 'Opss ...',
              text: error.message,
              icon: 'error',
              confirmButtonText: '¡Entendido!',
              confirmButtonColor: '#2CABBC'
            });
          }
        );
      }
    });
  }

  /**
   * Método para abrir el modal de resultados de adherencia
   * @author Yeison Sepulveda
   * @createdate 2024-04-30
  */
  AdherenciaModal(recording_id: any): void {
    const dialogRef = this.dialog.open(AdherenciaModalComponent, {
      width: '800px',
      data: { recordingId: recording_id } 
    });
  }

	/**
	 * Método para tratar los datos que retorna el servicio y descargar el archivo.
	 * @param data data respondida por el servicio
	 * @param fileName nombre con el que se va a descargar el archivo.
	 * @param file_type Tipo del archivo 
	 * @author Juan Carlos Alonso
	 * @createdate 2021-07-27
	 * @updatedate 2022-12-13  
	 */
	fileTransition(data: any, fileName: string, file_type:string = ""): void {
		let blob = new Blob([data], { type: file_type});
		let downloadURL = (file_type) ? window.URL.createObjectURL(blob) : window.URL.createObjectURL(data);
		let link = document.createElement('a');
		link.href = downloadURL;
		link.download = fileName;
		link.click();
	}

    /**
   * Metodo generar el nombre del documento a descargar
   * @author Yeison Sepulveda
   * @createdate 2024-0-03
   * @return nombre del archivo excel
  */
  
  generateFilename(): string {
    const currentDate = new Date().toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '');
  
    const currentTime = new Date().toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).replace(/:/g, '');
  
    const dateTimeString = `${currentDate}${currentTime}`;
    return `reporte_speech_${dateTimeString}.xlsx`;
  }

  /**
   * Descargar reporte de busqueda excel
   * @author Yeison Sepulveda
   * @createdate 2024-04-04
   */
    downloadReport(type: 'analysis' | 'strategy') {
      let filters: any = {};
    
      if (type === 'analysis') {
        filters = {
          recording_ids: this.dataSourceRecordings.data.filter(record => record?.analisis === 'COMPLETADO').map(record => record['recording_id']),
          type: 1
        };
      } else if (type === 'strategy') {
        filters = {
          recording_ids: this.selectionStrategy.selected.map(recording => recording['recording_id']),
          type: 2
        };
      }
    
      this.noDirigidoService.downloadReport(filters).subscribe(
        (response: any) => {
          const filename = this.generateFilename();
          this.fileTransition(response, filename);
        },
        (error) => {
          console.error('Error al descargar el informe:', error.message);
          Swal.fire({
            icon: 'error',
            title: 'Error al descargar el informe',
            text: error.message,
            showConfirmButton: true,
            confirmButtonColor: '#2CABBC',
            confirmButtonText: 'Aceptar',
          });
        }
      );
    }

    registrosCompletados(): boolean {
      return this.dataSourceRecordings.data.some(record => record.analisis === 'COMPLETADO');
  }

  /**
   * Metodo que se encarga de abirir el modal apra poder abrir el modal de creacion de estrategias
   * @author Yeison Sepulveda
   * @date 224-06-19
   */
    createStrategyTranscription(): void {
      const selectedRecordingIds = Array.from(this.selectionStrategy.selected.map(recording => recording['recording_id']));
  
      if (selectedRecordingIds.length === 1) {
          const selectedRecordingId = selectedRecordingIds[0];
          console.log('ID del grabador seleccionado:', selectedRecordingId);
  
          const dialogRef = this.dialog.open(TranscricpcionStrategyComponent, {
              width: '100%',
              maxHeight: '95vh',
              data: { modo: 'crear', recordingId: selectedRecordingId }
          });
          dialogRef.afterClosed().subscribe(result => {
              this.getStrategys();
          });
      } else if (selectedRecordingIds.length > 1) {
          Swal.fire({
            title: 'Advertencia',
            text: 'Seleccionaste más de un registro. Por favor, selecciona solo uno.',
            icon: 'warning',
              confirmButtonText: '¡Entendido!',
              confirmButtonColor: '#2CABBC',
          });
      } else {
          Swal.fire({
            title: 'Advertencia',
            text: 'Selecciona al menos un registro antes de continuar.',
            icon: 'warning',
            confirmButtonText: '¡Entendido!',
            confirmButtonColor: '#2CABBC',
          });
      }
  }

  /**
   * Método validar si ya esta la columna de analisis y si el total es mayor
   * @author Yeison Sepulveda
   * @createdate 2024-06-18
   * @returns El valor true o false
  */

  get ColumnVisibleTotal(): boolean {
    const isVisible = this.displayedColumnsRecordings.includes('analisis');
    const hasSelectedItems = this.getTotalItemSelectedRecordings > 0;
    return isVisible && hasSelectedItems;
  }
  
  /**
   * Método validar si ya esta la columna de analisis
   * @author Yeison Sepulveda
   * @createdate 2024-06-18
   * @returns El valor true o false
  */

  get isAnalisisColumnVisible(): boolean {
    return this.displayedColumnsRecordings.includes('analisis');
  }


  /**
   * Modal para abrir resultados de analisis de sentiemientos masivo
   * @author Yeison Sepulveda
   * @date 2024-08-08
  */
  analytics_emociones(): void {
    if (this.getTotalItemSelectedRecordings === 0) {
      Swal.fire({
        title: 'No hay registros seleccionados',
        text: 'Por favor, seleccione al menos un registro para analizar.',
        icon: 'warning',
        confirmButtonText: '¡Entendido!',
        confirmButtonColor: '#2CABBC',
        });
        return;
    } else{
      Swal.fire({
        title: '¿Estás seguro?',
        text: `Se iniciará el análisis de sentientos de  ${this.getTotalItemSelectedRecordings} grabaciones.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, continuar',
        confirmButtonColor: '#2CABBC',
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#FFFFFF',
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
          const selectedRecordingIds = this.selectionRecordings.selected.map(recording => recording.recording_id);
          console.log('data envío emociones', selectedRecordingIds);
      
          const dialogRef = this.dialog.open(AnalyticsVoiceGeneralComponent, {
            width: '800px',
            data: { id_recordings: selectedRecordingIds } 
        });
        }
    });
    }
  }

  
}