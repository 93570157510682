import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-on-boarding',
  templateUrl: './on-boarding.component.html',
  styleUrls: ['./on-boarding.component.sass']
})
export class OnBoardingComponent implements OnInit {

  public id: string;
  public img : string;
  public index: number;

  /**
   * @author Carlos Nieto
   * @createdate 2021-01-31
   * se debe pasar un array con la siguiente estructura:
   * [{ img: 'ciu/Onboarding-1.svg'}, { img: 'ciu/Onboarding-2.svg' },]
   * se debe tener en cuenta que las imagenes se deben alojar en la ubicación '/images/on-boarding'
   * y completar con la ruta de la imagen
   * @param data array con la ubicación y nombre del archivo imagen
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

 /**
   * @author Carlos Nieto
   * @createdate 2021-01-31
   *setea la variable inicial a mostrar
   */
  ngOnInit(): void {
    this.index = 0;
    this.img = this.data[this.index].img;
  }

   /**
   * @author Carlos Nieto
   * @createdate 2021-01-31
   *función para cambio de siguiente imagen
   */
  ChangeNext()
  {
    this.index += 1;
    this.img = this.data[this.index].img;
  }

 /**
   * @author Carlos Nieto
   * @createdate 2021-01-31
   *función para cambio de imagen anterior
   */

  ChangeBack()
  {
    this.index -= 1;
    this.img = this.data[this.index].img;
  }

}
