import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';




export default class Utils{



  static downloadFile( x , fileName ){
    var newBlob = new Blob([x], { type: x.type });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        console.log("set");
        window.navigator.msSaveOrOpenBlob(newBlob, fileName);
        return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
    }, 100);

  }


  static swal( title , text ,icon ){
    Swal.fire();
       Swal.close;
       Swal.fire({
        position:'center',
        icon: icon ,
        title: title,
        text: text,
        showConfirmButton:false,
        timer: 2500
       })
  }



  static buidForm(fields:any){
    let keys     = Object.keys(fields);
    let formData = new FormData();

    for (let i = 0; i < keys.length; i++) {
      formData.append(keys[i], fields[keys[i]] );
      //console.log( fields[keys[i]] , keys[i]);

    }
    return formData;
  }

  static convierteFechas(fields:any){
    let keys     = Object.keys(fields);


    for (let i = 0; i < keys.length; i++) {
      if ( fields[keys[i]] instanceof Date  ){
        const offset = fields[keys[i]].getTimezoneOffset()
        fields[keys[i]] = new Date(fields[keys[i]].getTime() - (offset*60*1000))
        fields[keys[i]] =  fields[keys[i]].toISOString().split('T')[0]
        console.log(fields[keys[i]]);
        //console.log(datePipe.transform(fields[keys[i]],"yyyy-MM-dd"));
      }

    }
    return fields;
  }
}
