<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>
<h3 mat-dialog-title>{{data.action}} cargo</h3>
<hr>
<mat-dialog-content>
    <form [formGroup]="positionForm" fxLayout="column" fxLayoutGap="20px" style="padding: 0 23px;">
        <mat-form-field appearance="fill">
            <mat-label>Nombre del cargo</mat-label>
            <input type="text" matInput autocomplete="off" formControlName="name" >
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Código</mat-label>
          <input type="number" matInput autocomplete="off" formControlName="key" >
      </mat-form-field>

      <mat-checkbox  formControlName="dependents" [value]="0">¿Personas a cargo?</mat-checkbox >

        <mat-form-field appearance="fill">
            <mat-label>Tipo de Cargo</mat-label >
            <mat-select  formControlName="position_category_id">
                <mat-option [value]="1">Administrativo</mat-option>
                <mat-option [value]="2">Operativo</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" >
            <mat-label>Identificar tipo de cargo</mat-label>
            <mat-select  formControlName="charges">
                <mat-option value="Jefatura">Jefatura</mat-option>
                <mat-option value="Coordinación">Coordinación</mat-option>
                <mat-option value="Directivos">Directivos</mat-option>
                <mat-option value="Gerenciales">Gerenciales</mat-option>
                <mat-option value="N/A">N/A</mat-option>
            </mat-select>
          </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Funciones</mat-label>
            <textarea matInput  rows="10" formControlName="functions"></textarea>
          </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-stroked-button  type="button" mat-dialog-close>Cancelar</button>
    <button mat-flat-button color="primary" (click)="createPosition()" [disabled]="positionForm.invalid == true">{{data.action}}</button>
</mat-dialog-actions>
