<button style="float: right;" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross"></i>
</button>
<form mat-dialog-content class="containerDialog" [formGroup]="moduleForm">
  <h2>{{config.labels.form.title}}</h2>
  <mat-divider class="mb-3"></mat-divider>
  <mat-form-field appearance="fill">
    <mat-label>{{config.labels.form.name}}</mat-label>
    <input type="text" matInput formControlName="nombre" [matAutocomplete]="modulo" cdkFocusInitial>
    <mat-icon matSuffix><span class="fi-rr-search" (click)="getModules()"></span></mat-icon>
    <mat-autocomplete #modulo="matAutocomplete" [displayWith]="displayFn" (optionSelected)="newModule()">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.nombre">
        {{option.nombre}}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="verError">{{config.labels.errors.noMatches}}</mat-error>
  </mat-form-field>
  <button mat-button class="nuevo" *ngIf="nuevo" (click)="newModule()">{{config.labels.buttons.create}} <mat-icon matSuffix><i class="fi-rr-add"></i></mat-icon></button>
  <mat-form-field appearance="fill" [hidden]="!peso">
    <mat-label>{{config.labels.form.weight}}</mat-label>
    <input type="number" matInput formControlName="peso">
    <mat-error *ngIf="moduleForm.get('peso').hasError('required')">{{config.labels.errors.requiredWeight}}</mat-error>
    <mat-error *ngIf="moduleForm.get('peso').hasError('min')">{{config.labels.errors.minWeight}} {{ this.datos.minimo }} .</mat-error>
    <mat-error *ngIf="moduleForm.get('peso').hasError('max')">{{config.labels.errors.maxWeight}} {{ this.datos.maximo }} .</mat-error>
  </mat-form-field>
  <mat-checkbox formControlName="error_critico" (change)="habilitaCheck($event)" >{{config.labels.checkbox.criticalModule}}</mat-checkbox>
  <br>
  <mat-checkbox formControlName="is_total" (change)="habilitarPeso($event)" >{{config.labels.checkbox.totalAffectation}}</mat-checkbox>

  <div class="btns-dialog" style="padding-right: 3em;">
    <button mat-stroked-button (click)="moduleRef.close()" [disabled]="continue">{{config.labels.buttons.cancel}}</button>
    <button mat-raised-button color="primary" (click)="saveModule()" [disabled]="continue">{{config.labels.buttons.create}}</button>
  </div>
</form>
