<div class="avatar row  admin-dialog" >
    <!-- <form class="row form " [formGroup]="solicitudesForm"  (ngSubmit)="saveSolicitud(solicitudesForm)"> -->
      <h2 mat-dialog-title>
        Orden de compra
        <button style="float: right" mat-icon-button mat-dialog-close>
          <i class="fi-rr-cross-small"></i>
        </button>
      </h2>
      <mat-dialog-content class="mat-typography">
        <mat-horizontal-stepper  #stepper>
          <mat-step  [editable]="isEditable" labelPosition="bottom">

            <ng-template matStepLabel>Datos generales</ng-template>
            <div class="row mt-3">
              <div class="col-12 subtitle">
                Datos de la empresa
                <hr class="mt-1" >
              </div>
            </div>
            <div class="row mt-3">

              <div class="col-4">
                <mat-label class="fw-bold">Empresa</mat-label><br>
                {{data.ordenCompra.company.name}}
              </div>
              <div class="col-4">
                <mat-label class="fw-bold">Nit</mat-label><br>
                {{data.ordenCompra.company.nit}}
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12 subtitle">
                Documento controlado
                <hr class="mt-1" >
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-4">
                <mat-label class="fw-bold">Número orden compra</mat-label><br>
                OC-{{data.ordenCompra.id}}
              </div>
              <div class="col-4">
                <mat-label class="fw-bold">Fecha Creación</mat-label><br>
                {{data.ordenCompra.created_at|date:'yyyy-MM-dd'}}
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12 subtitle">
                Datos del proveedor
                <hr class="mt-1">
              </div>
            </div>
            <div class="row mt-3">

              <div class="col-4">
                <mat-label class="fw-bold">Razón social</mat-label><br>
                {{data.ordenCompra.provider.razon_social}}
              </div>
              <div class="col-4">
                <mat-label class="fw-bold">Nit</mat-label><br>
                {{data.ordenCompra.provider.nit}}
              </div>
              <div class="col-4">
                <mat-label class="fw-bold">Dirección</mat-label><br>
                {{data.ordenCompra.provider.direccion}}
              </div>
            </div>
            <div class="row" *ngIf="proveedor">
              <div class="col-4">
                <mat-label class="fw-bold">Celular</mat-label><br>
                {{proveedor.user.celular}}
              </div>
              <div class="col-4">
                <mat-label class="fw-bold">Teléfono</mat-label><br>
                {{proveedor.telefono_proveedor}}
              </div>
              <div class="col-4">
                <mat-label class="fw-bold">E-mail</mat-label><br>
                {{proveedor.user.correo}}
              </div>
            </div>




          </mat-step>
          <mat-step [stepControl]="firstFormGroup" [editable]="isEditable">
            <form [formGroup]="firstFormGroup">
              <ng-template matStepLabel>Detalle compra</ng-template>
              <div formArrayName="items" class="row col-12">
                <h3>
                  Por los siguientes ítems

                </h3>

                <div class="col-12" *ngFor="let applet of items.controls; let i = index">
                  <mat-card style="background-color: #f0f0f0">
                    <div class="row col-12" [formGroupName]="i">

                      <mat-form-field style="padding: 1%;" class="col-12" appearance="fill">
                        <mat-label>Descripción</mat-label>
                        <input style="text-transform: none;" matInput type="text" placeholder="Escribe las especificaciones" formControlName="description" required>
                        <mat-error *ngIf="errorItem.items.controls[i].controls.description.errors"><span *ngIf="errorItem.items.controls[i].controls.description.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                        <mat-error *ngIf="errorItem.items.controls[i].controls.description.errors"><span *ngIf="errorItem.items.controls[i].controls.description.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                        <mat-error *ngIf="errorItem.items.controls[i].controls.description.errors"><span *ngIf="errorItem.items.controls[i].controls.description.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 255 caracteres</span></mat-error>
                      </mat-form-field>

                      <mat-form-field style="padding: 1%;" class="col-12" appearance="fill">
                        <mat-label>Especificación</mat-label>
                        <input style="text-transform: none;" matInput type="text" placeholder="Escribe el detalle" formControlName="specs" required>
                        <mat-error *ngIf="errorItem.items.controls[i].controls.specs.errors"><span *ngIf="errorItem.items.controls[i].controls.specs.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                        <mat-error *ngIf="errorItem.items.controls[i].controls.specs.errors"><span *ngIf="errorItem.items.controls[i].controls.specs.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                        <mat-error *ngIf="errorItem.items.controls[i].controls.specs.errors"><span *ngIf="errorItem.items.controls[i].controls.specs.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 255 caracteres</span></mat-error>
                      </mat-form-field>



                      <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                        <mat-label>Cantidad</mat-label>
                        <input style="text-transform: none;" matInput type="number" placeholder="Escribe la cantidad" formControlName="unit" (blur)="calcularValorTotal(i)" required>
                        <mat-error *ngIf="errorItem.items.controls[i].controls.unit.errors"><span *ngIf="errorItem.items.controls[i].controls.unit.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                        <mat-error *ngIf="errorItem.items.controls[i].controls.unit.errors"><span *ngIf="errorItem.items.controls[i].controls.unit.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                        <mat-error *ngIf="errorItem.items.controls[i].controls.unit.errors"><span *ngIf="errorItem.items.controls[i].controls.unit.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 3 caracteres</span></mat-error>
                      </mat-form-field>
                      <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                        <mat-label>Valor unitario</mat-label>
                        <input style="text-transform: none;" matInput type="number" placeholder="Escribe valor unitario" formControlName="unit_value" (blur)="calcularValorTotal(i)"  required>
                        <mat-error *ngIf="errorItem.items.controls[i].controls.unit_value.errors"><span *ngIf="errorItem.items.controls[i].controls.unit_value.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                        <mat-error *ngIf="errorItem.items.controls[i].controls.unit_value.errors"><span *ngIf="errorItem.items.controls[i].controls.unit_value.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                        <mat-error *ngIf="errorItem.items.controls[i].controls.unit_value.errors"><span *ngIf="errorItem.items.controls[i].controls.unit_value.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 3 caracteres</span></mat-error>
                      </mat-form-field>
                      <mat-form-field style="padding: 1%;" class="col-3" appearance="fill" *ngIf="!viewSolciitud">
                        <mat-label>Iva</mat-label>
                        <mat-select  formControlName="selectIva" (valueChange)="cambioEstado($event,i)" >
                            <mat-option  [value]=0>0%</mat-option>
                            <mat-option  [value]=5>5%</mat-option>
                            <mat-option  [value]=19>19%</mat-option>
                            <mat-option  [value]=30>30%</mat-option>
                            <mat-option  [value]=35>35%</mat-option>
                            <mat-option  [value]=100>Otro</mat-option>
                        </mat-select>
                        <mat-error *ngIf="errorItem.items.controls[i].controls.selectIva.errors"><span *ngIf="errorItem.items.controls[i].controls.selectIva.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                    </mat-form-field>
                      <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                        <mat-label>Otro IVA</mat-label>
                        <input style="text-transform: none;" matInput  type="number" placeholder="Escribe el IVA" formControlName="valorIva" disabled >
                      </mat-form-field>
                      <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                        <mat-label>Valor Total</mat-label>
                        <input style="text-transform: none;" matInput type="number" placeholder="Valor total" formControlName="total_value" readonly>
                        <mat-error *ngIf="errorItem.items.controls[i].controls.total_value.errors"><span *ngIf="errorItem.items.controls[i].controls.total_value.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                        <mat-error *ngIf="errorItem.items.controls[i].controls.total_value.errors"><span *ngIf="errorItem.items.controls[i].controls.total_value.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                        <mat-error *ngIf="errorItem.items.controls[i].controls.total_value.errors"><span *ngIf="errorItem.items.controls[i].controls.total_value.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 3 caracteres</span></mat-error>
                      </mat-form-field>
                      <input type="hidden"  formControlName="id">
                      <div class="col-2" >

                          <button mat-icon-button (click)="deleteItems(i)" style="color:#BE0F0F;font-size: 20px;" *ngIf="this.items.length >1"><i class="fi-rr-trash" style="position:absolute" ></i></button>
                          <button mat-icon-button type="button"  color="primary" style="border-color: #353535;" (click)="newItems()" ><i class="fi-rr-add" style="position:absolute"></i></button>
                    </div>
                        <div class="row" *ngFor="let item of filesUpload; let j = index;">
                          <div style="vertical-align: bottom;" class="col-3 ">
                            <p>{{item.name}}</p>
                          </div>
                          <div class="col-3 ">
                            <button  mat-icon-button class="action " (click)="EliminarArchivoLista(j) ">
                              <i class="fi-rr-trash" style="position:initial"></i>
                            </button>
                          </div>
                        </div>
                      </div>


                    </mat-card>
                    <br />
                    <br />
                  </div>
                </div>

              </form>
            </mat-step>
            <mat-step [stepControl]="secondFormGroup" [editable]="isEditable">
              <form [formGroup]="secondFormGroup">
                <ng-template matStepLabel>Finalizar orden</ng-template>
                <h3>Condiciones comerciales</h3>
                <div>
                  <mat-form-field style="padding: 1%;" class="col-12" appearance="fill">
                    <mat-label>Observaciones</mat-label>
                    <input style="text-transform: none;" matInput type="text" placeholder="Escribe la observación" formControlName="observations" required>
                    <mat-error *ngIf="error.observations.errors"><span *ngIf="error.observations.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                    <mat-error *ngIf="error.observations.errors"><span *ngIf="error.observations.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                    <mat-error *ngIf="error.observations.errors"><span *ngIf="error.observations.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 255 caracteres</span></mat-error>
                  </mat-form-field>

                  <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                    <mat-label>Sub-total</mat-label>
                    <input  matInput type="number" placeholder="Escribe el nombre de la aplicación" formControlName="sub_total" readonly >
                    <mat-error *ngIf="error.sub_total.errors"><span *ngIf="error.sub_total.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                    <mat-error *ngIf="error.sub_total.errors"><span *ngIf="error.sub_total.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                    <mat-error *ngIf="error.sub_total.errors"><span *ngIf="error.sub_total.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 40 caracteres</span></mat-error>
                  </mat-form-field>



                  <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                    <mat-label>Descuento</mat-label>
                    <input style="text-transform: none;" matInput type="number" placeholder="Escribe el descuenot" formControlName="discount" (keydown.enter)="calculardiscount()"  (blur)="calculardiscount()" required>
                    <mat-error *ngIf="error.discount.errors"><span *ngIf="error.discount.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                    <mat-error *ngIf="error.discount.errors"><span *ngIf="error.discount.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                    <mat-error *ngIf="error.discount.errors"><span *ngIf="error.discount.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 3 caracteres</span></mat-error>
                  </mat-form-field>

                  <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                    <mat-label>Total IVA</mat-label>
                    <input  matInput type="number" placeholder="Escribe el nombre de la aplicación" formControlName="iva" (keydown.enter)="calculardiscount()"  (blur)="calculardiscount()" required >
                    <mat-error *ngIf="error.iva.errors"><span *ngIf="error.iva.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                    <mat-error *ngIf="error.iva.errors"><span *ngIf="error.iva.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                    <mat-error *ngIf="error.iva.errors"><span *ngIf="error.iva.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 40 caracteres</span></mat-error>
                  </mat-form-field>



                  <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                    <mat-label>Total</mat-label>
                    <input style="text-transform: none;" matInput type="number" placeholder="Total" formControlName="total" readonly>
                    <mat-error *ngIf="error.total.errors"><span *ngIf="error.total.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                    <mat-error *ngIf="error.total.errors"><span *ngIf="error.total.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                    <mat-error *ngIf="error.total.errors"><span *ngIf="error.total.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 3 caracteres</span></mat-error>
                  </mat-form-field>

                  <mat-form-field style="padding: 1%;" class="col-12" appearance="fill">
                    <mat-label>Valor en Letras</mat-label>
                    <input style="text-transform: none;" matInput type="text" placeholder="Valor en letras" formControlName="value_in_letters" readonly>
                    <mat-error *ngIf="error.value_in_letters.errors"><span *ngIf="error.value_in_letters.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                    <mat-error *ngIf="error.value_in_letters.errors"><span *ngIf="error.value_in_letters.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                    <mat-error *ngIf="error.value_in_letters.errors"><span *ngIf="error.value_in_letters.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 3 caracteres</span></mat-error>
                  </mat-form-field>

                  <hr>

                  <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                    <mat-label>Fecha de Entrega</mat-label>
                    <input  matInput type="date" placeholder="Escribe el nombre de la aplicación" formControlName="delivery_date" required >
                    <mat-error *ngIf="error.delivery_date.errors"><span *ngIf="error.delivery_date.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                    <mat-error *ngIf="error.delivery_date.errors"><span *ngIf="error.delivery_date.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                    <mat-error *ngIf="error.delivery_date.errors"><span *ngIf="error.delivery_date.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 40 caracteres</span></mat-error>
                  </mat-form-field>



                  <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                    <mat-label>Método de Pago</mat-label>
                    <mat-select  formControlName="pay" (valueChange)="cambioPagoValue($event)" >
                        <mat-option *ngFor="let item of ['Efectivo','Transferencia','Cheque']"  [value]="item"> {{item}} </mat-option>
                    </mat-select>
                    <mat-error *ngIf="error.pay.errors"><span *ngIf="error.pay.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                  </mat-form-field>


                  <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                    <mat-label>Condición de Pago</mat-label>
                    <mat-select  formControlName="payment_condition" >
                        <mat-option *ngFor="let item of ['Abono','Anticipo','Crédito']"  [value]="item"> {{item}} </mat-option>
                    </mat-select>
                    <mat-error *ngIf="error.pay.errors"><span *ngIf="error.pay.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                  </mat-form-field>



                  <mat-form-field style="padding: 1%;"
                    class="col-3"
                    *ngIf="['Abono','Anticipo'].includes(secondFormGroup.get('payment_condition').value)"
                    appearance="fill" >
                    <mat-label>Valor {{secondFormGroup.get('payment_condition').value}} </mat-label>
                    <input
                      matInput type="number"
                      placeholder="Escribe el valor"
                      formControlName="payment_deb">
                    <mat-error *ngIf="error.sub_total.errors"><span *ngIf="error.sub_total.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                  </mat-form-field>



                  <mat-form-field style="padding: 1%;"
                    class="col-3"
                    appearance="fill"
                    *ngIf=" secondFormGroup.get('payment_condition').value == 'Crédito' "
                    >
                    <mat-label>Condición de crédito</mat-label>
                    <mat-select  formControlName="credit_condition" >
                        <mat-option *ngFor="let item of ['30 Días','60 Días','90 Días']"  [value]="item"> {{item}} </mat-option>
                    </mat-select>
                    <mat-error *ngIf="error.pay.errors"><span *ngIf="error.pay.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                  </mat-form-field>


                  <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                    <mat-label>Garantía</mat-label>
                    <mat-select  formControlName="warranty"  >
                        <mat-option value="Si">Si</mat-option>
                        <mat-option value=No>No</mat-option>

                    </mat-select>
                    <mat-error *ngIf="error.warranty.errors"><span *ngIf="error.warranty.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                </mat-form-field>

                <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
                  <mat-label>Póliza</mat-label>
                  <mat-select  formControlName="policy"  >
                      <mat-option value="Si">Si</mat-option>
                      <mat-option value=No>No</mat-option>

                  </mat-select>
                  <mat-error *ngIf="error.policy.errors"><span *ngIf="error.policy.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
              </mat-form-field>


                </div>
              </form>
            </mat-step>
          </mat-horizontal-stepper>
          <div  class="d-flex justify-content-end mt-3" >
            <button mat-stroked-button
              color="primary"
              (click)="editOrder()"
              >Enviar</button> &nbsp;
            <button mat-stroked-button mat-dialog-close>Cancelar</button>&nbsp;
            <button mat-raised-button color="primary"  (click)="next(stepper)">
              Continuar
            </button>

          </div>
        </mat-dialog-content>



        <!-- </form> -->
      </div>
