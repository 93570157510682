<div class="novelities">
  <div class="header">
    <h1>Novedades de vacaciones extemporáneas</h1>
    <p>En este espacio podrás revisar y/o gestionar las novedades de vacaciones extemporáneas de<br>los trabajadores, no olvides,"El
      respeto y la
      empatiá son clave"</p>
  </div>


  <mat-form-field appearance="fill" class="col-2 filter">
    <mat-label>Filtrar por fecha</mat-label>
    <mat-date-range-input [formGroup]="reportForm" [rangePicker]="picker">
      <input matStartDate [formControl]="reportForm.controls['start']" placeholder="Start date" #startDate>
      <input matEndDate [formControl]="reportForm.controls['end']" placeholder="End date" #endDate
             (dateChange)="dateRangeChange()">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
  <mat-form-field appearance="fill" class="col-3 filter">
    <mat-label>Filtrar por identificación</mat-label>
    <input matInput type="text" #input autocomplete="off" (keydown.enter)="onBlurMethod($event.target.value)">
    <mat-select name="ampm" class="ampm" matSuffix [formControl]="typeDocument" style="width: 60px; margin-left: 5px;">
      <mat-option *ngFor="let item of documents" [value]="item?.id">{{item.key}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="col-2 filter">
    <mat-label>Filtrar por estado</mat-label>
    <mat-select [formControl]="filterControl2">
      <mat-option value="">Seleccionar</mat-option>
      <mat-option *ngFor="let item of states" [value]="item.id">
        {{item?.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-raised-button color="primary" (click)="getExteporal(this.idState, this.pageSize, this.page, true)" class="download">Descargar reporte
    <span class="fi-rr-download"></span>
  </button>

  <div class="table">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="acciones">
        <th class="start" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="viewJustification(element)"
                  *ngIf="element.noveltyStateKey != 'aprobada-gerente-operaciones'">
            <i class="fi-rr-settings"></i>
          </button>
          <button mat-icon-button (click)="viewJustification2(element)"
                  *ngIf="element.noveltyStateKey == 'aprobada-gerente-operaciones'">
            <i class="fi-rr-eye"></i>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="fecha">
        <th mat-header-cell *matHeaderCellDef> Fecha</th>
        <td mat-cell *matCellDef="let element"> {{element.fechaSolicitud | date:"dd-MM-yyyy"}} </td>
      </ng-container>

      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef> Nombre trabajador</th>
        <td mat-cell *matCellDef="let element"> {{element.name}}</td>
      </ng-container>

      <ng-container matColumnDef="numero">
        <th mat-header-cell *matHeaderCellDef>Número de identificación</th>
        <td mat-cell *matCellDef="let element">{{element.id_number}}</td>
      </ng-container>

      <ng-container matColumnDef="tipo">
        <th mat-header-cell *matHeaderCellDef>Tipo de vacaciones</th>
        <td mat-cell *matCellDef="let element">{{element.vacationsType}}</td>
      </ng-container>

      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef> Estado</th>
        <td mat-cell *matCellDef="let element">{{element.noveltyState}}</td>
      </ng-container>

      <ng-container matColumnDef="campana">
        <th class="end" mat-header-cell *matHeaderCellDef> Campaña</th>
        <td mat-cell *matCellDef="let element">{{element.campaignName}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="8">No hay datos que coincidan con el filtro: "{{input.value}}"</td>
      </tr>

    </table>
    <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)"
                   [pageSizeOptions]="pageSizeOptions">
    </mat-paginator>

  </div>


</div>
