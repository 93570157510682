import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComprasComponent } from './compras.component';
import { SolicitudesCardsComponent } from './modules/solicitudes/solicitudes-cards/solicitudes-cards.component';
import { SolicitudesListComponent } from './modules/solicitudes/solicitudes/solicitudes-list.component';
import { SolicitudesAprobarComponent } from './modules/solicitudes/solicitudes-aprobar/solicitudes-aprobar.component';
import { SolicitudesControlComponent } from './modules/solicitudes/solicitudes-control/solicitudes-control.component';



const routes: Routes = [

  { path    : '', component: ComprasComponent},
  { path    : 'solicitudes', component: SolicitudesListComponent},
  { path    : 'solicitudes-user', component: SolicitudesCardsComponent},
  { path    : 'solicitudes-aprobador', component: SolicitudesAprobarComponent},
  { path    : 'solicitudes-control', component: SolicitudesControlComponent},


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComprasRoutingModule { }
