import { Injectable, ChangeDetectorRef  } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Manager } from 'socket.io-client';

@Injectable({
  providedIn: "root"
})
export class socketService {

  public socket: any = [];
  message: Observable<any> 
  connected: Observable<any> 
  connect: Observable<any>
  error: Observable<any>
  sdisconnect: Observable<any>
  syncConversations: Observable<any>

  callBackEmit: () => {};
  constructor(project, callBackEmit) {
    if(!project)
      return
    this.callBackEmit = callBackEmit
    const ManagerInstance = new Manager(`${environment.SOCKET_LIVECHAT}`, {transports: ["websocket"]});
    this.socket = ManagerInstance.socket(`/${project}`);
   
       
    this.message = new Observable((observer) => 
      this.socket.on('agent-message', (data) => observer.next(data))
    );
    this.connect = new Observable((observer) => 
      this.socket.on("connect", () => {
        observer.next()})
    );
    this.error = new Observable((observer) => 
      this.socket.on('error', (data) => {
        console.error("Error de conexion: ",data)
        observer.next(data)})
    );
    this.sdisconnect = new Observable((observer) => 
      this.socket.on("disconnect", (reazon) => {
        observer.next(reazon)})
    );
    this.syncConversations = new Observable((observer) => 
      this.socket.on('syncConversationsDown', (data) => {
        observer.next(data)})
    );
  }

  sendMessage(event: string, message: Object) {
    this.socket.emit(event, message, this.callBackEmit)
  }

  disconnect() {
    this.socket.disconnect(true);
  }
}
