import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommandsService } from '../services/commands.service';

@Component({
  selector: 'app-comandos-create',
  templateUrl: './comandos-create.component.html',
  styleUrls: ['./comandos-create.component.sass']
})
export class ComandosCreateComponent implements OnInit {
  formCommand: FormGroup = new FormGroup({
    id: new FormControl(null, []),
    nombre: new FormControl('', [Validators.required]),
    comando: new FormControl('', [Validators.required]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertsService,
    private matDialogRef: MatDialogRef<ComandosCreateComponent>,
    private matSnackBar: MatSnackBar,
    private commandService: CommandsService
  ) { }

  ngOnInit(): void {
    if (this.data.command) {
      this.formCommand.get('id').setValue(this.data.command.id);
      this.formCommand.get('nombre').setValue(this.data.command.nombre);
      this.formCommand.get('comando').setValue(this.data.command.comando);
    }
  }

  /**
    * @author Fabian Duran
    * @createdate 2024-02-22
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formCommand.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-22
    * Metodo que guarda o actualiza un comando. 
  */
  saveCommand(): void {
    if (this.formCommand.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De continuar con la gestión?').then(isConfirm => {
        if (isConfirm.isConfirmed) {
          this.commandService.storeCommand(this.formCommand.value).subscribe(res => {
            this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
              data: {
                title: '¡Excelente!',
                message: res.data
              },
              duration: 5000,
              verticalPosition: 'top',
              panelClass: ['green-snackbar']
            });
            this.matDialogRef.close(res);
          });
        }
      });
    } else {
      this.formCommand.markAllAsTouched();
    }
  }
}