import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { PositionsService } from '../../../services/positions.service';

@Component({
  selector: 'app-create-charge',
  templateUrl: './create-charge.component.html',
  styleUrls: ['./create-charge.component.scss']
})
export class CreateChargeComponent implements OnInit {
  createForm:FormGroup = this.fb.group({
    name:['',Validators.required],
    dependents:[false],
    key:['',Validators.required], 
    position_category_id:['',Validators.required],
    charges: ['',Validators.required]
  });
  constructor(private fb: FormBuilder,
    private positionsService:PositionsService,
    private dialogRef: MatDialogRef<CreateChargeComponent>,
    private alertService:AlertsService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if(this.data.action == 'Editar')
    {
      this.createForm.patchValue(
        {
          name:this.data.data.type_function_name,
          dependents:this.data.data.dependents,
          key:this.data.data.key,
          position_category_id:this.data.data.type_funtions == "Administrativa" ? 1 : 2,
          charges: this.data.data.charges == "" ? null : this.data.data.charges,
         });
    }
    if(this.data.action == 'Crear')
    {
      this.createForm.addControl('functions',this.fb.array([]));
      this.addEvidence();
    }
  }

  get functions(): FormArray {
    return this.createForm.get('functions') as FormArray;
  }

  addEvidence() {
    const funtionsForm = this.fb.group({
      name : ['', [Validators.required]]
    });
    this.functions.push(funtionsForm);
  }

  removeEvidence(index: number) {
    this.functions.removeAt(index);
  }

  createPosition(){
    switch (this.data.action) {
      case 'Crear':
         let saveconfirm1= this.alertService.alertConfirm('¿Está seguro que desea crear un cargo?')
        saveconfirm1.then(res=>{
          if(res.isConfirmed){
            this.positionsService.postPositionCreate(this.createForm.value).subscribe((res:any)=>{
              this.alertService.alertSuccess('¡Creado!', res);
              this.dialogRef.close(true)
            })
          }
        })
          break;
        case 'Editar':
         let saveconfirm2= this.alertService.alertConfirm('¿Está seguro que desea editar este cargo?')
        saveconfirm2.then(res=>{
          if(res.isConfirmed){
            this.createForm.value.id = this.data.id;
            this.positionsService.postPosition(`update/${this.data.id}`, this.createForm.value).subscribe((res:any)=>{
              this.alertService.alertSuccess('¡Actualizado!', `El cargo ${this.createForm.value.name} ha sido editado con éxito`);
              this.dialogRef.close(true)
            })
          }
        })
          break;

    }

  }


}
