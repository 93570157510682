<div class="main-container">
    <h1>Solicitud {{request?.code }}</h1>
    <h3>{{request?.request_data?.cargo}}</h3>
    <p>En este espacio podrás visualizar los detalles de la solicitud, detallar los candidatos <br> que ya han sido cargados a esta solicitud. No olvides, “El respeto y empatía son clave”.</p>
    <div fxLayout="row" fxLayoutAlign="end center" *ngIf="request && request.hired_user_id == userId || isRolOPerative">
        <button mat-stroked-button color="accent" style="margin-right: 24px;" matTooltip="Crear salas de assessment"  (click)="createAssessment()">Salas de assessment</button>
        <button mat-raised-button color="primary" (click)="assignRecluter()">{{assignButton}}</button>

    </div>

  <mat-tab-group class="tab-main" mat-align-tabs="left" (selectedTabChange)="getCandidates($event)">
    <mat-tab label="Detalles solicitud">
        <div class="details" *ngIf="request" fxLayout="row" >
            <rrhh-request-info [request]="request"></rrhh-request-info>
        </div>
    </mat-tab>
    <mat-tab label="Candidatos">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="filter">
            <div class="inputs">
                <mat-form-field appearance="fill" fxFlex="50">
                    <mat-label>Tipo de filtro</mat-label>
                    <mat-select [(ngModel)]="typeFilter">
                      <mat-option value="Identificación">Identificación</mat-option>
                      <mat-option value="Email">Email</mat-option>
                      <mat-option value="Teléfono">Teléfono</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="fill" fxFlex="50" *ngIf="typeFilter!=null">
                    <mat-label>Filtrar por {{typeFilter}}</mat-label>
                    <input matInput #filterEmail>
                    <button mat-icon-button matSuffix (click)="filterByValue(filterEmail.value)"><mat-icon>search</mat-icon></button>
                </mat-form-field>

            </div>

            <div class="btns">
                <button mat-stroked-button color="primary" matTooltip="Asignar pruebas masivamente" *ngIf="request?.hired_user_id == userId || isRolOPerative" [disabled]="candidatesByRequest.length == 0" (click)="openMassiveTests()">Pruebas por lotes</button>

                <button mat-stroked-button color="primary" matTooltip="Cargar csv de candidatos" (click)="uploadCandidates()" *ngIf="request?.recruitmentUserId == userId">Carga masiva</button>

                <button mat-flat-button color="primary" (click)="newRegister()" *ngIf="request?.recruitmentUserId == userId">Crear Registro</button>
                <button mat-stroked-button color="primary" (click)="assingAnalyst()" *ngIf="request?.hired_user_id == userId || isRolOPerative || isRolAdmin" [disabled]="candidatesByRequest.length == 0">Asignar a Analista</button>

                <button mat-stroked-button color="primary" (click)="assingTrainer()" *ngIf="request?.hired_user_id == userId || isRolOPerative" [disabled]="candidatesByRequest.length == 0">Asignar a Formador</button>

            </div>


        </div>

        <div>
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="actions">
                    <th class="start" mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                    <td mat-cell *matCellDef="let row" style="text-align:start;">
                         <button *ngIf="(row.state_id != '36') && (row.state_id != '3')" mat-icon-button (click)="getComments(row.id)" matTooltip="Crear comentario"> <span class="fi-rr-comment"></span> </button>
                         <button *ngIf="(row.state_id != '36') && (row.state_id != '3')" mat-icon-button (click)="editCandidate(row.id)"  matTooltip="Editar candidato"><span class="fi-rr-edit"></span></button>
                         <button mat-icon-button *ngIf="row.testAction && request?.hired_user_id == userId && !isRolOPerative" (click)="tests(row)"
                         matTooltip="Asignar pruebas"><span class="fi fi-rr-test"></span></button>
                         <button mat-icon-button *ngIf="row.testAction && request?.hired_user_id != userId && isRolOPerative" (click)="tests(row)"
                         matTooltip="Asignar pruebas"><span class="fi fi-rr-test"></span></button>
                         <button mat-icon-button *ngIf="row.testAction && request?.hired_user_id == userId && isRolOPerative" (click)="tests(row)"
                         matTooltip="Asignar pruebas"><span class="fi fi-rr-test"></span></button>
                    </td>
                </ng-container>


                <ng-container matColumnDef="class">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                    <td mat-cell *matCellDef="let row">
                        <span matSubheader>
                          <mat-icon [style.color]="row.class" >fiber_manual_record</mat-icon> &nbsp; {{row.stateName}}</span>
                    </td>
                </ng-container>


                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Nombre </th>
                    <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                </ng-container>


                <ng-container matColumnDef="id_number">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header># Documento </th>
                    <td mat-cell *matCellDef="let row"> {{row.documentId}} </td>
                </ng-container>


                <ng-container matColumnDef="mobilephone">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Celular </th>
                    <td mat-cell *matCellDef="let row"> {{row.mobile_phone}} </td>
                </ng-container>


                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                    <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                </ng-container>


                <ng-container matColumnDef="tracingAvg">
                    <th class="end" mat-header-cell *matHeaderCellDef mat-sort-header> % Avance </th>
                    <td mat-cell *matCellDef="let row" [style.color]="row.color">
                        <circle-progress [percent]="row.tracingAvg " [radius]="20" [space]="-10" [outerStrokeGradient]="true" [outerStrokeWidth]="8" [outerStrokeColor]="'#2CABBC'" [outerStrokeGradientStopColor]="'#2CABBC'" [innerStrokeColor]="'#e7e8ea'" [innerStrokeWidth]="8"
                            [title]="row.tracingAvg | number:'1.0-0' " [titleFontSize]="10" [animateTitle]="true" [animationDuration]="1000" [showUnits]="true" [showSubtitle]="false" [showBackground]="false" [clockwise]="true" [startFromZero]="false">
                        </circle-progress>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="7">No hay datos que coincidan con la busqueda</td>
                  </tr>
            </table>

            <mat-paginator  [length]="length" [pageSize]="pageSize" (page)="getPaginatorData($event)" showFirstLastButtons></mat-paginator>

        </div>
    </mat-tab>
    <mat-tab label="Historial">
        <div class="head-table">
              <mat-form-field appearance="fill">
                <mat-label>Buscar por fecha</mat-label>
                <input matInput [matDatepicker]="picker" (dateChange)="applyFilter2($event)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            <table mat-table [dataSource]="dataRecod" matSort>
                <ng-container matColumnDef="user_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
                    <td mat-cell *matCellDef="let row"> {{row.user_name}} </td>
                </ng-container>


                <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;">Fecha</th>
                    <td mat-cell *matCellDef="let row"> {{row.created_at | date: 'd/MM/yy hh:mm:ss'}} </td>
                </ng-container>




                <ng-container matColumnDef="new_values">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado de la solicitud</th>
                    <td mat-cell *matCellDef="let row">{{row.new_values.request_state}} </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedRecodColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedRecodColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="5">No hay datos que coincidan con la busqueda</td>
                  </tr>
            </table>
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>



    </mat-tab>
</mat-tab-group>
</div>
