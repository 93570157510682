import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../../../environments/environment';

// Models
import { CampaignUser } from '../models/campaign-user';

@Injectable({
	providedIn: 'root'
})
export class CampaignService {
	API_URL = environment.CARTERA_WOM_URL;
	// CAMPAIGNS = this.API_URL + 'campaign/';
	CAMPAIGNS_USER = this.API_URL + 'campaign_user/campaigns/';

	constructor(private http: HttpClient) {}

	handleError(error: HttpErrorResponse): Observable<any> {
		return throwError(error);
	}

	/**
	 * Método para obtener las campañas vinculadas al usuario logueado.
	 * @param id id del usuario
	 * @author Carlos Gomez
	 * @createdate 2021-05-20
	 */
	getCampaignsByUser(id: number): Observable<CampaignUser[]> {
		return this.http.get<CampaignUser[]>(this.CAMPAIGNS_USER + id).pipe(catchError(this.handleError));
	}
}
