<div class="mat-elevation-z0 mt-2">
    <table  [dataSource]="dataSource" mat-table  matSort>

      <!-- Columna con botónes de acciones -->
      <ng-container matColumnDef="acciones">
        <div>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="detailRetroAlimentacion(element.matriz.id)">
              <i class="fi fi-rr-settings" *ngIf="element.matriz.retroalimentada != 1 && is_supervisor == true; "></i>
              <i class="fi-rr-eye" *ngIf="element.matriz.retroalimentada==1 && is_supervisor == true;"></i>


              <i class="fi-rr-eye" *ngIf="is_supervisor == false;"></i>
              
            </button>
          </td>
        </div>
      </ng-container>

      <!-- Columna ID -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element"> {{ element.matriz.id}} </td>
      </ng-container>



      <!-- Columna fecha auditoría -->
      <ng-container matColumnDef="fecha_auditoria">
        <th mat-header-cell *matHeaderCellDef> Fecha auditoría </th>
        <td mat-cell *matCellDef="let element"> {{ element.matriz.created_at | date:'dd/MM/yyyy' }} </td>
      </ng-container>

      <!-- Columna Campaña -->
      <ng-container matColumnDef="campaña">
        <th mat-header-cell *matHeaderCellDef> Campaña </th>
        <td mat-cell *matCellDef="let element"> {{ element.campaign }} </td>
      </ng-container>

      <!-- Columna nombre auditado -->
      <ng-container matColumnDef="nombre_auditado">
        <th mat-header-cell *matHeaderCellDef> Nombre auditado </th>
        <td mat-cell *matCellDef="let element"> {{ element.matriz.nombre_asesor }} </td>
      </ng-container>

      <!-- Columna nombre auditado -->
      <ng-container matColumnDef="retroalimentada">
        <th mat-header-cell *matHeaderCellDef> Estado </th>
        <td mat-cell *matCellDef="let element"> {{ element.matriz.retroalimentada_nombre }} </td>
      </ng-container>

      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No hay registros para mostrar</td>
      </tr>
    </table>

    
    <mat-paginator [length]="length"
    [pageSize]="pageSize"
    (page) ="pageEvent($event)"
    [pageSizeOptions] = "pageSizeOptions"
    >
  </mat-paginator>
</div>