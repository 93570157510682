import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { socketService } from 'src/app/modules/livechat/services/socket/index.service';
import { SocketActiveService } from 'src/app/modules/livechat/services/socket/socket.service';
import { Message } from 'src/app/modules/livechat/modules/chat/models/message';
import { conversationsService } from 'src/app/modules/livechat/services/session/conversations.service';
import { sessionService } from 'src/app/modules/livechat/services/session/index.service';
import { Project } from 'src/app/modules/livechat/modules/chat/models/project';

@Component({
  selector: 'app-modal-password',
  templateUrl: './modal-password.component.html',
  styleUrls: ['./modal-password.component.sass']
})
export class ModalPasswordComponent implements OnInit {

  passwordForm: FormGroup;
  hide: boolean = true;
  public socket: socketService;
  public project: Project;
  public role: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public authService: AuthService,
    public dialogRef: MatDialogRef<ModalPasswordComponent>,
    private destroyService: DestroyComponentService,
    private _socketActiveService: SocketActiveService,
    private _conversationService: conversationsService,
    private _session: sessionService
  ) { }

  ngOnInit(): void {
    this.formControl();
    this.getProjectOmnichannel();
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo donde se establecen las validaciones del formulario del login
   */
  formControl(): void {

    this.passwordForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.maxLength(15), Validators.minLength(5)])
    });

  }

  get error(): any { return this.passwordForm.controls; }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que evalua en tiempo real los caracteres ingresados en el input, no deja ingresar numeros ni espacios
   * @param event evento del keypress del input usuario
   */
  omitSpecialChar(event): any {
    let k;
    k = event.charCode;
    return ((k > 32 && k < 126));
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que loguea al usuario y guarda sus datos en el local storage
   * @param loginForm formulario del login
   */
  passAuth(passwordForm): any {

    const user = new FormData();

    user.append('id', this.authService.getUser().idCiu);
    user.append('password', passwordForm.value.password);

    this.authService.passwordVerify(user).subscribe((resp) => {
      if (resp.data === 'OK') {
        this.dialogRef.close();
      }
    });

  }

  /**
   * @author Olme Marin
   * @createdate 2021-09-13
   * Método para obtener el socket activo de omnicanlidad
  */
  getSocketActive() {
    return this.socket = this._socketActiveService.getSocketActive();
  }

  /**
   * @author Olme Marin
   * @createdate 2021-09-13
   * Método para conectar agente al socket activo de omnicanlidad
  */
  connectSocket() { 
    //Procesos de socket
    this.socket = new socketService(this.project.id, this.callBackSocket)
    this.setSocketActive(this.socket);
    this.socket.message.subscribe((message: string) => {

      const jsonMessage: Message = JSON.parse(message)
      console.log('MessageSocket: ', jsonMessage)
      console.log('jsonMessage.conversation_id: ', jsonMessage.conversation_id)
      if (jsonMessage.type !== 'hidden')
        if(jsonMessage.conversation_id){
          this._conversationService.setAlertNewMessage(jsonMessage.conversation_id, true);
          // this.addMessages(jsonMessage)
        }
      if (jsonMessage.action) {
        this[jsonMessage.action](jsonMessage)
      }
    })

    this.socket.connect.subscribe(() => {
      const rrhh: any = this.authService.getUser()
      // const rrhh: any = JSON.parse(this._storageService.getItem('user'))
      const rooms:string = this._session.getItem('agent-conversations')
      const conversations = rooms ? JSON.parse(rooms) : null
      const torooms = conversations ? conversations.map(item => item.id) : []
      const toSend = {
        name: rrhh.rrhh.name,
        rrhh: rrhh.rrhh_id,
        document: rrhh.rrhh.ID_number,
        project: this.project.id,
        limit: this.project.users_per_agent,
        rooms: torooms
      }
      this.socket.sendMessage('knownagent', toSend)
    })
  }

  callBackSocket() { }

  /**
   * @author Olme Marin
   * @createdate 2021-09-13
   * Método para enviar el socket a todos los componentes y vistas
  */
  setSocketActive(socket: socketService){
    this._socketActiveService.setSocketActive(socket);
  }

  /**
   * @author Olme Marin
   * @createdate 2021-09-13
   * Método para obtener información del proyecto activo de omnicanlidad
  */
  getProjectOmnichannel(){
    this.project  = JSON.parse(this._session.getItem('project'));
    this.role     = this._session.getItem('role')
  }


  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

}
