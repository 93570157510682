<div class="admin-auditoria" >

    <div class="header">
  
      <h2 style="height: 33px !important;
      font: normal normal bold 45px/35px Poppins !important;
      letter-spacing: 0px !important;color: #353535 !important;opacity: 1 !important;">Auditoría</h2>
      <br>
      <p>Aquí podrás auditar tus matrices asignadas. No</p>
      <p>olvides, “El respeto y empatía son clave”.</p>
    </div>

    <app-main-matrices class="mt-5"></app-main-matrices>

</div>