import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../core/services/rest/auth.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  public server = environment.RRHH_URL;
  private user: any;

  private refreshTemplatesSource = new BehaviorSubject<boolean>(false);
  refreshTemplates$ = this.refreshTemplatesSource.asObservable();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
    this.user = this.authService.getUser();
  }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  getTemplates() {
    return this.http.get(`${this.server}templates`);
  }

  getHistory(body): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('page', body.page || '')
        .set('perPage', body.perPage || '')
        .set('filters[contract_type_id]', body.contract_type_id || '')
        .set('filters[version]', body.version || '')
        .set('filters[contract_templates.created_at]', body.created_at || '')
        .set('filters[status]', body.status || '')
    };
    return this.http.get(`${this.server}templates_history`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getTags(body): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('paginateOff', body.paginateOff || false)
        .set('page', body.page || '')
        .set('perPage', body.perPage || '')
        .set('filters[contract_type_id]', body.contract_type_id || '')
        .set('filters[contract_template_tags.contract_template_tag_origin_id]', body.origin || '')
    };
    return this.http.get(`${this.server}template/tags`, httpOptions);
  }


  storeTag(formData: FormData): Observable<any> {
    return this.http.post(`${this.server}template/tags`, formData);
  }


  getTagsReport(body) {
    const httpOptions = {
      params: new HttpParams()
        .set('page', body.page || '')
        .set('perPage', body.perPage || '')
        .set('filters[contract_type_id]', body.contract_type_id || '')
        .set('filters[contract_template_tags.contract_template_tag_origin_id]', body.origin || ''),
      responseType: 'blob' as 'json'
    };
    return this.http.get(`${this.server}template/tags/report`, httpOptions);
  }

  getInfoRrhhIds(rrhhIds: number[]): Observable<any> {
    const requestBody = { rrhh_ids: rrhhIds };
    return this.http.post(`${this.server}users/getInfoRrhhIds`, requestBody);
  }

  getOrigins(): Observable<any> {
    return this.http.get(`${this.server}template/origins`);
  }

  storeTemplate(file: File, contractTypeId: string): Observable<any> {
    const formData = new FormData();
    const responsableId = this.user['rrhh_id'];
    formData.append('path', file);
    formData.append('contract_type_id', contractTypeId);
    formData.append('responsable_id', responsableId);

    return this.http.post(`${this.server}templates`, formData);
  }

  showTemplate(id) {
    return this.http.get(`${this.server}templates/${id}`);
  }

  upgrade(id) {
    return this.http.get(`${this.server}templates_upgrade?id=${id}`);
  }

  triggerRefresh(): void {
    this.refreshTemplatesSource.next(true);
  }

  getTag(id: number): Observable<any> {
    return this.http.get(`${this.server}template/tag?id=${id}`);
  }

  updateTag(tag: any): Observable<any> {

    return this.http.put(`${this.server}template/tags`, tag);
  }
}
