import { Component, OnInit } from '@angular/core';
import {TranslatesService} from '../../services/translates.service';

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.sass']
})
export class ControlComponent implements OnInit {
  private uuid = 'fdb220f2-9372-11ee-b9d1-0242ac120002';
  public config: any;

  constructor(
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
  }

}
