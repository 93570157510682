import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalSurveysUserComponent } from 'src/app/mios/modal-surveys-user/modal-surveys-user.component';

@Injectable({
    providedIn: 'root'
  })
  export class DialogStateService{
    private dialogRef: MatDialogRef<any>;
    private isOpenDialog = false;

    constructor(private dialog: MatDialog) {}

    openDialogSurveys(){
        this.dialogRef = this.dialog.open(ModalSurveysUserComponent,{
            id:'dialog-surveys',
            width: '95vw',
            height: 'auto',
            disableClose: true,
        });

        this.dialogRef.afterClosed().subscribe(
            (closedResponse)=>{
                this.isOpenDialog = false
            }
        );

        this.isOpenDialog = true
    }

    resetDialog() {
        this.dialogRef = null;
        this.isOpenDialog = false;
    }
    
    isDialogOpen() {
        return this.isOpenDialog;
    }

  }