import { Component, OnInit, Inject } from '@angular/core';
import { Menu } from '../../class/menu';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MenusService } from '../../../services/rest/menus.service';
import { DestroyComponentService } from '../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { AuthService } from '../../../../../core/services/rest/auth.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationsService } from '../../../services/rest/applications.service';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-save-menus',
  templateUrl: './save-menus.component.html',
  styleUrls: ['./save-menus.component.sass']
})
export class SaveMenusComponent implements OnInit {

  menuForm: FormGroup;
  filteredOptions: Observable<any[]>;
  applications: any;
  menus: any;
  menusSet: any = [];

  constructor(private menuService: MenusService,
              private appService: ApplicationsService,
              private destroyService: DestroyComponentService,
              private alertsService: AlertsService,
              private AuthService: AuthService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {

    this.formControl();
    
    this.appService.getApplications().subscribe((resp) => {
      this.applications = resp.data;
      this.setEdit();
      this.filteredOptions = this.menuForm.get('applications').valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(applications => applications ? this._filter(applications) : this.applications.slice())
      );
    });
    
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * filtra por nombre, se usa para el autocompletable
   * @param value valor a filtrar
   */
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.applications.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   */
  displayFn(id: number): string{
    if (!id) { return ''; }
    
    let index = this.applications.findIndex(resp => resp.id === id);
    return this.applications[index].name;

  }

  get error(): any { return this.menuForm.controls; }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo donde se establecen las validaciones del formulario
   */
  formControl(): void{

    this.menuForm = new FormGroup({
      applications: new FormControl('', [this.autocompleteObjectValidator(), Validators.required]),
      name: new FormControl('', [Validators.required, Validators.maxLength(40), Validators.minLength(3)]),
      icon: new FormControl('', [Validators.required, Validators.maxLength(40), Validators.minLength(3)]),
      url: new FormControl('', [Validators.required, Validators.maxLength(40), Validators.minLength(3)]),
      index: new FormControl('', [Validators.required, Validators.maxLength(40), Validators.minLength(3)]),
     
    });

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que valida las funciones del autocompetable
   */
  autocompleteObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string') {
        return { invalidAutocompleteObject: { value: control.value } };
      }
      return null;
    };
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que setea los valores al momento de editar
   */
  setEdit(): void {
    if (this.data.type === 'editar') {
     this.menuForm.controls.applications.setValue(Number(this.data.menu.application.id));
     this.menuForm.controls.name.setValue(this.data.menu.name);
     this.menuForm.controls.icon.setValue(this.data.menu.icon);
     this.menuForm.controls.url.setValue(this.data.menu.url);
     this.menuForm.controls.index.setValue(this.data.menu.index);
      
    }
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que a partir de una variable enviada desde el administrador de roles crea un nuevo rol o edita un rol
   * @param rolForm datos que recolecta el formulario
   */
   saveMenu(rolForm): void {

    if (this.data.type === 'crear'){
      const menu = new Menu();

      menu.id=0;
      menu.application_id =this.menuForm.value.applications;
      menu.name = this.menuForm.value.name;
      menu.icon = this.menuForm.value.icon;
      menu.url = this.menuForm.value.url;
      menu.index=this.menuForm.value.index;
      this.menuService.saveMenu(menu).subscribe((resp) => {
        this.alertsService.alertSuccess('Guardado', resp.data);
      });
    }
    else if (this.data.type === 'editar'){
      this.editMenu();
    }

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que edita un rol
   * @param rolForm datos que recolecta el formulario
   */
  editMenu(): void {
    
    const menu = new Menu();
      menu.id=this.data.menu.id;
     
      menu.application_id =this.menuForm.value.applications;
      menu.name = this.menuForm.value.name;
      menu.icon = this.menuForm.value.icon;
      menu.url = this.menuForm.value.url;
      menu.index=this.menuForm.value.index;

    this.menuService.editMenu(menu).subscribe((resp) => {
      this.alertsService.alertSuccess('Editado', resp.data);
    });
  }

 

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }
}
