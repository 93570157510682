<!-- i class="icon-close-modal color-gray fi-rr-cross-small" mat-icon-button mat-dialog-close></i> -->
<h2 mat-dialog-title>Tienes {{totalSurvey}} encuestas por diligenciar.</h2>
<mat-divider class="w-100"></mat-divider>
<mat-dialog-content class="padding-mat-dialog-content">
    <div class="row w-100">
        <div class="col-12">
            <mat-tab-group class="w-100" mat-align-tabs="center" #tabsForms  [(selectedIndex)]="indexTabSurvey">
                <mat-tab [label]="'Encuesta ' +(i + 1)" *ngFor="let item of surveyList;  index as i" disabled> 
                    <div class=" w-100 d-flex mt-4 justify-content-center">
                    <span class="button_next " *ngIf="isOptionalSurvey(item)" >
                        <b>Saltar</b>
                        &nbsp;
                        <button (click)="skipSurvey(i)"  mat-mini-fab color="primary" aria-label="Saltar encuesta">
                            <mat-icon>skip_next</mat-icon>
                        </button>
                    </span>
                        
                        <h2>{{ item.name }}</h2>
                    </div>
                    
                    <shared-dynamic-form-v2  class="w-100"
                    [showButtonsActions]="true"
                    [sections]="{sections:item.sections , app:'encuestas'}" 
                    (submitForm)="saveSurvey($event, i)"
                   ></shared-dynamic-form-v2>
                </mat-tab>
              </mat-tab-group>
        </div>
    </div>


</mat-dialog-content>