<div fxLayout="row wrap">
    <div class="calendar">

        <full-calendar [options]="calendarOptions" dateClick="dateClick($event)" #calendar id="calendar"></full-calendar>
        <div style="margin-top: 20px;">
            Convenciones: <br>
            <ul class="conventions">
                <li *ngFor="let convention of optionsBooking['conventions']" [class]="convention.color"> <span>{{convention.label}}</span></li>
            </ul>

        </div>
    </div>


    <div class="schedules" *ngIf="optionsBooking['typeDetail']=='items' && optionsBooking['daySelectedBooking']!='' ">
        <div *ngIf="optionsBooking['eventsItems'].length==0">
            <p style="font-size: 16px !important; color: #BD77CE; font-weight: 700; text-align: center;padding-top: 26px;">
                {{(optionsBooking['daySelectedBooking'] | date:'EEEE d').charAt(0).toUpperCase()+(optionsBooking['daySelectedBooking'] | date:'EEEE d').slice(1).toLowerCase()}} de {{(optionsBooking['daySelectedBooking'] | date:'MMMM').charAt(0).toUpperCase()+(optionsBooking['daySelectedBooking']
                | date:'MMMM').slice(1).toLowerCase()}}
                <br>
                <br> Día no disponible
            </p>

        </div>
        <div *ngIf="optionsBooking['eventsItems'].length>0">
            <p style="font-size: 16px !important; color: #BD77CE; font-weight: 700; text-align: center;padding-top: 26px;">
                {{(optionsBooking['daySelectedBooking'] | date:'EEEE d').charAt(0).toUpperCase()+(optionsBooking['daySelectedBooking'] | date:'EEEE d').slice(1).toLowerCase()}} de {{(optionsBooking['daySelectedBooking'] | date:'MMMM').charAt(0).toUpperCase()+(optionsBooking['daySelectedBooking']
                | date:'MMMM').slice(1).toLowerCase()}}
            </p>
            <div class="row col-12">
                <div class="col-3" *ngFor="let hour of hours">
                    <mat-chip-list aria-label="Fish selection">
                        <div *ngFor="let item of optionsBooking['eventsItems']">
                            <mat-chip *ngIf="item.times.substring(0,2) == hour" color="#BD77CE" [matTooltipPosition]="'above'" [matTooltip]="item.doctor" class="chipButton" selected (click)="clickItem(item.id)" style="margin-bottom: 8px;" too>
                                {{item.times}}
                            </mat-chip>
                        </div>
                    </mat-chip-list>
                </div>
            </div>
        </div>

    </div>


    <div class="schedules" *ngIf="optionsBooking['typeDetail']=='details'  && optionsBooking['daySelectedBooking']!=''">
        <div *ngIf="optionsBooking['eventsDetails'].length==0">
            <strong>{{(optionsBooking['daySelectedBooking'] | date:'EEEE, MMMM d, y').charAt(0).toUpperCase()+(optionsBooking['daySelectedBooking'] | date:'EEEE, MMMM d, y').slice(1).toLowerCase()}} <br> <br> No hay citas agendadas</strong>
        </div>
        <div *ngIf="optionsBooking['eventsDetails'].length>0">
            <strong>{{(optionsBooking['daySelectedBooking'] | date:'EEEE, MMMM d, y').charAt(0).toUpperCase()+(optionsBooking['daySelectedBooking'] | date:'EEEE, MMMM d, y').slice(1).toLowerCase()}} <br> <br> Citas agendadas  </strong>
            <table>
                <tr *ngFor="let fila of optionsBooking['eventsDetails']">
                    <td *ngFor="let item of fila">{{item}}</td>
                </tr>
            </table>
        </div>
    </div>


</div>
