<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>
<h3 mat-dialog-title>Documentos del candidato</h3>
<hr>
<mat-dialog-content>
    <table mat-table [dataSource]="candidateDocuments">
        <ng-container matColumnDef="actions" >
          <th class="start" mat-header-cell *matHeaderCellDef> Acciones </th>
          <td mat-cell *matCellDef="let row" style="text-align: left;">
             <button mat-icon-button *ngIf="row.uploaded" (click)="checkItem(row.id)" matTooltip="Cambiar estado"><span class="fi-rr-file-check" ></span></button>
            <button mat-icon-button *ngIf="row.uploaded" (click)="downdloadFile(row, true)" matTooltip="Ver documento"><span class="fi-rr-eye"></span></button>
            <button mat-icon-button *ngIf="row.uploaded"  matTooltip="Descargar documento" (click)="downdloadFile(row)"><span class="fi-rr-cloud-download"></span></button> 
          </td>
        </ng-container>
        <ng-container matColumnDef="file">
          <th mat-header-cell *matHeaderCellDef> Tipo </th>
          <td mat-cell *matCellDef="let element"> {{element.file}} </td>
        </ng-container>
      
         <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Archivo cargado</th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
      
       <ng-container matColumnDef="state">
          <th class="end" mat-header-cell *matHeaderCellDef> Estado</th>
          <td mat-cell *matCellDef="let element"
          [ngStyle]="{'color': element.state=='Aprobado'? '#8ed885': element.state=='Pendiente'? '#A9A9A9':'#CD5E5E' }"> 
          {{element.state}} </td>
        </ng-container> 
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
</mat-dialog-content>
