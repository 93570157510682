<i class="fi-rr-cross-small" style="float: right; font-size: 32px; color: #353535;" mat-icon-button (click)="cancelSave()"></i>

<form [formGroup]="createCampaignForm">
  <div class="col-md-12 p-5">
      <div class="mb-4" style="font-weight: 700; font-size: 28px;">Crear campaña</div>
      <mat-divider role="separator" class="mat-divider mat-divider-horizontal" aria-orientation="horizontal"></mat-divider>
      
      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Nombre de la campaña</mat-label>
          <input matInput placeholder="Nombre de la campaña" formControlName="campaign_name" maxlength="100" required>
          <mat-error *ngIf="createCampaignForm.controls['campaign_name'].hasError('required')">
            Nombre de la campaña es requerido
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Conexión</mat-label>
          <mat-select placeholder="Conexión" formControlName="campaign_conexion" (selectionChange)="CampaignChange()" required>
            <mat-option *ngFor="let item of connections" [value]="item.id">{{ item.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="createCampaignForm.controls['campaign_conexion'].hasError('required')">
            Seleccione una conexión
          </mat-error>
        </mat-form-field>
      </div>
      
      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Campaña</mat-label>
          <input type="text" matInput formControlName="campaign" [matAutocomplete]="auto" required />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnCampaign.bind(this)" required>
            <mat-option *ngFor="let option of filteredOptionsCampaign | async" [value]="option.campaign_id">
              {{ option.campaign_name }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="createCampaignForm.controls['campaign'].hasError('required')">
            Seleccione una campaña
          </mat-error>
          <mat-error *ngIf="createCampaignForm.controls['campaign'].hasError('invalidCampaign')">
            Campaña no válida
          </mat-error>
          <!-- Hint -->
          <mat-hint *ngIf="createCampaignForm.controls['campaign'].value" style="font-size: 12px;">
            <strong style="font-size: 12px !important;">Fecha del último registro de grabaciones:</strong> {{ fecha_llamada || 'No disponible' }}
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Tiempo muerto (segundos)</mat-label>
            <input type="number" matInput placeholder="Tiempo muerto" formControlName="dead_time" min="0" maxlength="50" required>
          <mat-error *ngIf="createCampaignForm.controls['dead_time'].hasError('required')">Seleccione una opción</mat-error>
        </mat-form-field>
      </div>
      
      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Bilingue</mat-label>
          <mat-select placeholder="Bilingue" formControlName="campaign_bilingue" required>
            <mat-option value="Si">Si</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
          <mat-error *ngIf="createCampaignForm.controls['campaign_bilingue'].hasError('required')">
            Seleccione una opción
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mt-4">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Fecha</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="delimit_date" [matDatepickerFilter]="dateFilter" placeholder="Selecciona una fecha">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
  </div>
  
</form>

<div class="col-md-12 p-5 pt-0">
  <div class="row py-3 d-flex justify-content-end">
    <div class="col-md-3">
      <button (click)="cancelSave()" type="button" mat-raised-button class="w-100" style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Cancelar</button>
    </div>
    <div class="col-md-3">
      <button (click)="sendDataCampaign()" type="button" mat-raised-button color="primary" class="w-100" style="height: 48px; font-weight: 600;">Guardar</button>
    </div>
  </div>
</div>