<div class="admin-auditoria">

  <div class="header">

    <h2 style="height: 33px !important;
      font: normal normal bold 45px/35px Poppins !important;
      letter-spacing: 0px !important;color: #353535 !important;opacity: 1 !important;">{{config.labels.auditDeletion}}</h2>
    <br>
    <p>{{config.labels.deleteAuditsInfo1}}</p>
    <p>{{config.labels.deleteAuditsInfo2}}</p>
  </div>

  <app-tabla-eliminacion></app-tabla-eliminacion>

</div>
