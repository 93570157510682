import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/services/rest/auth.service';

const PROVEEDOR_URL = environment.PROVEEDOR_URL;

@Injectable({
  providedIn: 'root'
})
export class ProveedoresService {

  rol:any;
  user:any;
  headers;
  headersFile;
  constructor(private http:HttpClient, private authService: AuthService) {
    this.leerStorage();
   }

  /**
   * @author Cristian Gallo <cristian.g@montechelo.com.co>
   * @purpose Servicio que lista todos los proveedores
   */
  getProveedores( page:number,params:any ):Observable<any> {

    return this.http.post(`${PROVEEDOR_URL}proveedores?page=${page}`,params,this.headers);
  }

  getProveedorId(id:number){
    return this.http.get(`${PROVEEDOR_URL}internalShow/${id}`,this.headers);
  }

  getTipoIdentificacion(){
    return this.http.get(`${PROVEEDOR_URL}tipoIdentificacion`,this.headers);
  }

  getTipoPersona(){
    return this.http.get(`${PROVEEDOR_URL}tipoPersona`,this.headers);
  }

  getRetenedor(){
    return this.http.get(`${PROVEEDOR_URL}retenedor`,this.headers);
  }

  getTipoCuenta(){
    return this.http.get(`${PROVEEDOR_URL}tipoCuenta`,this.headers);
  }

  getRegimen(){
    return this.http.get(`${PROVEEDOR_URL}regimen`,this.headers);
  }

  saveProveedor(data:any){
    return this.http.post(`${PROVEEDOR_URL}interno/proveedor`,data,this.headers);
  }

  updateProveedor(id:number,data:any){
    return this.http.put(`${PROVEEDOR_URL}updateInterno/${id}`,data,this.headers);
  }

  descargaDoc(link : string){
    return this.http.get(`${link}`,this.headersFile);
  }

  descargaGestion(data: any){
    return this.http.post(`${PROVEEDOR_URL}descargaProveedores`, data, this.headersFile);
  }



  leerStorage(){
    this.user = this.authService.getToken();
    this.headers = new HttpHeaders ({
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization' : 'Bearer '+this.user
    });
    this.headersFile = {
      responseType: "blob",
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization' : 'Bearer '+this.user
      })
    };
  }
}
