import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { CandidateCommentsComponent } from "src/app/modules/rrhh/modules/candidates/candidate-comments/candidate-comments.component";

@Component({
  selector: 'app-userStatus-response',
  templateUrl: './userStatus-response.component.html',
  styleUrls: ['./userStatus-response.component.sass']
})
export class UserStatusResponseComponent implements OnInit {
  status: FormGroup;
  user:any =  [{id:36 , name:"Inactivo"},
                {id:3 , name:"Listado"}]
  constructor(
  private dialogRef: MatDialogRef<CandidateCommentsComponent>,
  private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.status = this.fb.group({
      state  :['', Validators.required],
      comment:[],
    });
  }

  send(){
    if(this.status.value.status_id !== '')
      this.dialogRef.close(this.status.value)
  }
}
