<div class="main-container">

  <div class="row">
    <div class="head col-6">
      <button mat-icon-button (click)="backRoute()">
        <div class="display:flex">
          <i class="fi-rr-angle-small-left" style="font-size:24px;"></i>
          <span
            style="vertical-align: super;font: normal normal 600 16px/25px Poppins;letter-spacing: 0px;color: #353535;opacity: 1;">Regresar</span>
        </div>
      </button>
      <br>
      <br>
      <h1>Gestión de reportes</h1>
      <p>Bienvenido al módulo de reportes</p>
    </div>
    <br>
  </div>

  <div class="row">
    <mat-form-field class="col-4" appearance="fill">
      <mat-label>Reporte</mat-label>
      <mat-select (selectionChange)="getReport($event.value)" [(value)]="selected">
        <mat-option *ngFor="let item of reports; let i = index" [value]="item.key">
          <span>{{item.nombre}}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!--
    <div class="col-6"></div>

    <div class="col-3" style="text-align: right;">
      <button mat-raised-button color="primary"  style="height:48px">Crear nuevo reporte</button>
    </div>
  </div>

  <div class="row" *ngIf="key != null">
    <iframe class="col-lg-12 col-md-12 col-sm-12" height="700px" [src]="key"></iframe>
  </div>-->

    <div class="mt-3" *ngIf="generar">
      <app-report-view [tokenReport]="token" [reportId]="report_id" style="height: 100%;"></app-report-view>
    </div>

  </div>