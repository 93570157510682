<h4 class="title-historico"><i class="fi-rr-refresh"></i> Histórico de la solicitud</h4>
<div class="mat-elevation-z0 mt-4">
    <table  [dataSource]="dataSource" mat-table  matSort>

      <!-- Columna con botónes de acciones -->
      <ng-container matColumnDef="acciones">
        <div>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="dialogHistorico(element.historial.id)" *ngIf="element.historial.id">
              <i class="fi-rr-eye"></i>
            </button>
          </td>
        </div>
      </ng-container>

       <!-- Columna position -->
       <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> Cargo </th>
        <td mat-cell *matCellDef="let element"> {{ element.supervisor && element.supervisor.position ? element.supervisor.position : ''  | titlecase }} </td>
      </ng-container>


      <!-- Columna Coach -->
      <ng-container matColumnDef="coach">
        <th mat-header-cell *matHeaderCellDef> Coach </th>
        <td mat-cell *matCellDef="let element"> {{ (element.supervisor.rrhh.first_name + ' ' + element.supervisor.rrhh.last_name) | titlecase }} </td>
      </ng-container>

      <!-- Columna Estado -->
      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef> Estado </th>
        <td mat-cell *matCellDef="let element"> {{ element.historial.estados.nombre  }} </td>
      </ng-container>

      <!-- Columna fecha de creación -->
      <ng-container matColumnDef="fecha_creacion">
        <th mat-header-cell *matHeaderCellDef> Fecha de creación </th>
        <td mat-cell *matCellDef="let element"> {{ element.historial.fechaCreacion | date:'dd/MM/yyyy' }} </td>
      </ng-container>

      <!-- Columna fecha de seguimiento -->
      <ng-container matColumnDef="proximo_seguimiento">
        <th mat-header-cell *matHeaderCellDef> Próximo seguimiento </th>
        <td mat-cell *matCellDef="let element"> {{ element.historial.proximoSeguimiento | date:'dd/MM/yyyy' }} </td>
      </ng-container>

      <!-- Columna fecha de ejecución -->
      <ng-container matColumnDef="fecha_ejecucion">
        <th mat-header-cell *matHeaderCellDef> Fecha de ejecución </th>
        <td mat-cell *matCellDef="let element"> {{ element.historial.created_at | date:'dd/MM/yyyy' }} </td>
      </ng-container>
      
      <!-- Columna días restantes -->
      <ng-container matColumnDef="dias">
        <th mat-header-cell *matHeaderCellDef> Días restantes </th>
        <td mat-cell *matCellDef="let element"> {{ element.historial.dias_restantes != null ? element.historial.dias_restantes:'N/A' }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="12">No hay registros para mostrar</td>
      </tr>
    </table>

    
    <mat-paginator [length]="length"
    [pageSize]="pageSize"
    (page) ="pageEvent($event)"
    [pageSizeOptions] = "pageSizeOptions"
    >
  </mat-paginator>
</div>