
<div class="coaching-main">   
    <h2 mat-dialog-title style="font-weight: 700 !important;">Histórico de la solicitud
        <button style="float: right; margin-top: -.5rem;" mat-icon-button mat-dialog-close>
            <i class="fi-rr-cross" style="font-size: 16px;"></i>
        </button>
    </h2>
    <div style="padding-right: 3.8rem;">
        <mat-divider></mat-divider>
    </div>
    <div matDialogContent>
        <form class="row" [formGroup]="historicoForm">
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Nombre del Coachee</mat-label>
                <input type="text" matInput formControlName="nombre_coachee">
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Cédula del Coachee</mat-label>
                <input type="number" matInput formControlName="cedula_coachee"> 
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Nombre del Coach</mat-label>
                <input type="text" matInput formControlName="nombre_coach">
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Cédula del Coach</mat-label>
                <input type="number" matInput formControlName="cedula_coach">
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Estado</mat-label>
                <mat-select formControlName="estado">         
                    <mat-option *ngFor="let estado of estados$ | async" [value]="estado.id">{{estado.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Fecha de creación</mat-label>
                <input matInput [matDatepicker]="fecha_creacion" formControlName="fecha_creacion">
                <mat-datepicker-toggle matSuffix [for]="fecha_creacion">
                    <mat-icon matDatepickerToggleIcon><i class="fi-rr-calendar"></i></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #fecha_creacion></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Próximo seguimiento</mat-label>
                <input matInput [matDatepicker]="fecha_seguimiento" formControlName="proximo_seguimiento">
                <mat-datepicker-toggle matSuffix [for]="fecha_seguimiento">
                    <mat-icon matDatepickerToggleIcon><i class="fi-rr-calendar"></i></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #fecha_seguimiento></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Fecha de ejecución</mat-label>
                <input matInput [matDatepicker]="fecha_ejecucion" formControlName="fecha_ejecucion">
                <mat-datepicker-toggle matSuffix [for]="fecha_ejecucion">
                    <mat-icon matDatepickerToggleIcon><i class="fi-rr-calendar"></i></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #fecha_ejecucion></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Días restantes</mat-label>
                <input type="text" matInput formControlName="dias">
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Indicador</mat-label>
                <mat-select formControlName="indicador">
                    <mat-option *ngFor="let indicador of indicadores$ | async" [value]="indicador.id">{{indicador.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Tipo de proceso</mat-label>
                <mat-select formControlName="tipo_proceso">
                    <mat-option *ngFor="let proceso of procesos$ | async" [value]="proceso.id">{{proceso.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Campaña</mat-label>
                <mat-select formControlName="campaing">
                    <ng-container *ngIf="campaignJefe; else noJefe">
                        <mat-option *ngFor="let campaign of campaigns$ | async" [value]="campaign.id">{{campaign.name | uppercase }}</mat-option>
                    </ng-container>
                    <ng-template #noJefe>
                        <mat-option *ngFor="let campaign of campaigns$ | async" [value]="campaign.id">{{campaign.name | uppercase }}</mat-option>
                    </ng-template>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Indicador inicial</mat-label>
                <input type="text" matInput formControlName="indicador_inicial"
                [value]="historicoForm.get('indicador_inicial').value |number:'1.0-3'">
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Cuartil</mat-label>
                <mat-select formControlName="cuartil_inicial">
                    <mat-option *ngFor="let cuartil of cuartiles$ | async" [value]="cuartil.id">{{cuartil.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Objetivo KPI</mat-label>
                <input type="text" matInput formControlName="objetivo_kpi"
                [value]="historicoForm.get('objetivo_kpi').value |number:'1.0-3'">
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Causa raíz</mat-label>
                <mat-select matInput formControlName="causa_raiz">
                    <mat-option *ngFor="let causa of causas$ | async" [value]="causa.id">{{causa.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-12">
                <mat-label>Conclusiones de la sesión</mat-label>
                <textarea type="text" matInput matInput formControlName="conclusion_sesion"></textarea>
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6" *ngIf="acompanamientos">
                <mat-label>Cumple compromiso</mat-label>
                <mat-select formControlName="cumpleCompromiso">
                    <mat-option value="na">No aplica</mat-option>
                    <mat-option *ngFor="let compromiso of compromisos$ | async" [value]="compromiso.id">{{compromiso.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6" *ngIf="acompanamientos">
                <mat-label>Presenta evolución</mat-label>
                <mat-select formControlName="presentaEvolucion">
                    <mat-option value="na">No aplica</mat-option>
                    <mat-option *ngFor="let evolucion of evoluciones$ | async" [value]="evolucion.id">{{evolucion.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Seguimiento</mat-label>
                <input type="text" matInput formControlName="seguimiento">
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Estado asesor</mat-label>
                <mat-select formControlName="estado_asesor">
                    <mat-option value="na">No aplica</mat-option>
                    <mat-option *ngFor="let estado of estados$ | async" [value]="estado.id">{{estado.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>            
            <mat-form-field appearance="fill" class="col-12">
                <mat-label>Comentario asesor</mat-label>
                <textarea type="text" matInput matInput formControlName="comentario_asesor"></textarea>
            </mat-form-field>
        </form>
    </div>
</div>