import { createAction, props } from "@ngrx/store";
import { CampañaModel } from "../../core/interfaces/campaña.interface";
import { SelectsModel } from '../../core/interfaces/selects.interface';

/**
 * @author Cristian Gallo
 * @createdate 2021-12-10
 * Action que cargara la información de los diferentes selects que se encuentren en app-coaching.
 * Los selects que hay son:
 * indicadores, procesos, campañas, cuartiles, causa raiz y estados
*/

export const loadSelects = createAction(
    '[Selects] Load selects',
    props<{ rol?,rrhhId?}>()
);

export const loadedSelects = createAction(
    '[Selects] Loaded selects',
    props<{ 
        selects:SelectsModel 
    }>()
);

export const loadedCampañas = createAction(
    '[Campañas] Loaded Campañas',
    props<{ campañas:CampañaModel[] }>()
);
// export const loadedIndicadores = createAction(
//     '[Indicadores] Loaded indicadores',
//     props<{ indicadores:IndicadorModel[] }>()
// );

// export const loadedProcesos = createAction(
//     '[Procesos] Loaded procesos',
//     props<{ procesos:ProcesoModel[] }>()
// );


// export const loadedCuartiles = createAction(
//     '[Cuartiles] Loaded cuartiles',
//     props<{ cuartiles:CuartilModel[] }>()
// );

// export const loadedCausas = createAction(
//     '[Causas] Loaded causas',
//     props<{ causas:CausaRaizModel[] }>()
// );

// export const loadedEstados = createAction(
//     '[Estados] Loaded estados',
//     props<{ estados:EstadoModel[] }>()
// );

// export const loadedCompromisos = createAction(
//     '[Compromisos] Loaded compromisos',
//     props<{ compromisos:CompromisoModel[] }>()
// );

// export const loadedEvoluciones = createAction(
//     '[Evoluciones] Loaded evoluciones',
//     props<{ evoluciones:EvolucionModel[] }>()
// );