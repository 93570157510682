<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross"></i>
</button>
<h3 mat-dialog-title>{{data.action}} cargo</h3>
<hr>
<div class="container-d" align="center">
  <mat-dialog-content  [ngStyle]="{'height': data.action == 'Crear' ? '410px' : '235px' }">
    <form [formGroup]="createForm" class="row">
      <div class="col-6">
        <mat-form-field appearance="fill" class="width-in">
          <mat-label>Nombre del cargo</mat-label>
          <input type="text" matInput autocomplete="off" formControlName="name" required>
       </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="fill" class="width-in">
          <mat-label>Código</mat-label>
          <input type="text" matInput autocomplete="off" formControlName="key" required>
        </mat-form-field>
      </div>
     
      <div class="col-6">
        <mat-form-field appearance="fill" class="width-in">
          <mat-label>Tipo de Cargo</mat-label >
          <mat-select required formControlName="position_category_id">
              <mat-option [value]="1">Administrativo</mat-option>
              <mat-option [value]="2">Operativo</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="fill" class="width-in" required>
          <mat-label>Identificar tipo de cargo</mat-label >
          <mat-select required formControlName="charges">
              <mat-option value="Jefatura">Jefatura</mat-option>
              <mat-option value="Coordinación">Coordinación</mat-option>
              <mat-option value="Directivos">Directivos</mat-option>
              <mat-option value="Gerenciales">Gerenciales</mat-option>
              <mat-option value="N/A">N/A</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12" align="left">
        <mat-checkbox formControlName="dependents" [value]="0">¿Personas a cargo?</mat-checkbox>
      </div>
 
      <div class="col-12 mt-3" *ngIf="data.action == 'Crear'">
        <div *ngFor="let item of functions.controls; let i = index" formArrayName="functions">
          <div [formGroupName]="i">
            <div class="row">
              <div class="col-6" align="left">
                <h4> Función N° {{i + 1}} </h4>
              </div>
              <div class="col-6" *ngIf="functions.length > 1">
                <mat-icon class="trahsIcon" (click)="removeEvidence(i)"><i class="fi-rr-trash"></i></mat-icon>
              </div>
            </div>
              <hr class="line">
              <div>
                  <mat-form-field class="col-12" appearance="fill">
                      <mat-label>Función</mat-label>
                      <textarea matInput formControlName="name" required></textarea>
                  </mat-form-field>
              </div>
          </div>
      </div>
      <button mat-stroked-button class="btns col-12" (click)="addEvidence()">
          Añadir función<mat-icon style="font-size: 16px;"><i class="fi-rr-add"></i></mat-icon>
      </button>
      </div> 
      <div class="col-12 mt-3">
        <mat-dialog-actions align="center">
          <button mat-stroked-button  type="button" mat-dialog-close>Cancelar</button>
          <button mat-flat-button color="primary" (click)="createPosition()" [disabled]="createForm.invalid">{{data.action}}</button> 
        </mat-dialog-actions>
      </div>
    </form>
  </mat-dialog-content>
  
</div>