import { ChangeDetectorRef, Component, Input, NgModule, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Params, ParamMap, Router } from '@angular/router';
import { Location } from '@angular/common'
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { Observable } from 'rxjs';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { ClientesService } from '../../services/rest/clientes.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import Swal from 'sweetalert2';
import { OportunidadesService } from '../../services/rest/oportunidades.service';
import { HistorialnComponent } from '../historialn/historialn.component';
import { CompaniesService } from '../../services/rest/companies.service';
import { debounceTime } from 'rxjs/operators';
import { NotesService } from '../../services/rest/notes.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';


@Component({
  selector: 'app-cliente-consulta',
  templateUrl: './cliente-consulta.component.html',
  styleUrls: ['./cliente-consulta.component.sass']
})

export class ClienteConsultaComponent implements OnInit, OnDestroy {

  currentAdIndex = -1;
  clientesconsulta: FormGroup;
  clienteconsulta: string[] = [];
  arraycomerciales: [] = [];
  arrayservicios: any = [];
  arraycompany: any = [];
  element = false;
  data: any;
  idcliente: any;
  cliente: Observable<any>;
  arrayNegadas: any = [];
  arrayProspecto: any = [];
  arrayganada: any = [];
  arraydesierta: any = [];
  arrayPerdidas: any = [];
  hanotaciones: any = [];
  clientInfo: any = [];
  arraycomercial: any[] = [];
  stateData: any[] = [
    { id: 'prospecto', name: 'Prospecto' },
    { id: 'acercamiento', name: 'Acercamiento Comercial' },
    { id: 'construccion', name: 'Construcción de Propuesta' },
    { id: 'enviada', name: 'Enviada' },
    { id: 'negociacion', name: 'Negociación/Sustentación' },
    { id: 'cierre', name: 'Cierre' }
  ];

  //Tab nuevas oportunidades
  @ViewChild(MatPaginator) paginator: MatPaginator;
  obs: Observable<any>;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  pageSize: 8;
  pageSizeOptions: number[] = [8, 16, 32, 100];
  length;
  page = 1;
  comercialControl = new FormControl();
  stateControl = new FormControl();
  lineServiceControl = new FormControl();
  companyControl = new FormControl();
  nameControl = new FormControl();
  comercial: any = '';
  state: any = '';
  lineService: any = '';
  company: any = '';
  nameOportunity: any = '';
  //Tab oportunidades Ganadas
  @ViewChild(MatPaginator) paginator2: MatPaginator;
  obs2: Observable<any>;
  dataSource2: MatTableDataSource<any> = new MatTableDataSource<any>();
  pageSize2: 8;
  pageSizeOptions2: number[] = [8, 16, 32, 100];
  length2;
  page2 = 1;
  comercialControl2 = new FormControl();
  lineServiceControl2 = new FormControl();
  companyControl2 = new FormControl();
  nameControl2 = new FormControl();
  comercial2: any = '';
  lineService2: any = '';
  company2: any = '';
  nameOportunity2: any = '';
  //Tab oportunidades Perdidas
  @ViewChild(MatPaginator) paginator3: MatPaginator;
  obs3: Observable<any>;
  dataSource3: MatTableDataSource<any> = new MatTableDataSource<any>();
  pageSize3: 8;
  pageSizeOptions3: number[] = [8, 16, 32, 100];
  length3;
  page3 = 1;
  comercialControl3 = new FormControl();
  lineServiceControl3 = new FormControl();
  companyControl3 = new FormControl();
  nameControl3 = new FormControl();
  comercial3: any = '';
  lineService3: any = '';
  company3: any = '';
  nameOportunity3: any = '';
  //Tab oportunidades Desiertas
  @ViewChild(MatPaginator) paginator4: MatPaginator;
  obs4: Observable<any>;
  dataSource4: MatTableDataSource<any> = new MatTableDataSource<any>();
  pageSize4: 8;
  pageSizeOptions4: number[] = [8, 16, 32, 100];
  length4;
  page4 = 1;
  comercialControl4 = new FormControl();
  lineServiceControl4 = new FormControl();
  companyControl4 = new FormControl();
  nameControl4 = new FormControl();
  comercial4: any = '';
  lineService4: any = '';
  company4: any = '';
  nameOportunity4: any = '';
  rolUser: any;
  observationField: boolean = false;
  observationControl = new FormControl('',Validators.maxLength(255));
  user: any;
  selectedIndex: any = 0;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private clientesService: ClientesService,
    private dialog: MatDialog,
    private mattab: MatTabsModule,
    private browse: BrowserModule,
    private alertsService: AlertsService,
    private router: Router,
    private fb: FormBuilder,
    private oportunidadservice: OportunidadesService,
    private changeDetectorRef: ChangeDetectorRef,
    private companyService: CompaniesService,
    private notesService: NotesService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.decryptToken().rrhh_id;
    const local = this.authService.getUser();
    local.roles.forEach((rol) => {
      const name = rol.split('::');
      if (name[0] == 'crm-cial' && name[1] == 'comercial') {
        this.rolUser = name[1];
      }
      if (name[0] == 'crm-cial' && name[1] == 'administrador') {
        this.rolUser = name[1];
      }
    });

    this.idcliente = this.route.snapshot.paramMap.get('id');
    this.clientesService.searchId(this.idcliente).subscribe(resp => {
      this.clientInfo = resp.data;
    });
    this.clientesService.getComercialsList().subscribe((resp: any) => {
      this.arraycomercial = resp.data;
    });

    this.clientesService.getLineServices().subscribe((resp: any) => {
      this.arrayservicios = resp.data;
    });

    this.companyService.getCompanies().subscribe((resp: any) => {
      this.arraycompany = resp.data;
    });

    //Tab nuevas oportunidades
    this.tabNuevas();
    //Tab oportunidades Ganadas
    this.tabGanadas();
    //Tab oportunidades perdidas
    this.tabPerdidas();
    //Tab oportunidades desiertas
    this.tabDesiertas();

    this.route.queryParams.subscribe(params => {
      this.selectedIndex = params.tab ? params.tab : 0;
    });
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la consulta y captura de cambios en los filtros de la tab de nuevas oportunidades
  */
  tabNuevas(){
    this.nameControl.valueChanges.pipe(debounceTime(1000)).subscribe(res => {
      this.resetPaginator();
      this.nameOportunity = res;
      this.getOportunitiesBy();
    });
    this.comercialControl.valueChanges.subscribe(resp => {
      this.resetPaginator();
      this.comercial = resp;
      this.getOportunitiesBy();
    });
    this.stateControl.valueChanges.subscribe(resp => {
      this.resetPaginator();
      this.state = resp;
      this.getOportunitiesBy();
    });
    this.lineServiceControl.valueChanges.subscribe(resp => {
      this.resetPaginator();
      this.lineService = resp;
      this.getOportunitiesBy();
    });
    this.companyControl.valueChanges.subscribe(resp => {
      this.resetPaginator();
      this.company = resp;
      this.getOportunitiesBy();
    });
    this.getOportunitiesBy();
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la consulta y captura de cambios en los filtros de la tab de ganadas
  */
  tabGanadas(){
    this.nameControl2.valueChanges.pipe(debounceTime(1000)).subscribe(res => {
      this.resetPaginator2();
      this.nameOportunity2 = res;
      this.getOportunitiesBy2();
    });
    this.comercialControl2.valueChanges.subscribe(resp => {
      this.resetPaginator2();
      this.comercial2 = resp;
      this.getOportunitiesBy2();
    });
    this.lineServiceControl2.valueChanges.subscribe(resp => {
      this.resetPaginator2();
      this.lineService2 = resp;
      this.getOportunitiesBy2();
    });
    this.companyControl2.valueChanges.subscribe(resp => {
      this.resetPaginator2();
      this.company2 = resp;
      this.getOportunitiesBy2();
    });
    this.getOportunitiesBy2();
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la consulta y captura de cambios en los filtros de la tab de perdidas
  */
  tabPerdidas(){
    this.nameControl3.valueChanges.pipe(debounceTime(1000)).subscribe(res => {
      this.resetPaginator3();
      this.nameOportunity3 = res;
      this.getOportunitiesBy3();
    });
    this.comercialControl3.valueChanges.subscribe(resp => {
      this.resetPaginator3();
      this.comercial3 = resp;
      this.getOportunitiesBy3();
    });
    this.lineServiceControl3.valueChanges.subscribe(resp => {
      this.resetPaginator3();
      this.lineService3 = resp;
      this.getOportunitiesBy3();
    });
    this.companyControl3.valueChanges.subscribe(resp => {
      this.resetPaginator3();
      this.company3 = resp;
      this.getOportunitiesBy3();
    });
    this.getOportunitiesBy3();
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la consulta y captura de cambios en los filtros de la tab de desiertas
  */
  tabDesiertas(){
    this.nameControl4.valueChanges.pipe(debounceTime(1000)).subscribe(res => {
      this.resetPaginator4();
      this.nameOportunity4 = res;
      this.getOportunitiesBy4();
    });
    this.comercialControl4.valueChanges.subscribe(resp => {
      this.resetPaginator4();
      this.comercial4 = resp;
      this.getOportunitiesBy4();
    });
    this.lineServiceControl4.valueChanges.subscribe(resp => {
      this.resetPaginator4();
      this.lineService4 = resp;
      this.getOportunitiesBy4();
    });
    this.companyControl4.valueChanges.subscribe(resp => {
      this.resetPaginator4();
      this.company4 = resp;
      this.getOportunitiesBy4();
    });
    this.getOportunitiesBy4();
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-31
  * Metodo para apertura de modal de historico de notas
  */ 
  HistoricoNotas(id) {
    const dialogNewRol = this.dialog.open(HistorialnComponent, {
      width: '77%',
      disableClose: true,
      data: {
        type: 'view',
        title: 'Editar Nota',
        rolUser: '',
        oportunity: id,
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {});
  }


  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para regresar a la vista de oportunidades
  */ 
  back(): void {
    this.router.navigate(['/mios/crm-cial/clientes']);
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la eliminación y limpieza de un componente
  */ 
  ngOnDestroy() {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
    if (this.dataSource2) {
      this.dataSource2.disconnect();
    }
    if (this.dataSource3) {
      this.dataSource3.disconnect();
    }
    if (this.dataSource4) {
      this.dataSource4.disconnect();
    }
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo que se encarga del paginador al momento de cambiar de pagina tab nuevas oportunidades
  * @param {any} event evento que trae la pagina a listar
  */
  pageEvent(event: any): void {    
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getOportunitiesBy();
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo que se encarga del paginador al momento de cambiar de pagina tab ganadas
  * @param {any} event evento que trae la pagina a listar
  */
   pageEvent2(event: any): void {    
    this.pageSize2 = event.pageSize;
    this.page2 = event.pageIndex + 1;
    this.getOportunitiesBy2();
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo que se encarga del paginador al momento de cambiar de pagina tab perdidas
  * @param {any} event evento que trae la pagina a listar
  */
   pageEvent3(event: any): void {    
    this.pageSize3 = event.pageSize;
    this.page3 = event.pageIndex + 1;
    this.getOportunitiesBy3();
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo que se encarga del paginador al momento de cambiar de pagina tab desiertas
  * @param {any} event evento que trae la pagina a listar
  */
   pageEvent4(event: any): void {    
    this.pageSize4 = event.pageSize;
    this.page4 = event.pageIndex + 1;
    this.getOportunitiesBy4();
  }

  /**
  * @author Javier Castañeda
  * @purpose resetea el paginador
  * @createdate 2022-10-29
  */
  resetPaginator(): void {
    this.length = 0;
    this.pageSize = 8;
    this.page = 1;
    this.paginator.firstPage();
  }

  /**
  * @author Javier Castañeda
  * @purpose resetea el paginador
  * @createdate 2022-10-29
  */
   resetPaginator2(): void {
    this.length2 = 0;
    this.pageSize2 = 8;
    this.page2 = 1;
    this.paginator2.firstPage();
  }

  /**
  * @author Javier Castañeda
  * @purpose resetea el paginador
  * @createdate 2022-10-29
  */
   resetPaginator3(): void {
    this.length3 = 0;
    this.pageSize3 = 8;
    this.page3 = 1;
    this.paginator3.firstPage();
  }

  /**
  * @author Javier Castañeda
  * @purpose resetea el paginador
  * @createdate 2022-10-29
  */
   resetPaginator4(): void {
    this.length4 = 0;
    this.pageSize4 = 8;
    this.page4 = 1;
    this.paginator4.firstPage();
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la consulta y filtrado de oportunidades de la tab de nuevas oportunidades
  */
  getOportunitiesBy() {
    let filters = {
      tap: 'nuevas',
      step: this.state || '',
      company: this.company || '',
      comercial: this.comercial || '',
      service_line: this.lineService || '',
      name_oportunity: this.nameOportunity || '',
      perPage: this.pageSize || 8,
      page: this.page || 1
    }; 
    this.oportunidadservice.getOportunitiesBy(this.idcliente, filters).subscribe(resp => {
      this.dataSource.data = resp?.data?.data;
      this.length = resp.data.total;
      this.obs = this.dataSource.connect();
    });
  }


  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la consulta y filtrado de oportunidades de la tab de ganadas
  */
  getOportunitiesBy2() {
    let filters = {
      tap: '',
      state: 'ganada',
      company: this.company2 || '',
      comercial: this.comercial2 || '',
      service_line: this.lineService2 || '',
      name_oportunity: this.nameOportunity2 || '',
      perPage: this.pageSize2|| 8,
      page: this.page2 || 1
    };   
    this.oportunidadservice.getOportunitiesBy(this.idcliente, filters).subscribe(resp => {
      this.dataSource2.data = resp?.data?.data;
      this.length2 = resp.data.total;
      this.obs2= this.dataSource2.connect();
    });
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la consulta y filtrado de oportunidades de la tab de perdidas
  */
  getOportunitiesBy3() {
    let filters = {
      tap: '',
      state: 'perdida',
      company: this.company3 || '',
      comercial: this.comercial3 || '',
      service_line: this.lineService3 || '',
      name_oportunity: this.nameOportunity3 || '',
      perPage: this.pageSize3 || 8,
      page: this.page3 || 1
    };   
    this.oportunidadservice.getOportunitiesBy(this.idcliente, filters).subscribe(resp => {
      this.dataSource3.data = resp?.data?.data;
      this.length3 = resp.data.total;
      this.obs3 = this.dataSource3.connect();
    });
  }


  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la consulta y filtrado de oportunidades de la tab de desiertas
  */
  getOportunitiesBy4() {
    let filters = {
      tap: '',
      state: 'desierta',
      company: this.company4 || '',
      comercial: this.comercial4 || '',
      service_line: this.lineService4 || '',
      name_oportunity: this.nameOportunity4 || '',
      perPage: this.pageSize4 || 8,
      page: this.page4 || 1
    };   
    this.oportunidadservice.getOportunitiesBy(this.idcliente, filters).subscribe(resp => {
      this.dataSource4.data = resp?.data?.data;
      this.length4 = resp.data.total;
      this.obs4 = this.dataSource4.connect();
    });
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-01
  * Metodo para mostrar y ocultar campo de observaciones para edición
  */
  EditObservation(){
      
      this.observationField == true ? this.observationField = false : (this.observationField = true, this.observationControl.setValue(this.clientInfo.observations));
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-01
  * Metodo para actualizar la observación del cliente  
  */
  updateObservation(){
    let formData = new FormData();
    formData.append('observations', this.observationControl.value ? this.observationControl.value : '');
    this.clientesService.updateCliente(this.idcliente, formData).subscribe(resp => {
      this.clientesService.searchId(this.idcliente).subscribe(res => {
        this.clientInfo = res.data;
      });
      this.alertsService.alertSuccessWithoutButton('Excelente',`El cliente ${this.clientInfo?.nameClient || ''} se ha guardado con éxito.`);
      this.EditObservation();
    });
  }

    /**
   * @author Nicoll Ramirez
   * @createdate 2022-11-03 
   * Funcion para cambiar la oportunidad a desierta
   * @returns Json
   *
   */
     saveDesierta(element) {
      let formData = new FormData();
      formData.append('step', 'cierre');
      formData.append('state', 'desierta');
  
      this.alertsService.alertWarning(
        '¿Estás seguro?',
      '¿En verdad deseas dejar esta oportunidad como <b>desierta</b>?, recuerda que esta acción será definitiva y no se podrá deshacer.'
        ).then((result) => {
        if (result.isConfirmed) {
          this.clientesService.updateOpportunities(element.id, formData)
            .subscribe((resp: any) => {
              this.getOportunitiesBy();
              this.getOportunitiesBy2();
              this.getOportunitiesBy3();
              this.getOportunitiesBy4();
              this.alertsService.alertSuccessWithoutButton('Excelente', resp.data);
            
            });
        }
      });
    }
  

  /**
  * @author Javier Castañeda
  * @createdate 2022-11-01
  * Metodo para gestionar la oportunidad
  */
  manageOpportunities(element, tab){
    this.router.navigate([`/mios/crm-cial/creop/${this.clientInfo?.idClient}`], { queryParams: { type: 'edit', idOportunity: element.id, tab: tab, table: 0 }});
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-11-01
  * Metodo para ver detalle de la oportunidad
  */
   viewOpportunities(element, tab){
    this.router.navigate([`/mios/crm-cial/creop/${this.clientInfo?.idClient}`], { queryParams: { type: 'view', idOportunity: element.id, tab:tab, table: 0 }});
  }
}
