import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup,  FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { QuestionBase } from 'src/app/core/models/questions-base';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { PermitService } from 'src/app/core/services/utils/permit.service';
import { QuestionControlService } from 'src/app/core/services/utils/question-control.service';
import { FormsRequestService } from 'src/app/modules/crm/services/rest/forms-request.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';


@Component({
  selector: 'dynamic-form-2',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.sass'],

})
export class DynamicFormComponent implements OnInit {
	@Output() submitForm = new EventEmitter<any>();
	@Output() denyForm = new EventEmitter<any>();
	@Output() enableTemplate = new EventEmitter<any>();
	_sections: any;
	forms = new FormArray([]);
  constructor(
    private qcs: QuestionControlService,
		private authService: AuthService,
		private permit: PermitService,
		private router: Router,
		private formsRequestService: FormsRequestService,
		public alertsService: AlertsService,
		private destroyService: DestroyComponentService
              ) {}
  @Input() dependencies;
  @Input() formId;
  @Input() files;
  Files: any = [];
  @Input()
  public set sections(form: any) {
    this.getRolUser(form.app);
    this._sections = form.sections;
    if (this._sections) {
      this._sections.forEach((section) => {
        this.checkSeeRol(section);
        const group = this.qcs.toFormGroup(section.fields);
        this.forms.push(group);
      });
    }
  }

  @Input() public set clearControl(clear: any) {
    if (clear === true) {
      this.forms.removeAt(1);
    }
  }

  rol: any = [];
	fieldsPreloaded: any[] = [];
	questionPush: any;
	evento: any;
	plantilla: any = '';

ngOnInit(): void {
  this.plantilla = this.router.url.substring(6).split('/', 3)[2];
}

getRolUser(app) {
  let user = this.authService.getUser();
  user.roles.forEach((rol) => {
    let name = rol.split('::');
    if (name[0] === app) {
      this.rol.push(name[1]);
    }
  });
}
/**
 * @author Karol García
 * @createdate 2021-03-29
 * Metodo que verifica si tiene el permiso
 */
checkSeeRol(section) {
  section['see'] = false;
  section.fields.forEach((question) => {
    const seePermit = this.permit.permit(this.rol, question.seeRoles);
    const editPermit = this.permit.permit(this.rol, question.editRoles);
    question.disabled = !editPermit;
    question['see'] = seePermit;
    question['seeSons'] = true;
    if (question.preloaded == true) {
      this.fieldsPreloaded.push(question);
    }
    if (question.isSon == true) {
      question.disabled = true;

      if (question.value !== '') {
        question.seeSons = true;
        question.disabled = false; /*
        question.disable = false; */
      } else {
        question.seeSons = false;
      }
    }
    if (question['see'] == true && question['seeSons'] == true) {
      section['see'] = true;
    }
  });
}
/**
 * @author Jair Celis
 * @createdate 2021-08-30
 * Metodo que añade las dependecias, dependiendo de la opciones del padre
 */
addDependencies(event: any) {
  const mySons = this.findSons(event.idFather);

  if (mySons.length != 0) {
    mySons.forEach((son) => {
      if (this.forms.controls[son.sectionIndex].get(son.key).value != '') {
        this.forms.controls[son.sectionIndex].get(son.key).setValue('');
        this.forms.controls[son.sectionIndex].get(son.key).disable();
        this.disableField(son.id);
      }
    });
  }

  this._sections.forEach((section, index) => {
    section.fields.forEach((field) => {
      if (field.isSon == true) {
        field.dependencies.forEach((dependencie) => {
          if (dependencie.idField == event.idFather) {
            dependencie.activators.forEach((activator) => {
              if (activator.id == event.idValue) {
                this.forms.controls[index].get(field.key).setValue('');
                field.seeSons = true;
                this.forms.controls[index].get(field.key).enable();
                field.options = [];
                if (dependencie.options !== '') {
                  dependencie.options.forEach((option) => {
                    field.options.push(option);
                  });
                }
              }
            });
          }
        });
      }
    });
  });
}
disableField(idField) {
  this._sections.forEach((section, index) => {
    section.fields.forEach((field) => {
      if (field.id == idField) {
        field.seeSons = false;
      }
    });
  });
}

/**
 * @author Jair Celis
 * @createdate 2021-08-30
 * Metodo que devuelve un arreglo de campos hijos
 * @param idField id del campo a buscar los hijos
 */

findSons(idField) {
  const mySons = [];
  this._sections.forEach((section, index) => {
    section.fields.forEach((field) => {
      if (field.isSon == true) {
        field.dependencies.forEach((dependencie) => {
          if (dependencie.idField == idField) {
            field.sectionIndex = index;
            mySons.push(field);
          }
        });
      }
    });
  });
  return mySons;
}
/**
 * @author Karol García
 * @createdate 2021-02-25
 * Metodo que añade otro campo
 */
addQuestion(event, nameSection) {
  this.evento = Object.assign({}, event);
  this.questionPush = this.evento;
  let cont = 0;
  let indexAuxField = 0;
  let indexAuxSec = 0;

  this._sections.forEach((section, indexSec) => {
    section.fields.forEach((question, indexField) => {
      if (question.key.includes(this.evento.key) && nameSection === section.name_section) {
        cont++;
        indexAuxField = indexField;
        indexAuxSec = indexSec;
      }
    });
  });

  cont = cont + 1;

  this.questionPush.id = new Date().getTime();
  this.questionPush.key = this.evento.key + '_' + cont;
  this.questionPush.value = '';
  this.questionPush.label = this.evento.label + ' ' + cont;
  this.questionPush.canAdd = false;
  this.questionPush.duplicated = {
    idOriginal: event.id,
    type: 'field'
  };

  if (this._sections[indexAuxSec].name_section === nameSection) {
    this._sections[indexAuxSec].fields.splice(indexAuxField + 1, 0, this.questionPush);
  }

  let formAux = this.forms.controls[indexAuxSec] as FormGroup;

  let controlAux = this.qcs.toFormControl(this.questionPush);

  formAux.addControl(this.questionPush.key, controlAux);
}

addSection(event) {
  let evento = Object.assign({}, event);
  let section = evento;
  let cont = 0;
  this._sections.forEach((element) => {
    if (element.name_section.includes(section.name_section)) {
      cont++;
    }
  });
  cont = cont + 1;
  let sendSection = new FormData();
  sendSection.append('section', JSON.stringify(section));
  sendSection.append('cont', cont.toString());
  this.formsRequestService.addSection(sendSection).subscribe((resp) => {
    this._sections.push(resp);
    const group = this.qcs.toFormGroup(resp.fields);
    this.forms.push(group);
  });
}
onSubmit() {
  let response = {
    sections: this._sections,
    answer: this.forms.getRawValue(),
    files: this.Files,
    forms: this.forms
  };

  this.submitForm.emit(response);
  this.fieldsPreloaded.forEach((element) => {
    this.forms.controls.forEach((sections) => {
      if (sections.get(element.key) != null) {
        sections.get(element.key).setValue(element.value);
      }
    });
  });
}

/**
 * @author Karol García
 * @createdate 2021-03-30
 * Metodo que cancela el formulario
 */
cancelForm() {
  let saveconfirm = this.alertsService.alertConfirm('¿Desea cancelar?');
  saveconfirm.then((res) => {
    if (res.isConfirmed) {
      this.denyForm.emit(true);
    }
  });
}
/**
 * @author Daniel Dominguez
 * @createdate 2021-04-06
 * Metodo recibe los archivos cargados en los campos tipo file
 */
addFiles(e) {
  this.Files.push(e);
}

ngOnDestroy(): void {
  this.destroyService.destroyComponent();
}

// Boton para generar las plantillas
generateTemplate() {
  let responseTemplate = {
    sections: this._sections,
    answer: this.forms.getRawValue(),
    files: this.Files,
    forms: this.forms
  };

  this.enableTemplate.emit(responseTemplate);
}

}
