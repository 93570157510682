import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SchedulesModule } from '../modules/schedules/schedules.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../modules/ciu/ciu.module').then((m) => m.CiuModule),
  },
  {
    path: 'bpms',
    loadChildren: () =>
      import('../modules/bpms/bpms.module').then((m) => m.BpmsModule),
  },
  {
    path: 'ciu',
    loadChildren: () =>
      import('../modules/ciu/ciu.module').then((m) => m.CiuModule),
  },
  {
    path: 'compras',
    loadChildren: () =>
      import('../modules/compras/compras.module').then((m) => m.ComprasModule),
  },
  {
    path: 'crm',
    loadChildren: () =>
      import('../modules/crm/crm.module').then((m) => m.CrmModule),
  },
  {
    path: 'crm-axa-colpatria',
    loadChildren: () =>
      import('../modules/crm-axa-colpatria/crm-axa-colpatria.module').then(
        (m) => m.CrmAxaColpatriaModule
      ),
  },
  {
    path: 'nomina',
    loadChildren: () =>
      import('../modules/nomina/nomina.module').then((m) => m.NominaModule),
  },
  {
    path: 'rrhh',
    loadChildren: () =>
      import('../modules/rrhh/rrhh.module').then((m) => m.RrhhModule),
  },
  {
    path: 'cartera',
    loadChildren: () =>
      import('../modules/cartera/cartera.module').then((m) => m.CarteraModule),
  },
  {
    path: 'cartera-wom',
    loadChildren: () =>
      import('../modules/cartera-wom/cartera-wom.module').then(
        (m) => m.CarteraWomModule
      ),
  },
  {
    path: 'cartera-air-e',
    loadChildren: () =>
      import('../modules/cartera-air-e/cartera-air-e.module').then(
        (m) => m.CarteraAireModule
      ),
  },
  {
    path: 'cartera-aaa',
    loadChildren: () =>
      import('../modules/cartera-aaa/cartera-aaa.module').then(
        (m) => m.CarteraAaaModule
      ),
  },
  {
    path: 'livechat',
    loadChildren: () =>
      import('../modules/livechat/livechat.module').then(
        (m) => m.LivechatModule
      ),
  },
  {
    path: 'juridica',
    loadChildren: () =>
      import('../modules/juridica/juridica.module').then(
        (m) => m.JuridicaModule
      ),
  },
  {
    path: 'portal',
    loadChildren: () =>
      import('../modules/portal/portal.module').then((m) => m.PortalModule),
  },
  {
    path: 'inventarios',
    loadChildren: () =>
      import('../modules/inventarios/inventarios.module').then(
        (m) => m.InventariosModule
      ),
  },
  {
    path: 'calidad',
    loadChildren: () =>
      import('../modules/calidad/calidad.module').then((m) => m.CalidadModule),
  },
  {
    path: 'coaching',
    loadChildren: () =>
      import('../modules/coaching/coaching.module').then(
        (m) => m.CoachingModule
      ),
  },
  {
    path: 'reservas',
    loadChildren: () =>
      import('../modules/reservas/reservas.module').then(
        (m) => m.ReservasModule
      ),
  },
  {
    path: 'agendamientos',
    loadChildren: () =>
      import('../modules/schedules/schedules.module').then(
        (m) => m.SchedulesModule
      ),
  },
  {
    path: 'modular_ds',
    loadChildren: () =>
      import('../modules/modular-ds/modular-ds.module').then(
        (m) => m.ModularDsModule
      ),
  },
  {
    path: 'voicebot',
    loadChildren: () =>
      import('../modules/voicebot/voicebot.module').then(
        (m) => m.VoicebotModule
      ),
  },
  {
    path: 'soul_bi',
    loadChildren: () =>
      import('../modules/soul_bi/soul_bi.module').then((m) => m.SoulBiModule),
  },
  {
    path: 'reportes',
    loadChildren: () =>
      import('../modules/reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'ojt',
    loadChildren: () =>
      import('../modules/ojt/ojt.module').then((m) => m.OjtModule),
  },
  {
    path: 'sable',
    loadChildren: () =>
      import('../modules/sable/sable.module').then((m) => m.SableModule),
  },
  {
    path: 'auditoria',
    loadChildren: () =>
      import('../modules/auditoria/auditoria.module').then(
        (m) => m.AuditoriaModule
      ),
  },
  {
    path: 'estandares',
    loadChildren: () =>
      import('../modules/standards/standards.module').then(
        (m) => m.StandardsModule
      ),
  },
  {
    path: 'crm-cial',
    loadChildren: () =>
      import('../modules/crm-cial/crm-cial.module').then(
        (m) => m.CrmCialModule
      ),
  },
  {
    path: 'rpa',
    loadChildren: () =>
      import('../modules/gestion-rpa/gestion-rpa.module').then(
        (m) => m.GestionRpaModule
      ),
  },
  {
    path: 'crm2',
    loadChildren: () =>
      import('../modules/crm_v2/crm.module').then((m) => m.CrmModule2),
  },
  {
    path: 'gestionador-correos',
    loadChildren: () =>
      import('../modules/email-manager/email-manager.module').then(
        (m) => m.EmailManagerModule
      ),
  },
  {
    path: 'pqrs_soul',
    loadChildren: () =>
      import('../modules/pqrs-soul/pqrs-soul.module').then(
        (m) => m.PqrsSoulModule
      ),
  },
  {
    path: 'encuestas',
    loadChildren: () =>
      import('../modules/encuestas/encuestas.module').then(
        (m) => m.EncuestasModule
      ),
  },
  {
    path: 'cartera-popular-2023',
    loadChildren: () =>
      import('../modules/cartera/cartera.module').then((m) => m.CarteraModule),
  },
  {
    path: 'cartera-falabella-2020',
    loadChildren: () =>
      import('../modules/cartera/cartera.module').then((m) => m.CarteraModule),
  },
  {
    path: 'cartera-falabella-2021',
    loadChildren: () =>
      import('../modules/cartera/cartera.module').then((m) => m.CarteraModule),
  },
  {
    path: 'cartera-serfinanza-2023',
    loadChildren: () =>
      import('../modules/cartera/cartera.module').then((m) => m.CarteraModule),
  },
  {
    path: 'cartera-propia-tuya-comercial-agosto-2022',
    loadChildren: () =>
      import('../modules/cartera/cartera.module').then((m) => m.CarteraModule),
  },
  {
    path: 'cartera-propia-tuya-comercial-junio-2022',
    loadChildren: () =>
      import('../modules/cartera/cartera.module').then((m) => m.CarteraModule),
  },
  {
    path: 'cartera-serfinanza-bogota',
    loadChildren: () =>
      import('../modules/cartera/cartera.module').then((m) => m.CarteraModule),
  },
  {
    path: 'cartera-propia-tuya-comercial-2021',
    loadChildren: () =>
      import('../modules/cartera/cartera.module').then((m) => m.CarteraModule),
  },
  {
    path: 'cartera-propia-tuya-judicializada-2022',
    loadChildren: () =>
      import('../modules/cartera/cartera.module').then((m) => m.CarteraModule),
  },
  {
    path: 'cartera-propia-banco-popular-2021',
    loadChildren: () =>
      import('../modules/cartera/cartera.module').then((m) => m.CarteraModule),
  },
  {
    path: 'cartera-propia-banco-popular-2020',
    loadChildren: () =>
      import('../modules/cartera/cartera.module').then((m) => m.CarteraModule),
  },
  {
    path: 'cartera-propia-tuya-judicializada-2021',
    loadChildren: () =>
      import('../modules/cartera/cartera.module').then((m) => m.CarteraModule),
  },
  {
    path: 'cartera-natura',
    loadChildren: () =>
      import('../modules/cartera-natura/cartera-natura.module').then(
        (m) => m.CarteraNaturaModule
      ),
  },
  {
    path: 'cartera-entre-amigos',
    loadChildren: () =>
      import(
        '../modules/cartera-entre-amigos/cartera-entre-amigos.module'
      ).then((m) => m.CarteraEntreAmigosModule),
  },
  {
    path: 'crm_nsdp',
    loadChildren: () =>
      import('../modules/crm_NSDP/crm.module').then((m) => m.CrmModuleNSDP),
  },
  {
    path: 'cartera-movistar',
    loadChildren: () =>
      import('../modules/cartera-movistar/cartera-movistar.module').then(
        (m) => m.CarteraMovistarModule
      ),
  },
  {
    path: 'cartera-directv',
    loadChildren: () =>
      import('../modules/cartera-directv/cartera-directv.module').then(
        (m) => m.CarteraDirectvModule
      ),
  },
  {
    path: 'tuya_demo',
    loadChildren: () =>
      import('../modules/cartera/cartera.module').then((m) => m.CarteraModule),
  },
  {
    path: 'cartera-movistar-premium',
    loadChildren: () =>
      import(
        '../modules/cartera-movistar-premium/cartera-movistar-premium.module'
      ).then((m) => m.CarteraMovistarPremiumModule),
  },
  {
    path: 'cartera-propia-banco-falabella-2024',
    loadChildren: () =>
      import('../modules/cartera/cartera.module').then((m) => m.CarteraModule),
  },
  {
    path: 'speech-analytics',
    loadChildren: () =>
      import('../modules/speech-analytics/speech-analytics.module').then(
        (m) => m.SpeechAnalyticsModule
      ),
  },
  {
    path: 'banco-bogota-2024',
    loadChildren: () =>
      import('../modules/cartera/cartera.module').then((m) => m.CarteraModule),
  },
  {
    path: 'site-mapping',
    loadChildren: () =>
      import('../modules/site-mapping/site-mapping.module').then(
        (m) => m.SiteMappingModule
      ),

  },
  {
    path: 'cartera-serfinanza-2024',
    loadChildren: () =>
      import('../modules/cartera/cartera.module').then((m) => m.CarteraModule),
  },
  {
    path: 'cartera-directv-cobranza',
    loadChildren: () =>
      import('../modules/cartera-directv-cobranza/cartera-directv-cobranza.module').then(
        (m) => m.CarteraDirectvCobranzaModule
      ),
  },
  {
    path: 'cartera-fidupopular',
    loadChildren: () =>
      import('../modules/cartera-fidupopular/cartera-fidupopular.module').then(
        (m) => m.CarteraFidupopularModule
      ),
  },
  {
    path: 'allinone-chat', 
    loadChildren: () => 
      import('../modules/allinone-chat/allinone-chat.module').then(
        (m) => m.AllinoneChatModule
      )
    },
  {
    path: 'banco-nu', 
    loadChildren: () => 
      import('../modules/cartera/cartera.module').then(
        (m) => m.CarteraModule
      )
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MiosRoutingModule {}
