import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JuridicaService } from 'src/app/modules/juridica/services/juridica.service';
import { DisabilitiesService } from 'src/app/modules/nomina/services/rest/disabilities.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-permit-view',
  templateUrl: './permit-view.component.html',
  styleUrls: ['./permit-view.component.sass']
})
export class PermitViewComponent implements OnInit {
  dataInfo: any;
  public realTime: string;
  public scheduleTime: string;
  public endTime: string;
  public noveltyDetailHeight: number;

  public showJustifyForm: boolean;
  public showJustifyInfo: boolean;
  public showInput: boolean;
  public titlePermit = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private alert: AlertsService,
              public dialog: MatDialog,
              private permit: JuridicaService,
              private disabilities: DisabilitiesService
  ) {
  }

  ngOnInit() {
    this.permit.getInfoByPermitId(this.data.data.idNovelty).subscribe(resp => {
      this.dataInfo = resp.data;
      if (this.dataInfo.validForMoreResponses) {
        this.showJustifyForm = true;
      }
      this.showJustifyInfo = true;
      this.changeDetailHeight(this.dataInfo);

    });
    this.titlePermit = this.data?.data?.novelty_type?.name ?? "Permiso";
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-06-23
   * Metodo para asignacion de detalle en primera instancia
   */
  changeDetailHeight(novelty): void {
    if (novelty.responseFirstInstance && (novelty.responseFirstInstance.justify || novelty.responseFirstInstance.response)) {
      this.noveltyDetailHeight = 240;
    }
  }

}
