import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';

const CRM_URL = environment.CRM2_URL;
const MODULAR_URL = environment.MODULAR_URL;

@Injectable({
  providedIn: 'root'
})
export class BDService {

  private headers: HttpHeaders;
  constructor(private http: HttpClient, private AuthService: AuthService) { }



  /**
   * @desc
   * @param formData
   * @returns
   * @author
   */


  public extractColumnsNames(formData) {
    return this.http.post<any>(`${CRM_URL}form/upload/extractColumnsNames`, formData);
    // return this.http.post<any>(`${MODULAR_URL}crm_loads/extractColumnsNames`, formData);
  }

  public excelClients(formData) {
    return this.http.post<any>(`${CRM_URL}form/upload/excelClients`, formData);
    // return this.http.post<any>(`${MODULAR_URL}crm_loads/loadExcel`, formData);
  }

  public downloadUploadManangment(formData) {
    const httpOptions = {responseType: 'blob' as 'json'};
    return this.http.post<any>(`${CRM_URL}form/upload/downloadManagement`, formData, httpOptions);
  }

  public getDatabaseClients(id, page, paginate = 50) {
    return this.http.get<any>(`${CRM_URL}managements/database/${id}?page=${page}&paginate=${paginate}`);
  }

  public filterDatabaseClients(id, page, paginate = 50, filter) {
    return this.http.post<any>(`${CRM_URL}managements/database/${id}?page=${page}&paginate=${paginate}`, filter);
  }

  public getClient(id) {
    return this.http.get<any>(`${CRM_URL}managements/outbound/client/show/${id}`);
  }

  public validateExcelData(formData) {
    return this.http.post<any>(`${CRM_URL}form/upload/validateExcelData`, formData);
  }
}
