import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { SPEECH_URL } = environment;

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  constructor(private httpClient: HttpClient) { }

  /**
   * Metodo que retorna los errores generados por HTTP. 
   * @author Yeison Sepulveda
   * @createdate 2024-06-14
   * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * Metodo ue obtiene la lista de campañas disponibles haciendo una solicitud HTTP GET. 
   * @author Yeison Sepulveda
   * @createdate 2024-06-14
  */
  getCampaigns(): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}campaigns/list_campaigns`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Traer la data del historial paginado
   * @author Yeison Sepulveda
   * @createdate 2024-06-14
   * @param filters filtro
   * @param paginator configuración de paginación (pageSize y pageIndex).
  */
  searchDataPaginate(filter: any, paginator: any): Observable<any> {
    const body = { filter, per_page: paginator.pageSize, page: paginator.pageIndex };
    return this.httpClient.post<any>(`${SPEECH_URL}paginate_analytics`, body).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Traer listado de informacion de los analisis sin segemntar paginados
   * @author Yeison Sepulveda
   * @createdate 2024-06-14
   * @param filters filtro
   * @param paginator configuración de paginación (pageSize y pageIndex).
  */
  localSearchDataPaginate(filter: any, paginator: any): Observable<any> {
    const body = { filter, per_page: paginator.pageSize, page: paginator.pageIndex };
    return this.httpClient.post<any>(`${SPEECH_URL}paginate_analytics_local `, body).pipe(
      catchError(this.handleError)
    );
  }

}