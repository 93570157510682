<div class="mat-elevation-z0 mt-2">
  <table [dataSource]="dataSource" mat-table matSort>

    <!-- Columna con botónes de acciones -->
    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="detailRetroAlimentacion(element.matriz.id)">
          <i class="fi-rr-eye"></i>
        </button>
      </td>
    </ng-container>

    <!-- Columna ID -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>{{config.labels.id}}</th>
      <td mat-cell *matCellDef="let element"> {{ element.matriz.id}} </td>
    </ng-container>

    <!-- Columna tipo de auditoriía -->
    <ng-container matColumnDef="tipo_auditoria">
      <th mat-header-cell *matHeaderCellDef>{{config.labels.tipoAuditoria}}</th>
      <td mat-cell *matCellDef="let element"> {{ element.matriz.tipo_audioria.nombre }} </td>
    </ng-container>

    <!-- Columna fecha auditoría -->
    <ng-container matColumnDef="fecha_auditoria">
      <th mat-header-cell *matHeaderCellDef>{{config.labels.fechaAuditoria}}</th>
      <td mat-cell *matCellDef="let element"> {{ element.matriz.created_at | date:'dd/MM/yyyy' }} </td>
    </ng-container>

    <!-- Columna Campaña -->
    <ng-container matColumnDef="campaña">
      <th mat-header-cell *matHeaderCellDef>{{config.labels.campania}}</th>
      <td mat-cell *matCellDef="let element"> {{ campaign ? campania: config.labels.noDataToShow }} </td>
    </ng-container>

    <!-- Columna cedula auditado -->
    <ng-container matColumnDef="cedula_auditado">
      <th mat-header-cell *matHeaderCellDef>{{config.labels.cedulaAuditado}}</th>
      <td mat-cell *matCellDef="let element"> {{ element.matriz.cedula_asesor }} </td>
    </ng-container>

    <!-- Columna nombre auditado -->
    <ng-container matColumnDef="nombre_auditado">
      <th mat-header-cell *matHeaderCellDef>{{config.labels.nombreAuditado}}</th>
      <td mat-cell *matCellDef="let element"> {{ element.matriz.nombre_asesor }} </td>
    </ng-container>

    <!-- Columna UCID -->
    <ng-container matColumnDef="ucid">
      <th mat-header-cell *matHeaderCellDef>{{config.labels.ucid}}</th>
      <td mat-cell *matCellDef="let element"> {{ element.matriz.ucid }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">{{config.labels.noDataToShow}}</td>
    </tr>
  </table>

  <mat-paginator [length]="length"
                 [pageSize]="pageSize"
                 (page)="pageEvent($event)"
                 [pageSizeOptions]="pageSizeOptions">
  </mat-paginator>
</div>
