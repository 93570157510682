import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from '../../../../environments/environment';

const url = environment.BPMS_URL;

@Injectable({
  providedIn: 'root'
})

export class RequestsService {

  usuario;

  constructor(private http: HttpClient,
    private authService: AuthService) {
    this.usuario = this.authService.getUser();
   }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  consultarTipoFormulario(tipo, cargo): Observable<any> {
    return this.http.get(`${url}typeRequest/${tipo}/?cargo=${cargo}&userId=${this.usuario.rrhh_id}`, this.httpOptions)
                    .pipe(retry(2), catchError(this.handleError));
  }

  consultarSeguimientoSolicitud(id): Observable<any> {
    return this.http.get(`${url}request/details/${id}?userId=${this.usuario.rrhh_id}`, this.httpOptions)
                    .pipe(retry(2), catchError(this.handleError));
  }

  guardarFormulario(formulario: any): Observable<any> {
    return this.http.post<any>(`${url}request?userId=${this.usuario.rrhh_id}`, formulario, this.httpOptions)
    .pipe(
      catchError(this.handleError)
      );
  }

  consultarTipoSolicitud(): Observable<any> {
    return this.http.get(`${url}typerequests?userId=${this.usuario.rrhh_id}`, this.httpOptions)
                    .pipe(retry(2), catchError(this.handleError));
  }

  consultarCampaings(currentPage, stateId?, name?): Observable<any> {
    return this.http.get(`${url}requests/campaings?page=${currentPage}&stateId=${stateId}&userId=${this.usuario.rrhh_id}&name=${name}`, this.httpOptions)
                    .pipe(retry(2), catchError(this.handleError));
  }

  getRequestsCampaign(id, page, stateId?, name?): Observable<any> {
    return this.http.get(`${url}requests/campaing/${id}?page=${page}&stateId=${stateId}&userId=${this.usuario.rrhh_id}&name=${name}`, this.httpOptions)
                    .pipe(retry(2), catchError(this.handleError));
  }

  consultarSolicitud(): Observable<any> {
    return this.http.get(`${url}Solicitud?userId=${this.usuario.rrhh_id}`, this.httpOptions)
                    .pipe(retry(2), catchError(this.handleError));
  }

  consultarSolicitudEditar(id) {
    return this.http.get(`${url}request/${id}?userId=${this.usuario.rrhh_id}`, this.httpOptions)
                    .pipe(retry(2), catchError(this.handleError));
  }

  getAllCampaigns(){
    return this.http.get(`${url}campaings?userId=${this.usuario.rrhh_id}`, this.httpOptions)
                    .pipe(retry(2), catchError(this.handleError));
  }

  editarSolicitud(id, formulario) {
    return this.http.put<any>(`${url}request/${id}?userId=${this.usuario.rrhh_id}`, formulario, this.httpOptions)
    .pipe(
      catchError(this.handleError)
      );
  }

  cancelarSolicitud(id, observacion) {
    return this.http.put<any>(`${url}request/cancel/${id}?userId=${this.usuario.rrhh_id}`, observacion, this.httpOptions)
    .pipe(
      catchError(this.handleError)
      );
  }

  consultarKPIS() {
    return this.http.get(`${url}kpis?userId=${this.usuario.rrhh_id}`, this.httpOptions)
                    .pipe(retry(2), catchError(this.handleError));
  }

  appovalRequest(requestId: number, params: any) {
    return this.http.post(`${url}approval/${requestId}?userId=${this.usuario.rrhh_id}`, params, this.httpOptions)
          .pipe( retry(2),catchError(this.handleError));
  }

  asignarReclutador(requestId: number, params: number) {
    return this.http.post(`${url}approval/${requestId}?userId=${this.usuario.rrhh_id}`, params, this.httpOptions)
          .pipe( retry(2), catchError(this.handleError));
  }
  getRequestStates(){
    return this.http.get(`${url}states/requests`);
  }
  setCommenty(entries){
    entries['userId'] = this.usuario.rrhh_id;
    return this.http.post(`${url}comentary/candidate`, entries, this.httpOptions)
    .pipe( retry(2), catchError(this.handleError));
  }
}
