import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CampaingsService } from './../../../../../crm/services/rest/campaings.service';
import { GroupsService } from 'src/app/modules/crm/services/rest/groups.service';
import { ActivatedRoute } from '@angular/router';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'app-admin-groups',
  templateUrl: './admin-groups.component.html',
  styleUrls: ['./admin-groups.component.sass']
})
export class AdminGroupsComponent implements OnInit {

  groupForm: FormGroup;
  filteredOptions: Observable<any[]>;
  campaigns: any;
  userSinGroup: any[] = [];
  userSinGroupNuevo: any[] = [];
  userGroup: any[] = [];
  userSelected: any[] = [];
  usersId: any[];
  campaingId: string;
  @ViewChild('shoes', {static: true}) private allSelected: MatSelectionList;
  @ViewChild('shoes2', {static: false}) private allSelected2: MatSelectionList;

  constructor(
    private campaing: CampaingsService,
    public group: GroupsService,
    private route: ActivatedRoute,
    private alertsService: AlertsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.formControl();
    this.campaingId = this.route.snapshot.queryParamMap.get('id');
    this.group.getUsers(this.campaingId).subscribe(res => {
      this.userSinGroup = res;
      this.setEdit();
    });
  }

  get error(): any { return this.groupForm.controls; }
  /**
   * @author Daniel Dominguez 
   * @createdate 2021-03-04
   * Form control de grupos
   */
  formControl(): void {

    this.groupForm = new FormGroup({
      group: new FormControl('', [Validators.required, Validators.maxLength(45), Validators.minLength(3)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(45), Validators.minLength(3)])
    });

  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.campaigns.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  /**
   * @author Daniel Dominguez 
   * @createdate 2021-03-04
   * Metodo mostrar nombre de la campaña
   */
  displayFn(id: number): string {
    if (!id) { return ''; }
    let index = this.campaigns.findIndex(resp => resp.id === id);
    return this.campaigns[index].name;

  }
  /**
   * @author Daniel Dominguez 
   * @createdate 2021-03-03
   * Metodo Cargar datos modal de editar
   */
  setEdit(): void {
    if (this.data.type === 'editar') {
      this.groupForm.controls.group.setValue(this.data.group.name_group);
      this.groupForm.controls.description.setValue(this.data.group.description);
      this.group.getGroupById(this.data.group.id).subscribe(res => {
        let dataGroup = res.members;
        this.userGroup = dataGroup;
        this.userSinGroup = res.available;

      });

    }
  }
  /**
   * @author Daniel Dominguez 
   * @createdate 2021-03-03
   * COntrol de individual de checkbox de usuarios sin grupo
   */
  lstInicial = new FormGroup({
    selected: new FormControl([])
  });
  /**
   * @author Daniel Dominguez 
   * @createdate 2021-03-03
   * COntrol de individual de checkbox de usuarios en el grupo
   */
  lstfinal = new FormGroup({
    selected: new FormControl([])
  });
  /**
   * @author Daniel Dominguez 
   * @createdate 2021-03-05
   * COntrol de checkbox de todos los usarios sin grupo
   */
  checkAll1 = new FormGroup({
    selected: new FormControl(false,[])
  });
  /**
   * @author Daniel Dominguez 
   * @createdate 2021-03-05
   * COntrol de checkbox de todos los usarios en el grupo
   */
  checkAll2 = new FormGroup({
    selected: new FormControl(false,[])
  });
  /**
   * @author Daniel Dominguez 
   * @createdate 2021-03-03
   * Metodo carga datos modal de crear grupo
   */
  saveGroup(groupForm): void {
    if (this.data.type === 'crear') {
      let users = [];
      this.userGroup.map(function (user) {
        let id = { 'idRrhh': user.id_rhh };
        users = [...users, id];
      });
      const group = {
        name_group: groupForm.value.group,
        description: groupForm.value.description,
        state: 1,
        users: users
      };
      this.group.createGroup(this.campaingId, group).subscribe(res => {
        this.alertsService.alertSuccess('Guardado', res.data);
      });

    }
    else if (this.data.type === 'editar') {
      let users = [];

      this.userGroup.map(function (user) {
        let id = { 'idRrhh': user.id_rhh };
        users = [...users, id ];
      });
      this.editGroup(groupForm, users);
    }

  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-03-04
   * Metodo editar grupo
   */
  editGroup(groupForm, users): void {

    const group = {
      name_group: groupForm.value.group,
      description: groupForm.value.description,
      state: this.data.group.state,
      users: users
    };

    this.group.updateGroup(this.data.group.id, group).subscribe(res => {
      this.alertsService.alertSuccess('Guardado', res.data);
    });
  }
  /**
   * @author Daniel Dominguez 
   * @createdate 2021-03-05
   * Metodo selecionar o deseleccionar todos los usuarios 
   */
  onChangeCheck(event){
    if (event.checked == true) {
      this.allSelected.selectAll();
    }else{
      this.allSelected.deselectAll();
    }
  }

  onChangeCheck2(event){
    if (event.checked == true) {
      this.allSelected2.selectAll();
    }else{
      this.allSelected2.deselectAll();
    }
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-03-04
   * Metodo asignación de usarios al grupo
   */
  asingarGrupo() {
    this.userSelected = this.lstInicial.value.selected;
    this.userSinGroupNuevo = this.userSinGroup;
    this.userSelected.forEach(select => {
      this.userGroup.push(select);
      this.userSinGroupNuevo = this.userSinGroupNuevo.filter((i) => i.id_rhh !== select.id_rhh || i.id !== select.id  )
    });
    
    this.lstInicial.value.selected = [];
    this.checkAll1.controls.selected.setValue(false,[]);
    this.userSinGroup = this.userSinGroupNuevo;
    this.lstInicial.controls.selected.setValue([]);
  }
  /**
   * @author Daniel Dominguez 
   * @createdate 2021-03-04
   * Metodo remover de usarios al grupo
   */
  quitarGrupo() {
    this.userSelected = this.lstfinal.value.selected;
    this.userSinGroupNuevo = this.userGroup;
    this.userSelected.forEach(select => {
      this.userSinGroup.push(select);
      this.userSinGroupNuevo = this.userSinGroupNuevo.filter((i) => i.id_rhh !== select.id_rhh || i.id !== select.id )
    });
    this.lstfinal.value.selected = [];
    this.checkAll2.controls.selected.setValue(false,[]);
    this.userGroup = this.userSinGroupNuevo;
    this.lstfinal.controls.selected.setValue([]);
  }

}