import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CrmCialComponent } from './crm-cial.component';
import { ClienteConsultaComponent } from './modules/cliente-consulta/cliente-consulta.component';
import { ClientesComponent } from './modules/clientes/clientes.component';
import { CreopComponent } from './modules/creop/creop.component';


const routes: Routes = [
  
  {path: '',component:CrmCialComponent},
  {path: 'clientes', component:ClientesComponent},
  {path : 'cliente-consulta/:id', component:ClienteConsultaComponent},
  {path : 'creop/:id', component:CreopComponent}
];
  



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CrmCialRoutingModule { }
