import { Injectable } from '@angular/core';
import { CanActivateChild } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { DocumentSignatureDialogComponent } from 'src/app/auth/document-signature-dialog/document-signature-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DocumentSignatureGuard implements CanActivateChild {
  constructor(
    private matDialog: MatDialog,
    private authService: AuthService
  ) { }

  canActivateChild(): Promise<boolean> {
    const user = this.authService.getUser();
    return new Promise((resolve) => {
      this.authService.verifyUserDescount(user.rrhh.ID_number).subscribe((res) => {
        if (res.state === 1 && !this.isDialogOpen('document-signature-dialog')) {
          this.matDialog.open(DocumentSignatureDialogComponent, {
            width: '70%',
            autoFocus: false,
            height: '60%',
            disableClose: true,
            panelClass: 'dialog-document-signature',
            id: 'document-signature-dialog',
            data: {
              document: user.rrhh.ID_number,
              isRequiredSignature: res.obligatorio
            }
          });
        }
        resolve(true);
      });
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-08-13
    * Metodo que valida si una modal esta o no renderizada en el DOM.
    * @param dialogId Id de la modal a validar.
  */
  private isDialogOpen(dialogId: string): boolean {
    return this.matDialog.getDialogById(dialogId) !== undefined;
  }
}