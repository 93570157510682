import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';


const REPORTS_URL = environment.REPORTS_URL;
const REPORTSS_URL = environment.REPORTSS_URL;


@Injectable({
  providedIn: 'root'
})
export class ReportsReportService {

  constructor(private http: HttpClient) { }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
  * @author Carlos Nieto
  * @createdate 2022-03-04
  * Servicio que lista todos los reportes
  * @param reportKey key de powerbi a visualizar
  */
  getReports(): Observable<any> {
    return this.http.get<any>(`${REPORTS_URL}reports`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
  * @author Carlos Nieto
  * @createdate 2022-03-04
  * Servicio que lista todos los reportes
  * @param reportKey key de powerbi a visualizar
  */
  getReportsByUser(rrhh_id): Observable<any> {
    return this.http.get<any>(`${REPORTS_URL}report/user/${rrhh_id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-01-25
   * Servicio que lista todos los groupes del aplicativo ciu
   * @param reportKey key de powerbi a visualizar
   */
  getReport(reportKey: string): Observable<any> {
    return this.http.get<any>(`${REPORTSS_URL}report/tokens?key=${reportKey}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-03-07
   * Servicio que actualiza o crea nuevos permisos de reortes
   * @param form formulario de data a actualizar
   */
  saveOrUpdateReport(form: string): Observable<any> {
    return this.http.post<any>(`${REPORTS_URL}report/user/save`, form)
      .pipe(
        catchError(this.handleError)
      );
  }
}
