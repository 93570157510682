import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import {DashboardService} from '../../services/dashboard.service'
import { DashboardInterface } from '../../interfaces/DashboardInterface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-show-report',
  templateUrl: './show-report.component.html',
  styleUrls: ['./show-report.component.sass']
})
export class ShowReportComponent implements OnInit {
  reportForm   : FormGroup;
  actual_url    : SafeResourceUrl
  base_url  	  : string = environment.JUPYTER_URL;
  reports      : any[] = ['interactive_dashboard','sample']
  isLoading     : boolean = false

  constructor(private sanitizer : DomSanitizer,
    private authService         : AuthService,
    private fb                  : FormBuilder,
    private location            : Location,
    private dashboardService    : DashboardService) { }

  ngOnInit(): void {
    this.actual_url = this.sanitizer.bypassSecurityTrustResourceUrl('');
    //Consulta los reportes a los que tiene acceso el rol logueado
    const rrhhInfo = this.authService.decryptToken();
    this.dashboardService.getReportByRol(rrhhInfo.rrhh.campaign_id, rrhhInfo.rrhh.position , rrhhInfo.rrhh_id).subscribe( (dash:DashboardInterface[]) => {
      this.reports = dash
    });

    this.reportForm = this.fb.group({
      reportSelect: ['']
    });

    // Suscribirse a los cambios en el select de campañas
    this.reportForm.get('reportSelect').valueChanges.subscribe(value => {
      this.isLoading = true;
      let url = `${this.base_url}/index?notebook=${this.reports[value].key}`
      for (const filter of this.reports[value].filters) {
        url += `&${filter.column}=${filter.value}`
      }
      this.actual_url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }
  backRoute(){
    this.location.back();
  }
  fullScreen(){
    const iframe = document.getElementById('iframe_dashboard');
    if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
    }
  }
  onIframeLoad(){
    if(this.actual_url["changingThisBreaksApplicationSecurity"] != ""){
      this.isLoading = false;
    }
  }
 
}
