import { PermitListComponent } from './pages/permit-list/permit-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PermitConsultantComponent } from './pages/permit-consultant/permit-consultant.component';

const routes: Routes = [
    { path: '', component: PermitListComponent },
    { path: 'consultant', component: PermitConsultantComponent },
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class PermitRoutingModule { }