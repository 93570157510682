import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../core/services/rest/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  private server =  environment.RRHH_URL;
  user;
  constructor(private http:HttpClient, private activeRoute: ActivatedRoute, private authService:AuthService) {
    this.user = this.authService.getUser();
   }

   getTypeComment(candidateId,requestId?){
    const entries = {};
    entries['userId'] = this.user.rrhh_id;
    entries['candidateState']= candidateId
    if(requestId != null)
    {
      entries['requestId']= requestId
    }
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}comments/type`, {params});
  }

  registerComment(entries: any){
    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.post(`${this.server}comment/candidate`, params);
  }
  editComment(commentId,entries:any){
    const params = new HttpParams( { fromObject: entries } );
    return this.http.put(`${this.server}comment/candidate/${commentId}`, params);
  }
}
