<div class="mat-typography" *ngIf="escalation.email_id">
  <mat-expansion-panel  (opened)="panelOpenState = true" (closed)="panelOpenState = false" style="padding: 0px; box-shadow: unset;">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3>
          <span style="margin-top: 5px; font-size: 22px !important;" class="fi-rr-envelope"></span>
          Correo 
        </h3>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="row">
      <div class="col-md-12">
        <div *ngFor="let item of listDataInfo" class="row mb-1">
          <div class="col-md-3 fw-bold">{{ item.name }}</div>
          <div class="col-md-9 text-justify">{{ item.value }}</div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="col-12 row">
      <p class="title"> <b>Archivos adjuntos</b> </p>
      <div class="col-12 row" style="padding-bottom: 32px;">
        <div class="col-4" style="display: flex;" *ngFor="let item of dataInfo[0]?.adjuntos">
          <a #file style="text-overflow: ellipsis; padding-top: 15px;
          overflow: hidden;
           white-space: nowrap;
           text-decoration: underline;
           color: #353535;
           opacity: 1;
           font-weight: 600;
           cursor: pointer;" (click)="downloadEmailAttachment(item.url, item.nombre)">{{item.nombre}} </a>
          <span class="fi-rr-download" style="padding-left: 14px; cursor: pointer;" (click)="file.click()"></span>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="col-12" style="margin-top: 20px; overflow-x: auto;" [innerHTML]="dataInfo[0]?.cuerpo">
    </div>
  </mat-expansion-panel>
</div>