import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const NOMINA_URL = environment.NOMINA_URL;
@Injectable({
  providedIn: 'root'
})
export class PaymentSlipService {

  constructor(private http: HttpClient) { }

  /**
   * @author Juan Carlos Alonso
   * @updateDate 2023-09-08
   * Metodo para consultar el pdf generado en el back del desprendible de nomina
   */
  getPayment(month, year, lastMonth?, liquidations?, selected_user_id?) {
    if(lastMonth){
      return this.http.get(`${NOMINA_URL}nomina/resume/paymentSlip?month=${month}&year=${year}&liquidations=${liquidations ?? ''}&selected_user_id=${selected_user_id ?? ''}`, {responseType: 'blob'})
    }
    return this.http.get(`${NOMINA_URL}nomina/resume/paymentSlip?month=${month}&year=${year}&lastMonth=${!lastMonth}&liquidations=${liquidations ?? ''}&selected_user_id=${selected_user_id ?? ''}`, {responseType: 'blob'})
  }

  /**
   * @author Johan Soler
   * @createDate 2022-06-29
   * Metodo para traer los meses trabajados del empleado
   */
  getWorkersMonth() {
    return this.http.get(`${NOMINA_URL}monthsWorked`)
  }

  /**
   * @author Johan Soler
   * @createDate 2022-06-29
   * Metodo para traer los meses trabajados del empleado
   */
  getPaymentsNominaByUser(year?,rrhh_id?) {
    return this.http.get(`${NOMINA_URL}paymentSlip/getPaymentSlipDataByUser?year=${year ?? ''}&rrhh_id=${rrhh_id}`)
  }
}
