<div class="avatar row  admin-dialog ps-5 pe-5">
  <!-- <form class="row form " [formGroup]="solicitudesForm"  (ngSubmit)="saveSolicitud(solicitudesForm)"> -->
  <div align="end">
    <button style="float: right" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
  </div>


  <div class="row d-flex justify-content-between px-5" >
    <div class="col-9 px-0">
      <h2 mat-dialog-title>
        Orden de compra <span class="text-title-info"><span class="m-0 p-0"
            *ngIf="data.type == 'edit'">OC-{{data.ordenCompra.purchase_order_number}}</span></span>
      </h2>
    </div>
    <div class="col-3 d-flex justify-content-end px-0">
      <button mat-button
        (click)="downloadCotizacion(data.ordenCompra?.request.id == undefined ? data.solicitud.id : data.ordenCompra.request.id)">Cotización
        &nbsp; <i class="fi-rr-download"></i></button>
    </div>

    <mat-divider></mat-divider>
  </div>

  <mat-dialog-content class="mat-typography">
    <mat-horizontal-stepper #stepper (selectionChange)="onStepChange($event)">
      <mat-step [editable]="isEditable" labelPosition="bottom">

        <ng-template matStepLabel>Datos generales</ng-template>
        <div class="row">

          <h3>Datos de la empresa</h3>
          <mat-divider></mat-divider>
          <br>

          <div class="col-4">
            <mat-label style="font-weight: bold;">Empresa</mat-label>
            <p>{{data.solicitud.company.name}}</p>
          </div>
          <div class="col-4">
            <mat-label style="font-weight: bold;">Nit</mat-label>
            <p>{{data.solicitud.company.nit}}</p>
          </div>
        </div>
        <br>
        <h3>Documento controlado</h3>
        <mat-divider></mat-divider>
        <br>

        <div class="row">


          <div class="col-4">
            <mat-label style="font-weight: bold;">Fecha emisión</mat-label>
            <p>{{data.solicitud.created_at | date}}</p>
          </div>
          <div class="col-4">
            <mat-label style="font-weight: bold;">Tipo de adquisición</mat-label>
            <p>{{data.solicitud.product_type.name}}</p>
          </div>
        </div>
        <br>
        <h3>Datos del proveedor</h3>
        <hr>
        <div class="row">

          <div class="col-4">
            <mat-label style="font-weight: bold;">Razón social</mat-label>
            <p>{{data.proveedor.razon_social}}</p>
          </div>
          <div class="col-4">
            <mat-label style="font-weight: bold;">Nit</mat-label>
            <p>{{data.proveedor.nit}}</p>
          </div>
          <div class="col-4">
            <mat-label style="font-weight: bold;">Dirección</mat-label>
            <p>{{data.proveedor.direccion}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <mat-label style="font-weight: bold;">Celular</mat-label>
            <p>{{data.proveedor.user.celular}}</p>
          </div>
          <div class="col-4">
            <mat-label style="font-weight: bold;">Teléfono</mat-label>
            <p>{{data.proveedor.telefono_proveedor}}</p>
          </div>
          <div class="col-4">
            <mat-label style="font-weight: bold;">E-mail</mat-label>
            <p>{{data.proveedor.user.correo}}</p>
          </div>
        </div>
        <br>
        <h3>Datos del solicitante</h3>
        <hr>
        <div class="row">

          <div class="col-4">
            <mat-label style="font-weight: bold;">Nombre del solicitante</mat-label>
            <p>{{data.solicitud.created_by}}</p>
          </div>
        </div>


        <div style="float: right">
          <button class="bold compras-modal-button" mat-flat-button color="primary" type="button"
            matStepperNext>Siguiente</button>
        </div>

      </mat-step>
      <mat-step [stepControl]="firstFormGroup" [editable]="isEditable">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Detalle compra</ng-template>


          <div formArrayName="items" class="row col-12">
            <h3>
              Por los siguientes ítems
            </h3>
            <mat-divider></mat-divider>
            <br>

            <div class="col-12 " *ngFor="let applet of items.controls; let i = index">
              <div>
                <strong>ítem {{i+1}}</strong><button mat-icon-button (click)="deleteItems(i)" *ngIf="i !=0"><i
                    class="fi-rr-trash fs-5"></i></button>
                <div class="row col-12" [formGroupName]="i">
                  <mat-form-field style="padding: 1%;" class="col-12" appearance="fill">
                    <mat-label>Descripción</mat-label>
                    <input style="text-transform: none;" matInput type="text" placeholder="Escribe las especificaciones"
                      formControlName="description" required>
                    <mat-error *ngIf="errorItem.items.controls[i].controls.description.errors"><span
                        *ngIf="errorItem.items.controls[i].controls.description.errors.required">¡Advertencia! Este
                        campo es requerido</span></mat-error>
                    <mat-error *ngIf="errorItem.items.controls[i].controls.description.errors"><span
                        *ngIf="errorItem.items.controls[i].controls.description.errors.minlength">¡Aún falta! Recibimos
                        mínimo 3 caracteres</span></mat-error>
                    <mat-error *ngIf="errorItem.items.controls[i].controls.description.errors"><span
                        *ngIf="errorItem.items.controls[i].controls.description.errors.maxlength">¡Cuidado! Solo puedes
                        tener un máximo de 255 caracteres</span></mat-error>
                  </mat-form-field>

                  <mat-form-field style="padding: 1%;" class="col-12" appearance="fill">
                    <mat-label>Especificación</mat-label>
                    <input style="text-transform: none;" matInput type="text" placeholder="Escribe el detalle"
                      formControlName="specs" required>
                    <mat-error *ngIf="errorItem.items.controls[i].controls.specs.errors"><span
                        *ngIf="errorItem.items.controls[i].controls.specs.errors.required">¡Advertencia! Este campo es
                        requerido</span></mat-error>
                    <mat-error *ngIf="errorItem.items.controls[i].controls.specs.errors"><span
                        *ngIf="errorItem.items.controls[i].controls.specs.errors.minlength">¡Aún falta! Recibimos mínimo
                        3 caracteres</span></mat-error>
                    <mat-error *ngIf="errorItem.items.controls[i].controls.specs.errors"><span
                        *ngIf="errorItem.items.controls[i].controls.specs.errors.maxlength">¡Cuidado! Solo puedes tener
                        un máximo de 255 caracteres</span></mat-error>
                  </mat-form-field>



                  <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
                    <mat-label>Cantidad</mat-label>
                    <input style="text-transform: none;" matInput type="number" placeholder="Escribe la cantidad"
                      min="0" formControlName="unit" (blur)="calcularValorTotal(i)" required>
                    <mat-error *ngIf="errorItem.items.controls[i].controls.unit.errors"><span
                        *ngIf="errorItem.items.controls[i].controls.unit.errors.required">¡Advertencia! Este campo es
                        requerido</span></mat-error>
                    <mat-error *ngIf="errorItem.items.controls[i].controls.unit.errors"><span
                        *ngIf="errorItem.items.controls[i].controls.unit.errors.minlength">¡Aún falta! Recibimos mínimo
                        3 caracteres</span></mat-error>
                    <mat-error *ngIf="errorItem.items.controls[i].controls.unit.errors"><span
                        *ngIf="errorItem.items.controls[i].controls.unit.errors.maxlength">¡Cuidado! Solo puedes tener
                        un máximo de 3 caracteres</span></mat-error>
                  </mat-form-field>
                  <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
                    <mat-label>Tipo de moneda</mat-label>
                    <mat-select formControlName="coin_type" (valueChange)="cambioTipoMoneda($event,i)" required>
                      <mat-option *ngFor="let typeCoin of ['COP','USD','MXN']" [value]="typeCoin"> {{typeCoin}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="errorItem.items.controls[i].controls.coin_type.errors"><span
                        *ngIf="errorItem.items.controls[i].controls.coin_type.errors.required">¡Advertencia! Este campo
                        es requerido</span></mat-error>
                  </mat-form-field>
                  <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
                    <mat-label>Valor unitario</mat-label>
                    <input style="text-transform: none;" matInput currencyMask [options]="options"
                      placeholder="Escribe el valor unitario " min="0" formControlName="unit_value"
                      (blur)="calcularValorTotal(i)" required>
                    <mat-error *ngIf="errorItem.items.controls[i].controls.unit_value.errors"><span
                        *ngIf="errorItem.items.controls[i].controls.unit_value.errors.required">¡Advertencia! Este campo
                        es requerido</span></mat-error>
                    <mat-error *ngIf="errorItem.items.controls[i].controls.unit_value.errors"><span
                        *ngIf="errorItem.items.controls[i].controls.unit_value.errors.minlength">¡Aún falta! Recibimos
                        mínimo 3 caracteres</span></mat-error>
                    <mat-error *ngIf="errorItem.items.controls[i].controls.unit_value.errors"><span
                        *ngIf="errorItem.items.controls[i].controls.unit_value.errors.maxlength">¡Cuidado! Solo puedes
                        tener un máximo de 3 caracteres</span></mat-error>
                  </mat-form-field>
                  <mat-form-field style="padding: 1%;" class="col-4" appearance="fill" *ngIf="!viewSolciitud">
                    <mat-label>IVA</mat-label>
                    <mat-select formControlName="selectIva" (valueChange)="cambioEstado($event,i)" required>
                      <mat-option [value]=0>0%</mat-option>
                      <mat-option [value]=5>5%</mat-option>
                      <mat-option [value]=19>19%</mat-option>
                      <mat-option [value]=30>30%</mat-option>
                      <mat-option [value]=35>35%</mat-option>
                      <mat-option [value]=100>Otro</mat-option>
                    </mat-select>
                    <mat-error *ngIf="errorItem.items.controls[i].controls.selectIva.errors"><span
                        *ngIf="errorItem.items.controls[i].controls.selectIva.errors.required">¡Advertencia! Este campo
                        es requerido</span></mat-error>
                  </mat-form-field>
                  <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
                    <mat-label>Otro IVA</mat-label>
                    <input style="text-transform: none;" matInput type="number" placeholder="Escribe el IVA"
                      formControlName="valorIva" required>
                  </mat-form-field>
                  <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
                    <mat-label>Valor total</mat-label>
                    <input style="text-transform: none;" matInput currencyMask [options]="options"
                      placeholder="Escribe el valor total" formControlName="total_value" required>
                    <mat-error *ngIf="errorItem.items.controls[i].controls.total_value.errors"><span
                        *ngIf="errorItem.items.controls[i].controls.total_value.errors.required">¡Advertencia! Este
                        campo es requerido</span></mat-error>
                    <mat-error *ngIf="errorItem.items.controls[i].controls.total_value.errors"><span
                        *ngIf="errorItem.items.controls[i].controls.total_value.errors.minlength">¡Aún falta! Recibimos
                        mínimo 3 caracteres</span></mat-error>
                    <mat-error *ngIf="errorItem.items.controls[i].controls.total_value.errors"><span
                        *ngIf="errorItem.items.controls[i].controls.total_value.errors.maxlength">¡Cuidado! Solo puedes
                        tener un máximo de 3 caracteres</span></mat-error>
                  </mat-form-field>
                  <input type="hidden" formControlName="id">
                  <!-- <div class="col-2 text-center d-flex align-items-center justify-content-center">
                      <button mat-icon-button (click)="deleteItems(i)" *ngIf="i !=0"><i class="fi-rr-trash fs-3"></i></button>
                    </div> -->
                  <div class="row" *ngFor="let item of filesUpload; let j = index;">
                    <div style="vertical-align: bottom;" class="col-3 ">
                      <p>{{item.name}}</p>
                    </div>
                    <div class="col-3 ">
                      <button mat-icon-button class="action " (click)="EliminarArchivoLista(j) ">
                        <i class="fi-rr-trash" style="position:initial"></i>
                      </button>
                    </div>
                  </div>
                </div>


              </div>
              <br />
              <br />
            </div>

            <div class="col-12" style="padding-right: 2rem !important;">
              <button type="button" class="addButton px-0" mat-button (click)="newItems()">
                <p style="margin-top: 6px; color: #B2B2B2;">
                  Agregar nuevo Ítem
                  <span class="fi-rr-add" style="margin-left: 20px;"></span>
                </p>
              </button>
              <!-- <button mat-icon-button type="button" (click)="newItems()" ><i class="fi-rr-add" ></i></button> -->
            </div>

          </div>
          <div style="float: right">
            <button class="bold compras-modal-button m-2" mat-stroked-button matStepperPrevious>Atrás</button>
            <button class="bold compras-modal-button" mat-flat-button color="primary" type="button"
              disabled='{{firstFormGroup.invalid}}' matStepperNext>Siguiente</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup" [editable]="isEditable">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>Finalizar orden</ng-template>
          <h3>Condiciones comerciales</h3>
          <mat-divider></mat-divider>
          <br>
          <div>
            <mat-form-field style="padding: 1%;" class="col-12" appearance="fill">
              <mat-label>Observaciones *</mat-label>
              <input style="text-transform: none;" matInput type="text" placeholder="Escribe las observaciones"
                formControlName="observations">
              <mat-error *ngIf="error.observations.errors"><span
                  *ngIf="error.observations.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
              <mat-error *ngIf="error.observations.errors"><span *ngIf="error.observations.errors.minlength">¡Aún falta!
                  Recibimos mínimo 3 caracteres</span></mat-error>
              <mat-error *ngIf="error.observations.errors"><span *ngIf="error.observations.errors.maxlength">¡Cuidado!
                  Solo puedes tener un máximo de 255 caracteres</span></mat-error>
            </mat-form-field>

            <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
              <mat-label>Sub-total</mat-label>
              <input matInput currencyMask [options]="options" placeholder="Escribe el nombre de la aplicación"
                formControlName="sub_total" readonly>
              <mat-error *ngIf="error.sub_total.errors"><span *ngIf="error.sub_total.errors.required">¡Advertencia! Este
                  campo es requerido</span></mat-error>
              <mat-error *ngIf="error.sub_total.errors"><span *ngIf="error.sub_total.errors.minlength">¡Aún falta!
                  Recibimos mínimo 3 caracteres</span></mat-error>
              <mat-error *ngIf="error.sub_total.errors"><span *ngIf="error.sub_total.errors.maxlength">¡Cuidado! Solo
                  puedes tener un máximo de 40 caracteres</span></mat-error>
            </mat-form-field>



            <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
              <mat-label>Descuento *</mat-label>
              <input style="text-transform: none;" matInput currencyMask [options]="options"
                placeholder="Escribe el descuento" formControlName="discount" (keydown.enter)="calculardiscount()"
                (blur)="calculardiscount()">
              <mat-error *ngIf="error.discount.errors"><span *ngIf="error.discount.errors.required">¡Advertencia! Este
                  campo es requerido</span></mat-error>
              <mat-error *ngIf="error.discount.errors"><span *ngIf="error.discount.errors.minlength">¡Aún falta!
                  Recibimos mínimo 3 caracteres</span></mat-error>
              <mat-error *ngIf="error.discount.errors"><span *ngIf="error.discount.errors.maxlength">¡Cuidado! Solo
                  puedes tener un máximo de 3 caracteres</span></mat-error>
            </mat-form-field>

            <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
              <mat-label>Total IVA. *</mat-label>
              <input matInput currencyMask [options]="options" placeholder="Escribe el nombre de la aplicación"
                formControlName="iva" (keydown.enter)="calculardiscount()" (blur)="calculardiscount()">
              <mat-error *ngIf="error.iva.errors"><span *ngIf="error.iva.errors.required">¡Advertencia! Este campo es
                  requerido</span></mat-error>
              <mat-error *ngIf="error.iva.errors"><span *ngIf="error.iva.errors.minlength">¡Aún falta! Recibimos mínimo
                  3 caracteres</span></mat-error>
              <mat-error *ngIf="error.iva.errors"><span *ngIf="error.iva.errors.maxlength">¡Cuidado! Solo puedes tener
                  un máximo de 40 caracteres</span></mat-error>
            </mat-form-field>



            <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
              <mat-label>Total</mat-label>
              <input style="text-transform: none;" matInput currencyMask [options]="options"
                placeholder="Escribe el total" formControlName="total" readonly>
              <mat-error *ngIf="error.total.errors"><span *ngIf="error.total.errors.required">¡Advertencia! Este campo
                  es requerido</span></mat-error>
              <mat-error *ngIf="error.total.errors"><span *ngIf="error.total.errors.minlength">¡Aún falta! Recibimos
                  mínimo 3 caracteres</span></mat-error>
              <mat-error *ngIf="error.total.errors"><span *ngIf="error.total.errors.maxlength">¡Cuidado! Solo puedes
                  tener un máximo de 3 caracteres</span></mat-error>
            </mat-form-field>

            <mat-form-field style="padding: 1%;" class="col-12" appearance="fill">
              <mat-label>Valor en letras</mat-label>
              <input style="text-transform: none;" matInput type="text" placeholder="Valor en letras"
                formControlName="value_in_letters" readonly>
              <mat-error *ngIf="error.value_in_letters.errors"><span
                  *ngIf="error.value_in_letters.errors.required">¡Advertencia! Este campo es
                  requerido</span></mat-error>
              <mat-error *ngIf="error.value_in_letters.errors"><span
                  *ngIf="error.value_in_letters.errors.minlength">¡Aún falta! Recibimos mínimo 3
                  caracteres</span></mat-error>
              <mat-error *ngIf="error.value_in_letters.errors"><span
                  *ngIf="error.value_in_letters.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 3
                  caracteres</span></mat-error>
            </mat-form-field>
            <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
              <mat-label>Fecha de entrega *</mat-label>
              <input matInput type="date" placeholder="Escribe el nombre de la aplicación"
                formControlName="delivery_date">
              <mat-error *ngIf="error.delivery_date.errors"><span
                  *ngIf="error.delivery_date.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
              <mat-error *ngIf="error.delivery_date.errors"><span *ngIf="error.delivery_date.errors.minlength">¡Aún
                  falta! Recibimos mínimo 3 caracteres</span></mat-error>
              <mat-error *ngIf="error.delivery_date.errors"><span *ngIf="error.delivery_date.errors.maxlength">¡Cuidado!
                  Solo puedes tener un máximo de 40 caracteres</span></mat-error>
            </mat-form-field>

            <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
              <mat-label>Método de pago *</mat-label>
              <mat-select formControlName="pay" (valueChange)="cambioPagoValue($event)">
                <mat-option *ngFor="let item of ['Efectivo','Transferencia','Cheque','Tarjeta de crédito']"
                  [value]="item"> {{item}} </mat-option>
              </mat-select>
              <mat-error *ngIf="error.pay.errors"><span *ngIf="error.pay.errors.required">¡Advertencia! Este campo es
                  requerido</span></mat-error>
            </mat-form-field>


            <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
              <mat-label>Condición de pago *</mat-label>
              <mat-select formControlName="payment_condition">
                <mat-option *ngFor="let item of ['Abono','Anticipo','Crédito']" [value]="item"> {{item}} </mat-option>
              </mat-select>
              <mat-error *ngIf="error.pay.errors"><span *ngIf="error.pay.errors.required">¡Advertencia! Este campo es
                  requerido</span></mat-error>
            </mat-form-field>


            <mat-form-field style="padding: 1%;" class="col-3"
              *ngIf="['Abono','Anticipo'].includes(secondFormGroup.get('payment_condition').value)" appearance="fill">
              <mat-label>Valor {{secondFormGroup.get('payment_condition').value}} *</mat-label>
              <input matInput currencyMask [options]="options" placeholder="Escribe el valor"
                formControlName="payment_deb">
              <mat-error *ngIf="error.payment_deb.errors"><span *ngIf="error.payment_deb.errors.required">¡Advertencia!
                  Este campo es requerido</span></mat-error>
            </mat-form-field>

            <mat-form-field style="padding: 1%;" class="col-3"
              *ngIf="['Abono','Anticipo'].includes(secondFormGroup.get('payment_condition').value)" appearance="fill">
              <mat-label>Porcentaje de {{secondFormGroup.get('payment_condition').value}} *</mat-label>
              <input (change)="cambiarValor($event.target.value)" matInput type="number" placeholder="50%"
                formControlName="payment_percent">
              <span matPrefix>% &nbsp;</span>
              <mat-error *ngIf="error.payment_percent.errors"><span
                  *ngIf="error.payment_percent.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            </mat-form-field>



            <mat-form-field style="padding: 1%;" class="col-3" appearance="fill"
              *ngIf=" secondFormGroup.get('payment_condition').value == 'Crédito' ">
              <mat-label>Condición de crédito *</mat-label>
              <mat-select formControlName="credit_condition">
                <mat-option *ngFor="let item of ['30 Días','60 Días','90 Días']" [value]="item"> {{item}} </mat-option>
              </mat-select>
              <mat-error *ngIf="error.pay.errors"><span *ngIf="error.pay.errors.required">¡Advertencia! Este campo es
                  requerido</span></mat-error>
            </mat-form-field>

            <mat-form-field style="padding: 1%;"
              [ngClass]="secondFormGroup.get('payment_condition').value == 'Crédito' ? 'col-6' : 'col-3'"
              appearance="fill">
              <mat-label>Garantía *</mat-label>
              <mat-select formControlName="warranty">
                <mat-option value="Si">Si</mat-option>
                <mat-option value=No>No</mat-option>

              </mat-select>
              <mat-error *ngIf="error.warranty.errors"><span *ngIf="error.warranty.errors.required">¡Advertencia! Este
                  campo es requerido</span></mat-error>
            </mat-form-field>

            <mat-form-field style="padding: 1%;" class="col-6" appearance="fill">
              <mat-label>Póliza *</mat-label>
              <mat-select formControlName="policy">
                <mat-option value="Si">Si</mat-option>
                <mat-option value=No>No</mat-option>

              </mat-select>
              <mat-error *ngIf="error.policy.errors"><span *ngIf="error.policy.errors.required">¡Advertencia! Este campo
                  es requerido</span></mat-error>
            </mat-form-field>

            <mat-form-field style="padding: 1%;" class="col-6" appearance="fill">
              <mat-label>Ciudad de Entrega *</mat-label>
              <input matInput type="text" placeholder="Indique la ciudad de entrega" formControlName="ciudad">
              <mat-error *ngIf="error.ciudad.errors"><span *ngIf="error.ciudad.errors.required">¡Advertencia! Este campo
                  es requerido</span></mat-error>
              <mat-error *ngIf="error.ciudad.errors"><span *ngIf="error.ciudad.errors.minlength">¡Advertencia! No puede
                  ser inferior a 4 caracteres</span></mat-error>
              <mat-error *ngIf="error.ciudad.errors"><span *ngIf="error.ciudad.errors.maxlength">¡Advertencia! No puede
                  ser superior a 50 caracteres</span></mat-error>
            </mat-form-field>
            <mat-form-field style="padding: 1%;" class="col-6" appearance="fill">
              <mat-label>Dirección *</mat-label>
              <input matInput type="text" placeholder="Indique la dirección" formControlName="direccion">
              <mat-error *ngIf="error.direccion.errors"><span *ngIf="error.direccion.errors.required">¡Advertencia! Este
                  campo es requerido</span></mat-error>
              <mat-error *ngIf="error.direccion.errors"><span *ngIf="error.direccion.errors.minlength">¡Advertencia! No
                  puede ser inferior a 4 caracteres</span></mat-error>
              <mat-error *ngIf="error.direccion.errors"><span *ngIf="error.direccion.errors.maxlength">¡Advertencia! No
                  puede ser superior a 20 caracteres</span></mat-error>
            </mat-form-field>
            <mat-form-field style="padding: 1%;"
              [ngClass]="secondFormGroup.get('payment_condition').value == '' ? 'col-6' : 'col-12'" appearance="fill">
              <mat-label>Observaciones de condiciones *</mat-label>
              <textarea matInput rows="1" placeholder="Registre las observaciones de las condiciones"
                formControlName="observacionCondiciones"></textarea>
              <mat-error *ngIf="error.observacionCondiciones.errors"><span
                  *ngIf="error.observacionCondiciones.errors.required">¡Advertencia! Este campo es
                  requerido</span></mat-error>
              <mat-error *ngIf="error.observacionCondiciones.errors"><span
                  *ngIf="error.observacionCondiciones.errors.minlength">¡Advertencia! No puede ser inferior a 20
                  caracteres</span></mat-error>
              <mat-error *ngIf="error.observacionCondiciones.errors"><span
                  *ngIf="error.observacionCondiciones.errors.maxlength">¡Advertencia! No puede ser superior a 100
                  caracteres</span></mat-error>
            </mat-form-field>


            <div class="col-12">
              <div style="float: right">
                <button mat-stroked-button matStepperPrevious class="bold compras-modal-button">Atrás</button>

                <button class="ms-2 me-2 bold compras-modal-button" mat-stroked-button type="button" color="primary"
                  (click)="enviarOrder()" *ngIf="this.data.ordenCompra">Enviar</button> &nbsp;
                <button class="bold compras-modal-button" mat-raised-button type="button" color="primary"
                  (click)="saveOrder(true)" disabled="{{!validarEnvioForm()}}">Guardar</button>
              </div>
            </div>




          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-dialog-content>
  <!-- </form> -->
</div>
