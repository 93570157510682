import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { CandidateCommentsComponent } from './candidate-comments/candidate-comments.component';
import { CandidateCreateComponent } from './candidate-create/candidate-create.component';
import { CandidateEditComponent } from './candidate-edit/candidate-edit.component';
import { CandidateMainComponent, TypeContracts } from './candidate-main/candidate-main.component';
import { CandidateUploadComponent } from './candidate-upload/candidate-upload.component';
import { SharedModule } from '../../../../shared/shared.module';
import { CandidateAssignComponent } from './candidate-assign/candidate-assign.component';
import localeCO from '@angular/common/locales/es-CO';
import { CandidateCommentEditComponent } from './candidate-comment-edit/candidate-comment-edit.component';
import { CandidateAssignTrainerComponent } from './candidate-assign-trainer/candidate-assign-trainer.component';
import { CandidateCvComponent, CreateSign } from './candidate-cv/candidate-cv.component';
import { CheckAttachmentComponent } from './check-attachment/check-attachment.component';
import { ContractCreationComponent, RejectionComment } from './contract-creation/contract-creation.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SignaturePadModule } from 'angular2-signaturepad';
import { CandidateAttachmentsComponent } from './candidate-attachments/candidate-attachments.component';
import { SharedFormModule } from 'src/app/modules/bpms/modules/shared-form/shared-form.module';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { CandidateJuridicoNominaDocComponent } from './candidate-juridico-nomina-doc/candidate-juridico-nomina-doc.component';
import { CandidateCvJuridicoNominaDocComponent } from './candidate-cv-juridico-nomina-doc/candidate-cv-juridico-nomina-doc.component';
import { ModalCampaignEditComponent } from './src/app/modules/rrhh/modules/candidates/modal-campaign-edit/modal-campaign-edit.component';
import { AttachmentDeleteFormComponent } from './attachment-delete-form/attachment-delete-form.component';
import { AddressFormComponent } from './address-form/address-form.component';
import { PersonalReferenceUpComponent } from './personal-reference-up/personal-reference-up.component';
registerLocaleData(localeCO, 'es-CO');



@NgModule({
  declarations: [
    CandidateCommentsComponent,
    CandidateCreateComponent,
    CandidateEditComponent,
    CandidateMainComponent,
    CandidateUploadComponent,
    CandidateAssignComponent,
    CandidateCommentEditComponent,
    CandidateAssignTrainerComponent,
    CandidateCvComponent,
    CheckAttachmentComponent,
    ContractCreationComponent,
    CreateSign,
    RejectionComment,
    CandidateAttachmentsComponent,
    TypeContracts,
    CandidateJuridicoNominaDocComponent,
    CandidateCvJuridicoNominaDocComponent,
    ModalCampaignEditComponent,
    AttachmentDeleteFormComponent,
    AddressFormComponent,
    PersonalReferenceUpComponent,
  ],
  imports: [
    SignaturePadModule,
    CommonModule,
    SharedModule,
    PipesModule,
    SharedFormModule,
    MaterialModule

  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es-CO'
    }
  ],
})
export class CandidatesModule { }
