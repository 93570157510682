import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild, Output, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DestroyComponentService } from '../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../shared/alerts/alerts.service';
import { MatDialog } from '@angular/material/dialog';
import { saveAs as importedSaveAs } from 'file-saver';
import { ImportDesprendibleService } from '../../services/rest/ImportDesprendible.service';
import Swal from 'sweetalert2';
import { CandidatesService } from 'src/app/modules/rrhh/services/candidates.service';
import { PaymentSlipService } from 'src/app/modules/rrhh/services/paymentSlip.service';
import { FilesViewComponent } from 'src/app/shared/files-view/files-view.component';


@Component({
  selector: 'app-liquidations-import',
  templateUrl: './liquidations-import.component.html',
  styleUrls: ['./liquidations-import.component.sass']
})
export class LiquidationsImportComponent implements OnInit {

  // MatPaginator Inputs
  dataSource = new MatTableDataSource<any>();
  documento_work = new FormControl('');
  typeDocument = new FormControl('');
  data_name_work = [];
  FrmFormulario: FormGroup;
  length = 100;
  result_busqueda = 0; // Si encuentra o no resultados, se activan las visualez
  pageSize = 5;
  page = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  total_registros = 5;

  displayedColumns: string[] = ['actions','fecha_carga', 'nombre', 'documento', 'salario_basico', 'total_descuentos', 'total_neto_pagar']; //Tabla inicial
  displayedColumns2: string[] = ['nombre', 'documento', 'salario_basico', 'total_descuentos', 'total_neto_pagar']; //Tabla para el modal

  months: any = [
    { id: 'all', name: 'Todos' },
    { id: 1, name: 'Enero' },
    { id: 2, name: 'Febrero' },
    { id: 3, name: 'Marzo' },
    { id: 4, name: 'Abril' },
    { id: 5, name: 'Mayo' },
    { id: 6, name: 'Junio' },
    { id: 7, name: 'Julio' },
    { id: 8, name: 'Agosto' },
    { id: 9, name: 'Septiembre' },
    { id: 10, name: 'Octubre' },
    { id: 11, name: 'Noviembre' },
    { id: 12, name: 'Diciembre' },
  ];
  years: any = [];

  documents: any = [];

  private month: string = '';
  private year: string = '';
  private document: string = '';
  private typeDoc: string = '';
  public uploadFile;
  nameFile: any;
  FileData: any;
  @ViewChild('fileInput') fileInput: ElementRef;
  selectMonths = new FormControl('');
  selectYears = new FormControl('');

  constructor(private importDesprendibles: ImportDesprendibleService,
    private alert: AlertsService,
    private formBuilder: FormBuilder,
    private users: CandidatesService,
    private dialog: MatDialog,
    private paymentSlipService: PaymentSlipService) { }

  ngOnInit(): void {
    let dateCurrier = new Date();
    let yearCurrier = dateCurrier.getFullYear();
    let yearMin = 2022;
    while (yearMin <= yearCurrier) { //Llenamos el array years con los años para el filtro
      this.years.push(yearMin);
      yearMin++;
    }
    //se listan los tipos de documentos
    this.users.getIdTypes().subscribe(resp => {
      this.documents = resp;
    });
    //Tabla historico de desprendibles
    this.get_data(this.pageSize, this.page);
    this.uploadFile = this.formBuilder.group({
      file: ['']
    });
    this.typeDocument.valueChanges.subscribe(resp => {
      this.typeDoc = resp;
    });

    this.selectMonths.setValidators([Validators.required]);
    this.selectMonths.updateValueAndValidity();

    this.selectYears.setValidators([Validators.required]);
    this.selectYears.updateValueAndValidity();

    this.selectMonths.valueChanges.subscribe(resp => {
      this.month = resp;
      this.get_data(this.pageSize, this.page);
      this.show_list(this.month, this.year);
    });
    this.selectYears.valueChanges.subscribe(resp => {
      this.year = resp;
      this.get_data(this.pageSize, this.page);
      this.show_list(this.month, this.year);
    });
  }

  pageEvent(event: any): void {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.get_data(this.pageSize, this.page);
  }

  /**
   * @author David Reinoso
   * Metodo para capturar el documento a filtrar
   */
  onBlurMethod(e) {
    this.document = e;
    this.get_data(this.pageSize, this.page);
  }

  /**
  * @author David Reinoso
  * Metodo para cargar el archivo
  */
  onChangeFile(e) {
    this.uploadFile.get('file').setValue('');
    this.nameFile = e.target.files[0].name;
    this.FileData = e.target.files[0];
    this.uploadFile.get('file').setValue(this.FileData);
    this.upload();
    e.target.value = null;
  }


  /**
   * @author David Reinoso
   * Metodo para listar
  */
  get_data(pageSize, page) {
    this.importDesprendibles.getPaymentSlipData({
      liquidations: true,
      month: this.month,
      year: this.year,
      document: this.document,
      typeDoc: this.typeDoc,
    }, pageSize, page).subscribe(resp => {
      this.dataSource = resp.data.data;
      this.length = resp.data.total;
    });
  }

  /**
  * @author David Reinoso
  * Metodo descargar la plantilla
  */
  downloadTemplate() {
    this.importDesprendibles.downloadTemplate(true).subscribe(resp => {
      importedSaveAs(resp, 'plantilla_cargue_desprendibles_de_liquidaciones.xlsx');
    });
  }

  /**
  * @author David Reinoso
  * Metodo realizar el import del archivpo
  */
  upload() {
    const formData = new FormData();
    formData.append('liquidations',"true");
    formData.append('file', this.uploadFile.get('file').value);

    let str = '¿En verdad deseas <b>cargar</b> el archivo?,' + '\n' + 'recuerda que si te equivocas podrás cargar de nuevo la' + '\n' + 'plantilla y el sistema lo corregirá' + '\n' + 'automáticamente<br><br><b style="text-align:center;">Nombre del archivo</b><br>' + this.nameFile;
    Swal.fire({
      title: '¿Estás seguro?',
      html: '<pre style="font-family: Poppins; font-size: 14px;">' + str + '</pre>',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.importDesprendibles.uploadFile(formData).subscribe(resp => {
          if (resp?.data?.msg == 'Se generó un Excel con los errores presentados') {
            this.alert_error(resp?.data?.file_url, resp?.data?.count_good, resp?.data?.count_bad);
          }
          if (resp.data == "Proceso realizado correctamente") {
            this.alert.alertSuccessWithoutButton('¡Excelente!', 'Se ha cargado el archivo con éxito.');
          }
          this.get_data(this.pageSize, this.page);
        });
      }
    });
    this.uploadFile.get('file').setValue('');
    this.nameFile = '';
    this.FileData = '';
  }

  /**
  * @author David Reinoso
  * Metodo que muestra los errores al cargar el desprendible
  */
  alert_error(file_error, good, bad) {

    let mgs = "Lo sentimos, ha ocurrido un error inesperado y no <br>fue posible cargar todos los desprendibles.";
    mgs = mgs.concat("</br></br><b>Resumen de la gestión</b> </br>");
    mgs = mgs.concat("</br><b>Desprendibles cargados: " + good + "</b>");
    mgs = mgs.concat("</br><b>Desprendibles con error: " + bad + "</b></br>");

    let btn_import = '</br><button  ';
    btn_import = btn_import.concat(' style="box-shadow:none !important;width: 300px;height: 48px;border-radius: 6px;border: 1px solid #51B0CB;background : #51B0CB; color:white !important"> ');
    btn_import = btn_import.concat(' Cargar reporte <span class="fi-rr-upload"></span></button>');

    let btn_dowload = '</br><button ';
    btn_dowload = btn_dowload.concat(' style="box-shadow:none !important;width: 300px;height: 48px;border-radius: 6px;border: 1px solid #51B0CB;background : white; color: #51B0CB  !important"> ');
    btn_dowload = btn_dowload.concat(' Descargar reporte <span class="fi-rr-download"></span> </button><br>');

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn',
        cancelButton: 'btn'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      icon: 'error',
      title: '¡Oops!',
      showCloseButton: true,
      width: '628px',
      html: mgs,
      showCancelButton: true,
      confirmButtonText: btn_dowload,
      cancelButtonText: btn_import,
      reverseButtons: false,
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(file_error, '_blank');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.fileInput.nativeElement.click();
      }
    })
  }

  /**
  * @author David Reinoso
  * Metodo para mostrar la lista
  */
  show_list(mount, year) {
    if (mount != '' && year != '') this.result_busqueda = 1;
    else this.result_busqueda = 0;
  }

    /**
  * @author Juan Carlos Alonso
  * @createdate 2023-09-08
  * Método que obtiene el desprendible, si es vista se visualiza en un modal, de lo contrario lo descarga
  */
    getCertificate(id:number,isView:boolean){
      const lastMonth = new Date().getMonth()+1 == this.selectMonths.value;
      this.paymentSlipService.getPayment(Number(this.selectMonths.value), this.selectYears.value, lastMonth, true, id).subscribe((resp: any) => {
        if (isView) {
          let fileURL = URL.createObjectURL(resp);
          const checkDialog = this.dialog.open(FilesViewComponent, {
            data: { url: fileURL, name: "desprendible_liquidación", file: resp ,print: true}
          });
        } else {
          importedSaveAs(resp, "desprendible_liquidación");
        }

      })
    }
}
