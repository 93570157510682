import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate,  CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { LeftNavService } from '../services/rest/left-nav.service';
import { AuthService } from '../services/rest/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UrlAplicationsGuard implements CanActivateChild {
    private applications:any[] = Array.from(this.authService.getUser().applications)
    constructor(
        private leftNavService: LeftNavService,     
        private authService: AuthService,
        private router: Router){}

    /**
     * Metodo que se encarga de determinar si se peude o no ingresar a las url del aplicativo
     * @author Juan David Guerrero Vargas
     * @param childRoute:ActivatedRouteSnapshot {ActivatedRouteSnapshot} SnapShto de la ruta con los datos
     * @param state:RouterStateSnapshot {RouterStateSnapshot} SpanShot del estado de la ruta redireccionada, parametros, URL implicita
     * @returns {Promise<boolean>} Promise<boolean> Respuesta booleana teniendo en cuenta el consumo del enpoint para determinar si se debe o no dar permiso de entrar
     */
    async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const urlActual = state.url.slice(1).split('/')[1];
        if(urlActual !== undefined){
            const vari = await this.leftNavService.getLeftMenu(urlActual).toPromise();
            if(vari?.data !== undefined && Array.isArray(vari?.data) == true && vari?.data.length > 0){
                return true
            }
        }
        if(this.applications.length > 0){
            this.router.navigate([this.applications[0].url.slice(1) + ""])
        }else{
            this.router.navigate(["mios/ciu"])
        }
        return false
    }

}