import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { TestsService } from '../../../services/tests.service';

@Component({
  selector: 'app-assessment-score',
  templateUrl: './assessment-score.component.html',
  styleUrls: ['./assessment-score.component.sass']
})
export class AssessmentScoreComponent implements OnInit {
  test
  TabIndex=0
  userId:number
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog, public alertService:AlertsService,
    public dialogRef: MatDialogRef<AssessmentScoreComponent>,
    private testsService:TestsService,
    private auth:AuthService) { 
      this.test = this.data.assessment.candidate_test.resources
      this.userId= this.auth.getUser().rrhh_id 
    }

  ngOnInit(): void {

    console.log(this.data);
  }
  giveTest(score){
  
      this.alertService.alertConfirm('¿Desea evaluar a este candidato?').then( e => {
        if(e.isConfirmed){
         
          this.testsService.getAssessmentScore(this.data.assessment.candidate_test_id,score).subscribe(res=>{
            this.TabIndex = this.test.length - 1
            this.alertService.alertSuccess('¡Evaluado!','El candidato ha sido evaluado exitosamente');
            this.test=res
          })
         
        }
      })

  }
  saveTest(score){
    this.testsService.updateAssessmentSection(this.data.assessment.candidate_test_id, score)
    .subscribe(res=>{
      this.alertService.alertConfirm('Guardado. ¿Desea evaluar el resultado actual?').then( e => {
        if(e.isConfirmed){
          this.giveTest(score)
        
        }
     
    })
    })
    
  }
}
