<button class="position-close" mat-icon-button aria-label="close dialog" mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>
<div class="content">
  <div class="position" fxLayout="row" fxLayoutAlign="center">
    <img src="../../../assets/images/lockOutline.png" width="100"
    height="100">
  </div>
    <form class="row form" [formGroup]="passForm" (ngSubmit)="sendEmail(passForm)">
      <div class="col-12">
          <mat-dialog-content class="mat-typography text-center mt-4">
            <h3 mat-dialog-title>{{data.title}}</h3>
            <p cdkFocusInitial>Selecciona e ingresa tu número de documento y te enviaremos a tu correo registrado el nombre de tu usario.
            </p>
              <br>
              <mat-form-field class="col-12" appearance="fill">
                <mat-label>Tipo de documento</mat-label>
                <mat-select formControlName="id_type_id">
                  <mat-option *ngFor="let documentType of documentList.data" [value]="documentType.id">
                    {{documentType.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="error.id_type_id.errors"><span *ngIf="error.id_type_id.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
              </mat-form-field>

              <mat-form-field class="col-12" appearance="fill">
                <mat-label>Ingresa tu número de documento</mat-label>
                <input matInput formControlName="id_number" maxlength="15" type="text" required>
                <mat-error *ngIf="error.id_number.errors"><span *ngIf="error.id_number.errors.pattern">¡Advertencia! Este campo solo acepta números</span></mat-error>
                <mat-error *ngIf="error.id_number.errors"><span *ngIf="error.id_number.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                <mat-error *ngIf="error.id_number.errors"><span *ngIf="error.id_number.errors.minlength">¡Aún falta! Recibimos mínimo 5 caracteres</span></mat-error>
                <mat-error *ngIf="error.id_number.errors"><span *ngIf="error.id_number.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 15 caracteres</span></mat-error>
              </mat-form-field>
              <br>
          </mat-dialog-content>

          <div class="row mt-3">
            <div class="col">
                <button mat-stroked-button style="width: 100%;" mat-dialog-close>Cancelar</button>
            </div>
            <div class="col">
                <button mat-raised-button type="submit" style="width: 100%;" color="primary" [disabled]="passForm.invalid" >Enviar</button>
            </div>
          </div>
      </div>
    </form>
</div>
