<div class="row novelty-row-padding">

  <h2 mat-dialog-title>
    <span class="gray-color" style="margin-left: -9px">Nueva fecha inactiva</span>
    <button style="float: right;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
  </h2>

  <mat-dialog-content style="padding: 3%; overflow-x: hidden" class="mat-typography">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <form [formGroup]="inactiveDateForm" (ngSubmit)="createInactiveDate()">

            <div class="col-12">
              <mat-form-field appearance="fill" class="w100">
                <mat-label>Fecha Inactiva</mat-label>
                <input matInput [matDatepicker]="picker" placeholder="Fecha Inactiva" formControlName="inactiveDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field class="w100">
                <mat-label>Descripción</mat-label>
                <input matInput
                       type="text"
                       id="justify"
                       name="justify"
                       formControlName="descriptionDate">
              </mat-form-field>
            </div>

            <div class="col-12">
              <button mat-raised-button
                      class="w100"
                      type="submit" color="primary"
                      [disabled]="sending"> Crear novedad
              </button>
            </div>

          </form>
        </div>
      </div>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
  </mat-dialog-actions>

</div>
