import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../../../core/services/rest/auth.service';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const COMPRAS_URL = environment.COMPRAS_URL;
@Injectable({
  providedIn: 'root'
})
export class SolicitudesService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-08-25
   * Servicio que lista todos los aplicativos del aplicativo ciu
   */
  getSolicitudes(): Observable<any>{
    return this.http.get<any>(`${COMPRAS_URL}request/?paginate=0`)
    .pipe(
      catchError(this.handleError)
      );
  }

    /**
   * @author Jose Vicente Silva
   * @createdate 2021-08-25
   * Servicio que lista todos los aplicativos del aplicativo ciu
   */
     getSolicitudesUser( idUser, params:any ): Observable<any>{
      //return this.http.get<any>(`${COMPRAS_URL}request/${idUser}/?paginate=${isPage}&n=${size}&page=${page}`);
      return this.http.post<any>(`${COMPRAS_URL}request/${idUser}/filter`,params);
    }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-08-25
   * Servicio que lista todos los aplicativos del aplicativo ciu
   */
    getSoliciudesPaginate(params, cotization = false,history = false): Observable<any>{
      /*
      let link = `${COMPRAS_URL}request/?paginate=${isPage}&n=${size}&page=${page}&rol=${rol}`;
      if( filtroFecha !== null ){
        link = `${COMPRAS_URL}request/?paginate=${isPage}&n=${size}&page=${page}&rol=${rol}&date=${filtroFecha}`;
      }
      */



      return this.http.post<any>( `${COMPRAS_URL}request/filter?${cotization ? 'cotization=true': ''}${history ? '&history=true': ''}` , params)
      .pipe(
        catchError(this.handleError)
        );
    }

    blockRequest(dates){
      return this.http.post<any>(`${COMPRAS_URL}blocksRequest`, dates)
      .pipe(
        catchError(this.handleError)
      );
    }

    validatorsBlocks() {
      return this.http.get<any>(`${COMPRAS_URL}validators/blocks`)
      .pipe(
        catchError(this.handleError)
      );
    }


    /**
   * @author Jair Armando Celis Torrado
   * @createdate 2021-09-06
   * Servicio filtro de solicitudes por fecha
   */
     searchRequestByDate(date): Observable<any> {
      return this.http.post<any>(`${COMPRAS_URL}requestByDate`, date )
      .pipe(
        catchError(this.handleError)
        );
    }

    /**
   * @author Jair Armando Celis Torrado
   * @createdate 2021-09-06
   * Servicio filtro de solicitudes por estado
   */
     searchRequestByState(state): Observable<any> {
      return this.http.post<any>(`${COMPRAS_URL}requestByState`, state )
      .pipe(
        catchError(this.handleError)
        );
    }

    /**
   * @author Jair Armando Celis Torrado
   * @createdate 2021-09-06
   * Servicio que lista todos los estados existentes para las solicitudes
   */
     getRequestStates(): Observable<any> {
      return this.http.get<any>(`${COMPRAS_URL}requestState/?paginate=0`)
      .pipe(
        catchError(this.handleError)
        );
    }

    /**
   * @author Jose Vicente Silva
   * @createdate 2021-08-25
   * Servicio que lista todos los aplicativos del aplicativo ciu
   */
     saveSolicitud(solicitud: any): Observable<any>{
      return this.http.post<any>(`${COMPRAS_URL}request/`, solicitud)
      .pipe(
        catchError(this.handleError)
        );
    }

      /**
   * @author Jose Vicente Silva
   * @createdate 2021-08-25
   * Servicio que lista todos los aplicativos del aplicativo ciu
   */
       updateSolicitudEstado(estado: any, id: number): Observable<any>{
        return this.http.put<any>(`${COMPRAS_URL}request/${id}`, estado)
        .pipe(
          catchError(this.handleError)
          );
      }

      saveObservacion(observacion: any  ): Observable<any>{
        return this.http.post<any>(`${COMPRAS_URL}requestObservation/`, observacion)
        .pipe(
          catchError(this.handleError)
          );
      }

    downloadRequest(idRol:number): Observable<any>{
      return this.http.get(`${COMPRAS_URL}file/request-export/${idRol}`, {responseType: 'blob' as 'json'});
    }


    downloadRequestHist( params ): Observable<any>{
      params["fecha_fin"].setHours(23);


      //console.log(fechaFin->setHours(23));
      return this.http.post(`${COMPRAS_URL}file/request-export-hist`, params, {responseType: 'blob' as 'json'});
    }

    itemsMother(id):Observable<any>{
      return this.http.get<any>(`${COMPRAS_URL}request/itemsMother/${id}`)
      .pipe(
        catchError(this.handleError)
      );
    }

    validateAssociated(id):Observable<any>{
      return this.http.get<any>(`${COMPRAS_URL}request/validateAssociated/${id}`)
      .pipe(
        catchError(this.handleError)
      );
    }
    /**
     * @author Andres Giraldo
     * @createdate 2022-03-09
     * Funcion que envia un rechazo o aprovación masiva de solicitudes
    */
    massiveRequestApproveOrDecline(data: any): Observable<any>{
      return this.http.post<any>(`${COMPRAS_URL}request/massive`, data)
      .pipe(
        catchError(this.handleError)
        );
    }
}
