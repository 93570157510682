<div class="main-container">
    <div class="mt-5">
        <button mat-icon-button class="btn-regresar" [routerLink]="['/mios/auditoria/control/detail/'+id_matriz]">
            <mat-icon matPrefix style="background-color: #000; border-radius: 50%;"><i class="fi-sr-angle-small-left" style="color: #FFF;"></i></mat-icon>
            Regresar
        </button>
    </div>
    <header class="mt-5">
        <h1 class="interlineado">{{titulo}}</h1>
        <p>{{supervisor}}</p>
        <h5>Creada el {{creacion | date:'dd/MM/yyyy hh:mm a'}}</h5>
    </header>
    <div class="mt-4">
        <app-tabla-grupo [idGrupo]="id_grupo"></app-tabla-grupo>
    </div>
</div>