<h3  mat-dialog-title>Diseño de pruebas</h3>
<hr>
<mat-dialog-content>
    <mat-tab-group [selectedIndex]="TabIndex" linear>
        <mat-tab *ngFor="let item of data.testsNames; let i = index"  [label]="item.name"> 
          <div *ngIf="item.type_key == 'attachment'" class="test-content" >
                <p>
                    Por favor cargue la prueba a aplicar al candidato.
                </p>
                <div>
                    <button mat-button  (click)="fileInput.click()"><b> Cargar Archivo &nbsp;<i class="fi-rr-cloud-upload"></i></b> </button>
                    <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" id="file">
                </div>
                <br>
                <div *ngIf="attachments.name?.length>0">Archivo cargado: {{attachments.name}}</div>
                <br>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Agregue un comentario</mat-label>
                    <textarea matInput placeholder="Descripción y condiciones..." cols="4"  [(ngModel)]="description"></textarea>
                </mat-form-field> 
                <div align="center">
                    <button color="primary" mat-flat-button [disabled]="!uploadedFile" (click)="uploadFiles(item.type_key, i)">Asignar </button>
                </div>  
         </div>
         <div *ngIf="item.type_key == 'assessment'" class="test-content">
            <p><strong>Seleccione las competencias</strong> </p> 
            <div fxLayout="row" fxLayoutAlign="end center"  *ngIf="competences?.length > 0"> 
                <mat-checkbox  
                                [checked]="allCompetencesSelected"
                                (change)="allCompetencesSelected= $event.checked; 
                                allCompetencesSelected? competencesSelected.selectAll():competencesSelected.deselectAll(); 
                                pushArray(competencesSelected)">
                                Seleccionar todos
                </mat-checkbox>
                   
            </div>
            <div class="list_items">
                <mat-selection-list #competencesSelected>
                    <mat-list-option checkboxPosition *ngFor="let item of competences" [selected]="item.selected" (click)="pushArray(competencesSelected)" [value]="item">
                        {{item.name}}
                    </mat-list-option>
                </mat-selection-list>
            </div> 
            
            <hr>
            <div fxLayout="row" fxLayoutAlign="space-between center" >
                <mat-form-field style="width: 65%;" appearance="fill">
                    <mat-label>Nombre de la nueva compotencia</mat-label>
                    <input matInput type="text" [(ngModel)]="newCompetence">
                  </mat-form-field>
                <button mat-button [disabled]="!newCompetence" (click)="addCompetence()">Agregar competencia &nbsp; <span class="fi-rr-add"></span></button>
            </div>
            <div align="center">
                <button color="primary" mat-flat-button  [disabled]="competencesTest.length == 0 && selected" (click)="uploadFiles(item.type_key, i)">Asignar </button>
            </div>
            
         </div>
         <div *ngIf="item.type_key == 'quantitative'" class="test-content" >

         </div>

       </mat-tab>
      
      </mat-tab-group>


</mat-dialog-content>
