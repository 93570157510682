import { Component, OnInit, Inject } from '@angular/core';
import { CandidatesService } from '../../../services/candidates.service';
import { UsersService } from '../../../services/users.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestAssignComponent } from '../../requests/request-assign/request-assign.component';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';

@Component({
  selector: 'app-candidate-assign',
  templateUrl: './candidate-assign.component.html',
  styleUrls: ['./candidate-assign.component.sass']
})
export class CandidateAssignComponent implements OnInit {

  analysts:any = [];
  analystSelected;
  dates:any = [];
  daleSelected;
  dateSelectedToCandidates;
  candidates = [];
  candidatesSelected = [];
  candidatesByDay = [];
  reAssingSection = false;
  reScheduled = false;
  reAssingDateSelected = false;
  candidatesList= false

  constructor(private _user:UsersService, private _candidate:CandidatesService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialogRef<CandidateAssignComponent>,
              private alert:AlertsService) { }

  ngOnInit(): void {
    this.getAnalysts();
  }

  getAnalysts(){
    this._user.getUserByRole('analista').subscribe( (analysts: any) => {
      this.analysts = analysts;
    });
  }
  reset(){
    this.candidates = [];
    this.dates = [];
    this.daleSelected = null;
    this.clearCandidateByDay();
    this.reAssingSection = false;
    this.reScheduled = false;
    this.reAssingDateSelected = false;
    this.candidatesSelected = [];
  }

  selectAnalyst(analystId){
    this.reset();
    this.analystSelected = analystId;
    this._candidate.checkSchedulesCandidates(analystId).subscribe( (dates: any) => {
        this.dates = dates;
    });

  }

  setAnalyst(){
    this.alert.alertConfirm('¿Estas seguro que desea asignar a los candidato(s) seleccionados?').then(e => {
        if(e.isConfirmed){
          this._candidate.assignCandidateToAnalyst(this.analystSelected, {candidateIds: this.candidatesSelected
                          .toString(),dateSelected: this.daleSelected, requestId: this.data.requestId})
                          .subscribe( (resp: any) => {
            this.alert.alertSuccess('¡Hecho!', 'Candidato(s) asignados con éxito');
            this.selectAnalyst(this.analystSelected);
          });
        }
    });
  }

  selectDate(date){
    this.clearCandidateByDay();
    this.candidatesList= false
    this.daleSelected = date.date;
    this._candidate.getCandidatesToAnalystByDaySelected(this.data.requestId, {date: new Date(date.date)
                    .toISOString(), analystId: this.analystSelected })
                    .subscribe( (candidates: any) => {
      this.candidates = candidates;
      var objDiv = document.getElementById('botDiv');
      objDiv.scrollIntoView()
 
    } );
    
  }

  checkCandidatesToDisable(candidate){

    if (candidate.scheduled){
      this.candidates.forEach(e => {
          if ( !e.scheduled ){
            e.disabled = true;
          }
      });
    }else{
      this.candidates.forEach(e => {
        if ( e.scheduled ){
          e.disabled = true;
        }
    });
    }
  }

  checkCandidateEnableAll(){
      if (this.candidatesSelected.length === 0){
        this.candidates.forEach(e => {
            e.disabled = false;
      });
    }
  }

  pushArr(candidate){
    if (candidate.scheduled){
      this.reScheduled = true;
    }else{
       this.reScheduled = false;
    }

    if (this.candidatesSelected.indexOf(candidate.id) < 0){
      this.candidatesSelected.push(candidate.id);
      this.checkCandidatesToDisable(candidate);
    }else{
      this.candidatesSelected.splice(this.candidatesSelected.indexOf(candidate.id), 1);
      this.checkCandidateEnableAll();
    }
  }

  showCandidates(date, candidateIds){
    this.candidatesList= false
    this.daleSelected= null
    this.dateSelectedToCandidates = date;
    this.candidatesByDay = [];
    this._candidate.candidatesByIds(candidateIds.toString()).subscribe((resp: any) => {
            this.candidatesByDay = resp;
            this.candidatesList= true
            setTimeout(f => {
              var objDiv = document.getElementById('candidatesDiv');
              objDiv.scrollIntoView()
            }, 400);
            
            
      });
  }

  clearCandidateByDay(){
    this.dateSelectedToCandidates;
    this.candidatesByDay = [];
  }

  reAssgign(){
    this.reAssingSection = true;
  }


  reAssingDateSelect(date){
      this.reAssingDateSelected = date;
  }

  reAssgignSave(){
    this.alert.alertConfirm('¿Estas seguro que quiere reasignar a los candidato(s) seleccionados?').then(e => {
      if(e.isConfirmed){
        this._candidate.reAssignCandidateToAnalyst(this.analystSelected, {candidateIds: this.candidatesSelected
                        .toString(), dateSelected: this.reAssingDateSelected, requestId: this.data.requestId})
                        .subscribe( (resp: any) => {
          this.alert.alertSuccess('¡Hecho!', 'Candidato(s) reasignados con éxito');
          this.selectAnalyst(this.analystSelected);
        });
      }
    });
  }

  cancelSchedules(){
    this.alert.alertConfirm('¿Quieres cancelar las citas para los candidato(s) selecionados?').then(e => {
      if (e.isConfirmed){
        this._candidate.cancelSchedulesCandidates(this.analystSelected, {candidateIds: this.candidatesSelected.toString()})
                        .subscribe( e => {
          this.alert.alertSuccess('Cancelado', 'Candidato(s) cancelados con éxito');
          this.selectAnalyst(this.analystSelected);
        });
      }
    });
  }
}
