import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MailService } from '../../../services/rest/mail.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-manage-email',
  templateUrl: './manage-email.component.html',
  styleUrls: ['./manage-email.component.sass']
})
export class ManageEmailComponent implements OnInit {

  mailForm: FormGroup;
  archivosCargados: any[] = [];
  file: any;
  gestionar: boolean = false;
  email: any;

  // tslint:disable-next-line: member-ordering
  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5%',
    minHeight: '0',
    maxHeight: '20%',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private form: FormBuilder,
              public mailService: MailService,
              private alertsService: AlertsService,
              public dialog: MatDialogRef<ManageEmailComponent>) { }

  ngOnInit(): void {

    this.formControl();

    const data = new FormData();

    data.append ( 'emailId', this.data.data.emailId);

    this.mailService.postObtenerCorreo(data).subscribe((respo) => {
      this.email = respo[0].email_adjuntos;
      if (!this.data.data.email.cuerpo){
        this.data.data.email.cuerpo = respo[0].cuerpo
      }
      this.mailForm.patchValue({
        para: respo[0].de,
        respuesta: respo[0].cuenta_email.firma[0] ?
                    '<br/><br/><br/>' + respo[0].cuenta_email.firma[0].firmaHtml + '<br/><br/><br/>' + respo[0].cuerpo :
                    '<br/><br/><br/>' + respo[0].cuerpo
      });
    });
  }

  formControl(): void {
    this.mailForm = this.form.group({
      para: new FormControl('', [Validators.required, Validators.email]),
      cc: new FormControl('', [Validators.email]),
      cco: new FormControl('', [Validators.email]),
      respuesta: new FormControl('', []),
    });
  }

  get error(): any { return this.mailForm.controls; }

  gestionarBoton(): void {
    this.gestionar = !this.gestionar;
  }

  CargarArchivo(event): void {
    this.file = event.target.files[0];
    const formData = new FormData();
    formData.append('archivoCarga', this.file);

    this.mailService.postCargarArchivos(formData).subscribe((resp) => {
      this.archivosCargados.push({
        path: resp.data.path,
        name: resp.data.name
      });
    });
  }

  resposeEmail(mailForm): void {

    const newMail = new FormData();

    newMail.append( 'emailId' , this.data.data.emailId );
    newMail.append( 'cuentaEmailId' , this.data.data.email.cuentaEmailId );
    newMail.append( 'emailHtml' , mailForm.value.respuesta );
    newMail.append( 'adjuntos' , JSON.stringify(this.archivosCargados) );
    newMail.append( 'para' , mailForm.value.para );
    newMail.append( 'cc' , mailForm.value.cc );
    newMail.append( 'cco' , mailForm.value.cco );

    this.mailService.postresponderCorreo(newMail).subscribe((resp) => {
      this.alertsService.alertSuccess('Enviado', resp.data);
      this.dialog.close();
    });
  }

  descargarArchivo(archivo: any): void {

    const data = new FormData();

    data.append( 'url', archivo.url);
    data.append( 'name', archivo.nombre);

    this.mailService.download(data).subscribe((resp: any) => {
      importedSaveAs(resp, archivo.nombre);
    });

  }

}
