import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { AuthService } from '../../../../core/services/rest/auth.service';
import { catchError } from 'rxjs/operators';

const COMPRAS_URL = environment.COMPRAS_URL;
@Injectable({
  providedIn: 'root'
})
export class ArchivosService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }


    /**
   * @author Jose Vicente Silva
   * @createdate 2021-08-25
   * Servicio que lista todos los aplicativos del aplicativo ciu
   */
     getArchivosSolicitud( idSolicitud:Number, selected = false): Observable<any>{
      return this.http.get<any>(`${COMPRAS_URL}requestFiles/${idSolicitud}?${selected ? 'selected=true': ''}`)
      .pipe(
        catchError(this.handleError)
        );
    }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-08-25
   * Servicio que lista todos los aplicativos del aplicativo ciu
   */
    saveArchivosSolicitud(archivo: any): Observable<any>{
      return this.http.post<any>(`${COMPRAS_URL}requestFiles/`, archivo)
      .pipe(
        catchError(this.handleError)
        );
    }
  /**
   * @author Jose Vicente Silva
   * @createdate 2021-08-25
   * Servicio que lista todos los aplicativos del aplicativo ciu
   */
    updateArchivosSolicitud(estado: any, id: number): Observable<any>{
      return this.http.put<any>(`${COMPRAS_URL}requestFiles/${id}`, estado)
      .pipe(
        catchError(this.handleError)
        );
    }

    postCargarArchivos(params: FormData): Observable<any> {
      return this.http.post<any>(`${COMPRAS_URL}cargar-adjuntos`, params)
      .pipe(
        catchError(this.handleError)
        );
    }

    eliminarArchivo(id): Observable<any> {
      return this.http.delete<any>(`${COMPRAS_URL}requestFiles/${id}`)
      .pipe(
        catchError(this.handleError)
        );
    }

    download(params: FormData){
      return this.http.post(`${COMPRAS_URL}descargar-adjuntos`, params, {responseType: 'blob'});
    }
           /**
   * @author Jose Vicente Silva
   * @createdate 2021-08-25
   * Servicio que lista todos los aplicativos del aplicativo ciu
   */
  downloadOrder(path: any): Observable<any>{
    return this.http.post<any>(`${COMPRAS_URL}descargar-adjuntos`, path, {responseType: 'arraybuffer' as 'json'})
    .pipe(
      catchError(this.handleError)
      );
  }

}
