import { MaterialModule } from 'src/app/shared/material/material.module';
import { ScheduleReportComponent } from './modules/schedule-report/page/schedule-report/schedule-report.component';
import { DisabilitiesViewComponent } from './modules/disabilities/pages/disabilities-view/disabilities-view.component';
import { DisabilitiesResponseComponent } from './modules/disabilities/pages/disabilities-response/disabilities-response.component';
import { DisabilitiesComponent } from './modules/disabilities/pages/disabilities/disabilities.component';
import { VacationBossComponent } from './modules/vacations/page/vacation-boss/vacation-boss.component';
import { VacationsViewComponent } from './modules/vacations/page/vacations-view/vacations-view.component';
import { VacationsResponseComponent } from './modules/vacations/page/vacations-response/vacations-response.component';
import { VacationsComponent } from './modules/vacations/page/vacations/vacations.component';
import { LiquidationsResponseComponent } from './modules/liquidations/pages/liquidations-response/liquidations-response.component';
import { LiquidationsComponent } from './modules/liquidations/pages/liquidations/liquidations.component';
import { NovedadesViewComponent } from './modules/data-nomina/pages/novedades-view/novedades-view.component';
import { NovedadesResponseComponent } from './modules/data-nomina/pages/novedades-response/novedades-response.component';
import { NovedadesComponent } from './modules/data-nomina/pages/novedades/novedades.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NominaComponent } from './nomina.component';
import { NominaRoutingModule } from './nomina-routing.module';
import { AdminCampaignsComponent } from './modules/campaigns/admin-campaigns/admin-campaigns.component';
import { SaveCampaignsComponent } from './modules/campaigns/save-campaigns/save-campaigns.component';
import { SharedModule } from '../../shared/shared.module';
import { NoveltiesModule } from './modules/novelties/novelties.module';
import { FormsModule } from '@angular/forms';
import { InactiveDaysModule } from './modules/inactive-days/inactive-days.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { HttpClientModule } from '@angular/common/http';
import { ReverseVacationsComponent } from './modules/vacations/page/reverse-vacations/reverse-vacations.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { GeneralReportComponent } from './modules/reports/general-report/general-report.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NovedadesObservationComponent } from './modules/data-nomina/pages/novedades-observation/novedades-observation.component';
import { CancelVacationsComponent } from './modules/vacations/page/cancel-vacations/cancel-vacations.component';
import { VacationOperationManagerComponent } from './modules/vacations/page/vacation-operation-manager/vacation-operation-manager.component';
import { ReferralsReportComponent } from './modules/referrals/ReferralsReport/ReferralsReport.component';
import { ImportDesprendiblesComponent } from './modules/import-desprendibles/import-desprendibles.component';
import { VacationConsultantComponent } from './modules/vacations/page/vacation-consultant/vacation-consultant.component';
import { DisabilitiesConsultantComponent } from './modules/disabilities/pages/disabilities-consultant/disabilities-consultant.component';
import { NovedadesCosultantComponent } from './modules/data-nomina/pages/novedades-cosultant/novedades-cosultant.component';
import { LiquidationsImportComponent } from './modules/liquidations-import/liquidations-import.component';
import { ImportGreenBookComponent } from './modules/import-green-book/import-green-book.component';
@NgModule({
  declarations: [
    NominaComponent,
    AdminCampaignsComponent,
    CancelVacationsComponent,
    SaveCampaignsComponent,
    NovedadesComponent,
    NovedadesResponseComponent,
    NovedadesViewComponent,
    LiquidationsComponent,
    LiquidationsResponseComponent,
    VacationsComponent,
    VacationsResponseComponent,
    VacationsViewComponent,
    VacationBossComponent,
    DisabilitiesComponent,
    DisabilitiesResponseComponent,
    DisabilitiesViewComponent,
    ScheduleReportComponent,
    ReverseVacationsComponent,
    GeneralReportComponent,
    NovedadesObservationComponent,
    VacationOperationManagerComponent,
    ReferralsReportComponent,
    ImportDesprendiblesComponent,
    VacationConsultantComponent,
    DisabilitiesConsultantComponent,
    NovedadesCosultantComponent,
    LiquidationsImportComponent,
    ImportGreenBookComponent,
  ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        NominaRoutingModule,
        SharedModule,
        NoveltiesModule,
        InactiveDaysModule,
        PipesModule,
        HttpClientModule,
        FullCalendarModule,
        NgxMatSelectSearchModule,
        MatSelectModule,
        MatFormFieldModule
    ]
})
export class NominaModule {
}
