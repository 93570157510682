import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../../core/services/rest/auth.service';

const COACHING = environment.COACHING_URL;
const NOMINA = environment.NOMINA_URL;
const CRM = environment.CRM_URL;

@Injectable({
	providedIn: 'root'
})
export class CoachingService {
	private _refresh$ = new Subject<void>();
	constructor(
		private http: HttpClient,
		private authService: AuthService,
	) { }

	handleError(error: HttpErrorResponse): any {
		return throwError(error);
	}

	get refresh$() {
		return this._refresh$;
	}

	/**
	 * @author Cristian Gallo
	 * @createdate 2021-12-10
	 * Servicio que carga el tipo de indicadores.
	*/

	getSelects() {
		return this.http.get(`${COACHING}getSelects`)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
   * @author Cristian Gallo
   * @createdate 2021-12-10
   * Servicio que carga el tipo de indicadores.
  */

	getIndicadores() {
		return this.http.get(`${COACHING}getIndicador`)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	* @author Cristian Gallo
	* @createdate 2021-12-10
	* Servicio que carga el tipo de procesos.
	*/

	getProceso() {
		return this.http.get(`${COACHING}getTipoProceso`)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	* @author Cristian Gallo
	* @createdate 2021-12-20
	* Servicio que carga el tipo de compromiso.
	*/

	getCompromiso() {
		return this.http.get(`${COACHING}getCumpleCompromiso`)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	* @author Cristian Gallo
	* @createdate 2021-12-20
	* Servicio que carga el tipo de evolución.
	*/

	getEvolucion() {
		return this.http.get(`${COACHING}getPresentaEvolucion`)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	* @author Cristian Gallo
	* @createdate 2021-12-10
	* Servicio que carga el tipo de estado.
	*/
	getEstado() {
		return this.http.get(`${COACHING}getEstado`)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	* @author Cristian Gallo
	* @createdate 2021-12-10
	* Servicio que carga los cuartiles.
	*/

	getCuartil() {
		return this.http.get(`${COACHING}getCuartilInicial`)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	* @author Cristian Gallo
	* @createdate 2021-12-10
	* Servicio que carga las causas raiz.
	*/
	getCausaRaiz() {
		return this.http.get(`${COACHING}getCausaRaiz`)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	* @author Cristian Gallo
	* @createdate 2021-12-10
	* Servicio que busca al asesor por número de cedula.
	*/

	getAsesor(params) {
		return this.http.get(`${COACHING}getAsesor`, { params })
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	* @author Cristian Gallo
	* @createdate 2021-12-10
	* Servicio que carga las campañas creadas en CRM.
	* @author Juan David Guerrero Vargas
	* @updateMethod   10/03/2022
	*/

	getCampaings(rol) {
		let url: string = '';
		url = `${COACHING}getCampaignByAgent`;
		return this.http.get(url)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	* @author Cristian Gallo
	* @createdate 2021-12-10
	* Servicio que carga todos los acompañamientos creados para cargarlos en una tabla.
	* @param params parametros para realizar los diferentes filtros.
	*/

	getAcompanamientos(params) {
		return this.http.get(`${COACHING}getAcompañamientos`, { params })
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	* @author Cristian Gallo
	* @createdate 2021-12-10
	* Servicio que crea un nuevo acompañamiento.
	* @param data datos del acompañamiento para realizar la creación del mismo.
	*/

	crearCoachee(data) {
		return this.http.post<any>(`${COACHING}crearCoachee`, data)
			.pipe(
				catchError(this.handleError),
				tap(() => {
					this._refresh$.next();
				})
			);
	}

	/**
  * @author Cristian Gallo
  * @createdate 2021-12-20
  * Servicio que llama la inforcación del Coaching creado para continuar la gestión.
  */
	getSolicitudById(id) {
		return this.http.get(`${COACHING}getAcompañamientosById/${id}`)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * Metodo que obtiene un listado de todas las campañas disponibles
	 * @author Juan David Guerrero Vargas
	 * @create_date 16/05/2022   
	 * @returns void
	 */
	getAllCampaigns() {
		return this.http.get(`${COACHING}asignacionJefe/listselect`)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * Metodo que obtiene el listado de agentes relacionads a las campañas indicadas
	 * @author Juan David Guerrero Vargas
	 * @param str_campaign:string Variable en la que llega el listado de campañas por las que se consultaran los asesores 
	 * @create_date 16/05/2022  
	 * @returns void
	 */
	getAsesoresByCampaign(str_campaigns: string) {
		return this.http.get(`${COACHING}asignacionJefe/listasesores?${str_campaigns}`)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * Metodo que obtiene los datos principales de el jefe inmediato
	 * @param cedula:string numero de cedula del jefe inmediato 
	 * @returns Observable
	 */
	getJefeInmediato(cedula: string) {

		return this.http.get(`${COACHING}asignacionJefe/listboss/${cedula}`)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * Metodo que consulta los asesores disponibles por No. de cedula
	 * @param data: cedula del asesor a consultar
	 * @returns:Observable, para obtener la informaicon de la solciitud
	 */
	getAsesorByDocument(data) {
		return this.http.post<any>(`${COACHING}asignacionJefe/searchasesor`, { cedula: data })
			.pipe(
				catchError(this.handleError),
				tap(() => {
					this._refresh$.next();
				})
			);
	}

	/**
	 * Metodo que registra la asignacion de agentes  a jefe inmediato
	 * @author Juan David Guerrero Vargas
	 * @create_date 20/05/22   
	 * @param model:any Objeto con formato y data necesaria para la asignacion de agentes
	 * @returns Obserbavle - Respuesta del servidor al proceso ejecutado
	 */
	asignarAgentesJefeInmediato(model:any){
		return this.http.post<any>(`${COACHING}asignacionJefe/store`, model)
			.pipe(
				catchError(this.handleError),
				tap(() => {
					this._refresh$.next();
				})
			);
	}

	/**
  * @author Cristian Gallo
  * @createdate 2021-12-10
  * Servicio que crea un nuevo acompañamiento.
  * @param data datos del acompañamiento para realizar la creación del mismo.
  */
	cambiarSolicitud(data) {
		return this.http.post<any>(`${COACHING}estadoSolicitud`, data)
			.pipe(
				catchError(this.handleError),
				tap(() => {
					this._refresh$.next();
				})
			);
	}

	/**
	 * Metodo para listar los jefes inmediatos asignados
	 * @author Juan David Guerrero Vargas 
	 * @create_date 23/05/2022
	 * @params str_url:string complemento de la url para paginacion y filtrados 
	 * @return Observale ojeto al cual subscribirse para obtener el retorno del servicio REST  
	 */
	getListaJefeInmediato(str_url:string):Observable<any> {
		return this.http.get(`${COACHING}asignacionJefe/index?${str_url}`)
		.pipe(
			catchError(this.handleError)
		);
	}

	/**
	 * Metodo que permite cargar los datos sobre la aignaciones de un jefe
	 * @author Juan David Guerrero Vargas
	 * @create_date 23/05/2022
	 * @return Observable Objeto al cual subscribirse para obtener la respuesta del metodo REST   
	 */
	getAsignaciones(id_boss:string):Observable<any>{
		return this.http.get(`${COACHING}asignacionJefe/show/${id_boss}`)
		.pipe(
			catchError(this.handleError)
		);
	}

	/**
	* @author Cristian Gallo
	* @createdate 2021-12-20
	* Servicio que llama la inforcación del historial del Coaching.
	*/
	getHistorialById(params) {
		return this.http.get(`${COACHING}getHistorialById/${params.idSolicitud}`, { params })
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
  * @author Cristian Gallo
  * @createdate 2022-01-05
  * Servicio que llama la inforcación del historial por Id.
  */
	getHistoricoById(data) {
		return this.http.get(`${COACHING}getHistorialId/${data.idHistorial}`)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
  * @author Cristian Gallo
  * @createdate 2022-01-18
  * Servicio que llama el reporte para descargar.
  */
	getReport(params) {
		return this.http.get(`${COACHING}descargaReporteGeneral`, { params, responseType: 'blob' })
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
   * @author Cristian Gallo
   * @createdate 2022-01-18
   * Servicio que llama el reporte para descargar.
   */
	senNotificationSupervisor() {
		const data = {
			mensaje: `Tienes un acompañamiento a vencer mañana ${moment(new Date()).add(1, 'd').format('DD/MM/yyyy')} con ID-`,
			ruta: '/mios/coaching/coaching/0',
		}
		return this.http.get(`${COACHING}sendNotificationSupervisor`, { params: data })
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * @author Cristian Gallo
	 * @createdate 2022-01-26
	 * Servicio que confirma si el asesor acepta o rechaza el acompañamiento generado.
	*/
	solicitudAsesor(data) {
		return this.http.post<any>(`${COACHING}estadoSolicitudAsesor`, data)
			.pipe(
				catchError(this.handleError),
				tap(() => {
					this._refresh$.next();
				})
			);
	}

	/**
	 * @author Cristian Gallo
	 * @createdate 2022-02-15
	 * Servicio que confirma si la auditoría tiene un acompañamiento.
	*/
	coachCalidad(idAuditoria) {
		return this.http.get(`${COACHING}validacionAcompañamientoCalidad/${idAuditoria}`)
			.pipe(
				catchError(this.handleError)
			);
	}

	updateAssignedBoss(id_assignacion:string, modelUpdate:any){
		return this.http.put<any>(`${COACHING}asignacionJefe/update/${id_assignacion}`, modelUpdate)
		.pipe(
			catchError(this.handleError),
			tap(() => {
				this._refresh$.next();
			})
		);
	}

}
