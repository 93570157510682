import { ActionReducerMap } from "@ngrx/store";
import { AcompañamientoState } from "../core/states/acompañamiento.state";
import { AuditoriaState } from "../core/states/auditoria.state";
import { FiltrosState } from "../core/states/filtros.state";
import { HistorialState } from "../core/states/historial.state";
import { HistorialByIdState } from "../core/states/historialById.state";
import { SelectsState } from "../core/states/selects.state";
import { SolicitudState } from "../core/states/solicitud.state";
import { acompañamientoReducer } from "./reducers/acompañamiento.reducer";
import { auditoriaReducer } from "./reducers/auditoria.reducer";
import { historialByIdReducer, historialReducer } from "./reducers/historial.reducer";
import { selectsReducer } from "./reducers/selects.reducer";
import { solicitudReducer } from "./reducers/solicitud.reducer";

export interface CoachingState{
    auditorias: AuditoriaState,
    selects:SelectsState,
    acompañamientos:AcompañamientoState,
    solicitud:SolicitudState
    historial:HistorialState,
    historialById:HistorialByIdState,
}

export const ROOT_REDUCERS_COACHING:ActionReducerMap<CoachingState>={
    auditorias:auditoriaReducer,
    selects: selectsReducer,
    acompañamientos:acompañamientoReducer,
    solicitud:solicitudReducer,
    historial: historialReducer,
    historialById: historialByIdReducer
}