<h2 mat-dialog-title>{{data.title}}
  <button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>
</h2>
 
<form class="row form" [formGroup]="userForm" (ngSubmit)="editReport(userForm)" style="padding-left: 10px;">

  <mat-dialog-content style="padding-left: 3%;" class="mat-typography">
    <section class="example-section">
      <div class="card-data" formArrayName="reports">
        <div class="card-check" *ngFor="let reportCheck of reportsArray.controls; let i = index;">
          <div>
            <mat-checkbox class="example-margin col-6" [checked]="reportCheck.value.report"
              (change)="validateAction($event,i)">{{reportCheck.value.name}}</mat-checkbox>
          </div>
        </div>
      </div>
    </section>
  </mat-dialog-content>

  <mat-dialog-actions style="height: 48px; padding-bottom: 70px;" align="end">
    <button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button mat-raised-button type="submit" color="primary" disabled="{{ userForm.invalid }}">Guardar</button>
  </mat-dialog-actions>

</form>