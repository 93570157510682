import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../../../core/services/rest/auth.service';
import { throwError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

const COMPRAS_URL = environment.COMPRAS_URL;

@Injectable({
  providedIn: 'root'
})
export class CompaniaService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-08-25
   * Servicio que lista todos los aplicativos del aplicativo ciu
   */
  getCompany(): Observable<any>{
    return this.http.get<any>(`${COMPRAS_URL}company/?paginate=0`)
    .pipe(
      catchError(this.handleError)
      );
  }

   

}
