import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { NetworkUsersService } from '../../services/rest/network-users.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-update-security-questions',
  templateUrl: './update-security-questions.component.html',
  styleUrls: ['./update-security-questions.component.sass']
})
export class UpdateSecurityQuestionsComponent implements OnInit {
  new_questions_form: FormGroup
  questions_form: FormGroup
  questions: any[] = []
  logged_user: any
  questions_labels: any = { first: "", second: "", third: "" }
  match_answers: boolean = false

  constructor(private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, private authService: AuthService, private networkUsersService: NetworkUsersService,
    private dialogRef: MatDialogRef<UpdateSecurityQuestionsComponent>, private alertService: AlertsService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.validateLastQuestionsUpdate()
    this.initializeNewQuestionsForm()
    this.initializeQuestionsForm()
    this.getQuestionsList()
  }

  initializeNewQuestionsForm() {
    this.new_questions_form = this.formBuilder.group({
      first_question: this.formBuilder.group({
        id: [],
        question_id: ['', [Validators.required]],
        answer: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      }),
      second_question: this.formBuilder.group({
        id: [],
        question_id: ['', [Validators.required]],
        answer: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      }),
      third_question: this.formBuilder.group({
        id: [],
        question_id: ['', [Validators.required]],
        answer: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      }),
      fourth_question: this.formBuilder.group({
        id: [],
        question_id: ['', [Validators.required]],
        answer: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      }),
      fifth_question: this.formBuilder.group({
        id: [],
        question_id: ['', [Validators.required]],
        answer: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      }),
      sixth_question: this.formBuilder.group({
        id: [],
        question_id: ['', [Validators.required]],
        answer: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      }),
      seventh_question: this.formBuilder.group({
        id: [],
        question_id: ['', [Validators.required]],
        answer: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      }),
      eighth_question: this.formBuilder.group({
        id: [],
        question_id: ['', [Validators.required]],
        answer: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      }),
    })
    this.setQuestionFormValue()
    this.setNewQuestionsFormValue()
    this.new_questions_form.valueChanges.subscribe(() => {
      this.updateOptions()
    });

  }

  /**
* Metódo que obtiene las preguntas almacenadas aleatoriamente y las asigna al formulario y a los labels
* @param event
* @author Juan Carlos Alonso
* @createdate 15-05-2024
*/
  setQuestionFormValue() {
    this.networkUsersService.getUserSecurityQuestions(0).subscribe(resp => {
      //Se asigna el valor de las ids de las preguntas asociadas al usuario
      this.first_question_controls.id.setValue(resp[0]?.id)
      this.second_question_controls.id.setValue(resp[1]?.id)
      this.third_question_controls.id.setValue(resp[2]?.id)
      this.questions_labels = { first: resp[0]?.question_text, second: resp[1]?.question_text, third: resp[2]?.question_text }
    })
  }

  setNewQuestionsFormValue() {
    this.networkUsersService.getUserSecurityQuestions(1).subscribe(resp => {
      //Se asigna el valor de las ids de las preguntas asociadas al usuario
      this.getNewQuestionControls('first_question').id.setValue(resp[0]?.id)
      this.getNewQuestionControls('second_question').id.setValue(resp[1]?.id)
      this.getNewQuestionControls('third_question').id.setValue(resp[2]?.id)
      this.getNewQuestionControls('fourth_question').id.setValue(resp[3]?.id)
      this.getNewQuestionControls('fifth_question').id.setValue(resp[4]?.id)
      this.getNewQuestionControls('sixth_question').id.setValue(resp[5]?.id)
      this.getNewQuestionControls('seventh_question').id.setValue(resp[6]?.id)
      this.getNewQuestionControls('eighth_question').id.setValue(resp[7]?.id)
    })
  }

  /**
 * @author Juan Carlos Alonso
 * @createdate 29-05-2024
 * Método que obtiene la lista de preguntas de seguridad
 */
  getQuestionsList() {
    this.authService.getSecurityQuestionsList().subscribe(resp => {
      this.questions = resp
    })
  }

   /**
  * Metódo que valida si la contraseña ya ha sido actualizada el día de hoy, si es asi restringe el acceso al formulario hasta que pasen 24 horas
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
   validateLastQuestionsUpdate(){
    this.networkUsersService.validateLastUpdates(1).subscribe(resp => {
      if (resp.data?.restriction) {
        this.alertService.alertInfo('¡Atención!', resp.data?.message)
        this.dialogRef.close()
      }
    })
  }


  /**
* @author Juan Carlos Alonso
* @createdate 29-05-2024
* Método que retorna los controles de un grupo de preguntas
* @param group nombre del grupo del formulario
*/
  getNewQuestionControls(group: string) {
    const QuestionGroup = this.new_questions_form.get(group) as FormGroup
    return QuestionGroup ? QuestionGroup.controls : {}
  }
  /**
* @author Juan Carlos Alonso
* @createdate 29-05-2024
* Método que desactiva las opciones de las preguntas que ya han sido seleccionadas, y asi mismo las activa al deseleccionar
*/
  updateOptions() {
    const selectedValues: string[] = [];
    const formControls = this.new_questions_form.controls;

    for (const key in formControls) {
      if (formControls.hasOwnProperty(key) && key.endsWith('_question')) {
        const questionGroup = formControls[key] as FormGroup;
        const questionIdControl = questionGroup.controls['question_id'];
        if (questionIdControl) {
          selectedValues.push(questionIdControl.value);
        }
      }
    }

    this.questions.forEach(question => {
      question['disabled'] = selectedValues.includes(question.id);
    });
  }


  /**
 * Metódo que inicializa el formulario de las preguntas
 * @param event
 * @author Juan Carlos Alonso
 * @createdate 15-05-2024
 */
  initializeQuestionsForm() {
    this.questions_form = this.formBuilder.group({
      first_question: this.formBuilder.group({
        id: ['', []],
        answer: ['', [Validators.required]]
      }),
      second_question: this.formBuilder.group({
        id: ['', []],
        answer: ['', [Validators.required]]
      }),
      third_question: this.formBuilder.group({
        id: ['', []],
        answer: ['', [Validators.required]]
      })
    })
  }

  /**
* Metódo que retorna los controles de la primera pregunta
* @param event
* @author Juan Carlos Alonso
* @createdate 15-05-2024
*/
  get first_question_controls() {
    const firstQuestionGroup = this.questions_form.get('first_question') as FormGroup
    return firstQuestionGroup ? firstQuestionGroup.controls : {}
  }

  /**
  * Metódo que retorna los controles de la segunda pregunta
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
  get second_question_controls() {
    const secondQuestionGroup = this.questions_form.get('second_question') as FormGroup
    return secondQuestionGroup ? secondQuestionGroup.controls : {}
  }

  /**
  * Metódo que retorna los controles de la tercera pregunta
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
  get third_question_controls() {
    const thirdQuestionGroup = this.questions_form.get('third_question') as FormGroup
    return thirdQuestionGroup ? thirdQuestionGroup.controls : {}
  }


  /**
* Metódo envía las respuestas ingresadas y valida si se puede continuar al cambio de contraseña
* @param event
* @author Juan Carlos Alonso
* @createdate 15-05-2024
*/
  validateAnswers(stepper) {
    if (this.questions_form.invalid) {
      this.questions_form.markAllAsTouched()
      return
    }
    this.networkUsersService.validateSecurityQuestionsAnswers(this.questions_form.value).subscribe((resp: any) => {
      if (resp.data?.confirmation) {
        this.match_answers = true
        this.cdr.detectChanges();
        stepper.next()
      }
    },
      (error) => {
        this.alertService.alertError('¡Atención!', error.error.error)
      }
    )
  }



  /**
* @author Juan Carlos Alonso
* @createdate 29-05-2024
* Método que envía y actualiza las preguntas de seguridad
*/
  save() {
    if (this.new_questions_form.invalid) {
      this.new_questions_form.markAllAsTouched()
      return
    }

    this.networkUsersService.updateUserSecurityAnswers(this.new_questions_form.value).subscribe((resp: any) => {
      Swal.fire({
        html: `${resp.data?.message}`,
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
      this.dialogRef.close();
    }, (error) => {
      Swal.fire({
        html: `${error.error.error}`,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
    )

  }
}
