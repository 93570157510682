<button style="float: right" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross-small fs-4"></i>
</button>
<div style="padding-right: 5rem; padding-left: 5rem; padding-top: 2rem; padding-bottom: 1rem;">
  <h2 class="fw-bold" mat-dialog-title>
    {{ data.title }}
  </h2>
  <mat-divider class="mb-5"></mat-divider>
  <form [formGroup]="campaignForm" (ngSubmit)="saveCampaign(campaignForm)">
    <mat-dialog-content class="mat-typography">
      <div class="row">
        <div class="col-12">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Empresa *</mat-label>
            <mat-select formControlName="company">
              <mat-option *ngFor="let item of companies" [value]="item.id">
                {{item?.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="error.company.errors"><span *ngIf="error.company.errors.required">¡Advertencia! Este campo
                es
                requerido</span></mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Nombre de la campaña</mat-label>
            <input style="text-transform: capitalize" matInput type="text" placeholder="Escribe el nombre de la Campaña"
              formControlName="name" required cdkFocusInitial />
            <mat-error *ngIf="error.name.errors"><span *ngIf="error.name.errors.required">¡Advertencia! Este campo es
                requerido</span></mat-error>
            <mat-error *ngIf="error.name.errors"><span *ngIf="error.name.errors.minlength">¡Aún falta! Recibimos mínimo
                3
                caracteres</span></mat-error>
            <mat-error *ngIf="error.name.errors"><span *ngIf="error.name.errors.maxlength">¡Cuidado! Solo puedes tener
                un
                máximo de 40 caracteres</span></mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Código de la campaña</mat-label>
            <input class="w-100" style="text-transform: lowercase" matInput type="text" placeholder="Escribe código"
              formControlName="code" required />
            <mat-error *ngIf="error.code.errors"><span *ngIf="error.code.errors.required">¡Advertencia! Este campo es
                requerido</span></mat-error>
            <mat-error *ngIf="error.code.errors"><span *ngIf="error.code.errors.minlength">¡Aún falta! Recibimos mínimo
                3
                caracteres</span></mat-error>
            <mat-error *ngIf="error.code.errors"><span *ngIf="error.code.errors.maxlength">¡Cuidado! Solo puedes tener
                un
                máximo de 5 caracteres</span></mat-error>
          </mat-form-field>
        </div>
        <br>
        <br>
        <div>
          <span>Los campos marcados con el asterisco (*) son obligatorios</span>
        </div>
      </div>
    </mat-dialog-content>
    <div class="row mt-5">
      <div class="col-6">
        <button style="height: 48px;" class="w-100 fw-semibold" mat-stroked-button mat-dialog-close>Cancelar</button>
      </div>
      <div class="col-6">
        <button style="height: 48px;" class="w-100 fw-semibold" mat-raised-button type="submit" color="primary"
          disabled="{{ campaignForm.invalid }}">
          Guardar
        </button>
      </div>
    </div>
  </form>
</div>
