import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

const CIU_URL = environment.CRM_URL;

@Injectable({
  providedIn: 'root'
})
export class CrmService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  /**
   * @author Sebastián Daza
   * @createdate 2022-03-03
   * Consulta para los formularios de CRM que tenga campos de tipo 'agendamiento'
   */
  getFormsCRM(): Observable<any>{
    return this.http.get<any>(`${CIU_URL}sectionCrmAgenda`)
    .pipe(
      catchError(this.handleError)
      );
  }

}
