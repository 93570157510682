import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportsComponent } from './reports.component';
import { UserAssignmentReportComponent } from './modules/admin/user-assignment-report/user-assignment-report.component';
import { ReportShowComponent } from './modules/report/report-show/report-show.component';

const routes: Routes = [
  { path: '', component: ReportsComponent },
  { path: 'asignacion-reportes', component: UserAssignmentReportComponent },
  { path: 'ver-reporte', component: ReportShowComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
