<div class="content">

  <div class="header">
    <button mat-icon-button (click)="back()" btn-regresar class="mat-icon notranslate material-icons mat-icon-no-color">
      <div class="display:flex">
        <span class="dot">
          <i class="fi-rr-angle-small-left" style="font-size:24px;color: white;"></i>
        </span>
        <span
          style="vertical-align: super;font: normal normal 600 16px/25px Poppins;letter-spacing: 0px;color: #353535;opacity: 1;">Regresar</span>

      </div>
    </button>
    <h2 class="col-8" style="margin-top: 64px; display: flex; font-size: 38px !important; font-weight: 700;color: #353535; word-break: break-all;line-height: 36px;">
      {{title}} {{((stateOpportunityId == 1) || (stateOpportunityId == 2) || (stateOpportunityId == 3)) ? '|' : ''}} &nbsp;
      <h2 *ngIf="stateOpportunityId == 1" style="color: #60BF66"> Ganada</h2>
      <h2 *ngIf="stateOpportunityId == 2" style="color: #D14A4A"> Perdida</h2>
      <h2 *ngIf="stateOpportunityId == 3" style="color: #D9A847"> Desierta</h2>
    </h2>
    <p style="color:#353535;margin-top:24px;font-size: 14px !important;" *ngIf="userName"><b>Comercial Asignado:</b>&nbsp;{{userName}}</p>
  </div>

  <!-- Matstpeeer-->
  <mat-horizontal-stepper #stepper [linear]="isLinear" [selectedIndex]="selectedIndex" (selectionChange)="selectionChange($event)">
    <!-- Paso prospecto -->
    <mat-step [stepControl]="firstFormGroup" [editable]="isEditable">
      <form [formGroup]="firstFormGroup" (ngSubmit)="saveOportunidad(0)" style="margin-top: 44px;">
        <ng-template matStepLabel>Prospecto</ng-template>
        <div class="row col-12" >
          <mat-form-field appearance="fill" class="col-md-3">
            <mat-label>Nombre de la oportunidad</mat-label>
            <input matInput maxlength="50" type="text" id="Notas" formControlName="nameOpportunity"
              >
            <mat-error *ngIf="error.nameOpportunity.errors"><span
                *ngIf="error.nameOpportunity.errors.required">¡Advertencia! Este campo es
                requerido</span></mat-error>
            <mat-error *ngIf="error.nameOpportunity.errors"><span
                *ngIf="error.nameOpportunity.errors.maxlength">¡Advertencia! Este campo solo permite
                50 caracteres
                </span></mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-3">
            <mat-label>Empresa</mat-label>
            <mat-select id="empresa_asociada" formControlName="empresa_asociada" >
              <mat-option *ngFor="let item of company" [value]="item?.id">{{item?.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="error.empresa_asociada.errors"><span
                *ngIf="error.empresa_asociada.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="col-3">
            <mat-label>Canal</mat-label>
            <mat-select id="canal" formControlName="canal">
              <mat-option *ngFor="let item of canal" [value]="item?.id">{{item?.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="error.canal.errors"><span
              *ngIf="error.canal.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="col-3" *ngIf="rolUser == 'comercial'" >
            <mat-label>Comercial Asignado</mat-label>
            <mat-select id="comercial" formControlName="comercial" [disabled]="true">
              <mat-option *ngFor="let item of comercialsData" [value]="item?.rrhh_id">{{item?.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="error.comercial.errors"><span
              *ngIf="error.comercial.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-3" *ngIf="rolUser == 'administrador'">
            <mat-label>Comercial Asignado</mat-label>
            <mat-select id="comercial" formControlName="comercial">
              <mat-option *ngFor="let item of comercialsData" [value]="item?.rrhh_id">{{item?.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="error.comercial.errors"><span
              *ngIf="error.comercial.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
          </mat-form-field>
        </div>

        <div class="row" style="margin-top: 64px !important; margin-right: 0;" *ngIf="idoportunidad">
          <div class=" col-3" align="rigth" (click)="RegistranotaP(1)" [hidden]="typeRequest == 'view'">
            <h3 style="font-family: 'Poppins';font-weight: 700;font-size: 28px !important;color: #353535;">Notas <i class="fi-rr-add"
              style="margin-left: 10px;font-size: 18px !important;"></i></h3>
          </div>
          <div class=" col-3" align="rigth"  [hidden]="typeRequest !== 'view'">
            <h3 style="font-family: 'Poppins';font-weight: 700;font-size: 28px !important;color: #353535;">Notas</h3>
          </div>
          <div class="col-md-6"></div>
          <div class="col-3" align="left" (click)="HistoricoNotas()" style="text-align: end;padding-right:0;font-weight: 600;"> Histórico de notas <i class="fi-rr-eye" style="margin-left: 10px;font-size: 16px!important;"></i></div>
        </div>

        <mat-divider style="border: 1px solid #D8D8D8;"></mat-divider>
        <app-by-step
          [notes] = "notes?.data['1']"
          [rolUser] = "rolUser"
          [oportunity] = "idoportunidad"
          [step]="1"
          [typeView]="typeRequest"
        ></app-by-step>

        <div class="col-12" align="right" *ngIf="typeRequest !== 'view'" style="margin-top: 50px;">
          <button mat-raised-button type="button" class="cancelButton" color="light"(click)="opcionCancelar()" *ngIf="typeRequest !== 'edit' && typeRequest !== 'view'">Cancelar</button>
          <button mat-raised-button type="button" class="cancelButton" color="light"(click)="deleteOpportunityById()" *ngIf="typeRequest == 'edit'">Cancelar</button>
          <button mat-raised-button type="submit" class="save" color="ligth"
            disabled='{{firstFormGroup.invalid}}' *ngIf="rolUser == 'administrador'">Guardar</button>
          <button mat-raised-button type="button" class="download" color="primary" (click)="saveOportunidad(1)"
            disabled='{{firstFormGroup.invalid}}' matStepperNext> Siguiente </button>
        </div>
        <div class="col-12" align="right" *ngIf="typeRequest == 'view'" style="margin-top: 50px;">
          <button mat-raised-button type="button" class="cancelButton" color="ligth"
            (click)="viewBack()">Cancelar</button>
          <button mat-raised-button type="button" class="download" color="primary"
             matStepperNext> Siguiente </button>
        </div>

      </form>

    </mat-step>
    <!-- Paso acercamiento comercial -->
    <mat-step [stepControl]="secondFormGroup" [editable]="isEditable">
      <form [formGroup]="secondFormGroup" (ngSubmit)="registrarAcercamiento(0)" style="margin-top: 44px;">
        <ng-template matStepLabel>Acercamiento Comercial</ng-template>
        <mat-form-field appearance="fill" class="col-md-3">
          <mat-label>Potencial compra</mat-label>
          <mat-select id="potencial_compra" formControlName="potencial_compra" (ngModelChange)="ViewData()">
            <mat-option [value]="'1'">Si</mat-option>
            <mat-option [value]="'0'">No</mat-option>
          </mat-select>
          <mat-error *ngIf="errorSecond.potencial_compra.errors"><span
            *ngIf="errorSecond.potencial_compra.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
        </mat-form-field>
        &nbsp;
        <mat-form-field class="col-md-3" appearance="fill" *ngIf="stfecha == true">
          <mat-label>Fecha de proceso</mat-label>
          <input matInput [matDatepicker]="pickerp" formControlName="fecha_proceso"
            matTooltip="En este espacio se debe colocar la fecha en el que el cliente solicita la cotizacion del servicio/producto">

          <mat-datepicker-toggle matSuffix [for]="pickerp">
            <span matDatepickerToggleIcon class="fi-rr-calendar" style="font-size: 22px !important;"></span>
          </mat-datepicker-toggle>
          <mat-datepicker #pickerp></mat-datepicker>
          <mat-error *ngIf="errorSecond.fecha_proceso.errors"><span
            *ngIf="errorSecond.fecha_proceso.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
        </mat-form-field>
        &nbsp;
        &nbsp;
        <mat-form-field appearance="fill" class="col-md-3">
          <mat-label>Contacto Comercial</mat-label>
          <mat-select id="cont_comercial" formControlName="cont_comercial">
            <mat-option *ngFor="let item of contactos" [value]="item?.id">{{item?.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="errorSecond.cont_comercial.errors"><span
            *ngIf="errorSecond.cont_comercial.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
        </mat-form-field>
        &nbsp;
        <i class="fi-rr-add" (click)="AgregarContacto()" [hidden]="typeRequest == 'view'"></i>



        <div class="row" style="margin-top: 64px !important; margin-right: 0;" *ngIf="idoportunidad">
          <div class=" col-3" align="rigth" (click)="RegistranotaP(2)" [hidden]="typeRequest == 'view'">
            <h3 style="font-family: 'Poppins';font-weight: 700;font-size: 28px !important;color: #353535;">Notas <i class="fi-rr-add"
              style="margin-left: 10px;font-size: 18px !important;"></i></h3>
          </div>
          <div class=" col-3" align="rigth"  [hidden]="typeRequest !== 'view'">
            <h3 style="font-family: 'Poppins';font-weight: 700;font-size: 28px !important;color: #353535;">Notas</h3>
          </div>
          <div class="col-md-6"></div>
          <div class="col-3" align="left" (click)="HistoricoNotas()" style="text-align: end;padding-right:0;font-weight: 600;"> Histórico de notas <i class="fi-rr-eye" style="margin-left: 10px;font-size: 16px!important;"></i></div>
        </div>

        <mat-divider style="border: 1px solid #D8D8D8;"></mat-divider>
        <app-by-step
          [notes] = "notes?.data['2']"
          [rolUser] = "rolUser"
          [oportunity] = "idoportunidad"
          [step]="2"
          [typeView]="typeRequest"
        ></app-by-step>
        <div class="col-12" align="right" *ngIf="typeRequest !== 'view'" style="margin-top: 50px;">
          <button mat-raised-button type="button" class="cancelButton" color="light"(click)="opcionCancelar()" *ngIf="typeRequest !== 'edit' && typeRequest !== 'view'">Cancelar</button>
          <button mat-raised-button type="button" class="cancelButton" color="light"(click)="deleteOpportunityById()" *ngIf="typeRequest == 'edit'">Cancelar</button>
          <button mat-raised-button type="submit" class="save" color="light" disabled='{{secondFormGroup.invalid}}' *ngIf="rolUser == 'administrador'">Guardar</button>
          <button mat-raised-button type="button" class="download" color="primary" (click)="registrarAcercamiento(1)" disabled='{{secondFormGroup.invalid}}'
            matStepperNext> Siguiente </button>
        </div>

        <div class="col-12" align="right" *ngIf=" typeRequest == 'view'" style="margin-top: 50px;">
          <button mat-raised-button type="button" class="cancelButton" color="ligth"
            (click)="viewBack()">Cancelar</button>
          <button mat-raised-button type="button" class="download" color="primary"
             matStepperNext> Siguiente </button>
        </div>
      </form>
    </mat-step>
    <!-- Paso propuesta -->
    <mat-step [stepControl]="thirddFormGroup" [editable]="isEditable">
      <form [formGroup]="thirddFormGroup" (ngSubmit)="saveThirddForm(0)" class="row col-12" style="margin-top: 44px;">
        <ng-template matStepLabel>Construcción de Propuesta</ng-template>

        <mat-form-field appearance="fill" class="col-md-3">
          <mat-label>Tipo de servicio</mat-label>
          <mat-select id="tiposervicio" multiple formControlName="tiposervicio" (selectionChange)="changeServicio($event)">
            <mat-option *ngFor="let item of linea" [value]="item?.id">{{item?.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="errort.tiposervicio.errors"><span *ngIf="errort.tiposervicio.errors.required">¡Advertencia! Este
            campo es requerido</span></mat-error>
        </mat-form-field>

        <mat-form-field class="col-3" appearance="fill" *ngIf="condicionCual" >
          <mat-label>Cual</mat-label>
          <input matInput formControlName="cual">
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-md-3">
          <mat-label>Unidad de facturación</mat-label>
          <mat-select id="tipofacturacion" multiple formControlName="tipofacturacion" (selectionChange)="changeUnitFact($event)">
            <mat-option *ngFor="let item of facturacion" [value]="item?.id">{{item?.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="errort.tipofacturacion.errors"><span *ngIf="errort.tipofacturacion.errors.required">¡Advertencia! Este
            campo es requerido</span></mat-error>
        </mat-form-field>

        <mat-form-field class="col-3" appearance="fill" *ngIf="cualUnidad" >
          <mat-label>Cual</mat-label>
          <input matInput formControlName="cualunidad">
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-3">
          <mat-label>Moneda</mat-label>
          <mat-select id="tipomoneda" (selectionChange)="setValidatorsForMonthlyRate()" formControlName="tipomoneda">
            <mat-option [value]="'0'">Peso Colombiano</mat-option>
            <mat-option [value]="'1'">Dólares</mat-option>
            <mat-option [value]="'2'">Peso Mexicano</mat-option>
            <mat-option [value]="'2'">Peso Chileno</mat-option>
            <mat-option [value]="'3'">Soles</mat-option>
            <mat-option [value]="'4'">Reales</mat-option>
            <mat-option [value]="'5'">Euros</mat-option>
          </mat-select>
          <mat-error *ngIf="errort.tipomoneda.errors"><span *ngIf="errort.tipomoneda.errors.required">¡Advertencia! Este
            campo es requerido</span></mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="thirddFormGroup.get('tipomoneda').value !== null && thirddFormGroup.get('tipomoneda').value !== '0' " class="col-md-3">
          <mat-label>Tasa de cambio</mat-label>
          <input style="text-transform: capitalize;" matInput maxlength="11" type="number"
            formControlName="tasa_mensual" (keypress)="keyDecimalPress($event)" (input)="calculateFacturacionExtranjera()" >
            <mat-error *ngIf="errort.tasa_mensual.errors">
              <span *ngIf="errort.tasa_mensual.errors.required">Por favor ingrese un valor para la tasa mensual</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="thirddFormGroup.get('tipomoneda').value !== null && thirddFormGroup.get('tipomoneda').value !== '0' " class="col-md-3">
          <mat-label>Facturacion Mensual (otra moneda)</mat-label>
          <input style="text-transform: capitalize;" matInput maxlength="11" type="number"
            formControlName="facturacion_diferente_pesos" (keypress)="keyDecimalPress($event)" (input)="calculateFacturacionExtranjera()" >
            <mat-error *ngIf="errort.facturacion_diferente_pesos.errors">
              <span *ngIf="errort.facturacion_diferente_pesos.errors.required">¡Advertencia! Este campo es requerido</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-md-3">
          <mat-label>Facturacion Mensual COP</mat-label>
          <input style="text-transform: capitalize;" matInput maxlength="11" type="number" id="facturacion"
                 formControlName="facturacion" (keypress)="keyDecimalPress($event)" (input)="calcContrato()">
          <mat-error *ngIf="errort.facturacion.errors"><span *ngIf="errort.facturacion.errors.required">¡Advertencia! Este
              campo es requerido</span></mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-md-3">
          <mat-label>Duracion del contrato (meses)</mat-label>
          <input style="text-transform: capitalize;" matInput maxlength="11" type="text" id="duracion_contrato"
            formControlName="duracion_contrato" (keypress)="keyPress($event)" ngPattern="/^@[0-9,-]+[0-9]\1*$/"
            (input)="calcContrato()">
            <mat-error *ngIf="errort.duracion_contrato.errors"><span *ngIf="errort.duracion_contrato.errors.required">¡Advertencia! Este
              campo es requerido</span></mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" disabled readonly class="col-md-3">
          <mat-label>Valor total del contrato</mat-label>
          <input matInput id="valor_contrato" ngModel formControlName="valor_contrato" disabled readonly>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-3">
          <mat-label>Número de agentes</mat-label>
          <input matInput type="number" id="n_agentes" formControlName="n_agentes" (keypress)="keyPress($event)">
          <mat-error *ngIf="errort.n_agentes.errors"><span *ngIf="errort.n_agentes.errors.required">¡Advertencia! Este
              campo es requerido</span></mat-error>
        </mat-form-field>

        <mat-form-field class="col-md-3" appearance="fill">
          <mat-label>Fecha estimada de inicio</mat-label>
          <input matInput [matDatepicker]="picker1" formControlName="fecha_estimada_inicio" >

          <mat-datepicker-toggle matSuffix [for]="picker1">
            <span matDatepickerToggleIcon class="fi-rr-calendar" style="font-size: 22px !important;"></span>
          </mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <mat-error *ngIf="errort.fecha_estimada_inicio.errors"><span *ngIf="errort.fecha_estimada_inicio.errors.required">¡Advertencia! Este
            campo es requerido</span></mat-error>
        </mat-form-field>

        <mat-form-field class="col-md-3" appearance="fill">
          <mat-label>Fecha entrega de propuesta</mat-label>
          <input matInput [matDatepicker]="picker2" formControlName="fecha_entrega_propuesta" >

          <mat-datepicker-toggle matSuffix [for]="picker2">
            <span matDatepickerToggleIcon class="fi-rr-calendar" style="font-size: 22px !important;"></span>
          </mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <mat-error *ngIf="errort.fecha_entrega_propuesta.errors"><span *ngIf="errort.fecha_entrega_propuesta.errors.required">¡Advertencia! Este
            campo es requerido</span></mat-error>
        </mat-form-field>

        <mat-form-field class="col-md-3" appearance="fill">
          <mat-label>Fecha de adjudicación</mat-label>
          <input matInput [matDatepicker]="picker3" formControlName="fecha_adjudicacion">

          <mat-datepicker-toggle matSuffix [for]="picker3">
            <span matDatepickerToggleIcon class="fi-rr-calendar" style="font-size: 22px !important;"></span>
          </mat-datepicker-toggle>
          <mat-datepicker #picker3></mat-datepicker>
          <mat-error *ngIf="errort.fecha_adjudicacion.errors"><span *ngIf="errort.fecha_adjudicacion.errors.required">¡Advertencia! Este
            campo es requerido</span></mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-3">
          <mat-label>margen de Mano de obra % </mat-label>
          <input matInput type="number" id="margen_mano_obra"
            formControlName="margen_mano_obra" (keypress)="keyDecimalPress($event)">
            <mat-error *ngIf="errort.margen_mano_obra.errors">
              <span *ngIf="errort.margen_mano_obra.errors.required">
                ¡Advertencia! Este campo es requerido
              </span>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-3">
          <mat-label>Número de puestos</mat-label>
          <input style="text-transform: capitalize;" matInput type="text" id="n_puesto" formControlName="n_puestos"
            (keypress)="keyPress($event)">
            <mat-error *ngIf="errort.n_puestos.errors"><span *ngIf="errort.n_puestos.errors.required">¡Advertencia! Este
              campo es requerido</span></mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-3">
          <mat-label>Probabilidad de cierre</mat-label>
          <mat-select id="probabilidadControl" formControlName="probabilidadControl">
            <mat-option [value]="30" matTooltip="Entrega de propuesta">
              30% <i class="fi fi-rr-info" style="margin-left: 10px;"></i>
            </mat-option>
            <mat-option [value]="50" matTooltip="Inicio de negociación">
              50% <i class="fi fi-rr-info" style="margin-left: 10px;"></i>
            </mat-option>
            <mat-option [value]="70" matTooltip="Negociación encaminada a un posible acuerdo">
              70% <i class="fi fi-rr-info" style="margin-left: 10px;"></i>
            </mat-option>
            <mat-option [value]="85" matTooltip="Aceptación verbal">
              85% <i class="fi fi-rr-info" style="margin-left: 10px;"></i>
            </mat-option>
            <mat-option [value]="100" matTooltip="Carta de intención o firma de contrato">
              100% <i class="fi fi-rr-info" style="margin-left: 10px;"></i>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="errort.probabilidadControl.errors">
            <span *ngIf="errort.probabilidadControl.errors.required">
              ¡Advertencia! Este campo es requerido
            </span>
          </mat-error>
        </mat-form-field>

        <div class="row" style="margin-top: 64px !important; margin-right: 0;" *ngIf="idoportunidad">
          <div class=" col-3" align="rigth" (click)="RegistranotaP(3)" [hidden]="typeRequest == 'view'">
            <h3 style="font-family: 'Poppins';font-weight: 700;font-size: 28px !important;color: #353535;">Notas <i class="fi-rr-add"
              style="margin-left: 10px;font-size: 18px !important;"></i></h3>
          </div>
          <div class=" col-3" align="rigth"  [hidden]="typeRequest !== 'view'">
            <h3 style="font-family: 'Poppins';font-weight: 700;font-size: 28px !important;color: #353535;">Notas</h3>
          </div>
          <div class="col-md-6"></div>
          <div class="col-3" align="left" (click)="HistoricoNotas()" style="text-align: end;padding-right:0;font-weight: 600;"> Histórico de notas <i class="fi-rr-eye" style="margin-left: 10px;font-size: 16px!important;"></i></div>
        </div>

        <mat-divider style="border: 1px solid #D8D8D8;"></mat-divider>
        <app-by-step
          [notes] = "notes?.data['3']"
          [rolUser] = "rolUser"
          [oportunity] = "idoportunidad"
          [step]="3"
          [typeView]="typeRequest"
        ></app-by-step>
        <div class="col-12" align="right" *ngIf="typeRequest !== 'view'" style="margin-top: 50px;">
          <button mat-raised-button type="button" class="cancelButton" color="light"(click)="opcionCancelar()" *ngIf="typeRequest !== 'edit' && typeRequest !== 'view'">Cancelar</button>
          <button mat-raised-button type="button" class="cancelButton" color="light"(click)="deleteOpportunityById()" *ngIf="typeRequest == 'edit'">Cancelar</button>
          <button mat-raised-button type="submit" class="save" color="light" disabled='{{thirddFormGroup.invalid}}'>Guardar</button>
          <button mat-raised-button type="button" (click)="saveThirddForm(1)" class="download" color="primary" disabled='{{thirddFormGroup.invalid}}'> Enviar </button>
        </div>
        <div class="col-12" align="right" *ngIf="typeRequest == 'view'" style="margin-top: 50px;">
          <button mat-raised-button type="button" class="cancelButton" color="ligth"
            (click)="viewBack()">Cancelar</button>
          <button mat-raised-button type="button" class="download" color="primary"
             matStepperNext> Siguiente </button>
        </div>
      </form>

    </mat-step>
    <!-- Paso Envio -->
    <mat-step [stepControl]="fourFormGroup" [editable]="isEditable">
      <form [formGroup]="fourFormGroup" (ngSubmit)="saveFourForm(0)" style="margin-top: 44px;">
        <ng-template matStepLabel>Enviada</ng-template>


        <mat-form-field class="col-12" appearance="fill">
          <mat-label>Observaciones (opcional)</mat-label>
          <textarea matInput style="height: 21px !important;" formControlName="observations" maxlength="255"></textarea>
        </mat-form-field>
        <div class="row" style="margin-top: 64px !important; margin-right: 0;" *ngIf="idoportunidad">
          <div class=" col-3" align="rigth" (click)="RegistranotaP(4)" [hidden]="typeRequest == 'view'">
            <h3 style="font-family: 'Poppins';font-weight: 700;font-size: 28px !important;color: #353535;">Notas <i class="fi-rr-add"
              style="margin-left: 10px;font-size: 18px !important;"></i></h3>
          </div>
          <div class=" col-3" align="rigth"  [hidden]="typeRequest !== 'view'">
            <h3 style="font-family: 'Poppins';font-weight: 700;font-size: 28px !important;color: #353535;">Notas</h3>
          </div>
          <div class="col-md-6"></div>
          <div class="col-3" align="left" (click)="HistoricoNotas()" style="text-align: end;padding-right:0;font-weight: 600;"> Histórico de notas <i class="fi-rr-eye" style="margin-left: 10px;font-size: 16px!important;"></i></div>
        </div>
        <mat-divider style="border: 1px solid #D8D8D8;"></mat-divider>
        <app-by-step
          [notes] = "notes?.data['4']"
          [rolUser] = "rolUser"
          [oportunity] = "idoportunidad"
          [step]="4"
          [typeView]="typeRequest"
        ></app-by-step>
        <div class="col-12" align="right" *ngIf="typeRequest !== 'view'" style="margin-top: 50px;">
          <button mat-raised-button type="button" class="cancelButton" color="light"(click)="opcionCancelar()" *ngIf="typeRequest !== 'edit' && typeRequest !== 'view'">Cancelar</button>
          <button mat-raised-button type="button" class="cancelButton" color="light"(click)="deleteOpportunityById()" *ngIf="typeRequest == 'edit'">Cancelar</button>
          <button mat-raised-button type="submit" class="save" color="light" *ngIf="rolUser == 'administrador'">Guardar</button>
          <button mat-raised-button type="button" class="download" (click)="saveFourForm(1)"  color="primary" matStepperNext> Siguiente </button>
        </div>
        <div class="col-12" align="right" *ngIf="typeRequest == 'view'" style="margin-top: 50px;">
          <button mat-raised-button type="button" class="cancelButton" color="ligth"
            (click)="viewBack()">Cancelar</button>
          <button mat-raised-button type="button" class="download" color="primary"
             matStepperNext> Siguiente </button>
        </div>
      </form>
    </mat-step>
    <!-- Paso de negociación -->
    <mat-step [stepControl]="fiveFormGroup" [editable]="isEditable">
      <form [formGroup]="fiveFormGroup" (ngSubmit)="saveFiveForm(0)" class="row col-12" style="margin-top: 44px;">

        <mat-form-field class="col-3" appearance="fill">
          <mat-label>Fecha de negociación (opcional)</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="fecha_negociacion">
          <mat-datepicker-toggle matSuffix [for]="picker">
            <span matDatepickerToggleIcon class="fi-rr-calendar" style="font-size: 22px !important;"></span>
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="col-9" appearance="fill">
          <mat-label>Observaciones (opcional)</mat-label>
          <textarea matInput style="height: 21px !important;" formControlName="observations" maxlength="255"></textarea>
        </mat-form-field>

        <ng-template matStepLabel>Negociacion/sustentación</ng-template>


        <div class="row" style="margin-top: 64px !important; margin-right: 0;" *ngIf="idoportunidad">
          <div class=" col-3" align="rigth" (click)="RegistranotaP(5)" [hidden]="typeRequest == 'view'">
            <h3 style="font-family: 'Poppins';font-weight: 700;font-size: 28px !important;color: #353535;">Notas <i class="fi-rr-add"
              style="margin-left: 10px;font-size: 18px !important;"></i></h3>
          </div>
          <div class=" col-3" align="rigth"  [hidden]="typeRequest !== 'view'">
            <h3 style="font-family: 'Poppins';font-weight: 700;font-size: 28px !important;color: #353535;">Notas</h3>
          </div>
          <div class="col-md-6"></div>
          <div class="col-3" align="left" (click)="HistoricoNotas()" style="text-align: end;padding-right:0;font-weight: 600;"> Histórico de notas <i class="fi-rr-eye" style="margin-left: 10px;font-size: 16px!important;"></i></div>
        </div>
        <mat-divider style="border: 1px solid #D8D8D8;"></mat-divider>
        <app-by-step
          [notes] = "notes?.data['5']"
          [rolUser] = "rolUser"
          [oportunity] = "idoportunidad"
          [step]="5"
          [typeView]="typeRequest"
        ></app-by-step>
        <div class="col-12" align="right" *ngIf="typeRequest !== 'view'" style="margin-top: 50px;">
          <button mat-raised-button type="button" class="cancelButton" color="light"(click)="opcionCancelar()" *ngIf="typeRequest !== 'edit' && typeRequest !== 'view'">Cancelar</button>
          <button mat-raised-button type="button" class="cancelButton" color="light"(click)="deleteOpportunityById()" *ngIf="typeRequest == 'edit'">Cancelar</button>
          <button mat-raised-button type="submit" class="save" color="light" disabled='{{fiveFormGroup.invalid}}' *ngIf="rolUser == 'administrador'">Guardar</button>
          <button mat-raised-button type="button" class="download" color="primary" (click)="saveFiveForm(1)" disabled='{{fiveFormGroup.invalid}}' matStepperNext> Siguiente </button>
        </div>
        <div class="col-12" align="right" *ngIf="typeRequest == 'view'" style="margin-top: 50px;">
          <button mat-raised-button type="button" class="cancelButton" color="ligth"
            (click)="viewBack()">Cancelar</button>
          <button mat-raised-button type="button" class="download" color="primary"
             matStepperNext> Siguiente </button>
        </div>
      </form>
    </mat-step>
    <!-- Paso final de cierre, ganada o desierta -->
    <mat-step>
      <ng-template matStepLabel>Cierre</ng-template>

      <form [formGroup]="sixtFormGroup" (ngSubmit)="saveSixtForm()" style="margin-top: 44px;">
        <ng-template matStepLabel>Cierre del Proceso</ng-template>
        <mat-form-field appearance="fill" class="col-md-3">
          <mat-label>Cierre</mat-label>
          <mat-select id="cierre" formControlName="cierre">
            <mat-option value='ganada'>Ganado</mat-option>
            <mat-option value='perdida'>Perdido</mat-option>
            <mat-option value='desierta'>Desierto</mat-option>
          </mat-select>
          <mat-error *ngIf="errorSix.cierre.errors"><span *ngIf="errorSix.cierre.errors.required">¡Advertencia! Este
            campo es requerido</span></mat-error>
        </mat-form-field>
        <h5 style="margin-top: 28px; margin-bottom: 28px; color:#353535; font-size:16px !important;font-weight:700;" [hidden]="typeRequest == 'view'">Contrato, Ofertas, RFPS/ Cartas de adjudicación</h5>
        <div class="row col-12">
          <div class="col-4">
            <button mat-raised-button type="button" class="upload" (click)="fileInput.click()" [hidden]="typeRequest == 'view'">
              Cargar archivos
              <span class="fi-rr-cloud-upload" style="margin-left: 10px"></span>
            </button>
            <input hidden (change)="onChangeFile($event)" multiple name="files" #fileInput type="file" id="file" accept="application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
            <h5 style="margin-top: 5px" [hidden]="typeRequest == 'view'">Solo se aceptan archivos .pdf y .xlsx</h5>
          </div>
          <!-- Vista archivos seleccionados -->
          <div *ngFor="let item of dataFileArray; let i = index" style="margin-top: 10px; overflow: hidden;" class="col-3">
            <h5 style="display: flex;">
              <span
                class="fi-rr-trash"
                style="color: #D14A4A; font-size: 14px; margin-right: 5px;cursor: pointer;"
                (click)="deleteFieldFile(i)"
              ></span>
              <h6 style="color:#717171;text-decoration: none;font-size: 16px !important;font-weight: 600;">
                {{ item.name | slice:0:15}} <small *ngIf="item.name.length > 15">...</small>
              </h6>
            </h5>
          </div>
          <!-- Vista archivos cargados anteriormente -->
          <div class="row col-12" [hidden]="typeRequest !== 'edit'">
            <div *ngFor="let item of uploadFiles; let i = index" style="margin-top: 10px;overflow: hidden;" class="col-3">
              <h5 style="display: flex;">
                <span
                  class="fi-rr-trash"
                  style="color: #D14A4A; font-size: 14px; margin-right: 5px;cursor: pointer;"
                  (click)="deleteUploadFile(item.id, i)"
                ></span>
                <a style="color:#717171;text-decoration: none;font-size: 16px !important;font-weight: 600;" target="_blank" [href]="item?.fullPath">
                  {{ item.name | slice:0:15}} <small *ngIf="item.name.length > 15">...</small>
                </a>
              </h5>
            </div>

          </div>
          <!-- Vista archivos detalle -->
          <h5 style="margin-top: 28px; margin-bottom: 28px; color:#353535; font-size:16px !important;font-weight:700;" [hidden]="typeRequest !== 'view'">Contrato, Ofertas, RFPS/ Cartas de adjudicación</h5>

          <div class="row col-12" *ngIf="typeRequest == 'view'">
            <div *ngFor="let item of uploadFiles; let i = index" style="margin-top: 10px;overflow: hidden;" class="col-3">
              <p style="color: #51B0CB; display: flex;font-size: 14px;">
                 <span class="fi-rr-download" (click)="fileView.click()" style="margin-right: 8px;cursor: pointer;">
                </span>
                <a #fileView [href]="item?.fullPath" target="_blank" style="color:#717171;text-decoration: none;font-size: 16px !important;font-weight: 600;">{{item?.name | slice:0:15}} <small *ngIf="item.name.length > 15">...</small></a>
              </p>
            </div>
          </div>
        </div>



        <div class="row" style="margin-top: 64px !important; margin-right: 0;" *ngIf="idoportunidad">
          <div class=" col-3" align="rigth" (click)="RegistranotaP(6)" [hidden]="typeRequest == 'view'">
            <h3 style="font-family: 'Poppins';font-weight: 700;font-size: 28px !important;color: #353535;">Notas <i class="fi-rr-add"
              style="margin-left: 10px;font-size: 18px !important;"></i></h3>
          </div>
          <div class=" col-3" align="rigth"  [hidden]="typeRequest !== 'view'">
            <h3 style="font-family: 'Poppins';font-weight: 700;font-size: 28px !important;color: #353535;">Notas</h3>
          </div>
          <div class="col-md-6"></div>
          <div class="col-3" align="left" (click)="HistoricoNotas()" style="text-align: end;padding-right:0;font-weight: 600;"> Histórico de notas <i class="fi-rr-eye" style="margin-left: 10px;font-size: 16px!important;"></i></div>
        </div>

        <mat-divider style="border: 1px solid #D8D8D8;"></mat-divider>
        <app-by-step
          [notes] = "notes?.data['6']"
          [rolUser] = "rolUser"
          [oportunity] = "idoportunidad"
          [step]="6"
          [typeView]="typeRequest"
        ></app-by-step>
        <div class="col-12" align="right" *ngIf="typeRequest !== 'view'" style="margin-top: 50px;">
          <button mat-raised-button type="button" class="cancelButton" color="light"(click)="opcionCancelar()" *ngIf="typeRequest !== 'edit' && typeRequest !== 'view'">Cancelar</button>
          <button mat-raised-button type="button" class="cancelButton" color="light"(click)="deleteOpportunityById()" *ngIf="typeRequest == 'edit'">Cancelar</button>
          <button mat-raised-button type="submit" class="save" color="light" disabled='{{sixtFormGroup.invalid || fileMessage}}' *ngIf="rolUser == 'administrador'"> Guardar </button>
          <button mat-raised-button type="button" class="download" color="primary" (click)="saveSixtForm()" disabled='{{sixtFormGroup.invalid || fileMessage}}' matStepperNext> Finalizar </button>
        </div>
        <div class="col-12" align="right" *ngIf="typeRequest == 'view'" style="margin-top: 50px;">
          <button mat-raised-button type="button" class="download" color="primary" (click)="viewBack()" matStepperNext> Finalizar </button>

        </div>
      </form>

    </mat-step>
  </mat-horizontal-stepper>
  <!-- fin MAstpeeer-->
</div>
