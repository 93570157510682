import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChatRestService } from '../../services/chat-rest.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { RespNewDateComponent } from 'src/app/modules/schedules/modules/customer-forms/resp-new-date/resp-new-date.component';

@Component({
  selector: 'app-chat-groups',
  templateUrl: './chat-groups.component.html',
  styleUrls: ['./chat-groups.component.sass']
})
export class ChatGroupsComponent implements OnInit {
  //Formulario de grupos
  groupForm: FormGroup
  //Formulario busqueda de usuario
  searchUserForm: FormGroup
  //Input para buscar usuario por documento
  searchByDocument: FormControl = new FormControl(['', []])
  /**Lista de campañas */
  campaigns: any[] = []
  //Lista de usuarios de campañas
  campaignsUsers: any[] = []
  //Lista típos de documentos
  documentTypes: any[] = []
  //Consecuencias
  available_users: any[] = []
  selected_users: any[] = []
  //Valor del selector de elementos
  available_users_value: any[] = []
  selected_users_value: any[] = []
  //Usuario se que asignara en busqueda manual
  searched_user: any = {}
  searched_user_to_add = {}
  //Información del grupo
  group_id: number = 0;



  constructor(
    private chatRestService: ChatRestService,
    private dialogRef: MatDialogRef<ChatGroupsComponent>,
    private authService: AuthService,
    private fb: FormBuilder,
    private alertService: AlertsService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any

  ) { }

  ngOnInit(): void {
    if (this.getTypeModal == "edit") {
      this.getGroup(this.modalGroupId)
    }
    this.getCampaigns()
    this.getDocumentTypes()
    this.initializeForm()
    this.initializeSearchUserForm()
  }

  /**
  * Método que inicializa el formulario de grupo
  * @author Juan Carlos Alonso
  * @createdate 05-06-2024
  * @returns {void} void
  */
  initializeForm(): void {
    this.groupForm = this.fb.group({
      group_name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      description: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      campaigns: ['', []]
    })

    this.groupForm.get('campaigns').valueChanges
      .pipe(
        tap(() => this.enableCampaigns()),
        debounceTime(700))
      .subscribe(valueCampaigns => {
        if (valueCampaigns.length) {
          this.getCampaignUsers()
        } else {
          this.available_users = []
        }
      })

  }

  /**
  * Método que inicializa el formulario de busqueda de usuario por documento
  * @author Juan Carlos Alonso
  * @createdate 05-06-2024
  * @returns {void} void
  */
  initializeSearchUserForm(): void {
    this.searchUserForm = this.fb.group({
      document: ['', []],
      document_type: [1, []]
    })
  }

  /**
  * Método que obtiene un grupo por su id y asigna el valor al formulario de grupo
  * @author Juan Carlos Alonso
  * @createdate 05-06-2024
  * @returns {void} void
  */
  getGroup(id: number): void {
    this.chatRestService.getGroupById(id, true).subscribe((resp: any) => {
      const obtained_group = resp.group
      this.group_id = obtained_group?.id
      this.selected_users = obtained_group?.participants
      this.selected_users_value = obtained_group?.participants
      this.groupForm.patchValue({ group_name: obtained_group?.name, description: obtained_group?.description })
    })
  }

  /**
* Método que obtiene y asigna la lista de campañas
* @author Juan Carlos Alonso
* @createdate 05-06-2024
* @returns {void} void
*/
  getCampaigns() {
    this.chatRestService.getCampaigns().subscribe(resp => {
      this.campaigns = resp?.data.map(data => {
        data['disabled'] = false
        return data;
      })
    })
  }

  /**
* Método que obtiene lista de usuarios por campañas
* @author Juan Carlos Alonso
* @createdate 05-06-2024
* @returns {void} void
*/
  getCampaignUsers() {
    const campaign_ids = this.groupForm.get('campaigns').value
    this.chatRestService.getCampaignsUsers({ campaigns: campaign_ids }).subscribe(resp => {
      this.available_users = resp?.data
    })

  }

  /**
* Método que obtiene y asigna la lista de tipos de documento
* @author Juan Carlos Alonso
* @createdate 05-06-2024
* @returns {void} void
*/
  getDocumentTypes() {
    this.chatRestService.getDocumentTypes().subscribe(resp => {
      this.documentTypes = resp
    })

  }

  /**
* Método que obtiene un usuario por documento y tipo de documento
* @author Juan Carlos Alonso
* @createdate 05-06-2024
* @returns {void} void
*/
  getUserByDocument(event) {
    const document = this.searchUserForm.get('document').value
    const type = this.searchUserForm.get('document_type').value
    if (document !== "" && type !== "") {
      this.chatRestService.getUserByDocument(type, document).subscribe(resp => {
        this.searched_user = resp.data
      }, error => {
        this.alertService.alertError('¡Atención¡', error.error.message)
      })
    }


  }

  /**
* Método que reinicia el usuario buscado
* @author Juan Carlos Alonso
* @createdate 05-06-2024
* @returns {void} void
*/
  clearSearchedUser(confirmation: boolean) {
    if (confirmation) {
      this.searched_user = {}
      this.searched_user_to_add = {}
      this.searchUserForm.patchValue({ document: '', document_type: 1 })
    }
  }

  /**
* Método que asigna el valor del usuario que sera enviado al componente de selector de elementos
* @author Juan Carlos Alonso
* @createdate 05-06-2024
* @returns {void} void
*/
  setSearchedUserToAdd() {
    this.searched_user_to_add = this.searched_user
  }

  /**
* Método que confirma si no hay ningun usuario encontrado
* @author Juan Carlos Alonso
* @createdate 05-06-2024
* @returns {boolean} boolean
*/
  isSearchedUserEmpty() {
    return Object.keys(this.searched_user).length === 0
  }


  /**
   * Metodo que se encarg de obtener el titulo del modal
   * @author Juan David Guerrero Vargas
   * @returns {string} string cadena de texto para el titulo
   */
  get getTitle(): string {
    if (this.dialogData?.title == undefined || this.dialogData.title == "") {
      return ""
    }
    return this.dialogData.title
  }

  /**
   * Metodo que se encarga de retornar cual es tipo de modal a renderizar
   * @author Juan David Guerrero Vargas
   * @returns {string} string
   */
  get getTypeModal(): string {
    return this.dialogData.optenTo
  }

  /**
* Método que retorna la id del grupo
* @author Juan Carlos Alonso
* @createdate 05-06-2024
* @returns {number} number
*/
  get modalGroupId(): number {
    return this.dialogData.idGroup
  }

  /**
* Método que asigna los valores a la lista de usuarios seleccionables
* @author Juan Carlos Alonso
* @createdate 05-06-2024
* @returns {void} void
*/
  setAvaiableUsers(users: any[]) {
    this.available_users_value = users;
  }
  /**
   * @author Juan Carlos Alonso
   * @createdate 2023-05-31
   * Método que asigna el valor a los usuarios seleccionadas
  */
  setSelectedUsers(users: any[]) {
    this.selected_users_value = users;
  }

  /**
* Método que valida si se han seleccionado mas de 3 campañas, de ser asi se deshabilitaran las demas campañas
* @author Juan Carlos Alonso
* @createdate 05-06-2024
* @returns {boolean} boolean
*/
  enableCampaigns() {
    const campaignsSelected = this.groupForm.get('campaigns').value
    if (campaignsSelected.length == 3) {
      this.campaigns.map(campaigns => {
        if (campaignsSelected.find(selected_campaign => selected_campaign == campaigns.id) == undefined) {
          campaigns['disabled'] = true
        } else {
          campaigns['disabled'] = false
        }
      });
    } else {
      this.campaigns.map(campaigns => {
        campaigns['disabled'] = false
        return campaigns;
      })
    }

  }


  /**
* Método que envía el grupo y lo crea o lo actualiza
* @author Juan Carlos Alonso
* @createdate 05-06-2024
* @returns {void} void
*/
  save(): void {
    if (this.groupForm.invalid) {
      this.groupForm.markAllAsTouched()
      return
    }

    var data = {
      id: this.group_id,
      name: this.groupForm.get('group_name').value,
      description: this.groupForm.get('description').value,
      participants: this.selected_users_value.map(({ isHidden, ...rest }) => rest),
    }

    if (this.getTypeModal == 'edit') {
      this.chatRestService.updateGroup(data).subscribe((resp) => {
        this.alertService.alertSuccess('¡Enhorabuena!', resp.message)
        this.dialogRef.close()
      }, (error) => {
        this.alertService.alertError('¡Atención!', error.error.message)
      })
    } else {
      this.chatRestService.createNewGroup(data).subscribe((resp) => {
        this.alertService.alertSuccess('¡Enhorabuena!', resp.message)
        this.dialogRef.close({confirmation: true, group_id: this.group_id})
      }, (error) => {
        this.alertService.alertError('¡Atención!', error.error.message)
      })

    }



  }


}
