import { PurchaseOrderItem } from './orden-items';
export class OrdenCompra {
    constructor() {
        this.PurchaseOrderItem = Array<PurchaseOrderItem>();
    }
    idPurchase          : number =0;
    observations        : string;
    sub_total           : number;
    discount            : number;
    iva                 : number;
    calculated_iva      : number;
    total               : number;
    value_in_letters    : string;
    delivery_date       : Date;
    warranty            : string;
    pay                 : string;
    payment_condition   : number;
    payment_deb         : number;
    payment_percent     : number;
    credit_condition    : string;
    pay_value           : string;
    policy              : string;
    request_id          : number;
    proveedor_nit       : number;
    company_id          : number;
    PurchaseOrderItem   : PurchaseOrderItem[];
    ciudad              : string;
    direccion           : string;
    observacionCondiciones : string;

}
