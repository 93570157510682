import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { ActivatedRoute } from '@angular/router';
import { TestsService } from '../../../services/tests.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table'
import { AssessmentScoreComponent } from '../assessment-score/assessment-score.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { AssingUserComponent } from '../../assign-user/assing-user/assing-user.component';

@Component({
  selector: 'app-assessments-detail',
  templateUrl: './assessments-detail.component.html',
  styleUrls: ['./assessments-detail.component.sass']
})
export class AssessmentsDetailComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['actions', 'name', 'document', 'phone'];
  dataSource = new MatTableDataSource();
  userId
  rommId
  room
  currentPage = 1;
  noEdit = false;
  roomForm: FormGroup = this.fb.group({
    name:['',Validators.required],
    capacity:['',Validators.required],
    start:['',Validators.required],
    end:['',Validators.required],
    description:['',Validators.required],
    user_selection_id:['',Validators.required],
    user_operation_id:['',Validators.required],
    user_formation_id:['',Validators.required],
  });
  user_selection_name:string;
  user_operation_name:string;
  user_formation_name:string;
  length
  pageSize
  constructor(private auth: AuthService, private activatedRoute: ActivatedRoute,
              private testsService:TestsService,  private fb: FormBuilder, 
              public dialog: MatDialog, public alert:AlertsService) { 
        this.userId = this.auth.getUser().rrhh_id;
        this.rommId = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.testsService.getRoomAssessmet( this.rommId).subscribe((res:any)=>{
      this.room = res
      res.start= new Date(res.start); 
      res.end= new Date(res.end);
      this.roomForm.patchValue(res)
      this.roomForm.disable()
      this.dataSource = new MatTableDataSource(res.assessment_room_candidates);
     this.dataSource.sort = this.sort;
     this.length = res.assessment_room_candidates.total;
     this.pageSize = res.assessment_room_candidates.per_page;
     this.user_formation_name= res.user_formation;
     this.user_operation_name= res.user_operation;
     this.user_selection_name= res.user_selection;
      if( this.userId === res.user_creator_id){
       this.noEdit = true
    }
    })
   
   
  }
  activeEdit() {
    this.roomForm.disabled? this.roomForm.enable():this.roomForm.disable()
 
  }
  updateRomm(){
    let roomAux = {
      name: this.roomForm.get('name').value,
      capacity: this.roomForm.get('capacity').value,
      start: this.roomForm.get('start').value,
      end: this.roomForm.get('end').value,
      description: this.roomForm.get('description').value,
      user_selection_id: this.roomForm.get('user_selection_id').value,
      user_operation_id: this.roomForm.get('user_operation_id').value,
      user_formation_id: this.roomForm.get('user_formation_id').value,
      request_id: this.room.request.id
    }
    
    this.alert.alertConfirm('¿Desea actualizar los datos de esta sala?').then( e => {
      if(e.isConfirmed){
        this.testsService.updateRoomAssessmet(this.rommId, roomAux).subscribe(res=>{
          this.ngOnInit()
          this.roomForm.disable();
          this.alert.alertSuccess('¡Actualizado!','La sala ha sido actualizada éxitosamente');
        })
      }
    })
    
  }
  getPaginatorData(e){
    this.currentPage = e.pageIndex + 1;
    this.ngOnInit();
  }
  assessmentDialog(id){
    this.testsService.getCandidateAssessmet(id).subscribe(res=>{
      const assessmentDialog = this.dialog.open( AssessmentScoreComponent, {
        data: { candidateId: id, assessment:res }
      });
      
    })
  }
  deleteCandidate(id){
    this.alert.alertConfirm('¿Desea eliminar a este candidato de esta sala?').then( e => {
      if(e.isConfirmed){
        this.testsService.deleteCndidateToAssessmet(id).subscribe(res=>{
          this.ngOnInit()
          this.alert.alertSuccess('¡Eliminado!','El candidato ha sido eliminado de esta sala');
        })
      }
    })
  
  }


}
