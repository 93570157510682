import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { AuditoriaService } from '../../services/auditoria.service';

@Component({
  selector: 'app-auditorias',
  templateUrl: './auditorias.component.html',
  styleUrls: ['./auditorias.component.sass']
})
export class AuditoriasComponent implements OnInit {

  constructor(
    private authService:AuthService,
    private auditoriaService:AuditoriaService,
  ) { }

  ngOnInit():void{
    
  }

}
