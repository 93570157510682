<div class="row novelty-row-padding">

  <h2 mat-dialog-title>
    <span class="gray-color" style="margin-left: -9px"></span>
    <button style="float: right;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
    <div class="title content" style="width: 100%;">
      <img src="./assets/images/novelties.png" alt="noveltyIcon" class="icon">
      <span>
        <h1>{{data.data?.name}}</h1>
        <h5 style="font-size: 15px; font-family: 'Poppins', sans-serif!important;">{{data.data?.id_number}}</h5>
      </span>
      <!--<button class="re-asign" mat-icon-button>
        <i style="margin-right: 10px;">Reasignar</i><i style="font-size: 25px;" class="fi-rr-redo"></i>
      </button>-->
    </div>
  </h2>

  <h3>
    <span class="gray-color" style="margin-left: -9px">{{ dataInfo?.novelty_date_start | date:'EEEE d, MMMM' }}</span>
  </h3>
  <div class="col-12" style="padding: 0px;">
    <div class="col-7">
      <span
        class="badge" [style.background]="dataInfo?.noveltyTypeToPrint?.backgroundColor">{{ dataInfo?.novelty_type?.key }}</span> {{ dataInfo?.noveltyTypeToPrint?.title }}
    </div>
  </div>

  <mat-dialog-content style="padding: 3%; overflow-x: hidden" class="mat-typography">
    <div class="row">
      <div class="col-6" style="padding-top: 10px;">
        <div class="col-12" *ngIf="data?.data?.novelty_type.key != '39'">
          <br>
          <h4 class="gray-color"><b>Justificación del trabajador</b></h4>
          <p class="p-color">{{ dataInfo?.responseFirstInstance?.justify }}</p>
        </div>
        <div class="col-12" *ngIf="data?.data?.novelty_type.key == '39'">
          <h4 class="gray-color"><b>Observación</b></h4>
          <p class="p-color">{{ dataInfo?.observation }}</p>
        </div>
        <p style="color: #868686; font-size: 14px !important; margin-bottom: 12px; font-weight: 600;"
        *ngIf="dataInfo?.files?.length > 0"> Archivos adjuntos </p>
       <div class="col-12 row"  *ngIf="dataInfo?.files?.length > 0" style="padding-bottom: 32px;">
         <div class="col-12" style="display: flex;" *ngFor="let item of dataInfo?.files">
           <a  #file style="text-overflow: ellipsis;
            overflow: hidden;
             white-space: nowrap;
             text-decoration: underline;
             color: #353535;
             opacity: 1;
             font-weight: 600;
             cursor: pointer;"
             [href]="item.fullPath" target="_blank">{{item.name}} </a>
             <span class="fi-rr-download" style="padding-left: 14px; cursor: pointer;" (click)="file.click()"></span>
         </div>
       </div>
        <div class="col-12" *ngIf="data?.data?.novelty_type.key != '39'">
          <br>
          <h4 class="gray-color"><b>Respuesta Nómina</b></h4>
          <p class="p-color">{{ dataInfo?.responseFirstInstance?.response }}</p>
        </div>

        <div class="col-12" *ngIf="dataInfo?.responseSecondInstance != null">
          <br>
          <h4 class="gray-color"><b>Justificación del trabajador</b></h4>
          <p class="p-color">{{ dataInfo?.responseSecondInstance?.justify }}</p>
        </div>

      </div>


      <div class="col-6 text-left">
        <h4 style="float: inline-start; margin-left: 60px;">Gestión Novedad</h4>
        <!-- aprobacion de novedades -->
        <form style="margin-left: 60px;" [formGroup]="noveltiesForm" *ngIf="data?.data?.novelty_type.key != '39'">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Estado</mat-label>
            <mat-select formControlName="state">
              <mat-option  *ngFor="let item of options" [value]="item.id">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Respuesta</mat-label>
            <textarea matInput formControlName="response"></textarea>
          </mat-form-field>

          <button mat-raised-button color="primary" [disabled]="noveltiesForm.invalid" (click)="UpdateState()">
            Enviar respuesta
          </button>
        </form>
        <!-- Justificación de retardos -->
        <form style="margin-left: 60px;" [formGroup]="justifyForm" *ngIf="data?.data?.novelty_type.key == '39'">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Tipo de justificación</mat-label>
            <mat-select formControlName="type">
              <mat-option  *ngFor="let item of justifyTipes" [value]="item.id">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 100%;" *ngIf="justifyForm.controls.type.value !== 'sistema_fuera_linea'">
            <mat-label>Justificación</mat-label>
            <textarea matInput formControlName="response" placeholder="Justificación o número de ticket"></textarea>
            <mat-error *ngIf="justifyForm.controls.response.hasError('maxlength')">¡Cuidado! Solo puedes tener un máximo de 250 caracteres</mat-error>
          </mat-form-field>

          <mat-form-field style="width: 100%;" appearance="fill" *ngIf="justifyForm.controls.type.value == 'sistema_fuera_linea'">
            <mat-label>Número de GLPI</mat-label>
            <input type="number" matInput  formControlName="response" placeholder="Número de GLPI">

          </mat-form-field>
          <mat-error style="font-size: 11px !important;" *ngIf="boolLength == true">¡Cuidado! Solo puedes tener un mínimo de 3 dígitos o un máximo de 10 dígitos</mat-error>
          
          <button mat-raised-button color="primary" style="margin-top: 10px;" [disabled]="justifyForm.invalid || boolLength == true" (click)="saveObservation()">
            Enviar justificación
          </button>
        </form>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="row novelty-footer-hours">
      <div class="col-4">
        <b>Inicio turno</b>
        <p class="gray-color"><b>{{ scheduleTime }}</b></p>
      </div>
      <div class="col-4">
        <b>Fin turno</b>
        <p class="gray-color"><b> {{ endTime }}</b></p>
      </div>
      <div class="col-4" style="color: red" *ngIf="dataInfo?.novelty_type?.key == 39">
        <b>Hora reporte</b>
        <p><b>{{ realTime }}</b></p>
      </div>
    </div>
  </mat-dialog-actions>

</div>
