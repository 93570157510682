import { Component, ComponentFactoryResolver, Injectable, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ClientesService } from '../../services/rest/clientes.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { ClientesCrearComponent } from '../clientes-crear/clientes-crear.component';
import { Route, Router, ActivatedRoute, Params } from '@angular/router';
import { map, debounceTime } from 'rxjs/operators';
import { AuthService } from '../../../../core/services/rest/auth.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.sass']

})

//@Injectable({providedIn:'root'});
export class ClientesComponent implements OnInit {

  length;
  isPage = 1;
  pageSize = 5;
  page = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  newgroupComponent: boolean = false;
  idcliente: number;
  public clientesarray: any[] = [];
  dataSource = new MatTableDataSource<any>();
  clienteop: { idcliente: number };
  filterStates: any;
  user: any;
  nameControl = new FormControl();
  typeControl = new FormControl();
  numberControl = new FormControl();
  name: any = '';
  id_type: any = '';
  number: any = '';
  rolUser: any = '';
  selectedIndex: any = 0;

  constructor(private clientesService: ClientesService,
    private destroyService: DestroyComponentService,
    private router: Router,
    private route: ActivatedRoute,
    private cfr: ComponentFactoryResolver,
    public dialog: MatDialog,
    private authService: AuthService,
  ) {
    this.user = this.authService.decryptToken().rrhh_id;
  }


  ngOnInit(): void {
    this.clientesService.getClientes().subscribe((resp: any) => {
      this.clientesarray = resp.data;
      this.dataSource = resp;
    });
    this.nameControl.valueChanges.pipe(debounceTime(1000)).subscribe(res => {
      this.name = res;
      this.getClientsBy();
    });
    this.numberControl.valueChanges.pipe(debounceTime(1000)).subscribe(res => {
      this.number = res;
      this.getClientsBy();
    });
    this.typeControl.valueChanges.subscribe(res => {
      this.id_type = res;
      this.getClientsBy();
    });

    const local = this.authService.getUser();
    local.roles.forEach((rol) => {
      const name = rol.split('::');
      if (name[0] == 'crm-cial' && name[1] == 'comercial') {
        this.rolUser = name[1];
      }
      if (name[0] == 'crm-cial' && name[1] == 'administrador') {
        this.rolUser = name[1];
      }
      if (name[0] == 'crm-cial' && name[1] == 'implementador') {
        this.rolUser = name[1];
      }
    });
    this.route.queryParams.subscribe(params => {
      this.selectedIndex = params.tab ? params.tab : 0;
    });
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para la apertura de modal de edición de los datos del cliente
  */
  EditarCliente(data: any) {
    const dialogNewRol = this.dialog.open(ClientesCrearComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'editar',
        title: data.name || '',
        info: data || ''
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.getClientsBy();
    });
  }


  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para la apertura de modal de creación de clientes
  */
  AperturaVentana(): void {
    const dialogNewRol = this.dialog.open(ClientesCrearComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Crear Cliente',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.getClientsBy();
    });

  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para consulta de clientes por nombre, documento y tipo de identificación
  */
  getClientsBy() {
    this.clientesService.getClientesBy(this.name, this.id_type, this.number).subscribe((resp: any) => {
      this.clientesarray = resp.data;
      this.dataSource = resp;
    });
  }






}
