import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { InteligenciaService } from 'src/app/modules/auditoria/services/inteligencia.service';
import { Tools } from 'src/app/modules/auditoria/utils/tools.utils';
import Swal from 'sweetalert2';
import { MatricesAsignadasComponent } from '../matrices-asignadas/matrices-asignadas.component';

@Component({
  selector: 'app-editar-formulario',
  templateUrl: './editar-formulario.component.html',
  styleUrls: ['./editar-formulario.component.sass']
})
export class EditarFormularioComponent implements OnInit {

  /**
  * @author Cristian Gallo
  * Variable para capturar las selecciones realizadas en la tabla de preguntas
  */  
  selections = [];

  //Variable para mostrar el formulario
  showForm:boolean = false;

  //Variable para habilitar o deshabilitar botones y dragdrop
  questions:boolean = false;

  //Variable que almacena las preguntas relacionadas en el formulario
  questionsForm: any[] = [];

  //Variable para capturar el id del formulario
  id_form:string = '';

  //Variable para indicar la ruta es decir, si para visualizar el formulario o editarlo
  type:string = '';

  //Variable para capturar el nombre del formulario o inteligencia de negocio
  nameForm:string = '';
  constructor(
    private aRoute:ActivatedRoute,
    private router:Router,
    private inteligenicaS:InteligenciaService,
    private matriz:MatDialog
  ) { 

  }

  ngOnInit(): void {
    this.enableForm();
    this.getFormById();
  }

  /**
  * @author Cristian Gallo
  * Función que verifica si se va a visualizar o editar el formulario
  */    
  enableForm(){
    this.aRoute.paramMap.subscribe(params=>{
      this.id_form = params.get('id');
      this.type = params.get('type');
      if (this.type === "edit") {
        this.questions = true;
      }else{
        this.questions = false;
      }
    })
  }

  /**
  * @author Cristian Gallo
  * Función para habilitar el formulario para la edición
  */ 
  edit(){
    this.router.navigateByUrl(`/mios/calidad/formulario/edit/${this.id_form}`);
    this.questions = true; 
  }

  /**
  * @author Cristian Gallo
  * Función para cambiar el orden de las preguntas en el dragdrop
  */ 
  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.questionsForm, event.previousIndex, event.currentIndex);
    this.changePosition(event.container.data);
  }

  /**
  * @author Cristian Gallo
  * Función para obtener los datos del formulario
  */ 
  getFormById(){
    this.inteligenicaS.getFormById(this.id_form).subscribe((resp:any)=>{
      this.nameForm = resp.name;
      this.questionsForm = resp.preguntas_acivas;
      if (this.questionsForm.length > 0) {
        this.showForm = true
      }else{
        this.showForm = false;
      }
    });
  }

  /**
  * @author Cristian Gallo
  * Función para obtener la selección de preguntas en la tabla de preguntas
  */ 
  selection(event:any[]){
    this.selections = event;
    this.save();
  }

  /**
  * @author Cristian Gallo
  * Función para eliminar preguntas del formulario
  */ 
  removeQuestion(id_question){
    Swal.fire({
      icon: 'warning',
      title: '¿Deseas eliminar esta pregunta del formulario?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      reverseButtons: true,
      width: '840px',
      padding: '2em 4em'
    }).then(result=>{
      if (result.isConfirmed) {        
        this.inteligenicaS.questionsForm(this.id_form,'',id_question).subscribe(()=>{
          this.getFormById();
          Tools.swalSuccess('¡Excelente!','Se ha eliminado la pregunta del formulario correctamente');
        });
      }
    })
  }

  /**
  * @author Cristian Gallo
  * Función para guardar o confirmar las preguntas seleccionadas en la tabla preguntas
  */ 
  save(){
    let newQuestions = [];
    this.selections.forEach(seleccion => {
      newQuestions.push({id:seleccion.id,});
    });
    this.inteligenicaS.questionsForm(this.id_form,newQuestions,'').subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202) {
        Tools.swalSuccess('¡Excelente!','Tus preguntas se han cargado con éxito.')
        this.getFormById();
      }
    },(err:any)=>{
    }
    );
  }

  /**
  * @author Cristian Gallo
  * Función que actualiza la posición de las preguntas
  */ 
  changePosition(data:any[]){
    let newPosition = [];
    data.forEach((element,index:number) => {
      element.position = index + 1; 
      newPosition.push({
        id: element.pregunta.id,
        position: element.position
      })
    });
    this.inteligenicaS.changePosition(this.id_form,newPosition).subscribe((resp:any)=>{
      this.getFormById();
    });
  }

  cancelar(){
    this.router.navigateByUrl(`/mios/calidad/formulario/detail/${this.id_form}`);
    this.questions = false; 
    this.getFormById();
  }

  matrices(){
    const matrizRef = this.matriz.open(MatricesAsignadasComponent,{
      width: '550px',
      height: '224px',
      disableClose: true,
      data: {id:this.id_form}
    });
  }

}
