<div mat-dialog-title>
    <button mat-icon-button style="float: right;" mat-dialog-close> <i class="fi-rr-cross"></i> </button>
    <h3>Cambiar contraseña</h3>
</div>
<mat-dialog-content>
    <form fxLayout="column" [formGroup]="formPassword" autocomplete="off">
        <input id="username" style="display:none" type="text" name="fakeusernameremembered">
        <input id="password" style="display:none" type="password" name="fakepasswordremembered">
        <mat-form-field fxFlex>
            <mat-label>Nueva contraseña {{showPassword ? 'text' : 'password'}}</mat-label>
            <input id="real-password" autocomplete="new-password" matInput appBlockCopyPaste (keypress)="omitSpecialChar($event)" [type]=" showPassword ? 'text' : 'password' " formControlName="password">
            <button mat-icon-button matSuffix (click)="showPassword = !showPassword" *ngIf="!showPassword"> <i class="fi-rr-eye"></i> </button>
            <button mat-icon-button matSuffix (click)="showPassword = !showPassword" *ngIf="showPassword"> <i class="fi-rr-eye-crossed"></i> </button>
            <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.minlength">¡Aún falta! Recibimos mínimo 5 caracteres</span></mat-error>
            <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 15 caracteres</span></mat-error>
            <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasNumber">Su contraseña debe tener un número</span></mat-error>
            <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasCapitalCase">Su contraseña debe tener una mayuscula</span></mat-error>
            <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasSmallCase">Su contraseña debe tener una minuscula</span></mat-error>
            <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasSpecialCharacters">Su contraseña debe tener un caracter especial</span></mat-error>
        </mat-form-field>
        <mat-form-field fxFlex>
            <mat-label>Confirmar contraseña</mat-label>
            <input id="real-confirm-password" matInput autocomplete="new-password" [type]="showPassword ? 'text':'password'" formControlName="confirmPassword">
            <button mat-icon-button matSuffix (click)="showPassword = !showPassword" *ngIf="!showPassword"> <i class="fi-rr-eye"></i> </button>
            <button mat-icon-button matSuffix (click)="showPassword = !showPassword" *ngIf="showPassword"> <i class="fi-rr-eye-crossed"></i> </button>
            <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.minlength">¡Aún falta! Recibimos mínimo 5 caracteres</span></mat-error>
            <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 15 caracteres</span></mat-error>
            <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.hasNumber">Su contraseña debe tener un número</span></mat-error>
            <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.hasCapitalCase">Su contraseña debe tener una mayuscula</span></mat-error>
            <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.hasSmallCase">Su contraseña debe tener una minuscula</span></mat-error>
            <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.hasSpecialCharacters">Su contraseña debe tener un caracter especial</span></mat-error>
            <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="!error.confirmPassword.errors.isMatching">Su contraseña no es igual a la ingresada previamente</span></mat-error>

        </mat-form-field>
        <div class="text-center">
            <button mat-stroked-button class="mr" color="primary" mat-dialog-close>Cancelar</button>
            <button mat-raised-button color="primary" [disabled]="formPassword.invalid" (click)="changePassword()">Guardar</button>
        </div>
    </form>
</mat-dialog-content>