<mat-card class="main-card">
   
    <mat-card-header class="row">
        <mat-card class="col-2 calificacion c1">
            <p style="font-size: 11px;font-weight:700">Total</p>
            <h4 style="font-weight:700">{{puntaje_total}}%</h4>
        </mat-card>

        <mat-card class="col-2 calificacion c2">
            <p style="font-size: 11px;font-weight:700">Cumplimiento</p>
            <h4 style="font-weight:700">{{puntaje_cumplimiento}}%</h4>
        </mat-card>

        <mat-card class="col-2 calificacion c3">
            <p style="font-size: 11px;font-weight:700">No crítico</p>
            <h4 style="font-weight:700">{{puntaje_no_critico}}%</h4>
        </mat-card>

        <mat-card class="col-2 calificacion c4">
            <p style="font-size: 11px;font-weight:700">Crítico usuario final Campaña</p>
            <h4 style="font-weight:700">{{puntaje_critico_usuario}}%</h4>
        </mat-card>


        <mat-card class="col-2 calificacion c5">
            <p style="font-size: 11px;font-weight:700">Crítico negocio</p>
             <h4 style="font-weight:700">{{puntaje_critico_negocio}}%</h4>
        </mat-card>

        <mat-card class="col-2 calificacion c6">
            <p style="font-size: 11px;font-weight:700">Precisión de error crítico</p>
             <h4 style="font-weight:700">{{puntaje_precision}}%</h4>
        </mat-card>
    


    </mat-card-header>


    <div style="overflow: auto; height: 356px;">
        <div fxLayout="column" [formGroup]="moduloForm">
            <div formArrayName="modulos" [togglePosition]="'before'"
                *ngFor="let modulo of getModulos().controls; let indexModulo = index">
                <mat-expansion-panel class="mt-4" id="modulo" [expanded]="indexModulo == 0" fxFlex.gt-lg="100%"
                    fxFlex.gt-md="100%" [formGroupName]="indexModulo" >
                    <mat-expansion-panel-header>
                        <mat-panel-title fxLayout="row" fxFlex="100%" class="content-module">
                                <div fxLayout="col-6" fxFlex="75%">
                                    <p class="sigla" fxFlex.gt-lg="4%" fxFlex.gt-md="8%"
                                        [matTooltip]="modulo.get('descripcion').value" [matTooltipPosition]="'above'">
                                        {{modulo.get('sigla').value}}</p>
                                    <p style="font-weight: 600;padding-top: .3em;"
                                        [matTooltip]="modulo.get('nombre').value" [matTooltipPosition]="'above'"
                                        fxFlex.gt-lg="80%" fxFlex.gt-md="80%">
                                        {{ modulo.get('nombre').value | cutText:0:35 }}</p>
                                        &nbsp;
                                   
                                </div>
                                <div fxLayout="col-3" fxFlex="15%">
                                     <mat-chip-list align="right" class="mat-chip-list-stacked" *ngIf="modulo.get('error_critico').value == '1' " > 
                                        <mat-chip selected style="background-color: red;">
                                            <i class="fi fi-rr-exclamation"></i> &nbsp;
                                            <b> Crítico </b>
                                        </mat-chip> 
                                    </mat-chip-list>
                                
                                 
                                </div>

                                <div fxLayout="col-3" fxFlex="10%">

                                     <mat-chip-list align="right" class="mat-chip-list-stacked" > 
                                        <mat-chip selected style="color: #51B0CB;background-color:white; padding-top:17px">
                                             <p class="peso" *ngIf="modulo.get('peso').value && tipo_matriz != 'Cumplimiento'"
                                             fxFlex.gt-lg="100%" fxFlex.gt-md="100%">Peso: {{modulo.get('peso').value}}</p>
                                             <p class="peso" *ngIf="modulo.get('peso').value && tipo_matriz == 'Cumplimiento'"
                                             fxFlex.gt-lg="100%" fxFlex.gt-md="100%">Peso: {{modulo.get('peso').value}}%</p>
                                        </mat-chip> 
                                    </mat-chip-list>                                    
                                 
                                </div>

                              </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div formArrayName="items_modulo"
                        *ngFor="let item of getItems(indexModulo).controls; let iItem = index">
                        <div [formGroupName]="iItem">

                            
                            <mat-expansion-panel [togglePosition]="'before'" style="margin-top:5px" id="{{item.get('padre_id').value}}_{{item.get('id').value}}_panel"

                            [expanded]="true"
                            (opened)="isExpanded = true"
                            (closed)="isExpanded = false" hideToggle  (click)="$event.stopPropagation()"
     >
                                <mat-expansion-panel-header style="min-height:120px; padding-top:20px;  overflow:auto">
                                   
                                    <mat-panel-title fxLayout="row" fxFlex.gt-lg="80%" fxFlex.gt-md="80%" (click)="$event.stopPropagation()">
                                        
                                        <p class="sigla" fxFlex.gt-lg="10%" fxFlex.gt-md="24%">
                                            {{item.get('sigla').value}}</p>
                                        <button mat-icon-button [disabled]="!item.get('descripcion').value">
                                            <mat-icon style="font-size: 11.5px; margin-top: -.7em;"
                                                [matTooltip]="item.get('descripcion').value"
                                                [matTooltipPosition]="'above'"><i class="fi-rr-document"></i></mat-icon>
                                        </button>
                                        <p style="font-size: 11.5px;padding-top: .3em;"
                                            [matTooltip]="item.get('nombre').value" [matTooltipPosition]="'above'"
                                            fxFlex.gt-lg="90%" fxFlex.gt-md="90%">
                                            {{item.get('nombre').value | cutText:0:10000}}
                                        </p>
                                        

                                    </mat-panel-title>

                                    <mat-panel-description fxLayout="row" align="right" fxFlex.gt-lg="20%" fxFlex.gt-md="20%" (click)="$event.stopPropagation()">
                                        
                                        <div *ngIf="data.tipo_matriz == 'Cumplimiento'">
                                            <mat-radio-group aria-label="Select an option"
                                                (change)="cantidadDecimales();calificacionItem(indexModulo,iItem)"
                                                formControlName="calificacion">
                                                <mat-radio-button value="cumple" (click)="$event.stopPropagation()" id="{{item.get('padre_id').value}}_{{item.get('id').value}}_1"   (change)="calificar_totales()">
                                                    Cumple</mat-radio-button>
                                                <mat-radio-button value="no_cumple" (click)="$event.stopPropagation()" id="{{item.get('padre_id').value}}_{{item.get('id').value}}_2"   (change)="calificar_totales()">
                                                    No cumple</mat-radio-button>
                                                <mat-radio-button [value]="null" (click)="$event.stopPropagation()"   id="{{item.get('padre_id').value}}_{{item.get('id').value}}_3"   (change)="calificar_totales()">N/A
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div *ngIf="data.tipo_peso == 'Peso en Items'">
                                            <mat-radio-group aria-label="Select an option"
                                                formControlName="calificacion"
                                                (change)="cantidadDecimales();calificacionItem(indexModulo,iItem)">
                                                <mat-radio-button value="si" (click)="$event.stopPropagation()" id="{{item.get('padre_id').value}}_{{item.get('id').value}}_1"   (change)="calificar_totales()">Si
                                                </mat-radio-button>
                                                <mat-radio-button value="no" (click)="$event.stopPropagation()"  id="{{item.get('padre_id').value}}_{{item.get('id').value}}_2"   (change)="calificar_totales()">No
                                                </mat-radio-button>
                                                <mat-radio-button [value]="null" (click)="$event.stopPropagation()" id="{{item.get('padre_id').value}}_{{item.get('id').value}}_3"   (change)="calificar_totales()">N/A
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <div *ngIf="data.tipo_peso  == 'Peso en Módulos'">
                                            <mat-radio-group aria-label="Select an option"
                                                formControlName="calificacion"
                                                (change)="cantidadDecimales();calificacionItem(indexModulo,iItem)">
                                                <mat-radio-button value="si" (click)="$event.stopPropagation()" id="{{item.get('padre_id').value}}_{{item.get('id').value}}_1"   (change)="calificar_totales()">Si
                                                </mat-radio-button>
                                                <mat-radio-button value="no" (click)="$event.stopPropagation()" id="{{item.get('padre_id').value}}_{{item.get('id').value}}_2"   (change)="calificar_totales()">No
                                                </mat-radio-button>
                                                <mat-radio-button [value]="null" (click)="$event.stopPropagation()" id="{{item.get('padre_id').value}}_{{item.get('id').value}}_3"   (change)="calificar_totales()">N/A
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>

                                         
                                        

                                    </mat-panel-description>
                                    <p class="peso" *ngIf="item.get('peso').value" fxFlex.gt-lg="8%"
                                        fxFlex.gt-md="8%">Peso: {{item.get('peso').value |number :'1.0-3'}}</p>
                                </mat-expansion-panel-header>   
                                 

                                 <mat-form-field  appearance="fill" id="{{item.get('padre_id').value}}_{{item.get('id').value}}_div"  style="width:100%; display:none;"  
                                 >
                                   <mat-label>Observación</mat-label>
                                   <span id="{{item.get('padre_id').value}}_{{item.get('id').value}}_txt" style="display:none"><b>Módulo:</b> {{modulo.get('nombre').value}}, <b>Subitem:</b> {{item.get('sigla').value}}</span>
                                   <textarea placeholder="Ingrese observación" matInput  name="{{item.get('padre_id').value}}_{{item.get('id').value}}_obj" 
                                      id="{{item.get('padre_id').value}}_{{item.get('id').value}}_obj"  ></textarea>

                                    <mat-error id="{{item.get('padre_id').value}}_{{item.get('id').value}}_error">Por favor ingresar observación.</mat-error>
                                 </mat-form-field>

                                

                                                   



                                <div formArrayName="sub_items"
                                    *ngFor="let subtItem of item.get('sub_items').controls; let iSub = index"
                                    fxLayout="row">
                                    <div [formGroupName]="iSub">
                                        <mat-expansion-panel [togglePosition]="'before'">
                                            <mat-expansion-panel-header style="min-height:120px; padding-top:20px; overflow:auto">
                                                <mat-panel-title fxFlex.gt-lg="40%" fxFlex.gt-md="34%">
                                                    <p class="sigla-sub ms-4"
                                                        [ngClass]="{'sigla-disabled': (item.get('calificacion').value === null || (item.get('calificacion').value === 'cumple' || item.get('calificacion').value === 'si'))}">
                                                        {{subtItem.get('sigla').value}}</p>
                                                    <button mat-icon-button
                                                        [disabled]="!subtItem.get('descripcion').value">
                                                        <mat-icon style="font-size: 1.3em; margin-top: -.7em;"
                                                            [matTooltip]="subtItem.get('descripcion').value"
                                                            [matTooltipPosition]="'above'"><i
                                                                class="fi-rr-document"></i></mat-icon>
                                                    </button>
                                                    <ng-container *ngIf="screen_md; else screenLG">
                                                        <p class="sub-item" [matTooltip]="subtItem.get('nombre').value"
                                                            [matTooltipPosition]="'above'" style="11.5px;">
                                                            {{subtItem.get('nombre').value | cutText:0:10000}}</p>
                                                    </ng-container>
                                                    <ng-template #screenLG>
                                                        <p class="sub-item" [matTooltip]="subtItem.get('nombre').value"
                                                            [matTooltipPosition]="'above'" style="11.5px;">
                                                            {{subtItem.get('nombre').value | cutText:0:10000}}</p>
                                                    </ng-template>
                                                </mat-panel-title>
                                                <mat-panel-description>
                                                    <mat-radio-group aria-label="Select an option"
                                                        (change)="cantidadDecimales();calificacionSubItem(indexModulo,iItem,iSub)"
                                                        formControlName="calificacion">
                                                        <mat-radio-button value="cumple"
                                                            (click)="$event.stopPropagation()">Cumple</mat-radio-button>
                                                        <mat-radio-button value="no_cumple"
                                                            (click)="$event.stopPropagation()">No cumple
                                                        </mat-radio-button>
                                                        <mat-radio-button [value]="null"
                                                            (click)="$event.stopPropagation()">N/A</mat-radio-button>
                                                    </mat-radio-group>
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                        </mat-expansion-panel>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </div>
                    </div>
                    <ng-container>
                    </ng-container>
                </mat-expansion-panel>
            </div>
        </div>
    </div>
</mat-card>