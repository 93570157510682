import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { AuthService } from "../../../../core/services/rest/auth.service";
import { throwError, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { Campaings } from "../../modules/class/Campaigns";

const NOMINA_URL = environment.NOMINA_URL;

@Injectable({
  providedIn: "root",
})
export class CampaignsService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * @author José Vicente Silva
   * @createdate 2021-02-02
   * Servicio que lista todos los groupes del aplicativo ciu
   * @param size tamaño de los datos a traer
   * @param page numero de pagina en la que se ubicara la tabla
   */
  getCampaigns(isPage: number, size: number, page: number): Observable<any> {
    return this.http
      .get<any>(
        `${NOMINA_URL}campaigns/?paginate=${isPage}&n=${size}&page=${page}`
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * Exportar campañas
   * @author Andres Buitrago
   */
  exportCampaigns(): Observable<any> {
    return this.http
      .get<any>(`${NOMINA_URL}campaigns/export`, {
        responseType: "blob" as "json",
      })
      .pipe(catchError(this.handleError));
  }

  /**
   * @author José Vicente Silva Rivera
   * @createdate 2021-03-12
   * Servicio que realiza una busqueda avanzada de groupes por un valor
   * @param size tamaño de los datos a traer
   * @param value valor a buscar
   */
  searchCampaigns(size: number, page: number, value: any): Observable<any> {
    return this.http
      .get<any>(
        `${NOMINA_URL}campaignssearch?n=${size}&page=${page}&q=${value}`
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * @author José Vicente Silva Rivera
   * @createdate 2021-02-03
   * Servicio que guarda un group en su respectiva tabla
   * @param campaign campaign a guardarse
   */
  saveCampaign(campaign: Campaings): Observable<any> {
    return this.http
      .post<any>(`${NOMINA_URL}campaigns`, campaign)
      .pipe(catchError(this.handleError));
  }

  /**
   * @author José Vicente Silva Rivera
   * @createdate 2021-03-12
   * Servicio que edita un group en su respectiva tabla
   * @param campaign campaign a editarse
   */
  editCampaign(campaign: Campaings): Observable<any> {
    return this.http
      .put<any>(`${NOMINA_URL}campaigns/update`, campaign)
      .pipe(catchError(this.handleError));
  }

  /**
   * @author José Vicente Silva Rivera
   * @createdate 2021-02-04
   * Servicio que borra una campaña en su respectiva tabla modo logico
   * @param campaign id del group a eliminarse
   * @param idcampaign id del group a eliminarse
   */
  deleteCampaing(campaign: Campaings, idcampaign: number): Observable<any> {
    return this.http
      .put<any>(`${NOMINA_URL}campaigns/${idcampaign}/updateState`, campaign)
      .pipe(catchError(this.handleError));
  }
  /**
   * @author Javier Alejandro Castañeda Celis
   * @createdate 2021-02-04
   * Servicio que obtiene todas las campañas
   */

  getAllCampaigns(): Observable<any> {
    return this.http
      .get<any>(`${NOMINA_URL}campaigns?paginate=0`)
      .pipe(catchError(this.handleError));
  }

  getCampaingsBossList(
    documentType?: any,
    document?: any,
    perPage: any = 5,
    page: any = 1
  ): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set("document", document || "")
        .set("documentType", documentType || "")
        .set("perPage", perPage)
        .set("page", page),
    };
    return this.http
      .get<any>(`${NOMINA_URL}campaingBoss`, httpOptions)
      .pipe(catchError(this.handleError));
  }

  /**
   * @author Javier Alejandro Castañeda Celis
   * @createdate 2022-03-17
   * Servicio que obtiene todas las campañas con jefe asigna o sin jefe asignado
   */
  getAllCampaignsBoss(): Observable<any> {
    return this.http
      .get<any>(`${NOMINA_URL}campaingBoss/getAllCampaigns`)
      .pipe(catchError(this.handleError));
  }

  /**
   * @author Javier Alejandro Castañeda Celis
   * @createdate 2022-03-17
   * Servicio que obtiene todas las campañas con jefe asigna o sin jefe asignado
   */
  getAllCampaignsWithoutBoss(): Observable<any> {
    return this.http
      .get<any>(`${NOMINA_URL}campaingBoss/searchUnassignedCampaigns`)
      .pipe(catchError(this.handleError));
  }

  /**
   * @author Javier Castañeda
   * @createdate 2022-03-17
   * Servico de consulta de usuario por documento y tipo de documento
   * @param document documento del usuario
   * @param documentType tipo de documento
   */
  getUserByDocument(document: any, documentType: any): Observable<any> {
    return this.http
      .get(`${NOMINA_URL}userByDocument/${document}/${documentType}`)
      .pipe(catchError(this.handleError));
  }
  /**
   * @author Javier Castañeda
   * @createdate 2022-03-17
   * Servico de consulta de campañas por id de gerente
   * @param id id de gerente de cuentas
   */
  getCampaingsByBoss(id: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set("boss_id", id || ""),
    };
    return this.http
      .get<any>(
        `${NOMINA_URL}campaingBoss/searchCampaignsByAccountManager`,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }
  /**
   * @author Javier Castañeda
   * @createdate 2022-03-17
   * Servico para la asignación de campañas a un gerente
   * @param id id de gerente de cuentas
   */
  assignCampaign(body: any) {
    return this.http
      .post<any>(`${NOMINA_URL}campaingBoss`, body)
      .pipe(catchError(this.handleError));
  }
  /**
   * @author Javier Castañeda
   * @createdate 2022-03-17
   * Servico para la asignación de campañas a un gerente
   * @param id id de gerente de cuentas
   */
  updateCampaingBoss(body: any): Observable<any> {
    return this.http
      .put<any>(`${NOMINA_URL}campaingBoss/update`, body)
      .pipe(catchError(this.handleError));
  }

  /**
   * @author Fabian Duran
   * @createdate 2023-05-19
   * Servicio que retorna todas las empresas registradas.
   */
  getCompanies(): Observable<any> {
    return this.http.get<any>(``).pipe(catchError(this.handleError));
  }
}
