import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { ScheduleService } from 'src/app/core/services/rest/schedule.service';

@Injectable({
    providedIn: "root"
  })
  export class sessionService{
    
    constructor(
      private _authService: AuthService,
      private _scheduleService: ScheduleService
    ) {}

    setItem( name: string, data: string){
        return sessionStorage.setItem(name, data)
    }

    getItem( name: string){
        return sessionStorage.getItem(name)
    }

    removeItem ( name: string  ){
      return sessionStorage.removeItem(name)
    }

    clearSession () {
      return sessionStorage.clear()
    }

    getRoleUserLivechat() {
      let user = this._authService.getUser();
      let role:string = '';      
      if (user) {
        let roles:any[] = user.roles;        
        if (roles && roles.length > 0) {
          roles.map((rol) => {            
            switch (rol) {
              case 'livechat::admin':
                role = rol;
                return rol;
              case 'livechat::supervisor':
                role = rol;
                return rol;
              case 'livechat::asesor':
                role = rol;
                return rol;
            }
          });
          return role;
        }
      }
    }  
  }