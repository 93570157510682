import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { RequestsService } from '../../../services/requests.service';
import { CandidatesService } from '../../../services/candidates.service';
import { MatAccordion } from '@angular/material/expansion';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CandidateCreateComponent } from '../../candidates/candidate-create/candidate-create.component';
import { CandidateUploadComponent } from '../../candidates/candidate-upload/candidate-upload.component';
import { RequestAssignComponent } from '../request-assign/request-assign.component';
import { CandidateCommentsComponent } from '../../candidates/candidate-comments/candidate-comments.component';
import { AuthService } from '../../../../../core/services/rest/auth.service';
import { CandidateEditComponent } from '../../candidates/candidate-edit/candidate-edit.component';
import { CandidateAssignComponent } from '../../candidates/candidate-assign/candidate-assign.component';
import { AssignTestComponent } from '../../candidate-tests/assign-test/assign-test.component';
import { CandidateAssignTrainerComponent } from '../../candidates/candidate-assign-trainer/candidate-assign-trainer.component';
import { MassiveTestsComponent } from '../../candidate-tests/massive-tests/massive-tests.component';
import { AssessmentsCreateComponent } from '../../assesments/assessments-create/assessments-create.component';


@Component({
  selector: 'app-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.sass']
})
export class RequestDetailsComponent implements OnInit {

  alertDialog: string = "";
  userId;
  public isRolOPerative : boolean;
  public isRolAdmin : boolean;
  request;
  requestId;
  viewCandidateDetails = false;

  candidatesByRequest = [];
  identification: any = '';
  email: any = '';
  mobile: any = '';
  typeFilter = null
  displayedColumns: string[] = ['actions', 'class', 'name', 'id_number', 'mobilephone', 'email', 'tracingAvg'];
  dataSource: MatTableDataSource<any>;

  displayedRecodColumns: string[] = ['user_name', 'new_values', 'created_at'];
  dataRecod: MatTableDataSource<any>;

  length: number;
  pageSize: number;
  currentPage = 1;
  showCandidates = false;

  candidateSelected;

  assignButton = 'Asignar Reclutador';


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(private activatedRoute: ActivatedRoute,
    private requestService: RequestsService,
    private candidatesService: CandidatesService,
    private dialog: MatDialog,
    private auth: AuthService) {

    this.userId = this.auth.getUser().rrhh_id;
    this.isRolOPerative = this.auth.getUser().roles.includes('rrhh::lider_operativo');
    this.isRolAdmin = this.auth.getUser().roles.includes('rrhh::lider_administrativo');
    this.requestId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getRequest();
  }

  ngOnInit(): void {    
  }

  filterByValue(event) {
    this.identification = ''
    this.email = ''
    this.mobile = ''
    switch (this.typeFilter) {
      case "Identificación":
        this.identification = event;
        break;
      case "Email":
        this.email = event
        break;
      case "Teléfono":
        this.mobile = event
        break;
    }
    this.getDataCandidates();
  }

  getRequest() {
    this.requestService.getRequestDetail(this.requestId).subscribe((resp: any) => {
      this.request = resp;
      if (resp.recruitment) { this.assignButton = 'Reasignar Reclutador'; }
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  applyFilter2(event: Event) {

    const filterValue = JSON.stringify((event.target as HTMLInputElement).value)
      .replace(/['"]+/g, '',).split('T');
    this.dataRecod.filter = filterValue[0].trim().toLowerCase();

  }

  newRegister() {
    const registerDialog = this.dialog.open(CandidateCreateComponent, {
      minWidth: '80%',
      disableClose: true,
      data: { requestId: this.requestId }
    });

    registerDialog.afterClosed().subscribe((refreshRequests) => {
      if (refreshRequests) {
        this.getDataCandidates();
      }

    });
  }

  getCandidates(event) {
    if (event.index === 1) {
      if (this.candidatesByRequest.length === 0) {
        this.getDataCandidates();
      }
    }
    else {
      this.viewCandidateDetails = false;
    }
    if (event.index === 2) {
      this.getRecord()
    }
  }

  getDataCandidates() {
    this.candidatesService.getCandidatesByRequest(this.requestId,
      { page: this.currentPage, identification: this.identification, email: this.email, mobile: this.mobile }).
      subscribe((resp: any) => {
        if (resp.total > 0) { this.showCandidates = true; }
        this.length = resp.total;
        this.pageSize = resp.per_page;
        this.dataSource = new MatTableDataSource(resp.data);
        this.dataSource.sort = this.sort;
        this.candidatesByRequest = resp.data;
      });
  }
  getPaginatorData(e) {
    this.currentPage = e.pageIndex + 1;
    this.getDataCandidates();
  }

  /**
 * @author Karol García
 * @createdate 2021-04-21
 * Metodo consulta el historial de la solicitud
 */
  getRecord() {
    this.requestService.getRecord(`requests/audit/${this.requestId}`).subscribe(list => {
      this.dataRecod = new MatTableDataSource(list);
      this.dataRecod.paginator = this.paginator;
      this.dataRecod.sort = this.sort;
    })

  }

  uploadCandidates() {
    const registerDialog = this.dialog.open(CandidateUploadComponent, {
      
      disableClose: true,
      data: { requestId: this.requestId }
    

    });

    registerDialog.afterClosed().subscribe((refreshRequests) => {
      this.getDataCandidates();

    });
  }

  /**
* @author Daniel Dominguez
* @createdate 2022-03-07
* Metodo consulta el historial de la solicitud
*/

  

  assignRecluter() {
    const assignDialog = this.dialog.open(RequestAssignComponent, {
      disableClose: true,
      data: { requestId: this.requestId, userApplicantId: this.request.applicant_user_id }
    });
    assignDialog.afterClosed().subscribe((params) => {
      if (params) {
        this.getRequest();
      }

      // this.requestService.assignRecluter(params);
    });
  }

  assingTrainer() {
    const assignTrainerDialog = this.dialog.open(CandidateAssignTrainerComponent, {
      disableClose: true,
      data: { requestId: this.requestId, userApplicantId: this.request.applicant_user_id }
    });
    assignTrainerDialog.afterClosed().subscribe((params) => {
      if (params) {
        this.getDataCandidates();
      }

      // this.requestService.assignRecluter(params);
    });
  }

  filterByIdentification(ident) {
    this.identification = ident;
    this.getDataCandidates();
  }
  filterByEmail(email) {
    this.email = email;
    this.getDataCandidates();
  }
  filterByMobile(mobile) {
    this.mobile = mobile;
    this.getDataCandidates();
  }
  getComments(candidateId) {
    const commentDialog = this.dialog.open(CandidateCommentsComponent, {
      disableClose: true,
      data: { candidateId, requestId: this.requestId, recruitmentUserId: this.request.recruitmentUserId, isRolOPerative: this.isRolOPerative }
    });
    commentDialog.afterClosed().subscribe((params) => {
      this.getDataCandidates();
    });
  }
  editCandidate(candidateId) {
    const candidateEdit = this.dialog.open(CandidateEditComponent, {
      minWidth: '80%',
      disableClose: true,
      data: { candidateId }
    });
    candidateEdit.afterClosed().subscribe((params) => {
    });
  }

  assingAnalyst() {
    const candidateAssingDialog = this.dialog.open(CandidateAssignComponent, {
      disableClose: true,
      data: { requestId: this.requestId }
    });
    candidateAssingDialog.afterClosed().subscribe((params) => {
      this.getDataCandidates();
    });
  }

  tests(candidate) {
    const candidateTestDialog = this.dialog.open(AssignTestComponent, {
      disableClose: true,
      data: {
        candidate,
        requestId: this.requestId
      }
    });
    candidateTestDialog.afterClosed().subscribe((params) => {
      this.getDataCandidates();
    });
  }
  openMassiveTests() {
    const MassiveTestsDialog = this.dialog.open(MassiveTestsComponent, {
      disableClose: true,
      data: {
        requestId: this.requestId
      }
    });
    MassiveTestsDialog.afterClosed().subscribe((params) => {
      if (params) {
        this.getDataCandidates();
      }

    });

  }
  createAssessment() {
    const AssessmentDialog = this.dialog.open(AssessmentsCreateComponent, {
      data: { action: 'Crear', requestId: this.requestId }
    });
    AssessmentDialog.afterClosed().subscribe((params) => {
      if (params) {
        this.getDataCandidates();
      }

    });
  }
}
