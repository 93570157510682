import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { AuditoriaService } from 'src/app/modules/calidad/services/auditoria.service';
 
@Injectable()
export class AuditoriasEffects {
 
  loadAuditorias$ = createEffect(() => this.actions$.pipe(
    ofType('[Auditoria] Load auditoria'),
    mergeMap((data:any) => this.auditoriaService.getRetroalimentacionById(data.idAuditoria)
      .pipe(
        map((auditorias:any) => ({ type: '[Auditoria] Loaded auditoría', auditoria: auditorias }))
      ))
    )
  );
 
  constructor(
    private actions$: Actions,
    private auditoriaService:AuditoriaService,
  ) {}
}