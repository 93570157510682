import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

const calidad_url = environment.CALIDAD_URL;

@Injectable({
  providedIn: 'root'
})
export class TranslatesService {

  public lang;

  constructor(
    private http: HttpClient
  ) { }

  getConfig(uuid) {
    const item = localStorage.getItem('locale');
    this.lang = item ? JSON.parse(item) : 'es';

    const params = {
      lang: this.lang,
      uuid: uuid,
    };
    return this.http.get(`${calidad_url}config/show`, {params});
  }
}
