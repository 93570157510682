<div class="admin-solicitudes">

    <div class="header col-6">
        <h1>Solicitudes y órdenes de compra<i class="fas fa-container-storage"></i></h1>
        <p>En este espacio podrás revisar y/o gestionar las solicitudes de compra. <br> Además, podrás agregar cotizaciones y crear órdenes de compra.</p>
    </div>

    <mat-tab-group class="remove-border-bottom" animationDuration="0ms">
        <mat-tab label="Solicitudes">
           <!--<app-gestion-inicial></app-gestion-inicial>-->
             <app-gestion-inicial-cards></app-gestion-inicial-cards>
        </mat-tab>
        <!-- <mat-tab label="Aprobadas/No aprobadas">
            <app-gestion-aprobacion></app-gestion-aprobacion>
        </mat-tab> -->
        <mat-tab label="Órdenes de compra">
            <ng-template matTabContent>
                <app-orden-compra-list></app-orden-compra-list>
            </ng-template>
        </mat-tab>
    </mat-tab-group>

</div>
