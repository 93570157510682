import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'days'
})
export class DaysPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let object =  results.find(e=>{return e.key === String(value)})
    return object? object.name : 'N/A';
  }

}
const results=[
  {key:'Monday', name: 'Lun.'},
  {key:'Tuesday', name: 'Mar.'},
  {key:'Wednesday', name: 'Mier.'},
  {key:'Wednesday', name: 'Mier.'},
  {key:'Thursday', name: 'Jue.'},
  {key:'Friday', name: 'Vie.'},
  {key:'Saturday', name: 'Sab.'},
  {key:'Sunday', name: 'Dom.'},
]
