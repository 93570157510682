import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, ValidationErrors, AbstractControl } from '@angular/forms';
import { CandidatesService } from '../../../services/candidates.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { leadingComment } from '@angular/compiler';
import { saveAs as importedSaveAs } from "file-saver";
import { DynamicFormServiceService } from 'src/app/core/services/rest/dynamic-form-service.service';
import { environment } from 'src/environments/environment';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { KindAccountService } from '../../../services/kindAccount.service';
import { NominaService } from '../../../../soul_bi/services/nomina.service';
import { map, startWith, timeout } from 'rxjs/operators';
import { isObject } from 'util';
import { Observable } from 'rxjs';
import {AddressFormComponent} from "../address-form/address-form.component";

@Component({
  selector: 'app-candidate-edit',
  templateUrl: './candidate-edit.component.html',
  styleUrls: ['./candidate-edit.component.sass']
})
export class CandidateEditComponent implements OnInit {

  civilStates = [
    {
      id: 1,
      name: 'Soltera(o)'
    },
    {
      id: 2,
      name: 'Casada(o)'
    },
    {
      id: 3,
      name: 'Unión libre'
    },
    {
      id: 4,
      name: 'Separada(o)'
    },
    {
      id: 5,
      name: 'Divorciada(o)'
    },
    {
      id: 6,
      name: 'Religiosa(o)'
    },
    {
      id: 7,
      name: 'No definida(o)'
    },
    {
      id: 8,
      name: 'Viuda(o)'
    },
    {
      id: 9,
      name: 'Indifernte'
    }
  ];

  displayedColumnsWorkExperiencies: string[] = ['actions','company_name', 'position_hired', 'boss_name', 'boss_mobile_phone', 'start_date', 'end_date'];
  displayedColumnsStudies: string[] = ['actions','education_level', 'institution', 'obtained_title', 'study_city', 'finish_date', 'periods_completed'];
  displayedColumnsFamily: string[] = ['actions','first_name', 'last_name', 'id_document_number', 'education_level', 'relationship', 'profession'];
  displayedPersonalReference: string[] = ['actions','first_name', 'last_name', 'relationship', 'mobile_phone', 'city', 'occupation' ];
  displayedColumnsDocuments = ['actions','file','state'];
  dataSourceWorkExperiences: MatTableDataSource<any>;
  dataSourceStudies: MatTableDataSource<any>;
  dataSourceFamilyGoup: MatTableDataSource<any>;
  dataSourceReferences: MatTableDataSource<any>;
  dataSourceDocuments: MatTableDataSource<any>;

  isLinear = false;
  basicFormGroup: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  sixthFormGroup: FormGroup;


  workExperiencies = [];
  studies = [];
  familyGroup = [];
  personalReferences = [];
  techTools = [];

  documents = [];
  identity_document: any = [];
  personal_reference: any = [];
  academic_reference: any = [];
  job_reference: any = [];
  adres_certified: any = [];
  ruaf_certified: any = [];
  bank_certified: any = [];
  photo: any = [];

  isEditable = true;
  data = [];
  candidateId;
  candidate;
  stepTouched = false;
  idTypes: any;
  typeData = ['basic_data', 'personal_data', 'jobs_references', 'education', 'group_family', 'personal_references', 'tech_tools', 'documents'];
  step = 'basic_data';
  now = new Date();
  documentsCandidate;
  children:FormArray;
  keyType: string;
  idDocumentType: number;
  state: any;
  epsData;
  pensionesData;
  bankData;
  currentEps;
  currentPens;
  kindAccountList;
  currentkindAccount;
  currentBank;
  campaignsData: any[] = [];
  //Otro género y nombre identitario
  show_other_field:boolean
  show_identity_name_field:boolean

  // READONLY
  read_only_city_document_id:boolean=true;
  read_only_nationality:boolean=true;
  read_only_blood_type:boolean=true;
  read_only_rh:boolean=true;
  filteredOptions: Observable<string[]>;


  constructor(private formBuilder: FormBuilder,
              private serviceCandidate: CandidatesService,
              private activatedRoute: ActivatedRoute,
              private snack: MatSnackBar,
              private alertsService : AlertsService,
              private dynamicService : DynamicFormServiceService,
              private kindAccountService : KindAccountService,
              @Inject(MAT_DIALOG_DATA) public id: any,
              public dialog: MatDialog,
              private NominaService:NominaService) {
                this.candidateId = id.candidateId;
              }

  ngOnInit(): void {
    setTimeout(res =>{this.getTypes()},1000);
    this.getTypes();
    this.getBasicData();
    this.runValidations();
    this.basicFormGroup.controls['id_type_id'].valueChanges.subscribe(resp => {
      this.idDocumentType = resp
    });
    this.getOptions('caja_de_pensiones');
    this.getOptions('eps');
    this.getOptions('banco');
    this.getKindAccounts();
    this.NominaService.getCampaings().subscribe(resp => {
      this.campaignsData = resp;
      this.filteredOptions = this.basicFormGroup.controls['cost_center'].valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value) )
      );
    });



  }

  getBasicData(){
    this.serviceCandidate.getCandidate(this.candidateId).subscribe( (resp: any) => {
      this.candidate = resp;
      console.log(this.candidate);
      this.candidate.birth_date = new Date (resp.birth_date);
      this.basicFormGroup.patchValue(this.candidate);
      this.state = resp.state.key;


    });
  }

  runValidations(){
      this.firstFormGroup = this.formBuilder.group({
          age:['', [Validators.required]],
          city_document_id: ['', [Validators.required, Validators.maxLength(20)]],
          expedition_date_id: ['', [Validators.required]],
          nationality: ['', [Validators.required, Validators.maxLength(20)]],
          alias: ['', [Validators.required, Validators.maxLength(20)]],
          place_of_birth: ['', [Validators.required, Validators.maxLength(20)]],
          social_group:[''],
          city_of_residence: ['', [Validators.required, Validators.maxLength(20)]],
          adress: ['', Validators.required],
          location:['', Validators.required],
          neighborhood: ['', [Validators.required, Validators.maxLength(20)]],
          stratum:['', Validators.required],
          housing_type: ['', Validators.required],
          gender: ['', Validators.required],
          other_gender: ['', []],
          identity_name: ['', []],
          blood_type: ['', Validators.required],
          rh: ['', Validators.required],
          emergency_contact: ['', [Validators.required, Validators.maxLength(30)]],
          emergency_number: ['', [Validators.required, Validators.min(0), Validators.max(999999999999)]],
          number_people_charge:['', Validators.required],
          number_people_at_home:['', Validators.required],
          children_number: ['', [Validators.required, Validators.min(0)]],
          children:this.formBuilder.array([]),
          military_situation: ['', Validators.required],
          //notebook_number: ['', [Validators.maxLength(20)]],
          //notebook_class: ['', [Validators.maxLength(20)]],
          //military_district_number: ['', [Validators.maxLength(20)]],
          travel_time:['', Validators.required],
          vehicle: ['', ],
          vehicle_type: ['', ],
          licence_number: ['', [Validators.maxLength(20)]],
          licence_type: ['', ],
          wage_aspiration: ['', Validators.required],
          hobbie: ['', [Validators.required, Validators.maxLength(30)]],
          shoe_size: ['', [Validators.required, Validators.maxLength(10)]],
          shirt_size: ['', [Validators.required, Validators.maxLength(10)]],
          pants_size: ['', [Validators.required, Validators.maxLength(10)]],
          caja_de_pensiones: ['', Validators.required],
          banco: ['', Validators.required],
          kind_account: ['', Validators.required],
          bank_account: ['', Validators.required],
          bank_account_comfirm: ['', [Validators.required, this.matchValues('bank_account')]],
          eps: ['', Validators.required],

      });
      this.children = this.firstFormGroup.get('children') as FormArray;
      if (this.workExperiencies.length > 0){
      this.secondFormGroup = this.formBuilder.group({
            id: [],
            company_name: ['', [Validators.maxLength(20)]],
            company_phone: ['', [Validators.min(1), Validators.max(999999999999)]],
            position_hired: ['', [Validators.maxLength(20)]],
            company_city: ['', [Validators.maxLength(20)]],
            boss_name: ['', [Validators.maxLength(40)]],
            boss_phone: ['', [Validators.min(1), Validators.max(999999999999)]],
            boss_position: ['', Validators.maxLength(40)],
            boss_mobile_phone: ['', ],
            current_job: ['', [Validators.maxLength(20)]],
            start_date: ['', ],
            end_date: ['', ],
            earned_salary: ['', ],
            retirement_reason: ['', ],
            functions_achievements: ['', [Validators.maxLength(500)]],
          });
    }else {
      this.secondFormGroup = this.formBuilder.group({
            id: [],
            company_name: ['', [Validators.required, Validators.maxLength(20)]],
            company_phone: ['', [Validators.required, Validators.min(1), Validators.max(999999999999)]],
            position_hired: ['', [Validators.required, Validators.maxLength(20)]],
            company_city: ['', [Validators.required, Validators.maxLength(20)]],
            boss_name: ['', [Validators.required, Validators.maxLength(40)]],
            boss_phone: ['', [Validators.required, Validators.min(1), Validators.max(999999999999)]],
            boss_position: ['', [Validators.required, Validators.maxLength(40)]],
            boss_mobile_phone: ['', [Validators.min(1), Validators.max(999999999999)]],
            current_job: ['', [Validators.required, Validators.maxLength(20)]],
            start_date: ['', Validators.required],
            end_date: ['', Validators.required],
            earned_salary: ['', Validators.required],
            retirement_reason: ['', Validators.required],
            functions_achievements: ['', [Validators.required, Validators.maxLength(500)]],
      });
    }
      if (this.studies.length > 0) {
      this.thirdFormGroup = this.formBuilder.group({
        id: [],
        currently_studying: ['', ],
        education_level: ['', ],
        institution: ['', [Validators.maxLength(100)]],
        obtained_title: ['', [Validators.maxLength(100)]],
        study_city: ['', [Validators.maxLength(100)]],
        finish_date: ['', ],
        periods_completed: ['', [Validators.min(0), Validators.max(999)]],
      });
    }else{
      this.thirdFormGroup = this.formBuilder.group({
        id: [],
        currently_studying: ['', Validators.required],
        education_level: ['', Validators.required],
        institution: ['', [Validators.required, Validators.maxLength(100)]],
        obtained_title: ['', [Validators.required, Validators.maxLength(100)]],
        study_city: ['', [Validators.required, Validators.maxLength(100)]],
        finish_date: ['', Validators.required],
        periods_completed: ['', [Validators.required, Validators.min(0), Validators.max(999)]],
      });
    }
      this.fourthFormGroup = this.formBuilder.group({
      id: [],
      id_document_type: ['', ],
      id_document_number: ['', Validators.maxLength(15)],
      city_document_id: ['', Validators.maxLength(20)],
      expedition_date_id: ['', ],
      first_name: ['', Validators.maxLength(20)],
      middle_name: ['', Validators.maxLength(20)],
      last_name: ['', Validators.maxLength(20)],
      second_last_name: ['', Validators.maxLength(20)],
      education_level: ['', ],
      relationship: ['', ],
      gender: ['', ],
      birthdate: ['', ],
      profession: ['', Validators.maxLength(30)],
    });
      if (this.personalReferences.length > 0){
      this.fifthFormGroup = this.formBuilder.group({
        id: [],
        first_name: ['', [Validators.maxLength(20)]],
        middle_name: ['', ],
        last_name: ['', [Validators.maxLength(20)]],
        second_last_name: ['', ],
        relationship: ['', ],
        mobile_phone: ['', [Validators.min(1), Validators.max(999999999999)]],
        phone: ['', ],
        city: ['', [Validators.maxLength(20)]],
        occupation: ['', ],
      });
    }else{
      this.fifthFormGroup = this.formBuilder.group({
        id: [],
        first_name: ['', [Validators.required, Validators.maxLength(20)]],
        middle_name: ['', ],
        last_name: ['', [Validators.required, Validators.maxLength(20)]],
        second_last_name: ['', ],
        relationship: ['', Validators.required],
        mobile_phone: ['', [Validators.required, Validators.min(1), Validators.max(999999999999)]],
        phone: ['', ],
        city: ['', [Validators.required, Validators.maxLength(20)]],
        occupation: ['', Validators.required],
      });
    }
      this.sixthFormGroup = this.formBuilder.group({
      computer: ['', ],
      computer_type: ['', ],
      ram: ['', ],
      processor: ['', [Validators.maxLength(100)]],
      internet: ['', ],
      internet_capacity: ['', [Validators.maxLength(100)]],
    });

      this.basicFormGroup = this.formBuilder.group({
        id_type_id: ['', ],
        id_number: ['', ],
        number_PA: ['', ],
        first_name: ['', [Validators.maxLength(100)]],
        middle_name: ['', Validators.maxLength(100)],
        last_name: ['', [Validators.maxLength(100)]],
        second_last_name: ['', Validators.maxLength(100)],
        birth_date: ['', Validators.required],
        civil_state_id: ['', Validators.required],
        email: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
        email2: ['', [Validators.email, Validators.maxLength(100)] ],
        mobile_phone: ['', [Validators.required, Validators.min(1), Validators.max(999999999999)]],
        phone: ['', [Validators.min(1), Validators.max(999999999999)]],
        cost_center: ['', []]
      });

  }
  updateMatTable(arrayData, arrayDataSource, data){
    if((Object.keys(data).length != 0)){
      this[arrayData] = [...data];
    this[arrayDataSource] = new MatTableDataSource(this[arrayData]);
    }


  }
  matchValues( matchTo: string ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }

  onTabChanged(event){
    this.step = this.typeData[event.index];
    this.serviceCandidate.getCandidateDataJson(this.candidateId, this.step).subscribe( (resp: any) => {
      if (this.step === 'personal_data') {
       this.firstFormGroup.patchValue(resp);
       if(resp.caja_de_pensiones.name && resp.eps.name)
       {
        this.firstFormGroup.patchValue({
          eps: resp.eps.name,
          caja_de_pensiones: resp.caja_de_pensiones.name,
          banco: resp.banco.name,
          kind_account: resp.kind_account.name
        });
       if(this.isObject(resp.banco)){
         this.bancoChange(resp.banco?.name);
       }
       if(this.isObject(resp.kind_account)){
         this.kindAccountChange(resp.kind_account?.name);
      }
        this.epsChange(resp.eps.name);
        this.pensionesChange(resp.caja_de_pensiones.name);
       }

       this.genderFields(this.firstFormGroup.controls.gender.value)
      }
      if (this.step === 'documents'){
        this.getDocumentsCandidate();
      }
      if (this.step === 'jobs_references'){
        this.updateMatTable('workExperiencies', 'dataSourceWorkExperiences', resp);
      }
      if (this.step === 'education'){
        this.updateMatTable('studies', 'dataSourceStudies', resp);
      }
      if (this.step === 'group_family'){
        this.updateMatTable('familyGroup', 'dataSourceFamilyGoup', resp);
      }
      if (this.step === 'personal_references'){
        this.updateMatTable('personalReferences', 'dataSourceReferences', resp);
      }
    });
    setTimeout(() => {
      this.validacion();
    }, 1000);
  }

  validacion(){
    if(this.id.type=='edit'){ // Rol Nomina
      this.read_only_city_document_id=false;
      this.read_only_nationality=false;
      this.read_only_blood_type=false;
      this.read_only_rh=false;

      // Si ya tienen registros, estos campos no se pueden editar, solo sera de lectura.
      if(this.firstFormGroup.value.blood_type){
        this.read_only_blood_type=true;
      }

      if(this.firstFormGroup.value.city_document_id){
        this.read_only_city_document_id=true;
      }

      if(this.firstFormGroup.value.nationality){
        this.read_only_nationality=true;
      }

      if(this.firstFormGroup.value.rh){
        this.read_only_rh=true;
      }
    }else{ // Rol de RRHH
      this.read_only_city_document_id=false;
      this.read_only_nationality=false;
      this.read_only_blood_type=false;
      this.read_only_rh=false;
    }
  }

  saveJsonData(nameJson, data){
    console.log(JSON.stringify(data));
    this.serviceCandidate.createJsonCandidate(this.candidateId, nameJson, JSON.stringify(data) ).subscribe(e => {
      this.snack.open('Datos Guardados', '', {
        duration: 2500
      });
    });
  }
  editFill(entry, form){
    this[form].patchValue(entry);
  }
  deleteFill(id, arrayData, arrayDataSource, endpoint?){
    const index = this[arrayData].map(e => e.id).indexOf(id);
    this[arrayData].splice(index, 1);
    this[arrayDataSource] = new MatTableDataSource(this[arrayData]);
    this.runValidations();
    this.saveJsonData(endpoint, this[arrayData]);
  }
  updated() {
    let formData;
    if (this.step === 'basic_data') {
      formData =  this.basicFormGroup.value;
      this.saveJsonData(this.step, formData);
    }
    if (this.step === 'personal_data') {
      if(this.firstFormGroup.valid)
      {
        this.firstFormGroup.get('eps').setValue(this.currentEps);
        this.firstFormGroup.get('caja_de_pensiones').setValue(this.currentPens);
        this.firstFormGroup.get('banco').setValue(this.currentBank);
        this.firstFormGroup.get('kind_account').setValue(this.currentkindAccount);

        formData =  this.firstFormGroup.value;
        this.saveJsonData(this.step, formData);
        this.firstFormGroup.patchValue({

          eps: this.currentEps.name,
          caja_de_pensiones: this.currentPens.name,
          banco: this.currentBank.name,
          kind_account: this.currentkindAccount.name,
        });
      }else{
        this.alertsService.alertError('Valida el formulario', 'Completa los campos obligatorios por favor')
      }

    }
    if (this.step === 'jobs_references') {
      formData =  this.secondFormGroup.value;
      this.add('secondFormGroup', 'workExperiencies', 'dataSourceWorkExperiences', this.step);
    }
    if (this.step === 'education') {
      formData =  this.thirdFormGroup.value;
      this.add('thirdFormGroup', 'studies', 'dataSourceStudies', this.step);
    }
    if (this.step === 'group_family') {
      formData =  this.fourthFormGroup.value;
      this.add('fourthFormGroup', 'familyGroup', 'dataSourceFamilyGoup', this.step);
    }
    if (this.step === 'personal_references') {
      formData =  this.fifthFormGroup.value;
      this.add('fifthFormGroup', 'personalReferences', 'dataSourceReferences', this.step);
    }
    if (this.step === 'tech_tools') {
      formData =  this.sixthFormGroup.value;
      this.saveJsonData(this.step, formData);
    }

  }
  add(form, arrayData, arrayDataSource, endpoint?) {
    if (this[form].value.id != null){
      const index = this[arrayData].map(e => e.id).indexOf(this[form].value.id);
      this[arrayData][index] = this[form].value;
    }else{
        const params = {
        ...this[form].value,
        id: new Date().getTime()
      };
        this[arrayData].push(params);
    }
    this[arrayDataSource] = new MatTableDataSource(this[arrayData]);
    this.clearForm(form);
    this.runValidations();
    this.saveJsonData(endpoint, this[arrayData]);
  }

  clearForm(form){
    this[form].reset();
    this[form].untouched;
  }
  getDocumentsCandidate() {
    this.serviceCandidate.getAttachments(this.candidate.id).subscribe( resp => {
      this.documentsCandidate = resp;
      this.dataSourceDocuments = new MatTableDataSource(this.documentsCandidate);
    });
  }
  downdloadFile(fileId,fileName){
    let direction= `candidate/download/attachment/${this.candidateId}/${fileId}`
    this.serviceCandidate.downdloadFile(direction).subscribe( (file) =>{
      importedSaveAs(file, fileName);
    })
  }
  getChildrensInputs(){
    this.children.controls =[]
    if(typeof this.firstFormGroup.get('children_number').value == 'number' && this.firstFormGroup.get('children_number').value <20){
      for (let index = 0; index < this.firstFormGroup.get('children_number').value; index++) {
        this.children.push(this.formBuilder.group({
            name: this.candidate?.personal_data?.children[index]?.name != '' || null ? this.candidate?.personal_data?.children[index]?.name : '',
            age: this.candidate?.personal_data?.children[index]?.age != '' || null ? this.candidate?.personal_data?.children[index]?.age : '',
          }))
      }
    }
  }
  /**
     * Metodo el cual lista dinamicamente los tipos de documentos
     * @author Lina Hueso
     * @return json
     */
   getTypes() {
    this.serviceCandidate.getIdTypes().subscribe(resp => {
      this.idTypes = resp;

      if(this.basicFormGroup.controls['id_type_id'].value)
      {
        let json = this.idTypes.find(element => element.id == this.basicFormGroup.controls['id_type_id'].value);
        this.keyType = json.key;
      }
    }
    );
  }

  /**
     * Metodo el cual setea el nuevo valos del key type segun seleccion del usuario
     * @author Lina Hueso
     * @return string
     */
   selectedIdtype(value) {
    this.keyType = value;
  }


  /**
     * Metodo que  llama los options desde bade de datos
     * @author Carlos Nieto
     * @date 14-07-2022
     * @return json
     */

  getOptions(question) {
    this.dynamicService.getData('rrhh',`dropdown/options/${question}`).subscribe( (options:any) => {
          if(question == 'eps')
          {
            this.epsData = options;
          }
          if(question == 'caja_de_pensiones')
          {
            this.pensionesData = options;
          }
          if(question == 'banco')
          {
            this.bankData = options;
          }
      });
  }

   /**
     * Metodo que valida el campo de eps para neviar el json completo
     * @author Carlos Nieto
     * @date 19-07-2022
     * @return json
     */
  epsChange(value)
  {
    this.currentEps = this.epsData.find(element => element.name == value);
  }

  /**
     * Metodo que valida el campo de pensiones para neviar el json completo
     * @author Carlos Nieto
     * @date 19-07-2022
     * @return json
     */
  pensionesChange(value)
  {
    this.currentPens = this.pensionesData.find(element => element.name == value);
  }

  /**
     * Metodo que valida el campo de pensiones para neviar el json completo
     * @author Carlos Nieto
     * @date 19-07-2022
     * @return json
     */
   bancoChange(value)
   {
       this.currentBank = this.bankData.find(element => element.name == value);
   }
/**
     * Metodo que valida los errores del formulario
     * @author Carlos Nieto
     * @date 14-07-2022
     * @return
     *      */
  get error(): any { return this.firstFormGroup.controls; }


  /**
     * Metodo el cual lista los tipos de cuentas bancarias
     * @author Carlos Nieto
     * @return json
     */
   getKindAccounts() {
      this.kindAccountService.getKindAccounts().subscribe(resp => {
      this.kindAccountList = resp;
      }
    );
  }

  /**
     * Metodo que valida el campo de kind_account el json completo
     * @author Carlos Nieto
     * @date 13-09-2022
     * @return json
     */
   kindAccountChange(value)
   {
     this.currentkindAccount = this.kindAccountList.find(element => element.name == value);
   }
  /**
   * metodo para validar si el valor recibido es objeto
   * @author Nicoll ramirez
   */
  isObject(val){
    return (typeof val === 'object');
  }

    /**
   * validacion de campos numericos
   */
    keyPress(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
    }
  }

  private _filter(value: string): string[] {

    const filterValue = value.toString().toLowerCase();
    return this.campaignsData.filter(({name}) => name.toLowerCase().includes(filterValue));
  }

  displayFn(centroCostos: any): string {
    if (!centroCostos) return '';
    return this.campaignsData.find(cc => cc.id === centroCostos).name;
  }

  showAddressForm() {
    const dialogRef = this.dialog.open(AddressFormComponent, {
      width: '100%',
      data: {address: this.firstFormGroup.controls.adress.value ?? null},
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.firstFormGroup.controls.adress.setValue(result);
      }
    });
  }

  /**
   * Método que define la visualización de los campos para ingresar un genero personalizado y un nombre identitario
   * @author Juan Carlos Alonso
   * @date 09-09-2024
   */
  genderFields(value: string): void {
    this.show_identity_name_field = (value === 'Otro' || value === 'Transgénero' || value === 'No binario') ? true : false

    if(value === 'Otro'){
      this.show_other_field = true
      this.firstFormGroup.controls.other_gender.setValidators([Validators.required])
    }else{
      this.show_other_field = false
      this.firstFormGroup.controls.other_gender.setValue('')
      this.firstFormGroup.controls.other_gender.setValidators([])
    }
    this.firstFormGroup.controls.other_gender.updateValueAndValidity()
  }
}
