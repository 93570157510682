<i class="fi-rr-cross-small" style="cursor: pointer; float: right; font-size: 32px" mat-icon-button
  mat-dialog-close></i>
<div class="row novelty-row-padding" style="overflow: hidden;">
  <h2 mat-dialog-title>
    <div class="title content" style="width: 100%;">
      <span style="margin-left: 60px;">
        <h1> Gestión Novedad </h1>
      </span>
    </div>
  </h2>

  <mat-dialog-content style="padding: 0 12px; overflow-x: hidden;" class="mat-typography">
    <mat-divider style=" margin-left: 20px; border: 1px solid #A9A9A9; margin-bottom: 36px;"></mat-divider>

    <form [formGroup]="justifyForm">
      <mat-form-field appearance="fill" style="width: 97%; margin-left: 20px;">
        <mat-label>Tipo de justificación</mat-label>
        <mat-select formControlName="type">
          <mat-option  *ngFor="let item of justifyTipes" [value]="item.id">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 97%; margin-left: 20px;" *ngIf="justifyForm.controls.type.value !== 'sistema_fuera_linea'">
        <mat-label>Justificación</mat-label>
        <textarea matInput formControlName="response" placeholder="Justificación o número de ticket"></textarea>
        <mat-error *ngIf="justifyForm.controls.response.hasError('required')">¡Advertencia! Este campo es requerido</mat-error>
        <mat-error *ngIf="justifyForm.controls.response.hasError('maxlength')">¡Cuidado! Solo puedes tener un máximo de 250 caracteres</mat-error>
      </mat-form-field>

      <mat-form-field style="width: 97%; margin-left: 20px;" appearance="fill" *ngIf="justifyForm.controls.type.value == 'sistema_fuera_linea'">
        <mat-label>Número de GLPI</mat-label>
        <input type="number" matInput  formControlName="response" placeholder="Número de GLPI">

      </mat-form-field>
      <mat-error style="font-size: 11px !important; margin-left: 20px;" *ngIf="boolLength == true">¡Cuidado! Solo puedes tener un mínimo de 6 dígitos o un máximo de 10 dígitos</mat-error>

    </form>
  </mat-dialog-content>

  <div mat-dialog-actions align="end" style="margin-bottom: 10px; margin-top: 48px;">
    <button mat-raised-button class="cancelButton" style="width: 192px; height: 48px;" mat-dialog-close>Cancelar</button>

    <button mat-raised-button color="primary" class="myButton2"
    style="width: 192px; height: 48px; color:white;"
    (click)="saveObservation()" [disabled]="justifyForm.invalid || boolLength == true">Guardar</button>
  </div>

</div>
