import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import esLocale from '@fullcalendar/core/locales/es';
import { HealthService } from '../../modules/schedules/services/health.service';
import { EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'shared-booking-reserve',
  templateUrl: './booking-reserve.component.html',
  styleUrls: ['./booking-reserve.component.sass']
})
export class BookingReserveComponent implements OnInit {
  @Input () optionsBooking: {};
  @Output () daySelected: EventEmitter<string> = new EventEmitter();  
  @Output () itemSelected: EventEmitter<number> = new EventEmitter();  

  hours: Observable<string>[]= [];
  details: Observable<string>[]= [];
  minutes;
  newEventsItems;
  displayDetails=false;
  displayItems=false;
  @ViewChild('calendar') calendar: FullCalendarComponent;

  public calendarOptions: CalendarOptions = {};
  constructor(private healthService:HealthService) { }

  ngOnInit(): void {

    this.displayItems=false;
    this.calendarOptions = {
      initialView: 'dayGridMonth',
      locales: [esLocale],
      dateClick: this.dateClick.bind(this),
      showNonCurrentDates: false,      
      locale: 'es', // the initial locale. of not specified, uses the first one
      headerToolbar: {
        left: 'title,prev,next',
        center: '',
        right: ''     
      },
      titleFormat: {
        month: 'long',
        year: 'numeric'
      },
      events: this.optionsBooking['eventsCalendar'],
      handleWindowResize: true,      
      windowResizeDelay: 200 // milliseconds before a rerender happens
    };

    if (this.optionsBooking['typeDetail']=='items'){
      this.showItems();      
    };

    
  }
  dateClick(arg): void {

    this.optionsBooking['daySelectedBooking']=arg.dateStr;
    this.daySelected.emit(arg.dateStr);

  }
  
  showItems(){
    let hours_1=[];
    let compareHour="";
    this.optionsBooking['eventsItems'].forEach(element=>{
      let dato= (element['times']+"").substring(0,2);
      if (dato!=compareHour){
        hours_1.push(dato);
        compareHour=dato;
      }
    });
    this.hours=hours_1;
    this.displayItems=true;

  }

  showDetails(){
    
  }

  clickItem(id){
    this.itemSelected.emit(id);
  }

}
