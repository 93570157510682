import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ControlService } from 'src/app/modules/calidad/services/control.service';
import { MatrizService } from 'src/app/modules/calidad/services/matriz.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';

@Component({
  selector: 'app-dialog-grupo',
  templateUrl: './dialog-grupo.component.html',
  styleUrls: ['./dialog-grupo.component.sass']
})
export class DialogGrupoComponent implements OnInit {

  titulo:string = '';
  grupoForm:FormGroup;

  supervisores = [];
  campaigns = [];
  nombre_grupo:string = '';
  nombre_supervisor;

  companiaMatriz;

  constructor(
    @Inject(MAT_DIALOG_DATA) public datos:any,
    private grupoRef:MatDialogRef<DialogGrupoComponent>,
    private fb:FormBuilder,
    private controlService:ControlService,
    private matrizService:MatrizService,
    ) {}
    

  ngOnInit(): void {
    this.getSelects();
    this.createForm();
    this.edit();
  }

  createForm(){
    if (this.datos.type == 'crear') {
      this.titulo = 'Crear grupo'
    }else if (this.datos.type == 'editar') {
      this.titulo = 'Editar grupo'
    }else{
      this.titulo = '';
    }

    if ( typeof this.datos.campania === 'object') {
      this.companiaMatriz = this.datos.campania.id
    }
    if( this.datos.campania.length == 0){
      this.companiaMatriz = 'ninguna';
    }
    this.grupoForm = this.fb.group({
      campania:[{value:this.companiaMatriz,disabled:true}],
      supervisor_id: ['',Validators.required],
      nombre: [{value:'',disabled:true}],
      correo_jefe_opraciones: ['',[Validators.required,Validators.email]],
      correo_supervisor: ['',[Validators.required,Validators.email]],
    });
    
  }

  enableNombre(selectSupervisor:MatSelect){
    const data ={
      idMatriz: this.datos.idMatriz,
      idSupervisor: this.grupoForm.get('supervisor_id').value
    }
    if (selectSupervisor.value != null) {  
      this.nombre_supervisor = (selectSupervisor.selected as MatOption).viewValue;    
      this.controlService.getConsecutivo(data).subscribe((resp:any)=>{
        this.nombre_grupo = this.datos.matriz.replace(' ','-') + '_' + this.nombre_supervisor.replace(' ','-')+'_'+resp;
        this.grupoForm.get('nombre').setValue(this.nombre_grupo);
      });
    }else{
      this.nombre_grupo = '';
      this.grupoForm.get('nombre').setValue(this.nombre_grupo);
    }
  }

  edit(){
    if (this.datos.type == 'editar') {
      this.controlService.getGrupoById(this.datos.id_grupo).subscribe((resp:any)=>{
        this.grupoForm.get('supervisor_id').setValue(resp[0].supervisor_id);
        this.grupoForm.get('nombre').setValue(resp[0].nombre);
        this.grupoForm.get('correo_jefe_opraciones').setValue(resp[0].correo_jefe_opraciones);
        this.grupoForm.get('correo_supervisor').setValue(resp[0].correo_supervisor);
        this.nombre_supervisor = resp[0].nombre_supervisor;
      });
    }
  }

  updateGrupo(){
    if (this.grupoForm.invalid) {
      return;
    }
    this.grupoForm.get('nombre').enable();
    Object.assign(this.grupoForm.value,{id:this.datos.id_grupo, idMatriz: this.datos.idMatriz,nombre_supervisor:this.nombre_supervisor});
    this.controlService.updateGroupById(this.grupoForm.value).subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202) {
        Tools.swalSuccess('¡Excelente!','El grupo se ha actualizado con éxito')
        this.closeDialog();
      }else{
        this.grupoForm.get('nombre').disable();
        Tools.swalError('¡Oops!','Lo sentimos, ha ocurrido un error inesperado y no fue posible actualizar el grupo, por favor intenta nuevamente.');
      }
    });
  }

  getSelects(){
    this.controlService.getSupervisores().subscribe((resp:any)=>{
      this.supervisores = resp;
    });
    this.matrizService.getCampaings().subscribe((resp:any)=>{
      this.campaigns = resp.data;
    });
  }

  closeDialog(){
    this.grupoRef.close();
  }

  createGroup(){
    if (this.grupoForm.invalid) {
      return
    }
    this.grupoForm.get('nombre').enable();
    this.grupoForm.addControl('nombre_supervisor',new FormControl(this.nombre_supervisor));
    this.grupoForm.get('supervisor_id').setValue(this.grupoForm.get('supervisor_id').value);
    Object.assign(this.grupoForm.value,{idMatriz: this.datos.idMatriz})
    this.controlService.createGroup(this.grupoForm.value).subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202) {
        Tools.swalSuccess('¡Excelente!','El grupo se ha creado con éxito')
        this.closeDialog();
      }else{
        this.grupoForm.get('nombre').disable();
        Tools.swalError('¡Oops!','Lo sentimos, ha ocurrido un error inesperado y no fue posible crear el grupo, por favor intenta nuevamente.');
      }
    });
  }

}
