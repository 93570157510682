<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>
  <h3>¿Desea modificar este usuario?</h3>
  <mat-divider></mat-divider>
  <mat-tab-group mat-align-tabs="center" #tabGroup>
    <mat-tab label="Listar o inactivar usuario">
    <div class="details">
        <p><strong> Listar o inactivar usuario</strong></p>
        <form [formGroup]="status" class="comment-form">
            <mat-form-field appearance="fill" >
                <mat-label>Estado</mat-label>
                <mat-select formControlName="state"  >
                    <mat-option [value]="item.id" *ngFor="let item of user">
                    {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" >
                <mat-label>Comentario</mat-label>
                <textarea matInput formControlName="comment" rows="5"></textarea>
  
            </mat-form-field>
            <button mat-flat-button color="primary" (click)="send()">Guardar</button>
        </form>
    </div>
  </mat-tab>
  </mat-tab-group>