import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cutText'
})
export class CutTextPipe implements PipeTransform {

  transform(value: string,start:number,end:number): string {
    value = value.slice(start,end);
    if (value.length >= end) {
      value = value + '...';
    }
    return value;
  }

}
