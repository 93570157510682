import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardInterface } from '../../interfaces/DashboardInterface';
import { DashboardService } from '../../services/dashboard.service';
import { NominaService } from '../../services/nomina.service';
import { RrhhService } from '../../services/rrhh.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {EditConfigurationComponent} from '../edit-configuration/edit-configuration.component'
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CampaignInterface } from '../../interfaces/CampaignInterface';
import { PositionInterface } from '../../interfaces/PositionInterface';
import { CandidatesService } from 'src/app/modules/rrhh/services/candidates.service';




@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.sass']
})
export class ConfigurationComponent implements OnInit {

  dashboards          : MatTableDataSource<DashboardInterface>
  displayedColumns    : string[] = ['accion' , 'name' , 'key'];
  pageNo              : number = 0;
  filteredCampaigns   : Observable<CampaignInterface[]>;
  filteredPositions   : Observable<PositionInterface[]>;
  formFilters         : FormGroup;
  campaigns           : CampaignInterface[];
  positions           : PositionInterface[];
  type_documents;



  public formulario;


  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(
      private dashboardService    : DashboardService,
      private nominaService       : NominaService,
      private candidateService    : CandidatesService,
      private rrhhService         : RrhhService,
      public dialog               : MatDialog,
      private alertsService       : AlertsService,
      public fb                   : FormBuilder
    ) { }

  ngOnInit(): void {
    this.formFilters = this.fb.group({
      campaign_id       : [],
      position          : [],
      id_type_id        : [],
      id_number         : [],
      dashboard_name    : []
    });

    this.nominaService.getCampaings().subscribe( (camps: CampaignInterface[]) => {
      this.campaigns = camps;
      this.filteredCampaigns = this.formFilters.get("campaign_id").valueChanges.pipe(
        startWith(''),
        map(value => this._filter_campaign(value || '')),
      );
    });

    this.rrhhService.getPositions().subscribe( (pos:PositionInterface[]) => {
      this.positions = pos;
      this.filteredPositions = this.formFilters.get("position").valueChanges.pipe(
        startWith(''),
        map(value => this._filter_position(value || '')),
      )
    });

    this.candidateService.getIdTypes().subscribe(resp => {
      this.type_documents = resp;
    });


    this.getDashes();
  }



  /**
   * Consulta los dashboards
   */
  public getDashes(){

    this.dashboardService.getDashboards( null, this.formFilters.value ).subscribe((dashes) => {
      //this.pagination = dashes;
      this.dashboards = new MatTableDataSource(dashes);
      this.dashboards.paginator = this.paginator;


    });
  }

  /**
   * Modal para la creación de una nueva configuración
   */
  openEditModal():void {
    const dialogRef = this.dialog.open( EditConfigurationComponent , {
      width: '40%',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDashes();
    });
  }

  editarReporte(reporte:DashboardInterface ){
    const dialogRef = this.dialog.open( EditConfigurationComponent , {
      width : '40%',
      data  : reporte,
      maxHeight: '90vh'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDashes();
    });

  }

  /**
   * Envía petición para el borrado de la confiuración de un repote especifico
   * @param report: Dashboard a eliminar
   */
  eliminarReporte(report:DashboardInterface ){
    const confirm = this.alertsService.alertConfirm('¿Realmente desea eliminar el registro?');
    confirm.then(res => {
      if(res.isConfirmed){
        this.dashboardService.eliminar(report).subscribe( res => {
          this.alertsService.alertInfo("¡Correcto!", "Registro eliminado");
          this.getDashes();
        },
        error => {
          this.alertsService.alertError("Atención!","No fue posible eliminar el registro");
        }
        );

      }
    });
  }


  /**
   * Filtra un cargo en el mat select
   * @param value , nombre del cargo seleccionado por el usuario
   * @returns
   */
  private _filter_position(value: string): PositionInterface[] {
    if( typeof(value) == "number"){
      return null;
    }
    const filterValue = value.toLowerCase();
    return this.positions.filter(pos => pos.name.toLowerCase().includes(filterValue));
  }

  /**
   * Filtra una campaña en el matselect
   * @param value nombre de la campaña o porte de ella
   * @returns
   */
  private _filter_campaign(value: string): CampaignInterface[] {
    if( typeof(value) == "number"){
      return null;
    }
    const filterValue = value.toLowerCase();
    return this.campaigns.filter(camp => camp.name.toLowerCase().includes(filterValue));
  }


  /**
   * Obtiene el nombre de una campaña dado un id en el mat select
   */
  public getCampaignName( campaignId:number ):string{
    if(this.campaigns == undefined){
      return;
    }
    return this.campaigns.find( c => c.id == campaignId).name;
  }



}
