<div *ngIf="dataSource">
  <br>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Actions Column -->
    <ng-container matColumnDef="path">
      <th class="start" mat-header-cell *matHeaderCellDef> Acciones </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="upload(element.contract_type_id)" matTooltip="Cargar nueva plantilla">
          <i class="fi fi-rr-upload"></i>
        </button>
        <button mat-icon-button *ngIf="element.contract_template_id" (click)="preview(element.contract_template_id)"
          matTooltip="Previsualizar plantilla">
          <span class="fi-rr-eye"></span>
        </button>
      </td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Nombre </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
    <!-- Version Column -->
    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef> Versión </th>
      <td mat-cell *matCellDef="let element"> {{element.version}} </td>
    </ng-container>
    <!-- Date Column -->
    <ng-container matColumnDef="updated_at">
      <th mat-header-cell *matHeaderCellDef> Fecha de actualización </th>
      <td mat-cell *matCellDef="let element"> {{element.updated_at_formatted}} </td>
    </ng-container>
    <!-- Responsable Column -->
    <ng-container matColumnDef="responsable">
      <th class="end" mat-header-cell *matHeaderCellDef> Responsable </th>
      <td mat-cell *matCellDef="let element"> {{element.responsable}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <br><br><br><br>
</div>