<div class="note-card row" *ngFor="let item of notes" xmlns="http://www.w3.org/1999/html"
     xmlns="http://www.w3.org/1999/html" style="margin-right: 0; margin-top: 31px;margin-left: 2px;" [hidden]="typeView == 'history'">
  <div class="col-10" align="left">
    <div style="margin-bottom: 15px; color: #717171; font-size: 12px;">
      Nota por: <strong style="font-size: 8px;">{{item.user_name}}</strong> | {{item.updated_at | date: 'dd/MM/yyyy h:mm a'}}
    </div>
    <div style="font-size: 14px !important;color:#353535; word-break: break-all;">{{item.note}}</div>
  </div>
  <div class="col-2" align="right" *ngIf="item?.responsible_id == user" style="position: relative;">
    <button type="button" mat-icon-button (click)='update(item.id)' 
      style="color:#353535;font-size: 16px; position: absolute; top: -20px; right: 44px;" [hidden]="typeView == 'view'">
      <i class="fi-rr-edit" style="position:absolute"></i>
    </button>
    <button type="button" mat-icon-button (click)='delete(item.id)'
      style="color:#BE0F0F;font-size: 16px; position: absolute; top: -20px; right: 0;" [hidden]="typeView == 'view'">
      <i class="fi-rr-trash" style="position:absolute"></i>
    </button>
  </div>
</div>

<div class="row notesMain" *ngFor="let item of notes; let i = index" xmlns="http://www.w3.org/1999/html"
     xmlns="http://www.w3.org/1999/html"[hidden]="typeView !== 'history'">
  <div class="col-12 notes" align="left" style="margin-bottom: 16px;">
    <div style="margin-bottom: 15px; color: #717171; font-size: 12px !important;">
      Nota por: <strong style="font-size: 12px !important;">{{item.user_name}}</strong> | {{item.updated_at | date: 'dd/MM/yyyy h:mm a'}}
    </div>
    <div style="font-size: 16px !important;color:#353535; word-break: break-all;">{{item.note}}</div>
    <div [hidden]="i == notes.length - 1" style="border-bottom: 1px solid #D8D8D8; width: 100%;margin-top: 16px;"></div>
  </div>
</div>
