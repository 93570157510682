<button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<form mat-dialog-content class="containerDialog" [formGroup]="questionForm">
    <h2>{{ config.title }}</h2>
    <mat-divider class="mb-4"></mat-divider>
    <mat-form-field appearance="fill">
        <mat-label>{{ config.questionType }}</mat-label>
        <mat-select #select formControlName="question_type_id" (selectionChange)="changeSelect()">
            <mat-option *ngFor="let tipo of datos.tiposPreguntas" [value]="tipo.id">{{tipo.name}}</mat-option>
        </mat-select>
        <mat-error> {{ config.rules.option }} </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{config.question}}</mat-label>
        <textarea  matInput formControlName="question" cdkFocusInitial></textarea>
        <mat-error>{{config.rules.question}}</mat-error>
    </mat-form-field>
    <div *ngIf="select.value == '2'">
        <div formArrayName="multiple" *ngFor="let opcion of getOptions.controls; let i = index">
            <div [formGroupName]="i">
                <mat-form-field appearance="fill">
                    <mat-label>{{config.list.option}} {{i+1}}</mat-label>
                    <input matInput formControlName="opcion">
                    <mat-icon matSuffix *ngIf="i>1" (click)="removeOption(i)"><i class="fi-rr-trash"></i></mat-icon>
                </mat-form-field>
            </div>
        </div>
        <button class="btn-agregar" (click)="addOption()"> {{config.list.addOption}} <i class="fi-rr-add"></i></button>
    </div>
    <div *ngIf="select.value == '3'" style="margin-bottom: .5em;">
        <mat-radio-group aria-label="Lineal" formControlName="question_type_item_id">
                <mat-radio-button *ngFor="let opcion of datos.tiposPreguntas[2].items_tipo_pregunta" [value]="opcion.id">
                  {{config.lineal.scale}} {{opcion.name}}
                </mat-radio-button>
        </mat-radio-group>
        <mat-error *ngIf="questionForm.get('question_type_item_id').hasError('required')" class="mt-2">
          {{config.rules.scale}}
        </mat-error>
    </div>
    <div *ngIf="select.value == '4'" style="margin-bottom: 3em;">
        <div formArrayName="multiple" *ngFor="let opcion of getOptions.controls; let i = index">
            <div [formGroupName]="i">
                <mat-form-field appearance="fill">
                    <mat-label>{{config.list.option}} {{i+1}}</mat-label>
                    <input matInput formControlName="opcion">
                    <mat-icon matSuffix *ngIf="i>1" (click)="removeOption(i)"><i class="fi-rr-trash"></i></mat-icon>
                </mat-form-field>
            </div>
        </div>
        <button class="btn-agregar" (click)="addOption()">{{config.list.addOption}} <i class="fi-rr-add"></i></button>
    </div>
    <mat-checkbox formControlName="requerida" class="mt-3">{{config.mandatoryQuestion}}</mat-checkbox>
    <div class="btns-dialog">
        <button mat-stroked-button (click)="questionRef.close()">{{config.btnCancel}}</button>
        <button mat-raised-button color="primary" (click)="save()">{{config.btnConfirm}}</button>
    </div>
</form>
