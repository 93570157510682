import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ControlService } from 'src/app/modules/calidad/services/control.service';

@Component({
  selector: 'app-detalle-grupo',
  templateUrl: './detalle-grupo.component.html',
  styleUrls: ['./detalle-grupo.component.sass']
})
export class DetalleGrupoComponent implements OnInit {

  titulo:string = '';
  supervisor:string = '';
  creacion;
  id_grupo;
  id_matriz;
  constructor(
    private controlService:ControlService,
    private aRoute:ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.aRoute.paramMap.subscribe(params=>{
      this.id_grupo = params.get('id');
    });
    this.getGrupoById();
  }

  getGrupoById(){
    this.controlService.getGrupoById(this.id_grupo).subscribe((resp:any)=>{
      this.titulo = resp[0].nombre;
      this.supervisor = resp[0].nombre_supervisor;
      this.creacion = resp[0].created_at;
      this.id_matriz = resp[0].matriz_id;
    });
  }

}
