import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReferralsReportService } from '../../../services/rest/referralsReport.service';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-ReferralsReport',
  templateUrl: './ReferralsReport.component.html',
  styleUrls: ['./ReferralsReport.component.sass']
})
export class ReferralsReportComponent implements OnInit {

  length;
  pageSize = 5;
  page = 1;
  isPage = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  displayedColumns: string[] = ['date_of_hire', 'name', 'document', 'contact_number', 'email', 'referredBy', 'documentReferredBy', 'state'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginatorHistory: MatPaginator;

  constructor(private referralsReportService: ReferralsReportService) {}


  ngOnInit() {
    this.referralsReportService.getReferralsList().subscribe(resp => {
      console.log(resp.data);
      this.dataSource = resp.data;
      this.length = resp.total;
    });
  }

  /**
   * @author David Reinoso
   * Metodo de paginación
   */
  pageEvent(event: any): void {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getReferralsList(this.pageSize, this.page);
  }

  /**
   * @author David Reinoso
   * @purpose resetea el paginador
   */
  resetPaginator(numPaginator): void {
    if (numPaginator === 2) {
      this.length = 0;
      this.pageSize = 5;
      this.page = 1;
      this.paginator.firstPage();
    }
  }

  /**
 * @author David Reinoso
 * Metodo para obtener el listado de referidos
 */
   getReferralsList(pageSize, page): void {
    if (page != null || pageSize != null) {
      this.referralsReportService.getReferralsList(pageSize, page).subscribe(resp => {
        this.dataSource = resp.data;
        this.length = resp.total;
      });
    }
  }

  /**
  * @author David Reinoso
  * Metodo de descarga de reporte de referidos
  */
  downloadReport() {
    this.referralsReportService.downloadReport().subscribe(resp => {
      importedSaveAs(resp, 'reporte_de_referidos.xlsx');
    });
  }
}
