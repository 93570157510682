<div class="row novelty-row-padding">

  <h2 mat-dialog-title>
    <span class="gray-color" style="margin-left: -9px"></span>
    <button style="float: right;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
    <div class="title content" style="width: 100%;">
      <span>
        <h1>{{data.data?.name}}</h1>
        <h5 style="font-size: 15px; font-family: 'Poppins', sans-serif!important;">{{data.data?.id_number}}</h5>
      </span>
    </div>
  </h2>

  <h3 mat-dialog-title>
    <span class="gray-color" style="margin-left: -9px">{{ dataInfo?.novelty_date_start | date:'EEEE d, MMMM' }}</span>
  </h3>

  <mat-dialog-content style="padding: 3%; overflow-x: hidden" class="mat-typography">
    <div class="row">
      <div class="col-7">
        <div class="col-12">
          <div class="col-12">
            <span
              class="badge" [style.background]="dataInfo?.noveltyTypeToPrint?.backgroundColor">{{ dataInfo?.novelty_type?.key }}</span> {{ dataInfo?.noveltyTypeToPrint?.title }}
          </div>
          <br>
          <div class="col-12" *ngIf="showJustifyInfo">
            <h4 class="gray-color"><b>Estado</b></h4>
            <p class="p-color">{{ dataInfo?.novelty_state?.name }}</p>
          </div>
        </div>
      </div>
      <div class="col-7">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-8">

                <div class="col-12" >
                  <br>
                  <h4 class="gray-color"><b>Justificación</b></h4>
                  <p class="p-color">{{ dataInfo?.responseFirstInstance?.justify }}</p>
                </div>

                <!-- payroll response -->
                <div class="col-12" *ngIf="dataInfo?.responseFirstInstance?.response">
                  <br>
                  <h4 class="gray-color"><b>Respuesta Nómina</b></h4>
                  <p class="p-color">{{ dataInfo?.responseFirstInstance?.response }}</p>
                </div>
                <!-- end -->

                <!-- user response -->
                <div class="col-12" *ngIf="dataInfo?.responseSecondInstance?.justify">
                  <br>
                  <h4 class="gray-color"><b>Justificación del trabajador</b></h4>
                  <p class="p-color">{{ dataInfo?.responseSecondInstance?.justify }}</p>
                 </div>
                <!-- end -->

                <!-- payroll response -->
                <div class="col-12" *ngIf="dataInfo?.responseSecondInstance?.response">
                  <br>
                  <h4 class="gray-color"><b>Respuesta Nómina</b></h4>
                  <p class="p-color">{{ dataInfo?.responseSecondInstance?.response }}</p>
                 </div>
                <!-- end -->
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="col-5 text-center">
        <img src="./assets/images/novelties-1.svg" alt="noveltyIcon">
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="row novelty-footer-hours">
      <div class="col-4">
        <b>Inicio turno</b>
        <p class="gray-color"><b>{{ scheduleTime }}</b></p>
      </div>
      <div class="col-4">
        <b>Fin turno</b>
        <p class="gray-color"><b> {{ endTime }}</b></p>
      </div>
      <div class="col-4" style="color: red">
        <b>Hora inicio turno</b>
        <p><b>{{ realTime }}</b></p>
      </div>
    </div>
  </mat-dialog-actions>

</div>
