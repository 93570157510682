<button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<form mat-dialog-content class="containerDialog" [formGroup]="matrixForm">
    <h2>{{config.labels.editMatrix}}</h2>
    <mat-divider></mat-divider>
    <div class="fields">
        <mat-form-field appearance="fill">
            <mat-label>{{config.labels.matrixName}}</mat-label>
            <input matInput formControlName="nombre">
        </mat-form-field>   
        <mat-form-field appearance="fill">
            <mat-label>{{config.labels.company}}</mat-label>            
            <mat-select formControlName="company_id" #tipo_matriz (selectionChange)="changeSelect()">
                <mat-option *ngFor="let emp of empresas" [value]="emp.id">{{emp.name}}</mat-option>
            </mat-select>
        </mat-form-field>  
        <mat-form-field appearance="fill">
            <mat-label>{{config.labels.matrixType}}</mat-label>
            <mat-select formControlName="tipo_matriz_id" #tipo_matriz (selectionChange)="changeSelect()">
                <mat-option *ngFor="let tipo of tiposMatrices" [value]="tipo.id">{{tipo.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="tipo_matriz.value == '2'">
            <mat-label>{{config.labels.weightRange}}</mat-label>
            <mat-select formControlName="rango_id">
                <mat-option *ngFor="let rango of rangos" [value]="rango.id">{{rango.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="tipo_matriz.value == '2'">
            <mat-label>{{config.labels.moduleOrItems}}</mat-label>
            <mat-select formControlName="tipo_peso_id">
                <mat-option *ngFor="let peso of tipos_peso" [value]="peso.id">{{peso.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{config.labels.associatedCampaign}}</mat-label>
            <mat-select formControlName="campania_asociada" #asociada (selectionChange)="changeSelect()" cdkFocusInitial>
                <mat-option [value]="1">{{config.labels.yes}}</mat-option>
                <mat-option [value]="0">{{config.labels.no}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="asociada.value == 1">
            <mat-label>{{config.labels.selectCRM}}</mat-label>
            <mat-select formControlName="crm_id">
                <mat-option *ngFor="let campaign of campaigns" [value]="campaign.id">{{campaign.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{config.labels.applyBusinessIntelligence}}</mat-label>
            <mat-select formControlName="aplicar_inteligenciaNegocio" #inteligencia (selectionChange)="changeSelect()">
                <mat-option [value]="1">{{config.labels.yes}}</mat-option>
                <mat-option [value]="0">{{config.labels.no}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="inteligencia.value == 1">
            <mat-label>{{config.labels.selectIntelligence}}</mat-label>
            <mat-select formControlName="inteligenciaNegocio_id">
                <mat-option *ngFor="let inteligencia of inteligencias" [value]="inteligencia.id">{{inteligencia.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{config.labels.applyForCP}}</mat-label>
            <mat-select formControlName="aplica_para_cp" #aplicacp>
                <mat-option [value]="1">{{config.labels.yes}}</mat-option>
                <mat-option [value]="0">{{config.labels.no}}</mat-option>
            </mat-select>
            <mat-error *ngIf="matrixForm.get('aplica_para_cp').hasError('required')">{{config.errors.required}}</mat-error>
        </mat-form-field>
        <!-- Campos nuevos -->
        <mat-form-field appearance="fill">
            <mat-label>{{config.labels.comment_apply}}</mat-label>
            <mat-select formControlName="aplica_comentario" #comentario (selectionChange)="changeSelect()">
              <mat-option [value]="1">{{config.labels.yes}}</mat-option>
              <mat-option [value]="0">{{config.labels.no}}</mat-option> 
            </mat-select>
            <mat-error *ngIf="matrixForm.get('aplica_comentario').hasError('required')">{{config.errors.selectOption}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="comentario.value == 1">
            <mat-label>{{config.labels.comment_is_mandatory_for}}</mat-label>
            <mat-select multiple formControlName="comentario_obligatorio_para">
              <mat-option [value]="1">{{config.labels.yes}}</mat-option>
              <mat-option [value]="0">{{config.labels.no}}</mat-option>
              <mat-option [value]="2">{{config.labels.na}}</mat-option>
              <mat-option [value]="3" *ngIf="aplicacp.value == 1">{{config.labels.cp}}</mat-option>
            </mat-select>
            <mat-error *ngIf="matrixForm.get('comentario_obligatorio_para').hasError('required')">{{config.errors.selectOption}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="comentario.value == 1">
            <mat-label>{{config.labels.comment_size}}</mat-label>
            <input matInput  formControlName="tamano_comentario" type="number" >
            <mat-error *ngIf="matrixForm.get('tamano_comentario').hasError('required')">{{config.errors.required}}</mat-error>
          </mat-form-field> 
    </div>
   
    <div class="btns-dialog">
        <button mat-stroked-button (click)="matrizRef.close()">{{config.labels.cancel}}</button>
        <button mat-raised-button color="primary" (click)="update()">{{config.labels.save}}</button>
    </div>
</form>
