import { Component, OnInit, Inject } from '@angular/core';
import { TestsService } from '../../../services/tests.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UploadTestComponent } from '../upload-test/upload-test.component';
import { saveAs as importedSaveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { CandidateCommentsComponent } from '../../candidates/candidate-comments/candidate-comments.component';
@Component({
  selector: 'app-assign-test',
  templateUrl: './assign-test.component.html',
  styleUrls: ['./assign-test.component.sass']
})
export class AssignTestComponent implements OnInit {

  test;

  testsList: any = [];
  candidate;
  selectedTests = [];
  testCandidates = [];
  testsNames =[];
  califications = [1, 2, 3];
  testSelectedToEvaluation: any = {};
  enableForm=false
  constructor(private testService: TestsService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: FormBuilder,
              private dialog: MatDialog,
              private alertService: AlertsService,
              
              public dialogRef: MatDialogRef<AssignTestComponent>) {
                this.candidate = data.candidate;
                this.getTestCandidates();
              }

  ngOnInit(): void {
    
    this.testService.getTestsList().subscribe( resp => {
      this.testsList = resp;
    });
    
  }

  getTestCandidates(){
    this.enableForm=false
    this.testService.getTestByCandidate(this.candidate.id).subscribe((resp: any) => {
       this.candidate =  resp;
       this.enableForm=true
       
    });
  }

  pushArray(event) {
    this.selectedTests = event.selectedOptions.selected.map(item => item.value.id);
    this.testsNames = event.selectedOptions.selected.map(item => item.value);
  }

  saveTests(testId) {
    this.registerTest().then( () => {
      if (this.selectedTests.includes(3) || this.selectedTests.includes(1)){
        const uploadTestDialog = this.dialog.open ( UploadTestComponent, {
          minWidth: '55%',
          disableClose: true,
          data: {
            testsNames:this.testsNames,
            candidateId: this.candidate.id,
            requestId: this.data.requestId
          }
        });
        uploadTestDialog.afterClosed().subscribe(res=>{
         this.dialog.closeAll()
         this.getTestCandidates();
        })
      }
      
    });
  
  }

  registerTest(){
    return new Promise(resolve => {
      this.testService.saveTests({candidateIds:this.candidate.id, testIds: this.selectedTests.toString(), requestId: this.data.requestId})
                    .subscribe( resp => {
                        this.alertService.alertSuccess('¡Hecho!', 'Pruebas asignadas correctamente');
                        resolve(resp);
      });
    });
  }

  resolveAfter2Seconds(x) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(x);
      }, 2000);
    });
  }

  saveTestsClinicaVentas(candidateTestId, resources){
      this.testService.saveResourcesFromAdmin(candidateTestId, {resources}).subscribe( (resp: any)  => {
        this.alertService.alertSuccess('Clínica de Ventas guardada con éxito', '');
      });
  }

  downloadFile(file){

      this.testService.downloadFile(file.id, 'attach').subscribe((resp:any) => {
      importedSaveAs(resp, `${file.resources.candidateAttachmentName}`);
    })

  }

  selectedTest(test){
      this.testSelectedToEvaluation = test;
  }
  saveEvaluation(){
    this.testService.saveEvalutationOrAssesments(this.testSelectedToEvaluation.id, this.testSelectedToEvaluation).subscribe(e => {
      this.alertService.alertSuccess('¡Hecho!', 'Evaluación creada con éxito');
      this.candidate.enableCommentBtn = true;
    });
  }
  openComments(){
    const candidateComments = this.dialog.open ( CandidateCommentsComponent, {
      minWidth: '80%',
      disableClose: true,
      data: { candidateId: this.candidate.id, requestId: this.data.requestId }
    });
    candidateComments.afterClosed().subscribe( (params) => {
    });
  }

}
