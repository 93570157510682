<div class="main-container">
    <div class="header">
        <h1>Asignación de exámenes</h1>
        <p>Bienvenido al módulo de Exámenes Médicos, aquí podrás agendar <br> o asignar exámenes médicos a los candidatos</p>
    </div>
    <div class="filter d-flex justify-content-between">
        <mat-form-field appearance="fill"  class="col-3">
            <mat-label>Tipo de filtro</mat-label>
            <mat-select [(ngModel)]="typeFilter">
                <mat-option value="Identificación">Identificación</mat-option>
                <mat-option value="Teléfono">Teléfono</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill"
                *ngIf="typeFilter !=null">
                    <mat-label>Filtrar por {{typeFilter}}</mat-label>
                    <input matInput #filter>
                    <button mat-icon-button matSuffix (click)="filterByValue(filter.value)"><mat-icon>search</mat-icon></button>
        </mat-form-field>
        <div class="col-3">
            <button mat-raised-button color="primary" class="w-100" (click)="openModalAssignmentMassive()">
                Asignación masiva
            </button>
        </div>
    </div>

  <div class="table">

    <table mat-table [dataSource]="dataSource" matSort >

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Acciones</th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button [matMenuTriggerFor]="menu"
                        *ngIf="(row.state !== 'Listado' && row.state !== 'Inactivo')">
                <mat-icon>more_vert</mat-icon>
            </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="createMedicalTest(row)">
                        <i class="fi fi-rr-doctor"></i>
                        <span style="padding-left:5px;">Agendar Exámenes</span>
                    </button>
                    <button mat-menu-item (click)="medicalTests(row)">
                        <i class="fi  fi-rr-treatment"></i>
                        <span style="padding-left:5px;">Exámenes Medicos</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>


        <!-- name -->
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Estado </th>
            <td mat-cell *matCellDef="let row"> {{row.state}} </td>
        </ng-container>

        <!-- name -->
        <ng-container matColumnDef="group">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Grupo de Formación </th>
            <td mat-cell *matCellDef="let row"> {{row.groupName}} </td>
        </ng-container>
        <!-- name -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Nombre </th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>

        <!-- document -->
        <ng-container matColumnDef="id_number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>No. Documento </th>
            <td mat-cell *matCellDef="let row"> {{row.documentId}} </td>
        </ng-container>

        <!-- cellphone -->
        <ng-container matColumnDef="mobilephone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Celular </th>
            <td mat-cell *matCellDef="let row"> {{row.mobile_phone}} </td>
        </ng-container>





        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8">No hay candidatos disponibles.</td>
        </tr>
    </table>


      <mat-paginator [length]="length"
                    [pageSize]="pageSize"
                    (page)="getPaginatorData($event)"
                    [pageSizeOptions]="pageSizeOptions" #paginator>
      </mat-paginator>

  </div>
</div>

