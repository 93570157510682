import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { EscalationService } from '../../../services/rest/escalation.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { ViewEscalationComponent } from '../view-escalation/view-escalation.component';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { ThemePalette } from '@angular/material/core';
import { CreateStateComponent } from '../create-state/create-state.component';
import { CreateAffairComponent } from '../create-affair/create-affair.component';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { MailEscalationComponent } from '../mail-escalation/mail-escalation.component';
import { GestionadorCorreosComponent } from "../gestionador-correos/gestionador-correos.component";

@Component({
  selector: 'app-escalation',
  templateUrl: './admin-escalation.component.html',
  styleUrls: ['./admin-escalation.component.sass']
})
export class AdminEscalationComponent implements OnInit, AfterViewInit {

  escalationfilter: FormGroup;
  public advancedFilters: boolean = true;
  message: string;
  channels: any;
  areas: any;
  subjects: any;
  status: any;
  users: any;
  gridUser: any;
  filteredOptionsUser: Observable<any[]>;
  rol: any;
  step: number;


  // MatPaginator Inputs
  length = 0;
  pageSize = 5;
  page = 1;

  lengthS;
  pageSizeS = 5;
  pageS = 1;

  lengthA;
  pageSizeA = 5;
  pageA = 1;

  lengthU;
  pageSizeU = 5;
  pageU = 1;

  pageSizeOptions: number[] = [5, 10, 25, 100];

  displayedColumns: string[] = ['traffic_lights', 'is_anonimous', 'id', 'created_at', 'name', 'document', 'subject', 'channel', 'status', 'responsible', 'accion'];
  dataSource = new MatTableDataSource<any>();

  displayedColumnsUser: string[] = ['name', 'id_document', 'roles', 'email', 'accion'];
  dataSourceUser = new MatTableDataSource<any>();

  displayedColumnsEstados: string[] = ['name', 'description', 'accion'];
  dataSourceEstados = new MatTableDataSource<any>();

  displayedColumnsAsuntos: string[] = ['name', 'description', 'accion'];
  dataSourceAsuntos = new MatTableDataSource<any>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('paginatorUser') paginatorUser: MatPaginator;
  @ViewChild('paginatorEstados') paginatorEstados: MatPaginator;
  @ViewChild('paginatorAsuntos') paginatorAsuntos: MatPaginator;

  color: ThemePalette = 'primary';
  checked = false;
  disabled = false;

  constructor(private form: FormBuilder,
              public escalationService: EscalationService,
              public datePipe: DatePipe,
              private destroyService: DestroyComponentService,
              public dialog: MatDialog,
              public authService: AuthService,
              private alertsService: AlertsService) { }

  ngOnInit(): void {
    this.formControl();
    this.getRolUser();
    this.escalationService.getEscalationInfo().subscribe(res => {
      this.channels = res?.canales;
      this.areas = res?.areas;
      this.subjects = res?.asuntos;
      this.status = res?.estados;
      this.getGridState();
      this.getGridAsunto();
    });

    this.escalationfilter.get('areaAfectada').valueChanges.subscribe(res => {
      this.users = this.areas.find(element => element.id === res)?.users;
      this.filteredOptionsUser = this.escalationfilter.get('asignado').valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(campaigns => campaigns ? this._filterUser(campaigns) : this.users.slice())
      );
    });
    this.getGridUsuarios();
    this.showButtonMessage();

  }

  openDialogGestionadorCorreos(): void {
    this.dialog.open(GestionadorCorreosComponent, {
      width: '1000px'
    })
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que obtiene que rol tiene asignado dependiendo del aplicativo
   */
  getRolUser(): void{
    const user = this.authService.getUser();
    user.roles.forEach(rol => {
      const name = rol.split('::');
      if (name[0] === 'pqrs') {
        this.rol = name[1];
      }
    });
  }


  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * Metodo que se encarga de consultar los datos de la grilla de escalamientos
   * @param size tamaño de datos a traer
   * @param page pagina a traer
   */
  getGridUser(): void {

    this.escalationService.getUsersGrid(this.pageSize, this.page).subscribe(respGrid => {
      this.dataSource.data = respGrid.data;
      this.dataSource.sort = this.sort;
      
      setTimeout(() => {
        this.paginator.pageIndex = this.page - 1;
        this.paginator.length = respGrid.total;
        
      });
      
    });

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSourceUser.paginator = this.paginatorUser;
    this.dataSourceEstados.paginator = this.paginatorEstados;
    this.dataSourceAsuntos.paginator = this.paginatorAsuntos;
  }


  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que se encarga de consultar los datos de la grilla de usuarios
   * @param size tamaño de datos a traer
   * @param page pagina a traer
   */
  getGridUsuarios(): void {
    this.escalationService.getUsers().subscribe(res => {
      this.dataSourceUser.data = res;
      this.dataSourceUser.sort = this.sort;
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que se encarga de traer los datos de la grilla de estados
   * @param size tamaño de datos a traer
   * @param page pagina a traer
   */
  getGridState(): void{
    this.dataSourceEstados.data = this.status;
    this.dataSourceEstados.sort = this.sort;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que se encarga de traer los datos de la grilla de asuntos
   * @param size tamaño de datos a traer
   * @param page pagina a traer
   */
  getGridAsunto(): void{
    this.dataSourceAsuntos.data = this.subjects;
    this.dataSourceAsuntos.sort = this.sort;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * Metodo que se encarga del paginador al momento de cambiar de pagina en la grilla de escalamientos
   * @param event evento que trae la pagina a listar
   */
  pageEvent(event: any): void {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    if (this.advancedFilters) {
      this.filter(this.escalationfilter, true);
    } else {
      this.getGridUser();
    }

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * Metodo que se encarga del paginador al momento de cambiar de pagina en la grilla de estados
   * @param event evento que trae la pagina a listar
   */
  pageEventS(event: any): void {

    this.pageSizeS = event.pageSize;
    this.pageS = event.pageIndex + 1;
    this.getGridState();

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * Metodo que se encarga del paginador al momento de cambiar de pagina en la grilla de asuntos
   * @param event evento que trae la pagina a listar
   */
  pageEventA(event: any): void {

    this.pageSizeA = event.pageSize;
    this.pageA = event.pageIndex + 1;
    this.getGridAsunto();

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * Metodo que se encarga del paginador al momento de cambiar de pagina en la grilla de usuarios
   * @param event evento que trae la pagina a listar
   */
  pageEventU(event: any): void {
    this.pageSizeU = event.pageSize;
    this.pageU = event.pageIndex + 1;
    this.getGridUsuarios();
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * Metodo donde se establecen las validaciones del formulario
   */
   formControl(): void{

    this.escalationfilter = this.form.group({
      radicado: new FormControl(''),
      fecha: new FormControl('', []),
      canal: new FormControl('', []),
      asunto: new FormControl('', []),
      areaAfectada: new FormControl('', []),
      asignado: new FormControl('', []),
      estado: new FormControl('', []),
    });

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * metodo que se establece para el control del autocompletable
   * @param id id a buscar
   * @returns respuesta del autocomplete
   */
  displayFn(id: number): string{
    if (!id) { return ''; }
    let index = this.users.findIndex(resp => resp.id === id);
    return this.users[index].name;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * metodo que se encarga de filtrar los datos del autocomplete
   * @param value valor a filtrar
   * @returns valores filtrados
   */
  private _filterUser(value: string): any[] {
    const filterValueUser = value.toLowerCase();
    return this.users.filter(option => option.name.toLowerCase().indexOf(filterValueUser) === 0);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * metodo que se encarga del filtrado de los datos de la grilla
   * @param escalationfilter formulario a filtrar
   */
  filter(escalationfilter, usePaginate = false): void{

    const filter = new FormData();

    filter.append( 'radicado', escalationfilter.value.radicado ? escalationfilter.value.radicado : '');
    filter.append( 'fecha', this.datePipe.transform(escalationfilter.value.fecha, 'yyyy-MM-dd', 'es-ES') ? this.datePipe.transform(escalationfilter.value.fecha, 'yyyy-MM-dd', 'es-ES') : '');
    filter.append( 'canal_id', escalationfilter.value.canal ? escalationfilter.value.canal : '' );
    filter.append( 'asunto_id', escalationfilter.value.asunto ? escalationfilter.value.asunto : '' );
    filter.append( 'area_id', escalationfilter.value.areaAfectada ? escalationfilter.value.areaAfectada : '');
    filter.append( 'solucionador_id', escalationfilter.value.asignado ? escalationfilter.value.asignado : '' );
    filter.append( 'estado_id', escalationfilter.value.estado ? escalationfilter.value.estado : '' );
    if(usePaginate === false) {
      this.page = 1;
    }
    this.escalationService.getFilter(filter, this.pageSize, this.page).subscribe((response) => {
      this.dataSource.data = response.data;
      this.dataSource.sort = this.sort;
      setTimeout(() => {
        this.paginator.pageIndex = this.page - 1;
        this.paginator.length = response.total;
        
      });
      
    });

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * Metodo que maneja el cambio del mensaje en el botos de activar filtros
   */
  showButtonMessage(): void {
    this.advancedFilters = !this.advancedFilters;
    if (!this.advancedFilters) {
      this.page = 1;
      this.getGridUser();
      this.message = 'Activar Filtros';
    } else {
      this.message = 'Desactivar Filtros';
    }
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Meotod que redirige al modal de ver escalamiento
   * @param id dat anecesaria para el modal
   */
  visualizar(id: any): void{

    const dialogViewEscala = this.dialog.open(ViewEscalationComponent, {
      width: '75%',
      disableClose: true,
      data: {
        type: 'viewEscalamiento',
        title: 'Visualizar Escalamiento',
        id
      },
      panelClass: 'custom-dialog-container',
    });

    dialogViewEscala.afterClosed().subscribe(() => {
      this.getGridUser();
    });

  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep(): void {
    this.step++;
  }

  prevStep(): void {
    this.step--;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Meotod que redirige al modal de ver crear estado
   * @param data dat anecesaria para el modal
   */
  crearEstado(): void{
    const dialogNewEstado = this.dialog.open(CreateStateComponent, {
      width: '75%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Crear Estado',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewEstado.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Meotod que redirige al modal de editar estado
   * @param data dat anecesaria para el modal
   */
  editarEstado(data: any): void{
    const dialogEditRol = this.dialog.open(CreateStateComponent, {
      width: '75%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Editar Estado',
        data
      },
      panelClass: 'custom-dialog-container',
    });

    dialogEditRol.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Meotod que redirige al modal de crear asunto
   * @param data dat anecesaria para el modal
   */
  crearAsunto(): void{
    const dialogNewAsunto = this.dialog.open(CreateAffairComponent, {
      width: '75%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Crear Asunto',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewAsunto.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Meotod que redirige al modal de editar asunto
   * @param data dat anecesaria para el modal
   */
  editarAsunto(data: any): void{
    const dialogEditAsunto = this.dialog.open(CreateAffairComponent, {
      width: '75%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Editar Asunto',
        data
      },
      panelClass: 'custom-dialog-container',
    });

    dialogEditAsunto.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Meotod que redirige al modal de editar usuario
   * @param data dat anecesaria para el modal
   */
  editarUser(data: any): void{
    const dialogEditUser = this.dialog.open(EditUserComponent, {
      width: '75%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Asignar area',
        data
      },
      panelClass: 'custom-dialog-container',
    });

    dialogEditUser.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que se encarga del comportamiento del toggle de la grilla de estados
   * @param row fila del toggle
   * @param event evento del toggle es true o false
   */
  toggleState(row: any, event: any): void{

    const state = new FormData();

    state.append( 'state', event);

    this.escalationService.updateState(row.id, state).subscribe((resp) => {
      this.alertsService.alertSuccess('Editado', resp.data);
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que se encarga del comportamiento del toggle de la grilla de asuntos
   * @param row fila del toggle
   * @param event evento del toggle es true o false
   */
  toggleAsunto(row: any, event: any): void{

    const asunto = new FormData();

    asunto.append( 'state', event);

    this.escalationService.updateAsunto(row.id, asunto).subscribe((resp) => {
      this.alertsService.alertSuccess('Editado', resp.data);
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-23
   * Metodo que se encarga del comportamiento del toggle de la grilla de usuarios
   * @param row fila del toggle
   * @param event evento del toggle es true o false
   */
  toggleUser(row: any, event: any): void{

    const user = new FormData();

    user.append( 'state', event);

    this.escalationService.updateUsuario(row.rrhh_id, user).subscribe((resp) => {
      this.alertsService.alertSuccess('Editado', 'El estado cambio correctamente');
    });
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

  visualizarEmail(data: any): void{

    const dialogViewEscala = this.dialog.open(MailEscalationComponent, {
      width: '75%',
      disableClose: true,
      data: {
        type: 'Correo',
        title: 'Visualizar Correo',
        data
      },
      panelClass: 'custom-dialog-container',
    });

    dialogViewEscala.afterClosed().subscribe(() => {

    });

  }


}
