import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { SaveTemplateComponent } from '../save-template/save-template.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { TemplatesService } from '../../../services/rest/templates.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-admin-template',
  templateUrl: './admin-template.component.html',
  styleUrls: ['./admin-template.component.sass']
})
export class AdminTemplateComponent implements OnInit {

  displayedColumnsTemplate: string[] = ['accion', 'estado', 'fechaCreacion', 'nombrePlantilla', 'camposPlantilla'];
  dataSourceTemplate = new MatTableDataSource<any>();
  @ViewChild(MatSort, { static: true }) sortTemplate: MatSort;
  lengthTemplate;
  pageSizeTemplate = 5;
  pageTemplate = 1;
  pageSizeOptionsTemplate: number[] = [5, 10, 25, 100];
  idForm: any;

  constructor(private location: Location,
              private templatesService: TemplatesService,
              public dialog: MatDialog,
              public route: ActivatedRoute) { }

  ngOnInit(): void {

    this.idForm = this.route.snapshot.queryParamMap.get('id');

    this.getTemplate(this.pageSizeTemplate, this.pageTemplate, '');
  }

  /**
 * @author Daniel Dominguez
 * @createdate 2021-09-05
 * Metodo que abre el modal de crear un nuevo template
 */
    backRoute() {
    this.location.back();
  }

  pageEventTemplate(event: any): void {
    this.pageSizeTemplate = event.pageSize;
    this.pageTemplate = event.pageIndex + 1;
    this.getTemplate(this.pageSizeTemplate, this.pageTemplate, '');
  }

  getTemplate(size, page, busqueda) {

    this.templatesService.getTemplate(this.idForm, size, page, busqueda).subscribe(resp => {
      this.dataSourceTemplate.data = resp.data;
      this.dataSourceTemplate.sort = this.sortTemplate;
      this.lengthTemplate = resp.total;
      this.pageSizeTemplate = resp.per_page;
    });
  }

  newTem(): void {
    const dialogNewTem = this.dialog.open(SaveTemplateComponent, {
      width: '60%',
      height: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Plantilla',
        idForm: this.idForm
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewTem.afterClosed().subscribe(() => {
      this.ngOnInit();
    });

  }

}