<h2 mat-dialog-title>{{data.title}}
  <button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>
</h2>

<form class="row form" [formGroup]="massiveForm" (ngSubmit)="sendMassive()" style="padding-left: 10px;" enctype="multipart/form-data">

  <mat-dialog-content style="padding-left: 3%;" class="mat-typography">

    <div class="col-12">
      <div class="col-6" appearance="fill">
        <input type="file" class="file-input" formControlName="file" accept=".xlsx"
       (change)="onFileSelected($event)" #fileUpload>

        <div class="file-upload">
            <a mat-mini-fab color="primary" class="upload-btn mr-3"
              (click)="fileUpload.click()">
                <mat-icon>attach_file</mat-icon>
            </a>
            {{fileName || "Ningún archivo cargado todavía."}}

            <div class="mt-1">
              <mat-error *ngIf="error.file.errors && notFocused"><span *ngIf="error.file.errors.hasWrongFile">¡Advertencia! Unica extención permitida .xlsx</span></mat-error>
              <mat-error *ngIf="error.file.errors && notFocused"><span *ngIf="error.file.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            </div>
        </div>
      </div>
    </div>
    <br><br>
    <div formArrayName="appxrol" class="row col-12">

      <h3>Editar permisos</h3>

      <div class="col-6" *ngFor="let applet of appxrol.controls; let i = index;">

        <mat-card style="background-color: #F0F0F0;">

          <div class="row col-12" [formGroupName]="i">
            <mat-form-field style="padding: 1%;" class="col-5" appearance="fill">
              <mat-label>Aplicación</mat-label>
              <input type="text"
                    matInput
                    formControlName="app"
                    [matAutocomplete]="auto"
                    required
                    (focus)="getFilter(i)">
              <mat-autocomplete (optionSelected)="appSelected($event.option.value, i)" #auto="matAutocomplete" [displayWith]='displayFn.bind(this)' required>
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
              <mat-error>¡Advertencia! Este campo es requerido</mat-error>
            </mat-form-field>

            <mat-form-field style="padding: 1%;margin-left:5px" class="col-5" appearance="fill">
              <mat-label>Rol</mat-label>
              <mat-select formControlName="rol" (selectionChange)="verifyRoles(i)" required>
                <mat-option *ngFor="let rol of roles[i]" [value]="rol.id">
                  {{rol.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="error.appxrol.controls[i].controls.rol.errors"><span *ngIf="error.appxrol.controls[i].controls.rol.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
              <mat-error *ngIf="error.appxrol.controls[i].controls.rol.errors"><span *ngIf="error.appxrol.controls[i].controls.rol.errors.invalidRol">¡Advertencia! Este rol ya fue seleccionado para esta aplicación</span></mat-error>
            </mat-form-field>
            <div class="col-1">
              <a mat-icon-button (click)="deleteappxrol(i)" class="mt-3" style="color:#BE0F0F;font-size: 20px;"><i class="fi-rr-trash"></i></a>
            </div>

          </div>



        </mat-card>
        <br>
        <br>
      </div>

    </div>

    <div class="col-6">
      <button mat-stroked-button type="button" class="col-4" color="primary" style="border-color: #2CABBC;" (click)="newappxrol()"> Añadir <i class="fi-rr-add"></i></button>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions style="height: 48px; padding-bottom: 70px;" align="end">
    <button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button mat-raised-button type="submit" color="primary" disabled="{{ massiveForm.invalid }}">Guardar</button>
  </mat-dialog-actions>

</form>

