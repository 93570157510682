import { Component, Inject, OnInit, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatrizService } from 'src/app/modules/auditoria/services/matriz.service';
import { Tools } from 'src/app/modules/auditoria/utils/tools.utils';

@Component({
  selector: 'app-crear-matriz',
  templateUrl: './crear-matriz.component.html',
  styleUrls: ['./crear-matriz.component.sass']
})
export class CrearMatrizComponent implements OnInit {

  matrixForm:FormGroup;
  tiposMatrices = [];
  inteligencias = [];
  rangos = [];
  tipos_peso = [];
  campaigns = [];
  filteredOptionsCampaign: Observable<any[]>;
  constructor(
    private fb:FormBuilder,
    public matrizRef:MatDialogRef<CrearMatrizComponent>,
    @Inject(MAT_DIALOG_DATA) public datos:any,
    private matrizService:MatrizService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.getSelects();
  }

  createForm(){
    this.matrixForm = this.fb.group({
      nombre: ['',[Validators.required]],
      tipo_matriz_id: ['',Validators.required],
      rango_id: ['',Validators.required],
      tipo_peso_id: ['',Validators.required],
      campania_asociada: ['',Validators.required],
      crm_id: ['',Validators.required],
      aplicar_inteligenciaNegocio: ['',Validators.required],
      inteligenciaNegocio_id: ['',Validators.required],
      isRetentionMatrix: [0, Validators.required],
      showFieldDuration: [0, Validators.required]
    })
  }

  save(){
    if (this.matrixForm.invalid) {
      return
    }
    this.matrixForm.addControl('grupo',new FormControl(1))
    this.matrizService.createMatrix(this.matrixForm.value).subscribe((resp:any)=>{
      if (resp.respuesta.codigo === 202) {
        Tools.swalSuccess('¡Excelente!','La matriz se ha creado con éxito.')
        this.matrizRef.close(resp.respuesta.codigo);
      }else if (resp.respuesta.codigo > 400) {
        Tools.swalError('¡Oops!','Lo sentimos, ha ocurrido un error inesperado y no fue posible crear la matriz, por favor intenta nuevamente.');
      }
    });
  }

  changeSelect(){
    if (this.matrixForm.get('tipo_matriz_id').value == 1) {
      this.matrixForm.get('rango_id').disable();
      this.matrixForm.get('tipo_peso_id').disable();
    }else{
      this.matrixForm.get('rango_id').enable();
      this.matrixForm.get('tipo_peso_id').enable();
    }

    if (this.matrixForm.get('campania_asociada').value == 1) {
      this.matrixForm.get('crm_id').enable();
    }else{
      this.matrixForm.get('crm_id').disable();
    }
    
    if (this.matrixForm.get('aplicar_inteligenciaNegocio').value == 0) {
      this.matrixForm.get('inteligenciaNegocio_id').disable();
    }else{
      this.matrixForm.get('inteligenciaNegocio_id').enable();
    }
  }

  getSelects(){
    this.matrizService.getTypeMatrix().subscribe((resp:any)=>{
      this.tiposMatrices = resp
    });

    this.matrizService.getIntelligence().subscribe((resp:any)=>{
      this.inteligencias = resp;
    });

    this.matrizService.getRange().subscribe((resp:any)=>{
      this.rangos = resp;
    })

    this.matrizService.getWeight().subscribe((resp:any)=>{
      resp.forEach(tipo => {
        if (tipo.nombre === "Peso en Items") {
          tipo.nombre = 'Peso en Ítems';
        }
      });
      this.tipos_peso = resp;
    })

    this.matrizService.getCampaings().subscribe((resp:any)=>{
      this.campaigns = resp.data;
    });

  }
}
