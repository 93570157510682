import { ExtraHoursResponseComponent } from './../../../../nomina/modules/extra-hours/pages/extra-hours-response/extra-hours-response.component';
import { Component, AfterViewInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CandidateCreateComponent } from '../candidate-create/candidate-create.component';
import { CandidatesService } from '../../../services/candidates.service';
import { CandidateCommentsComponent } from '../candidate-comments/candidate-comments.component';
import { CandidateEditComponent } from '../candidate-edit/candidate-edit.component';
import { RequestDetailsDialogComponent } from '../../requests/request-details-dialog/request-details-dialog.component';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ContractCreationComponent } from '../contract-creation/contract-creation.component';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { CandidateAttachmentsComponent } from '../candidate-attachments/candidate-attachments.component';
import { TrainingGroupsService } from '../../../services/training-groups.service';
import { saveAs as importedSaveAs } from "file-saver";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AgreementsType } from '../contract-creation/contractTypes';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { I } from '@angular/cdk/keycodes';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
  selector: 'app-candidate-main',
  templateUrl: './candidate-main.component.html',
  styleUrls: ['./candidate-main.component.sass']
})
export class CandidateMainComponent  {

  displayedColumns: string[] = ['check', 'actions', 'scheduled','state', 'name', 'id_number', 'mobilephone', 'email', 'position', 'campaign', 'tracingAvg'];
  displayedColumnsDisabled: string[] = ['state', 'name', 'id_number', 'mobilephone', 'email', 'position', 'campaign', 'tracingAvg'];

  selection = new SelectionModel(true, []);
  dataSource = new MatTableDataSource<any>();
  dataSourceDisabbledUsers: MatTableDataSource<any>;

  length: number;
  pageSize: number;
  lengthDisabled: number;
  pageSizeDisabled: number;
  currentPage = 1;
  currentPageDisabled = 1;
  showCandidates = false;
  selected = new FormControl(0);

  indexTab = 0;
  identification: any = '';
  email: any = '';
  formador: any = '';
  mobile: any = '';
  position: any = '';
  campaign: any = '';
  campaigns:any = [];
  tab: number = 0;
  status: any = [];
  statusTypes: any = [];
  typeFilter:any
  isLeader:boolean = false
  hiring: boolean = false
  nameFile: string=''
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('fileInput', {static: false})
  fileUpload: ElementRef;
  pageSizeOptions: number[] = [10, 50, 100, 150, 200];
  public page : number = 1;
  public per_page : number = 10;
  public pageDisabled : number = 1;
  public per_pageDisabled : number = 10;
  constructor(public dialog: MatDialog, private _candidate:CandidatesService, public AlertsService: AlertsService,
     public router: Router, public auth:AuthService, private serviceGroups: TrainingGroupsService,
     private destroyService: DestroyComponentService) {
      this.auth.getUser().roles.forEach(element => {
        if(element == 'rrhh::coordinador-de-contratacion'){
          this.isLeader = true
        }
        if(element == 'rrhh::coordinador-de-contratacion' || element == 'rrhh::analista-de-contratacion'){
          this.hiring = true
        }
      });
  }
  ngOnInit(): void {

    this.getCandidates();
    this.selection.clear();
  }
  getCandidates(){
        this._candidate.candidateByUserLoggued({page: this.page, per_page: this.per_page, identification: this.identification, email: this.email,
           mobile: this.mobile, position:this.position, state:this.status, campaign:this.campaign, disable:0}).subscribe( (candidate:any) => {
          if (candidate.total > 0) this.showCandidates = true;
          this.length = candidate.total;
          this.pageSize = candidate.per_page;
          this.dataSource = new MatTableDataSource(candidate.data);
          this.dataSource.sort = this.sort;
        })
        this.getStates();
  }

  getCandidatesDisabled(){
    this._candidate.candidateByUserLoggued({page: this.pageDisabled, per_page: this.per_pageDisabled, identification: this.identification, email: this.email,
       mobile: this.mobile, position:this.position, state:this.status, campaign:this.campaign, disable:1}).subscribe( (candidate:any) => {
      if (candidate.total > 0) this.showCandidates = true;
      this.lengthDisabled = candidate.total;
      this.pageSizeDisabled = candidate.per_page;
      this.dataSourceDisabbledUsers = new MatTableDataSource(candidate.data);
      this.dataSourceDisabbledUsers.sort = this.sort;
    })
}
changeTab(event?)
{
  this.indexTab = event;
  event == 0 ? this.getCandidates() : this.getCandidatesDisabled();
  this.tab = event;
}


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  newRegister(): any{
    const registerDialog = this.dialog.open( CandidateCreateComponent, {
      minWidth: '900px',
      minHeight: '400px'
    });

    registerDialog.afterClosed().subscribe( (refreshRequests) => {
    });
  }
  filterByValue(event){
    this.pageDisabled = 1
    this.page = 1
    this.paginator.pageIndex = 0;

    this.identification=''
    this.email=''
    this.mobile = ''
    this.position = '';
    this.campaign = '';
    this.status = '';
    this.formador = '';
    switch (this.typeFilter) {
      case "Identificación":
        this.identification = event;
       break;
      case "Email":
        this.email= event
       break;
      case "Teléfono":
        this.mobile = event
        break;
      case "Cargo":
        this.position= event
       break;
      case "Campaña":
        this.campaign = event
        break;
      case "Estado":
        this.status = event
        break;
      case "Formador":
      this.formador = event
      break;
    }
    this.indexTab == 0 ? this.getCandidates() : this.getCandidatesDisabled();
  }

  getPaginatorData(event){
    this.ValidateFilterParams(event);
    this.destroyService.destroyComponent();
    this.getCandidates();
  }

  getPaginatorDataDisabled(event){
    this.ValidateFilterParams(event);
    this.destroyService.destroyComponent();
    this.getCandidatesDisabled();
  }

  getComments(candidateId,requestId){
    const commentDialog = this.dialog.open( CandidateCommentsComponent, {
      data: { candidateId, requestId: requestId }
    });
    commentDialog.afterClosed().subscribe( (params) => {
      this.getCandidates();

    });
  }

  editCandidate(candidateId){
    const candidateEdit = this.dialog.open( CandidateEditComponent, {
      minWidth: '80%',
      data: { candidateId }
    });
    candidateEdit.afterClosed().subscribe( (params) => {
      this.tab == 0 ? this.getCandidates() : this.getCandidatesDisabled();
    });
  }

  requestDetail(requestId){
    const requestDetail = this.dialog.open( RequestDetailsDialogComponent, {
      minWidth: '80%',
      data: { requestId:requestId }
    });
    requestDetail.afterClosed().subscribe( (params) => {
    });
  }

  cv(candidateId, massive?){
    this._candidate.getCandidateDataJson(candidateId, 'personal_data').subscribe( (resp: any) => {
      if(!Object.keys(resp).length)
      {
        this.AlertsService.alertInfo('Completar datos','Por favor completa los datos personales del usuario');
        this.selection.deselect(candidateId);
      }else if(!massive){
        this.router.navigate([`/mios/rrhh/candidato-cv/${candidateId}`]);
      }
    });
  }

  agreementsView(agreements, id, status){
    const contractForm = this.dialog.open( ContractCreationComponent, {
      minWidth: '80%',
      data: {candidateId: id, agreementRules: agreements, action:'view', isLeader:this.isLeader, state: status}
    })
    contractForm.afterClosed().subscribe(res=>{
      if(res){
        this.getCandidates();
      }
    })
  }
  attachmentsView(candidateId){
    const attachmentsDialog = this.dialog.open( CandidateAttachmentsComponent, {
      data: {candidateId: candidateId}
    })
  }
  getCampaigns(){
    if(this.typeFilter == 'Campaña'){
      this.serviceGroups.getDataFilters(`dropdown/options/campana`).subscribe(res=>{
        this.campaigns= res
      })

    }
  }
  importAgreements(file){

    this.nameFile=  file[0].name
    let formData = new FormData();
    formData.append('file', file[0]);
    this._candidate.saveAgreement('agreement/rules/import',formData).subscribe(res=>{
      this.nameFile=''
      this.fileUpload.nativeElement.value = "";
      this.AlertsService.alertSuccess('¡Importado!', 'La reglas de contrato han sido cargadas con éxito')
      const contractSDialog = this.dialog.open( TypeContracts, {
        data:{action:true, fileUpdaloaded:res}
      })

    },err=>{
      this.nameFile=''
      this.fileUpload.nativeElement.value = "";
    })
  }
  exportAgreements(){ // formato de agreements
    const contractSDialog = this.dialog.open( TypeContracts,{
      data:{action:false}
    })
    contractSDialog.afterClosed().subscribe(resp=>{
      if(resp){
        this._candidate.downdloadFile(`agreement/rules/export/format?contract_type=${resp}`).subscribe(res=>{
          importedSaveAs(res, `formatoReglas.xlsx`);
        })
      }
    })


  }

  /**
       * @author Carlos Nieto
       * @createdate 2022-07-31
       * funcion que restablece las credenciales de self por defecto y renvia el email al candidato
       */
  resentEmail(candidateId,candidateEmail)
  {
    this.AlertsService.alertConfirm(
      '¿Estás seguro?',
      'Esta solicitud restablecerá la contraseña por defecto a Cos2022* y reenviará el correo al candidato con las credenciales de acceso'
      ).then((result) => {
      if (result.isConfirmed) {
        this._candidate.resentCredentialsByEmail({id:candidateId,email:candidateEmail}).subscribe(
          response =>{
            this.AlertsService.alertSuccess(
              'Email enviado',
              `Hemos restablecido la contraseña del candidato por defecto y reenviado el correo con las credenciales de acceso. Usuario: ${response['user_name']}`)
          }
        )
      }
    });
  }

  /**
	   * valida el paginado
	   * @author Carlos Nieto
	   * @param event
	   * @returns string
	   /**/
	public ValidateFilterParams(event)
	{
    if(this.tab == 0)
    {
      this.page =  event.pageIndex + 1,
      this.per_page = event.pageSize
    }else{
      this.pageDisabled =  event.pageIndex + 1,
      this.per_pageDisabled = event.pageSize
    }

	}
  getStates(){
      this._candidate.getStates().subscribe(res=>{
        this.statusTypes = res;
      })
    }

  /** MJ00035 - Modificación proceso para creación carga masiva de reglas de contratación
   * https://trello.com/c/VNacyHVa/124-mj00035-modificaci%C3%B3n-proceso-para-creaci%C3%B3n-carga-masiva-de-reglas-de-contrataci%C3%B3n
   * @createdate 10-08-2022
   * @author Lina Hueso
   *
  */
   openContractForm(){
    const contractForm = this.dialog.open( ContractCreationComponent, {
      minWidth: '80%',
      disableClose: true,
      data: {
        candidateId: '',
        agreementRules: '',
        action:'create',
        contract: '',
        specialPolicy: '',
        infoBpms: '',
        massive: true,
        ids: this.selection.selected
      }
    })
    contractForm.afterClosed().subscribe( (params) => {
      if(params){
        this.getCandidates();
        this.selection.clear();
      }
      //
    })
  }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-05-05
   * Metodo para seleccionar las filas en la tabla
   */
     isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-05-05
   * Selecciona todas las filas si no están todas seleccionadas; de lo contrario la selección se limpia.
   */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row.id));
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-09-13
   * envia una alerta al dar click en el check si el usuario ya tiene agremet rules
   * valida segun documentacion de abajo
   */
   validateAgremetRules(row) {
     /* valida si el usuario ya tiene personal data cargado */
     this.cv(row.id,true);
      /* valida si tiene archivos adjuntos */
      if(row.attachements)
      {
        if(row.attachements != [])
        {
          /* valida si tiene generado un contrato, mas no la aprobacion del mismo ni nada mas */
          if(row.attachements.find(contract => contract.file_attachment_id == 16))
          {
            /* valida si tiene generada una firma sobre el contrato, mas no la aprobacion de la misma ni nada mas */
            if(row.attachements.find(signatureContract => signatureContract.file_attachment_id == 20 ))
            {
              this.AlertsService.alertInfo('Alerta','Este usuario ya posee una firma sobre el contrato, por lo cual no se puede modificar sus condiciones de contratación de forma masiva.');
              this.selection.select(row.id)
              return;
            }else if(!this.selection.isSelected(row.id)){
              this.AlertsService.alertInfo('Cuidado','Este usuario ya posee un contrato generado, pero aún no se ha firmado');
              return;
            }
          }
        }
      }
     /* valida si el usuario ya posee reglas de contratacion */
     if(row.agreementRules != null && !this.selection.isSelected(row.id))
     {
      this.AlertsService.alertInfo('Cuidado','Este usuario ya posee reglas de contratación, por lo cual sobrescribirá las ya existentes');
     }
  }
}

@Component({
  selector: 'type-resources',
  templateUrl: './types-contracts.html',
  styleUrls: ['./candidate-main.component.sass']
})
export class TypeContracts  {
  option:any
  types = AgreementsType
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any) { }

}
