import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CampaignsGuard } from 'src/app/core/guards/campaigns.guard';
import { ScheduleGuard } from 'src/app/core/guards/schedule.guard';
import { CrmComponent } from './crm.component';
import { GroupsGuard } from '../../core/guards/groups.guard';
import { FormsGuard } from '../../core/guards/forms.guard';


const routes: Routes = [

  { path: '', component: CrmComponent},
  // { path: '', redirectTo: 'forms', pathMatch: 'full'},
  { path: 'campaigns', loadChildren: () => import('./modules/campaigns/campaigns.module').then(m => m.CampaignsModule), canActivate: [ScheduleGuard, CampaignsGuard]},
  { path: 'groups', loadChildren: () => import('./modules/groups/groups.module').then(m => m.GroupsModule), canActivate: [ScheduleGuard, GroupsGuard]},
  { path: 'forms', loadChildren: () => import('./modules/forms/forms.module').then(m => m.FormsModule), canActivate: [ScheduleGuard,FormsGuard]},
  { path: 'trays', loadChildren: () => import('./modules/trays/trays.module').then(m => m.TraysModule)},
  { path: 'reports', loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)},
  { path: 'templates', loadChildren: () => import('./modules/templates/templates.module').then(m => m.TemplatesModule)},
  { path: 'outbound-manager', loadChildren: () => import('./modules/outbound-manager/outbound-manager.module').then(m => m.OutboundManagerModule)},
  { path: 'circuits', loadChildren: () => import('./modules/circuits/circuits.module').then(m => m.CircuitsModule)},
  { path: 'reports-form', loadChildren: () => import('./modules/reports-form/report-form.module').then(m => m.ReportsFormModule)},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CrmRoutingModule { }
