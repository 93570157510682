<div class="dialog-choose-campaign">
	<div class="content-dialog mt-4">
		<h3 mat-dialog-title class="fw-700">¡Elige la campaña!</h3>
		<mat-divider></mat-divider>
		<mat-dialog-content class="mat-typography table-border">
			<table mat-table [dataSource]="dataSource" class="mt-2">
				<!-- Position Column -->
				<ng-container matColumnDef="index">
					<th mat-header-cell *matHeaderCellDef>N°</th>
					<td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
				</ng-container>

				<!-- Name Column -->
				<ng-container matColumnDef="code">
					<th mat-header-cell *matHeaderCellDef>Código</th>
					<td mat-cell *matCellDef="let element">{{ element.campaign.code }}</td>
				</ng-container>

				<!-- Weight Column -->
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>Nombre</th>
					<td mat-cell *matCellDef="let element">{{ element.campaign.name }}</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
				<tr mat-row *matRowDef="let row; let i = index; columns: columns" [ngClass]="{ highlight: selectedRowIndex == i }" (click)="highlight(row, i)" style="cursor: pointer"></tr>

				<!-- Row shown when there is no matching data. -->
				<tr class="mat-row" *matNoDataRow>
					<td colspan="9">No hay campañas</td>
				</tr>
			</table>
		</mat-dialog-content>
		<mat-dialog-actions align="center" class="mtn-64 mbn-40">
			<button mat-raised-button color="primary" class="button-dimensions mln-40" (click)="chooseCampaign()" [disabled]="!selectedCampaign">Elegir</button>
		</mat-dialog-actions>
	</div>
</div>
