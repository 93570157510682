import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DestroyComponentService } from '../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { MatDialog } from '@angular/material/dialog';
import { SaveCampaignsComponent } from '../save-campaigns/save-campaigns.component';
import { CampaignsService } from '../../../services/rest/campaigns.service';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-admin-campaigns',
  templateUrl: './admin-campaigns.component.html',
  styleUrls: ['./admin-campaigns.component.sass'],
})
export class AdminCampaignsComponent implements OnInit {
  // MatPaginator Inputs
  length;
  pageSize = 5;
  page = 1;
  isPage = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  filterValue;

  displayedColumns: string[] = [
    'acciones',
    'created_at',
    'empresa',
    'code',
    'name',
    'users',
    'created_by',
    'estado',
  ];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    public campaignService: CampaignsService,
    private destroyService: DestroyComponentService,
    private alertsService: AlertsService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getCampaigns();
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que trae las campañas registradas
   */
  getCampaigns(): void {
    if (this.dataSource.filter === '') {
      this.dataSource.filter = null;
    }
    this.campaignService
      .getCampaigns(this.isPage, this.pageSize, this.page)
      .subscribe((resp) => {
        this.dataSource.data = resp.data.data;

        this.dataSource.sort = this.sort;
        this.length = resp.data.total;
        this.pageSize = resp.data.per_page;
      });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que realiza el filtro
   * @param event datos para filtrar que son enviados desde el input
   * @param filterPage
   */
  applyFilter(event: Event, filterPage = null): void {
    const filterValue = event ? (event.target as HTMLInputElement).value : filterPage;

    if (event != null) {
      this.pageSize = 5;
      this.page = 1;
    }

    if (filterValue === '') {
      this.getCampaigns();
    } else {

      this.filterValue = filterValue;
      this.campaignService
        .searchCampaigns(this.pageSize, this.page, this.filterValue)
        .subscribe((resp) => {
          this.dataSource.data = resp.data.data;

          this.dataSource.sort = this.sort;
          this.length = resp.data.total;
          this.pageSize = resp.data.per_page;
        });
    }
  }

  /**
   * Metodo que se encarga del movimiento del paginador
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * @param event cuando se da click en el paginador trae este evento
   * @param filterValue
   */
  pageEvent(event: any, filterValue = null): void {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    if (filterValue) {
      this.applyFilter(null, filterValue);
    } else {
      this.getCampaigns();
    }
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que abre el modal de crear una nueva campaña, al cerral el modal se refresca la tabla
   */
  newCampaign(): void {
    const dialogNewRol = this.dialog.open(SaveCampaignsComponent, {
      width: '40%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Nueva campaña',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.pageSize = 5;
      this.ngOnInit();
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que abre el modal de editar campaña, envia una campaña, al cerrar el modal se refresca la tabla
   * @param campaign info de campaña
   */
  editCampaign(campaign: any): void {
    const dialogNewRol = this.dialog.open(SaveCampaignsComponent, {
      width: '40%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Editar campaña',
        campaign,
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.pageSize = 5;
      this.ngOnInit();
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que elimina un rol
   * @param campaign campaña a eliminar
   */
  deleteCampaign(campaign: any): void {
    this.alertsService
      .alertConfirm('¿Está seguro de inactivar esta campaña?')
      .then((result) => {
        if (result.isConfirmed) {
          campaign.state = 0;

          this.campaignService
            .deleteCampaing(campaign, campaign.id)
            .subscribe((resp) => {
              this.alertsService.alertSuccess('Inactivo', resp.data);
              this.ngOnInit();
            });
        }
      });
  }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-08-13
   * Metodo que activa una campaña
   * @param campaign campaña a activar
   */
  activateCampaign(campaign: any): void {
    this.alertsService
      .alertConfirm('¿Está seguro de activar esta campaña?')
      .then((result) => {
        if (result.isConfirmed) {
          campaign.state = 1;

          this.campaignService
            .deleteCampaing(campaign, campaign.id)
            .subscribe((resp) => {
              this.alertsService.alertSuccess('Activado', resp.data);
              this.ngOnInit();
            });
        }
      });
  }

  /**
   * Descargar campañas activas
   * @author Andres Buitrago
   */
  downloadReport() {
    this.campaignService
      .exportCampaigns()
      .subscribe((resp) => importedSaveAs(resp, 'reporte_campanias.xlsx'));
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }
}
