import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { PositionsService } from '../../../services/positions.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { PositionCreateComponent } from '../position-create/position-create.component';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { CreateChargeComponent } from '../create-charge/create-charge.component';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';

@Component({
  selector: 'app-positions-view',
  templateUrl: './positions-view.component.html',
  styleUrls: ['./positions-view.component.sass']
})
export class PositionsViewComponent implements OnInit {
  displayedColumns: string[] = ['actions','name','key', 'postition_category_id', 'created_at'];
  dataSource: MatTableDataSource<any>;
  showCandidates = false;
  length: number;
  pageSize = 50;
  page = 1;
  currentPage = 1;
  name: any = '';
  typeFilter:any
  positions: any[];
  pageSizeOptions: number[] = [50, 100, 150];
  public queryParams : string = 'flag=actives&page=1&per_page=50';



  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor( private positionsService:PositionsService,
      private dialog: MatDialog, private alertService:AlertsService, private destroyService: DestroyComponentService,
      ) { }

  ngOnInit(): void {
    this.getPositions()
  }
  getPositions(){
    this.positionsService.getPositions(this.queryParams)
    .subscribe((positions:any) =>{
      if (typeof positions.data !== 'undefined') {
        this.dataSource = new MatTableDataSource(Object.values(positions.data));
      }else{
        this.dataSource.data = [];
      }
      if (positions.total > 0) this.showCandidates = true;
      this.dataSource.sort = this.sort;
      this.length = positions.total;
      this.pageSize = positions.per_page;
      this.positions = positions.data
    })
  }
  filterByValue(filterValue){
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
		this.queryParams = `flag=actives&filter=${filterValue}`;
    this.destroyService.destroyComponent();
    this.getPositions();
    this.dataSource.filter = filterValue;
  }
  getPaginatorData(event){
		this.queryParams = this.ValidateFilterParams(event);
		//this.validInit = false;
    this.destroyService.destroyComponent();
    this.getPositions()
  }

  openCreate(action, data?){
    const createDialog = this.dialog.open( CreateChargeComponent, {
      disableClose: true,
      width: '600px',
      data: { 
        action: action, 
        id: action == "Editar" ? data.id : null,
        data: action == "Editar" ? data : null
      }
    });
    createDialog.afterClosed().subscribe( (params) => {
      if(params){
        this.getPositions()
      }
    });
  }

  viewPosition(position ){
    this.dialog.open(PositionsViewDialog, {
      disableClose: true,
      data: {
        function: position.functions,
        name: position.type_function_name
      }
    })
  }

  deletePosition(id): void{
    let saveconfirm1= this.alertService.alertConfirm('¿Está seguro que desea eliminar este cargo? Una vez eliminado, no podrá recuperarlo')
    saveconfirm1.then(res=>{
      if(res.isConfirmed){
        this.positionsService.deletePosition(id).subscribe((res:any)=>{
          this.alertService.alertSuccess('Eliminado', 'Cargo eliminado correctamente');
          this.getPositions()
        })
      }
    })
  }

  /**
	   * Primero valida si existe en la variable el string filter,
		 * en cuyo caso obtiene 'filter={info digitada}', limpiando los demas datos,
		 * luego vuelve a evaluar si existe filter en cuyo caso lo concatena al string
		 * en caso de que no exista arma el string solo con con page y pagezise
	   * @author Carlos Nieto
	   * @param event
	   * @returns string
	   /**/
	public ValidateFilterParams(event) : string
	{
		this.queryParams = 	this.queryParams.includes("filter") ?
		this.queryParams.substring(this.queryParams.indexOf('filter')) : this.queryParams;
		this.queryParams = this.queryParams.includes("filter") ?
		`flag=actives&page=${event.pageIndex + 1}&per_page=${event.pageSize}&${this.queryParams}` :
		`flag=actives&page=${event.pageIndex + 1}&per_page=${event.pageSize}` ;

		return this.queryParams;
	}
}


@Component({
  selector: 'positions-view-dialog',
  templateUrl: './positions-view-dialog.html',
  styleUrls: ['./positions-view.component.sass']
})
export class PositionsViewDialog {

  constructor(
    private dialogRef: MatDialogRef<PositionsViewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

}
