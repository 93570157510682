import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({
  name: 'userPhoto'
})
export class UserPhotoPipe implements PipeTransform {

 /**
   * @author Karol García
   * @createdate 2021-07-28
   * Pipe que permite visualizar una imagen tipo Blob traida de una consulta.
   */
  constructor(private sanitizer: DomSanitizer) { }

 transform(image: Blob) {
    if(image){
      let fileURL = window.URL.createObjectURL(image);
      let fileSrc = this.sanitizer.bypassSecurityTrustUrl(fileURL);
      return fileSrc
    }else{
      return 'assets/images/dummy-user.jpg';
    }
  }
} 
