import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { EscalationService } from '../../../services/rest/escalation.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { saveAs as importedSaveAs } from 'file-saver';
import Swal from 'sweetalert2';
import { FormsRequestService } from '../../../services/rest/forms-request.service';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { ViewPlantillasComponent } from '../view-plantillas/view-plantillas.component';

@Component({
  selector: 'app-view-escalation',
  templateUrl: './view-escalation.component.html',
  styleUrls: ['./view-escalation.component.sass']
})
export class ViewEscalationComponent implements OnInit {

  formEscalamiento = new FormGroup({
    bodyHtml: new FormControl('Mensaje html', [])
  });
  estado: any;
  user: any;
  area: any;
  userDependence: any;
  resendDependence: any;
  otherEmailDependence: any;
  filteredOptionsUser: Observable<any[]>;
  rol: string;
  sectionForm: any;
  pintarCrm1: boolean = false;
  pintarCrm2: boolean = false;
  userJson: any;
  isNewClient = false;
  adjuntosPqrs: any[] = [];
  length;
  pageSize = 5;
  page = 1;
  form = null;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  escalation: any = null;
  externalEmailContacts = [];
  displayedColumns: string[] = ['updated_at', 'observacion_user', 'observacion_estado', 'observacion'];
  dataSource = new MatTableDataSource<any>();
  escalationTemp = null;
  customerSection = null;
  htmlTemplate = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public escalationService: EscalationService,
    private authService: AuthService,
    private destroyService: DestroyComponentService,
    private alertsService: AlertsService,
    public dialogRef: MatDialogRef<ViewEscalationComponent>,
    private formsRequestService: FormsRequestService,
    private dialog: MatDialog,
    public dialogRefViewPlantillas: MatDialogRef<ViewPlantillasComponent>) { }

  ngOnInit(): void {
    let user = this.authService.getUser();
    user.roles.forEach(rol => {
      let name = rol.split('::');
      if (name[0] === 'pqrs') {
        this.rol = name[1];
      }
    });

    this.getHistory(this.pageSize, this.page);
    this.escalationService.getEstado().subscribe((responseEsta) => {
      this.estado = responseEsta;
    });
    this.obtenerAdjuntos();
    this.getEscalation();
  }

  getEscalation() {
    this.escalationService.getEscalation(this.data.id).subscribe(res => {
      this.escalationTemp = res.pqrs[0];
      this.escalation = res.pqrs[0];
      this.externalEmailContacts = res.external_email_contacts;
      this.resendDependence = [{ label: "Reenviar", idField: 5, options: this.externalEmailContacts.concat({ id: 9999999, name: 'Otro' }), activators: [{ id: 1, name: 'Sí' }] }];
      this.otherEmailDependence = [{ label: "Correo de destino", idField: 6, options: [], activators: [{ id: 9999999, name: 'Otro' }] }];


      if (this.escalation.form_id != undefined && this.escalation.form_id != null && this.escalation.form_id != 0) {
        let $getForm: Observable<any>;

        //Si se tiene la propiedad crm_service es por que se puede determinar que dynamicform debe renderizar la PQRS
        if (this.escalation.hasOwnProperty('crm_service') == true) {

          if (String(this.escalation.crm_service) == "1") {// En caso de Ser CRM V1
            $getForm = this.formsRequestService.getData(`searchform/${this.escalation.form_id}`)
          } else if (String(this.escalation.crm_service) == "2") {//En caso de ser CRM V2
            if (this.escalation['preguntas'] !== undefined && this.escalation['preguntas'] !== null && this.escalation?.preguntas['sections'] !== undefined) {
              this.escalation?.preguntas?.sections.map((section: any, indexS: number) => {
                section.fields.map((field: any, indexF: number) => {
                  if (field.value !== undefined && field.value !== null && field.value !== "") {
                    field['newValue'] = field.value
                  }
                })
              });
            }


            $getForm = this.formsRequestService.getDataCrmTwo(`searchform/${this.escalation.form_id}`)
          }
        } else {//Si no se determina usa por defecto el antiguo
          $getForm = this.formsRequestService.getData(`searchform/${this.escalation.form_id}`)
        }

        $getForm.subscribe(form => {
          this.form = form
          if (this.escalation.hasOwnProperty('crm_service') == true && String(this.escalation.crm_service) == "2") {
            this.form['section'] = this.form['sections']
          }

          if (this.rol != 'solucionador') {
            let section = this.form.section;
            section.pop();
            this.form.section = section;
          }
          this.escalationService.getAreaAndUsers(this.escalation.form_id, this.form.campaign_id).subscribe((respo) => {
            this.escalationService.getEmailsByExit().subscribe((dataEmails) => {
              if (respo['areas'] !== undefined && Array.isArray(respo['areas'])) {
                this.area = respo.areas;
              } else {
                this.area = []
              }

              this.userDependence = [];
              this.userJson = [{ name: '', id: null }];
              this.area.forEach(element => {
                this.userJson = this.userJson.concat(element.users);
                this.userDependence.push({ label: "Reasignar", idField: 2, options: [{ name: '', id: null }].concat(element.users), activators: [{ id: element.id, name: element.name }] });
              });
              this.htmlTemplate = dataEmails.emailTemplates;
              if (this.escalation.is_anonimous == 1) {
                this.customerSection = this.escalation.preguntas.sections.shift();
                this.form.section.shift();
              }
              if (this.escalation['preguntas'] == undefined || this.escalation['preguntas'] == null || this.escalation['preguntas'] == "") {
                this.isNewClient = true;
                this.escalation.preguntas = { sections: this.form.section }
              }

              this.creationForm();
            })
          });
        });
      }

    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-17
   * Creacion del json de la seccion del formulario requerida obligatoriamente para cada campaña
   */
  creationForm(): void {

    if (this.escalation.hasOwnProperty('crm_service') == true) {
      this.sectionForm = this.getSectionDefault(String(this.escalation['crm_service']));
    } else {
      this.sectionForm = this.getSectionDefault('1');
    }

    if (this.escalation.preguntas) {
      if (this.escalation.preguntas.hasOwnProperty('sections') == false || this.escalation.preguntas['sections'] == undefined) {
        this.escalation.preguntas['sections'] = []
      }
      this.escalation.preguntas.sections.push(this.sectionForm);
    }
    else {
      this.escalation["preguntas"] = { sections: [this.sectionForm] };
    }


    if (this.escalation.hasOwnProperty('crm_service') == true) {
      if (String(this.escalation.crm_service) == "1") {
        this.pintarCrm1 = true;
        this.pintarCrm2 = false
      } else if (String(this.escalation.crm_service) == "2") {
        this.pintarCrm1 = false;
        this.pintarCrm2 = true
      }
    } else {
      this.pintarCrm1 = true;
      this.pintarCrm2 = false
    }

  }

  /**
   * Metodo que retorna la estructura del section por defecto segun el tipo de formularion CRM ( 1- 2)
   * @param type:string {string} tipo de CRM
   * @returns any {any} Objeto por Defecto
   */
  getSectionDefault(type: string): any {
    if (type == "1") {
      return {
        "id": null,
        "fields": [
          {
            "id": 1,
            "see": true,
            "dependencies": [],
            "key": "estado",
            "cols": 1,
            "type": "options",
            "label": "Estado",
            "preloaded": false,
            "value": this.escalation.estado_id,
            "options": this.estado,
            "required": false,
            "isSon": true,
            "seeRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "editRoles": [
              "solucionador",
              "supervisor_esca",
              'administrador'
            ],
            "maxLength": 20,
            "minLength": 2,
            "controlType": "dropdown"
          },
          {
            "id": 2,
            "see": true,
            "dependencies": [],
            "key": "reasignar",
            "cols": 1,
            "type": "options",
            "label": "Reasignar",
            "preloaded": false,
            "value": 1,
            "options": this.area,
            "required": false,
            "isSon": true,
            "seeRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "editRoles": [
              "solucionador",
              "supervisor_esca",
              'administrador'
            ],
            "maxLength": 20,
            "minLength": 2,
            "controlType": "dropdown"
          },
          {
            "id": 3,
            "see": true,
            "dependencies": this.userDependence,
            "key": "responsable",
            "cols": 2,
            "type": "options",
            "label": "Responsable",
            "preloaded": false,
            "value": this.escalation.p_q_r_s_solucionador.id,
            "options": this.userJson,
            "required": false,
            "isSon": true,
            "seeRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "editRoles": [
              "solucionador",
              "supervisor_esca",
              'administrador'
            ],
            "maxLength": 20,
            "minLength": 2,
            "controlType": "dropdown"
          },
          {
            "id": 5,
            "see": true,
            "dependencies": [],
            "key": "reenviar",
            "cols": 1,
            "type": "others",
            "label": "Reenviar",
            "preloaded": false,
            "value": 2,
            "options": [{ id: 2, name: 'No' }, { id: 1, name: 'Sí' }],
            "required": false,
            "isSon": true,
            "seeRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "editRoles": [
              "solucionador",
              "supervisor_esca",
              'administrador'
            ],
            "maxLength": 20,
            "minLength": 2,
            "controlType": "radiobutton"
          },
          {
            "id": 6,
            "see": true,
            "dependencies": this.resendDependence,
            "key": "correo_reenvio",
            "cols": 2,
            "type": "options",
            "label": "Correo de destino",
            "preloaded": false,
            "value": null,
            "options": this.externalEmailContacts.concat({ id: 9999999, name: 'Otro' }),
            "required": false,
            "isSon": true,
            "seeRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "editRoles": [
              "solucionador",
              "supervisor_esca",
              'administrador'
            ],
            "maxLength": 100,
            "minLength": 2,
            "controlType": "dropdown"
          },
          {
            "id": 7,
            "see": true,
            "dependencies": this.otherEmailDependence,
            "key": "correo_reenvio_otro",
            "cols": 1,
            "type": "text",
            "label": "Ingrese un correo",
            "preloaded": false,
            "value": null,
            "options": [],
            "required": false,
            "isSon": true,
            "seeRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "editRoles": [
              "solucionador",
              "supervisor_esca",
              'administrador'
            ],
            "maxLength": 100,
            "minLength": 2,
            "controlType": "textbox"
          },
          {
            "id": 4,
            "see": true,
            "dependencies": [],
            "key": "respuestaCliente",
            "cols": 4,
            "type": "text",
            "label": "Observaciones",
            "preloaded": false,
            "value": this.escalation.observacion,
            "options": [],
            "required": false,
            "isSon": true,
            "seeRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "editRoles": [
              "solucionador",
              "supervisor_esca",
              'administrador'
            ],
            "maxLength": 2000,
            "minLength": 2,
            "controlType": "textarea"
          }
        ],
        "name_section": "Gestión Escalamientos",
        "type_section": 1
      };
    }

    if (type == "2") {
      return {
        "name_section": "Gestión Escalamientos",
        "type_section": 2,
        "see": true,
        "fields": [
          {
            "id": 1,
            "type": "options",
            "see": true,
            "key": "estado",
            "controlType": "dropdown",
            "label": "Estado",
            "value": this.escalation.estado_id,
            "required": true,
            "canAdd": false,
            "options": this.estado,
            "minLength": 1,
            "maxLength": 100,
            "inReport": true,
            "isClientInfo": false,
            "disabled": false,
            "cols": 1,
            "preloaded": false,
            "isSon": false,
            "dependencies": [],
            "editRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "seeRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "tooltip": {
              "have": false,
              "content": ""
            },
            "isTypificated": false,
            "isChargeableOption": false,
            "indDefaultValue": false,
            "isCalculated": false,
            "defaultCommentValue": null,
            "contentValueDefault": false,
            "contentTooltip": [],
            "calculatedFields": [],
            "typeArithmeticOperation": "",
            "fileOptions": false
          },
          {
            "id": 2,
            "type": "options",
            "see": true,
            "key": "reasignar",
            "controlType": "dropdown",
            "label": "Reasignar",
            "value": 1,
            "required": false,
            "canAdd": false,
            "options": this.area,
            "minLength": 1,
            "maxLength": 100,
            "inReport": true,
            "isClientInfo": false,
            "disabled": false,
            "cols": 1,
            "preloaded": false,
            "isSon": false,
            "dependencies": [],
            "editRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "seeRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "tooltip": {
              "have": false,
              "content": ""
            },
            "isTypificated": false,
            "isChargeableOption": false,
            "indDefaultValue": false,
            "isCalculated": false,
            "defaultCommentValue": null,
            "contentValueDefault": false,
            "contentTooltip": [],
            "calculatedFields": [],
            "typeArithmeticOperation": "",
            "fileOptions": false
          },
          {
            "id": 3,
            "type": "options",
            "see": true,
            "key": "responsable",
            "controlType": "dropdown",
            "label": "Responsable",
            "value": this.escalation.p_q_r_s_solucionador.id,
            "required": false,
            "canAdd": false,
            "options": this.userJson,
            "minLength": 1,
            "maxLength": 100,
            "inReport": true,
            "isClientInfo": false,
            "disabled": false,
            "cols": 1,
            "preloaded": false,
            "isSon": true,
            "dependencies": this.userDependence,
            "editRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "seeRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "tooltip": {
              "have": false,
              "content": ""
            },
            "isTypificated": false,
            "isChargeableOption": false,
            "indDefaultValue": false,
            "isCalculated": false,
            "defaultCommentValue": null,
            "contentValueDefault": false,
            "contentTooltip": [],
            "calculatedFields": [],
            "typeArithmeticOperation": "",
            "fileOptions": false
          },
          {
            "id": 5,
            "type": "others",
            "see": true,
            "key": "reenviar",
            "controlType": "radiobutton",
            "label": "Reenviar",
            "value": 2,
            "disabled": false,
            "required": true,
            "canAdd": false,
            "options": [
              {
                "id": 2,
                "name": "No"
              },
              {
                "id": 1,
                "name": "Sì",
                "stateDisabled": false,
                "isDefaultOption": false
              }
            ],
            "minLength": 1,
            "maxLength": 100,
            "inReport": true,
            "isClientInfo": false,
            "preloaded": false,
            "isSon": false,
            "dependencies": [],
            "cols": "1",
            "editRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "seeRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "tooltip": {
              "have": false,
              "content": ""
            },
            "isTypificated": false,
            "isChargeableOption": false,
            "indDefaultValue": false,
            "isCalculated": false,
            "defaultCommentValue": null,
            "contentValueDefault": false,
            "contentTooltip": [],
            "calculatedFields": [],
            "typeArithmeticOperation": "",
            "fileOptions": false
          },
          {
            "id": 6,
            "type": "options",
            "see": true,
            "key": "correo_reenvio",
            "controlType": "dropdown",
            "label": "Correo de destino",
            "value": null,
            "required": false,
            "canAdd": false,
            "options": this.externalEmailContacts.concat({ id: 9999999, name: 'Otro' }),
            "minLength": 1,
            "maxLength": 100,
            "inReport": true,
            "isClientInfo": false,
            "disabled": false,
            "cols": 1,
            "preloaded": false,
            "isSon": true,
            "dependencies": this.resendDependence,
            "editRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "seeRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "tooltip": {
              "have": false,
              "content": ""
            },
            "isTypificated": false,
            "isChargeableOption": false,
            "indDefaultValue": false,
            "isCalculated": false,
            "defaultCommentValue": null,
            "contentValueDefault": false,
            "contentTooltip": [],
            "calculatedFields": [],
            "typeArithmeticOperation": "",
            "fileOptions": false
          },
          {
            "id": 7,
            "type": "text",
            "see": true,
            "key": "correo_reenvio_otro",
            "controlType": "textbox",
            "label": "Ingrese un correo",
            "value": null,
            "disabled": false,
            "required": false,
            "canAdd": false,
            "options": [],
            "minLength": 1,
            "maxLength": 50,
            "inReport": true,
            "isClientInfo": false,
            "preloaded": false,
            "isSon": true,
            "dependencies": this.otherEmailDependence,
            "cols": 1,
            "editRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "seeRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "tooltip": {
              "have": false,
              "content": ""
            },
            "isTypificated": false,
            "isChargeableOption": false,
            "indDefaultValue": false,
            "isCalculated": false,
            "defaultCommentValue": null,
            "contentValueDefault": false,
            "contentTooltip": [],
            "calculatedFields": [],
            "typeArithmeticOperation": "",
            "fileOptions": false
          },
          {
            "id": 4,
            "type": "text",
            "see": true,
            "key": "respuestaCliente",
            "controlType": "textarea",
            "label": "Observaciones",
            "value": this.escalation.observacion,
            "disabled": false,
            "required": false,
            "canAdd": false,
            "options": [],
            "minLength": 2,
            "maxLength": 2000,
            "cols": "4",
            "inReport": true,
            "isClientInfo": false,
            "preloaded": false,
            "isSon": false,
            "dependencies": [],
            "editRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "seeRoles": [
              "solucionador",
              "supervisor_esca",
              "administrador"
            ],
            "tooltip": {
              "have": false,
              "content": ""
            },
            "isTypificated": false,
            "isChargeableOption": false,
            "indDefaultValue": false,
            "isCalculated": false,
            "defaultCommentValue": null,
            "contentValueDefault": false,
            "contentTooltip": [],
            "calculatedFields": [],
            "typeArithmeticOperation": "",
            "fileOptions": false
          }
        ],
        "collapse": 0,
        "duplicate": 0

      }
    }
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-17
   * Metodo que trae el historico de las pqrs
   * @param size tamaño de los datos a traer
   * @param page pagina a traer
   */
  getHistory(size: number, page: number): void {
    this.escalationService.getHistory(this.data.id, size, page).subscribe((respGrid) => {
      this.dataSource.data = respGrid.data;
      this.dataSource.sort = this.sort;
      this.length = respGrid.total;
      this.pageSize = respGrid.per_page;
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-03-17
   * Metodo que envia el formulario ya editado
   * @param event evento que trae desde el componente del formulario dinamico
   */
  sendForm(event: any, type: string): void {
    let form: FormData;
    switch (type) {
      case 'crm':

        form = this.createFormDataToSaveFormCrm(event, 'typification');

        break;
      case 'crm2':
        form = this.createFormDataToSaveFormCrm2(event, 'typification');
        break;

      default:
        form = this.createFormDataToSaveFormCrm(event, 'typification');
        break;
    }
    this.escalation.preguntas.sections.forEach((element, index) => {
      element.fields.forEach((elementInt) => {
        for (const key in event.answer[index]) {
          if (key === elementInt.key) {
            elementInt.value = event.answer[index][key];
          }
        }
      });
    });

    if (this.rol == 'solucionador') {
      let urlPqrs = ""
      let $obsEscalation: Observable<any> = new Observable();
      if (this.escalation['crm_service'] !== undefined && String(this.escalation['crm_service']) == String(2)) {
        urlPqrs = "formanswer/saveinfo"
        $obsEscalation = this.formsRequestService.postFormCrm2(urlPqrs, form)
      } else {
        urlPqrs = this.escalation.form_answer_id ? `formanswer/update/${this.escalation.form_answer_id}` : 'formanswer/saveinfo';
        $obsEscalation = this.formsRequestService.postForm(urlPqrs, form)
      }

      $obsEscalation.subscribe(res => {
        if (urlPqrs === 'formanswer/saveinfo') {
          this.escalation.form_answer_id = res.data.formAsnwerId;
        }
        const sectionFormEscalamientos = event.sections.filter(section => section.name_section == 'Gestión Escalamientos');
        const fieldEstado = sectionFormEscalamientos[0].fields.find(field => field.key === 'estado');
        const nameEstado = fieldEstado.options.find(option => option.id === fieldEstado.value);
        if (nameEstado.name === 'Cerrado') {
          const dialogPlantillas = this.dialog.open(ViewPlantillasComponent, {
            data: {
              id: this.data.id,
              escalamiento: this.escalation,
              plantillas: this.htmlTemplate
            },
            autoFocus: false
          });
          dialogPlantillas.afterClosed().subscribe(() => {
            this.dialogRef.close();
          });
        } else {
          if (event.answer[(event.answer.length - 1)].reenviar === 1) {
            this.alertsService.alertWarning('¿Estás seguro?', 'Ten en cuenta que una vez reenviado el correo, el estado del escalamiento se cerrará')
              .then(res => {
                if (res.isConfirmed) {
                  const editForm = new FormData();
                  editForm.append('solucionador_id', this.escalation.solucionador_id);
                  editForm.append('estado_id', this.escalation.estado_id);
                  editForm.append('preguntas', JSON.stringify(this.escalation.preguntas));
                  editForm.append('form_answer_id', this.escalation.form_answer_id);
                  this.escalationService.postFormEscalation(editForm, this.data.id).subscribe((respo) => {
                    if (respo.length !== 0) {
                      this.alertsService.alertSuccess('Editado', 'La pqrs fue editada correctamente');
                      this.dialogRef.close();
                    }
                  });
                }
              })
          } else {
            const editForm = new FormData();
            editForm.append('solucionador_id', this.escalation.solucionador_id);
            editForm.append('estado_id', this.escalation.estado_id);
            editForm.append('preguntas', JSON.stringify(this.escalation.preguntas));
            editForm.append('form_answer_id', this.escalation.form_answer_id);
            this.escalationService.postFormEscalation(editForm, this.data.id).subscribe((respo) => {
              if (respo.length !== 0) {
                this.alertsService.alertSuccess('Editado', 'La pqrs fue editada correctamente');
                this.dialogRef.close();
              }
            });
          }
        }
      });
    } else {
      const sectionFormEscalamientos = event.sections.filter(section => section.name_section === 'Gestión Escalamientos');
      const fieldEstado = sectionFormEscalamientos[0].fields.find(field => field.key === 'estado');
      const nameEstado = fieldEstado.options.find(option => option.id === fieldEstado.value);
      if (nameEstado.name === 'Cerrado') {
        const dialogPlantillas = this.dialog.open(ViewPlantillasComponent, {
          data: {
            id: this.data.id,
            escalamiento: this.escalation,
            plantillas: this.htmlTemplate
          },
          autoFocus: false
        });
        dialogPlantillas.afterClosed().subscribe(() => {
          this.dialogRef.close();
        });
      } else {
        if (event.answer[0].reenviar === 1) {
          this.alertsService.alertWarning('¿Estás seguro?', 'Ten en cuenta que una vez reenviado el correo, el estado del escalamiento se cerrará')
            .then(res => {
              if (res.isConfirmed) {
                const editForm = new FormData();
                editForm.append('solucionador_id', this.escalation.solucionador_id);
                editForm.append('estado_id', this.escalation.estado_id);
                editForm.append('preguntas', JSON.stringify(this.escalation.preguntas));
                editForm.append('form_answer_id', this.escalation.form_answer_id);
                this.escalationService.postFormEscalation(editForm, this.data.id).subscribe((respo) => {
                  if (respo.length !== 0) {
                    this.alertsService.alertSuccess('Editado', 'La pqrs fue editada correctamente');
                    this.dialogRef.close();
                  }
                });
              }
            })
        } else {
          const editForm = new FormData();
          editForm.append('solucionador_id', this.escalation.solucionador_id);
          editForm.append('estado_id', this.escalation.estado_id);
          editForm.append('preguntas', JSON.stringify(this.escalation.preguntas));
          editForm.append('form_answer_id', this.escalation.form_answer_id);
          this.escalationService.postFormEscalation(editForm, this.data.id).subscribe((respo) => {
            if (respo.length !== 0) {
              this.alertsService.alertSuccess('Editado', 'La pqrs fue editada correctamente');
              this.dialogRef.close();
            }
          });
        }
      }
    }
  }
  /**
   * @author Daniel Martinez
   * @createdate 2021-03-10
   * Metodo que se encarga del paginador al momento de cambiar de pagina
   * @param event evento que trae la pagina a listar
   */
  pageEvent(event: any): void {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getHistory(this.pageSize, this.page);
  }

  ngOnDestroy(): void {
    this.escalation?.preguntas?.sections.pop();
    this.pintarCrm1 = false;
    this.pintarCrm2 = false;
    this.destroyService.destroyComponent();
  }

  guardarAdjunto(e) {
    let adjunto = e.target.files[0];
    let formData = new FormData();
    formData.append('adjunto', adjunto);
    formData.append('pqrs_id', this.data.id);
    formData.append('nombre_adjunto', adjunto.name);
    this.escalationService.savePqrsAttachment(formData).subscribe((resp) => {
      this.obtenerAdjuntos();
    }, error => {
      Swal.fire('!Atención¡', "Ha ocurrido un error al intentar guardar el adjunto", "error");
    });

  }

  obtenerAdjuntos() {
    this.escalationService.getPqrsAttachments(this.data.id).subscribe((resp) => {
      this.adjuntosPqrs = resp.adjuntos;
    });
  }

  eliminarAdjunto(adjunto_id: any, ruta: any) {
    this.escalationService.deletePqrsAttachment({ id: adjunto_id, url: ruta }).subscribe((resp) => {
      this.obtenerAdjuntos();
    }, error => {
      Swal.fire('!Atención¡', "Ha ocurrido un error al intentar eliminar el adjunto", "error");
    });
  }

  descargarAdjunto(route: any, nombre: any) {
    this.escalationService.downloadEmailAttachment({ url: route }).subscribe(resp => {
      importedSaveAs(resp, nombre);
    },
      error => {
        Swal.fire('!Atención¡', "Ha ocurrido un error al intentar descargar el adjunto", "error");
      });
  }

  /**
   * @author Edwin Sanchez
   * @author Fabian Duran
   * @createdate 2021-06-21
   * @updatedate 2021-06-22
   * Metodo que imprime toda la informacion del escalamiento en un PDF. 
   * Por favor cuando se estabilice el modulo de CRM eliminar las lineas 635, 636 y 655. 
  */
  printMail() {
    const data = document.getElementById('mail-scalation');
    const divChildrenData = document.getElementById('form_escalation');
    const tempDeleteChildren = data.removeChild(divChildrenData);
    html2canvas(data).then((canvas: any) => {
      const PAGE_MARGIN = 10
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF('p', 'mm', 'a4', true);
      doc.addImage(canvas, 'PNG', 0, position, imgWidth - 15, imgHeight - 15, '', 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
      doc.save('Escalamiento.pdf');
    });
    data.appendChild(tempDeleteChildren);
  }

  /**
* Metodo que se encarga de crear el FormData para la tipificacion o edicion de registros
* @author juan David Guerrero Vargas 
* @param dataForm:any {any} Informacion del EventoSubmit guardado del formulario
* @param typeCreate:string {string} Tipo de creacion apra el FormData
* @returns FormData {FormData}   FormData con la información a registrar
*/
  createFormDataToSaveFormCrm2(dataForm: any, typeCreate: string): FormData {

    try {
      /** Variable de control donde se recolectara la informaicon por separado para su funcion especifica **/
      let listIdFiles: string[] = [] //Arreglo donde se envia los Id delos campos tipo file apra relacionar los archivos
      let listclientNewInfo: any[] = []//Arreglo de objetos { 1123154:value} id:valor para identificar las respuestas del formulario
      let listPreload: any[] = [] // Arreglo de Fields que marcan como preload(precargados)
      let formAnswerIndexData: any = {} //Objeto Id:value {1354156:value} donde se almaceanan todas las respuestas de todos los campos del formulario sin importar las secciones
      let formAnswerData2: any = {} //Objeto que indexa lo valores tipificados en un unico objeto de respuestas
      let listReports: any = {} // Objeto unico de Id:value {1325645:value} done van el id y valor de todos los campos marcados para reporteria
      let isAnonymous: string = "0"; //Variable que valdia si dentro del formulario hay algun campo que indique que el formulario se manjara de forma anonima
      let listTypificated: any = {} //Objeto unico id:value {112358:value} con todos los campos que se marquen para tipificacion
      let listSelect = {}; //Variable que se encarga de almacenar un objeto unico de clave valor para todos los selectores tipificados

      let sections = dataForm.sections
      let demarche = {};
      sections.forEach((section: any, index: number) => {
        if (Array.isArray(section.fields)) {
          section.fields.forEach((field: any, indexField: number) => {
            if ((typeCreate == "typification" || typeCreate == "editFromTrays") && dataForm.hasOwnProperty('answer') == true) {
              for (const key in dataForm.answer[index]) {
                if (key === field.key && field.seeSons === true) {
                  field.value = dataForm.answer[index][key];
                }
              }
            }
            if (field.id === this.form.client_unique[0].id) {
              if (section.required === false) {
                if ((field.value === '' || field.value === null) && field.type === 'text') {
                  field.value = 'sin-identificador';
                }
                if ((field.value === '' || field.value === null) && field.type === 'number') {
                  field.value = 999999999;
                }
                if ((field.value === '' || field.value === null) && field.type === 'email') {
                  field.value = 'sin-identificador@mysoul.com';
                }
              }
            }

            //En caso de que sea vacio se asigna el valor False
            if (field.isClientInfo) {
              let modelClientInfo = {}
              modelClientInfo[field.id] = field.value
              listclientNewInfo.push(modelClientInfo)
            } else {
              field.isClientInfo = false
            }

            switch (field.controlType) {
              case 'currency':
                //remplazamos la (,) de los valores tipo mondea por espacio en blanco
                field.value = field.value.replaceAll(/,/ig, '')
                break;
              case 'multiselect':
                if (!Array.isArray(field.value)) {
                  field.value = [field.value]
                }
                break;
              case 'file':
                if (field.value != '') {
                  field['storage_path'] = "@@" + field.id + "@@";
                  listIdFiles.push(field.id)
                }
                if (field.storage_path != "") {
                  field['storage_path'] = "@@" + field.id + "@@"
                }
                break;
            }

            //Si el field tiene algun valor y es de precarga lo agregamos al array de valores precargados
            if (field.value && (field.preloaded !== undefined && field.preloaded !== null && field.preloaded !== '')) {
              listPreload.push(field)
            }

            //Si el campo tiene algun valor Tipoficado Su valor se indexa en el formAnwerIndexData
            if (field.value !== undefined && field.value !== null && field.value !== '') {
              formAnswerIndexData = this.getObjectWithValues(formAnswerIndexData, field, { formAnswerIndexData: true })
            }//Fin recolexcion formAnswerIndexData

            //Si se marca el campo como entrada para reprte se almacena en su objeto clave valor como identificador
            if (field.hasOwnProperty('inReport') == true && field.inReport !== undefined && field.inReport == true) {
              listReports = this.getObjectWithValues(listReports, field)
            }

            //En caso de ser marcado como Tipificar este se almacenara en el objeto Clave Valor apra su indexacion
            if (field.hasOwnProperty('isTypificated') == true && field.isTypificated !== undefined && field.isTypificated == true) {
              listTypificated = this.getObjectWithValues(listReports, field, { typificated: true })
            }

            //Si algun campo tiene como nombre anonimo se asumira que el formulario cuenta con un apartado de anonimato porlo que se envia 1 como true
            if (field.key.includes('anonimo') && field.value == '1') {
              isAnonymous = "1"
            }

            if (field.type == "options") {
              let tempOptionObject = {};
              field.options.forEach(option => {
                tempOptionObject[option.id] = option.name
              });
              formAnswerData2[field.id] = tempOptionObject
            }

            //Pasamos los valores enviados para la tipififacion dentro del clien_unique envez de enviarle solo el Id con @@
            if (field.client_unique !== undefined && field.client_unique == true) {
              if (this.form['client_unique'] !== undefined) {
                this.form['client_unique'].forEach(unique => {
                  if (unique.id == field.id) {
                    unique.value = field.value
                  }
                });
              }
            }

            //Recogemos los labels de los selectores en el formulario
            if (field.options.length > 0) {
              //Si el valor es un array es un Multiselect
              if (Array.isArray(field.value)) {
                listSelect[field.id] = []
                field.value.forEach(option => {
                  listSelect[field.id].push(option);
                });
              } else {
                if (field.value && field.value != "") {

                }
                listSelect[field.id] = [field.value]
              }
            }
            //La validacion y limpieza de esta opcion siempre se debe hacer a lo ultimo de la iteracion
            //Ya que las opciones cargas desde un enpoint no se registraran dentro del formulario pero si se usaran para determinar los valores de algunas variables de control en la cabecera del metodo
            if (field.hasOwnProperty('isChargeableOption') == true && field.isChargeableOption && field.isChargeableOption == true) {
              field.options = []
            }

          });
        }
      });

      //Se carga la informacion a guardar entro de nustro Objeto padre
      demarche['sections'] = sections;
      demarche['formAnswerIndexData'] = JSON.stringify(formAnswerIndexData)
      demarche['reporting_fields'] = JSON.stringify(listReports)
      demarche['typified_fields'] = JSON.stringify(listTypificated)
      demarche['attachmentsList'] = JSON.stringify(listIdFiles)
      demarche['clientInfo'] = JSON.stringify(listclientNewInfo)
      demarche['preloaded'] = JSON.stringify(listPreload)
      demarche['indexData'] = JSON.stringify(formAnswerData2)
      demarche['listSelect'] = JSON.stringify(listSelect)

      const formData = new FormData();


      formData.append('form_id', String(this.form.id));
      if (this.escalation.is_anonimous === 1) {
        sections.unshift(this.customerSection);
        formData.append('sections', JSON.stringify(sections));
      } else {
        formData.append('sections', JSON.stringify(sections));
      }
      formData.append('client_unique', JSON.stringify(this.form['client_unique']));
      formData.append('chronometer', "");
      formData.append('listSelect', demarche['listSelect']);
      formData.append('formAnswerIndexData', demarche['formAnswerIndexData']);
      formData.append('reporting_fields', demarche['reporting_fields']);
      formData.append('attachmentsList', demarche['attachmentsList']);
      formData.append('clientInfo', demarche['clientInfo']);
      formData.append('preloaded', demarche['preloaded']);
      formData.append('typified_fields', demarche['typified_fields']);
      formData.append('isAnonymous', isAnonymous);
      formData.append('indexData', demarche['indexData']);

      dataForm.files.forEach((element) => {
        formData.append(element.id, element.file);
      });

      return formData;

    } catch (error) {
      throw new Error(error);
    }
  }

  /**
  * Metodo que se encarga de crear cargar a los objetos los valores seleccionados segun el criterio
  * @author Juan David Guerrero Vargas
  * @param  objectToModify:any {any} Objeto a modificar Agregar/Eliminar propiedades
  * @param field:any {any} campo del cual se obtendran las opciones y sus nombres
  * @param config?:any {any}  variable  de configuracion { typificated:false, formAnswerIndexData:false } permite enviar parametros para modificar el compotamiento de creacion del objeto 
  * 
  */
  getObjectWithValues(objectToModify: any, field: any, config?: any): any {
    if (field.options.length > 0 && field.controlType == 'multiselect') {
      if (Array.isArray(field.value) && field.value.filter(filtered => (filtered && filtered != "")).length > 0) {
        let labels = ""
        field.value.forEach(valueSelect => {
          let optionFinded = field.options.filter(filterd => filterd.id == valueSelect)
          if (optionFinded) {
            labels += optionFinded[0].name + ","
          }
        });
        objectToModify[field.id] = labels
      }
    } else if (field.options.length > 0) {
      let optionSelected = field.options.filter(filterd => filterd.id == field.value)
      if (optionSelected && optionSelected.length > 0) {
        objectToModify[field.id] = optionSelected[0].name
      }
    }

    if (field.options.length <= 0) {
      if ((config && config.hasOwnProperty('typificated') == true && config.typificated == true)) {
        objectToModify[field.id] = field.value
      }

      if ((config && config.hasOwnProperty('formAnswerIndexData') == true && config.formAnswerIndexData == true) && field.controlType == 'file') {
        if (field['storage_path']) {
          objectToModify[field.id] = field['storage_path']
        }
      } else {
        objectToModify[field.id] = field.value
      }

      if (!config && field.value && field.value !== "") {
        objectToModify[field.id] = field.value
      }
    }
    return objectToModify;
  }

  /**
   * Metodo que se encarga de registrar los datos apra CRM 1
   * @param dataForm:any {any} objeto con la respuesta de sections y formulario
   * @param typeCreate:string {string} tipo de cracion
   * @returns FormData {FormData}
   */
  createFormDataToSaveFormCrm(dataForm: any, typeCreate: string): FormData {
    const form = new FormData();
    form.append('form_id', String(this.form.id));
    form.append('client_unique', JSON.stringify(this.form['client_unique']));
    if (this.escalation.is_anonimous === 1) {
      dataForm.sections.unshift(this.customerSection);
      form.append('sections', JSON.stringify(dataForm.sections));
    } else {
      form.append('sections', JSON.stringify(dataForm.sections));
    }

    return form;
  }
}