import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { PageEvent } from '@angular/material/paginator';
import { AdminServersService } from '../services/admin-servers.service';
import { CommandsService } from '../services/commands.service';

@Component({
  selector: 'app-admin-servers',
  templateUrl: './admin-servers.component.html',
  styleUrls: ['./admin-servers.component.sass']
})
export class AdminServersComponent implements OnInit {
  formAdminServers: FormGroup = new FormGroup({
    id: new FormControl('', []),
    name: new FormControl('', [Validators.required]),
    host: new FormControl('', [Validators.required]),
    database: new FormControl('', [Validators.required]),
    user: new FormControl('', [Validators.required]),
    pass: new FormControl('', [Validators.required]),
    type_conexion: new FormControl('', [Validators.required]),
    name_vista: new FormControl('', [Validators.required]),
    port: new FormControl('', [Validators.required]),
    id_type_shell: new FormControl('', [Validators.required])
  });
  typeManagmentServer: string = 'create';
  configColumnsTable: any[] = [
    { key: 'actions', name: '' },
    { key: 'name', name: 'Nombre' },
    { key: 'database', name: 'Base de datos' },
    { key: 'type_conexion', name: 'Tipo de conexión' },
    { key: 'name_vista', name: 'Nombre de la vista' },
  ];
  keysColumnsTable: string[] = [];
  dataServersTable: any[] = [];
  configPaginator: any = { length: 0, pageSize: 5, pageIndex: 0, previousPageIndex: 0 };
  filter: string = '';
  hidePassword: boolean = true;
  commands: any[] = [];

  constructor(
    private location: Location,
    private alertService: AlertsService,
    private adminServersService: AdminServersService,
    private commandsService: CommandsService) { }

  ngOnInit(): void {
    this.keysColumnsTable = this.configColumnsTable.map((item: any) => item.key);
    this.getServers();
    this.getCommands();
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-06-20
    * Metodo que trae todos los servidores registrados. 
  */
  getServers(): void {
    this.adminServersService.getServers(this.configPaginator, this.filter).subscribe(res => {
      this.dataServersTable = res.data.data;
      this.configPaginator.length = res.data.total;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-20
    * Metodo que trae la información de un servidor seleccionado en la tabla. 
    * @param server Servidor seleccionado desde la tabla.
  */
  viewServer(server: any): void {
    this.adminServersService.showServer(server.id).subscribe(res => {
      this.typeManagmentServer = 'update';
      this.formAdminServers.get('id').setValue(res.data.id);
      this.formAdminServers.get('name').setValue(res.data.name);
      this.formAdminServers.get('host').setValue(res.data.host);
      this.formAdminServers.get('database').setValue(res.data.database);
      this.formAdminServers.get('user').setValue(res.data.user);
      this.formAdminServers.get('pass').setValue(res.data.pass);
      this.formAdminServers.get('type_conexion').setValue(res.data.type_conexion);
      this.formAdminServers.get('name_vista').setValue(res.data.name_vista);
      this.formAdminServers.get('port').setValue(res.data.port);
      this.formAdminServers.get('id_type_shell').setValue(res.data.id_type_shell);
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-20
    * Metodo que guarda o actualiza un servidor. 
  */
  saveOrUpdateServe(): void {
    if (this.formAdminServers.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De continuar con la gestión?').then(isConfirm => {
        if (isConfirm.isConfirmed) {
          this.adminServersService.saveOrUpdateServer(this.formAdminServers.value, this.typeManagmentServer).subscribe(res => {
            this.alertService.alertWithAnyProperties('Correcto', res.data, 'success', 'Continuar').then(isContinue => {
              this.formAdminServers.reset();
              this.getServers();
              this.typeManagmentServer = 'create';
            });
          });
        }
      });
    } else {
      this.formAdminServers.markAllAsTouched();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-20
    * Metodo que elimina un servidor del sistema. 
    * @param server Servidor seleccionado desde la tabla.
  */
  deleteServer(server: any): void {
    this.alertService.alertWarning('¿Está seguro?', '¿De eliminar el servidor seleccionado?').then(isConfirm => {
      if (isConfirm.isConfirmed) {
        this.adminServersService.deleteServer(server.id).subscribe(res => {
          this.alertService.alertWithAnyProperties('Correcto', res.data, 'success', 'Continuar').then(isContinue => {
            this.getServers();
          });
        })
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-20
    * Metodo que redirecciona a la pagina anterior visitada. 
  */
  backToPreviousPage(): void {
    this.alertService.alertWarning('¿Estás seguro?', '¿En verdad deseas salir de la página?').then(confirm => {
      if (confirm.isConfirmed) this.location.back();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-20
    * Metodo que retorna los controles del formulario. 
  */
  get errorsForm(): any {
    return this.formAdminServers.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-20
    * Metodo que pagina la informacion de la tabla.
    * @param $event Evento emitido por el paginador.
  */
  changePage($event: PageEvent): void {
    this.configPaginator = $event;
    this.getServers();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-20
    * Metodo que filtra los datos de la tabla.
    * @param $event Evento emitido por el input.
  */
  onChangeFilter($event: any): void {
    this.filter = $event.target.value;
    this.getServers();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-20
    * Metodo que reinicia los valores de la vista. 
  */
  cancelManagmentServers(): void {
    this.alertService.alertWarning('¿Estás seguro?', '¿En verdad deseas cancelar la gestión?').then(confirm => {
      if (confirm.isConfirmed) {
        this.formAdminServers.reset();
        this.typeManagmentServer = 'create';
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-22
    * Metodo que trae los comandos registrados en el sistema. 
  */
  getCommands(): void {
    this.commandsService.getCommands(null, '', false).subscribe(res => {
      this.commands = res.data;
    });
  }
}