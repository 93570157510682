import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GroupsRoutingModule } from './groups-routing.module';
import { GroupsListComponent } from './pages/groups-list/groups-list.component';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { AdminGroupsComponent } from './pages/admin-groups/admin-groups.component'; 


@NgModule({
  declarations: [GroupsListComponent, AdminGroupsComponent],
  imports: [
    CommonModule,
    GroupsRoutingModule,
    MaterialModule
  ]
})
export class GroupsModule { }
