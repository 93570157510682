<!--Icono de X para cierre en caso de modal-->
<i 
*ngIf="seeBotonsModal == true"
class="fi-rr-cross-small" 
style="cursor: pointer; float: right; font-size: 30px; padding:10px;" 
mat-icon-button (click)="close()"></i>
<!--Icono de X para cierre en caso de modal-->
<!--Span con _ generador de evento de minimisacion-->
<span 
*ngIf="seeBotonsModal == true"
style="cursor: pointer; float: right; font-size: 30px; padding:10px 0px 0px 0px;" 
(click)="minimize()">_</span>
<!--Span con _ generador de evento de minimisacion-->

<!--Iframe que carga URL liempiada para ser embeida-->
<iframe *ngIf="urlIframe !== ''" id="iframe-rpa" [ngClass]="getClassIframe" [src]="urlSafe" frameborder="0" width="100%" height="100%">

</iframe>