import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';
import { CarteraWomRoutingModule } from './cartera-wom-routing.module';

import { CarteraWomComponent } from './cartera-wom.component';
import { ChooseCampaignComponent } from './modules/shared/dialogs/choose-campaign/choose-campaign.component';


@NgModule({
	declarations: [CarteraWomComponent, ChooseCampaignComponent],
	imports: [CommonModule, CarteraWomRoutingModule, SharedModule]
})
export class CarteraWomModule {}
