import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormControl, Validators, FormBuilder, FormGroup} from '@angular/forms';
import { UpdatesService } from '../../../services/updates.service';
import { saveAs as importedSaveAs } from "file-saver";
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-updates-main',
  templateUrl: './updates-main.component.html',
  styleUrls: ['./updates-main.component.sass']
})
export class UpdatesMainComponent implements OnInit {

  constructor(public _location: Location, private fb: FormBuilder,
    public UpdatesService:UpdatesService, public alertService:AlertsService) { }

  filterForm = new FormControl('',[Validators.required]);
  fileUpdaloaded = null
  ngOnInit(): void {
  }
  getUpdate(file){
    this.fileUpdaloaded= null
    this.UpdatesService.postUpdates(this.filterForm.value, file[0])
    .subscribe((res:any)=>{
      this.fileUpdaloaded = res
      this.alertService.alertSuccess('¡Cargado!', 'Su documento se ha cargado correctamente');
    })

  }
  downloadDocument(){

    this.UpdatesService.downloadFormat(this.filterForm.value).subscribe(res=>{
      importedSaveAs(res, `${this.filterForm.value}.xlsx`);
    })
  }
}
