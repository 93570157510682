<div class="h-100 overflowx-none">

  <div class="row h-100 no-gutters">
    <div class="d-none d-lg-block col-lg-7 bg-color">
      <div class="trama">
        <img class="margin-img" src="../../../assets/images/g10.svg" alt="">
        <br>
        <img class="margin-text" src="../../../assets/images/cambio.svg" alt="">
        <br>
        <img class="margin-sub-text" src="../../../assets/images/experi.svg" alt="">
      </div>
    </div>
    <div class="bg-white justify-content-center align-items-center col-md-12 col-lg-5">
      <div class="row minh-90">
        <div class="col-lg-1"></div>
        <div class="mx-auto col-lg-10">
          <div class="card">
            <div class="margin-lg">
                <img src="../../../assets/images/Icon-Soul.svg" alt="">
            </div>
              <br>
              <br>
              <div class="title">
                  <h1> ¡Bienvenido a SOUL!</h1>
                  <p>Que tengas un excelente día</p>
              </div>
              <br>
              <br>
              <div style="min-height: 80%;" fxLayout="column" fxLayoutAlign="space-between none">
                <form class="row form" [formGroup]="loginForm" (ngSubmit)="loginAuth(loginForm)" style="min-height: 60%;">

                    <mat-form-field class="col-12"  appearance="fill">
                        <mat-label>Nombre de usuario</mat-label>
                        <input appBlockCopyPaste (keypress)="omitSpecialChar($event)" matInput type="text" placeholder="Escribe tu nombre de usuario" formControlName="user"  required>
                        <mat-error *ngIf="error.user.errors"><span *ngIf="error.user.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                        <mat-error *ngIf="error.user.errors"><span *ngIf="error.user.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
                        <mat-error *ngIf="error.user.errors"><span *ngIf="error.user.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 50 caracteres</span></mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-12"  appearance="fill">
                        <mat-label>Contraseña</mat-label>
                        <input matInput appBlockCopyPaste (keydown.enter)="loginAuth(loginForm)" (keypress)="omitSpecialChar($event)" [type]="hide ? 'password' : 'text'" placeholder="Escribe tu contraseña" formControlName="password" required>
                        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.minlength">¡Aún falta! Recibimos mínimo 5 caracteres</span></mat-error>
                        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 15 caracteres</span></mat-error>
                        <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ?  'visibility' : 'visibility_off'}}</mat-icon>
                        </a>
                    </mat-form-field>
                    <br>
                    <br>
                    <div class="col-12 forgotPass" >
                      <a (click)="forgotUser()"><b class="link">¿Olvidaste tu usuario?</b></a>
                      <br>
                      <a (click)="forgotPassword()"><b class="link">¿Olvidaste tu contraseña?</b></a>

                    </div>
                    <br>
                    <br>
                    <div class="col-12 mt-3">
                        <button mat-raised-button type="submit" style="width: 100%;" color="primary" disabled='{{loginForm.invalid}}'>Ingresar</button>
                    </div>
                </form>

              <div class="color-purple" style="min-height: 20%;">
                <div fxLayout="row" fxLayoutAlign="center center" class="col footer mt-5">Develop by Montechelo &nbsp;<span style="transform: scale(1)" class="fi-sr-heart"></span> &nbsp; {{anio}}</div>
                <div fxLayout="row" fxLayoutAlign="center center" class="col footerv mt-1">Versión 4.0</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-1"></div>
      </div>
    </div>
  </div>
</div>
