import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-template-confirmation',
  templateUrl: './template-confirmation.component.html',
  styleUrls: ['./template-confirmation.component.sass']
})
export class TemplateConfirmationComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.data = this.data.data;
  }

}
