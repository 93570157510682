import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TemplatesService } from 'src/app/modules/crm_v2/services/rest/templates.service';
import { ClipboardService } from 'ngx-clipboard';
import { FormControl, FormBuilder } from '@angular/forms';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';


@Component({
  selector: 'app-generate-template',
  templateUrl: './generate-template.component.html',
  styleUrls: ['./generate-template.component.sass']
})

export class GenerateTemplateComponent implements OnInit {

  template: any;
  plantilla: any = [];
  writable: any = [];
  options: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private templateService: TemplatesService,
              private clipCopy: ClipboardService,
              private fb: FormBuilder,
              private alertsService: AlertsService) {
                this.options = this.fb.group({});
              }

  ngOnInit(): void {

    this.templateService.obtenerTemplate(this.data.form).subscribe(resp => {
      this.template = resp;
      this.plantilla = resp.plantilla;
      this.writable = JSON.parse(resp.fields_writable);
      this.writable.forEach(element => {
        this.options.addControl(element.label, new FormControl(''));
      });
    });

  }

  copiarTexto(){

    let csv = this.template.csv;
    let form = this.options.getRawValue();

    for (const key in form) {
      if (Object.prototype.hasOwnProperty.call(form, key)) {

        this.writable.forEach(element => {

          if(element.label === key){
            csv = csv+String.fromCharCode(element.registerDelimiter);
            if(element.haveTheLabel){
              csv = csv+key+':';
            }
            csv = csv+form[key]+String.fromCharCode(element.registerDelimiter)+String.fromCharCode(this.template.value_delimiter);
          }

        });

      }
    }

    let copyCsv = csv.substring(0, csv.length - 1);

    this.clipCopy.copy(copyCsv);

    this.alertsService.alertSuccess('Éxito', 'Su texto se ha copiado correctamente');

  }



}
