<h4 class="title-estado"><i class="fi-rr-settings"></i> Estado solicitud</h4>
<form [formGroup]="estadoForm">
    <mat-form-field appearance="fill" class="col-12">
        <mat-label>Estado</mat-label>
        <mat-select #estado formControlName="estado">
            <mat-option *ngFor="let estado of estados " [value]="estado.id">{{estado.nombre}}</mat-option>
        </mat-select>
        <mat-error>Por favor seleccione una opción.</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="col-12">
        <mat-label>Comentario asesor</mat-label>
        <textarea type="text" matInput formControlName="comentario_asesor"></textarea>
        <mat-error>Por favor ingrese un comentario.</mat-error>
    </mat-form-field>
    <div class="col-12 text-center"> 
     <button mat-stroked-button class="btn-cancelar" (click)="cancelar()" [disabled]="!estadoForm.enabled">Cancelar</button>
     <button mat-raised-button color="primary" class="btn-guardar" (click)="saveSeguimiento()" [disabled]="!estadoForm.enabled">Guardar</button>
    </div>
</form>