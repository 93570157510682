import { environment } from 'src/environments/environment';
import { IMqttServiceOptions } from "ngx-mqtt";

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
    hostname: environment.MQTT_CONFIG.SERVER,
    port: environment.MQTT_CONFIG.PORT,
    username:environment.MQTT_CONFIG.USERNAME,
    password: environment.MQTT_CONFIG.PASSWORD ,
    protocol:  "wss",
    path: '/mqtt',
    
};