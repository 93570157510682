import { SideBarComponent } from './../../../../../mios/side-bar/side-bar.component';
import { ScheduleService } from './../../../../../core/services/rest/schedule.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MassMigrationUsersComponent } from 'src/app/modules/ciu/modules/users/mass-migration-users/mass-migration-users.component';
import { AuthService } from './../../../../../core/services/rest/auth.service';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SaveUsersComponent } from '../save-users/save-users.component';
import { UsersService } from '../../../services/rest/users.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { EditUsersComponent } from '../edit-users/edit-users.component';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { UserStatusResponseComponent } from '../userStatus-response/userStatus-response.component';


@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.sass']
})
export class AdminUsersComponent implements OnInit {

  // MatPaginator Inputs
  length;
  pageSize = 5;
  page = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  show = false;
  filterValue;
  public reactiveForm: FormGroup;
  public queryParams : string = 'page=1&per_page=5';
  public validInit: boolean = true;




  displayedColumns: string[] = ['accion', 'name', 'id_number','userName', 'email', 'campaign', 'group', 'applications'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(public dialog: MatDialog,
              public userService: UsersService,
              private alertsService: AlertsService,
              private destroyService: DestroyComponentService,
              private location: Location,
              private authService : AuthService,
              private formBuilder: FormBuilder,
              private changeDetectorRef : ChangeDetectorRef,
              private scheduleService : ScheduleService,
              private sideBar: SideBarComponent
              ) {}

  ngOnInit(): void {
    this.defineReactiveForm();
    this.getUsers();
  }

  get userLog() { return this.authService.decryptToken(); }


  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que consulta todos los usuarios debidamente paginados para mostrarlos en la tabla
   * @param size tamaño de datos a traer
   * @param page pagina a traer
   */
  getUsers(): void {

    if (this.dataSource.filter === ''){
      this.dataSource.filter = null;
    }
    this.userService.getUsersFilter(this.queryParams).subscribe((Response) => {
      if (typeof Response.data.data !== 'undefined') {
        this.dataSource = new MatTableDataSource(Object.values(Response.data.data));
      }else{
        this.dataSource.data = [];
      }
      this.dataSource.sort = this.sort;
      this.length = Response.data.total;
      this.pageSize = Response.data.per_page;
    });

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que se encarga del paginador al momento de cambiar de pagina
   * @param event evento que trae la pagina a listar
   */
  pageEvent(event: any): void {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que abre el modal para guardar un usuario
   */
  saveUser(): void{

    const dialogRefSave = this.dialog.open(SaveUsersComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Crear usuario',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogRefSave.afterClosed().subscribe(() => {
      this.ngOnInit();
    });

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que abre el modal para editar un usuario
   * @param user datos del usuario a editar
   */
  editUser(user: any): void{

    const dialogRefEdit = this.dialog.open(EditUsersComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Editar usuario',
        user
      },
      panelClass: 'custom-dialog-container',
    });

    dialogRefEdit.afterClosed().subscribe(() => {
      this.ngOnInit();
    });

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que se encarga de la eliminacion de un usuario
   * @param user datos del usuario a eliminar
   */
  deleteUser(user: any): void{

    let value = this.alertsService.alertConfirm('¿Desea eliminar este usuario?');
    value.then((result) =>{
      if (result.isConfirmed) {
        this.userService.deleteUser(user.id).subscribe((resp) => {
          this.alertsService.alertSuccess('Eliminado', resp.data);
          this.ngOnInit();
        });
      }
    });

  }

  /**
   * @author Carlos Nieto
   * @createdate 2021-12-13
   * @param email envia el email al back para enviar el correo con la ruta de cambio de password
   * Metodo que envia el formulario al servicio
   */
   sendEmail(rrhh_id, email): void{

        Swal.fire({
          title: "¿Estás seguro?",
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sí, enviar",
        })
        .then(resultado => {
          const formData =  new FormData();
          formData.append( 'rrhh_id' , rrhh_id);
            if (resultado.value) {
              this.authService.validateEmail(formData).subscribe(
                response => {
                  if (response.data.response === 'OK') {
                     Swal.fire({
                      html: `Solicitud enviada al correo ${email}`,
                      icon: 'success',
                      title : 'Enviado',
                      confirmButtonText: 'Aceptar'
                    });
                  } else if(response.error_code == 401)
                  {
                    Swal.fire({
                      html: `${response.error}`,
                      icon: 'error',
                      title : 'Error',
                      confirmButtonText: 'Aceptar'
                    });
                  }
                }
              );
            }
        });
     
  }

   /**
   * @author Carlos Nieto
   * @createdate 2021-12-07
   * Metodo que regula las extenciones de los correos ingresados al formulario
   */
    verifyEmail(email) : boolean{
      let bool = true;

        if (/@hotmail/.test(email)) {
          bool = false;
        }
        else if (/@gmail/.test(email)) {
          bool = false;
        }
        else if (/@outlook/.test(email)) {
          bool = false;
        }
        else if (/@yahoo/.test(email)) {
          bool = false;
        }
        else if (/@icloud/.test(email)) {
          bool = false;
        }

        return bool;
    }

     /**
   * @author Daniel Dominguez
   * @createdate 2021-05-06
   * Metodo para mostrar o ocultar filtros avanzados
   */
  Hide() {
    if (this.show == false) {
      this.show = true;
    } else {
      this.show = false;
    }
  }

  /**
   * @author Carlos Nieto
   * @createdate 2021-12-14
   * Metodo que abre el modal para agregar permisos massivamente
   * @param user datos del usuario a editar
   */
   rolMigration(): void{

    const dialogRefEdit = this.dialog.open(MassMigrationUsersComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Migración',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogRefEdit.afterClosed().subscribe(() => {
      this.ngOnInit();
    });

  }


	/**
	   * Primero valida si existe en la variable el string filter,
		 * en cuyo caso obtiene 'filter={info digitada}', limpiando los demas datos,
		 * luego vuelve a evaluar si existe filter en cuyo caso lo concatena al string
		 * en caso de que no exista arma el string solo con con page y pagezise
	   * @author Carlos Nieto
	   * @param event
	   * @returns string
	   /**/
	public ValidateFilterParams(event) : string
	{
		this.queryParams = 	this.queryParams.includes("filter") ?
		this.queryParams.substring(this.queryParams.indexOf('filter')) : this.queryParams;
		this.queryParams = this.queryParams.includes("filter") ?
		`page=${event.pageIndex + 1}&per_page=${event.pageSize}&${this.queryParams}` :
		`page=${event.pageIndex + 1}&per_page=${event.pageSize}` ;

		return this.queryParams;
	}

  public handleChangePage(event)
	{
		this.queryParams = this.ValidateFilterParams(event);
		this.validInit = false;
    this.destroyService.destroyComponent();
		this.getUsers();
	}

  /* Filter Function */
	applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
		this.queryParams = `filter=${filterValue}`;
    this.destroyService.destroyComponent();
		this.getUsers();
    this.dataSource.filter = filterValue;
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-02-01
   * Metodo que se encarga de pasar todos los valores objetos a array
   * @param row dato a convertir
   */
  vaidateData(row)
  {
    return Object.values(row);
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-02-01
   * Metodo que se encarga de resetear la  jornada de un usuario
   * guardando su respectivo log
   * @param user datos de la columna usuario
   */
   scheduleReset(user: any): void{

    let value = this.alertsService.alertConfirm('¿Desea reiniciar la jornada este usuario?');
    value.then((result) =>{
      if (result.isConfirmed) {
        let data = {"responsible_id":this.userLog.idCiu,"affected_user":user.id};
        if(this.userLog.idCiu != user.id )
        {
          this.scheduleService.scheduleReset(data).subscribe((resp) => {
            this.alertsService.alertSuccess('Jornada reiniciada', resp.data);
            this.sideBar.getSchedule();
            this.ngOnInit();
          });
        }else{
          Swal.fire({
            html: `No puedes modificar tu propia jornada.`,
            icon: 'error',
            title : 'Error',
            confirmButtonText: 'Aceptar'
          })
        }

      }
    });
  }


  defineReactiveForm(): void {
    this.reactiveForm = this.formBuilder.group({
      'filter': ['']
    });
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

  backRoute(){
    this.location.back()
  }
  /**
   * @author Daniel Dominguez 
   * @createdate 2022-05-04
   * Metodo que se encarga de la modificacion de un usuario a listado o inactivo
   * @param user datos del modificar usuario 
   */
  modifyUser(row){
    const commentDialog = this.dialog.open( UserStatusResponseComponent, {
    });
    commentDialog.afterClosed().subscribe(result=>{
      if(!result)
        return
      let message = ""
      if(result.state === 36)
        message = `Usuario desactivado exitosamente`
      if(result.state === 3)
        message = `Usuario listado exitosamente`
      this.userService.blockUser(row.id, {...result}).subscribe((resp:any) => {
        if(resp.error){
          this.alertsService.alertError('Error',resp.error)
          return
        }
          
        this.alertsService.alertSuccess('Correcto', message);
        this.ngOnInit();
      }, error=>{
        console.error(error)
      });
    })
  }
   /**
   * @author Daniel Dominguez 
   * @createdate 2022-05-05
   * Metodo que se encarga de la modificacion de un usuario a listado o inactivo
   * @param user datos del modificar usuario 
   */
  activateUser(row){
    console.log(row)
    let blockUser = row?.hired_user?.state_id
    if(!blockUser || (blockUser && blockUser === 3)){
      this.alertsService.alertError('Usuario listado', 'Por favor comuniquese con area de recursos humanos')
      return
    }
      
    let str = '¿En verdad deseas habilitar el usuario?, recuerda esto' + '\n' + 'se podra modificar las veces que sea necesario ';
    Swal.fire({
      title: '¿Estás seguro?',
      html: '<pre style="font-family: Poppins; font-size: 14px;">' + str + '</pre>',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
            this.userService.restoreUser(row.id).subscribe((resp:any) => {
              if(resp.error){
                this.alertsService.alertError('Error',resp.error)
                return
              }
              this.alertsService.alertSuccess('Restaurado', resp.data);
              this.ngOnInit();
            }, error=>{
              console.error(error)
            })
      }
    });
  }
}
