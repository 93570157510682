<div class="admin-inteligencia">

    <div class="header">

      <h2 style="height: 33px !important;
      font: normal normal bold 45px/35px Poppins !important;
      letter-spacing: 0px !important;color: #353535 !important;opacity: 1 !important;">{{config.title}}</h2>
      <br>
      {{config.subtitle}}
    </div>

    <mat-tab-group [selectedIndex]="selected.value"
    (selectedIndexChange)="changeTab($event)">
      <mat-tab label="{{config.questions.tabName}}">
          <app-tabla-preguntas></app-tabla-preguntas>
      </mat-tab>
      <mat-tab label="{{config.bi.tabName}}">
            <app-tabla-inteligencia-negocio></app-tabla-inteligencia-negocio>
      </mat-tab>
    </mat-tab-group>
  </div>
