<div class="avatar row  admin-dialog">
  <div>
    <button style="float: right;margin-top: -15px;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
  </div>

  <form class="row form" [formGroup]="solicitudesForm" (ngSubmit)="saveApplication(solicitudesForm)">

    <mat-dialog-content class="mat-typography">

      <div class="row mb-0">
        <div class="col-7">
          <h3 class="mb-1">
            {{ data.title }}
          </h3>
          <span class="text-muted">{{usuario.rrhh.name}}</span>
        </div>
        <div class="col-5 d-flex justify-content-end mb-auto p-2 bd-highlight">
          <p (click)="clearForm()" class="link-clear-sol">
            Limpiar solicitud <i class="fi fi-rr-broom"></i>
          </p>
        </div>
      </div>
      <hr class="mt-1" style="max-width: 100%;">

      <div class="avatar row" *ngIf="verSolicitud">

        <div class="col-1">
          <button mat-icon-button class="badge " type="button"
            [style.backgroundColor]="data.solicitud.request_state.color">
            <i [class]=" data.solicitud.request_state.icon"> </i>
          </button>
        </div>
        <div class="col-4">

          <mat-label style="font-weight: bold;">Estado Actual</mat-label>
          <p>{{data.solicitud.request_state.name}}</p>
        </div>
        <br>
        <br>
        <hr style="max-width: 100%;">

      </div>


      <div class="row">
        <mat-form-field class="col-4" appearance="fill">
          <mat-label>Tipo de adquisición</mat-label>
          <input type="text" matInput formControlName="tipoProductos" [matAutocomplete]="autoProd" required>
          <mat-autocomplete #autoProd="matAutocomplete" [displayWith]='displayFnTipoProducto.bind(this)'
            (optionSelected)="getCategories($event.option.value)" required cdkFocusInitial>
            <mat-option *ngFor="let prod of filteredOptionsTipoProducto | async" [value]="prod.id">
              {{prod.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="error.tipoProductos.errors"><span *ngIf="error.tipoProductos.errors.required">¡Advertencia!
              Este campo es requerido</span></mat-error>
          <mat-error *ngIf="error.tipoProductos.errors"><span
              *ngIf="error.tipoProductos.errors.invalidAutocompleteObject">Campaña no reconocida. Haga click en una de
              las opciones de autocompletar</span></mat-error>
        </mat-form-field>

        <mat-form-field class="col-4" appearance="fill">
          <mat-label>Categoría</mat-label>
          <input type="text" matInput formControlName="categorias" [matAutocomplete]="autoCat" required>
          <mat-autocomplete #autoCat="matAutocomplete" [displayWith]='displayFnCategoria.bind(this)' required>
            <mat-option *ngFor="let cat of filteredOptionsCategoria" [value]="cat.id">
              {{cat.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="error.categorias.errors"><span *ngIf="error.categorias.errors.required">¡Advertencia! Este
              campo es requerido</span></mat-error>
          <mat-error *ngIf="error.categorias.errors"><span
              *ngIf="error.categorias.errors.invalidAutocompleteObject">Campaña no reconocida. Haga click en una de las
              opciones de autocompletar</span></mat-error>
        </mat-form-field>

        <mat-form-field class="col-4" appearance="fill">
          <mat-label>Empresa</mat-label>
          <input type="text" matInput formControlName="companias" [matAutocomplete]="autoCom" required>
          <mat-autocomplete #autoCom="matAutocomplete" [displayWith]='displayFnCompania.bind(this)' required>
            <mat-option *ngFor="let comp of filteredOptionsCompania | async" [value]="comp.id">
              {{comp.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="error.companias.errors"><span *ngIf="error.companias.errors.required">¡Advertencia! Este
              campo es requerido</span></mat-error>
          <mat-error *ngIf="error.companias.errors"><span
              *ngIf="error.companias.errors.invalidAutocompleteObject">Campaña no reconocida. Haga click en una de las
              opciones de autocompletar</span></mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-4" appearance="fill">
          <mat-label>Nombre de campaña</mat-label>
          <input style="text-transform: none;" matInput type="text" placeholder="Escribe el nombre de la campaña"
            formControlName="proyecto" max="100" required>
          <mat-error *ngIf="error.proyecto.errors"><span *ngIf="error.proyecto.errors.required">¡Advertencia! Este campo
              es requerido</span></mat-error>
          <mat-error *ngIf="error.proyecto.errors"><span *ngIf="error.proyecto.errors.minlength">¡Aún falta! Recibimos
              mínimo 3 caracteres</span></mat-error>
          <mat-error *ngIf="error.proyecto.errors"><span *ngIf="error.proyecto.errors.maxlength">¡Cuidado! Solo puedes
              tener un máximo de 100 caracteres</span></mat-error>
        </mat-form-field>



        <mat-form-field class="col-8" appearance="fill">
          <mat-label>Título solicitud</mat-label>
          <input matInput type="text" placeholder="Escribe el título de la solicitud" formControlName="titulo"
            maxlength="255" required>
          <mat-error *ngIf="error.titulo.errors"><span *ngIf="error.titulo.errors.required">¡Advertencia! Este campo es
              requerido</span></mat-error>
          <mat-error *ngIf="error.titulo.errors"><span *ngIf="error.titulo.errors.minlength">¡Aún falta! Recibimos
              mínimo 3 caracteres</span></mat-error>
          <mat-error *ngIf="error.titulo.errors"><span *ngIf="error.titulo.errors.maxlength">¡Cuidado! Solo puedes tener
              un máximo de 255 caracteres</span></mat-error>
        </mat-form-field>
      </div>
      <div class="row">

        <mat-form-field class="col-12" appearance="fill">
          <mat-label>Detalle</mat-label>
          <input matInput type="text" placeholder="Escribe el detalle de la solicitud" formControlName="descripcion"
            maxlength="255" required>
          <mat-error *ngIf="error.descripcion.errors"><span *ngIf="error.descripcion.errors.required">¡Advertencia! Este
              campo es requerido</span></mat-error>
          <mat-error *ngIf="error.descripcion.errors"><span *ngIf="error.descripcion.errors.minlength">¡Aún falta!
              Recibimos mínimo 3 caracteres</span></mat-error>
          <mat-error *ngIf="error.descripcion.errors"><span *ngIf="error.descripcion.errors.maxlength">¡Cuidado! Solo
              puedes tener un máximo de 255 caracteres</span></mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="col-4" appearance="fill">
          <mat-label>Fecha de ejecución</mat-label>
          <input matInput type="date" formControlName="fechaEjecucion">
        </mat-form-field>
        <mat-form-field class="col-8" appearance="fill">
            <mat-label>Observación de ejecución</mat-label>
            <input matInput type="text" formControlName="observacionEjecucion" placeholder="Escriba el detalle de la ejecución">
            <mat-error *ngIf="error.observacionEjecucion.errors"><span *ngIf="error.observacionEjecucion.errors.minlength">¡Aún falta!
              Recibimos mínimo 30 caracteres</span></mat-error>
          <mat-error *ngIf="error.observacionEjecucion.errors"><span *ngIf="error.observacionEjecucion.errors.maxlength">¡Cuidado! Solo
              puedes tener un máximo de 300 caracteres</span></mat-error>
        </mat-form-field>
        <div class="mb-4">
          Nota: Esta información será evaluada por el área de compras, si el criterio no cumple con lo establecido por prioridad, no se tomará en cuenta la fecha indicada.
        </div>
      </div>

      <div formArrayName="items" class="row col-12">
        <p style="font-weight: bold;">
          Por los siguientes ítems

        </p>

        <div class="col-12" *ngFor="let applet of items.controls; let i = index">

          <div class="row col-12" [formGroupName]="i">

            <mat-form-field class="col-8" appearance="fill">
              <mat-label>Descripción</mat-label>
              <input style="text-transform: none;" matInput type="text" placeholder="Escribe la descripción del ítem"
                formControlName="detalle" maxlength="255" required>
              <mat-error *ngIf="error.items.controls[i].controls.detalle.errors"><span
                  *ngIf="error.items.controls[i].controls.detalle.errors.required">¡Advertencia! Este campo es
                  requerido</span></mat-error>
              <mat-error *ngIf="error.items.controls[i].controls.detalle.errors"><span
                  *ngIf="error.items.controls[i].controls.detalle.errors.minlength">¡Aún falta! Recibimos mínimo 3
                  caracteres</span></mat-error>
              <mat-error *ngIf="error.items.controls[i].controls.detalle.errors"><span
                  *ngIf="error.items.controls[i].controls.detalle.errors.maxlength">¡Cuidado! Solo puedes tener un
                  máximo de 255 caracteres</span></mat-error>
            </mat-form-field>

            <mat-form-field class="col-2" appearance="fill">
              <mat-label>Cantidad</mat-label>
              <input style="text-transform: none;" matInput type="number" min="0"
                placeholder="Escribe la cantidad de ítems" formControlName="cantidad" max="9999" required>
              <mat-error *ngIf="error.items.controls[i].controls.cantidad.errors"><span
                  *ngIf="error.items.controls[i].controls.cantidad.errors.required">¡Advertencia! Este campo es
                  requerido</span></mat-error>
              <mat-error *ngIf="error.items.controls[i].controls.cantidad.errors"><span
                  *ngIf="error.items.controls[i].controls.cantidad.errors.minlength">¡Aún falta! Recibimos mínimo 3
                  caracteres</span></mat-error>
              <mat-error *ngIf="error.items.controls[i].controls.cantidad.errors"><span
                  *ngIf="error.items.controls[i].controls.cantidad.errors.maxlength">¡Cuidado! Solo puedes tener un
                  máximo de 5 caracteres</span></mat-error>
            </mat-form-field>
            <div class="col-2" *ngIf="!verSolicitud">
              <!-- <input  type="file" class="file-input" (change)="handleFileInput($event)" #fileUpload>
                  <button mat-icon-button (click)="fileUpload.click()" style="color:#000;font-size: 20px;"><i class="fi-rr-picture" style="position:absolute"></i></button> -->
              <button mat-icon-button (click)="deleteItems(i)" style="color:#BE0F0F;font-size: 20px;"
                *ngIf="this.items.length >1"><i class="fi-rr-trash" style="position:absolute"></i></button>
                <button mat-icon-button (click)="newItems()" type="button" class="col-4" color="#353535"
                style="border-color: #353535; font-size: 20px;" *ngIf="!verSolicitud"><i class="fi-rr-add"
                  style="position:absolute;"></i></button>
              <button mat-icon-button style="font-size: 20px;" (click)="inputFile.click()" type="button">
                <i class="fi-rr-picture" style="position:absolute"></i>
              </button>
              <input #inputFile type="file" accept=".png,.pdf" name="inputFile" style="display: none"
                (change)="addFile($event, i)" />
            </div>
            <div *ngIf="this.items.controls[i].get('itemsFiles').value && this.items.controls[i].get('itemsFiles').value.length > 0">
              <span class="fw-bold">{{this.items.controls[i].get('itemsFiles').value[0].name}}</span> <button mat-icon-button (click)="deleteFile(i)" type="button" style="color:#BE0F0F;font-size: 20px;"><i class="fi-rr-trash"></i></button>
            </div>
            <!-- <div class="row" *ngFor="let item of filesUpload; let j = index;">
                  <div style="vertical-align: bottom;" class="col-3 ">
                    <p>{{item.name}}</p>
                  </div>
                  <div class="col-3 ">
                    <button  mat-icon-button class="action " (click)="EliminarArchivoLista(j) ">
                      <i class="fi-rr-trash" style="position:initial"></i>
                    </button>
                  </div>
                </div> -->
          </div>



          <br />
          <br />
        </div>
      </div>
      <div style="float: right;" *ngIf="!verSolicitud">
        <button class="bold compras-modal-button" mat-stroked-button mat-dialog-close>Cancelar</button>
        <button mat-raised-button type="submit" style="margin-left: 10px;" color="primary"
          disabled='{{solicitudesForm.invalid}}' [mat-dialog-close]="true"
          class="bold compras-modal-button">Guardar</button>
      </div>



    </mat-dialog-content>


  </form>
</div>
