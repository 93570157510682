import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TrainingGroupsService } from '../../../services/training-groups.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { environment } from '../../../../../../environments/environment';
import { CandidateCommentsComponent } from '../../candidates/candidate-comments/candidate-comments.component';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { retreatGroup, commentsGroup, statusGroup } from './commentsTypes';
@Component({
  selector: 'app-training-candidate',
  templateUrl: './training-candidate.component.html',
  styleUrls: ['./training-candidate.component.sass']
})
export class TrainingCandidateComponent implements OnInit {

  createComment = false;
  createEvaluation = false;
  types_retreat=retreatGroup
  formComment:FormGroup;
  formEvaluation:FormGroup
  comments:any[] = [];
  evaluations:any[] = [];
  attachments:any = [];
  uploadedFile = false;
  attachmentsName:any;
  attachmentStore
  comentPermit= false
  types_comments = commentsGroup;
  status_comments=statusGroup;
  states:boolean= false
  //Estado del candidato
  candidate_state:string;
  constructor(private fb:FormBuilder,
              private trainingGroup:TrainingGroupsService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private alertNotification:AlertsService,
              private auth:AuthService,
              private dialog:MatDialog) {
                this.createCommentForm();
              }


  ngOnInit(): void {
    this.auth.getUser().roles.forEach(element => {
      if(element == 'rrhh::usuario'){
        this.states = true
      }
    });
    if(this.data.candidate.states.name =='Transferido'){
      this.comentPermit =true
    }
    this.createEvaluationForm();
    this.candidateComments();
  }

  createCommentForm(){
    this.formComment = this.fb.group({
      'typeComment':[null],
      'comment':[null,Validators.required],
      'createdAt':[null],
    })
  }
  createEvaluationForm(){
    this.formEvaluation = this.fb.group({
      'typeEvaluation':[null],
      'evaluation':[null,Validators.required],
      'createdAt':[null]
    })
  }

  saveComment(){
    this.formComment.get('createdAt').setValue(new Date().toISOString())
      if(this.formComment.valid){
        this.comments.push(this.formComment.value)
      let params = {
        'comments':JSON.stringify(this.comments),
        'groupId':this.data.groupId,
        'candidateId':this.data.candidate.id
      }


      this.trainingGroup.createCommentOrEvaluations(params).subscribe( (e:any) => {
            this.alertNotification.alertSuccess('Comentario creado con éxito','');

            this.cancelForm('formComment','createComment');
             this.candidateComments();
      })
      }
  }
  saveEvalution(){

    this.formEvaluation.get('createdAt').setValue(new Date().toISOString())
    if(this.formEvaluation.valid){
      this.evaluations.push({...this.formEvaluation.value,'attachmentName':this.attachmentsName,
        'attachment':this.attachmentStore})
      let params = {
        'evaluations':JSON.stringify(this.evaluations),
        'groupId':this.data.groupId,
        'candidateId':this.data.candidate.id
      }

        this.trainingGroup.createCommentOrEvaluations(params).subscribe( (e:any) => {
              this.alertNotification.alertSuccess('¡Hecho!','Evaluacién creada con éxito');
              this.attachmentsName = null;
              this.attachmentStore = null;
              this.cancelForm('formEvaluation','createEvaluation');
        })
    }

  }
  cancelForm(form,arg){
    this[form].reset();
    this[arg] = !this[arg]
  }

  candidateComments(){
    this.trainingGroup.candidateComments({'candidateId':this.data.candidate.id,'groupId':this.data.groupId}).subscribe((e:any) => {
      if(e.comments != null){
        this.comments = e.comments;
        this.comments.reverse()
      }
      this.candidate_state = e.state_id;
      this.evaluations = e.evaluationsJson ? e.evaluationsJson : [];
    })
  }

  onFileSelected(files) {
    this.attachmentsName = files[0].name;
    this.attachments = files[0];
    this.uploadedFile = true;
    this.trainingGroup.uploadAttachments(this.attachments,this.data.candidate.id).subscribe((e:any) => {
      this.attachmentStore = e;

    })
  }

  downloadFile(file){
    // this.testService.downloadFile(file.id, 'attach').subscribe((resp:any) => {
    //   importedSaveAs(resp, `${file.resources.candidateAttachmentName}`);
    // })
    // const rrhh = environment.RRHH_URL;
    // const fileLink = file.substr(7);
    // const rrhhPublic = rrhh.substr(0, rrhh.length - 4);
    // window.open(rrhhPublic + 'storage/' + fileLink);
  }

  openComments(){
    const candidateComments = this.dialog.open ( CandidateCommentsComponent, {
      minWidth: '80%',
      disableClose: true,
      data: { candidateId: this.data.candidate.id, requestId: this.data.candidate.requestId }
    });
    candidateComments.afterClosed().subscribe( (params) => {
    });
  }

}
