import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProfileService } from '../../services/rest/profile.service';
import { AuthService } from '../../../../core/services/rest/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { CandidatesService } from '../../../rrhh/services/candidates.service';
import { AlertsService } from '../../../../shared/alerts/alerts.service';
import { ProfileUpdateComponent } from '../profile-update/profile-update.component';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { UpdateSecurityQuestionsComponent } from '../update-security-questions/update-security-questions.component';
import { ChangeNetworkUserPassComponent } from '../change-network-user-pass/change-network-user-pass.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.sass']
})
export class ProfileComponent implements OnInit {

  profileForm: FormGroup;

  userLogged;

  patch;


  constructor(
    private fb: FormBuilder, 
    private profileService: ProfileService, 
    private auth: AuthService,
    private dialog: MatDialog, 
    private candidateServices: CandidatesService, 
    private alertService: AlertsService,
    private datePipe: DatePipe,
    private router: Router) {
    this.getData();
    this.createForm();
  }

  ngOnInit(): void {
    this.profileService.dataUser(this.userLogged.rrhh_id).subscribe((resp: any) => {
      resp.id_type_id = resp.id_type.name;
      this.patch = resp;
      resp.birth_date = this.datePipe.transform(resp.birth_date, 'dd/MM/yyyy');
      this.profileForm.patchValue(resp);
      this.profileForm.disable();
    })
  }

  getData() {
    this.userLogged = this.auth.getUser();
  }


  createForm() {
    this.profileForm = this.fb.group({
      'idCiu': [this.userLogged.idCiu],
      'id_type_id': [],
      'state_id': [],
      'civil_state_id': [],
      'first_name': [],
      'middle_name': [],
      'last_name': [],
      'second_last_name': [],
      'email': ['', [Validators.required, Validators.email]],
      'email2': ['', [Validators.email]],
      'phone': ['', [Validators.maxLength(12), Validators.minLength(7), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      'mobile_phone': ['', [Validators.required, Validators.maxLength(13), Validators.minLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      'id_number': [],
      'birth_date': [],
    })

  }

  changePassword() {
    const passDialog = this.dialog.open(ChangePasswordComponent, {
      data: {
        userId: this.userLogged.idCiu,
      },
      width: '500px'
    })
  }

  /**
    * se encarga de abir el modal y de llamar las funciones
    * cuando se cierra
    * @author Carlos Nieto
  */
  ProfileUpdate() {
    const profileUpdateComponent = this.dialog.open(ProfileUpdateComponent, {
      data: {
        userId: this.userLogged.idCiu,
        profileForm: this.profileForm,
        patch: this.patch
      },
      width: '800px'
    });
    profileUpdateComponent.afterClosed().subscribe(() => {
      this.profileForm.controls.email.disable();
      this.profileForm.controls.email2.disable();
      this.profileForm.controls.mobile_phone.disable();
      this.profileForm.controls.phone.disable();
      this.getData;
      this.ngOnInit();
    });
  }

  redirectToFormPqrs(): void {
    this.router.navigate(['/mios/crm/forms/formView/' + environment.ID_FORM_PQRS]);
  }

  redirectToQuestionSanity(): void {
    this.router.navigate(['/mios/crm/forms/formView/' + environment.ID_FORM_SERVICEQUESTION]);
  }

  /**
  * Método que despliega el formulario para actualizar las preguntas de seguridad
  * @author Juan Carlos Alonso Perez
  * @createdate 27-05-2024
  */
  updateQuestionsDialog() {
    const dialogSecurityQuestions = this.dialog.open(UpdateSecurityQuestionsComponent, {
      width: '60%',
      height: 'auto',
      data: {
        type: 'cambiar',
        title: 'Actualizar preguntas de seguridad',
      },
      panelClass: 'custom-dialog-container'
    });

    dialogSecurityQuestions.afterClosed().subscribe(() => { });
  }
  /**
    * Método que despliega el formulario para cambiar la contraseña de usuario de red
    * @author Juan Carlos Alonso Perez
    * @createdate 27-05-2024
  */
  changeNetworkUserPasswordDialog() {
    const dialogSecurityQuestions = this.dialog.open(ChangeNetworkUserPassComponent, {
      width: '60%',
      height: 'auto',
      data: {
        type: 'cambiar',
        title: 'Cambiar contraseña usuario de red',
      },
      panelClass: 'custom-dialog-container'
    });

    dialogSecurityQuestions.afterClosed().subscribe(() => { });
  }
  /**
    * Método que genera un nuevo codigo de acceso para ingresar a la PWA.
    * @author Fabian Duran
    * @createdate 30-09-2024
  */
  onClickGeneratePwaAccessCode(): void {
    this.alertService.alertWarning('¿Está seguro?', '¿De generar un código de acesso para la PWA?').then(confirm => {
      if (confirm.isConfirmed) {
        this.profileService.generatePwaAccessCode().subscribe(res => {
          this.alertService.alertSuccess('¡Excelente!', `${res.message}. <br /> <b>Código: ${res.code}`, { timer: null });
        });
      }
    }); 
  }
}
