<div class="row">
  <!-- Lista de items disponibles -->
  <div class="col-5">
    <div class="row">
      <div class="col-12 d-flex justify-content-between">
        <label class="font-weight-bold">{{ first_list_title }}</label>
        <mat-checkbox [(ngModel)]="checked_all_available" (change)="selectAllAvailable()">Todos</mat-checkbox>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <input placeholder="Buscar" (input)="filterAvailableElements($event.target.value)"  matInput/>
          <mat-icon matSuffix><i class="fi-rr-search"></i></mat-icon>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-selection-list #selection_list [multiple]="true" class="selection-list">
          <mat-list-option *ngFor="let element of available_elements" [value]="element" [hidden]="element.isHidden">
            {{element.name}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <label class="font-weight-bold">{{ second_list_title }}: </label> {{selected_elements.length}}
      </div>
    </div>
  </div>
  <!-- Botones para mover elementos de las listas -->
  <div class="col-2 d-flex align-items-center mt-5">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <button mat-raised-button color="primary" (click)="moveToSelected()"><i class="fi-rr-arrow-right"></i></button>
      </div>
      <div class="col-12 d-flex justify-content-center">
        <button mat-raised-button color="primary" class="mt-3" (click)="moveToAvailable()"><i class="fi-rr-arrow-left"></i></button>
      </div>
    </div>
  </div>
  <!-- Lista de items seleccionados -->
  <div class="col-5">
    <div class="row">
      <div class="col-12 d-flex justify-content-between">
        <label class="font-weight-bold">{{ second_list_title }}</label>
        <mat-checkbox [(ngModel)]="checked_all_selected" (change)="selectAllSelected()">Todos</mat-checkbox>
      </div>
    </div>



    <div class="row mt-2">
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <input placeholder="Buscar" (input)="filterSelectedElements($event.target.value)" matInput />
          <mat-icon matSuffix><i class="fi-rr-search"></i></mat-icon>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <mat-selection-list #selected_list [multiple]="true" class="selection-list">
          <mat-list-option *ngFor="let element of selected_elements" [value]="element" [hidden]="element.isHidden">
            {{element.name}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
  </div>
  </div>
