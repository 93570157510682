import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ControlService } from 'src/app/modules/calidad/services/control.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import Swal from 'sweetalert2';
import { DialogGrupoComponent } from '../dialog-grupo/dialog-grupo.component';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
  selector: 'app-tabla-control',
  templateUrl: './tabla-control.component.html',
  styleUrls: ['./tabla-control.component.sass']
})
export class TablaControlComponent implements OnInit,OnDestroy {
  private uuid = 'ee7b4cf1-92ef-11ee-b9d1-0242ac120002';
  public config: any;

  @Input() campania;
  @Input() idMatriz;
  @Input() matriz;
  dataSource = [];
  displayedColumns:string[] = ['acciones','fecha_creacion','nombre_grupo','campania','supervisor'];
  filtroGrupo:string = '';

  length;
  pageSize = 5;
  page = 1;
  pageSizeOptions = [5,10,25,50,100];
  @ViewChild(MatPaginator) paginator:MatPaginator;

  //Observable para los cambios de la tabla
  subscription:Subscription;

  constructor(
    private dialog:MatDialog,
    private router:Router,
    private controlService:ControlService,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
    this.getGrupos(this.page,this.pageSize);
    this.subscription = this.controlService.refresh$.subscribe(()=>{
      this.getGrupos(this.page,this.pageSize);
    });
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  pageEvent(event:any){
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getGrupos(this.page,this.pageSize);
  }

  filtro(){
    this.paginator.firstPage();
    this.getGrupos(this.page,this.pageSize);
  }

  dialogControl(type,id_grupo?){
    const controlRef = this.dialog.open(DialogGrupoComponent,{
      width:'840px',
      disableClose: true,
      data:{
        type:type,
        id_grupo,
        campania: this.campania,
        idMatriz: this.idMatriz,
        matriz: this.matriz,
      },
    });
  }

  detailGrupo(idGrupo){
    this.router.navigateByUrl(`/mios/calidad/grupo/${idGrupo}`);
  }

  getGrupos(page,pageSize){
    const params = {
      nombre: '',
      numeroReg: pageSize,
      page,
      idMatriz: this.idMatriz,
    }
    if (this.filtroGrupo != '') {
      params.nombre = this.filtroGrupo;
    }

    this.controlService.getGroups(params).subscribe((resp:any)=>{
      this.dataSource = resp.data;
      this.length = resp.total;
    });
  }

  deleteGrupoById(id_grupo){
    Swal.fire({
      icon:'warning',
      title: this.config.modal.title,
      html: this.config.modal.html,
      showCancelButton: true,
      cancelButtonText: this.config.modal.cancel,
      confirmButtonText: this.config.modal.accept,
      reverseButtons: true,
      width: '810px',
      padding: '2em 7em',
    }).then((result)=>{
      if (result.isConfirmed) {
        this.controlService.deleteGrupoById(id_grupo).subscribe((resp:any)=>{
          if (resp.respuesta.codigo === 202) {
            Tools.swalSuccess(this.config.modal.excellent, this.config.modal.deleteOk);
          }else {
            Swal.fire({
              icon: 'error',
              title: '¡Oops!',
              text: this.config.modal.deleteError,
              showConfirmButton: false,
              timer: 2500
            })
          }
        });
      }
    });
  }

}
