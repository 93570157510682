import { state } from '@angular/animations';
import { VacationsService } from './../../../../services/rest/vacations.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import * as moment from 'moment';
import { delay } from 'rxjs/operators';
import { NoveltiesService } from 'src/app/modules/nomina/services/novelties.service';

@Component({
  selector: 'app-vacations-response',
  templateUrl: './vacations-response.component.html',
  styleUrls: [ './vacations-response.component.sass' ]
})
export class VacationsResponseComponent implements OnInit {

  dataInfo: any;
  public realTime: string;
  public scheduleTime: string;
  public endTime: string;
  public noveltyDetailHeight: number;

  public showJustifyForm: boolean;
  public showJustifyInfo: boolean;
  public showInput: boolean;
  vacationsForm: FormGroup;

  options: any = [
    { id: 0, name: 'Rechazar' },
    { id: 1, name: 'Aprobar' }
  ];
  availableDays = 0;
  businessDays = 0;
  public validDates: boolean;

  constructor(
    public dialogRef: MatDialogRef<VacationsResponseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alert: AlertsService,
    public dialog: MatDialog,
    private vacation: VacationsService,
    private noveltiesService: NoveltiesService,
  ) {
  }

  ngOnInit() {    
    this.vacationsForm = new FormGroup({
      state: new FormControl('', [ Validators.required ]),
      response: new FormControl('', [ Validators.required ])
    });

    this.vacation.getInfoVacationId(this.data.data.idHoliday).subscribe(resp => {
      this.dataInfo = resp.data;      
      this.dataInfo.novelty_type.key = (this.dataInfo.novelty_type.key < 10) ? `0${ this.dataInfo.novelty_type.key }` : this.dataInfo.novelty_type.key;
      this.showJustifyInfo = true;
      this.changeDetailHeight(this.dataInfo);
      this.getCalendarWithAvailableDays();
    });

  }

  /**
   * @author Javier Castañeda
   * @createdate 2021-04-27
   * Metodo para asignacion de detalle en primera instancia
   */
  changeDetailHeight(novelty): void {
    if (novelty.first_response && (novelty.first_response.justify || novelty.first_response.response)) {
      this.noveltyDetailHeight = 240;
    }
  }


  /**
   * @author Javier Castañeda
   * @createdate 2021-04-29
   * Metodo Aprobar o rechazar novedad
   */
  UpdateState() {
    const state = this.vacationsForm.controls['state'].value;

    const body = {
      state: this.vacationsForm.controls['state'].value,
      response: this.vacationsForm.controls['response'].value
    };
    
    if(this.data.data.noveltyStateKey == 'cancelacion-vacaciones'){
      this.vacation.changeStatusCancelVacation(this.data.data.idHoliday, body).subscribe(resp => {
        if (state == 1) {
          this.alert.alertSuccess('Novedad actualizada!', 'La novedad ha sido aprobada');
          this.dialogRef.close('');
        } else {
          this.alert.alertSuccess('Novedad actualizada!', 'La novedad ha sido rechazada');
          this.dialogRef.close('');
        }
  
      }, error => {
        this.alert.alertError('Error!', 'Ocurrio un error al intentar actualizar la novedad');
      });
    } else if(this.data.vacationType == 'extemporal'){
      this.vacation.changeExtemporalStatus(this.data.data.idHoliday, body).subscribe(resp => {
        if (state == 1) {
          this.alert.alertSuccess('Novedad actualizada!', 'La novedad ha sido aprobada');
          this.dialogRef.close('');
        } else {
          this.alert.alertSuccess('Novedad actualizada!', 'La novedad ha sido rechazada');
          this.dialogRef.close('');
        }
  
      }, error => {
        this.alert.alertError('Error!', 'Ocurrio un error al intentar actualizar la novedad');
      });
    }else {
      this.vacation.changeStatus(this.data.data.idHoliday, body).subscribe(resp => {
        if (state == 1) {
          this.alert.alertSuccess('Novedad actualizada!', 'La novedad ha sido aprobada');
          this.dialogRef.close('');
        } else {
          this.alert.alertSuccess('Novedad actualizada!', 'La novedad ha sido rechazada');
          this.dialogRef.close('');
        }
  
      }, error => {
        this.alert.alertError('Error!', 'Ocurrio un error al intentar actualizar la novedad');
      });
    }

  }
    /**
   * @author Javier Castañeda
   * @createdate 2021-05-11
   * Metodo para obtener los dias disponibles y los solicitados
   */
  getCalendarWithAvailableDays(): void {
    let startDate = this.dataInfo?.start_date;
    let endDate = this.dataInfo?.end_date;
    this.noveltiesService.getAvailableDaysForVacationsByUser({ startDate: startDate, endDate: endDate, rrhh_id: this.dataInfo?.rrhh_id}).pipe( delay(1000)).subscribe((respDates) => {      
      this.validDates = respDates.data.validDates;

      if(respDates.data.totalDaysVacations > 0){
        this.availableDays = respDates.data.totalDaysVacations;
      } else {
        this.availableDays = respDates.data.availableDays < 0 ? 0 : respDates.data.availableDays;
      }

      this.businessDays = respDates.data.businessDays;
    });
  
  }

}
