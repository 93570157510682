import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProfileService } from '../../services/rest/profile.service';
import { MatDialog, MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { AlertsService } from '../../../../shared/alerts/alerts.service';

@Component({
  selector: 'app-profile-update',
  templateUrl: './profile-update.component.html',
  styleUrls: ['./profile-update.component.sass']
})
export class ProfileUpdateComponent {
  profileForm:FormGroup;
  userLogged;

  constructor(private profileService:ProfileService,
              private dialog:MatDialog,private alertService:AlertsService,
              public dialogRef: MatDialogRef<ProfileUpdateComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.createForm();
  }

  get error(): any { return this.profileForm.controls; }

  /**
     * toma el formuliario y habilta los campos 
     * que se van a actualizar
     * @author Carlos Nieto
     */
  createForm(){
    this.profileForm = this.data.profileForm;
    this.profileForm.patchValue(this.data.patch);
    this.profileForm.enable()
    this.profileForm.controls.birth_date.disable();
    this.profileForm.controls.first_name.disable();
    this.profileForm.controls.id_number.disable();
    this.profileForm.controls.id_type_id.disable();
    this.profileForm.controls.last_name.disable();
    this.profileForm.controls.middle_name.disable();
    this.profileForm.controls.second_last_name.disable();
  }


  /**
     * metodo que consume el api de edit
     * @author Carlos Nieto
     */
  edit()
  {
    this.profileService.editProfile(this.profileForm.value).subscribe(
      response => {
        let text = response['data'];
        this.alertService.alertSuccess('Completado',text);
        this.dialogRef.close();
      }
    )
  }
 }
