<div class="main-container">

  <h1>Certificaciones</h1>
  <p>En esta sección podrás visualizar o descargar todos tus certificados</p>


  <div class="filter">
    <mat-form-field appearance="fill" fxFlex="33">
      <mat-label>Certificados Disponibles</mat-label>
      <mat-select #certificate (selectionChange)="getCertifications(certificate.value)">
        <mat-option *ngFor="let row of certifications" [value]="row.key">{{row.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="!showPaymentSlip">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container *ngFor="let column of displayColumnsName; index as i">
        <ng-container matColumnDef="{{column.field}}" *ngIf="column.field == 'file'">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column.name}} </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button matTooltip="Ver documento" [disabled]="!element.file"
              (click)="getCertificate(certificate.value,element, true)">
              <span class="fi-rr-eye"></span>
            </button>
            <button mat-icon-button matTooltip="Descargar documento" [disabled]="!element.file"
              (click)="getCertificate(certificate.value,element, false)">
              <span class="fi-rr-cloud-download"></span>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="{{column.field}}" *ngIf="column.field != 'file'">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column.name}} </th>
          <td mat-cell *matCellDef="let element"> {{element[column.field]}}</td>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
    </table>
    <mat-paginator *ngIf="displayColumns.length > 0" [length]="length" [pageSize]="pageSize"
      (page)="getPaginatorData($event)" showFirstLastButtons></mat-paginator>
  </div>

  <!-- showPaymentSlip variable para diferenciar si es un desprendible de nomina o general -->
  <div *ngIf="showPaymentSlip">
    <mat-tab-group>
      <mat-tab [label]="currentYear.getFullYear()">
        <div class="row col-12 mt-3">
          <div *ngFor="let element of listOfYear" class="col-3" style="padding-bottom: 24px;">
            <mat-card class="form-card" (click)="openPdfPaymentSlip(element.year, element.month)">
              <div class="row">
                <div class="row col-12">
                  <div class="col-3 row" style="padding: 0px; position:relative;">
                    <img src="../../../../../../assets/images/calendar.svg">
                  </div>

                  <mat-card-header class="card-view col">
                    <mat-card-title>
                      <p style="font-size: 15px">Desprendible mes</p>
                    </mat-card-title>
                    <mat-card-subtitle>
                      <h3>{{getName(element.month)}}</h3>
                    </mat-card-subtitle>
                  </mat-card-header>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Historico">
        <div class="row mt-3" [formGroup]="dateForm">
          <mat-form-field appearance="fill" class="col-3 pr-3">
            <mat-label>Filtrar por año</mat-label>
            <mat-select formControlName="year" (selectionChange)="filterPaymentSlip()">
              <mat-option value="">Seleccionar</mat-option>
              <mat-option *ngFor="let item of years" [value]="item.id">
                {{item?.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="col-3 pr-3">
            <mat-label>Filtrar por mes</mat-label>
            <mat-select formControlName="month" (selectionChange)="filterPaymentSlip()">
              <mat-option value="">Seleccionar</mat-option>
              <mat-option *ngFor="let item of months" [value]="item.id">
                {{item?.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row col-12">
          <div *ngFor="let element of filterWorkersMonths" class="col-3" style="padding-bottom: 24px;">
            <mat-card class="form-card" (click)="openPdfPaymentSlip(element.year, element.month)">
              <div class="row">
                <div class="row col-12">
                  <div class="col-3 row" style="padding: 0px; position:relative;">
                    <img src="../../../../../../assets/images/calendar.svg">
                  </div>
                  <mat-card-header class="card-view col">
                    <mat-card-title>
                      <p style="font-size: 15px">Desprendible año {{element.year}} mes</p>
                    </mat-card-title>
                    <mat-card-subtitle>
                      <h3>{{getName(element.month)}}</h3>
                    </mat-card-subtitle>
                  </mat-card-header>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

</div>