import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CandidateMainComponent } from './modules/candidates/candidate-main/candidate-main.component';
import { RequestDetailsComponent } from './modules/requests/request-details/request-details.component';
import { RequestsMainComponent } from './modules/requests/requests-main/requests-main.component';
import { RrhhComponent } from './rrhh.component';
import { GroupsMainComponent } from './modules/training-groups/groups-main/groups-main.component';
import { GroupsViewComponent } from './modules/training-groups/groups-view/groups-view.component';
import { CvComponent } from './modules/employee/cv/cv.component';
import { CertificationsComponent } from './modules/employee/certifications/certifications.component';
import { MedicalCandidatesComponent } from './modules/medical-test/medical-candidates/medical-candidates.component';
import { CandidateCvComponent } from './modules/candidates/candidate-cv/candidate-cv.component';
import { CvViewComponent } from 'src/app/shared/cv-view/cv-view.component';
import { PositionsViewComponent } from './modules/positions/positions-view/positions-view.component';
import { AssesmentsViewComponent } from './modules/assesments/assesments-view/assesments-view.component';
import { AssessmentsDetailComponent } from './modules/assesments/assessments-detail/assessments-detail.component';
import { ReportsMainComponent } from './modules/reports/reports-main/reports-main.component';
import { UpdatesMainComponent } from './modules/updates/updates-main/updates-main.component';
import { CandidateJuridicoNominaDocComponent } from './modules/candidates/candidate-juridico-nomina-doc/candidate-juridico-nomina-doc.component';
import { CandidateCvJuridicoNominaDocComponent } from './modules/candidates/candidate-cv-juridico-nomina-doc/candidate-cv-juridico-nomina-doc.component';
import {TemplateIndexComponent} from './modules/templates/template-index/template-index.component';

const routes: Routes = [

  { path: '', component: RrhhComponent},
  { path: 'solicitudes', component: RequestsMainComponent },
  { path: 'candidatos', component: CandidateMainComponent },
  { path: 'solicitud/:id', component: RequestDetailsComponent },
  { path: 'grupos-formacion', component: GroupsMainComponent },
  { path: 'grupo-formacion/:id', component: GroupsViewComponent },
  { path: 'hoja-vida', component:CvComponent },
  { path: 'certificados',component:CertificationsComponent },
  { path: 'examenes-medicos',component:MedicalCandidatesComponent },
  { path: 'candidato-cv/:id',component:CandidateCvComponent},
  { path: 'positions', component: PositionsViewComponent},
  { path: 'assessments', component: AssesmentsViewComponent },
  { path: 'assessment/:id', component: AssessmentsDetailComponent},
  { path: 'reportes', component: ReportsMainComponent},
  { path: 'updates', component: UpdatesMainComponent},
  { path: 'candidatos-juridico-nomina', component: CandidateJuridicoNominaDocComponent },
  { path: 'candidatos-cv-juridico-nomina/:id', component: CandidateCvJuridicoNominaDocComponent },
  { path: 'templates', component: TemplateIndexComponent},



];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RrhhRoutingModule { }

