/**
   * @author Karol García
   * @createdate 2021-05-05
   * Método que convierte número a letras
   * ***  IMPORTANTE  ********
   * Código tomado de lab master y adapatado a esta aplicación
   */
interface LocaleInterface {
    currency: {
      name: string;
      plural: string;
      symbol: string;
      fractionalUnit: {
        name: string;
        plural: string;
        symbol: string;
      };
    };

    texts: {
      and: string;
      only: string;
      point: string;
      zero: string;
    };
  
  }
export class Locale implements LocaleInterface {
    public currency = {
        name: 'PESO',
        plural: 'PESOS',
        symbol: '$',
        fractionalUnit: {
          name: 'CENTAVO',
          plural: 'CENTAVOS',
          symbol: '',
        },
      };
    
      public texts = {
        and: 'Y',
        only: 'DE',
        point: 'CON',
        zero: 'CERO'
      }; 
  }
interface ConstructorOf<T> {
    new (): T;
  }

export class ToWords {

  private locale: LocaleInterface | undefined = undefined;

  constructor() { }

    private getLocaleClass(): ConstructorOf<LocaleInterface> {
        return Locale
    }
    private getLocale(): LocaleInterface {
        if (this.locale === undefined) {
        const LocaleClass = this.getLocaleClass();
        this.locale = new LocaleClass();
        }
        return this.locale as LocaleInterface;
    }
    private Section(number, divider, strSingular, strPlural) {
        let hundreds = Math.floor(number / divider)
        let rest = number - (hundreds * divider)

        let letters = '';

        if (hundreds > 0)
            if (hundreds > 1) 
            letters = this.Hundreds(hundreds) + " " + strPlural;
            else
            letters = strSingular;

        if (rest > 0)
            letters += "";

        return letters;
        
    }
    convert(number: number){
        const locale = this.getLocale();
        var data = {
            number: number,
            whole: Math.floor(number),
            cents: (((Math.round(number * 100)) - (Math.floor(number) * 100))),
            lettersCents: ''
            };

            if (data.cents > 0) {
                if (data.cents == 1)
                  data.lettersCents = locale.texts.point + ' ' + this.Millions(data.cents) + " " + locale.currency.fractionalUnit.name
                else
                  data.lettersCents = locale.texts.point + ' ' + this.Millions(data.cents) + " " + locale.currency.fractionalUnit.plural
            };
        
        if (data.whole == 0)
                return locale.texts.zero + ' ' + locale.currency.plural + ' ' + data.lettersCents;
        if (data.whole == 1)
                return this.Millions(data.whole) + " " + locale.currency.name + " " + data.lettersCents;
                else
                return this.Millions(data.whole) + " " + locale.currency.plural + " " +  data.lettersCents;

    }
    private  Millions(number: number) {
            let divider = 1000000;
            let hundreds = Math.floor(number / divider)
            let rest = number - (hundreds * divider)
        
            let strMillions = this.Section(number, divider, "UN MILLON", "MILLONES");
          
            let strThousands = this.Thousands(rest);
        
            if (strMillions == "")
            return strThousands;

            if (strMillions != "" && rest == 0)
            return strMillions + " " +this.locale.texts.only ;
        
            return strMillions + " " + strThousands;

        }
    private Thousands(number: number) {
        let divider = 1000;
        let hundreds = Math.floor(number / divider)
        let rest = number - (hundreds * divider)

        let strThousands = this.Section(number, divider, "MIL", "MIL");
        let strHundreds = this.Hundreds(rest);

        if (strThousands == "")
        return strHundreds;

        return strThousands + " " + strHundreds;

    }
    private  Hundreds(number: number) {
        let hundreds = Math.floor(number / 100);
        let tens = number - (hundreds * 100);

        switch (hundreds) {
        case 1:
            if (tens > 0)
            return "CIENTO " + this.Tens(tens);
            return "CIEN";
        case 2: return "DOSCIENTOS " + this.Tens(tens);
        case 3: return "TRESCIENTOS " + this.Tens(tens);
        case 4: return "CUATROCIENTOS " + this.Tens(tens);
        case 5: return "QUINIENTOS " + this.Tens(tens);
        case 6: return "SEISCIENTOS " + this.Tens(tens);
        case 7: return "SETECIENTOS " + this.Tens(tens);
        case 8: return "OCHOCIENTOS " + this.Tens(tens);
        case 9: return "NOVECIENTOS " + this.Tens(tens);
        }

        return this.Tens(tens);
    }
    private Tens(number: number) {
            let ten= Math.floor(number / 10);
            let unit = number - (ten * 10);

            switch (ten) {
            case 1:
                switch (unit) {
                case 0: return "DIEZ";
                case 1: return "ONCE";
                case 2: return "DOCE";
                case 3: return "TRECE";
                case 4: return "CATORCE";
                case 5: return "QUINCE";
                default: return "DIECI" + this.Units(unit);
                }
            case 2:
                switch (unit) {
                case 0: return "VEINTE";
                default: return "VEINTI" + this.Units(unit);
                }
            case 3: return this.TensY("TREINTA", unit);
            case 4: return this.TensY("CUARENTA", unit);
            case 5: return this.TensY("CINCUENTA", unit);
            case 6: return this.TensY("SESENTA", unit);
            case 7: return this.TensY("SETENTA", unit);
            case 8: return this.TensY("OCHENTA", unit);
            case 9: return this.TensY("NOVENTA", unit);
            case 0: return this.Units(unit);
            }
    }
    private TensY(strSin, numUnits) {
        if (numUnits > 0)
        return strSin +' '+ this.locale.texts.and + ' '+this.Units(numUnits)
        return strSin;
    }     
    private Units(number: number) {

    switch (number) {
      case 1: return "UN";
      case 2: return "DOS";
      case 3: return "TRES";
      case 4: return "CUATRO";
      case 5: return "CINCO";
      case 6: return "SEIS";
      case 7: return "SIETE";
      case 8: return "OCHO";
      case 9: return "NUEVE";
    }
    return "";

    }
}