<div class="main-container">

    <div class="header">

        <h2 style="height: 33px !important;
      font: normal normal bold 45px/35px Poppins !important;
      letter-spacing: 0px !important;color: #353535 !important;opacity: 1 !important;">Jefe inmediato</h2>
        <br>
        <p>Aquí podrás ver asignar personal. </p>
        <p>No olvides, “El respeto y empatía son clave”.</p>
    </div>
    <form [formGroup]="formGroup">
        <div class="row mt-4">

            <div class="col-3">
                <mat-form-field appearance="fill" style="width: 100%;" class="me-3">
                    <mat-label>Número de documento </mat-label>
                    <input (keypress)="soloNumeros($event)" formControlName="documento" type="text" matInput (blur)="loadTable()" (keydown.enter)="loadTable($event)" >
                    <mat-icon (click)="loadTable($event)" matSuffix><i class="fi-rr-search"></i></mat-icon>
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field appearance="fill" style="width: 100%;" class="me-3">
                    <mat-label>Campaña</mat-label>
                    <input formControlName="name_campana" type="text" matInput (blur)="loadTable()" (keydown.enter)="loadTable($event)">
                    <mat-icon (click)="loadTable($event)" matSuffix><i class="fi-rr-search"></i></mat-icon>
                </mat-form-field>
            </div>

            <div class="col-3"></div>
            <div class="col-3">
                <button class="btn-asignaciones" mat-raised-button color="primary" (click)="openModal()">Asignaciones
                    &nbsp; <i class="fi fi-rr-add"></i> </button>
            </div>
        </div>
    </form>

    <div class="row mt-4">
        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="no">
                <th class="start" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" align="left">
                    <div class="action-icons">
                        <button mat-icon-button (click)="editarAsignacion(element)" >
                            <i class="fi fi-rr-edit"></i>
                        </button>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="assigned_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header >Última asignación</th>
                <td mat-cell *matCellDef="let element"> {{ element.assigned_date }} </td>
            </ng-container>

            <ng-container matColumnDef="name_boss">
                <th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre del Jefe inmediato</th>
                <td mat-cell *matCellDef="let element"> {{ element.name_boss }} </td>
            </ng-container>

            <ng-container matColumnDef="doc_boss">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header >Documento</th>
                <td mat-cell *matCellDef="let element"> {{ element.doc_boss }} </td>
            </ng-container>

            <ng-container matColumnDef="assigned_people">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Cantidad de personas a cargo</th>
                <td mat-cell *matCellDef="let element"> {{ element.assigned_people }} </td>
            </ng-container>

            <ng-container matColumnDef="campanas">
                <th mat-header-cell *matHeaderCellDef>Campañas a cargo</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.no_campaign }}
                    <span #tooltip="matTooltip"  matTooltip="{{  element.name_campaign | arrayToString:true }}" matTooltipPosition="right"
                        matTooltipHideDelay="800">
                        <i class="fi fi-rr-interrogation">
                        </i>
                    </span> 
                </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="5">No hay datos que mostrar</td>
            </tr>

        </table>
        <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)"
            [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>

    </div>

</div>