<div class="row novelty-row-padding">

    <h2 mat-dialog-title>
      <span class="gray-color" style="margin-left: -9px"></span>
      <button style="float: right;" mat-icon-button mat-dialog-close>
        <i class="fi-rr-cross-small"></i>
      </button>
    </h2>
  
    <mat-dialog-content style="padding: 3%; overflow-x: hidden" class="mat-typography">
      <form [formGroup]="formCampaign">
        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <h2>Actualizar Campaña</h2>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Selecciona una campaña</mat-label>
              <mat-select  formControlName="campaignId">
                <mat-option value="">Seleccionar</mat-option>
                <mat-option *ngFor="let item of campaings" [value]="item.id">
                  {{item?.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formCampaign.controls.campaignId.hasError('required')"> ¡Advertencia! Este campo es requerido</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 mt-5 d-flex justify-content-center">
            <button  mat-flat-button color="primary" class="me-2"
            [disabled]="formCampaign.invalid" (click)="callMethod()">
              Guardar
            </button>
            <button  mat-stroked-button mat-dialog-close>
              Cancelar
            </button> 
          </div>
        </div>
      </form>
    </mat-dialog-content>
  
  </div>