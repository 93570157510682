<div class="content">

  <div class="header">
    <h1 style="height: 33px;font: normal normal bold 24px,35px Poppins;letter-spacing: 0px;color: #353535;opacity: 1;">
      Clientes</h1>
    <p style="color:#343434">En esta sección podrás gestionar la información de los <br> clientes tanto externos como internos y crear nuevos.</p>
  </div>

  <mat-tab-group class="remove-border-bottom" animationDuration="0ms" style="margin-top: 65px;" [selectedIndex]="selectedIndex">
    <mat-tab label="Clientes" *ngIf="rolUser != 'implementador'">
      <div class="row col-12">

        <mat-form-field class="col-3 filter" appearance="fill">
          <mat-label>Cliente</mat-label>
          <input matInput [formControl]="nameControl" #input>
          <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
        </mat-form-field>

        <mat-form-field class="col-3 filter" appearance="fill">
          <mat-label>Tipo de identificación</mat-label>
          <mat-select [formControl]="typeControl">
            <mat-option value="">Todos</mat-option>
            <mat-option value="0">Nacional</mat-option>
            <mat-option value="1">Internacional</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-3 filter" appearance="fill">
          <mat-label>Número de identificación</mat-label>
          <input matInput [formControl]="numberControl" #input>
          <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
        </mat-form-field>

        <div class="col-3">
          <button class="download" mat-raised-button color="primary"
            [mat-dialog-close]="true" (click)="AperturaVentana()">
            Crear cliente <i class="fi-rr-add"></i>
          </button>
        </div>
      </div>
      <!-- Par generar las cards de los clientes-->
      <div class="table-container row">
        <div class="col-md-4 col-lg-3 mt-3 mb-3" *ngFor="let client of clientesarray" style="padding: 0 10px 0 10px;">
          <mat-card class="clientes-card mat-elevation-z8" style="margin-left: 8px;">
            <mat-card-header style="display: block;">

              <div class="badge">
                <button mat-icon-button style="background:#51B0CB;position: absolute;top: 5px;left: 8%;height: 55px;width: 55px;">
                  <i class="fi-rr-user"> </i>
                </button>
              </div>
              <div>
                <mat-card-subtitle style="color: #717171"> Cliente </mat-card-subtitle>
                <div>
                  <p style="color: #353535; font-weight: 700;overflow: hidden;text-overflow: ellipsis;" [matTooltip]="client?.name" [matTooltipPosition]="'above'">
                    {{client?.name}}
                  </p>
                </div>
                <div style="color: #717171; font-size: 12px;">
                  <label *ngIf="client?.country_id == '47'">NIT</label>
                  <label *ngIf="client?.country_id != '47'">EIN</label>
                  {{client?.id_number}}
                </div>

              </div>

            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
              <div align="left" class="subtitlecard2" style="align-items: left;">
                <label>Oportunidades activas :</label>&nbsp;
                {{client?.oportunities}} <br>
                <label>Lineas de servicio :</label>&nbsp;
                {{client?.service_line}} <br>
                <label>Fecha de creacion :</label>&nbsp;
                {{client?.created_at|date:'yyyy-MM-dd': 'UTC'}}
              </div>

            </mat-card-content>
            <mat-divider></mat-divider>
            <div class="action-icons">
              <button align="center" style="margin-top: 16px; color: #717171;" mat-button routerLink="/mios/crm-cial/cliente-consulta/{{client.id}}"><i
                  class="fi-rr-eye"></i> Ver</button>
              <button *ngIf="client?.responsible_id == user || rolUser == 'administrador'" align="center" style="margin-top: 16px; color: #717171;" mat-button (click)="EditarCliente(client)">
                <i class="fi-rr-edit"></i>
                Editar
              </button>
            </div>

          </mat-card>
        </div>



      </div>
      <!-- fin presentacion cards de clientes -->
    </mat-tab>
    <!-- OPCION DE OPORTUNIDADES EN PROCESO-->
    <mat-tab label="Oportunidades en proceso" *ngIf="rolUser != 'implementador'">
      <app-clientes-proceso></app-clientes-proceso>
    </mat-tab>
    <!-- FIN OPCION DE OPORTUNIDADES EN PROCESO-->
    <mat-tab label="Oportunidades cerradas">
      <app-clientes-seguimiento></app-clientes-seguimiento>
    </mat-tab>
  </mat-tab-group>


</div>
