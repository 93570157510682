import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { SchedulesService } from 'src/app/modules/ciu/services/rest/schedules.service';
import { NAMES_DAYS_OF_WEEK, OMIT_ACTIVITIES_WORK } from './constantes';
import { MatTabChangeEvent } from '@angular/material/tabs';
import * as moment from 'moment';

@Component({
  selector: 'app-user-schedule-v2',
  templateUrl: './user-schedule-v2.component.html',
  styleUrls: ['./user-schedule-v2.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class UserScheduleV2Component implements OnInit {
  user: any = null;
  indexWeekend: number = 0;
  indexTab: number = 0;
  currentMonth: string = '';
  currentYear: number = null;
  datesWeek: any[] = [];
  datesNextWeek: any[] = [];
  datesShowIntoView: any[] = [];
  activitiesWork: any[] = [];

  constructor(
    private authService: AuthService,
    private schedulesService: SchedulesService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.decryptToken();
    this.currentMonth = new Intl.DateTimeFormat('es-ES', { month: 'long' }).format(new Date());
    this.currentYear = moment().year();
    this.getDatesWeek();
    this.getDatesNextWeek();
    this.datesShowIntoView = this.datesWeek;
    this.getIndexByNumDay();
    this.getSchedules(this.user.idCiu, this.datesShowIntoView[this.indexTab].date);
  }

  /**
    * @author Fabian Duran
    * @createdate 2022-06-05
    * Metodo que retorna el numero del dia de la semana para setearlo en la tab de fechas. 
  */
  getIndexByNumDay(): void {
    const dayNow = moment().isoWeekday();
    this.indexTab = dayNow - 1;
  }
  /**
    * @author Fabian Duran
    * @createdate 2022-05-29
    * Metodo de retorna los horarios del empleado.
    * @param idUserCiu Id del usuario en CIU. 
    * @param date Fecha de la tab seleccionada.  
  */
  getSchedules(idUserCiu: number, date: string): void {
    this.activitiesWork = [];
    this.schedulesService.getWeekAndNextWeekSchedule(idUserCiu, date).subscribe(res => {
      res.data.forEach((week: any) => {
        if (week.days) {
          week.days.forEach((day: any) => {
            day.activities.forEach((activity: any) => {
              this.activitiesWork.push(activity);
            });
          });
        }
      });
      this.activitiesWork = this.activitiesWork.filter((activity: any) => {
        return activity.date === date && activity.schedule_type_name !== OMIT_ACTIVITIES_WORK[0] && activity.schedule_type_name !== OMIT_ACTIVITIES_WORK[1] && activity.schedule_type_name !== OMIT_ACTIVITIES_WORK[2];
      });
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2022-05-29
    * Metodo que setea el indice de la semana a 1. 
  */
  nextWeekend(): void {
    this.indexWeekend = 1;
    this.getDatesNextWeek();
    this.datesShowIntoView = this.datesNextWeek;
    this.getSchedules(this.user.idCiu, this.datesShowIntoView[this.indexTab].date);
  }
  /**
    * @author Fabian Duran
    * @createdate 2022-05-29
    * Metodo que setea el indice de la semana a 0. 
  */
  beforeWeekend(): void {
    this.indexWeekend = 0;
    this.getDatesWeek();
    this.datesShowIntoView = this.datesWeek;
    this.getSchedules(this.user.idCiu, this.datesShowIntoView[this.indexTab].date);
  }
  /**
    * @author Fabian Duran
    * @createdate 2022-05-30
    * Metodo que trae las fechas de la semana actual.
  */
  getDatesWeek(): void {
    this.datesWeek = [];
    const dateNow = moment();
    const dayWeek = dateNow.day();
    const monday = dateNow.subtract(dayWeek - 1, 'days');
    this.datesWeek.push({
      date: monday.format('DD/MM/YYYY'),
      nameDay: NAMES_DAYS_OF_WEEK[0]
    });
    for (let i = 1; i < 7; i++) {
      const dateClone = monday.clone().add(i, 'days');
      this.datesWeek.push({
        date: dateClone.format('DD/MM/YYYY'),
        nameDay: NAMES_DAYS_OF_WEEK[i]
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2022-05-30
    * Metodo que trae las fechas de la proxima semana. 
  */
  getDatesNextWeek(): void {
    this.datesNextWeek = [];
    const dateNow = moment();
    const dayWeek = dateNow.day();
    const mondayNextWeek = dateNow.add(7 - dayWeek + 1, 'days');
    this.datesNextWeek.push({
      date: mondayNextWeek.format('DD/MM/YYYY'),
      nameDay: NAMES_DAYS_OF_WEEK[0]
    });
    for (let i = 1; i < 7; i++) {
      const dateClone = mondayNextWeek.clone().add(i, 'days');
      this.datesNextWeek.push({
        date: dateClone.format('DD/MM/YYYY'),
        nameDay: NAMES_DAYS_OF_WEEK[i]
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2022-05-30
    * Metodo que detecta el cambio de las tabs sobre la vista. 
  */
  tabChanged($event: MatTabChangeEvent): void {
    this.indexTab = $event.index;
    this.getSchedules(this.user.idCiu, this.datesShowIntoView[this.indexTab].date);
  }
}