import { Component, OnInit, Inject } from '@angular/core';
import { CandidatesService } from '../../../services/candidates.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommentsService } from '../../../services/comments.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';

@Component({
  selector: 'app-candidate-comment-edit',
  templateUrl: './candidate-comment-edit.component.html',
  styleUrls: ['./candidate-comment-edit.component.sass']
})
export class CandidateCommentEditComponent implements OnInit {

  formComment:FormGroup;

  constructor(private commentService: CommentsService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private dialogRef: MatDialogRef<CandidateCommentEditComponent>,
              private alert:AlertsService) {
                this.createForm();
              }

  ngOnInit(): void {
    this.formComment.patchValue(this.data.comment);
  }

  createForm(){
      this.formComment = this.fb.group({
        comment:['',Validators.required]
      });
  }
  updateComment(){
    if(this.formComment.valid){
      this.alert.alertConfirm('¿Estas seguro que deaseas editar el comentario anterior?').then(e => {
        if(e.isConfirmed){
          this.commentService.editComment(this.data.comment.id,this.formComment.value).subscribe( resp => {
            this.alert.alertSuccess('¡Actualizado!','Comentario editado con éxito');
            this.dialogRef.close(resp);
          } )
        }
      })
    }
  }

}
