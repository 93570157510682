<div class="main-container">
    <div>
        <h1>Gestión de Grupos de Formación</h1>
        <p>Bienvenido al módulo de grupos de formación, en este espacio podrás gestionar los <br> grupos creados o crear nuevos. No olvides, “El respeto y empatía son clave”.</p>
    </div>

    <div class="filter">
        <div style="width: 50%;">
            <mat-form-field appearance="fill" class="col-3">
                <mat-label>Tipo de filtro</mat-label>
                <mat-select [(ngModel)]="typeFilter">
                    <mat-option value="id">id</mat-option>
                    <mat-option value="Formador">Formador</mat-option>
                    <mat-option value="Empresa">Empresa</mat-option>
                    <mat-option value="Campaña">Campaña</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" *ngIf="typeFilter !=null && typeFilter!='Empresa' && typeFilter!='Campaña'" class="col-3">
                <mat-label>Filtrar por {{typeFilter}}</mat-label>
                <input matInput #filter (keyup.enter)="filterByValue(filter.value)">
                <button mat-icon-button matSuffix (click)="filterByValue(filter.value)"><mat-icon>search</mat-icon></button>
            </mat-form-field>

            <mat-form-field appearance="fill" *ngIf="typeFilter =='Empresa'" class="col-3">
                <mat-label>Filtrar por empresa</mat-label>
                <mat-select #filterTrainer>
                    <mat-option *ngFor="let type of enterprises" [value]="type.id">
                        {{type.name}}</mat-option>
                </mat-select>
                <button mat-icon-button matSuffix (click)="filterByValue(filterTrainer.value)"><mat-icon>search</mat-icon></button>
            </mat-form-field>
            <mat-form-field appearance="fill" *ngIf="typeFilter =='Campaña'" class="col-3">
                <mat-label>Filtrar por campaña</mat-label>
                <mat-select [(value)]="selected" (selectionChange)="filterByValue(selected)">
                    <mat-option *ngFor="let type of campaigns" [value]="type.id">
                        {{type.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <button mat-raised-button color="primary" (click)="createGroup()" *ngIf="leader">Crear grupo</button>
        </div>
    </div>

    <p *ngIf="!showGroups">No tienes grupos disponibles</p>

    <div class="table" *ngIf="showGroups">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container *ngFor="let column of displayedColumns; index as i">
                <ng-container matColumnDef="{{column}}" *ngIf="column !='actions'">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{columnName[i]}} </th>
                    <td mat-cell *matCellDef="let element"> {{element[column]}}</td>
                </ng-container>
                <ng-container matColumnDef="{{column}}" *ngIf="column =='actions'">
                    <th mat-header-cell *matHeaderCellDef> {{columnName[i]}} </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutAlign="space-around center">
                            <button mat-icon-button matTooltip="Ver grupo">
                            <span class="fi fi-rr-eye" (click)="viewGroup(element)">
                        </span></button>
                            <button mat-icon-button [matTooltip]="element.analyst_user_id ? 'Actualizar analista' : 'Asignar analista'" *ngIf="analistUser">
                            <span class="fi-rr-user-add" (click)="openUsers(element)">
                        </span></button>
                            <span matTooltip="{{element.dateToFinish}} días restantes" style="transform:scale(0.8);">
                            <mat-icon [style.color]="element.indicatorStateColor">fiber_manual_record</mat-icon>
                        </span>
                        </div>
                    </td>
                </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="9">No hay grupos que coincidan con la busqueda"</td>
            </tr>
        </table>

        <mat-paginator [length]="length" [pageSize]="pageSize" (page)="getPaginatorData($event)" showFirstLastButtons></mat-paginator>
    </div>
</div>