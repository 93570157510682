<div class="img row" style="background-image: url('../../../assets/images/on-boarding/{{img}}')">
  <div class="col-12 mt-4 text-end">
    <button class="btnlanding" mat-stroked-button color="primary" [mat-dialog-close]="true">Finalizar onboarding</button>
  </div>
  <div class="col-12 mt-4 text-end">
    <button *ngIf="index > 0" class="btn-back" mat-stroked-button color="primary" (click)="ChangeBack()">Volver</button>
    <button *ngIf="data.length > index + 1" class="btn-next" mat-raised-button (click)="ChangeNext()">Siguiente</button>
    <button *ngIf="data.length == index + 1" class="btn-next" mat-raised-button [mat-dialog-close]="true">Terminar</button>
  </div>
</div>

