import { Pipe, PipeTransform } from '@angular/core';
import { Shortcut } from '../modules/chat/models/shortcut';

@Pipe({
  name: 'shortcutfilter'
})
export class ShortcutPipe implements PipeTransform {

  transform(favorites: Shortcut[], shortcuts: Shortcut[], search: string = ''): unknown {
    console.log('shortcut pipe. ', shortcuts);
    let items;
    if (search.length === 0) {
      items = favorites;
      return items;
    }

    const filterShortcut = shortcuts.filter(short => short.text.includes(search));
    items = filterShortcut;
    return items;
  }

}
