import { Injectable } from '@angular/core';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { DataDisconnectSocket } from '../../modules/chat/models/dataDisconnectSocket';
import { MixinsService } from '../rest/mixins.service';
import { socketService } from './index.service';
import { SocketActiveService } from './socket.service';

@Injectable({
  providedIn: 'root'
})
export class SocketDisconnectService {

  public socketActive:socketService;

  constructor(
    private _socketActiveService: SocketActiveService,
    private _mixinsService: MixinsService,
    private _alertsService: AlertsService,
    ) {
    
  }

  async disconnectSocket(rrhh:number, data:DataDisconnectSocket) {
    try {
      this.socketActive = this._socketActiveService.getSocketActive();      
      this.socketActive.sendMessage('agentDisconnect', { rrhh })
      this.socketActive.disconnect();
      await this._mixinsService.logoutRedis(data).toPromise()
    } catch (error) {
      console.error('Error al desconectar el usuario del socket: ', error);
      this._alertsService.dangerError('Error: No se pudo cambiar el estado, por favor actualice la página (F5).');
    }
  }
}
