<h2 class="mt-4 fw-bold" mat-dialog-title>
    {{ data.observation !== undefined ? 'Editar observación' : 'Crear observación' }}
</h2>
<mat-dialog-content>
    <mat-divider></mat-divider>
    <form class="px-4 py-4" [formGroup]="formCreateObservation">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="w-100" appearance="fill">
                    <input
                        formControlName="observation"
                        type="text"
                        matInput
                        placeholder="Escribe aquí tu observación">
                    <mat-error *ngIf="formCreateObservation.controls.observation.errors">
                        <span *ngIf="formCreateObservation.controls.observation.errors.required">Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="formCreateObservation.controls.observation.errors">
                        <span *ngIf="formCreateObservation.controls.observation.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 200 caracteres</span>
                    </mat-error>
                </mat-form-field> 
            </div>
        </div>
        <div class="row justify-content-end">
            <div class="col-md-3">
                <button
                    mat-stroked-button
                    mat-dialog-close
                    color="primary"
                    class="w-100 fw-bold"
                    type="button">
                    Cancelar
                </button>
            </div>
            <div class="col-md-3">
                <button
                    mat-raised-button
                    color="primary"
                    class="w-100 fw-bold"
                    type="button"
                    (click)="sendObservationItem()">
                    Enviar
                </button>
            </div>
        </div>
    </form>
</mat-dialog-content>
