import { SchedulesService } from './../../../modules/ciu/services/rest/schedules.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { ScheduleModalComponent } from '../schedule-modal/schedule-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-user-schedule',
  templateUrl: './user-schedule.component.html',
  styleUrls: ['./user-schedule.component.sass']
})
export class UserScheduleComponent implements OnInit {

  public weekScheduleList = [];
  public count : number;
  public user : any;

  constructor(
    private schedulesService : SchedulesService,
    private authService: AuthService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.user = this.authService.decryptToken(); 
    this.getUserWeekSchedule(this.user.idCiu);
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-03-11
   * Metodo lista el horario laborar de un usuario
   */
  getUserWeekSchedule(id)
  {
    this.schedulesService.getUserWeekSchedule(id).subscribe(
      response => {
        this.weekScheduleList = Object.values(response.data);
        this.count = this.weekScheduleList.length;
      },error =>{
        this.weekScheduleList = [];     
        this.count = this.weekScheduleList.length; 
      } 
    )
  }

  /**
     * Metodo abre el modal del crear referido y pasa el id de hired_user,
     * al cerrarse llama al metodo de getReferredList() 
     * @author Carlos Nieto
     * @date 11-10-2022  
     * @return
     */
   openCreateForm(){
    const registerDialog = this.dialog.open( ScheduleModalComponent, {
      minWidth: '150px',
      minHeight: '400px',
      data: this.weekScheduleList
    });
  }
}
