<main>
  <div fxLayout="row">
    <div fxFlex="70%">
      <mat-form-field appearance="fill" style="width: 300px;" class="me-3" fxFlex.gt-lg="25%" fxFlex.gt-md="20%">
        <mat-label>{{conf.labels.filters.arrays}}</mat-label>
        <input type="text" matInput [(ngModel)]="filtroMatriz" (blur)="filtrar()">
        <mat-icon matSuffix><i class="fi-rr-search"></i></mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill" class="me-3" fxFlex.gt-lg="20%" fxFlex.gt-md="20%">
        <mat-label>{{conf.labels.filters.date}}</mat-label>
        <mat-date-range-input [rangePicker]="rango" [formGroup]="filtroFecha" (keydown.enter)="filtrar()">
          <input matStartDate placeholder="Fecha inicio" formControlName="fechaInicial">
          <input matEndDate placeholder="Fecha fin" formControlName="fechaFinal" (dateChange)="filtroFechas()">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rango">
          <mat-icon matDatepickerToggleIcon><i class="fi-rr-calendar"></i></mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker #rango></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 206px;" class="me-3" fxFlex.gt-lg="20%" fxFlex.gt-md="20%">
        <mat-label>{{conf.labels.filters.arrayTypeLabel}}</mat-label>
        <mat-select [(value)]="filtroTipo_matriz" (valueChange)="filtrar()">
          <mat-option value="">{{conf.labels.filters.arrayTypeOptionAll}}</mat-option>
          <mat-option *ngFor="let tipo of tiposMatrices" [value]="tipo.id">{{tipo.nombre}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="reports" fxFlex.gt-lg="20%" fxFlex.gt-md="20%">
        <mat-label>{{conf.labels.filters.campaignLabel}}</mat-label>
        <mat-select [(value)]="filtroCampaign" (valueChange)="filtrar()">
          <mat-option>{{conf.labels.filters.campaignOptionAll}}</mat-option>
          <mat-option *ngFor="let campaign of campaigns" [value]="campaign.id">{{campaign.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="30%" fxLayoutAlign="end none" *ngIf="reports">
      <button mat-raised-button color="primary" style="height: 48px;width: 300px;" (click)="getReportGeneral(filtroTipo_matriz,filtroTipo_matriz)">
        {{conf.labels.btnDownload}}
        <mat-icon style="font-size: 16px;"><i class="fi-rr-download"></i></mat-icon>
      </button>
    </div>
  </div>
  <div>
    <div class="btn-crear">
      <p>{{conf.labels.actions.createTitle}}</p>
      <button mat-raised-button color="primary" style="height: 52px; font-size: 1.2em;"
              (click)="newMatrix()" *ngIf="!auditar && !control && !reports">
        {{conf.labels.actions.createBtn}} <i class="fi-rr-add"></i>
      </button>
    </div>
    <div class="matrices">
      <mat-card *ngFor="let matriz of dataSource">
        <mat-card-header>
          <div mat-card-avatar>
            <mat-icon [ngClass]="{'disable-icon': matriz.matriz.activo == 0,'enable-icon': matriz.matriz.activo== 1}"><i class="fi-rr-layout-fluid"></i></mat-icon>
          </div>
          <div class="content-card">
            <mat-card-title [ngClass]="{'disable-text': matriz.matriz.activo == 0,'txt-33': matriz.matriz.nombre.length > 33}" aria-label="Button that displays a tooltip when focused or hovered over"
                            [matTooltip]="matriz.matriz.nombre" [matTooltipPosition]="'above'" [matTooltipClass]="'tooltip-content'">{{matriz.matriz.nombre | titlecase | cutText:0:endText}}</mat-card-title>
            <h5 [ngClass]="{'disable-text': matriz.matriz.activo == 0}">{{matriz.matriz.tipo_matriz.nombre | titlecase}}</h5>
            <h6 [ngClass]="{'disable-text': matriz.matriz.activo == 0}">{{matriz.matriz.created_at | date:'dd/MM/yyyy h:mm a'}}</h6>
          </div>
        </mat-card-header>
        <div *ngIf="!auditar && !control && !reports">
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="edit(matriz.matriz.id)">{{conf.labels.actions.editBtn}}</button>
            <button mat-menu-item (click)="asignarSuper(matriz.matriz.id,'super')">{{conf.labels.actions.assignBtn}}</button>
            <button mat-menu-item (click)="disableMatrix(matriz.matriz.id,false)" *ngIf="matriz.matriz.activo == 1">{{conf.labels.actions.disableBtn}}</button>
            <button mat-menu-item (click)="enableMatrix(matriz.matriz.id,true)" *ngIf="matriz.matriz.activo == 0">{{conf.labels.actions.enableBtn}}</button>
            <button mat-menu-item (click)="descargaActualizaciones(matriz.matriz.id)" *ngIf="jefe">{{conf.labels.actions.downloadBtn}}</button>
            <button mat-menu-item  (click)="eliminarMatriz(matriz)" *ngIf="jefe">{{conf.labels.actions.deleteBtn}}</button>
          </mat-menu>
          <button mat-button [matMenuTriggerFor]="menu" id="menu-boton">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
        <button mat-icon-button id="btn-detail" (click)="detailMatrix(matriz.matriz.id)" [hidden]="matriz.matriz.activo==0" *ngIf="(!auditar && !control) && !reports">
          <mat-icon color="primary"><i class=" fi-rr-arrow-small-right"></i></mat-icon>
        </button>
        <button mat-icon-button id="btn-detail" [hidden]="matriz.matriz.activo==0" *ngIf="auditar"
                (click)="detailAuditar(matriz.matriz.id)">
          <mat-icon color="primary"><i class=" fi-rr-arrow-small-right"></i></mat-icon>
        </button>
        <button mat-icon-button id="btn-detail" [hidden]="matriz.matriz.activo==0" *ngIf="control"
                (click)="detailControl(matriz.matriz.id)">
          <mat-icon color="primary"><i class=" fi-rr-arrow-small-right"></i></mat-icon>
        </button>
        <button mat-icon-button id="btn-detail" [hidden]="matriz.matriz.activo==0" *ngIf="reports"
                (click)="getReportById(matriz.matriz.id, matriz.matriz.tipo_matriz_id)">
          <mat-icon color="primary" style="font-size: 16px;"><i class=" fi-rr-download"></i></mat-icon>
        </button>
      </mat-card>
    </div>
    <mat-paginator [length]="length"
                   [pageSize]="pageSize"
                   (page) ="pageEvent($event)"
                   [pageSizeOptions] = "pageSizeOptions"
    >
    </mat-paginator>
  </div>
</main>
