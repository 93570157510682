<div class="row novelty-row-padding">

  <h2 mat-dialog-title style="padding: 0px 12px 0px 6px; margin: 0px;">
    <button style="float: right;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
  </h2>
  <mat-dialog-content style="padding: 0px 48px 48px 48px; overflow-x: hidden" class="mat-typography">
    <mat-tab-group>
      <!--Tab de Hoja de vida-->
      <mat-tab label="Información de contacto">
        <div class="col-12" style="margin-top: 40px;">
          <h3 style="color:#353535">Información de contacto</h3>
          <mat-divider></mat-divider>
        </div>


          <div style="margin-top: 30px; overflow-x: hidden">
            <div class="row">
              <div class="col-5">
                <div class="profile">
                  <img [src]="photo | userPhoto" onerror="this.src='./assets/images/dummy-user.jpg';" [alt]="data?.data?.name" width="192" height="192">
                </div>
                <br>
                <br>
                <h3>{{data?.data?.name}}</h3>
                <p class="gray-color">{{candidate?.documentId}}</p>

              </div>
              <div class="col-7">
                <h3 class=" col-8 gray-color">Datos personales</h3>
                <div class="row">
                  <div class="col-6">

                    <div>
                      <strong>Fecha de Nacimiento</strong>
                      <p>{{candidate?.birth_date | date}}</p>
                    </div>
                    <div>
                      <strong>Estado civil</strong>
                      <p>{{candidate?.civilState?.name || 'No especifica'}}</p>
                    </div>
                    <div>
                      <strong >Correo</strong>
                      <p class="overflow-visible">{{candidate?.email || 'No especifica'}}</p>
                    </div>
                    <div *ngIf="candidate?.email2">
                      <strong>Correo secundario</strong>
                      <p class="overflow-visible">{{candidate?.email2 || 'No especifica'}}</p>
                    </div>
                    <div *ngIf="candidate?.phone">
                      <strong>Teléfono</strong>
                      <p>{{candidate?.phone || 'No especifica'}}</p>
                    </div>

                  </div>

                  <div class="col-6">
                    <div>
                      <strong>Celular</strong>
                      <p>{{candidate?.mobile_phone || 'No especifica'}}</p>
                    </div>
                    <div>
                      <strong>Ciudad</strong>
                      <p>{{candidate?.personal_data?.city_of_residence || 'No especifica'}}</p>
                    </div>
                    <div>
                      <strong>Localidad</strong>
                      <p>{{candidate?.personal_data?.location || 'No especifica'}}</p>
                    </div>
                    <div>
                      <strong> Dirección</strong>
                      <p>{{candidate?.personal_data?.adress || 'No especifica'}}</p>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>

        <br>
      </mat-tab>
      <!--Tab de Novedades-->
      <mat-tab label="Novedades">

        <div style="margin-top: 40px;">
          <h3>Novedades de nómina</h3>
          <p>En este espacio podrás descargar las novedades del trabajador, no olvides,"El respeto y la
            empatiá son clave"</p>
        </div>
        <mat-divider></mat-divider>

        <mat-form-field appearance="fill" class="col-8 filter">
          <mat-label>Filtrar por fecha</mat-label>
          <mat-date-range-input [formGroup]="reportForm" [rangePicker]="picker">
            <input matStartDate [formControl]="reportForm.controls['start']" placeholder="Start date" #startDate>
            <input matEndDate [formControl]="reportForm.controls['end']" placeholder="End date" #endDate (dateChange)="dateRangeChange()">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <button mat-raised-button color="primary" (click)="downloadReport()" class="download col-4">Descargar reporte
          <span class="fi-rr-download"></span>
        </button>

      </mat-tab>
      <!--Tab de liquidación-->
      <mat-tab label="liquidación" *ngIf="data.data.state == 1">

        <div style="margin-top: 40px;">
          <h3>Gestionar liquidación</h3>
        </div>
        <mat-divider></mat-divider>
        <div style="margin-top: 40px; display: inline-block;">
          <mat-form-field appearance="fill" style="margin-right: 20px;">
            <mat-label>Seleccione una fecha</mat-label>
            <input matInput [matDatepicker]="picker" [formControl]="dateLiquidation">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <button mat-raised-button color="primary" (click)="liquidations()">Liquidar
          </button>
        </div>


      </mat-tab>

    </mat-tab-group>
  </mat-dialog-content>
</div>
