<h2 mat-dialog-title>
    {{ data.title }}
    <button style="float: right" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
  </h2>

  <form class="row form" [formGroup]="applicationForm" (ngSubmit)="saveApplication(applicationForm)">

    <mat-dialog-content style="padding: 3%;" class="mat-typography">
  
        <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
            <mat-label>Nombre Aplicación</mat-label>
            <input style="text-transform: capitalize;" matInput type="text" placeholder="Escribe el nombre de la aplicación" formControlName="name" required cdkFocusInitial>
            <mat-error *ngIf="error.name.errors"><span *ngIf="error.name.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            <mat-error *ngIf="error.name.errors"><span *ngIf="error.name.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
            <mat-error *ngIf="error.name.errors"><span *ngIf="error.name.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 40 caracteres</span></mat-error>
        </mat-form-field>
        

  
        <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
            <mat-label>Llave</mat-label>
            <input style="text-transform: lowercase;" matInput type="text" placeholder="Escribe la llave" formControlName="key" required>
            <mat-error *ngIf="error.key.errors"><span *ngIf="error.key.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            <mat-error *ngIf="error.key.errors"><span *ngIf="error.key.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
            <mat-error *ngIf="error.key.errors"><span *ngIf="error.key.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 3 caracteres</span></mat-error>
        </mat-form-field>
        <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
            <mat-label>Endpoint</mat-label>
            <input style="text-transform: lowercase;" matInput type="text" placeholder="Escribe la llave" formControlName="endpoint" required>
            <mat-error *ngIf="error.endpoint.errors"><span *ngIf="error.endpoint.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            <mat-error *ngIf="error.endpoint.errors"><span *ngIf="error.endpoint.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
            <mat-error *ngIf="error.endpoint.errors"><span *ngIf="error.endpoint.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 3 caracteres</span></mat-error>
        </mat-form-field>
  
        <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
            <mat-label>Requiere Horario</mat-label>
            <input type="text"
                   matInput
                   formControlName="horario"
                   [matAutocomplete]="auto"
                   
                   required>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]='displayFn.bind(this)'  required cdkFocusInitial>
              <mat-option [value]="1">
                Si
              </mat-option>
              <mat-option [value]="2">
                No
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="error.horario.errors"><span *ngIf="error.horario.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            <mat-error *ngIf="error.horario.errors">Haga click en una de las opciones de autocompletar</mat-error>
        </mat-form-field>
  
    </mat-dialog-content>
  
    <mat-dialog-actions align="end">
      <button mat-stroked-button mat-dialog-close>Cancelar</button>
      <button mat-raised-button type="submit" color="primary" disabled='{{applicationForm.invalid}}' [mat-dialog-close]="true">Guardar</button>
    </mat-dialog-actions>
  
  </form>