<div class="app-ccomercial-create">

  <h2 tcomercial>
    Creacion de contacto
  </h2>

  <form class="row form" [formGroup]="contactosForm" (ngSubmit)="saveContacto(contactosForm)">

    <mat-form-field class="col-4" appearance="fill">
      <mat-label>Nombre</mat-label>
      <input style="text-transform: none;" matInput type="text" placeholder="Nombre del contacto"
        formControlName="contact_nombre" maxlength="255" required>
      <mat-error *ngIf="error.contact_nombre.errors"><span *ngIf="error.contact_nombre.errors.required">¡Advertencia!
          Este campo es requerido</span></mat-error>
      <mat-error *ngIf="error.contact_nombre.errors"><span *ngIf="error.contact_nombre.errors.minlength">¡Aún falta!
          Recibimos mínimo 3 caracteres</span></mat-error>
      <mat-error *ngIf="error.contact_nombre.errors"><span *ngIf="error.contact_nombre.errors.maxlength">¡Cuidado! Solo
          puedes tener un máximo de 255 caracteres</span></mat-error>
    </mat-form-field>

    <mat-form-field class="col-3" appearance="fill">
      <mat-label>Cargo</mat-label>
      <input style="text-transform: none;" matInput type="text" min="0" maxlength="255" placeholder="Escribe el cargo"
        formControlName="contact_cargo" required>
      <mat-error *ngIf="error.contact_cargo.errors"><span *ngIf="error.contact_cargo.errors.required">¡Advertencia! Este
          campo es requerido</span></mat-error>
      <mat-error *ngIf="error.contact_cargo.errors"><span *ngIf="error.contact_cargo.errors.minlength">¡Aún falta!
          Recibimos mínimo 3 caracteres</span></mat-error>

    </mat-form-field>
    <mat-form-field class="col-5" appearance="fill">
      <mat-label>Celular</mat-label>
      <input style="text-transform: none;" matInput type="text" min="0" maxlength="10" placeholder="" formControlName="contact_phone"
        (keypress)="keyPress($event)" required>
      <mat-error *ngIf="error.contact_phone.errors"><span *ngIf="error.contact_phone.errors.required">¡Advertencia! Este
          campo es requerido</span></mat-error>
      <mat-error *ngIf="error.contact_phone.errors"><span *ngIf="error.contact_phone.errors.minlength">¡Aún falta!
          Recibimos mínimo 7 caracteres</span></mat-error>

    </mat-form-field>
    <br>
    <mat-form-field class="col-7" appearance="fill">
      <mat-label>Correo electronico</mat-label>
      <input type="email" style="text-transform: none;" matInput  maxlength="255" type="text" placeholder=""
        formControlName="contact_email" ngModel email="true" required>
      <mat-error *ngIf="error.contact_email.errors"><span *ngIf="error.contact_email.errors.required">¡Advertencia! Este
          campo es requerido</span></mat-error>
      <mat-error *ngIf="error.contact_email.errors"><span *ngIf="error.contact_email.errors.minlength">¡Aún falta!
          Recibimos mínimo 7 caracteres</span></mat-error>
      <mat-error *ngIf="error.contact_email.errors"><span *ngIf="error.contact_email.errors.email">¡Aún falta! debe
          tener el formato de correo electronico</span></mat-error>
    </mat-form-field>

    <mat-form-field class="col-5" appearance="fill">
      <mat-label>Fecha de cumpleaños</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="fecha_cumpleanos">
      <mat-datepicker-toggle matSuffix [for]="picker">
        <span matDatepickerToggleIcon class="fi-rr-calendar" style="font-size: 22px !important;"></span>
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>


    <div class="row col-12" style="justify-content: end; margin-top: 48px;">
      <button mat-raised-button type="button" style="float:right" class="cancelButton" (click)="opcionCancelar()">Cancelar</button>
      <button mat-raised-button type="submit" style="float:right; margin-left: 30px;" class="myButton" disabled='{{contactosForm.invalid}}'
        [mat-dialog-close]="true">Guardar</button>

    </div>
  </form>
</div>
