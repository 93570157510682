import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { SPEECH_URL } = environment;

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(private httpClient: HttpClient) { }

  /**
   * Metodo que retorna los errores generados por HTTP. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * Trae la consulta de todas las uploads realizados
   * @author Yeison Sepulveda
   * @createdate 2024-06-14
   * @param filters filtros para la búsqueda.
   * @param paginator configuración de paginación (pageSize y pageIndex).
  */

  uploadDataPaginate(filter: any, paginator: any): Observable<any> {
    const body = {  filter, per_page: paginator.pageSize, page: paginator.pageIndex };
    return this.httpClient.post<any>(`${SPEECH_URL}uploads/paginate`, body).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Cargar el documento excel y guardarlo
   * @author Yeison Sepulveda
   * @createdate 2024-06-14
   * @param filter filtros para la búsqueda.
   * @param paginator configuración de paginación (pageSize y pageIndex).
  */
  uploadData(user: string, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('user', user);
    formData.append('file', file);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.httpClient.post<any>(`${SPEECH_URL}chatCases/import`, formData, { headers }).pipe(
      catchError(this.handleError)
    );
  }


  /**
   * Trae listado por id de carga de los chats que contenia el excel
   * @author Yeison Sepulveda
   * @createdate 2024-06-14
   * @param filters filtros para la búsqueda.
   * @param paginator configuración de paginación (pageSize y pageIndex).
  */
  loadUploadDataPaginate(requestData: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}chatCases/paginate_chats`, requestData).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Obtiene listado de los chats por carga
   * @author Yeison Sepulveda
   * @createdate 2024-07-07
  */
  getListUploads(): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}chatCases/list_uploads`)
      .pipe(
        catchError(this.handleError)
      );
  }
  /**
   * Descargar plantilla de excel para poder cargar los chats
   * @author Yeison Sepulveda
   * @createdate 2024-07-07
  */
  downloadTemplate(): Observable<Blob> {
    return this.httpClient.get<any>(`${SPEECH_URL}chatCases/download_template`, {responseType: 'blob' as 'json'}).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Obtener toda la informacion de la convercacion por id
   * @author Yeison Sepulveda
   * @createdate 2024-07-07
   * @param id id del caso de conversacion
  */
  getChatById(id: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}chatCases/chat_by_id/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Descargar documentos cargados segun su id
   * @author Yeison Sepulveda
   * @createdate 2024-07-07
   * @param id id del caso de conversacion
  */
  dowloadUploadTemplate(id: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}chatCases/download_file/${id}`, { responseType: 'blob' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo validar estado analisis de los casos
   * @author Yeison Sepulveda
   * @param cases array con los id de los casos
   * @createdate 2024-02-14
  */
  chatAnalyticState(cases: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}chatCases/status_chats`,cases).pipe(
      catchError(this.handleError)
    );
  }
  /**
   * Metodo para validar estado de error en detalle
   * @author Yeison Sepulveda
   * @param cases array con los id de los casos
   * @createdate 2024-09-19
  */
  chatAnalyticStateDetail(case_id: any): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}chatCases/status_chats/error-details/${case_id}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo para crear al peticion de analisis de los chats
   * @author Yeison Sepulveda
   * @createdate 2024-02-19
   * @param body Id de los casos para analizar
  */
  chatRequestAnalytics(body: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}chatCases/analysis_request`, body).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo que retorna los resultados del analisis
   * @author Yeison Sepulveda
   * @createdate 2024-02-19
  */
  showResultsChat(id: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}chatCases/analysis_result/${id}`).pipe(
      catchError(this.handleError)
    );
  }


  /**
   * Descargar reporte de analisis de los chats
   * @author Yeison Sepulveda
   * @createdate 2024-07-15
   * @param filters json de la informacion
  */
  downloadReportChat(filters){
    return this.httpClient.post<any>(`${SPEECH_URL}chatCases/downloadReportChats`,filters, {responseType: 'blob' as 'json'}).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo mandar a reprocesar el analisis si hay error
   * @author Yeison Sepulveda
   * @param case id del caso
   * @createdate 2024-09-03
  */
  chatReAnalyticState(cases: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}chatCases/updateCaseStatus`,cases).pipe(
      catchError(this.handleError)
    );
  }


}