import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AcompañamientoModel } from 'src/app/modules/coaching/core/interfaces/acompañamiento.interface';
import { loadAcompañamientos } from 'src/app/modules/coaching/state/actions/acompañamiento.actions';
import { loadAuditoria } from 'src/app/modules/coaching/state/actions/auditoria.actions';
import { CoachingState } from 'src/app/modules/coaching/state/coaching.state';
import { listAcompañamientos } from 'src/app/modules/coaching/state/selectors/acompañamiento.selector';

@Component({
  selector: 'app-tabla-coaching',
  templateUrl: './tabla-coaching.component.html',
  styleUrls: ['./tabla-coaching.component.sass']
})
export class TablaCoachingComponent implements OnInit,OnDestroy {
  displayedColumns:string[] = [];
  dataSource = [];

  length;
  pageSize = 5;
  page = 1;
  pageSizeOptions = [5,10,25,50,100];
  @ViewChild(MatPaginator) paginator:MatPaginator;

  filter:string="";
  constructor(
    private store:Store<CoachingState>,
    private router:Router,
    private aRoute:ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.dataTable();
  }

  /**
  * @author Cristian Gallo
  * @createdate 2021-12-13
  * Metodo para ejecutar un select y obtener la información para la tabla
  */
  dataTable(){
    this.getParamsUrl();
    this.store.select(listAcompañamientos).forEach((acompañamiento:AcompañamientoModel) => {
      if (acompañamiento != null) {
        if(this.filter === "reasignado"){
          
          this.dataSource = acompañamiento.data.filter( data => data.reasignado == 1 );
        }else{

          this.dataSource = acompañamiento.data;
        }
        this.length = acompañamiento.total;
        if (acompañamiento.current_page == 1 && this.paginator) {
          this.paginator.firstPage();
        }
      }
    });
  }

  ngOnDestroy(): void {
    const params = JSON.parse(localStorage.getItem('filtros_coaching'));
    params.numeroReg = 5;
    params.page = 1;
    localStorage.setItem('filtros_coaching',JSON.stringify(params));
  }

  detailCoaching(idCoaching){
    this.router.navigateByUrl(`/mios/coaching/solicitud/${idCoaching}`);
  }

  detailAuditoria(idAuditoria){
    this.router.navigateByUrl(`/mios/coaching/auditoria/${idAuditoria}`);
  }

  detailFinal(idAuditoria){
    this.router.navigateByUrl(`/mios/coaching/finalizado/${idAuditoria}`);
  }

  pageEvent(event:any){
    const params = JSON.parse(localStorage.getItem('filtros_coaching'));
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    params.numeroReg = this.pageSize;
    params.page = this.page;
    this.store.dispatch(loadAcompañamientos(params));
    localStorage.setItem('filtros_coaching',JSON.stringify(params));
  }

  get Tab(){
    if (JSON.parse(localStorage.getItem('filtros_coaching')).tab == 12) {
      this.displayedColumns = ['acciones','id_coaching','fecha_coaching','nombre_coachee','cedula','indicador','id_auditoria','causa_raiz','reasignacion'];
    }else if (JSON.parse(localStorage.getItem('filtros_coaching')).tab == 0) {
      this.displayedColumns = ['acciones','id_coaching','fecha_coaching','nombre_coachee','cedula','indicador','id_auditoria','causa_raiz','dias_restantes','estado', 'reasignacion'];
    }
    return JSON.parse(localStorage.getItem('filtros_coaching')).tab;
  }


    /**
   * Metodo que carga los datos por URL para validacion y pbtencion de datos apra carga de tabla
   * @author Juan David Guerrero Vargas
   * @create_date 02/06/2022
   * @return void   
   */
     getParamsUrl():void{
      this.aRoute.paramMap.subscribe(params => {
        this.filter = params.get('filter');
      });
  
    }

}
