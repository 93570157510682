import { Component, ElementRef, HostListener, Inject, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ResultService } from '../../../services/result.service';
import { RequestService } from '../../../services/request.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { CampaignService } from '../../../services/campaign.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-transcription-strategy-modal',
  templateUrl: './transcription-strategy-modal.component.html',
  styleUrls: ['./transcription-strategy-modal.component.sass']
})
export class TranscricpcionStrategyComponent implements OnInit {
  
  @Input() palabrasClave: string[] = [];
  @ViewChild('audioPlayer') audioPlayer: ElementRef<HTMLAudioElement>;
  @ViewChild('containerViewReport') containerViewReport: ElementRef;

  @ViewChild('transcripcionPanel') transcripcionPanel: MatExpansionPanel;
  @ViewChild('tiemposMuertosPanel') tiemposMuertosPanel: MatExpansionPanel;
  @ViewChild('resumenPanel') resumenPanel: MatExpansionPanel;


  diarization: any;
  llmResponse: any;
  data: any[] = [];
  private interactions:any[]=[]
  interactionsFormtat:any[] = []

  dataResponse: any;
  jsonInteraccion: any;

  audioUrl: any;
  recordingID: any;

  id: any; //id resultados

  //duracion del audio
  audioDuration: number;
  audioDurationMinutos = 0;
  audioDurationSegundos = 0;
  deadTimeaudioDurationMinutos = 0;
  deadTimeaudioDurationSegundos = 0;

  //audio
  start: number=0;
  end: number=0;
  audioEventListener: any;
  dead_time:any;
  
  //dirizacion, tiempos muertos 
  palabrasClaveArray: string[] = [];
  totalDuracionTiemposMuertos: number = 0;
  tiemposMuertos: any[] = [];
  tiemposMuertosMayores: any[] = [];

  @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef;

  campaign_id: any;
  containerViewMail: any;
  deadTimeaudioDurationFormatted: string;
  audioDurationHoras: number;
  audioDurationFormatted: string;

  campaignViciadial: any; //id de la campaña seleccionada
  llmResponseObject: any; // respuesta del resumen
  //variables respuestas
  resumen: any;
  entidadesPrincipales: any;
  temasPrincipales: any;
  puntosClave: any;
  analisisDeSentimientos: any;

  constructor( 
    @Inject(MAT_DIALOG_DATA) public date: any,
    public dialogRef: MatDialogRef<TranscricpcionStrategyComponent>,
    private resultService: ResultService,
    private requestService: RequestService,
    private authService: AuthService,
    private campaignServdce: CampaignService,
    private sanitazer:DomSanitizer,  
  ) {
      this.recordingID=date.recordingId;
      this.campaignViciadial= date.campaignVicidial_id;
    }

  ngOnInit(): void {
    this.dead_time = 30;
    this.getResultados();
    this.audioUrl = this.requestService.getAudioUrl(this.recordingID);
    const user = this.authService.getUser();
    this.campaign_id=user.rrhh.campaign_id;
    this.obtenerDuracionTotalAudio();
  }

  /**
   * Método donde se establecen las validaciones del formulario y adviarte antes de refrescar 
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @method unloadNotification
   * @param {Event} $event - El evento `beforeunload` del navegador.
   * @returns {void}
  */
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    $event.returnValue = true;
  }

  /**
   * Metodo que reinicia los valores del modal. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  cancelSave(): void {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Si cancelas el proceso, perderás toda la información no guardada.',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.dialogRef.close();
      }
    });
  }

  getdataCampaign(): void {
    this.campaignServdce.showCampaign(this.campaignViciadial).subscribe(
      (rest) => {
        this.dead_time = rest.data.dead_time;
        this.getResultados();
      },
      (error) => {
        console.error('Error al recuperar la campaña:', error.message);
        Swal.fire({
          icon: 'error',
          title: 'Error al recuperar la campaña',
          text: error.message,
          confirmButtonText: '¡Entendido!',
          confirmButtonColor: '#2CABBC',
        });
      }
    );
  }

  /**
  * Metodo que establece los tiempos de inicio y fin y controla la reproducción de un audio.
  * @param {number} start - Tiempo de inicio en segundos.
  * @param {number} end - Tiempo de fin en segundos.
  * @returns {void}
  * @author Yeison Sepulveda
  * @createdate 27/02/2024
  */

  logStartAndEnd(start: number, end: number): void {
    this.start = start;
    this.end = end;
  
    if (this.audioPlayer) {
      this.audioPlayer.nativeElement.currentTime = this.start;
      this.audioPlayer.nativeElement.play();
  
      const timeUpdateListener = () => {
        if (this.audioPlayer.nativeElement.currentTime >= this.end) {
          this.audioPlayer.nativeElement.pause();
          //despues de pausar crea un nuevo objeto resetea el tiempo limite al total del audio
          const audio = new Audio(this.audioUrl);
          audio.addEventListener('loadedmetadata', () => {
            const durationInSeconds = audio.duration;
            this.end=durationInSeconds;
          })
        }
      };
      this.audioPlayer.nativeElement.addEventListener('timeupdate', timeUpdateListener);
    } else {
    }
  }

  /**
   * Subraya las palabras clave dentro de un texto.
   * @author Yeison Sepulveda
   * @createdate 27/02/2024
   * @param {string} text - El texto en el que se buscarán y resaltarán las palabras clave.
   * @param {string} term - La palabra clave que se debe resaltar.
   * @returns {string} El texto con las palabras clave resaltadas.
  */
  highlightedTerms: Set<string> = new Set();

  highlightedText(text: string, terms: Set<string>): string {
    if (!terms || terms.size === 0) {
      return text;
    }

    const styleCss = 'padding: 0.1rem 0.4rem; background-color: #00acc1; color: #fff; border-radius: 50px;';
    terms.forEach(term => {
      if (term && term.trim()) {
        const regex = new RegExp(`\\b${term}\\b`, 'gi');
        text = text.replace(regex, `<span style='${styleCss}'>$&</span>`);
      }
    });

    return text;
  }

  /**
 * Elimina el resaltado de las palabras clave dentro de un texto resaltado.
 * @author Yeison Sepulveda
 * @createdate 20/05/2024
 * @param {string} text - El texto con las palabras clave resaltadas.
 * @returns {string} El texto sin el resaltado de las palabras clave.
 */


  removeHighlight(text: string): string {
    const spanRegex = /<span style='padding: 0.1rem 0.4rem; background-color: #00acc1; color: #fff; border-radius: 50px;'>(.*?)<\/span>/g;
    return text.replace(spanRegex, '$1');
  }

  /**
 * Verifica si un texto está resaltado.
 * @author Yeison Sepulveda
 * @createdate 20/05/2024
 * @param {string} text - El texto que se quiere verificar si está resaltado.
 * @returns {boolean} Verdadero si el texto está resaltado, falso si no lo está.
 */

  isTextHighlighted(text: string): boolean {
    const styleCss = 'padding: 0.1rem 0.4rem; background-color: #00acc1; color: #fff; border-radius: 50px;';
    return text.includes(styleCss);
  }

    /**
   * Metodo para los chips en los cuales busca las palabras y los subraya
   * @author Yeison Sepulveda
   * @createdate 27/02/2024
   */

  toggleHighlight(palabra: string): void {
    if (this.highlightedTerms.has(palabra)) {
      this.highlightedTerms.delete(palabra);
    } else {
      this.highlightedTerms.add(palabra);
    }

    this.interactionsFormtat = this.interactions.map(interaction => {
      let text = interaction.text;

      // Primero elimina todos los resaltados para una actualización completa
      text = this.removeHighlight(text);

      // Aplica los resaltados actuales
      text = this.highlightedText(text, this.highlightedTerms);

      return { ...interaction, text: this.sanitazer.bypassSecurityTrustHtml(text) };
    });
  }

  /**
   * Metodo para consumir y obtener los resultados
   * @author Yeison Sepulveda
   * @createdate 27/02/2024
   */

  getResultados(): void {
    this.resultService.showAnalyticsResults(this.recordingID).subscribe(
      result => {
        const jsonData = JSON.parse(result.data.diarization.interaccion); 
        this.interactions = Array.isArray(jsonData) ? jsonData : [jsonData]; 
        this.interactionsFormtat = Array.isArray(jsonData) ? jsonData : [jsonData]; 
  
        const jsonDataDeadTimes = JSON.parse(result.data.deadtimes.tiempos_muertos); 
        this.tiemposMuertos = Array.isArray(jsonDataDeadTimes) ? jsonDataDeadTimes : [jsonDataDeadTimes]; 


        this.llmResponseObject = result.data.llm_response; 
        this.palabrasClaveArray = this.llmResponseObject.palabras_clave;

        this.resumen = result.data.llm_response.resumen;
        this.palabrasClave = result.data.llm_response.palabras_clave;
        this.entidadesPrincipales = result.data.llm_response.entidades_principales;
        this.temasPrincipales = result.data.llm_response.temas_principales;
        this.puntosClave = result.data.llm_response.puntos_clave;
        this.analisisDeSentimientos = result.data.llm_response.analisis_de_sentimientos;

        // Asume que el `id` está en `result.data.id`
        this.id = result.data.id;

        // Lógica tiempos mayores segun tiempo muerto campaña
        const deadTimeFloat = parseFloat(this.dead_time);
        this.tiemposMuertosMayores = this.tiemposMuertos.filter(tiempo => {
          const duracion = tiempo.end - tiempo.start;
          return duracion > deadTimeFloat; // Comparar con el valor float
        });
  
        this.totalDuracionTiemposMuertos = parseFloat(this.tiemposMuertos.reduce((total, tiempo) => {
          return total + (tiempo.end - tiempo.start);
        }, 0).toFixed(3));
  
        this.deadTimeaudioDurationMinutos = Math.floor(this.totalDuracionTiemposMuertos / 60);
        this.deadTimeaudioDurationSegundos = Math.round(this.totalDuracionTiemposMuertos % 60);
  
        // Formatear el tiempo en el formato hh:mm:ss
        const formatTime = (time: number): string => {
          const hours = Math.floor(time / 3600);
          const minutes = Math.floor((time % 3600) / 60);
          const seconds = Math.floor(time % 60);
          return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
        };
  
        // Formatear los tiempos muertos
        this.deadTimeaudioDurationFormatted = formatTime(this.totalDuracionTiemposMuertos);
  
      },
      error => {
        console.error('Error al recuperar los resultados del análisis:', error.message);
        Swal.fire({
          icon: 'error',
          title: 'Error al recuperar los resultados del análisis:',
          text: error.message,
          confirmButtonText: '¡Entendido!',
          confirmButtonColor: '#2CABBC',
        });
      }
    );
  }

  /**
   * Metodo en el cual valida la totalidad del tiempo del audio
   * @author Yeison Sepulveda
   * @createdate 27/02/2024
   * @returns 
   */

  obtenerDuracionTotalAudio() {
    const audio = new Audio();
    audio.src = this.audioUrl;
    audio.onloadedmetadata = () => {
      this.audioDuration = audio.duration;
  
      // Formatear la duración total del audio en el formato hh:mm:ss
      this.audioDurationFormatted = this.formatTime(this.audioDuration);
    };
  }

  /**
   * Formatea un objeto de tiempo en una cadena de texto en formato de hora (HH:MM:SS).
   * @author Yeison Sepulveda
   * @createdate 22/04/2024
   * @param {Object} tiempo - Objeto que contiene los campos 'start' y 'end' que representan los tiempos de inicio y fin en segundos.
   * @param {number} tiempo.start - Tiempo de inicio en segundos.
   * @param {number} tiempo.end - Tiempo de fin en segundos.
   * @returns {string} Una cadena de texto en formato de hora (HH:MM:SS) que representa el intervalo de tiempo entre el tiempo de inicio y el tiempo de fin.
   */

  formatTiempo(tiempo: any): string {
    const formatTime = (time: number): string => {
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time % 3600) / 60);
      const seconds = Math.floor(time % 60);
      return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    };
  
    const startFormatted = formatTime(tiempo.start);
    const endFormatted = formatTime(tiempo.end);
  
    return `${startFormatted} - ${endFormatted}`;
  }

  calcularDuracion(start: number, end: number): string {
    const durationInSeconds = end - start;
    return this.formatTime(durationInSeconds);
  }


/**
 * Formatea un número de segundos en una cadena de texto en formato de hora (HH:MM:SS).
 * @author Yeison Sepulveda
 * @createdate 22/04/2024
 * @param {number} time - El número de segundos a formatear.
 * @returns {string} Una cadena de texto en formato de hora (HH:MM:SS).
 */
  formatTime(time: number): string {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  }
  

  /**
   * Validar el posicionamiento para graficar los tiempos muertos
   * @author Yeison Sepulveda
   * @createdate 27/02/2024
   * @returns void {left: posicionInicio + '%', width: longitud + '%'}    
   */

  calcularPosicionSegmento(segmento: any): { left: string, width: string } {
    const duracionTotal = this.audioDuration;
    const posicionInicio = (segmento.start / duracionTotal) * 100;
    const longitud = ((segmento.end - segmento.start) / duracionTotal) * 100;
    return { left: posicionInicio + '%', width: longitud + '%' };
  }


}
