<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>
<h3>Asignar Analista</h3>
<hr>
<mat-dialog-content>
    <div class="assig">
        <mat-form-field appearance="fill">
            <mat-label>Analistas</mat-label>
            <mat-select #analyst (selectionChange)="selectAnalyst(analyst.value)">
                <mat-option *ngFor="let row of analysts" [value]="row.id">
                    {{row.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayout="column" >
        <div *ngIf="dates.length > 0" >
            <h4>Agenda Analista</h4>
        </div>
        <div  fxLayout="row" fxLayoutGap="24px grid" class="schecule">
            <div *ngFor="let date of dates" >
                <mat-card >
                    <mat-card-header></mat-card-header>
                    <div class="_content">
                        <div class="_title">
                            <h3>{{ date.date | date:'EEEE'}}</h3>
                            <span mat-card-subtitle> {{ date.date | date:'MMMM d, y'}}</span>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between center" >
                            <div><b>
                                <button mat-icon-button  (click)="showCandidates(date.date,date.candidateIds)"
                                matTooltip="Ver los candidatos agendados">
                                    <span class="fi-rr-eye"></span>
                                </button>
                                Candidatos</b>
                            </div>
                            <span>{{date.candidates}} / {{date.availability}}</span>
                        </div>
                        <mat-card-actions align="end">
                            <button mat-icon-button color="primary" (click)="selectDate(date, end)" matTooltip="Agendar en esta fecha"> 
                               <span class="fi-rr-arrow-right"></span>
                            </button>
                        </mat-card-actions>
                    </div>
                    
                </mat-card>
            </div>
        </div>

    </div>
    <div class="bot-content" id='candidatesDiv' *ngIf="candidatesList">
        <p *ngIf="candidatesByDay.length == 0" >No hay candidatos asignados para este día</p>
        <div *ngIf="candidatesByDay.length > 0"  >
            <div class="title_section" fxLayout="row" fxLayoutAlign="space-between start">
                <h4>Candidatos asignados para el dia &nbsp; <br> <span>{{ dateSelectedToCandidates | date:'EEEE, MMM d, y'}}</span></h4> 
                <!-- <button mat-icon-button (click)="clearCandidateByDay()" matTooltip="Ocultar">  <i class="fi-sr-cross-small"></i> </button> -->
            </div>
            <mat-chip-list>
                <mat-chip *ngFor="let candidate of candidatesByDay">{{ candidate.name }} ({{candidate.requestCode}})</mat-chip>
            </mat-chip-list>
         
        </div>
    </div>
   
    

    <div fxLayout="column" *ngIf="daleSelected" class="bot-content" id='botDiv'>
        <div class="title_section">
            <h4>Fecha Seleccionada &nbsp; <span>{{daleSelected | date:'EEEE, MMMM d, y,'}}</span></h4> 
        </div>
       
        <mat-selection-list #candidateSelected>
            <mat-list-option checkboxPosition *ngFor="let candidate of candidates" (click)="pushArr(candidate)" [disabled]="candidate.disabled">
                {{candidate.fullname}} <small *ngIf="candidate.scheduled">(Programado)</small>
            </mat-list-option>
        </mat-selection-list>
         <br>
         <div style="text-align: center;">
            <p *ngIf="candidates.length == 0">No hay candidatos disponibles ni asignados</p>
            <p *ngIf="candidates.length > 0">Candidatos seleccionados {{candidateSelected.selectedOptions.selected.length}}</p> 
         </div>
        
    </div>

    <div fxLayout="column" *ngIf="reAssingSection">
        <hr>
        <div class="title_section">
            <h4 >Seleccione una nueva fecha para re-asignar a los candidatos seleccionados</h4>
        </div>
        <mat-form-field appearance="fill">
            <mat-label>Seleccione una nueva fecha</mat-label>
            <mat-select #reAssingDate (selectionChange)="reAssingDateSelect(reAssingDate.value)">
                <mat-option *ngFor="let row of dates" [value]="row.date">
                    {{ row.date | date:'dd/MM/yyyy'}} ({{row.candidates}} / {{row.availability}})
                </mat-option>
            </mat-select>
        </mat-form-field>
    
    </div> 
    <div #end></div>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button  mat-stroked-button type="button" *ngIf="reScheduled && !reAssingSection" [disabled]="candidatesSelected.length == 0" (click)="cancelSchedules()">Cancelar</button>

    <button color="primary" mat-flat-button (click)="reAssgignSave()" *ngIf="reAssingSection" [disabled]="!reAssingDateSelected">Asignar </button>
    <button color="primary" mat-flat-button (click)="reAssgign()" *ngIf="reScheduled && !reAssingSection" [disabled]="candidatesSelected.length == 0">Re-Asignar</button>
    <button color="primary" mat-flat-button (click)="setAnalyst()" *ngIf="!reScheduled" [disabled]="candidatesSelected.length == 0">Asignar</button>
</mat-dialog-actions>
