import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CandidatesService} from '../../../services/candidates.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import Swal from 'sweetalert2';
import {Tools} from '../../../../calidad/utils/tools.utils';

@Component({
  selector: 'app-attachment-delete-form',
  templateUrl: './attachment-delete-form.component.html',
  styleUrls: ['./attachment-delete-form.component.sass']
})
export class AttachmentDeleteFormComponent implements OnInit {

  public deleteForm: FormGroup;
  causes: any;

  constructor(
    private candidateService: CandidatesService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AttachmentDeleteFormComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.getCauses();
  }


  private buildForm() {
    this.deleteForm = new FormGroup({
      cause : new FormControl('', [Validators.required]),
      comment : new FormControl('', [
        Validators.maxLength(200),
      ]),
    });

  }

  get error(): any { return this.deleteForm.controls; }

  onSubmit() {
    Swal.fire({
      icon: 'warning',
      title: '¿Está seguro de asignar este estado?',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Si',
      reverseButtons: true,
      width: '700px',
      padding: '2em 2em',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          id: this.data.file.id,
          cause: this.deleteForm.get('cause').value,
          comment: this.deleteForm.get('comment').value,
        };

        this.candidateService.deleteDocumentAttachment( body ).subscribe(resp => {
          if (resp === 'Se elimino el archivo correctamente') {
            Tools.swalSuccess('¡Excelente!', 'El archivo se ha eliminado con éxito');
          } else {
            Swal.fire({
              icon: 'error',
              title: '¡Oops!',
              text: 'Lo sentimos ha sucedio un error',
              showConfirmButton: false,
              timer: 2500
            });
          }
        });
      }
      this.dialogRef.close();
    });
  }

  private getCauses() {
    this.candidateService.getCausesToDeleteFile().subscribe(resp => {
      this.causes = resp;
    });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
