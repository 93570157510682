import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChatService } from '../../../services/chat.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import * as html2pdf from 'html2pdf.js';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-analytics-chat-modal',
  templateUrl: './analytics-chat-modal.component.html',
  styleUrls: ['./analytics-chat-modal.component.sass']
})
export class AnalyticsChatModalComponent implements OnInit {

  @ViewChild('containerViewReport') containerViewReport: ElementRef;
  @ViewChild('transcripcionPanel') transcripcionPanel: MatExpansionPanel;
  @ViewChild('resumenPanel') resumenPanel: MatExpansionPanel;
  @ViewChild('analisisTexto') analisisTexto: MatExpansionPanel;

  highlightedTerms: Set<string> = new Set();
  interactions: any[] = [];
  palabrasClaveArray: string[] = [];
  data: any;
  case_id: any;
  llmResponseObject: any;
  editMode = false;

  chatData: any;
  interactionsFormat: any[];
  sentimientosDataPorSpeaker: any[];
  sentimientosData: { name: string; value: number; label: string; }[];


  //propiedades grafica
  view: any[] = [700, 400];  // Tamaño de la gráfica
  showLegend = true;
  showLabels = true;
  isDoughnut = false;
  gradient = false;
  colorScheme2 = {
    domain: ['#1565C0', '#B0BEC5', '#FFF176']
  };
  selectedInteractions: any[] = [];  // Almacena las interacciones filtradas
  modificarInteracciones: boolean = false; //modificar iteraciones para anlisis
  selectAll: boolean = true; //sekecciona todas las iteracciones
  indeterminate: boolean = false; //validas si hay interacciones indeterminadas

  constructor(  
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private chatService: ChatService,
    private sanitazer: DomSanitizer) 
    {}
    
  ngOnInit(): void {
    this.case_id = this.dialogData.id;
    this.loadChatData();
  }

  /**
   * Metodo para cargar los datos del chat y las interacciones formatadas.
   * @author Yeison Sepulveda
   * @createdate 2024-07-16
 */
  
  loadChatData(): void {
    this.chatService.getChatById(this.case_id).subscribe(
      (response) => {
        this.chatData = response.data[0];
  
        this.chatService.showResultsChat(this.case_id).subscribe(res => {
          this.llmResponseObject = res.data[0].llm_response;
          this.palabrasClaveArray = this.llmResponseObject.palabras_clave;
          this.interactions = this.mergeInteractions(this.chatData.chat_details, res.data[0].analysis_feelings);
  
          this.interactionsFormat = this.interactions;
          
          this.interactionsFormat.forEach(interaction => {
            interaction.selected = true; // Inicialmente todas seleccionadas
          });
          
          this.calcularPorcentajes(this.interactions);
        });
      },
      (error) => {
        console.error('Error al consultar los resultados de análisis', error);
      }
    );
  }
  

  /**
   * Combina los detalles del chat con los sentimientos analizados basados en el ID.
   * @updated Yeison Sepulveda
   * @createdate 2024-07-16
   * @param {any[]} chatDetails - Detalles del chat a ser combinados.
   * @param {any[]} analysisFeelings - Sentimientos analizados a ser combinados.
   * @returns {any[]} Un arreglo de interacciones combinadas.
 */
  mergeInteractions(chatDetails: any[], analysisFeelings: any[]): any[] {
    return chatDetails.reduce((mergedInteractions, detail) => {
      const matchingAnalysis = analysisFeelings.find(analysis => analysis.id === detail.id);
  
      if (matchingAnalysis) {
        //vrificar si el texto en analysisFeelings está vacío
        const isTextEmptyInAnalysis = !matchingAnalysis.text || matchingAnalysis.text.trim().length === 0;
  
        //verificar si el texto contiene palabras repetidas
        const isTextRepetitive = this.validateText(matchingAnalysis.text || detail.content);
  
        //validar el texto está vacío o contiene repeticiones, ajustar sentimiento a NEU y probabilidades a neutralidad
        const adjustedSentimiento = (isTextEmptyInAnalysis || isTextRepetitive) ? 'NEU' : matchingAnalysis.sentimiento;
        const sentimentProbabilities = (isTextEmptyInAnalysis || isTextRepetitive) ? { NEG: 0, NEU: 1, POS: 0 } : matchingAnalysis.probabilidad_sentimiento;
  
        //palabras clave y el tooltip del sentimiento
        const palabrasClave = this.palabrasClaveArray.filter(palabra => detail.content.includes(palabra));
        const sentimentTooltip = this.getSentimentTooltip(adjustedSentimiento);
  
        mergedInteractions.push({
          ...detail,
          ...matchingAnalysis,
          text: detail.content, 
          palabrasClave: palabrasClave,
          probabilidadSentimiento: sentimentProbabilities,
          sentimiento: adjustedSentimiento, 
          sentimentEmoji: sentimentTooltip.emoji,
          sentimentMessage: `${sentimentTooltip.message} ${sentimentTooltip.emoji}`
        });
      }
      return mergedInteractions;
    }, []);
  }
  
  /**
   * Metodo para detectar textos repetitivos
   * @author Yeison Sepulveda
   * @createdate 28-08-2024
   */
  validateText(text: string): boolean {
    if (!text) return false;
    const repetitivePattern = /(.)\1{3,}/;
    return repetitivePattern.test(text);
  }
  /**
   * Metodo para los chips en los cuales busca las palabras y los subraya
   * @author Yeison Sepulveda
   * @createdate 27/02/2024
   */
  toggleHighlight(palabra: string): void {
    if (this.highlightedTerms.has(palabra)) {
      this.highlightedTerms.delete(palabra);
    } else {
      this.highlightedTerms.add(palabra);
    }
  
    this.interactionsFormat = this.interactions.map(interaction => {
      let text = interaction.text;
  
      text = this.removeHighlight(text);
  
      text = this.highlightedText(text, this.highlightedTerms);
  
      return { ...interaction, text: this.sanitazer.bypassSecurityTrustHtml(text) };
    });
  
  }
  
  /**
   * Elimina el resaltado de las palabras clave dentro de un texto resaltado.
   * @author Yeison Sepulveda
   * @createdate 20/05/2024
   * @param {string} text - El texto con las palabras clave resaltadas.
   * @returns {string} El texto sin el resaltado de las palabras clave.
  */
  
  removeHighlight(text: string): string {
    const spanRegex = /<span style='padding: 0.1rem 0.4rem; background-color: #00acc1; color: #fff; border-radius: 50px;'>(.*?)<\/span>/g;
    return text.replace(spanRegex, '$1');
  }


  /**
   * Subraya las palabras clave dentro de un texto.
   * @author Yeison Sepulveda
   * @createdate 27/02/2024
   * @param {string} text - El texto en el que se buscarán y resaltarán las palabras clave.
   * @param {string} term - La palabra clave que se debe resaltar.
   * @returns {string} El texto con las palabras clave resaltadas.
  */

  highlightedText(text: string, terms: Set<string>): string {
    if (!terms || terms.size === 0) {
      return text;
    }

    const styleCss = 'padding: 0.1rem 0.4rem; background-color: #00acc1; color: #fff; border-radius: 50px;';
    terms.forEach(term => {
      if (term && term.trim()) {
        const regex = new RegExp(`\\b${term}\\b`, 'gi');
        text = text.replace(regex, `<span style='${styleCss}'>$&</span>`);
      }
    });

    return text;
  }

  /**
   * Método que devuelve un tooltip con un emoji y un mensaje correspondiente a un sentimiento dado.
   * @author Yeison Sepulveda
   * @createdate 27-06-2024
   * @param {string} sentimiento - El sentimiento para el cual se desea obtener el tooltip.
   * @returns {{ emoji: string, message: string }} - Un objeto con un emoji y un mensaje correspondientes al sentimiento.
   */
  getSentimentTooltip(sentimiento: string): { emoji: string, message: string } {
    switch (sentimiento) {
      case 'NEG':
        return { emoji: '😡', message: 'Negativo' };
      case 'NEU':
        return { emoji: '😐', message: 'Neutral' };
      case 'POS':
        return { emoji: '😊', message: 'Positivo' };
      default:
        return { emoji: '😐', message: 'Neutral' };
    }
  }

  /**
   * Metodo descarga e imprime pantallazo del modal
   * @update Yeison Sepulveda
   * @updatedate 29-05-2024
  */
  async onClickPrintPdf() {
    if (this.modificarInteracciones) {
      this.modificarInteracciones = false;
    }
    this.transcripcionPanel.open();
    this.resumenPanel.open();
    this.analisisTexto.open();
    await new Promise(resolve => setTimeout(resolve, 1000));
    const content = this.containerViewReport.nativeElement;
  
    const margin = 13;
    const filename = this.generateFilename();
  
    const options = {
      margin: margin,
      filename: filename,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 2,
      },
      jsPDF: {
        unit: 'mm',
        format: 'tabloid',
        orientation: 'portrait'
      },
      pagebreak: {
        mode: ['avoid-all', 'css', 'legacy']
      }
    };
  
    const testPDF = html2pdf().from(content).set(options).toContainer().toCanvas().toImg().toPdf();
    testPDF.get('pdf').then(function (pdf) {
      const totalHeight = content.scrollHeight * 0.264583 + 2 * margin;
      pdf.internal.pageSize = { width: 430, height: totalHeight };
    });
  
    testPDF.save();
  }


  /**
   * Metodo generar el nombre del documento a descargar 
   * @author Yeison Sepulveda
   * @createdate 2024-0-03
   * @return nombre del archivo PDF
  */
  
  generateFilename(): string {
    const currentDate = new Date().toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '');
  
    const currentTime = new Date().toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).replace(/:/g, '');
  
    const dateTimeString = `${currentDate}${currentTime}`;
    return `reporte_analisis_${this.case_id}_${dateTimeString}.pdf`;
  }

  /**
   * Método para formatear datos por speaker en base a los sentimientos.
   * @author Yeison Sepulveda
   * @createdate 28-06-2024
   * @param data - Los datos de sentimientos por speaker.
   * @param totalInteraccionesPorSpeaker - El número total de interacciones por speaker.
   * @returns Un array de datos formateados por speaker.
   */

  private formatSentimientosPorSpeaker(data: { [key: string]: any }, totalInteraccionesPorSpeaker: { [key: string]: number }): any[] {
    const formattedData = [];
  
    Object.keys(data).forEach(speaker => {
      if (speaker) {
        const speakerTotalInteracciones = totalInteraccionesPorSpeaker[speaker];
        formattedData.push(
          {
            name: speaker,
            series: [
              { name: 'Negativo', value: +(data[speaker].NEG / speakerTotalInteracciones * 100).toFixed(2), label: `${((data[speaker].NEG / speakerTotalInteracciones) * 100).toFixed(2)}%` },
              { name: 'Neutral', value: +(data[speaker].NEU / speakerTotalInteracciones * 100).toFixed(2), label: `${((data[speaker].NEU / speakerTotalInteracciones) * 100).toFixed(2)}%` },
              { name: 'Positivo', value: +(data[speaker].POS / speakerTotalInteracciones * 100).toFixed(2), label: `${((data[speaker].POS / speakerTotalInteracciones) * 100).toFixed(2)}%` }
            ]
          }
        );
      }
    });
    console.log('Formatted Data por Speaker:', formattedData);
    return formattedData;
  }
  
  /**
   * Método que calcula los porcentajes de emociones y sentimientos en las interacciones.
   * @author Yeison Sepulveda
   * @createdate 28-06-2024
   * @param {any[]} interactions - Un array de interacciones que contiene emociones y sentimientos.
   */

  calcularPorcentajes(interactions: any[]): void {
    const sentimientoContadores = { NEG: 0, NEU: 0, POS: 0 };
    const totalInteracciones = interactions.length;

    if (totalInteracciones === 0) {
      // Si no hay interacciones, se asigna valor neutral a todos los sentimientos
      this.sentimientosData = [
        { name: 'Negativo', value: 0, label: '0%' },
        { name: 'Neutral', value: 100, label: '100%' },
        { name: 'Positivo', value: 0, label: '0%' }
      ];
      this.sentimientosDataPorSpeaker = [];
      return;
    }
      
    //contador por speaker y sus totales
    const sentimientoContadoresPorSpeaker: { [key: string]: any } = {};
    const totalInteraccionesPorSpeaker: { [key: string]: number } = {};
  
    interactions.forEach(interaction => {
      const sentimiento = interaction.sentimiento;
      const speaker = interaction.answered_by === 'Agente' ? interaction.agent_name : 'Cliente';
  
      if (speaker) {
        //contador general
        if (sentimientoContadores[sentimiento] !== undefined) {
          sentimientoContadores[sentimiento]++;
        }
  
        //contador por speaker
        if (!sentimientoContadoresPorSpeaker[speaker]) {
          sentimientoContadoresPorSpeaker[speaker] = { NEG: 0, NEU: 0, POS: 0 };
        }
        if (!totalInteraccionesPorSpeaker[speaker]) {
          totalInteraccionesPorSpeaker[speaker] = 0;
        }
  
        if (sentimientoContadoresPorSpeaker[speaker][sentimiento] !== undefined) {
          sentimientoContadoresPorSpeaker[speaker][sentimiento]++;
        }
  
        //contador para interacciones
        totalInteraccionesPorSpeaker[speaker]++;
      }
    });

    // Datos generales
    this.sentimientosData = [
      { name: 'Negativo', value: (sentimientoContadores.NEG / totalInteracciones) * 100, label: `${((sentimientoContadores.NEG / totalInteracciones) * 100).toFixed(2)}%` },
      { name: 'Neutral', value: (sentimientoContadores.NEU / totalInteracciones) * 100, label: `${((sentimientoContadores.NEU / totalInteracciones) * 100).toFixed(2)}%` },
      { name: 'Positivo', value: (sentimientoContadores.POS / totalInteracciones) * 100, label: `${((sentimientoContadores.POS / totalInteracciones) * 100).toFixed(2)}%` }
    ];

    console.log('Datos Generales de Sentimientos:', this.sentimientosData)
  
    //resultados por speaker
    this.sentimientosDataPorSpeaker = this.formatSentimientosPorSpeaker(sentimientoContadoresPorSpeaker, totalInteraccionesPorSpeaker);
  }

  /**
   * Método asignar valor del label a la grafica de torta
   * @author Yeison Sepulveda
   * @createdate 2024-07-17
   */
  pieChartLabel(series: any[], name: string): string {
    const item = series.find(data => data.name === name);
    return item ? item.label : name;
  }

  /**
   * Metodo para manejar los cambios de selección en las interacciones.
   * @author Yeison Sepulveda
   * @createdate 2024-08-27
   */
  onSelectionChange(): void {
    this.selectedInteractions = this.interactionsFormat.filter(item => item.selected);
    this.updateGraphs();
    this.updateCheckboxState(); 
  }

  /**
   * Metodo para actualizar la informacion de las graficas basadas en las interacciones seleccionadas
   * @author Yeison Sepulveda
   * @createdate 2024-08-27
   */
  updateGraphs() {
    const selectedInteractions = this.selectedInteractions; 
    this.calcularPorcentajes(selectedInteractions);
  }

  /**
   * Metodo para actualizar la informacion de las graficas basadas en las interacciones seleccionadas
   * @author Yeison Sepulveda
   * @createdate 2024-08-27
   */
  toggleModificacion() {
    if (!this.modificarInteracciones) {
      Swal.fire({
        title: '¿Estás seguro?',
        text: 'Podrás desactivar interacciones y no se tendrán en cuenta en el análisis de las gráficas. Estos cambios son temporales.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2CABBC',
        cancelButtonColor: '#FFFFFF',
        confirmButtonText: 'Sí, continuar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.modificarInteracciones = true;
        }
      });
    } else {
      Swal.fire({
        title: 'Advertencia',
        text: 'Recuerda que si deseleccionas algo, no se tendrá en cuenta para el análisis de las gráficas. Los cambios son temporales y puedes reactivarlos si lo deseas.',
        icon: 'warning',
        confirmButtonColor: '#2CABBC',
        confirmButtonText: '¡Entendido!'
      }).then(() => {
        this.modificarInteracciones = false;
      });
    }
  }

  /**
   * Metodo para Manejar el cambio en el checkbox global
   * @author Yeison Sepulveda
   * @createdate 2024-08-28
   */
  toggleSelectAll(checked: boolean): void {
    this.interactionsFormat.forEach(item => item.selected = checked);
    this.onSelectionChange(); 
    this.updateCheckboxState();
  }
  
  /**
   * Metodo para actualizar el estado del checkbox global
   * @author Yeison Sepulveda
   * @createdate 2024-08-28
   */
  updateCheckboxState(): void {
    const allSelected = this.interactionsFormat.every(item => item.selected);
    const noneSelected = this.interactionsFormat.every(item => !item.selected);

    this.selectAll = allSelected;
    this.indeterminate = !allSelected && !noneSelected;
  }

  /**
   * Metodo para manejar estado indeterminado 
   * @author Yeison Sepulveda
   * @createdate 2024-08-28
   */
  isIndeterminate(): boolean {
    return this.indeterminate;
  }
  
}
