import { PermitViewComponent } from './pages/permit-view/permit-view.component';
import { PermitRoutingModule } from './permit-routing.module';
import { PermitListComponent } from './pages/permit-list/permit-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { PermitResponseComponent } from './pages/permit-response/permit-response.component';
import { PermitConsultantComponent } from './pages/permit-consultant/permit-consultant.component';

@NgModule({
  imports: [
    CommonModule,
    PermitRoutingModule,
    MaterialModule 
  ],
  declarations: [PermitListComponent, PermitListComponent, PermitViewComponent, PermitResponseComponent,PermitConsultantComponent]
})
export class PermitModule { }