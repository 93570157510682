import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/core/services/rest/auth.service';

const NOMINA_URL = environment.NOMINA_URL;
const RRHH_URL = environment.RRHH_URL;
const JURIDICA_URL = environment.JURIDICA_URL;

@Injectable({
  providedIn: 'root'
})
export class JuridicaService {

  constructor(private http: HttpClient, private AuthService: AuthService) {
  }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener los permisos
   */
  getPermits(id) {
    const httpOptions = {
      params: new HttpParams().set('state', id)
    };
    return this.http.get<any>(`${NOMINA_URL}permissions/getPermissions`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener los permisos por filtro
   */
  getPermitsBy(state?: any, idTipDoc?: any, document?: any, campaign_id?: any, novelty_date1?: any, novelty_date2?: any, perPage: any = 5, Page?: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('state', state)
        .set('document', document || '')
        .set('documentType', idTipDoc || 1)
        .set('campaign_id', campaign_id || '')
        .set('novelty_date1', novelty_date1 || '')
        .set('novelty_date2', novelty_date2 || '')
        .set('bossView', '0')
        .set('perPage', perPage)
        .set('page', Page || 1)
    };
    return this.http.get<any>(`${NOMINA_URL}permissions/getPermissions`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Andres Buitrago
   * @purpose Servicio para obtener los permisos por filtro
   */
  getPermitsForBoss(state?: any, idTipDoc?: any, document?: any, campaign_id?: any, novelty_date1?: any, novelty_date2?: any, perPage: any = 5, page?: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('state', state)
        .set('document', document || '')
        .set('documentType', idTipDoc || '')
        .set('campaign_id', campaign_id || '')
        .set('novelty_date1', novelty_date1 || '')
        .set('novelty_date2', novelty_date2 || '')
        .set('perPage', perPage)
        .set('bossView', '1')
        .set('page', page || 1)
    };
    return this.http.get<any>(`${NOMINA_URL}permissions/getPermissions`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio obtiene la información de un permiso
   */
  getInfoByPermitId(id: any) {
    return this.http.get<any>(`${NOMINA_URL}permissions/getPermit/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para aprobar o rechazar un permiso
   */
  changeStatus(id: any, body: any) {
    return this.http.put<any>(`${NOMINA_URL}permissions/managementPermit/${id}`, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Andres Buitrago
   * @purpose Servicio para marcar como remunerado un permiso
   */
  changePermissionType(id: any, body: any) {
    return this.http.put<any>(`${NOMINA_URL}permissions/managementType/${id}`, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para descarga de reporte de permisos
   */
  getReport(state?: any, idTipDoc?: any, document?: any, campaign_id?: any, novelty_date1?: any, novelty_date2?: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('state', state)
        .set('document', document || '')
        .set('documentType', idTipDoc || '')
        .set('campaign_id', campaign_id || '')
        .set('bossView', '0')
        .set('novelty_date1', novelty_date1 || '')
        .set('novelty_date2', novelty_date2 || ''),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${NOMINA_URL}permissions/permissionsReport`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Andres Buitrago
   * @purpose Servicio para descarga de reporte de permisos para el jefe
   */
  getReportPermissionsForBoss(state?: any, idTipDoc?: any, document?: any, campaign_id?: any, novelty_date1?: any, novelty_date2?: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('state', state)
        .set('document', document || '')
        .set('documentType', idTipDoc || '')
        .set('campaign_id', campaign_id || '')
        .set('bossView', '1')
        .set('novelty_date1', novelty_date1 || '')
        .set('novelty_date2', novelty_date2 || ''),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${NOMINA_URL}permissions/permissionsReport`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener los usuarios de una campaña
   */
  getUserByCampaign(idCampaign: number): Observable<any> {

    return this.http.get<any>(`${RRHH_URL}users/campaign/${idCampaign}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
  * @author Fabio Garcia Alvarez
  * @purpose Servicio para obtener los usuarios por documento del jefe.
  */
  getUserByCampaignBoss(idCampaign: number, documentBoss: number): Observable<any> {

    return this.http.get<any>(`${NOMINA_URL}usersByCampaign?documentType=1&documentBoss=${documentBoss}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para la creación de una suspensión
   */
  newDisciplinaryProcess(data: any): Observable<any> {
    return this.http.post<any>(`${JURIDICA_URL}newDisciplinaryProcess`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener listado de suspensiónes
   */
  getSuspensions(): Observable<any> {
    return this.http.get<any>(`${JURIDICA_URL}getSuspensions`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener listado de procesos disciplinarios
   */
  getDisciplinaryProcessBy(idTipDoc?: any, document?: any, initialDate?: any, endDate?: any, perPage: any = 5, page?: any, state?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('document', document || '')
        .set('documentType', idTipDoc || '')
        .set('initialDate', initialDate || '')
        .set('endDate', endDate || '')
        .set('state', state || '')
        .set('perPage', perPage)
        .set('page', page || 1)
    };
    return this.http.get<any>(`${JURIDICA_URL}getDisciplinaryProcesses`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener listado de suspensiónes
   */
  getSuspensionsBy(idTipDoc?: any, document?: any, initialDate?: any, endDate?: any, perPage: any = 5, page?: any, state?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('document', document || '')
        .set('documentType', idTipDoc || '')
        .set('initialDate', initialDate || '')
        .set('endDate', endDate || '')
        .set('state', state || '')
        .set('perPage', perPage)
        .set('page', page || 1)
    };
    return this.http.get<any>(`${JURIDICA_URL}getSuspensions`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }


  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para la creación de recordatorio de funciones
   */
  createReminder(data: any): Observable<any> {
    return this.http.post<any>(`${JURIDICA_URL}reminders/store`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener listado de suspensiónes
   */
  getReminder(): Observable<any> {
    return this.http.get<any>(`${JURIDICA_URL}reminders/all`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener listado de recordatorios
   */
  getReminderBy(idTipDoc?: any, document?: any, initial_date?: any, end_date?: any, perPage: any = 5, Page?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('document', document || '')
        .set('documentType', idTipDoc || '')
        .set('startDate', initial_date || '')
        .set('endDate', end_date || '')
        .set('perPage', perPage)
        .set('page', Page || 1)
    };
    return this.http.get<any>(`${JURIDICA_URL}reminders/all`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener un reporte de recordatorios
   */
  getReminderReport(idTipDoc?: any, document?: any, initial_date?: any, end_date?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('document', document || '')
        .set('documentType', idTipDoc || '')
        .set('startDate', initial_date || '')
        .set('endDate', end_date || '')
        .set('toExport', '1'),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${JURIDICA_URL}reminders/all`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Servicio para obtener los procesos disciplinarios
   * @author Daniel Dominguez 
   */
  disciplinaryProcessReport(idTipDoc?: any, document?: any, initialDate?: any, endDate?: any, state?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('document', document || '')
        .set('documentType', idTipDoc || '')
        .set('initialDate', initialDate || '')
        .set('endDate', endDate || '')
        .set('state', state || ''),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${JURIDICA_URL}disciplinaryProcessReport`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener un reporte de suspension
   */
  getSuspensionReport(idTipDoc?: any, document?: any, initialDate?: any, endDate?: any, state?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('document', document || '')
        .set('documentType', idTipDoc || '')
        .set('initialDate', initialDate || '')
        .set('endDate', endDate || '')
        .set('state', state || ''),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${JURIDICA_URL}suspensionsReport`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Servicio para obtener informacion de un proceso discinario
   * @param susId ID del proceso
   * @author Andres Buitrago
   */
  getProcessDisciplinaryInfo(susId: number): Observable<any> {
    return this.http.get<any>(`${JURIDICA_URL}disciplinaryProcess/${susId}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Servicio para obtener informacion de suspension
   * @param susId ID de la suspension
   * @author Andres Buitrago
   */
  getSuspensionInfo(susId: number): Observable<any> {
    return this.http.get<any>(`${JURIDICA_URL}suspensions/${susId}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Servicio para obtener los tipos de acta
   * @author Daniel Dominguez 
   */
  getCertificatesInfo(): Observable<any> {
    return this.http.get<any>(`${JURIDICA_URL}discharges-certificate`)
      .pipe(
        catchError(this.handleError)
      );
  }


  /**
   * Servicio para obtener listado de casos de acta de descargo
   * @author Daniel Dominguez 
   */
  getCertificatesList(): Observable<any> {
    return this.http.get<any>(`${JURIDICA_URL}myDischargeCertificates`)
      .pipe(
        catchError(this.handleError)
      );
  }


  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener listado de casos de acta de descargo por filtro
   */
  getCertificateBy(state?: any, acta?: any, initial_date?: any, end_date?: any, perPage: any = 5, Page?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('state', state || '')
        .set('acta', acta || '')
        .set('startDate', initial_date || '')
        .set('endDate', end_date || '')
        .set('perPage', perPage)
        .set('page', Page || 1)
    };
    return this.http.get<any>(`${JURIDICA_URL}myDischargeCertificates`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
 * @author Daniel Dominguez 
 * @purpose Servicio para reporte de casos de acta de descargo por filtro
 */
  getCertificateReport(state?: any, acta?: any, initial_date?: any, end_date?: any, perPage: any = 5, Page?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('state', state || '')
        .set('acta', acta || '')
        .set('startDate', initial_date || '')
        .set('endDate', end_date || '')
        .set('perPage', perPage)
        .set('page', Page || 1),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${JURIDICA_URL}myDischargeCertificatesReport`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener informacion de acta de descarga por id
   */
  getCertificateById(id) {
    return this.http.get<any>(`${JURIDICA_URL}myDischargeCertificates/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para respuesta de acta de descargo
   */
  certificateResponse(data) {
    return this.http.post<any>(`${JURIDICA_URL}myDischargeCertificates`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener casos pendientes
   */
  getPendingCases() {
    return this.http.get<any>(`${JURIDICA_URL}casesPendingDecision`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener casos pendientes
   */
  getPendingCasesBy(doctype?: any, document?: any, acta?: any, initial_date?: any, end_date?: any, campaing?: any, perPage: any = 5, Page?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('documentType', doctype)
        .set('document', document || '')
        .set('dischargeCertificate', acta || '')
        .set('initialDate', initial_date || '')
        .set('endDate', end_date || '')
        .set('campaign', campaing)
        .set('perPage', perPage)
        .set('page', Page || 1)
    };
    return this.http.get<any>(`${JURIDICA_URL}casesPendingDecision`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener reporte de casos pendientes
   */
  getPendingCasesReport(doctype?: any, document?: any, acta?: any, initial_date?: any, end_date?: any, campaing?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('documentType', doctype)
        .set('document', document || '')
        .set('dischargeCertificate', acta || '')
        .set('initialDate', initial_date || '')
        .set('endDate', end_date || '')
        .set('campaign', campaing),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${JURIDICA_URL}casePendingDecision/report`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener informacion de caso pendiente por decisión por id
   */
  getPendingCasesById(id) {
    return this.http.get<any>(`${JURIDICA_URL}casePendingDecision/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para respuesta de caso pendiente de decisión
   */
  PendingCasesResponse(id: any, data) {
    return this.http.post<any>(`${JURIDICA_URL}managePendingCase/${id}`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener casos pendientes
   */
  getFinishedCasesBy(doctype?: any, document?: any, state?: any, worker?: any, initial_date?: any, end_date?: any, campaing?: any, perPage: any = 5, Page?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('states', state || '')
        .set('initialDate', initial_date || '')
        .set('endDate', end_date || '')
        .set('documentType', doctype)
        .set('document', document || '')
        .set('worker', worker || '')
        .set('campaign', campaing)
        .set('perPage', perPage)
        .set('page', Page || 1)
    };
    return this.http.get<any>(`${JURIDICA_URL}closedProcesses/all`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener casos terminados
   */
  getFinishedCases() {

    return this.http.get<any>(`${JURIDICA_URL}closedProcesses/all`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener casos terminados por filtros
   */
  getReportFinishedCases(doctype?: any, document?: any, state?: any, worker?: any, initial_date?: any, end_date?: any, campaing?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('states', state || '')
        .set('initialDate', initial_date || '')
        .set('endDate', end_date || '')
        .set('documentType', doctype)
        .set('document', document || '')
        .set('worker', worker || '')
        .set('campaign', campaing || ''),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${JURIDICA_URL}closedProcesses/report`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener casos terminados por id
   */
  getFinishedCasesById(id) {
    return this.http.get<any>(`${JURIDICA_URL}closedProcess/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener los usuarios que han tenido procesos
   */
  getUsersWithProcess() {
    return this.http.get<any>(`${JURIDICA_URL}closedProcess/users`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener los tipos de recordatorio
   */
  getReminderTypes() {
    return this.http.get<any>(`${JURIDICA_URL}parameters/reminderTypes`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener los subtipos de un recordatorio
   */
  getReminderSubTypes(id) {
    return this.http.get<any>(`${JURIDICA_URL}parameters/reminderTypes/${id}/subTypes`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Andres Buitrago
   * @purpose Servicio para obtener los recordatorios que no tiene proceso disciplinario
   */
  getReminderWithoutDisciplinaryProcessByWorker(id) {
    return this.http.get<any>(`${JURIDICA_URL}worker/${id}/remindersWithoutDisciplinaryProcess`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener recordatorio por id
   */
  getReminderById(id) {
    return this.http.get<any>(`${JURIDICA_URL}reminders/${id}/show`)
      .pipe(
        catchError(this.handleError)
      );
  }


  createDisciplinaryProcess(id: any, data: any) {
    return this.http.post<any>(`${JURIDICA_URL}/suspension/management/${id}`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
    * @author Fabian Duran 
    * @createdate 2024-01-04
    * Metodo que trae los usuarios asociados a una campaña.
    * @param idCampaign Id de la campaña 
  */
  getUserByCampaignV2(idCampaign: number): Observable<any> {
    return this.http.get<any>(`${RRHH_URL}users/campaignv2/${idCampaign}`)
      .pipe(
        catchError(this.handleError)
      );
  }
}