import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let object =  results.find(e=>{return e.key === Number(value)})
    return object? object.name : 'N/A';
  }

}
const results=[{key:1, name: 'Pendiente'}, {key:2, name: 'Aprobado'}, {key:3, name: 'Rechazado'}]
