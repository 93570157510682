import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChatRestService } from '../../services/chat-rest.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-all-contacts',
  templateUrl: './all-contacts.component.html',
  styleUrls: ['./all-contacts.component.sass']
})
export class AllContactsComponent implements OnInit {
  /**
   * Listado de tipos de documento
   */
  listTypeDocuments:any[]=[]

  /**
   * Variable de control para la busqueda
   */
  filterForm:FormGroup = new FormGroup({
    searchControl:  new FormControl(null,[Validators.required]),
    typeDocument:   new FormControl(1,[]),
  })
  

  /**
   * Variable que indica el tipo de busqueda 
   * document 
   * user
   */
  typeSearch:'document' | 'user' = 'user';


  listUsers:any[]=[]
  constructor(
    private chatRestService:ChatRestService,
    private alertService:AlertsService,
    private dialogRef:MatDialogRef<AllContactsComponent>
  ) { }

  ngOnInit(): void {
    this.getTypedocuments();
    this.filterForm.get('searchControl').valueChanges.subscribe(
      (searchValue)=>{
        if(searchValue != null && searchValue != ""  ){
          if(this.isNumeric(searchValue)){
            this.typeSearch = 'document'
          }else{
            this.typeSearch = 'user'
          }
        }
      }
    );
  }

  /**
   * Metodo que se encarga de determinar si el valor es numerico o no
   * @param value:string|number {string|number}
   * @returns boolean {bollean} True en caso de ser numerico False en caso de contener letras o caracteres especiales
   */
  private isNumeric(value):boolean{
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  /**
   * Metodo que se encarga de obtener el listado de los tipos de documento
   * @author Juan David Guerrero Vargas
   */
  getTypedocuments():void{
    this.chatRestService.getIdTypesdocuments().subscribe((resp:any[]) => {
      this.listTypeDocuments = resp;
    });
  }


  /**
   * Metodo que se encarga de realizar la busuqeda segun el criterio de filtrado indicado
   * @author Juan David Guerrero Vargas
   * @returns {void} void
   */
  searchUsers():void{
    let searchValue = this.filterForm.get('searchControl').value
    if(searchValue == null || searchValue == "" ){
      this.alertService.alertSmallNotification('top-end', 'success', `<p class="fs-5">Debe ingrersar algun valor apra el filtrado</p>`)
      return;
    }

    switch (this.typeSearch) {
      case 'document':
        let searchValue = {
          document: this.filterForm.get('searchControl').value,
          type_doc: this.filterForm.get('typeDocument').value,
        }
        this.chatRestService.getUsersBydocumentAndType(searchValue).subscribe(
          (restUsers:any)=>{
            this.listUsers = [{
              rrhh_id:restUsers.data.rrhh_id,
              name:restUsers.data.name,
              recipient_type:1,
              countMessageNotRead:0,
            }]
          },(error)=>{
            this.listUsers = []
          }
        );
      break;
      case 'user':
        this.chatRestService.getUserByName(this.filterForm.get('searchControl').value).subscribe(
          (restUsers:any)=>{
            this.listUsers = restUsers.data.map( (user:any) =>{
              user["recipient_type"] = 1
              user ["countMessageNotRead"] = 0
              return user;
            } )
          },(error)=>{
            this.listUsers = []
          }
        );
      break;
        
      default:
        this.listUsers=[]
      break;
    }
   
  }

  /**
   * Metodo que se encarga de establecer un nuevo chat segun el usuario seleccionado
   * @param user:any {any}
   */
  newChat(user:any):void{
    this.dialogRef.close({user:user})
  }
}
