<button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<form mat-dialog-content class="containerDialog" [formGroup]="itemForm">
    <h2>Crear ítem</h2>
    <mat-divider class="mb-3"></mat-divider>
    <mat-form-field appearance="fill">
        <mat-label>Nombre</mat-label>
        <input  matInput formControlName="nombre" cdkFocusInitial
        [matAutocomplete]="auto">
        <mat-icon matSuffix><span class="fi-rr-search" (click)="getItems()"></span></mat-icon>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" 
        (optionSelected)="newItem()">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.nombre">
              {{option.nombre}}
            </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="verError">No se encontraron coincidencias con el nombre que acabas de ingresar</mat-error>
    </mat-form-field>
    <button mat-button class="nuevo" *ngIf="nuevo" (click)="newItem()">Nuevo item <mat-icon matSuffix><i class="fi-rr-add"></i></mat-icon></button>
    <mat-form-field appearance="fill" *ngIf="peso">
        <mat-label>Peso</mat-label>
        <input type="number"  matInput formControlName="peso">
        <mat-error *ngIf="itemForm.get('peso').hasError('required')">Por favor ingresa un número de peso.</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="descripcion">
        <mat-label>Descripción</mat-label>
        <textarea  matInput formControlName="descripcion"></textarea>
    </mat-form-field>

    <div class="btns-dialog">
        <button mat-stroked-button (click)="itemRef.close()" [disabled]="continue">Cancelar</button>
        <button mat-raised-button color="primary" [disabled]="continue" (click)="saveItem()">Guardar</button>
    </div>
</form>