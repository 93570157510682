import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})


export class PositionsService {

  constructor(private http:HttpClient){}

  cargos: any [];
  api_services = environment.BPMS_URL;
  api_rrhh = environment.RRHH_URL;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  getPositions(id,flag ?) {

    return this.http.get(`${this.api_services}position/category/${id}${flag}`, this.httpOptions);

    // this.cargos = cargosJSON;0

    // this.cargos = this.cargos.sort((a, b) => a.cargo.localeCompare(b.cargo));

    // this.cargos = this.cargos.filter( tipo => tipo.tipo === id);

    // return this.cargos.sort((a, b) => a.cargo.localeCompare(b.cargo));

  }
  getPositionsActives(id) {
    return this.http.get(`${this.api_services}position/category/${id}?flag=actives`, this.httpOptions);
  }

  getPositionsStatusActive(id) {
    return this.http.get(`${this.api_rrhh}position/category-active/${id}`);
  }
}

const cargosJSON = [
  {
    id: '1',
    cargo: 'Asesor comercial',

     tipo: 2,


  },
  {
    id: '2',
    cargo: 'Asesor servicio al Cliente',

     tipo: 2,


  },
  {
    id: '3',
    cargo: 'Asesor cobranza',

     tipo: 2,


  },
  {
    id: '4',
    cargo: 'Asesor Back Office',

     tipo: 2,


  },
  {
    id: '5',
    cargo: 'Asesor seguros',

     tipo: 2,


  },
  {
    id: '6',
    cargo: 'Asesor soporte',

     tipo: 2,


  },
  {
    id: '7',
    cargo: 'Asesor de salud',

     tipo: 2,


  },
  {
    id: '8',
    cargo: 'Asesor presencial Olimpicas',

     tipo: 2,


  },
  {
    id: '9',
    cargo: 'Aux de enfermeria',

     tipo: 2,


  },
  {
    id: '10',
    cargo: 'Jefe de enfermeria',

     tipo: 2,


  },
  {
    id: '11',
    cargo: 'Supervisor',

     tipo: 2,


  },
  {
    id: '12',
    cargo: 'Formador junior',

     tipo: 2,


  },
  {
    id: '13',
    cargo: 'Formador senior',

     tipo: 2,


  },
  {
    id: '14',
    cargo: 'Analista de calidad',

     tipo: 2,


  },
  {
    id: '15',
    cargo: 'Reporting',

     tipo: 2,


  },
  {
    id: '16',
    cargo: 'Controller',

     tipo: 2,


  },
  {
    id: '17',
    cargo: 'Pushers',

     tipo: 2,


  },
  {
    id: '18',
    cargo: 'Back office',

     tipo: 2,


  },
  {
    id: '19',
    cargo: 'Lider de formacion y calidad',

     tipo: 2,


  },
  {
    id: '20',
    cargo: 'Datamarshall',

     tipo: 2,


  },
  {
    id: '21',
    cargo: 'Jefes de Operación',

     tipo: 2,


  },
  {
    id: '22',
    cargo: 'Coordinadores de Operación',

     tipo: 2,


  },
  {
    id: '23',
    cargo: 'Especialista en ventas',

     tipo: 2,


  },
  {
    id: '24',
    cargo: 'Oficial de seguridad de la informacion',

     tipo: 1,


  },
  {
    id: '25',
    cargo: 'Analista de la seguridad de la informacion',

     tipo: 1,


  },
  {
    id: '26',
    cargo: 'Analista NOC',

     tipo: 1,


  },
  {
    id: '27',
    cargo: 'Analista de ciberseguridad',

     tipo: 1,


  },
  {
    id: '28',
    cargo: 'Director de control interno',

     tipo: 1,


  },
  {
    id: '29',
    cargo: 'Coordinador SST',

     tipo: 1,


  },
  {
    id: '30',
    cargo: 'Lider SGA',

     tipo: 1,


  },
  {
    id: '31',
    cargo: 'Jefe de sistemas de gestion',

     tipo: 1,


  },
  {
    id: '32',
    cargo: 'Analista SST',

     tipo: 1,


  },
  {
    id: '33',
    cargo: 'Analista en salud',

     tipo: 1,


  },
  {
    id: '34',
    cargo: 'Auxiliar de enfermeria',

     tipo: 1,


  },
  {
    id: '35',
    cargo: 'Auxiliar SGA',

     tipo: 1,


  },
  {
    id: '36',
    cargo: 'Auxiliar ambiental',

     tipo: 1,


  },
  {
    id: '37',
    cargo: 'Formador control Interno',

     tipo: 1,


  },
  {
    id: '38',
    cargo: 'Gerente de talento humano',

     tipo: 1,


  },
  {
    id: '39',
    cargo: 'Coordinador de contratacion',

     tipo: 1,


  },
  {
    id: '40',
    cargo: 'Analista de contratacion',

     tipo: 1,


  },
  {
    id: '41',
    cargo: 'Auxiliar de contratacion',

     tipo: 1,


  },
  {
    id: '42',
    cargo: 'Jefe de bienestar y comunicaciones',

     tipo: 1,


  },
  {
    id: '43',
    cargo: 'Analista de bienestar',

     tipo: 1,


  },
  {
    id: '44',
    cargo: 'Auxiliar talento humano - bienestar',

     tipo: 1,


  },
  {
    id: '45',
    cargo: 'Analista de comunicaciones',

     tipo: 1,


  },
  {
    id: '46',
    cargo: 'Asistente de comunicaciones',

     tipo: 1,


  },
  {
    id: '47',
    cargo: 'Lider selecccion y desarrollo',

     tipo: 1,


  },
  {
    id: '48',
    cargo: 'Analista de seleccion',

     tipo: 1,


  },
  {
    id: '49',
    cargo: 'Auxiliar de talento humano - desarrollo',

     tipo: 1,


  },
  {
    id: '50',
    cargo: 'Analista de desarrollo humano',

     tipo: 1,


  },
  {
    id: '51',
    cargo: 'Auxiliar desarrollo humano',

     tipo: 1,


  },
  {
    id: '52',
    cargo: 'Director de desarrollos de proyectos',

     tipo: 1,


  },
  {
    id: '53',
    cargo: 'Ejecutivo de proyectos',

     tipo: 1,


  },
  {
    id: '54',
    cargo: 'Coordinador de licitaciones y gestion de contratos',

     tipo: 1,


  },
  {
    id: '55',
    cargo: 'Analista de requerimientos',

     tipo: 1,


  },
  {
    id: '56',
    cargo: 'Gestor de negocios',

     tipo: 1,


  },
  {
    id: '57',
    cargo: 'Gerente financiero',

     tipo: 1,


  },
  {
    id: '58',
    cargo: 'Jefe financiero',

     tipo: 1,


  },
  {
    id: '59',
    cargo: 'Jefe de nomina',

     tipo: 1,


  },
  {
    id: '60',
    cargo: 'Analista contable',

     tipo: 1,


  },
  {
    id: '61',
    cargo: 'Asistente de tesoreria',

     tipo: 1,


  },
  {
    id: '62',
    cargo: 'Asistente contable',

     tipo: 1,


  },
  {
    id: '63',
    cargo: 'Analista de impuestos',

     tipo: 1,


  },
  {
    id: '64',
    cargo: 'Data nomina',

     tipo: 1,


  },
  {
    id: '65',
    cargo: 'Auxiliar contable - nomina',

     tipo: 1,


  },
  {
    id: '66',
    cargo: 'Gerente administrativa',

     tipo: 1,


  },
  {
    id: '67',
    cargo: 'Coordinador de infraestructura',

     tipo: 1,


  },
  {
    id: '68',
    cargo: 'Coordinador administrativo',

     tipo: 1,


  },
  {
    id: '69',
    cargo: 'Jefe seguridad fisica',

     tipo: 1,


  },
  {
    id: '70',
    cargo: 'Jefe juridico',

     tipo: 1,


  },
  {
    id: '71',
    cargo: 'analista juridico',

     tipo: 1,


  },
  {
    id: '72',
    cargo: 'Supervisor de mantenimiento',

     tipo: 1,


  },
  {
    id: '73',
    cargo: 'Tecnico de mantenimiento',

     tipo: 1,


  },
  {
    id: '74',
    cargo: 'Auxiliar administrativo almacen',

     tipo: 1,


  },
  {
    id: '75',
    cargo: 'Supervisor servicios generales',

     tipo: 1,


  },
  {
    id: '76',
    cargo: 'Auxiliar servicios generales',

     tipo: 1,


  },
  {
    id: '77',
    cargo: 'Supervisor administrativo',

     tipo: 1,


  },
  {
    id: '78',
    cargo: 'Recepcionista',

     tipo: 1,


  },
  {
    id: '79',
    cargo: 'Auxiliar mantenimiento CCTV',

     tipo: 1,


  },
  {
    id: '80',
    cargo: 'Operador medios tecnologicos',

     tipo: 1,


  },
  {
    id: '81',
    cargo: 'Auxiliar parqueaderos',

     tipo: 1,


  },
  {
    id: '82',
    cargo: 'Asistente administrativo - compras',

     tipo: 1,


  },
  {
    id: '83',
    cargo: 'Director IT',

     tipo: 1,


  },
  {
    id: '84',
    cargo: 'Coordinador de soporte y usuarios',

     tipo: 1,


  },
  {
    id: '85',
    cargo: 'Analista senior',

     tipo: 1,


  },
  {
    id: '86',
    cargo: 'Analista lider de soporte',

     tipo: 1,


  },
  {
    id: '87',
    cargo: 'Analista IT',

     tipo: 1,


  },
  {
    id: '88',
    cargo: 'Analista lider de accesos',

     tipo: 1,


  },
  {
    id: '89',
    cargo: 'Analista de accesos',

     tipo: 1,


  },
  {
    id: '90',
    cargo: 'Coordinador de infraestructura',

     tipo: 1,


  },
  {
    id: '91',
    cargo: 'Anlaista de infraestructura',

     tipo: 1,


  },
  {
    id: '92',
    cargo: 'Coordinador de telecomunicaciones',

     tipo: 1,


  },
  {
    id: '93',
    cargo: 'Analista senior telecomunicaciones',

     tipo: 1,


  },
  {
    id: '94',
    cargo: 'Analista junior telecomunicaciones',

     tipo: 1,


  },
  {
    id: '95',
    cargo: 'Jefe de redes y conectividad',

     tipo: 1,


  },
  {
    id: '96',
    cargo: 'Administrador de redes y conectividad',

     tipo: 1,


  },
  {
    id: '97',
    cargo: 'Administrador de redes y seguridad',

     tipo: 1,


  }
];
