import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../../../core/services/rest/auth.service';
import { throwError, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { catchError } from 'rxjs/operators';

const CIU_URL = environment.CIU_URL;
@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-03
   * Servicio que lista todas las campañas paginadas
   */
  getCampaigns(): Observable<any>{
    return this.http.get<any>(`${CIU_URL}campaigns/?paginate=0`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-03
   * Servicio que lista todas las campañas sin paginar
   */
  getCampaignsSelect(): Observable<any>{
    return this.http.get<any>(`${CIU_URL}users/getCampaigns`)
    .pipe(
      catchError(this.handleError)
      );
  }
}
