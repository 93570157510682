<button style="float: right;" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross"></i>
</button>
<div class="container-dialog">
  <h2>{{titulo}}</h2>
  <mat-divider class="mb-3"></mat-divider>
  <form class="row" [formGroup]="grupoForm">
    <mat-form-field appearance="fill" class="col-6">
      <mat-label>{{config.labels.campaign}}</mat-label>
      <mat-select formControlName="campania" #selectCampania>
        <mat-option value="ninguna">{{config.labels.noneOption}}</mat-option>
        <mat-option *ngFor="let campaign of campaigns" [value]="campaign.id">{{campaign.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="col-6">
      <mat-label>{{config.labels.supervisor}}</mat-label>
      <mat-select formControlName="supervisor_id" (selectionChange)="enableNombre(supervisor)" #supervisor>
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let supervisor of supervisores" [value]="supervisor.id">{{supervisor.nombre | titlecase}}</mat-option>
      </mat-select>
      <mat-error>{{config.labels.selectOptionError}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="col-12">
      <mat-label>{{config.labels.groupName}}</mat-label>
      <input type="text" matInput cdkFocusInitial formControlName="nombre" [value]="nombre_grupo">
      <mat-error>{{config.labels.groupNameError}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="col-6">
      <mat-label>{{config.labels.operationsChiefEmail}}</mat-label>
      <input type="email" matInput formControlName="correo_jefe_opraciones">
      <mat-error *ngIf="grupoForm.get('correo_jefe_opraciones').hasError('required')">{{config.labels.requiredEmail}}</mat-error>
      <mat-error *ngIf="grupoForm.get('correo_jefe_opraciones').hasError('email')">{{config.labels.invalidEmail}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="col-6">
      <mat-label>{{config.labels.supervisorEmail}}</mat-label>
      <input type="email" matInput formControlName="correo_supervisor">
      <mat-error *ngIf="grupoForm.get('correo_supervisor').hasError('required')">{{config.labels.requiredEmail}}</mat-error>
      <mat-error *ngIf="grupoForm.get('correo_supervisor').hasError('email')">{{config.labels.invalidEmail}}</mat-error>
    </mat-form-field>
    <div class="col-12 mt-3" style="text-align: center;">
      <button mat-stroked-button class="btn-dialog me-4" (click)="closeDialog()">{{config.labels.cancelButton}}</button>
      <button mat-raised-button color="primary" style="width: 192px; height: 48px;" (click)="createGroup()" [disabled]="grupoForm.invalid">{{config.labels.saveButton}}</button>
    </div>
  </form>
</div>
