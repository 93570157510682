import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from "@angular/router";
import { SableService } from '../services/sable.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import * as moment from 'moment';
import { NAMES_STATES_DOWNLOAD } from '../constantes/states-downloads';

@Component({
  selector: 'app-gestor',
  templateUrl: './gestor.component.html',
  styleUrls: ['./gestor.component.css']
})

export class GestorComponent implements OnInit {
  dateSelected: string = null;
  stateSelected: string = '';
  filtersDownloads: any = { date: '', state: null };
  downloads: any[] = [];
  estados: any = [];
  paginator: any = { length: 0, pageSize: 5, pageIndex: 0, previousPageIndex: 0 };
  namesStatesDownloads: string[] = NAMES_STATES_DOWNLOAD;

  constructor(
    private router: Router,
    private SableService: SableService,
    private alertService: AlertsService
  ) { }

  ngOnInit(): void {
    this.getDownloads();
    this.SableService.GetDataSelects('get_estados').subscribe(resp => {
      this.estados = resp.data;
    });
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-06-15
    * Metodo que trae las descargas registradas. 
  */
  getDownloads(): void {
    this.SableService.getDownloads(this.paginator, this.filtersDownloads).subscribe(res => {
      this.downloads = res.data.data;
      this.paginator.length = res.data.total;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-15
    * Metodo que se ejecuta al cambiar la fecha en el filtro. 
    * @param $event Parametro emitido por el campo fecha. 
  */
  onChangeDate($event: any): void {
    const date = moment($event.value);
    this.filtersDownloads.date = date;
    this.getDownloads();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-15
    * Metodo que se ejecuta al cambiar el estado en el filtro. 
    * @param $event Parametro emitido por el campo estado. 
  */
  onChangeState($event: any): void {
    this.filtersDownloads.state = $event.value;
    this.getDownloads();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-15
    * Metodo que redirecciona a la pagina de crear descarga. 
  */
  goToCreateDownloadPage(): void {
    this.router.navigate(['mios/sable/crear_descarga']);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-15
    * Metodo que descarga un archivo registrado.
    * @param id Id de la descarga seleccionada.  
  */
  downloadFile(id: number): void {
    this.SableService.downloadFile(id).subscribe(res => {
      importedSaveAs(res);
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-15
    * Metodo que elimina un archivo registrado. 
    * @param id Id de la descarga seleccionada.
  */
  deleteFile(id: number): void {
    this.alertService.alertWarning('¿Está seguro?', '¿De eliminar la descarga seleccionada?').then(isConfirm => {
      if (isConfirm.isConfirmed) {
        this.SableService.deleteDownload(id).subscribe(res => {
          this.alertService.alertSuccess('Correcto', res.data);
          this.getDownloads();
        });
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-15
    * Metodo que actualiza el estado de la paginacion.
    * @param $event Evento emitido por el componente de paginación. 
  */
  onChangePage($event: PageEvent): void {
    this.paginator = $event;
    this.getDownloads();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-17
    * Metodo que limpia los filtros de busqueda.
  */
  clearFilters(): void {
    this.filtersDownloads = { date: '', state: null };
    this.dateSelected = '';
    this.stateSelected = null;
    this.getDownloads();
  }
}