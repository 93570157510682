import { Component, OnInit, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';


import * as moment from 'moment';

import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import { InactiveDatesService } from '../../../../services/rest/inactive-dates.service';
import { CreateInactiveDatesComponent } from '../create-inactive-dates/create-inactive-dates.component';

@Component({
  selector: 'app-inactive-dates-list',
  templateUrl: './inactive-dates-list.component.html',
  styleUrls: ['./inactive-dates-list.component.sass']
})
export class InactiveDatesListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public filters: any = {
    month: '',
    year: '',
    textFilter: '',
    perPage: 5,
    page: 1,
    paginate: 1,
  };

  public monthControl = new FormControl();
  public yearControl = new FormControl();
  public textFilter = new FormControl('');

  // MatPaginator Inputs
  public paginatorObject: any = {
    length: 0,
    pageSize: 5,
    page: 1,
    isPage: 1,
    pageSizeOptions: [5, 10, 25, 100],
  };

  displayedColumns: string[] = ['acciones', 'inactive_date', 'description'];
  dataSource = new MatTableDataSource<any>();

  public months: { id, name }[] = [
    { id: 1, name: 'Enero' },
    { id: 2, name: 'Febrero' },
    { id: 3, name: 'Marzo' },
    { id: 4, name: 'Abril' },
    { id: 5, name: 'Mayo' },
    { id: 6, name: 'Junio' },
    { id: 7, name: 'Julio' },
    { id: 8, name: 'Agosto' },
    { id: 9, name: 'Septiembre' },
    { id: 10, name: 'Octubre' },
    { id: 11, name: 'Noviembre' },
    { id: 12, name: 'Diciembre' }
  ];

  public years: { id, name }[] = [];

  constructor(public dialog: MatDialog,
              private inactiveDatesService: InactiveDatesService,
              public alertService: AlertsService) {
  }

  ngOnInit(): void {
    this.generateArrayOfYears();
    this.getInactiveDates();
    this.monthControl.valueChanges
      .subscribe(resp => {
        this.filters.month = resp;
        this.getInactiveDates();
      });
    this.yearControl.valueChanges
      .subscribe(resp => {
        console.log(resp);
        this.filters.year = resp;
        this.getInactiveDates();
      });
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Obtiene las fechas inactivas para el proceso de vacaciones
   */
  getInactiveDates(): void {
    this.inactiveDatesService.getInactiveDates(this.filters)
      .subscribe(resp => {
        this.dataSource.data = resp.data.data;
        this.dataSource.sort = this.sort;
        this.paginatorObject.length = resp.data.total;
        this.paginatorObject.pageSize = resp.data.per_page;
      });
  }

  /**
   * @param value Búsqueda por descripción
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose búsqueda por descripcion de la fecha
   */
  searchByTextFilter(value: string): void {
    this.filters.textFilter = value;
    this.getInactiveDates();
  }

  /**
   * @param event objeto del paginador
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Traer info de la siguiente página
   */
  pageEvent(event: any): void {
    this.filters.perPage = event.pageSize;
    this.filters.page = event.pageIndex + 1;
    this.getInactiveDates();
  }

  /**
   * @param item registro de la fecha
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Eliminar una fecha especifica
   */
  deleteDate(item): void {
    this.alertService.alertConfirm('¿Está seguro de eliminar esta fecha?').then((result) => {
      if (result.isConfirmed) {
        this.inactiveDatesService.deleteInactiveDate(item.id)
          .subscribe(resp => {
            this.alertService.alertSuccess('Eliminado', 'Fecha eliminada correctamente');
            this.getInactiveDates();
          });
      }
    });
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Generar rango de años
   */
  generateArrayOfYears(): void {
    const max = new Date().getFullYear();
    const min = max - 9;

    for (let i = max; i >= min; i--) {
      this.years.push({ id: i, name: i });
    }
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Abre el modal para la creacion de una nueva fecha inactiva
   */
  createInactiveDate(): void {
    const dialogDetailNovelty = this.dialog.open(CreateInactiveDatesComponent, {
      width: '45%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Creacion de fecha inactiva'
      },
      panelClass: 'custom-dialog-container',
    });

    dialogDetailNovelty.afterClosed().subscribe(() => {
      this.getInactiveDates();
    });
  }

}
