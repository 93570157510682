import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatrizService } from 'src/app/modules/auditoria/services/matriz.service';
import { Tools } from 'src/app/modules/auditoria/utils/tools.utils';

@Component({
	selector: 'app-editar-modulo',
	templateUrl: './editar-modulo.component.html',
	styleUrls: ['./editar-modulo.component.sass']
})
export class EditarModuloComponent implements OnInit {

	moduleForm: FormGroup;
	modulos = [];
	nuevo: boolean = false;
	peso: boolean = false;
	verError: boolean = true;
	continue: boolean = false;
	filteredOptions: Observable<any[]>;
	constructor(
		private fb: FormBuilder,
		public moduleRef: MatDialogRef<EditarModuloComponent>,
		@Inject(MAT_DIALOG_DATA) public datos: any,
		private matrizService: MatrizService,
	) { }

	ngOnInit(): void {
		this.createForm();
		this.getModuleById();
	}
	createForm() {
		this.moduleForm = this.fb.group({
			nombre: ['', [Validators.required]],
			peso: ['', [Validators.required, Validators.max(this.datos.maximo), Validators.min(this.datos.minimo),Validators.required]],
			error_critico: [false, Validators.required],
			is_total: new FormControl('')
		});
	}
	private _filter(value: string): any[] {
		const filterValue = value.toLowerCase();
		return this.modulos.filter(option => option.nombre.toLowerCase().includes(filterValue));
	}

	displayFn(module: any): string {
		return module && module ? module : '';
	}
	getModules() {
		this.matrizService.getModules(this.moduleForm.get('nombre').value).subscribe((resp: any) => {
			if (resp.length == 0) {
				this.verError = true
				this.moduleForm.get('nombre').setErrors({ invalid: true });
				this.nuevo = true;
				this.peso = true;
				this.moduleForm.get('error_critico').disable();
				this.continue = true;
			} else {
				this.continue = false;
				this.verError = false;
				this.nuevo = false;
				this.modulos = resp
				this.filteredOptions = this.moduleForm.get('nombre').valueChanges.pipe(
					startWith(''),
					map(value => this._filter(value))
				);
			}
		});
	}

	newModule() {
		this.nuevo = false;
		this.moduleForm.get('nombre').setErrors(null);
		this.continue = false;
		this.moduleForm.get('error_critico').enable();
	}

	updateModule() {
		if (this.moduleForm.invalid) {
			return
		}
		if (
			this.moduleForm.get('error_critico').value
			&& (this.moduleForm.get('peso').value == "" || this.moduleForm.get('peso').value == undefined || this.moduleForm.get('peso').value == null)
			&& this.moduleForm.get('is_total').value == false) {
			this.moduleForm.get('peso').setErrors({ required: true });
			return;
		}

		const data = {
			idModulo: this.datos.id_module
		}
		Object.assign(data, this.moduleForm.value);
		this.matrizService.updateModule(data).subscribe((resp: any) => {
			if (resp.respuesta.codigo == 202) {
				Tools.swalSuccess('¡Excelente!', 'Se ha actualizado el módulo correctamente.');
				this.moduleRef.close();
			} else if (resp.respuesta.codigo > 400) {
				Tools.swalError('¡Oops!', 'Lo sentimos, ha ocurrido un error inesperado y no fue posible actualizar el módulo, por favor intenta nuevamente.');
			}
		}, (err: any) => {
		}
		);
	}

	getModuleById() {
		this.matrizService.getModuleById(this.datos.id_module).subscribe((resp: any) => {
			this.moduleForm.setValue({
				nombre: resp.nombre,
				peso: resp.peso,
				error_critico: resp.error_critico,
				is_total: resp.error_critico == 1 && resp.peso == null || resp.peso == 0 ? true : false
			});

			if (resp.error_critico == 1 && (resp.peso == null || resp.peso == 0)) {
				this.moduleForm.get('peso').disable();
			} else {
				this.moduleForm.get('peso').enable();
			}
			if (this.datos.tipo_matriz == "Cumplimiento") {

				this.moduleForm.get('peso').setErrors(null);
			} else {
				if (this.datos.tipo_peso) {
					if (this.datos.tipo_peso == 'Peso en Módulos') {
						this.moduleForm.get('peso').setErrors(null);
					} else {
						if (resp.peso !== "" && resp.peso !== null && resp.peso !== undefined && resp.peso > 0) {
							this.moduleForm.get('peso').enable();

						} else {
							this.moduleForm.get('peso').disable();
						}
						this.moduleForm.get('peso').setErrors(null);
					}
				}
			}
		});
	}

	changeCheck(event: any): void {

		if (event.checked) {
			if (this.datos.tipo_peso == 'Peso en Items') {
				this.moduleForm.get('peso').setValue(null);
				this.moduleForm.get('peso').enable();
			}
			this.moduleForm.get('is_total').enable();
			this.moduleForm.get('peso').setValidators([Validators.min(this.datos.minimo), Validators.max(this.datos.maximo), Validators.required])
		} else {
			if (this.datos.tipo_peso == 'Peso en Items') {
				this.moduleForm.get('peso').setValue(null);
				this.moduleForm.get('peso').disable();
			}
			this.moduleForm.get('peso').setValidators(null)
			this.moduleForm.get('is_total').setValue(false);
			this.moduleForm.get('is_total').disable();
		}
	}

	/** Metodo que se encarga de habilit el peso
	 * @author  Juan David Guerrero Vargas
	 * @create_date 16/06/2022 
	 * @param event:any evento de HTML 
	 */
	habilitarPeso(event: any): void {
		if (event.checked) {
			this.moduleForm.get('peso').setValue('');
			this.moduleForm.get('peso').disable();
			this.moduleForm.get('peso').setErrors(null);
			this.moduleForm.get('peso').setValidators(null)
		} else {
			this.moduleForm.get('peso').setValue('');
			this.moduleForm.get('peso').enable();
			if (this.moduleForm.get('error_critico').value) {
				this.moduleForm.get('peso').setValidators([Validators.min(this.datos.minimo), Validators.max(this.datos.maximo), Validators.required])
				this.moduleForm.get('peso').setErrors({ required: true });
			} else {
				this.moduleForm.get('peso').setErrors(null);
				this.moduleForm.get('peso').setValidators([Validators.min(this.datos.minimo), Validators.max(this.datos.maximo)])
			}
		}

	}


	
}
