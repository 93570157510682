import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {TranslatesService} from '../../services/translates.service';

@Component({
  selector: 'app-inteligencia-negocios',
  templateUrl: './inteligencia-negocios.component.html',
  styleUrls: ['./inteligencia-negocios.component.sass']
})
export class InteligenciaNegociosComponent implements OnInit {
  private uuid = '6111a21c-74b7-42dd-aac4-992da45dc867';
  public config: any;

  selected = new FormControl(0);
  tab;
  constructor(
    private aRouter: ActivatedRoute,
    private router: Router,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.aRouter.paramMap.subscribe(params =>{
      this.selected.setValue(params.get('tab'));
    });

    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
  }

  changeTab(event) {
    this.router.navigateByUrl(`/mios/calidad/inteligencia-negocios/${event}`);
    this.selected.setValue(event);
  }

}
