<form class="mt-4 row pb-3" [formGroup]="auditarForm">
  <input type="hidden" id="puntajes" value="100,100,100,100,100,100">
  <mat-form-field class="col-3" appearance="fill">
    <mat-label>Campaña</mat-label>
    <mat-select formControlName="tipo_campana" #tipo_campana>
      <mat-option *ngFor="let campana of campanas" [value]="campana.id">{{campana.nombre}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('tipo_campana').hasError('required')">Por favor seleccione una
      opción.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill">
    <mat-label>Aliado</mat-label>
    <mat-select formControlName="aliado">
      <mat-option *ngFor="let aliado of aliados" [value]="aliado.id">{{aliado.nombre}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('aliado').hasError('required')">Por favor seleccione una opción.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill">
    <mat-label>Nombre del asesor</mat-label>
    <input type="text" matInput formControlName="nombre_asesor" autocomplete="off">
    <mat-error *ngIf="auditarForm.get('nombre_asesor').hasError('required')">Por favor ingrese el nombre del
      asesor.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill">
    <mat-label>Etiqueta</mat-label>
    <input type="text" matInput formControlName="etiqueta">
    <mat-error *ngIf="auditarForm.get('etiqueta').hasError('required')">Por favor ingresa una etiqueta.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill">
    <mat-label>Fecha llamada Auditada</mat-label>
    <input type="text" matInput [matDatepicker]="llamada" formControlName="fecha" [max]="endDate">
    <mat-datepicker-toggle matSuffix [for]="llamada">
      <mat-icon matDatepickerToggleIcon><i class="fi-rr-calendar"></i></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #llamada></mat-datepicker>
    <mat-error *ngIf="auditarForm.get('fecha').hasError('required')">Por favor ingresa una fecha.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill">
    <mat-label>Número teléfono Auditado</mat-label>
    <input type="number" matInput formControlName="telefono">
    <mat-error *ngIf="auditarForm.get('telefono').hasError('required')">Por favor ingresa un número de
      teléfono.</mat-error>
    <mat-error *ngIf="auditarForm.get('telefono').hasError('max')">El telélefono solo puede tener maximo 10
      dígitos.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill" *ngIf=" tipo_campana.value == '180' || tipo_campana.value == '180'">
    <mat-label>Hora contacto asesor cliente</mat-label>
    <input type="time" matInput formControlName="hora">
    <mat-error *ngIf="auditarForm.get('hora').hasError('required')">Por favor ingresa una hora de
      contacto.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill" id="grabacion"
    *ngIf="showFieldDuration === 1">
    <mat-label>Duración grabación</mat-label>
    <input type="text" matInput formControlName="duracion_grabacion"
      (change)="auditarForm.get('duracion_grabacion').setValue(tiempo)" max="8">
    <mat-icon matSuffix style="color: #717171; font-size: 20px;"><i class="fi-rr-clock"></i></mat-icon>
    <mat-error *ngIf="auditarForm.get('duracion_grabacion').hasError('required')">Por favor ingresa el tiempo de la
      grabación.</mat-error>
    <mat-error *ngIf="auditarForm.get('duracion_grabacion').hasError('pattern')">Solo se permiten valores númericos
      con el formato 59:59:59</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill"
    *ngIf="tipo_campana.value == '11' || tipo_campana.value == '12' || tipo_campana.value == '17' ">
    <mat-label>Skills</mat-label>
    <mat-select formControlName="skills">
      <mat-option *ngFor="let skill of skills" [value]="skill.id">{{skill.nombre}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('skills').hasError('required')">Por favor seleccione una opción.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill"
    *ngIf="tipo_campana.value == '11' || tipo_campana.value == '12' || tipo_campana.value == '17' ">
    <mat-label>Tipificación</mat-label>
    <mat-select formControlName="tipificacion" (ngModelChange)="cargar_sub_tipificacion()">
      <mat-option *ngFor="let tipificacion of tipificaciones"
        [value]="tipificacion.id">{{tipificacion.nombre}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('tipificacion').hasError('required')">Por favor seleccione una
      opción.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill"
    *ngIf="tipo_campana.value == '11' || tipo_campana.value == '12' || tipo_campana.value == '17' ">
    <mat-label>Sub tipificación</mat-label>
    <mat-select formControlName="sub_tipificacion">
      <mat-option *ngFor="let sub_tipificacion of subtipificaciones"
        [value]="sub_tipificacion.id">{{sub_tipificacion.nombre}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('sub_tipificacion').hasError('required')">Por favor seleccione una
      opción.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill"
    *ngIf="tipo_campana.value == '11' || tipo_campana.value == '12' || tipo_campana.value == '17' ">
    <mat-label>Login</mat-label>
    <input type="number" matInput formControlName="login">
    <mat-error *ngIf="auditarForm.get('login').hasError('required')">Por favor ingresa login.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill"
    *ngIf="tipo_campana.value == '11' || tipo_campana.value == '14' || tipo_campana.value == '12' || tipo_campana.value == '17' || tipo_campana.value == '18' ">
    <mat-label>Usuario</mat-label>
    <input type="text" matInput formControlName="usuario">
    <mat-error *ngIf="auditarForm.get('usuario').hasError('required')">Por favor ingresa usuario.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill"
    *ngIf="tipo_campana.value == '18' || tipo_campana.value == '14' || tipo_campana.value == '13' || tipo_campana.value == '10' || tipo_campana.value == '16' || tipo_campana.value == '15' ">
    <mat-label>Número de Radicado(Incidencia y CUN)</mat-label>
    <input type="text" matInput formControlName="nur" autocomplete="off">
    <mat-error *ngIf="auditarForm.get('nur').hasError('required')">Por favor ingrese el NUR.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill" *ngIf="tipo_campana.value == '14' || tipo_campana.value == '18' ">
    <mat-label>Tienda</mat-label>
    <mat-select formControlName="tienda">
      <mat-option *ngFor="let tienda of tiendas" [value]="tienda.id">{{tienda.nombre}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('tienda').hasError('required')">Por favor seleccione una opción.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill" *ngIf="tipo_campana.value == '13' || tipo_campana.value == '13' ">
    <mat-label>Tipo</mat-label>
    <mat-select formControlName="tipo">
      <mat-option *ngFor="let tipo of tipos" [value]="tipo.id">{{tipo.nombre}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('tipo').hasError('required')">Por favor seleccione una opción.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill" *ngIf="tipo_campana.value == '13' || tipo_campana.value == '13' ">
    <mat-label>Subtipo PQRS</mat-label>
    <mat-select formControlName="subtipo_pqr">
      <mat-option *ngFor="let subtipo_pqr of subtipos_pqr" [value]="subtipo_pqr.id">{{subtipo_pqr.nombre}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('subtipo_pqr').hasError('required')">Por favor seleccione una
      opción.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill" *ngIf="tipo_campana.value == '13' || tipo_campana.value == '13' ">
    <mat-label>Segmento</mat-label>
    <mat-select formControlName="segmento">
      <mat-option *ngFor="let segmento of segmentos" [value]="segmento.id">{{segmento.nombre}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('segmento').hasError('required')">Por favor seleccione una opción.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill">
    <mat-label>Año</mat-label>
    <mat-select formControlName="ano">
      <mat-option *ngFor="let ano of anos" [value]="ano">{{ano}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('ano').hasError('required')">Por favor seleccione una opción.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill">
    <mat-label>Mes</mat-label>
    <mat-select formControlName="mes">
      <mat-option *ngFor="let mes of meses" [value]="mes.id">{{mes.nombre}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('mes').hasError('required')">Por favor seleccione una opción.</mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="isRetentionMatrix === 1" class="col-3" appearance="fill">
    <mat-label>¿Es una retención?</mat-label>
    <mat-select formControlName="is_retencion">
      <mat-option [value]="0">No</mat-option>
      <mat-option [value]="1">Si</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="col-12" appearance="fill">
    <mat-label>Observaciones</mat-label>
    <textarea type="text" matInput formControlName="observacion"></textarea>
    <mat-error *ngIf="auditarForm.get('observacion').hasError('required')">Por favor ingrese una
      observación.</mat-error>
  </mat-form-field>
  <mat-form-field class="col-12" appearance="fill">
    <mat-label>Voz cliente</mat-label>
    <textarea type="text" matInput formControlName="voz_cliente"></textarea>
  </mat-form-field>
</form>