<div style="margin: 2rem">
  <i class="fi-rr-cross-small" style="float: right; font-size: 32px;" mat-icon-button mat-dialog-close></i>
  <h2 mat-dialog-title style="margin-left: 50px; color: #353535; font-size: 38px!important;"><b>Resumen de mi nómina</b></h2>
  <p style="margin-left: 60px; color: #A9A9A9; font-size: 16px!important;"> <b>{{currentDate | titlecase}}</b> </p>

  <mat-divider style="border: 1px solid #A9A9A9; margin-left: 60px;
  margin-right: 50px;"></mat-divider>

  <!--Sistema de filas y columnas boostrap-->

  <div class="row col-12" style="padding: 32px 40px 32px 60px;">
    <div class="col-4">
      <div class="col-12" style="display: flex; padding-right: 14px; color: #353535; height: 2rem;
      font-size: 14px !important; font-weight: 600;" *ngFor="let item of novelties">
        <p>{{item.name}}</p>
      </div>
    </div>

    <div class="col-4">
      <div class="col-12" style="display: flex; padding-right: 14px; color: #717171; height: 2rem; font-size: Poppins;"
           *ngFor="let item of novelties">
        <p>{{item.value | currency: '$':true:'2.0' }}</p>
      </div>
    </div>

    <div class="col-4">
      <img src="./assets/images/resu-nomina.png" style="margin-left: -23%;">
    </div>

  </div>
  <footer>
    <div style="display: -webkit-box; margin-left: 60px;">
        <span style="font-size: 11px;
        color: #717171; font-weight: 600;">NOTA:</span>
      <p style="font-size: 11px;
        color: #717171;">"Esta información puede variar en la liquidación mensual del trabajador".</p>
    </div>
  </footer>
</div>
