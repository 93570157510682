import { AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { auditoriaModel } from 'src/app/modules/coaching/core/interfaces/auditoria.interface';
import { CoachingState } from 'src/app/modules/coaching/state/coaching.state';
import { selectedListAuditoria } from 'src/app/modules/coaching/state/selectors/auditoria.selector';

@Component({
  selector: 'app-auditoria',
  templateUrl: './auditoria.component.html',
  styleUrls: ['./auditoria.component.sass']
})
export class AuditoriaComponent implements OnInit, OnDestroy {

  auditoria$:Observable<any> = new Observable();
  dataSource = [];
  displayedColumns:string[] = ['matriz','fecha_auditoria','calificacion'];
  dataAuditoria:auditoriaModel;
  calificacionesForm:FormGroup;
  tipo_matriz:string = '';
  tipo_peso:string = '';
  constructor(
    private store:Store<CoachingState>,
    private fb:FormBuilder,
    private asyncPipe:AsyncPipe
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getAuditoria();
  }

  ngOnDestroy(): void {
    this.calificacionesForm.reset();
  }

  getAuditoria(){
    let dataTable = [];
    const modulosForm = <FormArray>this.calificacionesForm.controls['modulos'];
    this.auditoria$ = this.store.select(selectedListAuditoria);
    this.auditoria$.forEach(element => {
      if (element[0]) {
        this.dataAuditoria = element[0].auditoria;
      }
      if (this.dataAuditoria) {
        this.tipo_matriz = this.dataAuditoria.detalle_matriz.tipo_matriz.nombre;
        if (this.dataAuditoria.detalle_matriz.tipo_peso) {
          this.tipo_peso = this.dataAuditoria.detalle_matriz.tipo_peso.nombre
        }
      }
      if (this.dataAuditoria) {
        dataTable = [];
        dataTable.push({
          nombre_matriz: this.dataAuditoria.detalle_matriz.nombre,
          fecha_auditoria: this.dataAuditoria.fecha_llamada,
          tipo_audioria: this.dataAuditoria.tipo_audioria.nombre,
          calificacion: this.dataAuditoria.total_punaje.match(/(\d*.\d{0,3})/)[0]
        });
        this.dataSource = dataTable;
        modulosForm.clear();
        this.dataAuditoria.items_afectados.forEach((modulo,iModule) => {
          modulosForm.push(this.fb.group({
            calificacion: [{value:1,disabled:true}],
            created_at: modulo.created_at,
            cumple: modulo.cumple,
            deleted_at: modulo.deleted_at,
            detalle_item: modulo.detalle_item,
            id: modulo.id,
            items: this.fb.array([]),
            matriz_auditoria_id: modulo.matriz_auditoria_id,
            modulo_id: modulo.modulo_id,
            nivel: modulo.nivel,
            no: modulo.no,
            no_aplica: modulo.no_aplica,
            no_cumple: modulo.no_cumple,
            padre_id: modulo.padre_id,
            puntaje: modulo.puntaje,
            puntaje_obtenido: modulo.puntaje_obtenido,
            si: modulo.si,
            tipo_matriz_id: modulo.tipo_matriz_id,
            updated_at: modulo.updated_at,
          }));
          modulo.items.forEach((item,iItem) => {
            (this.modulesForm.at(iModule).get('items') as FormArray).push(this.fb.group({
              calificacion: [{value:1,disabled:true}],
              created_at: item.created_at,
              cumple: item.cumple,
              deleted_at: item.deleted_at,
              detalle_item: item.detalle_item,
              id: item.id,
              matriz_auditoria_id: item.matriz_auditoria_id,
              modulo_id: item.modulo_id,
              nivel: item.nivel,
              no: item.no,
              no_aplica: item.no_aplica,
              no_cumple: item.no_cumple,
              padre_id: item.padre_id,
              puntaje: item.puntaje,
              puntaje_obtenido: item.puntaje_obtenido,
              si: item.si,
              subitems: this.fb.array([]),
              tipo_matriz_id: item.tipo_matriz_id,
              updated_at: item.updated_at,
            }));
            item.subitems.forEach(subItem => {
              ((this.modulesForm.at(iModule).get('items') as FormArray).at(iItem).get('subitems') as FormArray).push(this.fb.group({
                created_at: subItem.created_at,
                cumple: subItem.cumple,
                deleted_at: subItem.deleted_at,
                detalle_item: subItem.detalle_item,
                id: subItem.id,
                matriz_auditoria_id: subItem.matriz_auditoria_id,
                modulo_id: subItem.modulo_id,
                nivel: subItem.nivel,
                no: subItem.no,
                no_aplica: subItem.no_aplica,
                no_cumple: subItem.no_cumple,
                padre_id: subItem.padre_id,
                puntaje: subItem.puntaje,
                puntaje_obtenido: subItem.puntaje_obtenido,
                si: subItem.si,
                tipo_matriz_id: subItem.tipo_matriz_id,
                updated_at: subItem.updated_at,
                calificacion: {value:1,disabled:true}
              }));
            });
          });
        });
        this.calificacionesForm.get('txtAuditoria').setValue(this.dataAuditoria.observacion_auditor);
        this.calificacionesForm.get('retroalimentacion_auditor').setValue(this.dataAuditoria.retroalimentacion_auditor);
        this.calificacionesForm.get('retroalimentacion_supervisor').setValue(this.dataAuditoria.retroalimentacion_supervisor);
        this.calificacionesForm.get('compromiso_calidad').setValue(this.dataAuditoria.compromiso_calidad);
        this.calificacionesForm.get('compromiso_operacion').setValue(this.dataAuditoria.compromiso);
      }
    });
  }

  createForm(){
    this.calificacionesForm = this.fb.group({
      modulos:this.fb.array([]),
      txtAuditoria:[{value:'',disabled:true}],
      retroalimentacion_auditor:[{value:'',disabled:true}],
      retroalimentacion_supervisor:[{value:'',disabled:true}],
      compromiso_calidad:[{value:'',disabled:true}],
      compromiso_operacion:[{value:'',disabled:true}],
    });
  }

  get modulesForm(){
    return this.calificacionesForm.get('modulos') as FormArray;
  }

}
