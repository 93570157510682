<button mat-icon-button mat-dialog-close style="float: right;"> <i class="fi-rr-cross"></i> </button>
<h3 mat-card-title>Exámenes Médicos</h3>
<hr>
<mat-dialog-content>
    <div fxLayout="row">
        <table mat-table [dataSource]="dataSource" matSort  fxFlex>

            <ng-container matColumnDef="test">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Exámen </th>
                <td mat-cell *matCellDef="let row"> {{row.medical_type_test.name}} </td>
            </ng-container>


            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Estado </th>
                <td mat-cell *matCellDef="let row"> {{row.stateName}} </td>
            </ng-container>
            <!-- name -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Fecha</th>
                <td mat-cell *matCellDef="let row"> {{row.date}} </td>
            </ng-container>
            <!-- name -->
            <ng-container matColumnDef="ips">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> IPS </th>
                <td mat-cell *matCellDef="let row"> {{row.ips.name}} </td>
            </ng-container>

            <!-- cellphone -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button matTooltip="Cambiar Estado" (click)="changeStatus(row)"> <i class="fi-rr-checkbox"></i> </button>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8">No hay exámenes disponibles.</td>
            </tr>
        </table>
    </div>

    <br>

</mat-dialog-content>