import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../core/services/rest/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TestsService {

  private server =  environment.RRHH_URL;
  user;
  testsList;

  constructor(private http: HttpClient, private activeRoute: ActivatedRoute, private authService: AuthService) {
    this.user = this.authService.getUser();
   }
   httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  getTestsList() {
    return this.http.get(`${this.server}tests`, this.httpOptions);
  }

  getTestByCandidate(candidateId){
    return this.http.get(`${this.server}candidate/tests/${candidateId}`);
  }

  saveTests(entries: any){
    entries.userId = this.user.rrhh_id;
    return this.http.post(`${this.server}candidate/assign/test`, entries);
  }
  saveEvalutationOrAssesments(candidateTestId, params){

    return this.http.post(`${this.server}candidate/test/feedback/${candidateTestId}`, params);

  }

  saveResourcesFromAdmin(candidateTestId, params){

    return this.http.post(`${this.server}candidate/test/resources/${candidateTestId}`, params);

  }

  uploadFiles(entries) {
    const formData = new FormData();
    formData.append('attachment', entries.attachment);
    formData.append('description', entries.description);
    formData.append('source', entries.source);
    formData.append('candidateId', entries.candidateId);
    formData.append('requestId', entries.requestId);
    formData.append('type', entries.type);
    return this.http.post(`${this.server}candidate/test/admin/resources`, formData);
  }

  finishTest(candidateId, requestId){
    return this.http.get(`${this.server}candidate/finished/test/${candidateId}/${requestId}`);
  }

  sendTest(candidateTestId, params){

    const formData = new FormData();
    formData.append('candidateAttachment', params.candidateAttachment);
    formData.append('comments', params.comments);
    formData.append('candidateAttachmentName', params.candidateAttachmentName);
    return this.http.post(`${this.server}candidate/test/${candidateTestId}`, formData);
  }
  downloadFile(fileId, type){
    return this.http.get(`${this.server}candidate/download/test/${fileId}/${type}`, {responseType:'blob'});
  }
  getUsersByArea(entries){
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}users/role`, {params});
  }
  getCandidatesAssessmets(request_id, entries){
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}assessment/candidate/${request_id}`, {params});
  }
  getAssessmets(){
    return this.http.get(`${this.server}assesments/test`);
  }
  getRoomsAssessmets(){
    return this.http.get(`${this.server}assessment/room`);
  }
  getRoomAssessmet(id){
    return this.http.get(`${this.server}assessment/room/${id}`);
  }
  getCandidateAssessmet(id){
    return this.http.get(`${this.server}assessment/room/assessment/${id}`);
  }
  postRoomAssessmet(form){
    return this.http.post(`${this.server}assessment/room/create`, form);
  }
  deleteCndidateToAssessmet(id){
    return this.http.delete(`${this.server}assessment/room/candidate/${id}`);
  }
  updateRoomAssessmet(id, form){
    return this.http.put(`${this.server}assessment/room/${id}`, form);
  }
  updateAssessmentTest(id, form){
    return this.http.put(`${this.server}candidate/assessments/competence/${id}`, form);
  }
  getAssessmentScore(id, form){
    return this.http.put(`${this.server}candidate/assessments/evaluation/${id}`, form);
  }
  updateAssessmentSection(id,form){
    return this.http.put(`${this.server}candidate/assessments/save/${id}`, form);
  }
}
