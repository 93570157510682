<div class="main-container row">
    <h1>Gestión de Cargos</h1>
    <p>Bienvenido al módulo de cargos, aquí podrás visualizar todos los cargos del sistema. <br> Recuerda que para crear un cargo,
        <strong>primero debes buscarlo y cerciorarte de que ya exista.</strong>
    </p>
    <div class="filter col-6">
        <mat-form-field appearance="fill">
            <mat-label>Buscar por cargo o código nómina</mat-label>
            <input matInput #filter (keydown.enter)="filterByValue(filter.value)">
            <button mat-icon-button matSuffix (click)="filterByValue(filter.value)"><mat-icon>search</mat-icon></button>
        </mat-form-field>
       <!--  <button *ngIf="!positions" mat-flat-button color="primary" (click)="openCreate('Editar', row.id)">Agregar cargo</button> -->
    </div>
    <div class="col-6" align="end">
        <button class="bton-create"  mat-flat-button color="primary" (click)="openCreate('Crear')">Crear cargo</button>
    </div>
    
    <div class="table">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="actions">
                <th class="start" mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row">
                    <!-- <button mat-icon-button  matTooltip="Borrar cargo" (click)="deletePosition(row.id)">
                        <span class="fi-rr-trash"></span>
                     </button> -->
                     <button mat-icon-button  matTooltip="Editar cargo"  (click)="openCreate('Editar', row)">
                        <span class="fi-rr-edit"></span>
                     </button>
                     <button mat-icon-button (click)="viewPosition(row)"  matTooltip="Ver cargo">
                        <span class="fi-rr-eye"></span>
                     </button>

                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Nombre </th>
                <td mat-cell *matCellDef="let row"> {{row.type_function_name}} </td>
            </ng-container>
            <ng-container matColumnDef="key">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Código </th>
              <td mat-cell *matCellDef="let row"> {{row.key}} </td>
          </ng-container>

            <ng-container matColumnDef="postition_category_id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Categoría</th>
                <td mat-cell *matCellDef="let row"> {{row.type_funtions}} </td>
            </ng-container>

            <ng-container matColumnDef="created_at">
                <th class="end" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha creación </th>
                <td mat-cell *matCellDef="let row"> {{row.created_at | date}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8">No hay cargos que coincidan con la busqueda</td>
            </tr>
        </table>

        <mat-paginator [length]="length"
                [pageSize]="pageSize"
                (page)="getPaginatorData($event)"
                [pageSizeOptions]="pageSizeOptions" #paginator>
        </mat-paginator>
    </div>
</div>
