import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const NOMINA_URL = environment.NOMINA_URL;

@Injectable({
  providedIn: 'root'
})
export class LiquidationsService {
  private server = environment.RRHH_URL;
  user;

  constructor(private http: HttpClient, private activeRoute: ActivatedRoute) {
  }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener hoja de vida usuario
   */
  getCVUserLoggued(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(`${ this.server }user/cv/${ id }`, httpOptions);
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para liquidación de usuario
   */
  createLiquidations(body: any) {
    return this.http.post<any>(`${ NOMINA_URL }liquidations/save`, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener los usuarios en base a filtros
   */
  getLiquidationsBy(state?: any, idTipDoc?: any, document?: any, campaign_id?: any, perPage: any = 5, Page?: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('state', state || '')
        .set('documento', document || '')
        .set('documentType', idTipDoc || '')
        .set('campaign_id', campaign_id || '')
        .set('perPage', perPage)
        .set('page', Page || 1)
    };
    return this.http.get<any>(`${ NOMINA_URL }users/all`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener el reporte de los usuarios activos/inactivos
   */
  getReport(state?: any, idTipDoc?: any, document?: any, campaign_id?: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('state', state || '')
        .set('documento', document || '')
        .set('documentType', idTipDoc || '')
        .set('campaign_id', campaign_id || ''),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${ NOMINA_URL }users/report`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }
}
