import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NetworkUsersService } from 'src/app/modules/ciu/services/rest/network-users.service';
import Swal from 'sweetalert2';
import { ActivateUserFormComponent } from './dialogs/activate-user-form/activate-user-form.component';
import * as moment from 'moment';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-network-users',
  templateUrl: './network-users.component.html',
  styleUrls: ['./network-users.component.sass']
})
export class NetworkUsersComponent implements OnInit {

  data: any[] = [];
  tableColumns: string[] = ['unlocked_user', 'unlocker_user', 'created_at'];
  //Paginador
  length: number;
  pageSize: number = 5;
  pageSizeOptions: number[] = [5, 10, 50, 100];
  page: number = 1;
  //Formulario de filtros
  filters: FormGroup;
  now:Date = new Date();

  @Input() tab_type: number;


  constructor(public dialog: MatDialog, private formBuilder: FormBuilder, private networkUsersService: NetworkUsersService, private alertService:AlertsService) { }

  ngOnInit(): void {
    this.getInfo(this.page, this.pageSize)
    this.initializeForm()
  }

  /**
  * Metódo que da funcionalidad a la paginación
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
  paginatorEvent(event: any) {
    this.pageSize = event.pageSize
    this.page = event.pageIndex + 1
    this.getInfo(this.page, this.pageSize)
  }

  /**
  * Metódo que inicializa el formulario de filtros
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
  initializeForm() {
    this.filters = this.formBuilder.group({
      start_date: ['', []],
      end_date: ['', []],
      unlocker_user: ['', []],
      unlocked_user: ['', []]
    })
  }

  /**
  * Metódo que obtiene los logs de activación de usuarios
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
  getInfo(page: number, pageSize: number, start_date: string = "", end_date: string = "", unlocker_user: string = "", unlocked_user: string = "") {
    var formatted_start_date = start_date ? moment(start_date).format('YYYY-MM-DD') : ""
    var formatted_end_date = end_date ? moment(end_date).format('YYYY-MM-DD') : ""
    this.networkUsersService.getManageNetworkUsersList(page, pageSize, formatted_start_date, formatted_end_date, unlocker_user, unlocked_user).subscribe((resp) => {
      this.data = resp.data
      this.length = resp.total;
      this.pageSize = resp.per_page;
    })
  }

/**
* Metódo que obtiene los log de activación de usuarios aplicando filtros
* @param event
* @author Juan Carlos Alonso
* @createdate 15-05-2024
*/
  filterData() {
    this.getInfo(this.page, this.pageSize, this.controls.start_date.value, this.controls.end_date.value, this.controls.unlocker_user.value, this.controls.unlocked_user.value)
  }

  /**
* Metódo que limpia los filtros
* @param event
* @author Juan Carlos Alonso
* @createdate 15-05-2024
*/
  clearFiltersForm() {
    this.filters.reset()
    this.controls.start_date.setValue('')
    this.controls.end_date.setValue('')
    this.controls.unlocker_user.setValue('')
    this.controls.unlocked_user.setValue('')
    this.getInfo(this.page, this.pageSize)
  }

  /**
* Metódo que retorna los controles del formulario
* @param event
* @author Juan Carlos Alonso
* @createdate 15-05-2024
*/
  get controls() {
    return this.filters.controls
  }

  /**
  * Metódo para descargar los logs
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
  downloadLogs() {
    this.networkUsersService.downloadLogs().subscribe(resp => {
     let date = moment(this.now).format('YYYY-MM-DD')
     let blob = new Blob([resp], { type: resp.type })
     let downloadURL = window.URL.createObjectURL(blob)
     let link = document.createElement('a')
     link.href = downloadURL
     link.download = `Historico_activaciones_usuarios_de_red_${date}`
     link.click()
    }, (error) => {
      this.alertService.alertError("¡Atención!", error.error.error)
    })
  }

  /**
  * Metódo que despliega el formulario de activación
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
  activateUserDialog() {
     const dialogConfig = new MatDialogConfig();
     dialogConfig.id = 'dialogActivateNetworkUser';
     dialogConfig.width = '50%';
     dialogConfig.height = 'auto';
     // dialogConfig.height = '90vh';

     const dialogRef = this.dialog.open(ActivateUserFormComponent, dialogConfig);
     dialogRef.afterClosed().subscribe((result) => {
       if (result) {
         if(result.created){
           Swal.fire({
             icon: 'success',
             title: '¡Felicidades!',
             text: 'Se ha activado el usuario de red con éxito.',
             showConfirmButton: false,
             timer: 3000
           });
         }
         this.getInfo(this.page, this.pageSize);
       }
     });

  }


}
