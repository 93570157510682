import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { SPEECH_URL } = environment;

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  constructor(private httpClient: HttpClient) { }

  /**
   * Metodo que retorna los errores generados por HTTP. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-19
   * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  
  /**
   * Metodo que crea la peticion de analisis
   * @author Yeison Sepulveda
   * @createdate 2024-02-19
   * @param request Informacion de los registros a analizar.
  */
  newRequestAnalytics(request: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}request/`, request).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo que reprocesa el audio para nuevo analisis
   * @author Yeison Sepulveda
   * @createdate 2024-02-19
   * @param request Informacion de los registros a analizar.
  */
  duplicateUpdateAnalytics(request: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}initialRequest/duplicateUpdate`, request).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo trae el audio del backend poara su reproduccion
   * @author Yeison Sepulveda
   * @createdate 2024-02-19
   * @param recordingId Id de la grabacion
  */
  getAudioUrl(recordingId: number): string {
    return `${SPEECH_URL}audio/${recordingId}`;
  }

}