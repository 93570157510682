import { BookingCrmComponent } from './modules/bookings/booking-crm/booking-crm.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BookingCreateComponent } from './modules/bookings/booking-create/booking-create.component';
import { BookingsAdminComponent } from './modules/bookings/bookings-admin/bookings-admin.component';
import { BookingsMainComponent } from './modules/bookings/bookings-main/bookings-main.component';
import { ResourcesMainComponent } from './modules/resources/resources-main/resources-main.component';
import { ReservasComponent } from './reservas.component';

const routes: Routes = [
  { path: '', component: ReservasComponent},
  { path: 'mis-reservas', component: BookingsMainComponent },
  { path: 'reservas-crm', component: BookingCrmComponent },
  { path: 'reservar/:id/:type', component: BookingCreateComponent},
  { path: 'admin', component: BookingsAdminComponent},
  { path: 'recursos', component: ResourcesMainComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReservasRoutingModule { }
