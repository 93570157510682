import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ClientesService } from '../../../services/rest/clientes.service';
import { OportunidadesService } from '../../../services/rest/oportunidades.service';
import { debounceTime } from 'rxjs/operators';
import { saveAs as importedSaveAs } from 'file-saver';
import * as moment from 'moment';
import 'moment-timezone';
import { Router } from '@angular/router';

@Component({
  selector: 'app-clientes-proceso',
  templateUrl: './clientes-proceso.component.html',
  styleUrls: ['./clientes-proceso.component.sass']
})
export class ClientesProcesoComponent implements OnInit {

  length;
  pageSize = 5;
  page = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  public editAvailable: boolean;
  displayedColumns: string[] = ['actions', 'cliente', 'documento', 'name', 'comercial', 'linea', 'meses', 'valor', 'channel' ,'state'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  arrayservicios: any[] = [];
  arraycomercial: any[] = [];
  element = false;

  nameControl = new FormControl();
  documentControl = new FormControl();
  typeControl = new FormControl();
  comercialControl = new FormControl();
  lineServiceControl = new FormControl();
  stateControl = new FormControl();
  channelControl = new FormControl();
  nameClient: any = '';
  document: any = '';
  type: any = '';
  comercial: any = '';
  lineService: any = '';
  state: any = '';
  channelSelected: any = '';

  stateData: any[] = [
    { id: 'prospecto', name: 'Prospecto' },
    { id: 'acercamiento', name: 'Acercamiento Comercial' },
    { id: 'construccion', name: 'Construcción de Propuesta' },
    { id: 'enviada', name: 'Enviada' },
    { id: 'negociacion', name: 'Negociación/Sustentación' },
    { id: 'cierre', name: 'Cierre' }
  ];
  channelsOptions: any[];

  constructor(
    private clientesService: ClientesService,
    private oportunidadservice: OportunidadesService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.clientesService.getComercialsList().subscribe((resp: any) => {
      this.arraycomercial = resp.data;
    });

    this.clientesService.getLineServices().subscribe((resp: any) => {
      this.arrayservicios = resp.data;
    });

    this.oportunidadservice.getChannels().subscribe((resp:any) => {
      this.channelsOptions = resp.data;
    });

    //filtros detectar cambios
    this.nameControl.valueChanges.pipe(debounceTime(1000)).subscribe(res => {
      this.resetPaginator();
      this.nameClient = res;
      this.getOportunitiesBy();
    });
    this.documentControl.valueChanges.subscribe(resp => {
      this.resetPaginator();
      this.document = resp;
      this.getOportunitiesBy();
    });
    this.typeControl.valueChanges.subscribe(resp => {
      this.resetPaginator();
      this.type = resp;
      this.getOportunitiesBy();
    });
    this.comercialControl.valueChanges.subscribe(resp => {
      this.resetPaginator();
      this.comercial = resp;
      this.getOportunitiesBy();
    });
    this.lineServiceControl.valueChanges.subscribe(resp => {
      this.resetPaginator();
      this.lineService = resp;
      this.getOportunitiesBy();
    });
    this.stateControl.valueChanges.subscribe(resp => {
      this.resetPaginator();
      this.state = resp;
      this.getOportunitiesBy();
    });
    this.channelControl.valueChanges.subscribe(resp => {
      this.resetPaginator();
      this.channelSelected = resp;
      this.getOportunitiesBy();
    });
    this.getOportunitiesBy();

  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-10-26
  * Metodo para mostrar y ocultar los filtros
  */
  showData() {
    if (this.element == true) {
      return (this.element = false);
    } else {
      return (this.element = true);
    }

  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-26
  * Metodo que se encarga del paginador al momento de cambiar de pagina
  * @param {any} event evento que trae la pagina a listar
  */
  pageEvent(event: any): void {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getOportunitiesBy();
  }

  /**
  * @author Javier Castañeda
  * @purpose resetea el paginador
  * @createdate 2022-10-29
  */
  resetPaginator(): void {
    this.length = 0;
    this.pageSize = 5;
    this.page = 1;
    this.paginator.firstPage();
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la consulta y filtrado de oportunidades en proceso
  */
  getOportunitiesBy() {
    let filters = {
      tap: 'proceso',
      client: this.nameClient,
      service_line: this.lineService,
      comercial: this.comercial,
      document: this.document,
      id_type: this.type,
      state: '',
      channel: this.channelSelected,
      step: this.state,
      perPage: this.pageSize,
      page: this.page
    };
    this.oportunidadservice.getOportunitiesHistoryBy(filters).subscribe(resp => {
      this.dataSource.data = resp?.data?.data;
      this.length = resp.data.total;
    });
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo de descarga de reporte de oportunidades en proceso
  */
   downloadReport() {
    let filters = {
      tap: 'proceso',
      client: this.nameClient,
      service_line: this.lineService,
      comercial: this.comercial,
      document: this.document,
      id_type: this.type,
      state: '',
      step: this.state,
    };
    let date = new Date();
    let reportDate = moment(date).tz('America/Bogota').format('YYYY-MM-DD')
    this.oportunidadservice.getOportunitiesHistoryReportBy(filters).subscribe(resp => {
      importedSaveAs(resp,`Reporte_oportunidades_en_proceso_${reportDate}.xlsx`);
    });
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-11-01
  * Metodo para gestionar la oportunidad
  */
   manageOpportunities(idClient: number, id: number){
    this.router.navigate([`/mios/crm-cial/creop/${idClient}`], { queryParams: { type: 'edit', idOportunity: id, tab: 1, table: 1 }});
  }

}
