<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>
<div  fxLayout="column">

<h3>Buscar Colaborador</h3>
<hr>
<br>
<mat-form-field fxFlex appearance="fill">
    <mat-label>Documento de Identidad</mat-label>
    <input matInput type="number" [formControl]="documentId">
    <button mat-icon-button matSuffix (click)="searchUser()" [disabled]="documentId.invalid" matTooltip="Buscar Responsable">  <i class="fi-rr-search" ></i> </button>
</mat-form-field>
<hr *ngIf="usersFound.length > 0">
</div>
<mat-dialog-content>
    <p *ngIf="usersFound.length > 0">Seleccione</p>
       <mat-selection-list [multiple]="false">
        <mat-list-option class="option" *ngFor="let user of usersFound" [value]="user" (click)="selectUser(user)">
                <span>{{user.name}}</span>
            <div>
                <mat-chip-list>
                    <mat-chip *ngFor="let chip of user.roles">
                        {{chip}}
                    </mat-chip>
                </mat-chip-list>
            </div>
            

        </mat-list-option>
  
    </mat-selection-list>  

</mat-dialog-content>