import { Component, OnInit, ViewChild } from '@angular/core';
import { CandidatesService } from '../../../services/candidates.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CheckAttachmentComponent } from '../check-attachment/check-attachment.component';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { ContractCreationComponent } from '../contract-creation/contract-creation.component';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { saveAs as importedSaveAs } from "file-saver";
import {Location} from '@angular/common';
import { FilesViewComponent } from 'src/app/shared/files-view/files-view.component';
import { SignaturePad } from 'angular2-signaturepad';
import { CandidateCommentsComponent } from '../candidate-comments/candidate-comments.component';
import {switchMap} from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { ModalCampaignEditComponent } from '../src/app/modules/rrhh/modules/candidates/modal-campaign-edit/modal-campaign-edit.component';
import { PaymentSlipService } from '../../../services/paymentSlip.service';
import { UsersService } from '../../../services/users.service';
import {AttachmentDeleteFormComponent} from '../attachment-delete-form/attachment-delete-form.component';
import {PersonalReferenceUpComponent} from '../personal-reference-up/personal-reference-up.component';

@Component({
  selector: 'app-candidate-cv',
  templateUrl: './candidate-cv.component.html',
  styleUrls: ['./candidate-cv.component.sass']
})


export class CandidateCvComponent implements OnInit {
  checkControl = new FormControl('1');
  fechaCita;
  identity_document: any = [];
  personal_reference: any = [];
  academic_reference: any = [];
  job_reference: any = [];
  adres_certified: any = [];
  ruaf_certified: any = [];
  bank_certified: any = [];
  photo: any = [];
  antecedentes_penales: any = [];
  eps: any = [];
  arl: any = [];
  afc: any = [];
  contrato: any = [];
  firma: any = [];
  codigo_qr: any = [];
  migracion_colombia:any = [];
  truora_aprobada:boolean = false;

  viewAgreement:boolean= false
  checkAgreement:boolean= false
  checkCredencials:boolean= true

  agreements:any = [];

  hiring:boolean= false
  candidateHistory: any = [];

  documentsCandidate: any = [];

  candidateId;
  candidateStatus = null;
  candidate: any = [];

  attachments: any = [];

  attachmentId;

  scheduledBtn = 'Agendar';
  contract:any;
  contractPhase= null
  documentToFilter = ['photo','identity_document','personal_reference','academic_reference','job_reference','adres_certified','ruaf_certified','bank_certified'];
  documentToOperationFilter = ['antecedentes_penales','eps','arl','afc', 'truora'];
  generateDocumentToActionsFilter = ['contrato','acuerdo_confidencialidad','acuerdo_imagen','consentimiento'];
  documentToActionsFilter = ['firma_contrato','firma_acuerdo_confidencialidad','firma_acuerdo_imagen','firma_consentimiento'];

  candidateDocuments:any = [];
  candidateOtherDocuments:any = [];
  candidateDocumentosEliminados:any = [];

  documentGeneratedByOperation: any = [];
  documentsToUploadToOperation:any = [];
  data:any
  now = new Date().toISOString().split('.')[0];
  displayedColumns: string[] = ['actions', 'file', 'name', 'state'];
  displayedDocumentsColumns: string[] = ['actions', 'file', 'name', 'state', 'templates'];
  disabledDocument:boolean= true
  validator=false
  selectCertifications = new FormControl('');
  selectYear = new FormControl('');
  certificationsType = [
    {key:'certificado-laboral', name:'Certificado Laboral'},
    {key:'desprendible-de-pago', name:'Desprendibles de pago'}
  ];
  public years: { id, name }[] = [];
  public paymentCertificates: any = [];
  public laborCertificates: any = [];
  public rrhh_id = null;
  public months: { id, name }[] = [
    { id: 1, name: 'ENERO' },
    { id: 2, name: 'FEBRERO' },
    { id: 3, name: 'MARZO' },
    { id: 4, name: 'ABRIL' },
    { id: 5, name: 'MAYO' },
    { id: 6, name: 'JUNIO' },
    { id: 7, name: 'JULIO' },
    { id: 8, name: 'AGOSTO' },
    { id: 9, name: 'SEPTIEMBRE' },
    { id: 10, name: 'OCTUBRE' },
    { id: 11, name: 'NOVIEMBRE' },
    { id: 12, name: 'DICIEMBRE' }
  ];

  constructor(private serviceCandidate: CandidatesService,
              private alertService: AlertsService,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              public _location: Location,
              public auth:AuthService,
              public paymentSlipService: PaymentSlipService,
              private usersService: UsersService
  ) {}

  ngOnInit(): void {
    this.rrhh_id = this.auth.getUser().rrhh_id ?? null;
    this.route.paramMap.subscribe(params => {
      this.candidateId = params.get('id');
    });
    this.auth.getUser().roles.forEach(element => {
      if(element == 'rrhh::coordinador-de-contratacion' || element == 'rrhh::analista-de-contratacion'){
        this.hiring = true
      }
      if(element == 'rrhh::validador-documentos'){
        this.validator = true
      }
    });

    this.getCandidate();

    this.generateRangeYear(2022);
    let date = new Date();
    let year = date.getFullYear();
    this.selectYear.setValue(year);

    this.selectYear.valueChanges.subscribe(resp => {
      this.selectCertifications.setValue(this.selectCertifications.value);
    });

    this.selectCertifications.valueChanges.subscribe(resp => {
      switch (resp) {
        case 'certificado-laboral':
          this.serviceCandidate.getHiredUserID(this.candidateId).subscribe((resp: any) => {
            if (resp != false) this.getLaborCertication();
          });
          break;
        case 'desprendible-de-pago':
          this.serviceCandidate.getHiredUserID(this.candidateId).subscribe((resp: any) => {
            if (resp != false) this.getPaymentsSlipsCertifications(this.selectYear.value, resp.id);
          });
          break;
      }
    });
  }

  documentView(url, name, file?){
    const checkDialog = this.dialog.open( FilesViewComponent, {
      disableClose: true,
      data: { url: url, name:name, file:file}
    });
  }
  async getCandidate(credentials?) {
    this.serviceCandidate.candidateAll(this.candidateId).subscribe( (resp:any) => {
      this.candidate = resp;
      this.checkControl = this.candidate.passive_holiday;
      this.candidateStatus = this.candidate.state_id;
       if(this.candidate && this.candidate.id_type.key != 'CC'){
        this.documentToOperationFilter.push('migracion_colombia');
      }
      this.fechaCita = resp.scheduleToSign ?  new Date(resp.scheduleToSign).toISOString().replace( 'Z', '' )  : null;
      if(resp.scheduleToSign){
        this.scheduledBtn = 'Reagendar';
      }
      if(this.candidate.agreementRules != null){
        this.viewAgreement= true
        this.disabledDocument = false
      }
      this.contractPhase = this.viewAgreement ? 'Ver condiciones del contrato': 'Crear condiciones del contrato'
      if(credentials)
      {
        this.checkCredencials = true;
        setTimeout((ref) => {
           let objDiv = document.getElementById('credentials');
           objDiv.scrollIntoView()
        }, 1500);
      }
    });
  }

  checkCanUpdateEmail(): boolean{
    if (this.hiring && this.candidate.state_id === 6) {
      return true;
    } else {
      return false;
    }
  }

  activar_documento(row){
    let obj = this;
    this.serviceCandidate.activarDocumentos(row.id).subscribe( (resp: any) => {
      obj.getDocuments(1);
    });
  }

  getDocuments(filter) {

    this.serviceCandidate.getDocumentosInactivos(this.candidateId).subscribe( (resp: any) => {
      this.candidateDocumentosEliminados = resp;
    });


    this.serviceCandidate.getAttachments(this.candidateId).subscribe( (resp: any) => {
        this.documentsCandidate = resp;

        const truora = this.documentsCandidate.find(element=>element.fileKey == 'truora');
        this.truora_aprobada=false;
        if(truora.state=="Aprobado"){
          this.truora_aprobada=true;
        }

      if(filter == OPTION_TO_FILTER.document){
        /**
         * Solo se filtraran documentos para el tab documentos
         */
        this.candidateDocuments = resp.filter( (elem:any) => {
          return this.documentToFilter.indexOf(elem.fileKey) > -1;
        })
        this.candidateOtherDocuments = resp.filter( (elem:any) => {
          return this.documentToOperationFilter.indexOf(elem.fileKey) > -1;
        })

      }else if(filter == OPTION_TO_FILTER.actions){
         /**
         * Solo se filtraran documentos para el tab Actions
         */
        this.documentGeneratedByOperation = resp.filter( (elem:any) => {
          return this.generateDocumentToActionsFilter.indexOf(elem.fileKey) > -1;
        })

        this.documentsToUploadToOperation = resp.filter( (elem:any) => {
          return this.documentToActionsFilter.indexOf(elem.fileKey) > -1;
        })
        /**
         * Revisa si se aprobó el contrato para habilitar la generacion de credenciales
         */


        const found1 = this.documentsToUploadToOperation.find(element=>element.fileKey == 'firma_contrato')
        if(found1 && found1.state == 'Pendiente'){
          this.checkAgreement = true
        }

      }else if(filter == OPTION_TO_FILTER.all){
         /**
         * Solo se filtraran documentos para el tab Todos
         */
        this.candidateDocuments = resp.filter( (elem:any) => {
          return this.documentToFilter.indexOf(elem.fileKey) > -1;
        })
        this.candidateOtherDocuments = resp.filter( (elem:any) => {
          return this.documentToOperationFilter.indexOf(elem.fileKey) > -1;
        })
        this.documentGeneratedByOperation = resp.filter( (elem:any) => {
          return this.generateDocumentToActionsFilter.indexOf(elem.fileKey) > -1;
        })

        this.documentsToUploadToOperation = resp.filter( (elem:any) => {
          return this.documentToActionsFilter.indexOf(elem.fileKey) > -1;
        })

        /**
         * Revisa si se aprobó el contrato para habilitar la generacion de credenciales
         */

         const found1 = this.documentsToUploadToOperation.find(element=>element.fileKey == 'firma_contrato')
         if(found1.state == 'Aprobado'){
           this.checkAgreement = false
         }

      }
      if(this.documentGeneratedByOperation.length > 0){
        this.documentGeneratedByOperation.forEach(element => {
          element['disabled']=true
        });
        this.contract = this.documentGeneratedByOperation.find(element=>element.fileKey == 'contrato')
        if(this.contract.state == 'Aprobado' && this.candidate.username == ""){
          this.checkCredencials = false
        }

      }
      this.checkSign('firma_acuerdo_confidencialidad')
      this.checkSign('firma_contrato')
      this.checkSign('firma_acuerdo_imagen')
      this.checkSign('firma_consentimiento')


    });

  }
  checkSign(fileKey){
    if(this.documentsToUploadToOperation.length > 0 && this.documentGeneratedByOperation.length >0){
      let fileKey2 = fileKey.split('firma_')[1]
      let found2 = this.documentGeneratedByOperation.find(element=>element.fileKey == fileKey2)
      let found = this.documentsToUploadToOperation.find(element=>element.fileKey == fileKey)
        if(found.state == 'Aprobado'){
          found2.disabled = false;
        }
    }
  }

  onTabChanged(event) {
    let idx = event.index;
    switch (idx) {
      case 0:
        this.getCandidate();
      case 1:
        this.getDocuments(OPTION_TO_FILTER.document);
        break;
      case 2:
        this.getHistory();
        break;
      case 3:
        this.getDocuments(OPTION_TO_FILTER.actions);
        break;
    }
  }

  checkItem(document) {
    const checkDialog = this.dialog.open( CheckAttachmentComponent, {
      minWidth: '50%',
      disableClose: true,
      data: { attachmentId: document.id, key: document.fileKey }
    });
    checkDialog.afterClosed().subscribe( (params) => {
      if(params){
        this.getDocuments(OPTION_TO_FILTER.all);
      }

    });
  }

  getHistory() {
    this.serviceCandidate.getHistory(this.candidateId).subscribe( resp => {
      this.candidateHistory = resp;
    });
  }

  agendarCita () {
    this.serviceCandidate.agendarCitaFirmaContrato(this.candidateId, this.fechaCita).subscribe( (resp: any) => {
      this.getCandidate();
    });
  }

  generateContract() {

    this.serviceCandidate.generateCandidateContract(this.candidateId).subscribe(resp => {
      this.disabledDocument=false
      this.alertService.alertSuccess('Generado','El contrato se ha creado con éxito')
      this.getDocuments(OPTION_TO_FILTER.actions);
    }, err=>{
      this.disabledDocument=false
    });
  }

  generateContractv2() {

    this.serviceCandidate.generateCandidateContractv2(this.candidateId).subscribe(resp => {
      this.disabledDocument=false
      this.alertService.alertSuccess('Generado','El contrato se ha creado con éxito')
      this.getDocuments(OPTION_TO_FILTER.actions);
    }, err=>{
      this.disabledDocument=false
    });
  }

  onFileSelected(files, documentTypeId,attachmentId) {
    const MAXIMUM_SIZE_BYTES= 20000000; // 1MB = 1 millón de bytes
    const sizaMb =  MAXIMUM_SIZE_BYTES / 1000000;

    const idx = this.attachments.length;
    this.attachments = files[0];
    this.attachmentId = attachmentId;
    if (this.attachments.size > MAXIMUM_SIZE_BYTES) {
      this.alertService.alertWarning('Alerta',`El archivo supera el tamaño máximo de ${sizaMb}.`);
    }
    if (this.attachments.size < MAXIMUM_SIZE_BYTES) {
      this.saveDocuments(this.attachmentId,documentTypeId);
    }
  }

   saveDocuments(attachmentId,documentTypeId) {
    this.serviceCandidate.uploadDocumentCandidate(this.candidateId, this.attachments, documentTypeId,attachmentId).subscribe((resp: any) => {
      this.alertService.alertSuccess('¡Cargado!', 'Su documento se ha cargado correctamente');
      this.getDocuments(OPTION_TO_FILTER.all);
    });

  }

  showModalCampaignEdit() {
    const modal = this.dialog.open( ModalCampaignEditComponent, {
      width: '50%',
      disableClose: true,
      data: { candidate: this.candidate, candidateId: this.candidateId}
    });
    modal.afterClosed().subscribe( (params) => {
      this.disabledDocument = false;
    });
  }

/*   generarQR() {
    this.serviceCandidate.generateQR(this.candidateId).subscribe( resp => {
      this.disabledDocument= false
      this.getDocuments(OPTION_TO_FILTER.actions);
    },(err) => {

      this.disabledDocument= false
    });
  } */

  getFilteredByKey(array, key, value) {
    return array.filter(function(e) {
      return e[key] == value;
    });
  }

  generateDocument(document){
    this.disabledDocument= true

    switch (document) {
      case 'contrato':
        this.generateContractv2();
        break;
      case 'codigo_qr':
        this.serviceCandidate.generateQR(this.candidate.id).subscribe( (resp:any) => {
          this.alertService.alertSuccess('¡Hecho!',resp);
        },(err) => {
          this.disabledDocument= false
        })
        break;
      default:
        let params = {
                  'stateKey':'contratacion',
                  'candidateId':this.candidate.id
                }
            params['document'] = document;
            this.serviceCandidate.generateDocuments(params).subscribe( (resp:any) => {
              this.disabledDocument= false
              this.alertService.alertSuccess('¡Hecho!',resp);
              this.getDocuments(OPTION_TO_FILTER.actions);
            },err =>{
              this.disabledDocument= false;
            })
        break;
    }

  }

  /**
   * @author Karol García
   * @createdate 2021-02-10
   * Metodo generar credenciales del colaborador
   */
  generateCredentials(){
    this.checkCredencials = true;
    let saveconfirm= this.alertService.alertConfirm('¿Está seguro?', 'Esta acción generará credenciales de acceso para esta persona.')
    saveconfirm.then(res=>{
      if(res.isConfirmed){
        this.serviceCandidate.getCredencials(`candidate/credential/${this.candidateId}`).subscribe(
          res=>{
            this.checkCredencials = true;

            this.getDocuments(OPTION_TO_FILTER.all);
            this.alertService.alertSuccess('¡Creadas!', 'Las credenciales fueron creadas con éxito');
            this.getCandidate(true);
          });
      } else if (res.isDenied){
        this.checkCredencials = false;
      }
    })
  }
   /**
   * @author Karol García
   * @createdate 2021-02-10
   * Metodo crea las reglas del contrato
   */
  openContractForm(){
    const contractForm = this.dialog.open( ContractCreationComponent, {
      minWidth: '80%',
      disableClose: true,
      data: {candidateId: this.candidateId, agreementRules: this.candidate.agreementRules, action:'create', contract: this.contract, specialPolicy: this.candidate['special-policy'],infoBpms: this.candidate['info_pbms'], candidate:this.candidate}
    })
    contractForm.afterClosed().subscribe( (params) => {
      if(params){
        this.getCandidate()
        this.getDocuments(OPTION_TO_FILTER.all);
        this.viewAgreement= true
        this.disabledDocument= false;
      }
      //
    })
  }
   /**
   * @author Karol García
   * @createdate 2021-02-10
   * Metodo descarga los documentos
   */
  downdloadFile(data?, view?: boolean){
    let direction= `candidate/download/attachment/${this.candidateId}`
    let name
    if(data){
      direction= direction.concat(`/${data.id}`)
      name =  data.name
    }else{
      direction= direction.concat(`/all`)
      name = `documentos_${this.candidateId}`
    }
    this.serviceCandidate.downdloadFile(direction).subscribe( (file) =>{
      if(view){
        let fileURL = URL.createObjectURL(file);
        this.documentView(fileURL, data.name, file)

      }
      else{
        importedSaveAs(file, name);
      }
    })
  }
   /**
   * @author Karol García
   * @createdate 2021-02-10
   * Metodo envia el contrato al colaborador
   */
  sendEmail(){
    let saveconfirm= this.alertService.alertConfirm('¿Desea enviar el contrato al colaborador?')
    saveconfirm.then(res=>{
      if(res.isConfirmed){
        this.serviceCandidate.sendContract(`candidates/contract/mail/${this.candidateId}`).subscribe(res=>{
          this.alertService.alertSuccess('¡Enviado!', 'El correo de ha enviado éxitosamente');
        })
      }
    })
  }
   /**
   * @author Karol García
   * @createdate 2021-08-13
   * Captura la firma del condidato
   */
  crateSign(documentTypeId,attachmentId){
    const sing = this.dialog.open( CreateSign)
    sing.afterClosed().subscribe( (file) => {
      if(file){
        this.attachments = file;
        this.attachmentId = attachmentId;
        this.saveDocuments(this.attachmentId,documentTypeId);
      }
    })
  }
  getComments(){
    const commentDialog = this.dialog.open( CandidateCommentsComponent, {
      data: { candidateId: this.candidateId, requestId: this.candidate.requestId }
    });
    // commentDialog.afterClosed().subscribe( (params) => {
    //   this.getCandidates();

    // });
  }
  notApply(row){
    const createdAt = new Date();
    let saveconfirm= this.alertService.alertConfirm('¿Desea no aplicar este documento en el proceso?')
    saveconfirm.then(res=>{
      if(res.isConfirmed){
        this.serviceCandidate.uploadFiles(this.candidateId, null, row.fileKey).pipe(
          switchMap((resp: any) =>{
            return  this.serviceCandidate.validateDocumentCandidate(resp.id, '', createdAt, 'Aprobado', '',row.fileKey)
          })
        ).subscribe(()=>{
          this.alertService.alertSuccess('¡Hecho!', 'Este archivo No aplica');
          this.getDocuments(OPTION_TO_FILTER.document)
        })

      }
    })
  }

   /**
  * @author Daniel Dominguez
  * @createdate 2022-03-31
  * Validamos checkbox pasivo vacacional
  */
    generateHoliday(event) {
      //console.log(event.checked);
      this.checkControl = event.checked;
      //detecta los cambios en el check
      let data = {
        candidate_id: this.candidateId,
        passive_holiday: this.checkControl
      }
      this.serviceCandidate.PassiveHoliday(data).subscribe(data => {});
    }

  /**
  * @author David Reinoso | 10-feb-23
  * Funcion para generar un rango de años
  * @param min_year
  */
   generateRangeYear(min_year){
    let date = new Date();
    let year = date.getFullYear();
    let yearMin = min_year;
    while (yearMin <= year) { //Llenamos el array years con los años para el filtro
      this.years.push({ id: yearMin, name: yearMin });
      yearMin++;
    }
  }

  getLaborCertication(){
    this.usersService.getHiredUsersByCandidate(this.candidateId).subscribe((resp: any) => {
      this.laborCertificates = resp;
     });
  }

  getPaymentsSlipsCertifications(year, rrhh_id){
    this.paymentSlipService.getPaymentsNominaByUser(year, rrhh_id ?? null ).subscribe((resp:any) =>{
      this.paymentCertificates = resp?.data;
    });
  }

   /**
   * @author David Reinoso | 20-feb-23
   * Metodo para retornar el nombre del mes
   */
   getNameMount(number){
    for (let i = 0; i < this.months.length; i++) {
      if(this.months[i].id == Number(number)){
        return this.months[i].name;
      }
    }
    return "";
  }

  openCertification(type, rrhh?, month?, year?){
    switch (type) {
      case 'certificado-laboral':
        this.usersService.getCertificate('certificado_laboral','',rrhh).subscribe((resp: any) => {
          let fileURL = URL.createObjectURL(resp);
          const checkDialog = this.dialog.open(FilesViewComponent, {
            data: { url: fileURL, name: 'certificado_laboral', file: resp, print: true}
          });
        });
        break;
      case 'desprendible-de-pago':
        this.paymentSlipService.getPayment(Number(month), year).subscribe((resp: any) => {
          let fileURL = URL.createObjectURL(resp);
          const checkDialog = this.dialog.open(FilesViewComponent, {
            data: { url: fileURL, name: "desprendible_nomina", file: resp ,print: true}
          });
        })
        break;
    }
  }

  deleteFile(row) {
    const modal = this.dialog.open( AttachmentDeleteFormComponent, {
      width: '50%',
      data: { file: row }
    });
    modal.afterClosed().subscribe(() => {
      this.getDocuments(OPTION_TO_FILTER.all);
    });
  }

  uploadTemplate() {
    const dialogRef = this.dialog.open( PersonalReferenceUpComponent, {
          width: '600px',
        });

        dialogRef.afterClosed().subscribe(result => { });
  }
}

const OPTION_TO_FILTER = {
  document:1,
  actions:2,
  all:3
}
@Component({
  selector: 'create-sign',
  templateUrl: 'create-sign.html',
  styleUrls: ['./candidate-cv.component.sass']
})
export class CreateSign {
  @ViewChild(SignaturePad) public signaturePad: SignaturePad;
  option:any
  signatureImg
  signaturePadOptions: Object = {
    'minWidth': 1,
    'canvasWidth': 200,
    'canvasHeight': 163
  };
  constructor(private dialogRef: MatDialogRef<CreateSign>) {}
  clear(){
    this.signaturePad.clear();
  }
  savePad(){
    const base64Data = this.signaturePad.toDataURL();
        this.signatureImg = base64Data;
        const url = this.signatureImg;
        fetch(url)
          .then(res => res.blob())
          .then(blob => {
            const file = new File([blob], "firmaDigital",{ type: "image/png" })
            this.dialogRef.close(file)
          })
  }
}
