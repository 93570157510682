import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';


const REPORTS_URL = environment.REPORTS_URL;

@Injectable({
  providedIn: 'root'
})
export class ReportsServiceCiu {

  constructor(private http: HttpClient) { }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

   /**
   * @author Carlos Nieto
   * @createdate 2022-01-25
   * Servicio que lista todos los groupes del aplicativo ciu
   * @param reportKey key de powerbi a visualizar
   */
  getReport(reportKey:string): Observable<any>{
    return this.http.get<any>(`${REPORTS_URL}report/tokens?key=${reportKey}` )
    .pipe(
      catchError(this.handleError)
      );
  }
}
