<div class="avatar row  admin-dialog">
    <div>
      <button style="float: right;" mat-icon-button mat-dialog-close>
          <i class="fi-rr-cross-small"></i>
      </button>
    </div>

      <form class="row form " [formGroup]="solicitudesForm" (ngSubmit)="saveSolicitud(solicitudesForm)">

          <mat-dialog-content class="mat-typography">
            <h3 mat-dialog-title>
                {{ data.title }}

                <button
                  type    = "button"
                  style   = "float: right;"
                  (click) = "editSolicitud()"
                  *ngIf   = "data.solicitud.request_state.id == 2"
                  mat-icon-button >
                    <i class="fi-rr-edit"></i>
                </button>
            </h3>
              <hr>
              <div class="row">
                  <div class="col-1">
                      <button mat-icon-button class="badge " type="button" [style.backgroundColor]="data.solicitud.request_state.color">
                      <i [class]=" data.solicitud.request_state.icon" > </i>

                  </button>
                  </div>
                  <div class="col-4">

                      <mat-label style="font-weight: bold; font-size: 14px">Estado actual</mat-label>
                      <p>{{data.solicitud.request_state.name}}</p>
                  </div>
              </div>
              <hr>
              <div class="row">
                  <div class="col-4">

                      <mat-label style="font-weight: bold; ">Fecha de creación</mat-label>
                      <p>{{data.solicitud.created_at|date:'yyyy-MM-dd': 'UTC'}}</p>
                  </div>
                  <div class="col-4">

                      <mat-label style="font-weight: bold;">Nombre del solicitante</mat-label>
                      <p>{{data.solicitud.created_by}}</p>
                  </div>
                  <div class="col-4">

                      <mat-label style="font-weight: bold;">Tipo de adquisición</mat-label>
                      <p>{{data.solicitud.product_type.name}}</p>
                  </div>
              </div>
              <div class="row">
                  <div class="col-4">

                      <mat-label style="font-weight: bold;">Categoría</mat-label>
                      <p>{{data.solicitud.category.name}}</p>
                  </div>


                  <mat-form-field class="col-4" appearance="fill">
                    <mat-label>Empresa</mat-label>
                    <input type="text"
                    matInput
                    formControlName="companias"
                    [matAutocomplete]="autoCom"
                    required>
                    <mat-autocomplete  #autoCom="matAutocomplete" [displayWith]='displayFnCompania.bind(this)' required>
                      <mat-option *ngFor="let comp of filteredOptionsCompania | async" [value]="comp.id">
                        {{comp.name}}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="error.companias.errors"><span *ngIf="error.companias.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                    <mat-error *ngIf="error.companias.errors"><span *ngIf="error.companias.errors.invalidAutocompleteObject">Campaña no reconocida. Haga click en una de las opciones de autocompletar</span></mat-error>
                  </mat-form-field>

                  <div class="col-4">

                      <mat-label style="font-weight: bold;">Nombre campaña</mat-label>
                      <p>{{data.solicitud.project}}</p>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12">

                      <mat-label style="font-weight: bold;">Título solicitud</mat-label>
                      <p>{{data.solicitud.title}}</p>
                  </div>

              </div>

              <div class="row">
                  <div class="col-12">

                      <mat-label style="font-weight: bold;">Descripción solicitud</mat-label>
                      <p>{{data.solicitud.description}}</p>
                  </div>

              </div>

              <div class="row">
                  <div class="col-8">

                      <mat-label style="font-weight: bold;">Por los siguientes ítems</mat-label>

                  </div>
                  <div class="col-4">

                      <mat-label style="font-weight: bold;">Cantidad</mat-label>

                  </div>
              </div>
              <div class="row" *ngFor="let item of data.solicitud.request_items">
                  <div class="col-8">


                      <p>{{item.detail}}</p>
                  </div>
                  <div class="col-4">


                      <p>{{item.quantity}}</p>
                  </div>
              </div>

              <hr style="max-width: 100%;">

              <div class="col-12" >
                <h4 class="fw-bold">
                    Validación Almacén
                </h4>
            </div>

              <mat-form-field
              style         = "padding: 1% 0;"
              class         = "col-3"
              appearance    = "fill"
              *ngIf         = "data.solicitud.request_state_id != 8 && !viewSolciitud"
              >
                <mat-label>Estado</mat-label>
                <mat-select
                  formControlName   = "estado"
                  (valueChange)     = "cambioEstado($event)"
                  >
                    <mat-option [value]="10">Disponible</mat-option>
                    <mat-option [value]="1">No disponible</mat-option>
                </mat-select>
                <mat-error *ngIf="error.estado.errors"><span *ngIf="error.estado.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            </mat-form-field>

            <div class="mb-3">
              <mat-form-field
              *ngIf=" solicitudesForm.get('estado').value &&  !viewSolciitud "
              style="padding:1% 0;"
              class="col-12"
              appearance="fill">
                <mat-label>Observación</mat-label>
                <input matInput
                  style             = "text-transform: lowercase;"
                  type              = "text"
                  placeholder       = "Escribe la observación"
                  formControlName   = "observacion">
                  <mat-error *ngIf="error.observacion.errors"><span *ngIf="error.observacion.errors.required">¡Advertencia! Este campo es obligatorio</span></mat-error>
            </mat-form-field>
            </div>

              <p *ngIf=" viewSolciitud" >
                {{ (data.solicitud.request_observation) ?  data.solicitud.request_observation[0].observation : "" }}
              </p>


              <div style="float: right" *ngIf="!viewSolciitud">
                <button
                  mat-stroked-button
                  mat-dialog-close class="bold compras-modal-button me-2 ms-2">Cancelar</button>

                <button mat-raised-button
                  type                  = "submit"
                  color                 = "primary"
                  disabled              = '{{solicitudesForm.invalid}}'
                  class                 = "bold compras-modal-button">
                  Enviar
                </button>
              </div>
          </mat-dialog-content>




      </form>
  </div>
