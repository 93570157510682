import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyComponentService } from '../../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import { ToWords } from '../../../../../../core/to-words/to-words';
import { OrdenCompra } from '../../../Class/orden-compras';
import { OrdenesService } from '../../../../services/rest/ordenes.service';
import { ArchivosService } from '../../../../services/rest/archivos.service';
import Swal from 'sweetalert2';
import { CurrencyMaskInputMode } from 'ngx-currency';


@Component({
  selector: 'app-orden-compra',
  templateUrl: './orden-compra.component.html',
  styleUrls: ['./orden-compra.component.sass']
})
export class OrdenCompraComponent implements OnInit {

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isEditable = true;
  toWords = new ToWords();
  valorFinal=0;
  valorIva =0;
  itemsOrder:any[]=[];
  archivoSolicitud: any;
  isDiscountValid = false;

  options = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    inputMode: CurrencyMaskInputMode.NATURAL
  }

  constructor(
      private formBuilder                     : FormBuilder,
      private form                            : FormBuilder,
      private ordenService                    : OrdenesService,
      private destroyService                  : DestroyComponentService,
      private alertsService                   : AlertsService,
      private archivoService                  : ArchivosService,
      @Inject(MAT_DIALOG_DATA) public data    : any,
      private dialogRef                       : MatDialogRef<OrdenCompraComponent>
    ) { }

    ngOnInit(): void {

      this.firstFormGroup = this.formBuilder.group({
        data: [''],
        items: this.form.array([])
      });

      if(this.data.solicitud.request_items){
        const itemsRequest = this.data.solicitud.request_items.map(item => {
          if(item.observations  && typeof item.observations == 'string') {
            const itemParse = JSON.parse(item.observations)
            item.observations = itemParse
          }
          return item
        });

        itemsRequest.forEach(item => {
          this.newItems(item);
        });
      }

      this.infoFile();

      this.secondFormGroup = this.formBuilder.group({
        observations        : new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
        sub_total           : new FormControl({value:'', disabled:true}, [Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
        discount            : new FormControl('', [Validators.required, Validators.maxLength(300), Validators.minLength(3)]),
        iva                 : new FormControl({value:'', disabled:false}, [Validators.required, Validators.maxLength(300), Validators.minLength(3)]),
        total               : new FormControl({value:'', disabled:true}, [Validators.required, Validators.maxLength(300), Validators.minLength(3)]),
        value_in_letters    : new FormControl({value:'', disabled:true}, [Validators.required, Validators.maxLength(300), Validators.minLength(3)]),
        delivery_date       : new FormControl('', [Validators.required, Validators.maxLength(300), Validators.minLength(3)]),
        pay                 : new FormControl('', [Validators.required, Validators.maxLength(300), Validators.minLength(3)]),
        payment_condition   : new FormControl('', [Validators.required]),
        payment_deb         : new FormControl(''),
        payment_percent     : new FormControl(''),
        credit_condition    : new FormControl(''),
        pay_value           : new FormControl({value:'', disabled:true},[]),
        warranty            : new FormControl('', [Validators.required]),
        policy              : new FormControl('', [Validators.required]),
        ciudad              : new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]),
        direccion           : new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]),
        observacionCondiciones : new FormControl('', [Validators.required, Validators.minLength(20), Validators.maxLength(100)])

      });

      if( this.data["type"] == "edit" )
      {
        this.setEdit();
      }






    }
    get error(): any { return this.secondFormGroup.controls; }
    get errorItem():any{ return this.firstFormGroup.controls}

    get items(): FormArray {
      return this.firstFormGroup.get('items') as FormArray;
    }

    /**
    * @author Daniel Martinez
    * @createdate 2021-02-16
    * Metodo que estable las validaciones del formarray dentro del formulario
    */
    newItems(item: any = null): void{
      const newItems = this.form.group({
        id:new FormControl('',[]),
        description: new FormControl(item && item.detail ? item.detail : '', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
        specs: new FormControl(item && item.observations ? item.observations.observations : '', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
        unit: new FormControl(item && item.quantity ? item.quantity : '', [Validators.required, Validators.maxLength(5), Validators.minLength(1), Validators.min(0)]),
        unit_value: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.minLength(1), Validators.min(0)]),
        selectIva:new FormControl('', [Validators.required]),
        valorIva: new FormControl({value:'', disabled:true},[]),
        total_value: new FormControl({value:'', disabled:true}, [Validators.required, Validators.maxLength(5), Validators.minLength(1)]),
        coin_type: new FormControl('', [Validators.required])
      });
      this.items.push(newItems);
    }
    /**
    * @author Daniel Martinez
    * @createdate 2021-02-16
    * Metodo que se encarga de la eliminacion de un grupo de aplicacion y rol
    * @param indice indice del grupo de aplicacion y rol a eliminar
    */
    deleteItems(indice: number): void {
      this.items.removeAt(indice);
    }

    /**
    * validar la existencia de al menos un item al avance.
    */
    onStepChange(event){
      if(event.selectedIndex == 1)
      {
        this.validarItems()
      }
      if(event.selectedIndex == 2 ){
        this.calcularValores()
      }
   }
    validarItems(){

      if (this.items.length===0) {
        this.newItems();
      }
    }

    calcularValores(){
      let valorTotal=0;
      this.valorIva=0;

      this.firstFormGroup.getRawValue().items.forEach(element => {
          valorTotal+=element['total_value'];
        if(element['selectIva']===100){

          this.valorIva+=(element['total_value']*element['valorIva'])/100;
        }else {
          this.valorIva+=(element['total_value']*element['selectIva'])/100;
        }
      });
      this.valorFinal= valorTotal+this.valorIva;
      let valorfinalletras = this.toWords.convert( this.valorFinal);

      if(this.firstFormGroup.value.items[0].coin_type === 'USD'){
        valorfinalletras = valorfinalletras.replace('PESOS','DOLARES');
      }else if (this.firstFormGroup.value.items[0].coin_type === 'MXN') {
        valorfinalletras = valorfinalletras.replace('PESOS','PESOS MEXICANOS');
      }

      this.secondFormGroup.controls.sub_total.setValue(valorTotal);
      //this.secondFormGroup.controls.discount.setValue(0);
      if(this.data["type"] != "edit" || this.data.ordenCompra.sub_total != valorTotal){
        this.secondFormGroup.controls.iva.setValue(this.valorIva);
        this.secondFormGroup.controls.total.setValue( this.valorFinal);
        this.secondFormGroup.controls.value_in_letters.setValue(valorfinalletras);
      }

      this.calculardiscount();
    }

    calcularValorTotal(indice){
      if(this.firstFormGroup.value.items[indice].coin_type === 'COP'){
        let cantidad = this.firstFormGroup.value.items[indice].unit;
        let valor=this.firstFormGroup.value.items[indice].unit_value;

        let valorTotal = (cantidad == undefined ? 0 :cantidad)* (valor == undefined ? 0:valor);

        this.firstFormGroup.controls.items["controls"][indice].controls.total_value.setValue(valorTotal);
      }
    }

    cambioTipoMoneda(event, i){
      this.secondFormGroup.controls.discount.setValue(0);
      if(event === 'USD' || event === 'MXN'){
        this.firstFormGroup.controls.items["controls"][i].controls.total_value.enable();
        this.firstFormGroup.controls.items["controls"][i].controls.total_value.setValue();
      }else{
        this.firstFormGroup.controls.items["controls"][i].controls.total_value.disable();
        this.firstFormGroup.controls.items["controls"][i].controls.unit_value.setValue(0);
        this.firstFormGroup.controls.items["controls"][i].controls.total_value.setValue();
      }
    }


    calculardiscount(){

      const descuento= this.secondFormGroup.get('discount').value;
      const iva =this.secondFormGroup.get('iva').value;
      let valorFinal;

      if(iva != this.valorIva){
        valorFinal = (this.valorFinal-this.valorIva)-descuento+iva;
      }else{
        valorFinal = this.valorFinal-descuento;
      }

      let valorfinalletras = this.toWords.convert(valorFinal);

      if(this.firstFormGroup.value.items[0].coin_type === 'USD'){
        valorfinalletras = valorfinalletras.replace('PESOS','DOLARES');
      }else if (this.firstFormGroup.value.items[0].coin_type === 'MXN') {
        valorfinalletras = valorfinalletras.replace('PESOS','PESOS MEXICANOS');
      }

      if (valorFinal> 0 ){
        this.isDiscountValid = true;
        this.secondFormGroup.controls.total.setValue(valorFinal);
        this.secondFormGroup.controls.value_in_letters.setValue(valorfinalletras);
      } else{
        this.isDiscountValid = false;
        this.alertsService.alertError('Error', 'No se puede aplicar un descuento superior al valor total')
      }
    }

     /**
	 * @method  downloadCotizacion Valida si el formulario es valido y el descuento no es mayor a el presupuesto
	 * @author Juan Carlos Alonso Perez
	 * @create_date 18/03/2022
	 */
   validarEnvioForm(){
     if(this.secondFormGroup.valid && this.isDiscountValid){
        return true;
     }else{
       return false;
     }
   }



    cambioEstado(event, index){


      if (event === 100){
        this.firstFormGroup.controls.items["controls"][index].controls.valorIva.enable();
      } else {
        this.firstFormGroup.controls.items["controls"][index].controls.valorIva.disable();
      }

    }

    cambioPagoValue(event){
      if (event === 'Abono'|| event === 'Anticipo' || event === 'Credito'){
        this.secondFormGroup.controls.pay_value.enable();
      } else {
        this.secondFormGroup.controls.pay_value.disable();
      }

    }

    cambiarValor(value){
      let porcentaje = Number(value);

      if(porcentaje > 100 ){
        this.alertsService.alertError('Error', '¡Porcentaje no valido! El porcentaje no puede ser mayor al 100%')
        this.secondFormGroup.controls.payment_percent.setValue('');
      }
    }

    infoFile(){
      let orderCompraId;
      this.data["type"] == "edit" ? orderCompraId = this.data.solicitud.request_id : orderCompraId = this.data.solicitud.id;

      this.archivoService.getArchivosSolicitud(orderCompraId).subscribe(resp => {
        if(resp.data.length > 1){
          resp.data.forEach((element) => {
            if(element.state == 1){
              this.firstFormGroup.controls.data.setValue(element.name);
              this.archivoSolicitud = element;
            }
          });
        }else if(resp.data.length == 1){
          if(resp.data[0].state == 1){
            this.firstFormGroup.controls.data.setValue(resp.data[0].name);
            this.archivoSolicitud = resp.data[0];
          }else{
            this.firstFormGroup.controls.data.setValue('Esta orden de compra no cuenta con una cotización asociada');
          }
        }
      });

    }

    downloadFile(){
      const archivo = {
        path: this.archivoSolicitud.path
      };

      this.archivoService.downloadOrder(archivo).subscribe( (data) => {
        const a = document.createElement('a');
        const file = new Blob([data], {type: this.archivoSolicitud.fileContentType});
        a.href = URL.createObjectURL(file);
        a.download = this.archivoSolicitud.name;
        a.click();
      });
    }

    saveOrder( alertResponse:boolean ){

      const orden =  new OrdenCompra();

      orden.observations        = this.secondFormGroup.getRawValue().observations;
      orden.sub_total           = this.secondFormGroup.getRawValue().sub_total;
      orden.discount            = this.secondFormGroup.getRawValue().discount;
      orden.iva                 = this.secondFormGroup.getRawValue().iva;
      orden.calculated_iva      = this.valorIva;
      orden.total               = this.secondFormGroup.getRawValue().total;
      orden.value_in_letters    = this.secondFormGroup.getRawValue().value_in_letters;
      orden.delivery_date       = this.secondFormGroup.getRawValue().delivery_date;
      orden.warranty            = this.secondFormGroup.getRawValue().warranty;
      orden.pay                 = this.secondFormGroup.getRawValue().pay;
      orden.payment_deb         = this.secondFormGroup.getRawValue().payment_deb;
      orden.payment_percent     = (this.secondFormGroup.getRawValue().payment_percent== '' ? 0 :this.secondFormGroup.value.payment_percent);
      orden.payment_condition   = this.secondFormGroup.getRawValue().payment_condition;
      orden.credit_condition    = this.secondFormGroup.getRawValue().credit_condition;
      orden.pay_value           = (this.secondFormGroup.getRawValue().pay_value==undefined ? '':this.secondFormGroup.value.pay_value);
      orden.policy              = this.secondFormGroup.getRawValue().policy;
      orden.PurchaseOrderItem   = this.firstFormGroup.getRawValue().items;
      orden.ciudad              = this.secondFormGroup.getRawValue().ciudad;
      orden.direccion           = this.secondFormGroup.getRawValue().direccion;
      orden.observacionCondiciones = this.secondFormGroup.getRawValue().observacionCondiciones;


      if( this.data.type =="edit" )
      {
        this.ordenService.updateOrder(orden,this.data.ordenCompra.id  ).subscribe(resp=>{
          if(alertResponse){
            this.alertsService.alertWithAnyProperties('Guardado', resp.data, 'success', 'Continuar').then(res => {
              if (res.isConfirmed) {
                this.dialogRef.close();
              }
            });
            // this.alertsService.alertSuccess('Guardado', resp.data);
            // if(resp){
            //   this.dialogRef.close();
            // }
          }
        });
      }
      else
      {
        orden.request_id          = this.data.solicitud.id;
        orden.proveedor_nit       = this.data.proveedor.nit;
        orden.company_id          = this.data.solicitud.company.id;
        this.ordenService.saveOrder(orden).subscribe(resp=>{
          if( alertResponse ){
            this.alertsService.alertWithAnyProperties('Guardado', resp.data, 'success', 'Continuar').then(res => {
              if (res.isConfirmed) {
                window.location.reload();
              }
            });
            // this.alertsService.alertSuccess('Guardado', resp.data);
            // if(resp){
            //   window.location.reload();
            // }
          }
        });
      }
    }


    enviarOrder(){
      const estado ={
        state:5
      }


      //Confirma  cambio de estado de aproba a rechazada
      Swal.fire({
        icon                : 'warning',
        title               : '¿Estás seguro?',
        text                : "¿Deseas enviar la orden de compra?, recuerda que esto será definitivo y no se podrá deshacer, únicamente podrás fragmentar la orden de compra",
        showCancelButton    : true,
        focusConfirm        : false,
        confirmButtonText   : 'Aceptar',
        cancelButtonText    : "Cancelar"
      }).then( (result) => {
        if(result.isConfirmed){
          this.saveOrder(false);
          this.ordenService.updateState(estado, this.data.ordenCompra.id).subscribe((resp)=>{
            this.alertsService.alertSuccess('Orden Enviada',resp.data);
            this.dialogRef.close();
          },
          error => {
            this.alertsService.alertWarning('Atención',error.error.error);
          }
          );
        }
      } )

    }

    setEdit(){
      this.ordenService.getOrderItems(this.data.ordenCompra.id).subscribe((resp)=>{
        this.itemsOrder=resp.data;

          this.secondFormGroup.controls.pay.setValue(this.data.ordenCompra.pay);
          this.secondFormGroup.controls.payment_condition.setValue(this.data.ordenCompra.payment_condition);
          this.secondFormGroup.controls.payment_deb.setValue(this.data.ordenCompra.payment_deb);
          this.secondFormGroup.controls.payment_percent.setValue(this.data.ordenCompra.payment_percent);
          this.secondFormGroup.controls.credit_condition.setValue(this.data.ordenCompra.credit_condition);
          this.secondFormGroup.controls.pay_value.setValue(this.data.ordenCompra.pay_value);
          this.secondFormGroup.controls.warranty.setValue(this.data.ordenCompra.warranty);
          this.secondFormGroup.controls.policy.setValue(this.data.ordenCompra.policy);
          this.secondFormGroup.controls.observations.setValue(this.data.ordenCompra.observations);
          this.secondFormGroup.controls.sub_total.setValue(this.data.ordenCompra.sub_total);
          this.secondFormGroup.controls.discount.setValue(this.data.ordenCompra.discount);
          this.secondFormGroup.controls.iva.setValue(this.data.ordenCompra.iva);
          this.secondFormGroup.controls.total.setValue(this.data.ordenCompra.total);
          this.secondFormGroup.controls.value_in_letters.setValue(this.data.ordenCompra.value_in_letters);
          this.secondFormGroup.controls.delivery_date.setValue(this.data.ordenCompra.delivery_date);
          this.secondFormGroup.controls.observacionCondiciones.setValue(this.data.ordenCompra.observaciones_condiciones);
          this.secondFormGroup.controls.ciudad.setValue(this.data.ordenCompra.ciudad);
          this.secondFormGroup.controls.direccion.setValue(this.data.ordenCompra.direccion);

          this.itemsOrder.forEach((element, index) => {
            this.newItems();
            this.items.controls[index].get('id').setValue(element.id);
            this.items.controls[index].get('specs').setValue(element.specs);
            this.items.controls[index].get('description').setValue(element.description);
            this.items.controls[index].get('unit').setValue(element.unit);
            this.items.controls[index].get('unit_value').setValue(element.unit_value);
            this.items.controls[index].get('valorIva').setValue(element.iva);
            this.items.controls[index].get('selectIva').setValue(element.iva);
            this.items.controls[index].get('total_value').setValue(element.total_value);
            this.items.controls[index].get('coin_type').setValue(this.data.ordenCompra.coin_type)
          });

      });
    }


    /**
	 * @method  downloadCotizacion Permite la descarga de la Cotizacion Seleccionada para la solicitud
	 * @author Juan David Guerrero Vargas
	 * @create_date 18/03/2022
	 * @param   id_purchase_id | string PArametro ue recibe el Id de la solicitud donde se registra el archivoa descargar
	 */
	downloadCotizacion(id_solicitud_id:Number){

		this.archivoService.getArchivosSolicitud(id_solicitud_id, true).subscribe(
			resp => {
				if(resp){
					let e = resp.data[0]
					const archivo = {
						path: e.path
					  };

					this.archivoService.downloadOrder(e).subscribe( (data) => {
						const a = document.createElement('a');
						const file = new Blob([data], {type: e.fileContentType});
						a.href = URL.createObjectURL(file);
						a.download = e.name;
						a.click();

					},
					(error) =>{

						this.alertsService.alertError("Error al descargar", "Se ah producido un error en la descarga ");

					});
				}else{

					this.alertsService.alertError("No se encontro archivo adjunto", "No se ha encontrado ningun archivo adjunto seleccionado");

				}


			},
			error =>{
				this.alertsService.alertError("Archivo No encontrado", "Lo sentimos no hemos logrado encontrar el documento adjunto" );
			}
		);

	}



    ngOnDestroy(): void {
      this.destroyService.destroyComponent();
    }

  }
