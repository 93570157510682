import { state } from '@angular/animations';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const NOMINA_URL = environment.NOMINA_URL;

@Injectable({
  providedIn: 'root'
})
export class VacationsService {

  constructor(private http: HttpClient) {
  }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * @author Alejandro Castañeda
   * @purpose Servicio para obtener las novedades de vacaciones
   */
  getVacations(state, forViewBoss: any = 0) {
    const httpOptions = {
      params: new HttpParams()
        .set('state', state)
        .set('forBossView', forViewBoss)
    };
    return this.http.get<any>(`${ NOMINA_URL }holidays/showHolidays`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }


  /**
   * @author Alejandro Castañeda
   * @purpose Servicio para obtener las novedades de vacaciones por filtro
   */
  getVacationsBy(filters: any, perPage: any = 5, page: any = 1) {
    const httpOptions = {
      params: new HttpParams()
        .set('state', filters.state)
        .set('document', filters.document || '')
        .set('documentType', filters.idTipDoc || '')
        .set('campaign_id', filters.campaign_id || '')
        .set('initial_holiday_date', filters.startDate || '')
        .set('end_holiday_date', filters.endDate || '')
        .set('perPage', perPage)
        .set('page', page || 1)
        .set('forBossView', filters.forBossView || 0)
        .set('export', filters.exportData ? '1' : '0'),
      responseType: filters.exportData ? 'blob' as 'json' : 'json'
    };
    return this.http.get<any>(`${ NOMINA_URL }holidays/showHolidays`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Alejandro Castañeda
   * @purpose Servicio para obtener las novedades de vacaciones por filtro
   */
  getVacationsForReversions(filters: any, perPage: any = 5, page: any = 1) {
    const httpOptions = {
      params: new HttpParams()
        .set('document', filters.document || '')
        .set('docType', filters.docType || '')
        .set('campaignId', filters.campaignId || '')
        .set('startDate', filters.startDate || '')
        .set('endDate', filters.endDate || '')
        .set('perPage', perPage)
        .set('page', page || 1)
        .set('export', filters.exportData ? '1' : '0'),
      responseType: filters.exportData ? 'blob' as 'json' : 'json'
    };
    return this.http.get<any>(`${ NOMINA_URL }vacations/reversions`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }


  /**
   * @author Javier Castañeda  <javier.c@montechelo.com.co>
   * @purpose Servicio obtiene la información de una novedad de vacaciones
   */
  getInfoVacationId(id: number): Observable<any> {
    return this.http.get<any>(`${ NOMINA_URL }holidays/showHoliday/${ id }`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Javier Castañeda
   * @purpose Servicio para aprobar o rechazar una novedad de vacaciones
   */
  changeStatus(id: any, body: any) {
    return this.http.put<any>(`${ NOMINA_URL }holidays/aprovalHolidays/${ id }`, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Javier Castañeda
   * @purpose Servicio para aprobar o rechazar una reversion de vacaciones
   */
  changeStatusReversion(id: any, stateCode: any) {
    return this.http.put<any>(`${ NOMINA_URL }vacations/${ id }/changeStatus`, { 'state': stateCode })
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Andres Buitrago
   * @purpose Servicio para registrar la solicitud de reversion de unas vacaciones
   */
  requestReverseVacation(id: any, body: any) {
    return this.http.put<any>(`${ NOMINA_URL }vacations/${ id }/reversion`, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Andres Buitrago
   * @purpose Servicio para aprobar/rechazar la solicitud de reversion de unas vacaciones
   */
  changeStatusReverseVacation(id: any, body: any) {
    return this.http.put<any>(`${ NOMINA_URL }vacations/${ id }/changeStatus`, body)
      .pipe(
        catchError(this.handleError)
      );
  }

    /**
   * @author Daniel Dominguez <daniel.d@montechelo.com.co>
   * @purpose Servicio que obtiene los dias habiles/no habiles de un rango de fechas seleccionado para el proceso de vacaciones
   */
     getAvailableDaysForVacations(data: any): Observable<any> {
      return this.http.post<any>(`${ NOMINA_URL }vacations/checkAvailableDaysReversion`, data)
        .pipe(
          catchError(this.handleError)
        );
    }

      /**
   * @author Javier Castañeda
   * @purpose Servicio para obtener listado de usuarios y sus días disponibles de vacaciones
   */
  getavailableDaysVacationsBy(document?: any, documentType?: any, campaign_id?: any, perPage: any = 5, page: any = 1) {
    const httpOptions = {
      params: new HttpParams()
        .set('document', document || '')
        .set('documentType', documentType || '')
        .set('campaign_id', campaign_id || '')
        .set('perPage', perPage)
        .set('page', page || 1)
    };
    return this.http.get<any>(`${ NOMINA_URL }vacations/staffVacationsByAreaDirector`, httpOptions)
      .pipe(
        catchError(this.handleError)
    );
  }


  /**
   * @author Javier Castañeda
   * @purpose Servicio para obtener reporte de usuarios y sus días disponibles de vacaciones
   */
  getReportavailableDaysVacationsBy(document?: any, documentType?: any, campaign_id?: any, perPage: any = 5, page: any = 1) {
    const httpOptions = {
      params: new HttpParams()
        .set('document', document || '')
        .set('documentType', documentType || '')
        .set('campaign_id', campaign_id || ''),
        responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${ NOMINA_URL }vacations/reportStaffVacationsByAreaDirector`, httpOptions)
      .pipe(
        catchError(this.handleError)
    );
  }

  /**
   * @author Johan Soler
   * @purpose Servicio para obtener las novedades de vacaciones por filtro para la vista vacationsBoss pestaña vacations
   */
  getVacationsActive(filters: any, perPage: any = 5, page: any = 1) {
    const httpOptions = {
      params: new HttpParams()
        .set('state', filters.state)
        .set('document', filters.document || '')
        .set('documentType', filters.idTipDoc || '')
        .set('campaign_id', filters.campaign_id || '')
        .set('initial_holiday_date', filters.startDate || '')
        .set('end_holiday_date', filters.endDate || '')
        .set('perPage', perPage)
        .set('page', page || 1)
        .set('export', filters.exportData ? '1' : '0'),
      responseType: filters.exportData ? 'blob' as 'json' : 'json'
    };
    return this.http.get<any>(`${NOMINA_URL}vacation/getVacationApprovedFinalvacations`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
 * @author Johan Soler
 * 
 * @purpose Servicio para obtener las novedades de vacaciones extemporaneas por filtro
 */
  getVacationsExtemporal(filters: any, perPage: any = 5, page: any = 1) {
    const httpOptions = {
      params: new HttpParams()
        .set('state', filters.state)
        .set('document', filters.document || '')
        .set('documentType', filters.idTipDoc || '')
        .set('campaign_id', filters.campaign_id || '')
        .set('initial_holiday_date', filters.startDate || '')
        .set('end_holiday_date', filters.endDate || '')
        .set('perPage', perPage)
        .set('page', page || 1)
        .set('forBossView', filters.forBossView || 0)
        .set('export', filters.exportData ? '1' : '0'),
      responseType: filters.exportData ? 'blob' as 'json' : 'json'
    };
    return this.http.get<any>(`${NOMINA_URL}holidays/getExtemporaneousVacations`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @autor Johan Soler
   * @createDate 17-Jun-2022
   * Metodo para cancelar las vacaciones
   */
  cancelVacation(id: any, data: any) {
    return this.http.post<any>(`${NOMINA_URL}vacation/cancelVacations/${id}`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
 * @author Johan Soler
 * @purpose Servicio para aprobar o rechazar una novedad de vacaciones
 */
  changeStatusCancelVacation(id: any, body: any) {
    return this.http.post<any>(`${NOMINA_URL}vacation/aprovalCancelVacations/${id}`, body)
      .pipe(
        catchError(this.handleError)
      );
  }
  /**
 * @author Johan Soler
 * @purpose Servicio para aprobar o rechazar una novedad de vacaciones extemporaneas
 */
  changeExtemporalStatus(id: any, body: any) {
    return this.http.post<any>(`${NOMINA_URL}holidays/aprovalExtemporaneousVacations/${id}`, body)
      .pipe(
        catchError(this.handleError)
      );
  }
}
