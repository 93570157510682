import { Component, OnInit, Inject } from '@angular/core';
import { RequestsService } from '../../../services/requests.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-request-details-dialog',
  templateUrl: './request-details-dialog.component.html',
  styleUrls: ['./request-details-dialog.component.sass']
})
export class RequestDetailsDialogComponent implements OnInit {

  request;
  constructor( private _request:RequestsService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialogRef<RequestDetailsDialogComponent> ) { }

  ngOnInit(): void {
    this.getDetail();
  }

  getDetail(){
      this._request.getRequestDetail(this.data.requestId).subscribe( (resp) => {
        this.request = resp;
      } )
  }

}
