import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadersService {

  constructor(private http: HttpClient){}
  api_services = environment.BPMS_URL

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  lideres = [
    {
      id : 5,
      nombre: 'lidera',
      email: 'lidera@groupcos.com.co'
    },
    {
      id : 6,
      nombre: 'liderb',
      email: 'liderb@montechelo.com.co'
    },
    {
      id : 7,
      nombre: 'liderc',
      email: 'liderc@montechelo.com.co'
    }
  ];


  getLideres() {
    return this.http.get(`${this.api_services}users/lider`, this.httpOptions);
    //return this.lideres;
  }
}
