import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { ScheduleService } from 'src/app/core/services/rest/schedule.service';
import { RolesService } from 'src/app/modules/ciu/services/rest/roles.service';
import { sessionService } from 'src/app/modules/livechat/services/session/index.service';
import { storageService } from '../../../../services/storage/storage.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
  providers: [storageService]
})
export class HomeComponent implements OnInit {

  public user: any;
  public roles = [];
  public rol_livechat: string = '';
  public scheduleStatus: string = '';

  constructor(
    private _storageService: storageService,
    private _sessionService: sessionService,
    private _authService: AuthService,
    private _router: Router,
    private _scheduleService: ScheduleService
  ) {
    this.user = this._authService.getUser();
    // this.user = JSON.parse(this._storageService.getItem('user'));
    if (this.user) {
      this.roles = this.user.roles;
      this.getRole();
    }
  }

  ngOnInit(): void {
  }

  getRole() {
    console.log('INGRESO AQUI 1 ');
    if (this.roles) {
      this._scheduleService.getScheduleGuard().subscribe((data) => {
        this.scheduleStatus = data;
        let role;
        let roleCrm = [];
        let idx = null;
        this.roles.map(rol => {
          console.log('ROL ACTUAL', rol);
          switch (rol) {
            case 'livechat::admin':
              role = rol;
              break;
            case 'livechat::supervisor':
              role = rol;
              break;
            case 'livechat::asesor':
              role = rol;
              break;

            default:
              break;
          }
        })
        this.rol_livechat = role;
        this._sessionService.setItem('role', this.rol_livechat);
        if (this.rol_livechat === 'livechat::admin') {
          this._router.navigate(['/mios/livechat/omni/campaigns']);
        } else if (this.rol_livechat === 'livechat::supervisor' && this.scheduleStatus === 'started') {
          console.log('ENRUTO POR MI ROL ');
          this._router.navigate(['/mios/livechat/omni/dashboard2']);

        } else if (this.rol_livechat === 'livechat::supervisor' && this.scheduleStatus !== 'started') {
          this._router.navigate(['/mios/livechat/omni/await-page']);
        }else if (this.rol_livechat === 'livechat::asesor' && this.scheduleStatus === 'started') {
          this._router.navigate(['/mios/livechat/omni/conversations']);
        } else if (this.rol_livechat === 'livechat::asesor' && this.scheduleStatus !== 'started') {
          this._router.navigate(['/mios/livechat/omni/await-page']);
        }
      });
    }
  }
}
