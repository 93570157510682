<div>
  <h2>Cargar nueva versión</h2>
  <p>Recuerda que una vez cargada la plantilla esta quedará activa para generar contratos,
    en caso que quieras regresar a una versión anterior puedes usar el historial</p>
  <br>
  <form (ngSubmit)="onSubmit()" #uploadForm="ngForm" enctype="multipart/form-data">
    <div>
      <label for="fileInput" class="label-b">Seleccionar Archivo:</label>
      <input type="file" class="upload" id="fileInput" name="fileInput" (change)="onFileChange($event)" accept=".doc, .docx" required>
    </div>
    <br><br>
    <div style="text-align: right">
      <button type="submit" mat-flat-button color="primary" style="margin-right: 1em;" [disabled]="!uploadForm.valid">Guardar</button>
      <button type="button" mat-stroked-button (click)="onCancel()">Cancelar</button>
    </div>
  </form>
</div>
