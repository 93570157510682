<div class="dialog-email">
    <button style="float: right;" mat-icon-button mat-dialog-close>
        <i class="fi-rr-cross-small"></i>
    </button>
    
    <mat-dialog-content class="email-content">
    
        <h4>De: {{data.data.email.de}}</h4>
        <h4>Para: {{data.data.email.para}}</h4>
        <h4>Asunto: {{data.data.email.asunto}}</h4>
        <h4>Fecha: {{data.data.email.fechaHora}}</h4>
    
        <mat-divider></mat-divider>
        <br>
        <div style="font-size: large" class="emailPreview" innerHTML="{{data.data.email.cuerpo}}"></div>
        <br>
        <button mat-raised-button color="primary" style="font-size: large;" mat-button [matMenuTriggerFor]="menu">Adjuntos: {{data.data.email.cantidadAdjuntos}}</button>
        <mat-menu #menu="matMenu">
            <div *ngFor="let adjunto of email">
                <button mat-menu-item (click)="descargarArchivo(adjunto)"><span class="fi-sr-download"></span>&nbsp;&nbsp;{{adjunto.nombre}}</button>
            </div>
        </mat-menu>
        <br>
        <br>
        <mat-divider></mat-divider>
        <br>
        <br>
        <button mat-raised-button color="primary" (click)="gestionarBoton();">Gestionar</button>
        <br>
        <br>
        <div>
    
            <mat-card *ngIf="gestionar">
    
                <mat-card-content>
                    
                    <form class="row form" [formGroup]="mailForm" (ngSubmit)="resposeEmail(mailForm)">
    
                        <mat-form-field class="col-12" appearance="fill">
                            <mat-label>Para: </mat-label>
                            <input matInput type="email" formControlName="para" required />
                            <mat-error *ngIf="error.para.errors"><span *ngIf="error.para.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                            <mat-error *ngIf="error.para.errors"><span *ngIf="error.para.errors.email">Debe ingresar un correo valido</span></mat-error>
                        </mat-form-field>
    
                        <mat-form-field class="col-12" appearance="fill">
                            <mat-label>cc: </mat-label>
                            <input matInput type="email" formControlName="cc"/>
                            <mat-error *ngIf="error.cc.errors"><span *ngIf="error.cc.errors.email">Debe ingresar un correo valido</span></mat-error>
                        </mat-form-field>
    
                        <mat-form-field class="col-12" appearance="fill">
                            <mat-label>cco: </mat-label>
                            <input matInput type="email" formControlName="cco"/>
                            <mat-error *ngIf="error.cco.errors"><span *ngIf="error.cco.errors.email">Debe ingresar un correo valido</span></mat-error>
                        </mat-form-field>
    
                        <input type="file" appearance="fill" #archivo (change)="CargarArchivo($event)" />
                        <ul style="padding-left: 170px;">
                            <div *ngFor="let item of archivosCargados; let i = index">
                                <li *ngIf="i < (archivosCargados.length-1)">{{item.name}}</li>
                            </div>
                            
                        </ul>
    
                        <angular-editor style="padding-bottom: 20px;" class="col-12" formControlName="respuesta" [config]="editorConfig"></angular-editor>
    
                        <div class="col-12">
                            <button mat-raised-button type="submit" color="primary" disabled="{{mailForm.invalid}}">Enviar Correo</button>
                        </div>
                        
                    </form>
    
                </mat-card-content>
    
            </mat-card>
    
            </div>
    
    
    </mat-dialog-content>
    
</div>
