import { Component, OnInit, Inject  } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { saveAs as importedSaveAs } from "file-saver";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-files-view',
  templateUrl: './files-view.component.html',
  styleUrls: ['./files-view.component.sass']
})
export class FilesViewComponent implements OnInit {
  fileSrc:any
  viewer:boolean 
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,  private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    let typeFile = []
    typeFile= this.data.file.type.split('/');
    switch (typeFile[0]) {
      case 'application':
        this.fileSrc = this.data.url
        this.viewer = true
        
        break;
    case 'image':
      this.fileSrc = this.data.file;
      this.viewer = false
          break;
    }
  }

  download(){
    importedSaveAs(this.data.file, this.data.name);
  }

}


