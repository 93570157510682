import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Echo from 'laravel-echo';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { NotificationService } from 'src/app/core/services/rest/notification.service';
import { PusherService } from 'src/app/modules/crm/services/pusher.service';
import { MeetingsComponent } from '../meetings/meetings.component';
import { VideollamadaService } from '../videollamada/services/videollamada.service';
import { DataPusher } from './models/data-pusher.model';

@Component({
	selector: 'app-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.sass']
})
export class NotificationsComponent implements OnInit, OnDestroy {

	@Input() alert = true;
	@Input() historial = false;
	@Output() cerrarEmit = new EventEmitter();
	mensaje: string = '';
	ruta: string = '';
	notificaciones = [];
	dataResp: DataPusher;
	//Observable para los cambios de la tabla
	subscription: Subscription;
	user: any;
	constructor(
		private pusherService: PusherService,
		private authService: AuthService,
		private notificacionService: NotificationService,
		private router: Router,
		public dialog: MatDialog
	) { }

	ngOnInit(): void {
		this.user = this.authService.getUser();
		this.getNotifications();
		this.pusherService.getNotificationsGlobal().bind('notification', (data: DataPusher) => {
			this.dataResp = data;
			console.log(this.dataResp);
			
			this.setNotification(data);
			this.getNotifications();
		});
		this.subscription = this.notificacionService.refresh$.subscribe(() => {
			this.getNotifications();
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	closeAlert() {
		this.alert = false;
		this.cerrarEmit.emit(this.alert);
	}

	cerrar() {
		this.historial = !this.historial;
		this.alert = false;
		this.cerrarEmit.emit(this.historial);
	}


	/**
	* @author Cristian Gallo
	* @createdate 2021-11-24
	* Metodo que redirige a la  pagina una vez se click en el mensaje de nueva notificación.
	*/
	redirigir() {
		this.closeAlert();
		this.notificacionService.changeStatus(this.dataResp.idNotification).subscribe(() => {
			if (this.ruta != this.router.url) {
				let notify = this.notificaciones[0];
				if (notify.mensaje.includes("Se te ha realizado un nuevo acompañamiento con")) { 
					let segments = notify.aplicacion.split("/");
					this.notificacionService.createhistorial(segments[(segments.length - 1)]).subscribe(
						(resp) => {
							window.location.replace(this.ruta);
						}
					);
				}else if (notify.mensaje.includes("Videollamada")){
					this.openMeeting(notify);					
				}else{
					window.location.replace(this.ruta);
				}
				
			}
		})
	}

  /**
  * @author Cristian Gallo
  * @createdate 2021-11-24
  * Metodo que redirige a la  pagina una vez se click en alguna notificación de la lista.
  */
	irPagina(ruta, id, i) {
		this.notificacionService.changeStatus(id).subscribe(() => {
			if (ruta != this.router.url) {
				let notify = this.notificaciones[i];
				if (notify.mensaje.includes("Se te ha realizado un nuevo acompañamiento con")) { 
					let segments = notify.aplicacion.split("/");
					this.notificacionService.createhistorial(segments[(segments.length - 1)]).subscribe(
						(resp) => {
							window.location.replace(ruta);
						}
					);
				} else if (notify.mensaje.includes("Videollamada")){
					this.openMeeting(notify);					
				}else{
					window.location.replace(ruta);
				}
			}
		});
	}




	deleteNotification(id) {
		this.notificacionService.deleteNotificationById(id).subscribe(() => { });
	}

	/**
   * @author Cristian Gallo
   * @createdate 2021-11-23
   * Metodo que setea la nueva notificación
   */
	setNotification(data) {
		this.alert = true;
		this.mensaje = data.message;
		this.ruta = data.app;
	}

	/**
	* @author Cristian Gallo
	* @createdate 2021-11-23
	* Metodo que setea la nueva notificación y el nombre de la aplicación de donde proviene la notificación.
	*/
	getNotifications() {
		this.notificacionService.getNotifications(this.authService.getUser().rrhh_id).subscribe((resp: any) => {
			this.notificaciones = resp;
			this.notificaciones.forEach(notificacion => {
				if (notificacion.mensaje.includes("Videollamada")){
					console.log('videollamada');									
				} else {
					Object.assign(notificacion, {
						nombre: (notificacion.aplicacion as string).match(/[a-z]+/g)[1],
					})
				}
			});
		});
	}
	/**
	* @author Javier Castañeda
	* @createdate 2022-07-18
	* Metodo que setea la nueva notificación y el nombre de la aplicación de donde proviene la notificación.
	*/
	openMeeting(data?: any) {
		this.dialog.open(MeetingsComponent, { 
		  disableClose: true,
		  hasBackdrop: false,
		  data: {
			data: data,
			user: this.user.rrhh.name
		  } 
		});		
	  }

}
