import { createReducer, on } from "@ngrx/store";
import { HistorialState } from "../../core/states/historial.state";
import { HistorialByIdState } from "../../core/states/historialById.state";
import { loadedHistorial, loadedHistorialById } from "../actions/historial.actions";

export const initialState: HistorialState = { historial:null };
export const initialStateById: HistorialByIdState = { historial:null };

export const historialReducer = createReducer(
    initialState,
    on(loadedHistorial,( state, { historial } )=>{
        return {...state, historial}
    })
);

export const historialByIdReducer = createReducer(
    initialStateById,
    on(loadedHistorialById,( state, { historial } )=>{
        return {...state, historial}
    })
);