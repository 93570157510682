import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import {QuestionBase} from 'src/app/core/models/questions-base';
import { of } from 'rxjs';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class QuestionControlService {

  constructor() { }
  /**
   * @author Karol García
   * @createdate 2021-01-26
   * Por cada pregunta se crea un control del formulario y las validaciones necesarias
   * @param questions
   */
  toFormGroup(questions: QuestionBase<any>[] ) {
    const group: any = {};
    
    questions.forEach(question => {
      let validatorsBase = [Validators.maxLength(question.maxLength), Validators.minLength(question.minLength)]  
      if(question.type === 'date' && question.controlType === 'datepicker'){
        question.value = new Date(moment(question.value).format());
        const testDate = new Date(moment(question.value).format()).toString();
        if (testDate === 'Invalid Date') {
          question.value = null;
        }
      }
      
      if(question.required && question.required == true){
        validatorsBase.push(Validators.required);
        if (question.controlType == 'autocomplete' || question.controlType == 'autocomplete2' && question.type == 'options') {
          validatorsBase.push(RequireMatch)
        }
      }else{
        if (question.controlType == 'autocomplete' || question.controlType == 'autocomplete2' && question.type == 'options') {
          validatorsBase.push(RequireMatchEmptyValue)
        }
      }
                             
      if (question.type == 'email') {
        validatorsBase.push(Validators.email)
      }
      group[question.key] =  new FormControl({value: question.value || '', disabled: question.disabled}, validatorsBase)

    });
    return new FormGroup(group);
  }

  

  toFormControl(question) {

    let control: any;
    let validators = [Validators.maxLength(question.maxLength), Validators.minLength(question.minLength)]
    if(question.required){
      validators.push(Validators.required)
    }
    if(question.type == 'email'){
      validators.push(Validators.email);
    }
    control = new FormControl({value: question.value || '', disabled: question.disabled}, validators )
    return control;
  }

}

export function RequireMatch(control: AbstractControl) {
  if (typeof control.value === 'string') {
    return { invalidAutocompleteObject: { value: control.value } };
  }
  return null;
}

/**
 * Metodo que se encarga de valdiar coincidencias de autocomplete, permite no ingresar un valor
 * @param control 
 * @returns 
 */
export function RequireMatchEmptyValue(control: AbstractControl) {
  if (typeof control.value === 'string' && control.value.trim() !== "") {
    return { invalidAutocompleteObject: { value: control.value } };
  }
  return null;
}
