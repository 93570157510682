import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';

export class CustomValidators {
    /**
	 * Método para validar si el archivo es de tipo csv
	 * @author Juan Carlos Alonso
	 */
    static isCsv(control: AbstractControl): ValidationErrors| null {
        let file = control.value;
        if(control.value){
        if (file._files[0].type == "text/csv")
            return null;
        else
            return { is_csv: true }
        }
    }

}
