<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>
<div fxLayout="row" fxLayoutAlign="space-between end">
    <h2>Detalles solicitud</h2>
    <button mat-button *ngIf="canEdit" (click)="editRequest()">Editar solicitud &nbsp; <span class="fi-rr-edit"></span></button>
</div>
<mat-divider></mat-divider>

<mat-dialog-content>
    <div fxLayout="row" class="current_state">
        <strong>Estado actual &nbsp;</strong>
        <p *ngIf="request?.tracing.length == 0 && request?.request_state_id != 2 && !request?.hired_user_id">Pendiente por aprobar</p>
        <p *ngIf="request?.hired_user_id"> Solicitud pendiente por asignar a proceso de reclutamiento</p>
    </div>
    <hr>
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="20px grid" *ngIf="!editForm">
        <div fxFlex="33">
            <b>Consecutivo</b>
            <p>{{request?.code}}</p>
        </div>
        <div fxFlex="33">
            <b> Fecha de creación</b>
            <p>{{request?.created_at | date:'dd/MM/yyyy' }}</p>
        </div>
        <div fxFlex="33">
            <b>Cargo</b>
            <p>{{request?.request_data.cargo}}</p>
        </div>
        <div fxFlex="33">
            <b>Ciudad</b>
            <p>{{request?.request_data.cities.name}}</p>
        </div>
        <div *ngIf="request?.request_data.jornadaTrabajo" fxFlex="33">
            <b>Jornada de Trabajo</b>
            <p>{{request?.request_data.jornadaTrabajo.name}}</p>
        </div>
        <div *ngIf="request?.request_data.horarioTrabajoInicio" fxFlex="33">
            <b>Jornada de Trabajo Inicio</b>
            <p>{{request?.request_data.horarioTrabajoInicio}}</p>
        </div>
        <div *ngIf="request?.request_data.horarioTrabajoFin" fxFlex="33">
          <b>Jornada de Trabajo Fin</b>
          <p>{{request?.request_data.horarioTrabajoFin}}</p>
      </div>
        <div *ngIf="request?.request_data.disponibilidad" fxFlex="33">
            <b>Disponibilidad</b>
            <p>{{request?.request_data.disponibilidad.name}}</p>
        </div>
        <div fxFlex="33">
            <b>Campaña</b>
            <p>{{request?.request_data.campana.name}}</p>
        </div>
        <div fxFlex="33">
            <b>Empresa</b>
            <p>{{request?.request_data.companies.name}}</p>
        </div>
        <div fxFlex="33">
            <b>Estado solicitud</b>
            <p>{{request?.state}}</p>
        </div>
        <div fxFlex="33" *ngIf="request?.request_data.personalRequerido">
            <b>Personas Solicitadas</b>
            <p>{{request?.request_data.personalRequerido}}</p>
        </div>
        <div fxFlex="33" *ngIf="request?.request_data.personalSolicitado">
            <b>Personas Requeridas</b>
            <p>{{request?.request_data.personalSolicitado}}</p>
        </div>
        <div fxFlex="33" *ngIf="request?.request_data.porcentajeComision">
            <b>Porcentaje Comisión</b>
            <p>{{request?.request_data.porcentajeComision}} %</p>
        </div>
        <div fxFlex="33">
            <b>Salario</b>
            <p> {{request?.request_data.asignacionSalarial | currency:'COP'}}</p>
        </div>
        <div fxFlex="33" *ngIf="request?.request_data['motivo-solicitud0']">
            <b>Motivo</b >
          <p *ngIf="request?.request_data['motivo-solicitud0'] == 1">Incremento</p>
          <p *ngIf="request?.request_data['motivo-solicitud0'] == 2">Reemplezo  </p>
        </div>
      <div fxFlex="33" *ngIf="request?.request_data['motivo-solicitud0']">
           <b *ngIf="request?.request_data['motivo-solicitud0'] == 2">A quién reemplaza</b >
          <b *ngIf="request?.request_data['motivo-solicitud0'] == 1">Correo de aprobación</b >
           <p *ngIf="request?.request_data['motivo-solicitud0'] == 1">{{request?.request_data['correo-de-aprobacion2']}}</p>
          <p *ngIf="request?.request_data['motivo-solicitud0'] == 2">{{request?.request_data['a-quien-reemplaza1']}}</p>
      </div>
        <div fxFlex="33" *ngIf="request?.request_data.tipoContrato">
            <b>Tipo de contrato</b>
            <p> {{request?.request_data.tipoContrato.name}}</p>
        </div>
        <div fxFlex="33" *ngIf="request?.request_data.modalidadLabor">
            <b>Modalidad Labor</b>
            <p> {{request?.request_data.modalidadLabor.name}}</p>
        </div>
        <div fxFlex="33" *ngIf="request?.request_data.duracionCapacitacion">
            <b>Duración de la capacitación</b>
            <p> {{request?.request_data.duracionCapacitacion.name}} días</p>
        </div>
        <div fxFlex="33" *ngIf="request?.request_data.remuneracionCapacitacion">
            <b>Remuneracion Capacitación</b>
            <p> {{request?.request_data.remuneracionCapacitacion.name}}</p>
        </div>
        <div fxFlex="33" *ngIf="request?.request_data.sites">
            <b>Lugar de trabajo</b>
            <p> {{request?.request_data.sites.name}}</p>
        </div>
        <div fxFlex="33" *ngIf="request?.request_data.iniciooperacion">
            <b>Inicio de operación</b>
            <p> {{request?.request_data.iniciooperacion | date}}</p>
        </div>
        <div fxFlex="66" *ngIf="request?.request_data.formacionAcademica">
            <b>Formación académica</b >
            <p> {{request?.request_data.formacionAcademica}}</p>
        </div>
        <div fxFlex=66 *ngIf="request?.request_data.experienciaRequerida">
            <b>Experiencia Requerida</b >
            <p> {{request?.request_data.experienciaRequerida}}</p>
        </div>
       <div fxFlex="66" *ngIf="request?.request_data.funciones">
           <b>Funciones</b>
            <p> {{request?.request_data.funciones}}</p>
        </div>

        <div fxFlex="33" *ngIf="request?.porcentajeComision">
            <b>Porcentaje Comision</b>
            <p> {{request?.request_data.porcentajeComision}}</p>
        </div>
        <div fxFlex="33">
            <b>Solicitante</b>
            <p> {{request?.applicant}}</p>
        </div>
        <div fxFlex="33" *ngIf="request?.request_data.cargoSolicitante">
            <b>Área solicitante</b>
            <p> {{request?.request_data.cargoSolicitante.name}}</p>
        </div>
        <div fxFlex="33" *ngIf="request?.request_data.areaSolicitante">
            <b>Área solicitante</b>
            <p> {{request?.request_data.areaSolicitante}}</p>
        </div>
        <div fxFlex="33" *ngIf="request?.userResponsable">
            <b>Responsable actual</b>
            <p>{{request?.userResponsable}}</p>
        </div>
        <div fxFlex="66" *ngIf="request?.request_data.observaciones">
            <b>Observaciones</b>
            <p>{{request?.request_data.observaciones}}</p>
        </div>
    </div>
    <div class="table" *ngIf="!editForm">
        <p><b>Candatos Asociados</b></p>
        <table mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef style="align-items: center;"> Nombre </th>
                <td mat-cell *matCellDef="let row"> {{row.name}} </td>
            </ng-container>

            <ng-container matColumnDef="id_number">
                <th mat-header-cell *matHeaderCellDef># Documento </th>
                <td mat-cell *matCellDef="let row"> {{row.documentId}} </td>
            </ng-container>


            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> Email </th>
                <td mat-cell *matCellDef="let row"> {{row.email}} </td>
            </ng-container>



            <ng-container matColumnDef="actions">
                <th class="end" mat-header-cell *matHeaderCellDef> Acctiones </th>
                <td mat-cell *matCellDef="let row">
                    <button *ngIf="!row.comentaryState" mat-stroked-button color="primary" (click)="setCommentary( 'aprobado_jefe_inmediato',row.id,'Aprobado por jefe Inmediato'  )">Aprobar</button>
                    <button *ngIf="!row.comentaryState" mat-stroked-button color="warn" (click)="setCommentary( 'rechazado_jefe_inmediato',row.id,'Rechazado por jefe Inmediato'  )">Rechazar</button>
                    <span *ngIf="row.comentaryState">{{row.comentaryState}}</span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8">No hay candidatos disponibles.</td>
            </tr>
        </table>


    </div>
    <div *ngIf="editForm">
        <div *ngIf="formSection[0].fields != null">
            <dynamic-form-2 [sections]="{sections:formSection, app:'bpms'}" (denyForm)="closeEdit($event)" (submitForm)="editarSolicitud($event)"></dynamic-form-2>
        </div>

    </div>

</mat-dialog-content>


<mat-dialog-actions align="center" *ngIf="request && request.request_state_id != 2 && !request.hired_user_id &&
                                            request.type_hired_user_id == user.rrhh_id && !editForm">
    <button class="alert" mat-stroked-button color="warn" (click)="deleteRequest()">Rechazar</button>
    <button class="primary" mat-raised-button color="primary" (click)="approveRequest()">Aprobar</button>
</mat-dialog-actions>
