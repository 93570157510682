import { NgModule } from "@angular/core";
import {  MqttModule } from "ngx-mqtt";
import { MQTT_SERVICE_OPTIONS } from "./config/mqtt.config";




@NgModule({
    imports:[
        MqttModule.forRoot(MQTT_SERVICE_OPTIONS)
    ]
})
export class MqttSoulModule { }
