import { Component, OnInit, Inject } from '@angular/core';
import { SolicitudesService } from '../../../../services/rest/solicitudes.service';
import { DestroyComponentService } from '../../../../../../core/services/utils/destroy-component.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import { AuthService } from '../../../../../../core/services/rest/auth.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ProveedoresService } from '../../../../../portal/services/proveedores.service';
import { OrdenCompraComponent } from '../orden-compra/orden-compra.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-consultar-proveedor',
  templateUrl: './consultar-proveedor.component.html',
  styleUrls: ['./consultar-proveedor.component.sass']
})
export class ConsultarProveedorComponent implements OnInit {
  proveedor ="";
  prov:any;
  generarOrden =false;
  formProv: FormGroup;
  textProveedor: FormControl = new FormControl('');
  listProveedores: any[] = [];
  filterListProveedores: Observable<any[]>;
  filterProveedor: any = null;

  constructor(private solicitudService: SolicitudesService,
    private proveedorService    : ProveedoresService,
    private destroyService      : DestroyComponentService,
    public dialog               : MatDialog,
    private fb                  : FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.formProv = this.fb.group({
      nitProveedor : ['', Validators.required ]
    });
    this.filterListProveedores = this.textProveedor.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
    this.getProveedores();
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.listProveedores.filter(option => option.razon_social.toLowerCase().includes(filterValue));
  }

  getProveedores(): void {
    const params = { numeroRegistros: null, razon_social: null, nit: null, all_provedores: true };
    this.proveedorService.getProveedores(null, params).subscribe(res => {
      this.listProveedores = res;
    });
  }

  filterProveedores($event: any) {
    const nameProveedor = $event.option.value;
    const proveedor = this.listProveedores.find(item => item.razon_social === nameProveedor);
    this.formProv.get('nitProveedor').setValue(proveedor.nit);
    this.formProv.get('nitProveedor').disable();
    this.prov = proveedor;
    this.generarOrden = true;
  }

  displayFn(module: any): string {
    return module && module ? module : '';
  }

  deleteProveedor($event: any) {
    const nameProveedor = $event.target.value;
    if (nameProveedor === '') {
      this.formProv.get('nitProveedor').setValue('');
      this.formProv.get('nitProveedor').enable();
      this.prov = null;
      this.generarOrden = false;
    }
  }

  consultaProveedor(event){
    const nit = (event.target as HTMLInputElement).value;
    const proveedor = { nit:nit };
    if (nit == "") {
      this.textProveedor.setValue('');
      this.textProveedor.enable();
      this.prov = null;
      this.generarOrden = false;
    }
    else {
      this.proveedorService.getProveedores(1, proveedor).subscribe((prov)=>{
        if (prov.data.length > 0) {
          this.proveedor       = prov.data[0].razon_social;
          this.prov            = prov.data[0];
          this.generarOrden    = true;
          this.textProveedor.setValue(prov.data[0].razon_social);
          this.textProveedor.disable();
        } else {
          this.proveedor       = "No se ha encontrado proveedor.";
          this.generarOrden    = false;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

  crearOrdenCompra(){
    const dialogNewRol = this.dialog.open(OrdenCompraComponent, {
      width: '90%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Editar Solicitudes',
        solicitud:this.data.solicitud,
        proveedor: this.prov
      },
      panelClass: 'custom-dialog-container',
    });
    dialogNewRol.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }
}