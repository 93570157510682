<div class="avatar row  admin-dialog">
  <div>
    <button style="float: right;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
  </div>
  <form class="row form " [formGroup]="solicitudesForm" (ngSubmit)="saveSolicitud(solicitudesForm)">
    <mat-dialog-content style="padding: 3%;" class="mat-typography">
      <div class="row">
        <div class="col-8 d-flex align-items-center">
          <button mat-icon-button type="button" class="badge shadowed" [style.backgroundColor]="data.solicitud.request_state.color">
            <i [class]=" data.solicitud.request_state.icon" > </i>
          </button>
          <div class="ms-3">
             <h3 mat-dialog-title style="padding:0 !important">
              {{ data.title }}
            </h3>
            <mat-label class="state"><strong>Estado actual : </strong> {{data.solicitud.request_state.name}}</mat-label>
          </div>
        </div>
        <div *ngIf="viewFile" class="col-4 d-flex align-items-center">
          <span class="me-1" style="text-decoration-line: underline;font-weight: 500;">Descarga orden de compra</span><button class=" action" (click)="downloadOrder(ordenCompra)" type="button"  mat-icon-button>
            <i class="fi-rr-download"></i>
          </button>
        </div>
      </div>
      <button
      type    = "button"
      style   = "float: right;"
      (click) = "editSolicitud(true)"
      *ngIf   = "(data.solicitud.request_state.id == 2  && !applicant) && (coordinador && view )"
      mat-icon-button >
      <i class="fi-rr-edit"></i>
    </button>

    <hr>

    <div class="row">
      <div class="col-4">

        <mat-label style="font-weight: bold;">Fecha de creación</mat-label>
        <p>{{data.solicitud.created_at|date:'dd-MM-yyyy': 'UTC'}}</p>
      </div>
      <div class="col-4">

        <mat-label style="font-weight: bold;">Nombre del solicitante</mat-label>
        <p>{{data.solicitud.created_by}}</p>
      </div>
      <div class="col-4">

        <mat-label style="font-weight: bold;">Tipo de adquisición</mat-label>
        <p>{{data.solicitud.product_type.name}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-4">

        <mat-label style="font-weight: bold;">Categoría</mat-label>
        <p>{{data.solicitud.category.name}}</p>
      </div>
      <div class="col-4">

        <mat-label style="font-weight: bold;">Empresa</mat-label>
        <p>{{data.solicitud.company.name}}</p>
      </div>
      <div class="col-4">

        <mat-label style="font-weight: bold;">Campaña</mat-label>
        <p>{{data.solicitud.project}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-4">

        <mat-label style="font-weight: bold;">Título solicitud</mat-label>
        <p>{{data.solicitud.title}}</p>
      </div>
      <div class="col-8">

        <mat-label style="font-weight: bold;">Descripción solicitud</mat-label>
        <p>{{data.solicitud.description}}</p>
      </div>

    </div>

    <div class="row" *ngIf="data.solicitud.date_ejecucion || data.solicitud.observaciones_ejecuccion">
      <div class="col-4">
        <mat-label style="font-weight: bold;">Fecha de ejecución</mat-label>
        <p>{{data.solicitud.date_ejecucion | date:'dd-MM-yyyy': 'UTC'}}</p>
      </div>
      <div class="col-8">
        <mat-label style="font-weight: bold;">Observación de ejecución</mat-label>
        <p>{{data.solicitud.observaciones_ejecuccion}}</p>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-4">
        <mat-label style="font-weight: bold;">Ítem de compra</mat-label>
      </div>
      <div class="col-2">
        <mat-label style="font-weight: bold;">Cantidad</mat-label>
      </div>
      <div [ngClass]="!coordinador ? 'col-4' : 'col-6'" *ngIf="!coordinador">
        <mat-label style="font-weight: bold;">
          Referencia
        </mat-label>
      </div>
      <div *ngIf="data.solicitud.request_state.id == 3 || data.solicitud.request_state.id == 4" [ngClass]="!coordinador ? 'col-2' : 'col-6'">
        <mat-label style="font-weight: bold;">
          Observación
        </mat-label>
      </div>
    </div>
    <div [ngClass]="coordinador ? 'row mb-3' : 'row  align-items-center'" *ngFor="let item of itemsSolciitud">
      <div class="col-4">
        <p>{{item.detail}}</p>
      </div>
      <div class="col-2">
        <p>{{item.quantity}}</p>
      </div>
      <div *ngIf="coordinador && item.observations else divPathReferencia" class="col-6">
        {{item.observations.observations}}
      </div>
      <ng-template #divPathReferencia>
        <div *ngIf="!coordinador" class="col-4">
          <div *ngIf="item.name && item.path">
            <span class="truncate" style="text-decoration-line: underline;font-weight: 500;">{{item.name}}</span><button class=" action" type="button" (click)="downloadFile(item)" mat-icon-button>
              <i class="fi-rr-download"></i>
            </button>
          </div>
        </div>
        <div *ngIf="!coordinador && (data.solicitud.request_state.id == 3 || data.solicitud.request_state.id == 4)" class="col-2">
          <button
            *ngIf="item.observations"
            type="button"
            mat-icon-button
            (click)="viewObservation(item.observations, item.id)">
              <i class="fi-rr-eye"></i>
          </button>
        </div>
      </ng-template>
    </div>

    <hr style="max-width: 100%;">

    <div *ngIf="data.solicitud.request_state.id == 6 || data.solicitud.request_state.id == 11">
      <mat-divider></mat-divider>

      <div class="row my-3">
        <div class="col-12" >
          <!-- <div *ngIf="data.solicitud.request_state.id != 6">
            <mat-label style="font-weight: bold;" *ngIf="data.solicitud.request_state.id == 8">Respuesta a la solicitud - </mat-label>
            <mat-label style="font-weight: bold;" *ngIf="data.solicitud.request_state.id == 3">Fecha de envio - </mat-label>
            <mat-label style="font-weight: bold;" *ngIf="data.solicitud.request_state.id == 5">Fecha de rechazo - </mat-label>
            <mat-label style="font-weight: bold;" *ngIf="data.solicitud.request_state.id == 4">Fecha de aprobacion - </mat-label>
            <mat-label style="font-weight: bold; color: #717171;" >{{data.solicitud.updated_at|date:'dd/MM/yyyy hh:mm:ss': 'UTC'}} | </mat-label>
            <mat-label style="font-weight: bold; color: #717171;" >{{validate_latesObservation(data.solicitud.latest_observations)}}</mat-label>
          </div> -->
          <div>
            <mat-label style="font-weight: bold;" >Fecha de creación de la orden de compra - </mat-label>
            <mat-label style="font-weight: bold; color: #717171;">{{data.solicitud.updated_at|date:'dd/MM/yyyy HH:mm:ss'}} | </mat-label>
            <mat-label style="font-weight: bold; color: #717171;" >{{validate_latesObservation(data.solicitud.created_purchase)}}</mat-label>
          </div>
        </div>
      </div>

      <mat-divider></mat-divider>
    </div>

    <div *ngIf="this.data.solicitud.is_associated == '1'">
      <div class="row">
        <div class="col-12" >
          <strong>Ítems de compra solicitud asociada</strong>
          <br><br>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-4">
          <mat-label style="font-weight: bold;">Ítem de compra</mat-label>
        </div>
        <div class="col-2">
          <mat-label style="font-weight: bold;">Cantidad</mat-label>
        </div>
        <div class="col-6">
          <mat-label style="font-weight: bold;">Observación</mat-label>
        </div>
      </div>
      <div class="row" *ngFor="let item of itemsAsociada">
        <div class="col-4">
          <p>{{item.detail}}</p>
        </div>
        <div class="col-2">
          <p>{{item.quantity}}</p>
        </div>
        <div *ngIf="item.observations" class="col-6">
          <p>{{item.observations.observations}}</p>
        </div>
      </div>

      <div *ngIf="this.data.solicitud.hasOwnProperty('observaciones_associated') && this.data.solicitud.observaciones_associated !== null">
        <div class="row">
          <div class="col-12">
            <strong>Observaciones de la solicitud asociada</strong>
            <br><br>
          </div>
        </div>

        <div>
          <p>{{this.data.solicitud.observaciones_associated}}</p>
        </div>
      </div>
    </div>
    <br><br>

    <h4 *ngIf=" (archivosPorSolicitud && archivosPorSolicitud.length > 0) && ((view && solicitudesForm.value.estado != 2) || !view ) && rol != 'solicitante'">
      <strong>Cotizaciones</strong>
      <br>
      <span style="font-size: 16px;">A continuación, se presentan las cotizaciones previamente cargadas por el solicitante.</span>
    </h4>


    <div class="row">
      <div class="col-12" *ngIf="((view && solicitudesForm.value.estado != 2) || !view ) && rol != 'solicitante'">
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group row cotizations-radio" formControlName="cotizacion" [disabled]="this.view || solicitudesForm.value.estado == 5">
          <div class="col-4 d-inline-block" *ngFor="let archivo of archivosPorSolicitud; let i = index">
            <mat-radio-button  [checked]="archivo.state" [value]="archivo.id">
              <span class="normal-text">Cotización {{i + 1}} </span>
            </mat-radio-button>
            <br/>
            <span class="cotization-name">
              {{ ( archivo.name.length > 15 ) ? archivo.name.slice(0,12)+"..." : archivo.name }}
            </span>
            &nbsp;
            <button class=" action" type="button" (click)="downloadFile(archivo)" mat-icon-button>
              <i class="fi-rr-eye"></i>
            </button>
            <br/>
            <span class="value-label">
              Valor:
            </span>
            &nbsp;
            <span *ngIf="archivo.value != null">
              <!-- ${{archivo.value | number}} -->
              {{formatNumber(archivo.value)}}
            </span>
            <span *ngIf="archivo.value == null">
              N/A
            </span>
          </div>
        </mat-radio-group>
      </div>
    </div>
    <br>
    <br>
    <div class="row" *ngIf="getObservacion('analista').length > 0" >
      <div class="col-12" *ngIf="data.solicitud.request_observation!==undefined">

        <mat-label style="font-weight: bold;">Observación analista - </mat-label>
        <mat-label style="font-weight: bold; color: #717171;">{{ getfechaCreated('analista') |date:'dd/MM/yyyy HH:mm:ss'}} | </mat-label>
        <mat-label style="font-weight: bold; color: #717171;">{{ getNombreObservacion('analista') }} </mat-label>
        <p>{{ getObservacion('analista') }}</p>
      </div>

    </div>

    <div class="row mt-3" *ngIf="getObservacion('coordinador').length > 0">
      <div class="col-12" *ngIf="data.solicitud.request_observation!==undefined">

        <mat-label
          style="font-weight: bold;"
          >Observación aprobador - </mat-label>
          <mat-label style="font-weight: bold; color: #717171;">{{ getfechaCreated('coordinador') |date:'dd/MM/yyyy HH:mm:ss'}} | </mat-label>
        <mat-label style="font-weight: bold; color: #717171;">{{ getNombreObservacion('coordinador') }}</mat-label>
        <p>{{ getObservacion('coordinador') }}</p>
      </div>

    </div>

    <div class="row mt-3" *ngIf="getObservacion('control').length > 0">
      <div class="col-12" *ngIf="data.solicitud.request_observation!==undefined">

        <mat-label
          style="font-weight: bold;"
          >Observación control - </mat-label>
          <mat-label style="font-weight: bold; color: #717171;">{{ getfechaCreated('control') |date:'dd/MM/yyyy HH:mm:ss'}} | </mat-label>
        <mat-label style="font-weight: bold; color: #717171;">{{ getNombreObservacion('control') }}</mat-label>
        <p>{{ getObservacion('control') }}</p>
      </div>

    </div>

    <div class="row mt-3" *ngIf="getObservacion('anulacion').length > 0">
      <div class="col-12" *ngIf="data.solicitud.request_observation!==undefined">

        <mat-label
          style="font-weight: bold;"
          >Fecha de anulacion - </mat-label>
          <mat-label style="font-weight: bold; color: #717171;">{{ getfechaCreated('anulacion') |date:'dd/MM/yyyy HH:mm:ss'}} | </mat-label>
        <mat-label style="font-weight: bold; color: #717171;">{{ getNombreObservacion('anulacion') }}</mat-label>
        <p>{{ getObservacion('anulacion') }}</p>
      </div>

    </div>

    <div class="row mt-3" *ngIf="data.solicitud.observation_logs.length > 0">
      <div class="col-12" *ngIf="data.solicitud.observation_logs!==undefined">
        <mat-label
          style="font-weight: bold;"
          >Observación cancelacion - </mat-label>
          <mat-label style="font-weight: bold; color: #717171;">{{ data.solicitud.observation_logs[0].created_at |date:'dd/MM/yyyy HH:mm:ss'}} | </mat-label>
        <mat-label style="font-weight: bold; color: #717171;">{{ data.solicitud.observation_logs[0].create_rrhh_id }}</mat-label>
        <p>{{ data.solicitud.observation_logs[0].observation }}</p>
      </div>

    </div>

    <mat-form-field class="col-3" appearance="fill" *ngIf="!view">
      <mat-label>Estado</mat-label>
      <mat-select formControlName="estado" #estado (selectionChange)="validateCotizatioControlRequired($event.value)"   >
        <mat-option [value]='4'>Aprobada</mat-option>
        <mat-option [value]='5'>Rechazada</mat-option>
      </mat-select>
      <mat-error *ngIf="error.estado.errors"><span *ngIf="error.estado.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
    </mat-form-field>


    <mat-form-field class="col-12" appearance="fill" *ngIf="!view && [4,8,5].includes(solicitudesForm.get('estado').value)">
      <mat-label> {{ [5,8].includes(solicitudesForm.get('estado').value) ? "Motivo de rechazo" : "Observación" }} </mat-label>
      <input
        style             = "text-transform: none;"
        type              = "text"
        placeholder       = "Escribe la Observación"
        formControlName   = "observacion"
        matInput
        required>
      <mat-error *ngIf="error.observacion.errors"><span *ngIf="error.observacion.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
      <mat-error *ngIf="error.observacion.errors"><span *ngIf="error.observacion.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
      <mat-error *ngIf="error.observacion.errors"><span *ngIf="error.observacion.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 255 caracteres</span></mat-error>
    </mat-form-field>

    <div class="col-12">
      <div style="float: right" *ngIf="!view">
        <button class="bold compras-modal-button" mat-stroked-button mat-dialog-close>Cancelar</button>
        <button mat-raised-button
          type                  = "submit"
          color                 = "primary"
          disabled              = '{{solicitudesForm.invalid}}'
          [mat-dialog-close]    = "true"
          class                 = "bold compras-modal-button ms-2"
          >Guardar</button>
      </div>
    </div>

      </mat-dialog-content>






</form>
</div>
