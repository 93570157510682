import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const CIU_URL = environment.CIU_URL;

@Injectable({
  providedIn: 'root'
})
export class DobbleAuthService {

  constructor(private http: HttpClient) { }

  validateToken(params) {
    return this.http.post(`${CIU_URL}dobbleAuthTokens/validateToken`, params);
  }
}
