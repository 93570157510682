import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalidadComponent } from './calidad.component';
import { AuditoriasComponent } from './modules/auditorias/auditorias.component';
import { DetalleAuditoriaComponent } from './modules/auditorias/components/detalle-auditoria/detalle-auditoria.component';
import { DetalleControlComponent } from './modules/control/components/detalle-control/detalle-control.component';
import { DetalleGrupoComponent } from './modules/control/components/detalle-grupo/detalle-grupo.component';
import { ControlComponent } from './modules/control/control.component';
import { EditarFormularioComponent } from './modules/inteligencia-negocios/components/editar-formulario/editar-formulario.component';
import { InteligenciaNegociosComponent } from './modules/inteligencia-negocios/inteligencia-negocios.component';
import { DetalleMatrizComponent } from './modules/matrices/components/detalle-matriz/detalle-matriz.component';
import { MatricesComponent } from './modules/matrices/matrices.component';
import { MisAuditoriasComponent } from './modules/mis-auditorias/mis-auditorias.component';
import { PowerbiComponent } from './modules/powerbi/powerbi.component';
import { ReporteriaComponent } from './modules/reporteria/reporteria.component';
import { EliminarAuditoriaComponent } from './modules/eliminar-auditoria/eliminar-auditoria.component';

const routes: Routes = [
  {path:'', component:CalidadComponent},
  {path:'inteligencia-negocios/:tab', component:InteligenciaNegociosComponent},
  {path:'matrices', component:MatricesComponent},
  {path:'auditorias', component:AuditoriasComponent},
  {path:'formulario/:type/:id', component:EditarFormularioComponent},
  {path:'matriz/detail/:id', component:DetalleMatrizComponent},
  {path:'auditoria/detail/:tab/:id', component:DetalleAuditoriaComponent},
  {path:'control', component:ControlComponent},
  {path:'control/detail/:id', component:DetalleControlComponent},
  {path:'grupo/:id', component:DetalleGrupoComponent},
  {path:'mis-auditorias', component:MisAuditoriasComponent},
  {path:'reporteria', component:ReporteriaComponent},
  {path:'dashboard-powerbi', component:PowerbiComponent},
  {path:'eliminacion', component:EliminarAuditoriaComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CalidadRoutingModule { }
