<div class="admin-horarios">

  <div class="header">
    <h1>Administración de Horarios</h1>
    <p>Bienvenido al módulo administración de Horarios, recuerda que puedes<br>filtrar por<strong> cualquier criterio
        que encuentres en la tabla.</strong></p>
  </div>

  <mat-tab-group>
    <mat-tab label="Horarios">
      <div class="row col-12 filter" style="padding-top: 2rem">
        <div class="col-12">
          <div class="row">
            <div class="col-8">

              <form class="form" [formGroup]="filterForm">
<!-- 
                <mat-form-field class="col-3" appearance="fill">
                  <mat-label>Campaña:</mat-label>
                  <input type="text" matInput
                         formControlName="campaigns" [matAutocomplete]="campaignList" required>
                  <mat-autocomplete #campaignList="matAutocomplete" [displayWith]='displayFnCampaigns.bind(this)'
                                    (optionSelected)="consultarGrupos($event)" required>
                    <mat-option *ngFor="let campaign of filteredOptionsCampaigns | async" [value]="campaign.id">
                      {{campaign.name}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="error.campaigns.errors"><span *ngIf="error.campaigns.errors.required">Campo es
                  requerido</span></mat-error>
                  <mat-error *ngIf="error.campaigns.errors">Opción no válida</mat-error>
                </mat-form-field> -->

                <mat-form-field class="col-3" style="padding: 1%;" appearance="fill">
                  <mat-label>Grupo:</mat-label>
                  <input type="text" matInput
                         formControlName="groups" [matAutocomplete]="groupsList" required>
                  <mat-autocomplete #groupsList="matAutocomplete" [displayWith]='displayFnGroups.bind(this)' required>
                    <mat-option *ngFor="let group of filteredOptionsGroups | async" [value]="group.id">
                      {{group.name}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="error.groups.errors"><span *ngIf="error.groups.errors.required">Campo es
                  requerido</span></mat-error>
                  <mat-error *ngIf="error.groups.errors">Opción no válida</mat-error>
                </mat-form-field>

                <mat-form-field class="col-3" style="padding: 1%;" appearance="fill">
                  <mat-label>Semana:</mat-label>
                  <input type="text"
                         [readonly]="!filters.groupId"
                         [matAutocompleteDisabled]="!filters.groupId"
                         matInput formControlName="rangeDate" [matAutocomplete]="rangeList" required #inputAutoComplete>
                  <mat-autocomplete #rangeList="matAutocomplete" [displayWith]='displayFnRanges.bind(this)'
                                    (optionSelected)="onChange($event)" required>
                    <mat-option *ngFor="let range of filteredOptionsRanges | async" [value]="range.id" style="font-size: 12px!important; text-transform: uppercase"
                                matTooltip="{{ range.name }}"
                                matTooltipPosition="right">
                      {{ range.name }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="error.rangeDate.errors"><span *ngIf="error.rangeDate.errors.required">Campo es
                  requerido</span></mat-error>
                  <mat-error *ngIf="error.rangeDate.errors">Opción no válida</mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" class="col-3 filter" *ngIf="show == true">
                  <mat-label>Documento</mat-label>
                  <input matInput [readonly]="!filters.startWeek" type="text" #input autocomplete="off" (keydown.enter)="onBlurMethod($event.target.value)">
                  <mat-select name="ampm" class="ampm" matSuffix  [formControl]="typeDocument" style="width: 60px; margin-left: 5px;">
                    <mat-option *ngFor="let item of documents" [value]="item?.id">{{item.key}}</mat-option>
                  </mat-select>
                </mat-form-field>


              </form>
            </div>
            <div class="col-4" *ngIf="show == false">
              <button mat-raised-button color="primary" class="myButton" (click)="newConfigSchedule()" *ngIf="rol != 'supervisor' && !isRolPayroll">Cargar Nueva
                Configuración
              </button>
              <button mat-raised-button color="ligth" class="myButton2" (click)="Hide()">Activar filtros avanzados</button>
            </div>


          </div>
        </div>
        <div class="col-12">
          <button mat-raised-button color="primary" class="myButton" (click)="newConfigSchedule()" *ngIf="show == true && !isRolPayroll">
            Cargar Nueva Configuración
          </button>
          <button mat-raised-button color="ligth" class="myButton2" (click)="Hide()" *ngIf="show == true">Desactivar filtros
            avanzados
          </button>
          <button mat-raised-button color="ligth" class="myButton3" (click)="viewEditSchedule()"
                  *ngIf="selection.selected.length > 1 && editAvailable && !isRolPayroll">Modificar turnos
          </button>
        </div>

        <div class="col-12">
          <div style="display: flex; float: left; margin-bottom: 20px;">
            <mat-card
              class="card-filter"
              (click)="selectCardFilter(1)"
              [ngStyle]="{'border': filters.stateUserSchedule === 1 ? '#2CABBC 2px solid' : 'none'}"
              style="color: #2CABBC;"><strong>Personas activas: {{Actives}}</strong></mat-card>
            <mat-card
              class="card-filter"
              (click)="selectCardFilter(2)"
              [ngStyle]="{'border': filters.stateUserSchedule === 2 ? 'red 2px solid' : 'none'}"
              style="color: red; margin-left: 5px;"><strong>Personal sin horarios: {{Inactives}}</strong></mat-card>
          </div>
          <br>
          <br>
        </div>

        <div class="table-container">
          <div class="mat-elevation-z8">
            <table class="col-12 schedule-table" mat-table [dataSource]="dataSource" matSort>


              <ng-container matColumnDef="check">
                <th class="start" mat-header-cell *matHeaderCellDef>
                  <mat-checkbox *ngIf="editAvailable && rol != 'supervisor' && !isRolPayroll" (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()" *ngIf="editAvailable && rol != 'supervisor' && !isRolPayroll"
                                (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                  </mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef [hidden]="rol == 'supervisor' || isRolPayroll"> Acciones</th>
                <td mat-cell *matCellDef="let row"  [hidden]="rol == 'supervisor' || isRolPayroll">
                  <button mat-icon-button *ngIf="editAvailable" (click)="viewEditSchedule(row)">
                    <i class="fi-rr-edit"></i>
                  </button>
                  <button mat-icon-button (click)="deleteSchedule(row)">
                    <i class="fi-rr-trash"></i>
                  </button>
                  <button mat-icon-button (click)="scheduleReset(row)">
                    <i class="fi-rr-clock"></i>
                </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre</th>
                <td mat-cell *matCellDef="let row"> {{row.name}}</td>
              </ng-container>

              <ng-container matColumnDef="document">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento</th>
                <td mat-cell *matCellDef="let row"> {{row.id_number}} </td>
              </ng-container>

              <ng-container matColumnDef="monday">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Lunes {{week?.monday |date:'dd'}}</th>
                <td mat-cell *matCellDef="let row">
                  <p> {{row.monday.jornada}} <span [matTooltipClass]="'my-tooltip'" matTooltip="{{row.monday?.turno}}"
                                                   class="fi-rr-clock"></span></p>
                </td>
              </ng-container>

              <ng-container matColumnDef="tuesday">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Martes {{week?.tuesday |date:'dd'}} </th>
                <td mat-cell *matCellDef="let row">
                  <p> {{row.tuesday?.jornada}} <span [matTooltipClass]="'my-tooltip'" matTooltip="{{row.tuesday?.turno}}"
                                                     class="fi-rr-clock"></span></p>
                </td>
              </ng-container>

              <ng-container matColumnDef="wednesday">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Miércoles {{week?.wednesday |date:'dd'}} </th>
                <td mat-cell *matCellDef="let row">
                  <p> {{row.wednesday?.jornada}} <span [matTooltipClass]="'my-tooltip'"
                                                       matTooltip="{{row.wednesday?.turno}}" class="fi-rr-clock"></span></p>
                </td>
              </ng-container>

              <ng-container matColumnDef="thursday">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Jueves {{week?.thursday |date:'dd'}} </th>
                <td mat-cell *matCellDef="let row">
                  <p> {{row.thursday?.jornada}} <span [matTooltipClass]="'my-tooltip'" matTooltip="{{row.thursday?.turno}}"
                                                      class="fi-rr-clock"></span></p>
                </td>
              </ng-container>

              <ng-container matColumnDef="friday">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Viernes {{week?.friday |date:'dd'}} </th>
                <td mat-cell *matCellDef="let row">
                  <p> {{row.friday?.jornada}} <span [matTooltipClass]="'my-tooltip'" matTooltip="{{row.friday?.turno}}"
                                                    class="fi-rr-clock"></span> </p>
                </td>
              </ng-container>

              <ng-container matColumnDef="saturday">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Sábado {{week?.saturday |date:'dd'}} </th>
                <td mat-cell *matCellDef="let row">
                  <p> {{row.saturday?.jornada}} <span [matTooltipClass]="'my-tooltip'" matTooltip="{{row.saturday?.turno}}"
                                                      class="fi-rr-clock"></span></p>
                </td>
              </ng-container>

              <ng-container matColumnDef="sunday">
                <th class="end" mat-header-cell *matHeaderCellDef mat-sort-header> Domingo {{week?.sunday |date:'dd'}} </th>
                <td mat-cell *matCellDef="let row">
                  <p> {{row.sunday?.jornada}} <span [matTooltipClass]="'my-tooltip'" matTooltip="{{row.sunday?.turno}}"
                                                    class="fi-rr-clock"></span></p>
                </td>
              </ng-container>
              <!-- (click)="viewJustification(element)" -->

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


            </table>

            <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)"
                           [pageSizeOptions]="pageSizeOptions">
            </mat-paginator>

          </div>
        </div>

        <div class="row">
          <div class="col-8"></div>
          <div class="col-4">
            <button mat-raised-button color="primary" class="myButton" (click)="exportSchedule()" style="margin-right: -30px; margin-top: 20px;">
              Descargar reporte
              <span class="fi-rr-download"></span>
            </button>
          </div>
        </div>

      </div>
    </mat-tab>
    <mat-tab label="Reporte CIU y Vicidial">
      <div class="row col-12 filter" style="padding-top: 2rem">
        <form [formGroup]="reportForm" (ngSubmit)="downloadReportCiuAndVici()">
          <mat-form-field appearance="fill" class="col-4 filter" style="padding-right: 1rem">
            <mat-label>Fecha Inicio y Fin</mat-label>
            <mat-date-range-input [rangePicker]="pickerRange2">
              <input matStartDate formControlName="startDate" placeholder="Fecha Inicio" #startDate2>
              <input matEndDate formControlName="endDate" placeholder="Fecha Fin" #endDate2>
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerRange2"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerRange2></mat-date-range-picker>
          </mat-form-field>

          <mat-form-field class="col-4" appearance="fill" style="padding-right: 1rem">
            <mat-label>Grupo:</mat-label>
            <input type="text" matInput
                   formControlName="groupId" [matAutocomplete]="groupsList" required>
            <mat-autocomplete #campaignList2="matAutocomplete" [displayWith]='displayFnGroups.bind(this)' required>
              <mat-option *ngFor="let group of filteredOptionsGroups | async" [value]="group.id">
                {{group.name}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="error.groups.errors"><span *ngIf="error.groups.errors.required">Campo es
              requerido</span></mat-error>
              <mat-error *ngIf="error.groups.errors">Opción no válida</mat-error>
          </mat-form-field>

          <button mat-raised-button color="primary" style="width: 33.33%" class="download" [disabled]="reportForm.invalid">Generar reporte
            <span class="fi-rr-download"></span>
          </button>

          <p>
            <b>Nota:</b> Genera el reporte de eventos entre CIU y Vicidial
          </p>

        </form>
      </div>
    </mat-tab>
  </mat-tab-group>

</div>
