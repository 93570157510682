import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BpmsComponent } from './bpms.component';
import { RequestsComponent } from './modules/requests/requests.component';

const routes: Routes = [

  { path: '', component: BpmsComponent},
  { path: 'solicitudes', component: RequestsComponent }
  // { path: '', pathMatch: 'full', component: RequestsComponent },
  // { path: '**', component: RequestsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BpmsRoutingModule { }
