<h2 mat-dialog-title>{{data.title}}
    <button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross-small"></i>
  </button>
</h2>

<form class="row form" [formGroup]="rolForm" (ngSubmit)="saveRol(rolForm)">

    <mat-dialog-content style="padding: 3%;" class="mat-typography">

        <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
            <mat-label>Nombre del Rol</mat-label>
            <input style="text-transform: capitalize;" matInput type="text" placeholder="Escribe el nombre del rol" formControlName="rol" required cdkFocusInitial>
            <mat-error *ngIf="error.rol.errors"><span *ngIf="error.rol.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            <mat-error *ngIf="error.rol.errors"><span *ngIf="error.rol.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
            <mat-error *ngIf="error.rol.errors"><span *ngIf="error.rol.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 35 caracteres</span></mat-error>
        </mat-form-field>

        <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
            <mat-label>Aplicación</mat-label>
            <input type="text" matInput formControlName="applications" [matAutocomplete]="auto" required>
            <mat-autocomplete (optionSelected)="appSelected($event.option.value)" #auto="matAutocomplete" [displayWith]='displayFn.bind(this)' required>
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="error.applications.errors"><span *ngIf="error.applications.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            <mat-error *ngIf="error.applications.errors"><span *ngIf="error.applications.errors.invalidAutocompleteObject">Campaña no reconocida. Haga click en una de las opciones de autocompletar</span></mat-error>
        </mat-form-field>

        <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
            <mat-label>Rol</mat-label>
            <input style="text-transform: lowercase;" matInput type="text" placeholder="Escribe el rol" formControlName="key" required>
            <mat-error *ngIf="error.key.errors"><span *ngIf="error.key.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            <mat-error *ngIf="error.key.errors"><span *ngIf="error.key.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
            <mat-error *ngIf="error.key.errors"><span *ngIf="error.key.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 35 caracteres</span></mat-error>
        </mat-form-field>

        <mat-form-field style="padding: 1%;" class="col-3" appearance="fill">
            <mat-label>Menús</mat-label>
            <mat-select formControlName="menu" multiple>
                <mat-option *ngFor="let menu of menus" [value]="menu.id">{{menu.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="error.menu.errors"><span *ngIf="error.menu.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
        </mat-form-field>
        <br>

        <div style="padding: 1%;" *ngIf="gridPermit" class="table-container">
            <div class="mat-elevation-z1">
                
              <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="modulos">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Modulos </th>
                    <td mat-cell *matCellDef="let row"> {{row.label}} </td>
                </ng-container>
                
                <div *ngFor="let action of actions; index as i">

                    <ng-container [matColumnDef]="action.name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{action.name}} </th>
                        <td mat-cell *matCellDef="let row">

                            <form [formGroup]="options">

                                <mat-checkbox class="example-margin" [formControlName]="action.id+'_'+row.id" color="primary" (change)="setValue($event.checked, action.id, row.id)"></mat-checkbox>

                            </form>

                        </td>
                    </ng-container>

                </div>
                    
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No hay datos encontrados</td>
                </tr>
    
              </table>
    
              <!-- <mat-paginator [length]="length"
                             [pageSize]="pageSize"
                             (page)="pageEvent($event)"
                             [pageSizeOptions]="pageSizeOptions">
              </mat-paginator> -->
            
                
            </div>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-stroked-button mat-dialog-close>Cancelar</button>
        <button mat-raised-button type="submit" color="primary" disabled='{{rolForm.invalid}}' [mat-dialog-close]="true">Guardar</button>
    </mat-dialog-actions>

</form>