import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject, Observable, throwError } from 'rxjs';

import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const ENCUESTAS_INTERNAS = environment.ENCUESTAS_INTERNAS;
const NOMINA_URL = environment.NOMINA_URL;

@Injectable({
	providedIn: 'root'
})
export class SurveyService {
	private _refresh$ = new Subject<void>();
	constructor(
		private http: HttpClient,
	) { }

	handleError(error: HttpErrorResponse): any {
		return throwError(error);
	}

    /**
     * Metodo que se encarga de retornar el listdo de Encuestas
     * @author Juan David Guerrero Vargas
     * @returns {Observable<any>} Observable<any>
     */
    getListSurvey(pageSize:number, page:number, ifFilter:boolean, searchModel:any):Observable<any>{ 
        
        let $requesTemp:Observable<any>;
        if(ifFilter === true ){
            $requesTemp = this.http.post<any>(`${ENCUESTAS_INTERNAS}surveys/list?per_page=${pageSize}&paginate=${page}`,searchModel)
        }else{
            $requesTemp =  this.http.get<any>(`${ENCUESTAS_INTERNAS}surveys/list?per_page=${pageSize}&paginate=${page}`)
        }
        return  $requesTemp.pipe(
            catchError(this.handleError)
        );
    }

    /**
     * Metodo que se erncarg de retornar el archivo de reporte
     * @author Juan David Guerrero Vargas
     * @param filters:any {any} filtros de fecha para el reporte
     * @returns {Observable<any>} Observable<any> 
     */
    downLoadReport(filters:any, idSurvey:string|number):Observable<any>{
        return this.http.post<any>(`${ENCUESTAS_INTERNAS}surveys/report/${idSurvey}`,filters,{responseType: 'blob' as 'json'}).pipe(
            catchError(this.handleError)
        );
    }


    /**
     * Metodo que se encarga de obtener el detalle de la encuesta
     * @author Juan David Guerrero Vargas
     * @param idSurvey:number {number} ide de la encuesta a consultar
     * @returns {Observable<any>} Observable<any> 
     */
    getSurvey(idSurvey:number):Observable<any>{
        return this.http.get<any>(`${ENCUESTAS_INTERNAS}surveys/show/${idSurvey}`).pipe(
            catchError(this.handleError)
        );
    }


    /**
     * Metodo que se encarga de guardar la configuracion de la encuesta
     * @author Juan David Guerrero Vargas
     * @param formData:FormData|any {FormData|any} formData con archivo de carga de usuario o Object JSON en caso contrario
     * @returns {Observable<any>} Observable<any>
     */
    saveCofigSurvey(formData:FormData|any):Observable<any>{
        return this.http.post(`${ENCUESTAS_INTERNAS}surveys`, formData,{observe: 'response', responseType: 'blob' as 'json'}).pipe(
            catchError(this.handleError)
        );
    }

    /**
     * Metodo que se encarga de actualziar la configuracion de una encuesta
     * @author Juan David Guerrero Vargasç
     * @param idSurvey:number {number} id de la encuesta a actualziar
     * @param formData:FormData|any {FormData|any} formData con archivo de carga de usuario o Object JSON en caso contrario
     * @returns {Observable<any>} Observable<any>
     */
    updateCondigSurvey(idSurvey:number,formData:FormData|any):Observable<any>{
        return this.http.post(`${ENCUESTAS_INTERNAS}surveys/${idSurvey}`, formData, { observe: 'response' , responseType: 'blob' as 'json'}).pipe(
            catchError(this.handleError)
        );
    }

      /**
     * Metodo que se encarga de actualziar el estdo de la encuesta
     * @author Juan David Guerrero Vargas
     * @param idSurvey:number {number} id de la encuesta a actualziar
     * @param state:number {number} stado a actualziar
     * @returns {Observable<any>} Observable<any>
     */
      updateSurveyState(idSurvey:number,state:number):Observable<any>{
        return this.http.post(`${ENCUESTAS_INTERNAS}surveys/${idSurvey}/change/status`,  {status:state }).pipe(
            catchError(this.handleError)
        );
    }


    /**
     * Metodo que se encarga de obtener las encuestas asigndas a un usuario
     * @author Juan David Guerrero Vargas
     * @author Juan David Guerrero Vargas
     * @returns {Observable<any>} Observable<any> 
     */
    getSurveysUser():Observable<any>{
        return this.http.get<any>(`${ENCUESTAS_INTERNAS}users/surveys`).pipe(
            catchError(this.handleError)
        );
    }


    /**
     * Metodo que se encarga de registrar la respuesta de ls encuestas
     * @author Juan David Guerrero Vargas
     * @param idSurvey:number {number} id de la encuesta a registrar
     * @param formData:any {any} objeto json con las respuestas de la encuesta
     * @returns {Observable<any>} Observable<any>
     */
    saveAnswer(idSurvey:number,formData:any):Observable<any>{
        return this.http.post(`${ENCUESTAS_INTERNAS}surveys/${idSurvey}/resolved/save`, formData).pipe(
            catchError(this.handleError)
        );
    }

    /**
     * Metodo que se encarga de obtener el listado de campañas
     * @author Juan David Guerrero Vargas
     * @returns {Observable<any>} Observable<any>
     */
    getCampaings():Observable<any>{
        return this.http.get<any>(`${NOMINA_URL}campaigns?paginate=0`);
    }

    /**
     * Metodo que se encarga de eliminar las encuestas
     * @author Juan David Guerrero Vargas
     * @param idSurvey:string {string} id de la encuesta a eliminar
     * @returns {Observable<any>} Observable<any>  Observable para pbntencion de respuesta eliminacion
     */
    deleteSurveys(idSurvey:String):Observable<any>{
        return this.http.delete<any>(`${ENCUESTAS_INTERNAS}surveys/${idSurvey}`);
    }
}
