<div class="mat-elevation-z0 mt-4">
    <table  [dataSource]="dataSource" mat-table  matSort>

      <!-- Columna con botónes de acciones -->
      <ng-container matColumnDef="acciones">
        <div>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="detailCoaching(element.id)" *ngIf="element.id && Tab == 0">
              <i class="fi-rr-settings"></i>
            </button>
            <button mat-icon-button (click)="detailAuditoria(element.idAuditoria)" *ngIf="!element.id && Tab == 0">
              <i class="fi-rr-add"></i>
            </button>
            <button mat-icon-button (click)="detailFinal(element.id)" *ngIf="Tab == 12">
              <i class="fi-rr-eye"></i>
            </button>
          </td>
        </div>
      </ng-container>

      <!-- Columna idAgente -->
      <ng-container matColumnDef="id_coaching">
        <th mat-header-cell *matHeaderCellDef> ID Coaching </th>
        <td mat-cell *matCellDef="let element"> {{ element.id ?  element.id: 'N/A'}} </td>
      </ng-container>

      <!-- Columna nombre agente -->
      <ng-container matColumnDef="fecha_coaching">
        <th mat-header-cell *matHeaderCellDef> Fecha Coaching </th>
        <td mat-cell *matCellDef="let element"> {{element.created_at ? (element.created_at | date:'dd/MM/yyyy'): 'N/A' }} </td>
      </ng-container>

      <!-- Columna nombre de documento -->
      <ng-container matColumnDef="nombre_coachee">
        <th mat-header-cell *matHeaderCellDef> Nombre Coachee </th>
        <td mat-cell *matCellDef="let element"> {{ element.nombreCoaching }} </td>
      </ng-container>

      <!-- Columna nombre de documento -->
      <ng-container matColumnDef="cedula">
        <th mat-header-cell *matHeaderCellDef> Cédula </th>
        <td mat-cell *matCellDef="let element"> {{ element.cedulaCoaching }} </td>
      </ng-container>

      
      <!-- Columna nombre de documento -->
      <ng-container matColumnDef="indicador">
        <th mat-header-cell *matHeaderCellDef> Indicador </th>
        <td mat-cell *matCellDef="let element"> {{ element.indicadores ? element.indicadores.nombre:'' }} </td>
      </ng-container>

      <!-- Columna nombre de documento -->
      <ng-container matColumnDef="id_auditoria">
        <th mat-header-cell *matHeaderCellDef> ID Auditoría </th>
        <td mat-cell *matCellDef="let element"> {{ element.idAuditoria === null ? 'N/A': 'Auditoría-'+element.idAuditoria }} </td>
      </ng-container>

      <!-- Columna nombre de documento -->
      <ng-container matColumnDef="causa_raiz">
        <th mat-header-cell *matHeaderCellDef> Causa raíz </th>
        <td mat-cell *matCellDef="let element"> {{ element.causas_raiz != null && element.causas_raiz.nombre ? element.causas_raiz.nombre: 'N/A' }} </td>
      </ng-container>
    
      <!-- Columna nombre de documento -->
      <ng-container matColumnDef="dias_restantes" *ngIf="Tab == 0">
        <th mat-header-cell *matHeaderCellDef> Días restantes </th>
        <td mat-cell *matCellDef="let element"> {{ element.dias_restantes != null ? element.dias_restantes:'N/A' }} </td>
      </ng-container>

      <!-- Columna nombre de documento -->
      <ng-container matColumnDef="estado" *ngIf="Tab == 0">
        <th mat-header-cell *matHeaderCellDef> Estado </th>
        <td mat-cell *matCellDef="let element"> {{ element.estados!=null && element.estados.nombre ? element.estados.nombre :'N/A' }} </td>
      </ng-container>

      <!-- Columna nombre de 'reasignacion' -->
      <ng-container matColumnDef="reasignacion">
        <th mat-header-cell *matHeaderCellDef> Reasignación </th>
        <td mat-cell *matCellDef="let element"> {{ element.reasignado == '1'? 'Sí':'No' }} </td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="12">No hay registros para mostrar</td>
      </tr>
    </table>

    
    <mat-paginator [length]="length"
    [pageSize]="pageSize"
    (page) ="pageEvent($event)"
    [pageSizeOptions] = "pageSizeOptions"
    >
  </mat-paginator>
</div>