import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { NetworkUsersService } from 'src/app/modules/ciu/services/rest/network-users.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-activate-user-form',
  templateUrl: './activate-user-form.component.html',
  styleUrls: ['./activate-user-form.component.sass']
})
export class ActivateUserFormComponent implements OnInit {
  form: FormGroup;
  private rrhh_id;
  private user_name;

  constructor(private formBuilder: FormBuilder, private alertService: AlertsService, private networkUsersService:NetworkUsersService, private dialog: MatDialog, private authService: AuthService) { }

  ngOnInit(): void {
    this.rrhh_id = this.authService.getUser()?.rrhh_id
    this.user_name = this.authService.getUser()?.rrhh?.name
    this.initializeForm()
  }

  /**
  * Metódo que inicializa el formulario
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
  initializeForm() {
    this.form = this.formBuilder.group({
      unlocked_user: ['', [Validators.required]],
      unlocker_user: [this.user_name, []],
      rrhh_id: [this.rrhh_id, []]
    })
  }

  /**
  * Metódo que envía la información para activar el usuario y guardar un registro
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
  saveData() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return
    }

    this.networkUsersService.activateUser(this.form.value).subscribe((resp) => {
      this.dialog.getDialogById('dialogActivateNetworkUser').close({ created: true })
    },(error) => {
      if (error.error.error) {
        this.alertService.alertError('!Atención¡', error.error.error)
      } else {
        this.alertService.alertError('!Atención¡', 'Ha ocurrido un error intentar activar el usuario de red, por favor contacta a soporte')
      }
    })

  }

}
