import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CalidadRoutingModule } from './calidad-routing.module';
import { CalidadComponent } from './calidad.component';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { InteligenciaNegociosComponent } from './modules/inteligencia-negocios/inteligencia-negocios.component';
import { MatricesComponent } from './modules/matrices/matrices.component';
import { TablaPreguntasComponent } from './modules/inteligencia-negocios/components/tabla-preguntas/tabla-preguntas.component';
import { TablaInteligenciaNegocioComponent } from './modules/inteligencia-negocios/components/tabla-inteligencia-negocio/tabla-inteligencia-negocio.component';
import { CrearPreguntaComponent } from './modules/inteligencia-negocios/components/crear-pregunta/crear-pregunta.component';
import { DetallePreguntaComponent } from './modules/inteligencia-negocios/components/detalle-pregunta/detalle-pregunta.component';
import { EditarFormularioComponent } from './modules/inteligencia-negocios/components/editar-formulario/editar-formulario.component';
import { CrearFormularioComponent } from './modules/inteligencia-negocios/components/crear-formulario/crear-formulario.component';
import { MatricesAsignadasComponent } from './modules/inteligencia-negocios/components/matrices-asignadas/matrices-asignadas.component';
import { MainMatricesComponent } from './modules/matrices/components/main-matrices/main-matrices.component';
import { DetalleMatrizComponent } from './modules/matrices/components/detalle-matriz/detalle-matriz.component';
import { CrearMatrizComponent } from './modules/matrices/components/crear-matriz/crear-matriz.component';
import { CrearModuloComponent } from './modules/matrices/components/crear-modulo/crear-modulo.component';
import { CrearItemComponent } from './modules/matrices/components/crear-item/crear-item.component';
import { EditarMatrizComponent } from './modules/matrices/components/editar-matriz/editar-matriz.component';
import { EditarModuloComponent } from './modules/matrices/components/editar-modulo/editar-modulo.component';
import { CrearSubItemComponent } from './modules/matrices/components/crear-sub-item/crear-sub-item.component';
import { EditarSubItemComponent } from './modules/matrices/components/editar-sub-item/editar-sub-item.component';
import { EditarItemComponent } from './modules/matrices/components/editar-item/editar-item.component';
import { BooleanPipe } from './pipes/boolean.pipe';
import { AnalistasCalidadComponent } from './modules/matrices/components/analistas-calidad/analistas-calidad.component';
import { AuditoriasComponent } from './modules/auditorias/auditorias.component';
import { DetalleAuditoriaComponent } from './modules/auditorias/components/detalle-auditoria/detalle-auditoria.component';
import { FormAuditarComponent } from './modules/auditorias/components/form-auditar/form-auditar.component';
import { CalificacionAuditoriaComponent } from './modules/auditorias/components/calificacion-auditoria/calificacion-auditoria.component';
import { RetroalimentarComponent } from './modules/auditorias/components/retroalimentar/retroalimentar.component';
import { TablaRetroalimentarComponent } from './modules/auditorias/components/retroalimentar/components/tabla-retroalimentar/tabla-retroalimentar.component';
import { FormInteligenciaComponent } from './modules/auditorias/components/detalle-auditoria/components/form-inteligencia/form-inteligencia.component';
import { CutTextPipe } from './pipes/cut-text.pipe';
import { DetalleRetroalimentacionComponent } from './modules/auditorias/components/retroalimentar/components/detalle-retroalimentacion/detalle-retroalimentacion.component';
import { TablaAuditadoComponent } from './modules/auditorias/components/tabla-auditado/tabla-auditado.component';
import { ControlComponent } from './modules/control/control.component';
import { DetalleControlComponent } from './modules/control/components/detalle-control/detalle-control.component';
import { TimePipe } from './pipes/time.pipe';
import { TablaControlComponent } from './modules/control/components/tabla-control/tabla-control.component';
import { DialogGrupoComponent } from './modules/control/components/dialog-grupo/dialog-grupo.component';
import { DetalleGrupoComponent } from './modules/control/components/detalle-grupo/detalle-grupo.component';
import { TablaGrupoComponent } from './modules/control/components/tabla-grupo/tabla-grupo.component';
import { AgregarAgenteComponent } from './modules/control/components/agregar-agente/agregar-agente.component';
import { MisAuditoriasComponent } from './modules/mis-auditorias/mis-auditorias.component';
import { ReporteriaComponent } from './modules/reporteria/reporteria.component';
import { UppercaseTextPipe } from './pipes/uppercase-text.pipe';
import { PowerbiComponent } from './modules/powerbi/powerbi.component';
import { EliminarAuditoriaComponent } from './modules/eliminar-auditoria/eliminar-auditoria.component';
import { TablaEliminacionComponent } from './modules/eliminar-auditoria/components/tabla-eliminacion/tabla-eliminacion.component';
import { DialogEliminacionComponent } from './modules/eliminar-auditoria/components/dialog-eliminacion/dialog-eliminacion.component';


@NgModule({
  declarations: [
    CalidadComponent,
    InteligenciaNegociosComponent,
    MatricesComponent,
    TablaPreguntasComponent,
    TablaInteligenciaNegocioComponent,
    CrearPreguntaComponent,
    DetallePreguntaComponent,
    EditarFormularioComponent,
    MainMatricesComponent,
    CrearFormularioComponent,
    MatricesAsignadasComponent,
    DetalleMatrizComponent,
    CrearMatrizComponent,
    CrearModuloComponent,
    CrearItemComponent,
    EditarMatrizComponent,
    EditarModuloComponent,
    CrearSubItemComponent,
    EditarSubItemComponent,
    EditarItemComponent,
    BooleanPipe,
    AnalistasCalidadComponent,
    AuditoriasComponent,
    DetalleAuditoriaComponent,
    FormAuditarComponent,
    CalificacionAuditoriaComponent,
    RetroalimentarComponent,
    TablaRetroalimentarComponent,
    FormInteligenciaComponent,
    CutTextPipe,
    DetalleRetroalimentacionComponent,
    TablaAuditadoComponent,
    ControlComponent,
    DetalleControlComponent,
    TimePipe,
    TablaControlComponent,
    DialogGrupoComponent,
    DetalleGrupoComponent,
    TablaGrupoComponent,
    AgregarAgenteComponent,
    MisAuditoriasComponent,
    ReporteriaComponent,
    UppercaseTextPipe,
    PowerbiComponent,
    EliminarAuditoriaComponent,
    TablaEliminacionComponent,
    DialogEliminacionComponent,
  ],
  imports: [
    CommonModule,
    CalidadRoutingModule,
    MaterialModule,
    SharedModule
  ],
  providers:[
    TimePipe
  ],
  exports:[
    DetalleRetroalimentacionComponent,
    CutTextPipe
  ]
})
export class CalidadModule { }
