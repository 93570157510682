<button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross-small"></i>
</button>


<mat-dialog-content style="padding: 3%;" class="mat-typography">

    <div>
        <h1>Escalamiento</h1>
        <p>Bienvenido a la bandeja de radicación de tickets, aquí podrás radicar nuevas solicitudes de las mismas, revisar y/o gestionar las solicitudes pendientes de radicación.</p>
    </div>

    <br>

    <mat-tab-group>

        <mat-tab label="Escalamientos">
            <br>
            <form class="row form" [formGroup]="escalationfilter" (ngSubmit)="filter(escalationfilter)">

                <div class="row col-12">

                    <mat-form-field style="padding: 1%;" class="col-3" *ngIf="advancedFilters === true" appearance="fill">
                        <mat-label>Número de Radicado</mat-label>
                        <input type="number" matInput formControlName="radicado" (blur)="filter(escalationfilter)">
                    </mat-form-field>

                    <mat-form-field style="padding: 1%;" class="col-3" *ngIf="advancedFilters === true" appearance="fill">
                        <mat-label>Fecha</mat-label>
                        <input matInput [matDatepicker]="dp3" formControlName="fecha" (blur)="filter(escalationfilter)" (dateChange)="filter(escalationfilter)">
                        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                        <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                        <mat-error>No estas incluyendo una fecha</mat-error>
                    </mat-form-field>

                    <mat-form-field style="padding: 1%;" class="col-3" *ngIf="advancedFilters === true" appearance="fill">
                        <mat-label>Canal</mat-label>
                        <mat-select formControlName="canal" (selectionChange)="filter(escalationfilter)">
                            <mat-option [value]="">Ninguna</mat-option>
                          <mat-option *ngFor="let channel of channels" [value]="channel.id">
                            {{channel.name}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field style="padding: 1%;" class="col-3" *ngIf="advancedFilters === true" appearance="fill">
                        <mat-label>Asunto</mat-label>
                        <mat-select formControlName="asunto" (selectionChange)="filter(escalationfilter)">
                            <mat-option [value]="">Ninguna</mat-option>
                         <mat-option *ngFor="let subject of subjects" [value]="subject.id">
                            {{subject.name}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field style="padding: 1%;" class="col-3" *ngIf="advancedFilters === true" appearance="fill">
                        <mat-label>Área Afectada</mat-label>
                        <mat-select formControlName="areaAfectada" (selectionChange)="filter(escalationfilter)">
                            <mat-option [value]="">Ninguna</mat-option>
                            <mat-option *ngFor="let area of areas" [value]="area.id">
                                {{area.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field style="padding: 1%;" class="col-3" *ngIf="advancedFilters === true" appearance="fill">
                        <mat-label>Asignado</mat-label>
                        <input type="text"
                               matInput
                               formControlName="asignado"
                               [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]='displayFn.bind(this)' (optionSelected)="filter(escalationfilter)" required>
                            <mat-option [value]="">Ninguna</mat-option>
                          <mat-option *ngFor="let option of filteredOptionsUser | async" [value]="option.id">
                            {{option.name}}
                          </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field style="padding: 1%;" class="col-3" *ngIf="advancedFilters === true" appearance="fill">
                        <mat-label>Estado</mat-label>
                        <mat-select formControlName="estado" (selectionChange)="filter(escalationfilter)">
                            <mat-option [value]="">Ninguna</mat-option>
                         <mat-option *ngFor="let state of status" [value]="state.id">
                            {{state.name}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="row">
                      <div class="col-3" style="padding-top: 20px;">
                          <button mat-stroked-button type="button" (click)="showButtonMessage()" style="width: 100%" color="primary">
                            {{message}}
                            <span class="fi-rr-filter"></span>
                          </button>
                      </div>
                      <div class="col-3" style="padding-top: 20px;">
                        <button mat-raised-button type="button" (click)="openDialogGestionadorCorreos()" style="width: 100%" color="primary">
                          Nuevo Correo &nbsp;
                          <span class="fi-rr-add"></span>
                        </button>
                      </div>
                    </div>
                </div>
            </form>
            <br>
            <br>
            <div class="table-container">
                <div class="mat-elevation-z8" *ngIf="dataSource.data">
                  <table class="col-12" mat-table [dataSource]="dataSource" matSort>

                          <ng-container matColumnDef="traffic_lights">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                            <td mat-cell *matCellDef="let row"> <div class="{{row.traffic_lights}}"></div> </td>
                          </ng-container>

                          <ng-container matColumnDef="is_anonimous">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Anonimo </th>
                            <td mat-cell *matCellDef="let row"> {{row.is_anonimous == 0 ? 'No' : 'Si'}} </td>
                          </ng-container>

                          <ng-container matColumnDef="id">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Radicado </th>
                              <td mat-cell *matCellDef="let row">{{row.id}} </td>
                          </ng-container>

                          <ng-container matColumnDef="created_at">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                              <td mat-cell *matCellDef="let row"> {{(row.fecha_Hora == null ? row.created_at : row.fecha_Hora )  | date:'shortDate'}} </td>
                          </ng-container>

                          <ng-container matColumnDef="name">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
                              <td mat-cell *matCellDef="let row"> {{row.is_anonimous == 0 ? row.client?.name : 'Anónimo'}}</td>
                          </ng-container>

                          <ng-container matColumnDef="document">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento </th>
                              <td mat-cell *matCellDef="let row"> {{row.is_anonimous == 0 ? row.client?.document : 'Anónimo'}} </td>
                          </ng-container>

                          <ng-container matColumnDef="subject">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Asunto </th>
                              <td mat-cell *matCellDef="let row"> {{row?.subject}} </td>
                          </ng-container>

                          <ng-container matColumnDef="channel">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Canal </th>
                            <td mat-cell *matCellDef="let row"> {{row?.channel}} </td>
                          </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                                <td mat-cell *matCellDef="let row"> {{row.status}} </td>
                            </ng-container>

                            <ng-container matColumnDef="responsible">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Responsable </th>
                                <td mat-cell *matCellDef="let row"> {{row.responsible}} </td>
                            </ng-container>

                            <ng-container matColumnDef="accion">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                              <td mat-cell *matCellDef="let row">

                                <div *ngIf="this.rol === 'radicador'">

                                    <button mat-icon-button (click)="visualizar(row.id)">
                                        <i class="fi-sr-eye"></i>
                                    </button>
                                </div>

                                <div *ngIf="this.rol === 'solucionador' || this.rol === 'administrador' || this.rol === 'supervisor_esca'">
                                    <button mat-icon-button (click)="visualizar(row.id)">
                                        <i class="fi-sr-asterisk"></i>
                                    </button>
                                </div>

                              </td>
                            </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro </td>
                    </tr>
                  </table>

                  <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="length" (page)="pageEvent($event)"></mat-paginator>
                </div>
            </div>

              <br>
              <br>

            <div class="row content-traffic">
              <div class="traffic">
                  <div class="VERDE"></div>&nbsp;Prioridad Baja
              </div>
              <div class="traffic">
                  <div class="AMARRILLO"></div>&nbsp;Prioridad Media
              </div>
              <div class="traffic">
                  <div class="ROJO"></div>&nbsp;Prioridad Alta
              </div>
            </div>

        </mat-tab>

        <mat-tab *ngIf="rol === 'administrador'" label="Usuarios">

            <br>
            <br>
            <div class="table-container">
                <div class="mat-elevation-z8">
                  <table class="col-12" mat-table [dataSource]="dataSourceUser" matSort>

                          <ng-container matColumnDef="name">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                              <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                          </ng-container>

                          <ng-container matColumnDef="id_document">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento </th>
                              <td mat-cell *matCellDef="let row"> {{row.id_document}} </td>
                          </ng-container>

                          <ng-container matColumnDef="roles">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Rol </th>
                              <td mat-cell *matCellDef="let row"> {{row.roles[0]}} </td>
                          </ng-container>

                          <ng-container matColumnDef="email">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Correo </th>
                              <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                          </ng-container>

                            <ng-container matColumnDef="accion">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                                <td mat-cell *matCellDef="let row">

                                <button mat-icon-button (click)="editarUser(row)">
                                    <i class="fi-rr-edit"></i>
                                </button>

                                <mat-slide-toggle
                                class="example-margin"
                                [color]="color"
                                [checked]="row.state"
                                [disabled]="disabled"
                                (change)="toggleUser(row, $event.checked)">
                                </mat-slide-toggle>

                                </td>
                            </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsUser"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsUser;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro </td>
                    </tr>
                  </table>

                  <mat-paginator
                  #paginatorUser
                  (page)="pageEventU($event)"
                  [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                </div>
            </div>

        </mat-tab>

        <mat-tab *ngIf="rol === 'administrador'" label="Campos">

            <br>

            <mat-accordion class="example-headers-align">

                <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Estados
                    </mat-panel-title>
                    <mat-panel-description>
                        Define la gestión a realizar en las PQRS
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <br>
                <button mat-raised-button color="primary" (click)="crearEstado()">Nuevo Item +</button>
                <br>
                <br>
                  <div class="table-container">
                    <div class="mat-elevation-z8">
                      <table class="col-12" mat-table [dataSource]="dataSourceEstados" matSort>

                              <ng-container matColumnDef="name">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre del campo </th>
                                  <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                              </ng-container>

                              <ng-container matColumnDef="description">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                                  <td mat-cell *matCellDef="let row"> {{row.description}} </td>
                              </ng-container>

                                <ng-container matColumnDef="accion">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                                    <td mat-cell *matCellDef="let row">

                                    <button mat-icon-button (click)="editarEstado(row)">
                                        <i class="fi-rr-edit"></i>
                                    </button>

                                    <mat-slide-toggle
                                    class="example-margin"
                                    [color]="color"
                                    [checked]="row.state"
                                    [disabled]="disabled"
                                    (change)="toggleState(row, $event.checked)">
                                    </mat-slide-toggle>

                                    </td>
                                </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsEstados"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsEstados;"></tr>

                        <tr class="mat-row" *matNoDataRow>
                          <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro </td>
                        </tr>
                      </table>

                      <mat-paginator #paginatorEstados [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                    </div>
                </div>

                  <mat-action-row>
                    <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
                  </mat-action-row>
                </mat-expansion-panel>


                <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                        Asuntos
                    </mat-panel-title>
                    <mat-panel-description>
                        Motivo principal asociado a la PQRS
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <br>
                <button mat-raised-button color="primary" (click)="crearAsunto()">Nuevo Item +</button>
                <br>
                <br>

                  <div class="table-container">
                    <div class="mat-elevation-z8">
                      <table class="col-12" mat-table [dataSource]="dataSourceAsuntos" matSort>

                              <ng-container matColumnDef="name">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                  <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                              </ng-container>

                              <ng-container matColumnDef="description">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                                  <td mat-cell *matCellDef="let row"> {{row.description}} </td>
                              </ng-container>

                                <ng-container matColumnDef="accion">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                                    <td mat-cell *matCellDef="let row">

                                    <button mat-icon-button (click)="editarAsunto(row)">
                                        <i class="fi-rr-edit"></i>
                                    </button>

                                    <mat-slide-toggle
                                    class="example-margin"
                                    [color]="color"
                                    [checked]="row.state"
                                    [disabled]="disabled"
                                    (change)="toggleAsunto(row, $event.checked)">
                                    </mat-slide-toggle>

                                    </td>
                                </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsAsuntos"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsAsuntos;"></tr>

                        <tr class="mat-row" *matNoDataRow>
                          <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro </td>
                        </tr>
                      </table>

                      <mat-paginator #paginatorAsuntos [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                    </div>
                </div>

                  <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Antes</button>
                    <button mat-button color="primary" (click)="nextStep()">Fin</button>
                  </mat-action-row>
                </mat-expansion-panel>

              </mat-accordion>


        </mat-tab>

    </mat-tab-group>


</mat-dialog-content>



