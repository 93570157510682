import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-print-form-survey',
  templateUrl: './print-form-survey.component.html',
  styleUrls: ['./print-form-survey.component.css']
})

export class PrintFormSurveyComponent implements OnInit, OnChanges {
  @Input() questions: any[] = [];
  @Input() textButtonSubmit: string = 'Guardar';
  @Input() ifFormFilter: boolean = false;
  @Input() alertTextConfirm = '¿De enviar las respuestas registradas?';
  @Input() showAlertToCancelButton: boolean = false;
  @Output() sendQuestions: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeValueSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendIndexField: EventEmitter<number> = new EventEmitter<number>();
  @Output() onClickButtonCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() onClickButtonDeleteFormArray: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  formSurvey: FormGroup = null;

  constructor(private alertService: AlertsService) { }

  ngOnInit(): void {
    const objectForm = {};
    this.questions.forEach((item: any) => {
      if (item.typeInput === 'date-range') {
        objectForm[item.range[0].key] = new FormControl(item.range[0].value, this.generateValidators(item.range[0].rules));
        objectForm[item.range[1].key] = new FormControl(item.range[1].value, this.generateValidators(item.range[1].rules));
      } else if (item.typeInput === 'array') {
        if (item.value.length === 0) {
          objectForm[item.key] = new FormArray([
            new FormGroup({
              text: new FormControl(null, [Validators.required])
            })
          ]);
        } else {
          const formArray = new FormArray(
            (item.value || []).map((val: any) =>
              new FormGroup({
                id: new FormControl(val.id, []),
                text: new FormControl(val.text, [Validators.required])
              })
            )
          );
          objectForm[item.key] = formArray;
        }
      }
      else {
        const validators = this.generateValidators(item.rules);
        objectForm[item.key] = new FormControl(item.value, validators);
      }
    });
    this.formSurvey = new FormGroup(objectForm);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.questions.forEach((item: any) => {
      const validators = this.generateValidators(item.rules);
      this.formSurvey?.get(item.key)?.setValidators(validators);
      this.formSurvey?.get(item.key)?.updateValueAndValidity();
    });
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-12
    * Metodo que añade reglas de validacion al formulario.
    * @param rules Json con las configuraciones de las validaciones. 
  */
  generateValidators(rules: any): any[] {
    if (!rules) return [];
    const validators = [];
    for (const key in rules) {
      if (key === 'required') validators.push(Validators.required);
      if (key === 'minLength') validators.push(Validators.minLength(rules[key]));
      if (key === 'maxLength') validators.push(Validators.maxLength(rules[key]));
      if (key === 'email') validators.push(Validators.email);
      if (key === 'pattern') validators.push(Validators.pattern(rules[key]));
    }
    return validators;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-11
    * Metodo que envia las respuestas del formulario al componente padre.  
  */
  submitForm(): void {
    if (this.formSurvey.valid) {
      if (!this.ifFormFilter) {
        this.alertService.alertWarning('¿Está seguro?', '¿De enviar las respuestas registradas?').then(isConfirm => {
          if (isConfirm.isConfirmed) this.sendQuestions.emit(this.formSurvey.value);
        });
      } else {
        this.sendQuestions.emit(this.formSurvey.value);
      }
    } else this.formSurvey.markAllAsTouched();

  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-12
    * Metodo que emite la informacion del select, al seleccionar una opcion.
    * @param $event Evento emitido por el campo del formulario. 
    * @param key Id unico del campo.   
  */
  onChangeValueSelect($event: MatSelectChange, key: string): void {
    this.changeValueSelect.emit({ value: $event.value, key });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-22
    * Metodo que captura el indice de la lista de campos del formulario. 
    * @param index Indice de la lista
  */
  onClickShowOrHidePassword(index: number): void {
    this.sendIndexField.emit(index);
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-08-29
    * Metodo que retorna el form array de los campos dinamicos.
    * @param key Identificador del campo.
  */
  get options() {
    return (key: string) => this.formSurvey.get(key) as FormArray;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-08-29
    * Metodo que agrega un campo al form array.
    * @param key Identificador del campo.
  */
  addOption(key: string) {
    const formArray = this.options(key);
    formArray.push(new FormGroup({
      text: new FormControl(null, [Validators.required])
    }));
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-08-29
    * Metodo que elimina un campo del form array.
    * @param key Identificador del campo.
    * @param index Indice del campo seleccionado dentro del form array.
  */
  removeOption(key: string, index: number) {
    this.alertService.alertWarning('¿Está seguro?', '¿Está seguro de eliminar el elemento seleccionado?').then(isConfirm => {
      if (isConfirm.isConfirmed) {
        const fieldSelected = this.options(key).at(index) as FormGroup;
        const formArray = this.options(key);
        formArray.removeAt(index);
        this.onClickButtonDeleteFormArray.emit(fieldSelected);
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-09-10
    * Metodo que carga el archivo sobre el value del campo del formulario.
    * @param  $event Evento generado por el campo del formulario.
    * @param key key del campo a modificar.
  */
  onFileChange($event: Event, key: string) {
    const input = $event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.formSurvey.get(key)?.setValue(file);
    }
  }
}