import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EDITOR_CONFIG } from '../gestionador-correos/ConfigAngularEditor';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { EscalationService } from 'src/app/modules/crm/services/rest/escalation.service';

@Component({
  selector: 'app-view-plantillas',
  templateUrl: './view-plantillas.component.html',
  styleUrls: ['./view-plantillas.component.sass']
})
export class ViewPlantillasComponent implements OnInit {
  editorConfig: any = EDITOR_CONFIG;
  formViewPlantillas = new   FormGroup({
    plantilla: new FormControl('', []),
    bodyPlantilla: new FormControl('', [Validators.required]),
    files: new FormControl([])
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: { 
    id: any,
    escalamiento: any, 
    plantillas: any }, 
    private alertService: AlertsService,
    private escalamientosService: EscalationService,
    public dialogRefViewPlantillas: MatDialogRef<ViewPlantillasComponent>) { }

  ngOnInit(): void {
    
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-02-02
    * Metodo que cambia la plantilla html sobre el cuerpo del correo.
    * @param $event Variable que trae toda la informacion del campo. 
  */
  changePlantillaEmailBody($event: any): void {
    const idPlantilla = $event.value;
    const searchPlantilla = this.data.plantillas.find(item => { return item.id === idPlantilla });
    if (searchPlantilla) this.formViewPlantillas.get('bodyPlantilla').setValue(searchPlantilla.body);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-02-09
    * Metodo que guarda la PQRS al cerrarse.
  */
  cerrarPqrs(): void {
    if (this.formViewPlantillas.valid) {
      const editForm = new FormData();
      editForm.append('solucionador_id' , this.data.escalamiento.solucionador_id);
      editForm.append('estado_id' , this.data.escalamiento.estado_id);
      editForm.append('preguntas' , JSON.stringify(this.data.escalamiento.preguntas));
      editForm.append('form_answer_id', this.data.escalamiento.form_answer_id);
      editForm.append('id_plantilla', this.formViewPlantillas.get('plantilla').value);
      editForm.append('html_body', this.formViewPlantillas.get('bodyPlantilla').value);
      for (let i = 0; i < this.formViewPlantillas.get('files').value.length; i ++) {
        editForm.append(`adjunto${i}`, this.formViewPlantillas.get('files').value[i].file);
      }
      this.escalamientosService.postFormEscalation(editForm, this.data.id).subscribe((resp) => {
        if (resp) {
          this.alertService.alertWithAnyProperties('Editado', 'La PQRS fue editada correctamente', 'success', 'Continuar').then(confirm => {
            if (confirm.isConfirmed) {
              this.dialogRefViewPlantillas.close();
            }
          });
        }
      });
    } else {
      this.formViewPlantillas.markAllAsTouched();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-02-11
    * Metodo que envia los archivos desde el componente hijjo. 
    * @param $event Lista de archivos.  
  */
  setListFiles($event: any): void {
    this.formViewPlantillas.get('files').setValue($event);
  }
}