<div class="main-control">
  <div class="header">
    <h2 style="height: 33px !important;
        font: normal normal bold 45px/35px Poppins !important;
        letter-spacing: 0px !important;color: #353535 !important;opacity: 1 !important;">{{config.title}}</h2>
    <br>
    <p>{{config.description1}}</p>
    <p>{{config.description2}}</p>
  </div>
  <div class="mt-2">
    <app-main-matrices></app-main-matrices>
  </div>
</div>
