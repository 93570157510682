<div class="main-control">
    <div class="header">
        <h2 style="height: 33px !important;
        font: normal normal bold 45px/35px Poppins !important;
        letter-spacing: 0px !important;color: #353535 !important;opacity: 1 !important;">Control</h2>
        <br>
        <p>Aquí podrás crear y editar los grupos de las campañas. No</p>
        <p>olvides, “El respeto y empatía son clave”.</p>
      </div>
    <div class="mt-2">
        <app-main-matrices></app-main-matrices>
    </div>
</div>