import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NoveltiesService } from '../../../../services/novelties.service';

// MomentJS
import * as moment from 'moment';

import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import { FormControl } from '@angular/forms';
import { FileValidator } from 'ngx-material-file-input';

@Component({
  selector: 'app-novelties-response',
  templateUrl: './novelties-response.component.html',
  styleUrls: [ './novelties-response.component.sass' ]
})
export class NoveltiesResponseComponent implements OnInit {

  public novelty;
  public justify: string;
  public sending: boolean;

  public showJustifyForm: boolean;
  public showJustifyInfo: boolean;
  public showInput: boolean;

  public realTime: string;
  public scheduleTime: string;
  public endTime: string;
  public noveltyTypeKey;
  public compensatorioKey = '0';
  public noveltyDetailHeight: number;
  public fileControl = new FormControl();
  readonly maxSize = 10000000;
  maxFiles: boolean = true;
  formData = new FormData();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private noveltiesService: NoveltiesService,
              private alert: AlertsService) {
    this.showJustifyForm = false;
    this.showJustifyInfo = false;
    this.showInput = false;
    this.sending = false;
    this.noveltyDetailHeight = 0;
  }

  ngOnInit(): void {
    this.noveltyTypeKey = this.data.extraData.novelty_type.key ?? null;
    this.getPayrollNovelty();
    this.fileControl.setValidators([ FileValidator.maxContentSize(this.maxSize) ]);
    this.fileControl.updateValueAndValidity();
    this.fileControl.setValue('');

    this.fileControl.valueChanges.subscribe(resp => {
      if (resp?._files?.length < 3) {
        this.sending = false;
        this.maxFiles = true;
      } else {
        this.sending = true;
        this.maxFiles = false;
      }

      if (resp == null) {
        this.sending = false;
        this.maxFiles = true;
      }
    });
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Consulta la novedad seleccionada previamente en el calendario
   */
  getPayrollNovelty(): void {
    this.noveltiesService.getEventInfoByPayrollNoveltyId(this.data.extraData.novelty_user_id, this.data.extraData.id)
      .subscribe(resp => {
        this.novelty = resp.data;
        this.novelty.novelty_type.key = (this.novelty.novelty_type.key < 10) ? `0${ this.novelty.novelty_type.key }` : this.novelty.novelty_type.key;
        this.novelty.novelty_date_start = moment(resp.data.novelty_date_start).locale('es').format('D [de] MMMM, YYYY');
        this.realTime = moment(resp.data.real_start_time).locale('es').format('hh:mm a').toString();
        this.scheduleTime = moment(`${ resp.data.schedule_info.schedule_date } ${ resp.data.schedule_info.scheduled_start_time }`).locale('es').format('hh:mm a').toString();
        this.endTime = moment(`${ resp.data.schedule_info.schedule_date } ${ resp.data.schedule_info.scheduled_end_time }`).locale('es').format('hh:mm a').toString();
        if (this.novelty.validForMoreResponses) {
          this.showJustifyForm = true;
        }
        this.showJustifyInfo = true;
        this.changeDetailHeight(this.novelty);
      });
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Justifica la novedad seleccionada previamente en el calendario
   */
  saveJustifyForEmployee(): void {
    this.sending = true;
    let Array = [];
    this.formData = new FormData();
    this.formData.append('justify', this.justify);
    if (this.fileControl.value._files !== undefined && this.fileControl.value._files.length > 0 && this.fileControl.value._files.length < 3) {
      Array = this.fileControl.value?._files;
      Array.map(item => {
        this.formData.append('Files[]', item);
      });
    }
    this.noveltiesService.saveJustifyForEmployee(this.data.extraData.id, this.formData)
      .subscribe(resp => {
        this.alert.alertSuccess('Justificación realizada', 'En espera de aprobación y/o rechazo');
        this.clear();
      }, error => this.sending = false);
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose limpiar propiedades
   */
  clear(): void {
    this.showJustifyForm = false;
    this.showJustifyInfo = false;
    this.showInput = false;
    this.sending = false;
    this.ngOnInit();
  }

  changeDetailHeight(novelty): void {
    if (novelty.responseFirstInstance && (novelty.responseFirstInstance.justify || novelty.responseFirstInstance.response)) {
      this.noveltyDetailHeight = 240;
    }
  }
}
