<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<h3>Pruebas del candidato</h3>
<hr>
<mat-dialog-content>
    <div *ngIf="!candidate?.testsAssigned">
        <p><strong>Seleccione las pruebas que desea asignar al candidato</strong> </p>
        <mat-selection-list #testSelected>
            <mat-list-option checkboxPosition *ngFor="let test of testsList" [selected]="test.selected" (click)="pushArray(testSelected)" [value]="test">
                {{test.name}}
            </mat-list-option>
        </mat-selection-list>
    </div>

    <div *ngIf="candidate?.testsAssigned && candidate?.tests.length > 0">
         <mat-tab-group mat-align-tabs="start" #tabs>
            <mat-tab *ngFor="let test of candidate?.tests" [label]="test.test.name">
              <div *ngIf="test.test.key =='clinica_assesment' && test.resources" class="content">
                <p><strong>Calificación general</strong>
                    <span matCardSubtitle *ngIf="test.evaluation == null">Pendiente</span> 
                    <span matCardSubtitle  *ngIf="test.evaluation != null">{{test.evaluation}}%</span>
                </p> 
                
                <p > <strong>Área de selección</strong> <br>
                     <span matCardSubtitle *ngIf="test.resources[0].evaluation === ''">Pendiente</span> 
                     <span matCardSubtitle *ngIf="test.resources[0].evaluation"> Calificación general <b>{{test.resources[0].evaluation}}%</b></span>
                </p>
                <ul>
                    <li *ngFor="let item of test.resources[0].assessments">
                        <p matCardSubtitle >{{item.name}} - Calificación
                             <b >{{item.calification | assessmentResults}}</b>
                        </p>
                    </li>
                </ul>
                 <p > <strong>Área de operación</strong> <br> 
                    <span matCardSubtitle *ngIf="test.resources[1].evaluation == ''">Pendiente</span> 
                     <span matCardSubtitle *ngIf="test.resources[1].evaluation"> Calificación general <b>{{test.resources[1].evaluation}}%</b></span>
                </p>
                <ul>
                     <li *ngFor="let item of test.resources[1].assessments">
                        <p matCardSubtitle >{{item.name}} - Calificación
                             <b>{{item.calification | assessmentResults}}</b>
                        </p>
                    </li>
                </ul>
                <p > <strong>Área de formación</strong> <br>
                    <span matCardSubtitle *ngIf="test.resources[2].evaluation ==''">Pendiente</span> 
                    <span matCardSubtitle *ngIf="test.resources[2].evaluation"> Calificación general <b>{{test.resources[2].evaluation}}%</b></span>
                </p>  
                <ul>
                     <li *ngFor="let item of test.resources[2].assessments">
                        <p matCardSubtitle >{{item.name}} - Calificación
                             <b>{{item.calification |assessmentResults}}</b>
                        </p>
                    </li>  
                </ul>
                </div> 

                <div *ngIf="test.test.key !== 'clinica_assesment' " class="content">
                     <div *ngIf="!test?.resources?.candidateAttachmentName || !test?.resources?.comments" class="content">
                        <p>El candidato aún no ha suminstrado sus respuestas</p>
                    </div>
                    <div *ngIf="test?.resources?.candidateAttachmentName || test?.resources?.comments">
                        <p> <strong>Respuestas suministradas por el candidato</strong> </p>
                        <br>
                        <p *ngIf="test?.resources?.comments"> <b>Comentario</b>   {{ test?.resources?.comments }}</p>
                        <div *ngIf="test?.resources?.candidateAttachmentName">
                            <p><b>Archivo Adjuntado</b> {{test?.resources?.candidateAttachmentName}}</p>
                            <button mat-button  (click)="downloadFile(test)">Descargar archivo adjunto &nbsp;<i class="fi-rr-cloud-download"></i> </button>
                        </div>
                    </div>
                </div>

            </mat-tab>
            <mat-tab label="Evaluación">
                <div class="content">
                    <p> <strong>De acuerdo a los resultados obtenidos por el candidato, ingrese su calificación y comentarios finales. <br> Una vez se registren sus calificaciones por prueba deberá registrar el concepto final para el candidato.</strong> 
                    </p>
                    <br>
                    <div fxLayout="column" >
                        <mat-form-field appearance="fill">
                            <mat-label>Pruebas a Evaluar</mat-label>
                            <mat-select #test (selectionChange)="selectedTest(test.value)">
                                <mat-option *ngFor="let row of candidate.tests" [value]="row">{{ row.test.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    
                        <mat-form-field appearance="fill">
                            <mat-label>Evaluación</mat-label>
                            <textarea matInput rows="6" [(ngModel)]="testSelectedToEvaluation.evaluation"></textarea>
                        </mat-form-field>
                    </div>
                   <div fxLayout="row" fxLayoutAlign="center center" >
                    <button style="margin: 8px;" mat-stroked-button color="primary" (click)="saveEvaluation()">Guardar Evaluación</button>
                    <button style="margin: 8px;" mat-flat-button color="primary" [disabled]="!candidate.enableCommentBtn" (click)="openComments()">Gestionar Candidato</button>
                   </div>
                   
                </div>


            </mat-tab>
        </mat-tab-group> 
    </div>


</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="!candidate?.testsAssigned">
    <button color="primary" mat-flat-button (click)="saveTests()" >Asignar </button>
</mat-dialog-actions>
