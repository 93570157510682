<div class="main-container">
    <button mat-button (click)="_location.back()" class="back-btn">
        <span class="fi-sr-angle-small-left"></span>
        <strong>Regresar &nbsp;</strong>
    </button>
    <h1>Reportes</h1>
    <p>En esta sección podrás visualizar o descargar todos los reportes</p>
    <form class="filter" [formGroup]="filterForm">
        <mat-form-field appearance="fill">
            <mat-label>Reportes Disponibles</mat-label>
            <mat-select formControlName="report"  required>
                <mat-option value="resumeReport">Reporte Hoja de vida</mat-option>
                <mat-option value="arlReport">Reporte ARL</mat-option>
                <mat-option value="newHireReport">Reporte Ingreso Personal</mat-option>
                <mat-option value="compensationReport">Reporte Caja de compensación</mat-option>
                <mat-option value="datos_cuenta">Reporte personal datos cuenta</mat-option>
                <mat-option value="referencias">Reporte referencias</mat-option>
                <mat-option value="validacion_documentos">Reporte validación de documentos</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Fecha de inicio</mat-label>
            <input matInput [matDatepicker]="picker1" required formControlName="start_date">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Fecha de fin</mat-label>
            <input matInput [matDatepicker]="picker2" [max]="maxDate" [min] ="filterForm.controls.start_date.value" required formControlName="end_date" >
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
          <button mat-flat-button color="primary" (click)="getReports()" [disabled]="filterForm.invalid">Buscar</button>
    </form>
  </div>
