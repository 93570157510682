import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA,  MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { CampaignService } from '../../../../services/campaign.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { AdminServersService } from 'src/app/modules/speech-analytics/services/admin-servers.service';
import { ViewService } from 'src/app/modules/speech-analytics/services/view.service';

@Component({
  selector: 'app-edit-campaign',
  templateUrl: './edit-campaign.component.html',
  styleUrls: ['./edit-campaign.component.sass']
})
export class EditCampaignComponent implements OnInit {

  EditCampaignForm: FormGroup;
  filteredOptionsCampaign: Observable<any[]>;
  campaigns: any;
  dataSource: any;
  dataConnection: any;
  connections: any;
  fecha_llamada: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditCampaignComponent>,
    private form: FormBuilder,
    private adminServersService: AdminServersService,
    private campaignService: CampaignService,
    private viewservice: ViewService,   
  ) { }

  ngOnInit(): void {
    this.formControl();
    this.viewServer(this.data);
    this.getConnections();
    this.subscribeToCampaignSelection();
  }

  /**
   * Método donde se establecen las validaciones del formulario y adviarte antes de refrescar 
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @method unloadNotification
   * @param {Event} $event - El evento `beforeunload` del navegador.
   * @returns {void}
  */

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.EditCampaignForm.dirty) {
      $event.returnValue = true;
    }
  }

  /**
   * Metodo donde se establecen las validaciones del formulario
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   */
  
  formControl(): void {
    this.EditCampaignForm = this.form.group({
      id: ['', Validators.required],
      campaign_name: new FormControl ('', Validators.required),
      campaign: new FormControl('', [Validators.required, this.campaignValidator.bind(this)]),
      dead_time: new FormControl ('', [Validators.required, Validators.pattern('^[0-9]+$')]),
      campaign_bilingue: new FormControl ('', Validators.required),
      campaign_conexion: new FormControl ('', Validators.required),
      delimit_date: new FormControl(''),
    });
  }

    /**
   * Metodo validar rango de fecha para desabilotar input fecha 2 meses
   * @author Yeison sepulveda
   * @createdate    14/03/2024
   * @returns void {void} 
   */
  dateFilter = (date: Date | null): boolean => {
    if (!date) {
      return false;
    }
    const today = new Date();
    return date <= today;
  }
  
  /**
   * Metodo qeu consulta la informacion para cargarla en el formulario
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  viewServer(server: any): void {
    this.campaignService.showCampaign(server.id).subscribe(res => {
      this.dataSource = res.data;
  
      const campaignConexion = res.data.connection_id;
      this.getCampaign(campaignConexion);
  
      this.EditCampaignForm.patchValue({
        id: res.data.id,
        campaign_name: res.data.name,
        campaign: res.data.campaign_id,
        dead_time: res.data.dead_time,
        campaign_bilingue: res.data.bilingue,
        campaign_conexion: res.data.connection_id,
        delimit_date: res.data.delimit_date ? this.addOneDay(new Date(res.data.delimit_date)) : null
      });
    });
  }

  /**
   * Cargar la fecha correcta delimitada
   * @author Yeison Sepulveda
   * @createdate 2024-09-26
  */
  addOneDay(date: Date): Date {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    return newDate;
  }

  /**
   * Metodo para traer todas las campañas
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   */

  getCampaign(id_server: number): void {
    this.viewservice.getCampaigns(id_server).subscribe((resp) => {
      this.campaigns = resp.data;
  
      const defaultCampaignId = this.EditCampaignForm.get('campaign')?.value;
      const defaultCampaign = this.campaigns.find(campaign => campaign.campaign_id === defaultCampaignId);
  
      if (defaultCampaign) {
        this.EditCampaignForm.get('campaign')!.setValue(defaultCampaign.campaign_id);
        this.fecha_llamada = defaultCampaign.fecha_llamada;
      }
      
      this.filteredOptionsCampaign = this.EditCampaignForm.get('campaign')!.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : this.displayFnCampaign(value)),
        map(name => name ? this._filterCampaign(name) : this.campaigns.slice())
      );
    });
  }
  
  /**
   * Metodo para obtener la data de lso selctores segun el tipo de llamada
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   */

  CampaignChange():void{
    if(this.EditCampaignForm.get("campaign_conexion").value !== null && this.EditCampaignForm.get("campaign_conexion").value !== "" ){
      const connection_id = this.EditCampaignForm.get("campaign_conexion").value;
      this.getCampaign(connection_id);
    }
  }

  /**
   * Metodo para obtener fecha llamada segun la selccion de la campaña
   * @author Yeison Sepulveda
   * @createdate 2024-08-30
   */
  subscribeToCampaignSelection(): void {
    this.EditCampaignForm.get('campaign')!.valueChanges.subscribe(campaignId => {
        const selectedCampaign = this.campaigns.find(c => c.campaign_id === campaignId);
        if (selectedCampaign) {
          this.fecha_llamada = selectedCampaign.fecha_llamada;
        }
    });
  }

  /**
   * Metodo obtener el listado de las conexiones activas para selector
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   */
  getConnections(): void {
    this.adminServersService.filterServe().subscribe(
      result => {
        this.connections = result.data;
      },
      error => {
        console.error('Error al obtener las conexiones', error.message);
        Swal.fire({
          icon: 'error',
          title: 'Error al obtener las conexiones',
          text: error.message,
          timer: 2000,
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            this.dialogRef.close();
          }
        });
      }
    );
  }

  /**
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   * @author Daniel Martinez
   * @createdate 2021-02-04
   */
  displayFnCampaign(campaign_id: number): string {
    if (!campaign_id || !this.campaigns) {
      return '';
    }
    const campaign = this.campaigns.find(c => c.campaign_id === campaign_id);
    return campaign ? campaign.campaign_name : '';
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * filtra por nombre de rol, se usa para el autocompletable
   * @param value valor a filtrar
   */

  private _filterCampaign(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.campaigns.filter(option => option.campaign_name.toLowerCase().includes(filterValue));
  }

  /**
   * Metodo que guarda o actualiza un servidor. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  sendDataCampaign() {
    if (this.EditCampaignForm.valid) {
      Swal.fire({
        title: '¿Está seguro?',
        icon: 'warning',
        text: '¿De continuar con la gestión?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#2CABBC',
        cancelButtonColor: '#FFFFFF',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          const dataToSend = {
            id: this.EditCampaignForm.value.id,
            name: this.EditCampaignForm.value.campaign_name,
            campaign_id: this.EditCampaignForm.value.campaign,
            dead_time: this.EditCampaignForm.value.dead_time,
            bilingue: this.EditCampaignForm.value.campaign_bilingue,
            connection_id: this.EditCampaignForm.value.campaign_conexion,
            delimit_date: this.EditCampaignForm.value.delimit_date
          };
          this.campaignService.UpdateCampaign(dataToSend).subscribe(
            res => {
              Swal.fire({
                title: '¡Excelente!',
                icon: 'success',
                text: 'Se ha registrado una nueva campaña',
                confirmButtonText: 'Continuar',
                confirmButtonColor: '#2CABBC'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.dialogRef.close();
                }
              });
            },
            error => {
              console.error('Error al editar la campaña', error.message);
              Swal.fire({
                icon: 'error',
                title: 'Ha ocurrido un error al editar la campaña.',
                text: error.message,
                showConfirmButton: true,
                confirmButtonColor: '#2CABBC',
                confirmButtonText: 'Aceptar',
              });
            }
          );
        }
      });
    } else {
      this.EditCampaignForm.markAllAsTouched();
    }
  }
  
  /**
   * Metodo que reinicia los valores del modal. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  cancelSave(): void {
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      text: 'Si cancelas el proceso, perderás toda la información no guardada.',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.EditCampaignForm.reset();
        this.dialogRef.close();
      }
    });    
  }

  /**
   * Validador personalizado para el campo 'campaign'.
   * @author Yeison Sepulveda
   * @createdate 2024-07-19
   * @param formGroup El grupo de formularios que contiene el campo 'campaign'.
   * @returns objeto con el error 'invalidCampaign' si el valor es inválido, o null si es válido.
   */
  campaignValidator(control: FormControl): { [key: string]: boolean } | null {
    if (!this.campaigns) {
      return null;
    }
    const isValid = this.campaigns.some(c => c.campaign_id === control.value);
    return isValid ? null : { invalidCampaign: true };
  }

}
