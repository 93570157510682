import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminTemplateComponent } from './admin-template/admin-template.component';
import { SaveTemplateComponent } from './save-template/save-template.component';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { GenerateTemplateComponent } from './generate-template/generate-template.component';
import { TemplatesRoutingModule } from './templates.routing.module';

@NgModule({
  declarations: [AdminTemplateComponent,
                 SaveTemplateComponent,
                 GenerateTemplateComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    TemplatesRoutingModule
  ]
})
export class TemplatesModule { }
