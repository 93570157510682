<button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<div mat-dialog-content class="containerDialog" >
    <div class="row">
        <h2 class="col-11">{{titulo}}</h2>
        <button mat-icon-button class="col-1" *ngIf="!showEdit" (click)="edit()">
            <mat-icon style="font-size: 16px;"><i class="fi-rr-edit"></i></mat-icon>
        </button>
    </div>
    <mat-divider class="mb-3"></mat-divider>
    <p *ngIf="analistas.length == 0 && this.datos.type == 'analista'" style="margin-bottom: 1.5em;">
        ¡Oops! Parece que aún no hay analistas de calidad asignados, busca al analista que deseas asignar a esta matriz.
    </p>
    <div class="row">
        <mat-form-field appearance="fill" class="col-7" *ngIf="this.datos.type == 'analista'">
            <mat-label>Analistas de calidad</mat-label>
            <mat-select multiple [(ngModel)]="seleccion" [disabled]="input">
                <mat-option *ngFor="let analista of listAnalistas" [value]="analista">{{analista.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-7" *ngIf="this.datos.type == 'super'">
            <mat-label>Supervisor</mat-label>
            <mat-select multiple [(ngModel)]="seleccion" [disabled]="input">
                <mat-option *ngFor="let supervisor of listSupervisores" [value]="supervisor">{{supervisor.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-5">
            <mat-label>Cédula</mat-label>
            <input type="number" matInput cdkFocusInitial [disabled]="input" (keydown.enter)="filtrar()"
            [(ngModel)]="filtroCedula">
            <mat-icon matSuffix style="color: #717171;" (click)="filtrar()"*ngIf="!input"><i class="fi-rr-search"></i></mat-icon>
            <mat-icon matSuffix style="color: #717171;" *ngIf="input"><i class="fi-rr-search"></i></mat-icon>
        </mat-form-field>
    </div>
    <div class="mt-3 mb-4">
        <mat-chip-list *ngIf="datos.type == 'analista'">
            <mat-chip *ngFor="let analista of analistas" [selectable]="selectable"
            [removable]="removable" (removed)="removeAnalista(analista)">
                {{analista.nombre}}
                <button matChipRemove *ngIf="removable">
                    <mat-icon>cancel</mat-icon>
                  </button>
            </mat-chip>
        </mat-chip-list>
        <mat-chip-list *ngIf="datos.type == 'super'">
            <mat-chip *ngFor="let supervisor of supervisores" [selectable]="selectable"
            [removable]="removable" (removed)="removesupervisor(supervisor)">
                {{supervisor.nombre}}
                <button matChipRemove *ngIf="removable">
                    <mat-icon>cancel</mat-icon>
                  </button>
            </mat-chip>
        </mat-chip-list>
    </div>

    <div class="btns-dialog" *ngIf="btns">
        <button mat-stroked-button (click)="edit() || showAsignados()">Cancelar</button>
        <button mat-raised-button color="primary" (click)="asignarAnalistas()">Asignar</button>
    </div>
</div>