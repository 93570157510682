<div class="alert" *ngIf="alert === true && historial === false">
    <i (click)="closeAlert()" class="fi-rr-cross-circle"></i>
    <img style="width: 88px; height: 88px; margin: 0px 10px 0px 10px" src="../../../assets/images/Icono-campana.png" (click)="historial = true">

    <div style="flex-direction: column" (click)="redirigir()">
        <b>¡Tienes una nueva notificación!</b>
        <br>{{mensaje}}
        <!-- <span>Tienes un mensaje nuevo de <br> </span> -->
    </div>
</div>

<div class="historial" *ngIf="historial">
    <span style="text-align: right; padding: 18px 17px 0px 0px; cursor: pointer;" class="fi-rr-cross-circle" (click)="cerrar()"></span>
    <div>
        <p class="title ps-3">¡Notificaciones SOUL!</p>
        <div *ngFor="let notificacion of notificaciones; let i = index">
            <div class="div-notificacion" [ngClass]="{'div-notificacion-leida': notificacion.read == 1}">
                <div fxLayout="row">
                    <h4 fxFlex="95%">¡Tienes una notificación de <br> {{notificacion.nombre}}!</h4>
                    <div fxFlex="5%" fxFlexAlign="end none">
                        <span style="cursor: pointer; font-size: 7px;" class="fi-rr-cross" (click)="deleteNotification(notificacion.id)"></span>
                    </div>
                </div>
                <div (click)="irPagina(notificacion.aplicacion,notificacion.id, i)" id="content" class="pe-3">
                    <p >{{notificacion.mensaje}}</p>
                    <div class="tiempo">
                        <span style="font-size: 10px;" class="pe-2">{{notificacion.created_at | date:'dd/MM/yyyy hh:mm'}}</span>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>
    </div>
</div>
