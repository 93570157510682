import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import { AuditoriaService } from 'src/app/modules/calidad/services/auditoria.service';
import { PusherService } from 'src/app/modules/crm/services/pusher.service';
import { DetalleRetroalimentacionComponent } from '../retroalimentar/components/detalle-retroalimentacion/detalle-retroalimentacion.component';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
  selector: 'app-tabla-auditado',
  templateUrl: './tabla-auditado.component.html',
  styleUrls: ['./tabla-auditado.component.sass']
})
export class TablaAuditadoComponent implements OnInit {
  private uuid = '5ede9452-9204-11ee-b9d1-0242ac120002';
  public config: any;

  dataSource = [];
  displayedColumns:string[] = ['ac','id','campania','fecha_auditoria','nombre_matriz','auditor','calificacion','gestion'];

  filtroFecha = new FormGroup({
    fechaInicial: new FormControl(),
    fechaFinal: new FormControl(),
  });

  filtroGestion;

  length;
  pageSize = 5;
  page = 1;
  pageSizeOptions = [5,10,25,50,100];
  @ViewChild(MatPaginator) paginator:MatPaginator;
  campania:string = '';

  constructor(
    private dialog:MatDialog,
    private auditoriaService:AuditoriaService,
    private pusher:PusherService,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
    this.getAuditoriasByAsesor(this.page,this.pageSize);
    this.pusher.getNotificationsGlobal().bind('notification',()=>{
      this.getAuditoriasByAsesor(this.page,this.pageSize);
    });
  }

  getAuditoriasByAsesor(page,pageSize){
    const params = {
      fechaInicial: '',
      fechaFinal: '',
      retroalimentacion: '',
      numeroReg: pageSize,
      page: page,
    };
    if (this.filtroFecha.get('fechaInicial').value != null) {
      params.fechaInicial = moment(this.filtroFecha.get('fechaInicial').value).format('yyyy-MM-DD');
    }
    if (this.filtroFecha.get('fechaFinal').value != null) {
      params.fechaFinal = moment(this.filtroFecha.get('fechaFinal').value).format('yyyy-MM-DD');
    }

    if (this.filtroGestion != '') {
      params.retroalimentacion = this.filtroGestion;
    }

    this.auditoriaService.getAuditoriasByAsesor(params).subscribe((resp:any)=>{
      this.dataSource = resp.data;
      this.length = resp.total;
    });
  }

  pageEvent(event:any){
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getAuditoriasByAsesor(this.page,this.pageSize);
  }

  detailRetroAlimentacion(id){
    const retroalimentacionRef = this.dialog.open(DetalleRetroalimentacionComponent,{
      width:'1056px',
      disableClose:true,
      data: id,
    });
    retroalimentacionRef.afterClosed().subscribe(()=>{

    });
  }

  filtroFechas(){
    if (this.filtroFecha.get('fechaInicial').value != null && this.filtroFecha.get('fechaFinal').value != null) {
      this.filtro();
    }
  }

  filtro(){
    this.paginator.firstPage();
    this.getAuditoriasByAsesor(this.page,this.pageSize);
  }

  get campaign(){
    let campaignMatriz;
    this.dataSource.forEach(element => {
      campaignMatriz = element.campaing;
      if (campaignMatriz.length == 0) {
        return false;
      }else{
        return true;
      }
    });
    this.campania = campaignMatriz.name;
    return true;
  }

}
