<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [style.width.px]="modalWidth" [style.height.px]="modalHeight">
    <div style="margin-bottom: 10px; width: 100%; display: flex;">
        <div style="width: 75%;">
            <button mat-raised-button *ngIf="minView == false" color="primary" class="myButton2 col-5" style="width: 200px;" (click)="fileInput.click()"> 
                Adjuntar documento
                <span class="fi-rr-clip" style="color: #51B0CB"></span>
            </button>
            <input hidden (change)="onChangeFile($event)" name="file" #fileInput type="file" id="file">
            <button mat-raised-button class="myButton2" style="margin-left: 20px; color: #51B0CB;" *ngIf="minView == false" (click)="seeDocuments()">
                Ver documentos <mat-icon>remove_red_eye</mat-icon> 
            </button>
        </div>
        <div style="width: 25%;">
            <button mat-icon-button (click)="minimize()" >
                <b *ngIf="minView == false">Minimizar ventana</b>
                <mat-icon *ngIf="minView == false">fullscreen_exit</mat-icon>
                <mat-icon *ngIf="minView == true">fullscreen_exit</mat-icon>
            </button>
        </div>
    </div>

    <div class="row" style="width: 100%;" [style.height.px]="frameHeight">
        <div [style.width.%]="docsWidth" [hidden]="viewFiles">
            <mat-card style="overflow-y: scroll; overflow-x: hidden; height: 400px;">
                <mat-card-title>
                    
                    <button mat-icon-button style="float: right;" (click)="hideFiles()">
                        <span class="fi-rr-cross"></span>
                    </button>
                    <button mat-icon-button style="float: right;" (click)="getFiles()">
                        <span class="fi-rr-refresh"></span>
                    </button>
                </mat-card-title>
                <mat-card-content>
                    <p><b>Documentos adjuntos</b></p>                    
                    <div style="margin-top: 20px;">
                        <p style="color: #2CABBC; margin-top: 5px; cursor: pointer; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" *ngFor="let item of files">
                           <span class="fi-rr-download" (click)="file.click()">
                            <a #file [href]="item?.fullPath" target="_blank"></a> {{item?.name}}
                           </span>
                        </p>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div [style.width.%]="frameWidth" >
            <div id="jitsi-iframe" [style.height.px]="frameHeight"></div>
            <div class="item-center"> 
                <i (click)="executeCommand('toggleAudio')" class="fas fa-2x grey-color" [ngClass]="isAudioMuted ? 'fa-microphone-slash' : 'fa-microphone'" aria-hidden="true" title="Mute / Unmute"></i>
                <i (click)="executeCommand('hangup')" class="fas fa-phone-slash fa-2x red-color" aria-hidden="true" title="Leave"></i>
                <i (click)="executeCommand('toggleVideo')" class="fas fa-2x grey-color" [ngClass]="isVideoMuted ? 'fa-video-slash' : 'fa-video'" aria-hidden="true" title="Start / Stop camera"></i>
                <i (click)="executeCommand('toggleShareScreen')" class="fas fa-film fa-2x grey-color" aria-hidden="true" title="Share your screen"></i>
            </div>
        </div>
    </div>

</div>
