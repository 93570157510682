import { Component, Input, OnInit } from '@angular/core';
import { onSideNavChange, animateText } from '../animation';
import { SidenavService } from '../../core/services/utils/sidenav.service';
import { LeftNavService } from '../../core/services/rest/left-nav.service';
import { Router } from '@angular/router';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../core/services/rest/auth.service';
import { VicidialService } from '../../shared/vicidial-voz/services/vicidial.service';
import { socketService } from 'src/app/modules/livechat/services/socket/index.service';
import { SocketActiveService } from 'src/app/modules/livechat/services/socket/socket.service';
import Swal from 'sweetalert2';
import { SideBarComponent } from '../side-bar/side-bar.component';
import { HttpService } from 'src/app/modules/livechat/services/http/index.service';
import { sessionService } from 'src/app/modules/livechat/services/session/index.service';
import { HttpClient } from '@angular/common/http';


interface AppItem {
  id: number;
  name: string;
  link: string;
  icon: string;
}

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.sass'],
  animations: [onSideNavChange, animateText],

})
export class LeftNavComponent implements OnInit {
  public sideNavState: boolean;
  public linkText: boolean;
  public pages: Array<AppItem> = [];
  public socket: socketService;
  public activateMenu: any = false;

  @Input() scheduleEnd: any;

  /**
   * Variable que permite bloquear cualquier accion de pausado si el agente esta en alguna llamada de vicidial (Integracion con voz)
   */
  public pauseToTypify: boolean = false;


  constructor(private _sidenavService: SidenavService,
    private leftNavService: LeftNavService,
    public router: Router,
    private destroyService: DestroyComponentService,
    public dialog: MatDialog,
    private authService: AuthService,
    private vicidialService: VicidialService,
    private _socketActiveService: SocketActiveService,
    private _httpService: HttpService,
    private _storageService: sessionService,
    private sideBar: SideBarComponent,
    private httpClient: HttpClient) {
    this._sidenavService.sideNavState$.subscribe(res => {

      this.sideNavState = res;
      this.linkText = res;
    });

  }

  ngOnInit(): void {

    const app = this.router.url.substring(6).split('/', 1)[0];

    if (app === null || app === '') {
      this.consultMenu('ciu');
    } else {
      this.consultMenu(app);
    }

    /**
     * Servicio que se subscribe a un observable que retorna el estado del agente segun vicidial service
     */
    this.vicidialService.pauseActiveObservable.subscribe(value => {
      this.pauseToTypify = value;
    });


    //this.checkEndedSchedule();
    // pasada las horas adicionales para el cierre de turno.
    const schedule = this.authService.decryptToken();
    let hour = new Date(schedule.schedule_extra.scheduleEndPlus);

    setInterval(() => {
      var currentDate = new Date();
      var dateMindNight = new Date();
      dateMindNight.setHours(23, 59, 0);

      if (currentDate.getHours() === dateMindNight.getHours() && currentDate.getMinutes() === dateMindNight.getMinutes()) {
        this.authService.logOut().subscribe(resp => {
          if (resp) {
            window.location.replace('/login');
            localStorage.clear();
          }
        }, error => {
          window.location.replace('/login');
          localStorage.clear();
        });
      }
    }, 19000);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-01
   * Metodo que consulta del local storage los aplicativos y los muestra en el menu
   */
  consultMenu(menu: any): void {

    this.activateMenu = false;
    this.pages = [];

    this.leftNavService.getLeftMenu(menu).subscribe((resp) => {

      resp.data.forEach(element => {
        const item: AppItem = {
          id: element.id,
          name: element.name,
          link: element.url,
          icon: element.icon
        };
        this.pages.push(item);
      });

      if (menu.includes('cartera')) {
        const userSystem = this.authService.decryptToken()
        const isIndex = this.pages.findIndex(page => page.link.includes(`${menu}/administracion`) === true)
        if (isIndex !== -1) {
          import(`../../modules/cartera/modules/gestiones/services/gestion.service`).then(module => {
            const serviceDinamic = module.GestionService
            const service = new serviceDinamic(this.httpClient, this.router)
            service.getisAdmin().subscribe(users => {
              const isUser = users.find(user => user.user_id === userSystem.rrhh_id)
              if (isUser) this.pages.splice(isIndex, 1)
            })
          }).catch(error => console.error('No se encontro la ruta para consultar el servicio'))
        }
      }
    }
    );

    this.sideBar.getSchedule();

    if (menu === 'crm' || menu === 'omni') {
      this.activateMenu = true;
    }

  }

  /**
   * @author Olme Marin
   * @createdate 2021-09-13
   * Método para obtener el socket activo de omnicanlidad
  */
  getSocketActive() {
    return this.socket = this._socketActiveService.getSocketActive();
  }

  /**
   * @author Olme Marin
   * @createdate 2021-09-13
   * Método para desconectar el socket de un agente de omnicanlidad
  */
  async disconnectSocket() {
    const rrhh: any = this.authService.getUser();
    let project: any = this._storageService.getItem('project');
    try {
      project = JSON.parse(project);
      console.log('project-cerrar', project.id);
      await this._httpService.post(`mixins/logout-agent`, {
        rrhh: rrhh.rrhh_id,
        project: project.id
      }).toPromise()
      this.socket.sendMessage('agentDisconnect', { rrhh: rrhh.rrhh_id })
      this.socket.disconnect();
    } catch (e) {
      console.log("Arror api logout livechat")
    }
    return null;
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

  /**
   * Método que que notifica a la persona 10min antes del cierre de turno
   * @author Andres Buitrago
   */
  checkEndedSchedule(): void {
    setInterval(() => {
      if (this.scheduleEnd) {
        const now = moment();
        const schedDate = moment(this.scheduleEnd);
        if (Math.abs(now.diff(schedDate, 'minutes')) === 10) {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 15000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: 'warning',
            title: 'En 10 minutos cerrará su turno, por favor tenerlo en cuenta para generar la marcación y desloguearse'
          });
        }
      }
    }, 50000);
  }

  changeActivateMenu() {
    const app = this.router.url.substring(6).split('/', 1)[0];
    if (app != 'crm' && app != 'omni') return;
    this.activateMenu = !this.activateMenu
  }

}
