<div class="row novelty-row-padding container-fluid">

  <h2 mat-dialog-title>
    <div class="title row" style="width: 100%;">
      <div class="d-flex col-8">
        <img src="./assets/images/novelties.png" alt="noveltyIcon" class="iconSize m-3">
        <span class="title m-3">
          <h2>{{data.name}}</h2>
          <h5 style="font-size: 15px; font-family: 'Poppins', sans-serif!important;">{{data.id_number}}</h5>
        </span>
      </div>
      <div class="col-3 d-flex align-items-end justify-content-center">
        <p style="color: #BD77CE"><strong>Estado: {{data.stateName}} </strong></p>
      </div>
      <div class="col">
        <span class="gray-color" style="margin-left: -9px"></span>
        <button style="float: right; margin-top: -5px" mat-icon-button mat-dialog-close>
          <i class="fi-rr-cross-small"></i>
        </button>
      </div>
    </div>
  </h2>

  <hr class="m-3" />

  <mat-dialog-content style="padding: 0px 15% 10px;; overflow-x: hidden" class="mat-typography">

    <div class="row">
      <div style="padding-top: 10px;">
        <div class="col-12">
          <br>
          <h4><b>Vacaciones solicitadas</b></h4>
          <p style="color: #51B0CB"> <strong>Desde: </strong> {{ data?.fechaSolicitud | date:'dd/MM/yyyy' }}
            <strong>Hasta: </strong> {{data?.fechaFinSolicitud | date:'dd/MM/yyyy' }}</p>
        </div>
        <div class="col-12">
          <p class="p-color"> <strong>Días disponibles: </strong> {{ availableDays }} <strong>Días hábiles solicitados:
            </strong> {{ businessDays }}</p>
        </div>
        <div class="col-12">
          <br>
          <p class="p-color"><b>Observaciones trabajador</b></p>
          <p class="p-color"> {{ dataInfo?.first_response.justify }}</p>
        </div>
        <div class="col-12">
          <br>
          <h4><b>Aprobacion jefe de área</b></h4>
          <p class="p-color"> <strong>Fecha de aprobacion: </strong> {{ dataInfo?.first_response.updated_at | date:'dd/MM/yyyy'  }}</p>
          <p class="p-color"><b>Observaciones jefe de área</b></p>
          <p class="p-color"> {{ dataInfo?.first_response.response }}</p>
        </div>
        <div class="col-12">
          <br>
          <h4><b>Respuesta Nómina</b></h4>
          <p class="p-color"> <strong>Fecha de aprobacion: </strong> {{ dataInfo?.second_response.updated_at | date:'dd/MM/yyyy'  }} </p>
          <p class="p-color"><b>Observaciones área jurídica</b></p>
          <p class="p-color"> {{ dataInfo?.second_response?.response }}
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="data.stateName == 'Interrumpidas'">
      <hr />
      <div style="padding-top: 5px;">
        <div class="col-12">
          <br>
          <h4><b>Interrumpir vacaciones</b></h4>
          <p style="color: #51B0CB"> <strong>Fecha de interrupción: </strong> {{ data.novelty_cancel_vacation[0].date_start  |
            date:'dd/MM/yyyy' }} <strong>Días tomados: </strong> {{data.novelty_cancel_vacation[0].days_taken}} <strong>Días no tomados: </strong> {{data.novelty_cancel_vacation[0].days_not_taken}}</p>
          <p class="p-color" *ngIf="dataInfo?.third_response.response == null"> Pendiente por aprobacion </p>
          <div *ngIf="dataInfo?.third_response.response != null">
            <p class="p-color"><b>Observaciones área jurídica</b></p>
            <p class="p-color">{{ dataInfo?.third_response?.response }} </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="data.stateName == 'Aprobadas' || data.stateName == 'En vacacaciones'">
      <hr />
      <form [formGroup]="vacationForm" (ngSubmit)="cancelVacation()">
        <div class="col-12" style="padding-top: 5px;">
          <h4><b>Interrumpir vacaciones</b></h4>
          <div class="row mx-auto">
            <mat-form-field appearance="fill" class="w100">
              <mat-label>Fecha inicio de interrupcion</mat-label>
              <input matInput [matDatepicker]="picker" [min]="minDay" [max]="maxDay" placeholder="Fecha novedad"
                autocomplete="off" formControlName="startDay">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="vacationForm.controls.startDay.hasError('required')"> ¡Advertencia! Este campo es
                requerido
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="col-12" *ngIf="showTotalDays">
          <div class="d-flex justify-content-center">
            <div>
              <p style="color: #BD77CE">Deseas cancelar <b>{{cancelTotalDays}}</b> dias de vacaciones de {{totalDays}}
                dias</p>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="d-flex justify-content-center">
            <mat-form-field appearance="fill" style="width: 95%;">
              <mat-label>Justificación</mat-label>
              <textarea class="w100" matInput formControlName="justification" id="justificationId" cols="3"
                rows="2 "></textarea>
              <mat-error *ngIf="vacationForm.controls.justification.hasError('required')"> ¡Advertencia! Este campo es
                requerido
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="col-12">
          <div class="d-flex justify-content-center">
            <button mat-raised-button color="primary" [disabled]="vacationForm.invalid">
              Crear novedad
            </button>
          </div>
        </div>

      </form>
    </div>
  </mat-dialog-content>
</div>