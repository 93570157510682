import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

const PQRS_URL = environment.PQRS_URL;
@Injectable({
  providedIn: 'root'
})
export class GestionadorCorreosService {
  constructor(private http: HttpClient) { }

  /**
    * @author Fabian Duran
    * @createdate 2022-11-02
    * Metodo que retorna los posibles errores generados a traves de las llamadas HTTP. 
    * @param error Instancia de la clase HttpErrorResponse. 
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
    * @author Fabian Duran
    * @createdate 2022-11-02
    * Metodo que retorna la lista de correos de salida
  */
  getEmailsByExit(): Observable<any> {
    return this.http.get<any>(`${PQRS_URL}emails/external`).pipe(
      catchError(this.handleError)
    )
  }

  /**
    * @author Fabian Duran
    * @createdate 2022-11-02
    * Metodo que valida cada uno de los correos registrados en los chip list. 
    * @param data Informacion generada desde el formulario para el envio de correos. 
  */
  sendEmail(data: any): Observable<any> {
    return this.http.post<any>(`${PQRS_URL}emails/send`, data).pipe(
      catchError(this.handleError)
    )
  }
}