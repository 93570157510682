import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const calidad_url = environment.CALIDAD_URL;

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private http:HttpClient,
  ) { }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  getReportGeneral(params){
    const item = localStorage.getItem('locale');
    params.lang = item ? JSON.parse(item) : 'es';
    return this.http.get(`${calidad_url}descargaReporteGeneral`,{params,responseType:'blob'})
    .pipe(
      catchError(this.handleError)
    );
  }

}
