import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { VicidialService } from '../vicidial-voz/services/vicidial.service';
import { ActivatedRoute } from '@angular/router';
import { interval } from 'rxjs';
import { delay } from 'rxjs/operators';
import { DestroyComponentService } from '../../core/services/utils/destroy-component.service';

@Component({
  selector: 'app-vicidial-dashboard',
  templateUrl: './vicidial-dashboard.component.html',
  styleUrls: ['./vicidial-dashboard.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class VicidialDashboardComponent implements OnInit {

  data;
  formId;
  campaignId;
  subscribeInterval;

  constructor(private location: Location,
              public vicidialService: VicidialService,
              private activatedRoute: ActivatedRoute,
              private destroyService: DestroyComponentService) {
                this.formId = this.activatedRoute.snapshot.paramMap.get('formId');
                this.campaignId = this.activatedRoute.snapshot.paramMap.get('campaignId');
              }

  ngOnInit(): void {
    this.getRealTimereport();
  }

  getRealTimereport(){
    const params = {
      formsId: this.formId,
      campaignId: this.campaignId
    };

    this.vicidialService.getRealTimeReports(params).subscribe( (resp: any) => {
      this.data = resp.data;
      this.data = this.data.replaceAll('images/', 'assets/images/');
    });

    const source = interval(10000);
    this.subscribeInterval = source.subscribe(val => {
      this.vicidialService.getRealTimeReports(params).subscribe( (resp: any) => {
        this.data = resp.data;
        this.data = this.data.replaceAll('images/', 'assets/images/');
      });
    });
  }

  backRoute(){
    this.subscribeInterval.unsubscribe();
    this.location.back();
  }

  OnDestroy(){
    this.subscribeInterval.unsubscribe();
    this.destroyService.destroyComponent();
  }

}
