import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { InteligenciaService } from 'src/app/modules/auditoria/services/inteligencia.service';
import Swal from 'sweetalert2';
import { CrearFormularioComponent } from '../crear-formulario/crear-formulario.component';

@Component({
  selector: 'app-tabla-inteligencia-negocio',
  templateUrl: './tabla-inteligencia-negocio.component.html',
  styleUrls: ['./tabla-inteligencia-negocio.component.sass']
})
export class TablaInteligenciaNegocioComponent implements OnInit {

  //Control de paginación de la tabla
  length;
  pageSize = 5;
  page = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  //Columnas e información de la tabla
  displayedColumns:string[] = ['acciones','fecha_creacion','nombre_inteligencia_negocio','num_preguntas','descripcion'];
  dataSource =new MatTableDataSource();
  //Filtros
  filtroFormulario:string = '';
  filtroFecha:string = '';
  constructor(
    private formDialog:MatDialog,
    private router:Router,
    private inteligenciaS:InteligenciaService
  ) { }

  ngOnInit(): void {
    this.getForms(this.page,this.pageSize);
  }

  /**
  * @author Cristian Gallo
  * Función desplegar el dialog nuevo formulario
  */ 
  newForm(){
    const formRef = this.formDialog.open(CrearFormularioComponent,{
      width: '840px',
      disableClose: true,
    });
  }

  /**
  * @author Cristian Gallo
  * Función para eliminar formulario
  */ 
  removeForm(id_form){
    Swal.fire({
      icon:'warning',
      title: '¿Esta seguro de eliminar este formulario?',
      text: '¿Deseas eliminar permanentemente el formulario? Recuerda que esta acción será definitiva y no se podrá deshacer.',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true,
      width: '810px'
    }).then((result)=>{
      if (result.isConfirmed) {
        this.inteligenciaS.removeForm(id_form).subscribe((resp:any)=>{
          if (resp.respuesta.codigo === 202) {
            Swal.fire({
              icon:'success',
              title: '¡Excelente!',
              text: 'El formulario se ha eliminado con éxito',
              showConfirmButton:false,
              timer: 2500
            });
            this.getForms(this.page,this.pageSize);
          }else {
            Swal.fire({
              icon: 'error',
              title: '¡Oops!',
              text: 'Lo sentimos, no es posible eliminar el formulario, esta siendo Utilizada por una matriz.',
              showConfirmButton: false,
              timer: 2500
            })
          }
        });
      }
    });
  }
  /**
  * @author Cristian Gallo
  * Función la paginación de la tabla
  */ 
  pageEvent(event:any){
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getForms(this.page,this.pageSize)
  }

  /**
  * @author Cristian Gallo
  * Función para ir a la ruta detalle o editar formulario
  */ 
  detailForm(type,id){
    this.router.navigateByUrl(`/mios/calidad/formulario/${type}/${id}`);
  }

  /**
  * @author Cristian Gallo
  * Función para obtener todos los formularios creados
  */ 
  getForms(page:number,pageSize:number){
    const params = {
      page: page,
      numeroReg: pageSize,
      formulario: '',
      fecha: '',
    }

    //Filtros
    if (this.filtroFormulario != "") {
      params.formulario = this.filtroFormulario
    }

    if (this.filtroFecha != "") {
      if ( this.filtroFecha == null) {
        params.fecha = ''
      }else{
        params.fecha = moment(this.filtroFecha).format('YYYY-MM-DD')
      }
    }

    this.inteligenciaS.getForms(params).subscribe((resp:any)=>{
      this.dataSource.data = resp.data
      this.length = resp.total
      this.pageSize = resp.per_page;
    });
  }

  filtrar(){
    this.getForms(this.page,this.pageSize);
  }

}
