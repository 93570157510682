import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestsService } from './../../../services/requests.service';
import { ModalDeleteComponent } from './../modal-delete/modal-delete.component';

import { ModalApproveComponent } from './../modal-approve/modal-approve.component';
import { ModalAssignRecruiterComponent } from './../modal-assign-recruiter/modal-assign-recruiter.component';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { async } from '@angular/core/testing';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-modal-validate',
  templateUrl: './modal-validate.component.html',
  styleUrls: ['./modal-validate.component.sass']
})
export class ModalValidateComponent implements OnInit {



  displayedColumns: string[] = ['name', 'id_number', 'email', 'actions'];

  dataSource: MatTableDataSource<any>;
  responsive = true;
  cols = 1;
  user;
  canEdit= false
  editForm= false
  formSection =[
    {
      name_section:null,
      fields: null,
      type_section: 1,
      collapse: false,
      duplicar: false,
    },
  ]
  constructor(@Inject(MAT_DIALOG_DATA) public datos: any,
              public dialogRef: MatDialogRef<ModalValidateComponent>,
              private serviceBpms: RequestsService,
              public dialog: MatDialog,
              public alertService: AlertsService,
              private authService: AuthService) {
                this.user = this.authService.getUser();

   }
   request: any;

  ngOnInit(): void {
    this.serviceBpms.consultarSeguimientoSolicitud(this.datos.item.id).subscribe(response => {
      this.request = response;
      if(this.request.request_state_id != 2 && this.request.request_type_id == 2){
        this.canEdit= true
      }
      this.dataSource = new MatTableDataSource(response.candidates);
    });
  }

  close(): void {
    this.dialogRef.close();
  }
  deleteRequest(): void {
    const item = this.datos.item;
    item.idEstadoSolicitud = 2;

    const deleteDialog =  this.dialog.open(ModalDeleteComponent, {
      width: '60%',
      data: {item},
      disableClose: true,
    });

    deleteDialog.afterClosed().subscribe((deleted) => {
      this.dialogRef.close(deleted);
    });
  }

  editRequest(): void {
    this.canEdit= false
    this.editForm = true
    this.formSection =[
      {
        name_section:null,
        fields: null,
        type_section: 1,
        collapse: false,
        duplicar: false,
      },
    ]
    this.serviceBpms.consultarSolicitudEditar(this.datos.item.id).subscribe((respuesta: any) => {
      this.formSection[0].name_section = `Editar Solicitud`;
      let formulario = JSON.parse(respuesta.form)
      for ( let i = 0; i < formulario.length; i++) {
        if(formulario[i].key == 'funciones'){
           formulario.splice(i,1);
         }
      }
      this.formSection[0].fields = formulario
    })
  }
  editarSolicitud(res) {
    let formulario = res.answer[0]
    const salary = formulario.asignacionSalarial;
    formulario.asignacionSalarial = salary.replaceAll(',', '');

    const solicitud = {
      infoSolicitud: JSON.stringify(formulario),
    };

    this.serviceBpms.editarSolicitud(this.datos.item.id, solicitud).subscribe((respuesta: any) => {
      this.alertService.alertSuccess('Actualizado', 'Su solicitud se ha actualizado correctamente');
      this.editForm= false
      this.ngOnInit()
    });

  }
  closeEdit(refreshRequests) {
   if(refreshRequests == true){
    this.editForm= false
    if(this.request.request_state_id != 2 && this.request.request_type_id == 2){
      this.canEdit= true
    }
   }
  }

  approveRequest(): void {
    // Administrador

     if (this.request.request_type_id === 1) {
      this.alertService.alertConfirm('¿Está seguro que desea generar esta solicitud?').then((result) => {
        if (result.isConfirmed) {
          // se asignada automaticamente al administrador
        const params = {
          userAssignedId: this.user.rrhh_id
      };
        this.serviceBpms.appovalRequest(this.request.id, params).subscribe(  () => {
          this.alertService.alertSuccess('Aprobada', 'Su solicitud se ha aprobado correctamente');
          this.dialogRef.close(true);
      });
    }});
      } else {

        this.alertService.alertConfirm('¿ESta seguro que desea aprobar esta solicitud?').then(e => {
              if(e.isConfirmed){
                const params = {
                  userAssignedId: this.user.rrhh_id
              };
                this.serviceBpms.appovalRequest(this.request.id, params).subscribe(  () => {
                  this.alertService.alertSuccess('Aprobada', 'Solicitud aprobada');
                  this.dialogRef.close(true);
              });
              }
              if(e.isDenied){
                this.dialogRef.close(true);
              }
        });
      }
  }

  assignRecruiter(): void {
    const assignRecruiterDialog = this.dialog.open(ModalAssignRecruiterComponent, {
      data: {id: this.datos.item.id},
      width: '80%',
      disableClose: true
    });

    assignRecruiterDialog.afterClosed().subscribe(() => {
      this.dialogRef.close(true);
    });
  }


  setCommentary( key,candidateId,commenary  ){


    this.serviceBpms.setCommenty({
      'requestId':this.request.id,
      'commentType':key,
      'candidateId':candidateId,
      'comment':commenary}).subscribe((respuesta: any) => {
      this.alertService.alertSuccess('Actualizado',
                                     'Su solicitud se ha actualizado correctamente');
                                     this.ngOnInit();
    });

  }
}
