import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComprasComponent } from './compras.component';
import { ComprasRoutingModule } from './compras-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { MaterialModule } from '../../shared/material/material.module';
import { MatTooltipModule}  from '@angular/material/tooltip';
import { SolicitudesCardsComponent } from './modules/solicitudes/solicitudes-cards/solicitudes-cards.component';
import { SolicitudesListComponent } from './modules/solicitudes/solicitudes/solicitudes-list.component';
import { CrearSolicitudComponent } from './modules/solicitudes/componentes/crear-solicitud/crear-solicitud.component';
import { GestionInicialComponent } from './modules/solicitudes/componentes/gestion-inicial/gestion-inicial.component';
import { GestionAprobacionComponent } from './modules/solicitudes/componentes/gestion-aprobacion/gestion-aprobacion.component';
import { OrdenCompraComponent } from './modules/solicitudes/componentes/orden-compra/orden-compra.component';
import { SolicitudesAprobarComponent } from './modules/solicitudes/solicitudes-aprobar/solicitudes-aprobar.component';
import { GestionAprobadorComponent } from './modules/solicitudes/componentes/gestion-aprobador/gestion-aprobador.component';
import { AprobarSolcitudComponent } from './modules/solicitudes/componentes/aprobar-solcitud/aprobar-solcitud.component';
import { HistorialAprobadorComponent } from './modules/solicitudes/componentes/historial-aprobador/historial-aprobador.component';
import { ProcesarSolicitudComponent } from './modules/solicitudes/componentes/procesar-solicitud/procesar-solicitud.component';
import { OrdenCompraListComponent } from './modules/solicitudes/componentes/orden-compra-list/orden-compra-list.component';
import { ConsultarProveedorComponent } from './modules/solicitudes/componentes/consultar-proveedor/consultar-proveedor.component';
import { OrdenCompraFragComponent } from './modules/solicitudes/componentes/orden-compra-frag/orden-compra-frag.component';
import { OrdenCompraEditComponent } from './modules/solicitudes/componentes/orden-compra-edit/orden-compra-edit.component';
import { DescargaComponent } from './modules/solicitudes/componentes/historial-aprobador/descarga/descarga.component';
import { SolicitudesControlComponent } from './modules/solicitudes/solicitudes-control/solicitudes-control.component';
import { GestionInicialCardsComponent } from './modules/solicitudes/componentes/gestion-inicial-cards/gestion-inicial-cards.component';
import { GestionInicialControlComponent } from './modules/solicitudes/componentes/gestion-inicial-control/gestion-inicial-control.component';
import { ProcesarSolicitudControlComponent } from './modules/solicitudes/componentes/procesar-solicitud-control/procesar-solicitud-control.component';
import { OrdenCompraListControlComponent } from './modules/solicitudes/componentes/orden-compra-list-control/orden-compra-list-control.component';
import { RemisionControlComponent } from './modules/solicitudes/componentes/remision-control/remision-control.component';
import { MatInputModule } from '@angular/material/input';
import { NgxCurrencyModule } from 'ngx-currency';
import { BlockRequestsComponent } from './modules/solicitudes/componentes/block-requests/block-requests.component';
import { AssociatedRequestComponent } from './modules/solicitudes/componentes/associated-request/associated-request.component';
import { CreateObservationItemComponent } from './modules/solicitudes/componentes/create-observation-item/create-observation-item.component';
import { ViewObservationItemComponent } from './modules/solicitudes/componentes/view-observation-item/view-observation-item.component';



@NgModule({
  declarations: [
    ComprasComponent,
    SolicitudesCardsComponent,
    SolicitudesListComponent,

    CrearSolicitudComponent,

    GestionInicialComponent,
    GestionAprobacionComponent,
    OrdenCompraComponent,
    SolicitudesAprobarComponent,
    GestionAprobadorComponent,
    AprobarSolcitudComponent,
    HistorialAprobadorComponent,
    ProcesarSolicitudComponent,
    OrdenCompraListComponent,
    OrdenCompraComponent,
    ConsultarProveedorComponent,
    OrdenCompraFragComponent,
    OrdenCompraEditComponent,
    DescargaComponent,
    SolicitudesControlComponent,
    GestionInicialCardsComponent,
    GestionInicialControlComponent,
    ProcesarSolicitudControlComponent,
    OrdenCompraListControlComponent,
    RemisionControlComponent,
    BlockRequestsComponent,
    AssociatedRequestComponent,
    CreateObservationItemComponent,
    ViewObservationItemComponent,

  ],

  imports: [
    CommonModule,
    ComprasRoutingModule,
    MaterialModule,
    SharedModule,
    MatTooltipModule,
    MatInputModule,
    NgxCurrencyModule
  ]
})
export class ComprasModule { }
