import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NetworkUsersService } from 'src/app/modules/ciu/services/rest/network-users.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-update-network-user-form',
  templateUrl: './update-network-user-form.component.html',
  styleUrls: ['./update-network-user-form.component.sass']
})
export class UpdateNetworkUserFormComponent implements OnInit {
  form: FormGroup

  constructor(private formBuilder: FormBuilder, private networkUserService:NetworkUsersService, private dialog:MatDialog, private alertService:AlertsService,  @Inject(MAT_DIALOG_DATA) public dialog_data: any) { }

  ngOnInit(): void {
    this.initializeForm()
  }

  /**
   * Metódo que inicializa el formulario
   * @param event
   * @author Juan Carlos Alonso
   * @createdate 19-09-2024
   */
  initializeForm() {
    this.form = this.formBuilder.group({
      network_user: [this.dialog_data.network_user ?? '', [Validators.required]],
    })
  }

  /**
* Metódo que envía la información para actualizar el usuario de red
* @param event
* @author Juan Carlos Alonso
* @createdate 15-05-2024
*/
  saveData() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return
    }
    this.networkUserService.updateNetworkUser(this.dialog_data.id,this.form.value).subscribe((resp) => {
      this.dialog.getDialogById('dialogUpdateNetworkUser').close({ created: true })
    }, (error) => {
      if (error.error.error) {
        this.alertService.alertError('!Atención¡', error.error.error)
      } else {
        this.alertService.alertError('!Atención¡', 'Ha ocurrido un error intentar actualizar el usuario de red, por favor contacta a soporte')
      }
    })

  }
}
