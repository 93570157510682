import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../core/services/rest/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';

const NOMINA_URL = environment.NOMINA_URL;
const RRHH_URL = environment.RRHH_URL;


@Injectable({
  providedIn: 'root'
})
export class NoveltiesService {

  constructor(private http: HttpClient) {
  }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Servicio que lista todas las novedades de un usuario
   */
  getPayrollNoveltiesForCurrentUser(month?: any, year?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('month', month || '')
        .set('year', year || '')
    };
    return this.http.get<any>(`${NOMINA_URL}novelties/myNovelties`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Servicio obtiene la información de una novedad
   */
  getEventInfoByPayrollNoveltyId(userId: number, noveltyId: number): Observable<any> {
    return this.http.get<any>(`${NOMINA_URL}novelties/myNovelties/${noveltyId}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Servicio para actualizar la justificación de una novedad
   */
  saveJustifyForEmployee(noveltyId: number, data: any): Observable<any> {
    return this.http.post<any>(`${NOMINA_URL}noveltyResponses/${noveltyId}/employee`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Servicio para crear novedades
   */
  createNovelties(data: any): Observable<any> {
    return this.http.post<any>(`${NOMINA_URL}noveltyResponses/createNovelty`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Servicio para crear novedades de tipo retardo
   */
  createTimeDelayNovelties(data: any): Observable<any> {
    return this.http.post<any>(`${NOMINA_URL}noveltyResponses/timeDelay`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Servicio para obtener los tipos de novedad
   */
  getNoveltyTypes(availableForNoveltyCreate: any = 0): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('availableInForm', availableForNoveltyCreate)
    };
    return this.http.get<any>(`${NOMINA_URL}noveltyTypes`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez
   * @purpose Servicio para obtener las novedades por filtro
   */
  getNoveltiesBy(state?: any, idTipDoc?: any, document?: any, campaign_id?: any, novelty_date1?: any, novelty_date2?: any, noveltyType?: any, perPage: any = 5, Page?: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('state', state)
        .set('document', document || '')
        .set('documentType', idTipDoc || '')
        .set('campaign_id', campaign_id || '')
        .set('novelty_date1', novelty_date1 || '')
        .set('novelty_date2', novelty_date2 || '')
        .set('noveltyType', noveltyType || '')
        .set('perPage', perPage)
        .set('page', Page || 1)
    };
    return this.http.get<any>(`${NOMINA_URL}noveltyReport/novelties`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez
   * @purpose Servicio para obtener las novedades
   */
  getNovelties(id) {
    const httpOptions = {
      params: new HttpParams().set('state', id)
    };
    return this.http.get<any>(`${NOMINA_URL}noveltyReport/novelties`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez
   * @purpose Servicio para aprobar o rechazar una novedad
   */
  changeStatus(id: any, body: any) {
    return this.http.put<any>(`${NOMINA_URL}noveltyResponses/${id}/changestatus`, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez
   * @purpose Servicio para descarga de reporte
   */
  getReport(state?: any, idTipDoc?: any, document?: any, campaign_id?: any, novelty_date1?: any, novelty_date2?: any, noveltyType?: any, forLiquidation?: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('state', state)
        .set('document', document || '')
        .set('campaign_id', campaign_id || '')
        .set('novelty_date1', novelty_date1 || '')
        .set('novelty_date2', novelty_date2 || '')
        .set('documentType', idTipDoc || '')
        .set('noveltyType', noveltyType || '')
        .set('forLiquidation', forLiquidation || 0),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${NOMINA_URL}noveltyReport/reportNovelty`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Andres Buitrago
   * @purpose Servicio para descarga de reporte de las novedades vinculadas al trabajador
   */
  getNoveltiesReportForLiquidation(docNumber: string, docTypeId: number, startDate: string, endDate: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('docNumber', docNumber)
        .set('docType', docTypeId.toString() || '')
        .set('startDate', startDate || '')
        .set('endDate', endDate || ''),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${NOMINA_URL}novelties/workerUser`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Servicio que obtiene los dias habiles/no habiles de un rango de fechas seleccionado para el proceso de vacaciones
   */
  getAvailableDaysForVacations(data: any): Observable<any> {
    return this.http.post<any>(`${NOMINA_URL}holidays/checkAvailableDays`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Servicio para crear novedades de vacaciones
   */
  createVacationNovelties(data: any): Observable<any> {
    return this.http.post<any>(`${NOMINA_URL}holidays/requestHolidays`, data)
      .pipe(
        catchError(this.handleError)
      );
  }


  /**
   * Metodo encargado de notificar al personal para cargue de mallas horarias
   * @author Daniel Dominguez
   * @createdate 2021-05-20
   */
  sendEmail(): Observable<any> {
    return this.http.get<any>(`${NOMINA_URL}schedules/sendAlertSchedule`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez
   * @purpose Servicio para crear novedades
   */
  createDisabilitiesNoveltie(data: any): Observable<any> {
    return this.http.post<any>(`${NOMINA_URL}disabilities/submitDisability`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez
   * @purpose Servicio para crear novedad de permiso
   */
  createPermitNoveltie(data: any): Observable<any> {
    return this.http.post<any>(`${NOMINA_URL}permissions/createPermit`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez
   * @purpose Servicio para obtener resumen de novedades
   */
  getSumary(month?: any, year?: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('month', month || '')
        .set('year', year || '')
    };
    return this.http.get<any>(`${NOMINA_URL}noveltyResponses/sumaryNovelties`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez
   * @purpose Servicio que lista todos los valores de resumen nomina
   */
  geValuesNomina() {
    return this.http.get(`${NOMINA_URL}nomina/resume`);
  }

  /**
   * exporta el listado de todas las novedades
   * @author Andres Buitrago
   * @createdate 2021-10-01
   * @param filters filtros a aplicar
   */
  downloadReportNovelties(filters: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('reportType', filters.typeReport || '')
        .set('startDate', moment(filters.startDate).format('YYYY-MM-DD') || '')
        .set('endDate', moment(filters.endDate).format('YYYY-MM-DD') || '')
        .set('campaignId', filters.campaignId || '')
        .set('docNumber', filters.docNumber || '')
        .set('docType', filters.docType || '')
        .set('userId', filters.userId || ''),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${NOMINA_URL}novelties/generateReportAllNovelties`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );


  }

  /**
 * @author Javier Castañeda
 * @purpose Servicio para obtener listado de EPS
 */
  getEps() {
    return this.http.get<any>(`${RRHH_URL}eps`)
      .pipe(
        catchError(this.handleError)
      );
  }
  /**
 * @author Javier Castañeda
 * @purpose Servicio para obtener EPS por id
 */
  getEpsById(id: any) {
    return this.http.get<any>(`${RRHH_URL}eps/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
* @author Javier Castañeda
* @purpose Servicio para obtener una empresa por id
*/
  getCompanyById(id) {
    return this.http.get<any>(`${RRHH_URL}company/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
 * @author Javier Castañeda
 * @purpose Servicio para obtener listado de empresas
 */
  getCompanys() {
    return this.http.get<any>(`${RRHH_URL}companies`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
* @author Javier Castañeda
* @purpose Servicio para la busqueda de diagnostico
*/
  searchDiagnostic(search: any): Observable<any> {
    return this.http.get<any>(`${NOMINA_URL}diagnostics/filter/${search}`)
  }

  /**
 * @author Javier Castañeda
 * @purpose Servicio para obterner informacion del trabajador
 */
  getInfoWorker(id: any): Observable<any> {
    return this.http.get<any>(`${RRHH_URL}user/candidate/${id}`)
  }

  /**
 * @author Javier Castañeda
 * @purpose Servicio para la justificar de novedades de retardo masivamente o individual
 */
  justifyNovelties(data: any): Observable<any> {
    return this.http.post<any>(`${NOMINA_URL}noveltyResponses/justificationForDelays`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Nicolas Soler
   * @purpuse Servicio para consultar todas las novedades de retardo 
   */
  getAllDelay(document: any, documentType: any, campaign_id: any, novelty_date1?: any, novelty_date2?: any, justifyType?: any, perPage: any = 5, Page?: any, state: any = 1) {
    const httpOptions = {
      params: new HttpParams()
        .set('state', state)
        .set('document', document || '')
        .set('documentType', documentType || '')
        .set('campaign_id', campaign_id || '')
        .set('novelty_date1', novelty_date1 || '')
        .set('novelty_date2', novelty_date2 || '')
        .set('typeJustification', justifyType || '')
        .set('perPage', perPage)
        .set('page', Page || 1)
    };
    return this.http.get<any>(`${NOMINA_URL}noveltyReport/noveltiesForDelays`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Nicolas Soler
   * @purpuse Servicio para aprovar o rechazar de forma masiva o individual los retardos
   */
  delayMasiveAprove(body) {
    return this.http.put<any>(`${NOMINA_URL}noveltyResponses/changestatusForDelays`, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
  * @author Nicolas Soler
  * @purpuse Servicio para aprovar o rechazar de forma masiva o individual los retardos
   */
  getDelayReport(document: any, documentType: any, campaign_id: any, novelty_date1?: any, novelty_date2?: any, justifyType?: any, state: any = 1) {
    const httpOptions = {
      params: new HttpParams()
        .set('state', state)
        .set('document', document || '')
        .set('documentType', documentType || '')
        .set('campaign_id', campaign_id || '')
        .set('novelty_date1', novelty_date1 || '')
        .set('novelty_date2', novelty_date2 || '')
        .set('typeJustification', justifyType || ''),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${NOMINA_URL}noveltyReport/noveltiesForDelays/report`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
* @author Javier Castañeda <javier.c@montechelo.com.co>
* @purpose Servicio que obtiene los dias habiles/no habiles de un rango de fechas seleccionado y rrhh_id del usuario para el proceso de vacaciones
*/
  getAvailableDaysForVacationsByUser(data: any): Observable<any> {
    return this.http.post<any>(`${NOMINA_URL}holidays/checkAvailableDaysByUser`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
* @author Johan Soler
* @createDate 2022-06-03
* Servicio para obtener los tipos de novedad usados en el filtro
*/
  getFilterNoveltyTypes(): Observable<any> {
    return this.http.get<any>(`${NOMINA_URL}noveltyTypes/getTypesNoveltiesByListNovelties`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Johan Soler
   * @purpose Servicio para crear la calamidad domestica
   */
  createDomesticCalamity(data: any){
    return this.http.post<any>(`${NOMINA_URL}novelty/createNoveltyDomesticCalamity`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Johan Soler 
   * @purpose Servicio para crear novedades de vacaciones extemporaneas
   */
  createVacationExtemporal(data: any): Observable<any> {
    return this.http.post<any>(`${NOMINA_URL}holidays/requestExtemporaneousVacations`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author David Reinoso
   * @purpose Servicio para obtener los dias disponibles de vacaciones de un usuario
   */
  getDaysVacationsAvailable(rrhh_id: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('rrhh_id', rrhh_id || '')
    };
    return this.http.get<any>(`${NOMINA_URL}vacation/getPassiveVacational`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

   /**
   * @author David Reinoso
   * @purpose Servicio para obtener los dias disponibles de vacaciones de un usuario
   */
   isOperationalPosition(): Observable<any> {
    return this.http.get<any>(`${RRHH_URL}position/userLogged`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez
   * @purpose Servicio para crear novedad de permiso
   */
  createPermitGrantedPreviousDaysNoveltie(data: any): Observable<any> {
    return this.http.post<any>(`${NOMINA_URL}permissions/createPermitGrantedPreviousDays`, data)
      .pipe(
        catchError(this.handleError)
      );
  }
}
