import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Menu } from '../../modules/class/menu';

const CIU_URL = environment.CIU_URL;

@Injectable({
  providedIn: 'root'
})
export class MenusService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-02
   * Servicio que lista todos los menus por un aplicativo
   * @param id id del aplicativo
   */
  getMenus(id: number): Observable<any>{
    return this.http.get<any>(`${CIU_URL}menus/showByApplicationId/` + id)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author jose vicente silva
   * @createdate 2021-02-03
   * Servicio que lista todos los aplicativos del aplicativo ciu paginados
   */
   getMenuPaginate(isPage: number,size: number, page: number ): Observable<any>{
    return this.http.get<any>(`${CIU_URL}menus?paginate=${isPage}&n=${size}&page=${page}`)
    .pipe(
      catchError(this.handleError)
      );
  }

   /**
   * @author José Vicente Silva Rivera
   * @createdate 2021-03-12
   * Servicio que realiza una busqueda avanzada de groupes por un valor
   * @param size tamaño de los datos a traer
   * @param value valor a buscar
   */
    searchMenu(size: number, page:number, value: any): Observable<any>{
      return this.http.get<any>(`${CIU_URL}menus/search?n=${size}&page=${page}&q=${value}`)
      .pipe(
        catchError(this.handleError)
        );
    }

  /**
   * @author José Vicente Silva Rivera
   * @createdate 2021-02-03
   * Servicio que guarda un group en su respectiva tabla
   * @param campaign campaign a guardarse
   */
   saveMenu(app: Menu): Observable<any>{
    
    return this.http.post<any>(`${CIU_URL}menus`, app)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author José Vicente Silva Rivera
   * @createdate 2021-03-12
   * Servicio que edita un group en su respectiva tabla
   * @param campaign campaign a editarse
   */
  editMenu(app: Menu): Observable<any>{
    return this.http.put<any>(`${CIU_URL}menus`, app)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author José Vicente Silva Rivera
   * @createdate 2021-02-04
   * Servicio que borra una campaña en su respectiva tabla modo logico
   * @param campaign id del group a eliminarse
   * @param idcampaign id del group a eliminarse
   */
  deleteMenu(app: Menu,idMenu: number): Observable<any>{
    return this.http.put<any>(`${CIU_URL}menus/${idMenu}/updateState`, app)
    .pipe(
      catchError(this.handleError)
      );
  }

}
