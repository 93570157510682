import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, filter } from 'rxjs/operators';
import { QuestionBase } from 'src/app/core/models/questions-base';
import { DateAdapter } from '@angular/material/core';
import { DynamicFormServiceService } from 'src/app/core/services/rest/dynamic-form-service.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { RequireMatch } from 'src/app/core/services/utils/question-control.service';

@Component({
  selector: 'form-questions-2',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.sass']
})
export class DynamicFormQuestionComponent {
  myControl = new FormControl();
  options: any = [];
  filteredOptions: Observable<any[]>;
  @Output() addField = new EventEmitter<any>();
  @Output() addDependece = new EventEmitter<any>();
  @Input() question: QuestionBase<any>;
  @Input() form: FormGroup;
  nameFile: string;

  @Output() addFile = new EventEmitter<any>();

  constructor(private fb: FormBuilder,
              private adapter: DateAdapter<any>,
              private dynamicService: DynamicFormServiceService,
              private destroyService: DestroyComponentService) {
                this.adapter.setLocale('es');
  }

  ngOnInit(): void {
    if(this.question.controlType == 'dropdown-request'){
      this.getOptions(this.question)
     .then(() =>{
       let index = this.question.options.findIndex(state => JSON.stringify(state) === JSON.stringify(this.question.value));
       if(index >= 0){
        this.form.controls[this.question.key].setValue(this.question.options[index])
       }
      });
  }

    this.filteredOptions = this.form.get(this.question.key).valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(state => state ? this._filter(state) : this.question.options.slice(0, 5))
    );
  }

   private _filter(value: string): any[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.question.options.filter(state => state.name.toLowerCase().includes(filterValue));
    }
  }

  displayFn(id): any {
    if (!id) {
      return '';
    }
    const index = this.question.options.findIndex(state => state.id === id);
    return this.question.options[index].name;
  }

  /**
   * @author Karol García
   * @createdate 2021-04-12
   * Método que devuelve el nombre elegido en loa autocomplete
   */
  displayFn2(option): any {
    if (!option) {
      return '';
    }
    let index = this.question.options.findIndex(state => JSON.stringify(state) === JSON.stringify(option));
    return this.question.options[index].name;
  }


  getErrorMessage() {
      if (this.form.controls[this.question.key].hasError('required')) {
        return `${this.question.label} es requerido`;
      }
      if (this.form.get(this.question.key).hasError('invalidAutocompleteObject')) {
        return `No es una opción válida`;
      }
      if (this.form.get(this.question.key).hasError('maxlength')) {
        return `${this.question.label} supera el límite de ${this.question.maxLength} caracteres`;
      }
      if (this.form.get(this.question.key).hasError('minlength')) {
        return `${this.question.label} debe tener ${this.question.minLength} mínimo caracteres`;
      }
      if (this.form.get(this.question.key).hasError('email')) {
        return `No es un correo electrónico válido`;
      }
  }
  addQuestion(question) {
    this.addField.emit(question);
  }
  optionSelected(event, question) {
    let aux: any;
    aux = {
      idFather: question.id,
      idValue: event
    };
    this.addDependece.emit(aux);
  }

  onFileSelected(e, id) {
    this.question.value = e.target.files[0].name;
    const data = {
      id: id,
      file: e.target.files[0]
    };
    this.addFile.emit(data);

  }
  getOptions(question) {
    var promise = new Promise((resolve) => {
      if(question.apiServe){
        this.dynamicService.getData(question.apiServe,`dropdown/options/${question.key}`).subscribe( (options:any) => {
          if(options.length > 7){
              this.question.controlType ='autocomplete2'
              this.form.controls[this.question.key].setValidators([
                RequireMatch,
              ]);
            }
            this.question.options = options;
           resolve(options);
         });
        }
  });
  return promise;
  }

  downloadFile(value: any) {

    const data = new FormData();

    data.append( 'url', value.value);

    this.dynamicService.downloadFile(data).subscribe((resp: any) => {
      importedSaveAs(resp, value.nameFile);
    });
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }
}
