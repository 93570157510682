import Swal from 'sweetalert2';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, ValidatorFn, FormArray } from '@angular/forms';
import { UsersService } from '../../../services/rest/users.service';
import { ApplicationsService } from '../../../services/rest/applications.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';

@Component({
  selector: 'app-mass-migration-users',
  templateUrl: './mass-migration-users.component.html',
  styleUrls: ['./mass-migration-users.component.sass']
})
export class MassMigrationUsersComponent implements OnInit {
  hide: boolean = true;
  massiveForm: FormGroup;
  fileName = '';
  applications: any;
  filteredOptions: Observable<any[]>;
  roles: any = [];
  notFocused = false;
  fileSend : File;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private form: FormBuilder,
              public userService: UsersService,
              private appService: ApplicationsService,
              private alertsService: AlertsService,
              private destroyService: DestroyComponentService,
              private dialogRef: MatDialogRef<MassMigrationUsersComponent>
              ) { }

  ngOnInit(): void {
    this.formControl();
    this.getApplicationList();
  }

  get error(): any { return this.massiveForm.controls; }

  get appxrol(): FormArray {
    return this.massiveForm.get('appxrol') as FormArray;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo donde se establecen las validaciones del formulario
   */
  formControl(): void{

    this.massiveForm = this.form.group({
      file: new FormControl('', [Validators.required,this.verifyFileExt()]),
      id_type_id : new  FormControl('1'),
      appxrol: this.form.array([])
    });
    this.newappxrol();

  }

  getApplicationList(){
    this.appService.getApplications().subscribe((resp) => {
      this.applications = resp.data;
    });
  }


  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que estable las validaciones del formarray dentro del formulario
   */
  newappxrol(): void{
    const newappxrol = this.form.group({
      app: new FormControl('', [Validators.required]),
      rol: new FormControl('', [Validators.required]),
    });
    this.appxrol.push(newappxrol);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que evalua en tiempo real los caracteres ingresados en el input, no deja ingresar espacios
   * @param event evento del keypress del input usuario
   */
  omitSpecialChar(event): any{
    let k;
    k = event.charCode;
    return((k > 32 && k < 126));
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que se encarga de la filtracion de aplicaciones
   * @param pos posicion del autocompletable a filtrar
   */
  getFilter(pos: number): void{
    this.filteredOptions = this.appxrol.controls[pos].get('app').valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(applications => applications ? this._filter(applications) : this.applications.slice())
    );
  }

  /**
   * @author Carlos Nieto
   * @createdate 2021-12-15
   * Metodo que se encarga de la eliminacion de un grupo de aplicacion y rol y valida que quede almenos uno
   * @param indice indice del grupo de aplicacion y rol a eliminar
   */
  deleteappxrol(indice: number): void {
    if(this.appxrol.length > 1)
    {
      this.roles.splice(indice,1);
      this.appxrol.removeAt(indice);
    }else{
      Swal.fire({
        html: `Este campo es obligatorio`,
        icon: 'error',
        title : 'Error',
        confirmButtonText: 'Aceptar'
      });
    }
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * filtra por nombre, se usa para el autocompletable
   * @param value valor a filtrar
   */
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.applications.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   */
  displayFn(id: number): string{
    if (!id) { return ''; }
    let index = this.applications.findIndex(resp => resp.id === id);
    return this.applications[index].name;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que se activa cuando una aplicacion ha sido seleccionada, busca los roles de la aplicacion seleccionada
   * @param id id de la aplicacion
   * @param pos posicion del grupo de app y rol
   */
  appSelected(id: any, pos: number): void{
    this.appxrol.controls[pos].get('rol').setValue('');
    this.appService.getRolesxApp(id).subscribe((resp) => {
      this.roles[pos] = resp.data;
    });
  }

  /**
   * @author Carlos Nieto
   * @createdate 2021-12-15
   * Metodo que se encarga de consumir el servicio de carga masiva
   * @param massiveForm formulario del usuario a editar
   */
  sendMassive(): void{
    let formdata: FormData = new FormData();

    formdata.append('cedulas', this.fileSend),
    formdata.append('appxrol', JSON.stringify(this.massiveForm.value.appxrol)),
    formdata.append('id_type_id', '1'),

    this.userService.massiveRolesImport(formdata).subscribe(
      response => {
        this.dialogRef.close();
        if (response.exis != null) {
           Swal.fire({
            html: `${response.exis} Roles asignados con exito${response.no_exi.length == 0 ? '.' : '. Cedulas que no existen ' + response.no_exi.join()+'.'}
            ${response.rep.length == 0 ? ' ' : ' Cedulas con roles previamente asignados ' + response.rep.join()+'.'}`,
            icon: 'success',
            title : 'Enviado',
            confirmButtonText: 'Aceptar'
          });
        } else if(response.error_code == 401)
        {
          Swal.fire({
            html: `${response.error}`,
            icon: 'error',
            title : 'Error',
            confirmButtonText: 'Aceptar'
          });
        }
      }
    );

  }


  /**
  * @author Carlos Nieto
  * @createdate 2021-12-15
  * Metodo que se encarga cargar el nombre del archivo
  * @param event envento on change
  */
  onFileSelected(event) {
    this.notFocused = true;
    const file:File = event.target.files[0];

    if (file) {
        this.fileName = file.name;
        this.fileSend = file;
    }
  }

 /**
  * @author Daniel Martinez
  * @createdate 2021-04-22
  * Metodo que se encarga de validar que no se repitan roles
  * @param pos posicion del select
  */
  verifyRoles(pos: number): void {
    let cont = 0;
    this.appxrol.controls.forEach(element => {
      if ( element.get('rol').value === this.appxrol.controls[pos].get('rol').value) {
        cont++;
      }
    });

    if (cont > 1) {
      this.appxrol.controls[pos].get('rol').setErrors({ invalidRol: true});
    }

  }

  /**
   * @author Carlos Nieto
   * @createdate 2021-12-15
   * Metodo que regula las extenciones del archivo cargado al formulario
   */
   verifyFileExt(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!/.xlsx/.test(control.value)) {
        return { hasWrongFile: { value: control.value } };
      }
      return null;
    };
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }
}
