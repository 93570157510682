import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CampaignService } from 'src/app/modules/ciu/services/rest/campaign.service';
import { ConfigService } from 'src/app/modules/ciu/services/rest/config.service';
import { GroupsService } from 'src/app/modules/ciu/services/rest/groups.service';
import { ScheduleTypesService } from 'src/app/modules/ciu/services/rest/schedule-types.service';
import { SchedulesService } from 'src/app/modules/ciu/services/rest/schedules.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { CandidatesService } from 'src/app/modules/rrhh/services/candidates.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'app-schedule-report',
    templateUrl: './schedule-report.component.html',
    styleUrls: ['./schedule-report.component.sass']
})
export class ScheduleReportComponent implements OnInit {
    @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoCompleteScReport: MatAutocompleteTrigger;

    filterForm: FormGroup;
    campaigns: any;
    groups: any;
    rangeDates: any = [];
    filteredOptionsCampaigns: Observable<any[]>;
    filteredOptionsGroups: Observable<any[]>;
    filteredOptionsRanges: Observable<any[]>;
    week: any;
    document: any;
    show = false;
    selection = new SelectionModel(true, []);

    length;
    pageSize = 5;
    page = 1;
    pageSizeOptions: number[] = [5, 10, 25, 100];
    public editAvailable: boolean;

    public filters: any = {
        campaignId: '',
        startWeek: '',
        endWeek: '',
        groupId: '',
        document: '',
        type: 1,
        perPage: 5,
        page: 1,
        paginate: 1,
    };

    // displayedColumns: string[] = ['date_start', 'date_end', 'hour_start', 'hour_end', 'user'];
    displayedColumns: string[] = ['name', 'document', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    dataSource = new MatTableDataSource<any>();

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    documents: any = [];
    idTypeDoc: any;
    typeDocument = new FormControl();

    constructor(
        private campaignService: CampaignService,
        private groupService: GroupsService,
        private scheduleTypeService: ScheduleTypesService,
        private configService: ConfigService,
        private alert: AlertsService,
        private datePipe: DatePipe,
        private sheduleService: SchedulesService,
        private users: CandidatesService) {
    }

    ngOnInit() {
        this.formControl();
        this.getWeekRanges();
        this.campaignService.getCampaigns().subscribe((resp) => {
            this.campaigns = resp.data;

            this.filteredOptionsCampaigns = this.filterForm.get('campaigns').valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                map(x => x ? this._filterCampaigns(x) : this.campaigns.slice())
            );
        });

        // atento a cambios de campaña y grupo
        this.filterForm.controls.groups.valueChanges
            .subscribe(resp => {
                if (resp) {
                    this.filters.groupId = resp;
                    this.getSchedulesToPrint();
                }
            });
        this.filterForm.controls.campaigns.valueChanges
            .subscribe(resp => {
                if (resp && this.filterForm.controls.rangeDate.value) {
                    this.filters.campaignId = resp;
                    if (this.filterForm.controls.groups.value) {
                        this.getSchedulesToPrint();
                    }
                }
            });
        // Tipos de documento
        this.users.getIdTypes().subscribe(resp => {
            this.documents = resp;
        });
        this.typeDocument.valueChanges.subscribe(resp => {
            this.filters.type = resp;
            if (this.filters.document) {
                this.getSchedulesToPrint();
            }
        });
        this.typeDocument.setValue(1);
    }

    get error(): any {
        return this.filterForm.controls;
    }

    /**
     * Encargado de la accíon al cambio de pagina
     * @param event
     * @author Daniel Dominguez 
     */
    pageEvent(event: any): void {

        this.pageSize = event.pageSize;
        this.page = event.pageIndex + 1;
        this.filters.page = this.page;
        this.filters.perPage = this.pageSize;
        this.getSchedulesToPrint();
        // this.getSchedule(this.pageSize, this.page, null);

    }

    /**
     * Formulario
     * @author Daniel Dominguez 
     */
    formControl(): void {
        this.filterForm = new FormGroup({
            rangeDate: new FormControl('', [this.autocompleteObjectValidator(), Validators.required]),
            campaigns: new FormControl('', [this.autocompleteObjectValidator(), Validators.required]),
            groups: new FormControl('', [this.autocompleteObjectValidator(), Validators.required])
        });
    }


    /**
     * Permite el autocompletado
     * @author Daniel Dominguez 
     */
    autocompleteObjectValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any }|null => {
            if (typeof control.value === 'string') {
                return { invalidAutocompleteObject: { value: control.value } };
            }
            return null;
        };
    }

    /**
     * Filtro para campañas
     * @param value
     * @private
     */
    private _filterCampaigns(value: string): any[] {
        const filterValue = value.toLowerCase();
        return this.campaigns.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    /**
     * Filtro para grupos
     * @param value
     * @private
     * @author Daniel Dominguez 
     */
    private _filterGroups(value: string): any[] {
        const filterValue = value.toLowerCase();
        return this.groups.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    /**
     * Filtro de fechas
     * @param value
     * @private
     */
    private _filterRanges(value: string): any[] {
        const filterValue = value.toLowerCase();
        return this.rangeDates.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }


    /**
     * Muestra campañas
     * @param id
     * @author Daniel Dominguez 
     */
    displayFnCampaigns(id: number): string {
        if (!id) {
            return '';
        }
        const index = this.campaigns.findIndex(resp => resp.id === id);
        return this.campaigns[index].name;
    }

    /**
     * Muestra los grupos
     * @param id
     * @author Daniel Dominguez 
     */
    displayFnGroups(id: number): string {
        if (!id) {
            return '';
        }
        const index = this.groups.findIndex(resp => resp.id === id);
        return this.groups[index].name;
    }

    /**
     * Muestra rango de semanas
     * @param id
     * @author Daniel Dominguez 
     */
    displayFnRanges(id: any): string {
        if (!id) {
            return '';
        }
        const index = this.rangeDates.findIndex(resp => resp.id === id);
        return this.rangeDates[index].name;
    }

    /**
     * Realiza la consulta de grupos en base a la campaña
     * @param e
     * @author Daniel Dominguez 
     */
    public consultarGrupos(e): void {
        this.filterForm.patchValue({
            groups: ''
        });

        this.groupService.getGroupsByCampaign(this.filterForm.value.campaigns).subscribe((response) => {
            this.groups = response.data;
            this.filteredOptionsGroups = this.filterForm.get('groups').valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                map(x => x ? this._filterGroups(x) : this.groups.slice())
            );
        });
    }

    /**
     * Dias de la semana
     * @author Daniel Dominguez 
     */
    public getWeekRanges() {
        this.filterForm.patchValue({
            rangeDate: ''
        });
        const to = new Date().getFullYear() + '-01-01';
        const numWeeks = 52;
        this.sheduleService.getWeeksRangesForScheduleFilters({ to, numWeeks })
            .subscribe(resp => {
                this.rangeDates = resp.data;
                this.filteredOptionsRanges = this.filterForm.get('rangeDate').valueChanges.pipe(
                    startWith(''),
                    map(value => {
                        return typeof value === 'string' ? value : value.name;
                    }),
                    map(x => {
                        return x ? this._filterRanges(x) : this.rangeDates.slice();
                    })
                );
                setTimeout(() => {
                    this.inputAutoCompleteScReport.openPanel();
                }, 700);
            });
    }


    /**
     * @author Daniel Dominguez 
     * @createdate 2021-05-05
     * Metodo que devuelve los dias de la semana seleccionada, para ser mostrados en la cabecera de la tabla
     */
    onChange(e): void {
        const index = this.rangeDates.findIndex(resp => resp.id === e.option.value);
        this.filters.startWeek = this.rangeDates[index].from;
        this.filters.endWeek = this.rangeDates[index].to;
        this.week = this.rangeDates[index].days;
        // validar si muestro botones para edicion
        this.editAvailable = this.filters.endWeek >= moment().format('YYYY-MM-DD');

        if (this.filterForm.controls.campaigns.value) {
            this.getSchedulesToPrint();
        }
    }

    /**
     * @author Daniel Dominguez 
     * @createdate 2021-05-05
     * Metodo para capturar el documento a filtrar
     */
    onBlurMethod(e): void {
        this.document = Number(e);
        this.filters.document = this.document;
        this.getSchedulesToPrint();
    }

    /**
     * @author Daniel Dominguez 
     * @createdate 2021-05-06
     * Metodo para mostrar o ocultar filtros avanzados
     */
    Hide(): void {
        if (this.show == false) {
            this.show = true;
        } else {
            this.show = false;
        }
    }

    /**
     * Obtiene las marcaciones realizadas por los usuarios de un grupo
     * @author Andres Buitrago
     */
    getSchedulesToPrint(): void {
        this.sheduleService.getMarkedSchedulesByFilters(this.filters)
            .subscribe(resp => {
                this.dataSource.data = resp.data.data;
                this.dataSource.sort = this.sort;
                this.length = resp.data.total;
                this.pageSize = resp.data.per_page;
            }, error => {
                this.dataSource.data = [];
                this.dataSource.sort = this.sort;
                this.length = 0;
                this.pageSize = 5;
            });
    }

    /**
     * @author Andres Buitrago
     * Metodo para la descarga del reporte
     */
    downloadReport(): void {
      if (!this.filters.startWeek) return;
        this.sheduleService.exportMarkedSchedulesByFilters(this.filters)
            .subscribe(resp => {
                importedSaveAs(resp, 'reporte_marcaciones.xlsx');
            });
    }

}
