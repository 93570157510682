import { createReducer, on } from "@ngrx/store";
import { AuditoriaState } from "../../core/states/auditoria.state";
import { loadAuditoria, loadedAuditoria } from "../actions/auditoria.actions";

export const initialState: AuditoriaState = { loading:false, auditoria:[] };

export const auditoriaReducer = createReducer(
    initialState,
    on(loadAuditoria,(state)=>{
        return {...state,loading:true}
    }),
    on(loadedAuditoria,( state, { auditoria } )=>{
        return {...state, loading:false, auditoria}
    })
);