import { EditSchedulesComponent } from './../edit-schedules/edit-schedules.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfigSchedulesComponent } from '../config-schedules/config-schedules.component';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { CampaignService } from '../../../services/rest/campaign.service';
import { GroupsService } from '../../../services/rest/groups.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { startWith, map, filter } from 'rxjs/operators';
import { ScheduleTypesService } from '../../../services/rest/schedule-types.service';
import { ConfigService } from '../../../services/rest/config.service';
import { DatePipe } from '@angular/common';
import { SchedulesService } from '../../../services/rest/schedules.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import { SelectionModel } from '@angular/cdk/collections';
import { saveAs as importedSaveAs } from 'file-saver';
import { CandidatesService } from 'src/app/modules/rrhh/services/candidates.service';
import { element } from 'protractor';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { ScheduleService } from './../../../../../core/services/rest/schedule.service';
import Swal from 'sweetalert2';




@Component({
  selector: 'app-admin-schedules',
  templateUrl: './admin-schedules.component.html',
  styleUrls: ['./admin-schedules.component.sass']
})
export class AdminSchedulesComponent implements OnInit {
  filterForm: FormGroup;
  campaigns: any;
  campaigns2: any;
  groups: any = [];
  rangeDates: any;
  filteredOptionsCampaigns: Observable<any[]>;
  filteredOptionsCampaigns2: Observable<any[]>;
  filteredOptionsGroups: Observable<any[]>;
  filteredOptionsRanges: Observable<any[]>;
  week: any;
  document: any;
  show = false;
  selection = new SelectionModel(true, []);

  length;
  pageSize = 5;
  page = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  public editAvailable: boolean;

  public filters: any = {
    campaignId: '',
    startWeek: '',
    endWeek: '',
    groupId: '',
    document: '',
    type: 1,
    stateUserSchedule: 0,
    perPage: 5,
    page: 1,
    paginate: 1,
  };

  // displayedColumns: string[] = ['date_start', 'date_end', 'hour_start', 'hour_end', 'user'];
  displayedColumns: string[] = ['check', 'actions', 'name', 'document', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;

  Actives: any;
  Inactives: any;
  documents: any = [];
  idTypeDoc: any;
  typeDocument = new FormControl();
  rol: any;
  reportForm: FormGroup;
  isRolPayroll : boolean;

  constructor(public dialog: MatDialog,
    private campaignService: CampaignService,
    private groupService: GroupsService,
    private scheduleTypeService: ScheduleTypesService,
    private configService: ConfigService,
    private alert: AlertsService,
    private datePipe: DatePipe,
    private users: CandidatesService,
    private sheduleService: SchedulesService,
    private authService: AuthService,
    private scheduleService2 : ScheduleService,
  ) {
    this.isRolPayroll = this.authService.getUser().roles.includes('ciu::horarios_nomina');
  }

  ngOnInit(): void {
    this.editAvailable = true;
    this.selection.clear();
    this.formControl();
    this.reportForm = new FormGroup({
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      groupId: new FormControl('', Validators.required),
      userId: new FormControl('')
    });
    this.campaignService.getCampaigns().subscribe((resp) => {
      this.campaigns = resp.data;
      this.campaigns2 = resp.data;

/*       this.filteredOptionsCampaigns = this.filterForm.get('campaigns').valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(x => x ? this._filterCampaigns(x) : this.campaigns.slice())
      ); */

      // filtro tab reporte
      this.filteredOptionsCampaigns2 = this.reportForm.get('groupId').valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(x => x ? this._filterCampaigns(x) : this.campaigns2.slice())
      );
    });

    //obtener rol
    let user = this.authService.getUser();
    user.roles.map(item => {
      let array = item.split('::');
      if (array[0] == 'ciu') {
        this.rol = array[1];
      }
    });

    // atento a cambios de campaña y grupo

    this.consultarGrupos();
    this.filterForm.controls.groups.valueChanges.subscribe(resp => {

        if (resp) {
          this.cleanAllByChangeFilters('groups');
          this.filters.groupId = resp;
          this.getWeeksByGroupId();
          if (this.filters.startWeek) {
            this.getSchedulesToPrint();
          }
        }
      });

    //Tipos de documento
    this.users.getIdTypes().subscribe(resp => {
      this.documents = resp;
    });
    this.typeDocument.valueChanges.subscribe(resp => {
      this.filters.type = resp;
      this.getSchedulesToPrint();
    });
    this.typeDocument.setValue(1);

  }

  get error(): any {
    return this.filterForm.controls;
  }

  get errorReport(): any {
    return this.reportForm.controls;
  }

  get userLog() { return this.authService.decryptToken(); }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que se encarga del paginador al momento de cambiar de pagina
   * @param event evento que trae la pagina a listar
   */
  pageEvent(event: any): void {

    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.filters.page = this.page;
    this.filters.perPage = this.pageSize;
    this.getSchedulesToPrint();
    // this.getSchedule(this.pageSize, this.page, null);

  }

  /**
   * @author Jose Silva
   * @createdate 2021-02-09
   * Metodo donde se establecen las validaciones del formulario
   */
  formControl(): void {

    this.filterForm = new FormGroup({
      rangeDate: new FormControl('', [this.autocompleteObjectValidator(), Validators.required]),
      groups: new FormControl('', [this.autocompleteObjectValidator(), Validators.required])
    });
  }

  /**
   * @author Jose Silva
   * @createdate 2021-02-09
   * Metodo que valida las funciones del autocompetable
   */
  autocompleteObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string') {
        return { invalidAutocompleteObject: { value: control.value } };
      }
      return null;
    };
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * filtra por nombre, se usa para el autocompletable
   * @param value valor a filtrar
   */
  private _filterCampaigns(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.campaigns.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * filtra por nombre, se usa para el autocompletable
   * @param value valor a filtrar
   */
  private _filterGroups(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.groups.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * filtra por nombre, se usa para el autocompletable
   * @param value valor a filtrar
   */
  private _filterRanges(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.rangeDates.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   */
  displayFnCampaigns(id: number): string {
    if (!id) {
      return '';
    }
    const index = this.campaigns.findIndex(resp => resp.id === id);
    return this.campaigns[index].name;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   */
  displayFnGroups(id: number): string {
    if (!id) {
      return '';
    }
    const index = this.groups.findIndex(resp => resp.id === id);
    return this.groups[index].name;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   */
  displayFnRanges(id: any): string {
    if (!id) {
      return '';
    }
    const index = this.rangeDates.findIndex(resp => resp.id === id);
    return this.rangeDates[index].name;
  }

  /**
   * Consultar grupos
   * @author Daniel Dominguez 
   * @createdate 2021-05-05
   */
  public consultarGrupos(e?) {

    this.filterForm.patchValue({
      groups: ''
    });

    this.groupService.getAllGroups().subscribe((response) => {
      this.groups = response.data;
      this.filteredOptionsGroups = this.filterForm.get('groups').valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(x => x ? this._filterGroups(x) : this.groups.slice())
      );
    });
  }

  /**
   * Obtner semanas
   * @author Daniel Dominguez 
   * @createdate 2021-05-05
   */
  public getWeeksRange() {
    this.filterForm.patchValue({
      rangeDate: ''
    });
    const to = new Date().getFullYear() + '-01-01';
    const numWeeks = 52;
    this.sheduleService.getWeeksRangesForScheduleFilters({ to, numWeeks })
      .subscribe(resp => {
        this.rangeDates = resp.data;
        this.filteredOptionsRanges = this.filterForm.get('rangeDate').valueChanges.pipe(
          startWith(''),
          map(value => {
            return typeof value === 'string' ? value : value.name;
          }),
          map(x => {
            return x ? this._filterRanges(x) : this.rangeDates.slice();
          })
        );
        /*setTimeout(() => {
          this.inputAutoComplete.openPanel();
        }, 700);*/
      });
  }

  /**
   * @author Daniel Dominguez 
   * Modal para la creación de horarios
   */
  public newConfigSchedule() {
    const dialogNewRol = this.dialog.open(ConfigSchedulesComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Administrar tipos de horario',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.selection.clear();
      if (this.filters.startWeek) {
        this.getSchedulesToPrint();
      }
    });
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-05-05
   * Modal para la edición de horarios
   */

  public viewEditSchedule(element) {

    if (this.week.length > 0 || this.week != undefined) {
      const dialogNewRol = this.dialog.open(EditSchedulesComponent, {
        width: '60%',
        disableClose: true,
        data: {
          type: 'crear',
          title: 'Editar horarios',
          data: this.selection.selected,
          data2: element,
          week: this.week,
          filters: this.filters
        },
        panelClass: 'custom-dialog-container',
      });

      dialogNewRol.afterClosed().subscribe(() => {
        this.selection.clear();
        delete this.filters.userIds;
        if (this.filters.startWeek) {
          this.getSchedulesToPrint();
        }
      });
    }
  }

  /**
   * Metodo encargado de la eliminacion de horarios
   * @param row info del usuario seleccionado
   * @author Andrés Buitrago
   */
  deleteSchedule(row): void {
    this.alert.alertConfirm('¿Desea eliminar el horario de esta semana para este usuario?').then(e => {
      if (e.isConfirmed) {
        const { groupId, startWeek, endWeek } = this.filters;
        this.sheduleService.deleteScheduleByParams({ groupId, startWeek, endWeek, userId: row.id })
          .subscribe(resp => {
            this.selection.clear();
            this.getSchedulesToPrint();
          });
      }
    });
  }


  /**
   * @author Daniel Dominguez 
   * @createdate 2021-05-05
   * Metodo que devuelve los dias de la semana seleccionada, para ser mostrados en la cabecera de la tabla
   */
  onChange(e) {
    const index = this.rangeDates.findIndex(resp => resp.id === e.option.value);
    this.filters.startWeek = this.rangeDates[index].from;
    this.filters.endWeek = this.rangeDates[index].to;
    this.week = this.rangeDates[index].days;
    // validar si muestro botones para edicion
    this.editAvailable = this.filters.endWeek >= moment().format('YYYY-MM-DD');

    if (this.filterForm.controls.groups.value) {
      this.getSchedulesToPrint();
    }
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-05-05
   * Metodo para capturar el documento a filtrar
   */
  onBlurMethod(e) {
    this.document = e;
    this.filters.document = this.document;
    this.getSchedulesToPrint();
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-05-06
   * Metodo para mostrar o ocultar filtros avanzados
   */
  Hide() {
    if (this.show == false) {
      this.show = true;
    } else {
      this.show = false;
      this.filters.document = this.show ? this.document : '';
      this.getSchedulesToPrint();
    }
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-05-05
   * Metodo para seleccionar las filas en la tabla
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-05-05
   * Selecciona todas las filas si no están todas seleccionadas; de lo contrario la selección se limpia.
   */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-05-05
   * Obtiene los horarios en base a los filtros seleccionados.
   */
  getSchedulesToPrint(): void {
    if (!this.filters.groupId || this.filters.groupId === '') {
      return;
    }

    this.sheduleService.getSchedulesByFilters(this.filters)
      .subscribe(resp => {
        this.Actives = resp.data.actives;
        this.Inactives = resp.data.inactives;
        this.dataSource.data = resp.data.data;
        this.dataSource.sort = this.sort;
        this.length = resp.data.total;
        this.pageSize = resp.data.per_page;
      }, error => {
        this.Actives = 0;
        this.Inactives = 0;
        this.dataSource.data = [];
        this.dataSource.sort = this.sort;
        this.length = 0;
        this.pageSize = 5;
      });
  }

  /**
   * @author Andres Buitrago
   * @createdate 2021-05-28
   * Obtiene los horarios en base a los filtros seleccionados.
   */
  selectCardFilter(state: number): void {
    if (!this.filters.groupId && !this.filters.startWeek) {
      return;
    }
    this.filters.stateUserSchedule = (this.filters.stateUserSchedule === state) ? 0 : state;
    this.getSchedulesToPrint();
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-05-05
   * Obtiene los horarios en base a los filtros seleccionados.
   */
  exportSchedule(): void {
    if (!this.filters.startWeek) {
      return;
    }
    this.sheduleService.exportScheduleByFilters(this.filters)
      .subscribe(resp => {
        const dateToFile = moment().format('YYYY-MM-DD');
        importedSaveAs(resp, `reporte_horarios_${dateToFile}.xlsx`);
      });
  }

  /**
   * Obtiene las semanas cargadas por grupo
   * @author Andres Buitrago
   */
  getWeeksByGroupId(): void {
    this.filterForm.patchValue({
      rangeDate: ''
    });
    this.sheduleService.getWeeksRangesByGroupId(this.filters.groupId)
      .subscribe(resp => {
        this.rangeDates = resp.data.data;
        if (resp.data.currentWeekId) {
          this.filterForm.controls.rangeDate.setValue(resp.data.currentWeekId);
          this.onChange({ option: { value: resp.data.currentWeekId } });
        }
        this.filteredOptionsRanges = this.filterForm.get('rangeDate').valueChanges.pipe(
          startWith(''),
          map(value => {
            return typeof value === 'string' ? value : value.name;
          }),
          map(x => {
            return x ? this._filterRanges(x) : this.rangeDates.slice();
          })
        );
      });
  }

  /**
   * Reporte eventos CIU and VICI
   * @author Andres Buitrago
   */
  public downloadReportCiuAndVici(): void {
    this.sheduleService.exportEventsCiuAndVicidialByFilters(this.reportForm.value)
      .subscribe(resp => {
        const dateToFile = moment().format('YYYY-MM-DD');
        importedSaveAs(resp, `reporte_eventos_${dateToFile}.xlsx`);
      });
  }

  /**
   * Limpia los filtros de búsqueda cuando se cambia la campaña
   */
  cleanAllByChangeFilters(type: string): void {
    this.selection.clear();
    this.editAvailable = false;
    this.filterForm.controls.rangeDate.setValue('');
    this.dataSource.data = [];
    this.dataSource.sort = this.sort;
    this.length = 0;
    this.pageSize = 5;
    this.rangeDates = [];
    this.Actives = 0;
    this.Inactives = 0;
    if (type === 'campaign') {
      this.filterForm.controls.groups.setValue('');
      this.filters = {
        campaignId: this.filterForm.controls.campaigns.value,
        startWeek: '',
        endWeek: '',
        groupId: '',
        document: '',
        type: 1,
        stateUserSchedule: 0,
        perPage: 5,
        page: 1,
        paginate: 1,
      };
    } else if (type === 'group') {
      this.filters = {
        campaignId: this.filterForm.controls.campaigns.value,
        startWeek: '',
        endWeek: '',
        groupId: this.filterForm.controls.groups.value,
        document: '',
        type: 1,
        stateUserSchedule: 0,
        perPage: 5,
        page: 1,
        paginate: 1,
      };
    }
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-03-07
   * Metodo que se encarga de resetear la  jornada de un usuario
   * guardando su respectivo log
   * @param user datos de la columna usuario
   */
   scheduleReset(user: any): void{

    let value = this.alert.alertConfirm('¿Desea reiniciar la jornada este usuario?');
    value.then((result) =>{
      if (result.isConfirmed) {
        let data = {"responsible_id":this.userLog.idCiu,"affected_user":user.id};
        if(this.userLog.idCiu != user.id )
        {
          this.scheduleService2.scheduleReset(data).subscribe((resp) => {
            this.alert.alertSuccess('Jornada reiniciada', resp.data);
          });
        }else{
          Swal.fire({
            html: `No puedes modificar tu propia jornada.`,
            icon: 'error',
            title : 'Error',
            confirmButtonText: 'Aceptar'
          })
        }

      }
    });
  }
}


