import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotesService } from '../../services/rest/notes.service';

/**
 * @ignore 
 */
@Component({
  selector: 'app-historialn',
  templateUrl: './historialn.component.html',
  styleUrls: ['./historialn.component.sass']
})
export class HistorialnComponent implements OnInit {

  notes: any;
  rolUser: any;
  panelOpenState: boolean;
  oportunity: any;
  step: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notesService: NotesService,
  ) {
    this.rolUser = this.data['rolUser'];
    this.oportunity = this.data['oportunity'];
  }

  /**
 * @author Javier Castañeda
 * @createddate 2022-10-29 
 * Metodo de inicio del componente, ejecuta servicio de consulta del historico de notas
 * @returns Json
 *
 */
  ngOnInit(): void {
    this.notesService.search(this.oportunity).subscribe((resp) => {
      this.notes = resp;
    });
  }

}
