<mat-card class="main-card">
  <mat-card-header class="row">
    <mat-card class="col-4 calificacion" [ngClass]="{'change-3': decimales, 'change-md': screen_md}">
      <p style="font-size: 1em;">{{ config.calificacionCampanha.titulo }}</p>
      <h1 *ngIf="data.tipo_matriz != config.cumplimiento">{{ calculateCalificacion() | number:'1.0-2' }}</h1>
      <h1 *ngIf="data.tipo_matriz == config.cumplimiento">{{ calculateCalificacion() | number:'1.0-2' }}%</h1>
    </mat-card>
    <mat-card class="col-7" id="subCard">
      <div id="div1">
        <p>{{ config.gestionResumida.titulo }}</p>
      </div>
      <div id="div2">
        <div>
          <p>{{ config.gestionResumida.auditoriaInterna }}</p>
          <h2>{{ tipo_auditar.interna }}</h2>
        </div>
        <div>
          <p>{{ config.gestionResumida.auditoriaCliente }}</p>
          <h2>{{ tipo_auditar.cliente }}</h2>
        </div>
      </div>
    </mat-card>
  </mat-card-header>
    <div style="overflow: auto; height: 356px;">
        <div fxLayout="column" [formGroup]="moduloForm">
            <div formArrayName="modulos" [togglePosition]="'before'"
                *ngFor="let modulo of getModulos().controls; let indexModulo = index">
                <mat-expansion-panel class="mt-4" id="modulo" [expanded]="indexModulo == 0" fxFlex.gt-lg="100%"
                    fxFlex.gt-md="100%" [formGroupName]="indexModulo">
                    <mat-expansion-panel-header>
                        <mat-panel-title fxLayout="row" fxFlex="100%" class="content-module">
                                <div fxLayout="col-6" fxFlex="90%">
                                    <p class="sigla" fxFlex.gt-lg="4%" fxFlex.gt-md="8%"
                                        [matTooltip]="modulo.get('descripcion').value" [matTooltipPosition]="'above'">
                                        {{modulo.get('sigla').value}}</p>
                                    <p style="font-weight: 600;padding-top: .3em;"
                                        [matTooltip]="modulo.get('nombre').value" [matTooltipPosition]="'above'"
                                        fxFlex.gt-lg="80%" fxFlex.gt-md="80%">
                                        {{ modulo.get('nombre').value | cutText:0:35 }}</p>
                                        &nbsp;
                                    <p class="peso" *ngIf="modulo.get('peso').value && tipo_matriz != config.cumplimiento"
                                        fxFlex.gt-lg="18%" fxFlex.gt-md="18%">{{ config.peso.titulo }}: {{modulo.get('peso').value}}</p>
                                    <p class="peso" *ngIf="modulo.get('peso').value && tipo_matriz == config.cumplimiento"
                                        fxFlex.gt-lg="18%" fxFlex.gt-md="18%">{{ config.peso.titulo }}: {{modulo.get('peso').value}}%</p>
                                </div>
                                <div fxLayout="col-5" fxFlex="10%">
                                     <mat-chip-list align="right" class="mat-chip-list-stacked" *ngIf="modulo.get('error_critico').value == '1' " >
                                        <mat-chip selected style="background-color: red;">
                                            <i class="fi fi-rr-exclamation"></i> &nbsp;
                                            <b> {{ config.critico.tooltip }} </b>
                                        </mat-chip>
                                    </mat-chip-list>

                                </div>
                              </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div formArrayName="items_modulo"
                        *ngFor="let item of getItems(indexModulo).controls; let iItem = index">
                        <div [formGroupName]="iItem">
                            <mat-expansion-panel [togglePosition]="'before'">
                                <mat-expansion-panel-header class="mt-4">
                                    <mat-panel-title fxLayout="row" fxFlex.gt-lg="40%" fxFlex.gt-md="34%">
                                        <p class="sigla" fxFlex.gt-lg="10%" fxFlex.gt-md="24%">
                                            {{item.get('sigla').value}}</p>
                                        <button mat-icon-button [disabled]="!item.get('aspectosToltip').value">
                                            <mat-icon style="font-size: 1.3em; margin-top: -.7em;"
                                                [matTooltip]="item.get('aspectosToltip').value"
                                                matTooltipClass="multi-line-tooltip"
                                                [matTooltipPosition]="'above'"><i class="fi-rr-document"></i>
                                            </mat-icon>

                                        </button>
                                        <p style="font-size: 1em;padding-top: .3em;"
                                            [matTooltip]="item.get('nombre').value" [matTooltipPosition]="'above'"
                                            fxFlex.gt-lg="90%" fxFlex.gt-md="90%">
                                            {{item.get('nombre').value | cutText:0:10}}
                                        </p>
                                    </mat-panel-title>
                                    <mat-panel-description fxLayout="row" fxFlex.gt-lg="60%" fxFlex.gt-md="56%">
                                        <div *ngIf="data.tipo_matriz == config.cumplimiento">
                                            <mat-radio-group aria-label="Select an option"
                                                (change)="cantidadDecimales();calificacionItem(indexModulo,iItem)"
                                                formControlName="calificacion" >
                                                <mat-radio-button value="cumple" class="radios_check" (click)="$event.stopPropagation()" [checked]="false">
                                                    {{ config.radio.cumple }} </mat-radio-button>
                                                <mat-radio-button value="no_cumple" class="radios_check" (click)="$event.stopPropagation()" [checked]="false">
                                                    {{ config.radio.noCumple }} </mat-radio-button>
                                                <mat-radio-button value="null" class="radios_check" (click)="$event.stopPropagation()" [checked]="false" >
                                                    {{ config.na }}
                                                </mat-radio-button>
                                            </mat-radio-group>
                                            <!-- <p>Cumplimiento</p> -->
                                        </div>
                                        <div *ngIf="data.tipo_peso == config.pesoEnItems">
                                            <mat-radio-group aria-label="Select an option"
                                                formControlName="calificacion"
                                                (change)="cantidadDecimales();calificacionItem(indexModulo,iItem)">
                                                <mat-radio-button value="si" (click)="$event.stopPropagation()" [checked]="false">
                                                    {{ config.yes }}
                                                </mat-radio-button>
                                                <mat-radio-button value="no" (click)="$event.stopPropagation()" [checked]="false">
                                                    {{ config.no }}
                                                </mat-radio-button>
                                                <mat-radio-button value="null" (click)="$event.stopPropagation()" [checked]="false" >
                                                    {{ config.na }}
                                                </mat-radio-button>
                                                <mat-radio-button *ngIf="modulo.get('error_critico').value != '1' && data.aplica_para_cp === 1" value="cp" (click)="$event.stopPropagation()" [checked]="false" >
                                                    {{ config.cp }}
                                                </mat-radio-button>
                                            </mat-radio-group>
                                            
                                            <!-- <p>Peso items</p> -->

                                        </div>
                                        <div *ngIf="data.tipo_peso  == config.pesoEnModulos">
                                            <mat-radio-group aria-label="Select an option"
                                                formControlName="calificacion"
                                                (change)="cantidadDecimales();calificacionItem(indexModulo,iItem)">
                                                <mat-radio-button value="si" (click)="$event.stopPropagation()" [checked]="false">
                                                    {{ config.yes }}
                                                </mat-radio-button>
                                                <mat-radio-button value="no" (click)="$event.stopPropagation()" [checked]="false">
                                                    {{ config.no }}
                                                </mat-radio-button>
                                                <mat-radio-button value="null" (click)="$event.stopPropagation()" [checked]="false" >
                                                    {{ config.na }}
                                                </mat-radio-button>
                                            </mat-radio-group>
                                            <!-- <p>Peso modulos</p> -->
                                        </div>
                                       
                                    </mat-panel-description>
                                    <p class="peso" *ngIf="item.get('peso').value" fxFlex.gt-lg="14%"
                                        fxFlex.gt-md="20%">{{ config.peso.titulo }}: {{item.get('peso').value |number :'1.0-3'}}</p>
                                    </mat-expansion-panel-header>
                                        <mat-form-field class="col-12" appearance="fill"  *ngIf="data.comment_apply">
                                                    <mat-label>{{config.comentario}}</mat-label>
                                                    <textarea type="number" matInput (change)="cantidadDecimales();calificacionItem(indexModulo,iItem)" formControlName="comments" minlength="{{data.comment_size}}" maxlength="9999"></textarea>
                                                    <mat-error *ngIf="item.get('comments').hasError('required')">{{ config.labels.requiredObservation }}</mat-error>
                                                    <mat-error *ngIf="item.get('comments').hasError('minlength')">{{ config.labels.minCharacters }}</mat-error>
                                        </mat-form-field>
                                <div formArrayName="sub_items"
                                    *ngFor="let subtItem of item.get('sub_items').controls; let iSub = index"
                                    fxLayout="row">
                                    <div [formGroupName]="iSub">
                                        <mat-expansion-panel [togglePosition]="'before'">
                                            <mat-expansion-panel-header class="mt-3">
                                                <mat-panel-title fxFlex.gt-lg="40%" fxFlex.gt-md="34%">
                                                    <p class="sigla-sub ms-4"
                                                    [ngClass]="{'sigla-disabled': (item.get('aspectosToltip').value === null || (item.get('calificacion').value === 'cumple' || item.get('calificacion').value === 'si'))}">
                                                        {{subtItem.get('sigla').value}}</p>
                                                    <button mat-icon-button
                                                        [disabled]="!subtItem.get('aspectosToltip').value">
                                                        <mat-icon style="font-size: 1.3em; margin-top: -.7em;"
                                                            [matTooltip]="subtItem.get('aspectosToltip').value"
                                                            matTooltipClass="multi-line-tooltip"
                                                            [matTooltipPosition]="'above'"><i
                                                                class="fi-rr-document"></i></mat-icon>
                                                    </button>
                                                    <ng-container *ngIf="screen_md; else screenLG">
                                                        <p class="sub-item" [matTooltip]="subtItem.get('nombre').value"
                                                            [matTooltipPosition]="'above'">
                                                            {{subtItem.get('nombre').value | cutText:0:3}}</p>
                                                    </ng-container>
                                                    <ng-template #screenLG>
                                                        <p class="sub-item" [matTooltip]="subtItem.get('nombre').value"
                                                            [matTooltipPosition]="'above'">
                                                            {{subtItem.get('nombre').value | cutText:0:10}}</p>
                                                    </ng-template>
                                                </mat-panel-title>
                                                <mat-panel-description>
                                                    <mat-radio-group aria-label="Select an option"
                                                        (change)="cantidadDecimales();calificacionSubItem(indexModulo,iItem,iSub)"
                                                        formControlName="calificacion">
                                                        <mat-radio-button value="cumple"
                                                            (click)="$event.stopPropagation()" [checked]="false" >
                                                            {{ config.radio.cumple }}
                                                        </mat-radio-button>
                                                        <mat-radio-button value="no_cumple"
                                                            (click)="$event.stopPropagation()" [checked]="false">
                                                            {{ config.radio.noCumple }}
                                                        </mat-radio-button>
                                                        <mat-radio-button value="null"
                                                            (click)="$event.stopPropagation()" [checked]="true" >
                                                            {{ config.radio.na }}
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                                <mat-form-field class="col-12" appearance="fill" *ngIf="data.comment_apply" >
                                                        <mat-label>{{config.comentario}}</mat-label>
                                                        <textarea type="number" matInput (change)="cantidadDecimales();calificacionSubItem(indexModulo,iItem,iSub)" formControlName="comments" minlength="{{data.comment_size}}" maxlength="9999"></textarea>
                                                        <mat-error *ngIf="subtItem.get('comments').hasError('required')">{{ config.labels.requiredObservation }}</mat-error>
                                                        <mat-error *ngIf="subtItem.get('comments').hasError('minlength')">{{ config.labels.minCharacters }}</mat-error>
                                                </mat-form-field>
                                            
                                        </mat-expansion-panel>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </div>
                    </div>
                    <ng-container>
                    </ng-container>
                </mat-expansion-panel>
            </div>
        </div>
    </div>
</mat-card>
