import { Injectable } from "@angular/core";
import { IMqttMessage, MqttConnectionState, MqttService } from 'ngx-mqtt';
import { Observable, Subscription, of } from "rxjs";
import { MQTT_SERVICE_OPTIONS } from "./config/mqtt.config";
import { QoS } from 'mqtt-packet'
import { environment } from 'src/environments/environment';
import { map } from "rxjs/operators";
import { NotificacitonMqtt } from "./interfaces/mqtt.response.interface";
import { AuthService } from "src/app/core/services/rest/auth.service";


@Injectable({
    providedIn: 'root'
})
export class MqttSoulService {

    /**
     * QoS = Quality of Service
     */
    
    /**
     * 0 = Maximo una vez, se entrega el mensaje maximo una vez o no se entrega (Se peude dar perdida de informacion si el cliente esta desconectado o el servidor no logra dar conexcion)
     * 1 = El mensaje se entrega solo 1 vez, El mensaje Siempre se entrega como minimo una vez encaso de no tener conexcion se queda en cola hasta que se de como recibido
     * 2 = El mesaje siempre se envia y se entrega, Envio de mensje mas seguro pero mas lento
     */
    private qoS:QoS = 1

    private mqttConnectionSub!: Subscription;
    private subs:Subscription[]=[];
    private chanelName:string = "/notification/"
    private chanelLeads:String= "notification-lead"

    private stateConnection;

    constructor(
        private ngxMqttService: MqttService,
        private authService: AuthService,

    ){
        if(this.stateConnection !== 1 && this.stateConnection !== 2  ){
            this.connectMqtt()
        }
        
    }

    /**
     * Metodo que se encarga de asignar el Qos por el cual se emitiran las notificaciones MQTT
     * @param qos:QoS {QoS} 0|1|2 tipo de QoS a enviar
     */
    set setQoS(qos:QoS){
        this.qoS = qos
    }

    /**
     * Metodo que permite cambiar el canal por defecto al cual se va a conectar
     */
    set setChanelName(chanelName:string){
        this.chanelName = chanelName
    }

    set setChanelLeads(chanelLeads:string){
        this.chanelLeads = chanelLeads
    }

    /**
     * Metodo que se encarga de estabalcer conexcion con el servidor Mqtt
     * @author Juan David Guerrero Vargas
     * @returns {Observable<MqttConnectionState>} Observable<MqttConnectionState>  Estado dela conexion
     */
    private  connectMqtt(){
        try {
            if (this.mqttConnectionSub && !this.mqttConnectionSub.closed) {
                this.mqttConnectionSub.unsubscribe();
                if(environment.production == false){
                    console.log("Desconectado");
                }
                return;
            }

            this.ngxMqttService.connect(MQTT_SERVICE_OPTIONS)
            this.mqttConnectionSub =   this.ngxMqttService.state.subscribe(
                (mqttState:MqttConnectionState)=>{
                    switch (mqttState) {
                        case MqttConnectionState.CLOSED:
                            if(environment.production == false){
                                console.log("Estado Mqtt: Conexion Cerrada, reintentando...");
                            }
                            this.stateConnection = 0
                            break;
                          case MqttConnectionState.CONNECTING:
                            if(environment.production == false){
                                console.log("Estado Mqtt: Conectando...");
                            }
                            this.stateConnection = 1
                            break;
                          case MqttConnectionState.CONNECTED:
                            if(environment.production == false){
                                console.log("Estado Mqtt: Conectado");
                            }
                            this.stateConnection = 2
                            break;
                    }
                }
            );
            this.subs.push(this.mqttConnectionSub)  
        } catch (error) {
            console.error("Error en conexion Servidor notificaciones", error);
        }
        
    }

    /**
     * Metodo que se encarga de obtener cual sera el canal a escuchar del servidor MQTT
     * @author Juan David Guerrero Vargas
     * @param channelName:string {string} Canal a escuchar el servidor Mqtt
     * @returns {Observable<IMqttMessage>} Observable<IMqttMessage> 
     */
    public getChannel(channelName:string): Observable<IMqttMessage>{
        return this.ngxMqttService.observe(channelName)
    }

    /**
     * Metodo que se encarga de obtener la subscripcion de mensajes del MQTT
     * @author Juan David Guerrero Vargas
     * @param chanelName:string {string} i del canar del usuario a consultar o nombre en especifico del canal
     * @returns {Observable<IMqttMessage>} Mensaje emitido por MQTT
     */
    public getSubcriptionChannel():Observable<NotificacitonMqtt|null>{
        return this.getChannel(`${this.chanelName}${this.authService.getUser().rrhh_id}` )
        .pipe(
            map( (message:IMqttMessage) => message.payload ? new TextDecoder('utf-8').decode(message.payload) : "" ),
            map( (msg:string) =>  msg ? JSON.parse(msg) as NotificacitonMqtt : null ) ,
        )    
    }

    /**
     * Metodo que se encarga de obtener las notificaciones Leads
     * @author Juan David Guerrero Vargas
     * @returns {Observable<NotificacitonMqtt|null>} Observable<NotificacitonMqtt|null>
     */
    public getChannelNotificacionsLeads():Observable<NotificacitonMqtt|null>{
        return this.getChannel(`${this.chanelLeads}` )
        .pipe(
            map( (message:IMqttMessage) => message.payload ? new TextDecoder('utf-8').decode(message.payload) : "" ),
            map( (msg:string) =>  msg ? JSON.parse(msg) as NotificacitonMqtt : null ) ,
        ) 
    }


    /**
     * Metodo que se encarga de enviar un mensaje a un canla en especifico del servidor Mqtt
     * @author Juan David Guerrero Vargas
     * @param channelName:string {string}
     * @param message:any {any}
     */
    public sendMessage(channelName:string, message:any):void{
        this.ngxMqttService.unsafePublish(`${channelName}`, `${message}`,{qos:this.qoS, retain:true})
    }

    /**
     * Metodo que se encarga de agregar la subcripcion del canal par asu eliminacion
     * @author Juan David Guerrero Vargas
     * @param newSub:Subscription {Subscription} subscripcion aosicaida al canal
     */
    public addSubscriptions(newSub:Subscription):void{
        this.subs.push(newSub)
    }

    /**
     * Metodo que se encarga de eliminar las subscriciones 
     * @author Juan David Guerrero Vargas
     * @returns {Boolean} Boolean true en casod e eliminacion False en caso de error en limpieza
     */
    public deleteSubscriptions():boolean{
        try {
            if(this.subs.length > 0){
                this.subs.forEach((subcrip)=> subcrip.unsubscribe());
            }
            return true;
        } catch (error) {
            return false;
        }
    }

}