import { PermitModule } from './modules/permit/permit.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JuridicaComponent } from './juridica.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JuridicaRoutingModule } from './juridica-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { PermitBossModule } from './modules/permit-boss/permit-boss.module';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { TooltipModule } from 'ng2-tooltip-directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    JuridicaRoutingModule,
    SharedModule,
    MaterialModule,
    PermitModule,
    PermitBossModule,
    TooltipModule,
  ],
  declarations: [JuridicaComponent,]
})
export class JuridicaModule { }
