import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { socketService } from './index.service';

@Injectable({
  providedIn: 'root'
})
export class SocketActiveService {

  sockeActive = null;
  socketActiveSubject: Subject<socketService> = new Subject();
  constructor() { }

  setSocketActive(socket: socketService){
    this.sockeActive = socket;
    this.socketActiveSubject.next(socket);
  }

  getSocketActive() {
    return this.sockeActive;
  }
}
