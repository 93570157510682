<div fxLayout="column">
  <div fxFlex="100%" fxLayout="row">
    <div fxFlex="50%">
      <mat-form-field appearance="fill" style="width: 300px;">
        <mat-label>{{config.labels.filterByGroup}}</mat-label>
        <input type="text" matInput [(ngModel)]="filtroGrupo" (blur)="filtro()">
        <mat-icon (click)="filtro()" matSuffix><i class="fi-rr-search"></i></mat-icon>
      </mat-form-field>
    </div>
    <div fxFlex="50%" fxLayoutAlign="end none">
      <button mat-raised-button color="primary" class="btn-plus" (click)="dialogControl('crear')">
        {{config.labels.createGroup}}
        <mat-icon style="font-size: 14px;margin-top: .6em;"><i class="fi-rr-add"></i></mat-icon>
      </button>
    </div>
  </div>
  <div class="mat-elevation-z0 mt-2" fxFlex="100%">
    <table [dataSource]="dataSource" mat-table matSort>

      <!-- Columna con botones de acciones -->
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="detailGrupo(element.matriz.id)">
            <i class="fi-rr-eye"></i>
          </button>
          <button mat-icon-button (click)="deleteGrupoById(element.matriz.id)">
            <i class="fi-rr-trash"></i>
          </button>
        </td>
      </ng-container>

      <!-- Columna fecha auditoría -->
      <ng-container matColumnDef="fecha_creacion">
        <th mat-header-cell *matHeaderCellDef>{{config.labels.creationDate}}</th>
        <td mat-cell *matCellDef="let element">{{ element.matriz.created_at | date:'dd/MM/yyyy' }}</td>
      </ng-container>

      <!-- Columna Campaña -->
      <ng-container matColumnDef="nombre_grupo">
        <th mat-header-cell *matHeaderCellDef>{{config.labels.groupName}}</th>
        <td mat-cell *matCellDef="let element">{{ element.matriz.nombre }}</td>
      </ng-container>

      <!-- Columna cedula auditado -->
      <ng-container matColumnDef="campania">
        <th mat-header-cell *matHeaderCellDef>{{config.labels.campaign}}</th>
        <td mat-cell *matCellDef="let element">{{ campania.name }}</td>
      </ng-container>

      <!-- Columna nombre auditado -->
      <ng-container matColumnDef="supervisor">
        <th mat-header-cell *matHeaderCellDef>{{config.labels.supervisor}}</th>
        <td mat-cell *matCellDef="let element">{{ element.matriz.nombre_supervisor }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{config.labels.noRecords}}</td>
      </tr>
    </table>

    <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </div>
</div>
