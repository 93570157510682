import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../../../environments/environment';
import { EndPointSelector } from '../../../configs/cartera-settings.config';
import { Router } from '@angular/router';

// Models

@Injectable({
	providedIn: ReportsService
})
export class ReportsService {
	ENDPOINT:string;
	PROMISE:string;
	PAY:string;
	constructor(
		private http: HttpClient,
		router: Router) {
		this.ENDPOINT = EndPointSelector.getSelector(router);
		this.PROMISE = this.ENDPOINT + 'promise/';
		this.PAY = `${this.ENDPOINT}pay/`
	}

	handleError(error: HttpErrorResponse): Observable<any> {
		return throwError(error);
	}


    getPropsalsOutOfPolitics(per_page: number, from_page: number, creation_time: string = "", start_date: string = "", end_date: string = ""){
     return this.http.post<any>(this.PROMISE + 'showPropuestasFueraPoliticas', {per_page, from_page, creation_time, start_date, end_date}).pipe(catchError(this.handleError));
    }

	downloadPropsals(creation_time: string = "", start_date: string = "", end_date: string = ""){
		const httpOptions = {
			responseType: 'blob' as 'json'
		};
		return this.http.post(this.PROMISE + `downloadPromises`,{start_date, end_date,creation_time}, httpOptions).pipe(catchError(this.handleError));

	}

	getAudits(per_page: number, from_page: number, start_date: string = "", end_date: string = ""){
		return this.http.post<any>(this.PROMISE + 'showAuditPromises', {per_page, from_page, start_date, end_date}).pipe(catchError(this.handleError));
	   }

	/**
	 * @author Duvan Ramirez
	 * @createdate 2023-08-24
	 * Metodo que consume la api para la data de pagos
	 * @param data
	*/
	getDataPayments(data: object): Observable<any> {
		return this.http.post(`${this.PAY}report/indexPays`, data)
		.pipe(catchError(this.handleError))
	}

	/**
	 * @author Duvan Ramirez
	 * @createdate 2023-08-24
	 * Metodo que consume la api para descargar el excel de pagos
	 * @param data
	*/
	downloadExcelPayments(data: any): Observable<any>{
		const httOptions = {
			responseType: 'blob' as 'json',
			params: data
		}
		return this.http.get(`${this.PAY}report/donwloadExcel`, httOptions)
		.pipe(catchError(this.handleError))
	}

	/**
	 * @author Duvan Ramirez
	 * @createdate 2024-02-07
	 * Metodo que consume la api para descargar el excel reporte de gestion de los asesores
	 * @param date_range
	*/
	downloadAdviserManagement(date_range: object): Observable<any>{
		return this.http.post<any>(`${this.ENDPOINT}management/exportAdviserManagement/export`, date_range, {responseType: 'blob' as 'json'}).pipe(catchError(this.handleError))
	}
}
