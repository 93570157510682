<!--<app-landing-page></app-landing-page>-->

<div class="row col-12">
    <div class="box-text col-5">
        <h4 class="title">Jurídico</h4>
        <h1 class="welcome">¡Bienvenid@ <br> {{nameUser}}! </h1>
        <p class="text">Este es el módulo de <b style="color: #000000;">Jurídico</b>, aquí podrás <br> hacer todas las
            gestiones respecto a los <br> procesos de contratación.</p>
        <button mat-raised-button class="btn-juridica">Ver funcionamiento del módulo</button>
    </div>

    <div class="box-image col-7">
        <img src="../../../assets/images/Ilustración_Juridico.svg" alt="Ilustración_Juridico" style="padding: 34px;">
    </div>

</div>

<!--SECCION DE NOTICIAS-->
    <div class="row col-12" style="padding: 0px 64px 0px 64px;">
        <h3 class="title-new"><b>Noticias</b></h3>
        <div class="col-3">
            <mat-card class="card">
                <mat-card-title></mat-card-title>
                <mat-card-subtitle></mat-card-subtitle>
                <mat-card-content>
                  <p></p>
                </mat-card-content>
                <mat-card-actions>
                  <button mat-button></button>
                  <button mat-button></button>
                </mat-card-actions>
              </mat-card>
        </div>
        <div class="col-3">
            <mat-card class="card">
                <mat-card-title></mat-card-title>
                <mat-card-subtitle></mat-card-subtitle>
                <mat-card-content>
                  <p></p>
                </mat-card-content>
                <mat-card-actions>
                  <button mat-button></button>
                  <button mat-button></button>
                </mat-card-actions>
              </mat-card>
        </div>
        <div class="col-3">
            <mat-card class="card">
                <mat-card-title></mat-card-title>
                <mat-card-subtitle></mat-card-subtitle>
                <mat-card-content>
                  <p></p>
                </mat-card-content>
                <mat-card-actions>
                  <button mat-button></button>
                  <button mat-button></button>
                </mat-card-actions>
              </mat-card>
        </div>
        <div class="col-3">
            <mat-card class="card">
                <mat-card-title></mat-card-title>
                <mat-card-subtitle></mat-card-subtitle>
                <mat-card-content>
                  <p></p>
                </mat-card-content>
                <mat-card-actions>
                  <button mat-button></button>
                  <button mat-button></button>
                </mat-card-actions>
              </mat-card>
        </div>
    </div>