import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NoveltiesService } from 'src/app/modules/nomina/services/novelties.service';

@Component({
  selector: 'app-resum-nomina',
  templateUrl: './resum-nomina.component.html',
  styleUrls: [ './resum-nomina.component.sass' ]
})
export class ResumNominaComponent implements OnInit {
  novelties: number[] = [];
  currentDate: any;
  constructor(
    private noveltiesservice: NoveltiesService
  ) {
    this.noveltiesservice.geValuesNomina().subscribe((resp: any) => {
      this.novelties = resp.data;
    });
    
  }

  ngOnInit(): void {
    this.currentDate = moment().locale('es').format('MMMM, YYYY');
  }

  /**
   * @author Daniel Dominguez <DANIEL.D@montechelo.com.co>
   * @purposeenvia valores de nomina
   */
  getValuesTypes(): void {
    this.noveltiesservice.geValuesNomina();
  }
}
