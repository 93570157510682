import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, AfterViewInit, AfterContentInit, AfterContentChecked, AfterViewChecked, OnChanges, SimpleChanges, DoCheck } from '@angular/core';
import { FormArray, FormArrayName, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataAuditoria, ItemModel, ModuloModel } from 'src/app/modules/auditoria/interfaces/dataAuditoria.interface';
import { AuditoriaService } from 'src/app/modules/auditoria/services/auditoria.service';
import { FormAuditarComponent } from "../form-auditar/form-auditar.component";

@Component({
	selector: 'app-calificacion-auditoria',
	templateUrl: './calificacion-auditoria.component.html',
	styleUrls: ['./calificacion-auditoria.component.sass']
})
export class CalificacionAuditoriaComponent implements OnInit, OnDestroy {

	@Input() data: DataAuditoria;
	@Output() calificacionModulos = new EventEmitter();

	//Variables para el calcular la calificación
	num_items = 0;
	cumple = 0;
	no_cumple = 0;
	peso_modulo = 0;
	itemSi = 0;
	itemNo = 0;
	pesoItems = 0;
	is_critico = 0;
	items_na = 0;
	decimales: boolean = false;
	screen_md: boolean = false;
	@Input() tipo_auditar = { interna: 0, cliente: 0 };

	calificacion: number = 0;
	readyCalificacion: boolean = false;
	moduloForm: FormGroup;
	conteo = 0;
	withRadio = '';


	puntaje_total           = 100;
	puntaje_cumplimiento    = 100;
	puntaje_no_critico      = 100;
	puntaje_critico_usuario = 100;
	puntaje_critico_negocio = 100;
	puntaje_precision       = 100;
	puntajes = "";
	
	total_si                = [];
	total_no                = [];


	//Observable para los cambios en los servicios de auditoria
	subscription: Subscription;
	constructor(
		private fb: FormBuilder,
		private auditoriaService: AuditoriaService,
	) { }

	ngOnInit(): void {
		this.createForm();
		this.verModulos();
		this.cantidadDecimales();
		this.subscription = this.auditoriaService.refresh$.subscribe(() => {
			this.verModulos();
			this.cantidadDecimales();
			this.tipo_auditar = { interna: 0, cliente: 0 };
		});

	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	/**
	* @author Cristian Gallo
	* @createdate 2022-02-24
	* Metodo que se encarga habilitar y desabilidar los sub items dependiendo la calificación que se de en el item
	*/
	calificacionItem(iModulo, iItem) {
		let item_calificado = ((this.moduloForm.get('modulos') as FormArray).at(iModulo).get('items_modulo') as FormArray).at(iItem).get('calificacion').value;
		let subItems = (((this.moduloForm.get('modulos') as FormArray).at(iModulo).get('items_modulo') as FormArray).at(iItem).get('sub_items') as FormArray);
		if (item_calificado == 'no_cumple' || item_calificado == 'no') {
			subItems.controls.forEach((subItem: FormGroup) => {
				subItem.controls['calificacion'].setValue('no_cumple');
				subItem.controls['calificacion'].enable();
			});
		} else {
			subItems.controls.forEach((subItem: FormGroup) => {
				subItem.controls['calificacion'].setValue('');
				subItem.controls['calificacion'].disable();
			});
		}
		//this.calificacionModulos.emit({ modulos: this.moduloForm.get('modulos').value, calificacion: this.calculateCalificacion() });
		this.calificacionModulos.emit({ modulos: this.moduloForm.get('modulos').value, calificacion: this.calculateCalificacion() });
	}

	/**
	* @author Cristian Gallo
	* @createdate 2022-03-07
	* Metodo que se encarga validar cuando los sub items se califican como cumple o N/A
	* cambiando la calificación del item a cumple, si o N/A si es requerido
	*/

	calificacionSubItem(iModulo, iItem, iSubItem) {
		let cantidadCumple = 0;
		let cantidadNA = 0;
		let cantidadNoCumple = 0;
		let subItem = ((this.moduloForm.get('modulos') as FormArray).at(iModulo).get('items_modulo') as FormArray).at(iSubItem);
		let item = ((this.moduloForm.get('modulos') as FormArray).at(iModulo).get('items_modulo') as FormArray).at(iItem);
		const sub_items = item.get('sub_items') as FormArray;
		sub_items.controls.forEach((subItem: FormGroup) => {
			if (subItem.get('calificacion').value == 'cumple') {
				cantidadCumple++
			}
			if (subItem.get('calificacion').value === null) {
				cantidadNA++
			}
			if (subItem.get('calificacion').value === 'no_cumple') {
				cantidadNoCumple++
			}
		});
		if (cantidadCumple == sub_items.length) {
			if (this.data.tipo_matriz == 'Cumplimiento') {
				item.get('calificacion').setValue('cumple');
			} else {
				item.get('calificacion').setValue('si');
			}
			sub_items.controls.forEach((subItem: FormGroup) => {
				subItem.get('calificacion').setValue('');
				subItem.get('calificacion').disable();
				subItem.markAllAsTouched();
			});
			item.get('calificacion').setValue('si');
		}
		if (cantidadNA == sub_items.length) {
			item.get('calificacion').setValue(null);
			sub_items.controls.forEach((subItem: FormGroup) => {
				subItem.get('calificacion').setValue('');
				subItem.get('calificacion').disable();
				subItem.markAllAsTouched();
			});
		}
		if (cantidadNoCumple == 0) {
			if (this.data.tipo_matriz == 'Cumplimiento') {
				item.get('calificacion').setValue('cumple');
			} else {
				item.get('calificacion').setValue('si');
			}
			sub_items.controls.forEach((subItem: FormGroup) => {
				subItem.get('calificacion').setValue('');
				subItem.get('calificacion').disable();
				subItem.markAllAsTouched();
			});
		}
		this.calificacionModulos.emit({ modulos: this.moduloForm.get('modulos').value, calificacion: this.calculateCalificacion() });
	}

	/**
	 * Metodo que se encarga de calcular la calificacion total de del los modulos
	 * @author Juan David Guerrero Vargas
	 * @create_date 05/07/2022    
	 * @returns number : numero con el total de la clificaicon para los modulos
	 */
	calculateCalificacion() {
		//variable que contiene el total de la calificacion
		this.calificacion = 0;
		//Variable que indica si se debe alnular toda la clificacipn o no (esto depende de si el el modulo es crtico total)
		let anulaTotal: boolean = false;

		//Se realiza validacion para determinar como se calculara la nota
		if (this.data.tipo_matriz == 'Cumplimiento') {
			let pesoCriticoRest = 0;//Peso de modulos criticos parciales a restar dentro de la nota
			let no_cumpleCritico: number = 0; //contador de items que no cumplen dentro de los modulos critocs
			let sumatoria = 0; //Variable que contendra la sumtarios de los modulos y servira de variable de opraciones apra los calculos
			/****************************************************
			Para el calculo de la calificacion por modulo se determina esta formula por el cliente 
			En este escenario los items N/A no forman parte de la calificaicon por lo 2ue no se tienen encuanta para el calculo
			itemsCumple + itemsNocumple = totalRegistrosEvaluados
			itemsCumple / totalRegistrosEvaluados = porcentajeCumplimientoModulo
			porcentajeCumplimientoModulo * PesoModulo = Calificacion del modulo
			****************************************************/
			let cumple=0 //items que cumplen dentro del modulo
			let no_cumple = 0 //items que no cumplen  
			//Se realiza itracion por cada modulo de la matriz
			this.moduloForm.get('modulos').value.forEach(modulo => {
				cumple=0
				no_cumple=0
				//Si el modulo no es de Error critico se asigna el peso para la sumatoria
				if (modulo.error_critico == 0) {
					this.peso_modulo = modulo.peso;
				} else {//En caso de que el modulo sea critico este no afecta la sumatoria total de la nota por lo que se deja en 0
					this.peso_modulo = 0;
				}
				//iteramos los items de cada modulo
				modulo.items_modulo.forEach(item => {
					Object.assign(item, { puntaje: 0 });//Se le asigna atributo puntaje para registro
					//Se realiza la validacion del estado de cada item dentro de los modulos
					if (item.calificacion == 'cumple') {
						cumple++
					} else if (item.calificacion == 'no_cumple') {
						no_cumple++
						
					}//Si no se registra calificaicon como (cumple - no cumple) este no se debe tener en cuenta para el calculo de l total del modulo
					
					//Se asigna el puntaje a cada item
					modulo.items_modulo.forEach(item => {
						if (item.calificacion === null) {
							if (item.calificacion == 'cumple') {
								item.puntaje = this.peso_modulo / (cumple + no_cumple);
							} else if (item.calificacion == 'no_cumple') {
								item.puntaje = 0;
							} else {
								item.puntaje = 0;
							}
						}
					});
	

					//Validamos si el modulo es de error critico (error_critico = 1)
					//Si el modulo.peso es igual a null se asume que es un critico total, es decir dejara la nota en 0 sin importar cuantos items con cumple tenga
					//Se valida que almenos un item dentro del modulo critico total no cumpla para que se pueda anular la calificacion
					if (modulo.error_critico == 1 && (modulo.peso === null || modulo.peso === "" || modulo.peso == undefined) && item.calificacion == 'no_cumple') {
						anulaTotal = true;
					}
					//Validamos si es error critico pero si se registra algun valor en modulo.peso se asume que es un critico parcial, y envez de afectar toda la nota solo afectara el valor del modulo al que pertenece
					if (modulo.error_critico == 1 && modulo.peso && item.calificacion == 'no_cumple') {
						no_cumpleCritico++;//Se realiza conteo de item que no cumplan dentro del modulo critico parcial

					}

				});//FINALIZA ITERACION DE ITEMS DEL MODULO

				//Si el modulo es critico parcial, y almenos un item del modulo parcial no cumple, se se asigna a la variable el valor a restar por el modulo
				if (modulo.error_critico == 1 && modulo.peso && no_cumpleCritico > 0) {
					pesoCriticoRest = pesoCriticoRest + modulo.peso;
				}
				//Se seta en 0 el contador para el conteno del siguiente modulo
				no_cumpleCritico = 0;

				//Se realiza el calculo de la nota segun comentario inicial del metodo
				if((cumple > 0 || no_cumple > 0) &&  modulo.error_critico == 0){
					let totalRegistrosEvaluados = cumple + no_cumple
					let porcentajeCumplimientoModulo = cumple / totalRegistrosEvaluados
					sumatoria += porcentajeCumplimientoModulo * this.peso_modulo 
				}
			}
			);//FINALIZA ITERACION DE MODULOS
			//Se asigna el valor de la variable sumatoria a la variabe de calificaion asumiendo que esta variab solo tiene la sumatoria de los calculos por modulo y no se la a hecho los descuentos por modulos criticos
			this.calificacion = sumatoria

			//Si se registro algun peso de los modulos criticos parciales, se hace la resta de ese modulo dentro de la clificacion
			if (pesoCriticoRest > 0) {
				this.calificacion = this.calificacion - pesoCriticoRest
			}

			//Si un modulo critico total tiene un item con no_cumple y anula_total queda en true, la califiacion se anula por lo que queda en 0
			if (anulaTotal) {
				this.calificacion = 0;
			}
			
			//Ninguna calificaicon puede ser menor de 0
			if (this.calificacion <= 0) {
				this.calificacion = 0;
			}

		} else {
			if (this.data.tipo_peso == 'Peso en Módulos') {
				let pesoCriticoRest = 0;
				let no_cumpleCritico: number = 0;
				let sumatoria=0;
				this.moduloForm.get('modulos').value.forEach(modulo => {

					if (modulo.error_critico == 0) {
						this.peso_modulo = modulo.peso;
					} else {
						this.peso_modulo = 0;
					}
					modulo.items_modulo.forEach(item => {
						if (item.calificacion) {
							Object.assign(item, { puntaje: 0 });
							if (item.calificacion == 'si') {
								this.itemSi++;
							} else if (item.calificacion == 'no') {
								this.itemNo++;
							}

							if (modulo.error_critico == 1 && (modulo.peso === null || modulo.peso === "" || modulo.peso == undefined) && item.calificacion == 'no') {
								anulaTotal = true;
							}
							if (modulo.error_critico == 1 && modulo.peso && item.calificacion == 'no') {
								no_cumpleCritico++;
							}


						}
					});

					if (modulo.error_critico == 1 && modulo.peso && no_cumpleCritico > 0) {
						pesoCriticoRest = pesoCriticoRest +  Number(modulo.peso);
					}

					modulo.items_modulo.forEach(item => {
						if (item.calificacion === null) {
							if (item.calificacion == 'si') {
								item.puntaje = this.peso_modulo / this.num_items;
							} else if (item.calificacion == 'no') {
								item.puntaje = 0;
							} else {
								item.puntaje = 0;
								this.items_na++;
							}
						}
					});

					if(this.itemSi === modulo.items_modulo.length ){						
						sumatoria = sumatoria + this.peso_modulo
					}else if(this.itemNo > 0){
						sumatoria = sumatoria + 0
					}else if(this.items_na > 0){
						sumatoria = sumatoria + this.peso_modulo
					}

					
					this.itemSi = 0;
					this.itemNo = 0;
					this.items_na = 0;
					no_cumpleCritico = 0;
				}
				);

				this.calificacion = sumatoria
				
				
				if (pesoCriticoRest > 0) {
					this.calificacion = this.calificacion - pesoCriticoRest
				}

				if (this.calificacion <= 0) {
					this.calificacion = 0;
				}
			} else if (this.data.tipo_peso == 'Peso en Items') {
				let pesoCriticoRest = 0;
				let pesoCriticoSum = 0;
				this.moduloForm.get('modulos').value.forEach(modulo => {
					modulo.items_modulo.forEach(item => {
						if (item.calificacion) {
							Object.assign(item, { puntaje: 0 });
							if (item.calificacion == 'si') {
								this.pesoItems = this.pesoItems + item.peso;
								item.puntaje = item.peso;
							} else if (item.calificacion == 'no') {
								this.pesoItems = this.pesoItems + 0;
								item.puntaje = 0;
							} else {
								item.puntaje = 0;
							}


							if (modulo.error_critico == 1 && (modulo.peso === null || modulo.peso === "" || modulo.peso == undefined) && item.calificacion == 'no') {
								anulaTotal = true;
							}

							if (modulo.error_critico == 1 && modulo.peso && item.calificacion == 'no') {
								let sizeItems = modulo.items_modulo.length
								pesoCriticoRest = pesoCriticoRest + (Number(modulo.peso) / sizeItems)

							}


						}
						if (item.calificacion === null) {
							this.pesoItems = this.pesoItems + item.peso;
							item.puntaje = 0;
						}
						this.calificacion = this.calificacion + this.pesoItems;


						this.pesoItems = 0;
						this.items_na = 0;
					}
					);

				});

				if (pesoCriticoRest > 0) {
					this.calificacion = this.calificacion - pesoCriticoRest;
				}

				if (this.calificacion <= 0) {
					this.calificacion = 0;
				}

			}

			if (anulaTotal) {
				this.calificacion = 0;
			}



		}
		return this.calificacion;

	}

	cantidadDecimales() {
		let calificacion: string = this.calculateCalificacion().valueOf().toLocaleString();
		let cantidadDecimales = 0;
		if (calificacion.indexOf(',') >= 2 && calificacion.indexOf(',') <= 3) {
			for (let index = calificacion.indexOf(',') + 1; index < calificacion.length; index++) {
				cantidadDecimales++
			}
		}
		if (cantidadDecimales > 1) {
			this.decimales = true;
			if (window.screen.width > 1060 && window.screen.width < 1660) {
				this.screen_md = true;
			} else {
				this.screen_md = false;
			}
		} else {
			if (window.screen.width > 1060 && window.screen.width < 1660) {
				this.screen_md = true;
			} else {
				this.screen_md = false;
			}
		}
	}

	createForm() {
		this.moduloForm = this.fb.group({
			modulos: this.fb.array([]),
		});
	}

	/**
	* @author Cristian Gallo
	* @createdate 2022-02-24
	* Metodo que se encarga de llenar los modulos, items y subitems con sus respectivos formcontrol
	* agregando el valor en la calificación en si o cumple
	*/
	verModulos() {
		const modulos = <FormArray>this.moduloForm.controls['modulos'];
		modulos.clear();
		this.data.modulos.forEach((modulo: ModuloModel, iModule) => {
			modulo.items_modulo.forEach(item => {
				if (this.data.tipo_matriz == 'Cumplimiento') {
					Object.assign(item, { calificacion: 'cumple' });
				} else {
					Object.assign(item, { calificacion: 'si' });
				}
				item.sub_items.forEach(sub_item => {
					Object.assign(sub_item, { calificacion: { value: '', disabled: true } });
				});
			});
			modulos.push(this.fb.group({
				created_at: modulo.created_at,
				descripcion: modulo.descripcion,
				error_critico: modulo.error_critico,
				id: modulo.id,
				items_modulo: this.fb.array([]),
				matriz_id: modulo.matriz_id,
				nivel: modulo.nivel,
				nombre: modulo.nombre,
				padre_id: modulo.padre_id,
				peso: modulo.peso,
				premium: modulo.premium,
				sigla: modulo.sigla,
				type_select: modulo.type_select,
				updated_at: modulo.updated_at,
				usuario_actualizacion_id: modulo.usuario_actualizacion_id,
				usuario_creador_id: modulo.usuario_creador_id,
				activo: modulo.activo,
			}));
			modulo.items_modulo.forEach((item: ItemModel) => {
				(this.getModulos().at(iModule).get('items_modulo') as FormArray).push(this.fb.group({
					calificacion: item.calificacion,
					puntaje: item.puntaje,
					activo: item.activo,
					descripcion: item.descripcion,
					error_critico: item.error_critico,
					id: item.id,
					matriz_id: item.matriz_id,
					nivel: item.nivel,
					nombre: item.nombre,
					padre_id: item.padre_id,
					peso: item.peso,
					premium: item.premium,
					sigla: item.sigla,
					sub_items: this.fb.array(item.sub_items.map(subItem => this.fb.group(subItem))),
					type_select: item.type_select,
					updated_at: item.updated_at,
					usuario_actualizacion_id: item.usuario_actualizacion_id,
					usuario_creador_id: item.usuario_creador_id,
					created_at: item.created_at,
				}));
			});
		});
		this.calificacionModulos.emit({ modulos: this.moduloForm.get('modulos').value, calificacion: this.calculateCalificacion() });
	}
	/**
	* @author Cristian Gallo
	* @createdate 2022-02-24
	* Metodo que retorna los modulos con sus controls
	*/
	getModulos(): FormArray {
		return this.moduloForm.get('modulos') as FormArray;
	}

	/**
  * @author Cristian Gallo
  * @createdate 2022-02-24
  * Metodo que retorna los items con sus controls
  */
	getItems(indexModulo: number): FormArray {
		return this.getModulos().at(indexModulo).get('items_modulo') as FormArray;
	}

    
    /**
	* @author Fabio Garcia
	* @createdate 2022-07-28
	* Metodo que retorna los totales
	*/

	calificar_totales(){

		//Matriz
	  	/*
	  	  3: ERROR NO CRITICO
	  	  6: ERROR CRITICO DE USUARIO FINAL
	  	  8: ERROR CRITICO DE NEGOCIO
	  	  9: ERROR CRITICO DE CUMPLIMIENTO

    
		*/



        let tn  = 0;
        let tn_no_critico  = 0;
        let tn_critico= 0;
        let ts = 0;
        let tna = 0;

        let tptn  = 0;
		let tpts = 0;
		let tptna = 0;
		let anulaTotal: boolean = false;

        

		const modulos = <FormArray>this.moduloForm.controls['modulos'];
		this.data.modulos.forEach((modulo: ModuloModel, iModule) => {
            
            //Recorremos las items
			let ptn  = 0; // pesos total no 
			let pts = 0; // pesos total si 
			let ptna = 0; // pesos total na 

			//Recorremos las items
			let n  = 0; // no
			let s = 0; // si 
			let na = 0; // na 

            //validamos el modulo
			let m = modulo.nombre.trim(); // nombre del modulo
			let peso_modulo = modulo.peso;
			let modulo_error_critico =  modulo.error_critico;

			if (isNaN(peso_modulo)) {
			   peso_modulo=0;
			}
			

            modulo.items_modulo.forEach((item: ItemModel) => {
            	
              let idb_1 = item.padre_id+'_'+ item.id+'_1-input'; // SI CUMPLE
              let idb_2 = item.padre_id+'_'+ item.id+'_2-input'; // NO / NO CUMPLE
              let idb_3 = item.padre_id+'_'+ item.id+'_3-input'; // NA

              let obs = item.padre_id+'_'+ item.id+'_div'; // OBJ
              let txt = item.padre_id+'_'+ item.id+'_obj'; // OBJ

              let peso = item.peso;
			  if (isNaN(peso)) {
				 peso=0;
			   }
                
                let ck1 = document.getElementById(idb_1) as HTMLInputElement;
                let ck2 = document.getElementById(idb_2) as HTMLInputElement;
                let div_obs = document.getElementById(obs) as HTMLInputElement;
                let div_txt = document.getElementById(txt) as HTMLInputElement;

                let panel_id = item.padre_id+'_'+ item.id+'_panel'; // PANEL
                let panel = document.getElementById(panel_id) as HTMLInputElement;




				if(m == "ERROR CRITICO DE USUARIO FINAL" || m=='ERROR CRITICO DE NEGOCIO' || m=='ERROR CRITICO DE CUMPLIMIENTO' || m=='ERROR NO CRITICO'){
						
					if (ck2.checked  == true){ // NO - NO CUMPLE
						tn = tn +1
						n = n +1
						ptn = ptn + peso;
						tptn = tptn + peso;

					   if(m == "ERROR CRITICO DE USUARIO FINAL" || m=='ERROR CRITICO DE NEGOCIO' || m=='ERROR CRITICO DE CUMPLIMIENTO'){
						
						tn_no_critico  = tn_no_critico+1;

						}else if(m=='ERROR NO CRITICO'){
						  tn_critico  = tn_critico+1;   
						}
                        
                        div_obs.style.display = "block";
                        div_txt.setAttribute("required", "true"); 
					}else{
                       div_obs.style.display = "none";
                       div_txt.removeAttribute("required"); 

					}
				}



            });
           
           
			switch (m) {
				case "ERROR NO CRITICO":
				 this.puntaje_no_critico = 100-ptn;
				break;

				case "ERROR CRITICO DE USUARIO FINAL":

				   if(ptn<=0){ // SI AL SUMAR SUS PUNTOS NO TIENE REGISTROS
				   	 if (n>0) { this.puntaje_critico_usuario=0; anulaTotal=true; } else { this.puntaje_critico_usuario=100; }				   	  
				   }else{
				   	 this.puntaje_critico_usuario = 100-ptn;		   	 
				   }
				 
				
				break;

				case "ERROR CRITICO DE NEGOCIO":

				  if(ptn<=0){
				   	  if (n>0) { this.puntaje_critico_negocio=0; anulaTotal=true; } else { this.puntaje_critico_negocio=100; }
				   }else{
				   	 this.puntaje_critico_negocio = 100-ptn;
				   }


				break;

				case "ERROR CRITICO DE CUMPLIMIENTO":

				   if(ptn<=0){
				   	  if (n>0) { this.puntaje_cumplimiento=0; anulaTotal=true; } else { this.puntaje_cumplimiento=100; }				   	  
				   }else{
				   	 this.puntaje_cumplimiento = 100-ptn;
				   }

				break;
			}


		});


		this.puntaje_total = 100 - tptn;
		if (anulaTotal) {
		  this.puntaje_total = 0;
		}
		if (tn_no_critico>0) { this.puntaje_precision=0; } else { this.puntaje_precision=100; }
		this.puntajes= this.puntaje_total+','+this.puntaje_cumplimiento+','+this.puntaje_no_critico+','+this.puntaje_critico_usuario+','+this.puntaje_critico_negocio+','+this.puntaje_precision;
        
         let puntajes = document.getElementById('puntajes') as HTMLInputElement;
         puntajes.value = this.puntajes;  
	}


	limpiar_error(p,h){
       alert(p);
	}

   

}
