<div class="landing">

    <div class="row" >
        <div class="col-6">
          <div class="landing-div-text">
            <br>
            <br>
            <br>
            <p class="landing-tittle">Cartera WOM</p>
            <br>
            <p class="landing-welcome">¡Bienvenid@<br> {{userName.rrhh.name | titlecase }}!</p>
            <br>
            <div class="col-8 mt-3">
              <p class="landing-sub-tittle">
                <!--Este es el módulo de <b> OJT </b>, donde podrás visualizar y realizar las gestiones diarias respecto a la asistencia, indicadores y retroalimentaciones. -->
              </p>
            </div>
            <br>
            <div style="width: 429px;">
                <button class="btnlanding" mat-stroked-button color="primary" (click)="onBoadingCalidad()" >Quiero ver el onboarding</button>
            </div>
            
          </div>
        </div>

        <div class="col-6" style="text-align: right;">
          <img src="../../../assets/images/landing-pages/ojt/landing-OJT.svg" style="height:90%;" alt="">
      </div>

    </div>
</div>
