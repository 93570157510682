import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CrmCialRoutingModule } from './crm-cial-routing.module';
import { ClientesComponent } from './modules/clientes/clientes.component';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { ClientesCrearComponent } from './modules/clientes-crear/clientes-crear.component';
import { ClienteConsultaComponent } from './modules/cliente-consulta/cliente-consulta.component';

import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from 'src/app/shared/shared.module';
import { CcomercialCreateComponent } from './modules/ccomercial-create/ccomercial-create.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import { ClientesProcesoComponent } from './modules/clientes/clientes-proceso/clientes-proceso.component';
import { ClientesSeguimientoComponent } from './modules/clientes/clientes-seguimiento/clientes-seguimiento.component';
import { CreopComponent } from './modules/creop/creop.component';
// import { OportunidadNuevaComponent } from './modules/cliente-consulta/oportunidad-nueva/oportunidad-nueva.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { HistorialnComponent } from './modules/historialn/historialn.component';
import { ByStepComponent } from './modules/notes/by-step/by-step.component';
import { UpdateNotesComponent } from './modules/notes/update-notes/update-notes.component';
import { NotasComponent } from './modules/creop/notas/notas.component';



@NgModule({
  declarations: [
    ClientesComponent,
    ClientesCrearComponent,
    ClienteConsultaComponent,
    CcomercialCreateComponent,
    ClientesProcesoComponent,
    ClientesSeguimientoComponent,
    CreopComponent,
    HistorialnComponent,
    ByStepComponent,
    UpdateNotesComponent,
    NotasComponent
  ],
  imports: [
    CommonModule,
    CrmCialRoutingModule,
    MaterialModule,
    MatTooltipModule,
    SharedModule,
    MatFormFieldModule,
    FlexLayoutModule
   ]
})

export class CrmCialModule { }
