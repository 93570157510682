import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ControlService } from 'src/app/modules/calidad/services/control.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import Swal from 'sweetalert2';
import { AgregarAgenteComponent } from '../agregar-agente/agregar-agente.component';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
  selector: 'app-tabla-grupo',
  templateUrl: './tabla-grupo.component.html',
  styleUrls: ['./tabla-grupo.component.sass']
})
export class TablaGrupoComponent implements OnInit, OnDestroy {
  private uuid = 'a42cd65c-9309-11ee-b9d1-0242ac120003';
  public config: any;

  @Input() idGrupo;
  dataSource = new MatTableDataSource();
  selection = new SelectionModel<any>(true, []);
  displayedColumns:string[] = ['acciones','id_agente','nombre_agente','numero_documento'];
  length;
  pageSize = 5;
  page = 1;
  pageSizeOptions = [5,10,25,50,100];
  @ViewChild(MatPaginator) paginator:MatPaginator;

  //Filtros
  filtroNombre:string = '';
  filtroCedula:string = '';

  //Observable para los cambios de la tabla
  subscription:Subscription;

  constructor(
    private dialog:MatDialog,
    private controlService:ControlService,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
    this.getAsesoresGrupo(this.page,this.pageSize);
    this.subscription = this.controlService.refresh$.subscribe(()=>{
      this.getAsesoresGrupo(this.page,this.pageSize);
      this.selection.clear();
    });
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  pageEvent(event:any){
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getAsesoresGrupo(this.page,this.pageSize);
  }

  addAgent(){
    const agenteRef = this.dialog.open(AgregarAgenteComponent,{
      width: '732px',
      disableClose: true,
      data:{
        idGrupo: this.idGrupo,
        agentes: this.dataSource.data,
      }
    });
  }

  getAsesoresGrupo(page,pageSize){
    const params = {
      nombre: '',
      cedula: '',
      idGrupo: this.idGrupo,
      numeroReg: pageSize,
      page
    }

    if (this.filtroNombre != '') {
      params.nombre = this.filtroNombre;
    }

    if (this.filtroCedula != '' && this.filtroCedula != null) {
      params.cedula = this.filtroCedula;
    }
    this.controlService.getAsesoresGrupo(params).subscribe((resp:any)=>{
      this.dataSource.data = resp.data;
      this.length = resp.total;
    })
  }

  filtro(){
    this.paginator.firstPage();
    this.getAsesoresGrupo(this.page,this.pageSize);
  }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      if (this.isAllSelected()) {
        this.selection.clear();
        return;
      }

      this.selection.select(...this.dataSource.data);
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: any): string {
      if (!row) {
        return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    deleteAsesores(){
      Swal.fire({
        position:'center',
        width:'866px',
        icon: 'warning' ,
        title: '¿Desea eliminar los asesores seleccionados?',
        text: '¿Deseas eliminar permanentemente los agentes seleccionados? Recuerda que esta acción será definitiva y no se podrá deshacer.',
        showCancelButton:true,
        reverseButtons:true,
        confirmButtonText:'Eliminar',
        cancelButtonText:'Cancelar',
      }).then(result=>{
          if (result.isConfirmed) {
            const data = {
             asesores: this.selection.selected.map(seleccion=>{
               return {id:seleccion.usuario_id}
             }),
             idGrupo: this.idGrupo,
            }
            this.controlService.deleteAsesorGrupo(data).subscribe((resp:any)=>{
            })
          }

        })
    }

}
