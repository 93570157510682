<div class="avatar row  admin-dialog">
  <div>
    <button style="float: right;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
  </div>

  <form class="row form " [formGroup]="solicitudesForm">

    <mat-dialog-content class="mat-typography">
      <h3 mat-dialog-title>
        {{ data.title }}

        <button type="button" style="float: right;" (click)="editSolicitud()"
          *ngIf="data.solicitud.request_state.id == 2 && !edit" mat-icon-button>
          <i class="fi-rr-edit"></i>
        </button>
      </h3>
      <hr>
      <div class="row">
        <div class="col-1">
          <button mat-icon-button class="badge " type="button"
            [style.backgroundColor]="data.solicitud.request_state.color">
            <i [class]=" data.solicitud.request_state.icon"> </i>

          </button>
        </div>
        <div class="col-4">

          <mat-label style="font-weight: bold; font-size: 14px">Estado actual</mat-label>
          <p>{{data.solicitud.request_state.name}}</p>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-4">

          <mat-label style="font-weight: bold; ">Fecha de creación</mat-label>
          <p>{{data.solicitud.created_at|date:'yyyy-MM-dd': 'UTC'}}</p>
        </div>
        <div class="col-4">

          <mat-label style="font-weight: bold;">Nombre del solicitante</mat-label>
          <p>{{data.solicitud.created_by}}</p>
        </div>
        <div class="col-4">

          <mat-label style="font-weight: bold;">Tipo de adquisición</mat-label>
          <p>{{data.solicitud.product_type.name}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-4">

          <mat-label style="font-weight: bold;">Categoría</mat-label>
          <p>{{data.solicitud.category.name}}</p>
        </div>


        <mat-form-field class="col-4" appearance="fill">
          <mat-label>Empresa</mat-label>
          <input type="text" matInput formControlName="companias" [matAutocomplete]="autoCom" required>
          <mat-autocomplete #autoCom="matAutocomplete" [displayWith]='displayFnCompania.bind(this)' required>
            <mat-option *ngFor="let comp of filteredOptionsCompania | async" [value]="comp.id">
              {{comp.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="error.companias.errors"><span *ngIf="error.companias.errors.required">¡Advertencia! Este
              campo es requerido</span></mat-error>
          <mat-error *ngIf="error.companias.errors"><span
              *ngIf="error.companias.errors.invalidAutocompleteObject">Campaña no reconocida. Haga click en una de las
              opciones de autocompletar</span></mat-error>
        </mat-form-field>

        <div class="col-4">

          <mat-label style="font-weight: bold;">Nombre campaña</mat-label>
          <p>{{data.solicitud.project}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-4">

          <mat-label style="font-weight: bold;">Título solicitud</mat-label>
          <p>{{data.solicitud.title}}</p>
        </div>
        <div class="col-8">

          <mat-label style="font-weight: bold;">Descripción solicitud</mat-label>
          <p>{{data.solicitud.description}}</p>
        </div>

      </div>

      <div class="row" *ngIf="data.solicitud.date_ejecucion || data.solicitud.observaciones_ejecuccion">
        <div class="col-4">
          <mat-label style="font-weight: bold;">Fecha de ejecución</mat-label>
          <p>{{data.solicitud.date_ejecucion | date:'dd-MM-yyyy': 'UTC'}}</p>
        </div>
        <div class="col-8">
          <mat-label style="font-weight: bold;">Observación de ejecución</mat-label>
          <p>{{data.solicitud.observaciones_ejecuccion}}</p>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-4">

          <mat-label style="font-weight: bold;">Por los siguientes ítems</mat-label>

        </div>
        <div class="col-4">

          <mat-label style="font-weight: bold;">Cantidad</mat-label>

        </div>
        <div class="col-4">
          <mat-label style="font-weight: bold;">
            Referencia
          </mat-label>
        </div>
      </div>
      <div class="row" *ngFor="let item of data.solicitud.request_items">
        <div class="col-4">


          <p>{{item.detail}}</p>
        </div>
        <div class="col-4">


          <p>{{item.quantity}}</p>
        </div>
        <div *ngIf="item.name && item.path" class="col-4">
          <span style="text-decoration-line: underline;font-weight: 500;">{{item.name}}</span><button class=" action"
            type="button" (click)="downloadFile(item)" mat-icon-button>
            <i class="fi-rr-download"></i>
          </button>
        </div>
      </div>

      <hr style="max-width: 100%;">

      <div class="row my-3" *ngIf="data.solicitud.request_state.id == 2">
        <mat-divider></mat-divider>
        <div class="col-12">
          <mat-label style="font-weight: bold;">Respuesta a la solicitud - </mat-label>
          <mat-label style="font-weight: bold; color: #717171;">{{data.solicitud.updated_at|date:'dd/MM/yyyy hh:mm:ss':
            'UTC'}} | </mat-label>
          <mat-label
            style="font-weight: bold; color: #717171;">{{validate_latesObservation(data.solicitud.latest_observations)}}</mat-label>
        </div>
      </div>

      <div class="row">
        <div class="col-12" *ngIf="solicitudesForm.get('estado').value && solicitudesForm.get('estado').value != 8">
          <strong>Respuesta a la solicitud</strong>
          <br>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-4">
          <mat-form-field class="w-100" style="padding: 1%;" appearance="fill"
            *ngIf="data.solicitud.request_state_id != 8 && !viewSolciitud">
            <mat-label>Estado</mat-label>
            <mat-select formControlName="estado" (valueChange)="cambioEstado($event)">
              <mat-option [value]="2">Aceptada</mat-option>
              <mat-option [value]="8">Devuelta</mat-option>
            </mat-select>
            <mat-error *ngIf="error.estado.errors"><span *ngIf="error.estado.errors.required">¡Advertencia! Este campo
                es
                requerido</span></mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12" *ngIf="solicitudesForm.get('estado').value && solicitudesForm.get('estado').value != 8">
          <strong>Ítems de compra solicitud asociada</strong>
          <br>
        </div>
      </div>

      <div class="my-3" *ngIf="solicitudesForm.get('estado').value && solicitudesForm.get('estado').value != 8">
        <div *ngFor="let applet of items.controls; let i = index">
          <div class="row" [formGroup]="applet">
            <mat-form-field class="col-8" appearance="fill">
              <mat-label>Descripción</mat-label>
              <input style="text-transform: none;" matInput type="text" placeholder="Escribe la descripción del ítem"
                formControlName="detalle" maxlength="255" >
                <mat-error *ngIf="error.items.controls[i].controls.detalle.errors"><span
                  *ngIf="error.items.controls[i].controls.detalle.errors.required">¡Advertencia! Este campo es
                  requerido</span></mat-error>
              <mat-error *ngIf="error.items.controls[i].controls.detalle.errors"><span
                  *ngIf="error.items.controls[i].controls.detalle.errors.minlength">¡Aún falta! Recibimos mínimo 3
                  caracteres</span></mat-error>
              <mat-error *ngIf="error.items.controls[i].controls.detalle.errors"><span
                  *ngIf="error.items.controls[i].controls.detalle.errors.maxlength">¡Cuidado! Solo puedes tener un
                  máximo de 255 caracteres</span></mat-error>
            </mat-form-field>
            <mat-form-field class="col-4" appearance="fill">
              <mat-label>Cantidad</mat-label>
              <input style="text-transform: none;" matInput type="number" min="0"
                placeholder="Escribe la cantidad de ítems" formControlName="cantidad" max="9999">
                <mat-error *ngIf="error.items.controls[i].controls.cantidad.errors"><span
                  *ngIf="error.items.controls[i].controls.cantidad.errors.required">¡Advertencia! Este campo es
                  requerido</span></mat-error>
              <mat-error *ngIf="error.items.controls[i].controls.cantidad.errors"><span
                  *ngIf="error.items.controls[i].controls.cantidad.errors.minlength">¡Aún falta! Recibimos mínimo 3
                  caracteres</span></mat-error>
              <mat-error *ngIf="error.items.controls[i].controls.cantidad.errors"><span
                  *ngIf="error.items.controls[i].controls.cantidad.errors.maxlength">¡Cuidado! Solo puedes tener un
                  máximo de 5 caracteres</span></mat-error>
            </mat-form-field>
            <mat-form-field class="col-12" appearance="fill">
              <mat-label>Observación</mat-label>
              <input style="text-transform: none;" matInput type="text"
                placeholder="Escribe la observacione del ítem" formControlName="observacion" >
                <mat-error *ngIf="error.items.controls[i].controls.observacion.errors"><span
                  *ngIf="error.items.controls[i].controls.observacion.errors.required">¡Advertencia! Este campo es
                  requerido</span></mat-error>
              <mat-error *ngIf="error.items.controls[i].controls.observacion.errors"><span
                  *ngIf="error.items.controls[i].controls.observacion.errors.maxlength">¡Cuidado! Solo puedes tener un
                  máximo de 200 caracteres</span></mat-error>
            </mat-form-field>
          </div>
          <div class="row mb-2">
            <div class="col-2 text-center d-flex align-items-center justify-content-center">
              <button mat-icon-button (click)="deleteItems(i)" *ngIf="i !=0"><i class="fi-rr-trash fs-3"></i></button>
            </div>
            <div [ngClass]="{'col-12 pe-0': i === 0, 'col-10 pe-0': i !== 0}">
              <button type="button" class="addButton px-0" mat-button (click)="newItems()">
                <p style="margin-top: 6px; color: #B2B2B2;">
                  Agregar nuevo Ítem
                  <span class="fi-rr-add" style="margin-left: 20px;"></span>
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12" *ngIf="solicitudesForm.get('estado').value && solicitudesForm.get('estado').value != 8">
          <strong>Observaciones de la solicitud asociada</strong>
          <br><br>
        </div>
      </div>

      <div class="row">
        <mat-form-field class="col-12" appearance="fill">
          <mat-label>Observaciones de la solicitud asociada</mat-label>
          <input style="text-transform: none;" matInput type="text" placeholder="Observaciones de la solicitud asociada"
            formControlName="observaciones_asociada" maxlength="255">
        </mat-form-field>
      </div>


      <div class="mb-3">



        <div class="row">
          <div class="col-12" *ngIf="solicitudesForm.get('estado').value && solicitudesForm.get('estado').value != 8">
            <strong>Cotizaciones</strong>
            <br><br>
            <span *ngIf="sinAdjuntos" style="font-size: 16px;">Es necesario que adjuntes al menos una cotización.</span>
            <br>
          </div>
        </div>
        <div class="row mt-2" *ngIf=" solicitudesForm.get('estado').value && solicitudesForm.get('estado').value != 8">
          <div class="col-4">
            <mat-form-field class="w-100" appearance="fill">
              <mat-label>Cotización 1</mat-label>
              <input type="text" readonly="true" placeholder="Cotización 1" formControlName="cot1_txt" matInput>
              <i *ngIf="!registrosPorCargar[0]" (click)="fileUpload1.click()" matSuffix class="fi-rr-clip"></i>
              <i *ngIf="registrosPorCargar[0]" (click)="downloadFile(registrosPorCargar[0])" matSuffix
                class="fi fi-rr-download"></i>
              <i *ngIf="registrosPorCargar[0]" (click)="removeFileDetails(registrosPorCargar[0], 0)" matSuffix
                class="fi fi-rr-trash"></i>

            </mat-form-field>
            <input hidden type="file" #fileUpload1 (change)="setFile($event,0)">
            <mat-form-field class="w-100" appearance="fill" *ngIf="registrosPorCargar[0]">
              <input required currencyMask [options]="options" placeholder="Valor cotización 1*"
                formControlName="cot1_val_txt" matInput (ngModelChange)="setFileValue($event,0)" max="999999999999">
              <mat-error *ngIf="error.cot1_val_txt.errors">¡Advertencia! El valor no puede ser mayor a {{999999999999 |
                number}}</mat-error>

            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Cotización 2</mat-label>
              <input type="text" readonly="true" placeholder="Cotización 2" formControlName="cot2_txt" matInput>
              <i *ngIf="!registrosPorCargar[1]" (click)="fileUpload2.click()" matSuffix class="fi-rr-clip"></i>
              <i *ngIf="registrosPorCargar[1]" (click)="downloadFile(registrosPorCargar[1])" matSuffix
                class="fi fi-rr-download"></i>
              <i *ngIf="registrosPorCargar[1]" (click)="removeFileDetails(registrosPorCargar[1], 1)" matSuffix
                class="fi fi-rr-trash"></i>

            </mat-form-field>

            <input hidden type="file" #fileUpload2 (change)="setFile($event,1)">

            <mat-form-field class="w-100" appearance="fill" *ngIf="registrosPorCargar[1]">
              <input required currencyMask [options]="options" placeholder="Valor cotización 2*"
                formControlName="cot2_val_txt" matInput (ngModelChange)="setFileValue($event,1)" max="999999999999">
              <mat-error *ngIf="error.cot2_val_txt.errors">¡Advertencia! El valor no puede ser mayor a {{999999999999 |
                number}}</mat-error>

            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Cotización 3</mat-label>
              <input type="text" readonly="true" placeholder="Cotización 3" formControlName="cot3_txt" matInput>
              <i *ngIf="!registrosPorCargar[2]" (click)="fileUpload3.click()" matSuffix class="fi-rr-clip"></i>
              <i *ngIf="registrosPorCargar[2]" (click)="downloadFile(registrosPorCargar[2])" matSuffix
                class="fi fi-rr-download"></i>
              <i *ngIf="registrosPorCargar[2]" (click)="removeFileDetails(registrosPorCargar[2], 2)" matSuffix
                class="fi fi-rr-trash"></i>

            </mat-form-field>
            <input hidden type="file" #fileUpload3 (change)="setFile($event,2)">

            <mat-form-field class="w-100" appearance="fill" *ngIf="registrosPorCargar[2]">
              <input required currencyMask [options]="options" placeholder="Valor cotización 3*"
                formControlName="cot3_val_txt" matInput (ngModelChange)="setFileValue($event,2)" max="999999999999">
              <mat-error *ngIf="error.cot3_val_txt.errors">¡Advertencia! El valor no puede ser mayor a {{999999999999 |
                number}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="solicitudesForm.get('estado').value == 8">
          <h4>
            Motivo de rechazo de solicitud
          </h4>
        </div>

        <mat-form-field *ngIf=" solicitudesForm.get('estado').value &&  !viewSolciitud " style="padding:1% 0;"
          class="col-12" appearance="fill">
          <mat-label>Observación</mat-label>
          <input matInput style="text-transform: lowercase;" type="text" placeholder="Escribe la observación"
            formControlName="observacion">
          <mat-error *ngIf="error.observacion.errors"><span *ngIf="error.observacion.errors.required">¡Advertencia! Este
              campo es obligatorio</span></mat-error>
        </mat-form-field>
      </div>

      <p *ngIf=" viewSolciitud">
        {{ (data.solicitud.request_observation) ? data.solicitud.request_observation[0].observation : "" }}
      </p>


      <div style="float: right" class="pb-2" *ngIf="!viewSolciitud">
        <button mat-stroked-button color="primary" type="button" (click)="sendSolicitud()"
          *ngIf="solicitudesForm.valid && !sinAdjuntos &&  solicitudesForm.get('observacion').value.length > 1 && solicitudesForm.get('estado').value == 2 "
          class="bold compras-modal-button">
          Enviar</button>

        <button mat-stroked-button mat-dialog-close class="bold compras-modal-button me-2 ms-2">Cancelar</button>

      </div>
    </mat-dialog-content>




  </form>
</div>
