import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { DobbleAuthService } from './../../core/services/rest/dobble-auth.service';
import { LoginComponent } from './../login/login.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-dobble-auth',
  templateUrl: './dobble-auth.component.html',
  styleUrls: ['./dobble-auth.component.sass']
})
export class DobbleAuthComponent implements OnInit, OnDestroy {
  public showCode: boolean = false;
  public showEmail: boolean = false;
  public showSms: boolean = false;
  public email: string;
  public validEmail: boolean = true;
  public cellphone: string;
  public codeForm: FormGroup;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<LoginComponent>,
    private dobbleAuthService: DobbleAuthService,
    private authService: AuthService,
    private destroyService: DestroyComponentService
  ) { }

  ngOnInit(): void {
    this.hideEmail();
    this.hideSms();
    this.formControl();
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

  get error(): any { return this.codeForm.controls; }

  /**
   * @author Carlos Nieto
   * @createdate 2022-02-10
   * Metodo que crea el formGroup con cada campo y su respectiva validacion
   */
  formControl(): void {
    this.codeForm = new FormGroup({
      code: new FormControl('', [Validators.required, Validators.minLength(5)])
    });
  }

  /**
     * @author Carlos Nieto
     * @createdate 2022-02-16
     * @param codeForm Formulario diligenciado con el campo code
     */
  sendCode(codeForm): void {
    let json = { 'token': codeForm.value.code, 'user_id': this.data.user_id }
    this.dobbleAuthService.validateToken(json).subscribe(
      response => {
        this.matDialogRef.close(response['data'].valid);
      },
      error => {
        this.matDialogRef.close(false);
      }
    );
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-02-10
   * metodo que envia nuevamente el codigo
   */
  resendCode() {
    this.showEmail == true ? this.sendEmail() : this.sendSms();
  }


  /**
   * @author Carlos Nieto
   * @createdate 2022-02-18
   * Servicio que envia el email con el codigo para el doble login
   * y cambia la pantalla del modal
   */
  sendEmail() {
    this.showCode = true;
    this.showSms = false;
    this.showEmail = true;
    let json = { 'rrhh_id': this.data.user_id, 'email': this.data.email }
    this.authService.sendEmailToDobbleAuth(json).subscribe();
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-02-18
   * funcion que oculta los datos del email
   * o registra si el email si esta guardado en bd
   */
  hideEmail() {
    if (this.data.email != '') {
      this.validEmail = true;
      let array = this.data.email.split("@");
      let firstFour = array[0].substring(0, 4);
      this.email = `${firstFour}****@${array[1]}`;
    } else {
      this.validEmail = false;
      this.email = "No hay correo registrado";
    }
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-02-18
   * Servicio que envia el sms con el codigo para el doble login
   * y cambia la pantalla del modal
   */
  sendSms() {
    this.showCode = true;
    this.showEmail = false;
    this.showSms = true;
    let json = { 'rrhh_id': this.data.user_id, 'cellphone': this.data.phoneNumber }
    this.authService.sendSmsToDobbleAuth(json).subscribe();
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-02-18
   * funcion que oculta los datos del telefono
   * o registra si el telefono si esta guardado en bd
   */
  hideSms() {
    if (this.data.phoneNumber != false) {
      let cellphone = this.data.phoneNumber
      let lastNumbers = cellphone.substring(4, 10);
      this.cellphone = `****${lastNumbers}`;
    } else {
      this.cellphone = 'No hay número celular registrado'
    }
  }

  /**
     * @author Carlos Nieto
     * @createdate 2022-02-18
     * funcion que devuelve la pantalla a la seleccion de opciones
     */
  back() {
    this.showCode = false;
    this.formControl();
  }

}
