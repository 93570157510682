import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UsersService } from 'src/app/modules/ciu/services/rest/users.service';
import { CampaignsService } from 'src/app/modules/nomina/services/rest/campaigns.service';
import { VacationsResponseComponent } from '../vacations-response/vacations-response.component';
import { VacationsViewComponent } from '../vacations-view/vacations-view.component';
import { VacationsService } from 'src/app/modules/nomina/services/rest/vacations.service';
import { CandidatesService } from 'src/app/modules/rrhh/services/candidates.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { ReverseVacationsComponent } from '../reverse-vacations/reverse-vacations.component';
import { CancelVacationsComponent } from '../cancel-vacations/cancel-vacations.component';

@Component({
  selector: 'app-vacation-boss',
  templateUrl: './vacation-boss.component.html',
  styleUrls: ['./vacation-boss.component.sass']
})
export class VacationBossComponent implements OnInit {
  // MatPaginator Inputs solicitudes
  length;
  pageSize = 5;
  page = 1;
  isPage = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  displayedColumns: string[] = [ 'acciones', 'fecha', 'nombre', 'numero','tipo', 'estado', 'campana' ];
  dataSource = new MatTableDataSource<any>();

  reportForm: FormGroup;
  Campaings: any;
  filterControl = new FormControl();
  filterControl2 = new FormControl();
  states: any = [{ id: 1, name: 'En revisión' }, { id: 2, name: 'Aprobado' }, { id: 3, name: 'Rechazado' }];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  document: any = '';
  startDate: any = '';
  endDate: any = '';
  idCampaing: any;
  idState: any = 1;

  documents: any = [];
  idTypeDoc: any;

  typeDocument = new FormControl();

  //vacaciones aprobadas variables
  lengthVacation;
  pageSizeVacation = 5;
  pageVacation = 1;
  isPageVacation = 1;
  pageSizeOptionsVacation: number[] = [5, 10, 25, 100];

  statesVacation: any = [{ id: 1, name: 'En vacaciones' }, { id: 2, name: 'Interrumpidas' }, { id: 3, name: 'Finalizadas' }, { id: 4, name: 'Vaciones aprobadas' }];
  displayedColumnsVacation: string[] = ['acciones', 'nombre', 'numero', 'fecha', 'fechaFin', 'campana' ,'estado'];
  dataSourceVacation = new MatTableDataSource<any>();
  reportFormVacation: FormGroup;
  @ViewChild(MatPaginator, { static: true }) paginatorVacation: MatPaginator;

  idTypeDocVacation: any;
  documentVacation: any = '';
  startDateVacation: any = '';
  endDateVacation: any = '';
  idCampaingVacation: any;
  idStateVacation: any = '';


  constructor(public dialog: MatDialog,
    private service: CampaignsService,
    public userService: UsersService,
    private vacation: VacationsService,
    private users: CandidatesService
  ) {
  }

  ngOnInit() {
    this.iniciarVariablesSolicitudes();
    this.iniciarVariablesVacaciones();
  }

  iniciarVariablesSolicitudes() {
    this.filterControl2.setValue(1);
    this.service.getAllCampaigns().subscribe(resp => {
      this.Campaings = resp.data;
    });
    this.reportForm = new FormGroup({
      start: new FormControl(''),
      end: new FormControl('')
    });
    this.users.getIdTypes().subscribe(resp => {
      this.documents = resp;
    });
    this.typeDocument.valueChanges.subscribe(resp => {
      this.idTypeDoc = resp;
      this.getVacations(this.idState, this.pageSize, this.page);
    });
    this.typeDocument.setValue(1);

    this.filterControl.valueChanges.subscribe(resp => {
      this.resetPaginator(1);
      this.idCampaing = resp;
      this.getVacations(this.idState, this.pageSize, this.page);
    });
    this.filterControl2.valueChanges.subscribe(resp => {
      this.resetPaginator(1);
      this.idState = resp;
      this.getVacations(this.idState, this.pageSize, this.page);
    });
  }

  iniciarVariablesVacaciones() {
    this.reportFormVacation = new FormGroup({
      start: new FormControl(''),
      end: new FormControl(''),
      typeDocument: new FormControl(1),
      state: new FormControl('')
    });

    this.reportFormVacation.controls.typeDocument.valueChanges.subscribe(resp => {
      this.idTypeDocVacation = resp;
      this.getVacationsActive(this.idStateVacation, this.pageSizeVacation, this.pageVacation);
    })

    this.reportFormVacation.controls.state.valueChanges.subscribe(resp => {
      this.idStateVacation = resp;
      this.getVacationsActive(this.idStateVacation, this.pageSizeVacation, this.pageVacation);
    })

    this.getVacationsActive(this.idStateVacation, this.pageSizeVacation, this.pageVacation);
  }


  /**
   * @author Javier Castañeda
   * @createdate 2021-04-29
   * Metodo de paginación
   */
  pageEvent(event: any): void {

    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getVacations(this.idState, this.pageSize, this.page);
  }

  /**
   * @author Javier Castañeda
   * @createdate 2021-04-29
   * Modal de justificación
   */
  viewJustification(element?): void {
    const dialogNewgroup = this.dialog.open(VacationsResponseComponent, {
      width: '60%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Juan Manuel Gonzales',
        data: element
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewgroup.afterClosed().subscribe(() => {
      this.getVacations(this.idState, this.pageSize, this.page);
    });

  }

  /**
   * @author Javier Castañeda
   * @createdate 2021-04-29
   * Modal de justificación rechazada
   */
  viewJustification2(element?): void {
    const dialogNewgroup = this.dialog.open(VacationsViewComponent, {
      width: '60%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Juan Manuel Gonzales',
        data: element
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewgroup.afterClosed().subscribe(() => {
      this.getVacations(this.idState, this.pageSize, this.page);
    });

  }

  /**
   * @author Javier Castañeda
   * @createdate 2021-10-22
   * Modal de reversion de vacaciones
   */
  showModalToReverseVacation(element): void {
    const dialogReversion = this.dialog.open(ReverseVacationsComponent, {
      width: '60%',
      disableClose: true,
      data: {
        type: 'edit',
        title: 'Proceso de reversion',
        data: element
      },
      panelClass: 'custom-dialog-container',
    });

    dialogReversion.afterClosed().subscribe(() => {
      this.getVacations(this.idState, this.pageSize, this.page);
    });

  }

  /**
   * @author Javier Castañeda
   * @createdate 2021-04-29
   * Metodo para capturar el documento a filtrar
   */
  onBlurMethod(e) {
    this.resetPaginator(1);
    this.document = e;
    this.getVacations(this.idState, this.pageSize, this.page);
  }

  /**
   * @author Javier Castañeda
   * @createdate 2021-04-29
   * Metodo para capturar el rango de fechas a filtrar
   */
  dateRangeChange() {
    this.resetPaginator(1);
    const date1: Date = this.reportForm.controls.start.value;
    const date2: Date = this.reportForm.controls.end.value;
    const dateStart: string | null = new DatePipe('en-US').transform(date1, 'yyyy-MM-dd');
    const dateEnd: string | null = new DatePipe('en-US').transform(date2, 'yyyy-MM-dd');
    this.startDate = dateStart;
    this.endDate = dateEnd;
    this.getVacations(this.idState, this.pageSize, this.page);
  }

  /**
   * @author Javier Castañeda
   * @createdate 2021-04-29
   * Metodo para filtrar fecha, campaña y rango de fechas
   */
  getVacations(state, pageSi, page, exportData: boolean = false) {
    this.vacation.getVacationsBy({
      state,
      idTipDoc: this.idTypeDoc,
      document: this.document,
      campaign_id: this.idCampaing,
      startDate: this.startDate,
      endDate: this.endDate,
      forBossView: 1,
      exportData
    }, pageSi, page).subscribe(resp => {
      if (!exportData) {
        this.dataSource = resp.data.data;
        this.length = resp.data.total;
      } else {
        importedSaveAs(resp, 'reporte_vacaciones_jefe.xlsx');
      }
    });
  }

  resetPaginator(numPaginator): void {
    if (numPaginator === 1) {
      this.length = 0;
      this.pageSize = 5;
      this.page = 1;
      this.paginator.firstPage();
    }else {
      this.lengthVacation = 0;
      this.pageSizeVacation = 5;
      this.pageVacation = 1;
      this.paginatorVacation.firstPage();
    }
  }

  /**
   * @author Johan Soler 
   * @createDate 15-Jun-2022
   * Metodo decidir si algun empleado se encuentran las vacaciones validas para cancelar
   */
  inTime(element) {
    const currentTime = new DatePipe('en-US').transform(new Date, 'yyyy-MM-dd');    
    if(element.stateName == 'Interrumpidas'){
      return false;
    }
    return element.fechaFinSolicitud >= currentTime;
  }

  /**
   * @author Johan Soler 
   * @createDate 15-Jun-2022
   * Metodo para abrir el modal con el formulario para cancelar
   */
  cancelVacation(element, type?) {
    if(type){
      const dialogReversion = this.dialog.open(CancelVacationsComponent, {
        width: '60%',
        disableClose: true,
        data: element,
        panelClass: 'custom-dialog-container',
      });
  
      dialogReversion.afterClosed().subscribe(() => {
        this.getVacationsActive(this.idStateVacation, this.pageSizeVacation, this.pageVacation);
      });
    }else{
      const dialogReversion = this.dialog.open(CancelVacationsComponent, {
        width: '60%',
        disableClose: true,
        data: element,
        panelClass: 'custom-dialog-container',
      });
  
      dialogReversion.afterClosed().subscribe(() => {
        this.getVacationsActive(this.idStateVacation, this.pageSizeVacation, this.pageVacation);
      });
    }
  }

  /**
   * @author Johan Soler
 * @createdate 2022-06-16
   * Metodo para capturar el rango de fechas a filtrar
   */
  dateRangeChangeVacation() {
    this.resetPaginator(2);
    const date1: Date = this.reportFormVacation.controls.start.value;
    const date2: Date = this.reportFormVacation.controls.end.value;
    const dateStart: string | null = new DatePipe('en-US').transform(date1, 'yyyy-MM-dd');
    const dateEnd: string | null = new DatePipe('en-US').transform(date2, 'yyyy-MM-dd');
    this.startDateVacation = dateStart;
    this.endDateVacation = dateEnd;
    this.getVacationsActive(this.idStateVacation, this.pageSizeVacation, this.pageVacation);
  }

  /**
 * @author Johan Soler
 * @createdate 2022-06-16
 * Metodo para capturar el documento a filtrar
 */
  onBlurMethodVacation(e) {
    this.resetPaginator(2);
    this.documentVacation = e;
    this.getVacationsActive(this.idStateVacation, this.pageSizeVacation, this.pageVacation);
  }

  /**
 * @author Johan Soler
 * @createdate 2022-06-16
 * Metodo para filtrar fecha, campaña y rango de fechas
 */
  getVacationsActive(state, pageSi, page, exportData: boolean = false) {
    this.vacation.getVacationsActive({
      state,
      idTipDoc: this.idTypeDocVacation,
      document: this.documentVacation,
      campaign_id: this.idCampaingVacation,
      startDate: this.startDateVacation,
      endDate: this.endDateVacation,
      forBossView: 1,
      exportData
    }, pageSi, page).subscribe(resp => {
      if (!exportData) {
        this.dataSourceVacation = resp.data.data;        
        this.lengthVacation = resp.data.total;
      } else {
        importedSaveAs(resp, 'reporte_vacaciones_activas_jefe.xlsx');
      }
    });
  }

  /**
 * @author Johan Soler
 * @createdate 2022-06-16
 * Metodo de paginación
 */
  pageEventVacation(event: any): void {
    this.pageSizeVacation = event.pageSize;
    this.pageVacation = event.pageIndex + 1;
    this.getVacationsActive(this.idStateVacation, this.pageSizeVacation, this.pageVacation);
  }
}
