import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from '@angular/core';
import { ResourcesService } from '../../../services/resources.service';
import { CrmService } from '../../../services/crm.service';
import { Crm2Service } from '../../../services/crm2.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-booking-crm',
  templateUrl: './booking-crm.component.html',
  styleUrls: ['./booking-crm.component.sass']
})
export class BookingCrmComponent implements OnInit {

  bookingList:any[]=[];
  dataSource:any;
  formList: any = [];
  formListCrmTwo:any[]= [];

  displayedColumns = ['resource', 'created_at', 'start', 'status'];


  constructor(
    public resourcesService : ResourcesService,
    public crmService: CrmService,
    public crmServicesTwo:Crm2Service,
  ) { }

  ngOnInit(): void {
    this.crmService.getFormsCRM().subscribe(res => {
      this.formList = res;
    } );

    this.crmServicesTwo.getFormsCRM().subscribe((res:any|any[]) => {
    
      if(Array.isArray(res) == true){
        this.formListCrmTwo = res;
      }else if (res !== null && res !== undefined){
        for (const key in res) {
            if(this.formListCrmTwo.find( fnd => fnd.id == res[key]?.id ) == undefined){
              this.formListCrmTwo.push(res[key]);
            }
        }
      }
      
    } );

  }

  /**
   * Metodoq ue se encarga dde filtrar
   * @param formId:number {number} id del formualrio a consultar
   * @param type:string {string} indicador para saber que filtro usar
   */
  filter( formId:number, type:string ){
    let $obsResource:Observable<any>;
    if(type == "crm"){
      $obsResource = this.resourcesService.getCrmResources(formId);
    }else if(type == "crm2"){
      $obsResource =this.resourcesService.getCrmResourcesCrm2(formId);
    }

    $obsResource.subscribe(res=>{
      if(Array.isArray(res) == true){
        this.bookingList = res
      }else{
        this.bookingList = []
      }
      
      this.dataSource = new MatTableDataSource(res);
    })
  }

  get isBookingListLoad():boolean{
    if(Array.isArray(this.bookingList) == true && this.bookingList.length > 0){
      return true;
    }
    return false;
  }


}
