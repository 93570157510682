import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../core/services/rest/auth.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const RRHH_URL = environment.RRHH_URL;
const CIU_URL = environment.CIU_URL;

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient, private authService: AuthService) { }


  /**
   * Endpoint que retorna los datos desde RRHH,
   * @param id (hired_user_id)
   * @returns Object con la data de candidate table
   * @author Carlos Galindez
   */
  dataUser(id:number){
    return this.http.get(`${RRHH_URL}user/candidate/${id}`);
  }
  /**
   * Endpoint que permite cambiar la contraseña
   * @param id user id (user table CIU)
   * @param pass
   * @returns
   * @author Carlos Galindez
   */
  changePassword(id:number,pass:any){
    const params = new HttpParams( { fromObject: {'password':pass} } );
    return this.http.post(`${CIU_URL}user/password/${id}`,params);
  }

  /**
   * Endpoint que permite cambiar la contraseña
   * @param id user id (user table CIU)
   * @param pass
   * @returns
   * @author Carlos Galindez
   */
  changePasswordLogin(id:number,pass:any){
    return this.http.post(`${CIU_URL}user/passwordLogin/${id}`,pass);
  }

  /**
   * Endpoint que permite actualizar los daros del usuario
   * @param data
   * @returns
   * @author Carlos Nieto
   */
   editProfile(data){
    return this.http.post(`${CIU_URL}user/update-profile`,data);
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-09-30
    * Metodo que genera un nuevo codigo de acceso para la PWA. 
  */
  generatePwaAccessCode(): Observable<any> {
    return this.http.get(`${CIU_URL}generatePwaCode`);
  }
}
