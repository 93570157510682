<div class="filter mb-3" style="--bs-gutter-x: 0rem !important;">
  <div>
    <mat-form-field appearance="fill">
        <mat-label>{{config.filters.byQuestion}}</mat-label>
        <input matInput [(ngModel)]="filtroPregunta" (keydown.enter)="filtrar()" (blur)="filtrar()">
        <mat-icon matSuffix (click)="filtrar()"><span class="fi-rr-search"></span></mat-icon>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="fill">
        <mat-label>{{config.filters.byDate}}</mat-label>
        <input matInput [matDatepicker]="fechaFiltro" [(ngModel)]="filtroFecha" (dateChange)="filtrar()">
        <mat-datepicker-toggle matSuffix [for]="fechaFiltro">
            <mat-icon matDatepickerToggleIcon><span class="fi-rr-calendar"></span></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #fechaFiltro></mat-datepicker>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="fill">
        <mat-label>{{config.filters.byType}}</mat-label>
        <mat-select [(value)]="filtroTipo_pregunta" (valueChange)="filtrar()">
            <mat-option>{{config.filters.all}}</mat-option>
            <mat-option *ngFor="let tipo of tiposPreguntas" [value]="tipo.id">{{tipo.name}}</mat-option>
        </mat-select>
    </mat-form-field>
  </div>
  <div class="text-end">
    <button mat-raised-button color="primary"
    (click)="newQuestion()"
    *ngIf="!checkbox">
      {{config.btnQuestion}} <i class="fi-rr-add"></i>
    </button>
  </div>
</div>

<div class="table-container">
    <div class="mat-elevation-z0">
      <table  [dataSource]="dataSource" mat-table  matSort>

        <!-- Columna con botónes de acciones -->
        <ng-container matColumnDef="acciones">
          <div *ngIf="checkbox">
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </div>
          <div>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="detailQuestion(false,element.id)">
                <i class="fi-rr-eye"></i>
              </button>
              <button mat-icon-button (click)="detailQuestion(true,element.id)">
                <i class="fi-rr-edit"></i>
              </button>
              <button mat-icon-button (click)="removeQuestion(element.id)">
                <i class="fi-rr-trash"></i>
              </button>
            </td>
          </div>
        </ng-container>

        <!-- Columna fecha de creación -->
        <ng-container matColumnDef="fecha_creacion">
          <th mat-header-cell *matHeaderCellDef> {{config.table.createdAt}} </th>
          <td mat-cell *matCellDef="let element"> {{ element.created_at | date:'dd/MM/yyyy'}} </td>
        </ng-container>

        <!-- Columna tipo de pregunta -->
        <ng-container matColumnDef="tipo_pregunta">
          <th mat-header-cell *matHeaderCellDef> {{config.table.questionType}} </th>
          <td mat-cell *matCellDef="let element"> {{ element.tipo_pregunta.name }} </td>
        </ng-container>

        <!-- Columna pregunta -->
        <ng-container matColumnDef="pregunta">
          <th mat-header-cell *matHeaderCellDef> {{config.table.question}}  </th>
          <td mat-cell *matCellDef="let element"> {{ element.question | cutText}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">{{config.table.nothingToShow}}</td>
        </tr>
      </table>


      <mat-paginator [length]="length"
      [pageSize]="pageSize"
      (page) ="pageEvent($event)"
      [pageSizeOptions] = "pageSizeOptions"
      >
    </mat-paginator>
  </div>
</div>
<div class="btns-table" *ngIf="checkbox">
  <button mat-stroked-button class="cancel" (click)="cancelar()">{{config.table.btnCancel}}</button>
  <button mat-raised-button color="primary" (click)="selections()">{{config.table.btnSave}}</button>
</div>
