import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../../../core/services/rest/auth.service';
import { throwError, Observable } from 'rxjs';
import { buffer, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const COMPRAS_URL = environment.COMPRAS_URL;

@Injectable({
  providedIn: 'root'
})
export class OrdenesService {

  constructor(private http: HttpClient,
    private authService: AuthService) { }

    handleError(error: HttpErrorResponse): any{
      return throwError(error);
    }

    /**
    * @author Jair Armando Celis Torrado
    * @createdate 2021-09-03
    * Servicio que lista todos las ordenes
    */
    getOrders(): Observable<any>{
      return this.http.get<any>(`${COMPRAS_URL}purchaseOrder/?paginate=0`)
      .pipe(
        catchError(this.handleError)
        );
    }

    /**
     * Consulta listado deordenes correspondiente
     * @param isPage
     * @param size pagina
     * @param page
     * @param nit nit de proveedor
     * @param states  arreglo de estados
     * @param fecha  fecha de creación
     * @param remission  si traer remision o no
     * @returns
     */

     getOrdersPaginate(isPage    : number,
      size      : number,
      page      : number,
      nit       : string,
      states    : number[],
      fecha     : string,
      filterPurchaseOrderId: number = null,
      company     : number = null,
      remission : boolean = false,
      razon: string,
      proveedor: any = null
      ): Observable<any>{
      const data = {
      paginate    : isPage,
      n           : size,
      page        : page,
      nit         : nit,
      states      : states,
      fecha       : fecha,
      filterPurchaseOrderId,
      company,
      razon       : razon,
      proveedor: proveedor
      }
      return this.http.post<any>(`${COMPRAS_URL}purchaseOrder/filter${remission == true ? '?remission=true' : ''}` , data );
    }




  /**
  * @author Jair Armando Celis Torrado
  * @createdate 2021-09-03
  * Servicio que descarga en excel todas las ordenes
  */
  downloadOrders( params ): Observable<any>{
    params["fecha_fin"].setHours(23);
    return this.http.post<any>(`${COMPRAS_URL}file/order-export`, params, {responseType: 'blob' as 'json'})
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
  * @author Jose Vicente Silva
  * @createdate 2021-08-25
  * Servicio que lista todos los aplicativos del aplicativo ciu
  */
  saveOrder(ordenCompra: any): Observable<any>{
    return this.http.post<any>(`${COMPRAS_URL}purchaseOrder/`, ordenCompra)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
  * @author Jose Vicente Silva
  * @createdate 2021-08-25
  * Servicio que lista todos los aplicativos del aplicativo ciu
  */
  updateOrder(ordenCompra: any, idorder: any): Observable<any>{
    return this.http.put<any>(`${COMPRAS_URL}purchaseOrder/${idorder}`, ordenCompra)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
  * @author Jose Vicente Silva
  * @createdate 2021-08-25
  * Servicio que lista todos los aplicativos del aplicativo ciu
  */
  updateState(state: any, id:number): Observable<any>{
    return this.http.put<any>(`${COMPRAS_URL}purchaseOrder/update-state/${id}`, state)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
  * @author Jose Vicente Silva
  * @createdate 2021-08-25
  * Servicio que lista todos los aplicativos del aplicativo ciu
  */
  updateStateFrag(state: any, id:number): Observable<any>{
    return this.http.put<any>(`${COMPRAS_URL}purchaseOrderFrag/update-state/${id}`, state)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
  * @author Jose Vicente Silva
  * @createdate 2021-08-25
  * Servicio que lista todos los aplicativos del aplicativo ciu
  */
  saveOrderFrag(ordenCompra: any): Observable<any>{
    return this.http.post<any>(`${COMPRAS_URL}purchaseOrderFrag/`, ordenCompra)
    .pipe(
      catchError(this.handleError)
      );
    }


    getOrderItems( idOrder:Number): Observable<any>{
      return this.http.get<any>(`${COMPRAS_URL}purchaseOrderItem/${idOrder}`)
      .pipe(
        catchError(this.handleError)
        );
      }


   /**
    * @author Juan Carlos Alonso
    * @createdate 2022-03-04
    * Servicio que lista las remisiones de una orden de compra
    */
      getRemission(idOrder:Number, idOrderFrag:Number): Observable<any>{
        return this.http.get<any>(`${COMPRAS_URL}purchaseOrder/remission/${idOrder}${idOrderFrag ? `/${idOrderFrag}` : '' }`)
        .pipe(
          catchError(this.handleError)
          );
     }

      /**
    * @author Juan Carlos Alonso
    * @createdate 2022-03-04
    * Servicio que lista las remisiones de una orden de compra
    */
       updateRemission(remission:any, idOrderRemission:Number): Observable<any>{
        return this.http.put<any>(`${COMPRAS_URL}purchaseOrder/remission/${idOrderRemission}`, remission)
        .pipe(
          catchError(this.handleError)
          );
     }

      /**
    * @author Juan Carlos Alonso
    * @createdate 2022-03-04
    * Servicio que lista las remisiones de una orden de compra
    */
       updateRemissionItems(remissionItems:any): Observable<any>{
        return this.http.post<any>(`${COMPRAS_URL}purchaseOrder/remission/update-items`, remissionItems)
        .pipe(
          catchError(this.handleError)
          );
     }

      /**
    * @author Juan Carlos Alonso
    * @createdate 2022-03-04
    * Servicio que descarga pdf de la remisión
    */
     downloadOrderRemission(purchaseOrderId: number): Observable<any>{
      return this.http.post<any>(`${COMPRAS_URL}purchaseOrder/remission/download/${purchaseOrderId}`, null ,{observe: 'response', responseType: 'blob' as 'json'})
      .pipe(
        catchError(this.handleError)
        );
    }

    downloadOrderRemissionFrag(purcharseOrderId: number,FragId: number): Observable<any>{
      return this.http.post<any>(`${COMPRAS_URL}purchaseOrder/remission/download/${purcharseOrderId}/${FragId}`, null ,{observe: 'response', responseType: 'blob' as 'json'})
      .pipe(
        catchError(this.handleError)
        );
    }


    /**
   * @author Jair Armando Celis Torrado
   * @createdate 2021-09-14
   * Servicio filtro de ordenes por fecha
   */
     searchOrdersByDate(params): Observable<any> {
      return this.http.post<any>(`${COMPRAS_URL}purchaseOrderByDate`, params )
      .pipe(
        catchError(this.handleError)
        );
    }

    /**
   * @author Jair Armando Celis Torrado
   * @createdate 2021-09-15
   * Servicio que lista todos los estados existentes para las ordenes
   */
     getOrdersStates(): Observable<any> {
      return this.http.get<any>(`${COMPRAS_URL}purchaseOrderStates`)
      .pipe(
        catchError(this.handleError)
        );
    }

     /**
   * @author Andres Giraldo
   * @createdate 2022-03-18
   * Servicio que lista todos los estados existentes para las remisiones
   */
      getRemissionStates(): Observable<any> {
        return this.http.get<any>(`${COMPRAS_URL}purchaseOrder/remission/states`)
        .pipe(
          catchError(this.handleError)
          );
      }

    /**
   * @author Jair Armando Celis Torrado
   * @createdate 2021-09-15
   * Servicio filtro de ordenes por estado
   */
     searchOrdersByState(params): Observable<any> {
      return this.http.post<any>(`${COMPRAS_URL}purchaseOrderByState`, params )
      .pipe(
        catchError(this.handleError)
        );
    }

     /**
   * @author Jair Armando Celis Torrado
   * @createdate 2021-09-15
   * Servicio filtro de ordenes por NIT
   */
    searchOrdersByNIT(params): Observable<any> {
      return this.http.post<any>(`${COMPRAS_URL}purchaseOrderByNIT`, params )
      .pipe(
        catchError(this.handleError)
        );
    }

        /**
     * @method procesarObservacion Metodo que procesa la peticion apra Observaciones de cancelacion
     * @author Juan David Guerrero Vargas
     * @createdate  17/03/2022
     * @param id_pruchase_order:string  Id de la orden de compra
     * @param observacion:string  | null Observacion de cancelacion registrada
     * @param method:string Metodo (GET - POST) para peticion
     * @returns Observable<any> para subcribe a la peticion de Observaciones
     */
         procesarObservacion(id_pruchase_order:string, observacion:string|null, method:string){

          switch (method) {
            case 'post':
              return this.http.post<any>(`${COMPRAS_URL}purchaseOrder/${id_pruchase_order}/cancel`, {
                observation:observacion
              })
              .pipe(
                catchError(this.handleError)
                );

            break;
            case 'get':
              return this.http.get<any>(`${COMPRAS_URL}purchaseOrder/${id_pruchase_order}/cancel/observation`)
              .pipe(
                catchError(this.handleError)
                );
            break;


          }

        }




}
