import { Injectable, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { Conversation } from '../../modules/chat/models/conversation';
import { Message } from '../../modules/chat/models/message';
import { HttpService } from 'src/app/modules/livechat/services/http/index.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
@Injectable({
    providedIn: "root"
})
export class conversationsService {
    conversations: Conversation[] = []
    currentConversation = null
    currentConversationSubject: Subject<Conversation> = new Subject()
    finishedConversations: Subject<Conversation> = new Subject()
    historyConversations: Subject<Conversation> = new Subject()
    movedConversations: Subject<Conversation[]> = new Subject()
    lastMessageConversation: Subject<Message> = new Subject()
    projectId: Subject<String> = new Subject()
    isOpen = false;
    @Output () change: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private _httpService: HttpService,
        private _authService: AuthService,
    ) {
        let conversations: Conversation[] | string = sessionStorage.getItem('agent-conversations')
        if (conversations) {
            this.conversations = JSON.parse(conversations)
        } else {
            this.conversations = []
        }
    }


    getConversationsWhitAgent(){
        let conversations: Conversation[] | string = sessionStorage.getItem('agent-conversations')
        if (conversations) {
            this.conversations = JSON.parse(conversations)
        } else {
            this.conversations = []
        }
    }

    setProjectId(projectId: String) {
        return this.projectId;
    }

    updateConversation(conversationUpdate: Conversation) {
        try {
            let conversationsData = []
            let conversations: Conversation[] | string = sessionStorage.getItem('agent-conversations')
            if (conversations) {
                conversationsData = JSON.parse(conversations)
            } 
            conversationsData = conversationsData.map(conversation => {
                if(conversation.id == conversationUpdate.id){
                    conversation.newChat = false;
                }
                return conversation
            })
            sessionStorage.setItem('agent-conversations', JSON.stringify(conversationsData))
            return true
        } catch (e) {
            console.log("Error: ", e)
            return false
        }
    }

    setConversation(conversation: Conversation) {
        try {
            let issetConversation = false
            for(let i=0; i < this.conversations.length ; i++){
                if(conversation.id === this.conversations[i].id){
                    issetConversation = true
                    break
                }
            }
            if(!issetConversation){
                this.conversations.push(conversation)
                sessionStorage.setItem('agent-conversations', JSON.stringify(this.conversations))
            }

            return true
        } catch (e) {
            console.log("Error: ", e)
            return false
        }
    }

    setMessageConversation(id: string, message: Message) {
        const conversation = this.conversations.filter(item => item.id === id)
        if (conversation.length !== 0) {
            conversation[0].messages.push(message)
            sessionStorage.setItem('agent-conversations', JSON.stringify(this.conversations))
            if (this.currentConversation){
                if (this.currentConversation.id === conversation[0].id) {
                    this.currentConversationSubject.next(conversation[0])
                }
            }
            this.lastMessageConversation.next(message)
            return true
        }
        return false
    }

    setAlertCloseConversation(id: string, alertClose: string) {
        const conversation = this.conversations.filter(item => item.id === id)
        if (conversation.length !== 0) {
            conversation[0].alertClose = alertClose;
            sessionStorage.setItem('agent-conversations', JSON.stringify(this.conversations))
            if (this.currentConversation)
                if (this.currentConversation.id === conversation[0].id) {
                    this.currentConversationSubject.next(conversation[0])
                }
            return true
        }
        return false;
    }

    setAlertNewMessage(id: string, alertNewMessage: boolean) {
        const conversation = this.conversations.filter(item => item.id === id)
        if (conversation.length !== 0) {
            conversation[0].newMessage = alertNewMessage;
            sessionStorage.setItem('agent-conversations', JSON.stringify(this.conversations))
            if (this.currentConversation)
                if (this.currentConversation.id === conversation[0].id) {
                    this.currentConversationSubject.next(conversation[0])
                }
            return true
        }
        return false;
    }


    setCurrentConversation(conversation: Conversation) {
        this.currentConversation = conversation
        this.currentConversationSubject.next(conversation)
    }

    async getFinishedConversations() {
        const rrhh: any = this._authService.getUser();
        // const rrhh: any = JSON.parse(this._storageService.getItem('user'))
        try{
            const conversations: any = await this._httpService.get(`typification_conversation/list-finished/${rrhh.rrhh_id}`).toPromise();
            if(conversations.data){
                const tmp = conversations.data.map( item => {
                    return {
                        ...item.conversation,
                        finished: true
                    }
                })

                this.finishedConversations.next(tmp)
            }
            else
                this.finishedConversations.next(null)
        }catch(e){
          console.error('Error peticion de conversaciones trasladadas: ',e)
        }
    }

    async getMovedConversations() {
        const rrhh: any = this._authService.getUser();
        // const rrhh: any = JSON.parse(this._storageService.getItem('user'))
        try{
            const conversations: any = await this._httpService.get(`event/trasnferred/${rrhh.rrhh_id}`).toPromise();
            if(conversations.data){
                const tmp = conversations.data.map( item => {
                    return {
                        ...item.conversation,
                        finished: true
                    }
                })
                this.movedConversations.next(tmp)
            }
            else{
                this.movedConversations.next(null)
            }
        }catch(e){
          console.error('Error peticion de conversaciones finalizadas: ',e)
        }
    }

    async getHistoryConversations() {
        try{
            if(!this.currentConversation || !this.currentConversation.user_data)
                return false
            const conversations: any = await this._httpService.get(`conversation/by-user`,[
                    {email: this.currentConversation.user_data.email || ''},
                    {phone: this.currentConversation.user_data.phone || ''}
                ]).toPromise();
            if(conversations.data){
                const tmp = conversations.data
                this.historyConversations.next(tmp)
            }
            else
                this.historyConversations.next(null)
        }catch(e){
          console.error('Error peticion de historial conversaciones: ',e)
        }
    }

    getConversations() {
        return this.conversations
    }
    getProjectId() {
        return this.projectId;
    }

    getConversation(id: string) {
        const conversation = this.conversations.filter(item => item.id === id)
        const toReturn = conversation.length === 0 ? null : conversation[0]
        return toReturn
    }

    removeConversation(id: string) {
        try {
            const conversations = this.conversations.filter(item => item.id !== id)
            this.conversations = conversations
            sessionStorage.setItem('agent-conversations', JSON.stringify(this.conversations));
            if (this.currentConversation.id === id) {
                this.currentConversationSubject.next(null)
            }
            return true
        } catch (e) {
            console.error("Error: ", e)
            return false
        }
    }

}
