import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { inicialFieldsContract, AgreementsType, typeForms } from './contractTypes';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CandidatesService } from '../../../services/candidates.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

import { Observable } from 'rxjs';
import {switchMap} from 'rxjs/operators';
import { ToWords } from 'src/app/core/to-words/to-words';
import { KindAccountService } from '../../../services/kindAccount.service';
import { NominaService } from '../../../../soul_bi/services/nomina.service';

@Component({
  selector: 'app-contract-creation',
  templateUrl: './contract-creation.component.html',
  styleUrls: ['./contract-creation.component.sass']
})
export class ContractCreationComponent implements OnInit{

  myControl = new FormControl();
  campaign= new FormControl();
  contractTypes= AgreementsType
  formSection =[
    {
      name_section:null,
      fields: null,
      type_section: 1,
      collapse: false,
      duplicar: false,
    },
  ]
  fieldOthers={
    salary: null,
    letters:null
  };
  now = new Date();
  salaryAux= null
  toWords = new ToWords()
  fieldsContract = new inicialFieldsContract
  items= new Observable
  agreements: any
  disabledOthes: boolean= false
  seeForm: boolean= false
  isEdit: boolean= false
  isChange: boolean= false
  comition: boolean= false
  specialComition: boolean= false
  boification: boolean= false
  formGroup :  FormGroup;
  kindAccountList;
  showCampaign: boolean = false;
  campaignsData: any[] = [];
  rrhhId = null;
  initialOperation=false;

  constructor(private _fb: FormBuilder,  public dialog: MatDialog,
    private dialogRef: MatDialogRef<ContractCreationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private serviceCandidate: CandidatesService,
    public AlertsService: AlertsService,
    private kindAccountService : KindAccountService,
    private NominaService : NominaService
    ) {
      this.agreements= this.data.agreementRules;
      this.now.setDate(this.now.getDate()-30);

     }

  ngOnInit(): void {
    this.now.toISOString().split('.')[0];
    if(typeof this.data.agreementRules.salario !== 'undefined'  ){
      this.fieldOthers.salary = this.data.agreementRules.salario;
      this.salaryAux= this.data.agreementRules.salario
      this.fieldOthers.letters = this.data.agreementRules['salario_letras'];
      const found = AgreementsType.find(element => element.key == this.data.agreementRules.contractType.key)
      this.myControl.setValue(found)
      this.changeTypeContract(found)
      this.getKindAccounts();
      this.NominaService.getCampaings().subscribe(resp => {
        this.campaignsData = resp;
      });
      this.serviceCandidate.getHiredUserID(this.data.candidateId).subscribe((resp: any) => {

        this.rrhhId = resp;
        if(this.rrhhId != false){
          this.campaign.setValue(resp?.campaign_id);
         this.showCampaign = true;
       }
      });
    }
    this.formControl();
    this.initialOperation = this.data.candidate.initial_operation_date;
  }

  specialPolicy(agreementRules)
  {
    if(agreementRules['specialPolicy'])
    {
      this.formGroup.get("hasComitionFrom").value == true ? this.comition = true : this.comition = false;
      this.formGroup.get("hasSpecialComitionFrom").value == true ? this.specialComition = true : this.specialComition = false;
      this.formGroup.get("hasBonificationFrom").value == true ? this.boification = true : this.boification = false;
    }
  }

  async formControl(){

    this.formGroup = new FormGroup({
      hoursPerWeek: new FormControl('', [Validators.maxLength(15),Validators.minLength(1)]),
      hasComitionFrom: new FormControl(''),
      hasSpecialComitionFrom: new FormControl(''),
      specialComitionDetails: new FormControl(''),
      comprehensiveSalary: new FormControl(''),
      hasBonificationFrom: new FormControl(''),
      bonificationDetailsrom: new FormControl(''),
      finalidad: new FormControl(''),
    });
    await this.formGroup.patchValue(this.data.specialPolicy);
    await this.formGroup.disable();
    this.specialPolicy(this.data);

  }
  /**
   * @author Karol García
   * @createdate 2021-05-11
   * Método que convierte número a letras
   */
  changeValue(event){
    this.fieldOthers.salary = event.replaceAll(',','');
    this.fieldOthers.salary = Number( this.fieldOthers.salary)
    let words = this.toWords.convert( this.fieldOthers.salary )
    this.fieldOthers.letters= words
  }
/**
   * @author Karol García
   * @createdate 2021-05-06
   * Método que crea el formulario según el tipo de contrato
   */
  changeTypeContract(e){
    let editAux=[]
    let typeFormsAux = new typeForms
    const found = typeFormsAux.value.find(element => element.key == e.type);
    const lastArray = this.fieldsContract.value.concat(found.form);
    lastArray.map(element=>{
      if(element.controlType == "datepicker"){
        element.minLength = this.now
      }
    })
    if(this.data.agreementRules != null){
      if( this.isEdit == false){
        this.disabledOthes=true
        this.myControl.disable()
        this.campaign.disable()
      }else editAux =  ['coordinador-de-contratacion','analista-de-contratacion']
       lastArray.forEach(element => {
         if(element.key == 'fecha_de_ingreso'){
          if(this.data.agreementRules[element.key]){
            element.value = this.data.agreementRules[element.key];
          }else if(this.initialOperation){
            element.value = this.initialOperation;
          }else{
            element.value = new Date();
          }
        }else{
          element.value = this.data.agreementRules[element.key];
        }
        
       });
    }else editAux =  ['coordinador-de-contratacion','analista-de-contratacion']

    this.formSection[0].fields=lastArray
    
    this.formSection[0].name_section = `Contrato tipo ${e.name}`;
    this.doAsyncTask(editAux).then(() => this.seeForm=true);
  }
  /**
   * @author Karol García
   * @createdate 2021-05-06
   * Método que crear las reglas y genera el contrato
   */
  createContract(event){
    let message ='¿Desea generar las reglas del contrato?'
    let direction = '/agreementRules'
    if(this.isEdit == true){
      message = '¿Desea editar las reglas del contrato?'
      direction = '/agreementRulesEdit'
    }
    let saveconfirm= this.AlertsService.alertConfirm(message)
    let formAux = event.forms as FormArray;
    let toWords = this.toWords.convert(this.formGroup.get('hoursPerWeek').value);
    let hoursLetters = toWords.substring(0, toWords.length - 6);

    saveconfirm.then(res=>{
      if(res.isConfirmed){
        let answer = event.answer[0]
        answer['contractType'] =this.myControl.value
        answer['salario'] =this.fieldOthers.salary
        answer['salario_letras'] =this.fieldOthers.letters,
        answer['special-policy'] = this.formGroup.value,
        answer['special-policy']['hoursLetters'] = hoursLetters
        answer['cost_center'] = this.campaign.value
          formAux.disable()

          if(this.data.massive != true)
          {
            this.serviceCandidate.saveAgreement(`candidate/json/${this.data.candidateId}${direction}`, answer).subscribe(
              answer=>{

                this.AlertsService.alertSuccess('¡Generadas!', 'La reglas de contrato han sido creadas con éxito')
                this.dialogRef.close(true);
              }
              )
          }else{
            answer['candidatesIds']= this.data.ids;
            let saveconfirmNumber = this.AlertsService.alertConfirm('¿Estás seguro?',`Se afectará un total de ${this.data.ids.length} candidatos`);
            saveconfirmNumber.then(res=>{
              if(res.isConfirmed){
                this.serviceCandidate.saveAgreement(`masive/arguments`, answer).subscribe(
                  answer=>{
                    this.AlertsService.alertSuccess('¡Generadas!', 'La reglas de los contratos han sido creadas con éxito')
                    this.dialogRef.close(true);
                  }
                )
              }
            });
          }

        }
    })
  }
  doAsyncTask(who:string[]) {
    var promise = new Promise((resolve) => {
      this.seeForm=false
      this.formSection[0].fields.forEach(element => {
        element.editRoles=who;
        element.seeRoles=["coordinador-de-contratacion","analista-de-contratacion"];
      });
      setTimeout(() => {
        resolve( this.formSection[0].fields);
      }, 350);

    });
    return promise;
  }
  editAgreements(){
    this.formGroup.enable();
    this.isEdit= true
    this.doAsyncTask(['coordinador-de-contratacion']).then(() => this.seeForm=true);
    this.disabledOthes = false
    this.myControl.enable();
    this.campaign.enable();
  }
  rejectionDialog(){
    this.isChange = false;
    const contractForm = this.dialog.open( RejectionComment, {
      disableClose: true,
    })
    contractForm.afterClosed().subscribe(res=>{
      if(res){
        this.agreements['status']= {name:'Rechazado', comment: res}
           this.serviceCandidate.saveAgreement(`candidate/json/${this.data.candidateId}/agreementRules`, this.agreements).subscribe(
             answer=>{
               this.AlertsService.alertSuccess(`¡Rechazado!`, 'La reglas de contrato han cambiado de estado con éxito')
              this.isChange = true;
             }
           )
      }

    })
  }
  agreementsStatus(){
    this.isChange = false;
    let saveconfirm= this.AlertsService.alertConfirm(`¿Desea aceptar las reglas del contrato?`)
    saveconfirm.then(res=>{
      if(res.isConfirmed){
       this.agreements['status']= {name:'Aprobado'}
          this.serviceCandidate.saveAgreement(`candidate/json/${this.data.candidateId}/agreementRules`, this.agreements).subscribe(
            answer=>{
              this.AlertsService.alertSuccess(`¡Aprobado!`, 'La reglas de contrato han cambiado de estado con éxito')
              this.isChange = true;
              // this.dialogRef.close(true);
            }
          )
        }
    })
  }
  close(event){
    if(this.isEdit){
      this.isEdit= false
      this.doAsyncTask([]).then(() => this.seeForm=true);
      this.disabledOthes = true
      this.myControl.disable()
    }else{
      if(event){
        this.dialogRef.close();
      }
    }

  }
  closeDialof(){
    if(this.isChange){
      this.dialogRef.close(true);
    }
    else{
      this.dialogRef.close();
    }
  }

  hasComition(){
    this.comition = !this.comition;
    if(this.specialComition == true)
    {
      this.specialComition = false;
      this.formGroup.get("hasSpecialComitionFrom").setValue("");
      this.formGroup.get("specialComitionDetails").setValue("");
    }
  }

  hasSpecialComition(){
    this.specialComition = !this.specialComition;
    if(this.specialComition == false)
    {
      this.formGroup.get("specialComitionDetails").setValue("");
    }
  }

  hasboification(){
    this.boification = !this.boification;
    if(this.boification == false)
    {
      this.formGroup.get("bonificationDetailsrom").setValue("");
    }
  }

   /**
     * Metodo el cual lista los tipos de cuentas bancarias
     * @author Carlos Nieto
     * @return json
     */
    getKindAccounts() {
      this.kindAccountService.getKindAccounts().subscribe(resp => {
      this.kindAccountList = resp;
      }
    );
  }

}

@Component({
  selector: 'create-sign',
  templateUrl: 'rejection-comment.html',
  styleUrls: ['./contract-creation.component.sass']
})
export class RejectionComment {
  comment:any

  constructor() {}

}
