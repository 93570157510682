<i class="fi-rr-cross-small" style="float: right; font-size: 32px; color: #353535;" mat-icon-button mat-dialog-close></i>

<div class="col-md-12 p-5">
  <div class="mb-4" style="font-weight: 700; font-size: 28px;">Cargar documento</div>
  <mat-divider role="separator" class="mat-divider mat-divider-horizontal" aria-orientation="horizontal"></mat-divider>
  
  <div class="mt-4">
    <input type="file" (change)="onFileSelected($event)" enctype="multipart/form-data" accept=".xlsx, .xls" />
  </div>
  <div class="mt-3">
    <button (click)="downloadTemplate()" type="button" mat-raised-button color="primary">Descargar Plantilla <i class="fi-rr-download"></i></button>
  </div>
</div>

<div class="col-md-12 p-5 pt-0">
  <div class="row py-3 d-flex justify-content-end">
    <div class="col-md-3">
      <button (click)="cancelSave()" type="button" mat-raised-button class="w-100" style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Cancelar</button>
    </div>
    <div class="col-md-3">
      <button (click)="sendDataUpload()" type="button" mat-raised-button color="primary" class="w-100" style="height: 48px; font-weight: 600;" [disabled]="!selectedFile">Guardar</button>
    </div>
  </div>
</div>