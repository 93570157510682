import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable }   from 'rxjs';

import { environment }  from 'src/environments/environment';
import { Project } from '../../modules/chat/models/project';

@Injectable({
  providedIn: 'root'
})
export class MixinsService {

  public url: string;

  constructor(private _http: HttpClient) { 
    this.url = environment.LIVECHAT_URL;
  }

  // Método para sacar ala gente del redis
  logoutRedis(param:any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params  = JSON.stringify(param);
    return this._http.post(this.url + 'mixins/logout-agent/', params, {headers: headers});
  }

  // Método para notificar agente pegado en redis
  agentStuckInRedis(param:any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params  = JSON.stringify(param);
    return this._http.post(this.url + 'mixins/redis-notify-email', params, {headers: headers});
  }

  // Método para notificar un problema de conexión
  connectionIssues(param:any, project:string): Observable<any>{
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params  = JSON.stringify(param);
    return this._http.post(this.url + `mixins/fails/${project}`, params, {headers: headers});
  }

  // Método para notificar un problema de conexión
  getQueueInfo(project: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + `mixins/queue/${project}`, { headers: headers });
  }

  // Método para traer informacion resumida de conversaciones en linea
  getTableDashboardInfo(project: string): Observable<any>{
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + `mixins/dashboard/table/${project}`, { headers: headers });
  }
}
