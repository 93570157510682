<div class="admin-users">
  <div class="header">
    <button mat-icon-button (click)="backRoute()">
      <div class="display:flex">
        <i class="fi-rr-angle-small-left" style="font-size:24px;"></i>
        <span
          style="vertical-align: super;font: normal normal 600 16px/25px Poppins;letter-spacing: 0px;color: #353535;opacity: 1;">Regresar</span>
      </div>
    </button>
    <br>
    <br>
    <h2 style="height: 33px;font: normal normal bold 24px/35px Poppins;letter-spacing: 0px;color: #353535;opacity: 1;">
      Asignación de reportes a usuarios</h2>
    <p>Bienvenido al módulo de asignación de reportes, recuerda que puedes<br>filtrar por<strong> cualquier criterio que
        encuentres en la tabla.</strong></p>
  </div>

  <div class="row col-12 filter" style="--bs-gutter-x: 0rem !important;">
    <mat-form-field class="col-3" appearance="fill" [formGroup]="reactiveForm">
      <mat-label>Filtro de búsqueda</mat-label>
      <input matInput (keydown.enter)="applyFilter($event.target.value)" formControlName="filter" #input>
      <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
    </mat-form-field>
    <div class="col-6" *ngIf="show == false"></div>
    <div class="col-5" *ngIf="show == true"></div>
    <!-- Divs de botones de acciones -->
    <!--  <div class="col-3" style="text-align: right;" *ngIf="show == false">
          <button mat-raised-button color="primary" (click)="saveUser()" style="height:48px">Crear nuevo usuario</button>
          <button mat-raised-button color="ligth" class="myButton2" (click)="Hide()">Activar filtros avanzados</button>
      </div>
      <div class="col-4" style="text-align: right;" *ngIf="show == true">
        <button mat-raised-button color="ligth" (click)="rolMigration()" class="myButton1">Migración masiva</button>
        <button mat-raised-button color="primary" (click)="saveUser()" style="height:48px">Crear nuevo usuario</button>
        <button mat-raised-button color="ligth" class="myButton2" (click)="Hide()">Desactivar filtros avanzados</button>
      </div> -->
  </div>

  <div class="table-container">
    <div class="mat-elevation-z1">
      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="accion">
          <th class="start" mat-header-cell *matHeaderCellDef mat-sort-header> Acciones</th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="addPermisions(row.rrhh_id)">
              <i class="fi-rr-file"></i>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre y apellido </th>
          <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>

        <ng-container matColumnDef="id_number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Identificación </th>
          <td mat-cell *matCellDef="let row"> {{row.id_number}} </td>
        </ng-container>

        <ng-container matColumnDef="userName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre de Usuario </th>
          <td mat-cell *matCellDef="let row"> {{row.username}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Correo </th>
          <td mat-cell *matCellDef="let row"> {{row.email}} </td>
        </ng-container>

        <ng-container matColumnDef="campaign">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Campaña </th>
          <td mat-cell *matCellDef="let row"> {{row.campaign}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro: "{{input.value}}"</td>
        </tr>
      </table>

      <mat-paginator [length]="length" [pageSize]="pageSize" (page)="handleChangePage($event)"
        [pageSizeOptions]="pageSizeOptions" #paginator>
      </mat-paginator>
    </div>
  </div>
</div>