import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'arrayToString'
})
export class ArrayToStringPipe implements PipeTransform {

	/**
	 * Metodo que vuelve un array de datos en un string separado
	 * @param lista:any[] array de datos con la que se va a construir 
	 * @param saltoLinea:boolean agrega un salto de linea en medio de los datos concatenados al string
	 * @return string 
	 */
	transform(lista: any[], saltoLinea: boolean): string {	
		if (lista !== undefined && lista.length > 0) {
			let str = "";
			lista.forEach(element => {
				str += element
				if (saltoLinea) {
					str += "-"
				}
			});
			return str;
		} else {
			return "";
		}

	}

}
