import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatrizService } from 'src/app/modules/calidad/services/matriz.service';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
  selector: 'app-detalle-control',
  templateUrl: './detalle-control.component.html',
  styleUrls: ['./detalle-control.component.sass']
})
export class DetalleControlComponent implements OnInit {
  private uuid = '0362ea6-909a-11ee-b9d1-0242ac120002';
  public config: any;

  title:string = '';
  tipo:string = '';
  creacion:string = '';
  minimo = 0;
  maximo = 0;
  peso_actual = 0;

  id_matriz;
  tipo_peso;
  campania;
  constructor(
    private aRoute:ActivatedRoute,
    private matrizService:MatrizService,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
    this.aRoute.paramMap.subscribe(params=>{
      this.id_matriz = params.get('id');
    });
    this.getMatrixById();
  }


  getMatrixById(){
    const lang = this.configService.lang;
    this.matrizService.getMatrixById(this.id_matriz).subscribe((resp:any)=>{
      this.title = resp[0].matriz.nombre;
      this.tipo = lang === 'es' ? resp[0].matriz.tipo_matriz.nombre : resp[0].matriz.tipo_matriz.en;
      this.creacion = resp[0].matriz.created_at;
      this.minimo = resp[0].matriz.valor_peso_validacion.minimo;
      this.maximo = resp[0].matriz.valor_peso_validacion.maximo;
      this.peso_actual = resp[0].matriz.peso_actual;
      if (resp[0].campaign.length == 0) {
        this.campania = {name: 'Ninguna'}
      }else{
        this.campania = resp[0].campaign;
      }
    });
  }

}
