import { Pipe, PipeTransform } from '@angular/core';
import { Templates } from '../modules/chat/models/templates'

@Pipe({
  name: 'filtertemplate'
})
export class FiltertemplatePipe implements PipeTransform {

  transform( template: Templates[], page: number = 0 ): Templates[] {
    console.log ('AQUI ESTOY ********', template)
    return template.slice(page, page + 5);
  }

}
