<div class="px-5 py-3">
  <span class="mb-1" style="font-size: 28px; font-weight: 700;" mat-dialog-title>
    Creación de etiquetas
  </span>
  <mat-divider></mat-divider>
  <div class="mt-3" mat-dialog-content>
    <form [formGroup]="formCreateLabel">
      <p style="font-size: 16px; font-weight: 400;">
        En este espacio podrás crear etiquetas <b>temporales</b> para la descarga de las llamadas.
      </p>
      <div class="row mt-5">
        <div class="col-md-12">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Nombre de la etiqueta</mat-label>
            <input matInput placeholder="Nombre de la etiqueta" formControlName="nameLabel">
            <mat-error *ngIf="errorsForm.nameLabel.errors">
              <span *ngIf="errorsForm.nameLabel.errors.required">Este campo es requerido</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-5 py-1 justify-content-end">
        <div class="col-md-4">
          <button type="button" mat-raised-button mat-dialog-close class="w-100" style="height: 48px;">Cancelar</button>
        </div>
        <div class="col-md-4">
          <button type="submit" mat-raised-button color="primary" class="w-100" style="height: 48px;"
            (click)="createLabel()">Crear</button>
        </div>
      </div>
    </form>
  </div>
</div>