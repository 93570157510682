import { Component, OnInit, ElementRef, ViewChild, NgZone, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalCreateRequestComponent } from './modal-create-request/modal-create-request.component';
import { RequestsService } from './../../services/requests.service';
import { ModalValidateComponent } from './modal-validate/modal-validate.component';
import { ModalDeleteComponent } from './modal-delete/modal-delete.component';
import { CampaignsService } from './../../services/campaigns.service';
import { ScrollDispatcher, CdkScrollable } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.sass']
})
export class RequestsComponent implements OnInit, AfterViewInit {

  @ViewChild('filterNam') filterNam: ElementRef;


  allCampains = [];
  allCampaignsToPipe = [];
  nextPageUrlCampaign = false;

  requestResponse: any;
  campaigns: any = [];

  stateId = '';
  allCampaigns: any = [];
  campaignId = null;
  campaign;

  currentPageCampaign = 1;
  filterName = '';

  campaignRequestState = null;

  toolbar = false;

  cdkScrolling;

  requestsStates = [];

  constructor(public dialog: MatDialog,
              private serviceBpms: RequestsService,
              private campaignService: CampaignsService,
              private scrollDispatcher: ScrollDispatcher,
              private zone: NgZone) { }

  ngOnInit(): void {

   this.allCampaignsToPipe = this.campaignService.getCampaings();

   this.serviceBpms.getAllCampaigns().subscribe( (resp: any) => {
        this.allCampaigns = resp;
        this.generalInquiries(true);
        this.serviceBpms.getRequestStates().subscribe(( resp: any) => {
          this.requestsStates = resp;
        });
    });
  }

  ngAfterViewInit(): void {
    this.scrollDispatcher.scrolled().subscribe((cdk: CdkScrollable)  => {
      this.zone.run(() => {
        this.cdkScrolling = cdk;
        const scrollPosition = cdk.getElementRef().nativeElement.scrollTop;
        if (scrollPosition > 150) {
            this.toolbar = true;
          } else {
            this.toolbar = false;
          }
        });
    });
  }

  createRequest(): void {
    const dialogCrear = this.dialog.open(ModalCreateRequestComponent, {
      minWidth: '80%',
      minHeight: '400px'
    });

    dialogCrear.afterClosed().subscribe( (refreshRequests) => {
  
     if (refreshRequests) { this.generalInquiries(true); }
    });
  }

  /**
   *
   * @param campaign La campaña, es obligatorio
   * @param cleanCampaign Arg de tipo boolean que termine limpiar el array campaings
   * @param indexCampaign Indice del array campaing, usado cuando se esta paginando
   * @param nextPage Variable usada para la paginacion
   *
   * Este metodo sera usado unicamente con el paginador
   *
   */
  getRequestByCampaing(campaign, cleanCampaign?, indexCampaign?, nextPage?): void {

    let idx = indexCampaign - 1;
    // Pendiente por verificar si borrar clean campaing
    if (cleanCampaign) {
      this.campaigns = [];
      this.nextPageUrlCampaign =  false;
      this.campaigns.push({
        campaign : campaign.name,
        id: campaign.id,
        requestStatus: 'Buscando solicitudes...',
        data: [],
        currentPage: null,
        prevPage: false,
        nextPage: false
      });
      idx = this.campaigns.length - 1;
    }
    const page = nextPage ? nextPage : 1;

    this.serviceBpms.getRequestsCampaign(campaign.id, page, this.stateId, this.filterName).subscribe(respuestaSolicitudes => {
      // IndexCampaign es cuando se quiere agregar mas solicitudes a una campaña (paginado)
      if (indexCampaign){
        this.campaigns[idx].data = [...this.campaigns[idx].data, ...respuestaSolicitudes.data];
        this.campaigns[idx].requestStatus = respuestaSolicitudes.data.length > 0 ?  null : 'No hay solicitudes disponibles';
        this.campaigns[idx].currentPage = respuestaSolicitudes.current_page;
        this.campaigns[idx].prevPage = respuestaSolicitudes.prev_page_url ? true : false;
        this.campaigns[idx].nextPage = respuestaSolicitudes.next_page_url ? true : false;
      }else{
        this.campaigns[idx].data = respuestaSolicitudes.data;
        this.campaigns[idx].requestStatus = respuestaSolicitudes.data.length > 0 ?  null : 'No hay solicitudes disponibles';
        this.campaigns[idx].currentPage = respuestaSolicitudes.current_page;
        this.campaigns[idx].prevPage = respuestaSolicitudes.prev_page_url ? true : false;
        this.campaigns[idx].nextPage = respuestaSolicitudes.next_page_url ? true : false;

      }
  });
  }

  /**
   *
   * @param campaignId ID de capaña
   * @param indexCampaign  Indice del array campaings
   * Metodo usado para paginar las solicitudes de cada campaña
   *
   */
  paginatorRequests(campaign, indexCampaign): void {
    const nextPage = this.campaigns[indexCampaign].currentPage += 1;
    this.getRequestByCampaing(campaign, false, indexCampaign + 1, nextPage);
  }

  /**
   *
   * @param cleanCampign Argumento de tipo boolean usado para limpiar el array campaings
   * Metodo que sera ejecutado inicialmente o cuando se quieran ver todas las campañas
   *
   */
  generalInquiries(cleanCampaign?): void {
    if (cleanCampaign){
      this.currentPageCampaign = 1;
      this.campaigns = [];
    }
    this.campaignRequestState = 'Buscando campañas...';
    this.campaignId =  null;
    this.serviceBpms.consultarCampaings(this.currentPageCampaign, this.stateId, this.filterName).subscribe( async respuestacampaigns => {
        this.nextPageUrlCampaign = respuestacampaigns.next_page_url ? true : false;
        for ( const campaing of respuestacampaigns.data) {
            this.campaigns.push({
              campaign : campaing.name,
              id : campaing.id,
              requestStatus: 'Buscando solicitudes...',
              data: [],
              currentPage: null,
              prevPage: false,
              nextPage: false
            });

            await new Promise ((resolve, reject) => {
              const lastIndex = this.campaigns.length - 1;
              this.campaignRequestState = null;
              this.serviceBpms.getRequestsCampaign(campaing.id, 1, this.stateId, this.filterName).subscribe(e => {
                 this.campaigns[lastIndex].requestStatus = null;
                 this.campaigns[lastIndex].data = e.data;
                 this.campaigns[lastIndex].currentPage = e.current_page;
                 this.campaigns[lastIndex].prevPage = e.prev_page_url ? true : false;
                 this.campaigns[lastIndex].nextPage = e.next_page_url ? true : false;
                 resolve(e);
              });
            });
      }
        if (respuestacampaigns.data.length === 0) { this.campaignRequestState = 'No tienes campañas disponibles'; }
    });
  }

  /**
   *
   * @param stateId Variable de id que es asignada a la variable global [this.stateId]
   * Metodo basicamente actualiza la variable global stateId y ejecuta el query consulta general
   * Este metodo retornada todas las solicitud con un estado Activo / Cancelado
   */
  filterByState(stateId): void {
    this.stateId = stateId;
    if (this.campaignId) {
      this.getRequestByCampaing(this.campaignId, true);
    } else {
      this.generalInquiries(true);
    }
  }

/**
 *
 * @param campaingId Variable de tipo ID
 * Este metodo asigna el id de la campaña y ejecuta el metodo requestbyCampaing para actualizar unicamente solicitudes
 * de la campana seleccionada, en caso que campaingID sea empty entonces se asume que se quieren ver todas las campañas
 */

  filterByCampaing(campaign): void {
    if (!campaign){
      this.currentPageCampaign = 1;
      this.filterNam.nativeElement.value = '';
      this.filterName = '';
      this.generalInquiries(true);
    }else{
      this.campaign = campaign;
      this.getRequestByCampaing(campaign, true);
    }

  }

  modalValidate(request): void {
   const requestDetailDialog = this.dialog.open(ModalValidateComponent, {
      width: '80%',
      data: {item: request},
      disableClose: true
    });
   requestDetailDialog.afterClosed().subscribe((refreshRequests) => {
      if (refreshRequests) { this.generalInquiries(true); }
    });
  }

  deleteRequest(request): void {
    request.idEstadoSolicitud = 2;
    const deleteDialog =  this.dialog.open(ModalDeleteComponent, {
      width: '558px',
      height: 'auto',
      position: {top: '10px'},
      data: {item: request},
      disableClose: true,
      panelClass: 'custom-dialog-container'
    });

    deleteDialog.afterClosed().subscribe(() => {
      this.generalInquiries(true);
    });

  }


  /**
   * Metodo que consulta mas campaña, tomando en cuenta que las campañas tambien estan paginadas
   * */

  moreCampaigns(): void {
    this.currentPageCampaign += 1;
    this.generalInquiries();
  }

  /**
   *
   * @param name Metodo que actualiza la variable global [filterName] y posteriormente ejectuta el metodo que retonar todas
   * las campañas
   */
  filterByName(name): void {
    this.filterName = name;
    if (this.campaignId) {
      this.getRequestByCampaing(this.campaignId, true);
    } else {
      this.generalInquiries(true);
    }
  }

}
