<div>
  <h2>Cargar nueva versión de plantilla</h2>
  <p>Aquí podrás cargar o actualizar la plantilla, recuerda que al subir un archivo reemplazaras
    el existente</p>
  <br>
  <form (ngSubmit)="onSubmit()" #uploadForm="ngForm" enctype="multipart/form-data">
    <div *ngIf="fileName">Archivo existente: {{fileName}} </div>
    <div>
      <label for="fileInput" class="label-b">Seleccionar Archivo:  </label>
      <input type="file" class="upload" id="fileInput" name="fileInput"
             (change)="onFileChange($event)" accept=".doc, .docx, .pdf" required>
    </div>
    <br><br>
    <div style="text-align: right">
      <button type="submit" mat-flat-button color="primary"
              style="margin-right: 1em;"
              [disabled]="selectedFile == null">Guardar</button>
      <button type="button" mat-stroked-button (click)="onCancel()">Cancelar</button>
    </div>
  </form>
</div>
