import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-files-upload',
  templateUrl: './files-upload.component.html',
  styleUrls: ['./files-upload.component.sass']
})
export class FilesUploadComponent implements OnInit {
  @Output() sendFiles = new EventEmitter<any>();
  files: any = [];

  constructor() { }

  ngOnInit(): void {
  
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-02-11
    * Metodo que agrega un archivo a la lista de archivos. 
  */
  addFile(): void {
    const inputFile = document.getElementById('inputFile') as HTMLInputElement;
    if (inputFile) {
      for (let i = 0; i < inputFile.files!.length; i++) {
        const file = inputFile!.files![i];
        this.files.push({ file });
      }
    }
    this.sendFiles.emit(this.files);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-02-11
    * Metodo que elimina un archivo de la lista de archivos. 
    * @param nameFile Nombre del archivo a eliminar. 
  */
  removeFile(nameFile: string): void {
    const filterListFiles = this.files.filter(item => item.file.name !== nameFile);
    this.files = filterListFiles;
    this.sendFiles.emit(this.files);
  }
}