import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { SPEECH_URL } = environment;

@Injectable({
  providedIn: 'root'
})
export class ViewService {
  constructor(private httpClient: HttpClient) { }

  /**
   * Metodo que retorna los errores generados por HTTP. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * Metodo que retorna campañas de vicidial (selector)
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param id Id del servidor seleccionado.   
  */
  getCampaigns(id_server: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}get_campaign/${id_server}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Listado de los grupos de vicidial (selector)
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param campaign_id id campaña seleccioada.   
  */
  getGroups(campaign_id: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}get_group/${campaign_id}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo para obtener listado de tipificaciones de vicidial (selector)
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param campaign_id Id de la campaña seleccionada.
  */
  getTipifications(campaign_id: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}get_tipification/${campaign_id}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo para obtener listado skills de vicidial (selector)
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param id_typeCall id tipo de la llamada outboung o inboun.   
   * @param campaign_id id de la campaña seleccionada.   
  */
  getSkills(id_typeCall: number, campaign_id: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}get_skills/${id_typeCall}/${campaign_id}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo busca segun filtros activos las grabaciones
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param paginator configuración de paginación (pageSize y pageIndex).
   * @param filters filtros de busqueda
  */
  searchVicidial( paginator: any, filters: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}buscar?page=${paginator.pageIndex}&perPage=${paginator.pageSize}`, filters).pipe(
    catchError(this.handleError)
    );
  }

  /**
   * Metodo para solicitar la descarga de los audios y paginar la informacion
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param recordings array de recordings
   * @param descargarAudios boleano si hay qque realizar descarga
   * @param campaign_id campaña id
   * @param paginator configuración de paginación (pageSize y pageIndex).
  */
  searchDownload(paginator: any, recordings: any, descargarAudios: boolean, campaign_id: any): Observable<any> {
    const body = { recording_ids: recordings, descargarAudios: descargarAudios, campaign_id: campaign_id  };
    return this.httpClient.post<any>(`${SPEECH_URL}buscarRecor?page=${paginator.pageIndex}&perPage=${paginator.pageSize}`, body).pipe(
    catchError(this.handleError)
    );
  }
  
  /**
   * Metodo para validar estado error
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param idrecordin array de objetos
  */
  analyticsState(recordings: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}state`,recordings).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo para validar estado de error en detalle
   * @author Yeison Sepulveda
   * @createdate 2024-09-19
   * @param idrecordin array de objetos
  */
  analyticsStateDetail(recording_id: any): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}state/error-details/${recording_id}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Obtener los filtros y grabaciones e integracion de CRM2 y Vicidial
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param paginator configuración de paginación (pageSize y pageIndex).
   * @param filters filtros de busueda
  */
  getFilter(paginator: any, filters){
    return this.httpClient.post<any>(`${SPEECH_URL}searchRecordingsCrm?page=${paginator.pageIndex }&perPage=${paginator.pageSize}`,filters).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Obtener los filtros y grabaciones e integracion de CRM2 y Vicidial
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param paginator configuración de paginación (pageSize y pageIndex).
   * @param filters filtros de busqueda
  */
  getFilter2(paginator: any, filters){
    return this.httpClient.post<any>(`${SPEECH_URL}searchRecordingsCrm2?page=${paginator.pageIndex}&perPage=${paginator.pageSize}`,filters).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Descargar reporte de excel
   * @author Yeison Sepulveda
   * @createdate 2024-04-04
   * @param filters json de la informacion
  */
  downloadReport(filters){
    return this.httpClient.post<any>(`${SPEECH_URL}downloadReport`,filters, {responseType: 'blob' as 'json'}).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo que se encarga de obtener las campañas asociadas a carteras
   * @author Juan David Guerrer Vargas
   * @returns {Obseravble<any>} Observable<any>
   */
  getCampaignsCarteras():Observable<any>{
    return this.httpClient.get<any>(`${SPEECH_URL}speech/carteras/showcampaign`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo que se encarga de obtener lis listados de carteras segun su campaña
   * @author Juan David Guerrer Vargas
   * @returns {Obseravble<any>} Observable<any>
   */
  getListCarteras(id_campaign):Observable<any>{
    return this.httpClient.get<any>(`${SPEECH_URL}speech/carteras/showlists/${id_campaign}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo   que se encarga de obtener el listado de grabaciones para carteras
   * @param filterSearch:any {any} filtrado { list_ids:[], phone:"", start_date:"",end_date:"", per_page:"", from_page:"" }
   * @author Juan David Guerrer Vargas
   * @returns {Obseravble<any>} Observable<any>
   */
  getRecordingsCarteras(filterSearch:any):Observable<any>{
    return this.httpClient.post<any>(`${SPEECH_URL}speech/carteras/paginateRecordingsCartera`, filterSearch).pipe(
      catchError(this.handleError)
    );
  }


}