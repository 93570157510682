import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Import } from '../../../interfaces/import';
import { CandidatesService } from '../../../services/candidates.service';
import { saveAs as importedSaveAs } from "file-saver";
import Swal from 'sweetalert2';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';

@Component({
  selector: 'app-candidate-upload',
  templateUrl: './candidate-upload.component.html',
  styleUrls: ['./candidate-upload.component.sass']
})
export class CandidateUploadComponent implements OnInit {

  @ViewChild('fileUpload', {static: false})
  fileUpload: ElementRef;

  requestId;
  fileUpdaloaded = false;
  candidateSummaryUpdated;
  userName;


  constructor(
    @Inject(MAT_DIALOG_DATA) public datos: any,
    private candidateService: CandidatesService,
    public dialog: MatDialogRef<CandidateUploadComponent>,
    private alert: AlertsService,
    private authService: AuthService
    ) {
     this.requestId = datos.requestId;
  }

  ngOnInit(): void {
    this.alertCandidates();
    this.userName = this.authService.decryptToken();

  }

  onClick() {
    this.alert.alertConfirm('¿Deseas continúar?',`Recuerda, esta acción generará el cargue masivo de los candidatos`).then(
      res=>{
        if(res.isConfirmed)
          {
            const fileUpload = this.fileUpload.nativeElement; fileUpload.onchange = () => {
              for (let index = 0; index < fileUpload.files.length; index++) {
              const file = new Import( fileUpload.files[index]);
              this.uploadFile(file);
              }
            };
            fileUpload.click();
          }
      }
    );
  }

  uploadFile(file) {
    this.candidateService.importData(file , {requestId: this.requestId }).subscribe((resp: any) => {
      this.fileUpload.nativeElement.value = '';
      this.fileUpdaloaded = true;
      this.candidateSummaryUpdated = resp;
      },err=>{
        this.dialog.close()
      });
  }

  exportFormats(){
    this.candidateService.downloadFormat().subscribe( (e: any) => {
      importedSaveAs(e, 'carga-masiva-candidatos.xlsx');
    })
  }

  alertCandidates() {
    /*  swal('Advertencia Señor usurio / a debe tener en cuenta antes de subir el archivo a importar:',
       'La cédula no puede tener ni puntos ni comas y debe ser menor a 15 dígitos.', 'warning'); */
       let str = 'La cédula del candidato no pueden tener ni puntos ni comas y deben ser menores a 15 dígitos ' + '\n' + 'El número de celular no debe tener más de 10 dígitos ej: 344556690'
       + '\n' +  'El email debe ser ej: ejemplo@montechelo.com';
       Swal.fire({
         title: 'Advertencia carga masiva de archivos',
         html: '<pre style="font-family: Poppins; font-size: 14px;"> '+ str + ' </pre>',
         icon: 'warning',
         width: 'auto',
        //  showCancelButton: true,
         showConfirmButton: true,
         confirmButtonColor: '#2CABBC',
        /*  cancelButtonColor: '#000',
         cancelButtonText: 'Cancelar', */
         confirmButtonText: 'Aceptar',
         reverseButtons: true,

       })
   }

}
