import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { SPEECH_URL } = environment;

@Injectable({
  providedIn: 'root'
})
export class ResultService {
  constructor(private httpClient: HttpClient) { }

  /**
   * Metodo que retorna los errores generados por HTTP. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-19
   * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * Metodo que retorna los resultados del analisis
   * @author Yeison Sepulveda
   * @createdate 2024-02-19
  */
  showAnalyticsResults(id: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}result/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Actualizar/ editar una transcripcion de los resultados de analisis
   * @author Yeison Sepulveda
   * @createdate 2024-06-19
   * @param request
  */

  updateTranscription(request: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}result/update_transcript`, request).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo que retorna los resultados de sentiemientos massivo
   * @author Yeison Sepulveda
   * @createdate 2024-02-19
  */
  massiveAnalyticsResults(request: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}result/result_masive`, request).pipe(
      catchError(this.handleError)
    );
  }
}