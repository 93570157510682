import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { OrdenesService } from '../../../../services/rest/ordenes.service';
import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import { DestroyComponentService } from '../../../../../../core/services/utils/destroy-component.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToWords } from '../../../../../../core/to-words/to-words';
import { OrdenCompra } from '../../../Class/orden-compras';
import { ProveedoresService } from '../../../../../portal/services/proveedores.service';
import { ArchivosService } from '../../../../services/rest/archivos.service';
import { ObservationCancelInterface } from '../../../interfaces/observation.cancel.interface';
import { CurrencyMaskInputMode } from 'ngx-currency';


@Component({
	selector: 'app-orden-compra-frag',
	templateUrl: './orden-compra-frag.component.html',
	styleUrls: ['./orden-compra-frag.component.sass']
})
export class OrdenCompraFragComponent implements OnInit {
	firstFormGroup: FormGroup;
	secondFormGroup: FormGroup;
	isEditable = true;
	toWords = new ToWords();
	itemsOrder: any[] = [];
	valorFinal = 0;
	valorIva = 0;
	fragmentar = true
	proveedor: any;
	view: boolean = false;
	disableButton: boolean;
	archivoSolicitud: boolean;
	Observacion_cancel: ObservationCancelInterface = {
		valueInput :"",
		disabled:false
	};
  options = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    inputMode: CurrencyMaskInputMode.NATURAL
  };


	constructor(private formBuilder: FormBuilder,
		private form: FormBuilder,
		private ordenService: OrdenesService,
		private proveedorService: ProveedoresService,
		private destroyService: DestroyComponentService,
		private alertsService: AlertsService,
		private archivoService: ArchivosService,
		private dialogRef:MatDialogRef<OrdenCompraFragComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit(): void {


		if (this.data.type === 'view') {
			this.fragmentar = false;
			this.view = true;
		}

		//this.infoFile();

		const proveedor = {
			nit: this.data.ordenCompra.provider.nit
		}
		this.proveedorService.getProveedores(1, proveedor).subscribe((prov) => {
			if (prov.data.length > 0) {

				this.proveedor = prov.data[0];

			} else {
				this.proveedor = "No se ha encontrado proveedor."

			}
		});
		this.crearControles();
		this.setEdit();

		//Se consulta por metodo get si hay una observacion registrada
		//dependiendo del estado de la orden de compra y la cancelacion se define que partes mostrar del componente
		this.ordenService.procesarObservacion(this.data.ordenCompra.id, null, "get").subscribe(
			(resp) => {

				if(resp){
					this.Observacion_cancel = {
						valueInput :resp.observation,
						disabled:true
					}
				}else{

					this.Observacion_cancel = {
						valueInput :"",
						disabled:false
					}
				}


			}//Fin del resp (Respuesta subscribe)
		);//Fn del obsrbavle

	}





	crearControles() {
		this.firstFormGroup = this.formBuilder.group({
			data: [''],
			items: this.form.array([])
		});

		this.secondFormGroup = this.formBuilder.group({

			observations: new FormControl({ value: '', disabled: this.view }, [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
			sub_total: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
			discount: new FormControl({ value: '',  disabled: this.view }, [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
			iva: new FormControl({ value: '', disabled: this.view }, [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
			total: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
			value_in_letters: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
			delivery_date: new FormControl({ value: '', disabled: this.view }, [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
			pay: new FormControl({ value: '', disabled: this.view }, [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
			payment_condition: new FormControl({ value: '', disabled: this.view }, [Validators.required]),
			payment_deb: new FormControl({ value: '', disabled: this.view }),
			payment_percent: new FormControl({ value: '', disabled: this.view }),
			credit_condition: new FormControl({ value: '', disabled: this.view }),
			warranty: new FormControl({ value: '', disabled: this.view }, [Validators.required]),
			policy: new FormControl({ value: '', disabled: this.view }, [Validators.required]),
			pay_value: new FormControl({ value: '', disabled: true }, []),
			observ_cancel: new FormControl({ value: '', disabled: false}, [ Validators.maxLength(255), Validators.minLength(5)]),
      ciudad              : new FormControl({ value: '', disabled: this.view}, [Validators.required, Validators.minLength(4), Validators.maxLength(50)]),
      direccion           : new FormControl({ value: '', disabled: this.view}, [Validators.required, Validators.minLength(4), Validators.maxLength(20)]),
      observacionCondiciones : new FormControl({ value: '', disabled: this.view}, [Validators.required, Validators.minLength(20), Validators.maxLength(100)])

		});


	}
	get error(): any { return this.secondFormGroup.controls; }
	get errorItem(): any { return this.firstFormGroup.controls }

	get items(): FormArray {
		return this.firstFormGroup.get('items') as FormArray;
	}

	newItems(): void {
		const newItems = this.form.group({
			id: new FormControl('', []),
			description: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
			specs: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
			unit: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(5), Validators.minLength(1)]),
			pendiente: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(5), Validators.minLength(1)]),
			newUnit: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.maxLength(5), Validators.minLength(1)]),
			unit_value: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(5), Validators.minLength(1)]),
			valorIva: new FormControl({ value: '', disabled: true }, [Validators.required]),
			total_value: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(5), Validators.minLength(1)]),
      coin_type: new FormControl({ value: '', disabled: true }, [Validators.required])
		});
		this.items.push(newItems);
	}

	onStepChange(event) {
		if (event.selectedIndex == 2 && this.view != true) {
			this.calcularValores();
			this.validarPendiente();
		}
	}

	setEdit() {

		this.ordenService.getOrderItems(this.data.ordenCompra.id).subscribe((resp) => {

			if (this.data.type != 'view') {
				resp.data.map(item => {
					if (item.pendiente > 0) {
						this.itemsOrder.push(item);
					}
				});
			} else {
				this.itemsOrder = resp.data;
			}

			this.secondFormGroup.controls.pay.setValue(this.data.ordenCompra.pay);
			this.secondFormGroup.controls.payment_condition.setValue(this.data.ordenCompra.payment_condition);
			this.secondFormGroup.controls.payment_deb.setValue(this.data.ordenCompra.payment_deb);
			this.secondFormGroup.controls.payment_percent.setValue(this.data.ordenCompra.payment_percent);
			this.secondFormGroup.controls.credit_condition.setValue(this.data.ordenCompra.credit_condition);
			this.secondFormGroup.controls.pay_value.setValue(this.data.ordenCompra.pay_value);
			this.secondFormGroup.controls.warranty.setValue(this.data.ordenCompra.warranty);
			this.secondFormGroup.controls.policy.setValue(this.data.ordenCompra.policy);
			this.secondFormGroup.controls.observations.setValue(this.data.ordenCompra.observations);
			this.secondFormGroup.controls.sub_total.setValue(this.data.ordenCompra.sub_total);
			this.secondFormGroup.controls.discount.setValue(this.data.ordenCompra.discount);
			this.secondFormGroup.controls.iva.setValue(this.data.ordenCompra.iva);
			this.secondFormGroup.controls.total.setValue(this.data.ordenCompra.total);
			this.secondFormGroup.controls.value_in_letters.setValue(this.data.ordenCompra.value_in_letters);
			this.secondFormGroup.controls.delivery_date.setValue(this.data.ordenCompra.delivery_date);
      this.secondFormGroup.controls.observacionCondiciones.setValue(this.data.ordenCompra.observaciones_condiciones);
      this.secondFormGroup.controls.ciudad.setValue(this.data.ordenCompra.ciudad);
      this.secondFormGroup.controls.direccion.setValue(this.data.ordenCompra.direccion);

			this.itemsOrder.forEach((element, index) => {
				this.newItems();
				this.items.controls[index].get('id').setValue(element.id);
				this.items.controls[index].get('specs').setValue(element.specs);
				this.items.controls[index].get('description').setValue(element.description);
				this.items.controls[index].get('unit').setValue(element.unit);
				this.items.controls[index].get('unit_value').setValue(element.unit_value);
				this.items.controls[index].get('valorIva').setValue(element.iva);
				this.items.controls[index].get('total_value').setValue(element.total_value);
				this.items.controls[index].get('pendiente').setValue(element.pendiente);
				this.items.controls[index].get('newUnit').setValue(0);
        this.items.controls[index].get('coin_type').setValue(this.data.ordenCompra.coin_type);
			});
			if (this.view) {
				this.disableControls();
			}

		});
	}


	calcularValores() {
		let valorTotal = 0;
		this.valorIva = 0;

		//this.firstFormGroup.get('items').value.forEach((element, index) => {
		this.firstFormGroup.getRawValue()["items"].forEach((element, index) => {
			if (element['newUnit'] > 0) {
				valorTotal += element['total_value'];
				this.valorIva += (element['total_value'] * element['valorIva']) / 100;
			}
		});

		this.valorFinal = valorTotal + this.valorIva;
		let valorfinalletras = this.toWords.convert(this.valorFinal);

    if(this.firstFormGroup.getRawValue()['items'][0].coin_type === 'USD'){
      valorfinalletras = valorfinalletras.replace('PESOS','DOLARES');
    }else if (this.firstFormGroup.getRawValue()['items'][0].coin_type === 'MXN') {
      valorfinalletras = valorfinalletras.replace('PESOS','PESOS MEXICANOS');
    }

		this.secondFormGroup.controls.sub_total.setValue(valorTotal);
		this.secondFormGroup.controls.discount.setValue(0);
		this.secondFormGroup.controls.iva.setValue(this.valorIva);
		this.secondFormGroup.controls.total.setValue(this.valorFinal);
		this.secondFormGroup.controls.value_in_letters.setValue(valorfinalletras);
	}

	calcularValorTotal(indice) {
		let cantidad = this.firstFormGroup.getRawValue()["items"][indice].newUnit;
		let pendiente = this.firstFormGroup.getRawValue()["items"][indice].pendiente;
		let valor = this.firstFormGroup.getRawValue()["items"][indice].unit_value;


		if (cantidad > pendiente) {
			this.alertsService.alertError('Error', 'No se puede elegir una cantidad mayor a la cantidad pendiente.')
		}
		let valorTotal = (cantidad == undefined ? 0 : cantidad) * (valor == undefined ? 0 : valor);
		this.firstFormGroup.controls.items["controls"][indice].controls.total_value.setValue(valorTotal);


	}

	calculardiscount() {
		const descuento = this.secondFormGroup.get('discount').value;
		//const iva =this.secondFormGroup.get('iva').value;

		const valorFinal = this.valorFinal - descuento;
		let valorfinalletras = this.toWords.convert(valorFinal);

    if(this.firstFormGroup.getRawValue()['items'][0].coin_type === 'USD'){
      valorfinalletras = valorfinalletras.replace('PESOS','DOLARES');
    }else if (this.firstFormGroup.getRawValue()['items'][0].coin_type === 'MXN') {
      valorfinalletras = valorfinalletras.replace('PESOS','PESOS MEXICANOS');
    }

		if (valorFinal > 0) {
			this.secondFormGroup.controls.total.setValue(valorFinal);
			this.secondFormGroup.controls.value_in_letters.setValue(valorfinalletras);
		} else {
			this.alertsService.alertError('Error', 'No se puede aplicar un descuento superior al valor total')
		}
	}

	// cambioEstado(event, index){


	//   if (event === 100){
	//     this.firstFormGroup.controls.items["controls"][0].controls.valorIva.enable();
	//   } else {
	//     this.firstFormGroup.controls.items["controls"][0].controls.valorIva.disable();
	//   }

	// }

	cambioPagoValue(event) {
		if (event === 'Abono' || event === 'Anticipo' || event === 'Credito') {
			this.secondFormGroup.controls.pay_value.enable();
		} else {
			this.firstFormGroup.controls.pay_value.disable();
		}
	}

	validarPendiente() {
		let contador = 0;
		this.firstFormGroup.getRawValue()["items"].forEach((element) => {
			if (element['newUnit'] == 0) {
				contador++
			}
		})

		if (contador == this.firstFormGroup.getRawValue()["items"].length) {
			this.disableButton = true;
		} else {
			this.disableButton = false;
		}

	}


	saveOrder() {



		const orden = new OrdenCompra();
		orden.idPurchase = this.data.ordenCompra.id;
		orden.observations = this.secondFormGroup.getRawValue()["observations"];
		orden.sub_total = this.secondFormGroup.getRawValue()["sub_total"];
		orden.discount = this.secondFormGroup.getRawValue()["discount"];
		orden.iva = this.secondFormGroup.getRawValue()["iva"];
		orden.calculated_iva = this.valorIva;
		orden.total = this.secondFormGroup.getRawValue()["total"];
		orden.value_in_letters = this.secondFormGroup.getRawValue()["value_in_letters"];
		orden.delivery_date = this.secondFormGroup.getRawValue()["delivery_date"];
		orden.warranty = this.secondFormGroup.getRawValue()["warranty"];
		orden.pay = this.secondFormGroup.getRawValue()["pay"];
		orden.payment_deb = this.secondFormGroup.getRawValue()["payment_deb"];
		orden.payment_percent = this.secondFormGroup.getRawValue()["payment_percent"];
		orden.payment_condition = this.secondFormGroup.getRawValue()["payment_condition"];
		orden.credit_condition = this.secondFormGroup.getRawValue()["credit_condition"];
		orden.pay_value = (this.secondFormGroup.getRawValue()["pay_value"] == undefined ? '' : this.secondFormGroup.getRawValue()["pay_value"]);
		orden.policy = this.secondFormGroup.getRawValue()["policy"];
		orden.request_id = this.data.ordenCompra.request_id;
		orden.proveedor_nit = this.data.ordenCompra.provider.nit;
		orden.company_id = this.data.ordenCompra.company.id;
    	orden.observacionCondiciones = this.secondFormGroup.getRawValue()["observacionCondiciones"];
    	orden.ciudad = this.secondFormGroup.getRawValue()["ciudad"];
    	orden.direccion = this.secondFormGroup.getRawValue()["direccion"];
		//orden.PurchaseOrderItem   = this.firstFormGroup.value.items;
		orden.PurchaseOrderItem = this.firstFormGroup.getRawValue()["items"];
		this.ordenService.saveOrderFrag(orden).subscribe(resp => {
			if(resp){
				// this.alertsService.alertSuccess('Guardado', resp.data);
				this.alertsService.alertWithAnyProperties('Guardado', resp.data, 'success', 'Continuar').then(res => {
					if (res.isConfirmed) {
						this.dialogRef.close();
					}
				});
			}
		});
	}



	ngOnDestroy(): void {
		this.destroyService.destroyComponent();
	}

	private disableControls() {
		//this.firstFormGroup.controls["items"].disable();
		var arrayControl = this.firstFormGroup.get('items') as FormArray;

		for (let i = 0; i < arrayControl.length; i++) {
			const controlItems = arrayControl.at(i) as FormArray;
			for (var controlItem in controlItems.controls) {
				controlItems.controls[controlItem].disable();
			}

		}

		for (var control2 in this.secondFormGroup.controls) {

			if (!this.secondFormGroup.controls['observ_cancel']) {
				this.secondFormGroup.controls[control2].disable();
			}
		}

	}


	/*
	*/
	sendObservCancel() {
		//const observacion = new FormData();
		//observacion.append('observacion', this.secondFormGroup.getRawValue()["observ_cancel"]);
		//observacion.append('idSolicitud', this.data.ordenCompra.request_id);
		//observacion.append('type', "cancelacion");
		let $ordenObservale = this.ordenService.procesarObservacion(this.data.ordenCompra.id,
			this.secondFormGroup.getRawValue()["observ_cancel"], "post");
		if ($ordenObservale) {
			$ordenObservale.subscribe(resp => {
        this.alertsService.alertWithAnyProperties(
          'Guardado',
          'Tu observación se ha enviado satisfactoriamente',
          'success',
          'Continuar'
        ).then(res => {
          if(res.isConfirmed){
            this.dialogRef.close();
            return true;
          }
        })
			});

		} else {

			this.alertsService.alertError('Error en proceso', 'Lo sentimos no hemos logrado procesar tu peticion');

		}

	}


	/**
	 * @method  downloadCotizacion Permite la descarga de la Cotizacion Seleccionada para la solicitud
	 * @author Juan David Guerrero Vargas
	 * @create_date 18/03/2022
	 * @param   id_purchase_id | string PArametro ue recibe el Id de la solicitud donde se registra el archivoa descargar
	 */
	downloadCotizacion(id_solicitud_id:Number){

		this.archivoService.getArchivosSolicitud(id_solicitud_id, true).subscribe(
			resp => {
				if(resp){
					let e = resp.data[0]
					const archivo = {
						path: e.path
					  };

					this.archivoService.downloadOrder(e).subscribe( (data) => {
						const a = document.createElement('a');
						const file = new Blob([data], {type: e.fileContentType});
						a.href = URL.createObjectURL(file);
						a.download = e.name;
						a.click();

					},
					(error) =>{

						this.alertsService.alertError("Error al descargar", "Se ah producido un error en la descarga ");

					});
				}else{

					this.alertsService.alertError("No se encontro archivo adjunto", "No se ha encontrado ningun archivo adjunto seleccionado");

				}


			},
			error =>{
				this.alertsService.alertError("Archivo No encontrado", "Lo sentimos no hemos logrado encontrar el documento adjunto" );
			}
		);

	}

}
