import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DestroyComponentService } from '../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatDialog } from '@angular/material/dialog';
import { SaveGroupsComponent } from '../save-groups/save-groups.component';
import { GroupsService } from '../../../services/rest/groups.service';
import { DatePipe, Location } from '@angular/common';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-admin-groups',
  templateUrl: './admin-groups.component.html',
  styleUrls: ['./admin-groups.component.sass']
})
export class AdminGroupsComponent implements OnInit {

  // MatPaginator Inputs
  length;
  pageSize = 5;
  page = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  newgroupComponent: boolean = false;

  displayedColumns: string[] = ['acciones',  'name', 'description','state', 'created_at', 'nameUser','noUsers'];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(public groupsService: GroupsService,
              private destroyService: DestroyComponentService,
              private alertsService: AlertsService,
              public dialog: MatDialog,
              private location: Location,
              public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.getGroups(this.pageSize, this.page);
  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-05
   * Metodo que trae los grupos paginados
   * @param size cantidad de groupes a traer en la tabla
   * @param page pagina que va a mostrar la tabla
   */
  getGroups(size: number, page: number): void {

    if (this.dataSource.filter === ''){
      this.dataSource.filter = null;
    }
    this.groupsService.getGroups(size, page).subscribe((Response) => {
      this.dataSource.data = Response.data.data;
      this.dataSource.sort = this.sort;
      this.length = Response.data.total;
      this.pageSize = Response.data.per_page;

    });

  }
 /**
  * @author Jose Vicente Silva
  * @createdate 2021-02-04
  * Metodo que realiza el filtro
  * @param event datos para filtrar que son enviados desde el input
  */
  applyFilter(event: Event): void {

    const filterValue = (event.target as HTMLInputElement).value;

    this.groupsService.searchGroup(this.pageSize, filterValue).subscribe((Response) => {
      this.dataSource.data = Response.data.data;
      this.dataSource.sort = this.sort;
      this.length = Response.data.total;
      this.pageSize = Response.data.per_page;
    });
  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que se encarga del movimiento del paginador
   * @param event cuando se da click en el paginador trae este evento
   */
  pageEvent(event: any): void {

    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getGroups(this.pageSize, this.page);

  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que abre el modal de crear un nuevo group, al cerral el modal se refresca la tabla
   */
  newgroup(): void{
    const dialogNewgroup = this.dialog.open(SaveGroupsComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Crear grupo',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewgroup.afterClosed().subscribe(() => {
      this.ngOnInit();
    });

  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que abre el modal de editar usuario, envia un usuario, al cerrar el modal se refresca la tabla
   * @param group group a editar
   */
  editgroup(group: any): void{
    const dialogNewgroup = this.dialog.open(SaveGroupsComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Editar grupo',
        group
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewgroup.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }

  /**
   * @author Jose Vicente Silva
   * @createdate 2021-02-04
   * Metodo que elimina un group
   * @param group group a eliminar
   */
  deletegroup(group: any): void{
    this.alertsService.alertConfirm('¿Está seguro de eliminar este grupo?').then(result => {
      if(result.isConfirmed){
        this.groupsService.deleteGroup(group.id).subscribe((resp) => {
          this.alertsService.alertSuccess('Eliminado', resp.data);
          this.ngOnInit();
        });
      }
    });
  }
  /**
   * @author Jair Celis
   * @createdate 2021-04-27
   * Metodo que cambia el estado de un grupo
   * @param group group a eliminar
   */
  changeState(group){
    this.alertsService.alertConfirm('¿Está seguro de cambiar el estado del grupo?').then(result => {
      if(result.isConfirmed){
        this.groupsService.deleteGroup(group.id).subscribe((resp) => {
          this.alertsService.alertSuccess('Estado Actualizado', resp.data);
          this.ngOnInit();
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

  backRoute(){
    this.location.back();
  }

  /**
   * @author Nicoll Ramirez
   * Método para descargar el reporte general de los grupos
   */
  downloadReport() {
    this.groupsService.downloadReport().subscribe((resp) => {
      const dateToFile = this.datepipe.transform(new Date, 'short');
      importedSaveAs(resp, `Grupos_${ dateToFile }.xlsx`);
    });
  }

  /**
   * @author Nicoll Ramirez
   * Método para descargar el reporte de un grupo en específico
   */
  downloadGroup(row) {
    this.groupsService.downloadGroup(row.id).subscribe((resp) => {
      const dateToFile = this.datepipe.transform(new Date, 'short');
      importedSaveAs(resp, `${ row.name }_${ dateToFile }.xlsx`);
    });
  }

}
