<div class="admin-roles">
  <div class="header">
    <h1>Campañas<i class="fas fa-container-storage"></i></h1>
    <p>
      En este espacio podrás revisar y/o crear Campañas.<br />No olvides, "El
      respeto y la empatía son clave"
    </p>
  </div>
  <div class="row mb-4">
    <div class="col-md-3">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Filtro de búsqueda</mat-label>
        <input matInput (keydown.enter)="applyFilter($event)" (blur)="applyFilter($event)" #input>
        <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
      </mat-form-field>
    </div>
    <div class="d-flex col-md-9 justify-content-end pe-5">
      <div class="col-3">
        <button style="width: 100%" mat-raised-button color="ligth" (click)="downloadReport()" class="exportBtn">
          Descargar reporte
          <i class="fi-rr-download ms-2"></i>
        </button>
      </div>
      <div class="col-3">
        <button style="width: 100%" mat-raised-button color="primary" class="download" (click)="newCampaign()">
          Nueva Campaña
          <i class="fi-rr-add ms-2"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="table-container">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="acciones">
          <th class="start" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="editCampaign(row)">
              <i class="fi-rr-edit"></i>
            </button>
            <button mat-icon-button (click)="deleteCampaign(row)" *ngIf="row?.state == 1 && row.users == 0"
              #tooltip="matTooltip" matTooltip="Inactivar campaña" matTooltipPosition="above">
              <i class="fi-rr-cross-circle"></i>
            </button>
            <button mat-icon-button (click)="activateCampaign(row)" *ngIf="row?.state == 0" #tooltip="matTooltip"
              matTooltip="Activar campaña" matTooltipPosition="above">
              <i class="fi-rr-check"></i>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Fecha Creación
          </th>
          <td mat-cell *matCellDef="let row">{{ row.created_at | date }}</td>
        </ng-container>

        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Código</th>
          <td mat-cell *matCellDef="let row">{{ row.code }}</td>
        </ng-container>

        <ng-container matColumnDef="empresa">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Empresa</th>
          <td mat-cell *matCellDef="let row"> {{ row.company && row.company.name ? row.company.name : 'No registra' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Campaña</th>
          <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>
        <ng-container matColumnDef="users">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Integrantes</th>
          <td mat-cell *matCellDef="let row">{{ row.users }}</td>
        </ng-container>

        <ng-container matColumnDef="created_by">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Nombre del creador
          </th>
          <td mat-cell *matCellDef="let row">{{ row.created_by }}</td>
        </ng-container>

        <ng-container matColumnDef="estado">
          <th class="end" mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
          <td mat-cell *matCellDef="let row">
            <p *ngIf="row?.state == 0">Inactivo</p>
            <p *ngIf="row?.state == 1">Activo</p>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No hay datos que coincidan con el filtro: "{{ input.value }}"
          </td>
        </tr>
      </table>
      <mat-paginator
        [length]="length"
        [pageSize]="pageSize"
        (page)="pageEvent($event, input.value)"
        [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </div>
  </div>
</div>
