import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import Swal from 'sweetalert2';
import { NoveltiesService } from '../nomina/services/novelties.service';

@Component({
  selector: 'app-juridica',
  templateUrl: './juridica.component.html',
  styleUrls: ['./juridica.component.sass']
})
export class JuridicaComponent implements OnInit {
  public durationInSeconds = 10;
  nameUser = '';
  constructor(private snackBar: MatSnackBar,
    private noveltiesService: NoveltiesService,
    private authService: AuthService) { }

  ngOnInit() {
    const user = this.authService.getUser();
    this.nameUser = user.rrhh.name;
    // Mensaje sin asignación de horarios
    /*if (user.schedule_start == null) {
      let str = 'Nombre: ' + user.rrhh.name + '\n' +
        'número de idenficación: ' + user.rrhh.ID_number + '\n' +
        'Campaña: ' + user.rrhh.campaign;
      Swal.fire({
        icon: 'error',
        title: 'Sin asignación de horarios',
        html: '<pre style="font-family: Poppins">' + str + '</pre>',
        confirmButtonText: `Enviar notificación`,
        confirmButtonColor: '#2CABBC',
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.noveltiesService.sendEmail().subscribe(resp => {
            this.snackBar.open(resp.data, 'cerrar', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: this.durationInSeconds * 1000,
            });
          }, error => {
            this.snackBar.open('No fue posible enviar la solicitud para cargue de mallas', 'cerrar', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: this.durationInSeconds * 1000,
            });
          });
        }
      });
    }*/


  }

}
