
<div class="alert" *ngIf="alert && historial === false">

    <i (click)="closeAlert()" class="fi-rr-cross-circle"></i>
    <img style="width: 88px; height: 88px; margin: 0px 10px 0px 10px" src="../../../assets/images/Icono-campana.png">

    <div style="flex-direction: column" (click)="historial = true">
        <b>¡Tienes una nueva notificación!</b>
        <br>
        <span>Tienes un mensaje nuevo de <br> {{resp.message.sender_name}}</span>
    </div>
    
</div>

<div class="historial" *ngIf="historial">

    <span style="text-align: right; padding: 18px 17px 0px 0px; cursor: pointer;" class="fi-rr-cross" (click)="cerrar()"></span>

    <div style="padding: 50px 20px 0px 64px;">

        <h4>Historial de conversaciones</h4>

        <mat-tab-group mat-align-tabs="center">
            
                <mat-tab>
    
                    <ng-template matTabLabel>
                        <span matBadge="{{counterConversation}}" matBadgeOverlap="false">Mensajes</span>
                    </ng-template>
        
                    <br>
                    <br>
    
                    <mat-form-field style="width: 330px; height: 48px;" appearance="fill">
                        <input placeholder="Buscar..." matInput #input (keydown.enter)="applyFilter($event)" (blur)="applyFilter($event)">
                        <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
                    </mat-form-field>
        
                    <br>
                    <br>
                    <br>

                    <div style="overflow-y: auto; overflow-x: hidden; max-height: 515px;">

                        <div *ngIf="conversation.length === 0"> No se encontraron resultados... </div>

                        <div class="row message" *ngFor="let item of conversation" style="cursor: pointer; padding: 10px 0px 0px 10px;" (click)="abrirConversacion(item)" >
    
                            <div class="col-2" [ngClass]="{'tittleAbrevationBlue': item.userState === 1, 'tittleAbrevationRed' : item.userState === 0}">
                                <button style="background-color:#A9A9A9;" mat-fab matBadge="." matBadgePosition="below after" matBadgeColor="accent" matBadgeSize="small" disabled>{{item.titleAbbreviation}}</button>
                                <br>
                                <br>    
                            </div>
                            <div class="col-10">
                                <label *ngIf="item.quantity_unread_messages !== 0" style="width: 60%;">{{item.title}}</label>
                                <label *ngIf="item.quantity_unread_messages === 0" style="width: 60%; font-weight: 500 !important">{{item.title}}</label>
                                <span>{{item.lastMessage?.created_at  | date:'E h:mm a' }}</span> <br>
                                <div style="width: 80%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; float: left;">
                                    {{item.lastMessage?.message}}
                                </div>
                                <div *ngIf="item.quantity_unread_messages != 0" class="badge">{{item.quantity_unread_messages}}</div>
                                <br>
                                <br>
                            </div>
        
                        </div>

                    </div>
                    
                    <br>

                    <div style="text-align: center;">
                        <span *ngIf="ver && conversation.length > 4" style="cursor: pointer;" (click)="verMas(0)">Ver más <i class="fi-rr-angle-small-down"></i></span>
                        <span *ngIf="!ver && conversation.length > 4" style="cursor: pointer;" (click)="verMas(5)">Ver menos <i class="fi-rr-angle-small-up"></i></span>
                    </div>
                    
    
                    <br>
                    <br>
                    
                    <div class="row">
                        <div class="col-5"></div>
                        <div class="col-7">
                            <button (click)="newConversation = true" mat-stroked-button color="primary">Nuevo mensaje <i class="fi-rr-edit"></i></button>
                        </div>
                    </div>
                
                </mat-tab>

    
            <mat-tab label="Notas">Notas</mat-tab>
        </mat-tab-group>

    </div>

    
</div>

<div class="chat row" *ngIf="chat">

    <div style="height: 87px; background-color: #2CABBC; border-radius: 10px 10px 0px 0px" >
        <div class="row" style="padding: 16px 0px 0px 32px;">
            <div class="col-2">
                <button style="background-color:#A9A9A9;" mat-fab disabled>{{ventanaChat.titleAbbreviation}}</button>
            </div>
            <div class="col-8">
                <label style="width: 60%; font-weight: 500 !important; color:white;">{{ventanaChat.title}}</label>
                <br>
                <label style="width: 60%; font-weight: 500 !important; color:white;" *ngIf="ventanaChat.userState === 0">Desconectado</label>
                <label style="width: 60%; font-weight: 500 !important; color:white;" *ngIf="ventanaChat.userState === 1">Activo</label>
            </div>
            <div class="col-2">
                <span style="text-align: right; cursor: pointer; color: white; padding: 0px 0px 0px 20px;" class="fi-rr-cross" (click)="chat = false"></span>
            </div>
        </div>
    </div>

    <div id="parentDiv" style="height: 42vh; overflow-y: auto;">
        <br>

        <div *ngFor="let item of mensajes">

            <div style="text-align: center;">
                <span>{{today | date:'hh:mm:ss'}}</span>    
            </div>

            <br>
            
            <div class="row" *ngIf="item.sender_rrhh_id !== rrhhId">
                <div class="col-8">
                    <mat-card style="background-color: #EBEBEB; color:#717171;; max-width: 252px; min-width: 171px; word-wrap: break-word;">{{item.message}}</mat-card>
                    <div style="text-align: left;">
                        <small >{{item.created_at | date:'E h:mm a'}}</small>
                    </div>
                </div>
                <div class="col-4">
                </div>
            </div>

            <div class="row" *ngIf="item.sender_rrhh_id === rrhhId">
                <div class="col-4">  
                </div>
                <div class="col-8">
                    <mat-card style="background-color: #2CABBC; color:white; max-width: 252px; min-width: 171px; word-wrap: break-word;">{{item.message}}</mat-card>
                    <div style="text-align: right;">
                        <small >{{item.created_at | date:'E h:mm a'}}</small>
                    </div>
                </div>
            </div>
            
            

        </div>


    </div>
    <div style="height: 70px; padding-top: 10px;">
        <form [formGroup]="newMessage">
            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-icon matPrefix><span class="fi-rr-plus-small"></span></mat-icon>
                <input matInput (keydown.enter)="sendMessage(newMessage)" formControlName="message">
                <mat-icon (click)="sendMessage(newMessage)" matSuffix style="cursor: pointer;"><span class="fi-rr-paper-plane"></span></mat-icon>
                </mat-form-field>
        </form>
    </div>

</div>

<div class="chat row" *ngIf="newConversation">

    <div style="height: 87px; background-color: #2CABBC; border-radius: 10px 10px 0px 0px" >
        <div class="row" style="padding: 16px 0px 0px 32px;">
            <div class="col-2"> 
            </div>
            <div class="col-8">
            </div>
            <div class="col-2">
                <span style="text-align: right; cursor: pointer; color: white; padding: 0px 0px 0px 20px;" class="fi-rr-cross" (click)="newConversation = false"></span>
            </div>
        </div>
    </div>

    <div id="parentDiv" style="height: 42vh; overflow-y: auto;">
        <div style="padding: 24px 55px 0px 33px;">
            <span> Para:</span>
            <mat-form-field class="example-chip-list">
                <mat-chip-list #chipList aria-label="Fruit selection">
                  <mat-chip
                    *ngFor="let fruit of fruits"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(fruit)">
                    {{fruit.name}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>
                  <input
                    #fruitInput
                    [formControl]="fruitCtrl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                  <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">
                    {{fruit.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>


        </div>
    </div>

    <div style="height: 70px; padding-top: 10px;">
        <form [formGroup]="newMessageDiffusion">
            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-icon matPrefix><span class="fi-rr-plus-small"></span></mat-icon>
                <input matInput (keydown.enter)="sendMessageDiffusion(newMessageDiffusion)" formControlName="messageDiffusion">
                <mat-icon (click)="sendMessageDiffusion(newMessageDiffusion)" matSuffix style="cursor: pointer;"><span class="fi-rr-paper-plane"></span></mat-icon>
                </mat-form-field>
        </form>
    </div>

</div>

