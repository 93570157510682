import { createReducer, on } from "@ngrx/store";
import { SelectsState } from "../../core/states/selects.state";
import { loadedCampañas, loadSelects, loadedSelects } from '../actions/selects.actions';

/**
 * @author Cristian Gallo
 * @createdate 2021-12-13
 * Constante que manejara el estado inicial de cada arreglo.
*/

export const initialState: SelectsState = { 
    selects:{
        cuartilInicial:[],
        causaRaiz:[],
        cumpleCompromiso:[],
        estado:[],
        indicadores:[],
        presentaEvolucion:[],
        tipoProceso:[],
    },
    campañas:[],
};

/**
 * @author Cristian Gallo
 * @createdate 2021-12-13
 * El reducer se encarga de tomar los datos que hayan obtenido las acciones para ser leidos mas adelante.
*/

export const selectsReducer = createReducer(
    initialState,
    on(loadSelects,(state)=>{
        return {...state}
    }),
    on(loadedSelects,( state, { selects } )=>{
        return {...state, selects}
    }),
    on(loadedCampañas,( state, { campañas } )=>{
        return {...state, campañas}
    }),
    // on(loadedIndicadores,( state, { indicadores } )=>{
    //     return {...state, indicadores}
    // }),
    // on(loadedProcesos,( state, { procesos } )=>{
    //     return {...state, procesos}
    // }),
    // on(loadedCuartiles,( state, { cuartiles } )=>{
    //     return {...state, cuartiles}
    // }),
    // on(loadedCausas,( state, { causas } )=>{
    //     return {...state, causas}
    // }),
    // on(loadedEstados,( state, { estados } )=>{
    //     return {...state, estados}
    // }),
    // on(loadedCompromisos,( state, { compromisos } )=>{
    //     return {...state, compromisos}
    // }),
    // on(loadedEvoluciones,( state, { evoluciones } )=>{
    //     return {...state, evoluciones}
    // }),
);