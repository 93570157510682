<div class="row col-12 filter mx-0" style="padding-top: 40px;">

  <mat-form-field class="col-3 ps-2" appearance="fill">
    <mat-label>Fecha de creación</mat-label>
    <input matInput autocomplete="off" [matDatepicker]="picker" (dateChange)="filterBydate($event)">
    <mat-datepicker-toggle matSuffix [for]="picker">
      <mat-icon matDatepickerToggleIcon><span class="fi fi-rr-calendar"></span></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="col-3" appearance="fill">
    <mat-label>Estado</mat-label>
    <mat-select [(ngModel)]="filters" (selectionChange)="filterByState($event.value)" multiple>
      <mat-option *ngFor="let state of requestStates" [value]="state.id">{{state.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-3" appearance="fill">
    <mat-label>Nombre de la solicitud</mat-label>
    <input matInput type="text" (blur)="filterByNameRequest($event)" autocomplete="off">
  </mat-form-field>
  <mat-form-field class="col-3" appearance="fill">
    <mat-label>Nombre del solicitante</mat-label>
    <input matInput type="text" autocomplete="off" (blur)="filterByNameApplicant($event)">
  </mat-form-field>
</div>

<div class="row col-12 filter div-flex mx-0 justify-content-end">
  <mat-form-field class="col-3 ps-2" appearance="fill">
    <mat-label>Consecutivo solicitudes</mat-label>
    <input matInput type="text" autocomplete="off" (blur)="filterByConsecutiveRequests($event)">
  </mat-form-field>
  <div class="col-3 buttons pe-2">
    <button class="w-100 bold" mat-raised-button color="primary" (click)="newSolicitud()">Crear nueva solicitud &nbsp;<i
        class="fi-rr-add"></i></button>
  </div>
  <div class="col-3 buttons pe-2" align="right">
    <button class="w-100 bold" mat-stroked-button color="primary" (click)="downloadManagement()">Descargar gestión <i class="fi-rr-download"></i></button>
  </div>
  <div class="col-3 buttons pe-2" align="left">
    <button class="w-100 bold" mat-stroked-button (click)="bloqueoSolicitudes()" color="accent" disabled="true">Bloqueo de solicitudes</button>
  </div>
</div>


<div class="table-container row p-1">

  <div class="col-md-4 col-lg-3 mt-3 mb-3" *ngFor="let solicitud of solicitudes">
    <mat-card class="solicitud-card    mat-elevation-z8">
      <mat-card-header style="display: block;">

        <div class="badge ">
          <button mat-icon-button [style.backgroundColor]="solicitud.request_state.color" class="shadowed">
            <i [class]=" solicitud.request_state.icon"> </i>
          </button>
        </div>
        <div class="header">
          <mat-card-subtitle> {{solicitud.request_state.name}} </mat-card-subtitle>
          <mat-card-title class="text-truncate">
            {{solicitud.title}}
            <small *ngIf="solicitud.title.length > 30">...</small>

          </mat-card-title>
          <mat-card-subtitle>Detalle de la solicitud
            <span mat-raised-button #tooltip="matTooltip" matTooltip="{{ solicitud.description }}"
              matTooltipPosition="below" matTooltipClass="custom-tooltip" class="example-button">
              <i class="fi fi-rr-info"></i>
          </span>
          <br>
          <br>
            <p class="fs-5 fw-bold text-dark" *ngIf="!solicitud.is_associated">SO-{{solicitud.id}}</p>
            <p class="fs-5 fw-bold text-dark" *ngIf="solicitud.is_associated">SO-{{solicitud.request_id}}-{{solicitud.associated_consecutive}}</p>
          </mat-card-subtitle>

        </div>
        <!-- <p>
                  {{solicitud.description | slice:0:80}}
                  <small *ngIf="solicitud.description.length > 80">...</small>
              </p>-->



      </mat-card-header>
      <mat-card-content>
        <div>
          <hr>
          <div class="info-oc-card">
            <mat-card-subtitle><b>Solicitante:</b> {{solicitud.created_by}}</mat-card-subtitle>
            <mat-card-subtitle><b>Empresa:</b> {{solicitud.company.name}}</mat-card-subtitle>
            <mat-card-subtitle><b>Fecha de creación:</b> {{solicitud.created_at|date:'dd/MM/yyyy': 'UTC'}}
            </mat-card-subtitle>
          </div>
          <hr>
          <button *ngIf="solicitud.request_state_id > 2" mat-button (click)="viewSolicitud(solicitud)">
            Ver <i class="fi-rr-eye"></i>
          </button>
          <button mat-button (click)="editSolicitud(solicitud)" *ngIf="solicitud.request_state_id == 2">
            Editar <i class="fi-rr-edit"></i>
          </button>
          <button mat-button (click)="procesarSolictud(solicitud)" *ngIf="solicitud.request_state_id == 1">
            Gestionar <i class="fi-rr-settings"></i>
          </button>
          <button mat-button (click)="generarSolicitud(solicitud)" *ngIf="solicitud.request_state_id == 4">
            Orden <i class="fi-rr-document-signed"></i>
          </button>
          <button *ngIf="solicitud.request_state_id == 4" mat-button (click)="anularSolicitud(solicitud)">
            Anular <i class="fi-rr-cross-circle"></i>
          </button>


        </div>
      </mat-card-content>

    </mat-card>
  </div>
  <mat-paginator class="mt-5" [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)"
    [pageSizeOptions]="pageSizeOptions">
  </mat-paginator>



</div>
