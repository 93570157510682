import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith, filter } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { GroupsService } from 'src/app/modules/crm/services/rest/groups.service';
import { AdminGroupsComponent } from '../admin-groups/admin-groups.component';

@Component({
  selector: 'app-groups-list',
  templateUrl: './groups-list.component.html',
  styleUrls: ['./groups-list.component.sass']
})
export class GroupsListComponent implements OnInit {
  groupsLists: any[];
  campaingId: string;
  public campaingName: string = '';
  dataSource: any;
  filteredOptions: Observable<any[]>;
  Form: FormGroup;
  permit: any;

  constructor(
    public group: GroupsService, 
    private route: ActivatedRoute, 
    public dialog: MatDialog,
    private authService: AuthService
    ) { }

  ngOnInit(): void {
    this.Form = new FormGroup({
      search: new FormControl('', [])
    });
    this.campaingId = this.route.snapshot.queryParamMap.get('id');
    this.campaingName = this.route.snapshot.queryParamMap.get('name');
    this.permit = this.authService.getUser().permissions.crm.groups;
    /* if(this.permit.all == 1){
      this.group.getGroups('groupslist',this.campaingId).subscribe(res=>{
        this.groupsLists =res;    
        this.groupsLists.map(data =>{
          if (data.state == 1) {
            data['status'] = true;
          } else {
            data['status'] = false;        
          }
        });
  
        this.filteredOptions = this.Form.get('search').valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name_group),
          map(groups => groups ? this._filter(groups) : this.groupsLists.slice())
        );
      });
    }else{ */
      let user = this.authService.getUser();
      let userID = user.rrhh_id;
      this.group.getGroupsByUser('groupsbyuser/', userID).subscribe(res=>{
        this.groupsLists =res.groups;    
        this.groupsLists.map(data =>{
          if (data.state == 1) {
            data['status'] = true;
          } else {
            data['status'] = false;        
          }
        });
  
        this.filteredOptions = this.Form.get('search').valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name_group),
          map(groups => groups ? this._filter(groups) : this.groupsLists.slice())
        );
      });
      
    /* }  */  

  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-03-03
   * Metodo filtro busqueda
   */
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    
    return this.groupsLists.filter(option => option.name_group.toLowerCase().indexOf(filterValue.toLocaleLowerCase()) > -1);
  }
  /**
   * @author Daniel Dominguez 
   * @createdate 2021-03-03
   * Metodo cambiar estado del grupo
   */
  changeStatus(id,state){
    if (state == 1) {
      state = 0;
    } else {
      state = 1;
    }
    let data={
      state: state
    }
    this.group.putState(id, data).subscribe(res => {
      this.ngOnInit();
    });
  }
  /**
   * @author Daniel Dominguez 
   * @createdate 2021-03-03
   * Metodo abrir modal de crear grupo
   */
  newgroup(): void{
    const dialogNewgroup = this.dialog.open(AdminGroupsComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Crear grupo',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewgroup.afterClosed().subscribe(() => {
      this.ngOnInit();
    });

  }
  /**
   * @author Daniel Dominguez 
   * @createdate 2021-03-03
   * Metodo abrir modal de editar grupo
   */
  editgroup(group: any): void{
    const dialogNewgroup = this.dialog.open(AdminGroupsComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Editar Grupo',
        group
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewgroup.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }

}
