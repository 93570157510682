<mat-dialog-content class="padding-mat-dialog-content" style="padding-bottom: 10px;">
    <i class="fi-rr-cross-small" style="float: right; font-size: 32px; color: #353535;" mat-icon-button mat-dialog-close></i>
    <div id="containerViewReport" class="avoid-page-break" #containerViewReport>
        <h3 mat-dialog-title>Resultados de análisis.</h3>
        <mat-divider class="w-100"></mat-divider>

        <!-- Panel de Emociones -->
        <mat-expansion-panel #analisisTono>
            <mat-expansion-panel-header>
                <mat-panel-title>Análisis de tono de voz</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row avoid-page-break">
                <div class="col-12 text-center">
                    <ngx-charts-pie-chart 
                        [view]="view" 
                        [scheme]="colorScheme" 
                        [results]="emocionesData" 
                        [legend]="showLegend"
                        [labels]="showLabels" 
                        [doughnut]="isDoughnut" 
                        [gradient]="gradient"
                        [animations]="false"
                        [labelFormatting]="pieChartLabel.bind(this, emocionesData)">
                    </ngx-charts-pie-chart>
                </div>
            </div>
        </mat-expansion-panel>
        
        <!-- Panel de Sentimientos -->
        <mat-expansion-panel #analisisTexto>
            <mat-expansion-panel-header>
                <mat-panel-title>Análisis de texto</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row avoid-page-break">
                <div class="col-12 text-center">
                    <ngx-charts-pie-chart 
                        [view]="view" 
                        [scheme]="colorScheme2" 
                        [results]="sentimientosData" 
                        [legend]="showLegend"
                        [labels]="showLabels" 
                        [doughnut]="isDoughnut" 
                        [gradient]="gradient"
                        [animations]="false"
                        [labelFormatting]="pieChartLabel.bind(this, sentimientosData)">
                    </ngx-charts-pie-chart>
                </div>
            </div>
        </mat-expansion-panel>

    </div>
</mat-dialog-content>

<div class="col-md-12 pt-0">
    <div class="row py-3 d-flex justify-content-end">
        <div class="col-md-2 d-flex justify-content-center">
            <button mat-flat-button color="primary" class="w-100"
                    (click)="onClickPrintPdf()"
                    matTooltip="Descargar informe">
                <i class="fi-rr-download"></i>
            </button>
        </div>
    </div>
</div>