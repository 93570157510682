import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';

const CHAT_URL = environment.CHAT_URL;

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private http: HttpClient,
              private destroyService: DestroyComponentService) { }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-07-06
   * @param size tamaño de datos que traera el servicio
   * @param openConversation parametro que especifica si una conversacion esta abierta o cerrada
   * @returns lista de conversaciones por el rrhh id
   * Servicio que lista todas las conversaciones disponibles de un usuario
   */
  getListConversationByRhhId(size: number, openConversation: any): Observable<any>{
    return this.http.get<any>(`${CHAT_URL}listConversationByRhhId?size=${size}&conversationOpenId=${openConversation}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-07-06
   * @param idConversation id de la conversion de la que se obtendra los mensajes
   * @returns lista de mensajes de una conversacion en especifico
   * Servicio que lista todos los mensajes de una conversacion en especifico
   */
  getChatConversation(idConversation: number): Observable<any>{
    return this.http.get<any>(`${CHAT_URL}message/${idConversation}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-07-06
   * @param message objeto donde se encuentra el mensaje, id de la conversacion, rrhh de quien envia
   * @returns mensaje de exito
   * Servicio que envia un nuevo mensaje a una conversacion en especifico 
   */
  newMessage(message: any): Observable<any>{
    return this.http.post<any>(`${CHAT_URL}newMessage`, message)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-07-06
   * @param message objeto donde se encuentra el mensaje, arreglo de personas a las que se les enviara el mensaje
   * @returns mensaje de exito
   * Servicio que envia la difusion de un mensaje a varios usuarios
   */
  newMessageDiffusion(message: any): Observable<any>{
    return this.http.post<any>(`${CHAT_URL}newMessageDiffusion`, message)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-07-06
   * @param search palabra a buscar
   * @returns lista de conversaciones
   * Servicio que filtra las conversaciones por un parametro que se recibe
   */
  searchConversation(search: any): Observable<any>{
    return this.http.get<any>(`${CHAT_URL}listConversationByRhhId?size=0&fetch=${search}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-07-06
   * @returns lista usuarios
   * Servicio que lista los usuarios con los que se ha tenido una conversacion
   */
  getUser(): Observable<any> {
    return this.http.get<any>(`${CHAT_URL}listUsersByIdGroup`)
    .pipe(
      catchError(this.handleError)
      );
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

}
