import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

const CIU_URL = environment.CIU_URL;

@Injectable({
  providedIn: 'root'
})
export class NetworkUsersService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
  * Metódo para descargar los logs
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
  getManageNetworkUsersList(page: number, pageSize: number, start_date: string = "", end_date: string = "", unlocker_user: string = "", unlocked_user: string = ""): Observable<any> {
    return this.http.get(`${CIU_URL}manageNetworkUsers?per_page=${pageSize}&page=${page}&start_date=${start_date}&end_date=${end_date}&unlocker_user=${unlocker_user}&unlocked_user=${unlocked_user}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
  * Metódo para activar el usuario de red
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
  activateUser(data:any): Observable<any> {
    return this.http.post(`${CIU_URL}manageNetworkUsers/unlockUser`, data).pipe(catchError(this.handleError))
  }

  /**
  * Metódo que obtiene el archivo del historico
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
  downloadLogs(): Observable<any>{
    return this.http.get(`${CIU_URL}manageNetworkUsers/downloadLogs`, { responseType: 'blob' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }

  /**
  * Metódo que obtiene las preguntas de seguridad asociadas al usuario
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
   getUserSecurityQuestions(all:number): Observable<any>{
    return this.http.get(`${CIU_URL}SecurityQuestions/getQuestions/${all}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
  * Metódo que envía las respuestas ingresadas por el usuario y las valida con las que estan almacenadas, si coinciden devuelve verdadero
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
  validateSecurityQuestionsAnswers(data:any){
    return this.http.post(`${CIU_URL}SecurityQuestions/validateQuestions`, data).pipe(catchError(this.handleError))
  }

  /**
  * Metódo que envía la nueva contraseña de red para actualizarla
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
   updateNetworkUserPassword(data:any){
    return this.http.post(`${CIU_URL}manageNetworkUsers/changeNetworkUserPass`, data).pipe(catchError(this.handleError))
  }

    /**
  * Metódo que envía las nuevas preguntas de seguridad y actualiza las anteriores
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 29-05-2024
  */
    updateUserSecurityAnswers(data:any){
      return this.http.put(`${CIU_URL}SecurityQuestions/update`, data).pipe(catchError(this.handleError))
    }

    validateLastUpdates(type:number): Observable<any>{
      return this.http.get(`${CIU_URL}SecurityQuestions/validateLastUpdate/${type}`).pipe(catchError(this.handleError))
    }

 /**
  * Metódo que trae los datos de los usuarios activos paginados con información de rrhh
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
  getUsersFilter(params: string) {
    return this.http.get<any>(`${CIU_URL}users?rrhh=2&${params}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
  * Metódo que envía y actualiza el usuario de red
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 19-09-2024
  */
  updateNetworkUser(id:number,data:any){
    return this.http.post(`${CIU_URL}users/updateNetworkUser/${id}`, data).pipe(catchError(this.handleError))
  }
}
