import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import { JuridicaService } from '../../../../services/juridica.service';

@Component({
  selector: 'app-permit-view-boss',
  templateUrl: './permit-view-boss.component.html',
  styleUrls: ['./permit-view-boss.component.sass']
})
export class PermitViewBossComponent implements OnInit {
  dataInfo: any;
  public realTime: string;
  public scheduleTime: string;
  public endTime: string;
  public noveltyDetailHeight: number;

  public showJustifyForm: boolean;
  public showJustifyInfo: boolean;
  public showInput: boolean;
  public titlePermit = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private alert: AlertsService,
              public dialog: MatDialog,
              private permit: JuridicaService
  ) {
  }

  ngOnInit() {
    this.permit.getInfoByPermitId(this.data.data.idNovelty).subscribe(resp => {
      this.dataInfo = resp.data;
      if (this.dataInfo.validForMoreResponses) {
        this.showJustifyForm = true;
      }
      this.showJustifyInfo = true;
      this.changeDetailHeight(this.dataInfo);
    });
    this.titlePermit = this.data?.data?.novelty_type?.name ?? "Permiso";
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-06-23
   * Alto del modal
   */
  changeDetailHeight(novelty): void {
    if (novelty.responseFirstInstance && (novelty.responseFirstInstance.justify || novelty.responseFirstInstance.response)) {
      this.noveltyDetailHeight = 240;
    }
  }
}
