import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const calidad_url = environment.CALIDAD_URL;
const nomina_url = environment.NOMINA_URL;
const rrhh = environment.RRHH_URL;
const lang = JSON.parse(localStorage.getItem('locale')) ?? 'es';

@Injectable({
  providedIn: 'root'
})
export class MatrizService {
  private _refresh$ = new Subject<void>();
  constructor(
    private http:HttpClient,
  ) { }

  get refresh$(){
    return this._refresh$;
  }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  createMatrix(data){
    return this.http.post<any>(`${calidad_url}crearMatriz`,data)
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

  getTypeMatrix(){
    return this.http.get(`${calidad_url}getTipoMatriz?lang=${lang}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  getIntelligence(){
    return this.http.get(`${calidad_url}getInteligenciaNegocio`)
    .pipe(
      catchError(this.handleError)
      );
  }

  getCampaings(){
    return this.http.get(`${nomina_url}campaigns?paginate=0`)
    .pipe(
      catchError(this.handleError)
    );
  }

  getAllMatrix(params){
    return this.http.get(`${calidad_url}listarMatrices?lang=${lang}`,{params})
    .pipe(
      catchError(this.handleError)
      );
  }

  getRange(){
    return this.http.get(`${calidad_url}getRangos?lang=${lang}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  disableMatrix(id,estado){
    return this.http.post<any>(`${calidad_url}desabilitarMatriz/${id}/${estado}`,{})
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

  getWeight(){
    return this.http.get(`${calidad_url}getPeso?lang=${lang}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  getMatrixById(id){
    return this.http.get(`${calidad_url}verMatriz/${id}`)
    .pipe(
      catchError(this.handleError)
      );
  }


  updateMatrix(id,data){
    return this.http.post<any>(`${calidad_url}updateMatriz/${id}`,data)
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

  newModule(data){
    return this.http.post<any>(`${calidad_url}crearModulo`,data)
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

  getModules(modulo){
    return this.http.get(`${calidad_url}searchModulo?modulo=${modulo}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  getModulesMatrix(params:any){
    return this.http.get(`${calidad_url}searchModulosMatriz?idMatriz=${params.idMatriz}&modulo=${params.modulo}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  getItems(item){
    return this.http.get(`${calidad_url}searchItems?modulo=${item}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  newItem(data){
    data.lang = lang;
    return this.http.post<any>(`${calidad_url}crearItems`, data)
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }


  getSubtItems(subItem){
    return this.http.get(`${calidad_url}searchSubitems?modulo=${subItem}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  newSubItem(data){
    return this.http.post<any>(`${calidad_url}crearSubitems`,data)
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

  getModuleById(id_module){
    return this.http.get(`${calidad_url}verModulo/${id_module}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  updateModule(data){
    return this.http.post<any>(`${calidad_url}updateModulo`,data)
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

  updateItem(data){
    return this.http.post<any>(`${calidad_url}updateItems`,data)
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

  updateSubItem(data){
    return this.http.post<any>(`${calidad_url}updateSubitems`,data)
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

  disableModule(data){
    return this.http.post<any>(`${calidad_url}desabilitar`,data)
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

  getListAnalistas(params){
    return this.http.get(`${calidad_url}showAnalistas?lang=${lang}`,{params})
        .pipe(
      catchError(this.handleError)
      );
  }

  asignarAnalistas(data){
    return this.http.post<any>(`${calidad_url}asignarAnalista`,data)
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

  getAnalistaById(id_matriz){
    return this.http.get(`${calidad_url}analistasByMatriz/${id_matriz}`)
    .pipe(
      catchError(this.handleError)
      );
  }


  eliminateAnalista(idAnalista,idMatriz){
    return this.http.post<any>(`${calidad_url}deleteAnalista/${idAnalista}/${idMatriz}`,{})
    .pipe(
      catchError(this.handleError)
      );
  }

  descargaActualizaciones(idMatriz){
    return this.http.get(`${calidad_url}descargaActualizaciones?idMatriz=${idMatriz}&lang=${lang}`,{responseType: "blob"})
    .pipe(
      catchError(this.handleError)
      );
  }

  showSupervisores(params){
    params.lang = lang;
    return this.http.get(`${calidad_url}showSupervisor`,{params})
    .pipe(
      catchError(this.handleError)
    );
  }

  getSupervisorByMatriz(idMatriz){
    return this.http.get(`${calidad_url}getSupervisorMatriz/${idMatriz}`)
    .pipe(
      catchError(this.handleError)
  );
  }

  /**
   * Metodo Que elimina las matrices
   * @author Juan David Guerrero Vargas
   * @create_date 01/05/2022
   * @param idMatriz:string ID de la matriz a eliminar
   * @returns Observable para sobrcribirse a la peticion
   */
  deleteMatrix(idMatriz:string){
    return this.http.delete(`${calidad_url}deleteMatriz/${idMatriz}`)
    .pipe(
      catchError(this.handleError)
    );
  }
  getComapanies(){
    return this.http.get(`${rrhh}companies`)
    .pipe(
      catchError(this.handleError)
  );
  }


}
