import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../../services/users.service';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs as importedSaveAs } from "file-saver";
import { FilesViewComponent } from 'src/app/shared/files-view/files-view.component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { FormControl, FormGroup } from '@angular/forms';
import { PaymentSlipService } from '../../../services/paymentSlip.service';

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.sass']
})
export class CertificationsComponent implements OnInit {

  certifications: any = CERTIFICATIONES;

  currentPage = 1;
  displayColumns: string[] = [];
  displayColumnsName: any = [];

  dataSource: any = [];
  length: number;
  pageSize: number;

  certicate: any;
  showPaymentSlip: boolean;
  dateForm: FormGroup
  currentYear: Date;
  currentMonthsByYear: { year, month}[] = [];
  allWorkersMonths:  { year, month}[] = [];
  filterWorkersMonths: { year, month}[] = [];
  listOfYear:{ year, month}[] = [];
  workersMonths:{ year, month}[] = [];
  public months: { id, name }[] = [
    { id: 1, name: 'Enero' },
    { id: 2, name: 'Febrero' },
    { id: 3, name: 'Marzo' },
    { id: 4, name: 'Abril' },
    { id: 5, name: 'Mayo' },
    { id: 6, name: 'Junio' },
    { id: 7, name: 'Julio' },
    { id: 8, name: 'Agosto' },
    { id: 9, name: 'Septiembre' },
    { id: 10, name: 'Octubre' },
    { id: 11, name: 'Noviembre' },
    { id: 12, name: 'Diciembre' }
  ];
  public years: { id, name }[] = [];
  constructor(
    private userServices: UsersService,
    private dialog: MatDialog,
    private alertService: AlertsService,
    private paymentSlipService: PaymentSlipService
    ) { }

  ngOnInit(): void {
    this.showPaymentSlip = false;
  }

  /**
   * @author Johan Soler
   * @createdate 2022-06-29
   * Metodo para saber que desprendibles son del año actual
   */
  startPaymentSlip(){
    // this.generateArrayOfYears();
    this.generateRangeYear(2022); //Por defecto se pono 2022, por que asi se solicito
    this.currentYear = new Date();

    this.dateForm = new FormGroup({
      month: new FormControl(),
      year: new FormControl()
    });

    this.paymentSlipService.getWorkersMonth().subscribe((resp:any) => {
      for (let i = 0; i < resp.data.length; i++) {
        let date = resp.data[i].split('-');
        if(date[0] == this.currentYear.getFullYear()){
          this.currentMonthsByYear.push({year: date[0], month: date[1]});
        }
        this.allWorkersMonths.push({year: date[0], month: date[1]})
      }
    })
  }

  /**
   * @author Johan Soler
   * @createdate 2022-06-29
   * Metodo para retornar el nombre del mes
   */
  getName(number){
    for (let i = 0; i < this.months.length; i++) {
      if(this.months[i].id == Number(number)){
        return this.months[i].name;
      }
    }
    return "";
  }

    /**
   * @author Johan Soler
   * @createdate 2022-06-29
   * Metodo para cargar la vista correspondiente al filtro
   */
  getCertifications(value) {
    if (value == "desprendibles") {
      this.startPaymentSlip()
      this.showPaymentSlip = true;
      this.certicate = value;
    } else {
      this.showPaymentSlip = false;
      this.certicate = value;
      this.userServices.getCertifications(this.certicate).subscribe((resp: any) => {
        this.displayColumns = resp.columnsFields;
        this.displayColumnsName = resp.columnsName
        this.dataSource = new MatTableDataSource<any>(resp.data.data);
        this.length = resp.data.total;
        this.pageSize = resp.data.per_page;
      })
    }
  }

  getPaginatorData(e) {
    this.currentPage = e.pageIndex + 1;
    this.getCertifications(this.certicate);
  }

  getCertificate(certificate, values, isView) {
    console.log(values);
    if (certificate == "liquidacion") {
      this.paymentSlipService.getPayment(values.month ?? '', values.year ?? '', '', true).subscribe((resp: any) => {
        if (isView) {
          let fileURL = URL.createObjectURL(resp);
          const checkDialog = this.dialog.open(FilesViewComponent, {
            data: { url: fileURL, name: certificate, file: resp }
          });
        } else {
          importedSaveAs(resp, certificate);
        }
      });
    }else{
      this.userServices.getCertificate(certificate, values).subscribe((resp: any) => {
        if (isView) {
          let fileURL = URL.createObjectURL(resp);
          const checkDialog = this.dialog.open(FilesViewComponent, {
            data: { url: fileURL, name: certificate, file: resp }
          });
        }
        else {
          importedSaveAs(resp, certificate);
        }
      },
        error => {
          if (error.status == 402 || error.status == 403 || error.status == 400) {
            this.alertService.alertError("Error", 'Documento no encontrado, por favor valide sus documentos en Recursos Humanos');
          }
          if (error.status == 406) {
            this.alertService.alertError("Error", 'No es posible generar tu certificado, por favor acércate con el personal de Recursos Humanos informando el siguiente código: LOGCOM01');
          }
        }
      )
    }
  }

  /**
 * @author Andrés Buitrago <andres.b@montechelo.com.co>
 * @purpose Generar rango de años
 */
  generateArrayOfYears(): void {
    const max = new Date().getFullYear();
    let min = max;
    for (let i = 0; i < this.allWorkersMonths.length; i++) {

      if(this.allWorkersMonths[i].year < min){
        min = this.allWorkersMonths[i].year
      }
    }
    for (let i = max; i >= min-1; i--) {
      this.years.push({ id: i, name: i });
    }
    // this.filterWorkersMonths = this.allWorkersMonths;
    this.filterWorkersMonths = this.workersMonths;


  }

  /**
   * @author Johan Soler
   * Metodo para filtrar los meses y años del desprendible de nomina historial
   */
  filterPaymentSlip(){
    let yearValue = this.dateForm.controls['year'].value;
    let monthValue = this.dateForm.controls['month'].value;
    // this.filterWorkersMonths = this.allWorkersMonths;
    this.filterWorkersMonths = this.workersMonths;

    if((yearValue == null || yearValue == '') && (monthValue == null || monthValue == '')){
      return;
    }else if (monthValue == null || monthValue == ''){
      // this.filterWorkersMonths = this.allWorkersMonths.filter(element => element.year == yearValue);
    this.filterWorkersMonths = this.workersMonths.filter(element => element.year == yearValue);

    } else if (yearValue == null || yearValue == ''){
      // this.filterWorkersMonths = this.allWorkersMonths.filter(element => element.month == monthValue);
      this.filterWorkersMonths = this.workersMonths.filter(element => element.month == monthValue);
    } else {
      // this.filterWorkersMonths = this.allWorkersMonths.filter(element => element.month == monthValue && element.year == yearValue);
      this.filterWorkersMonths = this.workersMonths.filter(element => element.month == monthValue && element.year == yearValue);
    }
  }

  /**
  * @author Johan Soler
  * @createdate 2022-06-28
  * Modal para ver y descargar el documento
  */
  openPdfPaymentSlip(year, month) {
    const lastMonth = new Date().getMonth()+1 == month;
    this.paymentSlipService.getPayment(Number(month), year, lastMonth).subscribe((resp: any) => {
      let fileURL = URL.createObjectURL(resp);
        const checkDialog = this.dialog.open(FilesViewComponent, {
          data: { url: fileURL, name: "desprendible_nomina", file: resp ,print: true}
        });
    })
  }

  /**
  * @author David Reinoso
  * @createdate 2022-12-06
  * Funcion para generar un rango de años
  * @param min_year
  */
  generateRangeYear(min_year){
    if (this.workersMonths.length <= 0) {
      let date = new Date();
      let year = date.getFullYear();
      let mount = date.getMonth();
      let yearMin = min_year;
      while (yearMin <= year) { //Llenamos el array years con los años para el filtro
        this.years.push({ id: yearMin, name: yearMin });
        for (let i = 0; i < this.months.length; i++) {
          let dateCurrent = new Date(year, mount, 1);
          let dateBucle = new Date(yearMin, this.months[i].id, 1);
          if (dateBucle <= dateCurrent) {
            this.workersMonths.push({ year: yearMin, month: this.months[i].id });
            if (yearMin == year) {
              this.listOfYear.push({ year: yearMin, month: this.months[i].id });
            }
          }
        }
        yearMin++;
      }
    }
  }

}

const CERTIFICATIONES = [
  {
    key: 'certificado_laboral',
    name: 'Certificado Laboral'
  },
  {
    key: 'desprendibles',
    name: 'Desprendibles'
  },
  // {
  //   key: 'cesantias',
  //   name: 'Cesantias'
  // },
  // {
  //   key: 'prima',
  //   name: 'Prima'
  // },
  {
    key: 'liquidacion',
    name: 'Liquidación'
  },
  // {
  //   key: 'contrato',
  //   name: 'Contrato'
  // },
  // {
  //   key: 'arl',
  //   name: 'ARL'
  // },
  // {
  //   key: 'caja',
  //   name: 'CAJA'
  // },
  // {
  //   key: 'bank_certified',
  //   name: 'Certificación Bancaria'
  // }
];

