<div class="filter mb-3" style="--bs-gutter-x: 0rem !important;">
    <mat-form-field appearance="fill">
        <mat-label>{{config.filters.byForm}}</mat-label>
        <input matInput (keydown.enter)="filtrar()" [(ngModel)]="filtroFormulario" (blur)="filtrar()">
        <mat-icon matSuffix (click)="filtrar()"><span class="fi-rr-search"></span></mat-icon>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{config.filters.byDate}}</mat-label>
      <input matInput [matDatepicker]="fechaFiltro" [(ngModel)]="filtroFecha" (dateChange)="filtrar()">
      <mat-datepicker-toggle matSuffix [for]="fechaFiltro">
          <mat-icon matDatepickerToggleIcon><span class="fi-rr-calendar"></span></mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #fechaFiltro></mat-datepicker>
  </mat-form-field>
  <div class="text-end">
    <button mat-raised-button color="primary" style="height: 52px; font-size: 1.2em;"
    (click)="newForm()">
      {{config.btnNew}} <i class="fi-rr-add"></i>
    </button>
  </div>
</div>
<div class="table-container">
    <div class="mat-elevation-z1">
      <table  [dataSource]="dataSource" mat-table  matSort>

        <!-- Columna con botónes de acciones -->
        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="detailForm('detail',element.id)">
              <i class="fi-rr-eye"></i>
            </button>
          <button mat-icon-button (click)="detailForm('edit',element.id)">
              <i class="fi-rr-edit"></i>
            </button>
            <button mat-icon-button (click)="removeForm(element.id)">
              <i class="fi-rr-trash"></i>
            </button>
          </td>
        </ng-container>

        <!-- Columna fecha de creación -->
        <ng-container matColumnDef="fecha_creacion">
          <th mat-header-cell *matHeaderCellDef> {{config.table.createdAt}} </th>
          <td mat-cell *matCellDef="let element" matTooltip> {{ element.created_at | date:'dd/MM/yyyy'}} </td>
        </ng-container>

        <!-- Columna tipo de pregunta -->
        <ng-container matColumnDef="nombre_inteligencia_negocio">
          <th mat-header-cell *matHeaderCellDef> {{config.table.biName}} </th>
          <td mat-cell *matCellDef="let element" aria-label="Button that displays a tooltip when focused or hovered over"
          [matTooltip]="element.name" [matTooltipPosition]="'above'"> {{ element.name | cutText}} </td>
        </ng-container>

        <!-- Columna pregunta -->
        <ng-container matColumnDef="num_preguntas">
          <th mat-header-cell *matHeaderCellDef> {{config.table.questionAmount}} </th>
          <td mat-cell *matCellDef="let element"> {{ element.numero_preguntas_count }} </td>
        </ng-container>

        <!-- Columna pregunta -->
        <ng-container matColumnDef="descripcion">
            <th mat-header-cell *matHeaderCellDef> {{config.table.description}} </th>
            <td mat-cell *matCellDef="let element"  aria-label="Button that displays a tooltip when focused or hovered over"
            [matTooltip]="element.description" [matTooltipPosition]="'above'" [matTooltipClass]="'tooltip-content'"> {{ element.description | cutText:0:37 }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">{{config.table.nothingToShow}}</td>
        </tr>
      </table>

      <mat-paginator [length]="length"
      [pageSize]="pageSize"
      (page) ="pageEvent($event)"
      [pageSizeOptions] = "pageSizeOptions"
      >
    </mat-paginator>
  </div>
</div>
