<div class="main-container">
    <h1>Gestión de Candidatos</h1>
    <p>Bienvenido al módulo de candidatos, aquí podrás visualizar aquellos que ya han <br> sido cargados a esta solicitud.</p>
    <div class="filter">
      <mat-form-field appearance="fill" class="col-3">
          <mat-label>Tipo de filtro</mat-label>
          <mat-select [(ngModel)]="typeFilter" (selectionChange)="getCampaigns()">
            <mat-option value="Campaña">Campaña</mat-option>
            <mat-option value="Cargo">Cargo</mat-option>
            <mat-option value="Estado">Estado</mat-option>
            <mat-option value="Formador">Formador</mat-option>
            <mat-option value="Identificación">Identificación</mat-option>
          </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="typeFilter !=null && typeFilter !='Estado' && typeFilter !='Campaña'">
          <mat-label>Filtrar por {{typeFilter}}</mat-label>
          <input matInput #filter (keyup.enter)="filterByValue(filter.value)">
          <button mat-icon-button matSuffix (click)="filterByValue(filter.value)"><mat-icon>search</mat-icon></button>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="typeFilter =='Estado'">
          <mat-label>Filtrar por {{typeFilter}}</mat-label>
          <mat-select [(value)]="selected" (selectionChange)="filterByValue(selected)">
            <mat-option *ngFor="let type of statusTypes" [value]="type.id">
            {{type.name}}</mat-option>
          </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="typeFilter =='Campaña'">
          <mat-label>Filtrar por {{typeFilter}}</mat-label>
          <mat-select [(value)]="selected" (selectionChange)="filterByValue(selected)">
            <mat-option *ngFor="let type of campaigns" [value]="type.id">
              {{type.name}}</mat-option>
          </mat-select>
      </mat-form-field>
  
  
      <!-- <button mat-button class="btns" *ngIf="hiring" matTooltip="Descargar formato de reglas masivas" (click)="exportAgreements()">
        Descargar formato &nbsp; <span class="fi-rr-download"></span>
      </button> -->
      <button mat-flat-button class="btns" *ngIf="hiring" matTooltip="Crear reglas masivamente" color="primary"  (click)="openContractForm()" [disabled]="selection.selected.length < 1">
        Crear reglas de contratación masivas
      </button>
  
      <!-- <button mat-flat-button class="btns" *ngIf="hiring" matTooltip="Crear reglas masivamente" color="primary" (click)="fileInput.click()">
        Crear reglas de contratación
      </button>
      <input hidden (change)="importAgreements($event.target.files)" #fileInput type="file" id="file" >
      <p>{{nameFile}}</p> -->
  
    </div>
    <div class="table">
        <mat-tab-group [selectedIndex]="selected.value"
        (selectedIndexChange)="changeTab($event)" mat-align-tabs="left" >
            <mat-tab label="Usuarios activos">
                <table mat-table [dataSource]="dataSource" matSort>
  
                  <ng-container matColumnDef="check">
                    <div *ngIf="hiring" >
                      <th class="start separator" mat-header-cell *matHeaderCellDef>
                      </th>
                      <td class="separator"  mat-cell *matCellDef="let row" >
                        <mat-checkbox (click)="$event.stopPropagation(); validateAgremetRules(row)" [disabled]="row.check_personal_data == 0"
                                      (change)="$event ? selection.toggle(row.id) : null" [checked]="selection.isSelected(row.id)">
                        </mat-checkbox>
                      </td>
                    </div>
                    <div>
                      <th class="start separator" mat-header-cell *matHeaderCellDef></th>
                      <td class="separator"  mat-cell *matCellDef="let row" ></td>
                    </div>
  
                  </ng-container>
  
                    <ng-container matColumnDef="actions">
                        <th class="start" mat-header-cell *matHeaderCellDef> Acciones </th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="getComments(row.id,row.requestId)">
                              <i class="fi fi-rr-comment-alt"></i>
                              <span style="padding-left:5px;">Comentarios</span>
                            </button>
                                <button mat-menu-item (click)="editCandidate(row.id)">
                              <i class="fi fi-rr-edit"></i>
                              <span style="padding-left:5px;">Editar</span>
                            </button>
                                <button mat-menu-item (click)="requestDetail(row.requestId)">
                              <i class="fi fi-rr-eye"></i>
                              <span style="padding-left:5px;">Ver detalles Solicitud</span>
                            </button>
                                <button mat-menu-item (click)="cv(row.id)">
                              <i class="fi fi-rr-book-alt"></i>
                              <span style="padding-left:5px;">Hoja de vida</span>
                            </button>
                            <button mat-menu-item (click)="resentEmail(row.id,row.email)">
                                <i class="fi fi-rr-envelope"></i>
                                <span style="padding-left:5px;">Reenviar email</span>
                              </button>
                            </mat-menu>
                        </td>
                    </ng-container>
  
                    <ng-container matColumnDef="scheduled">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header >
                          <span>Cita</span>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.scheduled }}
                        </td>
                    </ng-container>
  
  
                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Estado </th>
                        <td mat-cell *matCellDef="let row"> {{row.stateName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Nombre </th>
                        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                    </ng-container>
  
                    <ng-container matColumnDef="id_number">
                        <th mat-header-cell class="w-75" *matHeaderCellDef mat-sort-header># Documento </th>
                        <td mat-cell *matCellDef="let row"> {{row.documentId}} </td>
                    </ng-container>
  
                    <ng-container matColumnDef="mobilephone">
                        <th mat-header-cell class="w-75" *matHeaderCellDef mat-sort-header> Celular </th>
                        <td mat-cell *matCellDef="let row"> {{row.mobile_phone}} </td>
                    </ng-container>
  
                    <ng-container matColumnDef="email">
                        <th mat-header-cell class="w-75" *matHeaderCellDef mat-sort-header> Email </th>
                        <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                    </ng-container>
                    <ng-container matColumnDef="position" >
                        <th mat-header-cell class="w-75" *matHeaderCellDef mat-sort-header style="align-items: center;"> Cargo </th>
                        <td mat-cell *matCellDef="let row"> {{row.position}} </td>
                    </ng-container>
                    <ng-container matColumnDef="campaign" >
                        <th mat-header-cell class="w-75" *matHeaderCellDef mat-sort-header style="align-items: center;"> Campaña </th>
                        <td mat-cell *matCellDef="let row"> {{row.campaign}} </td>
                    </ng-container>
  
                    <ng-container matColumnDef="tracingAvg">
                        <th class="end" mat-header-cell *matHeaderCellDef mat-sort-header> % Avance </th>
                        <td mat-cell *matCellDef="let row" [style.color]="row.color">
                            <circle-progress [percent]="row.tracingAvg" [radius]="20" [space]="-10" [outerStrokeGradient]="true" [outerStrokeWidth]="8" [outerStrokeColor]="'#2CABBC'" [outerStrokeGradientStopColor]="'#2CABBC'" [innerStrokeColor]="'#e7e8ea'" [innerStrokeWidth]="8"
                                [title]="row.tracingAvg" [titleFontSize]="10" [animateTitle]="true" [animationDuration]="1000" [showUnits]="true" [showSubtitle]="false" [showBackground]="false" [clockwise]="true" [startFromZero]="false">
                            </circle-progress>
                        </td>
                    </ng-container>
  
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="8">No hay candidatos disponibles.</td>
                    </tr>
                </table>
                <mat-paginator [length]="length"
                    [pageSize]="pageSize"
                    (page)="getPaginatorData($event)"
                    [pageSizeOptions]="pageSizeOptions" #paginator>
                </mat-paginator>
            </mat-tab>
            <mat-tab label="Usuarios inactivos o listados">
                <table mat-table [dataSource]="dataSourceDisabbledUsers" matSort>

                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Estado </th>
                        <td mat-cell *matCellDef="let row"> {{row.stateName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Nombre </th>
                        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                    </ng-container>
  
                    <ng-container matColumnDef="id_number">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header># Documento </th>
                        <td mat-cell *matCellDef="let row"> {{row.documentId}} </td>
                    </ng-container>
  
                    <ng-container matColumnDef="mobilephone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Celular </th>
                        <td mat-cell *matCellDef="let row"> {{row.mobile_phone}} </td>
                    </ng-container>
  
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                        <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                    </ng-container>
                    <ng-container matColumnDef="position" >
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Cargo </th>
                        <td mat-cell *matCellDef="let row"> {{row.position}} </td>
                    </ng-container>
                    <ng-container matColumnDef="campaign" >
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> Campaña </th>
                        <td mat-cell *matCellDef="let row"> {{row.campaign}} </td>
                    </ng-container>
  
                    <ng-container matColumnDef="tracingAvg">
                        <th class="end" mat-header-cell *matHeaderCellDef mat-sort-header> % Avance </th>
                        <td mat-cell *matCellDef="let row" [style.color]="row.color">
                            <circle-progress [percent]="row.tracingAvg" [radius]="20" [space]="-10" [outerStrokeGradient]="true" [outerStrokeWidth]="8" [outerStrokeColor]="'#2CABBC'" [outerStrokeGradientStopColor]="'#2CABBC'" [innerStrokeColor]="'#e7e8ea'" [innerStrokeWidth]="8"
                                [title]="row.tracingAvg" [titleFontSize]="10" [animateTitle]="true" [animationDuration]="1000" [showUnits]="true" [showSubtitle]="false" [showBackground]="false" [clockwise]="true" [startFromZero]="false">
                            </circle-progress>
                        </td>
                    </ng-container>
  
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsDisabled"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsDisabled;"></tr>
  
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="8">No hay candidatos disponibles.</td>
                    </tr>
                </table>
                <mat-paginator [length]="lengthDisabled"
                    [pageSize]="pageSizeDisabled"
                    (page)="getPaginatorDataDisabled($event)"
                    [pageSizeOptions]="pageSizeOptions" #paginator>
                </mat-paginator>
            </mat-tab>
        </mat-tab-group>
    </div>
  </div>
  