<div class="admin-reporte">

    <div class="header">
  
      <h2 style="height: 33px !important;
      font: normal normal bold 45px/35px Poppins !important;
      letter-spacing: 0px !important;color: #353535 !important;opacity: 1 !important;">Reportería</h2>
      <br>
      <p>Aquí podrás crear y descargar los reportes detallados de retroalimentaciones realizadas</p>
      <p>a las campañas. No olvides, “El respeto y empatía son clave”.</p>
    </div>
    <div class="mt-5">
        <app-main-matrices></app-main-matrices>
    </div>
</div>