<mat-vertical-stepper #stepperStrategys [linear]="true" >
    <!-- STEP BUSQUEDA DE CHATS -->
    <mat-step>
        <ng-template matStepLabel>Consulta</ng-template>
        <div class="row mt-4">
            <div class="col-12">
                <h3>Consulta de chats</h3>
                <hr/>
            </div>
            <div class="col-12 mt-3">
                <p>En esta sección, podrás buscar y encontrar registros de los chats por carga disponibles.</p>
            </div>
        </div>

        <form class="mt-4"  [formGroup]="formFilters">
            <div class="row d-flex justify-content-end mt-5 w-100 ">

                <div class="col-3">
                    <mat-form-field class="w-100" appearance="fill">
                        <mat-label>Carga</mat-label>
                        <mat-select formControlName="upload" required  (selectionChange)="searchSelection('search')">
                            <ng-container *ngFor="let type of upload_list">
                                <mat-option [value]="type.id" [matTooltip]="type.file">{{ type.file }}</mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="formFilters.get('upload').hasError('required')">Selecciona una opción</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-3">
                    <mat-form-field class="w-100 custom-form-field" appearance="fill">
                        <mat-label>Nº Caso </mat-label>
                        <input matInput formControlName="case_number" type="text" (input)="stripText('case_number')">
                    </mat-form-field>
                </div>

                <div class="col-3">
                    <mat-form-field class="w-100 custom-form-field" appearance="fill">
                        <mat-label>Nombre del agente</mat-label>
                        <input matInput formControlName="agent_name" type="text">
                    </mat-form-field>
                </div>

                <div class="col-3">
                    <button class="heaight-buttons w-100" matTooltip='Buscar' mat-flat-button
                        color="primary" (click)="searchSelection('searchFilter')">
                        Buscar &nbsp; <i class="fi-rr-search"></i>
                    </button>
                </div>
            </div>
        </form>

        <div class="table-container mt-5">
            <div class="mat-elevation-z1">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container *ngFor="let column of displayedColumns">
                        <ng-container [matColumnDef]="column" *ngIf="column !== 'checbox' && column !== 'conversation'">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }}</th>
                            <td mat-cell *matCellDef="let element" class="conserver-original-text"> {{ element[column] }} </td>
                        </ng-container>
                        <ng-container [matColumnDef]="column" *ngIf="column === 'checbox'">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? selectAll() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                            </th>
                            <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? toggleSelection(element) : null"
                                [checked]="selection.isSelected(element)" ></mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container [matColumnDef]="column" *ngIf="column === 'conversation'">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                            <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element" >
                            <button  mat-icon-button style="width: 25px !important;" (click)="modalChatView(element.id)">
                                <span class="fi-rr-comment"></span>
                            </button>
                            </td>
                        </ng-container>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr style="background: white" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr class="mat-row" style="background: white" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="displayedColumns.length">No existe información relacionada</td>
                    </tr>
                </table>

                <mat-paginator #paginator (page)="changePage($event, 'recordings')" [length]="configPaginator.length"
                    [pageSize]="configPaginator.pageSize" [pageSizeOptions]="pageSizeOptions">
                </mat-paginator>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-4 mt-3 ">
                <button class="w-100"  mat-raised-button color="primary" (click)="analytics()" > Analizar &nbsp; <i class="fi-rr-forward"></i> </button>
            </div>
        </div>
    </mat-step>
    <!-- STEP BUSQUEDA DE CHATS -->


    <!-- STEP ANALIZAR DE CHATS -->
    <mat-step>
        <ng-template matStepLabel>Análisis</ng-template>
        <div class="row mt-4">
            <div class="col-12">
                <h3>Resultados de análisis</h3>
                <hr />
            </div>
            <div class="col-9 mt-3">
                <p>En esta sección, podrás visualizar los resultados de los análisis de las conversaciones.</p>
            </div>

            <div class="col-3 d-flex  align-items-end">
                <button class="w-100 heaight-buttons" (click)="downloadReport('analysis')" 
                    mat-flat-button color="primary">
                    Descargar informe <i class="fi-rr-download"></i>
                </button>
            </div>
        </div>

        <div class="table-container mt-5">
            <div class="mat-elevation-z1">
                <table mat-table [dataSource]="dataSourceRecordings" matSort #sortRecordings="matSort">
                    <ng-container *ngFor="let column of displayedColumnsRecordings ">
                        <ng-container [matColumnDef]="column" *ngIf="column !== 'checbox' && column !== 'conversation' && column !== 'analisis' ">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                            <td mat-cell *matCellDef="let element" class="conserver-original-text"> {{ element[column] }} </td>
                        </ng-container>
                        <ng-container [matColumnDef]="column" *ngIf="column === 'checbox'">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? selectAllRecordings() : null"
                                [checked]="selectionRecordings.hasValue() && isAllSelectedRecordings()" 
                                [indeterminate]="selectionRecordings.hasValue() && !isAllSelectedRecordings()"
                                ></mat-checkbox>
                            </th>
                            <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element">
                                <mat-checkbox 
                                    (click)="$event.stopPropagation()"
                                    (change)="element.analisis !== 'ERROR' && element.analisis !== 'PENDIENTE' ? toggleRecordingSelection(element) : null"
                                    [checked]="selectionRecordings.isSelected(element)"
                                    [disabled]="element.analisis === 'ERROR' || element.analisis === 'PENDIENTE'">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container [matColumnDef]="column" *ngIf="column === 'conversation'">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                            <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element" >
                            <button  mat-icon-button style="width: 25px !important;" (click)="modalChatView(element.id)">
                                <span class="fi-rr-comment"></span>
                            </button>
                            </td>
                        </ng-container>

                        <ng-container [matColumnDef]="column" *ngIf="column === 'analisis'">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                            <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element">
                                <ng-container *ngIf="element.analisis  === 'PENDIENTE'">
                                    Pendiente
                                </ng-container>
                                <ng-container *ngIf="element.analisis  === 'ERROR'">
                                    <!-- Si el estado es error, mostrar el modal -->
                                    <button mat-icon-button style="width: 25px !important;" (click)="errorAnalyticModal(element)">
                                        <span class="fi fi-sr-exclamation"></span>
                                    </button>
                                    <button mat-icon-button style="width: 25px !important;" (click)="confirmReprocess(element)">
                                        <span class="fi-sr-time-past"></span>
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="element.analisis  !== 'PENDIENTE' && element.analisis !== 'ERROR'">
                                    <!-- Si el estado no es pendiente, mostrar el botón de búsqueda -->
                                    <button mat-icon-button style="width: 25px !important;" (click)="openAnalyticsModal(element.id)">
                                    <span class="fi-rr-search"></span>
                                    </button>
                                </ng-container>
                            </td>
                        </ng-container>

                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsRecordings"></tr>
                    <tr style="background: white" mat-row *matRowDef="let row; columns: displayedColumnsRecordings;"></tr>
                    <tr class="mat-row" style="background: white" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="displayedColumnsRecordings.length">No existe información relacionada</td>
                    </tr>
                </table>

                <mat-paginator #Analyticpaginator (page)="changePage($event, 'analisis')" [length]="configPaginatorRecording.length"
                    [pageSize]="configPaginatorRecording.pageSize"
                    [pageSizeOptions]="pageSizeOptions">
                </mat-paginator>

            </div>
        </div>
        <div class="row mt-5">
            <div class="col-4 mt-3">
                <button class="w-100"  mat-raised-button color="primary" (click)="searchSelection('strategia')" [disabled]="!ColumnVisibleTotal"> Aplicar estrategia &nbsp; <i class=" fi-rr-forward"></i></button>
            </div>
        </div>

    </mat-step>
    <!-- STEP ANALIZAR DE CHATS -->
        <!-- STEP APLICAR STRATEGIA -->
        <mat-step>
            <ng-template matStepLabel>Aplicar estrategia</ng-template>
                <div class="row mt-4">
                    <div class="col-12">
                        <h3>Aplicar estrategias</h3>
                        <hr/>
                    </div>
                    <div class="col-9 mt-3">
                        <p>En esta sección, podrás seleccionar las conversaciones que fueron analizados y aplicar o crear una estrategia para estas.</p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-3">
                        <mat-form-field class="w-100 custom-form-field" appearance="fill">
                            <mat-label>Estrategias</mat-label>
                            <mat-select [formControl]="strategyControl" required>
                                <ng-container *ngFor="let strategy of listStrategys">
                                    <mat-option [value]="strategy.id">{{ strategy.strategy_name }}</mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-error *ngIf="strategyControl.hasError('required')">La estrategia aplicar es requerida</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <button class="w-100 heaight-buttons"  mat-raised-button color="primary" (click)="createStrategyTranscription()" >Crear estrategia con transcripción &nbsp; <i class="fi-rr-plus"></i></button>
                    </div>
                    <div class="col-2"></div>
                    <div class="col-3">
                        <button class="w-100 heaight-buttons" mat-flat-button color="primary" (click)="downloadReport('strategy')" [disabled]="!startegyComplete">
                            Descargar informe <i class="fi-rr-download"></i>
                        </button>
                    </div>
                </div>
    
            <div class="table-container mt-5">
                <div class="mat-elevation-z1">
    
                    <table mat-table [dataSource]="dataSourceStrategy" matSort #sortStrategy="matSort">
                        <ng-container *ngFor="let column of displayedColumnsStrategy ">
                        <ng-container [matColumnDef]="column" *ngIf="column !== 'checbox' && column !== 'conversation' && column !== 'analisis' && column !== 'adherencia'">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                            <td mat-cell *matCellDef="let element" class="conserver-original-text"> {{ element[column] }} </td>
                        </ng-container>
                        <ng-container [matColumnDef]="column" *ngIf="column === 'checbox'">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? selectAllStrategy() : null"
                                    [checked]="selectionStrategy.hasValue() && isAllSelectedStrategy()"
                                    [indeterminate]="selectionStrategy.hasValue() && !isAllSelectedStrategy()"></mat-checkbox>
                            </th>
                            <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                              (change)="$event ? toggleSelectionStrategy(element) : null"
                                              [checked]="selectionStrategy.isSelected(element)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container [matColumnDef]="column" *ngIf="column === 'conversation'">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                            <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element" >
                            <button  mat-icon-button style="width: 25px !important;" (click)="modalChatView(element.id)">
                                <span class="fi-rr-comment"></span>
                            </button>
                            </td>
                        </ng-container>

                        <ng-container [matColumnDef]="column" *ngIf="column === 'analisis'">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                            <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element">
                                <ng-container *ngIf="element.analisis  === 'PENDIENTE'">
                                    Pendiente
                                </ng-container>
                                <ng-container *ngIf="element.analisis  === 'ERROR'">
                                    <!-- Si el estado es error, mostrar el modal -->
                                    <button mat-icon-button style="width: 25px !important;" (click)="errorAnalyticModal()">
                                    <span class="fi fi-sr-exclamation"></span>
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="element.analisis  !== 'PENDIENTE' && element.analisis !== 'ERROR'">
                                    <!-- Si el estado no es pendiente, mostrar el botón de búsqueda -->
                                    <button mat-icon-button style="width: 25px !important;" (click)="openAnalyticsModal(element.id)">
                                    <span class="fi-rr-search"></span>
                                    </button>
                                </ng-container>
                            </td>
                        </ng-container>
                        
                        <ng-container [matColumnDef]="column" *ngIf="column === 'adherencia'">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ tHeadersRecordings[column] }}</th>
                            <td mat-cell *matCellDef="let element" (click)="AdherenciaModal(element.id)">
                                <span *ngIf="element.adherencia">{{ element[column] }}</span>
                                <span *ngIf="!element.adherencia">No Aplica</span>
                            </td>
                        </ng-container>
    
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsStrategy"></tr>
                    <tr style="background: white" mat-row *matRowDef="let row; columns: displayedColumnsStrategy;"></tr>
                    <tr class="mat-row" style="background: white" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="displayedColumnsStrategy.length">No existe información relacionada</td>
                    </tr>
                    </table>
    
                    <mat-paginator #StrategyPaginator (page)="changePage($event, 'strategy')" [length]="configPaginatorStrategy.length"
                        [pageSize]="configPaginatorStrategy.pageSize" [pageSizeOptions]="pageSizeOptions">
                    </mat-paginator>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-4 mt-3" >
                    <button class="w-100"  mat-raised-button color="primary" (click)="applyStrategySelected()">Aplicar estrategia</button>
                </div>
            </div>
    
        </mat-step>
        <!-- STEP APLICAR STRATEGIA -->

</mat-vertical-stepper>
