import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { PositionsService } from '../../../services/positions.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-position-create',
  templateUrl: './position-create.component.html',
  styleUrls: ['./position-create.component.sass']
})
export class PositionCreateComponent implements OnInit {
  positionForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    key: ['', Validators.required],
    position_category_id: ['', Validators.required],
    functions: ['', Validators.required],
    charges: ['', Validators.required],
    dependents: ['', Validators.required]
  });

  constructor(private fb: FormBuilder, private positionsService: PositionsService,
    private dialogRef: MatDialogRef<PositionCreateComponent>,
    private alertService: AlertsService,
     @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if (this.data.action == 'Editar') {
      this.positionsService.getPosition(this.data.id)
        .subscribe(res => {
          this.positionForm.patchValue(res)
          console.log(res);
          let x = res;
          var y: number = +x["charges"];
          this.positionForm.controls.charges.setValue(y);
        })
    }
  }
  createPosition() {
    switch (this.data.action) {
      case 'Crear':
        let saveconfirm1 = this.alertService.alertConfirm('¿Está seguro de actualizar la posición?')
        saveconfirm1.then(res => {
          if (res.isConfirmed) {
            this.positionsService.postPosition('create', this.positionForm.value).subscribe((res: any) => {
              this.alertService.alertSuccess('¡Creado!', res);
              this.dialogRef.close(true)
            })
          }
        })
        break;
      case 'Editar':
        let saveconfirm2 = this.alertService.alertConfirm(`¿Está seguro que desea editar este cargo?\nCualquier modificación realizada afectara los contratos ya firmados.`)
        saveconfirm2.then(res => {
          if (res.isConfirmed) {
            this.positionsService.postPosition(`update/${this.data.id}`, this.positionForm.value).subscribe((res: any) => {
              this.alertService.alertSuccess('¡Actualizado!', `El cargo ${this.positionForm.value.name} ha sido editado con éxito`);
              this.dialogRef.close(true)
            })
          }
        })
        break;

    }

  }
}
