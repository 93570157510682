import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SoulBiComponent } from './soul_bi.component';
import { SoulBiRoutingModule } from './soul_bi-routing.module';
import { ConfigurationComponent } from './modules/configuration/configuration.component';
import { EditConfigurationComponent } from './modules/edit-configuration/edit-configuration.component';
import { ShowReportComponent } from './modules/show-report/show-report.component';
import { IndexComponent } from './modules/index/index.component';



@NgModule({
  declarations: [
    SoulBiComponent,
    ConfigurationComponent,
    EditConfigurationComponent,
    ShowReportComponent,
    IndexComponent
  ],
  imports: [
    CommonModule,
    SoulBiRoutingModule,
    MaterialModule,
    SharedModule,
  ]
})
export class SoulBiModule { }
