<div class="sidenav_container" [@onSideNavChange]="sideNavState ? 'open' : 'close'">
    <div fxLayout="column" fxLayoutGap="10px" style="height: 100%;">
        <div>

            <div class="navbar-brand">
                <span [@animateText]="linkText ? 'show' : 'hide'"><img src="../../../assets/images/Icon-Soul.svg" alt=""></span>
                <span [@animateText]="linkText ? 'hide' : 'show'"><img src="../../../assets/images/Icon-Soul.svg" alt=""></span>
            </div>

            <mat-nav-list>
                <div class="navbar-scroll">
                    <a routerLink="ciu/adherencia" mat-list-item *ngIf="router.url.includes('/mios/ciu')"  style="padding-left: 5px;" [disabled]='this.activateMenu'>
                        <i class="fi-rr-stats"></i>
                        <span   [@animateText]="linkText ? 'show' : 'hide'" style="padding-left:5px;">Adherencia</span>
                    </a>
                    <a [routerLink]="[page.link]" mat-list-item *ngFor="let page of pages" style="padding-left: 5px;" [disabled]='this.activateMenu'>
                        <i class="{{page?.icon}}"></i>
                        <span [@animateText]="linkText ? 'show' : 'hide'" style="padding-left:5px;">{{ page?.name }}</span>
                    </a>
                </div>
                

            </mat-nav-list>

        </div>
    </div>
</div>
