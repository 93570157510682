import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import { CoachingService } from 'src/app/modules/coaching/services/coaching.service';
import { CoachingState } from 'src/app/modules/coaching/state/coaching.state';
import { listCampañas } from 'src/app/modules/coaching/state/selectors/selects.selector';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.sass']
})
export class FiltrosComponent implements OnInit {


  btnActive:boolean = false;
  campaignJefe:boolean = false;
  is_supervisor:boolean = false;

    //Filtros
    filtroFecha = new FormGroup({
      fechaInicial: new FormControl(),
      fechaFinal: new FormControl(),
    });
    filtroFechaSegui = new FormGroup({
      fechaInicialSegui: new FormControl(),
      fechaFinalSegui: new FormControl(),
    });
    filtroSeguimiento:string = '';
    filtroCampaign:string = '';
    filtroParametro:string = '';

    campaigns$:Observable<any> = new Observable();

  constructor(
    private coachService:CoachingService,
    private store:Store<CoachingState>,
    private authService:AuthService,
  ) { }

  ngOnInit(): void {
    this.campaigns$ = this.store.select(listCampañas);
    this.getFilterParam();
  }

  download(){

    const params = {
      fechaInicial:'',
      fechaFinal:'',
      fechaInicialSegui:'',
      fechaFinalSegui:'',
      campaing:'',
      search:''
    }

    if (this.filtroFecha.get('fechaInicial').value != null && this.filtroFecha.get('fechaFinal').value != null) {
      params.fechaInicial = moment(this.filtroFecha.get('fechaInicial').value).format('yyyy-MM-DD');
      params.fechaFinal = moment(this.filtroFecha.get('fechaFinal').value).format('yyyy-MM-DD');
    }

    if (this.filtroFechaSegui.get('fechaInicialSegui').value != null && this.filtroFechaSegui.get('fechaFinalSegui').value != null) {
      params.fechaInicialSegui = moment(this.filtroFechaSegui.get('fechaInicialSegui').value).format('yyyy-MM-DD');
      params.fechaFinalSegui = moment(this.filtroFechaSegui.get('fechaFinalSegui').value).format('yyyy-MM-DD');
    }

    if (this.filtroCampaign != null) {
      params.campaing = this.filtroCampaign;
    }

    if (this.filtroParametro != '') {
      params.search = this.filtroParametro;
    }

  this.coachService.getReport(params).subscribe((resp:any)=>{
    Tools.downloadFile(resp,'Coach_'+moment(new Date()).format('DD_MM_yyyy_hh_mm_ss'))
  });
  }

 /**
 * @author Cristian Gallo
 * @createdate 2022-02-04
 * Metedo que se encargara de habilitar y completar el campo filtro supervisor.
 * Tambien dara manejo el filtro de campañas.
 */

 getFilterParam(){
  this.authService.getUser().roles.forEach(rol => {
    if (rol == 'coaching::jefe' || rol == 'coaching::gerente') {
      this.campaignJefe = true;
    }
    
    if (rol == 'coaching::supervisor') {
      this.is_supervisor = true;
      this.filtroParametro = this.authService.decryptToken().rrhh.ID_number
    }

  });
 }

}
