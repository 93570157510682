import { Component, OnInit, ViewChild,OnDestroy,DoCheck } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, throwError } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { PositionsService } from './../../../services/positions.service';
import { RequestsService } from './../../../services/requests.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { DynamicFormComponent } from '../../shared-form/dynamic-form/dynamic-form.component';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { ToWords } from 'src/app/core/to-words/to-words';


@Component({
  selector: 'app-modal-create-request',
  templateUrl: './modal-create-request.component.html',
  styleUrls: ['./modal-create-request.component.sass'],
})
export class ModalCreateRequestComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  @ViewChild(DynamicFormComponent) hijo: DynamicFormComponent;

  myControl = new FormControl();
  options: any;
  filteredOptions: Observable<any[]>;
  data: any = [];
  cargos: any;
  formularios: any = [];
  usuario: any;
  seleccionado: any;
  responsable: any = {};
  tipoSeleccionado;
  formSection =[
    {
      name_section:null,
      fields: null,
      type_section: 1,
      collapse: false,
      duplicar: false
    },
  ]
  tipos;
  now = new Date().toISOString().split('.')[0];
  formGroup :  FormGroup;
  comition: boolean= false
  specialComition: boolean= false
  boification: boolean= false
  toWords = new ToWords()

  constructor(public dialogRef: MatDialogRef<ModalCreateRequestComponent>,
              public dialog: MatDialog,
              private cargosService: PositionsService,
              private serviceBpms: RequestsService,
              private alertService: AlertsService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.usuario = this.authService.getUser();
    this.serviceBpms.consultarTipoSolicitud().subscribe(respuesSolicitud => {
      this.tipos = respuesSolicitud;
      });
      this.formControl();
    }
   /**
   * @author Karol García
   * @createdate 2021-04-12
   * Método que devuelve el nombre elegido en loa outocomplete
   */
  displayFn(option): any {
    if (!option) return '';
    let index = this.options.findIndex(state => state === option);
    return this.options[index].name;
  }

  filtrarTipo() {
     this.cargosService.getPositionsStatusActive(this.tipoSeleccionado.id).subscribe( (resp:any) => {
      this.options = resp;
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
    });

  }

  buildForm(cargo) {
    this.seleccionado = cargo;
    this.serviceBpms.consultarTipoFormulario(cargo.postition_category_id, cargo.name).subscribe(form => {
      this.formSection[0].name_section = `Solicitud ${this.tipoSeleccionado.name}`
      let index2 = form.formJson.findIndex(question => question.key === 'cargoSolicitante');
      if(index2>=0){
        const found = form.formJson[index2].options.find(element => element.name == this.usuario.rrhh.position);
        form.formJson[index2]['value']= found
       }
      form.formJson.map(element=>{
        if(element.controlType == "datepicker"){
          element.minLength = this.now
        }
        if(element.key == 'fechaSolicitud'){
          element.value= this.now
        }
      })
      let formulario = form.formJson;
      for ( let i = 0; i < formulario.length; i++) {
        if(formulario[i].key == 'funciones'){
          formulario.splice(i,1);
        }
      }
      this.formSection[0].fields= formulario;

      let index1 = this.formSection[0].fields.findIndex(question => question.key === 'nombreSolicitante');
      this.formSection[0].fields[index1].value = this.usuario.rrhh.name
  });
  }

  close(refreshRequests?) {
    this.dialogRef.close(refreshRequests);
  }

  createRequest(res) {
    let formulario = res.answer[0]

    this.alertService.alertConfirm('¿Está seguro que desea generar esta solicitud?').then((result) => {
      if (result.isConfirmed) {
        const cargoSeleccionado = {
        cargo: this.seleccionado.name
        };
        //se llama la funcion que convierte el valor numerico en string
        let toWords = this.toWords.convert(this.formGroup.get('hoursPerWeek').value);
        let hoursLetters = toWords.substring(0, toWords.length - 6);
        //se agregan los valores al JSON de special-policy
        const special_policy = {
          'special-policy': this.formGroup.value
          };
        special_policy['special-policy']['hoursLetters'] = hoursLetters;

        const salary = formulario.asignacionSalarial;
        formulario.asignacionSalarial = salary.replaceAll(',', '');
        const formularioCompleto = Object.assign(formulario, cargoSeleccionado, special_policy);

        const Solicitud = {
          keyTipoSolicitud: this.seleccionado.postition_category_id, // tipo es el id del tipo de solicitud
          applicantUserId: this.usuario.rrhh_id,
          infoSolicitud: JSON.stringify(formularioCompleto),
          state: 1
        };

        this.serviceBpms.guardarFormulario(Solicitud).subscribe((respuesta: any) => {
          this.alertService.alertSuccess('Guardado', respuesta.message);
          this.close(true);
        });
  }});
}

 /**
   * @author Karol García
   * @createdate 2021-04-12
   * Método que deshabilita el autocomplete y crea el formulario
   */
optionSelected(event){
  this.buildForm(event.option.value);
  this.myControl.disable()
}

  private _filter(value: any): string[] {
    if (!value.id) {
      const filterValue = value.toLowerCase();
      return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
    }
  }
  ngOnDestroy() {

    this.seleccionado = null;
    this.destroy$.next();  // trigger the unsubscribe
    this.destroy$.complete(); // finalize & clean up the subject stream
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-06-13
   * Método que muestra u oculta el checkbox
   */

  hasComition(){
    this.comition = !this.comition;
    if(this.specialComition == true)
    {
      this.specialComition = false;
      this.formGroup.get("hasSpecialComitionFrom").setValue("");
      this.formGroup.get("specialComitionDetails").setValue("");
    }
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-06-13
   * Método que muestra u oculta el checkbox
   */
  hasSpecialComition(){
    this.specialComition = !this.specialComition;
    if(this.specialComition == false)
    {
      this.formGroup.get("specialComitionDetails").setValue("");
    }
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-06-13
   * Método que muestra u oculta el checkbox
   */
  hasboification(){
    this.boification = !this.boification;
    if(this.boification == false)
    {
      this.formGroup.get("bonificationDetailsrom").setValue("");
    }
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-06-13
   * Método que setea los valores del formulario
   */
  async formControl(){
    this.formGroup = new FormGroup({
      hoursPerWeek: new FormControl('', [Validators.maxLength(15),Validators.minLength(1)]),
      hasComitionFrom: new FormControl(''),
      hasSpecialComitionFrom: new FormControl(''),
      specialComitionDetails: new FormControl(''),
      comprehensiveSalary: new FormControl(''),
      hasBonificationFrom: new FormControl(''),
      bonificationDetailsrom: new FormControl(''),
      finalidad: new FormControl(''),
    });
  }
}
