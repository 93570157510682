import { Component, OnInit, Inject } from '@angular/core';
import { UsersService } from '../../../services/users.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestsService } from '../../../services/requests.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';

@Component({
  selector: 'app-request-assign',
  templateUrl: './request-assign.component.html',
  styleUrls: ['./request-assign.component.sass']
})
export class RequestAssignComponent implements OnInit {

  recluters = [];
  requestId;
  recluterSelected;
  constructor(private userService: UsersService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private requestService: RequestsService,
              public dialog: MatDialogRef<RequestAssignComponent>,
              private notification:AlertsService) {
                this.requestId = this.data.requestId;
               }

  ngOnInit(): void {
    this.getRecluters();
  }
   /**
   * Metodo que retorna reclutadores solo para que el usuario lider(responsable)
   * pueda asignar un reclutador a la solicitud
   */
  getRecluters(){
    this.userService.getUserByRole('reclutador').subscribe((resp: any) => {
      this.recluters = resp;
    });
  }
  /**
   * Metodo que permite asignar reclutador a una solicitud
   * @param requestId
   */
  assign(){

    this.notification.alertConfirm('¿Estas seguro que desea asignar el reclutador seleccionado?').then(e => {
      if(e.isConfirmed){
        const params = {
          userAssignedId: this.recluterSelected,
          userApplicantId: this.data.userApplicantId
        }
        this.requestService.assignRecluter(this.requestId,params).subscribe( ( e ) => {
          this.notification.alertSuccess('Asignado', 'Reclutador Asignado con éxito');
          this.dialog.close(true);
        });
      }
    });
  }
  setRecluter(recluterId){
    this.recluterSelected = recluterId;
  }
}
