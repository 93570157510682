import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { TrainingGroupsService } from '../../../services/training-groups.service';
import { MatDialog } from '@angular/material/dialog';
import { GroupsCreateComponent } from '../groups-create/groups-create.component';
import { MatSort } from '@angular/material/sort';
import { GroupsViewComponent } from '../groups-view/groups-view.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../../core/services/rest/auth.service';
import {switchMap} from 'rxjs/operators';
import { AssignUsersComponent } from '../assign-users/assign-users.component';

@Component({
  selector: 'app-groups-main',
  templateUrl: './groups-main.component.html',
  styleUrls: ['./groups-main.component.sass']
})
export class GroupsMainComponent implements OnInit {

  length: number;
  pageSize: number;
  currentPage = 1;
  showGroups = false;
  campaigns:any
  enterprises:any
  action:string= 'Asignar'
  id:any =''
  trainer:any=''
  company :any=''
  campaign:any=''
  typeFilter:string = null
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  columnName:string[]=[]
  displayedColumns: string[] =[]
  dataSource = new MatTableDataSource();
  analistUser: boolean = false;
  leader: boolean = false;

  constructor(private serviceGroups: TrainingGroupsService,
              public dialog: MatDialog,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public auth:AuthService) { }

  ngOnInit(): void {
    this.auth.getUser().roles.forEach(element => {
      if(element == 'rrhh::lider-de-usuarios' || element == 'rrhh::coordinador-de-usuarios' || element == 'rrhh::lider_operativo' || element == 'rrhh::lider_administrativo'){
        this.analistUser = true
      }
      if(element == 'rrhh::lider-formador'){
        this.leader = true;
      }
    });
    this.getGroups();
    this.serviceGroups.getDataFilters(`dropdown/options/campana`).pipe(
      switchMap(campaigns =>{
        this.campaigns = campaigns
        return  this.serviceGroups.getDataFilters('formationgroups/enterprises')
      })
    ).subscribe(enterprises=>{
      this.enterprises = enterprises
    })
  }

  filterByValue(event){
    this.currentPage = 1
    this.paginator.pageIndex = 0;

    this.id=''
    this.trainer=''
    this.company = ''
    this.campaign=''
    switch (this.typeFilter) {
      case "id":
        this.id = event;
       break;
      case "Formador":
        this.trainer= event
       break;
      case "Empresa":
        this.company = event
        break;
      case "Campaña":
        this.campaign = event;
        break;
    }
    this.getGroups() ;
  }
  getGroups() {
    this.columnName=[]
    this.displayedColumns=[]
    this.serviceGroups.getGroups({page: this.currentPage, id: this.id, trainer: this.trainer, company: this.company, campaing: this.campaign}).subscribe( (resp: any) => {
      resp.columns.forEach(element => {
        this.columnName.push(element.name)
        this.displayedColumns.push(element.field)
      });
      
      this.length = resp.data.total;
      this.pageSize = resp.data.per_page;
      this.dataSource = new MatTableDataSource(resp.data.data);
      this.dataSource.sort = this.sort;
      this.showGroups = true;
    });
  }

  createGroup () {
    const createDialog = this.dialog.open( GroupsCreateComponent, {
      minWidth: '60%',
      disableClose: true,
    });

    createDialog.afterClosed().subscribe( (res) => {

      if(res){
        this.getGroups();
      }

    });
  }

  viewGroup(trainingGroup) {
    this.router.navigate(['../grupo-formacion', trainingGroup.id], {relativeTo: this.activatedRoute});
  }
  openUsers(data){
    const assignUser=  this.dialog.open(AssignUsersComponent, {
      minWidth: '80%',
      disableClose: true,
      data: { group: data }
    })
    assignUser.afterClosed().subscribe( (res) => {
      if(res==true){
        this.getGroups();
      }
    });
  }

  getPaginatorData(event){
    this.currentPage = event.pageIndex + 1;    
    this.getGroups()
  }



}
