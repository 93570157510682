<div class="container-fluid p-5">
    <div class="row">
        <div class="col-md-12">
            <span style="font-size: 32px; font-weight: 700;">Chat analytics</span>
        </div>
        <div class="col-md-12 mt-4">
            <p class="lh-base" style="color: #353535">
                Este es el módulo de Chat analytics, en este espacio podrás realizar la cargar de los chats, analizarlos
                <br />
                y aplicar estrategias. No olvides, “El respeto y empatía son clave”.
            </p>
        </div>
    </div>
    <div class="row justify-content-between mt-5">
        <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabChange($event)">
            <mat-tab label="Cargar chats">
                <app-speech-uploadchat></app-speech-uploadchat>
            </mat-tab>
            <mat-tab label="Análisis de chats">
                <app-search-chat></app-search-chat>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>