import { ScheduleReportComponent } from './modules/schedule-report/page/schedule-report/schedule-report.component';
import { DisabilitiesComponent } from './modules/disabilities/pages/disabilities/disabilities.component';
import { VacationsComponent } from './modules/vacations/page/vacations/vacations.component';
import { LiquidationsComponent } from './modules/liquidations/pages/liquidations/liquidations.component';
import { NovedadesComponent } from './modules/data-nomina/pages/novedades/novedades.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NominaComponent } from './nomina.component';
import { AdminCampaignsComponent } from './modules/campaigns/admin-campaigns/admin-campaigns.component';
import { VacationBossComponent } from './modules/vacations/page/vacation-boss/vacation-boss.component';
import { VacationOperationManagerComponent } from './modules/vacations/page/vacation-operation-manager/vacation-operation-manager.component';
import { GeneralReportComponent } from './modules/reports/general-report/general-report.component';
import { JefeDirectosListComponent } from './modules/jefe-directos/pages/jefe-directos-list/jefe-directos-list.component';
import { ReferralsReportComponent } from './modules/referrals/ReferralsReport/ReferralsReport.component';
import { ImportDesprendiblesComponent } from './modules/import-desprendibles/import-desprendibles.component';
import { VacationConsultantComponent } from './modules/vacations/page/vacation-consultant/vacation-consultant.component';
import { DisabilitiesConsultantComponent } from './modules/disabilities/pages/disabilities-consultant/disabilities-consultant.component';
import { NovedadesCosultantComponent } from './modules/data-nomina/pages/novedades-cosultant/novedades-cosultant.component';
import { LiquidationsImportComponent } from './modules/liquidations-import/liquidations-import.component';
import { ImportGreenBookComponent } from './modules/import-green-book/import-green-book.component';

const routes: Routes = [
  { path: '', component: NominaComponent },
  { path: 'novelties', loadChildren: () => import('./modules/novelties/novelties.module').then(m => m.NoveltiesModule) },
  { path: 'inactivedates', loadChildren: () => import('./modules/inactive-days/inactive-days.module').then(m => m.InactiveDaysModule) },
  { path: 'commissions', loadChildren: () => import('./modules/commissions/commissions.module').then(m => m.CommissionsModule) },
  { path: 'campaigns', component: AdminCampaignsComponent},
  { path: 'noveltieslist', component: NovedadesComponent},
  { path: 'liquidations', component: LiquidationsComponent},
  { path: 'vacations', component: VacationsComponent},
  { path: 'vacationsboss', component: VacationBossComponent},
  { path: 'vacationsoperations', component: VacationOperationManagerComponent},
  { path: 'disabilities', component: DisabilitiesComponent},
  { path: 'schedulereport', component: ScheduleReportComponent},
  { path: 'reports', component: GeneralReportComponent},
  { path: 'boss', loadChildren: () => import('./modules/jefe-directos/jefe-directos.module').then(m => m.JefeDirectosModule)},
  { path: 'extrahours', loadChildren: () => import('./modules/extra-hours/extra-hours.module').then(m => m.ExtraHoursModule) },
  { path: 'countries', loadChildren: () => import('./modules/Countries/Countries.module').then(m => m.CountriesModule) },
  { path: 'assigncampaign', loadChildren: () => import('./modules/manager-campaign/manager-campaign.module').then(m => m.ManagerCampaignModule)},
  { path: 'workersvacation', loadChildren: () => import('./modules/workers-vacation/workers-vacation.module').then(m => m.WorkersVacationModule)},
  { path: 'aprovationlist', loadChildren: ()=> import('./modules/novelties-aprovation/novelties-aprovation.module').then(m => m.NoveltiesAprovationModule)},
  { path: 'saturdayvacations', loadChildren: ()=> import('./modules/saturday-vacations/saturday-vacations.module').then(m => m.SaturdayVacationsModule)},
  { path: 'formulaManager', loadChildren: ()=> import('./modules/formulaManager/formulaManager.module').then(m => m.FormulaManagerModule)},
  { path: 'business', loadChildren: ()=> import('./modules/Business/Business.module').then(m => m.BusinessModule)},
  { path: 'referrals', component: ReferralsReportComponent},
  { path: 'import-desprendibles', component: ImportDesprendiblesComponent},
  { path: 'payroll-permits',loadChildren: ()=> import('./modules/payroll-permits/payroll-permits.module').then(m => m.PayrollPermitsModule )},
  { path: 'vacations-consultant', component: VacationConsultantComponent},
  { path: 'disabilities-consultant', component: DisabilitiesConsultantComponent},
  { path: 'noveltieslist-consultant', component: NovedadesCosultantComponent},
  { path: 'liquidations-import', component: LiquidationsImportComponent},
  { path: 'import-greenbook', component: ImportGreenBookComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NominaRoutingModule { }
