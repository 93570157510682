<form mat-dialog-content [formGroup]="formGestionadorCorreos" (ngSubmit)="validateForm(formGestionadorCorreos)" autocomplete="off">
  <h2 mat-dialog-title class="fw-bold fs-4 mb-0">Nuevo correo</h2>
  <mat-divider></mat-divider>
  <p class="text-justify fs-6 mt-3">
    En este espacio podrás redactar y configurar el nuevo correo, podrás
    seleccionar el remitente desde el cual deseas que se envíe el correo, el o
    los destinatarios, si deseas enviar una copia o una copia oculta y
    personalizar el texto según se requiera.
  </p>
  <h2 mat-dialog-title class="fw-bold fs-4 mb-0">Destinatarios</h2>
  <div class="row mt-3">
    <div class="col-6">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Seleccioné un correo</mat-label>
        <mat-select formControlName="emailSalida">
          <mat-option *ngFor="let item of listDesde" [value]="item.id">
            {{ item.email }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="error.emailSalida.errors">
          <span *ngIf="error.emailSalida.errors.required">Este campo es requerido</span>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Para</mat-label>
        <mat-chip-list #chipListPara>
          <mat-chip
            *ngFor="let item of listPara"
            (removed)="removeItemByChipList(item, 'listPara')"
          >
            {{ item }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="Correo para"
            [matChipInputFor]="chipListPara"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="addItemByChipList($event, 'listPara')"
          />
        </mat-chip-list>
      </mat-form-field>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-6">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Con copia a</mat-label>
        <mat-chip-list #chipListCopia>
          <mat-chip
            *ngFor="let item of listCopia"
            (removed)="removeItemByChipList(item, 'listCopia')"
          >
            {{ item }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="Con copia a"
            [matChipInputFor]="chipListCopia"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="addItemByChipList($event, 'listCopia')"
          />
        </mat-chip-list>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Con copia oculta a</mat-label>
        <mat-chip-list #chipListCopiaOculta>
          <mat-chip
            *ngFor="let item of listCopiaOculta"
            (removed)="removeItemByChipList(item, 'listCopiaOculta')"
          >
            {{ item }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="Con copia oculta a"
            [matChipInputFor]="chipListCopiaOculta"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="
              addItemByChipList($event, 'listCopiaOculta')
            "
          />
        </mat-chip-list>
      </mat-form-field>
    </div>
  </div>
  <h2 mat-dialog-title class="fw-bold fs-4 mb-0">Detalle de correo</h2>
  <mat-divider></mat-divider>
  <div class="row mt-3">
    <div class="col-12">
      <mat-form-field class="w-100" appearance="fill">
        <input matInput placeholder="Asunto" formControlName="asunto" />
        <mat-error *ngIf="error.asunto.errors">
          <span *ngIf="error.asunto.errors.required">Este campo es requerido</span>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-chip-list>
        <mat-chip
          *ngFor="let item of listFiles"
          (removed)="removeFile(item.file.name)"
        >
          {{ item.file.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-4">
      <button
        mat-stroked-button
        color="accent"
        class="w-100 h-75"
        (click)="inputFile.click()"
        type="button"
      >
        Adjuntar documento &nbsp;
        <span class="fi-rr-upload"></span>
      </button>
      <input
        #inputFile
        type="file"
        id="inputFile"
        name="inputFile"
        style="display: none"
        multiple="multiple"
        (change)="addFile()"
      />
    </div>
    <div *ngIf="plantillasHtml.length > 0" class="col-5">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Plantilla HTML</mat-label>
        <mat-select formControlName="plantillaHtml" (selectionChange)="changePlantillaEmailBody($event)">
          <mat-option *ngFor="let plantilla of plantillasHtml" [value]="plantilla.id">{{ plantilla.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="error.plantillaHtml.errors">
          <span *ngIf="error.plantillaHtml.errors.required">Este campo es requerido</span>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-12">
      <angular-editor
        [placeholder]="'Enter text here...'"
        [config]="editorConfig"
        formControlName="emailBody"
      ></angular-editor>
      <mat-error *ngIf="error.emailBody.errors">
        <span *ngIf="error.emailBody.errors.required">Este campo es requerido</span>
      </mat-error>
    </div>
    <div id="bodyEmail" style="display: none;"></div>
  </div>
  <div class="row mt-4 d-flex justify-content-center">
    <div class="col-3">
      <button mat-stroked-button mat-dialog-close color="primary" class="w-100" type="button">Cancelar</button>
    </div>
    <div class="col-3">
      <button mat-raised-button color="primary" class="w-100" type="submit">Enviar</button>
    </div>
  </div>
</form>
