import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { CandidatesService } from '../../../services/candidates.service';
import { TestsService } from '../../../services/tests.service';
import { AssingUserComponent } from '../../assign-user/assing-user/assing-user.component';


@Component({
  selector: 'app-assessments-create',
  templateUrl: './assessments-create.component.html',
  styleUrls: ['./assessments-create.component.sass']
})
export class AssessmentsCreateComponent implements OnInit {
  allCandidateSelected = false;
  candidatesSelected:any = [];
  user_selection_name:string;
  user_operation_name:string;
  user_formation_name:string
  candidates=[];
  validTwoRes : number = 0;

  assessmentForm:FormGroup = this.fb.group({
    name:new FormControl('',[Validators.required]),
    capacity:new FormControl('',[Validators.required]),
    selection_name:[{value: '', disabled: true},Validators.required],
    operation_name:[{value: '', disabled: true},Validators.required],
    formation_name:[{value: '', disabled: true},Validators.required],
    start:new FormControl('',[Validators.required]),
    end:new FormControl('',[Validators.required]),
    description:['',Validators.required],
    user_selection_id: new FormControl(''),
    user_operation_id:new FormControl(''),
    user_formation_id: new FormControl(''),
    request_id:[this.data.requestId],
    candidate_ids:[null],
    
  });
 
  constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, 
  public testsService:TestsService, public candidatesService:CandidatesService, public alertService:AlertsService,
  public dialogRef: MatDialogRef<AssessmentsCreateComponent>, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.testsService.getCandidatesAssessmets(this.data.requestId,
      {type: 'assessment'}).
        subscribe((resp: any) => {
        this.candidates = resp.data
        });
  }
  createAssessment(){
    this.assessmentForm.get('candidate_ids').setValue( this.assessmentForm.get('candidate_ids').value.toString())
    //a solicitud de la MJ010 se valida que minimo sean dos responsables.
    if(this.validTwoRes > 1)
    {
      this.testsService.postRoomAssessmet(this.assessmentForm.getRawValue()).subscribe((res:any)=>{
        this.alertService.alertSuccess('¡Hecho!', res);
        this.dialogRef.close(true)
      });      
    }else{
      this.alertService.alertError("Error","Debes seleccionar mínimo dos responsables");
    }
  }
  searchUsers(rol, where){
    const saerchDialog = this.dialog.open( AssingUserComponent, {
      data: { role: rol}
    });
    saerchDialog.afterClosed().subscribe(res=>{
      if(res){
        this.validTwoRes++;
        this.assessmentForm.get(`user_${where}_id`).setValue(res.user.rrhh_id)
        this[`user_${where}_name`]= res.user.name
      }
    })
  }
  pushCandidates(event){
    this.assessmentForm.get('candidate_ids').setValue(event.selectedOptions.selected.map(item => item.value.id))
    this.candidatesSelected= event.selectedOptions.selected.map(item => item.value.name);
  }
  // let optionText = event.source.selected.viewValue;
}
