<i class="fi-rr-cross-small" style="cursor: pointer; float: right; font-size: 32px" mat-icon-button
  mat-dialog-close></i>
<h2 mat-dialog-title>
  <div style="width: 100%; padding-left: 50px; margin-top: 28px;">
    <h3 style="font-size: 28px !important; color: #353535;">{{data.title}}</h3>
  </div>
</h2>
<div class="row novelty-row-padding">

  <form class="form" [formGroup]="groupForm">

    <mat-dialog-content class="mat-typography">
      <!-- 
          <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
              <mat-label>Campaña</mat-label>
              <input type="text"
                     matInput
                     formControlName="campaigns"
                     [matAutocomplete]="auto"
                     [readonly]="data.type === 'editar'"
                     [matAutocompleteDisabled]="data.type === 'editar'"
                     required>
              <mat-autocomplete #auto="matAutocomplete" [displayWith]='displayFn.bind(this)' (optionSelected)="onChangeCampaing()" required cdkFocusInitial>
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="error.campaigns.errors"><span *ngIf="error.campaigns.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
              <mat-error *ngIf="error.campaigns.errors">Campaña no reconocida. Haga click en una de las opciones de autocompletar</mat-error>
          </mat-form-field> -->

      <mat-form-field appearance="fill" class="col-4">
        <mat-label>Campañas</mat-label>
        <mat-select [formControl]="CampaignServerSideCtrl" placeholder="Campañas" [multiple]="true">
          <mat-option>
            <ngx-mat-select-search [formControl]="campaignServerSideFilteringCtrl" [searching]="searching"
              placeholderLabel="Campaña..." noEntriesFoundLabel="'No encontrado'"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let item of filteredServerSideCampaigns | async" [value]="item">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
        <mat-label>Nombre del grupo</mat-label>
        <input matInput type="text" placeholder="Escribe el nombre del grupo" formControlName="group" required>
        <mat-error *ngIf="error.group.errors"><span *ngIf="error.group.errors.required">¡Advertencia! Este campo es
            requerido</span></mat-error>
        <mat-error *ngIf="error.group.errors"><span *ngIf="error.group.errors.minlength">¡Aún falta! Recibimos mínimo 3
            caracteres</span></mat-error>
        <mat-error *ngIf="error.group.errors"><span *ngIf="error.group.errors.maxlength">¡Cuidado! Solo puedes tener un
            máximo de 45 caracteres</span></mat-error>
      </mat-form-field>


      <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
        <mat-label>Descripción</mat-label>
        <input style="text-transform: lowercase;" matInput type="text" placeholder="Escribe la despcripción del grupo"
          formControlName="description" required>
        <mat-error *ngIf="error.description.errors"><span *ngIf="error.description.errors.required">¡Advertencia! Este
            campo es requerido</span></mat-error>
        <mat-error *ngIf="error.description.errors"><span *ngIf="error.description.errors.minlength">¡Aún falta!
            Recibimos
            mínimo 3 caracteres</span></mat-error>
        <mat-error *ngIf="error.description.errors"><span *ngIf="error.description.errors.maxlength">¡Cuidado! Solo
            puedes
            tener un máximo de 50 caracteres</span></mat-error>
      </mat-form-field>

      <!-- titulos -->
      <div fxLayout="row wrap" appearance="fill" fxLayoutAlign="space-between none">
        <div fxFlex="39" style=" padding-left: 1%;">
          <mat-label><b>Usuarios Disponibles</b></mat-label>
          <mat-form-field style="margin-left: 13px">
            <input matInput #userSearch>
          </mat-form-field>

        </div>
        <div fxFlex="20"></div>
        <div fxFlex="38">
          <br>
          <mat-label style="margin-bottom: 17px;"><b>Usuarios del Grupo</b></mat-label>
        </div>
      </div>

      <div fxLayout="row wrap" appearance="fill" fxLayoutAlign="space-around center">
        <!-- Listado de usuarios sin grupo-->
        <div fxFlex="37"
          style="padding-left: 1%; height: 250px; overflow-y:auto; border: solid 1px #ccc;  border-radius: 4px;"
          cdkScrollable>

          <mat-selection-list #disponibles [formControl]="lstInicial.controls['selected']">
            <mat-list-option *ngFor="let usuario of (userSinGroup | userGroupFilter:userSearch.value)"
              [value]="usuario">
              <span style="font-size: 13px!important;">
                <b>Nombre: </b>{{usuario.name}} | <b>Doc: </b>{{ usuario.documento }} | <b>Fecha: </b> {{
                usuario.created_at | date }}
              </span>
            </mat-list-option>
          </mat-selection-list>

        </div>
        <!-- Botones de agregar y quitar-->
        <div fxFlex="20" fxLayout="column" fxLayoutAlign="space-between center" style="align-content: center;">
          <button mat-raised-button color="primary"
            style="align-content: center; margin-top: 20px; margin-bottom: 30px;" (click)="asingarGrupo()">
            <span class="fi-rr-arrow-right"></span>
          </button>
          <button mat-raised-button color="primary" style="align-content: center;" (click)="quitarGrupo()">
            <span class="fi-rr-arrow-left"></span>
          </button>

        </div>
        <!-- Listado de usuarios del grupo-->
        <div fxFlex="37"
          style="padding-left: 1%; height: 250px; overflow-y:auto; border: solid 1px #ccc;  border-radius: 4px;"
          cdkScrollable>
          <mat-selection-list items="5" #usuarios [formControl]="lstfinal.controls['selected']">
            <mat-list-option *ngFor="let usuario of userGroup" [value]="usuario">
              <span style="font-size: 13px!important;">
                <b>Nombre: </b>{{usuario.name}} | <b>Doc: </b>{{ usuario.documento }} | <b>Fecha: </b> {{
                usuario.created_at | date }}
              </span>
            </mat-list-option>
          </mat-selection-list>
        </div>

      </div>

      <!-- contadores de opciones selecionadas -->
      <div fxLayout="row wrap" appearance="fill" fxLayoutAlign="space-around center" style="margin-bottom: 40px; margin-top: 16px;">
        <div fxFlex="42" style=" padding-left: 1%; text-align: start;">
          <p>
            <b>Opciones seleccionadas:</b> {{disponibles.selectedOptions.selected.length}}
          </p>
        </div>
        <div fxFlex="20"></div>
        <div fxFlex="38" style="text-align: start;">
          <p>
            <b>Opciones seleccionadas:</b> {{usuarios.selectedOptions.selected.length}}
          </p>
        </div>
      </div>

      <div fxLayout="row wrap" appearance="fill" fxLayoutAlign="space-around center">
        <div fxFlex="40" style="padding-left: 1%;">
          <p style="width: 100%; color: #353535; font-size: 24px !important;"> <b>Añadir usuario al grupo</b>  </p>
          <mat-form-field appearance="fill" style="width: 95%;">
            <mat-label>Documento</mat-label>
            <input matInput type="text" #input autocomplete="off" [formControl]="documentNumber"
              (keydown.enter)="onBlurMethod($event.target.value)" style="width: 147px !important;">
            <mat-select name="ampm" class="ampm" matSuffix [formControl]="typeDocument"
              style="width: 60px; margin-left: 5px;">
              <mat-option *ngFor="let item of documents" [value]="item?.id">{{item.key}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="60">
          <button mat-icon-button fxFlex="10" style="margin-top: 25px;" [hidden]="userInfo.length == 0" (click)="addUserToGroup()" >
            <span class="fi-rr-add" style="font-size: 24px !important; color: #BD77CE;"></span>
          </button>
          <p style="margin-top: 22px;" [hidden]="userInfo.length == 0"> <b>Nombre: </b> {{userInfo[0]?.name}}  <br>  <b>Grupo: </b> {{userInfo[0]?.group}}</p>
        </div>

      </div>

    </mat-dialog-content>

    <mat-dialog-actions align="end" style="padding: 8px 8px 25px 8px !important;">
      <button mat-stroked-button mat-dialog-close class="cancelButton">Cancelar</button>
      <button mat-raised-button color="primary" type="button" class="myButton"
        *ngIf="(data.type === 'editar' || data.type === 'crear') && userGroup.length > 0"
        disabled="{{groupForm.invalid || userGroup.length === 0}}" (click)="saveGroup(groupForm)">Guardar
      </button>
      <button mat-raised-button color="primary" type="button" class="myButton" (click)="inactiveGroup()"
        *ngIf="data.type === 'editar' && userGroup.length === 0" disabled="{{groupForm.invalid}}">Inactivar grupo
      </button>
    </mat-dialog-actions>

  </form>
</div>