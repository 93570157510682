import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { loadAcompañamientos } from 'src/app/modules/coaching/state/actions/acompañamiento.actions';
import { CoachingState } from 'src/app/modules/coaching/state/coaching.state';
import { NuevoCoachingComponent } from '../nuevo-coaching/nuevo-coaching.component';
import { listIndicadores, listEstados } from '../../../../state/selectors/selects.selector';
import { ActivatedRoute } from '@angular/router';
import { EstadoModel } from '../../../../core/interfaces/estado.interface';

@Component({
  selector: 'app-filtro-tabla',
  templateUrl: './filtro-tabla.component.html',
  styleUrls: ['./filtro-tabla.component.sass']
})
export class FiltroTablaComponent implements OnInit {

  indicadores$:Observable<any> = new Observable();
  estados$:Observable<any> = new Observable();
  selects$:Observable<any> = new Observable();
  estados = [];

  //Filtros
  filtroParametro:string = '';
  filtroFecha = new FormGroup({
    fechaInicial: new FormControl(),
    fechaFinal: new FormControl(),
  });
  filtroIndicador:string = '';
  filtroEstado:string = '';
  filtroReasignado:string = '';
  tab;
  //Variable para validar si es asesor
  is_asesor:boolean = false;
  //Varibale que contiene el filtro seleccionado por URL
  filter:string="";
  constructor(
    private dialog:MatDialog,
    private store:Store<CoachingState>,
    private authService:AuthService,
    private aRoute:ActivatedRoute,
    
  ) { }

  ngOnInit(): void {
    this.indicadores$ = this.store.select(listIndicadores);
    this.estados$ = this.store.select(listEstados);
    this.estados$.forEach((element) => {
      if (element) {
        this.estados = [];
        element.forEach(estado =>{
          if (estado.nombre == 'Abierto') {
            this.estados.push(estado);
          }
          if (estado.nombre == 'Vencido') {
            this.estados.push(estado);
          }
        })
      }
    });
    this.authService.getUser().roles.forEach(rol => {
      if (rol == 'coaching::asesor') {
        this.is_asesor = true;
      }
    });
    this.getParamsUrl();
    if(this.filter && this.filter !== ""){
      this.filtroReasignado = "1";
    }
  }

  newCoaching(){
    const coachingRef = this.dialog.open(NuevoCoachingComponent,{
      disableClose:true,
      width: '940px',
    })
  }

  filtroFechas(){
    if (this.filtroFecha.get('fechaInicial').value != null && this.filtroFecha.get('fechaFinal').value != null) {
      this.filtrar();
    }
  }

  get Tab(){
    return JSON.parse(localStorage.getItem('filtros_coaching')).tab;
  }

  /**
  * @author Cristian Gallo
  * @createdate 2021-12-10
  * Metodo para realizar filtros.
  */

  filtrar(){
    const params = JSON.parse(localStorage.getItem('filtros_coaching'));
    this.getParamsUrl();
    if (this.filtroParametro != '' && this.filtroParametro != null) {
      params.parametro = this.filtroParametro.toString();
    }else{
      params.parametro = '';
    }

    if (this.filtroFecha.get('fechaInicial').value != '' && this.filtroFecha.get('fechaInicial').value != null) {
      params.fechaInicial = moment(this.filtroFecha.get('fechaInicial').value).format('yyyy-MM-DD');
    }else{
      params.fechaInicial = '';
    }
    if (this.filtroFecha.get('fechaFinal').value != '' && this.filtroFecha.get('fechaFinal').value != null) {
      params.fechaFinal = moment(this.filtroFecha.get('fechaFinal').value).format('yyyy-MM-DD');
    }else{
      params.fechaFinal = '';
    }

    if (this.filtroIndicador != '' && this.filtroIndicador != null) {
      params.indicador = this.filtroIndicador;
    }else{
      params.indicador = '';
    }

    if (this.filtroEstado != '' && this.filtroEstado != null) {
      params.estado = this.filtroEstado;
    }else{
      params.estado = '';
    }

    if (this.filtroReasignado != '' && this.filtroReasignado != null && this.filtroReasignado !== "-" ) {
      params.reasignado = this.filtroReasignado;
    }else if(this.filtroReasignado === "-"){
      params.reasignado = "";
    }
    else if(this.filter && this.filter !== "" && this.filtroReasignado === ""){
      params.reasignado = '1'
    }else{
      params.reasignado = '';
    }
    params.page = 1;
    this.store.dispatch(loadAcompañamientos(params));
    localStorage.setItem('filtros_coaching',JSON.stringify(params));
  }

    /**
   * Metodo que carga los datos por URL para validacion y pbtencion de datos apra carga de tabla
   * @author Juan David Guerrero Vargas
   * @create_date 02/06/2022
   * @return void   
   */
     getParamsUrl():void{
      this.aRoute.paramMap.subscribe(params => {
        this.filter = params.get('filter');
      });
  
    }
}
