import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-network-users',
  templateUrl: './manage-network-users.component.html',
  styleUrls: ['./manage-network-users.component.sass']
})
export class ManageNetworkUsersComponent implements OnInit {
  tab_type:number = 1;

  constructor() { }

  ngOnInit(): void {
  }

  /**
  * Metódo que define la tab seleccionada
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
  setTabType(event:any){
    this.tab_type = event.index + 1;
  }

}
