export const NAMES_DAYS_OF_WEEK: string[] = [
  'Lunes',
  'Martes',
  'Miercoles',
  'Jueves',
  'Viernes',
  'Sabado',
  'Domingo'
];

export const OMIT_ACTIVITIES_WORK: string[] = [
  'Baño',
  'Pausa activa',
  'Capacitación'
];