import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AcompañamientoModel } from 'src/app/modules/coaching/core/interfaces/acompañamiento.interface';
import { loadHistorial, loadHistorialById } from 'src/app/modules/coaching/state/actions/historial.actions';
import { CoachingState } from 'src/app/modules/coaching/state/coaching.state';
import { historialSolicitud } from 'src/app/modules/coaching/state/selectors/historial.selector';
import { DialogHistoricoComponent } from '../dialog-historico/dialog-historico.component';

@Component({
  selector: 'app-tabla-historico',
  templateUrl: './tabla-historico.component.html',
  styleUrls: ['./tabla-historico.component.sass']
})
export class TablaHistoricoComponent implements OnInit {

  displayedColumns:string[] = ['acciones','coach', 'position','estado','fecha_creacion','proximo_seguimiento','fecha_ejecucion','dias'];
  dataSource = [];

  id;

  length;
  pageSize = 5;
  page = 1;
  pageSizeOptions = [5,10,25,50,100];
  @ViewChild(MatPaginator) paginator:MatPaginator;

  constructor(
    private aRoute:ActivatedRoute,
    private store:Store<CoachingState>,
    private dialog:MatDialog,
  ) { }

  ngOnInit(): void {
    this.aRoute.paramMap.subscribe(params=>{
      this.id = params.get('id');
    });
    this.datosTabla();
  }

  datosTabla(){
        /**
    * @author Cristian Gallo
    * @createdate 2021-12-13
    * Metodo para ejecutar un select y obtener la información para la tabla
    */
   this.store.select(historialSolicitud).forEach((historial:AcompañamientoModel) => {
    if (historial != null) {
      this.dataSource = historial.data;
      this.length = historial.total;
      if (historial.current_page == 1 && this.paginator) {
        this.paginator.firstPage();
      }
    }
  });
  }

  dialogHistorico(idHistorial){
    this.store.dispatch(loadHistorialById({idHistorial}));
    const historicoRef = this.dialog.open(DialogHistoricoComponent,{
      disableClose:true,
      width: '940px',
    })
  }

  pageEvent(event:any){
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.store.dispatch(loadHistorial({idSolicitud:this.id,numeroReg:this.pageSize,page:this.page}));
  }

}
