import { Component, ElementRef, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatStepper, MatStepperIntl } from '@angular/material/stepper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { ClientesService } from '../../services/rest/clientes.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { MatStepperModule } from '@angular/material/stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NotasComponent } from './notas/notas.component';
import { empty } from 'rxjs';
import { CcomercialCreateComponent } from '../ccomercial-create/ccomercial-create.component';
import { ClienteSectorService } from '../../services/rest/cliente-sector.service';
import { HistorialnComponent } from '../historialn/historialn.component';
import * as moment from 'moment';
import 'moment-timezone';
import { CompaniesService } from '../../services/rest/companies.service';
import {NotesService} from '../../services/rest/notes.service';
import { OportunidadesService } from '../../services/rest/oportunidades.service';
import { log } from 'util';
import { MatSelectChange } from '@angular/material/select';

/**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Class para la definicón del stepper inicial
  */
export class StepperIntl extends MatStepperIntl {
  // the default optional label text, if unspecified is "Optional"
  optionalLabel = 'Registro de Oportunidades';
}

@Component({
  selector: 'app-creop',
  templateUrl: './creop.component.html',
  styleUrls: ['./creop.component.sass'],
})
export class CreopComponent implements OnInit {
  idcliente: any;
  roles: any;
  company: any = [];
  canal: any = [];
  contactos: any = [];
  servicio: any = [];
  facturacion: any = [];
  moneda: any = [];
  linea: any = [];
  idoportunidad: any;
  userName: any;
  infonota: any;
  condicionCual:boolean = false;
  cualUnidad:boolean = false;

  isEditable = true;
  isLinear = true;
  stfecha = false;
  elementedit: any;

  firstFormGroup = this._formBuilder.group({
    nameOpportunity: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    empresa_asociada: new FormControl('', [Validators.required]),
    canal: new FormControl('', [Validators.required]),
    comercial: new FormControl('', [Validators.required]),
  });
  secondFormGroup = this._formBuilder.group({
    potencial_compra: ['', Validators.required],
    fecha_proceso: [''],
    cont_comercial: ['', Validators.required],
  });
  fiveFormGroup = this._formBuilder.group({
    fecha_negociacion: [''],
    observations: ['', Validators.maxLength(255)],
  });
  fourFormGroup = this._formBuilder.group({
    observations: ['', Validators.maxLength(255)],
  });
  sixtFormGroup = this._formBuilder.group({
    cierre: ['', Validators.required],
  });
  thirddFormGroup = this._formBuilder.group({
    tiposervicio: ['', Validators.required],
    tipofacturacion: ['', Validators.required],
    tipomoneda: ['', Validators.required],
    duracion_contrato: ['', Validators.required],
    facturacion: [{value:'', disabled:false}, [Validators.required, Validators.maxLength(11)]],
    valor_contrato: ['', Validators.maxLength(11)],
    n_agentes: ['', Validators.required],
    fecha_estimada_inicio: ['', Validators.required],
    fecha_entrega_propuesta: ['', Validators.required],
    fecha_adjudicacion: ['', Validators.required],
    margen_mano_obra: ['', Validators.required],
    n_puestos: ['', Validators.required],
    cual: new FormControl('', []),
    cualunidad: new FormControl('', []),
    probabilidadControl: new FormControl('', Validators.required),
    facturacion_diferente_pesos:new FormControl(''),
    tasa_mensual:new FormControl(''),
  });
  formnotica = this._formBuilder.group({ editanotacion: [''] });
  hanotaciones: any = [];
  hlanotaciones: any = [];
  comercialsData: any[] = [];
  rolUser: any = '';
  dataFileArray: any[] = [];
  fileMessage:boolean = false;
  @ViewChild('fileInput')
  myInputVariable: ElementRef;
  title: string = 'Crear oportunidad';
  selectedIndex = 0;
  notes: any;
  anotacion: any;
  typeRequest: any;
  stateOpportunityId: any;
  uploadFiles: any[] = [];
  tabSelected: any = 0;
  table: any = 0;
  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  stepSelected: any = 0;

  constructor(
    private rutaActiva: ActivatedRoute,
    private location: Location,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private clientesService: ClientesService,
    private sectorService: ClienteSectorService,
    private dialog: MatDialog,
    private mattab: MatTabsModule,
    private alertsService: AlertsService,
    private _router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private notesService: NotesService,
    private companyService: CompaniesService,
    private opportunityService: OportunidadesService
  ) {}

  ngOnInit(): void {
    this.idcliente = this.route.snapshot.paramMap.get('id');
    this.roles = this.authService.getUser().roles;
    this.thirddFormGroup.controls['valor_contrato'].disable();
    this.getcompany();
    this.getChannels();
    this.getComercials();
    this.getContacts();
    this.listaNotas();

    const user = this.authService.decryptToken();
    const local = this.authService.getUser();
    local.roles.forEach((rol) => {
      const name = rol.split('::');
      if (name[0] == 'crm-cial' && name[1] == 'comercial') {
        this.rolUser = name[1];
        this.firstFormGroup.controls.comercial.setValue(user.rrhh_id);
        setTimeout(() => {
          let index = this.comercialsData.findIndex((item) => item.rrhh_id == user.rrhh_id);
          if(index >= 0){this.userName = this.comercialsData[index]['name'];}
        },3000)}
      if (name[0] == 'crm-cial' && name[1] == 'administrador') {
        this.rolUser = name[1];
      }
    });

    this.route.queryParams.subscribe(params => {
      this.typeRequest = params.type ? params.type : '';
      this.idoportunidad = params.idOportunity ? params.idOportunity : '';
      this.tabSelected = params.tab ? params.tab : 0;
      this.table = params.table ? params.table : 0;
      //Editar
      this.getLineServices();
      this.getUnitFacturation();
      if(this.typeRequest == 'edit'){
        this.loadDataOportunityEdit(params.idOportunity);
      } else if(this.typeRequest == 'view'){
        this.loadDataOportunityView(params.idOportunity);
      }
    });
    this.secondFormGroup.controls.potencial_compra.valueChanges.subscribe(
      (resp) => {
        if (resp == 1) {
          this.secondFormGroup.controls.fecha_proceso.clearValidators();
          this.secondFormGroup.controls.fecha_proceso.updateValueAndValidity();
        } else {
          this.secondFormGroup.controls.fecha_proceso.setValidators(
            Validators.required
          );
          this.secondFormGroup.controls.fecha_proceso.updateValueAndValidity();
        }
      }
    );
    this.thirddFormGroup.controls['valor_contrato'].disable();
      this.sixtFormGroup.controls.cierre.valueChanges.subscribe(resp => {
        if(resp == 'ganada' && this.dataFileArray.length == 0){
          this.fileMessage = true;
        }else if(resp == 'ganada' && this.dataFileArray.length > 0){
          this.fileMessage = false;
        }else{
          this.fileMessage = false;

        }
      });

      this.firstFormGroup.controls.comercial.valueChanges.subscribe(resp => {
        let index = this.comercialsData.findIndex((item) => item.rrhh_id == resp);
        if(index >= 0){this.userName = this.comercialsData[index]['name'];}

      });
  }

  /**
   * @author Javier Castañeda
   * Funcion para regresar a la vista anterior
   * @createdate 2022-10-29
   * @returns Json
   */
  back(): void {
    this.alertsService
      .alertWarning(
        '¿Estás seguro?',
        '¿Estás seguro de que <b>deseas cancelar</b> este proceso? Recuerda que la información se borrará automáticamente si realizas esta acción.'
      )
      .then((result) => {
        if (result.isConfirmed) {
          if(this.table == 1){
            this._router.navigate([`/mios/crm-cial/clientes`], { queryParams: { tab: this.tabSelected }});
          } else {
            this._router.navigate([`/mios/crm-cial/cliente-consulta/${this.idcliente}`], { queryParams: { tab: this.tabSelected }});
          }
        }
      });
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para pasar al siguiente paso del stepper
  * @param {MatStepper} stepper paso actual
  */
  next(stepper: MatStepper) {
    stepper.next();
  }

  /**
   * @author Javier Castañeda
   * Funcion para obtener listado de empresas
   * @returns Json
   *
   */
  getcompany(): void {
    this.companyService.getCompanies().subscribe((resp: any) => {
      this.company = resp.data;
    });
  }
  /**
   * @author Javier Castañeda
   * Funcion para obtener listado de canales
   * @returns Json
   *
   */
  getChannels(): void {
    this.clientesService.getChannels().subscribe((resp: any) => {
      this.canal = resp.data;
    });
  }
  /**
   * @author Javier Castañeda
   * Funcion para obtener listado de gestores comerciales
   * @returns Json
   *
   */
  getComercials() {
    this.clientesService.getComercialsList().subscribe((resp: any) => {
      this.comercialsData = resp.data;
    });
  }
  /**
   * @author Javier Castañeda
   * Funcion para obtener listado de contactos por el id del cliente
   * @returns Json
   *
   */
  getContacts() {
    this.clientesService
      .getContactsByClient(this.idcliente)
      .subscribe((resp: any) => {
        this.contactos = resp.data;
      });
  }
  /**
   * @author Javier Castañeda
   * Funcion para obtener listado de unidades de facturación
   * @returns Json
   *
   */
  getUnitFacturation() {
    if(this.typeRequest == 'edit' || this.typeRequest == 'view'){
      this.clientesService.getUnitFacturation(this.idoportunidad).subscribe((resp: any) => {
        this.facturacion = resp.data;
      });
    }else{
      this.clientesService.getUnitFacturation().subscribe((resp: any) => {
        this.facturacion = resp.data;
      });
    }
  }
  /**
   * @author Javier Castañeda
   * Funcion para obtener listado de servicios
   * @returns Json
   *
   */
  getLineServices() {
    if(this.typeRequest == 'edit' || this.typeRequest == 'view'){
      this.clientesService.getLineServices(this.idoportunidad).subscribe((resp: any) => {
        this.linea = resp.data;
      });
    }else{
      this.clientesService.getLineServices().subscribe((resp: any) => {
        this.linea = resp.data;
      });
    }
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para listar las notas de una oportunidad
  */
  listaNotas() {
    this.notesService.search(this.idoportunidad).subscribe((resp) => {
      this.notes = resp;
    });
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la apertura de modal de historico de notas
  */
  HistoricoNotas() {
    const dialogNewRol = this.dialog.open(HistorialnComponent, {
      width: '77%',
      disableClose: true,
      data: {
        type: 'view',
        title: 'Editar Nota',
        rolUser: this.rolUser,
        oportunity: this.idoportunidad,
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {});
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para mostrar y ocultar campo de fecha de acuerdo a la opcion de potencial de compra
  */
  ViewData() {
    let pcompra: string = this.secondFormGroup.get('potencial_compra').value;
    if (pcompra == '0') {
      return (this.stfecha = true);
    } else {
      return (this.stfecha = false);
    }
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la calculo de valor del contrato
  */
  calcContrato() {
    let tipoMoneda = this.thirddFormGroup.get('tipomoneda').value;
    if(tipoMoneda !== null && tipoMoneda !== '' && tipoMoneda !== '0'){
      this.thirddFormGroup.get('facturacion').enable();
    }
    this.thirddFormGroup
      .get('valor_contrato')
      .setValue(
        this.thirddFormGroup.value.facturacion *
          this.thirddFormGroup.value.duracion_contrato
      );
    if(tipoMoneda !== null && tipoMoneda !== '' && tipoMoneda !== '0'){
      this.thirddFormGroup.get('facturacion').disable();
    }
  }

  /**
   * Metodo que se encarga de calcular el valor para la facturacion COP
   * @author Juan David Guerrero Vargas
   * @createdate 14/02/2023
   * @return {void} void
   */
  calculateFacturacionExtranjera():void{
    this.thirddFormGroup.get('facturacion').setValue(
      this.thirddFormGroup.value.tasa_mensual *
          this.thirddFormGroup.value.facturacion_diferente_pesos
    );
    this.calcContrato();
  }
  /**
   * @author Javier Castañeda
   * Funcion para guardado del paso de acercamiento comercial
   * @returns Json
   *
   */
  registrarAcercamiento(next) {
    let formData = new FormData();
    formData.append('step', 'acercamiento_comercial');
    formData.append('step_id', '2');
    formData.append(
      'potencialCompra',
      this.secondFormGroup.value.potencial_compra
    );
    formData.append(
      'business_contact_id',
      this.secondFormGroup.value.cont_comercial
    );
    formData.append('next', next);
    if (this.secondFormGroup.value.potencial_compra == '0') {
      formData.append(
        'process_date',
        moment(moment(this.secondFormGroup.value.fecha_proceso).add(1, 'days'))
          .tz('America/Bogota')
          .format('YYYY-MM-DD')
      );
    }
    this.clientesService
      .updateOpportunities(this.idoportunidad, formData)
      .subscribe((resp: any) => {
        this.alertsService.alertSuccessWithoutButton('Excelente', resp.data);
      });
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la validación de carateres digitados por el usuario
  */
  keyPress(event: any) {
    const pattern = /[0-9\+\-\,1 ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  /**
   * @author Gabriel Garcia
   * Metodo para la validación de campo decimal
   */
   keyDecimalPress(event: any) {
    const pattern = /^[0-9+\-. ]*\.{0,1}[0-9+\-. ]*$/;

    let inputChar = String.fromCharCode(event.keyCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la apertura de modal de creación de la nota de acuerdo al paso en el que se encuentra el usuario
  */
  RegistranotaP(proceso: any): void {
    if (this.idoportunidad > 0) {
      const dialogNewRol = this.dialog.open(NotasComponent, {
        width: '60%',
        disableClose: true,
        data: {
          type: 'crear',
          title: 'Crear Nota',
          oportunidad: this.idoportunidad,
          step: proceso,
        },
        panelClass: 'custom-dialog-container',
      });

      dialogNewRol.afterClosed().subscribe(() => {
        //this.ngOnInit();
        this.listaNotas();
      });
    } else {
      this.alertsService.dangerError(
        'Es necesario registrar primero la Propuesta'
      );
    }
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la captura de errores del tercer formulario
  */
  get errort(): any {
    return this.thirddFormGroup.controls;
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la captura de errores del segundo formulario
  */
  get errorSecond(): any {
    return this.secondFormGroup.controls;
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la captura de errores del quinto formulario
  */
  get errorFive(): any {
    return this.fiveFormGroup.controls;
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la captura de errores del sexto formulario
  */
  get errorSix(): any {
    return this.sixtFormGroup.controls;
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la apertura de modal de creación de la nota de acuerdo al paso en el que se encuentra el usuario
  */
  RegistranotaC(): void {
    const dialogNewRol = this.dialog.open(NotasComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Crear Nota',
        id_oportunidad: this.idoportunidad,
        estado_anotacion: 3,
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      //this.ngOnInit();
      this.listaNotas();
    });
  }
  /**
   * @author Javier Castañeda
   * @createdate 2022-10-29
   * Funcion para cancelar proceso
   */
  opcionCancelar() {
    this.alertsService
      .alertWarning(
        '¿Estás seguro?',
        '¿Estás seguro de que <b>deseas cancelar</b> este proceso? Recuerda que la información se borrará automáticamente si realizas esta acción.'
      )
      .then((result) => {
        if (result.isConfirmed) {
          if(this.table == 1){
            this._router.navigate([`/mios/crm-cial/clientes`], { queryParams: { tab: this.tabSelected }});
          } else {
            this._router.navigate([`/mios/crm-cial/cliente-consulta/${this.idcliente}`], { queryParams: { tab: this.tabSelected }});
          }
        }
      });
  }
  /**
   * @author Javier Castañeda
   * @createdate 2022-10-29
   * Funcion para guardado del paso de prospecto
   * @param {any} next estado de guardar paso o siguiente
   * @returns Json
   */
  saveOportunidad(next?) {
    if (this.idoportunidad) {
      let id = +this.idcliente;
      let formData = new FormData();
      formData.append('step', 'prospecto');
      formData.append('step_id', '1');
      formData.append(
        'name_oportunity',
        this.firstFormGroup.value.nameOpportunity
      );
      formData.append(
        'companion_id',
        this.firstFormGroup.value.empresa_asociada
      );
      formData.append('channel_id', this.firstFormGroup.value.canal);
      formData.append('client_id', this.idcliente);
      formData.append('next', next);
      if(this.rolUser == 'administrador'){
        formData.append('responsable_id', this.firstFormGroup.value.comercial);
      } else if(this.rolUser == 'comercial'){
        formData.append('responsable_id', this.firstFormGroup.value.comercial);
      }
      this.clientesService
        .updateOpportunities(this.idoportunidad, formData)
        .subscribe((resp: any) => {
          this.alertsService.alertSuccessWithoutButton('Excelente', resp.data);
        });
    } else {
      const body = {
        name_oportunity: this.firstFormGroup.value.nameOpportunity,
        companion_id: this.firstFormGroup.value.empresa_asociada,
        channel_id: this.firstFormGroup.value.canal,
        client_id: this.idcliente,
        responsable_id: this.firstFormGroup.value.comercial
      };
      this.clientesService.saveOportunities(body).subscribe(
        (resp) => {
          this.idoportunidad = resp.id;
          this.alertsService.alertSuccessWithoutButton('Excelente', resp.data);
        },
        (error: any) => {
          this.alertsService.alertError('Error', error);
        }
      );
    }
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la captura de errores del primer formulario
  */
  get error(): any {
    return this.firstFormGroup.controls;
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-10-29
  * Metodo para la apertura de modal de creación de la nota de acuerdo al paso en el que se encuentra el usuario
  */
  searchContacto() {
    this.clientesService
      .searchContacto(this.idcliente)
      .subscribe((resp: any) => {
        this.contactos = resp;
      });
  }

  /**
   * @author Javier Castañeda
   * @createdate 2022-10-29
   * Metodo para abrir modal de creación de contactos
   */
  AgregarContacto() {
    const dialogNewRol = this.dialog.open(CcomercialCreateComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        Nidcliente: this.idcliente,
      },
      panelClass: 'custom-dialog-container',
    });
    dialogNewRol.afterClosed().subscribe(() => {
      this.getContacts();
    });
  }

  /**
   * @author Javier Castañeda
   * @createdate  2022-10-29
   * Funcion para guardado del paso de negociación
   * @param {any} next estado de guardar paso o siguiente
   * @returns Json
   *
   */
  saveThirddForm(next) {
    let formData = new FormData();
    let lines = [];
    lines = this.thirddFormGroup.value.tiposervicio;
    formData.append('step', 'construccion_propuesta');
    formData.append('step_id', '3');
    lines.forEach((element, index) => {
      formData.append('service_lines[]', element);
    });
    formData.append(
      'invoicing_unit',
      '[' + this.thirddFormGroup.value.tipofacturacion + ']'
    );
    formData.append('currency', this.thirddFormGroup.value.tipomoneda);
    if(this.thirddFormGroup.value.tipomoneda !== '0'){
      formData.append('facturacion_diferente_pesos',this.thirddFormGroup.value.facturacion_diferente_pesos)
      formData.append('tasa_mensual',this.thirddFormGroup.value.tasa_mensual)
    }
    formData.append(
      'contract_duration',
      this.thirddFormGroup.value.duracion_contrato
    );
    formData.append('invoicing', this.thirddFormGroup.controls.facturacion.value);
    formData.append('agents', this.thirddFormGroup.value.n_agentes);
    formData.append(
      'initial_date',
      moment(
        moment(this.thirddFormGroup.value.fecha_estimada_inicio).add(1, 'days')
      )
        .tz('America/Bogota')
        .format('YYYY-MM-DD')
    );
    formData.append(
      'deliver_date',
      moment(
        moment(this.thirddFormGroup.value.fecha_entrega_propuesta).add(
          1,
          'days'
        )
      )
        .tz('America/Bogota')
        .format('YYYY-MM-DD')
    );
    formData.append(
      'award_date',
      moment(
        moment(this.thirddFormGroup.value.fecha_adjudicacion).add(1, 'days')
      )
        .tz('America/Bogota')
        .format('YYYY-MM-DD')
    );
    formData.append('workforce', this.thirddFormGroup.value.margen_mano_obra);
    formData.append('positions', this.thirddFormGroup.value.n_puestos);
    formData.append('next', next);
    formData.append('cual', this.thirddFormGroup.value.cual);
    formData.append('cualunidad', this.thirddFormGroup.value.cualunidad);
    formData.append('probability', this.thirddFormGroup.value.probabilidadControl);

    let textInfo = this.firstFormGroup.controls.nameOpportunity.value ? this.firstFormGroup.controls.nameOpportunity.value : '';
    this.alertsService.alertWarning(
      '¿Estás seguro?',
      '¿Estás seguro de que deseas enviar la oportunidad <b>'+ textInfo +'</b>?'
    ).then((result) => {
      if (result.isConfirmed) {
        this.clientesService
          .updateOpportunities(this.idoportunidad, formData)
          .subscribe((resp: any) => {
            this.selectedIndex = 3;
            this.alertsService.alertSuccessWithoutButton('Excelente', resp.data);
          });
      } else {
        this.selectedIndex = 2;
      }
    });
  }

    /**
   * @author Javier Castañeda
   * @createdate  2022-10-29
   * Funcion para guardado del paso de negociación
   * @param {any} next estado de guardar paso o siguiente
   * @returns Json
   *
   */
  saveFourForm(next) {
    let formData = new FormData();
    formData.append('step', 'enviada');
    formData.append('step_id', '4');
    formData.append('observations', this.fourFormGroup.value.observations);
    formData.append('next', next);
    this.clientesService
      .updateOpportunities(this.idoportunidad, formData)
      .subscribe((resp: any) => {
        this.alertsService.alertSuccessWithoutButton('Excelente', resp.data);
      });
  }

    /**
   * @author Javier Castañeda
   * @createdate  2022-10-29
   * Funcion para guardado del paso de negociación
   * @param {any} next estado de guardar paso o siguiente
   * @returns Json
   *
   */
  saveFiveForm(next) {
    let formData = new FormData();
    let date = this.fiveFormGroup.value.fecha_negociacion
      ? moment(
          moment(this.fiveFormGroup.value.fecha_negociacion).add(1, 'days')
        )
          .tz('America/Bogota')
          .format('YYYY-MM-DD')
      : '';
    formData.append('step', 'negociacion');
    formData.append('step_id', '5');
    formData.append('negociation_date', date);
    formData.append('observations', this.fiveFormGroup.value.observations);
    formData.append('next', next);
    this.clientesService
      .updateOpportunities(this.idoportunidad, formData)
      .subscribe((resp: any) => {
        this.alertsService.alertSuccessWithoutButton('Excelente', resp.data);
      });
  }

  /**
   * @author Javier Castañeda
   * @createdate  2022-10-29
   * Funcion para guardado del paso de negociación
   * @param {any} next estado de guardar paso o siguiente
   * @returns Json
   *
   */
  saveSixtForm(next?) {
    let formData = new FormData();
    formData.append('step', 'cierre');
    formData.append('step_id', '6');
    formData.append('state', this.sixtFormGroup.value.cierre);
    formData.append('next', next);
    if(this.dataFileArray.length > 0){
      this.dataFileArray.forEach(element=>{
        formData.append('files[]', element);
      })
    }
    let textInfo = this.firstFormGroup.controls.nameOpportunity.value ? this.firstFormGroup.controls.nameOpportunity.value : '';
    let state = '';
    let tab = 0;
    if(this.sixtFormGroup.value.cierre == 'ganada'){
      state = 'Ganado';
      tab = 1;
    } else if(this.sixtFormGroup.value.cierre == 'perdida'){
      state = 'Perdido';
      tab = 2;
    } else if(this.sixtFormGroup.value.cierre == 'desierta'){
      state = 'Desierto';
      tab = 3;
    }

    this.alertsService.alertWarning(
      '¿Estás seguro?',
      '¿Estás seguro de que deseas finalizar la oportunidad <b>'+ textInfo +'</b> en cierre de <br> proceso: <b>'+ state +'</b>?'
    ).then((result) => {
      if (result.isConfirmed) {
        this.clientesService.updateOpportunities(this.idoportunidad, formData)
          .subscribe((resp: any) => {
            this.alertsService.alertSuccessWithoutButton('Excelente', resp.data);
            this._router.navigate([`/mios/crm-cial/cliente-consulta/${this.idcliente}`], { queryParams: { tab: tab }});
          });
      }
    });
  }

   /**
   * @author Nicoll Ramirez
   * @createdate 2022-10-29
   * Funcion para el cargue de archivos
   */
  public onChangeFile(e): void {
    const files = e.target.files;
    const type = [
      'application/pdf',
      '.csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ];
    for (let i = 0; i < e.target.files.length; i++) {
      if (type.indexOf(e.target.files[i].type) !== -1) {
        this.dataFileArray.push(e.target.files.item(i));
      }
    }
    this.myInputVariable.nativeElement.value = "";

    if(this.dataFileArray.length == 0){
      this.fileMessage = true;
    }else{
      this.fileMessage = false;
    }
  }

  /**
   * @author Nicoll Ramirez
   * @createdate 2022-10-29
   * Método para eliminar un archivo cargado
   * @param {number} indice index de la posición en el arreglo
   */
  deleteFieldFile(indice) {
    this.dataFileArray.splice(indice, 1);
    if(this.dataFileArray.length == 0){
      this.fileMessage = true;
    }else{
      this.fileMessage = false;

    }
  }

  /**
   *@author Javier Castañeda
   *@createdate 2022-10-27
   * Método para capturar el step actual y detectar el cambio del titulo
   * @param {any} event evento del cambio al crear la oportunidad
   */
  selectionChange(event) {
    if(event.selectedIndex == 0) {
      this.title = this.idoportunidad ? this.firstFormGroup.controls.nameOpportunity.value : 'Crear oportunidad';
    } else {
      this.title = this.firstFormGroup.controls.nameOpportunity.value ? this.firstFormGroup.controls.nameOpportunity.value : 'Crear oportunidad';
    }
  }

  /**
   * @author Nicoll Ramirez
   * @createdate 2022-10-29
   * campo para mostrar input cual en caso de ser seleccionado
   * otro en el tipo servicio
   * @param event
   */
  changeServicio(event){
    if(event.value.includes(9)){
      this.condicionCual = true;
    }else{
      this.condicionCual = false;
    }
  }

  /**
   * @author Nicoll Ramirez
   * @createdate 2022-10-29
   * campo para mostrar input cual en caso de ser seleccionado
   * otro en el tipo servicio
   * @param event
   */
  changeUnitFact(event){
    if(event.value.includes(8)){
      this.cualUnidad = true;
    }else{
      this.cualUnidad = false;
    }
  }

  /**
   *@author Javier Castañeda
   *@created 2022-11-02
   * Método para cargar la data para la gestión
   * @param {number} id id de la oportunidad
   */
  loadDataOportunityEdit(id) {
    this.opportunityService.getOportunitiesById(id).subscribe(resp => {
      this.stateOpportunityId = resp?.data?.state_id ? resp?.data?.state_id : '';
      this.title = resp?.data?.name_oportunity;
      this.uploadFiles = resp?.data?.files;

      this.listaNotas();
      //First form
      this.firstFormGroup.controls.nameOpportunity.setValue(resp?.data?.name_oportunity ? resp?.data?.name_oportunity : '');
      this.firstFormGroup.controls.empresa_asociada.setValue(resp?.data?.companion_id ? resp?.data?.companion_id : '');
      this.firstFormGroup.controls.canal.setValue(resp?.data?.channel_id ? resp?.data?.channel_id : '');
      this.firstFormGroup.controls.comercial.setValue(resp?.data?.responsible_id ? resp?.data?.responsible_id : '');
      //Second Form
      if(String(resp?.data?.purchase_potential) === '0'){
        this.secondFormGroup.controls.potencial_compra.setValue('0');
      } else if(String(resp?.data?.purchase_potential) === '1') {
        this.secondFormGroup.controls.potencial_compra.setValue('1');
      } else {
        this.secondFormGroup.controls.potencial_compra.setValue('');
      }
      this.secondFormGroup.controls.fecha_proceso.setValue(resp?.data?.process_date ? resp?.data?.process_date : '');
      this.secondFormGroup.controls.cont_comercial.setValue(resp?.data?.business_contact_id ? resp?.data?.business_contact_id : '');
      //Third Form
      let serviceLinesData = resp?.data?.lineas ? resp?.data?.lineas : [];
      let dataLines = [];
      let units = JSON.parse(resp.data.invoicing_unit);
      let dataUnits = [];
      serviceLinesData.map(item => {
        if(item?.service_line_id != 9){
          dataLines.push(item?.service_line_id);
        }
      });
      if(units) {
        units.map(item => {
          if(item != 8){
            dataUnits.push(item);
          }
        });
      }
      this.thirddFormGroup.controls.tiposervicio.setValue(dataLines.length > 0 ? dataLines : '');
      this.thirddFormGroup.controls.tipofacturacion.setValue(resp?.data?.invoicing_unit ? dataUnits : '');
      this.thirddFormGroup.controls.tipomoneda.setValue(String(resp?.data?.currency));

      this.setValidatorsForMonthlyRate();
      if(resp?.data?.tasa_cambio) {
        this.thirddFormGroup.controls.tasa_mensual.setValue(resp?.data?.tasa_cambio);
      }
      if(resp?.data?.facturacion_diferente_pesos) {
        this.thirddFormGroup.controls.facturacion_diferente_pesos.setValue(resp?.data?.facturacion_diferente_pesos);
      }
      this.thirddFormGroup.controls.duracion_contrato.setValue(resp?.data?.contract_duration ? resp?.data?.contract_duration : '');
      this.thirddFormGroup.controls.facturacion.setValue(resp?.data?.invoicing ? resp?.data?.invoicing : '');
      this.thirddFormGroup.controls.valor_contrato.setValue(resp?.data?.contract_value ? resp?.data?.contract_value : '');
      this.thirddFormGroup.controls.n_agentes.setValue(resp?.data?.agents ? resp?.data?.agents : '');
     this.thirddFormGroup.controls.fecha_estimada_inicio.setValue(resp?.data?.initial_date
       ? moment(
         moment(resp?.data?.initial_date).add(1, 'days')
       )
         .tz('America/Bogota')
         .format('YYYY-MM-DD')
       : '');
     this.thirddFormGroup.controls.fecha_entrega_propuesta.setValue(resp?.data?.deliver_date
       ? moment(
         moment(resp?.data?.deliver_date).add(1, 'days')
       )
         .tz('America/Bogota')
         .format('YYYY-MM-DD')
       : '');
     this.thirddFormGroup.controls.fecha_adjudicacion.setValue(resp?.data?.award_date
       ? moment(
         moment(resp?.data?.award_date).add(1, 'days')
       )
         .tz('America/Bogota')
         .format('YYYY-MM-DD')
       : '');
      this.thirddFormGroup.controls.margen_mano_obra.setValue(resp?.data?.workforce ? resp?.data?.workforce : '');
      this.thirddFormGroup.controls.n_puestos.setValue(resp?.data?.positions ? resp?.data?.positions : '');
      this.thirddFormGroup.controls.probabilidadControl.setValue(resp?.data?.probability ? resp?.data?.probability : '');
      //Four form
      this.fourFormGroup.controls.observations.setValue(resp?.data?.observationEnviada ? resp?.data?.observationEnviada : '');
      //Five form
      this.fiveFormGroup.controls.fecha_negociacion.setValue(resp?.data?.negociation_date ? resp?.data?.negociation_date : '');
      this.fiveFormGroup.controls.observations.setValue(resp?.data?.observationNegociacion ? resp?.data?.observationNegociacion : '');
      //Six form
      let state = '';
      if(resp?.data?.state_id == 1){
        state = 'ganada';
      } else if(resp?.data?.state_id == 2){
        state = 'perdida';
      } else if(resp?.data?.state_id == 3){
        state = 'desierta';
      }
      this.sixtFormGroup.controls.cierre.setValue(state);
      this.stepSelected = resp?.data?.step_id <= 6 ? resp?.data?.step_id - 1 : 5;
      this.stepper.linear = false;
      for (let index = 0; index <= this.stepSelected; index++) {
        this.stepper.selectedIndex = index;
      }

      setTimeout(()=> {
        this.stepper.linear = true;
      });
    });
  }
  /**
   *@author Javier Castañeda
   *@created 2022-11-02
   * Método para cargar la data para la vista de detalle
   * @param {number} id id de la oportunidad
   */
  loadDataOportunityView(id) {
    this.firstFormGroup.disable();
    this.secondFormGroup.disable();
    this.thirddFormGroup.disable();
    this.fourFormGroup.disable();
    this.fiveFormGroup.disable();
    this.sixtFormGroup.disable();
    this.listaNotas();
    this.opportunityService.getOportunitiesById(id).subscribe(resp => {
      this.stateOpportunityId = resp?.data?.state_id ? resp?.data?.state_id : '';
      this.title = resp?.data?.name_oportunity;
      this.uploadFiles = resp?.data?.files;

      //First form
      this.firstFormGroup.controls.nameOpportunity.setValue(resp?.data?.name_oportunity ? resp?.data?.name_oportunity : '');
      this.firstFormGroup.controls.empresa_asociada.setValue(resp?.data?.companion_id ? resp?.data?.companion_id : '');
      this.firstFormGroup.controls.canal.setValue(resp?.data?.channel_id ? resp?.data?.channel_id : '');
      this.firstFormGroup.controls.comercial.setValue(resp?.data?.responsible_id ? resp?.data?.responsible_id : '');
      //Second Form
      if(String(resp?.data?.purchase_potential) === '0'){
        this.secondFormGroup.controls.potencial_compra.setValue('0');
      } else if(String(resp?.data?.purchase_potential) === '1') {
        this.secondFormGroup.controls.potencial_compra.setValue('1');
      } else {
        this.secondFormGroup.controls.potencial_compra.setValue('');
      }
      this.secondFormGroup.controls.fecha_proceso.setValue(resp?.data?.process_date ? resp?.data?.process_date : '');
      this.secondFormGroup.controls.cont_comercial.setValue(resp?.data?.business_contact_id ? resp?.data?.business_contact_id : '');
      //Third Form
      let serviceLinesData = resp?.data?.lineas ? resp?.data?.lineas : [];
      let dataLines = [];
      let units = JSON.parse(resp.data.invoicing_unit);
      let dataUnits = [];
      serviceLinesData.map(item => {
        if(item?.service_line_id != 9){
          dataLines.push(item?.service_line_id);
        }
      });
      if(units) {
        units.map(item => {
          if(item != 8){
            dataUnits.push(item);
          }
        });
      }
      this.thirddFormGroup.controls.tiposervicio.setValue(dataLines.length > 0 ? dataLines : '');
      this.thirddFormGroup.controls.tipofacturacion.setValue(resp?.data?.invoicing_unit ? units : '');
      this.thirddFormGroup.controls.tipomoneda.setValue(String(resp?.data?.currency));

      this.setValidatorsForMonthlyRate();
      if(resp?.data?.tasa_cambio) {
        this.thirddFormGroup.controls.tasa_mensual.setValue(resp?.data?.tasa_cambio);
      }
      if(resp?.data?.facturacion_diferente_pesos) {
        this.thirddFormGroup.controls.facturacion_diferente_pesos.setValue(resp?.data?.facturacion_diferente_pesos);
      }
      this.thirddFormGroup.controls.duracion_contrato.setValue(resp?.data?.contract_duration ? resp?.data?.contract_duration : '');
      this.thirddFormGroup.controls.facturacion.setValue(resp?.data?.invoicing ? resp?.data?.invoicing : '');
      this.thirddFormGroup.controls.valor_contrato.setValue(resp?.data?.contract_value ? resp?.data?.contract_value : '');
      this.thirddFormGroup.controls.n_agentes.setValue(resp?.data?.agents ? resp?.data?.agents : '');
      this.thirddFormGroup.controls.fecha_estimada_inicio.setValue(resp?.data?.initial_date
        ? moment(
          moment(resp?.data?.initial_date).add(1, 'days')
        )
          .tz('America/Bogota')
          .format('YYYY-MM-DD')
        : '');
      this.thirddFormGroup.controls.fecha_entrega_propuesta.setValue(resp?.data?.deliver_date
        ? moment(
          moment(resp?.data?.deliver_date).add(1, 'days')
        )
          .tz('America/Bogota')
          .format('YYYY-MM-DD')
        : '');
      this.thirddFormGroup.controls.fecha_adjudicacion.setValue(resp?.data?.award_date
        ? moment(
          moment(resp?.data?.award_date).add(1, 'days')
        )
          .tz('America/Bogota')
          .format('YYYY-MM-DD')
        : '');
      this.thirddFormGroup.controls.margen_mano_obra.setValue(resp?.data?.workforce ? resp?.data?.workforce : '');
      this.thirddFormGroup.controls.n_puestos.setValue(resp?.data?.positions ? resp?.data?.positions : '');
      this.thirddFormGroup.controls.probabilidadControl.setValue(resp?.data?.probability ? resp?.data?.probability : '');
      //Four form
      this.fourFormGroup.controls.observations.setValue(resp?.data?.observationEnviada ? resp?.data?.observationEnviada : '');
      //Five form
      this.fiveFormGroup.controls.fecha_negociacion.setValue(resp?.data?.negociation_date ? resp?.data?.negociation_date : '');
      this.fiveFormGroup.controls.observations.setValue(resp?.data?.observationNegociacion ? resp?.data?.observationNegociacion : '');
      //Six form
      let state = '';
      if(resp?.data?.state_id == 1){
        state = 'ganada';
      } else if(resp?.data?.state_id == 2){
        state = 'perdida';
      } else if(resp?.data?.state_id == 3){
        state = 'desierta';
      }
      this.sixtFormGroup.controls.cierre.setValue(state);
      this.stepSelected = resp?.data?.step_id <= 6 ? resp?.data?.step_id - 1 : 5;
      this.stepper.linear = false;
      for (let index = 0; index <= this.stepSelected; index++) {
        this.stepper.selectedIndex = index;
      }

      setTimeout(()=> {
        this.stepper.linear = true;
      });

    });
  }

  /**
   * @author Javier Castañeda
   * @createdate 2022-10-29
   * Funcion para regresar a la vista anterior
   * @returns Json
   *
   */
  viewBack(): void {
    if(this.table == 1){
      this._router.navigate([`/mios/crm-cial/clientes`], { queryParams: { tab: this.tabSelected }});
    } else {
      this._router.navigate([`/mios/crm-cial/cliente-consulta/${this.idcliente}`], { queryParams: { tab: this.tabSelected }});
    }
  }

  /**
   * @author Javier Castañeda
   * @createdate 2022-10-29
   * Metodo para eliminar un archivo ya subidoen la creación
   * @param {number} indice index del archivo a borrar
   * @param {number} id id del archivo a borrar
   * @returns Json
   *
   */
  deleteUploadFile(id, indice): void {
    this.uploadFiles.splice(indice, 1);
    this.opportunityService.deleteFileById(id).subscribe(resp => {});
  }

  /**
   * @author Javier Castañeda
   * @createddate 2022-10-29
   * Metodo para eliminar un archivo ya subidoen la creación
   * @returns Json
   *
   */
  deleteOpportunityById(): void {
    this.alertsService
    .alertWarning(
      '¿Estás seguro?',
      '¿Estás seguro de que <b>deseas cancelar</b> este proceso? Recuerda que la información se borrará automáticamente si realizas esta acción.'
    )
    .then((result) => {
      if(this.idoportunidad){
        if(this.table == 1){
          this._router.navigate([`/mios/crm-cial/clientes`], { queryParams: { tab: this.tabSelected }});
        } else {
          this._router.navigate([`/mios/crm-cial/cliente-consulta/${this.idcliente}`], { queryParams: { tab: this.tabSelected }});
        }
      }
    });
  }

  /**
   * Metood que se encarga de asignar nuevas validaicon o retirarlas para los campos de tasa mensual y facturacion emnsual en caso de no se modea colombiana
   * @author Juan David Guerrero Vargas
   * @createdate 14/0272023
   * @return {void}  void
   */
  setValidatorsForMonthlyRate():void{
    let valueValidate:string = this.thirddFormGroup.get('tipomoneda').value;
    if(valueValidate !== '0'){
      this.thirddFormGroup.get('facturacion_diferente_pesos').setValue(null);
      this.thirddFormGroup.get('facturacion_diferente_pesos').setValidators(Validators.required);
      this.thirddFormGroup.get('tasa_mensual').setValue(null);
      this.thirddFormGroup.get('tasa_mensual').setValidators(Validators.required);
      this.thirddFormGroup.get('facturacion_diferente_pesos').updateValueAndValidity();
      this.thirddFormGroup.get('tasa_mensual').updateValueAndValidity();
      this.thirddFormGroup.get('facturacion').setValue(null);
      this.thirddFormGroup.get('facturacion').disable();

    }else{
      this.thirddFormGroup.get('facturacion_diferente_pesos').setValidators(null);
      this.thirddFormGroup.get('tasa_mensual').setValidators(null);
      this.thirddFormGroup.get('facturacion_diferente_pesos').updateValueAndValidity();
      this.thirddFormGroup.get('tasa_mensual').updateValueAndValidity();
      this.thirddFormGroup.get('facturacion').setValue(null);
      this.thirddFormGroup.get('facturacion').enable();
    }
  }

}
