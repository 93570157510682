<div class="row col-12 filter mx-0" style="padding-top: 40px;">

    <mat-form-field class="col-3" appearance="fill">
        <mat-label>Orden de compra</mat-label>
        <input matInput
          type="number"
          autocomplete    = "off"
          (keydown.enter) = "filterByPurchaseOrderId($event)"
          (blur)          = "filterByPurchaseOrderId($event)" #nit
          [(ngModel)]     = "filterPurchaseOrderId"
          >
        <span matPrefix>OC-&nbsp;</span>
        <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
    </mat-form-field>

    <mat-form-field class="col-3" appearance="fill">
        <mat-label>Estado</mat-label>
        <mat-select (selectionChange)="filterByState($event.value)" multiple>
            <mat-option *ngFor="let state of ordersStates" [value]="state.id">{{state.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="col-3" appearance="fill">
      <mat-label>NIT</mat-label>
      <input matInput type="number" (blur)="filterByNit($event)" autocomplete = "off">
    </mat-form-field>

    <mat-form-field class="col-3" appearance="fill">
      <mat-label>Razón social</mat-label>
      <input matInput type="text" (blur)="filterbyRazonsocial($event)" autocomplete = "off">
    </mat-form-field>

</div>

<div class="row filter d-flex mx-0 justify-content-end">
  <!-- <mat-form-field class="col-6" appearance="fill">
    <mat-label>Proveedor</mat-label>
    <input
        [formControl]="textProveedor"
        type="text"
        aria-label="Number"
        matInput
        [matAutocomplete]="auto">
    <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="filterProveedores($event)">
        <mat-option *ngFor="let option of filterListProveedores | async" [value]="option.razon_social">
            {{ option.razon_social }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field> -->
  <div class="col-3 buttons" align="right">
    <button class="bold w-100" mat-raised-button color="primary" (click)="downloadManagement()">Descargar gestión &nbsp;<i class="fi-rr-download"></i></button>
  </div>
</div>

<div class="table-container row mt-3">

        <table mat-table [dataSource]="dataSource" multiTemplateDataRows >
            <ng-container matColumnDef="Acciones">
                <th class="start" mat-header-cell *matHeaderCellDef></th>
                <td style="width: 12.5%;" mat-cell *matCellDef="let element" align="right" class="p-0 pe-4">
                    <div class="action-icons">
                      <button mat-icon-button (click)="viewOrder(element)">
                        <i class="fi-rr-eye"></i>
                      </button>
                      <button mat-icon-button (click)="editOrderTota(element)" *ngIf="element.purchase_order_state_id==1">
                          <i class="fi-rr-edit"></i>
                      </button>
                      <button mat-icon-button (click)="editOrder(element)" *ngIf="element.purchase_order_state_id==1">
                          <i class="fi-rr-paper-plane"></i>
                      </button>
                      <button mat-icon-button (click)="downloadOrder(element)" *ngIf="(element.purchase_order_state_id==5||element.purchase_order_state_id==6 || element.purchase_order_state_id==8) && element.path!=null">
                          <i class="fi-rr-download"></i>
                      </button>
                      <button [disabled]="validateStateFrags(element)" mat-icon-button (click)="fragmentarOrder(element)" *ngIf="element.purchase_order_state_id!=7 && element.purchase_order_state_id!=8">
                          <i class="fi-rr-layout-fluid"></i>
                      </button>
                    </div>

                </td>
            </ng-container>

            <ng-container matColumnDef="ID Orden">
                <th mat-header-cell *matHeaderCellDef> ID Orden </th>
                <td mat-cell *matCellDef="let element">OC-{{element.purchase_order_number }} </td>
            </ng-container>

            <ng-container matColumnDef="Fecha de creación">
                <th mat-header-cell *matHeaderCellDef> Fecha de creación </th>
                <td mat-cell *matCellDef="let element"> {{element.created_at | date:'yyyy-MM-dd': 'UTC'}} </td>
            </ng-container>
            <ng-container matColumnDef="NIT">
                <th mat-header-cell *matHeaderCellDef> NIT </th>
                <td mat-cell *matCellDef="let element"> {{element.provider.nit}} </td>
            </ng-container>
            <ng-container matColumnDef="Razón Social">
                <th mat-header-cell *matHeaderCellDef> Razón social </th>
                <td mat-cell *matCellDef="let element"> {{element.provider.razon_social|slice:0:30 }} </td>
            </ng-container>
            <ng-container matColumnDef="request_title">
                <th mat-header-cell *matHeaderCellDef> Título de solicitud </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.request.title | slice:0:30 }}
                    <span *ngIf="element.request.title.length > 30" >...</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="Empresa">
                <th mat-header-cell *matHeaderCellDef> Empresa </th>
                <td mat-cell *matCellDef="let element"> {{element.company.name}} </td>
            </ng-container>
            <ng-container matColumnDef="Estado">
                <th class="end" mat-header-cell *matHeaderCellDef> Estado </th>
                <td mat-cell *matCellDef="let element" align="center">
                  <div style="white-space: nowrap;">
                    <i
                      class                     = "span"
                      style                     = "align-items: initial;"
                      [style.backgroundColor]   = "element.purchase_order_state.color"
                      [style.color]             = "element.purchase_order_state.color"
                      >&nbsp;</i>
                   {{element.purchase_order_state.name}}</div> </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" class="px-2">
                    <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <table class="table">
                            <tbody>
                                <tr *ngFor="let frag of element.purchase_order_frags">
                                    <td style="width: 12.5%; text-align: right;" class="p-0 action-icons pe-4">
                                        <div class="action-icons" style="margin-right: 5px;">
                                            <button mat-icon-button (click)="viewSolicitud(frag)" *ngIf="frag.purchase_order_state_id==0">
                                                <i class="fi-rr-eye"></i>
                                            </button>
                                            <button mat-icon-button (click)="editOrderFrag(frag)" *ngIf="frag.purchase_order_state_id==1 && element.purchase_order_state_id!=7">
                                                <i class="fi-rr-paper-plane"></i>
                                            </button>
                                            <button mat-icon-button (click)="downloadOrder(frag)" *ngIf="frag.path!=null && (frag.purchase_order_state_id==5 || frag.purchase_order_state_id == 8)">
                                                <i class="fi-rr-download"></i>
                                            </button>
                                        </div>
                                    </td>
                                    <td style="width: 12.5%; text-align: left;" class="ps-4"> OC-{{element.purchase_order_number}}-{{frag.purchase_order_number}}</td>
                                    <td style="width: 12.5%; text-align: left;">{{frag.created_at | date}}</td>
                                    <td style="width: 12.5%; text-align: left;"></td>
                                    <td style="text-align: left;"></td>
                                    <td style="width: 12.5%; text-align: left;"></td>
                                    <td style="width: 12.5%; text-align: left;"></td>
                                    <td style="width: 20%; text-align: center;" *ngIf="element.purchase_order_state_id!=7">{{frag.remission_state == 'Finalizado' ||  frag.purchase_order_state_id == 1 ? 'Creado' : frag.purchase_order_state_id == 8 ? 'En inventario' : 'Enviado a pago'}}</td>
                                    <td style="width: 20%; text-align: center;" *ngIf="element.purchase_order_state_id==7">Cancelada</td>
                                    <!--<td mat-cell *matCellDef="let element" align="left"><small><i class="span" style="align-items: initial;" [style.backgroundColor]= "element.purchase_order_state.color" [style.color]= "element.purchase_order_state.color">.</i></small> {{element.purchase_order_state.name}}
                                    </td>-->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row" [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="7">No hay datos que coincidan con el filtro</td>
            </tr>
        </table>
        <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)" [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>

</div>
