import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { ResourcesService } from '../../../services/resources.service';

@Component({
  selector: 'app-resources-create',
  templateUrl: './resources-create.component.html',
  styleUrls: ['./resources-create.component.sass']
})
export class ResourcesCreateComponent implements OnInit {

  createResourceType = false;
  resourceForm = this.fb.group({
    'name': [null, Validators.required],
    'type_resource_id': [null, Validators.required],
    'status': [1],
  });
  resourceTypes = [];
  states = false;

  constructor(private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<ResourcesCreateComponent>,
              private resourcesService: ResourcesService,
              private alertNotification: AlertsService,
              private auth: AuthService,
              private dialog: MatDialog) {
              }


  ngOnInit(): void {
    this.auth.getUser().roles.forEach(element => {
      if (element === 'rrhh::usuario') {
        this.states = true;
      }

      if (this.data.action === 'Editar') {
        this.resourceForm.patchValue(this.data.row);
      }
    });
    this.getResourceTypes();
  }

  getResourceTypes() {
    this.resourcesService.getResourceTypes(true).subscribe( res => {
      this.resourceTypes = res;
    });
  }

  saveResource() {
    if (this.resourceForm.valid) {
      if (this.data.action === 'Crear') {
        this.resourcesService.createResources(this.resourceForm.value).subscribe(res => {
          this.alertNotification.alertSuccess('Recurso creado con éxito', '');
          this.dialogRef.close(true);
        });
      }

      if (this.data.action === 'Editar') {
        this.resourcesService.updateResources(this.data.row.id, this.resourceForm.value).subscribe( res => {
          this.alertNotification.alertSuccess('Recurso editado con éxito', '');
          this.dialogRef.close(true);
        });
      }
    } else {
      this.resourceForm.markAllAsTouched();
    }
  }

}
