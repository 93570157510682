<i *ngIf="showCloseIcon" class="fi-rr-cross-small" style="float: right; font-size: 32px; color: #353535;" mat-icon-button (click)="cancelSave()"></i>
<h3 mat-dialog-title>{{ modo === 'crear' ? 'Crear' : modo === 'editar' ? 'Editar' : 'Visualizar' }} estrategia.</h3>

<mat-divider role="separator" class="mat-divider mat-divider-horizontal" aria-orientation="horizontal"></mat-divider>

<mat-dialog-content>
    <form [formGroup]="strategyForm">
        <div class="col-md-12 p-3">

            <!-- Nombre de la estrategia -->
            <div class="row mt-3">
                <div class="col col-md-12">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Nombre de la estrategia</mat-label>
                        <input matInput formControlName="name" 
                            placeholder="Nombre de la estrategia" 
                            [readonly]="modo === 'visualizar'" 
                            [disabled]="modo === 'visualizar'">
                        <mat-error *ngIf="strategyForm.get('name').touched && strategyForm.get('name').hasError('required')">
                            El nombre de la estrategia es requerido.
                        </mat-error>
                    </mat-form-field>
                </div>
                
                <!-- Nuevo multiselect basado en la lista de canales -->
                <div class="col col-md-12 col-lg-12">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-select formControlName="channel" placeholder="Seleccione canales" multiple>
                            <mat-option *ngFor="let channel of listChannel" [value]="channel.id">
                                {{ channel.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="strategyForm.get('channel').touched && strategyForm.get('channel').hasError('required')">
                            Debe seleccionar al menos un canal.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="w-100 mt-4" formArrayName="sections">
                <div class="row mt-2 mb-2" *ngFor="let section of sections.controls; let i = index" [formGroupName]="i">
                    <!-- Nombre de la sección -->
                    <div class="col col-md-12 col-lg-12">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Nombre de la sección</mat-label>
                            <input matInput formControlName="section_name" 
                                placeholder="Nombre de la sección" required 
                                [readonly]="modo === 'visualizar'" 
                                [disabled]="modo === 'visualizar'">
                            <mat-error *ngIf="section.get('section_name').touched && section.get('section_name').hasError('required')">
                                El nombre de la sección es requerido.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- Contenido de la sección -->
                    <div class="col col-md-12 col-lg-12">
                        <mat-form-field appearance="fill" class="w-100">
                            <textarea matInput #textArea formControlName="guion" 
                                (input)="createChips(textArea.value, i)" 
                                placeholder="Contenido" required 
                                [readonly]="modo === 'visualizar'" 
                                [disabled]="modo === 'visualizar'" 
                                (mouseup)="getSelectedText(i)">
                            </textarea>
                            <mat-error *ngIf="section.get('guion').touched && section.get('guion').hasError('required')">
                                El contenido es requerido.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- Sección para agregar etiquetas personalizadas -->
                    <div class="col col-md-12 col-lg-12" *ngIf="tagModeSelected && tagMode === 'personalizadas' && modo !== 'visualizar'">
                        <p style="font-size: 14px; padding-top:10px">Selecciona el texto que deseas etiquetar y agrégalo como una etiqueta en esta sección.</p>
                        <button mat-raised-button color="primary" (click)="addChipFromSelection(selectedText, i)">
                            Agregar etiqueta <i class="fi-rr-plus"></i>
                        </button>
                    </div>

                    <!-- Sección para mostrar etiquetas recomendadas -->
                    <div class="col col-md-12 col-lg-12" *ngIf="tagModeSelected && tagMode === 'recomendadas' && modo !== 'visualizar'">
                        <p style="font-size: 14px; padding-top: 10px">Haz clic en las etiquetas que deseas agregar en esta sección.</p>
                        <div class="chip-container">
                            <div *ngFor="let chip of chips[i]" (click)="chipSelection(chip, i)" 
                                [ngClass]="{'selected': chip.selected}" class="chip">{{chip.text}}
                            </div>
                        </div>
                    </div>

                    <!-- Selector de etiquetas -->
                    <div *ngIf="modo !== 'visualizar'" style="padding-top: 10px">
                        <div class="col col-md-12 col-lg-12">
                            <mat-button-toggle-group class="toggle-group" (change)="onTagSelectionChange($event.value)" aria-label="Seleccionar modo de etiquetas">
                                <mat-button-toggle class="toggle-button" value="recomendadas" mat-raised-button color="primary">
                                    Etiquetas Recomendadas
                                </mat-button-toggle>
                                <mat-button-toggle class="toggle-button" value="personalizadas" mat-raised-button color="primary">
                                    Etiquetas Personalizadas
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>


                    <!-- Sección para mostrar etiquetas seleccionadas -->
                    <div class="col col-md-12 col-lg-12" style="padding-top: 10px">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-chip-list #chipList>
                                <mat-chip *ngFor="let word of selectedChips[i]" [selectable]="false" 
                                    [removable]="modo !== 'visualizar'" 
                                    (removed)="removeWordSelected(word, i)">
                                        {{word.text}} 
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input formControlName="etiquetas" placeholder="Etiquetas seleccionadas" 
                                       [matChipInputFor]="chipList" 
                                       [matChipInputAddOnBlur]="false" 
                                       [readonly]="true" 
                                       [disabled]="modo === 'visualizar'">
                            </mat-chip-list>
                        </mat-form-field>
                    </div>

                    <!-- Campo de Porcentaje de Sección -->
                    <div class="col col-md-12 col-lg-12" *ngIf = "showRatingSection">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Porcentaje de la sección</mat-label>
                            <input matInput formControlName="percentage_section" type="number" 
                                placeholder="Porcentaje de la sección" min="0" required 
                                [readonly]="modo === 'visualizar'" 
                                [disabled]="modo === 'visualizar'">
                            <mat-error *ngIf="section.get('percentage_section').touched && section.get('percentage_section').hasError('required')">
                                El porcentaje es requerido.
                            </mat-error>
                            <mat-error *ngIf="section.get('percentage_section').hasError('min') || section.get('percentage_section').hasError('max')">
                                El porcentaje debe estar entre 0 y 100.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- Botón para eliminar sección -->
                    <div class="col-md-12 col-lg-12 d-flex justify-content-center" *ngIf="modo !== 'visualizar'">
                        <button mat-raised-button color="warn" type="button" (click)="eliminarSeccion(i)">
                            Eliminar sección <i class="fi-rr-trash"></i>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Botón para agregar nueva sección -->
            <div class="col-md-12 col-lg-12 d-flex justify-content-center" *ngIf="modo !== 'visualizar'">
                <button mat-raised-button color="primary" type="button" (click)="agregarSeccion()">
                    Nueva sección <i class="fi-rr-add"></i>
                </button>
            </div>
            
        </div>
    </form>
</mat-dialog-content>

<div class="col-md-12 p-2 pt-0" *ngIf="modo !== 'visualizar'">
    <div class="row py-3 d-flex justify-content-end">
        <div class="col-md-3">
            <button type="button" mat-raised-button class="w-100" style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;"(click)="cancelSave()">Cancelar</button>
        </div>
        <div class="col-md-3" *ngIf="modo === 'crear'">
            <button type="button" mat-raised-button color="primary" class="w-100" style="height: 48px; font-weight: 600;" (click)="saveStrategy()">Guardar</button>
        </div>
        <div class="col-md-3" *ngIf="modo === 'editar'">
            <button type="button" mat-raised-button color="primary" class="w-100" style="height: 48px; font-weight: 600;" (click)="updateStrategy()">Guardar</button>
        </div>
    </div>
</div>
