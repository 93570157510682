import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";
import { MatDialog } from '@angular/material/dialog';
import { UsersReportsCreateComponent } from '../users-reports-create/users-reports-create.component';
import { ReportService } from '../../../services/rest/reports.service';


@Component({
  selector: 'app-users-reports',
  templateUrl: './users-reports.component.html',
  styleUrls: ['./users-reports.component.sass']
})
export class UsersReportsComponent implements OnInit {

  formId: any;
  reports: any;
  key : any;
  selectTitle: any;
  dataReport: any = [];

  constructor(private location: Location,
              private activatedRoute: ActivatedRoute,
              private sanitizer: DomSanitizer,
              public dialog: MatDialog,
              private reportService : ReportService
              ) {}

  ngOnInit(): void {
    this.getReports();
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-01-20
   * Metodo que lista los reportes en estado activo
   */
  getReports()
  {
    this.reportService.getReports().subscribe(
      response => {
        this.reports = response;
      }
    );
  }

/**
   * @author Carlos Nieto
   * @createdate 2022-01-20
   * Metodo que retorna a la pagina anterior
   */
  backRoute(){
    this.location.back();
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-01-20
   * Metodo que setea la variable del select y sanitia la ruta
   * @param evento on click
   */
  select(event: any)
  {
    let valueAtIndex = this.reports[event];
    this.selectTitle = valueAtIndex.name;
    this.key =this.sanitizer.bypassSecurityTrustResourceUrl('http://reportes.outsourcingcos.com/report/'+valueAtIndex.key_powerBi);
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-01-20
   * Metodo que abre el modal para guardar un reporte
   */
   saveReport(): void{

    const dialogRefSave = this.dialog.open(UsersReportsCreateComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Crear Reporte',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogRefSave.afterClosed().subscribe(() => {
      setTimeout(()=>{
        this.getReports();
      }, 2500);
    });

  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-01-20
   * Metodo que abre el modal para editar un reporte
   * @param item del array listado
   */
  editReport(item)
  {
    const dialogRefSave = this.dialog.open(UsersReportsCreateComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'Editar',
        title: 'Editar Reporte',
        item :item,
      },
      panelClass: 'custom-dialog-container',
    });

    dialogRefSave.afterClosed().subscribe(() => {
      setTimeout(()=>{
        this.selectTitle = null;
        this.getReports();
      }, 2500);
    });
  }

}
