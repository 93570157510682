import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ManagerChatService } from './../../services/manager-chat.service'
import { ChatFromHistoryInterface, MessageChatInterface, UserChatInterface } from '../../utils/interfaces.chat';
import { ChatRestService } from '../../services/chat-rest.service';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ChatGroupsComponent } from '../chat-groups/chat-groups.component';


@Component({
  selector: 'app-active-chat',
  templateUrl: './active-chat.component.html',
  styleUrls: ['./active-chat.component.sass']
})
export class ActiveChatComponent implements OnInit {
  //Listado de chats 
  listChat:any[]=[]
  //Indicador de si esta activo o no un chat
  isSeeChat:boolean = false
  //Indicador de si el chat se encuentra desplegado totamente o solo con el nombre
  isToggleChat:boolean = false
  //Valor del chat buscado
  userChat:UserChatInterface|null = null

  //Control del formulario done se registra el los mensajes a enviar
  messageControl:FormControl = new FormControl(null,[Validators.required])

  //Refrencia al contenedor del chat el cual ejecutara el scroll de manera suave para ir al final del chat
  @ViewChild('scrollContainer') private scrollContainer: ElementRef;
  constructor(
    private managerChat:ManagerChatService,
    private chatRestService:ChatRestService,
    private authService: AuthService,
    private dialog:MatDialog,
  ) { }

  ngOnInit(): void {
    //REcibe el chat que se eta usando actualmente
    
    const subs =   this.managerChat.$actualChat.subscribe(
        (actualChat:UserChatInterface|null)=>{
          if(actualChat !== null ){
            this.isSeeChat = true
            this.userChat = actualChat
            if( this.userChat.countMessageNotRead > 0 ){
              this.chatRestService.markAllMessageRead(String(this.userChat.rrhh_id) , String(this.userChat.recipient_type)).subscribe(resp=>{});
            }
            
            const subsHistory = this.chatRestService.getHistoryChat(this.userChat.rrhh_id, String(this.userChat.recipient_type) ).subscribe(
              (history:any)=>{
                if(history && history?.messages ){
                    if(this.userChat.recipient_type == 1 && Array.isArray(history.messages?.users) ){
                      this.listChat = history.messages?.users

                    }else if (this.userChat.recipient_type == 2 && Array.isArray(history.messages?.groups)){
                      this.listChat = history.messages?.groups

                    }
                    this.setPositionChat();
                }
              }
            );


            //Se recibe  el ultimo mensaje emitido 
            const subsMessage = this.managerChat.$messagePublic.subscribe(
              (message:MessageChatInterface|null)=>{
                const messageExist = this.listChat.find( msg => msg.id == message.id )
                if(messageExist == undefined){
                  if(message !== null && message.recipient_type == 1 ){
                    if( message.sender_id == this.userChat.rrhh_id){
                      this.chatRestService.markAllMessageRead(String(this.userChat.rrhh_id) , String(this.userChat.recipient_type)).subscribe(resp=>{});
                      this.listChat.push(message);
                      
    
                    }
                  }else if ( message !== null && message.recipient_type == 2 ){
                    if(this.userChat && this.userChat !== null){
                      this.chatRestService.markAllMessageRead(String(this.userChat.rrhh_id) , String(this.userChat.recipient_type)).subscribe(resp=>{});
                      this.listChat.push(message);
                    }
                                      
                  }
                }
                this.setPositionChat();
                
              }
            );


          }
        }
      )
    


    const readerMessage = this.managerChat.$readerMessages.subscribe(
      (readMessages)=>{
        if(this.userChat !== null && this.userChat.rrhh_id == readMessages.sender){
          this.listChat.map( (chat:any) =>{
            if(chat?.read == undefined || Array.isArray(chat?.read) == false) {
              chat["read"]=[{ user_id:readMessages.reader_id , name: readMessages.reader_name , read_at: readMessages.read_at }]
            }else if (Array.isArray(chat?.read) == true){
              chat?.read.push({ user_id:readMessages.reader_id , name: readMessages.reader_name , read_at: readMessages.read_at })
            }
            return chat
          } );
        }
      }
    );

  }

  /**
   * MEtodo que se encarga de obtener la ohrao fecha del chat segun el dia que se tenga registro
   * @author Juan David Guerrero Vargas
   * @param created_at:string|Date {string|Date} fecha a formatear
   * @returns string {string} fecha formateada
   */
  getMessageTime(created_at: string | Date): string {
    const messageDate = new Date(created_at);
    const today = new Date();

    if (
      messageDate.getDate() === today.getDate() &&
      messageDate.getMonth() === today.getMonth() &&
      messageDate.getFullYear() === today.getFullYear()
    ) {
      const hour = messageDate.getHours() % 12 || 12;
      const minutes = messageDate.getMinutes();
      const ampm = messageDate.getHours() >= 12 ? 'PM' : 'AM';
      return `${hour}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    } else {
      const month = messageDate.toLocaleString('default', { month: 'short' });
      const day = messageDate.getDate();
      const hour = messageDate.getHours() % 12 || 12;
      const minutes = messageDate.getMinutes();
      const ampm = messageDate.getHours() >= 12 ? 'PM' : 'AM';
      return `${day} ${month}, ${hour}:${minutes
        .toString()
        .padStart(2, '0')} ${ampm}`;
    }
  }


  /**
   * Metodo que se encrga de salir del chat actual
   * @author Juan David Guerrero Vargas
   * @returns void
   */
  closeChat():void{
    this.isSeeChat = false
    this.listChat = []
    this.userChat = null
    this.managerChat.setActualChat(null)
  }

  /**
   * MEtodo que se encarga de abrir o disminuir el chat activo segun el estado anterior
   */
  toggleChat():void{
    this.isToggleChat = !this.isToggleChat
    if(this.isToggleChat){
      this.setPositionChat(200);
    }
  }


  /**
   * Metodo que se encarga de definir el estilo del chat segun si el emnsaje es mio o es de otro
   * @param chat:any {chat} Interaccion entre chats
   */
  styleChat(chat:any):string{
    let classMessageStandar = " message "
    if(chat.sender_id == this.authService.decryptToken().rrhh_id ){
      classMessageStandar += " own "
    }
    return classMessageStandar
  }


  /**
   * Metodo que se encarga de enviar un mensaje al usuario indicado del chat
   * @author Juan David Guerrero Vargas
   * @returns void
   */
  sendMessage():void{
      if(this.messageControl.value !== null && this.messageControl.value !== ""){
        let message = {
          content: this.messageControl.value,
          recipient_type:this.userChat.recipient_type,
          recipient_entity_id:this.userChat.rrhh_id
        }
        
        this.chatRestService.sendMessage(message).subscribe((resp)=> {
          if(message !== null && message.recipient_type == 1 ){
            this.listChat.push(resp.mesage)
          }
          this.messageControl.setValue("");
          this.setPositionChat();
        } )
      }
  }

  /**
   * Valida si el mensaje se encuentra enviado o si se ha recibido por el otro usuario
   * @author Juan David Guerrero Vargas
   * @param chat:any {any} chat 
   * @returns 
   */
  validateSendeMessages(chat:any):string{
    if(chat?.read == undefined || Array.isArray(chat?.read)==true && chat?.read.length > 0  ){
      return "Recibido"
    }
    return "Enviado"
  }

  /**
   * Metodo que se encarga de colocar la pocision del scroll de la pantalla en la aprte de abajo conce aparecenlos ultimo mensajes
   * @author Juan David Guerrero Vargas
   */
  setPositionChat(time?:number):void{

    setTimeout(() => {
      const container = this.scrollContainer.nativeElement;
      container.scrollTo({
        top: container.scrollHeight,
        behavior: 'smooth'
      });
    }, time == undefined ? 300 : time );
  }

  /**
   * Metodo que se encarga de obtener si el uusario en session es el dueño del chat 
   * @author Juan David Guerrero Vargas
   * @param chat:any {any} Chat a evaluar apra identificar si se debe o no mostrar el indicador de lider del grupo
   * @returns {boolean} boolean True en caso de ser el dueño del grupo false en caso contrario
   */
  getIsOwnerChar(chat):boolean{
    if(this.userChat != null && this.userChat?.onwer_id !== undefined && this.userChat?.onwer_id == chat.sender_id ){
      return true
    }
    return false
  }



}
