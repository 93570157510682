import { createAction, props } from "@ngrx/store";
import { auditoriaModel } from "../../core/interfaces/auditoria.interface";

/**
 * @author Cristian Gallo
 * @createdate 2021-12-10
 * Action que cargara la información de la uditoría.
*/

export const loadAuditoria = createAction(
    '[Auditoria] Load auditoria',
    props<{idAuditoria}>()
);

export const loadedAuditoria = createAction(
    '[Auditoria] Loaded auditoría',
    props<{ auditoria:auditoriaModel[] }>()
)