import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalApproveComponent } from './modal-approve/modal-approve.component';
import { ModalAssignRecruiterComponent } from './modal-assign-recruiter/modal-assign-recruiter.component';
import { ModalCreateRequestComponent } from './modal-create-request/modal-create-request.component';
import { ModalDeleteComponent } from './modal-delete/modal-delete.component';
import { ModalValidateComponent } from './modal-validate/modal-validate.component';
import { RequestsComponent } from './requests.component';
import { SharedModule } from '../../../../shared/shared.module';
import { SharedFormModule } from '../shared-form/shared-form.module';
import { MaterialModule } from '../../../../shared/material/material.module';



@NgModule({
  declarations: [
    ModalApproveComponent,
    ModalAssignRecruiterComponent,
    ModalCreateRequestComponent,
    ModalDeleteComponent,
    ModalValidateComponent,
    RequestsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedFormModule
  ]
})
export class RequestsModule { }
