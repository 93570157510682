  <form class="row form" [formGroup]="passwordForm" (ngSubmit)="passAuth(passwordForm)">

    <mat-dialog-content style="padding: 3%; text-align: center;" class="mat-typography">

      <h1>Retomar turno</h1>
      <p>Ingrese su contraseña para retomar su turno</p>
      <br>
      <mat-form-field class="col-4"  appearance="fill">
        <mat-label>Contraseña</mat-label>
        <input matInput (keydown.enter)="passAuth(passwordForm)" (keypress)="omitSpecialChar($event)" [type]="hide ? 'password' : 'text'" placeholder="Escribe tu contraseña" formControlName="password" required>
        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.minlength">¡Aún falta! Recibimos mínimo 5 caracteres</span></mat-error>
        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 15 caracteres</span></mat-error>
        <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
        <mat-icon>{{hide ?  'visibility' : 'visibility_off'}}</mat-icon>
        </a>
    </mat-form-field>
    <br>
    <br>
    <button mat-raised-button class='col-4' type="submit" color="primary" disabled='{{passwordForm.invalid}}'>Ingresar</button>
    

    </mat-dialog-content>

  </form>


