<div class="main-container row">

    <div class="mt-5 col-12">
        <button mat-icon-button class="btn-regresar" (click)="backClicked()">
            <mat-icon matPrefix style="background-color: #000; border-radius: 50%;"><i class="fi-sr-angle-small-left"
                    style="color: #FFF;"></i></mat-icon>
            Regresar
        </button>
    </div>


    <div class="mt-3 col-12">
        <div fxFlex="86.5%">
            <h2>ID Auditoría - {{datos}}</h2>
        </div>
    </div>
    <div class="mat-elevation-z0 mt-2 mb-4 col-12">
        <table [dataSource]="dataSource" mat-table matSort>

            <!-- Columna matriz -->
            <ng-container matColumnDef="matriz">
                <th mat-header-cell *matHeaderCellDef> Matriz </th>
                <td mat-cell *matCellDef="let element"> {{ element.nombre_matriz}}</td>
            </ng-container>

            <!-- Columna Fecha auditoría -->
            <ng-container matColumnDef="fecha_auditoria">
                <th mat-header-cell *matHeaderCellDef> Fecha de la gestión </th>
                <td mat-cell *matCellDef="let element"> {{ element.fecha_auditoria | date:'dd/MM/yyyy'}} </td>
            </ng-container>


            <!-- Columna Calificación -->
            <ng-container matColumnDef="calificacion">
                <th mat-header-cell *matHeaderCellDef> Calificación </th>
                <td mat-cell *matCellDef="let element"> {{ element.calificacion | number:'1.0-1'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No hay registros para mostrar</td>
            </tr>
        </table>
    </div>

    <mat-form-field class="col-3" appearance="fill" disabled>
        <mat-label>Campaña</mat-label>
        <input type="text" matInput value="{{ data_general.tipo_campana }}" disabled>
    </mat-form-field>


    <mat-form-field class="col-3" appearance="fill" disabled>
        <mat-label>Aliado</mat-label>
        <input type="text" matInput value="{{ data_general.aliado }}" disabled>
    </mat-form-field>

    <mat-form-field class="col-3" appearance="fill" disabled>
        <mat-label>Nombre del asesor</mat-label>
        <input type="text" matInput value="{{ data_general.nombre_asesor }}" disabled>
    </mat-form-field>


    <mat-form-field class="col-3" appearance="fill" disabled>
        <mat-label>Etiqueta</mat-label>
        <input type="text" matInput value="{{ data_general.etiqueta }}" disabled>
    </mat-form-field>


    <mat-form-field class="col-3" appearance="fill" disabled>
        <mat-label>Fecha llamada Auditada</mat-label>
        <input type="text" matInput value="{{ data_general.fecha_llamada }}" disabled>
    </mat-form-field>

    <mat-form-field class="col-3" appearance="fill" disabled>
        <mat-label>Número teléfono Auditado</mat-label>
        <input type="text" matInput value="{{ data_general.telefeno_auditado }}" disabled>
    </mat-form-field>


    <mat-form-field class="col-3" appearance="fill" disabled
        *ngIf=" data_general.id_tipo_campana == '180' || data_general.id_tipo_campana == '180'">
        <mat-label>Hora contacto asesor cliente</mat-label>
        <input type="text" matInput value="{{ data_general.hora_contacto }}" disabled>
    </mat-form-field>

    <mat-form-field class="col-3" appearance="fill" disabled id="grabacion"
        *ngIf="showFieldDuration === 1">
        <mat-label>Duración grabación</mat-label>
        <input type="text" matInput value="{{ data_general.duracion_llamada }}" disabled>
    </mat-form-field>

    <mat-form-field class="col-3" appearance="fill" disabled
        *ngIf="data_general.id_tipo_campana == '11' || data_general.id_tipo_campana == '12' || data_general.id_tipo_campana == '17' ">
        <mat-label>Skills</mat-label>
        <input type="text" matInput value="{{ data_general.skill }}" disabled>
    </mat-form-field>

    <mat-form-field class="col-3" appearance="fill" disabled
        *ngIf="data_general.id_tipo_campana == '11' || data_general.id_tipo_campana == '12' || data_general.id_tipo_campana == '17' ">
        <mat-label>Tipificación</mat-label>
        <input type="text" matInput value="{{ data_general.tipificacion }}" disabled>
    </mat-form-field>

    <mat-form-field class="col-3" appearance="fill" disabled
        *ngIf="data_general.id_tipo_campana == '11' || data_general.id_tipo_campana == '12' || data_general.id_tipo_campana == '17' ">
        <mat-label>Sub tipificación</mat-label>
        <input type="text" matInput value="{{ data_general.sub_tipificacion }}" disabled>
    </mat-form-field>


    <mat-form-field class="col-3" appearance="fill" disabled
        *ngIf="data_general.id_tipo_campana == '11' || data_general.id_tipo_campana == '12' || data_general.id_tipo_campana == '17' ">
        <mat-label>Login</mat-label>
        <input type="text" matInput value="{{ data_general.login }}" disabled>
    </mat-form-field>


    <mat-form-field class="col-3" appearance="fill" disabled
        *ngIf="data_general.id_tipo_campana == '11' || data_general.id_tipo_campana == '14' || data_general.id_tipo_campana == '12' || data_general.id_tipo_campana == '17' || data_general.id_tipo_campana == '18' ">
        <mat-label>Usuario</mat-label>
        <input type="text" matInput value="{{ data_general.usuario }}" disabled>
    </mat-form-field>


    <mat-form-field class="col-3" appearance="fill" disabled
        *ngIf="data_general.id_tipo_campana == '18' || data_general.id_tipo_campana == '14' || data_general.id_tipo_campana == '13' || data_general.id_tipo_campana == '10' || data_general.id_tipo_campana == '16' || data_general.id_tipo_campana == '15' ">
        <mat-label>Número de Radicado(Incidencia y CUN)</mat-label>
        <input type="text" matInput value="{{ data_general.nur }}" disabled>
    </mat-form-field>


    <mat-form-field class="col-3" appearance="fill" disabled
        *ngIf="data_general.id_tipo_campana == '14' || data_general.id_tipo_campana == '18' ">
        <mat-label>Tienda</mat-label>
        <input type="text" matInput value="{{ data_general.tienda }}" disabled>
    </mat-form-field>

    <mat-form-field class="col-3" appearance="fill" disabled
        *ngIf="data_general.id_tipo_campana == '13' || data_general.id_tipo_campana == '13' ">
        <mat-label>Tipo</mat-label>
        <input type="text" matInput value="{{ data_general.tipo }}" disabled>
    </mat-form-field>


    <mat-form-field class="col-3" appearance="fill" disabled
        *ngIf="data_general.id_tipo_campana == '13' || data_general.id_tipo_campana == '13' ">
        <mat-label>Subtipo PQRS</mat-label>
        <input type="text" matInput value="{{ data_general.subtipo_pqrs }}" disabled>
    </mat-form-field>

    <mat-form-field class="col-3" appearance="fill" disabled
        *ngIf="data_general.id_tipo_campana == '13' || data_general.id_tipo_campana == '13' ">
        <mat-label>Segmento</mat-label>
        <input type="text" matInput value="{{ data_general.segmento }}" disabled>
    </mat-form-field>



    <mat-form-field class="col-3" appearance="fill" disabled>
        <mat-label>Año</mat-label>
        <input type="text" matInput value="{{ data_general.ano }}" disabled>
    </mat-form-field>


    <mat-form-field class="col-3" appearance="fill" disabled>
        <mat-label>Mes</mat-label>
        <input type="text" matInput value="{{ data_general.nombre_mes }}" disabled>
    </mat-form-field>

    <mat-form-field *ngIf="isRetentionMatrix === 1" class="col-3" appearance="fill">
        <mat-label>¿Es una retención?</mat-label>
        <input type="text" matInput value="{{ data_general.is_retencion === 1 ? 'Si' : 'No' }}" disabled>
    </mat-form-field>
    

    <mat-form-field class="col-12" appearance="fill" disabled>
        <mat-label>Observaciones</mat-label>
        <input type="text" matInput value="{{ data_general.observacion }}" disabled>
    </mat-form-field>

    <mat-form-field class="col-12" appearance="fill" disabled>
        <mat-label>Voz cliente</mat-label>
        <input type="text" matInput value="{{ data_general.voz_cliente }}" disabled>
    </mat-form-field>


    <mat-card-header class="row col-12">
        <mat-card class="col-2 calificacion c1">
            <p style="font-size: 11px;font-weight:700">Total</p>
            <h4 style="font-weight:700">{{data_general.Calificacion_total}}%</h4>
        </mat-card>

        <mat-card class="col-2 calificacion c2">
            <p style="font-size: 11px;font-weight:700">Cumplimiento</p>
            <h4 style="font-weight:700">{{data_general.Calificacion_cumplimiento}}%</h4>
        </mat-card>

        <mat-card class="col-2 calificacion c3">
            <p style="font-size: 11px;font-weight:700">No crítico</p>
            <h4 style="font-weight:700">{{data_general.Calificacion_no_critico}}%</h4>
        </mat-card>

        <mat-card class="col-2 calificacion c4">
            <p style="font-size: 11px;font-weight:700">Crítico usuario final Campaña</p>
            <h4 style="font-weight:700">{{data_general.Calificacion_usuario_final_campana}}%</h4>
        </mat-card>


        <mat-card class="col-2 calificacion c5">
            <p style="font-size: 11px;font-weight:700">Crítico negocio</p>
            <h4 style="font-weight:700">{{data_general.Calificacion_critico_negocio}}%</h4>
        </mat-card>

        <mat-card class="col-2 calificacion c6">
            <p style="font-size: 11px;font-weight:700">Precisión de error crítico</p>
            <h4 style="font-weight:700">{{data_general.Calificacion_PEC}}%</h4>
        </mat-card>



    </mat-card-header>






    <div [formGroup]="calificacionesForm" class="mb-4 col-12">

        <div style="overflow: auto; min-height: 450px;" class="mb-4 col-12">
            <div formArrayName="modulos" *ngFor="let modulo of modulesForm.controls; let iModulo = index">
                <div [formGroupName]="iModulo">
                    <mat-expansion-panel togglePosition="before" [expanded]="iModulo == 0">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <p class="sigla">{{modulo.get('detalle_item').value.sigla}}</p>
                                <p>{{modulo.get('detalle_item').value.nombre}}</p>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div formArrayName="items" *ngFor="let item of modulo.get('items').controls;let iItem= index">
                            <div [formGroupName]="iItem">
                                <mat-expansion-panel hideToggle [expanded]="true" (opened)="isExpanded = false"
                                    (closed)="isExpanded = false" hideToggle (click)="$event.stopPropagation()">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title fxFlex="80%">
                                            <p class="sigla">{{item.get('detalle_item').value.sigla}}</p>
                                            <p [matTooltip]="item.get('detalle_item').value.nombre"
                                                style="font-size: 11.5px;padding-top: .3em;"
                                                [matTooltipPosition]="'above'">{{item.get('detalle_item').value.nombre
                                                |cutText:0:1000}}</p>
                                        </mat-panel-title>
                                        <mat-panel-description fxFlex="20%">
                                            <div *ngIf="tipo_matriz == 'Cumplimiento'">
                                                <mat-radio-group aria-label="Select an option"
                                                    (change)="calificacionItem(item)" formControlName="calificacion">
                                                    <mat-radio-button [value]="item.get('cumple').value"
                                                        (click)="$event.stopPropagation()">Cumple</mat-radio-button>
                                                    <mat-radio-button [value]="item.get('no_cumple').value"
                                                        (click)="$event.stopPropagation()">No cumple</mat-radio-button>
                                                    <mat-radio-button [value]="item.get('no_aplica').value"
                                                        (click)="$event.stopPropagation()">N/A</mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div *ngIf="tipo_peso == 'Peso en Items'">
                                                <mat-radio-group aria-label="Select an option"
                                                    (change)="calificacionItem(item)" formControlName="calificacion">
                                                    <mat-radio-button [value]="item.get('si').value"
                                                        (click)="$event.stopPropagation()">Si</mat-radio-button>
                                                    <mat-radio-button [value]="item.get('no').value"
                                                        (click)="$event.stopPropagation()">No</mat-radio-button>
                                                    <mat-radio-button [value]="item.get('no_aplica').value"
                                                        (click)="$event.stopPropagation()">N/A</mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div *ngIf="tipo_peso  == 'Peso en Módulos'">
                                                <mat-radio-group aria-label="Select an option"
                                                    (change)="calificacionItem(item)" formControlName="calificacion">
                                                    <mat-radio-button [value]="item.get('si').value"
                                                        (click)="$event.stopPropagation()">Si</mat-radio-button>
                                                    <mat-radio-button [value]="item.get('no').value"
                                                        (click)="$event.stopPropagation()">No</mat-radio-button>
                                                    <mat-radio-button [value]="item.get('no_aplica').value"
                                                        (click)="$event.stopPropagation()">N/A</mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <p style="font-size: 11.5px;" *ngIf="item.get('observacion').value">
                                        <b>Observación:</b> {{ item.get('observacion').value }}</p>


                                    <div formArrayName="subitems"
                                        *ngFor="let subtItem of item.get('subitems').controls;let iSubItem = index">
                                        <div [formGroupName]="iSubItem">
                                            <mat-expansion-panel hideToggle>
                                                <mat-expansion-panel-header class="sub-item">
                                                    <mat-panel-title fxFlex="46%">
                                                        <p class="sigla-sub ms-4">
                                                            {{subtItem.get('detalle_item').value.sigla}}</p>
                                                        <button mat-icon-button
                                                            [disabled]="!subtItem.get('detalle_item').value.descripcion">
                                                            <mat-icon style="font-size: 1.3em; margin-top: -.7em;"
                                                                [matTooltip]="subtItem.get('detalle_item').value.descripcion"
                                                                [matTooltipPosition]="'above'"><i
                                                                    class="fi-rr-document"></i></mat-icon>
                                                        </button>
                                                        <p class="sub-item" style="font-size: 11.5px;padding-top: .3em;"
                                                            [matTooltip]="subtItem.get('detalle_item').value.nombre"
                                                            [matTooltipPosition]="'above'">
                                                            {{subtItem.get('detalle_item').value.nombre |
                                                            cutText:0:1000}}</p>
                                                    </mat-panel-title>
                                                    <mat-panel-description fxFlex="54%">
                                                        <mat-radio-group aria-label="Select an option"
                                                            formControlName="calificacion" class="calificacion-sub">
                                                            <mat-radio-button [value]="subtItem.get('cumple').value"
                                                                (click)="$event.stopPropagation()">Cumple</mat-radio-button>
                                                            <mat-radio-button [value]="subtItem.get('no_cumple').value"
                                                                (click)="$event.stopPropagation()">No
                                                                cumple</mat-radio-button>
                                                            <mat-radio-button [value]="subtItem.get('no_aplica').value"
                                                                (click)="$event.stopPropagation()">N/A</mat-radio-button>
                                                        </mat-radio-group>
                                                    </mat-panel-description>
                                                </mat-expansion-panel-header>
                                            </mat-expansion-panel>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>
            </div>
        </div>



        <div class="ms-3 col-12 row" mat-dialog-content style="overflow: auto;">
            <mat-form-field appearance="fill" style="display:none">
                <mat-label>Observación de la auditoría</mat-label>
                <textarea matInput formControlName="txtAuditoria"></textarea>
            </mat-form-field>
            <mat-form-field appearance="fill" style="width:100%">
                <mat-label>Retroalimentación</mat-label>
                <textarea matInput formControlName="retroalimentacion_auditor"></textarea>
                <mat-error>Por favor ingrese una retroalimentación.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" style="display:none">
                <mat-label>Retroalimentación Supervisor</mat-label>
                <textarea matInput formControlName="retroalimentacion_supervisor"></textarea>
            </mat-form-field>
            <mat-form-field appearance="fill" style="display:none">
                <mat-label>Compromiso calidad</mat-label>
                <textarea matInput formControlName="compromiso_calidad"></textarea>
            </mat-form-field>
            <mat-form-field appearance="fill" style="display:none">
                <mat-label>Compromiso operación</mat-label>
                <textarea matInput formControlName="compromiso_operacion"></textarea>
            </mat-form-field>
        </div>


        <div fxLayoutAlign="center center" *ngIf="verBtns" class="col-12 row">
            <button mat-stroked-button class="btns-dialog me-3" (click)="backClicked()">Cancelar</button>
            <button mat-raised-button color="primary" class="btns-dialog" (click)="updateRetroalimentacionById()"
                *ngIf="!is_asesor">Guardar</button>
            <button mat-raised-button color="primary" class="btns-dialog" (click)="updateAuditoria()" *ngIf="is_asesor"
                [disabled]="calificacionesForm.get('compromiso_calidad').enabled || calificacionesForm.get('compromiso_operacion').enabled ? false: true">
                Guardar
            </button>
        </div>
    </div>

</div>