import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { EscalationService } from '../../../services/rest/escalation.service';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs as importedSaveAs } from 'file-saver';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-mail-escalation',
  templateUrl: './mail-escalation.component.html',
  styleUrls: ['./mail-escalation.component.sass']
})
export class MailEscalationComponent implements OnInit {

  dataInfo: any[] = [];
  listDataInfo: any[] = [];
  @Input() escalation: any; 
  panelOpenState = false;

  constructor(
  private escalationService: EscalationService,
  private sanitizer:DomSanitizer
  ) { 
  }

  ngOnInit() {
    this.loadDataEscalation();
  }

  async loadDataEscalation(){
    if(this.escalation.email_id != null){
      // await this.escalationService.getAttachments(this.escalation.email_id).toPromise();
      this.escalationService.getEmailPQRS(this.escalation.email_id).subscribe(resp => {
        resp[0].cuerpo = this.convertSanitizer(resp[0].cuerpo);
        this.dataInfo = resp;
        this.listDataInfo = [
          { name: 'De: ', value: this.dataInfo[0]?.de },
          { name: 'Para: ', value: this.dataInfo[0]?.para },
          { name: 'Asunto: ', value: this.dataInfo[0]?.asunto },
          { name: 'Fecha de recibido: ', value: this.dataInfo[0]?.fecha_Hora },
        ];
        
      });
      
    }
  }

  convertSanitizer(body){
    return this.sanitizer.bypassSecurityTrustHtml(body);
  }

  downloadEmailAttachment(route:any, nombre:any){
    this.escalationService.downloadEmailAttachment({url: route}).subscribe(resp => {
      importedSaveAs(resp, nombre);      
    },
    error =>{
      Swal.fire('!Atención¡', "Ha ocurrido un error al intentar descargar el adjunto", "error");
    }
    );
  }
}