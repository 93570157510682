<div class="content-login">
  <div class="trama">
      <img src="../../../assets/images/Trama_Mask-lg.png" alt="">
  </div>

<!--Inicio Form-->
  <mat-card class="card" fxLayout="column" fxLayoutAlign="space-between none">

      <mat-card-content style="min-height: 85%;" fxLayout="column" fxLayoutAlign="space-between none">
          <br>
          <form class="row form" [formGroup]="passForm" (ngSubmit)="changePass()">

            <div class="col-12 text-center">
                <mat-dialog-content class="mat-typography">
                    <h2 mat-dialog-title>Estás a un paso</h2>
                    <p cdkFocusInitial>A continuación, ingresa tu nueva contraseña</p>
                    <br>
                    <mat-form-field class="col-12" appearance="fill">
                        <mat-label>Contraseña nueva</mat-label>
                        <input matInput appBlockCopyPaste autocomplete="new-password"(keypress)="omitSpecialChar($event)" formControlName="nueva" [type]="hideDos ? 'password' : 'text'" required>
                        <mat-error *ngIf="error.nueva.errors"><span *ngIf="error.nueva.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                        <mat-error *ngIf="error.nueva.errors"><span *ngIf="error.nueva.errors.minlength">¡Aún falta! Recibimos mínimo 5 caracteres</span></mat-error>
                        <mat-error *ngIf="error.nueva.errors"><span *ngIf="error.nueva.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 15 caracteres</span></mat-error>
                        <mat-error *ngIf="error.nueva.errors"><span *ngIf="error.nueva.errors.hasNumber">Su contraseña debe tener un número</span></mat-error>
                        <mat-error *ngIf="error.nueva.errors"><span *ngIf="error.nueva.errors.hasCapitalCase">Su contraseña debe tener una mayuscula</span></mat-error>
                        <mat-error *ngIf="error.nueva.errors"><span *ngIf="error.nueva.errors.hasSmallCase">Su contraseña debe tener una minuscula</span></mat-error>
                        <mat-error *ngIf="error.nueva.errors"><span *ngIf="error.nueva.errors.hasSpecialCharacters">Su contraseña debe tener un caracter especial</span></mat-error>
                        <mat-error *ngIf="error.nueva.errors"><span *ngIf="!error.nueva.errors.isMatching">Su nueva contraseña no puede ser igual a la actual</span></mat-error>
                        <a mat-icon-button matSuffix (click)="hideDos = !hideDos" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideDos">
                            <mat-icon>{{hideDos ?  'visibility' : 'visibility_off'}}</mat-icon>
                        </a>
                    </mat-form-field>
                    <br>
                    <br>
                    <mat-form-field class="col-12" appearance="fill">
                        <mat-label>Confirma la contraseña nueva</mat-label>
                        <input matInput appBlockCopyPaste autocomplete="new-password" (keypress)="omitSpecialChar($event)" formControlName="confirma" [type]="hideTres ? 'password' : 'text'" required>
                        <mat-error *ngIf="error.confirma.errors"><span *ngIf="error.confirma.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                        <mat-error *ngIf="error.confirma.errors"><span *ngIf="error.confirma.errors.minlength">¡Aún falta! Recibimos mínimo 5 caracteres</span></mat-error>
                        <mat-error *ngIf="error.confirma.errors"><span *ngIf="error.confirma.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 15 caracteres</span></mat-error>
                        <mat-error *ngIf="error.confirma.errors"><span *ngIf="error.confirma.errors.hasNumber">Su contraseña debe tener un número</span></mat-error>
                        <mat-error *ngIf="error.confirma.errors"><span *ngIf="error.confirma.errors.hasCapitalCase">Su contraseña debe tener una mayuscula</span></mat-error>
                        <mat-error *ngIf="error.confirma.errors"><span *ngIf="error.confirma.errors.hasSmallCase">Su contraseña debe tener una minuscula</span></mat-error>
                        <mat-error *ngIf="error.confirma.errors"><span *ngIf="error.confirma.errors.hasSpecialCharacters">Su contraseña debe tener un caracter especial</span></mat-error>
                        <mat-error *ngIf="error.confirma.errors"><span *ngIf="!error.confirma.errors.isMatching">Su contraseña no es igual a la ingresada previamente</span></mat-error>
                        <a mat-icon-button matSuffix (click)="hideTres = !hideTres" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideTres">
                            <mat-icon>{{hideTres ?  'visibility' : 'visibility_off'}}</mat-icon>
                        </a>
                    </mat-form-field>
                </mat-dialog-content>

                <div class="row mt-4">
                  <div class="col">
                  </div>
                  <div class="col">
                    <button mat-raised-button type="submit" style="width: 100%;" color="primary" [disabled]="passForm.invalid" >Cambiar contraseña</button>
                  </div>
                </div>
            </div>
          </form>
      </mat-card-content>

  </mat-card>
</div>
