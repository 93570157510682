

<div class="main-container">
  <mat-dialog-content>
  <h1>Disponibilidad {{nameResource}}</h1>
  <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-bottom: -10px;">
    <button mat-icon-button matTooltip="Anterior semana" (click)="paginateWeek('subtract')" [disabled]="back"> <i class="fi-sr-angle-left" ></i> </button>
    <button mat-icon-button matTooltip="Siguiente semana" (click)="paginateWeek('add')"> <i class="fi-sr-angle-right"></i> </button>
  </div>

  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="selectedTab">

    <mat-tab 
      *ngFor     = "let day of week; index as i"
      [label]    = ""
      [disabled] = "day.data == 0">
      <ng-template mat-tab-label>
        {{day.name + ' ' + day.number + ( ( day.cant_disp!== undefined && day.cant_disp > 0 ) ?  ' ('+day.cant_disp+')' : '' )}}
        <br>
        <span class="text-muted" *ngIf="day.data.observation"> 
          {{day.data.observation}}
        </span>
      </ng-template>
    <br>
    <div fxLayout="row wrap" fxLayoutGap="20px grid">
      <div fxFlex=16 *ngFor="let item of day.data?.resources" >
        <div class="example-list" (click)="makeBooking(item.id, day.data?.date)" [ngClass]="{'disabled_card':item.available == false}" fxLayout="column">
          <p>{{item.name}}</p>
          <mat-card-subtitle>Tiempos disponibles</mat-card-subtitle>
          <span class="hours" *ngFor="let hour of item?.hours" style="padding-bottom: 6px;" >
            {{hour.start }} - {{hour.end}}</span>
          <mat-card-subtitle *ngIf="!item.available">Sin disponibilidad</mat-card-subtitle>
          <mat-card-subtitle style="padding-bottom: 6px;">
            {{item.cant_avaiable }} disponibles
          </mat-card-subtitle>
        </div>

      </div>
    </div>

    </mat-tab>

  </mat-tab-group>
</mat-dialog-content>
</div>
