import { Component, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { EDITOR_CONFIG } from './ConfigAngularEditor';
import { FormGroup, FormControl, Validators, FormGroupDirective } from "@angular/forms";
import { GestionadorCorreosService } from "./services/gestionador-correos.services";
import Swal  from "sweetalert2";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-gestionador-correos',
  templateUrl: './gestionador-correos.component.html',
  styleUrls: ['./gestionador-correos.component.scss'],
})
export class GestionadorCorreosComponent implements OnInit {
  formGestionadorCorreos: FormGroup;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  editorConfig = EDITOR_CONFIG;

  listDesde: any[] = [];
  listPara: string[] = [];
  listCopia: string[] = [];
  listCopiaOculta: string[] = [];
  listFiles: any = [];

  ifValidEmails: boolean = false;
  messageValidEmails: string = '';
  plantillasHtml: any[] = [];

  constructor(public servicesGestionadorCorreo: GestionadorCorreosService, private dialogRef: MatDialogRef<GestionadorCorreosComponent>) {}

  /**
    * @author Fabian Duran
    * @createdate 2022-11-02
    * Metodo que inicializa la configuracion del formulario y llena los correos de salida. 
  */
  ngOnInit(): void {
    this.formGestionadorCorreos = new FormGroup({
      emailSalida: new FormControl('', [Validators.required]),
      asunto: new FormControl('', [Validators.required]),
      emailBody: new FormControl('', [Validators.required]),
      plantillaHtml: new FormControl(null, [])
    });
    this.formGestionadorCorreos.get('emailBody').clearValidators();
    this.formGestionadorCorreos.get('emailBody').updateValueAndValidity();
    this.getEmailExit();
  }

  /**
    * @author Fabian Duran
    * @createdate 2022-11-02
    * Metodo que llama al backend para traer los correos de salida. 
  */
  getEmailExit(): void {
    this.servicesGestionadorCorreo.getEmailsByExit().subscribe(res => {
      this.listDesde = res.emails.map(item => { 
        return {
          email: item.email,
          id: `${item.id} - ${item.email} - ${item.name} - ${item.origin}`  
        }
      });
      this.plantillasHtml = res.emailTemplates;
    });
  }

  /**
    * @author Fabian Duran
    * @createdate 2022-11-02
    * Metodo que valida cada uno de los correos registrados en los chip list. 
    * @param listEmails Lista de correos los cuales van a ser validados.
    * @param nameList Nombre de la lista a validar.
  */
  validateListCorreos(listEmails: string[], nameList: string): void {
    if (listEmails.length > 0) {
      for (let i = 0; i < listEmails.length; i ++) {
        if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(listEmails[i])) {
          this.ifValidEmails = true;
        } else {
          this.ifValidEmails = false;
          if (nameList === 'listPara') this.messageValidEmails = 'Correos invalidos en la lista "Para"';
          if (nameList === 'listCopia') this.messageValidEmails = 'Correos invalidos en la lista "Con copia"';
          if (nameList === 'listCopiaOculta') this.messageValidEmails = 'Correos invalidos en la lista "Con copia oculta"';
        }
      }
    }
  }

  /**
    * @author Fabian Duran
    * @createdate 2022-11-02
    * Metodo que agrega una cadena de texto a cada uno de los chip list.
    * @param event Manejador de eventos de los mat chip.
    * @param nameChipList Nombre del list chip en el cual se van agregar una cadena de texto.  
  */
  addItemByChipList(event: MatChipInputEvent, nameChipList: string): void {
    const input = event.input;
    const value = (event.value || '').trim();
    if (nameChipList === 'listPara') if (value) this.listPara.push(value);
    if (nameChipList === 'listCopia') if (value) this.listCopia.push(value);
    if (nameChipList === 'listCopiaOculta')
      if (value) this.listCopiaOculta.push(value);
    if (input) input.value = '';
  }

  /**
    * @author Fabian Duran
    * @createdate 2022-11-02
    * Metodo que elimina una cadena de texto dentro un chip list.
    * @param item Cadena de texto seleccionada dentro del chip list. 
    * @param nameChipList Nombre del chip list en el cual se va a remover el elemento.
  */
  removeItemByChipList(item: string, nameChipList: string): void {
    if (nameChipList === 'listPara') {
      const index = this.listPara.indexOf(item);
      if (index >= 0) this.listPara.splice(index, 1);
    }
    if (nameChipList === 'listCopia') {
      const index = this.listCopia.indexOf(item);
      if (index >= 0) this.listCopia.splice(index, 1);
    }
    if (nameChipList === 'listCopiaOculta') {
      const index = this.listCopiaOculta.indexOf(item);
      if (index >= 0) this.listCopiaOculta.splice(index, 1);
    }
  }

  /**
    * @author Fabian Duran
    * @createdate 2022-11-02
    * Metodo que agrega un archivo a la lista de archivos. 
  */
  addFile(): void {
    const inputFile = document.getElementById('inputFile') as HTMLInputElement;
    if (inputFile) {
      for (let i = 0; i < inputFile.files!.length; i++) {
        const file = inputFile!.files![i];
        this.listFiles.push({
          file,
        });
      }
    }
  }

  /**
    * @author Fabian Duran
    * @createdate 2022-11-02
    * Metodo que elimina un archivo de la lista de archivos. 
    * @param nameFile Nombre del archivo a eliminar. 
  */
  removeFile(nameFile: string): void {
    const filterListFiles = this.listFiles.filter(
      (item: any) => item.file.name !== nameFile
    );
    this.listFiles = filterListFiles;
  }

  /**
    * @author Fabian Duran
    * @createdate 2022-11-02
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formGestionadorCorreos.controls;
  }

  /**
    * @author Fabian Duran
    * @createdate 2022-11-03
    * Metodo que captura todas las etiquetas <img> y almacena el src de la imagen. 
    * @param form Instancia del formulario principal enviado desde el submit. 
  */
  getImageByEmailBody(form: any): string[] {
    const containerBody = document.getElementById('bodyEmail');
    containerBody.innerHTML = form.value.emailBody;
    const arrayImage = containerBody.querySelectorAll('img');
    const listImageBase = [];
    if (arrayImage.length > 0) {
      for (let i = 0; i < arrayImage.length; i ++) {
        listImageBase.push(arrayImage[i].getAttribute('src'));
      }
    }
    return listImageBase;
  }

  /**
    * @author Fabian Duran
    * @createdate 2022-11-02
    * Metodo que valida las listas y realiza el llamado al submit del formulario.  
    * @param form Instancia del formulario principal enviado desde el submit. 
  */
  validateForm(form: any): void {
    if (form.status === 'VALID') {
      if (this.listPara.length > 0) {
        this.validateListCorreos(this.listPara, 'listPara');
        this.validateListCorreos(this.listCopia, 'listCopia');
        this.validateListCorreos(this.listCopiaOculta, 'listCopiaOculta');
        if (this.ifValidEmails) {
          if (this.validateSizeFiles(this.listFiles)) {
            this.sendEmail(form);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Los archivos adjuntos superan las 25 MB'
            });
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: this.messageValidEmails
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Deben haber correos de destino'
        });
      }
    }
  }

  /**
    * @author Fabian Duran
    * @createdate 2022-11-02
    * Metodo que resetea los valores del formulario y atributos de la clase.  
  */
  resetForm():void {
    this.formGestionadorCorreos.reset();
    this.formGestionadorCorreos.get('emailSalida').clearValidators();
    this.formGestionadorCorreos.get('asunto').clearValidators();
    this.formGestionadorCorreos.get('emailBody').clearValidators();
    this.formGestionadorCorreos.get('plantillaHtml').clearValidators();
    this.formGestionadorCorreos.get('emailSalida').updateValueAndValidity();
    this.formGestionadorCorreos.get('asunto').updateValueAndValidity();
    this.formGestionadorCorreos.get('emailBody').updateValueAndValidity();
    this.formGestionadorCorreos.get('plantillaHtml').updateValueAndValidity();
    this.listPara = [];
    this.listCopia = [];
    this.listCopiaOculta = [];
    this.listFiles = [];
  }

  /**
    * @author Fabian Duran
    * @createdate 2022-11-02
    * Metodo que envia los datos del formulario hacia el backend para el envio de correos. 
    * @param form Instancia del formulario enviado desde el submit.
  */
  sendEmail(form: any): void {
    const emailConfig = form.value.emailSalida.split(' - ')[0];
    const address = form.value.emailSalida.split(' - ')[1];
    const name = form.value.emailSalida.split(' - ')[2];
    const origin = form.value.emailSalida.split(' - ')[3];
    const listImageBase = this.formGestionadorCorreos.get('plantillaHtml').value !== null ? [] : this.getImageByEmailBody(form);

    const formData = new FormData();
    formData.append('emailConfig', emailConfig);
    formData.append('address', address);
    formData.append('name', name);
    formData.append('origin', origin);
    formData.append('subject', form.value.asunto);
    formData.append('body', form.value.emailBody);
    formData.append('recipients', JSON.stringify(this.listPara));
    formData.append('recipients_cc', JSON.stringify(this.listCopia));
    formData.append('recipients_cco', JSON.stringify(this.listCopiaOculta));
    formData.append('listImageBase', JSON.stringify(listImageBase));
    formData.append('emailTemplate', form.value.plantillaHtml);
    for (let i = 0; i < this.listFiles.length; i ++) {
      formData.append(`adjunto${i}`, this.listFiles[i].file);
    }
    
    this.servicesGestionadorCorreo.sendEmail(formData).subscribe(res => {
      Swal.fire({
        title: 'Enhorabuena',
        icon: 'success',
        text: 'Correo enviado conrrectamente'
      });
      this.resetForm();
      this.dialogRef.close();                                                                                                                                                   
    }, error => {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: 'Error al enviar el correo'
      });                                                                                                      
    });
  }

  /**
    * @author Fabian Duran
    * @createdate 2022-11-02
    * Metodo que valida el tamaño total de todos los archivos. 
    * @param listFiles Lista de archivos para validar su tamaño. 
  */
  validateSizeFiles(listFiles: any) {
    const maxSize = 26214400;
    let sumSize = 0;
    for (let i = 0; i < listFiles.length; i ++) {
      const size = parseInt(listFiles[i].file.size);
      sumSize += size;  
    } 
    const isValid = sumSize < maxSize ? true : false;
    return isValid;
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-01-24
    * Metodo que cambia la plantilla html sobre el cuerpo del correo.
    * @param $event Variable que trae toda la informacion del campo. 
  */
  changePlantillaEmailBody($event: any): void {
    const idPlantilla = $event.value;
    const searchPlantilla = this.plantillasHtml.find(item => { return item.id === idPlantilla });
    if (searchPlantilla) this.formGestionadorCorreos.get('emailBody').setValue(searchPlantilla.body);
  }
}