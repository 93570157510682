import { Component, OnInit, Inject } from '@angular/core';
import { CandidatesService } from '../../../services/candidates.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommentsService } from '../../../services/comments.service';
import { RequestsAllComponent } from '../../requests/requests-all/requests-all.component';
import { CandidateEditComponent } from '../candidate-edit/candidate-edit.component';
import { AuthService } from '../../../../../core/services/rest/auth.service';
import { CandidateCommentEditComponent } from '../candidate-comment-edit/candidate-comment-edit.component';
import { type } from 'os';

@Component({
  selector: 'app-candidate-comments',
  templateUrl: './candidate-comments.component.html',
  styleUrls: ['./candidate-comments.component.sass']
})
export class CandidateCommentsComponent implements OnInit {

  userLogged;
  candidateId;
  requestId;
  comments = [];
  otherComments = [];
  candidate;
  commentsType = [];
  recruitmentUserId;
  commentForm: FormGroup;
  statusComment:string;
  isRolOPerative;
  isRoleAdministrative;
  isRoleAnalyst;
  isRoleRecruiter;

  canCreateComments;
  constructor(private candidateService: CandidatesService, @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private dialogRef: MatDialogRef<CandidateCommentsComponent>,
              private commentService: CommentsService,
              private dialog: MatDialog,
              private authService:AuthService) {
               this.candidateId =  this.data.candidateId;
               this.requestId = this.data.requestId;
               this.userLogged = this.authService.getUser();
               this.isRolOPerative = this.authService.getUser().roles.includes('rrhh::lider_operativo');
               this.isRoleAdministrative = this.authService.getUser().roles.includes('rrhh::lider_administrativo');
               this.isRoleAnalyst = this.authService.getUser().roles.includes('rrhh::analista');
               this.isRoleRecruiter = this.authService.getUser().roles.includes('rrhh::reclutador');
               this.recruitmentUserId = this.data.recruitmentUserId;
              }

  ngOnInit(): void {
    this.commentService.getTypeComment(this.candidateId,this.data.requestId).subscribe((resp: any) => {
      this.commentsType = resp;
    });
    this.getComments();
    this.commentForm = this.fb.group({
      comment_type_id  :['', Validators.required],
      comment  :['', Validators.required],
      allProcess: ['2',Validators.required]
    });    
  }

  getComments(){
    this.candidateService.getComments(this.candidateId, {requestId: this.requestId,userId:this.userLogged.rrhh_id})
                          .subscribe((resp: any) => {

      this.candidate = resp;
      this.canCreateComments = typeof this.candidate?.creatorCommentary != undefined ? this.candidate?.creatorCommentary : false;
      if(this.isRolOPerative || this.isRoleAdministrative)
      {
        this.canCreateComments = true;
      }
      
      this.comments = resp.currentComments;
      this.otherComments = resp.othersComments;
    });
  }
  saveComment(){
    if(this.commentForm.valid){
          const params = {
            ...this.commentForm.value,
            candidateId: this.candidateId,
            requestId: this.requestId
          };
          this.commentService.registerComment(params).subscribe((resp: any) => {
              this.comments.push(resp);
              this.commentForm.reset();
              this.commentForm.untouched;
              if(resp.blockCreateComentary){
                this.candidate.creatorCommentary = false;
              }
          });
    }
  }

  transferCandidate(){
    const transfeDialog = this.dialog.open( RequestsAllComponent, {
      minWidth: '700px',
      data: { candidateId: this.candidateId, oldRequestId: this.requestId }
    });
    transfeDialog.afterClosed().subscribe( (params) => {
      if(params){
        this.dialogRef.close()
      }
         this.candidate.creatorCommentary = false;
    });

  }
  editComment(comment){
    const editComment = this.dialog.open( CandidateCommentEditComponent, {
      minWidth: '400px',
      data: { comment }
    });
    editComment.afterClosed().subscribe( (params) => {
         const commentIndexEdited = this.comments.map( e => e.id ).indexOf(params.id);
         if(commentIndexEdited > -1 ){
          this.comments[commentIndexEdited].comment = params.comment;
          this.comments[commentIndexEdited].updated = params.updated_at;

         }
    });
  }

    /**
   * @author Karol García
   * @createdate 2021-04-27
   * Metodo asigna el tipo de comentario y verifica el key de estado
   */
  changeComment(event){
    const found= this.commentsType.find(element => element.id == event);
    if(found){
      this.statusComment = found.key
    }

  }
}
