import { Component, OnInit, Inject } from '@angular/core';
import { RequestsService } from '../../../services/requests.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CandidatesService } from '../../../services/candidates.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';

@Component({
  selector: 'app-requests-all',
  templateUrl: './requests-all.component.html',
  styleUrls: ['./requests-all.component.sass']
})
export class RequestsAllComponent implements OnInit {

  constructor(
              private requestService: RequestsService,
              private candidateService: CandidatesService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialogRef<RequestsAllComponent>,
              private alert:AlertsService) {

              }

  requests = [];
  requestSelected;
  ngOnInit(): void {
    this.getAllRequests();
  }

  getAllRequests(){
    this.requestService.getAllRequests(this.data.oldRequestId).subscribe((resp: any) => {
        this.requests = resp;
    })
  }
  selectRequest(value){
    this.requestSelected = value;
  }

transferRequest(){
  this.alert.alertConfirm('¿Estás seguro?','Realizar el traslado implicará que a este candidato se le deban cargar y validar nuevamente los documentos para su contratación').then(e => {
    if(e.isConfirmed){
      const param = {
        candidateId: this.data.candidateId,
        requestId: this.requestSelected.id,
        userResponsableId: this.requestSelected.user_responsable_id,
        campaign:JSON.stringify(this.requestSelected.campaign),
        oldRequestId:this.data.oldRequestId
      };
      this.candidateService.transferCandidate(param).subscribe((resp: any) => {
        this.alert.alertSuccess('Transferido','El candidato ya forma parte del proceso de convocatoria de la solicitud seleccionada.');
         this.dialog.close(true);
    });
    }
  })

  }
}
