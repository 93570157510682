import { Component, Inject, OnInit } from '@angular/core';

import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { NoveltiesService } from '../../../../services/novelties.service';
import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import { AuthService } from '../../../../../../core/services/rest/auth.service';
import { InactiveDatesService } from '../../../../services/rest/inactive-dates.service';

// FullCalendar options
import { CalendarOptions } from '@fullcalendar/angular';
import esLocale from '@fullcalendar/core/locales/es';
import * as moment from 'moment';
import { debounceTime, delay, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

const NOMINA_URL = environment.NOMINA_URL;
@Component({
  selector: 'app-create-novelties',
  templateUrl: './create-novelties.component.html',
  styleUrls: ['./create-novelties.component.sass']
})
export class CreateNoveltiesComponent implements OnInit {

  public sending: boolean;
  public noveltyDate: string;
  public noveltyTypes: any[] = [];
  public noveltyTypesOld: any[] = [];
  public noveltyForm: FormGroup;
  Object = Object;
  public calendarOptions: CalendarOptions = {};
  public validDates: boolean;
  public showCalendar: boolean;
  public isVacation: boolean;
  public vacationCode: number;
  public vacationPaidCode: number;
  public disabilitiesCode: number;
  public userRrhhId: number;
  public campaignId: number;
  public availableDays: number;
  public businessDays: number;
  public permitCode: number;
  public permitGrantedPreviousDaysCode: number;
  public vacationExtemporalCode: number;
  public inactivesInCurrentYear: any[] = [];
  currentDate;
  public cleanNoveltyDate;
  public domesticCalamity: number;

  public nameFile: any = [];
  public dataFileArray: any = [];
  public formData = new FormData();
  public editDate: boolean = false;
  public durationInSeconds = 10;
  public nameUser: string = '';
  public isVacationPaid = false;
  public isOperationalPosition = false;

  public vacationsTypes: any = [ 
    {id: 90, name: 'Vacaciones en disfrute'}, //91 = Key Vacaciones Pagas Nomina 
  ];

  //EPS
  epsData: any;
  companyData: any;
  companyId: any = '';
  companyName: any = '';
  company: any;
  eps: any;
  hiddenEps: boolean = false;

  //Diagnostico
  searchDiagnosticCtrl = new FormControl();
  filteredDiagnostic: any[] = [];
  isLoading = false;
  errorMsg: string;
  selectedDiagnostic: any = "";
  showField: boolean = false;
  hiddeWhenIsLicency: boolean = false;
  isSelected: Number = 0;

  //tamaño del archivo
  showFileError = false;
  permittedSize = 4500000;
  currentSize = 0;

  //dias vacaciones
  totalDaysVacations = 0;
  totalDaysVacationPaid = 0;
  totalDaysMixted = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private noveltiesService: NoveltiesService,
    private alert: AlertsService,
    private authService: AuthService,
    private inactiveDatesService: InactiveDatesService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CreateNoveltiesComponent>,
    private http: HttpClient) {
    this.showCalendar = false;
    this.isVacation = false;
    this.vacationCode = 90;
    this.vacationPaidCode = 91;
    this.disabilitiesCode = 27;
    this.availableDays = 0;
    this.businessDays = 0;
    this.permitCode = 40;
    this.domesticCalamity = 99
    this.vacationExtemporalCode = 92;
    this.permitGrantedPreviousDaysCode = 42;
  }

  ngOnInit(): void {
    this.currentDate = new Date();
    this.userRrhhId = this.authService.getUser().rrhh_id;
    this.campaignId = this.authService.getUser().rrhh.campaign_id ?? null;
    this.companyId = this.authService.getUser().rrhh.company_id ?? null;
    this.companyName = this.authService.getUser().rrhh.company ?? null;
    this.noveltyDate = moment(`${this.data.extraData.dateStr} 00:00:00`).locale('es').format('D [de] MMMM, YYYY');
    this.cleanNoveltyDate = new Date(`${this.data.extraData.dateStr} 23:59:59`);
    const date = new Date(this.data.extraData.dateStr);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

    this.noveltiesService.getInfoWorker(this.userRrhhId).subscribe(resp => {
      let x = resp?.agreementRules?.eps?.id;
      let idEps: number = +x;
      this.noveltyForm.controls.eps.setValue(idEps);
      this.noveltyForm.controls.company.setValue(this.companyId);
    });
    this.noveltyForm = this.fb.group({
      rrhhId: [this.userRrhhId, Validators.required],
      noveltyDate: [date, Validators.required],
      noveltyDateEnd: [''],
      noveltyDateEndMixted: [''],
      noveltyTypeId: ['', Validators.required],
      noveltySubTypeId: [''],
      campaignId: [this.campaignId, Validators.required],
      noveltyJustify: ['', Validators.required],
      paidVacations: [''],
      eps: [''],
      company: [''],
      other: [''],
      vacationTypeId:['']
    });
    this.getNoveltyTypes();
    this.inactiveDatesService.getInactiveInCurrentYear()
      .subscribe(resp => {
        this.inactivesInCurrentYear = resp.data;
      });
    this.noveltiesService.getEps().subscribe(resp => {
      this.epsData = resp;
    });

    this.noveltiesService.getCompanys().subscribe(resp => {
      this.companyData = resp;
    });
    this.noveltyForm.controls.company.valueChanges.subscribe(resp => {
      if (resp || this.companyId) {
        this.noveltiesService.getCompanyById(resp || this.companyId).subscribe(resp => {
          this.company = resp;
        });
      }
    });

    this.form.noveltySubTypeId.valueChanges.subscribe(resp => {

      resp == 2 ? this.hiddenEps = true : this.hiddenEps = false;

      if (resp == 1 || resp == 5 || resp == 6) {
        this.hiddeWhenIsLicency = false;
        this.searchDiagnosticCtrl.setValidators(Validators.required);
        this.searchDiagnosticCtrl.updateValueAndValidity();


      } else if (resp == 4) {
        this.searchDiagnosticCtrl.setValue('');
        this.searchDiagnosticCtrl.clearValidators();
        this.searchDiagnosticCtrl.updateValueAndValidity();
        this.hiddeWhenIsLicency = true;

      } else if (resp == 2 || resp == 3) {
        this.searchDiagnosticCtrl.setValue('');
        this.searchDiagnosticCtrl.clearValidators();
        this.searchDiagnosticCtrl.updateValueAndValidity();
        this.noveltyForm.controls.eps.clearValidators();
        this.noveltyForm.controls.eps.updateValueAndValidity();
        this.hiddeWhenIsLicency = true;

      }
    });

    this.noveltyForm.controls.eps.valueChanges.subscribe(resp => {
      this.noveltiesService.getEpsById(resp).subscribe(resp => {
        this.eps = resp;
      });
    });

    this.searchDiagnosticCtrl.valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= 3
        }),
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredDiagnostic = [];
          this.isLoading = true;
        }),
        //this.http.get<any>(`${NOMINA_URL}diagnostics/filter/${value}`
        switchMap((value) => this.noveltiesService.searchDiagnostic(value)
          .pipe(
            finalize(() => {
              this.isLoading = false
            }),
          )
        )
        //this.noveltiesService.searchDiagnostic(res).subscribe(data => {} )
      ).subscribe((resp) => {
        if (resp?.data.length == 0) {
          this.errorMsg = 'No hay coincidencias';
          this.filteredDiagnostic = [];
        } else {
          this.errorMsg = "";
          this.filteredDiagnostic = resp?.data;
        }
      });

    // atento a cambios para setear como requerido el campo de subtipos de novedad
    this.form.noveltyTypeId.valueChanges.subscribe(val => {
      this.form.vacationTypeId.setValue("");
      const noveltyKey = this.noveltyTypesOld[val].key;
      if (this.noveltyTypesOld[val].sub_types.length > 0) {
        this.form.noveltySubTypeId.setValidators(Validators.required);
        this.form.noveltySubTypeId.updateValueAndValidity();
      } else {
        this.form.noveltySubTypeId.clearValidators();
        this.form.noveltySubTypeId.updateValueAndValidity();
      }
      this.showCalendar = false;

      // vacations validate if required
      if (noveltyKey == this.vacationCode || noveltyKey == this.permitCode || noveltyKey == this.disabilitiesCode || this.permitGrantedPreviousDaysCode) {
        if(this.form.vacationTypeId.value == this.vacationCode){
          this.form.noveltyDateEnd.setValidators(Validators.required);
          this.form.noveltyDateEnd.updateValueAndValidity();
          this.cleanValidations(['paidVacations','noveltyDateEndMixted']);
        }else if(this.form.vacationTypeId.value == this.vacationPaidCode || this.form.vacationTypeId.value == 'mixed'){
          this.form.paidVacations.setValidators([Validators.required,Validators.min(1)]);
          this.form.paidVacations.updateValueAndValidity();
          this.cleanValidations(['noveltyDateEnd','noveltyDateEndMixted']);
        }else if(this.form.vacationTypeId.value == 'mixed'){
          this.form.noveltyDateEndMixted.setValidators(Validators.required);
          this.form.noveltyDateEndMixted.updateValueAndValidity();
          this.form.paidVacations.setValidators([Validators.required,Validators.min(1)]);
          this.form.paidVacations.updateValueAndValidity();
          this.cleanValidations(['noveltyDateEnd']);
        }else{
          this.form.noveltyDateEnd.setValidators(Validators.required);
          this.form.noveltyDateEnd.updateValueAndValidity();
        }
      } else {
        this.form.noveltyDateEnd.clearValidators();
        this.form.noveltyDateEnd.updateValueAndValidity();
      }
    });

    // cambio de fecha fin para validar dias habiles
    this.form.noveltyDateEnd.valueChanges.subscribe((resp) => {
        if (this.form.noveltyTypeId.value && this.noveltyTypesOld[this.form.noveltyTypeId.value].key == this.vacationCode || this.form.noveltyTypeId.value && this.noveltyTypesOld[this.form.noveltyTypeId.value].key == this.vacationExtemporalCode) {
          this.getCalendarWithAvailableDays();
        }
      });

    // cambio de fecha inicio para validar dias habiles
    this.form.noveltyDate.valueChanges.subscribe((resp) => {
        if (this.form.noveltyTypeId.value && this.noveltyTypesOld[this.form.noveltyTypeId.value].key == this.vacationCode || this.form.noveltyTypeId.value && this.noveltyTypesOld[this.form.noveltyTypeId.value].key == this.vacationExtemporalCode) {
          this.getCalendarWithAvailableDays();
        }
      });
    
      // cambio de fecha fin para validar dias habiles
    this.form.noveltyDateEndMixted.valueChanges.subscribe((resp) => {
      this.getCalendarWithAvailableDays(true);
    });

    //cambio de tipo de vacaciones para validar dias habiles
    this.form.vacationTypeId.valueChanges.subscribe(resp => {
      this.businessDays = 0;
      this.businessDays = 0;
      this.totalDaysVacationPaid = 0;
      this.totalDaysVacations = 0;
      this.form.noveltyDateEnd.setValue('');
      this.form.paidVacations.setValue('');
      this.form.noveltyDateEndMixted.setValue('');
      //Se validan los campos obligatorios
      if(this.form.vacationTypeId.value == this.vacationCode){
        this.form.noveltyDateEnd.setValidators(Validators.required);
        this.form.noveltyDateEnd.updateValueAndValidity();
        this.cleanValidations(['paidVacations','noveltyDateEndMixted']);
      }
      if(this.form.vacationTypeId.value == this.vacationPaidCode || this.form.vacationTypeId.value == 'mixed'){
         this.form.paidVacations.setValidators([Validators.required,Validators.min(1)]);
        this.form.paidVacations.updateValueAndValidity();
        this.cleanValidations(['noveltyDateEnd','noveltyDateEndMixted']);
      }
      if(this.form.vacationTypeId.value == 'mixed'){
        this.form.noveltyDateEndMixted.setValidators(Validators.required);
        this.form.noveltyDateEndMixted.updateValueAndValidity();
        this.form.paidVacations.setValidators([Validators.required,Validators.min(1)]);
        this.form.paidVacations.updateValueAndValidity();
        this.cleanValidations(['noveltyDateEnd']);
      }
      if (this.form.vacationTypeId.value != '') {
        this.getDaysVacationsAvailable();
      }
    });

    //se setea el valor de fin fecha vacaciones, se suma los dias de pagos
    this.form.paidVacations.valueChanges.subscribe(resp => {
      if(resp != null || resp != ''){
        if(resp > 0){
          this.isVacationPaid = true;
          let dateEnd = new Date(this.form.noveltyDate.value);
          dateEnd.setDate(dateEnd.getDate() + (resp - 1));
          this.form.noveltyDateEnd.setValue(dateEnd);
          setTimeout(() => {
            this.isVacationPaid = false
           }, 1500);
          ;
        }
      }
    });

    // cambio de diagnostico
    this.searchDiagnosticCtrl.valueChanges.subscribe((resp) => {
      if (!this.hiddeWhenIsLicency) {
        if (resp?.id) {
          this.isSelected = 2;
        } else if (resp?.length > 0) {
          this.isSelected = 1;
        }
      } else {
        this.isSelected = 0;
      }
    })

    //se valida si la persona tiene cargo operativo
    this.noveltiesService.isOperationalPosition().subscribe(resp => {
      if(resp === true || resp === false) this.isOperationalPosition = resp;
      if(this.isOperationalPosition == true){
        this.vacationsTypes.push({id: 91, name: 'Vacaciones en dinero'}); //90 = Key Vacaciones Nomina 
        this.vacationsTypes.push({id: 'mixed', name: 'Vacaciones mixtas'}); //vacaciones mixtas
      }
    });
  }
  onSelected() {
    this.selectedDiagnostic = this.selectedDiagnostic;
    if (this.selectedDiagnostic.code == 'OTRO') {
      this.showField = true;
    } else {
      this.showField = false;
    }
  }


  displayFn(value: any): string {
    if (!value) {
      return '';
    }
    return value.code + ' - ' + value.description;
  }

  clearSelection() {
    this.selectedDiagnostic = "";
    this.filteredDiagnostic = [];
    this.showField = false;
  }

  /**
   * Obtiene el calendario con los dias habiles para la solicitud vacaciones
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   */
  getCalendarWithAvailableDays(mixed = null): void {
    if (!this.form.noveltyDateEnd.value) return;
    this.noveltiesService.getAvailableDaysForVacations({
      startDate: moment(this.form.noveltyDate.value).locale('es').format('YYYY-MM-DD'),
      endDate: mixed == null ? moment(this.form.noveltyDateEnd.value).locale('es').format('YYYY-MM-DD') : moment(this.form.noveltyDateEndMixted.value).locale('es').format('YYYY-MM-DD')
    }).subscribe((respDates) => {
      this.validDates = respDates.data.validDates;
      this.availableDays = respDates.data.availableDays < 0 ? 0 : respDates.data.availableDays;
      if(mixed != null) this.totalDaysMixted = respDates.data.businessDays;
      //Validacion dias habiles vacaciones renumeradas
      if(this.form.vacationTypeId.value == this.vacationCode) this.businessDays = respDates.data.businessDays;
      //Validacion dias vacaciones pagas
      if(this.isVacationPaid === true || this.form.vacationTypeId.value == this.vacationPaidCode){
        this.totalDaysVacationPaid = respDates.data.totalDays;
      }
      if(this.form.vacationTypeId.value == this.vacationPaidCode) this.businessDays = respDates.data.totalDays;
      //Suma de vacaciones remuneras y vacaciones paas
      if(this.form.vacationTypeId.value == 'mixed') {
        if(this.isVacationPaid == false) this.totalDaysVacations = respDates.data.businessDays;
        this.businessDays = this.totalDaysVacationPaid + this.totalDaysVacations;
      }
    
      // configure calendar options
      this.calendarOptions = {
        initialView: 'dayGridMonth',
        locales: [esLocale],
        locale: 'es',
        headerToolbar: {
          left: 'title',
          center: '',
          right: 'prev,next'
        },
        titleFormat: {
          month: 'long',
          year: 'numeric'
        },
        events: respDates.data.dates,
        handleWindowResize: true,
        windowResizeDelay: 200
      };
      this.showCalendar = (this.form.vacationTypeId.value == this.vacationCode || this.noveltyTypesOld[this.form.noveltyTypeId.value].key == this.vacationExtemporalCode);
    });
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Justifica la novedad seleccionada previamente en el calendario
   */
  createNovelty(): void {
    this.sending = true;
    const formData = new FormData();
    switch (Number(this.noveltyTypesOld[this.form.noveltyTypeId.value].key)) {
      case this.vacationCode:

        if (this.inactivesInCurrentYear[moment(this.form.noveltyDate.value).locale('es').format('YYYY-MM-DD')]) {
          this.alert.alertWarning('Proceso detenido', 'La fecha inicial no empezar en un día no hábil, por favor seleccione una fecha diferente');
          this.sending = false;
          return;
        }

        if (this.inactivesInCurrentYear[moment(this.form.noveltyDateEnd.value).locale('es').format('YYYY-MM-DD')] && this.form.vacationTypeId.value != 'mixed' && this.form.vacationTypeId.value != this.vacationPaidCode) {
          this.alert.alertWarning('Proceso detenido', 'La fecha final no puede terminar en un día no hábil, por favor seleccione una fecha diferente');
          this.sending = false;
          return;
        }

        // validamos que para pedir las vacaciones sea menor al dia siguiente
        if (moment(this.form.noveltyDate.value).locale('es').format('YYYY-MM-DD') <= moment(this.currentDate).format('YYYY-MM-DD')) {
          this.alert.alertWarning('Proceso detenido', 'Solo puede solicitar vacaciones a partir del día siguiente');
          this.sending = false;
          return;
        }
        let validDateMixted = false;
        if(this.form.vacationTypeId.value == 'mixed'){
          validDateMixted = (this.form.paidVacations.value + this.totalDaysMixted) > this.availableDays;
        }
        if (!this.validDates || validDateMixted ) {
          this.alert.alertWarning('Proceso detenido', 'Verifique las fechas de su solicitud de vacaciones');
          this.sending = false;
          return;
        }

        if(this.form.paidVacations.value > 7){
          this.alert.alertWarning('Proceso detenido', 'Solo puedes solicitar  máximo 7 días de vacaciones pagas.');
          this.sending = false;
          return;
        }

        var vacationToCreate:any = {
          rrhhId: this.userRrhhId,
          start_date: moment(this.form.noveltyDate.value).locale('es').format('YYYY-MM-DD'),
          end_date: moment(this.form.noveltyDateEnd.value).locale('es').format('YYYY-MM-DD'),
          justify: this.form.noveltyJustify.value,
          noveltyType: this.form.vacationTypeId.value
        };

        if(this.form.vacationTypeId.value == 'mixed'){
          vacationToCreate = {
            noveltyType: 'mixed',
            start_date: moment(this.form.noveltyDate.value).locale('es').format('YYYY-MM-DD'),
            vacations:[
              {
                rrhhId: this.userRrhhId,
                start_date: moment(this.form.noveltyDate.value).locale('es').format('YYYY-MM-DD'),
                end_date: moment(this.form.noveltyDateEnd.value).locale('es').format('YYYY-MM-DD'),
                justify: this.form.noveltyJustify.value,
                noveltyType: this.vacationPaidCode
              },
              {
                rrhhId: this.userRrhhId,
                start_date: moment(this.form.noveltyDate.value).locale('es').format('YYYY-MM-DD'),
                end_date: moment(this.form.noveltyDateEndMixted.value).locale('es').format('YYYY-MM-DD'),
                justify: this.form.noveltyJustify.value,
                noveltyType: this.vacationCode
              }
            ]
          } 
        }

        this.noveltiesService.createVacationNovelties(vacationToCreate).subscribe(resp => {
            this.dialogRef.close('');
            this.alert.alertSuccess('Solicitud creada!', 'En espera de validación por el área encargada');
            this.sending = false;
          }, (error) => {
            this.sending = false;
          });
        break;
      case this.disabilitiesCode:
        if (this.inactivesInCurrentYear[moment(this.form.noveltyDate.value).locale('es').format('YYYY-MM-DD')]) {
          this.alert.alertWarning('Proceso detenido', 'La fecha inicial no empezar en un día no hábil, por favor seleccione una fecha diferente');
          this.sending = false;
          return;
        }

        if (this.inactivesInCurrentYear[moment(this.form.noveltyDateEnd.value).locale('es').format('YYYY-MM-DD')]) {
          this.alert.alertWarning('Proceso detenido', 'La fecha final no puede terminar en un día no hábil, por favor seleccione una fecha diferente');
          this.sending = false;
          return;
        }

        const dateEnd: string | null = new DatePipe('en-US').transform(this.form.noveltyDateEnd.value, 'yyyy-MM-dd');
        const dateStart: string | null = new DatePipe('en-US').transform(this.form.noveltyDate.value, 'yyyy-MM-dd');
        formData.append('rrhhId', this.form.rrhhId.value);
        formData.append('noveltyDate', dateStart ?? '');
        formData.append('noveltyDateEnd', dateEnd ?? '');
        formData.append('noveltyTypeId', this.form.noveltyTypeId.value);
        formData.append('noveltySubtypeId', this.form.noveltySubTypeId.value);
        formData.append('noveltyJustify', this.form.noveltyJustify.value);
        formData.append('campaignId', this.form.campaignId.value);
        for (let i = 0; i < this.dataFileArray.length; i++) {
          formData.append('Files[]', this.dataFileArray[i]);
        }
        if (this.form.noveltySubTypeId.value == 2) {
          formData.append('eps[id]', '');
          formData.append('eps[name]', '');
        } else {
          formData.append('eps[id]', this.eps.id || '');
          formData.append('eps[name]', this.eps.name || '');
        }
        formData.append('empresa[id]', this.company.id);
        formData.append('empresa[name]', this.company.name);
        formData.append('other_diagnostic', this.form.other.value);
        formData.append('diagnostic_id', this.selectedDiagnostic.id == undefined ? '' : this.selectedDiagnostic.id);
        this.noveltiesService.createDisabilitiesNoveltie(formData)
          .subscribe(resp => {
            this.dialogRef.close('');
            this.alert.alertSuccess('Novedad creada!', 'En espera de validación por el área encargada');
            this.sending = false;
          }, (error) => {
            this.sending = false;
          });
        break;
      case this.permitCode:
        if (this.inactivesInCurrentYear[moment(this.form.noveltyDate.value).locale('es').format('YYYY-MM-DD')]) {
          this.alert.alertWarning('Proceso detenido', 'La fecha inicial no empezar en un día no hábil, por favor seleccione una fecha diferente');
          this.sending = false;
          return;
        }

        if (this.inactivesInCurrentYear[moment(this.form.noveltyDateEnd.value).locale('es').format('YYYY-MM-DD')]) {
          this.alert.alertWarning('Proceso detenido', 'La fecha final no puede terminar en un día no hábil, por favor seleccione una fecha diferente');
          this.sending = false;
          return;
        }

        const datePermitEnd: string | null = new DatePipe('en-US').transform(this.form.noveltyDateEnd.value, 'yyyy-MM-dd');
        const datePermit: string | null = new DatePipe('en-US').transform(this.form.noveltyDate.value, 'yyyy-MM-dd');
        formData.append('rrhhId', this.form.rrhhId.value);
        formData.append('noveltyDate', datePermit ?? '');
        formData.append('noveltyDateEnd', datePermitEnd ?? '');
        formData.append('noveltyTypeId', this.form.noveltyTypeId.value);
        formData.append('noveltyJustify', this.form.noveltyJustify.value);
        formData.append('campaignId', this.form.campaignId.value);
        for (let i = 0; i < this.dataFileArray.length; i++) {
          formData.append('Files[]', this.dataFileArray[i]);
        }
        this.noveltiesService.createPermitNoveltie(formData)
          .subscribe(resp => {
            this.dialogRef.close('');
            this.alert.alertSuccess('Novedad creada!', 'En espera de validación por el área encargada');
            this.sending = false;
          }, (error) => {
            this.sending = false;
          });
        break;
      case this.domesticCalamity:
        if (this.inactivesInCurrentYear[moment(this.form.noveltyDate.value).locale('es').format('YYYY-MM-DD')]) {
          this.alert.alertWarning('Proceso detenido', 'La fecha inicial no empezar en un día no hábil, por favor seleccione una fecha diferente');
          this.sending = false;
          return;
        }

        if (this.inactivesInCurrentYear[moment(this.form.noveltyDateEnd.value).locale('es').format('YYYY-MM-DD')]) {
          this.alert.alertWarning('Proceso detenido', 'La fecha final no puede terminar en un día no hábil, por favor seleccione una fecha diferente');
          this.sending = false;
          return;
        }
        const dateCalamityEnd: string | null = new DatePipe('en-US').transform(this.form.noveltyDateEnd.value, 'yyyy-MM-dd');
        const dateCalamity: string | null = new DatePipe('en-US').transform(this.form.noveltyDate.value, 'yyyy-MM-dd');
        formData.append('rrhhId', this.form.rrhhId.value);
        formData.append('noveltyDate', dateCalamity ?? '');
        formData.append('noveltyDateEnd', dateCalamityEnd ?? '');
        formData.append('noveltyTypeId', this.form.noveltyTypeId.value);
        formData.append('noveltyJustify', this.form.noveltyJustify.value);
        formData.append('campaignId', this.form.campaignId.value);
        for (let i = 0; i < this.dataFileArray.length; i++) {
          formData.append('Files[]', this.dataFileArray[i]);
        }

        this.noveltiesService.createDomesticCalamity(formData)
          .subscribe(resp => {
            this.dialogRef.close('');
            this.alert.alertSuccess('Novedad creada!', 'En espera de validación por el área encargada');
            this.sending = false;
          }, (error) => {
            this.sending = false;
          });
        break;
      case this.vacationExtemporalCode:

        if (this.inactivesInCurrentYear[moment(this.form.noveltyDate.value).locale('es').format('YYYY-MM-DD')]) {
          this.alert.alertWarning('Proceso detenido', 'La fecha inicial no empezar en un día no hábil, por favor seleccione una fecha diferente');
          this.sending = false;
          return;
        }

        if (this.inactivesInCurrentYear[moment(this.form.noveltyDateEnd.value).locale('es').format('YYYY-MM-DD')]) {
          this.alert.alertWarning('Proceso detenido', 'La fecha final no puede terminar en un día no hábil, por favor seleccione una fecha diferente');
          this.sending = false;
          return;
        }

        // validamos que para pedir las vacaciones sea a partir del dia siguiente
        if (moment(this.form.noveltyDate.value).locale('es').format('YYYY-MM-DD') >= moment(this.currentDate).format('YYYY-MM-DD')) {
          this.alert.alertWarning('Proceso detenido', 'Solo puede solicitar vacaciones a partir del día anterior');
          this.sending = false;
          return;
        }

        if (!this.validDates) {
          this.alert.alertWarning('Proceso detenido', 'Verifique las fechas de su solicitud de vacaciones');
          this.sending = false;
          return;
        }

        const vacationExtemporal = {
          campaignId: this.campaignId,
          rrhhId: this.userRrhhId,
          start_date: moment(this.form.noveltyDate.value).locale('es').format('YYYY-MM-DD'),
          end_date: moment(this.form.noveltyDateEnd.value).locale('es').format('YYYY-MM-DD'),
          justify: this.form.noveltyJustify.value,
          paidVacations: this.form.paidVacations.value
        };

        const user = this.authService.getUser();
        this.nameUser = user.rrhh.name;

        this.showModal(vacationExtemporal);
        break;
      case this.permitGrantedPreviousDaysCode:
        if (this.inactivesInCurrentYear[moment(this.form.noveltyDate.value).locale('es').format('YYYY-MM-DD')]) {
          this.alert.alertWarning('Proceso detenido', 'La fecha inicial no empezar en un día no hábil, por favor seleccione una fecha diferente');
          this.sending = false;
          return;
        }

        if (this.inactivesInCurrentYear[moment(this.form.noveltyDateEnd.value).locale('es').format('YYYY-MM-DD')]) {
          this.alert.alertWarning('Proceso detenido', 'La fecha final no puede terminar en un día no hábil, por favor seleccione una fecha diferente');
          this.sending = false;
          return;
        }

        const datePermitPreviusDays: string | null = new DatePipe('en-US').transform(this.form.noveltyDate.value, 'yyyy-MM-dd');
        const datePermitPreviusDaysEnd: string | null = new DatePipe('en-US').transform(this.form.noveltyDateEnd.value, 'yyyy-MM-dd');
        formData.append('rrhhId', this.form.rrhhId.value);
        formData.append('noveltyDate', datePermitPreviusDays ?? '');
        formData.append('noveltyDateEnd', datePermitPreviusDaysEnd ?? '');
        formData.append('noveltyTypeId', this.form.noveltyTypeId.value);
        formData.append('noveltyJustify', this.form.noveltyJustify.value);
        formData.append('campaignId', this.form.campaignId.value);
        for (let i = 0; i < this.dataFileArray.length; i++) {
          formData.append('Files[]', this.dataFileArray[i]);
        }
        this.noveltiesService.createPermitGrantedPreviousDaysNoveltie(formData)
          .subscribe(resp => {
            this.dialogRef.close('');
            this.alert.alertSuccess('Novedad creada!', 'En espera de validación por el área encargada');
            this.sending = false;
          }, (error) => {
            this.sending = false;
          });
        break;
      default:
        this.noveltiesService.createNovelties(this.noveltyForm.value)
          .subscribe(resp => {
            this.dialogRef.close('');
            this.alert.alertSuccess('Novedad creada!', 'En espera de validación por el área encargada');
            this.sending = false;
          }, (error) => {
            this.sending = false;
          });
        break;
    }
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Obtiene los tipos de novedad con sus subtipos
   */
  getNoveltyTypes(): void {
    this.noveltyTypes = [];
    this.noveltiesService.getNoveltyTypes(1)
      .subscribe(resp => {
        this.noveltyTypesOld = resp.data;
        for (let nType in resp.data) {
          if (resp.data.hasOwnProperty(nType)) {
            if (this.vacationCode == resp.data[nType].key && this.data.extraData.dateStr <= moment(this.currentDate).format('YYYY-MM-DD')) {
              continue;
            }
            if (this.vacationExtemporalCode == resp.data[nType].key && this.data.extraData.dateStr >= moment(this.currentDate).format('YYYY-MM-DD')) {
              continue;
            }
            if (this.disabilitiesCode == resp.data[nType].key && this.data.extraData.dateStr > moment(this.currentDate).format('YYYY-MM-DD')) {
              continue;
            }
            this.noveltyTypes.push(resp.data[nType]);
          }
        }
      });
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose limpiar propiedades
   */
  clear(): void {
    this.sending = false;
    this.noveltyForm.reset();
    this.ngOnInit();
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose obtiene los controles del form de creacion de novedades
   */
  get form(): any {
    return this.noveltyForm.controls;
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose limpiar posible opcion seleccionada
   */
  cleanPossibleSubtypeSelected(): any {
    this.form.noveltySubTypeId.reset();
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose Inicializar el calendario
   */
  initCalendar(): void {
    this.calendarOptions = {
      initialView: 'dayGridMonth',
      locales: [esLocale],
      locale: 'es',
      headerToolbar: {
        left: 'title',
        center: '',
        right: 'prev,next'
      },
      titleFormat: {
        month: 'long',
        year: 'numeric'
      },
      handleWindowResize: true,
      windowResizeDelay: 200
    };
  }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-06-21
   * Metodo eliminar un archivo del array
   */
  show() {
    if (this.editDate == false) {
      this.editDate = true;
    } else {
      this.editDate = false;
      const date = new Date(this.data.extraData.dateStr);
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
      this.form.noveltyDate.setValue(new Date(date));
    }
    this.validateFileSize();
  }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-05-26
   * Metodo eliminar un archivo del array
   */
  deleteFieldFile(indice) {
    this.dataFileArray.splice(indice, 1);
    this.showFileError = !this.showFileError;
    this.validateFileSize();
  }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-05-27
   * Metodo para la captura de archivos
   */
  public onChangeFile(e): void {
    const files = e.target.files;
    const type = ['image/jpeg', 'image/png', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', '.docx', 'application/pdf'];
    for (let i = 0; i < e.target.files.length; i++) {
      if (type.indexOf(e.target.files[i].type) !== -1) {
        this.dataFileArray.push(e.target.files.item(i));
      }
    }

    this.validateFileSize();
    if (this.dataFileArray.length > 0) {
      this.snackBar.open('Archivo cargado correctamente', 'cerrar', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 3500,
      });
    }
  }

  /**
 * @author Johan Soler
 * @createdate 2022-06-14
 * Metodo mostrar los modales de confirmacion
 */
  showModal(vacationExtemporal) {
    let str = '¿En verdad deseas solicitar <b>vacaciones en fechas' + '\n' 
            + 'pasadas</b>?, ten presente que únicamente se ' + '\n' 
            + 'pueden solicitar si el rango de fecha tiene la' + '\n' 
            + '<b>novedad "ausencia injustificada".</b> Recuerda que' + '\n' 
            + 'esta acción será definitiva y no se podrá deshacer.' + '\n \n \n' 
            + '<b>Resumen de la gestión</b>' + '\n' 
            + '<b>Trabajador:</b> ' + this.nameUser + '\n' 
            + '<b>Vacaciones:</b> ' + vacationExtemporal.start_date + ' al ' + vacationExtemporal.end_date;
    Swal.fire({
      title: '¿Estás seguro?',
      html: '<pre style="font-family: Poppins; font-size: 14px;">' + str + '</pre>',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.noveltiesService.createVacationExtemporal(vacationExtemporal)
          .subscribe(resp => {
            this.dialogRef.close('');
              this.alert.alertSuccessWithoutButton('Excelente','Se han guardado los cambios con éxito');
            this.sending = false;
          }, (error) => {
            this.sending = false;
          });
      }
    });
  }
    /**
   * @author Johan Soler 
   * @createdate 2022-06-06
   * Validar que el peso del archivo sea correcto
   */
  validateFileSize(){
    let generalSize = 0;
    for (let i = 0; i < this.dataFileArray.length; i++) {
      generalSize = this.dataFileArray[i].size;
    }
    this.currentSize = generalSize;

    if(generalSize < this.permittedSize){
      this.showFileError = false;
    }else {
      this.showFileError = true;
    }
  }

  /**
   * @author David Reinoso 
   * @createdate 16-ene-23
   * Consulta los dias de vacaciones disponibles
   */
  getDaysVacationsAvailable(){
    this.noveltiesService.getDaysVacationsAvailable('').subscribe(res => {
     if(res?.data >= 0){
      this.availableDays = res.data;
     }
    });
  }

  /**
   * @author David Reinoso 
   * @createdate 16-ene-23
   * Limpia las validaciones de los formularios pasados
   */
  cleanValidations(keys){
   for (let i = 0; i < keys.length; i++) {
    this.noveltyForm.get(keys[i]).clearValidators();
    this.noveltyForm.get(keys[i]).updateValueAndValidity();
   }
  }

}
