import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const calidad_url = environment.CALIDAD_URL;
const lang = JSON.parse(localStorage.getItem('locale')) ?? 'es';

@Injectable({
  providedIn: 'root'
})
export class AuditoriaService {
  protected dataUser;
  private _refresh$ = new Subject<void>();
 
  constructor(
    private http:HttpClient,
  ) { }

  get refresh$(){
    return this._refresh$;
  }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  getTypeService(){
    return this.http.get(`${calidad_url}getTipoServicio?lang=${lang}`) 
    .pipe(
      catchError(this.handleError)
      );
  }

  getTypeMonitoring(){
    return this.http.get(`${calidad_url}getTipoMonitoreo?lang=${lang}`)  
    .pipe(
      catchError(this.handleError)
      );
  }

  getTypeAudit(){
    return this.http.get(`${calidad_url}getTipoAuditoria?lang=${lang}`)    
    .pipe(
      catchError(this.handleError)
      );
  }

  getTypeSolution(){
    return this.http.get(`${calidad_url}getSolucion?lang=${lang}`)  
    .pipe(
      catchError(this.handleError)
      );
  }

  getTypegetOjt(){
    return this.http.get(`${calidad_url}getOjt?lang=${lang}`)  
    .pipe(
      catchError(this.handleError)
      );
  }

  getNoSolucion(){
    return this.http.get(`${calidad_url}getNoSolucion?lang=${lang}`)  
    .pipe(
      catchError(this.handleError)
      );
  }

  getAsesor(cedula){
    return this.http.get(`${calidad_url}getAsesor?cedula=${cedula}&lang=${lang}`)
    .pipe(
      catchError(this.handleError)
    );
  }

  createAuditoria(data){
    return this.http.post<any>(`${calidad_url}crearAuditoria`,data)  
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

  getRetroalimentar(params){
    return this.http.get(`${calidad_url}buscarAsesorAuditoria`,{params})
    .pipe(
      catchError(this.handleError)
    );
  }

  getRetroalimentacionById(id:number){
    return this.http.get(`${calidad_url}auditoriaById/${id}`)
    .pipe(
      catchError(this.handleError)
    );
  }

  updateRetroalimentacionById(id:number,data){
    return this.http.post<any>(`${calidad_url}updateRetroalimentacion/${id}`,data)
    .pipe(
      catchError(this.handleError)
    );
  }

  getAuditoriasByAsesor(params){
    return this.http.get(`${calidad_url}getAuditorias`,{params})
    .pipe(
      catchError(this.handleError)
    );
  }

  getPuntajes(){
    return this.http.get(`${calidad_url}totalAuditorias`)
    .pipe(
      catchError(this.handleError)
    );
  }

  updateAuditoria(id,data){
    return this.http.post<any>(`${calidad_url}updateAuditoriaAsesor/${id}`,data)
    .pipe(
      catchError(this.handleError)
    );
  }

  getAllAuditorias(params){
    return this.http.get(`${calidad_url}auditorias`,{params})
    .pipe(
      catchError(this.handleError)
    );
  }

  deleteAuditoria(id,data){
    return this.http.post<any>(`${calidad_url}deleteAuditoria/${id}`,data)
    .pipe(
      catchError(this.handleError),
      tap(()=>{
        this._refresh$.next();
      })
    );
  }

  getTypeMuestra(){
    return this.http.get(`${calidad_url}tipo_de_muestra?lang=${lang}`)
    .pipe(
      catchError(this.handleError)
    );

  }


}
