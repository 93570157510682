<div class="head col-6">
    <h1>Gestión de Solicitudes</h1>
    <p>Bienvenido al módulo de solicitudes.</p>
</div>

<div class="filter row">
 
    <div class="col-3">
        <mat-form-field appearance="fill">
            <mat-label>Filtrar por Código de Solicitud</mat-label>
            <input matInput #filterCode (keyup.enter)="filterByCode(filterCode.value)">
            <button mat-icon-button matSuffix (click)="filterByCode(filterCode.value)"><mat-icon>search</mat-icon></button>
        </mat-form-field>
    </div>
    <div class="col-3">
        <mat-form-field appearance="fill">
            <mat-label>Filtrar por Cargo Solicitado</mat-label>
            <input matInput #filterPosition (keyup.enter)="filterByPosition(filterPosition.value)">
            <button mat-icon-button matSuffix (click)="filterByPosition(filterPosition.value)"><mat-icon>search</mat-icon></button>
        </mat-form-field>
    </div>

    <p *ngIf="!showRequests">No tienes solicitudes disponibles</p>
</div>

<div class="table" *ngIf="showRequests">

    <table mat-table [dataSource]="dataSource" matSort >

        <!-- View -->
        <ng-container matColumnDef="view">
            <th class="start" mat-header-cell *matHeaderCellDef mat-sort-header> Ver </th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button (click)="viewDetails(row.id)" matTooltip="Ver solicitud">
                    <span  class="fi-rr-eye"></span></button>
            </td>
        </ng-container>

        <!-- Code -->
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="align-items: center;"> # de Solicitud </th>
            <td mat-cell *matCellDef="let row"> {{row.code}} </td>
        </ng-container>

        <!-- Campaign Column -->
        <ng-container matColumnDef="campaign">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Campaña </th>
            <td mat-cell *matCellDef="let row"> {{row.campaign.name}} </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cargo </th>
            <td mat-cell *matCellDef="let row"> {{row.position}} </td>
        </ng-container>

        <!-- PersonalRequest Column -->
        <ng-container matColumnDef="personalRequest">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Personal Requerido </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.personalRequest}} </td>
        </ng-container>

        <!-- TracingAvg Column -->
        <ng-container matColumnDef="tracingAvg">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> % Avance </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color">
                <circle-progress [percent]="row.tracingAvg" [radius]="20" [space]="-10" [outerStrokeGradient]="true" [outerStrokeWidth]="8" [outerStrokeColor]="'#2CABBC'" [outerStrokeGradientStopColor]="'#2CABBC'" [innerStrokeColor]="'#e7e8ea'" [innerStrokeWidth]="8"
                    [title]="row.tracingAvg | number:'1.0-0' " [titleFontSize]="10" [animateTitle]="true" [animationDuration]="1000" [showUnits]="true" [showSubtitle]="false" [showBackground]="false" [clockwise]="true" [startFromZero]="false">
                </circle-progress>
            </td>
        </ng-container>

        <!-- State Column -->
        <ng-container matColumnDef="state">
            <th class="end" mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.state}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="7">No hay datos que coincidan</td>
        </tr>
    </table>

    <mat-paginator  [length]="length" [pageSize]="pageSize" (page)="getPaginatorData($event)" showFirstLastButtons></mat-paginator>

</div>
