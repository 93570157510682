<div class="admin-roles">
  <div class="header">
    <h1>Eliminación de registros</h1>
    <p>En este espacio podrás eliminar los registros de las <br> encuestas, no olvides, “El respeto y empatía son
      clave”.</p>
  </div>
  <form [formGroup]="FrmFormulario">
    <div class="row">
      <mat-form-field class="col-4" appearance="fill">
        <mat-label>Versión CRM</mat-label>
        <mat-select matInput formControlName="crm" (selectionChange)="onChangeSelectCrmVersion()">
          <mat-option [value]="1">CRM V1</mat-option>
          <mat-option [value]="2">CRM V2</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-4" appearance="fill">
        <mat-label>Estados</mat-label>
        <mat-select matInput formControlName="status">
          <mat-option [value]="0">Inactivos</mat-option>
          <mat-option [value]="1">Activos</mat-option>
        </mat-select>
        <mat-error *ngIf="FrmFormulario.get('id_formulario').hasError('required')">Por favor seleccione una
          opción.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-4">
        <mat-label>Rango de Fecha</mat-label>
        <mat-date-range-input [rangePicker]="rango">
          <input matStartDate placeholder="Fecha inicio" formControlName="fechaInicial">
          <input matEndDate placeholder="Fecha fin" formControlName="fechaFinal">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rango">
          <mat-icon matDatepickerToggleIcon><i class="fi-rr-calendar"></i></mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker #rango></mat-date-range-picker>
        <mat-error *ngIf="FrmFormulario.get('fechaFinal').hasError('required')">Por favor seleccione un rango de
          fecha.</mat-error>
      </mat-form-field>
      <mat-form-field class="col-4" appearance="fill">
        <mat-label>Nombre del formulario</mat-label>
        <mat-select matInput formControlName="id_formulario">
          <mat-option *ngFor="let item of formsCrm" [value]="item.id">{{item.name_form}}</mat-option>
        </mat-select>
        <mat-error *ngIf="FrmFormulario.get('id_formulario').hasError('required')">Por favor seleccione una
          opción.</mat-error>
      </mat-form-field>
      <mat-form-field class="col-2" appearance="fill">
        <mat-label>Id registro</mat-label>
        <input matInput type="number" id="telefono" formControlName="idRegistro">
        <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
      </mat-form-field>
      <div class="col-2">
        <button mat-raised-button color="primary" style="height:3.6em;width: 12em ; box-shadow: none;"
          (click)="get_tipificaciones()">
          Buscar
          <mat-icon style="font-size: 1.2em; margin-top: .4em;"><i class="fi-rr-search"></i></mat-icon>
        </button>
      </div>
    </div>
  </form>
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="accion">
        <th class="start" mat-header-cell *matHeaderCellDef class="negrita">Acciones</th>
        <td mat-cell *matCellDef="let row">
          <button *ngIf="row.status === 1" mat-icon-button (click)="ConfirmEliminar(row.id, 0)">
            <i class="fi-rr-trash"></i>
          </button>
          <button *ngIf="row.status === 0" mat-icon-button (click)="ConfirmEliminar(row.id, 1)">
            <i class="fi-rr-check"></i>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="negrita">Id Registro</th>
        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
      </ng-container>
      <ng-container matColumnDef="adviser">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="negrita">Asesor que gestiono</th>
        <td mat-cell *matCellDef="let row"> {{row.adviser}} </td>
      </ng-container>
      <ng-container matColumnDef="form_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="negrita">Nombre del formulario</th>
        <td mat-cell *matCellDef="let row"> {{row.form_name}} </td>
      </ng-container>
      <ng-container matColumnDef="management_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="negrita">Fecha - Hora</th>
        <td mat-cell *matCellDef="let row"> {{row.management_date}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="6">No hay datos</td>
      </tr>
    </table>
    <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)"
      [pageSizeOptions]="pageSizeOptions">
    </mat-paginator>
  </div>
</div>