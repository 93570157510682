import { Component, OnInit, Inject } from '@angular/core';
import { ToWords } from '../../../../../../core/to-words/to-words';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { OrdenesService } from '../../../../services/rest/ordenes.service';
import { DestroyComponentService } from '../../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProveedoresService } from '../../../../../portal/services/proveedores.service';
import { OrdenCompra } from '../../../Class/orden-compras';
import { MatStepper } from '@angular/material/stepper';


@Component({
  selector: 'app-orden-compra-edit',
  templateUrl: './orden-compra-edit.component.html',
  styleUrls: ['./orden-compra-edit.component.sass']
})
export class OrdenCompraEditComponent implements OnInit {

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isEditable = true;
  toWords = new ToWords();
  valorFinal=0;
  valorIva =0;
  itemsOrder:any[]=[];
  proveedor: any;

  constructor(
    private formBuilder                     : FormBuilder,
    private form                            : FormBuilder,
    private proveedorService                : ProveedoresService,
    private ordenService                    : OrdenesService,
    private destroyService                  : DestroyComponentService,
    private alertsService                   : AlertsService,
    public ordenesService                   : OrdenesService,
    private dialogRef                       : MatDialogRef< OrdenCompraEditComponent >,
    @Inject(MAT_DIALOG_DATA) public data    : any
    ) { }

  ngOnInit(): void {
    const proveedor={
      nit:this.data.ordenCompra.provider.nit
    }
   this.proveedorService.getProveedores(1,proveedor).subscribe((prov)=>{
     if (prov.data.length>0) {
       this.proveedor = prov.data[0];

     } else {
       this.proveedor= "No se ha encontrado proveedor."
     }
   });
    this.crearFromulario();
    this.setEdit();
  }

  crearFromulario(){
    this.firstFormGroup = this.formBuilder.group({

      items: this.form.array([])
    });
    this.secondFormGroup = this.formBuilder.group({
      observations        : new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
      sub_total           : new FormControl('', [Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
      discount            : new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
      iva                 : new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
      total               : new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
      value_in_letters    : new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
      delivery_date       : new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
      pay                 : new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
      payment_condition   : new FormControl('', [Validators.required]),
      payment_deb         : new FormControl(''),
      credit_condition    : new FormControl(''),
      pay_value           : new FormControl({value:'', disabled:true},[]),
      warranty            : new FormControl('', [Validators.required]),
      policy              : new FormControl('', [Validators.required]),
    });
  }

  get error(): any { return this.secondFormGroup.controls; }
  get errorItem():any{ return this.firstFormGroup.controls}

  get items(): FormArray {
    return this.firstFormGroup.get('items') as FormArray;
  }

   /**
    * @author Daniel Martinez
    * @createdate 2021-02-16
    * Metodo que estable las validaciones del formarray dentro del formulario
    */
    newItems(): void{
      const newItems = this.form.group({
        id:new FormControl('',[]),
        description: new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
        specs: new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
        unit: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.minLength(1)]),
        unit_value: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.minLength(1)]),
        selectIva:new FormControl('', [Validators.required]),
        valorIva: new FormControl({value:'', disabled:true},[]),
        total_value: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.minLength(1)]),
      });
      this.items.push(newItems);
    }

    setEdit(){
      this.ordenService.getOrderItems(this.data.ordenCompra.id).subscribe((resp)=>{
        this.itemsOrder=resp.data;

          this.secondFormGroup.controls.pay.setValue(this.data.ordenCompra.pay);
          this.secondFormGroup.controls.payment_condition.setValue(this.data.ordenCompra.payment_condition);
          this.secondFormGroup.controls.payment_deb.setValue(this.data.ordenCompra.payment_deb);
          this.secondFormGroup.controls.credit_condition.setValue(this.data.ordenCompra.credit_condition);
          this.secondFormGroup.controls.pay_value.setValue(this.data.ordenCompra.pay_value);
          this.secondFormGroup.controls.warranty.setValue(this.data.ordenCompra.warranty);
          this.secondFormGroup.controls.policy.setValue(this.data.ordenCompra.policy);
          this.secondFormGroup.controls.observations.setValue(this.data.ordenCompra.observations);
          this.secondFormGroup.controls.sub_total.setValue(this.data.ordenCompra.sub_total);
          this.secondFormGroup.controls.discount.setValue(this.data.ordenCompra.discount);
          this.secondFormGroup.controls.iva.setValue(this.data.ordenCompra.iva);
          this.secondFormGroup.controls.total.setValue(this.data.ordenCompra.total);
          this.secondFormGroup.controls.value_in_letters.setValue(this.data.ordenCompra.value_in_letters);
          this.secondFormGroup.controls.delivery_date.setValue(this.data.ordenCompra.delivery_date);
          this.itemsOrder.forEach((element, index) => {
            this.newItems();
            this.items.controls[index].get('id').setValue(element.id);
            this.items.controls[index].get('specs').setValue(element.specs);
            this.items.controls[index].get('description').setValue(element.description);
            this.items.controls[index].get('unit').setValue(element.unit);
            this.items.controls[index].get('unit_value').setValue(element.unit_value);
            this.items.controls[index].get('valorIva').setValue(element.iva);
            this.items.controls[index].get('total_value').setValue(element.total_value);

          });

      });
    }
  /**
  * validar la existencia de al menos un item al avance.
  */
  validarItems(){
    if (this.items.length===0) {
      this.newItems();
    }
  }

    saveOrder(){
      const orden               = new OrdenCompra();
      orden.idPurchase          = this.data.ordenCompra.id;
      orden.observations        = this.secondFormGroup.value.observations;
      orden.sub_total           = this.secondFormGroup.value.sub_total;
      orden.discount            = this.secondFormGroup.value.discount;
      orden.iva                 = this.secondFormGroup.value.iva;
      orden.calculated_iva      = this.valorIva;
      orden.total               = this.secondFormGroup.value.total;
      orden.value_in_letters    = this.secondFormGroup.value.value_in_letters;
      orden.delivery_date       = this.secondFormGroup.value.delivery_date;
      orden.warranty            = this.secondFormGroup.value.warranty;
      orden.pay                 = this.secondFormGroup.value.pay;
      orden.payment_deb         = this.secondFormGroup.value.payment_deb;
      orden.payment_condition   = this.secondFormGroup.value.payment_condition;
      orden.credit_condition   = this.secondFormGroup.value.credit_condition;
      orden.pay_value           = (this.secondFormGroup.value.pay_value==undefined ? '':this.secondFormGroup.value.pay_value);
      orden.policy              = this.secondFormGroup.value.policy;
      orden.request_id          = this.data.ordenCompra.request_id;
      orden.proveedor_nit       = this.proveedor.nit;
      orden.company_id          = this.data.ordenCompra.company.id;
      orden.PurchaseOrderItem   = this.firstFormGroup.value.items;

      this.ordenService.updateOrder(orden,this.data.ordenCompra.id).subscribe(resp=>{
        this.alertsService.alertSuccess('Guardado', resp.data);
      });
    }

    calcularValorTotal(indice){
      let cantidad = this.firstFormGroup.value.items[indice].unit;
      let valor=this.firstFormGroup.value.items[indice].unit_value;

      let valorTotal = (cantidad == undefined ? 0 :cantidad)* (valor == undefined ? 0:valor);

      this.firstFormGroup.controls.items["controls"][indice].controls.total_value.setValue(valorTotal);


    }

    cambioEstado(event, index){


      if (event === 100){
        this.firstFormGroup.controls.items["controls"][0].controls.valorIva.enable();
      } else {
        this.firstFormGroup.controls.items["controls"][0].controls.valorIva.disable();
      }

    }

    cambioPagoValue(event){
      if (event === 'Abono'|| event === 'Anticipo' || event === 'Credito'){
        this.secondFormGroup.controls.pay_value.enable();
      } else {
        this.secondFormGroup.controls.pay_value.disable();
      }
    }

    calcularValores(){
      let valorTotal=0;
      this.valorIva=0;

      this.firstFormGroup.get('items').value.forEach((element, index) => {
        valorTotal+=element['total_value'];
        if(element['selectIva']===100){

          this.valorIva+=(element['total_value']*element['valorIva'])/100;
        }else {
          this.valorIva+=(element['total_value']*element['selectIva'])/100;
        }

      });
      this.valorFinal= valorTotal+this.valorIva;
      const valorfinalletras = this.toWords.convert( this.valorFinal);
      this.secondFormGroup.controls.sub_total.setValue(valorTotal);
      this.secondFormGroup.controls.discount.setValue(0);
      this.secondFormGroup.controls.iva.setValue(this.valorIva);
      this.secondFormGroup.controls.total.setValue( this.valorFinal);
      this.secondFormGroup.controls.value_in_letters.setValue(valorfinalletras);
    }



    calculardiscount(){
      const descuento= this.secondFormGroup.get('discount').value;
      const iva =this.secondFormGroup.get('iva').value;

      const valorFinal = this.valorFinal-descuento+iva;
      const valorfinalletras = this.toWords.convert(valorFinal);
      if (valorFinal> 0 ){
        this.secondFormGroup.controls.total.setValue(valorFinal);
        this.secondFormGroup.controls.value_in_letters.setValue(valorfinalletras);
      } else{
        this.alertsService.alertError('Error', 'No se puede aplicar un descuento superior al valor total')
      }
    }


    next(stepper: MatStepper){
      stepper.next();
    }

    editOrder () {
      const estado ={
        state:5
      }
      this.ordenesService.updateState(estado, this.data.ordenCompra.id ).subscribe((resp)=>{
        this.alertsService.alertSuccess('Orden Enviada',resp.data);
        this.dialogRef.close();
      },
      error => {
        this.alertsService.alertWarning('Atención',error.error.error);
      }
      );

    }

}
