export interface FormStructure {
    id :number;
    name_form : string;
    filters :any[];
    externalNotifications:boolean;
    section?:[
      {
        name_section:string,
        fields: any,
        type_section: number,
        collapse: boolean,
        duplicar: boolean
      },
    ],
    sections?:[
      {
        name_section:string,
        fields: any,
        type_section: number,
        collapse: boolean,
        duplicar: boolean
      },
    ],
    vicidial_key: string;
 }


class Tooltip {
  have: boolean;
  content: string;
}

export class QuestionBase<T> {
  id: number;
  type: string;
  key: string;
  controlType: string;
  label: string;
  value: T;
  disabled: boolean;
  required: boolean;
  canAdd: boolean;
  options: {id: string, name: string}[];
  minLength: number;
  maxLength: number;
  inReport: boolean;
  cols: number;
  preloaded: boolean;
  isFather: boolean;
  dependencies: any[];
  editRoles: any[];
  seeRoles: any[];
  tooltip: {have: boolean, content: string};
  nameFile: string;

  constructor(options: {
      id?: number;
      type?: string;
      key?: string;
      controlType?: string;
      label?: string;
      value?: T;
      disabled?: boolean;
      required?: boolean;
      canAdd?: boolean
      options?: {id: string, name: string}[];
      minLength?: number;
      maxLength?: number;
      inReport?: boolean;
      cols?: number;
      preloaded?: boolean;
      isFather?: boolean;
      dependencies?: any[]
      editRoles?: any[];
      seeRoles?: any[];
      tooltip?: {have: boolean, content: string};
      nameFile?: string;
    } = {}) {
    this.id = options.id || 0;
    this.type = options.type || 'textbox';
    this.key = options.key || '';
    this.controlType = options.controlType || '';
    this.label = options.label || '';
    this.value = options.value;
    this.disabled= !!options.disabled;
    this.required = !!options.required;
    this.canAdd = !!options.canAdd;
    this.options = options.options || [];
    this.minLength = options.minLength || 1;
    this.maxLength = options.maxLength || 50 ;
    this.inReport = !!options.inReport;
    this.cols= options.cols || 1;
    this.preloaded= !!options.preloaded;
    this.isFather= !!options.isFather;
    this.dependencies= options.dependencies || [];
    this.editRoles= options.editRoles || [];
    this.seeRoles= options.seeRoles || [];
    this.tooltip= options.tooltip || {have: false, content: ''};
    this.nameFile= options.nameFile || '';

    }
  }

