import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AssingUserService } from '../assing-user.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { inject } from '@angular/core/testing';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-assing-user',
  templateUrl: './assing-user.component.html',
  styleUrls: ['./assing-user.component.sass']
})
export class AssingUserComponent implements OnInit {

  /**
   * Data debe contenter { role : '' }
   */
   usersFound: any[]=[];
   documentId = new FormControl('',[Validators.required,Validators.minLength(2),Validators.maxLength(15)]); 
  constructor(private fb:FormBuilder,
              private assingService:AssingUserService,
              private matDialogRef:MatDialogRef<AssingUserComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private notification:AlertsService) { }

  ngOnInit(): void {


  }

 
  searchUser(){
    this.assingService.getUser( this.documentId.value).subscribe( (resp:any) => {
      this.usersFound = resp;
    })
  }

  selectUser(user){
    this.notification.alertConfirm(`¿Estas seguro que desea seleccionar al usuario ${user.name}?`).then( e => {
      if(e.isConfirmed){
        this.assingService.assingUserRole(user.id,this.data.role).subscribe( (userResp:any) => {
          this.matDialogRef.close({'user':userResp,'role':this.data.role});
        })
      }
    })
  }


}


  /**
   * Pasos para ser usado.
   * Usar como cualquier componente.
   * Despues de seleccionar el usuario el mismo sera retornado al
   * compoenente padre al igual que el rol que requiere el usuario
   * es decir, si el rol es ([key]: formador) sera el rol que va a llevar
   * el usuario seleccionado.
   *
   * El mismo rol debe ser confirmado para asignar si es necesario
   * el endpoint sera el siguiente:
   *
   * assign/hired/user/{userId}/{role}
   *
   * Este endpoint es solo para validar y crear role la respuesta
   * seran los valores del hiredUser con candidate data
   *
   */
