import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { SolicitudesService } from 'src/app/modules/compras/services/rest/solicitudes.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-block-requests',
  templateUrl: './block-requests.component.html',
  styleUrls: ['./block-requests.component.sass']
})
export class BlockRequestsComponent implements OnInit {

  constructor(
    private alert: AlertsService,
    private serviceSolicitudes: SolicitudesService,
    private modal: MatDialogRef<BlockRequestsComponent>
  ) { }

  ngOnInit(): void {
   /*  this.alert.alertQuestion(
      'Prueba',
      'seguro esta bien'
    ) */

  }

  dateBlock = new FormGroup({
    inicial: new FormControl('',Validators.required),
    final: new FormControl('',Validators.required)
  })



  saveDate () {
    if(this.dateBlock.valid){
      const dates = {
        dateInit: moment(this.dateBlock.get('inicial').value).format('YYYY-MM-DD'),
        dateEnd: moment(this.dateBlock.get('final').value).format('YYYY-MM-DD')
      }

        this.alert.alertWarning(
          '¿Estás seguro?',
          `¿En verdad deseas bloquear la creación de solicitudes <b>${dates.dateInit}</b> del al <b>${dates.dateEnd}</b>.?, recuerda que esta acción será definitiva y no se podrá deshacer.`,
        ).then(respuesta=>{
          if(respuesta.isConfirmed){
            this.serviceSolicitudes.blockRequest(dates).subscribe(rest=>{
              this.alert.alertSuccess(
                '¡Excelente!',
                rest.data
              )
              this.modal.close()
            })
          }
        })
    }
  }

}
