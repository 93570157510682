<div class="mt-4">
  <div class="row col-12">
    <mat-form-field appearance="fill" class="col-3">
      <mat-label>{{config.labels.filtrarPorAuditado}}</mat-label>
      <input matInput [(ngModel)]="filtroAsesor" (blur)="filtrar()">
      <mat-icon matSuffix (click)="filtrar()"><i class="fi-rr-search"></i></mat-icon>
    </mat-form-field>
    <mat-form-field appearance="fill" class="col-2">
      <mat-label>{{config.labels.filtrarPorCedula}}</mat-label>
      <input matInput type="number" [(ngModel)]="filtroCedula" (blur)="filtrar()">
      <mat-icon matSuffix (click)="filtrar()"><i class="fi-rr-search"></i></mat-icon>
    </mat-form-field>
    <mat-form-field appearance="fill" class="col-2">
      <mat-label>{{config.labels.fecha}}</mat-label>
      <mat-date-range-input [rangePicker]="rango" [formGroup]="filtroFecha">
        <input matStartDate placeholder="{{config.labels.fechaInicio}}" formControlName="fechaInicial">
        <input matEndDate placeholder="{{config.labels.fechaFin}}" formControlName="fechaFinal" (dateChange)="filtroFechas()">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="rango">
        <mat-icon matDatepickerToggleIcon><i class="fi-rr-calendar"></i></mat-icon>
      </mat-datepicker-toggle>
      <mat-date-range-picker #rango></mat-date-range-picker>
    </mat-form-field>
    <mat-form-field appearance="fill" class="col-2">
      <mat-label>{{config.labels.gestion}}</mat-label>
      <mat-select [(value)]="filtroGestion" (selectionChange)="filtrar()">
        <mat-option>-------</mat-option>
        <mat-option value="1">{{config.labels.retroalimentada}}</mat-option>
        <mat-option value="0">{{config.labels.sinRetroalimentacion}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="col-2">
      <button mat-raised-button color="primary" style="height:3.6em;width: 12em ; box-shadow: none;"
              (click)="getRetroalimentaciones()">
        {{config.labels.buscar}}
        <mat-icon style="font-size: 1.2em; margin-top: .4em;"><i class="fi-rr-search"></i></mat-icon>
      </button>
    </div>
  </div>
  <div>
    <app-tabla-retroalimentar [dataSource]="datosTabla"
                              *ngIf="this.datosTabla.length > 0"
                              [length]="length" [pageSize]="pageSize" [page]="page"
                              (paginacion)="getRetroalimentaciones($event.pagina,$event.registros)"></app-tabla-retroalimentar>
  </div>
</div>
