import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../core/services/rest/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AssingUserService {

  private server =  environment.RRHH_URL;
  constructor(private http: HttpClient) {

            }

          getUser( documentId){
            return this.http.get(`${this.server}users/hired/${documentId}`);
          }
          assingUserRole(hiredUserId,role){
            return this.http.get(`${this.server}assign/hired/user/${hiredUserId}/${role}`);
          }
}
