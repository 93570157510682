<div class="app-landge-page" id="landge-page">
    <div id="intro"> 
        <section id="text-content">
            <p id="role">CRM Comercial</p>
            <b id="title">¡Bienvenid@ <br>{{this.userName}}!</b>
            <p id="info">En el módulo de <strong>CRM Comercial</strong>, aquí <br>
                 podrás gestionar clientes, portunidades y<br>
                otros proceos realacionados con el área<br>
                comercial.</p>
    
            <!-- <button id="onboarding-button" mat-stroked-button color="primary">Quiero ver el onboarding</button> -->
        </section>
    
        <section id="image-content">
            <div id="image"></div>
        </section>
    </div>
</div>
<!-- <router-outlet></router-outlet> -->