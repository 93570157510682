<div class="landing">

    <div class="row" >
        <div class="col-6">
          <div class="landing-div-text">            <br>
            <br>
            <br>
            <p class="landing-tittle">Coaching</p>
            <br>
            <p class="landing-welcome">¡Bienvenid@<br> {{userName.rrhh.name | titlecase}}!</p>
            <br>
            <div class="col-8 mt-3">
              <p class="landing-sub-tittle">
                Este es el módulo de <b>Coaching</b> donde podrás gestionar los acompañamientos a los agentes y validar su progreso.
              </p>
            </div>
            <br>
            <div style="width: 429px;">
                <button class="btnlanding" mat-stroked-button color="primary" (click)="onBoardingCoach()" [disabled]="true">Quiero ver el onboarding</button>
            </div>
          </div>
        </div>

        <div class="col-6" style="text-align: right;">
            <img src="../../../assets/images/landing-pages/coach/Ilustración_Self-Management.svg" style="height:90%;" alt="">
        </div>
    </div>
</div>