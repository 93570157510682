<div class="inactivesDays">
  <div class="header">
    <h1>Días Inactivos</h1>
    <p>En este espacio podrás gestionar los días inactivos <br>no olvides,"El respeto y la
      empatía son clave"</p>
  </div>

  <div>

    <mat-form-field appearance="fill" class="col-3 filter">
      <mat-label>Filtrar por año</mat-label>
      <mat-select [formControl]="yearControl">
        <mat-option value="">Seleccionar</mat-option>
        <mat-option *ngFor="let item of years" [value]="item.id">
          {{item?.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="col-3 filter">
      <mat-label>Filtrar por mes</mat-label>
      <mat-select [formControl]="monthControl">
        <mat-option value="">Seleccionar</mat-option>
        <mat-option *ngFor="let item of months" [value]="item.id">
          {{item?.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-3 filter" appearance="fill">
      <mat-label>Filtrar por descripción</mat-label>
      <input matInput type="text" #input autocomplete="off"
             (keydown.enter)="searchByTextFilter($event.target.value)">
      <mat-icon matSuffix (click)="searchByTextFilter($event.target.value)"><span class="fi-rr-search"></span></mat-icon>
    </mat-form-field>

    <button mat-raised-button color="primary" (click)="createInactiveDate()" class="download">Agregar fecha
      <span class="fi-rr-edit"></span>
    </button>

    <div class="table">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="acciones">
          <th class="start" mat-header-cell *matHeaderCellDef>Acciones</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="deleteDate(element)">
              <i class="fi-rr-trash"></i>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="inactive_date">
          <th mat-header-cell *matHeaderCellDef> Fecha Inactiva</th>
          <td mat-cell *matCellDef="let element"> {{ element.inactive_date }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Descripción</th>
          <td mat-cell *matCellDef="let element">{{ element.description }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="3">No hay datos que coincidan con los filtros</td>
        </tr>

      </table>
      <mat-paginator [length]="paginatorObject.length" [pageSize]="paginatorObject.pageSize" (page)="pageEvent($event)"
                     [pageSizeOptions]="paginatorObject.pageSizeOptions">
      </mat-paginator>

    </div>

  </div>

</div>
