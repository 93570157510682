import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfileService } from '../../services/rest/profile.service';
import { AlertsService } from '../../../../shared/alerts/alerts.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass']
})
export class ChangePasswordComponent implements OnInit {

  formPassword:FormGroup;
  showPassword = false;
  constructor(private fb:FormBuilder,private profileService:ProfileService,
              private dialogRef:MatDialogRef<ChangePasswordComponent>,
              private alertServices:AlertsService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.formPassword = this.fb.group({
      'password':['',
          [
            this.passwordValidate(),
            Validators.required,
            Validators.maxLength(15),
            Validators.minLength(5)]
          ],
      'confirmPassword':['',
          [
            this.passwordValidate(),
            Validators.required,
            Validators.maxLength(15),
            Validators.minLength(5),
            this.matchValues('password')]
          ]
    })

  }
  /**
   * Metodo que permite validar si la  segunda contraseña coincide con la anterior
   *
   * @param matchTo Parametro al que se quiere hacer referencia, por ejemplo password
   * @returns isMatching boolean
   * @author Carlos Galindez
   */

  matchValues( matchTo: string ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
}
  get error(): any { return this.formPassword.controls; }

   /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que se encarga de las validaciones del campo password
   */
    passwordValidate(): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
        if (!/[A-Z]/.test(control.value)) {
          return { hasCapitalCase: { value: control.value } };
        }
        else if (!/[a-z]/.test(control.value)) {
          return { hasSmallCase: { value: control.value } };
        }
        else if (!/[!@#$%^&*()_+=[{};':"|,.<>/?/{};':"|,.<>/?-]/.test(control.value)) {
          return { hasSpecialCharacters: { value: control.value } };
        }
        else if (!/\d/.test(control.value)) {
          return { hasNumber: { value: control.value } };
        }
        return null;
      };
    }
    /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que evalua en tiempo real los caracteres ingresados en el input, no deja ingresar numeros ni espacios
   * @param event evento del keypress del input usuario
   */
  omitSpecialChar(event): any{
    let k;
    k = event.charCode;
    return((k > 32 && k < 126));
  }

  /**
   * Metodo que consume el servicio y permite cambiar la contraseña
   * @author Carlos Galindez
   */
  changePassword(){
    if(this.formPassword.valid){
      this.profileService.changePassword(this.data.userId,this.formPassword.value.password).subscribe( (resp)  => {
          this.alertServices.alertSuccess('Su contraseña fue actualizada con exito','');
          this.dialogRef.close();
      })
    }

  }



}
