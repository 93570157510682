<div class="head col-6">
  <h1>Administrador contratos</h1>
  <p>En este espacio podrás administrar las plantillas (minutas) de los diferentes tipos de contratos en el sistema, podrás actualizar sus versiones, habilitarlas o desactivarlas, también verás el histórico de las modificaciones realizadas y administrar las variables para dinamizar las plantillas (minutas)</p>
</div>

<mat-tab-group class="head" (selectedTabChange)="onTabChange()">
  <mat-tab label="Plantillas">
    <app-templates-list></app-templates-list>
  </mat-tab>
  <mat-tab label="Historial">
    <app-template-history></app-template-history>
  </mat-tab>
  <mat-tab label="Variables">
    <app-tags-list></app-tags-list>
  </mat-tab>
</mat-tab-group>

