import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, FormBuilder, FormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import { SolicitudesService } from '../../../../services/rest/solicitudes.service';
import { DestroyComponentService } from '../../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import { AuthService } from '../../../../../../core/services/rest/auth.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CategoriaService } from '../../../../services/rest/categoria.service';
import { CompaniaService } from '../../../../services/rest/compania.service';
import { TipoProductoService } from '../../../../services/rest/tipo-producto.service';
import { startWith, map } from 'rxjs/operators';
import { Solicitud } from '../../../Class/solicitud';

@Component({
  selector: 'app-crear-solicitud',
  templateUrl: './crear-solicitud.component.html',
  styleUrls: ['./crear-solicitud.component.sass']
})
export class CrearSolicitudComponent implements OnInit {

  solicitudesForm: FormGroup;
  filteredOptionsTipoProducto: Observable<any[]>;
  filteredOptionsCategoria: Observable<any[]>;
  filteredOptionsCompania: Observable<any[]>;
  compania:any;
  categoria:any;
  tipoProducto:any;
  filesUpload: any[] =[];
  uploadFiles: any[] = [];
  maxFile = false;
  verSolicitud= false;
  usuario


  constructor(private solicitudService: SolicitudesService,
    private categoriaService: CategoriaService,
    private companiaService:CompaniaService,
    private tipoProductoService:TipoProductoService,
    private destroyService: DestroyComponentService,
    private form: FormBuilder,
    private alertsService: AlertsService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
      this.usuario= this.authService.getUser();
      this.formControl();
      this.getData();
      this.newItems();

      if(this.data.type ==='Editar'){
        this.verSolicitud=true;
        this.setEdit()

      }

    }
    /**
    * @author Jose Vicente Silva
    * @createdate 2021-08-27
    * filtra por nombre, se usa para el autocompletable
    * @param value valor a filtrar
    */
    private getData(){
      this.companiaService.getCompany().subscribe(comp=>{
        this.compania = comp.data;

        if(this.data.type ==='Editar'){
          this.solicitudesForm.controls.companias.setValue(Number(this.data.solicitud.company_id));
        }

        this.filteredOptionsCompania = this.solicitudesForm.get('companias').valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(companias => companias ? this._filterCompany(companias) : this.compania.slice())
          );
        });
      this.categoriaService.getCategoria().subscribe(cat=>{
        this.categoria = cat.data;
        if(this.data.type ==='Editar'){
          this.solicitudesForm.controls.categorias.setValue(Number(this.data.solicitud.category_id));
        }
        });
      this.tipoProductoService.getTipoProducto().subscribe(tipos=>{
        this.tipoProducto = tipos.data;
        if(this.data.type ==='Editar'){
          this.solicitudesForm.controls.tipoProductos.setValue(Number(this.data.solicitud.product_type_id));
        }
        this.filteredOptionsTipoProducto = this.solicitudesForm.get('tipoProductos').valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(tipoProducto => tipoProducto ? this._filterProductType(tipoProducto) : this.tipoProducto.slice())
          );
        });


      }

  /**
  * @author Jose Vicente Silva
  * @createdate 2021-08-27
  * filtra por nombre, se usa para el autocompletable
  * @param value valor a filtrar
  */
  private _filterCompany(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.compania.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  /**
  * @author Jose Vicente Silva
  * @createdate 2021-08-27
  * filtra por nombre, se usa para el autocompletable
  * @param value valor a filtrar
  */
  private _filterCategory(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.categoria.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  /**
  * @author Jose Vicente Silva
  * @createdate 2021-08-27
  * filtra por nombre, se usa para el autocompletable
  * @param value valor a filtrar
  */
  private _filterProductType(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.tipoProducto.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
  * @author Jose Vicente Silva
  * @createdate 2021-08-27
  * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
  */
  displayFnTipoProducto(id: number): string{
    if (!id) { return ''; }
    let index = this.tipoProducto.findIndex(resp => resp.id === id);
    return this.tipoProducto[index].name;

  }

  /**
  * @author Jose Vicente Silva
  * @createdate 2021-08-27
  * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
  */
  displayFnCategoria(id: number): string{
    if (!id) { return ''; }
    let index = this.categoria.findIndex(resp => resp.id === id);
    return this.categoria[index].name;

  }
  /**
  * @author Jose Vicente Silva
  * @createdate 2021-08-27
  * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
  */
  displayFnCompania(id: number): string{
    if (!id) { return ''; }
    let index = this.compania.findIndex(resp => resp.id === id);
    return this.compania[index].name;

  }

  get error(): any { return this.solicitudesForm.controls; }

  get items(): FormArray {
    return this.solicitudesForm.get('items') as FormArray;
  }

  /**
  * @author Daniel Martinez
  * @createdate 2021-02-04
  * Metodo que setea los valores al momento de editar
  */
  setEdit(): void {

    this.solicitudesForm.controls.titulo.setValue(this.data.solicitud.title);
    this.solicitudesForm.controls.proyecto.setValue(this.data.solicitud.project);
    this.solicitudesForm.controls.descripcion.setValue(this.data.solicitud.description);

    this.data.solicitud.request_items.forEach((element, index) => {
      this.newItems();
      this.items.controls[index].get('detalle').setValue(element.detail);
      this.items.controls[index].get('cantidad').setValue(element.quantity);
    });

  }
  /**
  * @author Jose Vicente Silva
  * @createdate 2021-01-27
  * Metodo donde se establecen las validaciones del formulario
  */
  formControl(): void{

    this.solicitudesForm = new FormGroup({
      tipoProductos   : new FormControl('', [this.autocompleteObjectValidator(), Validators.required ]),
      categorias      : new FormControl('', [this.autocompleteObjectValidator(), Validators.required]),
      companias       : new FormControl('', [this.autocompleteObjectValidator(), Validators.required]),
      proyecto        : new FormControl('', [Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
      titulo          : new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
      descripcion     : new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
      items           : this.form.array([]),
      fechaEjecucion  : new FormControl(''),
      observacionEjecucion : new FormControl('', [Validators.minLength(30),Validators.maxLength(300)])

    });

  }

  addFile($event, index){
    const file = $event.target.files[0];
    const tempFiles = [];
    tempFiles.push(file)
    this.items.controls[index].get('itemsFiles').setValue(tempFiles);
  }

  deleteFile(index){
    	this.items.controls[index].get('itemsFiles').setValue([]);
  }


  /**
  * @author Jose Vicente Silva
  * @createdate 2021-08-27
  * Metodo que valida las funciones del autocompetable
  */
  autocompleteObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string') {
        return { invalidAutocompleteObject: { value: control.value } };
      }
      return null;
    };
  }



  /**
  * @author Jose Vicente Silva
  * @createdate 2021-08-27
  * Metodo que a partir de una variable enviada desde el administrador de roles crea un nuevo rol o edita un rol
  * @param rolForm datos que recolecta el formulario
  */
  saveApplication(solicitudesForm): void {

    const form = new FormData();

    if (this.data.type === 'crear') {
      form.append('titulo',solicitudesForm.value.titulo);
      form.append('descripcion', solicitudesForm.value.descripcion);
      form.append('proyecto',solicitudesForm.value.proyecto);
      form.append('idCategoria',solicitudesForm.value.categorias);
      form.append('idEmpresa',solicitudesForm.value.companias);
      form.append('idTipoProducto',solicitudesForm.value.tipoProductos);
      form.append('fechaEjecuccion',solicitudesForm.value.fechaEjecucion);
      form.append('observacionEjecuccion',solicitudesForm.value.observacionEjecucion)
      for (let index = 0; index < solicitudesForm.value.items.length; index++) {
        if(solicitudesForm.value.items[index].itemsFiles !== null){
          const setFile = solicitudesForm.value.items[index].itemsFiles[0];
          setFile.indexItem = index;
          form.append('archivo'+index,setFile)
        }
      }
      form.append('solicitudItems',JSON.stringify(solicitudesForm.value.items));

      this.solicitudService.saveSolicitud(form).subscribe((resp) => {
        this.alertsService.alertSuccess('Guardado', resp.data);
      });
    }
/*
    if (this.data.type === 'crear'){
      const sol             = new Solicitud();
      sol.titulo            = solicitudesForm.value.titulo;
      sol.descripcion       = solicitudesForm.value.descripcion;
      sol.proyecto          = solicitudesForm.value.proyecto;
      sol.idCategoria       = solicitudesForm.value.categorias;
      sol.idEmpresa         = solicitudesForm.value.companias;
      sol.idTipoProducto    = solicitudesForm.value.tipoProductos;
      sol.solicitudItems    = solicitudesForm.value.items;


      this.solicitudService.saveSolicitud(sol).subscribe((resp) => {
        this.alertsService.alertSuccess('Guardado', resp.data);
      });
    } */


  }



  /**
  * @author Daniel Martinez
  * @createdate 2021-02-16
  * Metodo que estable las validaciones del formarray dentro del formulario
  */
  newItems(): void{
    const newItems = this.form.group({
      detalle: new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
      cantidad: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.minLength(1)]),
      itemsFiles:[],
    });
    this.items.push(newItems);
  }

  /**
  * @author Daniel Martinez
  * @createdate 2021-02-16
  * Metodo que se encarga de la eliminacion de un grupo de aplicacion y rol
  * @param indice indice del grupo de aplicacion y rol a eliminar
  */
  deleteItems(indice: number): void {
    this.items.removeAt(indice);
    this.uploadFiles = this.uploadFiles.filter(item => item.indexItem !== indice);
  }

  handleFileInput(event) {
    if(this.filesUpload.length === 3  ) {
      this.maxFile = true;
      //this.snackbar.open('Solo puedes cargar 3 archivos', '', this.config);
      return;
    }
    if(event.target.files[0].size > (1024 * 1024 * 3)) {
      this.maxFile = true;
      //this.snackbar.open('El archivo a cargar debe pesar máximo 3 MB', '', this.config);
      return;
    }

    this.maxFile = false;
    this.filesUpload.push(... event.target.files);


  }

  EliminarArchivoLista (index: number) {
    const item = this.filesUpload[index];
    this.filesUpload = this.filesUpload.filter( e => e !== item);
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }


  getCategories( id ){
    this.categoriaService.getCategories(id)
      .subscribe( cat => this.filteredOptionsCategoria = cat);

  }


  clearForm(){
    this.solicitudesForm.reset();

  }

}
