import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestsService } from './../../../services/requests.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';

@Component({
  selector: 'app-modal-delete',
  templateUrl: './modal-delete.component.html',
  styleUrls: ['./modal-delete.component.sass']
})
export class ModalDeleteComponent implements OnInit {

  formulario: FormGroup;

  constructor(public dialogRef: MatDialogRef<ModalDeleteComponent>,
              private formBuilder: FormBuilder,
              private serviceBpms: RequestsService,
              private alertService: AlertsService,
              @Inject(MAT_DIALOG_DATA) public datos: any) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      campo: ['', [Validators.required]]
    });

  }

  onSubmit(formulario) {
    const observacion = {
      observations: this.formulario.value.campo,
    };

    this.serviceBpms.cancelarSolicitud(this.datos.item.id, observacion).subscribe((respuesta: any) => {
      this.alertService.alertSuccess('Cancelada', 'Su solicitud ha sido cancelada correctamente');
      this.dialogRef.close(true);
    });
  }
}
