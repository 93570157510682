import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const AUDITORIA_URL = environment.AUDITORIA_URL;

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private http:HttpClient,
  ) { }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  getReportGeneral(params){
    return this.http.get(`${AUDITORIA_URL}descargaReporteGeneral`,{params,responseType:'blob'})
    .pipe(
      catchError(this.handleError)
    );
  }

   getReportGeneralEncuesta(params){
    return this.http.get(`${AUDITORIA_URL}descargaReporteGeneralEncuesta`,{params,responseType:'blob'})
    .pipe(
      catchError(this.handleError)
    );
  }

}
