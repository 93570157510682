import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Paginator, Button, ConfigTable } from './type';
import { PageEvent } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SelectionModel } from '@angular/cdk/collections';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'shared-basic-table',
  templateUrl: './basic-table.component.html',
  styleUrls: ['./basic-table.component.css']
})

export class BasicTableComponent implements OnInit {
  public keyColumns: string[] = [];
  public checkedRegister: number
  public selection = new SelectionModel<any>(true, []);
  public formInput: FormArray
  @Input() columnsTable: ConfigTable[] = [];
  @Input() dataTables: any[] = [];
  @Input() stickyTable: boolean = false;
  @Input() messageLoading: string = 'No hay información disponible';
  @Input() buttonsOptions: Button[] = [];
  @Input() isToogle: boolean = true;
  @Input() isRadioButton: boolean = false;
  @Input() configPaginator: Paginator = null;
  @Input() showTooltipTable: boolean = false;
  @Input() showHorizontalScroll: boolean = false
  @Input() showButtonSubmit: boolean = false
  @Output() eventEmitRadio: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventEmitButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventEmitSlider: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatePaginator: EventEmitter<Paginator> = new EventEmitter<Paginator>();
  @Output() eventEmitCheck: EventEmitter<any> = new EventEmitter<any>();
  @Output() sumValuesInput: EventEmitter<number> = new EventEmitter<number>()
  @Output() sendInputValues: EventEmitter<any> = new EventEmitter<any>()
  @Output() eventSubmit: EventEmitter<void> = new EventEmitter<void>()

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.keyColumns = this.columnsTable.map(column => column.key);
    const keyInput = this.columnsTable.find(column => column.type && column.type === 'input')
    if (this.dataTables.length > 0 && keyInput) {
      this.formInput = new FormArray([])
      this.dataTables.forEach((data) => {
        this.formInput.push(new FormControl({ value: 0, disabled: true }, [Validators.min(1), Validators.required]))
      })
    }
  }

  /**
    * @author Duvan Ramirez
    * @createdate 2024-03-06
    * Metodo que emite un evento a traves de el radio button. 
    * @param element Registro seleccionado de la tabla.
  */
  eventRadio(element: any): void {
    this.checkedRegister = element.id
    this.eventEmitRadio.emit(element);
  }
  /**
    * @author Duvan Ramirez
    * @createdate 2024-03-06
    * Metodo que emite un evento a traves del boton. 
    * @param element Registro seleccionado de la tabla.
    * @param action Tipo de accion generada. 
  */
  eventButton(element: any, action: string): void {
    this.eventEmitButton.emit({ element, action });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-06
    * Metodo que emite un evento a traves del slide. 
    * @param $event emitido por el slider. 
  */
  eventSlider($event: MatSlideToggleChange, element: any): void {
    this.eventEmitSlider.emit({ state: $event.checked, element });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-06
    * Metodo que emite un evento a traves del paginator.
    * @param $event emitido por el paginador. 
  */
  onChangePage($event: PageEvent): void {
    this.updatePaginator.emit($event);
  }
  /**
    * @author Duvan Ramirez
    * @createdate 2024-03-21
    * Metodo valida si se selecciona todas las filas de la tabla. 
  */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataTables.length;
    return numSelected === numRows;
  }
  /**
      * @author Duvan Ramirez
      * @createdate 2024-03-21
      * Metodo que toma la selecciona de todas las filas. 
    */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataTables.forEach(row => this.selection.select(row));
    this.eventEmitCheck.emit({ type: 'all', selected: this.selection.selected })
    this.inputDisableOrEnable(false, 'all', 0)
  }
  /**
    * @author Duvan Ramirez
    * @createdate 2024-03-21
    * Metodo pitan la seleccion de cada una de las filas
  */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  /**
    * @author Duvan Ramirez
    * @createdate 2024-03-21
    * Metodo que emitira al padre la o las filas seleccionadas
    * @param event evento emitido por el check
    * @param element element de la fila seleccionado
    * @param index posicion de elemento seleccionado
  */
  changeCheckbox(event: MatCheckboxChange, element: any, index: number) {
    this.inputDisableOrEnable(event.checked, '', index)
    this.eventEmitCheck.emit({ type: 'unique', selected: element, elementsSelected: this.selection.selected })
  }
  /**
    * @author Duvan Ramirez
    * @createdate 2024-03-22
    * Metodo que suma los campos input activos
  */
  captureInput() {
    if(this.formInput.valid){
      const sumInput = this.formInput.value.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr), 0)
      this.sumValuesInput.emit(sumInput)
    }
  }
  /**
    * @author Duvan Ramirez
    * @createdate 2024-03-22
    * Metodo valida que campos input activar seleccionar uno o todos los checks
    * @param checked boleano indicando el campo seleccionaod
    * @param type tipo de check seleccionado
    * @param index posicion del campo checkqueado
  */
  inputDisableOrEnable(checked: boolean, type: string, index: number): void {
    if (this.formInput) {
      if (type === 'all') {
        this.formInput.controls.forEach(control => {
          if (this.selection.selected.length > 0) {
            control.enable()
          } else {
            control.setValue(0)
            control.disable()
          }
        })
      } else {
        if (checked) {
          this.formInput.at(index).enable()
        } else {
          this.formInput.at(index).disable()
          this.formInput.at(index).setValue(0)
        }
      }
      this.captureInput()
    }
  }
  /**
    * @author Duvan Ramirez
    * @createdate 2024-04-02
    * Metodo que emite los valores a relacionar de la fila
    * @param element elemento de la fila seleccionada
    * @param valueInput valor ingresado en la fila
  */
  blurInput(element: any, valueInput:number):void{
    this.sendInputValues.emit({element, valueInput})
  }
  /**
    * @author Duvan Ramirez
    * @createdate 2024-04-02
    * Metodo valida que valida los campos no presenten errores y lanzar el submit
  */
  submit():void{
    if(this.formInput.invalid) return this.formInput.markAllAsTouched()
    this.eventSubmit.emit()
  }
}