import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, ValidatorFn, FormArray } from '@angular/forms';
import { UsersService } from '../../../services/rest/users.service';
import { ApplicationsService } from '../../../services/rest/applications.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import swal from 'sweetalert2';


@Component({
  selector: 'app-edit-users',
  templateUrl: './edit-users.component.html',
  styleUrls: ['./edit-users.component.sass']
})
export class EditUsersComponent implements OnInit {

  hide: boolean = true;
  userForm: FormGroup;
  applications: any;
  filteredOptions: Observable<any[]>;
  roles: any = [];
  dobAuthCheck: boolean;
  enableCheck: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private form: FormBuilder,
    public userService: UsersService,
    private appService: ApplicationsService,
    private alertsService: AlertsService,
    private destroyService: DestroyComponentService,
    private dialogRef: MatDialogRef<EditUsersComponent>
  ) { }

  ngOnInit(): void {
    this.dobAuthCheck = this.data.user.dob_auth == "0" ? false : true;
    this.enableCheck = this.data.user.mobile_phone != '' && this.data.user.email != '' ? true : false;
    this.formControl();

    this.appService.getApplications().subscribe((resp) => {
      this.applications = resp.data;
    });

    this.userService.getAppxRol(this.data.user.id).subscribe((resp) => {

      resp.data.forEach((element, index) => {
        this.newappxrol();
        this.appxrol.controls[index].get('application_id').setValue(element.application_id);
        this.appSelected(element.application_id, index);
        this.appxrol.controls[index].get('role_id').setValue(element.role_id);
      });

    });

  }

  get error(): any { return this.userForm.controls; }

  get appxrol(): FormArray {
    return this.userForm.get('appxrol') as FormArray;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo donde se establecen las validaciones del formulario
   */
  formControl(): void {

    this.userForm = this.form.group({
      password: new FormControl('', []),
      phone: new FormControl('', [Validators.maxLength(15), Validators.minLength(7)]),
      mobile_phone: new FormControl('', [Validators.maxLength(15), Validators.minLength(10)]),
      email: new FormControl('', [Validators.maxLength(50), Validators.minLength(5), Validators.email]),
      dobAuth: new FormControl(''),
      appxrol: this.form.array([])
    });

  }

  validatorEvent(): void {
    if (this.userForm.controls['password'].value !== '') {
      this.userForm.controls['password'].setValidators([this.passwordValidate(), Validators.maxLength(15), Validators.minLength(5)]);
    } else {
      this.userForm.controls['password'].setValidators([]);
    }
    this.userForm.controls['password'].updateValueAndValidity();
  }


  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que estable las validaciones del formarray dentro del formulario
   */
  newappxrol(): void {
    const newappxrol = this.form.group({
      application_id: new FormControl('', [Validators.required]),
      role_id: new FormControl('', [Validators.required]),
    });
    this.appxrol.push(newappxrol);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que evalua en tiempo real los caracteres ingresados en el input, no deja ingresar espacios
   * @param event evento del keypress del input usuario
   */
  omitSpecialChar(event): any {
    let k;
    k = event.charCode;
    return ((k > 32 && k < 126));
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que se encarga de las validaciones del campo password
   */
  passwordValidate(): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!/[A-Z]/.test(control.value)) {
        return { hasCapitalCase: { value: control.value } };
      }
      else if (!/[a-z]/.test(control.value)) {
        return { hasSmallCase: { value: control.value } };
      }
      else if (!/[!@#$%^&*()_+=[{};':"|,.<>/?/{};':"|,.<>/?-]/.test(control.value)) {
        return { hasSpecialCharacters: { value: control.value } };
      }
      else if (!/\d/.test(control.value)) {
        return { hasNumber: { value: control.value } };
      }
      return null;
    };
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que se encarga de la filtracion de aplicaciones
   * @param pos posicion del autocompletable a filtrar
   */
  getFilter(pos: number): void {
    this.filteredOptions = this.appxrol.controls[pos].get('application_id').valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(applications => applications ? this._filter(applications) : this.applications.slice())
    );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que se encarga de la eliminacion de un grupo de aplicacion y rol
   * @param indice indice del grupo de aplicacion y rol a eliminar
   */
  deleteappxrol(indice: number): void {
    this.roles.splice(indice, 1);
    this.appxrol.removeAt(indice);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * filtra por nombre, se usa para el autocompletable
   * @param value valor a filtrar
   */
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.applications.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   */
  displayFn(id: number): string {
    if (!id) { return ''; }
    let index = this.applications.findIndex(resp => resp.id === id);
    return this.applications[index].name;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que se activa cuando una aplicacion ha sido seleccionada, busca los roles de la aplicacion seleccionada
   * @param id id de la aplicacion
   * @param pos posicion del grupo de app y rol
   */
  appSelected(id: any, pos: number): void {
    this.appxrol.controls[pos].get('role_id').setValue('');
    this.appService.getRolesxApp(id).subscribe((resp) => {
      this.roles[pos] = resp.data;
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que se encarga de la edicion de aplicacion y roles de un usuario
   * @param userForm formulario del usuario a editar
   */
  editUser(userForm): void {
    const user = {
      password: userForm.value.password,
      mobile_phone: userForm.value.mobile_phone,
      appxrol: userForm.value.appxrol,
      admin: true,
      dob_auth: this.dobAuthCheck,
      email: userForm.value.email
    };

    this.userService.editUser(this.data.user.id, user).subscribe((resp) => {
      this.dialogRef.close();
      this.alertsService.alertSuccess('Editado', resp.data.ciu);
    });

  }

  DobAuthValidate(event) {
    if (this.userForm.value.mobile_phone != '' && this.userForm.value.email != '') {
      this.dobAuthCheck = event.checked;
    } else {
      swal.fire(
        '!Atención!',
        'Debes tener número de telefono y email registrados para poder activar esta función',
        'warning'
      );
    }
  }

  enableCheckBox() {
    this.enableCheck = this.userForm.value.mobile_phone != '' && this.userForm.value.email != '' && this.userForm.valid ? true : false;
    if (!this.enableCheck) {
      this.dobAuthCheck = false;
    }
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-04-22
   * Metodo que se encarga de validar que no se repitan roles
   * @param pos posicion del select
   */
  verifyRoles(pos: number): void {
    let cont = 0;
    this.appxrol.controls.forEach(element => {
      if (element.get('role_id').value === this.appxrol.controls[pos].get('role_id').value) {
        cont++;
      }
    });

    if (cont > 1) {
      this.appxrol.controls[pos].get('role_id').setErrors({ invalidRol: true });
    }

  }

  /**
  * @author Daniel Martinez
  * @createdate 2021-01-27
  * Metodo que evalua en tiempo real los caracteres ingresados en el input
  * @param event evento del keypress del input usuario
  */
  omitSpecialCharPhone(event): any {
    let k;
    k = event.charCode;
    return ((k > 47 && k < 58));
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

}
