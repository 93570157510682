import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ApplicationsService } from '../../../services/rest/applications.service';
import { DestroyComponentService } from '../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { AuthService } from '../../../../../core/services/rest/auth.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Aplication } from '../../class/aplications';

@Component({
  selector: 'app-save-aplications',
  templateUrl: './save-aplications.component.html',
  styleUrls: ['./save-aplications.component.sass']
})
export class SaveAplicationsComponent implements OnInit {

  applicationForm: FormGroup;
  filteredOptions: Observable<any[]>;
  applications: any[] = [{id:1,name:'Si'},{id:2,name:'No'}];
  menus: any;
  menusSet: any = [];

  constructor(private applicationService: ApplicationsService,
              private destroyService: DestroyComponentService,
              private alertsService: AlertsService,
              private AuthService: AuthService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {

    this.formControl();
    this.setEdit();
    

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * filtra por nombre, se usa para el autocompletable
   * @param value valor a filtrar
   */
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.applications.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   */
  displayFn(id: number): string{
    if (!id) { return ''; }
    let index = this.applications.findIndex(resp => resp.id === id);
    return this.applications[index].name;

  }

  get error(): any { return this.applicationForm.controls; }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo donde se establecen las validaciones del formulario
   */
  formControl(): void{

    this.applicationForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(40), Validators.minLength(3)]),
      key: new FormControl('', [Validators.required, Validators.maxLength(40), Validators.minLength(3)]),
      endpoint: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
      horario: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
     
    });

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que valida las funciones del autocompetable
   */
  autocompleteObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string') {
        return { invalidAutocompleteObject: { value: control.value } };
      }
      return null;
    };
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que setea los valores al momento de editar
   */
  setEdit(): void {
    if (this.data.type === 'editar') {

     this.applicationForm.controls.name.setValue(this.data.app.name);
     this.applicationForm.controls.key.setValue(this.data.app.key);
     this.applicationForm.controls.endpoint.setValue(this.data.app.endpoint);
     if(this.data.app.enforce_schedule==1){
       this.applicationForm.controls.horario.setValue(Number(this.data.app.enforce_schedule));

     }else {
      this.applicationForm.controls.horario.setValue(Number('2'));
       
     }
      
    }
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que a partir de una variable enviada desde el administrador de roles crea un nuevo rol o edita un rol
   * @param rolForm datos que recolecta el formulario
   */
   saveApplication(rolForm): void {

    if (this.data.type === 'crear'){
      const app = new Aplication();

      app.id=0;
      app.name = this.applicationForm.value.name;
      app.endpoint = this.applicationForm.value.endpoint;
      app.key = this.applicationForm.value.key;
      if(this.applicationForm.value.horario==1){

        app.enforce_schedule='1';
      }else{
        app.enforce_schedule='0';

      }
      this.applicationService.saveApplication(app).subscribe((resp) => {
        this.alertsService.alertSuccess('Guardado', resp.data);
      });
    }
    else if (this.data.type === 'editar'){
      this.editApplication();
    }

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que edita un rol
   * @param rolForm datos que recolecta el formulario
   */
  editApplication(): void {
    
    const app = new Aplication();
    app.id=this.data.app.id;
    app.name = this.applicationForm.value.name;
    app.key = this.applicationForm.value.key;
    app.endpoint = this.applicationForm.value.endpoint;
    if(this.applicationForm.value.horario==1){

      app.enforce_schedule='1';
    }else{
      app.enforce_schedule='0';

    }
    this.applicationService.editApplication(app).subscribe((resp) => {
      this.alertsService.alertSuccess('Editado', resp.data);
    });
  }

 

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

}
