import { JuridicaService } from './../../../../services/juridica.service';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CampaignsService } from 'src/app/modules/nomina/services/rest/campaigns.service';
import { CandidatesService } from 'src/app/modules/rrhh/services/candidates.service';
import { PermitResponseComponent } from '../permit-response/permit-response.component';
import { PermitViewComponent } from '../permit-view/permit-view.component';
import { saveAs as importedSaveAs } from 'file-saver';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-permit-list',
  templateUrl: './permit-list.component.html',
  styleUrls: ['./permit-list.component.sass']
})
export class PermitListComponent implements OnInit {
  // MatPaginator Inputs
  length;
  pageSize = 5;
  page = 1;
  isPage = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  displayedColumns: string[] = ['acciones', 'fecha', 'nombre', 'numero', 'campana', 'tipo', 'estado'];
  dataSource = new MatTableDataSource<any>();

  reportForm: FormGroup;
  reportForm2: FormGroup;
  Campaings: any;
  filterControl = new FormControl();
  filterControl2 = new FormControl(4);
  typeDocument = new FormControl(1);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator2: MatPaginator;

  document: any;
  startDate: any = '';
  endDate: any;
  idCampaing: any;

  document2: any;
  startDate2: any = '';
  endDate2: any;
  idCampaing2: any;

  documents: any = [];
  idTypeDoc: any = 1;
  idTypeDoc2: any;
  states: any = [{ id: 4, name: 'Todos' }, { id: 0, name: 'En revisión' }, { id: 1, name: 'Aprobado' }, { id: 2, name: 'Rechazado' }];
  idState: any = 4;

  public documentControl = new FormControl();


  constructor(public dialog: MatDialog,
              private service: CampaignsService,
              private permit: JuridicaService,
              private users: CandidatesService
  ) {

  }

  ngOnInit() {
    this.reportForm = new FormGroup({
      start: new FormControl(''),
      end: new FormControl('')
    });
    this.filterControl2.valueChanges.subscribe(resp => {
      this.resetPaginator(1);
      this.idState = resp;
      this.getPermits(this.idState, this.pageSize, this.page);
    });
    //documento
    this.users.getIdTypes().subscribe(resp => {
      this.documents = resp;
    });
    this.typeDocument.valueChanges.subscribe(resp => {
      this.idTypeDoc = resp;
      this.getPermits(this.idState, this.pageSize, this.page);
    });
    // Campaña
    this.filterControl.valueChanges.subscribe(resp => {
      this.resetPaginator(1);
      this.idCampaing = resp;
      this.getPermits(this.idState, this.pageSize, this.page);
    });
    this.service.getAllCampaigns().subscribe(resp => {
      this.Campaings = resp.data;
    });

    this.permit.getPermits(this.idState).subscribe(resp => {
      this.dataSource = resp.data.data;
      this.length = resp.data.total;
    });
  }


  /**
   * @author Daniel Dominguez 
   * @createdate 2021-06-22
   * Metodo de paginación
   */
  pageEvent(event: any): void {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getPermits(this.idState, this.pageSize, this.page);
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-06-22
   * Modal de respuests de permiso
   */
  viewJustification(element): void {
    const dialogNewgroup = this.dialog.open(PermitResponseComponent, {
      width: '70%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Juan Manuel Gonzales',
        data: element
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewgroup.afterClosed().subscribe(() => {
      this.getPermits(this.idState, this.pageSize, this.page);
    });

  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-06-22
   * Modal de visualizar justificación
   */
  viewJustification2(element): void {
    const dialogNewgroup = this.dialog.open(PermitViewComponent, {
      width: '70%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Juan Manuel Gonzales',
        data: element
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewgroup.afterClosed().subscribe(() => {
      this.getPermits(this.idState, this.pageSize, this.page);
    });

  }


  /**
   * @author Daniel Dominguez 
   * @createdate 2021-06-22
   * Metodo para capturar el documento a filtrar
   */
  onBlurMethod(e) {
    this.resetPaginator(1);
    this.document = e;
    this.getPermits(this.idState, this.pageSize, this.page);
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-06-22
   * Metodo para capturar el rango de fechas a filtrar
   */
  dateRangeChange() {
    this.resetPaginator(1);
    const date1: Date = this.reportForm.controls.start.value;
    const date2: Date = this.reportForm.controls.end.value;
    const dateStart: string | null = new DatePipe('en-US').transform(date1, 'yyyy-MM-dd');
    const dateEnd: string | null = new DatePipe('en-US').transform(date2, 'yyyy-MM-dd');
    this.startDate = dateStart;
    this.endDate = dateEnd;
    this.getPermits(this.idState, this.pageSize, this.page);
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-06-23
   * Metodo para filtrar los pendientes
   */
  getPermits(type, pageSi, page) {
    if (this.document != null || this.idTypeDoc != null ||this.idCampaing != null || page != null || pageSi != null || this.startDate != null && this.endDate != null) {
      this.permit.getPermitsBy(type, this.idTypeDoc, this.document, this.idCampaing, this.startDate, this.endDate, pageSi, page).subscribe(resp => {
        this.dataSource = resp.data.data;
        this.length = resp.data.total;
      });
    }
  }

  /**
   * @author D aniel Dominguez 
   * @createdate 2021-06-24
   * Metodo de descarga de reporte de permisos
   */
  downloadReport() {
    this.permit.getReport(this.idState, this.idTypeDoc, this.document, this.idCampaing, this.startDate, this.endDate).subscribe(resp => {
      importedSaveAs(resp, 'reporte_permisos.xlsx');
    });
  }

  /**
   * @author Daniel Dominguez  <javier.c@montechelo.com.co>
   * @purpose resetea el paginador
   */
  resetPaginator(numPaginator): void {
    this.length = 0;
    this.pageSize = 5;
    this.page = 1;
    this.paginator.firstPage();
  }

}
