<div class="main-container">
    <div class="mt-5">
        <button mat-icon-button class="btn-regresar" [routerLink]="['/mios/auditoria/control']">
            <mat-icon matPrefix style="background-color: #000; border-radius: 50%;"><i class="fi-sr-angle-small-left" style="color: #FFF;"></i></mat-icon>
            Regresar
        </button>
    </div>
    <header class="mt-5 mb-5">
        <h1 class="interlineado">{{title}}</h1>
        <p>Matriz de {{tipo}}</p>
        <h5 *ngIf="tipo != 'Cumplimiento'">{{ tipo_peso }}</h5>
        <h5>Creada el {{creacion | date:'dd/MM/yyyy hh:mm a'}}</h5>
    </header>
    <p class="primary">Rango de peso ({{minimo}} - {{maximo}})</p>
    <p class="blue">Peso actual ({{peso_actual}})</p>
    <div class="mt-5">
        <app-tabla-control [campania]="campania" [idMatriz]="id_matriz"
        [matriz]="title"></app-tabla-control>
    </div>
</div>