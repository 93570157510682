import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { SABLE_URL } = environment;

@Injectable({
  providedIn: 'root'
})
export class CommandsService {
  constructor(private httpClient: HttpClient) { }

  /**
    * @author Fabian Duran
    * @createdate 2024-02-22
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-22
    * Metodo que retorna los comandos registrados en el sistema.
    * @param paginator Paginador configurado en la vista. 
    * @param filter Filtro configurado en la vista.
    * @param showAllCommands Muestra todas las bandejas activas en el sistema.   
  */
  getCommands(paginator: any = null, filter: string = '', showAllCommands: boolean = false): Observable<any> {
    const paramState = showAllCommands ? '' : 'state=1';
    const url = paginator === null ? `${SABLE_URL}shell/showall?${paramState}` : `${SABLE_URL}shell/showall?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&name=${filter}`;
    return this.httpClient.get(url).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-22
    * Metodo que guardar o actualiza un comando en el sistema. 
    * @param command Informacion proveniente del formulario.  
  */
  storeCommand(command: any): Observable<any> {
    const url = command.id === null ? `${SABLE_URL}shell/store` : `${SABLE_URL}shell/update/${command.id}`;
    return this.httpClient.post(url, command).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-22
    * Metodo que elimina o inactiva un commando. 
    * @param id Comando seleccionado en la tabla.  
  */
  deleteCommand(id: number): Observable<any> {
    return this.httpClient.delete(`${SABLE_URL}shell/destroy/${id}`).pipe(
      catchError(this.handleError)
    );
  }
}
