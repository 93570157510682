<ng-container [ngSwitch]="faceType">
  <!-- Cara Brava -->
  <svg *ngSwitchCase="'Angry'" [width]="30" height="30" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="40" fill="#FF0000" stroke="#000" stroke-width="2"/>
    <circle cx="35" cy="40" r="5" fill="#000"/>
    <circle cx="65" cy="40" r="5" fill="#000"/>
    <path d="M35 60 Q50 40, 65 60" stroke="#000" stroke-width="2" fill="none"/>
    <path d="M30 30 Q35 20, 40 30" stroke="#000" stroke-width="2" fill="none"/>
    <path d="M60 30 Q65 20, 70 30" stroke="#000" stroke-width="2" fill="none"/>
  </svg>
  <!-- Cara Seria -->
  <svg *ngSwitchCase="'Normal'" width="30" height="30" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="40" fill="#FFFF00" stroke="#000" stroke-width="2"/>
    <circle cx="35" cy="40" r="5" fill="#000"/>
    <circle cx="65" cy="40" r="5" fill="#000"/>
    <path d="M35 60 Q50 65, 65 60" stroke="#000" stroke-width="2" fill="none"/>
    <path d="M30 30 Q35 25, 40 30" stroke="#000" stroke-width="2" fill="none"/>
    <path d="M60 30 Q65 25, 70 30" stroke="#000" stroke-width="2" fill="none"/>
  </svg>
  <!-- Cara Feliz -->
  <svg *ngSwitchCase="'Happy'" width="30" height="30" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="40" fill="#00FF00" stroke="#000" stroke-width="2"/>
    <circle cx="35" cy="40" r="5" fill="#000"/>
    <circle cx="65" cy="40" r="5" fill="#000"/>
    <path d="M35 60 Q50 75, 65 60" stroke="#000" stroke-width="2" fill="none"/>
    <path d="M30 30 Q35 25, 40 30" stroke="#000" stroke-width="2" fill="none"/>
    <path d="M60 30 Q65 25, 70 30" stroke="#000" stroke-width="2" fill="none"/>
  </svg>
  <!-- Cara seria por defecto -->
  <svg **ngSwitchDefault width="30" height="30" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="40" fill="#FFFF00" stroke="#000" stroke-width="2"/>
    <circle cx="35" cy="40" r="5" fill="#000"/>
    <circle cx="65" cy="40" r="5" fill="#000"/>
    <path d="M35 60 Q50 65, 65 60" stroke="#000" stroke-width="2" fill="none"/>
    <path d="M30 30 Q35 25, 40 30" stroke="#000" stroke-width="2" fill="none"/>
    <path d="M60 30 Q65 25, 70 30" stroke="#000" stroke-width="2" fill="none"/>
  </svg>
</ng-container>