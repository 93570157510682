<div class="row">
  <div class="box-text col-md-5">
    <h4 class="title">Gestor de descargas</h4>
    <h1 class="welcome">¡Bienvenid@ <br /> {{nameUser}} </h1>
    <p class="text lh-base">Este es el módulo de <b>gestor de descargas</b>, <br />
      donde podrás visualizar y realizar la gestión <br />
      para programar descargas de las llamadas <br />
      registradas en Vicidial.
    </p>
  </div>
  <div class="box-image col-md-7">
    <img src="../../../assets/images/Ilustración_Juridico.svg" alt="Imagen landing page sable">
  </div>
</div>