import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { AuditoriaService } from '../../services/auditoria.service';
import {TranslatesService} from '../../services/translates.service';

@Component({
  selector: 'app-auditorias',
  templateUrl: './auditorias.component.html',
  styleUrls: ['./auditorias.component.sass']
})
export class AuditoriasComponent implements OnInit {
  private uuid = '362eb5fc-9072-11ee-b9d1-0242ac120002';
  public config: any;

  constructor(
    private authService:AuthService,
    private auditoriaService:AuditoriaService,
    private configService: TranslatesService,
  ) { }

  ngOnInit():void{
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
  }

}
