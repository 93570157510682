<h2 mat-dialog-title> {{data.title}}
  <button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross-small"></i>
  </button>
</h2>

<form class="row form" [formGroup]="groupForm">
  <mat-dialog-content style="padding: 3%;" class="mat-typography">

    <mat-form-field class="col-4" appearance="fill">
      <mat-label>Campañas</mat-label>
      <mat-select  formControlName="campaigns" multiple>
        <mat-select-trigger>
          {{ (groupForm.get('campaigns')?.value && groupForm.get('campaigns')?.value[0]) ? groupForm.get('campaigns').value[0].name : ''}}
          <span *ngIf="groupForm.get('campaigns').value?.length > 1" class="example-additional-selection">
            (+ {{groupForm.get('campaigns').value?.length === 2 ? 'otra' : 'otras'}} {{ groupForm.get('campaigns').value.length - 1}} )
          </span>
        </mat-select-trigger>
        <mat-option *ngFor="let campaign of listCampaign" [disabled]="campaign.isDisabled" [value]="campaign">{{campaign.name}}  </mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
      <mat-label>Nombre del grupo</mat-label>
      <input matInput type="text" placeholder="Escribe el nombre del grupo" formControlName="group" required>
      <mat-error *ngIf="error.group.errors"><span *ngIf="error.group.errors.required">¡Advertencia! Este campo es
          requerido</span></mat-error>
      <mat-error *ngIf="error.group.errors"><span *ngIf="error.group.errors.minlength">¡Aún falta! Recibimos mínimo 3
          caracteres</span></mat-error>
      <mat-error *ngIf="error.group.errors"><span *ngIf="error.group.errors.maxlength">¡Cuidado! Solo puedes tener un
          máximo de 100 caracteres</span></mat-error>
    </mat-form-field>


    <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
      <mat-label>Descripción</mat-label>
      <input style="text-transform: lowercase;" matInput type="text" placeholder="Escribe la despcripción del grupo"
        formControlName="description" required>
      <mat-error *ngIf="error.description.errors"><span *ngIf="error.description.errors.required">¡Advertencia! Este
          campo es requerido</span></mat-error>
      <mat-error *ngIf="error.description.errors"><span *ngIf="error.description.errors.minlength">¡Aún falta! Recibimos
          mínimo 3 caracteres</span></mat-error>
      <mat-error *ngIf="error.description.errors"><span *ngIf="error.description.errors.maxlength">¡Cuidado! Solo puedes
          tener un máximo de 100 caracteres</span></mat-error>
    </mat-form-field>
    <!-- titulos -->
    <div fxLayout="row wrap" appearance="fill" fxLayoutAlign="space-between none">
      <div fxFlex="39" style=" padding-left: 1%;">
        <mat-label> <strong>Usuarios disponibles</strong></mat-label>
        <mat-checkbox [formControl]="checkAll1.controls['selected']" (change)="onChangeCheck($event)"
          style="float: right; margin-right: 16px;" labelPosition="before">Todos</mat-checkbox>
      </div>
      <div fxFlex="20"></div>
      <div fxFlex="38">
        <mat-label> <strong>Usuarios del grupo</strong> </mat-label>
        <mat-checkbox [formControl]="checkAll2.controls['selected']" (change)="onChangeCheck2($event)"
          style="float: right; margin-right: 16px;" labelPosition="before">Todos</mat-checkbox>
      </div>
    </div>
    <!-- selects usuarios -->
    <div fxLayout="row wrap" appearance="fill" fxLayoutAlign="space-around center">
      <div fxFlex="37"
        style="padding-left: 1%; height: 250px; overflow-y:auto; border: solid 1px #ccc;  border-radius: 4px;"
        cdkScrollable>

        <mat-selection-list #shoes [formControl]="lstInicial.controls['selected']" appearance="fill">
          <mat-list-option *ngFor="let usuario of userSinGroup " [value]="usuario">
            {{usuario.name}}
          </mat-list-option>
        </mat-selection-list>
      </div>

      <div fxFlex="20" fxLayout="column" fxLayoutAlign="space-between center" style="align-content: center;">
        <button mat-raised-button color="primary" style="align-content: center; margin-top: 20px; margin-bottom: 30px;"
          (click)="asingarGrupo()">
          <span class="fi-rr-arrow-right"></span>
        </button>
        <button mat-raised-button color="primary" style="align-content: center;" (click)="quitarGrupo()">
          <span class="fi-rr-arrow-left"></span>
        </button>

      </div>

      <div fxFlex="37"
        style="padding-left: 1%; height: 250px; overflow-y:auto; border: solid 1px #ccc;  border-radius: 4px;"
        cdkScrollable>
        <mat-selection-list items="5" #shoes2 [formControl]="lstfinal.controls['selected']">
          <mat-list-option *ngFor="let usuario of userGroup" [value]="usuario">
            {{usuario.name}}
          </mat-list-option>
        </mat-selection-list>
      </div>

    </div>
    <!-- select options -->
    <div fxLayout="row wrap" appearance="fill" fxLayoutAlign="space-around center">
      <div fxFlex="42" style=" padding-left: 1%; text-align: start;">
        <p>
          <strong>opciones seleccionadas: {{shoes.selectedOptions.selected.length}}</strong>
        </p>
      </div>
      <div fxFlex="20"></div>
      <div fxFlex="38" style="text-align: start;">
        <p>
          <strong>opciones seleccionadas: {{shoes2.selectedOptions.selected.length}}</strong>
        </p>
      </div>
    </div>

    <div fxLayout="row  wrap" appearance="fill" fxLayoutAlign="space-around center">
      <div fxFlex="40" class="mt-5" style="padding-left: 1%;">
        <p style="width: 100%; color: #353535; font-size: 24px !important;"> <b>Añadir usuario</b> </p>
        <mat-form-field appearance="fill" style="width: 95%;">
          <mat-label>Documento</mat-label>
          <input matInput type="text" #input autocomplete="off" [formControl]="documentNumber"
            (keydown.enter)="onBlurMethod($event.target.value)" style="width: 147px !important;">
          <mat-select name="ampm" class="ampm" matSuffix [formControl]="typeDocument"
            style="width: 60px; margin-left: 5px;">
            <mat-option *ngFor="let item of listTypeDocuments" [value]="item?.id">{{item.key}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="60">
        <button mat-icon-button fxFlex="10" style="margin-top: 25px;" [hidden]="!userData?.name"
          (click)="addUserToGroup()">
          <span class="fi-rr-add" style="font-size: 24px !important; color: #BD77CE;"></span>
        </button>
        <p style="margin-top: 22px;" *ngIf="userData?.name"> <b>Nombre: </b> {{userData?.name}} <br>
          <b>Campaña: </b> {{userData?.campaing}}
        </p>
      </div>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button mat-raised-button type="button" color="primary" [disabled]='groupForm.invalid || userGroup.length === 0'
      (click)="saveGroup(groupForm)" [mat-dialog-close]="true">Guardar</button>
  </mat-dialog-actions>

</form>
