import { Component, OnInit } from '@angular/core';
import {TranslatesService} from '../../services/translates.service';

@Component({
  selector: 'app-matrices',
  templateUrl: './matrices.component.html',
  styleUrls: ['./matrices.component.sass']
})
export class MatricesComponent implements OnInit {
  private uuid = 'dd7112ca-9094-11ee-b9d1-0242ac120002';
  public confi: any;

  constructor(
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.confi = resp;
    });
  }

}
