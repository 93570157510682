<div fxLayout="column" class="main-container">
    <div fxLayout="column" class="left-section">
        <div  class="fixed">
            <div class="left-section_title">
                <h1 *ngIf="campaingName == null ">Grupos</h1>
                <h1 *ngIf="campaingName != null ">Grupos de {{campaingName}}</h1>
                <p>En este espacio podrás ver todos los grupos <br> que tienes creados</p>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <form class="search-form" fxLayoutAlign="start" [formGroup]="Form">
                    <mat-form-field class="search-full-width" appearance="fill">
                        <mat-label>Buscar grupo</mat-label>
                        <span class="fi-rr-search" matSuffix></span>
                        <input type="text" formControlName="search" matInput placeholder="Buscar grupo" autocomplete="off" #input>
                    </mat-form-field>
                </form>
                <div fxLayoutAlign="end" style="padding: 25px 60px;">
                    <button color="primary" mat-raised-button *ngIf="permit?.save !== undefined" (click)="newgroup()">Crear grupo</button>
                </div>
            </div>
        </div>

        <div fxLayout="row wrap" *ngIf="permit?.view !== undefined">
            <mat-card fxFlex="30" class="form-card" *ngFor="let list of filteredOptions | async">
                <button mat-button class="mat-icon-button" [matMenuTriggerFor]="Menu" *ngIf="permit?.edit !== undefined">
                    <span class="fi-rr-menu-dots-vertical"></span>
                </button>
                <mat-menu #Menu="matMenu" xPosition="before" yPosition="above">
                    <button class="fontSize" mat-menu-item (click)="editgroup(list)">
                        <span class="fi-rr-edit" style="padding-right: 8px;"></span> Editar grupo
                    </button>
                </mat-menu>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-card-header [routerLink]="['/mios/crm2/forms']" class="card-view">
                        <mat-card-title>
                            <strong>{{list?.name_group}}</strong>
                        </mat-card-title>
                        <mat-card-subtitle>{{list?.description}}</mat-card-subtitle>
                    </mat-card-header>
                    <mat-slide-toggle *ngIf="permit?.change !== undefined" (change)="changeStatus(list?.id,list?.state)" [checked]="list?.status" class="position"></mat-slide-toggle>
                </div>

            </mat-card>
        
        </div>
        <div class="row d-flex justify-content-end ">

            <mat-paginator  class="mt-5 " [length]="length"
                    [pageSize]="pageSize"
                    (page)="pageEvent($event)"
                    [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>

        </div>
    </div>
</div>
