<div class="container-fluid py-3" style="padding-left: 6%;">
  <mat-card class="mat-elevation-z3 px-4 pt-5 border-card">
    <mat-card-title style="color: #353535; font-weight: 700; font-size: 28px;">
      Horarios
    </mat-card-title>
    <mat-card-content>
      <div class="d-flex pt-4 pb-4">
        <div class="mt-2 text-capitalize" style="font-size: 20px; font-weight: 700;">{{ currentMonth }} {{ currentYear
          }}</div>
        <div class="ms-2">
          <button style="height: 20px; width: 20px;" mat-icon-button [disabled]="indexWeekend === 0"><i
              class="fi-rr-angle-small-left fs-4" (click)="beforeWeekend()"></i></button>
          <button style="height: 20px; width: 20px;" mat-icon-button (click)="nextWeekend()"
            [disabled]="indexWeekend === 1"><i class="fi-rr-angle-small-right fs-4"></i></button>
        </div>
      </div>
      <div class="mb-4">
        <span [ngClass]="indexWeekend === 0 ? 'text-weekend-selected' : 'text-weekend-not-selected'">Semana
          actual ({{ datesWeek[0].date }} - {{ datesWeek[6].date }}) </span> |
        <span [ngClass]="indexWeekend === 1 ? 'text-weekend-selected' : 'text-weekend-not-selected'">Semana
          siguiente ({{ datesNextWeek[0].date }} - {{ datesNextWeek[6].date }})</span>
      </div>
      <mat-tab-group mat-stretch-tabs="true" (selectedTabChange)="tabChanged($event)" [selectedIndex]="indexTab">
        <mat-tab *ngFor="let item of datesShowIntoView;">
          <ng-template mat-tab-label>
            {{ item.date }} <br />
            {{ item.nameDay }}
          </ng-template>
          <div *ngIf="activitiesWork.length > 0; else noData" class="row px-2 py-4">
            <div *ngFor="let activity of activitiesWork" class="col-md-3 py-2">
              <mat-card class="mat-elevation-z4" [ngClass]="{
                  'border-card-turno': activity.schedule_type_name === 'Inicio de turno', 
                  'border-card-brake' : activity.schedule_type_name === 'Break',
                  'border-card-almuerzo' : activity.schedule_type_name === 'Almuerzo',
                  'border-card-fin-turno' : activity.schedule_type_name === 'Fin de turno'
                }">
                <mat-card-title style="font-size: 16px; font-weight: 700;">{{ activity.schedule_type_name
                  }}</mat-card-title>
                <mat-card-content>
                  <div *ngIf="activity.schedule_type_name === 'Break' || activity.schedule_type_name === 'Almuerzo'"
                    style="font-size: 14px; font-weight: 400;">{{ activity.schedule_start_time }} - {{
                    activity.schedule_end_time }}
                  </div>
                  <div *ngIf="activity.schedule_type_name === 'Inicio de turno'"
                    style="font-size: 14px; font-weight: 400;">{{ activity.schedule_start_time }}</div>
                  <div *ngIf="activity.schedule_type_name === 'Fin de turno'"
                    style="font-size: 14px; font-weight: 400;"> {{ activity.schedule_end_time }}
                  </div>
                  <div
                    *ngIf="activity.schedule_type_name === 'Break' || activity.schedule_type_name === 'Almuerzo'; else otherActivity"
                    style="font-size: 12px;">
                    <span style="font-weight: 700;">Registro: </span><span>{{ activity.start_time ===
                      '00:00' ? '--' : activity.start_time }} - {{ activity.end_time ===
                      '00:00' ? '--' : activity.end_time }}
                    </span>
                  </div>
                  <ng-template #otherActivity>
                    <div *ngIf="activity.schedule_type_name === 'Inicio de turno'" style="font-size: 12px;">
                      <span style="font-weight: 700;">Registro: </span><span>{{ activity.start_time ===
                        '00:00' ? '--' :
                        activity.start_time }}</span>
                    </div>
                    <div *ngIf="activity.schedule_type_name === 'Fin de turno'" style="font-size: 12px;">
                      <span style="font-weight: 700;">Registro: </span><span>{{ activity.end_time ===
                        '00:00'
                        ? '--' :
                        activity.end_time }}</span>
                    </div>
                  </ng-template>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <ng-template #noData>
            <div style="min-height: 235px;" class="row d-flex justify-content-center align-items-center px-2 py-4">
              <div class="col-md-6 text-center">
                <div style="font-size: 14px;">
                  Hoy no tienes asignado ningún horario <br />
                  <span style="font-weight: 700;">¡es tu día de descanso!</span>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>