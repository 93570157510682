import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assessmentResults'
})
export class AssessmentResultsPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let object =  results.find(e=>{return e.key === String(value)})
    return object? object.name : 'Pendiente';
  }

}
const results=[{key:'0', name: '0. No se evidencia la competencia.'}, {key:'1', name: '1. Se evidencia parcialmente la competencia'}, {key:'2', name: '2. Se evidencia una competencia desarrollada.'}]