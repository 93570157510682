<h2 mat-dialog-title>{{data.title}}
    <button style="float: right;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
</h2>

<form class="row form" [formGroup]="rolState" (ngSubmit)="saveState(rolState)">
<mat-dialog-content style="padding: 3%;" class="mat-typography">
    

        <mat-form-field style="padding: 1%;" class="col-12" appearance="fill">
            <mat-label>Nombre</mat-label>
            <input style="text-transform: capitalize;" matInput type="text" placeholder="Escribe el nombre del estado" formControlName="nombre" required cdkFocusInitial>
            <mat-error *ngIf="error.nombre.errors"><span *ngIf="error.nombre.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
        </mat-form-field>

        <mat-form-field style="padding: 1%;" class="col-12" appearance="fill">
            <mat-label>Descripción</mat-label>
            <textarea matInput type="text" placeholder="Escribe el nombre del estado" formControlName="descripcion" required></textarea>
            <mat-error *ngIf="error.descripcion.errors"><span *ngIf="error.descripcion.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
          </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button mat-raised-button type="submit" color="primary" disabled='{{rolState.invalid}}' [mat-dialog-close]="true">Guardar</button>
</mat-dialog-actions>
</form>