<button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<form mat-dialog-content class="containerDialog" [formGroup]="itemForm">
    <h2>{{config.labels.editItem}}</h2>
    <mat-divider class="mb-3"></mat-divider>
    <mat-form-field appearance="fill">
        <mat-label>{{config.labels.name}}</mat-label>
        <input matInput formControlName="nombre" [matAutocomplete]="auto">
        <mat-icon matSuffix><span class="fi-rr-search" (click)="getItems()"></span></mat-icon>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="newItem()">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.nombre">
                {{option.nombre}}
            </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="verError">{{config.labels.noResults}}</mat-error>
        <mat-error *ngIf="itemForm.get('nombre').hasError('required')">{{config.labels.required}}</mat-error>
    </mat-form-field>
    <button mat-button class="nuevo" *ngIf="nuevo" (click)="newItem()">{{config.labels.newItem}} <mat-icon matSuffix><i class="fi-rr-add"></i></mat-icon></button>
    <mat-form-field appearance="fill" *ngIf="peso">
        <mat-label>{{config.labels.weight}}</mat-label>
        <input type="number" matInput formControlName="peso">
        <mat-error *ngIf="itemForm.get('peso').hasError('required')">{{config.labels.enterWeight}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="descripcion">
        <mat-label>{{config.labels.description}}</mat-label>
        <textarea matInput formControlName="descripcion" cdkFocusInitial></textarea>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="mostarDescripciones">
        <mat-label>{{config.labels.positiveAspect}}</mat-label>
        <textarea matInput formControlName="aspectoPositivo" maxlength="101"></textarea>
        <mat-error *ngIf="itemForm.get('aspectoPositivo').hasError('maxlength')">{{config.labels.maxLength}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="mostarDescripciones">
        <mat-label>{{config.labels.negativeAspect}}</mat-label>
        <textarea matInput formControlName="aspectoNegativo" maxlength="101"></textarea>
        <mat-error *ngIf="itemForm.get('aspectoNegativo').hasError('maxlength')">{{config.labels.maxLength}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="mostarDescripciones">
        <mat-label>{{config.labels.naAspect}}</mat-label>
        <textarea matInput formControlName="aspectoNa" maxlength="101"></textarea>
        <mat-error *ngIf="itemForm.get('aspectoNa').hasError('maxlength')">{{config.labels.maxLength}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="mostarDescripciones && datos.tipo_matriz === config.labels.value && datos.tipo_peso === config.labels.weightItem && datos.aplica_para_cp === 1">
        <mat-label>{{config.labels.cpAspect}}</mat-label>
        <textarea matInput formControlName="aspectoCp" maxlength="101"></textarea>
        <mat-error *ngIf="itemForm.get('aspectoCp').hasError('maxlength')">{{config.labels.maxLength}}</mat-error>
    </mat-form-field>
    <div class="btns-dialog">
        <button mat-stroked-button (click)="itemRef.close()" [disabled]="!continue">{{config.labels.cancel}}</button>
        <button mat-raised-button color="primary" (click)="updateItem()" [disabled]="!continue">{{config.labels.save}}</button>
    </div>
</form>
