<div class="admin-inteligencia">

    <div class="header">
  
      <h2 style="height: 33px !important;
      font: normal normal bold 45px/35px Poppins !important;
      letter-spacing: 0px !important;color: #353535 !important;opacity: 1 !important;">Inteligencia de negocio</h2>
      <br>
      <p>En este espacio podrás crear las preguntas y los formularios para tu</p>
      <p>inteligencia de negocio, no olvides, “El respeto y empatía son clave”.</p>
    </div>
  
    <mat-tab-group [selectedIndex]="selected.value"
    (selectedIndexChange)="changeTab($event)">
      <mat-tab label="Preguntas">
          <app-tabla-preguntas></app-tabla-preguntas>
      </mat-tab>
      <mat-tab label="Inteligencia de negocio"> 
            <app-tabla-inteligencia-negocio></app-tabla-inteligencia-negocio>
      </mat-tab>
    </mat-tab-group> 
  </div>