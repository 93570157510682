import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/chat/pages/home/home.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'omni', loadChildren: () => import('./modules/chat/chat.module').then( m => m.ChatModule ) },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LivechatRoutingModule { }
