import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ForgotChangePasswordComponent } from './auth/forgot-change-password/forgot-change-password.component';
import { MiosComponent } from './mios/mios.component';
import { AuthGuard } from './core/guards/auth.guard';
import { SurverysGuard } from './core/guards/surveys.guard';
import { UrlAplicationsGuard } from './core/guards/UrlAplicacionGuard';
import { DocumentSignatureGuard } from './core/guards/document-signature.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'changePaswordAuth/:id', component: ForgotChangePasswordComponent },
  {
    path: 'mios', loadChildren: () => import('./mios/mios.module').then(m => m.MiosModule),
    component: MiosComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard, UrlAplicationsGuard, SurverysGuard, DocumentSignatureGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
