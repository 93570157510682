import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solicitudes-aprobar',
  templateUrl: './solicitudes-aprobar.component.html',
  styleUrls: ['./solicitudes-aprobar.component.sass']
})
export class SolicitudesAprobarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    localStorage.removeItem('filter_request')
  }

  selectedTabChange(evt){
    if(evt.index != 0) return;
    const tabChangeEvent = new Event('coordinator-gestion-called');
    window.dispatchEvent(tabChangeEvent);
  }

}
