import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ReportsReportService } from '../../../services/reports.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';



@Component({
  selector: 'app-report-show',
  templateUrl: './report-show.component.html',
  styleUrls: ['./report-show.component.sass']
})
export class ReportShowComponent implements OnInit {

  public token: string = '';
  public report_id: string = '';
  public rrhh_id: string;
  public generar = false;
  public type: number;
  public tittle: string;
  public subTittle: string;
  public selected: any;


  formId: any;
  reports: any;
  key: any;
  selectTitle: any;
  dataReport: any = [];

  constructor(private location: Location,
    public dialog: MatDialog,
    private reportsReportService: ReportsReportService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    let dataDecrypt = this.authService.decryptToken();
    this.rrhh_id = dataDecrypt.rrhh_id;
    this.getReports();
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-01-20
   * Metodo que lista los reportes en estado activo
   */
  getReports() {
    this.reportsReportService.getReportsByUser(this.rrhh_id).subscribe(
      response => {
        this.reports = response['data'];
        this.getReport(this.reports[0].key);
        this.selected = this.reports[0].key;
      }
    );
  }

  /**
     * @author Carlos Nieto
     * @createdate 2022-01-20
     * Metodo que retorna a la pagina anterior
     */
  backRoute() {
    this.location.back();
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-01-20
   * Metodo que setea la variable del select y llama al
   * componente que muestra PowerBI
   * @param key del powerBi
   */

  getReport(key) {
    this.token = null;
    this.report_id = null;
    this.generar = false;

    this.reportsReportService.getReport(key).subscribe((resp) => {
      if (resp.data.token !== '') {
        this.token = resp.data.token;
        this.report_id = resp.data.reporte_id;
        this.generar = true;
      }
    });
  }

}
