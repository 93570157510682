import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CandidatesService } from '../../../services/candidates.service';
import { CheckAttachmentComponent } from '../check-attachment/check-attachment.component';
import { saveAs as importedSaveAs } from "file-saver";
import { FilesViewComponent } from 'src/app/shared/files-view/files-view.component';

@Component({
  selector: 'app-candidate-attachments',
  templateUrl: './candidate-attachments.component.html',
  styleUrls: ['./candidate-attachments.component.sass']
})
export class CandidateAttachmentsComponent implements OnInit {
  candidateDocuments:any = [];
  displayedColumns: string[] = ['actions', 'file', 'name', 'state'];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  private serviceCandidate: CandidatesService,  private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.serviceCandidate.getAttachments(this.data.candidateId).subscribe( (resp: any) => {
      this.candidateDocuments = resp
    })
  }
  downdloadFile(data?, view?: boolean){
    let direction= `candidate/download/attachment/${this.data.candidateId}`
    let name
    if(data){
      direction= direction.concat(`/${data.id}`)
      name =  data.name
    }
    this.serviceCandidate.downdloadFile(direction).subscribe( (file) =>{
      if(view){
        let fileURL = URL.createObjectURL(file);
        this.documentView(fileURL, data.name, file)

      }
      else{
        importedSaveAs(file, name);
      }
    })
  }
  checkItem(id){
    const checkDialog = this.dialog.open( CheckAttachmentComponent, {
      minWidth: '50%',
      disableClose: true,
      data: { attachmentId: id }
    });
    checkDialog.afterClosed().subscribe( (params) => {
      if(params){
        this.ngOnInit()
      }

    });
  }
  documentView(url, name, file?){
    const checkDialog = this.dialog.open( FilesViewComponent, {
      disableClose: true,
      data: { url: url, name:name, file:file}
    });
  }
}
