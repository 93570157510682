import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

const CRM_URL = environment.CRM_URL;
const CRM2_URL = environment.CRM2_URL;

@Injectable({
  providedIn: 'root'
})

export class FormsCrmRequestService {
  constructor(private http: HttpClient) { }


  getForm(direction, size, page){
    if (size === 0 || page === 0) {
      return this.http.get<any>(`${CRM_URL}${direction}`);
    } else {
      return this.http.get<any>(`${CRM_URL}${direction}?n=${size}&page=${page}`);
    }
  }

  getFormCrm2(direction, size, page){
    if (size === 0 || page === 0) {
      return this.http.get<any>(`${CRM2_URL}${direction}`);
    } else {
      return this.http.get<any>(`${CRM2_URL}${direction}?n=${size}&page=${page}`);
    }
  }

  getsearchform(direction,form){
    return this.http.get<any>(`${CRM_URL}${direction}/${form}`);
  }

  getsearchformCrm2(direction,form){
    return this.http.get<any>(`${CRM2_URL}${direction}/${form}`);
  }
}
