import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, AfterViewInit, AfterContentInit, AfterContentChecked, AfterViewChecked, OnChanges, SimpleChanges, DoCheck, forwardRef } from '@angular/core';
import { FormArray, FormArrayName, FormBuilder, FormGroup, Validators,ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataAuditoria, ItemModel, ModuloModel } from 'src/app/modules/calidad/interfaces/dataAuditoria.interface';
import { AuditoriaService } from 'src/app/modules/calidad/services/auditoria.service';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
	selector: 'app-calificacion-auditoria',
	providers:[{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => CalificacionAuditoriaComponent),
		multi: true
	}],
	templateUrl: './calificacion-auditoria.component.html',
	styleUrls: ['./calificacion-auditoria.component.sass']
})
export class CalificacionAuditoriaComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private uuid = '7416e410-92b2-11ee-b9d1-0242ac120002';
  public config: any;

	@Input() data: DataAuditoria;
	@Output() calificacionModulos = new EventEmitter();

	//Variables para el calcular la calificación
	num_items = 0;
	cumple = 0;
	no_cumple = 0;
	peso_modulo = 0;
	itemSi = 0;
	itemNo = 0;
	pesoItems = 0;
	is_critico = 0;
	items_na = 0;
	decimales: boolean = false;
	screen_md: boolean = false;
	@Input() tipo_auditar = { interna: 0, cliente: 0 };

	is_inicio: boolean  = true;

	calificacion: number = 0;
	readyCalificacion: boolean = false;
	moduloForm: FormGroup;
	conteo = 0;
	withRadio = '';
	//Observable para los cambios en los servicios de auditoria
	subscription: Subscription;
	constructor(
		private fb: FormBuilder,
		private auditoriaService: AuditoriaService,
    private configService: TranslatesService,
	) { }
    selected:string;

	value: string;
  onChange = (value: any) => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}

	ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
      this.verModulos(this.config);
    });
		this.createForm();
		this.cantidadDecimales();
		this.subscription = this.auditoriaService.refresh$.subscribe(() => {
			this.verModulos(this.config);
			this.cantidadDecimales();
			this.tipo_auditar = { interna: 0, cliente: 0 };
		});
		console.log(this.data);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	/**
	* @author Cristian Gallo
	* @createdate 2022-02-24
	* Metodo que se encarga habilitar y desabilidar los sub items dependiendo la calificación que se de en el item
	*/
	calificacionItem(iModulo, iItem) {
		this.is_inicio=false;
		let item_calificado = ((this.moduloForm.get('modulos') as FormArray).at(iModulo).get('items_modulo') as FormArray).at(iItem).get('calificacion').value;
		let subItems = (((this.moduloForm.get('modulos') as FormArray).at(iModulo).get('items_modulo') as FormArray).at(iItem).get('sub_items') as FormArray);
		let itemModulo = ((this.moduloForm.get('modulos') as FormArray).at(iModulo).get('items_modulo') as FormArray).at(iItem).get('comments');
		itemModulo.disable();
		
		if(this.data.comment_is_mandatory_for.includes('0') && item_calificado == 'no' ){
			itemModulo.enable();
		}
		if(this.data.comment_is_mandatory_for.includes('1') && item_calificado == 'si' ){
			itemModulo.enable();
		}
		if(this.data.comment_is_mandatory_for.includes('2') && item_calificado == 'na' ){
			itemModulo.enable();
		}
		if(this.data.comment_is_mandatory_for.includes('3') && item_calificado == 'cp' ){
			itemModulo.enable();
		}
		
		if (item_calificado == 'no_cumple' || item_calificado == 'no') {
			subItems.controls.forEach((subItem: FormGroup) => {
				subItem.controls['calificacion'].setValue('no_cumple');
				subItem.controls['calificacion'].enable();
			});
		} else {
			subItems.controls.forEach((subItem: FormGroup) => {
				subItem.controls['calificacion'].setValue('');
				subItem.controls['calificacion'].disable();
			});
		}
		//this.calificacionModulos.emit({ modulos: this.moduloForm.get('modulos').value, calificacion: this.calculateCalificacion() });
		this.calificacionModulos.emit({ modulos: this.moduloForm.get('modulos').value, calificacion: this.calculateCalificacion() });
	}

	/**
	* @author Cristian Gallo
	* @createdate 2022-03-07
	* Metodo que se encarga validar cuando los sub items se califican como cumple o N/A
	* cambiando la calificación del item a cumple, si o N/A si es requerido
	*/

	calificacionSubItem(iModulo, iItem, iSubItem) {
		this.is_inicio=false;
		let cantidadCumple = 0;
		let cantidadNA = 0;
		let cantidadNoCumple = 0;
		let subItem = ((this.moduloForm.get('modulos') as FormArray).at(iModulo).get('items_modulo') as FormArray).at(iSubItem);
		let item = ((this.moduloForm.get('modulos') as FormArray).at(iModulo).get('items_modulo') as FormArray).at(iItem);
		const sub_items = item.get('sub_items') as FormArray;
		sub_items.controls.forEach((subItem: FormGroup) => {
			if (subItem.get('calificacion').value == 'cumple') {
				cantidadCumple++
			}
			if (subItem.get('calificacion').value === null) {
				cantidadNA++
			}
			if (subItem.get('calificacion').value === 'no_cumple') {
				cantidadNoCumple++
			}
		});
		if (cantidadCumple == sub_items.length) {
			if (this.data.tipo_matriz == 'Cumplimiento' || this.data.tipo_matriz == 'Compliance') {
				item.get('calificacion').setValue('cumple');
			} else {
				item.get('calificacion').setValue('si');
			}
			sub_items.controls.forEach((subItem: FormGroup) => {
				subItem.get('calificacion').setValue('');
				subItem.get('calificacion').disable();
				subItem.markAllAsTouched();
			});
			item.get('calificacion').setValue('si');
		}
		if (cantidadNA == sub_items.length) {
			item.get('calificacion').setValue(null);
			sub_items.controls.forEach((subItem: FormGroup) => {
				subItem.get('calificacion').setValue('');
				subItem.get('calificacion').disable();
				subItem.markAllAsTouched();
			});
		}
		if (cantidadNoCumple == 0) {
			if (this.data.tipo_matriz == 'Cumplimiento' || this.data.tipo_matriz == 'Compliance') {
				item.get('calificacion').setValue('cumple');
			} else {
				item.get('calificacion').setValue('si');
			}
			sub_items.controls.forEach((subItem: FormGroup) => {
				subItem.get('calificacion').setValue('');
				subItem.get('calificacion').disable();
				subItem.markAllAsTouched();
			});
		}
		this.calificacionModulos.emit({ modulos: this.moduloForm.get('modulos').value, calificacion: this.calculateCalificacion() });
	}

	/**
	 * Metodo que se encarga de calcular la calificacion total de del los modulos
	 * @author Juan David Guerrero Vargas
	 * @create_date 05/07/2022
	 * @returns number : numero con el total de la clificaicon para los modulos
	 */
	calculateCalificacion() {
		//variable que contiene el total de la calificacion
		this.calificacion = 0;
		//Variable que indica si se debe alnular toda la clificacipn o no (esto depende de si el el modulo es crtico total)
		let anulaTotal: boolean = false;


		//Se realiza validacion para determinar como se calculara la nota
		if (this.data.tipo_matriz == 'Cumplimiento' || this.data.tipo_matriz == 'Compliance') {
			let pesoCriticoRest = 0;//Peso de modulos criticos parciales a restar dentro de la nota
			let no_cumpleCritico: number = 0; //contador de items que no cumplen dentro de los modulos critocs
			let sumatoria = 0; //Variable que contendra la sumtarios de los modulos y servira de variable de opraciones apra los calculos
			/****************************************************
			Para el calculo de la calificacion por modulo se determina esta formula por el cliente
			En este escenario los items N/A no forman parte de la calificaicon por lo 2ue no se tienen encuanta para el calculo
			itemsCumple + itemsNocumple = totalRegistrosEvaluados
			itemsCumple / totalRegistrosEvaluados = porcentajeCumplimientoModulo
			porcentajeCumplimientoModulo * PesoModulo = Calificacion del modulo
			****************************************************/
			let cumple=0 //items que cumplen dentro del modulo
			let no_cumple = 0 //items que no cumplen
			//Se realiza itracion por cada modulo de la matriz
			this.moduloForm.get('modulos').value.forEach(modulo => {
				cumple=0
				no_cumple=0
				//Si el modulo no es de Error critico se asigna el peso para la sumatoria
				if (modulo.error_critico == 0) {
					this.peso_modulo = modulo.peso;
				} else {//En caso de que el modulo sea critico este no afecta la sumatoria total de la nota por lo que se deja en 0
					this.peso_modulo = 0;
				}
				//iteramos los items de cada modulo
				modulo.items_modulo.forEach(item => {
					Object.assign(item, { puntaje: 0 });//Se le asigna atributo puntaje para registro
					//Se realiza la validacion del estado de cada item dentro de los modulos

					//item NA -> por defecto no resta a la suma y su valor se pone como positivo
					//if (item.calificacion == 'null') { cumple++; }

					if (item.calificacion == 'cumple') {
						cumple++
					} else if (item.calificacion == 'no_cumple') {
						no_cumple++

					}//Si no se registra calificaicon como (cumple - no cumple) este no se debe tener en cuenta para el calculo de l total del modulo

					//Se asigna el puntaje a cada item
					modulo.items_modulo.forEach(item => {
						if (item.calificacion === null) {
							if (item.calificacion == 'cumple') {
								item.puntaje = this.peso_modulo / (cumple + no_cumple);
							} else if (item.calificacion == 'no_cumple') {
								item.puntaje = 0;
							} else {
								//item.puntaje = this.peso_modulo / (cumple + no_cumple);
								item.puntaje = 0;
							}
						}
					});


					//Validamos si el modulo es de error critico (error_critico = 1)
					//Si el modulo.peso es igual a null se asume que es un critico total, es decir dejara la nota en 0 sin importar cuantos items con cumple tenga
					//Se valida que almenos un item dentro del modulo critico total no cumpla para que se pueda anular la calificacion
					if (modulo.error_critico == 1 && (modulo.peso === null || modulo.peso === "" || modulo.peso == undefined) && item.calificacion == 'no_cumple') {
						anulaTotal = true;
					}
					//Validamos si es error critico pero si se registra algun valor en modulo.peso se asume que es un critico parcial, y envez de afectar toda la nota solo afectara el valor del modulo al que pertenece
					if (modulo.error_critico == 1 && modulo.peso && item.calificacion == 'no_cumple') {
						no_cumpleCritico++;//Se realiza conteo de item que no cumplan dentro del modulo critico parcial

					}

				});//FINALIZA ITERACION DE ITEMS DEL MODULO

				//Si el modulo es critico parcial, y almenos un item del modulo parcial no cumple, se se asigna a la variable el valor a restar por el modulo
				if (modulo.error_critico == 1 && modulo.peso && no_cumpleCritico > 0) {
					pesoCriticoRest = pesoCriticoRest + modulo.peso;
				}
				//Se seta en 0 el contador para el conteno del siguiente modulo
				no_cumpleCritico = 0;

				//Se realiza el calculo de la nota segun comentario inicial del metodo
				if((cumple > 0 || no_cumple > 0) &&  modulo.error_critico == 0){
					let totalRegistrosEvaluados = cumple + no_cumple
					let porcentajeCumplimientoModulo = cumple / totalRegistrosEvaluados
					sumatoria += porcentajeCumplimientoModulo * this.peso_modulo
				}
			}
			);//FINALIZA ITERACION DE MODULOS
			//Se asigna el valor de la variable sumatoria a la variabe de calificaion asumiendo que esta variab solo tiene la sumatoria de los calculos por modulo y no se la a hecho los descuentos por modulos criticos
			this.calificacion = sumatoria

			//Si se registro algun peso de los modulos criticos parciales, se hace la resta de ese modulo dentro de la clificacion
			if (pesoCriticoRest > 0) {
				this.calificacion = this.calificacion - pesoCriticoRest
			}

			//Si un modulo critico total tiene un item con no_cumple y anula_total queda en true, la califiacion se anula por lo que queda en 0
			if (anulaTotal) {
				this.calificacion = 0;
			}

			//Ninguna calificaicon puede ser menor de 0
			if (this.calificacion <= 0) {
				this.calificacion = 0;
			}

			//console.log(this.is_inicio);
			if(this.is_inicio==true){
				this.calificacion = 100;
				//this.is_inicio=false;
			}

		} else {
			if (this.data.tipo_peso == 'Peso en Módulos' || this.data.tipo_peso == 'Weight in Modules') {
				let pesoCriticoRest = 0;
				let no_cumpleCritico: number = 0;
				let sumatoria=0;
				this.moduloForm.get('modulos').value.forEach(modulo => {

					if (modulo.error_critico == 0) {
						this.peso_modulo = modulo.peso;
					} else {
						this.peso_modulo = 0;
					}
					modulo.items_modulo.forEach(item => {
						if (item.calificacion) {

							//item NA -> por defecto no resta a la suma y su valor se pone como positivo
					        //if (item.calificacion == 'null') { this.itemSi++; }

							Object.assign(item, { puntaje: 0 });
							if (item.calificacion == 'si') {
								this.itemSi++;
							} else if (item.calificacion == 'no') {
								this.itemNo++;
							} else{
								this.itemSi++;
							}

							if (modulo.error_critico == 1 && (modulo.peso === null || modulo.peso === "" || modulo.peso == undefined) && item.calificacion == 'no') {
								anulaTotal = true;
							}
							if (modulo.error_critico == 1 && modulo.peso && item.calificacion == 'no') {
								no_cumpleCritico++;
							}


						}
					});

					if (modulo.error_critico == 1 && modulo.peso && no_cumpleCritico > 0) {
						pesoCriticoRest = pesoCriticoRest +  Number(modulo.peso);
					}

					modulo.items_modulo.forEach(item => {
						if (item.calificacion === null) {
							if (item.calificacion == 'si') {
								item.puntaje = this.peso_modulo / this.num_items;
							} else if (item.calificacion == 'no') {
								item.puntaje = 0;
							} else {
								item.puntaje = 0;
								//item.puntaje = this.peso_modulo / this.num_items;
								this.items_na++;
							}
						}
					});

					if(this.itemSi === modulo.items_modulo.length ){
						sumatoria = sumatoria + this.peso_modulo
					}else if(this.itemNo > 0){
						sumatoria = sumatoria + 0
					}else if(this.items_na > 0){
						sumatoria = sumatoria + this.peso_modulo
					}


					this.itemSi = 0;
					this.itemNo = 0;
					this.items_na = 0;
					no_cumpleCritico = 0;
				}
				);

				this.calificacion = sumatoria


				if (pesoCriticoRest > 0) {
					this.calificacion = this.calificacion - pesoCriticoRest
				}

				if (this.calificacion <= 0) {
					this.calificacion = 0;
				}
			} else if (this.data.tipo_peso == 'Peso en Items' || this.data.tipo_peso == 'Weight in Items') {
				// console.log('Peso en Items');
				let pesoCriticoRest = 0;
				let pesoCriticoSum = 0;
				this.moduloForm.get('modulos').value.forEach(modulo => {
					modulo.items_modulo.forEach(item => {
						if (item.calificacion) {
							Object.assign(item, { puntaje: 0 });
							if (item.calificacion == 'si') {
								this.pesoItems = this.pesoItems + item.peso;
								item.puntaje = item.peso;
							} else if (item.calificacion == 'no') {
								this.pesoItems = this.pesoItems + 0;
								item.puntaje = 0;
							} else if (item.calificacion == 'cp') {
								this.pesoItems = (item.peso / 2) + this.pesoItems;
								item.puntaje = item.peso / 2;
							} else {
								//item.puntaje = 0;
								// Cambio para que los NA no resten
								this.pesoItems = this.pesoItems + item.peso;
								item.puntaje = item.peso;
							}


							if (modulo.error_critico == 1 && (modulo.peso === null || modulo.peso === "" || modulo.peso == undefined) && item.calificacion == 'no') {
								anulaTotal = true;
							}

							if (modulo.error_critico == 1 && modulo.peso && item.calificacion == 'no') {
								let sizeItems = modulo.items_modulo.length
								pesoCriticoRest = pesoCriticoRest + (Number(modulo.peso) / sizeItems)

							}


						}
						if (item.calificacion === null) {
							this.pesoItems = this.pesoItems + item.peso;
							item.puntaje = 0;
						}
						this.calificacion = this.calificacion + this.pesoItems;


						this.pesoItems = 0;
						this.items_na = 0;
					}
					);

				});

				if (pesoCriticoRest > 0) {
					this.calificacion = this.calificacion - pesoCriticoRest;
				}

				if (this.calificacion <= 0) {
					this.calificacion = 0;
				}

			}

			if (anulaTotal) {
				this.calificacion = 0;
			}



		}
		return this.calificacion;

	}
	/**
	 * Funcion valida cantidad de decimales 
	 */
	cantidadDecimales() {
		let calificacion: string = this.calculateCalificacion().valueOf().toLocaleString();
		let cantidadDecimales = 0;
		if (calificacion.indexOf(',') >= 2 && calificacion.indexOf(',') <= 3) {
			for (let index = calificacion.indexOf(',') + 1; index < calificacion.length; index++) {
				cantidadDecimales++
			}
		}
		if (cantidadDecimales > 1) {
			this.decimales = true;
			if (window.screen.width > 1060 && window.screen.width < 1660) {
				this.screen_md = true;
			} else {
				this.screen_md = false;
			}
		} else {
			if (window.screen.width > 1060 && window.screen.width < 1660) {
				this.screen_md = true;
			} else {
				this.screen_md = false;
			}
		}
	}

	createForm() {
		this.moduloForm = this.fb.group({
			modulos: this.fb.array([]),
		});
	}

	/**
	* @author Cristian Gallo
	* @createdate 2022-02-24
	* Metodo que se encarga de llenar los modulos, items y subitems con sus respectivos formcontrol
	* agregando el valor en la calificación en si o cumple
	*/
	verModulos(config) {
		const modulos = <FormArray>this.moduloForm.controls['modulos'];
		modulos.clear();
		this.data.modulos.forEach((modulo: ModuloModel, iModule) => {
			modulo.items_modulo.forEach(item => {
				if (this.data.tipo_matriz == 'Cumplimiento' || this.data.tipo_matriz == 'Compliance') {
				    Object.assign(item, { calificacion: 'null' });
					//Object.assign(item, { calificacion: 'cumple' });
				} else {
					Object.assign(item, { calificacion: 'si' });
					//Object.assign(item, { calificacion: 'null' });
				}
				item.sub_items.forEach(sub_item => {
					Object.assign(sub_item, { calificacion: { value: '', disabled: true },
						aspectosToltip : config.tooltip.description + (sub_item.descripcion || config.tooltip.undefined) +
						"\n" + config.tooltip.positiveAspects + (sub_item.aspectoPositivo || config.tooltip.undefined) +
						"\n" + config.tooltip.negativeAspects + (sub_item.aspectoNegativo  || config.tooltip.undefined) +
						"\n" + config.tooltip.naAspects + (sub_item.aspectoNa || config.tooltip.undefined)});
				});
			});
			modulos.push(this.fb.group({
				created_at: modulo.created_at,
				descripcion: modulo.descripcion,
				error_critico: modulo.error_critico,
				id: modulo.id,
				items_modulo: this.fb.array([]),
				matriz_id: modulo.matriz_id,
				nivel: modulo.nivel,
				nombre: modulo.nombre,
				padre_id: modulo.padre_id,
				peso: modulo.peso,
				premium: modulo.premium,
				sigla: modulo.sigla,
				type_select: modulo.type_select,
				updated_at: modulo.updated_at,
				usuario_actualizacion_id: modulo.usuario_actualizacion_id,
				usuario_creador_id: modulo.usuario_creador_id,
				activo: modulo.activo,
			}));
			modulo.items_modulo.forEach((item: ItemModel, index: number) => {
				(this.getModulos().at(iModule).get('items_modulo') as FormArray).push(this.fb.group({
					calificacion: item.calificacion,
					puntaje: item.puntaje,
					activo: item.activo,
					descripcion: item.descripcion,
					error_critico: item.error_critico,
					id: item.id,
					matriz_id: item.matriz_id,
					nivel: item.nivel,
					nombre: item.nombre,
					padre_id: item.padre_id,
					peso: item.peso,
					premium: item.premium,
					sigla: item.sigla,
					comments: item.comments,
					sub_items: this.fb.array([]),
					type_select: item.type_select,
					updated_at: item.updated_at,
					usuario_actualizacion_id: item.usuario_actualizacion_id,
					usuario_creador_id: item.usuario_creador_id,
					created_at: item.created_at,
					aspectNa: item.aspectoNa,
					aspecPos: item.aspectoPositivo,
					aspectNeg: item.aspectoNegativo,
					aspectosToltip :config.tooltip.description + (item.descripcion  || config.tooltip.undefined)+
					"\n" + config.tooltip.positiveAspects + (item.aspectoPositivo || config.tooltip.undefined) +
					"\n" + config.tooltip.negativeAspects + (item.aspectoNegativo  || config.tooltip.undefined) +
					"\n" + config.tooltip.naAspects + (item.aspectoNa || config.tooltip.undefined) +
					"\n" + (this.data.aplica_para_cp === 1 && item.aspectocp ? `${config.tooltip.cpAspects} ${item.aspectocp}` : '')


				}));
				console.log(item);
				item.sub_items.forEach((sub_item: ItemModel, index1: number)=>{
					console.log(iModule,index,index1);
					(this.getItems(iModule).at(index).get('sub_items') as FormArray).push(this.fb.group({
						calificacion: sub_item.calificacion,
						puntaje: sub_item.puntaje,
						activo: sub_item.activo,
						descripcion: sub_item.descripcion,
						error_critico: sub_item.error_critico,
						id: sub_item.id,
						matriz_id: sub_item.matriz_id,
						nivel: sub_item.nivel,
						nombre: sub_item.nombre,
						padre_id: sub_item.padre_id,
						peso: sub_item.peso,
						premium: sub_item.premium,
						sigla: sub_item.sigla,
						comments: sub_item.comments,
						type_select: sub_item.type_select,
						updated_at: sub_item.updated_at,
						usuario_actualizacion_id: sub_item.usuario_actualizacion_id,
						usuario_creador_id: sub_item.usuario_creador_id,
						created_at: sub_item.created_at,
						aspectNa: sub_item.aspectoNa,
						aspecPos: sub_item.aspectoPositivo,
						aspectNeg: sub_item.aspectoNegativo,
						aspectosToltip :config.tooltip.description + (sub_item.descripcion  || config.tooltip.undefined)+
						"\n" + config.tooltip.positiveAspects + (sub_item.aspectoPositivo || config.tooltip.undefined) +
						"\n" + config.tooltip.negativeAspects + (sub_item.aspectoNegativo  || config.tooltip.undefined) +
						"\n" + config.tooltip.naAspects + (sub_item.aspectoNa || config.tooltip.undefined) +
						"\n" + (this.data.aplica_para_cp === 1 && sub_item.aspectocp ? `${config.tooltip.cpAspects} ${sub_item.aspectocp}` : '')
					}));
				});

			});
		});
		this.calificacionModulos.emit({ modulos: this.moduloForm.get('modulos').value, calificacion: this.calculateCalificacion() });
	}
	/**
	* @author Cristian Gallo
	* @createdate 2022-02-24
	* Metodo que retorna los modulos con sus controls
	*/
	getModulos(): FormArray {
		return this.moduloForm.get('modulos') as FormArray;
	}

	/**
  * @author Cristian Gallo
  * @createdate 2022-02-24
  * Metodo que retorna los items con sus controls
  */
	getItems(indexModulo: number): FormArray {
		return this.getModulos().at(indexModulo).get('items_modulo') as FormArray;
	}

}
