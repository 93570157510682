<div class="main-container">
    <div class="mt-5">
        <button mat-icon-button class="btn-regresar" [routerLink]="['/mios/auditoria/matrices']">
            <mat-icon matPrefix style="background-color: #000; border-radius: 50%;"><i class="fi-sr-angle-small-left" style="color: #FFF;"></i></mat-icon>
            Regresar
        </button>
    </div>
    <header class="mt-5">
        <h1 class="interlineado">{{title}}</h1>
        <p>Matriz de {{tipo}}</p>
        <div *ngIf="tipo != 'Cumplimiento'">
            <h5 *ngIf="tipo_peso == 'Peso en Items'">Peso en Ítems</h5>
            <h5 *ngIf="tipo_peso == 'Peso en Módulos'">{{ tipo_peso }}</h5>
        </div>
        <h5>Creada el {{creacion | date:'dd/MM/yyyy hh:mm a'}}</h5>
        <button mat-icon-button class="ver-analista" (click)="analyst('analista')">Ver analista de calidad asignado
            <mat-icon><i class="fi-rr-arrow-small-right"></i></mat-icon>
        </button>
    </header>
    <p class="primary">Rango de peso ( {{maximo}} - {{minimo}} )</p>
    <p class="blue">Peso actual ({{peso_actual | number:'1.0-3'}})</p>
    <div class="filtro">
        <mat-form-field appearance="fill">
            <mat-label>Filtrar por módulo</mat-label>
            <input matInput [(ngModel)]="filtroModulo" (keydown.enter)="filtrar()" (blur)="filtrar()">
            <mat-icon matSuffix (click)="filtrar()"><span class="fi-rr-search"></span></mat-icon>
        </mat-form-field>
        <div class="text-end">
            <button mat-raised-button color="primary" style="height: 52px; font-size: 1.2em; width: 15em;"
            (click)="newModule()">
                Crear módulo <i class="fi-rr-add"></i>
            </button>
        </div>
    </div>
    <div class="mb-3" *ngFor="let modulo of modulos_matriz" fxLayout="row" fxLayout.lg="row" 
    fxLayoutAlign="start none">
        <div fxFlex.gt-md="100%">
            <mat-expansion-panel>
                <mat-expansion-panel-header class="">
                    <mat-panel-title fxLayout="row" fxFlex="70%">
                    <div fxFlex.gt-md="13%" fxFlex.gt-lg="7%">
                        <mat-slide-toggle color="primary" #slide
                        [checked]="modulo.activo | boolean"
                        (click)="disableModule(modulo.id,modulo.activo,'módulo')"
                        (click)="$event.preventDefault()"
                        (click)="$event.stopPropagation()">
                        </mat-slide-toggle>
                        <button mat-icon-button (click)="editModule(modulo.id)" (click)="$event.stopPropagation()">
                            <mat-icon style="font-size: 1.3em; color: #000; margin-top: -.5em;"><i class="fi-rr-edit"></i></mat-icon>
                        </button>
                    </div>
                    <p class="sigla" [ngClass]="{'sigla-disabled': !slide.checked}" fxFlex.gt-md="8%" fxFlex.gt-lg="6%">{{modulo.sigla}}</p>
                    <p class="nombre" [ngClass]="{'nombre-disabled': !modulo.activo}" [matTooltip]="modulo.nombre" [matTooltipPosition]="'above'" 
                    fxFlex.gt-lg="80%" fxFlex.gt-md="79%">{{modulo.nombre | cutText:0:54}}</p>
                </mat-panel-title>
                <mat-panel-description fxLayout="row" fxLayoutAlign="end start" fxFlex.gt-lg="30%" fxFlex.gt-md="35%">
                    <div fxFlex.gt-lg="25%" fxFlex.gt-md="35%">
                        <p *ngIf="modulo.peso" style="margin-top: .2em;">Peso({{modulo.peso | number:'1.0-3'}})</p>
                    </div>
                    <div fxFlex.gt-lg="41%" fxFlex.gt-md="57%">
                        <button mat-stroked-button color="accent" [disabled]="!slide.checked" style="width: 100%;"
                        (click)="newItem(modulo.id,modulo.sigla,modulo.error_critico)" (click)="$event.stopPropagation()">
                          Agregar ítem
                        </button>
                    </div>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="mb-3" *ngFor="let item of modulo.items_modulo" fxLayout="row" fxLayout.lg="row">
                <mat-expansion-panel style="padding-right: 0;">
                    <mat-expansion-panel-header style="padding-right: 0;">
                        <mat-panel-title fxLayout="row" fxFlex="70%">
                            <div fxFlex.gt-md="20%" fxFlex.gt-lg="12%" (click)="$event.stopPropagation()">
                                <mat-slide-toggle color="primary" #itemSlide
                                [checked]="item.activo | boolean"
                                (click)="disableModule(item.id,item.activo,'item')"
                                (click)="$event.preventDefault()"
                                (click)="$event.stopPropagation()">
                                </mat-slide-toggle>
                                <button mat-icon-button (click)="editItem(item.id,modulo.error_critico)" (click)="$event.stopPropagation()">
                                    <mat-icon style="font-size: 1.3em; color: #000;"><i class="fi-rr-edit"></i></mat-icon>
                                </button>
                                <button mat-icon-button [disabled]="!item.descripcion">
                                    <mat-icon style="font-size: 1.3em;"
                                    [matTooltip]="item.descripcion" [matTooltipPosition]="'above'"><i class="fi-rr-document"></i></mat-icon>
                                </button>
                            </div>
                            <p class="sigla" [ngClass]="{'sigla-disabled': !itemSlide.checked}" fxFlex.gt-md="7%" fxFlex.gt-lg="6%">{{item.sigla}}</p>
                            <p class="nombre" [ngClass]="{'nombre-disabled': !item.activo}" [matTooltip]="item.nombre" [matTooltipPosition]="'above'"
                            fxFlex.gt-md="80%" fxFlex.gt-lg="70%">{{item.nombre | cutText:0:50}}</p>
                        </mat-panel-title>
                        <mat-panel-description fxLayout="row" fxFlex="35%" fxLayoutAlign="end start">
                            <div fxFlex.gt-lg="25%" fxFlex.gt-md="33%">
                                <p *ngIf="item.peso">Peso({{item.peso | number:'1.0-3'}})</p>
                            </div>
                            <div fxFlex.gt-lg="36%" fxFlex.gt-md="58%">
                                <button mat-stroked-button color="accent" [disabled]="!itemSlide.checked" style="width: 100%;"
                                (click)="newSubItem(item.id,item.sigla,modulo.error_critico)" 
                                (click)="$event.stopPropagation()">
                                    Agregar Subítem
                                </button>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="mb-3" *ngFor="let sub_item of item.sub_items" fxLayout="row" fxLayout.lg="row">
                        <mat-expansion-panel [disabled]="!sub_item.activo | boolean" hideToggle>
                            <mat-expansion-panel-header>                                
                                <mat-panel-title class="mb-3" fxLayout="row" fxFlex="100%">
                                    <div>
                                        <mat-slide-toggle color="primary" #subItemSlide
                                        [checked]="sub_item.activo | boolean"
                                        (click)="disableModule(sub_item.id,sub_item.activo,'sub item')"
                                        (click)="$event.preventDefault()"
                                        (click)="$event.stopPropagation()">
                                        </mat-slide-toggle>
                                        <button mat-icon-button (click)="editSubItem(sub_item.id)" (click)="$event.stopPropagation()">
                                            <mat-icon style="font-size: 1.3em; color: #000;"><i class="fi-rr-edit"></i></mat-icon>
                                        </button>
                                        <button mat-icon-button [disabled]="!sub_item.descripcion">
                                            <mat-icon [matTooltip]="sub_item.descripcion" [matTooltipPosition]="'above'" style="font-size: 1.3em;">
                                                <i class="fi-rr-document"></i>
                                            </mat-icon>
                                        </button>
                                    </div>
                                    <p class="sigla-sub" [ngClass]="{'sigla-disabled': !subItemSlide.checked}">{{sub_item.sigla}}</p>
                                    <p style="font-weight: 600;padding-top: .3em;" 
                                    [matTooltip]="sub_item.nombre" [matTooltipPosition]="'above'"
                                    [ngClass]="{'subItem-disabled': !subItemSlide.checked}">{{sub_item.nombre | cutText}}</p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                    </div>
                </mat-expansion-panel>
              </div>
            </mat-expansion-panel>
        </div>
    </div>      
</div>