import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InteligenciaService } from 'src/app/modules/calidad/services/inteligencia.service';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
  selector: 'app-matrices-asignadas',
  templateUrl: './matrices-asignadas.component.html',
  styleUrls: ['./matrices-asignadas.component.sass']
})
export class MatricesAsignadasComponent implements OnInit {
  private uuid = '1a197260-9060-11ee-b9d1-0242ac120002';
  public config: any;

  matrices = [];
  constructor(
    private inteligenciaS:InteligenciaService,
    @Inject(MAT_DIALOG_DATA) public datos:any,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.getMatrix();
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
  }

  getMatrix(){
    this.inteligenciaS.getMatrix(this.datos.id).subscribe((resp:any)=>{
      this.matrices = resp;
    });
  }

}
