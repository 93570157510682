import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AuthService } from 'src/app/core/services/rest/auth.service';

const CRM_URL = environment.CRM_URL;

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  constructor(private http: HttpClient, private AuthService: AuthService) { }

  getGroups(direction, campaignid) {

    const httpOptions = {
      params: new HttpParams().set("campaign_id", campaignid),
      headers: new HttpHeaders({

        Authorization: 'Bearer' + this.AuthService.getUser().access_token,
      }),
    };
    return this.http.get<any>(`${CRM_URL}${direction}`, httpOptions);
  }
  getGroupsByUser(direction, id) {
    return this.http.get<any>(`${CRM_URL}${direction}${id}`);
  }

  putState(id, state) {
    return this.http.put(`${CRM_URL}deletegroup/` + id, state);

  }


  createGroup(campaignid, body) {
    const httpOptions = {
      params: new HttpParams().set("campaign_id", campaignid),
      headers: new HttpHeaders({
        Authorization: 'Bearer' + this.AuthService.getUser().access_token,
      }),
    };
    return this.http.post<any>(`${CRM_URL}savegroup`, body, httpOptions);
  }

  getUsers(id) {
    return this.http.get<any>(`${CRM_URL}searchUser/${id}`);
  }

  getGroupById(id) {
    return this.http.get<any>(`${CRM_URL}searchgroup/${id}`);
  }

  updateGroup(id, body) {
    return this.http.put<any>(`${CRM_URL}updategroup/${id}`, body);
  }

}
