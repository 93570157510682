import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../core/services/rest/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  user;
  private server =  environment.RRHH_URL;
  constructor(private http: HttpClient, private authService: AuthService) { }
  downloadReport(entries){
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}candidate/report`, {responseType:'blob', params});
  }


  downloadNewReports(entries){
    return this.http.post(`${this.server}reports/${entries.report}`, entries);
  }
}
