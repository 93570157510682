<form class="mt-4 row pb-3" [formGroup]="auditarForm">
  <mat-form-field class="col-6" appearance="fill">
    <mat-label>{{ config.labels.cedulaAsesor }}</mat-label>
    <input type="number" matInput (blur)="getAsesor('asesor')" formControlName="cedula_asesor">
    <mat-icon matSuffix style="color: #717171;" (click)="getAsesor('asesor')"><i class="fi-rr-search"></i></mat-icon>
    <mat-error *ngIf="auditarForm.get('cedula_asesor').hasError('required')">{{ config.labels.requiredDocument }}</mat-error>
    <mat-error *ngIf="auditarForm.get('cedula_asesor').hasError('no_resultado')">{{ config.labels.tryAnotherDocument }}</mat-error>
  </mat-form-field>
  <mat-form-field class="col-6" appearance="fill">
    <mat-label>{{ config.labels.asesorName }}</mat-label>
    <input type="text" matInput formControlName="nombre_asesor" autocomplete="off">
    <mat-error *ngIf="auditarForm.get('nombre_asesor').hasError('required')">{{ config.labels.requiredAdvisorName }}</mat-error>
  </mat-form-field>

  <!-- Disabled de Datos Jefe -->
  <mat-form-field class="col-6" appearance="fill">
    <mat-label>{{ config.labels.bossId }}</mat-label>
    <input
      type="number"
      matInput
      formControlName="doc_boss"
      (blur)="getAsesor('boss')"
      autocomplete="off"
    >
    <mat-icon matSuffix style="color: #717171;" (click)="getAsesor('boss')">
      <i class="fi-rr-search"></i>
    </mat-icon>
    <mat-error *ngIf="auditarForm.get('doc_boss').hasError('required')">{{ config.labels.requiredBossDocument }}</mat-error>
    <mat-error *ngIf="auditarForm.get('doc_boss').hasError('no_resultado')">{{ config.labels.tryAnotherDocument }}</mat-error>
  </mat-form-field>

  <mat-form-field class="col-6" appearance="fill">
    <mat-label>{{ config.labels.bossName }}</mat-label>
    <input type="text" matInput formControlName="name_boss"  autocomplete="off">
  </mat-form-field>
  <!-- Disabled de Datos Jefe-->


  <mat-form-field class="col-6" appearance="fill">
    <mat-label>{{ config.labels.auditDate }}</mat-label>
    <input type="text" matInput [matDatepicker]="llamada" formControlName="fecha" [max]="endDate">
    <mat-datepicker-toggle matSuffix [for]="llamada">
      <mat-icon matDatepickerToggleIcon><i class="fi-rr-calendar"></i></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #llamada></mat-datepicker>
    <mat-error *ngIf="auditarForm.get('fecha').hasError('required')">{{ config.labels.requiredDate }}</mat-error>
  </mat-form-field>
  <mat-form-field class="col-6" appearance="fill">
    <mat-label>{{ config.labels.auditedPhoneNumber }}</mat-label>
    <input type="number" matInput formControlName="telefono">
    <mat-error *ngIf="auditarForm.get('telefono').hasError('required')">{{ config.labels.requiredPhoneNumber }}</mat-error>
    <mat-error *ngIf="auditarForm.get('telefono').hasError('max')">{{ config.labels.maxPhoneNumber }}</mat-error>
  </mat-form-field>
  <mat-form-field class="col-6" appearance="fill">
    <mat-label>{{ config.labels.contactTime }}</mat-label>
    <input type="time" matInput formControlName="hora">
    <mat-error *ngIf="auditarForm.get('hora').hasError('required')">{{ config.labels.requiredContactTime }}</mat-error>
  </mat-form-field>
  <mat-form-field class="col-6" appearance="fill" id="grabacion">
    <mat-label>{{ config.labels.recordingDuration }}</mat-label>
    <input type="text" matInput formControlName="duracion_grabacion" (blur)="auditarForm.get('duracion_grabacion').setValue(tiempo)" (change)="validaTemporizador($event)" >
    <mat-icon matSuffix style="color: #717171; font-size: 20px;"><i class="fi-rr-clock"></i></mat-icon>
    <mat-error *ngIf="auditarForm.get('duracion_grabacion').hasError('required')">{{ config.labels.requiredRecordingDuration }}</mat-error>
    <mat-error *ngIf="auditarForm.get('duracion_grabacion').hasError('pattern')">{{ config.labels.numericFormat }}</mat-error>
  </mat-form-field>
  <mat-form-field class="col-6" appearance="fill">
    <mat-label>{{ config.labels.monitoringType }}</mat-label>
    <mat-select formControlName="tipo_monitoreo">
      <mat-option *ngFor="let monitoreo of monitoreos" [value]="monitoreo.id">{{monitoreo.nombre}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('tipo_monitoreo').hasError('required')">{{ config.labels.requiredSelection }}</mat-error>
  </mat-form-field>
  <mat-form-field class="col-6" appearance="fill">
    <mat-label>{{ config.labels.serviceType }}</mat-label>
    <mat-select formControlName="tipo_servicio">
      <mat-option *ngFor="let servicio of servicios" [value]="servicio.id">{{servicio.nombre}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('tipo_servicio').hasError('required')">{{ config.labels.requiredSelection }}</mat-error>
  </mat-form-field>
  <mat-form-field class="col-6" appearance="fill">
    <mat-label>{{ config.labels.ucid }}</mat-label>
    <input type="text" matInput formControlName="ucid">
    <mat-error *ngIf="auditarForm.get('ucid').hasError('required')">{{ config.labels.requiredUCID }}</mat-error>
  </mat-form-field>
  <mat-form-field class="col-6" appearance="fill">
    <mat-label>{{ config.labels.auditType }}</mat-label>
    <mat-select formControlName="tipo_auditoria" (selectionChange)="auditorio()">
      <mat-option *ngFor="let auditoria of auditorias" [value]="auditoria.id">{{auditoria.nombre}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('tipo_auditoria').hasError('required')">{{ config.labels.requiredSelection }}</mat-error>
  </mat-form-field>
  <mat-form-field class="col-6" appearance="fill">
    <mat-label>{{ config.labels.firstContactSolution }}</mat-label>
    <mat-select formControlName="solucion_primer_contacto_id" (selectionChange)="changeSelect()">
      <mat-option *ngFor="let solucion of soluciones" [value]="solucion.id">{{solucion.nombre}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('solucion_primer_contacto_id').hasError('required')">{{ config.labels.requiredSelection }}</mat-error>
  </mat-form-field>
  <mat-form-field class="col-6" appearance="fill"
                  *ngIf="auditarForm.get('solucion_primer_contacto_id').value == 18">
    <mat-label>{{ config.labels.noSolutionReason }}</mat-label>
    <mat-select formControlName="razon_solucion_id" (selectionChange)="changeSelect()">
      <mat-option *ngFor="let no_solucion of no_soluciones" [value]="no_solucion.id">{{no_solucion.nombre}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('solucion_primer_contacto_id').hasError('required')">{{ config.labels.requiredSelection }}</mat-error>
  </mat-form-field>
  <mat-form-field class="col-12" appearance="fill" *ngIf="auditarForm.get('solucion_primer_contacto_id').value == 18">
    <mat-label>{{ config.labels.whyNoSolution }}</mat-label>
    <textarea matInput formControlName="porque_no"></textarea>
    <mat-error *ngIf="auditarForm.get('solucion_primer_contacto_id').hasError('required')">{{ config.labels.requiredSelection }}</mat-error>
  </mat-form-field>
  <mat-form-field class="col-6" appearance="fill">
    <mat-label>{{ config.labels.ojt }}</mat-label>
    <mat-select formControlName="ojt_id" (selectionChange)="changeSelect()">
      <mat-option *ngFor="let ojt of ojts" [value]="ojt.id">{{ojt.nombre}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('ojt_id').hasError('required')">{{ config.labels.requiredSelection }}</mat-error>
  </mat-form-field>
  <mat-form-field class="col-6" appearance="fill" *ngIf="auditarForm.get('ojt_id').value == 19">
    <mat-label>{{ config.labels.days }}</mat-label>
    <mat-select formControlName="dias">
      <mat-option *ngFor="let dia of dias" [value]="dia">{{dia}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('ojt_id').hasError('required')">{{ config.labels.requiredSelection }}</mat-error>
  </mat-form-field>
  <mat-form-field class="col-12" appearance="fill">
    <mat-label>{{ config.labels.selectedSampleType }}</mat-label>
    <mat-select formControlName="tipoMuestra" (selectionChange)="changeSelect()">
      <mat-option *ngFor="let muestra of tipoMuestra" [value]="muestra.id">{{muestra.name}}</mat-option>
    </mat-select>
    <mat-error *ngIf="auditarForm.get('tipoMuestra').hasError('required')">{{ config.labels.requiredSelection }}</mat-error>
  </mat-form-field>
  <mat-form-field class="col-12" appearance="fill"  *ngIf="auditarForm.get('tipoMuestra').value !=''">
    <mat-label>{{ config.labels.sampleObservations }}</mat-label>
    <textarea type="number" matInput formControlName="observacionMuestra" maxlength="101"></textarea>
    <mat-error *ngIf="auditarForm.get('observacionMuestra').hasError('required')">{{ config.labels.requiredObservation }}</mat-error>
    <mat-error *ngIf="auditarForm.get('observacionMuestra').hasError('maxlength')">{{ config.labels.maxCharacters }}</mat-error>
  </mat-form-field>

  <mat-form-field class="col-12" appearance="fill">
    <mat-label>{{ config.labels.observations }}</mat-label>
    <textarea type="number" matInput formControlName="observacion"></textarea>
    <mat-error *ngIf="auditarForm.get('observacion').hasError('required')">{{ config.labels.requiredObservation }}</mat-error>
  </mat-form-field>
  <div class="col-12 text-end" >
    <button mat-stroked-button class="col-2 btn-cancelar" ngClass.gt-lg="btn-lg" ngClass.gt-md="btn-md" (click)="cancelar()">{{ config.labels.cancel }}</button>
    <button mat-raised-button color="primary" (click)="saveForm()" ngClass.gt-lg="btn-lg" ngClass.gt-md="btn-md">{{ config.labels.save }}</button>
  </div>
</form>
