import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { CampaignsService } from 'src/app/modules/nomina/services/rest/campaigns.service';
import { CandidatesService } from 'src/app/modules/rrhh/services/candidates.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-modal-campaign-edit',
  templateUrl: './modal-campaign-edit.component.html',
  styleUrls: ['./modal-campaign-edit.component.sass']
})
export class ModalCampaignEditComponent implements OnInit {

  public campaings: any;
  public formCampaign: FormGroup;
  private userRrhhId : any;
  private hired_user:any ;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ModalCampaignEditComponent>,
  public dialog: MatDialog,
  private alert: AlertsService,
  private fb: FormBuilder,
  private serviceCandidate: CandidatesService,
  private serviceCampaign: CampaignsService,
  private authService: AuthService) { }

  ngOnInit() {
    this.userRrhhId = this.authService.getUser().rrhh_id;

    this.formCampaign = this.fb.group({
      campaignId: ['', Validators.required],
    });
    this.serviceCampaign.getAllCampaigns().subscribe(resp => {
      this.campaings = resp.data;
    });
    this.serviceCandidate.getHiredUserID(this.data.candidateId).subscribe( (resp:any) => {
      if(resp != false){
        this.hired_user = resp;
        this.formCampaign.controls.campaignId.setValue(this.hired_user.campaign_id);
      }
    });
  }

   /**
   * @author David Reinoso
   * Metodo para actualizar la campaña del hired_user
   */
  saveCampaig(){
    this.serviceCandidate.changeCampaign(this.hired_user.id, {campaign_id: this.formCampaign.controls.campaignId.value}).subscribe(resp => {
      if(resp){
        this.alert.alertSuccess('Campaña actualizada!', 'Se actualizo la campaña correctamente.');
        this.dialogRef.close('');
      }
    },err => {});
  }

   /**
   * @author David Reinoso
   * Metodo para actualizar la campaña en la tabla de asignacion temporal de campaña
   */
  saveTemporalyCampaig(){
    this.serviceCandidate.saveTemporaryCampaignAssignment({
      campaign_id: this.formCampaign.controls.campaignId.value,
      candidate_id: this.data.candidateId,
      rrhh_id: this.userRrhhId
    }).subscribe(resp => {
      if(resp){
        this.alert.alertSuccess('Campaña actualizada!', 'Se actualizo la campaña correctamente.');
        this.dialogRef.close('');
      }
    },err => {});
  }

  callMethod(){
    this.serviceCandidate.getHiredUserID(this.data.candidateId).subscribe( (resp:any) => {
      if(resp != false){
        this.saveCampaig();
      }
      this.saveTemporalyCampaig();

    });
  }

}
