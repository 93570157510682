import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { JuridicaService } from 'src/app/modules/juridica/services/juridica.service';
import { SuspencionProcessService } from 'src/app/modules/juridica/services/suspencion-process.service';

@Component({
  selector: 'app-suspension-boss-view',
  templateUrl: './suspension-boss-view.component.html',
  styleUrls: ['./suspension-boss-view.component.sass']
})
export class SuspensionBossViewComponent implements OnInit {
  public dataInfo: any;
  public suspension: any;
  public startDate: string;
  public endDate: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private juridica: SuspencionProcessService,              
              public dialogRef: MatDialogRef<SuspensionBossViewComponent>,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
  
    this.juridica.getSuspencionManagedById(this.data.data.idProcess).subscribe(resp => {
      this.dataInfo = resp.data;
    });

  }


}
