<h2 mat-dialog-title>{{data.title}}
    <button style="float: right;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross"></i>
    </button>
</h2>
 <mat-divider></mat-divider>
  <br>
    <form [formGroup]="options" class="row">
  
      <div class="col-4" *ngFor="let item of plantilla">
        <mat-form-field class="col-12" appearance="fill">
          <mat-label>{{item.label}}</mat-label>
          <input matInput [value]="item.value" readonly>
        </mat-form-field>
      </div>
  
      <div class="col-4" *ngFor="let item of writable">
        <mat-form-field class="col-12" appearance="fill">
          <mat-label>{{item.label}}</mat-label>
          <input [formControlName]="item.label" matInput>
        </mat-form-field>
      </div>
  
    </form>
  
   <div class="row">
  
        <div class="col-4"></div>
        <div class="col-4"><button class="col-12" mat-stroked-button color="primary" (click)="copiarTexto()" >Copiar plantilla en texto <i class=" fi-rr-copy-alt"></i></button></div>
        <div class="col-4"></div>
        
  </div>

