import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { Observable, of } from 'rxjs';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { DialogStateService } from 'src/app/core/services/utils/DialogSurveysState.service';
import { SurveyService } from 'src/app/modules/encuestas/services/suervey.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { environment } from 'src/environments/environment';
import { SweetAlertResult } from 'sweetalert2';

const ENCUESTAS_INTERNAS = environment.ENCUESTAS_INTERNAS;


@Component({
    selector: 'app-modal-surveys-user',
    templateUrl: './modal-surveys-user.component.html',
    styleUrls: ['./modal-surveys-user.component.sass']
})
export class ModalSurveysUserComponent implements OnInit, OnDestroy {

    @ViewChild('tabGroup') tabGroup: MatTabGroup;
    private countSurveyAnswered: number = 0;
    private surveySaveComplete: any[] = []
    private readonly replaceUrlStorageImg:string = ENCUESTAS_INTERNAS.replace(/api\//g,'');

    public indexTabSurvey: number = 0
    surveyList: any[] = []

    constructor(
        private suerveyService: SurveyService,
        private alertService: AlertsService,
        private dialigRef: MatDialogRef<ModalSurveysUserComponent>,
        private sessionStorge: StorageService,
        private dialogStateService:DialogStateService
    ) { }

    ngOnInit(): void {  
        this.surveyList = this.getSurveys;
    }

    ngOnDestroy(){
        if (this.dialogStateService.isDialogOpen()) {
            this.dialogStateService.resetDialog();
          }
    }

    /**
    * Metodo que se encarga de obtenerlas encuestas a aplicar al usuario
    * @returns 
    */
    get getSurveys(): any[] {
        const surveysList = this.sessionStorge.getStorage('surveys');        
        if (surveysList !== undefined && surveysList !== null && Array.isArray(surveysList) == true && surveysList.length > 0) {
            let surveysNotSkiped = surveysList.filter( survey => survey?.is_skiped == false )
            return surveysNotSkiped
        }
        return [];
    }

    /**
     * Metodo que se encarga de guardar una solicitud
     * @author Juan David Guerrero Vrgas
     * @param survey:any {any}
     */
    saveSurvey(surveyAnswers: any, indexSurvey: number): void {
        /** Variable de control donde se recolectara la informaicon por separado para su funcion especifica **/

        let sections = surveyAnswers.sections;
        const surveyActual = this.surveyList[indexSurvey];
        
        sections.forEach((section: any, index: number) => {
            if (Array.isArray(section.fields)) {
                section.fields.forEach((field: any, indexField: number) => {
                    if (surveyAnswers.hasOwnProperty('answer') == true) {
                        for (const key in surveyAnswers.answer[index]) {
                            if (key === field.key && field.seeSons === true && field.type !== 'image') {
                                field.value = surveyAnswers.answer[index][key];
                                break;
                            }else if (key === field.key && field.seeSons === true && field.type == 'image') {
                                if(surveyAnswers.answer[index][key] !== undefined){
                                    field.value = surveyAnswers.answer[index][key].replace(this.replaceUrlStorageImg,'');
                                }else{
                                    field.value = ""
                                }
                                break;
                            }
                        }
                    }
                    switch (field.controlType) {
                        case 'multiselect':
                            if (!Array.isArray(field.value)) {
                                field.value = [field.value]
                            }
                            break;
                    }

                });
            }
        });

        const formSaveSurvey = {
            sections: sections
        };        

        this.suerveyService.saveAnswer(surveyActual.id, formSaveSurvey).subscribe(
            (restResponse) => {

                this.countSurveyAnswered++;
                let filterdSurveys = this.getSurveys.filter( (survey:any)=> survey.id != surveyActual.id );
                this.surveyList[indexSurvey]['is_skiped'] = true
                this.sessionStorge.removeStorage('surveys')
                this.sessionStorge.setStorage('surveys', filterdSurveys);
                if (indexSurvey == (this.surveyList.length - 1)) {
                    this.alertService.alertSuccess('Registro exitoso', 'Porque tu opinión nos importa mucho, gracias por responder las encuestas.');
                    this.dialogStateService.resetDialog();
                    this.dialigRef.close();

                } else {
                    this.indexTabSurvey++;
                }
            });
        
    }

    /**
     * Metodo que se encarga de obtener el total de encuestas
     * @author Juan David Guerrero Vargas
     * @returns {number} number
     */
    get totalSurvey(): number {
        if (Array.isArray(this.surveyList) == false) {
            return 0;
        } else if (Array.isArray(this.surveyList)) {
            return this.surveyList.length
        }

    }

    /**
     * Metodo que se encarga de determinar una encuesta es opcional o es obligatoria
     * @param survey:any {any} Encuesta a evaluar
     * @returns {Boolean} boolean True en caso de ser opcional False de lo contrario
     */
    isOptionalSurvey(survey):boolean{
        let launch_survey = survey.launch_configuration
        if(launch_survey?.is_optional !== undefined){
            return Boolean(launch_survey.is_optional)
        }
        return false
    }

    /**
     * Metodo que se encarga de saltar entre tabs 
     * @params index:number {number} indice de la encuesta a saltr
     */
    skipSurvey(index:number){
        if(this.indexTabSurvey == (this.surveyList.length - 1)){
            this.dialigRef.close();
        }else{
            this.indexTabSurvey++;
        }        
        this.surveyList[index]['is_skiped'] = true
        this.sessionStorge.setStorage('surveys', this.surveyList);
    }

}
