import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { SolicitudesService } from '../../../services/rest/solicitudes.service';
import { Solicitud } from '../../Class/solicitud';
import { AprobarSolcitudComponent } from '../componentes/aprobar-solcitud/aprobar-solcitud.component';
import { ConsultarProveedorComponent } from '../componentes/consultar-proveedor/consultar-proveedor.component';
import { CrearSolicitudComponent } from '../componentes/crear-solicitud/crear-solicitud.component';
import { ProcesarSolicitudComponent } from '../componentes/procesar-solicitud/procesar-solicitud.component';

@Component({
  selector: 'app-solicitudes-control',
  templateUrl: './solicitudes-control.component.html',
  styleUrls: ['./solicitudes-control.component.sass']
})
export class SolicitudesControlComponent implements OnInit {

  constructor(){

  }

  ngOnInit(): void {
    localStorage.removeItem('filter_request')
  }

}
