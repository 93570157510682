import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PositionsService {
  user;
  private server =  environment.RRHH_URL;
  constructor(private http: HttpClient, private activeRoute: ActivatedRoute, private authService: AuthService) {
    this.user = this.authService.getUser();
  }

  getPositions(entries){
    return this.http.get(`${this.server}position?${entries}`);
  }
  getPosition(id){
    return this.http.get(`${this.server}position/${id}` );
  }
  postPosition(direction, form){
    return this.http.post(`${this.server}position/${direction}`, form);
  }
  deletePosition(id){
    return this.http.delete(`${this.server}position/delete/${id}`);
  }
  postPositionCreate(value){
    return this.http.post(`${this.server}position/create`, value);
  }

  getPositionsCategory(){
    return this.http.get(`${this.server}position/category`);
  }


}
