import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
const HEALTH_URL = environment.HEALTH_URL;
@Injectable({
  providedIn: 'root'
})
export class HealthService {

  constructor(private http: HttpClient) { }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /*getBooking(isPage: number,size: number, page: number){
    return this.http.get<any>(`${HEALTH_URL}campaigns/?paginate=${isPage}&n=${size}&page=${page}` )
  }
  postBooking(direction, form){
    return this.http.post<any>(`${HEALTH_URL}${direction}`, form )
  }
  putBooking(direction, form){
    return this.http.put<any>(`${HEALTH_URL}${direction}`, form )
  }*/

  getResources(direction, entries?) {
    const params = new HttpParams({ fromObject: entries });
    return this.http.get<any>(`${HEALTH_URL}${direction}`, { params });
  }

  postResources(direction, form) {
    return this.http.post<any>(`${HEALTH_URL}${direction}`, form);
  }
  putResources(direction, form) {
    return this.http.put<any>(`${HEALTH_URL}${direction}`, form);
  }
  deleteResource(direction, id) {
    return this.http.delete<any>(`${HEALTH_URL}${direction}/${id}`);
  }

  getTipicacion(id) {
    return this.http.get<any>(`${HEALTH_URL}tipificationByAction/${id}`);
  }

  postReport(obj) {
    return this.http.post<any>(`${HEALTH_URL}schedule/download`, obj, { responseType: 'blob' as 'json' });
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-11-28
  * Servicio para busqueda de campaña de agendamientos
  * @param {id} number
  * @returns json
  */
  getCampaign(id: number): Observable<any> {
    return this.http.get<any>(`${HEALTH_URL}campaign/${id}`)
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-11-28
  * Servicio para busqueda de citas del cliente
  * @param {client_id} number
  * @param {page} number
  * @param {per_page} number
  * @returns json
  */
  getClientAppoinments( client_id,campaign_id, page, per_page){
    const httpOptions = {
      params: new HttpParams()
        .set('client_id', client_id || '')
        .set('campaign_id', campaign_id || '')
        .set('page', page || '')
        .set('per_page', per_page || 5)
    };
    return this.http.get<any>(`${HEALTH_URL}client_appointments`, httpOptions)
      .pipe(
        catchError(this.handleError)
    );
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-11-28
  * Servicio para busqueda de citas del cliente
  * @param {id_schedule} number
  * @returns json
  */
  getHistoryById(id_schedule){
    const httpOptions = {
      params: new HttpParams()
        .set('id_schedule', id_schedule || '')
    };
    return this.http.get<any>(`${HEALTH_URL}history/dating_history`, httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-12-02
  * Servicio para busqueda de tab de agendadas, confirmadas y canceladas
  * @param {filters} json
  * @returns json
  */
  getAgenda(filters){
    const httpOptions = {
      params: new HttpParams()
        .set('doctor_id', filters.doctor_id || '')
        .set('procedure_id', filters.procedure_id || '')
        .set('number_id', filters.number_id || '')
        .set('tap', filters.tap || '')
        .set('medical_center_id', filters.medical_center_id || '')
        .set('speciality_id', filters.speciality_id || '')
        .set('consulting_room_id', filters.consulting_room_id || '')
        .set('date_start', filters.date_start || '')
        .set('date_end', filters.date_end || '')
        .set('reason_id', filters.reason_id || '')
        .set('page', filters.page || '')
        .set('per_page', filters.per_page || 5)
    };
    return this.http.get<any>(`${HEALTH_URL}schedules/medical_states`, httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-12-02
  * Servicio para busqueda de listado de especialidades
  * @returns json
  */
  getEspeciality(){
    return this.http.get<any>(`${HEALTH_URL}speciality-service`)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-12-02
  * Servicio para busqueda de listado de examenes
  * @returns json
  */
   getExams(){
    return this.http.get<any>(`${HEALTH_URL}procedure`)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-12-02
  * Servicio para busqueda de listado de salas
  * @returns json
  */
   getRooms(){
    return this.http.get<any>(`${HEALTH_URL}consulting-room`)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-12-05
  * Servicio para busqueda de listado de doctores
  * @returns json
  */
   getDoctors(){
    return this.http.get<any>(`${HEALTH_URL}doctor`)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-12-05
  * Servicio para busqueda de listado de tipificaciones de cancelación
  * @returns json
  */
   getTipifications(){
    return this.http.get<any>(`${HEALTH_URL}tipification/cancel`)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-12-09
  * Servicio para busqueda de listado de doctores por filtros
  * @param {filters} json
  * @returns json
  */
  getDoctorsBy(filters){
    const httpOptions = {
      params: new HttpParams()
        .set('doctor_id', filters.doctor_id || '')
        .set('medical_center_id', filters.medical_center_id || '')
        .set('speciality', filters.speciality || '')
        .set('number_id', filters.number_id || '')
        .set('name', filters.name || '')
        .set('procedure', filters.procedure || '')
        .set('page', filters.page || '')
        .set('per_page', filters.per_page || 5)
    };
    return this.http.get<any>(`${HEALTH_URL}admin/manageDoctors`, httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-12-12
  * Servicio para crear un doctor
  * @param {body} json
  * @returns json
  */
  postDoctor(body){
    return this.http.post<any>(`${HEALTH_URL}doctor`, body)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-12-12
  * Servicio para busqueda de listado de procedimientos por filtros
  * @param {filters} json
  * @returns json
  */
  getProceduresBy(filters){
    const httpOptions = {
      params: new HttpParams()
        .set('duration', filters.duration || '')
        .set('procedure_id', filters.procedure_id || '')
        .set('medical_center_id', filters.medical_center_id || '')
        .set('speciality_id', filters.speciality_id || '')
        .set('page', filters.page || '')
        .set('per_page', filters.per_page || 5)
    };
    return this.http.get<any>(`${HEALTH_URL}admin/manageProcedures`, httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-12-12
  * Servicio para crear un examen
  * @param {body} json
  * @returns json
  */
  postExam(body){
    return this.http.post<any>(`${HEALTH_URL}procedure`, body)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-12-12
  * Servicio para editar un examen
  * @param {body} json
  * @returns json
  */
   putExam(body){
    return this.http.post<any>(`${HEALTH_URL}admin/updateProcedure`, body)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-12-12
  * Servicio para busacar doctor por id
  * @param {id} number
  * @returns json
  */
   getDoctorById(id){
    return this.http.get<any>(`${HEALTH_URL}doctor/${id}`)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-12-13
  * Servicio para editar un doctor
  * @param {body} json
  * @returns json
  */
   putDoctor(body){
    return this.http.put<any>(`${HEALTH_URL}doctor`, body)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-12-13
  * Servicio para busqueda especialidades por doctor o campaña
  * @param {filters} json
  * @returns json
  */
  getSpecialityBy(filters){
    const httpOptions = {
      params: new HttpParams()
        .set('campaign_id', filters.campaign_id || '')
        .set('doctor_id', filters.doctor_id || '')
    };
    return this.http.get<any>(`${HEALTH_URL}specialties/config`, httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }
  /**
  * @author Lina Hueso
  * @createdate 2022-12-02
  * Servicio para busqueda de especialidad por centro medico
  * @param {campaign_id} number
  * @returns json
  */
   getEspecialityByCampaign(campaign_id){
    return this.http.get<any>(`${HEALTH_URL}speciality-service/campaign/${campaign_id}`)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-12-12
  * Servicio para la asignación de especialidades y examenes del doctor
  * @param {body} json
  * @returns json
  */
  postDoctorEspecialities(body){
    return this.http.post<any>(`${HEALTH_URL}admin/saveSpecialtyExam`, body)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-12-12
  * Servicio para busqueda de listado de procedimientos por filtros
  * @param {filters} json
  * @returns json
  */
  getScheduleDoctor(filters){
    const httpOptions = {
      params: new HttpParams()
        .set('doctor_id', filters.doctor_id || '')
        .set('medical_center_id', filters.medical_center_id || '')
        .set('procedure_id', filters.procedure_id || '')
        .set('speciality_id', filters.speciality_id || '')
        .set('date_start', filters.date_start || '')
        .set('date_end', filters.date_end || '')
        .set('page', filters.page || '')
        .set('per_page', filters.per_page || 5)
    };
    return this.http.get<any>(`${HEALTH_URL}seeSchedulesDoctor`, httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }
  /**
  * @author Lina Hueso
  * @createdate 2022-12-15
  * Servicio para buscar las especialidades asigandas del doctor con su procedimiento y clinica
  * @param {doctor_id} number
  * @returns json
  */
   getAssignedSpecialties(doctor_id){
    const httpOptions = {
      params: new HttpParams()
        .set('doctor_id', doctor_id || '')
    };
    return this.http.get<any>(`${HEALTH_URL}admin/assignedSpecialties`, httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }
  /**
  * @author Lina Hueso
  * @createdate 2022-12-15
  * Servicio para buscar las especialidades asigandas del doctor con su procedimiento y clinica
  * @param {doctor_id} number
  * @param {medical_center_id} number
  * @param {speciality_id} number
  * @param {flag} string
  * @returns json
  */
   getAssignedSpecialtiesByCampaing(doctor_id,medical_center_id,speciality_id,flag){
    const httpOptions = {
      params: new HttpParams()
      .set('doctor_id', doctor_id || '')
      .set('medical_center_id', medical_center_id || '')
      .set('speciality_id', speciality_id || '')
      .set('flag', flag || '')

    };
    return this.http.get<any>(`${HEALTH_URL}admin/assignedSpecialtiesByCampaing`, httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

   /**
  * @author Lina Hueso
  * @createdate 2022-12-15
  * Servicio para buscar el doctor de acuerdo a la campaña
  * @param {medical_center_id} number
  * @returns json
  */
    doctorByCampaign(campaign){
      return this.http.get<any>(`${HEALTH_URL}doctor/campaign/${campaign}`)
      .pipe(
        catchError(this.handleError)
      );
    }

     /**
  * @author Lina Hueso
  * @createdate 2022-12-02
  * Servicio para busqueda de examen por especialidad
  * @param {speciality_id} number
  * @returns json
  */
   getProcedureBySpeciality(speciality_id){
    return this.http.get<any>(`${HEALTH_URL}procedure/speciality/${speciality_id}`)
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
  * @author Javier Castañeda
  * @createdate 2023-03-14
  * Servicio para eliminar horario del doctor
  * @param {id} number
  * @returns json
  */
   deleteDoctorSchedule(body){
    return this.http.post<any>(`${HEALTH_URL}doctor-schedule/confirmed/delete`, body)
    .pipe(
      catchError(this.handleError)
    );
  }

}
