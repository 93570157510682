<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<h3 mat-dialog-title>Editar comentario</h3>
<mat-divider></mat-divider>
<mat-dialog-content>
    <form [formGroup]="formComment" class="comment-form">
        
            <mat-form-field appearance="fill">
                <mat-label>Comentario</mat-label>
                <textarea matInput formControlName="comment" rows="5"></textarea>
            </mat-form-field>

        <button mat-raised-button color="primary" (click)="updateComment()">Guardar</button>
    </form>
</mat-dialog-content>
