import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreguntasService } from 'src/app/modules/auditoria/services/preguntas.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-crear-pregunta',
  templateUrl: './crear-pregunta.component.html',
  styleUrls: ['./crear-pregunta.component.sass']
})
export class CrearPreguntaComponent implements OnInit {

  //Formulario para la creación de preguntas
  questionForm: FormGroup;
  constructor(
    private fb:FormBuilder,
    public questionRef:MatDialogRef<CrearPreguntaComponent>,@Inject(MAT_DIALOG_DATA) public datos:any,
    private preguntaService:PreguntasService
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(){
    this.questionForm = this.fb.group({
      question_type_id: ['',Validators.required],
      question: ['',Validators.required],
      question_type_item_id: [''],
      multiple: this.fb.array([this.fb.group({
        opcion: ''
      }),
      this.fb.group({
        opcion: ''
      })
    ]),
      requerida: [false],
    })
  
  }

  changeSelect(){
    if (this.questionForm.get('question_type_id').value == 3) {
      this.questionForm.get('question_type_item_id').setValidators(Validators.required);
    }else{
      this.questionForm.get('question_type_item_id').setValidators(null);
    }
  }

    /**
  * @author Cristian Gallo
  * Observador para las opciones para preguntas de selección multiple,
  * el cual retonar todas las opciones que hayan
  */
  get getOptions(){
    return this.questionForm.get('multiple') as FormArray;
  }

  /**
  * @author Cristian Gallo
  * Función para guardar una nueva pregunta
  */
  save(){
    this.questionForm.addControl('other_answer', new FormControl(0))
    if (this.questionForm.invalid) {
      return;
    }
    this.preguntaService.saveQuestion(this.questionForm.value).subscribe((resp:any)=>{
      if (resp.respuesta.codigo >= 400) {
        Swal.fire({
          icon: 'error',
          title: '¡Oops!',
          text: 'Lo sentimos ha sucedio un error',
          showConfirmButton: false,
          timer: 2500
        })
        return;
      }
      Swal.fire({
        icon: 'success',
        title: '¡Excelente!',
        text: 'La pregunta se ha creado con éxito',
        showConfirmButton: false,
        timer: 2500
      });
      this.questionRef.close();
    });
  }

  /**
  * @author Cristian Gallo
  * Función para agregar una nueva opción
  */
  addOption(){
    const option = <FormArray>this.questionForm.controls['multiple'];
    option.push(this.fb.group({
      opcion: [''],
    }));
  }

  /**
  * @author Cristian Gallo
  * Función para retirar una opción
  */
  removeOption(item:number){
    const option = <FormArray>this.questionForm.controls['multiple'];
    option.removeAt(item);
  }

}
