import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import {AuthService} from '../../../core/services/rest/auth.service';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PusherChatService {

  private client: Pusher;
  private _rrhhId: number;

  constructor(
    private authService: AuthService
  ) {
    this.client = new Pusher(
      environment.PUSHER_CHAT_APP_KEY,
      {
        cluster: environment.pusher_cluster
      }
    );
    this._rrhhId = this.authService.getUser().rrhh_id;
  }

  /**
   * @desc Obtiene los datos del chat
   */
  public getChatConnection() {
    return this.client.subscribe(`chat${this._rrhhId}`);
  }

  /**
   * @desc Obtiene el estado de la notificación del usuario
   */
  public getNotifyUserState() {
    return this.client.subscribe(`NotifyUserState${this._rrhhId}`);
  }

}
