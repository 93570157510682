import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { startWith } from 'rxjs/internal/operators/startWith';
import { debounceTime, delay, filter, map, takeUntil, tap } from 'rxjs/operators';
import { NoveltiesService } from '../../../services/novelties.service';
import { CampaignsService } from '../../../services/rest/campaigns.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { CandidatesService } from 'src/app/modules/rrhh/services/candidates.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-general-report',
  templateUrl: './general-report.component.html',
  styleUrls: [ './general-report.component.sass' ],
})
export class GeneralReportComponent implements OnInit {
  reportForm: FormGroup;
  filteredOptionsCampaigns: Observable<any[]>;
  Campaings: any;
  Campaings3: any;
  reportDescription: string;
  documents: any = [];
  typeReports: any = [ {
    id: 'retardos',
    name: 'Retardos',
    description: 'Genera el reporte de retardos en base a los filtros seleccionados'
  }, {
    id: 'dominicales',
    name: 'Dominicales',
    description: 'Genera el reporte de Dominicales en base a los filtros seleccionados',
  }, {
    id: 'nocturnos',
    name: 'Recargo nocturnos',
    description: 'Genera el reporte de recargos nocturnos en base a los filtros seleccionados'
  }, {
    id: 'historial_justificados',
    name: 'Novedades antes de justificar',
    description: 'Genera el reporte de novedades antes de ser justificadas en base a los filtros seleccionados'
  }, {
    id: 'all',
    name: 'Reporte Novedades',
    description: 'Genera el reporte de Retardos, ausencias, ausencias por no deslogueo pendientes por justificar y vacaciones, incapacidades, permisos, asistencias, suspensiones, compensatorios aprobados) en base a los filtros seleccionados'
  } ];
  nameReport: any;

  /** control for the selected campaign for server side filtering */
  public CampaignServerSideCtrl: FormControl = new FormControl();
  /** control for filter for server side. */
  public campaignServerSideFilteringCtrl: FormControl = new FormControl('');
  /** indicate search operation is in progress */
  public searching: boolean = false;
  /** list of campaigns filtered after simulating server side search */
  public filteredServerSideCampaigns: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  campaigns: any;
  campaign_id: any;
  formData = new FormData();

  constructor(
    private service: CampaignsService,
    private novelties: NoveltiesService,
    private users: CandidatesService,
    private alertsService: AlertsService,
  ) {
  }

  ngOnInit() {
    this.reportForm = new FormGroup({
      typeReport: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      campaignId: new FormControl(''),
      docNumber: new FormControl(''),
      docType: new FormControl(1),
      });
      
      this.reportForm.controls.typeReport.valueChanges
      .subscribe(resp => {
      const index = this.typeReports.findIndex(x => x.id === resp);
      this.reportDescription = this.typeReports[index].description ?? '';
      });
      this.users.getIdTypes().subscribe(resp => {
        this.documents = resp;
      });
      this.service.getAllCampaigns().subscribe((resp) => {
      this.Campaings = resp.data;
      this.Campaings3 = resp.data;
      
      this.filteredOptionsCampaigns = this.reportForm
      .get('campaignId')
      .valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((x) => (x ? this._filterCampaigns(x) : this.Campaings3.slice()))
      );
      });
  }

  get errorReport(): any {
    return this.reportForm.controls;
  }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-11-04
   * filtra por nombre, se usa para el autocompletable
   * @param value valor a filtrar
   */
  private _filterCampaigns(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.Campaings3.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  /**
   * @author Daniel Dominguez
   * @createdate 2021-11-05
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   */
  displayFnCampaigns(id: number): string {
    if (!id) {
      return '';
    }
    const index = this.Campaings3.findIndex((resp) => resp.id === id);
    return this.Campaings3[index].name;
  }

/**
* Reporte de novedades
* @author Javier Castañeda
*/
public downloadReportNovelties(): void {
  this.novelties
  .downloadReportNovelties(this.reportForm.value)
  .subscribe((resp) => {
  // const _ext = (this.reportForm.controls.typeReport.value === 'nocturnos') ? 'xlsx' : 'csv';
  const dateToFile = moment().format('YYYY-MM-DD HH:MM:SS');
  importedSaveAs(resp, `reporte_novedades_${ dateToFile }.xlsx`);
  });
  }
}
