import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';

const { JURIDICA_URL, RRHH_URL } = environment;

@Injectable({
  providedIn: 'root',
})
export class CesionService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * @author Daniel Dominguez
   * @purpose Servicio para obtener listado de empresas
   */
  getEmployers(): Observable<any> {
    return this.http
      .get<any>(`${RRHH_URL}companies`)
      .pipe(catchError(this.handleError));
  }

  /**
   * @author Daniel Dominguez
   * @purpose Servicio para obtener listado de cesion por filtro
   */

  getCesionList(
    rol?: any,
    campaing?: any,
    document?: any,
    documentType?: any,
    startDate?: any,
    endDate?: any,
    company?: any,
    perPage: any = 5,
    Page?: any
  ) {
    const httpOptions = {
      params: new HttpParams()
        .set('rol', rol || '')
        .set('campaign', campaing || '')
        .set('document', document || '')
        .set('documentType', documentType || '')
        .set('initialDate', startDate || '')
        .set('endDate', endDate || '')
        .set('company', company || '')
        .set('perPage', perPage)
        .set('page', Page || 1),
    };

    return this.http
      .get<any>(`${JURIDICA_URL}cesions/all`, httpOptions)
      .pipe(catchError(this.handleError));
  }
  /**
   * @author Javier Castañeda
   * @purpose Servicio para obtener reporte de cesion por filtro
   */

  getCesionReport(
    rol?: any,
    campaing?: any,
    document?: any,
    documentType?: any,
    startDate?: any,
    endDate?: any,
    company?: any
  ) {
    const httpOptions = {
      params: new HttpParams()
        .set('rol', rol || '')
        .set('campaign', campaing || '')
        .set('document', document || '')
        .set('documentType', documentType || '')
        .set('initialDate', startDate || '')
        .set('endDate', endDate || '')
        .set('company', company || ''),
      responseType: 'blob' as 'json',
    };

    return this.http
      .get<any>(`${JURIDICA_URL}cesions/report`, httpOptions)
      .pipe(catchError(this.handleError));
  }

  /**
   * @author Javier Castañeda
   * @purpose Servicio para creación de cesion
   */
  CreateCesion(data) {
    return this.http
      .post<any>(`${JURIDICA_URL}cesions/store`, data)
      .pipe(catchError(this.handleError));
  }
  /**
   * @author Javier Castañeda
   * @purpose Servicio para descargar de palntilla de cargue masivo de cesion
   */
  getCesionTemplate(id: any) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http
      .get<any>(`${JURIDICA_URL}cesions/template-company/${id}`, httpOptions)
      .pipe(catchError(this.handleError));
  }
  /**
   * @author Daniel Dominguez
   * @purpose Servicio para la carga de la plantilla de Cesion.
   */

  getCesionImport(id: any, data: any) {
    return this.http
      .post<any>(`${JURIDICA_URL}cesions/template-company/${id}`, data)
      .pipe(catchError(this.handleError));
  }

  /**
   * @author Javier Castañeda
   * @purpose Servicio para la carga de archivos de la plntilla masiva de cesion.
   */
  continueProcessMasive(id: any, data: any) {
    return this.http
      .post<any>(
        `${JURIDICA_URL}cesions/template-company/${id}/continueMassiveProcess`,
        data
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * @author Daniel Dominguez
   * @purpose Servicio para obtener un registro de cesion por id
   */
  getCesionById(id: any): Observable<any> {
    return this.http
      .get<any>(`${JURIDICA_URL}cesion/${id}`)
      .pipe(catchError(this.handleError));
  }

  /**
   * @author Javier Castañeda
   * @purpose Actualizar un proceso de cesion
   */
  updateCesion(id: any, body: any): Observable<any> {
    return this.http
      .post<any>(`${JURIDICA_URL}cesion/update/${id}`, body)
      .pipe(catchError(this.handleError));
  }

  /**
   * @author Javier Castañeda
   * @purpose Aprobar o rechazar solicitudes de cesion
   */
  sendResponse(data: any): Observable<any> {
    return this.http
      .put<any>(`${JURIDICA_URL}approveOrRejectCesion`, data)
      .pipe(catchError(this.handleError));
  }

  /**
   * @author Daniel Dominguez
   * @purpose Servicio para la cancelación de una solicitud
   */
  deletedCesion(id: any): Observable<any> {
    const body = [];
    return this.http
      .delete<any>(`${JURIDICA_URL}cesion/${id}/inactive`)
      .pipe(catchError(this.handleError));
  }

  /**
   * @author Javier Castañeda
   * @purpose Aprobar o rechazar solicitudes de cesion con actualizacion (backoffice)
   */
  updateAndApproveOrRejectCesion(id: any, body: any): Observable<any> {
    return this.http
      .put<any>(
        `${JURIDICA_URL}cesion/${id}/updateAndApproveOrRejectCesion`,
        body
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * @author Javier Castañeda
   * @purpose Servicio para la firma de cesion del trabajador
   */
  submitFirm(id: any, data: any) {
    return this.http
      .post<any>(`${JURIDICA_URL}cesion/firmWorker/${id}`, data)
      .pipe(catchError(this.handleError));
  }

  /**
   * Obtiene la firma del contrato en rrhh
   * @param id number ID RRHH
   */
  getContractFirm(id: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('id', id.toString() || '')
        .set('key', 'firma_contrato'),
      responseType: 'blob' as 'json',
    };
    return this.http
      .get<any>(`${RRHH_URL}download/attachmets/hired/user`, httpOptions)
      .pipe(catchError(this.handleError));
  }


  /**
* @author Javier Castañeda
* @purpose Servicio para cosnultar la cesion del trabajador
*/
getWorkerCesionBy(tab?: any, startDate?: any, endDate?: any, state?: any, perPage: any = 5, Page?: any): Observable<any> {
  const httpOptions = {
    params: new HttpParams()
      .set('tab', tab || '')
      .set('initialDate', startDate || '')
      .set('endDate', endDate || '')
      .set('state', state || '')
      .set('perPage', perPage)
      .set('page', Page || 1)
  };
  return this.http.get<any>(`${JURIDICA_URL}cesion/worker`, httpOptions)
    .pipe(catchError(this.handleError));
}


/**
* @author Javier Castañeda
* @purpose Servicio para cosnultar la cesion pendientes juridico
*/
getJuridicoCesionBy(tab?: any, company?: any, startDate?: any, endDate?: any, campaign?: any, document?: any, idType?: any, state?: any, perPage: any = 5,Page?: any): Observable<any> {
 const httpOptions = {
   params: new HttpParams()
     .set('tab', tab || '')
     .set('company', company || '')
     .set('initialDate', startDate || '')
     .set('endDate', endDate || '')
     .set('campaign', campaign || '')
     .set('document', document || '')
     .set('documentType', idType || '')
     .set('state', state || '')
     .set('perPage', perPage)
     .set('page', Page || 1)
 };
 return this.http.get<any>(`${JURIDICA_URL}cesion/juridicRol`, httpOptions)
   .pipe(catchError(this.handleError));
}


/**
* @author Daniel Dominguez
* @purpose Servicio para cosnultar los otro si pendientes juridico
*/
getJuridicoReportBy(tab?: any, company?: any, startDate?: any, endDate?: any, campaign?: any, document?: any, idType?: any, state?: any): Observable<any> {
 const httpOptions = {
   params: new HttpParams()
     .set('tab', tab || '')
     .set('company', company || '')
     .set('initialDate', startDate || '')
     .set('endDate', endDate || '')
     .set('campaign', campaign || '')
     .set('document', document || '')
     .set('documentType', idType || '')
     .set('state', state),
     responseType: 'blob' as 'json'
 };
 return this.http.get<any>(`${JURIDICA_URL}cesion/juridicRol/report`, httpOptions)
   .pipe(catchError(this.handleError));
}


  /**
* @author Javier Castañeda
* @purpose Servicio para descarga documento cesion
*/
downloadPDF(id: any) {
  const httpOptions = {
    responseType: 'blob' as 'json'
  };
  return this.http.get<any>(`${JURIDICA_URL}downloadPdfCesion/${id}`, httpOptions)
    .pipe(
      catchError(this.handleError)
    );
}

 /**
  * @author Javier Castañeda
  * @purpose Aprobar o rechazar firma y documento cesion
  */
  aprobeOrRejectFirm(id: any, data: any): Observable<any> {
    return this.http.put<any>(`${JURIDICA_URL}cesion/juridic/aproveOrReject/${id}`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

   /**
  * @author Javier Castañeda
  * @purpose Obtener los usuarios de una empresa
  */
    getUsersByCompany(id: any): Observable<any> {
      return this.http.get<any>(`${RRHH_URL}users/company/${id}`)
        .pipe(
          catchError(this.handleError)
        );
    }

}
