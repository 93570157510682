import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {CandidatesService} from '../../../services/candidates.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-personal-reference-up',
  templateUrl: './personal-reference-up.component.html',
  styleUrls: ['./personal-reference-up.component.sass']
})
export class PersonalReferenceUpComponent implements OnInit {

  selectedFile: File | null = null;
  fileName: string;

  constructor(
    public dialogRef: MatDialogRef<PersonalReferenceUpComponent>,
    public candidateService: CandidatesService,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.getReferenceFileName();
  }

  onSubmit() {
    this.upPersonalReferenceTemplate();
  }

  onFileChange(event: any ) {
    this.selectedFile = event.target.files[0];
  }

  onCancel() {
    this.dialogRef.close();
  }

  getReferenceFileName() {
    this.candidateService.getReferenceFileName().subscribe((resp: any ) => {
      this.fileName = resp.basename;
    });
  }

  upPersonalReferenceTemplate() {
    const data = {
      template: this.selectedFile,
    };

    this.candidateService.upPersonalReferenceTemplate(data).subscribe(
  response => {
        this.snackBar.open('Tu plantilla ha sido procesada satisfactoriamente'
          , 'cerrar', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['green-snackbar'],
          duration: 20000,
        });
      },
      error => {
        this.snackBar.open('El documento cargado no ha podido ser procesado correctamente'
          , '', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['red-snackbar'],
          duration: 20000,
        });
      });

    this.dialogRef.close();
  }

}
