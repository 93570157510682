<div class="my-2 mx-5">
  <div class="d-flex justify-content-between">
    <h3 class="ms-0">Bloqueo de solicitudes</h3>
    <button style="float: right" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
  </div>
  <div mat-dialog-content>
    <span class="my-5">En este espacio podrás elegir el rango de fechas durante el cual quieres bloquear la creación de solicitudes.</span>
    <mat-divider class="my-3" ></mat-divider>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Rango de fechas</mat-label>
          <mat-date-range-input [formGroup]="dateBlock" [rangePicker]="picker">
            <input matStartDate formControlName="inicial">
            <input matEndDate formControlName="final">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error *ngIf="dateBlock.controls.inicial.hasError('required')">Fecha de inicio es requerida</mat-error>
          <mat-error *ngIf="dateBlock.controls.final.hasError('required')">Fecha de final es requerida</mat-error>
        </mat-form-field>
      </div>
    </div>

  </div>
  <div style="float: right;">
    <button class="compras-modal-button" mat-stroked-button mat-dialog-close>Cancelar</button>
    <button class="compras-modal-button" mat-raised-button type="button" (click)="saveDate()" style= "margin-left: 10px;" color= "primary">Aceptar</button>
  </div>
</div>
