<h4 class="title-estado"><i class="fi-rr-settings"></i> Estado solicitud</h4>
<form [formGroup]="solicitudForm" class="row">
        <mat-form-field appearance="fill" class="col-12">
            <mat-label>Estado</mat-label>
            <mat-select formControlName="estado" #estado (selectionChange)="changeSelect()">
                <mat-option *ngFor="let estado of estados" [value]="estado.id">{{estado.nombre}}</mat-option>
            </mat-select>
            <mat-error>Por favor seleccione una opción.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6" *ngIf="estado.value == 50">
            <mat-label>Próximo seguimiento</mat-label>
            <input matInput [matDatepicker]="seguimiento" formControlName="proximo_seguimiento" [min]="startDate" [max]="endDate">
            <mat-datepicker-toggle matSuffix [for]="seguimiento">
              <mat-icon matDatepickerToggleIcon><i class="fi-rr-calendar"></i></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #seguimiento></mat-datepicker>
            <mat-error>Por favor ingrese una fecha.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6" *ngIf="estado.value == 50">
            <mat-label>Objetivo planteado</mat-label>
            <input type="number" matInput formControlName="objetivo_planteado">
            <mat-error>Por favor ingrese un valor.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6">
            <mat-label>Causa raíz</mat-label>
            <mat-select formControlName="causa_raiz">
                <mat-option *ngFor="let causa of causas$ | async" [value]="causa.id">{{causa.nombre}}</mat-option>
            </mat-select>
            <mat-error>Por favor seleccione una opción.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6">
            <mat-label>Cumple compromiso</mat-label>
            <mat-select formControlName="cumple_compromiso">
                <mat-option *ngFor="let compromiso of compromisos$ | async" [value]="compromiso.id">{{compromiso.nombre}}</mat-option>
            </mat-select>
            <mat-error>Por favor seleccione una opción.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" [ngClass]="{'col-12': estado.value == null,'col-6': (estado.value == 52 || estado.value == 50)}">
            <mat-label>Presenta evolución</mat-label>
            <mat-select formControlName="presenta_evolucion">
                <mat-option *ngFor="let evolucion of evoluciones$ | async" [value]="evolucion.id">{{evolucion.nombre}}</mat-option>
            </mat-select>
            <mat-error>Por favor seleccione una opción.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6" *ngIf="estado.value == 50">
            <mat-label>Ingresa KPI</mat-label>
            <input type="number" matInput formControlName="kpi">
            <mat-error>Por favor ingrese un valor KPI.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6" *ngIf="estado.value == 52">
            <mat-label>Indicador final</mat-label>
            <input type="number" matInput formControlName="indicador_final">
            <mat-error>Por favor ingrese un valor.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12" *ngIf="estado.value == 52">
            <mat-label>Cuartil final</mat-label>
            <mat-select formControlName="cuartil_final">
                <mat-option *ngFor="let cuartil of cuartiles$ | async" [value]="cuartil.id">{{cuartil.nombre}}</mat-option>
            </mat-select>
            <mat-error>Por favor seleccione una opción</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12">
            <mat-label>Conclusiones</mat-label>
            <textarea type="text" matInput formControlName="conclusion_sesion"></textarea>
            <mat-error>Por favor ingrese una conclusión.</mat-error>
        </mat-form-field>
        <div class="col-12 text-center">
         <button mat-stroked-button class="btn-cancelar" (click)="cancelar()">Cancelar</button>
         <button mat-raised-button color="primary" class="btn-guardar" (click)="saveSeguimiento()">Guardar</button>
        </div>
</form>