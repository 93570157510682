import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';

const { SABLE_URL, RRHH_URL } = environment;

@Injectable({
  providedIn: 'root',
})
export class SableService {
  constructor(private http: HttpClient, private authService: AuthService) { }

  /**
    * @author Fabian Garcia
    * @createdate 2023-06-20
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  /**
    * @author Fabio Garcia
    * @purpose Obtener el listado de la data de los selects. 
    * @param servicio Tipo de servicio a consumir. 
    * @param server Servidor seleccionado en la vista. 
  */
  GetDataSelectsServer(servicio: any, server: any): Observable<any> {
    return this.http.get<any>(`${SABLE_URL}${servicio}/${server}`)
      .pipe(
        catchError(this.handleError)
      );
  }
  /**
    * @author Fabio Garcia
    * @createdate 2023-06-20
    * Metodo que trae la informacion para llenar en los select del formulario. 
    * @param servicio Servidor seleccionado en el formulario. 
  */
  GetDataSelects(servicio: any): Observable<any> {
    return this.http.get<any>(`${SABLE_URL}${servicio}`)
      .pipe(
        catchError(this.handleError)
      );
  }
  /**
    * @author Fabio Garcia
    * @purpose Obtener el listado de la data de los selects
  */
  GetDataColumns(data: any): Observable<any> {
    return this.http.post<any>(`${SABLE_URL}consulta`, data)
      .pipe(
        catchError(this.handleError)
      );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-20
    * Metodo que trae la informacion para llenar en los select del formulario. 
    * @param data Informacion enviada desde el formulario. 
  */
  saveConfigDownloads(data: any): Observable<any> {
    return this.http.post<any>(`${SABLE_URL}generarDescarga`, data).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-20
    * Metodo que trae las descargas almacenadas en el sistema. 
    * @param page Informacion de la paginacion.
    * @param filters Filtros de busqueda de la vista. 
  */
  getDownloads(paginator: any, filters: any): Observable<any> {
    return this.http.get<any>(`${SABLE_URL}descarga?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&date=${filters.date}&state=${filters.state}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-20
    * Metodo que trae el archivo zip generado de la descarga. 
    * @param id Id de la descarga seleccionada.
  */
  downloadFile(id: number): Observable<any> {
    return this.http.get<any>(`${SABLE_URL}downloadFileZip/${id}`, { responseType: 'blob' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-20
    * Metodo que elimina una descarga generada. 
    * @param id Id de la descarga seleccionada.
  */
  deleteDownload(id: number): Observable<any> {
    return this.http.delete<any>(`${SABLE_URL}descarga/${id}`).pipe(
      catchError(this.handleError)
    );
  }
}