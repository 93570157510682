import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { LivechatRoutingModule } from './livechat-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ShortcutPipe } from './pipes/shortcut.pipe';
import { FiltertemplatePipe } from './pipes/filtertemplate.pipe';

@NgModule({
  declarations: [ShortcutPipe, FiltertemplatePipe],
  imports: [
    CommonModule,
    LivechatRoutingModule,
    SharedModule,
    NgxChartsModule
  ],
  exports:[
    ShortcutPipe,
    NgxChartsModule
  ]
})
export class LivechatModule { }
