<div class="novelities">
  <div class="header">
    <h1>Liquidación</h1>
    <p>En este espacio podrás revisar y/o gestionar <br>liquidaciones, no olvides,"El respeto y la
      empatiá son clave"</p>
  </div>

  <div>

    <mat-form-field appearance="fill" class="col-3 filter">
      <mat-label>Filtrar por estado</mat-label>
      <mat-select [formControl]="filterControl2">
        <mat-option value="">Seleccionar</mat-option>
        <mat-option *ngFor="let item of states" [value]="item.id">
          {{item?.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="col-3 filter">
      <mat-label>Filtrar por identificación</mat-label>
      <input matInput type="text" #input autocomplete="off" (keydown.enter)="onBlurMethod($event.target.value)">
      <mat-select name="ampm" class="ampm" matSuffix [formControl]="typeDocument"
        style="width: 60px; margin-left: 5px;">
        <mat-option *ngFor="let item of documents" [value]="item?.id">{{item.key}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="col-3 filter">
      <mat-label>Filtrar por campaña</mat-label>
      <mat-select [formControl]="filterControl">
        <mat-option *ngFor="let item of Campaings" [value]="item.id">
          {{item?.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="downloadReport()" class="download">Descargar reporte
      <span class="fi-rr-download"></span>
    </button>

    <div class="table">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="acciones">
          <th class="start" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="viewJustification(element)">
              <i class="fi-rr-eye"></i>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef> Nombre trabajador</th>
          <td mat-cell *matCellDef="let element"> {{element.name}}</td>
        </ng-container>

        <ng-container matColumnDef="numero">
          <th mat-header-cell *matHeaderCellDef>Número de identificación</th>
          <td mat-cell *matCellDef="let element">{{element.documento}}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element">{{element.email}}</td>
        </ng-container>

        <ng-container matColumnDef="campana">
          <th mat-header-cell *matHeaderCellDef> Campaña</th>
          <td mat-cell *matCellDef="let element">{{element?.campaign?.name}}</td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th class="end" mat-header-cell *matHeaderCellDef> Estado </th>
          <td mat-cell *matCellDef="let element">
            <span class="circle" *ngIf="element.state == 1">Activo</span>
            <span class="circle2" *ngIf="element.state == 3">Inactivo</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="8">No hay datos que coincidan con el filtro: "{{input.value}}"</td>
        </tr>

      </table>
      <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)"
        [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>

    </div>

  </div>

</div>
