<div class="novelities">
  <div class="header">
    <h1 style="color: #353535;">Novedades de incapacidad</h1>
    <p>En este espacio podrás revisar y/o gestionar las novedades de<br>incapacidades, no olvides,"El respeto y la
      empatía son clave"</p>
  </div>
  <mat-tab-group style="margin-top: 36px;">
    <mat-tab label="Incapacidades">
      <mat-form-field appearance="fill" class="col-2 filter">
        <mat-label>Estado</mat-label>
        <mat-select [formControl]="stateType">
          <mat-option value="">Seleccionar</mat-option>
          <mat-option *ngFor="let item of states" [value]="item.id">
            {{item?.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-2 filter">
        <mat-label>Fecha</mat-label>
        <mat-date-range-input [formGroup]="reportForm" [rangePicker]="picker">
          <input matStartDate [formControl]="reportForm.controls['start']" placeholder="Start date" #startDate>
          <input matEndDate [formControl]="reportForm.controls['end']" placeholder="End date" #endDate
                 (dateChange)="dateRangeChange()">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-2 filter">
        <mat-label>Identificación</mat-label>
        <input matInput type="text" #input autocomplete="off" (keydown.enter)="onBlurMethod($event.target.value)">
        <mat-select name="ampm" class="ampm" matSuffix [formControl]="typeDocument"
                    style="width: 50px; margin-left: 5px;">
          <mat-option *ngFor="let item of documents" [value]="item?.id">{{item.key}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-2 filter">
        <mat-label>Campaña</mat-label>
        <mat-select [formControl]="filterControl">
          <mat-option value="">Todos</mat-option>
          <mat-option *ngFor="let item of Campaings" [value]="item.id">
            {{item?.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!--<mat-form-field appearance="fill" class="col-2 filter">
        <mat-label>Filtrar por tipo de novedad</mat-label>
        <mat-select [formControl]="stateType">
          <mat-option value="">Todos</mat-option>
          <mat-option *ngFor="let item of states" [value]="item.id">
            {{item?.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>-->


      <button mat-raised-button color="primary" (click)="downloadReport()" class="download">Descargar reporte
        <span class="fi-rr-download"></span>
      </button>

      <div class="table">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="acciones">
            <th class="start" mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="viewJustification2(element)"
                      *ngIf="element.noveltyTypeKey == 27 && element.stateKey == 'aprobada' || element.noveltyTypeKey == 27 && element.stateKey == 'rechazada'">
                <i class="fi-rr-eye"></i>
              </button>
              <button mat-icon-button (click)="viewJustification(element)" *ngIf="element.noveltyTypeKey == 27 && element.stateKey == 'revision'">
                <i class="fi-rr-settings"></i>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef> Fecha</th>
            <td mat-cell *matCellDef="let element"> {{element.novelty_date_start | date:"dd-MM-yyyy"}} </td>
          </ng-container>

          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef> Nombre trabajador</th>
            <td mat-cell *matCellDef="let element"> {{element.name}}</td>
          </ng-container>

          <ng-container matColumnDef="numero">
            <th mat-header-cell *matHeaderCellDef>Número de identificación</th>
            <td mat-cell *matCellDef="let element">{{element.id_number}}</td>
          </ng-container>

          <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef>Tipo de novedad</th>
            <td mat-cell *matCellDef="let element">{{element.noveltyTypeName}}</td>
          </ng-container>

          <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef> Estado</th>
            <td mat-cell *matCellDef="let element">{{element.stateName}}</td>
          </ng-container>

          <ng-container matColumnDef="campana">
            <th class="end" mat-header-cell *matHeaderCellDef> Campaña</th>
            <td mat-cell *matCellDef="let element">{{element.campaignName}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8">No hay datos que coincidan con el filtro: "{{input.value}}"</td>
          </tr>

        </table>
        <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)"
                       [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>

      </div>
    </mat-tab>
    <mat-tab label="Permisos aprobados - Jurídica">
      <mat-form-field appearance="fill" class="col-2 filter">
        <mat-label>Fecha</mat-label>
        <mat-date-range-input [formGroup]="reportForm3" [rangePicker]="picker3">
          <input matStartDate [formControl]="reportForm3.controls['start3']" placeholder="Start date" #startDate>
          <input matEndDate [formControl]="reportForm3.controls['end3']" placeholder="End date" #endDate
                 (dateChange)="dateRangeChange3()">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
        <mat-date-range-picker #picker3></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-2 filter">
        <mat-label>Identificación</mat-label>
        <input matInput type="text" #input autocomplete="off" (keydown.enter)="onBlurMethod3($event.target.value)">
        <mat-select name="ampm" class="ampm" matSuffix [formControl]="typeDocument3"
                    style="width: 50px; margin-left: 5px;">
          <mat-option *ngFor="let item of documents" [value]="item?.id">{{item.key}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-2 filter">
        <mat-label>Campaña</mat-label>
        <mat-select [formControl]="filterControl3">
          <mat-option value="">Todos</mat-option>
          <mat-option *ngFor="let item of Campaings" [value]="item.id">
            {{item?.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="getPermits(this.pageSize3, this.page3, true)" class="download">Descargar reporte
        <span class="fi-rr-download"></span>
      </button>

      <div class="table">
        <table mat-table [dataSource]="dataSource3">
          <ng-container matColumnDef="acciones">
            <th class="start" mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="viewJustification3(element)">
                <i class="fi-rr-eye"></i>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef> Fecha</th>
            <td mat-cell *matCellDef="let element"> {{element.novelty_date_start | date:"dd-MM-yyyy"}} </td>
          </ng-container>

          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef> Nombre trabajador</th>
            <td mat-cell *matCellDef="let element"> {{element.name}}</td>
          </ng-container>

          <ng-container matColumnDef="numero">
            <th mat-header-cell *matHeaderCellDef>Número de identificación</th>
            <td mat-cell *matCellDef="let element">{{element.id_number}}</td>
          </ng-container>

          <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef>Tipo de novedad</th>
            <td mat-cell *matCellDef="let element">{{element.noveltyTypeName}}</td>
          </ng-container>

          <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef> Estado</th>
            <td mat-cell *matCellDef="let element">{{element.stateName}}</td>
          </ng-container>

          <ng-container matColumnDef="campana">
            <th class="end" mat-header-cell *matHeaderCellDef> Campaña</th>
            <td mat-cell *matCellDef="let element">{{element.campaignName}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8">No hay datos que coincidan con el filtro: "{{input.value}}"</td>
          </tr>

        </table>
        <mat-paginator [length]="length3" [pageSize]="pageSize3" (page)="pageEvent3($event)"
                       [pageSizeOptions]="pageSizeOptions3">
        </mat-paginator>

      </div>
    </mat-tab>
  </mat-tab-group>

</div>
