import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { error } from 'selenium-webdriver';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { ProfileService } from 'src/app/modules/ciu/services/rest/profile.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass']
})
export class ChangePasswordComponent implements OnInit {

  passForm: FormGroup;
  hide: boolean = true;
  hideDos: boolean = true;
  hideTres: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public authService: AuthService,
              private profileService: ProfileService,
              private dialogRef:MatDialogRef<ChangePasswordComponent>,
              private snack: MatSnackBar,
              private destroyService: DestroyComponentService) { }

  ngOnInit(): void {
    this.formControl();
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-05-19
   * Metodo que regula las validaciones de cada campo del formulario de cambio de contraseña
   */
  formControl(): void{

    this.passForm = new FormGroup({
      actual: new FormControl('', [Validators.required,Validators.maxLength(15),Validators.minLength(1)]),
      nueva: new FormControl('', [this.passwordValidate(),Validators.required,Validators.maxLength(15),Validators.minLength(5), this.matchValues2('actual')]),
      confirma: new FormControl('', [this.passwordValidate(),Validators.required,Validators.maxLength(15),Validators.minLength(5), this.matchValues('nueva')])
    });

  }

  get error(): any { return this.passForm.controls; }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que se encarga de las diferentes validaciones de los campos contraseña
   * @returns control de error
   */
  passwordValidate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!/[A-Z]/.test(control.value)) {
        return { hasCapitalCase: { value: control.value } };
      }
      else if (!/[a-z]/.test(control.value)) {
        return { hasSmallCase: { value: control.value } };
      }
      else if (!/[!@#$%^&*()_+=[{};':"|,.<>/?/{};':"|,.<>/?-]/.test(control.value)) {
        return { hasSpecialCharacters: { value: control.value } };
      }
      else if (!/\d/.test(control.value)) {
        return { hasNumber: { value: control.value } };
      }
      return null;
    };
  }

/**
 * @author Daniel Martinez
 * @createdate 2021-01-27
 * Metodo que se encarga de verificar si la contraseña nueva y el campo de validacion son iguales
 * @param matchTo contraseña en el campo de validacion
 * @returns control de error
 */
  matchValues( matchTo: string ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que valida si la contraseña nueva es diferente a la contraseña ya establecida
   * @param matchTo Valor del campo de contraseña nueva
   * @returns control de error
   */
  matchValues2( matchTo: string ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value !== control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que se encarga de omitir algunos caracteres en el campo de contraseña
   * @param event valor a evaluar
   * @returns boolean
   */
  omitSpecialChar(event): any{
    let k;
    k = event.charCode;
    return((k > 32 && k < 126));
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que se encarga de realizar el cambio de la contraseña
   * @param passForm Formulario diligenciado
   */
  changePass(passForm): void{

    const user =  new FormData();

    user.append( 'username' , this.data.userName);
    user.append( 'password' , passForm.value.actual);
    user.append( 'new_password' , passForm.value.confirma);

    this.authService.passwordVerifyLogin(user).subscribe((resp) => {
      if (resp.data?.message === 'OK') {
        let pass =  {'password':passForm.value.confirma};
        this.profileService.changePasswordLogin(resp.data.userId.id,pass).subscribe( (resp)  => {
          this.dialogRef.close();
          this.snack.open('Tu contraseña se ha cambiado con éxito', '', {
            duration: 3500,
            verticalPosition: 'top',
            panelClass : ['green-snackbar']
          });
      })
      } else if(resp.error_code == 401)
      {
        Swal.fire({
          html: `${resp.error}`,
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

}
