import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { ResourcesService } from '../../../services/resources.service';

@Component({
  selector: 'app-resources-rules',
  templateUrl: './resources-rules.component.html',
  styleUrls: ['./resources-rules.component.sass']
})
export class ResourcesRulesComponent implements OnInit {

  createRule = false;
  ruleForm: FormGroup;
  rules: any[] = [];
  days: any[] = [];
  states: boolean = false;
  constructor(private fb:FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private alertNotification: AlertsService,
              private auth: AuthService,
              private resourcesService: ResourcesService) {
              }


  ngOnInit(): void {

    this.ruleForm = this.fb.group({
      'resource_id'   : [null],
      'day'           : [null, Validators.required],
      'start'         : [null, Validators.required],
      'end'           : [null, Validators.required],
      'date_start'    : [null],
      'date_end'      : [null],
      'dynamic'       : [1],
    });

    this.getInfo();
    this.ruleForm.get('end').setValidators(Validators.min(this.ruleForm.get('start').value));
    this.auth.getUser().roles.forEach(element => {
      if (element === 'rrhh::usuario') {
        this.states = true;
      }
    });
  }

  getInfo() {
    this.days = [
      {name: 'Lunes', value: 'Monday'},
      {name: 'Martes', value: 'Tuesday'},
      {name: 'Miércoles', value: 'Wednesday'},
      {name: 'Jueves', value: 'Thursday'},
      {name: 'Viernes', value: 'Friday'},
      {name: 'Sábado', value: 'Saturday'},
    ];
    this.getRules();
  }

  saveRule() {
    this.ruleForm.get('dynamic').setValue(1);
    this.ruleForm.get('resource_id').setValue(this.data.id);
      if (this.ruleForm.valid) {
        if (this.ruleForm.get('start').value <= this.ruleForm.get('end').value) {
          this.resourcesService.createResourceRules(this.ruleForm.value).subscribe( res => {
            this.alertNotification.alertSuccess('Regla creada con éxito', '');
            this.rules.push(this.ruleForm.value);
            this.cancelForm('ruleForm', 'createRule');
          });
        } else {
          this.alertNotification.alertWarning('Cuidado', 'La hora fin no puede ser inferior a la hora de inicio');
        }
      } else {
        this.ruleForm.markAllAsTouched();
      }
  }
  cancelForm(form, arg) {
    this[form].reset();
    this[arg] = !this[arg];
  }

  getRules() {
    this.resourcesService.getResourceRules(this.data.id).subscribe((res: any) => {
      if (res != null) {
        this.rules = res;
        this.rules.reverse();
      }
    });
  }

  removeRule(id) {
    this.alertNotification.alertConfirm('¿Deseas eliminar este horario?').then((result) => {
      if (result.isConfirmed) {
        this.resourcesService.removeResourceRules(id).subscribe((res: any) => {
          this.alertNotification.alertSuccess('Eliminado', res);
          this.getRules();
        });
      }
    });
  }

}
