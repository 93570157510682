<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>

<h3 mat-dialog-title>Asignar solicitud</h3>
<mat-divider>
</mat-divider>
<mat-dialog-content>
    <br>
      <p>Seleccione a que lider asignar la solicitud <br> para su seguimiento.</p>
      <br>
      <div  style="text-align: center;">
        <mat-form-field appearance="fill" style="width: 90%;">
            <mat-label>Seleccione un lider</mat-label>
            <mat-select #leader (selectionChange)="selectLeader(leader.value)" required>
                <mat-option *ngFor="let lider of leaders" [value]="lider.id">
                    {{lider.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
      </div>
      <br>
      

</mat-dialog-content>
<mat-dialog-actions align="center">
    <button  mat-stroked-button color="warn" mat-dialog-close>Cancel</button>
    <button class="primary" mat-flat-button color="primary" (click)="asignarSolicitud()" [disabled]="!leaderId">Aprobar</button>
</mat-dialog-actions>
