import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreguntasService } from 'src/app/modules/auditoria/services/preguntas.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-detalle-pregunta',
  templateUrl: './detalle-pregunta.component.html',
  styleUrls: ['./detalle-pregunta.component.sass']
})
export class DetallePreguntaComponent implements OnInit {

  /**
  * @author Cristian Gallo
  * Variable para almacenar los tipos de preguntas 
  */
  tiposPreguntas = [];
  
  /**
  * @author Cristian Gallo
  * Variable para almacenar los rangos existente por tipo de pregunta lineal
  */
  items_tipo_pregunta;
  questionForm:FormGroup;
  elimantedOptions = [];
  constructor(
    private fb:FormBuilder,
    public questionRef:MatDialogRef<DetallePreguntaComponent>,@Inject(MAT_DIALOG_DATA) public datos:any,
    private preguntaService:PreguntasService
  ) { }

  ngOnInit(): void {
    this.getTypeQuestions();
    this.createForm();
    this.getQuestionById();
  }

  /**
  * @author Cristian Gallo
  * Función para llamar los tipos de preguntas
  */
  getTypeQuestions(){
    this.preguntaService.getTypeQuestions().subscribe((resp:any)=>{
      this.tiposPreguntas = resp;
    })
  }

  createForm(){
    this.questionForm = this.fb.group({
      question_type_id: [{value:'', disabled:true}],
      question: [''],
      multiple: this.fb.array([]),
      question_type_item_id: '',
      requerida: [''],
    });
    this.edit()
  }

  /**
  * @author Cristian Gallo
  * Función para llamar los datos de la pregunta seleccionada
  */
  getQuestionById(){
    this.preguntaService.getQuestionByID(this.datos.id).subscribe((resp:any)=>{
      this.questionForm.get('question_type_id').setValue(resp.question_type_id);
      this.questionForm.get('question').setValue(resp.question);
      this.questionForm.get('requerida').setValue(resp.requerida);
      if (resp.tipo_pregunta.key == 'selección_múltiple' || resp.tipo_pregunta.key == 'lista_desplegable') {
        const option = <FormArray>this.questionForm.controls['multiple'];
        resp.opciones_respuesta_form_pregunta_activos.forEach(opcion => {
          option.push(this.fb.group(opcion));
        });
        this.edit()
      }
      if (resp.tipo_pregunta.key == "escala_lineal") {
        this.items_tipo_pregunta = resp.tipo_pregunta.items_tipo_pregunta;
        this.questionForm.get('question_type_item_id').setValue(resp.question_type_item_id)
      }
    });
  }

  get getOptions(){
    return this.questionForm.get('multiple') as FormArray;
  }

  /**
  * @author Cristian Gallo
  * Función para habilitar o desabilidar campos y botones del formulario,
  * una vez se ingrese al dialog
  */
  edit(){
    if (this.datos.edit) {
      this.questionForm.enable();
      this.questionForm.get('question_type_id').disable();
    }else{
      this.questionForm.disable();
    }
  }

    /**
  * @author Cristian Gallo
  * Función para habilitar campos y botones del formulario usando el boton editar
  */
  btnEdit(){
    this.questionForm.enable();
    this.questionForm.get('question_type_id').disable();
    this.datos.edit = true;
  }

  /**
  * @author Cristian Gallo
  * Función para agregar una nueva opción
  */
  addOption(){
    const option = <FormArray>this.questionForm.controls['multiple'];
    option.push(this.fb.group({
      activo: 1,
      business_intelligence_question_id: this.datos.id,
      created_at: '',
      name: '',
      other_answer: 0,
      updated_at: '',
    }));
  }

  /**
  * @author Cristian Gallo
  * Función para retirar una opción
  */
  removeOption(item:number,id_option){
    const option = <FormArray>this.questionForm.controls['multiple'];
    option.removeAt(item);
    const data = {
      id_pregunta: this.datos.id,
      id_opcion: id_option
    }
    this.elimantedOptions.push(data);
  }

  /**
  * @author Cristian Gallo
  * Función para actualizar información de la pregunta
  */
  updateQuestion(){
    this.questionForm.addControl('other_answer', new FormControl(0));
    this.questionForm.get('question_type_id').enable();
    this.preguntaService.updateQuestion(this.datos.id,this.questionForm.value).subscribe((resp:any)=>{
      this.questionForm.get('question_type_id').disable();
      if (resp.respuesta.codigo != 202) {
        Swal.fire({
          icon: 'error',
          title: '¡Oops!',
          text: 'Lo sentimos ha sucedio un error',
          showConfirmButton: false,
          timer: 3500
        })
        return;
      }
      this.elimantedOptions.forEach(option => {
        this.preguntaService.deleteOpcion(option).subscribe(()=>{});
      });
      Swal.fire({
        icon: 'success',
        title: '¡Excelente!',
        text: 'La pregunta se ha actualizado con éxito',
        showConfirmButton:false,
        timer: 2500
      })
      this.questionRef.close(resp.respuesta.codigo);
    },(err:any)=>{
      this.questionForm.get('question_type_id').disable();
    }
    );
  }

}
