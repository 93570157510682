import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import { auditoriaModel } from 'src/app/modules/coaching/core/interfaces/auditoria.interface';
import { CoachingService } from 'src/app/modules/coaching/services/coaching.service';
import { CoachingState } from 'src/app/modules/coaching/state/coaching.state';
import { selectedListAuditoria } from 'src/app/modules/coaching/state/selectors/auditoria.selector';
import { listCampañas } from 'src/app/modules/coaching/state/selectors/selects.selector';
import Swal from 'sweetalert2';
import { listIndicadores, listProcesos, listCuartiles, listCausas } from '../../../../../../state/selectors/selects.selector';

@Component({
  selector: 'app-form-auditoria-coaching',
  templateUrl: './form-auditoria-coaching.component.html',
  styleUrls: ['./form-auditoria-coaching.component.sass']
})
export class FormAuditoriaCoachingComponent implements OnInit {
  startDate = new Date();
  endtDate = moment(this.startDate).add(30,'d').format();
  acompanamientoForm:FormGroup;
  auditoria$:Observable<any> = new Observable();
  dataAuditoria:auditoriaModel;
  dataCampaign;
  id_asesor;
  campaignJefe:boolean = false;
  //Selects
  indicadores$:Observable<any> = new Observable();
  procesos$:Observable<any> = new Observable();
  campaigns$:Observable<any> = new Observable();
  cuartiles$:Observable<any> = new Observable();
  causas$:Observable<any> = new Observable();
  constructor(
    private fb:FormBuilder,
    private store:Store<CoachingState>,
    private numberPipe:DecimalPipe,
    private coachingService:CoachingService,
    private router:Router,
    private authService:AuthService,
  ) { }

  ngOnInit(): void {
    this.getSelects();
    this.createForm();
    this.getFilterParam();
  }

  createForm(){
    this.acompanamientoForm = this.fb.group({
      tipo_proceso:['',[Validators.required]],
      idAuditoria:[{value:'',disabled:true},[Validators.required]],
      nombre_coachee:[{value:'',disabled:true},[Validators.required]],
      cedula_coachee:[{value:'',disabled:true},[Validators.required]],
      campaing:['',[Validators.required]],
      matriz:[{value:'',disabled:true},[Validators.required]],
      indicador:[{value:5,disabled:true},[Validators.required]],
      indicador_inicial:[{value:'',disabled:true},[Validators.required]],
      cuartil_inicial:['',[Validators.required]],
      proximo_seguimiento:['',[Validators.required]],
      objetivo_kpi:['',[Validators.required]],
      causa_raiz:['',[Validators.required]],
      conclusion_sesion:['',[Validators.required]],
    });
    this.auditoria$ = this.store.select(selectedListAuditoria);
    this.auditoria$.forEach(element => {
      if (element[0]) {
        this.dataAuditoria = element[0].auditoria;
        this.dataCampaign = element[0].campaing;
        this.id_asesor = this.dataAuditoria.asesor_id;
        this.acompanamientoForm.get('idAuditoria').setValue(this.dataAuditoria.id);
        this.acompanamientoForm.get('nombre_coachee').setValue(this.dataAuditoria.nombre_asesor);
        this.acompanamientoForm.get('cedula_coachee').setValue(this.dataAuditoria.cedula_asesor);
        this.acompanamientoForm.get('matriz').setValue(this.dataAuditoria.detalle_matriz.nombre);
        this.acompanamientoForm.get('indicador_inicial').setValue(this.dataAuditoria.total_punaje.match(/(\d*.\d{0,3})/)[0]);
      }
    });
  }

  getSelects(){
    this.indicadores$ = this.store.select(listIndicadores);
    this.procesos$ = this.store.select(listProcesos);
    this.cuartiles$ = this.store.select(listCuartiles);
    this.causas$ = this.store.select(listCausas);
    this.campaigns$ = this.store.select(listCampañas);
  }

  save(){
    if (this.acompanamientoForm.invalid) {
      return
    }
    this.acompanamientoForm.enable();
    this.acompanamientoForm.get('proximo_seguimiento').setValue(moment(this.acompanamientoForm.get('proximo_seguimiento').value).format('yyyy-MM-DD'));
    Object.assign(this.acompanamientoForm.value,{
      idCoachee:this.id_asesor,
      ruta:`/mios/coaching/solicitud/`,
      mensaje:`Se te ha realizado un nuevo acompañamiento con ID-`
    });
    this.coachingService.crearCoachee(this.acompanamientoForm.value).subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202) {
        Swal.fire({
          icon:'success',
          title:'¡Excelente!',
          html:`<p>El coaching se ha creado con exito.</p><p><b>ID Coaching ${resp.respuesta.id}</b></p>`,
          showConfirmButton:false,
          timer: 4500
        })
        this.router.navigateByUrl('/mios/coaching/coaching/0');
      }else{
        this.acompanamientoForm.get('idAuditoria').disable();
        this.acompanamientoForm.get('nombre_coachee').disable();
        this.acompanamientoForm.get('cedula_coachee').disable();
        this.acompanamientoForm.get('matriz').disable();
        this.acompanamientoForm.get('indicador').disable();
        this.acompanamientoForm.get('indicador_inicial').disable();
        Tools.swalError('¡Oops!','Lo sentimos, ha ocurrido un error inesperado y no fue posible crear el acompañamiento, por favor intenta nuevamente.');
      }
    },(err)=>{
        this.acompanamientoForm.get('idAuditoria').disable();
        this.acompanamientoForm.get('nombre_coachee').disable();
        this.acompanamientoForm.get('cedula_coachee').disable();
        this.acompanamientoForm.get('matriz').disable();
        this.acompanamientoForm.get('indicador').disable();
        this.acompanamientoForm.get('indicador_inicial').disable();
    }
    );
  }

  cancel(){
    this.acompanamientoForm.get('tipo_proceso').reset();
    this.acompanamientoForm.get('campaing').reset();
    this.acompanamientoForm.get('cuartil_inicial').reset();
    this.acompanamientoForm.get('proximo_seguimiento').reset();
    this.acompanamientoForm.get('objetivo_kpi').reset();
    this.acompanamientoForm.get('causa_raiz').reset();
    this.acompanamientoForm.get('conclusion_sesion').reset();
    this.acompanamientoForm.get('tipo_proceso').setErrors(null);
    this.acompanamientoForm.get('campaing').setErrors(null);
    this.acompanamientoForm.get('cuartil_inicial').setErrors(null);
    this.acompanamientoForm.get('proximo_seguimiento').setErrors(null);
    this.acompanamientoForm.get('objetivo_kpi').setErrors(null);
    this.acompanamientoForm.get('causa_raiz').setErrors(null);
    this.acompanamientoForm.get('conclusion_sesion').setErrors(null);
  }

  getFilterParam(){
    this.authService.getUser().roles.forEach(rol => {
      if (rol == 'coaching::jefe' || rol == 'coaching::gerente') {
        this.campaignJefe = true;
      }
    });
   }

}
