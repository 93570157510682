<mat-vertical-stepper #stepperStrategys linear>
    <!-- STEP BUSQUEDA DE GRABACIONES -->
    <mat-step>
        <ng-template matStepLabel>Buscar grabaciones</ng-template>
        <div class="row mt-4">
            <div class="col-12">
                <h3>Consulta las grabaciones</h3>
                <hr/>
            </div>
            <div class="col-12 mt-3">
                <p>En esta sección, podrás buscar y encontrar registros de grabaciones disponibles para su descarga.</p>
            </div>
        </div>

        <form class="mt-4"  [formGroup]="formFilters">
            <div class="row d-flex justify-content-end mt-5 w-100 ">

                <div class="col-3">
                    <mat-form-field class="w-100 custom-form-field" appearance="fill">
                        <mat-label>Campaña</mat-label>
                        <input type="text" matInput [matAutocomplete]="auto" #inputField formControlName="campaign" required/>
                        <mat-icon *ngIf="inputField.value" style="color: grey;" matSuffix (click)="clearField()">cancel</mat-icon>
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnCampaign.bind(this)">
                            <mat-option *ngFor="let type of filteredOptionsCampaign | async" [value]="type.id" (onSelectionChange)="selectorsCampaigns(type.id)" [matTooltip]="type.name">
                                {{ type.name }}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="formFilters.get('campaign').hasError('required')">La campaña es requerida</mat-error>
                        <mat-error *ngIf="formFilters.get('campaign').hasError('invalidCampaign')">Campaña no válida</mat-error>
                    </mat-form-field>
                </div>

                <div [ngClass]="{ 'col-3': showFilterByCarteras && isFiltersAdvancedActive || !showFilterByCarteras, 
                    'col-2': showFilterByCarteras && !isFiltersAdvancedActive }">
                    <mat-form-field class="w-100 custom-form-field" appearance="fill">
                        <mat-label required>Módulo</mat-label>
                        <mat-select formControlName="module" required>
                            <mat-option value="crm1">CRM 1</mat-option>
                            <mat-option value="crm2">CRM 2</mat-option>
                            <mat-option value="carteras">VICIDIAL</mat-option>
                        </mat-select>
                        <mat-error *ngIf="formFilters.get('module').hasError('required')">El campo de módulo es requerido</mat-error>
                    
                    </mat-form-field>
                </div>
                <div class="col-3" *ngIf="showFilterByForms">
                    <mat-form-field class="w-100 custom-form-field" appearance="fill">
                        <mat-label>Formulario</mat-label>
                        <mat-select formControlName="form" [disabled]="!formFilters.get('module').value">
                        <mat-option *ngFor="let formulario of formularios" [value]="formulario.id">{{ formulario.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-3" *ngIf="showFilterByForms">
                    <mat-form-field class="w-100 custom-form-field" appearance="fill">
                        <mat-label>Sección</mat-label>
                        <mat-select formControlName="section" [disabled]="!formFilters.get('form').value">
                        <mat-option *ngFor="let seccions of seccion; let i = index" [value]="i">{{ seccions.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-3" *ngIf="showFilterByForms">
                    <mat-form-field class="w-100 custom-form-field" appearance="fill">
                        <mat-label>Campo</mat-label>
                        <mat-select formControlName="field" [disabled]="!formFilters.get('section').value">
                            <mat-option *ngFor="let field of fields" [value]="field.id">{{ field.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-3" *ngIf="showFilterByForms">
                    <mat-form-field class="w-100 custom-form-field" appearance="fill">
                        <mat-label>Valor del campo</mat-label>
                        <mat-select formControlName="option" [disabled]="!formFilters.get('field').value">
                        <mat-option *ngFor="let opcion of opciones" [value]="opcion.id">{{ opcion.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-3">
                    <mat-form-field class="w-100 custom-form-field" appearance="fill">
                        <mat-label>Rango de fecha</mat-label>
                        <mat-date-range-input [rangePicker]="pickerFilterRange" [dateFilter]="dateFilter">
                            <input matStartDate formControlName="start_date" placeholder="Fecha inicio">
                            <input matEndDate formControlName="end_date" placeholder="Fecha final">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="pickerFilterRange"></mat-datepicker-toggle>
                        <mat-date-range-picker #pickerFilterRange></mat-date-range-picker>
                    </mat-form-field>
                </div>

                <div class="col-3" *ngIf="showFilterByCarteras && isFiltersAdvancedActive">
                    <mat-form-field class="w-100 custom-form-field" appearance="fill">
                        <mat-label>Teléfono </mat-label>
                        <input matInput formControlName="phone_number" type="text" (input)="stripText('phone_number')">
                    </mat-form-field>
                </div>

                <div class="col-3" *ngIf="showFilterByCarteras && isFiltersAdvancedActive">
                    <mat-form-field class="w-100 custom-form-field" appearance="fill">
                        <mat-label>Tipo de la llamada</mat-label>
                        <mat-select formControlName="type_call" (selectionChange)="typeCallchage()">
                            <ng-container *ngFor="let type of type_call">
                                <mat-option [value]="type.type_call">{{ type.type_call }}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-3" *ngIf="showFilterByCarteras && isFiltersAdvancedActive">
                    <mat-form-field class="custom-form-field" appearance="fill" style="width: 100%;">
                        <mat-label>Tiempo de duración</mat-label>
                        <input type="time" matInput formControlName="duration" min="00:01" max="23:59" class="custom-time-input">
                        <mat-hint align="end">Formato: HH:MM</mat-hint>
                        <mat-error *ngIf="formFilters.get('duration')?.hasError('timeGreaterThanOneMinute')">
                            Se recomienda tiempos mayores a 1 minuto
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-3" *ngIf="showFilterByCarteras && isFiltersAdvancedActive">
                    <mat-form-field class="w-100 custom-form-field" appearance="fill">
                        <mat-label>Grupo</mat-label>
                        <mat-select formControlName="usergroup">
                            <mat-option [value]="">Selecciona una opción</mat-option>
                            <ng-container *ngFor="let group of groups">
                                <mat-option [value]="group.user_group">{{ group.group_name }}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-3" *ngIf="showFilterByCarteras && isFiltersAdvancedActive && isAviableSkills">
                    <mat-form-field class="w-100 custom-form-field" appearance="fill">
                        <mat-label>Skill</mat-label>
                        <mat-select formControlName="skill_id">
                            <mat-option [value]="">Selecciona una opción</mat-option>
                            <ng-container *ngFor="let skill of skills">
                                <mat-option [value]="skill.skill_id">{{ skill.skill_name }}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-3" *ngIf="showFilterByCarteras && isFiltersAdvancedActive">
                    <mat-form-field class="w-100 custom-form-field" appearance="fill">
                        <mat-label>Tipificación</mat-label>
                        <mat-select formControlName="status">
                            <mat-option [value]="">Selecciona una opción</mat-option>
                            <mat-option *ngFor="let item of tipifications" [value]="item.status">
                                {{ item.status_name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-3" *ngIf="showFilterByCarteras && isFiltersAdvancedActive">
                    <mat-form-field class="w-100 custom-form-field" appearance="fill">
                        <mat-label>Usuario</mat-label>
                        <input matInput formControlName="user">
                    </mat-form-field>
                </div>

                <div [ngClass]="{ 'col-3': showFilterByCarteras && isFiltersAdvancedActive, 
                    'col-2': showFilterByCarteras && !isFiltersAdvancedActive}" style="text-align: left;" *ngIf="showFilterByCarteras">
                    <button class="heaight-buttons w-100" matTooltip='Buscar' mat-flat-button
                        color="primary" [disabled]="!isSerachAviable" (click)="searchSelection('search')">
                        Buscar &nbsp; <i class="fi-rr-search"></i>
                    </button>
                </div>
                
                <div class="col-2" *ngIf="showFilterByCarteras && !isFiltersAdvancedActive">
                    <button class="w-100 heaight-buttons" mat-stroked-button color="primary"
                        (click)="changeStateFiltersAdvanced($event)">
                        Filtros avanzados 
                        <i class="fi-rr-filter"></i>
                    </button>
                </div>
                <div class="col-3" *ngIf="showFilterByCarteras && isFiltersAdvancedActive">
                    <button class="w-100 heaight-buttons" mat-stroked-button color="primary"
                        (click)="changeStateFiltersAdvanced($event)">
                        Desactivar filtros avanzados &nbsp;
                        <i class="fi-rr-filter"></i>
                    </button>
                </div>

                <div class="col-3" *ngIf="showFilterByCarteras && isFiltersAdvancedActive"></div>

                <div class="col-3" style="text-align: left;" *ngIf="!showFilterByCarteras">
                    <button class="heaight-buttons w-100" matTooltip='Buscar' mat-flat-button
                        color="primary" [disabled]="!isSerachAviable" (click)="search()">
                        Buscar &nbsp; <i class="fi-rr-search"></i>
                    </button>
                </div>
            </div>
        </form>
        
        <div class="table-container mt-5">
            <div class="mat-elevation-z1">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container *ngFor="let column of displayedColumns">
                        <ng-container [matColumnDef]="column" *ngIf="column !== 'checbox'">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }}
                            </th>
                            <td mat-cell *matCellDef="let element"> {{ element[column] }} </td>
                        </ng-container>
                        <ng-container [matColumnDef]="column" *ngIf="column === 'checbox'">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? selectAll() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                            </th>
                            <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? toggleSelection(element) : null"
                                [checked]="selection.isSelected(element)" ></mat-checkbox>
                            </td>
                        </ng-container>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr style="background: white" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr class="mat-row" style="background: white" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="displayedColumns.length">No existe información relacionada</td>
                    </tr>
                </table>

                <mat-paginator (page)="changePage($event, 'recordings')" [length]="configPaginator.length"
                    [pageSize]="configPaginator.pageSize" [pageSizeOptions]="pageSizeOptions">
                </mat-paginator>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-4 mt-3 ">
                <button class="w-100"  mat-raised-button color="primary"  (click)="searchSelection('descarga')" [disabled]="getTotalItemSelected <= 0" >Descargar grabaciones <i class="fi-rr-download"></i> </button>
            </div>
        </div>
    </mat-step>
    <!-- STEP BUSQUEDA DE GRABACIONES -->


    <!-- STEP ANALIZAR DE GRABACIONES -->
    <mat-step>
        <ng-template matStepLabel>Analizar grabaciones</ng-template>
        <div class="row mt-4">
            <div class="col-12">
                <h3>Analizar las grabaciones</h3>
                <hr />
            </div>
            <div class="col-9 mt-3">
                <p>En esta sección, podrás seleccionar las grabaciones que serán analizadas.</p>
            </div>

            <div class="col-3 d-flex  align-items-end">
                <button class="w-100 heaight-buttons" [disabled]="getTotalcompletedAnalisis <= 0 && !isAllComplete"
                    mat-flat-button color="primary" (click)="downloadReport('analysis')">
                    Descargar informe <i class="fi-rr-download"></i>
                </button>
            </div>

        </div>

        <div class="table-container mt-5">
            <div class="mat-elevation-z1">
                <table mat-table [dataSource]="dataSourceRecordings" matSort #sortRecordings="matSort">
                    <ng-container *ngFor="let column of displayedColumnsRecordings ">
                        <ng-container [matColumnDef]="column" *ngIf="column !== 'checbox' && column !== 'audio' && column !== 'analisis' ">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                            <td mat-cell *matCellDef="let element"> {{ element[column] }} </td>
                        </ng-container>
                        <ng-container [matColumnDef]="column" *ngIf="column === 'checbox'">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? selectAllRecordings() : null"
                                [checked]="selectionRecordings.hasValue() && isAllSelectedRecordings()" 
                                [indeterminate]="selectionRecordings.hasValue() && !isAllSelectedRecordings()"
                                ></mat-checkbox>
                            </th>
                            <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element">
                                <mat-checkbox 
                                    (click)="$event.stopPropagation()"
                                    (change)="element.analisis !== 'ERROR' && element.analisis !== 'PRIORITARIO' && element.analisis !== 'PENDIENTE' ? toggleRecordingSelection(element) : null"
                                    [checked]="selectionRecordings.isSelected(element)"
                                    [disabled]="element.analisis === 'ERROR' || element.analisis === 'PENDIENTE' || element.analisis === 'PRIORITARIO'">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container [matColumnDef]="column" *ngIf="column === 'audio'">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                            <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element" >
                            <button  mat-icon-button style="width: 25px !important;" (click)="openAudioModal(element.recording_id)">
                                <span class="fi-rr-megaphone"></span>
                            </button>
                            </td>
                        </ng-container>

                        <ng-container [matColumnDef]="column" *ngIf="column === 'analisis'">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                            <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element">
                                <ng-container *ngIf="element.analisis  === 'PENDIENTE' || element.analisis === 'PRIORITARIO'">
                                    Pendiente
                                </ng-container>
                                <ng-container *ngIf="element.analisis  === 'ERROR'">
                                    <!-- Si el estado es error, mostrar el modal -->
                                    <button mat-icon-button style="width: 25px !important;" (click)="errorAnalyticModal(element.recording_id)">
                                        <span class="fi fi-sr-exclamation"></span>
                                    </button>
                                    <button mat-icon-button style="width: 25px !important;" (click)="confirmReprocess(element.recording_id)">
                                        <span class="fi-sr-time-past"></span>
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="element.analisis  !== 'PENDIENTE' && element.analisis !== 'ERROR' && element.analisis !== 'PRIORITARIO'">
                                    <!-- Si el estado no es pendiente, mostrar el botón de búsqueda -->
                                    <button mat-icon-button style="width: 25px !important;" (click)="openAnalyticsModal(element.recording_id)">
                                    <span class="fi-rr-search"></span>
                                    </button>
                                </ng-container>
                            </td>
                        </ng-container>

                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsRecordings"></tr>
                    <tr style="background: white" mat-row *matRowDef="let row; columns: displayedColumnsRecordings;"></tr>
                    <tr class="mat-row" style="background: white" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="displayedColumnsRecordings.length">No existe información relacionada</td>
                    </tr>
                </table>

                <mat-paginator (page)="changePage($event, 'analisis')" [length]="configPaginatorRecording.length"
                    [pageSize]="configPaginatorRecording.pageSize"
                    [pageSizeOptions]="pageSizeOptions">
                </mat-paginator>

            </div>
        </div>

        <div class="row mt-5">
            <div class="col-4 mt-3" *ngIf="isAnalisisColumnVisible">
                <button class="w-100" mat-raised-button color="primary" (click)="searchSelection('analisis_sentimientos')">Análisis de sentimientos masivo </button>
            </div>
            <div class="col-4 mt-3" *ngIf="!isAnalisisColumnVisible">
                <button class="w-100" mat-raised-button color="primary" (click)="searchSelection('analisis')" [disabled]="isAnalisisColumnVisible">Analizar grabaciones <i class="fi-rr-chart-histogram"></i> </button>
            </div>
            <div class="col-4 mt-3">
                <button class="w-100"  mat-raised-button color="primary" (click)="searchSelection('strategia')" [disabled]="!ColumnVisibleTotal">Aplicar estrategia &nbsp; <i class=" fi-rr-forward"></i></button>
            </div>
        </div>
    </mat-step>
    <!-- STEP ANALIZAR DE GRABACIONES -->

    <!-- STEP APLICAR STRATEGIA -->
    <mat-step>
        <ng-template matStepLabel>Aplicar estrategia</ng-template>
            <div class="row mt-4">
                <div class="col-12">
                    <h3>Aplicar estrategias</h3>
                    <hr/>
                </div>
                <div class="col-9 mt-3">
                    <p>En esta sección, podrás seleccionar las grabaciones que fueron analizadas y aplicar o crear una estrategia para estas.</p>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-3">
                    <mat-form-field class="w-100 custom-form-field" appearance="fill">
                        <mat-label>Estrategias</mat-label>
                        <mat-select [formControl]="strategyControl" required>
                            <ng-container *ngFor="let strategy of listStrategys">
                                <mat-option [value]="strategy.id" [matTooltip]="strategy.strategy_name">{{ strategy.strategy_name }}</mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="strategyControl.hasError('required')">La estrategia aplicar es requerida</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <button class="w-100 heaight-buttons"  mat-raised-button color="primary" (click)="createStrategyTranscription()" >Crear estrategia con transcripción &nbsp; <i class="fi-rr-plus"></i></button>
                </div>
                <div class="col-2"></div>
                <div class="col-3">
                    <button class="w-100 heaight-buttons" mat-flat-button color="primary" (click)="downloadReport('strategy')" [disabled]="!startegyComplete" >
                        Descargar informe <i class="fi-rr-download"></i>
                    </button>
                </div>
            </div>

        <div class="table-container mt-5">
            <div class="mat-elevation-z1">

                <table mat-table [dataSource]="dataSourceStrategy" matSort #sortStrategy="matSort">
                    <ng-container *ngFor="let column of displayedColumnsStrategy ">
                    <ng-container [matColumnDef]="column"
                        *ngIf="column !== 'checbox' && column !== 'audio' && column !== 'adherencia' && column !== 'analisis'  ">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                        <td mat-cell *matCellDef="let element"> {{ element[column] }} </td>
                    </ng-container>
                    <ng-container [matColumnDef]="column" *ngIf="column === 'checbox'">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? selectAllStrategy() : null"
                                [checked]="selectionStrategy.hasValue() && isAllSelectedStrategy()"
                                [indeterminate]="selectionStrategy.hasValue() && !isAllSelectedStrategy()"></mat-checkbox>
                        </th>
                        <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? toggleSelectionStrategy(element) : null"
                                        [checked]="selectionStrategy.isSelected(element)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container [matColumnDef]="column" *ngIf="column === 'analisis'">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                        <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element">
                            <ng-container *ngIf="element.analisis  === 'PENDIENTE' || element.analisis === 'PRIORITARIO'">
                                Pendiente
                            </ng-container>
                            <ng-container *ngIf="element.analisis  === 'ERROR'">
                                <!-- Si el estado es error, mostrar el modal -->
                                <button mat-icon-button style="width: 25px !important;" (click)="errorAnalyticModal()">
                                    <span class="fi fi-sr-exclamation"></span>
                                </button>
                                <button mat-icon-button style="width: 25px !important;" (click)="confirmReprocess(element.recording_id)">
                                    <span class="fi-sr-time-past"></span>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="element.analisis  !== 'PENDIENTE' && element.analisis !== 'ERROR' && element.analisis !== 'PRIORITARIO'">
                                <!-- Si el estado no es pendiente, mostrar el botón de búsqueda -->
                                <button mat-icon-button style="width: 25px !important;"
                                    (click)="openAnalyticsModal(element.recording_id)">
                                    <span class="fi-rr-search"></span>
                                </button>
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container [matColumnDef]="column" *ngIf="column === 'audio'">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeadersRecordings[column] }} </th>
                        <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element">
                            <button mat-icon-button style="width: 25px !important;"
                                (click)="openAudioModal(element.recording_id)">
                                <span class="fi-rr-megaphone"></span>
                            </button>
                        </td>
                    </ng-container>
                    
                    <ng-container [matColumnDef]="column" *ngIf="column === 'adherencia'">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ tHeadersRecordings[column] }}</th>
                        <td mat-cell *matCellDef="let element" (click)="AdherenciaModal(element.recording_id)">
                            <span *ngIf="element.adherencia">{{ element[column] }}</span>
                            <span *ngIf="!element.adherencia">No Aplica</span>
                        </td>
                    </ng-container>

                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsStrategy"></tr>
                <tr style="background: white" mat-row *matRowDef="let row; columns: displayedColumnsStrategy;"></tr>
                <tr class="mat-row" style="background: white" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="displayedColumnsStrategy.length">No existe información relacionada</td>
                </tr>
                </table>

                <mat-paginator (page)="changePage($event, 'strategy')" [length]="configPaginatorStrategy.length"
                    [pageSize]="configPaginatorStrategy.pageSize" [pageSizeOptions]="pageSizeOptions">
                </mat-paginator>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-4 mt-3" >
                <button class="w-100"  mat-raised-button color="primary" (click)="applyStrategySelected()" [disabled]="getTotalRecordingsForStrategy <=0 && strategyControl.invalid || !strategyControl.value" >Aplicar estrategia</button>
            </div>
        </div>

    </mat-step>
    <!-- STEP APLICAR STRATEGIA -->

</mat-vertical-stepper>
