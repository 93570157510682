import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';

const CIU_URL = environment.CIU_URL;
const { SPEECH_URL } = environment;
@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  constructor(private httpClient: HttpClient) { }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * Metodo retornar las campañas
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param filter filtros para la búsqueda.
   * @param paginator configuración de paginación (pageSize y pageIndex).
  */
  getCampaign(paginator: any, filter: string): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}campaign?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&filter=${filter}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo que retorna la información de la campaña por  id
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
  */
  showCampaign(id: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}campaign/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo que crea o actualiza la informacion de la campaña
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param campaign Informacion proveniente del servidor. 
  */
  UpdateCampaign(campaign: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}campaign/${campaign.id}`, campaign).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo para crear una nueva campaña
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param campaign Informacion proveniente del servidor. 
  */
  CreaCampaign(campaign: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}campaign/`, campaign).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo que lista los usuarios que tienen el rol de speech.
   * @author Fabian Duran
   * @createdate 2024-04-23
  */
  getUsersToRolSpeech(): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}campaigns/getUsers`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo que lista las campañas registradas en el sistema.
   * @author Fabian Duran
   * @createdate 2024-04-23
   * @param userId Usuario seleccionado en la lista. 
   * @modifiedBy Yeison Sepulveda
   * @modifyDate 20-05-2024
  */
  getAvailableCampaigns(userIds: number[]): Observable<any> {
    const body = { rrhh_ids: userIds }
    return this.httpClient.post<any>(`${SPEECH_URL}campaigns/availableCampaigns`, body).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo que lista las campañas asociadas a un usuario seleccionado.
   * @author Fabian Duran
   * @createdate 2024-04-23
   * @param userId Usuario seleccionado en la lista. 
   * @modifiedBy Yeison Sepulveda
   * @modifyDate 20-05-2024
  */
  getUserAssociatedCampaigns(userIds: number[]): Observable<any> {
    const body = { rrhh_ids: userIds }
    return this.httpClient.post<any>(`${SPEECH_URL}campaigns/associatedCampaigns`, body).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo que guarda la asociacion entre campañas y usuarios.
   * @author Yeison Sepulveda
   * @createdate 2024-04-23
   * @param userId Usuario seleccionado de la lista.
   * @param campaignsId Campañas seleccionadas de la segunda lista. 
  */
  associatedCampaigns(userId: number[], campaignsId: number[]): Observable<any> {
    const body = { campaign_ids: campaignsId, rrhh_ids: userId };
    return this.httpClient.post<any>(`${SPEECH_URL}campaigns/saveCampaignsUser`, body).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Desactivar/inhabilitar campaña por id
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
   * @param campaignId Campaña id
  */

  stateCampaign(campaignId: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}campaigns/inactivateCampaigns`, campaignId).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo comprobar
   * @author Yeison Sepulveda
   * @createdate 2024-04-23
  */
  getVerifyScopeSOUL(campaign_id: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}verify_scopes/${campaign_id}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
   * Metodo comprobar IA
   * @author Yeison Sepulveda
   * @createdate 2024-04-23
  */
  getVerifyScopeIA(campaign_id: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}verify_scopes_IA/${campaign_id}`).pipe(
      catchError(this.handleError)
    );
  }
}
