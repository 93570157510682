<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>

<h3  mat-dialog-title>Asignar reclutador</h3>
<mat-dialog-content class="mat-typography">
    <p *ngIf="reclutadores.length!=0">Seleccione a que reclutador asignar la solicitud para su seguimiento.</p>
    <p *ngIf="reclutadores.length==0">No hay reclutadores a quien asignar su solicitud.</p>

    <mat-form-field appearance="fill" *ngIf="reclutadores.length!=0">
        <mat-label>Seleccione un reclutador</mat-label>
        <mat-select #leader (selectionChange)="selectReclutador(leader.value)">
            <mat-option *ngFor="let reclutador of reclutadores" [value]="reclutador.id">
                {{reclutador.first_name }} {{reclutador.last_name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="reclutadores.length !=0">
    <button class="alert" mat-raised-button color="warn" mat-dialog-close>Cancel</button>
    <button class="primary" mat-raised-button color="primary" (click)="asignarReclutador()">Asignar</button>
</mat-dialog-actions>