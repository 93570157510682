<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<h2 mat-dialog-title>Documentos del candidato</h2>
<hr>
<mat-dialog-content>
    <table mat-table [dataSource]="dataSource">

        
     
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Documento</th>
          <td mat-cell *matCellDef="let element"> {{element.name}}
            </td>
        </ng-container>
      
     
        <ng-container matColumnDef="attached">
          <th mat-header-cell *matHeaderCellDef> Subido </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.status.attached" class="fi-sr-check"></span>
            <span *ngIf="!element.status.attached">Pendiente</span>
             </td>
        </ng-container>
      
  
        <ng-container matColumnDef="verified">
          <th mat-header-cell *matHeaderCellDef> Verificado</th>
          <td mat-cell *matCellDef="let element"> 
            <span *ngIf="element.status.verified == 'Aprobado'" [matTooltip]="element.status.verified" class="fi-sr-check"></span>
            <span *ngIf="!element.status.verified">Pendiente</span>
            <span *ngIf="element.status.verified == 'Rechazado'" [matTooltip]="element.status.verified" class="fi-sr-cross"></span>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
</mat-dialog-content>