import { createAction, props } from "@ngrx/store";
import { AcompañamientoModel } from "../../core/interfaces/acompañamiento.interface";
import { HistorialModel } from "../../core/interfaces/historial.interface";

/**
 * @author Cristian Gallo
 * @createdate 2021-12-10
 * Action que cargara la información para la tabla de historial de solicitud.
*/

export const loadHistorial = createAction(
    '[Historial] Load historial',
    props<{
        idSolicitud,
        numeroReg?:number,
        page?:number,
    }>()
);

export const loadedHistorial = createAction(
    '[Historial] Loaded historial',
    props<{ historial:AcompañamientoModel }>()
);


export const loadHistorialById = createAction(
    '[HistorialById] Load historial',
    props<{ idHistorial }>()
);

export const loadedHistorialById = createAction(
    '[HistorialById] Loaded historial',
    props<{ historial:HistorialModel }>()
);