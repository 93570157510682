import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { catchError } from 'rxjs/operators';

const NOMINA_URL = environment.NOMINA_URL;

@Injectable({
  providedIn: 'root'
})
export class InactiveDatesService {

  constructor(private http: HttpClient) {
  }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * @author Andres Buitrago
   * @created 2021-05-05
   * @purpose Servicio para obtener las dias inactivos en base a filtros
   */
  getInactiveDates(filters: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('month', filters.month || '')
        .set('year', filters.year || '')
        .set('textFilter', filters.textFilter || '')
        .set('perPage', filters.perPage || 5)
        .set('paginate', filters.paginate || 1)
        .set('page', filters.page || 1)
    };
    return this.http.get<any>(`${ NOMINA_URL }inactiveDays`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Andres Buitrago
   * @created 2021-05-05
   * @purpose Servicio para obtener las dias inactivos del año actual
   */
  getInactiveInCurrentYear(): Observable<any> {
    return this.http.get<any>(`${ NOMINA_URL }inactiveDays/currentYear`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Andres Buitrago
   * @created 2021-05-05
   * @purpose Servicio para registrar fechas inactivas
   */
  createInactiveDate(data: any): Observable<any> {
    return this.http.post<any>(`${ NOMINA_URL }inactiveDays`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Andres Buitrago
   * @created 2021-05-05
   * @purpose Servicio para eliminar un dia inactivo
   */
  deleteInactiveDate(inactiveDayId: number): Observable<any> {
    return this.http.delete<any>(`${ NOMINA_URL }inactiveDays/${ inactiveDayId }`)
      .pipe(
        catchError(this.handleError)
      );
  }

}
