export class ConfigSchedule {
    id: number;
    quantity: number;
    config_id: number;
    schedule_type_id: number;
    name: string;
    key: any;
    editable?: boolean;
    open_dial?: number | boolean;
}
