import { SelectionModel } from '@angular/cdk/collections';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { ChatService } from '../../../services/chat.service';
import { ChatViewModalComponent } from '../chat-view-modal/chat-view-modal.component';
import { AnalyticsChatModalComponent } from '../analytics-chat-modal/analytics-chat-modal.component';
import { StrategyServersService } from '../../../services/strategy.service';
import { TranscChatModalComponent } from '../transc-chat-modal/transc-chat-modal.component';
import { AdherenciaChatModalComponent } from '../adherencia-chat-modal/adherencia-chat-modal.component';

@Component({
  selector: 'app-search-chat',
  templateUrl: './search-chat.component.html',
  styleUrls: ['./search-chat.component.sass']
})
export class SearchChatComponent implements OnInit, OnDestroy {

  /**
   * formulario
   */
  formFilters:FormGroup;
  
  /**
   * Variables de paginacion
   */
  configPaginator : any = { length: 0, pageSize: 5, pageIndex: 1 };
  configPaginatorRecording : any ={ pageSize : 5, length:0,  pageIndex:1 }
  configPaginatorStrategy : any ={ pageSize : 5, length:0,  pageIndex:1 }
  pageSizeOptions : number[] = [5, 10, 20, 50, 100];

  upload_list: any[] = [];

  /**
   * Columnas tablas
   */
  displayedColumns : string[] = ['checbox', 'case_number', 'entry_date_time', 'agent_name', 'phone', 'file', 'conversation'];
  displayedColumnsRecordings : string[] = ['checbox', 'case_number', 'entry_date_time', 'agent_name', 'phone', 'file', 'conversation', 'analisis'];
  displayedColumnsStrategy : string[] = ['checbox', 'case_number', 'entry_date_time', 'agent_name', 'phone', 'file', 'conversation', 'analisis', 'adherencia'];

  /**
   * Cabeceras de tablas
   */
  tHeadersRecordings:any={
    checbox: "", case_number:"Caso",entry_date_time: "Fecha",
    agent_name: 'Nombre agente', phone: "Telefono", file:'Archivo',
    conversation:'Conversación', analisis:'Análisis' , adherencia:'Adherencia'
  }
  
  //datasources tablas
  dataSource = new MatTableDataSource<any>(); 
  dataSourceRecordings = new MatTableDataSource<any>();
  dataSourceStrategy = new MatTableDataSource<any>();  

  @ViewChild('paginator') paginator: MatPaginator;//paginador de busqyeda
  @ViewChild('Analyticpaginator') Analyticpaginator: MatPaginator;//paginador de busqyeda
  @ViewChild('StrategyPaginator') StrategyPaginator: MatPaginator;//paginador de busqyeda

  //Variables de control con Array original de datos para tablas que requiren Paginacion estatica
  listrecordingsAnalising:any[] = [];
  listRecordingsStrategys:any[] = []
  listStrategys:any[] = [];


  /**
   * Varibales de seleccions
   */
  selection = new SelectionModel<any>(true, []);
  selectionRecordings = new SelectionModel<any>(true, []);
  selectionStrategy = new SelectionModel<any>(true, []);


  isAllComplete: boolean;
  private isActive = true;
  startegyComplete: boolean;

  //parar el metodo obtener estados
  private abortController: AbortController | null = null;

  //selectores 
  selectedSearch: Map<number, any> = new Map(); //selest activos busqueda
  selectedDownload: Map<number, any> = new Map(); //selects activos descarga audios
  selectedStrategy: Map<number, any> = new Map();

  @ViewChild('stepperStrategys') stepperStrategys: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('sortRecordings') sortRecordings: MatSort;
  @ViewChild('sortStrategy') sortStrategy: MatSort;

  paginaActual: number;

  strategyControl:FormControl = new FormControl(null)

  constructor(
      private dialog:MatDialog,
      private chatService: ChatService,
      private strategyService: StrategyServersService,
  ) {}

  ngOnInit(): void {
    this.initFormFilters();
    this.filterUploads();
    this.dataSource.paginator = this.paginator;
    this.dataSourceRecordings.paginator = this.Analyticpaginator;
  }

  ngOnDestroy() {
    this.isActive = false; 
  }

  /**
   * Método donde se establecen las validaciones del formulario y adviarte antes de refrescar 
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @method unloadNotification
   * @param {Event} $event - El evento `beforeunload` del navegador.
   * @returns {void}
  */
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.formFilters.dirty) {
      $event.returnValue = true;
    }
  }

  /**
   * Metodo que se encarga de inicializar los controles apra los filtros
   * @author Yeison sepulveda
   * @createdate    14/03/2023
   * @returns void {void} 
   */
  initFormFilters():void{
    this.formFilters = new FormGroup({
      upload: new FormControl(Validators.required),
      case_number: new FormControl(null),
      agent_name: new FormControl(null),
    });
  }

  /**
   * Metodo solo permite numeros
   * @author Yeison Sepulveda
   * @date 2024-07-31
   */
  stripText(control: string) {
    this.formFilters.controls[control].setValue(
        this.formFilters.controls[control].value.replace(/[^0-9]/g, '')
    );
  }

  /**
   * Metodo trae listado de nombres de documento subidos para listar lso chats
   * @author Yeison sepulveda
   * @createdate    06/05/2023
   * @returns void {void} 
   */
  filterUploads():void{
    this.chatService.getListUploads().subscribe(res => {
      this.upload_list = res.data;
    },
    (error)=>{
      this.upload_list = []
      console.error('Error al encontrar listado de documentos cargados', error.message);
      Swal.fire({
        icon: 'error',
        title: 'Error al encontrar información',
        text: error.message,
        showConfirmButton: true,
        confirmButtonColor: '#2CABBC',
        confirmButtonText: '¡Entendido!',
      });
    });
  }
  
  /**
   * Metodo consulta la informacion de los chats cada vez que se cambia el select
   * @author Yeison Sepulveda
   * @date 2024-07-09
  */
  loadFile(): void {
    const upload_id = this.formFilters.get("upload").value;
    const case_number = this.formFilters.get("case_number").value;
    const agent_name = this.formFilters.get("agent_name").value;
  
    const requestData = {
      id_upload: upload_id,
      per_page: this.configPaginator.pageSize,
      page: this.configPaginator.pageIndex,
      case_number: case_number,
      agent_name: agent_name
    };
    this.chatService.loadUploadDataPaginate(requestData).subscribe(res => {
      //procesar informacion y seteo de la misma
      const processedData = res.data.data.map(item => {
        const earliestChatDetail = item.chat_details.reduce((earliest, current) => {
          return new Date(earliest.entry_date_time) < new Date(current.entry_date_time) ? earliest : current;
        });
  
        const agentName = item.chat_details.find(detail => detail.agent_name)?.agent_name || 'N/A';
        const file = item.uploads.file;
  
        return {
          ...item,
          entry_date_time: earliestChatDetail.entry_date_time,
          agent_name: agentName,
          file: file
        };
      });
  
      this.dataSource.data = processedData;
      this.dataSource.sort = this.sort;
      this.configPaginator.length = res.data.total;
      this.syncSelectionWithStored('search');
    },
    (error) => {
      console.error('Error al cargar la información',error.message);
    });
  }

  /**
   * Metodo para abrir el modal detalle del caso y visual del chat
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  modalChatView(id: any): void {
    const dialogRef = this.dialog.open(ChatViewModalComponent, {
      width: '800px',
      data: { id: id } 
    });
  }

  /**
   * Método para limpiar la selección basado en un parámetro
   * @author Yeison Sepulveda
   * @param param
   */
  searchSelection(param: string) {
    switch (param) {
      case 'search':
        this.clearSelections();
        this.resetPaginator();
        this.resetFilters(['case_number', 'agent_name']);
        this.loadFile();
        break;
      case 'searchFilter':
        this.resetPaginator();
        this.loadFile();
        break;
      case 'strategia':
        if (this.selectedDownload.size === 0) {
          Swal.fire({
            title: 'No hay registros seleccionados',
            text: 'Por favor, seleccione al menos un registro para aplicarle estrategias.',
            icon: 'warning',
            confirmButtonText: '¡Entendido!',
            confirmButtonColor: '#2CABBC',
          });
          return;
        }
        this.dataSourceStrategy.data = [];
        this.configPaginatorStrategy.pageSize = this.configPaginatorRecording.pageSize;
        this.selectedStrategy.clear();
        this.selectionStrategy.clear();
        this.applyStrategy(this.stepperStrategys);
        break;
      }
  }

  /**
   * Metodo para limpiar todas las selecciones depues de cambiar o consultar la carga
   * @author Yeison Sepulveda
   * @date 2024-07-31
   */
  clearSelections(): void {
    this.selectedSearch.clear();
    this.selectedDownload.clear();
    this.selectedStrategy.clear();
    this.selection.clear();
    this.selectionRecordings.clear();
    this.selectionStrategy.clear();
  }

  /**
   * Metodo para reiniciar el paginador de la primera tabla
   * @author Yeison Sepulveda
   * @date 2024-07-31
   */
  resetPaginator(): void {
    this.paginator.firstPage();
    this.configPaginator.pageIndex = 1;
  }

  /**
   * Metodo que se encarga de resetear los controles indicados
   * @author Yeison Sepulveda
   * @param controls:string[] array de name_controls a resetear e inhbilitar o habilitar
   */
  resetFilters(controls:string[]):void{
    controls.forEach( (control_name:string) => {
        this.formFilters.get(control_name).reset();
    });
  }

  /**
   * Recuperar los selec seleccionados en distintas paginas
   * @author Yeison Sepulveda
   * @param param
   */
  syncSelectionWithStored(param: string) {
    switch (param) {
      case 'search':
        this.selection.clear();
        this.dataSource.data.forEach((row) => {
          if (this.selectedSearch.has(row.id)) {
            this.selection.select(row);
          }
        });
        break;
  
      case 'analisis':
        this.selectionRecordings.clear();
        this.dataSourceRecordings.data.forEach((row) => {
          if (this.selectedDownload.has(row.id)) {
            this.selectionRecordings.select(row);
          }
        });
        break;
      case 'strategy':
        this.selectionStrategy.clear();
        this.dataSourceStrategy.data.forEach((row) => {
          if (this.selectedStrategy.has(row.id)) {
            this.selectionStrategy.select(row);
          }
        });
        break;
      default:
        console.error(`Error de parametro: ${param}`);
        break;
    }
  }

  /**
   * Metodo que determinao si los checbox estan seleccionados o no
   * @author Juan David Guerrero Vargas
   * @returns {Boolean}
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /**
   * Metodo para para seleccionar todos los elementos en la página actual
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
  */
  selectAll(): void {
    if (this.isAllSelected()) {
        this.dataSource.data.forEach((row) => {
            this.selection.deselect(row);
            this.selectedSearch.delete(row.id);
        });
    } else {
        this.dataSource.data.forEach((row) => {
            if (!this.selection.selected.some((selectedItem: any) => selectedItem.id === row.id)) {
                this.selection.select(row);
                this.selectedSearch.set(row.id, row);
            }
        });
    }
  }

  /**
   * Método para alternar la selección de una grabación en la lista.
   * @author Yeison Sepulveda
   * @param row - El objeto que representa una grabación en la lista.
  */
  toggleSelection(row: any): void {
    if (this.selection.isSelected(row)) {
        this.selection.deselect(row);
        this.selectedSearch.delete(row.id);
    } else {
        this.selection.select(row);
        this.selectedSearch.set(row.id, row);
    }
  }

  /**
   * Método que determinao si los checbox estan seleccionados o no en la tabal de analisis
   * @author Juan David Guerrero Vargas
   * @returns {Boolean}
   */
  isAllSelectedRecordings() {
    const numSelected = this.selectionRecordings.selected.length;
    const numRows = this.dataSourceRecordings.data.filter(row => row.analisis !== 'ERROR' && row.analisis !== 'PENDIENTE').length;
    return numSelected === numRows;
  }

  /**
   * Método para seleccionar todos los elementos en la tabla de analsis
   * @modificado Yeison Sepulveda
   * @createdate 2024-07-16
   */
  selectAllRecordings(): void {
    if (this.isAllSelectedRecordings()) {
      this.dataSourceRecordings.data.forEach((row: any) => {
        if (row.analisis !== 'ERROR' && row.analisis !== 'PENDIENTE') {
          this.selectionRecordings.deselect(row);
          this.selectedDownload.delete(row.id);
        }
      });
    } else {
      this.dataSourceRecordings.data.forEach((row: any) => {
        if (row.analisis !== 'ERROR' && row.analisis !== 'PENDIENTE') {
          if (!this.selectionRecordings.isSelected(row)) {
            this.selectionRecordings.select(row);
            this.selectedDownload.set(row.id, row);
          }
        }
      });
    }
  }
  

  /**
   * Método para alternar la selección de una grabación en la lista.
   * @author Yeison Sepulveda
   * @param row - El objeto que representa una grabación en la lista.
  */
  toggleRecordingSelection(row: any): void {
    if (this.selectionRecordings.isSelected(row)) {
      this.selectionRecordings.deselect(row);
      this.selectedDownload.delete(row.id);
    } else {
      if (row.analisis !== 'ERROR' && row.analisis !== 'PENDIENTE') {
        this.selectionRecordings.select(row);
        this.selectedDownload.set(row.id, row);  // Almacena el objeto completo
      }
    }
  }

  /**
   * Metodo que se encarga de determinar si ya estan seleccionadas todas las grbaciones apra aplicar estrategias
   * @author Juan David Guerrero Vaegas
  */
  isAllSelectedStrategy() {
    const numSelected = this.selectionStrategy.selected.length;
    const numRows = this.dataSourceStrategy.data.length;
    return numSelected === numRows;
  }

  /**
    * Método para seleccionar todos los elementos en la página actual
    * @author Yeison Sepulveda
    */
  selectAllStrategy(): void {
  if (this.isAllSelectedStrategy()) {
      this.dataSourceStrategy.data.forEach((row) => {
        this.selectionStrategy.deselect(row);
        this.selectedStrategy.delete(row.id);
      });
    } else {
      this.dataSourceStrategy.data.forEach((row) => {
        if (!this.selectionStrategy.isSelected(row)) {
          this.selectionStrategy.select(row);
          this.selectedStrategy.set(row.id, row);
        }
      });
    }
  }

  /**
    * Método para alternar la selección de una grabación en la lista.
    * @author Yeison Sepulveda
    * @param row - El objeto que representa una grabación en la lista.
   */
  toggleSelectionStrategy(row: any): void {
    if (this.selectionStrategy.isSelected(row)) {
      this.selectionStrategy.deselect(row);
      this.selectedStrategy.delete(row.id);
    } else {
      this.selectionStrategy.select(row);
      this.selectedStrategy.set(row.id, row);
    }
  }

	/**
	 * Método para tratar los datos que retorna el servicio y descargar el archivo.
	 * @param data data respondida por el servicio
	 * @param fileName nombre con el que se va a descargar el archivo.
	 * @param file_type Tipo del archivo 
	 * @author Juan Carlos Alonso
	 * @createdate 2021-07-27
	 * @updatedate 2022-12-13  
	 */
	fileTransition(data: any, fileName: string, file_type:string = ""): void {
		let blob = new Blob([data], { type: file_type});
		let downloadURL = (file_type) ? window.URL.createObjectURL(blob) : window.URL.createObjectURL(data);
		let link = document.createElement('a');
		link.href = downloadURL;
		link.download = fileName;
		link.click();
	}

  /**
   * Método para obtener los objetos seleccionados
   * @author Yeison Sepulveda
   * @createdate 2024-07-15
   * @returns {any[]} Lista de objetos seleccionados
  */
  getSelectedRows(): any[] {
    return Array.from(this.selectedSearch.values());
  }

  /**
  * Método para obtener los case_numbers seleccionados
  * @author Yeison Sepulveda
  * @createdate 2024-07-15
  * @returns {number[]} Lista de case_numbers seleccionados
  */
  getSelectedCaseNumbers(): number[] {
    return Array.from(this.selectedSearch.keys());
  }


  /**
   * Metodo consume servicio analizar y asigna los valores al datasource
   * @author Yeison Sepulveda
   * @createdate 2024-07-15
  */
  analytics(): void {
    const registros = this.getSelectedRows();
    
    if (registros.length === 0) {
        Swal.fire({
            icon: 'warning',
            title: 'No hay registros seleccionados',
            text: 'Por favor, seleccione al menos un registro para continuar.',
            confirmButtonText: '¡Entendido!',
            showConfirmButton: true,
            confirmButtonColor: '#2CABBC',
        });
        return; 
    }
    
    if (registros.length > 1) {
        Swal.fire({
            title: '¿Estás seguro?',
            text: `Se iniciará el análisis de ${registros.length} chats.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, continuar',
            confirmButtonColor: '#2CABBC',
            cancelButtonText: 'Cancelar',
            cancelButtonColor: '#FFFFFF',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                this.procesarAnalisis(registros);
            }
        });
    } else {
        this.procesarAnalisis(registros);
    }
  }

  /**
   * Método para procesar el análisis de registros.
   * @author Yeison Sepulveda
   * @createdate 2024-07-15
   * @param {any[]} registros - Arreglo de registros a ser procesadas.
   */
  procesarAnalisis(registros: any[]): void {
    this.stepperStrategys.next();
    this.actualizarConfiguraciones(registros);
    const caseNumbers = this.getSelectedCaseNumbers();
    this.resquestAnalytics(caseNumbers);
    this.obtenerEstadosRegistros(this.configPaginatorRecording.pageIndex);
  }

  /**
   * Manejo y actualizacion del datasource despeus de analizar
   * @autor Yeison Sepulveda
   * @createdate 2024-07-15
   * @param {any[]} registros - Arreglo de registros a ser procesadas.
   */
  actualizarConfiguraciones(registros: any[]): void {
    this.Analyticpaginator.firstPage();
    this.configPaginatorRecording.pageSize = this.configPaginator.pageSize;
    this.configPaginatorRecording.pageIndex = 1;
    this.configPaginatorRecording.length = registros.length;
    this.listrecordingsAnalising = registros;
    //datos paginados y manejo paginador statico
    const paginatedData = this.paginateAnalyticsStaticTable(registros, this.configPaginatorRecording);
    this.dataSourceRecordings.data = paginatedData;
    this.dataSourceRecordings.sort = this.sortRecordings;

  }

  /**
   * Consumo para analizar los registros
   * @autor Yeison Sepulveda
   * @createdate 2024-07-15
   * @param {numbr[]} caseNumbers - Arreglo delos ids
   */
  resquestAnalytics(caseNumbers: number[]): void {
    const body = {
        ids_case: caseNumbers
    };
    this.chatService.chatRequestAnalytics(body).subscribe(
        (response: any) => {
          console.log('Solicitud realizada con exito:', response);
        },
        (error) => {
            console.error('Error en la solicitud:', error.message);
        }
    );
  }

  /**
   * Metodo que limita las acciones de botones por defecto
   */
  evenPrevenDefault($event){
    $event.preventDefault();
  }

  /**
   * Método que pagina la informacion de la tabla.
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
   * @param $event Evento emitido por el paginador.
  */
  changePage($event: PageEvent, tablePaginate: string): void {
    if (tablePaginate === 'recordings') {
        this.configPaginator.pageSize = $event.pageSize;
        this.configPaginator.pageIndex = $event.pageIndex + 1;
        this.loadFile();

    }else if (tablePaginate === 'analisis' && this.displayedColumnsRecordings.find(ffnn => ffnn === 'analisis') !== undefined) {
        this.configPaginatorRecording.pageSize = $event.pageSize;
        this.configPaginatorRecording.pageIndex = $event.pageIndex + 1;
        this.dataSourceRecordings.data = this.paginateAnalyticsStaticTable(this.listrecordingsAnalising, this.configPaginatorRecording);
        this.obtenerEstadosRegistros(this.configPaginatorRecording.pageIndex);
        this.syncSelectionWithStored('analisis');
    }else if (tablePaginate === 'strategy' && this.displayedColumnsRecordings.find(ffnn => ffnn === 'analisis') !== undefined) {
      this.configPaginatorStrategy.pageSize = $event.pageSize;
      this.configPaginatorStrategy.pageIndex = $event.pageIndex + 1;
      this.dataSourceStrategy.data = this.paginateStaticTable(this.listRecordingsStrategys, this.configPaginatorStrategy);
      this.syncSelectionWithStored('strategy');
  }
  }

  /**
   * Metodo que se encarg de realziar paginacion estatica para tablas 
   * @author Yeison Sepulveda
   * @param dataTable:any[] {any[]} Listado original de datos a paginar
   * @param configPaginator:any {any} Configuracion de paginacion a aplicar de manera estatica
   * @returns {any[]} any[]
   */
  paginateAnalyticsStaticTable(dataTable: any[], configPaginator: any): any[] {
    const startIndex = (configPaginator.pageIndex - 1) * configPaginator.pageSize;
    return dataTable.slice(startIndex, startIndex + configPaginator.pageSize);
  }

  /**
   * Metodo que se encarg de realziar paginacion estatica para tablas 
   * @author Yeison Sepulveda
   * @param dataTable:any[] {any[]} Listado original de datos a paginar
   * @param configPaginator:any {any} Configuracion de paginacion a aplicar de manera estatica
   * @returns {any[]} any[]
  */
  paginateStaticTable(dataTable: any[], configPaginator: any): any[] {
    const startIndex = (configPaginator.pageIndex - 1) * configPaginator.pageSize;
    return dataTable.slice(startIndex, startIndex + configPaginator.pageSize);
  }

  /**
   * Metodo para abrir el modal de analisis
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  openAnalyticsModal(id: any): void {
    const dialogRef = this.dialog.open(AnalyticsChatModalComponent, {
      width: '800px',
      data: { id: id } 
    });
  }

  /**
   * Validar el estado de los análisis por pagina
   * @author Yeison Sepulveda
   * @createdate 2024-03-07
   * @param paginaActual pagina actual para validar los estados por pagina
  */
  async obtenerEstadosRegistros(paginaActual: number) {
    if (this.abortController) {
        this.abortController.abort();
    }
    
    this.abortController = new AbortController();
    const signal = this.abortController.signal;

    try {
        let registrosPendientes = this.dataSourceRecordings.data.filter(ffll => ffll?.analisis !== 'COMPLETADO');
        this.selectionRecordings.clear();

        while (registrosPendientes.length > 0 && this.isActive) {
            if (signal.aborted) {
                console.log('Proceso cancelado');
                return;
            }

            const idsCasos = registrosPendientes.map(record => record.id);

            const body = {
                ids_case: idsCasos
            }

            const response: any = await this.chatService.chatAnalyticState(body).toPromise();

            if (response.data) {
                const estados = {};
                response.data.forEach(item => {
                    estados[item.id] = item.status.toUpperCase();
                });

                this.dataSourceRecordings.data.forEach(record => {
                    if (estados[record.id]) {
                        record.analisis = estados[record.id];
                    }
                });
            }

            // Actualiza la lista de registros pendientes después de la consulta
            registrosPendientes = this.dataSourceRecordings.data.filter(ffll => ffll.analisis !== 'COMPLETADO' && ffll.analisis !== 'ERROR');

            // Si no hay más registros pendientes, salir del bucle
            if (registrosPendientes.length === 0) {
                break;
            }

            // Espera 30 segundos antes de la próxima consulta
            await new Promise(resolve => setTimeout(resolve, 30000));
        }

        this.isAllComplete = true;
    } catch (error) {
        console.error('Error al obtener estados de registros pendientes:', error.message);
    }
  }

  /**
	 * Método enviar mensaje swal de error de la grabación
	 * @author Yeison Sepulveda
	 * @createdate 2024-04-23
	 */
  errorAnalyticModal(element: any) {
    this.chatService.chatAnalyticStateDetail(element.id).subscribe(
      (response: any) => {
        Swal.fire({
          icon: 'warning',
          title: '¡Aviso!',
          text: response.data.message,
          showCancelButton: true,
          confirmButtonText: 'Reprocesar análisis',
          cancelButtonText: 'Cancelar',
          confirmButtonColor: '#2CABBC',
          cancelButtonColor: '#FFFFFF',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.confirmReprocess(element);
          }
        });
      },
      (error) => {
          console.error('Error en la solicitud:', error.error.message);
      });
  }

  /**
   * Metodo para reprocesar análisis con error
   * @author Yeison Sepulveda
   * @createdate 2024-09-03
  */
  confirmReprocess(detalle_caso: any) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: `¿Quieres volver a intentar analizar el caso Nº ${detalle_caso.case_number}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, continuar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.chatService.chatReAnalyticState({ id_case: detalle_caso.id }).subscribe(
          response => {
            Swal.fire({
              title: '¡Excelente!',
              text: 'La solicitud ha sido procesada correctamente.',
              icon: 'success',
              confirmButtonText: '¡Entendido!',
              confirmButtonColor: '#2CABBC'
            }).then((result) => {
              if (result.isConfirmed) {
                console.log('Llamando a obtenerEstadosRegistros');
                this.obtenerEstadosRegistros(this.configPaginatorRecording.pageIndex);
              }
            });
          },
          error => {
            console.error('Error', error);
            Swal.fire({
              title: 'Opss ...',
              text: error.message,
              icon: 'error',
              confirmButtonText: '¡Entendido!',
              confirmButtonColor: '#2CABBC'
            });
          }
        );
      }
    });
  }

  /**
   * Descargar reporte de busqueda excel
   * @author Yeison Sepulveda
   * @createdate 2024-07-15
   */
  downloadReport(type: string): void {
    let filters: any;
  
    if (type === 'analysis') {
      const completedRecords = Array.from(this.selectedDownload.values())
        .filter(record => record.analisis === 'COMPLETADO');
  
      if (completedRecords.length === 0) {
        Swal.fire({
          icon: 'warning',
          title: 'No hay registros completados',
          text: 'No se han seleccionado registros para descargar informe.',
          showConfirmButton: true,
          confirmButtonColor: '#2CABBC',
          confirmButtonText: '¡Entendido!',
        });
        return;
      }
      //praparar la data a enviar
      filters = {
        ids_case: completedRecords.map(record => record.id)
      };
    } else if (type === 'strategy') {
      if (this.selectedStrategy.size <= 0) {
        Swal.fire({
          title: 'No hay registros seleccionados',
          text: 'Por favor, seleccione al menos un registro para descargar el informe',
          icon: 'warning',
          confirmButtonText: '¡Entendido!',
          confirmButtonColor: '#2CABBC',
        });
        return;
      }
      //praparar la data a enviar
      filters = {
        ids_case: Array.from(this.selectedStrategy.keys())
      };
    }
  
    //descargar reporte
    this.chatService.downloadReportChat(filters).subscribe(
      (response: any) => {
        const filename = this.generateFilename();
        this.fileTransition(response, filename);
      },
      (error) => {
        console.error('Ha ocurrido un error al descargar el reporte', error.message);
        Swal.fire({
          icon: 'error',
          title: '¡Ops! Ocurrio un error',
          text: error.message,
          showConfirmButton: true,
          confirmButtonColor: '#2CABBC',
          confirmButtonText: '¡Entendido!',
        });
      }
    );
  }

  /**
   * Metodo generar el nombre del documento a descargar
   * @author Yeison Sepulveda
   * @createdate 2024-0-03
   * @return nombre del archivo excel
  */
  generateFilename(): string {
    const currentDate = new Date().toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '');
  
    const currentTime = new Date().toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).replace(/:/g, '');
  
    const dateTimeString = `${currentDate}${currentTime}`;
    return `reporte_speech_${dateTimeString}.xlsx`;
  }

  /**
   * Método que se encarga de retonar la cantidad de items seleccioandos en la tabal de Analsis
   * @author Juan David Guerrero Vargas
   * @returns {number} Cantidad de items seleccionados
   */
  getTotalItemSelectedRecordings(): number {
    if (this.selectionRecordings && this.selectionRecordings.selected) {
      return this.selectionRecordings.selected.length;
    }
    return 0;
  }

  /**
   * Método validar si ya esta la columna de analisis y si el total es mayor
   * @author Yeison Sepulveda
   * @createdate 2024-06-18
   * @returns El valor true o false
  */
  get ColumnVisibleTotal(): boolean {
    const isVisible = this.displayedColumnsRecordings.includes('analisis');
    const hasSelectedItems = this.getTotalItemSelectedRecordings() > 0;
    return isVisible && hasSelectedItems;
  }

  /**
   * Metodo que se encarga de redirigir al metodo de aplicar estrategias
   * @author Yeison Sepulveda
   * @param stepperReference:any {any} Referencia del steper para realizar la accion de continuar el paso siguiente
   */
  applyStrategy(stepperReference?: any): void {
    this.deleteColumnAdherencia();
    this.getStrategys();
    const registros = Array.from(this.selectedDownload.values());
    this.configPaginatorStrategy.length = registros.length;
    this.listRecordingsStrategys= registros;
    this.dataSourceStrategy.data = this.paginateStaticTable(this.listRecordingsStrategys, this.configPaginatorStrategy);
    this.dataSourceStrategy.sort = this.sortStrategy;
    this.selectionStrategy.clear();
    this.StrategyPaginator.firstPage();
    
    if (stepperReference !== undefined) {
        stepperReference.next();
    }
    this.startegyComplete = false;
  }

  /**
   * Metodo que se encarga de obtenre el listado de estrategias
   * @author Yeison Sepulveda
   * @date 2024-08-08
  */
  getStrategys():void{
    const type_channel= [2];
    this.strategyService.listStrategies(type_channel).subscribe(res => {
        this.listStrategys = res.data;
    }); 
  }

  /**
   * Metodo que se encarga de abirir el modal apra poder abrir el modal de creacion de estrategias
   * @author Yeison Sepulveda
   * @date 2024-06-19
   */
  createStrategyTranscription(): void {
    const selectedRecordingIds = Array.from(this.selectedStrategy.keys());

    if (selectedRecordingIds.length === 1) {
        const selectedRecordingId = selectedRecordingIds[0];

        const dialogRef = this.dialog.open(TranscChatModalComponent, {
            width: '100%',
            maxHeight: '95vh',
            data: { modo: 'crear', id: selectedRecordingId }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.getStrategys();
        });
    } else if (selectedRecordingIds.length > 1) {
        Swal.fire({
          title: 'Advertencia',
          text: 'Seleccionaste más de un registro. Por favor, selecciona solo uno.',
          icon: 'warning',
          confirmButtonText: '¡Entendido!',
          confirmButtonColor: '#2CABBC',
        });
    } else {
        Swal.fire({
          title: 'Advertencia',
          text: 'Selecciona al menos un registro antes de continuar.',
          icon: 'warning',
          confirmButtonText: '¡Entendido!',
          confirmButtonColor: '#2CABBC',
        });
    }
  }

  
  /**
   * Metodo para reiniciar tabla eliminando columna adherencia
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
   */
  
  deleteColumnAdherencia(): void {
    const indexConfigAudio = this.displayedColumnsStrategy.findIndex(column => column === 'adherencia');
    if (indexConfigAudio !== -1) {
      this.displayedColumnsStrategy.splice(indexConfigAudio, 1);
    }
  }
  /**
   * Metodo que se encarga de enviar la informacion apra aplciar la estrategia seleccionada.
   * Actualiza los registros con la información de adherencia calculada y actualiza el paginador.
   * @author Juan David Guerrero Vargas
   * @Update Yeison Sepulveda
   * @dateupdate 03-07-2024
   */

  applyStrategySelected(): void {
    this.deleteColumnAdherencia();
    
    if (this.selectedStrategy.size <= 0) {
        Swal.fire({
          title: 'No hay registros seleccionados',
          text: 'Por favor, seleccione al menos un registro para aplicar la estrategia.',
          icon: 'warning',
          confirmButtonText: '¡Entendido!',
          confirmButtonColor: '#2CABBC',
        });
        return;
    }
    const selectedStrategyId = this.strategyControl.value;
    if (!selectedStrategyId) {
        Swal.fire({
            title: 'Estrategia no seleccionada',
            text: 'Por favor, seleccione una estrategia antes de continuar.',
            icon: 'warning',
            confirmButtonText: '¡Entendido!',
            confirmButtonColor: '#2CABBC',
        });
        return; // Detiene la ejecución del resto del código si no se ha seleccionado una estrategia
    }
    
    const selectedRecordingIds = Array.from(this.selectedStrategy.keys());

    Swal.fire({
        title: '¿Está seguro?',
        text: `Se aplicará la estrategia a ${this.selectedStrategy.size} registros.`,
        icon: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#2CABBC',
        cancelButtonColor: '#FFFFFF',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {

          this.startegyComplete = true;
          
          let strategyForms = {
                ids_cases: selectedRecordingIds,
                id_strategy: selectedStrategyId
            };

            this.strategyService.assignStrategyChat(strategyForms).subscribe(
                (response) => {

                    this.strategyService.applyAdherenciaChats(strategyForms.ids_cases).subscribe(
                        (adherenciaResponse: any[]) => {
                          this.StrategyPaginator.firstPage(); /// primera pagina
                            if (this.displayedColumnsStrategy.indexOf('adherencia') === -1) {
                                this.displayedColumnsStrategy.push('adherencia');
                            }

                            const filteredRecordings = this.listRecordingsStrategys.filter(record => selectedRecordingIds.includes(record.id));
                    
                            // Actualizar información por id del caseo, la adherencia agregandolo como campo de adherencia
                            adherenciaResponse.forEach((item) => {
                                const adherencia = `${item.adherence_percentage} / ${item.not_adherence_percentage}`;
                                const item_id = item.id;
                                const recordToUpdate = filteredRecordings.find(record => record.id === item_id);
                                if (recordToUpdate) {
                                  recordToUpdate.adherencia = adherencia;
                                }
                            });

                            // Actualizar información
                            this.listRecordingsStrategys = filteredRecordings;

                            this.configPaginatorStrategy.length = this.listRecordingsStrategys.length;

                            // Nuevo MatTableDataSource para evitar problemas de paginado
                            this.dataSourceStrategy = new MatTableDataSource(this.paginateStaticTable(this.listRecordingsStrategys, this.configPaginatorStrategy));
                            this.dataSourceStrategy.sort = this.sortStrategy;

                        },
                        (adherenciaError: any) => {
                            console.error("Error al calcular la adherencia:", adherenciaError.error);
                        }
                    );
                },
                (error: any) => {
                    console.error("Error al asignar la estrategia:", error);
                }
            );
            

        }
    });
  }

  /**
   * Método para abrir el modal de resultados de adherencia
   * @author Yeison Sepulveda
   * @createdate 2024-04-30
  */
  AdherenciaModal(id: any): void {
    const recording = this.listRecordingsStrategys.find(record => record.id === id);
    if (recording && recording.adherencia) {
        const dialogRef = this.dialog.open(AdherenciaChatModalComponent, {
            width: '800px',
            data: { id: id } 
        });
    } 
  } 
  

}
