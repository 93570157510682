import { ReportsReportService } from './../../../services/reports.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, ValidatorFn, FormArray } from '@angular/forms';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-report-form',
  templateUrl: './add-report-form.component.html',
  styleUrls: ['./add-report-form.component.sass']
})
export class AddReportFormComponent implements OnInit {

  userForm: FormGroup;
  reportList: any;
  reportsByUser: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private form: FormBuilder,
    private reportsReportService: ReportsReportService,
    private destroyService: DestroyComponentService,
    private dialogRef: MatDialogRef<AddReportFormComponent>
  ) { }

  ngOnInit(): void {
    this.formControl();
    this.getReportsList();
  }

  get error(): any { return this.userForm.controls; }

  get reportsArray(): FormArray {
    return this.userForm.get('reports') as FormArray;
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-03-04
   * Metodo que valida el momento que se hace click para actualizar el estado
   * en el form
   * @param event envento del click
   * @param i posicion del item en el array
   */
  validateAction(event, i): void {
    this.reportsArray.value[i].report = event.checked;
  }

  /**
   * @author Carlos Nieto
   * @createdate 2021-01-27
   * Metodo donde se establecen las validaciones del formulario
   */
  formControl(): void {

    this.userForm = this.form.group({
      rrhh_id: this.data.rrhh_id,
      reports: this.form.array([])
    });
  }

  /**
   * @author Carlos Nieto
   * @createdate 2021-03-04
   * Metodo que se encarga de actualizar los permisos de reportes del usuario
   * @param userForm formulario
   */
  editReport(userForm): void {
    this.reportsReportService.saveOrUpdateReport(userForm.value).subscribe(
      response => {
        Swal.fire({
          html: `Reportes actulizados con exito!`,
          icon: 'success',
          confirmButtonText: 'Aceptar'
        });
        this.dialogRef.close();
      }
    );
  }

  /**
  * @author Carlos Nieto
   * @createdate 2021-03-04
   * Metodo que se encarga de crear un nuevo control en el array principal
   * @param name nombre para mostrar en el from
   * @param idReport id del reporte
   */
  addNewControl(name, idReport): void {
    const newCheckControl = this.form.group({
      id_report: idReport,
      name: name,
      report: new FormControl(false)
    });
    this.reportsArray.push(newCheckControl);
  }

  /**
    * @author Carlos Nieto
     * @createdate 2021-03-04
     * Metodo que lista los reporte disponibles en bd
     */
  getReportsList() {
    this.reportsReportService.getReports().subscribe(
      response => {
        response['data'].forEach((element, index) => {
          this.addNewControl(element.nombre, element.id);
        });
        this.getReportsByUser();
      }
    );
  }

  /**
    * @author Carlos Nieto
     * @createdate 2021-03-04
     * Metodo que lista reportes asociados al usuario
     */
  getReportsByUser() {
    this.reportsReportService.getReportsByUser(this.data.rrhh_id).subscribe(
      response => {
        this.setDefaultReportsByUser(response['data']);
      }
    );
  }

  /**
  * @author Carlos Nieto
   * @createdate 2021-03-04
   * Metodo que setea los datos en el array de from para
   * mostrar los datos asociados
   */
  setDefaultReportsByUser(data) {
    let report = this.reportsArray.value;
    for (let i = 0; i < report.length; i++) {
      data.forEach(elementDefaulUser => {
        if (report[i].id_report === elementDefaulUser.id) {
          this.reportsArray.controls[i].get('report').setValue(true);
        }
      });
    }
    this.reportList = this.reportsArray.value;
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

}

