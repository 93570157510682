<div class="novelities">
  <div class="header">
    <h1>Desprendibles de pago</h1>
    <p>En este espacio podrás revisar y cargar los ítems de los desprendibles<br> de pago, no olvides, "El respeto y
      empatía son clave".</p>
  </div>

  <div style="margin-top: 20px" class="row">

    <div class="col-2">
      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Mes</mat-label>
        <mat-select [formControl]="selectMonths">
          <mat-option value="">Seleccionar</mat-option>
          <mat-option *ngFor="let item of months" [value]="item.id">
            {{item?.name}}
          </mat-option>
        </mat-select>

      </mat-form-field>
    </div>

    <div class="col-2">
      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Año</mat-label>
        <mat-select [formControl]="selectYears">
          <mat-option value="">Seleccionar</mat-option>

          <mat-option *ngFor="let item of years" [value]="item">
            {{item}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-2"></div>

    <div class="col-3">
      <button mat-raised-button (click)="downloadTemplate()" class="download btn" style="box-shadow:none !important"
      [disabled]="selectMonths.invalid || selectYears.invalid" >Descargar plantilla
        <span class="fi-rr-download"></span>
      </button>
    </div>

    <div class="col-3">
      <input #fileInput type="file" accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      (ngModelChange)="onChangeFile($event)"
      (change)="onChangeFile($event)" style="display: none">

      <button mat-raised-button color="primary" class="btn" (click)="fileInput.click()" class="upload btn" [disabled]="selectMonths.invalid || selectYears.invalid"
        style="box-shadow:none !important">Cargar plantilla
        <span class="fi-rr-upload"></span>
      </button>
    </div>

    <div class="col-12" style="margin-top: 30px"><b>Nota:</b> Ten en cuenta que debes <b>seleccionar el mes</b> para
      <b>descargar, cargar y ver los desprendibles.</b> Si te equivocas, podrás cargar de nuevo la plantilla y el
      sistema hará automáticamente las correcciones.</div>

    <div *ngIf="result_busqueda == 0" style="padding-top: 50px" class="col-12">
      <img src="./assets/images/desk.png" alt="" style="width: 100%; height: 400PX;">
    </div>

  </div>


  <div style="margin-top: 50px" class="row" *ngIf="result_busqueda == 1">

    <div class="col-6">
      <mat-form-field appearance="fill" class="filter">
        <mat-label>Documento</mat-label>
        <input matInput type="text" #input autocomplete="off" (keydown.enter)="onBlurMethod($event.target.value)">
        <mat-select name="ampm" class="ampm" matSuffix [formControl]="typeDocument"
                    style="width: 50px; margin-left: 5px;">
          <mat-option *ngFor="let item of documents" [value]="item?.id">{{item.key}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </div>


  <div class="table-container" *ngIf="result_busqueda == 1">
    <div class="mat-elevation-z1">


      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button matTooltip="Ver documento"
              (click)="getCertificate(row.rrhh_id,true)">
              <span class="fi-rr-eye"></span>
            </button>
            <button mat-icon-button matTooltip="Descargar documento"
              (click)="getCertificate(row.rrhh_id,false)">
              <span class="fi-rr-cloud-download"></span>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="fecha_carga">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de carga </th>
          <td mat-cell *matCellDef="let row"> {{row.created_at | date:"dd-MM-yyyy"}} </td>
        </ng-container>

        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="negrita"> Nombre </th>
          <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>

        <ng-container matColumnDef="documento">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="negrita"> Documento </th>
          <td mat-cell *matCellDef="let row"> {{row.document}} </td>
        </ng-container>

        <ng-container matColumnDef="salario_basico">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="negrita"> Salario básico </th>
          <td mat-cell *matCellDef="let row"> {{row.basic_salary}} </td>
        </ng-container>

        <ng-container matColumnDef="total_descuentos">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="negrita"> Total descuentos </th>
          <td mat-cell *matCellDef="let row"> {{row.total_discounts}} </td>
        </ng-container>

        <ng-container matColumnDef="total_neto_pagar">
          <th class="end negrita" mat-header-cell *matHeaderCellDef mat-sort-header> Total neto pagado </th>
          <td mat-cell *matCellDef="let row"> {{row.total_net_paid}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="7">No hay datos</td>
        </tr>
      </table>

      <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)"
        [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>

    </div>
  </div>
</div>
