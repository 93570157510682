<div class="row col-12 filter mx-0" style="padding-top: 40px;">

    <mat-form-field appearance="fill" class="col-3 ps-2" >
      <mat-label>Fecha de creación</mat-label>

      <input matInput
        [matDatepicker]   = "picker"
        autocomplete      = "off"
        readonly          = "true"
        (dateChange)      = "applyFilter($event)"
        #input
        >

        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon><span class="fi fi-rr-calendar"></span></mat-icon>
        </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="col-3 " appearance="fill" *ngIf="requestStates" >
      <mat-label>Estado</mat-label>
      <mat-select (selectionChange)="filterByState($event.value)" multiple>
          <mat-option *ngFor="let state of requestStates" [value]="state.id">{{state.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    
    <div class="col-3 offset-3 buttons buttons pe-2">
      <button mat-raised-button color="primary" (click)="descargaGestion()" class="w-100 bold">
        Descargar Gestión &nbsp; <i class="fi-rr-download" ></i></button>

    </div>

</div>


<div class="table-container">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>

              <ng-container matColumnDef="acciones">
                    <th class="start" mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <div class="action-icons" >
                          <button mat-icon-button (click)="viewSolicitud(row)">
                            <i class="fi-rr-eye"></i>
                          </button>
                          <button mat-icon-button (click)="editSolicitud(row)" *ngIf="row.request_state_id != 6">
                              <i class="fi-rr-edit"></i>
                          </button>
                        </div>

                    </td>
              </ng-container>
              <ng-container matColumnDef="request_date">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de creación </th>
                  <td mat-cell *matCellDef="let row"> {{row.request_date|date:'yyyy-MM-dd'}} </td>
              </ng-container>
              <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Título de solicitud </th>
                  <td mat-cell *matCellDef="let row">
                    {{row.title | slice:0:30}}<small *ngIf="row.title.length > 30">...</small>
                  </td>
              </ng-container>

              <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción de solicitud </th>
                  <td mat-cell *matCellDef="let row" matTooltip="{{row.description}}"[matTooltipClass]="'my-tooltip'">
                       {{row.description | slice:0:30}}<small *ngIf="row.description.length > 30">...</small>
                </td>
              </ng-container>
              <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef>Valor</th>
                <td mat-cell *matCellDef="let row" >{{getSelectedCotization(row)}}</td>
              </ng-container>
              <ng-container matColumnDef="created_by">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Solicitante </th>
                <td mat-cell *matCellDef="let row"> {{row.created_by}} </td>
            </ng-container>
            <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Empresa </th>
                <td mat-cell *matCellDef="let row" > {{row.company.name}} </td>
            </ng-container>

              <ng-container matColumnDef="state">
                  <th class="end" mat-header-cell *matHeaderCellDef mat-sort-header>Estado </th>
                  <td mat-cell *matCellDef="let row" >
                    <div style="white-space: nowrap;">
                      
                      <i
                        class                     = "span"
                        [style.backgroundColor]   = "row.request_state.id != 4  ? row.request_state.color : '#7ECE57'"
                        [style.color]             = "row.request_state.id != 4  ? row.request_state.color : '#7ECE57'"
                        >&nbsp;</i> {{row.request_state.name}}
                    </div>
                  </td>
              </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro: "{{input.value}}"</td>
        </tr>

      </table>

      <mat-paginator [length]="length"
                     [pageSize]="pageSize"
                     (page)="pageEvent($event)"
                     [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>


    </div>
</div>

