<button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<form mat-dialog-content class="containerDialog" [formGroup]="subItemForm">
    <h2>Crear Subítem</h2>
    <mat-divider class="mb-3"></mat-divider>
    <mat-form-field appearance="fill">
        <mat-label>Nombre</mat-label>
        <input  matInput formControlName="nombre"
        [matAutocomplete]="auto">
        <mat-icon matSuffix><span class="fi-rr-search" (click)="getSubItems()"></span></mat-icon>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" 
        (optionSelected)="newSubItem()">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.nombre">
              {{option.nombre}}
            </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="verError">No se encontraron coincidencias con el nombre que acabas de ingresar</mat-error>
        <mat-error *ngIf="subItemForm.get('nombre').hasError('required')">Por favor ingrese un nombre</mat-error>
    </mat-form-field>
    <button mat-button class="nuevo" *ngIf="nuevo" (click)="newSubItem()">Nuevo sub item <mat-icon matSuffix><i class="fi-rr-add"></i></mat-icon></button>
    <mat-form-field appearance="fill" *ngIf="descripcion">
        <mat-label>Descripción</mat-label>
        <textarea  matInput formControlName="descripcion"></textarea>
    </mat-form-field>

    <div class="btns-dialog">
        <button mat-stroked-button (click)="subItemRef.close()" [disabled]="!continue">Cancelar</button>
        <button mat-raised-button color="primary" [disabled]="!continue" (click)="saveSubItem()">Guardar</button>
    </div>
</form>