import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MatrizService } from 'src/app/modules/calidad/services/matriz.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
  selector: 'app-analistas-calidad',
  templateUrl: './analistas-calidad.component.html',
  styleUrls: ['./analistas-calidad.component.sass']
})
export class AnalistasCalidadComponent implements OnInit, OnDestroy {
  private uuid = 'fedb3706-912a-11ee-b9d1-0242ac120002';
  public config: any;

  analistas = [];
  supervisores = [];
  seleccion = [];
  listAnalistas = [];
  listSupervisores = [];
  listEliminated = [];
  selectable = false;
  removable = false;
  showEdit:boolean = false;
  btns:boolean = false;
  input:boolean = false;
  subscription:Subscription;
  filtroCedula:string = '';
  titulo:string = '';
  constructor(
    private matrizService:MatrizService,
    public analistaRef:MatDialogRef<AnalistasCalidadComponent>,
    @Inject(MAT_DIALOG_DATA) public datos:any,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
      this.tipoListas(this.config);
    });
    this.subscription = this.matrizService.refresh$.subscribe(()=>{
      this.showAsignados();
      this.edit();
    });
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  tipoListas(config){
    if (this.datos.type == 'analista') {
      this.getListAnalistas();
      this.getAnalistasById();
      this.validEdit();
      this.titulo = config.labels.analystTitle;
    }else if (this.datos.type == 'super') {
      this.titulo = config.labels.supervisorTitle;
      this.getListSupervisores();
      this.getSupervisoresById();
      this.validEdit();
    }
  }

  getListAnalistas(){
    const params = {
      idMatriz: this.datos.id_matriz,
      cedula:'',
    }
    if (this.filtroCedula != '') {
      params.cedula = this.filtroCedula;
    }
    this.matrizService.getListAnalistas(params).subscribe((resp:any)=>{
      this.listAnalistas = resp;
    });
  }

  getAnalistasById(){
    this.matrizService.getAnalistaById(this.datos.id_matriz).subscribe((resp:any)=>{
      this.analistas = resp;
    });
  }

  getListSupervisores(){
    const params = {
      idMatriz: this.datos.idMatriz,
      cedula: ''
    }
    if (this.filtroCedula != '') {
      params.cedula = this.filtroCedula;
    }
    this.matrizService.showSupervisores(params).subscribe((resp:any)=>{
      this.listSupervisores = resp;
    });
  }

  getSupervisoresById(){
    this.matrizService.getSupervisorByMatriz(this.datos.idMatriz).subscribe((resp:any)=>{
      this.supervisores = resp;
    })
  }

  validEdit(){
    if (this.analistas.length == 0 || this.supervisores.length == 0) {
      this.showEdit = false;
      this.btns = false;
      this.input = true;
    }else {
      this.input = false;
      this.showEdit = true;
    }
  }

  edit(){
    this.btns = !this.btns;
    this.input = !this.input;
    this.showEdit = !this.showEdit;
    this.selectable = !this.selectable;
    this.removable = !this.removable;
    this.analistas.forEach(analista => {
      this.listAnalistas.forEach((element,index) => {
        if (analista.id_usuario == element.id) {
          this.listAnalistas.splice(index,1);
        }
      });
    });
    this.supervisores.forEach(supervisor => {
      this.listSupervisores.forEach((element,index) => {
        if (supervisor.id_usuario == element.id) {
          this.listSupervisores.splice(index,1);
        }
      });
    });
    this.listEliminated = [];
  }

  asignarAnalistas(){
    let mensaje:string = '';
    this.seleccion = this.seleccion.map(item =>{
      return {analista_id: item.id,nombre_analista: item.nombre, cedula: item.cedula}
    });
    const data = {
      idMatriz : null,
      data: this.seleccion,
    }
    if (this.datos.type == 'super') {
      mensaje = this.config.modal.supervisorOk;
      data.idMatriz = this.datos.idMatriz;
    }else{
      mensaje = this.config.modal.analistOk;
      data.idMatriz = this.datos.id_matriz;
    }
    if (data.data.length == 0) {
      this.listEliminated.forEach(analista => {
        this.matrizService.eliminateAnalista(analista.id_usuario,data.idMatriz).subscribe((resp:any)=>{
          Tools.swalSuccess(this.config.modal.excellent, this.config.modal.saveOk);
          this.analistaRef.close();
        });
      });
    }else{
      this.matrizService.asignarAnalistas(data).subscribe((resp:any)=>{
        if (resp.respuesta.codigo == 202) {
          this.listEliminated.forEach(analista => {
            this.matrizService.eliminateAnalista(analista.id_usuario,data.idMatriz).subscribe(()=>{});
          });
          Tools.swalSuccess(this.config.modal.excellent, mensaje);
          this.seleccion = [];
          this.analistaRef.close();
        }
      });
    }
  }

  removeAnalista(analista){
    this.listEliminated.push(analista);
    this.listAnalistas.push(this.listEliminated.map(item =>{
      return {id: item.id_analista,nombre: item.nombre_analista}
    }))
    const index = this.analistas.indexOf(analista);
    if (index >=0) {
      this.analistas.splice(index,1);
    }
  }

  removesupervisor(supervisor){
    this.listEliminated.push(supervisor);
    this.listEliminated.map(supervisor=>{
      return {id: supervisor.id_usuario,nombre: supervisor.nombre,cedula: ''}
    });
    const index = this.supervisores.indexOf(supervisor);
    if (index >=0) {
      this.supervisores.splice(index,1);
    }
  }

  filtrar(){
    if (this.datos.type == 'super') {
      this.getListSupervisores();
    }else{
      this.getListAnalistas();
    }
  }

  showAsignados(){
    if (this.datos.type == 'super') {
      this.getSupervisoresById();
    }else{
      this.getAnalistasById();
    }
  }

}
