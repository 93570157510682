<div id="fab-dismiss" *ngIf="fabTogglerState === 'active'" (click)="onToggleFab()"></div>

<div class="fab-container">
	<button mat-fab class="fab-toggler" (click)="onToggleFab()" color="primary">
		<i class="fi-sr-asterisk" [@fabToggler]="{ value: fabTogglerState }"></i>
	</button>

	<!-- 
		<button mat-fab class="fab-toggler button-rap" (click)="chageStateModal()" >
		RPA
	</button> 
	-->

	<div [@speedDialStagger]="buttons.length" style="background-color: #fafafa; padding-right: 20px; padding-left: 10px">
		<div *ngFor="let btn of buttons">
			<div *ngIf="btn.permiso" style="text-align: right">
				<span style="text-align: right; cursor: pointer; padding-bottom: 10px" (click)="funcionalidad(btn.key)">{{ btn.label }}</span>

				<button style="text-align: right" mat-icon-button class="fab-secondary" color="secondary" (click)="funcionalidad(btn.key)">
					<i *ngIf="btn.key != 'voz'" class="{{ btn.icon }}"></i>
					<mat-icon *ngIf="btn.key == 'voz'">phone</mat-icon>
				</button>
			</div>
		</div>
	</div>
</div>

<app-chat [historial]="historial"></app-chat>
<chat-list-contact></chat-list-contact>
<app-active-chat></app-active-chat>

<app-vicidial-voz
	*ngIf="vozButton"
	[phoneCallManual]="phoneCallManual"
	(vicidialActive)="changeVicidialArg($event)"
	(viewPhoneButton)="changeViewPhone($event)"
	(callResponse)="processCallResponse($event)"
	[campaignId]="campaignId"
	[phoneDial]="vicidialService.getPhoneNumber()"
></app-vicidial-voz>

<app-videollamada 
	*ngIf="VideollamadaButton && videoLlamada" 
	(viewVideoButton)="changeViewVideo($event)"	
></app-videollamada>
<!-- 

	<div class="modal-center"  class="container-modal">
	-->
<div [ngClass]="stateMinimizeModalRPA ? 'container-modal-minimized' : 'container-modal'"  *ngIf="stateOpenModal.status">

	<div class="modal-center">
		<app-rpa-modal class="w-100" (actionModal)="modifyModal($event)" [urlByIframe]="'outbound'" [uid]="stateOpenModal.uid"></app-rpa-modal>
	</div>

</div>

<div class="minimized" (click)="modifyModal({action:'open'})" *ngIf="stateMinimizeModalRPA">
	<span>&nbsp;<i class="fi-rr-angle-down"></i>  RPA</span>
</div>
