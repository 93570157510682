import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { onMainContentChange, onSideNavChange, animateText } from '../animation';
import { SidenavService } from '../../core/services/utils/sidenav.service';
import { AuthService } from '../../core/services/rest/auth.service';
import { LeftNavComponent } from '../left-nav/left-nav.component';
import { Router } from '@angular/router';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { ScheduleService } from 'src/app/core/services/rest/schedule.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { SocketActiveService } from 'src/app/modules/livechat/services/socket/socket.service';
import { socketService } from 'src/app/modules/livechat/services/socket/index.service';
import { conversationsService } from 'src/app/modules/livechat/services/session/conversations.service';

import { Conversation } from 'src/app/modules/livechat/modules/chat/models/conversation';
import { MatDialog } from '@angular/material/dialog';

import { ModalPasswordComponent } from '../modal-password/modal-password.component';
import { VicidialService } from 'src/app/shared/vicidial-voz/services/vicidial.service';
import * as moment from 'moment';
import { NoveltiesService } from 'src/app/modules/nomina/services/novelties.service';
import { sessionService } from 'src/app/modules/livechat/services/session/index.service';
import { MixinsService } from 'src/app/modules/livechat/services/rest/mixins.service';
import { DataDisconnectSocket } from 'src/app/modules/livechat/modules/chat/models/dataDisconnectSocket';
import { SocketDisconnectService } from 'src/app/modules/livechat/services/socket/socket-disconnect.service';
import Swal from 'sweetalert2';
import { I } from '@angular/cdk/keycodes';
import { DialogStateService } from 'src/app/core/services/utils/DialogSurveysState.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';
interface MenuItem {
  name: string;
  link: string;
  icon: string;
}

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.sass'],
  animations: [onMainContentChange, onSideNavChange, animateText]
})
export class SideBarComponent implements OnInit {

  public onSideNavChange: boolean;
  public darkMode: boolean = false;
  public sideNavState: boolean = false;
  public linkText: boolean = false;
  public pages: Array<MenuItem> = [];
  public appStorage: any;
  public scheduleTypes: any = [];
  public scheduleTypesMenu: any = [];
  public socket: socketService;
  public conversations: Conversation[] = [];

  public scheduleEnd: any;
  public validatorTurn: any = true;
  public finishTurn: any = false;
  public vicidialStatus: string;

  public verNotificacion: boolean = false;
  public verAlert: boolean = false;

  public dataAgentOmni: DataDisconnectSocket;
  public livechatRol: any;
  public loadedSchedule: boolean = false;

  public item;
  public locale;


  @ViewChild(LeftNavComponent) leftNav: LeftNavComponent;
  @HostBinding('class')
  get themeMode() {
    return this.darkMode ? 'dark-theme' : 'light-theme';
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver,
    private _sidenavService: SidenavService,
    private authService: AuthService,
    private router: Router,
    private destroyService: DestroyComponentService,
    private alertsService: AlertsService,
    private scheduleService: ScheduleService,
    private _socketActiveService: SocketActiveService,
    public dialog: MatDialog,
    private vicidialService: VicidialService,
    private noveltiesService: NoveltiesService,
    private _conversationService: conversationsService = null,
    private _storageService: sessionService,
    private _mixinsService: MixinsService,
    private _socketDisconnectService: SocketDisconnectService,
    private dialogStateService:DialogStateService,
    private storageService: StorageService,


  ) {

    this._sidenavService.sideNavState$.subscribe(res => {
      this.onSideNavChange = res;
      this.linkText = res;
    });

  }

  ngOnInit(): void {
    this.item = localStorage.getItem('locale');
    this.locale = this.item ? JSON.parse(this.item) : 'es';

    this.darkMode = localStorage.getItem('theme') === 'dark-theme' ? true : false;

    this.pages = [
      { name: 'Menú', link: 'some-link', icon: 'fi-sr-grid' },
      { name: 'Buscar', link: 'some-link', icon: 'fi-rr-search' },
      { name: 'Perfil', link: 'some-link', icon: 'fi-rr-user' }
    ];

    this.appStorage = this.authService.getUser().applications as Array<any>;

    this.vicidialService.vicidialStatusObservable.subscribe(value => {
      this.vicidialStatus = value;
    });

    if(this.getSurveyList.length > 0 && this.dialogStateService.isDialogOpen() == false){
      this.dialogStateService.openDialogSurveys();
    }else if(this.getSurveyList.length > 0 && this.dialogStateService.isDialogOpen() == true){      
      this.dialogStateService.resetDialog();
    }




  }

  get getSurveyList():any[]{
    const surveyList = this.storageService.getStorage('surveys');
    if(surveyList !== undefined && surveyList !== null && Array.isArray(surveyList) == true && surveyList.length > 0){      
        let surveysNotSkiped = surveyList.filter( survey => survey?.is_skiped == false )
        return surveysNotSkiped;
    }
    return []
  }


  /**
   * Metodo que cambia guarda el tema del aplicativo en el local storage
   * @param modeDark
   */
  changeTheme(modeDark): void {
    localStorage.setItem('theme', modeDark ? 'light-theme' : 'dark-theme');
  }

  /**
   * Metodo del toggle que cambia el tema del aplicativo
   */
  onSinenavToggle(): void {
    this.sideNavState = !this.sideNavState;
    this.linkText = !this.linkText;
    this._sidenavService.sideNavState$.next(this.sideNavState);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-01
   * metodo que llama un metodo del menu de aplicativos para cargarlo
   * @param name key del menu del aplicativo o modulo
   */
  idMenuLeft(name: any): void {

    this.leftNav.consultMenu(name);
  }

  /**
   * @author Olme Marin
   * @createdate 2021-12-16
   * Método para obtner el rol del usuario de livechat
  */
  getRoleLivechatUser() {
    return this.livechatRol = this._storageService.getRoleUserLivechat();
  }

  /**
   * @author Olme Marin
   * @createdate 2021-09-13
   * Método para obtener el socket activo de omnicanlidad
  */
  getSocketActive() {
    return this.socket = this._socketActiveService.getSocketActive();
  }

  /**
   * @author Olme Marin
   * @createdate 2021-09-13
   * Método para obtener la lista de conversaciones de omnicanlidad
  */
  getConversations() {
    return this.conversations = this._conversationService.getConversations();
  }

  /**
   * @author Olme Marin
   * @createdate 2021-09-13
   * Método para desconectar el socket de un agente de omnicanlidad
  */
  async disconnectSocket() {
    const rrhh: any = this.authService.getUser();
    let projectTemp: any = this._storageService.getItem('project');
    const project = JSON.parse(projectTemp);
    console.log('project-cerrar', project.id);
    const data: DataDisconnectSocket = { rrhh: rrhh.rrhh_id, project: project.id };
    this.dataAgentOmni = data;
    try {
      await this._socketDisconnectService.disconnectSocket(rrhh.rrhh_id, this.dataAgentOmni);
    } catch (e) {
      console.error("Error api logout livechat")
      // Se notifica que no se pudo quitar de redis a un agente
      this._mixinsService.agentStuckInRedis(this.dataAgentOmni).subscribe((res) => {
        if (res.data === 'success') {
          console.log('Se notifico error por api');
        }
      });
    }
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que trae el horario del usuario logueado
   */
  getSchedule(): void {
    this.scheduleService.getSchedule().subscribe((resp) => {

      this.scheduleTypesMenu = resp.data;


      this.scheduleTypesMenu.forEach(element => {
        // guardar hora fin
        if (element.schedule_type.key === 'workday') {
          this.scheduleEnd = `${element.date_start} ${element.hour_end}`;
        }

        if (element.schedule_type.key === 'workday' && element.status === 'started') {
          this.validatorTurn = false;
          this.leftNav.changeActivateMenu();

        } else if (element.schedule_type.key === 'workday' && element.status === 'stopped') {
          this.validatorTurn = true;
          if (!this.leftNav.activateMenu) {
            this.leftNav.changeActivateMenu();
          }
          this.finishTurn = true;
        } else if ((element.schedule_type.key === 'break' || element.schedule_type.key.toLowerCase() === 'lunch' || element.schedule_type.key === 'training' || element.schedule_type.key === 'banio' || element.schedule_type.key === 'pausa_activa' || element.schedule_type.key === 'feedback') && element.status === 'started') {

          const app = this.router.url.substring(6).split('/', 1)[0];
          console.log('App: ', app)
          if (app === 'livechat') {
            this.getRoleLivechatUser();
            this.conversations = this._conversationService.getConversations();
            if (this.conversations.length > 0 && this.livechatRol === 'livechat::asesor') {
              this.alertsService.alertWarningV2('Atención', 'No puede cambiar estado porque tienes conversaciones sin cerrar.');
            } else {
              this.disconnectSocket();
              const tip = new FormData();
              tip.append('schedule_type_id', element.schedule_type_id);
              tip.append('schedule_id', element.id);

              const dialogPassword = this.dialog.open(ModalPasswordComponent, {
                width: '60%',
                disableClose: true,
                data: {
                  type: 'crear',
                  title: 'Ingrese su Contraseña'
                },
                panelClass: 'custom-dialog-container'
              });

              dialogPassword.afterClosed().subscribe(() => {
                tip.append('action', 'stop');
                // if(this.vicidialStatus == 'PAUSED'){
                //   this.vicidialService.setStatusCiu('READY', '');
                // }
                this.vicidialService.setStatusCiu('PAUSED');
                this.scheduleService.postSchedule(tip).subscribe((resp) => {
                  this.getSchedule();
                  const url = window.location.href;
                  if (url.includes('livechat')) {
                    window.location.reload();
                  }
                });
              });
            }
          } else {
            const tip = new FormData();
            tip.append('schedule_type_id', element.schedule_type_id);
            tip.append('schedule_id', element.id);

            const dialogPassword = this.dialog.open(ModalPasswordComponent, {
              width: '60%',
              disableClose: true,
              data: {
                type: 'crear',
                title: 'Ingrese su Contraseña'
              },
              panelClass: 'custom-dialog-container'
            });

            dialogPassword.afterClosed().subscribe(() => {
              tip.append('action', 'stop');
              // if(this.vicidialStatus == 'PAUSED'){
              //   this.vicidialService.setStatusCiu('READY', '');
              // }
              this.vicidialService.setStatusCiu('PAUSED');
              this.scheduleService.postSchedule(tip).subscribe((resp) => {
                this.getSchedule();
                const url = window.location.href;
                if (url.includes('livechat')) {
                  window.location.reload();
                }
              });
            });
          }
        }

      });
      this.loadedSchedule = true;
    });
  }


  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que define una accion dependiendo del turno que escoja
   * @param item
   */
  actionSchedule(item: any) {

    //validacion de usuarios administrativos pueden iniciar fuera de los 30 min antes de iniciar turno
    const decryptToken = this.authService.decryptToken();
    let position_category = decryptToken.rrhh.position_category == "" ? "pass" : decryptToken.rrhh.position_category;

    const nowTime = moment().format('YYYY-MM-DD HH:mm');
    const scheduleTime = moment(`${item.date_start} ${item.hour_start}`).subtract(30, 'minutes').format('YYYY-MM-DD HH:mm');

    if (item.schedule_type.key === 'workday' && nowTime < scheduleTime && position_category != 'Administrativa') {
      this.alertsService.alertInfo('Cuidado', 'Puedes marcar turno hasta 30 minutos antes de la hora inicial');
    } else {

      const tip = new FormData();

      if (item.status === 'waiting') {
        tip.append('action', 'start');
        console.log('reactivo');

      } else if (item.status === 'started') {
        tip.append('action', 'stop');
      }
      tip.append('schedule_type_id', item.schedule_type_id);
      tip.append('schedule_id', item.id);

      if (item.schedule_type.key === 'workday') {

        if (item.status === 'started') {
          console.log('opcion 1');

          let value = this.alertsService.alertConfirm('¿Desea finalizar su turno?');
          value.then((result) => {
            if (result.isConfirmed) {
              const app = this.router.url.substring(6).split('/', 1)[0];
              console.log('App: ', app)
              if (app === 'livechat') {
                this.getRoleLivechatUser();
                this.conversations = this._conversationService.getConversations();
                if (this.conversations.length > 0 && this.livechatRol === 'livechat::asesor') {
                  this.alertsService.alertWarningV2('Atención', 'No puede cerrar sesión porque tienes conversaciones sin cerrar.');
                } else {
                  this.disconnectSocket();
                  this.scheduleService.postSchedule(tip).subscribe((resp) => {
                    // Finalizar turno . Logout viciphone
                    if (this.vicidialStatus != '') {
                      this.vicidialService.setStatusCiu('LOGOUT', item.schedule_type.key);
                    }
                    this.getSchedule();
                  });
                }
              } else {
                this.scheduleService.postSchedule(tip).subscribe((resp) => {
                  // Finalizar turno . Logout viciphone
                  if (this.vicidialStatus != '') {
                    this.vicidialService.setStatusCiu('LOGOUT', item.schedule_type.key);
                  }
                  this.getSchedule();
                });
              }
            }
          });

        } else {
          console.log('opcion 2');
          this.scheduleService.postSchedule(tip).subscribe((resp) => {
            this.getSchedule();
            const logged = this.authService.getUser();
            this.noveltiesService.createTimeDelayNovelties({
              rrhhId: logged.rrhh_id,
              campaignId: logged.rrhh.campaign_id || 1
            }).subscribe(respNovelty => console.log(respNovelty));
          });

        }

      } else {
        console.log('opcion 3');
        if (item.status === 'waiting') {
          console.log('opcion 4');
          // saber si hay conversaciones de omnicanalidad
          this.getRoleLivechatUser();
          this.conversations = this._conversationService.getConversations();
          if (this.conversations.length > 0 && this.livechatRol === 'livechat::asesor') {
            this.alertsService.alertWarningV2('Atención', 'No puede cambiar estado porque tienes conversaciones sin cerrar.');
          } else {
            this.scheduleService.postSchedule(tip).subscribe((resp) => {

              // Pausa de turno. Logout telefono indicando tip
              // if (this.vicidialStatus == 'READY'){
              this.vicidialService.setStatusCiu('PAUSED', item.schedule_type.key);
              // Desabilitar el agente en omnicanalidad
              this.getSocketActive();
              if (this.socket) this.disconnectSocket();
              // }
              this.getSchedule();
            });
          }
        } else {
          console.log('opcion 5');
          this.scheduleService.postSchedule(tip).subscribe((resp) => {
            // Tomar descanso
            this.getSchedule();
          });

        }
      }

    }
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo que define un color segun un estatus
   * @param status estatus que define el color
   * @returns retorna el color
   */
  getColor(status: any) {

    switch (status) {
      case 'waiting': return 'orange';
      case 'started': return 'green';
      case 'stopped': return 'red';
    }

  }

  notificaciones() {
    this.verNotificacion = !this.verNotificacion;
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }


  /**
  * @author Javier Saldana
  * @createdate 2022-04-07
  * Metodo para cerrar sesion de omni
  */

  async logoutOmni(logout = true) {
    this.getRoleLivechatUser();
    this.conversations = this._conversationService.getConversations();
    if (this.conversations.length > 0 && this.livechatRol === 'livechat::asesor') {
      this.alertsService.alertWarningV2('Atención', 'No puede cerrar sesión porque tienes conversaciones sin cerrar.');
      return false
    } else {
      this.getSocketActive();
      try {
        await this.disconnectSocket();
      } catch (e) {
        console.error("Error api logout livechat")
      }

      if (logout) {
        try {
          await this.authService.logOut().toPromise()
          window.location.replace('/login');
          localStorage.clear();
          localStorage.setItem('alert', 'true');
        } catch (e) {
          console.error(e)
        }
      } else {
        try {
          const res: any = await this.scheduleService.getSchedule().toPromise()
          this.scheduleTypes = res.data
          const element = this.scheduleTypes.filter(item => item.schedule_type.key === 'workday')
          if (!element[0]) {
            console.warn(`No hay horario`)
            return
          }
          const theSchedule = element[0]
          const tip = new FormData();
          tip.append('action', 'stop');
          tip.append('schedule_type_id', theSchedule.schedule_type_id);
          tip.append('schedule_id', theSchedule.id);

          await this.scheduleService.postSchedule(tip).toPromise()
          await this.authService.logOut().toPromise()
          window.location.replace('/login');
          localStorage.clear();
          localStorage.setItem('endturn' , 'true');
        } catch (e) {
          console.error(e)
        }
      }
    }
  }

  /**
  * @author Daniel Dominguez
  * @createdate 2022-04-05
  * Metodo modal para cerrar cesion
  */
  closeSession() {
    let str =
      '\n' +
      '¿En verdad deseas cerrar la sesión ?, podrás iniciar la sesión en' +
      '\n' +
      'cualquier momento y continuar trabajando por el día de hoy.';
    Swal.fire({
      width: "auto",
      title: '¿Deseas cerrar sesión?',
      html:
        '<pre style="font-family: Poppins; font-size: 14px;">' + str + '</pre>',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      //cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Cerrar sesión',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const app = this.router.url.substring(6).split('/', 1)[0];
        this._storageService.clearSession();
        if (app === 'livechat') {
          this.logoutOmni(true)
        } else {
          this.authService.logOut().subscribe(respuesta => {
            window.location.replace('/login');
            localStorage.clear();
            localStorage.setItem('alert' , 'true'); //guardar en variable de localStorage
          });
        }
      }
    });
  }
  /**
  * @author Daniel Dominguez
  * @createdate 2022-04-05
  * Metodo modal para cerrar cesion
  */
  closeSchedule() {
    let str =
      '\n' +
      '¿En verdad deseas finalizar turno ?, recuerda que si finalizas el ' +
      '\n' +
      'turno no podrás seguir trabajando el día de hoy .';
    Swal.fire({
      width: "auto",
      title: '¿Deseas finalizar turno?',
      html:
        '<pre style="font-family: Poppins; font-size: 14px;">' + str + '</pre>',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      //cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Finalizar turno',
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const app = this.router.url.substring(6).split('/', 1)[0];
        if (app === 'livechat') {
          this.logoutOmni(false)
        } else {
          try {
            const res: any = await this.scheduleService.getSchedule().toPromise()
            this.scheduleTypes = res.data
          } catch (e) { console.error(e) }
          const element = this.scheduleTypes.filter(item => item.schedule_type.key === 'workday')
          if(!element[0]){
            console.warn(`No hay horario`)
            return
          }
          const theSchedule = element[0]
          const tip = new FormData();
          tip.append('action', 'stop');
          tip.append('schedule_type_id', theSchedule.schedule_type_id);
          tip.append('schedule_id', theSchedule.id);

          this.scheduleService.postSchedule(tip).subscribe((resp) => {
            this.authService.logOut().subscribe(respuesta => {
                if (respuesta != null) {
                  window.location.replace('/login');
                  localStorage.clear();
                  localStorage.setItem('endturn' , 'true'); //guardar en variable de localStorage
                }

            });
          })
        }
      }
    });
  }

  setLocale(lang: any) {
    localStorage.setItem('locale', JSON.stringify(lang));
    this.ngOnInit();
    window.location.reload();
  }
}
