<div class="w-100 h-100 d-flex flex-column p-4">
  <div class="fw-bold fs-6">Resumen de la llamada con el BOT</div>
  <div class="d-flex flex-column my-3" style="gap: 0.5rem;">
    <div class="fw-bold" style="color: #A9A9A9;">Sentimiento del cliente:</div>
    <div>
      <app-svg-feelings *ngIf="dataCallSumary.customerSentiment; else notSentiment"
        [faceType]="dataCallSumary.customerSentiment"></app-svg-feelings>
      <ng-template #notSentiment>
        <div class="fst-italic">No registra</div>
      </ng-template>
    </div>
    <div class="fw-bold" style="color: #A9A9A9;">Palabras clave:</div>
    <div class="fst-italic">{{ dataCallSumary.keywords }}</div>
  </div>
  <div class="d-flex flex-column">
    <p class="fw-bold" style="color: #A9A9A9;">Resumen de la conversación:</p>
    <mat-form-field class="w-100">
      <textarea readonly matInput [matTextareaAutosize]="false" rows="10"
        [value]="dataCallSumary.summaryOfConversation"></textarea>
    </mat-form-field>
  </div>
</div>