import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

const CRM_URL = environment.CRM2_URL;

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  constructor(private http: HttpClient) { }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

  getTemplate(id: any, size: any, page: any, busqueda: any): Observable<any>{
    return this.http.get<any>(`${CRM_URL}template/show/${id}?n=${size}&page=${page}&fetch=${busqueda}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  obtenerTemplate(data: FormData): Observable<any>{
    return this.http.post<any>(`${CRM_URL}template/buildTemplate`, data)
    .pipe(
      catchError(this.handleError)
      );
  }

  buildTemplateHtml(data: FormData): Observable<any>{
    return this.http.post<any>(`${CRM_URL}template/buildTemplateHtml`, data)
    .pipe(
      catchError(this.handleError)
      );
  }
}
