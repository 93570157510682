<div class="row novelty-row-padding">

  <h2 mat-dialog-title>
    <span class="gray-color" style="margin-left: -9px; margin-left: 84px;"></span>
    <button style="float: right;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
    <div class="title content" style="width: 100%; margin-left: 84px;">
      <span>
        <h1 style="color: #353535;">{{data.data?.name}}</h1>
        <h5 style="font-size: 16px; font-family: 'Poppins', Regular!important; color: #A9A9A9;">{{data.data?.id_number}}
        </h5>
      </span>
    </div>
  </h2>

  <mat-dialog-content style="padding: 3%; overflow-x: hidden !important;" class="mat-typography">
    <div class="row">

      <div class="col-6">
        <div class="row">
          <div class="col-12">

            <div class="col-12">

              <div class="col-12">
                <h3 style="color: #353535; margin-left: 84px; font-family: 'Poppins', SemiBold!important;">{{titlePermit}}</h3>
                <p class="p-color"><strong>Fecha inicio: </strong> {{ dataInfo?.novelty_date_start | date:'dd/MM/yyyy'}}</p>
                <p class="p-color"><strong>Fecha fin: </strong> {{dataInfo?.novelty_date_end | date:'dd/MM/yyyy' }}</p>
              </div>
              <br>
            </div>

            <div class="col-12">
              <br>
              <h4 class="gray-color" style="font-size: 18px !important; font-family: 'Poppins', Bold!important;"><b>Justificación
                del trabajador</b></h4>
              <p class="p-color">{{ dataInfo?.justificacionTrabajador }}</p>

              <div class="col-12" style="margin-top: 32px;" *ngIf="showJustifyInfo">
                <h4 class="gray-color" style="font-size: 18px !important; font-family: 'Poppins', Bold!important;"><b>Estado</b>
                </h4>
                <p class="p-color">{{ dataInfo?.stateName }}</p>
              </div>
            </div>

            <!-- payroll response -->
            <div class="col-12">
              <br>
              <h4 class="gray-color" style="font-size: 18px !important; font-family: 'Poppins', Bold!important;"><b>Observaciones</b></h4>
              <p class="p-color">{{ dataInfo?.respuestaJuridico }}</p>
              <p class="p-color"><strong>Fecha de aprobación: </strong> {{dataInfo?.fechaRespuestaJuridico | date:'dd/MM/yyyy' }}</p>
              <p class="p-color"><b>Gestionado por:</b></p>
              <p class="p-color">{{ dataInfo?.aprovationUser }}</p>
            </div>


          </div>
        </div>

      </div>

      <div class="col-6 text-center">
        <img src="./assets/images/suspension.png" class="center" alt="noveltyIcon">
      </div>
    </div>
  </mat-dialog-content>

</div>
