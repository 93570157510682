<div>
  <h2>Eliminar documento</h2>
  <br><br>
  <form (ngSubmit)="onSubmit()"  [formGroup]="deleteForm">

    <mat-form-field class="col-12" appearance="fill">
      <mat-label>Causa *</mat-label>
      <mat-select formControlName="cause">
        <mat-option *ngFor="let item of causes" [value]="item?.id">{{item?.name}}</mat-option>
      </mat-select>
      <mat-error *ngIf="error.cause.errors?.required">Éste campo es requerido</mat-error>
    </mat-form-field>

    <!--    // MAX 200 CARACTERES-->
    <mat-form-field class="col-12" appearance="fill">
      <mat-label>Comentarios </mat-label>
      <textarea type="text" matInput formControlName="comment"></textarea>
      <mat-error *ngIf="error.comment.errors?.maxlength">Máximo 200 carácteres</mat-error>
      <mat-error *ngIf="error.comment.errors?.required">Éste campo es requerido</mat-error>
    </mat-form-field>

    <div style="text-align: right">
      <button type="submit" mat-flat-button color="primary" style="margin-right: 1em;" [disabled]="deleteForm.invalid">Registrar</button>
      <button type="button" mat-stroked-button (click)="onCancel()">Cancelar</button>
    </div>

  </form>
</div>
