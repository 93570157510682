<form (ngSubmit)="onSubmit()">
    <mat-accordion class="example-headers-align" multi>
        <div *ngFor="let section of _sections; index as i; let first = first;">

            <mat-expansion-panel *ngIf="section.see" [expanded]="!section?.collapse">
                <mat-expansion-panel-header>
                    <mat-panel-title matTooltip="{{section.name_section}}">
                        <h3> {{section.name_section}}</h3>
                        <button   mat-button matSuffix mat-icon-button aria-label="Agregar" *ngIf="section.duplicate && (section['duplicated_section'] == undefined || section['duplicated_section'] == false)"
                            type="button" (click)="addSection(section, i)" (click)="$event.stopPropagation();">
                            <span class="fi-rr-add"></span>
                        </button>
                        <button mat-button matSuffix mat-icon-button aria-label="Eliminar"
                            *ngIf="section.duplicated_section" type="button" (click)="removeSection(section, i)"
                            (click)="$event.stopPropagation();">
                            <span class="fi-rr-trash"></span>
                        </button>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-grid-list cols="4" rowHeight="170px" [gutterSize]="'20px'">
                    <div *ngFor="let question of section.fields ; index as y">
                        <mat-grid-tile [colspan]="question.cols" [rowspan]="seeTypeQuestion(question, forms.controls[i]) == 'table_api' ? 3 : 1" *ngIf="seeTypeQuestion(question, forms.controls[i]) !== 'none_question' " [ngSwitch]="seeTypeQuestion(question, forms.controls[i])" [matTooltipPosition]="'above'" [matTooltip]=" question.tooltip.content ">
                            <ng-template ngSwitchCase="question" >
                                <!-- Question Normal Tipos normales de campos de formulario -->
                                <shared-form-questions-v2 style="width: 100%"
                                    (addField)="addQuestion($event, section.name_section)" [question]="question"
                                    [idSection]="section.id" [form]="forms.controls[i]"
                                    (addDependece)="addDependencies($event)" (addFile)="addFiles($event)"
                                    (calculateField)="calculatedFields($event)"
                                    (triggerApi)="triggerApiAction($event)"
                                    [formId]="formId">
                                </shared-form-questions-v2>
                                <!-- Question Normal Tipos normales de campos de formulario -->
                            </ng-template>
                            <ng-template ngSwitchCase="default_field" >
                                <!-- Question de valor por defecto Este campos no tiene gestion alguna, simplemente muestra una informaicon segun su configuracion -->
                                <mat-card class="w-100">
                                    <p> {{ question.defaultCommentValue }} </p>
                                </mat-card>
                                <!-- Question de valor por defecto Este campos no tiene gestion alguna, simplemente muestra una informaicon segun su configuracion -->
                            </ng-template>
                            <ng-template ngSwitchCase="button_api" >
                                <!-- Boton tipo API este boton se encarga de disparar un consumo de API externo enviando los datos segun su configuracion-->
                                <button class="w-100" type="button" (click)="buttonTrigger($event, question)" [matTooltipPosition]="'above'" [matTooltip]=" question.tooltip.have == true ?  question.tooltip.content : '' "   mat-raised-button class="w-100" [color]="question.buttonConfig.color == '' ? 'primary': question.buttonConfig.color " >
                                    {{question.label}} &nbsp; &nbsp; <i *ngIf="question.buttonConfig.icon !== ''" [ngClass]="question.buttonConfig.icon" ></i>
                                </button>
                                <!-- Boton tipo API este boton se encarga de disparar un consumo de API externo enviando los datos segun su configuracion-->
                            </ng-template>
                            <ng-template ngSwitchCase="image_field" >
                                <!-- Este campo se encarga mostrar una imagn acomodada segun configuiracion dentro del formulario -->
                                <img class="img-preview" *ngIf="question.value !== ''" [src]="question.value" />
                                <span *ngIf="question.value == ''" >No se logro cargar la imagen...</span>
                                <!-- Este campo se encarga mostrar una imagn acomodada segun configuiracion dentro del formulario -->
                            </ng-template>
                            <ng-template ngSwitchCase="table_api" >
                                <!-- Tabla cargada por medio de una variable obtenida desde API -->
                                <div class="w-100"> <table class="table">
                                    <thead class="border-header-table">
                                        <th class="hedar-table"  [attr.colspan]="getColumnsTable(question).length" > {{ question.label }} </th>
                                    </thead>
                                    <thead>
                                        <th *ngFor="let name_column of getColumnsTable(question)">{{ name_column }}</th> 
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of getDataToTable(question)" > 
                                            <td *ngFor="let value of getValuesForRowtoTable(data,question)" > {{ value }} </td> 
                                        </tr>
                                    </tbody>
                                </table> </div>
                                    
                                <!-- Tabla cargada por medio de una variable obtenida desde API -->
                            </ng-template>
                        </mat-grid-tile>
                    </div>
                </mat-grid-list>
            </mat-expansion-panel>
        </div>
    </mat-accordion>
    <div fxLayout="row" *ngIf="appOrigen !== 'livechat' && appOrigen !== 'encuestas'" fxLayoutAlign="end center" class="action-btns">
        <button mat-stroked-button *ngIf="plantilla === 'formView' && template" type="button" [disabled]="!forms.valid"
            (click)="generateTemplate()">Generar plantilla</button>
        <button *ngIf="showButtonsActions" mat-stroked-button type="button" (click)="cancelForm()">Cancelar</button>
        <button *ngIf="showButtonsActions" mat-flat-button color="primary" [disabled]="!forms.valid" type="submit">Guardar</button>
    </div>
    <div fxLayout="row" *ngIf="appOrigen == 'encuestas' && showButtonsActions == true" fxLayoutAlign="end center" class="action-btns">
        <button mat-flat-button color="primary" [disabled]="!forms.valid" type="submit">Responder</button>
    </div>
    <div fxLayout="row" *ngIf="appOrigen == 'livechat'" fxLayoutAlign="center center" class="action-btns">
        <button type="submit" class="btn btn-primary text-center btn-finish-conversation" [disabled]="!forms.valid"
            (click)="onSubmit()">Finalizar
            conversación</button>
    </div>

</form>