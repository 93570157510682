<button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<div class="container-dialog">
    <h2>{{titulo}}</h2>
    <mat-divider class="mb-3"></mat-divider>
    <form class="row" [formGroup]="grupoForm">
        <mat-form-field appearance="fill" class="col-6">
            <mat-label>Campaña</mat-label>
            <mat-select formControlName="campania" #selectCampania>
                <mat-option value="ninguna">Ninguna</mat-option>
                <mat-option *ngFor="let campaign of campaigns" [value]="campaign.id">{{campaign.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6">
            <mat-label>Supervisor</mat-label>
            <mat-select formControlName="supervisor_id" (selectionChange)="enableNombre(supervisor)" #supervisor>
                <mat-option [value]="null"></mat-option>
                <mat-option *ngFor="let supervisor of supervisores" [value]="supervisor.id">{{supervisor.nombre | titlecase}}</mat-option>
            </mat-select>
            <mat-error>Por favor seleccione una opción</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-12">
            <mat-label>Nombre del grupo</mat-label>
            <input type="text" matInput cdkFocusInitial formControlName="nombre"
            [value]="nombre_grupo">
            <mat-error>Por favor ingrese un nombre para el grupo</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6">
            <mat-label>Correo jefe de operaciones</mat-label>
            <input type="email" matInput formControlName="correo_jefe_opraciones">
            <mat-error *ngIf="grupoForm.get('correo_jefe_opraciones').hasError('required')">Por favor ingrese un correo electrónico</mat-error>
            <mat-error *ngIf="grupoForm.get('correo_jefe_opraciones').hasError('email')">Por favor ingrese un correo valido</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6">
            <mat-label>Correo supervisor</mat-label>
            <input type="email" matInput formControlName="correo_supervisor">
            <mat-error *ngIf="grupoForm.get('correo_supervisor').hasError('required')">Por favor ingrese un correo electrónico</mat-error>
            <mat-error *ngIf="grupoForm.get('correo_supervisor').hasError('email')">Por favor ingrese un correo valido</mat-error>
        </mat-form-field>
        <div class="col-12 mt-3" style="text-align: center;">
            <button mat-stroked-button class="btn-dialog me-4" (click)="closeDialog()">Cancelar</button>
            <button mat-raised-button color="primary" style="width: 192px; height: 48px;" (click)="createGroup()" [disabled]="grupoForm.invalid">Guardar</button>
        </div>
    </form>
</div>