import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DetalleRetroalimentacionComponent } from '../detalle-retroalimentacion/detalle-retroalimentacion.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/rest/auth.service';

@Component({
  selector: 'app-tabla-retroalimentar',
  templateUrl: './tabla-retroalimentar.component.html',
  styleUrls: ['./tabla-retroalimentar.component.sass']
})
export class TablaRetroalimentarComponent implements OnInit {

  @Input() dataSource = [];
  displayedColumns:string[] = ['acciones','id','fecha_auditoria','campaña','nombre_auditado','retroalimentada'];
  roles = [];


  is_supervisor:boolean = false;

  @Input()length;
  @Input()pageSize = 5;
  @Input() page = 1;
  pageSizeOptions = [5,10,25,50,100];
  @Output() paginacion = new EventEmitter();
  campania:string = '';
  constructor(
    private dialog:MatDialog,
    private authService:AuthService,
    private router: Router,
  ) { 
    
  }

  ngOnInit(): void {

    this.roles = this.authService.getUser().roles;
    //console.log(this.roles);
    this.roles.forEach(rol => {
     
      if (rol == 'auditoria::supervisor') {
        this.is_supervisor  = true;
      }
      

    });


  }

   detailRetroAlimentacion(id){


     //this.router.navigateByUrl(`/mios/auditoria/detail-retro/${id}`);
     let uri = `/mios/auditoria/detail-retro/${id}`;

     this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
     this.router.navigate([uri]));


   /* const retroalimentacionRef = this.dialog.open(DetalleRetroalimentacionComponent,{
      width:'1056px',
      disableClose:true,
      data: id,
    });
    retroalimentacionRef.afterClosed().subscribe(()=>{
      
    });*/
  }

  pageEvent(event:any){
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.paginacion.emit({
      pagina: this.page,
      registros: this.pageSize,
    });
  }

  get campaign(){
    let campaignMatriz;
    this.dataSource.forEach(element => {
      campaignMatriz = element.campaign;
    });
    if (campaignMatriz.length == 0) {
      return false
    }
    this.campania = campaignMatriz.name;
    return true;
  }

}
