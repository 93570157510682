<div class="content">
  <div class="col-12">
    <button mat-icon-button (click)="back()" btn-regresar class="mat-icon notranslate material-icons mat-icon-no-color">
      <div class="display:flex">
        <span class="dot">
          <i class="fi-rr-angle-small-left" style="font-size:24px;color: white;"></i>
        </span>
        <span
          style="vertical-align: super;font: normal normal 600 16px/25px Poppins;letter-spacing: 0px;color: #353535;opacity: 1;">Regresar</span>
      </div>
    </button>
  </div>
  <div class="col-12" style="margin-top: 64px;">
    <h1 style="font-weight: 600; font-size: 38px; line-height: 24px;"> {{clientInfo?.nameClient || ''}} </h1>
  </div>

  <div class="row col-12" style="margin-top: 60px;">
    <div class="col-9" style="color: #353535;">
      <p *ngIf="clientInfo?.idCountry == '47'"><b>NIT.</b> {{clientInfo?.id_number || ''}}</p>
      <p *ngIf="clientInfo?.idCountry != '47'"><b>EIN.</b> {{clientInfo?.id_number || ''}}</p>
      <p><b>Teléfono:</b> {{clientInfo?.phone_number || ''}}</p>
      <p><b>Dirección: </b> {{clientInfo?.address || ''}}</p>
      <p *ngIf="clientInfo?.website"><b>Página web:</b> {{clientInfo?.website}}</p>
    </div>
    <div class="col-3" style="position: relative;">
      <button type="button" mat-raised-button color="primary"
        style="height:48px; width:220px;margin-right: 10px; position: absolute;bottom: 0;right: 0;font-weight: 600;"
        routerLink="/mios/crm-cial/creop/{{this.idcliente}}">
        Crear Oportunidad
        <i class="fi-rr-add"></i>
      </button>
    </div>
  </div>

  <div class="col-8" style="margin-top: 30px;word-break: break-all;">
    <div style="display: flex;">
      <p style="font-size: 20px !important; color: #353535;"> <b>Observación</b> </p>
      <button align="center" style="margin-top: -10px;" mat-button (click)="EditObservation()" *ngIf="(rolUser == 'administrador') || (clientInfo?.responsible_id == user)"><i class="fi-rr-edit"></i></button>
    </div>
    <p style="font-size: 14px !important; color:#A9A9A9; font-weight: 400;" *ngIf="clientInfo?.observations && observationField == false"> {{clientInfo?.observations}} </p>
    <mat-form-field class="col-6" appearance="fill" *ngIf="observationField == true">
      <mat-label>Observaciones</mat-label>
      <textarea matInput style="height: 21px !important;" [formControl]="observationControl" (keydown.enter)="updateObservation()" maxlength="255"
      [value]="(clientInfo?.observations ? clientInfo?.observations : '')"></textarea>
      <mat-error *ngIf="observationControl.hasError('maxlength')"><span
        *ngIf="error.observationControl.errors.maxlength">¡Advertencia! Solo se permite maximo 255 caracteres</span>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="col-12" style="margin-top: 60px;">
    <mat-tab-group class="remove-border-bottom" [selectedIndex]="selectedIndex">
      <mat-tab label="Nuevas Oportunidades">
        <div class="row col-lg-12" style="margin-top: 48px;">

          <mat-form-field class="col-3" appearance="fill">
            <mat-label>Nombre de la oportunidad</mat-label>
            <input matInput #input [formControl]="nameControl">
            <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
          </mat-form-field>

          <mat-form-field class="col-3" appearance="fill">
            <mat-label>Comercial asignado</mat-label>
            <mat-select [formControl]="comercialControl">
              <mat-option value="">Todos</mat-option>
              <mat-option *ngFor="let item of arraycomercial" [value]="item?.rrhh_id">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-2" appearance="fill">
            <mat-label>Estado</mat-label>
            <mat-select [formControl]="stateControl">
              <mat-option value="">Todos</mat-option>
              <mat-option *ngFor="let item of stateData" [value]="item?.id">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-2" appearance="fill">
            <mat-label>Linea de servicio</mat-label>
            <mat-select [formControl]="lineServiceControl">
              <mat-option value="">Todos</mat-option>
              <mat-option *ngFor="let item of arrayservicios" [value]="item?.name">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-2" appearance="fill">
            <mat-label>Empresa asignada</mat-label>
            <mat-select [formControl]="companyControl">
              <mat-option value="">Todos</mat-option>
              <mat-option *ngFor="let item of arraycompany" [value]="item?.id">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="table-container row col-12" style="margin-bottom: 48px;">

          <div class="col-3" *ngFor="let item of obs | async" style="margin-top: 48px;">
            <mat-card class="clientes-card mat-elevation-z8" style="border-radius: 20px;margin-left: 6px;">
              <mat-card-header class="row">
                <div class="badge ">
                  <button mat-icon-button *ngIf="item?.step_id == 1"
                    style="background:#EB996E;position: absolute;top: -27px;left: 8%;height: 55px;width: 55px;">
                    <i style="font-size: 24px !important;" class="fi-rr-envelope"> </i>
                  </button>
                  <button mat-icon-button *ngIf="item?.step_id == 2"
                    style="background:#DF85B6;position: absolute;top: -27px;left: 8%;height: 55px;width: 55px;">
                    <i style="font-size: 24px !important;" class="fi-rr-users"> </i>
                  </button>
                  <button mat-icon-button *ngIf="item?.step_id == 3"
                    style="background:#51B0CB;position: absolute;top: -27px;left: 8%;height: 55px;width: 55px;">
                    <i style="font-size: 24px !important;" class="fi-rr-list-check"> </i>
                  </button>
                  <button mat-icon-button *ngIf="item?.step_id == 4"
                    style="background:#6AE5C8;position: absolute;top: -27px;left: 8%;height: 55px;width: 55px;">
                    <i style="font-size: 24px !important;" class="fi-rr-paper-plane"> </i>
                  </button>
                  <button mat-icon-button *ngIf="item?.step_id == 5"
                    style="background:#F2B50D;position: absolute;top: -27px;left: 8%;height: 55px;width: 55px;">
                    <i style="font-size: 24px !important;" class="fi-rr-briefcase"> </i>
                  </button>
                  <button mat-icon-button *ngIf="item?.step_id == 6"
                    style="background:#60BF66;position: absolute;top: -27px;left: 8%;height: 55px;width: 55px;">
                    <i style="font-size: 24px !important;" class="fi-rr-hourglass"> </i>
                  </button>
                </div>
                <div class="col-12">
                  <mat-card-subtitle subtitlecard align="center" style="font-size: 14px !important; margin-top: 20px; color: #717171;">
                    {{item?.step}} </mat-card-subtitle>
                </div>
                <div class="col-12" style="text-align: center;text-overflow: ellipsis;overflow:hidden;width: 92%;">
                  <p lblcliente style="font-weight: 700;color:#353535;" [matTooltip]="item?.name_oportunity" [matTooltipPosition]="'above'">
                    {{item?.name_oportunity}}
                  </p>
                </div>
                <div class="col-12" style="text-align: center;">
                  <p style="font-size: 12px !important;color:#717171">líneas de servicio: {{item?.lineas}} </p>
                </div>
              </mat-card-header>
              <mat-divider></mat-divider>
              <mat-card-content>
                <div align="left" class="subtitlecard2" style="margin-top: 16px;color:#717171;align-items: left; font-size: 11px !important;">
                  <label style="font-weight:700;">Facturacion Mensual :  </label> &nbsp;{{item?.invoicing ? '$' : ''}}{{item?.invoicing}}
                  <br>
                  <label style="font-weight:700;">Tiempo de contrato : </label> &nbsp;{{item?.tiempo_contrato}} {{ item?.tiempo_contrato ? (item?.tiempo_contrato > 1 ? ' meses' : ' mes') : ''}}
                  <br>
                  <label style="font-weight:700;">Valor de la oportunidad : </label> &nbsp;{{item?.val_oportunidad ? '$' : ''}}{{item?.val_oportunidad}}
                  <br>
                  <label style="font-weight:700;">Comercial asignado : </label> &nbsp;{{item?.comercial}}
                  <br>
                  <label style="font-weight:700;">Empresa Asociada : </label> &nbsp;{{item?.empresa}}
                </div>
              </mat-card-content>
              <mat-divider></mat-divider>
              <mat-card-actions>
                <div class="row" style="font-size: 11px;color:#717171;display: flex;">
                  <div class="col-12 col-lg-4">
                    <button mat-button (click)="manageOpportunities(item, 0)"><i class="fi-rr-settings"></i> Gestionar</button>
                  </div>
                  <div class="col-12 col-lg-3">
                    <button mat-button (click)="HistoricoNotas(item.id)"><i class="fi-rr-document"></i>Notas</button>
                  </div>
                  <div class="col-12 col-lg-3">
                    <button mat-button (click)="saveDesierta(item, 0)"><i class="fi-rr-hourglass-end"></i>Desierta</button>
                  </div>
                </div>
              </mat-card-actions>
            </mat-card>
          </div>
          <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)"
            [pageSizeOptions]="pageSizeOptions"></mat-paginator>
        </div>

      </mat-tab>
      <!-- Tab Ganadas -->
      <mat-tab label="Oportunidades ganadas">
        <div class="row col-lg-12" style="margin-top: 48px;">

          <mat-form-field class="col-3" appearance="fill">
            <mat-label>Nombre de la oportunidad</mat-label>
            <input matInput #input2 [formControl]="nameControl2">
            <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
          </mat-form-field>

          <mat-form-field class="col-3" appearance="fill">
            <mat-label>Linea de servicio</mat-label>
            <mat-select [formControl]="lineServiceControl2">
              <mat-option value="">Todos</mat-option>
              <mat-option *ngFor="let item of arrayservicios" [value]="item?.name">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-3" appearance="fill">
            <mat-label>Comercial asignado</mat-label>
            <mat-select [formControl]="comercialControl2">
              <mat-option value="">Todos</mat-option>
              <mat-option *ngFor="let item of arraycomercial" [value]="item?.rrhh_id">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div class="table-container row col-12" style="margin-bottom: 48px;">

          <div class="col-3" *ngFor="let item of obs2 | async" style="margin-top: 48px;">
            <mat-card class="clientes-card mat-elevation-z8" style="border-radius: 20px;margin-left: 6px;">
              <mat-card-header class="row">
                <div class="badge ">
                  <button mat-icon-button
                    style="background:#60BF66;position: absolute;top: -27px;left: 8%;height: 55px;width: 55px;">
                    <i style="font-size: 24px !important;" class="fi-rr-check"> </i>
                  </button>
                </div>
                <div class="col-12">
                  <mat-card-subtitle subtitlecard align="center" style="font-size: 14px !important; margin-top: 20px;color:#717171;">
                    {{item?.state}} </mat-card-subtitle>
                </div>
                <div class="col-12" style="text-align: center;text-overflow: ellipsis;overflow:hidden;width: 92%;">
                  <p lblcliente style="font-weight: 700;color:#353535;" [matTooltip]="item?.name_oportunity" [matTooltipPosition]="'above'">
                    {{item?.name_oportunity}}
                  </p>
                </div>
                <div class="col-12" style="text-align: center;">
                  <p style="font-size: 12px !important;color:#717171;">líneas de servicio: {{item?.lineas}} </p>
                </div>
              </mat-card-header>
              <mat-divider></mat-divider>
              <mat-card-content>
                <div align="left" class="subtitlecard2" style="margin-top: 16px;color:#717171;align-items: left; font-size: 11px !important;">
                  <label style="font-weight:700;">Facturacion Mensual :  </label> &nbsp;{{item?.invoicing ? '$' : ''}}{{item?.invoicing}}
                  <br>
                  <label style="font-weight:700;">Tiempo de contrato : </label> &nbsp;{{item?.tiempo_contrato}} {{ item?.tiempo_contrato ? (item?.tiempo_contrato > 1 ? ' meses' : ' mes') : ''}}
                  <br>
                  <label style="font-weight:700;">Valor de la oportunidad : </label> &nbsp;{{item?.val_oportunidad ? '$' : ''}}{{item?.val_oportunidad}}
                  <br>
                  <label style="font-weight:700;">Comercial asignado : </label> &nbsp;{{item?.comercial}}
                  <br>
                  <label style="font-weight:700;">Empresa Asociada : </label> &nbsp;{{item?.empresa}}
                </div>
              </mat-card-content>
              <mat-divider></mat-divider>
              <mat-card-actions>
                <div class="row" style="font-size: 11px;color:#717171;display:flex;">
                  <div class="col-12 col-lg-4" *ngIf="rolUser !== 'administrador'">
                    <button mat-button (click)="viewOpportunities(item, 1)"><i class="fi-rr-eye"></i> Ver</button>
                  </div>
                  <div class="col-12 col-lg-4">
                    <button mat-button (click)="HistoricoNotas(item.id)"><i class="fi-rr-document"></i>Notas</button>
                  </div>
                  <div class="col-12 col-lg-4" *ngIf="rolUser == 'administrador'">
                    <button mat-button (click)="manageOpportunities(item, 1)"><i class="fi-rr-settings"></i> Gestionar</button>
                  </div>
                </div>
              </mat-card-actions>
            </mat-card>
          </div>
          <mat-paginator [length]="length2" [pageSize]="pageSize2" (page)="pageEvent2($event)"
            [pageSizeOptions]="pageSizeOptions2"></mat-paginator>
        </div>

      </mat-tab>
      <!-- Tab perdidas -->
      <mat-tab label="Oportunidades perdidas">
        <div class="row col-lg-12" style="margin-top: 48px;">

          <mat-form-field class="col-3" appearance="fill">
            <mat-label>Nombre de la oportunidad</mat-label>
            <input matInput #input3 [formControl]="nameControl3">
            <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
          </mat-form-field>

          <mat-form-field class="col-3" appearance="fill">
            <mat-label>Linea de servicio</mat-label>
            <mat-select [formControl]="lineServiceControl3">
              <mat-option value="">Todos</mat-option>
              <mat-option *ngFor="let item of arrayservicios" [value]="item?.name">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-3" appearance="fill">
            <mat-label>Comercial asignado</mat-label>
            <mat-select [formControl]="comercialControl3">
              <mat-option value="">Todos</mat-option>
              <mat-option *ngFor="let item of arraycomercial" [value]="item?.rrhh_id">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div class="table-container row col-12" style="margin-bottom: 48px;">

          <div class="col-3" *ngFor="let item of obs3 | async" style="margin-top: 48px;">
            <mat-card class="clientes-card mat-elevation-z8" style="border-radius: 20px;margin-left: 6px;">
              <mat-card-header class="row">
                <div class="badge ">
                  <button mat-icon-button
                    style="background:#D14A4A;position: absolute;top: -27px;left: 8%;height: 55px;width: 55px;">
                    <i style="font-size: 24px !important;" class="fi-rr-cross"> </i>
                  </button>
                </div>
                <div class="col-12">
                  <mat-card-subtitle subtitlecard align="center" style="font-size: 14px !important; margin-top: 20px;color:#717171;">
                    {{item?.state}} </mat-card-subtitle>
                </div>
                <div class="col-12" style="text-align: center;text-overflow: ellipsis;overflow:hidden;width: 92%;">
                  <p lblcliente style="font-weight: 700;color:#353535;" [matTooltip]="item?.name_oportunity" [matTooltipPosition]="'above'">
                    {{item?.name_oportunity}}
                  </p>
                </div>
                <div class="col-12" style="text-align: center;">
                  <p style="font-size: 12px !important;color:#717171;">líneas de servicio: {{item?.lineas}} </p>
                </div>
              </mat-card-header>
              <mat-divider></mat-divider>
              <mat-card-content>
                <div align="left" class="subtitlecard2" style="margin-top: 16px;color:#717171;align-items: left; font-size: 11px !important;">
                  <label style="font-weight:700;">Facturacion Mensual :  </label> &nbsp;{{item?.invoicing ? '$' : ''}}{{item?.invoicing}}
                  <br>
                  <label style="font-weight:700;">Tiempo de contrato : </label> &nbsp;{{item?.tiempo_contrato}} {{ item?.tiempo_contrato ? (item?.tiempo_contrato > 1 ? ' meses' : ' mes') : ''}}
                  <br>
                  <label style="font-weight:700;">Valor de la oportunidad : </label> &nbsp;{{item?.val_oportunidad ? '$' : ''}}{{item?.val_oportunidad}}
                  <br>
                  <label style="font-weight:700;">Comercial asignado : </label> &nbsp;{{item?.comercial}}
                  <br>
                  <label style="font-weight:700;">Empresa Asociada : </label> &nbsp;{{item?.empresa}}
                </div>
              </mat-card-content>
              <mat-divider></mat-divider>
              <mat-card-actions>
                <div class="row" style="font-size: 11px;color:#717171;display:flex;">
                  <div class="col-12 col-lg-4" *ngIf="rolUser !== 'administrador'">
                    <button mat-button  (click)="viewOpportunities(item,2)"><i class="fi-rr-eye"></i> Ver</button>
                  </div>
                  <div class="col-12 col-lg-4">
                    <button mat-button (click)="HistoricoNotas(item.id)"><i class="fi-rr-document"></i>Notas</button>
                  </div>
                  <div class="col-12 col-lg-4" *ngIf="rolUser == 'administrador'">
                    <button mat-button (click)="manageOpportunities(item, 2)" ><i class="fi-rr-settings"></i> Gestionar</button>
                  </div>
                </div>
              </mat-card-actions>
            </mat-card>
          </div>
          <mat-paginator [length]="length3" [pageSize]="pageSize3" (page)="pageEvent3($event)"
            [pageSizeOptions]="pageSizeOptions3"></mat-paginator>
        </div>

      </mat-tab>
      <!-- Tab desiertas -->
      <mat-tab label="Oportunidades desiertas">
        <div class="row col-lg-12" style="margin-top: 48px;">

          <mat-form-field class="col-3" appearance="fill">
            <mat-label>Nombre de la oportunidad</mat-label>
            <input matInput #input4 [formControl]="nameControl4">
            <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
          </mat-form-field>

          <mat-form-field class="col-3" appearance="fill">
            <mat-label>Linea de servicio</mat-label>
            <mat-select [formControl]="lineServiceControl4">
              <mat-option value="">Todos</mat-option>
              <mat-option *ngFor="let item of arrayservicios" [value]="item?.name">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-3" appearance="fill">
            <mat-label>Comercial asignado</mat-label>
            <mat-select [formControl]="comercialControl4">
              <mat-option value="">Todos</mat-option>
              <mat-option *ngFor="let item of arraycomercial" [value]="item?.rrhh_id">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div class="table-container row col-12" style="margin-bottom: 48px;">

          <div class="col-3" *ngFor="let item of obs4 | async" style="margin-top: 48px;">
            <mat-card class="clientes-card mat-elevation-z8" style="border-radius: 20px;margin-left: 6px;">
              <mat-card-header class="row">
                <div class="badge ">
                  <button mat-icon-button
                    style="background:#D9A847;position: absolute;top: -27px;left: 8%;height: 55px;width: 55px;">
                    <i style="font-size: 24px !important;" class="fi-rr-hourglass-end"> </i>
                  </button>
                </div>
                <div class="col-12">
                  <mat-card-subtitle subtitlecard align="center" style="font-size: 14px !important; margin-top: 20px;color: #717171;">
                    {{item?.state}} </mat-card-subtitle>
                </div>
                <div class="col-12" style="text-align: center;text-overflow: ellipsis;overflow:hidden;width: 92%;">
                  <p lblcliente style="font-weight: 700;color:#353535;" [matTooltip]="item?.name_oportunity" [matTooltipPosition]="'above'">
                    {{item?.name_oportunity}}
                  </p>
                </div>
                <div class="col-12" style="text-align: center;">
                  <p style="font-size: 12px !important;color:#717171;">líneas de servicio: {{item?.lineas}} </p>
                </div>
              </mat-card-header>
              <mat-divider></mat-divider>
              <mat-card-content>
                <div align="left" class="subtitlecard2" style="margin-top: 16px;color:#717171;align-items: left; font-size: 11px !important;">
                  <label style="font-weight:700;">Facturacion Mensual :  </label> &nbsp;{{item?.invoicing ? '$' : ''}}{{item?.invoicing}}
                  <br>
                  <label style="font-weight:700;">Tiempo de contrato : </label> &nbsp;{{item?.tiempo_contrato}} {{ item?.tiempo_contrato ? (item?.tiempo_contrato > 1 ? ' meses' : ' mes') : ''}}
                  <br>
                  <label style="font-weight:700;">Valor de la oportunidad : </label> &nbsp;{{item?.val_oportunidad ? '$' : ''}}{{item?.val_oportunidad}}
                  <br>
                  <label style="font-weight:700;">Comercial asignado : </label> &nbsp;{{item?.comercial}}
                  <br>
                  <label style="font-weight:700;">Empresa Asociada : </label> &nbsp;{{item?.empresa}}
                </div>
              </mat-card-content>
              <mat-divider></mat-divider>
              <mat-card-actions>
                <div class="action-icons" style="font-size: 11px;color:#717171;display:flex;">
                  <div class="col-12 col-lg-4" *ngIf="rolUser !== 'administrador'">
                    <button mat-button  (click)="viewOpportunities(item, 3)"><i class="fi-rr-eye"></i> Ver</button>
                  </div>
                  <div class="col-12 col-lg-4">
                    <button mat-button (click)="HistoricoNotas(item.id)"><i class="fi-rr-document"></i>Notas</button>
                  </div>
                  <div class="col-12 col-lg-4" *ngIf="rolUser == 'administrador'">
                    <button mat-button (click)="manageOpportunities(item, 3)" ><i class="fi-rr-settings"></i> Gestionar</button>
                  </div>
                </div>
              </mat-card-actions>
            </mat-card>
          </div>
          <mat-paginator [length]="length4" [pageSize]="pageSize4" (page)="pageEvent4($event)"
            [pageSizeOptions]="pageSizeOptions4"></mat-paginator>
        </div>

      </mat-tab>
    </mat-tab-group>
  </div>

</div>
