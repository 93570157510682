import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../core/services/rest/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private server =  environment.RRHH_URL;
  user;
  constructor(private http:HttpClient, private activeRoute: ActivatedRoute, private authService:AuthService) {
    this.user = this.authService.getUser();
   }

  getUserByRole(role: string){
    const entries = {};
    entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${this.server}users/role/${role}`, {params});
  }

  getSites() {
    return this.http.get(`${this.server}sites`, this.httpOptions);
  }

  getCVUserLoggued(){
    return this.http.get(`${this.server}user/cv/${this.user.rrhh_id}`)
  }
  getCertifications(value){
    return this.http.get(`${this.server}user/certifications/${this.user.rrhh_id}/${value}`)
  }
  getCertificate(certificate,value, rrhh_id?){
    if(rrhh_id) return this.http.post(`${this.server}user/generate/certificate/${rrhh_id}/${certificate}`,value,{responseType:'blob'})
    return this.http.post(`${this.server}user/generate/certificate/${this.user.rrhh_id}/${certificate}`,value,{responseType:'blob'})
  }

  /**
   * servicio que retorna las columnas necesarias de acuerdo al tipo de certificado
   * @param value = tipo de certificado
   * @param rrhh_id = id del candidato a buscar
   * @returns Tabla de certificados
   */
  getCertificationsTwo(value,rrhh_id){
    return this.http.get(`${this.server}user/certifications/${rrhh_id}/${value}?flag=juridico`)
  }
  /**
   * Servicio que crea el documento solicitado.
   * @param certificate = tipo de certificado
   * @param rrhh_id = id del candidato a buscar
   * @param value = ver o descargar documento
   * @returns  documento solicitado
   */
  getCertificateTwo(certificate,rrhh_id,value){
    return this.http.post(`${this.server}user/generate/certificate/${rrhh_id}/${certificate}?flag=juridico`,value,{responseType:'blob'})
  }

  /**
   * Servicio que retorna todos los hired user de un candidato
   * @autor David Reinoso | 20-feb-23 
   * @param candidate_id 
   */
  getHiredUsersByCandidate(candidate_id){
    return this.http.get(`${this.server}candidate/getHiredUsersByCandidate/${candidate_id}`);
  }

}
