<h2 mat-dialog-title>{{data.title}}
    <button style="float: right;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
</h2>

<form class="row form" [formGroup]="rolAsunto" (ngSubmit)="saveAsunto(rolAsunto)">

<mat-dialog-content style="padding: 3%;" class="mat-typography">

  <mat-form-field style="padding: 1%;" class="col-6" appearance="fill">
    <mat-label>Nombre</mat-label>
    <input style="text-transform: capitalize;" matInput type="text" placeholder="Escribe el nombre del asunto" formControlName="nombre" required cdkFocusInitial>
    <mat-error *ngIf="error.nombre.errors"><span *ngIf="error.nombre.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
  </mat-form-field>

  <mat-form-field style="padding: 1%;" class="col-6" appearance="fill">
    <mat-label>Área</mat-label>
    <mat-select formControlName="area" (selectionChange)="jefeByArea($event.value)">
      <mat-option *ngFor="let area of areas" [value]="area.id">{{area.name}}</mat-option>
    </mat-select>
    <mat-error *ngIf="error.area.errors"><span *ngIf="error.area.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
  </mat-form-field>

  <mat-form-field style="padding: 1%;" class="col-6" appearance="fill">
    <mat-label>Jefe Directo</mat-label>
    <mat-select formControlName="jefeDirecto">
      <mat-option *ngFor="let jefe of jefes" [value]="jefe.id">{{jefe.name}}</mat-option>
    </mat-select>
    <mat-error *ngIf="error.jefeDirecto.errors"><span *ngIf="error.jefeDirecto.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
  </mat-form-field>

  <mat-form-field style="padding: 1%;" class="col-6" appearance="fill">
    <mat-label>Jefe Inmediato</mat-label>
    <mat-select formControlName="jefeInmediato">
      <mat-option *ngFor="let jefe of jefes" [value]="jefe.id">{{jefe.name}}</mat-option>
    </mat-select>
    <mat-error *ngIf="error.jefeInmediato.errors"><span *ngIf="error.jefeInmediato.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
  </mat-form-field>

  <mat-form-field style="padding: 1%;" class="col-6" appearance="fill">
    <mat-label>Hora Jefe Directo</mat-label>
    <input matInput type="number" formControlName="horaJefeDirecto" required>
    <mat-error *ngIf="error.horaJefeDirecto.errors"><span *ngIf="error.horaJefeDirecto.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
  </mat-form-field>

  <mat-form-field style="padding: 1%;" class="col-6" appearance="fill">
    <mat-label>Hora Jefe inmediato</mat-label>
    <input matInput type="number" formControlName="horaJefeInmediato" required>
    <mat-error *ngIf="error.horaJefeInmediato.errors"><span *ngIf="error.horaJefeInmediato.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
  </mat-form-field>

  <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
    <mat-label>Prioridad Alta</mat-label>
    <input matInput type="number" formControlName="prioridadAlta" required>
    <mat-error *ngIf="error.prioridadAlta.errors"><span *ngIf="error.prioridadAlta.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
  </mat-form-field>

  <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
    <mat-label>Prioridad Media</mat-label>
    <input matInput type="number" formControlName="prioridadMedia" required>
    <mat-error *ngIf="error.prioridadMedia.errors"><span *ngIf="error.prioridadMedia.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
  </mat-form-field>

  <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
    <mat-label>Prioridad Baja</mat-label>
    <input matInput type="number" formControlName="prioridadBaja" required>
    <mat-error *ngIf="error.prioridadBaja.errors"><span *ngIf="error.prioridadBaja.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
  </mat-form-field>

  <mat-form-field style="padding: 1%;" class="col-12" appearance="fill">
    <mat-label>Descripción</mat-label>
    <textarea matInput formControlName="descripcion" required></textarea>
    <mat-error *ngIf="error.descripcion.errors"><span *ngIf="error.descripcion.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
  </mat-form-field>
  
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button mat-raised-button type="submit" color="primary" disabled='{{rolAsunto.invalid}}' [mat-dialog-close]="true">Guardar</button>
</mat-dialog-actions>

</form>
