<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>

<h3>Crear grupo</h3>
<mat-divider></mat-divider>
<mat-dialog-content>
    <form [formGroup]="trainerGroupForm">
        <div class="form">
            <div class="row">
                <div class="col">
                    <mat-form-field appearance="fill">
                        <mat-label>Nombre</mat-label>
                        <input matInput formControlName="name">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="fill">
                        <mat-label>Modalidad</mat-label>
                        <mat-select formControlName="modality">
                            <mat-option [value]="1">Presencial</mat-option>
                            <mat-option [value]="2">Remota</mat-option>
                            <mat-option [value]="2">Mixta</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <mat-form-field appearance="fill">
                        <mat-label>Capacidad</mat-label>
                        <input matInput formControlName="capacity" type="number">

                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="fill">
                        <mat-label>Rescate</mat-label>
                        <mat-select formControlName="mode_rescue">
                            <mat-option [value]="1">Si</mat-option>
                            <mat-option [value]="0">No</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <mat-form-field appearance="fill">
                        <mat-label>Fecha Inicio</mat-label>
                        <input matInput [matDatepicker]="picker1" formControlName="start">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="fill">
                        <mat-label>Fecha Fin</mat-label>
                        <input matInput [matDatepicker]="picker2" [min]="trainerGroupForm.value.start" formControlName="end">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <mat-form-field appearance="fill">
                        <mat-label>Ubicación</mat-label>
                        <mat-select formControlName="ubication">
                            <mat-option *ngFor="let site of sites" [value]="site.id">{{site.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <!-- <mat-form-field appearance="fill">
                        <mat-label>Formador</mat-label>
                        <mat-select formControlName="user_trainer_id">
                            <mat-option *ngFor="let trainer of trainers" [value]="trainer.id">{{trainer.name}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <mat-form-field appearance="fill" fxFlex="calc(50% - 20px)">
                        <mat-label>Formador</mat-label>
                        <input matInput [(ngModel)]="user_trainer_name" [ngModelOptions]="{standalone: true}" disabled>
                        <button mat-icon-button matSuffix type="button" matTooltip="Buscar Responsable" (click)="searchUsers('formador')">
                          <i class="fi-rr-search" ></i>
                        </button>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field appearance="fill">
                        <mat-label>Caso GLPI</mat-label>
                        <input matInput formControlName="case_glpi">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="fill">
                        <mat-label>Tipo de grupo</mat-label>
                        <mat-select formControlName="group_type_id">
                            <mat-option *ngFor="let group of  groupType" [value]="group.id">
                                {{group.name}}
                            </mat-option>
                        </mat-select>

                    </mat-form-field>
                </div>
            </div>

            <div class="col">
                <mat-form-field appearance="fill">
                    <mat-label>Segmento </mat-label>
                    <input matInput formControlName="segment">

                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field appearance="fill">
                    <mat-label>Descripción</mat-label>
                    <textarea matInput placeholder="Descripción del grupo" formControlName="description"></textarea>
                </mat-form-field>
            </div>

        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button mat-raised-button color="primary" [disabled]="trainerGroupForm.invalid" (click)="groupRegister()">Crear</button>
</mat-dialog-actions>