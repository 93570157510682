import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { CoachingService } from '../../services/coaching.service';
 
@Injectable()
export class SolicitudEffects {
 
  loadAuditorias$ = createEffect(() => this.actions$.pipe(
    ofType('[Solicitud] Load solicitud'),
    mergeMap((data:any) => this.coachingService.getSolicitudById(data.idSolicitud)
      .pipe(
        map(solicitud => ({ type: '[Solicitud] Loaded solicitud', solicitud }))
      ))
    )
  );
 
  constructor(
    private actions$: Actions,
    private coachingService:CoachingService,
  ) {}
}