import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { SolicitudesService } from 'src/app/modules/compras/services/rest/solicitudes.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { Solicitud } from '../../../Class/solicitud';
import { AprobarSolcitudComponent } from '../aprobar-solcitud/aprobar-solcitud.component';
import { ConsultarProveedorComponent } from '../consultar-proveedor/consultar-proveedor.component';
import { CrearSolicitudComponent } from '../crear-solicitud/crear-solicitud.component';
import { DescargaComponent } from '../historial-aprobador/descarga/descarga.component';
import { ProcesarSolicitudControlComponent } from '../procesar-solicitud-control/procesar-solicitud-control.component';
import { ProcesarSolicitudComponent } from '../procesar-solicitud/procesar-solicitud.component';

@Component({
  selector: 'app-gestion-inicial-control',
  templateUrl: './gestion-inicial-control.component.html',
  styleUrls: ['./gestion-inicial-control.component.sass']
})
export class GestionInicialControlComponent implements OnInit {

  user: any;
  length = 0;
  rol = 4;
  pageSize = 8;
  page = 1;
  isPage = 1;
  pageSizeOptions: number[] = [4, 8, 24, 100];
  newRolComponent: boolean = false;
  requestStates: any = [];
  filterDate: string = null;
  filterStates: any[] = null;
  public solicitudes: [] = [];
  defaultStates = [1,10];

  constructor(
    private solicitudService: SolicitudesService,
    private destroyService: DestroyComponentService,
    private alertsService: AlertsService,
    private authService: AuthService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUser().rrhh_id;
    this.solicitudService.getRequestStates().subscribe(resp => {
      //this.requestStates = resp.data;
      this.requestStates= resp.data.filter(state =>{
        let flag = false;
        this.defaultStates.map(s =>{
            if(s === state.id) flag = true;
        })
        return flag;
    });
    });
    this.filterStates = this.defaultStates;

    this.getSolicitudes();
  }

  getSolicitudes() {
    const params = {
      isPage: this.isPage,
      size: this.pageSize,
      page: this.page,
      states: this.filterStates,
      fecha: this.filterDate,
      rol: this.rol
    }
    this.solicitudService.getSoliciudesPaginate(params)
      .subscribe(resp => {
        this.solicitudes = resp.data.sort((a, b) => {
          if (Number(a.id) > Number(b.id)) {
            return -1;
          }
          if (Number(a.id) < Number(b.id)) {
            return 1;
          }
          return 0;
        });
        this.length = resp.total;
        this.pageSize = resp.per_page;
      });

  }


  newSolicitud() {
    const dialogNewRol = this.dialog.open(CrearSolicitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Crear Solicitud',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      //this.pageSize=5;
      this.ngOnInit();
    });
  }

  viewSolicitud(solicitud: Solicitud) {

    const dialogNewRol = this.dialog.open(AprobarSolcitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'view',
        title: 'Detalle solicitud',
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      //this.pageSize=5;
      //this.ngOnInit();
    });
  }


  editSolicitud(solicitud: Solicitud): void {
    const dialogNewRol = this.dialog.open(ProcesarSolicitudControlComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Detalles solicitud',
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      /* this.ngOnInit(); */
      this.getSolicitudes();
    });
  }

  procesarSolictud(solicitud: Solicitud): void {
    const dialogNewRol = this.dialog.open(ProcesarSolicitudControlComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'Procesar',
        title: 'Detalles solicitud',
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.getSolicitudes();
    });
  }



  generarSolicitud(solicitud: Solicitud): void {
    const dialogNewRol = this.dialog.open(ConsultarProveedorComponent, {
      width: '50%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Editar Solicitudes',
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      /* this.ngOnInit(); */
      this.getSolicitudes();
    });
  }


  viewSolicitudAprobada(solicitud: Solicitud): void {
    const dialogNewRol = this.dialog.open(AprobarSolcitudComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'view',
        title: 'Detalles solicitudes',
        coordinador: false,
        solicitud
      },
      panelClass: 'custom-dialog-container',
    });
  }

  applyFilter($event) {

  }

  filterBydate(event) {
    this.filterDate = (event.target as HTMLInputElement).value;
    this.getSolicitudes();
  }

  filterByState(event) {
    if(event.length === 0){
      this.filterStates = this.defaultStates;
    }else{
      this.filterStates = event;
    }
    this.getSolicitudes();

  }

  /**
 * @author Jose Vicente Silva
 * @createdate 2021-02-04
 * Metodo que se encarga del movimiento del paginador
 * @param event cuando se da click en el paginador trae este evento
 */
  pageEvent(event: any): void {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getSolicitudes();

  }

  downloadManagement () {
    this.dialog.open( DescargaComponent , {
      width : '60%',
      data:{
        type:"orden"
      }
    } )


  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }
}
