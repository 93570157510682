import { OrdenCompraComponent } from './../orden-compra/orden-compra.component';
import { DescargaComponent } from './../historial-aprobador/descarga/descarga.component';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { OrdenesService } from '../../../../services/rest/ordenes.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { OrdenCompraFragComponent } from '../orden-compra-frag/orden-compra-frag.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import { ArchivosService } from '../../../../services/rest/archivos.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common'
import Swal from 'sweetalert2';
import { CompaniaService } from 'src/app/modules/compras/services/rest/compania.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ProveedoresService } from 'src/app/modules/portal/services/proveedores.service';


@Component({
  selector: 'app-orden-compra-list',
  templateUrl: './orden-compra-list.component.html',
  styleUrls: ['./orden-compra-list.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OrdenCompraListComponent implements OnInit {
  companias : any[] = [];
  filteredOptionsCompania : any[] = [];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  columnsToDisplay = ['Acciones', 'ID Orden', 'Fecha de creación', 'NIT', 'Razón Social','request_title', 'Empresa', 'Estado'];
  expandedElement;
  length;
  pageSize          = 5;
  page              = 1;
  isPage            = 0;
  pageSizeOptions   : number[] = [5, 10, 25, 100];
  ordersStates      : any = [];
  filterState       : number[] = [];
  filterPurchaseOrderId         : number  = null;
  filterCompany: number  = null;
  filterNit: string = null;
  filterRazon: string = null;
  defaultStates = [1,7,5,8,6,9];
  listOrderStates  : any =[];
  textProveedor: FormControl = new FormControl('');
  listProveedores: any[] = [];
  filterListProveedores: Observable<any[]>;
  filterProveedor: any = null;


  columns = this.columnsToDisplay;

  constructor( public ordenesService    : OrdenesService,
               private alertsService    : AlertsService,
               private archivoService   : ArchivosService,
               public datepipe          : DatePipe,
               public dialog            : MatDialog,
               private companiaService   : CompaniaService,
               private proverdorService: ProveedoresService
               ) { }

  ngOnInit(): void {

    const filterSaveIntoStorageOc = JSON.parse(localStorage.getItem('filter_orden'));
    if(filterSaveIntoStorageOc && filterSaveIntoStorageOc.length > 0) this.filterState = filterSaveIntoStorageOc
    this.getCompanies();
    this.getOrders();
    this.getOrdersStates();
    this.getProveedores();
    this.filterListProveedores = this.textProveedor.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
  }

  getProveedores(): void {
    const params = { numeroRegistros: null, razon_social: null, nit: null, all_provedores: true };
    this.proverdorService.getProveedores(null, params).subscribe(res => {
      this.listProveedores = res;
    });
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.listProveedores.filter(option => option.razon_social.toLowerCase().includes(filterValue));
  }

  displayFn(module: any): string {
    return module && module ? module : '';
  }

  filterProveedores($event: any) {
    const nameProveedor = $event.option.value;
    const proveedor = this.listProveedores.find(item => item.razon_social === nameProveedor);
    this.filterProveedor = proveedor ? proveedor.id : null;
    this.getOrders();
  }

  validateStateFrags(ordenCompra: any): boolean {
    if (ordenCompra.hasOwnProperty('purchase_order_frags')) {
      if (ordenCompra.purchase_order_frags.length > 0) {
        return ordenCompra.purchase_order_frags.filter(item => item.purchase_order_state_id === 1).length > 0 ? true : false;
      } else {
        return false;
      }
    } 
    return false;
  }

  /**
   * Consulta las companias
   */
  getCompanies() {
    this.companiaService.getCompany().subscribe(comp=>{
      this.companias = comp.data;
      this.filteredOptionsCompania = this.companias;
    });
  }

  /**
   * Consulta las órdenes con los filtros correspondientes
   */
  getOrders() {
    for (let index = 0; index < this.filterState.length; index++) this.filterState = this.filterState[index] == 9 ? [] : this.filterState;
    this.ordenesService.getOrdersPaginate(
        this.isPage,
        this.pageSize,
        this.page ,
        this.filterNit,
        this.filterState,
        null,
        this.filterPurchaseOrderId,
        this.filterCompany,
        null,
        this.filterRazon,
        this.filterProveedor
         ).subscribe( resp => {

      this.dataSource.data = resp.data.data;
      this.dataSource.sort = this.sort;
      this.length = resp.data.total;
      this.pageSize = resp.data.per_page;
    });
  }
  /**
   * Consulta listado de ordenes de estado
   */
  getOrdersStates() {
    this.ordenesService.getOrdersStates().subscribe( resp => {
      this.ordersStates = resp.data.filter(state => {
        let flag = false;
        this.defaultStates.map(s => {
          if(s === state.id) flag = true;
        })
        return flag;
      })
      //this.ordersStates = this.defaultStates;
    });
  }

   /**
  * @author Andres Giraldo
  * @createdate 2022-03-10
  * Metodo que filtra las companias segun el autocomplete
  */
   filterByCompany(company) {
    this.filterCompany  = company;
    this.getOrders();
  }

   /**
  * @author Andres Giraldo
  * @createdate 2022-03-10
  * Metodo que muestra el nombre de la empresa seleccionada
  */
    displayFnCompania(id: number): string{
      if (!id) { return ''; }
      let index = this.companias.findIndex(resp => resp.id === id);
      return this.companias[index].name;

    }

  /**
  * @author Andres Giraldo
  * @createdate 2022-03-10
  * Metodo que filtra las companias segun el autocomplete
  */
    filterCompanies(v){
      const filterValue = v.toLowerCase();
      this.filteredOptionsCompania  = this.companias.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }



  /**
   * Consulta ordenes aplicando filtro por Estados
   * @param event
   */

  filterByState(event) {
    this.filterState = event;
    localStorage.setItem('filter_request', JSON.stringify(this.filterState));
    this.getOrders();

  }

  filterbyRazonsocial(event) {
    this.filterRazon = event.target.value;
    this.getOrders();
  }

  filterByNit(event) {
    this.filterNit = event.target.value;
    this.getOrders();
  }


  /**
   * Consulta ordenes aplicando filtro por nit
   * @param event
   */
   filterByPurchaseOrderId(event) {
    this.filterPurchaseOrderId = Number((event.target as HTMLInputElement).value.replace('OC-',''));
    this.getOrders();
  }


  downloadManagement () {
    this.dialog.open( DescargaComponent , {
      width : '60%',
      data:{
        type:"orden"
      }
    } )


  }

  editOrder (order) {
    const estado ={
      state:5
    }


    //Confirma  cambio de estado de aproba a rechazada
    Swal.fire({
      icon                : 'warning',
      title               : '¿Estás seguro?',
      text                : "¿Deseas enviar la orden de compra?, recuerda que esto será definitivo y no se podrá deshacer, únicamente podrás fragmentar la orden de compra",
      showCancelButton    : true,
      focusConfirm        : false,
      confirmButtonText   : 'Aceptar',
      cancelButtonText    : "Cancelar"
    }).then( (result) => {
      if(result.isConfirmed){
        this.ordenesService.updateState(estado, order.id).subscribe((resp)=>{
          this.alertsService.alertSuccess('Orden Enviada',resp.data);
          this.ngOnInit();
        },
        error => {
          console.error(error)
          this.alertsService.alertWarning('Atención',error.error.error);
        }
        );
      }
    } )



  }

  editOrderFrag(order){
    const estado ={
      state:5
    }

    //Confirma  cambio de estado de aproba a rechazada
    Swal.fire({
      icon                : 'warning',
      title               : '¿Estás seguro?',
      text                : "¿Deseas enviar la orden de compra?, recuerda que esto será definitivo y no se podrá deshacer, únicamente podrás fragmentar la orden de compra",
      showCancelButton    : true,
      focusConfirm        : false,
      confirmButtonText   : 'Aceptar',
      cancelButtonText    : "Cancelar"
    }).then( (result) => {
      if(result.isConfirmed){
        this.ordenesService.updateStateFrag(estado, order.id).subscribe((resp)=>{
          this.alertsService.alertSuccess('Orden Enviada',resp.data);
          this.ngOnInit();
        });
      }
    } )



  }

  downloadOrder (order) {
    const archivo ={
      path:order.path
    };
    this.archivoService.downloadOrder(archivo).subscribe( resp=>{
      const a = document.createElement('a');
      const file = new Blob([resp], { type: 'application/pdf' });
      a.href = window.URL.createObjectURL(file);
      a.download = 'Orden_Compra.pdf';
      a.click();

    }, error =>{

    });
  }


  fragmentarOrder(order){
    const dialogNewRol = this.dialog.open(OrdenCompraFragComponent, {
      width: '90%',
      disableClose: true,
      data: {
        type: 'fragmentar',
        title: 'Fragmentar orden de compra',
        ordenCompra:order

      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(resp => {
      if(resp) {
        this.getOrders();
      }
      this.getOrders();
    });
  }




  viewOrder(order){

    const dialogNewRol = this.dialog.open(OrdenCompraFragComponent, {
      width: '90%',
      disableClose: true,
      data: {
        type: 'view',
        title: 'Orden de compra',
        ordenCompra:order,

      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {

      this.ngOnInit();
    });

  }

  editOrderTota(order){

    //const dialogNewRol = this.dialog.open(OrdenCompraEditComponent, {
    const dialogNewRol = this.dialog.open(OrdenCompraComponent , {
      width: '90%',
      disableClose: true,
      data: {
        type: 'edit',
        title: 'Orden de compra',
        ordenCompra:order,
        solicitud:order,
        proveedor: order.provider

      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(result => {
      this.getOrders();
      //this.ngOnInit();
    });
  }

  pageEvent(event) {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getOrders();
  }





}
