import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { interval } from 'rxjs';

@Component({
  selector: 'app-chronometer',
  templateUrl: './chronometer.component.html',
  styleUrls: ['./chronometer.component.sass']
})
export class ChronometerComponent implements OnInit {

  @Input() timeAlert: number;
  @Output() timer = new EventEmitter<any>();

  stateAlert = 'normal';

  mm = 0;
	ss = 0;
	ms = 0;
	isRunning = false;
	timerId;

  subscribeIntervalConnected;

  constructor() { }

  ngOnInit(): void {
    const source = interval(1000);
		this.subscribeIntervalConnected = source.subscribe((val) => {
			this.ss++;

			if (this.ss >= 60) {
				this.mm++;
        if (this.mm >= this.timeAlert) {
          this.stateAlert = 'timeAlert';
        }
				this.ss = 0;
			}
		});
		this.isRunning = !this.isRunning;
  }

  format(num: number) {
    this.timer.emit(this.mm + ':' + this.ss);
		return (num + '').length === 1 ? '0' + num : num + '';
	}


}
