import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-rpa-modal',
  templateUrl: './rpa-modal.component.html',
  styleUrls: ['./rpa-modal.component.sass']
})
export class RpaModalComponent implements OnInit {

  @Output()actionModal= new EventEmitter();
  @Input() public set urlByIframe(type:string){
    this.type = type;
    switch (type) {
        case 'outbound':
            if(this.uidlocal){
                this.uidlocal = this.uidlocal.replace('.', 'x');
            }
            this.url = environment.RPA_OUTBOUND;//URL embeido para oubound (Modal)
            this.urlIframe = this.url + "?token=" + encodeURIComponent(this.authService.getToken()) + "&campaign_id=" + this.authService.getUser().rrhh.campaign_id + "&conversation_id=" + this.uidlocal;
            this.seeBotonsModal = true;
        break;
        case 'inbound':
            this.url = environment.RPA_INBOUND
            this.seeBotonsModal = false;
            this.urlIframe = this.url + "?token=" + encodeURIComponent(this.authService.getToken()) + "&campaign_id=" + this.authService.getUser().rrhh?.campaign_id + "&rrhh_id=" + this.authService.getUser().rrhh_id + "&form_id=414&gestion=true";
        break;
        default:
            this.url = "https://crmwhatsappaaaof.groupcos.com/auth-jwt"
            this.seeBotonsModal = true
        break;
    }

    this.loadIframe();//Se realiza la gestion de embeer
  }
  public urlIframe:string= ""
  public urlSafe:SafeResourceUrl;
  private url:string ="";
  public type:string = ""
  public seeBotonsModal:boolean = true;
  constructor(
    private authService: AuthService,
    private sanitizer: DomSanitizer
  ) { }

  uidlocal;

  @Input()
	public set uid(uid: any) {
    if (uid != null || uid != undefined) {
			this.uidlocal = uid;
		}
	}

  ngOnInit(): void {}

  get getClassIframe(){
    let classCss = "";
    switch (this.type) {
        case 'outbound':
            classCss = 'iframe-rpa';
        break;
        case 'inbound':
            classCss = 'iframe-rpa-extended';
        break;
    
        default:
            classCss = 'iframe-rpa';

        break;
    }
    return classCss;
  }
  /**
   * Cada que cambie el valor del input se tomara el valor de la url y se vovlera a cargar dentro del iframe
   */
  loadIframe():void{
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlIframe);
  }

  minimize(){
    this.actionModal.emit({action:'minimize'})
  }

  close(){
    this.actionModal.emit({action:'close'})
  }

}
