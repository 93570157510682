import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatrizService } from 'src/app/modules/auditoria/services/matriz.service';
import { Tools } from 'src/app/modules/auditoria/utils/tools.utils';

@Component({
  selector: 'app-crear-item',
  templateUrl: './crear-item.component.html',
  styleUrls: ['./crear-item.component.sass']
})
export class CrearItemComponent implements OnInit {

  itemForm:FormGroup;
  items = [];
  filteredOptions: Observable<any[]>;
  verError:boolean = true;
  continue:boolean = true;
  nuevo:boolean = false;
  peso:boolean = false;
  descripcion:boolean = false;

  constructor(
    public itemRef:MatDialogRef<CrearItemComponent>,
    @Inject(MAT_DIALOG_DATA) public datos:any,
    private fb:FormBuilder,
    private matrizService:MatrizService,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }
  
  createForm(){
    this.itemForm = this.fb.group({
      nombre:['',Validators.required],
      peso:['',[Validators.required]],
      descripcion:[''],
    })
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.items.filter(option => option.nombre.toLowerCase().includes(filterValue));
  }

  displayFn(module: any): string {
    return module && module ? module : '';
  }

  getItems(){
    this.matrizService.getItems(this.itemForm.get('nombre').value).subscribe((resp:any)=>{
      if (resp.length == 0) {
        this.verError = true
        this.itemForm.get('nombre').setErrors({invalid:true});
        this.nuevo = true;
        this.continue = true;
        this.descripcion = false;
        this.peso = false;
      }else{
        this.newItem();
        this.descripcion = true;
        this.verError = false;
        this.nuevo = false;
        this.items = resp
        this.filteredOptions = this.itemForm.get('nombre').valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      }
    })
  }

  newItem(){
    this.nuevo = false;
    this.itemForm.get('nombre').setErrors(null);
    this.descripcion = true;
    if (this.datos.tipo_matriz == "Cumplimiento") {
      this.peso = false
      this.itemForm.get('peso').setValue(null);
      this.itemForm.get('peso').setErrors(null);
    }else{
      if (this.datos.tipo_peso == 'Peso en Items') {
        if (this.datos.error_critico) {
          this.peso = false;
          this.itemForm.get('peso').setValue(null);
          this.itemForm.get('peso').setErrors(null);
        }else{
          this.peso = true;
        }
      }else{
        this.peso = false;
        this.itemForm.get('peso').setValue(null);
        this.itemForm.get('peso').setErrors(null);
      }
    }
    this.descripcion = true;
    this.continue = false;
  }

  saveItem(){
    if (this.itemForm.invalid) {
      return
    }
    const data = {
      idMatriz: this.datos.id_matriz,
      nivel: 2,
      sigla: this.datos.sigla,
      padre_id: this.datos.id_modulo,
      error_critico: this.datos.error_critico,
    }
    Object.assign(data,this.itemForm.value);
    this.matrizService.newItem(data).subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202) {
        Tools.swalSuccess('¡Excelente!','Se ha creado el ítem correctamente.');
        this.itemRef.close();
      }else if (resp.respuesta.codigo > 400) {
        Tools.swalError('¡Oops!','Lo sentimos, ha ocurrido un error inesperado y no fue posible crear el ítem, por favor intenta nuevamente.');
      }
    },(err:any)=>{
    }
    );
  }

}
