import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfigurationComponent } from './modules/configuration/configuration.component';
import { ShowReportComponent } from './modules/show-report/show-report.component';
import { IndexComponent } from './modules/index/index.component';



const routes: Routes = [
  { path: 'configuration', component: ConfigurationComponent },
  { path: 'report', component: ShowReportComponent },
  { path: '', component:  IndexComponent },

]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SoulBiRoutingModule { }
