import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import { CoachingService } from 'src/app/modules/coaching/services/coaching.service';
import { CoachingState } from 'src/app/modules/coaching/state/coaching.state';
import { listCampañas, listProcesos } from 'src/app/modules/coaching/state/selectors/selects.selector';
import Swal from 'sweetalert2';
import { listIndicadores, listCuartiles, listCausas } from '../../../../state/selectors/selects.selector';

@Component({
	selector: 'app-nuevo-coaching',
	templateUrl: './nuevo-coaching.component.html',
	styleUrls: ['./nuevo-coaching.component.sass']
})
export class NuevoCoachingComponent implements OnInit {

	coachingForm: FormGroup;
	indicadores$: Observable<any> = new Observable();
	procesos$: Observable<any> = new Observable();
	campaigns$: Observable<any> = new Observable();
	cuartiles$: Observable<any> = new Observable();
	causas$: Observable<any> = new Observable();
	idCoachee;
	startDate = new Date();
	endDate = moment(this.startDate).add(30, 'd').format();
	campaignJefe: boolean = false;
	hiddenByProcess:boolean=true;

	constructor(
		private coachingRef: MatDialogRef<NuevoCoachingComponent>,
		@Inject(MAT_DIALOG_DATA) public datos: any,
		private fb: FormBuilder,
		private coachingService: CoachingService,
		private store: Store<CoachingState>,
		private authService: AuthService
	) { }

	ngOnInit(): void {
		this.getFilterParam();
		this.createForm();
		this.getSelects();
	}

	createForm() {
		this.coachingForm = this.fb.group({
			indicador: ['', [Validators.required]],
			tipo_proceso: ['', [Validators.required]],
			cedula_coachee: ['', [Validators.required]],
			nombre_coachee: [{ value: '', disabled: true }, [Validators.required]],
			campaing: ['', [Validators.required]],
			indicador_inicial: ['', [Validators.required]],
			cuartil_inicial: ['', [Validators.required]],
			proximo_seguimiento: ['', [Validators.required]],
			objetivo_kpi: ['', [Validators.required]],
			causa_raiz: ['', [Validators.required]],
			conclusion_sesion: ['', [Validators.required]],
		});
	}

	/**
	* @author Cristian Gallo
	* @createdate 2021-12-10
	* Metodo enviar y guardar el nuevo acompañamiento.
	*/

	saveCoaching() {
		this.coachingForm.get('nombre_coachee').enable();
		this.coachingForm.get('proximo_seguimiento').setValue(moment(this.coachingForm.get('proximo_seguimiento').value).format('yyyy-MM-DD'));
		Object.assign(this.coachingForm.value, {
			idCoachee: this.idCoachee,
			ruta: `/mios/coaching/solicitud/`,
			mensaje: `Se te ha realizado un nuevo acompañamiento con ID-`
		});
		this.coachingService.crearCoachee(this.coachingForm.value).subscribe((resp: any) => {
			if (resp.respuesta.codigo == 202) {
				Swal.fire({
					icon: 'success',
					title: '¡Excelente!',
					html: `<p>El coaching se ha creado con exito.</p><p><b>ID Coaching ${resp.respuesta.id}</b></p>`,
					showConfirmButton: false,
					timer: 4500
				})
				this.coachingRef.close();
			} else {
				this.coachingForm.get('nombre_coachee').disable();
				Tools.swalError('¡Oops!', 'Lo sentimos, ha ocurrido un error inesperado y no fue posible crear el acompañamiento, por favor intenta nuevamente.');
			}
		}, (err) => {
			this.coachingForm.get('nombre_coachee').disable();
			Tools.swalError('¡Oops!', 'Lo sentimos, ha ocurrido un error inesperado y no fue posible crear el acompañamiento, por favor intenta nuevamente.');
		}
		);
	}
	/**
	* @author Cristian Gallo
	* @createdate 2021-12-10
	* Metodo para cargar la información de los selects.
	*/
	getSelects() {
		this.indicadores$ = this.store.select(listIndicadores);
		this.procesos$ = this.store.select(listProcesos);
		this.cuartiles$ = this.store.select(listCuartiles);
		this.causas$ = this.store.select(listCausas);
		this.campaigns$ = this.store.select(listCampañas);
		
	}

	/**
	* @author Cristian Gallo
	* @createdate 2021-12-10
	* Metodo para llamar y cargar la información del asesor.
	*/

	getAsesor() {
		const params = {
			cedula: this.coachingForm.get('cedula_coachee').value
		}
		this.coachingService.getAsesor(params).subscribe((resp: any) => {
			this.idCoachee = resp[0].id;
			this.coachingForm.get('nombre_coachee').setValue(resp[0].nombre)
			this.coachingForm.get('cedula_coachee').setErrors(null);
		}, () => {
			this.coachingForm.get('nombre_coachee').setValue('');
			this.coachingForm.get('cedula_coachee').setErrors({ no_resultado: true });
		}
		);
	}


	getFilterParam() {
		this.authService.getUser().roles.forEach(rol => {
			if (rol == 'coaching::jefe' || rol == 'coaching::gerente') {
				this.campaignJefe = true;
			}
		});
	}

	/**
	 * Metodo que oculta determinados campos del formulario de nuevo aocmpañamiento
	 * @author Juan David Guerrero Vargas
	 * @create_date 07/06/2022  
	 * @param event:MatSelectChange Output de MatSelect cambio de estado en el selector de tipo de proceso
	 * @return void 
	 */
	changeTypeProcess(event:MatSelectChange):void{
		let inputsEnableDisabled :string[]=[
			'proximo_seguimiento',
			'objetivo_kpi',
			'causa_raiz'
		];

		let selection:any;
		this.procesos$.subscribe(
			process => {
				selection = process.filter( process => process.id === event.value )[0];
				inputsEnableDisabled.forEach(itera =>{
					if(selection.nombre && selection.nombre.toLowerCase().includes('felicitacio')){	
						this.coachingForm.controls[itera].setValue('');
						this.coachingForm.controls[itera].disable();
						this.hiddenByProcess = false;						
					}else{
						this.coachingForm.controls[itera].enable();
						this.hiddenByProcess = true;	
					}
				})
			}
		);
		

		
	}

}
