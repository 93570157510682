<div class="row col-12 filter mx-0" style="padding-top: 40px;">
    <mat-form-field class="col-3 ps-0" appearance="fill">
      <mat-label>Fecha de creación</mat-label>
      <input matInput
        autocomplete    = "off"
        readonly        = "true"
        [matDatepicker] = "picker"
        (dateChange)    = "filterBydate($event)"
        >
      <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon><span class="fi fi-rr-calendar"></span></mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>



      <mat-form-field class="col-3" appearance="fill">
          <mat-label>Estado</mat-label>
          <mat-select class = "w-100" (selectionChange)="filterByState($event.value)" multiple [(ngModel)]="filterState">
              <mat-option *ngFor="let state of ordersStates" [value]="state.id">{{state.name}}</mat-option>
          </mat-select>
      </mat-form-field>

      <mat-form-field class="col-3" appearance="fill">
          <mat-label>NIT</mat-label>
          <input matInput
            autocomplete    = "off"
            (keydown.enter) = "filterByNIT($event)"
            (blur)          = "filterByNIT($event)" #nit
            [(ngModel)]     = "filterNit"

            >
          <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
      </mat-form-field>

      <div class="col-3 buttons pe-2" align="center">
        <button class="w-100 bold" mat-raised-button color="primary" (click)="downloadManagement()">Descargar gestión <i class="fi-rr-download"></i></button>
      </div>

  </div>
  <div class="table-container">

          <table mat-table [dataSource]="dataSource" multiTemplateDataRows >
              <ng-container matColumnDef="Acciones">
                  <th class="start" mat-header-cell *matHeaderCellDef></th>
                  <td style="width: 12.5%;" mat-cell *matCellDef="let element" align="right" class="p-0 pe-4">
                      <div class="action-icons">
                        <button mat-icon-button (click)="viewOrderRemission(element)">
                          <i class="fi-rr-eye"></i>
                        </button>
                        <button mat-icon-button (click)="editOrderRemission(element)" *ngIf="(element.remission == null && element.purchase_order_frags.length === 0) || (element.remission?.state?.id == 1 && element.purchase_order_frags.length === 0) || (element.remission?.state?.id == 2 && element.purchase_order_frags.length === 0)">
                            <i class="fi-rr-edit"></i>
                        </button>

                        <button mat-icon-button (click)="downloadRemission(element.id)">
                            <i class="fi-rr-download"></i>
                        </button>

                      </div>

                  </td>
              </ng-container>

              <ng-container matColumnDef="ID Orden">
                <th mat-header-cell *matHeaderCellDef> ID Orden </th>
                <td mat-cell *matCellDef="let element">  OC-{{ element.purchase_order_number }}</td>
            </ng-container>

              <ng-container matColumnDef="Fecha de creación">
                  <th mat-header-cell *matHeaderCellDef> Fecha de creación </th>
                  <td mat-cell *matCellDef="let element"> {{element.created_at | date}} </td>
              </ng-container>
              <ng-container matColumnDef="NIT">
                  <th mat-header-cell *matHeaderCellDef> NIT </th>
                  <td mat-cell *matCellDef="let element"> {{element.provider.nit}} </td>
              </ng-container>
              <ng-container matColumnDef="Razón Social">
                  <th mat-header-cell *matHeaderCellDef> Razón social </th>
                  <td mat-cell *matCellDef="let element"> {{element.provider.razon_social|slice:0:30 }} </td>
              </ng-container>
              <ng-container matColumnDef="request_title">
                  <th mat-header-cell *matHeaderCellDef> Nombre de la solicitud </th>
                  <td mat-cell *matCellDef="let element">
                      {{ element.request.title | slice:0:30 }}
                      <span *ngIf="element.request.title.length > 30" >...</span>
                  </td>
              </ng-container>
              <ng-container matColumnDef="Empresa">
                  <th mat-header-cell *matHeaderCellDef> Empresa </th>
                  <td mat-cell *matCellDef="let element"> {{element.company.name}} </td>
              </ng-container>
              <ng-container matColumnDef="Estado">
                  <th class="end" mat-header-cell *matHeaderCellDef> Estado </th>
                  <td mat-cell *matCellDef="let element" align="center">
                    <div *ngIf="element.remission === null" style="white-space: nowrap;">
                      <i
                        class                     = "span"
                        style                     = "align-items: initial; background-color: #FE6D73;"
                        >&nbsp;</i>
                     Pendiente</div>

                     <div *ngIf="element.remission !== null"  style="white-space: nowrap;">
                        <i
                          class                     = "span"
                          style                     = "align-items: initial;"
                          [style.backgroundColor]   = "element.remission.state.color"
                          [style.color]             = "element.remission.state.color"
                          >&nbsp;</i>
                       {{element.remission.state.name}}</div>


                    </td>
              </ng-container>

              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
              <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" class="px-2">
                      <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                          <table class="table table-frag-layout">
                              <tbody>
                                  <tr *ngFor="let frag of element.purchase_order_frags">
                                      <td style="width: 12.5%; text-align: right;" class="p-0 action-icons pe-4">
                                        <div class="action-icons" style="margin-right: 5px;">

                                          <button mat-icon-button (click)="viewOrderRemission(element, frag)">
                                              <i class="fi-rr-eye"></i>
                                          </button>

                                          <button mat-icon-button (click)="editOrderRemission(element, frag)" *ngIf="frag.remission_state == 'Pendiente' || frag.remission_state == 'En Proceso'">
                                            <i class="fi-rr-edit"></i>
                                        </button>

                                          <button mat-icon-button (click)="downloadFragRemission(element.id,frag.id)">
                                              <i class="fi-rr-download"></i>
                                          </button>
                                          </div>
                                      </td>
                                      <td style="width: 12.5%; text-align: left;" class="ps-4"> OC-{{element.purchase_order_number}}-{{frag.purchase_order_number}}</td>
                                      <td style="width: 12.5%; text-align: left;">{{frag.created_at | date}}</td>
                                      <td style="width: 12.5%; text-align: left;"></td>
                                      <td style="text-align: left;width: 12.5%;"></td>
                                      <td style="width: 12.5%;  text-align: left;"></td>
                                      <td style="width: 12.5%; text-align: left;"></td>
                                      <td style="width: 12.5%; text-align: left;" clas="ps-0">{{frag.remission_state || 'Pendiente' }}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
              <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row" [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element">
              </tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

              <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="7">No hay datos que coincidan con el filtro</td>
              </tr>
          </table>
          <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)" [pageSizeOptions]="pageSizeOptions">
          </mat-paginator>

  </div>
