<h2 mat-dialog-title>{{data.title}}
  <button style="float: right;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross-small"></i>
  </button>
</h2>
<mat-dialog-content style="padding: 3%;" class="mat-typography" *ngIf="escalation">
  <div id="mail-scalation">
    <div class="row">
      <div class="col-6">
        <h3>Radicado #{{data.id}}</h3>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <button mat-stroked-button (click)="printMail()" color="primary"> <span class="fi-rr-download"></span> Descargar
          PDF</button>

      </div>
    </div>
    <br>
    <div><b>Asunto: </b>{{escalation?.p_q_r_s_asunto?.name}}</div>
    <br>
    <div><b>Estado: </b>{{escalation?.p_q_r_s_estado?.name}}</div>
    <br>
    <div><b>Es Anónimo: </b>{{ escalation.is_anonimous === 1 ? 'Si' : 'No' }}</div>
    <app-mail-escalation [escalation]="escalation"></app-mail-escalation>
    <br>
    <div id="form_escalation">
      <shared-dynamic-form *ngIf="pintarCrm1" [sections]="{sections:escalation?.preguntas.sections, app:'pqrs'}"
        (submitForm)="sendForm($event,'crm1')"></shared-dynamic-form>

        <shared-dynamic-form-v2 *ngIf="pintarCrm2"  [sections]="{sections:escalation?.preguntas.sections, app:'pqrs'}" 
        (submitForm)="sendForm($event,'crm2')"></shared-dynamic-form-v2>
    </div>
    

    <!-- <app-gestion-escalamientos
    [formEscalamiento]="formEscalamiento">
  </app-gestion-escalamientos> -->
  </div>
  <br>
  <br>
  <h3>Adjuntar archivos</h3>
  <div class="row">
    <div class="col-3" style="display: flex;" *ngFor="let item of adjuntosPqrs">
      <a #file style="text-overflow: ellipsis; padding-top: 15px;
          overflow: hidden;
           white-space: nowrap;
           text-decoration: underline;
           color: #353535;
           opacity: 1;
           font-weight: 600;
           cursor: pointer;" (click)="descargarAdjunto(item.url, item.nombre)">{{item.nombre}} </a>
      <button mat-icon-button (click)="file.click()">
        <i class="fi-rr-download"></i>
      </button>
      <button mat-icon-button (click)="eliminarAdjunto(item.id, item.url)">
        <i class="fi-rr-trash"></i>
      </button>
    </div>
    <div class="col-1">
      <button color="primary" mat-icon-button type="button" (click)="uploadFile.click()">
        <i class="fi fi-sr-add document"></i>
      </button>
    </div>
    <input hidden type="file" #uploadFile (change)="guardarAdjunto($event)">
  </div>
  <br>
  <h3>Historial de Modificaciones</h3>
  <div class="table-container">
    <div class="mat-elevation-z8">
      <table class="col-12" mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="updated_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Modificación </th>
          <td mat-cell *matCellDef="let row"> {{row.updated_at | date:'shortDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="observacion_user">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuario </th>
          <td mat-cell *matCellDef="let row"> {{row.observacion_user?.name}}</td>
        </ng-container>

        <ng-container matColumnDef="observacion_estado">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
          <td mat-cell *matCellDef="let row"> {{row.observacion_estado?.name}} </td>
        </ng-container>

        <ng-container matColumnDef="observacion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Observación </th>
          <td mat-cell *matCellDef="let row"> {{row.observacion}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro </td>
        </tr>
      </table>

      <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)"
        [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </div>
  </div>
</mat-dialog-content>