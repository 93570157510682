import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { AuditoriaService } from 'src/app/modules/auditoria/services/auditoria.service';
import * as moment from 'moment';

@Component({
  selector: 'app-crud-tipificacion',
  templateUrl: './crud-tipificacion.component.html',
  styleUrls: ['./crud-tipificacion.component.sass']
})
export class CrudTipificacionComponent implements OnInit {
  dataSource = [];
  FrmFormulario: FormGroup;
  length = null;
  pageSize = 5;
  page = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  displayedColumns: string[] = ['accion', 'id', 'adviser', 'form_name', 'management_date'];
  formsCrm: any[] = [];

  constructor(
    private alertsService: AlertsService,
    private fb: FormBuilder,
    private AuditoriaService: AuditoriaService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.getForms();
  }

  /**
    * @author Fabio Garcia Alvarez
    * @createdate 2022-02-04
    * Metodo que emite cambios de la paginacion en la tabla
    * @param event Evento emitido por el paginador
  */
  pageEvent(event: any): void {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.listar(this.FrmFormulario.get('id_formulario').value, this.pageSize, this.page, this.FrmFormulario.get('idRegistro').value, this.FrmFormulario.get('status').value, this.FrmFormulario.get('crm').value);
  }
  /**
    * @author Fabio Garcia Alvarez
    * @createdate 2022-02-04
    * Metodo que crea el formulario para los filtros
  */
  createForm() {
    this.FrmFormulario = this.fb.group({
      id_formulario: ['', Validators.required],
      fechaInicial: ['', Validators.required],
      fechaFinal: ['', Validators.required],
      idRegistro: [''],
      status: [1],
      crm: [1]
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2022-02-04
    * Metodo que llama a la funcion get_tipificaciones
  */
  get_tipificaciones() {
    this.page = 1;
    this.listar(this.FrmFormulario.get('id_formulario').value, this.pageSize, this.page, this.FrmFormulario.get('idRegistro').value, this.FrmFormulario.get('status').value, this.FrmFormulario.get('crm').value);
  }
  /**
    * @author Fabian Duran
    * @createdate 2022-02-04
    * Metodo lista las tipificaciones del formulario CRM de ETB
  */
  listar(formid: number, pageSize: number, page: number, id_registro: number, status: number, crmVersion: number): void {
    if (this.FrmFormulario.invalid === true) this.FrmFormulario.markAllAsTouched();
    else {
      const fi = moment(this.FrmFormulario.get('fechaInicial').value).format('yyyy-MM-DD');
      const ff = moment(this.FrmFormulario.get('fechaFinal').value).format('yyyy-MM-DD');
      this.AuditoriaService.listarCrmV2(formid, pageSize, page, id_registro, fi, ff, status, crmVersion).subscribe((resp: any) => {
        this.dataSource = resp.data;
        this.length = resp.total;
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2022-02-04
    * Metodo que confirma la eliminacion de una tificacion
    * @param id Id del registro a eliminar
    * @param status Tipo de gestion a realizar
  */
  ConfirmEliminar(id: number, status: number) {
    const messageAlert = status === 0 ? `¿En verdad deseas <b>eliminar</b> el registro? <br><b>ID del registro:</b> ${id}` : `¿En verdad deseas <b>restaurar</b> el registro? <br><b>ID del registro:</b> ${id}`;
    this.alertsService.alertWarning('¿Estás Seguro?', messageAlert).then(confirm => {
      if (confirm.isConfirmed) this.eliminarTipificacion(id, status, this.FrmFormulario.get('crm').value);
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2022-02-04
    * Metodo que elimina una tificacion
    * @param id a eliminar.
    * @param status Tipo de gestion a realizar.
    * @param crmVersion Version de crm a gestionar.
  */
  eliminarTipificacion(id: number, status: number, crmVersion: number): void { 
    this.AuditoriaService.eliminarCrmV2(id, status, crmVersion).subscribe((resp: any) => {
      this.alertsService.alertSuccess(`${status === 0 ? 'Eliminado' : 'Restaurado'}`, `${status === 0 ? 'Dato eliminado exitosamente' : 'Dato restaurado exitosamente'}`);
      this.listar(this.FrmFormulario.get('id_formulario').value, this.pageSize, this.page, this.FrmFormulario.get('idRegistro').value, this.FrmFormulario.get('status').value, this.FrmFormulario.get('crm').value);
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-01-11
    * Metodo que lista los formularios de CRM
  */
  getForms(): void {
    this.AuditoriaService.listFormsCrm(this.FrmFormulario.get('crm').value).subscribe((res: any) => {
      this.formsCrm = res;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-04-05
    * Metodo que actualiza el estado del formulario cuando se cambia version de CRM
  */
  onChangeSelectCrmVersion(): void {
    const fields = ['id_formulario', 'fechaInicial', 'fechaFinal', 'idRegistro'];
    fields.forEach(field => {
      this.FrmFormulario.get(field).reset();
    });
    this.dataSource = [];
    this.getForms();
  }
}