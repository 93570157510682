<div class="row">
  <div class="col-10">
    <h2 mat-dialog-title class="title">{{ data.title }}</h2>
  </div>
  <div class="col-2 d-flex justify-content-end">
    <i class="fi-rr-cross-small" style="cursor:pointer; font-size: 20px; color: #353535;" mat-icon-button
      mat-dialog-close></i>
  </div>
</div>

<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-12">
      <p cdkFocusInitial>Estas preguntas son necesarias para poder restaurar tus credenciales en caso de
        necesitarlo, las mayúsculas, minúsculas y acentos de tus respuestas se tendrán en cuenta.</p>
    </div>
  </div>


  <mat-horizontal-stepper linear #stepper>
    <mat-step [stepControl]="match_answers" [editable]="false" [completed]="match_answers">
      <form [formGroup]="questions_form" class="mt-3">
        <ng-template matStepLabel>Responde las preguntas de seguridad</ng-template>
        <div class="row" formGroupName="first_question">
          <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>{{ questions_labels.first }}</mat-label>
              <input type="text" matInput formControlName="answer" required />
              <mat-error *ngIf="first_question_controls.answer.hasError('required')">Ingresa una respuesta</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row" formGroupName="second_question">
          <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>{{ questions_labels.second }}</mat-label>
              <input type="text" matInput formControlName="answer" required />
              <mat-error *ngIf="second_question_controls.answer.hasError('required')">Ingresa una respuesta</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row" formGroupName="third_question">
          <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>{{ questions_labels.third }}</mat-label>
              <input type="text" matInput formControlName="answer" required />
              <mat-error *ngIf="third_question_controls.answer.hasError('required')">Ingresa una respuesta</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="d-flex justify-content-end">
            <button color="primary" mat-raised-button (click)="validateAnswers(stepper)">Validar</button>
          </div>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="new_questions_form" [editable]="false">
      <form [formGroup]="new_questions_form" class="mt-3">
        <ng-template matStepLabel>Crea las nuevas preguntas de seguridad</ng-template>
          <div class="row" formGroupName="first_question">
            <div class="col-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Primera pregunta</mat-label>
                <mat-select formControlName="question_id" required>
                 <mat-option value="">---Selecciona una opción---</mat-option>
                 <mat-option *ngFor="let question of questions" [value]="question.id" [disabled]="question.disabled">{{ question.question }}</mat-option>
                </mat-select>
                <mat-error *ngIf="getNewQuestionControls('first_question').question_id.hasError('required')">Selecciona una opción</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Respuesta</mat-label>
                <input type="text" matInput formControlName="answer" required/>
                <mat-error *ngIf="getNewQuestionControls('first_question').answer.hasError('required')">Ingresa una respuesta</mat-error>
                <mat-error *ngIf="getNewQuestionControls('first_question').answer.hasError('minlength')">La respuesta debe contener mínimo 2 caracteres</mat-error>
                <mat-error *ngIf="getNewQuestionControls('first_question').answer.hasError('maxlength')">La respuesta debe contener máximo 200 caracteres</mat-error>
              </mat-form-field>
            </div>
          </div>

          <mat-divider></mat-divider>

          <div class="row mt-3" formGroupName="second_question">
            <div class="col-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Segunda pregunta</mat-label>
                <mat-select formControlName="question_id" required>
                 <mat-option value="">---Selecciona una opción---</mat-option>
                 <mat-option *ngFor="let question of questions" [value]="question.id" [disabled]="question.disabled">{{ question.question }}</mat-option>
                </mat-select>
                <mat-error *ngIf="getNewQuestionControls('second_question').question_id.hasError('required')">Selecciona una opción</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Respuesta</mat-label>
                <input type="text" matInput formControlName="answer" required/>
                <mat-error *ngIf="getNewQuestionControls('second_question').answer.hasError('required')">Ingresa una respuesta</mat-error>
                <mat-error *ngIf="getNewQuestionControls('second_question').answer.hasError('minlength')">La respuesta debe contener mínimo 2 caracteres</mat-error>
                <mat-error *ngIf="getNewQuestionControls('second_question').answer.hasError('maxlength')">La respuesta debe contener máximo 200 caracteres</mat-error>
              </mat-form-field>
            </div>
          </div>

          <mat-divider></mat-divider>


          <div class="row mt-3" formGroupName="third_question">
            <div class="col-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Tercera pregunta</mat-label>
                <mat-select formControlName="question_id" required>
                 <mat-option value="">---Selecciona una opción---</mat-option>
                 <mat-option *ngFor="let question of questions" [value]="question.id" [disabled]="question.disabled">{{ question.question }}</mat-option>
                </mat-select>
                <mat-error *ngIf="getNewQuestionControls('third_question').question_id.hasError('required')">Selecciona una opción</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Respuesta</mat-label>
                <input type="text" matInput formControlName="answer" required/>
                <mat-error *ngIf="getNewQuestionControls('third_question').answer.hasError('required')">Ingresa una respuesta</mat-error>
                <mat-error *ngIf="getNewQuestionControls('third_question').answer.hasError('minlength')">La respuesta debe contener mínimo 2 caracteres</mat-error>
                <mat-error *ngIf="getNewQuestionControls('third_question').answer.hasError('maxlength')">La respuesta debe contener máximo 200 caracteres</mat-error>
              </mat-form-field>
            </div>
          </div>

          <mat-divider></mat-divider>


          <div class="row mt-3" formGroupName="fourth_question">
            <div class="col-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Cuarta pregunta</mat-label>
                <mat-select formControlName="question_id" required>
                 <mat-option value="">---Selecciona una opción---</mat-option>
                 <mat-option *ngFor="let question of questions" [value]="question.id" [disabled]="question.disabled">{{ question.question }}</mat-option>
                </mat-select>
                <mat-error *ngIf="getNewQuestionControls('fourth_question').question_id.hasError('required')">Selecciona una opción</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Respuesta</mat-label>
                <input type="text" matInput formControlName="answer" required/>
                <mat-error *ngIf="getNewQuestionControls('fourth_question').answer.hasError('required')">Ingresa una respuesta</mat-error>
                <mat-error *ngIf="getNewQuestionControls('fourth_question').answer.hasError('minlength')">La respuesta debe contener mínimo 2 caracteres</mat-error>
                <mat-error *ngIf="getNewQuestionControls('fourth_question').answer.hasError('maxlength')">La respuesta debe contener máximo 200 caracteres</mat-error>
              </mat-form-field>
            </div>
          </div>

          <mat-divider></mat-divider>


          <div class="row mt-3" formGroupName="fifth_question">
            <div class="col-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Quinta pregunta</mat-label>
                <mat-select formControlName="question_id" required>
                 <mat-option value="">---Selecciona una opción---</mat-option>
                 <mat-option *ngFor="let question of questions" [value]="question.id" [disabled]="question.disabled">{{ question.question }}</mat-option>
                </mat-select>
                <mat-error *ngIf="getNewQuestionControls('fifth_question').question_id.hasError('required')">Selecciona una opción</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Respuesta</mat-label>
                <input type="text" matInput formControlName="answer" required/>
                <mat-error *ngIf="getNewQuestionControls('fifth_question').answer.hasError('required')">Ingresa una respuesta</mat-error>
                <mat-error *ngIf="getNewQuestionControls('fifth_question').answer.hasError('minlength')">La respuesta debe contener mínimo 2 caracteres</mat-error>
                <mat-error *ngIf="getNewQuestionControls('fifth_question').answer.hasError('maxlength')">La respuesta debe contener máximo 200 caracteres</mat-error>
              </mat-form-field>
            </div>
          </div>

          <mat-divider></mat-divider>


          <div class="row mt-3" formGroupName="sixth_question">
            <div class="col-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Sexta pregunta</mat-label>
                <mat-select formControlName="question_id" required>
                 <mat-option value="">---Selecciona una opción---</mat-option>
                 <mat-option *ngFor="let question of questions" [value]="question.id" [disabled]="question.disabled">{{ question.question }}</mat-option>
                </mat-select>
                <mat-error *ngIf="getNewQuestionControls('sixth_question').question_id.hasError('required')">Selecciona una opción</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Respuesta</mat-label>
                <input type="text" matInput formControlName="answer" required/>
                <mat-error *ngIf="getNewQuestionControls('sixth_question').answer.hasError('required')">Ingresa una respuesta</mat-error>
                <mat-error *ngIf="getNewQuestionControls('sixth_question').answer.hasError('minlength')">La respuesta debe contener mínimo 2 caracteres</mat-error>
                <mat-error *ngIf="getNewQuestionControls('sixth_question').answer.hasError('maxlength')">La respuesta debe contener máximo 200 caracteres</mat-error>
              </mat-form-field>
            </div>
          </div>

          <mat-divider></mat-divider>


          <div class="row mt-3" formGroupName="seventh_question">
            <div class="col-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Séptima pregunta</mat-label>
                <mat-select formControlName="question_id" required>
                 <mat-option value="">---Selecciona una opción---</mat-option>
                 <mat-option *ngFor="let question of questions" [value]="question.id" [disabled]="question.disabled">{{ question.question }}</mat-option>
                </mat-select>
                <mat-error *ngIf="getNewQuestionControls('seventh_question').question_id.hasError('required')">Selecciona una opción</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Respuesta</mat-label>
                <input type="text" matInput formControlName="answer" required/>
                <mat-error *ngIf="getNewQuestionControls('seventh_question').answer.hasError('required')">Ingresa una respuesta</mat-error>
                <mat-error *ngIf="getNewQuestionControls('seventh_question').answer.hasError('minlength')">La respuesta debe contener mínimo 2 caracteres</mat-error>
                <mat-error *ngIf="getNewQuestionControls('seventh_question').answer.hasError('maxlength')">La respuesta debe contener máximo 200 caracteres</mat-error>
              </mat-form-field>
            </div>
          </div>

          <mat-divider></mat-divider>


          <div class="row mt-3" formGroupName="eighth_question">
            <div class="col-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Octava pregunta</mat-label>
                <mat-select formControlName="question_id" required>
                 <mat-option value="">---Selecciona una opción---</mat-option>
                 <mat-option *ngFor="let question of questions" [value]="question.id" [disabled]="question.disabled">{{ question.question }}</mat-option>
                </mat-select>
                <mat-error *ngIf="getNewQuestionControls('eighth_question').question_id.hasError('required')">Selecciona una opción</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Respuesta</mat-label>
                <input type="text" matInput formControlName="answer" required/>
                <mat-error *ngIf="getNewQuestionControls('eighth_question').answer.hasError('required')">Ingresa una respuesta</mat-error>
                <mat-error *ngIf="getNewQuestionControls('eighth_question').answer.hasError('minlength')">La respuesta debe contener mínimo 2 caracteres</mat-error>
                <mat-error *ngIf="getNewQuestionControls('eighth_question').answer.hasError('maxlength')">La respuesta debe contener máximo 200 caracteres</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="d-flex justify-content-end">
              <button mat-stroked-button color="primary" mat-dialog-close>Cancelar</button>
              <button mat-raised-button class="ms-2" color="primary" (click)="save()" [disabled]="new_questions_form.invalid">Guardar</button>
            </div>
          </div>
        </form>
    </mat-step>
  </mat-horizontal-stepper>


</mat-dialog-content>
