import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssignTestComponent } from './assign-test/assign-test.component';
import { SharedModule } from '../../../../shared/shared.module';
import { UploadTestComponent } from './upload-test/upload-test.component';
import { MassiveTestsComponent } from './massive-tests/massive-tests.component';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { AssessmentResultsPipe } from './assessment-results.pipe';



@NgModule({
  declarations: [AssignTestComponent, UploadTestComponent, MassiveTestsComponent, AssessmentResultsPipe],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule
  ]
})
export class CandidateTestsModule { }
