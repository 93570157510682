import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { throwError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

const CIU_URL = environment.CIU_URL;

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  handleError(error: HttpErrorResponse): any{
    return throwError(error);
  }

   /**
   * @author Daniel Martinez
   * @createdate 2021-02-02
   * Servicio que lista todos los groupes del aplicativo ciu
   * @param size tamaño de los datos a traer
   * @param page numero de pagina en la que se ubicara la tabla
   */
  getGroups(size: number, page: number): Observable<any>{
    return this.http.get<any>(`${CIU_URL}groups?n=` + size + `&page=` + page)
    .pipe(
      catchError(this.handleError)
      );
  }

   /**
   * @author Jose Vicente Silva Rivera
   * @createdate 2021-02-02
   * Servicio que lista todos los groupes del aplicativo por campaña
   * @param size tamaño de los datos a traer
   * @param page numero de pagina en la que se ubicara la tabla
   */
  getGroupsByCampaign(idCampaign: number): Observable<any>{
    return this.http.get<any>(`${CIU_URL}groupByCampaign/?campaign_id=${idCampaign}`)
    .pipe(
      catchError(this.handleError)
      );
  }
  /**
   * @author Javier Castañeda
   * Método para obtener los grupos
   */
  getAllGroups(){
    return this.http.get<any>(`${CIU_URL}list/groups`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-03
   * Servicio que realiza una busqueda avanzada de groupes por un valor
   * @param size tamaño de los datos a traer
   * @param value valor a buscar
   */
  searchGroup(size: number, value: any): Observable<any>{
    return this.http.get<any>(`${CIU_URL}groups/search?n=` + size + `&q=` + value)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-03
   * Servicio que guarda un group en su respectiva tabla
   * @param group group a guardarse
   */
  saveGroup(group: any): Observable<any>{
    
    return this.http.post<any>(`${CIU_URL}groups`, group)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Servicio que edita un group en su respectiva tabla
   * @param group group a editarse
   */
  editGroup(id: any, group: any): Observable<any>{
    return this.http.put<any>(`${CIU_URL}groups/${id}`, group)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Servicio que borra un group en su respectiva tabla
   * @param idgroup id del group a eliminarse
   */
  deleteGroup(idgroup: any): Observable<any>{
    return this.http.delete<any>(`${CIU_URL}groups/${idgroup}`)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Nicoll Ramirez
   * Método para descargar el reporte general de los grupos
   */
  downloadReport(): Observable<any>{
    const httpOptions = {responseType: 'blob' as 'json'};
    return this.http.get<any>(`${CIU_URL}groupsReport`,httpOptions)
    .pipe(
      catchError(this.handleError)
      );
  }
  /**
   * @author Nicoll Ramirez
   * Método para descargar el reporte de un grupo especifico
   */
  downloadGroup(id): Observable<any>{
    const httpOptions = {responseType: 'blob' as 'json'};
    return this.http.get<any>(`${CIU_URL}groupReport/${id}`,httpOptions)
    .pipe(
      catchError(this.handleError)
      );
  }

  /**
   * @author Javier Castañeda
   * Método para obtener los usuarios de un grupo
   */
  getUsersByGroup(groupId: any){
    return this.http.get<any>(`${CIU_URL}getUserByGroupToEdit/${groupId}`);
  }
}
