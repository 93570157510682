import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { MatrizService } from 'src/app/modules/calidad/services/matriz.service';
import { ReportsService } from 'src/app/modules/calidad/services/reports.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import Swal from 'sweetalert2';
import { AnalistasCalidadComponent } from '../analistas-calidad/analistas-calidad.component';
import { CrearMatrizComponent } from '../crear-matriz/crear-matriz.component';
import { EditarMatrizComponent } from '../editar-matriz/editar-matriz.component';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
	selector: 'app-main-matrices',
	templateUrl: './main-matrices.component.html',
	styleUrls: ['./main-matrices.component.sass']
})
export class MainMatricesComponent implements OnInit, OnDestroy {
  private uuid = 'b27d928e-9092-11ee-b9d1-0242ac120002';
  public conf: any;

	//Filtros
	filtroMatriz: string = '';
	filtroFecha = new FormGroup({
		fechaInicial: new FormControl(),
		fechaFinal: new FormControl(),
	});
	filtroTipo_matriz: string = '';
	filtroCampaign: string = '';
	idMatriz;

	auditar: boolean = false;
	control: boolean = false;
	jefe: boolean = false;
	reports: boolean = false;

	//Control de paginación de la tabla
	length;
	pageSize = 10;
	page = 1;
	pageSizeOptions: number[] = [10, 25, 100];
	@ViewChild(MatPaginator) paginator: MatPaginator;
	endText: number;

	dataSource: [] = [];
	tiposMatrices = [];
	campaigns = [];

	//Observable para los cambios de la tabla
	subscription: Subscription;
	constructor(
		private dialog: MatDialog,
		private router: Router,
		private matrizService: MatrizService,
		private authService: AuthService,
		private reportService: ReportsService,
    private configService: TranslatesService,
	) { }

	ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.conf = resp;
    });
		this.getTypeMatrix();
		this.getAllMatrix(this.page, this.pageSize);
		this.subscription = this.matrizService.refresh$.subscribe(() => {
			this.getAllMatrix(this.page, this.pageSize);
		});
		this.validateRoute();
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	newMatrix() {
		const matrizRef = this.dialog.open(CrearMatrizComponent, {
			width: '840px',
			disableClose: true,
			data: {},
		});
	}

	edit(id) {
		const editRef = this.dialog.open(EditarMatrizComponent, {
			width: '840px',
			disableClose: true,
			data: { id_matriz: id },
		});
	}

	asignarSuper(idMatriz, type) {
		const superRef = this.dialog.open(AnalistasCalidadComponent, {
			width: '732px',
			disableClose: true,
			data: {
				idMatriz,
				type
			},
		})
	}

	detailMatrix(id) {
		this.router.navigateByUrl(`/mios/calidad/matriz/detail/${id}`);
	}

	getTypeMatrix() {
		this.matrizService.getTypeMatrix().subscribe((resp: any) => {
			this.tiposMatrices = resp;
		})
		this.authService.getUser().roles.forEach(rol => {
			if (rol == 'calidad::jefe') {
				this.jefe = true;
			}
		});



	}

	getAllMatrix(page, sizePage) {

		const params = {
			page: page,
			numeroReg: sizePage,
			matriz: '',
			fechaInicial: '',
			fechaFinal: '',
			tipo_matriz: '',
			campaing: '',
		}


		//Filtros
		if (this.filtroMatriz != "") {
			params.matriz = this.filtroMatriz
		}

		if (this.filtroFecha.get('fechaInicial').value != '' && this.filtroFecha.get('fechaInicial').value != null) {
			params.fechaInicial = moment(this.filtroFecha.get('fechaInicial').value).format('yyyy-MM-DD');
		}
		if (this.filtroFecha.get('fechaFinal').value != '' && this.filtroFecha.get('fechaFinal').value != null) {
			params.fechaFinal = moment(this.filtroFecha.get('fechaFinal').value).format('yyyy-MM-DD');
		}

		if (this.filtroTipo_matriz != "") {
			if (this.filtroTipo_matriz == null) {
				params.tipo_matriz = '';
			} else {
				params.tipo_matriz = this.filtroTipo_matriz
			}
		}

		if (this.filtroCampaign != '' && this.filtroCampaign != null) {
			params.campaing = this.filtroCampaign;
		}

		this.matrizService.getAllMatrix(params).subscribe((resp: any) => {
			this.dataSource = resp.data
			this.length = resp.total
			this.pageSize = resp.per_page;
		});
		this.matrizService.getCampaings().subscribe((resp: any) => {
			this.campaigns = resp.data;
		});
	}

	disableMatrix(id, estado) {
		Swal.fire({
			icon: 'warning',
			title: this.conf.modal.disableTitle,
			showCancelButton: true,
			cancelButtonText: this.conf.modal.cancel,
			confirmButtonText: this.conf.modal.accept,
			reverseButtons: true,
			width: '810px',
			padding: '2em 7em'
		}).then(result => {
			if (result.isConfirmed) {
				this.matrizService.disableMatrix(id, estado).subscribe(() => {
					Tools.swalSuccess(this.conf.modal.excellent, this.conf.modal.disableOk)
				});
			}
		})
	}

	enableMatrix(id, estado) {
		Swal.fire({
			icon: 'warning',
			title: this.conf.modal.enableTitle,
			showCancelButton: true,
			cancelButtonText: this.conf.modal.cancel,
			confirmButtonText: this.conf.modal.accept,
			reverseButtons: true,
			width: '810px',
			padding: '2em 7em'
		}).then(result => {
			if (result.isConfirmed) {
				this.matrizService.disableMatrix(id, estado).subscribe(() => {
					Tools.swalSuccess(this.conf.modal.excellent, this.conf.modal.enableOk)
				});
			}
		})
	}

	pageEvent(event: any) {
		this.pageSize = event.pageSize;
		this.page = event.pageIndex + 1;
		this.getAllMatrix(this.page, this.pageSize);
	}

	filtroFechas() {
		if (this.filtroFecha.get('fechaInicial').value != null && this.filtroFecha.get('fechaFinal').value != null) {
			this.filtrar();
		}
	}

	getReportGeneral(tipo_reporte?, tipo_matriz?) {
		const params = {
			fechaInicial: '',
			fechaFinal: '',
			tipoMatriz: tipo_matriz,
			tipoReporte: '',
			nombre: '',
			idMatriz: '',
			campaing: '',
		}

		if (this.filtroMatriz != '') {
			params.nombre = this.filtroMatriz;
		}

		if (this.idMatriz != '' && this.idMatriz) {
			params.idMatriz = this.idMatriz;
		}

		if (this.filtroFecha.get('fechaInicial').value != '' && this.filtroFecha.get('fechaInicial').value != null) {
			params.fechaInicial = moment(this.filtroFecha.get('fechaInicial').value).format('yyyy-MM-DD');
		}
		if (this.filtroFecha.get('fechaFinal').value != '' && this.filtroFecha.get('fechaFinal').value != null) {
			params.fechaFinal = moment(this.filtroFecha.get('fechaFinal').value).format('yyyy-MM-DD');
		}

		if (this.filtroTipo_matriz != '') {
			params.tipoMatriz = this.filtroTipo_matriz;
		}

		if (tipo_reporte != null && tipo_reporte != '') {
			params.tipoReporte = tipo_reporte;
		}

		if (this.filtroCampaign != '' && this.filtroCampaign != null) {
			params.campaing = this.filtroCampaign;
		}
		this.reportService.getReportGeneral(params).subscribe((resp: any) => {
			Tools.downloadFile(resp, 'Auditoria_' + moment(new Date).format('DD_MM_yyy_hh_mm_ss'));
			this.idMatriz = null;
		});
	}

	getReportById(id: number, tipo) {
		this.idMatriz = id;
		this.getReportGeneral('', tipo);
	}

	filtrar() {
		this.paginator.firstPage();
		this.getAllMatrix(this.page, this.pageSize);
	}

	validateRoute() {
		if (this.router.url == '/mios/calidad/auditorias') {
			this.auditar = true;
		} else {
			this.auditar = false;
		}
		if (this.router.url == '/mios/calidad/control') {
			this.control = true;
		} else {
			this.control = false;
		}
		if (this.router.url == '/mios/calidad/reporteria') {
			this.reports = true;
		} else {
			this.reports = false;
		}
		if (window.screen.width >= 1920) {
			this.endText = 30;
		} else {
			this.endText = 15;
		}
	}

	detailAuditar(idMatriz) {
		this.router.navigateByUrl(`/mios/calidad/auditoria/detail/0/${idMatriz}`);
	}

	detailControl(idMatriz) {
		this.router.navigateByUrl(`/mios/calidad/control/detail/${idMatriz}`);
	}

	descargaActualizaciones(idMatriz) {
		this.matrizService.descargaActualizaciones(idMatriz).subscribe((resp: any) => {
			Tools.downloadFile(resp, 'Trazabilidad_' + moment(new Date).format('DD_MM_yyyy_hh_mm'))
		});
	}

	/**
	 * Metodo para eliminacion de matrices
	 * @author Juan David Guerrero Vargas
	 * @create_date 01/06/22
	 * @param matriz:any Objeto con la informaicon de la matriz seleccionada
	 * @return void
	 */
	eliminarMatriz(matriz: any): void {

		Swal.fire({
			title: this.conf.modal.deleteTitle,
			icon: 'warning',
			html: this.conf.modal.deleteText,
			showDenyButton: true,
			confirmButtonColor: '#00acc1',
			confirmButtonText: this.conf.modal.accept,
			denyButtonText: this.conf.modal.cancel,
		}).then(
			(result) => {
				if (result.isConfirmed) {
					this.matrizService.deleteMatrix(String(matriz.matriz.id)).subscribe(
						(resp: any) => {
							if (resp.respuesta.codigo == 400) {
								Swal.fire({
									icon: 'warning',
									title: this.conf.modal.sorry,
									html: `<p> ${resp.respuesta.text}</p>`,
									showConfirmButton: false,
									timer: 4500
								})

							}else{
								this.getAllMatrix(this.page, this.pageSize);
								Swal.fire({
									icon: 'success',
									title: this.conf.modal.excellent,
									html: this.conf.modal.deleteOk,
									showConfirmButton: false,
									timer: 4500
								})
							}
						}
					);
				}
			}
		);



	}

}
