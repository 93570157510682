import {  Directive,  Output, EventEmitter, HostBinding, HostListener} from '@angular/core';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();


  /**
   * Método para gestionar el evento 'dragover' cuando se está arrastrando un archivo sobre el elemento.
   * @author Yeison Sepulveda
   * @createdate 2024-05-05
   * @param $event El evento de soltar archivos.
  */
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  /**
   * Método para gestionar el evento 'dragleave' cuando se está saliendo del área de arrastre.
   * @author Yeison Sepulveda
   * @createdate 2024-05-05
   * @param $event El evento de soltar archivos.
  */
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  /**
   * Método para gestionar el evento 'drop' cuando se suelta un archivo arrastrado sobre el elemento.
   * @author Yeison Sepulveda
   * @createdate 2024-05-05
   * @param $event El evento de soltar archivos.
  */
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}