<div id="landing-page">
    <div id="intro"> 
        <section id="text-content">
            <p id="role">Gestionador CRM</p>
            <b id="title">¡Bienvenid@ <br>{{userName}}!</b>
            <p id="info">En el módulo de <strong>CRM</strong> podrás administrar<br>
                toda la gestión, crear formularios y ver el<br>
                dashboard de estado de las campañas.</p>
    
            <!-- <button id="onboarding-button" mat-stroked-button color="primary">Quiero ver el onboarding</button> -->
        </section>
    
        <section id="image-content">
            <div id="image"></div>
        </section>
    </div>

    <!-- <div id="news">
        <div id="title">
            <h3>Noticias</h3>
        </div>
        <div id="new-cards">
            <mat-card class="new"></mat-card>
            <mat-card class="new"></mat-card>
            <mat-card class="new"></mat-card>
            <mat-card class="new"></mat-card>
        </div>
    </div> -->
    
</div>