import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/rest/auth.service';

@Component({
  selector: 'app-speech-analytics',
  templateUrl: './speech-analytics.component.html',
  styleUrls: ['./speech-analytics.component.sass']
})
export class SpeechAnalyticsComponent implements OnInit {

  nameUser: string = '';
  constructor(
    private authService: AuthService) 
    { }

  ngOnInit() {
    const user = this.authService.getUser();
    this.nameUser = user.rrhh.name;
  }

}
