import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NetworkUsersService } from 'src/app/modules/ciu/services/rest/network-users.service';
import { UpdateNetworkUserFormComponent } from './dialogs/update-network-user-form/update-network-user-form.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.sass']
})
export class UsersListComponent implements OnInit {

  data:any = new MatTableDataSource<any>()
  tableColumns: string[] = ['actions', 'name', 'document', 'network_user', 'updated_at']
  //Paginador
  length: number
  pageSize: number = 5
  pageSizeOptions: number[] = [5, 10, 50, 100]
  page: number = 1
  //Formulario de filtros
  filter: FormControl = new FormControl('', [])
  now:Date = new Date()

  @Input() tab_type: number
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private formBuilder:FormBuilder, private networkUserService:NetworkUsersService, private dialog:MatDialog) { }

  ngOnInit(): void {
    this.getInfo(this.page, this.pageSize)
  }


  /**
  * Metódo que da funcionalidad a la paginación
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 15-05-2024
  */
    paginatorEvent(event: any):void {
      this.pageSize = event.pageSize
      this.page = event.pageIndex + 1
      this.getInfo(this.page, this.pageSize)
    }

  getInfo(page:number, pageSize:number, filter:string = ''):void{
    let params = `page=${page}&per_page=${pageSize}`
    if(filter){
      params = params + `&filter=${filter}`
    }
    this.networkUserService.getUsersFilter(params).subscribe((resp) => {
      if (typeof resp.data.data !== 'undefined') {
        this.data = new MatTableDataSource(Object.values(resp.data.data));
      }else{
        this.data.data = [];
      }
      this.data.sort = this.sort;
      this.length = resp.data.total;
      this.pageSize = resp.data.per_page;
    })
  }

  filterInfo(value:string):void {
    this.getInfo(1, 5, value)
  }


   /**
  * Metódo que despliega el formulario de actualización
  * @param event
  * @author Juan Carlos Alonso
  * @createdate 19-09-2024
  */
   formDialog(id:number, network_user:string) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.id = 'dialogUpdateNetworkUser'
    dialogConfig.width = '50%'
    dialogConfig.height = 'auto'
    dialogConfig.data = {
      id: id,
      network_user: network_user
    }

    const dialogRef = this.dialog.open(UpdateNetworkUserFormComponent, dialogConfig)
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if(result.created){
          Swal.fire({
            icon: 'success',
            title: '¡Felicidades!',
            text: 'Se ha actualizado el usuario de red con éxito.',
            showConfirmButton: false,
            timer: 3000
          });
        }
        this.getInfo(this.page, this.pageSize);
      }
    });

 }
}
