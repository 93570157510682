import { Component, OnInit, Inject, Optional, EventEmitter, Output } from '@angular/core';
import { ResourcesService } from '../../../services/resources.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as moment from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { BookingService } from '../../../services/booking.service';

@Component({
  selector: 'app-booking-create',
  templateUrl: './booking-create.component.html',
  styleUrls: ['./booking-create.component.sass']
})
export class BookingCreateComponent implements OnInit {
  typeResource        : number|string;
  nameResource        : string='';
  today               = new Date();
  date                = new Date();
  selectedTab
  numberDay           = new Date(this.today ).getDay();
  dates               : any=[]
  back                : boolean= false
  private isDialog    : boolean = false;

  booking={
    resourceId:'',
    rrhhId: this.auth.getUser().rrhh_id,
    dateStart:'',
    dateEnd:''
  }
  week =[
    {name:'Lunes', number:'' },
    {name:'Martes', number:''},
    {name:'Miércoles', number:''},
    {name:'Jueves', number:''},
    {name:'Viernes', number:''},
    {name:'Sábado', number:''},
    {name:'Domingo', number:''},
  ]


  formattedDate:any
  constructor(
    private resourcesService    : ResourcesService,
    private dialog              : MatDialog,
    private route               : ActivatedRoute,
    public alertsService        : AlertsService,
    public auth                 : AuthService,
    private bookingService      : BookingService,
    public router               : Router,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() private dialogRef: MatDialogRef<BookingCreateComponent >
    ) { }

  ngOnInit(): void {
    if ( this.data !== null ){
      this.typeResource = this.data.id;
      this.nameResource = this.data.type;
      this.isDialog = true;
    }
    else
    {
      this.route.paramMap.subscribe(params => {
        this.typeResource = params.get('id');
        this.nameResource = params.get('type');
      });
    }

    this.numberDay = new Date(this.today).getDay() - 1;
    this.selectedTab = this.numberDay
    this.formattedDate =  moment(this.today).format('DD-MM-YYYY');
    this.resourcesService.getResources('resources/availabilities/range', {typeId:this.typeResource, date:  this.formattedDate}).subscribe(res=>{
      this.dates = res
      if(this.dates[0].date ==  this.formattedDate){
        this.back = true
      }
      else{
        this.back = false
      }
      let count = 0
      this.week.forEach((element, index) => {
        element['data']=0
        if(index >= this.numberDay){
          let date                = res[count].date.split('-');
          element['data']         = res[count];
          element.number          = date[0];
          element['cant_disp']    = element['data'].resources.reduce((prev ,a) =>  prev + a.cant_avaiable , 0  );
          count++;


        }
      });



    })


  }

  makeBooking(type, date){
    this.resourcesService.getResources(`resource/availabilities/${type}`, {date: date}).subscribe((res:any)=>{

      const resourceDialog = this.dialog.open( ReserveResource, {
        width: '25%',
        data: {name: res.name, hours:res.hours, type:res.type, date: date , isCrm: this.isDialog }
      });
      resourceDialog.afterClosed().subscribe(resp=>{
        if(resp){
           let saveconfirm= this.alertsService.alertConfirm(` ${res.type.name} - ${res.name} el ${moment(resp[0].showTime).format('DD/MM/YYYY hh:mm') }. ¿Seguro que desea reservar este día y a esta hora?`)
           saveconfirm.then(asw=>{
            if(asw.isConfirmed){
                resp.forEach( r => {
                  this.createBooking(type, r.showTime, res.type.booking_time)
                });
              }
          })

        }
      })
    })

  }
  createBooking(type, start, time){

    this.booking.resourceId= type
    let dateStart = moment(start).format('YYYY-MM-DD HH:mm')
    let dateEnd = moment(start).add( time, 'minutes').format('YYYY-MM-DD HH:mm');
    this.booking.dateStart= dateStart
    this.booking.dateEnd = dateEnd
    this.bookingService.postBooking('booking', this.booking).subscribe(res=>{


      this.alertsService.alertSuccess('¡Reservado!','Espere la confirmación de su reserva')

      //Cierra el modal si el componente está en uno, si no, redirecciona....
      if( this.isDialog ){
        this.dialogRef.close(start);

      }
      else
      {
        this.router.navigate([`/mios/reservas/mis-reservas`])

      }


    })
  }
  paginateWeek(action){

    let date = moment(this.dates[this.dates.length-1].date,'DD-MM-YYYY');
    let start
    switch (action) {
      case 'add':
        start = date.add(1, 'day');
        break;
      case 'subtract':
        start = date.subtract(2,'week').startOf('week').add(1, 'day');

    }
    this.resourcesService.getResources('resources/availabilities/range', {typeId:this.typeResource, date: start.format('DD-MM-YYYY')}).subscribe(res=>{
      this.dates = res

      let found = this.dates.find(element=>element.date ==  this.formattedDate)

      if(found){
        this.back = true
        this.selectedTab = this.numberDay;
      }else  {this.back = false, this.selectedTab=0}
        this.week.forEach((element, index) => {
            element['data']         = 0;
            let date                = res[index].date.split('-');
            element['data']         = '';
            element['data']         = res[index];
            element.number          = date[0];
            element['cant_disp']    = element['data'].resources.reduce((prev ,a) =>  prev + a.cant_avaiable , 0  );
        });
    })
  }

}


@Component({
  selector: 'reserve-resource',
  templateUrl: './reserve-resource.html',
  styleUrls: ['./booking-create.component.sass']
})
export class ReserveResource {
  hours         : any=[];
  isContinuos   = true;

  constructor(
    private dialogRef: MatDialogRef<ReserveResource>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {}


    checkContinuo(list){
      //Reccorre el arrego y filtra los índices seleccionados
      let lasItemSelItem = null;
      this.isContinuos = true;

      const isContinuo = list.options.forEach( (v , i  ) => {
        if (v.selected ){
          if( lasItemSelItem !== null && i - lasItemSelItem  > 1 ){
            this.isContinuos = false;
            return;
          }
          lasItemSelItem = i;
        }
      } );












    }


}

