import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { CampaignService } from '../../../services/rest/campaign.service';
import { GroupsService } from '../../../services/rest/groups.service';
import { startWith, map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ScheduleTypesService } from '../../../services/rest/schedule-types.service';
import { ConfigService } from '../../../services/rest/config.service';
import { Configs } from '../../class/configs';
import { MatTableDataSource } from '@angular/material/table';
import { ScheduleType } from '../../class/schedule-type';
import { ConfigSchedule } from '../../class/config-schedule';
import { SchedulesService } from '../../../services/rest/schedules.service';
import { DestroyComponentService } from '../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { UsersService } from '../../../services/rest/users.service';
import { DateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { saveAs as importedSaveAs } from 'file-saver';
import { AuthService } from '../../../../../core/services/rest/auth.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-config-schedules',
  templateUrl: './config-schedules.component.html',
  styleUrls: [ './config-schedules.component.sass' ]
})
export class ConfigSchedulesComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoCompleteScCreate: MatAutocompleteTrigger;

  filterForm: FormGroup;
  campaigns: any;
  groups: any;
  rangeDates: any = [];
  filteredOptionsCampaigns: Observable<any[]>;
  filteredOptionsGroups: Observable<any[]>;
  filteredOptionsRanges: Observable<any[]>;
  configGrid = new Configs();
  botonesCarga = false;
  Consultados = false;
  archivoSeleccionado = false;
  private to: any;
  private from: any;
  public rangeDateInit: string;
  public rangeDateEnd: string;

  public file: any; //archivo

  displayedColumns: string[] = [ 'Tipo', 'Observaciones', 'Cantidad' ];
  dataSource = new MatTableDataSource<any>();

  constructor(
    private campaignService: CampaignService,
    private destroyService: DestroyComponentService,
    private groupService: GroupsService,
    private scheduleTypeService: ScheduleTypesService,
    private configService: ConfigService,
    private datePipe: DatePipe,
    private scheduleService: SchedulesService,
    private alertsService: AlertsService,
    private authService: AuthService,
    private userService: UsersService,
    private adapter: DateAdapter<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  get error(): any {
    return this.filterForm.controls;
  }


  ngOnInit(): void {
    this.adapter.setLocale('co');
    this.formControl();
    this.getWeekRanges();
    this.consultarGrupos();
    this.campaignService.getCampaigns().subscribe((resp) => {
      this.campaigns = resp.data;
      this.setEdit();
    });
  }

  /**
   * Almacena las fechas en la seleccion de una de ellas
   * @param e event con valor
   * @author Andres Buitrago
   */
  onChange(e): void {
    const index = this.rangeDates.findIndex(resp => resp.id === e.option.value);
    this.rangeDateInit = this.rangeDates[index].from ?? null;
    this.rangeDateEnd = this.rangeDates[index].to ?? null;
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que setea los valores al momento de editar
   */
  setEdit(): void {
    if (this.data.type === 'editar') {
      this.filterForm.controls.campaigns.setValue(this.data.rol.name);
    }
  }

  /**
   * @author Jose Silva
   * @createdate 2021-02-09
   * Metodo donde se establecen las validaciones del formulario
   */
  formControl(): void {

    this.filterForm = new FormGroup({
      rangeDate: new FormControl('', [ this.autocompleteObjectValidator(), Validators.required ]),
      groups: new FormControl('', [ this.autocompleteObjectValidator(), Validators.required ]),
      upload: new FormControl('')
    });
  }

  /**
   * @author Jose Silva
   * @createdate 2021-02-09
   * Metodo que valida las funciones del autocompetable
   */
  autocompleteObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string') {
        return { invalidAutocompleteObject: { value: control.value } };
      }
      return null;
    };
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * filtra por nombre, se usa para el autocompletable
   * @param value valor a filtrar
   */
  private _filterCampaigns(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.campaigns.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * filtra por nombre, se usa para el autocompletable
   * @param value valor a filtrar
   */
  private _filterGroups(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.groups.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * filtra por nombre, se usa para el autocompletable
   * @param value valor a filtrar
   */
  private _filterRanges(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.rangeDates.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   */
  displayFnCampaigns(id: number): string {
    if (!id) {
      return '';
    }
    let index = this.campaigns.findIndex(resp => resp.id === id);
    return this.campaigns[index].name;
  }

  /**
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   * @author Daniel Martinez
   * @createdate 2021-02-04
   */
  displayFnGroups(id: number): string {
    if (!id) {
      return '';
    }
    let index = this.groups.findIndex(resp => resp.id === id);
    return this.groups[index].name;
  }

  /**
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   * @author Daniel Martinez
   * @createdate 2021-02-04
   */
  displayFnRanges(id: any): string {
    if (!id) {
      return '';
    }
    const index = this.rangeDates.findIndex(resp => resp.id === id);
    return this.rangeDates[index].name;
  }

  /**
   * Metodo encargado de realizar la consulta de grupos por campaña seleccionada
   * @author Jose silva
   */
  public consultarGrupos(): void {
    this.dataSource = new MatTableDataSource<any>();
    this.filterForm.patchValue({
      groups: ''
    });

    this.groupService.getAllGroups().subscribe((response) => {
      this.groups = response.data;
      this.filteredOptionsGroups = this.filterForm.get('groups').valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(x => x ? this._filterGroups(x) : this.groups.slice())
      );
    });
  }

  /**
   * Generacion de semanas
   * @author Daniel Dominguez 
   */
  public getWeekRanges() {
    this.filterForm.patchValue({
      rangeDate: ''
    });
    const to = moment().format('YYYY-MM-DD');
    const numWeeks = 52;
    this.scheduleService.getWeeksRangesForScheduleFilters({ to, numWeeks })
      .subscribe(resp => {
        this.rangeDates = resp.data;
        this.filteredOptionsRanges = this.filterForm.get('rangeDate').valueChanges.pipe(
          startWith(''),
          map(value => {
            return typeof value === 'string' ? value : value.name;
          }),
          map(x => {
            return x ? this._filterRanges(x) : this.rangeDates.slice();
          })
        );
        setTimeout(() => {
          this.inputAutoCompleteScCreate.openPanel();
        }, 700);
      });
  }

  /**
   * Metodo encargado de realizar la consulta de configuracion semanal
   * @author Jose silva
   */
  public findConfigs(): void {
    this.Consultados = true;
    this.configGrid = new Configs();
    //Data de los filtros
    let data = {
      rangeDate: this.datePipe.transform(this.rangeDateInit, 'yyyy-MM-dd'),
      groups: this.filterForm.value.groups
    };
    //Creacion de array de tipos
    let dbTypes = Array<ScheduleType>();
    //Consulto los tipos
    this.scheduleTypeService.getScheduleTypes().subscribe((types) => {
      //Asigno la respuesta a los tipos
      dbTypes = types.data;
      console.log(dbTypes);
      //Comnulto los filtros
      this.configService.getConfigByDateGroup(data).subscribe((res) => {
        if (res.data) {
          this.botonesCarga = true;
          let config_schedule = Array<ConfigSchedule>();
          this.configGrid.date_start = res.data.date_start;
          this.configGrid.date_end = res.data.date_end;
          this.configGrid.name = res.data.name;
          this.configGrid.group_id = res.data.group_id;
          this.configGrid.id = res.data.id;
          dbTypes.forEach(type => {
            console.log(type);
            let newConfigSchedule = new ConfigSchedule();
            let existConfigSchedule = res.data.config_schedule.filter(x => x.schedule_type_id == type.id);
            console.log(existConfigSchedule);
            if (existConfigSchedule.length > 0) {
              newConfigSchedule.id = existConfigSchedule[0].id;
              newConfigSchedule.schedule_type_id = existConfigSchedule[0].schedule_type_id;
              newConfigSchedule.config_id = res.data.id;
              newConfigSchedule.name = type.name;
              newConfigSchedule.key = type.key;
              newConfigSchedule.quantity = existConfigSchedule[0].quantity;
              newConfigSchedule.editable = type.extra.editable ?? 0;
              newConfigSchedule.open_dial = type.open_dial ?? 0;
              config_schedule.push(newConfigSchedule);
            } else {
              newConfigSchedule.schedule_type_id = type.id;
              newConfigSchedule.config_id = res.data.id;
              newConfigSchedule.name = type.name;
              newConfigSchedule.key = type.key;
              newConfigSchedule.quantity = type.extra.quantity;
              newConfigSchedule.editable = type.extra.editable ?? 0;
              newConfigSchedule.open_dial = type.open_dial ?? 0;
              config_schedule.push(newConfigSchedule);
            }

          });
          this.configGrid.config_schedule = config_schedule;
        } else {
          this.botonesCarga = false;
          // configuracion predeterminados de un cargue de mallas horarias / MH-68 (Horarios)
          this.configGrid.date_start = this.rangeDateInit;
          this.configGrid.date_end = this.rangeDateEnd;
          this.configGrid.name = 'Semana del ' + this.rangeDateInit + ' al ' + this.rangeDateEnd;
          this.configGrid.group_id = this.filterForm.value.groups;
          dbTypes.forEach(type => {
            let newConfigSchedule = new ConfigSchedule();
            newConfigSchedule.schedule_type_id = type.id;
            newConfigSchedule.name = type.name;
            newConfigSchedule.key = type.key;
            newConfigSchedule.quantity = type.extra.quantity ?? 1;
            newConfigSchedule.editable = type.extra.editable ?? 0;
            newConfigSchedule.open_dial = type.open_dial ?? 0;
            this.configGrid.config_schedule.push(newConfigSchedule);
          });
        }
        this.dataSource.data = this.configGrid.config_schedule;
      });
    });
  }

  /**
   * Metodo encargado de realizar la actualizacion de la cantidad del tipo de horario
   * @author Jose vicente silva
   */
  public updateValue(event, row): void {
    this.configGrid.config_schedule.filter(x => x.schedule_type_id == row.schedule_type_id)[0].quantity = event.target.value;
  }

  /**
   * Metodo encargado de realizar el guardado o actualizacion de una config semanal de horarios
   * @author Jose vicente silva
   */
  public saveConfig(): void {
    if (this.configGrid.id !== undefined) {
      this.configService.patchConfigs(this.configGrid).subscribe(res => {

        this.alertsService.alertSuccess('Guardado', res.data);

      });
    } else {
      this.configService.postConfigs(this.configGrid).subscribe(res => {
        this.botonesCarga = true;

        this.configGrid.id = res.id;
        this.alertsService.alertSuccess('Guardado', res.data);

      });
    }
  }


  /**
   * Metodo encargado de subir el archivo para realizar el cargue de horarios
   * @author Andres Buitrago
   */
  cargarArchivoBase(e): void {
    this.file = e.target.files[0];
    const validExt = [ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel' ];
    if (validExt.indexOf(this.file.type) === -1) {
      this.alertsService.alertError('Error', 'La extensión del archivo no es valida, solo se permiten archivos excel');
      return;
    }

    const confirm = this.alertsService.alertConfirm('¿Esta seguro que desea guardar el archivo?');
    confirm.then(res => {
      if (res.isConfirmed) {
        const currentDate = moment().format('YYYY-MM-DD');
        const formData = new FormData();
        formData.append('upload', this.file);
        formData.append('groupId', this.filterForm.value.groups);
        formData.append('toCreate', '1');
        formData.append('startWeek', moment(this.rangeDateInit).format('YYYY-MM-DD'));
        formData.append('endWeek', moment(this.rangeDateInit).isoWeekday(7).format('YYYY-MM-DD'));

        this.scheduleService.updateScheduleSelected(formData)
          .subscribe(resp => {
            e.srcElement.value = null;
            if (resp.data.possibleErrors.length === 0) {
              this.alertsService.alertSuccess('¡Archivo cargado!', `
              <h4><b>Nota:</b></h4>
              <p>
              <b>Archivo subido por:</b> ${ this.authService.getUser().rrhh.name ?? 'NA' } <br>
              <b>Fecha: </b> ${ currentDate }
              </p>
            `);
            } else {
              let buildMessages = '';
              for (const property in resp.data.possibleErrors) {
                if (resp.data.possibleErrors.hasOwnProperty(property)) {
                  buildMessages += resp.data.possibleErrors[property];
                }
              }
              this.alertsService.alertWarning('Proceso realizado', `
              <h4><b>Nota:</b></h4>
              <p>
              <b>Archivo subido por:</b> ${ this.authService.getUser().rrhh.name ?? 'NA' } <br>
              <b>Fecha: </b> ${ currentDate } <br><br>
              <b>Inconvenientes presentados: </b><br> ${ buildMessages }
              </p>
            `);
            }
          }, error => {
            e.srcElement.value = null;
            this.alertsService.alertError('Error', 'Ocurrio un error al intentar actualizar masivamente');
          });
      }
    });
  }

  /**
   * Metodo encargado de generar el archivo para el cargue de horarios en base a filtro seleccionados
   * @author Andres Buitrago
   */
  descargarArchivoBase(): void {
    const date =
      this.scheduleService.getScheduleFileToEdit({
        groupId: this.filterForm.value.groups,
        startWeek: moment(this.rangeDateInit).format('YYYY-MM-DD'),
        endWeek: moment(this.rangeDateInit).isoWeekday(7).format('YYYY-MM-DD'),
        toCreate: true
      }).subscribe(resp => importedSaveAs(resp, 'cargue_horarios.xlsx'));
  }

  /**
   * Metodo encargado de omitir caracteres especiales
   * @param event
   * @author Daniel martinez
   */
  omitSpecialChar(event): any {
    let k;
    k = event.charCode;
    return ((k > 255 && k < 255));
  }

  /**
   * Metodo destroy -> ciclo de vida del componente
   * @author Jose vicente silva
   */
  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }


}
