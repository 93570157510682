import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatrizService } from 'src/app/modules/calidad/services/matriz.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
  selector: 'app-editar-item',
  templateUrl: './editar-item.component.html',
  styleUrls: ['./editar-item.component.sass']
})
export class EditarItemComponent implements OnInit {
  private uuid = '9dd3ecb0-9156-11ee-b9d1-0242ac120002';
  public config: any;

  itemForm:FormGroup;
  items = [];
  filteredOptions: Observable<any[]>;
  verError:boolean = true;
  continue:boolean = true;
  nuevo:boolean = false;
  peso:boolean = false;
  descripcion:boolean = true;
  mostarDescripciones:boolean = true;
  constructor(
    public itemRef:MatDialogRef<EditarItemComponent>,
    @Inject(MAT_DIALOG_DATA) public datos:any,
    private fb:FormBuilder,
    private matrizService:MatrizService,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
    this.createForm();
    this.getItemById();
    console.log(this.datos);

  }

  createForm(){
    this.itemForm = this.fb.group({
      nombre:['',Validators.required],
      peso:['',[Validators.required]],
      descripcion:[''],
      aspectoPositivo:['',Validators.maxLength(100)],
      aspectoNegativo:['',Validators.maxLength(100)],
      aspectoNa:['',Validators.maxLength(100)],
      aspectoCp: ['', Validators.maxLength(100)]
    })
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.items.filter(option => option.nombre.toLowerCase().includes(filterValue));
  }

  displayFn(module: any): string {
    return module && module ? module : '';
  }

  getItems(){
    this.matrizService.getItems(this.itemForm.get('nombre').value).subscribe((resp:any)=>{
      if (resp.length == 0) {
        this.verError = true
        this.itemForm.get('nombre').setErrors({invalid:true});
        this.nuevo = true;
        this.peso = false;
        this.descripcion = false;
        this.mostarDescripciones = false;
        this.continue = false;
      }else{
        this.newItem();
        this.items = resp
        this.filteredOptions = this.itemForm.get('nombre').valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      }
    })
  }

  newItem(){
    this.nuevo = false;
    this.itemForm.get('nombre').setErrors(null);
    if (this.datos.tipo_matriz == "Cumplimiento") {
      this.peso = false
      this.itemForm.get('peso').setValue(null);
      this.itemForm.get('peso').setErrors(null);
      this.itemForm.get('peso').clearValidators();
    }else{
      if (this.datos.tipo_peso == 'Peso en Items') {
        if (this.datos.error_critico) {
          this.peso = false;
          this.itemForm.get('peso').setValue(null);
          this.itemForm.get('peso').setErrors(null);
          this.itemForm.get('peso').clearValidators();

        } else {
          this.peso = true;
        }
      }else{
        this.peso = false;
        this.itemForm.get('peso').setValue(null);
        this.itemForm.get('peso').setErrors(null);
        this.itemForm.get('peso').clearValidators();

      }
    }
    this.descripcion = true;
    this.continue = true;
  }

  getItemById(){
    this.matrizService.getModuleById(this.datos.id_item).subscribe((resp:any)=>{
      this.itemForm.setValue({
        nombre: resp.nombre,
        peso: resp.peso,
        descripcion: resp.descripcion,
        aspectoPositivo: resp.aspectoPositivo,
        aspectoNegativo: resp.aspectoNegativo,
        aspectoNa: resp.aspectoNa,
        aspectoCp: resp.aspectocp
      });
      if (this.datos.tipo_peso == 'Peso en Items') {
        if (this.datos.error_critico) {
          this.peso = false;
          this.itemForm.get('peso').setValue(null);
          this.itemForm.get('peso').setErrors(null);
          this.itemForm.get('peso').clearValidators();

        } else {
          this.peso = true;
        }
      }else{
        this.peso = false
        this.itemForm.get('peso').setValue(null);
        this.itemForm.get('peso').setErrors(null);
        this.itemForm.get('peso').clearValidators();

      }
    });
  }

  updateItem(){
    if (this.itemForm.invalid) {
      return
    }
    const data = {
      idModulo: this.datos.id_item
    }
    Object.assign(data,this.itemForm.value);
    this.matrizService.updateItem(data).subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202) {
        Tools.swalSuccess(this.config.modals.excellent, this.config.modals.text);
        this.itemRef.close();
      }
    },(err:any)=>{
      this.itemRef.close();
    }
    );
  }

}
