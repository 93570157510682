<button style="float: right; margin:-15px" mat-icon-button (click)="closeDialof()">
    <i class="fi-rr-cross"></i>
  </button>
<h3 mat-dialog-title></h3>
<hr>
<mat-dialog-content [ngSwitch]="data.action">
    <div *ngSwitchCase="'create'" class="group_actions">
        <button mat-button *ngIf="!isEdit && this.data.contract?.state != 'Aprobado'"
        (click)="editAgreements()">
            Editar reglas &nbsp;
            <span class="fi fi-rr-edit"></span>
        </button>
    </div>
    <div *ngSwitchCase="'create'">
        <div fxLayout="row" fxLayoutGap="20px" style="padding: 0 23px;">
            <mat-form-field appearance="fill" fxFlex=23>
                <mat-label>Tipo de contrato</mat-label >
                <mat-select [formControl]="myControl" (ngModelChange)="changeTypeContract($event)">
                    <mat-option *ngFor="let option of contractTypes" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex=23>
                <mat-label>Salario</mat-label>
                <input type="text" matInput [(ngModel)]="salaryAux" autocomplete="off"
                appNumber (input)="changeValue($event.target.value)" [disabled]="disabledOthes">
                <span matPrefix>$ &nbsp;</span>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex=50>
            <mat-label>Salario en letras</mat-label>
                <input matInput type="text" [(ngModel)]="fieldOthers.letters" [disabled]="disabledOthes">
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="20px" style="padding: 0 23px;">
            <h3> Politicas especiales</h3>
        </div>
       
        <form [formGroup]="formGroup" class="row" style="padding: 0 23px;">
            <div class="col-4">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Horas semanales</mat-label>
                    <input matInput type="text" formControlName="hoursPerWeek">
                </mat-form-field>
            </div>
            <div class="col-8">
                <div class="row">
                    <div class="col-6">
                        <div appearance="fill" fxFlex=50>
                            <mat-checkbox class="example-margin" (click)="hasComition()" formControlName="hasComitionFrom">¿Tiene
                                comisión?</mat-checkbox>
                        </div>
                    </div>
                    <div class="col-6">
                        <div appearance="fill" fxFlex=50 *ngIf="comition">
                            <mat-checkbox class="example-margin" (click)="hasSpecialComition()"
                                formControlName="hasSpecialComitionFrom">¿Tiene comisión especial?</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12" *ngIf="specialComition">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Detalles de la comisión especial</mat-label>
                    <textarea class="col-12" matInput placeholder="Describe los detalles de la comisión." formControlName="specialComitionDetails"></textarea>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-checkbox class="example-margin"      formControlName="comprehensiveSalary">¿Tiene salario integral?</mat-checkbox>
            </div>
            <div class="col-4">
                    <mat-checkbox class="example-margin" (click)="hasboification()" formControlName="hasBonificationFrom">¿Tiene bonos?</mat-checkbox>
            </div>
            <div class="col-12" *ngIf="boification">
                    <mat-form-field appearance="fill" style="width: 100%;">
                        <mat-label>Detalles de la bonificación</mat-label>
                        <textarea matInput placeholder="Describe los detalles de la bonificación..." formControlName="bonificationDetailsrom"></textarea>
                    </mat-form-field>
            </div>
            <div class="col-12">
                    <mat-form-field style="width: 100%;" appearance="fill">
                        <mat-label>Finalidad</mat-label>
                        <textarea matInput placeholder="Describe la finalidad..." formControlName="finalidad"></textarea>
                    </mat-form-field>
            </div>
        </form>
        <dynamic-form-2 *ngIf="seeForm" [sections]="{sections:formSection, app:'rrhh'}"
            (submitForm)="createContract($event)" (denyForm)="close($event)" ></dynamic-form-2>

    </div>
    <div *ngSwitchCase="'view'">
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="20px grid" *ngIf="this.data.agreementRules.salario">
            <div fxFlex= 33>
               <b>Tipo de contrato</b>
               <p>{{agreements.contractType.name}}</p>
           </div>
           <div fxFlex= 33>
            <b>Estado</b>
            <p *ngIf="agreements?.status?.name">{{agreements?.status?.name}}</p>
            <p *ngIf="!agreements?.status?.name">Pendiente</p>
            </div>
            <div fxFlex= 33 *ngIf="agreements.status?.comment">
                <b>Comentario del rechazo</b>
                <p>{{agreements.status.comment}}</p>
            </div>
            <div fxFlex= 33>
               <b>Salario</b>
               <p>$ {{agreements.salario | number }}</p>
           </div>
           <div fxFlex= 66>
               <b>Salario letras</b>
               <p>{{agreements['salario_letras']}}</p>
           </div>
           <div fxFlex= 33 *ngFor="let field of formSection[0].fields">
               <b>{{field.label}}</b>
               <p *ngIf="field.value.name && field.type != 'date'">{{field.value.name}}</p>
               <p *ngIf="!field.value.name && field.type != 'date'">{{field.value}}</p>
               <p *ngIf="field.type == 'date'">{{field.value | date:'dd/MM/yyyy'}}</p>
           </div>

       </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="data.action=='view' && data.isLeader && !agreements.status && data.state?.key=='Precontratado'" >
    <button class="alert" mat-stroked-button color="warn" (click)="rejectionDialog()">Rechazar</button>
    <button class="primary" mat-raised-button color="primary" (click)="agreementsStatus()">Aprobar</button>
</mat-dialog-actions>

