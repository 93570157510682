import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { QuestionBase } from 'src/app/core/models/questions-base';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { PermitService } from 'src/app/core/services/utils/permit.service';
import { QuestionControlService } from 'src/app/core/services/utils/question-control.service';
import { FormsRequestService } from 'src/app/modules/crm_NSDP/services/rest/forms-request.service';
import { storageService } from 'src/app/modules/livechat/services/storage/storage.service';
import { AlertsService } from '../../alerts/alerts.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { id } from '@swimlane/ngx-charts';

const ENCUESTAS_INTERNAS = environment.ENCUESTAS_INTERNAS;


@Component({
	selector: 'shared-dynamic-form-v4',
	templateUrl: './dynamic-form.component.html',
	styleUrls: ['./dynamic-form.component.sass']
})
export class DynamicFormV4Component implements OnInit {
	@Output() submitForm = new EventEmitter<any>();
	@Output() denyForm = new EventEmitter<any>();
	@Output() enableTemplate = new EventEmitter<any>();
	_sections: any;
	forms = new FormArray([]);
	private readonly urlStorageImg:string = ENCUESTAS_INTERNAS.replace(/api\//g,'');
	constructor(
		private qcs: QuestionControlService,
		private authService: AuthService,
		private permit: PermitService,
		private router: Router,
		private formsRequestService: FormsRequestService,
		public alertsService: AlertsService,
		private destroyService: DestroyComponentService,
		public storage: storageService,
		private changeDetector: ChangeDetectorRef,
		private _snackBar: MatSnackBar

	) { }
	@Input() dependencies;
	@Input() formId;
	@Input() files;
	@Input() template;
	@Input() showButtonsActions: boolean = true;
	Files: any = [];
	variablesTriggerLoads:any = {} //Variables Cargadas desde campos tipos API
	variablesToUploadValueAPI:any[]=[] //Campos los cuales deberan ser cargados por la respuesta de un campo tipo API
	@Input()
	public set sections(form: any) {
		if (form.app) {
			this.appOrigen = form.app
		}

		this.forms = new FormArray([]);
		this.getRolUser(form.app);
		this._sections = form.sections;



		if (this._sections) {

			this._sections.forEach((section, index) => {
				section.fields.map(
					mapp =>{
						if(mapp?.newValue !== undefined ){
							mapp['value'] = mapp.newValue
						}
						
												
						if ((mapp['value'] == undefined || mapp['value'] == "") && mapp.options.length > 0) {
							const optionDefault = mapp.options.find(finded => finded.isDefaultOption == true)
							if (mapp['controlType'] == "multiselect" && optionDefault) {
								mapp['value'] = [optionDefault.id]
							} else if (optionDefault) {
								mapp['value'] = optionDefault.id
							}

						}else if (mapp['value'] != undefined && typeof mapp['value'] === "string" &&  mapp['value'] !== "" && mapp.options.length > 0 && mapp.type !== "time"){
							mapp['value'] = Number(mapp['value'])
						}

						if (mapp.hasOwnProperty('defaultCommentValue') && mapp.hasOwnProperty('indDefaultValue')) {

							if (mapp.indDefaultValue == true) {
								mapp['value'] = mapp.defaultCommentValue
								mapp['required'] = false
							}

						}

						if (mapp.hasOwnProperty('isCalculated') && mapp.isCalculated == true) {
							if (this.activatorsCalculatefield.find(finded => finded.id == mapp.id) == undefined) {
								this.activatorsCalculatefield.push({ field: mapp, idSectionOrigin: index })
							}

						} 
						
						if(mapp.type == 'image' && typeof mapp.value == 'string' && mapp.value.trim() !== ""){
							if(mapp.value.includes(this.urlStorageImg) == false){
								mapp.value = this.urlStorageImg + "" + mapp.value
							}
							
						}

						if(mapp?.isApiValue !== undefined && mapp.isApiValue == true && mapp.apiValueConfig.hasOwnProperty('variable_api') == true){

							this.variablesToUploadValueAPI.push(
								{
									idField:mapp.id,
									keyFieldLoad:mapp.key,
									indexSection:index,
									name_variable:mapp.apiValueConfig.variable_api,
									name_data:mapp.apiValueConfig.value_api
								}
							);
						}

						return mapp;
					})

			if( form.app && form.app == 'crm_nsdp' ){				
				this.checkSeeRol(section);
			}
			const group = this.qcs.toFormGroup(section.fields);
			this.forms.push(group);

			});

			this._sections.forEach((section) => {
				this.fieldsInTrays(section);
			})
			
		}
	}

	typeSelectorChargables:string[]=[
		'dropdown', 'multiselect', 'autocomplete'
	]

	@Input() public set clearControl(clear: any) {
		if (clear === true) {
			/* this.forms.removeAt(1); */
		}
	}

	rol: any = [];
	fieldsPreloaded: any[] = [];
	questionPush: any;
	evento: any;
	plantilla: any = '';
	tab: any;
	tray: any;
	seePermit: any;
	editPermit: any;
	appOrigen: string = "crm"
	activatorsCalculatefield: any[] = []

	ngOnInit(): void {
		this.plantilla = this.router.url.substring(6).split('/', 3)[2];
	}
	/**
	 * @author Karol García
	 * @createdate 2021-03-29
	 * Metodo que captura el rol del usuario
	 */
	getRolUser(app) {
		let user = this.authService.getUser();
		user.roles.forEach((rol) => {
			let name = rol.split('::');
			if (name[0] === app) {
				this.rol.push(name[1]);
			}
		});
	}
	/**
	 * @author Karol García
	 * @createdate 2021-03-29
	 * Metodo que verifica si tiene el permiso
	 */
	checkSeeRol(section) {

		section['see'] = false;

		section.fields.forEach((question) => {
			this.seePermit = this.permit.permit(this.rol, question.seeRoles);
			this.editPermit = this.permit.permit(this.rol, question.editRoles);
			question.disabled = !this.editPermit;
			question['see'] = this.seePermit;
			question['seeSons'] = true;
			question['edit_by_adviser'] = false;
			if (question.preloaded == true) {
				this.fieldsPreloaded.push(question);
			}
			if (this.editPermit && question.isSon) {

				question = this.checkDependence(question);
			}

			section.see = true;
		});
	}

	/**
	 * @author Miguel Restrepo
	 * @createdate 2022-03-01
	 * Metodo que permite validar las dependencias de un campo
	 */

	checkDependence(question) {

		this._sections.forEach((section, indexSection) => { // Busca si tiene dependencias de campos de otras secciones
			section.fields.forEach((field, indexField) => {
				if(question?.dependencies !== undefined && question?.dependencies !== null && Array.isArray(question?.dependencies) == true){
					const dependences = question?.dependencies?.filter(dependence => dependence.idField === field.id);
					if (dependences?.length != 0) {
						question.disabled = true;
						question.seeSons = false;
						dependences.forEach(dependence => {
							if(question.seeSons === false) {
								
								if(Array.isArray(field.value)){
									question.seeSons = dependence.activators.some(activator => field.value.includes( activator.id )  );
									question.disabled = !dependence.activators.some(activator => field.value.includes( activator.id ) );
								}else{
									question.seeSons = dependence.activators.some(activator => activator.id === field.value);
									question.disabled = !dependence.activators.some(activator => activator.id === field.value);
								}
	
	
							}
							if (dependence.disabled) {
								question.disabled = true;
							}
						})
					}else if(question.dependencies.length <= 0 && this.appOrigen == "encuestas" ){
						question['see'] = true
						question['seeSons'] = true
						question['disabled'] = false
						
					}
				}
			
			})
		})
		return question;
	}
	/**
	 * @author Jair Celis
	 * @createdate 2021-08-30
	 * Metodo que añade las dependecias, dependiendo de la opciones del padre
	 */
	async addDependencies(event: any) {
		await this.disabledSons(event.idFather);
		const sonsFatherFieldActive = this.findSons(event.idFather).filter( (sonFinded:any) =>{
			return sonFinded.dependencies.some( (dependen:any) =>{
				return dependen.activators.find( ffnndd => String(ffnndd.id) == String(event.idValue) ) !== undefined
			})
		}); //Obtenemos todos los Campos hijos del Selector que activa la dependencias (Selector Padre)
	
		await this._sections.forEach(async (section, index) => {
			await section.fields.forEach(async (field) => {
				if (field.isSon === true) {//Se valida que sea un campo hijo o capo dependiente

					let idActivadores = [] //variable done se almacenara los ids de todos los activadores
					if(field.type != 'options'){ //En caso de no ser de tipo "options" significa que no se va a realizar unificacion de opciones dentro de selector de dependencia, por lo que se toma solo las dependencias asociadas al padre
						field.dependencies.filter((filterDependence:any)=> String(filterDependence.idField) == String(event.idFather) ).forEach(dependence => {
							idActivadores = idActivadores.concat(dependence.activators.map(activator => String(activator.id)))
						});
					}else{
						field.dependencies.forEach(dependence => {
							idActivadores = idActivadores.concat(dependence.activators.map(activator => String(activator.id)))
						});
					}

					// variable donde se almacenan los ids de los activadores que estan e el valor seleccionado en el multiselect
					const activadorIsFinded = idActivadores.filter(filtered => (Array.isArray(event.idValue) == true && event.idValue.map( value_map => String(value_map)).includes(filtered)) || (Array.isArray(event.idValue) == false && String(event.idValue) == String(filtered)))
					//Se obtienen los id de todas las dependencias del padre
					const idFatherdependence = field.dependencies.map(dep => dep.idField)
					if (idFatherdependence.find(fnd => fnd == event.idFather)) {//si el campo  es padre e alguna dependencia se limpia las opciones a marcar
						field.options = [];
					}


					let options = [];
					await field.dependencies.forEach(async dependence => {//Iteracion para mostrar campos de dependientes

						if (dependence.idField === event.idFather) { //Validamos que la depencia a  agregar sea del cmap padre
							//desactivar hermanos de dependencia
							this.forms.controls[index].get(field.key).disable();
							field.disabled = true;
							field.seeSons = false;

							await dependence.activators.forEach(async (activator) => {
								if (activadorIsFinded.length > 0) { //Validamos que el activador que se itera si este entro de los marcado en el valor el multiselect
									this.forms.controls[index].get(field.key).setValue('');
									field.seeSons = true;
									this.forms.controls[index].get(field.key).enable();
									field.disabled = false;
									//activa seccion del campo dependiente
									if (section.see === false) {
										section.see = true;
									}

									if (activadorIsFinded.find(fined => fined == activator.id)) {//Se valida que e activador se encuentre dentro e los activadores e la epenencia
										if ( this.typeSelectorChargables.find( (type:any) => type == field.controlType )  !== undefined && field?.isChargeableOption == true) {// enc aso de ser despelgable por carga se consutlan las opciones antes de hacer depuracion de las opciones
											await this.formsRequestService.getOptionsUploadFileAll(String(this.formId), String(field.id), false).toPromise()
												.then((restResponse) => {
													if (Array.isArray(restResponse.options) == true && restResponse.options.length > 0 ) {
														field.options = [];
														restResponse.options.forEach( (option:any) => {
															field.options.push( { id:Number(option.id), name:option.option_name , value_option:option.option_id, stateDisabled:false } )
														});
													}else{
														field.options = [];
														for (const key in restResponse.options) {
															field.options.push({ id: Number(key), name: restResponse.options[key] })
														}
													}
												})
										}
										if (dependence.options !== '') {
										
											await dependence.options.forEach(async (option) => {
												let optionFinded = field.options.find(finded => finded.id == option.id);
												if (this.typeSelectorChargables.find( (type:any) => type == field.controlType )  !== undefined && field?.isChargeableOption == true) {
													if (optionFinded != undefined) {
														options.push(optionFinded);
													}
												} else {
													if (optionFinded == undefined) {
														option['id'] = Number(option['id'])
														field.options.push(option);
													}
												}
											});
											if (this.typeSelectorChargables.find( (type:any) => type == field.controlType )  !== undefined && field?.isChargeableOption == true) {
												field.options = options
											}

										}
										if(this.appOrigen == "encuestas"){
											field['see'] = true;
										}
										
									}
								}
							});
							//Validamos que el campo que se activa por pertenecer a una dependencia del Padre tenga hijos
							let sonsfield = this.findSons(field.id);
							if (sonsfield) {
								let map = sonsfield.find(fnd => fnd.dependencies.find(ffnndd => ffnndd.idField == field.id));
								if (map !== undefined) {//Si los campos Hijos que se activa por dependencia del padre se activan con el selector activado Entoces desHabiltamos y vaciamos los selectores apra dejar los campo como listos para recien tipificar
									this.disabledSons(field.id, sonsFatherFieldActive, true);
								}
							}
						}
					});
				}
			});//Fin de la iteracion de Fields
			section.see = section.fields.some(field => field.see === true && field.seeSons === true);

		});//Fin de la iteracion de sections
		this.changeDetector.detectChanges();//Deteccion de cambios para reseteo de valores

	}

	/**
	 * Metodo que se encarga de ddesahabilitar los hijos de un padre
	 * @author Juan David Guerrero Vargas
	 * @param idFather:id del campo padre
	 */
	async disabledSons(idFather: string | number, sonsOfFatherActivador?:any[], allChilds?: boolean) {
		this._sections.forEach((section, index) => {
			section.fields.forEach((field) => {
				if (field.isSon == true) {
					const depField = field.dependencies.filter( (ffdd:any) => ffdd.idField == idFather );
					if(depField.length > 0){//Se valida que el campo en iteracion tenga dependencias con el campos padrea a validar
							field.sectionIndex = index;
							/*En casdo de que llegan los campos hijos del Selector que diparara la dependencias (Selector padre)
								Se valida que el campo a deshabilitar como Hijo de un Hijo no pertenezca  a un campo que se esta activando por parte del selector padre
								Este escenario  se da cuando  Un campo Padre y un campo Hijo pueden ambos activar un campo diferente a ellos pero con opciones aparte
							*/
							if( sonsOfFatherActivador !== undefined && Array.isArray(sonsOfFatherActivador) == true && sonsOfFatherActivador.length > 0 ){
								if(sonsOfFatherActivador.find( fndEqu => String(fndEqu.id) == String(field.id) ) == undefined){
									if (this.forms.controls[field.sectionIndex].get(field.key).value != '') {
										this.forms.controls[field.sectionIndex].get(field.key).setValue(null);
										this.forms.controls[field.sectionIndex].get(field.key).disable();
									}
									field.disabled = true
									field.seeSons = false
									if (allChilds) {//Se reutiliza el metodo para deshabilitar en segundo-Tercer (Maximo 4) nivel de validacion antes de generar problemas de rendimiento
										this.disabledSons(field.id)
									}
								}
							}else{//En caso de no tener campos del padre se procede a deshabilitar tantos campos como sea necesarios
								if (this.forms.controls[field.sectionIndex].get(field.key).value != '') {
									this.forms.controls[field.sectionIndex].get(field.key).setValue(null);
									this.forms.controls[field.sectionIndex].get(field.key).disable();
								}
								field.disabled = true
								field.seeSons = false
								if (allChilds) {//Se reutiliza el metodo para deshabilitar en segundo-Tercer (Maximo 4) nivel de validacion antes de generar problemas de rendimiento
									this.disabledSons(field.id)
								}
							}
							
					}

				}
			});
		});
	}

	/**
	 * @author Jair Celis
	 * @createdate 2021-08-30
	 * Metodo que desactiva la visualizacion de un campo
	 * @param idField id del campo a desactivar
	 */

	disableField(idField) {
		this._sections.forEach((section, index) => {
			section.fields.forEach((field) => {
				if (field.id == idField) {
					field.seeSons = false;
				}
			});
		});
	}

	/**
	 * @author Jair Celis
	 * @createdate 2021-08-30
	 * Metodo que devuelve un arreglo de campos hijos
	 * @param idField id del campo a buscar los hijos
	 */

	findSons(idField) {
		const mySons = [];
		this._sections.forEach((section, index) => {
			section.fields.forEach((field) => {
				if (field.isSon == true) {
					field.dependencies.forEach((dependencie) => {
						if (dependencie.idField == idField) {
							field.sectionIndex = index;
							mySons.push(field);							
						}
					});
				}
			});
		});
		return mySons;
	}
	/**
	 * @author Karol García
	 * @createdate 2021-02-25
	 * Metodo que añade otro campo
	 */
	addQuestion(event, nameSection) {
		this.evento = Object.assign({}, event);
		this.questionPush = this.evento;
		let cont = 0;
		let indexAuxField = 0;
		let indexAuxSec = 0;

		this._sections.forEach((section, indexSec) => {
			section.fields.forEach((question, indexField) => {
				if (question.key.includes(this.evento.key) && nameSection === section.name_section) {
					cont++;
					indexAuxField = indexField;
					indexAuxSec = indexSec;
				}
			});
		});

		cont = cont + 1;

		this.questionPush.id = new Date().getTime();
		this.questionPush.key = this.evento.key + '_' + cont;
		this.questionPush.value = '';
		this.questionPush.label = this.evento.label + ' ' + cont;
		this.questionPush.canAdd = false;
		this.questionPush.duplicated = {
			idOriginal: event.id,
			type: 'field'
		};

		if (this.questionPush.nameFile) {
			this.questionPush.nameFile = '';
		}

		if (this.questionPush.idValue) {
			this.questionPush.idValue = '';
		}

		if (this._sections[indexAuxSec].name_section === nameSection) {
			this._sections[indexAuxSec].fields.splice(indexAuxField + 1, 0, this.questionPush);
		}

		let formAux = this.forms.controls[indexAuxSec] as FormGroup;

		let controlAux = this.qcs.toFormControl(this.questionPush);
		formAux.addControl(this.questionPush.key, controlAux);
	}

	/**
	 * @author Miguel Restrepo
	 * @createdate 2021-02-25
	 * @updatedate 2022-05-18
	 * Metodo que añade una nueva sección duplicada
	 */

	addSection(event, i) {
		//let evento = Object.assign({}, event);
		let evento = JSON.parse(JSON.stringify(event))
		let section = evento;
		let cont = 0;
		this._sections.forEach((element) => {
			if (element.name_section.includes(section.name_section)) {
				cont++;
			}
		});
		cont = cont + 1;

		section['duplicated_section'] = true
		section.fields.forEach((field) => {
			field.id = Number(field.id + "" + cont)
			field.key = field.key + "_" + cont
			field.label = field.label + "" + cont
			field.disabled = false
			if (field.dependencies) {
				field.dependencies.forEach((dependence) => {
					dependence.idField = Number(dependence.idField + "" + cont)
					dependence.seeDepen = false
				});

			}
		});
		this._sections.push(section)
		const group = this.qcs.toFormGroup(section.fields);
		this.forms.push(group);
		/* this.formsRequestService.addSection(sendSection).subscribe((resp) => {
			resp["duplicated_section"] = true;
			this._sections.push(resp);
			const group = this.qcs.toFormGroup(resp.fields);
			this.forms.push(group);
		}); */
	}

	removeSection(event, index) {
		this.forms.removeAt(index)
		this._sections.splice(index, 1)

	}

	/**
	 * @author Karol García
	 * @createdate 2021-02-17
	 * Metodo que emite el formulario resuelto
	 */
	onSubmit() {
		let response = {
			sections: this._sections,
			answer: this.forms.getRawValue(),
			files: this.Files,
			forms: this.forms,
			isValid: this.forms.valid
		};
		this.submitForm.emit(response);
		this.fieldsPreloaded.forEach((element) => {
			this.forms.controls.forEach((sections) => {
				if (sections.get(element.key) != null) {
					sections.get(element.key).setValue(element.value);
				}
			});
		});
	}

	/**
	 * @author Karol García
	 * @createdate 2021-03-30
	 * Metodo que cancela el formulario
	 */
	cancelForm() {
		let saveconfirm = this.alertsService.alertConfirm('¿Desea cancelar?');
		saveconfirm.then((res) => {
			if (res.isConfirmed) {
				this.denyForm.emit(true);
			}
		});
	}
	/**
	 * @author Daniel Dominguez
	 * @createdate 2021-04-06
	 * Metodo recibe los archivos cargados en los campos tipo file
	 */
	addFiles(e) {
		this.Files.push(e);
	}

	ngOnDestroy(): void {
		this.destroyService.destroyComponent();
	}

	// Boton para generar las plantillas
	generateTemplate() {
		let responseTemplate = {
			sections: this._sections,
			answer: this.forms.getRawValue(),
			files: this.Files,
			forms: this.forms
		};

		this.enableTemplate.emit(responseTemplate);
	}

	fieldsInTrays(section) {

		this.tab = this.storage.getItem('tab');
		this.tray = this.storage.getItem('tray');
		section.fields.forEach((question) => {
			if (question?.tray && Array.isArray(question.tray)== true && question.tray.length > 0 ) {
				var band = false;
				question.tray.forEach(element => {

					question.disabled = true;
					question['see'] = true;

					if (element.id !== +this.tray && band === false) {
						question.disabled = false;
						question['see'] = false;
						this._sections.forEach((sectionArray, index) => {
							if (sectionArray.name_section === section.name_section) {
								this.forms.controls[index].get(question.key).disable();
							}
						});
					} else {
						question.disabled = true;
						question['see'] = true;
						band = true;

						if (element.preloaded === false) {

							this._sections.forEach((sectionArray, index) => {
								if (sectionArray.name_section === section.name_section) {
									this.forms.controls[index].get(question.key).setValue('');
								}
							});
						}
					}
				});

				if (this.template) {
					if (+this.tab < 2) {
						question.disabled = !this.editPermit;
						question['see'] = false;
						question['seeSons'] = true;
						this._sections.forEach((sectionArray, index) => {
							if (sectionArray.name_section === section.name_section) {
								this.forms.controls[index].get(question.key).disable();
							}
						});
					}
				} else {
					if (+this.tab < 1) {
						question.disabled = !this.editPermit;
						question['see'] = false;
						question['seeSons'] = true;
						this._sections.forEach((sectionArray, index) => {
							if (sectionArray.name_section === section.name_section) {
								this.forms.controls[index].get(question.key).disable();
							}
						});
					}
				}
			}
		});
		section.see = section.fields.some(field => field.see === true && field.seeSons === true);
	}

	/**
	 * Metodo que se encarga de recalcular los valores de los campos calculados y asignar su valor 
	 * @author Juan David Guerrero Vargas 
	 * @update 01/01/2024 
	 * @param event:any {any} evento de cambio para campos Dropdown, Radiobutton, Autocomplete, Number, Desplegable por Carga
	 */
	calculatedFields(event: any) {
		//Campos que se usan para calcula alguna formula
		let fieldsCalcualteFormula = []
		//Expresion regular apra identificar los campos en la formula
		const regexFieldInFormula = /\[\[([^[\]]*)\]\]/g;
		//Expresion regular para identificar el valor numerico de un porcentaje
		let regexPercentNumber = /\d+(?=%)/g
		//Expresion regular para validar numero Enteros y decimales
		let regexNumber = /^-?\d+(\.\d+)?$/
		//Buscamos y obtenemos los campos que calculan algun campos calculado
		this._sections.forEach( (section:any, indexSection:number)=>{
			section.fields.forEach( (field:any, indexField:number)=>{
				const fieldInFormula = this.isFieldInFormula(field)
				if(fieldInFormula  == true){
					fieldsCalcualteFormula.push(
						{
							filed_key: field.key,
							field:field,
							indexSection:indexSection,
							indexField:indexField,
							valueControl:this.forms.controls[indexSection].get(field.key).value
						}
					)
				}
			});//Fin iteracion Fields
		});//Fin iteracion _sections
		
		try {
			//Identificamos los campos calculados y realizamos el reemplazo de los valores segun el reemplazamiento de formula o infromaicon obtenida en el paso anterior
			this._sections.forEach( (section:any, indexSection:number)=>{
				section.fields.forEach( (field:any, indexField:number)=>{
					if(field.isCalculated == true && field.calculatedFields['formula'] !== undefined){
						let formula = field.calculatedFields['formula']
						let formatFormula = formula.replace(regexFieldInFormula, (match, field_key) =>{
							let fieldFinded = fieldsCalcualteFormula.find( (fnd:any) => fnd.filed_key == field_key  )
							if(fieldFinded!== undefined){
								if(fieldFinded.field.controlType == 'dropdown' || fieldFinded.field.controlType == 'radiobutton' || fieldFinded.field.controlType == 'autocomplete'){
									return this.getValueOptions(fieldFinded.field, String(fieldFinded.valueControl));
								}else{
									return fieldFinded.valueControl !== null && fieldFinded.valueControl!== "" ? fieldFinded.valueControl : 0
								}
							}
							return 0
						});//Fin de Regex reemplazameinto de llaves de formula por valores de formulario
						//Remplazamos los porcentajes por su valor numerico operable
						formatFormula = formatFormula.replace( regexPercentNumber, (match, precentNumber) =>{
							if(regexNumber.test(match) == true){
								return Number(match) / 100
							}
							return match + "%"
						});//Fin Regex Porcentaje
						//Retiramos los signos de % que no se usan en la formula final
						formatFormula = formatFormula.replaceAll("%","")

						//Reemplazamos el signo (^) de porcentaje por su representacion para ejecucion de (**)
						if(formatFormula.includes('^')){
							formatFormula = formatFormula.replaceAll('^',"**")
						}

						this.forms.controls[indexSection].get(field.key).setValue(eval(formatFormula))
					}
				});//Fin iteracion Fields
			});//Fin iteracion _sections		
		} catch (error) {
			this._snackBar.open("Error en el cálculo de fórmula", '', {
				duration: 1100,
				horizontalPosition: 'center',
				verticalPosition: 'top',
				panelClass : ['red-snackbar']
			  });
		}
	
	}

	/**
	 * Metodo que se encarga de identificar si un campo forma parte de la formula de algun campo calculado
	 * @author Juan David Guerrero Vargas
	 * @param field:any {any}
	 * @returns {boolean} boolean True en caso de ser un campos usado en alguna formula - False en caso de no pertenecer aninguna formula
	 */
	isFieldInFormula(field:any):boolean{
		const sectionsWithCalculatedFields = this._sections.map( (sectionMap:any, index:number) =>{
			sectionMap['indexSection'] = index
			sectionMap.fields.map( (fieldMap:any, indexField)=> {
				fieldMap['indexField'] = indexField
				return fieldMap
			} )
			return sectionMap;
		} ).filter( (section:any)=> section.fields.find( (fnd:any) => fnd.isCalculated == true && fnd?.calculatedFields && fnd?.calculatedFields['formula'] !== undefined && fnd?.calculatedFields['formula'].includes(field.key)  ) )
		if( Array.isArray(sectionsWithCalculatedFields) == true && sectionsWithCalculatedFields.length > 0 ){
			return true
		}else{
			return false
		}
	}

	/**
	 * Metodo que se encarga de extraer el valor numerico de los values de las opciones
	 * @author Juan David Guerrero Vargas
	 * @param field:any {any} estructura del campo tipo selector al cual se le obtendra su valor numerico
	 * @param value:string|number {string|number} id de la opcion a la cual se le obtendra su valor numerico
	 * @returns {number} Valor numerico de la opcion  
	 */
	getValueOptions(field:any, value:string|number):number{
		if(field !== undefined){
			let option = field.options.find( (option:any)=> String(option.id) == String(value) )
			if(option !== undefined ){
			if( option.hasOwnProperty('value_option') && option.value_option !== null && option.value_option !== "" ){
				let validate_numbers = this.getNumberFromString(option.value_option)
				return validate_numbers[1]
			}else{
				let validate_numbers = this.getNumberFromString(option.name)
				return validate_numbers[1]
			}
			}
			return 0
		}
		return 0
	}
	
	
	/** Metodo que obtiene los numeros dentro de un strng
	 * @author Juan David Guerrero Vargas
	 * @param strWithnumber:string {string}  cadena de texto que peude o no tener numero en su interior
	 */
	getNumberFromString(strWithnumber:string):any[]{
		const regexNumbers = /\d+/
		let matchNumbers = strWithnumber.match(regexNumbers);
		if(matchNumbers){
			return [true, parseInt(matchNumbers[0])]
		}else{
			return [false, 0]
		}
	}


	/**
	 * Metodo que se encarga de ordenar de manera Descedente la lista de valores
	 * @author Juan David Guerrero Vargas
	 * @createddate 18/08/2023
	 * @param array:array listado de valores a ordenar
	 * @returns any[] {any[]} 
	 */
	sortDescValues(array) {
		let swap = true;
		while (swap) {
			swap = false;
			for (let i = 0; i < array.length - 1; i++) {
				if (array[i] < array[i + 1]) {
					// SI DOY VUELTA EL > POR < ME CAMBIA EL ORDENAMIENTO DE MAYOR A MENOR
					let aux = array[i];
					array[i] = array[i + 1];
					array[i + 1] = aux;
					swap = true;
				}
			}
		}
		for (let i = 2; i > array.length; i++) {
			if (array[i] % i === 0) { return false };
		}
		return array;
	}

	/**
	 * Metodo que se encarga de retornar cual sera el tipo de visual a renderizar para el campo (Question)
	 * @author Juan David Guerrero Vargas
	 * @param question:any {any} campos (question) a evaluar para dertminar su visual
	 * @returns {string} string calve que indica que visual se rederizara 
	 */
	seeTypeQuestion(question:any, controls:any):string{
		if(question.see && question.seeSons && controls){

			if((question.hasOwnProperty('indDefaultValue') == false || question.indDefaultValue == false) && (question.type !== 'image' && question.type !== 'api' &&  question.type !== 'table'  ) ){
				return 'question';
			} 

			if((question.hasOwnProperty('indDefaultValue') == true && question.indDefaultValue == true)  && (question.type !== 'image' && question.type !== 'api' &&  question.type !== 'table'  ) ){
				return 'default_field';
			}

			if( question.type == 'image' ){
				return 'image_field';
			} 

			if(question.type == 'api' && (question.hasOwnProperty('isButtonApi') == true && question.isButtonApi == true)  && (question.type !== 'image' && question.type !== 'table') ){
				return 'button_api';
			}

			if(question.type == 'table' && (question.hasOwnProperty('isTable') == true && question.isTable == true)  && (question.type !== 'image' && question.type !== 'api') ){
				return 'table_api';
			}
		}
		return 'none_question'
	}



	/**
	 * Metodo que se encarga de obtener los datos del formulario segun la configuracion ingresada, para envio a terceros
	 * @param configTrigger:any {any} configuracion de disprador
	 * @returns {body:any} body:any Cupero de solicitud clave valor con los valores reocogidos del formulario
	 */
	getDataSendFromTrigger(configTrigger:any):any{
		let bodyRequest = {};
		if(Array.isArray(configTrigger.body_api) == true){ //Validamos que haya una configuracion que iterar
			configTrigger.body_api.forEach( (config)=>{
				if(config?.origin_data == "1"){ //Si el origin_data es (1) el dato proviene del formulario 					
					let new_Sections = JSON.parse(JSON.stringify(this._sections))
					let fieldFinded = new_Sections.map( (section:any, indexSection:number)=>{ //Filtramos y obtenemos el campo y dus indices dentro de las secciones
						let sections = section.fields.map( (field:any, indexField:number)=>{
							if(config.field_data.find( ffnn => ffnn.field_id == field.id ) !== undefined){
								return {field:field, indexSection:indexSection, indexField:indexField }
							}
							return undefined
						} )	;

						if(sections.filter( ffll=> ffll !== undefined).length > 0){
							return sections.filter( ffll=> ffll !== undefined)
						}
						return undefined
					});
			
					let cleanFields = fieldFinded.flat().filter(ffll=> ffll !== undefined) //Limpiamos el array de valores indefimidos y dejamos los campos encontrados en el primer nivel del array					
					if(Array.isArray(config.field_data) == true && config.field_data.length > 0 ){
						let value = ""
						config.field_data.forEach( (fieldData:any, index:number) => { //Iteramos cada una de los campos del formulario con los que se armara la opcion
					 		const fieldFinded = cleanFields.find( ffnn => ffnn.field.id == fieldData.field_id );
							if(fieldFinded !== undefined){
								let controlValue = this.forms.controls[fieldFinded.indexSection].get(fieldFinded.field.key).value !== null ? this.forms.controls[fieldFinded.indexSection].get(fieldFinded.field.key).value : ""
								if(fieldFinded.field.controlType == 'datepicker'){ //Formateamos el valor si es tipo FEcha
									value += moment(controlValue).format('DD/MM/yyyy') 
									if(index < (config.field_data.length -1 ) ){
										value += " "
									}
								}else if(fieldFinded.field.controlType == 'textbox' && fieldFinded.field.type == "time" ) { //Formaetamos el valor a Horas:minutos en caso de ser tiempo
									let time = controlValue.split(":")
									if(time.length > 0){
										value += time[0] + ":" + time[1] 
									}else{
										value += controlValue + " "
									}
								}
								else if(this.typeSelectorChargables.find( ffnn => ffnn == fieldFinded.field.controlType ) !== undefined){//En caso de ser selectores se debe validar si la informaicon se obtiene desde el Id o el option_value de la opcion seleccionada
									if( fieldFinded.field.controlType == 'multiselect' ){
										if(Array.isArray(controlValue) == true && controlValue.length > 0){
											value = fieldFinded.field.options.map( (optionMap:any)=>{
													if( controlValue.find( ffnn => ffnn == optionMap.id ) !== undefined ){
														if(optionMap?.value_option !== undefined && optionMap?.value_option !== null && optionMap?.value_option !== ""){
															return optionMap?.value_option
														}else{
															return optionMap?.id
														}
													}
													return undefined
												}).filter( ffll => ffll != undefined  );
										}else{
											value = controlValue
										}
									}else{
										let option = fieldFinded.field.options.find( optionFN => String(optionFN.id) == String(controlValue) )										
										if(option !== undefined){
											if(option?.value_option !== undefined && option?.value_option !== null && option?.value_option !== ""){
												value = option?.value_option
											}else{
												value = option?.id
											}
										}else{
											value = controlValue
										}
									}
								}else{
									value += controlValue
								}
							}else{
								value += ""
							}
						});

						bodyRequest[config.variable_send] = value
					}else{
						bodyRequest[config.variable_send] = ""
					}
				
					
				}else if (config?.origin_data == "2"){ //(2) proviene de valirable global
					const dataVariable = this.variablesTriggerLoads[config.variable_data];
					if(dataVariable.hasOwnProperty(config.name_in_variable) == true){
						bodyRequest[config.variable_send] = dataVariable[config.name_in_variable];
					}else{
						bodyRequest[config.variable_send] = "";
					}
				}

				else if (config?.origin_data == "3"){ //(3) Proviene de un selecotr cargado por API por lo que se debe obtener l nuevo valor desde la variable globarl cargada

					const selectorLoad = this.variablesToUploadValueAPI.find( ffnn => ffnn.idField == config.variable_data ) //Obtenemos la configuracion del campo Selector
					if(selectorLoad !== undefined){
						if(this.variablesTriggerLoads.hasOwnProperty(selectorLoad.name_variable) == true){//Validamos que ya se haya cargado un array o un valor a la variable
							const field = this._sections[selectorLoad.indexSection].fields.find( ffnn => ffnn.id == config.variable_data ) //Obtenemos el campo consu configuracion
							if(field !== undefined){
								let valueVariable = this.variablesTriggerLoads[selectorLoad.name_variable][selectorLoad.name_data] //Obtenemos el array de opciones completo desde el que e carga el selector
								if(Array.isArray(valueVariable) == true){
									let valueSelector = this.forms.controls[selectorLoad.indexSection].get(field.key).value
									let option = valueVariable.find( ffnn => ffnn[field.apiValueConfig.name_attribute_id] == valueSelector ) //Obtenemos la opcion seleccionada 
									if(option !== undefined && option.hasOwnProperty(config.name_in_variable) == true){
										bodyRequest[config.variable_send] = option[config.name_in_variable]; //Cargamos el valor adicional de la configuracion
									}
								}
							}
						}
					}
				}
			});
		}
		return bodyRequest
	}

	/**
	 * Metodo que se encarg de realizar la peticion y carga de informaicon para datos que dependen de la informaicon cargada desde el consumo
	 * @author Juan David Guerrero Vargas
	 * @param configTrigger:any {any} Configuracion del disparador
	 * @returns void
	 */
	sendAndLoadData(configTrigger:any):void{
		//Obtenemos el cuerpo de la peticion con los campos indicados en la configuracion ya sea desde el formulario o desde variable global
		let bodyRequest = this.getDataSendFromTrigger(configTrigger) ;
		
		this.formsRequestService.getValuesConsumeAPI(String(configTrigger.id_consume), bodyRequest ).subscribe(
			(restResponse)=>{

				this.variablesTriggerLoads[configTrigger.name_variable] = restResponse
				
				const listFieldsLoad =  this.variablesToUploadValueAPI.filter( filtered => filtered.name_variable == configTrigger.name_variable  );
				
				if(Array.isArray(listFieldsLoad) == true && listFieldsLoad.length > 0){
					listFieldsLoad.forEach(
						(fieldLoad)=> {
							const fielConfig = this._sections[fieldLoad.indexSection].fields.find( (field)=> String(field.key) == String(fieldLoad.keyFieldLoad) );
							const indexField = this._sections[fieldLoad.indexSection].fields.findIndex( (field)=> String(field.key) == String(fieldLoad.keyFieldLoad) );
							if(fielConfig.type == 'options'){
								const lista = restResponse[fieldLoad.name_data]
								
								const configOptionsEntry = fielConfig.apiValueConfig;
								let options:any[] = [];
								if(Array.isArray(lista) == true && lista.length > 0){
									lista.forEach(
										(itemList)=>{
											options.push( { id:itemList[configOptionsEntry.name_attribute_id],  name:itemList[configOptionsEntry.name_attribute_label]  } )
										}
									);
								}

								if(indexField !== -1){
									fielConfig.options = options
									this._sections[fieldLoad.indexSection].fields[indexField] = fielConfig
								}

							}else{
								this.forms.controls[fieldLoad.indexSection].get(fieldLoad.keyFieldLoad).setValue(restResponse[fieldLoad.name_data]);
								this.forms.controls[fieldLoad.indexSection].get(fieldLoad.keyFieldLoad).patchValue(restResponse[fieldLoad.name_data]);
							}
							
						}
					);
				}

				if(configTrigger.hasOwnProperty('msg_config') == true && configTrigger.msg_config ){
					if(configTrigger.msg_config?.msg_sucess !== undefined && configTrigger.msg_config?.msg_sucess !== null){
						this.alertsService.alertSuccess('Proceso exitoso', configTrigger.msg_config.msg_sucess)
					}
				}
		},(error:any)=>{
			if(configTrigger.hasOwnProperty('msg_config') == true && configTrigger.msg_config ){
				if(configTrigger.msg_config?.msg_error !== undefined && configTrigger.msg_config?.msg_error !== null){
					this.alertsService.alertError('Proceso fallido', configTrigger.msg_config.msg_error)
				}
			}
		});
	}


	/**
	 * Metodo que captura el evento de disparador de campo tipo API
	 * @param event:any {any} 
	 */
	triggerApiAction(event:any){		
		const configTrigger = event.question.apiTriggerConfig
		this.sendAndLoadData(configTrigger);
	}


	/**
	 * Metodo que se encarga dispara el evento de consulta del boton
	 * @author Juan David Guerrero Vargas
	 * @param event:any {any} evento click por defecto
	 * @param question:any {any} Boton configurado para disparar la consulta
	 */
	buttonTrigger(event:any, question:any):void{
		if(event){
			event.preventDefault();
		}
		const configTrigger = question.apiButtonConfig
		this.sendAndLoadData(configTrigger);
	}

	/**
	 * Metodo que se encarga de obtener las columnas de la tabla
	 * @param question:any {question} configuracion del campo que tiene la tabla
	 * @returns {string[]} string[]  array de nombres de columnas
	 */
	getColumnsTable(question:any):string[]{
		if(question?.tableConfig !== undefined &&  question?.tableConfig?.columnsTable !== undefined){
			if(Array.isArray(question?.tableConfig?.columnsTable) == true && question?.tableConfig?.columnsTable.length > 0){
				return question?.tableConfig?.columnsTable.map( (column:any)=>{
					return column.name_column
				});
			}
		}
		return []
	}

	/**
	 * Metodo que se encarga de obtener el listado de valores con que se obtiene desde la variable cargada por un campo disprador Trigger
	 * @author Juan David Guerrero Vargas
	 * @param question:any {any} Campos tipo tabla que contiene la configuracion de que variable se obtienen sus valores 
	 * @returns {any[]} any[] Listado de datos 
	 */
	getDataToTable(question:any):any[]{
		if(question?.tableConfig !== undefined &&  question?.tableConfig?.variable_data !== undefined){
			try {
				let dataInVariable = this.variablesTriggerLoads[question?.tableConfig?.variable_data][question?.tableConfig?.value_api];
				if(dataInVariable !== undefined && dataInVariable !== null && Array.isArray(dataInVariable) == true ){
					return dataInVariable
				}
			} catch (error) {
				return []				
			}
		}
		return []
	}

	/**
	 * Metodo que se encarga de obtener los valores de cada fila segun configuracion de la tabla
	 * @author Juan David Guerrero Vargas
	 * @param data:any {any} Informaicon de cada fila de donde se obtendran los valores a pintar
	 * @param question:any {any} Campo con la configuracion de la tabal donde se botiene el nombre de las variables a mostrar en la tabla
	 */
	getValuesForRowtoTable(data:any, question:any):string[]{
		if(question?.tableConfig !== undefined &&  question?.tableConfig?.columnsTable !== undefined){
			if(Array.isArray(question?.tableConfig?.columnsTable) == true && question?.tableConfig?.columnsTable.length > 0){ 
				return question?.tableConfig?.columnsTable.map( (column:any)=>{
					try {
						let value = data[column.name_variable]
						return value !== null && value !== "" ? value : ""
					} catch (error) {
						return ""
					}
				});
			}
		}
		return []
	}


}
