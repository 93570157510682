import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CandidatesService } from '../../../services/candidates.service';
import { TrainingGroupsService } from '../../../services/training-groups.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';

@Component({
  selector: 'app-groups-candidate-assign',
  templateUrl: './groups-candidate-assign.component.html',
  styleUrls: ['./groups-candidate-assign.component.sass']
})
export class GroupsCandidateAssignComponent implements OnInit {
  group;

  candidates = [];
  candidatesSelected = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialogRef<GroupsCandidateAssignComponent>,
              public candidatesService: CandidatesService,
              public groupServices: TrainingGroupsService,
              private notification: AlertsService ) {
                this.group = data.group;
               }

  ngOnInit(): void {
    this.getCandidates();
  }

  getCandidates() {
    this.candidatesService.candidateByUserLogguedForGroups(this.group.id).subscribe((resp: any) => {
      this.candidates = resp;
    });
  }

  pushArray(event) {
    this.candidatesSelected = event.selectedOptions.selected.map(item => item.value);
  }

  assignCandidates () {
    this.notification.alertConfirm('¿Está seguro que desea asignar el/los candidatos a este grupo?').then(e => {
      if (e.isConfirmed){
        const params = {
          candidateIds: this.candidatesSelected.toString(),
          groupId: this.group.id
        };
        this.groupServices.assignCandidatesToGroup(params)
                              .subscribe( resp => {
          this.notification.alertSuccess('¡Asignados!', 'Candidatos asignados con éxito');
          this.dialog.close(true);
        });
      }
    });
  }
}
