<div class="container-fluid p-5">
  <div class="row">
    <div class="col-md-12">
      <span style="font-size: 32px; font-weight: 700;">Speech to Text</span>
    </div>
    <div class="col-md-12 mt-4">
      <p class="lh-base" style="color: #353535">
        Este es el módulo de Speech to Text, donde podrás administrar la transcripción de <br />
        audios y aplicar estrategias. No olvides, “El respeto y empatía son clave”.
      </p>
    </div>
  </div>
  
  <div class="row justify-content-between mt-5">
        <app-search-module></app-search-module>
  </div>

  <!-- TAB MODULO BUSQUEDA POR MODULO O DIRIGIDA 
    <div class="row justify-content-between mt-5">
      <mat-tab-group mat-align-tabs="start">
        <mat-tab label="Consulta módulo">
          <app-module></app-module>
        </mat-tab> 
        <mat-tab label="Consulta dirigida">
          <app-dirigido></app-dirigido>
        </mat-tab>
      </mat-tab-group>
    </div>
  -->

  <div class=" row mt-4">
    <div class="col-md-12">
      <p class="lh-base" style="color: #717171">
        <b>Nota:</b> Recuerda que <b>los audios solo estarán disponibles durante
          <span color="primary" class="blue">1 mes </span>
          para realizar su auditoría,</b> después de esto se eliminarán automáticamente.
      </p>
    </div>
  </div>
</div>