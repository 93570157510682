<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>

<h3 mat-dialog-title>Solicitud {{request?.code }}</h3>
<h4 mat-dialog-title>{{request?.request_data?.cargo}}</h4>
 
<mat-dialog-content *ngIf="request">
    <rrhh-request-info [request]="request"></rrhh-request-info>
</mat-dialog-content>
