<form [formGroup]="filters">
  <div class="row mt-4">
    <div class="col-md-2">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Fecha</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Inicio" formControlName="start_date">
          <input matEndDate placeholder="Fin" formControlName="end_date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <div class="col-md-2">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Usuario activado</mat-label>
        <input type="text" matInput formControlName="unlocked_user">
      </mat-form-field>
    </div>

    <div class="col-md-2">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Gestionado por</mat-label>
        <input type="text" matInput formControlName="unlocker_user">
      </mat-form-field>
    </div>

    <div class="col-md-1">
      <button (click)="filterData()" type="button" mat-raised-button color="primary" class="w-100 action-buttons"><i
          class="fi-rr-search"></i></button>
    </div>
    <div class="col-md-1">
      <button (click)="clearFiltersForm()" type="button" mat-raised-button color="primary" class="w-100 action-buttons"><i
          class="fi-rr-refresh"></i></button>
    </div>


    <div class="col-md-2">
      <button (click)="downloadLogs()" type="button" mat-stroked-button color="primary" class="w-100 action-buttons">
        Historico
        <span class="fi-rr-download"></span>
      </button>
    </div>

    <div class="col-md-2">
      <button (click)="activateUserDialog()" type="button" mat-raised-button color="primary"
        class="w-100 action-buttons">
        Activar usuario
      </button>
    </div>
  </div>
</form>


<div class="row mt-3">
  <div class="col-12 table-border">
    <table mat-table [dataSource]="data" matSort>
      <ng-container matColumnDef="unlocker_user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Gestionado por</th>
        <td mat-cell *matCellDef="let element">{{ element.unlocker_user }}</td>
      </ng-container>

      <ng-container matColumnDef="unlocked_user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario de red activado</th>
        <td mat-cell *matCellDef="let element">{{ element.unlocked_user }}</td>
      </ng-container>

      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de activación</th>
        <td mat-cell *matCellDef="let element">{{ element.created_at | date: 'yy-MM-dd h:mm:ss a' }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <ng-template #notPromises>
          <td colspan="3">No se encontraron registros</td>
        </ng-template>
      </tr>
    </table>
    <mat-paginator [length]="length" [pageSize]="pageSize" (page)="paginatorEvent($event)"
      [pageSizeOptions]="pageSizeOptions"> </mat-paginator>
  </div>
</div>
