import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import { AuthService } from '../../../../../../core/services/rest/auth.service';
import { InactiveDatesService } from '../../../../services/rest/inactive-dates.service';

@Component({
  selector: 'app-create-inactive-dates',
  templateUrl: './create-inactive-dates.component.html',
  styleUrls: ['./create-inactive-dates.component.sass']
})
export class CreateInactiveDatesComponent implements OnInit {

  public sending: boolean;
  public inactiveDateForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private inactiveDatesService: InactiveDatesService,
              private alert: AlertsService,
              private authService: AuthService,
              private fb: FormBuilder,
              public dialogRef: MatDialogRef<CreateInactiveDatesComponent>) {
  }

  ngOnInit(): void {
    this.inactiveDateForm = this.fb.group({
      inactiveDate: ['', Validators.required],
      descriptionDate: ['']
    });
  }

  /**
   * @author Andrés Buitrago <andres.b@montechelo.com.co>
   * @purpose registrar una fecha inactiva
   */
  createInactiveDate(): void {
    this.sending = true;
    this.inactiveDatesService.createInactiveDate(this.inactiveDateForm.value)
      .subscribe(resp => {
        this.dialogRef.close('');
        this.alert.alertSuccess('Exito!', 'Fecha registrada correctamente');
        this.sending = false;
      }, (rej) => {
        this.alert.alertError('Error!', rej.error.error ?? 'Internal server error');
        this.sending = false;
      });
  }

}
