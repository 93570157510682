<div class="admin-calidad">
  <div class="header">
    <h1>{{ config.labels.qualityReportTitle }}<i class="fas fa-container-storage"></i></h1>
    <p>{{ config.labels.qualityReportDescription }}</p>
  </div>
</div>

<div *ngIf="generar">
  <app-report-view [tokenReport]="token" [reportId]="report_id" style="height: 100%;"></app-report-view>
</div>
