<div class="admin-reporte">

    <div class="header">
  
      <h2 style="height: 33px !important;
      font: normal normal bold 45px/35px Poppins !important;
      letter-spacing: 0px !important;color: #353535 !important;opacity: 1 !important;">Reporte Encuesta</h2>
      <br>
      <p>Aquí podrás descargar los reportes detallados de las encuestas externas realizadas</p>
      <p>No olvides, “El respeto y empatía son clave”.</p>
    </div>
    <div class="mt-5">
        <div fxLayout="row">
        <div fxFlex="20%">
           
            <mat-form-field appearance="fill" class="me-3" fxFlex.gt-lg="100%" fxFlex.gt-md="100%">
                <mat-label>Rango de Fecha</mat-label>
                <mat-date-range-input [rangePicker]="rango" [formGroup]="filtroFecha" (keydown.enter)="filtrar()">
                    <input matStartDate placeholder="Fecha inicio" formControlName="fechaInicial">
                    <input matEndDate placeholder="Fecha fin" formControlName="fechaFinal" (dateChange)="filtroFechas()">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="rango">
                    <mat-icon matDatepickerToggleIcon><i class="fi-rr-calendar"></i></mat-icon>
                </mat-datepicker-toggle>
                <mat-date-range-picker #rango></mat-date-range-picker>
            </mat-form-field>
           
        </div>
        <div fxFlex="20%" fxLayoutAlign="end none">
            <button mat-raised-button color="primary" style="height: 48px;width: 300px;" (click)="getReportGeneral()">Descargar reporte
                <mat-icon style="font-size: 16px;"><i class="fi-rr-download"></i></mat-icon>
            </button>
        </div>
    </div>
    </div>
</div>


