import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NoveltiesService } from 'src/app/modules/nomina/services/novelties.service';

@Component({
  selector: 'app-novelties-codes',
  templateUrl: './novelties-codes.component.html',
  styleUrls: ['./novelties-codes.component.sass']
})
export class NoveltiesCodesComponent implements OnInit {
  public noveltyTypes: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<NoveltiesCodesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private noveltiesService: NoveltiesService,
  ) { }

  ngOnInit() {
    this.getNoveltyTypes();
  }


    /**
   * @author Daniel Dominguez 
   * @createdate 2021-07-28
   * @purpose Obtiene los tipos de novedad con sus subtipos
   */
     getNoveltyTypes(): void {
      this.noveltyTypes = [];
      this.noveltiesService.getNoveltyTypes()
        .subscribe(resp => {
          console.log(resp);
          for (let nType in resp.data) {
            if (resp.data.hasOwnProperty(nType)) {
              let type = resp.data[nType];
              type.key = (type.key < 10) ? `0${ type.key }` : type.key;
              this.noveltyTypes.push(type);
            }
          }
        });
    }
}
