import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { CoachingService } from '../../services/coaching.service';
import { loadAcompañamientos } from '../../state/actions/acompañamiento.actions';
import { loadSelects } from '../../state/actions/selects.actions';

const params = {
	parametro: '',
	fechaInicial: '',
	fechaFinal: '',
	indicador: '',
	numeroReg: 5,
	estado: '',
	tab: 0,
	page: 1,
	reasignado:""
}

@Component({
	selector: 'app-coaching',
	templateUrl: './coaching.component.html',
	styleUrls: ['./coaching.component.sass']
})
export class CoachingComponent implements OnInit, OnDestroy {
	selected = new FormControl(0);
	rol;
	//Observable para los cambios de la tabla
	subscription: Subscription;
	//Variable que determina si se ingreso a la vista con el servicio o travez de una notificacion 
	filter: string = "";
	constructor(
		private store: Store<any>,
		private coachingService: CoachingService,
		private aRoute: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
	) { }

	ngOnInit(): void {
		/**
		* @author Cristian Gallo
		* @createdate 2021-12-10
		* Metodo para ejecutar los servicios para cargar los selects para filtros 
		* como para la creación de un acompañamiento.
		*/
		this.getRoles();
		this.store.dispatch(loadSelects({ rol: this.rol }));
		this.aRoute.paramMap.subscribe(param => {
			params.tab = parseInt(param.get('tab'));
			this.selected.setValue(param.get('tab'));
		})

		this.changeTab(params.tab);
		this.subscription = this.coachingService.refresh$.subscribe(() => {
			this.changeTab(params.tab);
		});

	}

	ngOnDestroy(): void {
		if (this.subscription !== undefined) {
			this.subscription.unsubscribe();

		}
	}

	/**
	* @author Cristian Gallo
	* @createdate 2021-12-10
	* Metodo para validar el tab en el que se encuentra el usuario y asi mismo filtrar la información que se mostrara.
	 */
	changeTab(event?) {
		if (event == 1) {
			params.tab = 12;
		} else {
			params.tab = event;
		}

		this.getParamsUrl();
		if (this.filter !== "reasignacion") {
			this.router.navigateByUrl(`mios/coaching/coaching/${event}`);
			this.selected.setValue(event);
			this.store.dispatch(loadAcompañamientos(params));
			localStorage.setItem('filtros_coaching', JSON.stringify(params));
		} else {
			this.selected.setValue(event);
			
			params.reasignado = '1';
			this.store.dispatch(loadAcompañamientos(params));
			localStorage.setItem('filtros_coaching', JSON.stringify(params));
		}

	}

	getRoles() {
		this.authService.getUser().roles.forEach(rol => {
			if (rol.includes('coaching::')) {
				this.rol = rol;
			}
		});
	}

	/**
   * Metodo que carga los datos por URL para validacion y pbtencion de datos apra carga de tabla
   * @author Juan David Guerrero Vargas
   * @create_date 02/06/2022
   * @return void   
   */
	getParamsUrl(): void {
		this.aRoute.paramMap.subscribe(params => {
			this.filter = params.get('filter');
		});

	}


}
