import { JuridicaService } from './../../../../services/juridica.service';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DisabilitiesService } from 'src/app/modules/nomina/services/rest/disabilities.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-permit-response',
  templateUrl: './permit-response.component.html',
  styleUrls: ['./permit-response.component.sass']
})

export class PermitResponseComponent implements OnInit {
  dataInfo: any;
  public realTime: string;
  public scheduleTime: string;
  public endTime: string;
  public noveltyDetailHeight: number;

  public showJustifyForm: boolean;
  public showJustifyInfo: boolean;
  public showInput: boolean;
  noveltiesForm: FormGroup;
  dateForm: FormGroup;

  selectedOption: any;
  subTypes: any;

  startDate: any;
  endDate: any;
  fieldate: boolean = false;
  files: any = [];
  options: any = [
    { id: 0, name: 'Rechazar' },
    { id: 1, name: 'Aprobar' }
  ];

  constructor(
    private disabilities: DisabilitiesService,
    private permit: JuridicaService,
    public dialogRef: MatDialogRef<PermitResponseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alert: AlertsService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.noveltiesForm = new FormGroup({
      response: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required])
    });

    this.dateForm = new FormGroup({
      start: new FormControl(''),
      end: new FormControl('')
    });

    this.disabilities.getSubTypes().subscribe(resp => {
      this.subTypes = resp.data;
    });

    this.permit.getInfoByPermitId(this.data.data.idNovelty).subscribe(resp => {
      this.dataInfo = resp.data;
      this.files = resp.data.files;
      this.dataInfo.novelty_type.key = (this.dataInfo.novelty_type.key < 10) ? `0${this.dataInfo.novelty_type.key}` : this.dataInfo.novelty_type.key;

      if (this.dataInfo.validForMoreResponses) {
        this.showJustifyForm = true;
      }
      this.showJustifyInfo = true;
      this.changeDetailHeight(this.dataInfo);
    });
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-06-23
   * Metodo para asignacion de detalle
   */
  changeDetailHeight(novelty): void {
    if (novelty.responseFirstInstance && (novelty.responseFirstInstance.justify || novelty.responseFirstInstance.response)) {
      this.noveltyDetailHeight = 240;
    }
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-06-23
   * Metodo para actualizar el estado de la novedad
   */
  updateState() {
    const body = {
      state: this.noveltiesForm.controls['state'].value,
      response: this.noveltiesForm.controls['response'].value,
      extra: 'Aprobada nomina'
    };

    this.permit.changeStatus(this.data.data.idNovelty, body).subscribe(resp => {
      if (this.selectedOption == 1) {
        this.alert.alertSuccessWithoutButton('¡Excelente!', 'Su solicitud ha sido enviada satisfactoriamente');
        this.dialogRef.close('');
      } else {
        this.alert.alertSuccessWithoutButton('¡Excelente!', 'Su solicitud ha sido enviada satisfactoriamente');
        this.dialogRef.close('');
      }
    }, error => {
      this.alert.alertError('Error!', 'Ocurrio un error al intentar actualizar el permiso');
    });
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-06-23
   * Metodo para actualizar el estado de la novedad
   */
  dateRangeChange() {
    const date1: Date = this.dateForm.controls.start.value;
    const date2: Date = this.dateForm.controls.end.value;
    const dateStart: string | null = new DatePipe('en-US').transform(date1, 'yyyy-MM-dd');
    const dateEnd: string | null = new DatePipe('en-US').transform(date2, 'yyyy-MM-dd');
    this.startDate = dateStart;
    this.endDate = dateEnd;
  }

  /**
 * @author Johan Soler
 * @createdate 2022-07-26
 * Metodo para detectar la opcion seleccionada
 */
  changeState(e) {
    this.selectedOption = e.value;
  }
}
