import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ReportService } from '../../../services/rest/reports.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-users-reports-create',
  templateUrl: './users-reports-create.component.html',
  styleUrls: ['./users-reports-create.component.sass']
})
export class UsersReportsCreateComponent implements OnInit {

  reportForm: FormGroup;
  isEdit : boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private form: FormBuilder,
    private reportService : ReportService
  ) { }

  ngOnInit(): void {
    this.formControl();
    if(typeof this.data.item?.id != 'undefined')
    {
      this.isEdit = true;
    }
  }

   /**
   * @author Carlos Nieto
   * @createdate 2022-01-20
   * Metodo donde se establecen las validaciones del formulario
   */
    formControl(): void{
      this.reportForm = this.form.group({
        name: new FormControl('', [Validators.required, Validators.maxLength(35), Validators.minLength(5)]),
        key_powerBi: new FormControl('', [ Validators.required, Validators.maxLength(35), Validators.minLength(5)]),
      });
    }

     /**
   * @author Carlos Nieto
   * @createdate 2022-01-20
   * Metodo que sirve para validar los estados de los errores en los campos del formulario
   */
    get error(): any { return this.reportForm.controls; }

    /**
   * @author Carlos Nieto
   * @createdate 2022-01-20
   * Metodo guarda un reporte
   * @param reportForm formulario con name y key_powerbi
   */
    saveReport(reportForm)
    {
      this.reportService.saveReport(reportForm.value).subscribe(
        response => {
          Swal.fire({
            html: `Reporte agregado correctamente!`,
            icon: 'success',
            title : 'Enviado',
            confirmButtonText: 'Aceptar'
          });
        }
      );
    }

    /**
   * @author Carlos Nieto
   * @createdate 2022-01-20
   * Metodo edita un reporte, agrega el id al objeto
   * @param reportForm formulario con name y key_powerbi
   */
    editReport(reportForm)
    {
      let formValue = reportForm.value;
      formValue.id = this.data.item.id;

      this.reportService.updateReport(formValue).subscribe(
        response => {
          Swal.fire({
            html: `Reporte editado correctamente!`,
            icon: 'success',
            title : 'Enviado',
            confirmButtonText: 'Aceptar'
          });
        }
      );
    }

    /**
   * @author Carlos Nieto
   * @createdate 2022-01-20
   * Metodo que valida si se esta editando o creando
   * @param reportForm formulario con name y key_powerbi
   */
    validExecute(reportForm)
    {
      if(this.isEdit)
      {
        this.editReport(reportForm);
      }else{
        this.saveReport(reportForm);
      }
    }

     /**
   * @author Carlos Nieto
   * @createdate 2022-01-20
   * Metodo que elemina un registro
   */
    deleteReport()
    {
      Swal.fire({
        title: "¿Estás seguro?",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, enviar",
      })
      .then(resultado => {
        if (resultado.value)
        {
          this.reportService.deleteReport(this.data.item.id).subscribe(
            res => {
              Swal.fire({
                html: `Reporte eliminado correctamente!`,
                icon: 'success',
                title : 'Enviado',
                confirmButtonText: 'Aceptar'
              });
            }
          );
        }
      });
    }

}
