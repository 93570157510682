import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BpmsRoutingModule } from './bpms-routing.module';
import { BpmsComponent } from './bpms.component';
import { RequestsModule } from './modules/requests/requests.module';
import { SharedModule } from '../../shared/shared.module';
import { UsersModule } from './modules/users/users.module';
import { SharedFormModule } from './modules/shared-form/shared-form.module';

@NgModule({
  declarations: [BpmsComponent],
  imports: [
    CommonModule,
    BpmsRoutingModule,
    RequestsModule,
    SharedModule,
    UsersModule
  ],
  exports:[
    SharedFormModule
  ]
})
export class BpmsModule { }
