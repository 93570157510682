import { Component, OnInit, Inject } from '@angular/core';
import { ResourcesService } from '../../../services/resources.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { BookingService } from '../../../services/booking.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';


AuthService
@Component({
  selector: 'app-bookings-admin',
  templateUrl: './bookings-admin.component.html',
  styleUrls: ['./bookings-admin.component.sass']
})
export class BookingsAdminComponent implements OnInit {
  displayedColumns = ['actions','resources_associated' , 'user', 'created_at','start', 'status'];
  bookingList =[];
  dataSource:any
  currentPage = 1;
  length: number = 0;
  pageSize: number = 0;
  typeStatus:any= ''
  constructor(public resourcesService:ResourcesService,  private dialog: MatDialog,
    public router: Router,  public auth:AuthService, private bookingService:BookingService, private alertsService:AlertsService) { }

  ngOnInit(): void {
   this.getResources()
  }
  getResources(){
    this.resourcesService.getResources('bookings', {page:this.currentPage, status:this.typeStatus}).subscribe(res=>{
      this.length = res.total;
      this.pageSize = res.per_page;
      this.bookingList =res;
      this.dataSource = new MatTableDataSource(res.data);
    })
  }

  changeStatus(row){

    const statusDialog = this.dialog.open( StatusBooking, {
      width: '25%',
      disableClose: true,
      data: {booking: row}
    });
    statusDialog.afterClosed().subscribe(resp=>{
      if(resp){
        console.log(resp);
        this.bookingService.putBooking(`booking/${row.id}`, resp).subscribe((mess)=>{
          this.alertsService.alertSuccess('¡Actualizado!', mess)
          this.getResources()
        })

      }
    })
  }
  getPaginatorData(e){
    this.currentPage = e.pageIndex + 1;
    this.getResources()
  }

}
@Component({
  selector: 'status-booking',
  templateUrl: './status-booking.html',
  styleUrls: ['./bookings-admin.component.sass']
})
export class StatusBooking implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<StatusBooking>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }
    observations= null;
    rejection=false;
   ngOnInit(): void { }
   rejectionAction(event){
    this. rejection=event
   }
}
