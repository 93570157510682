<h2 mat-dialog-title>{{ data.title }}
	<button style="float: right" mat-icon-button mat-dialog-close>
		<i class="fi-rr-cross"></i>
	</button>
</h2>

<form class="row form" [formGroup]="reportForm" (ngSubmit)="validExecute(reportForm)" style="padding-left: 10px;">
	<mat-dialog-content style="padding-left: 3%;" class="mat-typography">
		<div class="row">
			<div style="padding-right: 30px;">
        <mat-form-field class="col-6" style="padding: 1%;" appearance="fill">
          <mat-label>Nombre del reporte</mat-label>
          <input *ngIf="isEdit" matInput type="text" formControlName="name" required  [ngModel]="data.item.name"/>
          <input *ngIf="!isEdit" matInput type="text" formControlName="name" required/>
          <mat-error *ngIf="error.name.errors"><span *ngIf="error.name.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
          <mat-error *ngIf="error.name.errors"><span *ngIf="error.name.errors.minlength">¡Aún falta! Recibimos mínimo 5 caracteres</span></mat-error>
          <mat-error *ngIf="error.name.errors"><span *ngIf="error.name.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 35 caracteres</span></mat-error>
        </mat-form-field>

        <mat-form-field class="col-6" style="padding: 1%;" appearance="fill">
          <mat-label>key de reporte</mat-label>
          <input *ngIf="isEdit" style="text-transform: capitalize" matInput type="text" formControlName="key_powerBi" required [ngModel]="data.item.key_powerBi"/>
          <input *ngIf="!isEdit" style="text-transform: capitalize" matInput type="text" formControlName="key_powerBi" required />
          <mat-error *ngIf="error.key_powerBi.errors"><span *ngIf="error.key_powerBi.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
          <mat-error *ngIf="error.key_powerBi.errors"><span *ngIf="error.key_powerBi.errors.minlength">¡Aún falta! Recibimos mínimo 5 caracteres</span></mat-error>
          <mat-error *ngIf="error.key_powerBi.errors"><span *ngIf="error.key_powerBi.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 35 caracteres</span></mat-error>
        </mat-form-field>
			</div>
			<br />
		</div>
	</mat-dialog-content>

	<mat-dialog-actions style="height: 48px; padding-bottom: 70px;" align="end">
		<button mat-stroked-button mat-dialog-close>Cancelar</button>
		<button mat-raised-button type="submit" color="primary" disabled="{{ reportForm.invalid }}" [mat-dialog-close]="true">Guardar</button>
    <a *ngIf="isEdit" (click)="deleteReport()" mat-raised-button style="float: right" color="warn" [mat-dialog-close]="true">Eliminar</a>
	</mat-dialog-actions>
</form>
