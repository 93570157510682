import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CoachingState } from "../coaching.state";

const getCoachingState = createFeatureSelector<CoachingState>('COACHING')

// const selectAuditoriaFeature = (state:CoachingState) => state.auditorias;

export const selectedListAuditoria = createSelector(
    getCoachingState,
    (state) => state.auditorias.auditoria,
);

export const selectedLoading = createSelector(
    getCoachingState,
    (state) => state.auditorias.loading,
);