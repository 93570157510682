import { Component, OnInit } from '@angular/core';
import { ReportsService } from 'src/app/modules/nomina/services/rest/reports.service';

@Component({
  selector: 'app-powerbi',
  templateUrl: './powerbi.component.html',
  styleUrls: ['./powerbi.component.sass']
})
export class PowerbiComponent implements OnInit {

  public token: string='';
  public report_id:string='';
  public generar = false;

  constructor(
    private reportService:ReportsService
  ) { }

  ngOnInit(): void {
    this.reportService.getReport('11_DBS_CAL_PBI').subscribe((resp)=>{
      if(resp.data.token !== ''){
        this.token = resp.data.token;
        this.report_id =resp.data.reporte_id;
        this.generar=true;
      }
    });
  }

}
