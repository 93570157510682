<!-- <div class='app-notas col-10' align="center">
  <h2 mat-dialog-title>
    Editar Nota
    <button style="float: right" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
  </h2>
  <mat-divider></mat-divider>
  <form class="row form" [formGroup]="notasForm" (ngSubmit)="update(notasForm)">

    <mat-dialog-content class="mat-typography" class="col-12">
      <mat-form-field appearance="fill" class="col-8">
        <mat-label>Escribe aqui tu nota</mat-label>
        <input type="text" class="col-10" matInput  formControlName="anotacion">
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align="center">

      <button mat-stroked-button type="button"
              mat-dialog-close>
        Cancelar</button>

      <button
        mat-raised-button
        type        = "submit"
        style       = "margin-left: 10px;"
        color       = "primary"
        class       ="bold compras-modal-button"
      >Guardar nota</button>
    </mat-dialog-actions>
  </form>
</div> -->

<button style="float: right" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross-small" style="font-size: 20px !important; color: #353535;"></i>
</button>
<div class="col-12" style="padding: 48px 108px 48px 108px;">
  <h2 mat-dialog-title>
    Editar Nota
  </h2>
  <mat-divider style="border: 1px solid #D8D8D8;margin-bottom: 20px;"></mat-divider>
  <form [formGroup]="notasForm" (ngSubmit)="update(notasForm)" style="margin-right:0px;width: 100%;">

    <div style="margin-top: 48px;width: 100%;">
      <mat-form-field appearance="fill" style="width: 100%;" >
        <mat-label>Escribe aqui tu nota</mat-label>
        <textarea matInput formControlName="anotacion" maxlength="255"></textarea>
      </mat-form-field>
    </div>

    <mat-dialog-actions align="right" style="margin-top: 64px; display: block; padding: 0 !important;">
      <button mat-stroked-button type="button" tyle="margin-left: 20px; float: right;" class="cancelButton" (click)="opcionCancelar()">Cancelar</button>

      <button mat-raised-button type="submit" style="margin-left: 20px; float: right;" color="primary"
        class="myButton2">Guardar nota</button>
    </mat-dialog-actions>
  </form>
</div>