<button style="float: right;" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross"></i>
</button>
<div class="retroalimencion" fxLayout="column">
  <div fxLayout="row" class="mt-3">
    <div fxFlex="86.5%">
      <h2>{{config.labels.idAuditoria}} - {{datos}}</h2>
    </div>
    <div fxFlex="10%">
      <div fxLayout="row" style="cursor: pointer;" *ngIf="is_coaching && !is_asesor" (click)="nuevoCoaching()">
        <p class="btn-coaching">{{config.labels.coaching}}</p>&nbsp;&nbsp;
        <mat-icon matPrefix style="font-size: 16px; margin-top: .3rem;"><i class="fi-rr-eye"></i></mat-icon>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z0 mt-2 mb-4" fxFlex="100%">
    <table [dataSource]="dataSource" mat-table matSort>
      <!-- Columna matriz -->
      <ng-container matColumnDef="matriz">
        <th mat-header-cell *matHeaderCellDef>{{config.labels.matriz}}</th>
        <td mat-cell *matCellDef="let element">{{ element.nombre_matriz}}</td>
      </ng-container>

      <!-- Columna Fecha auditoría -->
      <ng-container matColumnDef="fecha_auditoria">
        <th mat-header-cell *matHeaderCellDef>{{config.labels.fechaAuditoria}}</th>
        <td mat-cell *matCellDef="let element">{{ element.fecha_auditoria | date:'dd/MM/yyyy'}} </td>
      </ng-container>

      <!-- Columna Tipo de auditoría -->
      <ng-container matColumnDef="tipo_auditoria" *ngIf="!is_asesor">
        <th mat-header-cell *matHeaderCellDef>{{config.labels.tipoAuditoria}}</th>
        <td mat-cell *matCellDef="let element">{{ element.tipo_audioria  }} </td>
      </ng-container>

      <!-- Columna Calificación -->
      <ng-container matColumnDef="calificacion">
        <th mat-header-cell *matHeaderCellDef>{{config.labels.calificacion}}</th>
        <td mat-cell *matCellDef="let element">{{ element.calificacion | number:'1.0-1'}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{config.labels.noDataToShow}}</td>
      </tr>
    </table>
  </div>
  <div fxFlex="100%" [formGroup]="calificacionesForm" class="mb-4">
    <div fxFlex="60%" style="overflow: auto; height: 350px;">
      <div formArrayName="modulos" *ngFor="let modulo of modulesForm.controls; let iModulo = index">
        <div [formGroupName]="iModulo">
          <mat-expansion-panel togglePosition="before" [expanded]="iModulo == 0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p class="sigla">{{modulo.get('detalle_item').value.sigla}}</p>
                <p>{{modulo.get('detalle_item').value.nombre}}</p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div formArrayName="items" *ngFor="let item of modulo.get('items').controls;let iItem= index">
              <div [formGroupName]="iItem">
                <mat-expansion-panel hideToggle>
                  <mat-expansion-panel-header class="mt-3">
                    <mat-panel-title fxFlex="33%">
                      <p class="sigla">{{item.get('detalle_item').value.sigla}}</p>
                      <p [matTooltip]="item.get('detalle_item').value.nombre"
                         [matTooltipPosition]="'above'">{{item.get('detalle_item').value.nombre |cutText:0:9}}</p>
                    </mat-panel-title>
                    <mat-panel-description fxFlex="66%">
                      <div *ngIf="tipo_matriz == 'Cumplimiento'">
                        <mat-radio-group aria-label="Select an option" (change)="calificacionItem(item)" formControlName="calificacion">
                          <mat-radio-button [value]="item.get('cumple').value" (click)="$event.stopPropagation()">{{config.labels.cumple}}</mat-radio-button>
                          <mat-radio-button [value]="item.get('no_cumple').value" (click)="$event.stopPropagation()">{{config.labels.noCumple}}</mat-radio-button>
                          <mat-radio-button [value]="item.get('no_aplica').value" (click)="$event.stopPropagation()">{{config.labels.noAplica}}</mat-radio-button>
                        </mat-radio-group>
                      </div>
                      <div *ngIf="tipo_peso == 'Peso en Items'">
                        <mat-radio-group aria-label="Select an option" (change)="calificacionItem(item)" formControlName="calificacion">
                          <mat-radio-button [value]="item.get('si').value" (click)="$event.stopPropagation()">{{config.labels.si}}</mat-radio-button>
                          <mat-radio-button [value]="item.get('no').value" (click)="$event.stopPropagation()">{{config.labels.no}}</mat-radio-button>
                          <mat-radio-button [value]="item.get('no_aplica').value" (click)="$event.stopPropagation()">{{config.labels.noAplica}}</mat-radio-button>
                          <mat-radio-button *ngIf="item.get('error_critico').value != 1 && aplica_para_cp === 1" [value]="item.get('cp').value" (click)="$event.stopPropagation()">{{config.labels.cp}}</mat-radio-button>
                        </mat-radio-group>

                      
                      </div>
                      <div *ngIf="tipo_peso  == 'Peso en Módulos'">
                        <mat-radio-group aria-label="Select an option" (change)="calificacionItem(item)" formControlName="calificacion">
                          <mat-radio-button [value]="item.get('si').value" (click)="$event.stopPropagation()">{{config.labels.si}}</mat-radio-button>
                          <mat-radio-button [value]="item.get('no').value" (click)="$event.stopPropagation()">{{config.labels.no}}</mat-radio-button>
                          <mat-radio-button [value]="item.get('no_aplica').value" (click)="$event.stopPropagation()">{{config.labels.noAplica}}</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <mat-form-field class="col-12" appearance="fill"  *ngIf="commentApply">
                    <mat-label>{{config.labels.comentario}}</mat-label>
                    <textarea type="number" matInput formControlName="comments" maxlength="101"></textarea>
                  </mat-form-field>
                  <div formArrayName="subitems" *ngFor="let subtItem of item.get('subitems').controls;let iSubItem = index">
                    <div [formGroupName]="iSubItem">
                      <mat-expansion-panel hideToggle>
                        <mat-expansion-panel-header class="sub-item mt-3">
                          <mat-panel-title fxFlex="46%">
                            <p class="sigla-sub ms-4">{{subtItem.get('detalle_item').value.sigla}}</p>
                            <button mat-icon-button [disabled]="!subtItem.get('detalle_item').value.descripcion">
                              <mat-icon style="font-size: 1.3em; margin-top: -.7em;"
                                        [matTooltip]="subtItem.get('detalle_item').value.descripcion" [matTooltipPosition]="'above'"><i class="fi-rr-document"></i></mat-icon>
                            </button>
                            <p class="sub-item"
                               [matTooltip]="subtItem.get('detalle_item').value.nombre"
                               [matTooltipPosition]="'above'">{{subtItem.get('detalle_item').value.nombre | cutText:0:7}}</p>
                          </mat-panel-title>
                          <mat-panel-description fxFlex="54%">
                            <mat-radio-group aria-label="Select an option" formControlName="calificacion" class="calificacion-sub">
                              <mat-radio-button [value]="subtItem.get('cumple').value" (click)="$event.stopPropagation()">{{config.labels.cumple}}</mat-radio-button>
                              <mat-radio-button [value]="subtItem.get('no_cumple').value" (click)="$event.stopPropagation()">{{config.labels.noCumple}}</mat-radio-button>
                              <mat-radio-button [value]="subtItem.get('no_aplica').value" (click)="$event.stopPropagation()">{{config.labels.noAplica}}</mat-radio-button>
                            </mat-radio-group>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <mat-form-field class="col-12" appearance="fill"  >
                          <mat-label>{{config.labels.comentario}}</mat-label>
                          <textarea type="number" matInput formControlName="comments" maxlength="101"></textarea>
                        </mat-form-field>
                      </mat-expansion-panel>
                    </div>
                  </div>
                </mat-expansion-panel>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
      </div>
    </div>
    <div fxFlex="40%" fxLayout="column" class="ms-3" mat-dialog-content style="overflow: auto; height: 350px;">
      <mat-form-field appearance="fill">
        <mat-label>{{config.labels.observacionAuditoria}}</mat-label>
        <textarea matInput formControlName="txtAuditoria"></textarea>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{config.labels.retroAuditor}}</mat-label>
        <textarea matInput formControlName="retroalimentacion_auditor"></textarea>
        <mat-error>{{config.labels.retroalimentacionError}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{config.labels.retroSupervisor}}</mat-label>
        <textarea matInput formControlName="retroalimentacion_supervisor"></textarea>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{config.labels.compromisoCalidad}}</mat-label>
        <textarea matInput formControlName="compromiso_calidad"></textarea>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{config.labels.compromisoOperacion}}</mat-label>
        <textarea matInput formControlName="compromiso_operacion"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxFlex="100%" fxLayoutAlign="center center" *ngIf="verBtns" class="mt-2">
    <button mat-stroked-button class="btns-dialog me-3" (click)="retroalimentacionRef.close()">{{config.labels.cancelar}}</button>
    <button mat-raised-button color="primary" class="btns-dialog" (click)="updateRetroalimentacionById()" *ngIf="!is_asesor">{{config.labels.guardar}}</button>
    <button mat-raised-button color="primary" class="btns-dialog" (click)="updateAuditoria()" *ngIf="is_asesor"
            [disabled]="calificacionesForm.get('compromiso_calidad').enabled || calificacionesForm.get('compromiso_operacion').enabled ? false: true">
      {{config.labels.guardar}}
    </button>
  </div>
</div>
