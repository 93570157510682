<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross"></i>
</button>
<h3 mat-dialog-title>Cambiar de estado</h3>
<hr>
<mat-dialog-content>

  <strong>Solicitante</strong>
  <p>{{data.booking.user[0].name}}</p>
  <strong>Cargo solicitante</strong>
  <p>{{data.booking.user[0].position}}</p>

  <strong>Correo solicitante</strong>
  <p>{{data.booking.user[0].email}}</p>
  <br>
  <strong>Fecha y hora de inicio</strong>
  <p>{{data.booking.start}}</p>
  <strong>Fecha y hora de fin</strong>
  <p>{{data.booking.end}}</p>
  <br>
  <strong>Recursos</strong>
  <ul>
    <li *ngFor="let element of data.booking.resources_associated">
      <p>{{element.resource.name}}</p>
    </li>
  </ul>
  <mat-form-field appearance="fill" style="width: 100%;" *ngIf="rejection">
    <mat-label>Motivo del rechazo</mat-label>
    <textarea [(ngModel)]="observations" matInput rows="5"></textarea>
  </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="!rejection">
  <button matSuffix mat-stroked-button (click)="rejectionAction(true)" color="warn" [disabled]="data.booking.status == 3">Rechazar</button>
   <button matSuffix mat-flat-button [mat-dialog-close]="{status:2, observations:observations}" color="primary" [disabled]="data.booking.status == 2">Aprobar</button>

</mat-dialog-actions>
<mat-dialog-actions align="center" *ngIf="rejection">
  <button matSuffix mat-stroked-button (click)="rejectionAction(false)">Cancelar</button>
   <button matSuffix mat-flat-button [mat-dialog-close]="{status:3, observations:observations}" color="primary" [disabled]="data.booking.status == 3">Rechazar</button>
</mat-dialog-actions>
