import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { ClientesService } from '../../services/rest/clientes.service';
import * as moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'app-ccomercial-create',
  templateUrl: './ccomercial-create.component.html',
  styleUrls: ['./ccomercial-create.component.sass']
})
export class CcomercialCreateComponent implements OnInit {

  contactosForm :FormGroup;
  idcliente: any;

  constructor(public dialog:MatDialog,
               public dialogRef: MatDialogRef<CcomercialCreateComponent>,
               private clienteservice:ClientesService,
               private alertsService: AlertsService,
               private form: FormBuilder,
               private authService:AuthService, 
               @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.formControl();
    this.idcliente = this.data.Nidcliente;
  }

  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para cancelar la creación del contacto
  */ 

  opcionCancelar(){
    this.alertsService.alertWarning('¿Estás seguro?','¿Estás seguro de que <b>deseas cancelar</b> este proceso? Recuerda que la información se borrará automáticamente si realizas esta acción.').then((result)=>{
      if(result.isConfirmed){
      this.dialogRef.close();
      }
    });
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para definicion de controles del formulario
  */ 
  formControl(): void {
    this.contactosForm = new FormGroup({
    contact_nombre: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
    contact_cargo: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.minLength(1)]),
    contact_email: new FormControl('', [Validators.required, Validators.email ,Validators.maxLength(100), Validators.minLength(1)]),
    contact_phone: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.minLength(7)]),
    fecha_cumpleanos: new FormControl(''),
    id_cliente: new FormControl('')
    });
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para captura de errores de los controles
  */ 
  get error(): any { return this.contactosForm.controls; }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para captura de errores de los controles
  */ 
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  /**
  * @author Javier Castañeda
  * @createdate 2022-11-03
  * Metodo para la creación del contacto
  */ 
  saveContacto(rolForm): void {
    let formData = new FormData();
    let date = this.contactosForm.value.fecha_cumpleanos ?  moment(moment(this.contactosForm.value.fecha_cumpleanos).add(1,'days')).tz('America/Bogota').format('YYYY-MM-DD') : '';
    formData.append('nameContact', this.contactosForm.value.contact_nombre);
    formData.append('chargeContact', this.contactosForm.value.contact_cargo);
    formData.append('phoneContact', this.contactosForm.value.contact_phone);
    formData.append('emailContact', this.contactosForm.value.contact_email);
    formData.append('birthdayContact', date);
    formData.append('client_id', this.idcliente);

    this.clienteservice.saveContacto(formData).subscribe((resp)=>{
      this.alertsService.alertSuccessWithoutButton('Excelente', 'Contacto Creado');

    });
  }

}
