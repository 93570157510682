import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { OnBoardingComponent } from '../../shared/on-boarding/on-boarding.component';
import {TranslatesService} from './services/translates.service';

@Component({
  selector: 'app-calidad',
  templateUrl: './calidad.component.html',
  styleUrls: ['./calidad.component.sass']
})
export class CalidadComponent implements OnInit {

  public userName: any;
  public config: any;
  private uuid = 'cf4c624c-8f01-11ee-b9d1-0242ac120002';

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.userName = this.authService.decryptToken();
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
  }

  onBoadingCalidad(): void {
    const landingRef = this.dialog.open(OnBoardingComponent,{
      disableClose: true,
      panelClass: 'full-screen-modal',
    });
  }

    setLocale(lang: any) {
      localStorage.setItem('locale', JSON.stringify(lang));
      this.ngOnInit();
      window.location.reload();
    }
}
