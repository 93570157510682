import { Component, OnInit, Input, Output, ViewChild, EventEmitter, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { QuestionControlService } from 'src/app/core/services/utils/question-control.service';
import { CandidatesService } from 'src/app/modules/rrhh/services/candidates.service';
import { AlertsService } from '../alerts/alerts.service';
import { secondSection, education, personal_references, jobs_references,  civilStates } from './edit-questions';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'shared-cv-view',
  templateUrl: './cv-view.component.html',
  styleUrls: ['./cv-view.component.sass']
})
export class CvViewComponent implements OnInit {
  _person: any;
  startDate:any
  emailInput: boolean = false
  email = new FormControl(null, Validators.email)
  @Input() canUpdateEmail = false
  @Input() candidateStatus: any = null
  @Input() edit: any = false
  @Input()
  public set person(data:any)
  {
    this._person = data
    if(this._person.agreementRules){
      this.startDate =  data.agreementRules['fecha_de_ingreso']
    }
    this.email.setValue(this._person.email)
    this.findPhoto(this._person?.attachements)

  }
  @Output() editEvent = new EventEmitter<any>();
  showEdit:boolean= false
  secordGroup:FormGroup

  _secondSection:any[] =  secondSection
  _education= education
  _personal_references= personal_references
  _jobs_references= jobs_references
  _civilStates= civilStates
  photo:any
  constructor(private fb:FormBuilder, private serviceCandidate: CandidatesService, private sanitizer: DomSanitizer,
    private qcs: QuestionControlService, public alertService:AlertsService) { }

  ngOnInit(): void {
    this.checkDependencies()
  }
  checkDependencies(){
    this._secondSection.forEach(question => {
      if(question.dependencies.length > 0 && (question.value == "" || question.value == null) ){
        question.disabled = true
      }
    });
  }
   /**
   * @author Karol García
   * @createdate 2021-06-02
   * Añade nuevos items 
   */
  addDependencies(event){
    if(event && event.isFather){
      this._secondSection.forEach(question => {
          question.dependencies.forEach(element => {
            if(element.idField == event.idFather && element.name == event.name){
              this.secordGroup.get(question.key).enable();
            }
            if(element.idField == event.idFather && element.name != event.name){
              question.disabled = true
              question.value = ""
              this.secordGroup.get(question.key).disable();
              this.secordGroup.get(question.key).setValue(question.value);
             }
          });
        });
    }

  }
  findPhoto(documents){
    if(documents && this._person.id){
    
      const found = documents.find(element => element.file_attachment_id == 8);
      if(found){
        let direction= `candidate/download/attachment/${this._person.id}/${found.id}`
        this.serviceCandidate.downdloadFile(direction).subscribe( (file) =>{
         this.photo= file
        })
      }
 
    }
   
  }
   /**
   * @author Karol García
   * @createdate 2021-05-27
   * Añade nuevos items 
   */
  addItem(type:string){
    if(this.person[type] == null) this.person[type]=[]
    switch (type) {
      case 'education':
        this.person[type].push(this._education)
        break;
      case 'personal_references':
        this.person[type].push(this._personal_references)
        break;
      case 'jobs_references':
        this.person[type].push(this._jobs_references)
        break;
    }

  }
    /**
   * @author Karol García
   * @createdate 2021-05-27
   * Remueve items 
   */
  removeItem(type:string, pos:number){
    this.person[type].splice(pos, 1)
  }
      /**
   * @author Karol García
   * @createdate 2021-05-27
   * Activa la opción de editar la hoja de vida
   */
  editData(){
    this.secordGroup = this.qcs.toFormGroup(this._secondSection);
    if(this._person.personal_data != null)this.secordGroup.patchValue(this._person.personal_data)
    this.showEdit= true
  }
   /**
   * @author Karol García
   * @createdate 2021-06-02
   * Remueve items 
   */
  submitData(){
    let response =this._person
    response.personal_data= this.secordGroup.getRawValue()
    let confirm = this.alertService.alertConfirm('¿Estás seguro que deseas actualizar la hoja de vida?')
    confirm.then(res=>{
      if(res.isConfirmed){
        this.editEvent.emit(response)
        this.showEdit= false
      }
    })
  }
   /**
   * @author Karol García
   * @createdate 2021-06-02
   * Remueve items 
   */
  cancelAction(){
    let confirm = this.alertService.alertConfirm('¿Estás seguro que deseas cancelar la edición?')
    confirm.then(res=>{
      if(res.isConfirmed){
        this.showEdit= false
      }
    })
  }

  @ViewChild('target') target

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {
      if (this.emailInput) {
        const clickedInside = this.target.nativeElement.contains(targetElement);
        if (!clickedInside) {
          this.saveEmail()
        }
      }
  }

  saveEmail() {
      if (this.email.valid) {

        if (this._person.email !== this.email.value) {
          this.serviceCandidate.changeCandidateEmail(this._person.id, {email: this.email.value}).subscribe(
            res => {
              const message: any = res
              this.alertService.alertSuccess('¡Bien!', message)
              this._person.email = this.email.value
              this.emailInput = false
            }
          )
        }
        else {
          this.emailInput = false
        }
      }
      else {
        this.email.markAsTouched()
      }
    
  }
}
