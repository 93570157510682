import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AuditoriaService } from 'src/app/modules/calidad/services/auditoria.service';
import { InteligenciaService } from 'src/app/modules/calidad/services/inteligencia.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import {TranslatesService} from '../../../../../../services/translates.service';

@Component({
  selector: 'app-form-inteligencia',
  templateUrl: './form-inteligencia.component.html',
  styleUrls: ['./form-inteligencia.component.sass']
})
export class FormInteligenciaComponent implements OnInit {
  private uuid = '93e8e0fe-92b7-11ee-b9d1-0242ac120002';
  public config: any;

  preguntas = [];
  inteligenciaForm:FormGroup;
  constructor(
    public inteligenciaRef:MatDialogRef<FormInteligenciaComponent>,
    @Inject(MAT_DIALOG_DATA) public datos:any,
    private inteligenciaService:InteligenciaService,
    private fb:FormBuilder,
    private auditoriaService:AuditoriaService,
    private configService: TranslatesService,
  ) { }

  ngOnInit(): void {
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
    this.createForm();
    this.getInteligenciaById();
  }

  createForm(){
    this.inteligenciaForm = this.fb.group({
      preguntas_activas: this.fb.array([])
    })
  }

  getInteligenciaById(){
    const inteligenciaQuestions = <FormArray>this.inteligenciaForm.controls['preguntas_activas'];
    this.inteligenciaService.getFormById(this.datos.inteligencia_id).subscribe((resp:any)=>{
      this.preguntas = resp.preguntas_acivas;
      this.preguntas.forEach(pregunta => {
        let opciones = [];
        let escalas = [];
        let is_required = pregunta.pregunta.requerida;
        let validador;
        let validador_otra;
        if (pregunta.pregunta.opciones_respuesta_form_pregunta_activos) {
          opciones = pregunta.pregunta.opciones_respuesta_form_pregunta_activos;
        }
        if (pregunta.pregunta.escala) {
          escalas = pregunta.pregunta.escala;
        }
        if (is_required == 1) {
          validador = ['',Validators.required];
          validador_otra = ['',Validators.required];
        }else{
          validador = '';
          validador_otra = '';
        }
        inteligenciaQuestions.push(this.fb.group({
          id:pregunta.business_intelligence_question_id,
          name:pregunta.pregunta.question,
          respuesta_usuario: validador,
          respuesta_usuario_otra: {value:'',disabled:true},
          tipo_pregunta_id: pregunta.pregunta.tipo_pregunta.id,
          tipo_pregunta: pregunta.pregunta.tipo_pregunta.key,
          opciones: this.fb.array(opciones),
          escalas: this.fb.array(escalas),
        }))
      });
    })
  }

  get inteligenciaQuestions(){
    return this.inteligenciaForm.get('preguntas_activas') as FormArray;
  }

  selectMultiple(index:FormGroup){
    if (index.get('respuesta_usuario').value === 'otra') {
      index.get('respuesta_usuario_otra').enable();
    }else{
      index.get('respuesta_usuario_otra').disable();
    }
  }

  cambioFecha(pregunta:FormControl){
    pregunta.get('respuesta_usuario').setValue(moment(pregunta.get('respuesta_usuario').value).format('yyyy-MM-DD'));
  }

  sendRequest(){
    if (this.inteligenciaForm.invalid) {
      return
    }
    const inteligenciaQuestions = <FormArray>this.inteligenciaForm.controls['preguntas_activas'];
    inteligenciaQuestions.controls.forEach(grupo => {
      if (grupo.get('respuesta_usuario').value != 'otra') {
        grupo.get('respuesta_usuario_otra').setValue(null);
      }
      grupo.get('respuesta_usuario_otra').enable();
    });
    this.inteligenciaForm.addControl('id_form',new FormControl(this.datos.inteligencia_id));
    Object.assign(this.datos,{formulario:[this.inteligenciaForm.value]});
    this.auditoriaService.createAuditoria(this.datos).subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202) {
        Tools.swalSuccess(this.config.modal.excellent,this.config.modal.requestOk);
        this.inteligenciaRef.close(resp.respuesta.codigo);
      }else if (resp.respuesta.codigo != 202) {
        Tools.swalError('¡Oops!',this.config.modal.requestError);
        inteligenciaQuestions.controls.forEach(grupo => {
          grupo.get('respuesta_usuario_otra').disable();
        });
      }
    });
  }

}
