<div class="row novelty-row-padding">

  <h2 mat-dialog-title>
    <span class="gray-color" style="margin-left: -9px"></span>
    <button style="float: right;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
    <div class="title content" style="width: 100%;">
      <span>
        <h1>{{data.data?.name || 'Juan Manuel Gómez'}}</h1>
        <h5 style="font-size: 15px; font-family: 'Poppins', sans-serif!important;">{{data.data?.id_number || 123456789}}
        </h5>
      </span>
    </div>
  </h2>


  <mat-dialog-content style="padding: 3%; overflow-x: hidden" class="mat-typography">
    <div class="row">
      <div class="col-6">

        <div>
          <br>
          <h4><b>Solicitud Vacaciones</b></h4>
          <p class="p-color"> <strong>Desde: </strong> {{ dataInfo?.start_date | date:'dd/MM/yyyy' }} <strong>Hasta:
            </strong> {{dataInfo?.end_date | date:'dd/MM/yyyy' }}</p>
          <p class="p-color"> <strong>Días disponibles: </strong> {{ availableDays }} <strong>Días hábiles solicitados:
            </strong> {{ businessDays }}</p>
        </div>

        <div>
          <br>
          <h4 class="gray-color"><b>Observaciones del trabajador</b></h4>
          <p class="p-color">{{ dataInfo?.first_response?.justify }}</p>
        </div>

        <!-- state -->
        <div *ngIf="showJustifyInfo">
          <h4 class="gray-color"><b>Estado</b></h4>
          <p class="p-color">{{ dataInfo?.nameState }}</p>
        </div>

        <!-- boss response -->
        <div>
          <br>
          <h4><b>Aprobacion jefe de área</b></h4>
          <p class="p-color"> <strong>Fecha de aprobacion: </strong> {{ dataInfo?.first_response?.response == null ? '' : dataInfo?.first_response.updated_at | date:'dd/MM/yyyy' }} </p>
          <p class="p-color"><b>Observaciones jefe de área</b></p>
          <p class="p-color">{{ dataInfo?.first_response?.response }}</p>
          <p class="p-color"><b>Gestionado por:</b></p>
          <p class="p-color">{{ dataInfo?.first_response?.aprovationUser }}</p>
        </div>
        <!-- end -->

        <!-- vacations response -->
        <div *ngIf="data.type == 'vacaciones' || 'trabajador' ">
          <br>
          <h4><b>Respuesta Vacaciones</b></h4>
          <p class="p-color"> <strong>Fecha de aprobacion: </strong> {{ dataInfo?.second_response.updated_at |
            date:'dd/MM/yyyy' }} </p>
          <p class="p-color"><b>Observacion:</b></p>
          <p class="p-color">{{ dataInfo?.second_response?.response }}</p>
          <p class="p-color"><b>Gestionado por:</b></p>
          <p class="p-color">{{ dataInfo?.second_response?.aprovationUser }}</p>
        </div>

      </div>
      <div class="col-6">
        <div class="text-center">
          <img src="./assets/images/vacations.png" alt="vacationIcon">
        </div>
      </div>
    </div>
  </mat-dialog-content>


</div>