import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import Swal from 'sweetalert2';

// Dialogs
import { ChooseCampaignComponent } from './modules/shared/dialogs/choose-campaign/choose-campaign.component';

// Models
import { CampaignUser } from './modules/shared/models/campaign-user';

// Servicios
import { LocalStorageService } from './modules/shared/services/localStorage.service';
import { CampaignService } from './modules/shared/services/campaign.service';
import { OnBoardingComponent } from 'src/app/shared/on-boarding/on-boarding.component';
import { AuthService } from 'src/app/core/services/rest/auth.service';

@Component({
	selector: 'app-cartera-wom',
	templateUrl: './cartera-wom.component.html',
	styleUrls: ['./cartera-wom.component.sass']
})
export class CarteraWomComponent implements OnInit {
	rrhh_id: number; // Id del usuario logueado.
	public userName: any;


	constructor(public dialog: MatDialog, private campaignService: CampaignService, private localStorageService: LocalStorageService, private router: Router, private spinner: NgxSpinnerService, private authService: AuthService,) {}

	ngOnInit(): void {
		this.localStorageService.removeCampaign();
		this.rrhh_id = this.localStorageService.getRRHHID();
		this.getCampaignsByUser(this.rrhh_id);
		this.userName = this.authService.decryptToken();

	}

	/**
	 * Método para obtner las campañas en las cuales se encuentra el usuario logueado en la aplicación.
	 * @param id id del usuario logueado
	 * @author Carlos Gomez
	 * @createdate 2021-05-21
	 */
	getCampaignsByUser(id: number): void {
		this.campaignService.getCampaignsByUser(id).subscribe((campaigns) => this.validateCampaigns(campaigns));
	}

	/**
	 * Método para validar las campañas de vinculadas al usuario.
	 * @param campaigns campañas del usuario
	 * @author Carlos Gomez
	 * @createdate 2021-05-21
	 */
	validateCampaigns(campaigns: CampaignUser[]): void {
		this.spinner.show();
		const campaignsValid = campaigns.filter((campaign) => campaign.is_active === 1);
		if (campaignsValid.length === 0) {
			this.spinner.hide();
			this.showDialogNoCampaign();
		} else if (campaignsValid.length === 1) {
			this.localStorageService.setCampaignUser(campaignsValid[0]);
			this.spinner.hide();
		} else {
			this.spinner.hide();
			this.showDialogChooseCampaign(campaignsValid);
		}
	}

	/**
	 * Método para mostrar un mensaje de error cuando el usuario no tiene ninguna campaña activa a la cual pueda ingresar
	 * @author Carlos Gomez
	 * @createdate 2021-05-21
	 */
	showDialogNoCampaign(): void {
		Swal.fire({
			icon: 'error',
			title: '¡Lo sentimos!',
			text: 'No tienes ninguna campaña activa',
			showConfirmButton: false,
			timer: 3000,
			allowOutsideClick: false
		});

		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['/mios/ciu']));
	}

	/**
	 * Método para mostrar el dialogo con las campañas del usuario.
	 * @param campaigns campañas del usuario
	 * @author Carlos Gomez
	 * @createdate 2021-05-21
	 */
	showDialogChooseCampaign(campaigns: CampaignUser[]): void {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = 'dialog_chooseCampaign';
		dialogConfig.data = {
			campaigns
		};
		dialogConfig.disableClose = true;

		// Abre el dialogo
		this.dialog.open(ChooseCampaignComponent, dialogConfig);
	}


	onBoadingCalidad(): void{
		const landingRef = this.dialog.open(OnBoardingComponent,{
		  disableClose: true,
		  panelClass: 'full-screen-modal',
		})
	  }
}
