import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DataAuditoria } from 'src/app/modules/auditoria/interfaces/dataAuditoria.interface';
import { AuditoriaService } from 'src/app/modules/auditoria/services/auditoria.service';
import { MatrizService } from 'src/app/modules/auditoria/services/matriz.service';

import { FormAuditarComponent } from "../form-auditar/form-auditar.component";


@Component({
  selector: 'app-detalle-auditoria',
  templateUrl: './detalle-auditoria.component.html',
  styleUrls: ['./detalle-auditoria.component.sass']
})
export class DetalleAuditoriaComponent implements OnInit, OnDestroy {
  
  @ViewChild(FormAuditarComponent) FormAuditar;

  title:string = '';
  creacion:string = '';
  tipo_auditar = {interna:0,cliente:0};
  verCalificacion:boolean = false;
  dataHijo:DataAuditoria={
    modulos:[],
    tipo_matriz:'',
    tipo_peso:'',
    pesoMinimo:0,
    calificacion:'',
  }
  inteligencia;
  idMatriz;
  maximo = 0;
  peso_actual = 0;
  calificacion = '';
  dataCalificacion = {modulos:[],calificacion:0};
  selected = new FormControl(0);

  trimestre_puntaje = 0;
  mes_puntaje = 0;
  hoy_puntaje = 0;
  totales = "";
  isRetentionMatrix: number = 0;
  showFieldDuration: number = 0;


  //Observable para los cambios en los servicios de auditoria
  subscription:Subscription; 
  constructor(
    private matrizService:MatrizService,
    private aRoute:ActivatedRoute,
    private auditoriaService:AuditoriaService,
    private router:Router,
  ) { }

  ngOnInit(): void {
    this.aRoute.paramMap.subscribe(params=>{
      this.idMatriz = params.get('id');
      this.selected.setValue(params.get('tab'));
      this.ocultar_ver_gestion(params.get('tab'));
    });
    this.getMatrixById();
    this.getPuntajes();
    this.subscription = this.auditoriaService.refresh$.subscribe(()=>{
      //this.getMatrixById();
    });

    
  }


  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  ocultar_ver_gestion(val){
    console.log(val);
    const div = document.getElementById("su_gestion");
    if(val==1){
       document.getElementById("su_gestion").style.display = "none";
    }else{
       document.getElementById("su_gestion").style.display = "block";
    }
  }

  changePage(event){
    this.selected.setValue(event);
    this.ocultar_ver_gestion(event);
  
    this.router.navigateByUrl(`/mios/auditoria/auditoria/detail/${event}/${this.idMatriz}`);


  }

   getPuntajes(){
    this.auditoriaService.getPuntajes().subscribe((resp:any)=>{
      this.trimestre_puntaje = resp[0].auditoriasTrimestre;
      this.mes_puntaje = resp[0].auditoriasMes;
      this.hoy_puntaje = resp[0].auditoriasHoy;
    });
  }

  getMatrixById(){
    let items = [];
    this.matrizService.getMatrixById(this.idMatriz).subscribe((resp:any)=>{
      this.title = resp[0].matriz.nombre;
      this.dataHijo.tipo_matriz = resp[0].matriz.tipo_matriz.nombre;
      this.creacion = moment(resp[0].matriz.created_at).format('DD/MM/yyyy hh:mm a');
      this.dataHijo.pesoMinimo = resp[0].matriz.valor_peso_validacion.minimo;
      this.maximo = resp[0].matriz.valor_peso_validacion.maximo;
      this.peso_actual = resp[0].matriz.peso_actual;
      this.isRetentionMatrix = resp[0].matriz.is_retencion_matrix;
      this.showFieldDuration = resp[0].matriz.showFieldDuration;
      if (resp[0].matriz.tipo_peso) {
        this.dataHijo.tipo_peso = resp[0].matriz.tipo_peso.nombre;
      }
      this.dataHijo.modulos = [];
      if (resp[0].matriz.tiene_inteligenciaNegocio == 1) {
        this.inteligencia = resp[0].matriz.inteligenciaNegocio_id;
      }else{
        this.inteligencia = null
      }
      resp[0].matriz.modulo.forEach(modulo => {
        if (modulo.nivel == 1 && modulo.activo == 1) {
          this.dataHijo.modulos.push(modulo);
        }
      });
      this.dataHijo.modulos.forEach(modulo => {
        modulo.items_modulo.forEach((item,index) => {
          if (item.activo == 1) {
            // modulo.items_modulo.splice(index,1);
            items.push(item);
          }
        });
        modulo.items_modulo = items;
        items = [];
      });
      this.verCalificacion = true;
    });
  }



   cancelar(){ 
    this.FormAuditar.cancelar();

  }
   saveForm(){ 
    this.FormAuditar.saveForm();

  }
  

 

}
