<h2 mat-dialog-title>{{data.title}}
    <button style="float: right;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
</h2>


<form class="row form" [formGroup]="userArea" (ngSubmit)="saveArea(userArea)">

<mat-dialog-content style="padding: 3%;" class="mat-typography">

    <mat-form-field style="padding: 1%;" class="col-6" appearance="fill">
        <mat-label>Área</mat-label>
        <mat-select formControlName="area">
          <mat-option *ngFor="let area of areas" [value]="area.id">{{area.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="error.area.errors"><span *ngIf="error.area.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
      </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button mat-raised-button type="submit" color="primary" [mat-dialog-close]="true">Guardar</button>
</mat-dialog-actions>

</form>
