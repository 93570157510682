import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rrhh-request-info',
  templateUrl: './request-info.component.html',
  styleUrls: ['./request-info.component.sass']
})
export class RequestInfoComponent implements OnInit {
@Input() request
  constructor() { }

  ngOnInit(): void {
  }

}
