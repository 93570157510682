<h2 mat-dialog-title>
    {{ data.title }}
    <button style="float: right" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
  </h2>

  <form class="row form" [formGroup]="menuForm" (ngSubmit)="saveMenu(menuForm)">

    <mat-dialog-content style="padding: 3%;" class="mat-typography">

       
  
        <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
            <mat-label>Nombre Menú</mat-label>
            <input style="text-transform: capitalize;" matInput type="text" placeholder="Escribe el nombre del centro de costos" formControlName="name" required cdkFocusInitial>
            <mat-error *ngIf="error.name.errors"><span *ngIf="error.name.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            <mat-error *ngIf="error.name.errors"><span *ngIf="error.name.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
            <mat-error *ngIf="error.name.errors"><span *ngIf="error.name.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 40 caracteres</span></mat-error>
        </mat-form-field>
        
        <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
            <mat-label>Aplicación</mat-label>
            <input type="text"
                   matInput
                   formControlName="applications"
                   [matAutocomplete]="auto"
                   required>
            <mat-autocomplete  #auto="matAutocomplete" [displayWith]='displayFn.bind(this)' required>
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="error.applications.errors"><span *ngIf="error.applications.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            <mat-error *ngIf="error.applications.errors"><span *ngIf="error.applications.errors.invalidAutocompleteObject">Campaña no reconocida. Haga click en una de las opciones de autocompletar</span></mat-error>
        </mat-form-field>
  
        <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
            <mat-label>Icono</mat-label>
            <input style="text-transform: lowercase;" matInput type="text" placeholder="Escribe código" formControlName="icon" required>
            <mat-error *ngIf="error.icon.errors"><span *ngIf="error.icon.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            <mat-error *ngIf="error.icon.errors"><span *ngIf="error.icon.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
            <mat-error *ngIf="error.icon.errors"><span *ngIf="error.icon.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 3 caracteres</span></mat-error>
        </mat-form-field>

        <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
            <mat-label>URL</mat-label>
            <input style="text-transform: lowercase;" matInput type="text" placeholder="Escribe código" formControlName="url" required>
            <mat-error *ngIf="error.url.errors"><span *ngIf="error.url.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            <mat-error *ngIf="error.url.errors"><span *ngIf="error.url.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
            <mat-error *ngIf="error.url.errors"><span *ngIf="error.url.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 3 caracteres</span></mat-error>
        </mat-form-field>

        <mat-form-field style="padding: 1%;" class="col-4" appearance="fill">
            <mat-label>Índice</mat-label>
            <input style="text-transform: lowercase;" matInput type="number" placeholder="Escribe código" formControlName="index" required>
            <mat-error *ngIf="error.index.errors"><span *ngIf="error.index.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
            <mat-error *ngIf="error.index.errors"><span *ngIf="error.index.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
            <mat-error *ngIf="error.index.errors"><span *ngIf="error.index.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 3 caracteres</span></mat-error>
        </mat-form-field>
  
       
  
    </mat-dialog-content>
  
    <mat-dialog-actions align="end">
      <button mat-stroked-button mat-dialog-close>Cancelar</button>
      <button mat-raised-button type="submit" color="primary" disabled='{{menuForm.invalid}}' [mat-dialog-close]="true">Guardar</button>
    </mat-dialog-actions>
  
  </form>