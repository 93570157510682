<button style="float: right" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross-small" style="font-size: 20px !important; color: #353535;"></i>
</button>
<div class="col-12" style="padding: 48px 108px 48px 108px;">
  <h2 mat-dialog-title>
    Crear Nota
  </h2>
  <mat-divider style="border: 1px solid #D8D8D8;margin-bottom: 20px;"></mat-divider>
  <form [formGroup]="notasForm" (ngSubmit)="store(notasForm)" style="margin-right:0px;width: 100%;">

    <div style="margin-top: 48px;width: 100%;">
      <mat-form-field appearance="fill" style="width: 100%;" >
        <mat-label>Escribe aqui tu nota</mat-label>
        <textarea matInput formControlName="anotacion" maxlength="255"></textarea>
      </mat-form-field>
    </div>

    <mat-dialog-actions align="right" style="margin-top: 64px; display: block; padding: 0 !important;">
      <button mat-stroked-button type="button" tyle="margin-left: 20px; float: right;" class="cancelButton" (click)="opcionCancelar()">Cancelar</button>

      <button mat-raised-button type="submit" style="margin-left: 20px; float: right;" color="primary"
        class="myButton2">Guardar nota</button>
    </mat-dialog-actions>
  </form>
</div>