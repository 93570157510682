import { Injectable } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  COMERCIALURL = environment.CRM_CIAL;

  constructor(
    private http: HttpClient
  ) { }
  /**
  * @author javier Castañeda
  * @createdate 2022-10-29
  * Servicio que lista los paises
  * @returns JSON
  */
  getCountries(): Observable<any> {
    return this.http.get<any>(`${this.COMERCIALURL}countries`);
  }
}
