import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

const CIU_URL = environment.CIU_URL;

@Injectable({
  providedIn: 'root'
})
export class LeftNavService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-01
   * Servicio que trae el el menu de cada aplicativo
   * @param id id o key del aplicativo
   */
  getLeftMenu(id: any): Observable<any>{
    const item = localStorage.getItem('locale');
    const lang = item ? JSON.parse(item) : 'es';

    const params = {
      lang: lang,
    };
    return this.http.get<any>(`${CIU_URL}applications/` + id + `/menus`, {params})
    .pipe(
      catchError(this.handleError)
      );
  }

}
