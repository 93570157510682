<i class="fi-rr-cross-small" style="float: right; font-size: 32px; color: #353535;" mat-icon-button mat-dialog-close></i>
<h3 mat-dialog-title>Reproducir grabación</h3>

<mat-dialog-content class="padding-mat-dialog-content" style="padding-top: 10px;">
    <div class="row">
        <div class="col-md-12" style="text-align: center;">
            <audio #audioPlayer controls style="width: 100%; max-width: 400px;">
                <source [src]="audioUrl" type="audio/mpeg">
                Tu navegador no soporta el elemento de audio.
            </audio>
        </div>
        <div class="d-flex justify-content-center">
            <div class="col-md-3 d-flex justify-content-between align-items-center">
                <button mat-icon-button matTooltip="Retrasar" matTooltipPosition="above" color="primary" style="height: 40px; width: 48px; background-color: #2cabbc; border-color: #2cabbc; border-radius: 4px;" (click)="skipBackward()">
                    <i class="fi-sr-rewind" style="color: white;"></i>
                </button>
                <button mat-icon-button matTooltip="Reproducir/Pausa" matTooltipPosition="above" color="primary" style="height: 40px; width: 48px; background-color: #2cabbc; border-color: #2cabbc; border-radius: 4px;" (click)="togglePlay()">
                    <i class="fi-sr-play" style="color: white;"></i>
                </button>
                <button mat-icon-button matTooltip="Adelantar" matTooltipPosition="above" color="primary" style="height: 40px; width: 48px; background-color: #2cabbc; border-color: #2cabbc; border-radius: 4px;" (click)="skipForward()">
                    <i class="fi-sr-forward" style="color: white;"></i>
                </button>
            </div>
        </div>

    </div>

</mat-dialog-content>

