import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uppercaseText'
})
export class UppercaseTextPipe implements PipeTransform {

  transform(value: string): string {
    const firtsString = value.charAt(0).toUpperCase();
    const restString = value.slice(1).toLowerCase();
    return firtsString + restString;
  }

}
