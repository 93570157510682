<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<br>
<h3>Asignar Lider de Formación</h3>
<hr>

<mat-dialog-content>
    <div class="assig">
        <mat-form-field appearance="fill">
            <mat-label>Formador</mat-label>
            <mat-select #trainer (selectionChange)="selectTrainer(trainer.value)">
                <mat-option *ngFor="let row of trainers" [value]="row.id">
                    {{row.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div *ngIf="trainerSelected">
      <p *ngIf="candidates.length == 0">No hay candidatos diponibles para esta acción</p>
      <p *ngIf="candidates.length > 0">Seleccione los candidatos a cargar a este formador</p>
      <mat-selection-list #candidateSelected>
          <mat-list-option checkboxPosition *ngFor="let candidate of candidates" [selected]="candidate.selected" (click)="pushArray(candidateSelected)" [value]="candidate.id">
              {{candidate.name}}
          </mat-list-option>
      </mat-selection-list>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-stroked-button type="button" mat-dialog-close>Cancelar</button>
    <button mat-flat-button color="primary" (click)="assign(trainer.value)" [disabled]="candidatesSelected.length === 0">Asignar</button>
</mat-dialog-actions>
