<div class="modal-container">

<div class="row">
  <div class="col-10">
    <h2 mat-dialog-title class="title">{{ getTitle }}</h2>
  </div>
  <div class="col-2 d-flex justify-content-end">
    <i class="fi-rr-cross-small" style="cursor:pointer; font-size: 20px; color: #353535;" mat-icon-button mat-dialog-close></i>
  </div>
</div>
<mat-divider></mat-divider>

<mat-dialog-content class="mat-typography">
  <form class="row mt-3" [formGroup]="groupForm">
    <div class="col">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Campañas</mat-label>
        <mat-select formControlName="campaigns" multiple>
          <mat-option *ngFor="let campaign of campaigns" [value]="campaign.id" [disabled]="campaign.disabled">{{ campaign.name }}</mat-option>
        </mat-select>
        <mat-hint>Puedes consultar un máximo de 3 campañas</mat-hint>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Nombre del grupo</mat-label>
        <input type="text" matInput formControlName="group_name" required/>
        <mat-error *ngIf="groupForm.get('group_name').hasError('required')">Ingresa el nombre del grupo</mat-error>
        <mat-error *ngIf="groupForm.get('group_name').hasError('minlength')">El nombre del grupo debe tener mínimo 3 caracteres</mat-error>
        <mat-error *ngIf="groupForm.get('group_name').hasError('maxlength')">El nombre del grupo debe tener máximo 100 caracteres</mat-error>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Descripción</mat-label>
        <input  type="text" matInput formControlName="description" required/>
        <mat-error *ngIf="groupForm.get('description').hasError('required')">Ingresa el nombre del grupo</mat-error>
        <mat-error *ngIf="groupForm.get('description').hasError('minlength')">La descripción debe tener mínimo 3 caracteres</mat-error>
        <mat-error *ngIf="groupForm.get('description').hasError('maxlength')">La descripción debe tener máximo 100 caracteres</mat-error>
      </mat-form-field>
    </div>
  </form>

  <div class="row mt-3">
    <div class="col">
      <app-element-selector
      [available_elements]="available_users"
      [selected_elements]="selected_users"
      [foreign_element]="searched_user_to_add"
      [first_list_title]="'Usuarios disponibles'"
      [second_list_title]="'Usuarios seleccionados'"
      (updated_available_elements)="setAvaiableUsers($event)"
      (updated_selected_elements)="setSelectedUsers($event)"
      (confirmation_foreign_element)="clearSearchedUser($event)"
      ></app-element-selector>
    </div>
  </div>

  <form class="row mt-3" [formGroup]="searchUserForm">
    <div class="col-12">
      <h3 class="title">Buscar y agregar usuario</h3>
    </div>

    <div class="col">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Documento</mat-label>
        <input (keydown.enter)="getUserByDocument($event.target.value)" matInput type="text" formControlName="document"  style="width: 167px !important;">
        <mat-select  matSuffix formControlName="document_type" style="width: 40px; margin-left: 5px;">
          <mat-option *ngFor="let document_type of documentTypes" [value]="document_type?.id">{{document_type.key}}</mat-option>
        </mat-select>
        <mat-hint>Ingresa el númnero de documento, el tipo de documento y presiona Enter</mat-hint>
      </mat-form-field>
    </div>

    <div class="col" *ngIf="!isSearchedUserEmpty()">
      <div class="row d-flex align-items-center h-100">
        <div class="col d-flex justify-content-end">
          <p class="fw-bold">{{ searched_user?.name }}</p>
        </div>
        <div class="col">
          <button mat-icon-button color="primary" (click)="setSearchedUserToAdd()">
            <i class="fi-rr-check"></i>
          </button>
          <button class="ms-2" mat-icon-button color="warn" (click)="clearSearchedUser(true)">
            <i class="fi-rr-cross"></i>
          </button>
        </div>
      </div>
    </div>

  </form>


</mat-dialog-content>


<mat-dialog-actions align="end">
    <button  mat-stroked-button color="primary" mat-dialog-close>Cancelar</button>
    <button class="ms-3" *ngIf="getTypeModal == 'create' " mat-raised-button color="primary" [disabled]="groupForm.invalid || selected_users_value.length < 2" (click)="save()" >Crear grupo</button>
    <button class="ms-3" *ngIf="getTypeModal == 'edit' " mat-raised-button color="primary" [disabled]="groupForm.invalid || selected_users_value.length < 2 "  (click)="save()">Actualizar grupo</button>

</mat-dialog-actions>

</div>
