<div class="state-voz" *ngIf="videoLlamadaButton">
  <button mat-button (click)="viewPhone()" [ngClass]="videoState" class="phone-status">
    <mat-icon>videocam</mat-icon> 
  </button>
</div>

<div *ngIf="viewVideoState" class="phone">
  <mat-card>
    <mat-card-content class="col row">
      <div class="col" style="display: flex; justify-content: end;">
        <h3 style="font-size: 24px !important;"><b>Mi estado</b></h3>
        <button mat-icon-button  style="float:right;" (click)="viewPhone()" >
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
      </div>
      <div class="col" style="margin-top: 10px; padding-left: 30px;">
        <mat-slide-toggle (change)="onValChange($event)" [checked]="toggleCheck">Disponible</mat-slide-toggle> 
      </div>
      <div class="col" style="margin-top: 32px;">
        <button mat-fab color="accent" class="buttonGreen" [disabled]="VideoStartButton" (click)="startCalls()">
          <mat-icon>phone_callback</mat-icon>
        </button>
      </div>

    </mat-card-content>
  </mat-card>
</div>