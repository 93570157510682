<div fxLayout="column">
    <div fxFlex="100%" fxLayout="row">
      <mat-form-field appearance="fill" class="me-3">
        <mat-label>Fecha</mat-label>
        <mat-date-range-input [rangePicker]="rango" [formGroup]="filtroFecha">
            <input matStartDate placeholder="Fecha inicio" formControlName="fechaInicial">
            <input matEndDate placeholder="Fecha fin" formControlName="fechaFinal" (dateChange)="filtroFechas()">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rango">
            <mat-icon matDatepickerToggleIcon><i class="fi-rr-calendar"></i></mat-icon>
          </mat-datepicker-toggle>
        <mat-date-range-picker #rango></mat-date-range-picker>
    </mat-form-field>
        <mat-form-field appearance="fill" style="width: 300px;">
            <mat-label>Filtrar por auditoría</mat-label>
            <mat-select [(value)]="filtroGestion" (selectionChange)="filtro()">
              <mat-option>-------</mat-option>
              <mat-option value="1">Retroalimentada</mat-option>
              <mat-option value="0">Sin retroalimentación</mat-option>
          </mat-select>
        </mat-form-field>
    </div>
    <div class="mat-elevation-z0 mt-2" fxFlex="100%">
        <table  [dataSource]="dataSource" mat-table  matSort>
    
          <!-- Columna con botónes de acciones -->
          <ng-container matColumnDef="ac">
            <div>
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <i class="fi-rr-eye" (click)="detailRetroAlimentacion(element.id)" style="cursor: pointer;"></i>
              </td>
            </div>
          </ng-container>
    
          <!-- Columna ID -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{ element.id}} </td>
          </ng-container>

          <!-- Columna fecha auditoría -->
          <ng-container matColumnDef="campania">
            <th mat-header-cell *matHeaderCellDef> Campaña </th>
            <td mat-cell *matCellDef="let element"> {{ element.campaing.name ? element.campaing.name: 'Ninguna' }} </td>
          </ng-container>
        
          <!-- Columna fecha auditoría -->
          <ng-container matColumnDef="fecha_auditoria">
            <th mat-header-cell *matHeaderCellDef> Fecha de auditoría </th>
            <td mat-cell *matCellDef="let element"> {{ element.fecha | date:'dd/MM/yyyy' }} </td>
          </ng-container>
    
          <!-- Columna Campaña -->
          <ng-container matColumnDef="nombre_matriz">
            <th mat-header-cell *matHeaderCellDef> Nombre de la matriz </th>
            <td mat-cell *matCellDef="let element"> {{ element.nombre_matriz }} </td>
          </ng-container>
    
          <!-- Columna cedula auditado -->
          <ng-container matColumnDef="auditor">
            <th mat-header-cell *matHeaderCellDef> Auditado por </th>
            <td mat-cell *matCellDef="let element"> {{ element.auditor }} </td>
          </ng-container>
    
          <!-- Columna nombre auditado -->
          <ng-container matColumnDef="calificacion">
            <th mat-header-cell *matHeaderCellDef> Calificación </th>
            <td mat-cell *matCellDef="let element"> {{ element.calidicacion | number:'1.0-3'}} </td>
          </ng-container>
    
          <!-- Columna UCID -->
          <ng-container matColumnDef="gestion">
            <th mat-header-cell *matHeaderCellDef> Gestión </th>
            <td mat-cell *matCellDef="let element"> {{ element.gestion }} </td>
          </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No hay registros para mostrar</td>
          </tr>
        </table>
    
        
        <mat-paginator [length]="length"
        [pageSize]="pageSize"
        (page) ="pageEvent($event)"
        [pageSizeOptions] = "pageSizeOptions"
        >
      </mat-paginator>
    </div>
</div>