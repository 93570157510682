<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
</button>
<h3 >Proceso de Formación</h3>
<mat-divider></mat-divider>
<mat-dialog-content >
    <mat-tab-group mat-align-tabs="right">
        <mat-tab label="Comentarios">

            <div class="details">
                <div class="coment-btns">
                    <p><strong> Lista de comentarios</strong></p>
                    <button [disabled]="comentPermit" *ngIf="!createComment && (candidate_state != '36' && candidate_state != '3')" mat-button
                            (click)="createComment = !createComment">Crear nuevo comentario &nbsp;<span class="fi-rr-add"></span></button>
                </div>
                <form [formGroup]="formComment" *ngIf="createComment" class="comment-form">
                        <mat-form-field appearance="fill" fxFlex *ngIf="!states">
                            <mat-label>Tipo de Comentario</mat-label>
                            <mat-select formControlName="typeComment">
                                <mat-option *ngFor="let type of types_comments" [value]="type.name">
                                    {{type.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" fxFlex *ngIf="formComment.value.typeComment == 'Retiro'" >
                            <mat-label>Tipo de retiro</mat-label>
                            <mat-select formControlName="comment" >

                              <mat-optgroup *ngFor="let group of types_retreat" [label]="group.name">
                                <mat-option *ngFor="let type of group.types" [value]="type.viewValue">
                                  {{type.viewValue}}
                                </mat-option>
                              </mat-optgroup>
                              <!-- <mat-option >-- Otro --</mat-option> -->
                            </mat-select>
                          </mat-form-field>
                        <mat-form-field appearance="fill" fxFlex *ngIf="states" >
                            <mat-label>Estados del comentario</mat-label>
                            <mat-select formControlName="typeComment">
                                <mat-option *ngFor="let type of status_comments" [value]="type.name">
                                    {{type.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill" fxFlex >
                            <mat-label>Comentario</mat-label>
                            <textarea matInput rows="2" formControlName="comment"></textarea>
                        </mat-form-field>

                        <div class="comment-form_btns">
                          <button mat-stroked-button type="button" (click)="cancelForm('formComment','createComment')">Cancelar</button>
                          <button mat-flat-button color="primary" (click)="saveComment()">Guardar</button>
                        </div>

                </form>
                <mat-divider></mat-divider>
                <div class="comment-list">
                    <p *ngIf="comments.length == 0" >No hay comentarios creados relacionados a este candidato</p>
                    <mat-list>
                        <mat-list-item *ngFor="let row of comments">
                            <br>
                            <div matLine><b>{{row.typeComment}}</b> </div>

                            <p mat-line matSubheader>
                                <span >{{row.comment}}</span> <br>
                                <span><strong>Creado</strong> &nbsp;{{row.createdAt | date:'dd/MM/yyyy'}}</span>
                            </p>
                                <mat-divider></mat-divider>
                        </mat-list-item>
                    </mat-list>

                </div>
            </div>
        </mat-tab>
        <mat-tab label="Evaluaciones">
            <div class="details">
                <div class="coment-btns">
                    <p><strong> Lista de Evaluaciones</strong></p>
                    <button *ngIf="!createEvaluation"  [disabled]="comentPermit" mat-button
                         (click)="createEvaluation = !createEvaluation">Cargar Evaluación &nbsp;<span class="fi-rr-cloud-upload"></span></button>
                </div>

                <form [formGroup]="formEvaluation" *ngIf="createEvaluation" class="comment-form">

                    <mat-form-field appearance="fill" fxFlex>
                        <mat-label>Tipo de Evaluación</mat-label>
                        <input matInput formControlName="typeEvaluation">
                    </mat-form-field>

                    <mat-form-field appearance="fill" fxFlex>
                        <mat-label>Evaluación</mat-label>
                        <textarea matInput rows="5" formControlName="evaluation"></textarea>
                    </mat-form-field>
                    <mat-form-field  appearance="fill" fxFlex>
                        <mat-label>Prueba Realizada</mat-label>
                        <input matInput type="text" [(ngModel)]="attachmentsName" [ngModelOptions]="{standalone: true}" name="attachmentName" disabled="true">
                        <button mat-icon-button matSuffix (click)="fileInput.click()"  matTooltip="Cargar Prueba">
                          <i class="fi-rr-clip" ></i>
                        </button>

                        <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" id="file">

                    </mat-form-field>


                    <div class="comment-form_btns">
                        <button mat-stroked-button (click)="cancelForm('formEvaluation','createEvaluation')">Cancelar</button>
                        <button mat-flat-button color="primary" (click)="saveEvalution()" [disabled]="!formEvaluation.valid" >Guardar</button>

                    </div>

                </form>
                <mat-divider></mat-divider>
                <div class="comment-list">
                    <p *ngIf="evaluations.length == 0">No hay evaluaciones creadas relacionados a este candidato</p>
                    <mat-list>
                        <mat-list-item *ngFor="let row of evaluations"><br>

                                <div mat-line><b>{{row.typeEvaluation}}</b> </div>
                                <div mat-line matSubheader>{{row.evaluation}} <br>
                                    <span *ngIf="row?.attachment">{{row.attachmentName}}</span> <br>
                                     <span><strong>Creado</strong>
                                    {{row.createdAt | date:'dd/MM/yyyy'}}</span>
                                </div>
                                <div>
                                <!-- <button mat-button *ngIf="row?.attachment" (click)="downloadFile(row?.attachment)">
                                    {{row.attachmentName}} &nbsp; <span class="fi-rr-cloud-download"></span>
                                </button> -->
                            </div>
                            <mat-divider></mat-divider>
                        </mat-list-item>
                    </mat-list>

                </div>

            </div>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-stroked-button color="warn" (click)="openComments()" [disabled]="comentPermit"
    [disabled]="evaluations.length == 0 && comments.length == 0" *ngIf="(candidate_state != '36' && candidate_state != '3')">Reprobar </button>
    <button mat-flat-button color="primary" (click)="openComments()" [disabled]="comentPermit"
    [disabled]="evaluations.length == 0 && comments.length == 0" *ngIf="(candidate_state != '36' && candidate_state != '3')">Aprobar</button>
</mat-dialog-actions>
