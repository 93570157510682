<div class="admin-groups">
  <div class="header">
    <h1>Bienvenido</h1>
    <p>En esta sección podrás actualizar tu información personal y más</p>
    <!-- <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem blanditiis illum quos provident, nemo totam, cumque perferendis neque obcaecati voluptatum nulla maxime! Provident dolore quis nam. Dignissimos quisquam aut alias? </p> -->
  </div>
  <mat-card>
    <button mat-raised-button style="float: right;" (click)="changePassword()" color="primary">Cambiar
      Contraseña</button>
    <button mat-stroked-button style="float: right; margin-right: 10px;" (click)="ProfileUpdate()"
      color="primary">Editar información <i class="fi-rr-edit"></i></button>
    <h3 mat-card-title>Información Personal</h3>
    <mat-card-content>
      <br>
      <form [formGroup]="profileForm">
        <div fxLayout="row wrap" fxLayoutGap="32px">
          <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
            <mat-label>Tipo Documento</mat-label>
            <input matInput formControlName="id_type_id">
          </mat-form-field>
          <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
            <mat-label>Número de Identificación</mat-label>
            <input matInput formControlName="id_number">
          </mat-form-field>
          <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
            <mat-label>Primer Nombre</mat-label>
            <input matInput formControlName="first_name">
          </mat-form-field>
          <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
            <mat-label>Segundo Nombre</mat-label>
            <input matInput formControlName="middle_name">
          </mat-form-field>
          <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
            <mat-label>Primer Apellido</mat-label>
            <input matInput formControlName="last_name">
          </mat-form-field>
          <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
            <mat-label>Segundo Apellido</mat-label>
            <input matInput formControlName="second_last_name">
          </mat-form-field>
          <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email">
          </mat-form-field>
          <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
            <mat-label>Email Secundario</mat-label>
            <input matInput formControlName="email2">
          </mat-form-field>
          <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
            <mat-label>Teléfono</mat-label>
            <input matInput formControlName="phone">
          </mat-form-field>
          <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
            <mat-label>Teléfono Movil</mat-label>
            <input matInput formControlName="mobile_phone">
          </mat-form-field>
          <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" appearance="fill">
            <mat-label>Fecha de Nacimiento</mat-label>
            <input matInput formControlName="birth_date">
          </mat-form-field>

        </div>
      </form>
    </mat-card-content>
  </mat-card>
  <mat-card class="card-pqrs">
    <button color="accent" mat-stroked-button class="card-pqrs-buttons" (click)="redirectToFormPqrs()">Crear PQRS &nbsp;
      <span class="fi-rr-add"></span></button>
    <h3 mat-card-title>Mis PQRS</h3>
    <mat-card-content></mat-card-content>
  </mat-card>
  <mat-card class="card-pqrs">
    <button color="accent" mat-stroked-button class="card-pqrs-buttons" (click)="redirectToQuestionSanity()">Encuesta
      Sanitaria &nbsp; <span class="fi-rr-add"></span></button>
    <h3 mat-card-title>Encuesta Sanitaria</h3>
    <mat-card-content></mat-card-content>
  </mat-card>
  <!-- Preguntas de seguridad -->
  <mat-card class="card-pqrs">
    <button color="primary" mat-stroked-button class="card-pqrs-buttons" (click)="changeNetworkUserPasswordDialog()">
      Cambiar contraseña &nbsp; <span class="fi-rr-edit"></span></button>
    <h3 mat-card-title>Usuario de red</h3>
    <mat-card-content></mat-card-content>
  </mat-card>
  <mat-card class="card-pqrs">
    <button color="primary" mat-stroked-button class="card-pqrs-buttons" (click)="updateQuestionsDialog()">Actualizar
      preguntas &nbsp; <span class="fi-rr-edit"></span></button>
    <h3 mat-card-title>Preguntas de seguridad</h3>
    <mat-card-content></mat-card-content>
  </mat-card>
  <mat-card class="card-pqrs">
    <button color="primary" mat-stroked-button class="card-pqrs-buttons" (click)="onClickGeneratePwaAccessCode()">Acceso PWA &nbsp;<span class="fi-rr-edit"></span></button>
    <h3 mat-card-title>Generar código</h3>
    <mat-card-content></mat-card-content>
  </mat-card>
</div>