import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    let str =value;
    str = str.replace(':', '');
    str = str.replace(':', '');
    str = str.replace(':', '');
 

    let arr = str.split('');
    let toal_r = arr.length;
    if (toal_r>6){ toal_r=6; } // Si supera los 6 caracteres , limitamos
    //Llenamos los numeros de la derecha
    for (let i = 1; i <=(6-toal_r); i++) {
      str = str+'0';
    }    
    arr = str.split('');
    let text = "";
    //formateamos el texto, lo maximo son 6 caracteres HH:MM:SS
    for (let i = 1; i <= 6; i++) {
         text = text+''+arr[i-1];
         if ( (i%2)==0 ) {
           if (i<6) { text = text+':'; }              
         }    
    } 

   return text;  
}

/*




    /*
    let newValue:string = '';
    if (value.length >= 4) {
      if (value.length == 5) {
        Array.from(value).forEach((numero, index) => {
          newValue = newValue + numero
          if (index == 2) {
            newValue = newValue + ':'
          }
        });
        return newValue;
      }else if (value.length == 4) {
        Array.from(value).forEach((numero, index) => {
          newValue = newValue + numero
          if (index == 1) {
            newValue = newValue + ':'
          }
        });
        return newValue;
    }
    }else{
      return value;
    }
  }*/

}
