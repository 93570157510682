import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { HistorialModel } from 'src/app/modules/coaching/core/interfaces/historial.interface';
import { selectHistorial } from 'src/app/modules/coaching/state/selectors/historial.selector';
import { listCampañas, listCausas, listCompromisos, listCuartiles, listEstados, listEvoluciones, listIndicadores, listProcesos } from 'src/app/modules/coaching/state/selectors/selects.selector';

@Component({
  selector: 'app-dialog-historico',
  templateUrl: './dialog-historico.component.html',
  styleUrls: ['./dialog-historico.component.sass']
})
export class DialogHistoricoComponent implements OnInit {

  historicoForm:FormGroup;
  historial$:Observable<any> = new Observable();
  causas$:Observable<any> = new Observable();
  compromisos$:Observable<any> = new Observable();
  evoluciones$:Observable<any> = new Observable();
  cuartiles$:Observable<any> = new Observable();
  indicadores$:Observable<any> = new Observable();
  procesos$:Observable<any> = new Observable();
  campaigns$:Observable<any> = new Observable();
  estados$:Observable<any> = new Observable();
  acompanamientos:boolean;
  campaignJefe:boolean = false;
  constructor(
    private fb:FormBuilder,
    @Inject(MAT_DIALOG_DATA) public datos:any,
    private store:Store,
    private authService:AuthService
  ) { }

  ngOnInit(): void {
    this.getSelects();
    this.createForm();
    this.loadData();
    this.getFilterParam();
  }

  getSelects(){
    this.causas$ = this.store.select(listCausas);
    this.compromisos$ = this.store.select(listCompromisos);
    this.evoluciones$ = this.store.select(listEvoluciones);
    this.cuartiles$ = this.store.select(listCuartiles);
    this.indicadores$ = this.store.select(listIndicadores);
    this.procesos$ = this.store.select(listProcesos);
    this.campaigns$ = this.store.select(listCampañas);
    this.estados$ = this.store.select(listEstados);
  }
  
    /**
     * @author Cristian Gallo
     * @createdate 2022-01-26
     * Se encargar de llamar y llenar los datos del formulario.
    */

  loadData(){
    this.historial$ = this.store.select(selectHistorial);
    this.historial$.forEach((historial) => {
      if (historial) {
        this.acompanamientos = historial[0].masAcompanamientos;
        this.historicoForm.setValue({
          nombre_coachee:historial[0].acompanamiento.nombreCoaching,
          cedula_coachee:historial[0].acompanamiento.cedulaCoaching,
          nombre_coach:historial[0].supervisor.rrhh.first_name + ' ' + historial[0].supervisor.rrhh.last_name,
          cedula_coach:historial[0].supervisor.rrhh.id_number,
          fecha_creacion:moment(historial[0].acompanamiento.fechaCreacion).format(),
          proximo_seguimiento:moment(historial[0].acompanamiento.proximoSeguimiento).format(),
          fecha_ejecucion:historial[0].acompanamiento.created_at,
          dias:historial[0].acompanamiento.dias_restantes,
          estado:historial[0].acompanamiento.estado,
          indicador:historial[0].acompanamiento.indicador,
          tipo_proceso:historial[0].acompanamiento.tipoProceso,
          campaing:historial[0].campaign.id,
          indicador_inicial:historial[0].acompanamiento.indicadorInicial,
          cuartil_inicial:historial[0].acompanamiento.cuartilInicial,
          objetivo_kpi:historial[0].acompanamiento.objetivoPlanteado == null ? historial[0].acompanamiento.objetivoKPI:historial[0].acompanamiento.objetivoPlanteado,
          causa_raiz:historial[0].acompanamiento.causaRaiz,
          conclusion_sesion:historial[0].acompanamiento.conclusionSesion,
          cumpleCompromiso:historial[0].acompanamiento.cumpleCompromiso === null ? 'na':historial[0].acompanamiento.cumpleCompromiso,
          presentaEvolucion:historial[0].acompanamiento.presentaEvolucion === null ? 'na':historial[0].acompanamiento.presentaEvolucion,
          seguimiento:historial[0].acompanamiento.objetivoPlanteado == null ? 'No aplica':historial[0].acompanamiento.objetivoKPI,
          estado_asesor:historial[0].acompanamiento.estado_asesor === null ? 'na':historial[0].acompanamiento.estado_asesor.id,
          comentario_asesor:historial[0].acompanamiento.comentario_asesor,
        });
      }
    });
  }

  createForm(){
    this.historicoForm = this.fb.group({
      nombre_coachee:[{value:'',disabled:true}],
      cedula_coachee:[{value:'',disabled:true}],
      nombre_coach:[{value:'',disabled:true}],
      cedula_coach:[{value:'',disabled:true}],
      estado:[{value:'',disabled:true}],
      fecha_creacion:[{value:'',disabled:true}],
      proximo_seguimiento:[{value:'',disabled:true}],
      fecha_ejecucion:[{value:'',disabled:true}],
      dias:[{value:'',disabled:true}],
      indicador:[{value:'',disabled:true}],
      tipo_proceso:[{value:'',disabled:true}],
      campaing:[{value:'',disabled:true}],
      indicador_inicial:[{value:'',disabled:true}],
      cuartil_inicial:[{value:'',disabled:true}],
      objetivo_kpi:[{value:'',disabled:true}],
      causa_raiz:[{value:'',disabled:true}],
      conclusion_sesion:[{value:'',disabled:true}],
      cumpleCompromiso:[{value:'',disabled:true}],
      presentaEvolucion:[{value:'',disabled:true}],
      seguimiento:[{value:'',disabled:true}],
      estado_asesor:[{value:'',disabled:true}],
      comentario_asesor:[{value:'',disabled:true}],
    });
  }

  getFilterParam(){
    this.authService.getUser().roles.forEach(rol => {
      if ((rol == 'coaching::jefe' || rol == 'coaching::gerente') || rol == 'coaching::asesor') {
        this.campaignJefe = true;
      }
    });
   }

}
