import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { ChatService } from '../../../services/chat.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-upload-chats-modal',
  templateUrl: './upload-chats-modal.component.html',
  styleUrls: ['./upload-chats-modal.component.sass']
})
export class UploadChatsModalComponent implements OnInit {

  filteredOptionsCampaign: Observable<any[]>;
  campaigns: any;
  nameUser: any;
  selectedFile: File;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UploadChatsModalComponent>,
    private chatService: ChatService,
    private authService: AuthService  ) { }


  ngOnInit(): void {
    const user = this.authService.getUser();
    this.nameUser = user.rrhh.name;
  }

  /**
   * Metodo envia docuemnto y consume endpoint. 
   * @author Yeison Sepulveda
   * @createdate 2024-07-05
  */
  sendDataUpload(): void {
    Swal.fire({
      title: '¿Está seguro?',
      text: '¿De continuar con la gestión?',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.chatService.uploadData(this.nameUser, this.selectedFile).subscribe(
          res => {
            Swal.fire({
              title: '¡Excelente!',
              text: 'Se ha subido el archivo',
              icon: 'success',
              confirmButtonText: 'Continuar',
              confirmButtonColor: '#2CABBC',
            }).then((result) => {
              if (result.isConfirmed) {
                this.dialogRef.close();
              }
            });
          },
          error => {
            console.error('Ha ocurrido un error al cargar el documento.', error);
          }
        );
      }
    });
  }
    
  /**
   * Metodo cancela proceso de carga
   * @author Yeison Sepulveda
   * @createdate 2024-07-05
  */
  cancelSave(): void {
  Swal.fire({
    title: '¿Estás seguro?',
    text: '¿En verdad deseas cancelar la gestión?',
    icon: 'warning',
    showCancelButton: true,
    showConfirmButton: true,
    confirmButtonColor: '#2CABBC',
    cancelButtonColor: '#FFFFFF',
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Aceptar',
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed) {
      this.dialogRef.close();
    }
  });
  }

  /**
   * Método que descarga una plantilla Excel con encabezados predeterminados.
   * @author Yeison Sepulveda
   * @createdate 2024-07-05
 */

  downloadTemplate(): void {
    //fecha y hora actual formateada
    const currentDate = new Date().toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '');
  
    const currentTime = new Date().toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).replace(/:/g, '');
  
    const dateTimeString = `${currentDate}${currentTime}`;
  
    this.chatService.downloadTemplate().subscribe(
      (response) => {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
  
        //nombre del archivo
        a.href = url;
        a.download = `plantilla_carga_${dateTimeString}.xlsx`;
        a.click();
  
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.error('Error al descargar la plantilla', error);
        Swal.fire({
          icon: 'error',
          title: '¡Ups!',
          text: 'Error al descargar la plantilla',
          showConfirmButton: true,
          confirmButtonColor: '#2CABBC',
          confirmButtonText: '¡Entendido!',
        });
      }
    );
  }

  /**
   * Método que se activa cuando se selecciona un archivo y valida el tipo de documento.
   * @param event Objeto del evento de selección de archivo
   * @author Yeison Sepulveda
   * @createdate 2024-07-05
  */

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    const maxSizeInBytes = 20 * 1024 * 1024; // 20 MB
  
    if (file) {
      if (file.size > maxSizeInBytes) {
        Swal.fire({
          icon: 'warning',
          title: 'Tamaño excedido',
          text: 'El archivo no debe superar los 20 MB.',
          showConfirmButton: true,
          confirmButtonColor: '#2CABBC',
          confirmButtonText: 'Aceptar',
        });
        this.selectedFile = null;
        return;
      }
  
      if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel') {
        this.validateHeaders(file).then(isValid => {
          if (isValid) {
            this.selectedFile = file;
          } else {
            Swal.fire({
              icon: 'warning',
              title: '¡Cabeceras incorrectas!',
              text: 'El documento no cumple con las cabeceras establecidas y requeridas.',
              showConfirmButton: true,
              confirmButtonColor: '#2CABBC',
              confirmButtonText: '¡Entendido!',
            });
            this.selectedFile = null;
          }
        });
      } else {
        this.selectedFile = null;
        Swal.fire({
          icon: 'warning',
          title: 'Formato incorrecto',
          text: 'Por favor seleccione un archivo con formato válido.',
          showConfirmButton: true,
          confirmButtonColor: '#2CABBC',
          confirmButtonText: '¡Entendido!',
        });
      }
    }
  }

/**
 * Método para validar las cabeceras del archivo Excel seleccionado.
 * @author Yeison Sepulveda
 * @createdate 2024-07-05
 * @param file Archivo seleccionado de tipo File
 * @returns Promesa que resuelve en true si las cabeceras son válidas, de lo contrario false.
 */
  
  async validateHeaders(file: File): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const arrayBuffer: ArrayBuffer = e.target?.result as ArrayBuffer;
        if (!arrayBuffer) {
          reject(false);
          return;
        }
  
        const data = new Uint8Array(arrayBuffer);
        const arr = Array.from(data).map((byte) => String.fromCharCode(byte)).join('');
        const workbook = XLSX.read(arr, { type: 'binary' });
  
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
  
        const headers = XLSX.utils.sheet_to_json<string[]>(worksheet, { header: 1 })[0];
  
        if (!Array.isArray(headers)) {
          reject(false);
          return;
        }
  
        const requiredHeaders = [
          'FECHA_HORA_INGRESO', 'CODIGO', 'CASO', 'RESPONDIDO_POR', 'ESTADO',
          'CATEGORIA_MENSAJE', 'CONTENIDO', 'TELEFONO_WA', 'NOMBRE_AGENTE', 'PROCESO'
        ];
  
        const isValid = headers.length === requiredHeaders.length && headers.every((header, index) => header === requiredHeaders[index]);
        resolve(isValid);
      };
  
      fileReader.onerror = (error) => {
        console.error('Error leyendo el archivo:', error);
        reject(false);
      };
  
      fileReader.readAsArrayBuffer(file);
    });
  }

}
