<div class="row novelty-row-padding">

  <h2 mat-dialog-title>
    <span class="gray-color" style="margin-left: -9px;"></span>
    <button style="float: right;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
    <div class="title content" style="width: 100%; margin-left: 55px;">
      <span>
        <h1 class="gray-color">{{dataInfo?.worker}}</h1>
        <h5 style="font-size: 15px; font-family: 'Poppins', sans-serif!important;">{{ dataInfo?.document}}</h5>
      </span>
    </div>
  </h2>

  <mat-dialog-content style="padding: 3%; overflow-x: hidden !important;" class="mat-typography">
    <div class="row" style="margin-left: 50px;">

      <div class="col-6">
        <div class="row">
          <div class="col-12">

            <div class="col-12">

              <div class="col-12">
                <h3 class="gray-color">Suspensión</h3>

                <h4 class="gray-color" style="margin-top: 32px;"><b>Acta de descargo</b></h4>
                <p class="p-color">{{ dataInfo?.certificateName }}</p>

              </div>
              <br>
            </div>

            <div class="col-12">
              <br>
              <h4 class="gray-color"><b>Observaciones del área jurídica</b></h4>
              <p
                class="p-color">{{ dataInfo?.observations[0]?.observation }}</p>
            </div>

            <div class="col-12">
              <br>
              <h4 class="gray-color"><b>Fecha de suspensión</b></h4>
              <p class="p-color">{{ dataInfo?.extra.initial_date_suspension | date:'dd/MM/yyyy'}} al {{ dataInfo?.extra.end_date_suspension | date:'dd/MM/yyyy'}}</p>
            </div>

            <div class="col-12">
              <br>
              <h4 class="gray-color"><b>Observaciones del jefe inmediato</b></h4>
              <p
                class="p-color">{{ dataInfo?.observations[1]?.observation }}</p>
            </div>


          </div>
        </div>

      </div>

      <div class="col-6 text-center">
        <img src="./assets/images/suspension.png" alt="noveltyIcon">
      </div>
    </div>
  </mat-dialog-content>

</div>
