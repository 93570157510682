import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SearchChatComponent } from '../search-chat/search-chat.component';
import { SpeechUploadchatComponent } from '../speech-uploadchat/speech-uploadchat.component';

@Component({
  selector: 'app-speech-chat-main',
  templateUrl: './speech-chat-main.component.html',
  styleUrls: ['./speech-chat-main.component.sass']
})
export class SpeechChatMainComponent implements OnInit {

  @ViewChild(SpeechUploadchatComponent) speechUploadChatComponent: SpeechUploadchatComponent;
  @ViewChild(SearchChatComponent) searchChatComponent: SearchChatComponent;

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Método que se llama cuando cambia la pestaña en el componente de pestañas.
   * @author Yeison Sepulveda
   * @createdate 2024-07-16
   * @param {MatTabChangeEvent} event - El evento de cambio de pestaña de Angular Material.
 */

  onTabChange(event: MatTabChangeEvent) {
    if (event.index === 0) {
      this.speechUploadChatComponent.uploadData();
    } else if (event.index === 1) {
      this.searchChatComponent.filterUploads();
    }
  }
}