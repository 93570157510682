import { ProcesarSolicitudComponent } from './../procesar-solicitud/procesar-solicitud.component';
import { Component, OnInit, Inject } from '@angular/core';
import { SolicitudesService } from '../../../../services/rest/solicitudes.service';
import { DestroyComponentService } from '../../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../../../shared/alerts/alerts.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../../../../core/services/rest/auth.service';
import { ArchivosService } from '../../../../services/rest/archivos.service';

import Swal from 'sweetalert2';
import { formatNumber, JsonPipe } from '@angular/common';
import { ViewObservationItemComponent } from '../view-observation-item/view-observation-item.component';

@Component({
  selector: 'app-aprobar-solcitud',
  templateUrl: './aprobar-solcitud.component.html',
  styleUrls: ['./aprobar-solcitud.component.sass']
})
export class AprobarSolcitudComponent implements OnInit {

  solicitudesForm         : FormGroup;
  itemsSolciitud          : [] = [];
  itemsAsociada         : [] = [];
  archivosPorSolicitud    : any;
  archivo                 : any;
  view                    = false;
  coordinador:boolean     = false;
  applicant      :boolean = false;
  viewInfo       :boolean;
  rol = null;
  ordenCompra             :any;
  viewFile                :boolean;

  constructor(
    private solicitudService                : SolicitudesService,
    private archivoService                  : ArchivosService,
    private destroyService                  : DestroyComponentService,
    private form                            : FormBuilder,
    private alertsService                   : AlertsService,
    private authService                     : AuthService,
    public dialog                           : MatDialog,
    private dialogRef                       : MatDialogRef< AprobarSolcitudComponent >,
    @Inject(MAT_DIALOG_DATA) public data    : any) { }

    ngOnInit(): void {
      this.data.solicitud.request_items =  this.data.solicitud.request_items.map(item => {
        if(item.observations && typeof item.observations == 'string') {
          const itemParse = JSON.parse(item.observations)
          item.observations = itemParse
        }
        return item
      });

      if(this.data.solicitud.is_associated == "1"){
        this.solicitudService.itemsMother(this.data.solicitud.request_id).subscribe(resp => {
          this.itemsSolciitud = resp.data;
          this.itemsAsociada = this.data.solicitud.request_items;
        })
      }else{
        this.itemsSolciitud = this.data.solicitud.request_items;
      }
      this.viewFile = false;
      this.applicant = this.authService.getUser().roles.includes("compras::solicitante")
      this.coordinador = this.data.coordinador;
      this.rol = this.data.rol;
      if((this.data.solicitud.request_state.id == 11 || this.data.solicitud.request_state.id == 6) && this.data.solicitud.purchase_order && this.data.solicitud.purchase_order[0].path && this.rol == 'solicitante'){
        this.viewFile = true;
        this.ordenCompra = this.data.solicitud.purchase_order[0];
      }
      this.formControl();
      if (this.data.type === 'view') {
        this.view = true;
        this.viewInfo = this.data.viewInfo;
        // this.formControlView();
      }


      this.archivoService.getArchivosSolicitud(this.data.solicitud.id)
      .subscribe(resp => {
        this.archivosPorSolicitud = resp.data;
        this.archivosPorSolicitud.forEach((element, index) => {
          if (element.state == 1) {
            this.solicitudesForm.controls.cotizacion.setValue(element.id);
          }
        });
      });

    }

    private save(solicitudesForm){
      let estado = {
        state: solicitudesForm.value.estado
      };
      let archivoSeleccionado = {
        state: 1,
        idSolicitud: this.data.solicitud.id
      };
      const observacion = new FormData();
      observacion.append('observacion', solicitudesForm.value.observacion);
      observacion.append('idSolicitud', this.data.solicitud.id);
      observacion.append('type', "coordinador");

      this.solicitudService.updateSolicitudEstado(estado, this.data.solicitud.id).subscribe(resp => {
        // observacion
        this.solicitudService.saveObservacion(observacion).subscribe();
        if (solicitudesForm.value.estado == 4) {
          if (solicitudesForm.value.cotizacion != null) {
            this.archivoService.updateArchivosSolicitud(archivoSeleccionado, solicitudesForm.value.cotizacion).subscribe();
          } else {
            this.alertsService.alertError('Error', 'Seleccione una cotizcion');
            return;
          }
          this.alertsService.alertSuccess('¡Excelente!', ' Se aprobó una cotización de la solicitud, será enviada al analista para generar la orden de compra');
        } else {
          this.alertsService.alertSuccess('¡Excelente!', resp.data);
        }
      });

    }

    saveSolicitud(solicitudesForm): void {
      let confirmationText = null;


      if( this.data.solicitud.request_state_id == 4 &&
          solicitudesForm.value.estado         == 5 ){
          confirmationText = "¿Deseas cambiar el estado de la solicitud de aprobada a rechazada?,  recuerda que podrás volver a editar la solicitud.";
      }
      else if (
        this.data.solicitud.request_state_id == 5 &&
          solicitudesForm.value.estado       == 4
      )
      {
        confirmationText = "¿Deseas cambiar el estado de la solicitud de rechazada a aprobada?, recuerda que podrás volver a editar antes de que se genere la orden de compra.";
      }

      if( confirmationText != null ){
        //Confirma  cambio de estado de aproba a rechazada
        Swal.fire({
          icon                : 'warning',
          title               : '¿Estás seguro?',
          text                : confirmationText,
          showCancelButton    : true,
          focusConfirm        : false,
          confirmButtonText   : 'Aceptar',
          cancelButtonText    : "Cancelar"
        }).then( (result) => {
          if(result.isConfirmed){
            this.save( solicitudesForm );
          }
        } )
      }else{
        this.save( solicitudesForm );
      }




    }

    get error(): any { return this.solicitudesForm.controls; }


    /**
    * @author Jose Vicente Silva
    * @createdate 2021-01-27
    * Metodo donde se establecen las validaciones del formulario
    */
    formControl(): void {

      const stateId = parseInt(this.data.solicitud.request_state_id);
      this.solicitudesForm = new FormGroup({
        cotizacion: new FormControl('', stateId == 4 ? [  Validators.required  ] : []),
        estado: new FormControl( stateId  , [ Validators.required ]),
        observacion: new FormControl( this.getObservacion( this.coordinador ? "coordinador" : "analista" ) , [Validators.required, Validators.maxLength(255), Validators.minLength(3)]),
      });

    }

    validate_latesObservation(data){
      return data == undefined ? 'No hay informacion del usuario' : data;
    }


    downloadFile(e: any) {

      const archivo = {
        path: e.path
      };
      this.archivoService.downloadOrder(archivo).subscribe( (data) => {
        const a = document.createElement('a');
        const file = new Blob([data], {type: e.fileContentType});
        a.href = URL.createObjectURL(file);
        a.download = e.name;
        a.click();
      });
    }

    downloadOrder (order) {
      const archivo ={
        path:order.path
      };
      this.archivoService.downloadOrder(archivo).subscribe( resp=>{
        const a = document.createElement('a');
        const file = new Blob([resp], { type: 'application/pdf' });
        a.href = window.URL.createObjectURL(file);
        a.download = 'Orden_Compra.pdf';
        a.click();

      }, error =>{

      });
    }

    ngOnDestroy(): void {
      this.destroyService.destroyComponent();
    }

    formatNumber(number){
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        minimumFractionDigits: 2,
        currency: 'USD'
      })
      return formatter.format(number)
    }

    editSolicitud(  ){
      let component;
      if( !this.coordinador ){
        component = ProcesarSolicitudComponent;
        this.dialog.open(component , {
          width: '80%',
          disableClose: true,
          data: {
            type: 'editar',
            title: 'Detalles solicitud',
            coordinador : this.coordinador,
            solicitud:this.data.solicitud
          },
          panelClass: 'custom-dialog-container',
        });
        this.dialogRef.close();
      }
      else{
        this.view = false;
      }


    }

    getNombreObservacion(tipo){
      if( this.data.solicitud.request_observation == undefined ){
        return "";
      }
      const obs = this.data.solicitud.request_observation.filter( (v,i) => v.type == tipo );
      const len = obs.length;
      if( len > 0  ){
        return obs[len -1 ].name_created;
      }
      return "";
    }

    getfechaCreated(tipo){
      if( this.data.solicitud.request_observation == undefined ){
        return "";
      }
      const obs = this.data.solicitud.request_observation.filter( (v,i) => v.type == tipo );
      const len = obs.length;
      if( len > 0  ){
        return obs[len -1 ].created_at;
      }
      return "";
    }


    getObservacion( tipo ){
      if( this.data.solicitud.request_observation == undefined ){
        return "";
      }
      const obs = this.data.solicitud.request_observation.filter( (v,i) => v.type == tipo );
      const len = obs.length;
      if( len > 0  ){
        return obs[len -1 ].observation;
      }
      return "";

    }

    validateCotizatioControlRequired(state){
      if(state == 5)
        this.solicitudesForm.get('cotizacion').clearValidators();
      else if(state == 4)
        this.solicitudesForm.get('cotizacion').setValidators([Validators.required]);
        this.solicitudesForm.get('cotizacion').updateValueAndValidity();
    }

    /**
   * @author Fabian Duran
   * @createdate 2023-04-17
   * Abre la modal de la vista de observaciones de los items.
  */
  viewObservation(observation, indexItem): void {
    const modalViewObservation = this.dialog.open(ViewObservationItemComponent, {
      data: {
        observation: observation,
        indexItem: indexItem
      },
      width: '50%'
    });
    modalViewObservation.afterClosed().subscribe(res => {
      if (typeof res !== 'string' && res !== undefined) {
        const itemsRequest = this.data.solicitud.request_items;
        const setItemsRequest = itemsRequest.map(item => {
          if (item.id === res.resUpdate.indexItem) item.observations = res.resUpdate.observation;
          return item;
        });
        this.data.solicitud.request_items = setItemsRequest;
      }
    });
  }
}
