<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>
  <h3 mat-dialog-title>Firma</h3>
  <hr>
<mat-dialog-content>
    <p matCardTitle>Trace su firma dentro del siguiente recuadro.</p>
    <div  class="sign">
        <signature-pad  [options]="signaturePadOptions"></signature-pad>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button  style="margin: 10px;" matSuffix mat-stroked-button mat-dialog-close>Cancelar</button>
    <button  style="margin: 10px;" matSuffix mat-stroked-button (click)="clear()">Borrar</button>
    <button style="margin: 10px;" matSuffix mat-flat-button color="primary" (click)="savePad()">Firmar</button>
</mat-dialog-actions>