<h4 class="title-info"><i class="fi-rr-info"></i> Información solicitud</h4>
<mat-list>
    <div fxLayout="row">
        <mat-list-item fxFlex.gt-lg="33%" fxFlex.gt-md="33%">
            <div mat-line class="encabezado">Cédula</div>
            <div mat-line>{{ solicitud ? solicitud.acompanamiento.cedulaCoaching:'' }}</div>
        </mat-list-item>
        <mat-list-item fxFlex.gt-lg="33%" fxFlex.gt-md="33%">
            <div mat-line class="encabezado">Nombre del Coachee</div>
            <div mat-line>{{ solicitud ? solicitud.acompanamiento.nombreCoaching :'' }}</div>
        </mat-list-item>
        <mat-list-item fxFlex.gt-lg="33%" fxFlex.gt-md="33%">
            <div mat-line class="encabezado">Campaña</div>
            <div mat-line *ngIf="solicitud" >{{  solicitud.campaign.name | uppercase }}</div>
            <div mat-line *ngIf="!solicitud" ></div>

        </mat-list-item>
    </div>
    <div fxLayout="row">
        <mat-list-item fxFlex.gt-lg="33%" fxFlex.gt-md="33%">
            <div mat-line class="encabezado">Fecha coaching</div>
            <div mat-line>{{ solicitud ? (solicitud.acompanamiento.created_at | date:'dd-MM-yyyy'):''}}</div>
        </mat-list-item>
        <mat-list-item fxFlex.gt-lg="33%" fxFlex.gt-md="33%">
            <div mat-line class="encabezado">Indicador a trabajar</div>
            <div mat-line>{{ solicitud ? solicitud.acompanamiento.indicadores.nombre:'' }}</div>
        </mat-list-item>
        <mat-list-item fxFlex.gt-lg="33%" fxFlex.gt-md="33%">
            <div mat-line class="encabezado">Indicador inicial</div>
            <div mat-line>{{ solicitud ? (solicitud.acompanamiento.indicadorInicial | number:'1.0-3'):'' }}</div>
        </mat-list-item>
    </div>
    <div fxLayout="row">
        <mat-list-item fxFlex.gt-lg="33%" fxFlex.gt-md="33%">
            <div mat-line class="encabezado">Cuartil inicial</div>
            <div mat-line>{{ solicitud ? solicitud.acompanamiento.cuartil_inicial.nombre:'' }}</div>
        </mat-list-item>
        <mat-list-item fxFlex.gt-lg="33%" fxFlex.gt-md="33%" *ngIf="hiddenItems">
            <div mat-line class="encabezado">Próximo seguimiento</div>
            <div mat-line>{{ solicitud ? solicitud.acompanamiento.proximoSeguimiento:'' }}</div>
        </mat-list-item>
        <mat-list-item fxFlex.gt-lg="33%" fxFlex.gt-md="33%" *ngIf="hiddenItems" >
            <div mat-line class="encabezado">Objetivo planteado</div>
            <div mat-line>{{ solicitud && solicitud.acompanamiento.objetivoPlanteado !=null ? (solicitud.acompanamiento.objetivoPlanteado|number:'1.0-3'):solicitud ?(solicitud.acompanamiento.objetivoKPI |number:'1.0-3'):'' }}</div>
        </mat-list-item>
    </div>
    <div fxLayout="row">
        <mat-list-item fxFlex.gt-lg="33%" fxFlex.gt-md="33%" *ngIf="hiddenItems">
            <div mat-line class="encabezado">Causa raíz</div>
            <div mat-line>{{ solicitud && solicitud.acompanamiento.causas_raiz ? solicitud.acompanamiento.causas_raiz.nombre:'' }}</div>
        </mat-list-item>
        <mat-list-item fxFlex.gt-lg="33%" fxFlex.gt-md="33%" *ngIf="solicitud && solicitud.acompanamiento.objetivoPlanteado != null">
            <div mat-line class="encabezado">Seguimiento</div>
            <div mat-line>{{ solicitud ? (solicitud.acompanamiento.objetivoKPI | number:'1.0-3'):'' }}</div>
        </mat-list-item>
        <mat-list-item fxFlex.gt-lg="33%" fxFlex.gt-md="33%" *ngIf="solicitud && solicitud.acompanamiento.cumpleCompromiso != null">
            <div mat-line class="encabezado">Cumple compromiso?</div>
            <div mat-line>{{ solicitud ? solicitud.acompanamiento.cumple_compromisos.nombre:'' }}</div>
        </mat-list-item>
        <mat-list-item fxFlex.gt-lg="33%" fxFlex.gt-md="33%" *ngIf="solicitud && solicitud.acompanamiento.cumpleCompromiso === null">
            <div mat-line class="encabezado">Tipo de proceso</div>
            <div mat-line>{{ solicitud ? solicitud.acompanamiento.tipo_proceso.nombre:'' }}</div>
        </mat-list-item>
    </div>
    <div fxLayout="row" *ngIf="solicitud && solicitud.acompanamiento.presentaEvolucion !=null">
        <mat-list-item fxFlex.gt-lg="33%" fxFlex.gt-md="33%">
            <div mat-line class="encabezado">Presenta evolución</div>
            <div mat-line>{{ solicitud ? solicitud.acompanamiento.presenta_evolucions.nombre:'' }}</div>
        </mat-list-item>
        <mat-list-item fxFlex.gt-lg="33%" fxFlex.gt-md="33%">
            <div mat-line class="encabezado">Tipo de proceso</div>
            <div mat-line>{{ solicitud ? solicitud.acompanamiento.tipo_proceso.nombre:'' }}</div>
        </mat-list-item>
    </div>
</mat-list>
   