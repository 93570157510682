import { UsersReportsComponent } from './modules/users/users-reports/users-reports.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CiuComponent } from './ciu.component';
import { AdminUsersComponent } from './modules/users/admin-users/admin-users.component';
import { AdminRolesComponent } from './modules/roles/admin-roles/admin-roles.component';
import { AdminGroupsComponent } from './modules/groups/admin-groups/admin-groups.component';
import { AdminSchedulesComponent } from './modules/schedules/admin-schedules/admin-schedules.component';
import { AdminApplicationsComponent } from './modules/aplications/admin-applications/admin-applications.component';
import { AdminMenusComponent } from './modules/menus/admin-menus/admin-menus.component';
import { ProfileComponent } from './modules/profile/profile.component';
import { ReportAdhesionComponent } from './modules/reports/report-adhesion/report-adhesion.component';
import { ReportUsersAppComponent } from './modules/reports/report-users-app/report-users-app.component';
import { ManageNetworkUsersComponent } from './modules/manage-network-users/manage-network-users.component';


const routes: Routes = [

  { path: '', component: CiuComponent},
  { path: 'usuarios', component: AdminUsersComponent},
  { path: 'roles', component: AdminRolesComponent},
  { path: 'grupos', component: AdminGroupsComponent},
  { path: 'horarios', component: AdminSchedulesComponent},
  { path: 'aplicaciones', component: AdminApplicationsComponent},
  { path: 'menus', component: AdminMenusComponent},
  { path: 'perfil', component: ProfileComponent },
  { path: 'adherencia', component: ReportAdhesionComponent},
  { path: 'usuarios-de-red', component: ManageNetworkUsersComponent}
  //{ path: 'reportes/:id', component: ReportUsersAppComponent}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CiuRoutingModule { }
