import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { PusherService } from 'src/app/modules/crm/services/pusher.service';
import { AuditoriaService } from '../../services/auditoria.service';

@Component({
  selector: 'app-mis-auditorias',
  templateUrl: './mis-auditorias.component.html',
  styleUrls: ['./mis-auditorias.component.sass']
})
export class MisAuditoriasComponent implements OnInit {
  auditar:boolean = false;
  auditado:boolean = false;
  roles;
  trimestre_puntaje = 0;
  mes_puntaje = 0;
  hoy_puntaje = 0;
  subs
  constructor(
    private authService:AuthService,
    private auditoriaService:AuditoriaService,
    private pusher:PusherService,
  ) { }
  ngOnInit(): void {
    this.getPuntajes();
    this.pusher.getNotificationsGlobal().bind('notification',()=>{
      this.getPuntajes();
    });
  }

  getPuntajes(){
    this.auditoriaService.getPuntajes().subscribe((resp:any)=>{
      this.trimestre_puntaje = resp[0].auditoriasTrimestre;
      this.mes_puntaje = resp[0].auditoriasMes;
      this.hoy_puntaje = resp[0].auditoriasHoy;
    });
  }

  // verRoles(){
  //   this.roles = this.authService.getUser().roles;
  //   this.roles.forEach(rol => {
  //     if (rol == "calidad::analista") {
  //       this.auditar = true;
  //     }else if (rol == "calidad::supervisor") {
  //       this.auditar = true;
        
  //     }else if (rol == "calidad::jefe") {
  //       this.auditar = true;
  //     }
  //     else{
  //       this.auditar = false;
  //     }
  //     if (rol == "calidad::asesor") {
  //       this.auditado = true;
  //       this.getPuntajes();
  //     }else{
  //       this.auditado = false;
  //     }
  //   });
  // }
}
