import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SolicitudesService } from '../../../services/rest/solicitudes.service';
import { DestroyComponentService } from '../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { MatDialog } from '@angular/material/dialog';
import { CrearSolicitudComponent } from '../componentes/crear-solicitud/crear-solicitud.component';
import { Solicitud } from '../../Class/solicitud';

@Component({
  selector: 'app-solicitudes-list',
  templateUrl: './solicitudes-list.component.html',
  styleUrls: ['./solicitudes-list.component.sass']
})
export class SolicitudesListComponent implements OnInit {

  ngOnInit(){
    
  }
}
