<div class="row novelty-row-padding">

  <h2 mat-dialog-title>
    <span class="gray-color" style="margin-left: -9px"></span>
    <button style="float: right;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
    <div class="title content" style="width: 100%;">
      <img src="./assets/images/novelties.png" alt="noveltyIcon" class="icon">
      <span>
        <h1>{{data.data?.name}}</h1>
        <h5 style="font-size: 15px; font-family: 'Poppins', sans-serif!important;">{{data.data?.id_number}}</h5>
      </span>
      <!--<button class="re-asign" mat-icon-button>
        <i style="margin-right: 10px;">Reasignar</i><i style="font-size: 25px;" class="fi-rr-redo"></i>
      </button>-->
    </div>
  </h2>

  <h3>
    <span class="gray-color" style="margin-left: -9px">{{ dataInfo?.first_response.created_at | date:'EEEE d, MMMM' }}</span>
  </h3>
  <div class="col-12" style="padding: 0px;">
    <div class="col-7">
      <span
        class="badge" [style.background]="dataInfo?.noveltyTypeToPrint?.backgroundColor">{{ dataInfo?.novelty_type?.key }}</span> {{ dataInfo?.novelty_type?.name }}
    </div>
  </div>

  <mat-dialog-content style="padding: 3%; overflow-x: hidden" class="mat-typography">
    <div class="row">
      <div class="col-6" style="padding-top: 10px;">
        <div class="col-12">
          <br>
          <h4><b>Vacaciones solicitadas</b></h4>
          <p class="p-color"> <strong>Desde: </strong> {{ dataInfo?.start_date | date:'dd/MM/yyyy'  }} <strong>Hasta: </strong> {{dataInfo?.end_date | date:'dd/MM/yyyy' }}</p>
        </div>
        <div class="col-12">
          <p class="p-color"> <strong>Días disponibles: </strong> {{ availableDays }} <strong>Días hábiles solicitados: </strong> {{ businessDays }}</p>
        </div>
        <div class="col-12">
          <br>
          <p class="p-color"><b>Observaciones trabajador</b></p>
          <p class="p-color">{{ dataInfo?.first_response.justify }}</p>
        </div>
        <div class="col-12" *ngIf="dataInfo?.first_response.response != null">
          <br>
          <h4><b>Aprobacion jefe de área</b></h4>
          <p class="p-color"> <strong>Fecha de aprobacion: </strong> {{ dataInfo?.first_response.updated_at | date:'dd/MM/yyyy'  }} </p>
          <p class="p-color"><b>Observaciones jefe de área</b></p>
          <p class="p-color">{{ dataInfo?.first_response?.response }}</p>
        </div>
      </div>


      <div class="col-6 text-left">
        <h4 style="float: inline-start; margin-left: 60px;">Gestión Novedad</h4>
        <form style="margin-left: 60px;" [formGroup]="vacationsForm">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Estado</mat-label>
            <mat-select formControlName="state">
              <mat-option  *ngFor="let item of options" [value]="item.id">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Respuesta</mat-label>
            <textarea matInput formControlName="response"></textarea>
          </mat-form-field>

          <button mat-raised-button color="primary" (click)="UpdateState()" [disabled]="vacationsForm.invalid">
            Enviar respuesta
          </button>
        </form>
      </div>
    </div>
  </mat-dialog-content>

</div>