import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';
import { ReactiveFormsModule } from '@angular/forms';


//components
import { ReportsComponent } from './reports.component';
import { UserAssignmentReportComponent } from './modules/admin/user-assignment-report/user-assignment-report.component';
import { ReportShowComponent } from './modules/report/report-show/report-show.component';
import { AddReportFormComponent } from './modules/admin/add-report-form/add-report-form.component';


@NgModule({
  declarations: [
    ReportsComponent,
    UserAssignmentReportComponent,
    ReportShowComponent,
    AddReportFormComponent
  ],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule
  ]
})
export class ReportsModule { }
