import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatrizService } from 'src/app/modules/auditoria/services/matriz.service';
import { Tools } from 'src/app/modules/auditoria/utils/tools.utils';

@Component({
  selector: 'app-editar-matriz',
  templateUrl: './editar-matriz.component.html',
  styleUrls: ['./editar-matriz.component.sass']
})
export class EditarMatrizComponent implements OnInit {

  matrixForm:FormGroup;
  tiposMatrices = [];
  inteligencias = [];
  rangos = [];
  tipos_peso = [];
  campaigns = [];
  filteredOptionsCampaign: Observable<any[]>;
  crm_id;
  @ViewChild('campaignAuto', { read: MatAutocomplete }) campaignAuto:MatAutocomplete;
  constructor(
    public matrizRef:MatDialogRef<EditarMatrizComponent>,
    @Inject(MAT_DIALOG_DATA) public datos:any,
    private fb:FormBuilder,
    private matrizService:MatrizService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.getMatrixById();
    this.getSelects();
  }

  

  createForm(){
    this.matrixForm = this.fb.group({
      nombre: [{value:'',disabled:true},[Validators.required]],
      tipo_matriz_id: [{value:'',disabled:true},Validators.required],
      rango_id: [{value:'',disabled:true},Validators.required],
      tipo_peso_id: [{value:'',disabled:true},Validators.required],
      campania_asociada: ['',Validators.required],
      crm_id: ['',Validators.required],
      aplicar_inteligenciaNegocio: ['',Validators.required],
      inteligenciaNegocio_id: ['',Validators.required],
      isRetentionMatrix: [0, Validators.required],
      showFieldDuration: [0, Validators.required]
    })
  }

  getMatrixById(){
    this.matrizService.getMatrixById(this.datos.id_matriz).subscribe((resp:any)=>{
      this.matrixForm.get('nombre').setValue(resp[0].matriz.nombre);
      this.matrixForm.get('tipo_matriz_id').setValue(resp[0].matriz.tipo_matriz_id);
      this.matrixForm.get('crm_id').setValue(resp[0].matriz.crm_id);
      if (resp[0].matriz.rango) {
        this.matrixForm.get('rango_id').setValue(resp[0].matriz.rango_total_id);
      }else{
        this.matrixForm.get('rango_id').setValue('');
      }
      this.matrixForm.get('tipo_peso_id').setValue(resp[0].matriz.tipo_peso_id);
      this.matrixForm.get('campania_asociada').setValue(resp[0].matriz.asociacion_crm);
      this.matrixForm.get('aplicar_inteligenciaNegocio').setValue(resp[0].matriz.tiene_inteligenciaNegocio);
      this.matrixForm.get('inteligenciaNegocio_id').setValue(resp[0].matriz.inteligenciaNegocio_id);
      this.matrixForm.get('isRetentionMatrix').setValue(resp[0].matriz.is_retencion_matrix);
      this.matrixForm.get('showFieldDuration').setValue(resp[0].matriz.showFieldDuration);
    });
  }

  getSelects(){
    this.matrizService.getTypeMatrix().subscribe((resp:any)=>{
      this.tiposMatrices = resp
    });

    this.matrizService.getIntelligence().subscribe((resp:any)=>{
      this.inteligencias = resp;
    });

    this.matrizService.getRange().subscribe((resp:any)=>{
      this.rangos = resp;
    })

    this.matrizService.getWeight().subscribe((resp:any)=>{
      resp.forEach(tipo => {
        if (tipo.nombre === "Peso en Items") {
          tipo.nombre = 'Peso en Ítems';
        }
      });
      this.tipos_peso = resp;
    })

    this.matrizService.getCampaings().subscribe((resp:any)=>{
      this.campaigns = resp.data;
    });

  }

  private _filterCampaign(name: any): any[] {
    const filterValueCampaign = name.toLowerCase();
    return this.campaigns.filter(option => option.name.toLowerCase().includes(filterValueCampaign));
  }

  displayFnCampaign(campaign: any): string{
    return campaign && campaign.name ? campaign.name : '';
  }

  changeSelect(){

    if (this.matrixForm.get('campania_asociada').value == 1) {
      this.matrixForm.get('crm_id').enable();
    }else{
      this.matrixForm.get('crm_id').disable();
      this.matrixForm.get('crm_id').setValue(null);
    }
    
    if (this.matrixForm.get('aplicar_inteligenciaNegocio').value == 0) {
      this.matrixForm.get('inteligenciaNegocio_id').disable();
      this.matrixForm.get('inteligenciaNegocio_id').setValue(null);
    }else{
      this.matrixForm.get('inteligenciaNegocio_id').enable();
    }
  }

  update(){
    if (this.matrixForm.get('tipo_matriz_id').value == 1) {
      this.matrixForm.get('rango_id').disable();
      this.matrixForm.get('tipo_peso_id').disable();
    }else{
      this.matrixForm.get('rango_id').enable();
      this.matrixForm.get('tipo_peso_id').enable();
    }

    if (this.matrixForm.get('campania_asociada').value == 1) {
      this.matrixForm.get('crm_id').enable();
    }else{
      this.matrixForm.get('crm_id').disable();
    }
    
    if (this.matrixForm.get('aplicar_inteligenciaNegocio').value == false) {
      this.matrixForm.get('inteligenciaNegocio_id').disable();
    }else{
      this.matrixForm.get('inteligenciaNegocio_id').enable();
    }
    if (this.matrixForm.invalid) {
      return
    }
    this.matrixForm.addControl('grupo',new FormControl(1))
    this.matrixForm.enable();
    this.matrizService.updateMatrix(this.datos.id_matriz,this.matrixForm.value).subscribe((resp:any)=>{
      this.matrixForm.disable();
      if (resp.respuesta.codigo == 202) {        
        Tools.swalSuccess('¡Excelente!','Se ha actualizado la matriz correctamente.');
        this.matrizRef.close();
      }
    });
  }

}
