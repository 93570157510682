import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PurchaseOrderState } from './../../../../Class/order-state';
import { SolicitudesService } from './../../../../../services/rest/solicitudes.service';
import { RequestsService } from './../../../../../../bpms/services/requests.service';
import { OrdenesService } from './../../../../../services/rest/ordenes.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { saveAs as importedSaveAs } from 'file-saver';
@Component({
  selector: 'app-descarga',
  templateUrl: './descarga.component.html',
  styleUrls: ['./descarga.component.sass']
})
export class DescargaComponent implements OnInit {
  formDescarga    : FormGroup;
  states     : PurchaseOrderState [] ;
  type            : string;



  constructor(
    private fb: FormBuilder,
    private solicitudesService: SolicitudesService,
    private ordenesService: OrdenesService,
    @Inject(MAT_DIALOG_DATA) public data: any
     ) {
    this.initForm();
   }


  initForm(){
    this.formDescarga = this.fb.group({
      fecha_ini       : [ '', [Validators.required] ],
      fecha_fin       : [ '', [Validators.required] ],
      estado          : ['']
    })
  }

  ngOnInit(): void {
    this.type = this.data["type"];
    this.getStates();

  }


  private getStates(){
    if ( this.type =="solicitud" || this.type =="solicitud_aprobador"  || this.type == "historico" ){
      this.solicitudesService.getRequestStates()
        .subscribe( (res:PurchaseOrderState[] ) => {
          //res["data"].pop();
          if( this.type =="solicitud_aprobador" ){
            this.states = res["data"].filter( s => s.id  == 3);
            this.formDescarga.get("estado").setValue(3);
          }
          else if( this.type =="historico" ){
            this.states = res["data"].filter( s => s.id  == 4 || s.id  == 5 );
            this.formDescarga.get("estado").setValue(4);
          }
          else
          {
            this.states = res["data"].filter(s=> s.id !== 10 && s.id !== 11);
          }
        } )
    }
    else if(this.type =="orden")
    {
      this.ordenesService.getOrdersStates()
        .subscribe( (res:PurchaseOrderState[] ) => {
          this.states = res["data"].filter(s=> s.id !==2 && s.id !== 3 && s.id !== 4 );
        } )
    }
  }


  descargar(){
    if ( this.formDescarga.invalid ){
      return
    }
    let action;

    if ( this.type == "solicitud" || this.type =="solicitud_aprobador" || this.type == "historico" ){
      if(this.formDescarga.value.estado == 12) this.formDescarga.value.estado = "";
      action = this.solicitudesService.downloadRequestHist( this.formDescarga.value );
    }
    else if ( this.type == "orden" ){
      if(this.formDescarga.value.estado == 9) this.formDescarga.value.estado = "";
      action = this.ordenesService.downloadOrders(this.formDescarga.value);
    }



    action.subscribe( res => {
      importedSaveAs(res, 'reporte');
    } );




  }

}
