
<div class="margenes">

  <div class="row">
    <div class="header col-12">
      <h1>Configuración de accesos<i class="fas fa-container-storage"></i></h1>
      <p>Administre los accesos a cada informe de Power BI según el perfil de cada rol</p>
    </div>
  </div>

  <!--Filtros-->
  <form [formGroup]="formFilters">
    <div class="row mt-5" style="height: 50px;">
        <div class="col-2">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Campaña</mat-label>
            <input matInput
                  type               = "text"
                  placeholder        = "Seleccione una campaña"
                  formControlName    = "campaign_id"
                  [matAutocomplete]  = "auto"
                  >
            <mat-autocomplete
              autoActiveFirstOption
              #auto         = "matAutocomplete"
              [displayWith] = "getCampaignName.bind(this)"
              >
              <mat-option *ngFor="let camp of filteredCampaigns | async" [value]="camp.id">
                {{camp.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-2">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Cargo</mat-label>
            <input
                  type              = "text"
                  [matAutocomplete] = "auto2"
                  placeholder       = "Seleccione un cargo"
                  formControlName   = "position"
                  matInput
                  >
            <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">
              <mat-option *ngFor="let pos of filteredPositions | async" [value]="pos.name">
                {{pos.name}}sebas
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-1">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Tipo doc.</mat-label>
            <mat-select formControlName="id_type_id" #id_type_id>
              <mat-option></mat-option>
              <mat-option
                [value] = "type.id"
                *ngFor  = "let type of type_documents">
                {{ type.key }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-2">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Número de documento</mat-label>
            <input
              matInput
              [readonly]      = "id_type_id.value == null"
              formControlName = "id_number"
              autocomplete    = "off"
              placeholder     = "Seleccione un tipo de documento"
              >
          </mat-form-field>

        </div>
        <div class="col-1">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Nombre</mat-label>
            <input 
              matInput 
              placeholder       = "Nombre del dashboard"
              formControlName   = "dashboard_name"
              >
          </mat-form-field>
        </div>
        <div class="col-1">
          <button
            mat-raised-button
            class="w-100 h-75"
            color="primary"
            (click)="getDashes()"
            >
          <span class="fi-rr-search"></span>
        </button>

        </div>
        
        <div class="col-3">
          <button
            mat-raised-button
            class="w-100 h-75"
            color="primary"
            (click)="openEditModal()"
            style="float: right">
            Crear
          </button>
        </div>
    </div>
  </form>

  <div class="row">
    <div *ngIf="dashboards">
      <table mat-table [dataSource]="dashboards" class="mat-elevation-z8" class="mt-3" >
        <ng-container matColumnDef="accion">
          <th class="start" mat-header-cell *matHeaderCellDef> Acciones</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="editarReporte(element)">
              <i class="fi-rr-edit"></i>
            </button>

            <button mat-icon-button>
              <i class="fi fi-rr-trash" (click)="eliminarReporte(element)" ></i>
            </button>

          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Nombre </th>
          <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>


        <ng-container matColumnDef="key">
          <th mat-header-cell *matHeaderCellDef> Llave </th>
          <td mat-cell *matCellDef="let element"> {{element.key}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div>
    <mat-paginator #paginator
      [pageSizeOptions]   = "[5, 10, 25, 100]"
      aria-label          = "Select page"

      ></mat-paginator>
</div>




