import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs as importedSaveAs } from 'file-saver';
import { debounceTime } from 'rxjs/operators';
import { CampaignsService } from '../../../../../nomina/services/rest/campaigns.service';
import { JuridicaService } from '../../../../services/juridica.service';
import { CandidatesService } from '../../../../../rrhh/services/candidates.service';
import { PermitResponseBossComponent } from '../permit-response-boss/permit-response-boss.component';
import { PermitViewBossComponent } from '../permit-view-boss/permit-view-boss.component';

@Component({
  selector: 'app-permit-list-boss',
  templateUrl: './permit-list-boss.component.html',
  styleUrls: [ './permit-list-boss.component.sass' ]
})
export class PermitListBossComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  length;
  pageSize = 5;
  page = 1;
  isPage = 1;
  pageSizeOptions: number[] = [ 5, 10, 25, 100 ];

  displayedColumns: string[] = [ 'acciones', 'fecha', 'nombre', 'numero', 'estado' ];
  dataSource = new MatTableDataSource<any>();

  reportForm: FormGroup;

  stateControl = new FormControl();
  typeDocument = new FormControl(1);

  document: any;
  startDate: any = '';
  endDate: any;
  idCampaing: any;
  documents: any = [];
  idTypeDoc: any;
  states: any = [ { id: 4, name: 'Todos' }, { id: 0, name: 'En revisión' }, { id: 1, name: 'Aprobado' }, { id: 2, name: 'Rechazado' } ];
  idState: any = 4;

  public documentControl = new FormControl();

  constructor(public dialog: MatDialog,
              private service: CampaignsService,
              private permit: JuridicaService,
              private users: CandidatesService
  ) {

  }

  ngOnInit(): void {
    this.reportForm = new FormGroup({
      start: new FormControl(''),
      end: new FormControl('')
    });
    // get document types
    this.users.getIdTypes().subscribe(resp => {
      this.documents = resp;
    });

    // when state filter change
    this.stateControl.valueChanges.subscribe(resp => {
      this.resetPaginator(1);
      this.idState = resp;
      this.getPermits(this.idState, this.pageSize, this.page);
    });
    // documento
        this.users.getIdTypes().subscribe(resp => {
      this.documents = resp;
    });
    // when documentType change
    this.typeDocument.valueChanges.subscribe(resp => {
      this.idTypeDoc = resp;
      this.getPermits(this.idState, this.pageSize, this.page);
    });
    // get permits
    this.permit.getPermitsForBoss(this.idState).subscribe(resp => {
      this.dataSource = resp.data.data;
      this.length = resp.data.total;
    });

    // debounce document
    this.documentControl.valueChanges.pipe(debounceTime(1000)).subscribe(resp => {
      this.document = resp;
      this.getPermits(this.idState, this.pageSize, this.page);
    });
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-06-22
   * Metodo de paginación
   */
  pageEvent(event: any): void {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getPermits(this.idState, this.pageSize, this.page);
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-06-22
   * Modal de respuests de permiso
   */
  viewToPermissionResponse(element): void {
    const dialogNewgroup = this.dialog.open(PermitResponseBossComponent, {
      width: '70%',
      disableClose: true,
      data: {
        type: 'gestionar',
        data: element
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewgroup.afterClosed().subscribe(() => {
      this.getPermits(this.idState, this.pageSize, this.page);
    });

  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-06-22
   * Modal de visualizar justificación
   */
  viewPermissionDetail(element): void {
    const dialogNewgroup = this.dialog.open(PermitViewBossComponent, {
      width: '70%',
      disableClose: true,
      data: {
        type: 'detalle',
        data: element
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewgroup.afterClosed().subscribe(() => {
      this.getPermits(this.idState, this.pageSize, this.page);
    });

  }


  /**
   * @author Daniel Dominguez 
   * @createdate 2021-06-22
   * Metodo para capturar el documento a filtrar
   */
  onBlurMethod(e) {
    this.resetPaginator(1);
    this.document = e;
    this.getPermits(this.idState, this.pageSize, this.page);
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-06-22
   * Metodo para capturar el rango de fechas a filtrar
   */
  dateRangeChange() {
    this.resetPaginator(1);
    const date1: Date = this.reportForm.controls.start.value;
    const date2: Date = this.reportForm.controls.end.value;
    const dateStart: string | null = new DatePipe('en-US').transform(date1, 'yyyy-MM-dd');
    const dateEnd: string | null = new DatePipe('en-US').transform(date2, 'yyyy-MM-dd');
    this.startDate = dateStart;
    this.endDate = dateEnd;
    this.getPermits(this.idState, this.pageSize, this.page);
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-06-23
   * Metodo para filtrar los permisos
   */
  getPermits(type, pageSi, page) {
    if (this.document != null || this.idCampaing != null || page != null || pageSi != null || this.startDate != null && this.endDate != null) {
      this.permit.getPermitsForBoss(type, this.idTypeDoc, this.document, this.idCampaing, this.startDate, this.endDate, pageSi, page).subscribe(resp => {
        this.dataSource = resp.data.data;
        this.length = resp.data.total;
      });
    }
  }

  /**
   * @author Daniel Dominguez 
   * @createdate 2021-06-24
   * Metodo de descarga de reporte de permisos
   */
  downloadReport() {
    this.permit.getReportPermissionsForBoss(this.idState, this.idTypeDoc, this.document, this.idCampaing, this.startDate, this.endDate).subscribe(resp => {
      importedSaveAs(resp, 'reporte_permisos.xlsx');
    });
  }

  /**
   * @author Daniel Dominguez  <javier.c@montechelo.com.co>
   * @purpose resetea el paginador
   */
  resetPaginator(numPaginator): void {
    this.length = 0;
    this.pageSize = 5;
    this.page = 1;
    this.paginator.firstPage();
  }

}
