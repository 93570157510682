import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';

const RRHH_URL = environment.RRHH_URL;

@Injectable({
  providedIn: 'root'
})

export class ReferralsReportService {

  constructor(private http: HttpClient,
    private authService: AuthService) {
  }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * @author David Reinoso
   * @purpose Servicio para obtener la lista de referidos
   */
  getReferralsList(perPage: any = 5, Page?: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('perPage', perPage || 5)
        .set('page', Page || 1)
    };
    return this.http.get<any>(`${RRHH_URL}referenced/info/report`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author David Reinoso
   * @purpose Servicio para descargar el reporte de referidos
   */
  downloadReport() {
    const httpOptions = {
      params: new HttpParams()
        .set('export', 'true'),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${RRHH_URL}referenced/info/report`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }
}
