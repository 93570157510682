<div class="row novelty-row-padding">

  <h2 mat-dialog-title>
    <span class="gray-color" style="margin-left: -9px">Creación de Novedad</span>
    <button style="float: right;" mat-icon-button mat-dialog-close>
      <i class="fi-rr-cross-small"></i>
    </button>
  </h2>

  <mat-dialog-content style="padding: 3%; overflow-x: hidden" class="mat-typography">
    <div class="row">
      <div class="col-7">
        <div class="row">
          <form [formGroup]="noveltyForm" (ngSubmit)="createNovelty()">

            <div class="col-12 novelty-date gray-color">
              <span>Fecha de Novedad: {{ noveltyDate }}</span>
              <span style="margin-left: 10px;" class="fi-rr-pencil" (click)="show()"
                *ngIf="form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == vacationCode || form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == 27|| form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == 40 || form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == permitGrantedPreviousDaysCode || form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == domesticCalamity || form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == vacationExtemporalCode"></span>
            </div>

            <div class="col-12">
              <mat-form-field appearance="fill" class="w100">
                <mat-label>Tipo de Novedad</mat-label>
                <mat-select formControlName="noveltyTypeId" (selectionChange)="cleanPossibleSubtypeSelected()">
                  <mat-option *ngFor="let noveltyType of noveltyTypes" [value]="noveltyType.id">{{ noveltyType.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.noveltyTypeId.hasError('required')"> ¡Advertencia! Este campo es requerido
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12"
              *ngIf="form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].sub_types.length > 0">
              <mat-form-field appearance="fill" class="w100">
                <mat-label>Subtipo de Novedad</mat-label>
                <mat-select formControlName="noveltySubTypeId">
                  <mat-option *ngFor="let subType of noveltyTypesOld[form.noveltyTypeId.value].sub_types"
                    [value]="subType.id">{{ subType.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="form.noveltySubTypeId.hasError('required')"> ¡Advertencia! Este campo es requerido
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12"
              *ngIf="form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == 40 && editDate == true ||
                form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == permitGrantedPreviousDaysCode && editDate == true ||
                form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == 27 && editDate == true ||
                form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == vacationCode && editDate == true ||
                form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == domesticCalamity && editDate == true ||
                form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == vacationExtemporalCode && editDate == true">
              <div class="row">
                <div class="col-6">
                  <mat-form-field appearance="fill" class="w100">
                    <mat-label>Fecha inicio</mat-label>
                    <input matInput [matDatepicker]="picker"
                      [min]="(form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == vacationCode) ? currentDate : ''"
                      placeholder="Fecha novedad" autocomplete="off" formControlName="noveltyDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="form.noveltyDate.hasError('required')"> ¡Advertencia! Este campo es requerido
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-6">
                  <mat-form-field appearance="fill" class="w100">
                    <mat-label>Fecha fin</mat-label>
                    <input matInput [matDatepicker]="picker2" [min]="form.noveltyDate.value" placeholder="Fecha fin"
                      autocomplete="off" formControlName="noveltyDateEnd">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                    <mat-error *ngIf="form.noveltyDateEnd.hasError('required')"> ¡Advertencia! Este campo es requerido
                    </mat-error>
                  </mat-form-field>
                </div>

              </div>

            </div>

            <div class="col-12"
              *ngIf="form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == vacationExtemporalCode && editDate == false">
              <mat-form-field appearance="fill" class="w100">
                <mat-label>Fin Vacaciones</mat-label>
                <input matInput [matDatepicker]="picker" [min]="form.noveltyDate.value"
                  placeholder="Fecha fin vacaciones" autocomplete="off" formControlName="noveltyDateEnd">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="form.noveltyDateEnd.hasError('required')"> ¡Advertencia! Este campo es requerido
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12"
              *ngIf="form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == domesticCalamity && editDate == false">
              <mat-form-field appearance="fill" class="w100">
                <mat-label>Fin de calamidad</mat-label>
                <input matInput [matDatepicker]="picker" [min]="form.noveltyDate.value"
                  placeholder="Fecha fin vacaciones" autocomplete="off" formControlName="noveltyDateEnd">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="form.noveltyDateEnd.hasError('required')"> ¡Advertencia! Este campo es requerido
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12"
              *ngIf="form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == vacationCode || form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == vacationExtemporalCode && editDate == false">
              <p style="font-size: 15px!important;">
                <b>Días disponibles: </b> {{ availableDays }} | <b>Días solicitados: </b> {{ businessDays }}
              </p>
            </div>

            <div class="col-12"
              *ngIf="form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == vacationCode && editDate == false">
              <mat-form-field appearance="fill" class="w100">
                <mat-label>Tipo de vacaciones</mat-label>
                <mat-select formControlName="vacationTypeId">
                  <mat-option *ngFor="let vacationType of vacationsTypes" [value]="vacationType.id">{{ vacationType.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.vacationTypeId.hasError('required')"> ¡Advertencia! Este campo es requerido</mat-error>
              </mat-form-field>
            </div>

            <div class="col-12" *ngIf="form.vacationTypeId.value != '' ">
              <!-- Vacaciones remuneradas -->
              <div *ngIf="form.vacationTypeId.value == vacationCode">
                <mat-form-field appearance="fill" class="w100">
                  <mat-label>Rango vacaciones en disfrute</mat-label>
                  <input matInput [matDatepicker]="picker2" [min]="form.noveltyDate.value" placeholder="Fecha fin"
                    autocomplete="off" formControlName="noveltyDateEnd">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                  <mat-error *ngIf="form.noveltyDateEnd.hasError('required')"> ¡Advertencia! Este campo es requerido</mat-error>
                </mat-form-field>
              </div>
              <!-- Vacaciones en dinero/mixtas -->
              <div *ngIf="isOperationalPosition && form.vacationTypeId.value == vacationPaidCode || form.vacationTypeId.value == 'mixed'">
                <mat-form-field appearance="fill" class="w100">
                  <mat-label>Días solicitados en dinero</mat-label>
                  <input matInput min="1" placeholder="Días solicitados en dinero" autocomplete="off" formControlName="paidVacations" type="number">
                  <mat-error *ngIf="form.paidVacations.hasError('required')"> ¡Advertencia! Este campo es requerido</mat-error>
                  <mat-error *ngIf="form.paidVacations.hasError('min')"> ¡Advertencia! Este campo debe ser mayor o igual a uno (1)</mat-error>
                </mat-form-field>
                <span style="font-size: 12px; color: #717171;position: relative;top: -7px;">Recuerda que solo puedes solicitar la mitad o menos de los días que tienes disponibles en vacaciones anualmente.</span>
              </div>
              <!-- Vacaciones mixtas -->
              <div *ngIf="isOperationalPosition && form.vacationTypeId.value == 'mixed'">
                <mat-form-field appearance="fill" class="w100">
                  <mat-label>Rango vacaciones en disfrute</mat-label>
                  <input matInput [matDatepicker]="picker3" [min]="form.noveltyDate.value" placeholder="Fecha fin"
                    autocomplete="off" formControlName="noveltyDateEndMixted">
                  <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
                  <mat-error *ngIf="form.noveltyDateEndMixted.hasError('required')"> ¡Advertencia! Este campo es requerido</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="col-12"
              *ngIf="form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == 27 && editDate == false">
              <mat-form-field appearance="fill" class="w100">
                <mat-label>Fin incapacidad</mat-label>
                <input matInput [matDatepicker]="picker" [min]="form.noveltyDate.value"
                  placeholder="Fecha fin vacaciones" autocomplete="off" formControlName="noveltyDateEnd">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="form.noveltyDateEnd.hasError('required')"> ¡Advertencia! Este campo es requerido
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Eps -->

            <div class="col-12" *ngIf="form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == 27">
              <mat-form-field [hidden]="hiddenEps == true" appearance="fill" class="w100">
                <mat-label>Eps</mat-label>
                <mat-select formControlName="eps">
                  <mat-option *ngFor="let item of epsData" [value]="item.id">{{ item.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.eps.hasError('required')"> ¡Advertencia! Este campo es requerido
                </mat-error>
              </mat-form-field>
              <p style="font-size: 12px !important;"> <span class="fi-rr-info"></span> Por favor actualizar información
                si lo ve necesario</p>
            </div>

            <!-- Company -->

            <div class="col-12" *ngIf="form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == 27">
              <mat-form-field appearance="fill" class="w100">
                <mat-label>Empresa</mat-label>
                <mat-select formControlName="company">
                  <mat-option *ngFor="let item of companyData" [value]="item.id">{{ item.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.company.hasError('required')"> ¡Advertencia! Este campo es requerido
                </mat-error>
              </mat-form-field>
              <p style="font-size: 12px !important;"> <span class="fi-rr-info"></span> Por favor actualizar información
                si lo ve necesario</p>
            </div>

            <div class="col-12" *ngIf="form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == 27">
              <mat-form-field appearance="fill" class="w100" [hidden]="hiddeWhenIsLicency == true">
                <input matInput placeholder="Diagnostico" aria-label="State" [matAutocomplete]="auto"
                  [(ngModel)]="selectedDiagnostic" [formControl]="searchDiagnosticCtrl">
                <button *ngIf="selectedDiagnostic" matSuffix mat-icon-button aria-label="Clear"
                  (click)="clearSelection()">
                  <span class="fi-rr-cross-small"></span>
                </button>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelected()">
                  <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                  <ng-container *ngIf="!isLoading">
                    <mat-option *ngFor="let item of filteredDiagnostic" [value]="item">
                      {{item.code +'-'+ item.description}}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
                <mat-error class="mb-3" *ngIf="searchDiagnosticCtrl.hasError('required')"> ¡Advertencia! Este campo es
                  requerido
                </mat-error>
              </mat-form-field>
              <mat-error style="font-size: 11px !important;" class="ml-3 mb-3" *ngIf="isSelected == 1"> ¡Advertencia!
                Debe seleccionar una opcion de la lista
              </mat-error>

            </div>

            <div class="col-12" *ngIf="form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == 27">
              <mat-form-field appearance="fill" class="w100" *ngIf="showField">
                <mat-label>Especifique: </mat-label>
                <textarea class="w100" matInput formControlName="other" id="other" cols="3" rows="3"></textarea>
                <mat-error *ngIf="form.other.hasError('required')"> ¡Advertencia! Este campo es requerido
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12"
              *ngIf="form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == 40 && editDate == false || form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == permitGrantedPreviousDaysCode && editDate == false">
              <mat-form-field appearance="fill" class="w100">
                <mat-label>Fecha fin</mat-label>
                <input matInput [matDatepicker]="picker" [min]="cleanNoveltyDate" placeholder="Fecha fin permiso"
                  autocomplete="off" formControlName="noveltyDateEnd">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="form.noveltyDateEnd.hasError('required')"> ¡Advertencia! Este campo es requerido
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="fill" class="w100">
                <mat-label>Justificación</mat-label>
                <textarea class="w100" matInput formControlName="noveltyJustify" id="noveltyJustifyId" cols="3"
                  rows="3"></textarea>
                <mat-error *ngIf="form.noveltyJustify.hasError('required')"> ¡Advertencia! Este campo es requerido
                </mat-error>
              </mat-form-field>
            </div>

            <div *ngIf="form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == 40 || form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == permitGrantedPreviousDaysCode">
              <div class="col-12">
                <div style="display: block;">

                  <button mat-raised-button type="button" class="myButton2" (click)="fileInput.click()">
                    Subir Archivos
                    <span class="fi-rr-cloud-upload" style="margin-left: 10px;"></span>
                  </button>
                  <input hidden (change)="onChangeFile($event)" multiple name="file" #fileInput type="file" id="file"
                    accept="image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .docx, application/pdf">

                  <span class="fi-rr-add" style="font-size: 20px; color: #00acc1; margin-left: 20px;"
                    (click)="fileInput2.click()"></span>
                  <mat-error *ngIf="showFileError"> ¡Advertencia! Los archivos en conjunto no deben pesar mas de
                    {{permittedSize/1000000}} Mb actualmente pesan {{currentSize/1000000 | number: '1.1-1'}} Mb
                  </mat-error>
                  <input hidden (change)="onChangeFile($event)" name="file" #fileInput2 type="file" id="file2"
                    accept="image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .docx, application/pdf">

                  <!--                   <h5 style="margin-top: 10px;">Archivos seleccionados: {{dataFileArray.length}}</h5> -->
                  <h5 style="margin-top: 5px;">Solo se aceptan archivos .docx .jpg .png o .pdf </h5>

                  <h5 *ngFor="let item of dataFileArray; let i = index;" style="margin-top: 10px;">
                    <span class="fi-rr-trash" style="color: crimson; font-size: 14px; margin-right: 5px;"
                      (click)="deleteFieldFile(i)"></span> {{item.name}}
                  </h5>

                </div>
              </div>
            </div>
            <!-- archivos incapacidad -->
            <div
              *ngIf="form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == 27 || form.noveltyTypeId.value && noveltyTypesOld[form.noveltyTypeId.value].key == 99">
              <div class="col-12">
                <div style="display: block;">

                  <button mat-raised-button type="button" class="myButton2" (click)="fileInput.click()">
                    Subir Archivos
                    <span class="fi-rr-cloud-upload" style="margin-left: 10px;"></span>
                  </button>
                  <input hidden (change)="onChangeFile($event)" multiple name="file" #fileInput type="file" id="file"
                    accept="application/pdf">

                  <span class="fi-rr-add" style="font-size: 20px; color: #00acc1; margin-left: 20px;"
                    (click)="fileInput2.click()"></span>
                  <input hidden (change)="onChangeFile($event)" name="file" #fileInput2 type="file" id="file2"
                    accept="application/pdf">
                  <mat-error *ngIf="showFileError"> ¡Advertencia! Los archivos en conjunto no deben pesar mas de
                    {{permittedSize/1000000}} Mb actualmente pesan {{currentSize/1000000 | number: '1.1-1'}} Mb
                  </mat-error>
                  <!--                   <h5 style="margin-top: 10px;">Archivos seleccionados: {{dataFileArray.length}}</h5> -->
                  <h5 style="margin-top: 5px;">Solo se aceptan archivos .pdf </h5>

                  <h5 *ngFor="let item of dataFileArray; let i = index;" style="margin-top: 10px;">
                    <span class="fi-rr-trash" style="color: crimson; font-size: 14px; margin-right: 5px;"
                      (click)="deleteFieldFile(i)"></span> {{item.name}}
                  </h5>

                </div>
              </div>
            </div>

            <div class="col-12">
              <button mat-raised-button class="w100" color="primary"
                [disabled]="sending || noveltyForm.invalid || isSelected == 1 || searchDiagnosticCtrl.invalid || showFileError">
                Crear novedad
              </button>
            </div>




          </form>
        </div>
      </div>

      <div class="col-5 text-center">
        <div *ngIf="showCalendar" style="height: 250px">
          <full-calendar [options]="calendarOptions"></full-calendar>
        </div>
        <img *ngIf="!showCalendar" src="./assets/images/novelties-1.svg" style="height: 350px; width: 350px"
          alt="noveltyIcon">
      </div>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <!--<div class="row novelty-footer-hours">
      <div class="col-4">
        <b>Inicio turno</b>
        <p class="gray-color"><b>{{ scheduleTime }}</b></p>
      </div>
      <div class="col-4">
        <b>Fin turno</b>
        <p class="gray-color"><b> {{ endTime }}</b></p>
      </div>
      <div class="col-4" style="color: red">
        <b>Hora inicio turno</b>
        <p><b>{{ realTime }}</b></p>
      </div>
    </div>-->
  </mat-dialog-actions>

</div>