import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { QuestionInterface } from 'src/app/modules/calidad/interfaces/question.interface';
import { InteligenciaService } from 'src/app/modules/calidad/services/inteligencia.service';
import { PreguntasService } from 'src/app/modules/calidad/services/preguntas.service';
import { Tools } from 'src/app/modules/calidad/utils/tools.utils';
import Swal from 'sweetalert2';
import { CrearPreguntaComponent } from '../crear-pregunta/crear-pregunta.component';
import { DetallePreguntaComponent } from '../detalle-pregunta/detalle-pregunta.component';
import {TranslatesService} from '../../../../services/translates.service';

@Component({
  selector: 'app-tabla-preguntas',
  templateUrl: './tabla-preguntas.component.html',
  styleUrls: ['./tabla-preguntas.component.sass']
})
export class TablaPreguntasComponent implements OnInit, OnDestroy {
  private uuid = 'd74571b3-6e8a-4a2a-871b-aafb16d2b1a2';
  public config: any;
  /**
  * @author Cristian Gallo
  * Variable para almacenar los tipos de preguntas
  */
  tiposPreguntas = [];

  //Columnas de la tablas
  displayedColumns:string[] = ['acciones','fecha_creacion','tipo_pregunta','pregunta'];

  //Variable para el manejo de los datos en la tabla
  dataSource = new MatTableDataSource<QuestionInterface>();

  //Variable para almacenar la selección del checkbox
  selection = new SelectionModel<QuestionInterface>(true, []);

  //Variable para controlar la activación de los checkbox
  @Input()checkbox:boolean = false;

  //Variable para recibir el id de fomulario
  @Input()idForm = '';

  //Eventos para actualizar tabla desde el componente padre
  @Output() arraySelection = new EventEmitter()
  @Output() cancelarEvent = new EventEmitter()

  //Control de paginación de la tabla
  length;
  pageSize = 5;
  page = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  @ViewChild(MatPaginator) paginator:MatPaginator;

  //Filtros
  filtroPregunta:string = '';
  filtroFecha:string = '';
  filtroTipo_pregunta:string = '';

  //Observable para los cambios de la tabla
  subscription:Subscription;

  constructor(
    private questionDialog:MatDialog,
    private detailDialog:MatDialog,
    private preguntaService:PreguntasService,
    private inteligenciaS:InteligenciaService,
    private configService: TranslatesService,
    ) { }

  ngOnInit(): void {
    this.getQuestions(this.page,this.pageSize);
    this.getTypeQuestions();
    this.subscription = this.inteligenciaS.refresh$.subscribe(()=>{
      this.getQuestions(this.page,this.pageSize);
    });
    this.configService.getConfig(this.uuid).subscribe((resp) => {
      this.config = resp;
    });
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  /**
  * @author Cristian Gallo
  * Función para visualizar el dialog de crear preguntas
  */
  newQuestion(){
    const questionRef = this.questionDialog.open(CrearPreguntaComponent, {
      width: '840px',
      disableClose: true,
      data: {tiposPreguntas: this.tiposPreguntas}
    });
    questionRef.afterClosed().subscribe(result=>{
      this.getQuestions(this.page,this.pageSize)
    });
  }
  /**
  * @author Cristian Gallo
  * Función para visualizar el dialog detalle y edición de preguntas
  */
  detailQuestion(edit:boolean,id_question){
    const detailRef = this.detailDialog.open(DetallePreguntaComponent,{
      width: '840px',
      disableClose: true,
      data: {edit:edit, id:id_question}
    })
    detailRef.afterClosed().subscribe(result=>{
      if (result == 202) {
        this.getQuestions(this.page,this.pageSize)
      }
    })
  }
  /**
  * @author Cristian Gallo
  * Función para eliminar preguntas
  */
  removeQuestion(id_question){
    Swal.fire({
      icon:'warning',
      title: this.config.actions.remove.title,
      html: '<p> ' + this.config.actions.remove.htmlConfirm + '</p><p style="margin-top:-1em;"> ' + this.config.actions.remove.htmlBody +' </p>',
      showCancelButton: true,
      cancelButtonText: this.config.actions.remove.btnCancel,
      confirmButtonText: this.config.actions.remove.btnConfirm,
      reverseButtons: true,
      width: '810px',
      padding: '2em 7em'
    }).then((result)=>{
      if (result.isConfirmed) {
        this.preguntaService.removeQuestion(id_question).subscribe((resp:any)=>{
          if (resp.respuesta.codigo === 202) {
            Swal.fire({
              icon:'success',
              title: this.config.actions.remove.resSuccessTitle,
              text: this.config.actions.remove.resSuccessText,
              showConfirmButton:false,
              timer: 2500
            });
            this.getQuestions(this.page,this.pageSize);
          }else {
            Swal.fire({
              icon: 'error',
              title: this.config.actions.remove.resErrorTitle,
              text: this.config.actions.remove.resErrorText,
              showConfirmButton: false,
              timer: 2500
            })
          }
        },(err:any)=>{
          Swal.fire({
            icon: 'error',
            title: this.config.actions.remove.resError2Title,
            text: this.config.actions.remove.resError2Text,
            showConfirmButton: false,
            timer: 2500
          })
        });
      }
    });
  }

  /**
  * @author Cristian Gallo
  * Función para la paginación de la tabla
  */
  pageEvent(event:any){
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getQuestions(this.page,this.pageSize);
  }

  filtrar(){
    this.paginator.firstPage();
    this.getQuestions(this.page,this.pageSize);
  }

  /**
  * @author Cristian Gallo
  * Función para obtener todas las preguntas creadad y activas
  */
  getQuestions(page,pageSize){
    const params = {
      page: page,
      numeroReg: pageSize,
      pregunta: '',
      fecha: '',
      tipo_pregunta: '',
      idForm: '',
    }
    //Filtros
    if (this.filtroPregunta != "") {
      params.pregunta = this.filtroPregunta
    }

    if (this.filtroFecha != "") {
      if ( this.filtroFecha == null) {
        params.fecha = ''
      }else{
        params.fecha = moment(this.filtroFecha).format('YYYY-MM-DD')
      }
    }

    if (this.filtroTipo_pregunta != "") {
      if (this.filtroTipo_pregunta == null) {
        params.tipo_pregunta = '';
      }else{
        params.tipo_pregunta = this.filtroTipo_pregunta
      }
    }

    if (this.idForm != "") {
      params.idForm = this.idForm;
    }

    this.preguntaService.getQuestions(params).subscribe((resp:any)=>{
      this.dataSource.data = resp.data
      this.length = resp.total
      this.pageSize = resp.per_page;
    });
  }

  /**
  * @author Cristian Gallo
  * Función para obtener los tipos de preguntas existentes
  */
  getTypeQuestions(){
    this.preguntaService.getTypeQuestions().subscribe((resp:any)=>{
      this.tiposPreguntas = resp;
    })
  }

  //Control para checkbox
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  //Control para checkbox
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  //Control para checkbox
  checkboxLabel(row?: QuestionInterface): string {

    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  /**
  * @author Cristian Gallo
  * Función para obtener las filas seleccionadas
  * y envio de las mismas al padre
  */
  selections(){
    this.arraySelection.emit(this.selection.selected);
    this.selection.clear();
  }

  cancelar(){
    this.cancelarEvent.emit();
  }

}
