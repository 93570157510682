<div class="admin-reporte">

    <div class="header">
  
      <h2 style="height: 33px !important;
      font: normal normal bold 45px/35px Poppins !important;
      letter-spacing: 0px !important;color: #353535 !important;opacity: 1 !important;">Reporte</h2>
      <br>
      <p>Aquí podrás generar el reporte de los coachings realizados. </p>
      <p>No olvides, “El respeto y empatía son clave”.</p>
    </div>  
    <div class="mt-4">
      <app-filtros></app-filtros>
    </div>
    <div class="fondo">
      <img src="../../../../../assets/images/coach/Ilustracion.svg" alt="" width="100%" height="10%">
    </div>
</div>