import { animate, state, style, transition, trigger } from "@angular/animations";

export const contacAnimation= [
    trigger('toggleContactBar', [
        state('true', style({
          transform: 'translateX(0%)'
        })),
        state('false', style({
          transform: 'translateX(110%)'
        })),
        transition('true <=> false', [
          animate('300ms ease-in-out')
        ])
    ])
]