import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CreateCampaignComponent } from '../create-campaign/create-campaign.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CampaignService } from 'src/app/modules/speech-analytics/services/campaign.service';
import { EditCampaignComponent } from '../edit-campaign/edit-campaign.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-config-campain',
  templateUrl: './config-campain.component.html',
  styleUrls: ['./config-campain.component.sass']
})
export class ConfigCampainComponent implements OnInit {
  
  filter: string = '';
  formFilters:FormGroup; 
  textInputFilter: string = '';
  configPaginator: any = { length: 0, pageSize: 5, pageIndex: 0, previousPageIndex: 0 };
  pageSizeOptions = [5, 10, 25, 50, 100];

  @ViewChild(MatPaginator) paginator: MatPaginator;


  //configuracion informacion a mostar
  configColumnsTable: any[] = [
    { key: 'actions', name: 'Acciones' },
    { key: 'name', name: 'Nombre' },
    { key: 'campaign_id', name: 'Campaña vicidial' },
    { key: 'connection_name', name: 'Conexión' },
    { key: 'bilingue', name: 'Bilingue' },
    { key: 'dead_time', name: 'Tiempo muerto' },
  ]; 

  //configuracion columnas tabla
  keysColumnsTable: string[] = [];
  displayedColumns: string[] = ['actions', 'name', 'campaign_id', 'connection_name', 'bilingue', 'dead_time'];

//datasoure para manejo informacion en la tabla
  dataSource = new MatTableDataSource<any>();
  
//marsort para ordenar los datos cargados por la tabla
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.getCampaigns();
    this.keysColumnsTable = this.configColumnsTable.map((item: any) => item.key);
  }

  constructor(
    private dialog:MatDialog,
    private campaignService: CampaignService,
  ) { }


  /**
   * Modal para crear nueva campaña
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  createCampaing(): void {
    const dialogRef= this.dialog.open(CreateCampaignComponent, {
      width: '50%',
      maxWidth: '80vw',
      maxHeight: '95vh',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getCampaigns();  
    });
  }

  /**
   * Metodo para modal editar
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  editCampaign(id: number): void {
    const dialogRef = this.dialog.open(EditCampaignComponent, {
      width: '50%',
      maxHeight: '95vh',
      data: { id: id }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getCampaigns();  
    });
  }

  /**
   * Modal para probar la conexion
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  openTestModal(id: number): void {
    Swal.fire({
      title: 'Selecciona una opción',
      text: "¿Deseas hacer el test de alcance al backend o a la IA?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      confirmButtonText: 'Back de Speech',
      cancelButtonText: 'Inteligencia Artificial'
    }).then((result) => {
      if (result.isConfirmed) {
        this.campaignService.getVerifyScopeSOUL(id).subscribe(res => {
          Swal.fire({
            title: 'Validación alcances SOUL',
            text: res.data,
            icon: 'info',
            timer: 5000,
            showConfirmButton: false
          });
        }, error => {
          console.log('Error en la verificación:', error.error);
          Swal.fire({
            title: 'Error',
            text: 'Ocurrió un error en la verificación del alcance al backend.',
            icon: 'error',
            timer: 2000,
            showConfirmButton: false
          });
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.campaignService.getVerifyScopeIA(id).subscribe(res => {
          const message = res.data.message
            .replace(/True/g, 'true')
            .replace(/False/g, 'false')
            .replace(/None/g, 'null');
  
          const data = JSON.parse(message.replace(/'/g, '"'));
          const ipStatusMap = new Map<string, { allPortsOk: boolean, anyPortFail: boolean }>();
  
          // Procesar los datos
          data.forEach(item => {
            if (!ipStatusMap.has(item.ip)) {
              ipStatusMap.set(item.ip, { allPortsOk: true, anyPortFail: false });
            }
            const status = ipStatusMap.get(item.ip);
            if (item.estado === false) {
              status.allPortsOk = false;
              status.anyPortFail = true;
            }
          });
  
          let todasConexionesEstablecidas = true;
          let conexionesParciales = [];
          let sinConexion = [];
  
          ipStatusMap.forEach((status, ip) => {
            if (status.allPortsOk) {
              //odas las conexiones están bien para esta IP
            } else if (status.anyPortFail) {
              conexionesParciales.push(ip);
            } else {
              sinConexion.push(ip);
            }
  
            if (!status.allPortsOk) {
              todasConexionesEstablecidas = false;
            }
          });
  
          let resultado = '';
          if (todasConexionesEstablecidas) {
            resultado = 'Conexión establecida correctamente. El servidor está accesible desde el back IA.';
          } else if (conexionesParciales.length > 0) {
            resultado = `Alcance Incompleto. No se pudo establecer conexión completa en las siguientes IPs: ${conexionesParciales.join(', ')}`;
          } else {
            resultado = `Sin conexión: No se pudo establecer conexión en ninguna de las IPs.`;
          }
  
          Swal.fire({
            title: 'Validación alcances IA',
            text: resultado,
            icon: 'info',
            timer: 5000,
            showConfirmButton: true,
            confirmButtonColor: '#2CABBC',
            confirmButtonText: 'Aceptar',
          });
        }, error => {
          console.log('Error en la verificación:', error.message);
          Swal.fire({
            title: 'Error',
            text: 'Ocurrió un error en la verificación del alcance al backend de la IA.',
            icon: 'error',
            timer: 5000,
            showConfirmButton: true,
            confirmButtonColor: '#2CABBC',
            confirmButtonText: 'Aceptar',
          });
        });
      }
    });
  }

  /**
   * Metodo que pagina la informacion de la tabla.
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
   * @param $event Evento emitido por el paginador.
  */
  changePage($event: PageEvent): void {
    this.configPaginator = $event;
    this.getCampaigns();
  }

  /**
   * Metodo que filtra los datos de la tabla.
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
   * @param $event Evento emitido por el input.
  */

  onSearchClick(value: string): void {
    this.configPaginator.pageIndex= 0;
    this.paginator.firstPage();
    this.filter = value;
    this.getCampaigns(); 
  }

  /**
   * Metodo que trae todos los servidores registrados. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  getCampaigns(): void {
    this.campaignService.getCampaign(this.configPaginator, this.filter).subscribe(res => {
      this.dataSource.data = res.data.data.map(item => ({
        ...item,
        connection_name: item.connection.name  //signa el nombre de la base de datos al campo connection_id
      }));
      this.dataSource.sort = this.sort;
      this.configPaginator.length = res.data.total;
    });
  }

  /**
   * Metodo cambiar de estado el toggle
   * @author Yeison Sepulevda
   * @createdate 2024-02-15
  */
  toggleStateChanged(element: any): void {
    let confirmationMessage = '';
    if (!element.active) {
      confirmationMessage = '¿Estás seguro de deshabilitar la campaña?';
    } else {
      confirmationMessage = '¿Estás seguro de habilitar la campaña?';
    }
  
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      text: confirmationMessage,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.changeState(element);
      } else {
        element.active = !element.active;
      }
    });
  }
  
  /**
   * Metodo actualizar el estado de la campaña
   * @author Yeison Sepulevda
   * @createdate 2024-02-15
   * 
  */
  changeState(element: any): void {   
    this.campaignService.stateCampaign({ id: element.id })
      .subscribe(
        () => {
          let confirmationMessage = '';
          if (!element.active) {
            confirmationMessage = 'Se ha deshabilitado la campaña con éxito.';
          } else {
            confirmationMessage = 'Se ha habilitado la campaña con éxito.';
          }
          
          Swal.fire({
            icon: 'success',
            title: '¡Excelente!',
            text: confirmationMessage,
            timer: 3000, 
            showConfirmButton: false 
          });
        },
        (error) => {
          console.error('Error al cambiar de estado', error.error)
            Swal.fire({
              icon: 'error',
              title: 'No se pudo cambiar el estado',
              text: error.error.error,
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#2CABBC',
            }).then(() => {
              this.getCampaigns();
            });
        }
      );
  }

}
