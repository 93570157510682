<h2 mat-dialog-title class="fw-bold fs-4">Finalizar PQRS</h2>
<div mat-dialog-content>
    <form [formGroup]="formViewPlantillas" class="mt-2">
        <div class="row mb-4">
            <div class="col-md-12 text-justify">
                <span class="fw-bold">Nota:</span> Desde aquí podrá enviar la respuesta al cliente, adicional si tiene una plantilla perzonalizada podra seleccionarla y editarla.
            </div>
        </div>
        <div *ngIf="data.plantillas.length > 0" class="row">
            <div class="col-md-12">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Plantilla HTML</mat-label>
                    <mat-select formControlName="plantilla" (selectionChange)="changePlantillaEmailBody($event)">
                        <mat-option *ngFor="let plantilla of data.plantillas" [value]="plantilla.id">{{ plantilla.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-files-upload
                    (sendFiles)="setListFiles($event)">
                </app-files-upload>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <angular-editor
                    [placeholder]="'Enter text here...'"
                    [config]="editorConfig"
                    formControlName="bodyPlantilla">
                </angular-editor>
                <mat-error *ngIf="formViewPlantillas.get('bodyPlantilla').errors">
                    <span *ngIf="formViewPlantillas.get('bodyPlantilla').errors.required">Este campo es requerido</span>
                </mat-error>
            </div>
        </div>
        <div class="row mt-4 d-flex justify-content-center">
            <div class="col-3">
              <button mat-stroked-button mat-dialog-close color="primary" class="w-100" type="button">Cancelar</button>
            </div>
            <div class="col-3">
              <button mat-raised-button color="primary" class="w-100" type="button" (click)="cerrarPqrs()">Enviar</button>
            </div>
        </div>
    </form>
</div>
