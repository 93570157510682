import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReservasRoutingModule } from './reservas-routing.module';
import { ReservasComponent } from './reservas.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { BookingsModule } from './modules/bookings/bookings.module';
import { ResourcesModule } from './modules/resources/resources.module';



@NgModule({
  declarations: [ReservasComponent],
  imports: [
    CommonModule,
    ReservasRoutingModule,
    SharedModule,
    MaterialModule,
    BookingsModule,
    ResourcesModule
  ]
})
export class ReservasModule { }
