import { UsersService } from 'src/app/modules/ciu/services/rest/users.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { Subscription } from 'rxjs';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-forgot-user',
  templateUrl: './forgot-user.component.html',
  styleUrls: ['./forgot-user.component.sass']
})
export class ForgotUserComponent implements OnInit, OnDestroy {
  public passForm: FormGroup;
  public subscription : Subscription;
  public documentList : any;
  public selectedValue: string;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService : AuthService,
    private dialogRef:MatDialogRef<ForgotUserComponent>,
    private userService : UsersService,
    private destroyService: DestroyComponentService,
    ) { }

  ngOnInit(): void {
    this.formControl();
    this.getDocumentList();
  }

get error(): any { return this.passForm.controls; }

/**
   * @author Carlos Nieto
   * @createdate 2021-12-07
   * Metodo que crea el formGroup con cada campo y su respectiva validacion
   */
 formControl(): void{
    this.passForm = new FormGroup({
      id_type_id: new FormControl('', [Validators.required]),
      id_number: new FormControl('', [Validators.required,Validators.pattern('^([0-9])*$'),Validators.maxLength(15), Validators.minLength(5)])
    });
}

/**
   * @author Carlos Nieto
   * @createdate 2022-02-14
   * Metodo que crea lista los tipos de documentos
   */
 getDocumentList(): void{
   this.userService.getIdTypes().subscribe(
       response => {
        this.documentList = response;
       }
   );
}


  /**
   * @author Carlos Nieto
   * @createdate 2021-12-07
   * @param passForm Formulario diligenciado con el campo id_number y id_type_id
   * Metodo que envia el formulario al servicio
   */
  sendEmail(passForm): void{
    this.subscription = this.authService.SendEmailWithUserName(passForm.value).subscribe(
      response => {
        if (response.data.response === 'OK') {
           Swal.fire({
            html: `Por favor revisa tu correo ${response.data.mail}`,
            icon: 'success',
            title : 'Enviado',
            confirmButtonText: 'Aceptar'
          });
          this.dialogRef.close();
        }
      }
    );
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
      this.destroyService.destroyComponent();
  }

}
