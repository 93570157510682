import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-label-dialog',
  templateUrl: './create-label-dialog.component.html',
  styleUrls: ['./create-label-dialog.component.sass']
})
export class CreateLabelDialogComponent implements OnInit {
  formCreateLabel: FormGroup = new FormGroup({
    nameLabel: new FormControl('', [Validators.required])
  });

  constructor(private matDialogRef: MatDialogRef<CreateLabelDialogComponent>) { }

  ngOnInit(): void {

  }

  /**
    * @author Fabian Duran
    * @createdate 2023-06-16
    * Metodo que retorna los controles del formulario. 
  */
  get errorsForm(): any {
    return this.formCreateLabel.controls;
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-06-16
    * Metodo que valida el formulario y envia la data al componente padre. 
  */
  createLabel(): void {
    if (this.formCreateLabel.valid) this.matDialogRef.close(this.formCreateLabel.value);
    else this.formCreateLabel.markAllAsTouched();
  }
}