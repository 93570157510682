<div class="main-container">
  <div class="mt-5">
    <button mat-icon-button class="btn-regresar" [routerLink]="['/mios/calidad/control']">
      <mat-icon matPrefix style="background-color: #000; border-radius: 50%;"><i class="fi-sr-angle-small-left" style="color: #FFF;"></i></mat-icon>
      {{config.labels.backButton}}
    </button>
  </div>
  <header class="mt-5 mb-5">
    <h1 class="interlineado">{{title}}</h1>
    <p>{{config.labels.matrix}}{{tipo}}</p>
    <h5 *ngIf="tipo != 'Cumplimiento'">{{ tipo_peso }}</h5>
    <h5>{{config.labels.createdOn}} {{creacion | date:'dd/MM/yyyy hh:mm a'}}</h5>
  </header>
  <p class="primary">{{config.labels.weightRange}} ({{minimo}} - {{maximo}})</p>
  <p class="blue">{{config.labels.currentWeight}} ({{peso_actual}})</p>
  <div class="mt-5">
    <app-tabla-control [campania]="campania" [idMatriz]="id_matriz"
                       [matriz]="title"></app-tabla-control>
  </div>
</div>
