<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
  <i class="fi-rr-cross"></i>
</button>
<h2 mat-dialog-title *ngIf="!data.action">Tipos de contrato</h2>
<h2 mat-dialog-title *ngIf="data.action">Resumen</h2>
<hr>
<mat-dialog-content>
  <div  style="padding: 15px 25px 32px;" *ngIf="!data.action">
      <mat-radio-group class="example-radio-group"   fxLayout="column" [(ngModel)]="option">
          <mat-radio-button *ngFor="let item of  types" [value]="item.key" class="example-radio-button" ><span>{{item.name}}</span> </mat-radio-button>

       </mat-radio-group>
  </div>
  <div *ngIf="data.action">
     <p><i class="fi-rr-users"></i> &nbsp; <b>Total de Candidatos Importados</b> &nbsp;&nbsp; <span>{{data.fileUpdaloaded.totalCandidates}}</span> </p>
    <p><i class="fi-rr-check"></i> &nbsp; <b>Candidatos registrados con éxito </b> &nbsp;&nbsp;<span>{{data.fileUpdaloaded.agreementRulesToSave}}</span></p>
    <p> <i class="fi-rr-exclamation"></i> &nbsp; <b>Candidatos o razones para ignorar este proceso</b>&nbsp;&nbsp;  <span>{{ data.fileUpdaloaded.agreementRulesToIgnore.length }}</span></p>
     <div *ngIf="data.fileUpdaloaded.agreementRulesToIgnore.length">
        <ul>
            <li *ngFor="let row of fileUpdaloaded.agreementRulesToIgnore"> {{row.name}} <b>({{ row.reason }})</b></li>
        </ul>
    </div> <!---->
  </div>

</mat-dialog-content>
<mat-dialog-actions align="center" style="margin-bottom:10px ; " *ngIf="!data.action">
<button mat-flat-button color="primary" [mat-dialog-close]="option">Descargar</button>
</mat-dialog-actions>
