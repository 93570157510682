import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthService } from '../../../core/services/rest/auth.service';
import { environment } from '../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

const JURIDICA_URL = environment.JURIDICA_URL;

@Injectable({
  providedIn: 'root'
})
export class SuspencionProcessService {


  constructor(private http: HttpClient,
              private authService: AuthService) {
  }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener la suspenciones que estan en proceso
   */
  getSuspencionProcess() {
    return this.http.get<any>(`${ JURIDICA_URL }suspensions`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener reporte de suspenciones
   */
  getSuspencionProcessReport(doctype?: any, document?: any, acta?: any, initial_date?: any, end_date?: any, campaing?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('dischargeCertificate', acta || '')
        .set('initialDate', initial_date || '')
        .set('endDate', end_date || '')
        .set('documentType', doctype || '')
        .set('document', document || '')
        .set('campaign', campaing || ''),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(`${ JURIDICA_URL }suspensions/report`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener las suspenciones por filtros
   */
  getSuspencionProcessBy(doctype?: any, document?: any, acta?: any, initial_date?: any, end_date?: any, campaing?: any, perPage: any = 5, Page?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('dischargeCertificate', acta || '')
        .set('initialDate', initial_date || '')
        .set('endDate', end_date || '')
        .set('documentType', doctype || '')
        .set('document', document || '')
        .set('campaign', campaing || '')
        .set('perPage', perPage)
        .set('page', Page || 1)
    };
    return this.http.get<any>(`${ JURIDICA_URL }suspensions`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener informacion de una suspension
   */
  getSuspencionProcessById(id) {
    return this.http.get<any>(`${ JURIDICA_URL }suspension/${ id }`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para obtener informacion de una suspension gestionada
   */
  getSuspencionManagedById(id) {
    return this.http.get<any>(`${ JURIDICA_URL }suspensionManaged/${ id }`)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Daniel Dominguez 
   * @purpose Servicio para respuesta de suspension
   */
  suspensionProcessResponse(id, data) {
    return this.http.post<any>(`${ JURIDICA_URL }suspension/manage/${ id }`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * @author Andres Buitrago
   * @purpose Servicio que verifica dias habiles y no habiles de la suspension a realizar
   */
  checkSuspensionDates(params): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', params.startDate || '')
        .set('endDate', params.endDate || '')
    };
    return this.http.get<any>(`${ JURIDICA_URL }suspension/checkSuspensionDays`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

}
