import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const CRM_URL = environment.CRM2_URL;

@Injectable({
  providedIn: 'root'
})
export class DynamicFormServiceService {

  constructor(private http:HttpClient) { }

  getData(apiserve:string, key:string){
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     Authorization: 'bearer ' + this.AuthService.getUser().access_token,
    //   }),
    // };

    let serve = null;
    if(apiserve == 'rrhh'){
      serve = environment.RRHH_URL;
    }else if(apiserve == 'bpms'){
      serve = environment.BPMS_URL;
    }else if(apiserve == 'nomina'){
      serve = environment.NOMINA_URL
    }else if(apiserve == 'health'){
    serve = environment.HEALTH_URL
    }
    if(!serve) throw new Error("Api Serve no encontrado");
    return this.http.get( `${serve}${key}`);
  }

  downloadFile(params: FormData){
    return this.http.post(`${CRM_URL}formanswer/download`, params, {responseType: 'blob'});
  }
}
