<h2 mat-dialog-title>{{ data.title }}
	<button style="float: right" mat-icon-button mat-dialog-close>
		<i class="fi-rr-cross"></i>
	</button>
</h2>

<form class="row form" [formGroup]="userForm" (ngSubmit)="saveUser(userForm)" style="padding-left: 10px;">
	<mat-dialog-content style="padding-left: 3%;" class="mat-typography">
		<div class="row col-12">
			<div style="padding-right: 30px;">
				<mat-form-field class="col-4" style="padding: 1%;" appearance="fill">
					<mat-label>Tipo de identificación</mat-label>
					<mat-select formControlName="idType" (selectionChange)="validateDocumentId()" required>
						<mat-option *ngFor="let type of idTypes" [value]="type.id">
							{{ type.name }}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="error.idType.errors"><span *ngIf="error.idType.errors.required">¡Advertencia! Este
							campo es requerido</span></mat-error>
				</mat-form-field>

				<mat-form-field class="col-4" style="padding: 1%;" appearance="fill">
					<mat-label>Número de identificación</mat-label>
					<input matInput (keypress)="omitSpecialCharId($event)" type="text" formControlName="id"
						(blur)="validateDocumentId()" required />
					<mat-error *ngIf="error.id.errors"><span *ngIf="error.id.errors.pattern">¡Advertencia! Este campo
							solo acepta números</span></mat-error>
					<mat-error *ngIf="error.id.errors"><span *ngIf="error.id.errors.required">¡Advertencia! Este campo
							es requerido</span></mat-error>
					<mat-error *ngIf="error.id.errors"><span *ngIf="error.id.errors.minlength">¡Aún falta! Recibimos
							mínimo 5 caracteres</span></mat-error>
					<mat-error *ngIf="error.id.errors"><span *ngIf="error.id.errors.maxlength">¡Cuidado! Solo puedes
							tener un máximo de 15 caracteres</span></mat-error>
				</mat-form-field>

				<mat-form-field class="col-4" style="padding: 1%;" appearance="fill">
					<mat-label>Primer Nombre</mat-label>
					<input style="text-transform: capitalize" matInput type="text" formControlName="first_name"
						required />
					<mat-error *ngIf="error.first_name.errors"><span
							*ngIf="error.first_name.errors.required">¡Advertencia! Este campo es requerido</span>
					</mat-error>
					<mat-error *ngIf="error.first_name.errors"><span *ngIf="error.first_name.errors.minlength">¡Aún
							falta! Recibimos mínimo 3 caracteres</span></mat-error>
					<mat-error *ngIf="error.first_name.errors"><span *ngIf="error.first_name.errors.maxlength">¡Cuidado!
							Solo puedes tener un máximo de 20 caracteres</span></mat-error>
				</mat-form-field>

				<mat-form-field class="col-4" style="padding: 1%;" appearance="fill">
					<mat-label>Segundo Nombre</mat-label>
					<input style="text-transform: capitalize" matInput type="text" formControlName="middle_name" />
					<mat-error *ngIf="error.middle_name.errors"><span *ngIf="error.middle_name.errors.minlength">¡Aún
							falta! Recibimos mínimo 3 caracteres</span></mat-error>
					<mat-error *ngIf="error.middle_name.errors"><span
							*ngIf="error.middle_name.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 20
							caracteres</span></mat-error>
				</mat-form-field>

				<mat-form-field class="col-4" style="padding: 1%;" appearance="fill">
					<mat-label>Primer Apellido</mat-label>
					<input style="text-transform: capitalize" matInput type="text" formControlName="last_name"
						required />
					<mat-error *ngIf="error.last_name.errors"><span
							*ngIf="error.last_name.errors.required">¡Advertencia! Este campo es requerido</span>
					</mat-error>
					<mat-error *ngIf="error.last_name.errors"><span *ngIf="error.last_name.errors.minlength">¡Aún falta!
							Recibimos mínimo 3 caracteres</span></mat-error>
					<mat-error *ngIf="error.last_name.errors"><span *ngIf="error.last_name.errors.maxlength">¡Cuidado!
							Solo puedes tener un máximo de 20 caracteres</span></mat-error>
				</mat-form-field>

				<mat-form-field class="col-4" style="padding: 1%;" appearance="fill">
					<mat-label>Segundo Apellido</mat-label>
					<input style="text-transform: capitalize" matInput type="text" formControlName="second_last_name" />
					<mat-error *ngIf="error.second_last_name.errors"><span
							*ngIf="error.second_last_name.errors.minlength">¡Aún falta! Recibimos mínimo 3
							caracteres</span></mat-error>
					<mat-error *ngIf="error.second_last_name.errors"><span
							*ngIf="error.second_last_name.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 20
							caracteres</span></mat-error>
				</mat-form-field>

				<mat-form-field class="col-4" style="padding: 1%;" appearance="fill">
					<mat-label>Campaña</mat-label>
					<input type="text" matInput formControlName="campaign" [matAutocomplete]="auto" required />
					<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnCampaign.bind(this)" required>
						<mat-option *ngFor="let option of filteredOptionsCampaign | async" [value]="option.id">
							{{ option.name }}
						</mat-option>
					</mat-autocomplete>
					<mat-error *ngIf="error.campaign.errors"><span *ngIf="error.campaign.errors.required">¡Advertencia!
							Este campo es requerido</span></mat-error>
					<mat-error *ngIf="error.campaign.errors"><span
							*ngIf="error.campaign.errors.invalidAutocompleteObject">Aplicación no reconocida. Haga click
							en una de las opciones de autocompletar</span></mat-error>
				</mat-form-field>

				<mat-form-field class="col-4" style="padding: 1%;" appearance="fill">
					<mat-label>Teléfono</mat-label>
					<span matPrefix>+&nbsp;</span>
					<input matInput (keypress)="omitSpecialCharPhone($event)" type="text" formControlName="phone"
						required />
					<mat-error *ngIf="error.phone.errors"><span *ngIf="error.phone.errors.required">¡Advertencia! Este
							campo es requerido</span></mat-error>
					<mat-error *ngIf="error.phone.errors"><span *ngIf="error.phone.errors.minlength">¡Aún falta!
							Recibimos mínimo 7 caracteres</span></mat-error>
					<mat-error *ngIf="error.phone.errors"><span *ngIf="error.phone.errors.maxlength">¡Cuidado! Solo
							puedes tener un máximo de 15 caracteres</span></mat-error>
				</mat-form-field>

				<mat-form-field class="col-4" style="padding: 1%;" appearance="fill">
					<mat-label>Correo</mat-label>
					<input matInput type="email" formControlName="email" required />
					<mat-error *ngIf="error.email.errors"><span *ngIf="error.email.errors.required">¡Advertencia! Este
							campo es requerido</span></mat-error>
					<mat-error *ngIf="error.email.errors"><span *ngIf="error.email.errors.minlength">¡Aún falta!
							Recibimos mínimo 7 caracteres</span></mat-error>
					<mat-error *ngIf="error.email.errors"><span *ngIf="error.email.errors.maxlength">¡Cuidado! Solo
							puedes tener un máximo de 50 caracteres</span>
						<mat-error *ngIf="error.email.errors"><span *ngIf="error.email.errors.email">¡Advertencia! Debe
								ingresar un correo valido</span></mat-error>
					</mat-error>
				</mat-form-field>

				<mat-form-field class="col-4" style="padding: 1%;" appearance="fill">
					<mat-label>Usuario</mat-label>
					<input appBlockCopyPaste matInput (keypress)="omitSpecialChar($event)" type="text"
						formControlName="user" (blur)="validateUser()" required />
					<mat-error *ngIf="error.user.errors"><span *ngIf="error.user.errors.required">¡Advertencia! Este
							campo es requerido</span></mat-error>
					<mat-error *ngIf="error.user.errors"><span *ngIf="error.user.errors.minlength">¡Aún falta! Recibimos
							mínimo 3 caracteres</span></mat-error>
					<mat-error *ngIf="error.user.errors"><span *ngIf="error.user.errors.maxlength">¡Cuidado! Solo puedes
							tener un máximo de 15 caracteres</span></mat-error>
					<mat-error *ngIf="error.user.errors"><span *ngIf="error.user.errors.invalidUser">{{ errorUser
							}}</span></mat-error>
				</mat-form-field>

				<mat-form-field class="col-4" style="padding: 1%;" appearance="fill">
					<mat-label>Contraseña</mat-label>
					<input appBlockCopyPaste matInput (keypress)="omitSpecialChar($event)"
						[type]="hide ? 'password' : 'text'" matInput formControlName="password" required />
					<mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.required">¡Advertencia!
							Este campo es requerido</span></mat-error>
					<mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.minlength">¡Aún falta!
							Recibimos mínimo 5 caracteres</span></mat-error>
					<mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.maxlength">¡Cuidado!
							Solo puedes tener un máximo de 15 caracteres</span></mat-error>
					<mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasNumber">Su contraseña
							debe tener un número</span></mat-error>
					<mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasCapitalCase">Su
							contraseña debe tener una mayuscula</span></mat-error>
					<mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasSmallCase">Su
							contraseña debe tener una minuscula</span></mat-error>
					<mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasSpecialCharacters">Su
							contraseña debe tener un caracter especial</span></mat-error>
					<a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
						[attr.aria-pressed]="hide">
						<mat-icon>{{ hide ? 'visibility' : 'visibility_off' }}</mat-icon>
					</a>
				</mat-form-field>
				<section class="col-4 mb-3" style="padding: 1%;" appearance="fill">
					<mat-checkbox formControlName="dobAuth">¿Requiere doble autentificación?</mat-checkbox>
				</section>
			</div>
			<br />
			<br />
			<div formArrayName="appxrol" class="row col-12">
				<h3>Añadir permisos</h3>

				<div class="col-6" *ngFor="let applet of appxrol.controls; let i = index">
					<mat-card style="background-color: #f0f0f0">
						<div class="row col-12" [formGroupName]="i">
							<mat-form-field style="padding: 1%" class="col-5" appearance="fill">
								<mat-label>Aplicación</mat-label>
								<input type="text" matInput formControlName="app" [matAutocomplete]="auto" required
									(focus)="getFilter(i)" />
								<mat-autocomplete (optionSelected)="appSelected($event.option.value, i)"
									#auto="matAutocomplete" [displayWith]="displayFn.bind(this)" required>
									<mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
										{{ option.name }}
									</mat-option>
								</mat-autocomplete>
								<mat-error *ngIf="error.appxrol.controls[i].controls.app.errors"><span
										*ngIf="error.appxrol.controls[i].controls.app.errors.required">¡Advertencia!
										Este campo es requerido</span></mat-error>
							</mat-form-field>

							<mat-form-field style="padding: 1%;margin-left:5px" class="col-5" appearance="fill">
								<mat-label>Rol</mat-label>
								<input type="text" matInput formControlName="rol" [matAutocomplete]="auto1" required
									(focus)="getfilterRol(i)" />
								<mat-autocomplete (optionSelected)="verifyRoles(i)" #auto1="matAutocomplete"
									[displayWith]="displayFnRoles.bind(this)" required>
									<mat-option *ngFor="let optionrol of filteredOptionsRoles | async"
										[value]="optionrol.id">
										{{ optionrol.name }}
									</mat-option>
								</mat-autocomplete>
								<mat-error *ngIf="error.appxrol.controls[i].controls.rol.errors"><span
										*ngIf="error.appxrol.controls[i].controls.rol.errors.required">¡Advertencia!
										Este campo es requerido</span></mat-error>
								<mat-error *ngIf="error.appxrol.controls[i].controls.rol.errors"><span
										*ngIf="error.appxrol.controls[i].controls.rol.errors.invalidRol">¡Advertencia!
										Este rol ya fue seleccionado para esta aplicación</span></mat-error>
							</mat-form-field>
							<div class="col-1">
								<button mat-icon-button (click)="deleteappxrol(i)"
									style="color:#BE0F0F;font-size: 20px;"><i class="fi-rr-trash"
										style="position:absolute"></i></button>
							</div>
						</div>


					</mat-card>
					<br />
					<br />
				</div>
			</div>

			<div class="col-6">
				<button mat-stroked-button type="button" class="col-4" color="primary" style="border-color: #2CABBC;"
					(click)="newappxrol()">Añadir <i class="fi-rr-add"></i></button>
			</div>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions style="height: 48px; padding-bottom: 70px;" align="end">
		<button mat-stroked-button mat-dialog-close>Cancelar</button>
		<button mat-raised-button type="submit" color="primary" disabled="{{ userForm.invalid }}"
			[mat-dialog-close]="true">Guardar</button>
	</mat-dialog-actions>
</form>