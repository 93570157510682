import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';
import { DashboardInterface } from '../interfaces/DashboardInterface';
import { map } from 'rxjs/operators';
import { PaginatorInterface } from '../interfaces/PaginatorInterface';


const SOULBI_URL = environment.REPORTS_URL;

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  headersFile;
  headers;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.headersFile = {
      responseType: "blob"
    };
  }

  /**
   * Consulta todos los dashboard activos
   * @returns
   */
  getDashboards(url = null , params ={}): Observable<DashboardInterface[]> {
    const dest = url == null ? `${SOULBI_URL}dashboards/` : url;

    return this.http.post(dest , params)
      .pipe( map( (dashes:DashboardInterface[]) => dashes ));
  }


  /**
   * Almacena una nueva configuración
   * @param data
   * @returns
   */
  guardar(data:DashboardInterface ):Observable<DashboardInterface>{
    return this.http.post(`${SOULBI_URL}dashboard/` , data)
      .pipe( map( (dash:DashboardInterface) => dash ));
  }

  /**
   * Almacena una nueva configuración
   * @param data
   * @returns
   */
   actualizar(dash:DashboardInterface , id:number ):Observable<DashboardInterface>{
    return this.http.put(`${SOULBI_URL}dashboard/${id}` , dash)
      .pipe( map( (dash:DashboardInterface) => dash ));
   }


  /**
   * Consulta los reportes asociados al rol logueado
   * @param campaign_id : campaña del usuario
   * @param position : cargo del usuario
   */
  getReportByRol( campaign_id:number, position:string , rrhh_id:number ) : Observable<DashboardInterface[]> {
    return this.http.post(`${SOULBI_URL}dashboard/get_by_rol/` , {campaign_id: campaign_id , position:position, rrhh_id: rrhh_id })
      .pipe( map( (dashes:DashboardInterface[]) => dashes["data"] ));
  }

  /**
   * Consulta los reportes asociados al usuario logueado
   * @param rrhh_id : campaña del usuario
   * @param position : cargo del usuario
   */
  getReportByuser( rrhh_id:number) : Observable<DashboardInterface[]> {
    return this.http.get(`${SOULBI_URL}dashboard/get_by_user/${rrhh_id}`)
      .pipe( map( (dashes:DashboardInterface[]) => dashes["data"] ));
  }


  eliminar( dash:DashboardInterface ): Observable<any> {
    return this.http.delete(`${SOULBI_URL}dashboard/${dash.id}`);
  }

  getToken(reportKey): Observable<any> {
    return this.http.get(`https://backendreportes.groupcos.com/api/report/tokens?key=${reportKey}`);

  }

}
