import { Component, OnInit, Inject } from '@angular/core';
import { LeadersService } from './../../../services/leaders.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestsService } from './../../../services/requests.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';

@Component({
  selector: 'app-modal-approve',
  templateUrl: './modal-approve.component.html',
  styleUrls: ['./modal-approve.component.sass']
})
export class ModalApproveComponent implements OnInit {

  leaders;
  requestId;
  leaderId;
  constructor(
        private leadersService: LeadersService,
        public dialogRef: MatDialogRef<ModalApproveComponent>,
        private requestServices: RequestsService,
        private alertService: AlertsService,
        @Inject(MAT_DIALOG_DATA) public data: any
        ) {
        this.requestId = data.id;
        }

  ngOnInit(): void {
     this.leadersService.getLideres().subscribe(  (leaders:any) => {
      this.leaders = leaders;
     });
  }
  selectLeader(leaderId): void {
      this.leaderId = leaderId;
  }
  asignarSolicitud(): void {
    this.alertService.alertConfirm('¿Está seguro que desea aprobar esta solicitud?').then((result) => {
      if (result.isConfirmed) {
        const params = {
        userAssignedId: this.leaderId
        };
        this.requestServices.appovalRequest(this.requestId, params).subscribe(  (resp) => {
          this.alertService.alertSuccess('Asignado', 'Su solicitud se ha asignado correctamente');
          this.dialogRef.close(true);
        });
      }
    });
  }
}
