import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { CoachingService } from '../../services/coaching.service';

@Injectable()
export class Historialffects {
 
  loadHistorico$ = createEffect(() => this.actions$.pipe(
    ofType('[Historial] Load historial'),
    mergeMap((data:any) => this.coachingSerivce.getHistorialById(data)
      .pipe(
        map((historial:any) => ({ type: '[Historial] Loaded historial', historial }))
      ))
    )
  );

  loadHistorialById$ = createEffect(() => this.actions$.pipe(
    ofType('[HistorialById] Load historial'),
    mergeMap((data:any) => this.coachingSerivce.getHistoricoById(data)
      .pipe(
        map((historial:any) => ({ type: '[HistorialById] Loaded historial', historial }))
      ))
    )
  );
 
  constructor(
    private actions$: Actions,
    private coachingSerivce:CoachingService,
  ) {}
}