import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatrizService } from 'src/app/modules/auditoria/services/matriz.service';
import { Tools } from 'src/app/modules/auditoria/utils/tools.utils';

@Component({
  selector: 'app-crear-sub-item',
  templateUrl: './crear-sub-item.component.html',
  styleUrls: ['./crear-sub-item.component.sass']
})
export class CrearSubItemComponent implements OnInit {

  subItemForm:FormGroup
  items = [];
  filteredOptions: Observable<any[]>;
  verError:boolean = true;
  continue:boolean = true;
  nuevo:boolean = false;
  descripcion:boolean = false;

  constructor(
    private fb:FormBuilder,
    public subItemRef:MatDialogRef<CrearSubItemComponent>,
    @Inject(MAT_DIALOG_DATA) public datos:any,
    private matrizService:MatrizService,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(){
    this.subItemForm = this.fb.group({
      nombre:['',Validators.required],
      descripcion:[''],
    })
  }


  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.items.filter(option => option.nombre.toLowerCase().includes(filterValue));
  }

  displayFn(module: any): string {
    return module && module ? module : '';
  }

  getSubItems(){
    this.matrizService.getSubtItems(this.subItemForm.get('nombre').value).subscribe((resp:any)=>{
      if (resp.length == 0) {
        this.verError = true
        this.subItemForm.get('nombre').setErrors({invalid:true});
        this.nuevo = true;
        this.descripcion = false;
        this.continue = false;
      }else{
        this.verError = false;
        this.nuevo = false;
        this.items = resp
        this.filteredOptions = this.subItemForm.get('nombre').valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      }
    })
  }


  newSubItem(){
    this.nuevo = false;
    this.subItemForm.get('nombre').setErrors(null);
    this.descripcion = true;
    this.continue = true;
  }

  saveSubItem(){
    if (this.subItemForm.invalid) {
      return
    }
    const data = {
      idMatriz: this.datos.id_matriz,
      nivel: 3,
      sigla: this.datos.sigla,
      padre_id: this.datos.id_item,
      error_critico: this.datos.error_critico,
    }
    Object.assign(data,this.subItemForm.value);
    this.matrizService.newSubItem(data).subscribe((resp:any)=>{
      if (resp.respuesta.codigo == 202) {
        Tools.swalSuccess('¡Excelente!','Se ha creado el subítem correctamente.');
        this.subItemRef.close();
      }else if (resp.respuesta.codigo > 400) {
        Tools.swalError('¡Oops!','Lo sentimos, ha ocurrido un error inesperado y no fue posible crear el subítem, por favor intenta nuevamente.');
      }
    });
  }


}
